<template>
  <transition name="fade">
    <div class="wrap" v-if="show">
      <div class="filter" v-on:click="closeModal"></div>
      <div class="main-block">
        <div class="top-panel">
          <div>
            <p class="fz18 fw500">Noon reports</p>
            <p class="fz13 text-gray">{{getDataContractV.name}} {{getFormDateTwo(getDataContractV.cp_date)}}</p>
          </div>
          <payment-dropdown-export @eventExportPDF="eventExportPDF" @eventExportXLSX="downloadXLSX" class="button-export"></payment-dropdown-export>
<!--          <report-option-button @archive="archive" @change="change"></report-option-button>-->
          <vessesls-reports-option-button v-if="$route.path.indexOf('archive') === -1" class="option" @emitEdit="change" @emitArchive="archive"></vessesls-reports-option-button>
          <button class="close" v-on:click="closeModal">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
              <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
          </button>
        </div>
        <div ref="mainBlock">
          <div class="main" v-if="info !== null" >
            <div>
              <p class="fz13 text-gray">REF Number</p>
              <p class="fz14 text-dark">{{info.reference_number}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Date</p>
              <p class="fz14 text-dark">{{getFormDate(info.date)}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Time</p>
              <p class="fz14 text-dark">{{getFormDateTime(info.date)}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Time Zone</p>
              <p class="fz14 text-dark">{{info.time_zone}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">HRS Since Last Report</p>
              <p class="fz14 text-dark">{{info.hrs_since_last_report}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Latitude</p>
              <p class="fz14 text-dark">{{info.latitude}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Longitude</p>
              <p class="fz14 text-dark">{{info.longitude}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Course</p>
              <p class="fz14 text-dark">{{info.course}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Speed</p>
              <p class="fz14 text-dark">{{info.speed}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">RPM</p>
              <p class="fz14 text-dark">{{info.rpm}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Slip</p>
              <p class="fz14 text-dark">{{info.slip}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Weather</p>
              <p class="fz14 text-dark">{{info.weather}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Sea Stade</p>
              <p class="fz14 text-dark">{{info.sea_stade}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Distance Since Last Report</p>
              <p class="fz14 text-dark">{{info.distance_since_last_report}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">AVG Speed Since Last Report</p>
              <p class="fz14 text-dark">{{info.avg_speed_since_last_report}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">BROB HSFO</p>
              <p class="fz14 text-dark">{{info.brob_hsfo}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">BROB VLSFO</p>
              <p class="fz14 text-dark">{{info.brob_vlsfo}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">BROB MDO</p>
              <p class="fz14 text-dark">{{info.brob_mdo}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">BROB LSMGO</p>
              <p class="fz14 text-dark">{{info.brob_lsmgo}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">BROB FW</p>
              <p class="fz14 text-dark">{{info.brob_fw}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Consumed HSFO (24hrs)</p>
              <p class="fz14 text-dark">{{info.consumed_hsfo_24_hrs}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Consumed VLSFO (24 hrs)</p>
              <p class="fz14 text-dark">{{info.consumed_vlsfo_24_hrs}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Consumed MDO (24 hrs)</p>
              <p class="fz14 text-dark">{{info.consumed_mdo_24_hrs}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Consumed LSMGO (24 hrs)</p>
              <p class="fz14 text-dark">{{info.consumed_lsmgo_24_hrs}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Distance From</p>
              <p class="fz14 text-dark">{{info.distance_from}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Distance To Go</p>
              <p class="fz14 text-dark">{{info.distance_to_go}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Next Port Name / ETA (wp/agw/uce)</p>
              <p class="fz14 text-dark">{{info.next_port_name_eta_wpagwuce}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Instruted Speed / Consumption</p>
              <p class="fz14 text-dark">{{info.instruted_speed_consumption}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Stoppages / Delays Ref Number</p>
              <p class="fz14 text-dark">{{info.stoppages_delays_ref_number}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Stoppage / Delays Description</p>
              <p class="fz14 text-dark">{{info.stoppage_delays_description}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Total Revolutions Counter (Last 24 hrs)</p>
              <p class="fz14 text-dark">{{info.total_revolition_counter_24_hrs}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Scavenge Air Pressure</p>
              <p class="fz14 text-dark">{{info.scavenge_air_pressure}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Me Speed Up To Normal</p>
              <p class="fz14 text-dark">{{info.me_speed_up_to_normal}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Quantity of auxiliary engines in operation</p>
              <p class="fz14 text-dark">{{info.quantity_of_auxiliary_engines_in_operation}}</p>
            </div>
            <div>
              <p class="fz13 text-gray">Other Remark</p>
              <p class="fz14 text-dark">{{info.other_remarks}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PaymentDropdownExport from "@/components/payment/payment-dropdown-export";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';
import VesseslsReportsOptionButton from "@/components/vessels/vessels-reports/vessesls-reports-option-button";

export default {
  name: "vessels-reports-detail-modal",
  components: {VesseslsReportsOptionButton, PaymentDropdownExport},
  computed: mapGetters(["getDataContractV"]),
  data() {
    return {
      info: null,
      show: false,
      startHTML: "<!doctype html>\n" +
          "<html lang=\"en\">\n" +
          "<head>\n" +
          "    <meta charset=\"UTF-8\">\n" +
          "    <title>Document</title>\n" +
          "    <style>\n" +
          "        @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');\n" +
          "\n" +
          "\n" +
          "        a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {\n" +
          "            margin: 0;\n" +
          "            padding: 0;\n" +
          "            border: 0;\n" +
          "            font-size: 100%;\n" +
          "            font: inherit;\n" +
          "            vertical-align: baseline\n" +
          "        }\n" +
          "\n" +
          "        :focus {\n" +
          "            outline: 0\n" +
          "        }\n" +
          "\n" +
          "        article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {\n" +
          "            display: block\n" +
          "        }\n" +
          "\n" +
          "        body {\n" +
          "            line-height: 1;\n" +
          "            width: 100vw;\n" +
          "            overflow-x: hidden;\n" +
          "        }\n" +
          "\n" +
          "        ol, ul {\n" +
          "            list-style: none\n" +
          "        }\n" +
          "\n" +
          "        input::-webkit-outer-spin-button,\n" +
          "        input::-webkit-inner-spin-button {\n" +
          "            -webkit-appearance: none;\n" +
          "            margin: 0;\n" +
          "        }\n" +
          "\n" +
          "        /* Firefox */\n" +
          "        input[type=number] {\n" +
          "            -moz-appearance: textfield;\n" +
          "        }\n" +
          "\n" +
          "        blockquote, q {\n" +
          "            quotes: none\n" +
          "        }\n" +
          "\n" +
          "        blockquote:after, blockquote:before, q:after, q:before {\n" +
          "            content: '';\n" +
          "            content: none\n" +
          "        }\n" +
          "\n" +
          "        table {\n" +
          "            border-collapse: collapse;\n" +
          "            border-spacing: 0\n" +
          "        }\n" +
          "\n" +
          "        input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {\n" +
          "            -webkit-appearance: none;\n" +
          "            -moz-appearance: none\n" +
          "        }\n" +
          "\n" +
          "        input[type=search] {\n" +
          "            -webkit-appearance: none;\n" +
          "            -moz-appearance: none;\n" +
          "            -webkit-box-sizing: content-box;\n" +
          "            -moz-box-sizing: content-box;\n" +
          "            box-sizing: content-box\n" +
          "        }\n" +
          "\n" +
          "        textarea {\n" +
          "            overflow: auto;\n" +
          "            vertical-align: top;\n" +
          "            resize: vertical\n" +
          "        }\n" +
          "\n" +
          "        audio, canvas, video {\n" +
          "            display: inline-block;\n" +
          "            max-width: 100%\n" +
          "        }\n" +
          "\n" +
          "        audio:not([controls]) {\n" +
          "            display: none;\n" +
          "            height: 0\n" +
          "        }\n" +
          "\n" +
          "        [hidden] {\n" +
          "            display: none\n" +
          "        }\n" +
          "\n" +
          "        html {\n" +
          "            font-size: 100%;\n" +
          "            -webkit-text-size-adjust: 100%;\n" +
          "            -ms-text-size-adjust: 100%\n" +
          "        }\n" +
          "\n" +
          "        a:focus {\n" +
          "            outline: thin dotted\n" +
          "        }\n" +
          "\n" +
          "        a:active, a:hover {\n" +
          "            outline: 0\n" +
          "        }\n" +
          "\n" +
          "        img {\n" +
          "            border: 0;\n" +
          "            -ms-interpolation-mode: bicubic\n" +
          "        }\n" +
          "\n" +
          "        figure {\n" +
          "            margin: 0\n" +
          "        }\n" +
          "\n" +
          "        form {\n" +
          "            margin: 0\n" +
          "        }\n" +
          "\n" +
          "        fieldset {\n" +
          "            border: 1px solid silver;\n" +
          "            margin: 0 2px;\n" +
          "            padding: .35em .625em .75em\n" +
          "        }\n" +
          "\n" +
          "        legend {\n" +
          "            border: 0;\n" +
          "            padding: 0;\n" +
          "            white-space: normal\n" +
          "        }\n" +
          "\n" +
          "        button, input, select, textarea {\n" +
          "            font-size: 100%;\n" +
          "            margin: 0;\n" +
          "            vertical-align: baseline\n" +
          "        }\n" +
          "\n" +
          "        button, input {\n" +
          "            line-height: normal\n" +
          "        }\n" +
          "\n" +
          "        button, select {\n" +
          "            text-transform: none\n" +
          "        }\n" +
          "\n" +
          "        button, html input[type=button], input[type=reset], input[type=submit] {\n" +
          "            -webkit-appearance: button;\n" +
          "            cursor: pointer\n" +
          "        }\n" +
          "\n" +
          "        button[disabled], html input[disabled] {\n" +
          "            cursor: default\n" +
          "        }\n" +
          "\n" +
          "        input[type=checkbox], input[type=radio] {\n" +
          "            box-sizing: border-box;\n" +
          "            padding: 0\n" +
          "        }\n" +
          "\n" +
          "        input[type=search] {\n" +
          "            -webkit-appearance: textfield;\n" +
          "            -moz-box-sizing: content-box;\n" +
          "            -webkit-box-sizing: content-box;\n" +
          "            box-sizing: content-box\n" +
          "        }\n" +
          "\n" +
          "        input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {\n" +
          "            -webkit-appearance: none\n" +
          "        }\n" +
          "\n" +
          "        button::-moz-focus-inner, input::-moz-focus-inner {\n" +
          "            border: 0;\n" +
          "            padding: 0\n" +
          "        }\n" +
          "\n" +
          "        textarea {\n" +
          "            overflow: auto;\n" +
          "            vertical-align: top\n" +
          "        }\n" +
          "\n" +
          "        table {\n" +
          "            border-collapse: collapse;\n" +
          "            border-spacing: 0\n" +
          "        }\n" +
          "\n" +
          "        button, html, input, select, textarea {\n" +
          "            color: #222\n" +
          "        }\n" +
          "\n" +
          "        ::-moz-selection {\n" +
          "            background: #b3d4fc;\n" +
          "            text-shadow: none\n" +
          "        }\n" +
          "\n" +
          "        ::selection {\n" +
          "            background: #b3d4fc;\n" +
          "            text-shadow: none\n" +
          "        }\n" +
          "\n" +
          "        img {\n" +
          "            vertical-align: middle\n" +
          "        }\n" +
          "\n" +
          "        fieldset {\n" +
          "            border: 0;\n" +
          "            margin: 0;\n" +
          "            padding: 0\n" +
          "        }\n" +
          "\n" +
          "        textarea {\n" +
          "            resize: vertical\n" +
          "        }\n" +
          "\n" +
          "        a, button, h1, h2, h3, h4, h5, h6, span, div, section, body, html, p, input, select {\n" +
          "            font-family: 'SFProDisplay', sans-serif;\n" +
          "            font-style: normal;\n" +
          "            font-weight: normal;\n" +
          "        }\n" +
          "\n" +
          "        button, a {\n" +
          "            border: none;\n" +
          "            background: transparent;\n" +
          "            outline: none !important;\n" +
          "            box-shadow: none;\n" +
          "            text-decoration: none;\n" +
          "            padding: 0;\n" +
          "            margin: 0;\n" +
          "        }\n" +
          "\n" +
          "        ::-webkit-scrollbar-track\n" +
          "        {\n" +
          "            background-color: #F4F6F9;\n" +
          "        }\n" +
          "\n" +
          "        ::-webkit-scrollbar\n" +
          "        {\n" +
          "            width: 4px;\n" +
          "            height: 4px;\n" +
          "            background-color: #F5F5F5;\n" +
          "        }\n" +
          "\n" +
          "        ::-webkit-scrollbar-thumb\n" +
          "        {\n" +
          "            background: #BDCBD6;\n" +
          "            border-radius: 30px;\n" +
          "            width: 3px;\n" +
          "        }\n" +
          "\n" +
          "        input {\n" +
          "            -webkit-appearance: none;\n" +
          "        }\n" +
          "\n" +
          "\n" +
          "        * {\n" +
          "            font-family: 'Source Sans Pro', sans-serif;\n" +
          "            font-style: normal;\n" +
          "            font-weight: normal;\n" +
          "        }\n" +
          "        body, html {\n" +
          "            background-color: #fff;\n" +
          "        }\n" +
          "        body {\n" +
          "            background-color: #F4F6F9 !important;\n" +
          "            width: 1400px;\n" +
          "            height: 100vh;\n" +
          "        }\n" +
          "\n" +
          "        .fw500 {\n" +
          "            font-weight: 500;\n" +
          "        }\n" +
          "        .fw600 {\n" +
          "            font-weight: 600;\n" +
          "        }\n" +
          "\n" +
          "        .text-dark {\n" +
          "            color: #2C2C2C;\n" +
          "        }\n" +
          "\n" +
          "        .text-green {\n" +
          "            color: #0FA54E;\n" +
          "        }\n" +
          "\n" +
          "        .text-white {\n" +
          "            color: #ffffff;\n" +
          "        }\n" +
          "        .text-dark-gray {\n" +
          "            color: #4B4B4B;\n" +
          "        }\n" +
          "        .text-gray {\n" +
          "            color: #A3A3A3;\n" +
          "        }\n" +
          "        .text-red {\n" +
          "            color: #E85353;\n" +
          "        }\n" +
          "\n" + ".main > * {\n" +
          "\t display: flex;\n" +
          "width: 100%;" +
          "}\n" +
          " .main > * > *:nth-child(1) {\n" +
          "\t width: 44%;\n" +
          "\t padding: 8px 24px;\n" +
          "}\n" +
          " .main > * > *:nth-child(2) {\n" +
          "\t width: 56%;\n" +
          "\t padding: 8px 24px 8px 0;\n" +
          "}\n" +
          " .main > *:nth-child(2n+2) {\n" +
          "\t background-color: #f8fafd;\n" +
          "}\n" +
          " " +
          "   .fz13 {\n" +
          "            font-size: 13px !important;\n" +
          "            line-height: 15px !important;\n" +
          "word-wrap: break-word;\n" +
          "overflow-wrap: normal;  /* не поддерживает IE, Firefox; является копией word-wrap */ \n" +
          "  word-wrap: break-word;\n" +
          "  word-break: normal;  /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */ \n" +
          "  line-break: auto;  /* нет поддержки для русского языка */ \n" +
          "  hyphens: manual;  /* значение auto не поддерживается Chrome */ " +
          "width: 100%;" +
          "box-sizing: border-box;"+
          "        }\n" +
          "\n" +
          "        .fz14 {\n" +
          "            font-size: 14px !important;\n" +
          "            line-height: 16px !important;\n" +
          "        }"+
          "        .text-blue {\n" +
          "            color: #319FEF;\n" +
          "        }\n" +
          "    </style>\n" +
          "\n" +
          "</head>\n" +
          "<body>",
      endHTML: "</body>\n" +
          "</html>",
    }
  },
  methods: {
    ...mapActions(["axiosGetPDF", "axiosGetXLSX"]),
    archive() {
      this.$emit('archive', this.info.id)
      this.closeModal();
    },
    change() {
      this.$emit('change', this.info)
      this.show = false;
      this.info = null;
    },
    openModal(info) {
      this.$emit('open')
      this.show = true;
      // console.log("info")
      // console.log(info)
      this.info = info;
    },
    closeModal() {
      this.$emit('close')
      this.show = false;
      this.info = null;
    },
    getFormDateTime(date) {
      if(date !== null && ( ""+date).length !== 0) {
        return moment(new Date(date)).format("HH:mm")
      }
      return  "";
    },
    getFormDate(date) {
      if(date !== null && ( ""+date).length !== 0) {
        return moment(new Date(date)).format("DD/MM/YYYY")
      }
      return  "";
    },
    getFormDateTwo(date) {
      if(date !== null && ( ""+date).length !== 0) {
        return moment(new Date(date)).format("DD.MM.YYYY")
      }
      return  "";
    },
    downloadXLSX() {
      let formAxios= {
        sheet_title: "Non Reports",
        file_name: "Non Reports",
        data: []
      }
      let dataLoc=[];
      let array1 = ["REF Number"]
      if((""+this.info.reference_number).length === 0 && this.info.reference_number !== null) {
        array1.push("-")
      } else {
        array1.push(this.info.reference_number)
      }
      dataLoc.push({id: 1, values: array1})
      let array2 = ["Date"]
      if((""+this.info.date).length === 0 && this.info.date !== null) {
        array2.push("-")
      } else {
        array2.push(this.getFormDate(this.info.date))
      }
      dataLoc.push({id: 2, values: array2})
      let array3 = ["Time"]
      if((""+this.info.time).length === 0 && this.info.time !== null) {
        array3.push("-")
      } else {
        array3.push(this.info.time)
      }
      dataLoc.push({id: 3, values: array3})
      let array4 = ["Time Zone"]
      if((""+this.info.time_zone).length === 0 && this.info.time_zone !== null) {
        array4.push("-")
      } else {
        array4.push(this.info.time_zone)
      }
      dataLoc.push({id: 4, values: array4})
      let array5 = ["HRS Since Last Report"]
      if((""+this.info.hrs_since_last_report).length === 0 && this.info.hrs_since_last_report !== null) {
        array5.push("-")
      } else {
        array5.push(this.info.hrs_since_last_report)
      }
      dataLoc.push({id: 5, values: array5})
      let array6 = ["Latitude"]
      if((""+this.info.latitude).length === 0 && this.info.latitude !== null) {
        array6.push("-")
      } else {
        array6.push(this.info.latitude)
      }
      dataLoc.push({id: 6, values: array6})
      let array7 = ["Longitude"]
      if((""+this.info.longitude).length === 0 && this.info.longitude !== null) {
        array7.push("-")
      } else {
        array7.push(this.info.longitude)
      }
      dataLoc.push({id: 7, values: array7})
      let array8 = ["Course"]
      if((""+this.info.course).length === 0 && this.info.course !== null) {
        array8.push("-")
      } else {
        array8.push(this.info.course)
      }
      dataLoc.push({id: 8, values: array8})
      let array9 = ["Speed"]
      if((""+this.info.speed).length === 0 && this.info.speed !== null) {
        array9.push("-")
      } else {
        array9.push(this.info.speed)
      }
      dataLoc.push({id: 9, values: array9})
      let array10 = ["RPM"]
      if((""+this.info.rpm).length === 0 && this.info.rpm !== null) {
        array10.push("-")
      } else {
        array10.push(this.info.rpm)
      }
      dataLoc.push({id: 10, values: array10})
      let array11 = ["Slip"]
      if((""+this.info.slip).length === 0 && this.info.slip !== null) {
        array11.push("-")
      } else {
        array11.push(this.info.slip)
      }
      dataLoc.push({id: 11, values: array11})
      let array12 = ["Weather"]
      if((""+this.info.weather).length === 0 && this.info.weather !== null) {
        array12.push("-")
      } else {
        array12.push(this.info.weather)
      }
      dataLoc.push({id: 12, values: array12})
      let array13 = ["Sea Stade"]
      if((""+this.info.sea_stade).length === 0 && this.info.sea_stade !== null) {
        array13.push("-")
      } else {
        array13.push(this.info.sea_stade)
      }
      dataLoc.push({id: 13, values: array13})
      let array14 = ["Distance Since Last Report"]
      if((""+this.info.distance_since_last_report).length === 0 && this.info.distance_since_last_report !== null) {
        array14.push("-")
      } else {
        array14.push(this.info.distance_since_last_report)
      }
      dataLoc.push({id: 14, values: array14})
      let array15 = ["AVG Speed Since Last Report"]
      if((""+this.info.avg_speed_since_last_report).length === 0 && this.info.avg_speed_since_last_report !== null) {
        array15.push("-")
      } else {
        array15.push(this.info.avg_speed_since_last_report)
      }
      dataLoc.push({id: 15, values: array15})
      let array16 = ["BROB HSFO"]
      if((""+this.info.brob_hsfo).length === 0 && this.info.brob_hsfo !== null) {
        array16.push("-")
      } else {
        array16.push(this.info.brob_hsfo)
      }
      dataLoc.push({id: 16, values: array16})
      let array17 = ["BROB VLSFO"]
      if((""+this.info.brob_vlsfo).length === 0 && this.info.brob_vlsfo !== null) {
        array17.push("-")
      } else {
        array17.push(this.info.brob_vlsfo)
      }
      dataLoc.push({id: 17, values: array17})
      let array18 = ["BROB MDO"]
      if((""+this.info.brob_mdo).length === 0 && this.info.brob_mdo !== null) {
        array18.push("-")
      } else {
        array18.push(this.info.brob_mdo)
      }
      dataLoc.push({id: 18, values: array18})
      let array19 = ["BROB LSMGO"]
      if((""+this.info.brob_lsmgo).length === 0 && this.info.brob_lsmgo !== null) {
        array19.push("-")
      } else {
        array19.push(this.info.brob_lsmgo)
      }
      dataLoc.push({id: 19, values: array19})
      let array20 = ["BROB FW"]
      if((""+this.info.brob_fw).length === 0 && this.info.brob_fw !== null) {
        array20.push("-")
      } else {
        array20.push(this.info.brob_fw)
      }
      dataLoc.push({id: 20, values: array20})
      let array21 = ["Consumed HSFO (24hrs)"]
      if((""+this.info.consumed_hsfo_24_hrs).length === 0 && this.info.consumed_hsfo_24_hrs !== null) {
        array21.push("-")
      } else {
        array21.push(this.info.consumed_hsfo_24_hrs)
      }
      dataLoc.push({id: 21, values: array21})
      let array22 = ["Consumed VLSFO (24 hrs)"]
      if((""+this.info.consumed_vlsfo_24_hrs).length === 0 && this.info.consumed_vlsfo_24_hrs !== null) {
        array22.push("-")
      } else {
        array22.push(this.info.consumed_vlsfo_24_hrs)
      }
      dataLoc.push({id: 22, values: array22})
      let array23 = ["Consumed MDO (24 hrs)"]
      if((""+this.info.consumed_mdo_24_hrs).length === 0 && this.info.consumed_mdo_24_hrs !== null) {
        array23.push("-")
      } else {
        array23.push(this.info.consumed_mdo_24_hrs)
      }
      dataLoc.push({id: 23, values: array23})
      let array24 = ["Consumed LSMGO (24 hrs)"]
      if((""+this.info.consumed_lsmgo_24_hrs).length === 0 && this.info.consumed_lsmgo_24_hrs !== null) {
        array24.push("-")
      } else {
        array24.push(this.info.consumed_lsmgo_24_hrs)
      }
      dataLoc.push({id: 24, values: array24})
      let array25 = ["Distance From"]
      if((""+this.info.distance_from).length === 0 && this.info.distance_from !== null) {
        array25.push("-")
      } else {
        array25.push(this.info.distance_from)
      }
      dataLoc.push({id: 25, values: array25})
      let array26 = ["Distance To Go"]
      if((""+this.info.distance_to_go).length === 0 && this.info.distance_to_go !== null) {
        array26.push("-")
      } else {
        array26.push(this.info.distance_to_go)
      }
      dataLoc.push({id: 26, values: array26})
      let array27 = ["Next Port Name / ETA (wp/agw/uce)"]
      if((""+this.info.next_port_name_eta_wpagwuce).length === 0 && this.info.next_port_name_eta_wpagwuce !== null) {
        array27.push("-")
      } else {
        array27.push(this.info.next_port_name_eta_wpagwuce)
      }
      dataLoc.push({id: 27, values: array27})
      let array28 = ["Instruted Speed / Consumption"]
      if((""+this.info.instruted_speed_consumption).length === 0 && this.info.instruted_speed_consumption !== null) {
        array28.push("-")
      } else {
        array28.push(this.info.instruted_speed_consumption)
      }
      dataLoc.push({id: 28, values: array28})
      let array29 = ["Stoppages / Delays Ref Number"]
      if((""+this.info.stoppages_delays_ref_number).length === 0 && this.info.stoppages_delays_ref_number !== null) {
        array29.push("-")
      } else {
        array29.push(this.info.stoppages_delays_ref_number)
      }
      dataLoc.push({id: 29, values: array29})
      let array31 = ["Stoppage / Delays Description"]
      if((""+this.info.stoppage_delays_description).length === 0 && this.info.stoppage_delays_description !== null) {
        array31.push("-")
      } else {
        array31.push(this.info.stoppage_delays_description)
      }
      dataLoc.push({id: 30, values: array31})
      let array32 = ["Total Revolutions Counter (Last 24 hrs)"]
      if((""+this.info.total_revolition_counter_24_hrs).length === 0 && this.info.total_revolition_counter_24_hrs !== null) {
        array32.push("-")
      } else {
        array32.push(this.info.total_revolition_counter_24_hrs)
      }
      dataLoc.push({id: 31, values: array32})
      let array33 = ["Scavenge Air Pressure"]
      if((""+this.info.scavenge_air_pressure).length === 0 && this.info.scavenge_air_pressure !== null) {
        array33.push("-")
      } else {
        array33.push(this.info.scavenge_air_pressure)
      }
      dataLoc.push({id: 32, values: array33})
      let array34 = ["Me Speed Up To Normal"]
      if((""+this.info.me_speed_up_to_normal).length === 0 && this.info.me_speed_up_to_normal !== null) {
        array34.push("-")
      } else {
        array34.push(this.info.me_speed_up_to_normal)
      }
      dataLoc.push({id: 33, values: array34})
      let array35 = ["Quantity of auxiliary engines in operation"]
      if((""+this.info.quantity_of_auxiliary_engines_in_operation).length === 0 && this.info.quantity_of_auxiliary_engines_in_operation !== null) {
        array35.push("-")
      } else {
        array35.push(this.info.quantity_of_auxiliary_engines_in_operation)
      }
      dataLoc.push({id: 34, values: array35})
      let array36 = ["Other Remark"]
      if((""+this.info.other_remarks).length === 0 && this.info.other_remarks !== null) {
        array36.push("-")
      } else {
        array36.push(this.info.other_remarks)
      }
      dataLoc.push({id: 35, values: array36})
      formAxios.data = dataLoc;
      formAxios = JSON.parse(JSON.stringify(formAxios))
      this.axiosGetXLSX(formAxios).then(response => {

        let link = response.data;
        //  // window.open(link)·
        // // console.log(1);
        function download(content, contentType, fileName) {
          var link1 = document.createElement("a");
          link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
          link1.download = fileName;
          link1.click();
        }


        download(link, "application/vnd.ms-excel", "NonPeports.xls")
      });

    },
    PDFHtmlCode(codePage) {
      this.axiosGetPDF({html: codePage, name: "pdf"}).then(response=> {
        // console.log("File");
        // console.log(new File(, "file.pdf"));
        // var pdfAsDataUri = "data:application/pdf;base64,"+response.data;
        // window.open(pdfAsDataUri);
        // console.log(response.data)
        let data = response.data;
        // window.open("data:application/pdf;base64," + encodeURI(response.data));
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
          var byteCharacters = atob(data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: 'application/pdf'});
          //var blob_iframe = document.querySelector('#blob-src-test');
          //blob_iframe.src = blob_url;
          window.navigator.msSaveOrOpenBlob(blob, "pdf");
        }
        else { // much easier if not IE
          // this.filePath = "data:application/pdf;base64," + data, '', "height=600,width=800"
          // window.open("data:application/pdf;base64," + data);
          let link = "data:application/pdf;base64," + data;
          axios.get(link, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = "Non Reports";
                link.click()
                URL.revokeObjectURL(link.href)
              }).catch(console.error)
        }
      })
    },

    eventExportPDF() {
      this.PDFHtmlCode(this.startHTML + this.$refs.mainBlock.innerHTML + this.endHTML)
    },
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
  @import "vessels-reports-detail-modal";
</style>

