<template>
  <div class="modal-wrap">
    <!-- <div class="modal-bg" @click="closeModal"></div> -->
    <div class="modal-bg"></div>
    <div class="modal-content">
      <div class="modal-content_head">
        <p class="modal-content_title" v-if="!bank">Create New Bank or Cash <button class="modal-close" @click="closeModal"></button></p>
        <p class="modal-content_title" v-else>Edit {{bank.type}} <button class="modal-close" @click="closeModal"></button></p>
      </div>
      <div class="modal-content_body">

        <div class="bank-add_switcher" v-if="!bank">
          <label :class="{'active': type == 'bank'}"><span>Bank</span><input type="radio" value="bank" v-model="type"></label>
          <label :class="{'active': type == 'cash'}"><span>Cash</span><input type="radio" value="cash" v-model="type"></label>
        </div>

        <div v-if="type == 'bank'">
          <label class="block-input" :class="{ 'block-input_error': $v.name.$error }">
            <p class="block-input_title text-gray">Bank Name</p>
            <input class="block-input_content" type="text" maxlength="255" v-model.trim="$v.name.$model">
            <div class="error" v-if="!$v.name.required && $v.name.$dirty">Field is required</div>
            <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div>
            <div class="error" v-if="!$v.name.maxLength">Name must have at max {{$v.name.$params.maxLength.max}} letters.</div>
          </label>

          <label class="block-input checkbox">
            <input class="block-input_content" type="checkbox" v-model="isFavourite">
            <span class="custom-checkbox"></span>
            <p class="fz14">Favorite</p>
          </label>
        </div>
        <div v-else>
          <label class="block-input" :class="{ 'block-input_error': $v.name.$error }">
            <p class="block-input_title text-gray">Cash Name</p>
            <input class="block-input_content" type="text" maxlength="255" v-model.trim="$v.name.$model">
            <div class="error" v-if="!$v.name.required && $v.name.$dirty">Field is required</div>
            <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div>
            <div class="error" v-if="!$v.name.maxLength">Name must have at max {{$v.name.$params.maxLength.max}} letters.</div>
          </label>

          <counterparties-drop 
            :class="{ 'block-input_error': $v.selectCurrency.$error }"
            ref="dropCurrency"
            :list="currencyList" 
            :activeItem="activeCurrency ? activeCurrency : null"
            @select="selectedCurrency" 
            title="Currency"
          ></counterparties-drop>
          <div class="error" v-if="!$v.selectCurrency.required && $v.selectCurrency.$dirty">Field is required</div>

          <counterparties-drop 
            :class="{ 'block-input_error': $v.selectTrial.$error }"
            ref="dropAccount"
            :list="getTrialsInDrop"
            :activeItem="activeTrial ? activeTrial : null"
            @select="selectedTrial" 
            title="Account Name"
          ></counterparties-drop>
          <div class="error" v-if="!$v.selectTrial.required && $v.selectTrial.$dirty">Field is required</div>

          <label class="block-input checkbox">
            <input class="block-input_content" type="checkbox" v-model="isFavourite">
            <span class="custom-checkbox"></span>
            <p class="fz14">Favorite</p>
          </label>
        </div>

      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal">Cancel</button>
        <button class="btn btn-next" @click="onSubmit" v-if="!bank">Create</button>
        <button class="btn btn-next" @click="onSubmit" v-else>Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop';
export default {
  mixins: [validationMixin],
  props: ['bank'],
  components: {
    counterpartiesDrop,
  },
  data() {
    return {
      type: 'bank',
      name: '',
      isFavourite: false,
      selectCurrency: {},
      activeCurrency: null,
      selectTrial: {},
      activeTrial: null,
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(2)
    },
    selectCurrency: {
      required
    },
    selectTrial: {
      required
    },
  },
  computed: {
    ...mapState({
      currencyList: state => state.payment.valutaList,
      trials: state => state.trialBalance.trials
    }),
    getTrialsInDrop() {
      return this.trials.map(el => {
        return {id: el.id, name: el.id+': '+el.name}
      })
    },
    getFieldsInObj() {
      let obj = {
        type: this.type,
        name: this.name,
        is_favorite: this.isFavourite,
        is_archive: false,
      }
      if(this.type == 'cash'){
        obj.currency = this.selectCurrency.id,
        obj.account = this.selectTrial.id
      }
      return obj
    }
  },
  methods: {
    ...mapActions(['axiosGetTrials', 'axiosAddBank', 'axiosEditBank']),
    ...mapMutations(['showLoad', 'hideLoad']),
    closeModal() {
      this.$emit('close')
    },
    async onSubmit() {
      if(!this.validateFields()) return false

      this.showLoad()
      if(this.bank){
        await this.axiosEditBank({bank: this.getFieldsInObj, id: this.bank.id})
      }else{
        await this.axiosAddBank(this.getFieldsInObj)
      }

      this.$emit('update')
      this.closeModal()
      this.hideLoad()
    },
    selectedCurrency(currency) {
      this.selectCurrency = currency
      this.$v.selectCurrency.$touch()
    },
    selectedTrial(trial) {
      this.selectTrial = trial
      this.$v.selectTrial.$touch()
    },
    validateFields() {
      this.$v.$touch()
      if(this.type == 'bank' && this.$v.name.$invalid)
        return false
      if (this.type == 'cash' && this.$v.$invalid)
        return false
      return true
    },
  },
  mounted() {
    if(!this.trials.length)
      this.axiosGetTrials()

    if(this.bank){
      this.type = this.bank.type
      this.name = this.bank.name
      this.isFavourite = this.bank.is_favorite
      if(this.bank.type == 'cash') {
        this.activeCurrency = this.currencyList.filter(el => {
          return el.name == this.bank.currency
        })[0]
        this.activeTrial = this.getTrialsInDrop.filter(el => {
          return el.id == this.bank.account
        })[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-wrap{
  .modal-content_head{
    padding: 16px 12px 8px 24px;
    border-bottom: 1px solid var(--color-card-border);
  }
  .modal-content_title{
    margin-bottom: 0;
    font-size: 18px;
  }
  .modal-content_body{
    padding: 0 24px;
  }
  .modal-content{
    max-width: 420px;
  }
  .modal-content_bottom{
    margin-top: 20px;
    padding: 8px 24px;
    border-top: 1px solid var(--color-card-border);
  }
  .btn{
    min-width: 136px;
  }
  .btn-close{
    min-width: 60px;
    margin-right: 25px;
  }
  .button-blue-border{
    display: inline-flex;
    margin-right: 8px;
    padding: 7px;
    border: 1px solid var(--color-blue-dark);
  }
}
.error{
  margin-top: 4px;
  color: #E85353;
  font-size: 12px;
}
.bank-add_switcher{
  position: relative;
  display: flex;
  margin-left: -24px;
  margin-right: -24px;
  padding: 0 24px;
  border-bottom: 1px solid var(--color-border);
  label{
    position: relative;
    display: inline-block;
    width: 50%;
    text-align: center;
    color: #9AA3B0;
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.3s;
    &:after{
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #094172;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover{
      color: #094172;
    }
    &.active{
      color: #094172;
      &:after{
        opacity: 1;
      }
    }
    span{
      display: inline-block;
      padding: 16px;
      font-weight: 500;
    }
    input{
      position: absolute;
      opacity: 0;
      clip: rect(0, 0, 0, 0)
    }
  }
}
</style>

<style lang="scss">
.block-input.checkbox{
  display: flex;
  align-items: center;
  .custom-checkbox{
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    &:before{
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--color-card);
      border-radius: 4px;
      border: 1px solid var(--color-border);
      outline: none;
      box-shadow: none;
      pointer-events: none;
      box-sizing: border-box;
      transition: .1s linear;
    }
    &:after{
      position: absolute;
      left: 0px;
      top: 0px;
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      content: "";
      box-sizing: border-box;
      background: no-repeat 50% / 50% 50%;
    }
  }
  .block-input_content:checked ~ .custom-checkbox:before {
    border-color: var(--color-blue-dark) !important;
    background-color: #094172;
  }
  .block-input_content:checked ~ .custom-checkbox:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
}
</style>