<template>
  <div class="counterparties-tabs">
    <div class="counterparties-tabs_top">
      <router-link 
        v-for="link in links" 
        :key="link.to" 
        ref="tab"
        :to="link.to" 
        class="counterparties-tabs_link"
      >{{link.text}}</router-link>

<!--      <span ref="line" class="line"></span>-->
    </div>
    <router-view :client="client"></router-view>
  </div>
</template>

<script>
export default {
  props: ['links', 'client'],
  data() {
    return {
    }
  },
  watch: {
    async $route() {
      await this.$nextTick()
      // this.changeTab(this.getActiveItem())
    }
  },
  methods: {
    // changeTab(i) {
    //   this.$refs.line.style.left = this.$refs.tab[i].$el.offsetLeft + "px";
    //   this.$refs.line.style.width = this.$refs.tab[i].$el.clientWidth + "px";
    // },
    // getActiveItem() {
    //   return Array.from(this.$refs.tab[0].$el.parentNode.children).indexOf(this.$refs.tab[0].$el.parentNode.querySelector('.router-link-active'))
    // }
  },
  mounted() {
    // this.changeTab(this.getActiveItem())
  }
}
</script>

<style lang="scss">
  .counterparties-tabs{
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    &_top{
      position: relative;
      padding: 0 24px;
      background: var(--color-bgc-page-white);
      box-shadow: inset 0px -1px 0px var(--color-border);
      .line{
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 0;
        height: 2px;
        will-change: left, width;
        background-color: var(--color-blue-dark);
        transition: left 0.3s, width 0.3s;
      }
    }
    &_link{
      display: inline-block;
      padding: 20px 0 19px;
      font-weight: 500;
      font-size: 16px;
      color: #9AA3B0;
      &:not(:last-of-type) {
        margin-right: 32px;
      }
      &.router-link-active{
        position: relative;

        color: var(--color-blue-dark);
        &::after {
          content: '';
          width: 100%;
          height: 2px;
          background-color: var(--color-blue-dark);
          bottom: 0px;
          left: 0;
          position: absolute;
        }
      }
    }
  }
</style>