<template>
  <div class="block-list">
    <div class="title-block">
      <div class="description">
        <p class="text-white-always fw500 fz14" v-if="itemChLocal">{{itemChLocal.name}}</p>
      </div>
      <div class="priority">
        <p class="fz14 text-white-always">Priority</p>
      </div>
      <div class="date">
        <p class="fz14 text-white-always">Due date</p>
      </div>
      <div class="show-hidden justify-center cursor-pointer" v-on:click="hideAll = !hideAll">
        <div class="add-title" :title-text="!hideAll ? 'Hide hidden' : 'Show hidden'">
          <svg v-if="hideAll" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.6204 11.5823C22.4282 11.3194 17.8487 5.14453 11.9998 5.14453C6.15093 5.14453 1.57119 11.3194 1.3792 11.5821C1.19717 11.8315 1.19717 12.1698 1.3792 12.4193C1.57119 12.6822 6.15093 18.8571 11.9998 18.8571C17.8487 18.8571 22.4282 12.6821 22.6204 12.4195C22.8027 12.1701 22.8027 11.8315 22.6204 11.5823ZM11.9998 17.4385C7.69149 17.4385 3.96002 13.3401 2.85543 12.0003C3.95859 10.6593 7.68225 6.56306 11.9998 6.56306C16.3079 6.56306 20.0392 10.6607 21.1442 12.0013C20.0411 13.3422 16.3174 17.4385 11.9998 17.4385Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 10.1728C10.9934 10.1728 10.1718 10.9944 10.1718 12.0011C10.1718 13.0077 10.9934 13.8294 12.0001 13.8294C13.0067 13.8294 13.8283 13.0077 13.8283 12.0011C13.8283 10.9944 13.0067 10.1728 12.0001 10.1728ZM8.57209 12.0011C8.57209 10.1109 10.1099 8.57311 12.0001 8.57311C13.8902 8.57311 15.4281 10.1109 15.4281 12.0011C15.4281 13.8913 13.8902 15.4291 12.0001 15.4291C10.1099 15.4291 8.57209 13.8913 8.57209 12.0011Z" fill="white"/>
          </svg>
          <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4734 4.65002C19.7879 4.95943 19.7879 5.46108 19.4734 5.77049L5.66444 19.3526C5.34986 19.662 4.83984 19.662 4.52526 19.3526C4.21069 19.0432 4.21069 18.5415 4.52526 18.2321L18.3342 4.65002C18.6488 4.34062 19.1588 4.34062 19.4734 4.65002Z" fill="white"/>
            <path d="M17.6711 7.00013C16.0381 5.98747 14.1002 5.20968 11.9997 5.20968C6.10948 5.20968 1.49734 11.3261 1.30399 11.5863C1.12067 11.8334 1.12067 12.1685 1.30399 12.4155C1.43851 12.5967 3.7118 15.6123 7.06628 17.4307L8.12789 16.3866C5.55125 15.0957 3.55176 12.9086 2.79066 12.0006C3.90163 10.6722 7.65163 6.61478 11.9997 6.61478C13.6668 6.61478 15.2482 7.21407 16.6266 8.02743L17.6711 7.00013Z" fill="white"/>
            <path d="M19.0089 7.92527L17.9826 8.9347C19.5192 10.0843 20.6719 11.3608 21.2088 12.0015C20.0979 13.3298 16.3479 17.3873 11.9997 17.3873C11.2254 17.3873 10.4696 17.258 9.74395 17.038L8.61878 18.1447C9.67666 18.5438 10.8122 18.7924 11.9997 18.7924C17.89 18.7924 22.5019 12.6759 22.6955 12.4157C22.8791 12.1687 22.8791 11.8334 22.6955 11.5865C22.5909 11.4458 21.1971 9.59635 19.0089 7.92527Z" fill="white"/>
            <path d="M11.4554 15.3547C11.6327 15.3824 11.8145 15.3968 11.9997 15.3968C13.9032 15.3968 15.4519 13.8736 15.4519 12.0013C15.4519 11.8192 15.4373 11.6404 15.409 11.4659L11.4554 15.3547Z" fill="white"/>
            <path d="M14.7154 9.90724C14.0829 9.11534 13.1006 8.60579 11.9997 8.60579C10.0962 8.60579 8.54745 10.1291 8.54745 12.0013C8.54745 13.0842 9.0655 14.0503 9.87062 14.6725L11.0254 13.5367C10.5055 13.2159 10.1585 12.647 10.1585 12.0013C10.1585 11.0042 10.9859 10.1904 11.9997 10.1904C12.6562 10.1904 13.2346 10.5317 13.5607 11.043L14.7154 9.90724Z" fill="white"/>
          </svg>
        </div>


      </div>
    </div>
<!--    <checklist-item class="block-ch" v-for="(ch, k) in itemChLocal.array" :key="k" :ch="ch"></checklist-item>-->
      <div class="block-ch" v-for="(ch, k) in (getFilterChecklist(itemChLocal) ? getFilterChecklist(getShowCheckListFilter) : [])" :key="k" :class="{'all-text-gray': ch.is_hidden}">
        <div class="description" :class="{'task-checkbox': isDetailTask}">
          <div class="checkbox" v-on:click="ch.is_completed = !ch.is_completed" v-if="isDetailTask">
            <svg v-if="!ch.is_completed" class="no-complete" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" stroke="#BDCBD6"/>
            </svg>
            <svg v-if="ch.is_completed" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#094172"/>
              <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
            </svg>
          </div>
          <p class="fw500 fz14" :class="{'description-completed': ch.is_completed, 'text-dark-local' : !ch.is_completed}" v-if="ch">{{ch.description}}</p>
        </div>
        <div class="priority">
          <checklist-select-priority v-model="ch.priority"></checklist-select-priority>
        </div>
        <div class="date">
          <select-due-date
              :resetAllAfterSelectDueDate="true"
              :class="{'date-expired': getDateExpired(ch.due_date), 'date-expired-period': getDateExpiredPeriod(Array.isArray(ch.days_for_repeat) ? ch.days_for_repeat : [])}"
              :disabled="false"
              class="select-due-date"
              :text-no-select="''"
              :button-show-after-hover="true"
              :due_date_status="ch.due_date_status"
              :active-due-date="ch.due_date"
              :active-custom-days="getActiveCustomDays(ch.days_for_repeat)"
              :itemForChange="ch"
              :showSelectTime="true"
              :short-view="true"
          ></select-due-date>
        </div>
        <div class="show-hidden justify-center cursor-pointer" v-on:click="ch.is_hidden = !ch.is_hidden">
          <div class="add-title" :title-text="!ch.is_hidden ? 'Hide item' : 'Show item'">
            <svg v-if="ch.is_hidden" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.6204 11.5823C22.4282 11.3194 17.8487 5.14453 11.9998 5.14453C6.15093 5.14453 1.57119 11.3194 1.3792 11.5821C1.19717 11.8315 1.19717 12.1698 1.3792 12.4193C1.57119 12.6822 6.15093 18.8571 11.9998 18.8571C17.8487 18.8571 22.4282 12.6821 22.6204 12.4195C22.8027 12.1701 22.8027 11.8315 22.6204 11.5823ZM11.9998 17.4385C7.69149 17.4385 3.96002 13.3401 2.85543 12.0003C3.95859 10.6593 7.68225 6.56306 11.9998 6.56306C16.3079 6.56306 20.0392 10.6607 21.1442 12.0013C20.0411 13.3422 16.3174 17.4385 11.9998 17.4385Z" fill="#9AA3B0"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 10.1728C10.9934 10.1728 10.1718 10.9944 10.1718 12.0011C10.1718 13.0077 10.9934 13.8294 12.0001 13.8294C13.0067 13.8294 13.8283 13.0077 13.8283 12.0011C13.8283 10.9944 13.0067 10.1728 12.0001 10.1728ZM8.57209 12.0011C8.57209 10.1109 10.1099 8.57311 12.0001 8.57311C13.8902 8.57311 15.4281 10.1109 15.4281 12.0011C15.4281 13.8913 13.8902 15.4291 12.0001 15.4291C10.1099 15.4291 8.57209 13.8913 8.57209 12.0011Z" fill="#9AA3B0"/>
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4734 4.65002C19.7879 4.95943 19.7879 5.46108 19.4734 5.77049L5.66444 19.3526C5.34986 19.662 4.83984 19.662 4.52526 19.3526C4.21069 19.0432 4.21069 18.5415 4.52526 18.2321L18.3342 4.65002C18.6488 4.34062 19.1588 4.34062 19.4734 4.65002Z" fill="#9AA3B0"/>
              <path d="M17.6711 7.00013C16.0381 5.98747 14.1002 5.20968 11.9997 5.20968C6.10948 5.20968 1.49734 11.3261 1.30399 11.5863C1.12067 11.8334 1.12067 12.1685 1.30399 12.4155C1.43851 12.5967 3.7118 15.6123 7.06628 17.4307L8.12789 16.3866C5.55125 15.0957 3.55176 12.9086 2.79066 12.0006C3.90163 10.6722 7.65163 6.61478 11.9997 6.61478C13.6668 6.61478 15.2482 7.21407 16.6266 8.02743L17.6711 7.00013Z" fill="#9AA3B0"/>
              <path d="M19.0089 7.92527L17.9826 8.9347C19.5192 10.0843 20.6719 11.3608 21.2088 12.0015C20.0979 13.3298 16.3479 17.3873 11.9997 17.3873C11.2254 17.3873 10.4696 17.258 9.74395 17.038L8.61878 18.1447C9.67666 18.5438 10.8122 18.7924 11.9997 18.7924C17.89 18.7924 22.5019 12.6759 22.6955 12.4157C22.8791 12.1687 22.8791 11.8334 22.6955 11.5865C22.5909 11.4458 21.1971 9.59635 19.0089 7.92527Z" fill="#9AA3B0"/>
              <path d="M11.4554 15.3547C11.6327 15.3824 11.8145 15.3968 11.9997 15.3968C13.9032 15.3968 15.4519 13.8736 15.4519 12.0013C15.4519 11.8192 15.4373 11.6404 15.409 11.4659L11.4554 15.3547Z" fill="#9AA3B0"/>
              <path d="M14.7154 9.90724C14.0829 9.11534 13.1006 8.60579 11.9997 8.60579C10.0962 8.60579 8.54745 10.1291 8.54745 12.0013C8.54745 13.0842 9.0655 14.0503 9.87062 14.6725L11.0254 13.5367C10.5055 13.2159 10.1585 12.647 10.1585 12.0013C10.1585 11.0042 10.9859 10.1904 11.9997 10.1904C12.6562 10.1904 13.2346 10.5317 13.5607 11.043L14.7154 9.90724Z" fill="#9AA3B0"/>
            </svg>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import ChecklistSelectPriority from "@/components/checklist/checklist-select-priority";
import SelectDueDate from "@/components/tasks/selectDueDate/selectDueDate";
import moment from 'moment'
import {mapActions, mapGetters} from "vuex";
export default {
  name: "checklist-wrap-item",
  components: {SelectDueDate, ChecklistSelectPriority},
  props: {
    isDetailTask: Boolean,
    value: [Object, Array],
  },
  computed: {
    ...mapGetters(['getTaskInfo']),
    getShowCheckListFilter() {
      return this.hideAll ? this.itemChLocal.items.filter(a=> !a.is_hidden) : this.itemChLocal.items
    },
  },
  watch: {
    value: function () {
      let data = JSON.parse(JSON.stringify(this.value))
      this.itemChLocal = data
    },
    itemChLocal: {
      deep: true,
      handler() {
        if(this.isDetailTask) {
          if(!this.watchDisabled) {
            this.changeTaskCheckBox()
          }
        } else {
          this.$emit('change', JSON.parse(JSON.stringify(this.itemChLocal)))
        }
      }
    }
  },
  data() {
    return {
      itemChLocal: null,
      hideAll: true,
      watchDisabled: true,
      itemChLocalCopy: null,
    }
  },
  methods: {
    ...mapActions(['axiosChangeCheckboxInTask']),
    getFilterChecklist(items) {
      if(Array.isArray(items) === true) {
        return (items.filter(a=> !a.is_completed)).concat(items.filter(a=> a.is_completed))
      }
      return items
    },
    getDateExpired(date) {
      return moment(date).isBefore(new Date())
    },
    getDateExpiredPeriod(days) {
      let count = 0;
      days.forEach(day=> {
        if(day < Number(moment(new Date).format('DD'))) {
          count++;
        }
      })
      return count === days.length
    },
    changeTaskCheckBox() {
      let array = [];
      console.log('start change')
      this.itemChLocal.items.forEach((item)=> {
        console.log(item.days_for_repeat)
        let form = {
          task: this.getTaskInfo ? this.getTaskInfo.id : null,
          checklist_item: item.id,
          priority: item.priority ? item.priority.id : null,
          due_date: item.due_date ? item.due_date : null,
          days_for_repeat: Array.isArray(item.days_for_repeat) === true ? item.days_for_repeat : [],
          due_date_status: item.due_date_status ? item.due_date_status : null,
          is_hidden: item.is_hidden !== null ? item.is_hidden : false,
          is_completed: item.is_completed !== null ? item.is_completed : false,
        }
        array.push(form)
      })
      console.log(array)
      this.axiosChangeCheckboxInTask(array);

      this.itemChLocalCopy = JSON.parse(JSON.stringify(this.itemChLocal))
    },
    getActiveCustomDays(array) {
      if(Array.isArray(array) === true) {
        let dateCurrent = new Date();
        return  array.map(a=> {
          return new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), a)
        })
      }
      return [];
    },
  },

  mounted() {
    this.itemChLocal = JSON.parse(JSON.stringify(this.value))
    setTimeout(()=> {
      this.watchDisabled = false;
      this.itemChLocalCopy = JSON.parse(JSON.stringify(this.itemChLocal))
    }, 1000)
  }
}
</script>

<style scoped lang="scss">
  .block-list {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--color-blue-dark);
    box-sizing: border-box;
  }
  .title-block {
    border-radius: 8px 8px 0 0;
    background-color: var(--color-blue-dark);
    display: grid;
    grid-template-columns: 1fr 66px 113px 28px;
    grid-template-rows: 33px;
    &>*:not(:first-child) {
      border-left: 1px solid var(--color-border);
    }
    &>* {
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
  }
  .description {
    padding: 8px;
  }
  .title-block {
    .priority, .date {
      padding: 8px 4px;
    }
  }


  .block-ch {
    min-height: 33px;
    box-sizing: border-box;
    width: 100%;
    //border-radius: 8px 8px 0 0;
    background-color: var(--color-bgc-page-white);
    display: grid;
    grid-template-columns: 1fr 66px 113px 28px;
    grid-template-rows: max-content;
    &>*:not(:first-child) {
      border-left: 1px solid var(--color-border);
    }
    &>* {
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    &:nth-child(even) {
      background-color: var(--color-modal);
    }
    &:nth-child(odd) {
      background-color: var(--color-bgc-page);
    }
    &:last-child {
      border-radius: 0 0 9px 9px;
    }
    .show-hidden {
      &>* {
        display: none;
      }
    }
    &:hover {
      background-color: var(--color-card-border) !important;
      .show-hidden {
        &>* {
          display: block;
        }
      }
      .checkbox {
        .no-complete {
          rect {
            stroke: var(--color-blue-dark);
          }
        }
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-border);
    }
  }
  .description {
    padding: 8px;
  }
  .task-checkbox {
    position: relative;
    padding-left: 37px;
    display: flex;
    align-items: center;
    .checkbox {
      position: absolute;
      left: 11px;
    }
  }
  .priority {
    padding: 4px;
  }
  .select-due-date {
    /deep/ {
      .button-drop {
        overflow: hidden;
        width: 113px;
        //width: 100%;
        height: 33px;
        border: none !important;
        background: transparent !important;
        padding: 8px 4px !important;
        p, span {
          font-size: 0.8125rem !important;
          line-height: 1rem !important;
        }
        &>svg {
          display: none;
        }
      }
      //svg {
      //  display: none;
      //}
      .button-opacity {
        display: none !important;
      }
      &:hover {
        .button-opacity {
          display: flex !important;
        }
      }
      .dropdown-menu {
        width: 200px;
      }
    }
  }
  .all-text-gray {
    .description, .show-selected, .button-drop {
      a, p, span, button, input {
        color: var(--color-gray) !important
      }
    }

    /deep/ {
      .description, .show-selected, .button-drop {
        a, p, span, button, input {
          color: var(--color-gray) !important
        }
      }
    }
  }
  .text-dark-local {
    color: #4B4B4B;
  }
  /deep/ {
    .text-dark-local {
      color: #4B4B4B;
    }
    .btn {
      background-color: transparent !important;
    }
  }
.block-ch {
  /deep/ {
    .text-dark1 {
      color: #4B4B4B;
    }
  }
  &:hover {
    .text-dark-local {
      color: #2c2c2c;
    }
    /deep/ {
      .text-dark-local, .text-dark1 {
        color: #2c2c2c;
      }
    }
  }
}
  .dart-mode {
    .block-ch {
      .text-dark-local {
        color: var(--color-gray-light);
      }
      /deep/ {
        .text-dark-local, .text-dark1 {
          color: var(--color-gray-light) !important;
        }
      }
      /deep/ {
        .btn {
          background-color: transparent !important;
        }
      }
    }

    .block-ch {
      &:hover {
        .text-dark-local {
          color: #ffffff;
        }
        /deep/ {
          .text-dark-local, .text-dark1 {
            color: #ffffff !important;
          }
        }
      }
    }
  }
  .description-completed {
    color: #9AA3B0 !important;
  }
  .date-expired {
    /deep/ {
      .due-date {
        color: var(--color-red) !important;
      }
    }
  }
  .date-expired-period {
    /deep/ {
      .custom-date {
        color: var(--color-red) !important;
      }
      .circleNumber {
        border: 1px solid var(--color-red);
      }
    }
  }
</style>