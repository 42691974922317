<template>
  <div class="counterparties-add_bread counterparties-archive-bread">
    <router-link to="/counterparties" class="counterparties-add_bread__link">Counterparties</router-link>
    <svg class="counterparties-add_bread__separator" width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.29924 4.40429L1.18326 7.70661C0.916143 7.97847 0.483669 7.97801 0.216997 7.7052C-0.0494694 7.43242 -0.0487808 6.99055 0.218374 6.71847L2.84906 3.91032L0.218374 1.19306C-0.0487463 0.920949 -0.049435 0.479353 0.216997 0.206542C0.350677 0.0697846 0.525808 0.00140381 0.700939 0.00140381C0.875622 0.00140381 1.05006 0.0693274 1.1835 0.205135L4.29924 3.41637C4.42789 3.54708 4.50009 3.72497 4.50009 3.91032C4.50009 4.09566 4.42769 4.27334 4.29924 4.40429Z" fill="#9AA3B0"/></svg>
    
    <router-link :to="'/counterparties/'+client.id" v-if="client" class="counterparties-add_bread__link">{{client.company_name}}</router-link>
    <svg v-if="client" class="counterparties-add_bread__separator" width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.29924 4.40429L1.18326 7.70661C0.916143 7.97847 0.483669 7.97801 0.216997 7.7052C-0.0494694 7.43242 -0.0487808 6.99055 0.218374 6.71847L2.84906 3.91032L0.218374 1.19306C-0.0487463 0.920949 -0.049435 0.479353 0.216997 0.206542C0.350677 0.0697846 0.525808 0.00140381 0.700939 0.00140381C0.875622 0.00140381 1.05006 0.0693274 1.1835 0.205135L4.29924 3.41637C4.42789 3.54708 4.50009 3.72497 4.50009 3.91032C4.50009 4.09566 4.42769 4.27334 4.29924 4.40429Z" fill="#9AA3B0"/></svg>

    <span class="counterparties-add_bread__link" v-if="client">Archive Payments Accounts</span>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: String
    }
  },
  data() {
    return {
      client: null
    }
  },
  computed: {
    ...mapState({
      clients: state => state.counterparties.clients
    }),
    ...mapGetters(['getClientById'])
  },
  async mounted() {
    this.client = this.getClientById(this.$route.params.id)[0]
  }
}
</script>

<style lang="scss">

</style>