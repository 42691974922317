<template>
<!--  <button class="fs_export fz13 fw500">-->
<!--    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.0359 5C13.0359 4.6134 12.7225 4.3 12.3359 4.3C11.9493 4.3 11.6359 4.6134 11.6359 5H13.0359ZM12.3359 14L11.8418 14.4959C12.115 14.768 12.5568 14.768 12.83 14.4959L12.3359 14ZM16.83 10.5102C17.1039 10.2373 17.1047 9.79412 16.8318 9.52026C16.5589 9.2464 16.1157 9.24561 15.8418 9.51848L16.83 10.5102ZM8.83 9.51848C8.55614 9.24561 8.11292 9.2464 7.84005 9.52026C7.56717 9.79412 7.56797 10.2373 7.84183 10.5102L8.83 9.51848ZM19.7026 16C19.7026 15.6134 19.3892 15.3 19.0026 15.3C18.616 15.3 18.3026 15.6134 18.3026 16H19.7026ZM6.03593 16C6.03593 15.6134 5.72253 15.3 5.33593 15.3C4.94933 15.3 4.63593 15.6134 4.63593 16H6.03593ZM11.6359 5V14H13.0359V5H11.6359ZM12.83 14.4959L16.83 10.5102L15.8418 9.51848L11.8418 13.5041L12.83 14.4959ZM12.83 13.5041L8.83 9.51848L7.84183 10.5102L11.8418 14.4959L12.83 13.5041ZM17.0026 18.3H7.33593V19.7H17.0026V18.3ZM18.3026 16V17H19.7026V16H18.3026ZM6.03593 17V16H4.63593V17H6.03593ZM7.33593 18.3C6.61796 18.3 6.03593 17.718 6.03593 17H4.63593C4.63593 18.4912 5.84476 19.7 7.33593 19.7V18.3ZM17.0026 19.7C18.4938 19.7 19.7026 18.4912 19.7026 17H18.3026C18.3026 17.718 17.7206 18.3 17.0026 18.3V19.7Z" fill="#094172"/></svg>-->
<!--    Export all-->
<!--  </button>-->
  <payment-dropdown-export class="export" @eventExportXLSX="downloadXLSX" @eventExportPDF="downloadPDF"></payment-dropdown-export>
</template>

<script>
import PaymentDropdownExport from "@/components/payment/payment-dropdown-export";
export default {
  components: {PaymentDropdownExport},
  methods: {
    downloadXLSX() {
      this.$emit("downloadXLSX")
    },
    downloadPDF() {
      this.$emit("downloadPDF")
    }
  }
}
</script>

<style lang="scss">
  .fs_export{
    display: flex;
    align-items: center;
    color: #094172;
    svg{
      margin-right: 4px;
    }
  }
</style>