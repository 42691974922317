<template>
    <transition name="fade">
      <div class="wrap-content edit-department-modal" v-if="show">
        <div class="filter" v-on:click="hideMenu"></div>
        <div class="modal" v-if="modalShow">

          <div class="filter" v-on:click="hideModal"></div>
          <div class="modal-content">
            <button class="close-modal" v-on:click="hideModal">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
              </svg>
            </button>
            <p class="fz500 fz16 text-dark">Delete department</p>
            <p class="fz14 text-dark text2">Are you sure you want to delete the department <span class="fz14 fw500"> {{ nameForModal }}</span>? All employees assigned to this department will be moved to <span class="fz14 fw500"> Custom </span></p>
            <div>
              <button class="blue-button-text fz14" v-on:click="hideModal">Cancel</button>
              <button class="blue-button fz14 h32 buttonW100" v-on:click="deleteDepartments">Delete</button>
            </div>
          </div>
        </div>
        <div class="main-wrap show-menu" :class="{'show-menu': show}">
          <div class="dop-menu">
<!--            {{buttons}}-->
<!--            {{getPermissionsInDepartmentData}}-->
<!--            {{selected.selected}}-->
              <p class="title fz18 fw500 text-dark">Edit departments</p>
              <p class="fz13 text-dark">Select a department from the list and set up permissions and name for it</p>
            <div class="button-department" v-for="(dep, i) in buttons" :key="i" :class="{'button-active': dep.status}">
              <button class="text-dark1" v-on:click="changeDepartments(dep, dep.name, i)">
                <p>{{ dep.name }}</p>
              </button>
              <button class="delete" v-on:click="showModal(dep.name, dep.id)">
                <svg class="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.1333 6.32059V17.1435C17.1333 18.1688 16.2976 19 15.2667 19H8.73333C7.7024 19 6.86667 18.1688 6.86667 17.1435V6.32059M17.1333 6.32059H6.86667M17.1333 6.32059H19M6.86667 6.32059H5M9.66667 6.32059L9.66667 4.92824C9.66667 4.41559 10.0845 4 10.6 4L13.4 4C13.9155 4 14.3333 4.41559 14.3333 4.92824V6.32059M10.6 10.0335V15.1388M13.4 10.0335V15.1388" stroke="#4B4B4B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>

            <button class="close-menu" v-on:click="hideMenu">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
              </svg>
            </button>

          </div>
          <div class="content" :class="{'show-content-small-screen': showContent}">
<!--            {{buttons}}-->
<!--{{activeCheckbox}}-->

<!--            {{getPermissionsEditDepartment}}-->
            <!--        {{getResponseAddDepartments}}-->
            <div class="wrap-block">

              <button class="close-menu" v-on:click="hideMenu">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                  <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
                </svg>
              </button>
              <!--            <p class="title fz18 fw500 text-dark">Add new department</p>-->
              <!--          <p class="fz13 text-dark-gray">Select departments or individual employees</p>-->
              <button v-on:click="showContent = false" class="fw500 fz12 text-before-button button-close-content">
                <span>&lt;</span> Edit departments
              </button>
              <div class="block-input">
                <p class="fz12 text-gray">Name</p>
                <input type="text" class="h36 fz13" placeholder="Enter the name of the department" v-model="name">
              </div>
              <p class="fz12 title1 text-gray mt24">Permissions</p>
<!--                        {{selected}}-->
<!--              {{permissions}}-->
<!--<pre>-->
<!--  {{getPermissionsDepartmentsData}}-->
<!--</pre>-->
              <div class="checkbox-block" :key="keyRender">
                <checkboxes @showModalColumns="showModalColumns($event, i)" @openModalAccount="openModalAccount" @renderCheckbox="renderCheckbox" @changeSelected="changeSelected" :selected-props="activeCheckbox" ref="checkboxes" v-for="(perm, i) in permissions" :key="i" :checkboxes="perm"></checkboxes>
<!--              {{activeCheckbox}}-->
              </div>
            </div>
            <div class="bottom-panel" :class="{'show-button-small-screen': showContent}">
              <button class="blue-button" v-on:click="saveChange">Save</button>
            </div>
          </div>

        </div>
      </div>
    </transition>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Checkboxes from "@/components/addEmployeesStep2/checkboxes";

export default {
  components: {Checkboxes},
  computed: mapGetters(["getDepartments", "getPermissionsEditDepartment", "getShowEditDepartment", "getPermissionsDepartmentsData", "getPermissionsInDepartmentData"]),
  name: "editDepartment",
  data() {
    return {
      show: false,
      name: "",
      buttons: [],
      permissions: [],
      selected: [],
      keyRender: -99342,
      modalShow: false,
      nameForModal: "",
      idForDelete: "",
      idActiveButton: "",
      ID: null,
      activeCheckbox: null,
      permissionsCopy: null,
      showContent: false,
      firstMounted: true,
      countRequests: 0
    }
  },
  watch: {
    countRequests: function () {
      if(this.countRequests === 0) {
        this.$emit("renderNew");
      }
    },
  },
  methods: {
    ...mapActions(["axiosAddPermissionsNewDepartmentsList", "axiosGetDepartments", "axiosGetPermissions", "axiosDeleteDepartment", "axiosGetPermissionsDepartments",
      "axiosEditPermissionInDepartments", "axiosGetPermissionInDepartment", "axiosDeletePermissionInDepartments",
      "axiosAddPermissionsNewDepartments", "axiosChangeNameDepartment"]),
    ...mapMutations(["setAlert", "setShowEditDepartment", "clearActiveCheckboxInMenuEditDepartment"]),
    openModalAccount(data) {
      data.textModalOpen = 'editDepartment'
      this.$emit("openModalAccount", data);
    },
    showModalColumns(data, indexOne) {
      this.$emit("showModalColumns", {titleEdit: data.titleEdit, id: data.id, title2: indexOne, indexTwo: data.index, active: data.active, textModalOpen: 'editDepartment'});
    },
    showMenu() {
      this.show = true;
      this.permissions = [];
      this.buttons = [];
      // this.axiosGetDepartments().then(()=> {

        if(this.getDepartments.data.results.length !== 0) {
          for(let i=0; i<this.getDepartments.data.results.length; i++) {
            let obj = {
              name: "",
              id: "",
              status: false,
              permissions: [],
            }
            obj.name = this.getDepartments.data.results[i].name;
            obj.id = this.getDepartments.data.results[i].id;
            // if(this.getDepartments.data.results[i].permissions!==null && this.getDepartments.data.results[i].permissions !== undefined)
            //   for(let j=0; j<this.getDepartments.data.results[i].permissions.length; j++) {
            //   obj.permissions.push(this.getDepartments.data.results[i].permissions[j]);
            // }
            for (let j = 0; j < this.getPermissionsDepartmentsData.data.results.length; j++) {
              if(this.getPermissionsDepartmentsData.data.results[j].department === this.getDepartments.data.results[i].id) {
                obj.permissions.push(this.getPermissionsDepartmentsData.data.results[j].type);
              }
            }
            this.buttons.push(obj);
          }
          this.changeDepartments(this.buttons[0], this.buttons[0].name, 0);
          // console.log("button0")
          // console.log(this.buttons[0]);
          // this.$emit("changeDepartments", this.buttons[0].permissions);
        }
      // });
      // this.axiosGetPermissions().then(()=> {
      for(let i=0; i< this.getPermissionsEditDepartment.length; i++) {
        this.permissions.push(this.getPermissionsEditDepartment[i]);
      }
      // });
      this.setShowEditDepartment(true);

    },
    hideMenu() {
      this.show = false;
      this.setShowEditDepartment(false);
      this.clearActiveCheckboxInMenuEditDepartment();
      this.firstMounted = true;
      // activeCheckbox: null,
    },
    changeDepartments(activeCheckbox, name, index) {
      // console.log(activeCheckbox)
      this.showContent = false;
      this.activeCheckbox = activeCheckbox;
      this.name = name;
      for(let i=0 ; i<this.buttons.length; i++) {
        this.buttons[i].status = false;
      }
      this.idActiveButton = index;
      this.ID = this.buttons[index].id
      this.buttons[index].status = true;
      this.clearActiveCheckboxInMenuEditDepartment()
      // this.keyRender++;
      // if(window.innerWidth <= 768 && this.firstMounted===false) {
      //   this.showContent = true;
      // }
      this.firstMounted = false;
    },
    changeSelected(data) {
      if(this.selected.length !== 0) {
        let coincidences = 0;
        for(let i=0; i<this.selected.length; i++) {
          if(JSON.stringify(this.selected[i].title) === JSON.stringify(data.title)) {
            coincidences+=1;
            // this.selected[i].selected.slice(0,this.selected[i].selected.length);
            this.selected[i].selected = [];
            // // console.log(2);
            this.selected[i].selected = data.selected;
            // // console.log(3);
          }
        }
        // // console.log(coincidences)
        if(coincidences === 0) {
          this.selected.push(data);
        }
      } else {
        this.selected.push(data);
      }
      // } else {
      //   // console.log("a");
      //   for(let i=0; i<this.selected.length; i++) {
      //     if(this.selected[i].title === data.title) {
      //       this.selected[i].selected = [];
      //     }
      //   }
      // }
    },
    renderCheckbox() {
      this.keyRender++;
    },
    hideModal() {
      this.modalShow = false;
      this.nameForModal = "";
      this.idForDelete = "";
      this.idActiveButton = "";
    },
    showModal(name, id) {
      this.nameForModal = name;
      this.idForDelete = id;
      // console.log("ID");
      // console.log(id);
      this.modalShow = true;

    },
    deleteDepartments() {
      this.axiosDeleteDepartment(this.idForDelete).then(()=> {
        this.permissions = [];
        this.buttons = [];
        this.axiosGetDepartments().then(()=> {

          this.axiosGetPermissionsDepartments().then(()=> {
            if(this.getDepartments.data.results.length !== 0) {
              for(let i=0; i<this.getDepartments.data.results.length; i++) {
                let obj = {
                  name: "",
                  id: "",
                  status: false,
                  permissions: [],
                }
                obj.name = this.getDepartments.data.results[i].name;
                obj.id = this.getDepartments.data.results[i].id;
                // if(this.getDepartments.data.results[i].permissions!==null && this.getDepartments.data.results[i].permissions !== undefined)
                //   for(let j=0; j<this.getDepartments.data.results[i].permissions.length; j++) {
                //   obj.permissions.push(this.getDepartments.data.results[i].permissions[j]);
                // }
                for (let j = 0; j < this.getPermissionsDepartmentsData.data.results.length; j++) {
                  if(this.getPermissionsDepartmentsData.data.results[j].department === this.getDepartments.data.results[i].id) {
                    obj.permissions.push(this.getPermissionsDepartmentsData.data.results[j].type);
                  }
                }
                this.buttons.push(obj);
              }
              if(window.innerWidth > 768 && this.firstMounted===false) {
                this.changeDepartments(this.buttons[0], this.buttons[0].name, 0);
              }
              // console.log("button0")
              // console.log(this.buttons[0]);
              // this.$emit("changeDepartments", this.buttons[0].permissions);
            }

          })
        });
        this.axiosGetPermissions().then(()=> {
          for(let i=0; i< this.getPermissionsEditDepartment.length; i++) {
            this.permissions.push(this.getPermissionsEditDepartment[i]);
          }
        });
        this.hideModal();
        this.$emit("removeDepartment");

      });
    },
    saveChange() {
      let n = 0;
      for(let i=0; i<this.selected.length; i++) {
        if(this.selected[i].selected.length === 0) {
          n+=1;
        }
      }
      if(n===this.selected.length) {
        this.setAlert({show:true, text: "Select permissions for this department."});
      } else {
        let render = true
        if(render) {
          this.countRequests++;
        }
        console.log(this.activeCheckbox)
        console.log(this.selected)
        this.axiosGetPermissionInDepartment().then(()=> {
          for(let t=0; t<this.activeCheckbox.permissions.length; t++) {
            let c=0;

            for(let i=0; i<this.selected.length; i++) {
              for(let j=0; j<this.selected[i].selected.length; j++) {
                console.log('active - ' + this.activeCheckbox.permissions[t])
                console.log('select - ' +this.selected[i].selected[j].id)


                if(this.activeCheckbox.permissions[t] === this.selected[i].selected[j].id) {
                  c+=1;
                }
              }
            }
            if(c===0) {
              let PermissionsInDepartmentData = this.getPermissionsInDepartmentData;
              for(let f=0; f<PermissionsInDepartmentData.length; f++) {
                if (PermissionsInDepartmentData[f].department === this.ID && PermissionsInDepartmentData[f].type === this.activeCheckbox.permissions[t]) {
                  let IDPer = PermissionsInDepartmentData[f].id;
                  if(render) {
                    this.countRequests++;
                  }
                  this.axiosDeletePermissionInDepartments({IDPer: IDPer}).then(()=> {
                    if(render) {
                      this.countRequests--;
                    }
                  });
                }
              }
            }
          }
          for(let i=0; i<this.selected.length; i++) {
            for(let j=0; j<this.selected[i].selected.length; j++) {
              let IDPer = null;
              let PermissionsInDepartmentData = this.getPermissionsInDepartmentData;
              let coincidencesPermissions = 0;

              // let coincidencesD = 0;

                for(let y=0 ; y<this.activeCheckbox.permissions.length; y++) {
                  if(this.activeCheckbox.permissions[y] === this.selected[i].selected[j].id) {
                    for(let f=0; f<PermissionsInDepartmentData.length; f++) {
                      if (PermissionsInDepartmentData[f].department === this.ID && PermissionsInDepartmentData[f].type === this.selected[i].selected[j].id) {
                        let countChange = 0;
                        let array = PermissionsInDepartmentData[f].such_columns !== null ? PermissionsInDepartmentData[f].such_columns : []
                          this.selected[i].selected[j].activeCheckboxInMenu.columns.map(a=> {
                            if(array.indexOf(a)=== -1) {
                              countChange++
                            }
                          })

                        this.selected[i].selected[j].activeCheckboxInMenu.account.map(a=> {
                          if(PermissionsInDepartmentData[f].related_account.indexOf(a.id)=== -1) {
                            countChange++
                          }
                        })
                        this.selected[i].selected[j].activeCheckboxInMenu.departments.map(a=> {
                          if(PermissionsInDepartmentData[f].related_department.indexOf(a.id)=== -1) {
                            countChange++
                          }
                        })
                        this.selected[i].selected[j].activeCheckboxInMenu.employees.map(a=> {
                          if(PermissionsInDepartmentData[f].related_staff.indexOf(a.id)=== -1) {
                            countChange++
                          }
                        })
                        if(countChange !== 0) {
                          coincidencesPermissions+=1;
                          console.log("info")
                          console.log(PermissionsInDepartmentData[f])
                          console.log(this.selected[i].selected[j].activeCheckboxInMenu)
                          IDPer = PermissionsInDepartmentData[f].id;
                        }

                        // f = PermissionsInDepartmentData.length;
                      }
                    }
                  }
                }
                if(coincidencesPermissions > 0) {
                  let related_staff = [];
                  let related_department = [];
                  // console.log(this.selected[i].selected[j].activeCheckboxInMenu)
                  for(let e=0; e<this.selected[i].selected[j].activeCheckboxInMenu.employees.length; e++) {
                    related_staff.push(this.selected[i].selected[j].activeCheckboxInMenu.employees[e].id);
                  }
                  for(let e=0; e<this.selected[i].selected[j].activeCheckboxInMenu.departments.length; e++) {
                    related_department.push(this.selected[i].selected[j].activeCheckboxInMenu.departments[e].id);
                  }
                  // console.log("axiosEditPermissionInDepartments");
                  let related_account = [];
                  if(this.selected[i].selected[j].button2Show === true) {
                    if(Array.isArray(this.selected[i].selected[j].activeCheckboxInMenu.account)) {
                      related_account = this.selected[i].selected[j].activeCheckboxInMenu.account.map(a=> {
                        return a.id
                      })
                    }
                  }
                  // if(this.selected[i].selected[j].id === 190) {
                  //   console.log(this.selected[i].selected[j])
                  // }
                  let such_columns = this.selected[i].selected[j].activeCheckboxInMenu.columns !== null ? this.selected[i].selected[j].activeCheckboxInMenu.columns : []
                  if(render) {
                    this.countRequests++;
                  }
                  this.axiosEditPermissionInDepartments({type: this.selected[i].selected[j].id, department: this.ID ,related_staff: related_staff,related_department: related_department, such_columns: such_columns, related_account:related_account, IDPer: IDPer, methods: "path"}).then(()=> {
                    if(render) {
                      this.countRequests--;
                    }
                    // console.log("axiosEditPermissionInDepartments111");
                  });
                  // console.log(this.selected[i].selected[j]);
                }
            }
            // this.$emit("editDepartment");
          }

          console.log("delete permissions")
          console.log(this.selected)
          let ArrayAdd = [];
          for(let i=0; i<this.selected.length; i++) {
            for(let j=0; j<this.selected[i].selected.length; j++) {
              let m=0;
              for(let t=0; t<this.activeCheckbox.permissions.length; t++) {
                if(this.activeCheckbox.permissions[t] === this.selected[i].selected[j].id) {
                  m+=1;
                }
              }
              if(m===0) {
                let related_staff = [];
                let related_department = [];
                // console.log(this.selected[i].selected[j].activeCheckboxInMenu)
                for(let e=0; e<this.selected[i].selected[j].activeCheckboxInMenu.employees.length; e++) {
                  related_staff.push(this.selected[i].selected[j].activeCheckboxInMenu.employees[e].id);
                }
                for(let e=0; e<this.selected[i].selected[j].activeCheckboxInMenu.departments.length; e++) {
                  related_department.push(this.selected[i].selected[j].activeCheckboxInMenu.departments[e].id);
                }
                let related_account = [];
                if(this.selected[i].selected[j].button2Show === true) {
                  if(Array.isArray(this.selected[i].selected[j].activeCheckboxInMenu.account)) {
                    related_account = this.selected[i].selected[j].activeCheckboxInMenu.account.map(a=> {
                      return a.id
                    })
                  }
                }
                let such_columns = this.selected[i].selected[j].activeCheckboxInMenu.columns !== null ? this.selected[i].selected[j].activeCheckboxInMenu.columns : []

                // console.log("axio6666sEditP6666666ermissionInDepartment56566666s");
                // console.log({type: this.selected[i].selected[j].id, department: this.ID ,related_staff: related_staff,related_department: related_department});
                // if(render) {
                //   this.countRequests++;
                // }
                ArrayAdd.push({type: this.selected[i].selected[j].id, such_columns: such_columns, related_account: related_account, department: this.ID ,related_staff: related_staff,related_department: related_department})
                // this.axiosAddPermissionsNewDepartments({type: this.selected[i].selected[j].id, department: this.ID ,related_staff: related_staff,related_department: related_department}).then(()=> {
                //   if(render) {
                //     this.countRequests--;
                //   }
                // })

              }
            }
          }
          if(render) {
            this.countRequests++;
          }
          this.axiosAddPermissionsNewDepartmentsList(ArrayAdd).then(()=> {
            if(render) {
              this.countRequests--;
            }
          })

          if(this.name.length !== 0 && this.name !== this.activeCheckbox.name) {
            if(render) {
              this.countRequests++;
            }
            this.axiosChangeNameDepartment({name: this.name, id: this.ID}).then(()=> {
              if(render) {
                this.countRequests--;
              }
              this.$emit("editDepartment");
              this.hideMenu();
            });
          }


                    // this.axiosDeletePermissionInDepartments({IDPer: PermissionsInDepartmentData[f].id});

          // this.permissions = [];
          // this.axiosGetPermissions().then(()=> {
          //   for(let i=0; i< this.getPermissionsEditDepartment.length; i++) {
          //     this.permissions.push(this.getPermissionsEditDepartment[i]);
          //   }
          // });
          this.$emit("editDepartment");
          this.hideMenu();
          // this.$emit("removeDepartment");
        })









        // this.changeDepartments(this.buttons[0], this.buttons[0].name, 0);
        // // console.log("button0")
        // // console.log(this.buttons[0])
      }


    }

  },
  mounted() {
    window.resize(function() {
      if(window.innerWidth > 768) {
        this.showContent = false;
      }
    });
  }
}
</script>

<style scoped lang="scss">
.title1 {
  margin-top: 25px;
}

.wrap-button-department {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  .delete {

  }
}

.dart-mode {
  svg {
    path {
      stroke: var(--color-card);
    }
  }
}

.button-department {
  &>button:not(.delete) {
    padding-left: 56px;
    padding-right: 29px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
  }
  .delete {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .1s linear;
    transform: translateX(17px);
    svg path {
      transition: .1s linear;
    }
    &:hover {
      transform: scale(1.1) !important;
      svg {
        path {
          stroke: #EB5757;
        }
      }
    }
    @media (max-width: 1280px) {
      transform: none;
      opacity: 1;
      right: 12px;
    }
  }

  p {
    transition: .1s linear;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  height: 32px;
  transition: .1s linear;
  position: relative;
  @media (min-width: 768px) {
    &:hover {
      background-color: #DEE3EA;
      .delete {
        //display: flex;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media (max-width: 768px) {
    &:active {
      background-color: #DEE3EA;
      .delete {
        //display: flex;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &::after {
    cursor: pointer;
    content: " ";
    position: absolute;
    right: 0px;
    opacity: 1;
    transform: translateX(0);
    border: 17px solid transparent; border-left: 8px solid transparent;
    transition: .1s linear;
  }
  @media (max-width: 768px) {
    margin-bottom: 12px;
    &>button:not(.delete) {
      padding: 0 15px;
    }
    .delete {
      padding: 3px 0px 3px 3px;
    }
  }
}

.button-active {
  @media (min-width: 768px) {
    p {
      color: #319FEF;
    }
    &::after {
      //content: " ";
      //position: absolute;
      right: -25px;
      opacity: 1;
      transform: translateX(0);
      border: 17px solid transparent; border-left: 8px solid var(--color-input);
      //transition: .1s linear;
    }
    &:hover {
      &:after {
        border: 17px solid transparent; border-left: 8px solid #DEE3EA;
      }
    }
  }
}

.block-input {
  //margin-top: 20px;
  width: 100%;
  p {
    margin-bottom: 4px;
  }
  input {
    height: 36px !important;
    width: 100% !important;
  }
}
/deep/ p {
  margin-bottom: 0;
}
.bottom-panel {
  height: 44px;
  width: 100%;
  padding: 4px 56px;
  background: var(--color-modal);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  position: fixed;
  z-index: 50;
  left: 0;
  bottom: 0;
  //right: 56px;
  .blue-button {
    width: 349px;
    height: 100%;
    box-sizing: border-box;
    @media (max-width: 1280px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      width: 93.75vw;
    }
  }
  @media (max-width: 1280px) {
    padding: 4px 36px 4px 306px;
  }
  @media (max-width: 768px) {
    display: none;
    justify-content: center;
    padding: 4px 0;
  }
}
.checkboxes-block {
  @media (min-width: 768px) {
    padding-bottom: 44px;
  }
  position: relative;
  //z-index: 100;
  &>* {
    padding: 16px 0;
    border-top: 1px solid #DEE3EA;
  }
  &>*:first-child {
    border-top: none !important;
  }
  padding-bottom: 24px;
}
.search-block {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  width: 100% !important;
  svg {
    position: absolute;
    left: 10px;
  }
  input {
    width: 100% !important;
    height: 32px !important;
    padding-left: 42px;

  }

  margin-bottom: 21px;
}
.wrap-content {
  width: 100vw;
  height: 100%;
  z-index: 300;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  body {
    overflow: hidden;
  }
}
.filter {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  right: 0;
  top: 0;
  opacity: 0.15;
  background-color: var(--color-filter-modal);
}
.dart-mode {
  .filter {
    opacity: 0.75;
  }
}

.main-wrap {
  width: 740px;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 300;
  transform: translateX(100%);
  transition: transform 0.3s linear;
  .close-menu {
    position: absolute;
    left: 20px;
    top: 17px;
    @media (max-width: 1280px) {
      left: auto;
      right: 16px;
      top: 16px;
    }
  }
  .wrap-block {
    padding: 53px 56px 50px 56px;
    min-height: calc(100% - 44px);
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    @media (max-width: 1280px) {
      padding: 16px 48px 50px 36px;
    }
    @media (max-width: 768px) {
      position: relative;
      padding: 13px 15px;
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
}
.title {
  padding-top: 16px;
  margin-bottom: 17px;
}
.content {
  width: 480px;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-modal);
  //position: absolute;
  //top: 0;
  //right: 0;

  &>* {
    //padding-right: 56px;
  }
  @media (max-width: 1280px) {
    width: calc(100% - 270px);
  }
  @media (max-width: 768px) {
    display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    //padding: 13px 15px;
  }
}
.show-content-small-screen {
  @media (max-width: 768px) {
    display: block;
  }
}
.show-button-small-screen {
  @media (max-width: 768px) {
    display: flex;
  }
}
.show-menu {
  transform: translateX(0);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  .main-wrap {
    transform: translateX(100%);
  }
}
.blue-button-text {
  display: inline;
}
.border-block {
  width: 100%;
  height: 100%;
  padding: 16px 20px;
}

.dop-menu {
  .close-menu {
    display: none;
  }
  width: 290px;
  height: 100%;
  background-color: var(--color-bgc-page);
  max-height: calc(100% - 44px);
  &>*:not(.button-department) {
    padding-left: 56px;
    padding-right: 29px;
    @media (max-width: 1280px) {
      padding-left: 36px;
    }
    @media (max-width: 768px) {
      padding: 0 15px;
    }
  }
  &>.button-department:first-of-type {
    margin-top: 21px;
    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
  @media (max-width: 1280px) {
    width: 270px;
    .title {
      padding-top: 16px;
    }
  }
  @media (max-width: 768px) {
    background-color: var(--color-card);
    width: 100%;
    max-height: 100%;
    height: 100%;
    .close-menu {
      left: auto;
      right: 16px;
      padding: 0;
      top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.checkbox-block {
  width: 100%;
  box-sizing: border-box;
  &>* {
    padding: 16px 0;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }
  &>*:not(:last-child) {
    border-bottom: 1px solid #DEE3EA;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 400;
  .modal-content {
    padding: 12px 16px;
    box-sizing: border-box;
    width: 424px;
    height: 155px;
    background: var(--color-modal);
    border-radius: 8px;
    position: relative;
    z-index: 300;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-left: 33px;
      }
    }
    .text2 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .close-modal {
      position: absolute;
      top: 13px;
      right: 16px;
    }
    @media (max-width: 768px) {
      width: 93.75vw;
      height: max-content;
    }
  }
}

.button-close-content {
  @media (min-width: 768px) {
    display: none;
  }
  span {
    margin-right: 4px;
  }

  margin-bottom: 21px;
}


</style>