<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">
          <span v-if="data">Edit Financial Statement</span>
          <span v-else>Create New  Financial Statement</span>
          <button class="modal-close" @click="$emit('close')"></button>
        </p>
        <label class="block-input" :class="{ 'block-input_error': $v.name.$error || ifNameExists }">
          <p class="block-input_title text-gray">FS Name</p>
          <input class="h36" :class="{
            'border-red': $v.name.$error || ifNameExists
          }" type="text" maxlength="255" v-model.trim="$v.name.$model" @input="()=>{this.ifIdExists = false; this.name=name.toUpperCase()}">
          <div class="fz12 text-red m-t-1" v-if="ifNameExists">FS with this name already exists.</div>
          <div class="fz12 text-red m-t-1" v-if="!$v.name.required && $v.name.$dirty">Field is required</div>
          <div class="fz12 text-red m-t-1" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div>
          <div class="fz12 text-red m-t-1" v-if="!$v.name.maxLength">Name must have at max {{$v.name.$params.maxLength.max}} letters.</div>
        </label>

        <counterparties-drop 
          ref="drop"
          :list="fsType" 
          :activeItem="activeType"
          @select="selectedType" 
          title="Type"
          class="uppercase-list"
        ></counterparties-drop>

<!--        <counterparties-drop -->
<!--          v-show="selectType.type == 'operating_expenses'"-->
<!--          ref="drop"-->
<!--          :list="fsSubtype" -->
<!--          :activeItem="activeSubtype"-->
<!--          @select="selectedSubtype" -->
<!--          title="Subtype"-->
<!--        ></counterparties-drop>-->

      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="$emit('close')">Cancel</button>
        <button class="btn btn-next" @click="onSubmit" v-if="data">Save</button>
        <button class="btn btn-next" @click="onSubmit" v-else>Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop';
export default {
  mixins: [validationMixin],
  props: ['data'],
  components: {
    counterpartiesDrop
  },
  data() {
    return {
      name: '',
      selectType: '',
      selectSubtype: '',
      activeType: null,
      activeSubtype: null,
      ifNameExists: false
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(2)
    }
  },
  computed: {
    ...mapState({
      fsType: state => state.trialBalance.fsType,
      fsSubtype: state => state.trialBalance.fsSubtype
    }),
    getFSinObj() {
      let obj = {
        name: this.name,
        type: this.selectType.type
      }
      if(this.selectType.type == 'operating_expenses')
        obj.subtype = this.selectSubtype.type

      return obj
    }
  },
  methods: {
    ...mapActions(['axiosAddFS', 'axiosEditFS', 'axiosGetsetFinancialStatementlist']),
    ...mapMutations(['showLoad', 'hideLoad']),
    async onSubmit() {
      this.showLoad()
      let response
      if(this.data) {
        response = await this.axiosEditFS({
          id: this.data.id,
          name: this.name,
          type: this.selectType.type
        })
      }else{
        response = await this.axiosAddFS(this.getFSinObj)
      }
      if(response){
        this.axiosGetsetFinancialStatementlist()
        this.$emit('close')
      }else{
        this.ifNameExists = true
      }

      this.hideLoad()
    },
    selectedType(item) {
      this.selectType = item
    },
    selectedSubtype(item) {
      this.selectSubtype = item
    },
  },
  mounted() {
    if(this.data) {
      this.name = this.data.name
      this.activeType = this.fsType.filter(el => {
        return el.type == this.data.type
      })[0]
      if(this.data.subtype){
        this.activeSubtype = this.fsSubtype.filter(el => {
          return el.type == this.data.subtype
        })[0]
      }else{
        this.activeSubtype = this.fsSubtype[0]
      }
    }else{
      this.activeType = this.fsType[0]
      this.activeSubtype = this.fsSubtype[0]
    }
  }
}
</script>

<style lang="scss" scoped>
  .uppercase-list {
    /deep/ {
      .button-text {
        text-transform: uppercase;
      }
      .dropdown-menu {
        a {
          text-transform: uppercase;
        }
      }
    }
  }
</style>