<template>
  <div class="wrap">
    <div class="block-button-dropdown">
      <b-form-checkbox-group
          @change="selectAll"
          v-model="status1"
      >
        <b-form-checkbox
            name="status"
            class="title"
        >
          <p class="fz14 fw500 text-dark1">{{ checkboxes.title }}</p>
        </b-form-checkbox>
      </b-form-checkbox-group>
      <button class="button-dropdown" v-on:click="hide=!hide">
        <svg :class="{'rotateX180':hide}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
<!--    {{selected}}-->
<!--    {{checkboxes}}-->
      <div class="content" :class="{'hide-content': hide}">
        <b-form-group>
          <b-form-checkbox
              @change="changeCheck"
              v-for="(ch, i) in checkboxes.details"
              v-model="selected"
              :key="i"
              :value="ch"
              name="flavour-3a"
              class="check"
          >
            <p class="fz14 text-dark-gray" v-if="ch.name!==null && ch.name !== undefined">{{ ch.name }}</p>
            <p class="fz14 text-dark-gray" v-if="ch.first_name!==null && ch.first_name !== undefined">{{ ch.first_name }}  {{ch.last_name}}</p>
          </b-form-checkbox>
        </b-form-group>
      </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props: ['checkboxes', "selec"],
  computed: mapGetters(["getSelected1MenuCheckbox", "getSelected2MenuCheckbox"]),
  name: "checkbox-dropdown-in-modal",
  data() {
    return {
      selected: [],
      status:false,
      hide: false,
      status1: [],
    }
  },
  watch: {
    // selec: function () {
    //   this.selected = this.selec;
    // }
  },
  methods: {
    // consSR() {
    //   // console.log("WORK")
    // },
    setSel() {
      // // console.log("Sel1");
      this.selected = this.selec;
      this.changeCheck();
    },
    // setSel2() {
    //   // console.log("Sel2");
    //   this.selected = this.getSelected1MenuCheckbox.employees;
    // },
    // setSel3() {
    //   this.selected = this.getSelected2MenuCheckbox.departments;
    // },
    // setSel4() {
    //   this.selected = this.getSelected2MenuCheckbox.employees;
    // },
    changeCheck() {
      if(this.checkboxes.details.length === this.checkboxes.firstCount) {
        if(this.selected.length === this.checkboxes.details.length) {
          this.status1 = ["true"];
          this.status = true;
        } else {
          this.status1 = [];
          this.status = false;
        }
      }
      this.$emit("changeCheckbox", {title: this.checkboxes.title, selected: this.selected});
    },
    selectAll() {
      if(this.status === true) {
        this.status = false;
        this.selected.splice(0,this.selected.length);
      } else {
        this.status = true;
        this.selected.splice(0,this.selected.length);
        for(let i=0; i<this.checkboxes.details.length; i++) {
          this.selected.push(this.checkboxes.details[i]);
        }
      }
      this.changeCheck()
      this.$emit("changeCheckbox", {title: this.checkboxes.title, selected: this.selected});
    },
    // changeSelected() {
    //   this.$emit("changeCheckbox", this.selected);
    // },
  },
  mounted() {
    this.selected = this.selec;
    this.changeCheck();
  }
}
</script>

<style scoped lang="scss">
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/dropdown";
  @import "./src/components/style-element/checkbox";
  .wrap {
    .check {
      margin-top: 10px;
      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }
    .content {
      overflow: hidden;
      max-height: 10000px;
      height: max-content;
      transition: 0.6s linear;
    }
    .hide-content {
      max-height: 1px;
      //height: 1px;
    }
  }
  p {
    margin-bottom: 0 !important;
  }
  .block-button-dropdown {
    display: flex;
    align-items: center;
    position: relative;
    .button-dropdown {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      svg {
        transition: .3s linear;
      }
    }
  }
</style>