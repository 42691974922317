<template>
    <div>
        <b-dropdown left ref="dropdown">
            <template #button-content>
                <button class="button-drop fz13 fw500 text-dark1">
                  {{ nameActiveButton }}
                    <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2C2C2C"/>
                    </svg>
                </button>
            </template>
            <div class="content" :key="keyRender">
                <button v-on:click="$refs.dropdown.hide(true)" class="title fw500 text-gray">
                    Departments
<!--                  {{buttons}}-->
                </button>
                <button v-for="(b, i) in buttons" :key="i" :class="{'active-button': b.status}" v-on:click="changeActiveButton(i)" class="fz13 text-dark">
                    {{b.name}}
                </button>
            </div>
        </b-dropdown>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
      computed: mapGetters(["getDepartmentsForEmployees", "getButtonsDepartments"]),
        name: "all-department",
      props: ["buttons1"],
        data() {
            return {
                buttons: [
                //     {name: "All", status: true},
                //     {name: "Superadmin", status: false},
                //     {name: "Freighter", status: false},
                //     {name: "Accountant", status: false},
                //     {name: "OPS", status: false},
                //     {name: "Custom", status: false},
                ],
              indexActive: 0,
              keyRender: 0,
              nameActiveButton: "",
            }
        },
        methods: {
        ...mapActions(["getAxiosDepartmentsEmployeesPage"]),
          ...mapMutations(["sortEmployeesByDepartmentFilter"]),
            changeActiveButton(index) {
              if(this.indexActive !== index) {
                this.indexActive = index;
                this.$refs.dropdown.hide(true);
                for(let i=0; i<this.buttons.length; i++) {
                  this.buttons[i].status = false;
                }
                this.buttons[index].status = true;
                this.nameActiveButton = this.buttons[index].name;
                this.sortEmployeesByDepartmentFilter(this.buttons[index].name);
                this.keyRender++;
                this.$emit("changeButton", index);
              } else {
                this.$refs.dropdown.hide(true);
              }
          },
          refresh() {
            this.$refs.dropdown.hide(true);
            for(let i=0; i<this.buttons.length; i++) {
              this.buttons[i].status = false;
            }
            this.buttons[0].status = true;
            this.nameActiveButton = this.buttons[0].name;
          },
          setButtonDep(index) {
            this.indexActive = index;
            for(let i=0; i<this.buttons.length; i++) {
              this.buttons[i].status = false;
            }
            this.buttons[index].status = true;
            this.nameActiveButton = this.buttons[index].name;
          }

        },
        mounted() {
          if(this.getDepartmentsForEmployees === null) {
            this.getAxiosDepartmentsEmployeesPage().then(()=> {
              for(let i=0; i<this.getButtonsDepartments.length; i++) {
                this.buttons.push(this.getButtonsDepartments[i]);
                this.nameActiveButton = this.buttons[0].name;
              }
            })
          } else {
            for(let i=0; i<this.getButtonsDepartments.length; i++) {
              this.buttons.push(this.getButtonsDepartments[i]);
              this.nameActiveButton = this.buttons[0].name;
            }
          }
        }
    }
</script>

<style scoped lang="scss">
    @import "../style-element/dropdown";
    .active-button {
        background-image: url("../../assets/ic_chsdeck.svg");
        background-position-x: calc(100% - 4px);
        background-position-y: center;
        background-repeat: no-repeat;
    }
    .svg-1 {
        margin-right: 8px;
    }
    .arrow {
        margin-left: 4px;
    }
    /deep/ .show {
    .arrow {
        transform: rotateX(180deg);
    }
    }
    /deep/ .dropdown-menu {
               top: -29px !important;
               border-radius: 4px;
               border: none;
               box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
               padding: 4px 0 !important;
           }
    .content {
        width: 160px;
        background-color: var(--color-modal);
        border-radius: 4px;
    .title {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 4px 12px 5px 12px;
        display: flex;
        align-items: center;
    svg {
        position: relative;
        top: 2px;
        margin-left: 4px;
    }
    transition: .1s linear;
    &:hover {
         color: #2C2C2C !important;
     }
    }
    button:not(:first-child) {
        height: 28px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 12px;
        box-sizing: border-box;
        transition: background-color .1s linear;
    &:hover {
         background-color: #DEE3EA;
     }
    }
    }
    .button-drop {
      //min-width: 100px;
      //display: flex;
      //justify-content: flex-start;
      //align-items: center;
    }
</style>
