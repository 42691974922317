<template>
  <div class="flex overflow-hidden h-full">
    <div class="w-1/6 flex-shrink-0 overflow-y-auto">
      <div class="min-h-full bg-white border-r border-gray-light">
        <aside-ports 
          :countryList="portsCountry" 
          :active="activeCountry" 
          :ifLoaded="ifLoaded"
          :nextRequest="nextRequest"
          :filters="filters"
          @on-select="onSelectCountry"
          @on-filters="isFilters = true"
          @on-load-next="onLoadNext"
          @on-add="onAdd"
        />
      </div>
    </div>
    <div class="flex flex-col w-full p-x-6 p-b-6 overflow-y-auto">
      <list-top :active="activeCountry" v-model.lazy="localSearch"/>
      <list-ports
          :loadListLoader="loadListLoader"
          @load-next="showNext"
          :loadActive="loadActiveNext"
          :next="nextLintForPortsList"
        ref="list"
        :ports="ports"
        @on-select="(arr)=>selectedPorts=arr"
        @on-edit="onEdit"
        @on-dupl="onDupl"
        @on-archive="onArchive"
      />
<!--      :localSearch="localSearch"-->
    </div>

    <filters-ports 
      v-if="isFilters" 
      :filters="filters" 
      @on-close="isFilters = false"
    />

    <bottom-ports 
      v-if="selectedPorts.length" 
      :ports="ports" 
      :selected="selectedPorts"
      @on-unselect="$refs.list.uncheckAll()"
      @on-archive="onArchive(selectedPorts)"
    />

    <add-modal 
      ref="add_modal"
      :account="editPort"
      :isDupl="isDupl"
      :isShowModal="isShowModal"
      @on-close="isShowModal = false"
    />

    <archive-modal 
      :isShowModal="isShowArchiveModal"
      :ports="archivedPorts"
      @on-close="onCloseModal"
      @on-send="sendUnarchive"
    ></archive-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import asidePorts from '@/components/ports/list/aside'
import listTop from '@/components/ports/list/listTop'
import listPorts from '@/components/ports/list/list'
import filtersPorts from '@/components/ports/list/filters'
import bottomPorts from '@/components/ports/list/bottom'
import addModal from '@/components/ports/list/add'
import archiveModal from '@/components/ports/archive/modal'

export default {
  components:{
    asidePorts,
    listTop,
    listPorts,
    filtersPorts,
    bottomPorts,
    addModal,
    archiveModal
  },
  data() {
    return {
      loadListLoader: false,
      activeCountry: '',
      ifLoaded: false,
      selectedPorts: [],
      localSearch: '',
      isFilters: false,
      isShowModal: false,
      editPort: null,
      isDupl: false,
      isShowArchiveModal: false,
      archivedPorts: null,
      loadActiveNext: false,
    }
  },
  watch: {
    localSearch: function () {
      this.loadPorts()
    },
    filters: {
      deep: true,
      async handler() {
        await this.axiosGetPortsByCountry()
        this.onSelectCountry(Object.keys(this.portsCountry)[0])
      }
    }
  },
  computed: {
    ...mapState({
      nextLintForPortsList: state => state.ports.nextLintForPortsList,
      ports: state => state.ports.ports,
      portsCountry: state => state.ports.portsCountry,
      nextRequest: state => state.ports.nextRequest,
      filters: state => state.ports.filters,
    }),
    /* countryList() {
      if(!this.ports) return []
      let result = []
      this.ports.forEach(el => {
        if(el.country){
          if(result.indexOf(el.country) == -1)
            result.push(el.country)
        }
      })

      return result
    }, */
    /* getPortsByActive() {
      if(!this.ports) return []
      return this.ports.filter(el => {
        return el.country == this.activeCountry
      })
    } */
  },
  methods: {
    ...mapActions(['axiosGetPortsByCountry', 'axiosGetPortsList', 'axiosPortArchive', 'axiosShowNextPortsList']),
    ...mapMutations(['addTip', 'showLoad', 'hideLoad']),
    async showNext() {
      this.loadActiveNext = true
      await this.axiosShowNextPortsList({next: this.nextLintForPortsList, is_archive: false})
      this.loadActiveNext = false

    },
    async loadPorts() {
      this.loadListLoader = true
      await this.axiosGetPortsList({search: this.localSearch, ports: this.portsCountry[this.activeCountry]})
      this.loadListLoader = false
    },
    async onSelectCountry(country) {
      // this.showLoad()
      this.loadListLoader = true
      this.activeCountry = country
      let ports = this.portsCountry[country]
      await this.axiosGetPortsList({search: this.localSearch, ports: ports})
      // this.hideLoad()
      this.loadListLoader = false
    },
    async onLoadNext() {
      this.ifLoaded = true
      await this.axiosGetPortsByCountry(true)
      this.ifLoaded = false
    },
    onAdd() {
      this.editPort = null
      this.isDupl = false
      this.isShowModal = true
    },
    onEdit(port) {
      this.editPort = port
      this.isDupl = false
      this.isShowModal = true
    },
    onDupl(port) {
      this.editPort = port
      this.isDupl = true
      this.isShowModal = true
    },
    onArchive(ports) {
      this.archivedPorts = ports
      this.isShowArchiveModal = true
    },
    onCloseModal() {
      this.archivedPorts = null
      this.isShowArchiveModal = false
    },
    async sendUnarchive() {
      let str = ''
      let obj = {
        ids: null,
        is_archived: true
      }
      if(Array.isArray(this.archivedPorts)){
        obj.ids = this.archivedPorts
        str = this.archivedPorts.length + (this.archivedPorts.length == 1 ? ' port is':' ports are') +' archived'
      }else{
        obj.ids = [this.archivedPorts.id]
        str = this.archivedPorts.name + ` is archived`
      }
      
      await this.axiosPortArchive(obj)
      this.addTip(str)
      this.onCloseModal()

      this.portsCountry[this.activeCountry] = this.portsCountry[this.activeCountry].filter(el=>obj.ids.indexOf(el)==-1)
      await this.axiosGetPortsList({search: this.localSearch, ports:this.portsCountry[this.activeCountry]})
      //await this.axiosGetPortsList(this.ports.filter(el=>obj.ids.indexOf(el.id)==-1).map(el=>el.id).toString())
      //await this.axiosGetPortsByCountry()
      /* if(this.countryList.findIndex(el => el == this.activeCountry) == -1)
        this.activeCountry = '' */
    }
  },
  async beforeMount() {
    this.showLoad()
    await this.axiosGetPortsByCountry()
    if(this.ports?.length){
      this.activeCountry = this.ports[0].country
      await this.axiosGetPortsList({search: this.localSearch, ports:this.ports.map(el=>el.id).toString()})
    }else{
      await this.onSelectCountry(Object.keys(this.portsCountry)[0])
    }
    this.hideLoad()
  }
}
</script>

<style>

</style>