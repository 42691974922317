<template>
  <modal-slots :show-mod="true" class="modal-archive" :textForSecondButton="'Archive'" @close="$emit('close')" @apply="apply">
    <template v-slot:title>
      <p class="fz16 text-dark fw500">To archive</p>
    </template>
    <p class="fz14 text-dark-gray mb p16 pt0">Are you sure you want to archive it?</p>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import {mapActions, mapMutations} from "vuex";
export default {
  name: "folders-modal-archive",
  components: {ModalSlots},
  props: {
    items: [Array]
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(['axiosSetAccessUsersForFolders']),
    ...mapMutations(['showLoad', 'hideLoad', 'deleteFileWithFoldersSection', 'clearSelectedFoldersPage', 'createEmitGetActivityFolders']),
    apply() {
      // for(let i=0 ; i<this.items.length; i++) {
      //   let form = {
      //     id: this.items[i].id,
      //     is_archive: this.$route.path.indexOf('archive') === -1
      //   }
      //   this.axiosPutFileForFolders()
      // }
      let items = this.items
      this.showLoad()
      this.axiosSetAccessUsersForFolders({data: {ids: this.items.map(a=> {return a.id}), physically_move_file: this.$route.path.indexOf('my-files') >= 0, is_archive: this.$route.path.indexOf('archive') === -1}}).then(()=> {
        this.$emit('archived')
        this.hideLoad()
        items.forEach(item=> {
          this.deleteFileWithFoldersSection(item.id)
        })
        this.clearSelectedFoldersPage()
      this.createEmitGetActivityFolders()
      })
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
  .modal-archive {
    /deep/ {
      .top-panel {
        border: none !important;
        padding: 16px !important;
      }
      .bottom-panel {
        border: none !important;
      }
      .blue-button {
        width: 100px !important;
      }
      .content {
        min-width: 400px !important;
      }
    }
  }
</style>