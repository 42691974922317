<template>
  <section class="counterparties-add_section">
    <div class="container counterparties-add_container">
      <counterparties-bread></counterparties-bread>
      <h1 class="counterparties-add_title">Create new counterpartie profile</h1>
      <counterparties-add @close="emitClose" :insideBlockTypeModal="insideBlockTypeModal" :bus="bus" />
    </div>
    <counterparties-add-bottom @close="emitClose" :insideBlockTypeModal="insideBlockTypeModal" @save="addClient" />
  </section>
</template>

<script>
import Vue from 'vue'

import counterpartiesBread from '@/components/counterparties/counterpartiesAdd/counterpartiesBread'
import counterpartiesAdd from '@/components/counterparties/counterpartiesAdd/counterpartiesAdd'
import counterpartiesAddBottom from '@/components/counterparties/counterpartiesAdd/counterpartiesAddBottom'

export default {
  components: {
    counterpartiesBread,
    counterpartiesAdd,
    counterpartiesAddBottom,
  },
  props: {
    insideBlockTypeModal: {
      type: Boolean,
    }
  },
  data() {
    return {
      bus: new Vue()
    }
  },
  methods: {
    addClient() {
      this.bus.$emit('addClient');
    },
    emitClose() {
      this.$emit('close')
    }
  },
}
</script>

<style lang="scss">
  .counterparties-add{
    &_section{
      width: 100%;
      background-color: var(--color-bgc-page-white) !important;
    }
    &_container{
      max-width: 460px;
    }
    &_title{
      margin-top: 26px;
      padding-bottom: 6px;
      font-size: 20px;
      font-weight: 500;
    }
  }
</style>