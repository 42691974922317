<template>
  <section :class="{'archive-tasks' : $route.path.indexOf('archive') >= 0}" v-if="getPermissionsByType('show_manual_tasks')">
    <bread
        v-if="$route.path.indexOf('archive') >= 0"
        :allowOptions="false"
        :links="[
        {name: 'Tasks', link: '/tasks'},
        {name: `Archive Tasks`, link: ''}
      ]"
    />
    <div class="container">
      <div class="main">
        <div class="top-panel">
          <div class="part1">
            <p class="fz20 text-dark fw500">{{$route.path.indexOf('archive') >=0 ? 'Archive Tasks' : 'Tasks'}}</p>
            <div class="buttonBlock" :class="{'oneElem':!getPermissionsForUserCreateManualTask && !getPermissionsForUserCreateVesselTasks}" v-if="$route.path.indexOf('archive') === -1 && (getPermissionsByType('can_create_manual_task') || getPermissionsByType('can_create_ship_task') || getPermissionsByType('can_create_cargo_task'))">
              <button class="fz13 blue-button" v-on:click="showModalAddManuallyTask" v-if="getPermissionsForUserCreateManualTask">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3333 5.33332H6.66668V1.66662C6.66668 1.2987 6.36798 1 5.99994 1C5.63202 1 5.33332 1.2987 5.33332 1.66662V5.33332H1.66662C1.2987 5.33332 1 5.63202 1 5.99994C1 6.36798 1.2987 6.66668 1.66662 6.66668H5.33332V10.3333C5.33332 10.7013 5.63202 11 5.99994 11C6.36798 11 6.66668 10.7013 6.66668 10.3333V6.66668H10.3333C10.7013 6.66668 11 6.36798 11 5.99994C11 5.63202 10.7013 5.33332 10.3333 5.33332Z" fill="white"/>
                </svg>
                Manually task
              </button>
              <button class="fz13 blue-button" v-on:click="showModalAddVesselTask" v-if="!getPermissionsForUserCreateManualTask && getPermissionsForUserCreateVesselTasks">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3333 5.33332H6.66668V1.66662C6.66668 1.2987 6.36798 1 5.99994 1C5.63202 1 5.33332 1.2987 5.33332 1.66662V5.33332H1.66662C1.2987 5.33332 1 5.63202 1 5.99994C1 6.36798 1.2987 6.66668 1.66662 6.66668H5.33332V10.3333C5.33332 10.7013 5.63202 11 5.99994 11C6.36798 11 6.66668 10.7013 6.66668 10.3333V6.66668H10.3333C10.7013 6.66668 11 6.36798 11 5.99994C11 5.63202 10.7013 5.33332 10.3333 5.33332Z" fill="white"/>
                </svg>
                Vessel search
              </button>
              <button v-on:click="showModalAddCargoTask" class="fz13 blue-button" v-if="!getPermissionsForUserCreateManualTask && !getPermissionsForUserCreateVesselTasks">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3333 5.33332H6.66668V1.66662C6.66668 1.2987 6.36798 1 5.99994 1C5.63202 1 5.33332 1.2987 5.33332 1.66662V5.33332H1.66662C1.2987 5.33332 1 5.63202 1 5.99994C1 6.36798 1.2987 6.66668 1.66662 6.66668H5.33332V10.3333C5.33332 10.7013 5.63202 11 5.99994 11C6.36798 11 6.66668 10.7013 6.66668 10.3333V6.66668H10.3333C10.7013 6.66668 11 6.36798 11 5.99994C11 5.63202 10.7013 5.33332 10.3333 5.33332Z" fill="white"/>
                </svg>
                Cargo search
              </button>
              <manual-task-dropdown @cargoWithoutVessel="showModalCargoWithoutVessel" @cargoOurVessel="showModalAddCargoOurVessel" @cargoEmit="showModalAddCargoTask" @vesselEmit="showModalAddVesselTask" v-if="getPermissionsForUserCreateManualTask || getPermissionsForUserCreateVesselTasks"></manual-task-dropdown>
            </div>
            <router-link to="/tasks-archive" class="button-dark-link archive-link svg-white-dart-stroke text-dark fw500 fz13" v-if="getPermissionsForUserCanViewArchive && $route.path.indexOf('archive') === -1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Archive
            </router-link>
            <router-link :to="{name: 'Tasks checklists'}" class="button-dark-link button-dark-link-1 archive-link svg-white-dart text-dark fw500 fz13">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00005 5.59531C6.28208 5.59531 5.70005 6.17734 5.70005 6.89531V17.8953C5.70005 18.6133 6.28208 19.1953 7.00005 19.1953H17C17.718 19.1953 18.3 18.6133 18.3 17.8953V13.5008C18.3 13.1142 18.6134 12.8008 19 12.8008C19.3866 12.8008 19.7 13.1142 19.7 13.5008V17.8953C19.7 19.3865 18.4912 20.5953 17 20.5953H7.00005C5.50888 20.5953 4.30005 19.3865 4.30005 17.8953V6.89531C4.30005 5.40414 5.50888 4.19531 7.00005 4.19531H14C14.3866 4.19531 14.7 4.50871 14.7 4.89531C14.7 5.28191 14.3866 5.59531 14 5.59531H7.00005Z" fill="#2C2C2C"/>
                <path d="M19.6745 5.2683C19.3613 4.92023 18.8445 4.91057 18.5201 5.24674C18.5133 5.25377 18.5066 5.26096 18.5 5.2683L12.0774 13.6424L8.92601 11.012C8.60169 10.6758 8.08487 10.6855 7.77164 11.0335C7.46607 11.3731 7.46607 11.9114 7.77164 12.2509L11.5002 15.5008C11.8191 15.8429 12.3358 15.8429 12.6546 15.5008L19.6544 6.50724C19.9787 6.17103 19.9877 5.61637 19.6745 5.2683Z" fill="#2C2C2C"/>
              </svg>
              Checklists
            </router-link>
          </div>
          <div class="part2">
            <task-sort-by @changeSort="changeSort" class="sort-block"></task-sort-by>
            <div class="taskDisplay">
              <button class="fw500 fz13 text-gray" v-on:click="showMeTask" :class="{'active': switchHowShowTask === false}">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.212 10.8719C13.9294 10.8719 15.3223 9.47945 15.3223 7.7612C15.3223 6.04334 13.9302 4.65048 12.212 4.65088C10.4949 4.65088 9.10206 6.04374 9.10206 7.7612C9.10206 9.47945 10.4949 10.8719 12.212 10.8719Z" stroke="#9AA3B0" stroke-width="1.4"/>
                  <path d="M6.41675 17.2501C6.41675 18.0457 7.06159 18.6906 7.85722 18.6906H16.9136C17.7088 18.6906 18.3537 18.0457 18.3537 17.2501V16.4378C18.3537 14.6961 16.9425 13.2981 15.2017 13.2981H9.57984C7.83937 13.2981 6.41675 14.6969 6.41675 16.4378V17.2501Z" stroke="#9AA3B0" stroke-width="1.4"/>
                </svg>
                Me
              </button>
              <button v-on:click="showAllTask" class="fw500 fz13 text-gray" :class="{'active': switchHowShowTask === true}">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.21174 10.8719C9.9292 10.8719 11.3221 9.47945 11.3221 7.7612C11.3221 6.04334 9.92999 4.65048 8.21174 4.65088C6.49468 4.65088 5.10182 6.04374 5.10182 7.7612C5.10182 9.47945 6.49468 10.8719 8.21174 10.8719Z" stroke="#2C2C2C" stroke-width="1.4"/>
                  <path d="M2.4165 17.2501C2.4165 18.0457 3.06135 18.6906 3.85698 18.6906H12.9133C13.7086 18.6906 14.3534 18.0457 14.3534 17.2501V16.4378C14.3534 14.6961 12.9423 13.2981 11.2014 13.2981H5.5796C3.83912 13.2981 2.4165 14.6969 2.4165 16.4378V17.2501Z" stroke="#2C2C2C" stroke-width="1.4"/>
                  <path d="M14 5.72699C14.3546 5.58084 14.7444 5.5 15.1536 5.5C16.7893 5.49963 18.1144 6.78935 18.1144 8.37999C18.1144 9.971 16.7885 11.2603 15.1536 11.2603C14.7444 11.2603 14.3546 11.1795 14 11.0334M16.573 13.5069H17.9996C19.6567 13.5069 21 14.8014 21 16.414V17.1662C21 17.9029 20.3862 18.5 19.6292 18.5H17.4698" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
                </svg>
              </button>
            </div>
            <div class="block-search">
              <input type="text" v-model="searchText" @focusout="searchInTaskList" v-on:keydown.enter="searchInTaskList" placeholder="Search by tasks" class="search-input-task">
              <button class="icon-close" v-on:click="clearSearch">
                <svg width="12" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.05373 1.9253C0.810215 1.68418 0.810216 1.29326 1.05373 1.05214C1.29724 0.811029 1.69206 0.811029 1.93557 1.05214L6.94574 5.97329C7.18925 6.21441 7.18925 6.60533 6.94574 6.84644C6.70223 7.08756 6.30741 7.08756 6.0639 6.84644L1.05373 1.9253Z" fill="#2C2C2C"/>
                  <path d="M6.06398 1.15353C6.3075 0.912416 6.70231 0.912416 6.94582 1.15353C7.18933 1.39465 7.18933 1.78557 6.94582 2.02669L1.93565 6.94783C1.69214 7.18895 1.29732 7.18895 1.05381 6.94783C0.810298 6.70672 0.810298 6.31579 1.05381 6.07468L6.06398 1.15353Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div class="block-status-done">
              <button class="fz13 fw500 text-dark show-done" v-on:click="doneStatusChange(doneStatus)">
                <svg v-if="!doneStatus" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
                </svg>
                <svg v-if="doneStatus" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="18" height="18" rx="4" fill="#094172"/>
                  <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
                </svg>
                Show Done
              </button>
<!--              <button class="fz13 fw500 text-dark" v-if="!doneStatus" v-on:click="doneStatusChange(doneStatus)">-->
<!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#2C2C2C"/>-->
<!--                </svg>-->

<!--                Hide Done-->
<!--              </button>-->
            </div>
          </div>
        </div>
        <tasks-list :doneStatus="doneStatus" @duplicateTaskWithVessel="duplicateTaskWithVessel" @duplicateTaskWithoutVessel="duplicateTaskWithoutVessel" @duplicateTaskCoupe="showModalAddManuallyTaskDuplicateCargo" @duplicateTaskVessel="showModalAddManuallyTaskDuplicateVessel" @duplicateTask="showModalAddManuallyTaskDuplicate" @renderNew="renderTaskList" ref="taskList" @showModalCreateTask="showModalAddManuallyTask"></tasks-list>
      </div>
      <div class="filter">
        <task-list-filter @clear-assignee="switchHowShowTask = true" @clearAll="switchHowShowTask = true" ref="tasksFilter" :searchName="searchText" @doneStatusTrue="doneStatus=true" @doneStatusFalse="doneStatus=false" @changeAssignee="dontMeTasks" @changeAssigneeMe="switchHowShowTask = false"></task-list-filter>
      </div>
    </div>
    <manually-task-add :class="{'opacity-0 z-index-none': showPageForCreateTask}" ref="manuallyTask" @renderTaskList="renderTaskList" @openCheckList="openCheckList"></manually-task-add>
    <modal-checklist ref="modalCheckList"></modal-checklist>
    <!--    <transition name="fade">-->
<!--      <div class="modal-add-task fz13 text-dark" v-if="getShowModalResponseAfterAddTask">-->
<!--        {{getResponseCreateTask.name}} was added-->
<!--        <button v-on:click="closeModal">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>-->
<!--            <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>-->
<!--          </svg>-->

<!--        </button>-->
<!--      </div>-->
<!--    </transition>-->
    <check-list-edit v-if="showPageForCreateTask || getShow()" :block-name="'createTask'" :in-block="true"></check-list-edit>
  </section>
</template>

<script>
import ManualTaskDropdown from "@/components/tasks/manual-task-dropdown";
import TaskSortBy from "@/components/tasks/taskSortBy";
import ManuallyTaskAdd from "@/components/tasks/manually-task-add/manually-task-add";
import TasksList from "@/components/tasks/tasks-list/tasks-list";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import TaskListFilter from "@/components/tasks/filter/task-list-filter";
import ModalChecklist from "@/components/tasks/modal-checklist/modal-checklist";
import Bread from "@/components/reuse/bread";
import CheckListEdit from "@/views/checkListEdit/check-list-edit";
export default {
  name: "tasks",
  computed: {
    ...mapGetters(["getPermissionsByType", "getShowMeTask", "getResponseCreateTask", "getShowModalResponseAfterAddTask", "getLoginData", "getPermissionsForUserCreateVesselTasks", "getPermissionsForUserCanViewArchive","getPermissionsForUser","getPermissionsForUserCreateManualTask", "getPermissionsForUserCanSeeTask"]),
    ...mapState({
      showPageForCreateTask: state => state.checklistPage.showPageForCreateTask,
      closeModalTask: state => state.task.closeModalTask,
    })
  },
    components: {CheckListEdit, Bread, ModalChecklist, TaskListFilter, TasksList, ManuallyTaskAdd, TaskSortBy, ManualTaskDropdown},
    data() {
      return {
        switchHowShowTask: true,
        searchText: "",
        searchTextCopy: "",
        doneStatus: false,
      }
    },
  watch: {
    closeModalTask: function () {
      this.$refs.manuallyTask.hideModal()
    }
  },
    methods: {
    ...mapActions(["axiosGetPermissionsForUserLogin"]),
    ...mapMutations(["setShowMeTask", "clearTasksList", "setShowModalResponseAfterAddTask", "searchForTasksList", "setStatusShowPageForCreateTask"]),
      clearSearch() {
        if(this.searchText.length) {
          this.searchText = '';
          setTimeout(()=> {
            this.searchInTaskList()
          }, 50)
        } else {
          this.searchText = ''
        }
      },
      getShow() {
        // return window.location.href.indexOf('local')>=0
        return false
      },
      openCheckList() {
        this.$refs.modalCheckList.openModalChekList()
      },
      doneStatusChange(bool) {
        this.$refs.tasksFilter.doneStatusChange(bool);
      },
      closeModal() {
        this.setShowModalResponseAfterAddTask(false);
      },
      showModalAddManuallyTaskDuplicate(data) {
        if(this.getPermissionsForUserCreateManualTask)
          this.$refs.manuallyTask.showModalDuplicate(data);
      },
      showModalAddManuallyTaskDuplicateVessel(data) {
        if(this.getPermissionsForUserCreateManualTask) {
          this.$refs.manuallyTask.showModalDuplicateVessel(data);
        }
      },
      showModalAddManuallyTaskDuplicateCargo(data) {
        if(this.getPermissionsForUserCreateManualTask) {
          // alert(1);
          this.$refs.manuallyTask.showModalDuplicateCargo(data);
        }
      },
      duplicateTaskWithVessel(data) {
        this.$refs.manuallyTask.showModalDuplicateCargoWithVessel(data);
      },
      duplicateTaskWithoutVessel(data) {
        this.$refs.manuallyTask.showModalDuplicateCargoWithOutVessel(data);
      },
      changeSort() {
        // this.searchText = "";
        // this.searchForTasksList(this.searchText);
        this.$refs.tasksFilter.convertToFormAxiosFilter()
      },
      searchInTaskList() {
        console.log(this.searchTextCopy, this.searchText)
        if(this.searchTextCopy !== this.searchText) {
          this.searchTextCopy = this.searchText
          this.$refs.tasksFilter.convertToFormAxiosFilter()
        }
      },
      showModalAddManuallyTask() {
        if(this.getPermissionsForUserCreateManualTask) {
          this.$refs.manuallyTask.showModal();
          this.$refs.manuallyTask.manuallyTaskEvent();
        }
      },
      showModalAddVesselTask() {
        this.$refs.manuallyTask.showModal();
        this.$refs.manuallyTask.vesselSearchEvent();
      },
      showModalAddCargoTask() {
        this.$refs.manuallyTask.showModal();
        this.$refs.manuallyTask.cargoSearchEvent();
      },
      showModalAddCargoOurVessel() {
        this.$refs.manuallyTask.showModal();
        this.$refs.manuallyTask.cargoOurVesselSearchEvent();
      },
      showModalCargoWithoutVessel() {
        this.$refs.manuallyTask.showModal();
        this.$refs.manuallyTask.cargoSearchWithoutVesselEvent();
      },
      showMeTask() {
        this.switchHowShowTask = false;
        this.$refs.tasksFilter.showMeTask(this.getLoginData.data.user.id)
      },
      showAllTask() {
        this.switchHowShowTask = true;
        this.$refs.tasksFilter.clearTaskAssignee()
      },
      dontMeTasks() {
        this.switchHowShowTask = true;
      },
      renderTaskList() {
        this.$refs.tasksFilter.convertToFormAxiosFilter();
      }
    },
  mounted() {
    if(this.getShowMeTask === true) {
      this.showMeTask();
      this.setShowMeTask(false)
    }
  },
  beforeCreate: function() {
    document.body.classList.add('tasks');
  },
  beforeRouteLeave(to,from,next) {
    document.body.classList.remove('tasks');

    this.clearTasksList();

        // this.$refs.tasksFilter.setCollapse();
    if(!this.showPageForCreateTask) {
      next();
    } else {
      this.setStatusShowPageForCreateTask(false)
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      // console.log("ROUUUTE");
      // console.log(vm.$store.getters.getPermissionsForUser.length);
      // console.log(vm.$store.getters.getPermissionsForUserCanSeeTask.status);

      if(!vm.$store.getters.getPermissionsByType('show_manual_tasks') && vm.$store.getters.getPermissionsForUser.length !== 0) {
        next(from);
      }
    })
  }
  }
</script>

<style scoped lang="scss">
  @import "tasks.scss";
  .z-index-none {
    z-index: 10 !important;
  }
  .svg-white-dart {
    path {
      stroke: transparent !important;
      fill: var(--color-dark) !important;
    }
  }
  .button-dark-link-1:hover {
    svg {
      path {
        stroke: transparent !important;
        fill: var(--color-blue-dark) !important;

      }
    }
    .svg-white-dart {
      path {
        stroke: transparent !important;
        fill: var(--color-blue-dark) !important;
      }
    }
  }
  .taskDisplay {
    background-color: var(--color-modal);
  }
  .block-search {
    input {

      background-color: var(--color-modal) !important;
    }
  }
  @media (max-width: 1500px) {
    .archive-link {
      margin-left: 16px !important;
    }
    .top-panel {
      justify-content: flex-start !important;
      .part2 {
        margin-left: 16px;
        flex: 1;
      }
    }
    .sort-block {
      width: max-content !important;
    }
    .sort-block {
      order: 1
    }
    .block-status-done {
      order: 2;
    }
    .taskDisplay {
      order: 3
    }
    .block-search {
      order: 4;
      flex: 1;
      margin-left: 16px !important;
    }
  }
  .dart-mode {
    .block-status-done {
      svg {
        rect {
          fill: var(--color-blue-dark)
        }
      }
    }
  }

</style>