<template>
  <div class="wrap svg-white-for-dart">
    <button v-on:click="changeOne" :class="{'button-change-active': activeOne}" class="button-change">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.83325 17.5L6.73396 12.2391L9.85453 17.5L14.5354 10.3261H18.8262L21.1666 6.5" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <button v-html="activeButtonSvg" v-on:click="changeTwo" :class="{'button-change-active': !activeOne}" class="button-change"></button>
    <b-dropdown right ref="dropdown">
      <template #button-content>

        <button class="dropdown-button">

          <button>
            <svg class="arrow-d" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2C2C2C"/>
            </svg>
          </button>
        </button>
      </template>
      <div class="content">
        <button class="content-button"  v-for="(b, i) in buttons" :key="i" :class="{'active-button': b.status}" v-on:click="changeList(i)">
          <span v-html="b.svg"></span> <p class="text-dark fz13">{{ b.name }}</p>
        </button>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  computed: mapGetters(['getLoginData', "getToken"]),
  name: "type-of-graphs",
  data() {
    return {
      nameActive: '',
      buttons: [
        {name: "Bars", svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M6.82464 5.33203V7.77389M6.82464 20.332V17.8902M6.82464 17.8902H3.66675M6.82464 17.8902V7.77389M6.82464 7.77389H10.3334" stroke="#9AA3B0" stroke-width="1.8" stroke-linecap="round"/>\n' +
              '<path d="M17.1754 18.668L17.1754 11.3424M17.1754 3.66797L17.1754 15.8773M17.1754 15.8773L20.3333 15.8773M17.1754 15.8773L17.1754 11.3424M17.1754 11.3424L13.6666 11.3424" stroke="#9AA3B0" stroke-width="1.8" stroke-linecap="round"/>\n' +
              '</svg>\n',
        status: false},
        {name: "Candles", svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M7.66675 4.5V8.66667M7.66675 21.1667V19.0833" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '<path d="M16.3333 2.83203V5.33203M16.3333 19.4987V15.7487" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '<mask id="path-3-inside-1" fill="white">\n' +
              '<rect x="5" y="8.04688" width="5.33333" height="11.4247" rx="1"/>\n' +
              '</mask>\n' +
              '<rect x="5" y="8.04688" width="5.33333" height="11.4247" rx="1" stroke="#9AA3B0" stroke-width="3" mask="url(#path-3-inside-1)"/>\n' +
              '<rect x="13.6666" y="4.85938" width="5.33333" height="11.4247" rx="1" fill="#9AA3B0"/>\n' +
              '</svg>\n',
        status: false},
        {name: "Hollow Candles", svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M7.66675 4.5V8.66667M7.66675 21.1667V19.0833" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '<path d="M16.3333 2.83203V5.33203M16.3333 19.4987V15.7487" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '<mask id="path-3-inside-1" fill="white">\n' +
              '<rect x="5" y="8.04688" width="5.33333" height="11.4247" rx="1"/>\n' +
              '</mask>\n' +
              '<rect x="5" y="8.04688" width="5.33333" height="11.4247" rx="1" stroke="#9AA3B0" stroke-width="3" mask="url(#path-3-inside-1)"/>\n' +
              '<mask id="path-4-inside-2" fill="white">\n' +
              '<rect x="13.6666" y="4.85938" width="5.33333" height="11.4247" rx="1"/>\n' +
              '</mask>\n' +
              '<rect x="13.6666" y="4.85938" width="5.33333" height="11.4247" rx="1" stroke="#9AA3B0" stroke-width="3" mask="url(#path-4-inside-2)"/>\n' +
              '</svg>\n',
        status: false},
        {name: "Filled Candles", svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M7.66675 4.5V21.1667" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '<path d="M16.3333 2.83203V19.4987" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '<rect x="5" y="8.04688" width="5.33333" height="11.4247" rx="1" fill="#9AA3B0"/>\n' +
              '<rect x="13.6666" y="4.85938" width="5.33333" height="11.4247" rx="1" fill="#9AA3B0"/>\n' +
              '</svg>\n',
        status: false},
        {name: "Line", svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M2.83325 17.5L6.73396 12.2391L9.85453 17.5L14.5354 10.3261H18.8262L21.1666 6.5" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '</svg>\n',
        status: true},
        {name: "Area", svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path opacity="0.2" d="M6.73399 11.3212L2.375 16.582V20.2487H21.625V5.58203L18.8262 9.40812H14.5354L9.85456 16.582L6.73399 11.3212Z" fill="#9AA3B0"/>\n' +
              '<path d="M2.83325 16.582L6.73396 11.3212L9.85453 16.582L14.5354 9.40812H18.8262L21.1666 5.58203" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '</svg>\n',
        status: false},
        // {name: "Baseline", svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        //       '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.2093 3.5064C11.1093 3.21523 10.8416 3.01475 10.534 3.00078C10.2265 2.98681 9.94165 3.16219 9.81567 3.44309L8.5631 6.23598L8.20254 5.27122C8.09298 4.97809 7.81294 4.78379 7.5 4.78379C7.18706 4.78379 6.90702 4.97809 6.79746 5.27122L4.62546 11.0828H6.2268L7.5 7.67612L7.79746 8.47203C7.90395 8.75696 8.172 8.94936 8.47603 8.95908C8.78006 8.9688 9.05985 8.79393 9.18433 8.51638L10.4074 5.78932L12.2253 11.0828H13.8113L11.2093 3.5064ZM15.0706 14.7495L16.2206 18.0979L17.5614 14.7495H19.1772L16.8629 20.5288C16.7464 20.8198 16.4612 21.0076 16.1479 20.9998C15.8346 20.9919 15.5591 20.7901 15.4573 20.4936L13.4846 14.7495H15.0706ZM4.85643 14.7495H3.25509L2.1308 17.7577C1.98578 18.1457 2.18277 18.5778 2.57077 18.7228C2.95877 18.8678 3.39086 18.6708 3.53587 18.2828L4.85643 14.7495ZM20.6454 11.0828H19.0296L20.4704 7.48472C20.6244 7.10018 21.0609 6.91329 21.4455 7.06726C21.83 7.22124 22.0169 7.65779 21.8629 8.04232L20.6454 11.0828Z" fill="#9AA3B0"/>\n' +
        //       '<path opacity="0.2" d="M2.375 12.918H21.1667" stroke="#2C2C2C" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2 2"/>\n' +
        //       '</svg>\n',
        // status: false},
      ],
      activeButtonSvg: '<svg class="button-change-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M7.66675 4.5V8.66667M7.66675 21.1667V19.0833" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '<path d="M16.3333 2.83203V5.33203M16.3333 19.4987V15.7487" stroke="#9AA3B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '<mask id="path-3-inside-1" fill="white">\n' +
          '<rect x="5" y="8.04688" width="5.33333" height="11.4247" rx="1"/>\n' +
          '</mask>\n' +
          '<rect x="5" y="8.04688" width="5.33333" height="11.4247" rx="1" stroke="#9AA3B0" stroke-width="3" mask="url(#path-3-inside-1)"/>\n' +
          '<rect x="13.6666" y="4.85938" width="5.33333" height="11.4247" rx="1" fill="#9AA3B0"/>\n' +
          '</svg>\n',
      activeOne: true,
      activeButtonIndex: 4,
      firstClick: false,
    }
  },
  methods: {
    ...mapActions(["axiosLogoutInServer"]),
    clickProfile() {
      this.$refs.dropdown.hide(true);
    },
    clickLogout() {
      this.$refs.dropdown.hide(true);
      this.axiosLogoutInServer(this.getToken).then(()=> {
        this.$router.push("/login");
      });
    },
    changeOne() {
      this.activeOne = true;
      this.$emit("changeType", 'Line')
    },
    changeTwo() {
      this.activeOne = false;
      if(!this.firstClick) {
        this.firstClick = true;
        this.changeList(1);
      }
      this.$emit("changeType", this.nameActive)
    },
    changeList(index) {
      this.$refs.dropdown.hide(true);
      if(index!==this.activeButtonIndex) {
        for(let i=0; i<this.buttons.length; i++) {
          if(index === i) {
            this.activeButtonIndex = index;
            this.buttons[i].status = true;
            // console.log(this.buttons[i].name)
            this.nameActive = this.buttons[i].name;
            if(''+this.buttons[i].name === "Line") {
              // if(!this.buttons[i].status) {
              this.activeOne = true;
              // }
            } else {
              this.activeButtonSvg = this.buttons[i].svg
              this.activeOne = false;
            }
            this.$emit("changeType", this.nameActive)

          } else {
            this.buttons[i].status = false;
          }
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style-element/dropdown";

.wrap {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  border-radius: 4px;
  width: 104px;

  &>* {
    height: 28px;
    box-sizing: border-box;
  }
  &>*:nth-child(-n+2) {
    width: 40px;
    border-right: 1px solid var(--color-border);
  }
  &>*:nth-child(1) {
  }
  &>*:nth-child(2) {
  }

}

.dropdown-button {
  &>*:nth-child(1) {
    height: 28px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}
p {
  margin: 0;
}
.name {
  margin-right: 8px;
}
img {
  width: 32px;
  height: 32px;
  border-radius: 40px;
}
.arrow {
  margin-left: 4px;
}
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  background: transparent;
  border: none;
  min-width: 1px;
  width: max-content;
}
.content {
  background: var(--color-modal);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  &-button {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    p {
      margin-left: 8px;
    }
    @media (min-width: 1281px) {
      &:hover {
        background: var(--color-input);
      }
    }
    position: relative;
    padding-right: 30px;

  }
}
.active-button {
  &::after {
    content: url("../../../assets/Vector1.svg");
    position: absolute;
    right: 8px;
    bottom: 5px;
  }
}

.button-change {
  &-active {
    background: var(--color-card-border) !important;
  }
}


.dart-mode {
  .svg-white-for-dart {
    svg {
      path {
        stroke: #FFFFFF;
      }
      rect {
        fill: #FFFFFF;
      }
    }
  }
}

//.button-change {
//  @media (min-width: 1281px) {
//    svg {
//      path {
//        transition: .1s linear;
//      }
//    }
//    &:hover {
//      svg {
//        path {
//          fill: #319FEF;
//          stroke: #319FEF;
//        }
//      }
//    }
//  }
//}
    ///deep/ {
    //  .button-change {
    //    @media (min-width: 1281px) {
    //      svg {
    //        path, mask, rect {
    //          transition: .1s linear;
    //        }
    //      }
    //      &:hover {
    //        svg {
    //          path, mask, rect {
    //            fill: #319FEF;
    //            stroke: #319FEF;
    //          }
    //        }
    //      }
    //    }
    //  }
    //}
</style>
