<template>
  <div>
    <cargo-head class="mt16" :loadAddCargo="loadAddCargo" @add="onAddCargo"></cargo-head>
    <cargo-item @checkRoutesAndCargos="$emit('checkRoutesAndCargos')" @calc="$emit('calc')" :lastRow="i === getOrderingCargo.length -1" @delete="onDeleteRow" :routeList="routeList ? routeList : []" v-for="(cargo, i) in getOrderingCargo" :detail="cargo" :index="i" :key="i+'sds'"></cargo-item>
  </div>
</template>

<script>
import CargoHead from "@/components/calc/cargoTable/cargo-head";
import CargoItem from "@/components/calc/cargoTable/cargo-item";
import {mapActions} from "vuex";
export default {
  name: "cargo-list",
  components: {CargoItem, CargoHead},
  computed: {
    getOrderingCargo() {
      // let orderingList = []
      // this.routeList.forEach(route=>{
      //   let filteredCargo = this.cargoList.filter(cargo=>cargo.loading_port_name == `#${route.id}`)
      //   orderingList.push(...filteredCargo)
      // })
      //
      // let filteredCargo = this.cargoList.filter(cargo=>!cargo.loading_port_name)
      // orderingList.push(...filteredCargo)

      // orderingList = this.cargoList.map(cargo=> {
      //   if(this.routeList.find(route=> cargo.loading_port_name == `#${route.route_id}`) || !cargo.loading_port_name) {
      //     return cargo;
      //   }
      // }).filter(a=> a)


      // return orderingList
      return this.cargoList
    }
  },
  props: {
    cargoList: {
      type: Array,
    },
    routeList: Array,
    selectedId: [Number, String]
  },
  data() {
    return {
      emptyCargo: {
        tab: null,
        ballast_voyage: false,
        cargo_class: null,
        quantity_min: null,
        quantity_max: null,
        quantity_mt: null,
        quantity_percentage: null,
        freight: null,
        cg_comm: 3.75,
        stowage_factor_m: null,
        stowage_factor_ft: null,
        extra_cargo_costs: null,
        loading_wk_cranes: true,
        loading_port_name: null,
        loading_rate_type: 'RATE',
        loading_rate: null,
        loading_terms: null,
        loading_turn_time: '12',
        loading_liner: null,
        loading_draft: null,
        loading_water_density: 'Salt water (1,025)',
        loading_seasonal_mark: 'summer',
        discharging_wk_cranes: true,
        discharging_port_name: null,
        discharging_rate_type: 'RATE',
        discharging_rate: null,
        discharging_terms: null,
        discharging_turn_time: '12',
        discharging_liner: null,
        discharging_draft: null,
        discharging_water_density: 'Salt water (1,025)',
        discharging_seasonal_mark: 'summer',
        loading_port: null,
        discharging_port: null
      },
      loadAddCargo: false,
    }
  },
  methods: {
    ...mapActions(['axiosDeleteCalcCargo', 'axiosPostCalcCargo']),
    checkCargos() {
      this.cargoList.forEach(cargo => {
        let loadCargoId = +cargo.loading_port_name?.replace('#','')
        let loadRoute = this.routeList.find(el=>el.id==loadCargoId)
        let dischargingCargoId = +cargo.discharging_port_name?.replace('#','')
        let dischargingRoute = this.routeList.find(el=>el.id==dischargingCargoId)
        if(cargo.loading_port && (cargo.loading_port_name?.indexOf('#') != 0 || !loadRoute || loadCargoId != loadRoute.id || loadRoute.port.name != cargo.loading_port.name)){
          cargo.loading_port = null
          cargo.loading_port_name = null
        }else if(cargo.loading_port){
          cargo.loading_port.route_id = +cargo.loading_port_name.replace('#', '')
        }
        if(cargo.discharging_port && (cargo.discharging_port_name?.indexOf('#') != 0 || !dischargingRoute || dischargingCargoId != dischargingRoute.id || dischargingRoute.port.name != cargo.discharging_port.name)){
          cargo.discharging_port = null
          cargo.discharging_port_name = null
        }else if(cargo.discharging_port){
          cargo.discharging_port.route_id = +cargo.discharging_port_name.replace('#', '')
        }
      })
    },
    async onDeleteRow(id) {
      await this.axiosDeleteCalcCargo(id)
      let delIndex = this.cargoList.findIndex(el=>el.id == id)
      if(delIndex >= 0)
        this.cargoList.splice(delIndex, 1)

      if(!this.cargoList.length){
        this.onAddCargo()
      }
    },
    async onAddCargo() {
      let newCargo = JSON.parse(JSON.stringify(this.emptyCargo))
      newCargo.tab = +this.$route.params.id
      newCargo['vessel'] = this.selectedId
      this.loadAddCargo = true
      let rasponceCargo = await this.axiosPostCalcCargo(newCargo)
      this.loadAddCargo = false
      this.cargoList.push(rasponceCargo)
    },
  },
  mounted() {
    this.checkCargos()
  }
}
</script>

<style scoped lang="scss">

</style>