import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import QueryString from "querystring";

export default {
  actions: {
    async axiosDeleteContract(ctx, id) {
      return await axios.delete(process.env.VUE_APP_URL+'/contract/'+id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        },
      }).then(()=> {
        return true
      }).catch(()=> {
        return false
      })
    },
    async axiosGetAllContractForList(ctx) {
      return await axios.get(process.env.VUE_APP_URL+'/contract/dropdown', {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        },
        params: {
          limit: 99999,
          contract_type: 'SHIP'
        }
      }).then(res=> {
        return res.data.results
      }).catch(()=> {
        return []
      })
    },
    async checkContract(ctx, id) {
      if(ctx.state.dataContract !== null && ctx.state.dataContract !== undefined) {
        ctx.state.activeContract = ctx.state.dataContract
      }
      if(ctx.state.activeContract?.id == id)
        return false
      else {
        ctx.state.activeContract = null
        await ctx.dispatch('axiosOpenContractById', id)
      }
    },
    async checkSubcontract(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/contract/'+ router.currentRoute.params.subId , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
      .then( response => {
        ctx.state.activeSubcontract = response.data
      })
      .catch((error) => {
        console.error('checkSubcontract', error);
        ctx.state.activeSubcontract = null
        router.push('/vessels')
        ctx.commit('addTip', 'The Sublet/Voyage doesn\'t exist')
        ctx.commit('hideLoad')
      })
    },
    async axiosOpenContractById(ctx, id) {
      await axios.get(process.env.VUE_APP_URL+'/contract/'+ id , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
      .then( response => {
        ctx.state.activeContract = response.data
        ctx.commit('setDataContract', response.data)
      })
      .catch(() => {
        router.push('/vessels')
      })
    },
    async axiosChangeContract(ctx, data) {
      return await axios.patch(process.env.VUE_APP_URL+'/contract/' + data.id, data.data, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      }).then(()=> {
        return true;
      }).catch(()=> {
        return false;
      })
    },
    async axiosGetContractByIdReturnResponse(ctx, id) {
      await axios.get(process.env.VUE_APP_URL+'/contract/' + id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      }).then(res=> {
        ctx.commit("setResponseContract", res.data);
      }).catch(()=> {
        return false;
      })
      // await               console.log(2);
    },
    axiosGetContractById(ctx, id) {
      ctx.commit("showLoad");
      return axios.get(process.env.VUE_APP_URL+'/contract/' + id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      }).then(res=> {
        ctx.commit("setDataDetailContract", res.data)
        ctx.commit("hideLoad");
        return res.data;
      }).catch(()=> {
        ctx.commit("hideLoad");
        return false;
      })
    },
    axiosGetContractByIdJustResponse(ctx, id) {
      return axios.get(process.env.VUE_APP_URL+'/contract/' + id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      }).then(res=> {
        return res.data
      }).catch(()=> {
        return false;
      })
    },
    async axiosContractAddFiles(ctx, data) {
      let filesId = [];
      filesId = data.fileInPayment.map(a=> {return a.id});
      let notAddFile = false;
      if(data.file.length) {
        for (let i = 0; i < data.file.length; i++) {
          let file = data.file[i];
          let formData = new FormData();
          formData.append("file", file);
          formData.append("name", file.name);
          formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
          if(file.parent_id !== undefined && file.parent_id !== null) {
            notAddFile = true;
            formData.append("parent_id", file.parent_id);
            formData.append("parent_file", file.parent_id);
          }
          filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
          console.log(file)
        }
      }
      console.log(data)
      if(!notAddFile) {
        return await axios.patch(process.env.VUE_APP_URL+"/contract/" + data.id, {
          'files': filesId
        }, {
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken,
          }
        }).then(res=> {
          return res.data;
        });
      } else {
        return null
      }

    },
    axiosContractRemoveFile(ctx, data) {
      return  axios.patch(process.env.VUE_APP_URL+"/contract/" + data.id, {
        'files': data.filesId
      }, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      });
    },
    async axiosGetBalances(ctx, data) {
      ctx.state.vesselBalances = null
      let oldYears = [];
      let payload = {
        contract_id: data.id
      }
      let periodBool = false;
      if(data.before) {
        payload.date_before = data.before
        periodBool = true
      }
      if(data.after) {
        payload.date_after = data.after
        periodBool = true
      }
      ctx.state.periodBool = periodBool;
      if(periodBool && ctx.state.available_years.length !== 0) {
        oldYears = ctx.state.available_years
      }
      await axios.post(process.env.VUE_APP_URL+'/owners/balances/', payload, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.state.vesselBalances = response.data
        if(oldYears.length !== 0) {
          ctx.state.vesselBalances.available_years = oldYears
        } else {
          ctx.state.available_years = response.data.available_years
          ctx.state.vesselBalances.available_years = response.data.available_years
        }
      }).catch(() => {
        ctx.state.vesselBalances = null
      })
    },
    async axiosGetClientBalance(ctx, contract_id) {
      return await axios.post(process.env.VUE_APP_URL+'/owners/client/balances/',
      {
        contract_id
      }, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        return response.data
      }).catch(error => {
        console.error('error in axiosGetClientBalance', error);
        return false
      })
    },
    async axiosGetCargoContracts(ctx, ids) {
      return axios.get(process.env.VUE_APP_URL+'/contract/cargo/contracts', {
        params: {
          ids: ids
        },
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        },
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      }).then(res=> {
        return res.data
      })
    }
  },
  mutations: {
    changeDataMainShip(state, data) {
      if(state.activeContract && data && state.activeContract.id === data.id) {
        state.activeContract = data;
        state.refreshBreadEvent++;
      }
    },
    setAvailableDate(state, data) {
      state.available_years = data;
    },
    changeContractBread(state, {index, obj}) {
      if(obj){
        Vue.set(state.contractBread, index, obj)
      }else{
        state.contractBread.splice(index, 1)
      }
    },
    setResponseContract(state, data) {
      state.responseContract = data;
    },
    emitOpenModalVoyage(state) {
      state.modalVoyage++;
    },
    emitOpenModalVoyageCharterer(state) {
      state.modalVoyageCharterer++
    },
    emitOpenModalSublet(state) {
      state.modalSublet++;
    },
    emitOpenModalVoyageEdit(state) {
      state.modalVoyageEdit++;
    },
    emitOpenModalSubletEdit(state) {
      state.modalSubletEdit++;
    },
    refreshContractStateAdd(state) {
      state.refreshContractState++;
    },
    setDataContract(state, data) {
      state.dataContract = data;
      state.activeContract = data;
      state.refreshBreadEvent++;
    },
    emitEditContract(state) {
      state.emitEditContract++;
    },
    setDataDetailContract(state, data) {
      state.dataDetailContract = data;
    },
    setDataContractForEdit(state, data) {
      state.dataContractForEdit = data;
    },
    setStartLoadNewVessel(state, data) {
      state.StartLoadNewVessel = data
    },
    emitOpenModalVoyageChartererEdit(state) {
      state.OpenModalVoyageChartererEdit++
    }
  },
  state: {
    OpenModalVoyageChartererEdit: 0,
    StartLoadNewVessel: false,
    refreshBreadEvent: -3242300,
    modalVoyageCharterer: 0,
    contractBread: [
      {
        name: 'Vessels',
        link: '/vessels/actual'
      },
      {
        name: '',
        link: ''
      },
    ],
    activeContract: null,
    activeSubcontract: null,
    responseContract: null,
    modalVoyage: 0,
    modalSublet: 0,
    modalVoyageEdit: 0,
    modalSubletEdit: 0,
    refreshContractState: 0,
    dataContract: null,
    emitEditContract: 0,
    dataDetailContract: null,
    dataContractForEdit: null,
    vesselBalances: null,
    clientBalances: null,
    periodBool: false,
    available_years: [],
  },
  getters: {
    getStartLoadNewVessel(state) {
      return state.StartLoadNewVessel
    },
    getDataDetailContract(state) {
      return state.dataDetailContract
    },
    getResponseContract(state) {
      return state.responseContract;
    },
    getWatchOpenModalVoyage(state) {
      return state.modalVoyage;
    },
    getWatchOpenModalSublet(state) {
      return state.modalSublet;
    },
    getWatchModalVoyageCharterer(state) {
      return state.modalVoyageCharterer;
    },
    getWatchOpenModalVoyageEdit(state) {
      return state.modalVoyageEdit;
    },
    getWatchOpenModalSubletEdit(state) {
      return state.modalSubletEdit;
    },
    refreshContract(state) {
      return state.refreshContractState;
    },
    getDataContractV(state) {
      return state.dataContract;
    },
    getEmitEditContract(state) {
      return state.emitEditContract;
    },
    getDataContractForEdit(state) {
      return state.dataContractForEdit;
    },
    getWatchOpenModalVoyageChartererEdit(state) {
      return state.OpenModalVoyageChartererEdit
    }
  }
}