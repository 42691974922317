import axios from 'axios'
import QueryString from "querystring";

export default {
    actions: {
        axiosGetAllCurrency(ctx, data) {
            return axios.get(process.env.VUE_APP_URL + "/currency", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    limit: 99999,
                    created_at: data.created_at
                }
            }).then(response=> {
                return response
            }).catch(()=> {
                return false
            })
        },
        axiosContractVesselBool(ctx) {
            return axios.get(process.env.VUE_APP_URL + "/contract/?is_redelivery=false&is_archive=false&limit=1", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                if(res.data.results.length !== 0) {
                    return true;
                }
                return false
            }).catch(()=> {
                return false;
            })
        },
        axiosContractRedeliveryVessel(ctx) {
            return axios.get(process.env.VUE_APP_URL + "/contract/?is_redelivery=true&is_archive=false", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                if(res.data.results.length !== 0) {
                    return res.data.results;
                }
                return false
            }).catch(()=> {
                return false;
            })
        },
        axiosContractCargo(ctx) {
            return axios.get(process.env.VUE_APP_URL + "/contract/?is_archive=false&is_redelivery=false", {
                params: {
                    // contract_type: ["CARGO", "SUBLET"]
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(res=> {
                if(res.data.results.length !== 0) {
                    return res.data.results;
                }
                return false
            }).catch(()=> {
                return false;
            })
        },
        axiosGetFuel(ctx, data) {
            return axios.get(process.env.VUE_APP_URL +  "/fuel/price/", {
                params: {
                    fuel_type: data.fuel_type,
                    created_at: data.created_at,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                if(res.data.length !== 0) {
                    return res.data;
                }
                return false
            }).catch(()=> {
                return false;
            })
        },
        axiosGetBanksDashboard(ctx) {
            return axios.get(process.env.VUE_APP_URL + "/payment/cash/balance/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    is_archive_cash: "false",
                    is_archive_requisite: "false",
                }
            }).then(res=> {
                    return res.data;
            }).catch(()=> {
                return false;
            })
        },
        async axiosGetCurrencyAsync(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL + "/currency/", {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              params: {
                  ordering: '-created_at',
                  limit: 1,
                  created_at: data.created_at,
                  currency: data.currency,
              }
          }).then(res=> {
              return res.data.results;
          })
        },
        axiosGetCountPayment(ctx, data) {
            return axios.get(process.env.VUE_APP_URL + "/payment/", {
                params: {
                    execute_date_before: data.after,
                    execute_date_after: data.before,
                    // record_type: "PAYMENT",
                    // data_type: 'invoice'
                    payment_type: 'expense',
                    status: 'not_paid',
                    files_exists: true,
                    limit: 9999,
                    data_type: 'payment',
                    record_type: 'PAYMENT',
                    is_archive: false,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res.data.results.filter(a=> a.execute_date !== null).length;
            }).catch(()=> {
                return false;
            })

        },
        async axiosSetDashboard(ctx, data) {
          return await axios.post(process.env.VUE_APP_URL + "/dashboard", {
              "widget": data,
          }, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res
          }).catch(()=> {
              return false;
          })
        },
        async axiosGetDashboard(ctx) {
            return await axios.get(process.env.VUE_APP_URL + "/dashboard", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                if(res.data.results.length !== 0) {
                    return res.data.results;
                }
                return false;
            }).catch(()=> {
                return false;
            })
        },
        async axiosDeleteDashboard(ctx, id) {
            await axios.delete(process.env.VUE_APP_URL + "/dashboard/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            })
        },
        axiosGetFavoriteCurrency(ctx) {
            return axios.get(process.env.VUE_APP_URL + "/currency/favorite/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                    return res.data.results;
            }).catch(()=> {
                return false;
            })
        },
        axiosAddFavoriteCurrency(ctx, cur) {
            return axios.post(process.env.VUE_APP_URL + "/currency/favorite/", {
                currency: cur
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res;
            }).catch(()=> {
                return false;
            })
        },
        axiosRemoveFavoriteCurrency(ctx, id) {
            return axios.delete(process.env.VUE_APP_URL + "/currency/favorite/" + id,{
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            })
        }

    },
    mutations: {
        setDashBoardPush(state, data) {
            state.DashBoardPush = data;
        },
        setTimeForPaymentInstruction(state, data) {
            state.timeForPaymentInstruction = data;
        },
        setShowMeTask(state, data) {
            state.showMeTask = data;
        },
        setDashboardPushYear(state, data) {
            state.DashboardPushYear = data;
        }
    },
    state: {
        DashBoardPush: false,
        timeForPaymentInstruction: "",
        showMeTask: false,
        listCurrency: [
            {value: "AED", name: "UAE dirham"},
            {value: "AFN", name: "Afghan afghani"},
            {value: "ALL", name: "Albanian lek"},
            {value: "AMD", name: "Armenian dram"},
            {value: "ANG", name: "Netherlands Antillean gulden"},
            {value: "AOA", name: "Angolan kwanza"},
            {value: "ARS", name: "Argentine peso"},
            {value: "AUD", name: "Australian dollar"},
            {value: "AWG", name: "Aruban florin"},
            {value: "AZN", name: "Azerbaijani manat"},
            {value: "BAM", name: "Bosnia and Herzegovina konvertibilna marka"},
            {value: "BBD", name: "Barbadian dollar"},
            {value: "BDT", name: "Bangladeshi taka"},
            {value: "BGN", name: "Bulgarian lev"},
            {value: "BHD", name: "Bahraini dinar"},
            {value: "BIF", name: "Burundi franc"},
            {value: "BMD", name: "Bermudian dollar"},
            {value: "BND", name: "Brunei dollar"},
            {value: "BOB", name: "Bolivian boliviano"},
            {value: "BRL", name: "Brazilian real"},
            {value: "BSD", name: "Bahamian dollar"},
            {value: "BTN", name: "Bhutanese ngultrum"},
            {value: "BWP", name: "Botswana pula"},
            {value: "BYR", name: "Belarusian ruble"},
            {"value":"BZD","name":"Belize dollar"},
            {"value":"CAD","name":"Canadian dollar"},
            {"value":"CDF","name":"Congolese franc"},
            {"value":"CHF","name":"Swiss franc"},
            {"value":"CLP","name":"Chilean peso"},
            {"value":"CNY","name":"Chinese/Yuan renminbi"},
            {"value":"COP","name":"Colombian peso"},
            {"value":"CRC","name":"Costa Rican colon"},
            {"value":"CUC","name":"Cuban peso"},
            {"value":"CVE","name":"Cape Verdean escudo"},
            {"value":"CZK","name":"Czech koruna"},
            {"value":"DJF","name":"Djiboutian franc"},
            {"value":"DKK","name":"Danish krone"},
            {"value":"DOP","name":"Dominican peso"},
            {"value":"DZD","name":"Algerian dinar"},
            {"value":"EEK","name":"Estonian kroon"},
            {"value":"EGP","name":"Egyptian pound"},
            {"value":"ERN","name":"Eritrean nakfa"},
            {"value":"ETB","name":"Ethiopian birr"},
            {"value":"EUR","name":"European Euro"},
            {"value":"FJD","name":"Fijian dollar"},
            {"value":"FKP","name":"Falkland Islands pound"},
            {"value":"GBP","name":"British pound"},
            {"value":"GEL","name":"Georgian lari"},
            {"value":"GHS","name":"Ghanaian cedi"},
            {"value":"GIP","name":"Gibraltar pound"},
            {"value":"GMD","name":"Gambian dalasi"},
            {"value":"GNF","name":"Guinean franc"},
            {"value":"GQE","name":"Central African CFA franc"},
            {"value":"GTQ","name":"Guatemalan quetzal"},
            {"value":"GYD","name":"Guyanese dollar"},
            {"value":"HKD","name":"Hong Kong dollar"},
            {"value":"HNL","name":"Honduran lempira"},
            {"value":"HRK","name":"Croatian kuna"},
            {"value":"HTG","name":"Haitian gourde"},
            {"value":"HUF","name":"Hungarian forint"},
            {"value":"IDR","name":"Indonesian rupiah"},
            {"value":"ILS","name":"Israeli new sheqel"},
            {"value":"INR","name":"Indian rupee"},
            {"value":"IQD","name":"Iraqi dinar"},
            {"value":"IRR","name":"Iranian rial"},
            {"value":"ISK","name":"Icelandic kr\u00f3na"},
            {"value":"JMD","name":"Jamaican dollar"},
            {"value":"JOD","name":"Jordanian dinar"},
            {"value":"JPY","name":"Japanese yen"},
            {"value":"KES","name":"Kenyan shilling"},
            {"value":"KGS","name":"Kyrgyzstani som"},
            {"value":"KHR","name":"Cambodian riel"},
            {"value":"KMF","name":"Comorian franc"},
            {"value":"KPW","name":"North Korean won"},
            {"value":"KRW","name":"South Korean won"},
            {"value":"KWD","name":"Kuwaiti dinar"},
            {"value":"KYD","name":"Cayman Islands dollar"},
            {"value":"KZT","name":"Kazakhstani tenge"},
            {"value":"LAK","name":"Lao kip"},
            {"value":"LBP","name":"Lebanese lira"},
            {"value":"LKR","name":"Sri Lankan rupee"},
            {"value":"LRD","name":"Liberian dollar"},
            {"value":"LSL","name":"Lesotho loti"},
            {"value":"LTL","name":"Lithuanian litas"},
            {"value":"LVL","name":"Latvian lats"},
            {"value":"LYD","name":"Libyan dinar"},
            {"value":"MAD","name":"Moroccan dirham"},
            {"value":"MDL","name":"Moldovan leu"},
            {"value":"MGA","name":"Malagasy ariary"},
            {"value":"MKD","name":"Macedonian denar"},
            {"value":"MMK","name":"Myanma kyat"},
            {"value":"MNT","name":"Mongolian tugrik"},
            {"value":"MOP","name":"Macanese pataca"},
            {"value":"MRO","name":"Mauritanian ouguiya"},
            {"value":"MUR","name":"Mauritian rupee"},
            {"value":"MVR","name":"Maldivian rufiyaa"},
            {"value":"MWK","name":"Malawian kwacha"},
            {"value":"MXN","name":"Mexican peso"},
            {"value":"MYR","name":"Malaysian ringgit"},
            {"value":"MZM","name":"Mozambican metical"},
            {"value":"NAD","name":"Namibian dollar"},
            {"value":"NGN","name":"Nigerian naira"},
            {"value":"NIO","name":"Nicaraguan c\u00f3rdoba"},
            {"value":"NOK","name":"Norwegian krone"},
            {"value":"NPR","name":"Nepalese rupee"},
            {"value":"NZD","name":"New Zealand dollar"},
            {"value":"OMR","name":"Omani rial"},
            {"value":"PAB","name":"Panamanian balboa"},
            {"value":"PEN","name":"Peruvian nuevo sol"},
            {"value":"PGK","name":"Papua New Guinean kina"},
            {"value":"PHP","name":"Philippine peso"},
            {"value":"PKR","name":"Pakistani rupee"},
            {"value":"PLN","name":"Polish zloty"},
            {"value":"PYG","name":"Paraguayan guarani"},
            {"value":"QAR","name":"Qatari riyal"},
            {"value":"RON","name":"Romanian leu"},
            {"value":"RSD","name":"Serbian dinar"},
            {"value":"RUB","name":"Russian ruble"},
            {"value":"SAR","name":"Saudi riyal"},
            {"value":"SBD","name":"Solomon Islands dollar"},
            {"value":"SCR","name":"Seychellois rupee"},
            {"value":"SDG","name":"Sudanese pound"},
            {"value":"SEK","name":"Swedish krona"},
            {"value":"SGD","name":"Singapore dollar"},
            {"value":"SHP","name":"Saint Helena pound"},
            {"value":"SLL","name":"Sierra Leonean leone"},
            {"value":"SOS","name":"Somali shilling"},
            {"value":"SRD","name":"Surinamese dollar"},
            {"value":"SYP","name":"Syrian pound"},
            {"value":"SZL","name":"Swazi lilangeni"},
            {"value":"THB","name":"Thai baht"},
            {"value":"TJS","name":"Tajikistani somoni"},
            {"value":"TMT","name":"Turkmen manat"},
            {"value":"TND","name":"Tunisian dinar"},
            {"value":"TRY","name":"Turkish new lira"},
            {"value":"TTD","name":"Trinidad and Tobago dollar"},
            {"value":"TWD","name":"New Taiwan dollar"},
            {"value":"TZS","name":"Tanzanian shilling"},
            {"value":"UAH","name":"Ukrainian hryvnia"},
            {"value":"UGX","name":"Ugandan shilling"},
            {"value":"USD","name":"United States dollar"},
            {"value":"UYU","name":"Uruguayan peso"},
            {"value":"UZS","name":"Uzbekistani som"},
            {"value":"VEB","name":"Venezuelan bolivar"},
            {"value":"VND","name":"Vietnamese dong"},
            {"value":"VUV","name":"Vanuatu vatu"},
            {"value":"WST","name":"Samoan tala"},
            {"value":"XAF","name":"Central African CFA franc"},
            {"value":"XCD","name":"East Caribbean dollar"},
            {"value":"XDR","name":"Special Drawing Rights"},
            {"value":"XOF","name":"West African CFA franc"},
            {"value":"XPF","name":"CFP franc"},
            {"value":"YER","name":"Yemeni rial"},
            {"value":"ZAR","name":"South African rand"},
            {"value":"ZMK","name":"Zambian kwacha"},
            {"value":"ZWR","name":"Zimbabwean dollar"},
            {"value":"RWF","name":"Indian railways"},
            {"value":"VES","name":"Venezuelan bolívar"},
            {"value":"STH","name":" Smartholdem"},
            {"value":"MZN","name":" Mozambican metical"},
            {"value":"ZMW","name":" Zambian kwacha"},
            {"value":"STN","name":" Goodness Sao-Tome and Principe"},
            {"value":"MRU","name":" Mauritanian ouguiya"},
        ],
        DashboardPushYear: null,
    },
    getters: {
        GetDashboardPushYear(state) {
            return state.DashboardPushYear;
        },
        getDashBoardPush(state) {
          return state.DashBoardPush;
        },
        getTimeForPaymentInstruction(state) {
            return state.timeForPaymentInstruction;
        },
        getShowMeTask(state) {
            return state.showMeTask;
        },
        getCurrencyList(state) {
            return state.listCurrency;
        }
    },
}
