<template>
  <div class="flex flex-col overflow-hidden h-full">
    <bread 
      class="bg-white"
      :allowOptions="false"
      :links="bread"
    />

    <div class="flex items-center p-x-6 border-b border-gray-light bg-white">
      <page-navigation :links="navigations"/>

      <router-link :to="`/ports/${$route.params.id}/archive`" class="m-l-auto m-r-6 fz0 svg-white-dart-stroke">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.12 9.96h1.6l-.96 9.28H5.96L5 9.96h1.76m11.36 0l.48-2.56h-2.08m1.6 2.56H6.76m0 0L6.12 7.4H8.2m0 0L7.72 5H17l-.48 2.4m-8.32 0h8.32m-6.56 5.92h4.8" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </router-link>

      <options
        v-if="canPermission.length"
        :options="canPermission"
        @editItem="onEdit"
        @duplicateItem="onDupl"
        @archiveItem="onArchive"
      />
    </div>

    <div class="h-full overflow-hidden" :class="{'position-relative z-100': $route.path.indexOf('emails') >= 0}" v-if="port">
      <router-view
        :vesselMode="true"
      ></router-view>
    </div>

    <add-modal 
      ref="add_modal"
      :account="{...port}"
      :isDupl="isDupl"
      :isShowModal="isShowModal"
      @on-close="isShowModal = false"
    />

    <archive-modal 
      :isShowModal="isShowArchiveModal"
      :ports="port"
      @on-close="isShowArchiveModal = false"
      @on-send="sendArchive"
    ></archive-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import bread from '@/components/reuse/bread'
import pageNavigation from '@/components/reuse/pageNavigation'
import options from '@/components/reuse/options'
import addModal from '@/components/ports/list/add'
import archiveModal from '@/components/ports/archive/modal'

export default {
  components: {
    bread,
    pageNavigation,
    options,
    addModal,
    archiveModal
  },
  data() {
    return {
      bread: [
        {name: 'Ports database', link: '/ports'},
        {name: 'Port', link: '/'},
      ],
      navigations: [
        {name: 'Details', link: 'detail'},
        {name: 'Berth List', link: 'berth'},
        {name: 'Contacts', link: 'contacts'},
        {name: 'Emails', link: 'emails'}

      ],

      isShowModal: false,
      isShowArchiveModal: false,
      isDupl: false,
      editPort: null
    }
  },
  computed: {
    ...mapState({
      port: state => state.ports.openPort
    }),
    ...mapGetters(['getPermissionsByType']),
    canPermission() {
      let arr = []
      if(this.getPermissionsByType('edit_ports')) arr.push('edit')
      if(this.getPermissionsByType('create_ports')) arr.push('duplicate')
      if(this.getPermissionsByType('archive_and_unarchive_ports')) arr.push('archive')
      return arr
    }
  },
  methods: {
    ...mapActions(['axiosGetPortById', 'axiosPortArchive']),
    ...mapMutations(['addTip', 'showLoad', 'hideLoad', 'setOpenPort']),
    onEdit() {
      this.isDupl = false
      this.isShowModal = true
    },
    onDupl() {
      this.isDupl = true
      this.isShowModal = true
    },
    onArchive() {
      this.isShowArchiveModal = true
    },
    async sendArchive() {
      let obj = {
        ids: [this.port.id],
        is_archived: true
      }
      
      await this.axiosPortArchive(obj)
      this.addTip( this.port.name+' is archived')
      this.isShowArchiveModal = false
      this.$router.push(`/ports`)
    }
  },
  async beforeMount() {
    this.setOpenPort(null)
    this.showLoad()
    await this.axiosGetPortById(this.$route.params.id)
    this.bread[1].name = this.port.name
    this.hideLoad()
  },
}
</script>

<style>

</style>