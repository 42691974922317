<template>
  <div class="p-t-4" :class="{'map-view-top': !mapView}">
    <div class="top-panel m-b-4">
      <h1 class="fw500">Create Route</h1>
      <p class="fz14 fw600 text-gray ml24">Total Dist: <span class="fw400 text-dark">{{totalDist | formatNumberTwo}}</span></p>
      <p class="fz14 fw600 text-gray ml24">Total SECA: <span class="fw400 text-dark">{{totalSeca | formatNumberTwo}}</span></p>
      <p class="fz14 fw600 text-gray ml-auto">Bypass ECA</p>
      <select-white class="ml8"
                    :allow-empty="false"
                    :list="secaBypassOptionList"
                    v-model="secaBypassOption"
                    :watchKey="'option'"
                    :small-list="true"
      ></select-white>
      <select-white class="ml8"
                    v-if="secaBypassOption && secaBypassOption.option === 'optimized' && false"
                    :allow-empty="false"
                    :list="secaBypassOptionList"
                    v-model="secaCostRation"
                    :watchKey="'option'"
                    :small-list="true"
      ></select-white>
      <p class="fz14 fw600 text-gray ml8">Piracy options</p>
      <select-white class="ml8"
                    :allow-empty="false"
                    :list="piracyCodes"
                    v-model="piracyActive"
                    :watchKey="'code'"
                    :watchKey2="'description'"
      ></select-white>
      <canal-code class="ml48" :key="$route.path" v-model="codeCanal"></canal-code>
      <div class="map-view ml48">
        <button :class="{'active-button' : mapView}" v-on:click="mapView = true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6 17L21.6 6.5C21.6 6.16863 21.3314 5.9 21 5.9L13 5.9C12.6686 5.9 12.4 6.16863 12.4 6.5L12.4 17C12.4 17.3314 12.6686 17.6 13 17.6L21 17.6C21.3314 17.6 21.6 17.3314 21.6 17ZM23 6.5C23 5.39543 22.1046 4.5 21 4.5L13 4.5C11.8954 4.5 11 5.39543 11 6.5L11 17C11 18.1045 11.8954 19 13 19L21 19C22.1046 19 23 18.1046 23 17L23 6.5Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5.75C9 5.33579 8.66421 5 8.25 5L2.75 5C2.33579 5 2 5.33579 2 5.75C2 6.16421 2.33579 6.5 2.75 6.5L8.25 6.5C8.66421 6.5 9 6.16421 9 5.75Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 9.75C9 9.33579 8.66421 9 8.25 9L2.75 9C2.33579 9 2 9.33579 2 9.75C2 10.1642 2.33579 10.5 2.75 10.5L8.25 10.5C8.66421 10.5 9 10.1642 9 9.75Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13.75C9 13.3358 8.66421 13 8.25 13L2.75 13C2.33579 13 2 13.3358 2 13.75C2 14.1642 2.33579 14.5 2.75 14.5L8.25 14.5C8.66421 14.5 9 14.1642 9 13.75Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 17.75C9 17.3358 8.66421 17 8.25 17L2.75 17C2.33579 17 2 17.3358 2 17.75C2 18.1642 2.33579 18.5 2.75 18.5L8.25 18.5C8.66421 18.5 9 18.1642 9 17.75Z" fill="#9AA3B0"/>
          </svg>
        </button>
        <button :class="{'active-button' : !mapView}" v-on:click="mapView = false">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19 5.4H5C4.66863 5.4 4.4 5.66863 4.4 6V10C4.4 10.3314 4.66863 10.6 5 10.6H19C19.3314 10.6 19.6 10.3314 19.6 10V6C19.6 5.66863 19.3314 5.4 19 5.4ZM5 4C3.89543 4 3 4.89543 3 6V10C3 11.1046 3.89543 12 5 12H19C20.1046 12 21 11.1046 21 10V6C21 4.89543 20.1046 4 19 4H5Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 14C3.33579 14 3 14.3358 3 14.75C3 15.1642 3.33579 15.5 3.75 15.5H20.25C20.6642 15.5 21 15.1642 21 14.75C21 14.3358 20.6642 14 20.25 14H3.75Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 18C3.33579 18 3 18.3358 3 18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75C21 18.3358 20.6642 18 20.25 18H3.75Z" fill="#9AA3B0"/>
          </svg>
        </button>

      </div>
    </div>
    <div class="wrap">
      <div class="wrap-list" :class="{'overflow-list-route': mapView}">
        <div class="bg-white shadow rounded-lg border border-dark-gray relative">
          <div class="blocked-changes" v-if="$route.path.indexOf('archive') >= 0 || (getLoginData && activeCreator && activeCreator.id !== getLoginData.data.user.id)"></div>
          <calc-route-table-head/>
          <calc-route-table-empty direction="top" @on-add="(port)=>onAddRoute(0, port)"/>
          <draggable
              v-if="routes.length"
              v-model="routes"
              handle=".handle"
              @change="onUpdateDrag"
          >
            <calc-route-table-row
                v-for="(row, index) in routes"
                :key="index"
                :route="row"
                @on-add="onAddRoute(index+1)"
                @on-delete="onDeleteRoute(index)"
                @on-select-port="preLoadMap"
                @changeHasSeca="preLoadMap"
            />

          </draggable>
          <calc-route-table-empty direction="bottom" @on-add="(port)=>onAddRoute(routes.length, port)"/>
        </div>

        <div class="p-t-6"></div>
        <app-loader :is-local="true" v-if="isLoad" class="loader-change"></app-loader>
      </div>
      <div class="wrap-map">
        <map-stream ref="mapStream" @changeSecaDistance="(e)=>{totalSeca = e}" @changeTotalDistance="(e)=>{totalDist = e}" :render="mapView" class="map-component"></map-stream>
      </div>
    </div>
<!--    <button v-on:click="preLoadMap">LoadMap</button>-->
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import draggable from 'vuedraggable'
import CalcRouteTableHead from '@/components/calc/calcRouteTableHead'
import CalcRouteTableEmpty from '@/components/calc/calcRouteTableEmpty'
import CalcRouteTableRow from '@/components/calc/calcRouteTableRow'
import AppLoader from "@/components/loader";
import MapStream from "@/views/map/mapStream";
import SelectWhite from "@/components/select-white";
import CanalCode from "@/components/canal-code";
export default {
  props: {
  },
  components: {
    CanalCode,
    SelectWhite,
    MapStream,
    AppLoader,
    draggable,
    CalcRouteTableHead,
    CalcRouteTableEmpty,
    CalcRouteTableRow
  },
  data() {
    return {
      mapView: true,
      codeCanal: null,
      piracyCodes: [
        {code: '000', description: 'The latest update of piracy area. Identified with code 009 as of\n' +
              'April, 2021'},
        {code: '001', description: 'The shortest path '},
        {code: '002', description: '250nm outer route from Somalia east coast'},
        {code: '003', description: '600NM outer route from Somalia east coast'},
        {code: '004', description: 'JWLA015(2nd Aug 2010)'},
        {code: '005', description: 'JWLA016(16th Dec 2010)'},
        {code: '006', description: 'JWLA016 Up to Mumbai'},
        {code: '007', description: 'JWLA016 Max Avoid Route'},
        {code: '008', description: 'JWLA023(14th Jun 2018)'},
        {code: '009', description: 'JWLA027(29th April 2021)'},
      ],
      piracyActive: {code: '001', description: 'The shortest path '},
      secaBypassOption: {option: 'normal', description: ''},
      secaCostRation: {option: 'normal', description: ''},
      secaBypassOptionList: [
        {option: 'none', description: ''},
        {option: 'normal', description: ''},
        {option: 'shortest', description: ''},
        // {option: 'optimized', description: ''},
      ],
      isLoad: false,
      timeout: null,
      routes: [],
      copyRoutes: [],
      port: null,
      emptyRoute: {
        tab: null,
        ordering_index: 0,
        port: null,
        from_port: null,
        status: 'passing',
        dist: null,
        has_seca: false,
        seca: null
      },
      vessels: [],
      totalSeca: 0,
      totalDist: 0,
      timeoutLoadMap: null,
    }
  },
  watch: {
    routes: {
      deep: true,
      handler(newVal, oldVal) {
        if(oldVal.length == 0) return false
        if(this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.onPatchRoutes()
        }, 1000)
      }
    },
    piracyActive: function () {
      localStorage.setItem(this.$route.params.id+'piracyActive', JSON.stringify(this.piracyActive))
      this.preLoadMap();
    },
    codeCanal: function() {
      this.preLoadMap();
    },
    secaBypassOption: function () {
      localStorage.setItem(this.$route.params.id+'secaBypassOption', JSON.stringify(this.secaBypassOption))
      this.preLoadMap();
    },
    secaCostRation: function () {
      localStorage.setItem(this.$route.params.id+'secaCostRation', JSON.stringify(this.secaCostRation))
      this.preLoadMap();
    },
    mapView: function () {
      localStorage.setItem(this.$route.params.id+'mapView', JSON.stringify(this.mapView))
    },
  },
  computed: {
    ...mapState({
      activeCreator: state => state.tabs.activeCreator,
    }),
    ...mapGetters(['getLoginData']),
  },
  methods: {
    ...mapActions(['axiosPatchCalcVessel', 'axiosGetCalcRoute', 'axiosGetCalcVessel', 'axiosDeleteCalcRoute', 'axiosPatchCalcRoute', 'axiosPostCalcRoute', 'axiosGetRouteDistance']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    preLoadMap() {
      if(!this.routes.length) {
        return null;
      }
      if(this.timeoutLoadMap) clearTimeout(this.timeoutLoadMap)
      this.timeoutLoadMap = setTimeout(() => {
        this.getDistanceForRoutes()
        this.loadMap()
      }, 1000)
    },
    loadMap() {
      console.log('this.routes', this.routes)
      let error = false
      this.routes.forEach(a=> {
        if(!a.port) {
          error = true;
          return null;
        }
      })
      if(error) {
        this.addTip('All ports/canals must be filled')
        return null;
      }
      let portsForAxios = []
      this.routes.forEach((rout, index)=> {
        if(index !== 0) {
          portsForAxios.push({from_port: this.routes[index-1].port.name, to_port: this.routes[index].port.name, has_seca: this.routes[index].has_seca ? 'True' : 'False'})
        }
      })
      console.log(portsForAxios)
      this.$refs.mapStream.getDistanceRouter({ seca_cost_ratio: this.secaCostRation ? this.secaCostRation.option : null, seca_bypass_option: this.secaBypassOption ? this.secaBypassOption.option: null, ports: portsForAxios, piracyOption: this.piracyActive ? this.piracyActive.code : null, canalPassCode: this.codeCanal})
    },
    async loadRoutes() {
      if(localStorage.getItem(this.$route.params.id+'secaBypassOption') && localStorage.getItem(this.$route.params.id+'secaBypassOption') !== undefined && localStorage.getItem(this.$route.params.id+'secaBypassOption') !== null) {
        this.secaBypassOption = JSON.parse(localStorage.getItem(this.$route.params.id+'secaBypassOption'))
      } else {
        this.secaBypassOption = {option: 'normal', description: ''}
      }
      if(localStorage.getItem(this.$route.params.id+'secaCostRation') && localStorage.getItem(this.$route.params.id+'secaCostRation') !== undefined && localStorage.getItem(this.$route.params.id+'secaCostRation') !== null) {
        this.secaCostRation = JSON.parse(localStorage.getItem(this.$route.params.id+'secaCostRation'))
      } else {
        this.secaCostRation = {option: 'normal', description: ''}
      }
      if(localStorage.getItem(this.$route.params.id+'piracyActive') && localStorage.getItem(this.$route.params.id+'piracyActive') !== undefined && localStorage.getItem(this.$route.params.id+'piracyActive') !== null) {
        this.piracyActive = JSON.parse(localStorage.getItem(this.$route.params.id+'piracyActive'))
      } else {
        this.piracyActive = {code: '001', description: 'The shortest path '}
      }
      if(localStorage.getItem(this.$route.params.id+'mapView') && localStorage.getItem(this.$route.params.id+'mapView') !== undefined && localStorage.getItem(this.$route.params.id+'mapView') !== null) {
        this.mapView = JSON.parse(localStorage.getItem(this.$route.params.id+'mapView'))
      } else {
        this.mapView = true;
      }
      this.totalDist = 0;
      this.totalSeca = 0
      this.showLoad()
      let routes = await this.axiosGetCalcRoute(this.$route.params.id)
      if(routes?.length) {
        this.routes = routes
        this.copyRoutes = JSON.parse(JSON.stringify(routes))
        this.vessels = await this.axiosGetCalcVessel(this.$route.params.id)
        this.preLoadMap()
      }
      else {
        this.copyRoutes = []
        this.routes = []
        this.vessels = []
      }
      this.hideLoad()
    },
    onPatchRoutes() {
      this.isLoad = true
      let patchRoutes = JSON.parse(JSON.stringify(this.routes))
      patchRoutes.forEach(el => {
        if(el.port) el.port = el.port.id
      });
      this.axiosPatchCalcRoute(patchRoutes)
      this.onPatchVessels()
    },
    onPatchVessels() {
      let arrayIndexesNow = [];
      if(this.vessels.length !== 0) {
        this.routes.forEach(item=> {
          let index = this.vessels[0].additional_info.routes.findIndex(a=> item.id === a.route_id)
          if(index && index>=0) {
            arrayIndexesNow.push(index)
          }
        })
      }
      let sortIndexes = (JSON.parse(JSON.stringify(arrayIndexesNow))).sort((a, b)=> a-b)
      this.vessels.forEach(vessel=> {
        if(vessel.additional_info?.routes) {
          arrayIndexesNow.forEach((index, i)=> {
            vessel.additional_info.routes[index]['ordering'] = sortIndexes[i]
          })
          vessel.additional_info.routes.sort((a, b)=> a.ordering-b.ordering)
        }
      })

      let savedVessels = JSON.parse(JSON.stringify(this.vessels))
      let bool = false
      if(''+sortIndexes !== ''+arrayIndexesNow) {
        bool = true
      }
        savedVessels.forEach(vessel => {
          if(vessel.additional_info?.routes) {
            vessel.additional_info.routes.forEach(item=> {
              let data = this.routes.find(a=> a.id === item.route_id)
              // let dataIndex = this.routes.findIndex(a=> a.id === item.route_id)
              let dataCopy = this.copyRoutes.find(a=> a.id === item.route_id)
              if(data && dataCopy) {
                // console.log(`port ${data.port?.id} id: ${this.copyRoutes[dataIndex].port?.id}`)
                if(data.port?.id !== dataCopy.port?.id) {
                  item.port = data.port;
                  bool = true
                }
                // console.log(`status: ${data.status} ${dataCopy.status}`)
                if(data.status !== dataCopy.status) {
                  item.status = data.status;
                  bool = true
                }
                // console.log(`dist ${}`)
                if(data.dist-data.seca !== dataCopy.dist) {
                  item.dist = data.dist - data.seca;
                  bool = true
                }
                if(data.seca !== dataCopy.seca) {
                  item.seca = data.seca;
                  bool = true
                }
                if(data.has_seca !== dataCopy.has_seca) {
                  item.has_seca = data.has_seca;
                  bool = true
                }
              }
            })
          }
        })
      console.log('savedVessels', savedVessels)
      if(bool) {
        this.axiosPatchCalcVessel(savedVessels).then(()=> {
          this.isLoad = false
          this.addTip('Data saved')
          this.onUpdateDrag()
        })
      } else {
        this.isLoad = false
        this.addTip('Data saved')
      }
    },
    onUpdateDrag(type) {
      if(type.moved){
        for (let i = 0; i < this.routes.length; i++) {
          let el = this.routes[i];
          el.ordering_index = i
        }
        this.preLoadMap()
        // this.getDistanceForRoutes()
      }
    },
    async onDeleteRoute(index) {
      let deletedItem = this.routes[index]
      if(!deletedItem) return false
      let response = await this.axiosDeleteCalcRoute(deletedItem.id)
      if(!response) return false
      this.routes.splice(index, 1)
      this.onUpdateDrag({moved:true})
    },
    async onAddRoute(index, port) {
      let route = {...this.emptyRoute}
      route.tab = +this.$route.params.id
      if(port) route.port = port.id
      let newRoute = await this.axiosPostCalcRoute(route)
      if(!newRoute) return false
      this.routes.splice(index, 0, newRoute)
      this.onUpdateDrag({moved:true})
    },
    async getDistanceForRoutes() {
      if(!this.routes.length) return false

      this.routes[0].dist = 0
      this.routes[0].seca = 0
      this.routes[0].has_seca = false
      this.routes[0].from_port = null

      for (let i = 1; i < this.routes.length; i++) {
        const prevEl = this.routes[i-1];
        const el = this.routes[i];
        // if(el.from_port == prevEl.port?.name) continue
        if(!prevEl.port?.name || !el.port?.name) continue
        // console.log('getDistanceForRoutes')
        let distance = await this.axiosGetRouteDistance({
          // from: prevEl.port?.name,
          // to: el.port?.name
          seca_cost_ratio: this.secaCostRation ? this.secaCostRation.option : null,
          seca_bypass_option: this.secaBypassOption ? this.secaBypassOption.option: null,
          piracy_option: this.piracyActive ? this.piracyActive.code : null,
          canal_pass_code: this.codeCanal,
          from_port: prevEl.port?.name,
            to_port: el.port?.name,
            use_local_eca: el.has_seca ? 'True' : 'False'
        })
        console.log('distance Seca', distance.cross_seca)


        el.dist = distance?.status == 400 ? 0 : distance.total_distance - distance.total_seca_distance
        el.seca = distance?.status == 400 ? 0 : distance.total_seca_distance
        el.has_seca = distance?.status == 400 ? false : distance.section[0].to_port.seca_port
        if(!(distance?.status == 400) && i === 1) {
          this.routes[i-1].has_seca = distance?.status == 400 ? false : distance.section[0].from_port.seca_port
        }
        // if(el.has_seca) {
        //   this.routes[i-1].has_seca = distance?.status == 400 ? false : distance.cross_seca
        //   // console.log(1, this.routes[i-1] )
        // }
        el.from_port = prevEl.port?.name
      }
    }
  },
  beforeMount() {
    console.log(this.$route.params)
    this.loadRoutes()
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.loadRoutes()
  }
}
</script>

<style lang="scss">
.calc_routes_table{
  display: grid;
  grid-template-columns: 
    2.2003%
    2.2003%
    28.5281%
    15.1745%
    24.2792%
    25.0379%
    2.5797%;

  .multiselect__tags {
    min-height: 28px;
    padding-top: 4px;
    padding-right: 20px;
    padding-left: 4px;
    border: 0;
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
  }
  .async_select__wrap .multiselect__single {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .multiselect, 
  .multiselect__input, 
  .multiselect__single{
    font-size: 14px;
  }
  .multiselect__input, 
  .multiselect__single{
    margin-bottom: 4px;
  }
}
</style>
<style lang="scss" scoped>
  .loader-change {
    position: fixed;
    top: auto;
    left: auto;
    right: 24px;
    bottom: 24px;
    height: 34px;
    width: 34px;
    background-color: transparent !important;
  }
  .top-panel {
    align-items: center;
    display: flex;
  }
  .wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .wrap-list {
    width: calc(100% - 560px - 16px);
  }
  .wrap-map {
    width: 560px;
    //height: calc(100vh - 152px);
    /deep/ {
      .wrap-page {
        z-index: 1;
      }
    }
  }
  .map-component {
    height: calc(100vh - 180px);

  }
  .map-view {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-blue-dark);
    border-radius: 4px;
    overflow: hidden;
    width: 72px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      width: 50%;
    }
    .active-button {
      background-color: var(--color-blue-dark);
      svg {
        path {
          fill: #ffffff
        }
      }
    }
  }
  .overflow-list-route {
    height: calc(100vh - 180px);
    padding-left: 2px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .map-view-top {
    .wrap-list {
      width: 100%;
    }
    position: relative;
    .top-panel {
      margin-top: 408px;
    }
    .wrap-map {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0px;
      height: 392px;
      /deep/ {
        .map-component {
          height: 392px !important;
        }
      }
    }
  }
</style>