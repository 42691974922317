<template>
  <modal-slots
    :is-pointer-bg="false"
    :no-close-after-apply="true"
    :show-mod="show"
    @close="closeModal"
    class="modal-edit-clean"
    @apply="sendForm"
    :textForSecondButton="eventID ? 'Save' : 'Create'"
  >
    <template v-slot:title>
      <p class="fz18 fw500" v-if="eventID">Edit Personal Event</p>
      <p class="fz18 fw500" v-else>Create Personal Event</p>
    </template>
    <div class="modal-edit-clean-content create-event-wrapper">
      <div class="block-from-inputs p-b-3">
        <p class="fz13 text-gray m-b-1">
          Event Name <span class="text-red">*</span>
        </p>
        <input
          type="text"
          class="h36"
          :class="{ error: $v.$dirty && $v.newEvent.name.$invalid }"
          maxlength="100"
          v-model="newEvent.name"
        />
      </div>
      <div class="three-inputs p-b-3">
        <div class="block-input-wrapper">
          <p class="fz13 text-gray m-b-1">
            Date <span class="text-red">*</span>
          </p>
          <date-picker
            v-model="newEvent.date"
            class="datepick"
            :class="{ error: $v.$dirty && $v.newEvent.date.$invalid }"
            ref="datePickDue"
            format="DD.MM.YYYY"
            @change="clearNotification"
          >
          </date-picker>
        </div>
        <div class="block-input-wrapper">
          <p class="fz13 text-gray m-b-1">
            From <span class="text-red">*</span>
          </p>
          <div class="customSelect-wrapper time-wrapper">
            <date-picker
              :popup-class="'my-time-picker'"
              v-model="newEvent.from_time"
              :time-picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45',
              }"
              :hour-options="hours"
              format="HH:mm"
              value-type="format"
              type="time"
              :class="{ error: $v.$dirty && $v.newEvent.from_time.$invalid }"
              @change="changeTimeFrom($event)"
            ></date-picker>
          </div>
        </div>
        <div class="block-input-wrapper time-wrapper">
          <p class="fz13 text-gray m-b-1">To <span class="text-red">*</span></p>
          <div class="customSelect-wrapper" :class="{disabled: !newEvent.from_time || newEvent.from_time == '23:45'}">
            <date-picker
              :popup-class="'my-time-picker'"
              v-model="newEvent.to_time"
              :hour-options="hours"
              :time-picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45',
              }"
              format="HH:mm"
              value-type="format"
              type="time"
              :disabled-time="notBeforeFromTime"
              :default-value="setTimeFrom"
              :class="{ error: $v.$dirty && $v.newEvent.to_time.$invalid }"
              
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="block-from-inputs p-b-3">
        <p class="fz13 text-gray m-b-1">Event Type</p>
        <div class="customSelect-wrapper">
          <multiselect
            v-model="newEvent.type"
            :options="eventTypeList"
            @input="($event) => (newEvent.type = $event)"
            :show-labels="false"
            :class="{ error: $v.$dirty && $v.newEvent.type.$invalid }"
          >
            <template slot="option" slot-scope="props">
              <p class="option__name">{{ props.option }}</p>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="block-from-inputs p-b-3">
        <p class="fz13 text-gray m-b-1">Repeat</p>
        <calendarRepeatSelect
          ref="calendarRepeatSelect"
          :class="{disabled: !newEvent.date || (!!editParentEvent && !editAllEvents)}"
          :date="newEvent.date"
          :defaultValue="{
            repeatOption: newEvent.repeat,
            repeatDays: newEvent.days_for_repeat,
          }"
          @change="setRepeatOption($event)"
        ></calendarRepeatSelect>
      </div>
      <div class="block-from-inputs p-b-3">
        <span class="fz13 text-gray m-b-1">Timezone</span>
        <div class="customSelect-wrapper">
          <multiselect
            v-model="selectedTimezone"
            :options="timezone.timezoneList"
            :show-labels="false"
            
          >
            <template slot="option" slot-scope="props">
              <p class="option__name">{{ props.option.timeZoneInfo }}</p>
            </template>
          </multiselect>
        </div>
        
      </div>
      <div class="radio-buttons-block p-b-3">
        <p class="fz13 text-gray">Display to</p>
        <div class="radio-block">
          <label class="radio-wrapper">
            <input
              v-model="newEvent.display_to"
              type="radio"
              value="Everyone"
              name="display_to"
            />
            <span></span>
            <p class="fz14 text-dark1">Everyone</p>
          </label>
          <label class="radio-wrapper">
            <input
              v-model="newEvent.display_to"
              value="Only to myself"
              type="radio"
              name="display_to"
            />
            <span></span>
            <p class="fz14 text-dark1">Only to myself</p>
          </label>
        </div>
      </div>
      <div
        class="members-search flex flex-col"
        :class="{ disabled: !displayTo }"
      >
        <span class="fz13 text-gray m-b-1">Members</span>
        <myCustom-select
          :valueList="members.membersList"
          :defaultValue="members.selectedMembers"
          @selected="selectMember($event)"
          :clear="clearSelected"
        ></myCustom-select>
      </div>
      <div class="members-list">
        <div
          class="member-item"
          v-for="member in members.selectedMembers"
          :key="member.id"
        >
          <div class="avatar" v-if="!member.avatar"></div>
          <div class="avatar" v-else :style="{ 'background-image': `url(${member.avatar})` }"></div>
          <div class="member-info">
            <span class="fz13 text-dark1">{{ member.name }}</span>
            <div class="fz13 text-gray">
              Can accept an invitation to:

              <label class="accept-date-wrapper" v-if="!member.acceptDate">
                <date-picker
                  class="datepick"
                  format="DD.MM.YYYY"
                  @change="setAcceptDate($event, member.id)"
                >
                </date-picker>
                <span class="date">Choose Date</span>
              </label>
              <div class="accept-date-wrapper" v-else>
                <span class="date-num">
                  {{ member.acceptDate.date }}
                  <span class="del-btn" @click="delAcceptDate(member.id)">
                    <svg
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                        fill="#2C2C2C"
                      />
                      <path
                        d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                        fill="#2C2C2C"
                      />
                    </svg>
                  </span>
                </span>
              </div>
              <div class="accept-time-wrapper" v-if="!member.acceptTime">
                <span class="date" @click="showTimelist(member.id)"
                  >Choose Time</span
                >
              </div>
              <div class="accept-time-wrapper" v-else>
                <span class="date-num">
                  {{ member.acceptTime.time }}
                  <span class="del-btn" @click="delAcceptTime(member.id)">
                    <svg
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                        fill="#2C2C2C"
                      />
                      <path
                        d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                        fill="#2C2C2C"
                      />
                    </svg>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <span class="del-btn" @click="delSelectedMember(member.id)">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                fill="#2C2C2C"
              />
              <path
                d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                fill="#2C2C2C"
              />
            </svg>
          </span>
        </div>
        <multiselect
          ref="timelistSelect"
          class="timelistSelect"
          open-direction="top"
          :show-labels="false"
          :options="timeList"
          :multiple="false"
          :searchable="false"
          :allow-empty="false"
          :hide-selected="true"
          :max-height="150"
          :max="10"
          @input="setAcceptTime($event)"
          @close="editMemberid = null"
        ></multiselect>
      </div>
      <div class="block-from-inputs p-b-3">
        <p class="fz12 text-gray m-b-1">Event tag</p>
        <tag-select
          @deleteUsageTag="deleteTagWhichUsed"
          @tagDeleted="tagDeleted($event)"
          ref="tagsSel"
          :defaultTags="tagsSelectedDefault"
          @emitGetTags="setTags"
          :clear="clearSelected"
          class="tagsSel"
        ></tag-select>
      </div>
      <modal-delete-tag ref="modalDeleteTag"></modal-delete-tag>
      <div class="members-search flex flex-col block-from-inputs p-b-3">
        <span class="fz13 text-gray m-b-1">Counterparty</span>
        <div class="my-custom-select-wrapper">
          <multiselect
            v-model="counterpartyes.selectedCounterpartyes"
            label="company_name"
            track-by="company_name"
            :options="counterpartyes.counterpartyesList"
            :multiple="true"
            :taggable="false"
            :limit="1"
            :limit-text="limitText"
            :show-labels="false"
            @input="selectCounterparty($event)"
          >
            <template slot="tag" slot-scope="{ option }">
              <span class="selected">
                <span>{{ option.company_name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__name">
                {{ props.option.company_name }}
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="block-from-inputs p-b-3">
        <span class="fz13 text-gray m-b-1">Vessels</span>
        <div class="customSelect-wrapper">
          <multiselect
            v-model="vessels.selectedVessels"
            :options="vessels.vesselsList"
            :show-labels="false"
            label="name"
            track-by="name"
          >
            <template slot="option" slot-scope="props">
              <p class="option__name">{{ props.option.name }}</p>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="block-from-inputs p-b-3">
        <p class="fz12 text-gray m-b-1">Comment</p>
        <textarea v-model="newEvent.comment" class="comment-area"></textarea>
      </div>
      <div class="block-from-inputs p-b-3" v-if="false" :class="{disabled: !newEvent.date}" data-title="The date field is required to fill ">
        <p class="fz12 text-gray m-b-1">Notifications</p>
        <div class="notifications-list">
          <div
            class="notifications__item"
            v-for="(notification, index) in newEvent.notifications"
            :key="index"
          >
            <customSelect
              class="invisible-select"
              :options="notificatiionsListFiltered"
              :selectedValue="notification"
              @selected="($event) => (newEvent.notifications[index] = $event)"
            ></customSelect>
            <span
              class="del-btn"
              @click="newEvent.notifications.splice(index, 1)"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                  fill="#2C2C2C"
                />
                <path
                  d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
          </div>
        </div>
        <span class="notification-btn text-blue fz13" @click="addNotification">
          <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.76471 16.2H6.23529C6.23529 16.3733 6.25963 16.5411 6.30499 16.7C6.40721 17.0581 6.61618 17.371 6.89399 17.6C7.19712 17.8499 7.58221 18 8 18C8.41779 18 8.80288 17.8499 9.10601 17.6C9.38382 17.371 9.59279 17.0581 9.69501 16.7C9.74037 16.5411 9.76471 16.3733 9.76471 16.2ZM15.5 15.3V14.4L13.7353 12.6V7.65C13.7353 4.86 11.8824 2.61 9.32353 1.98V1.35C9.32353 0.63 8.70588 0 8 0C7.29412 0 6.67647 0.63 6.67647 1.35V1.98C4.11765 2.61 2.26471 4.86 2.26471 7.65V12.6L0.5 14.4V15.3H15.5ZM2.95078 13.9H13.0492L12.3353 13.1718V7.65C12.3353 5.515 10.9325 3.81796 8.98883 3.3394L8 3.09595L7.01117 3.3394C5.06747 3.81795 3.66471 5.515 3.66471 7.65V13.1718L2.95078 13.9Z"
              fill="#319FEF"
            />
          </svg>
          Add notification
        </span>
      </div>
    </div>
  </modal-slots>
</template>
<script>
import ModalSlots from "@/components/modal/modal-slots";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import customSelect from "@/components/calendar/customSelect";
import TagSelect from "@/components/calendar/tagSelect";
import ModalDeleteTag from "@/components/tasks/modalDeleteTag";
import { mapActions, mapMutations, mapGetters } from "vuex";
import moment from "moment";
// import timezones from "timezones.json";
import timezones from "@/assets/timezones.json";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import calendarRepeatSelect from "@/components/calendar/calendarRepeatSelect";
export default {
  props: ["show", "eventID", "editParentEvent", "isClonedEvent", "editAllEvents"],
  mixins: [validationMixin],
  components: {
    DatePicker,
    Multiselect,
    ModalSlots,
    customSelect,
    TagSelect,
    ModalDeleteTag,
    calendarRepeatSelect,
  },
  data() {
    return {
      hours: Array.from({ length: 24 }).map((_, i) => i ),
      counterpartyes: {
        selectedCounterpartyes: [],
        counterpartyesList: [],
      },
      members: {
        selectedMembers: [],
        membersList: [],
      },
      vessels: {
        selectedVessels: [],
        vesselsList: [],
      },
      timezone: {
        selectedTimezone: null,
        timezoneList: [],
        timezone_info: null
      },
      editMemberid: null,
      timeList: [],
      eventTypeList: [
        "Meeting",
        "Business trip",
        "Weekends",
        "Birthday",
        "Vacation",
      ],
      notificatiionsList: [
        "5 min before start",
        "15 min before start",
        "30 min before start",
        "1 hour before start",
        "2 hours before start",
        "5 hours before start",
        "1 day before start",
        "2 days before start",
        "5 days before start",
        "7 days before start",
      ],
      newEvent: {
        name: "",
        date: null,
        from_time: null,
        to_time: null,
        type: "Meeting",
        repeat: "Do no repeat",
        days_for_repeat: [],
        timezone: null,
        display_to: "Everyone",
        comment: null,
        notifications: [],
        vessel: null,
        tags: [],
        counterparties: [],
        end_repeat_day: null
      },
      tagsSelectedDefault: null,
      clearSelected: false,
      showRepeatOptionDatePicker: false,
      editInfo: null
    };
  },
  validations() {
    return {
      newEvent: {
        date: { required },
        from_time: { required },
        to_time: { required },
        name: { required },
        repeat: { required },
        type: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      "axiosGetCalendarMembersList",
      "axiosGetCalendarClientsList",
      "axiosGetCalendarVesselsList",
      "axiosCreateCalendarEvent",
      "axiosGetCalendarEventById",
      "axiosUpdateCalendarEvent",
      
    ]),
    ...mapMutations(["showLoad", "hideLoad"]),
    ...mapGetters(["getCalendarMembersList"]),
    notBeforeFromTime(date){
      // Заблокировать пердыдущее время для выбора
      if(this.newEvent.from_time){
        let fromTime = this.newEvent.from_time.split(':');
        let min = fromTime[1];
        let hour = fromTime[0];
        if(['00', '15', '30'].includes(min)){
          min = (+min + 15) + '';
        }else if(min == 45){
          hour = (+hour + 1) + '';
          min = '00'
        }
        return date < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), hour.toString(), min.toString());
      }
    },
    changeTimeFrom(time){
      // Автовыставление to_time
      if(time){
        let fromTime = time.split(':');
        let min = fromTime[1];
        let hour = fromTime[0];
        if(['00', '15', '30'].includes(min)){
          min = (+min + 15) + '';
        }else if(min == 45){
          hour = (+hour + 1) + '';
          min = '00'
        }
        
        if(hour == '23' && min == '45'){
          this.newEvent.to_time = '00:00'
        }
        this.newEvent.to_time = `${hour.padStart(2, '0')}:${min}`
      }else{
        this.newEvent.to_time = null
      }
    },
    clearNotification(){
      this.newEvent.notifications = [];
      this.newEvent.repeat = 'Do no repeat'
    },
    closeModal() {
      this.$v.$reset();
      this.clearForm();
      this.$emit("close", false);
    },
    fillTimeList() {
      for (var i = 0; i < 24; i++) {
        if (i < 10) {
          this.timeList.push(`0${i}:00`);
          this.timeList.push(`0${i}:15`);
          this.timeList.push(`0${i}:30`);
          this.timeList.push(`0${i}:45`);
        } else {
          this.timeList.push(`${i}:00`);
          this.timeList.push(`${i}:15`);
          this.timeList.push(`${i}:30`);
          this.timeList.push(`${i}:45`);
        }
      }
    },
    limitText(count) {
      return `+ ${count}`;
    },
    setDefaultTimezone(){
      let currentTimeZone = moment(new Date()).format('Z');
      let tz = this.timezone.timezoneList.find(tz => tz.timezone == currentTimeZone);
      this.timezone.selectedTimezone = currentTimeZone;
      this.timezone.timezone_info = tz.timeZoneInfo;
    },
    async getMembers() {
      if (this.members.membersList.length == 0) {
        // let members = await this.axiosGetCalendarMembersList();
        let currentUser = JSON.parse(localStorage.getItem("userData"));
        let members;
        let storeMembers = this.getCalendarMembersList();
        if (storeMembers) {
          members = JSON.parse(JSON.stringify(storeMembers));
        } else {
          members = await this.axiosGetCalendarMembersList('create modal');
        }
        this.members.membersList = members.results.filter(el => el.id != currentUser.data.user.id);
      }
    },
    selectMember(selectedMembers) {
      this.members.selectedMembers = selectedMembers;
    },

    async getCounterpartyes() {
      if (this.counterpartyes.counterpartyesList.length == 0) {
        let clients = await this.axiosGetCalendarClientsList();
        this.counterpartyes.counterpartyesList = clients.results.map((el) => {
          el.name = el.company_name;
          return el;
        });
      }
    },
    selectCounterparty(selectedCounterpartyes) {
      this.counterpartyes.selectedCounterpartyes = selectedCounterpartyes;
    },

    async getVessels() {
      if (this.vessels.vesselsList.length == 0) {
        let vessels = await this.axiosGetCalendarVesselsList({
          is_archive: false,
          contract_type: "SHIP",
        });
        this.vessels.vesselsList = vessels.results;
      }
    },
    selectVessels(selectedVessels) {
      this.vessels.selectedVessels = selectedVessels;
    },

    setRepeatOption(ev) {
      this.newEvent.is_never = ev.is_never;
      this.newEvent.repeat = ev.repeatOption;
      this.newEvent.days_for_repeat = ev.repeatDays;
      this.newEvent.end_repeat_day = ev.end_repeat_day;
    },

    delSelectedMember(id) {
      this.members.selectedMembers = this.members.selectedMembers.filter(
        (item) => item.id != id
      );
      this.$forceUpdate();
    },
    setAcceptDate(date, id) {
      let member = this.members.selectedMembers.find((item) => item.id == id);
      member.acceptDate = {
        date: moment(date).format("YYYY.MM.DD"),
      };
      this.$forceUpdate();
    },
    delAcceptDate(id) {
      let member = this.members.selectedMembers.find((item) => item.id == id);

      delete member.acceptDate;
      this.$forceUpdate();
    },
    setAcceptTime(e) {
      let member = this.members.selectedMembers.find(
        (item) => item.id == this.editMemberid
      );
      member.acceptTime = {
        time: e,
      };
      this.$forceUpdate();
    },
    delAcceptTime(id) {
      let member = this.members.selectedMembers.find((item) => item.id == id);
      delete member.acceptTime;
      this.$forceUpdate();
    },
    showTimelist(id) {
      this.editMemberid = id;
      this.$refs.timelistSelect.activate();
    },
    deleteTagWhichUsed(tag) {
      console.log('tag::: ', tag);
      
      // this.$refs.modalDeleteTag.openModal(tag);
    },
    tagDeleted(tag){
      let tagIndex = this.newEvent.tags.findIndex(el => {
        return el == tag.id
      })

      if(tagIndex != -1){
        this.newEvent.tags.splice(tagIndex,1)
      }

    },
    setTags(data) {
      this.newEvent.tags = data;
    },
    addNotification() {
      this.newEvent.notifications.push("5 min before start");
    },
    createRequestData(){
      let user = JSON.parse(localStorage.getItem("userData"));
      let data = JSON.parse(JSON.stringify(this.newEvent));
      data.timezone = this.timezone.selectedTimezone;
      data.timezone_info = this.timezone.timezone_info;
      

      data.days_for_repeat = this.newEvent.days_for_repeat.map((el) => {
        return moment(el).format("YYYY.MM.DD").split(".").join("-");
      });
      data.repeat = data.repeat.toLowerCase().split(" ").join("_");
      if(this.vessels.selectedVessels){
        data.vessel = this.vessels.selectedVessels.id;
      }else{
        data.vessel = null
      }
      
      data.date = moment(this.newEvent.date)
        .format("YYYY.MM.DD")
        .split(".")
        .join("-");
      if(this.newEvent.end_repeat_day){
        data.end_repeat_day = moment(this.newEvent.end_repeat_day)
          .format("YYYY.MM.DD")
          .split(".")
          .join("-");
      }else{
        data.end_repeat_day = null
      }
      data.counterparties = this.counterpartyes.selectedCounterpartyes.map(
        (el) => {
          return el.id;
        }
      );
      data.members = this.members.selectedMembers.map((el) => {
        let member = {};
        member.creator = user.data.user.id
        member.member = el.id;
        member.is_accepted = false;
        member.date = null;
        member.time = null;
        if (el.acceptDate) {
          member.date = moment(el.acceptDate.date)
            .format("YYYY.MM.DD")
            .split(".")
            .join("-");
        }
        if (el.acceptTime) {
          member.time = el.acceptTime.time;
        }
        return member;
      });
      return data;
    },
    async sendForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      this.showLoad();
      let data = await this.createRequestData()
      // Если редактируется Event
      if(this.eventID){
        data.eventID = this.eventID
          // Если редактируется повторяющийся Event в отпределенный день
        if(this.editParentEvent && !this.editAllEvents){
          // Если это еще не клонированный Event, создать клон
          if(!this.isClonedEvent){
            let editData = JSON.parse(JSON.stringify(this.editParentEvent));
            editData.eventID = this.editParentEvent.id;
            // Добавить дни в которые ивент будет скрываться в календаре
            if(!editData.exclude_days){
              editData.exclude_days = [moment(this.editParentEvent.currentDay.date).format("YYYY.MM.DD").split(".").join("-")]
            }else{
              editData.exclude_days.push(moment(this.editParentEvent.currentDay.date).format("YYYY.MM.DD").split(".").join("-"))
            }

            if (Array.isArray(this.editParentEvent.members) && this.editParentEvent.members.length != 0){
              editData.members = this.editParentEvent.members.map((el) => {
                let member = {};
                member.creator = el.creator;
                member.member = el.member.id;
                member.is_accepted = el.is_accepted;
                member.date = el.date;
                member.time = el.time;
                return member;
              });
            }

            if (Array.isArray(this.editParentEvent.tags) && this.editParentEvent.tags.length != 0){
              editData.tags = this.editParentEvent.tags.map(el => el.id)
            }

            if (Array.isArray(this.editParentEvent.counterparties) && this.editParentEvent.counterparties.length != 0){
              editData.counterparties = this.editParentEvent.counterparties.map(el => el.id)
            }

            if(this.editParentEvent.vessel){
              editData.vessel = this.editParentEvent.vessel.id;
            }
            // Обновить ивент
            this.axiosUpdateCalendarEvent(editData)
              .then(() => {
                // Создать клон ивента для показе в выбранный день вместо повторяющегося
                data.exclude_days = null;
                data.repeat = 'do_no_repeat';
                data.parent_id = this.editParentEvent.id;
                data.date = moment(this.editParentEvent.currentDay.date).format("YYYY.MM.DD").split(".").join("-")
                this.axiosCreateCalendarEvent(data)
                  .then(() => {
                    this.$myEmitter.emit("lodadNewData", new Date());
                    this.$myEmitter.emit("UpdateEvents", new Date());
                    this.hideLoad();
                    this.closeModal();
                  })
                  .catch((err) => {
                    this.hideLoad();
                    console.log(err);
                  });

              })
              .catch((err) => {
                this.hideLoad();
                console.log(err);
              });
          }else{
            // Если клонированный Event редактируется повторно
            this.axiosUpdateCalendarEvent(data)
            .then(() => {
              this.$myEmitter.emit("lodadNewData", new Date());
              this.$myEmitter.emit("UpdateEvents", new Date());
              this.hideLoad();
              this.closeModal();
            })
            .catch((err) => {
              this.hideLoad();
              console.log(err);
            });
          }
          
        }else if(this.editParentEvent && this.editAllEvents){
          // console.log('this.editParentEvent::: ', this.editParentEvent);
          // console.log('data::: ', data);
          data.eventID = this.editParentEvent.parent_id;
          data.delete_child = true;
          data.exclude_days = [];
          this.axiosUpdateCalendarEvent(data)
            .then(() => {
              this.$myEmitter.emit("lodadNewData", new Date());
              this.$myEmitter.emit("UpdateEvents", new Date());
              this.hideLoad();
              this.closeModal();
            })
            .catch((err) => {
              this.hideLoad();
              console.log(err);
            });
        }else{
          // Если редактируются все повторяющиеся Events
          // Удалить все клонированные ивенты и очистить поле exclude_days
          data.delete_child = true;
          data.exclude_days = [];
          // Обновить данные
          this.axiosUpdateCalendarEvent(data)
            .then(() => {
              this.$myEmitter.emit("lodadNewData", new Date());
              this.$myEmitter.emit("UpdateEvents", new Date());
              this.hideLoad();
              this.closeModal();
            })
            .catch((err) => {
              this.hideLoad();
              console.log(err);
            });
        }
      // Если создается новый
      }else{
        this.axiosCreateCalendarEvent(data)
          .then(() => {
            this.$myEmitter.emit("lodadNewData", new Date());
            this.$myEmitter.emit("UpdateEvents", new Date());
            this.hideLoad();
            this.closeModal();
          })
          .catch((err) => {
            this.hideLoad();
            console.log(err);
          });
      }
      this.$myEmitter.emit("updateDots");
      // console.log('data', data)
      // this.hideLoad()
      // this.closeModal()
    },
    
    clearForm() {
      this.counterpartyes.selectedCounterpartyes = [];
      this.members.selectedMembers = [];
      this.members.membersList = [];
      this.vessels.selectedVessels = [];
      this.editMemberid = null;
      this.newEvent.name = "";
      this.newEvent.date = "";
      this.newEvent.from_time = "";
      this.newEvent.to_time = "";
      this.newEvent.type = "Meeting";
      // this.newEvent.repeat = 'do_no_repeat';
      this.newEvent.repeat = "Do no repeat";
      this.newEvent.end_repeat_day = null;
      this.newEvent.days_for_repeat = [];
      this.newEvent.timezone = null;
      this.newEvent.display_to = "Everyone";
      this.newEvent.comment = null;
      this.newEvent.notifications = [];
      this.newEvent.vessel = null;
      this.tagsSelectedDefault = null;
      this.editInfo = null;
      this.newEvent.tags = [];
      this.newEvent.counterparties = [];
      this.$refs.calendarRepeatSelect.clear()
    },
  },
  computed: {
    displayTo() {
      return this.newEvent.display_to == "Everyone";
    },
    setTimeFrom(){
      // Для блокировки предыдущего времени
      if(this.newEvent.from_time){
        let fromTime = this.newEvent.from_time.split(':');
        return new Date().setHours(fromTime[0], fromTime[1]);
      }
      return new Date().setHours(0, 0, 0, 0);
    },
    notificatiionsListFiltered(){
      //пока на паузе
      if(moment(this.newEvent.date).isBefore(new Date())){
        // return  [
        //   "5 min before start",
        //   "15 min before start",
        //   "30 min before start",
        // ]
        return this.notificatiionsList
      }
      return this.notificatiionsList
    },
    selectedTimezone: {
      get: function () {
        return this.timezone.timezone_info
      },
      set: function (newValue) {
        this.timezone.timezone_info = newValue.timeZoneInfo;
        this.timezone.selectedTimezone = newValue.timezone
      }
    }
  },
  async beforeMount() {
    // this.fillTimeList();
    // this.getMembers();
    // this.getCounterpartyes();
    // this.getVessels();
  },
  mounted(){
    // console.log('timezones::: ',timezones );
    let sortedTimeZones = timezones.sort((a, b) => a.offset - b.offset) 
    this.timezone.timezoneList = sortedTimeZones.map(el => {
        // if(!el.text.match(/\d/g)){
        //   el.text = el.text.replace('UTC','UTC+00:00')
        // }
      return {
        timeZoneInfo: el.text,
        timeZoneOffset: el.offset,
        timezone: el.text.replace(/[{()}]/g, '').split(' ')[0].replace('UTC','')
      }
    })
    
  },
  watch: {
    newEvent: {
      handler: function (val) {
        if (val.display_to == "Only to myself") {
          this.members.selectedMembers = [];
        }
      },
      deep: true,
    },
    show: async function (val) {
      this.clearForm();
      if(val){
        // перенес из beforeMount
        this.fillTimeList();
        this.getMembers();
        this.getCounterpartyes();
        this.getVessels();
        if(!this.eventID){
          this.setDefaultTimezone()
        }
      }

      this.clearSelected = !val;
      if (this.eventID) {
        this.showLoad();
        let eventInfo = await this.axiosGetCalendarEventById(this.eventID);
        this.editInfo = eventInfo;
        
        if (eventInfo.name) this.newEvent.name = eventInfo.name;
        if (eventInfo.date) this.newEvent.date = new Date(eventInfo.date);
        if (eventInfo.from_time)
          this.newEvent.from_time = eventInfo.from_time.substr(0, 5);
        if (eventInfo.to_time)
          this.newEvent.to_time = eventInfo.to_time.substr(0, 5);
        if (eventInfo.type) this.newEvent.type = eventInfo.type;
        if (eventInfo.repeat) this.newEvent.repeat = eventInfo.repeat;
        if (eventInfo.timezone){
          this.timezone.selectedTimezone = eventInfo.timezone;
          this.timezone.timezone_info = eventInfo.timezone_info;
        }
        if (eventInfo.display_to) this.newEvent.display_to = eventInfo.display_to;
        if (eventInfo.members){
          this.members.selectedMembers = eventInfo.members.map(el => {
            
            el.member.name = `${el.member.first_name} ${el.member.last_name}`
            el.member.creator = el.creator
            if(el.date){
              el.member.acceptDate = {}
              el.member.acceptDate.date = el.date.split('-').join('.')
            }
            if(el.time){
              el.member.acceptTime = {}
              el.member.acceptTime.time = el.time.substr(0,5)
            }
            return el.member
          })
        }

        if(eventInfo.tags){
          let tagsList = eventInfo.tags.map(el => {
            return el.id
          })
          this.$refs.tagsSel.setDefaultTags(tagsList)
        }
        if (eventInfo.counterparties)
          this.counterpartyes.selectedCounterpartyes = eventInfo.counterparties;

        if (eventInfo.vessel)
          this.vessels.selectedVessels = eventInfo.vessel;

        if(eventInfo.comment) this.newEvent.comment = eventInfo.comment;

        if(eventInfo.notifications) this.newEvent.notifications = eventInfo.notifications;

        this.hideLoad();
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  .timelistSelect {
    .multiselect__select,
    .multiselect__tags {
      display: none;
    }
  }
  .create-event-wrapper {
    .error {
      .mx-input {
        border-color: red !important;
      }
    }
  }
  .my-custom-select-wrapper {
    .multiselect__strong {
      display: inline-flex;
      border-radius: 50%;
      border: 1px solid black;
      align-items: center;
      justify-content: center;
      padding: 4px 4px;
      font-size: 10px;
      line-height: 100%;
    }
  }
  .repeat-select-wrapper {
    .mx-icon-calendar {
      display: none;
    }
    .mx-input-wrapper {
      width: 100%;
    }
    .date-picker-input {
      display: none;
    }
  }
}
.my-time-picker{
  top: 25% !important;
  .mx-time{
    width: 100%;
  }
  .mx-time-content{
    height: 224px !important;
  }
  .mx-time-option.disabled{
   display: none;
  }
}
.invisible-select {
  .multiselect__tags {
    border: none;
    background: none;
    font-size: 14px;
  }
  .multiselect {
    width: fit-content;
  }
  .multiselect__single {
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.create-event-wrapper {
  padding: 24px;
  .members-search {
    .multiselect__single {
      font-size: 13px;
      line-height: 16px;
      padding-top: 2px;
      color: #9aa3b0;
    }
    .multiselect__input {
      font-size: 13px;
      line-height: 16px;
      color: #9aa3b0;
    }
    .multiselect__element {
      span {
        font-size: 13px;
        line-height: 16px;
      }
    }
    .multiselect__option--selected {
      position: relative;
      display: flex;
      align-items: center;
      .option__name:after {
        content: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z' fill='%23319FEF'/%3E%3C/svg%3E ");
        position: absolute;
        background: none;
        right: 6px;
      }
    }
    .option__name {
      // width: 150px;
      width: calc(100% - 50px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    &.disabled{
      pointer-events: none;
      opacity: .5;
    }
  }
  .notification-btn {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .error {
    border-color: red;
  }
}
.avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z' fill='%23DEE3EA'/%3E%3C/svg%3E%0A");
  margin-right: 6px;
  background-size: cover;
}
.three-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .mx-datepicker {
    max-width: 100%;
  }
  .block-input-wrapper {
    width: 145px;
    max-width: 145px;
  }
}
.radio-buttons-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .radio-block {
    display: flex;
    align-items: center;
    .radio-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      height: 24px;
      &:hover {
        cursor: pointer;
      }
      &:nth-child(1) {
        margin-right: 35px;
      }
    }
    input {
      position: absolute;
      z-index: -99999;
      opacity: 0;
    }
    span {
      display: flex;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid var(--color-gray-blacker-than-black);
      position: relative;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }
    input:checked + span {
      border-color: var(--color-blue-dark);
      &:after {
        content: "";
        position: absolute;
        display: flex;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--color-blue-dark);
      }
    }
  }
}
.members-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  .member-item {
    height: 40px;
    width: 100%;
    padding-right: 30px;
    padding-left: 8px;
    padding: 4px 30px 4px 8px;
    position: relative;
    margin-bottom: 13px;
    position: relative;
    display: flex;
    align-items: center;
    .del-btn {
      position: absolute;
      right: 5px;
      top: 14px;
      display: none;
    }
    &:hover {
      background: var(--color-bgc-page);
      cursor: pointer;
      .del-btn {
        display: flex;
      }
    }
  }
  .member-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .accept-date-wrapper,
  .accept-time-wrapper {
    position: relative;
    display: inline-flex;
    .datepick {
      position: absolute;
      z-index: -999999;
      opacity: 0;
    }
    .date {
      color: #9aa3b0;
      &:hover {
        color: #319fef;
        cursor: pointer;
      }
    }
    .date-num {
      color: #2c2c2c;
      display: inline-flex;
      width: 85px;
      align-items: center;
      .del-btn {
        top: 4px;
        display: flex;
        svg {
          width: 8px;
          height: 8px;
          path {
            fill: #9aa3b0;
          }
        }
      }
    }
  }
  .accept-time-wrapper {
    margin-left: 10px;
    .date-num {
      width: 60px;
    }
  }
}
.tagsSel {
  position: relative;
}
.notifications-list {
  
  .notifications__item {
    display: flex;
    align-items: center;
    position: relative;
    .del-btn {
      position: absolute;
      right: 10px;
      display: none;
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      background: var(--color-gray-lightest);
      .del-btn {
        display: flex;
      }
    }
  }
}
.my-custom-select-wrapper {
  .selected {
    display: inline-block;
    transform: translateY(3px);
  }
  .avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z' fill='%23DEE3EA'/%3E%3C/svg%3E%0A");
    margin-right: 6px;
  }
  .option__name {
    // width: 150px;
    width: calc(100% - 50px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .multiselect__option--selected {
    position: relative;
    display: flex;
    align-items: center;
    .option__name:after {
      content: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z' fill='%23319FEF'/%3E%3C/svg%3E ");
      position: absolute;
      background: none;
      right: 6px;
    }
  }
}
.customSelect-wrapper {
  &.disabled{
    pointer-events: none;
    opacity: .5;
  }
  .option__name {
    position: relative;
    width: 100%;
    padding: 6px 0;
  }
  .multiselect__option--selected {
    display: flex;
    align-items: center;
    .option__name {
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z' fill='%23319FEF'/%3E%3C/svg%3E ");
        position: absolute;
        background: none;
        right: 6px;
      }
    }
  }
}
.block-from-inputs{
  &.disabled{
    position: relative;
    .notification-btn{
      opacity: .5;
      pointer-events: none;
    }
    &:hover:after { 
      content: attr(data-title); 
      display: flex;
      position: absolute;
      font-size: 13px;
      color: #E85353;
      margin-top: 8px;
    }
  }
}
</style>