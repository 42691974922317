<template>
  <div>
    <b-dropdown right ref="dropdown">
      <template #button-content>
        <button class="button-drop fz13 fw500 text-dark1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.28634 3.73746C8.22907 3.73746 7.3304 4.45987 7.03965 5.44983H4.63436C4.26432 5.44983 4 5.74415 4 6.11873C4 6.49331 4.29075 6.78763 4.66079 6.78763H7.06608C7.3304 7.77759 8.25551 8.5 9.31277 8.5C10.3965 8.5 11.2952 7.77759 11.5859 6.78763H18.3392C18.7093 6.78763 19 6.49331 19 6.11873C19 5.74415 18.7093 5.44983 18.3392 5.44983H11.5859C11.2687 4.45987 10.37 3.73746 9.28634 3.73746ZM9.31277 5.07525C9.86784 5.07525 10.3436 5.5301 10.3436 6.11873C10.3436 6.70736 9.86784 7.16221 9.31277 7.16221C8.75771 7.16221 8.28194 6.70736 8.28194 6.11873C8.28194 5.5301 8.75771 5.07525 9.31277 5.07525Z" fill="#2C2C2C"/>
            <path d="M14.2423 9.61377C13.185 9.61377 12.2863 10.3362 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6205 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6205 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3362 15.326 9.61377 14.2423 9.61377ZM14.2687 10.9516C14.8238 10.9516 15.2996 11.4064 15.2996 11.995C15.2996 12.5837 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5837 13.2379 11.995C13.2379 11.4064 13.6872 10.9516 14.2687 10.9516Z" fill="#2C2C2C"/>
            <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5186 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8029 8.42731 20.5253 9.48458 20.5253C10.5683 20.5253 11.467 19.8029 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5186 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5554 10.5154 18.144C10.5154 18.7326 10.0396 19.1875 9.48458 19.1875C8.92951 19.1875 8.45374 18.7326 8.45374 18.144C8.48018 17.5554 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
          </svg>
          Filter
          <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2C2C2C"/>
          </svg>
        </button>
      </template>
      <div class="content">
        <button v-on:click="$refs.dropdown.hide(true)" class="title fw500 text-gray">
          View
        </button>
        <button v-for="(b, j) in buttonsView" :key="'a'+j" :class="{'active-button': b.status}" v-on:click="changeActiveButtonView(j)" class="fz13 text-dark">
          {{b.name}}
        </button>
        <hr>
        <button v-on:click="$refs.dropdown.hide(true)" class="title fw500 text-gray">
          Departments
        </button>
        <div class="wrap" :key="keyRender">
          <button v-for="(d, i) in buttonsDepartments" :key="'asd' + i" :class="{'active-button': d.status}" v-on:click="changeActiveButtonAllDepartment(i)" class="fz13 text-dark">
            {{d.name}}
          </button>
        </div>

      </div>
    </b-dropdown>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "filter-employees-mobile",
  computed: mapGetters(["getButtonsDepartments", "getDepartmentsForEmployees"]),
  data() {
    return {
      buttonsView: [
        {name: "Cards", status: true},
        {name: "Table", status: false},
      ],
      buttonsDepartments: [],
      indexActiveView: 0,
      indexActiveDepartment: 0,
      keyRender: -3434,
    }
  },
  methods: {
    ...mapActions(["getAxiosDepartmentsEmployeesPage"]),
    ...mapMutations(["sortEmployeesByDepartmentFilter"]),
    changeActiveButtonAllDepartment(index) {
      if(index !== this.indexActiveDepartment) {
        this.indexActiveDepartment = index;
        for(let i=0; i<this.buttonsDepartments.length; i++) {
          this.buttonsDepartments[i].status = false;
        }
        this.buttonsDepartments[index].status = true;
        this.$refs.dropdown.hide(true);
        this.keyRender++;
        this.sortEmployeesByDepartmentFilter(this.buttonsDepartments[index].name);
        this.$emit("changeButton", index);
      } else {
        this.$refs.dropdown.hide(true);

      }
    },
    changeActiveButtonView(index) {
      if(this.indexActiveView !== index) {
        this.indexActiveView = index;
        this.$refs.dropdown.hide(true);
        for(let i=0; i<this.buttonsView.length; i++) {
          this.buttonsView[i].status = false;
        }
        this.buttonsView[index].status = true;
        this.$emit("changeView");

      } else {
        this.$refs.dropdown.hide(true);
      }
    },
    setButtonView(index) {
      this.indexActiveView = index;
      for(let i=0; i<this.buttonsView.length; i++) {
        this.buttonsView[i].status = false;
      }
      this.buttonsView[index].status = true;
    },
    setButtonDepartments(index) {
      this.indexActiveDepartment = index;
      for(let i=0; i<this.buttonsDepartments.length; i++) {
        this.buttonsDepartments[i].status = false;
      }
      this.buttonsDepartments[index].status = true;
      this.keyRender++;
    }
  },
  mounted() {
    if(this.getDepartmentsForEmployees === null) {
      this.getAxiosDepartmentsEmployeesPage().then(()=> {
        for(let i=0; i<this.getButtonsDepartments.length; i++) {
          this.buttonsDepartments.push(this.getButtonsDepartments[i]);
        }
      })
    } else {
      for(let i=0; i<this.getButtonsDepartments.length; i++) {
        this.buttonsDepartments.push(this.getButtonsDepartments[i]);
      }
    }

  }
}
</script>

<style scoped lang="scss">
@import "../style-element/dropdown";
.active-button {
  background-image: url("../../assets/ic_chsdeck.svg");
  background-position-x: calc(100% - 4px);
  background-position-y: center;
  background-repeat: no-repeat;
}
.svg-1 {
  margin-right: 8px;
}
.arrow {
  margin-left: 4px;
}
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  top: -29px !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  padding: 4px 0 !important;
}
.content {
  width: 160px;
  background-color: var(--color-modal);
  border-radius: 4px;
  .title {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 5px 12px;
    display: flex;
    align-items: center;
    svg {
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    transition: .1s linear;
    &:hover {
      color: #2C2C2C !important;
    }
  }
  button:not(:first-child) {
    height: 28px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    transition: background-color .1s linear;
    &:hover {
      background-color: #DEE3EA;
    }
  }
  .wrap {
    button {
      height: 28px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      transition: background-color .1s linear;

      &:hover {
        background-color: #DEE3EA;
      }
    }
  }
}
hr {
  margin: 5px 0;
}
</style>
