<template>
  <div class="flex items-center w-full fz0" v-if="activeCreator && activeTab && getLoginData">
    <div class="flex items-center name-wrap svg-white-dart">

      <div class="fz16 fw500 name-wrap__name" v-if="!isEdit" :title="activeTab.name">
        {{activeTab.name}}
      </div>
      <svg v-if="!isEdit && $route.path.indexOf('archive') === -1 && activeCreator.id === getLoginData.data.user.id" @click="onRename" class="m-l-1 cursor-pointer hover:fill-blue-dark" width="24" height="24" viewBox="0 0 24 24" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z"/></svg>

      <input 
        v-if="isEdit"
        type="text" 
        class="text-blue-dark fz16 fw500 bg-transparent p-0 border-0" 
        ref="name" 
        :value="activeTab.name"
        @keyup.enter="onBlurName"
        @blur="onBlurName"
      >
    </div>

    <calc-top-nav class="ml16" />

    <div class="flex items-center ml-auto">
<!--      <label class="relative flex items-center m-r-4">-->
<!--        <div class="m-r-2 fz13 fw500 text-gray-text">VLSFO</div>-->
<!--        <input -->
<!--          type="text" -->
<!--          class="w-18 p-x-2 p-y-1"-->
<!--          :value="activeTab.vlsfo"-->
<!--          :disabled="isDisabled || $route.path.indexOf('archive') >= 0 || activeCreator.id !== getLoginData.data.user.id"-->
<!--          @blur="onBlurInfo($event, 'vlsfo')"-->
<!--          @keyup.enter="onBlurInfo($event, 'vlsfo')"-->
<!--        >-->
<!--        <div class="absolute right-2 centered-y fz14 text-gray-text">$</div>-->
<!--      </label>-->
<!--      <label class="relative flex items-center m-r-4">-->
<!--        <div class="m-r-2 fz13 fw500 text-gray-text">LSMGO</div>-->
<!--        <input -->
<!--          type="text" -->
<!--          class="w-18 p-x-2 p-y-1"-->
<!--          :value="activeTab.lsmgo"-->
<!--          :disabled="isDisabled || $route.path.indexOf('archive') >= 0 || activeCreator.id !== getLoginData.data.user.id"-->
<!--          @blur="onBlurInfo($event, 'lsmgo')"-->
<!--          @keyup.enter="onBlurInfo($event, 'lsmgo')"-->
<!--        >-->
<!--        <div class="absolute right-2 centered-y fz14 text-gray-text">$</div>-->
<!--      </label>-->
<!--      <label class="relative flex items-center m-r-4">-->
<!--        <div class="m-r-2 fz13 fw500 text-gray-text">HSFO</div>-->
<!--        <input -->
<!--          type="text" -->
<!--          class="w-18 p-x-2 p-y-1"-->
<!--          :value="activeTab.hsfo"-->
<!--          :disabled="isDisabled || $route.path.indexOf('archive') >= 0 || activeCreator.id !== getLoginData.data.user.id"-->
<!--          @blur="onBlurInfo($event, 'hsfo')"-->
<!--          @keyup.enter="onBlurInfo($event, 'hsfo')"-->
<!--        >-->
<!--        <div class="absolute right-2 centered-y fz14 text-gray-text">$</div>-->
<!--      </label>-->
<!--      <label class="relative flex items-center m-r-4">-->
<!--        <div class="m-r-2 fz13 fw500 text-gray-text">Addcom</div>-->
<!--        <input -->
<!--          type="text" -->
<!--          class="w-18 p-x-2 p-y-1"-->
<!--          :value="activeTab.addcom"-->
<!--          :disabled="isDisabled || $route.path.indexOf('archive') >= 0 || activeCreator.id !== getLoginData.data.user.id"-->
<!--          @blur="onBlurInfo($event, 'addcom')"-->
<!--          @keyup.enter="onBlurInfo($event, 'addcom')"-->
<!--        >-->
<!--        <div class="absolute right-2 centered-y fz14 text-gray-text">%</div>-->
<!--      </label>-->

<!--      <label class="relative flex items-center">-->
<!--        <div class="m-r-2 fz13 fw500 text-gray-text">Weather Factor</div>-->
<!--        <input -->
<!--          type="text" -->
<!--          class="w-18 p-x-2 p-y-1"-->
<!--          :value="activeTab.weather_factor"-->
<!--          :disabled="isDisabled || $route.path.indexOf('archive') >= 0 || activeCreator.id !== getLoginData.data.user.id"-->
<!--          @blur="onBlurInfo($event, 'weather_factor')"-->
<!--          @keyup.enter="onBlurInfo($event, 'weather_factor')"-->
<!--        >-->
<!--        <div class="absolute right-2 centered-y fz14 text-gray-text">%</div>-->
<!--      </label>-->
    </div>

    <div class="flex items-center m-l-4" v-if="!exportPdf">
      <button class="fz0">
        <payment-dropdown-export class="fz0" @eventExportPDF="eventExportPDF" @eventExportXLSX="eventExportXLSX"></payment-dropdown-export>
      </button>

      <button class="fz0 m-l-6 svg-dart" @click="onDuplicate" v-if="$route.path.indexOf('archive') === -1">
        <svg class="hover:fill-gray-text" width="24" height="24" viewBox="0 0 24 24" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.1719C4 9.13178 4.85439 8.28861 5.90834 8.28861H12.9456C13.9996 8.28861 14.854 9.13178 14.854 10.1719V17.1167C14.854 18.1568 13.9996 19 12.9456 19H5.90834C4.85439 19 4 18.1568 4 17.1167V10.1719ZM5.90834 9.60405C5.59056 9.60405 5.33294 9.85828 5.33294 10.1719V17.1167C5.33294 17.4303 5.59056 17.6846 5.90834 17.6846H12.9456C13.2634 17.6846 13.521 17.4303 13.521 17.1167V10.1719C13.521 9.85828 13.2634 9.60405 12.9456 9.60405H5.90834Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.14605 5.88328C8.14605 4.84317 9.00044 4 10.0544 4H17.0917C18.1456 4 19 4.84317 19 5.88328V12.8281C19 13.8682 18.1456 14.7114 17.0917 14.7114C16.7236 14.7114 16.4252 14.4169 16.4252 14.0537C16.4252 13.6904 16.7236 13.396 17.0917 13.396C17.4094 13.396 17.6671 13.1417 17.6671 12.8281V5.88328C17.6671 5.56967 17.4094 5.31543 17.0917 5.31543H10.0544C9.7366 5.31543 9.47899 5.56967 9.47899 5.88328C9.47899 6.24652 9.1806 6.54099 8.81252 6.54099C8.44444 6.54099 8.14605 6.24652 8.14605 5.88328Z"/></svg>
      </button>
      <button class="fz0 m-l-6 svg-dart" @click="onArchive" v-if="($route.path.indexOf('archive') === -1 && activeCreator.id === getLoginData.data.user.id) || getPermissionArchive()">
        <svg v-if="$route.path.indexOf('archive') === -1" class="hover:fill-gray-text" width="24" height="24" viewBox="0 0 24 24" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"/></svg>
        <svg v-else class="hover:fill-gray-text" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/><path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#2C2C2C"/></svg>
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters, mapState} from 'vuex'
import PaymentDropdownExport from "@/components/vessels/sublet/vsoaExport";
import calcTopNav from "@/components/calc/calcTopNav";
export default {
  components: {
    PaymentDropdownExport,
    calcTopNav
  },
  props: {
    exportPdf: {
      type: Boolean,
      default: false,
    },
    isBurgerActive: Boolean
  },
  data() {
    return {
      isEdit: false,
      isDisabled: false,
    }
  },
  computed: {
    ...mapState({
      activeCreator: state => state.tabs.activeCreator,
      windowWidth: state => state.windowWidth,
    }), 
    ...mapGetters(['getCurrentTab', 'getLoginData', 'getPermissionsByType']),
    activeTab() {
      return this.getCurrentTab(this.$route.params?.id)
    }
  },
  methods: {
    ...mapActions(['axiosPatchTab', 'axiosCopyTab', 'axiosDeleteTab']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    eventExportXLSX(){
      this.$emit('eventExportXLSX')
    },
    eventExportPDF(){
      this.$emit('eventExportPDF')
    },
    getPermissionArchive() {
      return this.getPermissionsByType('archive_and_unarchive_all_users_lists_and_folders')
    },
    async onRename() {
      this.isEdit = true
      await this.$nextTick()
      this.$refs.name.focus()
    },
    onBlurName(e) {
      if(e.target.value && this.activeTab.name != e.target.value){
        let name = e.target.value
        if(name.length > 50){
          name = name.slice(0, 50)
          this.addTip(`The maximum number of characters is 50.`)
        }
        this.activeTab.name = name
        this.axiosPatchTab({
          id: this.activeTab?.id,
          name: name
        })
      }
      this.isEdit = false
    },
    async onBlurInfo(e, key) {
      if(e.target.value == this.activeTab[key]) return false
      if(parseFloat(e.target.value) == 0 || parseFloat(e.target.value)){
        this.isDisabled = true
        let saveVal = parseFloat(e.target.value)
        let saveObj = {id: this.activeTab?.id}
        saveObj[key] = saveVal
        this.activeTab[key] = saveVal
        e.target.value = saveVal
        await this.axiosPatchTab(saveObj)
        this.isDisabled = false
      }else{
        e.target.value = this.activeTab[key]
      }
    },

    async onDuplicate() {
      this.showLoad()
      await this.axiosCopyTab({list_pk: this.activeTab?.id})
      this.addTip(`The file “${this.activeTab.name}” is duplicated`)
      this.hideLoad()
    },
    async onArchive() {
      this.$emit('on-archive', this.activeTab)
      // this.showLoad()
      // await this.axiosDeleteTab(this.activeTab.id)
      // this.hideLoad()
    },
  },
}
</script>

<style scoped lang="scss">
.name-wrap{
  width: 200px;
  overflow: hidden;
  &__name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  svg{
    flex-shrink: 0;
    //opacity: 0;
    transition: opacity 0.3s;
  }
  &:hover{
    svg{
      opacity: 1;
    }
  }
}
</style>