import axios from 'axios'

export default {
  actions: {
    async axiosAddPaymentAccount(ctx, account) {
      await axios.post(process.env.VUE_APP_URL+'/client/payment/', account, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then( () => {
        ctx.dispatch('axiosGetPaymentAccountsByID', account.client)
      }).catch(error => {
        console.error('axiosAddPaymentAbbount', error);
      })
    },
    async axiosEditPaymentAccount(ctx, {account, id}) {
      await axios.put(process.env.VUE_APP_URL+'/client/payment/'+ id, account, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then( () => {
        ctx.dispatch('axiosGetPaymentAccountsByID', account.client)
      }).catch(error => {
        console.error('axiosAddPaymentAbbount', error);
      })
    },
    async axiosGetPaymentAccountsByID(ctx, id) {
      await axios.get(process.env.VUE_APP_URL+'/client/payment/?is_archived=false&client=' + id , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        ctx.commit('setPaymentAccounts', response.data.results)
      }).catch(error => {
        console.error('axiosGetClients', error);
      })
    },
    async axiosGetPaymentAccountsArchiveByID(ctx, id) {
      await axios.get(process.env.VUE_APP_URL+'/client/payment/?is_archived=true&client=' + id , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        ctx.commit('setPaymentAccountsArchive', response.data.results)
      }).catch(error => {
        console.error('axiosGetClients', error);
      })
    },
    async axiosArchivedPaymentAccount(ctx, {clientId, id}) {
      await axios.patch(process.env.VUE_APP_URL+'/client/payment/'+ id, {is_archived: true, client: clientId}, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        ctx.dispatch('axiosGetPaymentAccountsByID', clientId)
      }).catch(error => {
        console.error('axiosArchivedPaymentAccount', error);
      })
    },
    async axiosUnArchivedPaymentAccount(ctx, {clientId, id}) {
      await axios.patch(process.env.VUE_APP_URL+'/client/payment/'+ id, {is_archived: false, client: clientId}, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        ctx.dispatch('axiosGetPaymentAccountsArchiveByID', clientId)
      }).catch(error => {
        console.error('axiosUnArchivedPaymentAccount', error);
      })
    },
  },
  mutations: {
    setPaymentAccounts(state, accounts) {
      state.accounts = []
      accounts.forEach(account => {
        state.accounts.push(account)
      })
    },
    setPaymentAccountsArchive(state, accounts) {
      state.accountsArchive = []
      accounts.forEach(account => {
        state.accountsArchive.push(account)
      })
    }
  },
  state: {
    accounts: [],
    accountsArchive: []
  },
  getters: {
    getPaymentAccountsByID: (state) => id => {
      return state.accounts.filter((el) => {
        return el.client == id
      })
    },
    getPaymentAccountsArchiveByID: (state) => id => {
      return state.accountsArchive.filter((el) => {
        return el.client == id
      })
    }
  },
}
