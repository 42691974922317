<template>
  <div class="wrap-item" :class="{'ch-completed': ch.is_completed}">
    <div class="part1 d-flex align-items-center">
      <div class="checkbox" v-on:click="changeComplete(ch)">
        <svg v-if="!ch.is_completed" class="no-complete" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" stroke="#BDCBD6"/>
        </svg>
        <svg v-if="ch.is_completed" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#094172"/>
          <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
        </svg>
      </div>
      <p class="ml8 fz13 description">{{ch.description}}</p>
    </div>
    <div class="part2 d-flex align-items-center">
      <checklist-select-priority v-model="ch.priority"></checklist-select-priority>
    </div>
    <div class="part3 d-flex align-items-center">
      <select-due-date
          :class="{'date-expired': getDateExpired(ch.due_date)}"
          :disabled="false"
          class="select-due-date"
          :text-no-select="''"
          :button-show-after-hover="true"
          :due_date_status="ch.due_date_status"
          :active-due-date="ch.due_date"
          :active-custom-days="getActiveCustomDays(ch.days_for_repeat)"
          :itemForChange="ch"
          :showSelectTime="true"
          :short-view="true"
      ></select-due-date>
    </div>
    <div class="part4">
      <p class="fz13">{{ch.done_date | formatDateAndTime2}}</p>
    </div>

  </div>
</template>

<script>
import ChecklistSelectPriority from "@/components/checklist/checklist-select-priority";
import SelectDueDate from "@/components/tasks/selectDueDate/selectDueDate";
import moment from 'moment';
export default {
  name: "checklist-task-list-checkbox",
  components: {SelectDueDate, ChecklistSelectPriority},
  props: {
    ch: Object,
  },
  data() {
    return {

    }
  },
  methods: {
    getDateExpired(date) {
      return moment(date).isBefore(new Date())
    },
    getActiveCustomDays(array) {
      if(Array.isArray(array) === true) {
        let dateCurrent = new Date();
        return  array.map(a=> {
          return new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), a)
        })
      }
      return [];
    },
    changeComplete(ch) {
      ch.is_completed = !ch.is_completed
      ch.done_date = new Date();
    },
  }
}
</script>

<style scoped lang="scss">
.date-expired {
  /deep/ {
    .due-date {
      color: var(--color-red) !important;
    }
  }
}
  .wrap-item {
    display: flex;
    align-items: center;
    height: 34px;
    padding: 5px 12px;
    box-sizing: border-box;
    background-color: #F8FAFD;
  }
  .description {
    color: #4B4B4B;
    margin-left: 8px !important;
    width: calc(100% - 40px);
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
  }
  /deep/ {
    .text-dark-local, .button-drop .text-dark1 {
      color: #4B4B4B;
    }
  }
  .dart-mode {
    .wrap-item {
      background-color: #353644;
    }
    .description {
      color: #BDCBD6;
    }
    .part2 {
      /deep/ {
        .show-selected {
          .text-dark-local {
            color: #BDCBD6 !important;
          }
        }
      }
    }

    .select-due-date {
      /deep/ {
        .button-drop {
          .text-dark1 {
            color: #BDCBD6 !important;
          }
        }
      }
    }
  }
  .part1 {
    width: calc(51.33% - 130px + 8.726%);
  }
  .part2 {
    width: calc(23.173% );
  }
  .part3 {
    width: calc(130px);
  }
  .part4 {
    width: 16%;
  }
  .select-due-date {
    /deep/ {
      .dropdown-menu {
        padding: 0 !important;
      }
      .button-drop {
        overflow: hidden;
        width: 125px;
        //width: 100%;
        height: 33px;
        border: none !important;
        background: transparent !important;
        padding: 8px 4px !important;
        p, span {
          font-size: 0.8125rem !important;
          line-height: 1rem !important;
        }
        &>svg {
          display: none;
        }
      }
      //svg {
      //  display: none;
      //}
      .button-opacity {
        display: none !important;
      }
      &:hover {
        .button-opacity {
          display: flex !important;
        }
      }
      .dropdown-menu {
        width: 200px;
      }
    }
  }
/deep/ {
  .ml4 {
    margin-left: 4px !important;
  }
}

.ch-completed {
  .description {
    color: #9AA3B0 !important;
  }
  /deep/ {
    .text-dark-local, .button-drop .text-dark1 {
      color: #9AA3B0 !important;
    }
  }
}
.dart-mode {
  .ch-completed {
    .description {
      color: #9AA3B0;
    }
    .part2 {
      /deep/ {
        .show-selected {
          .text-dark-local {
            color: #9AA3B0 !important;
          }
        }
      }
    }
    .select-due-date {
      /deep/ {
        .button-drop {
          .text-dark1 {
            color: #9AA3B0 !important;
          }
        }
      }
    }
  }
}
</style>