<template>
  <div class="bg-white m-t-auto fixed bottom-0 z-100">
    <div class="flex justify-between items-center p-y-2 box-border vessel_owners__page_container">
      <div class="relative w-1/2">
        <button v-if="blocks.length" class="relative flex items-center text-blue-dark fz13 fw500 z-10" @click="$refs.dropBlocks.openList()">
          <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="#094172" xmlns="http://www.w3.org/2000/svg"><path d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z"/></svg>
          Add new section
        </button>
        <counterparties-drop
          class="hide_input hide_input_in_bottom"
          v-if="blocks.length"
          ref="dropBlocks"
          :list="blocks"
          :isDropup="true"
          @select="selectedBlock"
          title="Add new section"
        ></counterparties-drop>
      </div>
      <div class="flex w-1/2 justify-end">
        <button @click="$router.go(-1)" class="edit__owners_btn p-2 fz14 blue-button-text">Cancel</button>
        <button type="button" class="edit__owners_btn blue-button fz14 fw500" @click="$emit('on-save')">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop';
export default {
  components: {
    counterpartiesDrop
  },
  props: {
    blocks: Array
  },
  methods: {
    selectedBlock(block) {
      this.$emit('selectedBlock', block)
      this.$refs.dropBlocks.render()
    }
  }
}
</script>

<style lang="scss">
.edit__owners_btn{
  &:last-child{
    width: 50%;
    max-width: 360px;
    margin-left: 10%;
  }
}
.counterparties-drop.hide_input_in_bottom{
  top: -18px;
}
</style>