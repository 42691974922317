
<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">Delete the PD/A <button class="modal-close" @click="closeModal()"></button></p>

        <p class="modal-content_descr">Are you sure want to delete this PD/A?</p>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal">No</button>
        <button class="btn btn-next" @click="onSubmit">Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pdaNumb'],
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      this.$emit('submit', this.pdaNumb)
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-wrap{
  .modal-content{
    max-width: 424px;
    &_descr{
      word-break: break-word;
    }
    &_bottom{
      border-top: none;
    }
  }
}
</style>