<template>
  <div class="wrap-cash bgc-gray w100vw h100Proc d-flex justify-between box-border">
    <div class="wrap-datepicker d-flex align-items-center">
      <payment-dropdown-export class="download-list button-top-panel position-relative z-10" @eventExportXLSX="downloadXLSXListPayment" @eventExportPDF="downloadPDFListPayment"></payment-dropdown-export>

      <payment-panel-select-period :class="{'archive-mode': $route.path.indexOf('archive') >= 0}" :active-date="dateDefault" :default-status-button="4" class="date-selected panel-select-period" :normal-date="true" :hide-date-picker="false" :return-type="true"  @changeDate="changeDate"></payment-panel-select-period>
      <router-link :to="{name: 'CashFlow Diagram New Archive'}" class="position-relative z-10 button-dark-link button-archive fz13 fz500 text-dark flex align-items-center justify-center" v-if="$route.path.indexOf('archive') === -1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </router-link>
    </div>
    <div class="wrap-diagram position-relative">
      <cash-flow-diagram-chart class="h100Proc" ref="chartCashFlow" :notUseDefaultPicker="true" :useTypePeriod="true" :hide-date="true"></cash-flow-diagram-chart>
    </div>
    <div class="wrap-list position-relative payment-block">
      <transition name="fade">
        <app-loader :is-local="true" v-if="isLoader" class="loader-payment"></app-loader>
      </transition>
      <virtual-payment-list @changeOrdering="changeOrdering" @scrollBottomEnd="getNextLinkPayment && getPaymentListOriginal.length !== 0 && !showLoader ? paymentNext() : null" @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" ref="paymentList"></virtual-payment-list>
      <loader-for-autoload v-if="showLoader && getNextLinkPayment && getPaymentListOriginal.length !== 0"></loader-for-autoload>

    </div>
  </div>
</template>

<script>
import CashFlowDiagramChart from "@/views/cashFlow/cashFlowDiagram/cash-flow-diagram-chart.vue";
import LoaderForAutoload from "@/components/loader-for-autoload.vue";
import VirtualPaymentList from "@/components/payment/payment-list/virtual-payment-list.vue";
import AppLoader from "@/components/loader.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from 'moment';
import PaymentPanelSelectPeriod from "@/components/payment/payment-panel-select-period.vue";
import PaymentDropdownExport from "@/components/payment/payment-dropdown-export.vue";


export default {
  name: 'cash-flow-diagram-new',
  components: {
    PaymentDropdownExport,
    PaymentPanelSelectPeriod, AppLoader, VirtualPaymentList, LoaderForAutoload, CashFlowDiagramChart},
  data() {
    return {
      isLoader: false,
      orderingName: '',
      showLoader: false,
      showLoaderButton: false,
      activeDate: ['', ''],
      dateDefault: null,
    }
  },
  computed: {
    ...mapGetters(["getFilterDataPaymentAxios", "getNextLinkPayment", "getPermissionsForUser", "getPermissionsByType", "getContracts","getPaymentList", "getPaymentListOriginal", "getDataPaymentModal", "getInfoDetailPayment", "getPaymentList", "getDateForViewPayments"]),


  },
  watch: {
    $route: function () {
      this.axiosChangeGetList();
    }
  },
  methods: {
    ...mapActions(['axiosGetPayment', 'axiosPaymentNextLinkPdf']),
    ...mapMutations(["setPaymentsList", "setNextLinkPayment", "setFilterDataPaymentAxios", "cancelPostPayment", "setDateForViewPayments", "setDataPaymentModal", "hideLoad", "showLoad"]),
    async downloadXLSXListPayment() {
      if(this.getNextLinkPayment) {
        this.showLoad()
        await this.axiosPaymentNextLinkPdf().then(()=> {
        })
        this.hideLoad()
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadXML()
      }, 500)
    },
    async downloadPDFListPayment() {
      if(this.getNextLinkPayment) {
        this.showLoad()
        await this.axiosPaymentNextLinkPdf(false).then(()=> {
        })
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadPDF()
      }, 1000)
    },
    changeDate(date) {
      this.activeDate = date
      this.axiosChangeGetList();
      this.$refs.chartCashFlow.setNewDate(date)
    },
    paymentNext() {
      this.showLoader = true;
      this.axiosPaymentNextLink(true).then(()=> {
        this.showLoader = false;
      })
    },
    changeOrderingStatus(data) {
      this.status_ordering = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrderingDueDate(data) {
      this.due_date_ordering_status = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrdering(ordering) {
      this.orderingName = ordering
      this.axiosChangeGetList();
    },
    axiosChangeGetList() {
      // let before = "";
      // let after = "";
      // if(""+this.dateSelected[0] !== 'null' && this.dateSelected[0].length !== 0) {
      //   before = ""+moment(this.dateSelected [0]).utcOffset(0, true).format("YYYY-MM-DD")+ " 00:00:00";
      // }
      // if(""+this.dateSelected[1] !== 'null' && this.dateSelected[1].length !== 0) {
      //   after = ""+moment(this.dateSelected [1]).utcOffset(0, true).format("YYYY-MM-DD")+ " 23:59:59";
      // }
      let type = "payment";
      if(this.$route.fullPath.indexOf("accounting-invoice") >= 0) {
        type = "invoice";
      }
      // if(this.firstRequest) {
      //   this.showLoad();
      //   this.firstRequest = false;
      // }
      let orderExecuteDate = ''
      if(this.status_ordering) {
        orderExecuteDate += '-status'
      } else {
        orderExecuteDate += 'status'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-paid_date'
      } else {
        orderExecuteDate += ',paid_date'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-execute_date'
      } else {
        orderExecuteDate += ',execute_date'
      }
      orderExecuteDate = this.orderingName ? this.orderingName : orderExecuteDate
      this.isLoader = true;
      let is_archived = this.$route.path.indexOf('archive') >= 0;
      this.setFilterDataPaymentAxios({is_archived: is_archived, ordering: orderExecuteDate, type: type, before: this.activeDate.date[0] ? moment(this.activeDate.date[0]).format('YYYY-MM-DD 00:00:00') : '',after: this.activeDate.date[1] ? moment(this.activeDate.date[1]).format('YYYY-MM-DD 23:59:59') : '', filter: this.filterData})
      // this.axiosGetPaymentBreakdown({is_archived: is_archived, ordering: orderExecuteDate, type: type, before: this.activeDate[0] ? moment(this.activeDate[0]).format('YYYY-MM-DD 00:00:00') : '',after: this.activeDate[1] ? moment(this.activeDate[1]).format('YYYY-MM-DD 23:59:59') : '', filter: this.filterData}).then(()=> {
      //   this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
      //   this.isLoader = false;
      // });
      this.axiosGetPayment({is_archived: is_archived, noLoad: true, ordering: orderExecuteDate, before: this.activeDate.date[0] ? moment(this.activeDate.date[0]).format('YYYY-MM-DD 00:00:00') : '',after: this.activeDate.date[1] ? moment(this.activeDate.date[1]).format('YYYY-MM-DD 23:59:59') : '', filter: this.filterData, type: type}).then(()=> {
        this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
          this.isLoader = false;
      });
    },

  },
  mounted() {
    if(localStorage.getItem(this.$route.path+"22") !== null && localStorage.getItem(this.$route.path+"22") !== undefined) {
      let date = JSON.parse(localStorage.getItem(this.$route.path+"22"));
      if((""+date[0]) !== "null") {
        date[0] = new Date(moment(date[0]).format("YYYY,MM,DD"));
      }
      if((""+date[1]) !== "null") {
        date[1] = new Date(moment(date[1]).format("YYYY,MM,DD"));
      }
      this.dateDefault = date
    }
  }
}
</script>

<style scoped lang="scss">
.wrap-cash {
  padding: 17px 24px 24px;
}
.wrap-diagram {
  height: calc(100vh - 56px - 51px - 17px - 24px);
  width: calc(50% - 12px);
  /deep/ {
    .chart-block-c {
      height: calc(100% - 44px) !important;
    }
  }
}
.wrap-list {
  width: calc(50% - 12px);
  height: calc(100vh - 56px - 51px - 17px - 24px);
  /deep/ {
    .block-payments {
      margin: 0;
    }
  }
}
.dart-mode {
  .period-active {
    color: var(--color-white-always) !important
  }
  .button-archive {
    svg {
      path {
        fill: transparent !important;
        stroke: var(--color-blue-dark)
      }
    }
  }
}
/deep/ {
  .expense, .income {
    min-width: 100px;
  }
  .counterparty {
    min-width: 4px;
  }
  .account {
    min-width: 65px;
  }
}
.loader-payment {
  border: 1px solid var(--color-blue-dark);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: var(--color-bgc-page-white) !important;
}
/deep/ .loader-chart {
        border-radius: 8px;
        background-color: var(--color-bgc-page-white) !important;
   .sk-fading-circle {
    width: 40px;
    height: 40px;
  }
      }
.payment-block {
  overflow-y: auto;
  overflow-x: hidden;
}
.wrap-diagram {
    border: 1px solid var(--color-blue-dark);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: var(--color-bgc-page-white) !important;
  /deep/ {
    .buttons-period {
      display: none;
    }
    .date-selected {
      opacity: 0;
    }
  }
}
.wrap-datepicker {
  position: absolute;
  right: 24px;
  top: 64px;
  width: max-content;
  ///deep/ {
  //  &>*:nth-child(1) {
  //    position: relative;
  //    left: -200px;
  //  }
  //}

}
///deep/ .panel-selected {
//  position: fixed;
//  top: 100px;
//  right: 0;
//}
.dart-mode {
  .period-active {
    color: var(--color-white-always) !important
  }
  .button-archive {
    svg {
      path {
        fill: transparent !important;
        stroke: var(--color-blue-dark)
      }
    }
  }
}
.button-archive {
  width: 34px;
  height: 34px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--color-blue-dark);
  margin-left: 8px;
}
.download-list {
  /deep/ {
    .btn {
      svg {
        path {
          fill: var(--color-blue-dark) !important
        }
      }
      width: 36px;
      height: 34px;
      border: 1px solid var(--color-blue-dark) !important;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }
  }
}
.date-selected {
  width: max-content;
  /deep/ {
    &>*:nth-child(1) {
      position: absolute;
      top: 74px;
      left: calc(-100vw + 575px);
    }
  }
}
.archive-mode {
  /deep/ {
    &>*:nth-child(1) {
      left: calc(-100vw + 583px);
    }
  }
}
/deep/ {
  .end-block {
    p {
      display: none;
    }
  }
}
/deep/ .due-date {
  border-left: 1px solid var(--color-border);
}
/deep/ .buttons-period>*:nth-child(1) {
  display: none;
}
</style>