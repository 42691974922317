<template>
  <div class="full-screen" :class="{'vessel-task': $route.path.indexOf('vessel-task') !== -1}">
    <modal-checklist ref="modalCheckList" @changeEmitAfterClose="refreshListSelect"></modal-checklist>
    <modal-delete-tag ref="modalDeleteTag" @renderTask="renderNew"></modal-delete-tag>
    <select-on-the-map :is-group-country="true" @closeModal="closeModalMap" class="map-modal" @changeRegion="changeRegion" ref="modalMap"></select-on-the-map>
    <modal-slots :show-mod="showWarningChangeRegion" @close="closeModalWarn" @apply="deleteShips">
      <template v-slot:title>
        <p class="fz18 fw500">Warning change region</p>
      </template>
      <p class="fz14 text-modal-warn text-dark">You really want to change the region data, all ships of the following regions will be removed from the task: <span class="fz14 fw500 text-dark">{{textModalRegion}}</span></p>
    </modal-slots>
    <transition name="fade">
      <div class="view-file" v-if="showViewFile">
        <div class="container">
          <div class="view-file__top-panel">
            <div class="edit-file-name">
              <button class="edit-file-name__button fz14 fw500" v-on:click="editNameInModal" v-if="!editNameModeInModal">
                {{editNameFileInModal}}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="white"/>
                </svg>
              </button>
              <div class="edit-file-name__block" v-if="editNameModeInModal && getPermissionEditAndRemoveFile() === true">
                <input type="text" class="fz14 fw500 text-white" v-model="editNameFileInModal">
                <button v-on:click="saveEditNameInModal">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="white"/>
                  </svg>
                </button>
                <button v-on:click="closeEditNameInModal">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.51424 7.57874C6.21661 7.28404 6.21661 6.80625 6.51424 6.51155C6.81186 6.21685 7.29441 6.21685 7.59204 6.51155L17.4868 16.2975C17.7844 16.5922 17.7844 17.07 17.4868 17.3647C17.1892 17.6594 16.7066 17.6594 16.409 17.3647L6.51424 7.57874Z" fill="white"/>
                    <path d="M16.4091 6.63547C16.7067 6.34077 17.1893 6.34077 17.4869 6.63547C17.7845 6.93016 17.7845 7.40796 17.4869 7.70266L7.59214 17.4886C7.29451 17.7833 6.81196 17.7833 6.51434 17.4886C6.21671 17.1939 6.21671 16.7161 6.51434 16.4214L16.4091 6.63547Z" fill="white"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="block-scale-button">
              <div class="block-scale-button__block">
                <button v-on:click="zoomOut">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2222 12.7778L5.77787 12.7778C5.34849 12.7778 5 12.4293 5 12.0001C5 11.5707 5.34849 11.2222 5.77787 11.2222L11.2222 11.2222L12.7778 11.2222L18.2223 11.2222C18.6515 11.2222 19 11.5707 19 12.0001C19 12.4293 18.6515 12.7778 18.2223 12.7778L12.7778 12.7778L11.2222 12.7778Z" fill="white"/>
                  </svg>
                </button>
                <p class="fz13 fw500 text-white-always">{{ zoomFile }}%</p>
                <button v-on:click="zoomIn">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"/>
                  </svg>
                </button>
              </div>
              <button v-on:click="zoomSetDefault">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.77951 5.77792L5.77951 5.07788C5.39293 5.07788 5.07955 5.3913 5.07955 5.77792L5.77951 5.77792ZM18.2224 18.2221L18.2249 18.9222C18.6095 18.9208 18.921 18.6093 18.9224 18.2247L18.2224 18.2221ZM18.9351 14.6819C18.9365 14.2953 18.6243 13.9808 18.2377 13.9794C17.8511 13.978 17.5366 14.2903 17.5352 14.6769L18.9351 14.6819ZM14.6775 17.5349C14.2909 17.5363 13.9787 17.8508 13.9801 18.2374C13.9815 18.624 14.296 18.9363 14.6826 18.9349L14.6775 17.5349ZM9.33462 6.47795C9.7212 6.47795 10.0346 6.16454 10.0346 5.77792C10.0346 5.3913 9.7212 5.07788 9.33462 5.07788L9.33462 6.47795ZM5.07955 9.33341C5.07955 9.72003 5.39293 10.0334 5.77951 10.0334C6.16609 10.0334 6.47947 9.72003 6.47947 9.33341L5.07955 9.33341ZM5.28456 6.27292L17.7275 18.7171L18.7174 17.7271L6.27446 5.28292L5.28456 6.27292ZM18.9224 18.2247L18.9351 14.6819L17.5352 14.6769L17.5225 18.2196L18.9224 18.2247ZM18.2199 17.5221L14.6775 17.5349L14.6826 18.9349L18.2249 18.9222L18.2199 17.5221ZM5.77951 6.47795L9.33462 6.47795L9.33462 5.07788L5.77951 5.07788L5.77951 6.47795ZM5.07955 5.77792L5.07955 9.33341L6.47947 9.33341L6.47947 5.77792L5.07955 5.77792Z" fill="white"/>
                  <path d="M18.2209 5.77824L18.9209 5.77824C18.9209 5.39162 18.6075 5.0782 18.2209 5.0782L18.2209 5.77824ZM5.77802 18.2225L5.07806 18.225C5.07944 18.6096 5.39089 18.9211 5.7755 18.9225L5.77802 18.2225ZM9.31786 18.9352C9.70444 18.9366 10.0189 18.6244 10.0203 18.2377C10.0217 17.8511 9.70948 17.5366 9.3229 17.5352L9.31786 18.9352ZM6.46522 14.6772C6.46383 14.2906 6.14932 13.9783 5.76274 13.9797C5.37617 13.9811 5.06392 14.2956 5.06531 14.6822L6.46522 14.6772ZM17.521 9.33373C17.521 9.72035 17.8343 10.0338 18.2209 10.0338C18.6075 10.0338 18.9209 9.72035 18.9209 9.33373L17.521 9.33373ZM14.6658 5.0782C14.2792 5.0782 13.9658 5.39162 13.9658 5.77824C13.9658 6.16486 14.2792 6.47827 14.6658 6.47827L14.6658 5.0782ZM17.726 5.28323L5.28307 17.7275L6.27296 18.7175L18.7159 6.27324L17.726 5.28323ZM5.7755 18.9225L9.31786 18.9352L9.3229 17.5352L5.78054 17.5224L5.7755 18.9225ZM6.47797 18.2199L6.46522 14.6772L5.06531 14.6822L5.07806 18.225L6.47797 18.2199ZM17.521 5.77824L17.521 9.33373L18.9209 9.33373L18.9209 5.77824L17.521 5.77824ZM18.2209 5.0782L14.6658 5.0782L14.6658 6.47827L18.2209 6.47827L18.2209 5.0782Z" fill="white"/>
                </svg>
              </button>
            </div>
            <div class="block-option-button">
              <button class="block-option-button__download" v-on:click="downloadFile">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.034 5C13.034 4.6134 12.7206 4.3 12.334 4.3C11.9474 4.3 11.634 4.6134 11.634 5H13.034ZM12.334 14L11.8399 14.4959C12.113 14.768 12.5549 14.768 12.828 14.4959L12.334 14ZM16.828 10.5102C17.1019 10.2373 17.1027 9.79412 16.8298 9.52026C16.5569 9.2464 16.1137 9.24561 15.8399 9.51848L16.828 10.5102ZM8.82804 9.51848C8.55419 9.24561 8.11097 9.2464 7.8381 9.52026C7.56522 9.79412 7.56602 10.2373 7.83987 10.5102L8.82804 9.51848ZM19.7006 16C19.7006 15.6134 19.3872 15.3 19.0006 15.3C18.614 15.3 18.3006 15.6134 18.3006 16H19.7006ZM6.03397 16C6.03397 15.6134 5.72057 15.3 5.33397 15.3C4.94737 15.3 4.63397 15.6134 4.63397 16H6.03397ZM11.634 5V14H13.034V5H11.634ZM12.828 14.4959L16.828 10.5102L15.8399 9.51848L11.8399 13.5041L12.828 14.4959ZM12.828 13.5041L8.82804 9.51848L7.83987 10.5102L11.8399 14.4959L12.828 13.5041ZM17.0006 18.3H7.33397V19.7H17.0006V18.3ZM18.3006 16V17H19.7006V16H18.3006ZM6.03397 17V16H4.63397V17H6.03397ZM7.33397 18.3C6.616 18.3 6.03397 17.718 6.03397 17H4.63397C4.63397 18.4912 5.84281 19.7 7.33397 19.7V18.3ZM17.0006 19.7C18.4918 19.7 19.7006 18.4912 19.7006 17H18.3006C18.3006 17.718 17.7186 18.3 17.0006 18.3V19.7Z" fill="white"/>
                </svg>
              </button>
              <button class="block-option-button__delete" v-on:click="deleteFileInModal" v-if="getPermissionEditAndRemoveFile() === true">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="white"/>
                </svg>
              </button>
              <button class="block-option-button__close" v-on:click="hideViewFileModal">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.51424 7.57874C6.21661 7.28404 6.21661 6.80625 6.51424 6.51155C6.81186 6.21685 7.29441 6.21685 7.59204 6.51155L17.4868 16.2975C17.7844 16.5922 17.7844 17.07 17.4868 17.3647C17.1892 17.6594 16.7066 17.6594 16.409 17.3647L6.51424 7.57874Z" fill="white"/>
                  <path d="M16.4091 6.63547C16.7067 6.34077 17.1893 6.34077 17.4869 6.63547C17.7845 6.93016 17.7845 7.40796 17.4869 7.70266L7.59214 17.4886C7.29451 17.7833 6.81196 17.7833 6.51434 17.4886C6.21671 17.1939 6.21671 16.7161 6.51434 16.4214L16.4091 6.63547Z" fill="white"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="view-file__main">
            <button class="button-prev" v-on:click="prevFile">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.71834 11.5972L14.8343 2.29486C15.1014 2.023 15.5339 2.02345 15.8006 2.29626C16.067 2.56904 16.0664 3.01092 15.7992 3.28299L7.16851 12.0911L15.7992 20.8084C16.0663 21.0805 16.067 21.5221 15.8006 21.7949C15.6669 21.9317 15.4918 22.0001 15.3166 22.0001C15.142 22.0001 14.9675 21.9321 14.8341 21.7963L5.71834 12.5851C5.58968 12.4544 5.51749 12.2765 5.51749 12.0911C5.51749 11.9058 5.58989 11.7281 5.71834 11.5972Z" fill="white"/>
              </svg>
            </button>
            <div class="block-with-file">
              <img v-if="formatThisImg" :src="viewFileSrc" alt="" :style="{transform: 'scale(' + zoomFile/100 + ')'}">
              <video v-if="formatThisVideo" controls="controls" :style="{transform: 'scale(' + zoomFile/100 + ')'}" :src="viewFileSrc"></video>
              <p v-if="!formatThisImg && !formatThisVideo" class="fz16 fw500 text-white">Unable to display "File name".<br>Download the file to view it on your device</p>
            </div>
            <button class="button-next" v-on:click="nextFile">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.2817 12.4028L9.16569 21.7051C8.89857 21.977 8.46609 21.9765 8.19942 21.7037C7.93295 21.431 7.93364 20.9891 8.2008 20.717L16.8315 11.9089L8.2008 3.1916C7.93368 2.91949 7.93299 2.47789 8.19942 2.20508C8.3331 2.06832 8.50823 1.99994 8.68336 1.99994C8.85805 1.99994 9.03249 2.06786 9.16593 2.20367L18.2817 11.4149C18.4103 11.5456 18.4825 11.7235 18.4825 11.9089C18.4825 12.0942 18.4101 12.2719 18.2817 12.4028Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>

      </div>
    </transition>
    <div class="top-panel" v-if="$route.path.indexOf('vessel-task') === -1">
      <div class="container">
        <div class="crumbs">
          <router-link to="/tasks" class="bread__link">Tasks</router-link>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40283L5.18326 9.70514C4.91614 9.977 4.48367 9.97655 4.217 9.70373C3.95053 9.43096 3.95122 8.98908 4.21837 8.71701L6.84906 5.90885L4.21837 3.19159C3.95125 2.91948 3.95057 2.47789 4.217 2.20508C4.35068 2.06832 4.52581 1.99994 4.70094 1.99994C4.87562 1.99994 5.05006 2.06786 5.1835 2.20367L8.29924 5.41491C8.42789 5.54562 8.50009 5.72351 8.50009 5.90885C8.50009 6.09419 8.42769 6.27187 8.29924 6.40283Z" fill="#9AA3B0"/>
          </svg>
          <p class="bread__link">{{getTaskInfo.name}}</p>
        </div>
        <div class="block-buttons">
          <p class="fz13 status" :class="{'status-open': getTaskInfo.status.toUpperCase() === 'OPEN', 'status-done': getTaskInfo.status.toUpperCase() === 'DONE', 'status-inProgress': getTaskInfo.status.toUpperCase() === 'IN PROGRESS'}">{{getTaskInfo.status}}</p>
          <button class="status-button fz14 fw500" :class="{'status-open-button': getStatusButtonNext().toUpperCase() === 'OPEN', 'status-done-button': getStatusButtonNext().toUpperCase() === 'DONE', 'status-inProgress-button': getStatusButtonNext().toUpperCase() === 'IN PROGRESS'}" v-on:click="changeStatusTask(getStatusButtonNext())">{{ getStatusButtonNext() }}</button>
          <button class="copy-link" v-on:click="copyLink" title="Copy Link">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.633 16.1952L8.74761 18.0806C7.96598 18.8622 6.70019 18.8622 5.91921 18.0807C5.13808 17.2996 5.13808 16.0337 5.91906 15.2527L9.69042 11.4813C10.4714 10.7003 11.7373 10.7003 12.5183 11.4813C12.7786 11.7417 13.2008 11.7417 13.4611 11.4813C13.7214 11.221 13.7214 10.7989 13.4611 10.5385C12.1594 9.23683 10.0493 9.23683 8.74761 10.5385L4.97627 14.3099C3.67458 15.6116 3.67458 17.7217 4.97627 19.0234C6.27781 20.3257 8.38807 20.3257 9.69045 19.0234L11.5758 17.138C11.8362 16.8777 11.8362 16.4555 11.5758 16.1952C11.3155 15.9348 10.8933 15.9349 10.633 16.1952Z" fill="#2C2C2C"/>
              <path d="M19.0236 4.97627C17.7219 3.67458 15.6111 3.67458 14.3094 4.97627L12.0474 7.23828C11.787 7.49863 11.787 7.92076 12.0474 8.1811C12.3077 8.44145 12.7299 8.44145 12.9902 8.1811L15.2522 5.91909C16.0332 5.13808 17.2998 5.13808 18.0808 5.91909C18.8617 6.70006 18.8617 7.96598 18.0808 8.74695L13.9327 12.895C13.1517 13.676 11.8858 13.676 11.1049 12.895C10.8445 12.6347 10.4224 12.6347 10.1621 12.895C9.90171 13.1553 9.90171 13.5775 10.1621 13.8378C11.4638 15.1395 13.5739 15.1395 14.8756 13.8378L19.0236 9.6898C20.3253 8.3881 20.3253 6.27797 19.0236 4.97627Z" fill="#2C2C2C"/>
            </svg>
          </button>
          <task-full-info-button-option :archivestatus="getTaskInfo.is_archived" :status="getStatusButtonPrev()" @emitChangeStatus="changeStatusTask" @changeEmitArchiveTask="changeArchiveTask" class="button-option"></task-full-info-button-option>
        </div>
      </div>
    </div>
    <div class="main">
<!--      <iframe :src="getlinkPDF()"-->
<!--              style="width: 600px; height: 600px;">Ваш браузер не поддерживает фреймы</iframe>-->
      <div class="left-panel">
<!--        <pre>-->
<!--          {{getVesselTaskInfo}}-->
<!--        </pre>-->
        <div class="wrap-block-for-margin">
          <p class="fz13 text-gray">Created</p>
          <p class="fz13 text-dark">{{getTaskInfo.created_at | formatDateAndTimeSpace}}</p>
        </div>
        <div class="wrap-block-for-margin">
          <p class="fz13 text-gray" v-if="$route.path.indexOf('vessel-task')>=0">Region</p>
          <div class="block-with-select-list" v-if="$route.path.indexOf('vessel-task')>=0">
            <p class="fz13 text-dark text-region" v-if="!showSelectedRegion" v-on:click="showSelectedRegionMet" v-html="getRegionText(getVesselTaskInfo !== null ? getVesselTaskInfo.region : [])"></p>
<!--{{getActiveGroupRegion(defaultRegionActive)}}-->
            <dropdown-selected-region
                :class="{'displayNone' : !showSelectedRegion}"
                title="Region"
                class="counterparties-drop "
                :is-multiple="true"
                :list = "groupRegionList"
                :active-item="getActiveGroupRegion(defaultRegionActive)"
                ref="regionListDrop"
                @select="setActiveRegion"
                @closeDrop="closePeriodList"
                @clickMap="openMapModal"
            ></dropdown-selected-region>
          </div>
        </div>

        <div class="wrap-block-for-margin">
          <div class="block-with-select-list" v-if="$route.path.indexOf('vessel-task')>=0 && getTaskInfo.type !== 'SHIP_SEARCH_WITHOUT_CARGO' && getTaskInfo.type !== 'CARGO_SEARCH_WITH_SHIP' && getTaskInfo.type !== 'CARGO_SEARCH_WITHOUT_SHIP' && getVesselTaskInfo !== null">
            <!--          <p class="fz13 text-dark" v-on:click="clientSelectedListShow" v-if="getVesselTaskInfo.clients !== null && getVesselTaskInfo.clients !== undefined && !clientSelectedList">{{getVesselTaskInfo.clients.company_name}}</p>-->
            <!--          <p class="fz13 text-dark" v-on:click="clientSelectedListShow" v-if="(getVesselTaskInfo.clients === null || getVesselTaskInfo.clients === undefined) && !clientSelectedList">Select Charterer</p>-->
            <!--          <counterparties-drop-->
            <!--              v-if="clientSelectedList"-->
            <!--              title="Charterer"-->
            <!--              ref="clientListDrop"-->
            <!--              :list = "getClientsFormAddModal"-->
            <!--              :active-item="defaultClientActive"-->
            <!--              @select="setActiveClient"-->
            <!--              @close="closeClientList"-->
            <!--              @mountedEnd="clientSelectedListShow"-->
            <!--          ></counterparties-drop>-->
            <async-select
                id="counterparty"
                label="Charterer"
                url="/client/dropdown"
                :params="{is_archived: false, ordering: 'company_name'}"
                watchKey="company_name"
                :allowEmpty="true"
                :closeOnSelect="true"
                v-model="clientActive"
                placeholder="Search Charterer"
            />
          </div>
        </div>

        <div class="wrap-block-for-margin">
          <div class="block-with-select-list">
            <async-select
                :id="'sd2sd2112'"
                label="Vessel"
                url="/contract/dropdown"
                :params="{is_archive: false, contract_type: ['SHIP']}"
                :allowEmpty="true"
                :closeOnSelect="true"
                v-model="shipActive"
                :placeholder="'Search Vessel'"
            />
          </div>
        </div>

        <div class="wrap-block-for-margin">
          <div class="block-with-select-list" v-if="$route.path.indexOf('vessel-task')>=0 && getVesselTaskInfo !== null">
            <async-select
                id="counterparty"
                label="Broker"
                url="/client/dropdown"
                :params="{is_archived: false, ordering: 'company_name', has_broker: true}"
                watchKey="company_name"
                :allowEmpty="true"
                :closeOnSelect="true"
                v-model="brokerActive"
            />
          </div>
        </div>
        <div class="wrap-block-for-margin">
          <p class="fz13 text-gray" v-if="$route.path.indexOf('vessel-task')>=0 && getVesselTaskInfo !== null"><span v-if="getVesselTaskInfo.cargo_text !== null">Cargo</span></p>
          <div class="block-with-select-list" v-if="$route.path.indexOf('vessel-task')>=0 && getVesselTaskInfo !== null && getVesselTaskInfo.cargo_text !== null">
            <p class="text fz13 text-dark" v-on:click="showEditCargoText" v-if="getVesselTaskInfo.cargo_text.length !== 0">{{getVesselTaskInfo.cargo_text}}</p>
            <p class="text fz13 text-dark" v-on:click="showEditCargoText" v-if="getVesselTaskInfo.cargo_text.length === 0">Set cargo text</p>
            <textarea class="" v-model="cargoText" v-if="editCargoTextShow" @focusout="closeEditCargoText"></textarea>
          </div>
        </div>

        <div class="wrap-block-for-margin">
          <p class="fz13 text-gray">Due date</p>
          <div class="block-due-date">
            <!--          <button title="Edit Due Date" v-if="getTaskInfo.execute_time !== null" :class="{'expired': checkDate(getTaskInfo.execute_time), 'buttonDashedBlue': !checkDate(getTaskInfo.execute_time)}" class="fz13" v-on:click="openDatePickerChangeDateForTask(getTaskInfo.execute_time)">{{getFormDueDate(getTaskInfo.execute_time)}}</button>-->
            <!--                    <button title="Edit Due Date" v-if="getTaskInfo.execute_time === null" class="fz13 setDateIfNoDate buttonDashedBlue" v-on:click="openDatePickerChangeDateForTask('')">Set due date</button>-->
            <!--          <task-list-change-date @changeDateForTaskEmit="changeDateForTask" :ref="'changeDate'" class="changeDateTask"></task-list-change-date>-->
            <select-due-date
                :showSelectTime="true"
                :textNoSelect="'Set due date'"
                class="changeDateTask"
                ref="changeDate"
                :activeDueDate="(getTaskInfo.execute_time !== null && (''+getTaskInfo.execute_time).length !== 0) ? getTaskInfo.execute_time : null"
                :activeCustomDays="getActiveCustomDays(getTaskInfo.days_for_repeat)"
                :due_date_status="getTaskInfo.due_date_status"
                @close="changeDateForTask"
                :buttonShowAfterHover="true"
            ></select-due-date>
          </div>
        </div>
        <div class="wrap-block-for-margin" v-if="getTaskInfo && getTaskInfo.done_date">
          <p class="fz13 text-gray">Done date</p>
          <p class="fz13 text-dark">{{getTaskInfo.done_date | formatDateAndTimeSpace2}}</p>
        </div>
      <div class="wrap-block-for-margin">
        <p class="fz13 text-gray">Assigned</p>
        <div class="assignee">
<!--          <button title="Set Assigned" class="one-user one-user-button" v-if="getTaskInfoConvert.executors.length === 1" v-on:click="openUserSelectForTask(getTaskInfoConvert.executors)">-->
<!--            <img v-if="getTaskInfoConvert.executors[0].avatar !== null" :src="getTaskInfoConvert.executors[0].avatar" class="img-profile" alt="">-->
<!--            <svg v-if="getTaskInfoConvert.executors[0].avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <circle cx="80" cy="80" r="80" fill="white"/>-->
<!--              <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>-->
<!--            </svg>-->
<!--            <button class="fz13 text-dark1">{{getTaskInfoConvert.executors[0].first_name}} {{getTaskInfoConvert.executors[0].last_name}}</button>-->
<!--          </button>-->
<!--          <button title="Set Assigned" class="more-user more-user-button" v-if="getTaskInfoConvert.executors.length > 1" v-on:click="openUserSelectForTask(getTaskInfoConvert.executors)">-->
<!--            <div class="user-image-wrap" v-for="(u, m) in getTaskInfoConvert.executors" :key="m" :style="'z-index:'+ (getTaskInfoConvert.executors.length - m)">-->
<!--              <div class="user-image" v-if="m<6">-->
<!--                <img v-if="u.avatar !== null" :src="u.avatar" class="img-profile" alt="">-->
<!--                <svg v-if="u.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <circle cx="80" cy="80" r="80" fill="white"/>-->
<!--                  <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>-->
<!--                </svg>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="moreUser fz11 text-blue-dark" v-if="getTaskInfoConvert.executors.length > 6">-->
<!--              +{{getTaskInfoConvert.executors.length - 6}}-->
<!--            </div>-->
<!--          </button>-->
<!--          <button class="no-assigned svg-white-dart-stroke" v-if="getTaskInfoConvert.executors.length === 0" v-on:click="openUserSelectForTask([])">-->
<!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M12.212 10.8719C13.9294 10.8719 15.3223 9.47945 15.3223 7.7612C15.3223 6.04334 13.9302 4.65048 12.212 4.65088C10.4949 4.65088 9.10206 6.04374 9.10206 7.7612C9.10206 9.47945 10.4949 10.8719 12.212 10.8719Z" stroke="#4B4B4B" stroke-width="1.4"/>-->
<!--              <path d="M6.41675 17.2501C6.41675 18.0457 7.06159 18.6906 7.85722 18.6906H16.9136C17.7088 18.6906 18.3537 18.0457 18.3537 17.2501V16.4378C18.3537 14.6961 16.9425 13.2981 15.2017 13.2981H9.57984C7.83937 13.2981 6.41675 14.6969 6.41675 16.4378V17.2501Z" stroke="#4B4B4B" stroke-width="1.4"/>-->
<!--            </svg>-->
<!--            <p class="fz13 text-dark">No assignee</p>-->
<!--          </button>-->
          <async-select-employees :showNoEmployees="true" :is-multiple="true" v-model="getTaskInfoConvert.executors" @close="changeUserForTask" :permissionAssigned="getTaskInfo.type.indexOf('MANUAL') >=0 ? '' : 'ship_task'" ></async-select-employees>
<!--          <task-list-change-assignee :placeholder="true" :permissionAssigned="getTaskInfo.type.indexOf('MANUAL') >=0 ? '' : 'cargoVesselTask'" v-if="(getPermissionsByType('assign_tasks_to') && getTaskInfo.type === 'MANUAL') || (getPermissionsByType('assign_contracts_to') && getTaskInfo.type !== 'MANUAL')" @changeEmployeeForTaskEmit="changeUserForTask" class="change-assignee" :assignee="getTaskInfoConvert.executors" :ref="'changeAssignee'"></task-list-change-assignee>-->
        </div>
      </div>
       <div class="wrap-block-for-margin">
         <p class="fz13 text-gray">Creator</p>
         <div class="creator-block" v-for="(c, i) in getTaskInfoConvert.creator" :key="'23s' + i">
           <img v-if="c.avatar !== null" :src="c.avatar" class="img-profile" alt="">
           <svg v-if="c.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
             <circle cx="80" cy="80" r="80" fill="white"/>
             <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
           </svg>
           <p class="fz13 text-dark">{{c.first_name}} {{c.last_name}}</p>
         </div>
       </div>
     <div class="wrap-block-for-margin">
       <p class="fz13 text-gray">Priority</p>
       <div class="block-priority">
          <span class="priority">
          <span v-html="getTaskInfoConvert.priority.svg"></span>
            <p class="fz13 text-dark">{{getTaskInfoConvert.priority.name}}</p>
          </span>
         <button title="Edit Priority" class="priority-button buttonDashedBlue fz13" v-on:click="openChangePriorityDropdown(getTaskInfoConvert.priority)">
           Set priority
         </button>
         <task-list-change-priority class="changePriority" :ref="'changePriorityDropdown'" @changePriorityForTaskEmit="changePriorityForTask"></task-list-change-priority>
       </div>
     </div>
        <div class="wrap-block-for-margin">
          <p class="fz13 text-gray">Tags</p>
          <div class="block-with-option-tag">
            <div class="wrap-block-tag" v-for="(t,l) in getTaskInfoConvert.tags" :key="'2342sadasd'+l">
              <input class="fz13 renameTagInput" type="text" @focusout="editModeFalse" v-model="tagNameEdit" v-if="t.editStatus" v-on:keyup.enter="renameTagAxios(t)">

              <div v-if="l<2" class="tag-wrap">
                <div class="button-tag" v-if="!t.editStatus">
                  <p class="fz13 text-dark">{{ t.name }}</p>
                  <!--          <tag-options class="tag-options"></tag-options>-->

                  <div class="tag-options-block">
                    <task-full-info-tag-option @eventDeleteTagInTask="eventDeleteTag(t.id)" @eventRenameTag="editModeTrue(l)"></task-full-info-tag-option>
                  </div>
                  <button class="button-drop-tags1 fz13 fw500" v-on:click="eventDeleteTagInTask(getTaskInfoConvert.tags, t)">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.05373 4.42533C2.81022 4.18421 2.81022 3.79329 3.05373 3.55217C3.29724 3.31106 3.69206 3.31106 3.93557 3.55217L8.94574 8.47332C9.18925 8.71444 9.18925 9.10536 8.94574 9.34647C8.70223 9.58759 8.30741 9.58759 8.0639 9.34647L3.05373 4.42533Z" fill="#319FEF"/>
                      <path d="M8.06398 3.65356C8.3075 3.41245 8.70231 3.41245 8.94582 3.65356C9.18933 3.89468 9.18933 4.2856 8.94582 4.52672L3.93565 9.44786C3.69214 9.68898 3.29732 9.68898 3.05381 9.44786C2.8103 9.20675 2.8103 8.81582 3.05381 8.57471L8.06398 3.65356Z" fill="#319FEF"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="numberMore fz11 text-dark" v-if="getTaskInfoConvert.tags.length>2">
              +{{getTaskInfo.tags.length - 2}}
            </div>
            <button title="Edit Tags" class="button-tags-filter" v-on:click="openChangeSelectTag(getTaskInfoConvert.tags)">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.96699 11.837L4.967 11.837L4.96355 11.8336C4.80041 11.6727 4.7 11.4504 4.7 11.2V5.60001C4.7 5.10259 5.10259 4.7 5.60001 4.7H11.2C11.4444 4.7 11.6702 4.79917 11.839 4.96494L19.033 12.159C19.2001 12.326 19.3 12.5504 19.3 12.8C19.3 13.0444 19.2009 13.2701 19.0351 13.4389L13.437 19.037C13.2756 19.1984 13.0478 19.3 12.8 19.3C12.5541 19.3 12.3298 19.1998 12.167 19.037L4.96699 11.837Z" stroke="#BDCBD6" stroke-width="1.4"/>
                <path d="M7 8.2C7 8.86401 7.53599 9.4 8.2 9.4C8.86397 9.4 9.4 8.86401 9.4 8.2C9.4 7.53599 8.86401 7 8.2 7C7.53599 7 7 7.53599 7 8.2Z" fill="#BDCBD6"/>
              </svg>
            </button>
            <task-list-tag-select @deleteUsageTag="deleteTagWhichUsed" @renderTags="renderActivity" @changeTagsForTaskEmit="changeTagsForTask" class="tag-select-dropdown" :ref="'tagSelect'"></task-list-tag-select>
          </div>
        </div>

      </div>
      <div class="main-block">
        <div class="edit-block-wrap">
          <p :contenteditable="getPermissionsForUserCanEditTask" @focus="focusName = true" @focusout="changeName" :class="{'errorName': errorName}" class="edit-block name-task fz16 fw500 text-dark">{{getTaskInfo.name}}</p>
          <span v-if="errorName && !focusName && loadingPage" class="fz14 text-gray">Task Name</span>
        </div>
<!--        <div class="edit-block-wrap" v-if="getPermissionsForUserCanEditTask">-->
<!--          <p v-if="getPermissionsForUserCanEditTask" :contenteditable="descriptionEditText" @focus="focusDescription=true" @focusout="changeDescription" id="textDescription" ref="textDescription" class="edit-block description-task fz14 text-dark">{{getTaskInfo.description}}</p>-->
<!--          <span v-if="descriptionTask.length === 0 && !focusDescription && loadingPage" class="fz14 text-gray">Task Description</span>-->
<!--        </div>-->
<!--        <Editor @blur="changeDescription()" @focusout="changeDescription" @input="changeDescription" :image-provider="imageProvider" v-model="getTaskInfo.description" />-->
        <vue-editor :disabled="!getPermissionsForUserCanEditTask" v-model="getTaskInfo.description" class="edit-block-wrap" @blur="changeDescription" />
<!--        {{getTaskInfo.description}}-->
        <!--
  {{$refs.textDescription.innerHTML}}-->
<!--sd-->
<!--        <p v-if="!getPermissionsForUserCanEditTask" class="edit-block name-task fz16 fw500 text-dark">{{getTaskInfo.name}}</p>-->
<!--        <p v-if="!getPermissionsForUserCanEditTask" class="edit-block description-task fz14 text-dark">{{getTaskInfo.description}}</p>-->
<!--        <div class="files-block-wrap">-->
<!--          <div class="name-block">-->
<!--            <p class="fw500 fz16 text-dark">Attachments</p>-->
<!--            <button class="blue-button-text fz13 fw500" v-if="getPermissionsForUserCanAddFile">-->
<!--              <input type="file" multiple  id="files" ref="files" v-on:change="handleFilesUpload()">-->
<!--              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M10.3333 5.33332H6.66668V1.66662C6.66668 1.2987 6.36798 1 5.99994 1C5.63202 1 5.33332 1.2987 5.33332 1.66662V5.33332H1.66662C1.2987 5.33332 1 5.63202 1 5.99994C1 6.36798 1.2987 6.66668 1.66662 6.66668H5.33332V10.3333C5.33332 10.7013 5.63202 11 5.99994 11C6.36798 11 6.66668 10.7013 6.66668 10.3333V6.66668H10.3333C10.7013 6.66668 11 6.36798 11 5.99994C11 5.63202 10.7013 5.33332 10.3333 5.33332Z" fill="white"/>-->
<!--              </svg>-->
<!--              Add-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="files-block" :key="'asd'+getFileForTask">-->
<!--            <div class="file-type" v-for="(f, a) in getFileForTask" :key="'23s'+a">-->
<!--              <img v-on:click="showViewFileModal(a)" :src="f.thumbnail" class="img-preview" alt="preview" v-if="f.thumbnail !== null && getItsImg(f.thumbnail)">-->
<!--              <img v-on:click="showViewFileModal(a)" src="../../assets/imgNone.svg" v-if="f.thumbnail === null || !getItsImg(f.thumbnail)">-->
<!--              <p :contenteditable="f.editMode && getPermissionEditAndRemoveFile() === true" v-if="getPermissionsForUserDeleteOrRenameFile" v-on:keyup.enter="changeNameFile(f)" v-on:dblclick="editModeFile(f)" @focusout="editmodeFalse(f)" @input="inputEditFile" :class="{'editMode':f.editMode}" class="fz11 text-dark">{{f.name}}</p>-->
<!--              <p :class="{'editMode':f.editMode}" class="fz11 text-dark" v-if="!getPermissionsForUserDeleteOrRenameFile">{{f.name}}</p>-->
<!--              <task-full-info-file-option-button :perm-delete="getPermissionEditAndRemoveFile()" :perm-edit="getPermissionEditAndRemoveFile()" @emitDeleteFile="deleteFile(f.id)" :file="f" @emitEditMode="editModeFile(f)" class="option-button"></task-full-info-file-option-button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="checklist-wrap">-->
<!--          <div class="top-panel-checklist">-->
<!--            <p class="fz16 fw500 text-dark1">Checklist</p>-->
<!--            <button class="blue-button-text fz13 fw500 button1" v-on:click="openModalCheckList()">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="white"/>-->
<!--              </svg>-->
<!--              Edit-->
<!--            </button>-->
<!--          </div>-->
<!--          <async-select-->
<!--              :params="{is_template: true}"-->
<!--              class="checklist-dropdown"-->
<!--              id="sd"-->
<!--              :isMultiple="true"-->
<!--              :allowEmpty="true"-->
<!--              url="/task/checklist/"-->
<!--              v-model="checkListActive"-->
<!--              ref="asyncCheckList"-->
<!--              @close="closeSelectCheckList"-->
<!--          ></async-select>-->
<!--          <div class="list-check-list" v-for="(ch, i) in check_list" :key="i">-->
<!--            <div class="list-check-list__title">-->
<!--              <p class="fz14 fw500 text-dark1">{{ch.name}}</p>-->
<!--              <button class="delete-button-check" v-on:click="deleteCheckList(i)">-->
<!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>-->
<!--                </svg>-->
<!--              </button>-->
<!--            </div>-->
<!--            <div v-for="(item, k) in ch.check_list" :key="k" v-on:click="changeStatusChecklist(i, k)">-->
<!--              <button>-->
<!--                <svg v-if="!item.is_done" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path class="sdsd" fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>-->
<!--                </svg>-->
<!--                <svg v-if="item.is_done" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <rect width="18" height="18" rx="4" fill="#094172"/>-->
<!--                  <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>-->
<!--                </svg>-->
<!--              </button>-->
<!--              <p class="fz14 text-dark1">{{item.name}}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--         {{ getPermissionsFullByType('delete_and_edit_name_of_the_attachments')}}-->
        <payment-detail-atachements-file
            :file-props="getTaskInfo.files ? getTaskInfo.files : []"
            :add-file-permission="getPermissionsForUserCanAddFile"
            :edit-permission="getPermissionEditAndRemoveFile()"
            :delete-file-permission="getPermissionEditAndRemoveFile()"
            :visible-add-file="true"
            :await-delete-file="true"
            :class-for-title="'fz13 text-gray mt12'"
            :normal-size-button="true"
            @addFile="handleFilesUpload"
            @emitBeforeDeleteFile="deleteFile"
            @fileDeleted="refreshFile"
            @changeParentId="setParentsID"
            :disabled-add-new-folder="loadAddFolder"
        ></payment-detail-atachements-file>

<!--        {{getFileForTask}}-->
        <div class="contract-links" v-if="getInfoContractManual !== null">
          <p class="fz13 text-gray">Contract <span v-if="getInfoContractManual.contracts.length > 1">s</span></p>
          <div class="contract-links__links" v-for="(c, i) in getInfoContractManual.contracts" :key="i" v-on:click="redirectToDetailVessel(c.id)">
            <p class="fz14 text-dark fw500">{{ c.name }} {{c.created_at | formatDate}}</p>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
            </svg>
          </div>
          <p class="fz13 text-gray">Task</p>
          <div class="contract-links__links" v-if="getInfoContractManual.contracts.length !== 0" v-on:click="pushTask(getInfoContractManual.contracts[0].task)">
            <p class="fz14 text-dark fw500">{{ getInfoContractManual.contracts[0].task.name }}</p>
            <div class="creator-block" v-for="(c, i) in getTaskInfoConvert.creator" :key="'23s' + i">
              <img v-if="c.avatar !== null" :src="c.avatar" class="img-profile" alt="">
              <svg v-if="c.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="80" cy="80" r="80" fill="white"/>
                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
              </svg>
              <p class="fz13 text-dark">{{c.first_name}} {{c.last_name}}</p>
            </div>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
            </svg>
          </div>
        </div>

      </div>
      <div class="chat-block">
        <div class="block-buttons position-relative">
          <button class="fz14 fw500 text-gray" v-if="!getThisDetailVessel()" :class="{'activity-button': chatShow}" v-on:click="chatShow = true; showChecklist = false">Comments</button>
          <button class="fz14 fw500 text-gray" :class="{'activity-button': (!chatShow || getThisDetailVessel()) && !showChecklist}" v-on:click="chatShow = false; showChecklist = false">Activity</button>
          <button class="fz14 fw500 text-gray ml24" v-if="getTaskInfo.checklists.length" :class="{'activity-button': (!chatShow || getThisDetailVessel()) && showChecklist}" v-on:click="chatShow = false; showChecklist = true">Check Lists</button>
          <button class="open-edit-checklist-button blue-button-text fz13 fw500" v-if="showChecklist" v-on:click="openCheckList">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9998 5.59531C6.28183 5.59531 5.6998 6.17734 5.6998 6.89531V17.8953C5.6998 18.6133 6.28183 19.1953 6.9998 19.1953H16.9998C17.7178 19.1953 18.2998 18.6133 18.2998 17.8953V13.5008C18.2998 13.1142 18.6132 12.8008 18.9998 12.8008C19.3864 12.8008 19.6998 13.1142 19.6998 13.5008V17.8953C19.6998 19.3865 18.491 20.5953 16.9998 20.5953H6.9998C5.50864 20.5953 4.2998 19.3865 4.2998 17.8953V6.89531C4.2998 5.40414 5.50864 4.19531 6.9998 4.19531H13.9998C14.3864 4.19531 14.6998 4.50871 14.6998 4.89531C14.6998 5.28191 14.3864 5.59531 13.9998 5.59531H6.9998Z" fill="#094172"/>
              <path d="M19.6743 5.2683C19.361 4.92023 18.8442 4.91057 18.5199 5.24674C18.5131 5.25377 18.5064 5.26096 18.4998 5.2683L12.0772 13.6424L8.92577 11.012C8.60144 10.6758 8.08463 10.6855 7.7714 11.0335C7.46583 11.3731 7.46583 11.9114 7.7714 12.2509L11.5 15.5008C11.8188 15.8429 12.3356 15.8429 12.6544 15.5008L19.6542 6.50724C19.9785 6.17103 19.9875 5.61637 19.6743 5.2683Z" fill="#094172"/>
            </svg>
            Edit checklists
          </button>
        </div>
        <div v-if="(!chatShow || getThisDetailVessel()) && !showChecklist" class="block-text-activity">
<!--          <pre>{{getTaskActivity}}</pre>-->
          <div class="activity-wrap" v-for="(a, i) in getTaskActivity" :key="i">
            <div class="activity" v-if="a.activity_type === 'task_created' && a.user !== null">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">created the task</p>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity" v-if="a.activity_type === 'expiration_changed'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">set the Due Date on</p>
              <p class="text-gray fz14 activity-text">{{getFormDueDate(a.description)}}</p>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity" v-if="a.activity_type === 'new_executor'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">assigned to</p>
              <div class="activity-user-block">
                <img v-if="a.description.avatar !== null" :src="getURL()+''+a.description.avatar" class="img-profile" alt="">
                <svg v-if="a.description.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="80" cy="80" r="80" fill="white"/>
                  <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                </svg>
                <router-link class="blue-button-text fz13 fw500 activity-user" to="">{{a.description.first_name}} {{a.description.last_name}} <span v-if="!a.description.is_active" class="fz13 fw500"> (archived)</span></router-link>
              </div>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity" v-if="a.activity_type === 'remove_executor'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">remove assigned</p>
              <div class="activity-user-block">
                <img v-if="a.description.avatar !== null" :src="getURL()+''+a.description.avatar" class="img-profile" alt="">
                <svg v-if="a.description.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="80" cy="80" r="80" fill="white"/>
                  <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                </svg>
                <router-link class="blue-button-text fz13 fw500 activity-user" to="">{{a.description.first_name}} {{a.description.last_name}} <span v-if="!a.description.is_active">(archived)</span></router-link>
              </div>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity" v-if="a.activity_type === 'priority_changed'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">change priority</p>
              <div class="activity-priority-block">
                <svg v-if="a.description.type.toLowerCase() === 'urgent'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z" fill="#E85353"/>
                </svg>
                <svg v-if="a.description.type.toLowerCase() === 'high'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z" fill="#FF9035"/>
                </svg>
                <svg v-if="a.description.type.toLowerCase() === 'normal'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z" fill="#0FA54E"/>
                </svg>
                <svg v-if="a.description.type.toLowerCase() === 'low'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z" fill="#9AA3B0"/>
                </svg>
                <p class="fz13 text-gray">{{a.description.type}}</p>
              </div>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity" v-if="a.activity_type === 'name_changed'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">name edited</p>
              <p class="text-gray fz14 activity-text">{{a.description}}</p>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity" v-if="a.activity_type === 'description_changed'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">description edited</p>
<!--              <p class="text-gray fz14 activity-text">{{a.description}}</p>-->
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity" v-if="a.activity_type === 'new_tag'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text" v-if="a.description !== null">set tag</p>
              <p class="text-dark fz14 activity-text" v-if="a.description === null">remove tag</p>
              <p class="text-dark fz11 activity-tag" v-if="a.description !== null">{{a.description.name}}</p>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity" v-if="a.activity_type === 'remove_tag'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">remove tag</p>
              <p class="text-dark fz11 activity-tag" v-if="a.description !== null">{{a.description.name}}</p>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>

            <div class="activity activity-file" v-if="a.activity_type === 'new_file'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text" v-if="a.description === null">attached a file</p>
              <p class="text-dark fz14 activity-text" v-if="a.description !== null && a.description.thumbnail !== undefined">attached a file</p>
              <div class="activity-block-file" v-if="a.description !== null && a.description.thumbnail !== undefined">
                <div class="files-block">
                  <div class="file-type">
                    <img :src="getURL()+''+a.description.thumbnail" alt="preview" class="img-preview" v-if="a.description.thumbnail !== null && getItsImg(a.description.thumbnail)">
                    <img src="../../assets/imgNone.svg" v-if="a.description.thumbnail === null || !getItsImg(a.description.thumbnail)">
                    <p class="fz11 text-dark">{{a.description.name}}</p>
                  </div>
                </div>
              </div>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity activity-file" v-if="a.activity_type === 'change_file_name'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text" v-if="a.description === null">removed a file</p>
              <p class="text-dark fz14 activity-text" v-if="a.description !== null && a.description.thumbnail !== undefined">edited a file name</p>
              <div class="activity-block-file" v-if="a.description !== null && a.description.thumbnail !== undefined">
                <div class="files-block">
                  <div class="file-type">
                    <img :src="getURL()+''+a.description.thumbnail" alt="preview" class="img-preview" v-if="a.description.thumbnail !== null && getItsImg(a.description.thumbnail)">
                    <img src="../../assets/imgNone.svg" v-if="a.description.thumbnail === null || getItsImg(a.description.thumbnail)">
                    <p class="fz11 text-dark">{{a.description.name}}</p>
                  </div>
                </div>
              </div>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
            <div class="activity activity-file" v-if="a.activity_type === 'remove_file'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">removed a file</p>
              <div class="activity-block-file" v-if="a.description !== null && a.description.thumbnail !== undefined">
                <div class="files-block">
                  <div class="file-type">
                    <img :src="getURL()+''+a.description.thumbnail" class="img-preview" alt="preview" v-if="a.description.thumbnail !== null && getItsImg(a.description.thumbnail)">
                    <img src="../../assets/imgNone.svg" v-if="a.description.thumbnail === null || !getItsImg(a.description.thumbnail)">
                    <p class="fz11 text-dark">{{a.description.name}}</p>
                  </div>
                </div>
              </div>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>

            <div class="activity" v-if="a.activity_type === 'status_changed'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">changed the status to</p>
              <p class="text-gray fz14 activity-text">”{{a.description}}”</p>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>

            <div class="activity" v-if="a.activity_type === 'task_active'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">unarchived task</p>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>

            <div class="activity" v-if="a.activity_type === 'task_inactive'">
              <router-link class="blue-button-text fz14 fw500 activity-user" to="">{{a.user.first_name}} {{a.user.last_name}}</router-link>
              <p class="text-dark fz14 activity-text">archived task</p>
              <p class="fz12 text-gray activity-date">{{getFormDueDate(a.created_at)}}</p>
            </div>
          </div>
          <tips-list></tips-list>
        </div>
<!--        {{ getFileForTask }}-->

        <div class="chat-wrap" v-if="$route.path.indexOf('vessel-task') === -1" :class="{hide:!chatShow && !getThisDetailVessel() || showChecklist}">
          <task-fill-info-chat @refreshFile="refreshFile"></task-fill-info-chat>
        </div>
        <div class="check-list-block" v-if="showChecklist && !showPageForCreateTask">
          <check-list-detailt-task :idTask="getTaskInfo ? getTaskInfo.id : null"></check-list-detailt-task>
        </div>
      </div>
    </div>
    <check-list-edit v-if="showPageForCreateTask" :block-name="'detailTask'" :in-block="true"></check-list-edit>
    <modal-slots :isBorder="false" class="change-vessel-modal" :showMod="isShowModalChangeVessel" @close="isShowModalChangeVessel = false">
      <template v-slot:title>
        <h2 class="fz16 fw500 text-dark">Delete Vessel</h2>
      </template>

      <p class="fz14 text-dark p24">Are you sure you want to delete this vessel from the task? <br>Checklists will be deleted from the task automatically </p>

      <template v-slot:bottomFirstButton>
        <button class="blue-button-text fz14" @click="cancelChangeVessel">Cancel</button>
      </template>
      <template v-slot:bottomSecondButton>
        <button class="m-l-8 p-x-16 blue-button fz14 fw500" @click="changeVesselAndDeleteChecklists">Delete</button>
      </template>
    </modal-slots>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import TaskFullInfoButtonOption from "@/components/taks-full-info/task-full-info-button-option";
// import TaskListChangeDate from "@/components/tasks/tasks-list/task-list-changeDate";
import TaskListChangePriority from "@/components/tasks/tasks-list/task-list-change-priority";
import TaskListTagSelect from "@/components/tasks/tasks-list/task-list-tag-select";
import TaskFullInfoTagOption from "@/components/taks-full-info/task-full-info-tag-option";
import TaskFillInfoChat from "@/views/taskFullinfo/taskFillInfoChat";
import axios from "axios";
import TipsList from "@/components/counterparties/counterpartiesList/counterpartiesTips";



Vue.use(VueClipboard)

import { VueEditor } from "vue2-editor";
import ModalDeleteTag from "@/components/tasks/modalDeleteTag";
import SelectOnTheMap from "@/components/tasks/manually-task-add/selectOnTheMap/selectOnTheMap";
import DropdownSelectedRegion from "@/views/tasks/vessel-task/detail/dropdown-selected-region";
import ModalSlots from "@/components/modal/modal-slots";
import PaymentDetailAtachementsFile from "@/components/paymentDetail/paymentDetailAtachementsFile";
import ModalChecklist from "@/components/tasks/modal-checklist/modal-checklist";
import AsyncSelect from "@/components/reuse/asyncSelect";
import SelectDueDate from "@/components/tasks/selectDueDate/selectDueDate";
import AsyncSelectEmployees from "@/components/tasks/async-select-employees";
import CheckListDetailtTask from "@/components/checklist/check-list-detailt-task";
import CheckListEdit from "@/views/checkListEdit/check-list-edit";

export default {
  name: "taskFullinfo",
  components: {
    CheckListEdit,
    CheckListDetailtTask,
    AsyncSelectEmployees,
    SelectDueDate,
    AsyncSelect,
    ModalChecklist,
    PaymentDetailAtachementsFile,
    ModalSlots,

    DropdownSelectedRegion,
    SelectOnTheMap,
    ModalDeleteTag,
    VueEditor,
    TipsList,
    TaskFillInfoChat,
    TaskFullInfoTagOption,
    TaskListTagSelect,
    TaskListChangePriority, TaskFullInfoButtonOption},
  computed: {
    ...mapGetters(["getCheckList", "getPermissionsByType", "getPermissionsFullByType", "getContracts", "getShipsListTemplate", "getInfoContractManual", "getPermissionsForUserDeleteOrRenameFile", "getPermissionsForUser","getPermissionsForUserCanManualTask",
      "getPermissionsForUserCanAddFile","getPermissionsForUserCanEditTask","getTaskActivity",
      "getTaskInfo", "getTaskInfoConvert", "getAllTag", "getEmployees", "getFileForTask", "getKeyRenderTask", "getVesselTaskInfo"]),
    ...mapState({
      clients: state => state.counterparties.clientsDrop,
      showPageForCreateTask: state => state.checklistPage.showPageForCreateTask
    }),
    getClientsFormAddModal() {
      let clients = [];
      clients = this.clients.map(a=> {
        return {id: a.id, name: a.company_name}
      })
      return clients
    },
  },
  watch: {
    brokerActive: function () {
      if(this.getVesselTaskInfo.brokers !== this.brokerActive) {
        let data = {
          "brokers": this.brokerActive.id
        }
        this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
          this.renderNew();
        });
      }

    },
    getKeyRenderTask: function () {
      this.renderNew();
    },
    loadRequests: function () {
      if(this.loadRequests === 0) {
        this.hideLoad();
      } else {
        this.showLoad();
      }
    },
    shipActive: function () {
      if(this.getTaskInfo.vessel !== this.shipActive) {
        if(this.getTaskInfo.checklists.length) {
          this.isShowModalChangeVessel = true;
        } else {
          let data = {
            "vessel": this.shipActive.id
          }
          this.axiosChangeManualTask({id: this.getTaskInfo.id, data: data}).then(()=> {
            this.renderNew();
          });
        }
      }

    },
    clientActive: function () {
      if(this.clientActive !== this.getVesselTaskInfo.clients) {
        let data = {
          "clients": this.clientActive.id
        }
        this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
          this.renderNew();
        });
      }

    }

  },
  data() {
    return {
      isShowModalChangeVessel: false,
      defaultActiveCheckList: null,
      editCargoTextShow: false,
      cargoText: "",
      defaultRegionActive: [],
      showSelectedRegion: false,
      permissionAssignName: "manual",
      groupRegionList: [
        {name: 'Singapore – Japan (incl. Aussie)', id: 'Singapore – Japan (incl. Aussie)', listRegion: [ 'STH PACIFIC 1', 'FEAST', 'SE ASIA', 'W AUSSIE', 'E AUSSIE', 'NWST PASSAGE', 'NZ']},
        {name: 'Persian gulf – Singapore (incl. east and south africa)', id: 'Persian gulf – Singapore (incl. east and south africa)',  listRegion: ['SW AFRICA', 'NW AFRICA', 'AG', 'E AFRICA', 'EC INDIA', 'INDIAN OCEAN', 'RED SEA', 'STH AFRICA', 'STH INDIAN OCEAN', 'WC INDIA']},
        {name: 'Mediterranean - Black sea range (incl red sea)', id: 'Mediterranean - Black sea range (incl red sea)',  listRegion: ['BLACK SEA', 'CASPIAN', 'E MED', 'W MED']},
        {name: 'Gibraltar – Baltic (incl west africa)', id: 'Gibraltar – Baltic (incl west africa)', listRegion: ['Spain Atl', 'ARAG', 'BALTIC', 'French Atl', 'N CONT', 'UK EIRE']},
        {name: 'USG – USEC', id: 'USG – USEC', listRegion: ['CARIBS', 'CENTRAL ATLANTIC', 'EC CAN', 'ECCA', 'GREAT LAKES', 'MIDNORTH ATLANTIC', 'ST LAWRENCE', 'USEC', 'ASG']},
        {name: 'NCSA – ECSA', id: 'NCSA – ECSA', listRegion: ['ECSA', 'NCSA', 'STH ANTARCTICA', 'WCCA', 'WCSA']},
        {name: 'NOPAC – WCSA', id: 'NOPAC – WCSA', listRegion: ['STH PACIFIC 2', 'MID PACIFIC', 'NEST PASSAGE', 'NOPAC']},
      ],
      regionList: [
        {name: "AG", id: "AG"},
        {name: "ARAG", id: "ARAG"},
        {name: "BALTIC", id: "BALTIC"},
        {name: "BLACK SEA", id: "BLACK SEA"},
        {name: "CARIBS", id: "CARIBS"},
        {name: "CASPIAN", id: "CASPIAN"},
        {name: "CENTRAL ATLANTIC", id: "CENTRAL ATLANTIC"},
        {name: "E AFRICA", id: "E AFRICA"},
        {name: "E AUSSIE", id: "E AUSSIE"},
        {name: "E MED", id: "E MED"},
        {name: "EC CAN", id: "EC CAN"},
        {name: "EC INDIA", id: "EC INDIA"},
        {name: "ECCA", id: "ECCA"},
        {name: "ECSA", id: "ECSA"},
        {name: "FEAST", id: "FEAST"},
        {name: "French Atl", id: "French Atl"},
        {name: "GREAT LAKES", id: "GREAT LAKES"},
        {name: "INDIAN OCEAN", id: "INDIAN OCEAN"},
        {name: "MID PACIFIC", id: "MID PACIFIC"},
        {name: "MIDNORTH ATLANTIC", id: "MIDNORTH ATLANTIC"},
        {name: "N CONT", id: "N CONT"},
        {name: "NCSA", id: "NCSA"},
        {name: "NEST PASSAGE", id: "NEST PASSAGE"},
        {name: "NOPAC", id: "NOPAC"},
        {name: "NW AFRICA", id: "NW AFRICA"},
        {name: "NWST PASSAGE", id: "NWST PASSAGE"},
        {name: "NZ", id: "NZ"},
        {name: "RED SEA", id: "RED SEA"},
        {name: "SE ASIA", id: "SE ASIA"},
        {name: "ST LAWRENCE", id: "ST LAWRENCE"},
        {name: "STH AFRICA", id: "STH AFRICA"},
        {name: "STH ANTARCTICA", id: "STH ANTARCTICA"},
        {name: "STH INDIAN OCEAN", id: "STH INDIAN OCEAN"},
        {name: "STH PACIFIC", id: "STH PACIFIC"},
        {name: "SW AFRICA", id: "SW AFRICA"},
        {name: "Spain Atl", id: "Spain Atl"},
        {name: "UK EIRE", id: "UK EIRE"},
        {name: "USEC", id: "USEC"},
        {name: "ASG", id: "ASG"},
        {name: "W AUSSIE", id: "W AUSSIE"},
        {name: "W MED", id: "W MED"},
        {name: "WC INDIA", id: "WC INDIA"},
        {name: "WCCA", id: "WCCA"},
        {name: "WCSA", id: "WCSA"},
      ],
      formatIMG: [".jpeg", ".png", ".raw", ".jpg", ".tiff", ".bmp", ".gif", "jp2", ".svg"],
      formatVIDEO: [".avi", ".mkv", ".mov", ".flv", ".vop", "3gp", ".mp4"],
      zoomFile: 100,
      showViewFile: false,
      viewFileSrc: "",
      loadingPage: false,
      chatRender: -200,
      files: [],
      statusTask: ["Open", "In Progress", "Done",],
      nameTask: "",
      descriptionTask: "",
      descriptionEditText: true,
      errorName: false,
      fileInEditMode: null,
      editNameFile: "",
      tagNameEdit: '',
      chatShow: true,
      showChecklist: false,
      focusDescription: false,
      focusName: false,
      editNameModeInModal: false,
      editNameFileInModal: "",
      showViewFileIndex: null,
      formatThisImg: false,
      formatThisVideo: false,
      selectionTextEvent: null,
      openModalMap: false,
      clientSelectedBrokerShow: false,
      imageProvider: {
        name: 'qiniu', // provider name
        token:
            '-qWchT63mkZEJch0ygm3bN9h3peInHqCcSAEMtvV:9YAz4dCiB3EAdYuoDVO0YvObtqY=:eyJzY29wZSI6InRlc3QiLCJkZWFkbGluZSI6MTkwMjAyODY1NX0=', // upload token
        domain: 'cdn-testing.zanquan.net', // upload domain
        modifier: ({ width, url }) => {
          if (width < 750) {
            return url
          } else {
            return `${url}?imageMogr2/thumbnail/750x/`
          }
        },
      },
      defaultClientActive: {},
      defaultBrokerActive: {},
      clientActive: {},
      brokerActive: {},
      clientSelectedList: false,
      brokerSelectedList: false,
      showWarningChangeRegion: false,
      textModalRegion: "",
      regionForDelete: [],
      regionAdd: [],
      clientSelectedShipShow: false,
      defaultShipActive: null,
      shipActive: null,
      loadRequests: 0,
      parent_id: null,
      activeCheckList: [],
      showChecklistSelect: false,
      check_list: [],
      loadAddFolder: false,
      checkListActive: [],
    }
  },
  methods: {
    ...mapActions(['axiosAddFilesToObject', "axiosDeleteCheckList", "axiosAddNewChecklistAwait", "axiosGetCheckListById", "axiosChangeCheck", "axiosChangeManualTask", "axiosGetTaskCheckList", "axiosFileDelete", "axiosGetAllContracts", "axiosCreateSearchShip", "getAxiosShipsTemplate", "getAxiosInfoContractManual", "axiosDeleteShip", "axiosGetClientsForDropdown", "axiosChangeVesselTask", "getAxiosInfoForVesselTask","axiosGetPermissionsForUserLogin", "axiosGetActivity", "axiosRenameTag","axiosDeleteTag","axiosAddFileToTask","axiosDeleteFileTask","changeAxiosFileForTask","axiosChangeTask", "getAxiosInfoForTask", "axiosGetPriorityTask", "getAxiosAllTag", "axiosGetEmployees", "getAxiosFileForTask"]),
    ...mapMutations(["setStatusShowPageForCreateTask", "setVesselTaskInfoData", "setStatusOpenEditModelInDetail", "hideLoad", "showLoad", "clearInfoContractManual", "clearTaskInfo","clearTaskInfoConvert", "setIdTaskDuplicate", "conversionForTaskInfo", "cancelAxiosChangeTask", "addTip"]),
    // getlinkPDF() {
    //   return "http://docs.google.com/gview?url=http://134.209.119.20:8000/media/user_None/sdsdsdsd.pdf&embedded=true"
    // },
    cancelChangeVessel() {
      this.shipActive = this.getTaskInfo.vessel;
      this.isShowModalChangeVessel = false
    },
    changeVesselAndDeleteChecklists() {
      this.isShowModalChangeVessel = false
      let data = {
        "vessel": this.shipActive.id,
        'checklists': [],
      }
      this.showLoad();
      this.axiosChangeManualTask({id: this.getTaskInfo.id, data: data}).then(()=> {
        this.renderNew();
      });
    },
    openCheckList() {
      this.setStatusShowPageForCreateTask(true)
    },
    refreshListSelect() {
      this.$refs.asyncCheckList.refreshList();
    },
    getActiveGroupRegion(region) {
      let groupActive = [];
      this.groupRegionList.map(a=> {
        let error = 0;
        a.listRegion.map(b=> {
          if(region.indexOf(b) === -1) {
            error++;
          }
        })
        if(error === 0) {
          groupActive.push({name: a.name, id: a.name, listRegion: a.listRegion})
        }
      })
      return groupActive
    },
    getActiveCustomDays(array) {
      if(Array.isArray(array) === true) {
        let dateCurrent = new Date();
        return  array.map(a=> {
          return new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), a)
        })
      }
        return [];
    },
    deleteCheckList(index) {
        let check_list = this.getTaskInfo.check_list.map(a=> {
          return a.id;
        }).filter(a=> a !== this.check_list[index].id)
      this.axiosDeleteCheckList(this.check_list[index].id)
        let form = {
          "check_list": check_list
        }
        this.check_list.splice(index, 1)
        this.axiosChangeManualTask({id: this.getTaskInfo.id, data: form}).then(()=> {
          this.renderTask();
        })
    },
    changeStatusChecklist(index1, index2) {
      let form = {
        "is_done": !this.check_list[index1].check_list[index2].is_done
      }
      this.axiosChangeCheck({id: this.check_list[index1].check_list[index2].id, data: form}).then(res=> {
        if(res !== false)
        this.check_list[index1].check_list[index2].is_done = !this.check_list[index1].check_list[index2].is_done
      })
    },
    async closeSelectCheckList() {
      let data = this.getTaskInfo.check_list
      // checkListActive
      let active_checklist = this.checkListActive.map(a=> {return a.id})
      let check_list = [];
      let count = 0;
      console.log(active_checklist)
      // for(let j=0; j<active_checklist.length; j++) {
      //   for(let i=0; i<data.length; i++) {
      //     if(active_checklist.indexOf(data[i].parent_id) === -1) {
      //       this.axiosDeleteCheckList(data[i].id);
      //       count++
      //     } else if(active_checklist.indexOf(data[i].parent_id) === -1) {
      //       console.log(2)
      //       check_list.push(data[i].id)
      //       active_checklist = active_checklist.filter(a=> a !== data[i].id)
      //     }
      //   }
      // }
      // let additional_checklist = [];
      for(let i=0; i<data.length; i++) {
        if(active_checklist.indexOf(data[i].parent_id) === -1) {
          this.axiosDeleteCheckList(data[i].id);
          // additional_checklist.push(data[i].id)
          count++
        } else {
          check_list.push(data[i].id)
          active_checklist = active_checklist.filter(a=> a !== data[i].parent_id)
        }
      }
      // let additional_checklist = [];
      // for(let i=0; i<active_checklist.length; i++) {
      //   if(data.indexOf(active_checklist[i]) >= 0) {
      //
      //   }
      // }


      if(active_checklist.length !== 0 || count !== 0) {
        for(let i=0; i<active_checklist.length; i++) {
          let item = this.checkListActive.filter(a=> a.id === active_checklist[i])
          item = item[0]
          let checkListAxios = item.check_list.map(a=> {
            return {name: a.name, is_done: false}
          })
          let res = await this.axiosAddNewChecklistAwait({name: item.name, check_list: checkListAxios, is_template: false, parent_id: item.id})
          check_list.push(res.id)
        }
        // if(Array.isArray(data) === true) {
        //   check_list = data.map(a=> {
        //     return a.id;
        //   })
        let form = {
          "check_list": check_list
        }
        this.axiosChangeManualTask({id: this.getTaskInfo.id, data: form}).then(()=> {
          this.renderTask();
        })
      }
    },
    openModalCheckList() {
      this.$refs.modalCheckList.openModalChekList();
    },
    setParentsID(id) {
      this.parent_id = id;
    },
    redirectToDetailVessel(id) {
      this.setStatusOpenEditModelInDetail(true)
      this.$router.push({name: "Detail Vessel", params: {id: id}})
    },
    getPermissionEditAndRemoveFile() {
      if(this.$route.path.indexOf("vessel")>=0) {
        return this.getPermissionsByType('can_delete_or_rename_file');
      }
      return true;
    },
    getShipContracts() {
      let ships = this.getContracts.filter(a => a.contract_type === 'SHIP')
      ships = ships.map(a=> {
        return {name: a.name, id: a.id};
      });

      return ships;
    },
    pushTask(task) {
      if(task.type === "SHIP_SEARCH_WITH_CARGO") {
        this.$router.push("../vessel-task/"+task.id+"/detail")
      }
    },
    deleteShips() {
      let region = this.regionForDelete;
      // // console.log("delete")
      if(region.length > 0) {
        this.getVesselTaskInfo.ships.map(a=> {
          // // console.log(a.region)
          // // console.log(region)
          // // console.log(region.indexOf(a.region)>=0)

          if(region.indexOf(a.region)>=0) {
            this.axiosDeleteShip(a.id)
          }
        })
      }

      let regionAdd = this.regionAdd;
      if(regionAdd.length !== 0) {
        this.getAxiosShipsTemplate({region: regionAdd}).then(()=> {
          for(let i=0; i<this.getShipsListTemplate.length; i++) {
            this.axiosCreateSearchShip({data: this.getShipsListTemplate[i], task: this.getVesselTaskInfo.task.id});
          }
        })
      }
      let data = {
        "region": this.activeRegion.map(a=> {return a.name})
      }
      this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
        this.renderNew();
      });

    },
    getRegionText(list) {
      if(Array.isArray(list) === true) {
        list = this.getActiveGroupRegion(list)
        if(list.length !== 0) {
          if(list.length === 1) {
            return list[0].name;
          }
          let text = "";
          list.map(a=> {
            text+= a.name + "<br/>"
          })
          return text
        } else {
          return 'Select group region'
        }
      } else {
        return null
      }

    },
    closeModalWarn() {
      this.showWarningChangeRegion = false;
      this.regionAdd = [];
      this.regionForDelete = [];
    },
    showEditCargoText() {
      this.editCargoTextShow = true;
      this.cargoText = this.getVesselTaskInfo.cargo_text;
    },
    closeEditCargoText() {
      this.editCargoTextShow = false;
      let data = {
        "cargo_text": this.cargoText
      }
      this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
        this.renderNew();
      });
      this.cargoText = "";
    },
    closeClientList() {
      this.clientSelectedList = false;
      let data = {
        "clients": this.clientActive.id
      }
      this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
        this.renderNew();
      });
    },
    closeBrokerList() {
      this.clientSelectedBrokerShow = false;
      let data = {
        "brokers": this.brokerActive.id
      }
      this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
        this.renderNew();
      });
    },
    closeShipList() {
      this.clientSelectedShipShow = false;
      let data = {
        "ship": this.shipActive.id
      }
      this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
        this.renderNew();
      });
    },
    clickShowShipList() {
      this.clientSelectedShipShow = true
      if(this.getVesselTaskInfo?.ship !== null) {
        this.shipActive = this.getVesselTaskInfo.ship
      }
      this.$refs.shipListDrop.openList()
    },
    clickShowBrokerList() {
      this.clientSelectedBrokerShow = true
      if(this.getVesselTaskInfo.brokers !== null) {
        this.defaultBrokerActive = this.getClientsFormAddModal.filter(a=> a.id === this.getVesselTaskInfo.brokers.id)[0];
      }
      this.$refs.brokerListDrop.openList()
    },
    clientSelectedListShow() {
      this.clientSelectedList = true
      if(this.getVesselTaskInfo.clients !== null) {
        this.defaultClientActive = this.getClientsFormAddModal.filter(a=> a.id === this.getVesselTaskInfo.clients.id)[0];
      }
      this.$refs.clientListDrop.openList()
    },
    setActiveClient(data) {
      this.clientActive = data;
    },
    setActiveBroker(data) {
      this.brokerActive = data;
    },
    setActiveShip(data) {
      this.shipActive = data;
    },


    changeRegion(name) {
      // alert(name);

      // if(this.openModalMap && this.getTaskInfo.type !== 'CARGO_SEARCH_WITH_SHIP') {
      //   this.defaultRegionActive = name
      //   this.activeRegion = this.getActiveGroupRegion(this.defaultRegionActive)
      //   let data = {
      //     "region": name
      //   }
      //   let count = 0;
      //   let region = [];
      //   const numArr = data.region;
      //   region = this.getVesselTaskInfo.region.map(number => {
      //     if (!numArr.includes(number)) {
      //       return number;
      //     }
      //   }).filter(y => y !== undefined);
      //
      //   let regionNew = data.region.map(number => {
      //     if (!this.getVesselTaskInfo.region.includes(number)) {
      //       return number;
      //     }
      //   }).filter(y => y !== undefined);
      //
      //   if(count !== this.getVesselTaskInfo.region.length) {
      //     region.map(a=> {
      //       this.textModalRegion+= a + ","
      //     });
      //     if(this.textModalRegion.length !== 0) {
      //       this.textModalRegion = this.textModalRegion.substring(0, this.textModalRegion.length - 1);
      //       this.textModalRegion += "."
      //     }
      //     this.showWarningChangeRegion = true;
      //     this.regionForDelete = region;
      //     this.regionAdd = regionNew;
      //   } else {
      //     this.defaultRegionActive = name
      //     this.activeRegion = this.getActiveGroupRegion(this.defaultRegionActive)
      //
      //     let data = {
      //       "region": name
      //     }
      //     this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
      //       this.renderNew();
      //     });
      //   }
      //
      //
      // } else {
      //   this.activeRegion = name.map(a=> {return {name: a, id: a}});
      //
      //   let data = {
      //     "region": name
      //   }
      //   this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
      //     this.renderNew();
      //   });
      // }


      let region = []
      console.log(name)
      console.log(name)
      name.map(a=> {region = region.concat(a.listRegion)})
      console.log(region)
      let data = {
        "region": region
      }
      this.showLoad()
      this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
        this.renderNew();
      });

      this.openModalMap = false;
    },
    openMapModal() {
      console.log(this.defaultRegionActive)
      this.openModalMap = true;
      if(this.defaultRegionActive !== null && this.defaultRegionActive !== undefined) {
        this.$refs.modalMap.openModal(this.getActiveGroupRegion(this.defaultRegionActive));
      } else {
        this.$refs.modalMap.openModal([]);
      }
    },
    closeModalMap() {
      this.openModalMap = false;
    },
    closePeriodList(axios) {
      this.showSelectedRegion = false;
      // alert(this.getTaskInfo.type)
      // if(!this.openModalMap && this.getTaskInfo.type !== 'CARGO_SEARCH_WITH_SHIP' && this.getTaskInfo.type !== 'CARGO_SEARCH_WITHOUT_SHIP') {
      //   let data = {
      //     "region": this.activeRegion.map(a=> {return a.name})
      //   }
      //   let count = 0;
      //   let region = [];
      //   const numArr = data.region;
      //   region = this.getVesselTaskInfo.region.map(number => {
      //     if (!numArr.includes(number)) {
      //       return number;
      //     }
      //   }).filter(y => y !== undefined);
      //
      //   let regionNew = data.region.map(number => {
      //     if (!this.getVesselTaskInfo.region.includes(number)) {
      //       return number;
      //     }
      //   }).filter(y => y !== undefined);
      //
      //   if(count !== this.getVesselTaskInfo.region.length) {
      //     region.map(a=> {
      //       this.textModalRegion+= a + ","
      //     });
      //     if(this.textModalRegion.length !== 0) {
      //       this.textModalRegion = this.textModalRegion.substring(0, this.textModalRegion.length - 1);
      //       this.textModalRegion += "."
      //     }
      //     this.showWarningChangeRegion = true;
      //     this.regionForDelete = region;
      //     this.regionAdd = regionNew;
      //   } else {
      //     this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
      //       this.renderNew();
      //     });
      //   }
      //
      //
      // } else {
      if(axios === false) {
        let region = []
        // console.log('this.activeRegion1111')
        // console.log(this.activeRegion)
        this.activeRegion.map(a=> {region = region.concat(a.listRegion)})
        // console.log(region)
        let data = {
          "region": region
        }
        this.showLoad()
        this.axiosChangeVesselTask({id: this.getVesselTaskInfo.id, data: data}).then(()=> {
          this.renderNew();
        });
      }

      // }
    },
    showSelectedRegionMet() {
      this.showSelectedRegion = true;
      this.defaultRegionActive = this.getVesselTaskInfo.region
      // // console.log("defaultRegionActive");
      // // console.log(this.defaultRegionActive);
      this.$refs.regionListDrop.openList()
    },
    renderTask() {
      let textRoute = this.$route.path;
      let idTask = textRoute.replace(/[^-0-9]/gim,'');
      this.getAxiosInfoForTask(idTask).then(()=> {
        this.check_list = this.getTaskInfo.check_list
        let info = false;
        this.nameTask = this.getTaskInfo.name;
        this.descriptionTask = this.getTaskInfo.description;
        info = true
        if(info && priority && tag && employees) {
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        let priority = false;
        this.axiosGetPriorityTask().then(()=> {
          priority = true;
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
          }
        })
        let tag = false;
        this.getAxiosAllTag().then(()=> {
          tag = true;
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
          }
        })
        let employees = false;
        this.axiosGetEmployees().then(()=> {
          employees = true
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
          }
        });
      });
    },

    setActiveRegion(data) {
      console.log("this.selectItems")
      console.log(data)
      this.activeRegion = data;
    },
    getThisDetailVessel() {
      return this.$route.path.indexOf("vessel-task") >=0
    },
    deleteTagWhichUsed(tag) {
      this.$refs.modalDeleteTag.openModal(tag);
    },
    zoomSetDefault() {
      this.zoomFile = 100;
    },

    downloadFile() {
      let file = this.getFileForTask[this.showViewFileIndex];
      axios.get(file.file, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = file.name;
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    prevFile() {
      if(this.showViewFileIndex !== 0) {
        this.showViewFileIndex -=1;
        this.zoomFile = 100;
        this.showViewFileModal(this.showViewFileIndex);
      }
    },
    nextFile() {
      if(this.showViewFileIndex !== this.getFileForTask.length-1) {
        this.showViewFileIndex +=1;
        this.zoomFile = 100;
        this.showViewFileModal(this.showViewFileIndex);
      }
    },
    editNameInModal() {
      if(this.getPermissionEditAndRemoveFile() === true) {
        this.editNameModeInModal = true;
      }
      // this.editNameFileInModal = JSON.parse(JSON.stringify(this.getFileForTask[this.showViewFileIndex].name));
    },
    saveEditNameInModal() {
      let file = this.getFileForTask[this.showViewFileIndex]
      this.editNameFileInModal = this.editNameFileInModal.replace(/\r?\n/g, "");
      this.changeAxiosFileForTask({id: file.id, file: file.file, thumbnail: file.thumbnail, type: file.type, name: this.editNameFileInModal, task_id: file.task_id}).then(this.refreshFile);
      this.editNameModeInModal = false;
    },
    closeEditNameInModal() {
      this.editNameModeInModal = false;
      this.editNameFileInModal = JSON.parse(JSON.stringify(this.getFileForTask[this.showViewFileIndex].name));
    },
    zoomOut() {
      if(this.zoomFile !== 10 && !this.formatThisVideo) {
        this.zoomFile-=10
      }
    },
    zoomIn() {
      if(this.zoomFile !== 400 && !this.formatThisVideo) {
        this.zoomFile+=10
      }
    },
    showViewFileModal(index) {
      this.showViewFile = true;
      this.showViewFileIndex = index;
      this.viewFileSrc = this.getFileForTask[index].file;
      this.editNameFileInModal = JSON.parse(JSON.stringify(this.getFileForTask[this.showViewFileIndex].name));
      let type = this.editNameFileInModal.substring(this.editNameFileInModal.lastIndexOf("."));
      this.formatThisImg = false;
      this.formatThisVideo = false;
      // // console.log("type")
      // // console.log(this.editNameFileInModal.lastIndexOf("."));
      // // console.log((this.editNameFileInModal.length -1))
      // // console.log(type);
      if(this.formatIMG.indexOf(type.toLowerCase()) !== -1) {
        this.formatThisImg = true;
      } else if(this.formatVIDEO.indexOf(type.toLowerCase()) !== -1) {
        this.formatThisVideo = true;
      }
    },
    getItsVideo(name) {
      let type = name.substring(name.lastIndexOf("."));
      if(this.formatVIDEO.indexOf(type.toLowerCase()) !== -1) {
       return true;
      } else {
        return false;
      }
    },
    getItsImg(name) {
      name = ""+name;
      let type = name.substring(name.lastIndexOf("."));
      if(this.formatIMG.indexOf(type.toLowerCase()) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
    hideViewFileModal() {
      this.showViewFile = false;
      this.viewFileSrc = "";
      this.zoomFile = 100;
      this.showViewFileIndex = null;
    },
    changeArchiveTask() {
      let executors = [];
      let task = this.getTaskInfoConvert;
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      let tags = [];
      for(let i=0; i<task.tags.length; i++) {
        tags.push(task.tags[i].id);
      }
      this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.date, is_archived: !task.is_archived, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(()=> {
        if(!task.is_archived) {
          this.addTip("Task is archived")
          this.$router.push({name: "Tasks"})

        } else {
          this.addTip("Task is unarchived")
        }
        this.renderNew()
      });
    },
    renderActivity() {
        let textRoute = this.$route.path;
        let idTask = textRoute.replace(/[^0-9]/gim,'');
        this.axiosGetActivity(idTask);
      },
    changeStatusTask(status) {
      let executors = [];
      let task = this.getTaskInfoConvert;
      // // console.log(1);
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      // // console.log(2);
      let tags = [];
      for(let i=0; i<task.tags.length; i++) {
        tags.push(task.tags[i].id);
      }
      // console.log(3);
      this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.date, is_archived: task.is_archived, status: status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(this.renderNew);
    },
    editModeTrue(indexTag) {
      for(let i=0; i<this.getTaskInfoConvert.tags.length; i++) {
        this.getTaskInfoConvert.tags[i].editStatus = false;
      }
      this.tagNameEdit = this.getTaskInfoConvert.tags[indexTag].name;
      this.getTaskInfoConvert.tags[indexTag].editStatus = true;
    },
    editModeFalse() {
      for(let i=0; i<this.getTaskInfoConvert.tags.length; i++) {
        this.getTaskInfoConvert.tags[i].editStatus = false;
      }
      this.tagNameEdit = "";
    },
    renameTagAxios(tag) {
      this.axiosRenameTag({id: tag.id, name: this.tagNameEdit}).then(this.renderNew);
      this.editModeFalse();
    },
    eventDeleteTag(id) {
      this.axiosDeleteTag(id).then(this.renderNew);
    },
    eventDeleteTagInTask(tagsArray, deleteTag) {
      let executors = [];
      let task = this.getTaskInfoConvert;
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      let tags = [];
      for(let i=0; i<tagsArray.length; i++) {
        if(tagsArray[i].id !== deleteTag.id) {
          tags.push(tagsArray[i].id);
        }
      }
      this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.execute_time, is_archived: task.is_archived, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(this.renderNew);
    },
    openChangeSelectTag(tags) {
      this.$refs.tagSelect.showDropdown({tags: tags, index: 0});
    },
    changeTagsForTask(data) {
      let executors = [];
      let task = this.getTaskInfoConvert;
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      let tags = [];
      for(let i=0; i<data.tags.length; i++) {
        tags.push(data.tags[i].id);
      }

      this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.execute_time, is_archived: task.is_archived, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(this.renderNew);
    },
    handleFilesUpload(files){
      if(this.getPermissionsForUserCanAddFile) {
        let textRoute = this.$route.path;
        let idTask = textRoute.replace(/[^0-9]/gim, '').replaceAll("-", "");
        // this.files.splice(0, this.files.length)
        // this.files = this.$refs.files.files;
        // // console.log(this.files);
        // // console.log(this.files.length);
        if(this.parent_id !== null) {
          for(let i=0; i<files.length; i++) {
            if(files[i].file_type === 'FOLDER')
                this.loadAddFolder = true;
            files[i]["parent_id"] = this.parent_id;
          }
        } else {
          for(let i=0; i<files.length; i++) {
            if(files[i].file_type === 'FOLDER')
              this.loadAddFolder = true;
          }
        }
        this.axiosAddFilesToObject({files: files, model: 'Task', model_id: idTask}).then(()=> {
          this.loadAddFolder = false;
          this.renderNew();
        })
        // this.axiosAddFileToTask({id: idTask, fileInPayment: this.getTaskInfo.files, file: files}).then(() => {
        //   this.loadAddFolder = false;
        //   this.renderNew();
        // });
      }
    },
    deleteFile(id) {
      this.showLoad()
      let files = this.getTaskInfo.files.filter(a=> a.id !== id);
      files = files.map(a=> {return a.id})
      // console.log(files)
      this.axiosDeleteFileTask({id: this.getTaskInfo.id, filesId: files}).then(()=> {
        this.axiosFileDelete(id).then(()=> {
          this.hideLoad()
          this.renderNew();
          // this.info.files = this.info.files.filter(a=> a.id !== id);
        });
      });
      // this.axiosDeleteFileTask(id).then(this.renderNew);
    },
    refreshFile() {
      let textRoute = this.$route.path;
      let idTask = textRoute.replace(/[^0-9]/gim,'');
      this.getAxiosFileForTask(idTask);
      this.axiosGetActivity(idTask);
    },
    changeNameFile(file) {
      // // console.log(file);
      this.editNameFile = this.editNameFile.replace(/\r?\n/g, "");
      this.changeAxiosFileForTask({id: file.id, file: file.file, thumbnail: file.thumbnail, type: file.type, name: this.editNameFile, task_id: file.task_id}).then(this.refreshFile);
    },
    editmodeFalse(file) {
      this.changeNameFile(file);
      this.editNameFile = "";
      for(let i=0; i<this.getFileForTask.length; i++) {
        this.getFileForTask[i].editMode = false;
      }
    },
    editModeFile(file) {
      if(this.getPermissionEditAndRemoveFile() === true) {
        this.editNameFile = "";
        for(let i=0; i<this.getFileForTask.length; i++) {
          this.getFileForTask[i].editMode = false;
        }
        file.editMode = true;
      }

    },
    inputEditFile(e) {
      this.editNameFile = this.nameTask = e.target.innerText;
    },
    changePriorityForTask(data) {
      let executors = [];
      let task = this.getTaskInfoConvert;
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      let tags = [];
      for(let i=0; i<task.tags.length; i++) {
        tags.push(task.tags[i].id);
      }
      this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.execute_time, is_archived: task.is_archived, status: task.status, type: task.type, priority: data.priority.id, executors: executors, tags: tags}).then(this.renderNew);
    },
    openChangePriorityDropdown(priority) {
      this.$refs.changePriorityDropdown.showDropdown({priority: priority, index: 0});
    },
    changeName(e) {
      this.focusName = false;
      if(this.getPermissionsForUserCanEditTask) {
        this.nameTask = e.target.innerText;
        if(this.nameTask.length === 0) {
          this.errorName = true;
          this.descriptionEditText = false;
        } else {
          this.errorName = false;
          this.descriptionEditText = true;
          this.cancelAxiosChangeTask();
          this.axiosChangeTask({id: this.getTaskInfo.id, name: this.nameTask, description: this.descriptionTask, execute_time: this.getTaskInfo.date, is_archived: this.getTaskInfo.is_archived, status: this.getTaskInfo.status, type: this.getTaskInfo.type, priority: this.getTaskInfo.priority, executors: this.getTaskInfo.executors, tags: this.getTaskInfo.tags}).then(()=> {
            let textRoute = this.$route.path;
            let idTask = textRoute.replace(/[^0-9]/gim,'');
            this.axiosGetActivity(idTask);
          });

        }
      }
    },
    changeDescription() {
      this.focusDescription = false;
      if(this.getPermissionsForUserCanEditTask) {
        // this.descriptionTask = e.target.innerText;

        this.descriptionTask = this.getTaskInfo.description

        // // console.log(e)
        // alert(this.nameTask)

        if(this.getTaskInfo.name.length !==0) {

          this.cancelAxiosChangeTask();
          this.axiosChangeTask({id: this.getTaskInfo.id, name: this.getTaskInfo.name, description: this.descriptionTask, execute_time: this.getTaskInfo.date, is_archived: this.getTaskInfo.is_archived, status: this.getTaskInfo.status, type: this.getTaskInfo.type, priority: this.getTaskInfo.priority, executors: this.getTaskInfo.executors, tags: this.getTaskInfo.tags}).then(()=> {
            let textRoute = this.$route.path;
            let idTask = textRoute.replace(/[^0-9]/gim,'');
            this.axiosGetActivity(idTask);
          });
        }
      }
    },
    changeDateForTask(data) {
      this.axiosChangeManualTask({id: this.getTaskInfo.id, data: data}).then(()=> {
        this.renderNew()
      })
    },
    openDatePickerChangeDateForTask(date) {
      // date = date.replace("T", " ");
      // date = date.replace("Z", "");
      this.$refs.changeDate.showDatePicker({date: date, index:0});
    },
    checkDate(date) {
      // date = date.replace("T", " ");
      // date = date.replace("Z", "");
      if((new Date()).getFullYear()>=(new Date(date)).getFullYear() && (new Date()).getMonth()>=(new Date(date)).getMonth() && (new Date()).getDate()>=(new Date(date)).getDate() && (new Date()).getHours()>=(new Date(date)).getHours() && (new Date()).getMinutes()>(new Date(date)).getMinutes()) {
        return true;
      }
      return false;
    },
    openUserSelectForTask(array) {
      if(this.getPermissionsByType('assign_tasks_to'))
      this.$refs.changeAssignee.showDropdown(array, true)
    },
    getFormDateCreated(date) {

      // date = date.replace("T", " ");
      // date = date.replace("Z", "");
      date = new Date(date);
      let mm = date.toLocaleString('en', {
        month: 'short'
      });
      return ""+date.getDate() + ", " + mm;
    },
    getFormDueDate(date) {
      // console.log("DATE" )
      // console.log(date);
      // date = date.replace("T", " ");
      // date = date.replace("Z", "");
      date = new Date(date);
      // console.log(date);

      // console.log(date.getHours());
      let mm = date.toLocaleString('en', {
        month: 'short'
      });
      let min = date.getMinutes();
      if(min<10) {
        min = "0"+min;
      }
      // console.log(""+date.getDate() + ", " + mm + ", " + date.getHours() + ":" + min);

      return ""+date.getDate() + ", " + mm + ", " + date.getHours() + ":" + min;
    },
    getStatusButtonNext() {
      for(let i=0; i<this.statusTask.length; i++) {
        if(this.statusTask[i].toLowerCase() === this.getTaskInfo.status.toLowerCase()) {
          if(i === this.statusTask.length - 1) {
            return this.statusTask[0];
          } else {
            return this.statusTask[i+1];
          }
        }
      }
    },
    getStatusButtonPrev() {
      for(let i=0; i<this.statusTask.length; i++) {
        if(this.statusTask[i].toLowerCase() === this.getTaskInfo.status.toLowerCase()) {
          if(i === 0) {
            return this.statusTask[this.statusTask.length - 1];
          } else {
            return this.statusTask[i-1];
          }
        }
      }
    },
    copyLink() {
      let url = window.location.href;
      this.$copyText(url).then(function (e) {
        console.log(e)
      }, function (e) {
        console.log(e)
      })
    },
    changeUserForTask(array, bool) {
      let executors = [];
      for(let i=0; i<array.length; i++) {
        executors.push(array[i].id);
      }
      let task = this.getTaskInfoConvert;
      let tags = [];
      for(let i=0; i<task.tags.length; i++) {
        tags.push(task.tags[i].id);
      }
      console.log(executors)
      if(bool) {
        this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.execute_time, is_archived: task.is_archived, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(this.renderNew);
      }
    },
    renderNew() {
      if(this.loadRequests === 0) {
        let textRoute = this.$route.path;
        let idTask = textRoute.replace(/[^0-9]/gim,'');
        let info = false;
        if(this.$route.path.indexOf('vessel-task') >= 0) {
          this.getAxiosInfoForVesselTask(idTask);
        }
        this.getAxiosInfoForTask(idTask).then(()=> {
          this.check_list = this.getTaskInfo.check_list;
          this.nameTask = this.getTaskInfo.name;
          this.descriptionTask = this.getTaskInfo.description;
          info = true
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
            this.hideLoad()
          }
        });
        let priority = false;
        this.axiosGetPriorityTask().then(()=> {
          priority = true;
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
            this.hideLoad()
          }
        })
        let tag = false;
        this.getAxiosAllTag().then(()=> {
          tag = true;
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
            this.hideLoad()
          }
        })
        let employees = false;
        this.axiosGetEmployees().then(()=> {
          employees = true
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
            this.hideLoad()
          }
        });
        this.axiosGetActivity(idTask);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(!vm.$store.getters.getPermissionsByType("can_view_manual_task") && vm.route.path.indexOf('vessel-task') === -1) {
        next(from);
      }
    })
  },
  beforeRouteLeave(to, form, next) {
    this.clearInfoContractManual()
    // this.clearTaskInfoConvert();
    // this.clearTaskInfo();
    if(!this.showPageForCreateTask) {
      next();
    } else {
      this.setStatusShowPageForCreateTask(false)
    }
  },
  mounted() {
    // if(!this.getContracts.length) {
    //   this.axiosGetAllContracts();
    // }
    // if(!this.clients.length) {
    //   this.axiosGetClientsForDropdown();
    // }
    // this.clearTaskInfoConvert();
    // this.clearTaskInfo();
    // this.renderTask();
    // if(this.$route.path.indexOf('vessel') === -1) {
      this.setIdTaskDuplicate(null);
      if(this.getPermissionsForUser.length === 0) {
        this.loadRequests++;
        this.axiosGetPermissionsForUserLogin().then(()=> {
          if(this.getPermissionsForUserCanManualTask === false) {
            this.$router.push({name: "Tasks"})
          }
          this.loadRequests--;
        })
      } else {
        if(this.getPermissionsForUserCanManualTask === false) {
          this.$router.push({name: "Tasks"})
        }
      }

      let textRoute = this.$route.path;
      let idTask = textRoute.replace(/[^0-9]/gim,'');

      let info = false;
      if(this.$route.path.indexOf('vessel-task') >= 0) {
        this.loadRequests++;
        this.getAxiosInfoForVesselTask(idTask).then(()=> {
          this.clientActive = this.getVesselTaskInfo.clients
          this.brokerActive = this.getVesselTaskInfo.brokers
          this.defaultRegionActive = this.getVesselTaskInfo.region
          this.loadRequests--;
        })
      }
      this.loadRequests++;
      this.getAxiosInfoForTask(idTask).then(()=> {
        if(this.getTaskInfo.type === 'MANUAL' && !this.getPermissionsByType("can_view_manual_task")) {
          this.$router.push({name: 'Dashboard'})
        }
        this.check_list = this.getTaskInfo.check_list;
        if(this.getTaskInfo.checklists.length) {
          this.statusTask = this.statusTask.filter(a=> a !== "In Progress")
          this.chatShow = false;
          this.showChecklist = true
        }
        if(Array.isArray(this.check_list)===true) {
          this.check_list.map(a=> {
            this.axiosGetCheckListById(a.parent_id).then(res=> {
              this.checkListActive.push(res.data)
            })
          })
        }

        this.nameTask = this.getTaskInfo.name;
        this.descriptionTask = this.getTaskInfo.description;
        info = true
        if(info && priority && tag && employees) {
          this.loadingPage = true;
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
          this.shipActive = this.getTaskInfo.vessel
        if(this.getTaskInfo.type === "CARGO_SEARCH_WITHOUT_SHIP") {
          this.permissionAssignName = "vessel";
        }
        if(this.getTaskInfo.type === "SHIP_SEARCH_WITHOUT_CARGO") {
          this.permissionAssignName = "vessel";
        }
        if(this.getTaskInfo.type === "SHIP_SEARCH_WITH_CARGO") {
          this.permissionAssignName = "cargo";
        }
        if(this.getTaskInfo.type === "MANUAL_WITH_CONTRACT") {
          this.permissionAssignName = "manual";
          this.loadRequests++;
          this.getAxiosInfoContractManual(this.getTaskInfo.id).then(()=> {
            this.loadRequests--;
          }).catch(()=> {
            this.loadRequests--;
          })
        }
        if(this.getTaskInfo.type === "CARGO_SEARCH_WITH_SHIP") {
          this.permissionAssignName = "cargo";
        }
        this.loadRequests--;
      });
      let priority = false;
      this.loadRequests++;
      this.axiosGetPriorityTask().then(()=> {
        priority = true;
        if(info && priority && tag && employees) {
          this.loadingPage = true;
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        this.loadRequests--;
      }).catch(()=> {
        this.loadRequests--;
      })
      let tag = false;
      this.loadRequests++;
      this.getAxiosAllTag().then(()=> {
        tag = true;
        if(info && priority && tag && employees) {
          this.loadingPage = true;
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        this.loadRequests--;
      })
      let employees = false;
      this.loadRequests++;
      this.axiosGetEmployees().then(()=> {
        employees = true
        if(info && priority && tag && employees) {
          this.loadingPage = true;
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        this.loadRequests--;
      });

      this.loadRequests++;
      this.getAxiosFileForTask(idTask).then(()=> {
        this.loadRequests--;
      });
      this.loadRequests++;
      this.axiosGetActivity(idTask).then(()=> {
        this.loadRequests--;
      });
    // }
    let vm = this;
    document.addEventListener('keydown', function (e) {
      // // console.log(vm.$refs.textDescription);
      // // console.log(document.getElementById("textDescription"))
      // // console.log(e.keyCode)
      if (e.ctrlKey && vm.focusDescription){
        vm.selectionTextEvent = window.getSelection();
        // console.log(vm.selectionTextEvent);
      }
        if (e.ctrlKey && e.code === "KeyB" && vm.focusDescription) {
          // console.log(window.getSelection())
          let text = vm.$refs.textDescription;
          // console.log(e);
          // console.log({text: text});

          if (vm.selectionTextEvent.extentOffset !== undefined) {
            // var startPos = text.selectionStart;
            // var endPos = text.selectionEnd;
            var selectedText = text.innerHTML.substring(vm.selectionTextEvent.extentOffset, vm.selectionTextEvent.anchorOffset)
            // console.log(selectedText)
            if(selectedText.indexOf("<b>") === -1 && selectedText.indexOf("</b>") === -1) {
              if (selectedText) {
                // alert(1);
                // // console.log(selectedText.indexOf("<b>"));
                // console.log(selectedText);
                let v = text.innerHTML.substring(0, vm.selectionTextEvent.extentOffset);
                // console.log("v");
                v += '<b>' + selectedText + '</b>';
                v += text.innerHTML.substring(vm.selectionTextEvent.anchorOffset);
                console.log(v);
                // text.innerHTML = v;
                // vm.getTaskInfo.description = v;
              }
            } else
            if(selectedText.indexOf("</b>") !== -1) {
              if (selectedText) {
                // // console.log(2);
                // alert(2);
                // console.log(text.innerHTML);
                let v = text.innerHTML.substring(0, vm.selectionTextEvent.extentOffset);
                selectedText.replace("<b>", "");
                // console.log("v");
                v += '</b>' + selectedText;
                v += text.innerHTML.substring(vm.selectionTextEvent.anchorOffset);
                // console.log(text.innerHTML);
                console.log(v);
                // text.innerHTML = v;
                // vm.getTaskInfo.description = v;
              }
            }
          }
        }
    })
    if(!this.getCheckList.length) {
      this.axiosGetTaskCheckList();
    }
  }
}
</script>

<style scoped lang="scss">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
  @import "taskFullinfo";
.check-list-block {
  overflow-y: auto;
    width: 100%;
    height: calc(100% - 15px);
    box-sizing: border-box;
}
.open-edit-checklist-button {
  position: absolute;
  padding: 0 !important;
  border: none !important;
  right: 0;
}
.change-vessel-modal {
  /deep/ {
    .top-panel {
      padding: 12px 16px 0 16px;
    }
    .main-block {
       p {
         padding: 20px 16px 20px 16px;
       }
    }
    .bottom-panel {
      padding: 0 16px 12px 16px !important;
    }
  }
}

</style>