<template>
  <div class="autoloader">
    <app-loader :is-local="true"></app-loader>
  </div>
</template>

<script>
import AppLoader from "@/components/loader";
export default {
  name: "loader-for-autoload",
  components: {AppLoader}
}
</script>

<style scoped lang="scss">
  .autoloader {
    margin: 15px 0 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ {
      .wrap-loader-local {
        position: static;
        background-color: transparent !important;
      }
      .sk-fading-circle {
        height: 32px;
        width: 32px;
      }
    }
  }
</style>