<template>
  <div class="linked_wrap">
    <p class="m-b-3 fw500 text-dark" :class="{'hidden': isEntries}">Linked Operation</p>

    <div 
      v-if="linkeds && !linkeds.length" 
      class="m-b-2 rounded border border-gray-dark bg-white p-y-px block-border"
      :class="{'hidden': isEntries}"
    >
      <div class="p-x-3 p-y-2 text-gray-text fz14">No Linked Operation</div>
    </div>
    <div 
      v-else 
      class="m-b-2 rounded bg-white p-y-px"
      :class="{
        'border border-gray-dark': !isEntries
      }"
    >
      <div 
        v-for="link in linkeds" 
        :key="link.id"
        class="flex items-center svg-white-dart"
        :class="{
          'text-dark-gray': isEntries,
          'p-l-3 p-r-2 p-y-1': !isEntries,
        }"
      >
        <div class="inline-flex overflow-hidden whitespace-nowrap fz14">
          <p>
            <span v-if="link.payment_type == 'expense' && link.data_type == 'payment'">Payment</span>
            <span v-else-if="link.payment_type == 'expense' && link.data_type == 'invoice'">Bill</span>
            <span v-else-if="link.payment_type == 'income' && link.data_type == 'payment'">Income</span>
            <span v-else-if="link.payment_type == 'income' && link.data_type == 'invoice'">Invoice</span>
            <span v-else-if="link.invoice_type">Voyage Statement of Account</span>
            <span v-else-if="link.contract.contract_type == 'SHIP'">SOA with Owners</span>
            <span v-else>SOA Sublet</span>
            #{{link.id}}
            <span v-if="link.execute_date">({{link.execute_date | formatDate}})</span>
            <span v-else-if="link.due_date">({{link.due_date | formatDate}})</span>
            <span v-else-if="link.paid_date">({{link.paid_date | formatDate}})</span>
          </p>
        </div>
        <svg @click="onDeleteLink(link)" v-if="!disabled" class="cursor-pointer m-l-auto flex-shrink-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/></svg>
      </div>
    </div>

    <button class="flex items-center text-blue-dark fz14 fw500" type="button" @click="onOpenModal" :disabled="disabled">
      <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="dark-mode-stroke-white" d="M8.11797 7.5C8.11797 7.33431 8.25228 7.2 8.41797 7.2H18.3346C18.5003 7.2 18.6346 7.33431 18.6346 7.5V19.8064L16.8473 18.5298C16.4032 18.2125 15.8009 18.235 15.3816 18.5844L13.3763 20.2555L11.371 18.5844C10.9517 18.235 10.3494 18.2125 9.9053 18.5298L10.3122 19.0994L9.90529 18.5298L8.11797 19.8064V7.5Z" stroke="#094172" stroke-width="1.4"/><path class="dark-mode-stroke-white" d="M15.6654 4.66797H7.58203C6.47746 4.66797 5.58203 5.5634 5.58203 6.66797V17.5013" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
      Link
    </button>

    <modal-slots :showMod="isModalShow" @close="isModalShow = false" @apply="onConfirmModal">
      <template v-slot:title>
        <h2 class="fz18 fw500">Link Operation</h2>
      </template>
      <div class="linked_modal__content">
        <div class="p-x-6">
          <div class="flex">
            <div class="w-1/2 m-r-5">
              <counterparties-drop
                class="m-t-3 p-x-0"
                :list="getTypes"
                :removeSelectItemBeforeClick="false"
                @select="onSelectType"
                title="Operation Type"
              />
            </div>
            <div class="w-1/2">
              <async-select
                class="m-t-3"
                id="counterparty"
                label="Counterparty"
                url="/client/dropdown"
                :params="{is_archived: false}"
                watchKey="company_name"
                :isMultiple="true"
                :allowEmpty="true"
                v-model="clients"
              />
            </div>
          </div>
          <div class="flex">
            <div class="w-1/2 m-r-5">
              <p class="m-t-3 m-b-1 fz13 text-gray-text">Period</p>
              <date-picker
                class="w-full"
                format="DD.MM.YYYY"
                type="date"
                range-separator="-"
                :range="true"
                placeholder="dd.mm.yyyy"
                v-model="period"
              ></date-picker>
            </div>
            <div class="w-1/2">
              <async-select
                class="m-t-3"
                id="account"
                label="Account"
                url="/payment/account/"
                :params="{is_archive: false}"
                watchKey="name"
                :beforeWatchKey="'account_id'"
                :isMultiple="true"
                :allowEmpty="true"
                v-model="accounts"
              />
            </div>
          </div>
          <div class="flex">
            <div class="flex w-1/2 m-r-5">
              <div class="w-1/2 m-r-3">
                <async-select
                  class="m-t-3"
                  id="vessel"
                  label="Vessel"
                  url="/contract/dropdown"
                  :params="{
                    contract_type: 'SHIP',
                    is_archive: false
                  }"
                  watchKey="name"
                  afterWatchKeyBrackets="cp_date"
                  :isMultiple="false"
                  :allowEmpty="true"
                  v-model="vessel"
                />
              </div>
              <div class="w-1/2">
                <label class="block-input">
                  <p class="block-input_title text-gray">CP Date</p>
                  <input class="block-input_content" type="text" v-model="getCpDate" disabled>
                </label>
              </div>
            </div>
            <div class="w-1/2">
              <counterparties-drop
                class="m-t-3 p-x-0"
                ref="statementDrop"
                :list="getStatementMember"
                @select="onSelectSM"
                title="Statement Member"
              />
            </div>
          </div>
        </div>

        
        <div class="flex m-t-5 p-b-1 fz13 text-gray-text bg-white-dark">
          <div class="flex items-center w-1/5 p-l-6">
            Due Date
            <svg
              @click="ordering = ordering == '+' ? '-' : '+'"
              :class="{'rotate-180': ordering == '+'}"
              class="m-l-1"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            ><path d="M8.30679 8.29156C8.15388 8.13938 7.90656 8.13996 7.75437 8.29287L6.39062 9.66322V1.39062C6.39062 1.17488 6.21574 1 6 1C5.78425 1 5.60937 1.17488 5.60937 1.39062V9.66324L4.24562 8.29285C4.09344 8.13994 3.84611 8.13936 3.6932 8.29154C3.54027 8.44375 3.53971 8.69107 3.69187 8.84398L5.72312 10.8851C5.72324 10.8852 5.72337 10.8853 5.72349 10.8854C5.87568 11.0379 6.1238 11.0384 6.2765 10.8854C6.27662 10.8853 6.27675 10.8852 6.27687 10.8851L8.30812 8.844C8.46027 8.69111 8.45974 8.44377 8.30679 8.29156Z" fill="#2C2C2C"/></svg>
          </div>
          <div class="w-1/6">Status</div>
          <div class="w-1/8">Curr.</div>
          <div class="">Amount</div>
        </div>

        <div class="relative">
          <div class="p-y-6 text-align-center border-t border-gray-dark fz13 text-gray-text" v-if="isLoading">Searching...</div>
          <div class="p-y-6 text-align-center border-t border-gray-dark fz13 text-gray-text" v-if="!operationType">Please, select the operation type</div>
          <div class="p-y-6 text-align-center border-t border-gray-dark fz13 text-gray-text" v-if="operationType && !isLoading && !resultsList.length">No results were found for your search</div>
          
          <div
            class="flex items-center p-y-1 border-t border-gray-dark fz14 hover:bg-gray-darkest cursor-pointer"
            :class="{
              'bg-gray-darkest': localLinkeds.find(el=>el.id==row.id)
            }"
            v-for="row in resultsList"
            :key="row.id"
            @click="onLink(row)"
          >
            <div class="w-1/5 p-l-6" v-if="row.execute_date">{{row.execute_date | formatDate}}</div>
            <div 
              class="w-1/5 p-l-6" 
              v-else-if="
                operationType && (operationType.id == 'soa_owners' || operationType.id == 'soa_sublet')
              "
            >{{row.created_at | formatDate}}</div>
            <div class="w-1/5 p-l-6" v-else-if="row.due_date">{{row.due_date | formatDate}}</div>
            <div class="w-1/5 p-l-6" v-else></div>
            <div class="w-1/6">
              <span v-if="row.linked_payments.length || row.linked_owners.length || row.linked_invoices.length" class="text-gray-text">Paid</span>
              <span v-else class="text-green">Open</span>
            </div>
            <div class="w-1/8">{{row.currency}}</div>
            <div class="" v-if="row.payment_amount">{{row.payment_amount | formatNumber}}</div>
            <div class="" v-else-if="row.total_amount">{{row.total_amount | formatNumber}}</div>
            <div class="" v-else-if="row.balance">{{row.balance | formatNumber}}</div>
            <div class="" v-else>0, 0000</div>
            <div class="m-l-auto p-r-6 fz0">
              <svg :class="{'opacity-100': localLinkeds.find(el=>el.id==row.id)}" class="opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
            </div>
          </div>

          <div class="text-align-center p-y-1 border-t border-gray-dark fz14 fw500 " v-if="nextUrl">
            <button class="text-blue-dark" @click="onSearch(true)">Show more</button>
          </div>

          <transition name="fade">
            <app-loader :isLocal="true" v-if="isLoading"></app-loader>
          </transition>
        </div>
      </div>
    </modal-slots>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
const linkedCancelToken = axios.CancelToken;
let linkedCancel;
import QueryString from "querystring"
import { mapGetters } from 'vuex'

import DatePicker from 'vue2-datepicker'
import AppLoader from "@/components/loader";
import modalSlots from '@/components/modal/modal-slots'
import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import asyncSelect from '@/components/reuse/asyncSelect'
export default {
  components: {
    AppLoader,
    modalSlots,
    counterpartiesDrop,
    DatePicker,
    asyncSelect
  },
  props: {
    linkedPayments: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    linkedInvoices: {
      type: Array,
      default: () => {
        return []
      }
    },
    linkedOwners: {
      type: Array,
      default: () => {
        return []
      }
    },
    linkedType: {
      type: String,
      default: 'Invoices' // Payments - if we linked Payment/Income to invoices
    },
    isEntries: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoading: false,
      isModalShow: false,
      ordering: '-',
      nextUrl: null,
      operationType: null,
      period: [],
      clients: [],
      accounts: [],
      vessel: null,
      localLinkedPayments: [],
      localLinkedInvoices: [],
      localLinkedOwners: [],
      statementMember: null,
      invoiceTypes: [
        {
          id: 'expense',
          name: 'Bill'
        },
        {
          id: 'income',
          name: 'Invoice'
        },
        {
          id: 'soa_owners',
          name: 'SOA with Owners'
        },
        {
          id: 'soa_sublet',
          name: 'SOA Sublet'
        },
        {
          id: 'Voyage Statement of Account',
          name: 'Voyage Statement of Account'
        }
      ],
      paymentTypes: [
        {
          id: 'expense',
          name: 'Payment'
        },
        {
          id: 'income',
          name: 'Income'
        }
      ],
      resultsList: [],
      resultsOld: []
    }
  },
  watch: {
    operationType: function() {this.onSearch(false)},
    ordering: function() {this.onSearch(false)},
    period: function() {this.onSearch(false)},
    clients: function() {this.onSearch(false)},
    accounts: function() {this.onSearch(false)},
    statementMember: function() {this.onSearch(false)},
    vessel: function() {this.onSearch(false), this.checkSM()},

    linkedPayments: function() {this.checkIsLinkType()},
    linkedInvoices: function() {this.checkIsLinkType()},
    linkedOwners: function() {this.checkIsLinkType()}
  },
  computed: {
    ...mapGetters(['getToken', 'paramsGetFilter', 'getPermissionsByType']),
    getTypes() {
      if(this.linkedType == 'Invoices') return this.paymentTypes
      return this.invoiceTypes
    },
    linkeds() {
      if(this.linkedType == 'Invoices') return this.linkedPayments
      else if(this.linkedType == 'Payments'){
        let result = [...this.linkedPayments, ...this.linkedInvoices, ...this.linkedOwners]
        return result
      }
      return []
    },
    localLinkeds() {
      if(this.linkedType == 'Invoices') return this.localLinkedPayments
      else if(this.linkedType == 'Payments'){
        let result = [...this.localLinkedPayments, ...this.localLinkedInvoices, ...this.localLinkedOwners]
        return result
      }
      return []
    },
    getCpDate() {
      let result = this.vessel?.cp_date
      if(result) result = moment(result, 'YYYY-MM-DD').format('DD.MM.YYYY')
      return result
    },
    getStatementMember() {
      if(!this.vessel) return []

      return this.vessel.cargo_contracts.map(el => {
        return {
          id: el.id,
          contract_type: el.contract_type,
          name: moment(el.cp_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
          cp_date: el.cp_date,
        }
      })
    }
  },
  methods: {
    getPermissionShowDopInfoAcc() {
      return this.getPermissionsByType('show_dop_info_for_acc_in_payments')
    },
    onDeleteLink(item) {
      if(!item.link_type) return false

      let index = this[item.link_type].findIndex(el => el.id == item.id)
      this[item.link_type].splice(index, 1)

      this.$emit('on-change')
    },
    onOpenModal() {
      this.localLinkedPayments = [...this.linkedPayments]
      this.localLinkedInvoices = [...this.linkedInvoices]
      this.localLinkedOwners = [...this.linkedOwners]
      this.isModalShow = true
    },
    onSelectType(item) {
      this.operationType = item
    },
    onSelectSM(item) {
      if(this.statementMember?.id == item?.id)
        this.statementMember = null
      else
        this.statementMember = item
    },
    checkSM() {
      this.statementMember = null
      this.$refs.statementDrop.render()
    },
    onLink(item) {
      let usedArray
      if(this.operationType.id == 'expense' || this.operationType.id == 'income'){
        usedArray = this.localLinkedPayments
        item.link_type = 'linkedPayments'
      }else if(this.operationType.id == 'soa_owners' || this.operationType.id == 'soa_sublet'){
        usedArray = this.localLinkedOwners
        item.link_type = 'linkedOwners'
      }else{
        usedArray = this.localLinkedInvoices
        item.link_type = 'linkedInvoices'
      }

      let findIndex = usedArray.findIndex(el => el.id == item.id)
      if(findIndex >= 0)
        usedArray.splice(findIndex, 1)
      else{
        if(this.linkedType == 'Payments'){ // because allowed only one link to payment
          this.localLinkedPayments.length = 0
          this.localLinkedOwners.length = 0
          this.localLinkedInvoices.length = 0
        }
        usedArray.push(item)
      }
    },

    async searchPayment(isNext, dataType) {
      if(isNext){
        this.resultsOld = [...this.resultsOld, ...this.resultsList]
      }
      this.resultsList = []

      this.isLoading = true
      let ordering = (this.ordering == '-' ? this.ordering : '')+'execute_date'
      let url = isNext ? this.nextUrl : process.env.VUE_APP_URL+'/payment?ordering='+ordering
      
      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
        cancelToken: new linkedCancelToken(function executor(c) {
          linkedCancel = c;
        }),
        params: isNext ? {} : this.paramsGetFilter({
          data_type: dataType,
          is_archive: false,
          limit: 10,
          record_type: 'PAYMENT',
          payment_type: this.operationType?.id,
          execute_date_after: this.period[0] && moment(this.period[0]).isValid() ? moment(this.period[0]).format('YYYY-MM-DD') : null,
          execute_date_before: this.period[1] && moment(this.period[1]).isValid() ? moment(this.period[1]).format('YYYY-MM-DD') : null,
          client: this.clients.length ? this.clients.map(el=>{return el.id}) : null,
          account: this.accounts.length ? this.accounts.map(el=>{return el.id}) : null,
          ship: this.vessel?.id,
          settled_ship: dataType == 'invoice' ? null : this.vessel?.id,
          statement_member: this.statementMember?.cp_date
        }),
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      }).then(response => {
        this.nextUrl = response.data.next
        this.resultsList = [...this.resultsOld, ...response.data.results]
        this.resultsOld = []
        this.isLoading = false
      }).catch(error => {
        console.error('searchPayment', error);
      })
    },
    async searchOwners(isNext, contractType) {
      if(isNext){
        this.resultsOld = [...this.resultsOld, ...this.resultsList]
      }
      this.resultsList = []

      this.isLoading = true
      let ordering = (this.ordering == '-' ? this.ordering : '')+'due_date'
      let url = isNext ? this.nextUrl : process.env.VUE_APP_URL+'/owners?ordering='+ordering
      
      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
        cancelToken: new linkedCancelToken(function executor(c) {
          linkedCancel = c;
        }),
        params: isNext ? {} : this.paramsGetFilter({
          is_archive: false,
          limit: 10,
          created_at_after: this.period[0] && moment(this.period[0]).isValid() ? moment(this.period[0]).format('YYYY-MM-DD') : null,
          created_at_before: this.period[1] && moment(this.period[1]).isValid() ? moment(this.period[1]).format('YYYY-MM-DD') : null,
          contract__id: contractType == 'SHIP' ? this.vessel?.id : null,
          contract__many_contracts__id: contractType == 'SHIP' ? null : this.vessel?.id,
          contract__contract_type: contractType,
          contract__cp_date: this.statementMember?.cp_date
        }),
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      }).then(response => {
        this.nextUrl = response.data.next
        this.resultsList = [...this.resultsOld, ...response.data.results]
        this.resultsOld = []
        this.isLoading = false
      }).catch(error => {
        console.error('searchOwners', error);
      })
    },
    async searchVsoa(isNext) {
      if(isNext){
        this.resultsOld = [...this.resultsOld, ...this.resultsList]
      }
      this.resultsList = []

      this.isLoading = true
      let ordering = (this.ordering == '-' ? this.ordering : '')+'created_at'
      let url = isNext ? this.nextUrl : process.env.VUE_APP_URL+'/invoice?ordering='+ordering
      
      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
        cancelToken: new linkedCancelToken(function executor(c) {
          linkedCancel = c;
        }),
        params: isNext ? {} : this.paramsGetFilter({
          is_archive: false,
          limit: 10,
          invoice_type: 'SHIP',
          due_date_after: this.period[0] && moment(this.period[0]).isValid() ? moment(this.period[0]).format('YYYY-MM-DD') : null,
          due_date_before: this.period[1] && moment(this.period[1]).isValid() ? moment(this.period[1]).format('YYYY-MM-DD') : null,
          charterer: this.clients.length ? this.clients.map(el=>{return el.id}) : null,
          contract__many_contracts__id: this.vessel?.id
        }),
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      }).then(response => {
        this.nextUrl = response.data.next
        this.resultsList = [...this.resultsOld, ...response.data.results]
        this.resultsOld = []
        this.isLoading = false
      }).catch(error => {
        console.error('searchVsoa', error);
        this.isLoading = false
      })
    },

    async onSearch(isNext) {
      if(!this.operationType) return false

      if(this.isLoading){
        linkedCancel()
        linkedCancel = null
      }

      if(this.linkedType == 'Invoices')
        await this.searchPayment(isNext, 'payment')
      else{ // this.linkedType == 'Payments'
        if(this.operationType.name == 'Bill' || this.operationType.name == 'Invoice'){
          await this.searchPayment(isNext, 'invoice')
        }
        if(this.operationType.name == 'SOA with Owners' || this.operationType.name == 'SOA Sublet'){
          let contractType = this.operationType.name == 'SOA with Owners' ? 'SHIP' : 'SUBLET'
          await this.searchOwners(isNext, contractType)
        }
        if(this.operationType.name == 'Voyage Statement of Account'){
          await this.searchVsoa(isNext)
        }
      }

    },

    forEachForLinked(fromArr, toArr) {
      toArr.length = 0
      fromArr.forEach(el => {
        toArr.push(el)
      })
    },
    onConfirmModal() {
      this.forEachForLinked(this.localLinkedPayments, this.linkedPayments)
      this.forEachForLinked(this.localLinkedInvoices, this.linkedInvoices)
      this.forEachForLinked(this.localLinkedOwners, this.linkedOwners)
      this.$emit('on-change')
    },
    checkIsLinkType() {
      this.linkedPayments?.forEach(el => {
        el.link_type = 'linkedPayments'
      })
      this.linkedInvoices?.forEach(el => {
        el.link_type = 'linkedInvoices'
      })
      this.linkedOwners?.forEach(el => {
        el.link_type = 'linkedOwners'
      })
    }
  },
  beforeMount() {
    this.nextUrl = null

    this.checkIsLinkType()
  },
}
</script>

<style lang="scss">
.linked_modal__content{
  width: 720px;
  max-height: 386px;
  overflow: auto;
  .mx-input{
    height: 36px;
  }
}
.dart-mode {
  .block-border {
    border-color: #FFFFFF !important;
  }
}
</style>