<template>
  <div class="inside-folder drag-block" draggable="true" @dragend="$emit('dragEnd')" @dragstart="$emit('dragStart', child_folder)" :idFolder="child_folder.id" @mouseleave="hideSubMenu" @click="setActiveFolder(child_folder.id, parent_folder.title)">
    <button class="drag-btn-inside">

      <svg width="16" height="16">
        <use xlink:href="#drag_icn"></use>
      </svg>
    </button>
    <svg width="16" height="16">
      <use xlink:href="#folderDefault_icn"></use>
    </svg>
    <span class="name"  :title="child_folder.title">{{child_folder.title}}</span>
    <span class="unread-mails-count" v-if="child_folder.unread_mails_count != 0">{{child_folder.unread_mails_count}}</span>
    <span class="mails-count">{{child_folder.mails_count}}</span>
    <button class="menu" v-click-outside="hideSubMenu">
      <svg width="24" height="24" @click.stop="showSubMenu($event)">
        <use xlink:href="#menu_icn"></use>
      </svg>
      <div class="sub-menu">
        <button class="sub-menu__button" @click.stop="showFolderSettings(child_folder, false)">
          <svg width="24" height="24">
            <use xlink:href="#edit_name_icn"></use>
          </svg>
          <span>Edit folder</span>
        </button>
        <button class="sub-menu__button" @click.stop="uploadLetter(child_folder.id)" v-if="parent_folder.title !== 'Draft'">
          <svg width="24" height="24">
            <use xlink:href="#upload_letter_icn"></use>
          </svg>
          <span>Upload letter</span>
        </button>
        <button class="sub-menu__button" @click.stop="deleteFolder(child_folder.id)">
          <svg width="24" height="24">
            <use xlink:href="#delete_icn"></use>
          </svg>
          <span>Delete</span>
        </button>
      </div>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'child_folder',
    props: {
      child_folder: {type: Object},
      parent_folder: {type: Object}
    },
    inject: ['setActiveFolder', 'showFolderSettings', 'deleteFolder', 'uploadLetter'],
    methods: {
      showSubMenu(e){
        setTimeout(() => {
          e.target.nextSibling.classList.add('show')
        }, 100)
      },
      hideSubMenu(){
        let submenues = document.querySelectorAll('.sub-menu.show')
        submenues.forEach(element => {
          element.classList.remove('show')
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
@import "./src/components/mailbox/styles/folders";
.inside-folder{
  height: 28px;
  // padding-left: 8px;
  padding-left: 32px;
  display: flex;
  align-items: center;
  .name{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 8px;
    transition: .3s;
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .unread-mails-count{
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    color: var(--color-white);
    border-radius: 10px;
    background: var(--color-blue);
    margin-left: 4px;
  }
  .mails-count{
    margin-left: auto;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .drag-btn-inside{
    // left: -8px;
    left: 10px;
    position: absolute;
    display: none;
  }
  &:hover{
    cursor: pointer;
    .name{
      color: var(--color-blue);
      transition: .3s;
    }
    .drag-btn-inside{
      display: flex;
    }
    .menu{
      opacity: 1;
    }
  }
  .menu{
    position: relative;
    margin-left: 8px;
    opacity: 0;
    svg{
      use{
        pointer-events: none;
      }
    }
    &:hover{
      cursor: pointer;
    }
  }
  .sub-menu{
    position: absolute;
    padding: 8px;
    display: none;
    flex-direction: column;
    row-gap: 8px;
    right: 0;
    top: 0;
    background: var(--color-white);
    z-index: 9;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    width: fit-content;
    &.show{
      display: flex;
    }
  }
  .sub-menu__button{
    display: flex;
    align-items: center;
    column-gap: 4px;
    span{
      white-space: nowrap;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:hover{
      span{
        color: var(--color-blue);
      }
    }
  }
}
</style>