<template>
  <div v-if="item.notification_type == 'eventnotification' && false">
    <calendarNotificationItem :info="item"></calendarNotificationItem>
  </div>
  <div v-else>
    <div v-if="isOneRow" class="shadow bg-white rounded-lg m-b-2 flex p-x-4 p-y-3 flex">
      <div class="m-r-3 w-8 fz0 svg-item">
        <img v-if="item.activity.user && item.activity.user.avatar" :src="item.activity.user.avatar" :alt="item.activity.user.email" class="h-8 w-8 rounded-full">
        <!-- <svg v-else-if="item.activity.action == 'overdue' && getIfTask(item)" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="16" fill="#F4F6F9"/><path d="M10.3605 19.6918L15.593 4V17.017C15.593 17.2013 15.4857 17.3685 15.3188 17.4441L10.3605 19.6918Z" fill="#094172"/><path d="M21.6395 19.6918L16.407 4V17.017C16.407 17.2013 16.5143 17.3685 16.6812 17.4441L21.6395 19.6918Z" fill="#094172"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.9281 8.2179C10.2312 8.75358 6.5814 12.769 6.5814 17.6425C6.5814 19.2866 6.99649 20.8323 7.72674 22.1798L15.3882 18.4978V19.1419L7.4897 22.9403L7.35539 22.7083C6.49362 21.22 6 19.4889 6 17.6425C6 12.4678 9.87521 8.20493 14.8626 7.6361L14.9281 8.2179ZM25.4186 17.6425C25.4186 12.769 21.7688 8.75358 17.0719 8.2179L17.1374 7.6361C22.1248 8.20493 26 12.4678 26 17.6425C26 19.4889 25.5064 21.22 24.6446 22.7083L24.5087 22.9431L16.6118 19.1529L16.6036 18.4978L24.2748 22.177C25.0041 20.8301 25.4186 19.2855 25.4186 17.6425Z" fill="#094172"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3882 21.2235L8.77042 24.4585L8.51766 23.9313L15.3882 20.5647V21.2235Z" fill="#094172"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3882 23.1058L10.1295 25.7119L9.87826 25.1839L15.3882 22.4617V23.1058Z" fill="#094172"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3882 24.9795L11.4086 27L11.1573 26.472L15.3882 24.3354V24.9795Z" fill="#094172"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.6118 21.2235L23.4139 24.4234L23.6667 23.8961L16.6118 20.5647V21.2235Z" fill="#094172"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.6118 23.1058L22.1937 25.7119L22.445 25.1839L16.6118 22.4617V23.1058Z" fill="#094172"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.6118 24.9795L20.9146 27L21.166 26.472L16.6118 24.3354V24.9795Z" fill="#094172"/></svg> -->
        <svg v-else-if="item.activity.action == 'overdue'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="16" fill="#F4F6F9"/><path d="M14.5781 6.68066H17.4092L17.2061 18.9316H14.7812L14.5781 6.68066ZM15.9873 25.1904C15.5218 25.1904 15.1283 25.0339 14.8066 24.7207C14.485 24.4076 14.3242 24.0182 14.3242 23.5527C14.3242 23.0872 14.485 22.6979 14.8066 22.3848C15.1283 22.0716 15.5218 21.915 15.9873 21.915C16.4613 21.915 16.859 22.0716 17.1807 22.3848C17.5023 22.6979 17.6631 23.0872 17.6631 23.5527C17.6631 24.0182 17.5023 24.4076 17.1807 24.7207C16.859 25.0339 16.4613 25.1904 15.9873 25.1904Z" fill="#E85353"/></svg>
<!--        <svg  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="16" fill="#F4F6F9"/><path d="M14.5781 6.68066H17.4092L17.2061 18.9316H14.7812L14.5781 6.68066ZM15.9873 25.1904C15.5218 25.1904 15.1283 25.0339 14.8066 24.7207C14.485 24.4076 14.3242 24.0182 14.3242 23.5527C14.3242 23.0872 14.485 22.6979 14.8066 22.3848C15.1283 22.0716 15.5218 21.915 15.9873 21.915C16.4613 21.915 16.859 22.0716 17.1807 22.3848C17.5023 22.6979 17.6631 23.0872 17.6631 23.5527C17.6631 24.0182 17.5023 24.4076 17.1807 24.7207C16.859 25.0339 16.4613 25.1904 15.9873 25.1904Z" fill="#E85353"/></svg>-->
        <svg v-else-if="item.activity.action == 'deadline_soon'" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.5407 23.5378L17.3895 0V19.5255C17.3895 19.8019 17.2286 20.0527 16.9782 20.1662L9.5407 23.5378Z" fill="#094172"/>
          <path d="M26.4593 23.5378L18.6105 0V19.5255C18.6105 19.8019 18.7714 20.0527 19.0218 20.1662L26.4593 23.5378Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3921 6.32684C9.34679 7.13038 3.87209 13.1535 3.87209 20.4638C3.87209 22.9299 4.49474 25.2484 5.59012 27.2697L17.0824 21.7467V22.7128L5.23456 28.4105L5.03308 28.0625C3.74043 25.8299 3 23.2333 3 20.4638C3 12.7016 8.81281 6.30739 16.294 5.45415L16.3921 6.32684ZM32.1279 20.4638C32.1279 13.1535 26.6532 7.13038 19.6079 6.32684L19.706 5.45415C27.1872 6.30739 33 12.7016 33 20.4638C33 23.2333 32.2596 25.8299 30.9669 28.0625L30.7631 28.4146L18.9176 22.7294L18.9053 21.7467L30.4122 27.2655C31.5061 25.2452 32.1279 22.9282 32.1279 20.4638Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0824 25.8353L7.15564 30.6878L6.7765 29.8969L17.0824 24.8471V25.8353Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0824 28.6587L9.19424 32.5678L8.81739 31.7758L17.0824 27.6926V28.6587Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0824 31.4692L11.1129 34.5L10.7359 33.708L17.0824 30.5031V31.4692Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9176 25.8353L29.1209 30.6351L29.5 29.8441L18.9176 24.8471V25.8353Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9176 28.6587L27.2906 32.5678L27.6675 31.7758L18.9176 27.6926V28.6587Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9176 31.4692L25.3719 34.5L25.7489 33.708L18.9176 30.5031V31.4692Z" fill="#094172"/>
        </svg>

        <svg v-else width="32" height="32" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"></circle><path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"></path></svg>
      </div>
      <div class="">
        <p class="text-gray-text fz12">{{getPartName}}</p>
        <p class="fz14 break-anywhere">
          <span class="text-blue-dark" v-if="item.activity.user">{{item.activity.user.first_name}} {{item.activity.user.last_name}} </span>
          <span v-html="getNotificationAction()"></span>
          <!-- {{item.activity.action == 'create' ? 'create' : 'overdue'}}
          <span class="text-blue-dark">{{getNotificationGoal}}</span> -->
        </p>
      </div>
      <div v-if="item.activity.action == 'deadline_soon'" class="m-l-auto text-gray-text fz12 flex-shrink-0">
        {{item.activity.created_at | notificationDateTime1}}
      </div>
      <div v-else class="m-l-auto text-gray-text fz12 flex-shrink-0">
        {{item.activity.created_at | notificationDateTime}}
      </div>

    </div>

    <div v-else v-for="note in filteredChanges" :key="note.id" class="shadow bg-white rounded-lg m-b-2 flex p-x-4 p-y-3 flex">
      <div class="m-r-3 w-8 fz0">
        <img v-if="item.activity.user && item.activity.user.avatar" :src="item.activity.user.avatar" :alt="item.activity.user.email" class="h-8 w-8 rounded-full">
        <svg v-else width="32" height="32" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"></circle><path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"></path></svg>
      </div>
      <div class="">
        <p class="text-gray-text fz12">{{getPartName}}</p>
        <p class="fz14 break-anywhere">
          <span class="text-blue-dark" v-if="item.activity.user">{{item.activity.user.first_name}} {{item.activity.user.last_name}} </span>
          <span v-html="getNotificationAction(note)"></span>
          <!-- {{getNotificationAction(note)}}
          <span class="text-blue-dark">{{getNotificationGoal}}</span> -->
        </p>
      </div>
      <div class="m-l-auto text-gray-text fz12 flex-shrink-0">
        {{item.activity.created_at | notificationDateTime}}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import calendarNotificationItem from '@/components/calendar/calendarNotificationItem'

export default {
  props: {
    item: Object
  },
  components: {calendarNotificationItem},
  filters: {
    notificationDateTime: function(date) {
      if(!moment(date).isValid()) return ''
      date = moment(date).add('2', 'hours')
      return moment(date).format('DD MMM') + ' at '+ moment(date).format('HH:mm')
    },
    notificationDateTime1: function(date) {
      if(!moment(date).isValid()) return ''
      return moment(date).format('DD MMM') + ' at '+ moment(date).format('HH:mm')
    },
  },
  data() {
    return {
      hiddenInPayments: ['currency_amount', 'statement_member', 'cp_date', 'bank']
    }
  },
  computed: {
    filteredChanges() {
      return this.item.activity.changes.filter(el=>{
        if(this.item.notification_type == 'payment'){
          return this.hiddenInPayments.indexOf(el.field) < 0
        }else{
          return true
        }
      })
    },
    isOneRow() {
      return this.item.activity.changes.length == 0 
      || this.item.notification_type == 'route'
      || this.item.notification_type == 'user'
    },
    getPartName() {
      if(this.item.notification_type == 'payment' || this.item.notification_type == 'invoice') return 'Payments'
      if(this.item.notification_type == 'port') return 'Ports'
      if(this.item.notification_type == 'layreport'){
        return `Vessels: `+this.getVesselTitle(this.item.activity.instance.contract)
      }
      if(this.item.notification_type == 'nonereport'){
        return `Vessels: `+this.getVesselTitle(this.item.activity.instance.contract)
      }
      if(this.item.notification_type == 'contract' || this.item.notification_type == 'voyage'){
        let activity = this.item.activity
        if(activity.model == 'payment')
          return `Vessels: `+this.getVesselTitle(activity.instance.ship)
        if(activity.action == 'update' && (activity.changes[0].field == 'cargo_contracts' || activity.changes[0].field == 'operation_manager' || activity.changes[0].field == 'chartering_manager'))
          return `Vessels: `+this.getVesselTitle(activity.instance)
        if(activity.action == 'create' && activity.model == 'payment')
          return `Vessels: `+this.getVesselTitle(activity.instance.ship)
        return 'Vessels'
      }
      if(this.item.notification_type == 'shipsearchtask') return 'Vessel Contracts'
      if(this.item.notification_type == 'index' || this.item.notification_type == 'route') return 'Indexes'
      if(this.item.notification_type == 'user') return 'Employees'
      if(this.item.notification_type == 'client') return 'Counterparties'
      if(this.item.notification_type == 'paymentaccount' || this.item.notification_type == 'financialstatement' || this.item.notification_type == 'cashaccount' || this.item.notification_type == 'requisite') return 'Accounting'
      if(this.item.notification_type == 'task') return 'Tasks'
      if(this.item.notification_type == 'task_is_overdue') return 'Tasks'
      if(this.item.notification_type == 'invoice_is_overdue') return 'Invoices'
      if(this.item.notification_type == 'payment_is_overdue') return 'Payments'
      if(this.item.notification_type == 'eventnotification') return 'Calendar'
      return 'Other'
    },
    getNotificationGoal() {
      if(this.item.notification_type == 'payment'){
        if(this.item.activity.instance.payment_type == 'expense'){
          return `<a href="#/payment/detail/${this.item.activity.instance.id}" class="text-blue-dark">Payment</a>`
        }else{
          return `<a href="#/payment/detail/${this.item.activity.instance.id}" class="text-blue-dark">Income</a>`
        }
      }
      if(this.item.notification_type == 'port') return `<a href="#/ports/${this.item.activity.instance.id}" class="text-blue-dark">${this.item.activity.instance.name}${this.item.activity.instance.country ? ', '+this.item.activity.instance.country : ''}</a>`
      if(this.item.notification_type == 'invoice'){
        if(this.item.activity.instance.invoice_type == 'DETENTION'){
          return `<a href="#/payment/detail/${this.item.activity.instance.id}" class="text-blue-dark">Payment</a>`
        }else{
          return `<a href="#/payment/detail/${this.item.activity.instance.id}" class="text-blue-dark">Income</a>`
        }
      }
      if(this.item.notification_type == 'shipsearchtask') return 'Vessel Contracts'
      if(this.item.notification_type == 'index') return `<a href="#/baltic-dry-index" class="text-blue-dark">${moment(this.item.activity.instance.created_at).format('DD MMM')}</a>`
      if(this.item.notification_type == 'route') return `<a href="#/baltic-dry-index" class="text-blue-dark">Baltic Dry Index</a>`
      if(this.item.notification_type == 'user'){
        let img = this.item.activity.instance.avatar ? `<img src="${process.env.VUE_APP_URL +'/media/'+ this.item.activity.instance.avatar}" class="vertical-middle rounded-full h-6 w-6">` : '<svg class="vertical-middle" width="24" height="24" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"></circle><path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"></path></svg>'
        return img + ` <a href="#/employees">${this.item.activity.instance.first_name} ${this.item.activity.instance.last_name}</a>`
      }
      if(this.item.notification_type == 'client') return `<a href="#/counterparties/${this.item.activity.id}" class="text-blue-dark">${this.item.activity.instance.company_name}</a>`
      if(this.item.notification_type == 'paymentaccount') return `<a href="#/accounts/trial-balance" class="text-blue-dark">${this.item.activity.instance.name}</a>`
      if(this.item.notification_type == 'cashaccount') return `<a href="#/accounts/bank-and-cash" class="text-blue-dark">${this.item.activity.instance.name}</a>`
      if(this.item.notification_type == 'requisite') return `<a href="#/accounts/bank-and-cash" class="text-blue-dark">${this.item.activity.instance.cash.name}</a>`
      if(this.item.notification_type == 'financialstatement') return this.item.activity.instance.name
      /* if(this.item.notification_type == 'task_is_overdue') return 'Tasks overdue'
      if(this.item.notification_type == 'invoice_is_overdue') return 'Invoices overdue'
      if(this.item.notification_type == 'payment_is_overdue') return 'Payments overdue' */
      return 'Other'
    },
    getPaymentType() {
      let instance = this.item.activity.instance
      let paymentType = 'Bill'
      if(instance.payment_type == 'expense')
        paymentType = 'Invoice'
      return paymentType
    }
  },
  methods: {
    getIfTask(item) {
      return item?.activity?.instance?.due_date_status !== 'due_date' ? true : false;
    },
    getTaskGoal() {
      if(this.item.activity.changes.length && this.item.activity.changes[0].field == 'executors'){
        let assigned = []
        let removed = []

        if(this.item.activity.changes[0].changed_from.length){ // removed
          this.item.activity.changes[0].changed_from.forEach(el => {
            if(this.item.activity.changes[0].changed_to.findIndex(item => {
              return item.id == el.id
            }) == -1){
              removed.push(this.getTaskExecutors(el))
            }
          })
        }
        if(this.item.activity.changes[0].changed_to.length){ // assigned
          this.item.activity.changes[0].changed_to.forEach(el => {
            if(this.item.activity.changes[0].changed_from.findIndex(item => {
              return item.id == el.id
            }) == -1){
              assigned.push(this.getTaskExecutors(el))
            }
          })
        }

        if(assigned.length && removed.length){
          return `assigned the task <a href="#/task/${this.item.activity.instance.id}" class="text-blue-dark">"${this.item.activity.instance.name}"</a> to ${assigned}, and remove assigned ${removed}`
        }else if(assigned.length){
          return `assigned the task <a href="#/task/${this.item.activity.instance.id}" class="text-blue-dark">"${this.item.activity.instance.name}"</a> to ${assigned}`
        }else if(removed.length){
          return `remove assigned the task <a href="#/task/${this.item.activity.instance.id}" class="text-blue-dark">"${this.item.activity.instance.name}"</a> to ${removed}`
        }
      } else if(this.item.activity.changes.length && this.item.activity.changes[0].field == 'is_archived') {
        return `${this.item.activity.changes[0].changed_to == 'True' ? 'archived' : 'unarchived'} the task <a href="#/task/${this.item.activity.instance.id}" class="text-blue-dark">"${this.item.activity.instance.name}"</a>`
      } else if(this.item.activity?.instance?.due_date_status !== 'due_date') {
        return `The deadline of the task <a href="#/task/${this.item.activity.instance.id}" class="text-blue-dark">"${this.item.activity.instance.name}"</a>`
      } else {
        return `<a href="#/task/${this.item.activity.instance.id}" class="text-blue-dark">"${this.item.activity.instance.name}"</a>`
      }
    },
    getTaskExecutors(user) {
      let img = user.avatar ? `<img src="${process.env.VUE_APP_URL +'/media/'+ user.avatar}" class="vertical-middle h-6 w-6 rounded-full">` : '<svg class="vertical-middle" width="24" height="24" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"></circle><path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"></path></svg>'
      return `${img} <a href="#/employees" class="text-blue-dark">${user.first_name} ${user.last_name}</a>`
    },
    getVesselTitle(instance) {
      return `${instance.name} ${moment(instance.cp_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}`
    },
    getVesselsGoal(item) {
      let activity = this.item.activity
      if(activity.action == 'create'){
        if(activity.model == 'payment'){
          
          return `created the <a href="#/payment/detail/${activity.instance.id}" class="text-blue-dark">${this.getPaymentType}</a>`
        }
        return `created the vessel <a href="#/vessels/${activity.instance.id}" class="text-blue-dark">${this.getVesselTitle(activity.instance)}</a>`
      }
      if(activity.action == 'update'){
        if(item){
          if(activity.model == 'payment' && item.field == 'is_archive'){
            return `${item.changed_to == 'True' ? 'archived':'unarchived'} the <a href="#/payment/detail/${activity.instance.id}" class="text-blue-dark">${this.getPaymentType}</a>`
          }
          if(item.field == 'is_archive'){
            return `${item.changed_to == 'True' ? 'archived':'unarchived'} the vessel <a href="#/vessels/${activity.instance.id}" class="text-blue-dark">${this.getVesselTitle(activity.instance)}</a>`
          }
          if(item.field == 'execute_date'){
            return `changed the Due Date on ${moment(item.changed_to).format('DD MMM')} in the <a href="#/payment/detail/${activity.instance.id}" class="text-blue-dark">${this.getPaymentType}</a>`
          }
          if(item.field == 'payment_amount'){
            return `edited the payment amount to ${item.changed_to} ${activity.instance.currency} (${activity.instance.currency_amount} USD) in the <a href="#/payment/detail/${activity.instance.id}" class="text-blue-dark">${this.getPaymentType}</a>`
          }
           if(item.field == 'currency_amount'){
            return `edited the currency amount to ${item.changed_to} ${activity.instance.currency} (${activity.instance.currency_amount} USD) in the <a href="#/payment/detail/${activity.instance.id}" class="text-blue-dark">${this.getPaymentType}</a>`
          }
          if(item.field == 'operation_manager'){
            return `assigned the Operations Manager in the <a href="#/vessels/${activity.instance.id}" class="text-blue-dark">${this.getVesselTitle(activity.instance)}</a>`
          }
          if(item.field == 'chartering_manager'){
            return `assigned the Chartering Manager in the <a href="#/vessels/${activity.instance.id}" class="text-blue-dark">${this.getVesselTitle(activity.instance)}</a>`
          }
          if(item.field == 'cargo_contracts'){
            let linked = []
            let unlinked = []
  
            if(item.changed_from.length){ // unlinked
              item.changed_from.forEach(el => {
                if(item.changed_to.findIndex(item => {
                  return item.id == el.id
                }) == -1){
                  unlinked.push(el)
                }
              })
            }
            if(item.changed_to.length){ // linked
              item.changed_to.forEach(el => {
                if(item.changed_from.findIndex(item => {
                  return item.id == el.id
                }) == -1){
                  linked.push(el)
                }
              })
            }
  
            
  
            if(linked.length){
              let contract = linked[0].contract_type == 'CARGO' ? 'Voyage Contract' : 'Sublet Contract'
              return `linked the ${contract} to the <a href="#/vessels/${activity.instance.id}" class="text-blue-dark">${this.getVesselTitle(activity.instance)}</a>`
            }else if(unlinked.length){
              let contract = unlinked[0].contract_type == 'CARGO' ? 'Voyage Contract' : 'Sublet Contract'
              return `unlinked the ${contract} to the <a href="#/vessels/${activity.instance.id}" class="text-blue-dark">${this.getVesselTitle(activity.instance)}</a>`
            }
          }
        }
      }
      if(activity.action == 'overdue'){
        return `<a href="#/payment/detail/${activity.instance.id}" class="text-blue-dark">${this.getPaymentType}</a> is overdue`
      }
    },
    getNotificationAction(item) {
      let string = ''

      if(this.item.notification_type == 'payment'){
        if(item){
          if(item.field == 'paid_date')
            string = `changed Paid date on ${moment(item.changed_to).format('DD MMM')} in the ` + this.getNotificationGoal
          
          if(item.field == 'execute_date')
            string = `changed the Due Date on ${moment(item.changed_to).format('DD MMM')} in the ` + this.getNotificationGoal
          
          if(item.field == 'payment_amount')
            string = `edited the payment amount to ${item.changed_to} ${this.item.activity.instance.currency} in the ` + this.getNotificationGoal

          if(item.field == 'status')
            string = `changed the status to “${item.changed_to.charAt(0).toUpperCase() + item.changed_to.slice(1)}” in the ` + this.getNotificationGoal
          
          if(item.field == 'is_archive'){
            string = item.changed_to == 'True' ? 'archived the ' : 'unarchived the '
            string += this.getNotificationGoal
          }
        }else if(this.item.activity.action == 'overdue'){
          string = this.getNotificationGoal + ' is overdue'
        }else if(this.item.activity.action == 'create'){
          string = 'created ' + this.getNotificationGoal
        }

        return string
      }

      if(this.item.notification_type == 'port'){
        if(item){
          if(item.field == 'is_archive')
            string = 'archived the ' + this.getNotificationGoal
        }else if(this.item.activity.action == 'create'){
          string = 'created ' + this.getNotificationGoal
        }
        return string
      }

      if(this.item.notification_type == 'contract' || this.item.notification_type == 'voyage'){
        string = this.getVesselsGoal(item)
        return string
      }

      if(this.item.notification_type == 'invoice'){
        if(this.item.activity.action == 'overdue'){
          return string = this.getNotificationGoal + ' is overdue'
        }
      }

      if(this.item.notification_type == 'index'){
        if(this.item.activity.action == 'create'){
          string = 'created the index from ' + this.getNotificationGoal
        }
        return string
      }
      if(this.item.notification_type == 'route'){
        if(this.item.activity.action == 'create'){
          string = `changed the route "${this.item.activity.instance.reduction}" in the ` + this.getNotificationGoal
        }else if(this.item.activity.action == 'update'){
          string = `created the route "${this.item.activity.instance.reduction}" in the ` + this.getNotificationGoal
        }
        return string
      }

      if(this.item.notification_type == 'user'){
        if(this.item.activity.action == 'create'){
          string = `created a new employee ` + this.getNotificationGoal
        }else if(this.item.activity.action == 'update'){
          if(this.item.activity.changes.length && this.item.activity.changes[0].field == 'is_active'){
            string = this.item.activity.changes[0].changed_to == 'True' ? 'unarchived the ' : 'archived the '
            string += this.getNotificationGoal
          }else{
            string = `edited the employee ` + this.getNotificationGoal
          }
        }
        return string
      }

      if(this.item.notification_type == 'client'){
        if(this.item.activity.action == 'create'){
          string = `created a new counterparty ` + this.getNotificationGoal
        }else if(this.item.activity.action == 'update'){
          if(this.item.activity.changes[0].field == 'is_archived'){
            string = this.item.activity.changes[0].changed_to == 'True' ? 'archived the counterparty ' : 'unarchived the counterparty '
            string += this.getNotificationGoal
          }
        }
        return string
      }

      if(this.item.notification_type == 'paymentaccount'){
        if(this.item.activity.action == 'create'){
          string = `created a new account ` + this.getNotificationGoal
        }else if(this.item.activity.action == 'update'){
          if(this.item.activity.changes[0].field == 'is_archive'){
            string = this.item.activity.changes[0].changed_to == 'True' ? 'archived the account ' : 'unarchived the account '
            string += this.getNotificationGoal
          }
        }
        return string
      }
      if(this.item.notification_type == 'cashaccount'){
        if(this.item.activity.action == 'create'){
          string = `created a new Bank/Cash Account ` + this.getNotificationGoal
        }else if(this.item.activity.action == 'update'){
          if(this.item.activity.changes[0].field == 'is_archive'){
            string = this.item.activity.changes[0].changed_to == 'True' ? 'archived the Bank/Cash Account ' : 'unarchived the Bank/Cash Account '
            string += this.getNotificationGoal
          }
        }
        return string
      }
      if(this.item.notification_type == 'requisite'){
        if(this.item.activity.action == 'create'){
          string = `created a new Account in the ` + this.getNotificationGoal
        }else if(this.item.activity.action == 'update'){
          if(this.item.activity.changes[0].field == 'is_archive'){
            string = this.item.activity.changes[0].changed_to == 'True' ? 'archived the Account in the ' : 'unarchived the Account in the '
            string += this.getNotificationGoal
          }
        }
        return string
      }
      if(this.item.notification_type == 'financialstatement'){
        if(this.item.activity.action == 'create'){
          string = `created a new FS ` + this.getNotificationGoal
        }else if(this.item.activity.action == 'update'){
          if(this.item.activity.changes[0].field == 'is_archive'){
            string = this.item.activity.changes[0].changed_to == 'True' ? 'archived the FS ' : 'unarchived the FS '
            string += this.getNotificationGoal
          }
        }
        return string
      }

      if(this.item.notification_type == 'task'){
        if(this.item.activity.action == 'create'){
          string = `created the task ` + this.getTaskGoal()
        }else if(this.item.activity.action == 'overdue'){
          if(this.item.activity?.instance?.due_date_status !== 'due_date') {
            string = this.getTaskGoal() + ' is today ' + moment(new Date()).format("(DD.MM.YYYY)")
          } else {
            string = this.getTaskGoal() + ' is overdue'
          }
        }else if(this.item.activity.action == 'update'){
          string = this.getTaskGoal()
          /* if(this.item.activity.changes[0].field == 'is_archive'){
            string = this.item.activity.changes[0].changed_to == 'True' ? 'archived the task ' : 'unarchived the task '
            string += this.getTaskGoal()
          }else if(this.item.activity.changes[0].field == 'executors'){
            string = `assigned the task ` + this.getTaskGoal()
          } */
        } else if(this.item.activity.action === 'deadline_soon') {
          string = 'The deadline of the task ' + this.getTaskGoal() + 'is today at ' +  moment(new Date(this.item.activity.instance.execute_time)).format("HH:mm") + ' ' + moment(new Date(this.item.activity.created_at)).format("(DD.MM.YYYY)")
        }
        return string
      }
      if(this.item.notification_type == 'layreport'){
        let activity = this.item.activity
        if(activity.action == 'create'){
          string = `create the <a href="#/vessels/${activity.instance.contract.id}/open/lay" class="text-blue-dark">LayTime</a>`
        }
        if(activity.action == 'update' && item.field == 'is_archive'){
          string = `${item.changed_to == 'True'?'archived':'unarchived'} the <a href="#/vessels/${activity.instance.contract.id}/open/lay" class="text-blue-dark">LayTime</a>`
        }
        return string
      }
      if(this.item.notification_type == 'nonereport'){
        let activity = this.item.activity
        if(activity.action == 'create'){
          string = `create the <a href="#/vessels/${activity.instance.contract.id}/open/reports" class="text-blue-dark">NoonReport</a>`
        }
        if(activity.action == 'update' && item.field == 'is_archive'){
          string = `${item.changed_to == 'True'?'archived':'unarchived'} the <a href="#/vessels/${activity.instance.contract.id}/open/reports" class="text-blue-dark">NoonReport</a>`
        }
        return string
      }
      return 'Other ' + this.item.notification_type + ', id:' + this.item.id
    }
  }
}
</script>

<style lang="scss" scoped>
  .svg-item {
    svg {
      width: 32px;
      height: 32px;
    }
  }

</style>