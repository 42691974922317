<template>
  <div class="wrap-chat-block">
    <div class="write-comment-block" :class="{'write-comment-block-focus':focusText}">
      <textarea type="text" @focus="focusText=true" @focusout="focusText = false" v-on:keydown.enter="sedMessage"  v-model="text" placeholder="Write a comment"></textarea>
      <button class="button-send blue-button fz14 fw500 buttonW100 h28" v-on:click="sedMessage">Send</button>
      <button class="upload-file blue-button-text fz13 fw500" v-if="getPermissionsForUserCanAddFile">
        <input type="file" multiple  id="files" ref="files" v-on:change="handleFilesUpload()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 20C9.24268 20 7 17.7573 7 15V8C7 7.63135 7.29871 7.33337 7.66663 7.33337C8.03467 7.33337 8.33337 7.63135 8.33337 8V15C8.33337 17.0214 9.97803 18.6666 12 18.6666C14.022 18.6666 15.6666 17.0214 15.6666 15V7.66663C15.6666 6.38 14.62 5.33337 13.3334 5.33337C12.0466 5.33337 11 6.38 11 7.66663V14.3334C11 14.8846 11.4486 15.3334 12 15.3334C12.5514 15.3334 13 14.8846 13 14.3334V8C13 7.63135 13.2987 7.33337 13.6666 7.33337C14.0347 7.33337 14.3334 7.63135 14.3334 8V14.3334C14.3334 15.62 13.2866 16.6666 12 16.6666C10.7134 16.6666 9.66663 15.62 9.66663 14.3334V7.66663C9.66663 5.64539 11.3113 4 13.3334 4C15.3553 4 17 5.64539 17 7.66663V15C17 17.7573 14.7573 20 12 20Z" fill="#9AA3B0"/>
        </svg>
      </button>
    </div>
    <!--    {{userChat}}-->
    <div class="message-wrap" v-chat-scroll>
      <div class="message" v-for="(m, i) in userChat" :key="i">
        <div class="user-data">
          <img v-if="m.avatar !== null && m.avatar.length !== 0" :src="getURL()+'/media/'+m.avatar" class="img-profile" alt="">
          <svg v-if="m.avatar === null || m.avatar.length === 0" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="80" cy="80" r="80" fill="white"/>
            <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
          </svg>
          <p class="text-dark fz13 fw500">{{m.username}}</p>
          <p class="created fz13 text-gray">{{getFormDate(m.created_at)}}</p>
        </div>
        <div class="text" v-if="m.type === 'text'">
          <p class="text-dark fz14">{{m.text}}</p>
        </div>
        <div class="file-type" v-if="m.type === 'file'">
          <img :src="getUrlUser(m.file_url)" v-if="m.file_url !== null && ''+m.file_url !== ''" alt="preview">
          <img src="../../../assets/imgNone.svg" v-if="m.file_url === null || ''+m.file_url === ''">
          <p class="fz11 text-dark">{{m.text}}</p>
        </div>
      </div>
    </div>
    <!--    {{testEvent.data}}-->


  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Vue from 'vue';
import moment from 'moment';
Vue.prototype.$moment = moment;
import VueChatScroll from 'vue-chat-scroll';
Vue.use(VueChatScroll);
export default {
  name: "vessel-detail-chat",
  computed: {
    ...mapState({
      chatResponse: state => state.chatResponse,
    }),
    ...mapGetters(["getChatResponse", "getToken", "getPermissionsForUserCanAddFile"]),
  },
  data() {
    return {
      focusText: false,
      socket: null,
      connected: false,
      userActivity: null,
      userChat: [],
      text: "",
      files: [],
      testEvent: null,
    }
  },
  methods: {
    ...mapActions(["axiosPostChat", "FileAdd"]),
    closeModalEditClean() {
      this.indexShipForModalInContract = null;
      this.contractValueName = 'contracts';
      this.contractCreateModal = true;
      // if(!this.axiosBool) {
      this.showModalEditClean = false;
      this.errorCPDate = false;
      this.CPDate = null;
      this.deliveryDate = null;
      this.redeliveryDate = null;
      this.activeBroker = null;
      this.defaultActiveBroker = null;
      this.vesselNameEdit = "";
      this.builtEdit = "";
      this.dwtEdit = "";
      this.perEdit = "";
      this.defaultOwnerActive = null;
      this.activeOwner = null;
      this.deliveryActive = null;
      this.redeliveryActive = null;
      this.defaultDeliveryActive = null;
      this.defaultRedeliveryActive = null;
      this.activeValuta = null;
      this.defaultActiveValuta = {name: "USD", id: "USD"};
      this.laycanDateEdit = "";
      this.hireEdit = "";
      this.assignedOperations = [];
      this.assignedChartering = [];
      this.commentEdit = "";
      this.cargoCommentEdit = "";
      this.periodEdit = [null, null];
      this.defaultActiveOperations = null;
      this.defaultActiveChartering = null;
      this.idShipForModal = null;
      // }
    },

    getUrlUser(link) {
      if(link && link.indexOf(process.env.VUE_APP_URL)>=0) {
        return link;
      }
      return this.getURL()+ link
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
    getFormDate(date) {
      // return new Date(Number(date))
      return "" + moment(Number(date + '000')).format('DD MMM, HH:mm')
    },
    connectSocked() {
      // let url = 'ws://'+process.env.VUE_APP_URL_SOCKET+":"+process.env.VUE_APP_PORT_CHAT+"/"
      let url = 'wss://'+process.env.VUE_APP_URL_CHAT+"/"
      // let url = 'ws://134.209.119.20:3000/'
      this.socket = new WebSocket(url);
    },
    sedMessage() {
      if(this.text.length !==0) {
        // let textRoute = this.$route.path;
        // let idTask = textRoute.replace(/[^0-9]/gim,'');
        let data = {
          "chat_id": this.getChatResponse.id,
          "token": this.getToken,
          "event_type": "send_message",
          "message": {
            "type": "text",
            "text": this.text,
          }
        }
        console.log("sedMessage")
        console.log(data)
        this.socket.send(JSON.stringify(data));
        this.text = ""
      }
    },
    handleFilesUpload(){
      if(this.getPermissionsForUserCanAddFile) {
        // let textRoute = this.$route.path;
        // let idTask = textRoute.replace(/[^-0-9]/gim,'');
        // this.files.splice(0, this.files.length)
        this.files = this.$refs.files.files;
        // console.log(this.files);
        // console.log(this.files.length);
        if(this.files.length !== 0) {
          let files = []
          this.FileAdd({file: this.files}).then(response=> {
            files = response;
            this.$emit("addFile", files)
            for(let i=0; i<this.files.length; i++) {
              let data = {
                "chat_id": this.getChatResponse.id,
                "token": this.getToken,
                "event_type": "send_file",
                "message": {
                  "type": "file",
                  "text": files[i].name,
                  "file_url": files[i].file,
                }
              }
              console.log("chat");
              // console.log(files);
              console.log(data);
              this.socket.send(JSON.stringify(data));
              this.$emit('refreshFile');
              // console.log("response")
              // console.log(response)
              // console.log(response.data)
              // console.log(response.data.file)
            }
          })


        }
      }
    },
    getMessages() {
      // let textRoute = this.$route.path;
      // let idTask = textRoute.replace(/[^0-9]/gim,'');
      if(this.getChatResponse !== null) {
        let data = {
          "chat_id": this.getChatResponse.id,
          "token": this.getToken,
          "event_type": "get_history",
          "message": null
        }
        // console.log(data)
        this.socket.send(JSON.stringify(data));
      }

    }
  },
  updated() {
    // this.getMessages();
  },
  mounted() {
    let textRoute = this.$route.path;
    let idTask = textRoute.replace(/[^0-9]/gim,'');

    this.axiosPostChat({type: 'contract', id: idTask}).then(()=> {
      vm.getMessages();
    })
    this.connectSocked();

    this.socket.onopen = function() {
      // vm.setSocket(vm.socket);

      console.log("Socket - Соединение установлено чат");
      vm.getMessages();
      vm.connected = true;
    };

    let vm=this;
    // this.connectSocked();


    this.socket.onmessage = function(event) {
      vm.testEvent = event;
      console.log("CHAT")
      console.log(JSON.parse(event.data))
      if(JSON.parse(event.data).event_type === "get_history") {
        vm.userChat = JSON.parse(event.data).messages;
        let mes = document.getElementsByClassName('message-wrap')[0]
        mes.scrollTop = mes.scrollHeight;
      } else {
        vm.getMessages();
      }
    };
    this.socket.onclose = function () {
      console.log("Socket - Соединение закрыто чат");
      vm.connected = false;
      vm.connectSocked();
    }
    this.socket.onerror = function(error) {
      console.log("Ошибка " + error.message);
    };

  }
}
</script>

<style scoped lang="scss">
  @import "vessel-detail-chat";
</style>