<template>
  <div>
    <b-form-checkbox
        id="checkbox-1"
        v-model="statusLocal"
        class="fz14 fw500 text-dark1"
        name="checkbox-1"
        @change="changeStatusCheckbox"
    >
      Income
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "payment-checkbox-income",
  props: ["name", "status"],
  data() {
    return {
      statusLocal: false,
    }
  },
  watch: {
    status: function () {
      this.statusLocal = this.status;
    }
  },
  methods: {
    changeStatusCheckbox() {
      this.$emit("changeStatusCheckbox", this.statusLocal);
      // this.statusLocal = this.status;
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
@import "../../components/style-element/bootstrap";
@import "../../components/style-element/checkbox";
</style>