<template>
  <modal-slots
    :is-pointer-bg="false"
    :no-close-after-apply="true"
    :show-mod="show"
    @close="closeModal"
    class="modal-edit-clean"
    :textForSecondButton="'Invite'"
    @apply="addMembers"
  >
    <template v-slot:title>
      <p class="fz18 fw500">Invite Member</p>
    </template>
    <div class="modal-edit-clean-content create-event-wrapper">
      <div class="members-search flex flex-col">
        <span class="fz13 text-gray m-b-1">Members</span>
        <div class="my-custom-select-wrapper">
          <multiselect
            v-model="members.selectedMembers"
            label="name"
            track-by="name"
            :options="members.membersList"
            :multiple="true"
            :taggable="false"
            :limit="1"
            :limit-text="limitText"
            :show-labels="false"
          >
            <template slot="tag" slot-scope="{ option }">
              <span class="selected">
                <span>{{ option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__name">
                <div class="avatar" v-if="!props.option.avatar"></div>
                <div class="avatar" v-else :style="{ 'background-image': `url(${props.option.avatar})` }"></div>
                {{ props.option.name }}
              </div>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
  </modal-slots>
</template>
<script>
import ModalSlots from "@/components/modal/modal-slots";
import { mapActions, mapMutations, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
export default {
  props: ["show", "selected", "excludeMembers"],
  components: { ModalSlots, Multiselect },
  data() {
    return {
      members: {
        selectedMembers: [],
        membersList: [],
      },
      clearSelected: false,
    }
  },
  methods: {
    ...mapActions([
      "axiosGetCalendarMembersList"
    ]),
    ...mapGetters(["getCalendarMembersList"]),
    ...mapMutations(["showLoad", "hideLoad"]),
    limitText(count) {
      return `+ ${count}`;
    },
    closeModal() {
      this.$emit("close", false);
    },
    async getMembers() {
      if (this.members.membersList.length == 0){
        let members;
        let storeMembers = this.getCalendarMembersList();
        if (storeMembers) {
          members = storeMembers;
        } else {
          members = await this.axiosGetCalendarMembersList();
        }
        if(this.excludeMembers.length != 0){
          let filteredMembers = members.results.filter(el => !this.excludeMembers.includes(el.id))
          this.members.membersList = filteredMembers
        }else{
          this.members.membersList = members.results
        }
      }
    },
    selectMember(selectedMembers) {
      this.members.selectedMembers = selectedMembers;
    },
    addMembers(){
      if(this.members.selectedMembers.length != 0){
        this.$emit('addMembers', this.members.selectedMembers)
      }
      this.closeModal()
    }
  },
  watch: {
    show: async function (val) {
      if(val){
        this.showLoad()
        await this.getMembers();
        if(this.selected){
          this.members.selectedMembers = this.selected;
        }
        this.hideLoad()
      }else{
         this.clearSelected = !val;
      }
     
    },
  },
};
</script>
<style lang="scss" scoped>
.members-search{
  padding: 16px 24px 24px 24px;
}

@import "myCustomSelectStyles";
</style>