<template>
  <div class="reports-page" v-if="$route.path.indexOf('open/reports') >= 0">
    <vessels-reports-add-or-edit @renderList="renderList" :openDetailModal="openDetailModal" @changeReport="changeReport" ref="modal"></vessels-reports-add-or-edit>
    <vessels-reports-detail-modal ref="modalDetail" @close="openDetailModal=false" @open="openDetailModal=true" @change="emitEdit" @archive="emitArchive"></vessels-reports-detail-modal>
    <vessel-report-modal-delete ref="modalDelete" @clickYes="deleteThisReport"></vessel-report-modal-delete>
    <div class="reports-list">
      <p class="fz12 fw500 text-gray">REPORTS LIST</p>
      <button class="button-report fz14 fw500 text-dark" v-on:click="setActiveButton(button.name)" :class="{'button-report-active': buttonActive === button.name}" v-for="(button, i) in buttonReportList" :key="i">{{button.name}}</button>
    </div>
    <div class="main-block">
<!--       {{getListNoReports}}-->
      <payment-panel-select-period @changeDate="changeDate" :normalDate="true" :default-status-button="1" @createPayment="openModalCreate" :createPaymentEmit="true" :cash-flow="true" :text-title="buttonActive"></payment-panel-select-period>
      <div class="titles" v-if="getListNoReports.length !== 0">
        <svg v-on:click="selectAll" v-if="selectedCheckboxes.length !== 0" class="active-checkbox-style" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="18" height="18" rx="4" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6687 8.99989C4.6687 8.47177 5.09683 8.04364 5.62495 8.04364H12.375C12.9031 8.04364 13.3312 8.47177 13.3312 8.99989C13.3312 9.52801 12.9031 9.95614 12.375 9.95614H5.62495C5.09683 9.95614 4.6687 9.52801 4.6687 8.99989Z" fill="white"/>
        </svg>

        <svg v-on:click="selectAll" v-if="selectedCheckboxes.length === 0" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
        </svg>

        <p class="fz13 text-gray">Date</p>
        <p class="fz13 text-gray">Time</p>
        <p class="fz13 text-gray">Ref Number</p>
        <p class="fz13 text-gray">BROB VLSFO</p>
        <p class="fz13 text-gray">BROB MDO</p>
        <p class="fz13 text-gray">BROB LSMGO</p>
        <p class="fz13 text-gray">Consumed <br> VLSFO (24 hrs)</p>
        <p class="fz13 text-gray">Consumed <br> MDO (24 hrs)</p>
        <p class="fz13 text-gray">Consumed <br> LSMGO (24 hrs)</p>
      </div>
      <div class="list-block" v-if="getListNoReports.length !== 0">
        <div class="row" v-for="(item, i) in getListNoReports" :key="i">
          <div class="link-block" v-on:click="openDetail(item)"></div>
          <svg class="active-checkbox-style" v-on:click="select(item.id)" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="selectedCheckboxes.indexOf(item.id) !== -1">
            <rect width="18" height="18" rx="4" fill="#094172"/>
            <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
          </svg>
          <svg v-on:click="select(item.id)" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="selectedCheckboxes.indexOf(item.id) === -1">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
          </svg>

          <p class="fz14 text-dark">{{item.date | moment("DD.MM.YYYY")}}</p>
          <p class="fz14 text-dark">{{item.time}}</p>
          <p class="fz14 text-dark">{{item.reference_number}}</p>
          <p class="fz14 text-dark">{{item.brob_vlsfo}}</p>
          <p class="fz14 text-dark">{{item.brob_mdo}}</p>
          <p class="fz14 text-dark">{{item.brob_lsmgo}}</p>
          <p class="fz14 text-dark">{{item.consumed_vlsfo_24_hrs}}</p>
          <p class="fz14 text-dark">{{item.consumed_mdo_24_hrs}}</p>
          <p class="fz14 text-dark">{{item.consumed_vlsfo_24_hrs}}</p>
          <button class="option" v-if="$route.path.indexOf('archive') >= 0" v-on:click="emitArchive(item.id)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
              <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"/>
            </svg>
          </button>
          <vessesls-reports-option-button v-if="$route.path.indexOf('archive') === -1" class="option" @emitEdit="emitEdit(item)" @emitArchive="emitArchive(item.id)"></vessesls-reports-option-button>
        </div>
      </div>
      <div class="block-for-selected" v-if="selectedCheckboxes.length !== 0">
        <p class="fz13 text-gray fw500">Selected: <span class="fz13 text-blue-dark fw500"> {{selectedCheckboxes.length}}/{{getListNoReports.length}}</span></p>
        <button class="blue-button h32 buttonW130" v-on:click="deleteSelected">
          <svg v-if="$route.path.indexOf('archive') === -1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
          </svg>
          <svg v-if="$route.path.indexOf('archive') !== -1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
            <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"/>
          </svg>

          <span v-if="$route.path.indexOf('archive') === -1">Archive</span>
          <span v-if="$route.path.indexOf('archive') !== -1">Unarchive</span>
        </button>
        <button class="cancelSelect" v-on:click="selectAll">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
            <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
          </svg>
        </button>
      </div>
      <pagination-panel-default class="loadMore" :ifActive="showLoadMoreLoader" @load-more="loadMore" v-if="getListNoReports.length !== 0 && getLinkNextNonReport"></pagination-panel-default>
    </div>

  </div>
</template>

<script>

import PaymentPanelSelectPeriod from "@/components/payment/payment-panel-select-period";
import VesseslsReportsOptionButton from "@/components/vessels/vessels-reports/vessesls-reports-option-button";
import VesselsReportsAddOrEdit from "@/components/vessels/vessels-reports-add-or-edit/vessels-reports-add-or-edit";
import {mapActions, mapGetters, mapMutations} from "vuex";
import VesselsReportsDetailModal from "@/components/vessels/vessels-reports-detail-modal/vessels-reports-detail-modal";
import VesselReportModalDelete from "@/components/vessels/vessel-report-modal-delete/vessel-report-modal-delete";
import moment from 'moment'
import PaginationPanelDefault from "@/components/ports/paginationDefault";

export default {
  name: "vessels-reports",
  computed: mapGetters(["getListNoReports", "getLinkNextNonReport"]),
  components: {PaginationPanelDefault, VesselReportModalDelete, VesselsReportsDetailModal, VesselsReportsAddOrEdit, VesseslsReportsOptionButton, PaymentPanelSelectPeriod},
  data() {
    return {
      buttonActive: "Noon Reports",
      buttonReportList: [
        {name: "Noon Reports"},
        {name: "Port Report"},
        {name: "Arrival Report"},
        {name: "Anchorage Report"},
        {name: "Port Working Report"},
        {name: "Reports Name"},
      ],
      selectedCheckboxes: [],
      idFromDelete: null,
      dateAxios: null,
      openDetailModal: false,
      showLoadMoreLoader: false,
    }
  },
  watch: {
    $route: function () {
      this.renderList();
      if(this.$route.path.indexOf('archive')>=0) {
        this.buttonActive = "Archive Noon Reports"
      } else {
        this.buttonActive = "Noon Reports"
      }
      this.selectedCheckboxes = [];
    }
  },
  methods: {
    ...mapActions(["axiosLoadMoreNonReports", "axiosGetNoReports", "axiosArchiveNonReport", "axiosUnarchiveNonReport"]),
    ...mapMutations(["addTip"]),
    // changeReport(item) {
    //
    // },
    loadMore() {
      this.showLoadMoreLoader = true;
      this.axiosLoadMoreNonReports(this.getLinkNextNonReport).then(()=> {
        this.showLoadMoreLoader = false
      })
    },
    changeReport(item) {
      this.openDetail(item)
    },
    deleteSelected() {
      for(let i=0; i<this.selectedCheckboxes.length; i++) {
        if(this.$route.path.indexOf('archive') === -1) {
          this.axiosArchiveNonReport(this.selectedCheckboxes[i])
        } else {
          this.axiosUnarchiveNonReport(this.selectedCheckboxes[i])
        }
      }
      if(this.$route.path.indexOf('archive') === -1) {
        this.addTip(this.selectedCheckboxes.length + ' reports is archived')
      } else {
        this.addTip(this.selectedCheckboxes.length + ' reports is unarchived')
      }
      this.selectedCheckboxes = [];
    },
    emitArchive(id) {
      this.idFromDelete = id;
      this.$refs.modalDelete.openModal();
    },
    deleteThisReport() {
      if(this.$route.path.indexOf('archive') === -1) {
        this.axiosArchiveNonReport(this.idFromDelete).then(()=> {
          this.selectedCheckboxes = this.selectedCheckboxes.filter(a=> a !== this.idFromDelete);
          this.idFromDelete = null;
          this.addTip('Noon Report is archived')
        })
      } else {
        this.axiosUnarchiveNonReport(this.idFromDelete).then(()=> {
          this.selectedCheckboxes = this.selectedCheckboxes.filter(a=> a !== this.idFromDelete);
          this.idFromDelete = null;
          this.addTip('Noon Report is unarchived')
        })
      }

    },
    selectAll() {
      if(this.selectedCheckboxes.length !== 0) {
        this.selectedCheckboxes = [];
      } else {
        this.selectedCheckboxes = [];
        this.selectedCheckboxes = this.getListNoReports.map(a=> {return a.id})
      }
    },
    select(id) {
      if(this.selectedCheckboxes.indexOf(id) >= 0) {
        this.selectedCheckboxes = this.selectedCheckboxes.filter(a=> a !== id);
      } else {
        this.selectedCheckboxes.push(id);
      }
    },
    setActiveButton(name) {
      this.buttonActive = name;
    },
    openModalCreate() {
      this.$refs.modal.openModal();
    },
    renderList() {
      let is_archive = false;
      if(this.$route.path.indexOf('archive')>=0) {
        is_archive = true;
      }
      console.log(this.dateAxios)
      let date_after = null;
      let date_before = null;
      if(this.dateAxios !== null) {
        date_after = (this.dateAxios[0] !== null && this.dateAxios[0] !== undefined && (""+this.dateAxios[0]).length !== 0) ? moment(this.dateAxios[0]).format("YYYY-MM-DD 00:00") : "";
        date_before = (this.dateAxios[1] !== null && this.dateAxios[1] !== undefined && (""+this.dateAxios[1]).length !== 0) ? moment(this.dateAxios[1]).format("YYYY-MM-DD 23:59") : ""
      }
      this.axiosGetNoReports({is_archive: is_archive, date_after: date_after, date_before: date_before});
    },
    emitEdit(data) {
      this.$refs.modal.openModal(data);
    },
    openDetail(item) {
      this.$refs.modalDetail.openModal(item)
    },
    changeDate(date) {
      this.dateAxios = date;
      this.renderList();
    }
  },
  mounted() {
    // this.renderList();
    // if(this.$route.path.indexOf('archive')>=0) {
    //   this.buttonActive = "Archive Noon Reports"
    // } else {
    //   this.buttonActive = "Noon Reports"
    // }
    // if(this.)
  }
}
</script>

<style scoped lang="scss">
  @import "vessels-reports";
</style>