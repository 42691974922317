import axios from 'axios'

export default {
    actions: {
        getAxiosInfoForUser(ctx, id) {
            return axios.get(process.env.VUE_APP_URL+"/user/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(response => {
                ctx.commit("setInfoForUser", response.data)
            })
        }
    },
    mutations: {
        setInfoForUser(state, data) {
            state.infoForUser = data;
        }
    },
    state: {
        infoForUser: {}
    },
    getters: {
        getInfoForUser(state) {
            return state.infoForUser;
        }
    },
}
