<template>
  <div class="async_select__wrap">
    <label :for="id" class="block text-gray m-b-1 fz13" v-if="label">{{label}}</label>


    <multiselect
      :id="id"
      :class="{
        'error': isErrorRequired
      }"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      @open="onOpen()"
      :options="searchableResult"
      track-by="fake_id"
      group-values="list" 
      group-label="type"
      :group-select="groupSelect"
      :multiple="isMultiple"
      :disabled="disabled"
      :searchable="true"
      :taggable="false"
      :showLabels="false"
      :allowEmpty="allowEmpty"
      :clearOnSelect="false"
      :internalSearch="false"
      :preserveSearch="true"
      :loading="isLoading"
      :closeOnSelect="closeOnSelect"
      :maxHeight="200"
      :openDirection="openDirection"
      :ref="'multiselect' + id"
      @close="close"
      @search-change="onSearch"
    >
      <template slot="singleLabel" slot-scope="props">
        <span class="text-dark fz14">{{getItemAccountName(props.option)}}</span>
      </template>

      <template slot="option" slot-scope="props">
        <span 
          class="flex items-center" 
          :class="{
            'fw500 text-gray-text bg-transparent': props.option.$isLabel || props.option.type == 'bank',
          }" 
        >
          <template v-if="props.option.$isLabel"> {{props.option.$groupLabel}}</template>
          <template v-else>{{props.option.$isLabel || props.option.type == 'bank' ? getItemName(props.option) : getItemAccountName(props.option)}}</template>
          <svg class="option_check m-l-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
        </span>
      </template>

      <template slot="selection" slot-scope="{ values }">
        <span class="multiselect__single p-0 inline-flex flex-wrap items-center fz14 text-dark" v-if="values.length >= 1">
          {{getItemName(values[0])}}
          <span class="m-l-1" v-if="values.length > 1">/
            <span class="inline-flex items-center justify-center h-5 min-w-5 rounded-full border border-dark fz11">+{{ values.length-1 }}</span>
          </span>
        </span>
      </template>
    </multiselect>

    <div class="text-red fz12" v-if="isErrorRequired">Field is required</div>
  </div>
</template>

<script>
import axios from 'axios'
const linkedCancelToken = axios.CancelToken;
let linkedCancel;

import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import QueryString from "querystring";
export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      require: true
    },
    disabled: Boolean,
    openDirection: {
      type: String,
      default: 'bottom'
    },
    label: String,
    isErrorRequired: Boolean,
    allowEmpty: Boolean,
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    groupSelect: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Type to search'
    },
    selectedBanks: [Array, Object],
    selectedRequisites: [Array, Object]
  },
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      results: [],
      searchableResult: [],
      value: null,
      search: '',
    }
  },
  watch: {
    selectedBanks: function () {
      this.startWorkComponent()
    },
    selectedRequisites: function () {
      this.startWorkComponent()
    }
  },
  computed: {
    ...mapGetters(['getToken', 'getPermissionsByType']),
    ...mapState({
      trials: state => state.store.trialsForBankAndCash,
      offAxiosTrial: state => state.store.offAxiosTrial,
    }),
    getTrialsInDrop() {
      return this.trials.map(el => {
        return {id: el.id, name: (this.getPermissionsByType('show_dop_info_for_acc_in_payments') ?  el.account_id+': '+el.name : el.name)}
      })
    },
  },
  methods: {
    ...mapActions(['axiosGetTrialsForBankAndCashAccounts']),
    ...mapMutations(['setOffAxiosTrial']),
    getItemAccountName(item) {
      if(item.account && Number.isInteger(item.account) === false) {
        let name = this.getTrialsInDrop.filter(a=> a.id === item.account.id)
        if(name.length) {
          return name[0].name;
        }
      }
      return item.name ? item.name : item.holder
    },
    getItemName(item) {
      return (item.account && Number.isInteger(item.account) === false) ? item.account.name : item.name ? item.name : item.holder
    },
    asyncFind () {
      if(linkedCancel){
        linkedCancel()
        linkedCancel = null
        this.isLoading = false
      }
      this.isLoading = true

      axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/payment/cash/balance',
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
        cancelToken: new linkedCancelToken(function executor(c) {
          linkedCancel = c;
        }),
        params: {
          is_archive_cash: 'false',
          is_archive_requisite: 'false',
        },
        paramsSerializer: function (params) {
          return QueryString.stringify(params)
        },
      }).then(response => {
        this.results = response.data
        this.createList()
        this.isLoading = false
      }).catch(error => {
        console.error('searchError: ', error);
      })
    },
    onSearch(query) {
      this.search = query ? query : ''
      this.createList()
    },
    createList() {
      this.searchableResult = []
      if(!this.results || !this.results.length) return false
      let banks = this.results.filter(el => {
        return el.type == 'bank' && el.requisites.length
      })
      let cash = this.results.filter(el => el.type == 'cash' && el.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
      
      let bankRequisites = []
      banks.forEach(el => {
        el.fake_id = el.id+'cash'
        if(!this.isMultiple) el.$isDisabled = true
        let requisites = el.requisites.filter(item => {
          return item.holder.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        }).map(item => {
          item.fake_id = item.id+'requisite'
          return item
        })
        if(requisites.length)
          bankRequisites = [...bankRequisites, el, ...requisites]
      });

      if(bankRequisites.length){
        this.searchableResult.push({
          type: 'Bank Accounts',
          list: bankRequisites
        })
      }

      if(cash.length){
        this.searchableResult.push({
          type: 'Cash Accounts',
          list: cash.map(el => {
            el.fake_id = el.id+'cash'
            return el
          })
        })
      }
    },
    onOpen() {
      this.$emit('onOpen')
      if(this.results.length == 0)
        this.asyncFind()
    },
    onInput(value) {
      this.value = value

      if(this.isMultiple){
        this.selectedBanks.length = 0
        this.selectedRequisites.length = 0
      }
      
      if(!value){
        this.$emit('onSetBank', null)
        this.$emit('onSetRequisite', null)
        return false
      }

      if(Array.isArray(value)){
        value.forEach(el => {
          if(el.type) this.selectedBanks.push(el)
          else this.selectedRequisites.push(el)
        })
      }else{
        this.$emit('beforeSelect')
        if(value.type ) this.$emit('onSetBank', value)
        else this.$emit('onSetRequisite', value)
      }
      this.$emit('change')
    },
    close() {
      this.$emit("close")
    },
    onClear() {
      if(this.isMultiple){
        this.value.length = 0
        this.selectedBanks.length = 0
        this.selectedRequisites.length = 0
      }else{
        this.value = null
        this.selectedBanks = null
        this.selectedRequisites = null
      }
    },
    openDropdown(focusOn) {
      console.log(this.disabled)
      if(!this.disabled) {
        this.$refs['multiselect'+this.id].isOpen = true;
        if(focusOn === true) {
          this.$refs['multiselect'+this.id].$refs.search.focus()
        }
        this.onOpen();
      }
    },
    startWorkComponent() {
      if(this.isMultiple){
        this.value = []

        let resultBanks = this.selectedBanks.map(el => {
          el.fake_id = el.id+'cash'
          return el
        })
        let resultReqisites = this.selectedRequisites.map(el => {
          el.fake_id = el.id+'requisite'
          return el
        })

        this.value = [...resultBanks, ...resultReqisites]
      }else{
        this.value = this.selectedBanks ? this.selectedBanks : this.selectedRequisites
        if(this.value)
          this.value.fake_id = this.selectedBanks ? this.value?.id+'cash' : this.value?.id+'requisite'
      }
    }
  },
  beforeMount() {
    if(!this.trials.filter(a=> a.is_archive === false).length && !this.offAxiosTrial) {
      this.setOffAxiosTrial(true)
      this.axiosGetTrialsForBankAndCashAccounts().then(()=> {
        this.setOffAxiosTrial(false)
      })
      }
    this.startWorkComponent()
  }

}
</script>

<style lang="scss">
</style>
