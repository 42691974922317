<template>
  <div class="option-btn_wrap">
    <button class="option-btn" v-if="getPermissionsByType('edit_accounts') || getPermissionsByType('create_new_accounts') || getPermissionsByType('archive_accounts')">
      <svg class="option-btn_icon" @click.stop="openMenu" width="24" height="24" viewBox="0 0 24 24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path d="M6.44 10.56C7.23529 10.56 7.88 11.2047 7.88 12C7.88 12.7953 7.23529 13.44 6.44 13.44C5.64471 13.44 5 12.7953 5 12C5 11.2047 5.64471 10.56 6.44 10.56Z"/><circle cx="12.0001" cy="11.9999" r="1.44" transform="rotate(-90 12.0001 11.9999)"/><path d="M17.5601 10.5602C18.3554 10.5602 19.0001 11.2049 19.0001 12.0002C19.0001 12.7955 18.3554 13.4402 17.5601 13.4402C16.7648 13.4402 16.1201 12.7955 16.1201 12.0002C16.1201 11.2049 16.7648 10.5602 17.5601 10.5602Z"/></svg>

    </button>

    <div class="option-btn_list" tabindex="0" @blur="closeMenu" v-show="isOpen" ref="option_list">
      <div class="option-btn_item" @click.stop="editItem" v-if="getPermissionsByType('edit_accounts')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#2C2C2C"/></svg>
        Edit
      </div>
      <div class="option-btn_item" @click.stop="duplicateItem" v-if="getPermissionsByType('create_new_accounts')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 10.1719C5 9.13178 5.85439 8.28861 6.90834 8.28861H13.9456C14.9996 8.28861 15.854 9.13178 15.854 10.1719V17.1167C15.854 18.1568 14.9996 19 13.9456 19H6.90834C5.85439 19 5 18.1568 5 17.1167V10.1719ZM6.90834 9.60405C6.59056 9.60405 6.33294 9.85828 6.33294 10.1719V17.1167C6.33294 17.4303 6.59056 17.6846 6.90834 17.6846H13.9456C14.2634 17.6846 14.521 17.4303 14.521 17.1167V10.1719C14.521 9.85828 14.2634 9.60405 13.9456 9.60405H6.90834Z" fill="#4B4B4B"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.14605 6.88328C8.14605 5.84317 9.00044 5 10.0544 5H17.0917C18.1456 5 19 5.84317 19 6.88328V13.8281C19 14.8682 18.1456 15.7114 17.0917 15.7114C16.7236 15.7114 16.4252 15.4169 16.4252 15.0537C16.4252 14.6904 16.7236 14.396 17.0917 14.396C17.4094 14.396 17.6671 14.1417 17.6671 13.8281V6.88328C17.6671 6.56967 17.4094 6.31543 17.0917 6.31543H10.0544C9.73661 6.31543 9.47899 6.56967 9.47899 6.88328C9.47899 7.24652 9.1806 7.54099 8.81252 7.54099C8.44444 7.54099 8.14605 7.24652 8.14605 6.88328Z" fill="#2C2C2C"/></svg>
        Duplicate
      </div>
      <div class="option-btn_item" @click.stop="archiveItem" v-if="getPermissionsByType('archive_accounts')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/></svg>
        Archive
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    openMenu() {
      this.isOpen = true
      setTimeout(()=>{ // because when I call .focus() after change isOpen, it`s don`t work
        let optionList = this.$refs.option_list
        optionList.focus()
      }, 100)
    },
    closeMenu() {
      this.isOpen = false
    },
    editItem() {
      this.$emit('editItem')
    },
    duplicateItem() {
      this.$emit('duplicateItem')
    },
    archiveItem() {
      this.$emit('archiveItem')
    }
  },
}
</script>

<style lang="scss">
</style>