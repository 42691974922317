<template>
  <div class="block-input">
    <div class="m-b-3 fz12 text-gray-text">Bunkering</div>
    <div class="flex">
      <b-form-checkbox
        class="w-1/4"
        v-model="port.bunkerning_not_available"
        @change="changeBunkering($event, true)"
      >
        <div class="fz14 text-dark-gray">Not Available</div>
      </b-form-checkbox>

      <b-form-checkbox
        class="w-1/4"
        v-model="port.bunkerning_at_berth"
        @change="changeBunkering"
      >
        <div class="fz14 text-dark-gray">At Berth</div>
      </b-form-checkbox>

      <b-form-checkbox
        class="w-1/4"
        v-model="port.bunkerning_at_road"
        @change="changeBunkering"
      >
        <div class="fz14 text-dark-gray">At Road</div>
      </b-form-checkbox>

      <b-form-checkbox
        class="w-1/4"
        v-model="port.bunkerning_in_transit"
        @change="changeBunkering"
      >
        <div class="fz14 text-dark-gray">In Transit</div>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    port: Object
  },
  methods: {
    async changeBunkering(e, bool) {
      await this.$nextTick()
      if(bool) {
        this.port.bunkerning_at_berth = false
        this.port.bunkerning_at_road = false
        this.port.bunkerning_in_transit = false
      }else{
        this.port.bunkerning_not_available = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/checkbox";
</style>
<style>

</style>