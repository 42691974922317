<template>
  <div v-tooltip.top="localValue !== '0.00' && localValue !== 0 ? localValue : ''" v-on:mousedown="mouseDown" v-on:mouseup="mouseUp" class="position-relative">
    <input
        v-on:keydown.tab="(e)=>{e.preventDefault();clickTabPayment(e.target.parentNode)}"
        type="text"
        @keyup="keyup"
        @keydown="keydown"
        class="input-payment fz13 click-element-border currency"
        :class="{'text-green-dark': payment_type === 'income' && parseFloat(localValue) && status === 'paid',
        'border-select-top': showTopBorder(),'border-select-bottom': showBottomBorder(), 'border-select-right': showRightBorder(), 'border-select-left': showLeftBorder()}"
        v-model.lazy="localValue"
        @change="checkType"
        @focusout="focusout"
        :disabled="true"
    >
    <div class="position-absolute t0 l0 w100Proc h100Proc box-border pre-input-click-border">

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "element-input-currency-amount",
  props: {
    value: [Object, Array, String, Number],
    payment_type: String,
    status: String,
    item: Object
  },
  watch: {
    'item.payment_amount': function () {
      this.setPausedChange(true)
      this.changeCurrencyAmount()
      // console.log('item.payment_amount')
    },
    'item.currency': function () {
      this.setPausedChange(true)
      this.changeCurrencyAmount()
      // console.log('item.currency')
    }
  },
  computed: {
    ...mapState({
      typesSelectionCurrency: state => state.paymentListXY.typesSelectionCurrency,
    })
  },
  data() {
    return {
      localValue: '0.00',
      ctrlKey: false,
      pasteEvent: false,
    }
  },
  methods: {
    ...mapActions(['axiosGetCurrency', 'clickTabPayment']),
    ...mapMutations(['setPausedChange']),
    mouseDown() {
      this.$emit('mousedown', 'currency')
    },
    mouseUp() {
      this.$emit('mouseup', 'currency')
    },
    showTopBorder() {
      let array = JSON.parse(JSON.stringify(this.typesSelectionCurrency))
      let IDs = this.getIds(array)
      if(IDs.indexOf(this.item.id) >= 0) {
        let item = array.find(a=> a.id === this.item.id)
        let itemIndex = array.findIndex(a=> a.id === this.item.id)
        console.log(item, itemIndex)
        if(itemIndex === 0) {
          return true
        }
        if(item.index-1 !== array[itemIndex-1].index) {
          return true
        }
      }
      return false
    },
    showBottomBorder() {
      let array = this.typesSelectionCurrency
      let IDs = this.getIds(array)
      if(IDs.indexOf(this.item.id) >= 0) {
        let item = array.find(a=> a.id === this.item.id)
        let itemIndex = array.findIndex(a=> a.id === this.item.id)
        if(itemIndex === array.length-1) {
          return true
        }
        if(item.index+1 !== array[itemIndex+1].index) {
          return true
        }
      }
      return false
    },
    showRightBorder() {
      let array = this.typesSelectionCurrency
      let IDs = this.getIds(array)
      if(IDs.indexOf(this.item.id) >= 0) {
       return true
      }
      return false
    },
    showLeftBorder() {
      let array = this.typesSelectionCurrency
      let IDs = this.getIds(array)
      if(IDs.indexOf(this.item.id) >= 0) {
        return true
      }
      return false
    },
    getIds(array) {
      return array.map(a=> {return a.id})
    },
    async changeCurrencyAmount() {
      if(this.item.currency !== 'USD') {
        let currences = []
        if(this.item.paid_date !== null) {
          currences = await this.axiosGetCurrency({currency: this.item.currency, created_at: moment(this.item.paid_date).format('YYYY-MM-DD')});
        }
        let rate = 0;
        if(this.item.paid_date !== null && currences?.data?.results?.length !== 0) {
          rate = Math.round(currences.data.results[0].rate*10000)/10000
        } else {
          currences = await this.axiosGetCurrency({currency: this.item.currency});
          rate = Math.round(currences.data.results[0].rate*10000)/10000
        }
        this.localValue = this.numberFloatCheck(this.checkNum((Math.round(((this.item.payment_amount / rate)*100)))/100));
        this.localValue = this.localValue !== 'NaN' && this.localValue !== 'NaN.00' ? this.localValue : '0.00'
        if(this.localValue !== this.item.currency_amount) {
          this.setPausedChange(false)
        }
        this.checkType()
        this.setPausedChange(false)

      } else {
        let rate = 1;
        this.localValue = this.numberFloatCheck(this.checkNum((Math.round(((this.item.payment_amount / rate)*100)))/100));
        this.localValue = this.localValue !== 'NaN' && this.localValue !== 'NaN.00' ? this.localValue : '0.00'
        console.log('currency_amount', this.localValue, this.item.currency_amount)
        if(this.localValue !== this.item.currency_amount) {
          this.setPausedChange(false)
        }
        this.checkType()
        this.setPausedChange(false)
      }
    },
    numberFloatCheck(value) {
      let fixedNumber = value;
      if(!isNaN(value)) {
        fixedNumber = parseFloat(fixedNumber)
        fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',','')
        if(fixedNumber !== 'NaN' && (''+fixedNumber).indexOf('.') === -1) {
          fixedNumber+='.00'
        }
      }
      return fixedNumber
    },
    checkNum(num) {
      num = ''+num
      if((num).indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");

        if(num2.length > 4) {
          num2 = num2.substring(0, 4);
        }
        if(num1.length && num2.length) {
          num = num1 + "." + num2;
        } else {
          num = num1 + num2;
        }
      } else {
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    onPaste (evt) {
      evt.preventDefault()
      let textInput1 = this.localValue.substr(0, this.$refs.input.selectionStart)
      let textInput2 = this.localValue.substr(this.$refs.input.selectionEnd,this.localValue.length)
      let text = this.checkNum(evt.clipboardData.getData('text'));
      text = textInput1 + text + textInput2
      this.localValue = text && (''+text).length ? text : '0.00'
      this.localValue = this.localValue !== 'NaN' && this.localValue !== 'NaN.00' ? this.localValue : '0.00'
      this.pasteEvent = true
    },
    focusout() {
      this.localValue = parseFloat(''+this.localValue.replaceAll(',', '')).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      this.localValue = this.localValue !== 'NaN' && this.localValue !== 'NaN.00' ? this.localValue : '0.00'
      if(this.pasteEvent) {
        this.checkType();
        this.pasteEvent = false
      }
    },
    keyup(e) {
        if(this.ctrlKey) {
          if(e.key === 'Meta' || e.key === 'Control') {
            this.ctrlKey = false;
          }
        }
    },
    keydown(e) {
      let bool = false
        if(e.key === 'Meta' || e.key === 'Control') {
          this.ctrlKey = true;
        }
        if(e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) {
          if(this.ctrlKey) {
            bool=true
          }
        }

      if(
          (e.keyCode < 48 || e.keyCode > 57) && // numbers
          (e.keyCode < 96 || e.keyCode > 105) && // numpad
          (e.keyCode < 37 || e.keyCode > 40) && // arrows
          e.key != '.' && e.keyCode != 13 && // 13 - enter
          e.keyCode != 8 && e.keyCode != 46 && // backspace / delete
          e.keyCode != 110 // decimal point
      ) {
        if(!bool)
          e.preventDefault()
      }
    },
    checkType() {
      this.localValue = this.localValue ? this.localValue : '0.00'
      this.localValue = this.localValue !== 'NaN' && this.localValue !== 'NaN.00' ? this.localValue : '0.00'
      let amount = parseFloat(''+this.localValue.replaceAll(',', ''))
      this.$emit('input', amount)
    },
  },
  mounted() {
    console.log('this.value: ', this.value)
    this.localValue = this.value ? parseFloat(''+this.value.replaceAll(',', '')).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'
    this.localValue = this.localValue !== 'NaN' && this.localValue !== 'NaN.00' ? this.localValue : '0.00'
  }
}
</script>

<style scoped lang="scss">
.input-payment {
  border-radius: 0;
  height: 30px;
  border: 2px solid transparent;
  padding: 4px;
  text-align: right;
}

</style>