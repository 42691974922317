<template>
  <div class="upload-input-wrapper">
    <input type="file" accept=".eml" class="upload-eml-input" ref="uploadEml" @change="handleFileChange">
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
  export default {
    name: "upload-eml",
    data: () => ({
      selectedFile: null,
      folderId: null
    }),
    methods: {
      ...mapActions(['axiosMailboxUploadEml']),
      ...mapMutations(["showLoad", "hideLoad"]),
      showUploadForm(folderId){
        this.folderId = folderId
        this.$refs.uploadEml.click()
      },
      handleFileChange(event){
        this.selectedFile = event.target.files[0];
        this.uploadFile()
      },
      uploadFile(){
        if (this.selectedFile){
          this.showLoad()
          let formData = new FormData();
          formData.append('file', this.selectedFile);
          formData.append('folder_id', this.folderId);
          this.selectedFile = null
          this.folderId = null
          this.$refs.uploadEml.value = null;
          this.axiosMailboxUploadEml(formData)
          .then(() => {
            // console.log('response::: ', response);
            this.$emit('fileLoaded')
            this.hideLoad()
          })
          .catch( err => {
            console.log('err::: ', err);
            this.$toast.open({
              message: 'Oops! Something went wrong :(',
              type: 'error',
              duration: 3000
            });
            this.hideLoad()
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.upload-input-wrapper{
  display: none;
}
</style>