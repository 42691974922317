import axios from 'axios'
// import permissions from "@/store/modules/permissions";

export default {
    actions: {
        async axiosGetEmployeeById(ctx, id) {
          return await axios.get(process.env.VUE_APP_URL+'/user/'+id, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken
              },
          }).then(res=> {
              return res.data
          }).catch(()=> {
              return false
          })
        },
        axiosGetEmployees(ctx) {
            // // console.log('axios emp');
            // // console.log(ctx.getters.getToken);
            // // console.log('axios emp1');
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenEmp", ct);
            ctx.commit("cancelTokenSourceEmployees", ct);
            return axios.get(process.env.VUE_APP_URL+"/user/?is_active=true", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
                cancelToken: ct.token,
            }).then(response => {
                ctx.commit("setEmployeesDataAll", response.data.results)
                ctx.commit("updateEmployees", response);
            }).catch(error => {
                if(ctx.getters.getToken>20)
                    ctx.commit("setAlert", {show: true, text: error}, {root: true});
                ctx.commit("employeesSortByFirstName");
            });
        },
        axiosGetEmployeesArchive(ctx) {
            // // console.log('axios emp');
            // // console.log(ctx.getters.getToken);
            // // console.log('axios emp1');
            return axios.get(process.env.VUE_APP_URL+"/user/?is_active=false", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(response => {
                ctx.commit("updateEmployees", response);
            }).catch(error => {
                if(ctx.getters.getToken>20)
                    ctx.commit("setAlert", {show: true, text: error}, {root: true});
                ctx.commit("employeesSortByFirstName");
            });
        },
        // axiosPermissionRelated(ctx) {
        //     return axios.get(process.env.VUE_APP_URL+"/permission/related/").then(response => {
        //         ctx.commit("setPermissionRelated", response.data.results);
        //     }).catch(error => {
        //         ctx.commit("setAlert", {show: true, text: error}, {root: true});
        //     });
        // },
        getAxiosDepartmentsEmployeesPage(ctx) {
            if(!ctx.state.requestSend) {
                ctx.state.requestSend = true;
                return axios.get(process.env.VUE_APP_URL+"/department/", {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken
                    }
                }).then(response => {
                    ctx.commit("setDepartmentsEmployees", response.data.results);
                    ctx.state.requestSend = false;
                }).catch(error => {
                    if(ctx.getters.getToken>20)
                        ctx.commit("setAlert", {show: true, text: error}, {root: true});
                    ctx.state.requestSend = false;
                });
            }
           return  null
        },
        axiosGetInfoUserForStepOne(ctx, data) {
            return axios.get(process.env.VUE_APP_URL+"/user", {
                params: {
                    first_name: data.first_name,
                    last_name: data.last_name
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(req=> {
                // console.log(req);
                if(req.data.results.length === 0) {
                    return true;
                }
                    return false;
            }).catch(()=> {
                return false;
            })
        }
    },
    mutations: {
        setEmployeesDataAll(state, data) {
            if(data) {
                data.map(a=> {
                    state.employeesDataAll.push(a);
                })
            }
        },
        cancelTokenEmp(state) {
            if(state.cancelTokenSourceEmployees) {
                state.cancelTokenSourceEmployees.cancel();
            }
        },
        cancelTokenSourceEmployees(state, data) {
            state.cancelTokenSourceEmployees = data;
        },
        updateEmployees(state, data) {
            for(let i=0; i< data.data.results.length; i++) {
                data.data.results[i].departmentName = "No department";
                data.data.results[i].activity = "Offline";
            }
            state.employees = data;
        },
        // setPermissionRelated(state, data) {
        //     state.permissionRelated = data;
        // },
        setDepartmentsEmployees(state, data) {
            state.departmentsForEmployees = data;
            state.buttonDepartment.splice(0, state.buttonDepartment.length);
            state.buttonDepartment.push({name: "All", status: true});
            for(let i=0; i<state.departmentsForEmployees.length; i++) {
                state.buttonDepartment.push(state.departmentsForEmployees[i]);
            }
            for(let i=1; i<state.buttonDepartment.length; i++) {
                state.buttonDepartment[i].status = false;
            }
        },
        modifyEmployeeAddDepartment(state) {
            console.log('modifyEmployeeAddDepartment', state.employees)
            if(state.departmentsForEmployees !== null) {
                for (let i = 0; i < state.employees.data.results.length; i++) {
                    for (let t = 0; t < state.departmentsForEmployees.length; t++) {
                        if (state.employees.data.results[i].department === state.departmentsForEmployees[t].id) {
                            state.employees.data.results[i].departmentName = state.departmentsForEmployees[t].name;
                        }
                    }
                }

                state.employeesSortByDepartments.splice(0,state.employeesSortByDepartments.length);
                let form = state.departmentsForEmployees.map(a=> {return {title: a.name, array: []}})
                form.push({title: "No department", array: []});
                form.map(a=> {
                    a.array = state.employees.data.results.filter(b=> b.departmentName.toLowerCase() === a.title.toLowerCase());
                })

                for(let i=0; i<form.length; i++) {
                    form[i].array.sort(function(a, b){
                        var nameA=a.first_name.toLowerCase(), nameB=b.first_name.toLowerCase();
                        if (nameA < nameB) //sort string ascending
                            return -1;
                        if (nameA > nameB)
                            return 1;
                        return 0; //default return value (no sorting)
                    });
                }
                state.employeesSortByDepartments = form;
                state.employeesCopy = JSON.parse(JSON.stringify(state.employees));
                state.employeesSortByDepartmentsCopy = JSON.parse(JSON.stringify(state.employeesSortByDepartments));
            }

            return null;
        },
        modifyEmployeeAddDepartmentForTasksAssigned(state, data) {
            // // console.log("modifyEmployeeAddDepartment");
            let departmentsForEmployees = JSON.parse(JSON.stringify(state.departmentsForEmployees))

            for (let i = 0; i < data.length; i++) {
                for (let t = 0; t < departmentsForEmployees.length; t++) {
                    // // console.log(state.employees.data.results[i].department);
                    // // console.log(state.departmentsForEmployees[t].id);
                    if (data[i].department === departmentsForEmployees[t].id) {
                        // // console.log("YES")
                        // // console.log(state.departmentsForEmployees[t].name)
                        // // console.log(state.employees.data.results[i]);
                        data[i].departmentName = departmentsForEmployees[t].name;
                    }
                }
            }

            state.employeesSortByDepartments.splice(0,state.employeesSortByDepartments.length);
            // let form = [
            //     {title: "Superadmin", array: []},
            //     {title: "Accountant", array: []},
            //     {title: "Freighter", array: []},
            //     {title: "OPS", array: []},
            //     {title: "Custom", array: []},
            // ]

            let form = state.departmentsForEmployees.map(a=> {return {title: a.name, array: []}})
            form.push({title: "No department", array: []});
            // let form = [
            //     {title: "Superadmin", array: []},
            //     {title: "Accountant", array: []},
            //     {title: "Freighter", array: []},
            //     {title: "OPS", array: []},
            //     {title: "Custom", array: []},
            // ]
            form.map(a=> {
                a.array = state.employees.data.results.filter(b=> b.departmentName.toLowerCase() === a.title.toLowerCase());
            })



            for (let i = 0; i < data.length; i++) {
                if (data[i].departmentName.toLowerCase() === "superadmin"){
                    form[0].array.push(data[i]);
                } else if (data[i].departmentName.toLowerCase() === "accountant"){
                    form[1].array.push(data[i]);
                } else if (data[i].departmentName.toLowerCase() === "freighter"){
                    form[2].array.push(data[i]);
                } else if (data[i].departmentName.toLowerCase() === "ops"){
                    form[3].array.push(data[i]);
                } else {
                    form[4].array.push(data[i]);
                }
            }
            for(let i=0; i<form.length; i++) {
                form[i].array.sort(function(a, b){
                    var nameA=a.first_name.toLowerCase(), nameB=b.first_name.toLowerCase();
                    if (nameA < nameB) //sort string ascending
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0; //default return value (no sorting)
                });
            }
            state.employeesSortByDepartments = form;
            state.employeesCopy = JSON.parse(JSON.stringify(state.employees));
            state.employeesSortByDepartmentsCopy = JSON.parse(JSON.stringify(state.employeesSortByDepartments));
// // console.log("modifyEmployeeAddDepartment - end")
            return null;
        },

        employeesSortByFirstName(state) {
            state.employees.data.results.sort(function(a, b){
                var nameA=a.first_name.toLowerCase(), nameB=b.first_name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0; //default return value (no sorting)
            });
            state.employeesCopy.data.results.sort(function(a, b){
                var nameA=a.first_name.toLowerCase(), nameB=b.first_name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0; //default return value (no sorting)
            });
        },
        employeesSortByLastName(state) {
            state.employees.data.results.sort(function(a, b){
                var nameA=a.last_name.toLowerCase(), nameB=b.last_name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0; //default return value (no sorting)
            });
            state.employeesCopy.data.results.sort(function(a, b){
                var nameA=a.last_name.toLowerCase(), nameB=b.last_name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0; //default return value (no sorting)
            });
        },
        sortEmployeesByDepartmentFilter(state, name) {
            state.employees = JSON.parse(JSON.stringify(state.employeesCopy));
            state.employeesSortByDepartments = JSON.parse(JSON.stringify(state.employeesSortByDepartmentsCopy))
            if(name.toLowerCase() !== "all") {
                let employees = [];
                for(let i=0; i<state.employees.data.results.length; i++) {
                    if(state.employees.data.results[i].departmentName.toLowerCase() === name.toLowerCase()) {
                        employees.push(state.employees.data.results[i]);
                    }
                }
                state.employees.data.results.splice(state.employees.data.results.length);
                state.employees.data.results = employees;
                let employeesSortDepartment = {
                    title: name,
                    array: employees,
                };
                state.employeesSortByDepartments.splice(0,state.employeesSortByDepartments.length);
                state.employeesSortByDepartments.push(employeesSortDepartment)
            }
        },
        searchForEmployees(state, textSearch) {
            textSearch = (("" + textSearch).replace(/\s/g, '')).toLowerCase();
            // if(textSearch.length !== 0) {
                state.employees = JSON.parse(JSON.stringify(state.employeesCopy));
                state.employeesSortByDepartments = JSON.parse(JSON.stringify(state.employeesSortByDepartmentsCopy));
                let employees = [];
                for (let i = 0; i < state.employees.data.results.length; i++) {
                    let text = state.employees.data.results[i].first_name + state.employees.data.results[i].last_name + "*" +
                        state.employees.data.results[i].last_name + state.employees.data.results[i].first_name + "*" +
                        state.employees.data.results[i].phone_number + "*" + state.employees.data.results[i].email + "*" +
                        state.employees.data.results[i].departmentName + "*" + state.employees.data.results[i].activity;
                    text = (("" + text).replace(/\s/g, '')).toLowerCase();
                    if (text.indexOf(textSearch) !== -1) {
                        employees.push(state.employees.data.results[i]);
                    }
                }
            state.employees.data.results.splice(state.employees.data.results.length);
            state.employees.data.results = employees;

                for(let i=0; i<state.employeesSortByDepartments.length; i++) {
                    let employeesSortByDepartments = [];
                    for(let j=0; j<state.employeesSortByDepartments[i].array.length; j++) {
                        let text = state.employeesSortByDepartments[i].array[j].first_name + state.employeesSortByDepartments[i].array[j].last_name + "*" +
                            state.employeesSortByDepartments[i].array[j].last_name + state.employeesSortByDepartments[i].array[j].first_name + "*" +
                            state.employeesSortByDepartments[i].array[j].phone_number + "*" + state.employeesSortByDepartments[i].array[j].email + "*" +
                            state.employeesSortByDepartments[i].array[j].departmentName + "*" + state.employeesSortByDepartments[i].array[j].activity;
                        text = (("" + text).replace(/\s/g, '')).toLowerCase();
                        if (text.indexOf(textSearch) !== -1) {
                            employeesSortByDepartments.push(state.employeesSortByDepartments[i].array[j]);
                        }
                    }
                    state.employeesSortByDepartments[i].array.splice(state.employeesSortByDepartments[i].array.length);
                    state.employeesSortByDepartments[i].array = employeesSortByDepartments;
                }

            // }
        },
        setActivityEmployees(state, data) {
            console.log("setActivityEmployees 1")
            console.log(data);
            if(data !== null) {
                data = data.map(a=> {return a.user_id})
                console.log("setActivityEmployees 2")
                if(data.length !== 0) {
                    console.log("setActivityEmployees 3")
                    if(state.employees !== null) {
                        console.log("setActivityEmployees 4")
                        for(let i=0; i<state.employees.data.results.length; i++) {
                            state.employees.data.results[i].activity = (data.indexOf(state.employees.data.results[i].id) >= 0) ? "Online" : "Offline"
                            // for(let j=0; j<data.length; j++) {
                            //     if(state.employees.data.results[i].id === data[j].user_id) {
                            //         state.employees.data.results[i].activity = "Online"
                            //     }
                            // }
                        }
                        for(let i=0; i<state.employeesSortByDepartments.length; i++) {
                            for(let j=0; j<state.employeesSortByDepartments[i].array.length; j++) {
                                // console.log('1111', (data.indexOf(''+state.employeesSortByDepartments[i].array[j].id) >= 0), data, state.employeesSortByDepartments[i].array[j].id)
                                state.employeesSortByDepartments[i].array[j].activity = (''+data.indexOf(''+state.employeesSortByDepartments[i].array[j].id) >= 0) ? "Online" : "Offline"

                                // state.employeesSortByDepartments[i].array[j].activity = "Offline"
                                // for(let t=0; t<data.length; t++) {
                                //     if (state.employeesSortByDepartments[i].array[j].id === data[t].user_id) {
                                //         state.employeesSortByDepartments[i].array[j].activity = "Online"
                                //     }
                                // }
                            }
                        }
                        if(this.employeesCopy?.data) {
                            for(let i=0; i<state.employeesCopy.data.results.length; i++) {
                                state.employeesCopy.data.results[i].activity = (data.indexOf(''+state.employeesCopy.data.results[i].id) >= 0) ? "Online" : "Offline"

                                // state.employeesCopy.data.results[i].activity = "Offline"
                                // for(let j=0; j<data.length; j++) {
                                //     if(state.employeesCopy.data.results[i].id === data[j].user_id) {
                                //         state.employeesCopy.data.results[i].activity = "Online"
                                //     }
                                // }
                            }
                        }

                        for(let i=0; i<state.employeesSortByDepartmentsCopy?.length; i++) {
                            for(let j=0; j<state.employeesSortByDepartmentsCopy[i].array.length; j++) {
                                state.employeesSortByDepartmentsCopy[i].array[j].activity = (''+data.indexOf(state.employeesSortByDepartmentsCopy[i].array[j].id) >= 0) ? "Online" : "Offline"

                                // state.employeesSortByDepartmentsCopy[i].array[j].activity = "Offline"
                                // for(let t=0; t<data.length; t++) {
                                //     if (state.employeesSortByDepartmentsCopy[i].array[j].id === data[t].user_id) {
                                //         state.employeesSortByDepartmentsCopy[i].array[j].activity = "Online"
                                //     }
                                // }
                            }
                        }
                        let employees = JSON.parse(JSON.stringify(state.employees.data.results));
                        let employeesDEP = JSON.parse(JSON.stringify(state.employeesSortByDepartments));
                        state.employees.data.results.splice(state.employees.data.results.length);
                        state.employeesSortByDepartments.splice(state.employeesSortByDepartments.length);
                        state.employeesSortByDepartments = JSON.parse(JSON.stringify(employeesDEP));
                        state.employees.data.results =JSON.parse(JSON.stringify(employees));
                    }
                }
            }
        },
        setStopRequestTaskListChangeeAssignee(state, data) {
            state.StopRequestTaskListChangeeAssignee = data;
        },
    },
    state: {
        employeesDataAll: [],
        StopRequestTaskListChangeeAssignee: false,
        requestSend: false,
        cancelTokenSourceEmployees: null,
        employees: null,
        employeesCopy: null,
        employeesSortByDepartments: [],
        employeesSortByDepartmentsCopy: null,
        // permissionRelated: null,
        departmentsForEmployees: null,
        buttonDepartment: [],
        employeesBeforeSearch: null,
    },
    getters: {
        getStopRequestTaskListChangeeAssignee(state) {
          return state.StopRequestTaskListChangeeAssignee
        },
        getEmployees(state) {
            return state.employees;
        },
        getEmployeesDataAll(state) {
            return state.employeesDataAll;
        },
        getButtonsDepartments(state) {
            return state.buttonDepartment;
        },
        getEmployeesSortByDepartments(state) {
            return state.employeesSortByDepartments;
        },
        getDepartmentsForEmployees(state) {
            return state.departmentsForEmployees;
        }
    },
}
