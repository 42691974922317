<template>
  <div class="wrap-activity">

    <div v-for="(a, i) in getActivityData" :key="i">
      <div v-if="a.changes.length !== 0" class="wrap-text-block" >
        <p class="date fz12 text-gray" v-if="a.changes.length || a.action === 'create'">{{a.created_at | formatDateAndTime}}</p>
        <p class="name fz14 text-dark" v-if="a.action === 'create'">
          <span class="text-blue">{{a.user.first_name}} {{a.user.last_name}}</span>
          created the Payment
        </p>
        <p class="name fz14 text-dark" v-if="a.action === 'update' && a.changes.length">
          <span class="text-blue">{{a.user.first_name}} {{a.user.last_name}}</span>
          <span v-if="a.changes[0].field === 'status'"> changed the status from "{{getTextForStatus(a.changes[0].changed_from)}}" to "{{getTextForStatus(a.changes[0].changed_to)}}"</span>
          <span v-if="a.changes[0].field === 'paid_date' && a.changes[0].changed_to !== null && a.changes[0].changed_to.length"> changed the paid date to <span class="text-gray">{{getDateFormChangedActivity(a.changes[0].changed_to)}}</span></span>
          <span v-if="a.changes[0].field === 'paid_date' && (a.changes[0].changed_to === null || !a.changes[0].changed_to.length)"> removed a payment date</span>
          <span v-if="a.changes[0].field === 'files'"> {{textForFilesType(a.changes[0].changed_from.length, a.changes[0].changed_to.length)}}</span>
          <span v-if="a.changes[0].field === 'receipt_files'"> {{textForReceiptFilesType(a.changes[0].changed_from.length, a.changes[0].changed_to.length)}}</span>
          <span v-if="a.changes[a.changes.length - 1].field === 'bank' && a.changes[a.changes.length - 1].changed_to !== null"> changed the Bank and Cash Accounts to {{a.changes[a.changes.length - 1].changed_to.holder}}</span>
          <span v-if="a.changes[a.changes.length - 1].field === 'bank' && a.changes[a.changes.length - 1].changed_to === null"> remove Bank and Cash Accounts</span>
          <span v-if="a.changes[0].field === 'is_archive' && a.changes[0].changed_to.toLowerCase() === 'false'"> unarchived</span>
          <span v-if="a.changes[0].field === 'is_archive' && a.changes[0].changed_to.toLowerCase() === 'true'"> archived</span>
          <span v-if="a.changes[0].field === 'comment' && (!a.changes[0].changed_from || a.changes[0].changed_from.length === 0)"> add comment "{{ a.changes[0].changed_to }}"</span>
          <span v-if="a.changes[0].field === 'comment' && (a.changes[0].changed_from && (''+a.changes[0].changed_from).length !== 0)"> changed comment from "{{ a.changes[0].changed_from }}" to "{{ a.changes[0].changed_to }}"</span>
          <span v-if="a.changes[0].field === 'currency_amount' && (''+a.changes[0].changed_from).length !== 0"> changed currency amount from "{{ a.changes[0].changed_from }}" to "{{ a.changes[0].changed_to }}"</span>
          <span v-if="a.changes[0].field === 'payment_amount' && (''+a.changes[0].changed_from).length !== 0"> changed paymnet amount from "{{ a.changes[0].changed_from }}" to "{{ a.changes[0].changed_to }}"</span>
          <span v-if="a.changes[0].field === 'execute_date'">{{ (a.changes[0].changed_to !== null) ? ' changed the Due Date to ' + getDateDDMMYYYY(a.changes[0].changed_to) : (a.changes[0].changed_to === null) ? ' remove due date' : ''}}</span>
          <span v-if="a.changes[a.changes.length - 1].field === 'ship'">{{(a.changes[a.changes.length - 1].changed_to !== null ? ' changed the Vessel, CP Date to ' + a.changes[a.changes.length - 1].changed_to.name + ' (' + getDateDDMMYYYY(a.changes[0].changed_to) + ')' : a.changes[a.changes.length - 1].changed_to === null ? 'remove Vessel, Cp Date' : '')}}</span>
          <span v-if="a.changes[0].field === 'client_name'">{{a.changes[0].changed_to !== null ? ' changed the Counterparty to ' + a.changes[0].changed_to : ' remove Counterparty'}}</span>
          <span v-if="a.changes[0].field === 'account'">{{a.changes[0].changed_to.length !== 0 ? ' changed the Reference to ' + getTextAccountTitle(a.changes[0].changed_to) : ' remove Reference'}}</span>
          <span v-if="a.changes[0].field === 'real_account'">{{a.changes[0].changed_to !== null ? ' changed the Real Account to ' + a.changes[0].changed_to.company_name : ' remove Real Account'}}</span>
          <span v-if="a.changes[0].field === 'ports'">{{a.changes[0].changed_to !== null ? ' changed the Disbursements (Port) to ' + a.changes[0].changed_to.country  : ' remove Disbursements (Port)'}}</span>
          <span v-if="a.changes[0].field === 'settled_ship'">{{a.changes[0].changed_to !== null ? ' changed the Settled Ship to ' + a.changes[0].changed_to.name + (a.changes[0].changed_to.cp_date.length !== 0 ? ' ('+a.changes[0].changed_to.cp_date+')':'')  : ' remove Settled Ship'}}</span>
          <span v-if="a.changes[0].field === 'statement_member'">{{a.changes[0].changed_to !== null ? ' changed the Statement Member to ' + getDateForCPDATE(a.changes[0].changed_to)  : ' remove Statement Member'}}</span>
          <span v-if="a.changes[0].field === 'payment_type'"> {{a.changes[0].changed_to ? ' changed the Payment Type to ' + ($route.path.indexOf('accounting-invoice') >=0 ? (a.changes[0].changed_to === 'expense' ? 'Bill' : 'Invoice') : a.changes[0].changed_to) : 'remove Payment Type'}}</span>
          <span v-if="checkAccountChanges(a.changes)">{{ getTextChangeAccount(a.changes) }}</span>
          <span v-if="a.changes[0].field === 'additionally'"> {{a.changes[0].changed_to ? `changed the Additionally to ${a.changes[0].changed_to}` : `remove Additionally`}}</span>

          <!--        {{a.changes[0]}}-->

        </p>
        <div v-if="showPreviewFile(a)">
          <div class="files-block" v-if="(fileForView(a.changes[0].changed_from, a.changes[0].changed_to).length !== 0 && fileForView(a.changes[0].changed_from, a.changes[0].changed_to)[0].is_deleted !== true)">
            <!--            {{fileForView(a.changes[0].changed_from, a.changes[0].changed_to)}}-->
            <div class="wrap-file-type"  v-for="(f, a) in fileForView(a.changes[0].changed_from, a.changes[0].changed_to)" :key="'23s'+a">
              <div class="file-type" v-if="f.is_deleted !== true">
                <img :src="f.thumbnail" class="img-preview" alt="preview" v-if="f.thumbnail !== null && ''+f.thumbnail !== ''">
                <img src="../../../assets/imgNone.svg" v-if="f.thumbnail === null || ''+f.thumbnail === ''">
                <p v-if="f.name !== null" class="fz11 text-dark">{{f.name}}</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
<!--    <pre>-->
<!--      {{getActivityData}}-->
<!--    </pre>-->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';

export default {
  name: "payment-detail-activity",
  computed: mapGetters(["getActivityData", "getPermissionsByType"]),
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(["axiosGetActivityList"]),
    checkAccountChanges(array) {
      return array.find(a=> a.field === 'account_name')
    },
    getTextChangeAccount(array) {
      let element = array.find(a=> a.field === 'account_name');
      return element.changed_to ? ' changed the Reference to ' + element.changed_to : ' remove Reference'
    },
    getDateForCPDATE(value) {
      if(value) {
        return moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY')
      }
      return  ''
    },
    getPermissionShowDopInfoAcc() {
      return this.getPermissionsByType('show_dop_info_for_acc_in_payments')
    },
    getTextAccountTitle(account) {
      return Array.isArray(account) === true ? (""+account.map(ac=> {return (this.getPermissionShowDopInfoAcc() ? ac.account_id + ": " + ac.name : ac.name) })) : ''
    },
    getTextPorts(ports) {
      return Array.isArray(ports) === true ? (""+ports.map(ac=> {return  ac.name + ' (' + ac.country + ')' })) : ''
    },
    textForReceiptFilesType(from, to) {
      if(to > from) {
        if((to - 1) === from) {
          return "attached a receipt file"
        } else {
          return "attached a receipt file"
        }
      }
      return "remove a receipt file"
    },
    textForFilesType(from, to) {
      if(to > from) {
        if((to - 1) === from) {
          return "attached a file"
        } else {
          return "attached a files"
        }
      }
      return "remove a file"
    },
    showPreviewFile(a) {
      if(a.changes.length) {
        if(a.changes[0].field === 'files' || a.changes[0].field === 'receipt_files') {
          // alert(1);
          if(a.changes[0].changed_from < a.changes[0].changed_to) {
            return true
          }
        }
      }
      return false;
    },
    fileForView(from, to) {
      let files = [];
      // console.log("from")
      // console.log(from)
      // console.log("to")
      // console.log(to)
        for(let i=0; i<to.length; i++) {
          let k=0;
          for(let j=0; j<from.length; j++) {
            if(to[i].id === from[j].id) {
              k++;
            }
          }
          if(k===0) {
            files.push(to[i]);
          }
        }
      // }

      return files;
    },
    renderActivity() {
      let textRoute = this.$route.path;
      let id = textRoute.replace(/[^-0-9]/gim,'');
      if(this.$route.path.indexOf('cash-flow')>=0) {
        id = this.$route.params.paymentCashFlowID;
      }
      if(this.$route.path.indexOf('accounting-invoice')>=0) {
        id = this.$route.params.paymentAccInvID;
      }
      this.axiosGetActivityList({model: "payment", instance_id: id, ordering: "created_at", limit: 999999})
    },
    getDateFormat(date) {
      date = moment(new Date(date)).utcOffset(6).format("D MMM YYYY, H:mm")
      return date
    },
    getTextForStatus(status) {
      if(status === 'not_paid') {
        return 'Not Paid'
      }
      if(status === 'in_progress') {
        return 'Not Paid'
      }
      if(status === 'paid') {
        return 'Paid'
      }
    },
    getDateFormChangedActivity(date) {
       return moment(date, "YYYY-MM-DD").format("D MMM YYYY");
    },
    getDateDDMMYYYY(date) {
      return moment(date).format("DD.MM.YYYY");
    }
  },
  mounted() {
    this.renderActivity();
  }
}
</script>

<style scoped lang="scss">
  @import "payment-detail-activity";
</style>