<template>
  <div class="flex items-center">
    <div class="fs_period-btns relative">
      <date-picker
        class="datepicker_year_popup_wrap"
        format="YYYY"
        type="year"
        :popup-class="'datepicker_year_popup'"
        :open.sync="isOpen"
        value-type="YYYY"
        v-model="dateYear"
        @change="onChangeYear"
        :disabled-date="disabledYear"
      ></date-picker>
      <button class="fs_period-btn fz13 h32" :class="{'active': period == 'year'}" @click="isOpen = !isOpen">{{dateYear ? dateYear : 'Year'}}</button>
      <button class="fs_period-btn fz13 h32" :class="{'active': period == 'all'}" @click="setPeriod('all')">All time</button>
    </div>

    <date-picker
      class="max-w-220"
      input-class="border-blue-dark mx-input"
      format="DD.MM.YYYY"
      type="date"
      value-type="YYYY-MM-DD"
      range-separator="-"
      :range="true"
      placeholder="Choose period"
      v-model="date"
      @clear="setPeriod('all')"
      @change="onChangeDate"
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      period: 'all',
      date: [null, null],
      dateYear: null,
      isOpen: false,
      timeout: null
    }
  },
  watch: {
    date(val, oldVal) {
      if(val[0] != oldVal[0] || val[1] != oldVal[1])
        this.updateBalances()
    },
    dateYear() {
      this.updateBalances()
    },
  },
  computed: {
    ...mapState({
      vesselBalances: state => state.contract.vesselBalances
    }),
    getYears() {
      if(this.vesselBalances && this.vesselBalances?.available_years.length)
        return this.vesselBalances?.available_years
      return null
    },
  },
  methods: {
    ...mapActions(['axiosGetBalances']),
    ...mapMutations(['showLoad', 'hideLoad']),
    disabledYear(data) {
      if(this.getYears && this.getYears.length){
        return this.getYears.indexOf(data.getFullYear()) == -1
      }
      return true
    },
    setPeriod(str) {
      if(this.date[0])
        this.date = [null, null]
      if(this.dateYear)
        this.dateYear = null
      this.period = str
    },
    onChangeDate() {
      if(this.dateYear)
        this.dateYear = null
      if (this.date[0]) {
        this.period = 'period'
      }
    },
    onChangeYear() {
      if(this.date[0])
        this.date = [null, null]
      if(this.dateYear)
        this.period = 'year'
    },
    updateBalances() {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(()=>{
        this.sendUpdate()
      },100)
    },
    async sendUpdate() {
      let payload = {
        id: +this.$route.params.id,
        before: null,
        after: null
      }
      if(this.period == 'year'){
        payload.after = this.dateYear+'-01-01 00:00:00'
        payload.before = this.dateYear+'-12-31 23:59:59'
      }else if(this.period == 'period'){
        payload.after = this.date[0]+' 00:00:00'
        payload.before = this.date[1]+' 23:59:59'
      }
      this.showLoad()
      await this.axiosGetBalances(payload)
      this.hideLoad()
    }
  }
}
</script>

<style lang="scss">
.datepicker_year_popup_wrap{
  position: absolute;
  top: 100%;
  right: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
.datepicker_year_popup{
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  .mx-calendar-header-label > *{
    font-size: 13px;
    font-weight: 500;
  }
  .mx-calendar-decade-separator{
    &:after{
      content: '-';
    }
  }
  .mx-icon-double-left{
    &:after{
      content: none;
    }
    &:before{
      border-width: 3px 0 0 3px;
    }
  }
  .mx-icon-double-right{
    &:before{
      content: none;
    }
    &:after{
      border-width: 3px 0 0 3px;
    }
  }
  .mx-calendar-content{
    color: var(--color-dark)
  }
  .disabled {
    background-color: transparent !important;
  }
}
.fs_period-btn {
  color: var(--color-dark)
}
</style>
