<template>
  <div class="wrap">
    <async-select
        v-if="((getPermissionsByType('create_or_edit_base_checklist') && detailLocal.is_basic) || (!detailLocal.is_basic && detailLocal && detailLocal.creator && detailLocal.creator.id === getLoginData.data.user.id)) && !isArchive"
        :id="'839482sd22'"
        label="Linked Vessels"
        url="/contract/dropdown"
        :afterWatchKey="'cp_date'"
        :params="{is_archive: false, contract_type: ['SHIP']}"
        :allowEmpty="true"
        :isMultiple="true"
        :placeholder="''"
        :closeOnSelect="false"
        v-model="detailLocal.vessels"
        :after-watch-key-brackets="'cp_date'"
    />
    <div class="w100Proc" v-else>
      <p class="fz13 text-gray">Linked Vessels</p>
      <p class="fz14 text-dark mt6 word-break-all">{{getTextSelectedVessel()}}</p>
    </div>
    <checklist-checkboxes-list :disabled-create="disabledCreate" @create="disabledCreate = true" :isArchive="isArchive" @delete="(e)=> {idForDelete=e; isShowModalDelete=true}" class="mt20" v-model="detailLocal.items" :item="detailLocal"></checklist-checkboxes-list>
    <modal-slots :isBorder="false" class="delete-modal" :showMod="isShowModalDelete" @close="isShowModalDelete = false">
      <template v-slot:title>
        <h2 class="fz16 fw500 text-dark">Delete the item</h2>
      </template>

      <p class="fz14 text-dark p24">Are you sure you want to delete this item?</p>

      <template v-slot:bottomFirstButton>
        <button class="blue-button-text fz14" @click="isShowModalDelete = false">Cancel</button>
      </template>
      <template v-slot:bottomSecondButton>
        <button class="m-l-8 p-x-16 blue-button fz14 fw500" @click="deleteCheckbox(idForDelete)">Delete</button>
      </template>
    </modal-slots>
  </div>
</template>

<script>
import AsyncSelect from "@/components/reuse/asyncSelect";
import ChecklistCheckboxesList from "@/components/checklist/checklist-checkboxes-list";
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';
import ModalSlots from "@/components/modal/modal-slots";
export default {
  name: "checklistFormShow",
  components: {ModalSlots, ChecklistCheckboxesList, AsyncSelect},
  props: {
    type: {
      type: String,
      default: '',
    },
    isArchive: Boolean,
    detail: Object,
  },
  computed: {
    ...mapGetters(['getPermissionsByType', 'getLoginData'])
  },
  watch: {
    detail: function() {
      this.notChange = true;
      setTimeout(()=> {
        this.notChange = false
      }, 400)
      this.detailLocal = this.detail
    },
    detailLocal: {
      deep: true,
      handler() {
        if(!this.notChange) {
          if(this.timeout) clearTimeout(this.timeout)
          this.$emit('changeList', this.detailLocal.items)
          this.timeout = setTimeout(() => {
            this.onSaveCheckList()
          }, 400)
        }
      }
    },

  },
  data() {
    return {
      disabledCreate: false,
      idForDelete: false,
      isShowModalDelete: false,
      notChange: false,
      timeout: null,
      detailLocal: {
        name: 'Name',
        is_basic: this.type === 'basic',
        is_archived: false,
        vessels: [],
        items: [],

      }
    }
  },
  methods: {
    ...mapActions(['axiosPatchChecklist']),
    getTextSelectedVessel() {
      let text = ''
      this.detailLocal.vessels.forEach((item, index)=> {
        text+=index !==this.detailLocal.vessels.length-1 ? item.name + `(${moment(item.cp_date).format('DD.MM.YYYY')})` + ' / ' : item.name + `(${moment(item.cp_date).format('DD.MM.YYYY')})`
      })
      return !text.length ? 'No linked vessels' : text
    },
    deleteCheckbox(indexDelete) {
      this.isShowModalDelete = false
      let item = JSON.parse(JSON.stringify(this.detailLocal))
      let form = {
        name: item.name,
        is_basic: item.is_basic,
        is_archived: item.is_archived,
        vessels: item.vessels.map(a=> {return a.id}),
        items: item.items
      }
      let idFilter = -1
      form.items.forEach((item, index)=> {
        if(item.index === indexDelete) {
          item['is_deleted'] = true
          idFilter = item.id
        }
        item.index = index;
      })
      this.detailLocal.items = this.detailLocal.items.filter(a=> a.id !== idFilter)
      this.axiosPatchChecklist({id:item.id,form: form})
    },
    async onSaveCheckList() {
      let item = JSON.parse(JSON.stringify(this.detailLocal))

      let form = {
        name: item.name,
        is_basic: item.is_basic,
        is_archived: item.is_archived,
        vessels: item.vessels.map(a=> {return a.id}),
        items: item.items
      }
      console.log('form.items', form.items)

      form.items.forEach((item, index)=> {
        item.index = index;
      })
      // console.log('change, form', form)
      let changedData = await this.axiosPatchChecklist({id:item.id,form: form})
      if(changedData) {
        this.notChange = true;
        if(this.disabledCreate) {
          // eslint-disable-next-line no-unused-vars
          changedData.data.items.sort((a, b) => a.id > b.id ? 1 : -1);
          let IDS = this.detailLocal.items.map(a=> {return a.id}).filter(a=> a)
          let newElement = changedData.data.items.filter(a=> IDS.indexOf(a.id) === -1)
          this.detailLocal.items =  this.detailLocal.items.filter(a=> a.id)
          if(Array.isArray(newElement) === true && newElement.length !== 0) {
            this.detailLocal.items.push(newElement[0])
          }
        }
        setTimeout(()=> {
          this.notChange = false
          this.disabledCreate = false
        }, 100)
        console.log('changedData', changedData)
      }
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.wrap {
}
.delete-modal {
  /deep/ {
    .top-panel {
      padding: 12px 16px 0 16px !important;
    }
    .main-block {
      p {
        padding: 20px 16px 20px 16px !important;
      }
    }
    .bottom-panel {
      padding: 0 16px 12px 16px !important;
    }
  }
}
</style>