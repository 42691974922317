<template>
  <transition name="fade">
    <div class="wrap" v-if="show">
      <div class="filter" v-on:click="hideMenu"></div>
      <div class="content" :class="{'show-menu': show}">
        <div class="top-block svg-white-dart">
          <button class="close-modal" v-on:click="hideMenu">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
              <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
            </svg>
          </button>
          <p class="fz18 fw500">Edit Routes</p>
        </div>
        <div class="block-button" v-on:click="showAddRoute">
          <button class="fz13 fw500 button-blue-border">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z" fill="#094172"/>
            </svg>
            Create New Route
          </button>
        </div>
        <div class="main svg-white-dart-stroke">
          <div class="block-button-wrap" v-for="(r, i) in routesDetailsForShip" :key="i">
            <button class="button-route" :title="r.text" v-on:click="showEditRoute(r, r.indexInArray)">
              <p class=" fz13 text-dark">{{r.text}}</p>
            </button>
            <button class="button-delete" v-on:click="clickDelete(r)">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.1333 6.32059V17.1435C17.1333 18.1688 16.2976 19 15.2667 19H8.73333C7.7024 19 6.86667 18.1688 6.86667 17.1435V6.32059M17.1333 6.32059H6.86667M17.1333 6.32059H19M6.86667 6.32059H5M9.66667 6.32059L9.66667 4.92824C9.66667 4.41559 10.0845 4 10.6 4L13.4 4C13.9155 4 14.3333 4.41559 14.3333 4.92824V6.32059M10.6 10.0335V15.1388M13.4 10.0335V15.1388" stroke="#4B4B4B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
          <div class="unallocated" v-if="routesDetailsUnallocated.length !== 0">
            <p class="fz13 fw500 text-gray">Unallocated</p>
            <div class="block-button-wrap" v-for="(r, i) in routesDetailsUnallocated" :key="i">
              <button class="button-route" :title="r.text" v-on:click="showEditRoute(r, r.indexInArray)">
                <p class=" fz13 text-dark">{{r.text}}</p>
              </button>
              <button class="button-delete" v-on:click="clickDelete(r)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.1333 6.32059V17.1435C17.1333 18.1688 16.2976 19 15.2667 19H8.73333C7.7024 19 6.86667 18.1688 6.86667 17.1435V6.32059M17.1333 6.32059H6.86667M17.1333 6.32059H19M6.86667 6.32059H5M9.66667 6.32059L9.66667 4.92824C9.66667 4.41559 10.0845 4 10.6 4L13.4 4C13.9155 4 14.3333 4.41559 14.3333 4.92824V6.32059M10.6 10.0335V15.1388M13.4 10.0335V15.1388" stroke="#4B4B4B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div class="wrapDelete" v-if="showDelete">
          <div class="filter" v-on:click="hideDelete"></div>
          <div class="contentDelete">
            <div class="name-modal">
              <p class="fz16 fw500">Delete Route</p>
              <button class="close-modal"  v-on:click="hideDelete">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                  <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div class="text">
              <p class="fz14">Are you sure you want to delete the route <span class="fz14 fw500">{{ routeDelete.text }}</span>?</p>
            </div>
            <div class="bottom-panel">
              <button class="blue-button-text" v-on:click="hideDelete">Cancel</button>
              <button class="blue-button h32 buttonW100" v-on:click="deleteThisRoute(routeDelete.id)">Delete</button>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="wrapEdit" v-if="showEditRouteModal && getPermissionsByType('can_edit_route')">
          <div class="filter" v-on:click="hideEditRoute"></div>
          <div class="contentEdit">
            <div class="name-modal">
              <p class="fz16 fw500">Edit Route</p>
              <button class="close-modal"  v-on:click="hideEditRoute">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                  <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div class="block-input">
              <p class="fz12 text-gray">Ship type</p>
              <div class="ships">
                <div v-for="(s, i) in ships" :key="i" v-on:click="changeStatusShips(i)" :class="{'active': s.status}" class="fz13 text-dark">{{ s.name }}</div>
              </div>
            </div>
            <div class="block-input">
              <p class="fz12 text-gray">Abbreviation</p>
              <input type="text" class="h36" v-model="editName">
            </div>
            <div class="block-input">
              <p class="fz12 text-gray">Full route name</p>
              <input type="text" class="h36" v-model="editRoute">
            </div>
            <div class="block-input">
              <p class="fz12 text-gray">Sorting Index</p>
              <input type="text" class="h36" v-model="sortingIndexEdit" @input="sortingIndexEdit = changeInputIndex(sortingIndexEdit)">
            </div>
<!--            <div class="block-input block-input-svg">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M11.1458 19.291L11.1458 19.291L11.1517 19.2988C11.191 19.3501 11.2311 19.3875 11.2622 19.4132C11.2665 19.4168 11.2707 19.4202 11.2748 19.4234C11.782 19.9116 12.5078 19.7517 12.8515 19.2935L12.8516 19.2933C13.4261 18.5268 14.2391 17.4288 15.0081 16.3895C15.0695 16.3065 15.1307 16.2238 15.1914 16.1418C15.892 15.1949 16.5358 14.3247 16.906 13.831L16.9694 13.7465L16.9729 13.736C17.6687 12.7272 18.0362 11.5447 18.0362 10.3142C18.0362 6.98519 15.3287 4.27773 11.9997 4.27773C8.67074 4.27773 5.96328 6.98519 5.96328 10.3142C5.96328 11.6289 6.3794 12.8823 7.16689 13.9321C7.16724 13.9325 7.1676 13.933 7.16795 13.9335L11.1458 19.291ZM11.9997 12.5701C10.7596 12.5701 9.74381 11.5543 9.74381 10.3142C9.74381 9.07408 10.7596 8.05826 11.9997 8.05826C13.2398 8.05826 14.2556 9.07408 14.2556 10.3142C14.2556 11.5543 13.2398 12.5701 11.9997 12.5701Z" stroke="#9AA3B0" stroke-width="1.3"/>-->
<!--              </svg>-->
<!--              <p class="fz12 text-gray">From</p>-->
<!--              <input type="text" class="h36" v-model="editFrom">-->
<!--            </div>-->
<!--            <div class="block-input block-input-svg">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M11.1458 19.291L11.1458 19.291L11.1517 19.2988C11.191 19.3501 11.2311 19.3875 11.2622 19.4132C11.2665 19.4168 11.2707 19.4202 11.2748 19.4234C11.782 19.9116 12.5078 19.7517 12.8515 19.2935L12.8516 19.2933C13.4261 18.5268 14.2391 17.4288 15.0081 16.3895C15.0695 16.3065 15.1307 16.2238 15.1914 16.1418C15.892 15.1949 16.5358 14.3247 16.906 13.831L16.9694 13.7465L16.9729 13.736C17.6687 12.7272 18.0362 11.5447 18.0362 10.3142C18.0362 6.98519 15.3287 4.27773 11.9997 4.27773C8.67074 4.27773 5.96328 6.98519 5.96328 10.3142C5.96328 11.6289 6.3794 12.8823 7.16689 13.9321C7.16724 13.9325 7.1676 13.933 7.16795 13.9335L11.1458 19.291ZM11.9997 12.5701C10.7596 12.5701 9.74381 11.5543 9.74381 10.3142C9.74381 9.07408 10.7596 8.05826 11.9997 8.05826C13.2398 8.05826 14.2556 9.07408 14.2556 10.3142C14.2556 11.5543 13.2398 12.5701 11.9997 12.5701Z" stroke="#9AA3B0" stroke-width="1.3"/>-->
<!--              </svg>-->
<!--              <p class="fz12 text-gray">To</p>-->
<!--              <input type="text" class="h36" v-model="editTo">-->
<!--            </div>-->
            <div class="bottom-panel">
              <button class="blue-button-text" v-on:click="hideEditRoute">Cancel</button>
              <button class="blue-button h32 buttonW100" v-on:click="changeRoute">Confirm</button>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="wrapEdit" v-if="showAddRouteModal">
          <div class="filter" v-on:click="hideAddRoute"></div>
          <div class="contentEdit">
            <div class="name-modal">
              <p class="fz16 fw500">Create New Route</p>
              <button class="close-modal"  v-on:click="hideAddRoute">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                  <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div class="block-input">
              <p class="fz12 text-gray">Ship type</p>
              <div class="ships">
                <div v-for="(s, i) in ships" :key="i" v-on:click="changeStatusShips(i)" :class="{'active': s.status}" class="fz13 text-dark">{{ s.name }}</div>
              </div>
            </div>
            <div class="block-input">
              <p class="fz12 text-gray">Abbreviation</p>
              <input type="text" class="h36" v-model="nameAddRoute">
            </div>
<!--            <div class="block-input">-->
<!--              <p class="fz12 text-gray">Ship type</p>-->
<!--              <select class="h36" v-model="selectAdd">-->
<!--                <option value="">No chosen</option>-->
<!--                <option :value="s.id" v-for="(s,i) in getShips" :key="i">{{ s.name }}</option>-->
<!--              </select>-->
<!--            </div>-->

            <div class="block-input">
              <p class="fz12 text-gray">Full route name</p>
              <input type="text" class="h36" v-model="route">
            </div>
            <div class="block-input">
              <p class="fz12 text-gray">Sorting Index</p>
              <input type="text" class="h36" v-model="sortingIndex" @input="sortingIndex = changeInputIndex(sortingIndex)">
            </div>
<!--            <div class="block-input block-input-svg">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M11.1458 19.291L11.1458 19.291L11.1517 19.2988C11.191 19.3501 11.2311 19.3875 11.2622 19.4132C11.2665 19.4168 11.2707 19.4202 11.2748 19.4234C11.782 19.9116 12.5078 19.7517 12.8515 19.2935L12.8516 19.2933C13.4261 18.5268 14.2391 17.4288 15.0081 16.3895C15.0695 16.3065 15.1307 16.2238 15.1914 16.1418C15.892 15.1949 16.5358 14.3247 16.906 13.831L16.9694 13.7465L16.9729 13.736C17.6687 12.7272 18.0362 11.5447 18.0362 10.3142C18.0362 6.98519 15.3287 4.27773 11.9997 4.27773C8.67074 4.27773 5.96328 6.98519 5.96328 10.3142C5.96328 11.6289 6.3794 12.8823 7.16689 13.9321C7.16724 13.9325 7.1676 13.933 7.16795 13.9335L11.1458 19.291ZM11.9997 12.5701C10.7596 12.5701 9.74381 11.5543 9.74381 10.3142C9.74381 9.07408 10.7596 8.05826 11.9997 8.05826C13.2398 8.05826 14.2556 9.07408 14.2556 10.3142C14.2556 11.5543 13.2398 12.5701 11.9997 12.5701Z" stroke="#9AA3B0" stroke-width="1.3"/>-->
<!--              </svg>-->
<!--              <p class="fz12 text-gray">To</p>-->
<!--              <input type="text" class="h36" v-model="toNameRoute">-->
<!--            </div>-->
            <div class="bottom-panel">
              <button class="blue-button-text" v-on:click="hideAddRoute">Cancel</button>
              <button class="blue-button h32 buttonW100" v-on:click="addNewRoute">Confirm</button>
            </div>
          </div>
        </div>
      </transition>

    </div>
  </transition>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  // props: ["routesDetails"],
  computed: mapGetters(["getPermissionsByType", "getRoutes", "getShips", "getShipId"]),
  name: "editOrAddRoutes",
  data() {
    return {
      selectAdd: "",
      selectEdit: "",
      nameAddRoute: "",
      fromNameRoute: "",
      toNameRoute: "",
      route: "",
      sortingIndex: null,
      sortingIndexEdit: null,
      sortingIndexEditCopy: null,
      editName: "",
      editTo: "",
      editFrom: "",
      editId: "",
      editRoute: "",
      editRouteCopy: "",
      CopyEditName: "",
      CopyEditTo: "",
      CopyEditFrom: "",
      CopySelectEdit: "",
      indexActive: null,
      show: false,
      showDelete: false,
      showEditRouteModal: false,
      showAddRouteModal: false,
      routeDelete: null,
      ships: [],
      routesDetailsUnallocated:[],
      routesDetails: [
        // {text: "C2: Tubarao - Rotterdam"},
        // {text: "C3: Tubarao - Qingdao"},
        // {text: "C4: Richards Bay - Rotterdam"},
        // {text: "C5: W Australia - Qingdao"},
        // {text: "C7: Bolivar - Rotterdam"},
        // {text: "C8_07: Gibraltar/Hamburg trans Atlantic RV"},
        // {text: "C9_03: Continent/Mediterranean trip Far East Continent/Mediterranean trip Far East"},
        // {text: "C10_03: Pacific RV"},
        // {text: "C11_03: China/Japan trip Mediterranean/Cont"},
      ],
      routesDetailsForShip: [],
    }
  },
  methods: {
    ...mapActions(["axiosDeleteRoute", "axiosCreateRoute", "axiosChangeRoute", "axiosGetRoutes"]),
    ...mapMutations(["setAlert"]),
    showMenu(array) {
      this.routesDetails = [];
      this.routesDetails = JSON.parse(JSON.stringify(array));
      let m=0
      let n=0;
      this.routesDetailsForShip.splice(0,this.routesDetailsForShip.length);
      this.routesDetailsUnallocated.splice(0,this.routesDetailsUnallocated.length);
      for(let i=0; i<this.routesDetails.length; i++) {
        if((""+this.routesDetails[i].ship_type) === (""+this.getShipId)) {
          this.routesDetailsForShip.push(this.routesDetails[i]);
          this.routesDetailsForShip[m]["indexInArray"] = i;
          m++;
        }
        if(this.routesDetails[i].ship_type === null || (""+this.routesDetails[i].ship_type).length === 0) {
          this.routesDetailsUnallocated.push(this.routesDetails[i]);
          this.routesDetailsUnallocated[n]["indexInArray"] = i;
          n++;
        }
      }
      this.show = true;
    },
    changeInputIndex(number) {
      return number.replace(/\D/g,'');
    },
    hideMenu() {
      this.show = false;
      this.$emit("changeRoutes");
    },
    clickDelete(item) {
      this.routeDelete = item;
      this.showDelete = true;
    },
    hideDelete() {
      this.routeDelete = null;
      this.showDelete = false;
    },
    deleteThisRoute(id) {
      this.axiosDeleteRoute(id).then(()=> {
        this.hideDelete();
        this.changeEmitCall();
      })
    },
    showEditRoute(route, index) {
      if(this.getPermissionsByType('can_edit_route')) {
        this.indexActive = index;
        // console.log(route)
        if(route.ship_type !== null) {
          this.CopySelectEdit = route.ship_type;
        } else {
          this.selectEdit = "";
          this.CopySelectEdit = "";
        }
        this.ships.splice(0,this.ships.length);
        for(let i=0; i<this.getShips.length; i++) {
          let form = {
            id: this.getShips[i].id,
            name: this.getShips[i].name,
            status: false,
          }
          // console.log(this.getShips[i].id);
          if((""+this.getShips[i].id)===(""+route.ship_type)) {
            form.status = true;
          }
          this.ships.push(form);
        }
        this.sortingIndexEdit = route.sorting_index
        this.editId = route.id;
        // this.editFrom = route.from;
        // this.editTo = route.to;
        this.editRoute = route.route;
        this.editName = route.abbreviation;
        // this.CopyEditFrom = route.from;
        this.sortingIndexEditCopy = route.sorting_index
        this.editRouteCopy = route.route;
        this.CopyEditName = route.abbreviation;
        // this.CopyEditTo = route.to;
        this.showEditRouteModal = true;
      }
      // // console.log(route);

    },
    changeRoute() {
      if(this.editRoute.length !== 0 && this.editName.length !== 0) {
        let selectEdit = "";
        for(let i=0; i<this.ships.length; i++) {
          if(this.ships[i].status === true) {
            selectEdit = "" + this.ships[i].id;
          }
        }
        if(this.editName !== this.CopyEditName || this.editRouteCopy.length !== this.editRoute || selectEdit !== (""+this.CopySelectEdit) || this.sortingIndexEditCopy !== this.sortingIndexEdit) {
          this.axiosChangeRoute({id: this.editId, reduction: this.editName, route: this.editRoute, ship_type: selectEdit, sorting_index: this.sortingIndexEdit,}).then(()=> {
            this.hideEditRoute();
            this.changeEmitCall();
          });
        } else {
          this.hideEditRoute();
        }
      } else {
        this.setAlert({text: "Fill in all the fields, please.", show: true});
      }
    },
    hideEditRoute() {
      this.sortingIndexEdit = null;
      this.sortingIndexEditCopy = null;
      this.showEditRouteModal = false;
      this.editId = "";
      this.editFrom = "";
      this.editTo = "";
      this.editName = "";
      this.CopyEditFrom = "";
      this.CopyEditName = "";
      this.CopyEditTo = "";
      this.indexActive = null;
    },
    showAddRoute() {
      this.showAddRouteModal = true;
      this.ships.splice(0, this.ships.length);
      for(let i=0; i<this.getShips.length; i++) {
        let form = {
          id: this.getShips[i].id,
          name: this.getShips[i].name,
          status: false,
        }
        if(i===0) {
          form.status = true;
        }
        this.ships.push(form);
      }
    },
    changeStatusShips(index) {
      for(let i=0; i< this.ships.length; i++) {
        this.ships[i].status = false;
      }
      this.ships[index].status = true;
    },
    hideAddRoute() {
      this.sortingIndex = null;
      this.nameAddRoute = "";
      this.route = "";
      // this.fromNameRoute = "";
      // this.toNameRoute = "";
      this.showAddRouteModal = false;
    },
    addNewRoute() {
      if(this.nameAddRoute.length !== 0) {
        let idShips = 0;
        for(let i=0; i<this.ships.length; i++) {
          if(this.ships[i].status === true) {
            idShips = this.ships[i].id;
          }
        }
          this.axiosCreateRoute({reduction: this.nameAddRoute, route: this.route, ship_type: idShips, sorting_index: this.sortingIndex}).then(()=> {
            this.hideAddRoute();
            this.changeEmitCall();
          });
      } else {
        this.setAlert({text: "Fill in all the fields, please.", show: true});
      }
    },
    changeEmitCall() {
      this.axiosGetRoutes().then(()=> {
        let routes = this.getRoutes;
        this.routesDetails.splice(0, this.routesDetails.length);

        this.routesDetailsUnallocated.splice(0, this.routesDetailsUnallocated.length);
        // console.log(routes)
        // console.log(1);
        for(let i=0; i<routes.length; i++) {
          // console.log(routes[i].route);
          let text = routes[i].reduction + ": No Data";
          // if(routes[i].route_to.length !== 0 && routes[i].route_from.length !== 0) {
          //   text = routes[i].reduction + ": " + routes[i].route_from + " - " + routes[i].route_to;
          // }
          // console.log(3);
          if(routes[i].route === null) {
            routes[i].route = "";
          }
          // console.log(4);
          if(routes[i].route.length !== 0) {
            text = routes[i].reduction + ": " + routes[i].route;
          }
          let form = {
            text: text,
            id: routes[i].id,
            abbreviation: routes[i].reduction,
            // from: routes[i].route_from,
            sorting_index: routes[i].sorting_index,
            ship_type: routes[i].ship_type,
            route: routes[i].route,
            // to: routes[i].route_to,
          }
          // // console.log("For");
          this.routesDetails.push(form);
        }
        let n= 0;
        let m = 0;
        this.routesDetailsForShip.splice(0,this.routesDetailsForShip.length);
        for(let i=0; i<this.routesDetails.length; i++) {
          if((""+this.routesDetails[i].ship_type) === (""+this.getShipId)) {
            this.routesDetailsForShip.push(this.routesDetails[i]);
            this.routesDetailsForShip[m]["indexInArray"] = i;
            m++;
          }
          if(this.routesDetails[i].ship_type === null || (""+this.routesDetails[i].ship_type).length === 0) {
            this.routesDetailsUnallocated.push(this.routesDetails[i]);
            this.routesDetailsUnallocated[n]["indexInArray"] = i;
            n++;
          }
        }
        // // console.log("routesDetails");
        // // console.log(this.routesDetails)
      });

    }
  }
}
</script>

<style scoped lang="scss">
  @import "editOrAddRoutes";
</style>