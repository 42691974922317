<template>
  <div class="top-panel">
    <div class="part1">
      <p class="fz20 text-dark fw500">Сounterparties Database</p>

      <router-link v-if="getPermissionsByType('can_create_client')" to="/counterparties/add-counterparties" class="button-blue-border fw500 fz13">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>
          Create new
      </router-link>

      <router-link v-if="getPermissionsByType('show_archive_of_counterparties')" to="/counterparties/archive" class="button-dark-link archive-link svg-white-dart-stroke text-dark fw500 fz13">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Archive
      </router-link>
    </div>

    <div class="part2">
      <counterparties-sort-by @changeSort="changeSort" @changeOrder="changeOrder" class="sort-block"></counterparties-sort-by>
      <div class="block-search">
        <input type="text" v-model.lazy="filters.search" placeholder="Search by keyword">
        <button class="icon-close" @click="filters.search = ''" v-show="filters.search">
          <svg width="12" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.05373 1.9253C0.810215 1.68418 0.810216 1.29326 1.05373 1.05214C1.29724 0.811029 1.69206 0.811029 1.93557 1.05214L6.94574 5.97329C7.18925 6.21441 7.18925 6.60533 6.94574 6.84644C6.70223 7.08756 6.30741 7.08756 6.0639 6.84644L1.05373 1.9253Z" fill="#2C2C2C"/>
            <path d="M6.06398 1.15353C6.3075 0.912416 6.70231 0.912416 6.94582 1.15353C7.18933 1.39465 7.18933 1.78557 6.94582 2.02669L1.93565 6.94783C1.69214 7.18895 1.29732 7.18895 1.05381 6.94783C0.810298 6.70672 0.810298 6.31579 1.05381 6.07468L6.06398 1.15353Z" fill="#2C2C2C"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import counterpartiesSortBy from "@/components/counterparties/counterpartiesList/counterpartiesSortBy"
import { mapGetters } from 'vuex'

export default {
  components: {
    counterpartiesSortBy,
  },
  props: {
    filters: Object
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    changeSort(bool) {
      this.filters.orderDirection = bool ? '-' : '+'
    },
    changeOrder(str) {
      this.filters.ordering = str
    },
  }
}
</script>

<style scoped lang="scss">
  .top-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }
  .part1{
    display: flex;
    align-items: center;
  }
  .part2{
    display: flex;
    align-items: center;
  }
  .button-blue-border{
    margin-left: 32px;
  }
  .archive-link{
    display: flex;
    align-items: center;
    margin-left: 28px;
  }
  .block-search {
    margin-left: 24px;
    width: 224px;
    display: flex;
    align-items: center;
    position: relative;
    input {
      width: 100%;
      box-sizing: border-box;
      height: 32px;
      padding-left: 42px;
      padding-right: 32px;
      background-color: #fff;
      background-image: url(../../../assets/image/search1.svg);
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-position: 10px center;
    }
    .icon-search {
      z-index: 0;
      position: absolute;
      left: 10px;
    }
    .icon-close {
      position: absolute;
      padding: 6px;
      right: 4px;
      transition: .1s linear;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
</style>