<template>
  <span>{{converted | formatNumber}}</span>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['currency', 'value'],
  data() {
    return {
      converted: 0
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.converted = 0
        this.getRate()
      }
    }
  },
  methods: {
    ...mapActions(['axiosGetCurrency']),
    async getRate() {
      if(!this.value) return false
      await this.axiosGetCurrency({currency: this.currency}).then(response=> {
        let rate = response.data.results[response.data.results.length - 1].rate
        this.converted = this.value / rate
      })
    }
  },
  mounted() {
    this.getRate()
  }
}
</script>

<style>

</style>