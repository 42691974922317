<template>
  <div class="wrap position-relative w-max h-max" v-click-outside="hide">
    <div class="select d-flex align-items-center cursor-pointer" v-on:click="show = !show">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.28634 3.73435C8.22907 3.73435 7.3304 4.45675 7.03965 5.44672H4.63436C4.26432 5.44672 4 5.74103 4 6.11562C4 6.4902 4.29075 6.78451 4.66079 6.78451H7.06608C7.3304 7.77448 8.25551 8.49689 9.31277 8.49689C10.3965 8.49689 11.2952 7.77448 11.5859 6.78451H18.3392C18.7093 6.78451 19 6.4902 19 6.11562C19 5.74103 18.7093 5.44672 18.3392 5.44672H11.5859C11.2687 4.45675 10.37 3.73435 9.28634 3.73435ZM9.31277 5.07214C9.86784 5.07214 10.3436 5.52699 10.3436 6.11562C10.3436 6.70424 9.86784 7.15909 9.31277 7.15909C8.75771 7.15909 8.28194 6.70424 8.28194 6.11562C8.28194 5.52699 8.75771 5.07214 9.31277 5.07214Z" fill="#319FEF"/>
        <path d="M14.2423 9.61066C13.185 9.61066 12.2863 10.3331 11.9956 11.323H4.66079C4.26432 11.323 4 11.6173 4 11.9919C4 12.3665 4.29075 12.6608 4.66079 12.6608H11.9956C12.2599 13.6508 13.185 14.3732 14.2423 14.3732C15.326 14.3732 16.2247 13.6508 16.5154 12.6608H18.3392C18.7093 12.6608 19 12.3665 19 11.9919C19 11.6173 18.7093 11.323 18.3392 11.323H16.5154C16.2247 10.3331 15.326 9.61066 14.2423 9.61066ZM14.2687 10.9484C14.8238 10.9484 15.2996 11.4033 15.2996 11.9919C15.2996 12.5806 14.8238 13.0354 14.2687 13.0354C13.7137 13.0354 13.2379 12.5806 13.2379 11.9919C13.2379 11.4033 13.6872 10.9484 14.2687 10.9484Z" fill="#319FEF"/>
        <path d="M9.45815 15.7596C8.40088 15.7596 7.5022 16.482 7.21145 17.472H4.66079C4.26432 17.472 4 17.7663 4 18.1409C4 18.5155 4.29075 18.8098 4.66079 18.8098H7.23789C7.5022 19.7997 8.42731 20.5222 9.48458 20.5222C10.5683 20.5222 11.467 19.7997 11.7577 18.8098H18.3392C18.7093 18.8098 19 18.5155 19 18.1409C19 17.7663 18.7093 17.472 18.3392 17.472H11.7313C11.4405 16.482 10.5419 15.7596 9.45815 15.7596ZM9.48458 17.0974C10.0396 17.0974 10.5154 17.5523 10.5154 18.1409C10.5154 18.7295 10.0396 19.1844 9.48458 19.1844C8.92951 19.1844 8.45374 18.7295 8.45374 18.1409C8.48018 17.5523 8.92951 17.0974 9.48458 17.0974Z" fill="#319FEF"/>
      </svg>
      <p class="ml4 fz14 text-dark fw500">{{selectedSort}}</p>
      <svg class="ml4" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.76496 4.69531C2.49769 4.69531 2.36384 5.01846 2.55283 5.20744L5.82857 8.48318C5.94573 8.60034 6.13568 8.60034 6.25283 8.48318L9.52856 5.20744C9.71755 5.01845 9.5837 4.69531 9.31643 4.69531H2.76496Z" fill="#2C2C2C"/>
      </svg>

    </div>
    <div class="list w88 d-flex flex-column pt4 pb4 box-border" v-if="show">
      <button class="h32 fz13 text-dark box-border pl12 pr4 d-flex align-items-center text-align-left" v-for="(item, i) in list" v-on:click="select(item)" :key="'sd'+i">{{item}}
        <svg class="ml-auto" v-if="item === selectedSort" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "location-filter-emails",
  props: {
    value: [String, Object, Array],
  },
  data() {
    return {
      selectedSort: 'All',
      list: ['All', 'Read', 'Unread'],
      show: false,
    }
  },
  methods: {
    select(item) {
      this.selectedSort = item;
      this.$emit('input', item)
      this.show = false
    },
    hide() {
      this.show = false
    }
  },
  mounted() {
    this.$emit('input', this.selectedSort)
  }
}
</script>

<style scoped lang="scss">
.select {
  svg {
    path {
      fill: var(--color-dark)
    }
  }
  &:hover {
    p {
      color: var(--color-blue) !important;
    }
    svg {
      path {
        fill: var(--color-blue)
      }
    }
  }
}
.list {
  right: 0;
  position: absolute;
  bottom: -2px;
  transform: translateY(100%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: var(--color-modal);
}
</style>