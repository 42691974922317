<template>
  <div class="wrap">
    <div class="top-panel" v-if="$route.path.indexOf('archive') === -1">
        <router-link :to="{name: 'Vessels Actual'}" class="navigation-button-payment fz16 fw500 text-gray" :class="{'navigation-button-payment-active': $route.path.indexOf('actual')>=0}">Actual Vessels</router-link>
        <router-link :to="{name: 'Vessels Redelivered'}" class="navigation-button-payment fz16 fw500 text-gray" :class="{'navigation-button-payment-active': $route.path.indexOf('redelivered')>=0}">Redelivered Vessels</router-link>
    </div>
    <div class="top-panel-nav" v-if="$route.path.indexOf('actual/archive') >= 0 || $route.path.indexOf('redelivered/archive') >= 0">
      <router-link :to="$route.path.indexOf('actual/archive') >= 0 ? '/vessels/actual' : '/vessels/redelivered'" class="bread__link">{{$route.path.indexOf('actual/archive') >= 0 ? 'Actual' : 'Redelivered'}} Vessels</router-link>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
      </svg>
      <span class="bread__link">Archive {{$route.path.indexOf('actual/archive') >= 0 ? 'Actual' : 'Redelivered'}} Vessels</span>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "vessels-view",
  components: {},
  computed: mapGetters(["getPermissionsByType", "getPermissionsForUser", "getPermissionsByType", "getContracts", "getShipsListTemplate", "getInfoContractManual", "getPermissionsForUserDeleteOrRenameFile", "getPermissionsForUser","getPermissionsForUserCanManualTask",
               "getPermissionsForUserCanAddFile","getPermissionsForUserCanEditTask","getTaskActivity",
               "getTaskInfo", "getTaskInfoConvert", "getAllTag", "getEmployees", "getFileForTask", "getKeyRenderTask", "getVesselTaskInfo"]),
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(["axiosGetAllContracts", "axiosCreateSearchShip", "getAxiosShipsTemplate", "getAxiosInfoContractManual", "axiosDeleteShip", "axiosGetClientsForDropdown", "axiosChangeVesselTask", "getAxiosInfoForVesselTask","axiosGetPermissionsForUserLogin", "axiosGetActivity", "axiosRenameTag","axiosDeleteTag","axiosAddFileToTask","axiosDeleteFile","changeAxiosFileForTask","axiosChangeTask", "getAxiosInfoForTask", "axiosGetPriorityTask", "getAxiosAllTag", "axiosGetEmployees", "getAxiosFileForTask"]),
    ...mapMutations(["setStatusOpenEditModelInDetail", "hideLoad", "showLoad", "clearInfoContractManual", "clearTaskInfo","clearTaskInfoConvert", "setIdTaskDuplicate", "conversionForTaskInfo", "cancelAxiosChangeTask", "addTip"]),

  },
  watch: {
    loadRequests: function () {
      if(this.loadRequests === 0) {
        this.hideLoad();
      } else {
        this.showLoad();
      }
    },
    getPermissionsForUser: function() {
      if(this.getPermissionsByType("show_vessels_section") === false) {
        this.$router.push('/employees')
      }
    },
  },
  mounted() {
    if(this.$route.path.indexOf('vessel') === -1) {
      this.setIdTaskDuplicate(null);
      if(this.getPermissionsForUser.length === 0) {
        this.loadRequests++;
        this.axiosGetPermissionsForUserLogin().then(()=> {
          if(this.getPermissionsForUserCanManualTask === false) {
            this.$router.push({name: "Tasks"})
          }
          this.loadRequests--;
        })
      } else {
        if(this.getPermissionsForUserCanManualTask === false) {
          this.$router.push({name: "Tasks"})
        }
      }

      let textRoute = this.$route.path;
      let idTask = textRoute.replace(/[^0-9]/gim,'');

      let info = false;
      if(this.$route.path.indexOf('vessel-task') >= 0) {
        this.loadRequests++;
        this.getAxiosInfoForVesselTask(idTask).then(()=> {
          this.loadRequests--;
        })
      }
      this.loadRequests++;
      this.getAxiosInfoForTask(idTask).then(()=> {
        this.loadRequests--;
        this.nameTask = this.getTaskInfo.name;
        this.descriptionTask = this.getTaskInfo.description;
        info = true
        if(info && priority && tag && employees) {
          this.loadingPage = true;
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        if(this.getTaskInfo.type === "CARGO_SEARCH_WITHOUT_SHIP") {
          this.permissionAssignName = "vessel";
        }
        if(this.getTaskInfo.type === "SHIP_SEARCH_WITHOUT_CARGO") {
          this.permissionAssignName = "vessel";
        }
        if(this.getTaskInfo.type === "SHIP_SEARCH_WITH_CARGO") {
          this.permissionAssignName = "cargo";
        }
        if(this.getTaskInfo.type === "MANUAL_WITH_CONTRACT") {
          this.permissionAssignName = "manual";
          this.loadRequests++;
          this.getAxiosInfoContractManual(this.getTaskInfo.id).then(()=> {
            this.loadRequests--;
          }).catch(()=> {
            this.loadRequests--;
          })
        }
        if(this.getTaskInfo.type === "CARGO_SEARCH_WITH_SHIP") {
          this.permissionAssignName = "cargo";
        }
      });
      let priority = false;
      this.loadRequests++;
      this.axiosGetPriorityTask().then(()=> {
        priority = true;
        if(info && priority && tag && employees) {
          this.loadingPage = true;
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        this.loadRequests--;
      }).catch(()=> {
        this.loadRequests--;
      })
      let tag = false;
      this.loadRequests++;
      this.getAxiosAllTag().then(()=> {
        tag = true;
        if(info && priority && tag && employees) {
          this.loadingPage = true;
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        this.loadRequests--;
      })
      let employees = false;
      this.loadRequests++;
      this.axiosGetEmployees().then(()=> {
        employees = true
        if(info && priority && tag && employees) {
          this.loadingPage = true;
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        this.loadRequests--;
      });

      this.loadRequests++;
      this.getAxiosFileForTask(idTask).then(()=> {
        this.loadRequests--;
      });
      this.loadRequests++;
      this.axiosGetActivity(idTask).then(()=> {
        this.loadRequests--;
      });
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_vessels_section") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
        next(from);
      }
    })
  },
}
</script>

<style scoped lang="scss">
  @import "vessels-view";
</style>