<template>
  <div class="wrap-page" :class="{'in-block': inBlock}">
    <div class="top-panel" v-if="!inBlock">
      <router-link :to="{name: 'Tasks'}" class="bread__link text-dark fw700 fz20" >Tasks</router-link>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <button class="bread__link text-dark fw700 fz20" v-on:click="isArchive=false">Edit checklists</button>
      <svg v-if="isArchive" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <button v-if="isArchive" class="bread__link text-dark fw700 fz20">Checklists archive</button>
    </div>
    <div class="top-panel" v-if="inBlock && blockName === 'createTask'">
      <button class="bread__link text-dark fw700 fz20" v-on:click="()=>{emitCloseModalTask(); hidePage()}" >Tasks</button>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <button class="bread__link text-dark fw700 fz20" v-on:click="hidePage">Create task</button>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <button class="bread__link text-dark fw700 fz20" v-on:click="isArchive=false">Edit checklists</button>
      <svg v-if="isArchive" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <button v-if="isArchive" class="bread__link text-dark fw700 fz20">Checklists archive</button>
    </div>
    <div class="top-panel" v-if="inBlock && blockName === 'detailTask'">
      <router-link :to="{name: 'Tasks'}" class="bread__link text-dark fw700 fz20" >Tasks</router-link>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <button v-on:click="hidePage" class="bread__link text-dark fw700 fz20">{{getTaskInfo ? getTaskInfo.name : ''}}</button>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <button class="bread__link text-dark fw700 fz20" v-on:click="isArchive=false">Edit checklists</button>
      <svg v-if="isArchive" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <button v-if="isArchive" class="bread__link text-dark fw700 fz20">Checklists archive</button>
    </div>
    <div class="main-content">
      <div class="block-lists">
        <checklist-edit-toggle-hide-list @closeDetail="openFormShow(null)" :active-id="detailChecklist && detailChecklist.id ? detailChecklist.id : -1" @open-detail="openFormShow" :is-basic="true" :is-archive="isArchive" :name="isArchive ? 'Archive of basic checklists' : 'Basic checklists'" @archive="isArchive = !isArchive"></checklist-edit-toggle-hide-list>
        <checklist-edit-toggle-hide-list @closeDetail="openFormShow(null)" :active-id="detailChecklist && detailChecklist.id ? detailChecklist.id : -1" @open-detail="openFormShow" :is-basic="false" :is-archive="isArchive" :name="isArchive ? 'Archive of personal checklists' :'Personal checklists'"></checklist-edit-toggle-hide-list>
      </div>
      <div class="block-checkboxes">
        <checklist-form-show @changeList="setNewChangedList" :key="keyRender" :isArchive="isArchive" :type="(detailChecklist && detailChecklist.is_basic) ? 'basic' : 'personal'" v-show="detailChecklist" :detail="detailChecklist"></checklist-form-show>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import ChecklistEditToggleHideList from "@/components/checklist/checklist-edit-toggle-hide-list";
import ChecklistFormShow from "@/components/checklist/checklistFormShow";

export default {
  name: "check-list-edit",
  components: {ChecklistFormShow, ChecklistEditToggleHideList},
  props: {
    inBlock: Boolean,
    blockName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['getTaskInfo']),
  },
  data() {
    return {
      isArchive: false,
      showCreateForm: true,
      typeCreate: 'basic',
      detailChecklist: null,
      keyRender: -23874623,
    }
  },
  methods: {
    ...mapMutations(['setStatusShowPageForCreateTask', 'emitCloseModalTask']),
    setNewChangedList() {
      // if(this.detailChecklist) {
      //   let list = this.list.find(a=> a.id === this.detailChecklist.id)
      //   if(list) {
      //     list.items = items
      //   }
      // }
    },
    hidePage() {
      this.setStatusShowPageForCreateTask(false)
    },
    openFormShow(detail) {
      this.detailChecklist = detail
    },
  },
  mounted() {
    // let vm = this;
    // if(this.inBlock) {
    //   addEventListener("popstate",function(e){
    //     e.preventDefault();
    //     vm.hidePage()
    //   },false);
    // }
  },

}
</script>

<style scoped lang="scss">
  .wrap-page {
    width: 100vw;
    height: calc(100vh - 56px);
    background-color: var(--color-bgc-page-white);
  }
  .top-panel {
    padding: 8px 24px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
  }
  .in-block {
    position: fixed;
    //width: 100vw;
    top: 56px;
    //height: calc(100vh - 56px);
    z-index: 99;
  }
  .main-content {
    height: calc(100vh - 56px - 40px);
    display: flex;
  }
  .block-lists {
    width: 528px;
    border-right: 1px solid var(--color-border);
  }
  .block-checkboxes {
    padding: 16px 24px;
    box-sizing: border-box;
    width: calc(100vw - 528px);
  }
  .block-lists, .block-checkboxes {
    box-sizing: border-box;
    overflow-y: auto;
  }
  .dart-mode {
    .top-panel {
      border-bottom: 1px solid #4D546D;
    }
    .block-lists {
      border-right: 1px solid #4D546D;
    }
  }
</style>