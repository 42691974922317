<template>
  <div>
    <div class="relative" v-for="(row, i) in rows" :key="i">
      <div class="lay_table__grid bg-white shadow">
        <div class="p-0 position-relative block-lay-date">
          <button v-on:click="onAddByIndex(i)" class="button-add-row flex align-items-center justify-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2221 11.2222H12.7778V6.77773C12.7778 6.34849 12.4293 6 11.9999 6C11.5707 6 11.2222 6.34849 11.2222 6.77773V11.2222H6.77773C6.34849 11.2222 6 11.5707 6 11.9999C6 12.4293 6.34849 12.7778 6.77773 12.7778H11.2222V17.2221C11.2222 17.6515 11.5707 18 11.9999 18C12.4293 18 12.7778 17.6515 12.7778 17.2221V12.7778H17.2221C17.6515 12.7778 18 12.4293 18 11.9999C18 11.5707 17.6515 11.2222 17.2221 11.2222Z" fill="#9AA3B0"/>
            </svg>
          </button>
          <date-picker
            format="DD.MM.YYYY"
            value-type="format"
            type="date"
            placeholder="dd.mm.yyyy"
            v-model="row.date"
            @change="changeDate(row); changeDataList(i)"
          ></date-picker>
        </div>
        <div class="uppercase">
          {{row.day}}
        </div>

        <div class="col-3-5 p-0">
          <div class="flex items-center justify-center w-1/2 border-r border-gray-dark box-border self-stretch"><input @focus="(e)=>{selectAll(e, row.from_date)}" @focusout="focusoutFromTime(row.from_date, i)" v-on:keydown.enter="focusOutEnter" class="border-0 h-auto p-y-1 bg-transparent" type="text" placeholder="hh:mm" ref="timeInputs" :value="row.from_date" @change="checkFromTime($event, row)"></div>
          <div class="flex items-center justify-center w-1/2 self-stretch box-border"><input @focus="(e)=>{selectAll(e, row.to_date)}" @focusout="checkAddNewRow(row.to_date, i)" v-on:keydown.enter="(e)=>{focusOutEnter(e, i)}" class="border-0 h-auto p-y-1 bg-transparent" type="text" placeholder="hh:mm" ref="timeInputs" :value="row.to_date" @change="checkToTime($event, row)"></div>
        </div>
<!--        checkToTime($event, row);checkAddNewRow(row.to_date, i)-->

        <div class="col-5-8 p-0">
          <div class="flex items-center justify-center p-1 w-1/3 box-border self-stretch fw500">{{!row.remarks || row.remarks == 'na' ? getTimeUsed(row, 'days') : ''}}</div>
          <div class="flex items-center justify-center p-1 w-1/3 border-l border-gray-dark self-stretch box-border fw500">{{!row.remarks || row.remarks == 'na' ? getTimeUsed(row, 'hours') : ''}}</div>
          <div class="flex items-center justify-center p-1 w-1/3 border-l border-gray-dark self-stretch box-border fw500">{{!row.remarks || row.remarks == 'na' ? getTimeUsed(row, 'minutes') : ''}}</div>
        </div>
        <div class="p-0">
          <lay-remark :row="row"/>
        </div>
<!--        <div class="">{{row.time_count = (!row.remarks || row.remarks == 'na') && (row.from_date && row.to_date) ? 1 : null}}</div>-->
        <div class="percent-right">
          <input type="text" class="border-0 h-auto p-y-1 bg-transparent" @input="row.time_count = checkNum(row.time_count, 4)" @focusout="row.time_count = checkEmptyPercent(row.time_count)" v-model="row.time_count">
        </div>
        <div class="">
          <div v-if="getEqual(row)">
            {{getEqual(row) | formatNumberFive}}
          </div>
        </div>
        <div class="">{{checkOnDem(i)}}</div>
        <div class="">{{getTimeInRow(row)}}</div>
        <div class="">{{getDaysSumm(i) | formatNumberTwo}}</div>
      </div>

      <svg class="lay_table__delete_row" @click="onDelete(i)" width="24" height="24" viewBox="0 0 24 24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"/></svg>
    </div>

    <button class="button-blue-border w-full p-x-6 fw500 fz13 h32" @click="onAdd">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="#094172"></path></svg>
      Add new section
    </button>

    <modal-slots
      :showMod="isShowModalDelete"
      :isBorder="false"
      textForSecondButton="Delete"
      @apply="deteteBlockAfterModal"
      @close="isShowModalDelete = false"
    >
      <template v-slot:title>
        <h2 class="fw500">LayTime</h2>
      </template>

      <p class="m-b-4 p-x-6 fz14">Are you sure you want to delete this row?</p>
    </modal-slots>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import Inputmask from "inputmask"
import moment from "moment"

import layRemark from '@/components/lay/edit/layRemark';
import modalSlots from '@/components/modal/modal-slots'
import { mapGetters } from 'vuex';

export default {
  props: {
    rows: Array,
    arrived_time: String,
    arrived_date: String
  },
  components: {
    DatePicker,
    layRemark,
    modalSlots
  },
  data() {
    return {
      isShowModalDelete: false,
      deleteType: null,
      valueBeforeChange: '',
      rootRow: {
        date: '',
        day: '',
        from_date: '',
        to_date: '',
        remarks: 'na',
        remark_info: '',
        is_remark: true,
        time_count: 100,
        equal_days: 0,
        check_if: 0,
        time: '',
        days_qty: 0,
      },
    }
  },
  watch: {
    rows: function() {
      this.rows.forEach(item=> {
        if(''+item.time_count === '1') {
          item.time_count = 100;
        }
        if(!item.time_count || item.time_count === null || item.time_count === '') {
          item.time_count = 100;
        }
      })
      this.addInputMask()
    }
  },
  computed: {
    ...mapGetters(['getTimeAllowed'])
  },
  methods: {
    changeDataList(index) {
      this.$emit('changeDataList', index)
    },
    focusOutEnter(e, index) {
      e.target.blur()

        setTimeout(()=> {
          this.$refs['timeInputs'][(index+1)*2 + 1].focus()
        }, 300)
    },
    async checkAddNewRow(to_date, index) {
      if(index === this.rows.length - 1 && to_date) {
        await this.onAdd()
      } else if(this.valueBeforeChange !== to_date && to_date) {
        this.rows[index+1].from_date = to_date === '24:00' ? '00:00' : to_date;
        this.changeDataList(index)
      }
    },
    focusoutFromTime(from_date, index) {
      if(from_date && index !== 0 && from_date !== this.valueBeforeChange) {
        this.rows[index - 1].to_date = from_date === '00:00' ? '24:00' : from_date
        this.changeDataList(index)
        // if(this.rows[index - 1].date) {
        //   this.rows[index].date = this.rows[index-1].date ? moment(this.rows[index-1].date, 'DD.MM.YYYY').add(1, 'days').format('DD.MM.YYYY') : null
        // }
        // if(this.valueBeforeChange === '00:00' && from_date && from_date !== '00:00') {
        //   if(this.rows[index - 1].date) {
        //     this.rows[index].date = this.rows[index - 1].date
        //   }
        // }
        // if(from_date === '00:00') {
        //   this.rows.forEach((item, i)=> {
        //     if(i >= index && i>0 ) {
        //       if(i > index ) {
        //         if(i === index && (item.date === this.rows[i-1].date) || i > index) {
        //           item.date = moment(item.date, 'DD.MM.YYYY').add(1, 'days').format('DD.MM.YYYY')
        //         }
        //       }
        //     }
        //   })
        // } else if(this.valueBeforeChange === '00:00' && from_date !== '00:00') {
        //   this.rows.forEach((item, i)=> {
        //     if(i >= index && i>0 ) {
        //       // if(moment(item.date).isBefore(this.rows[i-1].date, 'day') === true) {
        //       item.date = moment(item.date, 'DD.MM.YYYY').add(-1, 'days').format('DD.MM.YYYY')
        //       // }
        //     }
        //   })
        // }
      }
    },
    checkNum(num) {
      if(num.indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");

        if(num2.length > 4) {
          num2 = num2.substring(0, 4);
        }
        num = num1 + "." + num2;
      } else {
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    checkEmptyPercent(num) {
      if(!num.length) {
        return 100
      }
      return num
    },
    selectAll(e, value) {
      this.valueBeforeChange = value
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },
    onDelete(type) {
      this.deleteType = type
      this.isShowModalDelete = true
    },
    deteteBlockAfterModal() {
      this.rows.splice(this.deleteType,1)
      if(this.deleteType !== 0) {
        if(this.rows[this.deleteType - 1].to_date) {
          this.rows[this.deleteType].from_date = this.rows[this.deleteType - 1].to_date
        } else {
          this.rows[this.deleteType - 1].to_date = this.rows[this.deleteType].from_date
        }
      }
      this.changeDataList(0)
      this.deleteType = null
    },
    async onAddByIndex(index) {
      this.rows.splice(index, 0, (JSON.parse(JSON.stringify(this.rootRow))));
      await this.$nextTick()
      this.addInputMask()

      if(this.rows.length == 1){
        // console.log('lol', this.arrived_time, this.arrived_date);
        if(this.arrived_date){
          this.rows[0].date = this.arrived_date
        }
        if(this.arrived_time){
          this.rows[0].from_date = this.arrived_time
        }
      } else if(this.rows.length > 1){ // change next row time and date
        if(index !== 0 && this.rows[index-1].to_date){
          this.rows[index].from_date = this.rows[index-1].to_date == '24:00' ? '00:00' : this.rows[index-1].to_date
        }
        if(this.rows.length !== index+1){
          this.rows[index + 1].from_date = null
        }

        if(index !== 0 && this.rows[index-1].date){
          let date = this.rows[index-1].date
          if(this.rows[index-1].to_date == '24:00'){
            date = moment(date, 'DD.MM.YYYY').add(1, 'days').format('DD.MM.YYYY')
          }
          this.rows[index].date = date
          this.changeDate(this.rows[index])
        } else if(index === 0) {
          let date = this.rows[index+1].date
          this.rows[index].date = date
          this.changeDate(this.rows[index])
        }
      }
    },
    async onAdd() {
      this.rows.push(JSON.parse(JSON.stringify(this.rootRow)))
      await this.$nextTick()
      this.addInputMask()

      if(this.rows.length == 1){
        // console.log('lol', this.arrived_time, this.arrived_date);
        if(this.arrived_date){
          this.rows[0].date = this.arrived_date
        }
        if(this.arrived_time){
          this.rows[0].from_date = this.arrived_time
        }
        this.changeDate( this.rows[0])
      } else if(this.rows.length > 1){ // change next row time and date
        if(this.rows[this.rows.length-2].to_date){
          this.rows[this.rows.length-1].from_date = this.rows[this.rows.length-2].to_date == '24:00' ? '00:00' : this.rows[this.rows.length-2].to_date
        }else if(this.rows[this.rows.length-2].from_date){
          this.rows[this.rows.length-1].from_date = this.rows[this.rows.length-2].from_date == '24:00' ? '00:00' : this.rows[this.rows.length-2].from_date
        }

        if(this.rows[this.rows.length-2].date){
          let date = this.rows[this.rows.length-2].date
          if(this.rows[this.rows.length-2].to_date == '24:00'){
            date = moment(date, 'DD.MM.YYYY').add(1, 'days').format('DD.MM.YYYY')
          }
          this.rows[this.rows.length-1].date = date
          this.changeDate(this.rows[this.rows.length-1])
        }
      }


    },
    async addInputMask() {
      await this.$nextTick()
      if(this.$refs.timeInputs){
        this.$refs.timeInputs.forEach(el=>{
          if (!el.inputmask)
            Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-4]):([0-5][0-9])", "clearIncomplete": true }).mask(el)
        })
      }
    },
    changeDate(row) {
      if(!row.date) {
        row.day = ''
        return false
      }
      row.day = moment(row.date, 'DD.MM.YYYY').format('ddd')
    },
    getTimeUsed(row, type) {
      if(row.from_date && row.to_date){
        let from = moment(row.from_date, 'hh:mm')
        let to = moment(row.to_date, 'hh:mm')
        let diff = to.diff(from, type)

        if(type == 'hours')
          return diff - (to.diff(from, 'days') * 24)
        else if(type == 'minutes')
          return diff - (to.diff(from, 'hours') * 60)
        else
          return diff
      }else{
        return ''
      }
    },
    checkFromTime(e, row) {
      let value = e.target.value === '24:00' ? '23:59' : e.target.value
      if(value && !moment(value, 'hh:mm').isValid()){
        row.from_date = '23:59'
        e.target.value = '23:59'
      }else row.from_date = value
    },
    checkToTime(e, row) {
      let value = e.target.value
      if(value && !moment(value, 'hh:mm').isValid()){
        row.to_date = '24:00'
        e.target.value = '24:00'
      }else row.to_date = value
    },
    getEqual(row) {
      let result = 0
      if((!row.remarks || row.remarks == 'na') && (row.from_date && row.to_date)){
        if(this.getTimeUsed(row, 'days')){
          result = 1 * (parseFloat(row.time_count)/100)
        }else{
          let equal = 0
          equal += this.getTimeUsed(row, 'hours') * 3600 // hours to seconds
          equal += this.getTimeUsed(row, 'minutes') * 60 // minutes to seconds
          result = equal / 86400 // timevalue
          result = result * (parseFloat(row.time_count)/100)
        }
      }
      result = (Math.round(result*10000000))/10000000
      row.equal_days = result
      return result
    },
    checkOnDem(index) {
      let summ = 0

      for (let i = 0; i < 1+index; i++) {
        let row = this.rows[i];
        if((!row.remarks || row.remarks == 'na') && (row.from_date && row.to_date))
          summ += row.equal_days
        row.check_if = summ
      }
      if(this.getTimeAllowed > summ) return ':('
      else return 'on dem'
    },
    getTimeInRow(row) {
      let result = '00:00'
      if(row.from_date && row.to_date){
        if(this.getTimeUsed(row, 'days')){
          result = '24:00'
        }else{
          let h = this.getTimeUsed(row, 'hours')
          let m = this.getTimeUsed(row, 'minutes')
          if(h.toString().length == 1) h = '0'+h
          if(m.toString().length == 1) m = '0'+m
          result = h+':'+m
        }

      }
      row.time = result
      return result
    },
    getDaysSumm(index) {
      let days = 0
      for (let i = 0; i < 1+index; i++) {
        let row = this.rows[i];
        if(row.from_date && row.to_date){
          let from = moment(row.from_date, 'hh:mm')
          let to = moment(row.to_date, 'hh:mm')
          let diff = to.diff(from, 'days', true)
          days += diff
        }
      }
      this.rows[index].days_qty = Math.floor(days)
      return Math.floor(days)
    }
  },
  async mounted() {
    await this.$nextTick()
    this.addInputMask()
  }
}
</script>

<style lang="scss">
.lay_table__delete_row{
  position: absolute;
  top: 50%;
  left: calc(100% + 0.25rem);
  transform: translateY(-50%);
  cursor: pointer;
  &:hover{
    fill: var(--color-blue-dark);
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 1611px) {
  .lay_table__grid {
    grid-template-columns: 160px repeat(3, 6.38%) repeat(3, 3.59%) calc(37.79% - 160px) repeat(5, 6.46%);
  }
}
//@media (max-width: 1420px) {
//  .lay_table__grid {
//    grid-template-columns: 11.64% repeat(3, 6.38%) repeat(3, 3.59%) 26.15% repeat(5, 6.46%);
//  }
//}
</style>
<style lang="scss" scoped>
  .button-blue-border {
    background-color: var(--color-bgc-page-white);
    color: var(--color-blue-dark) !important;
    svg {
      path {
        fill: var(--color-blue-dark) !important
      }
    }
  }
  .button-add-row {
    //position: absolute;
    //left: 0;
    //top: 0;
    height: 34px;
    width: 34px;
    border-right: 1px solid var(--color-gray-dark);
  }
  .block-lay-date {
    &>*:nth-child(2) {
      width: calc(100% - 34px);
    }
  }
</style>