<template>
  <div class="counterparties-edit-types financial-statement-edit-types">
    <button class="counterparties-edit-types_btn" @click="isEditOpen = true">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9815 1.02666C12.3235 0.365661 11.4509 0 10.5175 0C9.58888 0 8.71156 0.365661 8.05357 1.02666L0.846338 8.276C0.743673 8.37914 0.683007 8.5104 0.659674 8.65104L0.00634799 13.2499C-0.0216517 13.4515 0.043681 13.6578 0.188346 13.8031C0.309678 13.9297 0.482343 14 0.655007 14C0.683007 14 0.70634 14 0.73434 13.9953L5.38695 13.414C5.53162 13.3953 5.66695 13.3296 5.76962 13.2265L12.9815 5.97246C14.3395 4.60357 14.3395 2.39086 12.9815 1.02666ZM5.00429 12.1342L1.42033 12.5795L1.92433 9.04952L7.80157 3.13624L10.8769 6.22561L5.00429 12.1342ZM12.0622 5.04893L11.8055 5.30677L8.73023 2.2174L8.98689 1.95957C9.39289 1.53765 9.93888 1.31263 10.5222 1.31263C11.1009 1.31263 11.6515 1.53765 12.0622 1.95488C12.4728 2.37211 12.7015 2.91591 12.7015 3.5019C12.7015 4.08321 12.4775 4.63639 12.0622 5.04893Z" fill="#094172"/></svg>
      Edit or Create FS
    </button>

    <div class="counterparties-edit-types_form" v-if="isEditOpen">
      <!-- <div class="counterparties-edit-types_form__bg" @click="isEditOpen = false"></div> -->
      <div class="counterparties-edit-types_form__bg"></div>

      <div class="counterparties-edit-types_form__content">
        <h3 class="counterparties-edit-types_form__title">
          <button class="counterparties-edit-types_form__close" v-if="!isArchiveOpen" @click="isEditOpen = false">close</button>

          <button class="counterparties-edit-types_form__back" v-else @click="isArchiveOpen = false"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.25 11.2003H6.28787L11.5 6.37938C11.8 6.07458 11.8116 5.56819 11.5259 5.24819C11.2405 4.9286 10.7657 4.9158 10.4654 5.22059L4.4395 10.8687C4.15637 11.1711 4 11.5727 4 12.0003C4 12.4275 4.15637 12.8295 4.45262 13.1451L10.4654 18.6584C10.6105 18.806 10.7965 18.8792 10.9825 18.8792C11.1805 18.8792 11.3785 18.796 11.5259 18.6308C11.8116 18.3108 11.8 17.8048 11.5 17.5L6.26425 12.8003H19.25C19.664 12.8003 20 12.4419 20 12.0003C20 11.5587 19.664 11.2003 19.25 11.2003Z" fill="#2C2C2C"/></svg></button>
          Edit Financial Statements
        </h3>

        <div class="financial-statement-edit-types_btns" v-if="!isArchiveOpen">
          <button class="counterparties-edit-types_form__add button-blue-border fw500 fz13" @click="openModalAdd()" v-if="getPermissionsByType('create_new_fs')">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>
            Create new FS
          </button>

          <button class="archive-link svg-white-dart-stroke text-dark fw500 fz13" @click="isArchiveOpen = true">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            Archive FS
          </button>
        </div>

        <div class="financial-statement-edit-types_block" v-if="isArchiveOpen">
          <div class="financial-statement-edit-types_block__row" v-if="!financialStatementArchive.length">
            <div class="financial-statement-edit-types_block__row-title">Archive is empty</div>
          </div>

          <div class="financial-statement-edit-types_block__row" v-for="fs in financialStatementArchive" :key="fs.name" v-else>
            <div class="financial-statement-edit-types_block__row-title text-uppercase">{{fs.name}}</div>
            <div class="financial-statement-edit-types_block__row-del" v-if="getPermissionsByType('unarchive_fs')"><svg @click="openModalUnarchive(fs)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78522L6.71267 7.00234L9.01736 4.21946C9.28441 3.91342 9.25326 3.44869 8.94722 3.1814C8.64119 2.91435 8.1767 2.94575 7.90916 3.25154L5.18172 6.51849C4.93943 6.79559 4.93943 7.20931 5.18172 7.48641L7.9092 10.7534C8.05485 10.9196 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78522Z" fill="#9AA3B0"/><path d="M13.6538 6.26657H5.73568C5.32934 6.26657 5 6.59588 5 7.00225C5 7.40859 5.32934 7.73793 5.73568 7.73793H13.6538C15.7904 7.73793 17.5286 9.6013 17.5286 11.9795C17.5286 14.3576 15.7904 16.4871 13.6538 16.4871H6.71661C6.31027 16.4871 5.98093 16.8165 5.98093 17.2228C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.2129 19 11.9795C19 8.74599 16.6017 6.26657 13.6538 6.26657Z" fill="#9AA3B0"/></svg></div>
          </div>
        </div>

        <div class="financial-statement-edit-types_block" v-for="type in fsType" :key="type.type" v-else>
          <div class="financial-statement-edit-types_block__title text-uppercase">{{type.name}}</div>
          <div class="financial-statement-edit-types_block__row" v-for="fs in getFSFromType(type.type)" :key="fs.name">
            <div class="financial-statement-edit-types_block__row-title text-uppercase" @click="openModalAdd(fs)">{{fs.name}}</div>
            <div class="financial-statement-edit-types_block__row-del" v-if="getPermissionsByType('archive_fs')"><svg @click="openModalArchive(fs)" width="24" height="24" viewBox="0 0 24 24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"/></svg></div>
          </div>
        </div>

      </div>

      <modal-add :data="account" v-if="isModalAddOpen" @close="isModalAddOpen = false"></modal-add>
      <modal-del :data="account" v-if="isModalDelOpen" @close="isModalDelOpen = false"></modal-del>
      <modal-unarchive :data="account" v-if="isModalUnarchiveOpen" @close="isModalUnarchiveOpen = false"></modal-unarchive>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import modalAdd from '@/components/accounts/trials/fsModalAdd'
import modalDel from '@/components/accounts/trials/fsModalDel'
import modalUnarchive from '@/components/accounts/trials/fsModalUnarchive'

export default {
  components: {
    modalAdd,
    modalDel,
    modalUnarchive
  },
  data() {
    return {
      isEditOpen: false, // right modal-block
      isArchiveOpen: false,
      isModalAddOpen: false,
      isModalDelOpen: false,
      isModalUnarchiveOpen: false,
      account: null,
    }
  },
  computed: {
    ...mapState({
      financialStatement: state => state.trialBalance.financialStatement,
      financialStatementArchive: state => state.trialBalance.financialStatementArchive,
      fsType: state => state.trialBalance.fsType
    }),
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    ...mapActions(['axiosGetsetFinancialStatementArchivelist']),
    openModalAdd(account) {
      if(account) {
        if(!this.getPermissionsByType('edit_fs')) return false
        this.account = account
      }else{
        this.account = null
      }
      this.isModalAddOpen = true
    },
    openModalArchive(account) {
      this.account = account
      this.isModalDelOpen = true
    },
    openModalUnarchive(account) {
      this.account = account
      this.isModalUnarchiveOpen = true
    },
    getFSFromType(type) {
      return this.financialStatement.filter(el => {
        return el.type == type
      })
    },
  },
  async mounted() {
    if(!this.financialStatementArchive.length)
      await this.axiosGetsetFinancialStatementArchivelist()
  }
}
</script>

<style lang="scss">
  .financial-statement-edit-types{
    .counterparties-edit-types_btn{
      margin-top: 11px;
    }
    &_block{
      margin-bottom: 9px;
      &__title{
        margin-bottom: 4px;
        padding-bottom: 6px;
        font-weight: 500;
        font-size: 14px;
        color: #9AA3B0;
        border-bottom: 1px solid #9AA3B0;
      }
      &__row{
        display: flex;
        align-items: center;
        &-title{
          width: 100%;
          padding: 9px 8px;
          font-size: 14px;
          border-radius: 4px;
          cursor: pointer;
          &:hover{
            background-color: var(--color-input);
            color: #094172;
          }
        }
        &-del{
          font-size: 0;
          flex-shrink: 0;
          margin-left: 12px;
          svg{
            border-radius: 4px;
            cursor: pointer;
            &:hover{
              background-color: var(--color-input);
              fill: #094172;
            }
          }
        }
      }
    }
  }
  .financial-statement-edit-types_btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19px;
    .counterparties-edit-types_form__add{
      display: inline-flex;
      width: 68%;
    }
    .archive-link{
      display: inline-flex;
      align-items: center;
    }
  }
  .counterparties-edit-types_form__back{
    position: relative;
    top: -6px;
    height: 15px;
    margin-right: 12px;
  }
</style>