<template>
  <div class="w-full flex">
    <router-view class="w-full p-x-6"/>
  </div>
</template>

<script>
export default {
  computed: {
  },
  methods: {
  },
  beforeMount() {
  }
}
</script>

<style lang="scss">

</style>