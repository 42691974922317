<template>
  <div class="w320 h100vh bgc-white position-fixed l0 t56 wrap-modal overflow-y-auto">
    <div class="top-panel box-border border-bottom h48 pl16 pb15 pt15 d-flex align-items-center">
      <p class="fz16 fw500 text-dark">Add Vessel Location</p>
      <svg v-on:click="$emit('close')" class="position-absolute r12 cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#2C2C2C"/>
        <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#2C2C2C"/>
      </svg>

    </div>
    <div class="content-modal w100Proc p16 pb60 box-border ">
      <p class="fz14 fw500 text-dark">Vessel Name <span class="text-red">*</span></p>
      <input type="text" class="h32 w100Proc mt4" :class="{'border-red': errorName}" v-model="name">
      <p class="fz14 mt16 fw500 text-dark">DWT</p>
      <div class="mt4 position-relative d-flex align-items-center">
        <input @input="dwt = checkNum(dwt)" v-model="dwt" type="text" class="h32 w100Proc pr40">
        <span class="text-gray fz13 r8 position-absolute">MT</span>
      </div>
      <p class="fz14 mt16 fw500 text-dark">Built</p>
      <input type="text" class="h32 w100Proc mt4" @input="built = checkNum(built)">
      <p class="fz14 mt16 fw500 text-dark">Position</p>
      <input type="text" class="h32 w100Proc mt4" v-model="position">
      <p class="fz14 mt16 fw500 text-dark">Zone</p>
      <location-drop-zones class="mt4" v-model="zone"></location-drop-zones>
      <p class="fz14 mt16 fw500 text-dark">Laycan</p>
      <div class="double-input d-flex align-items-center justify-between mt4">
        <date-picker
            v-model="laycan_from"
            type="date"
            lang="en"
            placeholder=""
            format="DD MMM YYYY"
            value-type="date"
            class="datepick"
        ></date-picker>
        <date-picker
            v-model="laycan_to"
            type="date"
            lang="en"
            placeholder=""
            format="DD MMM YYYY"
            value-type="date"
            class="datepick"

        ></date-picker>
      </div>

    </div>
    <div class="bottom-panel p8-16px box-border w320 d-flex justify-end align-items-center border-top position-fixed b0 l0 bgc-white">
      <button class="blue-button-text mr55 fz14 fw500" v-on:click="$emit('close')">Cancel</button>
      <button class="blue-button w140 h32 fz14 fw500" v-on:click="save">Save</button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import LocationDropZones from "@/views/location/location-drop-zones";
import {mapActions, mapMutations} from "vuex";
import moment from 'moment';

export default {
  name: "add-vessel-location-modal",
  components: {LocationDropZones, DatePicker},
  props: {
    idMessage: null,
  },
  data() {
    return {
      laycan_from: null,
      laycan_to: null,
      zone: '',
      dwt: null,
      built: null,
      name: '',
      position: '',
      errorName: false,
    }
  },
  methods: {
    ...mapActions(['axiosCreateVesselLocation']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    checkNum(num) {
      num = num.replace(/[^0-9]/g, "");
      return num;
    },
    getIdZone(name) {
      let regions = [
        {name: 'NOPAC', id: 13},
        {name: 'Far East', id: 12},
        {name: 'ECSA', id: 11},
        {name: 'USG-NCSA', id: 10},
        {name: 'USEC', id: 9},
        {name: 'WC America', id: 8},
        {name: 'AUSSIE', id: 7},
        {name: 'SE Asia', id: 6},
        {name: 'Red Sea', id: 5},
        {name: 'Indian Ocean', id: 4},
        {name: 'WAFR', id: 3},
        {name: 'Baltic - Continent', id: 2},
        {name: 'Med-Bsea', id: 1},
      ]
      let item = regions.find(a=> a.name === name)
      return item ? item.id : null
    },
    async save() {
      this.name = this.name.trim()
      if(!this.name.length) {
        this.errorName = true
        return null;
      } else {
        this.errorName = false
      }
      this.showLoad()
      let res = await this.axiosCreateVesselLocation({
        name: this.name,
        dwt: this.dwt ? this.dwt : null,
        build: this.built ? this.built : null,
        position: this.position ? this.position : '',
        zone: this.zone ? this.getIdZone(this.zone) : null,
        date_from: this.laycan_from ? moment(this.laycan_from).format('YYYY-MM-DD') : null,
        date_to: this.laycan_to ? moment(this.laycan_to).format('YYYY-MM-DD') : null,
        message_id: this.idMessage
      })
      this.hideLoad()
      if(res) {
        this.addTip('Vessel successfully added to the list')
        this.$emit('close')
      } else {
        this.addTip('Error')
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .wrap-modal {
    //&:hover {
    //  opacity: 1;
    //}
    //opacity: 0.6;
    z-index: 99;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  }
  .double-input {
    &>* {
      width: calc(50% - 2px);
    }
  }
  .h100vh {
    height: calc(100vh - 56px);
  }
  .datepick {
    /deep/ {
      input {
        background-color: var(--color-input);
      }
    }
  }
</style>