<template>
  <div>
    <folders-list-links class="mt16" :items="list"></folders-list-links>
  </div>
</template>

<script>
import FoldersListLinks from "@/components/folders/folders-list-links";
import {mapActions, mapMutations} from "vuex";
export default {
  name: "folders-ports-countries-options-berth-list",
  components: {FoldersListLinks},
  data() {
    return {
      list: []
    }
  },
  props: {
    search: String,
    sort: String,
    routeChange: Number,
  },
  watch: {
    search: function () {
      this.loadData()
    },
    sort: function () {
      this.loadData()
    },
    routeChange: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions(['axiosGetFoldersForPort']),
    ...mapMutations(['setNameSectionFolders']),
    async loadData() {
      let res = await this.axiosGetFoldersForPort({typeFile: false, type: 'berths', id: this.$route.params.portDetail ? JSON.parse(this.$route.params.portDetail).id : null, params: {is_archive_files: this.$route.params.is_archive_files, ordering: this.sort, search: this.search}})
      this.list = res.map(item=> {
        return {
          name: item.name,
          last_updated: item.updated_at,
          link: {name: 'Folders - Ports Countries Ports Options Berth List Detail', params: {...this.$route.params, detailList: JSON.stringify({id: item.id, name: item.name}), is_archive_files: false}}
        }
      })
    }
  },
  mounted() {
    this.setNameSectionFolders('Ports')
    this.loadData()
  }
}
</script>

<style scoped>

</style>