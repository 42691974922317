
<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">Archive Bank and Cash Account <button class="modal-close" @click="closeModal()"></button></p>

        <p class="modal-content_descr">Are you sure you want to archive the <span class="capitalize">{{bank.type}}</span> <b>{{bank.name}}</b>?</p>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal">Cancel</button>
        <button class="btn btn-next" @click="onSubmit">Archive</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['bank'],
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      this.$emit('submit', [this.bank.id])
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-wrap{
  .modal-content{
    max-width: 424px;
    .modal-content_bottom{
      border-top: none;
    }
    .capitalize{
      text-transform: capitalize;
    }
    b{
      word-break: break-all;
    }
  }
}
</style>