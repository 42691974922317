<template>
  <b-dropdown left ref="dropdown" class="wrap-assignee" :class="{'show-active' : show, 'show-not-active' : !show}" @show="showDropdown" @hide="hideDropDown" >
    <template #button-content>
      <button class="button-drop" :class="{'active-in': show && showContentIf(employees)}">
        <div class="block-avatar-user" v-if="employeesSelect.length">
            <div class="wrap" v-for="(e, i) in employeesSelect" :key="i" :style="'z-index:' + (employeesSelect.length - i)">
              <div class=" avatar-user" v-if="i<=11">
                <img v-if="e.avatar !== null" :src="e.avatar" class="img-profile" alt="">
                <svg v-if="e.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="80" cy="80" r="80" fill="white"/>
                  <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                </svg>
              </div>
            </div>
          <p v-if="employeesSelect.length === 1" class="nameuser fz13 text-dark1">{{employeesSelect[0].first_name}} {{employeesSelect[0].last_name}}</p>
          <div class="more-avatar fz11 text-dark1" v-if="employeesSelect.length >= 13">
            +{{employeesSelect.length - 12}}
          </div>
        </div>
        <p v-if="employeesSelect.length === 0" class="fz14 text-gray-light">Search Employees</p>
        <svg v-if="showContentIf(employees)" class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/>
        </svg>
      </button>
    </template>
    <div class="content" v-show="showContentIf(employees)">
      <input type="text" :ref="'searchTextInput'+idref" class="search-emp" placeholder="Search Employees" v-model="searchText" v-show="show">
      <p class="fz13 text-gray" v-if="!showContentIf(getFilterSearch())">List Empty</p>
      <div v-for="(d, i) in getFilterSearch()" :key="i" class="block">
        <p class="fz13 fw500 text-gray title-department" v-if="d.array.length !== 0">{{ d.title }}</p>
        <div class="wrap-cards" v-if="d.array.length !== 0">
          <div class="tableView">
            <div class="content-card" :class="{'active-card': employeesSelect.map(a=> {return a.id}).indexOf(emp.id) >= 0}" v-for="(emp, j) in d.array" :key="j" v-on:click="changeStatusEmployees(i,j)">
              <img v-if="emp.avatar !== null" :src="emp.avatar" class="img-profile" alt="">
              <svg v-if="emp.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="80" cy="80" r="80" fill="white"/>
                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
              </svg>
              <p class="name fz13 text-dark1 fw500">
                {{emp.first_name}} {{ emp.last_name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  computed: mapGetters(["getPermissionsFullByType", "getPermissionsForUserCanAssignTask","getPermissionsForUserCanSeeTask", "getEmployeesSortByDepartments"]),
  props: ["oneUser", "defaultActive", "permissionAssigned"],
  name: "assignee-task",
  data() {
    return {
      show: false,
      employees: [],
      employeesCopy: [],
      employeesSelect: [],
      searchText: "",
      arrayPermission: {},
      idref: null,
    }
  },
  watch: {
    permissionAssigned: function () {
      if(this.permissionAssigned === 'vessel') {
        this.arrayPermission = this.getPermissionsFullByType("assign_voyage_contracts_to")
      } else if(this.permissionAssigned === 'vesselTask' || this.permissionAssigned === 'cargoTask') {
        this.arrayPermission = this.getPermissionsFullByType("assign_tasks_to");
      } else if(this.permissionAssigned === 'cargo') {
        this.arrayPermission = this.getPermissionsFullByType("assign_sublet_contracts_to");
      } else if(this.permissionAssigned === 'contract' || this.permissionAssigned === 'ship') {
        this.arrayPermission = this.getPermissionsFullByType("assign_contracts_to");
      } else if(this.permissionAssigned === 'voyage'){
        this.arrayPermission = this.getPermissionsFullByType("assign_voyage_contracts_to");
      } else if(this.permissionAssigned === 'sublet'){
        this.arrayPermission = this.getPermissionsFullByType("assign_sublet_contracts_to");
      } else {
        this.arrayPermission = this.getPermissionsForUserCanAssignTask;
      }
      if(this.defaultActive) {
        if(Array.isArray(this.defaultActive) === true) {
          this.defaultActive.forEach(item => {
            if(!(this.employeesSelect.find(a=> a.id === item.id))) {
              this.employeesSelect.push(item)
            }
          })
        } else {
          if(!(this.employeesSelect.find(a=> a.id === this.defaultActive.id))) {
            this.employeesSelect.push(this.defaultActive)
          }
        }
      }
      // if(this.defaultActive) {
      //   let bool = false;
      //   this.employees.map(a=> {
      //     a.array.map(b=> {
      //       if(Array.isArray(this.defaultActive)) {
      //         this.defaultActive.map(c=> {
      //           if(b.id === c.id) {
      //             bool = true;
      //           }
      //         })
      //       } else {
      //         if(b.id === this.defaultActive.id) {
      //           bool = true;
      //         }
      //       }
      //
      //     })
      //   })
      //   if(!bool) {
      //     this.employees.forEach((item, i) => {
      //       if(Array.isArray(this.defaultActive)) {
      //         this.defaultActive.map(a=> {
      //           if(item.title === a.department) {
      //             this.employees[i].array.push(a)
      //           }
      //         })
      //       } else {
      //         if(item.title === this.defaultActive.department) {
      //           this.employees[i].array.push(this.defaultActive)
      //         }
      //       }
      //     })
      //   }
      //
      // }

      if(this.getEmployeesSortByDepartments.length === 0) {
        this.axiosGetEmployees().then(() => {
          this.getAxiosDepartmentsEmployeesPage().then(() => {
            this.modifyEmployeeAddDepartment()
            this.employees.splice(0, this.employees.length);
            // this.employees = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
            let employeesAccess = [];
            for (let i = 0; i < this.getEmployeesSortByDepartments.length; i++) {
              let array1 = [];
              for (let j = 0; j < this.getEmployeesSortByDepartments[i].array.length; j++) {
                for(let g=0; g<this.arrayPermission.users.length; g++) {
                  if(this.getEmployeesSortByDepartments[i].array[j].id === this.arrayPermission.users[g].id) {
                    // console.log("ID")
                    // console.log(this.getEmployeesSortByDepartments[i].array[j].id);
                    // console.log(this.arrayPermission.users[g].id);
                    array1.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i].array[j])));
                  }
                }
              }
              employeesAccess.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i])));
              employeesAccess[i].array.splice(0, employeesAccess[i].array.length)
              employeesAccess[i].array = array1;
              employeesAccess[i] = JSON.parse(JSON.stringify(employeesAccess[i]));
            }
            this.employees = JSON.parse(JSON.stringify(employeesAccess));
            // if(this.defaultActive) {
            //   let bool = false;
            //   this.employees.map(a=> {
            //     a.array.map(b=> {
            //       if(Array.isArray(this.defaultActive)) {
            //         this.defaultActive.map(c=> {
            //           if(b.id === c.id) {
            //             bool = true;
            //           }
            //         })
            //       } else {
            //         if(b.id === this.defaultActive.id) {
            //           bool = true;
            //         }
            //       }
            //
            //     })
            //   })
            //   if(!bool) {
            //     this.employees.forEach((item, i) => {
            //       if(Array.isArray(this.defaultActive)) {
            //         this.defaultActive.map(a=> {
            //           if(item.title === a.department) {
            //             this.employees[i].array.push(a)
            //           }
            //         })
            //       } else {
            //         if(item.title === this.defaultActive.department) {
            //           this.employees[i].array.push(this.defaultActive)
            //         }
            //       }
            //     })
            //   }
            //
            // }
            for (let i = 0; i < this.employees.length; i++) {
              for (let j = 0; j < this.employees[i].array.length; j++) {
                this.employees[i].array[j].status = false;
              }
            }
            this.employees = JSON.parse(JSON.stringify(this.employees));
            this.employeesCopy = JSON.parse(JSON.stringify(this.employees));
            // if(this.defaultActive !== null && this.defaultActive !== undefined) {
            //   for(let w=0; w<this.employees.length; w++) {
            //     for(let j=0; j<this.employees[w].array.length; j++) {
            //       if(Array.isArray(this.defaultActive)) {
            //         this.defaultActive.map(a=> {
            //           if(this.employees[w].array[j].id === a) {
            //             this.changeStatusEmployees(w, j);
            //           }
            //         })
            //       } else {
            //         if(this.employees[w].array[j].id === this.defaultActive) {
            //           this.changeStatusEmployees(w, j);
            //         }
            //       }
            //     }
            //   }
            // }
            this.$emit("mountedAssigned");
          })
        });
      } else {
        this.modifyEmployeeAddDepartment()
        this.employees.splice(0, this.employees.length);
        // this.employees = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
        let employeesAccess = [];
        for (let i = 0; i < this.getEmployeesSortByDepartments.length; i++) {
          let array1 = [];
          for (let j = 0; j < this.getEmployeesSortByDepartments[i].array.length; j++) {
            for(let g=0; g<this.arrayPermission.users.length; g++) {
              if(this.getEmployeesSortByDepartments[i].array[j].id === this.arrayPermission.users[g].id) {
                // console.log("ID")
                // console.log(this.getEmployeesSortByDepartments[i].array[j].id);
                // console.log(this.arrayPermission.users[g].id);
                array1.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i].array[j])));
              }
            }
          }
          employeesAccess.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i])));
          employeesAccess[i].array.splice(0, employeesAccess[i].array.length)
          employeesAccess[i].array = array1;
          employeesAccess[i] = JSON.parse(JSON.stringify(employeesAccess[i]));
        }
        this.employees = JSON.parse(JSON.stringify(employeesAccess));
        // if(this.defaultActive) {
        //   let bool = false;
        //   this.employees.map(a=> {
        //     a.array.map(b=> {
        //       if(Array.isArray(this.defaultActive)) {
        //         this.defaultActive.map(c=> {
        //           if(b.id === c.id) {
        //             bool = true;
        //           }
        //         })
        //       } else {
        //         if(b.id === this.defaultActive.id) {
        //           bool = true;
        //         }
        //       }
        //
        //     })
        //   })
        //   if(!bool) {
        //     this.employees.forEach((item, i) => {
        //       if(Array.isArray(this.defaultActive)) {
        //         this.defaultActive.map(a=> {
        //           if(item.title === a.department) {
        //             this.employees[i].array.push(a)
        //           }
        //         })
        //       } else {
        //         if(item.title === this.defaultActive.department) {
        //           this.employees[i].array.push(this.defaultActive)
        //         }
        //       }
        //     })
        //   }
        //
        // }
        for (let i = 0; i < this.employees.length; i++) {
          for (let j = 0; j < this.employees[i].array.length; j++) {
            this.employees[i].array[j].status = false;
          }
        }
        this.employees = JSON.parse(JSON.stringify(this.employees));
        this.employeesCopy = JSON.parse(JSON.stringify(this.employees));
        // if(this.defaultActive !== null && this.defaultActive !== undefined) {
        //   for(let w=0; w<this.employees.length; w++) {
        //     for(let j=0; j<this.employees[w].array.length; j++) {
        //       if(Array.isArray(this.defaultActive)) {
        //         this.defaultActive.map(a=> {
        //           if(this.employees[w].array[j].id === a) {
        //             this.changeStatusEmployees(w, j);
        //           }
        //         })
        //       } else {
        //         if(this.employees[w].array[j].id === this.defaultActive) {
        //           this.changeStatusEmployees(w, j);
        //         }
        //       }
        //     }
        //   }
        // }
      }
    },
    defaultActive: function () {
      // if(this.defaultActive) {
      //   let bool = false;
      //   this.employees.map(a=> {
      //     a.array.map(b=> {
      //       if(Array.isArray(this.defaultActive)) {
      //         this.defaultActive.map(c=> {
      //           if(b.id === c.id) {
      //             bool = true;
      //           }
      //         })
      //       } else {
      //         if(b.id === this.defaultActive.id) {
      //           bool = true;
      //         }
      //       }
      //
      //     })
      //   })
      //   if(!bool) {
      //     this.employees.forEach((item, i) => {
      //       if(Array.isArray(this.defaultActive)) {
      //         this.defaultActive.map(a=> {
      //           if(item.title === a.department) {
      //             this.employees[i].array.push(a)
      //           }
      //         })
      //       } else {
      //         if(item.title === this.defaultActive.department) {
      //           this.employees[i].array.push(this.defaultActive)
      //         }
      //       }
      //     })
      //   }
      //
      // }
      if(this.defaultActive) {
        if(Array.isArray(this.defaultActive) === true) {
          this.defaultActive.forEach(item => {
            if(!(this.employeesSelect.find(a=> a.id === item.id))) {
              this.employeesSelect.push(item)
            }
          })
        } else {
          if(!(this.employeesSelect.find(a=> a.id === this.defaultActive.id))) {
            this.employeesSelect.push(this.defaultActive)
          }
        }
      }
    },
    getEmployeesSortByDepartments: function () {
      // let array = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
      // for(let i=0; i<array.length; i++) {
      //   for(let j=0; j<array[i].array.length; j++) {
      //     array[i].array[j].status = false;
      //     for(let m=0; m<this.employeesCopy.length; m++) {
      //       for(let n=0; n<this.employeesCopy[m].array.length; n++) {
      //         // // console.log(array[i].array[j].id);
      //         // // console.log(this.employeesCopy[m].array[n].id);
      //         if(array[i].array[j].id === this.employeesCopy[m].array[n].id) {
      //           array[i].array[j].status = this.employeesCopy[m].array[n].status;
      //         }
      //       }
      //     }
      //   }
      // }
      // this.employees.splice(0, this.employees.length);
      // this.employees = JSON.parse(JSON.stringify(array));
      // let array = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
      let employeesAccess = [];
      // console.log(1);
      if(Array.isArray(this.getEmployeesSortByDepartments)) {
        for (let i = 0; i < this.getEmployeesSortByDepartments.length; i++) {
          let array1 = [];
          if(Array.isArray(this.getEmployeesSortByDepartments[i].array)) {
            for (let j = 0; j < this.getEmployeesSortByDepartments[i].array.length; j++) {
              // console.log(2);
              if(Array.isArray(this.arrayPermission.users)) {
                for(let g=0; g<this.arrayPermission.users.length; g++) {
                  if(this.getEmployeesSortByDepartments[i].array[j].id === this.arrayPermission.users[g].id) {
                    // console.log("ID")
                    // console.log(this.getEmployeesSortByDepartments[i].array[j].id);
                    // console.log(this.arrayPermission.users[g].id);
                    array1.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i].array[j])));
                  }
                }

              }
            }
            employeesAccess.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i])));
            employeesAccess[i].array.splice(0, employeesAccess[i].array.length)
            employeesAccess[i].array = array1;
            employeesAccess[i] = JSON.parse(JSON.stringify(employeesAccess[i]));
          }

        }

      }
      // console.log(3);
      let array = JSON.parse(JSON.stringify(employeesAccess));
      // console.log(array);
      if(Array.isArray(array))
        for(let i=0; i<array.length; i++) {
          for(let j=0; j<array[i].array.length; j++) {
            array[i].array[j].status = false;
            for(let m=0; m<this.employeesCopy.length; m++) {
              for(let n=0; n<this.employeesCopy[m].array.length; n++) {
                // // console.log(array[i].array[j].id);
                // // console.log(this.employeesCopy[m].array[n].id);
                if(array[i].array[j].id === this.employeesCopy[m].array[n].id) {
                  array[i].array[j].status = this.employeesCopy[m].array[n].status;
                }
              }
            }
          }
        }
      this.employees.splice(0, this.employees.length);
      this.employees = JSON.parse(JSON.stringify(array));
    }
  },
  methods: {
    ...mapActions(["axiosGetEmployees", "getAxiosDepartmentsEmployeesPage"]),
    ...mapMutations(["modifyEmployeeAddDepartment", "searchForEmployees",]),
    hideDropDown() {
      if(this.oneUser === true) {
        this.$emit("oneElementClick", (this.employeesSelect.length !== 0 ? this.employeesSelect[0] : null))
        this.hideDropdown();
      } else {
        this.$emit("oneElementClick", (this.employeesSelect))
      }
      this.show = false
    },
    focusOnSearch() {
      let searchTextInout = this.$refs['searchTextInput'+this.idref];
      console.log('searchTextInout', searchTextInout)
      searchTextInout.focus();
    },
    showDropdown() {
      this.show = true;
      setTimeout(()=>this.focusOnSearch(), 150);
    },
    getFilterSearch() {
      let employees = JSON.parse(JSON.stringify(this.employees))
      return employees.map(a=> {
        a.array = a.array.filter(b=> (b.first_name +' '+b.last_name+'*'+b.last_name+' '+b.first_name+'*'+b.phone_number+'*'+b.email+'*'+b.departmentName+'*'+b.activity).toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0)
        return a;
      })
    },
    showContentIf(array) {
      let k=0
      array.map(a=> {
        k+=a.array.length
      })
      if(k === 0) {
        return false
      } else {
        return true
      }
    },
    getDataForAxios() {
      let array = [];
      for(let i=0; i<this.employeesSelect.length; i++) {
        array.push(this.employeesSelect[i].id);
      }
      this.$emit("emitGetData", array);
    },
    hideDropdown() {
      this.$refs.dropdown.hide(true);
    },
    setAssignedForDuplicate(data) {
      // console.log("setAssignedForDuplicate")
      // console.log(data);
      for(let i=0; i<data.length; i++) {
        for(let w=0; w<this.employees.length; w++) {
          for(let j=0; j<this.employees[w].array.length; j++) {
            if(this.employees[w].array[j].id === data[i].id) {
              // console.log(data[i])
              this.changeStatusEmployees(w, j);
              j = this.employees[w].array.length
            }
          }
        }
      }
    },
    searchByEmployees() {
      if(this.searchText.length !==0) {
        this.searchForEmployees(this.searchText);
      } else {
        this.employees = JSON.parse(JSON.stringify(this.employeesCopy));
      }
    },
    changeStatusEmployees(index, indexEmp) {

      // let id = null;
      // if(this.oneUser === true) {
      //   for(let i=0; i<this.employeesCopy.length; i++) {
      //     for(let j=0; j<this.employeesCopy[i].array.length; j++) {
      //       // if(i !== index && j !== indexEmp) {
      //         this.employees[i].array[j].status = false;
      //       // } else {
      //       //   this.employees[i].array[j].status = !this.employees[i].array[j].status;
      //       // }
      //     }
      //   }
      //   for(let i=0; i<this.employees.length; i++) {
      //     for(let j=0; j<this.employees[i].array.length; j++) {
      //       // if(i !== index && j !== indexEmp) {
      //         this.employees[i].array[j].status = false;
      //       // } else {
      //       //   this.employees[i].array[j].status = !this.employees[i].array[j].status;
      //       // }
      //     }
      //   }
      //   if((this.employeesSelect.map(a=> {return a.id})).indexOf(this.employees[index].array[indexEmp].id) === -1 && this.employeesCopy.length !== 0) {
      //     this.employees[index].array[indexEmp].status = !this.employees[index].array[indexEmp].status;
      //     this.employeesCopy[index].array[indexEmp].status = !this.employeesCopy[index].array[indexEmp].status;
      //   } else {
      //     this.employeesCopy = JSON.parse(JSON.stringify(this.employees))
      //   }
      //   this.employeesSelect.splice(0, this.employeesSelect.length)
      // } else {
      //   if(this.employees === this.employeesCopy) {
      //     this.employees[index].array[indexEmp].status = !this.employees[index].array[indexEmp].status;
      //     this.employeesCopy[index].array[indexEmp].status = !this.employeesCopy[index].array[indexEmp].status;
      //   } else {
      //     this.employees[index].array[indexEmp].status = !this.employees[index].array[indexEmp].status;
      //     for(let i=0; i<this.employeesCopy.length; i++) {
      //       for(let j=0; j<this.employeesCopy[i].array.length; j++) {
      //         if(this.employeesCopy[i].array[j].id === this.employees[index].array[indexEmp].id) {
      //           // console.log(this.employeesCopy[i].array[j].id);
      //           // console.log(this.employees[index].array[indexEmp].id);
      //           this.employeesCopy[i].array[j].status = !this.employeesCopy[i].array[j].status;
      //           // id = this.employeesCopy[i].array[j]
      //         }
      //       }
      //     }
      //   }
      // }


      let Ids=this.employeesSelect.map(a=>{return a.id})

      if(Ids.indexOf(this.employees[index].array[indexEmp].id) === -1) {
        if(this.oneUser === true) {
          this.employeesSelect = []
        }
        this.employeesSelect.push(this.employees[index].array[indexEmp]);
      } else {
        this.employeesSelect = this.employeesSelect.filter(a=> a.id !== this.employees[index].array[indexEmp].id)
      }
      // console.log(k)
    },
    clearData() {
      // this.employeesSelect.splice(0,this.employeesSelect.length)
      // this.axiosGetEmployees().then(()=> {
      //   this.getAxiosDepartmentsEmployeesPage().then(()=> {
      //     this.modifyEmployeeAddDepartment()
      //     this.employees.splice(0, this.employees.length);
      //     this.employees = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
      //     for(let i=0; i<this.employees.length; i++) {
      //       for(let j=0; j<this.employees[i].array.length; j++) {
      //         this.employees[i].array[j].status = false;
      //       }
      //     }
      //     this.employees = JSON.parse(JSON.stringify(this.employees));
      //     this.employeesCopy = JSON.parse(JSON.stringify(this.employees));
      //   })
      // });
      this.employeesSelect.splice(0,this.employeesSelect.length)
      if(this.getEmployeesSortByDepartments.length === 0) {
        this.axiosGetEmployees().then(()=> {
          this.getAxiosDepartmentsEmployeesPage().then(()=> {
            this.modifyEmployeeAddDepartment()
            this.employees.splice(0, this.employees.length);
            // this.employees = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
            let employeesAccess = [];
            for (let i = 0; i < this.getEmployeesSortByDepartments.length; i++) {
              let array1 = [];
              for (let j = 0; j < this.getEmployeesSortByDepartments[i].array.length; j++) {
                for(let g=0; g<this.arrayPermission.users.length; g++) {
                  if(this.getEmployeesSortByDepartments[i].array[j].id === this.arrayPermission.users[g].id) {
                    // console.log("ID")
                    // console.log(this.getEmployeesSortByDepartments[i].array[j].id);
                    // console.log(this.arrayPermission.users[g].id);
                    array1.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i].array[j])));
                  }
                }
              }
              employeesAccess.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i])));
              employeesAccess[i].array.splice(0, employeesAccess[i].array.length)
              employeesAccess[i].array = array1;
              employeesAccess[i] = JSON.parse(JSON.stringify(employeesAccess[i]));
            }
            this.employees = JSON.parse(JSON.stringify(employeesAccess));
            // if(this.defaultActive) {
            //   let bool = false;
            //   this.employees.map(a=> {
            //     a.array.map(b=> {
            //       if(Array.isArray(this.defaultActive)) {
            //         this.defaultActive.map(c=> {
            //           if(b.id === c.id) {
            //             bool = true;
            //           }
            //         })
            //       } else {
            //         if(b.id === this.defaultActive.id) {
            //           bool = true;
            //         }
            //       }
            //
            //     })
            //   })
            //   if(!bool) {
            //     this.employees.forEach((item, i) => {
            //       if(Array.isArray(this.defaultActive)) {
            //         this.defaultActive.map(a=> {
            //           if(item.title === a.department) {
            //             this.employees[i].array.push(a)
            //           }
            //         })
            //       } else {
            //         if(item.title === this.defaultActive.department) {
            //           this.employees[i].array.push(this.defaultActive)
            //         }
            //       }
            //     })
            //   }
            //
            // }
            for(let i=0; i<this.employees.length; i++) {
              for(let j=0; j<this.employees[i].array.length; j++) {
                this.employees[i].array[j].status = false;
              }
            }
            this.employees = JSON.parse(JSON.stringify(this.employees));
            this.employeesCopy = JSON.parse(JSON.stringify(this.employees));
          })
        });

      } else {
        this.modifyEmployeeAddDepartment()
        this.employees.splice(0, this.employees.length);
        // this.employees = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
        let employeesAccess = [];
        for (let i = 0; i < this.getEmployeesSortByDepartments.length; i++) {
          let array1 = [];
          for (let j = 0; j < this.getEmployeesSortByDepartments[i].array.length; j++) {
            for(let g=0; g<this.arrayPermission.users.length; g++) {
              if(this.getEmployeesSortByDepartments[i].array[j].id === this.arrayPermission.users[g].id) {
                // console.log("ID")
                // console.log(this.getEmployeesSortByDepartments[i].array[j].id);
                // console.log(this.arrayPermission.users[g].id);
                array1.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i].array[j])));
              }
            }
          }
          employeesAccess.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i])));
          employeesAccess[i].array.splice(0, employeesAccess[i].array.length)
          employeesAccess[i].array = array1;
          employeesAccess[i] = JSON.parse(JSON.stringify(employeesAccess[i]));
        }
        this.employees = JSON.parse(JSON.stringify(employeesAccess));
        // if(this.defaultActive) {
        //   let bool = false;
        //   this.employees.map(a=> {
        //     a.array.map(b=> {
        //       if(Array.isArray(this.defaultActive)) {
        //         this.defaultActive.map(c=> {
        //           if(b.id === c.id) {
        //             bool = true;
        //           }
        //         })
        //       } else {
        //         if(b.id === this.defaultActive.id) {
        //           bool = true;
        //         }
        //       }
        //
        //     })
        //   })
        //   if(!bool) {
        //     this.employees.forEach((item, i) => {
        //       if(Array.isArray(this.defaultActive)) {
        //         this.defaultActive.map(a=> {
        //           if(item.title === a.department) {
        //             this.employees[i].array.push(a)
        //           }
        //         })
        //       } else {
        //         if(item.title === this.defaultActive.department) {
        //           this.employees[i].array.push(this.defaultActive)
        //         }
        //       }
        //     })
        //   }
        //
        // }
        for(let i=0; i<this.employees.length; i++) {
          for(let j=0; j<this.employees[i].array.length; j++) {
            this.employees[i].array[j].status = false;
          }
        }
        this.employees = JSON.parse(JSON.stringify(this.employees));
        this.employeesCopy = JSON.parse(JSON.stringify(this.employees));
      }
    }
  },
  mounted() {
    this.idref = Math.trunc(Math.random()*1000) + ''+Math.trunc(Math.random()*1000)
    if(this.permissionAssigned === 'vessel') {
      this.arrayPermission = this.getPermissionsFullByType("assign_voyage_contracts_to")
    } else if(this.permissionAssigned === 'vesselTask' || this.permissionAssigned === 'cargoTask') {
      this.arrayPermission = this.getPermissionsFullByType("assign_tasks_to");
    } else if(this.permissionAssigned === 'cargo') {
      this.arrayPermission = this.getPermissionsFullByType("assign_sublet_contracts_to");
    } else if(this.permissionAssigned === 'contract' || this.permissionAssigned === 'ship') {
      this.arrayPermission = this.getPermissionsFullByType("assign_contracts_to");
    } else if(this.permissionAssigned === 'voyage'){
      this.arrayPermission = this.getPermissionsFullByType("assign_voyage_contracts_to");
    } else if(this.permissionAssigned === 'sublet'){
      this.arrayPermission = this.getPermissionsFullByType("assign_sublet_contracts_to");
    } else {
      this.arrayPermission = this.getPermissionsForUserCanAssignTask;
    }
    // if(this.defaultActive) {
    //   let bool = false;
    //   this.employees.map(a=> {
    //     a.array.map(b=> {
    //       if(Array.isArray(this.defaultActive)) {
    //         this.defaultActive.map(c=> {
    //           if(b.id === c.id) {
    //             bool = true;
    //           }
    //         })
    //       } else {
    //         if(b.id === this.defaultActive.id) {
    //           bool = true;
    //         }
    //       }
    //
    //     })
    //   })
    //   if(!bool) {
    //     this.employees.forEach((item, i) => {
    //       if(Array.isArray(this.defaultActive)) {
    //         this.defaultActive.map(a=> {
    //           if(item.title === a.department) {
    //             this.employees[i].array.push(a)
    //           }
    //         })
    //       } else {
    //         if(item.title === this.defaultActive.department) {
    //           this.employees[i].array.push(this.defaultActive)
    //         }
    //       }
    //     })
    //   }
    // }
    if(this.defaultActive) {
      if(Array.isArray(this.defaultActive) === true) {
        this.defaultActive.forEach(item => {
          if(!(this.employeesSelect.find(a=> a.id === item.id))) {
            this.employeesSelect.push(item)
          }
        })
      } else {
        if(!(this.employeesSelect.find(a=> a.id === this.defaultActive.id))) {
          this.employeesSelect.push(this.defaultActive)
        }
      }
    }
    if(this.getEmployeesSortByDepartments.length === 0) {
      this.axiosGetEmployees().then(() => {
        this.getAxiosDepartmentsEmployeesPage().then(() => {
          this.modifyEmployeeAddDepartment()
          this.employees.splice(0, this.employees.length);
          // this.employees = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
          let employeesAccess = [];
          for (let i = 0; i < this.getEmployeesSortByDepartments.length; i++) {
            let array1 = [];
            for (let j = 0; j < this.getEmployeesSortByDepartments[i].array.length; j++) {
              for(let g=0; g<this.arrayPermission.users.length; g++) {
                if(this.getEmployeesSortByDepartments[i].array[j].id === this.arrayPermission.users[g].id) {
                  // console.log("ID")
                  // console.log(this.getEmployeesSortByDepartments[i].array[j].id);
                  // console.log(this.arrayPermission.users[g].id);
                  array1.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i].array[j])));
                }
              }
            }
            employeesAccess.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i])));
            employeesAccess[i].array.splice(0, employeesAccess[i].array.length)
            employeesAccess[i].array = array1;
            employeesAccess[i] = JSON.parse(JSON.stringify(employeesAccess[i]));
          }
          this.employees = JSON.parse(JSON.stringify(employeesAccess));
          // if(this.defaultActive) {
          //   let bool = false;
          //   this.employees.map(a=> {
          //     a.array.map(b=> {
          //       if(Array.isArray(this.defaultActive)) {
          //         this.defaultActive.map(c=> {
          //           if(b.id === c.id) {
          //             bool = true;
          //           }
          //         })
          //       } else {
          //         if(b.id === this.defaultActive.id) {
          //           bool = true;
          //         }
          //       }
          //
          //     })
          //   })
          //   if(!bool) {
          //     this.employees.forEach((item, i) => {
          //       if(Array.isArray(this.defaultActive)) {
          //         this.defaultActive.map(a=> {
          //           if(item.title === a.department) {
          //             this.employees[i].array.push(a)
          //           }
          //         })
          //       } else {
          //         if(item.title === this.defaultActive.department) {
          //           this.employees[i].array.push(this.defaultActive)
          //         }
          //       }
          //     })
          //   }
          //
          // }
          for (let i = 0; i < this.employees.length; i++) {
            for (let j = 0; j < this.employees[i].array.length; j++) {
              this.employees[i].array[j].status = false;
            }
          }
          this.employees = JSON.parse(JSON.stringify(this.employees));
          this.employeesCopy = JSON.parse(JSON.stringify(this.employees));
          // if(this.defaultActive !== null && this.defaultActive !== undefined) {
          //   for(let w=0; w<this.employees.length; w++) {
          //     for(let j=0; j<this.employees[w].array.length; j++) {
          //       if(Array.isArray(this.defaultActive)) {
          //         this.defaultActive.map(a=> {
          //           if(this.employees[w].array[j].id === a) {
          //             this.changeStatusEmployees(w, j);
          //           }
          //         })
          //       } else {
          //         if(this.employees[w].array[j].id === this.defaultActive) {
          //           this.changeStatusEmployees(w, j);
          //         }
          //       }
          //     }
          //   }
          // }
          this.$emit("mountedAssigned");
        })
      });
    } else {
      this.modifyEmployeeAddDepartment()
      this.employees.splice(0, this.employees.length);
      // this.employees = JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments));
      let employeesAccess = [];
      for (let i = 0; i < this.getEmployeesSortByDepartments.length; i++) {
        let array1 = [];
        for (let j = 0; j < this.getEmployeesSortByDepartments[i].array.length; j++) {
          for(let g=0; g<this.arrayPermission.users.length; g++) {
            if(this.getEmployeesSortByDepartments[i].array[j].id === this.arrayPermission.users[g].id) {
              // console.log("ID")
              // console.log(this.getEmployeesSortByDepartments[i].array[j].id);
              // console.log(this.arrayPermission.users[g].id);
              array1.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i].array[j])));
            }
          }
        }
        employeesAccess.push(JSON.parse(JSON.stringify(this.getEmployeesSortByDepartments[i])));
        employeesAccess[i].array.splice(0, employeesAccess[i].array.length)
        employeesAccess[i].array = array1;
        employeesAccess[i] = JSON.parse(JSON.stringify(employeesAccess[i]));
      }
      this.employees = JSON.parse(JSON.stringify(employeesAccess));
      // if(this.defaultActive) {
      //   let bool = false;
      //   this.employees.map(a=> {
      //     a.array.map(b=> {
      //       if(Array.isArray(this.defaultActive)) {
      //         this.defaultActive.map(c=> {
      //           if(b.id === c.id) {
      //             bool = true;
      //           }
      //         })
      //       } else {
      //         if(b.id === this.defaultActive.id) {
      //           bool = true;
      //         }
      //       }
      //
      //     })
      //   })
      //   if(!bool) {
      //     this.employees.forEach((item, i) => {
      //       if(Array.isArray(this.defaultActive)) {
      //         this.defaultActive.map(a=> {
      //           if(item.title === a.department) {
      //             this.employees[i].array.push(a)
      //           }
      //         })
      //       } else {
      //         if(item.title === this.defaultActive.department) {
      //           this.employees[i].array.push(this.defaultActive)
      //         }
      //       }
      //     })
      //   }
      //
      // }
      for (let i = 0; i < this.employees.length; i++) {
        for (let j = 0; j < this.employees[i].array.length; j++) {
          this.employees[i].array[j].status = false;
        }
      }
      this.employees = JSON.parse(JSON.stringify(this.employees));
      this.employeesCopy = JSON.parse(JSON.stringify(this.employees));
      // if(this.defaultActive !== null && this.defaultActive !== undefined) {
      //   for(let w=0; w<this.employees.length; w++) {
      //     for(let j=0; j<this.employees[w].array.length; j++) {
      //       if(Array.isArray(this.defaultActive)) {
      //         // alert(1)
      //         this.defaultActive.map(a=> {
      //           if(this.employees[w].array[j].id === a) {
      //             this.changeStatusEmployees(w, j);
      //           }
      //         })
      //       } else {
      //         // alert(2)
      //         if(this.employees[w].array[j].id === this.defaultActive) {
      //           this.changeStatusEmployees(w, j);
      //         }
      //       }
      //     }
      //   }
      // }
      this.$emit("mountedAssigned");
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style-element/dropdown";

p {
  margin: 0;
}

.wrap-assignee {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
.nameuser {
  padding-left: 14px;
  align-self: center;
}
/deep/ .dropdown-menu {
  top: 38px !important;
  transform: none !important;
  width: 100%;
  background: transparent;
  border: none;
  min-width: 1px;
  //width: max-content;
  box-sizing: border-box;
  //display: flex;
  //.avatar-user {
  //  position: relative;
  //}
}
.content {
  background-color: var(--color-modal);
  box-sizing: border-box;
  width: 100%;
  max-height: 260px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  button {
    text-align: left;
    transition: background-color .1s linear;
    background-color: var(--color-modal);
    height: 34px;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 12px;
    //&:first-child {
    //  border-radius: 4px 4px 0 0px;
    //}
    //&:last-child {
    //  border-radius: 0px 0px 4px 4px;
    //}
    &:hover {
      background-color: var(--color-input);
    }
  }
}
.button-drop {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 36px !important;
  background: var(--color-input);
  /* Stroke */

  border: 1px solid var(--color-border);
  border-radius: 4px;
  &>svg {
    margin-left: 6px;
    position: absolute;
    right: 8px;
  }
  .text-gray-light {
    position: relative;
    top: 1px;
    left: 1px;
  }
  padding: 8px !important;
  padding-right: 25px;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-dark);
  transition: .2s linear;
  &:hover {
    border-color: #A3A3A3;
  }
  .block-avatar-user {
    align-items: center;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    //background-color: #000;
    height: 100%;
    .avatar-user {
      width: 16px;
      height: 24px;
      position: relative;
      img, svg {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        border: 1px solid var(--color-card);
        border-radius: 20px;
      }
    }

  }
  .more-avatar {
    border: 1px solid var(--color-blue-dark);
    color: var(--color-blue-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 13px;
    width: 24px;
    height: 24px;
    border-radius: 20px;
  }
  //&:focus {
  //  border-color: #094172;
  //}
}
.active-in {
  border-color: var(--color-blue-dark) !important;
}
/deep/ .btn {
  box-sizing: border-box;
  width: 100%;
  background: transparent !important;
}
.title-department {
  //margin-top: 10px;
  padding: 0 5px;
  box-sizing: border-box;
}

.search-emp {
  //vertical-align: center;
  //text-align: center;
  //&::placeholder {
  //  font-size: 0.875rem !important;
  //  line-height: 1.063rem !important;
  //  color: var(--color-gray-light);
  //}
  //border-radius: 4px 4px 0 0px !important;
  //background-image: url(../../../assets/image/search1.svg);
  //background-repeat: no-repeat;
  //background-position: 12px center;
  //padding-left: 44px;
  padding: 8px;

  position: absolute;
  top: -40px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  background-color: var(--color-modal);
  height: 36px !important;
  //margin-bottom: 10px;
  border-bottom: 1px solid #DEE3EA;
  width: 100% !important;
  box-sizing: border-box !important;
}


.content-card {
  //margin-bottom: 10px;
  padding: 5px;
  padding-right: 25px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img,svg {
    margin-right: 7px;
    width: 24px;
    height: 24px;
    border-radius: 50px;
  }
  cursor: pointer;
  &:hover {
    background-color: var(--color-hover-list);
  }
}


.active-card {
  background-image: url(../../../assets/image/Select.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 7px) center;
}

.block {
  //padding: 0 12px;
}

.dart-mode {
  .search-emp {
    background-image: url(../../../assets/image/search12.svg);
  }
  .button-drop > svg {
    path {
      fill: #FFFFFF;
    }
  }
}

.no-active {
  * {
    cursor: default !important;
  }
  .button-drop {
    background: var(--color-input) !important;
    border: 1px solid var(--color-blue-dark) !important;
  }
}


</style>