<template>
  <div class="counterparties-drop">
    <p class="counterparties-drop_title fz13 text-gray" v-if="title.length !== 0">{{formatCpDate ? formatCpDateReturn(title) : title}}</p>
    <b-dropdown :dropup="isDropup" ref="dropdown" :disabled="isDisabled" @hide="hideEvent">
      <template #button-content>
        <div class="button-text text-gray-light p-l-1" :class="classButtonText" v-if="!printDropResult">{{placeholder}} </div>
        <div class="button-text" :class="classButtonText" v-else v-html="formatCpDate ? formatCpDateReturn(printDropResult) : printDropResult"> </div>
        <div class="button-drop"><svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/></svg></div>
      </template>

      <li class="search" v-if="list.length > 14" v-on:click="prevent">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1535 14.2913L10.9827 10.1205C11.7906 9.12262 12.277 7.85457 12.277 6.47348C12.277 3.27251 9.67246 0.667969 6.47149 0.667969C3.27053 0.667969 0.666016 3.27248 0.666016 6.47345C0.666016 9.67442 3.27056 12.279 6.47152 12.279C7.85261 12.279 9.12066 11.7925 10.1186 10.9846L14.2894 15.1554C14.4085 15.2746 14.565 15.3345 14.7214 15.3345C14.8779 15.3345 15.0343 15.2746 15.1535 15.1554C15.3924 14.9165 15.3924 14.5303 15.1535 14.2913ZM6.47152 11.0567C3.94398 11.0567 1.88824 9.00099 1.88824 6.47345C1.88824 3.94591 3.94398 1.89016 6.47152 1.89016C8.99906 1.89016 11.0548 3.94591 11.0548 6.47345C11.0548 9.00099 8.99903 11.0567 6.47152 11.0567Z" fill="#2C2C2C"/></svg>
        <input v-on:click="prevent" type="text" v-model="searchText" placeholder="Search">
      </li>
      <li v-if="searchText && !onSearch.length"><span class="dropdown-item text-dark1">Nothing found</span></li>

      <li v-if="isSelectedPrev && !list.length"><span class="dropdown-item text-dark1">Selected type has`t sybtypes</span></li>
      <li v-else-if="!list.length"><span class="dropdown-item text-dark">{{textNoSelect}}</span></li>
      <li v-for="item in onSearch" :key="item.id">
        <a href="#" class="dropdown-item text-dark1" @click.prevent="select(item, true)" :title="item.name">{{ formatCpDate ? formatCpDateReturn(item.name) : item.name}}</a>
        <svg v-if="checkedSelected(item)" class="dropdown-check" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z" fill="#319FEF"/></svg>
      </li>
    </b-dropdown>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    formatCpDate: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array
    },
    activeItem: {
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    isSelectedPrev: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    removeSelectItemBeforeClick: {
      type: Boolean,
      default: true
    },
    textNoSelect: {
      type: String,
      default: "List empty",
    },
    dontSelect: {
      type: Boolean,
      default: false,
    },
    isDropup: {
      type: Boolean,
      dafault: false
    },
    classButtonText: {
      type: String,
      default: '',
    },
    noCheckName: Boolean,
  },
  watch: {
    // activeItem() {
    //   console.log('this.activeItem ', this.activeItem);
    //   if(this.activeItem) {
    //     //this.select(this.activeItem)
    //     if(this.isMultiple) {
    //       this.activeItem.forEach(item => {
    //         this.select(item)
    //       });
    //     }else{
    //       this.select(this.activeItem)
    //     }
    //   }
    // }
    activeItem() {
      //console.log('this.activeItem ', this.activeItem);
      if(this.activeItem) {
        //this.select(this.activeItem)
        if(this.isMultiple) {
          this.selectItems = [];
          this.activeItemLocal = JSON.parse(JSON.stringify(this.activeItem))
          this.activeItem.forEach(item => {
            this.select(item)
          });
        }else{
          this.activeItemLocal = JSON.parse(JSON.stringify(this.activeItem))
          if(!this.dontSelect) {
            this.select(this.activeItem)
          } else {
            let type = this.activeItem
            if(this.isMultiple) {
              let indexOfElem = this.selectItems.findIndex(el => {
                if(el.id !== null && el.id !== undefined) {
                  if(el.id === type.id) {
                    return el
                  }
                } else if(el.name === type.name) {
                  return el
                }
              })
              if(indexOfElem >= 0){ //remove item
                this.selectItems.splice(indexOfElem, 1)
              }else{ // add item
                this.selectItems.push(type)
              }
            }else {
                this.selectItem = type.name;
                this.activeItemLocal = type;
            }
          }
        }
      }
    }
  },
  data() {
    return {
      activeItemLocal: null,
      selectItem: '',
      selectItemInObj: {},
      selectItems: [],
      searchText: ''
    }
  },
  computed: {
    printDropResult() {
      if(this.isMultiple) {
        let result = ''
        if(this.selectItems.length > 2){
          result = this.selectItems[0].name + `&nbsp;/&nbsp;<b>+${this.selectItems.length - 1}</b>`
        } else if(this.selectItems.length) {
          for (let i = 0; i < this.selectItems.length; i++) {
            const el = this.selectItems[i];
            if(i == 0 && this.selectItems.length > 1) {
              result += el.name + '&nbsp;/&nbsp;'
            }else{
              result += el.name
            }
          }
        }
        return result
      }else{
        if(this.selectItem === 'Unselected') {
          return ""
        }
        return this.selectItem
      }
    },
    onSearch() {
      if(this.searchText){
        return this.list.filter(item => {
          return item.name.toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0
        })
      }
      return this.list
    }
  },
  methods: {
    prevent(e) {
      e.preventDefault()
    },
    formatCpDateReturn(value) {
      if(!value) {
        return  ''
      }
      return moment(value, 'YYYY-MM-DD').utcOffset(6).format('DD.MM.YYYY')
    },
    openList() {
      if(!this.isDisabled) {
        this.$refs.dropdown.show(true);
      }
    },
    hideEvent() {
      this.$emit('beforeClose')
      if(this.isMultiple) {
        this.$emit("close", this.selectItems);
      } else {
        this.$emit("close", this.selectItem);
      }
      setTimeout(()=> this.$emit('afterClose'), 50)

    },
    checkedSelected(item) {
      if(item.id !== null && item.id !== undefined && (this.activeItemLocal !== null && this.activeItemLocal !== undefined)) {
        if(this.activeItemLocal.id === item.id ||this.selectItems.findIndex(el => el.id === item.id) >= 0) {
          return true;
        } else {
          return false;
        }
      } else if(item.id !== null && item.id !== undefined && this.selectItems.length !== 0 ) {
        if(this.selectItems.findIndex(el => el.id === item.id) >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if(this.selectItem === item.name || this.selectItems.findIndex(el => el.name === item.name) >= 0) {
          return true;
        } else {
          return false;
        }
      }

    },
    select(type, hide) {
      if(this.isMultiple) {
        let indexOfElem = this.selectItems.findIndex(el => {
          if(el.id !== null && el.id !== undefined) {
            if(el.id === type.id) {
              return el
            }
          } else if(el.name === type.name) {
            return el
          }
        })
        if(indexOfElem >= 0){ //remove item
          this.selectItems.splice(indexOfElem, 1)
        }else{ // add item
          this.selectItems.push(type)
        }
        this.$emit('beforeSelect')
        this.$emit('select', this.selectItems)
        this.$emit('afterSelect')
      }else{
        if((this.selectItem === type.name || this.noCheckName) && this.activeItemLocal?.id === type.id && this.removeSelectItemBeforeClick !== false) {
          this.selectItem = "";
          this.activeItemLocal = null;
        } else {
          this.selectItem = type.name;
          this.activeItemLocal = type;
        }

        this.$emit('beforeSelect')
        this.$emit('select', this.activeItemLocal)
        this.$emit('afterSelect')
        if(hide === true) {
          this.$refs.dropdown.hide()
          this.$emit('change')
        }
        // this.hideEvent();
      }
    },
    render() {
      this.activeItemLocal = null
      this.selectItem = ''
      this.selectItemInObj = {}
      this.selectItems = []
    }
  },
  mounted() {

    // if(this.activeItem) {
    //   //this.select(this.activeItem)
    //   if(this.isMultiple) {
    //     this.activeItem.forEach(item => {
    //       this.select(item)
    //     });
    //   }else{
    //     this.select(this.activeItem)
    //   }
    // }


    if(this.activeItem !== null && this.activeItem !== undefined) {
      //this.select(this.activeItem)
      if(this.isMultiple) {
        this.selectItems = [];
        this.activeItemLocal = JSON.parse(JSON.stringify(this.activeItem))
        this.activeItem.forEach(item => {
          this.select(item)
        });
      }else{
        this.activeItemLocal = JSON.parse(JSON.stringify(this.activeItem))
        if(!this.dontSelect) {
          this.select(this.activeItem)
        } else {
        let type = this.activeItem
        if(this.isMultiple) {
          let indexOfElem = this.selectItems.findIndex(el => {
            if(el.id !== null && el.id !== undefined) {
              if(el.id === type.id) {
                return el
              }
            } else if(el.name === type.name) {
              return el
            }
          })
          if(indexOfElem >= 0){ //remove item
            this.selectItems.splice(indexOfElem, 1)
          }else{ // add item
            this.selectItems.push(type)
          }
        }else{
            this.selectItem = type.name;
            this.activeItemLocal = type;
        }
      }
      }
    }
    this.$emit("mountedEnd")
  }
}
</script>

<style scoped lang="scss">
  @import "../../style-element/dropdown";
</style>

<style lang="scss">
  .counterparties-drop{
    margin-top: 14px;
    &.hide_input{
      position: absolute;
      top: 0;
      left: 0;
      //height: 0;
      width: 260px;
      margin: 0;
      z-index: 1;
      .b-dropdown{
        display: none;
        &.show{
          display: inline-flex;
        }
      }
      .counterparties-drop_title{
        display: none;
      }
      .dropdown-toggle{
        top: 0;
        height: 0;
        padding: 0 !important;
        border: none !important;
        opacity: 0;
        pointer-events: none;
      }
      .dropdown-item{
        font-size: 13px;
      }
    }
    &.block-input_error{
      .dropdown-toggle{
        border-color: #f57f6c !important;
      }
    }
    &_title{
      margin-bottom: 0.25rem !important;
      //color: #9AA3B0;
    }
    .dropdown{
      width: 100%;
    }
    .dropdown-menu{
      max-height: 300px;
      overflow: auto;
    }
    .dropdown-toggle{
      display: flex;
      width: 100%;
      align-items: center;
      box-sizing: border-box;
      padding: 8px 7px 7px !important;
      font-size: 16px !important;
      border-radius: 4px;
      border: 1px solid var(--color-border) !important;
      background: var(--color-input) !important;
      line-height: normal;
      &:after{
        display: none !important;
      }
      .button-drop{
        width: auto !important;
      }
    }
    .button-text{
      display: inline-flex;
      text-align: left;
      font-size: 14px;
      color: var(--color-dark);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      b{
        display: inline-flex;
        min-width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--color-dark);
        font-weight: 400;
        font-size: 11px;
      }
    }
    .dropdown-menu{
      width: 100%;
      li{
        position: relative;
      }
    }
    .dropdown-check{
      position: absolute;
      top: 11px;
      right: 11px;
      user-select: none;
    }
    .dropdown-item{
      padding: 8px 30px 8px 16px;
      padding-right: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .search{
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px 16px;
      border-bottom: 1px solid var(--color-card-border);
      input{
        height: auto;
        margin-left: 12px;
        padding: 0;
        border: none;
        background-color: transparent;
      }
    }

    &.drop-bg-white{
      .dropdown-toggle{
        background: var(--color-white) !important;
      }
    }
  }
  .button-text, .button-drop {
    display: inline;
    min-height: 16px;
  }
</style>