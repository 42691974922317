<template>
  <div class="wrap " v-click-outside="hide"  :class="{'z-100': show}">
    <p class="fz14 text-dark selected cursor-pointer" :class="{active: show}" v-on:click="show = !show" v-html="getTextSelected"></p>
    <div class="position-relative">
      <div class="list z-10 position-absolute t8 l0 w180 bgc-white border-radius-4" v-if="show">
        <div v-on:click="$emit('clickUser'); show=false" class="item d-flex align-items-center h32 w100Proc box-border p0-8 pt4 pb4 cursor-pointer" v-for="(item, i) in value" :key="i+'sd'">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10074_14203)">
              <circle cx="12" cy="12" r="12" fill="white"/>
              <path d="M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z" fill="#DEE3EA"/>
            </g>
            <defs>
              <clipPath id="clip0_10074_14203">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p class="pl6 fz13 text-dark">{{item.first_name}} {{item.last_name}}</p>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    value: [Array],
  },
  computed: {
    getTextSelected() {
      let text = ''
      if(this.value.length) {
        text = `${this.value[0].first_name} ${this.value[0].last_name}`
      }
      if(this.value.length > 1) {
        text += `<span class="dop-empl fz11">+${this.value.length-1}</span>`
      }
      return text
    }
  },
  name: "foldersSelectedEmployees",
  data() {
    return {
      show: false
    }
  },
  methods: {
    hide() {
      this.show = false
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
  /deep/.dop-empl {
    margin-left: 4px;
    border-radius: 100px;
    border: 1px solid var(--color-dark);
    padding: 2px 4px;
  }
  .selected {
    &:hover {
      color: var(--color-blue) !important;
      /deep/ {
        color: var(--color-blue) !important;
        span {
          color: var(--color-blue) !important;
          border: 1px solid var(--color-blue) !important;
        }
      }


    }
  }
  .list {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .active {
    color: var(--color-blue) !important;
    /deep/ {
      color: var(--color-blue) !important;
      span {
        color: var(--color-blue) !important;
        border: 1px solid var(--color-blue) !important;
      }
    }
  }
</style>