import axios from 'axios'

export default {
    actions: {
        axiosGlobalSearch(ctx, search) {
            let ct = axios.CancelToken.source();
            ctx.commit("cancelGlobalSearch")
            ctx.commit("cancelTokenSourceGlobalSearch", ct);
            ctx.commit("setGlobalSearch", null)
            if(search.length >1) {
                return axios.get(process.env.VUE_APP_URL + "/search/global/", {
                    cancelToken: ct.token,
                    params: {
                        limit: 10,
                        search: search
                    },
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(res=> {
                    ctx.commit("setGlobalSearch", res.data)
                })
            } else {
                return null
            }
        },
        async axiosShowMoreGlobalSearch(ctx, data) {
            ctx.state.globalSearch[data.page_type].showLoad = true
            return await axios.get(process.env.VUE_APP_URL + "/search/global/", {
                params: {
                    search: data.search,
                    page: data.page,
                    page_type: data.page_type,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                ctx.commit('setShowMoreDate', {page_type: data.page_type, list: res.data[data.page_type].data})
                ctx.state.globalSearch[data.page_type].showLoad = false
            })
        },
        async axiosChangeSearchShipTemplate(ctx, data) {
            return await axios.patch(process.env.VUE_APP_URL + '/search/ship/template/' + data.id, data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                // if(ctx.state.globalSearch?.ship_template?.data) {
                //     ctx.state.globalSearch.ship_template.data.forEach((item) => {
                //         if(item.id === res.data.id) {
                //             item = res.data
                //         }
                //     })
                // }
                // ctx.commit("setNewTemplate", {data: res.data, name: "ship_template"})
                return true;
            }).catch(()=> {
                return false;
            })
        },
        async axiosCreateSearchShipTemplate(ctx, data) {
            return await axios.post(process.env.VUE_APP_URL + '/search/ship/template/', data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                // if(ctx.state.globalSearch?.ship_template?.data) {
                //     ctx.state.globalSearch.ship_template.data.forEach((item) => {
                //         if(item.id === res.data.id) {
                //             item = res.data
                //         }
                //     })
                // }
                // ctx.commit("setNewTemplate", {data: res.data, name: "ship_template"})
                return res.data;
            }).catch(()=> {
                return false;
            })
        },
        async axiosGetShipTemplateById(ctx, id) {
            return await axios.get(process.env.VUE_APP_URL + '/search/ship/template/' + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                return res.data
            }).catch(()=> {
                return null
            })
        }
    },
    mutations: {
        setNewTemplate(state, data) {
            if(Array.isArray(state.globalSearch[data.name]) === true) {
                state.globalSearch[data.name] = state.globalSearch[data.name].map(a=> {
                    if(a.id === data.id) {
                        a = data;
                    }
                    return a;
                })
            }
            state.globalSearch = JSON.parse(JSON.stringify(state.globalSearch))
        },
        setGlobalSearch(state, data) {
            console.log(data)
            if(data) {
                for (var key in data) {
                    data[key]['currentShowPage'] = 1;
                    data[key]['showLoad'] = false;
                }
            }
            state.globalSearch = data
        },
        setShowMoreDate(state, data) {
            state.globalSearch[data.page_type].currentShowPage+=1
          state.globalSearch[data.page_type].data = (state.globalSearch[data.page_type].data).concat(data.list)
        },
        cancelGlobalSearch(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
                // state.statusRequestFilter = false;
            }
        },
        cancelTokenSourceGlobalSearch(state, data) {
            state.cancelTokenSource = data;
        }
    },
    state: {
        globalSearch: null,
        cancelTokenSource: null,
    },
    getters: {
        getResultsGlobalSearch: state => (()=> {
            return state.globalSearch;

        }) ()
    }
}