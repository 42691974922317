<template>
    <b-dropdown left ref="dropdown">
      <template #button-content>
        <button class="button-drop text-dark1">
          {{activeName}}
          <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/>
          </svg>
        </button>
      </template>
      <div class="content">
        <div v-for="(b, i) in button"  :key="i">
          <button v-if="b.name !== activeName" v-on:click="changeButton(b)" class="text-dark1">{{ b.name }}</button>
        </div>
      </div>
    </b-dropdown>
</template>

  <script>
  import {mapGetters} from "vuex";

  export default {
    name: "dropdown-manually-task-add",
    computed: mapGetters(['getPermissionsForUserCreateManualTask', 'getPermissionsByType']),
    methods: {
      hideDropdown() {
        this.$refs.dropdown.hide(true);
      },
      changeButton(b) {
        this.activeName = b.name;
        this.hideDropdown();
        this.$emit(""+b.value);
      },
      setActiveName(name) {
        this.activeName = name;
      }
    },
    data() {
      return {
        activeName: "Manually task",
        // button: [
        //   {name: "Manually task", value: "manuallyTask"},
        //   {name: "Vessel search with Cargo", value: "vesselSearch"},
        //   {name: "Vessel search without Cargo", value: "cargoSearch"},
        //   {name: "Search Cargo for our Vessel", value: "cargoOurVessel"},
        //   {name: "Cargo Search without Vessel", value: "cargoWithoutVessel"}
        // ]
        button: [
          {name: "Manually task", value: "manuallyTask"},
          {name: "Vessel search", value: "vesselSearch"},
          {name: "Cargo Search", value: "cargoOurVessel"},
        ]
      }
    },
    mounted() {
      if(this.getPermissionsForUserCreateManualTask !== true) {
        this.button = this.button.filter(a=> a.name !== "Manually task")
      }
      if(!this.getPermissionsByType('can_create_cargo_task')) {
        this.button = this.button.filter(a=> a.name !== "Cargo Search")
      }
      if(!this.getPermissionsByType('can_create_ship_task')) {
        this.button = this.button.filter(a=> a.name !== "Vessel search")
      }
    }
  }
  </script>

  <style scoped lang="scss">
  @import "../../style-element/dropdown";
  /deep/ .show {
    .arrow {
      transform: rotateX(180deg);
    }
  }
  /deep/ .dropdown-menu {
    background: transparent;
    border: none;
    min-width: 1px;
    width: max-content;
  }
  .content {
    width: 220px;
    border-radius: 4px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    button {
      text-align: left;
      transition: background-color .1s linear;
      background-color: var(--color-modal);
      height: 34px;
      width: 100%;
      box-sizing: border-box;
      padding: 4px 12px;
      &:first-child {
        border-radius: 4px 4px 0 0px;
      }
      &:last-child {
        border-radius: 0px 0px 4px 4px;
      }
      &:hover {
        background-color: var(--color-input);
      }
    }
  }
  .button-drop {
    background-color: transparent !important;
    display: flex;
    align-items: center;
    svg {
      margin-left: 6px;
    }
  }
  /deep/ .btn {
    background: transparent !important;
  }
  </style>