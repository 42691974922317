<template>
  <div class="p-12-24 w100vw box-border">
    <div class="top-panel  d-flex align-items-center">
      <p class="fz20 fw500 text-dark">Contact Lists {{$route.path.indexOf('archive') === -1 ? '' : 'Archive'}}</p>
      <router-link :to="{name: 'Mailling Contacts List Archive'}" v-if="$route.path.indexOf('archive') === -1" class="d-flex align-items-center ml16 button-dark-link archive-link svg-white-dart-stroke text-dark fw500 fz13">
        <svg class="mr4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Archive
      </router-link>
      <mailing-sort-by class="ml-auto" v-model="sort"></mailing-sort-by>
      <div class="relative ml12 d-flex align-items-center">
        <svg class="absolute l8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
        </svg>
        <input type="text" class="w240 h32 pl40 bgc-white" placeholder="Search" @focusout="search !== searchCopy ? searchTemplate() : null" v-on:keydown.enter="search !== searchCopy ? searchTemplate() : null" v-model="search">
        <button class="blue-button h32 ml12" v-on:click="openModal">
          <svg class="mr4" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8333 7.33332H9.16668V2.66662C9.16668 2.2987 8.86798 2 8.49994 2C8.13202 2 7.83332 2.2987 7.83332 2.66662V7.33332H3.16662C2.7987 7.33332 2.5 7.63202 2.5 7.99994C2.5 8.36798 2.7987 8.66668 3.16662 8.66668H7.83332V13.3333C7.83332 13.7013 8.13202 14 8.49994 14C8.86798 14 9.16668 13.7013 9.16668 13.3333V8.66668H13.8333C14.2013 8.66668 14.5 8.36798 14.5 7.99994C14.5 7.63202 14.2013 7.33332 13.8333 7.33332Z" fill="white"/>
          </svg>
          Create Contact List
        </button>
      </div>
    </div>
    <mailling-list @unarchiveSelected="(e)=> {undoSelected = e}" ref="list" @deleteSelected="(e)=> {deleteSelected = e}" @deleteReal="(e)=> {deleteRealItem = e}" @clickItem="openEdit" @changeSort="(e)=>{sort = e}" :sort="sort" @delete="(e)=> {idDeleteContact=e}" :isLoad="isLoad" :searchActive="search.length !== 0" :items="contacts" class="mt16" :type="'contact'"></mailling-list>
    <pagination-panel-default v-if="contactsNext" :if-active="isLoadNext" @load-more="loadNext"></pagination-panel-default>
    <create-edit-contact-list :type="typeModal" ref="modal" @created="searchTemplate()" v-show="showCreateMailingContact" @close="showCreateMailingContact = false"></create-edit-contact-list>
    <delete-modal
        v-if="idDeleteContact"
        :title="$route.path.indexOf('archive') === -1 ? 'Archive Contact List' : 'Unarchive Contact List'"
        :text="$route.path.indexOf('archive') === -1 ? 'Are you sure you want to archive contact list?' : 'Are you sure you want to unarchive contact list?'"
        @close="idDeleteContact = null"
        @apply="deleteContact(idDeleteContact)"
        :textForSecondButton="$route.path.indexOf('archive') === -1 ? 'Archive' : 'Yes'"
        :textForFirstButton="$route.path.indexOf('archive') === -1 ? '' : 'No'"

    ></delete-modal>
    <delete-modal
        v-if="deleteRealItem"
        :title="'Delete Contact List'"
        :text="'Are you sure you want to delete contact list? <br> It Will be impossible to recover'"
        @close="deleteRealItem = null"
        @apply="deleteContactReal(deleteRealItem)"
        :textForSecondButton="'Delete'"
    ></delete-modal>
    <delete-modal
        v-if="deleteSelected.length"
        :title="'Delete Selected Contact List'"
        :text="'Are you sure you want to delete selected contact list? <br> It Will be impossible to recover'"
        @close="deleteSelected = []"
        @apply="deleteContactRealSelected(deleteSelected)"
        :textForSecondButton="'Yes'"
        :textForFirstButton="'No'"
    ></delete-modal>
    <delete-modal
        v-if="undoSelected.length"
        :title="'Unarchive Selected Contact List'"
        :text="'Are you sure you want to unarchive selected contact list?'"
        @close="undoSelected = []"
        @apply="unarchiveSelectedContact(undoSelected)"
        :textForSecondButton="'Yes'"
        :textForFirstButton="'No'"
    ></delete-modal>
  </div>
</template>

<script>
import MaillingList from "@/components/mailling/mailling-list";
import {mapActions, mapMutations, mapState} from "vuex";
import CreateEditContactList from "@/views/mailling/create-edit-contact-list/create-edit-contact-list";
import DeleteModal from "@/components/mailling/delete-modal";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import MailingSortBy from "@/views/mailling/mailing-sort-by";
export default {
  name: "mailling-contacts-list",
  components: {MailingSortBy, PaginationPanelDefault, DeleteModal, CreateEditContactList, MaillingList},
  computed: {
    ...mapState({
      contacts: state=> state.mailling.contacts,
      contactsNext: state => state.mailling.contactsNext,
    })
  },
  watch: {
    sort: function () {
      this.searchTemplate()
    },
    $route() {
      this.searchTemplate()
    },
  },
  data() {
    return {
      deleteSelected: [],
      undoSelected: [],
      idDeleteContact: null,
      showCreateContact: false,
      showCreateMailingContact: false,
      search: '',
      searchCopy: '',
      isLoad: false,
      sort: 'name',
      typeModal: 'create',
      isLoadNext: false,
      deleteRealItem: null,
    }
  },
  methods: {
    ...mapActions(['axiosDeleteRealMailingContact', 'axiosChangeContactList', 'axiosGetMailingContactList', 'axiosDeleteMailingContact', 'axiosGetNextMailingContacts']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    loadNext() {
      this.isLoadNext = true
      this.axiosGetNextMailingContacts().then(()=> {
        this.isLoadNext = false
      })
    },
    openModal() {
      this.showCreateMailingContact = true
      this.typeModal = 'create'
    },
    searchTemplate() {
      this.typeModal = 'create'
      this.searchCopy = this.search
      this.isLoad = true
      this.axiosGetMailingContactList({search: this.search, ordering: this.sort, is_archived: this.$route.path.indexOf('archive')>=0}).then(()=> {
        this.isLoad = false
      })
    },
    openEdit(item) {
      this.typeModal = 'edit'
      this.showCreateMailingContact = true
      this.$refs.modal.loadEditData(item.id)
    },
    async deleteContact(item) {
      this.axiosChangeContactList({id: item.id, name: item.name, emails_list: item.emails_list, is_archived: !item.is_archived})
      this.axiosDeleteMailingContact(item.id)
    },
    async deleteContactReal(item) {
      this.showLoad()
      let res = await this.axiosDeleteRealMailingContact(item.id)
      this.hideLoad()
      if(res) {
        this.deleteRealItem = null
        this.addTip('Mailing Template was deleted')
      } else {
        this.addTip('Error')
      }

    },
    async deleteContactRealSelected(array) {
      this.showLoad()
      for(let i=0; i< array.length; i++) {
        await this.axiosDeleteRealMailingContact(array[i].id)
      }
      this.hideLoad()
      this.$refs.list.clearSelected()
      this.deleteSelected = []
    },
    async unarchiveSelectedContact(array) {
      this.showLoad()
      for(let i=0; i<array.length; i++) {
        let item = array[i]
        this.axiosChangeContactList({id: item.id, name: item.name, emails_list: item.emails_list, is_archived: !item.is_archived})
        this.axiosDeleteMailingContact(item.id)
      }
      this.undoSelected = []
      this.$refs.list.clearSelected()
      this.hideLoad()
    }
  },
  beforeMount() {
    this.searchTemplate()
  }
}
</script>

<style scoped lang="scss">
.blue-button {
  background-color: var(--color-blue-dark);
  box-sizing: border-box;
  border: 1px solid var(--color-blue-dark);
  &:hover {
    color: var(--color-blue-dark);
    svg {
      path {
        fill: var(--color-blue-dark)
      }
    }
    background-color: transparent !important;
  }
}
</style>