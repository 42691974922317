<template>
  <div class="bank-details_list">
    <div class="bank-details_list_row head">
      <div class="bank-details_list_col name">Account Number and IBAN</div>
      <div class="bank-details_list_col holder">Holder</div>
      <div class="bank-details_list_col currency">Currency</div>
      <div class="bank-details_list_col balance">Balance</div>
      <div class="bank-details_list_col total">Total in USD</div>
    </div>
    <div class="bank-details_list_data">
      <div class="bank-details_list_row" v-for="item in list" :key="item.name">
        <div class="link" v-on:click="pushCashFlow(item)"></div>
        <div class="bank-details_list_col overflow-text-dots name">{{item.account_number}} {{item.iban}}</div>

        <div class="bank-details_list_col overflow-text-dots holder">{{item.holder}}</div>

        <div class="bank-details_list_col currency">{{item.currency}}</div>

        <div class="bank-details_list_col balance">{{item.balance | formatNumber}}</div>

        <div class="bank-details_list_col total">
          <p v-if="item.currency != 'USD'"><convert :currency="item.currency" :value="item.balance" /> USD</p>
        </div>

        <div class="bank-details_list_col menu">
          <counterparties-list-option-btn @editItem="$emit('edit-account', item)" @archiveItem="$emit('archive-account', item)" :canEdit="getPermissionsByType('edit_bank_accounts')" :canArchive="getPermissionsByType('archive_bank_accounts')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import convert from '@/components/accounts/bankAndCash/bankAsideConvert'
import counterpartiesListOptionBtn from '@/components/counterparties/counterpartiesList/counterpartiesListOptionBtn'
export default {
  props: ['list'],
  components: {counterpartiesListOptionBtn, convert},
  computed: {
    ...mapGetters(['getPermissionsByType']),
  },
  methods: {
    pushCashFlow(item) {
      this.$emit("emitPushCashFlow", item)
    }
  },
  mounted() {

  }
}
</script>


<style lang="scss">
  .bank-details_list{
    padding: 0 24px;
    &_row{
      display: flex;
      align-items: center;
      padding-top: 6px;
      padding-bottom: 6px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.5;
      transition: color 0.3s, background-color 0.3s;
      cursor: pointer;
      &:hover{
        background-color: var(--color-input);
        color: var(--color-blue-dark);
      }
      &:not(:last-of-type){
        border-bottom: 1px solid var(--color-card-border);
      }
      &.head{
        border-bottom: none;
        color: #9AA3B0;
        font-size: 12px;
      }
      position: relative;
      //&>* {
      //  position: relative;
      //  //z-index: 10;
      //}
    }
    &_data{
      background: var(--color-card);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
    &_col{
      position: relative;
      box-sizing: border-box;
      flex-shrink: 0;
      padding-right: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.name{
        width: 27.2%;
      }
      &.holder{
        width: 22.9%;
      }
      &.currency{
        width: 10%;
        text-align: center;
      }
      &.balance{
        width: 17.2%;
        text-align: right;
      }
      &.total{
        width: 20%;
        text-align: right;
        p{
          color: #9AA3B0;
        }
      }
      &.menu{
        padding-right: 0;
        padding-left: 2px;
      }
    }
  }
</style>

<style lang="scss" scoped>
.link {
  position: absolute;
  height: 100%;
  width: calc(100% - 70px);
  top: 0;
  left: 0;
  z-index: 40;
}
</style>