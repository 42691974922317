<template>
  <div class="border border-dark-gray m-b-4 rounded-lg overflow-hidden" v-if="filteredHireData.length">
    <div class="vessel_owners__table">
      <div class="colspan-1-6 bg-blue-dark uppercase p-x-3 p-y-2 fz13 fw500 text-white">OFF-HIRE</div>
    </div>

    <div 
      v-for="(hire, i) in filteredHireData"
      :key="i"
      class="vessel_owners__table-wrap bg-white overflow-hidden"
      :class="{'border-t border-dark-gray': i > 0}"
    >
      <div class="vessel_owners__table">
        <div class="border-t border-r p-x-3 p-y-2 text-dark fw500">{{(''+hire.index_hire_selected).length !== 0 && + hire.index_hire_selected !== null && hire.index_hire_selected > -1? 'Hire #'+ (1+hire.index_hire_selected) : hire.index_hire_selected === -2 ? 'Custom' : 'Not selected'}}</div>

        <div class="colspan-2-4 border-r p-x-2 p-y-2 text-dark relative">
          {{hire.additional_info.title ? hire.additional_info.title : ''}}
        </div>
        <div class="colspan-4-7 p-x-2 p-y-2 text-dark relative justify-start">
          {{hire.additional_info.hire_rate | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">Rate,&nbsp;{{activeCurrency}}/day</span>
        </div>
<!--        <div class="border-t colspan-2-6 p-x-2 p-y-2 text-dark">{{hire.additional_info.title ? hire.additional_info.title : ''}}</div>-->
      </div>

      <div class="vessel_owners__table" v-if="hire.additional_info.is_days && !hire.additional_info.hours">
        <div class="border-t border-r p-x-3 p-y-2 text-dark">From</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative">
          {{hire.additional_info.from_time ? hire.additional_info.from_time : ''}}
          <span class="absolute top-2 right-3">{{hire.additional_info.time_type}}</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{hire.additional_info.from_date ? hire.additional_info.from_date : ''}}</div>
        <div class="border-t border-r"></div>
        <div class="border-t"></div>
      </div>

      <div class="vessel_owners__table" v-if="hire.additional_info.is_days && !hire.additional_info.hours">
        <div class="border-t border-r p-x-3 p-y-2 text-dark">To</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative">
          {{hire.additional_info.to_time ? hire.additional_info.to_time : ''}}
          <span class="absolute top-2 right-3">{{hire.additional_info.time_type}}</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{hire.additional_info.to_date ? hire.additional_info.to_date : ''}}</div>
        <div class="border-t border-r"></div>
        <div class="border-t"></div>
      </div>


      <div class="vessel_owners__table" v-if="hire.additional_info.is_days && !hire.additional_info.hours">
        <div class="border-t border-r p-x-3 p-y-2 text-dark">Days</div>
        <div class="border-t border-r"></div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right">{{hire.additional_info.days | formatNumberFourZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right amount">{{hire.hire_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right amount">{{hire.hire_credit | formatNumberTwoZeroEmpty}}</div>
      </div>
      <div class="vessel_owners__table" v-if="hire.additional_info.is_days && hire.additional_info.hours">
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark">Time Lost</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative">
          {{hire.additional_info.hours | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">hrs</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative pr40">
          {{hire.additional_info.days | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">Days</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right amount">{{hire.hire_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right amount">{{hire.hire_credit | formatNumberTwoZeroEmpty}}</div>
      </div>
      <div class="vessel_owners__table"
        v-if="hire.additional_info.is_commission && (hire.additional_info.commision ||
          hire.commision_debit ||
          hire.commision_credit)"
      >
        <div class="border-t border-r p-x-3 p-y-2 text-dark fw500">Commission</div>
        <div class="border-t border-r"></div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative pr20">
          {{hire.additional_info.commision | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">%</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right amount">{{hire.commision_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right amount">{{hire.commision_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="hire.additional_info.is_cev && (hire.additional_info.rate ||
          hire.cev_debit ||
          hire.cev_credit)"
      >
        <div class="border-t border-r p-x-3 p-y-2 text-dark fw500">C/E/V</div>
        <div class="border-t border-r"></div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative pr110">
          {{hire.additional_info.rate | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">Rate, {{activeCurrency}}/PMPR</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark amount">{{hire.cev_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 amount">{{hire.cev_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="(hire.additional_info.is_hsfo && (hire.additional_info.hsfo_quantity ||
          hire.additional_info.hsfo_price ||
          hire.hsfo_debit ||
          hire.hsfo_credit)) ||
          (hire.additional_info.is_lsmgo && (hire.additional_info.lsmgo_quantity ||
          hire.additional_info.lsmgo_price ||
          hire.lsmgo_debit ||
          hire.lsmgo_credit))"
      >
        <div class="bg-white-dark border-t border-r p-x-3 p-y-2 text-dark fw500">Bunkers consumed during off-hire</div>
        <div class="bg-white-dark border-t border-r p-x-2 p-y-2 text-dark fz13 text-gray-text">Qntty, mt</div>
        <div class="bg-white-dark border-t border-r p-x-2 p-y-2 text-dark fz13 text-gray-text">Price, {{activeCurrency}}/mt</div>
        <div class="bg-white-dark border-t border-r"></div>
        <div class="bg-white-dark border-t"></div>
      </div>

      <div class="vessel_owners__table"
        v-if="hire.additional_info.is_hsfo && (hire.additional_info.hsfo_quantity ||
          hire.additional_info.hsfo_price ||
          hire.hsfo_debit ||
          hire.hsfo_credit)"
      >
        <div class="border-t border-r p-x-3 p-y-2 text-dark">VLSFO</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{hire.additional_info.hsfo_quantity | formatNumberThreeZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{hire.additional_info.hsfo_price | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right amount">{{hire.hsfo_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right amount">{{hire.hsfo_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="hire.additional_info.is_lsmgo && (hire.additional_info.lsmgo_quantity ||
          hire.additional_info.lsmgo_price ||
          hire.lsmgo_debit ||
          hire.lsmgo_credit)"
      >
        <div class="border-t border-r p-x-3 p-y-2 text-dark">LSMGO</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{hire.additional_info.lsmgo_quantity | formatNumberThreeZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{hire.additional_info.lsmgo_price | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right amount">{{hire.lsmgo_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right amount">{{hire.lsmgo_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table" v-if="hire.additional_info.comment">
        <div class="border-t border-r p-x-3 p-y-2 text-dark fw500">Comment</div>
        <div class="border-t colspan-2-6 p-x-2 p-y-2 text-dark">{{hire.additional_info.comment}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeCurrency: String,
    hireData: Array
  },
  computed: {
    filteredHireData() {
      return this.hireData.filter(el => {
        return el.additional_info.days ||
          el.hire_debit ||
          el.hire_credit ||
          el.additional_info.commision ||
          el.commision_debit ||
          el.commision_credit ||
          el.additional_info.rate ||
          el.cev_debit ||
          el.cev_credit ||
          el.additional_info.hsfo_quantity ||
          el.additional_info.hsfo_price ||
          el.hsfo_debit ||
          el.hsfo_credit ||
          el.additional_info.lsmgo_quantity ||
          el.additional_info.lsmgo_price ||
          el.lsmgo_debit ||
          el.lsmgo_credit ||
          el.additional_info.comment
      })
    }
  }
}
</script>

<style scoped>
  .pr40 {
    padding-right: 40px !important;
  }
  .pr20 {
    padding-right: 20px !important;
  }
  .pr110 {
    padding-right: 110px !important;
  }
</style>