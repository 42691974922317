<template>
  <div class="flex items-center justify-between p-l-6 p-r-4 p-y-4">
    <h1 class="fz18 fw500" v-if="$route.name == 'port-archive-contacts'">Archive Berths of {{name}} Port</h1>
    <h1 class="fz18 fw500" v-else>Contacts of {{name}}</h1>
    <button 
      v-if="$route.name != 'port-archive-contacts'"
      class="fz0 svg-white-dart" 
      @click="$emit('on-add')"
    >
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.222 11.222h-5.444V5.778a.778.778 0 00-1.556 0v5.444H5.778a.778.778 0 000 1.556h5.444v5.444a.778.778 0 001.556 0v-5.444h5.444a.778.778 0 000-1.556z" fill="#2C2C2C"/></svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    name: String
  }
}
</script>

<style>

</style>