<template>
  <div class="p-x-6 p-t-5 p-b-6">
    <div class="">
      <div class="entries-title p-x-6" :class="{'title-vessel': $route.path.indexOf('vessels') >= 0}">
        <div class="entries_row fz13 text-gray-text p-b-1.5 w100Proc">
          <div class="entries_col flex items-center p-l-3">
            Details
            <div class="m-l-1.5 fz0" v-tooltip="{content: `Type <br>Due Date <br>Vessel / CP Date <br>Real account / Port / Remark`, placement: 'right', offset: 10}">
              <svg class="entries_col__detail_svg" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill="#9AA3B0"/><path d="M4 5.0835C4.02539 4.31331 4.29622 3.69124 4.8125 3.21729C5.33301 2.7391 6.05241 2.5 6.9707 2.5C7.83398 2.5 8.53223 2.72428 9.06543 3.17285C9.60286 3.62142 9.87158 4.19694 9.87158 4.89941C9.87158 5.80924 9.42301 6.53076 8.52588 7.06396C8.11117 7.30518 7.82975 7.52734 7.68164 7.73047C7.53353 7.93359 7.45947 8.19385 7.45947 8.51123V8.87939H5.98682L5.98047 8.42871C5.95085 7.96745 6.02913 7.58024 6.21533 7.26709C6.40576 6.95394 6.72314 6.66618 7.16748 6.40381C7.56527 6.1626 7.84033 5.94043 7.99268 5.7373C8.14502 5.52995 8.22119 5.26969 8.22119 4.95654C8.22119 4.618 8.09847 4.34082 7.85303 4.125C7.60758 3.90495 7.28597 3.79492 6.88818 3.79492C6.48193 3.79492 6.15397 3.9113 5.9043 4.14404C5.65462 4.37679 5.51709 4.68994 5.4917 5.0835H4ZM7.396 11.6025C7.21826 11.776 7.00033 11.8628 6.74219 11.8628C6.48405 11.8628 6.264 11.776 6.08203 11.6025C5.9043 11.4248 5.81543 11.2111 5.81543 10.9614C5.81543 10.7118 5.9043 10.5002 6.08203 10.3267C6.264 10.1532 6.48405 10.0664 6.74219 10.0664C7.00033 10.0664 7.21826 10.1532 7.396 10.3267C7.57373 10.5002 7.6626 10.7118 7.6626 10.9614C7.6626 11.2111 7.57373 11.4248 7.396 11.6025Z" fill="white"/></svg>
            </div>
          </div>
          <div class="entries_col p-l-3">Debit</div>
          <div class="entries_col p-l-3">USD</div>
          <div class="entries_col p-l-3">Credit</div>
          <div class="entries_col flex items-center justify-between p-l-3">
            USD
            <div class="relative">
              <svg  @click.stop="openMenu" class="hover:fill-blue-dark cursor-pointer" width="14" height="14" viewBox="0 0 14 14" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM7.7778 6.2222H10.8888C11.3182 6.2222 11.6667 6.57069 11.6667 6.99993C11.6667 7.42931 11.3182 7.7778 10.8888 7.7778H7.7778V10.8888C7.7778 11.3182 7.42931 11.6667 6.99993 11.6667C6.57069 11.6667 6.2222 11.3182 6.2222 10.8888V7.7778H3.11106C2.68182 7.7778 2.33333 7.42931 2.33333 6.99993C2.33333 6.57069 2.68182 6.2222 3.11106 6.2222H6.2222V3.11106C6.2222 2.68182 6.57069 2.33333 6.99993 2.33333C7.42931 2.33333 7.7778 2.68182 7.7778 3.11106V6.2222Z"/></svg>
              <div class="option-btn_list right-0 top-full w-204" tabindex="0" @blur="closeMenu()" v-show="isShowMenu" ref="option_list">
                <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('statement')">
                  Statement Member & Counterparty Account
                  <svg :class="{'opacity-100': menu.indexOf('statement') >= 0}" class="m-l-2 m-r-0  flex-shrink-0 opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
                </div>
                <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('linked')">
                  Linked operations
                  <svg :class="{'opacity-100': menu.indexOf('linked') >= 0}" class="m-l-2 m-r-0 flex-shrink-0  opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
                </div>
                <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('port')">
                  Port / Remark
                  <svg :class="{'opacity-100': menu.indexOf('port') >= 0}" class="m-l-2 m-r-0 flex-shrink-0  opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
                </div>
                <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('account')">
                  Real Account
                  <svg :class="{'opacity-100': menu.indexOf('account') >= 0}" class="m-l-2 m-r-0 flex-shrink-0  opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h25"></div>
    </div>

    <div class="bg-white rounded-lg shadow wrap-list">
      <div 
        :class="{
          'border-blue-dark border-t': i,
        }"
        v-for="(item, i) in entries.items"
        :key="item.id+'_'+i+'_'+Math.random()"
      >
        <entries-list-item 
          :item="item"
          :menu="menu"
          @on-delete="$emit('on-delete', item, i)"
          @on-update="$emit('on-update')"
          @on-replace="(newItem) => onReplace(newItem, i)"
        />
        <entries-list-item 
          class="border-gray-light border-t"
          v-for="(subitem, i) in item.entries_payments"
          :key="subitem.id+'_'+i+'_'+Math.random()"
          :item="subitem"
          :menu="menu"
          @on-update="$emit('on-update')"
        />
        <entries-list-item
          class="border-gray-light border-t"
          v-for="(subitem, i) in item.entries_invoices"
          :key="subitem.id+'_'+i+'_'+Math.random()"
          :item="subitem"
          :menu="menu"
          @on-update="$emit('on-update')"
        />
        <entries-list-item 
          class="border-gray-light border-t"
          v-for="(subitem, i) in item.entries_owners"
          :key="subitem.id+'_'+i+'_'+Math.random()"
          :item="subitem"
          :menu="menu"
          @on-update="$emit('on-update')"
        />
      </div>
    </div>

    <pagination class="m-t-4 m-b-4" @load-more="onMore" :ifActive="ifLoaded" v-if="entriesNextUrl"/>
  </div>
</template>

<script>
import entriesListItem from '@/components/entries/entriesListItem'
import pagination from '@/components/ports/paginationDefault'
import moment from "moment"
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

export default {
  components: {
    entriesListItem,
    pagination
  },
  props: {
    entries: Object,
    filters: Object,
  },
  data() {
    return {
      menu: [
        "statement",
        "linked",
        "port",
        "account"
      ],
      isShowMenu: false,
      ifLoaded: false,
      types: {
        payment: 'Payment',
        income: 'Income',
        bill: 'Bill',
        invoice: 'Invoice',
        soa_with_owners: 'SOA with Owners',
        soa_sublet: 'SOA Sublet',
        voyage: 'Voyage Statement of Account',
      },
    }
  },
  computed: {
    ...mapState({
      entriesNextUrl: state => state.entries.entriesNextUrl,
      getTypesForDrop() {
        let arr = []
        Object.entries(this.types).forEach(([key, val]) => {
          arr.push({
            id: key,
            name: val
          })
        })
        return arr
      },
    }),
    ...mapGetters(['getPermissionsByType']),
  },
  methods: {
    ...mapActions(['axiosGetEntries', 'axiosGetXLSX']),
    ...mapMutations(['showLoad', 'hideLoad', 'setEntriesItems']),
    onReplace(newEntry, index) {
      this.entries.items.splice(index, 1, newEntry)
    },
    openMenu() {
      this.isShowMenu = true

      setTimeout(()=>{ // because when I call .focus() after change isOpen, it`s don`t work
        let optionList = this.$refs.option_list
        optionList.focus()
      }, 100)
    },
    closeMenu(item) {
      let index = this.menu.indexOf(item)
      if(index >= 0){
        this.menu.splice(index, 1)
      }else{
        this.menu.push(item)
      }
      this.isShowMenu = false
    },
    async onMore() {
      this.ifLoaded = true
      if(this.entriesNextUrl) {
        let entries_payments = []
        let entries_invoices = []
        let entries_owners = []
        this.entries.items.forEach(el=>{
          entries_payments.push(...el.entries_payments.map(el=>el.id))
          entries_invoices.push(...el.entries_invoices.map(el=>el.id))
          entries_owners.push(...el.entries_owners.map(el=>el.id))
        })
        let exclude_ids = [...entries_payments, ...entries_invoices, ...entries_owners]
        console.log('exclude_ids', exclude_ids);

        
        await this.axiosGetEntries({
          isNext: true,
          exclude_ids
        })
      }
      this.ifLoaded = false
    },
    getCpDate(item) {
      if(item?.vessel?.cargo_contracts){
        let result = item.vessel.cargo_contracts.filter(el => {
          return el.owner?.id == item['debit'].client?.id
        })
        return result.map(el => {
          return {
            id: el.id,
            name: el.cp_date
          }
        })
      }
      return []
    },
    async downloadXML() {
      this.showLoad();
      let entries = {entries: this.entries, items: this.entries.items, next: this.entriesNextUrl}
      let filter = JSON.parse(JSON.stringify(this.filters))
      filter.limit = 99999999
      await this.axiosGetEntries({params: filter})
      let formAxios= {
        sheet_title: "Journal Entries",
        file_name: "Journal Entries",
        data: []
      }
      formAxios.data.push({id:formAxios.data.length + 1, values: ['Details', null, null, null, 'Debit', null, null, 'USD', null, 'Credit', null, null, 'USD', null]});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      if(Array.isArray(this.entries.items) === true) {
        // console.log(this.entries.items)
        this.entries.items.map(a=> {
          let item = a;
          let activeItem = this.getTypesForDrop.find(el=>el.id === item.operation_type);
          let activeSM = this.getCpDate(item).find(el => el.name == moment(item['debit'].stat_member).format('YYYY-MM-DD'))
          let activeSMCredit = this.getCpDate(item).find(el => el.name == moment(item['credit'].stat_member).format('YYYY-MM-DD'))
          formAxios.data.push({id:formAxios.data.length + 1, values: [!item.is_manual ? this.getValueFromXml(this.types[item.operation_type]) : (typeof item.operation_type == 'string') ? (activeItem ? activeItem.name : null) : null, null, null, !item.is_manual ? (item.instance_id ? '#'+item.instance_id : null) : null, this.getValueFromXml(item['debit'].total_amount), null, item['debit'].currency ? (this.getValueFromXml(item['debit'].currency)): null, item.operation_type ? ((item.debit && item.debit.amount) ? this.getValueFromXml(item.debit.amount) : null) : null, null, this.getValueFromXml(item['credit'].total_amount), null, item['credit'].currency ? (this.getValueFromXml(item['credit'].currency)) : null, item.operation_type ? ((item.credit && item.credit.amount) ? this.getValueFromXml(item.credit.amount) : null) : null, null]});
          formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(item.due_date), null, null, null, this.getText1(item), null, null, null, null, this.getTextThreeColumns(item), null, null, null, null, null]});
          formAxios.data.push({id:formAxios.data.length + 1, values: [item.vessel ? this.getValueFromXml(item.vessel.name) : null, null, item.vessel ? this.getValueFromXml(item.vessel.cp_date) : 'CP Date', null, this.getTextTwoColumns1(item, this.getValueFromXml(this.getTextArray(item['debit'].bank_account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null)), this.getValueFromXml(this.getTextArray(item['debit'].account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null))), item.operation_type ? (this.getIsCol(item) ? activeSM ? this.getValueFromXml(this.getTextObject(activeSM, 'name')) : null : null) : null, null, null, null, this.getTextTwoColumns2(item, this.getValueFromXml(this.getTextArray(item['credit'].account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null))), null, item.operation_type ? (!this.getIsCol(item) ? activeSMCredit ? this.getValueFromXml(this.getTextObject(activeSMCredit, 'name')) : null : null) : null, null, null, null]});
          formAxios.data.push({id:formAxios.data.length + 1, values: [ this.menu.includes('account') ? (this.getValueFromXml(this.getTextArray(item.ports, 'name')) !== null ? "Real account" : null) : null, null, this.menu.includes('port') ? this.getValueFromXml(this.getTextArray(item.ports, 'name')) : null, (item.remark && this.menu.includes('port')) ? this.getValueFromXml(item.remark) : null, item.operation_type ? (this.getIsCol(item) ? (this.menu.includes('account') ? this.getValueFromXml(this.getLinkText(item, 'debit', 'account')) : null) : (this.menu.includes('linked') ? this.getValueFromXml(this.getLinkText(item, 'debit', 'bank')) : null)) : null, null, null, null, null, item.operation_type ? (!this.getIsCol(item) ? ( this.menu.includes('account') ? (this.getValueFromXml(this.getLinkText(item, 'credit', 'account'))): null) : ( this.menu.includes('linked') ? this.getValueFromXml(this.getLinkText(item, 'credit', 'bank')) : null)) : null, null, null, null, null, null]});
          if(item.operation_type && this.getIsCol(item)) {
            if(item.has_account && this.menu.includes('account')) {
              let activeRealSM = null;
              if(item['debit']?.statement_members?.length){
                activeRealSM = item['debit'].statement_members.map(el=>{
                  return this.getRealCpDate(item).find(item => item.name == moment(el, 'DD.MM.YYYY').format('YYYY-MM-DD'))
                })
              }
              formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, item['debit'].real_account ? this.getValueFromXml(this.getTextObject(item['debit'].real_account, 'company_name')) : null, null, activeRealSM ? this.getValueFromXml(this.getTextArray(activeRealSM, 'name')): null, null]});
            }
          }
          formAxios.data.push({id:formAxios.data.length + 1, values: []});

          if(Array.isArray(item.entries_payments) === true) {
            item.entries_payments.map(b=> {
              item = b;
              let activeItem = this.getTypesForDrop.find(el=>el.id === item.operation_type);
              let activeSM = this.getCpDate(item).find(el => el.name == moment(item['debit'].stat_member).format('YYYY-MM-DD'))
              let activeSMCredit = this.getCpDate(item).find(el => el.name == moment(item['credit'].stat_member).format('YYYY-MM-DD'))
              formAxios.data.push({id:formAxios.data.length + 1, values: [!item.is_manual ? this.getValueFromXml(this.types[item.operation_type]) : (typeof item.operation_type == 'string') ? (activeItem ? activeItem.name : null) : null, null, null, !item.is_manual ? (item.instance_id ? '#'+item.instance_id : null) : null, this.getValueFromXml(item['debit'].total_amount), null, item['debit'].currency ? (this.getValueFromXml(item['debit'].currency)): null, item.operation_type ? ((item.debit && item.debit.amount) ? this.getValueFromXml(item.debit.amount) : null) : null, null, this.getValueFromXml(item['credit'].total_amount), null, item['credit'].currency ? (this.getValueFromXml(item['credit'].currency)) : null, item.operation_type ? ((item.credit && item.credit.amount) ? this.getValueFromXml(item.credit.amount) : null) : null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(item.due_date), null, null, null, this.getText1(item), null, null, null, null, this.getTextThreeColumns(item), null, null, null, null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [item.vessel ? this.getValueFromXml(item.vessel.name) : null, null, item.vessel ? this.getValueFromXml(item.vessel.cp_date) : 'CP Date', null, this.getTextTwoColumns1(item, this.getValueFromXml(this.getTextArray(item['debit'].bank_account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null)), this.getValueFromXml(this.getTextArray(item['debit'].account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null))), item.operation_type ? (this.getIsCol(item) ? activeSM ? this.getValueFromXml(this.getTextObject(activeSM, 'name')) : null : null) : null, null, null, null, this.getTextTwoColumns2(item, this.getValueFromXml(this.getTextArray(item['credit'].account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null))), null, item.operation_type ? (!this.getIsCol(item) ? activeSMCredit ? this.getValueFromXml(this.getTextObject(activeSMCredit, 'name')) : null : null) : null, null, null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [ this.menu.includes('account') ? (this.getValueFromXml(this.getTextArray(item.ports, 'name')) !== null ? "Real account" : null) : null, null, this.menu.includes('port') ? this.getValueFromXml(this.getTextArray(item.ports, 'name')) : null, (item.remark && this.menu.includes('port')) ? this.getValueFromXml(item.remark) : null, item.operation_type ? (this.getIsCol(item) ? (this.menu.includes('account') ? this.getValueFromXml(this.getLinkText(item, 'debit', 'account')) : null) : (this.menu.includes('linked') ? this.getValueFromXml(this.getLinkText(item, 'debit', 'bank')) : null)) : null, null, null, null, null, item.operation_type ? (!this.getIsCol(item) ? ( this.menu.includes('account') ? (this.getValueFromXml(this.getLinkText(item, 'credit', 'account'))): null) : ( this.menu.includes('linked') ? this.getValueFromXml(this.getLinkText(item, 'credit', 'bank')) : null)) : null, null, null, null, null, null]});
              if(item.operation_type && this.getIsCol(item)) {
                if(item.has_account && this.menu.includes('account')) {
                  let activeRealSM = null;
                  if(item['debit']?.statement_members?.length){
                    activeRealSM = item['debit'].statement_members.map(el=>{
                      return this.getRealCpDate(item).find(item => item.name == moment(el, 'DD.MM.YYYY').format('YYYY-MM-DD'))
                    })
                  }
                  formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, item['debit'].real_account ? this.getValueFromXml(this.getTextObject(item['debit'].real_account, 'company_name')) : null, null, activeRealSM ? this.getValueFromXml(this.getTextArray(activeRealSM, 'name')): null, null]});
                }
              }
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            })
          }

          if(Array.isArray(item.entries_invoices) === true) {
            item.entries_invoices.map(b=> {
              item = b;
              let activeItem = this.getTypesForDrop.find(el=>el.id === item.operation_type);
              let activeSM = this.getCpDate(item).find(el => el.name == moment(item['debit'].stat_member).format('YYYY-MM-DD'))
              let activeSMCredit = this.getCpDate(item).find(el => el.name == moment(item['credit'].stat_member).format('YYYY-MM-DD'))
              formAxios.data.push({id:formAxios.data.length + 1, values: [!item.is_manual ? this.getValueFromXml(this.types[item.operation_type]) : (typeof item.operation_type == 'string') ? (activeItem ? activeItem.name : null) : null, null, null, !item.is_manual ? (item.instance_id ? '#'+item.instance_id : null) : null, this.getValueFromXml(item['debit'].total_amount), null, item['debit'].currency ? (this.getValueFromXml(item['debit'].currency)): null, item.operation_type ? ((item.debit && item.debit.amount) ? this.getValueFromXml(item.debit.amount) : null) : null, null, this.getValueFromXml(item['credit'].total_amount), null, item['credit'].currency ? (this.getValueFromXml(item['credit'].currency)) : null, item.operation_type ? ((item.credit && item.credit.amount) ? this.getValueFromXml(item.credit.amount) : null) : null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(item.due_date), null, null, null, this.getText1(item), null, null, null, null, this.getTextThreeColumns(item), null, null, null, null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [item.vessel ? this.getValueFromXml(item.vessel.name) : null, null, item.vessel ? this.getValueFromXml(item.vessel.cp_date) : 'CP Date', null, this.getTextTwoColumns1(item, this.getValueFromXml(this.getTextArray(item['debit'].bank_account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null)), this.getValueFromXml(this.getTextArray(item['debit'].account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null))), item.operation_type ? (this.getIsCol(item) ? activeSM ? this.getValueFromXml(this.getTextObject(activeSM, 'name')) : null : null) : null, null, null, null, this.getTextTwoColumns2(item, this.getValueFromXml(this.getTextArray(item['credit'].account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null))), null, item.operation_type ? (!this.getIsCol(item) ? activeSMCredit ? this.getValueFromXml(this.getTextObject(activeSMCredit, 'name')) : null : null) : null, null, null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [ this.menu.includes('account') ? (this.getValueFromXml(this.getTextArray(item.ports, 'name')) !== null ? "Real account" : null) : null, null, this.menu.includes('port') ? this.getValueFromXml(this.getTextArray(item.ports, 'name')) : null, (item.remark && this.menu.includes('port')) ? this.getValueFromXml(item.remark) : null, item.operation_type ? (this.getIsCol(item) ? (this.menu.includes('account') ? this.getValueFromXml(this.getLinkText(item, 'debit', 'account')) : null) : (this.menu.includes('linked') ? this.getValueFromXml(this.getLinkText(item, 'debit', 'bank')) : null)) : null, null, null, null, null, item.operation_type ? (!this.getIsCol(item) ? ( this.menu.includes('account') ? (this.getValueFromXml(this.getLinkText(item, 'credit', 'account'))): null) : ( this.menu.includes('linked') ? this.getValueFromXml(this.getLinkText(item, 'credit', 'bank')) : null)) : null, null, null, null, null, null]});
              if(item.operation_type && this.getIsCol(item)) {
                if(item.has_account && this.menu.includes('account')) {
                  let activeRealSM = null;
                  if(item['debit']?.statement_members?.length){
                    activeRealSM = item['debit'].statement_members.map(el=>{
                      return this.getRealCpDate(item).find(item => item.name == moment(el, 'DD.MM.YYYY').format('YYYY-MM-DD'))
                    })
                  }
                  formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, item['debit'].real_account ? this.getValueFromXml(this.getTextObject(item['debit'].real_account, 'company_name')) : null, null, activeRealSM ? this.getValueFromXml(this.getTextArray(activeRealSM, 'name')): null, null]});
                }
              }
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            })
          }

          if(Array.isArray(item.entries_owners) === true) {
            item.entries_owners.map(b=> {
              item = b;
              let activeItem = this.getTypesForDrop.find(el=>el.id === item.operation_type);
              let activeSM = this.getCpDate(item).find(el => el.name == moment(item['debit'].stat_member).format('YYYY-MM-DD'))
              let activeSMCredit = this.getCpDate(item).find(el => el.name == moment(item['credit'].stat_member).format('YYYY-MM-DD'))
              formAxios.data.push({id:formAxios.data.length + 1, values: [!item.is_manual ? this.getValueFromXml(this.types[item.operation_type]) : (typeof item.operation_type == 'string') ? (activeItem ? activeItem.name : null) : null, null, null, !item.is_manual ? (item.instance_id ? '#'+item.instance_id : null) : null, this.getValueFromXml(item['debit'].total_amount), null, item['debit'].currency ? (this.getValueFromXml(item['debit'].currency)): null, item.operation_type ? ((item.debit && item.debit.amount) ? this.getValueFromXml(item.debit.amount) : null) : null, null, this.getValueFromXml(item['credit'].total_amount), null, item['credit'].currency ? (this.getValueFromXml(item['credit'].currency)) : null, item.operation_type ? ((item.credit && item.credit.amount) ? this.getValueFromXml(item.credit.amount) : null) : null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(item.due_date), null, null, null, this.getText1(item), null, null, null, null, this.getTextThreeColumns(item), null, null, null, null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [item.vessel ? this.getValueFromXml(item.vessel.name) : null, null, item.vessel ? this.getValueFromXml(item.vessel.cp_date) : 'CP Date', null, this.getTextTwoColumns1(item, this.getValueFromXml(this.getTextArray(item['debit'].bank_account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null)), this.getValueFromXml(this.getTextArray(item['debit'].account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null))), item.operation_type ? (this.getIsCol(item) ? activeSM ? this.getValueFromXml(this.getTextObject(activeSM, 'name')) : null : null) : null, null, null, null, this.getTextTwoColumns2(item, this.getValueFromXml(this.getTextArray(item['credit'].account, 'name', this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? 'account_id' : null))), null, item.operation_type ? (!this.getIsCol(item) ? activeSMCredit ? this.getValueFromXml(this.getTextObject(activeSMCredit, 'name')) : null : null) : null, null, null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: [ this.menu.includes('account') ? (this.getValueFromXml(this.getTextArray(item.ports, 'name')) !== null ? "Real account" : null) : null, null, this.menu.includes('port') ? this.getValueFromXml(this.getTextArray(item.ports, 'name')) : null, (item.remark && this.menu.includes('port')) ? this.getValueFromXml(item.remark) : null, item.operation_type ? (this.getIsCol(item) ? (this.menu.includes('account') ? this.getValueFromXml(this.getLinkText(item, 'debit', 'account')) : null) : (this.menu.includes('linked') ? this.getValueFromXml(this.getLinkText(item, 'debit', 'bank')) : null)) : null, null, null, null, null, item.operation_type ? (!this.getIsCol(item) ? ( this.menu.includes('account') ? (this.getValueFromXml(this.getLinkText(item, 'credit', 'account'))): null) : ( this.menu.includes('linked') ? this.getValueFromXml(this.getLinkText(item, 'credit', 'bank')) : null)) : null, null, null, null, null, null]});
              if(item.operation_type && this.getIsCol(item)) {
                if(item.has_account && this.menu.includes('account')) {
                  let activeRealSM = null;
                  if(item['debit']?.statement_members?.length){
                    activeRealSM = item['debit'].statement_members.map(el=>{
                      return this.getRealCpDate(item).find(item => item.name == moment(el, 'DD.MM.YYYY').format('YYYY-MM-DD'))
                    })
                  }
                  formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, item['debit'].real_account ? this.getValueFromXml(this.getTextObject(item['debit'].real_account, 'company_name')) : null, null, activeRealSM ? this.getValueFromXml(this.getTextArray(activeRealSM, 'name')): null, null]});
                }
              }
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            })
          }
        })
      }

      this.setEntriesItems(entries)
      this.axiosGetXLSX(formAxios).then(response => {
        let link = response.data;
        function download(content, contentType, fileName) {
          var link1 = document.createElement("a");
          link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
          link1.download = fileName;
          link1.click();
        }
        download(link, "application/vnd.ms-excel", "Journal Entries.xls")
        this.hideLoad()

      });
    },
    getTextTwoColumns(item, bank, client) {
      if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'debit') {
        return bank
      } else if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'credit') {
        return client
      } else if(item.operation_type) {
        if(this.getIsCol(item)) {
          return client
        } else {
          return bank
        }
      }
      return null;
    },
    getTextTwoColumns2(item,client) {
      if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'debit') {
        if(this.menu.includes('port'))
          return client
        return null
      } else if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'credit') {
        return client
      } else if(item.operation_type) {
        if(this.getIsCol(item)) {
          return client
        } else {
          if(this.menu.includes('port'))
            return client
          return null
        }
      }
      return null;
    },
    getTextTwoColumns1(item, bank, client) {
      if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'debit') {
        return client
      } else if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'credit') {
        if(this.menu.includes('port'))
          return client
        return null
      } else if(item.operation_type) {
        if(this.getIsCol(item)) {
          if(this.menu.includes('port'))
            return client
          return null
        } else {
          return client
        }
      }
      return null;
    },
    getText1(item) {
      if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'debit') {
        return this.getValueFromXml(this.getTextForBankOrCash(item['debit'].bank, item['debit'].requisite))
      } else if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'credit') {
        return this.getValueFromXml(this.getTextClient(item['debit'].client))
      } else if(item.operation_type) {
        if(this.getIsCol(item)) {
          return this.getValueFromXml(this.getTextClient(item['debit'].client))
        } else {
          return this.getValueFromXml(this.getTextForBankOrCash(item['debit'].bank, item['debit'].requisite))
        }
      }
      return null;
    },
    getTextThreeColumns(item) {
      let bank = this.getValueFromXml(this.getTextForBankOrCash(item['credit'].bank, item['credit'].requisite))
      let client = this.getValueFromXml(this.getTextClient(item['credit'].client))
      // let bank = 1
      // let client = 0
      // console.log(item)
      if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'debit') {
        // console.log(1)
        return client
      } else if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'credit') {
        // console.log(2)
        return bank
      } else if(item.operation_type) {
        if(this.getIsCol(item)) {
          // console.log(3)
          return bank
        } else {
          // console.log(4)
          return client
        }
      }
      return null;
      // console.log(item)
      // if(item.operation_type && item.is_manual && item.column_type == 'unknown' && !this.getIsPayment(item)) {
      //   return null
      // } else if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'debit') {
      //   // return this.getValueFromXml(this.getTextClient(item['credit'].client))
      //   return '1'
      // } else if(item.operation_type && item.is_manual && !this.getIsPayment(item) && item.column_type == 'credit') {
      //   // return this.getValueFromXml(this.getTextForBankOrCash(item['credit'].bank, item['credit'].requisite))
      //   return '2'
      // } else if(item.operation_type) {
      //   if(this.getIsCol(item)) {
      //     // return this.getValueFromXml(this.getTextForBankOrCash(item['credit'].bank, item['credit'].requisite))
      //     return '3'
      //   } else {
      //     // return this.getValueFromXml(this.getTextClient(item['credit'].client))
      //     return '4'
      //   }
      // }
      // return null;
    },
    getRealCpDate(item) {
      if(item?.vessel?.cargo_contracts){
        return item.vessel.cargo_contracts.map(el => {
          return {
            id: el.id,
            name: el.cp_date
          }
        })
      }
      return []
    },
    getLinkText(item, column, part) {
      if(part === 'account') {
        if(this.menu.includes('linked')) {
          let linkeds = this.linkeds(item, column)
          if(linkeds && !linkeds.length) {
            return 'No Linked Operation'
          } else {
            if(Array.isArray(linkeds) === true) {
              let text = "";
              linkeds.map(link => {
                if(text.length === 0) {
                  // <span v-if="link.payment_type == 'expense' && link.data_type == 'payment'">Payment</span>
                  // <span v-else-if="link.payment_type == 'expense' && link.data_type == 'invoice'">Bill</span>
                  // <span v-else-if="link.payment_type == 'income' && link.data_type == 'payment'">Income</span>
                  // <span v-else-if="link.payment_type == 'income' && link.data_type == 'invoice'">Invoice</span>
                  // <span v-else-if="link.invoice_type">Voyage Statement of Account</span>
                  // <span v-else-if="link.contract.contract_type == 'SHIP'">SOA with Owners</span>
                  // <span v-else>SOA Sublet</span>
                  // #{{link.id}}
                  if(link.payment_type === 'expense' && link.data_type === 'payment') {
                    text = "Payment"
                  } else if(link.payment_type === 'expense' && link.data_type === 'invoice') {
                    text = "Bill"
                  } else if(link.payment_type === 'income' && link.data_type === 'payment'){
                    text = "Income"
                  } else if(link.payment_type === 'income' && link.data_type === 'invoice') {
                    text = "Invoice"
                  } else if(link.invoice_type) {
                    text = "Voyage Statement of Account"
                  } else if(link.contract.contract_type === 'SHIP') {
                    text = "SOA with Owners"
                  } else {
                    text = "SOA Sublet"
                  }
                  text+= " #"+link.id
                } else {
                  if(link.payment_type === 'expense' && link.data_type === 'payment') {
                    text = ", Payment"
                  } else if(link.payment_type === 'expense' && link.data_type === 'invoice') {
                    text = ", Bill"
                  } else if(link.payment_type === 'income' && link.data_type === 'payment'){
                    text = ", Income"
                  } else if(link.payment_type === 'income' && link.data_type === 'invoice') {
                    text = ", Invoice"
                  } else if(link.invoice_type) {
                    text = ", Voyage Statement of Account"
                  } else if(link.contract.contract_type === 'SHIP') {
                    text = ", SOA with Owners"
                  } else {
                    text = ", SOA Sublet"
                  }
                  text+= " #"+link.id
                }
              })
              return text;
            }
          }
        }
      }
      if(part === 'bank') {
        if(item.has_account && this.menu.includes('account')) {
          if(!item.credit.client && !item.debit.client) {
            return 'Counterparty'
          } else {
            return column === 'debit' ? item.credit.client.company_name : item.debit.client.company_name
          }
        }
      }
      return  null
    },
    linkeds(item, column) {
      let linkedPayments= item[column].linked_payments;
      let linkedInvoices= item[column].linked_owners;
      let linkedOwners= item[column].linked_invoices;
      let linkedType= item.operation_type === 'payment' || item.operation_type === 'income' ? 'Payments' : 'Invoices';
      if(linkedType === 'Invoices') return linkedPayments
      else if(linkedType === 'Payments'){
        let result = [...linkedPayments, ...linkedInvoices, ...linkedOwners]
        return result
      }
      return []
    },
    getIsCol(item) {
      if(item.operation_type == 'soa_with_owners' || item.operation_type == 'soa_sublet' || item.operation_type == 'voyage'){
        return item.transaction_type
      }else{
        return item.operation_type == 'payment' || item.operation_type == 'invoice'
      }
    },
    getTextClient(client) {
      if(client !== null) {
        return client.company_name
      }
      return  null
    },
    getTextForBankOrCash(bank, cash) {
      // console.log(bank)
      // console.log(cash)

      let text = "";
      if(Array.isArray(bank) === true || Array.isArray(cash) === true) {
        if(Array.isArray(bank) === true) {
          bank.map(a=> {
            if (text.length === 0) {
              text += "" + a.name + ": " + a.holder;
            } else {
              text += ", " + a.name + ": " + a.holder;
            }
          })
        }
        if(Array.isArray(cash) === true) {
          cash.map(a=> {
            if(text.length === 0) {
              text += ""+a.holder;
            } else {
              text += ", "+ a.holder;
            }
          })
        }
      } else {
        if(bank !== null) {
          if (text.length === 0) {
            text += "" + bank.name + ": " + bank.holder;
          }
        }
        if(cash !== null) {
          if(text.length === 0) {
            text += ""+cash.holder;
          } else {
            text += ", "+ cash.holder;
          }
        }
      }


      return text;
    },
    getTextArray(array, valueName, beforeValue) {
      let text = "";
      if(Array.isArray(array) === true) {
        array.map(a=> {
          if(text.length !== 0) {
            if(beforeValue) {
              text+=", "+ a[beforeValue] + ": " +a[valueName];
            } else {
              text+=", "+a[valueName];
            }
          } else {
            if(beforeValue) {
              text+= "" + a[beforeValue] + ": "+a[valueName];
            } else {
              text+= ""+a[valueName];
            }
          }
        })
      }
      return text;
    },
    getTextObject(obj, valueName, beforeValue) {
      let text = "";
      if(beforeValue && obj[valueName] && obj[beforeValue]) {
        text+=""+ obj[beforeValue] + ": " +obj[valueName];
      } else {
        if(obj[valueName])
          text+=""+obj[valueName];
      }
      return text;
    },
    getIsPayment(item) {
      return item.operation_type == 'payment' || item.operation_type == 'invoice' || item.operation_type == 'bill' || item.operation_type == 'income'
    },
    isItemSoa(item) {
      return item.operation_type == 'soa_sublet' || item.operation_type == 'soa_with_owners' || item.operation_type == 'voyage'
    },
    getValueFromXml(value) {
      if(value === 'No Linked Operation') {
        return null
      }
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined) {
        if(moment(""+value, "YYYY-MM-DD HH:mm:ss", true).isValid() || moment(""+value, "YYYY-MM-DD HH:mm", true).isValid() || moment(""+value, "YYYY-MM-DD", true).isValid()) {
          return moment(value).format("DD.MM.YYYY")
        }
        if(!isNaN(value)) {
          let fixedNumber = value ? value : 0
          if(fixedNumber < 0) {
            fixedNumber = fixedNumber * (-1)
          }
          fixedNumber = parseFloat(fixedNumber)

            fixedNumber = (''+((fixedNumber).toFixed(4))).replaceAll(".", ",").replaceAll(' ', '')
          return fixedNumber
        }
        return ""+value
      }
      return null
    },
    getUrl(obj, item) {
      return obj => {
        if(!obj.id) return '/accounts/entries'

        if(obj.model == 'bill' || obj.model == 'income')
          return `/vessels/${item.vessel.id}/open/accounting-invoice/${obj.id}/detail`

        if(obj.model == 'payment' || obj.model == 'invoice')
          return `/payment/list/detail/${obj.id}`

        if(obj.model == 'soa_sublet')
          return `/vessels/${item.parent_vessel}/open/charterers/${item.vessel.id}/soa`

        if(obj.model == 'soa_with_owners')
          return `/vessels/${item.vessel.id}/open/owners`

        if(obj.model == 'voyage'){
          if(this.item.parent_vessel){
            return `/vessels/${item.parent_vessel}/open/charterers/${item.vessel.id}/vsoa`
          }else{
            return ''
          }
        }

        if(obj.model == 'Contract')
          return `/vessels/${obj.id}/open/detail`

        return '/accounts/entries'
      }
    },

  },
}
</script>

<style lang="scss">
  .entries{
    &_row{
      display: flex;
    }
    &_col{
      box-sizing: border-box;
      &:nth-child(1){width: 21.7%;}
      &:nth-child(2),
      &:nth-child(4){width: 25.57%;}
      &:nth-child(3),
      &:nth-child(5){width: 13.58%;}
    }
  }
  .tooltip-inner{
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    max-width: 800px;
    background-color: var(--color-dark-gray);
    color: var(--color-white);
    border-radius: 0.25rem;
    word-break: break-all;
  }
  .entries_col__detail_svg:hover{
    circle{
      fill: var(--color-blue-dark);
    }
  }
</style>
<style scoped lang="scss">
.wrap-list {
  background-color: var(--color-bgc-page);
  border-radius: 0 0 0.5rem 0.5rem ;
  border: 1px solid var(--color-border);
  &>*:nth-child(even) {
    background-color: var(--color-bgc-page);
  }
}
.entries-title {
  left: 0;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 20;
  border-bottom: none;
  background-color: var(--color-bgc-page);
  .entries_row {
    display: flex;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid var(--color-border);
    background-color: var(--color-bgc-page);
    height: 26px;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 30;
    transform: translateY(-100%);
    width: 100%;
    height: 20px;
    background-color: var(--color-bgc-page);
  }
}
.title-vessel {
  background-color: var(--color-bgc-page-white);
  &::after {
    background-color: var(--color-bgc-page-white);
  }
}
</style>