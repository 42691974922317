<template>
  <div class="" v-click-outside="closeDrop">
    <button class="button-click" v-on:click="show = !show">

    </button>
    <div class="list" v-if="show">
      <button class="fz13" :class="{'active': indexSelect === -2}" v-on:click="selectNumber(-2)">
        Custom
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z" fill="#319FEF"/>
        </svg>
      </button>
      <button v-for="(l, i) in list" class="fz13" :class="{'active': indexSelect === i}" :key="i" v-on:click="selectNumber(i)">
        Hire #{{i+1}}
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z" fill="#319FEF"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "drop-select-number",
  props: {
    count: Number,
    indexSelect: {
      default: -1,
    }
  },
  data() {
    return {
      show: false,
      list: [],
    }
  },
  watch: {
    count: function () {
      this.list = []
      for(let i=0; i<this.count; i++) {
        this.list.push('a')
      }
    }
  },
  methods: {
    closeDrop() {
      this.show = false
    },
    selectNumber(index) {
      if(this.indexSelect === index) {
        index = -1
      }
      this.$emit('selectIndex', index)
      this.closeDrop()
    }
  },
  mounted() {
    this.list = []
    for(let i=0; i<this.count; i++) {
      this.list.push('a')
    }
  }
}
</script>

<style scoped lang="scss">
  .button-click {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .list {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 10;
    position: absolute;
    bottom: -1px;
    left: 0;
    transform: translateY(100%);
    background-color: var(--color-modal);
    max-height: 200px;
    width: 133px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    button {
      position: relative;
      text-align: start;
      width: 100%;
      height: 30px;
      padding: 4px 6px;
      box-sizing: border-box;
      &:hover {
        background-color: var(--color-hover-list) !important;
      }

      display: flex;
      align-items: center;
      svg {
        opacity: 0;
        position: absolute;
        right: 10px;
      }
    }
  }
  .active {
    svg {
      opacity: 1 !important;
    }
  }
</style>