<template>
  <div class="border border-dark-gray m-b-4 rounded-lg overflow-hidden">
    <div class="vessel_owners__table bg-blue-dark">
      <div class="first_element_stroke colspan-1-6 uppercase p-x-3 p-y-2 fz13 fw500 text-white">Charter Hire</div>
    </div>

    <div class="vessel_owners__table-wrap bg-white rounded-bl-lg rounded-br-lg overflow-hidden" v-if="!hireData.length">
      <div class="vessel_owners__table">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 text-dark fw500">Hire #1</div>
        <div class="colspan-2-4 border-r border-b p-x-2 p-y-2 text-dark relative">
          <span class="absolute top-2 right-2 text-gray-text">Rate,&nbsp;{{activeCurrency}}/day</span>
        </div>
        <div class="border-r border-b"></div>
        <div class="border-b"></div>
      </div>

      <div class="vessel_owners__table">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 text-dark">From</div>
        <div class="border-r border-b p-x-2 p-y-2 text-dark relative"></div>
        <div class="border-r border-b p-x-2 p-y-2 text-dark"></div>
        <div class="border-r border-b"></div>
        <div class="border-b"></div>
      </div>

      <div class="vessel_owners__table">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 text-dark">To</div>
        <div class="border-r border-b p-x-2 p-y-2 text-dark relative"></div>
        <div class="border-r border-b p-x-2 p-y-2 text-dark"></div>
        <div class="border-r border-b"></div>
        <div class="border-b"></div>
      </div>

      <div class="vessel_owners__table">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 text-dark">Days</div>
        <div class="colspan-2-4 border-r border-b p-x-2 p-y-2 text-dark text-align-right"></div>
        <div class="border-r border-b"></div>
        <div class="border-b p-x-2 p-y-2 text-align-right"></div>
      </div>

      <div class="vessel_owners__table">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 fz13 fw500 text-dark">Commission</div>
        <div class="border-r border-b"></div>
        <div class="border-r border-b p-x-2 p-y-2 relative"></div>
        <div class="border-r border-b p-x-2 p-y-2 text-align-right"></div>
        <div class="border-b"></div>
      </div>

      <div class="vessel_owners__table">
        <div class="first_element_stroke border-r p-x-3 p-y-2 fz13 fw500 text-dark">Broker commission</div>
        <div class="border-r"></div>
        <div class="border-r p-x-2 p-y-2 relative"></div>
        <div class="border-r p-x-2 p-y-2 text-align-right"></div>
        <div class=""></div>
      </div>
    </div>

    <div 
      class="vessel_owners__table-wrap bg-white overflow-hidden"
      :class="{'border-t border-dark-gray': i > 0}"
      v-for="(hire, i) in hireData"
      :key="hire.id"
    >
      <div class="vessel_owners__table">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 text-dark fw500">Hire #{{i+1}}</div>
        <div class="colspan-2-4 border-r border-b p-x-2 p-y-2 text-dark relative pr95">
          {{hire.additional_info.rate | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">Rate,&nbsp;{{activeCurrency}}/day</span>
        </div>
        <div class="border-r border-b"></div>
        <div class="border-b"></div>
      </div>

      <div class="vessel_owners__table" v-if="hire.additional_info.is_days">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 text-dark">From</div>
        <div class="border-r border-b p-x-2 p-y-2 text-dark relative">
          {{hire.additional_info.from_time ? hire.additional_info.from_time : ''}}
          <span class="absolute top-2 right-2 text-gray-text">{{hire.additional_info.time_type}}</span>
        </div>
        <div class="border-r border-b p-x-2 p-y-2 text-dark">
          {{hire.additional_info.from_date ? hire.additional_info.from_date : ''}}
        </div>
        <div class="border-r border-b"></div>
        <div class="border-b"></div>
      </div>

      <div class="vessel_owners__table" v-if="hire.additional_info.is_days">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 text-dark">To</div>
        <div class="border-r border-b p-x-2 p-y-2 text-dark relative">
          {{hire.additional_info.to_time ? hire.additional_info.to_time : ''}}
          <span class="absolute top-2 right-2 text-gray-text">{{hire.additional_info.time_type}}</span>
        </div>
        <div class="border-r border-b p-x-2 p-y-2 text-dark">
          {{hire.additional_info.to_date ? hire.additional_info.to_date : ''}}
        </div>
        <div class="border-r border-b"></div>
        <div class="border-b"></div>
      </div>

      <div class="vessel_owners__table" v-if="hire.additional_info.is_days">
        <div class="first_element_stroke border-b border-r p-x-3 p-y-2 text-dark">Days</div>
        <div class="border-b colspan-2-4 border-r p-x-2 p-y-2 text-dark text-align-right">{{hire.additional_info.days | formatNumberThreeZeroEmpty}}</div>
        <div class="border-b border-r p-x-2 p-y-2 text-dark text-align-right amount">{{hire.hire_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-b p-x-2 p-y-2 text-align-right amount">{{hire.hire_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table" v-if="hire.additional_info.is_commission">
        <div class="first_element_stroke border-r border-b p-x-3 p-y-2 fz13 fw500 text-dark">Commission</div>
        <div class="border-r border-b"></div>
        <div class="border-r border-b p-x-2 p-y-2 relative">
          {{hire.additional_info.commision | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">%</span>
        </div>
        <div class="border-r border-b p-x-2 p-y-2 text-align-right amount">{{hire.commision_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-b p-x-2 p-y-2 text-align-right amount">{{hire.commision_credit | formatNumberTwoZeroEmpty}}</div>
      </div>
      
      <div class="vessel_owners__table" v-if="hire.additional_info.is_broker">
        <div class="first_element_stroke border-r p-x-3 p-y-2 fz13 fw500 text-dark">Broker commission</div>
        <div class="border-r"></div>
        <div class="border-r p-x-2 p-y-2 relative">
          {{hire.additional_info.broker_commision | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">%</span>
        </div>
        <div class="border-r p-x-2 p-y-2 text-align-right amount">{{hire.broker_commision_debit | formatNumberTwoZeroEmpty}}</div>
        <div class=" p-x-2 p-y-2 text-align-right amount">{{hire.broker_commision_credit | formatNumberTwoZeroEmpty}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeCurrency: String,
    hireData: Array
  },
}
</script>

<style>

</style>