<template>
  <Transition name="fade">
    <div class="vue-modal" v-if="visibility">
      <div class="modal-container">
        <div class="modal-header">
          <span class="header-name">{{errorText}}</span>
          <!-- <span class="modal-close" @click.stop="hideModal">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2486 5.03572C19.5415 5.32861 19.5415 5.80348 19.2486 6.09638L13.2032 12.1417L19.2486 18.1871C19.5415 18.48 19.5415 18.9549 19.2486 19.2478C18.9557 19.5407 18.4808 19.5407 18.1879 19.2478L12.1426 13.2024L6.09721 19.2478C5.80432 19.5407 5.32944 19.5407 5.03655 19.2478C4.74366 18.9549 4.74366 18.48 5.03655 18.1871L11.0819 12.1417L5.03655 6.09637C4.74366 5.80348 4.74366 5.32861 5.03655 5.03571C5.32944 4.74282 5.80432 4.74282 6.09721 5.03571L12.1426 11.0811L18.1879 5.03572C18.4808 4.74282 18.9557 4.74282 19.2486 5.03572Z"
                fill="#262626"
              />
            </svg>
          </span> -->
        </div>
        <div class="modal-content">
          <p class="text">You can unlink this tag from the previous folder and link it to the current one. Or do not attach the tag to the current folder and leave it in the previous folder.</p>
        </div>
        <div class="modal-footer">
          <button class="modal-btn" @click="hideModal">
            Attach tag to this folder
          </button>
          <button class="cancel-btn" @click="removeTag">Don’t attach tag to this folder</button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
  export default {
    name: 'errorTag-modal',
    data: () => ({
      visibility: false,
      errorText: '',
      currentTag: ''
    }),
    methods: {
      showModal(errorText = '', currentTag = ''){
        this.currentTag = currentTag
        this.errorText = errorText
        this.visibility = true
      },
      hideModal(){
        this.visibility = false
        this.currentTag = ''
      },
      removeTag(){
        this.$emit('removeTag', this.currentTag)
        this.hideModal()
      }
    },
  }
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.vue-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.3);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: auto;
  }

  .modal-container {
    width: 508px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 13px 24px 0;
    // padding-top: 13px;
    // position: relative;
    // padding: 24px;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  
  // border-bottom: 1px solid #DEE3EA;
  padding: 14px 16px;
  .header-name{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
  }
}
.modal-footer{
  display: flex;
  column-gap: 16px;
  // padding: 8px 24px;
  flex-direction: column;
  row-gap: 8px;
  // border-top: 1px solid #DEE3EA;
  padding: 8px 16px 16px;

  .modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-white);
    background: var(--color-blue);
    transition: .3s;
    // width: 180px;
    width: 100%;
    border-radius: 4px;
    height: 32px;
    // padding: 8px 24px;
    &:hover{
      background: var(--color-blue-dark);
    }
    &.disabled{
      pointer-events: none;
      opacity: .5;
    }
  }
  .cancel-btn{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--color-blue-dark);
    transition: .3s;
    // padding: 8px 24px;
    height: 32px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--color-blue-dark);
    &:hover{
      background: var(--color-blue-dark);
      color: var(--color-white);
    }
  }
}
.modal-content{
 padding: 12px 0 18px 0;
 border: none;
 border-radius: 0;
 .text{
  padding: 0 16px;
 }
}
.modal-close {
  &:hover {
    cursor: pointer;
  }
}

</style>