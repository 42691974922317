import {mapActions, mapMutations} from "vuex";

export default {
    data() {
        return {
            mailForAttach: null,
            showAllAttach: false,
            bollShowAll: false,
            multipleMailAttach: false,
        }
    },
    watch: {
      'mailDetailInfo': function () {
          setTimeout(()=> {
              this.getBoolShowAll()
          }, 0)
      }
    },
    methods: {
        ...mapMutations(['showLoad', 'hideLoad']),
        ...mapActions(['axiosUnPinMessageFromObject']),
        setIdMailForAttach(mail) {
            this.mailForAttach = mail
        },
        checkUpdateMailDetailInfo(id) {
            if(this.mailDetailInfo.id == id) {
                this.updateMailDetailInfo()
            }
        },
        async unPinAttach(item) {
            // this.showLoad()
            let data = {}
            console.log(this.mailDetailInfo)
            if(item.cp_date !== undefined) {
                data['contracts_id'] = [item.id]
                this.mailDetailInfo.contracts = this.mailDetailInfo.contracts.filter(el=> el.id !== item.id)
            } else {
                this.mailDetailInfo.ports = this.mailDetailInfo.ports.filter(el=> el.id !== item.id)
                data['ports_id'] = [item.id]
            }
            await this.axiosUnPinMessageFromObject({id: this.mailDetailInfo.id, data: data})
            // this.hideLoad()
        },
        async getBoolShowAll() {
            await this.$nextTick()
            let item = document.querySelector('.wrap-items-attach')
            if(item) {
                let width = item.clientWidth
                let widthChildren = 0
                for(let i = 0; i<item.children.length; i++) {
                    widthChildren+=item.children[i].clientWidth + 4
                }
                this.bollShowAll = width < widthChildren
            } else {
                this.bollShowAll =  false
            }
        }
    }
}