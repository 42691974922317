<template>
  <div class="counterparties-drop position-relative" :class="{'height36' : height36, 'bgcWhite' : bgcWhite}">
    <p class="counterparties-drop_title fz13 text-gray" v-if="title.length !== 0">{{formatCpDate ? formatCpDateReturn(title) : title}}</p>
    <b-dropdown :dropup="isDropup" ref="dropdown" :disabled="isDisabled" @hide="hideEvent" @show="showEvent">
      <template #button-content>
        <span class="button-text text-gray-light p-l-1" v-if="!printDropResult && selectItems.length === 0 && !showNoEmployees">{{placeholder}}</span>
        <span class="button-text block-imgs" v-if="selectItems.length === 0 && showNoEmployees">
           <svg width="24" class="svg-da" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.212 10.8719C13.9294 10.8719 15.3223 9.47945 15.3223 7.7612C15.3223 6.04334 13.9302 4.65048 12.212 4.65088C10.4949 4.65088 9.10206 6.04374 9.10206 7.7612C9.10206 9.47945 10.4949 10.8719 12.212 10.8719Z" stroke="#4B4B4B" stroke-width="1.4"/>
            <path d="M6.41675 17.2501C6.41675 18.0457 7.06159 18.6906 7.85722 18.6906H16.9136C17.7088 18.6906 18.3537 18.0457 18.3537 17.2501V16.4378C18.3537 14.6961 16.9425 13.2981 15.2017 13.2981H9.57984C7.83937 13.2981 6.41675 14.6969 6.41675 16.4378V17.2501Z" stroke="#4B4B4B" stroke-width="1.4"/>
          </svg>
          <p class="fz13 text-dark1">No assignee</p>
        </span>
        <span class="button-text block-imgs" v-else-if="selectItems.length !== 0 && selectItems.length < 2" >
          <img v-if="selectItems[0].avatar !== null" :src="getUrlUser(selectItems[0].avatar)" class="img-profile" alt="">
              <svg v-if="selectItems[0].avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="80" cy="80" r="80" fill="white"/>
                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
              </svg>
            <p class="name fz13 text-dark1 fw500">{{selectItems[0].first_name}} {{selectItems[0].last_name}}</p>
        </span>
        <div class="block-avatar-user" v-if="selectItems.length >1">
          <div class="wrap" v-for="(e, i) in selectItems" :key="i" :style="'z-index:' + (selectItems.length - i)">
            <div class=" avatar-user" v-if="i<6">
              <img v-if="e.avatar !== null" :src="getUrlUser(e.avatar)" class="img-profile" alt="">
              <svg v-if="e.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="80" cy="80" r="80" fill="white"/>
                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
              </svg>
            </div>
          </div>
          <p v-if="selectItems.length === 1" class="nameuser fz13 text-dark1">{{selectItems[0].first_name}} {{selectItems[0].last_name}}</p>
          <div class="more-avatar fz11 text-dark1" v-if="selectItems.length >= 7">
            +{{(selectItems).length - 6}}
          </div>
        </div>
        <span class="button-drop-svg"><svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/></svg></span>
      </template>

      <div class="search">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1535 14.2913L10.9827 10.1205C11.7906 9.12262 12.277 7.85457 12.277 6.47348C12.277 3.27251 9.67246 0.667969 6.47149 0.667969C3.27053 0.667969 0.666016 3.27248 0.666016 6.47345C0.666016 9.67442 3.27056 12.279 6.47152 12.279C7.85261 12.279 9.12066 11.7925 10.1186 10.9846L14.2894 15.1554C14.4085 15.2746 14.565 15.3345 14.7214 15.3345C14.8779 15.3345 15.0343 15.2746 15.1535 15.1554C15.3924 14.9165 15.3924 14.5303 15.1535 14.2913ZM6.47152 11.0567C3.94398 11.0567 1.88824 9.00099 1.88824 6.47345C1.88824 3.94591 3.94398 1.89016 6.47152 1.89016C8.99906 1.89016 11.0548 3.94591 11.0548 6.47345C11.0548 9.00099 8.99903 11.0567 6.47152 11.0567Z" fill="#2C2C2C"/></svg>
        <input type="text" ref="searchInput" v-model="searchText" placeholder="Search">
      </div>

      <div class="content">
        <li v-if="searchText && !onSearch.length"><span class="dropdown-item text-dark1">Nothing found</span></li>

        <li v-if="isSelectedPrev && !list.length"><span class="dropdown-item text-dark1">Selected type has`t sybtypes</span></li>
        <li v-else-if="!list.length && !searchText"><span class="dropdown-item text-dark">{{textNoSelect}}</span></li>
        <li v-for="item in onSearch" :key="item.id">
          <a href="#" class="dropdown-item text-dark1" @click.prevent="select(item)" :title="item.name">
            <img v-if="item.avatar !== null" :src="getUrlUser(item.avatar)" class="img-profile" alt="">
            <svg v-if="item.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="80" cy="80" r="80" fill="white"/>
              <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
            </svg>
            <p class="name fz13 text-dark1 fw500">
              {{item.first_name}} {{ item.last_name }}
            </p>
          </a>
          <svg v-if="checkedSelected(item)" class="dropdown-check" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z" fill="#319FEF"/></svg>
        </li>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: "async-select-employees",

  props: {
    showNoEmployees: Boolean,
    bgcWhite: Boolean,
    height36: Boolean,
    formatCpDate: {
      type: Boolean,
      default: false,
    },
    activeItem: {
      default: null
    },
    value: [Object, Array],
    title: {
      type: String,
      default: ''
    },
    isSelectedPrev: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    removeSelectItemBeforeClick: {
      type: Boolean,
      default: true
    },
    textNoSelect: {
      type: String,
      default: "List empty",
    },
    dontSelect: {
      type: Boolean,
      default: false,
    },
    isDropup: {
      type: Boolean,
      dafault: false
    },
    permissionAssigned: String,
  },
  watch: {
    value: function() {
        if(Array.isArray(this.value)) {
          this.selectItems = this.value
        } else if(this.value) {
          this.selectItems = [this.value]
        } else {
          this.selectItems = []
        }
    },
    permissionAssigned: function () {
      if(this.permissionAssigned === 'ship') {
        this.arrayPermission = this.getPermissionsFullByType("assign_contracts_to");
      } else if(this.permissionAssigned === 'cargo') {
        this.arrayPermission = this.getPermissionsFullByType("assign_voyage_contracts_to")
      } else if(this.permissionAssigned === 'sublet') {
        this.arrayPermission = this.getPermissionsFullByType("assign_sublet_contracts_to");
      } else if(this.permissionAssigned === 'ship_task') {
        this.arrayPermission = this.getPermissionsFullByType("assign_tasks_to");
      } else {
        this.arrayPermission = this.getPermissionsForUserCanAssignTask;
      }
      //
      // if(this.permissionAssigned === 'vessel') {
      // this.arrayPermission = this.getPermissionsFullByType("assign_voyage_contracts_to")
      // } else if(this.permissionAssigned === 'cargo') {
      //   this.arrayPermission = this.getPermissionsFullByType("assign_sublet_contracts_to");
      // } else if(this.permissionAssigned === 'cargoVesselTask') {
      //   this.arrayPermission = this.getPermissionsFullByType("assign_tasks_to");
      // } else {
      //   this.arrayPermission = this.getPermissionsForUserCanAssignTask;
      // }
      this.list = this.arrayPermission?.users ? this.arrayPermission.users : [];
    },
    // activeItem() {
    //   //console.log('this.activeItem ', this.activeItem);
    //   if(this.activeItem) {
    //     //this.select(this.activeItem)
    //     if(this.isMultiple) {
    //       this.selectItems = [];
    //       this.activeItemLocal = JSON.parse(JSON.stringify(this.activeItem))
    //       this.activeItem.forEach(item => {
    //         this.select(item)
    //       });
    //     }else{
    //       this.activeItemLocal = JSON.parse(JSON.stringify(this.activeItem))
    //       if(!this.dontSelect) {
    //         this.select(this.activeItem)
    //       } else {
    //         let type = this.activeItem
    //         if(this.isMultiple) {
    //           let indexOfElem = this.selectItems.findIndex(el => {
    //             if(el.id !== null && el.id !== undefined) {
    //               if(el.id === type.id) {
    //                 return el
    //               }
    //             } else if(el.name === type.name) {
    //               return el
    //             }
    //           })
    //           if(indexOfElem >= 0){ //remove item
    //             this.selectItems.splice(indexOfElem, 1)
    //           }else{ // add item
    //             this.selectItems.push(type)
    //           }
    //         }else {
    //           this.selectItem = type.name;
    //           this.activeItemLocal = type;
    //         }
    //       }
    //     }
    //   }
    // }
  },
  data() {
    return {
      activeItemLocal: null,
      selectItem: '',
      selectItemInObj: {},
      selectItems: [],
      searchText: '',
      list: [],
      arrayPermission: null,
      changeOn: false,
      show: false,
    }
  },
  computed: {
    ...mapGetters(['getPermissionsFullByType', "getPermissionsForUserCanAssignTask"]),
    printDropResult() {
      if(this.isMultiple) {
        let result = ''
        if(this.selectItems.length > 2){
          result = this.selectItems[0].name + `&nbsp;/&nbsp;<b>+${this.selectItems.length - 1}</b>`
        } else if(this.selectItems.length) {
          for (let i = 0; i < this.selectItems.length; i++) {
            const el = this.selectItems[i];
            if(i == 0 && this.selectItems.length > 1) {
              result += el.first_name +' '+el.last_name + '&nbsp;/&nbsp;'
            }else{
              result += el.first_name +' '+el.last_name
            }
          }
        }
        return result
      }else{
        if(this.selectItem === 'Unselected') {
          return ""
        }
        return this.selectItem
      }
    },
    onSearch() {
      if(this.searchText){
        return this.list.filter(item => {
          return (item.first_name+item.last_name).toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0
        })
      }
      return this.list
    }
  },
  methods: {
    showEvent() {
      this.show = true;
      setTimeout(()=> {this.$refs.searchInput.focus()}, 200)
    },
    getUrlUser(link) {
      // console.log(process.env.VUE_APP_URL, link.indexOf(process.env.VUE_APP_URL), link, )
      if(link && link.indexOf(process.env.VUE_APP_URL_SIMPLE)>=0) {
        return link;
      }
      return this.getURL()+ link
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
    formatCpDateReturn(value) {
      if(!value) {
        return  ''
      }
      return moment(value, 'YYYY-MM-DD').utcOffset(6).format('DD.MM.YYYY')
    },
    openList() {
      this.$refs.dropdown.show(true);
    },
    hideEvent() {
      this.show = false;
      this.$emit('beforeClose')
        this.$emit("close", this.selectItems, this.changeOn);
      this.changeOn = false;
      setTimeout(()=> this.$emit('afterClose'), 50)
    },
    checkedSelected(item) {
      // if(item.id !== null && item.id !== undefined) {
      //   if(this.selectItems.findIndex(el => el.id === item.id) >= 0) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   if(this.selectItem === item.name || this.selectItems.findIndex(el => el.name === item.name) >= 0) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      if(item.id !== null && item.id !== undefined && (this.activeItemLocal !== null && this.activeItemLocal !== undefined)) {
        if(this.activeItemLocal.id === item.id ||this.selectItems.findIndex(el => el.id === item.id) >= 0) {
          return true;
        } else {
          return false;
        }
      } else if(item.id !== null && item.id !== undefined && this.selectItems.length !== 0 ) {
        if(this.selectItems.findIndex(el => el.id === item.id) >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if(this.selectItem === item.name || this.selectItems.findIndex(el => el.name === item.name) >= 0) {
          return true;
        } else {
          return false;
        }
      }

    },
    select(value) {
      this.changeOn = true;
      if(!(this.selectItems.find(a=> a.id === value.id))) {
        this.selectItems.push(value);
        this.$emit('input', this.isMultiple ? this.selectItems : value)
      } else {
        this.selectItems = this.selectItems.filter(a=> a.id !== value.id)
        this.$emit('input', this.isMultiple ? this.selectItems : null)
      }
    },
    render() {
      this.activeItemLocal = null
      this.selectItem = ''
      this.selectItemInObj = {}
      this.selectItems = []
    }
  },
  mounted() {

    // if(this.activeItem) {
    //   //this.select(this.activeItem)
    //   if(this.isMultiple) {
    //     this.activeItem.forEach(item => {
    //       this.select(item)
    //     });
    //   }else{
    //     this.select(this.activeItem)
    //   }
    // }


    if(this.activeItem !== null && this.activeItem !== undefined) {
      //this.select(this.activeItem)
      if(this.isMultiple) {
        this.selectItems = [];
        this.activeItemLocal = JSON.parse(JSON.stringify(this.activeItem))
        this.activeItem.forEach(item => {
          this.select(item)
        });
      }else{
        this.activeItemLocal = JSON.parse(JSON.stringify(this.activeItem))
        if(!this.dontSelect) {
          this.select(this.activeItem)
        } else {
          let type = this.activeItem
          if(this.isMultiple) {
            let indexOfElem = this.selectItems.findIndex(el => {
              if(el.id !== null && el.id !== undefined) {
                if(el.id === type.id) {
                  return el
                }
              } else if(el.name === type.name) {
                return el
              }
            })
            if(indexOfElem >= 0){ //remove item
              this.selectItems.splice(indexOfElem, 1)
            }else{ // add item
              this.selectItems.push(type)
            }
          }else{
            this.selectItem = type.name;
            this.activeItemLocal = type;
          }
        }
      }
    }
    this.$emit("mountedEnd")

    if(this.permissionAssigned === 'ship') {
      this.arrayPermission = this.getPermissionsFullByType("assign_contracts_to");
    } else if(this.permissionAssigned === 'cargo') {
      this.arrayPermission = this.getPermissionsFullByType("assign_voyage_contracts_to")
    } else if(this.permissionAssigned === 'sublet') {
      this.arrayPermission = this.getPermissionsFullByType("assign_sublet_contracts_to");
    } else if(this.permissionAssigned === 'ship_task') {
      this.arrayPermission = this.getPermissionsFullByType("assign_tasks_to");
    } else {
      this.arrayPermission = this.getPermissionsForUserCanAssignTask;
    }
      this.list = this.arrayPermission?.users ? this.arrayPermission.users : [];
      if (Array.isArray(this.value)) {
        this.selectItems = this.value
      } else if (this.value) {
        this.selectItems = [this.value]
      } else {
        this.selectItems = []
      }
  }
}
</script>

<style scoped lang="scss">
@import "../style-element/dropdown";
</style>

<style lang="scss" scoped>
p {
  margin: 0;
}
/deep/ {
  .btn {
    height: 30px !important;
  }
}
.counterparties-drop{
  box-sizing: border-box;
  margin-top: 0px;
  &.hide_input{
    position: absolute;
    top: 0;
    left: 0;
    //height: 0;
    width: 260px;
    margin: 0;
    z-index: 1;
    .b-dropdown{
      display: none;
      &.show{
        display: inline-flex;
      }
    }
    .counterparties-drop_title{
      display: none;
    }
    .dropdown-toggle{
      top: 0;
      height: 0;
      padding: 0 !important;
      border: none !important;
      opacity: 0;
      pointer-events: none;
    }
    .dropdown-item{
      font-size: 13px;
    }
  }
  &.block-input_error{
    .dropdown-toggle{
      border-color: #f57f6c !important;
    }
  }
  &_title{
    margin-bottom: 0.25rem !important;
    //color: #9AA3B0;
  }
  .dropdown{
    width: 100%;
  }
  .content{
    overflow: auto;
    max-height: 300px;

  }
  .dropdown-menu {
    max-height: 300px;
  }
  .dropdown-toggle{
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 7px 7px !important;
    font-size: 16px !important;
    border-radius: 4px;
    border: 1px solid var(--color-blue-dark) !important;
    background: var(--color-input) ;
    line-height: normal;
    &:after{
      display: none !important;
    }
    .button-drop-svg{
      width: auto !important;
    }
  }
  .button-text{
    display: inline-flex;
    text-align: left;
    font-size: 14px;
    color: var(--color-dark);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    b{
      display: inline-flex;
      min-width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid var(--color-dark);
      font-weight: 400;
      font-size: 11px;
    }
  }
  .dropdown-menu{
    width: 100%;
    li{
      position: relative;
    }
  }
  .dropdown-check{
    position: absolute;
    top: 11px;
    right: 11px;
    user-select: none;
  }
  .dropdown-item{
    padding: 8px 30px 8px 16px;
    padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    //padding: 5px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img,svg {
      margin-right: 7px;
      width: 24px;
      height: 24px;
      border-radius: 50px;
    }
    cursor: pointer;
    &:hover {
      background-color: var(--color-hover-list);
    }
    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 35px);
      box-sizing: border-box;
      overflow: hidden;
    }
  }
  .search{
    width: 100%;
    background-color: var(--color-bgc-page-white);
    position: absolute;
    top: -32px;
    height: 29px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 6px 16px;
    border-radius: 4px;
    //border-bottom: 1px solid var(--color-card-border);
    input{
      height: auto;
      margin-left: 12px;
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
  .block-avatar-user {
    //padding: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    //background-color: #000;
    height: 100%;
    .avatar-user {
      width: 16px;
      height: 24px;
      position: relative;
      img, svg {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
      }
    }
  }
 .block-imgs {
   box-sizing: border-box;
   img, svg {
     width: 24px;
     height: 24px;
     border: 1px solid #FFFFFF;
     border-radius: 20px;
   }
   p {
     padding-left: 5px;
     white-space: nowrap;
     text-overflow: ellipsis;
     width: calc(100% - 35px);
     box-sizing: border-box;
     overflow: hidden;
   }
 }

  .more-avatar {
    border: 1px solid var(--color-blue-dark);
    color: var(--color-blue-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 13px;
    width: 24px;
    height: 24px;
    border-radius: 20px;
  }
}
/deep/ {
  .dropdown-menu {
    overflow: visible !important;
    transform: none !important;
    top: 30px !important;
    padding: 0 !important;
  }
}

.height36 {
  .bth {
    height: 36px !important;
  }
  /deep/ {
    .btn {
      height: 36px !important;
    }
    .dropdown-menu {
      top: 36px !important;
    }
  }
  .search {
    height: 33px;
    top: -37px;
  }

}

.bgcWhite {
  /deep/ {
    .dropdown-toggle {
      background: var(--color-bgc-page-white) !important;
    }
  }

}

.dart-mode {
  .bgcWhite {
    /deep/ {
      .dropdown-toggle {
        background: transparent !important;
      }
    }
  }
  .svg-da {
    path {
      stroke: #FFFFFF;
    }
  }
}
</style>
