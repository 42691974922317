<template>
  <div class="wrap-page position-fixed bgc-white">
    <add-vessel-location-modal v-if="idMessageForAddVessel" :idMessage="idMessageForAddVessel" @close="idMessageForAddVessel = null"></add-vessel-location-modal>
    <div class="top-panel h36 box-border border-bottom d-flex align-items-center p0-24" v-if="!allEmails && !vesselMode">
      <router-link :to="{name: 'Location Emails Vessel'}" class="bread__link">Emails</router-link>

<!--      <router-link v-if="getWindowHistory()" :to="{name: 'Location'}" class="bread__link">Emails</router-link>-->
<!--      <button v-on:click="$router.go(-1)" v-else class="bread__link">Emails</button>-->

      <svg class="mr8 ml8" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.29924 6.40283L5.18326 9.70514C4.91614 9.977 4.48367 9.97655 4.217 9.70373C3.95053 9.43096 3.95122 8.98908 4.21837 8.71701L6.84906 5.90885L4.21837 3.19159C3.95125 2.91948 3.95057 2.47789 4.217 2.20508C4.35068 2.06832 4.52581 1.99994 4.70094 1.99994C4.87562 1.99994 5.05006 2.06786 5.1835 2.20367L8.29924 5.41491C8.42789 5.54562 8.50009 5.72351 8.50009 5.90885C8.50009 6.09419 8.42769 6.27187 8.29924 6.40283Z" fill="#9AA3B0"/>
      </svg>
      <p class="bread__link">{{name ? name : 'Name'}}</p>
    </div>
    <div class="top-panel-edited-date h64 border-bottom w100Proc p0-24 box-border" v-if="vesselLocation && !vesselMode">
      <div class="content-info d-flex pt14" v-if="!editMode">
        <div class="name">
          <p class="fz13 fw500 text-gray">Vessel Name</p>
          <p class="mt4 fz13 text-dark" v-if="vesselLocation.name">{{vesselLocation.name}}</p>
          <p class="mt4 fz13 text-gray" v-else>Empty</p>
        </div>
        <div class="dwt">
          <p class="fz13 fw500 text-gray">DWT</p>
          <p class="mt4 fz13 text-dark" v-if="vesselLocation.dwt">{{vesselLocation.dwt ? vesselLocation.dwt + ' MT' : ''}}</p>
          <p class="mt4 fz13 text-gray" v-else>Empty</p>

        </div>
        <div class="name">
          <p class="fz13 fw500 text-gray">Built</p>
          <p class="mt4 fz13 text-dark" v-if="vesselLocation.build">{{vesselLocation.build}}</p>
          <p class="mt4 fz13 text-gray" v-else>Empty</p>
        </div>
        <div class="name">
          <p class="fz13 fw500 text-gray">Position</p>
          <p class="mt4 fz13 text-dark" v-if="vesselLocation.position">{{vesselLocation.position}}</p>
          <p class="mt4 fz13 text-gray" v-else>Empty</p>
        </div>
        <div class="name">
          <p class="fz13 fw500 text-gray">Zone</p>
          <p class="mt4 fz13 text-dark" v-if="vesselLocation.zone">{{vesselLocation.zone}}</p>
          <p class="mt4 fz13 text-gray" v-else>Empty</p>
        </div>
        <div class="name">
          <p class="fz13 fw500 text-gray">Laycan</p>
          <p class="mt4 fz13 text-dark" v-if="vesselLocation.date_from && vesselLocation.date_to">{{vesselLocation.date_from | formatDateMonthWithoutTZ}} {{vesselLocation.date_from ? '-' : ''}} {{vesselLocation.date_to | formatDateMonthWithoutTZ}}</p>
          <p class="mt4 fz13 text-gray" v-else>Empty</p>
        </div>
        <button class="button-blue-border h32 fz14 fw500 ml-auto" v-on:click="editData = JSON.parse(JSON.stringify(vesselLocation)); editMode = true">Edit Vessel Data</button>
      </div>
      <div class="content-edit d-flex pt4" v-if="editData && editMode">
        <div class="name mr16">
          <p class="fz13 fw500 text-gray">Vessel Name</p>
          <input type="text" class="h32 mt4" v-model="editData.name">
        </div>
        <div class="dwt mr16">
          <p class="fz13 fw500 text-gray">DWT</p>
          <input type="text" class="h32 mt4" v-model="editData.dwt">
        </div>
        <div class="built mr16">
          <p class="fz13 fw500 text-gray">Built</p>
          <input type="text" class="h32 mt4" v-model="editData.build">
        </div>
        <div class="position mr16">
          <p class="fz13 fw500 text-gray">Position</p>
          <input type="text" class="h32 mt4" v-model="editData.position">
        </div>
        <div class="zone mr16">
          <p class="fz13 fw500 text-gray">Zone</p>
<!--          <input type="text" class="h32 mt4" v-model="editData.zone">-->
          <location-select-zone class="zone-drop" v-model="editData.zone"></location-select-zone>
        </div>
        <div class="laycan">
          <p class="fz13 fw500 text-gray">Laycan</p>
          <div class="mt4">
            <date-picker
                class="datepick"
                type="date"
                format="DD MMM YYYY"
                v-model="editData.date_from"
            ></date-picker>
            <date-picker
                class="datepick"
                type="date"
                format="DD MMM YYYY"
                v-model="editData.date_to"
            ></date-picker>
          </div>
        </div>
        <button class="ml-auto h32 blue-button fz14 fw500 mt20 w140" v-on:click="saveChange">Save</button>

      </div>
    </div>

    <div class="w100Proc d-flex ">
      <div class="h-wrap wrap-list bgc-gray position-relative overflow-y-auto">
        <div class="p0-24 selected-type d-flex align-items-center h44 box-border border-bottom position-relative z-10 ">
        <button class="h100Proc fz13 fw600 text-gray d-flex align-items-center justify-center" v-on:click="()=> {offsetMail = 0; type = 'incoming'}" :class="{'active-type': type === 'incoming'}">
          Incoming
        </button>
          <button class="h100Proc fz13 fw600 text-gray d-flex align-items-center justify-center" v-on:click="()=> {offsetMail = 0; type = 'sent'}" :class="{'active-type': type === 'sent'}">
            Sent
          </button>
          <button class="h100Proc fz13 fw600 text-gray d-flex align-items-center justify-center" v-if="vesselMode" v-on:click="()=> {offsetMail = 0; type = 'draft'}" :class="{'active-type': type === 'draft'}">
            Draft
          </button>
          <button class="h100Proc fz13 fw600 text-gray d-flex align-items-center justify-center" v-if="vesselMode" v-on:click="()=> {offsetMail = 0; type = 'archive'}" :class="{'active-type': type === 'archive'}">
            Archive
          </button>
          <location-filter-emails v-show="type === 'incoming'" v-model="filterDrop" class="ml-auto"></location-filter-emails>
          <div class="ml55 d-flex align-items-center" v-if="vesselMode && messages.length !== 0" :class="{'ml-auto': type !== 'incoming'}">
            <p class="text-gray fz14">{{offsetMail ? 50*offsetMail : 1}}-{{(offsetMail == 0 && !nextOffsetBool) ? messages.length : getCountMessage }}</p>
            <div class="d-flex align-items-center ml12 buttons">
              <svg v-on:click="offsetMail != 0 ? setOffset(-1) : null" :class="{'disabled-button-offset': offsetMail == 0}" class="cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.70271 6.00586L6.81869 2.70355C7.08581 2.43169 7.51828 2.43215 7.78495 2.70496C8.05142 2.97773 8.05073 3.41961 7.78358 3.69168L5.15289 6.49984L7.78358 9.2171C8.0507 9.48921 8.05139 9.9308 7.78495 10.2036C7.65127 10.3404 7.47614 10.4088 7.30101 10.4088C7.12633 10.4088 6.95189 10.3408 6.81845 10.205L3.70271 6.99378C3.57406 6.86307 3.50187 6.68518 3.50187 6.49984C3.50187 6.3145 3.57427 6.13682 3.70271 6.00586Z" fill="#9AA3B0"/>
              </svg>
              <svg v-on:click="nextOffsetBool ? setOffset(+1) : null" :class="{'disabled-button-offset': !nextOffsetBool}" class="ml16 cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.29728 6.90039L5.18131 10.2027C4.91419 10.4746 4.48172 10.4741 4.21504 10.2013C3.94858 9.92852 3.94927 9.48664 4.21642 9.21457L6.84711 6.40641L4.21642 3.68915C3.9493 3.41704 3.94861 2.97545 4.21504 2.70264C4.34872 2.56588 4.52386 2.4975 4.69899 2.4975C4.87367 2.4975 5.04811 2.56542 5.18155 2.70123L8.29728 5.91247C8.42594 6.04318 8.49813 6.22107 8.49813 6.40641C8.49813 6.59175 8.42573 6.76943 8.29728 6.90039Z" fill="#9AA3B0"/>
              </svg>

            </div>
          </div>
        </div>
<!--        <div class="list-emails overflow-y-auto ">-->
<!--          <app-loader class="loader-list" :is-local="true" v-if="loadList"></app-loader>-->
<!--          <div class="list-container list-title bgc-gray border-bottom box-border">-->
<!--            <div class="read fz13 fw500 text-gray d-flex align-items-center justify-start" :class="{'grid-helper': type !== 'incoming'}">-->
<!--              {{ type === 'incoming' ? 'Read' : 'Recipient' }}-->
<!--            </div>-->
<!--            <div class="sender fz13 fw500 text-gray d-flex align-items-center justify-start" v-if="type === 'incoming'">-->
<!--              Sender-->
<!--            </div>-->
<!--            <div class="subject fz13 fw500 text-gray d-flex align-items-center justify-start">-->
<!--              Subject-->
<!--            </div>-->
<!--            <div class="sent-time fz13 fw500 text-gray d-flex align-items-center justify-start">-->
<!--              Sent time-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="list-container list-item bgc-modal border-bottom box-border" v-on:click="select(msg)" :class="{'list-item-active': selectedEmail && selectedEmail.id === msg.id, 'list-item-not-read': !msg.is_read}" v-for="(msg, i) in messages" :key="'dd'+i">-->
<!--          <div class="read d-flex align-items-center" :class="{'grid-helper justify-start': type !== 'incoming', 'justify-center': type === 'incoming'}">-->
<!--            <svg v-if="!msg.is_read && type === 'incoming'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <circle cx="5" cy="5" r="5" fill="#21CA68"/>-->
<!--            </svg>-->
<!--            <svg v-else-if="type === 'incoming'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <circle cx="5" cy="5" r="4" stroke="#9AA3B0" stroke-width="2"/>-->
<!--            </svg>-->
<!--            <p v-else class="fz14 text-dark" v-html="getTextRecipient(msg.message_to)"></p>-->
<!--          </div>-->
<!--          <div class="sender fz14 text-dark d-flex align-items-center justify-start" v-if="type === 'incoming'">-->
<!--            <span class="overflow-text-dots">{{msg.message_from}}</span>-->
<!--          </div>-->
<!--          <div class="subject fz14 text-dark d-flex align-items-center justify-start overflow-text-dots">-->
<!--            <span class="overflow-text-dots">{{msg.subject}}</span>-->
<!--          </div>-->
<!--          <div class="sent-time fz14 text-dark d-flex align-items-center justify-start overflow-text-dots">-->
<!--            <span class="overflow-text-dots">{{getFormatTimeMessage(msg.receive_date)}}</span>-->
<!--          </div>-->
<!--        </div>-->
        <location-read-emails-list class="pb56" @deleted="deletedMessage" :vesselMode="vesselMode" @select="select" :loadList="loadList" :selected-email="selectedEmail" :type="type" :messages="messages" v-if="messages.length"></location-read-emails-list>
        <div class="not-found w100Proc d-flex flex-column justify-center align-items-center pb38 position-relative" v-else>
          <app-loader :is-local="true" v-if="loadList" class="loader-list"></app-loader>
          <svg width="140" height="120" viewBox="0 0 140 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M96.3145 17.3779C92.9427 17.3779 90.2226 20.0847 90.2226 23.4074V26.7301H102.405V23.2652C102.329 20.0082 99.6381 17.3779 96.3145 17.3779ZM104.789 23.4074H104.79C104.79 23.3471 104.79 23.287 104.788 23.227C104.691 18.6597 100.928 15 96.3145 15C91.6408 15 87.8387 18.7568 87.8387 23.4074V29.437H35.5245C34.9304 24.3516 30.5716 20.4139 25.2967 20.4139C19.6173 20.4139 15 24.9787 15 30.626V93.7879C15 99.4352 19.6173 104 25.2967 104H114.524C119.198 104 123 100.243 123 95.5926V37.8445C123 33.1939 119.198 29.437 114.524 29.437H104.789V23.4074ZM104.789 31.8149V79.3509C104.789 79.6221 104.696 79.8852 104.525 80.0965L97.243 89.1196C97.0167 89.3999 96.6754 89.563 96.3146 89.563C95.9538 89.563 95.6124 89.4 95.3862 89.1197L88.1023 80.0966C87.9317 79.8853 87.8387 79.6222 87.8387 79.3509V31.8149H35.5935V93.7879C35.5935 94.4446 35.0599 94.9769 34.4016 94.9769C33.7433 94.9769 33.2097 94.4446 33.2097 93.7879C33.2097 89.4685 29.6743 85.9537 25.2967 85.9537C20.9192 85.9537 17.3838 89.4685 17.3838 93.7879C17.3838 98.1073 20.9192 101.622 25.2967 101.622H114.524C117.896 101.622 120.616 98.9153 120.616 95.5926V37.8445C120.616 34.5217 117.896 31.8149 114.524 31.8149H104.789ZM17.3838 87.253C19.2738 85.0049 22.119 83.5759 25.2967 83.5759C28.4745 83.5759 31.3197 85.0049 33.2097 87.253V30.626C33.2097 26.3065 29.6743 22.7917 25.2967 22.7917C20.9192 22.7917 17.3838 26.3065 17.3838 30.626V87.253ZM102.405 29.1079H90.2226V33.9486H102.405V29.1079ZM102.405 36.3265H90.2226V78.9317L96.3144 86.4782L102.405 78.9318V36.3265Z" fill="#DEE3EA"/>
          </svg>
          <p class="fw600 fz18 text mt16">List is empty</p>
        </div>
        <pagination-panel-default class="p0-12 box-border" :if-active="loadMoreLoader" v-if="nextLinkLocationMessage" @load-more="loadMore"></pagination-panel-default>

      </div>
      <location-read-emails-detail :vesselMode="vesselMode" @createVesselLocation="(e)=> {idMessageForAddVessel = e}" @reply=" !vesselMode ? showReply = true : $refs.replyEmailModal.showModal([selectedEmail.id])" :getShow="getShow" :class="{'h-wrap-vessel': $route.name == 'Location Mails Vessel'}" class="h-wrap overflow-y-auto wrap-mail border-left box-border p18-24 box-border" :selected-email="selectedEmail" :load-message="loadMessage" v-if="selectedEmail"></location-read-emails-detail>
<!--      <div class="h-wrap overflow-y-auto wrap-mail border-left box-border p18-24 box-border position-relative" v-if="selectedEmail">-->
<!--        <app-loader :is-local="true" v-if="loadMessage"></app-loader>-->
<!--        <div class="top-panel d-flex align-items-center">-->
<!--          <p class="fz16 fw500 text-dark text-dots-overflow w-max">{{selectedEmail.subject}}</p>-->
<!--          <button class="blue-button ml-auto w130 h32 cursor-pointer">-->
<!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M2.57197 11.1535C2.47468 11.2319 2.47226 11.3793 2.56693 11.4608L10.7905 18.5422C10.9201 18.6539 11.121 18.5618 11.121 18.3907V14.2028C11.121 14.0923 11.2105 14.0028 11.321 14.0028H11.6973C15.8483 14.0028 17.8807 15.0054 19.7892 17.8495C19.9016 18.017 20.1635 17.9382 20.1532 17.7367C19.8937 12.6727 16.8303 9.18184 11.6973 9.18184H11.321C11.2105 9.18184 11.121 9.09229 11.121 8.98184V4.68475C11.121 4.51674 10.9264 4.4236 10.7955 4.529L2.57197 11.1535Z" stroke="white" stroke-width="1.4"/>-->
<!--            </svg>-->
<!--            Reply-->
<!--          </button>-->
<!--        </div>-->
<!--        <div class="msg-info w100Proc box-border mt12 border-radius-8 p12 bgc-gray">-->
<!--          <div class="d-flex">-->
<!--            <p class="text-gray w58 fz14 text-align-left">Sender:</p>-->
<!--            <p class="fz14 text-dark">{{selectedEmail.message_from}}</p>-->
<!--          </div>-->
<!--          <div class="d-flex mt4">-->
<!--            <p class="text-gray w58 fz14 text-align-left">Sent:</p>-->
<!--            <p class="fz14 text-dark">{{getFormatTimeMessage(selectedEmail.receive_date)}}</p>-->
<!--          </div>-->
<!--          <div class="d-flex mt4">-->
<!--            <p class="text-gray w58 fz14 text-align-left">To:</p>-->
<!--            <p ref="toText" class="fz14 text-dark w-max max-w-591" :class="{'text-dots-overflow': !showAll}">{{(selectedEmail.message_to.toString())}}</p>-->
<!--          </div>-->
<!--          <div class="d-flex mt4" v-if="getShow">-->
<!--            <p class="text-gray w58 fz14 text-align-left opacity-0">To:</p>-->
<!--            <button class="blue-button-text fz13" v-on:click="showAll = !showAll">-->
<!--              {{showAll ? 'Hide all' : 'Show all'}}-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="d-flex mt4">-->
<!--            <p class="text-gray w58 fz14 text-align-left">Parsed:</p>-->
<!--            <p class="fz14 text-dark">{{getFormatTimeMessage(selectedEmail.created_at)}}</p>-->
<!--          </div>-->
<!--          <payment-detail-atachements-file-->
<!--              :file-props="selectedEmail.files"-->
<!--              class="files"-->
<!--              :delete-file-permission="false"-->
<!--              :editPermission="false"-->
<!--          ></payment-detail-atachements-file>-->
<!--        </div>-->
<!--        <p class="mt16 fz14 text-dark">{{selectedEmail.text}}</p>-->

<!--      </div>-->
      <div class="h-wrap wrap-mail border-left box-border d-flex align-items-center justify-center position-relative" v-else>
        <app-loader :is-local="true" v-if="loadMessage"></app-loader>
        <div class="no-selected d-flex align-items-center flex-column">
          <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M72.5 72.6868V73.2235C72.5 75.0019 71.0673 76.4436 69.3 76.4436H60.4758M72.5 72.6868V35.0716C72.5 34.2096 72.1538 33.3843 71.54 32.7831M72.5 72.6868L46.1533 58.3297M8.5 72.6868V73.2235C8.5 75.0019 9.93269 76.4436 11.7 76.4436H54.5606M8.5 72.6868L34.6716 58.3297M8.5 72.6868V49.9606M46.1533 58.3297L41.9222 56.024C40.9688 55.5044 39.8188 55.506 38.8668 56.0283L34.6716 58.3297M46.1533 58.3297L66.74 37.6133M66.74 37.6133L71.54 32.7831M66.74 37.6133V28.0813M71.54 32.7831L66.74 28.0813M34.6716 58.3297L14.26 37.6521M14.26 37.6521L9.45362 32.7831M14.26 37.6521V28.0435M9.45362 32.7831C8.8437 33.3845 8.5 34.2077 8.5 35.067V44.9434M9.45362 32.7831L14.26 28.0435M66.74 28.0813V17.4082C66.74 15.6298 65.3073 14.1881 63.54 14.1881H52.5566M52.5566 14.1881L42.6329 4.46738C41.3875 3.24755 39.4024 3.25088 38.1611 4.47488L28.3109 14.1881M52.5566 14.1881H28.3109M28.3109 14.1881H17.46C15.6927 14.1881 14.26 15.6298 14.26 17.4082V28.0435M40.6067 42.7394C35.128 42.7394 30.6867 38.2221 30.6867 32.6497C30.6867 27.0774 35.128 22.5601 40.6067 22.5601C46.0853 22.5601 50.3133 26.7553 50.3133 32.3277C50.3133 35.2258 48.9267 37.9399 47.0067 38.1239C44.98 38.3182 43.8067 37.4799 43.9133 32.5424M43.9133 32.5424C43.9133 35.3286 42.1464 37.5872 39.9667 37.5872C37.787 37.5872 36.02 35.3286 36.02 32.5424C36.02 29.7562 37.787 27.4976 39.9667 27.4976C42.1464 27.4976 43.9133 29.7562 43.9133 32.5424ZM43.9133 32.5424V26.8535" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <p class="fz14 fw500 text-dark mt16">Select an email to see its content here</p>
        </div>
      </div>
    </div>
    <location-modal-reply @close="showReply = false" v-if="showReply && selectedEmail" :selectedEmail="selectedEmail"></location-modal-reply>
    <ReplyEmailModal
        v-if="vesselMode"
        :accounts="account_list"
        :mailList="messages"
        ref="replyEmailModal"
    ></ReplyEmailModal>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import moment from 'moment';
import DatePicker from 'vue2-datepicker';

import AppLoader from "@/components/loader";
import LocationFilterEmails from "@/components/location/location-filter-emails";
import LocationReadEmailsList from "@/components/location/location-read-emails/location-read-emails-list";
import LocationReadEmailsDetail from "@/components/location/location-read-emails/location-read-emails-detail";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import LocationModalReply from "@/components/location/location-modal-reply";
import AddVesselLocationModal from "@/views/location/add-vessel-location-modal";
import LocationSelectZone from "@/components/location/location-list/location-select-zone";
import mailling from "../../../store/modules/mailling";
import ReplyEmailModal from "@/components/mailbox/ReplyEmailModal.vue";
export default {
  name: "location-read-emails",
  components: {
    ReplyEmailModal,
    LocationSelectZone,
    AddVesselLocationModal,
    LocationModalReply,
    PaginationPanelDefault,
    LocationReadEmailsDetail,
    LocationReadEmailsList, LocationFilterEmails, AppLoader, DatePicker},
  props: {
    allEmails: Boolean,
    vesselMode: Boolean,
  },
  computed: {
    getCountMessage() {
      return this.countMessageAll < (50*(this.offsetMail+1)) ? this.countMessageAll : (50*(this.offsetMail+1))
    },
    mailling() {
      return mailling
    },
    ...mapState({
      nextLinkLocationMessage: state => state.location.nextLinkLocationMessage,
    }),
    getIdFoldersByType() {
      let ids = []
      this.account_list.forEach(item=> {
        let id = item.folders.find(a=> a.title.toLowerCase() == this.type)
        if(id) {
          ids.push(id.id)
        }
      })
      return ids
    }
  },
  data() {
    return {
      showReply: false,
      type: 'incoming',
      messages: [],
      name: '',
      selectedEmail: null,
      loadMessage: false,
      loadList: false,
      filterDrop: 'All',
      getShow: false,
      showAll: false,
      loadMoreLoader: false,
      idMessageForAddVessel: null,
      vesselLocation: null,
      editMode: false,
      editData: null,
      offsetMail: 0,
      nextOffsetBool: false,
      account_list: [],
      countMessageAll: 0,
    }
  },
  watch: {
    type: function () {
      if(!this.vesselMode) {
        this.getList()
      } else {
        this.offsetMail = 0
        this.loadMailingBox()
      }
    },
    filterDrop: function () {
      if(!this.vesselMode) {
        this.getList()
      } else {
        this.offsetMail = 0
        this.loadMailingBox()
      }
    },
    editMode: function () {
      if(this.editMode) {
        this.editData.date_from = this.vesselLocation.date_from ? new Date(this.vesselLocation.date_from) : null
        this.editData.date_to = this.vesselLocation.date_to ? new Date(this.vesselLocation.date_to) : null
      }
    }
  },
  methods: {
    ...mapActions(['axiosGetMailboxById', 'axiosGetMailboxAccounts', 'axiosGetMaillingForObject', 'axiosGetMessageForContract', 'axiosChangeVesselLocation', 'axiosGetMailsForVeselById', 'axiosGetMessagesById', 'axiosGetAllMails', 'axiosLoadMoreAllMails', 'axiosGetLocationById']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    deletedMessage(id) {
      this.messages = this.messages.filter(a=> a.id !== id)
      if(this.selectedEmail && this.selectedEmail.id == id) {
        this.selectedEmail = null
      }
    },
    setOffset(num) {
      let newOffset = this.offsetMail
      newOffset+=num
      if(newOffset<0) {
        newOffset = 0
        this.offsetMail = newOffset
      } else {
        this.offsetMail = newOffset
        this.loadMailingBox()
      }
    },
    getWindowHistory() {
      return window.history.length <= 1
    },
    async loadMore() {
      this.loadMoreLoader = true
      let res = await this.axiosLoadMoreAllMails()
      if(res) {
        this.messages = this.messages.concat(res.results)
      }
      this.loadMoreLoader = false
    },
    getTextRecipient(emails) {
      let text = ''
      if(emails.length > 1) {
        return '<span class="text-dots-overflow w-name box-border">'+emails[0]+'</span>' + '<span class="circle ml4">+'+ (emails.length-1) +'</span>'
      } else if(emails.length == 1) {
        return '<span class="text-dots-overflow w100Proc box-border">'+emails[0]+'</span>'
      }
      return text
    },
    getFormatTimeMessage(date) {
      return date ? moment(date).format('DD MMM YY, HH:mm:ss') : ''
    },
    async select(msg) {
      this.loadMessage = true
      let response = null
      if(this.vesselMode) {
        response = await this.axiosGetMailboxById(msg.id)
      } else {
        response = await this.axiosGetMessagesById(msg.id)
      }
      this.selectedEmail = response !== false ? response : null
      if(this.selectedEmail) {
        msg.is_read = true
        setTimeout(()=> {
          this.getShow = document.getElementById('toText').clientWidth > 590
        }, 120)
      }
      this.loadMessage = false
    },
    async getList() {
      this.loadList = true
      let params = {id: this.$route.params.id, params: {type: this.type}}
      if(this.filterDrop !== 'All' && this.type === 'incoming') {
        params.params['is_read'] = this.filterDrop === 'Read'
      }

      let response = null;
      if(!this.allEmails) {
         response = await this.axiosGetMailsForVeselById(params)
        if(response) {
          this.messages = response.messages
          this.name = response.name
        }
      } else {
        response = await this.axiosGetAllMails(params)
        if(response) {
          this.messages = response.results
          this.name = ''
        }
      }
      this.loadList = false
      console.log('response', response)

    },
    getBoolSizeTo() {
      // return this.$refs.toText.clientWidth > 590
      console.dir(this.$refs.toText)
      return ''
    },
    getNameById(id) {
      let regions = [
        {name: 'NOPAC', id: 13},
        {name: 'Far East', id: 12},
        {name: 'ECSA', id: 11},
        {name: 'USG-NCSA', id: 10},
        {name: 'USEC', id: 9},
        {name: 'WC America', id: 8},
        {name: 'AUSSIE', id: 7},
        {name: 'SE Asia', id: 6},
        {name: 'Red Sea', id: 5},
        {name: 'Indian Ocean', id: 4},
        {name: 'WAFR', id: 3},
        {name: 'Baltic - Continent', id: 2},
        {name: 'Med-Bsea', id: 1},
      ]

      return regions.find(a=> a.id == id).name
    },
    async saveChange() {
      this.editMode = false
      delete this.editData.id
      delete this.editData.messages
      if(!this.editData.zone) {
        delete this.editData.zone
      } else {
        this.editData.zone = {name: this.editData.zone}
      }
      this.editData.date_from = this.editData.date_from ? moment(this.editData.date_from).format('YYYY-MM-DD') : null
      this.editData.date_to = this.editData.date_to ? moment(this.editData.date_to).format('YYYY-MM-DD') : null
      this.editData.dwt = this.editData.dwt ? this.editData.dwt : 0

      this.showLoad()
      let res = await this.axiosChangeVesselLocation({id: this.vesselLocation.id, data: {...this.editData}})
      if(res) {
        this.addTip('Saved')
      } else {
        this.addTip('Error')
      }
      this.vesselLocation = await this.axiosGetLocationById(this.$route.params.id)
      this.vesselLocation.zone = this.vesselLocation.zone !== null && (''+this.vesselLocation.zone).length !== 0 ? this.getNameById(this.vesselLocation.zone) : ''
      this.hideLoad()
    },
    async loadMailingBox() {
      this.messages = []
      let params = {folder: this.getIdFoldersByType, limit: 50, offset: this.offsetMail*50, text_choice: 'and',
        subject_choice: 'and', }
      if(this.$route.path.indexOf('ports') >= 0) {
        params['ports'] = this.$route.params.id
      } else {
        params['contracts'] = this.$route.params.id
      }
      if(this.filterDrop !== 'All' && this.type.toLowerCase() == 'incoming') {
        params['is_read'] = this.filterDrop == 'Read'
      }
      console.log('loadMailingBox', params)
      let data = await this.axiosGetMaillingForObject({params: params})
      this.countMessageAll = data.count
      this.nextOffsetBool = data.next !== null
      this.messages = data.results
    }
  },
  async mounted() {
    if(!this.vesselMode) {
      if(this.$route.params.id) {
        this.vesselLocation = await this.axiosGetLocationById(this.$route.params.id)
        this.vesselLocation.zone = this.vesselLocation.zone !== null && (''+this.vesselLocation.zone).length !== 0 ? this.getNameById(this.vesselLocation.zone) : ''
      }

      this.getList()
    } else {
      this.axiosGetMailboxAccounts().then(response => {
        this.account_list = response.data
        this.loadMailingBox()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wrap-page {
  height: calc(100vh - 56px);
  width: 100vw;
}
.selected-type {
  button {
    margin-right: 10px;
    width: 72px;
  }
}
.active-type {
  position: relative;
  color: var(--color-blue-dark);
  &::after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: var(--color-blue-dark);
    position: absolute;
    bottom: 0;
  }
}
.wrap-list {
  width: calc(100vw - 726px);
}

.wrap-mail {
  width: 726px;
  /deep/ {
    .wrap-loader {
      z-index: 15 !important;
    }
  }
}
.h-wrap {
  height: calc(100vh - 56px - 36px);
}



.loader-list  {
  z-index: 5;
  top: 44px;
  height: calc(100% - 44px);
  background-color: transparent !important;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bgc-page-white);
    opacity: 0.7;
  }
  /deep/ {
    .sk-fading-circle {
      position: relative;
      z-index: 10;
    }
  }
}

.not-found {
  height: calc(100vh - 230px);
}

.top-panel-edited-date {
  .content-info {
    .name {
      width: 166px;
    }
    .dwt {
      width: 116px;
    }
    .built {
      width: 96px;
    }
    .position {
      width: 126px;
    }
    .zone {
      width: 146px;
    }
  }
  .content-edit {
    .name {
      width: 196px;
    }
    .dwt {
      width: 130px;
    }
    .built {
      width: 130px;
    }
    .position {
      width: 180px;
    }
    .zone {
      width: 180px;
    }
    .laycan {
      div {
        &>* {
          width: 130px;
          margin-right: 4px;
        }
      }
      .datepick {
        /deep/ {
          input {
            height: 32px;
            background-color: var(--color-input);
          }
        }
      }
    }
  }
}
.zone-drop {
  /deep/ {
    .select {
      height: 32px;
      width: 100%;
      background: var(--color-input);
      border: 1px solid var(--color-border);
      border-radius: 4px;
      margin-top: 4px;
      padding: 10px 30px 10px 12px;
      &::after {
        position: absolute;
        top: 9px;
        right: 7px;
        content: url("../../../../src/assets/ic_down-arrow.svg");
      }
    }
  }
}
.buttons {
  svg:not(.disabled-button-offset) {
      path {
        fill: var(--color-dark)
      }
  }
}

.h-wrap-vessel {
  height: calc(100vh - 64px - 56px - 36px) !important;
}

</style>