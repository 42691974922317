import axios from 'axios'

export default {
    actions: {
        async axiosGetDataMap(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/calculator/route/distance/', {
                params: {
                    'ports':  data.ports ? data.ports.toString() : null,
                    'piracy_option': data.piracy_option ? data.piracy_option : '000',
                    'canal_pass_code': data.canal_pass_code ? data.canal_pass_code : '000',
                    'use_local_eca': data.use_local_eca ? data.use_local_eca : 'False',
                    'seca_bypass_option': data.seca_bypass_option ? data.seca_bypass_option.toUpperCase() : null,
                    // 'seca_cost_ratio': data.seca_bypass_option === 'optimized' ? data.seca_cost_ratio.toUpperCase(): null,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }

            }).then(res=> {
                return res.data
            }).catch(()=> {
                return false
            })
        }
    },
    mutations: {

    },
    state: {
        secaZones: [
            [
                {
                    "number": "1",
                    "lat": 32.53611111111111,
                    "lon": -117.10305555555556
                },
                {
                    "number": "2",
                    "lat": 32.53444444444444,
                    "lon": -117.12472222222222
                },
                {
                    "number": "3",
                    "lat": 32.527499999999996,
                    "lon": -117.2388888888889
                },
                {
                    "number": "4",
                    "lat": 32.55361111111111,
                    "lon": -117.26388888888889
                },
                {
                    "number": "5",
                    "lat": 32.572500000000005,
                    "lon": -117.36694444444444
                },
                {
                    "number": "6",
                    "lat": 32.58972222222222,
                    "lon": -117.46472222222222
                },
                {
                    "number": "7",
                    "lat": 32.62722222222222,
                    "lon": -117.8261111111111
                },
                {
                    "number": "8",
                    "lat": 31.133055555555558,
                    "lon": -118.60583333333332
                },
                {
                    "number": "9",
                    "lat": 30.556944444444444,
                    "lon": -121.79138888888889
                },
                {
                    "number": "10",
                    "lat": 31.76972222222222,
                    "lon": -123.28944444444444
                },
                {
                    "number": "11",
                    "lat": 32.36611111111111,
                    "lon": -123.84555555555555
                },
                {
                    "number": "12",
                    "lat": 32.94416666666666,
                    "lon": -124.19638888888889
                },
                {
                    "number": "13",
                    "lat": 33.669999999999995,
                    "lon": -124.45416666666667
                },
                {
                    "number": "14",
                    "lat": 34.52444444444444,
                    "lon": -125.28111111111112
                },
                {
                    "number": "15",
                    "lat": 35.24388888888889,
                    "lon": -125.72305555555556
                },
                {
                    "number": "16",
                    "lat": 35.733333333333334,
                    "lon": -126.31472222222222
                },
                {
                    "number": "17",
                    "lat": 36.27361111111111,
                    "lon": -126.75833333333334
                },
                {
                    "number": "18",
                    "lat": 37.02638888888889,
                    "lon": -127.12166666666666
                },
                {
                    "number": "19",
                    "lat": 37.76083333333333,
                    "lon": -127.63388888888889
                },
                {
                    "number": "20",
                    "lat": 38.41888888888889,
                    "lon": -127.88333333333333
                },
                {
                    "number": "21",
                    "lat": 39.418055555555554,
                    "lon": -128.52305555555557
                },
                {
                    "number": "22",
                    "lat": 40.31305555555555,
                    "lon": -128.76277777777779
                },
                {
                    "number": "23",
                    "lat": 41.2275,
                    "lon": -128.67277777777778
                },
                {
                    "number": "24",
                    "lat": 42.21361111111111,
                    "lon": -129.01055555555556
                },
                {
                    "number": "25",
                    "lat": 42.79277777777778,
                    "lon": -129.095
                },
                {
                    "number": "26",
                    "lat": 43.43944444444444,
                    "lon": -129.0238888888889
                },
                {
                    "number": "27",
                    "lat": 44.411944444444444,
                    "lon": -128.68972222222223
                },
                {
                    "number": "28",
                    "lat": 45.511944444444445,
                    "lon": -128.66722222222222
                },
                {
                    "number": "29",
                    "lat": 46.183611111111105,
                    "lon": -128.81694444444443
                },
                {
                    "number": "30",
                    "lat": 46.56527777777777,
                    "lon": -129.07472222222222
                },
                {
                    "number": "31",
                    "lat": 47.665277777777774,
                    "lon": -131.2613888888889
                },
                {
                    "number": "32",
                    "lat": 48.54222222222222,
                    "lon": -132.68333333333334
                },
                {
                    "number": "33",
                    "lat": 48.963055555555556,
                    "lon": -133.24638888888887
                },
                {
                    "number": "34",
                    "lat": 49.3775,
                    "lon": -134.26416666666665
                },
                {
                    "number": "35",
                    "lat": 50.03111111111111,
                    "lon": -135.31694444444443
                },
                {
                    "number": "36",
                    "lat": 51.055,
                    "lon": -136.7625
                },
                {
                    "number": "37",
                    "lat": 51.901111111111106,
                    "lon": -137.69833333333332
                },
                {
                    "number": "38",
                    "lat": 52.75333333333333,
                    "lon": -138.33722222222224
                },
                {
                    "number": "39",
                    "lat": 53.48888888888889,
                    "lon": -138.67666666666665
                },
                {
                    "number": "40",
                    "lat": 53.677499999999995,
                    "lon": -138.81472222222223
                },
                {
                    "number": "41",
                    "lat": 54.22916666666667,
                    "lon": -139.5438888888889
                },
                {
                    "number": "42",
                    "lat": 54.65694444444444,
                    "lon": -139.93861111111113
                },
                {
                    "number": "43",
                    "lat": 55.33833333333334,
                    "lon": -140.92916666666665
                },
                {
                    "number": "44",
                    "lat": 56.12,
                    "lon": -141.605
                },
                {
                    "number": "45",
                    "lat": 56.47555555555556,
                    "lon": -142.28861111111112
                },
                {
                    "number": "46",
                    "lat": 56.621944444444445,
                    "lon": -142.81583333333336
                },
                {
                    "number": "47",
                    "lat": 58.85111111111111,
                    "lon": -153.25083333333333
                }
            ],
            [
                {
                    "number": "1",
                    "lat": 60.0,
                    "lon": -64.16000000000001
                },
                {
                    "number": "2",
                    "lat": 60.0,
                    "lon": -56.71666666666667
                },
                {
                    "number": "3",
                    "lat": 58.900277777777774,
                    "lon": -55.63472222222222
                },
                {
                    "number": "4",
                    "lat": 57.84777777777778,
                    "lon": -55.06305555555555
                },
                {
                    "number": "5",
                    "lat": 57.58694444444445,
                    "lon": -54.01638888888889
                },
                {
                    "number": "6",
                    "lat": 57.23888888888889,
                    "lon": -53.132777777777775
                },
                {
                    "number": "7",
                    "lat": 56.802499999999995,
                    "lon": -52.39138888888889
                },
                {
                    "number": "8",
                    "lat": 56.30361111111111,
                    "lon": -51.82833333333333
                },
                {
                    "number": "9",
                    "lat": 54.38916666666667,
                    "lon": -50.29555555555555
                },
                {
                    "number": "10",
                    "lat": 53.748333333333335,
                    "lon": -50.12138888888889
                },
                {
                    "number": "11",
                    "lat": 53.08305555555556,
                    "lon": -50.168055555555554
                },
                {
                    "number": "12",
                    "lat": 52.335,
                    "lon": -49.9525
                },
                {
                    "number": "13",
                    "lat": 51.57222222222222,
                    "lon": -48.87916666666667
                },
                {
                    "number": "14",
                    "lat": 50.670833333333334,
                    "lon": -48.26777777777777
                },
                {
                    "number": "15",
                    "lat": 50.04111111111111,
                    "lon": -48.1175
                },
                {
                    "number": "16",
                    "lat": 49.40083333333333,
                    "lon": -48.15972222222222
                },
                {
                    "number": "17",
                    "lat": 48.65611111111111,
                    "lon": -47.921388888888885
                },
                {
                    "number": "18",
                    "lat": 47.40694444444444,
                    "lon": -47.782222222222224
                },
                {
                    "number": "19",
                    "lat": 46.586666666666666,
                    "lon": -48.015
                },
                {
                    "number": "20",
                    "lat": 45.32916666666667,
                    "lon": -48.724444444444444
                },
                {
                    "number": "21",
                    "lat": 44.727222222222224,
                    "lon": -49.28055555555555
                },
                {
                    "number": "22",
                    "lat": 44.27722222222222,
                    "lon": -49.85638888888889
                },
                {
                    "number": "23",
                    "lat": 43.8875,
                    "lon": -50.566944444444445
                },
                {
                    "number": "24",
                    "lat": 43.60166666666667,
                    "lon": -51.344722222222224
                },
                {
                    "number": "25",
                    "lat": 43.39972222222222,
                    "lon": -52.28944444444444
                },
                {
                    "number": "26",
                    "lat": 43.330555555555556,
                    "lon": -53.33694444444445
                },
                {
                    "number": "27",
                    "lat": 43.35388888888889,
                    "lon": -54.15555555555555
                },
                {
                    "number": "28",
                    "lat": 43.49472222222222,
                    "lon": -55.128055555555555
                },
                {
                    "number": "29",
                    "lat": 42.669999999999995,
                    "lon": -55.528888888888886
                },
                {
                    "number": "30",
                    "lat": 41.971944444444446,
                    "lon": -56.159444444444446
                },
                {
                    "number": "31",
                    "lat": 41.33916666666667,
                    "lon": -57.08694444444445
                },
                {
                    "number": "32",
                    "lat": 40.92611111111111,
                    "lon": -58.04861111111111
                },
                {
                    "number": "33",
                    "lat": 40.693888888888885,
                    "lon": -59.08833333333334
                },
                {
                    "number": "34",
                    "lat": 40.6425,
                    "lon": -60.205555555555556
                },
                {
                    "number": "35",
                    "lat": 40.76277777777778,
                    "lon": -61.23416666666667
                },
                {
                    "number": "36",
                    "lat": 41.08111111111111,
                    "lon": -62.29694444444444
                },
                {
                    "number": "37",
                    "lat": 40.61527777777778,
                    "lon": -63.180277777777775
                },
                {
                    "number": "38",
                    "lat": 40.29222222222222,
                    "lon": -64.14361111111111
                },
                {
                    "number": "39",
                    "lat": 40.129444444444445,
                    "lon": -64.99194444444444
                },
                {
                    "number": "40",
                    "lat": 40.095555555555556,
                    "lon": -65.88527777777779
                },
                {
                    "number": "41",
                    "lat": 39.96805555555556,
                    "lon": -65.9975
                },
                {
                    "number": "42",
                    "lat": 39.473333333333336,
                    "lon": -66.35388888888889
                },
                {
                    "number": "43",
                    "lat": 39.031666666666666,
                    "lon": -66.80916666666667
                },
                {
                    "number": "44",
                    "lat": 38.654444444444444,
                    "lon": -67.34972222222221
                },
                {
                    "number": "45",
                    "lat": 38.32222222222222,
                    "lon": -68.03361111111111
                },
                {
                    "number": "46",
                    "lat": 38.09138888888889,
                    "lon": -68.78194444444445
                },
                {
                    "number": "47",
                    "lat": 37.970555555555556,
                    "lon": -69.56861111111111
                },
                {
                    "number": "48",
                    "lat": 37.963055555555556,
                    "lon": -70.4025
                },
                {
                    "number": "49",
                    "lat": 37.879444444444445,
                    "lon": -70.63055555555555
                },
                {
                    "number": "50",
                    "lat": 37.31027777777778,
                    "lon": -71.14250000000001
                },
                {
                    "number": "51",
                    "lat": 36.540277777777774,
                    "lon": -71.56638888888888
                },
                {
                    "number": "52",
                    "lat": 35.58277777777778,
                    "lon": -71.43388888888889
                },
                {
                    "number": "53",
                    "lat": 34.55277777777778,
                    "lon": -71.61777777777777
                },
                {
                    "number": "54",
                    "lat": 33.91361111111111,
                    "lon": -71.87638888888888
                },
                {
                    "number": "55",
                    "lat": 33.323055555555555,
                    "lon": -72.28666666666666
                },
                {
                    "number": "56",
                    "lat": 32.75861111111111,
                    "lon": -72.90138888888889
                },
                {
                    "number": "57",
                    "lat": 31.92027777777778,
                    "lon": -74.20055555555555
                },
                {
                    "number": "58",
                    "lat": 31.453888888888887,
                    "lon": -75.25555555555556
                },
                {
                    "number": "59",
                    "lat": 31.054444444444446,
                    "lon": -75.85499999999999
                },
                {
                    "number": "60",
                    "lat": 30.761666666666667,
                    "lon": -76.52722222222222
                },
                {
                    "number": "61",
                    "lat": 30.21333333333333,
                    "lon": -77.30805555555555
                },
                {
                    "number": "62",
                    "lat": 29.42138888888889,
                    "lon": -76.94500000000001
                },
                {
                    "number": "63",
                    "lat": 28.616388888888892,
                    "lon": -76.8
                },
                {
                    "number": "64",
                    "lat": 28.286944444444448,
                    "lon": -76.66944444444445
                },
                {
                    "number": "65",
                    "lat": 28.28666666666667,
                    "lon": -79.18972222222223
                },
                {
                    "number": "66",
                    "lat": 27.88222222222222,
                    "lon": -79.47638888888889
                },
                {
                    "number": "67",
                    "lat": 27.433611111111112,
                    "lon": -79.52722222222222
                },
                {
                    "number": "68",
                    "lat": 27.27027777777778,
                    "lon": -79.57166666666666
                },
                {
                    "number": "69",
                    "lat": 27.198333333333334,
                    "lon": -79.58222222222221
                },
                {
                    "number": "70",
                    "lat": 27.099722222222223,
                    "lon": -79.5886111111111
                },
                {
                    "number": "71",
                    "lat": 27.00777777777778,
                    "lon": -79.58805555555556
                },
                {
                    "number": "72",
                    "lat": 26.921111111111113,
                    "lon": -79.5775
                },
                {
                    "number": "73",
                    "lat": 26.899444444444445,
                    "lon": -79.57416666666666
                },
                {
                    "number": "74",
                    "lat": 26.762777777777778,
                    "lon": -79.54472222222222
                },
                {
                    "number": "75",
                    "lat": 26.741666666666667,
                    "lon": -79.53972222222222
                },
                {
                    "number": "76",
                    "lat": 26.727777777777778,
                    "lon": -79.53888888888889
                },
                {
                    "number": "77",
                    "lat": 26.686666666666667,
                    "lon": -79.53361111111111
                },
                {
                    "number": "78",
                    "lat": 26.636944444444445,
                    "lon": -79.52555555555556
                },
                {
                    "number": "79",
                    "lat": 26.608333333333334,
                    "lon": -79.51833333333333
                },
                {
                    "number": "80",
                    "lat": 26.589166666666664,
                    "lon": -79.51388888888889
                },
                {
                    "number": "81",
                    "lat": 26.580833333333334,
                    "lon": -79.51277777777777
                },
                {
                    "number": "82",
                    "lat": 26.56972222222222,
                    "lon": -79.51055555555556
                },
                {
                    "number": "83",
                    "lat": 26.52,
                    "lon": -79.50416666666666
                },
                {
                    "number": "84",
                    "lat": 26.484722222222224,
                    "lon": -79.49805555555555
                },
                {
                    "number": "85",
                    "lat": 26.42527777777778,
                    "lon": -79.49944444444445
                },
                {
                    "number": "86",
                    "lat": 26.391388888888887,
                    "lon": -79.49861111111112
                },
                {
                    "number": "87",
                    "lat": 26.389166666666664,
                    "lon": -79.49833333333333
                },
                {
                    "number": "88",
                    "lat": 26.315833333333334,
                    "lon": -79.53194444444445
                },
                {
                    "number": "89",
                    "lat": 26.25722222222222,
                    "lon": -79.55472222222222
                },
                {
                    "number": "90",
                    "lat": 26.253611111111113,
                    "lon": -79.55638888888889
                },
                {
                    "number": "91",
                    "lat": 26.135833333333334,
                    "lon": -79.59805555555555
                },
                {
                    "number": "92",
                    "lat": 26.129722222222224,
                    "lon": -79.60249999999999
                },
                {
                    "number": "93",
                    "lat": 26.116388888888892,
                    "lon": -79.60972222222222
                },
                {
                    "number": "94",
                    "lat": 26.047777777777778,
                    "lon": -79.63944444444445
                },
                {
                    "number": "95",
                    "lat": 25.991666666666667,
                    "lon": -79.6675
                },
                {
                    "number": "96",
                    "lat": 25.98777777777778,
                    "lon": -79.66888888888889
                },
                {
                    "number": "97",
                    "lat": 25.96333333333333,
                    "lon": -79.67722222222223
                },
                {
                    "number": "98",
                    "lat": 25.938333333333333,
                    "lon": -79.685
                },
                {
                    "number": "99",
                    "lat": 25.90111111111111,
                    "lon": -79.69388888888889
                },
                {
                    "number": "100",
                    "lat": 25.89,
                    "lon": -79.69611111111111
                },
                {
                    "number": "101",
                    "lat": 25.865000000000002,
                    "lon": -79.69972222222222
                },
                {
                    "number": "102",
                    "lat": 25.825833333333332,
                    "lon": -79.70444444444445
                },
                {
                    "number": "103",
                    "lat": 25.80666666666667,
                    "lon": -79.7063888888889
                },
                {
                    "number": "104",
                    "lat": 25.805555555555557,
                    "lon": -79.70666666666666
                },
                {
                    "number": "105",
                    "lat": 25.773888888888887,
                    "lon": -79.71222222222222
                },
                {
                    "number": "106",
                    "lat": 25.77111111111111,
                    "lon": -79.7125
                },
                {
                    "number": "107",
                    "lat": 25.727777777777778,
                    "lon": -79.71638888888889
                },
                {
                    "number": "108",
                    "lat": 25.70861111111111,
                    "lon": -79.71333333333334
                },
                {
                    "number": "109",
                    "lat": 25.676944444444445,
                    "lon": -79.7075
                },
                {
                    "number": "110",
                    "lat": 25.623333333333335,
                    "lon": -79.7075
                },
                {
                    "number": "111",
                    "lat": 25.61888888888889,
                    "lon": -79.7075
                },
                {
                    "number": "112",
                    "lat": 25.5175,
                    "lon": -79.70333333333333
                },
                {
                    "number": "113",
                    "lat": 25.46638888888889,
                    "lon": -79.70305555555557
                },
                {
                    "number": "114",
                    "lat": 25.40111111111111,
                    "lon": -79.70333333333333
                },
                {
                    "number": "115",
                    "lat": 25.3725,
                    "lon": -79.70555555555556
                },
                {
                    "number": "116",
                    "lat": 25.358055555555556,
                    "lon": -79.70222222222222
                },
                {
                    "number": "117",
                    "lat": 25.28111111111111,
                    "lon": -79.69
                },
                {
                    "number": "118",
                    "lat": 25.265833333333333,
                    "lon": -79.69194444444445
                },
                {
                    "number": "119",
                    "lat": 25.177500000000002,
                    "lon": -79.69194444444445
                },
                {
                    "number": "120",
                    "lat": 25.164166666666667,
                    "lon": -79.69333333333334
                },
                {
                    "number": "121",
                    "lat": 25.15083333333333,
                    "lon": -79.69583333333334
                },
                {
                    "number": "122",
                    "lat": 25.06527777777778,
                    "lon": -79.70805555555556
                },
                {
                    "number": "123",
                    "lat": 25.05,
                    "lon": -79.71555555555555
                },
                {
                    "number": "124",
                    "lat": 25.008333333333333,
                    "lon": -79.73472222222222
                },
                {
                    "number": "125",
                    "lat": 24.984166666666667,
                    "lon": -79.74666666666667
                },
                {
                    "number": "126",
                    "lat": 24.924444444444447,
                    "lon": -79.76583333333333
                },
                {
                    "number": "127",
                    "lat": 24.738333333333333,
                    "lon": -79.82333333333332
                },
                {
                    "number": "128",
                    "lat": 24.717777777777776,
                    "lon": -79.82722222222222
                },
                {
                    "number": "129",
                    "lat": 24.71,
                    "lon": -79.84722222222221
                },
                {
                    "number": "130",
                    "lat": 24.69638888888889,
                    "lon": -79.8825
                },
                {
                    "number": "131",
                    "lat": 24.642222222222223,
                    "lon": -79.99944444444445
                },
                {
                    "number": "132",
                    "lat": 24.6075,
                    "lon": -80.06416666666667
                },
                {
                    "number": "133",
                    "lat": 24.555,
                    "lon": -80.21194444444444
                },
                {
                    "number": "134",
                    "lat": 24.55138888888889,
                    "lon": -80.2225
                },
                {
                    "number": "135",
                    "lat": 24.536944444444448,
                    "lon": -80.25444444444445
                },
                {
                    "number": "136",
                    "lat": 24.524166666666666,
                    "lon": -80.28194444444445
                },
                {
                    "number": "137",
                    "lat": 24.515833333333333,
                    "lon": -80.29638888888888
                },
                {
                    "number": "138",
                    "lat": 24.503888888888888,
                    "lon": -80.32249999999999
                },
                {
                    "number": "139",
                    "lat": 24.501666666666665,
                    "lon": -80.32888888888888
                },
                {
                    "number": "140",
                    "lat": 24.49388888888889,
                    "lon": -80.35138888888888
                },
                {
                    "number": "141",
                    "lat": 24.471666666666664,
                    "lon": -80.40972222222223
                },
                {
                    "number": "142",
                    "lat": 24.46833333333333,
                    "lon": -80.41944444444445
                },
                {
                    "number": "143",
                    "lat": 24.45638888888889,
                    "lon": -80.45555555555556
                },
                {
                    "number": "144",
                    "lat": 24.441666666666666,
                    "lon": -80.49166666666667
                },
                {
                    "number": "145",
                    "lat": 24.41861111111111,
                    "lon": -80.53944444444444
                },
                {
                    "number": "146",
                    "lat": 24.391666666666666,
                    "lon": -80.60249999999999
                },
                {
                    "number": "147",
                    "lat": 24.375833333333333,
                    "lon": -80.64888888888889
                },
                {
                    "number": "148",
                    "lat": 24.36861111111111,
                    "lon": -80.66416666666667
                },
                {
                    "number": "149",
                    "lat": 24.325277777777778,
                    "lon": -80.75583333333333
                },
                {
                    "number": "150",
                    "lat": 24.32111111111111,
                    "lon": -80.76305555555555
                },
                {
                    "number": "151",
                    "lat": 24.310555555555556,
                    "lon": -80.78027777777778
                },
                {
                    "number": "152",
                    "lat": 24.309722222222224,
                    "lon": -80.78166666666667
                },
                {
                    "number": "153",
                    "lat": 24.164166666666667,
                    "lon": -80.99638888888889
                },
                {
                    "number": "154",
                    "lat": 24.16333333333333,
                    "lon": -80.9975
                },
                {
                    "number": "155",
                    "lat": 24.149444444444445,
                    "lon": -81.01861111111111
                },
                {
                    "number": "156",
                    "lat": 24.141666666666666,
                    "lon": -81.03083333333333
                },
                {
                    "number": "157",
                    "lat": 24.140555555555554,
                    "lon": -81.0325
                },
                {
                    "number": "158",
                    "lat": 24.124444444444446,
                    "lon": -81.05166666666666
                },
                {
                    "number": "159",
                    "lat": 24.03888888888889,
                    "lon": -81.15138888888889
                },
                {
                    "number": "160",
                    "lat": 24.0,
                    "lon": -81.18777777777778
                },
                {
                    "number": "161",
                    "lat": 23.925555555555558,
                    "lon": -81.21527777777779
                },
                {
                    "number": "162",
                    "lat": 23.897777777777776,
                    "lon": -81.3286111111111
                },
                {
                    "number": "163",
                    "lat": 23.847777777777775,
                    "lon": -81.49972222222222
                },
                {
                    "number": "164",
                    "lat": 23.833888888888886,
                    "lon": -81.66638888888889
                },
                {
                    "number": "165",
                    "lat": 23.818055555555556,
                    "lon": -81.83305555555555
                },
                {
                    "number": "166",
                    "lat": 23.818055555555556,
                    "lon": -82.00305555555556
                },
                {
                    "number": "167",
                    "lat": 23.828333333333333,
                    "lon": -82.16638888888889
                },
                {
                    "number": "168",
                    "lat": 23.85388888888889,
                    "lon": -82.41638888888889
                },
                {
                    "number": "169",
                    "lat": 23.85388888888889,
                    "lon": -82.66638888888889
                },
                {
                    "number": "170",
                    "lat": 23.828333333333333,
                    "lon": -82.81472222222222
                },
                {
                    "number": "171",
                    "lat": 23.825555555555557,
                    "lon": -82.85305555555556
                },
                {
                    "number": "172",
                    "lat": 23.823333333333334,
                    "lon": -82.99972222222222
                },
                {
                    "number": "173",
                    "lat": 23.83111111111111,
                    "lon": -83.24972222222222
                },
                {
                    "number": "174",
                    "lat": 23.85611111111111,
                    "lon": -83.43027777777779
                },
                {
                    "number": "175",
                    "lat": 23.874166666666667,
                    "lon": -83.55027777777778
                },
                {
                    "number": "176",
                    "lat": 23.90111111111111,
                    "lon": -83.69305555555556
                },
                {
                    "number": "177",
                    "lat": 23.929722222222225,
                    "lon": -83.80305555555556
                },
                {
                    "number": "178",
                    "lat": 23.97722222222222,
                    "lon": -83.99972222222222
                },
                {
                    "number": "179",
                    "lat": 24.160277777777775,
                    "lon": -84.49083333333333
                },
                {
                    "number": "180",
                    "lat": 24.22222222222222,
                    "lon": -84.64416666666668
                },
                {
                    "number": "181",
                    "lat": 24.278055555555554,
                    "lon": -84.76861111111111
                },
                {
                    "number": "182",
                    "lat": 24.391666666666666,
                    "lon": -84.99972222222222
                },
                {
                    "number": "183",
                    "lat": 24.44361111111111,
                    "lon": -85.10527777777777
                },
                {
                    "number": "184",
                    "lat": 24.649166666666666,
                    "lon": -85.53166666666667
                },
                {
                    "number": "185",
                    "lat": 24.738055555555558,
                    "lon": -85.71972222222223
                },
                {
                    "number": "186",
                    "lat": 24.899166666666666,
                    "lon": -85.99972222222222
                },
                {
                    "number": "187",
                    "lat": 25.17888888888889,
                    "lon": -86.50194444444445
                },
                {
                    "number": "188",
                    "lat": 25.72083333333333,
                    "lon": -86.35388888888889
                },
                {
                    "number": "189",
                    "lat": 26.220277777777778,
                    "lon": -86.1125
                },
                {
                    "number": "190",
                    "lat": 26.45611111111111,
                    "lon": -86.22083333333333
                },
                {
                    "number": "191",
                    "lat": 26.56277777777778,
                    "lon": -86.61861111111111
                },
                {
                    "number": "192",
                    "lat": 26.023333333333333,
                    "lon": -87.49305555555556
                },
                {
                    "number": "193",
                    "lat": 25.706944444444442,
                    "lon": -88.55
                },
                {
                    "number": "194",
                    "lat": 25.781666666666666,
                    "lon": -90.49472222222222
                },
                {
                    "number": "195",
                    "lat": 25.74416666666667,
                    "lon": -90.78472222222221
                },
                {
                    "number": "196",
                    "lat": 25.861944444444447,
                    "lon": -91.88055555555555
                },
                {
                    "number": "197",
                    "lat": 26.295555555555556,
                    "lon": -93.06638888888888
                },
                {
                    "number": "198",
                    "lat": 25.998611111111114,
                    "lon": -93.56444444444445
                },
                {
                    "number": "199",
                    "lat": 26.00888888888889,
                    "lon": -95.6575
                },
                {
                    "number": "200",
                    "lat": 26.009166666666665,
                    "lon": -96.80833333333334
                },
                {
                    "number": "201",
                    "lat": 25.975555555555555,
                    "lon": -96.92444444444445
                },
                {
                    "number": "202",
                    "lat": 25.97083333333333,
                    "lon": -96.97805555555556
                },
                {
                    "number": "203",
                    "lat": 25.96611111111111,
                    "lon": -97.03166666666667
                },
                {
                    "number": "204",
                    "lat": 25.961388888888887,
                    "lon": -97.08555555555554
                },
                {
                    "number": "205",
                    "lat": 25.956666666666667,
                    "lon": -97.13916666666667
                },
                {
                    "number": "206",
                    "lat": 25.956666666666667,
                    "lon": -97.1463888888889
                }
            ],
            [
                {
                    "number": "1",
                    "lat": 22.548333333333336,
                    "lon": -153.00916666666666
                },
                {
                    "number": "2",
                    "lat": 23.10138888888889,
                    "lon": -153.47666666666666
                },
                {
                    "number": "3",
                    "lat": 23.53638888888889,
                    "lon": -154.03666666666666
                },
                {
                    "number": "4",
                    "lat": 23.863055555555558,
                    "lon": -154.61333333333332
                },
                {
                    "number": "5",
                    "lat": 24.363611111111112,
                    "lon": -155.8536111111111
                },
                {
                    "number": "6",
                    "lat": 24.69638888888889,
                    "lon": -156.45749999999998
                },
                {
                    "number": "7",
                    "lat": 24.959166666666665,
                    "lon": -157.3713888888889
                },
                {
                    "number": "8",
                    "lat": 25.228055555555553,
                    "lon": -157.9036111111111
                },
                {
                    "number": "9",
                    "lat": 25.42527777777778,
                    "lon": -158.51
                },
                {
                    "number": "10",
                    "lat": 25.521944444444443,
                    "lon": -159.16305555555556
                },
                {
                    "number": "11",
                    "lat": 25.50861111111111,
                    "lon": -159.90583333333333
                },
                {
                    "number": "12",
                    "lat": 25.364722222222223,
                    "lon": -160.66472222222222
                },
                {
                    "number": "13",
                    "lat": 25.001666666666665,
                    "lon": -161.64249999999998
                },
                {
                    "number": "14",
                    "lat": 24.68027777777778,
                    "lon": -162.22027777777777
                },
                {
                    "number": "15",
                    "lat": 24.264722222222222,
                    "lon": -162.7188888888889
                },
                {
                    "number": "16",
                    "lat": 23.680555555555557,
                    "lon": -163.21666666666667
                },
                {
                    "number": "17",
                    "lat": 23.055555555555557,
                    "lon": -163.54944444444445
                },
                {
                    "number": "18",
                    "lat": 22.335833333333333,
                    "lon": -163.7447222222222
                },
                {
                    "number": "19",
                    "lat": 21.6125,
                    "lon": -163.7675
                },
                {
                    "number": "20",
                    "lat": 20.92388888888889,
                    "lon": -163.6288888888889
                },
                {
                    "number": "21",
                    "lat": 20.22611111111111,
                    "lon": -163.32027777777776
                },
                {
                    "number": "22",
                    "lat": 19.65083333333333,
                    "lon": -162.89666666666665
                },
                {
                    "number": "23",
                    "lat": 19.161944444444444,
                    "lon": -162.34305555555557
                },
                {
                    "number": "24",
                    "lat": 18.654444444444444,
                    "lon": -161.32055555555556
                },
                {
                    "number": "25",
                    "lat": 18.50861111111111,
                    "lon": -160.64166666666665
                },
                {
                    "number": "26",
                    "lat": 18.491944444444446,
                    "lon": -159.93805555555556
                },
                {
                    "number": "27",
                    "lat": 18.178055555555556,
                    "lon": -159.23555555555555
                },
                {
                    "number": "28",
                    "lat": 17.52138888888889,
                    "lon": -158.94861111111112
                },
                {
                    "number": "29",
                    "lat": 16.901666666666664,
                    "lon": -158.50805555555556
                },
                {
                    "number": "30",
                    "lat": 16.43027777777778,
                    "lon": -157.99027777777778
                },
                {
                    "number": "31",
                    "lat": 15.999166666666666,
                    "lon": -157.29305555555555
                },
                {
                    "number": "32",
                    "lat": 15.676944444444445,
                    "lon": -156.35166666666666
                },
                {
                    "number": "33",
                    "lat": 15.626666666666667,
                    "lon": -155.3711111111111
                },
                {
                    "number": "34",
                    "lat": 15.729444444444445,
                    "lon": -154.77694444444447
                },
                {
                    "number": "35",
                    "lat": 15.925555555555555,
                    "lon": -154.21805555555557
                },
                {
                    "number": "36",
                    "lat": 16.774166666666666,
                    "lon": -152.81972222222223
                },
                {
                    "number": "37",
                    "lat": 17.561666666666667,
                    "lon": -152.0088888888889
                },
                {
                    "number": "38",
                    "lat": 18.504444444444445,
                    "lon": -151.50666666666666
                },
                {
                    "number": "39",
                    "lat": 19.04638888888889,
                    "lon": -151.3713888888889
                },
                {
                    "number": "40",
                    "lat": 19.579444444444444,
                    "lon": -151.32972222222222
                },
                {
                    "number": "41",
                    "lat": 20.128333333333334,
                    "lon": -151.3827777777778
                },
                {
                    "number": "42",
                    "lat": 20.64527777777778,
                    "lon": -151.52666666666667
                },
                {
                    "number": "43",
                    "lat": 21.485833333333336,
                    "lon": -151.9972222222222
                },
                {
                    "number": "44",
                    "lat": 22.116111111111113,
                    "lon": -152.52361111111114
                },
                {
                    "number": "45",
                    "lat": 22.548333333333336,
                    "lon": -153.00916666666666
                }
            ],
            [
                {
                    "number": "1",
                    "lat": 17.310277777777777,
                    "lon": -67.53722222222223
                },
                {
                    "number": "2",
                    "lat": 19.18722222222222,
                    "lon": -67.44583333333334
                },
                {
                    "number": "3",
                    "lat": 19.50777777777778,
                    "lon": -65.28
                },
                {
                    "number": "4",
                    "lat": 19.206944444444442,
                    "lon": -65.10222222222221
                },
                {
                    "number": "5",
                    "lat": 18.753611111111113,
                    "lon": -65.00611111111111
                },
                {
                    "number": "6",
                    "lat": 18.68722222222222,
                    "lon": -64.9925
                },
                {
                    "number": "7",
                    "lat": 18.489444444444445,
                    "lon": -64.89750000000001
                },
                {
                    "number": "8",
                    "lat": 18.459722222222222,
                    "lon": -64.88944444444445
                },
                {
                    "number": "9",
                    "lat": 18.4225,
                    "lon": -64.8775
                },
                {
                    "number": "10",
                    "lat": 18.40833333333333,
                    "lon": -64.87194444444444
                },
                {
                    "number": "11",
                    "lat": 18.3975,
                    "lon": -64.86388888888888
                },
                {
                    "number": "12",
                    "lat": 18.395,
                    "lon": -64.85638888888889
                },
                {
                    "number": "13",
                    "lat": 18.393333333333334,
                    "lon": -64.83805555555556
                },
                {
                    "number": "14",
                    "lat": 18.396666666666665,
                    "lon": -64.82805555555555
                },
                {
                    "number": "15",
                    "lat": 18.403055555555554,
                    "lon": -64.81666666666666
                },
                {
                    "number": "16",
                    "lat": 18.407777777777778,
                    "lon": -64.79916666666666
                },
                {
                    "number": "17",
                    "lat": 18.404999999999998,
                    "lon": -64.78361111111111
                },
                {
                    "number": "18",
                    "lat": 18.386944444444445,
                    "lon": -64.77694444444444
                },
                {
                    "number": "19",
                    "lat": 18.376944444444444,
                    "lon": -64.75555555555556
                },
                {
                    "number": "20",
                    "lat": 18.3775,
                    "lon": -64.745
                },
                {
                    "number": "21",
                    "lat": 18.378333333333334,
                    "lon": -64.74333333333334
                },
                {
                    "number": "22",
                    "lat": 18.376944444444444,
                    "lon": -64.74
                },
                {
                    "number": "23",
                    "lat": 18.3775,
                    "lon": -64.72833333333334
                },
                {
                    "number": "24",
                    "lat": 18.375,
                    "lon": -64.72666666666667
                },
                {
                    "number": "25",
                    "lat": 18.37361111111111,
                    "lon": -64.71611111111112
                },
                {
                    "number": "26",
                    "lat": 18.37388888888889,
                    "lon": -64.70777777777778
                },
                {
                    "number": "27",
                    "lat": 18.370833333333334,
                    "lon": -64.70083333333334
                },
                {
                    "number": "28",
                    "lat": 18.372777777777777,
                    "lon": -64.68333333333334
                },
                {
                    "number": "29",
                    "lat": 18.365833333333335,
                    "lon": -64.67083333333333
                },
                {
                    "number": "30",
                    "lat": 18.36416666666667,
                    "lon": -64.63972222222223
                },
                {
                    "number": "31",
                    "lat": 18.35611111111111,
                    "lon": -64.63777777777779
                },
                {
                    "number": "32",
                    "lat": 18.344166666666666,
                    "lon": -64.64250000000001
                },
                {
                    "number": "33",
                    "lat": 18.320833333333333,
                    "lon": -64.63722222222223
                },
                {
                    "number": "34",
                    "lat": 18.31861111111111,
                    "lon": -64.63777777777779
                },
                {
                    "number": "35",
                    "lat": 18.289722222222224,
                    "lon": -64.66055555555556
                },
                {
                    "number": "36",
                    "lat": 18.27861111111111,
                    "lon": -64.6613888888889
                },
                {
                    "number": "37",
                    "lat": 18.1925,
                    "lon": -64.64944444444446
                },
                {
                    "number": "38",
                    "lat": 18.050555555555555,
                    "lon": -64.63416666666667
                },
                {
                    "number": "39",
                    "lat": 18.04888888888889,
                    "lon": -64.49305555555556
                },
                {
                    "number": "40",
                    "lat": 18.047500000000003,
                    "lon": -64.45055555555555
                },
                {
                    "number": "41",
                    "lat": 18.041666666666668,
                    "lon": -64.35222222222221
                },
                {
                    "number": "42",
                    "lat": 18.041944444444447,
                    "lon": -64.33555555555554
                },
                {
                    "number": "43",
                    "lat": 18.034166666666668,
                    "lon": -64.26583333333333
                },
                {
                    "number": "44",
                    "lat": 18.003333333333334,
                    "lon": -64.04138888888889
                },
                {
                    "number": "45",
                    "lat": 17.999444444444446,
                    "lon": -64.01777777777778
                },
                {
                    "number": "46",
                    "lat": 17.97972222222222,
                    "lon": -63.95027777777778
                },
                {
                    "number": "47",
                    "lat": 17.964166666666667,
                    "lon": -63.89833333333333
                },
                {
                    "number": "44",
                    "lat": 17.94388888888889,
                    "lon": -63.88916666666667
                },
                {
                    "number": "48",
                    "lat": 17.66111111111111,
                    "lon": -63.914722222222224
                },
                {
                    "number": "50",
                    "lat": 17.61888888888889,
                    "lon": -63.919444444444444
                },
                {
                    "number": "51",
                    "lat": 17.50583333333333,
                    "lon": -63.93222222222222
                },
                {
                    "number": "52",
                    "lat": 17.193333333333335,
                    "lon": -63.965833333333336
                },
                {
                    "number": "53",
                    "lat": 17.083333333333332,
                    "lon": -63.97805555555556
                },
                {
                    "number": "54",
                    "lat": 16.996944444444445,
                    "lon": -63.98833333333334
                },
                {
                    "number": "55",
                    "lat": 17.310277777777777,
                    "lon": -67.53722222222223
                }
            ],
            [
                {
                    "number": "1",
                    "lat": 32.53611111111111,
                    "lon": -117.10305555555556
                },
                {
                    "number": "2",
                    "lat": 32.53444444444444,
                    "lon": -117.12472222222222
                },
                {
                    "number": "3",
                    "lat": 32.527499999999996,
                    "lon": -117.2388888888889
                },
                {
                    "number": "4",
                    "lat": 32.55361111111111,
                    "lon": -117.26388888888889
                },
                {
                    "number": "5",
                    "lat": 32.572500000000005,
                    "lon": -117.36694444444444
                },
                {
                    "number": "6",
                    "lat": 32.58972222222222,
                    "lon": -117.46472222222222
                },
                {
                    "number": "7",
                    "lat": 32.62722222222222,
                    "lon": -117.8261111111111
                },
                {
                    "number": "8",
                    "lat": 31.133055555555558,
                    "lon": -118.60583333333332
                },
                {
                    "number": "9",
                    "lat": 30.556944444444444,
                    "lon": -121.79138888888889
                },
                {
                    "number": "10",
                    "lat": 31.76972222222222,
                    "lon": -123.28944444444444
                },
                {
                    "number": "11",
                    "lat": 32.36611111111111,
                    "lon": -123.84555555555555
                },
                {
                    "number": "12",
                    "lat": 32.94416666666666,
                    "lon": -124.19638888888889
                },
                {
                    "number": "13",
                    "lat": 33.669999999999995,
                    "lon": -124.45416666666667
                },
                {
                    "number": "14",
                    "lat": 34.52444444444444,
                    "lon": -125.28111111111112
                },
                {
                    "number": "15",
                    "lat": 35.24388888888889,
                    "lon": -125.72305555555556
                },
                {
                    "number": "16",
                    "lat": 35.733333333333334,
                    "lon": -126.31472222222222
                },
                {
                    "number": "17",
                    "lat": 36.27361111111111,
                    "lon": -126.75833333333334
                },
                {
                    "number": "18",
                    "lat": 37.02638888888889,
                    "lon": -127.12166666666666
                },
                {
                    "number": "19",
                    "lat": 37.76083333333333,
                    "lon": -127.63388888888889
                },
                {
                    "number": "20",
                    "lat": 38.41888888888889,
                    "lon": -127.88333333333333
                },
                {
                    "number": "21",
                    "lat": 39.418055555555554,
                    "lon": -128.52305555555557
                },
                {
                    "number": "22",
                    "lat": 40.31305555555555,
                    "lon": -128.76277777777779
                },
                {
                    "number": "23",
                    "lat": 41.2275,
                    "lon": -128.67277777777778
                },
                {
                    "number": "24",
                    "lat": 42.21361111111111,
                    "lon": -129.01055555555556
                },
                {
                    "number": "25",
                    "lat": 42.79277777777778,
                    "lon": -129.095
                },
                {
                    "number": "26",
                    "lat": 43.43944444444444,
                    "lon": -129.0238888888889
                },
                {
                    "number": "27",
                    "lat": 44.411944444444444,
                    "lon": -128.68972222222223
                },
                {
                    "number": "28",
                    "lat": 45.511944444444445,
                    "lon": -128.66722222222222
                },
                {
                    "number": "29",
                    "lat": 46.183611111111105,
                    "lon": -128.81694444444443
                },
                {
                    "number": "30",
                    "lat": 46.56527777777777,
                    "lon": -129.07472222222222
                },
                {
                    "number": "31",
                    "lat": 47.665277777777774,
                    "lon": -131.2613888888889
                },
                {
                    "number": "32",
                    "lat": 48.54222222222222,
                    "lon": -132.68333333333334
                },
                {
                    "number": "33",
                    "lat": 48.963055555555556,
                    "lon": -133.24638888888887
                },
                {
                    "number": "34",
                    "lat": 49.3775,
                    "lon": -134.26416666666665
                },
                {
                    "number": "35",
                    "lat": 50.03111111111111,
                    "lon": -135.31694444444443
                },
                {
                    "number": "36",
                    "lat": 51.055,
                    "lon": -136.7625
                },
                {
                    "number": "37",
                    "lat": 51.901111111111106,
                    "lon": -137.69833333333332
                },
                {
                    "number": "38",
                    "lat": 52.75333333333333,
                    "lon": -138.33722222222224
                },
                {
                    "number": "39",
                    "lat": 53.48888888888889,
                    "lon": -138.67666666666665
                },
                {
                    "number": "40",
                    "lat": 53.677499999999995,
                    "lon": -138.81472222222223
                },
                {
                    "number": "41",
                    "lat": 54.22916666666667,
                    "lon": -139.5438888888889
                },
                {
                    "number": "42",
                    "lat": 54.65694444444444,
                    "lon": -139.93861111111113
                },
                {
                    "number": "43",
                    "lat": 55.33833333333334,
                    "lon": -140.92916666666665
                },
                {
                    "number": "44",
                    "lat": 56.12,
                    "lon": -141.605
                },
                {
                    "number": "45",
                    "lat": 56.47555555555556,
                    "lon": -142.28861111111112
                },
                {
                    "number": "46",
                    "lat": 56.621944444444445,
                    "lon": -142.81583333333336
                },
                {
                    "number": "47",
                    "lat": 58.85111111111111,
                    "lon": -153.25083333333333
                }
            ],
            [
                {
                    "number": "1",
                    "lat": 60.0,
                    "lon": -64.16000000000001
                },
                {
                    "number": "2",
                    "lat": 60.0,
                    "lon": -56.71666666666667
                },
                {
                    "number": "3",
                    "lat": 58.900277777777774,
                    "lon": -55.63472222222222
                },
                {
                    "number": "4",
                    "lat": 57.84777777777778,
                    "lon": -55.06305555555555
                },
                {
                    "number": "5",
                    "lat": 57.58694444444445,
                    "lon": -54.01638888888889
                },
                {
                    "number": "6",
                    "lat": 57.23888888888889,
                    "lon": -53.132777777777775
                },
                {
                    "number": "7",
                    "lat": 56.802499999999995,
                    "lon": -52.39138888888889
                },
                {
                    "number": "8",
                    "lat": 56.30361111111111,
                    "lon": -51.82833333333333
                },
                {
                    "number": "9",
                    "lat": 54.38916666666667,
                    "lon": -50.29555555555555
                },
                {
                    "number": "10",
                    "lat": 53.748333333333335,
                    "lon": -50.12138888888889
                },
                {
                    "number": "11",
                    "lat": 53.08305555555556,
                    "lon": -50.168055555555554
                },
                {
                    "number": "12",
                    "lat": 52.335,
                    "lon": -49.9525
                },
                {
                    "number": "13",
                    "lat": 51.57222222222222,
                    "lon": -48.87916666666667
                },
                {
                    "number": "14",
                    "lat": 50.670833333333334,
                    "lon": -48.26777777777777
                },
                {
                    "number": "15",
                    "lat": 50.04111111111111,
                    "lon": -48.1175
                },
                {
                    "number": "16",
                    "lat": 49.40083333333333,
                    "lon": -48.15972222222222
                },
                {
                    "number": "17",
                    "lat": 48.65611111111111,
                    "lon": -47.921388888888885
                },
                {
                    "number": "18",
                    "lat": 47.40694444444444,
                    "lon": -47.782222222222224
                },
                {
                    "number": "19",
                    "lat": 46.586666666666666,
                    "lon": -48.015
                },
                {
                    "number": "20",
                    "lat": 45.32916666666667,
                    "lon": -48.724444444444444
                },
                {
                    "number": "21",
                    "lat": 44.727222222222224,
                    "lon": -49.28055555555555
                },
                {
                    "number": "22",
                    "lat": 44.27722222222222,
                    "lon": -49.85638888888889
                },
                {
                    "number": "23",
                    "lat": 43.8875,
                    "lon": -50.566944444444445
                },
                {
                    "number": "24",
                    "lat": 43.60166666666667,
                    "lon": -51.344722222222224
                },
                {
                    "number": "25",
                    "lat": 43.39972222222222,
                    "lon": -52.28944444444444
                },
                {
                    "number": "26",
                    "lat": 43.330555555555556,
                    "lon": -53.33694444444445
                },
                {
                    "number": "27",
                    "lat": 43.35388888888889,
                    "lon": -54.15555555555555
                },
                {
                    "number": "28",
                    "lat": 43.49472222222222,
                    "lon": -55.128055555555555
                },
                {
                    "number": "29",
                    "lat": 42.669999999999995,
                    "lon": -55.528888888888886
                },
                {
                    "number": "30",
                    "lat": 41.971944444444446,
                    "lon": -56.159444444444446
                },
                {
                    "number": "31",
                    "lat": 41.33916666666667,
                    "lon": -57.08694444444445
                },
                {
                    "number": "32",
                    "lat": 40.92611111111111,
                    "lon": -58.04861111111111
                },
                {
                    "number": "33",
                    "lat": 40.693888888888885,
                    "lon": -59.08833333333334
                },
                {
                    "number": "34",
                    "lat": 40.6425,
                    "lon": -60.205555555555556
                },
                {
                    "number": "35",
                    "lat": 40.76277777777778,
                    "lon": -61.23416666666667
                },
                {
                    "number": "36",
                    "lat": 41.08111111111111,
                    "lon": -62.29694444444444
                },
                {
                    "number": "37",
                    "lat": 40.61527777777778,
                    "lon": -63.180277777777775
                },
                {
                    "number": "38",
                    "lat": 40.29222222222222,
                    "lon": -64.14361111111111
                },
                {
                    "number": "39",
                    "lat": 40.129444444444445,
                    "lon": -64.99194444444444
                },
                {
                    "number": "40",
                    "lat": 40.095555555555556,
                    "lon": -65.88527777777779
                },
                {
                    "number": "41",
                    "lat": 39.96805555555556,
                    "lon": -65.9975
                },
                {
                    "number": "42",
                    "lat": 39.473333333333336,
                    "lon": -66.35388888888889
                },
                {
                    "number": "43",
                    "lat": 39.031666666666666,
                    "lon": -66.80916666666667
                },
                {
                    "number": "44",
                    "lat": 38.654444444444444,
                    "lon": -67.34972222222221
                },
                {
                    "number": "45",
                    "lat": 38.32222222222222,
                    "lon": -68.03361111111111
                },
                {
                    "number": "46",
                    "lat": 38.09138888888889,
                    "lon": -68.78194444444445
                },
                {
                    "number": "47",
                    "lat": 37.970555555555556,
                    "lon": -69.56861111111111
                },
                {
                    "number": "48",
                    "lat": 37.963055555555556,
                    "lon": -70.4025
                },
                {
                    "number": "49",
                    "lat": 37.879444444444445,
                    "lon": -70.63055555555555
                },
                {
                    "number": "50",
                    "lat": 37.31027777777778,
                    "lon": -71.14250000000001
                },
                {
                    "number": "51",
                    "lat": 36.540277777777774,
                    "lon": -71.56638888888888
                },
                {
                    "number": "52",
                    "lat": 35.58277777777778,
                    "lon": -71.43388888888889
                },
                {
                    "number": "53",
                    "lat": 34.55277777777778,
                    "lon": -71.61777777777777
                },
                {
                    "number": "54",
                    "lat": 33.91361111111111,
                    "lon": -71.87638888888888
                },
                {
                    "number": "55",
                    "lat": 33.323055555555555,
                    "lon": -72.28666666666666
                },
                {
                    "number": "56",
                    "lat": 32.75861111111111,
                    "lon": -72.90138888888889
                },
                {
                    "number": "57",
                    "lat": 31.92027777777778,
                    "lon": -74.20055555555555
                },
                {
                    "number": "58",
                    "lat": 31.453888888888887,
                    "lon": -75.25555555555556
                },
                {
                    "number": "59",
                    "lat": 31.054444444444446,
                    "lon": -75.85499999999999
                },
                {
                    "number": "60",
                    "lat": 30.761666666666667,
                    "lon": -76.52722222222222
                },
                {
                    "number": "61",
                    "lat": 30.21333333333333,
                    "lon": -77.30805555555555
                },
                {
                    "number": "62",
                    "lat": 29.42138888888889,
                    "lon": -76.94500000000001
                },
                {
                    "number": "63",
                    "lat": 28.616388888888892,
                    "lon": -76.8
                },
                {
                    "number": "64",
                    "lat": 28.286944444444448,
                    "lon": -76.66944444444445
                },
                {
                    "number": "65",
                    "lat": 28.28666666666667,
                    "lon": -79.18972222222223
                },
                {
                    "number": "66",
                    "lat": 27.88222222222222,
                    "lon": -79.47638888888889
                },
                {
                    "number": "67",
                    "lat": 27.433611111111112,
                    "lon": -79.52722222222222
                },
                {
                    "number": "68",
                    "lat": 27.27027777777778,
                    "lon": -79.57166666666666
                },
                {
                    "number": "69",
                    "lat": 27.198333333333334,
                    "lon": -79.58222222222221
                },
                {
                    "number": "70",
                    "lat": 27.099722222222223,
                    "lon": -79.5886111111111
                },
                {
                    "number": "71",
                    "lat": 27.00777777777778,
                    "lon": -79.58805555555556
                },
                {
                    "number": "72",
                    "lat": 26.921111111111113,
                    "lon": -79.5775
                },
                {
                    "number": "73",
                    "lat": 26.899444444444445,
                    "lon": -79.57416666666666
                },
                {
                    "number": "74",
                    "lat": 26.762777777777778,
                    "lon": -79.54472222222222
                },
                {
                    "number": "75",
                    "lat": 26.741666666666667,
                    "lon": -79.53972222222222
                },
                {
                    "number": "76",
                    "lat": 26.727777777777778,
                    "lon": -79.53888888888889
                },
                {
                    "number": "77",
                    "lat": 26.686666666666667,
                    "lon": -79.53361111111111
                },
                {
                    "number": "78",
                    "lat": 26.636944444444445,
                    "lon": -79.52555555555556
                },
                {
                    "number": "79",
                    "lat": 26.608333333333334,
                    "lon": -79.51833333333333
                },
                {
                    "number": "80",
                    "lat": 26.589166666666664,
                    "lon": -79.51388888888889
                },
                {
                    "number": "81",
                    "lat": 26.580833333333334,
                    "lon": -79.51277777777777
                },
                {
                    "number": "82",
                    "lat": 26.56972222222222,
                    "lon": -79.51055555555556
                },
                {
                    "number": "83",
                    "lat": 26.52,
                    "lon": -79.50416666666666
                },
                {
                    "number": "84",
                    "lat": 26.484722222222224,
                    "lon": -79.49805555555555
                },
                {
                    "number": "85",
                    "lat": 26.42527777777778,
                    "lon": -79.49944444444445
                },
                {
                    "number": "86",
                    "lat": 26.391388888888887,
                    "lon": -79.49861111111112
                },
                {
                    "number": "87",
                    "lat": 26.389166666666664,
                    "lon": -79.49833333333333
                },
                {
                    "number": "88",
                    "lat": 26.315833333333334,
                    "lon": -79.53194444444445
                },
                {
                    "number": "89",
                    "lat": 26.25722222222222,
                    "lon": -79.55472222222222
                },
                {
                    "number": "90",
                    "lat": 26.253611111111113,
                    "lon": -79.55638888888889
                },
                {
                    "number": "91",
                    "lat": 26.135833333333334,
                    "lon": -79.59805555555555
                },
                {
                    "number": "92",
                    "lat": 26.129722222222224,
                    "lon": -79.60249999999999
                },
                {
                    "number": "93",
                    "lat": 26.116388888888892,
                    "lon": -79.60972222222222
                },
                {
                    "number": "94",
                    "lat": 26.047777777777778,
                    "lon": -79.63944444444445
                },
                {
                    "number": "95",
                    "lat": 25.991666666666667,
                    "lon": -79.6675
                },
                {
                    "number": "96",
                    "lat": 25.98777777777778,
                    "lon": -79.66888888888889
                },
                {
                    "number": "97",
                    "lat": 25.96333333333333,
                    "lon": -79.67722222222223
                },
                {
                    "number": "98",
                    "lat": 25.938333333333333,
                    "lon": -79.685
                },
                {
                    "number": "99",
                    "lat": 25.90111111111111,
                    "lon": -79.69388888888889
                },
                {
                    "number": "100",
                    "lat": 25.89,
                    "lon": -79.69611111111111
                },
                {
                    "number": "101",
                    "lat": 25.865000000000002,
                    "lon": -79.69972222222222
                },
                {
                    "number": "102",
                    "lat": 25.825833333333332,
                    "lon": -79.70444444444445
                },
                {
                    "number": "103",
                    "lat": 25.80666666666667,
                    "lon": -79.7063888888889
                },
                {
                    "number": "104",
                    "lat": 25.805555555555557,
                    "lon": -79.70666666666666
                },
                {
                    "number": "105",
                    "lat": 25.773888888888887,
                    "lon": -79.71222222222222
                },
                {
                    "number": "106",
                    "lat": 25.77111111111111,
                    "lon": -79.7125
                },
                {
                    "number": "107",
                    "lat": 25.727777777777778,
                    "lon": -79.71638888888889
                },
                {
                    "number": "108",
                    "lat": 25.70861111111111,
                    "lon": -79.71333333333334
                },
                {
                    "number": "109",
                    "lat": 25.676944444444445,
                    "lon": -79.7075
                },
                {
                    "number": "110",
                    "lat": 25.623333333333335,
                    "lon": -79.7075
                },
                {
                    "number": "111",
                    "lat": 25.61888888888889,
                    "lon": -79.7075
                },
                {
                    "number": "112",
                    "lat": 25.5175,
                    "lon": -79.70333333333333
                },
                {
                    "number": "113",
                    "lat": 25.46638888888889,
                    "lon": -79.70305555555557
                },
                {
                    "number": "114",
                    "lat": 25.40111111111111,
                    "lon": -79.70333333333333
                },
                {
                    "number": "115",
                    "lat": 25.3725,
                    "lon": -79.70555555555556
                },
                {
                    "number": "116",
                    "lat": 25.358055555555556,
                    "lon": -79.70222222222222
                },
                {
                    "number": "117",
                    "lat": 25.28111111111111,
                    "lon": -79.69
                },
                {
                    "number": "118",
                    "lat": 25.265833333333333,
                    "lon": -79.69194444444445
                },
                {
                    "number": "119",
                    "lat": 25.177500000000002,
                    "lon": -79.69194444444445
                },
                {
                    "number": "120",
                    "lat": 25.164166666666667,
                    "lon": -79.69333333333334
                },
                {
                    "number": "121",
                    "lat": 25.15083333333333,
                    "lon": -79.69583333333334
                },
                {
                    "number": "122",
                    "lat": 25.06527777777778,
                    "lon": -79.70805555555556
                },
                {
                    "number": "123",
                    "lat": 25.05,
                    "lon": -79.71555555555555
                },
                {
                    "number": "124",
                    "lat": 25.008333333333333,
                    "lon": -79.73472222222222
                },
                {
                    "number": "125",
                    "lat": 24.984166666666667,
                    "lon": -79.74666666666667
                },
                {
                    "number": "126",
                    "lat": 24.924444444444447,
                    "lon": -79.76583333333333
                },
                {
                    "number": "127",
                    "lat": 24.738333333333333,
                    "lon": -79.82333333333332
                },
                {
                    "number": "128",
                    "lat": 24.717777777777776,
                    "lon": -79.82722222222222
                },
                {
                    "number": "129",
                    "lat": 24.71,
                    "lon": -79.84722222222221
                },
                {
                    "number": "130",
                    "lat": 24.69638888888889,
                    "lon": -79.8825
                },
                {
                    "number": "131",
                    "lat": 24.642222222222223,
                    "lon": -79.99944444444445
                },
                {
                    "number": "132",
                    "lat": 24.6075,
                    "lon": -80.06416666666667
                },
                {
                    "number": "133",
                    "lat": 24.555,
                    "lon": -80.21194444444444
                },
                {
                    "number": "134",
                    "lat": 24.55138888888889,
                    "lon": -80.2225
                },
                {
                    "number": "135",
                    "lat": 24.536944444444448,
                    "lon": -80.25444444444445
                },
                {
                    "number": "136",
                    "lat": 24.524166666666666,
                    "lon": -80.28194444444445
                },
                {
                    "number": "137",
                    "lat": 24.515833333333333,
                    "lon": -80.29638888888888
                },
                {
                    "number": "138",
                    "lat": 24.503888888888888,
                    "lon": -80.32249999999999
                },
                {
                    "number": "139",
                    "lat": 24.501666666666665,
                    "lon": -80.32888888888888
                },
                {
                    "number": "140",
                    "lat": 24.49388888888889,
                    "lon": -80.35138888888888
                },
                {
                    "number": "141",
                    "lat": 24.471666666666664,
                    "lon": -80.40972222222223
                },
                {
                    "number": "142",
                    "lat": 24.46833333333333,
                    "lon": -80.41944444444445
                },
                {
                    "number": "143",
                    "lat": 24.45638888888889,
                    "lon": -80.45555555555556
                },
                {
                    "number": "144",
                    "lat": 24.441666666666666,
                    "lon": -80.49166666666667
                },
                {
                    "number": "145",
                    "lat": 24.41861111111111,
                    "lon": -80.53944444444444
                },
                {
                    "number": "146",
                    "lat": 24.391666666666666,
                    "lon": -80.60249999999999
                },
                {
                    "number": "147",
                    "lat": 24.375833333333333,
                    "lon": -80.64888888888889
                },
                {
                    "number": "148",
                    "lat": 24.36861111111111,
                    "lon": -80.66416666666667
                },
                {
                    "number": "149",
                    "lat": 24.325277777777778,
                    "lon": -80.75583333333333
                },
                {
                    "number": "150",
                    "lat": 24.32111111111111,
                    "lon": -80.76305555555555
                },
                {
                    "number": "151",
                    "lat": 24.310555555555556,
                    "lon": -80.78027777777778
                },
                {
                    "number": "152",
                    "lat": 24.309722222222224,
                    "lon": -80.78166666666667
                },
                {
                    "number": "153",
                    "lat": 24.164166666666667,
                    "lon": -80.99638888888889
                },
                {
                    "number": "154",
                    "lat": 24.16333333333333,
                    "lon": -80.9975
                },
                {
                    "number": "155",
                    "lat": 24.149444444444445,
                    "lon": -81.01861111111111
                },
                {
                    "number": "156",
                    "lat": 24.141666666666666,
                    "lon": -81.03083333333333
                },
                {
                    "number": "157",
                    "lat": 24.140555555555554,
                    "lon": -81.0325
                },
                {
                    "number": "158",
                    "lat": 24.124444444444446,
                    "lon": -81.05166666666666
                },
                {
                    "number": "159",
                    "lat": 24.03888888888889,
                    "lon": -81.15138888888889
                },
                {
                    "number": "160",
                    "lat": 24.0,
                    "lon": -81.18777777777778
                },
                {
                    "number": "161",
                    "lat": 23.925555555555558,
                    "lon": -81.21527777777779
                },
                {
                    "number": "162",
                    "lat": 23.897777777777776,
                    "lon": -81.3286111111111
                },
                {
                    "number": "163",
                    "lat": 23.847777777777775,
                    "lon": -81.49972222222222
                },
                {
                    "number": "164",
                    "lat": 23.833888888888886,
                    "lon": -81.66638888888889
                },
                {
                    "number": "165",
                    "lat": 23.818055555555556,
                    "lon": -81.83305555555555
                },
                {
                    "number": "166",
                    "lat": 23.818055555555556,
                    "lon": -82.00305555555556
                },
                {
                    "number": "167",
                    "lat": 23.828333333333333,
                    "lon": -82.16638888888889
                },
                {
                    "number": "168",
                    "lat": 23.85388888888889,
                    "lon": -82.41638888888889
                },
                {
                    "number": "169",
                    "lat": 23.85388888888889,
                    "lon": -82.66638888888889
                },
                {
                    "number": "170",
                    "lat": 23.828333333333333,
                    "lon": -82.81472222222222
                },
                {
                    "number": "171",
                    "lat": 23.825555555555557,
                    "lon": -82.85305555555556
                },
                {
                    "number": "172",
                    "lat": 23.823333333333334,
                    "lon": -82.99972222222222
                },
                {
                    "number": "173",
                    "lat": 23.83111111111111,
                    "lon": -83.24972222222222
                },
                {
                    "number": "174",
                    "lat": 23.85611111111111,
                    "lon": -83.43027777777779
                },
                {
                    "number": "175",
                    "lat": 23.874166666666667,
                    "lon": -83.55027777777778
                },
                {
                    "number": "176",
                    "lat": 23.90111111111111,
                    "lon": -83.69305555555556
                },
                {
                    "number": "177",
                    "lat": 23.929722222222225,
                    "lon": -83.80305555555556
                },
                {
                    "number": "178",
                    "lat": 23.97722222222222,
                    "lon": -83.99972222222222
                },
                {
                    "number": "179",
                    "lat": 24.160277777777775,
                    "lon": -84.49083333333333
                },
                {
                    "number": "180",
                    "lat": 24.22222222222222,
                    "lon": -84.64416666666668
                },
                {
                    "number": "181",
                    "lat": 24.278055555555554,
                    "lon": -84.76861111111111
                },
                {
                    "number": "182",
                    "lat": 24.391666666666666,
                    "lon": -84.99972222222222
                },
                {
                    "number": "183",
                    "lat": 24.44361111111111,
                    "lon": -85.10527777777777
                },
                {
                    "number": "184",
                    "lat": 24.649166666666666,
                    "lon": -85.53166666666667
                },
                {
                    "number": "185",
                    "lat": 24.738055555555558,
                    "lon": -85.71972222222223
                },
                {
                    "number": "186",
                    "lat": 24.899166666666666,
                    "lon": -85.99972222222222
                },
                {
                    "number": "187",
                    "lat": 25.17888888888889,
                    "lon": -86.50194444444445
                },
                {
                    "number": "188",
                    "lat": 25.72083333333333,
                    "lon": -86.35388888888889
                },
                {
                    "number": "189",
                    "lat": 26.220277777777778,
                    "lon": -86.1125
                },
                {
                    "number": "190",
                    "lat": 26.45611111111111,
                    "lon": -86.22083333333333
                },
                {
                    "number": "191",
                    "lat": 26.56277777777778,
                    "lon": -86.61861111111111
                },
                {
                    "number": "192",
                    "lat": 26.023333333333333,
                    "lon": -87.49305555555556
                },
                {
                    "number": "193",
                    "lat": 25.706944444444442,
                    "lon": -88.55
                },
                {
                    "number": "194",
                    "lat": 25.781666666666666,
                    "lon": -90.49472222222222
                },
                {
                    "number": "195",
                    "lat": 25.74416666666667,
                    "lon": -90.78472222222221
                },
                {
                    "number": "196",
                    "lat": 25.861944444444447,
                    "lon": -91.88055555555555
                },
                {
                    "number": "197",
                    "lat": 26.295555555555556,
                    "lon": -93.06638888888888
                },
                {
                    "number": "198",
                    "lat": 25.998611111111114,
                    "lon": -93.56444444444445
                },
                {
                    "number": "199",
                    "lat": 26.00888888888889,
                    "lon": -95.6575
                },
                {
                    "number": "200",
                    "lat": 26.009166666666665,
                    "lon": -96.80833333333334
                },
                {
                    "number": "201",
                    "lat": 25.975555555555555,
                    "lon": -96.92444444444445
                },
                {
                    "number": "202",
                    "lat": 25.97083333333333,
                    "lon": -96.97805555555556
                },
                {
                    "number": "203",
                    "lat": 25.96611111111111,
                    "lon": -97.03166666666667
                },
                {
                    "number": "204",
                    "lat": 25.961388888888887,
                    "lon": -97.08555555555554
                },
                {
                    "number": "205",
                    "lat": 25.956666666666667,
                    "lon": -97.13916666666667
                },
                {
                    "number": "206",
                    "lat": 25.956666666666667,
                    "lon": -97.1463888888889
                }
            ],
            [
                {
                    "number": "1",
                    "lat": 22.548333333333336,
                    "lon": -153.00916666666666
                },
                {
                    "number": "2",
                    "lat": 23.10138888888889,
                    "lon": -153.47666666666666
                },
                {
                    "number": "3",
                    "lat": 23.53638888888889,
                    "lon": -154.03666666666666
                },
                {
                    "number": "4",
                    "lat": 23.863055555555558,
                    "lon": -154.61333333333332
                },
                {
                    "number": "5",
                    "lat": 24.363611111111112,
                    "lon": -155.8536111111111
                },
                {
                    "number": "6",
                    "lat": 24.69638888888889,
                    "lon": -156.45749999999998
                },
                {
                    "number": "7",
                    "lat": 24.959166666666665,
                    "lon": -157.3713888888889
                },
                {
                    "number": "8",
                    "lat": 25.228055555555553,
                    "lon": -157.9036111111111
                },
                {
                    "number": "9",
                    "lat": 25.42527777777778,
                    "lon": -158.51
                },
                {
                    "number": "10",
                    "lat": 25.521944444444443,
                    "lon": -159.16305555555556
                },
                {
                    "number": "11",
                    "lat": 25.50861111111111,
                    "lon": -159.90583333333333
                },
                {
                    "number": "12",
                    "lat": 25.364722222222223,
                    "lon": -160.66472222222222
                },
                {
                    "number": "13",
                    "lat": 25.001666666666665,
                    "lon": -161.64249999999998
                },
                {
                    "number": "14",
                    "lat": 24.68027777777778,
                    "lon": -162.22027777777777
                },
                {
                    "number": "15",
                    "lat": 24.264722222222222,
                    "lon": -162.7188888888889
                },
                {
                    "number": "16",
                    "lat": 23.680555555555557,
                    "lon": -163.21666666666667
                },
                {
                    "number": "17",
                    "lat": 23.055555555555557,
                    "lon": -163.54944444444445
                },
                {
                    "number": "18",
                    "lat": 22.335833333333333,
                    "lon": -163.7447222222222
                },
                {
                    "number": "19",
                    "lat": 21.6125,
                    "lon": -163.7675
                },
                {
                    "number": "20",
                    "lat": 20.92388888888889,
                    "lon": -163.6288888888889
                },
                {
                    "number": "21",
                    "lat": 20.22611111111111,
                    "lon": -163.32027777777776
                },
                {
                    "number": "22",
                    "lat": 19.65083333333333,
                    "lon": -162.89666666666665
                },
                {
                    "number": "23",
                    "lat": 19.161944444444444,
                    "lon": -162.34305555555557
                },
                {
                    "number": "24",
                    "lat": 18.654444444444444,
                    "lon": -161.32055555555556
                },
                {
                    "number": "25",
                    "lat": 18.50861111111111,
                    "lon": -160.64166666666665
                },
                {
                    "number": "26",
                    "lat": 18.491944444444446,
                    "lon": -159.93805555555556
                },
                {
                    "number": "27",
                    "lat": 18.178055555555556,
                    "lon": -159.23555555555555
                },
                {
                    "number": "28",
                    "lat": 17.52138888888889,
                    "lon": -158.94861111111112
                },
                {
                    "number": "29",
                    "lat": 16.901666666666664,
                    "lon": -158.50805555555556
                },
                {
                    "number": "30",
                    "lat": 16.43027777777778,
                    "lon": -157.99027777777778
                },
                {
                    "number": "31",
                    "lat": 15.999166666666666,
                    "lon": -157.29305555555555
                },
                {
                    "number": "32",
                    "lat": 15.676944444444445,
                    "lon": -156.35166666666666
                },
                {
                    "number": "33",
                    "lat": 15.626666666666667,
                    "lon": -155.3711111111111
                },
                {
                    "number": "34",
                    "lat": 15.729444444444445,
                    "lon": -154.77694444444447
                },
                {
                    "number": "35",
                    "lat": 15.925555555555555,
                    "lon": -154.21805555555557
                },
                {
                    "number": "36",
                    "lat": 16.774166666666666,
                    "lon": -152.81972222222223
                },
                {
                    "number": "37",
                    "lat": 17.561666666666667,
                    "lon": -152.0088888888889
                },
                {
                    "number": "38",
                    "lat": 18.504444444444445,
                    "lon": -151.50666666666666
                },
                {
                    "number": "39",
                    "lat": 19.04638888888889,
                    "lon": -151.3713888888889
                },
                {
                    "number": "40",
                    "lat": 19.579444444444444,
                    "lon": -151.32972222222222
                },
                {
                    "number": "41",
                    "lat": 20.128333333333334,
                    "lon": -151.3827777777778
                },
                {
                    "number": "42",
                    "lat": 20.64527777777778,
                    "lon": -151.52666666666667
                },
                {
                    "number": "43",
                    "lat": 21.485833333333336,
                    "lon": -151.9972222222222
                },
                {
                    "number": "44",
                    "lat": 22.116111111111113,
                    "lon": -152.52361111111114
                },
                {
                    "number": "45",
                    "lat": 22.548333333333336,
                    "lon": -153.00916666666666
                }
            ],
            [
                {
                    "number": "1",
                    "lat": 17.310277777777777,
                    "lon": -67.53722222222223
                },
                {
                    "number": "2",
                    "lat": 19.18722222222222,
                    "lon": -67.44583333333334
                },
                {
                    "number": "3",
                    "lat": 19.50777777777778,
                    "lon": -65.28
                },
                {
                    "number": "4",
                    "lat": 19.206944444444442,
                    "lon": -65.10222222222221
                },
                {
                    "number": "5",
                    "lat": 18.753611111111113,
                    "lon": -65.00611111111111
                },
                {
                    "number": "6",
                    "lat": 18.68722222222222,
                    "lon": -64.9925
                },
                {
                    "number": "7",
                    "lat": 18.489444444444445,
                    "lon": -64.89750000000001
                },
                {
                    "number": "8",
                    "lat": 18.459722222222222,
                    "lon": -64.88944444444445
                },
                {
                    "number": "9",
                    "lat": 18.4225,
                    "lon": -64.8775
                },
                {
                    "number": "10",
                    "lat": 18.40833333333333,
                    "lon": -64.87194444444444
                },
                {
                    "number": "11",
                    "lat": 18.3975,
                    "lon": -64.86388888888888
                },
                {
                    "number": "12",
                    "lat": 18.395,
                    "lon": -64.85638888888889
                },
                {
                    "number": "13",
                    "lat": 18.393333333333334,
                    "lon": -64.83805555555556
                },
                {
                    "number": "14",
                    "lat": 18.396666666666665,
                    "lon": -64.82805555555555
                },
                {
                    "number": "15",
                    "lat": 18.403055555555554,
                    "lon": -64.81666666666666
                },
                {
                    "number": "16",
                    "lat": 18.407777777777778,
                    "lon": -64.79916666666666
                },
                {
                    "number": "17",
                    "lat": 18.404999999999998,
                    "lon": -64.78361111111111
                },
                {
                    "number": "18",
                    "lat": 18.386944444444445,
                    "lon": -64.77694444444444
                },
                {
                    "number": "19",
                    "lat": 18.376944444444444,
                    "lon": -64.75555555555556
                },
                {
                    "number": "20",
                    "lat": 18.3775,
                    "lon": -64.745
                },
                {
                    "number": "21",
                    "lat": 18.378333333333334,
                    "lon": -64.74333333333334
                },
                {
                    "number": "22",
                    "lat": 18.376944444444444,
                    "lon": -64.74
                },
                {
                    "number": "23",
                    "lat": 18.3775,
                    "lon": -64.72833333333334
                },
                {
                    "number": "24",
                    "lat": 18.375,
                    "lon": -64.72666666666667
                },
                {
                    "number": "25",
                    "lat": 18.37361111111111,
                    "lon": -64.71611111111112
                },
                {
                    "number": "26",
                    "lat": 18.37388888888889,
                    "lon": -64.70777777777778
                },
                {
                    "number": "27",
                    "lat": 18.370833333333334,
                    "lon": -64.70083333333334
                },
                {
                    "number": "28",
                    "lat": 18.372777777777777,
                    "lon": -64.68333333333334
                },
                {
                    "number": "29",
                    "lat": 18.365833333333335,
                    "lon": -64.67083333333333
                },
                {
                    "number": "30",
                    "lat": 18.36416666666667,
                    "lon": -64.63972222222223
                },
                {
                    "number": "31",
                    "lat": 18.35611111111111,
                    "lon": -64.63777777777779
                },
                {
                    "number": "32",
                    "lat": 18.344166666666666,
                    "lon": -64.64250000000001
                },
                {
                    "number": "33",
                    "lat": 18.320833333333333,
                    "lon": -64.63722222222223
                },
                {
                    "number": "34",
                    "lat": 18.31861111111111,
                    "lon": -64.63777777777779
                },
                {
                    "number": "35",
                    "lat": 18.289722222222224,
                    "lon": -64.66055555555556
                },
                {
                    "number": "36",
                    "lat": 18.27861111111111,
                    "lon": -64.6613888888889
                },
                {
                    "number": "37",
                    "lat": 18.1925,
                    "lon": -64.64944444444446
                },
                {
                    "number": "38",
                    "lat": 18.050555555555555,
                    "lon": -64.63416666666667
                },
                {
                    "number": "39",
                    "lat": 18.04888888888889,
                    "lon": -64.49305555555556
                },
                {
                    "number": "40",
                    "lat": 18.047500000000003,
                    "lon": -64.45055555555555
                },
                {
                    "number": "41",
                    "lat": 18.041666666666668,
                    "lon": -64.35222222222221
                },
                {
                    "number": "42",
                    "lat": 18.041944444444447,
                    "lon": -64.33555555555554
                },
                {
                    "number": "43",
                    "lat": 18.034166666666668,
                    "lon": -64.26583333333333
                },
                {
                    "number": "44",
                    "lat": 18.003333333333334,
                    "lon": -64.04138888888889
                },
                {
                    "number": "45",
                    "lat": 17.999444444444446,
                    "lon": -64.01777777777778
                },
                {
                    "number": "46",
                    "lat": 17.97972222222222,
                    "lon": -63.95027777777778
                },
                {
                    "number": "47",
                    "lat": 17.964166666666667,
                    "lon": -63.89833333333333
                },
                {
                    "number": "44",
                    "lat": 17.94388888888889,
                    "lon": -63.88916666666667
                },
                {
                    "number": "48",
                    "lat": 17.66111111111111,
                    "lon": -63.914722222222224
                },
                {
                    "number": "50",
                    "lat": 17.61888888888889,
                    "lon": -63.919444444444444
                },
                {
                    "number": "51",
                    "lat": 17.50583333333333,
                    "lon": -63.93222222222222
                },
                {
                    "number": "52",
                    "lat": 17.193333333333335,
                    "lon": -63.965833333333336
                },
                {
                    "number": "53",
                    "lat": 17.083333333333332,
                    "lon": -63.97805555555556
                },
                {
                    "number": "54",
                    "lat": 16.996944444444445,
                    "lon": -63.98833333333334
                },
                {
                    "number": "55",
                    "lat": 17.310277777777777,
                    "lon": -67.53722222222223
                }
            ]
        ]
    },
    getters: {
        getSecaZones(state) {
            return state.secaZones
        }
    },
}