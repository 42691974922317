<template>
  <Transition name="fade">
    <div class="vue-modal" v-if="visibility">
      <div class="modal-container">
        <div class="modal-header">
          <div class="header-name">
            <p class="fz18 fw500">{{ info.name }}</p>
            <p class="fz13 text-purple">{{ info.type }}</p>
          </div>
          <span class="modal-close" @click="hideModal">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2486 5.03572C19.5415 5.32861 19.5415 5.80348 19.2486 6.09638L13.2032 12.1417L19.2486 18.1871C19.5415 18.48 19.5415 18.9549 19.2486 19.2478C18.9557 19.5407 18.4808 19.5407 18.1879 19.2478L12.1426 13.2024L6.09721 19.2478C5.80432 19.5407 5.32944 19.5407 5.03655 19.2478C4.74366 18.9549 4.74366 18.48 5.03655 18.1871L11.0819 12.1417L5.03655 6.09637C4.74366 5.80348 4.74366 5.32861 5.03655 5.03571C5.32944 4.74282 5.80432 4.74282 6.09721 5.03571L12.1426 11.0811L18.1879 5.03572C18.4808 4.74282 18.9557 4.74282 19.2486 5.03572Z"
                fill="#262626"
              />
            </svg>
          </span>
        </div>
        <div class="modal-content">
          <div class="modal-row">
            <span class="title fz13 text-gray-text">Date / Time</span>
            <span class="text text-dark1 fz14">{{
              getDateTransform(info)
            }}</span>
          </div>
          <div class="modal-row">
            <span class="title fz13 text-gray-text">Repeat</span>
            <span class="text text-dark1 fz14 text-capitalize">{{
              info | getRepeatStatus
            }}</span>
          </div>
          <div class="modal-row">
            <span class="title fz13 text-gray-text">Timezone</span>
            <span class="text text-dark1 fz14">{{ info.timezone_info }}</span>
          </div>
          <div class="modal-row" v-if="user.data.user.id == info.creator">
            <span class="title fz13 text-gray-text">Display to</span>
            <span class="text text-dark1 fz14">{{ info.display_to }}</span>
          </div>
          <div class="modal-row">
            <span class="title fz13 text-gray-text">Creator</span>
            <div class="creator-item" v-if="creator.isLoaded">
              <div class="avatar" v-if="!creator.creatorInfo.avatar"></div>
              <div
                  class="avatar"
                  v-else
                  :style="{ 'background-image': `url(${creator.creatorInfo.avatar})` }"
                ></div>
              <span class="fz13 text-dark1">{{creator.creatorInfo.first_name}} {{creator.creatorInfo.last_name}}</span>
            </div>
          </div>
          <div class="modal-row" v-if="info.members.length != 0">
            <span class="title fz13 text-gray-text">Members</span>
            <div
              class="user"
              v-for="(user, index) in info.members"
              :key="index"
            >
              <div
                class="avatar"
                v-if="user.member.avatar"
                :style="{ 'background-image': `url(${user.member.avatar})` }"
              ></div>
              <div class="avatar" v-else></div>
              <div class="user-name fz13">
                <span class="name"
                  >{{ user.member.first_name || "-" }}
                  {{ user.member.last_name || "-" }}</span
                >
                <div class="is-accepted" v-html="isAccepted(user)"></div>
              </div>
            </div>

            <div class="members-list" v-if="user.data.user.id != info.creator">
              <div
                class="member-item"
                v-for="member in members.selectedMembers"
                :key="member.id"
              >
                <div class="avatar" v-if="!member.avatar"></div>
                <div
                  class="avatar"
                  v-else
                  :style="{ 'background-image': `url(${member.avatar})` }"
                ></div>
                <div class="member-info">
                  <span class="fz13 text-dark1">{{ member.name }}</span>
                  <div class="fz13 text-gray">
                    Can accept an invitation to:

                    <label
                      class="accept-date-wrapper"
                      v-if="!member.acceptDate"
                    >
                      <date-picker
                        class="datepick"
                        format="DD.MM.YYYY"
                        @change="setAcceptDate($event, member.id)"
                      >
                      </date-picker>
                      <span class="date">Choose Date</span>
                    </label>
                    <div class="accept-date-wrapper" v-else>
                      <span class="date-num">
                        {{ member.acceptDate.date }}
                        <span class="del-btn" @click="delAcceptDate(member.id)">
                          <svg
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                              fill="#2C2C2C"
                            />
                            <path
                              d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                              fill="#2C2C2C"
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div class="accept-time-wrapper" v-if="!member.acceptTime">
                      <span class="date" @click="showTimelist(member.id)"
                        >Choose Time</span
                      >
                    </div>
                    <div class="accept-time-wrapper" v-else>
                      <span class="date-num">
                        {{ member.acceptTime.time }}
                        <span class="del-btn" @click="delAcceptTime(member.id)">
                          <svg
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                              fill="#2C2C2C"
                            />
                            <path
                              d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                              fill="#2C2C2C"
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <span class="del-btn" @click="delSelectedMember(member.id)">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                      fill="#2C2C2C"
                    />
                    <path
                      d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                      fill="#2C2C2C"
                    />
                  </svg>
                </span>
              </div>
              <multiselect
                ref="timelistSelect"
                class="timelistSelect"
                open-direction="top"
                :show-labels="false"
                :options="timeList"
                :multiple="false"
                :searchable="false"
                :allow-empty="false"
                :hide-selected="true"
                :max-height="150"
                :max="10"
                @input="setAcceptTime($event)"
                @close="editMemberid = null"
              ></multiselect>
            </div>

            <button
              class="invite-btn fz13 text-blue"
              v-if="!info.is_creator && isAcceptedEvent && !viewOnly"
              @click="showModalMembers = true"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2221 6.2222H7.7778V0.777727C7.7778 0.348489 7.42931 0 6.99993 0C6.57069 0 6.2222 0.348489 6.2222 0.777727V6.2222H0.777727C0.348489 6.2222 0 6.57069 0 6.99993C0 7.42931 0.348489 7.7778 0.777727 7.7778H6.2222V13.2221C6.2222 13.6515 6.57069 14 6.99993 14C7.42931 14 7.7778 13.6515 7.7778 13.2221V7.7778H13.2221C13.6515 7.7778 14 7.42931 14 6.99993C14 6.57069 13.6515 6.2222 13.2221 6.2222Z"
                  fill="#319FEF"
                />
              </svg>
              Invite Member
            </button>
          </div>
          <div class="modal-row" v-if="info.tags.length != 0">
            <span class="title fz13 text-gray-text">Event Tag</span>
            <div class="tags-wrapper">
              <div
                class="tag-info fz11"
                v-for="(tag, index) in tags.list"
                :key="index"
              >
                {{ tag.name }}
              </div>
            </div>
          </div>
          <div class="modal-row" v-if="info.counterparties.length != 0">
            <span class="title fz13 text-gray-text">Counterparty</span>
            <div class="counterparty-wrapper">
              <div
                class="fz11 counterparty-item"
                v-for="(tag, index) in counterparties.list"
                :key="index"
              >
                {{ tag.company_name }}
              </div>
            </div>
          </div>
          <div class="modal-row" v-if="info.vessel">
            <span class="title fz13 text-gray-text">Vessel</span>
            <span class="text text-dark1 fz14">{{ vessels.name }}</span>
          </div>
          <div class="modal-row" v-if="info.comment">
            <span class="title fz13 text-gray-text">Comment</span>
            <span class="text text-dark1 fz14">{{ info.comment }}</span>
          </div>
          <div
            class="modal-row"
            v-if="
              info.notifications &&
              info.notifications.length != 0 &&
              info.is_creator &&
              !viewOnly
            "
          >
            <span class="title fz13 text-gray-text">Notifications</span>
            <span
              class="text text-dark1 fz14"
              v-for="(not, i) in info.notifications"
              :key="i"
              >{{ not }}</span
            >
          </div>
          <div
            class="modal-row"
            v-if="!info.is_creator && isAcceptedEvent && !viewOnly"
          >
            <span class="title fz13 text-gray-text">Notifications</span>
            <div class="notifications-list" v-if="myNotifications.length != 0">
              <div
                class="notifications__item"
                v-for="(notification, index) in myNotifications"
                :key="index"
              >
                <customSelect
                  ref="test"
                  class="invisible-select"
                  :options="notificatiionsList"
                  :selectedValue="notification"
                  @selected="($event) => (myNotifications[index] = $event)"
                ></customSelect>
                <span class="del-btn" @click="myNotifications.splice(index, 1)">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                      fill="#2C2C2C"
                    />
                    <path
                      d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                      fill="#2C2C2C"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <span
              class="notification-btn text-blue fz13"
              @click="addNotification"
            >
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.76471 16.2H6.23529C6.23529 16.3733 6.25963 16.5411 6.30499 16.7C6.40721 17.0581 6.61618 17.371 6.89399 17.6C7.19712 17.8499 7.58221 18 8 18C8.41779 18 8.80288 17.8499 9.10601 17.6C9.38382 17.371 9.59279 17.0581 9.69501 16.7C9.74037 16.5411 9.76471 16.3733 9.76471 16.2ZM15.5 15.3V14.4L13.7353 12.6V7.65C13.7353 4.86 11.8824 2.61 9.32353 1.98V1.35C9.32353 0.63 8.70588 0 8 0C7.29412 0 6.67647 0.63 6.67647 1.35V1.98C4.11765 2.61 2.26471 4.86 2.26471 7.65V12.6L0.5 14.4V15.3H15.5ZM2.95078 13.9H13.0492L12.3353 13.1718V7.65C12.3353 5.515 10.9325 3.81796 8.98883 3.3394L8 3.09595L7.01117 3.3394C5.06747 3.81795 3.66471 5.515 3.66471 7.65V13.1718L2.95078 13.9Z"
                  fill="#319FEF"
                />
              </svg>
              Add notification
            </span>
          </div>
        </div>
        <div class="modal-footer" v-if="info.is_creator && !viewOnly">
          <button class="modal-btn del-btn" @click="deleteEvent(info.id)">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.63636 1.36585C6.50732 1.36585 6.40909 1.46907 6.40909 1.58852V2.26399H9.59091V1.58852C9.59091 1.46907 9.49268 1.36585 9.36364 1.36585L6.63636 1.36585ZM10.9545 2.26399V1.58852C10.9545 0.707685 10.2387 2.90754e-07 9.36364 2.32603e-07L6.63636 0C5.76125 -5.81509e-08 5.04545 0.707684 5.04545 1.58852V2.26399H1.18182C0.80526 2.26399 0.5 2.56975 0.5 2.94692C0.5 3.32409 0.80526 3.62984 1.18182 3.62984H2.31818V13.5059C2.31818 14.8869 3.441 16 4.81818 16H11.1818C12.559 16 13.6818 14.8869 13.6818 13.5059V3.62984H14.8182C15.1947 3.62984 15.5 3.32409 15.5 2.94692C15.5 2.56975 15.1947 2.26399 14.8182 2.26399H10.9545ZM3.68182 3.62984V13.5059C3.68182 14.1255 4.18706 14.6341 4.81818 14.6341H11.1818C11.8129 14.6341 12.3182 14.1255 12.3182 13.5059V3.62984H3.68182ZM6.63636 5.88637C7.01292 5.88637 7.31818 6.19213 7.31818 6.5693V11.5501C7.31818 11.9272 7.01292 12.233 6.63636 12.233C6.25981 12.233 5.95455 11.9272 5.95455 11.5501V6.5693C5.95455 6.19213 6.25981 5.88637 6.63636 5.88637ZM9.36364 5.88637C9.74019 5.88637 10.0455 6.19213 10.0455 6.5693V11.5501C10.0455 11.9272 9.74019 12.233 9.36364 12.233C8.98708 12.233 8.68182 11.9272 8.68182 11.5501V6.5693C8.68182 6.19213 8.98708 5.88637 9.36364 5.88637Z"
                fill="#E85353"
              />
            </svg>

            Delete
          </button>
          <button class="modal-btn edit-btn" @click="showEditForm(info.id)">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.9815 1.02666C12.3235 0.365661 11.4509 0 10.5175 0C9.58888 0 8.71156 0.365661 8.05357 1.02666L0.846338 8.276C0.743673 8.37914 0.683007 8.5104 0.659674 8.65104L0.00634799 13.2499C-0.0216517 13.4515 0.043681 13.6578 0.188346 13.8031C0.309678 13.9297 0.482343 14 0.655007 14C0.683007 14 0.70634 14 0.73434 13.9953L5.38695 13.414C5.53162 13.3953 5.66695 13.3296 5.76962 13.2265L12.9815 5.97246C14.3395 4.60357 14.3395 2.39086 12.9815 1.02666ZM5.00429 12.1342L1.42033 12.5795L1.92433 9.04952L7.80157 3.13624L10.8769 6.22561L5.00429 12.1342ZM12.0622 5.04893L11.8055 5.30677L8.73023 2.2174L8.98689 1.95957C9.39289 1.53765 9.93888 1.31263 10.5222 1.31263C11.1009 1.31263 11.6515 1.53765 12.0622 1.95488C12.4728 2.37211 12.7015 2.91591 12.7015 3.5019C12.7015 4.08321 12.4775 4.63639 12.0622 5.04893Z"
                fill="white"
              />
            </svg>

            Edit
          </button>
        </div>
        <div class="modal-footer" v-if="!info.is_creator && !viewOnly">
          <div class="button-wrapper" v-if="isAcceptedEvent">
            <button class="modal-btn del-btn" @click="cancel()">Cancel</button>
            <button class="modal-btn edit-btn" @click="saveEvent()">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9815 1.02666C12.3235 0.365661 11.4509 0 10.5175 0C9.58888 0 8.71156 0.365661 8.05357 1.02666L0.846338 8.276C0.743673 8.37914 0.683007 8.5104 0.659674 8.65104L0.00634799 13.2499C-0.0216517 13.4515 0.043681 13.6578 0.188346 13.8031C0.309678 13.9297 0.482343 14 0.655007 14C0.683007 14 0.70634 14 0.73434 13.9953L5.38695 13.414C5.53162 13.3953 5.66695 13.3296 5.76962 13.2265L12.9815 5.97246C14.3395 4.60357 14.3395 2.39086 12.9815 1.02666ZM5.00429 12.1342L1.42033 12.5795L1.92433 9.04952L7.80157 3.13624L10.8769 6.22561L5.00429 12.1342ZM12.0622 5.04893L11.8055 5.30677L8.73023 2.2174L8.98689 1.95957C9.39289 1.53765 9.93888 1.31263 10.5222 1.31263C11.1009 1.31263 11.6515 1.53765 12.0622 1.95488C12.4728 2.37211 12.7015 2.91591 12.7015 3.5019C12.7015 4.08321 12.4775 4.63639 12.0622 5.04893Z"
                  fill="white"
                />
              </svg>
              Save
            </button>
          </div>
          <div class="button-wrapper justify-end w-full p-r-8" v-else>
            <button class="modal-btn edit-btn" @click="acceptEvent()">
              Accept Invitation
            </button>
          </div>
        </div>
      </div>
      <membersModal
        @addMembers="addMembers($event)"
        :show="showModalMembers"
        @close="closeModalMembers"
        :selected="members.selectedMembers"
        :excludeMembers="info.members.map((el) => el.member.id)"
        ref="membersModal">
      </membersModal>
      <editRecurringModal
        ref="editReccuringEvent"
        @action="editRecurringEvents($event)"
      ></editRecurringModal>
    </div>
  </Transition>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
// import tzlist from "./timeZoneList";
import customSelect from "@/components/calendar/customSelect";
import membersModal from "@/components/calendar/membersModal";
import editRecurringModal from "@/components/calendar/editRecurringModal";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import moment from "moment";
export default {
  components: {
    customSelect,
    membersModal,
    Multiselect,
    DatePicker,
    editRecurringModal,
  },
  data: () => {
    return {
      date: null,
      isAcceptedEvent: true,
      showModalMembers: false,
      visibility: false,
      info: null,
      user: null,
      viewOnly: false,
      users: {
        isLoaded: false,
        list: [],
      },
      creator: {
        isLoaded: false,
        creatorInfo: [],
      },
      tags: {
        list: [],
      },
      counterparties: {
        list: [],
      },
      vessels: null,
      myNotifications: [],
      notificatiionsList: [
        "5 min before start",
        "15 min before start",
        "30 min before start",
        "1 hour before start",
        "1 day before start",
        "2 days before start",
        "5 days before start",
        "7 days before start",
      ],
      members: {
        selectedMembers: [],
      },
      timeList: [],
      editMemberid: null,
      showSaveButtons: false,
    };
  },
  methods: {
    ...mapActions([
      "axiosGetEmployeeById",
      "getAxiosTagById",
      "axiosGetCalendarClientByID",
      "axiosGetCalendarVesselsByID",
      "axiosGetCalendarEventById",
      "axiosDeleteCalendarEvent",
      "axiosGetCalendarMembersList",
      "axiosUpdateCalendarEvent",
      "axiosAcceptEvent",
    ]),
    ...mapMutations(["showLoad", "hideLoad", "setCalendarCurrentDate"]),
    fillTimeList() {
      for (var i = 0; i < 24; i++) {
        if (i < 10) {
          this.timeList.push(`0${i}:00`);
          this.timeList.push(`0${i}:15`);
          this.timeList.push(`0${i}:30`);
          this.timeList.push(`0${i}:45`);
        } else {
          this.timeList.push(`${i}:00`);
          this.timeList.push(`${i}:15`);
          this.timeList.push(`${i}:30`);
          this.timeList.push(`${i}:45`);
        }
      }
    },
    async showModal(info, day) {
      this.date = day;
      this.setInfo(info);
    },
    async setInfo(info) {
      this.showLoad();
      this.getCurrentUser();
      this.info = await this.axiosGetCalendarEventById(info.id);
      this.getCreatorInfo()

      let data = JSON.parse(JSON.stringify(this.info));
      if (this.info.notifications) {
        if (this.info.notifications.length != 0) {
          this.myNotifications = info.notifications;
        } else {
          this.myNotifications = [];
        }
      }

      if (Array.isArray(data.members) && data.members.length != 0) {
        if (this.user.data.user.id != this.info.creator) {
          let currentUser = data.members.find(
            (el) => el.member.id == this.user.data.user.id
          );
          if (currentUser) {
            this.isAcceptedEvent = currentUser.is_accepted;
            this.viewOnly = false;
          } else {
            this.viewOnly = true;
          }
          this.info.members = data.members.filter(
            (el) => el.creator != this.user.data.user.id
          );
          this.members.selectedMembers = data.members.filter(
            (el) => el.creator == this.user.data.user.id
          );
        } else {
          this.viewOnly = false;
        }
      } else if (this.user.data.user.id != this.info.creator) {
        this.viewOnly = true;
      }

      if (this.members.selectedMembers.length != 0) {
        this.members.selectedMembers.forEach((el) => {
          el.name = `${el.member.first_name} ${el.member.last_name}`;
          if (el.date) {
            el.acceptDate = {
              date: moment(el.date).format("YYYY.MM.DD"),
            };
          }
          if (el.time) {
            el.acceptTime = {
              time: el.time.substr(0, 5),
            };
          }
        });
      }
      if (this.info.display_to == "Only to myself") {
        this.info.members = [];
      }

      if (this.info.tags) this.tags.list = this.info.tags;
      if (this.info.counterparties)
        this.counterparties.list = this.info.counterparties;
      if (this.info.vessel) this.vessels = this.info.vessel;
      this.visibility = true;

      this.fillTimeList();
      this.hideLoad();
    },
    hideModal() {
      this.visibility = false;
      this.info = null;
      this.myNotifications = [];
      this.tags.list = [];
      this.counterparties.list = [];
      this.vessels = [];

      this.clear();
    },
    showEditForm(id) {
      
      if(this.$route.name == 'Notifications'){
        this.setCalendarCurrentDate(new Date(this.info.date))
        this.$router.push({ name: "calendar_day", query: {eventId: id} });
      }
      if (["custom_days_repeat", "daily", "every_workday_repeat"].includes(this.info.repeat) || this.info.parent_id) {
        this.$refs.editReccuringEvent.showModal("edit");
      } else {
        this.hideModal();
        this.$myEmitter.emit("showEditForm", { 
          id: id, 
          editAllEvents: false,
          parentEvent: null,
          isClonedEvent: false });
      }
    },
    cancel() {
      this.hideModal();
    },
    saveEvent() {
      this.showLoad();
      this.info.eventID = this.info.id;
      let data = JSON.parse(JSON.stringify(this.info));

      if (this.info.counterparties.length != 0) {
        data.counterparties = this.info.counterparties.map((el) => {
          return el.id;
        });
      }
      if (this.info.tags.length != 0) {
        data.tags = this.info.tags.map((el) => {
          return el.id;
        });
      }
      if (this.info.vessel) {
        data.vessel = this.info.vessel.id;
      }

      if (this.info.members.length != 0) {
        data.members = this.info.members.map((el) => {
          let member = {};
          member.creator = el.creator;
          member.member = el.member.id;
          member.date = el.date;
          member.time = el.time;
          member.is_accepted = el.is_accepted;
          return member;
        });
      }

      if (this.members.selectedMembers.length != 0) {
        this.members.selectedMembers.forEach((el) => {
          let member = {};
          member.creator = this.user.data.user.id;
          if (el.member) {
            member.member = el.member.id;
          } else {
            member.member = el.id;
          }
          member.is_accepted = false;
          member.date = null;
          member.time = null;
          if (el.acceptDate) {
            member.date = moment(el.acceptDate.date)
              .format("YYYY.MM.DD")
              .split(".")
              .join("-");
          }
          if (el.acceptTime) {
            member.time = el.acceptTime.time;
          }

          data.members.push(member);
        });
      }

      data.notifications = JSON.parse(JSON.stringify(this.myNotifications));

      this.axiosUpdateCalendarEvent(data)
        .then(() => {
          this.$myEmitter.emit("lodadNewData", new Date());
          this.$myEmitter.emit("UpdateEvents", new Date());
          this.hideLoad();
          this.hideModal();
        })
        .catch((err) => {
          this.hideLoad();
          console.log(err);
        });
    },
    clear() {
      this.info = null;
      this.users.isLoaded = false;
      this.users.list = [];
      this.tags.isLoaded = false;
      this.tags.list = [];
      this.creator.isLoaded = false;
      this.creator.creatorInfo = [];
      this.myNotifications = [];
      this.members.selectedMembers = [];
    },
    async getCreatorInfo() {
      if (this.info.creator) {
        let user = await this.axiosGetEmployeeById(this.info.creator);
        this.creator.creatorInfo = user;
        this.creator.isLoaded = true;
      }
    },
    deleteEvent(id) {
      if (
        [
          "custom_days_repeat",
          "daily",
          "every_workday_repeat",
        ].includes(this.info.repeat)
      ) {
        this.$refs.editReccuringEvent.showModal("del");
      } else {
        this.axiosDeleteCalendarEvent(id).then(() => {
          this.hideModal();
          this.clear();
          this.$emit("delEvent", id);
          this.$myEmitter.emit("updateDots");
        });
      }
    },
    editRecurringEvents(e) {
      let data = JSON.parse(JSON.stringify(this.info));
      if(data.parent_id){
        data.isClonedEvent = true;
      }else{
        data.isClonedEvent = false;
      }
      
      if (e.type == "del") {
        if (e.changeEvents == "Only this event") {
          data.eventID = this.info.id;
          if (!data.exclude_days) {
            data.exclude_days = [
              moment(this.date.date).format("YYYY.MM.DD").split(".").join("-"),
            ];
          } else {
            data.exclude_days.push(
              moment(this.date.date).format("YYYY.MM.DD").split(".").join("-")
            );
          }

          if(this.info.vessel){
            data.vessel = this.info.vessel.id
          }

          if(this.info.counterparties.length != 0){
            data.counterparties = this.info.counterparties.map((el) => { 
              return el.id; 
            });
          }
          if(this.info.tags.length != 0){
            data.tags = this.info.tags.map((el) => { 
              return el.id; 
            });
          }

          if(this.info.members.length != 0){
            data.members = this.info.members.map((el) => {
              let member = {};
              member.creator = el.creator
              member.member = el.member.id;
              member.is_accepted = el.is_accepted;
              member.date =  el.date;
              member.time = el.time;
              el.notifications ? member.notifications = el.notifications : member.notifications = []
              if (el.acceptDate) {
                member.date = moment(el.acceptDate.date)
                  .format("YYYY.MM.DD")
                  .split(".")
                  .join("-");
              }
              if (el.acceptTime) {
                member.time = el.acceptTime.time;
              }
              return member;
            });
          }
          this.axiosUpdateCalendarEvent(data)
            .then(() => {
              this.$myEmitter.emit("lodadNewData", new Date());
              this.$myEmitter.emit("UpdateEvents", new Date());
              this.hideLoad();
              this.hideModal();
            })
            .catch((err) => {
              this.hideLoad();
              console.log(err);
            });
        } else if (e.changeEvents == "All events") {
          this.axiosDeleteCalendarEvent(data.id).then(() => {
            this.hideModal();
            this.clear();
            this.$emit("delEvent", data.id);
          });
        }
      } else if (e.type == "edit") {
        if (e.changeEvents == "Only this event") {
          this.hideModal();
          data.currentDay = this.date;
          this.$myEmitter.emit("showEditForm", {
            id: data.id,
            parentEvent: data,
            isClonedEvent: data.isClonedEvent,
            editAllEvents: false
          });
        } else if (e.changeEvents == "All events") {
          this.hideModal();
          let parentEvent;
          if(data.isClonedEvent){
            parentEvent = data;
          }else{
            parentEvent = null;
          }
          this.$myEmitter.emit("showEditForm", {
            id: data.id,
            parentEvent: parentEvent,
            isClonedEvent: data.isClonedEvent,
            editAllEvents: true
          });
        }
      }
    },
    isAccepted(user) {
      let date = "";
      let time = "";

      if (user.date)
        date = new Date(user.date).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
          day: "numeric",
        });
      if (user.time) time = user.time.substr(0, 5);

      if (user.is_accepted) {
        return `
          <span class="is-accepted__text text-gray-text fz12">Accepted</span>`;
      }
      if (!user.is_accepted && date != "") {
        if (moment(`${user.date} ${user.time}`).isAfter(moment())) {
          return `
          <span class="is-accepted__text text-gray-text fz12">Can accept an invitation to: </span>
          <span class="is-accepted__date fz12">${date}, ${time}</span>
          `;
        } else {
          return `
          <span class="is-accepted__text text-red fz12">Can accept an invitation to: </span>
          <span class="is-accepted__date text-red fz12">${date}, ${time}</span>
          `;
        }
      }
      if (!user.is_accepted) {
        return `<span class="is-accepted__text text-gray-text fz12">Not accepted</span>`;
      }
    },
    getCurrentUser() {
      this.user = JSON.parse(localStorage.getItem("userData"));
    },
    addNotification() {
      this.myNotifications.push("5 min before start");
    },
    closeModalMembers() {
      this.showModalMembers = false;
    },
    addMembers(members) {
      this.members.selectedMembers = members;
    },
    showTimelist(id) {
      this.editMemberid = id;
      this.$refs.timelistSelect.activate();
    },
    setAcceptTime(e) {
      let member = this.members.selectedMembers.find(
        (item) => item.id == this.editMemberid
      );
      member.acceptTime = {
        time: e,
      };
      this.$forceUpdate();
    },
    delAcceptTime(id) {
      let member = this.members.selectedMembers.find((item) => item.id == id);
      delete member.acceptTime;
      this.$forceUpdate();
    },
    delSelectedMember(id) {
      this.members.selectedMembers = this.members.selectedMembers.filter(
        (item) => item.id != id
      );
      this.$forceUpdate();
    },
    setAcceptDate(date, id) {
      let member = this.members.selectedMembers.find((item) => item.id == id);
      member.acceptDate = {
        date: moment(date).format("YYYY.MM.DD"),
      };
      this.$forceUpdate();
    },
    delAcceptDate(id) {
      let member = this.members.selectedMembers.find((item) => item.id == id);

      delete member.acceptDate;
      this.$forceUpdate();
    },
    acceptEvent() {
      if (Array.isArray(this.info.members) && this.info.members.length != 0) {
        if (this.user.data.user.id != this.info.creator) {
          var currentMember = this.info.members.find(
            (el) => el.member.id == this.user.data.user.id
          );
        }
        this.showLoad();
        this.axiosAcceptEvent(currentMember.id)
          .then(() => {
            this.isAcceptedEvent = true;
            // Обновить данные события
            this.setInfo(this.info);
            // this.hideLoad();
          })
          .catch(() => {
            this.hideLoad();
          });
      }
    },
    getDateTransform(value) {
      let date;
      // Event info используется в календаре и уведомлениях, в уведомлениях нет this.date
      if(this.date){
        date = new Date(this.date.date).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
          day: "numeric",
        });
      }else{
        date = new Date(value.date).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
          day: "numeric",
        });
      }
      let str = `${date}, ${value.from_time.substr(0,5)} - ${value.to_time.substr(0, 5)}`;
      return str;
    },
  },
  filters: {
    // dateTransform: function (value) {
    //   let date = new Date(value.date).toLocaleDateString("en-US", {
    //     month: "short",
    //     year: "numeric",
    //     day: "numeric",
    //   });
    //   let str = `${date}, ${value.from_time.substr(0, 5)} - ${value.to_time.substr(0,5)}`;
    //   return str;
    // },
    getRepeatStatus: function (val) {
      let str = val.repeat.split("_").join(" ");
      if(val.end_repeat_day && !val.is_never){
        let date = new Date(val.end_repeat_day).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }).split(' ');

        str = `${str},  End: ${date[1].replace(',','')} ${date[0]}, ${date[2]}`
      }
      
      return str;
    },
  },
  watch: {
    // myNotifications: function (val) {
    // console.log(val.length)
    // if(val.length == 0){
    //   this.clearSelected = true;
    // }
    // if(!this.showSaveButtons) this.showSaveButtons = true
    // },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.task-name-wrapper {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  span {
    color: var(--color-orange);
    padding-top: 2px;
    text-transform: capitalize;
  }
}

.task-info-wrapper {
  width: 100%;
  padding: 24px 24px 10px 24px;
  padding-top: 0;

  .task-info-wrapper__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 12px;
  }

  .row-title {
    padding-bottom: 2px;
  }

  .status {
    color: #fff;
    padding: 2px 8px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;

    &.open {
      background: var(--color-green);
    }
  }

  .user {
    display: flex;
    align-items: center;
  }

  .avatar {
    background-size: contain;
    background-position: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z' fill='%23DEE3EA'/%3E%3C/svg%3E%0A");
    margin-right: 6px;
  }

  .task-block__prioity {
    color: #785400;
    padding-bottom: 3px;
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }

      &.High {
        svg {
          path {
            fill: #f2994a;
          }
        }
      }

      &.Urgent {
        svg {
          path {
            fill: #e85353;
          }
        }
      }

      &.Normal {
        svg {
          path {
            fill: #0fa54e;
          }
        }
      }

      &.Low {
        svg {
          path {
            fill: #9aa3b0;
          }
        }
      }
    }
  }

  .tags-wrapper {
    display: flex;
    max-width: 320px;
    flex-wrap: wrap;
  }

  .tag-info {
    border: 1px solid var(--color-border);
    padding: 2px 6px;
    border-radius: 2px 10px 10px 2px;
    margin-right: 6px;
    margin-bottom: 3px;
  }
}

// -----------------
.vue-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.3);
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: auto;
  }

  .modal-container {
    width: 430px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 13px 24px 0;
    padding-top: 13px;
    position: relative;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  height: 39px;
  margin-bottom: 12px;
  padding: 0 24px;
}

.modal-footer,
.button-wrapper {
  height: 48px;
  border-top: 1px solid #dee3ea;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 32px;

    &.edit-btn {
      background: #319fef;
      border-radius: 4px;
      color: #fff;

      svg {
        margin-right: 9px;
      }
    }

    &.del-btn {
      color: #fff;
      color: #e85353;

      svg {
        margin-right: 9px;
      }
    }
  }
}

.modal-content {
  max-height: 70vh;
  overflow-y: auto;

  .modal-row {
    width: 100%;
    min-height: 37px;
    height: auto;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 24px;

    .title {
      padding-bottom: 3px;
    }
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .user {
    display: flex;
    align-items: center;
    padding-bottom: 6px;

    .user-name {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .avatar {
    background-size: contain;
    background-position: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z' fill='%23DEE3EA'/%3E%3C/svg%3E%0A");
    margin-right: 6px;
  }

  .tags-wrapper {
    display: flex;
    max-width: 320px;
    flex-wrap: wrap;
    padding-top: 6px;
  }

  .tag-info {
    border: 1px solid var(--color-border);
    padding: 2px 6px;
    border-radius: 2px 10px 10px 2px;
    margin-right: 6px;
    margin-bottom: 3px;
  }

  .counterparty-wrapper {
    display: flex;
    max-width: 320px;
    flex-direction: column;
  }

  .counterparty-item {
    padding-right: 8px;
    width: fit-content;
    padding-bottom: 4px;
  }

  .invite-btn {
    border: none;
    background: none;
    display: flex;
    align-items: center;

    svg {
      margin-right: 9px;
    }
  }

  .notifications-list {
    .notifications__item {
      display: flex;
      align-items: center;
      position: relative;

      .del-btn {
        position: absolute;
        right: 10px;
        display: none;

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        background: var(--color-gray-lightest);

        .del-btn {
          display: flex;
        }
      }
    }
  }

  .notification-btn {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .members-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    border-top: 1px solid #dee3ea;

    .member-item {
      height: 40px;
      width: 100%;
      padding-right: 30px;
      padding: 4px;
      position: relative;
      position: relative;
      display: flex;
      align-items: center;

      .del-btn {
        position: absolute;
        right: 5px;
        top: 14px;
        display: none;
      }

      &:hover {
        background: var(--color-bgc-page);
        cursor: pointer;

        .del-btn {
          display: flex;
        }
      }
    }

    .member-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .accept-date-wrapper,
    .accept-time-wrapper {
      position: relative;
      display: inline-flex;

      .datepick {
        position: absolute;
        z-index: -999999;
        opacity: 0;
      }

      .date {
        color: #9aa3b0;

        &:hover {
          color: #319fef;
          cursor: pointer;
        }
      }

      .date-num {
        color: #2c2c2c;
        display: inline-flex;
        width: 85px;
        align-items: center;

        .del-btn {
          top: 4px;
          display: flex;

          svg {
            width: 8px;
            height: 8px;

            path {
              fill: #9aa3b0;
            }
          }
        }
      }
    }

    .accept-time-wrapper {
      margin-left: 10px;

      .date-num {
        width: 60px;
      }
    }
  }
  .creator-item{
    display: flex;
    align-items: center;
  }
}

.modal-close {
  &:hover {
    cursor: pointer;
  }
}
</style>
