<template>
  <div>
    <folders-charterers-aditionaly-wrap :getPermissionSelect="!getActiveSelectedType || getActiveSelectedType == 'DETAIL'" @create-folder="showModalCreateFolder = true" @upload-file="(e)=>{uploadFile(e)}" :files="files.filter(a => !a.filter_type)" :folders="folders.filter(a => !a.filter_type)" :title="'Details'"></folders-charterers-aditionaly-wrap>
    <folders-charterers-aditionaly-wrap :getPermissionSelect="!getActiveSelectedType || getActiveSelectedType == 'FREIGHT'" v-if="!isSublet && listTypes.find(a=> a.type == 'FREIGHT')" @upload-file="(e)=>{uploadFile(e, 'FREIGHT')}" :hideFolders="true" :files="files.filter(a => a.filter_type == 'FREIGHT')" :folders="[]" :title="'Freight Invoice'"></folders-charterers-aditionaly-wrap>
    <folders-charterers-aditionaly-wrap :getPermissionSelect="!getActiveSelectedType || getActiveSelectedType == 'SHIP'" v-if="!isSublet && listTypes.find(a=> a.type == 'SHIP')" @upload-file="(e)=>{uploadFile(e, 'SHIP')}" :hideFolders="true" :files="files.filter(a => a.filter_type == 'SHIP')" :folders="[]" :title="'VSOA'"></folders-charterers-aditionaly-wrap>
    <folders-charterers-aditionaly-wrap :getPermissionSelect="!getActiveSelectedType || getActiveSelectedType == 'ADDITIONAL_FREIGHT'" v-if="!isSublet && listTypes.find(a=> a.type == 'ADDITIONAL_FREIGHT')" @upload-file="(e)=>{uploadFile(e, 'ADDITIONAL_FREIGHT')}" :hideFolders="true" :files="files.filter(a => a.filter_type == 'ADDITIONAL_FREIGHT')" :folders="[]" :title="'Additional Freight Invoice'"></folders-charterers-aditionaly-wrap>
    <folders-charterers-aditionaly-wrap :getPermissionSelect="!getActiveSelectedType || getActiveSelectedType == 'DETENTION'" v-if="!isSublet && listTypes.find(a=> a.type == 'DETENTION')" @upload-file="(e)=>{uploadFile(e, 'DETENTION')}" :hideFolders="true" :files="files.filter(a => a.filter_type == 'DETENTION')" :folders="[]" :title="'Detention Invoice'"></folders-charterers-aditionaly-wrap>
    <folders-charterers-aditionaly-wrap :getPermissionSelect="!getActiveSelectedType || getActiveSelectedType == 'SOA'" v-if="isSublet && listTypes.find(a=> a.type == 'SOA')" @upload-file="(e)=>{uploadFile(e, 'SOA')}" :hideFolders="true" :files="files.filter(a => a.filter_type == 'SOA')" :folders="[]" :title="'SOA Sublet'"></folders-charterers-aditionaly-wrap>
    <folders-modal-add-folder @create="createFolder" @close="showModalCreateFolder = false" v-if="showModalCreateFolder"></folders-modal-add-folder>
  </div>
</template>

<script>
import FoldersCharterersAditionalyWrap
  from "@/components/folders/folders-charterers-additionally/folders-charterers-aditionaly-wrap.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import FoldersModalAddFolder from "@/components/folders/folders-modal-add-folder.vue";
export default {
  props: {
    search: String,
    sort: String,
    routeChange: Number,
  },
  name: "folders-vessels-option-charteres-files",
  components: {FoldersModalAddFolder, FoldersCharterersAditionalyWrap},
  computed: {
    ...mapState({
      files: state=> state.folders.files,
      folders: state=> state.folders.folders,
      selected: state=> state.folders.selected,
    }),
    isSublet() {
      let detail = JSON.parse(this.$route.params.charterersDetail)
      return detail.type ? detail.type === 'sublet' : false
    },
    getActiveSelectedType() {
      let item = this.selected.length ? this.selected[0] : null
      if(item) {
        return item.filter_type ? item.filter_type : 'DETAIL'
      }
      return ''
    }
  },
  data() {
    return {
      showDetails: true,
      showFrightInvoice: true,
      showVSOA: true,
      showAdditional: true,
      showDetention: true,
      showModalCreateFolder: false,
      listTypes: [],
    }
  },
  watch: {
    routeChange: function()  {
      this.loadData()
    },
    sort: function() {
      this.loadData()
    },
    search: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions(['axiosGetFoldersVorVessel', 'axiosGetFoldersForInvoice', 'axiosCreateFileInFolder', 'axiosGetFoldersForSOA']),
    ...mapMutations(['emitChangeLeftMenuSetCurrentFoldersInActiveObject', 'setFiles', 'showLoad', 'hideLoad', 'createEmitGetActivityFolders']),
    getIdByType(type) {
      let item = this.listTypes.find(a=> a.type == type)
      return item ? item.id : null
    },
    async loadData() {
      this.listTypes = []
      let filesDetail = await this.axiosGetFoldersVorVessel({notSetFiles: true, id: this.$route.params.charterersDetail ? JSON.parse(this.$route.params.charterersDetail).id : null, params: {is_archive_files: this.$route.params.files_is_archive,ordering: this.sort, search: this.search}})
      console.log(filesDetail)
      let filesInvoices = []
      if(!this.isSublet) {
        filesInvoices = await this.axiosGetFoldersForInvoice({notSetFiles: true, params: {contract: this.$route.params.charterersDetail ? JSON.parse(this.$route.params.charterersDetail).id : null, folders: true}})
        this.listTypes = filesInvoices.map(a=> {return {type: a.invoice_type, id: a.id}})
        filesInvoices = filesInvoices.map(a=>{
          return a.files
        }).flat()
      } else {
        let res = await this.axiosGetFoldersForSOA({is_archive_files: this.$route.params.files_is_archive, ordering: this.sort, search: this.search, contract: this.$route.params.charterersDetail ? JSON.parse(this.$route.params.charterersDetail).id : null, limit: 1, folders: true})
        if(res) {
          filesInvoices = res ? res.files : []
          filesInvoices.forEach(item=> {
            item['filter_type'] = 'SOA'
          })
          this.listTypes.push({type: 'SOA', id: res.id})
        }

      }


      console.log(filesInvoices)

      this.setFiles(filesDetail.concat(filesInvoices))
      this.emitChangeLeftMenuSetCurrentFoldersInActiveObject()
      return null
    },
    async createFolder(name) {
      this.showModalCreateFolder = false
      this.showLoad()
      let formData = ''
      formData = {
        file_type: 'FOLDER',
        type: this.getTypeFileBySection,
        name: name,
        // path_to_folder: this.getPathToFolder,
        is_important: false,
        is_archive: false,
        shared_with: [],
      }
      let path = this.$route.params.path ? this.$route.params.path : null
      let parent_id = null
      if(path) {
        path = JSON.parse(path)
        parent_id = path[path.length -1].id
        formData["parent_file"] = parent_id
      } else {
          formData['destination'] = 'vessels'
          let id = JSON.parse(this.$route.params.charterersDetail).id
          formData['destination_object_id'] = id
      }

      let res = await this.axiosCreateFileInFolder({data: formData, params: {is_create: true}})
      formData.id = res.id
      formData.name = res.name
      // this.addedFolderInMoveToModal({activeFolder: path ? parent_id : this.getActiveObject ? this.getActiveObject : 'myfiles', item: formData})
      this.createEmitGetActivityFolders()
      if(res) {
        console.log(1)
      }
      await this.loadData()
      this.hideLoad()

    },
    async uploadFolder(e) {
      let files = e.target.files
      let filteredFiles = []
      for(let i=0; i<files.length; i++) {
        if(files[i].name.indexOf('.DS') == -1) {
          filteredFiles.push(files[i])
        }
      }
      let errorName = filteredFiles.find(a=> a.name == a.webkitRelativePath)
      errorName = errorName ? errorName.name : null
      if(errorName) {
        errorName = this.folders.find(a=> a.name == errorName)
        if(errorName) {
          e.target.value = ''
          this.addTip('Unable to upload. An object with the same name already exists')
          return null
        }
      }

      console.log('filteredFiles', filteredFiles)
      let formatUploadFolder = new FormData();

      let filesPaths = []
      for(let i=0; i<filteredFiles.length; i++) {
        let file = filteredFiles[i]
        filesPaths.push(''+file.webkitRelativePath)
        formatUploadFolder.append('files', file)
        formatUploadFolder.append('webkit_paths', ''+file.webkitRelativePath)
      }
      let path = this.$route.params.path ? this.$route.params.path : null
      let parent_id = null
      if(path) {
        path = JSON.parse(path)
        parent_id = path[path.length -1].id
        formatUploadFolder.append("parent_file", parent_id)
      } else {
          formatUploadFolder.append('destination', 'vessels')
          let id = JSON.parse(this.$route.params.charterersDetail).id
          formatUploadFolder.append('destination_object_id', id)
      }


      await this.axiosUploadFolderInFolder(formatUploadFolder)
      this.createEmitGetActivityFolders()
      await this.loadData()
      e.target.value = ''
    },

    async uploadFile(e, type) {
      // this.showLoad()
      let files = e.target.files
      let filesForm = []
      for(let i=0; i<files.length; i++) {
        let file = files[i]
        let formData = new FormData();
        formData.append("file", file);
        formData.append("file_type", 'FILE');
        formData.append("type", type ? (type == 'SOA' ? 'owners' : 'invoices') : 'vessels');
        formData.append("name", file.name);
        let path = this.$route.params.path ? this.$route.params.path : null
        let parent_id = null
        if(path) {
          path = JSON.parse(path)
          parent_id = path[path.length -1].id
          formData.append("parent_file", parent_id);
        } else {
            formData.append('destination', type ? (type == 'SOA' ? 'owners' : 'invoices') : 'vessels')
            let id = JSON.parse(this.$route.params.charterersDetail).id
            formData.append('destination_object_id', type ? this.getIdByType(type) : id)
        }
        formData.append("is_important", false);
        formData.append("is_archive", false);

        // formData.append("shared_with", );
        filesForm.push(formData)
        await this.axiosCreateFileInFolder({data: formData})
      }
      this.createEmitGetActivityFolders()
      await this.loadData()

      e.target.value = ''
    },

  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>