<template>
    <div>
        <b-dropdown right ref="dropdown">
            <template #button-content>
                <button class="button-drop fz13 fw500">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.44 10.5601C7.23529 10.5601 7.88 11.2048 7.88 12.0001C7.88 12.7954 7.23529 13.4401 6.44 13.4401C5.64471 13.4401 5 12.7954 5 12.0001C5 11.2048 5.64471 10.5601 6.44 10.5601Z" fill="#A3A3A3"/>
                        <circle cx="12.0001" cy="12" r="1.44" transform="rotate(-90 12.0001 12)" fill="#A3A3A3"/>
                        <path d="M17.5601 10.5601C18.3554 10.5601 19.0001 11.2048 19.0001 12.0001C19.0001 12.7954 18.3554 13.4401 17.5601 13.4401C16.7648 13.4401 16.1201 12.7954 16.1201 12.0001C16.1201 11.2048 16.7648 10.5601 17.5601 10.5601Z" fill="#A3A3A3"/>
                    </svg>
                </button>
            </template>
            <div class="content">
                <router-link :to="/employee-card/ + id" class="fz13 text-dark1" v-on:click="clickEditProfile">
                    Edit profile
                </router-link>
                <router-link :to="'employee-card/change-rights/' + id" class="fz13 text-dark1" v-on:click="clickRights">
                    Edit permissions
                </router-link>
                <button class="fz13 text-dark1" v-on:click="clickDeleteProfile" v-if="getPermissionsByType('can_archive_user')">
                    Archive profile
                </button>
            </div>
        </b-dropdown>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: "button-card",
      props: ["id"],
      computed: mapGetters(["getPermissionsByType"]),
        data() {
            return {

            }
        },
        methods: {
            clickEditProfile() {
                this.$refs.dropdown.hide(true);

            },
            clickRights() {
                this.$refs.dropdown.hide(true);

            },
            clickDeleteProfile() {
              if(this.getPermissionsByType('can_archive_user')) {
                this.$refs.dropdown.hide(true);
                this.$emit("archiveUser")
              }

            }
        },
        mounted() {

        }
    }
</script>
<style scoped lang="scss">
    @import "../style-element/dropdown";
    @import "../style-element/checkbox";
    .button-drop {
        transition: .1s linear;
        svg {
            path, circle {
                transition: .1s linear
            }
        }
        &:hover {
            svg {
                path, circle {
                    fill: var(--color-dark);
                }
            }
            transform: scale(1.2);
        }
    }
    /deep/ {

        .custom-control {
            min-height: 1px !important;
            padding: 0 8px 0 12px;
            width: 100%;
            /*height: 100%;*/
            /*box-sizing: border-box;*/
            /*align-items: center;*/
            .custom-control-label {
                width: 100%;

                font-size: 13px;
                line-height: 16px;
                color: var(--color-dark);
            }
        }
        .custom-control-label::before {
            height:16px;
            top: 0;
        }
        .custom-control-label::after {
            left: auto !important;
            right: 14px;
        }
        .custom-switch .custom-control-label::after {
            top: 2px!important;
        }
        .custom-switch .custom-control-label::before {
            left:auto;
            right: 0;
        }
        .custom-switch {
            top: 0 !important;
        }
    }
    .active-button {
        background-image: url("../../assets/ic_chsdeck.svg");
        background-position-x: calc(100% - 4px);
        background-position-y: center;
        background-repeat: no-repeat;
    }
    .svg-1 {
        margin-right: 8px;
    }
    .arrow {
        margin-left: 4px;
    }
    /deep/ .show {
        .arrow {
            transform: rotateX(180deg);
        }
    }
    /deep/ .dropdown-menu {
        top: -29px !important;
        border-radius: 4px;
        border: none;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
        padding: 4px 0 !important;
    }
    .content {
        width: 160px;
        background-color: var(--color-modal);
        border-radius: 4px;
        .title {
            width: 100%;
            box-sizing: border-box;
            margin: 0;
            padding: 4px 12px 5px 12px;
            display: flex;
            align-items: center;
            svg {
                position: relative;
                top: 2px;
                margin-left: 4px;
            }
            transition: .1s linear;
            &:hover {
                color: var(--color-dark) !important;
            }
        }
        &>*{
            height: 28px;
          text-decoration: none !important;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 12px;
            box-sizing: border-box;
            transition: background-color .1s linear;
            &:hover {
                background-color: var(--color-hover-list);
            }
        }
    }
    .bottom-button {
        margin-top: 4px;
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        border-top: 1px solid var(--color-card-border);
    }

</style>