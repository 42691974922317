<template>
  <div class="payment-accounts_item__btns">
    <button class="btn button-blue-border fw500 fz13" @click="$emit('on-edit')">
      <svg style="transform: translateY(-1px)" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9815 1.02666C12.3235 0.365661 11.4509 0 10.5175 0C9.58888 0 8.71156 0.365661 8.05357 1.02666L0.846338 8.276C0.743673 8.37914 0.683007 8.5104 0.659674 8.65104L0.00634799 13.2499C-0.0216517 13.4515 0.043681 13.6578 0.188346 13.8031C0.309678 13.9297 0.482343 14 0.655007 14C0.683007 14 0.70634 14 0.73434 13.9953L5.38695 13.414C5.53162 13.3953 5.66695 13.3296 5.76962 13.2265L12.9815 5.97246C14.3395 4.60357 14.3395 2.39086 12.9815 1.02666ZM5.00429 12.1342L1.42033 12.5795L1.92433 9.04952L7.80157 3.13624L10.8769 6.22561L5.00429 12.1342ZM12.0622 5.04893L11.8055 5.30677L8.73023 2.2174L8.98689 1.95957C9.39289 1.53765 9.93888 1.31263 10.5222 1.31263C11.1009 1.31263 11.6515 1.53765 12.0622 1.95488C12.4728 2.37211 12.7015 2.91591 12.7015 3.5019C12.7015 4.08321 12.4775 4.63639 12.0622 5.04893Z" fill="#094172"/></svg>
        Edit
    </button>
    <button 
      class="btn button-blue-border fw500 fz13" 
      v-if="getPermissionsByType('can_archive_client_payments')"
      @click="$emit('on-archive')"
    >
      <svg style="transform: translateY(-2px)" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.63636 1.36585C6.50732 1.36585 6.40909 1.46907 6.40909 1.58852V2.26399H9.59091V1.58852C9.59091 1.46907 9.49268 1.36585 9.36364 1.36585L6.63636 1.36585ZM10.9545 2.26399V1.58852C10.9545 0.707685 10.2387 2.90754e-07 9.36364 2.32603e-07L6.63636 0C5.76125 -5.81509e-08 5.04545 0.707684 5.04545 1.58852V2.26399H1.18182C0.80526 2.26399 0.5 2.56975 0.5 2.94692C0.5 3.32409 0.80526 3.62984 1.18182 3.62984H2.31818V13.5059C2.31818 14.8869 3.441 16 4.81818 16H11.1818C12.559 16 13.6818 14.8869 13.6818 13.5059V3.62984H14.8182C15.1947 3.62984 15.5 3.32409 15.5 2.94692C15.5 2.56975 15.1947 2.26399 14.8182 2.26399H10.9545ZM3.68182 3.62984V13.5059C3.68182 14.1255 4.18706 14.6341 4.81818 14.6341H11.1818C11.8129 14.6341 12.3182 14.1255 12.3182 13.5059V3.62984H3.68182ZM6.63636 5.88637C7.01292 5.88637 7.31818 6.19213 7.31818 6.5693V11.5501C7.31818 11.9272 7.01292 12.233 6.63636 12.233C6.25981 12.233 5.95455 11.9272 5.95455 11.5501V6.5693C5.95455 6.19213 6.25981 5.88637 6.63636 5.88637ZM9.36364 5.88637C9.74019 5.88637 10.0455 6.19213 10.0455 6.5693V11.5501C10.0455 11.9272 9.74019 12.233 9.36364 12.233C8.98708 12.233 8.68182 11.9272 8.68182 11.5501V6.5693C8.68182 6.19213 8.98708 5.88637 9.36364 5.88637Z" fill="#094172"/></svg>
        Archive
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getPermissionsByType'])
  }
}
</script>

<style lang="scss">
  .payment-accounts_item__btns{
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    .btn{
      width: 48%;
      svg{
        margin-right: 8px;
      }
    }
  }
</style>