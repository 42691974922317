<template>
  <div>
    <folders-list-file-and-folder></folders-list-file-and-folder>
  </div>
</template>

<script>
import FoldersListFileAndFolder from "@/components/folders/folders-list-file-and-folder";
import {mapMutations} from "vuex";
export default {
  name: "folders-ports-countries-options-details",
  components: {FoldersListFileAndFolder},
  methods: {
    ...mapMutations(['setNameSectionFolders']),
  },
  mounted() {
    this.setNameSectionFolders('Ports')
  }
}
</script>

<style scoped>

</style>