<template>
  <div class="page_navigation">
    <router-link
      v-for="link in links"
      :to="link.link" 
      :key="link.link"
      :class="{'router-link-active': $route.path.indexOf(link.link) >=0}"
      class="page_navigation__link fw500 text-gray fz14"
    >{{link.name}}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    links: Array
  }
}
</script>

<style lang="scss">
.page_navigation{
  &__link{
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 16px 2px 15px;
    color: var(--color-gray-text);
    border-bottom: 2px solid transparent;
    transition: border-color .3s, color .3s;
    &:not(:first-of-type){
      margin-left: 26px;
    }
    &:hover, &.router-link-active{
      color: var(--color-blue-dark);
    }
    &.router-link-active{
      border-bottom: 2px solid var(--color-blue-dark);
    }
  }
}
</style>