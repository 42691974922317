<template>
  <div class="">
    <div class="top-panel bgc-white position-relative">
      <button class="blue-button w160 h32" v-on:click="showCreateMailing = true">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6997 7.99688C21.6997 6.50571 20.4909 5.29688 18.9997 5.29688H6.57715C5.31935 5.29688 4.29971 6.31652 4.29971 7.57431C4.29971 8.39709 4.7435 9.1559 5.46061 9.55927L11.676 13.0554C12.4979 13.5178 13.5015 13.5178 14.3234 13.0554L20.2997 9.69377V15.9969C20.2997 16.7148 19.7177 17.2969 18.9997 17.2969H12.9997C12.6131 17.2969 12.2997 17.6103 12.2997 17.9969C12.2997 18.3835 12.6131 18.6969 12.9997 18.6969H18.9997C20.4909 18.6969 21.6997 17.488 21.6997 15.9969V7.99688ZM20.2997 8.08748L13.637 11.8352C13.2413 12.0578 12.7581 12.0578 12.3624 11.8352L6.14697 8.33907C5.87069 8.18366 5.69971 7.89131 5.69971 7.57431C5.69971 7.08972 6.09255 6.69687 6.57715 6.69687H18.9997C19.7177 6.69687 20.2997 7.2789 20.2997 7.99688V8.08748ZM5.99971 12.2969C5.61311 12.2969 5.29971 12.6103 5.29971 12.9969V15.7969H2.49971C2.11311 15.7969 1.79971 16.1103 1.79971 16.4969C1.79971 16.8835 2.11311 17.1969 2.49971 17.1969H5.29971V19.9969C5.29971 20.3835 5.61311 20.6969 5.99971 20.6969C6.38631 20.6969 6.69971 20.3835 6.69971 19.9969V17.1969H9.49971C9.88631 17.1969 10.1997 16.8835 10.1997 16.4969C10.1997 16.1103 9.88631 15.7969 9.49971 15.7969H6.69971V12.9969C6.69971 12.6103 6.38631 12.2969 5.99971 12.2969Z" fill="white"/>
        </svg>
        Create Mailing
      </button>
      <router-link :class="{'active': $route.path.indexOf('sent') >= 0}" class="fz16 fw500 text-gray mr30" :to="{name: 'Mailling Sent'}">Sent Maillings</router-link>
      <router-link :class="{'active': $route.path.indexOf('templates') >= 0}" class="fz16 fw500 text-gray mr30" :to="{name: 'Mailling Templates'}">Mailling Template</router-link>
      <router-link :class="{'active': $route.path.indexOf('contacts') >= 0}" class="fz16 fw500 text-gray" :to="{name: 'Mailling Contacts List'}">Contact Lists</router-link>

    </div>
    <create-edit-template-mailing :showStatus="showCreateMailing" v-show="showCreateMailing" @close="showCreateMailing = false" :type-page="'mailing'"></create-edit-template-mailing>
    <div class="page">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CreateEditTemplateMailing from "@/views/mailling/create-edit-template-mailing/create-edit-template-mailing";
export default {
  name: "mailling-view",
  components: {CreateEditTemplateMailing},
  data() {
    return {
      showCreateMailing: false,
    }
  }
}
</script>

<style scoped lang="scss">
  .top-panel {
    padding: 0px 24px;
    box-sizing: border-box;
    height: 48px;
    width: 100vw;
    background-color: var(--color-white);
    display: flex;
    align-items: stretch;
    justify-content: center;
    border-bottom: 1px solid var(--color-card-border);
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .page {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    height: calc(100vh - 56px - 48px);
    background-color: var(--color-bgc-page);
  }
  .active {
    position: relative;
    box-sizing: border-box;
    color: var(--color-blue-dark);
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: var(--color-blue-dark);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .blue-button {
    position: absolute;
    left: 27px;
    top: 8px;
  }
</style>