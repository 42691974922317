<template>
  <div>
    <div
      :key="'list'+tab.id"
      v-if="isShowFolder"
      class="box-border h28 flex items-center p-l-6 p-r-3 p-y-0.5 text-dark-gray fz0 hover:bg-gray-lightest svg-white-dart"
    >
      <svg 
        v-on:click="changeOpen()" 
        :class="{
          'opacity-0': tab.list_in_group.length == 0,
          'r180': isOpen && tab.list_in_group.length
        }" 
        class="svg-dart m-r-1 flex-shrink-0"
        width="12" 
        height="12" 
        viewBox="0 0 12 12" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"><path d="M2.72248 4.39844C2.4552 4.39844 2.32135 4.72158 2.51034 4.91057L5.78609 8.18631C5.90325 8.30346 6.0932 8.30346 6.21035 8.18631L9.48608 4.91057C9.67507 4.72158 9.54122 4.39844 9.27395 4.39844H2.72248Z" 
        fill="#2C2C2C"
      /></svg>

      <svg v-on:click="changeOpen()" class=" m-r-1 flex-shrink-0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.7048 7.02591C15.4769 6.64984 15.0742 6.42505 14.6276 6.42505H4.68261C4.18964 6.42505 3.74129 6.70944 3.54006 7.14978L0.909668 12.7939C1.00897 13.2072 1.38616 13.516 1.83543 13.516H12.4161C12.8685 13.516 13.2819 13.2542 13.484 12.8399L15.7575 8.17635C15.937 7.80731 15.9171 7.37727 15.7048 7.02591Z" fill="#9AA3B0"/><path d="M3.0265 6.48486C3.22716 6.0332 3.67485 5.7413 4.16708 5.7413H13.5157V4.48363C13.5157 3.96518 13.0999 3.54331 12.5888 3.54331H7.13772C7.12973 3.54331 7.12425 3.54115 7.12219 3.53959L6.14652 2.10473C5.97372 1.8502 5.68832 1.69824 5.38339 1.69824H1.8368C1.3255 1.69824 0.909668 2.12011 0.909668 2.63856V11.1528L3.0265 6.48486Z" fill="#BDCBD6"/></svg>

      <div 
        v-if="!isEdit" 
        v-on:click="changeOpen()" 
        class="fz14 whitespace-nowrap overflow-ellipsis overflow-hidden cursor-default" 
        :title="tab.name" 
        v-html="getTabName"
      ></div>

      <input 
        v-else
        type="text" 
        class="fz14 bg-transparent p-0 border-0" 
        ref="name" 
        :value="tab.name"
        @keyup.enter="onBlurName"
        @blur="onBlurName"
      >

      <svg @click="onCreate" v-if="$route.path.indexOf('archive') === -1 && getLoginData.data.user.id === activeCreator.id" class="svg-dart m-l-auto m-r-1 flex-shrink-0 cursor-pointer hover:fill-gray-text" width="24" height="24" viewBox="0 0 24 24" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M18.2221 11.2222H12.7778V5.77773C12.7778 5.34849 12.4293 5 11.9999 5C11.5707 5 11.2222 5.34849 11.2222 5.77773V11.2222H5.77773C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77773 12.7778H11.2222V18.2221C11.2222 18.6515 11.5707 19 11.9999 19C12.4293 19 12.7778 18.6515 12.7778 18.2221V12.7778H18.2221C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2221 11.2222Z"/></svg>

      <calc-options
          v-if="getOptionPerm().length !== 0"
          :class="{'m-l-auto' : $route.path.indexOf('archive') !== -1 || getLoginData.data.user.id !== activeCreator.id}"
        :options="getOptionPerm()"
        @rename="onRename"
        @archive="onArchive"
      >
        <svg v-if="$route.path.indexOf('archive') === -1" class="svg-dart hover:fill-gray-text" width="24" height="24" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M6.44 10.56a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88ZM12 10.56a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88ZM17.56 10.56a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88Z"/></svg>
        <svg v-on:click="onArchive" v-else class="svg-dart hover:fill-gray-text" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/><path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#2C2C2C"/></svg>
      </calc-options>
    </div>
    <div v-if="isOpen && filteredList.length" class="content-folder">
      <calc-list-item 
        class="p-l-10"
        v-for="list in filteredList"
        :key="list.id" 
        :tab="list"
        :searchText="searchText"
        :ref="'list'+list.id"
        @on-archive="onArchiveFile"
        @move-to="onMoveFile"
      />
    </div>
  </div>
</template>

<script>
import calcOptions from '@/components/calc/calcOptions'
import calcListItem from '@/components/calc/calcListItem'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

export default {
  props: {
    tab: Object,
    searchText: String
  },
  components: {
    calcOptions,
    calcListItem
  },
  computed: {
    ...mapState({
      activeCreator: state => state.tabs.activeCreator,
    }),
    ...mapGetters(['getLoginData', 'getPermissionsByType']),
    filteredList() {
      let filtered = this.tab.list_in_group
      if(this.searchText){
        filtered = filtered.filter(item=>{
          let name = item.name.toLowerCase()
          let search = this.searchText.toLowerCase()
          if(this.tab.name.indexOf(search) >= 0) return true
          return name.indexOf(search) >= 0
        })
      }
      return filtered
    },
    isShowFolder() {
      return this.tab.tab_type == 'group' && (!this.searchText || this.filteredList.length)
    },
    getTabName() {
      let before = '<span class="text-gray">'
      let after = '</span>'
      let name = this.tab.name
      if(this.searchText) {
        let search = this.searchText.toLowerCase()
        let searchIndex = name.indexOf(search)
        if(searchIndex >= 0){
          let firstPart = name.slice(0, searchIndex)
          let searchPart = name.substr(searchIndex, search.length)
          let lastPart = name.slice(searchIndex+search.length)
          name = firstPart + before + searchPart + after + lastPart
        }
      }
      return name
    }
  },
  data() {
    return {
      isEdit: false,
      isOpen: true
    }
  },
  methods: {
    ...mapActions(['axiosPatchTab', 'axiosPostTab', 'axiosDeleteTab']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    onMoveFile(data) {
      this.$emit('move-to', {file: data.file, folder: this.tab})
    },
    getOptionPerm() {
      let option = ['rename', 'archive'];
      if(this.activeCreator.id !== this.getLoginData.data.user.id) {
        option = option.filter(a=> a !== 'move-to' && a !== 'rename')
      }
      if(this.$route.path.indexOf('archive')>=0) {
        option = option.filter(a=> a !== 'duplicate')
      }
      if(!this.getPermissionsByType('archive_and_unarchive_all_users_lists_and_folders') && this.activeCreator.id !== this.getLoginData.data.user.id ) {
        option = option.filter(a=> a !== 'archive')
      }
      return option;
    },
    async onRename() {
      this.isEdit = true
      await this.$nextTick()
      this.$refs.name.focus()
      this.$refs.name.select()
    },
    changeOpen() {
      if(!this.isEdit) {
        this.isOpen = !this.isOpen
      }
    },
    onBlurName(e) {
      if(e.target.value && this.tab.name != e.target.value){
        let name = e.target.value
        if(name.length > 50){
          name = name.slice(0, 50)
          this.addTip(`The maximum number of characters is 50.`)
        }
        this.tab.name = name
        this.axiosPatchTab({
          id: this.tab.id,
          name: name
        })
      }
      this.isEdit = false
    },

    async onCreate() {
      this.isOpen = true
      
      let newTab = {
        name: 'New file',
        tab_type: 'list',
        addcom: 3.75,
        weather_factor: 10,
        parent: this.tab.id
      }
      this.showLoad()
      let newTabId = await this.axiosPostTab(newTab)
      let groupList = this.tab.list_in_group.map(el=>el.id)
      groupList.push(newTabId)

      this.axiosPatchTab({
        id: this.tab.id,
        list_in_group: groupList
      })
      this.hideLoad()

      this.$refs['list'+newTabId][0].onRename()
    },

    onArchive() {
      this.$emit('on-archive', this.tab)
    },
    onArchiveFile(tab) {
      this.$emit('on-archive-file', tab)
    }
  },
}
</script>

<style lang="scss" scoped>
  .content-folder {
    position: relative;
    //&::after {
    //  content: "";
    //  background-color: var(--color-card-border);
    //  height: 1px;
    //  width: calc(100% - 2.25rem);
    //  position: absolute;
    //  bottom: 0;
    //  right: 0.75rem;
    //}
  }
  .svg-dart {
    path, circle {
      fill: var(--color-dark) !important
    }
    &:hover {
      path, circle {
        fill: var(--color-gray) !important
      }
    }
  }
</style>