

<template>
  <div>
    <b-dropdown right ref="dropdown" v-if="getPermissionEditPayment() || getPermissionsCreatePayment() || getPermissionDelete()">
      <template #button-content>
        <button class="button-drop fz13 fw500">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.44 10.5601C7.23529 10.5601 7.88 11.2048 7.88 12.0001C7.88 12.7954 7.23529 13.4401 6.44 13.4401C5.64471 13.4401 5 12.7954 5 12.0001C5 11.2048 5.64471 10.5601 6.44 10.5601Z" fill="#A3A3A3"/>
            <circle cx="12.0001" cy="12" r="1.44" transform="rotate(-90 12.0001 12)" fill="#A3A3A3"/>
            <path d="M17.5601 10.5601C18.3554 10.5601 19.0001 11.2048 19.0001 12.0001C19.0001 12.7954 18.3554 13.4401 17.5601 13.4401C16.7648 13.4401 16.1201 12.7954 16.1201 12.0001C16.1201 11.2048 16.7648 10.5601 17.5601 10.5601Z" fill="#A3A3A3"/>
          </svg>
        </button>
      </template>
      <div class="content">
<!--        <button class="fz13 text-dark" v-on:click="clickEdit" v-if="getPermissionEditPayment()===true">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#2C2C2C"/>-->
<!--          </svg>-->

<!--          Edit-->
<!--        </button>-->
        <button class="fz13 text-dark" v-on:click="clickDuplicate" v-if="getPermissionsCreatePayment()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 10.1719C5 9.13178 5.85439 8.28861 6.90834 8.28861H13.9456C14.9996 8.28861 15.854 9.13178 15.854 10.1719V17.1167C15.854 18.1568 14.9996 19 13.9456 19H6.90834C5.85439 19 5 18.1568 5 17.1167V10.1719ZM6.90834 9.60405C6.59056 9.60405 6.33294 9.85828 6.33294 10.1719V17.1167C6.33294 17.4303 6.59056 17.6846 6.90834 17.6846H13.9456C14.2634 17.6846 14.521 17.4303 14.521 17.1167V10.1719C14.521 9.85828 14.2634 9.60405 13.9456 9.60405H6.90834Z" fill="#2C2C2C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.14605 6.88328C8.14605 5.84317 9.00044 5 10.0544 5H17.0917C18.1456 5 19 5.84317 19 6.88328V13.8281C19 14.8682 18.1456 15.7114 17.0917 15.7114C16.7236 15.7114 16.4252 15.4169 16.4252 15.0537C16.4252 14.6904 16.7236 14.396 17.0917 14.396C17.4094 14.396 17.6671 14.1417 17.6671 13.8281V6.88328C17.6671 6.56967 17.4094 6.31543 17.0917 6.31543H10.0544C9.73661 6.31543 9.47899 6.56967 9.47899 6.88328C9.47899 7.24652 9.1806 7.54099 8.81252 7.54099C8.44444 7.54099 8.14605 7.24652 8.14605 6.88328Z" fill="#2C2C2C"/>
          </svg>
          Duplicate
        </button>
        <button class="fz13 text-dark" v-on:click="changeEmitArchiveTask" v-if="!archivestatus && getPermissionDelete() === true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
          </svg>
          Archive
        </button>
        <button class="fz13 text-dark" v-on:click="changeEmitArchiveTask" v-if="archivestatus && getPermissionUnDelete() === true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/>
            <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#2C2C2C"/>
          </svg>
          Unarchive
        </button>

        <button class="fz13 text-dark" v-on:click="emitChangeStatus" v-if="status.name !== 'off' && $route.path.indexOf('accounting-invoice') === -1 && getPermissionEditPayment() === true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/>
            <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#2C2C2C"/>
          </svg>
          To “{{status.name}}”
        </button>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  computed: mapGetters(["getPermissionsForUserCanArchiveTask","getPermissionsByType", "getInfoDetailPayment"]),
  name: "payment-detail-option-button",

  props: ["status", "archivestatus"],
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(['axiosCreatePayment']),
    ...mapMutations(["setDataPaymentModal", 'addTip']),
    getPermissionsCreatePayment() {
      return this.getPermissionsByType("create_payments_incomes");
    },
    getPermissionDelete(){
      if(this.$route.path.indexOf("vessel")>=0) {
        return this.getPermissionsByType("archive_the_payment_income_in_the_cashlow_tab_in_the_vessel");
      }
      return this.getPermissionsByType("archive_payments_incomes");
    },
    getPermissionUnDelete(){
      return this.getPermissionsByType("unarchive_payments_incomes");
    },
    getPermissionEditPayment(){
      return this.getPermissionsByType("edit_payments");
    },
    changeEmitArchiveTask() {
      this.$refs.dropdown.hide(true);
      this.$emit("changeEmitArchivePayment");
    },
    emitChangeStatus() {
      this.$refs.dropdown.hide(true);
      this.$emit("emitChangeStatus", this.status);
    },
    clickEditProfile() {
      this.$refs.dropdown.hide(true);

    },
    async clickDuplicate() {
      let payment_type = this.getInfoDetailPayment.payment_type
      let bank = this.getInfoDetailPayment.bank ? this.getInfoDetailPayment.bank.id : null;
      let cash = this.getInfoDetailPayment.cash ? this.getInfoDetailPayment.cash.id : null;
      let idReal = this.getInfoDetailPayment.real_account ? this.getInfoDetailPayment.real_account.id : null;
      let counterpatryID = this.getInfoDetailPayment.client ? this.getInfoDetailPayment.client.id : null;
      let vessel = this.getInfoDetailPayment.ship ? this.getInfoDetailPayment.ship.id : this.getInfoDetailPayment.ship;
      let settled_ship = this.getInfoDetailPayment.settled_ship ? this.getInfoDetailPayment.settled_ship.id : this.getInfoDetailPayment.settled_ship;
      let cp_date =  this.getInfoDetailPayment.cp_date;
      let payment_amount = this.getInfoDetailPayment.payment_amount;
      let type = this.getInfoDetailPayment.data_type;
      let ports = this.getInfoDetailPayment.ports ? this.getInfoDetailPayment.ports.id : null;
      let statement_member = this.getInfoDetailPayment.statement_member;
      let account = this.getInfoDetailPayment.account ? this.getInfoDetailPayment.account.id : null
      let saveObj = {
        data_type: type,
        ports: ports,
        payment_type: payment_type,
        currency:this.getInfoDetailPayment.currency,
        currency_amount: this.getInfoDetailPayment.currency_amount,
        payment_amount: payment_amount,
        execute_date: this.getInfoDetailPayment.execute_date,
        status: this.getInfoDetailPayment.status,
        is_archive: false,
        client: counterpatryID,
        real_account: idReal,
        comment: this.getInfoDetailPayment.comment,
        cash: cash,
        bank: bank,
        account: account,
        file: [],
        paid_date: this.getInfoDetailPayment.paid_date,
        fileWithID: Array.isArray(this.getInfoDetailPayment.files) === true ? this.getInfoDetailPayment.files.map(a=>{return a.id}) : [],
        receipt_files: Array.isArray(this.getInfoDetailPayment.receipt_files) === true ? this.getInfoDetailPayment.receipt_files.map(a=> {return a.id}) : [],
        ship: vessel,
        settled_ship: settled_ship,
        cp_date: cp_date,
        linked_payments: this.getInfoDetailPayment.linked_payments.map(el=>el.id),
        linked_owners: this.getInfoDetailPayment.linked_owners.map(el=>el.id),
        linked_invoices: this.getInfoDetailPayment.linked_invoices.map(el=>el.id),
        statement_member: statement_member,
        client_name: this.getInfoDetailPayment.client_name ? this.getInfoDetailPayment.client_name : '',
        account_name: this.getInfoDetailPayment.account_name ? this.getInfoDetailPayment.account_name : '',
        additionally: this.getInfoDetailPayment.additionally ? this.getInfoDetailPayment.additionally : '',
      }
      let res = await this.axiosCreatePayment(saveObj)
      this.addTip('Payment duplicated')
      this.$router.push({name: this.$route.name, params: {id: res.data.id}})
    },
    clickEdit() {
      this.setDataPaymentModal('edit');
      if(this.$route.path.indexOf('vessels')>=0) {
        if(this.$route.path.indexOf('cash-flow')>=0) {
          this.$router.push({name: "Vessels CashFlow"})
        }
        if(this.$route.path.indexOf('accounting-invoice')>=0) {
          this.$router.push({name: "Accounting Invoice"})
        }
      } else {
        if(this.$route.path.indexOf('cashflow')>=0) {
        this.$router.push({name: "CashFlow List"})
        } else {
        this.$router.push({name: "Payment List"})
        }
      }
    }
  },
  mounted() {

  }
}
</script>
<style scoped lang="scss">
@import "../style-element/dropdown";
@import "../style-element/checkbox";
.button-drop {
  transition: .1s linear;
  svg {
    path, circle {
      transition: .1s linear
    }
  }
  &:hover {
    svg {
      path, circle {
        fill: var(--color-dark);
      }
    }
    transform: scale(1.2);
  }
}
/deep/ {

  .custom-control {
    min-height: 1px !important;
    padding: 0 8px 0 12px;
    width: 100%;
    /*height: 100%;*/
    /*box-sizing: border-box;*/
    /*align-items: center;*/
    .custom-control-label {
      width: 100%;

      font-size: 13px;
      line-height: 16px;
      color: #2C2C2C;
    }
  }
  .custom-control-label::before {
    height:16px;
    top: 0;
  }
  .custom-control-label::after {
    left: auto !important;
    right: 14px;
  }
  .custom-switch .custom-control-label::after {
    top: 2px!important;
  }
  .custom-switch .custom-control-label::before {
    left:auto;
    right: 0;
  }
  .custom-switch {
    top: 0 !important;
  }
}
.active-button {
  background-image: url("../../assets/ic_chsdeck.svg");
  background-position-x: calc(100% - 4px);
  background-position-y: center;
  background-repeat: no-repeat;
}
.svg-1 {
  margin-right: 8px;
}
.arrow {
  margin-left: 4px;
}
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  top: -29px !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  padding: 0 !important;
}
.content {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  width: 140px;
  background-color: var(--color-modal);
  border-radius: 4px;
  .title {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 5px 12px;
    display: flex;
    align-items: center;
    //text-align: left;
    svg {
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    transition: .1s linear;
    &:hover {
      color: #2C2C2C !important;
    }
  }
  &>button{
    min-height: 28px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 12px;
    box-sizing: border-box;
    transition: background-color .1s linear;
    text-align: left;
    svg {
      margin-right: 4px;
      path {
        fill: var(--color-dark)
      }
    }
    &:hover {
      background-color: var(--color-card-border);
    }
  }
}
.bottom-button {
  margin-top: 4px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-card-border);
}

</style>