<template>
  <div class="flex flex-col min-h-full">
    <div class="vessels_detail_nav" v-if="getLinks">
      <div>
        <router-link
          class="page_navigation__link fw500"
          v-for="link in getLinks"
          :key="link.title"
          :to="link.link"
        >{{link.title}}</router-link>
      </div>
    </div>
    
    <router-view v-if="getLinks" class="flex flex-grow-1 bg-gray-lightest"></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract
    }),
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
    getLinks() {
      if(!this.getCharterer) return false
      if(this.getCharterer.contract_type == 'CARGO' || this.getCharterer.contract_type == 'VOYAGE_CHARTERER'){
        return [
          {
            title: 'Details',
            link: `/vessels/${this.contract.id}/open/charterers/${this.getCharterer.id}/details`
          },
          {
            title: 'Freight Invoice',
            link: `/vessels/${this.contract.id}/open/charterers/${this.getCharterer.id}/freight`
          },
          {
            title: 'VSOA',
            link: `/vessels/${this.contract.id}/open/charterers/${this.getCharterer.id}/vsoa`
          },
          {
            title: 'LayTime',
            link: `/vessels/${this.contract.id}/open/charterers/${this.getCharterer.id}/lay`
          },
          {
            title: 'Additional Freight Invoice',
            link: `/vessels/${this.contract.id}/open/charterers/${this.getCharterer.id}/additional_freight`
          },
          {
            title: 'Detention Invoice',
            link: `/vessels/${this.contract.id}/open/charterers/${this.getCharterer.id}/detention`
          },
        ]
      }else{
        return [
          {
            title: 'Details',
            link: `/vessels/${this.contract.id}/open/charterers/${this.getCharterer.id}/details`
          },
          {
            title: 'SOA Sublet',
            link: `/vessels/${this.contract.id}/open/charterers/${this.getCharterer.id}/soa`
          },
        ]
      }
    }
  },
}
</script>

<style>

</style>