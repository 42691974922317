<template>
  <div class="wrap-dropdown" v-click-outside="hideDropdown">
    <button v-on:click="isShow =!isShow" class="d-flex align-items-center">
      <img class="img-profile" v-if="activeUser && activeUser.avatar" :src="getUrlForAvatar(activeUser.avatar)" alt="">
      <svg v-else class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="80" cy="80" r="80" fill="white"/>
        <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
      </svg>
      <svg class="svg-dart ml4" :class="{'rotate-180': isShow}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/>
      </svg>
    </button>
    <div class="list" v-if="isShow">
      <div class="list-item position-relative d-flex align-items-center cursor-pointer" v-for="(user, i) in listUsers" :key="i" v-on:click="setUser(user)">
        <img class="img-profile" v-if="user.avatar" :src="getUrlForAvatar(user.avatar)" alt="">
        <svg v-else class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="80" cy="80" r="80" fill="white"/>
          <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
        </svg>
        <p class="ml4 fz13 text-dark text-dots-overflow">{{user.first_name}} {{user.last_name}}</p>
        <svg v-if="activeUser && user.id === activeUser.id" class="active" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "checklist-dropdown-select-user",
  props: {
    activeUser: Object,
  },
  computed: {
    ...mapGetters(['getLoginData', 'getPermissionsFullByType']),
    getPermissionSeeOtherUserCheckList() {
      return this.getPermissionsFullByType('show_private_checklists_of_the_users').has_permission;
    }
  },
  data() {
    return {
      listUsers: [],
      isShow: false,
    }
  },
  methods: {
    hideDropdown() {
      this.isShow = false
    },
    setUser(user) {
      this.$emit('on-change', user)
      this.hideDropdown()
    },
    getUrlForAvatar(link) {
      if(link.indexOf(this.getURL()) >= 0) {
        return link;
      }
      return this.getURL() + link;
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
  },
  mounted() {
    if(this.getPermissionSeeOtherUserCheckList) {
      this.listUsers = this.getPermissionsFullByType('show_private_checklists_of_the_users').users
      this.listUsers = this.listUsers.filter(a=> a.id !== this.getLoginData.data.user.id)
      this.listUsers = [{id: this.getLoginData.data.user.id ,first_name: this.getLoginData.data.user.first_name, last_name: this.getLoginData.data.user.last_name ,avatar: this.getLoginData.data.user.avatar}].concat(this.listUsers)
    } else {
      this.listUsers = [{id: this.getLoginData.data.user.id ,first_name: this.getLoginData.data.user.first_name, last_name: this.getLoginData.data.user.last_name ,avatar: this.getLoginData.data.user.avatar}]
    }
  }
}
</script>

<style scoped lang="scss">
  .wrap-dropdown {
   position: relative;
  }
  .img-profile {
    width: 24px;
    height: 24px;
    border-radius: 50px;
  }
  .list {
    max-height: 180px;
    overflow-y: auto;
    width: 220px;
    position: absolute;
    top: 30px;
    left: 0;
    background: var(--color-modal);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    &-item {
      box-sizing: border-box;
      width: 100%;
      height: 30px;
      padding: 4px 8px;
      padding-right: 30px;
      .active {
        position: absolute;
        right: 4px;
      }
      &:hover {
        background-color: var(--color-gray-lightest);
      }
    }
  }
  .text-dots-overflow {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: calc(100% - 28px);
    box-sizing: border-box !important;
    display: inline !important;
  }
</style>