import axios from 'axios'

export default {
    actions: {
        axiosPostChat(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/chat/room/", {
                "chat_type": data.type,
                "instance_id": data.id,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                ctx.commit("setChatResponse", response.data)
            })
        },
        getAxiosCommentForChat(ctx, data) {
          return axios.post(process.env.VUE_APP_URL + '/chat/messages/', {
              "instance_ids": data.instance_ids,
              "room_type": data.room_type,
          }, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(response => {
              ctx.commit("setComment", response.data);
          })
        },
    },
    mutations: {
        setChatResponse(state, data) {
            console.log("chat");
            console.log(data);
            state.chatResponse = data;
        },
        setComment(state, data) {
            state.commentData = data;
        }
    },
    state: {
        chatResponse: null,
        commentData: null,
    },
    getters: {
        getChatResponse(state) {
            return state.chatResponse;
        },
        getComment(state) {
            return state.commentData;
        }
    },
}
