<template>
  <div class="box-border pb50">
    <app-loader :is-local="true" v-if="load" class="loader-list"></app-loader>
    <location-list-title @select="selectAll" :selectedList="selectedList" :selected-all="selectedList.length >= locationListLocal.length"></location-list-title>
    <location-list-item :nwLoad="nwLoad" @select="select(item)" :selectedList="selectedList" v-for="(item, i) in locationListLocal" :item="item" :key="i"></location-list-item>
    <pagination-panel-default v-if="nextLinkLocation" @load-more="loadMore" :ifActive="loadNext"></pagination-panel-default>
    <div v-if="selectedList.length > 0" class="bottom-panel w100vw b0 l0 position-fixed h44 p-0-24 box-border d-flex align-items-center bgc-white">
      <p class="fz14 text-dark">Selected: {{selectedList.length}}</p>
      <button class="button-blue-border h32 w100 fz14 ml-auto" v-on:click="$emit('clickDelete')" v-if="getPermissionsByType('can_delete_location_ships')">
        Delete
      </button>
      <button :class="{'ml-auto': !getPermissionsByType('can_delete_location_ships')}" class="blue-button h32 w160 fz14 ml16" v-on:click="$emit('clickSend')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.6019 3.34307C20.5122 3.26456 20.403 3.21181 20.2857 3.19041C20.1684 3.16901 20.0476 3.17976 19.9359 3.22153L3.60174 9.28851C3.47527 9.33649 3.36659 9.42216 3.29041 9.53393C3.21424 9.6457 3.17423 9.77818 3.17583 9.91344C3.17742 10.0487 3.22053 10.1802 3.29932 10.2901C3.3781 10.4001 3.48877 10.4832 3.61634 10.5282L10.9375 13.0658L13.4752 20.387C13.5202 20.5146 13.6033 20.6252 13.7132 20.704C13.8232 20.7828 13.9547 20.8259 14.0899 20.8274C14.2252 20.829 14.3577 20.789 14.4694 20.7129C14.5812 20.6367 14.6669 20.528 14.7148 20.4015L20.7818 4.06737C20.8284 3.94258 20.8362 3.80661 20.8041 3.67733C20.772 3.54805 20.7015 3.43156 20.6019 3.34307ZM17.5636 5.50639L11.2827 11.7873L5.78937 9.8816L17.5636 5.50639ZM18.497 6.43978L14.1217 18.2139L12.2161 12.7207L18.497 6.43978Z" fill="white"/>
        </svg>
        Send Message
      </button>
      <button class="close ml20 w-max h-max d-flex align-items-center justify-center" v-on:click="selectedList = []">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#9AA3B0"/>
          <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#9AA3B0"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import LocationListTitle from "@/components/location/location-list/location-list-title";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import LocationListItem from "@/components/location/location-list/location-list-item";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import AppLoader from "@/components/loader";
export default {
  name: "location-list",
  components: {AppLoader, PaginationPanelDefault, LocationListItem, LocationListTitle},
  computed: {
    ...mapGetters(['getPermissionsByType']),
    ...mapState({
      locationList: state => state.location.locationList,
      nextLinkLocation: state => state.location.nextLinkLocation,
      load: state => state.location.loadLocation,
    })
  },
  data() {
    return {
      selectedList: [],
      loadNext: false,
      locationListLocal: [],
      nwLoad: 0,
    }
  },
  watch: {
    selectedList: function () {
      this.$emit('changeSelected', JSON.parse(JSON.stringify(this.selectedList)))
    },
    locationList: function () {
      this.nwLoad++;
      this.locationListLocal = this.selectedList.concat(this.locationList.filter(b=> (this.selectedList.map(a=> {return a.id})).indexOf(b.id) === -1))
    }
  },
  methods: {
    ...mapActions(['axiosGetNextLocation', 'axiosDeleteSelectedLocation']),
    ...mapMutations(['setLocation', 'showLoad', 'hideLoad']),
    setReads(ids) {
      this.locationListLocal.forEach(item=> {
        if(ids.indexOf(item.id)>=0) {
          item.is_unread_mail = false
        }
      })
    },
    async deleteSelected() {
      this.showLoad()
      let idForDelete = []
      for(let i=0; i<this.selectedList.length; i++) {
        let res = await this.axiosDeleteSelectedLocation(this.selectedList[i].id)
        if(res) {
          idForDelete.push(this.selectedList[i].id)
        }
      }
      this.locationListLocal = this.locationListLocal.filter(a=> idForDelete.indexOf(a.id) === -1)
      this.selectedList = this.selectedList.filter(a=> idForDelete.indexOf(a.id) === -1)
      this.hideLoad()
    },
    clearListSelected() {
      this.selectedList = []
    },
    async loadMore() {
      this.loadNext = true
      await this.axiosGetNextLocation()
      this.loadNext = false
    },
    select(item) {
      if(this.selectedList.find(a=> a.id === item.id)) {
        this.selectedList = this.selectedList.filter(a=> a.id !== item.id)
      } else {
        this.selectedList.push(item)
      }
    },
    selectAll() {
      let bool = this.selectedList.length !== this.locationListLocal.length
      this.selectedList = []
      if(bool) {
        this.selectedList = JSON.parse(JSON.stringify(this.locationListLocal))
      }
    }
  },
  mounted() {
    this.locationListLocal = this.selectedList.concat(this.locationList.filter(b=> (this.selectedList.map(a=> {return a.id})).indexOf(b.id) === -1))
  }
}
</script>

<style scoped lang="scss">
  .bottom-panel {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  }
  .close {
    path {
      fill: var(--color-dark)
    }
    opacity: 0.5;
    &:hover {
      opacity: 1
    }
  }
  .loader-list {
    background-color: transparent !important;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-bgc-page-white);
      opacity: 0.7;
    }
    /deep/ {
      .sk-fading-circle {
        position: relative;
        z-index: 10;
      }
    }
  }
</style>