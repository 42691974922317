export default {
  getters: {
    getDelta: () => season => {
      if(season == 'winter'){
        return -0.3014166667
      }else if(season == 'tropical'){
        return 0.3014166667
      }
      return 0
    },
    getTableDraft: () => (cargo_draft, vessel_draft, delta, dwa) => {
      let draft = Math.min((cargo_draft), vessel_draft + delta+dwa)
      return draft ? draft : 0
    },
    getFWA: () => draft => {
      let fwa = (draft/48)*1.1
      return fwa ? fwa : 0
    },
    getDWA: () => (water_destiny, fwa) => {
      // console.log('dwa = ((1.025-water_destiny)/0.025)*fwa : ', water_destiny, fwa)
      let dwa = ((1.025-water_destiny)/0.025)*fwa
      return dwa ? dwa : 0
    },
    getSeasonDraft: () => (vessel_draft, delta) => {
      let s_draft = vessel_draft + delta
      return s_draft ? s_draft : 0
    },
    getSeasonDwt: () => (delta, tpc, dw) => {
      let dwt = (delta * (tpc?tpc:0) * 100) + (dw?dw:0)
      return dwt ? dwt : 0
    },
    getDWCalc: () => (sdwt, s_draft, tpc, dwa, tdraft) => {
      // console.log('dw_calc = sdwt - (s_draft+dwa-tdraft)*tpc*100 : ', sdwt, s_draft, dwa, tdraft, tpc);
      let dw_calc = sdwt - (s_draft+dwa-tdraft)*tpc*100
      return dw_calc ? dw_calc : 0
    },
    getMinQtt: () => cargo => {
      let qtt = 0
      if(cargo.quantity_min) qtt = cargo.quantity_min
      else if(cargo.quantity_max) qtt = cargo.quantity_max
      else if(cargo.quantity_mt){
        if(cargo.quantity_percentage){
          qtt = cargo.quantity_mt - (cargo.quantity_mt*cargo.quantity_percentage/100)
        }else{
          qtt = cargo.quantity_mt
        }
      }
      return qtt ? qtt : 0
    },
    getMaxQtt: () => cargo => {
      let qtt = 0
      if(cargo.quantity_max) qtt = cargo.quantity_max
      else if(cargo.quantity_min) qtt = cargo.quantity_min
      else if(cargo.quantity_mt){
        if(cargo.quantity_percentage){
          qtt = cargo.quantity_mt + (cargo.quantity_mt*cargo.quantity_percentage/100)
        }else{
          qtt = cargo.quantity_mt
        }
      }
      return qtt ? qtt : 0
    },
    getTotalFinalLoad(state) {
      let amount = 0;
      state.tableAdditionalInfoBssLoadedCargoes.filter(a=> a.status === 'loading').forEach(item=> {
        amount+=(item.final_load ? item.final_load : 0)
      })
      return parseFloat((amount).toFixed(6));
    },
    getTotalFreightNet(state) {
      let amount = 0;
      state.tableAdditionalInfoBssLoadedCargoes.filter(a=> a.status === 'loading').forEach(item=> {
        amount+=(item.freight_net ? item.freight_net : 0)
      })
      return parseFloat((amount).toFixed(6));

    },
    getTotalLinerInCosts(state) {
      let amount = 0;
      state.tableAdditionalInfoBssLoadedCargoes.forEach(item=> {
        amount+=(item.liner_in_costs ? item.liner_in_costs : 0)
      })
      return parseFloat((amount).toFixed(6));

    },
    getTotalLinerOutCosts(state) {
      let amount = 0;
      state.tableAdditionalInfoBssLoadedCargoes.forEach(item=> {
        amount+=(item.liner_out_costs ? item.liner_out_costs : 0)
      })
      return parseFloat((amount).toFixed(6));

    },

    getTotalTimeNonEca(state) {
      let full = 0;
      let eco = 0;
      let slow = 0;
      let optimal = 0
      state.tableFullSpeed.forEach(item=> {
        full+=parseFloat(((item.full_time_non_eca ? item.full_time_non_eca : 0)).toFixed(6))
        eco+=parseFloat(((item.eco_time_non_eca ? item.eco_time_non_eca : 0)).toFixed(6))
        slow+=parseFloat(((item.slow_time_non_eca ? item.slow_time_non_eca : 0)).toFixed(6))
        slow+=parseFloat(((item.optimal_time_non_eca ? item.optimal_time_non_eca : 0)).toFixed(6))
      })
      return {full: full, eco: eco, slow: slow, optimal: optimal};
    },
    getTotalTimeEca(state) {
      let full = 0;
      let eco = 0;
      let slow = 0;
      let optimal = 0
      state.tableFullSpeed.forEach(item=> {
        full+=(item.full_time_eca ? item.full_time_eca : 0)
        eco+=(item.eco_time_eca ? item.eco_time_eca : 0)
        slow+=(item.slow_time_eca ? item.slow_time_eca : 0)
        slow+=(item.optimal_time_eca ? item.optimal_time_eca : 0)
      })
      return {full: full, eco: eco, slow: slow, optimal: optimal};
    },
    getTotalIfoEcaNonEca(state) {
      let full = 0;
      let eco = 0;
      let slow = 0;
      let optimal = 0
      state.tableFullSpeed.forEach(item=> {
        full+=parseFloat(((item.full_ifo_eca_non_eca ? item.full_ifo_eca_non_eca : 0)).toFixed(6))
        eco+=parseFloat(((item.eco_ifo_eca_non_eca ? item.eco_ifo_eca_non_eca : 0)).toFixed(6))
        slow+=parseFloat(((item.slow_ifo_eca_non_eca ? item.slow_ifo_eca_non_eca : 0)).toFixed(6))
        slow+=parseFloat(((item.optimal_ifo_eca_non_eca ? item.optimal_ifo_eca_non_eca : 0)).toFixed(6))
      })
      return {full: parseFloat((full).toFixed(6)), eco: parseFloat((eco).toFixed(6)), slow: parseFloat((slow).toFixed(6)), optimal: parseFloat((optimal).toFixed(6))};

    },
    getTotalMgoEcaNonEca(state) {
      let full = 0;
      let eco = 0;
      let slow = 0;
      let optimal = 0
      state.tableFullSpeed.forEach(item=> {
        full+=parseFloat(((item.full_mgo_eca_non_eca ? item.full_mgo_eca_non_eca : 0)).toFixed(6))
        eco+=parseFloat(((item.eco_mgo_eca_non_eca ? item.eco_mgo_eca_non_eca : 0)).toFixed(6))
        slow+=parseFloat(((item.slow_mgo_eca_non_eca ? item.slow_mgo_eca_non_eca : 0)).toFixed(6))
        slow+=parseFloat(((item.optimal_mgo_eca_non_eca ? item.optimal_mgo_eca_non_eca : 0)).toFixed(6))
      })
      return {full: parseFloat((full).toFixed(6)), eco: parseFloat((eco).toFixed(6)), slow: parseFloat((slow).toFixed(6)), optimal: parseFloat((optimal).toFixed(6))};
    },
    getTotalIfoCost(state) {
      let full = 0;
      let eco = 0;
      let slow = 0;
      let optimal = 0
      state.tableFullSpeed.forEach(item=> {
        full+=parseFloat(((item.full_ifo_cost ? item.full_ifo_cost : 0)).toFixed(6))
        eco+=parseFloat(((item.eco_ifo_cost ? item.eco_ifo_cost : 0)).toFixed(6))
        slow+=parseFloat(((item.optimal_ifo_cost ? item.optimal_ifo_cost : 0)).toFixed(6))
      })
      return {full: full, eco: eco, slow: slow, optimal: optimal};
    },
    getTotalMsgoCost(state) {
      let full = 0;
      let eco = 0;
      let slow = 0;
      let optimal = 0
      state.tableFullSpeed.forEach(item=> {
        full+=parseFloat(((item.full_msgo_cost ? item.full_msgo_cost : 0)).toFixed(6))
        eco+=parseFloat(((item.eco_msgo_cost ? item.eco_msgo_cost : 0)).toFixed(6))
        slow+=parseFloat(((item.slow_msgo_cost ? item.slow_msgo_cost : 0)).toFixed(6))
        slow+=parseFloat(((item.optimal_msgo_cost ? item.optimal_msgo_cost : 0)).toFixed(6))
      })
      return {full: full, eco: eco, slow: slow, optimal: optimal};
    },
    getTotalHireCost(state) {
      let full = 0;
      let eco = 0;
      let slow = 0;
      let optimal = 0
      state.tableFullSpeed.forEach(item=> {
        full+=parseFloat(((item.full_hire_cost ? item.full_hire_cost : 0)).toFixed(6))
        eco+=parseFloat(((item.eco_hire_cost ? item.eco_hire_cost : 0)).toFixed(6))
        slow+=parseFloat(((item.slow_hire_cost ? item.slow_hire_cost : 0)).toFixed(6))
        slow+=parseFloat(((item.optimal_hire_cost ? item.optimal_hire_cost : 0)).toFixed(6))
      })
      return {full: full, eco: eco, slow: slow, optimal: optimal};
    },
    getTotalBunkerPlusHire(state) {
      let full = 0;
      let eco = 0;
      let slow = 0;
      let optimal = 0
      state.tableFullSpeed.forEach(item=> {
        full+=parseFloat(((item.full_bunker_plus_hire ? item.full_bunker_plus_hire : 0)).toFixed(6))
        eco+=parseFloat(((item.eco_bunker_plus_hire ? item.eco_bunker_plus_hire : 0)).toFixed(6))
        slow+=parseFloat(((item.slow_bunker_plus_hire ? item.slow_bunker_plus_hire : 0)).toFixed(6))
        optimal+=parseFloat(((item.optimal_bunker_plus_hire ? item.optimal_bunker_plus_hire : 0)).toFixed(6))
      })
      return {full: full, eco: eco, slow: slow, optimal: optimal};
    }
  },
  actions: {
    async calcIntakeForVessel(ctx, payload) {
        console.log('calcIntakeForVessel', payload)
        ctx.state.noCalcIntake = true
        ctx.state.loadTable = []
        ctx.state.dischTable = []

        if(!(payload.vessel && payload.vessel.additional_info) || payload.vessel.additional_info.routes.length == 0) return false
        let routes = JSON.parse(JSON.stringify(payload.vessel.additional_info.routes.filter((el)=>el.route_id)))
        routes.forEach((el) => {
          if(Array.isArray(el.cargos) !== true) {
            el.cargos = []
          }
          if(el.status == 'loading'){
            el.cargos.forEach(cargo_id=>{
              console.log('cargos find', payload.cargos, cargo_id)
              let cargo = payload.cargos.find(item=> item.id==cargo_id)
              let dischEl = {...ctx.state.emptyDisch}
              let loadEl = {...ctx.state.emptyLoad}
              console.log('cargo', cargo)
              // if(!cargo) {
              //   return null
              // }
              let loadDelta = cargo ? ctx.getters.getDelta(cargo.loading_seasonal_mark) : 0
              let dischDelta = cargo ? ctx.getters.getDelta(cargo.discharging_seasonal_mark) : 0

              if(cargo) {
                loadEl.route_id = +cargo.loading_port_name?.replace('#','')
                loadEl.cargo_id = cargo.id
                loadEl.port = cargo.loading_port

                dischEl.route_id = +cargo.discharging_port_name?.replace('#','')
                dischEl.cargo_id = cargo.id
                dischEl.port = cargo.discharging_port

                loadEl.dwa = ctx.getters.getDWA(
                    ctx.getters.getNumbFromString(cargo.loading_water_density),
                    ctx.getters.getFWA(payload.vessel.draft)
                )
                dischEl.dwa = ctx.getters.getDWA(
                    ctx.getters.getNumbFromString(cargo.discharging_water_density),
                    ctx.getters.getFWA(payload.vessel.draft)
                )

                loadEl.draft = ctx.getters.getTableDraft(cargo.loading_draft, payload.vessel.draft, loadDelta, loadEl.dwa)
                dischEl.draft = ctx.getters.getTableDraft(cargo.discharging_draft, payload.vessel.draft, dischDelta, dischEl.dwa)

                loadEl.dw_calc = ctx.getters.getDWCalc(
                    ctx.getters.getSeasonDwt(
                        loadDelta,
                        payload.vessel.tpc,
                        payload.vessel.dwt
                    ),
                    ctx.getters.getSeasonDraft(
                        payload.vessel.draft,
                        loadDelta
                    ),
                    payload.vessel.tpc,
                    loadEl.dwa,
                    loadEl.draft
                )
                dischEl.dw_calc = ctx.getters.getDWCalc(
                    ctx.getters.getSeasonDwt(
                        dischDelta,
                        payload.vessel.tpc,
                        payload.vessel.dwt
                    ),
                    ctx.getters.getSeasonDraft(
                        payload.vessel.draft,
                        dischDelta
                    ),
                    payload.vessel.tpc,
                    dischEl.dwa,
                    dischEl.draft
                )

                let otherSumm = (payload.vessel.constants ? payload.vessel.constants : 0) + (payload.vessel.bob ? payload.vessel.bob : 0) + (payload.vessel.fresh_water ? payload.vessel.fresh_water : 0)
                otherSumm = otherSumm ? otherSumm : 0
                loadEl.dw_intake = loadEl.dw_calc - otherSumm
                dischEl.dw_intake = dischEl.dw_calc - otherSumm
                console.log('otherSumm', otherSumm)

                loadEl.cargo_min = ctx.getters.getMinQtt(cargo)
                loadEl.cargo_max = ctx.getters.getMaxQtt(cargo)

                ctx.state.loadTable.push(loadEl)
                ctx.state.dischTable.push(dischEl)
              }


            })

          }
        })

        ctx.state.loadTable.forEach((el, i) => {
          let cargo = payload.cargos.find(cargo => cargo.id==el.cargo_id)
          let prevIntakeSfSumm = 0
          let prevIntakeSumm = 0
          // let prevInTable = ctx.state.loadTable.slice(0, i)
          // prevInTable.forEach(item => {
          //   let cargoInPrev = payload.cargos.find(cargo => cargo.id==item.cargo_id)
          //   prevIntakeSfSumm += (item.intake * cargoInPrev.stowage_factor_m)
          //   prevIntakeSumm += item.intake
          // })
          el.sf_int = parseFloat(((payload.vessel.grain - prevIntakeSfSumm)/cargo.stowage_factor_m).toFixed(6))
          el.lim_max_qtt = parseFloat((Math.min(el.dw_intake, el.cargo_max, el.sf_int)).toFixed(6))
          // console.log('cackcIntake', el.lim_max_qtt, ctx.state.dischTable[i].dw_intake)

          if(i==0){
            el.intake = Math.min(el.lim_max_qtt, ctx.state.dischTable[i].dw_intake)
          }else{
            let arrOfDW = []
            let arrOfSummIntake = []
            let arrForIntake = []
            for(let j = i; j > 0; j--) {
              let intakeSumm = 0
              let prevInPrev = ctx.state.loadTable.slice(0, j)
              prevInPrev.forEach(row => {
                intakeSumm += row.intake
              })
              arrOfSummIntake.push(intakeSumm)
              arrOfDW.push(ctx.state.dischTable[j-1].dw_intake)
            }

            arrOfSummIntake.reverse()

            for(let j = 0; j < arrOfDW.length; j++) {
              arrForIntake.push(arrOfDW[j] - arrOfSummIntake[j])
            }

            // el.intake = Math.min((el.dw_intake - prevIntakeSumm), el.sf_int, el.lim_max_qtt, ...arrForIntake, ctx.state.dischTable[i].dw_intake)
            el.intake = Math.min((el.dw_intake - prevIntakeSumm), el.sf_int, el.lim_max_qtt, ctx.state.dischTable[i].dw_intake)

          }
        })
        let cargoControl = JSON.parse(JSON.stringify(payload.vessel.dwt));
        let routesCopy = JSON.parse(JSON.stringify(routes))
        routesCopy.forEach((el) => {
          if(el.cargos.length){
            let relativeItems = ctx.state.loadTable.filter(item=>el.cargos.indexOf(item.cargo_id)>=0)
            let intake = relativeItems.reduce((a, b) => a + +b.intake, 0);
            el.intake = intake ? +intake.toFixed(2) : 0
          }else{
            el.intake = 0
          }
        })
        routes.forEach((el1, index)=> {
          let el = JSON.parse(JSON.stringify(el1))
          if(el.status === 'loading' || el.status === 'discharging') {
            if(cargoControl < routesCopy[index].intake && el.status === 'loading') {
              el.intake = cargoControl ? +cargoControl.toFixed(0) : 0
              let relativeItems = ctx.state.loadTable.find(a=>el.cargos.indexOf(a.cargo_id)>=0)
              if(relativeItems) {
                relativeItems.intake = cargoControl
              }
              let dot = routes.find(a=> a.status === (el.status === 'loading' ? 'discharging' : 'loading') && a.cargos.length && el.cargos.length && (JSON.stringify(a.cargos) === JSON.stringify(el.cargos)))
              if(dot) {
                dot.intake = cargoControl ? +cargoControl.toFixed(0) : 0
              }
            } else {
              el.intake = routesCopy[index].intake ? routesCopy[index].intake.toFixed(0) : 0
            }
            cargoControl = (el.status === 'loading') ? (cargoControl - routesCopy[index].intake) : (cargoControl + routesCopy[index].intake)
            let otherSumm = (payload.vessel.constants ? payload.vessel.constants : 0) + (payload.vessel.bob ? payload.vessel.bob : 0) + (payload.vessel.fresh_water ? payload.vessel.fresh_water : 0)
            if(el.status === 'loading') {
              cargoControl = cargoControl - otherSumm
            }
            cargoControl = cargoControl>0 ? cargoControl : 0
            if(el.cargos.length && el.status === 'discharging') {
              let relativeItems = ctx.state.loadTable.filter(item=>el.cargos.indexOf(item.cargo_id)>=0)
              el.intake = relativeItems.reduce((a, b) => a + +b.intake, 0);
              el.intake = el.intake ? el.intake.toFixed(0) : 0
            }
          }
          el1.intake = el.intake
        })
        // console.log(routesCopy.map(a=> {return a.intake}))
        // routes = JSON.parse(JSON.stringify(routesCopy))
        // console.log(routes.map(a=> {return a.intake}))
      return await routes
    },
    async calcAdditionalyInfoForVessel(ctx, payload) {
      console.log('calcAdditionalyInfoForVessel start')
      ctx.state.tableAdditionalInfoBssLoadedCargoes = []
      if(!payload.vessel || !payload.vessel.additional_info) {
        return null;
      }
      let routes = payload.vessel.additional_info.routes.filter((el)=>el.route_id)
      routes.forEach((el)=> {
        if(el.status == 'loading' || el.status == 'discharging'){
          let array = el.status == 'loading' ? ctx.state.loadTable.filter(a=> a.route_id === el.route_id) : ctx.state.dischTable.filter(a=> a.route_id === el.route_id)
          array.forEach((loadTableItem)=> {
            let cargo = payload.cargos.find(item => item.id == loadTableItem.cargo_id)
            // if(!cargo) {
            //   return
            // }
            // console.log(cargo)
            // let loadTableItem = ctx.state.loadTable.find(a=> a.cargo_id === cargo_id)
            // let dischargingTableItem = ctx.state.dischTable.find(a=> a.cargo_id === cargo_id)
            let tableAdditionalData = {...ctx.state.emptyTableAdditionalInfoBssLoadedCargoes}
            tableAdditionalData.route_id = el.route_id
            tableAdditionalData.status = el.status
            tableAdditionalData.port_name = loadTableItem.port ? loadTableItem.port.name : null
            let final_load = 0;
            if(el.status == 'discharging') {
              let cargoLoading =  ctx.state.loadTable.find(a=> a.cargo_id === loadTableItem.cargo_id)
              final_load = cargoLoading && cargoLoading.intake ? parseFloat(cargoLoading.intake) : 0
            } else {
              final_load = loadTableItem.intake ? parseFloat(loadTableItem.intake) : 0
            }

            tableAdditionalData.final_load = parseFloat((final_load).toFixed(6))
            tableAdditionalData.freight_net = parseFloat((cargo ? (((cargo.freight ? parseFloat(cargo.freight) : 0) * tableAdditionalData.final_load) - ((cargo.freight ? parseFloat(cargo.freight) : 0) * tableAdditionalData.final_load * (parseFloat((cargo.cg_comm ? cargo.cg_comm : 0))/100))) : 0).toFixed(6))
            tableAdditionalData.liner_in_costs = parseFloat((cargo ? ((cargo.loading_liner ? parseFloat(cargo.loading_liner) : 0) * tableAdditionalData.final_load) : 0).toFixed(6))
            tableAdditionalData.liner_out_costs = parseFloat((cargo ? ((cargo.discharging_liner ? parseFloat(cargo.discharging_liner) : 0) * tableAdditionalData.final_load) : 0).toFixed(6))
            let cargoLoadingTerms = cargo ? cargo.loading_terms : 0
            if(cargoLoadingTerms){
              let index = cargoLoadingTerms.indexOf(' ')
              if(index > 0)
                cargoLoadingTerms = cargoLoadingTerms.slice(0, index)
            }
            let loading_terms = ctx.rootState.tabs.termsList.find(el=>el.name == cargoLoadingTerms)
            loading_terms = parseFloat((loading_terms ? parseFloat(loading_terms.coof) : 0).toFixed(6))
            tableAdditionalData.load_time = parseFloat((cargo ? (el.status == 'loading' ? ((cargo.loading_rate && cargo.loading_rate) !== 0 ? (cargo.loading_rate_type == 'RATE' ? (tableAdditionalData.final_load / parseFloat(cargo.loading_rate)) * loading_terms : parseFloat(cargo.loading_rate) * loading_terms) : 0) : 0) : 0).toFixed(6))
            // console.log('tableAdditionalData.load_time: '+tableAdditionalData.load_time, `\ncargo.loading_rate:${cargo.loading_rate}`, `\ntableAdditionalData.load_time:${tableAdditionalData.load_time}`, '\ntableAdditionalData.final_load: '+tableAdditionalData.final_load, '\nloading_terms: '+loading_terms, '\ncargo.loading_terms:'+cargo.loading_terms)
            // console.log('loading_terms: ', loading_terms)

            let cargoDischargingTerms = cargo ? cargo.discharging_terms : 0
            if(cargoDischargingTerms){
              let index = cargoDischargingTerms.indexOf(' ')
              if(index > 0)
                cargoDischargingTerms = cargoDischargingTerms.slice(0, index)
            }
            let discharging_terms = ctx.rootState.tabs.termsList.find(el=>el.name == cargoDischargingTerms)
            // console.log('discharging_terms: ', cargo.discharging_terms)
            discharging_terms = parseFloat((discharging_terms ? parseFloat(discharging_terms.coof) : 0).toFixed(6))
            tableAdditionalData.disch_time = parseFloat((el.status == 'discharging' ? ((cargo.discharging_rate && cargo.discharging_rate) !== 0 ? (cargo.discharging_rate_type == 'RATE' ? (tableAdditionalData.final_load / parseFloat(cargo.discharging_rate)) * discharging_terms : parseFloat(cargo.discharging_rate) * discharging_terms) : 0) : 0).toFixed(6))
            tableAdditionalData.loadtime_tt = parseFloat((el.status == 'loading' ? ( tableAdditionalData.load_time + ((cargo.loading_turn_time ? parseFloat(cargo.loading_turn_time) : 0))/24 ) : 0).toFixed(6))
            // console.log('tableAdditionalData.loadtime_tt', tableAdditionalData.loadtime_tt, 'tableAdditionalData.load_time: ' + tableAdditionalData.load_time, 'cargo.loading_turn_time: '+ cargo.loading_turn_time)
            tableAdditionalData.distime_tt = parseFloat((el.status == 'discharging' ? ( tableAdditionalData.disch_time + ((cargo.discharging_turn_time ? parseFloat(cargo.discharging_turn_time) : 0))/24) : 0).toFixed(6))
            // console.log('tableAdditionalData.loadtime_tt tableAdditionalData.distime_tt', tableAdditionalData.distime_tt)

            if(cargo.loading_wk_cranes) {
              tableAdditionalData.ifo_stay_loadport = parseFloat((el.status == 'loading' ? (tableAdditionalData.load_time*(payload.vessel?.ifo_wk ? parseFloat(payload.vessel.ifo_wk) : 0) + ((cargo.loading_turn_time ? parseFloat(cargo.loading_turn_time) : 0)/24) * (payload.vessel?.ifo_idle ? parseFloat(payload.vessel.ifo_idle) : 0)) : 0).toFixed(6))
            } else {
              tableAdditionalData.ifo_stay_loadport = parseFloat((el.status == 'loading' ? ( tableAdditionalData.load_time*(payload.vessel?.ifo_idle ? parseFloat(payload.vessel.ifo_idle) : 0) + ((cargo.loading_turn_time ? parseFloat(cargo.loading_turn_time) : 0)/24) * (payload.vessel?.ifo_idle ? parseFloat(payload.vessel.ifo_idle) : 0)) : 0).toFixed(6))
            }
            // console.log('tableAdditionalData.disch_time', tableAdditionalData.disch_time, 'payload.vessel.ifo_idle', payload.vessel.ifo_idle, 'payload.vessel.ifo_wk', payload.vessel.ifo_wk, 'cargo.discharging_turn_time', cargo.discharging_turn_time, )
            if(cargo.discharging_wk_cranes) {
              tableAdditionalData.ifo_stay_disport = parseFloat((el.status == 'discharging' ? ( tableAdditionalData.disch_time*(payload.vessel?.ifo_wk ? parseFloat(payload.vessel.ifo_wk) : 0) + ((cargo.discharging_turn_time ? parseFloat(cargo.discharging_turn_time) : 0)/24) * (payload.vessel?.ifo_idle ? parseFloat(payload.vessel.ifo_idle) : 0)) : 0).toFixed(6))
            } else {
              tableAdditionalData.ifo_stay_disport =  parseFloat((el.status == 'discharging' ? (tableAdditionalData.disch_time*(payload.vessel?.ifo_idle ? parseFloat(payload.vessel.ifo_idle) : 0) + ((cargo.discharging_turn_time ? parseFloat(cargo.discharging_turn_time) : 0)/24) * (payload.vessel?.ifo_idle ? parseFloat(payload.vessel.ifo_idle) : 0)) : 0).toFixed(6))
            }
            if(cargo.loading_wk_cranes) {
              // console.log('cargo.loading_wk_cranes', cargo.loading_wk_cranes)
              // console.log(tableAdditionalData.load_time, (payload.vessel?.mgo_wk ? parseFloat(payload.vessel.mgo_wk) : 0), (cargo.loading_turn_time ? parseFloat(cargo.loading_turn_time) : 0), (payload.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0))
              // console.log(tableAdditionalData.load_time*(payload.vessel?.mgo_wk ? parseFloat(payload.vessel.mgo_wk) : 0), ((cargo.loading_turn_time ? parseFloat(cargo.loading_turn_time) : 0)/24) * (payload.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0))
              tableAdditionalData.mgo_stay_loadport = parseFloat((el.status == 'loading' ? ( tableAdditionalData.load_time*(payload.vessel?.mgo_wk ? parseFloat(payload.vessel.mgo_wk) : 0) + ((cargo.loading_turn_time ? parseFloat(cargo.loading_turn_time) : 0)/24) * (payload.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0)) : 0).toFixed(6))
            } else {
              tableAdditionalData.mgo_stay_loadport = parseFloat((el.status == 'loading' ? ( tableAdditionalData.load_time*(payload.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0) + ((cargo.loading_turn_time ? parseFloat(cargo.loading_turn_time) : 0)/24) * (payload.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0)) : 0).toFixed(6))
            }
            if(cargo.discharging_wk_cranes) {
              tableAdditionalData.mgo_stay_disport =  parseFloat((el.status == 'discharging' ? ( tableAdditionalData.disch_time*(payload.vessel?.mgo_wk ? parseFloat(payload.vessel.mgo_wk) : 0) + ((cargo.discharging_turn_time ? parseFloat(cargo.discharging_turn_time) : 0)/24) * (payload.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0)) : 0).toFixed(6))
            } else {
              tableAdditionalData.mgo_stay_disport =  parseFloat((el.status == 'discharging' ? ( tableAdditionalData.disch_time*(payload.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0) + ((cargo.discharging_turn_time ? parseFloat(cargo.discharging_turn_time) : 0)/24) * (payload.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0)) : 0).toFixed(6))
            }
            // console.log('el.has_seca', el.has_seca, el.has_seca ? (parseFloat(tableAdditionalData.ifo_stay_loadport) + parseFloat(tableAdditionalData.mgo_stay_loadport)) : parseFloat(tableAdditionalData.mgo_stay_loadport))

            tableAdditionalData.ifo_load_eca_terms = parseFloat((el.status == 'loading' ? ( el.has_seca ? 0 : parseFloat(tableAdditionalData.ifo_stay_loadport)) : 0).toFixed(6))
            tableAdditionalData.ifo_disport_eca_terms = parseFloat((el.status == 'discharging' ? ( el.has_seca ? 0 : parseFloat(tableAdditionalData.ifo_stay_disport)) : 0).toFixed(6))
            tableAdditionalData.mgo_loadport_eca_terms =  parseFloat((el.status == 'loading' ? ( el.has_seca ? (parseFloat(tableAdditionalData.ifo_stay_loadport) + parseFloat(tableAdditionalData.mgo_stay_loadport)) : parseFloat(tableAdditionalData.mgo_stay_loadport)) : 0).toFixed(6))
            tableAdditionalData.mgo_disport_eca_terms = parseFloat((el.status == 'discharging' ? ( el.has_seca ? (parseFloat(tableAdditionalData.ifo_stay_disport) + parseFloat(tableAdditionalData.mgo_stay_disport)) : parseFloat(tableAdditionalData.mgo_stay_disport)) : 0).toFixed(6))
            ctx.state.tableAdditionalInfoBssLoadedCargoes.push(tableAdditionalData)
          })
        }
      })
      // console.log('payload.vessel.additional_info.routes', payload.vessel.additional_info.routes)
      let copyRoutesAll = JSON.parse(JSON.stringify(payload.vessel.additional_info.routes))
      let arrayLadBall = []
      let intake = 0;
      let ladBool = false;
      copyRoutesAll.forEach((item, index)=> {
        if(index === 0) {
          arrayLadBall.push('ball')
        } else {
          if(ladBool) {
            arrayLadBall.push('lad')
          } else {
            arrayLadBall.push('ball')
          }
          if(item.status === 'loading') {
            ladBool = true;
            intake += parseFloat(item.intake) ? parseFloat(item.intake) : 0
          }
          if(item.status === 'discharging') {
            intake -= parseFloat(item.intake) ? parseFloat(item.intake) : 0
            if(intake === 0) {
              ladBool = false
            }
          }
        }
      })
      function getLabOrBal(index) {
        // let index = copyRoutesAll.findIndex(a=> a.route_id === route_id)
        return arrayLadBall[index] ? arrayLadBall[index] : 'ball'
      }
      // console.log('arrayLadBall', arrayLadBall)
      let RouteAll = JSON.parse(JSON.stringify(payload.vessel.additional_info.routes));
      ctx.state.tableFullSpeed = [];
      RouteAll.forEach((el, index)=> {
        // console.log('ex', el.lay_ex)
        let prefixOptimal = el.is_eco ? 'eco' : el.is_full ? 'full' : el.is_slow ? 'slow' : 'optimal'
        let tableFullSpeed = {...ctx.state.tableFullSpeedEmpty}
        tableFullSpeed.route_id = el.route_id
        // console.log('prefixOptimal', prefixOptimal)
        tableFullSpeed.type_lad_ball = getLabOrBal(index)
        // console.log(index, 'el.has_seca: '+el.has_seca)
        tableFullSpeed.ifo_layExD = parseFloat(((el.lay_ex ? parseFloat(el.lay_ex) : 0) * (payload?.vessel?.ifo_idle ? parseFloat(payload.vessel.ifo_idle) : 0)).toFixed(6))
        tableFullSpeed.mgo_layExD = parseFloat(((el.lay_ex ? parseFloat(el.lay_ex) : 0) * (payload?.vessel?.mgo_idle ? parseFloat(payload.vessel.mgo_idle) : 0)).toFixed(6))
        tableFullSpeed.ifo_layExD_eca_non_eca = parseFloat((el.has_seca ? 0 : parseFloat(tableFullSpeed.ifo_layExD)).toFixed(6));
        tableFullSpeed.mgo_layExD_eca_non_eca = parseFloat((el.has_seca ? (parseFloat(tableFullSpeed.ifo_layExD) + parseFloat(tableFullSpeed.mgo_layExD)) : tableFullSpeed.mgo_layExD).toFixed(6));
        // status
        // console.log(`index: ${index} seca: ${el.has_seca} mgo_layExD_eca_non_eca: ${tableFullSpeed.mgo_layExD_eca_non_eca} tableFullSpeed.mgo_layExD: ${tableFullSpeed.mgo_layExD} el.lay_ex: ${el.lay_ex} payload.vessel.mgo_idle: ${payload.vessel.mgo_idle}`)

        tableFullSpeed.full_time_non_eca = parseFloat((((el.dist ? parseFloat(el.dist) : 0) / ((payload.vessel['full_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)).toFixed(6))
        tableFullSpeed.eco_time_non_eca = parseFloat((((el.dist ? parseFloat(el.dist) : 0) / ((payload.vessel['eco_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)).toFixed(6))
        tableFullSpeed.slow_time_non_eca = parseFloat((((el.dist ? parseFloat(el.dist) : 0) / ((payload.vessel['slow_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)).toFixed(6))
        tableFullSpeed.optimal_time_non_eca = parseFloat((((el.dist ? parseFloat(el.dist) : 0) / ((payload.vessel[prefixOptimal+'_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)).toFixed(6))
        console.log('Time NON_ECA full_time_non_eca ', getLabOrBal(index), '('+(el.dist ? parseFloat(el.dist) : 0)+'/('+(payload.vessel['full_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_sp_'+getLabOrBal(index)]) : 0)+'*24'+')'+')'+ '*(1+'+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0) + '/100) = ', tableFullSpeed.full_time_non_eca)
        // tableFullSpeed.time_non_eca = ((el.dist ? parseFloat(el.dist) : 0) * ((payload.vessel[payload.fuelType + '_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel[payload.fuelType + '_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)

        tableFullSpeed.full_time_eca = parseFloat((((el.seca ? el.seca : 0)/((payload.vessel['full_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)).toFixed(6))
        tableFullSpeed.eco_time_eca = parseFloat((((el.seca ? el.seca : 0)/((payload.vessel['eco_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)).toFixed(6))
        tableFullSpeed.slow_time_eca = parseFloat((((el.seca ? el.seca : 0)/((payload.vessel['slow_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)).toFixed(6))
        tableFullSpeed.optimal_time_eca = parseFloat((((el.seca ? el.seca : 0)/((payload.vessel[prefixOptimal+'_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)).toFixed(6))
        // console.log('index:'+index,'status:'+el.status, 'lad/ball:'+getLabOrBal(index), `calc: (${(el.seca ? el.seca : 0)} / (${(payload.vessel['full_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_sp_'+getLabOrBal(index)]) : 0)} * 24)) * (1+ ${(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)}/100) = ${tableFullSpeed.full_time_eca}`)
        // tableFullSpeed.time_eca = ((el.seca ? el.seca : 0)/((payload.vessel[payload.fuelType + '_sp_'+getLabOrBal(index)] ? parseFloat(payload.vessel[payload.fuelType + '_sp_'+getLabOrBal(index)]) : 0) * 24)) * (1+(payload.calculatorTab?.weather_factor ? parseFloat(payload.calculatorTab.weather_factor) : 0)/100)

        tableFullSpeed.full_total_time = parseFloat((parseFloat(tableFullSpeed.full_time_non_eca) + parseFloat(tableFullSpeed.full_time_eca)).toFixed(6));
        tableFullSpeed.eco_total_time = parseFloat((parseFloat(tableFullSpeed.eco_time_non_eca) + parseFloat(tableFullSpeed.eco_time_eca)).toFixed(6));
        tableFullSpeed.slow_total_time = parseFloat((parseFloat(tableFullSpeed.slow_time_non_eca) + parseFloat(tableFullSpeed.slow_time_eca)).toFixed(6));
        tableFullSpeed.optimal_total_time = parseFloat((parseFloat(tableFullSpeed.optimal_time_non_eca) + parseFloat(tableFullSpeed.optimal_time_eca)).toFixed(6));
        // tableFullSpeed.total_time = parseFloat(tableFullSpeed.time_non_eca) + parseFloat(tableFullSpeed.time_eca);

        tableFullSpeed.full_ifo_eca_non_eca =  parseFloat((payload.vessel.scrubber ? (parseFloat(tableFullSpeed.full_total_time) * (payload.vessel['full_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_ifo_'+getLabOrBal(index)]) : 0)) : ( parseFloat(tableFullSpeed.full_time_non_eca) * (payload.vessel['full_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_ifo_'+getLabOrBal(index)]) : 0))).toFixed(6))
        // console.log('tableFullSpeed.full_ifo_eca_non_eca ', payload.vessel.scrubber,  tableFullSpeed.full_total_time, tableFullSpeed.full_time_non_eca)
        // if(payload.vessel.scrubber) {
        //   console.log('full_ifo_eca_non_eca = ')
        // }
        // if(payload.vessel.scrubber) {
          // console.log(`index: ${index}, lad/ball: ${getLabOrBal(index)}, scrubber: ${payload.vessel.scrubber}, calc: ${parseFloat(tableFullSpeed.full_total_time)} * ${payload.vessel['full_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_ifo_'+getLabOrBal(index)]) : 0} = ${tableFullSpeed.full_ifo_eca_non_eca}`)
        // } else {
          // console.log(`index: ${index}, lad/ball: ${getLabOrBal(index)}, scrubber: ${payload.vessel.scrubber}, calc: ${parseFloat(tableFullSpeed.full_time_non_eca)} * ${(payload.vessel['full_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_ifo_'+getLabOrBal(index)]) : 0)} = ${tableFullSpeed.full_ifo_eca_non_eca}`)
        // }
        tableFullSpeed.eco_ifo_eca_non_eca =  parseFloat((payload.vessel.scrubber ? (parseFloat(tableFullSpeed.eco_total_time) * (payload.vessel['eco_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_ifo_'+getLabOrBal(index)]) : 0)) : ( parseFloat(tableFullSpeed.eco_time_non_eca) * (payload.vessel['eco_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_ifo_'+getLabOrBal(index)]) : 0))).toFixed(6))
        tableFullSpeed.slow_ifo_eca_non_eca =  parseFloat((payload.vessel.scrubber ? (parseFloat(tableFullSpeed.slow_total_time) * (payload.vessel['slow_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_ifo_'+getLabOrBal(index)]) : 0)) : ( parseFloat(tableFullSpeed.slow_time_non_eca) * (payload.vessel['slow_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_ifo_'+getLabOrBal(index)]) : 0))).toFixed(6))
        tableFullSpeed.optimal_ifo_eca_non_eca =  parseFloat((payload.vessel.scrubber ? (parseFloat(tableFullSpeed.optimal_total_time) * (payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)]) : 0)) : ( parseFloat(tableFullSpeed.optimal_time_non_eca) * (payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)]) : 0))).toFixed(6))


        // console.log('port'+(el.port ? el.port.name : ''),'full_ifo_eca_non_eca:' +tableFullSpeed.full_ifo_eca_non_eca, parseFloat(tableFullSpeed.full_time_non_eca), (payload.vessel['full_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_ifo_'+getLabOrBal(index)]) : 0), parseFloat(tableFullSpeed.full_time_non_eca) * (payload.vessel['full_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_ifo_'+getLabOrBal(index)]) : 0))
        // console.log('port'+(el.port ? el.port.name : ''),'eco_ifo_eca_non_eca:' +tableFullSpeed.eco_ifo_eca_non_eca, parseFloat(tableFullSpeed.eco_time_non_eca), (payload.vessel['eco_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_ifo_'+getLabOrBal(index)]) : 0), (parseFloat(tableFullSpeed.eco_time_non_eca) * (payload.vessel['eco_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_ifo_'+getLabOrBal(index)]) : 0)))
        // console.log('port'+(el.port ? el.port.name : ''),'slow_ifo_eca_non_eca:' +tableFullSpeed.slow_ifo_eca_non_eca, parseFloat(tableFullSpeed.slow_time_non_eca), (payload.vessel['slow_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_ifo_'+getLabOrBal(index)]) : 0), (parseFloat(tableFullSpeed.slow_time_non_eca) * (payload.vessel['slow_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_ifo_'+getLabOrBal(index)]) : 0)) )
        // console.log('port'+(el.port ? el.port.name : ''),'optimal_ifo_eca_non_eca:' +tableFullSpeed.optimal_ifo_eca_non_eca, parseFloat(tableFullSpeed.optimal_time_non_eca), (payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)]) : 0), (parseFloat(tableFullSpeed.optimal_time_non_eca) * (payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)]) : 0)))

        // tableFullSpeed.ifo_eca_non_eca = payload.vessel.scrubber ? (parseFloat(tableFullSpeed.total_time) * (payload.vessel[payload.fuelType + '_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[payload.fuelType + '_ifo_'+getLabOrBal(index)]) : 0)) : ( parseFloat(tableFullSpeed.time_non_eca) * (payload.vessel[payload.fuelType + '_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[payload.fuelType + '_ifo_'+getLabOrBal(index)]) : 0))

        tableFullSpeed.full_mgo_eca_non_eca = parseFloat((payload.vessel.scrubber ? ((payload.vessel['full_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.full_total_time) : ((payload.vessel['full_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_ifo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.full_time_eca + (payload.vessel['full_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['full_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.full_total_time)).toFixed(6))
        tableFullSpeed.eco_mgo_eca_non_eca = parseFloat((payload.vessel.scrubber ? ((payload.vessel['eco_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.eco_total_time) : ((payload.vessel['eco_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_ifo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.eco_time_eca + (payload.vessel['eco_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['eco_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.eco_total_time)).toFixed(6))
        tableFullSpeed.slow_mgo_eca_non_eca = parseFloat((payload.vessel.scrubber ? ((payload.vessel['slow_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.slow_total_time) : ((payload.vessel['slow_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_ifo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.slow_time_eca + (payload.vessel['slow_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel['slow_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.slow_total_time)).toFixed(6))
        tableFullSpeed.optimal_mgo_eca_non_eca = parseFloat((payload.vessel.scrubber ? ((payload.vessel[prefixOptimal+'_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.optimal_total_time) : ((payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_ifo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.optimal_time_eca + (payload.vessel[prefixOptimal+'_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[prefixOptimal+'_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.optimal_total_time)).toFixed(6))
        // tableFullSpeed.mgo_eca_non_eca = payload.vessel.scrubber ? ((payload.vessel[payload.fuelType + '_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[payload.fuelType + '_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.total_time) : ((payload.vessel[payload.fuelType + '_ifo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[payload.fuelType + '_ifo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.time_eca + (payload.vessel[payload.fuelType + '_mgo_'+getLabOrBal(index)] ? parseFloat(payload.vessel[payload.fuelType + '_mgo_'+getLabOrBal(index)]) : 0) * tableFullSpeed.total_time)
        // console.log('full_mgo_eca_non_eca', payload.vessel.scrubber, 'full_mgo_'+getLabOrBal(index)+': ',parseFloat(payload.vessel['full_mgo_'+getLabOrBal(index)]),  'tableFullSpeed.full_total_time: ', tableFullSpeed.full_total_time, '\'full_ifo_\'+getLabOrBal(index):', parseFloat(payload.vessel['full_ifo_'+getLabOrBal(index)]), 'tableFullSpeed.full_time_eca: ', tableFullSpeed.full_time_eca, 'full_mgo_'+getLabOrBal(index)+': ', payload.vessel['full_mgo_'+getLabOrBal(index)], )

        tableFullSpeed.full_ifo_cost = parseFloat((payload.vessel.scrubber ? (tableFullSpeed.full_ifo_eca_non_eca * (payload.vessel?.hsfo_price ? parseFloat(payload.vessel?.hsfo_price) : 0)) : (tableFullSpeed.full_ifo_eca_non_eca * (payload.vessel?.vlsfo_price ? parseFloat(payload.vessel?.vlsfo_price) : 0))).toFixed(6))
        tableFullSpeed.eco_ifo_cost = parseFloat((payload.vessel.scrubber ? (tableFullSpeed.eco_ifo_eca_non_eca * (payload.vessel?.hsfo_price ? parseFloat(payload.vessel?.hsfo_price) : 0)) : (tableFullSpeed.eco_ifo_eca_non_eca * (payload.vessel?.vlsfo_price ? parseFloat(payload.vessel?.vlsfo_price) : 0))).toFixed(6))
        tableFullSpeed.slow_ifo_cost = parseFloat((payload.vessel.scrubber ? (tableFullSpeed.slow_ifo_eca_non_eca * (payload.vessel?.hsfo_price ? parseFloat(payload.vessel?.hsfo_price) : 0)) : (tableFullSpeed.slow_ifo_eca_non_eca * (payload.vessel?.vlsfo_price ? parseFloat(payload.vessel?.vlsfo_price) : 0))).toFixed(6))
        tableFullSpeed.optimal_ifo_cost = parseFloat((payload.vessel.scrubber ? (tableFullSpeed.optimal_ifo_eca_non_eca * (payload.vessel?.hsfo_price ? parseFloat(payload.vessel?.hsfo_price) : 0)) : (tableFullSpeed.optimal_ifo_eca_non_eca * (payload.vessel?.vlsfo_price ? parseFloat(payload.vessel?.vlsfo_price) : 0))).toFixed(6))
        // tableFullSpeed.ifo_cost = payload.vessel.scrubber ? (tableFullSpeed.ifo_eca_non_eca * (payload.vessel?.hsfo_price ? parseFloat(payload.vessel?.hsfo_price) : 0)) : (tableFullSpeed.ifo_eca_non_eca * (payload.vessel?.vlsfo_price ? parseFloat(payload.vessel?.vlsfo_price) : 0))

        tableFullSpeed.full_msgo_cost = parseFloat((tableFullSpeed.full_mgo_eca_non_eca * (payload.vessel?.lsmgo_price ? parseFloat(payload.vessel?.lsmgo_price) : 0)).toFixed(6));
        tableFullSpeed.eco_msgo_cost = parseFloat((tableFullSpeed.eco_mgo_eca_non_eca * (payload.vessel?.lsmgo_price ? parseFloat(payload.vessel?.lsmgo_price) : 0)).toFixed(6));
        tableFullSpeed.slow_msgo_cost = parseFloat((tableFullSpeed.slow_mgo_eca_non_eca * (payload.vessel?.lsmgo_price ? parseFloat(payload.vessel?.lsmgo_price) : 0)).toFixed(6));
        tableFullSpeed.optimal_msgo_cost = parseFloat((tableFullSpeed.optimal_mgo_eca_non_eca * (payload.vessel?.lsmgo_price ? parseFloat(payload.vessel?.lsmgo_price) : 0)).toFixed(6));
        // tableFullSpeed.msgo_cost = tableFullSpeed.mgo_eca_non_eca * (payload.vessel?.lsmgo_price ? parseFloat(payload.vessel?.lsmgo_price) : 0);

        tableFullSpeed.full_hire_cost = parseFloat((tableFullSpeed.full_total_time * ((payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0) - (payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0)*((payload.vessel?.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100))).toFixed(6))
        tableFullSpeed.eco_hire_cost = parseFloat((tableFullSpeed.eco_total_time * ((payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0) - (payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0)*((payload.vessel?.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100))).toFixed(6))
        tableFullSpeed.slow_hire_cost = parseFloat((tableFullSpeed.slow_total_time * ((payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0) - (payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0)*((payload.vessel?.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100))).toFixed(6))
        tableFullSpeed.optimal_hire_cost = parseFloat((tableFullSpeed.optimal_total_time * ((payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0) - (payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0)*((payload.vessel?.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100))).toFixed(6))
        // tableFullSpeed.hire_cost = tableFullSpeed.total_time * ((payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0) - (payload.vessel?.hire ? parseFloat(payload.vessel.hire) : 0)*((payload.vessel?.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100))

        tableFullSpeed.full_bunker_plus_hire = parseFloat((tableFullSpeed.full_ifo_cost + tableFullSpeed.full_msgo_cost + tableFullSpeed.full_hire_cost).toFixed(6))
        tableFullSpeed.eco_bunker_plus_hire = parseFloat((tableFullSpeed.eco_ifo_cost + tableFullSpeed.eco_msgo_cost + tableFullSpeed.eco_hire_cost).toFixed(6))
        tableFullSpeed.slow_bunker_plus_hire = parseFloat((tableFullSpeed.slow_ifo_cost + tableFullSpeed.slow_msgo_cost + tableFullSpeed.slow_hire_cost).toFixed(6))
        tableFullSpeed.optimal_bunker_plus_hire = parseFloat((tableFullSpeed.optimal_ifo_cost + tableFullSpeed.optimal_msgo_cost + tableFullSpeed.optimal_hire_cost).toFixed(6))
        // tableFullSpeed.bunker_plus_hire = tableFullSpeed.ifo_cost + tableFullSpeed.msgo_cost + tableFullSpeed.hire_cost

        // console.log('_bunker_plus_hire', Math.min(tableFullSpeed.full_bunker_plus_hire, tableFullSpeed.eco_bunker_plus_hire, tableFullSpeed.slow_bunker_plus_hire))
        // console.log(`slow: ${tableFullSpeed.slow_bunker_plus_hire}, full: ${tableFullSpeed.full_bunker_plus_hire}, eco: ${tableFullSpeed.eco_bunker_plus_hire}`)

        ctx.state.tableFullSpeed.push(tableFullSpeed);
      })
      // function getLabOrBal(index) {
      //   let indexFirstLoading = routes.findIndex(a=> a.status === 'loading')
      //   indexFirstLoading = indexFirstLoading > 0 ? indexFirstLoading : null
      //   let indexLastDischarging = (routes.reverse()).findIndex(a=> a.status === 'discharging');
      //   indexLastDischarging = indexLastDischarging >=0 ? ((routes.length - 1) - indexLastDischarging) : null
      //   if(index >= indexFirstLoading && index <indexLastDischarging) {
      //     return 'lad'
      //   }
      //   return 'ball'
      // }


      let routesAll = payload.vessel.additional_info.routes;
      let full_allMgo = 0
      let full_allIfo = 0
      let full_allDaysSteaming = 0;
      let full_allPortStay = 0;
      let full_allLayExDay = 0;
      let full_allDisbursements = 0;

      let eco_allMgo = 0
      let eco_allIfo = 0
      let eco_allDaysSteaming = 0;
      let eco_allPortStay = 0;
      let eco_allLayExDay = 0;
      let eco_allDisbursements = 0;

      let slow_allMgo = 0
      let slow_allIfo = 0
      let slow_allDaysSteaming = 0;
      let slow_allPortStay = 0;
      let slow_allLayExDay = 0;
      let slow_allDisbursements = 0;

      let optimal_allMgo = 0
      let optimal_allIfo = 0
      let optimal_allDaysSteaming = 0;
      let optimal_allPortStay = 0;
      let optimal_allLayExDay = 0;
      let optimal_allDisbursements = 0;
      routesAll.forEach(route=> {
        let fuelType = 'full'
        full_allMgo+=(route[fuelType + '_mgo_mt']?parseFloat(route[fuelType + '_mgo_mt']):0)
        full_allIfo+=(route[fuelType + '_ifo_mt']?parseFloat(route[fuelType + '_ifo_mt']):0)
        let el = route
        if(el[fuelType+'_streaming_days'])
          full_allDaysSteaming += el[fuelType+'_streaming_days']
        // if(el[fuelType+'_streaming_time']){
        //   let time = el[fuelType+'_streaming_time'].split(':')
        //   full_allDaysSteaming += time[0]/24
        //   full_allDaysSteaming += time[1]/24/60
        // }
        full_allPortStay+=(route.port_stay?parseFloat(route.port_stay):0)
        full_allLayExDay+=(route.lay_ex?parseFloat(route.lay_ex):0)
        full_allDisbursements+=(route.disbursement ? parseFloat(route.disbursement) : 0)

        fuelType = 'eco'

        eco_allMgo+=(route[fuelType + '_mgo_mt']?parseFloat(route[fuelType + '_mgo_mt']):0)
        eco_allIfo+=(route[fuelType + '_ifo_mt']?parseFloat(route[fuelType + '_ifo_mt']):0)
        if(el[fuelType+'_streaming_days'])
          eco_allDaysSteaming += el[fuelType+'_streaming_days']
        // if(el[fuelType+'_streaming_time']){
        //   let time = el[fuelType+'_streaming_time'].split(':')
        //   eco_allDaysSteaming += time[0]/24
        //   eco_allDaysSteaming += time[1]/24/60
        // }
        eco_allPortStay+=(route.port_stay?parseFloat(route.port_stay):0)
        eco_allLayExDay+=(route.lay_ex?parseFloat(route.lay_ex):0)
        eco_allDisbursements+=(route.disbursement ? parseFloat(route.disbursement) : 0)

        fuelType = 'slow'
        slow_allMgo+=(route[fuelType + '_mgo_mt']?parseFloat(route[fuelType + '_mgo_mt']):0)
        slow_allIfo+=(route[fuelType + '_ifo_mt']?parseFloat(route[fuelType + '_ifo_mt']):0)
        if(el[fuelType+'_streaming_days'])
          slow_allDaysSteaming += el[fuelType+'_streaming_days']
        // if(el[fuelType+'_streaming_time']){
        //   let time = el[fuelType+'_streaming_time'].split(':')
        //   slow_allDaysSteaming += time[0]/24
        //   slow_allDaysSteaming += time[1]/24/60
        // }
        slow_allPortStay+=(route.port_stay?parseFloat(route.port_stay):0)
        slow_allLayExDay+=(route.lay_ex?parseFloat(route.lay_ex):0)
        slow_allDisbursements+=(route.disbursement ? parseFloat(route.disbursement) : 0)

        fuelType = 'optimal'
        if(fuelType === 'optimal') {
          // if ((route.eco_result < route.full_result) && (route.eco_result < route.slow_result) && route.eco_result !== 0 || (route.slow_result === 0 && route.full_result === 0)) {
          //   fuelType = 'eco'
          // } else if ((route.full_result < route.slow_result && route.full_result !== 0) || route.slow_result === 0) {
          //   fuelType = 'full'
          // } else {
          //   fuelType = 'slow'
          // }
          if (route.full_result != 0 && ((route.full_result <= route.slow_result && route.full_result <= route.eco_result && route.eco_result != 0 && route.slow_result != 0) || (route.full_result <= route.slow_result && route.slow_result != 0 && route.eco_result == 0) || (route.full_result <= route.eco_result && route.eco_result != 0 && route.slow_result == 0) || (route.eco_result == 0 && route.slow_result == 0))) {
            fuelType = 'full'

          } else if (route.eco_result != 0 && ((route.eco_result <= route.slow_result && route.eco_result <= route.full_result && route.full_result != 0 && route.slow_result != 0) || (route.eco_result <= route.slow_result && route.slow_result != 0 && route.full_result == 0) || (route.eco_result <= route.full_result && route.full_result != 0 && route.slow_result == 0) || (route.slow_result == 0 && route.full_result == 0))) {
            fuelType = 'eco'

          } else if (route.slow_result != 0 && ((route.slow_result <= route.eco_result && route.slow_result <= route.full_result && route.full_result != 0 && route.eco_result != 0) || (route.slow_result <= route.full_result && route.full_result != 0 && route.eco_result == 0) || (route.slow_result <= route.eco_result && route.eco_result != 0 && route.full_result == 0) || (route.full_result == 0 && route.eco_result == 0))) {
            fuelType = 'slow'

          } else if (route.full_result == 0 && route.eco_result == 0 && route.slow_result == 0) {
            fuelType = 'slow'

          }
          // console.log('fuelType', fuelType)
        }
        optimal_allMgo+=(route[fuelType + '_mgo_mt']?parseFloat(route[fuelType + '_mgo_mt']):0)
        optimal_allIfo+=(route[fuelType + '_ifo_mt']?parseFloat(route[fuelType + '_ifo_mt']):0)
        if(el[fuelType+'_streaming_days'])
          optimal_allDaysSteaming += el[fuelType+'_streaming_days']
        // if(el[fuelType+'_streaming_time']){
        //   let time = el[fuelType+'_streaming_time'].split(':')
        //   optimal_allDaysSteaming += time[0]/24
        //   optimal_allDaysSteaming += time[1]/24/60
        // }
        optimal_allPortStay+=(route.port_stay?parseFloat(route.port_stay):0)
        optimal_allLayExDay+=(route.lay_ex?parseFloat(route.lay_ex):0)
        optimal_allDisbursements+=(route.disbursement ? parseFloat(route.disbursement) : 0)
      })
      ctx.state.tableCostsFull = {
        // bunker_mgo: allMgo*payload.vessel.lsmgo_price,
        full_bunker_mgo: parseFloat((full_allMgo*payload.vessel.lsmgo_price).toFixed(6)),
        eco_bunker_mgo: parseFloat((eco_allMgo*payload.vessel.lsmgo_price).toFixed(6)),
        slow_bunker_mgo: parseFloat((slow_allMgo*payload.vessel.lsmgo_price).toFixed(6)),
        optimal_bunker_mgo: parseFloat((optimal_allMgo*payload.vessel.lsmgo_price).toFixed(6)),

        // bunker_ifo: !payload.vessel.scrubber ? allIfo*payload.vessel.vlsfo_price : allIfo*payload.vessel.hsfo_price,
        full_bunker_ifo: parseFloat((!payload.vessel.scrubber ? full_allIfo*payload.vessel.vlsfo_price : full_allIfo*payload.vessel.hsfo_price).toFixed(6)),
        eco_bunker_ifo: parseFloat((!payload.vessel.scrubber ? eco_allIfo*payload.vessel.vlsfo_price : eco_allIfo*payload.vessel.hsfo_price).toFixed(6)),
        slow_bunker_ifo: parseFloat((!payload.vessel.scrubber ? slow_allIfo*payload.vessel.vlsfo_price : slow_allIfo*payload.vessel.hsfo_price).toFixed(6)),
        optimal_bunker_ifo: parseFloat((!payload.vessel.scrubber ? optimal_allIfo*payload.vessel.vlsfo_price : optimal_allIfo*payload.vessel.hsfo_price).toFixed(6)),

        // hire_costs: (allDaysSteaming + allPortStay + allLayExDay)*((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) - ((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) * ((payload.vessel.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100))),
        full_hire_costs: parseFloat(((full_allDaysSteaming + full_allPortStay + full_allLayExDay)*((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) - ((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) * ((payload.vessel.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100)))).toFixed(6)),
        eco_hire_costs: parseFloat(((eco_allDaysSteaming + eco_allPortStay + eco_allLayExDay)*((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) - ((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) * ((payload.vessel.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100)))).toFixed(6)),
        slow_hire_costs: parseFloat(((slow_allDaysSteaming + slow_allPortStay + slow_allLayExDay)*((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) - ((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) * ((payload.vessel.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100)))).toFixed(6)),
        optimal_hire_costs: parseFloat(((optimal_allDaysSteaming + optimal_allPortStay + optimal_allLayExDay)*((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) - ((payload.vessel.hire ? parseFloat(payload.vessel.hire) : 0) * ((payload.vessel.additional_commission ? parseFloat(payload.vessel.additional_commission) : 0)/100)))).toFixed(6)),

        // disbursements: allDisbursements,
        full_disbursements: parseFloat((full_allDisbursements).toFixed(6)),
        eco_disbursements: parseFloat((eco_allDisbursements).toFixed(6)),
        slow_disbursements: parseFloat((slow_allDisbursements).toFixed(6)),
        optimal_disbursements: parseFloat((optimal_allDisbursements).toFixed(6)),

        other_costs: parseFloat(((payload.vessel.ballast_bonus ? parseFloat(payload.vessel.ballast_bonus) : 0) + (payload.vessel.expenses ? parseFloat(payload.vessel.expenses) : 0) + (payload.vessel.mins_cost ? parseFloat(payload.vessel.mins_cost) : 0) + (payload.vessel.insurance ? parseFloat(payload.vessel.insurance) : 0) + (payload.vessel.other ? parseFloat(payload.vessel.other) : 0)).toFixed(6)),
        // full_other_costs: (payload.vessel.ballast_bonus ? parseFloat(payload.vessel.ballast_bonus) : 0) + (payload.vessel.expenses ? parseFloat(payload.vessel.expenses) : 0) + (payload.vessel.mins_cost ? parseFloat(payload.vessel.mins_cost) : 0) + (payload.vessel.insurance ? parseFloat(payload.vessel.insurance) : 0) + (payload.vessel.other ? parseFloat(payload.vessel.other) : 0),
        // eco_other_costs: (payload.vessel.ballast_bonus ? parseFloat(payload.vessel.ballast_bonus) : 0) + (payload.vessel.expenses ? parseFloat(payload.vessel.expenses) : 0) + (payload.vessel.mins_cost ? parseFloat(payload.vessel.mins_cost) : 0) + (payload.vessel.insurance ? parseFloat(payload.vessel.insurance) : 0) + (payload.vessel.other ? parseFloat(payload.vessel.other) : 0),
        // slow_other_costs: (payload.vessel.ballast_bonus ? parseFloat(payload.vessel.ballast_bonus) : 0) + (payload.vessel.expenses ? parseFloat(payload.vessel.expenses) : 0) + (payload.vessel.mins_cost ? parseFloat(payload.vessel.mins_cost) : 0) + (payload.vessel.insurance ? parseFloat(payload.vessel.insurance) : 0) + (payload.vessel.other ? parseFloat(payload.vessel.other) : 0),
        // optimal_other_costs: (payload.vessel.ballast_bonus ? parseFloat(payload.vessel.ballast_bonus) : 0) + (payload.vessel.expenses ? parseFloat(payload.vessel.expenses) : 0) + (payload.vessel.mins_cost ? parseFloat(payload.vessel.mins_cost) : 0) + (payload.vessel.insurance ? parseFloat(payload.vessel.insurance) : 0) + (payload.vessel.other ? parseFloat(payload.vessel.other) : 0),

        liner_in_out: parseFloat((ctx.getters.getTotalLinerInCosts + ctx.getters.getTotalLinerOutCosts).toFixed(6)),
        // full_liner_in_out: ctx.getters.getTotalLinerInCosts + ctx.getters.getTotalLinerOutCosts,
        // eco_liner_in_out: ctx.getters.getTotalLinerInCosts + ctx.getters.getTotalLinerOutCosts,
        // slow_liner_in_out: ctx.getters.getTotalLinerInCosts + ctx.getters.getTotalLinerOutCosts,
        // optimal_liner_in_out: ctx.getters.getTotalLinerInCosts + ctx.getters.getTotalLinerOutCosts,

        // total_costs: null,
        full_total_costs: null,
        eco_total_costs: null,
        slow_total_costs: null,
        optimal_total_costs: null,

        // tce_costs: null,
        full_tce_costs: null,
        eco_tce_costs: null,
        slow_tce_costs: null,
        optimal_tce_costs: null,

        // results: null,
        full_results: null,
        eco_results: null,
        slow_results: null,
        optimal_results: null,

        // freight_bep: null,
        full_freight_bep: null,
        eco_freight_bep: null,
        slow_freight_bep: null,
        optimal_freight_bep: null,

        // voyage_days: null,
        full_voyage_days: null,
        eco_voyage_days: null,
        slow_voyage_days: null,
        optimal_voyage_days: null,

        // hire_bep: null,
        full_hire_bep: null,
        eco_hire_bep: null,
        slow_hire_bep: null,
        optimal_hire_bep: null,

      }
      // ctx.state.tableCostsFull.total_costs = ctx.state.tableCostsFull.bunker_mgo + ctx.state.tableCostsFull.bunker_ifo + ctx.state.tableCostsFull.hire_costs + ctx.state.tableCostsFull.disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out
      ctx.state.tableCostsFull.full_total_costs = parseFloat((ctx.state.tableCostsFull.full_bunker_mgo + ctx.state.tableCostsFull.full_bunker_ifo + ctx.state.tableCostsFull.full_hire_costs + ctx.state.tableCostsFull.full_disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out).toFixed(6))
      ctx.state.tableCostsFull.eco_total_costs = parseFloat((ctx.state.tableCostsFull.eco_bunker_mgo + ctx.state.tableCostsFull.eco_bunker_ifo + ctx.state.tableCostsFull.eco_hire_costs + ctx.state.tableCostsFull.eco_disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out).toFixed(6))
      ctx.state.tableCostsFull.slow_total_costs = parseFloat((ctx.state.tableCostsFull.slow_bunker_mgo + ctx.state.tableCostsFull.slow_bunker_ifo + ctx.state.tableCostsFull.slow_hire_costs + ctx.state.tableCostsFull.slow_disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out).toFixed(6))
      ctx.state.tableCostsFull.optimal_total_costs = parseFloat((ctx.state.tableCostsFull.optimal_bunker_mgo + ctx.state.tableCostsFull.optimal_bunker_ifo + ctx.state.tableCostsFull.optimal_hire_costs + ctx.state.tableCostsFull.optimal_disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out).toFixed(6))


      // ctx.state.tableCostsFull.tce_costs = ctx.state.tableCostsFull.bunker_mgo + ctx.state.tableCostsFull.bunker_ifo + ctx.state.tableCostsFull.disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out
      ctx.state.tableCostsFull.full_tce_costs = parseFloat((ctx.state.tableCostsFull.full_bunker_mgo + ctx.state.tableCostsFull.full_bunker_ifo + ctx.state.tableCostsFull.full_disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out).toFixed(6))
      ctx.state.tableCostsFull.eco_tce_costs = parseFloat((ctx.state.tableCostsFull.eco_bunker_mgo + ctx.state.tableCostsFull.eco_bunker_ifo + ctx.state.tableCostsFull.eco_disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out).toFixed(6))
      ctx.state.tableCostsFull.slow_tce_costs = parseFloat((ctx.state.tableCostsFull.slow_bunker_mgo + ctx.state.tableCostsFull.slow_bunker_ifo + ctx.state.tableCostsFull.slow_disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out).toFixed(6))
      ctx.state.tableCostsFull.optimal_tce_costs = parseFloat((ctx.state.tableCostsFull.optimal_bunker_mgo + ctx.state.tableCostsFull.optimal_bunker_ifo + ctx.state.tableCostsFull.optimal_disbursements + ctx.state.tableCostsFull.other_costs + ctx.state.tableCostsFull.liner_in_out).toFixed(6))



      // console.log('hire_costs', 'allDaysSteaming: '+allDaysSteaming, 'allPortStay: '+ allPortStay, 'allLayExDay: '+allLayExDay, 'payload.vessel.hire: '+payload.vessel.hire, 'payload.vessel.additional_commission: '+payload.vessel.additional_commission)
      let averageCgComm = 0
      payload.cargos.forEach(route=> {
        averageCgComm += parseFloat(((route.cg_comm) ? parseFloat(route.cg_comm) : 0).toFixed(6))
      })
      averageCgComm = parseFloat((averageCgComm/payload.cargos.length).toFixed(6));

      // ctx.state.tableCostsFull.freight_bep = (ctx.state.tableCostsFull.total_costs / ctx.getters.getTotalFinalLoad) + (ctx.state.tableCostsFull.total_costs / ctx.getters.getTotalFinalLoad) * (averageCgComm/100)
      ctx.state.tableCostsFull.full_freight_bep = parseFloat(((ctx.state.tableCostsFull.full_total_costs / ctx.getters.getTotalFinalLoad) + (ctx.state.tableCostsFull.full_total_costs / ctx.getters.getTotalFinalLoad) * (averageCgComm/100)).toFixed(6))
      ctx.state.tableCostsFull.eco_freight_bep = parseFloat(((ctx.state.tableCostsFull.eco_total_costs / ctx.getters.getTotalFinalLoad) + (ctx.state.tableCostsFull.eco_total_costs / ctx.getters.getTotalFinalLoad) * (averageCgComm/100)).toFixed(6))
      ctx.state.tableCostsFull.slow_freight_bep = parseFloat(((ctx.state.tableCostsFull.slow_total_costs / ctx.getters.getTotalFinalLoad) + (ctx.state.tableCostsFull.slow_total_costs / ctx.getters.getTotalFinalLoad) * (averageCgComm/100)).toFixed(6))
      ctx.state.tableCostsFull.optimal_freight_bep = parseFloat(((ctx.state.tableCostsFull.optimal_total_costs / ctx.getters.getTotalFinalLoad) + (ctx.state.tableCostsFull.optimal_total_costs / ctx.getters.getTotalFinalLoad) * (averageCgComm/100)).toFixed(6))
      // ctx.state.tableCostsFull.full_freight_bep = ctx.state.tableCostsFull.full_freight_bep ? ctx.state.tableCostsFull.full_freight_bep : 0
      // ctx.state.tableCostsFull.eco_freight_bep =  ctx.state.tableCostsFull.eco_freight_bep ?  ctx.state.tableCostsFull.eco_freight_bep : 0

      // ctx.state.tableCostsFull.voyage_days = allDaysSteaming + allPortStay + allLayExDay;
      ctx.state.tableCostsFull.full_voyage_days = parseFloat((full_allDaysSteaming + full_allPortStay + full_allLayExDay).toFixed(6));
      ctx.state.tableCostsFull.eco_voyage_days = parseFloat((eco_allDaysSteaming + eco_allPortStay + eco_allLayExDay).toFixed(6));
      ctx.state.tableCostsFull.slow_voyage_days = parseFloat((slow_allDaysSteaming + slow_allPortStay + slow_allLayExDay).toFixed(6));
      ctx.state.tableCostsFull.optimal_voyage_days = parseFloat((optimal_allDaysSteaming + optimal_allPortStay + optimal_allLayExDay).toFixed(6));

      // ctx.state.tableCostsFull.hire_bep = (ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.tce_costs) / ctx.state.tableCostsFull.voyage_days + ((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.tce_costs) / ctx.state.tableCostsFull.voyage_days) * (payload.vessel.additional_commission / 100)
      ctx.state.tableCostsFull.full_hire_bep = parseFloat(((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.full_tce_costs) / ctx.state.tableCostsFull.full_voyage_days + ((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.full_tce_costs) / ctx.state.tableCostsFull.full_voyage_days) * (payload.vessel.additional_commission / 100)).toFixed(6))
      ctx.state.tableCostsFull.eco_hire_bep = parseFloat(((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.eco_tce_costs) / ctx.state.tableCostsFull.eco_voyage_days + ((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.eco_tce_costs) / ctx.state.tableCostsFull.eco_voyage_days) * (payload.vessel.additional_commission / 100)).toFixed(6))
      ctx.state.tableCostsFull.slow_hire_bep = parseFloat(((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.slow_tce_costs) / ctx.state.tableCostsFull.slow_voyage_days + ((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.slow_tce_costs) / ctx.state.tableCostsFull.slow_voyage_days) * (payload.vessel.additional_commission / 100)).toFixed(6))
      ctx.state.tableCostsFull.optimal_hire_bep = parseFloat(((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.optimal_tce_costs) / ctx.state.tableCostsFull.optimal_voyage_days + ((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.optimal_tce_costs) / ctx.state.tableCostsFull.optimal_voyage_days) * (payload.vessel.additional_commission / 100)).toFixed(6))

      // ctx.state.tableCostsFull.results = ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.total_costs;
      ctx.state.tableCostsFull.full_results =  parseFloat((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.full_total_costs).toFixed(6))
      console.log('full_results', ctx.state.tableCostsFull.full_results, 'ctx.getters.getTotalFreightNet:', ctx.getters.getTotalFreightNet, 'ctx.state.tableCostsFull.full_total_costs: ', ctx.state.tableCostsFull.full_total_costs)

      ctx.state.tableCostsFull.eco_results = parseFloat((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.eco_total_costs).toFixed(6))
      ctx.state.tableCostsFull.slow_results = parseFloat((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.slow_total_costs).toFixed(6))
      ctx.state.tableCostsFull.optimal_results = parseFloat((ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.optimal_total_costs).toFixed(6))
      // console.log(' ctx.state.tableCostsFull.full_results', ctx.state.tableCostsFull.full_hire_bep, payload.vessel.hire, ctx.state.tableCostsFull.full_results)
      // console.log('ctx.state.tableCostsFull.eco_hire_bep', ctx.state.tableCostsFull.eco_hire_bep, ctx.state.tableCostsFull.eco_results,(ctx.getters.getTotalFreightNet - ctx.state.tableCostsFull.eco_total_costs) )
      console.log('calcAdditionalyInfoForVessel end')
      return await null
    }
  },
  state: {
    noCalcIntake: false,
    loadTable: [],
    dischTable: [],
    tableAdditionalInfoBssLoadedCargoes: [],
    tableFullSpeed: [],
    emptyLoad: {
      cargo_id: null,
      route_id: null,
      port: null,
      dwa: null,
      draft: null,
      dw_calc: null,
      dw_intake: null,
      cargo_min: null,
      cargo_max: null,
      sf_int: null,
      lim_max_qtt: null,
      intake: null,
    },
    emptyDisch: {
      cargo_id: null,
      route_id: null,
      port: null,
      dwa: null,
      draft: null,
      dw_calc: null,
      dw_intake: null,
    },
    emptyTableAdditionalInfoBssLoadedCargoes: {
      final_load: null,
      freight_net: null,
      liner_in_costs: null,
      liner_out_costs: null,
      load_time: null,
      disch_time: null,
      loadtime_tt: null,
      ifo_stay_loadport: null,
      ifo_stay_disport: null,
      mgo_stay_loadport: null,
      ifo_load_eca_terms: null,
      ifo_disport_eca_terms: null,
      mgo_loadport_eca_terms: null,
    },
    tableFullSpeedEmpty: {
      type_lad_ball: '',
      ifo_layExD: null,
      mgo_layExD: null,
      ifo_layExD_eca_non_eca: null,
      mgo_layExD_eca_non_eca: null,
      full_time_non_eca: null,
      eco_time_non_eca: null,
      slow_time_non_eca: null,
      optimal_time_non_eca: null,
      full_time_eca: null,
      eco_time_eca: null,
      slow_time_eca: null,
      optimal_time_eca: null,
      full_total_time: null,
      eco_total_time: null,
      slow_total_time: null,
      optimal_total_time: null,
      full_ifo_eca_non_eca: null,
      eco_ifo_eca_non_eca: null,
      slow_ifo_eca_non_eca: null,
      optimal_ifo_eca_non_eca: null,
      full_mgo_eca_non_eca: null,
      eco_mgo_eca_non_eca: null,
      slow_mgo_eca_non_eca: null,
      optimal_mgo_eca_non_eca: null,
      full_ifo_cost: null,
      eco_ifo_cost: null,
      slow_ifo_cost: null,
      optimal_ifo_cost: null,
      full_msgo_cost: null,
      eco_msgo_cost: null,
      slow_msgo_cost: null,
      optimal_msgo_cost: null,
      full_hire_cost: null,
      eco_hire_cost: null,
      slow_hire_cost: null,
      optimal_hire_cost: null,
      full_bunker_plus_hire: null,
      eco_bunker_plus_hire: null,
      slow_bunker_plus_hire: null,
      optimal_bunker_plus_hire: null,
    },
    tableCostsFull: {
      bunker_mgo: null,
      bunker_ifo: null,
      hire_costs: null,
      disbursements: null,
      other_costs: null,
      liner_in_out: null,
      total_costs: null,
      tce_costs: null,
      results: null,
      freight_bep: null,
      voyage_days: null,
      hire_bep: null,
    }
  }
}