<template>
  <div>
    <b-dropdown right ref="dropdown">
      <template #button-content>
        <button class="blue-button fz13 fw500 h32 p-l-1.5">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="white"/></svg>
          Create contract
        </button>
      </template>
      <div class="content">
        <button class="fz13 fw500 text-dark edit text-align-left" v-on:click="clickVoyageCharterer" v-if="getPermissionsVoyage()">
          Voyage contract (Owner)
        </button>
        <button class="fz13 fw500 text-dark edit text-align-left" v-on:click="clickVoyage" v-if="getPermissionsVoyage()">
          Voyage contract (Charterer)
        </button>
        <button class="fz13 fw500 text-dark edit" v-on:click="clickSublet">
          Sublet contract
        </button>

      </div>
    </b-dropdown>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "drop-menu-create-contract",
  props: ["dop"],
  computed: mapGetters(["getPermissionsByType"]),
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(["emitOpenModalVoyage", "emitOpenModalSublet" , 'emitOpenModalVoyageCharterer']),
    getPermissionsVoyage() {
      return this.getPermissionsByType("create_a_voyage_contract");
    },
    getPermissionsSublet() {
      return this.getPermissionsByType("create_a_sublet_contract");
    },
    clickVoyage() {
      this.$refs.dropdown.hide(false);
      this.emitOpenModalVoyage();
    },
    clickSublet() {
      this.$refs.dropdown.hide(false);
      this.emitOpenModalSublet();
    },
    clickVoyageCharterer() {
      this.$refs.dropdown.hide(false);
      this.emitOpenModalVoyageCharterer();
    }
  },
  mounted() {

  },
  beforeMount() {
    if(this.$route.query.from == 'counterparties' && this.$route.query.newcharterer){
      if(this.$route.query.newcharterer == 'voyage') this.emitOpenModalVoyage();
      else if(this.$route.query.newcharterer == 'sublet') this.emitOpenModalSublet();
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../style-element/dropdown";
@import "../../style-element/checkbox";
.edit {
  svg {
    path {
      fill: #2C2C2C;
    }
  }
}

.button-drop {
  transition: .1s linear;
  svg {
    path, circle {
      transition: .1s linear
    }
  }
  &:hover {
    svg {
      path, circle {
        fill: #2C2C2C;
      }
    }
    transform: scale(1.2);
  }
}
/deep/ {

  .custom-control {
    min-height: 1px !important;
    padding: 0 8px 0 12px;
    width: 100%;
    /*height: 100%;*/
    /*box-sizing: border-box;*/
    /*align-items: center;*/
    .custom-control-label {
      width: 100%;

      font-size: 13px;
      line-height: 16px;
      color: #2C2C2C;
    }
  }
  .custom-control-label::before {
    height:16px;
    top: 0;
  }
  .custom-control-label::after {
    left: auto !important;
    right: 14px;
  }
  .custom-switch .custom-control-label::after {
    top: 2px!important;
  }
  .custom-switch .custom-control-label::before {
    left:auto;
    right: 0;
  }
  .custom-switch {
    top: 0 !important;
  }
}
.active-button {
  background-image: url("../../../assets/ic_chsdeck.svg");
  background-position-x: calc(100% - 4px);
  background-position-y: center;
  background-repeat: no-repeat;
}
.svg-1 {
  margin-right: 8px;
}
.arrow {
  margin-left: 4px;
}
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  //top: -29px !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  padding: 4px 0 !important;
}
.content {
  width: 138px;
  background-color: var(--color-modal);
  border-radius: 4px;
  .title {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 5px 12px;
    display: flex;
    align-items: center;
    svg {
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    transition: .1s linear;
    &:hover {
      color: #2C2C2C !important;
    }
  }
  &>*{
    svg {
      margin-right: 4px;
    }
    min-height: 28px;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 8px;
    box-sizing: border-box;
    transition: background-color .1s linear;
    &:hover {
      background-color: #DEE3EA;
    }
  }
}
.bottom-button {
  margin-top: 4px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-top: 1px solid #DEE3EA;
}

.blue-button {
  border-radius: 4px !important;
}

</style>