<template>
  <div class="flex m-auto flex-col items-center justify-center">
    <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M178.29 120.654H124.348C121.586 120.654 119.348 118.415 119.348 115.654V35C119.348 32.2386 121.586 30 124.348 30H167.182L183.29 46.1086V115.654C183.29 118.415 181.052 120.654 178.29 120.654Z" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M157.578 49.8633H131.702" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M170.934 62.3867H131.702" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M170.934 74.9102H131.702" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M170.934 87.4336H131.702" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M152 110.755C155.102 111.238 161.453 110.812 160.918 105.314C160.143 97.3329 153.163 105.677 159.367 110.392C165.571 115.107 169.231 107.425 165.959 105.676C163.245 104.225 162.082 113.293 171 111.842" stroke="#DEE3EA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M195.976 110.776L200.317 119.983L205.325 110.776M195.976 110.776H205.325M195.976 110.776V60.8543M205.325 110.776V60.8543M195.976 60.8543V59.5256C195.976 56.9571 198.069 54.875 200.65 54.875C203.232 54.875 205.325 56.9571 205.325 59.5256V60.8543M195.976 60.8543H205.325M205.325 60.8543H207.662C209.506 60.8543 211.001 62.3415 211.001 64.1761V81.4497" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M109 44.8594V120.835C109 126.358 113.477 130.835 119 130.835H170.769" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M239.409 103.467C239.605 103.36 239.852 103.218 240.02 103.068C241.616 101.647 243.727 101.641 245.329 103.068C246.386 104.009 247.752 104.009 248.809 103.068C249.593 102.37 250.511 102.001 251.463 102.001C252.416 102.001 253.334 102.37 254.118 103.068C255.175 104.009 256.541 104.009 257.598 103.068C259.194 101.647 261.305 101.641 262.907 103.068C263.06 103.204 263.278 103.344 263.576 103.498C263.943 103.687 264.102 104.172 263.932 104.581C263.762 104.989 263.326 105.167 262.959 104.977C262.548 104.765 262.241 104.563 261.992 104.341C260.935 103.4 259.569 103.4 258.513 104.341C256.916 105.762 254.805 105.768 253.203 104.341C252.146 103.4 250.781 103.4 249.724 104.341C248.127 105.762 246.016 105.768 244.414 104.341C243.357 103.4 241.992 103.4 240.935 104.341C240.657 104.588 240.335 104.777 240.056 104.93C239.693 105.129 239.254 104.962 239.075 104.558C238.897 104.154 239.046 103.666 239.409 103.467Z" fill="#DEE3EA"/>
      <path d="M248.409 109.467C248.605 109.36 248.852 109.218 249.02 109.068C250.616 107.647 252.727 107.641 254.329 109.068C255.386 110.009 256.752 110.009 257.809 109.068C258.593 108.37 259.511 108.001 260.463 108.001C261.416 108.001 262.334 108.37 263.118 109.068C264.175 110.009 265.541 110.009 266.598 109.068C268.194 107.647 270.305 107.641 271.907 109.068C272.06 109.204 272.278 109.344 272.576 109.498C272.943 109.687 273.102 110.172 272.932 110.581C272.762 110.989 272.326 111.167 271.959 110.977C271.548 110.765 271.241 110.563 270.992 110.341C269.935 109.4 268.569 109.4 267.513 110.341C265.916 111.762 263.805 111.768 262.203 110.341C261.146 109.4 259.781 109.4 258.724 110.341C257.127 111.762 255.016 111.768 253.414 110.341C252.357 109.4 250.992 109.4 249.935 110.341C249.657 110.588 249.335 110.777 249.056 110.93C248.693 111.129 248.254 110.962 248.075 110.558C247.897 110.154 248.046 109.666 248.409 109.467Z" fill="#DEE3EA"/>
      <path d="M60.4095 101.467C60.6053 101.36 60.8517 101.218 61.0197 101.068C62.6159 99.6466 64.7272 99.6414 66.3293 101.068C67.3861 102.009 68.7519 102.009 69.8086 101.068C70.5928 100.37 71.5107 100.001 72.4634 100.001C73.4161 100.001 74.3341 100.37 75.1182 101.068C76.1751 102.009 77.5408 102.009 78.5976 101.068C80.1938 99.6467 82.305 99.6414 83.9071 101.068C84.0597 101.204 84.2784 101.344 84.5757 101.498C84.9426 101.687 85.102 102.172 84.9318 102.581C84.7615 102.989 84.3261 103.167 83.9591 102.977C83.5482 102.765 83.2409 102.563 82.992 102.341C81.9352 101.4 80.5695 101.4 79.5126 102.341C77.9164 103.762 75.8052 103.768 74.2031 102.341C73.1463 101.4 71.7806 101.4 70.7237 102.341C69.1274 103.762 67.0162 103.768 65.4141 102.341C64.3573 101.4 62.9916 101.4 61.9347 102.341C61.6575 102.588 61.3348 102.777 61.0557 102.93C60.6927 103.129 60.2537 102.962 60.0753 102.558C59.8969 102.154 60.0464 101.666 60.4095 101.467Z" fill="#DEE3EA"/>
      <path d="M218.409 44.467C218.605 44.3598 218.852 44.2176 219.02 44.068C220.616 42.6466 222.727 42.6414 224.329 44.068C225.386 45.009 226.752 45.009 227.809 44.068C228.593 43.3698 229.511 43.0007 230.463 43.0007C231.416 43.0007 232.334 43.3698 233.118 44.068C234.175 45.009 235.541 45.009 236.598 44.068C238.194 42.6467 240.305 42.6414 241.907 44.068C242.06 44.2038 242.278 44.3444 242.576 44.498C242.943 44.6875 243.102 45.1722 242.932 45.5806C242.762 45.989 242.326 46.1665 241.959 45.977C241.548 45.7648 241.241 45.5628 240.992 45.3411C239.935 44.4001 238.569 44.4 237.513 45.3411C235.916 46.7625 233.805 46.7677 232.203 45.3411C231.146 44.4001 229.781 44.4 228.724 45.3411C227.127 46.7625 225.016 46.7677 223.414 45.3411C222.357 44.4001 220.992 44.4 219.935 45.3411C219.657 45.588 219.335 45.7774 219.056 45.9302C218.693 46.1289 218.254 45.9623 218.075 45.5583C217.897 45.1542 218.046 44.6657 218.409 44.467Z" fill="#DEE3EA"/>
      <path d="M23.4095 56.467C23.6053 56.3598 23.8517 56.2176 24.0197 56.068C25.6159 54.6466 27.7272 54.6414 29.3293 56.068C30.3861 57.009 31.7519 57.009 32.8086 56.068C33.5928 55.3698 34.5107 55.0007 35.4634 55.0007C36.4161 55.0007 37.3341 55.3698 38.1182 56.068C39.1751 57.009 40.5408 57.009 41.5976 56.068C43.1938 54.6467 45.305 54.6414 46.9071 56.068C47.0597 56.2038 47.2784 56.3444 47.5757 56.498C47.9426 56.6875 48.102 57.1722 47.9318 57.5806C47.7615 57.989 47.3261 58.1665 46.9591 57.977C46.5482 57.7648 46.2409 57.5628 45.992 57.3411C44.9352 56.4001 43.5695 56.4 42.5126 57.3411C40.9164 58.7625 38.8052 58.7677 37.2031 57.3411C36.1463 56.4001 34.7806 56.4 33.7237 57.3411C32.1274 58.7625 30.0162 58.7677 28.4141 57.3411C27.3573 56.4001 25.9916 56.4 24.9347 57.3411C24.6575 57.588 24.3348 57.7774 24.0557 57.9302C23.6927 58.1289 23.2537 57.9623 23.0753 57.5583C22.8969 57.1542 23.0464 56.6657 23.4095 56.467Z" fill="#DEE3EA"/>
      <path d="M32.4095 62.467C32.6053 62.3598 32.8517 62.2176 33.0197 62.068C34.6159 60.6466 36.7272 60.6414 38.3293 62.068C39.3861 63.009 40.7519 63.009 41.8086 62.068C42.5928 61.3698 43.5107 61.0007 44.4634 61.0007C45.4161 61.0007 46.3341 61.3698 47.1182 62.068C48.1751 63.009 49.5408 63.009 50.5976 62.068C52.1938 60.6467 54.305 60.6414 55.9071 62.068C56.0597 62.2038 56.2784 62.3444 56.5757 62.498C56.9426 62.6875 57.102 63.1722 56.9318 63.5806C56.7615 63.989 56.3261 64.1665 55.9591 63.977C55.5482 63.7648 55.2409 63.5628 54.992 63.3411C53.9352 62.4001 52.5695 62.4 51.5126 63.3411C49.9164 64.7625 47.8052 64.7677 46.2031 63.3411C45.1463 62.4001 43.7806 62.4 42.7237 63.3411C41.1274 64.7625 39.0162 64.7677 37.4141 63.3411C36.3573 62.4001 34.9916 62.4 33.9347 63.3411C33.6575 63.588 33.3348 63.7774 33.0557 63.9302C32.6927 64.1289 32.2537 63.9623 32.0753 63.5583C31.8969 63.1542 32.0464 62.6657 32.4095 62.467Z" fill="#DEE3EA"/>
    </svg>
    <p class="m-t-4 m-b-6 fz18 fw500">Invoice has not been created yet</p>
    <button class="blue-button p-x-12 p-y-4" @click="$emit('on-create-invoice')">Create invoice</button>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>