<template>
  <transition name="fade">
    <div class="wrap-transition" v-if="show">
      <div class="filter" v-on:click="hideMenu"></div>
      <div class="wrap flex flex-col" :class="{ 'show-menu': show }">
        <div class="top-block">
          <button class="close-menu fz14 fw500 text-dark" v-on:click="hideMenu">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z"
                fill="#222222"
              />
              <path
                d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z"
                fill="#222222"
              />
            </svg>
            Filter
          </button>
          <button class="clearButton fz14 text-red" v-on:click="clearFilter">
            Clear
          </button>
        </div>
        <div class="filter-content" v-if="show">
          <div class="filter-row">
            <span class="filter-row__name fz14 fw500 p-b-3 d-flex"
              >Display to</span
            >
            <div class="radio-block">
              <label class="radio-wrapper p-b-2">
                <input
                  v-model="activeFilters.display_to"
                  type="radio"
                  value="Everyone"
                  name="display_to"
                />
                <span></span>
                <p class="fz14 text-dark1">Everyone</p>
              </label>
              <label class="radio-wrapper">
                <input
                  v-model="activeFilters.display_to"
                  value="Only to myself"
                  type="radio"
                  name="display_to"
                />
                <span></span>
                <p class="fz14 text-dark1">Only to myself</p>
              </label>
            </div>
          </div>
          <div class="filter-row">
            <span class="filter-row__name fz14 fw500 p-b-3 d-flex"
              >Event Type</span
            >

            <label class="my-custom-checkbox">
              <input type="checkbox" v-model="eventType['Meeting']" />
              <span class="fz13">Meeting</span>
            </label>
            <label class="my-custom-checkbox">
              <input type="checkbox" v-model="eventType['Business trip']" />
              <span class="fz13">Business trip</span>
            </label>
            <label class="my-custom-checkbox">
              <input type="checkbox" v-model="eventType['Weekends']" />
              <span class="fz13">Weekends</span>
            </label>
            <label class="my-custom-checkbox">
              <input type="checkbox" v-model="eventType['Birthday']" />
              <span class="fz13">Birthday</span>
            </label>
            <label class="my-custom-checkbox">
              <input type="checkbox" v-model="eventType['Vacation']" />
              <span class="fz13">Vacation</span>
            </label>
          </div>
          <div class="filter-row" v-if="isShowMembers">
            <span class="filter-row__name fz14 fw500 p-b-3 d-flex"
              >Members</span
            >
            <div class="my-custom-select-wrapper">
              <multiselect
                v-model="activeFilters.members"
                label="name"
                track-by="name"
                :options="filteredMembersList"
                :multiple="true"
                :taggable="false"
                :limit="1"
                :limit-text="limitText"
                :show-labels="false"
              >
                <template slot="tag" slot-scope="{ option }">
                  <span class="selected">
                    <span>{{ option.name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__name">
                    <div class="avatar" v-if="!props.option.avatar"></div>
                    <div
                      class="avatar"
                      v-else
                      :style="{
                        'background-image': `url(${props.option.avatar})`,
                      }"
                    ></div>
                    {{ props.option.name }}
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="filter-row">
            <span class="filter-row__name fz14 fw500 p-b-3 d-flex"
              >Event Tags</span
            >
            <tag-select
              @deleteUsageTag="deleteTagWhichUsed"
              ref="tagsSel"
              @emitGetTags="setTags"
              class="tagsSel"
            ></tag-select>
            <modal-delete-tag ref="modalDeleteTag"></modal-delete-tag>
          </div>
          <div class="filter-row">
            <span class="filter-row__name fz14 fw500 p-b-3 d-flex"
              >Counterparies</span
            >
            <div class="my-custom-select-wrapper">
              <multiselect
                v-model="activeFilters.counterparties"
                label="name"
                track-by="name"
                :options="counterpartyes.list"
                :multiple="true"
                :taggable="false"
                :limit="1"
                :limit-text="limitText"
                :show-labels="false"
              >
                <template slot="tag" slot-scope="{ option }">
                  <span class="selected">
                    <span>{{ option.name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__name">
                    {{ props.option.name }}
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="filter-row">
            <span class="filter-row__name fz14 fw500 p-b-3 d-flex"
              >Vessels</span
            >
            <div class="my-custom-select-wrapper">
              <multiselect
                v-model="activeFilters.vessel"
                label="name"
                track-by="name"
                :options="vessels.list"
                :multiple="false"
                :taggable="false"
                :limit="1"
                :limit-text="limitText"
                :show-labels="false"
              >
                <template slot="tag" slot-scope="{ option }">
                  <span class="selected">
                    <span>{{ option.name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__name">
                    {{ props.option.name }}
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="apply-button-wrapper">
          <button class="apply-btn fz14 fw500" @click="applyFilters">
            Apply
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import TagSelect from "@/components/calendar/tagSelect";
import ModalDeleteTag from "@/components/tasks/modalDeleteTag";
import Multiselect from "vue-multiselect";
export default {
  name: "calendar-filter",
  components: { TagSelect, ModalDeleteTag, Multiselect },
  data() {
    return {
      activeFilters: {
        display_to: "",
        tags: [],
        members: [],
        counterparties: [],
        vessel: null,
      },
      eventType: {
        Meeting: false,
        "Business trip": false,
        Weekends: false,
        Birthday: false,
        Vacation: false,
      },
      members: {
        selected: [],
        list: [],
      },
      counterpartyes: {
        selected: [],
        list: [],
      },
      vessels: {
        selected: [],
        list: [],
      },
      show: false,
    };
  },

  methods: {
    ...mapActions([
      "axiosGetCalendarMembersList",
      "axiosGetCalendarClientsList",
      "axiosGetCalendarVesselsList",
    ]),
    ...mapMutations([
      "setCalendarActiveFilters",
      "delCalendarActiveFilters",
      "setCalendarActiveFiltersFull",
      "delCalendarActiveFiltersFull",
    ]),
    ...mapGetters([
      "getCalendarActiveFilters",
      "getCalendarMembersList",
      "getCalendarActiveFiltersFull",
    ]),
    limitText(count) {
      return `+ ${count}`;
    },
    clearFilter() {
      this.clearSelected();
      this.$myEmitter.emit("loadFilteredEvents");
      this.show = false;
      this.$emit("applyFilters", false);
    },
    async showMenu() {
      this.show = true;
      this.getAllData();
      let activeFilters = JSON.parse(
        JSON.stringify(this.getCalendarActiveFiltersFull())
      );
      

      if (Object.keys(activeFilters).length != 0) {
        this.activeFilters = activeFilters;
        // this.members.selected = activeFilters.members
        // activeFilters.members.forEach(el => this.selectMember([el]))
       
        this.selectMember(activeFilters.members);
        this.$myEmitter.emit("UpdateDefaultValue");
      }
      await this.$nextTick()
      this.$refs.tagsSel.setDefaultTags(this.activeFilters.tags)
      
    },
    clearSelected() {
      this.delCalendarActiveFilters();
      this.delCalendarActiveFiltersFull();
      this.activeFilters.display_to = "";
      this.activeFilters.tags = [];
      this.activeFilters.members = [];
      this.activeFilters.counterparties = [];
      this.activeFilters.vessel = null;
      this.eventType["Meeting"] = false;
      this.eventType["Business trip"] = false;
      this.eventType["Weekends"] = false;
      this.eventType["Birthday"] = false;
      this.eventType["Vacation"] = false;
      this.members.selected = [];
      this.counterpartyes.selected = [];
      this.vessels.selected = [];
    },
    hideMenu() {
      this.show = false;
    },
    async getMembers() {
      let members;
      let storeMembers = this.getCalendarMembersList();
      if (storeMembers) {
        members = storeMembers;
      } else {
        members = await this.axiosGetCalendarMembersList("members filter");
      }
      this.members.list = members.results;
      // this.members.list = members.results.map((el) => {
      //   el.name = `${el.first_name} ${el.last_name}`;
      //   return el;
      // });
    },
    selectMember(selectedMembers) {
      this.activeFilters.members = selectedMembers;
      this.members.selected = selectedMembers;
    },
    async getCounterpartyes() {
      let clients = await this.axiosGetCalendarClientsList();
      this.counterpartyes.list = clients.results.map((el) => {
        el.name = el.company_name;
        return el;
      });
    },
    selectCounterparty(selectedCounterpartyes) {
      this.activeFilters.counterparties = selectedCounterpartyes;
      this.counterpartyes.selected = selectedCounterpartyes;
    },
    async getVessels() {
      let vessels = await this.axiosGetCalendarVesselsList({
        is_archive: false,
        contract_type: "SHIP",
      });
      this.vessels.list = vessels.results;
    },
    selectVessel(selectedVessels) {
      this.activeFilters.vessel = selectedVessels;
      this.vessels.selected = selectedVessels;
    },
    deleteTagWhichUsed(tag) {
      this.$refs.modalDeleteTag.openModal(tag);
    },
    setTags(data) {
      this.activeFilters.tags = data;
    },

    getAllData() {
      this.getMembers();
      this.getCounterpartyes();
      this.getVessels();
    },
    applyFilters() {
      let data = this.activeFilters;
      let query_string = "?";
      if (localStorage.getItem("membersFilter")) {
        JSON.parse(localStorage.getItem("membersFilter")).query_string == ""
          ? (query_string = "?")
          : (query_string =
              JSON.parse(localStorage.getItem("membersFilter")).query_string +
              "&");
      }

      for (var key in data) {
        if (key == "display_to" && data[key] != "")
          query_string += `display_to=${data[key]}&`;
        if (key == "tags" && data[key].length != 0) {
          data[key].forEach((el) => (query_string += `tags=${el}&`));
        }
        if (key == "members" && data[key].length != 0) {
          data[key].forEach((el) => (query_string += `members=${el.id}&`));
        }
        if (key == "counterparties" && data[key].length != 0) {
          data[key].forEach(
            (el) => (query_string += `counterparties=${el.id}&`)
          );
        }
        if (key == "vessel" && data[key]) {
          console.log('data[key]::: vessel', data[key]);
          query_string += `vessel=${data[key].id}&`;
        }
      }
      for (var k in this.eventType) {
        if (this.eventType[k]) {
          query_string += `type=${k}&`;
        }
      }
      let arr = query_string.split("&");
      arr.pop();
      let str = arr.join("&");
      this.setCalendarActiveFilters(str);
      this.setCalendarActiveFiltersFull(this.activeFilters);
      if (str.length != 0) {
        this.$emit("applyFilters", true);
      } else {
        this.$emit("applyFilters", false);
      }

      this.$myEmitter.emit("loadFilteredEvents");
      this.hideMenu();
    },
  },
  mounted() {
    // this.getAllData();
  },
  computed: {
    ...mapGetters(["getPermissionsFullByType"]),
    filteredMembersList() {
      const ids = new Set(
        this.getPermissionsFullByType(
          "can_view_employee_calendar_for"
        ).users.map((user) => user.id)
      );
      return this.members.list.filter((el) => ids.has(el.id));
    },
    isShowMembers() {
      if (
        this.getPermissionsFullByType("can_view_employee_calendar_for")
          .has_permission &&
        this.getPermissionsFullByType("can_view_employee_calendar_for").users
          .length != 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "calendar-filter";
.bank-and-cash-select {
  /deep/ {
    .multiselect--active {
      .multiselect__single,
      .multiselect__spinner {
        display: none !important;
      }
    }
  }
}
.filter-row {
  // border-bottom: 1px solid var(--color-card-border);
  margin-bottom: 16px;
  .avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z' fill='%23DEE3EA'/%3E%3C/svg%3E%0A");
    margin-right: 6px;
    background-size: cover;
  }
  .option__name {
    // width: 150px;
    width: calc(100% - 50px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .multiselect__option--selected {
    position: relative;
    display: flex;
    align-items: center;
    .option__name:after {
      content: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z' fill='%23319FEF'/%3E%3C/svg%3E ");
      position: absolute;
      background: none;
      right: 6px;
    }
  }

  .my-custom-select-wrapper {
    .selected {
      display: inline-block;
      transform: translateY(3px);
    }
  }
}
.radio-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  .radio-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
    &:nth-child(1) {
      margin-right: 35px;
    }
  }
  input {
    position: absolute;
    z-index: -99999;
    opacity: 0;
  }
  span {
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid var(--color-gray-blacker-than-black);
    position: relative;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
  input:checked + span {
    border-color: var(--color-blue-dark);
    &:after {
      content: "";
      position: absolute;
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--color-blue-dark);
    }
  }
}
.my-custom-checkbox {
  display: flex;
  position: relative;
  &:not(:last-child) {
    padding-bottom: 14px;
  }

  &:hover {
    cursor: pointer;
  }
  [type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -9999;
  }
  span {
    display: flex;
    align-items: center;
    &:before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 18px;
      height: 18px;
      border: 1px solid var(--color-gray-blacker-than-black);
      margin-right: 8px;
      border-radius: 6px;
    }
  }
  [type="checkbox"]:checked + span:before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1.02141C10.6868 0.673336 10.17 0.663685 9.84563 0.999847C9.83884 1.00688 9.83214 1.01407 9.82554 1.02141L4.26395 6.91934L1.38355 4.7889C1.05922 4.45274 0.542408 4.46239 0.22918 4.81046C-0.0763934 5.15001 -0.0763934 5.68829 0.22918 6.02784L3.68677 8.77773C4.00559 9.1198 4.52236 9.1198 4.84114 8.77773L10.9799 2.26035C11.3042 1.92414 11.3132 1.36948 11 1.02141Z' fill='white'/%3E%3C/svg%3E%0A");
    background: var(--color-blue-dark);
    border-color: var(--color-blue-dark);
  }
}
.tagsSel {
  position: relative;
}
.apply-button-wrapper {
  margin-top: auto;
  .apply-btn {
    height: 32px;
    border-radius: 4px;
    background: var(--color-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
  }
}
</style>
<style lang="scss">
#app {
  .my-custom-select-wrapper {
    .multiselect__strong {
      display: inline-flex;
      border-radius: 50%;
      border: 1px solid black;
      align-items: center;
      justify-content: center;
      padding: 4px 4px;
      font-size: 10px;
      line-height: 100%;
    }
  }
}
</style>