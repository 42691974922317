<template>
  <div v-click-outside="hideList" class="">
    <button class="w-max h-max " v-on:click="showList">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.44 10.5576C7.23529 10.5576 7.88 11.2023 7.88 11.9976C7.88 12.7928 7.23529 13.4376 6.44 13.4376C5.64471 13.4376 5 12.7928 5 11.9976C5 11.2023 5.64471 10.5576 6.44 10.5576Z" fill="#9AA3B0"/>
        <circle cx="11.9996" cy="11.9975" r="1.44" transform="rotate(-90 11.9996 11.9975)" fill="#9AA3B0"/>
        <path d="M17.5601 10.5575C18.3554 10.5575 19.0001 11.2022 19.0001 11.9975C19.0001 12.7928 18.3554 13.4375 17.5601 13.4375C16.7648 13.4375 16.1201 12.7928 16.1201 11.9975C16.1201 11.2022 16.7648 10.5575 17.5601 10.5575Z" fill="#9AA3B0"/>
      </svg>
    </button>
    <div class="position-fixed z-1000" :style="{'top': (topList + 24) + 'px'}">
      <div class="list w128 pt4 pb4 d-flex flex-column z-10 position-absolute border-radius-4 bgc-modal" v-if="show">
        <button v-on:click="()=> {$emit('clickOpenAccess'); show = false}" class="h32 w100Proc box-border pl8 pr8 fz13 text-dark d-flex align-items-center" v-if="$route.path.indexOf('payment') == -1 && $route.path.indexOf('important') == -1">
          <svg class="mr4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.78947 6.36842C7.00465 6.36842 6.36842 7.00465 6.36842 7.78947V16.2105C6.36842 16.9954 7.00465 17.6316 7.78947 17.6316H16.2105C16.9954 17.6316 17.6316 16.9954 17.6316 16.2105V14.1053C17.6316 13.7274 17.9379 13.4211 18.3158 13.4211C18.6937 13.4211 19 13.7274 19 14.1053V16.2105C19 17.7511 17.7511 19 16.2105 19H7.78947C6.24889 19 5 17.7511 5 16.2105V7.78947C5 6.24889 6.24889 5 7.78947 5H9.89474C10.2726 5 10.5789 5.30633 10.5789 5.68421C10.5789 6.06209 10.2726 6.36842 9.89474 6.36842H7.78947ZM13.4211 5.68421C13.4211 5.30633 13.7274 5 14.1053 5H18.3158C18.6937 5 19 5.30633 19 5.68421V9.89474C19 10.2726 18.6937 10.5789 18.3158 10.5789C17.9379 10.5789 17.6316 10.2726 17.6316 9.89474V7.33604L12.4838 12.4838C12.2166 12.751 11.7834 12.751 11.5162 12.4838C11.249 12.2166 11.249 11.7834 11.5162 11.5162L16.664 6.36842H14.1053C13.7274 6.36842 13.4211 6.06209 13.4211 5.68421Z" fill="#2C2C2C"/>
          </svg>
          To open access
        </button>
        <button v-on:click="()=> {$emit('moveTo'); show = false}" class="h32 w100Proc box-border pl8 pr8 fz13 text-dark d-flex align-items-center" v-if="$route.path.indexOf('important') == -1">
          <svg class="mr4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5.16927L7 5.16927C5.89543 5.16927 5 6.0647 5 7.16927L5 16.8359C5 17.9405 5.89543 18.8359 7 18.8359L12 18.8359" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
            <path d="M11 12L20 12M20 12L16.0143 8M20 12L16.0143 16" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Move to
        </button>
        <button v-on:click="()=> {$emit('download'); show = false}" class="h32 w100Proc box-border pl8 pr8 fz13 text-dark d-flex align-items-center">
          <svg class="mr4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.333 5V14M12.333 14L16.333 10.0143M12.333 14L8.33298 10.0143M18.9997 16V17C18.9997 18.1046 18.1042 19 16.9997 19H7.333C6.22843 19 5.333 18.1046 5.333 17V16" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Download
        </button>
        <button v-on:click="()=> {$emit('editName'); show = false}" class="h32 w100Proc box-border pl8 pr8 fz13 text-dark d-flex align-items-center">
          <svg class="mr4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#2C2C2C"/>
          </svg>
          Edit name
        </button>
        <button v-on:click="()=> {$emit('archive'); show = false}" class="h32 w100Proc box-border pl8 pr8 fz13 text-dark d-flex align-items-center" v-if="$route.path.indexOf('important') == -1">
          <svg class="mr4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
          </svg>
          Archive
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "folders-option-file",
  data() {
    return {
      show: false,
      topList: null,
    }
  },
  methods: {
    hideList() {
      this.show = false
    },
    showList(e) {
      this.show = !this.show
      console.log(e)
      this.topList = e.y
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}
.list {
  right: -20px;
}
</style>