<template>
  <div class="wrap-select position-relative" v-click-outside="hideList" :class="{'smallList': smallList}">
    <div class="block-selected" v-on:click="showList = !showList">
      <p class="fz13 text-dark" v-if="value">
        {{value[watchKey]}} {{watchKey2 ? '-' : ''}} {{value[watchKey2]}}
      </p>
      <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.3006 4C2.03333 4 1.89948 4.32314 2.08847 4.51213L5.36421 7.78787C5.48137 7.90503 5.67132 7.90502 5.78848 7.78787L9.06421 4.51213C9.25319 4.32314 9.11934 4 8.85207 4H2.3006Z" fill="#2C2C2C"/>
      </svg>

    </div>
    <div class="list-selected" v-if="showList">
      <button v-for="(item, i) in list" :key="i" class="fz14 text-dark" :class="{'active-item': JSON.stringify(value) === JSON.stringify(item)}" v-on:click="select(item)">
        {{item[watchKey]}} {{watchKey2 ? '-' : ''}} {{item[watchKey2]}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "select-white",
  props: {
    allowEmpty: {
      type: Boolean,
      default: true
    },
    smallList: Boolean,
    value: [Object],
    list: Array,
    watchKey: String,
    watchKey2: String,
  },
  data() {
    return {
      showList: false,
    }
  },
  methods: {
    select(item) {
      if(this.allowEmpty) {
        if(JSON.stringify(item) === JSON.stringify(this.value)) {
          this.$emit('input', null)
        } else {
          this.$emit('input', item)
        }
      } else {
        this.$emit('input', item)
      }
      this.showList = false;

    },
    hideList() {
      this.showList = false;
    }
  }
}
</script>

<style scoped lang="scss">
  .wrap-select {
    z-index: 100;
  }
  .block-selected {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--color-modal);
    border: 1px solid var(--color-border);
    padding: 0 28px 0 8px;
    height: 28px;
    width: 188px;
    box-sizing: border-box;
    border-radius: 4px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .list-selected {
    position: absolute;
    background-color: var(--color-bgc-page-white);
    border: 1px solid var(--color-border);
    box-sizing: border-box;
    width: 283px;
    bottom: 0;
    transform: translateY(100%);
    right: 0;
    button {
      width: 100%;
      min-height: 30px;
      text-align: left;
      padding: 2px 4px;
      box-sizing: border-box;
      &:hover {
        background-color: var(--color-hover-list);
      }
    }
  }
  .active-item {
    background-color: var(--color-hover-list) !important;
  }
  .arrow {
    position: absolute;
    right: 8px;
  }
  .smallList {
    .block-selected {
      width: 95px;
    }
    .list-selected {
      width: 95px;
    }
  }
</style>