<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="vessels_detail_nav">
      <page-navigation class="page-navigation" :links="getNavByPermissions"/>
      <button class="button-menu" v-on:click="leftMenuChangeVisible">
        <svg v-if="!showMenu" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.1667 12.8151H2.83333C2.37333 12.8151 2 12.4418 2 11.9818C2 11.5218 2.37333 11.1484 2.83333 11.1484H21.1667C21.6267 11.1484 22 11.5218 22 11.9818C22 12.4418 21.6267 12.8151 21.1667 12.8151Z" fill="#2C2C2C"/>
          <path d="M21.1667 5.42839H2.83333C2.37333 5.42839 2 5.05505 2 4.59505C2 4.13505 2.37333 3.76172 2.83333 3.76172H21.1667C21.6267 3.76172 22 4.13505 22 4.59505C22 5.05505 21.6267 5.42839 21.1667 5.42839Z" fill="#2C2C2C"/>
          <path d="M21.1667 20.2057H2.83333C2.37333 20.2057 2 19.8324 2 19.3724C2 18.9124 2.37333 18.5391 2.83333 18.5391H21.1667C21.6267 18.5391 22 18.9124 22 19.3724C22 19.8324 21.6267 20.2057 21.1667 20.2057Z" fill="#2C2C2C"/>
        </svg>
        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#094172"/>
          <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#094172"/>
        </svg>
      </button>
      <chose-period-vessel-balances v-if="$route.meta.isBalances"/>
    </div>
    <menu-list-vessel @renderNew="keyRenderAll++" v-show="showMenu" class="menu-list-vessel"></menu-list-vessel>
    <counterparties-add class="add-client-modal" @close="showAddClient = false" :inside-block-type-modal="true" :key="showAddClient" v-if="showAddClient"></counterparties-add>
    <modal-slots :class="{'displayNone': showAddClient}" :no-close-after-apply="true" :is-pointer-bg="false" :show-mod="showModalEditClean" @close="closeModalEditClean" @apply="applyCleanFixed" class="modal-edit-clean">
        <template v-slot:title>
          <p class="fz18 fw500" v-if="typeForModalVessel === 'ship'">Edit Vessel</p>
          <p class="fz18 fw500" v-if="typeForModalVessel === 'cargo' || typeForModalVessel == 'VOYAGE_CHARTERER'">{{typeOpenModal === 'edit' ? 'Edit' : 'Create'}} Voyage Contract {{typeModal == 'VOYAGE_CHARTERER' ? '(Owner)' : '(Charterer)'}}</p>
          <p class="fz18 fw500" v-if="typeForModalVessel === 'sublet'">{{typeOpenModal === 'edit' ? 'Edit' : 'Create'}} Sublet Contract</p>
        </template>
        <div class="modal-edit-clean-content">
          <async-select
              v-if="typeForModalVessel === 'sublet'"
              :imported-data="true"
              :class="{ 'block-input_error': $v.activeOwner.$error }"
              class="drop block-from-inputs"
              id="counte234rparty"
              :label="'Charterer'"
              url="/client/dropdown"
              :params="{is_archived: false, ordering: 'company_name'}"
              watchKey="company_name"
              :allowEmpty="true"
              :closeOnSelect="true"
              v-model="activeOwner"
              placeholder="Search Charterer"
          />
          <async-select
              v-if="typeForModalVessel !== 'sublet'"
              :imported-data="true"
              :class="{ 'block-input_error': $v.activeOwner.$error }"
              class="drop block-from-inputs"
              id="counte234rparty"
              :label="(typeForModalVessel !== 'cargo' || typeModal == 'VOYAGE_CHARTERER') ? 'Owner' : 'Charterer'"
              url="/client/dropdown"
              :params="{is_archived: false, ordering: 'company_name'}"
              watchKey="company_name"
              :allowEmpty="true"
              :closeOnSelect="true"
              v-model="activeOwner"
              placeholder="Search Charterer"
          >
            <template v-slot:additional-label>
              <button class="m-b-1 text-blue-dark fz13" v-on:click="showAddClient = true">Create new counterparty</button>
            </template>
          </async-select>
          <div class="block-from-inputs" :class="{ 'block-input_error': $v.vesselNameEdit.$error }">
            <p class="fz13 text-gray">Vessel Name <span class="text-red">*</span></p>
            <input type="text" class="h36" maxlength="100" v-model="vesselNameEdit" @focusout="$v.vesselNameEdit.$touch()">
          </div>
          <div class="block-from-inputs">
            <p class="fz13 text-gray">{{ (typeForModalVessel === 'cargo' || typeForModalVessel === 'sublet') ? 'CP Date (Charterer)' : typeForModalVessel == 'VOYAGE_CHARTERER' ? 'CP Date (Owner)' : 'CP Date'}} <span class="text-red">*</span></p>
            <date-picker
                value-type="date"
                v-model="CPDate"
                class="datepick"
                :class="{ 'block-input_error': $v.CPDate.$error }"
                ref="datePickDue"
                @change="$v.CPDate.$touch()"
                format="DD.MM.YYYY"
                :disabled-date="disabledDates"
            >
            </date-picker>
          </div>
          <div class="block-from-inputs" v-if="typeForModalVessel !== 'sublet'">
            <p class="fz13 text-gray">Laycan Date</p>
            <!--          <input type="text" class="h36" maxlength="300" v-model="laycanDateEdit">-->
            <date-picker
                value-type="format"
                placeholder=""
                class="datepickeer w100Proc"
                v-model="laycanDateEdit"
                range
                format="DD.MM.YYYY"
                popup-class="dateLeft0"
            ></date-picker>
          </div>
          <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo' && typeForModalVessel !== 'sublet'">
            <p class="fz13 text-gray">Built</p>
            <input type="text" class="h36" maxlength="50" v-model="builtEdit" @input="builtEdit= checkNumIn(builtEdit)">
          </div>
          <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo' && typeForModalVessel !== 'sublet'">
            <p class="fz13 text-gray">DWT</p>
            <input type="text" maxlength="50" class="h36" v-model="dwtEdit" @input="dwtEdit= checkNumIn(dwtEdit)">
          </div>
          <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo' && typeForModalVessel !== 'sublet'">
            <p class="fz13 text-gray">Percentage</p>
            <input type="text" maxlength="50" class="h36" v-model="perEdit" @input="perEdit= checkNumIn(perEdit)">
          </div>
          <!--        <counterparties-drop-->
          <!--            class="drop block-from-inputs"-->
          <!--            :class="{ 'block-input_error': $v.activeOwner.$error }"-->
          <!--            :title="(typeForModalVessel !== 'cargo') ? 'Owner' : 'Charterer'"-->
          <!--            :list = "getClientsFormAddModal"-->
          <!--            @select="selectOwner"-->
          <!--            :active-item="defaultOwnerActive"-->
          <!--        ></counterparties-drop>-->

          <div v-if="typeForModalVessel === 'ship'">
            <div class="two-inputs">
              <div class="position-relative box-border">
                <div class="blue-button-text button-new-port fz13 t12" v-on:click="isShowModal = true">Create new port</div>
                <async-select-port
                    :key="keyRenderPorts"
                    class="drop block-from-inputs"
                    :label="(typeForModalVessel !== 'cargo') ? 'Delivery' : 'Port of Loading'"
                    v-model="delivery_place"
                    :placeholder="''"
                ></async-select-port>
              </div>
              <div class="block-from-inputs">
                <p class="fz13 text-gray">Delivery Date</p>
                <date-picker
                    v-model="deliveryDate"
                    type="datetime"
                    class="datepick"
                    :show-time-panel="showTimePanel"
                    ref="datePickDue"
                    format="DD.MM.YYYY, HH:mm"
                    @close="showTimePanel=false"
                >
                  <template v-slot:footer>
                    <button class="mx-btn mx-btn-text" @click="showTimePanel= !showTimePanel">
                      {{ showTimePanel ? 'Select date' : 'Select time' }}
                    </button>
                  </template>
                </date-picker>
              </div>
            </div>

            <div class="two-inputs">
              <div class="position-relative box-border">
                <div class="blue-button-text button-new-port fz13 t12" v-on:click="isShowModal = true">Create new port</div>
                <async-select-port
                    :key="keyRenderPorts"
                    class="drop block-from-inputs"
                    :label="(typeForModalVessel !== 'cargo') ? 'Redelivery' : 'Port of Discharge'"
                    v-model="redelivery_place"
                    :placeholder="''"
                ></async-select-port>
              </div>
              <div class="block-from-inputs">
                <p class="fz13 text-gray">Redelivery Date</p>
                <date-picker
                    v-model="redeliveryDate"
                    type="datetime"
                    class="datepick"
                    :show-time-panel="showTimePanel"
                    ref="datePickDue"
                    format="DD.MM.YYYY, HH:mm"
                    @close="showTimePanel=false"
                >
                  <template v-slot:footer>
                    <button class="mx-btn mx-btn-text" @click="showTimePanel= !showTimePanel">
                      {{ showTimePanel ? 'Select date' : 'Select time' }}
                    </button>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>

          <div class="block-from-inputs" v-if="typeForModalVessel === 'cargo'">
            <p class="fz13 text-gray">Cargo</p>
            <textarea maxlength="2000" v-model="cargoCommentEdit"></textarea>
          </div>
          <div class="position-relative box-border" v-if="typeForModalVessel !== 'ship'">
            <div class="blue-button-text button-new-port fz13" v-on:click="isShowModal = true">Create new port</div>
            <async-select-port
                :key="keyRenderPorts"
                class="drop block-from-inputs"
                :label="(typeForModalVessel !== 'cargo') ? 'Delivery' : 'Port of Loading'"
                v-model="delivery_place"
                :placeholder="''"
            ></async-select-port>
          </div>
          <div class="position-relative box-border" v-if="typeForModalVessel !== 'ship'">
            <div class="blue-button-text button-new-port fz13" v-on:click="isShowModal = true">Create new port</div>
            <async-select-port
                :key="keyRenderPorts"
                class="drop block-from-inputs"
                :label="(typeForModalVessel !== 'cargo') ? 'Redelivery' : 'Port of Discharge'"
                v-model="redelivery_place"
                :placeholder="''"
            ></async-select-port>
          </div>
          <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo'">
            <p class="fz13 text-gray">Period</p>
            <!--          <date-picker-->
            <!--              value-type="date"-->
            <!--              v-model="periodEdit"-->
            <!--              class="datepick"-->
            <!--              ref="datePickDue"-->
            <!--              format="DD.MM.YYYY"-->
            <!--              range-->
            <!--          >-->
            <!--          </date-picker>-->
            <input type="text" v-model="periodEdit">
          </div>
          <div class="block-from-inputs" v-if="typeForModalVessel === 'sublet'">
            <p class="fz13 text-gray">Laycan Date</p>
            <!--          <input type="text" class="h36" maxlength="300" v-model="laycanDateEdit">-->
            <date-picker
                value-type="format"
                placeholder=""
                class="datepickeer w100Proc"
                v-model="laycanDateEdit"
                range
                format="DD.MM.YYYY"
                popup-class="dateLeft0"
            ></date-picker>
          </div>

          <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo' && typeForModalVessel !== 'sublet'">
            <p class="fz13 text-gray">Hire rate</p>
            <input type="text" class="h36" maxlength="50" v-model="hireEdit" @input="hireEdit =checkNum(hireEdit)">
            <counterparties-drop
                class="valute-drop"
                title=""
                :list="valutaList"
                :removeSelectItemBeforeClick="false"
                @select="setValuta"
                :active-item="defaultActiveValuta"
            ></counterparties-drop>
          </div>
          <div class="block-from-inputs" v-if="typeForModalVessel !== 'cargo' && typeForModalVessel !== 'sublet'">
            <p class="fz13 text-gray">Operations Manager</p>
            <async-select-employees :height36="true" :is-multiple="false" v-model="assignedOperations" :permission-assigned="typeForModalVessel"></async-select-employees>
            <!--          <assignee-task class="assign-border" :permission-assigned="typeModal" :default-active="defaultActiveOperations" :one-user="true" ref="assigneeTask" @oneElementClick="setAssignedOperations"></assignee-task>-->
          </div>
          <div class="block-from-inputs" v-if="typeForModalVessel !== 'sublet'">
            <p class="fz13 text-gray">Chartering Manager</p>
            <async-select-employees :height36="true" :is-multiple="false" v-model="assignedChartering" :permission-assigned="typeForModalVessel"></async-select-employees>
            <!--          <assignee-task class="assign-border" :permission-assigned="typeModal" :default-active="defaultActiveChartering" :one-user="true" ref="assigneeTask2" @oneElementClick="setAssignedChartering"></assignee-task>-->
          </div>
          <!--        <counterparties-drop-->
          <!--            class="drop block-from-inputs"-->
          <!--            title="Broker"-->
          <!--            :list = "getClientsFormAddModal"-->
          <!--            @select="setActiveBroker"-->
          <!--            :active-item="defaultActiveBroker"-->
          <!--        ></counterparties-drop>-->
          <async-select
              class="drop block-from-inputs"
              id="counterpart32y"
              label="Broker"
              url="/client/dropdown"
              :placeholder="''"
              :params="{is_archived: false, ordering: 'company_name', has_broker: true}"
              watchKey="company_name"
              :allowEmpty="true"
              :closeOnSelect="true"
              v-model="activeBroker"
          >
            <template v-slot:additional-label>
              <button class="m-b-1 text-blue-dark fz13" v-on:click="showAddClient = true">Create new broker</button>
            </template>
          </async-select>
          <div class="block-from-inputs" v-if="typeForModalVessel === 'sublet'">
            <p class="fz13 text-gray">Chartering Manager</p>
            <assignee-task class="assign-border" :permission-assigned="typeModal" :default-active="defaultActiveChartering" :one-user="true" ref="assigneeTask1" @oneElementClick="setAssignedChartering"></assignee-task>
          </div>
          <div class="block-from-inputs">
            <p class="fz13 text-gray">Comment</p>
            <textarea maxlength="2000" v-model="commentEdit"></textarea>
          </div>
          <div class="block-from-inputs" :class="{ 'block-input_error': $v.attachmentsForModal.$error }">
            <p class="fz13 text-gray">Attachments <span class="text-red">*</span></p>
            <div class="file-block">
              <vue-dropzone :options="dropzoneOptions" :useCustomSlot=true ref="dropzone" id="dropzone" @vdropzone-file-added="test" @vdropzone-files-added="test" @vdropzone-success="test" @vdropzone-removed-file="test">
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                  <div class="subtitle">...or click to select a file from your computer</div>
                </div>
              </vue-dropzone>
            </div>
          </div>

        </div>
      </modal-slots>
    <add-modal
        :no-redirect="true"
        :isShowModal="isShowModal"
        @on-close="isShowModal = false"
        @added="reloadPorts"
    ></add-modal>
    <div class="w100Proc height" :class="{'position-relative z-100': $route.path.indexOf('mailing') >= 0}" :key="keyRenderAll">
      <router-view :vesselMode="true" @on-add-tab="addTab" @on-remove-tab="removeTab"></router-view>
    </div>
  </div>
</template>

<script>
import pageNavigation from '@/components/reuse/pageNavigation'
import addModal from '@/components/ports/list/add'

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import moment from 'moment';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ModalSlots from "@/components/modal/modal-slots";
import CounterpartiesDrop from "@/components/counterparties/counterpartiesAdd/counterpartiesDrop";

import DatePicker from 'vue2-datepicker';
import AssigneeTask from "@/components/tasks/manually-task-add/assignee-task";
import { validationMixin } from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import AsyncSelectPort from "@/components/reuse/asyncSelectPort";
import AsyncSelect from "@/components/reuse/asyncSelect";
import AsyncSelectEmployees from "@/components/tasks/async-select-employees";
import chosePeriodVesselBalances from "@/components/vessels/chosePeriodVesselBalances";
import MenuListVessel from "@/components/vessels/menu-list-vessel/menu-list-vessel";
import CounterpartiesAdd from "@/views/counterparties/counterpartiesAdd";

export default {
  mixins: [validationMixin],
  validations: {
    vesselNameEdit: {
      required
    },
    activeOwner: {
      required
    },
    CPDate: {
      required
    },
    attachmentsForModal: {
      required,
    }
  },
  components: {
    CounterpartiesAdd,
    addModal,
    MenuListVessel,
    AsyncSelectEmployees,
    AsyncSelect,
    AsyncSelectPort,
    pageNavigation,
    AssigneeTask,
    vueDropzone: vue2Dropzone, 
    DatePicker, 
    CounterpartiesDrop, 
    ModalSlots,
    chosePeriodVesselBalances
  },
  data() {
    return {
      keyRenderPorts: -23126631,
      isShowModal: false,
      keyRenderAll: -23213,
      showAddClient: false,
      showMenu: false,
      redelivery_place: null,
      delivery_place: null,
      typeModal: "sublet",
      navLinks: [
        {
          name: 'Cash Flow',
          link: `/vessels/${this.$route.params.id}/open/cash-flow`
        },
        {
          name: 'Vsl Details',
          link: `/vessels/${this.$route.params.id}/open/detail`
        },
        {
          name: 'Owners',
          link: `/vessels/${this.$route.params.id}/open/owners`
        },
        {
          name: 'Charterers',
          link: `/vessels/${this.$route.params.id}/open/charterers`
        },
        {
          name: 'Acc. Invoices',
          link: `/vessels/${this.$route.params.id}/open/accounting-invoice`
        },
        {
          name: 'Balances',
          link: `/vessels/${this.$route.params.id}/open/balances`
        },
        {
          name: 'Acc. Entries',
          link: `/vessels/${this.$route.params.id}/open/entries`
        },
        {
          name: 'LayTime',
          link: `/vessels/${this.$route.params.id}/open/lay`
        },
        {
          name: 'Reports',
          link: `/vessels/${this.$route.params.id}/open/reports`
        },
        {
          name: 'Vsl Emails',
          link: `/vessels/${this.$route.params.id}/open/mailing`
        },

      ],

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      regionList: [
        {name: "AG", id: "AG"},
        {name: "ARAG", id: "ARAG"},
        {name: "BALTIC", id: "BALTIC"},
        {name: "BLACK SEA", id: "BLACK SEA"},
        {name: "CARIBS", id: "CARIBS"},
        {name: "CASPIAN", id: "CASPIAN"},
        {name: "CENTRAL ATLANTIC", id: "CENTRAL ATLANTIC"},
        {name: "E AFRICA", id: "E AFRICA"},
        {name: "E AUSSIE", id: "E AUSSIE"},
        {name: "E MED", id: "E MED"},
        {name: "EC CAN", id: "EC CAN"},
        {name: "EC INDIA", id: "EC INDIA"},
        {name: "ECCA", id: "ECCA"},
        {name: "ECSA", id: "ECSA"},
        {name: "FEAST", id: "FEAST"},
        {name: "French Atl", id: "French Atl"},
        {name: "GREAT LAKES", id: "GREAT LAKES"},
        {name: "INDIAN OCEAN", id: "INDIAN OCEAN"},
        {name: "MID PACIFIC", id: "MID PACIFIC"},
        {name: "MIDNORTH ATLANTIC", id: "MIDNORTH ATLANTIC"},
        {name: "N CONT", id: "N CONT"},
        {name: "NCSA", id: "NCSA"},
        {name: "NEST PASSAGE", id: "NEST PASSAGE"},
        {name: "NOPAC", id: "NOPAC"},
        {name: "NW AFRICA", id: "NW AFRICA"},
        {name: "NWST PASSAGE", id: "NWST PASSAGE"},
        {name: "NZ", id: "NZ"},
        {name: "RED SEA", id: "RED SEA"},
        {name: "SE ASIA", id: "SE ASIA"},
        {name: "ST LAWRENCE", id: "ST LAWRENCE"},
        {name: "STH AFRICA", id: "STH AFRICA"},
        {name: "STH ANTARCTICA", id: "STH ANTARCTICA"},
        {name: "STH INDIAN OCEAN", id: "STH INDIAN OCEAN"},
        {name: "STH PACIFIC", id: "STH PACIFIC"},
        {name: "SW AFRICA", id: "SW AFRICA"},
        {name: "Spain Atl", id: "Spain Atl"},
        {name: "UK EIRE", id: "UK EIRE"},
        {name: "USEC", id: "USEC"},
        {name: "ASG", id: "ASG"},
        {name: "W AUSSIE", id: "W AUSSIE"},
        {name: "W MED", id: "W MED"},
        {name: "WC INDIA", id: "WC INDIA"},
        {name: "WCCA", id: "WCCA"},
        {name: "WCSA", id: "WCSA"},
      ],
      showModalEditClean: false,
      CPDate: "",
      showTimePanel: false,
      deliveryDate: "",
      redeliveryDate: "",
      errorCPDate: false,
      activeBroker: null,
      defaultActiveBroker: null,
      vesselNameEdit: "",
      builtEdit: "",
      dwtEdit: null,
      perEdit: null,
      defaultOwnerActive: null,
      activeOwner: null,
      deliveryActive: null,
      redeliveryActive: null,
      defaultDeliveryActive: null,
      defaultRedeliveryActive: null,
      activeValuta: null,
      defaultActiveValuta: {name: "USD", id: "USD"},
      laycanDateEdit: "",
      hireEdit: "",
      assignedOperations: null,
      assignedChartering: null,
      commentEdit: "",
      cargoCommentEdit: "",
      periodEdit: "",
      defaultActiveOperations: null,
      defaultActiveChartering: null,
      idShipForModal: null,
      ports: [],
      contractCreateModal: true,
      indexShipForModal: null,
      keyRender: -9999,
      contractValueName: "contracts",
      indexShipForModalInContract: null,
      typeForModalVessel: 'ship',
      dataShipModal: null,
      attachmentsForModal: [],
      typeOpenModal: 'new',
    }
  },
  watch: {
    keyRenderAll: function () {
      this.navLinks = [
        {
          name: 'Cash Flow',
          link: `/vessels/${this.$route.params.id}/open/cash-flow`
        },
        {
          name: 'Vsl Details',
          link: `/vessels/${this.$route.params.id}/open/detail`
        },
        {
          name: 'Owners',
          link: `/vessels/${this.$route.params.id}/open/owners`
        },
        {
          name: 'Charterers',
          link: `/vessels/${this.$route.params.id}/open/charterers`
        },
        {
          name: 'Acc. Invoices',
          link: `/vessels/${this.$route.params.id}/open/accounting-invoice`
        },
        {
          name: 'Balances',
          link: `/vessels/${this.$route.params.id}/open/balances`
        },
        {
          name: 'Acc. Entries',
          link: `/vessels/${this.$route.params.id}/open/entries`
        },
        {
          name: 'LayTime',
          link: `/vessels/${this.$route.params.id}/open/lay`
        },
        {
          name: 'Reports',
          link: `/vessels/${this.$route.params.id}/open/reports`
        },

      ]
    },
    getWatchOpenModalVoyage: function () {
      this.typeModal = "voyage"
      this.typeOpenModal = 'new'
      this.openModalClean('new', 'cargo');
    },
    getWatchModalVoyageCharterer: function () {
      this.typeModal = "VOYAGE_CHARTERER"
      this.typeOpenModal = 'new'
      this.openModalClean('new', 'VOYAGE_CHARTERER');
    },
    getWatchOpenModalSublet: function () {
      this.typeModal = "sublet"
      this.typeOpenModal = 'new'
      this.openModalClean('new', 'sublet');
    },
    getDataContractV: function () {
      this.info = this.getDataContractV;
    },
    getEmitEditContract: function () {
      this.typeModal = "contract"
      this.typeOpenModal = 'edit'
      this.openModalClean('edit', 'ship', this.info);
    },
    getWatchOpenModalVoyageChartererEdit: function () {
      this.typeModal = "VOYAGE_CHARTERER"
      this.typeOpenModal = 'edit'
      this.openModalClean('edit', 'VOYAGE_CHARTERER', this.getDataContractForEdit);
    },
    getWatchOpenModalVoyageEdit: function () {
      this.typeModal = "voyage"
      this.typeOpenModal = 'edit'
      this.openModalClean('edit', 'cargo', this.getDataContractForEdit);
    },
    getWatchOpenModalSubletEdit: function () {
      this.typeModal = "sublet"
      this.typeOpenModal = 'edit'
      this.openModalClean('edit', 'sublet', this.getDataContractForEdit);
    },
    $route: function () {
      if(this.$route.path.indexOf('cash-flow') >= 0) {
        this.showMenu = true;
        this.setStatesLeftMenu(true);
      } else {
        this.showMenu = false;
        this.setStatesLeftMenu(false);
      }
    }
  },
  computed: {
    ...mapState({
      clients: state => state.counterparties.clientsDrop,
      valutaList: state => state.payment.valutaList
    }),
    ...mapGetters(["getDataContractForEdit", "getWatchOpenModalVoyageEdit", 'getWatchOpenModalVoyageChartererEdit', "getWatchOpenModalSubletEdit",  "getEmitEditContract", "getDataContractV","getPortsForVessel","getResponseContract", "getWatchOpenModalVoyage", "getWatchModalVoyageCharterer", "getWatchOpenModalSublet", 'getPermissionsByType']),
    getClientsFormAddModal() {
      let clients = [];
      clients = this.clients.map(a=> {
        return {id: a.id, name: a.company_name}
      })
      return clients
    },
    getNavByPermissions() {
      return this.navLinks.filter(el=>{
        if(el.name == 'Balances'){
          if(this.getPermissionsByType('show_tab_balances_in_the_vessel_contract')) return true
          return false
        }
        if(el.name == 'Acc. Invoices'){
          if(this.getPermissionsByType('show_tab_acc_invoices_in_the_vessel_contract')) return true
          return false
        }
        if(el.name == 'Acc. Entries'){
          if(this.getPermissionsByType('show_journal_entries_section_in_vessel')) return true
          return false
        }
        return true
      })
    },
    getLinkToCreateClient() {
      if(this.typeForModalVessel === 'ship') return `/counterparties/add-counterparties?from=vessels&vessel=${this.getDataContractV?.id}`
      else if(this.typeForModalVessel === 'cargo' && this.typeOpenModal == 'new') return `/counterparties/add-counterparties?from=vessels&vessel=${this.getDataContractV?.id}&subcontract=voyage`
      else if(this.typeForModalVessel === 'sublet' && this.typeOpenModal == 'new') return `/counterparties/add-counterparties?from=vessels&vessel=${this.getDataContractV?.id}&subcontract=sublet`
      else return `/counterparties/add-counterparties?from=vessels&vessel=${this.getDataContractV?.id}&subcontract=${this.getDataContractForEdit?.id}`
    },

  },
  methods: {
    ...mapActions(["axiosChangeContract", "axiosCreatContractForShip", "axiosChangeContractForShip", "axiosChangeContract", "axiosGetClientsForDropdown", "getAxiosPortsForVessel", "axiosContractRemoveFile", "axiosFileDelete", "axiosContractAddFiles", "axiosGetContractById", "axiosGetClientsByIdReturnResponse", "axiosGetContractByIdReturnResponse"]),
    ...mapMutations(["SetChangeNameVesselEmitForListLeftMenu", "changeDataMainShip", "hideLoad", "showLoad","setStatesLeftMenu", "setResponseContract", "refreshContractStateAdd", "setDataContract"]),
    disabledDates(date) {
      let array = this.getDataContractV.cargo_contracts.filter(a=> a.id !== this.$route.params.subId).map(a=> {
        return a.cp_date
      })
      return array.indexOf(moment(date).format('YYYY-MM-DD')) >= 0
      // const today = new Date();
      // today.setHours(0, 0, 0, 0);
      //
      // return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
    },
    reloadPorts() {
      this.keyRenderPorts++;
    },
    leftMenuChangeVisible() {
      this.showMenu = !this.showMenu;
      this.setStatesLeftMenu(this.showMenu);
    },
    test() {
      this.attachmentsForModal = this.$refs.dropzone.getAcceptedFiles();
      // console.log(this.attachmentsForModal)
      this.$v.attachmentsForModal.touch();
    },
    checkNum(num) {
      if(num.indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");

        if(num2.length > 4) {
          num2 = num2.substring(0, 4);
        }
        num = num1 + "." + num2;
      } else {
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    checkNumIn(num) {
      return num.replace(/[^0-9]/g, "");
    },

    openModalClean(typeModal, type, data) {
      this.typeForModalVessel = 'ship'
      // if(this.typeModal)
      if(type !== undefined && type !== null) {
        this.typeForModalVessel = type
      }
      this.showModalEditClean = true;
      let dataShip = null;
      if(typeModal !== 'new') {
        dataShip = data;
      }
      this.vesselNameEdit = this.info.name;
      this.builtEdit = this.info.built;
      this.dwtEdit = this.info.dwt;
      this.perEdit = this.info.percentage;
      console.log('openModal', data, this.builtEdit, this.dwtEdit,this.perEdit )
      // dataShip = this.contracts[index];
      if(this.info.owner !== null) {
        this.activeOwner = this.info.owner;
      }
      this.attachmentsForModal = [];
      if(dataShip !== null) {
        this.attachmentsForModal = dataShip.files;
        this.contractCreateModal = false;
        this.vesselNameEdit = dataShip.name;
        this.builtEdit = dataShip.built;
        this.dwtEdit = dataShip.dwt;
        this.perEdit = dataShip.percentage;
        this.cargoCommentEdit = dataShip.cargo_comment;
        this.commentEdit = dataShip.comment;
        this.laycanDateEdit = dataShip.laycan_date.length && (''+dataShip.laycan_date).indexOf('null') === -1 ? JSON.parse(''+dataShip.laycan_date) : '';
        if(dataShip.redelivery_place.length !== 0 || dataShip.delivery_place.length !== 0) {
          if(dataShip.delivery_place.length !== 0) {
            this.delivery_place = dataShip.delivery_place[0]
            this.defaultDeliveryActive = {name: dataShip.delivery_place[0].name + ": " + dataShip.delivery_place[0].country, id: dataShip.delivery_place[0].id, namePort: dataShip.delivery_place[0].name, countryPort: dataShip.delivery_place[0].country}
            // return list.map(a=>{return {name: a.name + ": " + a.country, id: a.id, namePort: a.name, countryPort: a.country}})

          }
          if(dataShip.redelivery_place.length !== 0) {
            this.redelivery_place = dataShip.redelivery_place[0];
            this.defaultRedeliveryActive = {name: dataShip.redelivery_place[0].name + ": " + dataShip.redelivery_place[0].country, id: dataShip.redelivery_place[0].id, namePort: dataShip.redelivery_place[0].name, countryPort: dataShip.redelivery_place[0].country}
            // this.defaultRedeliveryActive = {name: dataShip.redelivery_place[0].name, id: dataShip.redelivery_place[0].id}
          }
        } else {
          // if(dataShip.delivery_place.length !== 0) {
          //   this.defaultDeliveryActive = {name: dataShip.delivery_place[0].name+ ": " + dataShip.delivery_place[0].country, id: dataShip.delivery_place[0].id}
          // }
          // if(dataShip.redelivery_place.length !== 0) {
          //   this.defaultRedeliveryActive = {name: dataShip.redelivery_place[0].name+ ": " + dataShip.redelivery_place[0].country, id: dataShip.redelivery_place[0].id}
          // }
        }
        if(dataShip.broker !== null && dataShip.broker !== undefined) {
          this.activeBroker = dataShip.broker
        }

        // console.log("owner")
        // console.log(dataShip.owner)

        if(dataShip.owner !== null) {
          // console.log("dataShip.owner")
          this.activeOwner = dataShip.owner
          // console.log(this.defaultOwnerActive)
        }
        if(dataShip.cp_date !== null) {
          if(dataShip.cp_date.length !== 0) {
            this.CPDate = (new Date(dataShip.cp_date));
          }
        }
        if(dataShip.period !== null) {
          let period = dataShip.period
          this.periodEdit = period
        }
        if(dataShip.delivery !== null) {
          this.deliveryDate = (new Date(dataShip.delivery));
        }
        if(dataShip.redelivery !== null) {
          this.redeliveryDate = (new Date(dataShip.redelivery));
        }
        this.laycanDateEdit = dataShip.laycan_date ? JSON.parse(''+dataShip.laycan_date) : '';
        this.hireEdit = dataShip.hire_rate;
        if(dataShip.currency !== null) {
          this.defaultActiveValuta = {name: dataShip.currency, id: dataShip.currency}
        }
        if(dataShip.chartering_manager !== null && dataShip.chartering_manager !== undefined) {
          this.assignedChartering = dataShip.chartering_manager;
        }
        if(dataShip.operation_manager !== null && dataShip.operation_manager !== undefined) {
          this.assignedOperations = dataShip.operation_manager;
        }
        // console.log(this.defaultActiveChartering)
      } else {
        this.contractCreateModal = true;
      }
    },
    validateCheck() {
      this.$v.$touch()
      if (this.$v.$invalid)
        return false
      return true
    },

    setAssignedOperations(data) {
      // console.log(data);
      this.assignedOperations = data;
    },
    setAssignedChartering(data) {
      //console.log('log111', data);
      this.assignedChartering = data;
    },
    selectOwner(data) {
      this.activeOwner = data;
    },
    setValuta(data) {
      this.activeValuta = data;
    },
    setDeliveryPlace(data) {
      this.deliveryActive = data;
    },
    setRedeliveryPlace(data) {
      this.redeliveryActive = data;
    },
    setActiveBroker(data) {
      this.activeBroker = data;
    },
    applyCleanFixed() {
      this.$v.$touch()
      this.axiosBool = true;
      let cp_date = null;
      if(this.CPDate !== null && (""+this.CPDate).length !== 0) {
        if(this.CPDate.length !== 0) {
          cp_date = moment(this.CPDate).format("YYYY-MM-DD")
          this.errorCPDate = false;
        } else {
          this.errorCPDate = true;
        }
      } else {
        this.errorCPDate = true;
      }
      if (!this.errorCPDate) {
        let currency = "";
        if(this.activeValuta !== null) {
          currency = this.activeValuta.name
        }
        let owners = null;
        if(this.activeOwner !== null) {
          // owners = this.activeOwner.map(a=> {return a.id});
          owners = this.activeOwner.id;
        }
        let broker = "";
        if(this.activeBroker !== null) {
          broker = this.activeBroker.id;
        }
        // let delivery = "";
        // if(this.deliveryActive !== null) {
        //   delivery = this.deliveryActive.id;
        // }
        let redelivery = [];
        if(this.redelivery_place !== null) {
          redelivery.push(this.redelivery_place.id);
        }
        let deliveryPlace = [];
        if(this.delivery_place !== null) {
          deliveryPlace.push(this.delivery_place.id);
        }
        let period = this.periodEdit;
        let hire_rate = null;
        if((""+this.hireEdit).length !== 0) {
          hire_rate = this.hireEdit;
        }

        let operation_manager = null;
        if(this.assignedOperations?.id) {
          operation_manager = this.assignedOperations.id;
        }
        let chartering_manager = null;

        if(this.assignedChartering?.id) {
          chartering_manager = this.assignedChartering.id;
        }
        let delivery = null;
        if(this.deliveryDate !== null && (""+this.deliveryDate).length !== 0) {
          // delivery = moment(this.deliveryDate).format("YYYY-MM-DDThh:mm") + "Z";
          delivery = this.deliveryDate
        }
        let redeliveryDate = null;
        if(this.redeliveryDate !== null && (""+this.redeliveryDate).length !== 0) {
          // redeliveryDate = moment(this.redeliveryDate).format("YYYY-MM-DDThh:mm") + "Z";
          redeliveryDate = this.redeliveryDate
        }
        let name = this.vesselNameEdit;
        let dwt = this.dwtEdit;
        if((""+this.dwtEdit).length === 0) {
          dwt = null
        }
        // let build = null;
        // if(this.builtEdit !== null) {
        //   if(this.builtEdit.length !==0) {
        //     build = this.builtEdit;
        //   }
        // }
        let build = null;
        if(this.builtEdit !== null) {
          if(this.builtEdit.length !==0) {
            build = this.builtEdit;
          }
        }

        let percentage = this.perEdit;
        if((""+this.perEdit).length === 0) {
          percentage = null;
        }
        let laycan_date = this.laycanDateEdit.length && this.laycanDateEdit[0] ? JSON.stringify(this.laycanDateEdit) : '';
        let comment = this.commentEdit;
        let cargo = this.cargoCommentEdit;
        let ship = null;
        let task = null;
        let files = this.$refs.dropzone.getAcceptedFiles();
        let fileContract = [];

        let contract_type = 'CARGO';

        if(this.typeForModalVessel === 'ship') {
          contract_type = "SHIP";
        }
        if(this.typeForModalVessel === 'cargo') {
          contract_type = "CARGO";
        }
        if(this.typeForModalVessel === 'sublet') {
          contract_type = "SUBLET";
        }
        if(this.typeForModalVessel === 'VOYAGE_CHARTERER') {
          contract_type = 'VOYAGE_CHARTERER'
        }
        console.log('contract_type', contract_type)

        let dataShip = null;
        if(contract_type === 'SHIP') {
          dataShip = this.info;
        } else {
          dataShip = this.getDataContractForEdit
        }
        console.log('contract_type', contract_type)

        if (dataShip !== null && contract_type === 'SHIP') {
          contract_type = dataShip.contract_type;
        }
        console.log('contract_type', contract_type)

        if(dataShip !== null) {
          fileContract = dataShip.files;
        }
        let form = {
          "name": name,
          "dwt": dwt,
          "built": build,
          "percentage": percentage,
          "cp_date": cp_date,
          "delivery": delivery,
          "redelivery": redeliveryDate,
          "period": period,
          "laycan_date": laycan_date,
          "hire_rate": hire_rate,
          "currency": currency,
          "comment": comment,
          "cargo_comment": cargo,
          "ship": ship,
          "task": task,
          "operation_manager": operation_manager,
          "chartering_manager": chartering_manager,
          "broker": broker,
          "owners": owners,
          "delivery_place": deliveryPlace,
          "redelivery_place": redelivery,
          "filesContract": fileContract,
          "files": files,
          "contract_type": contract_type,
        }
        console.log(form)
        if(this.validateCheck()) {
          this.showLoad()
          this.showModalEditClean = false;
          if(dataShip !== null && this.typeOpenModal !== 'new') {
            form["id"] = dataShip.id;
            this.axiosChangeContractForShip(form).then(()=> {
              this.refreshContractStateAdd();
              this.closeModalEditClean();
              // this.changeDataMainShip(JSON.parse(JSON.stringify(dataShip)))
              this.hideLoad();
              this.getDataContract(true)
              this.SetChangeNameVesselEmitForListLeftMenu({id: form.id, name: form.name, cp_date: form.cp_date})
            }).catch(()=> {
              this.hideLoad()
              this.axiosBool = false;
              this.closeModalEditClean();
            })
          } else {
            this.axiosCreatContractForShip(form).then(res=> {
              let cargo_contracts = this.info.cargo_contracts.map(a=> {return a.id})
              cargo_contracts.push(res.data.id);
              let data = {
                "cargo_contracts": cargo_contracts
              }
              // let id = res.data.id
              this.closeModalEditClean();
              this.hideLoad()
              this.axiosChangeContract({id: this.info.id, data: data})
              .then(()=> {
                this.refreshContractStateAdd();
              });
            }).catch(()=> {
              this.axiosBool = false;
              this.closeModalEditClean();
              this.hideLoad()
            })
          }
        }

      }
    },

    closeModalEditClean() {
      this.typeOpenModal = 'new'
      this.indexShipForModalInContract = null;
      this.contractValueName = 'contracts';
      this.contractCreateModal = true;
      // if(!this.axiosBool) {
      this.showModalEditClean = false;
      this.errorCPDate = false;
      this.CPDate = null;
      this.deliveryDate = null;
      this.redeliveryDate = null;
      this.activeBroker = null;
      this.defaultActiveBroker = null;
      this.vesselNameEdit = "";
      this.builtEdit = "";
      this.dwtEdit = null;
      this.perEdit = null;
      this.defaultOwnerActive = null;
      this.activeOwner = null;
      this.redelivery_place = null;
      this.delivery_place = null;
      this.deliveryActive = null;
      this.delivery_place = null;
      this.redelivery_place = null;
      this.redeliveryActive = null;
      this.defaultDeliveryActive = null;
      this.defaultRedeliveryActive = null;
      this.activeValuta = null;
      this.defaultActiveValuta = {name: "USD", id: "USD"};
      this.laycanDateEdit = "";
      this.hireEdit = "";
      this.assignedOperations = null;
      this.assignedChartering = null;
      this.commentEdit = "";
      this.cargoCommentEdit = "";
      this.periodEdit = "";
      this.defaultActiveOperations = null;
      this.defaultActiveChartering = null;
      this.idShipForModal = null;
      if(this.$refs.dropzone)
        this.$refs.dropzone.removeAllFiles(true);
      if(this.$refs.assigneeTask)
        this.$refs.assigneeTask.clearData();
      if(this.$refs.assigneeTask1)
        this.$refs.assigneeTask1.clearData();
      if(this.$refs.assigneeTask2)
        this.$refs.assigneeTask2.clearData();
    },

    getPortsForDrop(list) {
      return list.map(a=>{return {name: a.name + ": " + a.country, id: a.id, namePort: a.name, countryPort: a.country}})
    },

    addTab(tab) {
      this.$set(this.navLinks, this.navLinks.length, tab)
    },
    removeTab(tab) {
      let tabIndex = this.navLinks.findIndex(el => {
        return el.link == tab.link
      })
      this.navLinks.splice(tabIndex, 1)
    },
    getDataContract(loader) {
      if(loader) {
        this.showLoad()
      }
      // let textRoute = this.$route.path;
      let id = this.$route.params.id;
      this.axiosGetContractById(id).then(async res=> {
        this.info = res;
        this.setDataContract(this.info);
        if(loader) {
          this.hideLoad()
        }
      });

    },
  },
  mounted() {
    if(!this.getPortsForVessel.length) {
      this.getAxiosPortsForVessel().then(()=> {
        this.ports = this.getPortsForDrop(this.getPortsForVessel);
      });
    } else {
      this.ports = this.getPortsForDrop(this.getPortsForVessel);
    }
    // if(!this.clients.length) {
    //   this.axiosGetClientsForDropdown();
    // }
    this.info = this.getDataContractV;
    if(this.info === null) {
      this.getDataContract();
    }
    if(this.$route.path.indexOf('cash-flow') >= 0) {
      this.showMenu = true;
      this.setStatesLeftMenu(true);
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-edit-clean {
  .modal-edit-clean-content {
    padding: 4px 24px 20px 24px;
    .file-block {
      width: 100%;
      box-sizing: border-box;
    }
    .file-block {
      /deep/ {
        .vue-dropzone {
          display: flex;
          padding: 0;
          padding-top: 41px;
          flex-direction: column;
          min-height: max-content;
          border: 1px solid var(--color-border);
          border-radius: 4px;
          align-items: center;
          position: relative;
          &:hover {
            background-color: var(--color-input);
          }
          &::before {
            border-bottom: 1px solid var(--color-border);
            content: "+ Attach files";
            position: absolute;
            top: 0px;
            font-size: 13px;
            font-weight: 600;
            color: var(--color-blue-dark);
            //display: none;
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            //background-color: #fff;
            //&:hover {
            //  background-color: var(--color-input) !important;
            //}
          }
        }


        .dz-preview {
          &:hover {
            background-color: var(--color-input);
          }
          width: 100%;
          margin: 0;
          display: flex;
          align-items: center;
          height: 38px;
          min-height: 38px;
          max-height: 38px;
          box-sizing: border-box;
          .dz-image {
            margin-left: 6px;
            max-width: 30px;
            max-height: 30px;
            img {
              border-radius: 3px;
              filter: none !important;
              width: 100%;
              height: 100%;
            }
          }
          .dz-details {
            margin-left: 12px;
            opacity: 1;
            position: static;
            padding: 0;
            min-width: max-content;
            max-height: max-content;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: row-reverse;
            width: 60%;
            overflow: hidden;
            .dz-size {
              margin: 0;
              margin-left: 12px;
              * {
                padding: 0;
                font-size: 13px;
                color: #9AA3B0;
              }
            }
            .dz-filename {
              //width: 70%;
              //overflow: hidden;
              * {
                font-size: 13px;
                color: #4B4B4B;
                padding: 0;
                width: 50%;
                white-space: nowrap; /* Запрещаем перенос строк */
                overflow: hidden; /* Обрезаем все, что не помещается в область */
                text-overflow: ellipsis; /* Добавляем многоточие */
              }
            }
          }
          .dz-remove {
            font-size: 0;
            opacity: 1;
            position: static;
            width: 0px;
            height: 0px;
            bottom: auto;
            margin: 0;
            display: flex;
            align-items: center;
            padding: 0;
            border: none !important;
            &::after {
              content: url("../../../assets/ic_close24.svg");
              color: #2C2C2C;
              position: absolute;
              right: 6px;
              top: calc(50% - 12px);
            }
          }
          .dz-error-message {
            z-index: 100;
            position: absolute;
            top: -15px;
            display: none;
          }
          .dz-success-mark {
            z-index: 100;
            position: absolute;
            top: -15px;
            display: none;
          }
          .dz-progress {
            opacity: 1;
            right: 33px;
            left: auto;
            top: auto;
            margin: 0;
            width: 120px;
            height: 4px;
            background: #DEE3EA;
            .dz-upload {
              background: var(--color-blue-dark)
            }
          }
        }
      }
    }
    .valute-drop {
      width: max-content;
      /deep/ {
        p {
          display: none;
        }
        .btn {
          background: transparent !important;
          background-color: transparent !important;
          border: none !important;
        }
        .dropdown-menu {
          width: max-content;
          left: auto !important;
          right: 0 !important;
        }
      }

      padding: 0;
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0px;
    }

    .two-inputs {
      display: flex;
      justify-content: space-between;
      &>* {
        width: calc((100%/2) - 4px);
      }
    }
    .block-from-inputs {
      position: relative;
      margin-top: 12px !important;
      p {
        margin-bottom: 4px;
      }
      textarea {
        height: 57px;
      }
    }
    .datepick {
      /deep/ {
        input {
          height: 36px;
        }
      }
      width: 100%;
    }
    .block-with-button {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .block-input_error{
    &>input {
      border-color: #f57f6c !important;
    }
    /deep/ {
      .multiselect__tags {
        border-color: #f57f6c !important;
      }
      .vue-dropzone {
        border-color: #f57f6c !important;
      }
      .dropdown-toggle{
        border-color: #f57f6c !important;
      }
      .mx-input {
        border-color: #f57f6c !important;
      }
    }
  }

  .assign-border {
    /deep/ {
      .button-drop {
        border-radius: 4px !important;
        height: 36px !important;
      }
    }
  }

}
@media (max-width: 1680px) {
  .page-navigation {
    /deep/ {
      .page_navigation__link:not(:first-of-type) {
        margin-left: 12px;
      }
    }
  }
}
.page-navigation {
  margin-left: 40px;
}
.button-menu {
  position: absolute;
  top: 12px;
  left: 24px;
  svg path {
    fill: var(--color-blue-dark)
  }
}
.height {
  min-height: calc(100vh - 146px);
}
.button-new-port {
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 10;
  cursor: pointer;
}
.t12 {
  top: 12px;
}

.add-client-modal {
  height: calc(100vh - 56px);
  width: 100vw;
  z-index: 400;
  position: absolute;
  top: 56px;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  /deep/ {
    .counterparties-add_bread {
      display: none;
    }
  }
}
</style>