<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="search-block">
        <button class="close-modal" v-on:click="hideModal">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.25 11.2003H6.28787L11.5 6.37938C11.8 6.07458 11.8116 5.56819 11.5259 5.24819C11.2405 4.9286 10.7657 4.9158 10.4654 5.22059L4.4395 10.8687C4.15637 11.1711 4 11.5727 4 12.0003C4 12.4275 4.15637 12.8295 4.45262 13.1451L10.4654 18.6584C10.6105 18.806 10.7965 18.8792 10.9825 18.8792C11.1805 18.8792 11.3785 18.796 11.5259 18.6308C11.8116 18.3108 11.8 17.8048 11.5 17.5L6.26425 12.8003H19.25C19.664 12.8003 20 12.4419 20 12.0003C20 11.5587 19.664 11.2003 19.25 11.2003Z" fill="#2C2C2C"/>
          </svg>
        </button>
        <input type="text" placeholder="Search by employees" v-model="searchName">
        <button class="clear-input" v-on:click="clearSearch">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
            <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
          </svg>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "search-employees-modal",
  data() {
    return {
      show: false,
      searchName: "",
    }
  },
  methods: {
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
      this.searchName = "";
    },
    clearSearch() {
      this.searchName = "";
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
  .open-modal {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal {
    padding: 24px 15px;
    box-sizing: border-box;
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 250;
    background-color: #FFFFFF;
  }
  .search-block {
    position: relative;
    display: flex;
    align-items: center;
    input {
      height: 36px;
      padding-left: 40px;
      padding-right: 32px;
    }
    .close-modal {
      position: absolute;
      left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .clear-input {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 4px;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
</style>