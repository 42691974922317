<template>
  <div class="calc_routes_table border-t border-gray-text">
    <div class="border-r border-gray-text flex justify-center items-center">
      <button class="fz0 cursor-grab handle">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.432 7.468a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 0 1 2.6 0ZM16.268 7.468a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 0 1 2.6 0Z" fill="#9AA3B0"/><circle cx="9.132" cy="12.097" r="1.3" fill="#9AA3B0"/><circle cx="14.968" cy="12.097" r="1.3" fill="#9AA3B0"/><path d="M10.432 16.73a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 0 1 2.6 0ZM16.268 16.73a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 0 1 2.6 0Z" fill="#9AA3B0"/></svg>
      </button>
    </div>
    <div class="border-r border-gray-text flex justify-center items-center">
      <button class="fz0" @click="$emit('on-add')">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.222 11.222h-4.444V6.778a.778.778 0 0 0-1.556 0v4.444H6.778a.778.778 0 0 0 0 1.556h4.444v4.444a.778.778 0 0 0 1.556 0v-4.444h4.444a.778.778 0 0 0 0-1.556Z" fill="#9AA3B0"/></svg>
      </button>
    </div>
    <div class="border-r border-gray-text">
      <async-select
        class="w-full"
        :id="'port-'+route.ordering_index"
        :allowEmpty="false"
        :closeOnSelect="true"
        :clearOnSelect="true"
        v-model="route.port"
        @on-select="$emit('on-select-port')"
        url="/port/dropdown"
        :params="{is_archive: false}"
        watchKey="name"
        afterWatchKeyBrackets="country"
        placeholder="Add port or canal"
      />
    </div>
    <div class="border-r border-gray-text">
      <default-select
        class="w-full capitalize-child"
        :id="'status-'+route.ordering_index"
        :options="['loading', 'discharging', 'passing', 'bunkering']"
        trackBy=""
        :searchable="false"
        :closeOnSelect="true"
        v-model="route.status"
        watchKey="name"
      />
    </div>
    <div class="border-r border-gray-text">
      <input
        type="text"
        class="p-y-1.5 p-x-2 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="route.dist"
        @keydown="onlyNumber($event)"
        @keyup.enter="$event.target.blur()"
        @blur="onBlurInput('dist')"
      >
    </div>
    <div class="border-r border-gray-text relative">
      <input
        type="text"
        class="p-y-1.5 p-x-2 border-0 bg-transparent rounded-none fz13 text-gray-light"
        v-model.number.lazy="route.seca"
        @keydown="onlyNumber($event)"
        @keyup.enter="$event.target.blur()"
        @blur="onBlurInput('seca')"
      >
      <checkbox class="absolute right-2 centered-y" @input="$emit('changeHasSeca')" v-model="route.has_seca"/>
    </div>
    <div class="flex justify-center items-center">
      <button class="fz0" @click="$emit('on-delete')">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.636 4.366a.225.225 0 0 0-.227.223v.675h3.182v-.675a.225.225 0 0 0-.227-.223h-2.728Zm4.319.898v-.675A1.59 1.59 0 0 0 13.364 3h-2.728a1.59 1.59 0 0 0-1.59 1.589v.675H5.181a.682.682 0 0 0 0 1.366h1.136v9.876A2.497 2.497 0 0 0 8.818 19h6.364c1.377 0 2.5-1.113 2.5-2.494V6.63h1.136a.682.682 0 0 0 0-1.366h-3.864ZM7.681 6.63v9.876c0 .62.505 1.128 1.136 1.128h6.364c.63 0 1.136-.509 1.136-1.128V6.63H7.682Zm2.954 2.256c.377 0 .682.306.682.683v4.981a.682.682 0 1 1-1.363 0V9.57c0-.378.305-.684.681-.684Zm2.728 0c.376 0 .681.306.681.683v4.981a.682.682 0 1 1-1.363 0V9.57c0-.378.305-.684.682-.684Z" fill="#9AA3B0"/></svg>
      </button>
    </div>
  </div>
</template>

<script>
import AsyncSelect from '@/components/reuse/asyncSelect'
import DefaultSelect from '@/components/reuse/defaultSelect'
import Checkbox from '@/components/reuse/checkbox'
import { mapActions } from 'vuex'

export default {
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  components: {
    AsyncSelect,
    DefaultSelect,
    Checkbox
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['onlyNumber']),
    onBlurInput(key) {
      if(this.route[key] === '')
        this.route[key] = 0
    }
  },
}
</script>