<template>
  <div class="counterparties-drop">
    <p class="counterparties-drop_title text-gray">{{title}}</p>
    <b-dropdown ref="dropdown" :disabled="isDisabled" @hide="hideEvent">
      <template #button-content>
        <span class="button-text" v-html="printDropResult"></span>
        <span class="button-drop"><svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/></svg></span>
      </template>
      <div class="search" v-if="list.length > 14">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1535 14.2913L10.9827 10.1205C11.7906 9.12262 12.277 7.85457 12.277 6.47348C12.277 3.27251 9.67246 0.667969 6.47149 0.667969C3.27053 0.667969 0.666016 3.27248 0.666016 6.47345C0.666016 9.67442 3.27056 12.279 6.47152 12.279C7.85261 12.279 9.12066 11.7925 10.1186 10.9846L14.2894 15.1554C14.4085 15.2746 14.565 15.3345 14.7214 15.3345C14.8779 15.3345 15.0343 15.2746 15.1535 15.1554C15.3924 14.9165 15.3924 14.5303 15.1535 14.2913ZM6.47152 11.0567C3.94398 11.0567 1.88824 9.00099 1.88824 6.47345C1.88824 3.94591 3.94398 1.89016 6.47152 1.89016C8.99906 1.89016 11.0548 3.94591 11.0548 6.47345C11.0548 9.00099 8.99903 11.0567 6.47152 11.0567Z" fill="#2C2C2C"/></svg>
        <input type="text" v-model="searchText" placeholder="Search" autocomplete="off">
      </div>
      <div class="map-block">
        <button class="blue-button-text fz13 fw500" v-on:click="clickMap">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9205 4.37895C20.1266 4.51848 20.25 4.75114 20.25 5V17C20.25 17.3067 20.0633 17.5825 19.7785 17.6964L14.7785 19.6964C14.5997 19.7679 14.4003 19.7679 14.2215 19.6964L9.5 17.8078L4.77854 19.6964C4.54747 19.7888 4.28561 19.7606 4.07953 19.6211C3.87345 19.4815 3.75 19.2489 3.75 19V7C3.75 6.69333 3.93671 6.41754 4.22146 6.30365L9.22146 4.30365C9.40027 4.23212 9.59973 4.23212 9.77854 4.30365L14.5 6.19223L19.2215 4.30365C19.4525 4.21122 19.7144 4.23943 19.9205 4.37895ZM13.75 7.50778L10.25 6.10778V16.4922L13.75 17.8922V7.50778ZM15.25 17.8922L18.75 16.4922V6.10778L15.25 7.50778V17.8922ZM8.75 16.4922V6.10778L5.25 7.50778V17.8922L8.75 16.4922Z" fill="#094172"/>
          </svg>
          Select on the map
        </button>
      </div>
      <li v-if="searchText && !onSearch.length"><span class="dropdown-item text-dark">Nothing found</span></li>

      <li v-if="isSelectedPrev && !list.length"><span class="dropdown-item text-dark">Selected type has`t sybtypes</span></li>
      <li v-else-if="!list.length"><span class="dropdown-item text-dark">Please select type</span></li>
      <li v-for="item in onSearch" :key="item.id">
       <a href="#" class="dropdown-item text-dark" @click.prevent="select(item)">{{item.name}}</a>
        <svg v-if="checkedSelected(item)" class="dropdown-check" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z" fill="#319FEF"/></svg>
      </li>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "dropdown-selected-region",
  props: {
    list: {
      type: Array
    },
    activeItem: {
      default: null
    },
    title: {
      type: String,
      default: 'Field'
    },
    isSelectedPrev: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    activeItem() {
      // console.log('this.activeItem ', this.activeItem);
      if(this.activeItem) {
        //this.select(this.activeItem)
        if(this.isMultiple) {
          // this.activeItem.map(item => {
          //   this.select(item)
          // });
          this.selectItems = this.activeItem
          // console.log('this.selectItems')
          // console.log(this.selectItems)
        }else{
          this.select(this.activeItem)
        }
      }
    }
  },
  data() {
    return {
      selectItem: '',
      selectItemInObj: {},
      selectItems: [],
      searchText: '',
      openMap: false,
    }
  },
  computed: {
    printDropResult() {
      if(this.isMultiple) {
        let result = ''
        if(this.selectItems.length > 2){
          result = this.selectItems[0].name + `&nbsp;/&nbsp;<b>+${this.selectItems.length - 1}</b>`
        } else if(this.selectItems.length) {
          for (let i = 0; i < this.selectItems.length; i++) {
            const el = this.selectItems[i];
            if(i == 0 && this.selectItems.length > 1) {
              result += el.name + '&nbsp;/&nbsp;'
            }else{
              result += el.name
            }
          }
        }
        return result
      }else{
        return this.selectItem
      }
    },
    onSearch() {
      if(this.searchText){
        return this.list.filter(item => {
          return item.name.toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0
        })
      }
      return this.list
    }
  },
  methods: {
    clickMap() {
      this.openMap = true
      this.$emit("clickMap")
    },
    openList() {
      // console.log(this.$refs.dropdown)
      this.$refs.dropdown.show(true);
    },
    hideEvent() {
        this.$emit("closeDrop", this.openMap);
        this.openMap = false;
    },
    checkedSelected(item) {
      // if(item.id !== null && item.id !== undefined) {
      //   if( this.selectItem === item.name ||this.selectItems.findIndex(el => el.id === item.id) >= 0) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
        if(this.selectItem === item.name || this.selectItems.findIndex(el => el.name === item.name) >= 0) {
          return true;
        } else {
          return false;
        }
      // }

    },
    select(type) {
      if(this.isMultiple) {
        let indexOfElem = this.selectItems.findIndex(el => {
          if(el.id !== null && el.id !== undefined) {
            if(el.id === type.id) {
              return el
            }
          } else if(el.name === type.name) {
            return el
          }
        })
        if(indexOfElem >= 0){ //remove item
          this.selectItems.splice(indexOfElem, 1)
        }else{ // add item
          this.selectItems.push(type)
        }

        this.$emit('select', this.selectItems)
        //selectItemInObj
      }else{
        this.selectItem = type.name
        this.selectItemInObj = type
        // console.log(this.selectItems)
        this.$emit('select', type)
        this.$refs.dropdown.hide(false)
      }
    },
    render() {
      this.selectItem = ''
      this.selectItemInObj = {}
      this.selectItems = []
    }
  },
  mounted() {
    // if(this.activeItem) {
    //   //this.select(this.activeItem)
    //   if(this.isMultiple) {
    //     this.activeItem.forEach(item => {
    //       this.select(item)
    //     });
    //   }else{
    //     this.select(this.activeItem)
    //   }
    // }
    this.$emit("mountedEnd")
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../components/style-element/dropdown";
</style>

<style lang="scss">
.counterparties-drop{
  &.block-input_error{
    .dropdown-toggle{
      border-color: #f57f6c !important;
    }
  }
  .dropdown{
    width: 100%;
  }
  .dropdown-menu{
    max-height: 300px;
    overflow: auto;
  }
  .dropdown-toggle{
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 7px 7px !important;
    font-size: 16px !important;
    border-radius: 4px;
    border: 1px solid var(--color-blue-dark) !important;
    background: var(--color-input) !important;
    line-height: normal;
    &:after{
      display: none !important;
    }
    .button-drop{
      width: auto !important;
    }
  }
  .button-text{
    display: inline-flex;
    text-align: left;
    font-size: 14px;
    color: var(--color-dark);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    b{
      display: inline-flex;
      min-width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid var(--color-dark);
      font-weight: 400;
      font-size: 11px;
    }
  }
  .dropdown-menu{
    width: 100%;
    li{
      position: relative;
    }
  }
  .dropdown-check{
    position: absolute;
    top: 11px;
    right: 11px;
    user-select: none;
  }
  .dropdown-item{
    padding: 8px 30px 8px 16px;
    padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search{
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 16px;
    border-bottom: 1px solid #DEE3EA;
    input{
      height: auto;
      margin-left: 12px;
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
  .map-block {
    width: 100%;
    padding: 8px 16px;
    display: flex;
    justify-content: flex-start;
  }
}
</style>