import { render, staticRenderFns } from "./task-full-info-button-option.vue?vue&type=template&id=4ca6000c&scoped=true&"
import script from "./task-full-info-button-option.vue?vue&type=script&lang=js&"
export * from "./task-full-info-button-option.vue?vue&type=script&lang=js&"
import style0 from "./task-full-info-button-option.vue?vue&type=style&index=0&id=4ca6000c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca6000c",
  null
  
)

export default component.exports