<template>
  <div class="">
    <div class="border border-dark-gray rounded-lg">
      <div
        class="vessel_owners__table_wide bg-blue-dark rounded-t-lg" 
        :class="{'without_trials': !getPermissionsByType('show_accounts_in_all_operations')}"
      >
        <div class="border-r uppercase p-x-2 p-y-2 fz13 fw500 text-white">Charter Hire</div>
        <div
          class="border-r"
          v-if="getPermissionsByType('show_accounts_in_all_operations')"
        >
          <get-trials v-if="$route.params.subId" trialKey="SUBLET_Charter Hire" :isWhite="true"/>
          <get-trials v-else trialKey="Charter Hire" :isWhite="true"/>
        </div>
        <div class="colspan-3-9"></div>
      </div>
      <edit-hire-table-row
          @change-date-hire="$emit('change-date-hire')"
        :class="{
          'border-t border-dark-gray': i > 0,
          'rounded-b-lg': i == hireData.length-1
        }"
        v-for="(hire, i) in hireData"
        :key="i"
        :hireData="hireData"
        :hire="hire"
        :activeCurrency="activeCurrency"
        :index="i"
        @on-delete="$emit('on-delete', 'hire', i)"
      />
    </div>

    <div class="m-t-2">
      <button type="button" class="w-full button-blue-border fw500 fz13" @click="$emit('on-add')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>
        Add one more charter hire
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import getTrials from '@/components/vessels/owners/edit/getTrials'
import editHireTableRow from '@/components/vessels/owners/edit/editHireTableRow'

export default {
  props: {
    activeCurrency: String,
    hireData: Array,
  },
  components: {
    getTrials,
    editHireTableRow
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['getPermissionsByType']),
  },
}
</script>

<style>

</style>