<template>
  <div class="m-l-auto fz13 fw500 text-gray-text whitespace-nowrap">
    <span>
       Time ballast, d:
    <span class="m-l-2 m-r-4 text-dark">{{getTimeBallast | formatNumberInTotal}}</span>
    </span>
   <span>
      Time laden, d: <span class="m-l-2 m-r-4 text-dark">{{getTimeLaden | formatNumberInTotal}}</span>
   </span>
    <span>
       Time in port/canal, d: <span class="m-l-2 m-r-4 text-dark">{{getTimeInPort | formatNumberInTotal}}</span>
    </span>
    <span>
          Total time, d: <span class="m-l-2 text-dark">{{getTimeBallast+getTimeLaden+getTimeInPort | formatNumberInTotal}}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    fuelType: String,
    routes: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      routesLocal: [],
    }
  },
  watch: {
    routes: {
      handler() {
        this.routesLocal = Array.isArray(this.routes) === true ? JSON.parse(JSON.stringify(this.routes)) : []
      },
      deep: true
    }
  },
  filters: {
    formatNumberInTotal: function (value) {
      let numb = parseFloat(value)
      numb = numb ? numb : 0
      numb = numb.toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 4})
      return numb
    }
  },
  computed: {
    getTimeBallast() {
      let array = JSON.parse(JSON.stringify(this.routesLocal))
      let arrayLadBall = []
      let intake = 0;
      let ladBool = false;
      array.forEach((item, index)=> {
        if(index === 0) {
          arrayLadBall.push('ball')
        } else {
          if(ladBool) {
            arrayLadBall.push('lad')
          } else {
            arrayLadBall.push('ball')
          }
          if(item.status === 'loading') {
            ladBool = true;
            intake += parseFloat(item.intake) ? parseFloat(item.intake) : 0
          }
          if(item.status === 'discharging') {
            intake -= parseFloat(item.intake) ? parseFloat(item.intake) : 0
            if(intake === 0) {
              ladBool = false
            }
          }
        }
      })
      function getLabOrBal(index) {
        // let index = copyRoutesAll.findIndex(a=> a.route_id === route_id)
        return arrayLadBall[index] ? arrayLadBall[index] : 'ball'
      }
      let result = 0
      this.routesLocal.forEach((el, index)=>{
        if(getLabOrBal(index) === 'ball'){
          if(el[this.fuelType+'_streaming_days'])
            result += el[this.fuelType+'_streaming_days']
          // if(el[this.fuelType+'_streaming_time']){
          //   let time = el[this.fuelType+'_streaming_days'].split(':')
          //   result += +time[0]/24
          //   result += +time[1]/24/60
          // }
        }
      })
      return result
    },
    getTimeLaden() {
      let array = JSON.parse(JSON.stringify(this.routesLocal))
      let arrayLadBall = []
      let intake = 0;
      let ladBool = false;
      array.forEach((item, index)=> {
        if(index === 0) {
          arrayLadBall.push('ball')
        } else {
          if(ladBool) {
            arrayLadBall.push('lad')
          } else {
            arrayLadBall.push('ball')
          }
          if(item.status === 'loading') {
            ladBool = true;
            intake += parseFloat(item.intake) ? parseFloat(item.intake) : 0
          }
          if(item.status === 'discharging') {
            intake -= parseFloat(item.intake) ? parseFloat(item.intake) : 0
            if(intake === 0) {
              ladBool = false
            }
          }
        }
      })
      function getLabOrBal(index) {
        return arrayLadBall[index] ? arrayLadBall[index] : 'ball'
      }
      let result = 0
      this.routesLocal.forEach((el, index)=>{
        if(getLabOrBal(index) === 'lad'){
          if(el[this.fuelType+'_streaming_days'])
            result += el[this.fuelType+'_streaming_days']
          // if(el[this.fuelType+'_streaming_time']){
          //   let time = el[this.fuelType+'_streaming_time'].split(':')
          //   result += +time[0]/24
          //   result += +time[1]/24/60
          // }
        }
      })
      return result
    },
    getTimeInPort() {
      let result = 0
      this.routesLocal.forEach(el=>{
        if(el.port_stay) {
          result += el.port_stay ? el.port_stay : 0
        }
        if(el.lay_ex) {
          result += el.lay_ex ? el.lay_ex : 0
        }
      })
      return result
    }
  },
  methods: {
  }
}
</script>

<style>

</style>