<template>
  <div>
    <div class="flex flex-wrap rounded-lg bg-white p-x-6 p-t-4 p-b-1 shadow break-words border border-dark-gray">
      <div class="flex w-full">
        <div class="w-1/5 m-b-3" v-if="activeContract.name">
          <p class="fz13 text-gray-text">Vessel</p>
          <p class="fz14">{{activeContract.name}}</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="lay.cp_date">
          <p class="fz13 text-gray-text">CP Date</p>
          <p class="fz14">{{lay.cp_date | formatDateFromCpDateWithotTimeZome}}</p>
        </div>
        <div class="w-1/5" v-if="lay.demurrage_rate">
          <p class="fz13 text-gray-text">Demurrage Rate</p>
          <p class="fz14">{{lay.demurrage_rate | formatNumberThreeWithoutEmptyAdditional}}</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="lay.cargo">
          <p class="fz13 text-gray-text">Cargo</p>
          <p class="fz14 p-r-1">{{lay.cargo}}</p>
        </div>
      </div>
      
      <div class="flex w-full">
        <div class="w-1/5 m-b-3" v-if="lay.owner && lay.owner.company_name">
          <p class="fz13 text-gray-text">Owner</p>
          <p class="fz14">{{lay.owner.company_name}}</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="charterer">
          <p class="fz13 text-gray-text">Charterer</p>
          <p class="fz14">{{charterer}}</p>
        </div>
        <div class="w-1/5" v-if="lay.dispatch_rate">
          <p class="fz13 text-gray-text">Dispatch Rate</p>
          <p class="fz14">{{lay.dispatch_rate | formatNumberThreeWithoutEmptyAdditional}}</p>
        </div>
        <div class="w-1/5 m-b-3">
          <p class="fz13 text-gray-text">Time allowed</p>
          <p class="fz14">{{timeAllowed | formatNumberFive}}</p>
        </div>
        <div class="w-1/5" v-if="!pdfHideBlock">
          <p class="fz13 text-gray-text">Status</p>
          <div class="option-btn_wrap">
            <button class="option-btn text-white hover:text-blue" @click.stop="openMenu">
              <span v-if="lay.status == 'confirmed'" class="hover:bg-gray-dark bg-green p-x-2 p-y-0.5 fz13 fw500 rounded-full">Confirmed</span>
              <span v-else-if="lay.status == 'under_dispute'" class="hover:bg-gray-dark bg-purple p-x-2 p-y-0.5 fz13 fw500 rounded-full">Under dispute</span>
              <span v-else class="hover:bg-gray-dark bg-red p-x-2 p-y-0.5 fz13 fw500 rounded-full">Not confirmed</span>
            </button>
            <div class="option-btn_list left-0 right-auto top-full" tabindex="0" @blur="closeMenu()" v-show="isShowMenu" ref="option_list">
              <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('not_confirmed')">
                Not confirmed
                <svg :class="{'opacity-100': lay.status == 'not_confirmed'}" class="m-l-2 m-r-0 opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
              </div>
              <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('under_dispute')">
                Under dispute
                <svg :class="{'opacity-100': lay.status == 'under_dispute'}" class="m-l-2 m-r-0 opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
              </div>
              <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('confirmed')">
                Confirmed
                <svg :class="{'opacity-100': lay.status == 'confirmed'}" class="m-l-2 m-r-0 opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(item) in getFilterPorts(lay.lay_ports)" class="w100Proc" :key="item.id">
      <div class="p-t-3" v-if="pdfHideBlock"></div>
      <div
          class="flex flex-wrap rounded-lg bg-white p-x-6 p-t-4 p-b-1 shadow break-words border border-dark-gray"
          :class="{'m-t-3': !pdfHideBlock}"
      >
        <div class="w-1/5 m-b-3" v-if="item.loading_port">
          <p class="fz13 text-gray-text">Port of Loading #{{1+(lay.lay_ports.findIndex(a=> a.id === item.id))}}</p>
          <p class="fz14">{{item.loading_port.name}}, {{item.loading_port.country}}</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="item.loading_quantity && item.loading_port">
          <p class="fz13 text-gray-text">Quantity of Loading Cargo</p>
          <p class="fz14">{{item.loading_quantity | formatNumberThree}} mt</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="item.loading_rate && item.loading_port">
          <p class="fz13 text-gray-text">{{!item.loading_is_day ? 'Rate of Loading' : 'Days'}}</p>
          <p class="fz14">{{item.loading_rate | formatNumberFour}} {{!item.loading_is_day ? 'mt' : ''}}</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="item.loading_terms && item.loading_port">
          <p class="fz13 text-gray-text">Terms of Loading</p>
          <p class="fz14">{{item.loading_terms}}</p>
        </div>

        <div v-if="item.loading_port && item.discharging_port" class="w-full"></div>

        <div class="w-1/5 m-b-3" v-if="item.discharging_port">
          <p class="fz13 text-gray-text">Port of Discharging #{{1+(lay.lay_ports.findIndex(a=> a.id === item.id))}}</p>
          <p class="fz14">{{item.discharging_port.name}}, {{item.discharging_port.country}}</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="item.discharging_quantity && item.discharging_port">
          <p class="fz13 text-gray-text">Quantity of Discharging Cargo</p>
          <p class="fz14">{{item.discharging_quantity | formatNumberThree}} mt</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="item.discharging_rate && item.discharging_port">
          <p class="fz13 text-gray-text">{{!item.discharging_is_day ? 'Rate of Discharging' : 'Days'}}</p>
          <p class="fz14">{{item.discharging_rate | formatNumberTwo}} {{!item.discharging_is_day ? 'mt' : ''}}</p>
        </div>
        <div class="w-1/5 m-b-3" v-if="item.discharging_terms && item.discharging_port">
          <p class="fz13 text-gray-text">Terms of Discharging</p>
          <p class="fz14">{{item.discharging_terms}}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    lay: Object,
    timeAllowed: Number,
    pdfHideBlock: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      charterer: null,
      isShowMenu: false
    }
  },
  computed: {
    ...mapState({
      activeContract: state => state.contract.activeContract
    }),
  },
  watch: {
    lay: function() {
      this.getCharterer()
    }
  },
  methods: {
    ...mapActions(['axiosGetClientsByIdReturnResponse', 'axiosChangeLayStatus']),
    getLoadingPorts() {
      let ports = this.lay.additional_info?.loading_ports?.length ? JSON.parse(JSON.stringify(this.lay.additional_info.loading_ports)) : [];
      ports = ports.filter(port=> (port.arrived_load_date || port.notice_tendered_loading_date || port.loading_arrived_time || port.loading_notice_time || port.loading_commenced_date || port.loading_completed_date || port.loading_commenced_time || port.loading_completed_time))
      return ports.sort((a, b) => a.id_port > b.id_port ? 1 : -1)
    },
    getDischargingPorts() {
      let ports = this.lay.additional_info?.discharging_ports?.length ? JSON.parse(JSON.stringify(this.lay.additional_info.discharging_ports)) : [];
      ports = ports.filter(port=> (port.arrived_discharging_date || port.notice_tendered_date || port.discharging_arrived_time || port.discharging_notice_time || port.discharging_commenced_date || port.discharging_completed_date || port.discharging_commenced_time || port.discharging_completed_time))
      return ports.sort((a, b) => a.id_port > b.id_port ? 1 : -1)
    },
    getFilterPorts(ports) {
      let array = JSON.parse(JSON.stringify(ports))
      let loadingIds = this.getLoadingPorts().map(a=> {return a.PORT_ID})
      let dischargingIds = this.getDischargingPorts().map(a=> {return a.PORT_ID})
      return array.map(a=> {
        let bool = false;
        if(loadingIds.indexOf(a.id) >= 0) {
          bool = true
        } else {
          a.loading_port = null
        }
        if(dischargingIds.indexOf(a.id) >= 0) {
          bool = true
        } else {
          a.discharging_port = null
        }
        if(bool) {
          return a;
        }
      }).filter(a=> a)
    },
    async getCharterer() {
      let charterer = null

      if(this.lay['additional_info']['charterer']) {
        charterer = this.activeContract.cargo_contracts.find(el=>{
          return el.contract_type == 'CARGO' && el.id == this.lay['additional_info']['charterer']
        })
      } else {
        charterer = this.activeContract.cargo_contracts.find(el=>{
          return el.contract_type == 'CARGO' && el.cp_date == this.lay.cp_date
        })
      }
      if(charterer){
        if(Number.isInteger(charterer.owner)){
          charterer.owner = await this.axiosGetClientsByIdReturnResponse(charterer.owner)
        }
        
        this.charterer = charterer.owner ? charterer.owner.company_name : ''
        this.$emit('changeCharterer', this.charterer)
      }
    },

    openMenu() {
      this.isShowMenu = true

      setTimeout(()=>{ // because when I call .focus() after change isOpen, it`s don`t work
        let optionList = this.$refs.option_list
        optionList.focus()
      }, 100)
    },
    closeMenu(status) {
      if(status){
        this.lay.status = status
        this.axiosChangeLayStatus({id: this.lay.id, status: this.lay.status})
      }
      this.isShowMenu = false
    },
  },
  mounted() {
    this.getCharterer()
  }
}
</script>

<style>

</style>