<template>
  <div class="border border-dark-gray m-b-4 rounded-lg overflow-hidden" v-if="getCEV.additional_info.is_row || getDelivery.length || getRedelivery.length">
    <div class="vessel_owners__table-wrap">
      <div class="vessel_owners__table" v-if="getDelivery.length">
        <div class="first_element_stroke bg-blue-dark border-b uppercase p-x-3 p-y-2 fz13 fw500 colspan-1-6 text-white">
          {{getDeliverLSMGO.additional_info.checkboxes == 'estimated' ? 'Estimated':''}}
          Bunkers on Delivery
        </div>

        <div class="first_element_stroke bg-white-dark border-b border-r"></div>
        <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Qntty, mt</div>
        <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Price, {{owners ? owners.currency : 'USD'}}/mt</div>
        <div class="bg-white-dark border-b border-r"></div>
        <div class="bg-white-dark border-b"></div>
      </div>

      <div class="vessel_owners__table" v-for="(type, i) in getDelivery" :key="'asd2323as'+i">
        <div 
          :key="type.id + '1'" 
          class="first_element_stroke border-b border-r p-x-3 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO'}"
        >
          {{type.additional_info.name ? type.additional_info.name : (type.fuel_type == 'VLSFO_2' ? 'VLSFO' : type.fuel_type == 'LSMGO_2' ? 'LSMGO' : type.fuel_type)}}
        </div>
        <div 
          :key="type.id + '2'" 
          class="border-b border-r p-x-2 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO'}"
        >
          {{type.additional_info.quantity | formatNumberThreeZeroEmpty}}
        </div>
        <div 
          :key="type.id + '3'" 
          class="border-b border-r p-x-2 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO'}"
        >
          {{type.additional_info.price | formatNumberTwoZeroEmpty}}
        </div>
        <div 
          :key="type.id + '4'" 
          class="border-b border-r p-x-2 p-y-2 text-dark text-align-right amount"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO'}"
        >
          {{type.debit | formatNumberTwoZeroEmpty}}
        </div>
        <div 
          :key="type.id + '5'" 
          class="border-b p-x-2 p-y-2 text-align-right amount"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO'}"
        >
          {{type.credit | formatNumberTwoZeroEmpty}}
        </div>
      </div>

      <div class="vessel_owners__table" v-if="getRedelivery.length">
        <div class="first_element_stroke bg-blue-dark border-b uppercase p-x-3 p-y-2 fz13 fw500 colspan-1-6 text-white">
          {{getRedeliverLSMGO.additional_info.checkboxes == 'estimated' ? 'Estimated':''}}
          Bunkers on Redelivery
        </div>

        <div class="first_element_stroke bg-white-dark border-b border-r"></div>
        <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Qntty, mt</div>
        <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Price, {{owners ? owners.currency : 'USD'}}/mt</div>
        <div class="bg-white-dark border-b border-r"></div>
        <div class="bg-white-dark border-b"></div>
      </div>

      <div class="vessel_owners__table" v-for="(type, i) in getRedelivery" :key="'asdas'+i">
        <div 
          :key="type.id + '1re'"
          class="first_element_stroke  border-r p-x-3 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO', 'border-b': i<getDelivery.length}"
        >
          {{type.additional_info.name ? type.additional_info.name : (type.fuel_type == 'VLSFO_2' ? 'VLSFO' : type.fuel_type == 'LSMGO_2' ? 'LSMGO' : type.fuel_type)}}
        </div>
        <div 
          :key="type.id + '2re'"
          class="border-r  p-x-2 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO', 'border-b': i<getDelivery.length}"
        >
          {{type.additional_info.quantity | formatNumberThreeZeroEmpty}}
        </div>
        <div 
          :key="type.id + '3re'"
          class="border-r  p-x-2 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO', 'border-b': i<getDelivery.length}"
        >
          {{type.additional_info.price | formatNumberTwoZeroEmpty}}
        </div>
        <div 
          :key="type.id + '4re'"
          class="border-r  p-x-2 p-y-2 text-dark text-align-right amount"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO', 'border-b': i<getDelivery.length}"
        >
          {{type.debit | formatNumberTwoZeroEmpty}}
        </div>
        <div 
          :key="type.id + '5re'"
          class=" p-x-2 p-y-2 text-align-right amount"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO' || type.fuel_type == 'LSMGO', 'border-b': i<getDelivery.length}"
        >
        {{type.credit | formatNumberTwoZeroEmpty}}
        </div>
      </div>

<!--      <div class="vessel_owners__table" v-if="getCEV.additional_info.is_row">-->
<!--        <div class="first_element_stroke border-r uppercase p-x-3 p-y-2 fz13 fw500 text-dark">C/E/V</div>-->
<!--        <div class="border-r"></div>-->
<!--        <div class="border-r p-x-2 p-y-2 relative">-->
<!--          {{getCEV.additional_info.rate | formatNumberTwoZeroEmpty}}-->
<!--          <span class="absolute top-2 right-2 text-gray-text fz13">Rate, {{owners ? owners.currency : 'USD'}}/PMPR</span>-->
<!--        </div>-->
<!--        <div class="border-r p-x-2 p-y-2 text-align-right">{{getCEV.debit | formatNumberTwoZeroEmpty}}</div>-->
<!--        <div class="p-x-2 p-y-2 text-align-right">{{getCEV.credit | formatNumberTwoZeroEmpty}}</div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    owners: Object,
    bunkersOnData: Array
  },
  data() {
    return {
      fuels: [
        'VLSFO',
        'LSMGO',
        'HSFO',
        'MGO',
        'VLSFO_2',
        'LSMGO_2',
      ]
    }
  },
  computed: {
    getDelivery() {
      let delivery = this.bunkersOnData.filter(el => {
        return el.additional_info.is_row && el.bunkers_type == 'DELIVERY' && 
          (el.credit || el.debit || 
          el.additional_info.price || 
          el.additional_info.quantity)
      })

      let list = []
      this.fuels.forEach(el => {
        let fuel = delivery.find(item => item.fuel_type == el)
        if(fuel)
          list.push(delivery.find(item => item.fuel_type == el))
      })
      return list
    },
    getRedelivery() {
      let delivery = this.bunkersOnData.filter(el => {
        return el.additional_info.is_row && el.bunkers_type == 'REDELIVERY' && 
          (el.credit || el.debit || 
          el.additional_info.price || 
          el.additional_info.quantity)
      })
      
      let list = []
      this.fuels.forEach(el => {
        let fuel = delivery.find(item => item.fuel_type == el)
        if(fuel)
          list.push(delivery.find(item => item.fuel_type == el))
      })
      return list
    },
    getCEV() {
      return this.bunkersOnData.find(el => {
        return el.bunkers_type == 'C/E/V'
      })
    },
    getDeliverLSMGO() {
      return this.bunkersOnData.find(el => {
        return el.bunkers_type == 'DELIVERY' && el.fuel_type == 'LSMGO'
      })
    },
    getRedeliverLSMGO() {
      return this.bunkersOnData.find(el => {
        return el.bunkers_type == 'REDELIVERY' && el.fuel_type == 'LSMGO'
      })
    },
  }
}
</script>

<style>

</style>