<template>
  <modal-slots 
    :showMod="isShowModal"
    :isBorder="false"
    @close="$emit('on-close')"
    @apply="$emit('on-send')"
  >
    <template v-slot:title>
      <h2 class="fz18 fw500" v-if="$route.name != 'port-archive-berths'">Archive the berth</h2>
      <h2 class="fz18 fw500" v-else>Unarchive the berth</h2>
    </template>

    <p class="p-6 fz14" v-if="$route.name != 'port-archive-berths'">Are you sure you want to archive this berth?</p>
    <p class="p-6 fz14" v-else>Are you sure you want to unarchive this berth?</p>

    <template v-slot:bottomFirstButton>
      <button class="blue-button-text fz14 fw500 m-l-6 p-x-16" @click="$emit('on-close')">No</button>
    </template>
    <template v-slot:bottomSecondButton>
      <button class="blue-button fz14 fw500 m-l-6 p-x-16" @click="$emit('on-send')">Yes</button>
    </template>
  </modal-slots>
</template>

<script>
import modalSlots from '@/components/modal/modal-slots'

export default {
  components: {
    modalSlots
  },
  props: {
    isShowModal: Boolean,
    berth: Object
  }
}
</script>

<style>

</style>