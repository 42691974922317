<template>
  <div>
    <b-dropdown left ref="dropdown">
      <template #button-content>
        <button 
          class="user-button svg-white-dart fw500 fz13"
          :class="{
            'w-130': btnText,
            'button-blue-border h32 rounded p-x-3.5': isBlueBtn
          }"
        >
          <svg 
            :class="{
              'm-r-0': !btnText
            }" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.0335 5C13.0335 4.6134 12.7201 4.3 12.3335 4.3C11.9469 4.3 11.6335 4.6134 11.6335 5H13.0335ZM12.3335 14L11.8394 14.4959C12.1125 14.768 12.5544 14.768 12.8276 14.4959L12.3335 14ZM16.8276 10.5102C17.1014 10.2373 17.1022 9.79412 16.8293 9.52026C16.5565 9.2464 16.1132 9.24561 15.8394 9.51848L16.8276 10.5102ZM8.82756 9.51848C8.5537 9.24561 8.11048 9.2464 7.83761 9.52026C7.56473 9.79412 7.56553 10.2373 7.83939 10.5102L8.82756 9.51848ZM19.7002 16C19.7002 15.6134 19.3868 15.3 19.0002 15.3C18.6136 15.3 18.3002 15.6134 18.3002 16H19.7002ZM6.03348 16C6.03348 15.6134 5.72008 15.3 5.33348 15.3C4.94689 15.3 4.63348 15.6134 4.63348 16H6.03348ZM11.6335 5V14H13.0335V5H11.6335ZM12.8276 14.4959L16.8276 10.5102L15.8394 9.51848L11.8394 13.5041L12.8276 14.4959ZM12.8276 13.5041L8.82756 9.51848L7.83939 10.5102L11.8394 14.4959L12.8276 13.5041ZM17.0002 18.3H7.33349V19.7H17.0002V18.3ZM18.3002 16V17H19.7002V16H18.3002ZM6.03348 17V16H4.63348V17H6.03348ZM7.33349 18.3C6.61551 18.3 6.03348 17.718 6.03348 17H4.63348C4.63348 18.4912 5.84232 19.7 7.33349 19.7V18.3ZM17.0002 19.7C18.4913 19.7 19.7002 18.4912 19.7002 17H18.3002C18.3002 17.718 17.7181 18.3 17.0002 18.3V19.7Z" fill="#2C2C2C"/>
          </svg>
          <template v-if="btnText">{{btnText}}</template>
        </button>
      </template>
      <div class="content bg-white">
<!--        <span class="p-x-2 p-y-1 fz12 fw500 text-gray-text">PDF</span>-->
        <button v-on:click="clickExportPDF()" class="fz14 text-dark">Export PDF</button>
<!--        <button v-on:click="clickExportPDFBlackWhite()" class="fz14 text-dark">Stamp in b / w</button>-->
<!--        <span class="p-x-2 p-y-1 fz12 fw500 text-gray-text">XLSX</span>-->
        <button v-on:click="clickExportXLSX()" class="fz14 text-dark">Export XLSX</button>
<!--        <button v-on:click="clickExportXLSX()" class="fz14 text-dark">Stamp in b / w</button>-->
      </div>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "payment-dropdown-export",
  props: {
    btnText: String,
    isBlueBtn: Boolean
  },
  data() {
    return {

    }
  },
  methods: {
    clickExportPDF() {
      this.$emit("eventExportPDF");
      this.$refs.dropdown.hide(false)
    },
    // clickExportPDFBlackWhite() {
    //   this.$emit("eventExportPDFBlackWhite");
    //   this.$refs.dropdown.hide(false)
    // },
    clickExportXLSX() {
      this.$emit("eventExportXLSX")
      this.$refs.dropdown.hide(false);

    },
  }
}
</script>

<style scoped lang="scss">
@import "../../style-element/dropdown";
.user-button {
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    p {
      display: none;

    }
  }
}
p {
  margin: 0;
}
.name {
  margin-right: 8px;
}
img, .no-avatar {
  width: 32px;
  height: 32px;
  border-radius: 40px;
}
.arrow {
  margin-left: 4px;
}
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  background: transparent;
  border: none;
  min-width: 1px;
  width: max-content;
}
.content {
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  button {
    transition: background-color .1s linear;
    background-color: var(--color-modal);
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    box-sizing: border-box;
    &:first-child {
      border-radius: 4px 4px 0 0px;
    }
    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }
    &:hover {
      background-color: var(--color-hover-list);
    }
  }
}
</style>
