<template>
  <div class="adblock" v-if="showAdblock">
    <svg width="160" height="28" viewBox="0 0 160 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.11883 18.4209L11.2614 0V15.2808C11.2614 15.4972 11.1354 15.6934 10.9395 15.7822L5.11883 18.4209Z" fill="#094172"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4808 4.95144C4.96707 5.58029 0.682529 10.2941 0.682529 16.0151C0.682529 17.9452 1.16982 19.7596 2.02707 21.3415L11.021 17.0192V17.7753L1.7488 22.2343L1.59113 21.962C0.579487 20.2147 2.11693e-05 18.1826 2.11693e-05 16.0151C2.11693e-05 9.94042 4.54917 4.93622 10.404 4.26846L10.4808 4.95144Z" fill="#094172"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.021 20.2189L3.25226 24.0166L2.95554 23.3976L11.021 19.4455V20.2189Z" fill="#094172"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.021 22.4286L4.84768 25.4879L4.55276 24.868L11.021 21.6725V22.4286Z" fill="#094172"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.021 24.6281L6.34928 27L6.05421 26.3802L11.021 23.872V24.6281Z" fill="#094172"/>
      <path d="M18.2577 18.4209L12.1151 0V15.2808C12.1151 15.4972 12.2411 15.6934 12.437 15.7822L18.2577 18.4209Z" fill="#319FEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8957 4.95144C18.4094 5.58029 22.694 10.2941 22.694 16.0151C22.694 17.9452 22.2067 19.7596 21.3494 21.3415L12.3555 17.0192V17.7753L21.6277 22.2343L21.7854 21.962C22.797 20.2147 23.3765 18.1826 23.3765 16.0151C23.3765 9.94042 18.8273 4.93622 12.9725 4.26846L12.8957 4.95144Z" fill="#319FEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3555 20.2189L20.1243 24.0166L20.421 23.3976L12.3555 19.4455V20.2189Z" fill="#319FEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3555 22.4286L18.5288 25.4879L18.8238 24.868L12.3555 21.6725V22.4286Z" fill="#319FEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3555 24.6281L17.0272 27L17.3223 26.3802L12.3555 23.872V24.6281Z" fill="#319FEF"/>
      <path d="M37.2692 16.1197C37.2692 16.6724 37.0817 17.1168 36.7067 17.453C36.348 17.7664 35.8996 17.9231 35.3615 17.9231H31.1141V16.906H35.3615C35.6279 16.906 35.8588 16.8433 36.0545 16.7179C36.2773 16.5641 36.3888 16.3561 36.3888 16.094C36.3888 15.8319 36.2746 15.6296 36.0463 15.4872C35.8561 15.3675 35.6279 15.3077 35.3615 15.3077H32.7365C32.2528 15.3077 31.8479 15.1681 31.5218 14.8889C31.1739 14.5869 31 14.188 31 13.6923C31 13.2023 31.1739 12.8063 31.5218 12.5043C31.8479 12.2251 32.2528 12.0855 32.7365 12.0855H36.935V13.1026H32.7365C32.5517 13.1026 32.3941 13.1567 32.2636 13.265C32.1332 13.3732 32.068 13.5185 32.068 13.7009C32.068 13.8889 32.1332 14.0342 32.2636 14.1368C32.3941 14.2393 32.5517 14.2906 32.7365 14.2906H35.3615C35.8996 14.2906 36.348 14.453 36.7067 14.7778C37.0817 15.1197 37.2692 15.567 37.2692 16.1197Z" fill="#094172"/>
      <path d="M44.2313 14.188C44.2313 14.8262 44.0084 15.3419 43.5628 15.735C43.1497 16.0997 42.6307 16.2821 42.0056 16.2821H39.5518V15.2735H42.1605C42.4812 15.2735 42.7502 15.1738 42.9676 14.9744C43.185 14.7749 43.2937 14.51 43.2937 14.1795C43.2937 13.849 43.185 13.5869 42.9676 13.3932C42.7502 13.1994 42.4812 13.1026 42.1605 13.1026H38.9811V17.9231H38.011V12.0855H42.0056C42.6307 12.0855 43.1497 12.2707 43.5628 12.641C44.0084 13.0285 44.2313 13.5442 44.2313 14.188Z" fill="#094172"/>
      <path d="M49.4809 15.4701H46.3015V14.453H49.4809V15.4701ZM49.7336 17.9231H44.9237V12.0855H49.7336V13.1026H45.8938V16.906H49.7336V17.9231Z" fill="#094172"/>
      <path d="M54.9185 15.4701H51.7391V14.453H54.9185V15.4701ZM55.1712 17.9231H50.3613V12.0855H55.1712V13.1026H51.3314V16.906H55.1712V17.9231Z" fill="#094172"/>
      <path d="M62.3998 14.9402C62.3998 15.8006 62.1335 16.5128 61.6009 17.0769C61.0737 17.641 60.4052 17.9231 59.5954 17.9231H56.4567V12.0855H59.5954C60.4161 12.0855 61.0873 12.3476 61.609 12.8718C62.1362 13.396 62.3998 14.0855 62.3998 14.9402ZM61.4297 15.0256C61.4297 14.4615 61.2558 14 60.9079 13.641C60.5655 13.2821 60.128 13.1026 59.5954 13.1026H57.4187V16.906H59.5954C60.128 16.906 60.5655 16.7322 60.9079 16.3846C61.2558 16.037 61.4297 15.584 61.4297 15.0256Z" fill="#094172"/>
      <path d="M70.3317 17.9231H65.5299V12.0855H66.5V16.906H70.3317V17.9231Z" fill="#094172"/>
      <path d="M78.0963 14.9402C78.0963 15.8006 77.8327 16.5128 77.3056 17.0769C76.7784 17.641 76.1072 17.9231 75.2919 17.9231H73.7348C72.925 17.9231 72.2538 17.641 71.7211 17.0769C71.194 16.5128 70.9304 15.8006 70.9304 14.9402C70.9304 14.0855 71.1912 13.396 71.713 12.8718C72.2402 12.3476 72.9141 12.0855 73.7348 12.0855H75.2919C76.118 12.0855 76.792 12.3476 77.3137 12.8718C77.8355 13.396 78.0963 14.0855 78.0963 14.9402ZM77.1344 15.0256C77.1344 14.4615 76.9604 14 76.6126 13.641C76.2702 13.2821 75.83 13.1026 75.2919 13.1026H73.7348C73.2022 13.1026 72.7619 13.2821 72.4141 13.641C72.0717 14 71.9005 14.4615 71.9005 15.0256C71.9005 15.584 72.0717 16.037 72.4141 16.3846C72.7619 16.7322 73.2022 16.906 73.7348 16.906H75.2919C75.83 16.906 76.2702 16.7322 76.6126 16.3846C76.9604 16.037 77.1344 15.584 77.1344 15.0256Z" fill="#094172"/>
      <path d="M85.1069 17.9231H81.5688C80.759 17.9231 80.0877 17.641 79.5551 17.0769C79.0279 16.5128 78.7643 15.8006 78.7643 14.9402C78.7643 14.0855 79.0252 13.396 79.547 12.8718C80.0742 12.3476 80.7481 12.0855 81.5688 12.0855H84.7074V13.1026H81.5688C81.0361 13.1026 80.5959 13.2821 80.2481 13.641C79.9057 14 79.7345 14.4615 79.7345 15.0256C79.7345 15.584 79.9057 16.037 80.2481 16.3846C80.5959 16.7322 81.0361 16.906 81.5688 16.906H84.1368V15.6325H81.4954V14.7009H85.1069V17.9231Z" fill="#094172"/>
      <path d="M87.1134 17.9231H86.1432V12.0855H87.1134V17.9231Z" fill="#094172"/>
      <path d="M94.3839 16.1197C94.3839 16.6724 94.1964 17.1168 93.8214 17.453C93.4627 17.7664 93.0143 17.9231 92.4763 17.9231H88.2288V16.906H92.4763C92.7426 16.906 92.9736 16.8433 93.1692 16.7179C93.392 16.5641 93.5035 16.3561 93.5035 16.094C93.5035 15.8319 93.3893 15.6296 93.1611 15.4872C92.9708 15.3675 92.7426 15.3077 92.4763 15.3077H89.8512C89.3675 15.3077 88.9626 15.1681 88.6365 14.8889C88.2886 14.5869 88.1147 14.188 88.1147 13.6923C88.1147 13.2023 88.2886 12.8063 88.6365 12.5043C88.9626 12.2251 89.3675 12.0855 89.8512 12.0855H94.0497V13.1026H89.8512C89.6664 13.1026 89.5088 13.1567 89.3783 13.265C89.2479 13.3732 89.1827 13.5185 89.1827 13.7009C89.1827 13.8889 89.2479 14.0342 89.3783 14.1368C89.5088 14.2393 89.6664 14.2906 89.8512 14.2906H92.4763C93.0143 14.2906 93.4627 14.453 93.8214 14.7778C94.1964 15.1197 94.3839 15.567 94.3839 16.1197Z" fill="#094172"/>
      <path d="M100.245 13.1026H98.0524V17.9231H97.0904V13.1026H94.8892V12.0855H100.245V13.1026Z" fill="#094172"/>
      <path d="M102.033 17.9231H101.063V12.0855H102.033V17.9231Z" fill="#094172"/>
      <path d="M109.157 17.9231H106.018C105.208 17.9231 104.537 17.641 104.004 17.0769C103.477 16.5128 103.214 15.8006 103.214 14.9402C103.214 14.0855 103.475 13.396 103.996 12.8718C104.523 12.3476 105.197 12.0855 106.018 12.0855H109.157V13.1026H106.018C105.485 13.1026 105.045 13.2821 104.697 13.641C104.355 14 104.184 14.4615 104.184 15.0256C104.184 15.584 104.355 16.037 104.697 16.3846C105.045 16.7322 105.485 16.906 106.018 16.906H109.157V17.9231Z" fill="#094172"/>
      <path d="M116.047 16.1197C116.047 16.6724 115.859 17.1168 115.484 17.453C115.126 17.7664 114.677 17.9231 114.139 17.9231H109.892V16.906H114.139C114.405 16.906 114.636 16.8433 114.832 16.7179C115.055 16.5641 115.166 16.3561 115.166 16.094C115.166 15.8319 115.052 15.6296 114.824 15.4872C114.634 15.3675 114.405 15.3077 114.139 15.3077H111.514C111.03 15.3077 110.625 15.1681 110.299 14.8889C109.951 14.5869 109.778 14.188 109.778 13.6923C109.778 13.2023 109.951 12.8063 110.299 12.5043C110.625 12.2251 111.03 12.0855 111.514 12.0855H115.713V13.1026H111.514C111.329 13.1026 111.172 13.1567 111.041 13.265C110.911 13.3732 110.846 13.5185 110.846 13.7009C110.846 13.8889 110.911 14.0342 111.041 14.1368C111.172 14.2393 111.329 14.2906 111.514 14.2906H114.139C114.677 14.2906 115.126 14.453 115.484 14.7778C115.859 15.1197 116.047 15.567 116.047 16.1197Z" fill="#094172"/>
      <path d="M127.789 17.9316H126.697L125.89 13.8462L124.023 17.6154C123.898 17.8718 123.724 18 123.501 18C123.251 18 123.061 17.8689 122.93 17.6068L121.063 13.8462L120.265 17.9316H119.164L120.411 12.5726C120.444 12.4188 120.515 12.2849 120.623 12.1709C120.737 12.057 120.868 12 121.015 12C121.227 12 121.428 12.1909 121.618 12.5726L123.477 16.3932L125.343 12.5726C125.534 12.1909 125.735 12 125.947 12C126.088 12 126.219 12.0598 126.338 12.1795C126.441 12.2877 126.512 12.4188 126.55 12.5726L127.789 17.9316Z" fill="#094172"/>
      <path d="M135.994 17.9231L134.747 17.9145L133.809 16.2821H131.168L131.755 15.2735H133.23L132.26 13.5897L129.749 17.9145H128.502L131.527 12.641C131.603 12.5043 131.709 12.3789 131.845 12.265C132.008 12.1339 132.154 12.0684 132.285 12.0684C132.426 12.0684 132.573 12.1311 132.725 12.2564C132.855 12.3647 132.961 12.4929 133.043 12.641L135.994 17.9231Z" fill="#094172"/>
      <path d="M142.985 17.9231H141.551L139.993 16.2821H137.898V15.2735H140.507C140.866 15.2735 141.167 15.1909 141.412 15.0256C141.689 14.8319 141.828 14.5556 141.828 14.1966C141.828 13.4672 141.388 13.1026 140.507 13.1026H137.328V17.9231H136.357V12.0855H140.352C141.07 12.0855 141.648 12.2479 142.089 12.5726C142.594 12.943 142.847 13.4872 142.847 14.2051C142.847 14.6952 142.7 15.1168 142.407 15.4701C142.135 15.8006 141.765 16.0313 141.298 16.1624L142.985 17.9231Z" fill="#094172"/>
      <path d="M145.008 17.9231H144.038V12.0855H145.008V17.9231Z" fill="#094172"/>
      <path d="M151.879 17.4188C151.879 17.7949 151.757 17.9829 151.513 17.9829C151.333 17.9829 151.132 17.8661 150.909 17.6325L147.338 13.8718V17.9231H146.376V12.5897C146.376 12.453 146.412 12.3362 146.482 12.2393C146.553 12.1368 146.654 12.0855 146.784 12.0855C146.963 12.0855 147.148 12.1852 147.338 12.3846L150.909 16.1368V12.0855H151.879V17.4188Z" fill="#094172"/>
      <path d="M157.747 15.4701H154.568V14.453H157.747V15.4701ZM158 17.9231H153.19V12.0855H158V13.1026H154.16V16.906H158V17.9231Z" fill="#094172"/>
    </svg>

    <p class="fz18 fw500 text-dark mt16">
      It looks like you’re using <br> an ad-blocker!
    </p>
      <p class="fz14 text-dark-gray mt8">If you want to receive notifications from <br>a site, disable ad blocker for that site.</p>
    <svg v-on:click="closeAdBlock" class="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#2C2C2C"/>
      <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#2C2C2C"/>
    </svg>

  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "adblock-modal",
  computed: {
    ...mapState(['showAdblock'])
  },
  methods: {
    ...mapMutations(['setShowAdBlock']),
    closeAdBlock() {
      this.setShowAdBlock(false)
      localStorage.setItem('noShowAdBlock', 'true')
    }
  }
}
</script>

<style scoped lang="scss">
  .adblock {
    padding: 12px 48px 12px 24px;
    z-index: 10000;
    background-color: var(--color-bgc-page-white);
    position: fixed;
    top: 50px;
    left: 50px;
    height: max-content;
    border-radius: 8px;
    width: 284px;
    border: 1px solid var(--color-border)
  }
  .close {
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
  }
</style>