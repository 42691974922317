import { render, staticRenderFns } from "./payment-checkbox-payment.vue?vue&type=template&id=f363874a&scoped=true&"
import script from "./payment-checkbox-payment.vue?vue&type=script&lang=js&"
export * from "./payment-checkbox-payment.vue?vue&type=script&lang=js&"
import style0 from "./payment-checkbox-payment.vue?vue&type=style&index=0&id=f363874a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f363874a",
  null
  
)

export default component.exports