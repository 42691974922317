<template>
  <div class="flex items-center" :class="{'pdf': pdf === true}">
    <div class="flex items-center" v-if="pdf !== true">
      <button v-if="pdf !== true" v-on:click="createPayment" class="w-130 button-blue-border fw500 fz13 h32 p-x-2"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"></path></svg> Create Payment </button>

      <router-link :to="`/vessels/${this.$route.params.id}/charterers/${this.$route.params.subId}/soa/history`" class="w-130 button-blue-border fw500 fz13 h32 m-l-2" v-if="historyCount > 1">
        <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M10.9989 8.25L10.9971 12.6226C10.9971 12.7886 11.063 12.9473 11.1802 13.0645L14.3082 16.1919L15.192 15.3081L12.247 12.3638L12.2489 8.25H10.9989Z" fill="#2C2C2C"/><path d="M12.25 3.25C7.85069 3.25 4.2097 6.51721 3.59989 10.75H1L4.125 13.875L7.25 10.75H4.8629C5.46083 7.20859 8.54098 4.50001 12.25 4.50001C16.3857 4.50001 19.75 7.86426 19.75 12C19.75 16.1358 16.3858 19.5 12.25 19.5C9.92519 19.5 7.76944 18.4478 6.33569 16.6131L5.35059 17.3821C7.02293 19.522 9.53759 20.75 12.25 20.75C17.0748 20.75 21 16.8242 21 12C21 7.17582 17.0748 3.25004 12.25 3.25Z" fill="#2C2C2C"/></g><defs><clipPath id="clip0"><rect width="20" height="20" fill="white" transform="translate(1 2)"/></clipPath></defs></svg>
        History
      </router-link>
      
      <vsoa-export v-if="pdf !== true" class="m-l-2" btnText="Export" :isBlueBtn="true" @eventExportXLSX="downloadXLSX" @eventExportPDF="downloadPDF" @eventExportPDFBlackWhite="eventExportPDFBlackWhite" />

      <router-link v-if="pdf !== true" :to="`/vessels/${this.$route.params.id}/open/charterers/${this.$route.params.subId}/soa/edit`" class="w-130 blue-button fz13 fw500 h32 m-l-2">
        <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#094172"/></svg>
        Edit SOA
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import vsoaExport from "@/components/vessels/sublet/vsoaExport";
import moment from 'moment'


export default {
  components: {vsoaExport},
  props: {
    pdf: Boolean,
  },
  computed: {
    ...mapState({
      historyCount: state => state.owners.ownersHistoryCount,
      owners: state => state.owners.activeOwners,
      trials: state => state.trialBalance.trials,
      contract: state => state.contract.activeContract,
      getCharterer() {
        return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
      },
    }),
    ...mapGetters(["getDataContractV"]),
    getContractOwnerName() {
      if(this.$route.params.subId){
        let subcontract = this.contract.cargo_contracts.find(el=>el.id == this.$route.params.subId)
        return subcontract.owner.company_name
      }else{
        return this.contract.owner.company_name
      }
    }
  },
  methods: {
    ...mapActions(['axiosGetTrials', 'axiosCreatePayment', 'axiosGetCurrency']),
    ...mapMutations(["addEmitAddPaymentForVessels", 'showLoad', 'hideLoad']),
    async createPayment() {
      this.showLoad()

      let payment_type = 'income'
      let bank = null;
      let cash = null;
      let idReal = null;
      let counterpatryID = this.getCharterer?.owner ? this.getCharterer?.owner.id : null;
      let client_name = this.getCharterer?.owner ? this.getCharterer?.owner.company_name : '';
      let vessel = this.getDataContractV ? this.getDataContractV.id : null;
      let settled_ship = null;
      let cp_date =  this.getDataContractV.cp_date;
      let payment_amount = this.owners?.balance ? this.owners.balance < 0 ?  this.owners.balance * (-1) : this.owners.balance: null;
      let currency = this.owners?.currency ? this.owners.currency : 'USD';
      let currency_amount = 0;
      if(currency !== 'USD') {
        if(currency !== null) {
          let rate = await this.axiosGetCurrency({currency: currency, created_at: moment().add(-1, 'day').format('YYYY-MM-DD')})
          rate = rate.data.results[0].rate;
          currency_amount = (Math.round(((payment_amount / rate)*100)))/100;
        }
      } else {
        let rate = 1
        currency_amount = (Math.round(((payment_amount / rate)*100)))/100;
      }
      let type = 'payment';
      let ports = null;
      let statement_member = '';
      if(!this.trials.length)
        await this.axiosGetTrials()
      let account = this.getTrials().length ? this.getTrials()[0].id : null
      let saveObj = {
        data_type: type,
        ports: ports,
        payment_type: payment_type,
        currency: currency,
        currency_amount: currency_amount,
        payment_amount: payment_amount,
        execute_date: null,
        status: 'not_paid',
        is_archive: false,
        client: counterpatryID,
        real_account: idReal,
        comment: '',
        cash: cash,
        bank: bank,
        account: account,
        file: [],
        receipt_files: [],
        ship: vessel,
        settled_ship: settled_ship,
        cp_date: cp_date,
        statement_member: statement_member,
        client_name: client_name,
      }
      console.log(this.owners)
      console.log(saveObj)
      let res = await this.axiosCreatePayment(saveObj)
      this.hideLoad()
      this.$router.push({name: 'Detail Cash Flow', params: {paymentCashFlowID: res.data.id}})
    },
    eventExportPDFBlackWhite() {
      this.$emit('eventExportPDFBlackWhite')
    },
    downloadPDF() {
      this.$emit("downloadPDF")
    },
    downloadXLSX() {
      this.$emit("downloadXLSX");
    },
    getTrials() {
      let trialKey = this.$route.params.subId ? 'SUBLET_Charter Hire' : 'Charter Hire'
      return this.trials.filter(el=>{
        let result = false
        el.accounts_field.forEach(item=>{
          if(item == trialKey)
            result = true
        })
        return result
      })
    },
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
  .pdf {
    //width: 1200px !important;
    display: flex;
    justify-content: center;
  }
</style>