<template>
  <div class="">
    <checklist-task-list-wrap-items :doneStatus="doneStatus" :ID="ID" v-for="(list, i) in checklist" :list="list" :key="i"></checklist-task-list-wrap-items>

  </div>
</template>

<script>
import ChecklistTaskListWrapItems from "@/components/checklist/checklist-task-list-wrap-items";
export default {
  name: "checklist-task-list",
  components: {ChecklistTaskListWrapItems},
  props: {
    doneStatus: Boolean,
    value: Array,
    ID: Number,
  },
  data() {
    return {
      checklist: this.value,
      watchDisabled: true,
    }
  },
  methods: {
  },
}
</script>

<style scoped lang="scss"></style>