<template>
  <div class="invoice_table border-t border-gray-text"
    :class="{
      'with_trials': getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'
    }"
  >
    <div class="border-r p-x-2 p-y-1 flex items-center justify-between">
      Extra ocean freight ILOW
      <svg class="cursor-pointer" @click="$emit('on-delete')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#4B4B4B"/></svg>
    </div>
    <div class="border-r" v-if="getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'"></div>
    <div class="border-r"></div>
    <div class="border-r relative">
      <input-format-number
          :fractionDigits="$route.path.indexOf('soa') >= 0 ? 3 : 2"

          v-model="detail.percentage"
        inputClass="p-l-2 p-r-6 p-y-2"
        placeholder="ILOW percentage"
        rightPlaceholder="%"
      ></input-format-number>
    </div>
    <div class="colspan-5-8"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import inputFormatNumber from '@/components/reuse/inputFormatNumber'

export default {
  props: {
    detail: Object
  },
  components: {
    inputFormatNumber
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
  }
}
</script>

<style>

</style>