<template>
  <div class="d-flex position-relative align-items-center">
    <select 
      class="p-l-3 p-r-6 p-y-2 text-gray-text rounded-none border-0 bg-transparent" 
      :class="{
        'text-white': isWhite,
        'text-gray-text': !isWhite
      }"
      v-if="getTrials.length > 1"
    >
      <option v-for="acc in getTrials" :key="acc.id">{{acc.account_id + ': ' + acc.name}}</option>
    </select>
    <svg v-if="getTrials.length > 1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow"><path data-v-31de80c0="" d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="white"></path></svg>
    <div 
      class="p-x-2 p-y-2 overflow-ellipsis whitespace-nowrap overflow-hidden" 
      :class="{
        'text-white': isWhite,
        'text-gray-text': !isWhite
      }"
      v-if="getTrials.length == 1"
      v-tooltip="getTrials[0].account_id + ': ' + getTrials[0].name"
    >{{getTrials[0].account_id + ': ' + getTrials[0].name}}</div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  props: {
    trialKey: String,
    isWhite: Boolean
  },
  computed: {
    ...mapState({
      trials: state => state.trialBalance.trials
    }),
    ...mapGetters(["getPermissionsByType"]),
    getTrials() {
      return this.trials.filter(el=>{
        if(el.is_archive) return false
        let result = false
        el.accounts_field.forEach(item=>{
          if(item == this.trialKey)
            result = true
        })
        return result
      })
    }
  },
  methods: {
    getPermissionShowDopInfoAcc() {
      return this.getPermissionsByType('show_dop_info_for_acc_in_payments')
    },
  }
}
</script>

<style scoped>
.text-white {
  background-image: none;
  color: var(--color-white) !important;
  -webkit-text-fill-color: var(--color-white);
  option {
    color: var(--color-dark);
    -webkit-text-fill-color: var(--color-dark);
  }
}
.arrow {
  position: absolute;
  right: 8px;
  path {
    fill: var(--color-white)
  }
}
</style>