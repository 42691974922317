import axios from 'axios'

export default {
  actions: {
    async axiosGetNotifications(ctx, next) {
      // ctx.commit('showLoad')
      let url = next ? next : process.env.VUE_APP_URL+'/notification/?limit=10'
       return await axios.get(url, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        // ctx.commit('hideLoad')
        ctx.state.next = response.data.next
        return response.data.results
      })
      .catch(() => {
        // ctx.commit('hideLoad')
        // console.log(error)
        return false
      })
    },
    async axiosGetNotificationSettings(ctx) {
      ctx.commit('showLoad')
      await axios.get(process.env.VUE_APP_URL+'/notification/settings/', {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('hideLoad')
        ctx.state.settings = response.data.results
        //console.log('axiosGetNotificationSettings', response.data.results);
      })
      .catch(error => {
        ctx.commit('hideLoad')
        console.error('axiosGetNotificationSettings', error)
      })
    },
    axiosSetNotificationSettings(ctx, settings) {
      axios.post(process.env.VUE_APP_URL+'/notification/settings/', settings, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        ctx.commit('addTip', 'Notifications saved')
        // console.log('axiosSetNotificationsSettings', response.data);
      })
      .catch(error => {
        console.error('axiosSetNotificationsSettings', error)
      })
    }
  },
  mutations: {
    setNotificationDot(state, bool) {
      state.isNotificationDot = bool
    },
    setMailNotificationDot(state, bool) {
      state.isMailNotificationDot = bool
    },
  },
  state: {
    next: null,
    settings: null,
    isNotificationDot: false,
    isMailNotificationDot: false
  }
}