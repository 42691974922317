<template>
  <div>
    <b-form-group>
      <div
          v-for="(ch, i) in columns"
          :key="i"
          class="check-wrap"
      >
        <b-form-checkbox
            @change="changeCheck"
            v-model="selected"
            :value="ch"
            name="flavour-3a"
            class="check"
        >
          <!--          <pre>{{selected}}</pre>-->
          <div class="text-block">
            <span><p class="text1 fz14 text-dark-gray"> {{ch}}</p> </span>
          </div>
        </b-form-checkbox>
      </div>

    </b-form-group>
    <!--    {{getPaymentsAccount}}-->
    <!--    {{selected}}-->
  </div>
</template>

<script>
export default {
  name: "checkboxes-for-select-column",
  props: ["searchText", "setSel"],

  data() {
    return {
      selected: [],
      columns: ['Bank and cash accounts', 'Statement member', 'Settled Vsl', 'Real Account']
    }
  },
  watch: {
    setSel: function () {
      this.selected = this.setSel
    }
  },
  methods: {
    changeCheck() {
      this.$emit("change", this.selected);
    },
    setSelect(sel) {
      if(Array.isArray(sel)) {
        this.selected = sel;
      }
    },
  },
  mounted() {
    this.selected = this.setSel
    this.$emit("mountedEnd")
  }

}
</script>

<style scoped lang="scss">
@import "./src/components/style-element/bootstrap";
@import "./src/components/style-element/checkbox";
.check-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
}
p {
  margin: 0;
}
.check {

}
.everyone {
  margin-left: 5px;
  cursor: pointer;
  display: inline;
}

.text-block {
  //white-space: pre-wrap;
  //display: flex;
  * {
    //display: inline !important;
    //float: left;
  }
  p {
    display: inline !important;
    width: max-content !important;
    height: 20px;
  }
  button {
    //float: left;
    word-break: break-all;
    //margin-left: 5px;
  }
}

/deep/ .custom-control {
  p:first-letter {
    display: block !important;
    text-transform: uppercase;
  }
}
</style>