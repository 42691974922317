import axios from 'axios'

export default {
    actions: {
        axiosGetActivityList(ctx, data) {
            return axios.get(process.env.VUE_APP_URL+"/activity", {
                params: {
                    ordering: data.ordering,
                    instance_id: data.instance_id,
                    model: data.model,
                    changes: data.changes,
                    action: data.action,
                    user: data.user,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                    limit: data.limit,
                    offset: data.offset,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setActivityData", response)
            })
        }
    },
    mutations: {
        setActivityData(state, data) {
            state.activityData = data.data.results;
        }
    },
    state: {
        activityData: [],
    },
    getters: {
        getActivityData(state) {
            return state.activityData;
        }
    },
}