import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);


export default {
    actions: {

    },
    mutations: {
        setFilterYear(state, data) {
            state.year = [];

            state.year = JSON.parse(JSON.stringify(data));
            localStorage.removeItem("yearFilter");
            localStorage.setItem("yearFilter", JSON.stringify(state.year));

            // console.log()
        },
        setFilterPeriod(state, data) {
            state.period = [];
            state.period = JSON.parse(JSON.stringify(data));
            localStorage.removeItem("periodFilter");
            localStorage.setItem("periodFilter", JSON.stringify(state.period));
        },
        setFilterMonth(state, data) {
            state.month = [];
            state.month = JSON.parse(JSON.stringify(data));
            localStorage.removeItem("monthFilter");
            localStorage.setItem("monthFilter", JSON.stringify(state.month));

        },
        setFilterRoutes(state, data) {
            state.routes = data;
            localStorage.removeItem("routesFilter");
            localStorage.setItem("routesFilter", JSON.stringify(state.routes));

        },
        setShipId(state, data) {
            state.ship_id = "" + data;
            localStorage.removeItem("shipIdFilter");
            localStorage.setItem("shipIdFilter", JSON.stringify(state.ship_id));
        },
        setCountRoutersForChoosedBlock(state, data) {
            state.countRoutersForChoosedBlock = data;
        },
        setLoaderPDF(state, data) {
            state.loaderPDF = data;
        },
    },
    state: {
        year: [],
        month: [],
        routes: [],
        period: [],
        ship_id: "",
        countRoutersForChoosedBlock: [],
        loaderPDF: false,
    },
    getters: {
        showLoaderPDF(state) {
          return state.loaderPDF;
        },
        getFilterPeriod(state) {
            if((state.period.length === 0 || (state.period.length !== 0 && (state.period[0].length === 0 || state.period[1].length !== 0))) && localStorage.getItem("periodFilter") !== null && localStorage.getItem("periodFilter") !== undefined) {
                state.period = JSON.parse(localStorage.getItem("periodFilter"));
            }
            return state.period;
        },
        getFilterYear(state) {
            if(state.year.length === 0 && localStorage.getItem("yearFilter") !== null && localStorage.getItem("yearFilter") !== undefined) {
                state.year = JSON.parse(localStorage.getItem("yearFilter"));
            }
            return state.year;
        },
        getFilterMonth(state) {
            if(state.month.length === 0 && localStorage.getItem("monthFilter") !== null && localStorage.getItem("monthFilter") !== undefined) {
                state.month = JSON.parse(localStorage.getItem("monthFilter"));
            }
            return state.month;
        },
        getFilterRoutes(state) {
            if(state.routes.length === 0 && localStorage.getItem("routesFilter") !== null && localStorage.getItem("routesFilter") !== undefined) {
                state.routes = JSON.parse(localStorage.getItem("routesFilter"));
            }
            return state.routes;
        },
        getShipId(state) {
            if((""+state.ship_id).length === 0 && localStorage.getItem("shipIdFilter") !== null && localStorage.getItem("shipIdFilter") !== undefined) {
                state.ship_id = JSON.parse(localStorage.getItem("shipIdFilter"));
            }
            return state.ship_id;
        },
        getCountRoutersForChoosedBlock(state) {
            return state.countRoutersForChoosedBlock;
        }
    },
}
