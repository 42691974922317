<template>
  <transition name="fade">
    <div class="wrap" v-show="showMod">
      <div class="filter" v-if="isPointerBg" v-on:click="noCloseFilter ? null : closeModal"></div>
      <div class="filter" v-else></div>
      <div class="content">
        <div class="top-panel" :class="{'border-b-0': !isBorder}">
          <slot name="title">Name</slot>
          <button class="close-button" v-on:click="closeModal">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#222222" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z"/>
              <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z"/>
            </svg>
          </button>
        </div>
        <div class="main-block">
          <slot></slot>
        </div>
        <div class="bottom-panel" :class="{'border-t-0': !isBorder}" v-if="footerHide !== true">
          <slot name="bottomFirstButton">
            <button class="blue-button-text fz14" v-on:click="closeModal">{{textForFirstButton}}</button>
          </slot>
          <slot name="bottomSecondButton">
            <button class="blue-button fz14 fw500" v-on:click="applyModal">{{textForSecondButton}}</button>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal-slots",
  props: {
    showMod: Boolean,
    noCloseAfterApply: Boolean,
    footerHide: Boolean,
    isBorder: {
      type: Boolean,
      default: true
    },
    textForFirstButton: {
      type: String,
      default: 'Cancel'
    },
    textForSecondButton: {
      type: String,
      default: 'Confirm'
    },
    isPointerBg: {
      type: Boolean,
      default: true
    },
    noCloseFilter: {
      type: Boolean,
      default: false,
    }
  },
  // watch: {
  //   showMod() {
  //     if(this.showMod === true) {
  //       this.openModal()
  //     } else {
  //       this.closeModal()
  //     }
  //   },
  // },
  data() {
    return {
      // show: true,
    }
  },
  methods: {
    openModal() {
      this.$emit("open");
    },
    closeModal() {
      this.$emit("close");
    },
    applyModal() {
      this.$emit("apply");
      if(this.noCloseAfterApply !== true) {
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "modal-slots";
</style>