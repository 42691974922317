<template>
  <div class="payment-accounts_list">
    <div class="payment-accounts_item" v-for="acc in getAccounts" :key="acc.id">
      <payment-account-btns @on-edit="onEdit(acc)" @on-archive="onArchive(acc)"/>
      <payment-account-row title="Holder" :value="acc.holder" />
      <payment-account-row title="Account Number" :value="acc.account_number" />
      <payment-account-row title="IBAN" :value="acc.iban" />
      <payment-account-row title="Description" :value="acc.description" />
      <payment-account-row title="Bank" :value="acc.bank" />
      <payment-account-row title="Clearing number" :value="acc.clearing_number" />
      <payment-account-row title="BIC / SWIFT" :value="acc.bic" />
      <payment-account-row title="Postal Account" :value="acc.postal" />
      <payment-account-row title="Currency" :value="acc.currency" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import paymentAccountBtns from './paymentAccountBtns.vue'
import paymentAccountRow from './paymentAccountRow'
export default {
  components: { paymentAccountBtns, paymentAccountRow },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['getPaymentAccountsByID']),
    getAccounts() {
      return this.getPaymentAccountsByID(this.$route.params.id)
    }
  },
  methods: {
    onEdit(acc) {
      this.$emit('edit-account', acc)
    },
    onArchive(acc) {
      this.$emit('on-archive', acc);
    },
  },
  
}
</script>

<style lang="scss">
  .payment-accounts{
    &_list{
      display: flex;
      flex-wrap: wrap;
      padding: 0 24px;
    }
    &_item{
      width: 32.6%;
      margin-right: 1.1%;
      margin-bottom: 12px;
      padding: 16px 0;
      background: var(--color-card);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      &:nth-child(3n){
        margin-right: 0;
      }
    }
  }
</style>