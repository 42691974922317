<template>
  <div 
    class="border border-dark-gray m-b-4 rounded-lg overflow-hidden"
    v-if="sortedByDate.length"
  >
    <div class="vessel_owners__table">
      <div class="colspan-1-6 bg-blue-dark uppercase p-x-3 p-y-2 fz13 fw500 text-white">Remittances done</div>
    </div>
    
    <div
      class="vessel_owners__table bg-white"
      v-for="(hire, i) in sortedByDate" 
      :key="i"
    >
      <div class="border-t border-r p-x-3 p-y-2 text-dark fw500">Hire #{{1+i}}</div>
      <div class="border-t colspan-2-4 border-r p-x-2 p-y-2 text-dark">{{hire.date}}</div>
      <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right">{{hire.debit | formatNumberTwoZeroEmpty}}</div>
      <div class="border-t p-x-2 p-y-2 text-align-right">{{hire.credit | formatNumberTwoZeroEmpty}}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    remittances: Array,
  },
  computed: {
    sortedByDate() {
      let remittances = [...this.remittances]
      remittances = remittances.filter(el=>el.is_row)
      return remittances.sort((a,b)=>new Date(moment(a.date, 'DD.MM.YYYY').format('YYYY-MM-DD'))-new Date(moment(b.date, 'DD.MM.YYYY').format('YYYY-MM-DD')));
    }
  }
}
</script>

<style>

</style>