// import Vue from "vue";

import axios from "axios";

export default {
    actions: {
        async axiosGetTrialsForBankAndCashAccounts(ctx) {
            await axios.get(process.env.VUE_APP_URL+'/payment/account/balance/?is_archive=0',
                {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response => {
                ctx.commit('setTrialsForBankAndCash', response.data)
            }).catch(error => {
                console.error('axiosGetTrials', error);
            })
        },
    },
    mutations: {
        setPhone(state, data) {
            state.phone = data;
        },
        setDartMode(state, data) {
            if(data) {
                document.body.classList.add('dart-mode');
            } else {
                document.body.classList.remove('dart-mode');
            }
            state.statusDartMode = data;
            if(data) {
                localStorage.setItem('dartMode', JSON.stringify(data))
            } else {
                localStorage.removeItem('dartMode')
            }
        },
        setBankAndCashAccountForCashFlowPush(state, data) {
            state.BankAndCashAccountForCashFlowPush = data;
        },
        setNoTurnOffLoader(state, data) {
            state.noTurnOffLoader = data;
        },
        setStatusUnlink(state, data) {
            state.StatusUnlink = data;
        },
        setTurnOnDarkModeAfterDownloadPDF(state, data) {
            state.turnOnDarkModeAfterDownloadPDF = data
        },
        setTrialsForBankAndCash(state, data) {
            state.trialsForBankAndCash = data
        },
        setOffAxiosTrial(state, data) {
            state.offAxiosTrial = data;
        },
    },
    state: {
        trialsForBankAndCash: [],
        turnOnDarkModeAfterDownloadPDF: false,
        StatusUnlink: false,
        noTurnOffLoader: false,
        phone: "",
        statusDartMode: false,
        BankAndCashAccountForCashFlowPush: [],
        offAxiosTrial: false,
    },
    getters: {
        getStatusUnlink(state) {
          return state.StatusUnlink
        },
        getNoTurnOffLoader(state) {
          return state.noTurnOffLoader
        },
        getBankAndCashAccountForCashFlowPush(state) {
          return state.BankAndCashAccountForCashFlowPush;
        },
        getStatusDartMode(state) {
            return state.statusDartMode;
        },
        getPhoneNumber(state) {
            // if(state.phone.length === 0 && Vue.prototype.$cookies.isKey("userPhoneNumber")) {
            //     state.phone = Vue.prototype.$cookies.get("userPhoneNumber");
            // }
            return state.phone;
        },
        getDartMode(state) {
            if(localStorage.getItem('dartMode') !== null && localStorage.getItem('dartMode') !== undefined && (''+localStorage.getItem('dartMode')).length !== 0) {
                state.statusDartMode = JSON.parse(localStorage.getItem('dartMode'))
            }
            return state.statusDartMode
        }
    },
}
