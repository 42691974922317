import Vue from 'vue'
import Vuex from 'vuex'
import store from './modules/store'
import step2 from './modules/step2addEmployee'
import login from './modules/login'
import alert from './modules/alertError'
import confirmation from './modules/confirmation-number'
import employees from './modules/employees'
import permissions from "@/store/modules/permissions";
import registration from "@/store/modules/registration";
import addDepartments from "./modules/addDepartments"
import editDepartment from "./modules/editDepartment"
import BDI from "@/store/modules/BDI";
import socket from "./modules/socket"
import logout from "./modules/logout"
import addNewIndex from "./modules/addNewIndex"
import filterBDI from "@/store/modules/filterBDI";
import tag from "@/store/modules/tag";
import task from "@/store/modules/task";
import taskInfo from "@/store/modules/taskInfo";
import employeeCard from "@/store/modules/employeeCard";
import counterparties from "@/store/modules/counterparties";
import counterpartiesType from "@/store/modules/counterpartiesType";
import payment from "@/store/modules/payment"
import paymentAccounts from "@/store/modules/paymentAccounts";
import trialBalance from "@/store/modules/trialBalance";
import banks from "@/store/modules/banks";
import fs from "@/store/modules/fs";
import files from "@/store/modules/files"
import chat from "@/store/modules/chat"
import activity from "@/store/modules/activity"
import ports from "@/store/modules/ports"
import portBerth from "@/store/modules/portBerth"
import portContacts from "@/store/modules/portContacts"
import ships from "@/store/modules/ships"
import contract from "@/store/modules/contract"
import owners from "@/store/modules/owners"
import nonReports from "@/store/modules/non-reports"
import invoice from "@/store/modules/invoice"
import lay from "@/store/modules/lay"
import dashboard from "@/store/modules/dashboard"
import notifications from "@/store/modules/notifications"
import globalSearch from "@/store/modules/globalSearch"
import checklist from "@/store/modules/checklist"
import entries from "@/store/modules/entries"
import tabs from "@/store/modules/tabs"
import tabsIntake from "@/store/modules/tabsIntake"
import parsedVessel from "@/store/modules/parsedVessel"
import checklistPage from "@/store/modules/checklistPage"
import paymentListXY from "@/store/modules/paymentListXY"
import mapStream from "@/store/modules/mapStream";
import mailling from "@/store/modules/mailling";
import calendar from "@/store/modules/calendar";
import location from "@/store/modules/location";
import folders from "@/store/modules/folders";
import mailbox from "@/store/modules/mailbox";






Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    windowWidth: null,
    showAdblock: false,
  },
  getters: {
    paramsGetFilter: () => obj => {
      let normalizeObj = {}
      for (const key in obj) {
        if(obj[key] !== null && obj[key] !== undefined){
          if(Array.isArray(obj[key])){
            normalizeObj[key] = obj[key].map(el=>{
              if(el && el.id) return el.id
              return el
            })
          }else{
            normalizeObj[key] = obj[key]
          }
        }
      }
      //console.log('normalizeObj', normalizeObj);
      return normalizeObj
    }
  },
  mutations: {
    setShowAdBlock(state, data) {
      state.showAdblock = data
    },
    showLoad(state) {
      state.isLoading = true
    },
    hideLoad(state) {
      state.isLoading = false;
      if(state.store.turnOnDarkModeAfterDownloadPDF === true) {
        document.body.classList.add('dart-mode');
        state.store.statusDartMode = true;
        localStorage.setItem('dartMode', JSON.stringify(true))
        state.store.turnOnDarkModeAfterDownloadPDF = false;
      }
    },
    onWindowResize(state) {
      state.windowWidth = window.innerWidth
    }
  },
  actions: {

  },
  modules: {
    paymentListXY,
    files,
    BDI,
    store,
    step2,
    login,
    alert,
    confirmation,
    employees,
    permissions,
    registration,
    addDepartments,
    editDepartment,
    socket,
    logout,
    addNewIndex,
    filterBDI,
    tag,
    task,
    taskInfo,
    employeeCard,
    counterparties,
    counterpartiesType,
    paymentAccounts,
    payment,
    trialBalance,
    banks,
    activity,
    fs,
    chat,
    ports,
    portBerth,
    portContacts,
    ships,
    contract,
    owners,
    lay,
    nonReports,
    invoice,
    dashboard,
    notifications,
    globalSearch,
    checklist,
    entries,
    tabs,
    tabsIntake,
    parsedVessel,
    checklistPage,
    mapStream,
    calendar,
    mailling,
    location,
    folders,
    mailbox
  }
})