<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">Delete contact <button class="modal-close" @click="closeModal()"></button></p>
        <p class="modal-content_descr">Are you sure you want to delete this contact? You can not longer restore it.</p>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal()">Cancel</button>
        <button class="btn btn-next" @click="onSubmit">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex';

export default {
  props: ['contact'],
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['axiosDelClientContact']),
    ...mapMutations(['showLoad', 'hideLoad']),
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      this.showLoad()
      if(this.contact.contact.id){
        this.axiosDelClientContact(this.contact.contact.id).then(() => {
          this.hideLoad()
          this.$emit('close')
          this.$emit('update', this.contact.i)
          //console.log(responce);
        })
      }
    }
  }
}
</script>

<style lang="scss">

</style>