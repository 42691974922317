<template>
  <div class="mail-item position-relative">
    <div class="click-zone z-10" v-on:click="clickItem"></div>
    <div class="name">
      <div class="checkbox-block position-relative z-index-10" v-on:click="$emit('select')" v-if="$route.path.indexOf('archive') >= 0">
        <svg v-if="selected.map(a=> {return a.id}).indexOf(item.id) === -1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4444 0.888889H3.55556C2.0828 0.888889 0.888889 2.0828 0.888889 3.55556V12.4444C0.888889 13.9172 2.0828 15.1111 3.55556 15.1111H12.4444C13.9172 15.1111 15.1111 13.9172 15.1111 12.4444V3.55556C15.1111 2.0828 13.9172 0.888889 12.4444 0.888889ZM3.55556 0C1.59188 0 0 1.59188 0 3.55556V12.4444C0 14.4081 1.59188 16 3.55556 16H12.4444C14.4081 16 16 14.4081 16 12.4444V3.55556C16 1.59188 14.4081 0 12.4444 0H3.55556Z" fill="#BDCBD6"/>
        </svg>
        <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#094172"/>
          <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
        </svg>
          <div class="checkbox-block position-absolute w100Proc h100Proc t0 l0"></div>
      </div>
      <p :class="{'pl30': $route.path.indexOf('archive') >= 0}" class="box-border fz14 text-dark overflow-text-dots">{{type === 'mail' ? (item.template ? item.template.name : item.template_name) : item.name}}</p>
    </div>
    <div class="contact-lists" v-if="type !== 'contact'">
      <p class="contact text-dark fz14" v-if="getContactList.length">{{ getContactList[0] }}</p>
      <p class="contact text-dark fz14" v-if="!getContactList.length && item.emails_list.length">{{item.emails_list[0]}}</p>
      <p class="contact-amount text-dark fz14" v-if="(getContactList.length + item.emails_list.length) > 1">+{{(getContactList.length + item.emails_list.length) - 1}}</p>
    </div>
    <div class="subject">
      <p class="fz14 text-dark overflow-text-dots">{{type === 'contact' ? item.count_emails_list : item.subject}}</p>
    </div>
    <div class="text" v-if="type !== 'contact'">
      <p class="fz14 text-dark overflow-text-dots">{{cutTegs(item.text)}}</p>
    </div>
    <div class="date">
      <p class="fz14 text-dark overflow-text-dots">
<!--        {{(type !== 'mail' ? item.created_at : item.updated_at) | formatDateWithotTZ}}-->
        {{(item.updated_at) | formatDateWithotTZ}}
      </p>
      <svg class="delete cursor-pointer ml-auto" v-if="(type === 'template' || type === 'contact') && $route.path.indexOf('archive') === -1" v-on:click="$emit('delete')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="delete" fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/>
      </svg>
      <mailing-drop-archive @delete="$emit('deleteReal')" @unarchive="$emit('delete')" class="delete cursor-pointer ml-auto" v-else-if="type === 'template' || type === 'contact'"></mailing-drop-archive>
<!--      <svg  v-on:click="$emit('delete')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/>-->
<!--        <path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#2C2C2C"/>-->
<!--      </svg>-->


    </div>
  </div>
</template>

<script>
import MailingDropArchive from "@/components/mailling/mailing-drop-archive";
export default {
  name: "mailling-item",
  components: {MailingDropArchive},
  props: {
    item: Object,
    type: String,
    selected: Array,
  },
  computed: {
    getContactList() {
      let names = this.item.contact_list_names ? this.item.contact_list_names : []
      return this.item.contact_list.filter(a=> names.indexOf(a.name) === -1).map(a=> {return a.name}).concat(names)
    },
  },
  methods: {
    cutTegs(str) {
      let regex = /( |<([^>]+)>)/ig,
      result = str.replace(regex, "");
      return result;
    },
    clickItem(e) {
      if(!((''+e.target.classList).indexOf('option') >=0) && !((''+e.target.classList).indexOf('delete') >=0) && !((''+e.target.classList).indexOf('checkbox-block') >=0)) {
        this.$emit('clickItem')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mail-item {
  &>* {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    p {
      position: absolute;
      width: 100%;
    }
  }
}
.contact {
  position: static !important;
  width: max-content !important;
  box-sizing: border-box !important;
  padding: 2px 8px;
  border-radius: 20px;
  background-color: var(--color-blue-lightest);
}
.contact-amount {
  position: static !important;
  width: max-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  border-radius: 50px;
  background-color: var(--color-blue-lightest);
  padding: 2px;
  box-sizing: border-box;
  height: 20px;
  min-width: 20px;
}
.delete {
  position: relative;
  //z-index: 10;
}
.click-zone {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 40px);
}
</style>