<template>
  <div class="mt16">
    <folders-list-links :items="countries"></folders-list-links>
    <pagination-panel-default @load-more="loadNext" :if-active="loadingNextPage" v-if="currentPage<totalPage"></pagination-panel-default>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import FoldersListLinks from "@/components/folders/folders-list-links";
import PaginationPanelDefault from "@/components/ports/paginationDefault.vue";

export default {
  name: "folders-ports",
  components: {PaginationPanelDefault, FoldersListLinks},
  props: {
    search: String,
    sort: String,
    routeChange: Number,
  },
  data() {
    return {
      countries: [],
      currentPage: 1,
      totalPage: 0,
      loadingNextPage: false,
    }
  },
  watch: {
    search: function () {
      this.loadData()
    },
    sort: function () {
      this.loadData()
    },
    routeChange: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions(['axiosGetCountriesForFolders']),
    ...mapMutations(['setNameSectionFolders']),
    async loadData() {
      this.currentPage = 1
      let res = await this.axiosGetCountriesForFolders({params: {page: this.currentPage, ordering: this.sort.replace('name', 'country'), limit: 20, is_archive: this.$route.params.countries_is_archive, search: this.search}})
      this.countries = res.ports.map(a=> {
        let form = {
          name: a.name,
          last_updated: '',
          link: {name: 'Folders - Ports Countries Ports', params: {countryDetail: JSON.stringify({
                name: a.name,
                idsPorts: a.ids,
              })}}
        }
        return form
      })
      this.totalPage = res.total_page
    },
    async loadNext() {
      this.currentPage++;
      this.loadingNextPage = true
      let res = await this.axiosGetCountriesForFolders({params: {page: this.currentPage, ordering: this.sort.replace('name', 'country'), limit: 20, is_archive: this.$route.params.countries_is_archive, search: this.search}})
      this.countries.push(...res.ports.map(a=> {
        let form = {
          name: a.name,
          last_updated: '',
          link: {name: 'Folders - Ports Countries Ports', params: {countryDetail: JSON.stringify({
                name: a.name,
                idsPorts: a.ids,
              })}}
        }
        return form
      }))
      this.totalPage = res.total_page
      this.loadingNextPage = false
    }
  },
  mounted() {
    this.setNameSectionFolders('Ports')
    this.loadData()
  }
}
</script>

<style scoped>

</style>