import {mapGetters} from "vuex";
export default function () {
    return {
        data () {
            return {
                displayPriority: 0
            }
        },
        computed: mapGetters(['getPaymentListOriginal']),
        mounted () {
            this.runDisplayPriority()
        },
        watch: {
            getPaymentListOriginal: function () {
                this.runDisplayPriority()
            }
        },

        methods: {
            runDisplayPriority () {
                const step = () => {
                    requestAnimationFrame(() => {
                        this.displayPriority++
                        if (this.displayPriority < this.getPaymentListOriginal.length) {
                            step()
                        }
                    })
                }
                step()
            },

            defer (priority) {
                return this.displayPriority >= priority
            }
        }
    }
}
