<template>
  <transition name="fade">
    <div class="wrap-content" v-if="show">
      <div class="filter" v-on:click="hideMenu"></div>
      <div class="content" :class="{'show-menu': show}">

<!--        {{getResponseAddDepartments}}-->
        <div class="wrap-block">
          <button class="close-menu" v-on:click="hideMenu">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
              <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
            </svg>
          </button>
          <p class="title fz18 fw500 text-dark1">Add new department</p>
<!--          {{selected}}-->
<!--          <p class="fz13 text-dark-gray">Select departments or individual employees</p>-->
          <div class="block-input">
            <p class="fz12 text-gray">Name</p>
            <input type="text" class="h36 fz13" placeholder="Enter the name of the department" v-model="name">
          </div>
          <p class="fz12 title1 text-gray mt24">Permissions</p>
<!--          {{selected}}-->
          <div class="checkboxes-block" :key="keyRender">
            <checkboxes @showModalColumns="showModalColumns($event, i)" @openModalAccount="openModalAccount" @renderCheckbox="renderCheckbox" @changeSelected="changeSelected" ref="checkboxes" v-for="(perm, i) in permissions" :key="i" :checkboxes="perm"></checkboxes>
          </div>

        </div>
        <div class="bottom-panel">
          <button class="blue-button" v-on:click="clickSave">Save</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Checkboxes from "@/components/addEmployeesStep2/checkboxes";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
name: "addNewDepartment",
  computed: mapGetters(["getPermissionsAddDepartment", "getResponseAddDepartments"]),
  components: {Checkboxes},
  data() {
    return {
      show: false,
      name: "",
      permissions: [],
      tasksRights: {
        title: "Tasks (all active)",
        details: [
          {name: "Assign tasks for ", button1Show: true, value: "1"},
          {name: "Automatically receive tasks from the system", value: "2"},
          {name: "Change task statuses for ", value: "3", button2Show: true,},
        ]
      },
      accessRights: {
        title: "Access to the Database (all active)",
        details: [
          {name: "Access to the Client Database"},
          {name: "Access to the Ship Database"},
          {name: "Access to the Port Database"},
        ]
      },
      editRights: {
        title: "Edit Database (all active)",
        details: [
          {name: "Edit Client Database"},
          {name: "Edit Ship Database"},
          {name: "Edit Port Database", notEnabled: true},
        ]
      },
      departmentsSelected: [],
      employeesSelected: [],
      selec1: [],
      selec2: [],
      keyRender: 0,
      selected: [],
    }
  },
  methods: {
    ...mapMutations(["setAlert", "setShowAddDepartment", "clearActiveCheckboxInMenuAddDepartment"]),
    ...mapActions(["axiosAddDepartments", "axiosGetDepartments", "axiosAddPermissionsNewDepartments"]),
    openModalAccount(data) {
      data.textModalOpen = 'addNewDepartment'
      this.$emit("openModalAccount", data);
    },
    showModalColumns(data, indexOne) {
      this.$emit("showModalColumns", {id: data.id, title2: indexOne, indexTwo: data.index, active: data.active, textModalOpen: 'addNewDepartment'});
    },
    changeSelected(data) {
      // // console.log(data);
      // // console.log(this.selected)
      // if(data.selected.length !== 0) {
      if(this.selected.length !== 0) {
        let coincidences = 0;
        for(let i=0; i<this.selected.length; i++) {
          if(this.selected[i].title === data.title) {
            coincidences+=1;
            // this.selected[i].selected.slice(0,this.selected[i].selected.length);
            this.selected[i].selected = [];
            // // console.log(2);
            this.selected[i].selected = data.selected;
            // // console.log(3);
          }
        }
        // // console.log(coincidences)
        if(coincidences === 0) {
          this.selected.push(data);
        }
      } else {
        this.selected.push(data);
      }
      // } else {
      //   // console.log("a");
      //   for(let i=0; i<this.selected.length; i++) {
      //     if(this.selected[i].title === data.title) {
      //       this.selected[i].selected = [];
      //     }
      //   }
      // }
    },

    renderCheckbox() {
      this.keyRender++;
    },
    setSelected() {
      // // console.log("Первая кнопка");
      this.selec1 = this.getSelected1MenuCheckbox.departments;
      this.selec2 = this.getSelected1MenuCheckbox.employees
    },
    setSelected1() {
      // // console.log("Вторая кнопка");
      this.selec1 = this.getSelected2MenuCheckbox.departments;
      this.selec2 = this.getSelected2MenuCheckbox.employees
    },
    changeCheckbox1(selected) {
      this.departmentsSelected = selected;
    },
    changeCheckbox2(selected) {
      this.employeesSelected = selected;
    },
    hideMenu() {
      this.show=false;
      this.setShowAddDepartment(false);

      this.name = "";
      this.selected = [];
      this.permissions = [];
      this.clearActiveCheckboxInMenuAddDepartment();
    },
    showMenu() {
      // console.log(1);
      this.show = true;
      this.renderCheckbox();
      this.permissions = [];
      for(let i=0; i< this.getPermissionsAddDepartment.length; i++) {
        this.permissions.push(this.getPermissionsAddDepartment[i]);
      }
      this.setShowAddDepartment(true);
    },
    // setSelected(selected1, selected2) {
    //   this.changeCheckbox1(selected1);
    //   this.changeCheckbox2(selected2);
    //   this.$refs.ch1.selSel(selected1);
    //   this.$refs.ch2.selSel(selected2);
    // },
      ucFirst(str) {
      if (!str) return str;

      return str[0].toUpperCase() + str.slice(1);
      },
    clickSave() {
      this.name = this.name.trim();
      this.name = this.ucFirst(this.name);
      if(this.name.length === 0) {
        this.setAlert({show: true, text: "Enter the department name, please."});
      } else {
        if(this.selected.length !==0) {
          let count = false;
          for(let i=0; i<this.selected.length; i++) {
            if(this.selected[i].selected.length !== 0) {
              count = true;
            }
          }
          if(count) {
            let axiosPermissions = [];
            for(let i=0; i<this.selected.length; i++) {
              if(this.selected[i].selected.length !== 0) {
                for(let j=0; j<this.selected[i].selected.length; j++) {
                  axiosPermissions.push(this.selected[i].selected[j].id);
                }
              }
            }
            this.axiosAddDepartments({name: this.name}).then( async ()=> {
              if(this.getResponseAddDepartments.status === 201) {
                // console.log("Response DepAdd");
                for(let i=0; i< this.selected.length; i++) {
                  // console.log("for1")
                  for(let j=0; j<this.selected[i].selected.length; j++) {
                    // console.log(this.selected[i].selected[j].id);
                    // console.log("for2")
                    let related_department = [];
                    if(this.selected[i].selected[j].activeCheckboxInMenu.departments.length!==0) {
                      for(let m=0; m<this.selected[i].selected[j].activeCheckboxInMenu.departments.length; m++) {
                        related_department.push(this.selected[i].selected[j].activeCheckboxInMenu.departments[m].id)
                      }
                    }
                    let related_staff = [];
                    if(this.selected[i].selected[j].activeCheckboxInMenu.employees.length!==0) {
                      // console.log("for4")
                      for(let m=0; m<this.selected[i].selected[j].activeCheckboxInMenu.employees.length; m++) {
                        related_staff.push(this.selected[i].selected[j].activeCheckboxInMenu.employees[m].id)
                      }
                    }
                    let related_account = [];
                    if(Array.isArray(related_account = this.selected[i].selected[j].activeCheckboxInMenu.account)) {
                      related_account = this.selected[i].selected[j].activeCheckboxInMenu.account.map(a=> {
                        return a.id
                      })
                    }
                    let such_columns = this.selected[i].selected[j].activeCheckboxInMenu.columns !== null ? this.selected[i].selected[j].activeCheckboxInMenu.columns : []

                    await this.axiosAddPermissionsNewDepartments({type: this.selected[i].selected[j].id, such_columns:such_columns, department: this.getResponseAddDepartments.data.id, related_staff: related_staff, related_department: related_department, related_account: related_account});
                  }
                }
                // this.addPermissionsNewDepartments({type: this.getResponseAddDepartments.data.id, department})

                this.setAlert({show: true, text: "Department added successfully!"});
                this.axiosGetDepartments();
                this.hideMenu();
                this.$emit("addOk");
              }
            });
          } else {
            this.setAlert({show: true, text: "Select Permissions for this department."});
          }
        } else {
          this.setAlert({show: true, text: "Select Permissions for this department."});
        }
      }
    }
  },
  mounted() {
  }
}
</script>



<style scoped lang="scss">
@import "./src/components/style-element/bootstrap";
@import "./src/components/style-element/checkbox";
.title1 {
  margin: 20px 0 0 0 !important;
}

.block-input {
  margin-top: 20px;
  width: 100%;
  p {
    margin-bottom: 4px;
  }
  input {
    height: 36px !important;
    width: 100% !important;
  }
}
/deep/ p {
  margin-bottom: 0;
}
.bottom-panel {
  height: 44px;
  width: 480px;
  padding: 4px 56px;
  background: var(--color-modal);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: fixed;
  z-index: 350;
  bottom: 0;
  right: 0;
  //right: 56px;
  .blue-button {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    @media (max-width: 1280px) {
      width: 368px;
    }
    @media (max-width: 768px) {
      width: 93.75vw;
    }
  }
  @media (max-width: 1280px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    right: 0;
  }
  @media (max-width: 768px) {
    padding: 4px 0;
  }
}
.checkboxes-block {
  @media (min-width: 768px) {
    padding-bottom: 44px;
  }
  &>* {
    padding: 16px 0;
    border-top: 1px solid var(--color-card-border);
    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }
  &>*:first-child {
    border-top: none !important;
  }
  padding-bottom: 24px;
}
.search-block {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  width: 100% !important;
  svg {
    position: absolute;
    left: 10px;
  }
  input {
    width: 100% !important;
    height: 32px !important;
    padding-left: 42px;

  }

  margin-bottom: 21px;
}
.wrap-content {
  width: 100vw;
  height: 100%;
  z-index: 30000;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  body {
    overflow: hidden;
  }
}
.filter {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  right: 0;
  opacity: 0.15;
  background-color: var(--color-filter-modal);
}
.dart-mode {
  .filter {
    opacity: 0.75;
  }
}
.content {
  .close-menu {
    position: absolute;
    left: 20px;
    top: 17px;
    @media (max-width: 1280px) {
      left: auto;
      right: 16px;
      top: 16px;
    }
  }
  .wrap-block {
    position: relative;
    padding: 16px 56px 0px 56px;
    min-height: calc(100% - 44px);
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    @media (max-width: 1280px) {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      padding: 18px 8px 0 0;
      &>*:not(.close-menu) {
        width: 360px !important;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      padding: 18px 0;
      &>*:not(.close-menu) {
        width: 93.75vw !important;
      }
    }
  }
  width: 480px;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-modal);
  z-index: 300;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s linear;
  .title {
    margin-bottom: 14px;
  }
  &>* {
    //padding-right: 56px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    //flex-direction: column;
    //align-items: center;
  }
}
.show-menu {
  transform: translateX(0);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  .content {
    transform: translateX(100%);
  }
}
.blue-button-text {
  display: inline;
}
.border-block {
  width: 100%;
  height: 100%;
  padding: 16px 20px;
}

</style>