<template>
  <b-dropdown left ref="dropdown" id="dropdown-1" @hide="hideDropdown" @show="showDrop">
    <template #button-content>
      <button class="button-drop">
        <span class="text-gray" v-if="selectedTag.length <= 0">Select option</span>
        <div class="wrap-button-tag" v-for="(t, i) in selectedTag" :key="i">
           <div class="button-tag" v-if="i<2" v-on:click="clickTagCheck(i)" :class="{'editMode': t.editStatus}">
          <p class="fz13 text-dark" :title="t.name">{{ t.name }}</p>
            <b-dropdown right >
              <template #button-content>
                <button class="button-drop-tags fz13 fw500" v-on:click="unselectTag(t)">
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.05373 4.42533C2.81022 4.18421 2.81022 3.79329 3.05373 3.55217C3.29724 3.31106 3.69206 3.31106 3.93557 3.55217L8.94574 8.47332C9.18925 8.71444 9.18925 9.10536 8.94574 9.34647C8.70223 9.58759 8.30741 9.58759 8.0639 9.34647L3.05373 4.42533Z" fill="#319FEF"/>
                    <path d="M8.06398 3.65356C8.3075 3.41245 8.70231 3.41245 8.94582 3.65356C9.18933 3.89468 9.18933 4.2856 8.94582 4.52672L3.93565 9.44786C3.69214 9.68898 3.29732 9.68898 3.05381 9.44786C2.8103 9.20675 2.8103 8.81582 3.05381 8.57471L8.06398 3.65356Z" fill="#319FEF"/>
                  </svg>
                </button>
              </template>
            </b-dropdown>
        </div>
        </div>
        <div class="numberMore fz11 text-dark" v-if="selectedTag.length>2">+{{selectedTag.length - 2}}</div>


        <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/>
        </svg>
      </button>
    </template>
    <div class="content">
      <input type="text" class="search" v-model="searchText" :ref="'searchTextInput'+idref" @input="searchTag" placeholder="Search or Create new" v-on:keyup.enter="submitEnter">
      <button v-on:click="selectTag(i)" class="fz13 text-dark" v-for="(t,i) in tag" :key="i" :class="{'active-btn': t.status, 'editMode': t.editStatus}">
        <input class="fz13" type="text" v-model="t.name" v-if="t.editStatus" v-on:keyup.enter="renameTagAxios(t)">
        <div class="button-tag" v-if="!t.editStatus">
          <p class="fz13 text-dark" :title="t.name">{{ t.name }}</p>
          <div class="tag-options" :class="{'open-drop': isDropdown2Visible}" >
            <b-dropdown right ref="dropdown1"  id="dropdown-2">
              <template #button-content>
                <button class="button-drop-tags fz13 fw500">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.44 10.5601C7.23529 10.5601 7.88 11.2048 7.88 12.0001C7.88 12.7954 7.23529 13.4401 6.44 13.4401C5.64471 13.4401 5 12.7954 5 12.0001C5 11.2048 5.64471 10.5601 6.44 10.5601Z" fill="#A3A3A3"/>
                    <circle cx="12.0001" cy="12" r="1.44" transform="rotate(-90 12.0001 12)" fill="#A3A3A3"/>
                    <path d="M17.5601 10.5601C18.3554 10.5601 19.0001 11.2048 19.0001 12.0001C19.0001 12.7954 18.3554 13.4401 17.5601 13.4401C16.7648 13.4401 16.1201 12.7954 16.1201 12.0001C16.1201 11.2048 16.7648 10.5601 17.5601 10.5601Z" fill="#A3A3A3"/>
                  </svg>
                </button>
              </template>
              <div class="content-tags">
                <button class="fz13 text-dark" v-on:click="deleteTag(t)" v-if="getPermissionsForUserCanDeleteTags">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
                  </svg>
                  Delete
                </button>
              </div>
            </b-dropdown>
          </div>
        </div>
      </button>
    </div>
  </b-dropdown>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  components: {},
  computed: mapGetters(["getPermissionsForUserCanDeleteTags","getAllTag"]),
  name: "tagSelect",
  props: {
    clear: {
      type: Boolean,
      default: false
    },
    defaultTags: {
      type: Array
    }
  },
  data() {
    return {
      timeActive: "",
      tag: [],
      prevIndex: null,
      prevIndex2: null,
      tagCopy: [],
      selectedTag: [],
      searchText: "",
      isDropdown2Visible: false,
      idref: null
    }
  },
  methods: {
    ...mapActions(["getAxiosTagsUsage", "getAxiosAllTag", "axiosTagCreate", "axiosDeleteTag", "axiosRenameTag"]),
    showDrop() {
      setTimeout(()=> this.focusOnSearch(), 150)
    },
    focusOnSearch() {
      let searchTextInout = this.$refs['searchTextInput'+this.idref];
      if(searchTextInout)
        searchTextInout.focus();
    },
    setDefaultTags(activeTAgs) {
      if(activeTAgs.length != 0){
        this.tag = JSON.parse(JSON.stringify(this.getAllTag))
        setTimeout(() => {
          for(var i = 0; i < activeTAgs.length; i++){
            let tagIndex = this.tag.findIndex(el => {
              return el.id == activeTAgs[i]
            })
            this.selectTag(tagIndex)
          }
        }, 1000)
        
      }
    },
    hideDropdown() {
      this.searchText = "";
      this.searchTag();
      this.$refs.dropdown.hide(true);
    },
    searchTag() {
      if(this.searchText.length !== 0) {
        let text = (("" + this.searchText).replace(/\s/g, '')).toLowerCase();
        let searchArray = [];
        for(let i=0; i<this.tag.length; i++) {
          let textTag = (("" + this.tag[i].name).replace(/\s/g, '')).toLowerCase();
          // // console.log(this.tag[i]);
          // // console.log(text);
          // // console.log(textTag);
          if(textTag.indexOf(text) !== -1) {
            searchArray.push(this.tag[i]);
          }
        }
        this.tag = JSON.parse(JSON.stringify(searchArray));
      } else {
        this.tag = JSON.parse(JSON.stringify(this.tagCopy));
      }


    },
    renameTagAxios(t) {
      this.axiosRenameTag({id: t.id, name: t.name}).then(()=> {
        this.renderTags();
    });
    },
    renderTags() {
      this.getAxiosAllTag().then(()=> {
        this.tag = JSON.parse(JSON.stringify(this.getAllTag));
        this.selectedTag.splice(0,this.selectedTag.length);
        for(let i=0; i<this.tagCopy.length; i++) {
          for(let j=0; j<this.tag.length; j++) {
            if(this.tagCopy[i].id === this.tag[j].id) {
              this.tag[j].status = this.tagCopy[i].status;
              if(this.tag[j].status) {
                this.selectedTag.push(this.tag[j]);
              }
            }
          }
        }
        this.tagCopy = JSON.parse(JSON.stringify(this.tag));
        this.searchTag();
      });
    },
    submitEnter() {
      if(this.tag.filter(t=> t.name === this.searchText).length === 0 && this.searchText.length !==0) {
        this.axiosTagCreate({name:this.searchText}).then(()=> {
          this.renderTags();
        })
      }
    },
    selectTag(index) {
      if(index!==this.prevIndex && this.tag[index].editStatus === false) {
        for(let i=0; i<this.tag.length; i++) {
          this.tag[i].editStatus = false;
          this.isDropdown2Visible = false;
        }
      }
      if(!this.isDropdown2Visible && this.tag[index].editStatus === false) {
        this.tag[index].status = !this.tag[index].status;
        for(let i=0; i<this.tagCopy.length; i++) {
          if(this.tagCopy[i].id === this.tag[index].id) {
            this.tagCopy[i].status = !this.tagCopy[i].status;
          }
        }
        if(this.tag[index].status) {
          this.selectedTag.push(this.tag[index])
          this.$refs.dropdown.hide(true);
        } else {
          for(let i=0; i<this.selectedTag.length; i++) {
            if(this.tag[index].id === this.selectedTag[i].id) {
              this.selectedTag.splice(i,1);
            }
          }
          this.$refs.dropdown.hide(true);
        }
      }
      this.prevIndex = index;
      this.getTagsForAxios()
    },
    renameTag(index) {
      this.tag[index].editStatus = true;
    },
    renameTag2(index) {
      this.selectedTag[index].editStatus = true;
      this.selectedTag = JSON.parse(JSON.stringify(this.selectedTag));
    },
    clickTagCheck(index) {
      this.selectedTag = JSON.parse(JSON.stringify(this.selectedTag));
      if(index !== this.prevIndex2 && this.selectedTag[index].editStatus === false) {
        for(let i=0; i<this.selectedTag.length; i++) {
          this.selectedTag[i].editStatus = false;
        }
      }
      this.prevIndex2 = index;
    },
    deleteTag(tag) {
      this.$emit('tagDeleted', tag)
      if(this.getPermissionsForUserCanDeleteTags) {
        this.getAxiosTagsUsage({tag: tag}).then(response => {
          if(!response) {
            this.axiosDeleteTag(tag.id).then(()=> {
              this.renderTags();
            });
          } else {
            this.$emit("deleteUsageTag", tag)
          }
        });
      }
      

    },
    unselectTag(tag) {
      for(let i=0; i<this.selectedTag.length; i++) {
        if(this.selectedTag[i].id === tag.id) {
          this.selectedTag.splice(i,1);
        }
      }
      for(let i=0; i<this.tag.length; i++) {
        if(this.tag[i].id === tag.id) {
          this.tag[i].status = false;
        }
      }
      for(let i=0; i<this.tagCopy.length; i++) {
        if(this.tagCopy[i].id === tag.id) {
          this.tagCopy[i].status = false;
        }
      }
      this.getTagsForAxios()
    },
    getTagsForAxios() {
      let array = [];
      for(let i=0; i<this.selectedTag.length; i++) {
        array.push(this.selectedTag[i].id);
      }
      this.$emit("emitGetTags", array);
    },
    selectForDuplicate(data) {
      for(let i=0;i<this.tag.length; i++) {
        for(let j=0; j<data.length; j++) {
          if(this.tag[i].id === data[j].id) {
            this.selectTag(i);
            j=data.length;
          }
        }
      }
    },
    clearData() {
      this.selectedTag.splice(0,this.selectedTag.length);
      this.getAxiosAllTag().then(()=> {
        this.tag = JSON.parse(JSON.stringify(this.getAllTag));
        this.tagCopy = JSON.parse(JSON.stringify(this.getAllTag));
      });
      this.$root.$on('bv::dropdown::show', bvEvent => {
        if(bvEvent.componentId === 'dropdown-2') {
          this.isDropdown2Visible = true;
        }
      })
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        if(bvEvent.componentId === 'dropdown-2') {
          this.isDropdown2Visible = false;
        }
        if(this.isDropdown2Visible) {
          bvEvent.preventDefault()
        }
      })
    },
  },
  mounted() {
    this.idref = Math.random() + ''+Math.random()
    this.getAxiosAllTag().then(()=> {
      this.tag = JSON.parse(JSON.stringify(this.getAllTag));
      this.tagCopy = JSON.parse(JSON.stringify(this.getAllTag));
     
    });
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if(bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true;
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if(bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false;
      }
      if(this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

  },
  watch: {
    clear: function(val){
      
      if(val){
        this.selectedTag = []
        this.tag.forEach(el => {
          el.status = false;
        });
      }
    },
    // defaultTags: function(val){
    //   if(val){
    //     for(var i = 0; i < val.length; i++){
    //       let tagIndex = this.tag.findIndex(el => {
    //         return el.id == val[i]
    //       })
    //       this.selectTag(tagIndex)
        
    //     }
    //   }
    // }
  },
}
</script>

<style scoped lang="scss">
@import "../style-element/dropdown";
.removeInSelect {
  position: relative;
  z-index: 200;
}
/deep/ .show {
  .arrow {
    position: absolute;
    right: 8px;
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  //margin: 2px;
  //top: 36px;
  width: 100%;
  background: transparent;
  border: none;
  min-width: 1px;
  box-sizing: border-box;
  top: auto!important;
  bottom: auto !important;
  transform: none !important;
}
.content {
  background-color: var(--color-modal);
  width: 100%;
  max-height: 260px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  &>button {
    text-align: left;
    transition: background-color .1s linear;
    background-color: var(--color-modal);
    height: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 7px 12px;
    &:first-child {
      border-radius: 4px 4px 0 0px;
    }
    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }
    &:hover {
      background-color: var(--color-input);
    }
  }
  .editMode {
    padding: 0;
    input {
      height: 30px;
    }
  }
}
.button-drop {
  box-shadow: none !important;
  background: var(--color-input);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  border-radius: 4px;
  height: 36px;
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-dark);
  resize: none;
  transition: .2s linear;
  &:hover {
    border-color: var(--color-gray);
  }
  &:focus {
    border-color: var(--color-blue-dark);
  }

  padding-right: 30px;
  display: flex;
  align-items: center;
  &>svg {
    position: absolute;
    right: 8px;
    margin-left: 6px;
  }
  .tag-options {
    position: absolute;
    top: 0;
    right: 0;
  }
  .button-drop-tags {
    display: none;
  }
  .wrap-button-tag {
    width: max-content;
    max-width: 40%;
  }
  .numberMore {
    padding: 2px 6px;
    border: 1px solid var(--color-border);
    border-radius: 10px;

  }
  .button-tag {
    margin-right: 4px;
    width: 100%;
    max-width: 100%;
    //position: relative;
    //z-index: 1000;
    &:hover {
      .button-drop-tags {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 14px;
        background: var(--color-input);
        //border-radius: 10px 10px;
        position: absolute;
        right: 0;
        transform: none !important;
        * {
          transform: none !important;
        }
      }
    }
  }
  .editMode {
    padding: 0;
    border: none;
    p {
      opacity: 0;
    }
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      border: 1px solid var(--color-blue-dark);
      border-radius: 4px;
      background: var(--color-input);
    }
  }
}
/deep/ .btn {
  width: 100%;
  background: transparent !important;
}

.active-btn {
  background-image: url(../../assets/image/Select.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

.search {
  border-radius:4px 4px 0 0;
  border: 1px solid var(--color-border);
  height: 36px;
  box-sizing: border-box;
  background: var(--color-modal);
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  top: -38px;
}


.button-tag {
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 65%;
  box-sizing: border-box;
  height: 17px;
  padding: 2px 6px;
  border-radius:  0 50px 50px 0;
  border: 1px solid var(--color-border);
  p {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
  }
  .tag-options {
    display: none;
    position: absolute;
    z-index: 20;
    right: 0;
    height: 100%;
    width: max-content;
    padding: 0 2px;
    background-color: var(--color-bgc-page);
    border-radius: 0 20px 20px 0;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      display: none !important;
    }
  }
  position: relative;
  &:hover {
    // .tag-options {
    //   display: flex;
    // }
  }
  /deep/  .dropdown-menu {
    margin: 0;
  }
}

.open-drop {
  display: flex ;
}

.content-tags {
  width: 96px;
  background-color: var(--color-modal);
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  .title {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 5px 12px;
    display: flex;
    align-items: center;
    svg {
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    transition: .1s linear;
    &:hover {
      color: #2C2C2C !important;
    }
  }
  &>button{
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    transition: background-color .1s linear;
    &:hover {
      background-color: var(--color-input);
    }
    &:first-child {
      padding-top: 4px;
      padding-bottom: 2px;
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      padding-bottom: 4px;
      padding-top: 2px;
      border-radius: 0 0 4px 4px;
    }
  }
}

.button-drop-tags {
  transition: .1s linear;
  svg {
    path, circle {
      transition: .1s linear;
      fill: var(--color-blue-dark);
    }
  }
  &:hover {
    svg {
      path, circle {
        fill: var(--color-blue);
      }
    }
    transform: scale(1.2);
  }
}


.tag-options {
  .content-tags {
    position: relative;
    transform: none !important;
    top: -18px!important;
    left: 0 !important;
  }
}
.btn.dropdown-toggle{
    position: relative;
    border: 1px solid red;
}


</style>