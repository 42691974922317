<template>
  <div class="modal-wrap">
    <div class="modal-bg"></div>
    <div class="modal-content">
      <div class="modal-content_head">
        <p class="modal-content_title" v-if="account">Edit Account <button class="modal-close" @click="closeModal()"></button></p>
        <p class="modal-content_title" v-else>Create new Account <button class="modal-close" @click="closeModal()"></button></p>
      </div>
      <div class="modal-content_body">

        <div class="block-input">
          <p class="block-input_title text-gray">Type</p>
          <div class="trial-balance_switcher">
            <label :class="{'active': trialType == 'active'}"><span>Active</span><input type="radio" value="active" v-model="trialType"></label>
            <label :class="{'active': trialType == 'passive'}"><span>Passive</span><input type="radio" value="passive" v-model="trialType"></label>
          </div>
        </div>

        <label class="block-input" :class="{ 'block-input_error': $v.acc.$error }">
          <p class="block-input_title text-gray">Acc#</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.number.trim="$v.acc.$model" @input="ifIdExists = false">
          <div class="error" v-if="!$v.acc.required && $v.acc.$dirty">Field is required</div>
          <div class="error" v-if="!$v.acc.numeric && $v.acc.$dirty">Field must be a number</div>
          <div class="error" v-if="!$v.acc.maxLength">ID must have at max {{$v.acc.$params.maxLength.max}} letters.</div>
          <div class="error" v-if="ifIdExists">This Acc# is already used</div>
        </label>

        <label class="block-input" :class="{ 'block-input_error': $v.name.$error }">
          <p class="block-input_title text-gray">Account Name</p>
          <input class="block-input_content " type="text" maxlength="255" v-model.trim="$v.name.$model" @input="$v.name.$model = $v.name.$model.toUpperCase()">
          <div class="error" v-if="!$v.name.required && $v.name.$dirty">Field is required</div>
          <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div>
          <div class="error" v-if="!$v.name.maxLength">Name must have at max {{$v.name.$params.maxLength.max}} letters.</div>
        </label>

        <counterparties-drop 
          :class="{ 'block-input_error': $v.selectFS.$error }"
          ref="drop"
          :list="financialStatement" 
          :activeItem="activeFS ? activeFS : null"
          @select="selectedFs" 
          title="Financial Statement"
          class="list-item-caps"
        ></counterparties-drop>
        <div class="error" v-if="!$v.selectFS.required && $v.selectFS.$dirty">Field is required</div>

        <trial-balance-edit-fs />

        <trial-balance-operations
          ref="dropOperations"
          :list="operations"
          :activeItem="activeOperations"
          @select="selectedOperations"
          title="Operations"
        />

        <label class="block-input">
          <p class="block-input_title text-gray">Note (optional)</p>
          <textarea class="block-input_content" rows="3" maxlength="2000" v-model="description"></textarea>
        </label>

      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal">Cancel</button>
        <button class="btn btn-next" @click="onSubmit">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from "vuex"
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength, numeric } from 'vuelidate/lib/validators'

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop';
import trialBalanceEditFs from '@/components/accounts/trials/trialBalanceEditFS';
import trialBalanceOperations from '@/components/accounts/trials/trialBalanceOperations';
export default {
  mixins: [validationMixin],
  props: ['account', 'duplAccount'],
  components: {
    counterpartiesDrop,
    trialBalanceEditFs,
    trialBalanceOperations,
  },
  data() {
    return {
      oldId: null,
      acc: null,
      trialType: 'active',
      name: '',
      description: '',
      selectFS: '',
      activeFS: null,
      activeOperations: [],
      ifIdExists: false,
      operations: [
        //name, id, strong, border
        {
          list: [
            {
              name: 'N/A',
              id: 'N/A'
            }
          ]
        },
        {
          name: 'STATEMENT OF ACCOUNT WITH OWNERS',
          list: [
            {
              name: 'Charter Hire',
              id: 'Charter Hire',
              strong: true
            },
            {
              name: 'Commission',
              id: 'Charter Hire:Commission',
            },
            {
              name: 'Broker Commission',
              id: 'Charter Hire:Broker Commission',
              border: true
            },

            {
              name: 'Bunkers on Delivery',
              id: 'Bunkers on Delivery',
              strong: true
            },
            {
              name: 'VLSFO',
              id: 'Bunkers on Delivery:VLSFO',
            },
            {
              name: 'LSMGO',
              id: 'Bunkers on Delivery:LSMGO',
            },
            {
              name: 'HSFO',
              id: 'Bunkers on Delivery:HSFO',
            },
            {
              name: 'MGO',
              id: 'Bunkers on Delivery:MGO',
            },
            {
              name: 'VLSFO (add.)',
              id: 'Bunkers on Delivery:VLSFO (add.)',
            },
            {
              name: 'LSMGO (add.)',
              id: 'Bunkers on Delivery:LSMGO (add.)',
              border: true
            },

            {
              name: 'Bunkers on Redelivery',
              id: 'Bunkers on Redelivery',
              strong: true
            },
            {
              name: 'VLSFO',
              id: 'Bunkers on Redelivery:VLSFO',
            },
            {
              name: 'LSMGO',
              id: 'Bunkers on Redelivery:LSMGO',
            },
            {
              name: 'HSFO',
              id: 'Bunkers on Redelivery:HSFO',
            },
            {
              name: 'MGO',
              id: 'Bunkers on Redelivery:MGO',
            },
            {
              name: 'VLSFO (add.)',
              id: 'Bunkers on Redelivery:VLSFO (add.)',
            },
            {
              name: 'LSMGO (add.)',
              id: 'Bunkers on Redelivery:LSMGO (add.)',
              border: true

            },
            {
              name: 'C/E/V',
              id: 'Bunkers on Redelivery:Bunkers on Dev./Redev.: C/E/V',
              border: true
            },

            {
              name: 'Ballast Bonus',
              id: 'Ballast Bonus',
              strong: true,
              disabled: true
            },
            {
              name: 'Commission',
              id: 'Ballast Bonus:Commission',
            },
            {
              name: 'Gross ballast bonus',
              id: 'Ballast Bonus:Gross ballast bonus',
            },
            {
              name: 'Net ballast bonus',
              id: 'Ballast Bonus:Net ballast bonus',
              border: true
            },

            {
              name: 'Deposit Paid by Chrts',
              id: 'Deposit Paid by Chrts',
              strong: true
            },
            {
              name: 'Deposit Revert to Chrts',
              id: 'Deposit Revert to Chrts',
              strong: true,
              border: true,
            },

            {
              name: 'Excess bunker on redelivery',
              id: 'Excess bunker on redelivery',
              strong: true,
            },
            {
              name: 'VLSFO',
              id: 'Excess bunker on redelivery:VLSFO',
            },
            {
              name: 'LSMGO',
              id: 'Excess bunker on redelivery:LSMGO',
              border: true,
            },

            {
              name: 'Bunker Supply by Owns',
              id: 'Bunker Supply by Owns',
              strong: true,
            },
            {
              name: 'VLSFO',
              id: 'Bunker Supply by Owns:VLSFO',
            },
            {
              name: 'LSMGO',
              id: 'Bunker Supply by Owns:LSMGO',
              border: true,
            },

            {
              name: 'Off-Hire',
              id: 'Off-Hire',
              strong: true,
            },
            {
              name: 'Days',
              id: 'Off-Hire:Days',
            },
            {
              name: 'Commission',
              id: 'Off-Hire:Commission',
            },
            {
              name: 'C/E/V',
              id: 'Off-Hire:C/E/V',
            },
            {
              name: 'HSFO',
              id: 'Off-Hire:HSFO',
            },
            {
              name: 'LSMGO',
              id: 'Off-Hire:LSMGO',
              border: true,
            },

            {
              name: 'Speed Claim',
              id: 'Speed Claim',
              strong: true,
            },
            {
              name: 'Time Lost',
              id: 'Speed Claim:Time Lost',
            },
            {
              name: 'Commission',
              id: 'Speed Claim:Commission',
              border: true,
            },
            {
              name: 'C/E/V',
              id: 'Speed Claim:C/E/V',
            },
            {
              name: 'HSFO',
              id: 'Speed Claim:HSFO',
            },
            {
              name: 'LSMGO',
              id: 'Speed Claim:LSMGO',
              border: true,
            },

            {
              name: 'Charterers Expenses',
              id: 'Charterers Expenses',
              strong: true,
              border: true,
            },
            {
              name: 'Owners Expenses',
              id: 'Owners Expenses',
              strong: true,
            },
          ]
        },
        {
          name: 'STATEMENT OF ACCOUNT IN SUBLET',
          list: [
            {
              name: 'Charter Hire',
              id: 'SUBLET_Charter Hire',
              strong: true
            },
            {
              name: 'Commission',
              id: 'SUBLET_Charter Hire:Commission',
            },
            {
              name: 'Broker Commission',
              id: 'SUBLET_Charter Hire:Broker Commission',
              border: true
            },

            {
              name: 'Bunkers on Delivery',
              id: 'SUBLET_Bunkers on Delivery',
              strong: true
            },
            {
              name: 'VLSFO',
              id: 'SUBLET_Bunkers on Delivery:VLSFO',
            },
            {
              name: 'LSMGO',
              id: 'SUBLET_Bunkers on Delivery:LSMGO',
            },
            {
              name: 'HSFO',
              id: 'SUBLET_Bunkers on Delivery:HSFO',
            },
            {
              name: 'MGO',
              id: 'SUBLET_Bunkers on Delivery:MGO',
            },
            {
              name: 'VLSFO (add.)',
              id: 'SUBLET_Bunkers on Delivery:VLSFO (add.)',
            },
            {
              name: 'LSMGO (add.)',
              id: 'SUBLET_Bunkers on Delivery:LSMGO (add.)',
              border: true
            },

            {
              name: 'Bunkers on Redelivery',
              id: 'SUBLET_Bunkers on Redelivery',
              strong: true
            },
            {
              name: 'VLSFO',
              id: 'SUBLET_Bunkers on Redelivery:VLSFO',
            },
            {
              name: 'LSMGO',
              id: 'SUBLET_Bunkers on Redelivery:LSMGO',
            },
            {
              name: 'HSFO',
              id: 'SUBLET_Bunkers on Redelivery:HSFO',
            },
            {
              name: 'MGO',
              id: 'SUBLET_Bunkers on Redelivery:MGO',
            },
            {
              name: 'VLSFO (add.)',
              id: 'SUBLET_Bunkers on Redelivery:VLSFO (add.)',
            },
            {
              name: 'LSMGO (add.)',
              id: 'SUBLET_Bunkers on Redelivery:LSMGO (add.)',
              border: true
            },
            {
              name: 'C/E/V',
              id: 'SUBLET_Bunkers on Redelivery:Bunkers on Dev./Redev.: C/E/V',
              border: true
            },

            {
              name: 'Ballast Bonus',
              id: 'SUBLET_Ballast Bonus',
              strong: true,
              disabled: true
            },
            {
              name: 'Commission',
              id: 'SUBLET_Ballast Bonus:Commission',
            },
            {
              name: 'Gross ballast bonus',
              id: 'SUBLET_Ballast Bonus:Gross ballast bonus',
            },
            {
              name: 'Net ballast bonus',
              id: 'SUBLET_Ballast Bonus:Net ballast bonus',
              border: true
            },

            {
              name: 'Deposit Paid by Chrts',
              id: 'SUBLET_Deposit Paid by Chrts',
              strong: true
            },
            {
              name: 'Deposit Revert to Chrts',
              id: 'SUBLET_Deposit Revert to Chrts',
              strong: true,
              border: true,
            },

            {
              name: 'Excess bunker on redelivery',
              id: 'SUBLET_Excess bunker on redelivery',
              strong: true,
            },
            {
              name: 'VLSFO',
              id: 'SUBLET_Excess bunker on redelivery:VLSFO',
            },
            {
              name: 'LSMGO',
              id: 'SUBLET_Excess bunker on redelivery:LSMGO',
              border: true,
            },

            {
              name: 'Bunker Supply by Owns',
              id: 'SUBLET_Bunker Supply by Owns',
              strong: true,
            },
            {
              name: 'VLSFO',
              id: 'SUBLET_Bunker Supply by Owns:VLSFO',
            },
            {
              name: 'LSMGO',
              id: 'SUBLET_Bunker Supply by Owns:LSMGO',
              border: true,
            },

            {
              name: 'Off-Hire',
              id: 'SUBLET_Off-Hire',
              strong: true,
            },
            {
              name: 'Days',
              id: 'SUBLET_Off-Hire:Days',
            },
            {
              name: 'Commission',
              id: 'SUBLET_Off-Hire:Commission',
            },
            {
              name: 'C/E/V',
              id: 'SUBLET_Off-Hire:C/E/V',
            },
            {
              name: 'HSFO',
              id: 'SUBLET_Off-Hire:HSFO',
            },
            {
              name: 'LSMGO',
              id: 'SUBLET_Off-Hire:LSMGO',
              border: true,
            },

            {
              name: 'Speed Claim',
              id: 'SUBLET_Speed Claim',
              strong: true,
            },
            {
              name: 'Time Lost',
              id: 'SUBLET_Speed Claim:Time Lost',
            },
            {
              name: 'Commission',
              id: 'SUBLET_Speed Claim:Commission',
            },
            {
              name: 'C/E/V',
              id: 'SUBLET_Speed Claim:C/E/V',
            },
            {
              name: 'HSFO',
              id: 'SUBLET_Speed Claim:HSFO',
            },
            {
              name: 'LSMGO',
              id: 'SUBLET_Speed Claim:LSMGO',
              border: true,
            },

            {
              name: 'Charterers Expenses',
              id: 'SUBLET_Charterers Expenses',
              strong: true,
              border: true,
            },
            {
              name: 'Owners Expenses',
              id: 'SUBLET_Owners Expenses',
              strong: true,
            },
          ]
        },
        {
          name: 'VOYAGE STATEMENT OF ACCOUNT',
          list: [
            {
              name: 'Thereof',
              id: 'Thereof',
              strong: true,
              border: true,
            },

            {
              name: 'Less Adress Commission',
              id: 'Less Adress Commission',
              strong: true,
              border: true,
            },

            {
              name: 'Less Brokerage Commission',
              id: 'Less Brokerage Commission',
              strong: true,
            },
            {
              name: 'Less Tax on Brokerage Commission',
              id: 'Less Brokerage Commission:Less Tax on Brokerage Commission',
              border: true,
            },

            {
              name: 'Plus Demurrage',
              id: 'Plus Demurrage',
              strong: true,
            },
            {
              name: 'Less Address Commission',
              id: 'Plus Demurrage:Less Address Commission',
            },
            {
              name: 'Less Brokerage Commission',
              id: 'Plus Demurrage:Less Brokerage Commission',
            },
            {
              name: 'Less Tax on Brokerage Commission',
              id: 'Plus Demurrage:Less Tax on Brokerage Commission',
              border: true,
            },

            {
              name: 'Less Dispatch',
              id: 'Less Dispatch',
              strong: true,
              border: true,
            },

            {
              name: 'Plus detention',
              id: 'Plus detention',
              strong: true,
            },
            {
              name: 'Less Commision',
              id: 'Plus detention:Less Commision',
              border: true,
            },
            
            {
              name: `Plus Charterers' expenses`,
              id: `Plus Charterers' expenses`,
              strong: true,
              border: true,
            },
            
            {
              name: `Less Owners' expenses`,
              id: `Less Owners' expenses`,
              strong: true,
            }
          ]
        }
      ]
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(2)
    },
    acc: {
      required,
      numeric,
      maxLength: maxLength(255),
    },
    selectFS: {
      required
    },
  },
  computed: {
    ...mapState({
      financialStatement: state => state.trialBalance.financialStatement
    }),
    ...mapGetters(["getPermissionsByType"]),
    getFieldsInObj() {
      return {
        account_id: this.acc,
        type: this.trialType,
        name: this.name,
        description: this.description,
        financial_statement: this.selectFS,
        accounts_field: this.activeOperations
      }
    }
  },
  methods: {
    ...mapActions(['axiosAddTrialAccount', 'axiosEditTrialAccount', 'axiosGetTrials']),
    ...mapMutations(['showLoad', 'hideLoad']),
    closeModal() {
      this.$emit('close')
    },
    async onSubmit() {
      if(this.validateFields()){
        this.showLoad()
        let response
        if(this.account){
          response = await this.axiosEditTrialAccount({account: this.getFieldsInObj, id: this.oldId})
        }else{
          response = await this.axiosAddTrialAccount(this.getFieldsInObj)
        }
        if(response){
          this.axiosGetTrials()
          this.closeModal()
          this.$emit('added')
        }else{
          this.ifIdExists = true
        }

        this.hideLoad()
      }
    },
    validateFields() {
      this.$v.$touch()
      if (this.$v.$invalid)
        return false
      return true
    },
    selectedFs(item) {
      this.selectFS = item.id
      this.$v.selectFS.$touch()
    },
    selectedOperations(item) {
      this.activeOperations = item
    }
  },
  mounted() {
    if(this.account){
      this.oldId = this.account.id
      this.acc = this.account.account_id
      this.trialType = this.account.type
      this.name = this.account.name
      this.description = this.account.description
      this.activeFS = this.account.financial_statement

      if(this.account.accounts_field?.length)
        this.activeOperations = this.account.accounts_field

    } else if(this.duplAccount) {
      this.acc = this.duplAccount.account_id
      this.trialType = this.duplAccount.type
      this.name = this.duplAccount.name
      this.description = this.duplAccount.description
      this.activeFS = this.duplAccount.financial_statement

      if(this.duplAccount.accounts_field?.length)
        this.activeOperations = this.duplAccount.accounts_field
    }

    if(Array.isArray(this.activeOperations) == false)
      this.activeOperations = []
  }
}
</script>

<style lang="scss" scoped>
.list-item-caps {
  /deep/ {
    .dropdown-item {
      text-transform: uppercase !important;
    }
    .button-text {
      text-transform: uppercase !important;
    }
  }

}
.modal-wrap{
  .modal-content_head{
    padding: 16px 12px 8px 24px;
    border-bottom: 1px solid #DEE3EA;
  }
  .modal-content_title{
    margin-bottom: 0;
    font-size: 18px;
  }
  .modal-content_body{
    padding: 0 24px;
  }
  .modal-content{
    max-width: 420px;
  }
  .modal-content_bottom{
    margin-top: 20px;
    padding: 8px 24px;
    border-top: 1px solid #DEE3EA;
  }
  .btn{
    min-width: 136px;
  }
  .btn-close{
    min-width: 60px;
    margin-right: 25px;
  }
  .button-blue-border{
    display: inline-flex;
    margin-right: 8px;
    padding: 7px;
    border: 1px solid #094172;
  }
}
.error{
  margin-top: 4px;
  color: #E85353;
  font-size: 12px;
}
.trial-balance_switcher{
  position: relative;
  display: flex;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  overflow: hidden;
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: #BDCBD6;
  }
  label{
    position: relative;
    display: inline-block;
    width: 50%;
    text-align: center;
    color: #9AA3B0;
    background-color: var(--color-input);
    box-sizing: border-box;
    &.active{
      color: #2C2C2C;
      background-color: #DEE3EA;
    }
    span{
      display: inline-block;
      padding: 9px;
    }
    input{
      position: absolute;
      opacity: 0;
      clip: rect(0, 0, 0, 0)
    }
  }
}
</style>