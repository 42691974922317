<template>
  <div class="flex justify-center m-t-5">
    <div class="flex items-center w-full max-w-920 border border-gray-dark rounded-t-lg p-x-6 p-y-2 box-border">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.9531C0 5.36203 5.36203 0 11.9531 0C18.5442 0 24 5.36203 24 11.9531C24 18.5442 18.5442 24 11.9531 24C5.36203 24 0 18.5442 0 11.9531ZM7.3438 9.02495C7.67317 8.75322 8.15468 8.80671 8.41984 9.14446C9.67282 10.8281 9.67282 13.1645 8.41979 14.8481L8.41454 14.8548C8.14754 15.1916 7.66514 15.2424 7.33711 14.9683C7.00908 14.6941 6.95958 14.1989 7.22658 13.8621C8.01857 12.7511 8.01857 11.2415 7.22658 10.1305L7.22654 10.1304C6.9616 9.79189 7.01411 9.29695 7.3438 9.02495ZM11.4862 6.78508C11.221 6.44734 10.7395 6.39385 10.4102 6.66557C10.0805 6.93758 10.028 7.43251 10.2929 7.771L10.293 7.77109C12.1173 10.2761 12.1173 13.7153 10.293 16.2204C10.026 16.5572 10.0755 17.0524 10.4035 17.3265C10.7315 17.6007 11.2139 17.5498 11.4809 17.2131L11.4862 17.2064C13.7363 14.1166 13.7363 9.87477 11.4862 6.78508ZM13.4713 4.3132C13.7993 4.03908 14.2817 4.08989 14.5487 4.42667C17.8174 8.91503 17.8174 15.0772 14.5487 19.5656L14.5434 19.5723C14.2764 19.9091 13.794 19.9599 13.466 19.6858C13.138 19.4117 13.0885 18.9164 13.3555 18.5796C16.1984 14.6759 16.1984 9.31644 13.3555 5.41268C13.0941 5.07553 13.1458 4.5852 13.4713 4.3132Z" fill="#BDCBD6"/></svg>
      <h1 class="fw500 m-l-3 m-r-auto">Notifications</h1>

      <button class="flex flex-col items-center" @click="$emit('on-check')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 23.7648C13.124 23.7648 14.0705 22.966 14.2999 21.9082C14.3584 21.6383 14.1291 21.4118 13.8529 21.4118H10.1471C9.87092 21.4118 9.64156 21.6383 9.70009 21.9082C9.92953 22.966 10.876 23.7648 12 23.7648ZM19.7935 16.8524C19.6997 16.7586 19.6471 16.6314 19.6471 16.4988V10.2354C19.6471 6.72531 17.3586 3.86911 14.1452 2.9253C13.9236 2.86022 13.7647 2.66087 13.7647 2.42992V2.00006C13.7647 1.05888 12.9412 0.235352 12 0.235352C11.0588 0.235352 10.2353 1.05888 10.2353 2.00006V2.42992C10.2353 2.66087 10.0764 2.86022 9.85482 2.9253C6.64138 3.86911 4.35294 6.72531 4.35294 10.2354V16.4988C4.35294 16.6314 4.30026 16.7586 4.20649 16.8524L2.14645 18.9124C2.05268 19.0062 2 19.1334 2 19.266V19.7354C2 20.0115 2.22386 20.2354 2.5 20.2354H21.5C21.7761 20.2354 22 20.0115 22 19.7354V19.266C22 19.1334 21.9473 19.0062 21.8536 18.9124L19.7935 16.8524Z" fill="#BDCBD6"/></svg>
        <span class="m-t-1 fz13 fw500 text-gray-text hover:text-blue">{{isChecked ? 'Clear all' : 'Check all'}}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    types: Array,
    isChecked: Boolean
  },
}
</script>

<style>

</style>