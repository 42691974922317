<template>
  <div class="payment-accounts_item__row" v-if="value">
    <div class="payment-accounts_item__title">{{title}}</div>
    <div class="payment-accounts_item__value">{{value}}</div>
    <svg ref="copy" @click.stop="onCopy(value)" class="counterparties-user_block__row__copy" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.17189C0 4.13178 0.854394 3.28861 1.90834 3.28861H8.94561C9.99956 3.28861 10.854 4.13178 10.854 5.17189V12.1167C10.854 13.1568 9.99956 14 8.94561 14H1.90834C0.854394 14 0 13.1568 0 12.1167V5.17189ZM1.90834 4.60405C1.59056 4.60405 1.33294 4.85828 1.33294 5.17189V12.1167C1.33294 12.4303 1.59056 12.6846 1.90834 12.6846H8.94561C9.2634 12.6846 9.52101 12.4303 9.52101 12.1167V5.17189C9.52101 4.85828 9.2634 4.60405 8.94561 4.60405H1.90834Z" fill="#4B4B4B"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.14605 1.88328C3.14605 0.843172 4.00044 0 5.05439 0H12.0917C13.1456 0 14 0.843172 14 1.88328V8.82811C14 9.86822 13.1456 10.7114 12.0917 10.7114C11.7236 10.7114 11.4252 10.4169 11.4252 10.0537C11.4252 9.69042 11.7236 9.39595 12.0917 9.39595C12.4094 9.39595 12.6671 9.14172 12.6671 8.82811V1.88328C12.6671 1.56967 12.4094 1.31543 12.0917 1.31543H5.05439C4.73661 1.31543 4.47899 1.56967 4.47899 1.88328C4.47899 2.24652 4.1806 2.54099 3.81252 2.54099C3.44444 2.54099 3.14605 2.24652 3.14605 1.88328Z" fill="#4B4B4B"/></svg>
  </div>
</template>

<script>
import tippy, {hideAll} from 'tippy.js';
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
  },
  methods: {
    onCopy(text) {
      this.$copyText(text)
      setTimeout(() => {
        hideAll() // tippy.js
      }, 700)
    }
  },
  async mounted() {
    await this.$nextTick()

    tippy(this.$refs.copy, {
      arrow: false,
      duration: [null, null],
      trigger: 'click',
      content: 'Copied to clipboard',
    })
  }
}
</script>

<style lang="scss">
  .payment-accounts_item{
    &__row{
      position: relative;
      margin-top: 8px;
      padding: 4px 24px;
      z-index: 1;
      &:hover{
        &:before,
        .counterparties-user_block__row__copy{
          opacity: 1;
        }
      }
      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-input);
        z-index: -1;
      }
      &:before,
      .counterparties-user_block__row__copy{
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    &__title{
      font-size: 12px;
      color: #9AA3B0;
    }
    &__value{
      margin-top: 8px;
      font-size: 14px;
    }
  }
</style>