<template>
  <modal-slots 
    :showMod="isShowModal"
    :noCloseAfterApply="true"
    @close="$emit('on-close')"
    @apply="addBerth"
  >
    <template v-slot:title>
      <h2 class="fz18 fw500">Create new berth</h2>
    </template>

    <div class="p-x-6 p-b-5">
      <label class="block-input" :class="{ 'block-input_error': $v.berth.name.$error }">
        <p class="block-input_title text-gray">Berth Name</p>
        <input class="block-input_content" type="text" v-model.trim="$v.berth.name.$model">
        <div class="fz10 text-red" v-if="!$v.berth.name.required && $v.berth.name.$dirty">Field is required</div>
        <div class="fz10 text-red" v-if="!$v.berth.name.maxLength">The maximum number of characters in the Berth Name is {{$v.berth.name.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.operator.$error }">
        <p class="block-input_title text-gray">Operator</p>
        <input class="block-input_content" type="text" v-model.trim="$v.berth.operator.$model">
        <div class="fz10 text-red" v-if="!$v.berth.operator.maxLength">The maximum number of characters in the Operator is {{$v.berth.operator.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.average_draft.$error }">
        <p class="block-input_title text-gray">Average Draft</p>
        <input class="block-input_content" type="text" v-model.trim="$v.berth.average_draft.$model">
        <div class="fz10 text-red" v-if="!$v.berth.average_draft.maxLength">The maximum number of characters in the Average Draft is {{$v.berth.average_draft.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.vessel_handled.$error }">
        <p class="block-input_title text-gray">Vessel Handled</p>
        <input class="block-input_content" type="text" v-model.trim="$v.berth.vessel_handled.$model">
        <div class="fz10 text-red" v-if="!$v.berth.vessel_handled.maxLength">The maximum number of characters in the Vessel Handled is {{$v.berth.vessel_handled.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.commodity.$error }">
        <p class="block-input_title text-gray">Commodity</p>
        <input class="block-input_content" type="text" v-model.trim="$v.berth.commodity.$model">
        <div class="fz10 text-red" v-if="!$v.berth.commodity.maxLength">The maximum number of characters in the Commodity is {{$v.berth.commodity.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.restrictions.$error }">
        <p class="block-input_title text-gray">Restrictions</p>
        <textarea class="block-input_content" v-model.trim="$v.berth.restrictions.$model" rows="5"></textarea>
        <div class="fz10 text-red" v-if="!$v.berth.restrictions.maxLength">The maximum number of characters in the Restrictions is {{$v.berth.restrictions.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.loading_discharging_capacities.$error }">
        <p class="block-input_title text-gray">Loading / Discharging Capacities</p>
        <textarea class="block-input_content" v-model.trim="$v.berth.loading_discharging_capacities.$model" rows="5"></textarea>
        <div class="fz10 text-red" v-if="!$v.berth.loading_discharging_capacities.maxLength">The maximum number of characters in the Loading / Discharging Capacities is {{$v.berth.loading_discharging_capacities.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.working_day.$error }">
        <p class="block-input_title text-gray">Working Day / Hours</p>
        <textarea class="block-input_content" v-model.trim="$v.berth.working_day.$model" rows="2"></textarea>
        <div class="fz10 text-red" v-if="!$v.berth.working_day.maxLength">The maximum number of characters in the Working Day / Hours is {{$v.berth.working_day.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.drydock_facilities.$error }">
        <p class="block-input_title text-gray">Drydock facilities</p>
        <input class="block-input_content" type="text" v-model.trim="$v.berth.drydock_facilities.$model">
        <div class="fz10 text-red" v-if="!$v.berth.drydock_facilities.maxLength">The maximum number of characters in the Drydock facilities is {{$v.berth.drydock_facilities.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.bunkering.$error }">
        <p class="block-input_title text-gray">Bunkering</p>
        <textarea class="block-input_content" v-model.trim="$v.berth.bunkering.$model" rows="2"></textarea>
        <div class="fz10 text-red" v-if="!$v.berth.bunkering.maxLength">The maximum number of characters in the Bunkering is {{$v.berth.bunkering.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.remarks.$error }">
        <p class="block-input_title text-gray">Remarks</p>
        <textarea class="block-input_content" v-model.trim="$v.berth.remarks.$model" rows="2"></textarea>
        <div class="fz10 text-red" v-if="!$v.berth.remarks.maxLength">The maximum number of characters in the Remarks is {{$v.berth.remarks.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.berth.certified_by.$error }">
        <p class="block-input_title text-gray">Certified by</p>
        <textarea class="block-input_content" v-model.trim="$v.berth.certified_by.$model" rows="2"></textarea>
        <div class="fz10 text-red" v-if="!$v.berth.certified_by.maxLength">The maximum number of characters in the Certified is {{$v.berth.certified_by.$params.maxLength.max}}.</div>
      </label>

      <files class="m-t-3" ref="dropzone"/>
    </div>

  </modal-slots>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

import modalSlots from '@/components/modal/modal-slots'
import files from '@/components/ports/list/files'


export default {
  mixins: [validationMixin],
  components: {
    modalSlots,
    files
  },
  props: {
    isShowModal: Boolean,
  },
  data() {
    return {
      berth: {
        name: '',
        operator: '',
        average_draft: '',
        vessel_handled: '',
        commodity: '',
        restrictions: '',
        loading_discharging_capacities: '',
        working_day: '',
        drydock_facilities: '',
        bunkering: '',
        remarks: '',
        certified_by: '',
        files: null,
        port: this.$route.params.id,
        is_archive: false
      }
    }
  },
  validations: {
    berth: {
      name: {
        required,
        maxLength: maxLength(200)
      },
      operator: {maxLength: maxLength(2000)},
      average_draft: {maxLength: maxLength(2000)},
      vessel_handled:{maxLength: maxLength(2000)},
      commodity: {maxLength: maxLength(2000)},
      restrictions: {maxLength: maxLength(2000)},
      loading_discharging_capacities: {maxLength: maxLength(2000)},
      working_day: {maxLength: maxLength(2000)},
      drydock_facilities: {maxLength: maxLength(2000)},
      bunkering: {maxLength: maxLength(2000)},
      remarks: {maxLength: maxLength(2000)},
      certified_by: {maxLength: maxLength(2000)}
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    ...mapActions(['axiosAddBerth']),
    async addBerth() {
      this.$v.$touch() //validation
      if (this.$v.$invalid) return false //validation

      this.berth.files = this.$refs.dropzone.getFiles()

      let newBerth = await this.axiosAddBerth(this.berth)
      this.$emit('on-add', newBerth)
      this.$emit('on-close')
    }
  },
}
</script>

<style>

</style>