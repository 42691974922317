<template>
  <modal-slots 
    :isPointerBg="false"
    :footerHide="true"
    :showMod="isOpen" 
    @close="$emit('close')" 
    class="fast_calc"
  >
    <template v-slot:title>Intake calculator</template>
    <div class="flex active:child-inputs-inset-shadow">
<!--      <button v-on:click="test">calc Test</button>-->
      <div class="w-1/2 border-r border-dark-gray">
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">Draft restriction</div>
          <div class="w-7/12 relative">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="draft"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">m</span>
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">Water density</div>
          <div class="w-7/12 relative svg-white-dart" v-click-outside="onBlurWater">
            <input
              type="text"
              class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13"
              v-model="water_density"
              @focus="onFocusWater"
              @blur="onBlurWater"
              @keyup.enter="$event.target.blur()"
              ref="waterInput"
            >
            <svg @click="onFocusWater" class="absolute right-2 centered-y cursor-pointer" width="12" height="12" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M2.724 4.4a.3.3 0 0 0-.212.512l3.276 3.276a.3.3 0 0 0 .424 0l3.276-3.276a.3.3 0 0 0-.212-.512H2.724Z"/></svg>
            <default-select
              class="absolute left-0 top-full w-full default_select__hide_main_input multiselect_fz13"
              id="water_density"
              :options="calcWaterDensity"
              :allowEmpty="true"
              :closeOnSelect="true"
              :searchable="false"
              trackBy="name"
              v-model="waterDensityObj"
              @on-select="onSelectWater"
              ref="waterDrop"
            />
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">Season</div>
          <div class="w-7/12 relative">
            <default-select
              class="w-full default_select__in_calc multiselect_outline_darc-blue capitalize-child default_select__fz13 multiselect_fz13"
              id="season"
              :options="['summer', 'winter', 'tropical']"
              :allowEmpty="true"
              :closeOnSelect="true"
              :searchable="false"
              trackBy=""
              placeholder=""
              v-model="season"
            />
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">Summer DWT</div>
          <div class="w-7/12 relative">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="sdwt"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">Summer Draft</div>
          <div class="w-7/12 relative">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="sdraft"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">m</span>
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">TPC</div>
          <div class="w-7/12">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="tpc"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">Constants</div>
          <div class="w-7/12 relative">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="constants"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">BOB</div>
          <div class="w-7/12 relative">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="bob"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">Fresh water</div>
          <div class="w-7/12 relative">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="fresh_water"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
          </div>
        </div>
        <div class="flex border-b border-gray-text">
          <div :class="getTitleClass">Grain capacity</div>
          <div class="w-7/12 relative">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="grain"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">m³</span>
          </div>
        </div>
        <div class="flex">
          <div :class="getTitleClass">Stowage Factor*</div>
          <div class="w-7/12">
            <div class="relative border-b border-gray-text">
              <input
                class="p-y-1.5 p-l-1.5 p-r-12 border-0 bg-transparent rounded-none fz13"
                type="number"
                v-model.number.lazy="stowage_factor_m"
                @blur="onBlurStowage('m')"
                @keyup.enter="onBlurStowage('m'), $event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">m³/mt</span>
            </div>
            <div class="relative">
              <input
                class="p-y-1.5 p-l-1.5 p-r-12 border-0 bg-transparent rounded-none fz13"
                type="number"
                v-model.number.lazy="stowage_factor_ft"
                @blur="onBlurStowage('ft')"
                @keyup.enter="onBlurStowage('ft'), $event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">ft³/mt</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-1/2 p-x-6 p-t-9 p-b-3 box-border fz13">
        <div class="flex w-full m-b-3">
          <div class="w-5/12 text-gray-text fw600">DWT</div>
          <div class="w-7/12 whitespace-nowrap overflow-ellipsis overflow-hidden">{{calcDWT | formatNumberTwoWithoutEmptyAdditional}}
            <span class="text-gray-text">mt</span>
          </div>
        </div>
        <div class="flex w-full m-b-3">
          <div class="w-5/12 text-gray-text fw600">Ship Draft</div>
          <div class="w-7/12 whitespace-nowrap overflow-ellipsis overflow-hidden">{{getShipDraft | formatNumberTwoWithoutEmptyAdditional}}
            <span class="text-gray-text">m</span>
          </div>
        </div>
        <div class="flex w-full m-b-3">
          <div class="w-5/12 text-gray-text fw600">FWA</div>
          <div class="w-7/12 whitespace-nowrap overflow-ellipsis overflow-hidden">{{calcFWA | formatNumberThreeWithoutEmptyAdditional}}
            <span class="text-gray-text">m</span>
          </div>
        </div>
        <div class="flex w-full m-b-3">
          <div class="w-5/12 text-gray-text fw600">DWA</div>
          <div class="w-7/12 whitespace-nowrap overflow-ellipsis overflow-hidden">{{calcDWA | formatNumberThreeWithoutEmptyAdditional}}
            <span class="text-gray-text">m</span>
          </div>
        </div>
        <div class="flex w-full m-b-3">
          <div class="w-5/12 text-gray-text fw600">DWCC calc</div>
          <div class="w-7/12 whitespace-nowrap overflow-ellipsis overflow-hidden">{{calcDWCC_calc | formatNumberTwoWithoutEmptyAdditional}}
            <span class="text-gray-text">mt</span>
          </div>
        </div>
        <div class="flex w-full m-b-3 p-b-3 border-b border-gray-text">
          <div class="w-5/12 text-gray-text fw600">DWCC cub</div>
          <div class="w-7/12 whitespace-nowrap overflow-ellipsis overflow-hidden">{{calcDWCC_cub | formatNumberTwoWithoutEmptyAdditional}}
            <span class="text-gray-text">mt</span>
          </div>
        </div>

        <div class="flex w-full fz20 text-blue-dark">
          <div class="w-5/12 fw600">DWCC</div>
          <div class="w-7/12 fw600 whitespace-nowrap overflow-ellipsis overflow-hidden">{{calcDWCC | formatNumberTwoWithoutEmptyAdditional}} mt</div>
        </div>

        <button class="blue-button fz13 fw500 h32 m-t-auto" @click="onCopy">
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.172c0-1.04.854-1.883 1.908-1.883h7.038c1.054 0 1.908.843 1.908 1.883v6.945c0 1.04-.854 1.883-1.908 1.883H5.908C4.854 19 4 18.157 4 17.117v-6.945zm1.908-.568a.572.572 0 00-.575.568v6.945c0 .313.258.568.575.568h7.038a.572.572 0 00.575-.568v-6.945a.572.572 0 00-.575-.568H5.908z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.146 5.883C8.146 4.843 9 4 10.054 4h7.038C18.146 4 19 4.843 19 5.883v6.945c0 1.04-.854 1.883-1.908 1.883a.662.662 0 01-.667-.657c0-.364.299-.658.667-.658a.572.572 0 00.575-.568V5.883a.572.572 0 00-.575-.568h-7.038a.572.572 0 00-.575.568.662.662 0 01-.666.658.662.662 0 01-.667-.658z" fill="#fff"/></svg>
          Copy DWCC 
        </button>
      </div>
    </div>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import defaultSelect from '@/components/reuse/defaultSelect'
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  components: {
    ModalSlots,
    defaultSelect
  },
  props: {
    isOpen: Boolean,
    vessel: Object
  },
  data() {
    return {
      draft: null,
      water_density: 'Salt water (1,025)',
      waterDensityObj: null,
      season: 'summer',
      sdwt: null,
      sdraft: null,
      tpc: null,
      constants: null,
      bob: null,
      fresh_water: null,
      grain: null,
      stowage_factor_m: null,
      stowage_factor_ft: null,
    }
  },
  computed: {
    ...mapState({
      calcWaterDensity: state => state.tabs.calcWaterDensity
    }),
    ...mapGetters(['getNumbFromString']),
    getTitleClass() {
      return 'w-5/12 box-border bg-gray-lightest border-r border-gray-text fz13 fw600 text-gray-text p-1.5 whitespace-nowrap overflow-ellipsis overflow-hidden'
    },
    getDelta() {
      if(this.season == 'winter'){
        return -0.3014166667
      }else if(this.season == 'tropical'){
        return 0.3014166667
      }
      return 0
    },
    getSeasonDwt() {
      let dwt = (this.getDelta * (this.tpc?this.tpc:0) * 100) + (this.sdwt?this.sdwt:0)
      return dwt ? dwt : 0
    },
    getShipDraft() {
      // MIN((C106),IF(C108=1,Q3,IF(C108=2,Q4,IF(C108=3,Q5)))+G109)
      let draft = Math.min(this.draft, this.getSeasonDraft + this.calcDWA)
      return draft ? +draft.toFixed(8) : 0
    },
    getSeasonDraft() {
      let draft = this.sdraft + this.getDelta
      return draft ? draft : 0
    },
    calcFWA() {
      let fwa = (this.sdraft/48)*1.1
      return fwa ? fwa : 0
    },
    calcDWA() {
      let dwa = ((1.025-this.getNumbFromString(this.water_density))/0.025)*(((this.sdraft?this.sdraft:0)/48)*1.1)
      return dwa ? +dwa.toFixed(10) : 0
    },
    calcDWT() {
      // let y = ((this.sdraft?this.sdraft:0) + this.getDelta + this.calcDWA - this.draft) * this.tpc * 100
      let y = ((this.sdraft?this.sdraft:0) + this.getDelta + this.calcDWA - this.getShipDraft) * this.tpc * 100
      // let y = ((this.sdraft?this.sdraft:0) + this.getDelta + this.calcDWA - this.getSeasonDraft) * this.tpc * 100
      let dwt = this.getSeasonDwt - y
      return dwt ? +dwt.toFixed(5) : 0
    },
    calcDWCC_calc() {
      let DWCC_calc = this.calcDWT-(this.constants ? this.constants : 0)-(this.bob ? this.bob : 0)-(this.fresh_water ? this.fresh_water : 0)
      return DWCC_calc ? +DWCC_calc.toFixed(5) : 0
    },
    calcDWCC_cub() {
      let grain = this.grain ? this.grain : 0
      let stowage_factor_m = this.stowage_factor_m ? this.stowage_factor_m : 0
      let DWCC_cub = grain/stowage_factor_m
      return DWCC_cub && stowage_factor_m ? +DWCC_cub.toFixed(5) : 0
    },
    calcDWCC() {
      let result = Math.min(this.calcDWCC_calc, this.calcDWCC_cub)
      return +result.toFixed(1)
    },
  },
  methods: {
    ...mapActions(['onlyNumber']),
    test() {
      this.draft= 18
      this.water_density= 1.027
      this.waterDensityObj= null
      this.season= 'summer'
      this.sdwt=80459
      this.sdraft = 14.47
      this.tpc = 70.24
      this.constants=450
      this.bob=1200
      this.fresh_water=600
      this.grain=95137
      this.stowage_factor_m= 1.18930755678
      // stowage_factor_ft: null,
    },
    onSelectWater() {
      if(this.waterDensityObj){
        this.water_density = this.waterDensityObj.name
      }else
        this.water_density = null
      this.$refs.waterInput.blur()
    },
    onFocusWater() {
      this.$refs.waterDrop.openDropdown()
    },
    onBlurWater() {
      this.$refs.waterDrop.closeDropdown()
      if(this.waterDensityObj && (this.waterDensityObj.name != this.water_density)){
        this.waterDensityObj = null
        if(!parseFloat(this.water_density))
          this.water_density = 1.25
      }
    },

    onBlurStowage(type) {
      let factor = 0
      if(type == 'm'){
        if(parseFloat(this.stowage_factor_m)) factor = parseFloat(this.stowage_factor_m)
        if(factor > 15){
          this.stowage_factor_ft = factor
          this.stowage_factor_m = Math.round(factor*0.02831684659*100000000000)/100000000000
        }else{
          this.stowage_factor_ft = this.stowage_factor_m ? Math.round((factor/0.02831684659)*100000000000)/100000000000 : null
        }
      }else{
        if(parseFloat(this.stowage_factor_ft)) factor = parseFloat(this.stowage_factor_ft)
        this.stowage_factor_m = this.stowage_factor_ft ? Math.round(factor*0.02831684659*100000000000)/100000000000 : null
      }
    },
    

    onCopy() {
      //navigator.clipboard.writeText(this.calcDWCC) - not working on http
      //this.$copyText(this.calcDWCC) - not working if computed
      this.$copyText(`${this.calcDWCC}`)
    }
  },
  beforeMount() {
    this.sdwt = this.vessel.dwt
    this.sdraft = this.vessel.draft
    this.tpc = this.vessel.tpc
    this.constants = this.vessel.constants
    this.bob = this.vessel.bob
    this.fresh_water = this.vessel.fresh_water
    this.grain = this.vessel.grain
  }
}
</script>

<style lang="scss">
.fast_calc{
  &.wrap{
    .content{
      width: 516px;
      overflow: hidden;
      border: 1px solid var(--color-dark-gray);
      .top-panel{
        padding: 8px 16px;
        background-color: var(--color-blue-dark);
        color: var(--color-white);
        border-bottom: 1px solid var(--color-dark-gray) !important;
        font-size: 20px;
        font-weight: 500;
        .close-button{
          font-size: 0;
          right: 16px;
          svg{
            fill: var(--color-white);
          }
        }
      }
    }
    .multiselect_outline_darc-blue{
      .multiselect{
        &:hover,
        &.multiselect--active{
          outline: 1px solid var(--color-blue-dark);
        }
      }
    }
  }
}
</style>