<template>
  <div class="banks-wrap" v-if="permissions.length && getPermissionsByType('show_bank_and_cash_accounts')">
    <div class="bank-aside">
      <bank-aside-total/>
      <div class="bank-aside_scroll">
        <bank-aside-list 
          title="Favorite" 
          :list="getFavouriteBanks" 
          v-if="banks && getFavouriteBanks.length" 
          @edit-bank="addBank" 
          @archive-bank="archiveBankModal"
        />
        <bank-aside-list 
          title="View all" 
          :collaps="true" 
          :list="getNotFavouriteBanks" 
          v-if="banks && getNotFavouriteBanks.length" 
          @edit-bank="addBank" 
          @archive-bank="archiveBankModal"
        />
        <bank-aside-controls @add="addBank"/>
        <bank-aside-empty v-if="banks && !banks.length" @add="addBank"/>
      </div>
    </div>

    <div class="bank-details">
      <bank-empty v-if="banks && !banks.length"/>
      <bank-details-top v-if="activeBank" @add-account="addAccount"/>
      <bank-details-list v-if="activeBank && activeBank.requisites.length" :list="getRequisitesNotArchive" @emitPushCashFlow="emitPushCashFlow"  @edit-account="addAccount" @archive-account="archiveAccountModal"/>
      <bank-details-empty v-if="activeBank && !activeBank.requisites.length" @add-account="addAccount"/>
    </div>

    <bank-modal-add 
      :bank="editBank"
      v-if="ifModalAdd"
      @close="ifModalAdd = false"
      @update="updateData"
    />
    <bank-modal-add-bank-account 
      :bank="activeBank"
      :account="editAccount"
      v-if="ifModalAddAccount"
      @close="ifModalAddAccount = false"
      @update="updateDataAccount"
    />

    <bank-modal-archive :bank="editBank" v-if="ifModalArchive" @close="ifModalArchive = false" @submit="archiveBank"/>
    <bank-modal-archive-account :account="editBank" v-if="ifModalArchiveAccount" @close="ifModalArchiveAccount = false" @submit="archiveBankAccount"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import bankAsideTotal from '@/components/accounts/bankAndCash/bankAsideTotal'
import bankAsideList from '@/components/accounts/bankAndCash/bankAsideList'
import bankAsideControls from '@/components/accounts/bankAndCash/bankAsideControls'
import bankAsideEmpty from '@/components/accounts/bankAndCash/bankAsideEmpty'
import bankDetailsTop from '@/components/accounts/bankAndCash/bankDetailsTop'
import bankDetailsList from '@/components/accounts/bankAndCash/bankDetailsList'
import bankDetailsEmpty from '@/components/accounts/bankAndCash/bankDetailsEmpty'
import bankEmpty from '@/components/accounts/bankAndCash/bankEmpty'
import bankModalAdd from '@/components/accounts/bankAndCash/bankModalAdd'
import bankModalAddBankAccount from '@/components/accounts/bankAndCash/bankModalAddBankAccount'
import bankModalArchive from '@/components/accounts/bankAndCash/archive/bankModalArchive'
import bankModalArchiveAccount from '@/components/accounts/bankAndCash/archive/bankModalArchiveAccount'
export default {
  components: {
    bankAsideTotal,
    bankAsideList,
    bankAsideControls,
    bankAsideEmpty,
    bankDetailsTop,
    bankDetailsList,
    bankDetailsEmpty,
    bankEmpty,
    bankModalAdd,
    bankModalAddBankAccount,
    bankModalArchive,
    bankModalArchiveAccount
  },
  data() {
    return {
      editBank: null,
      editAccount: null,
      ifModalAdd: false,
      ifModalAddAccount: false,
      ifModalArchive: false,
      ifModalArchiveAccount: false
    }
  },
  watch: {
    permissions: function(val) {
      if(val.length && !this.getPermissionsByType('show_bank_and_cash_accounts'))
        this.$router.replace({name: 'employees'})
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.confirmation.permissionsForUser,
      banks: state => state.banks.banks,
      activeBank: state => state.banks.activeBank,
      trials: state => state.trialBalance.trials
    }),
    ...mapGetters(['getFavouriteBanks', 'getNotFavouriteBanks', 'getPermissionsByType']),
    getRequisitesNotArchive() {
      return this.activeBank.requisites.filter(el=>{
        return el.is_archive == false
      })
    },
  },
  methods: {
    ...mapActions(['axiosGetBanks', 'axiosGetBanksArchive', 'axiosBanksToArchive', 'axiosBanksAccountsToArchive']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip', 'setActiveBank', "setBankAndCashAccountForCashFlowPush"]),
    addBank(bank) {
      if(bank)
        this.editBank = bank
      else
        this.editBank = null
      
      this.ifModalAdd = true
    },
    emitPushCashFlow(item) {
      this.setBankAndCashAccountForCashFlowPush([item])
      this.$router.push({name: 'CashFlow List'})
    },
    addAccount(account) {
      if(account)
        this.editAccount = account
      else
        this.editAccount = null
      
      this.ifModalAddAccount = true
    },
    archiveBankModal(bank) {
      this.editBank = bank
      this.ifModalArchive = true
    },
    archiveAccountModal(account) {
      this.editBank = account
      this.ifModalArchiveAccount = true
    },
    async archiveBank(ids) {
      this.showLoad()
      await this.axiosBanksToArchive({ids: ids, bool: true})
      this.hideLoad()

      this.axiosGetBanks() // update data
      this.axiosGetBanksArchive() // update data

      this.ifModalArchive = false
      this.addTip(this.editBank.name + ' is archived')
    },
    async archiveBankAccount(ids) {
      this.showLoad()
      await this.axiosBanksAccountsToArchive({ids: ids, bool: true})
      this.hideLoad()

      await this.axiosGetBanks()
      this.setActiveBank(this.banks.filter(el=>{return el.id == this.activeBank.id})[0]) // for update active bank accounts

      this.ifModalArchiveAccount = false
      this.addTip(this.editBank.account_number + ' is archived')
    },
    updateData() {
      this.axiosGetBanks()
    },
    async updateDataAccount() {
      await this.axiosGetBanks()
      this.setActiveBank(this.banks.filter(el=>{return el.id == this.activeBank.id})[0]) // for update active bank accounts
    }
  },
  async mounted() {
    this.showLoad()
    await this.axiosGetBanks()
    this.hideLoad()
    this.axiosGetBanksArchive()

    if(this.$route.query.bank){
      this.setActiveBank(this.banks.filter(el=> el.id == this.$route.query.bank)[0])
    }else if(this.activeBank)
      this.setActiveBank(this.banks.filter(el=>{return el.id == this.activeBank.id})[0]) // for update active bank accounts
  },
}
</script>

<style lang="scss">
  .banks-wrap{
    display: flex;
    height: 100%;
    overflow: hidden;
  }
  .bank-aside{
    display: flex;
    flex-direction: column;
    width: 334px;
    flex-shrink: 0;
    background-color: var(--color-bgc-page-white);
    box-shadow: inset -1px 0px 0px var(--color-border);
    &_scroll{
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      margin-right: 1px;
    }
  }
  .bank-details{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    overflow-y: auto;
  }
</style>