import axios from 'axios'
import QueryString from "querystring";
import moment from 'moment';

export default {
    actions: {
        async axiosGetPathActivityToSection(ctx, data) {
            console.log(data)
            return await axios.get(process.env.VUE_APP_URL+'/ftp/move/from/activity', {
                params: {...data},
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
        async axiosGetFoldersForClientImportant(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/client/folders', {
                params: {
                    ...data.params,
                    limit: 9999,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                return res.data
            }).catch(()=> {
                return false
            })
        },
        async axiosGetFoldersForPortImportant(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/port/folders', {
                params: {
                    ...data.params,
                    limit: 9999,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                return res.data
            }).catch(()=> {
                return false
            })
        },
        async axiosGetVesselsFoldersImportant(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/contract/folders', {
                params: {
                    ...data.params,
                    limit: 9999,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                return res.data
            }).catch(()=> {
                return false
            })
        },
        async axiosGetPaymentFoldersImportant(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL+'/payment/list', {
              params: {
                  ...data.params,
                  limit: 9999,
              },
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(res=> {
              return res.data
          }).catch(()=> {
              return false
          })
        },
        async axiosGetTaskFoldersImportant(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL+'/task/folders', {
              params: {
                  ...data.params,
                  limit: 9999,
              },
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(res=> {
              return res.data
          }).catch(()=> {
              return false
          })
        },
        async axiosGetFoldersForFtp(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL+'/ftp', {
              params: {
                  ...data.params,
                  limit: 99999,
              },
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res.data
          }).catch(()=> {
              return false
          })
        },
        async axiosGetMoreCounterparties(ctx, next) {
          return await axios.get(next, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res.data
          }).catch(()=> {
              return false
          })
        },
        async axiosGetFoldersForCounterparty(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/client/folders/'+data.id, {
                params: data.params,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                res.data.files.forEach(item=> {
                    item['is_show'] = false
                    item['is_load'] = false
                    item['created_in'] = false
                    item['hide_svg'] = false
                    item['url'] = item.file
                })
                if(data.notSetFile !== true) {
                    ctx.commit('setFiles', res.data.files)
                }

                return res.data
            })
        },
        async axiosGetClientsForFolder(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL+'/client/folders', {
              params: data.params,
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res.data
          })
        },
        async axiosGetFoldersForPort(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL+'/port/folders/'+data.id, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              params: data.params,

          }).then(res=> {
              console.log('axiosGetFoldersForPort', res)
              if(data.returnFullResponse == true) {
                  return res.data
              }
              if(data.typeFile == true) {
                  let files = data.typeAdditionaly ? res.data[data.type][data.typeAdditionaly] : res.data[data.type]
                  if(data.findId) {
                      files = files.find(a=> a.id == data.findId).files
                  }
                  files.forEach(item=> {
                      item['is_show'] = false
                      item['is_load'] = false
                      item['created_in'] = false
                      item['hide_svg'] = false
                      item['url'] = item.file
                  })
                  if(!data.notSetFiles) {
                      console.log('notSetFiles', files)
                      ctx.commit('setFiles', files)
                  }
                  return files
              } else {
                  return res.data[data.type]
              }
          })
        },
        async axiosGetPortsForFolders(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/port', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    limit: 9999,
                    ...data.params
                },
            }).then(res=> {
                return res.data.results
            })
        },
        async axiosGetCountriesForFolders(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/port/country/', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: data.params,
            }).then(res=> {
                let keys = Object.keys(res.data.ports)
                res.data.ports = keys.map(a=> {
                    let form = {
                        name: a,
                        ids: res.data.ports[a]
                    }
                    return form
                })
                return res.data
            })
        },
        async axiosGetFtpDownload(ctx, ids) {
          return await axios.get(process.env.VUE_APP_URL+'/ftp/bulk/download', {
              params: {
                  ids: ids.toString()
              },
              responseType: 'blob',
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              // paramsSerializer: function (params) {
              //     return QueryString.stringify(params)
              // },
          })
        },
        async axiosGetFoldersForInvoice(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/invoice', {
                params: data.params,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                res.data.results.forEach(invoice=> {
                    invoice.files.forEach(item=> {
                        item['is_show'] = false
                        item['is_load'] = false
                        item['created_in'] = false
                        item['hide_svg'] = false
                        item['url'] = item.file
                        item['filter_type'] = invoice.invoice_type
                    })
                })
                return res.data.results
            })
        },
        async axiosGetCharterersForFolders(ctx, params) {
            return await axios.get(process.env.VUE_APP_URL+'/contract/folders/charterers', {
                params: params,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res
            }).catch(()=> {
                return false
            })
        },
        async axiosGetFoldersForSOA(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL+'/owners/', {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              params: data
          }).then(res=> {
              if(res.data.results.length) {
                  res.data.results[0].files.forEach(item=> {
                      item['is_show'] = false
                      item['is_load'] = false
                      item['created_in'] = false
                      item['hide_svg'] = false
                      item['url'] = item.file
                  })
              }
              if(data.noSetFile !== true) {
                  ctx.commit('setFiles', res.data.results.length !== 0 ? res.data.results[0].files : [])
              }
              return res.data.results.length ? res.data.results[0] : null
          })
        },
        async axiosGetFoldersVorVessel(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/contract/folders/'+data.id, {
                params: data.params,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                res.data.files.forEach(item=> {
                    item['is_show'] = false
                    item['is_load'] = false
                    item['created_in'] = false
                    item['hide_svg'] = false
                    item['url'] = item.file
                })
                if(!data.notSetFiles) {
                    ctx.commit('setFiles', res.data.files)
                }
                return res.data.files
            })
        },
        async axiosGetFoldersForTasks(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL+'/task/folders/'+data.id, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              params: data.params,
          }).then(res=> {
              res.data.files.forEach(item=> {
                  item['url'] = item.file
              })
              if(data.forList == true) {

                  res.data.files.forEach(item=> {
                      item['is_show'] = false
                      item['is_load'] = false
                      item['created_in'] = false
                      item['hide_svg'] = false

                  })
                  return res
              } else {
                  ctx.commit('setFiles', res.data.files)
              }
          })
        },
        async axiosLoadMoreFoldersInLeftListSection(ctx, data) {
          return await axios.get(data.link, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res
          })
        },
        async axiosGetFoldersTasksSection(ctx, params) {
          return await axios.get(process.env.VUE_APP_URL+'/task/folders', {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              params: params
          }).then(res=> {
              if(params.forList == true) {
                  res.data.results.forEach(item=> {
                      item['type_load'] = 'task'
                      item['type_object'] = 'task'
                      item['hide_svg'] = true
                  })
              }
              return res
          })
        },

        async axiosGetLoadMoreFoldersTasksSection(ctx, link) {
          return await axios.get(link, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res
          })
        },
        async axiosDeleteFolderForever(ctx, id) {
          return await axios.delete(process.env.VUE_APP_URL+'/ftp/' + id, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(()=> {
              return true
          }).catch(()=> {
              return false
          })
        },
        async axiosDeleteFolderForeverNew(ctx, ids) {
          return await axios.delete(process.env.VUE_APP_URL+'/ftp/', {
              params: {
                  ids: ids
                      // .toString()
              },
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              paramsSerializer: function (params) {
                  return QueryString.stringify(params)
              },
          }).then(()=> {
              return true
          }).catch(()=> {
              return false
          })
        },
        async axiosGetActivityForFolderSections(ctx, data) {
            let params = {
                ordering: '-created_at',
                limit: 30,
                offset: 0,
                section: data.section
            }
            if(data.shared_with_me) {
                params['shared_with_me'] = true
            }
            return await axios.get(process.env.VUE_APP_URL+'/ftp/activity', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: params
            }).then(res=> {
                ctx.commit('setActivityFolders', res.data.results)
                ctx.state.nextLinkActivity = res.data.next
            })
        },
        async axiosloadFoldersActivityNext(ctx) {
            return await axios.get(ctx.state.nextLinkActivity, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                ctx.state.activityFolders = ctx.state.activityFolders.concat(res.data.results)
                ctx.state.nextLinkActivity = res.data.next
            })
        },
        async axiosGetIdsSectionsForFolders(ctx) {
            return await axios.get(process.env.VUE_APP_URL+'/ftp/folders/info', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                ctx.state.listSectionsIds = res.data
            })
        },
        async axiosGetFolders(ctx, params) {
            let ct = axios.CancelToken.source();
            ctx.commit("cancelToken")
            ctx.commit("cancelTokenSource", ct);
            return await axios.get(process.env.VUE_APP_URL+'/ftp/', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    ...params,
                    limit: 99999,
                    offset: 0,
                },
                cancelToken: ct.token
            }).then(res=> {
                ctx.commit('setFiles', res.data.results)
                console.log('axiosGetFolders',res.data.results)
            })
        },
        async axiosGetFileById(ctx, id) {
            return await axios.get(process.env.VUE_APP_URL+'/ftp/', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    parent_file: id,
                    file_type: 'FOLDER',
                    ordering: '-is_important, name',
                    limit: 99999,
                    offset: 0,
                },
            }).then(res=> {
                return res.data.results
            })
        },
        async axiosSetAccessUsersForFolders(ctx, data) {
          return await axios.put(process.env.VUE_APP_URL+'/ftp/', data.data ,{
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              if(data.data['shared_with']) {
                  let array = res.data
                  array.forEach(item=> {
                      if(item.file_type === 'FILE') {
                          console.log(1)
                          let file = ctx.state.files.find(a=> a.id == item.id)
                          file.shared_with = item.shared_with
                      } else {
                          let file = ctx.state.folders.find(a=> a.id == item.id)
                          console.log(2)
                          file.shared_with = item.shared_with
                      }
                  })
              }
              return res

          }).catch(res=> {
              if(res.request.response.indexOf('You move parent folder in child folder') >= 0) {
                  ctx.commit('addTip', 'You can\'t move parent folder in child folder')
              } else if(res.request.response.indexOf('Rename file or choose another folder') >= 0) {
                  ctx.commit('addTip', res.request.response.replaceAll('["', '').replaceAll('"]', '').replaceAll('\\"', '"'))
              } else {
                  ctx.commit('addTip', 'Something went wrong')
              }
              return false
          })
        },
        axiosCreateFileInFolder(ctx, data) {
            let params = data.params ? data.params : ''
            return axios.post(process.env.VUE_APP_URL+'/ftp/', data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    ...params
                }
            }).then(res=> {
                return res.data
            }).catch(()=> {
                return false
            })
        },
        axiosUploadFolderInFolder(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+'/ftp/upload', data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                    'Content-Type':'application/json'
                },
            }).then(res=> {
                return res.data
            }).catch(()=> {
                return false
            })
        },
        async axiosGetShortViewVessels(ctx, params) {
            if(!params.contract_type) {
                params['contract_type'] = 'SHIP'
            }
            return await axios.get(process.env.VUE_APP_URL+'/contract/folders/', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    ...params
                }
            }).then(res=> {
                return res
            }).catch(()=> {
                return false
            })
        },
        async axiosGetShortViewVesselsLoadMore(ctx, link) {
          return await axios.get(link, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res
          }).catch(()=> {
              return false
          })
        },
        async axiosGetContractYearsForFolders(ctx, params) {
            return await axios.get(process.env.VUE_APP_URL+'/contract/years', {
                params: {
                    ...params,
                    is_redelivery: true,
                    contract_type: 'SHIP',
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res.data
            }).catch(()=> {
                return false
            })
        },
        axiosGetAllUsersForFolders(ctx) {
          return axios.get(process.env.VUE_APP_URL+'/user', {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              params: {
                  limit: 99999,
              }
          }).then(res=> {
              // console.log('axiosGetAllUsersForFolders', res)
              ctx.state.usersForFolders = res.data.results
          })
        },
        async axiosPutFileForFolders(ctx, data) {
          return await axios.put(process.env.VUE_APP_URL+'/ftp/'+data.id, data.data, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res.data
          }).catch(()=> {
              return false
          })
        },
        async axiosGetFolderPayments(ctx, data) {
            ctx.state.nextLinkPayment = null
            let link = process.env.VUE_APP_URL+"/payment/list/";
            let dopLink = "";
            console.log('data', data)
            if(data?.counterparty) {
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink+= "&client=" + data.counterparty;
            }
            if(data.filter !== null && data.filter !== undefined) {
                if(data.filter?.account_name?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.account_name.map(a=> {return "&account=" + a});
                }
                if(data.filter?.bank?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.bank.map(a=> {return "&bank=" + a});
                }
                if(data.filter?.cash?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.cash.map(a=> {return "&cash=" + a});
                }

                if(data?.filter?.currency?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= data.filter.currency.map(a=> {return "&currency=" + a});
                }
                if(data?.filter?.statement_member) {
                    dopLink+= '&statement_member='+data?.filter?.statement_member
                }
                let status = [];
                if(data.filter.paymentCheckbox?.length) {
                    data.filter.paymentCheckbox.map(a=> {status.push(a)});
                }
                if(data.filter.incomeCheckbox?.length) {
                    data.filter.incomeCheckbox.map(a=> {status.push(a)});
                }
                status = Array.from(new Set(status));
                if(status?.length) {
                    if(data.filter?.paymentCheckbox?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&payment_type=expense";
                    }
                    if(data.filter?.incomeCheckbox?.length) {
                        if(dopLink.length === 0) {
                            dopLink = "?"
                        }
                        dopLink+= "&payment_type=income";
                    }
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= status.map(a=> {return "&status=" + a.toLowerCase()});
                }
                if(data.filter?.paymentFrom?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&currency_amount_min=" + data.filter.paymentFrom
                }
                if(data.filter?.paymentTo?.length) {
                    if(dopLink.length === 0) {
                        dopLink = "?"
                    }
                    dopLink+= "&currency_amount_max=" + data.filter.paymentTo
                }

            }
            if(data.ship !== null && data.ship !== undefined){
                if(dopLink.length === 0) {
                    dopLink = "?"
                }
                dopLink+= "&ship=" + data.ship;
            }

            if(data) {
                if(data?.files_exists !== null && data?.files_exists !== undefined) {
                    dopLink+= "&files_exists=" + data.files_exists;
                }
            }
            if(dopLink.length) {
                link+=dopLink.replaceAll(",", "");
            }
            let ct = axios.CancelToken.source();
            if(!data.stopCancel) {
                ctx.commit("cancelPostPayment")
                ctx.commit("cancelTokenSource1", ct);
            }

            let is_archived = false;
            if(data.is_archived !== null && data.is_archived !== undefined) {
                is_archived = data.is_archived;
            }
            let fs = [];
            if(data.filter !== null && data.filter !== undefined) {
                if(data.filter.fs !== null && data.filter.fs) {
                    fs = data.filter.fs
                }
            }
            let limit = 25;
            if(data.limit !== null && data.limit !== undefined) {
                limit = data.limit
            }
            // console.log(7)
            console.log(data)
            return await axios.get(link, {
                params: {
                    cash_flow_ship: data.cash_flow_ship,
                    search_ftp: data.search,
                    limit: limit,
                    ordering: ((data.ordering !== null && data.ordering !== undefined && (""+data.ordering).length !== 0) ? data.ordering : "-execute_date")+ (data.sortById !== false ? ',id' : ''),
                    data_type: data.type,
                    record_type: ["PAYMENT"],
                    ftp: true,
                    is_archive: is_archived,
                    execute_date_before: data.after,
                    execute_date_after: data.before,
                    financial_statement: fs,
                },
                cancelToken: ct.token,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                if(!data.move_to) {
                    if(data.left_menu) {
                        ctx.state.nextLinkPaymentLeftList = response.data.next
                    } else {
                        ctx.state.nextLinkPayment = response.data.next
                    }
                    return response.data.results
                }
                return {results: response.data.results, next: response.data.next}
            })
        },
        async axiosFoldersGetPaymentLoadMore(ctx) {
          return await axios.get(ctx.state.nextLinkPayment, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              ctx.state.nextLinkPayment = res.data.next
              return res.data.results;
          })
        },
        async axiosFoldersGetPaymentLoadMoreLeftList(ctx) {
            return await axios.get(ctx.state.nextLinkPaymentLeftList, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                ctx.state.nextLinkPaymentLeftList = res.data.next
                res.data.results.forEach(item=> {
                    item['link'] = {name: 'Folders - Payments Folders', params: {detailData: JSON.stringify({id: item.id, date: item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'})}}
                    item['folders'] = []
                    item['hide_svg'] = true
                    item['type_load'] = 'payment'
                    item['type_object'] = 'payment'
                    item['is_show'] = false
                    item['is_load'] = false
                    item['created_in'] = false
                    item['name'] = item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'
                })
                ctx.state.foldersList = ctx.state.foldersList.concat(res.data.results)
                return res.data.results;
            })
        },
        async axiosGetFoldersForPaymentDetail(ctx, data) {
            return await axios.get(process.env.VUE_APP_URL+'/payment/'+data.id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    search: data.search,
                    is_archive_files: data.is_archive_files
                }
            }).then(res=> {
                res.data.files.forEach(item=> {
                    item['url'] = item.file
                })
                if(!data.notAdded) {
                    ctx.commit('setFiles', res.data.files)
                }
                return data.onlyFolder ? res.data.files.filter(a=> a.file_type === 'FOLDER') : res.data.files


            })
        }
    },
    mutations: {
        emitChangeLeftMenuSetCurrentFoldersInActiveObject(state) {
            state.emitSetCurrentFoldersInLeftMenuActualObject++
        },
        setActiveSectionFolders(state, name) {
            state.activeNameSection = name
        },
        cancelTokenSource1(state, data) {
            state.cancelTokenSource1 = data;
        },
        cancelPostPayment(state) {
            if(state.cancelTokenSource1 !== null) {
                state.cancelTokenSource1.cancel();
            }
        },
        cancelToken(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
            }
        },
        cancelTokenSource(state, data) {
            state.cancelTokenSource = data;
        },
        setFiles(state, data) {
            state.files = data.filter(a=> a.file_type === 'FILE' && a.url)
            state.folders = data.filter(a=> a.file_type === 'FOLDER')
        },
        selectFolderAll(state, data) {
            if(!data.bool) {
                state.selectedFolders = state.selectedFolders.filter(a=> data.items.map(a=> {return a.id}).indexOf(a.id) === -1)
            } else {
                state.selectedFolders = state.selectedFolders.filter(a=> data.items.map(a=> {return a.id}).indexOf(a.id) === -1)
                data.items.forEach(item=> {
                    state.selectedFolders.push(item)
                })
            }
            state.selected = (state.selectedFiles).concat(state.selectedFolders)
        },
        selectFileAll(state, data) {
            if(!data.bool) {
                state.selectedFiles = state.selectedFiles.filter(a=> data.items.map(a=> {return a.id}).indexOf(a.id) === -1)
            } else {
                state.selectedFiles = state.selectedFiles.filter(a=> data.items.map(a=> {return a.id}).indexOf(a.id) === -1)
                data.items.forEach(item=> {
                    state.selectedFiles.push(item)
                })
            }
            state.selected = (state.selectedFiles).concat(state.selectedFolders)
        },
        selectFolder(state, data) {
            if(state.selectedFolders.find(a=> a.id === data.id)) {
                state.selectedFolders = state.selectedFolders.filter(a=> a.id !== data.id)
            } else {
                state.selectedFolders.push(data)
            }
            state.selected = (state.selectedFiles).concat(state.selectedFolders)
        },
        selectFile(state, data) {
            if(state.selectedFiles.find(a=> a.id === data.id)) {
                state.selectedFiles = state.selectedFiles.filter(a=> a.id !== data.id)
            } else {
                state.selectedFiles.push(data)
            }
            state.selected = (state.selectedFiles).concat(state.selectedFolders)
        },
        setSearchForFolders(state, search) {
            state.search = search
        },
        setNameSectionFolders(state, name) {
            state.nameSectionFolders = name
        },
        setListViewFoldersPage(state, bool) {
            state.listView = bool
        },
        clearSelectedFoldersPage(state) {
            state.selectedFiles = []
            state.selectedFolders = []
            state.selected = []

        },
        showModalOpenAccess(state, item) {
            state.showModalOpenAccessBool= !state.showModalOpenAccessBool
            state.dataForModalAccess = item
        },
        showModalOpenAccessBoolMeth(state, bool) {
            state.showModalOpenAccessBool= bool
        },
        moveToLocalFildesForFoldersSection(state, data) {
            console.log('moveToLocalFildesForFoldersSection', data)
            if(!data.inSection) {
                const dfs = (tree) => {
                    // console.log(tree)
                    if (Array.isArray(tree) !== true && tree.id == data.idMoveTo) {
                        return tree;
                    }
                    const children = tree.folders;
                    children.forEach(dfs);
                };
                let item = state.foldersList.find(item=> {
                    if(dfs(item)) {
                        return dfs(item)
                    }
                })
                console.log('moveToLocalFildesForFoldersSection', item)

                if(item) {
                    item.folders = item.folders.concat(data.items)
                }
            } else {
                state.foldersList = state.foldersList.concat(data.items)
            }

        },
        deleteFileWithFoldersSection(state, id) {
            state.folders = state.folders.filter(a=> a.id !== id)
            state.files = state.files.filter(a=> a.id !== id)
            // state.foldersList

            // const dfs = (tree) => {
            //     if(Array.isArray(tree) === true && tree.find(a=> a.id == id)) {
            //         return 'main'
            //     }
            //     if (Array.isArray(tree) !== true && tree.folders.find(a=> a.id == id)) {
            //         return tree;
            //     }
            //     const children = Array.isArray(tree) !== true ? tree.folders : tree;
            //     children.forEach(dfs);
            // };
            // let item = dfs(state.foldersList)
            // if(item) {
            //     if(item !== 'main') {
            //         item.folders = item.folders.filter(a=> a.id !== id)
            //     } else {
            //         state.foldersList = state.foldersList.filter(a=> a.id !== id)
            //     }
            // }
            // eslint-disable-next-line no-unused-vars
            let itemFind = null
            let findFolder = (it, array) => {
                if(Array.isArray(array) == true) {
                    let item = array.find(el=> el.id == id)
                    if(item) {
                        itemFind = it
                        return null;
                    } else {
                        return array.forEach(el=> findFolder(el, el.folders))
                    }
                }
            }
            findFolder(null, state.foldersList)
            console.log('itemFind', itemFind)
            if(itemFind) {
                itemFind.folders = itemFind.folders.filter(el=> el.id !== id)
            } else {
                state.foldersList = state.foldersList.filter(el=> el.id !== id)
            }
        },
        setFoldersForMoveTo(state, data) {
            state.foldersForMoveTo = data
        },
        setFoldersList(state, data) {
            state.foldersList = data
        },
        pushFoldersList(state, data) {
            state.foldersList.push(...data)
        },
        addedFolderInMoveToModal(state, data) {
            console.log('addedFolderInMoveToModal', data)
            if(!data.item.folders) {
                data.item['folders'] = []
            }
            data.item['is_load'] = false
            data.item['is_show'] = false
            if(Number.isInteger(data.activeFolder) == true) {
                // const dfs = (tree) => {
                //     // console.log(tree)
                //     if (Array.isArray(tree) !== true && tree.id == data.activeFolder) {
                //         return tree;
                //     }
                //     const children = tree.folders;
                //     children.forEach(dfs);
                // };
                // let item = state.foldersList.find(item=> {
                //     if(dfs(item)) {
                //         return dfs(item)
                //     }
                // })
                if(data.activeFolder) {
                    let itemFind = null
                    let findFolder = (array) => {
                        if(Array.isArray(array) == true) {
                            let item = array.find(el=> el.id == data.activeFolder)
                            if(item) {
                                itemFind = item
                                return null;
                            } else {
                                return array.forEach(el=> findFolder(el.folders))
                            }
                        }
                    }
                    findFolder(state.foldersList)
                    if(itemFind) {
                        itemFind.folders.push(data.item)
                    }
                }

            } else {
                // let item = state.foldersList.find(a=> a.name == data.activeFolder)
                state.foldersList.push(data.item)

            }
        },
        changeNameForFolderLeftMenu(state, data) {
            let itemFind = null
            let findFolder = (array) => {
                if(Array.isArray(array) == true) {
                    let item = array.find(el=> el.id == data.id)
                    if(item) {
                        itemFind = item
                        return null;
                    } else {
                        return array.forEach(el=> findFolder(el.folders))
                    }
                }
            }
            findFolder(state.foldersList)
            if(itemFind) {
                itemFind.name = data.name
            }
                // let changeItem = state.foldersList.find(a=> a.id == data.id)
                // if(changeItem)
                // changeItem.name = data.name
        },
        setActivityFolders(state, data) {
            state.activityFolders = data
        },
        createEmitGetActivityFolders(state) {
            state.loadActivityEmit++
        },
        setIdFileForFocus(state, data) {
            state.idFileForFocus = data
        }
    },
    state: {
        idFileForFocus: null,
        emitSetCurrentFoldersInLeftMenuActualObject: 827342,
        activeNameSection: '',
        nextLinkPaymentLeftList: null,
        nextLinkPayment: null,
        cancelTokenSource1: null,
        nextLinkActivity: null,
        loadActivityEmit: -23437643,
        activityFolders: [],
        foldersList: [],
        listSectionsIds: [],
        foldersForMoveTo: [],
        cancelTokenSource: null,
        usersForFolders: [],
        dataForModalAccess: null,
        showModalOpenAccessBool: false,
        selected: [],
        nameSectionFolders: '',
        tasksOptionList: [
            {name: 'Manually Tasks'},
            {name: 'Vessel Search'},
            {name: 'Cargo Search'}
        ],
        listView: true,
        selectedFolders: [],
        selectedFiles: [],
        folders: [],
        files: [],
        search: '',
        listSections: [
            {name: 'My Files', is_show: false, contains_folders: true, contains_files: true, folders: []},
            {name: 'Tasks', is_show: false, contains_folders: false, contains_files: false, folders: []},
            {name: 'Payments', is_show: false, contains_folders: false, contains_files: false, folders: [],},
            {name: 'Vessels', is_show: false, contains_folders: false, contains_files: false, folders: []},
            {name: 'Ports', is_show: false, contains_folders: false, contains_files: false, folders: []},
            {name: 'Counterparties', is_show: false, contains_folders: false, contains_files: false, folders: []}
        ]
    },
    getters: {

    },
}