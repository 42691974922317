<template>
  <div>
    <folders-list-file-and-folder :hide-folders="true"></folders-list-file-and-folder>
  </div>
</template>

<script>
import FoldersListFileAndFolder from "@/components/folders/folders-list-file-and-folder";
import {mapMutations} from "vuex";
export default {
  name: "folders-ports-countries-options-beth-list-detail",
  components: {FoldersListFileAndFolder},
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setNameSectionFolders'])
  },
  mounted() {
    this.setNameSectionFolders('Ports')
  }
}
</script>

<style scoped>

</style>