<template>
  <transition name="fade">
    <div class="wrap" v-if="show">
      <div class="filter" v-on:click="closeModal()"></div>
      <div class="content-modal" :class="{'show-menu': show}">
        <button class="close-modal" v-on:click="closeModal">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
            <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
          </svg>
        </button>
        <p class="title fz18 fw500 text-dark1">Choose columns</p>
        <!--        {{dataOpenModal}}-->

        <checkboxes-for-select-column ref="checkboxSelectColumn" :setSel="selectDef" @change="changeSelect"></checkboxes-for-select-column>
        <div class="bottom-panel">
          <button class="blue-button" v-on:click="clickSave">Save</button>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import CheckboxesForSelectColumn from "@/components/addEmployeesStep2/checkboxes-for-select-column";
import {mapMutations} from "vuex";
export default {
  name: "modal-select-column",
  components: {CheckboxesForSelectColumn},
  data() {
    return {
      show: false,
      dataOpenModal: null,
      select: [],
      selectDef: [],
      keyRender: -945634,
    }
  },
  methods: {
  ...mapMutations(['changeActiveCheckboxInMenuEditDepartment', 'changeActiveCheckboxInMenuAddDepartment', 'changeActiveCheckboxInMenu']),
    closeModal() {
      this.show = false;
      this.select = [];
    },
    changeSelect(sel) {
      this.select = sel;
    },
    openModal(data) {
      console.log(data)
      this.show = true;
      this.dataOpenModal = data;
      this.select = data.active
      this.selectDef = data.active
      // this.$refs.checkboxSelectColumn.setSelect(data.active)
    },
    clickSave() {
      // let k = 0;
      // for(let i=0; i<this.getPermissions.length; i++) {
      //   if(this.getPermissions[i].titleForButton === this.dataOpenModal.title) {
      //     k = i;
      //     i = this.getPermissions.length;
      //   }
      // }
      // this.mountedSelectActiveCheckboxInMenuAll({title: this.dataOpenModal.title, id: this.dataOpenModal.id, departments: [], employees: [], })
      if(this.dataOpenModal.textModalOpen === "editDepartment") {
        // this.setTextButtonEditDepartment({indexBlock: k, indexDetails: this.dataOpenModal.index, text: this.select, textName: "account"})
        this.changeActiveCheckboxInMenuEditDepartment({title: this.dataOpenModal.titleEdit, id: this.dataOpenModal.id, text: '', departments: [], employees: [], account: [], columns: this.select})
        this.$emit("changeSelectEdit")
      } else
      if(this.dataOpenModal.textModalOpen === 'addNewDepartment') {
        this.changeActiveCheckboxInMenuAddDepartment({title: this.dataOpenModal.title2, id: this.dataOpenModal.id, text: '', departments: [], employees: [], account: [], columns: this.select})
        this.$emit("changeSelectAdd")
      }else {
        this.changeActiveCheckboxInMenu({title: this.dataOpenModal.title2, id: this.dataOpenModal.id, text: '', departments: [], employees: [], account: [], columns: this.select})
        this.$emit("changeSelect")
      }
      this.closeModal();
    },
  },
}
</script>

<style scoped lang="scss">
.bottom-panel {
  height: 44px;
  width: 480px;
  padding: 4px 56px;
  background: var(--color-modal);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: fixed;
  z-index: 350;
  bottom: 0;
  right: 0;
  //right: 56px;
  .blue-button {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    @media (max-width: 1280px) {
      width: 368px;
    }
    @media (max-width: 768px) {
      width: 93.75vw;
    }
  }
  @media (max-width: 1280px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    right: 0;
  }
  @media (max-width: 768px) {
    padding: 4px 0;
  }
}
.wrap {
  z-index: 99999;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
}
.filter {
  opacity: 0.15;
  background-color: var(--color-filter-modal);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.content-modal {
  transform: translateX(100%);
  transition: transform 0.3s linear;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: var(--color-modal);
  width: 480px;
  height: 100%;
  padding: 15px 64px 59px 56px;
  box-sizing: border-box;
}
.close-modal {
  position: absolute;
  top: 17px;
  left: 20px;
}
.title {
  margin-bottom: 24px;
}
.block-input {
  //margin-top: 20px;
  margin-bottom: 12px;
  width: 100%;
  p {
    margin-bottom: 4px;
  }
  input {
    height: 36px !important;
    width: 100% !important;
  }
}
/deep/ p {
  margin-bottom: 0;
}
.dart-mode {
  .filter {
    opacity: 0.75;
  }
}
.show-menu {
  transform: translateX(0);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  .content-modal {
    transform: translateX(100%);
  }
}
</style>