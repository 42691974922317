<template>
  <div class="flex flex-col h-full">
    <div class="relative flex border-b border-gray-dark p-t-px entries_elems">
      <input-format-number
        class="w-full"
        v-model="localNumb"
        inputClass="p-y-2 border-0 fw700 entries_outline h33"
        placeholder="0.00"
        :fractionDigits="2"
        :isRoundOutput="false"
        :boldText="true"
      ></input-format-number>
      <div class="absolute top-2 right-2 flex items-center fz14 cursor-pointer svg-white-dart" @click="$refs.dropCurrency.openList()">
        {{item[column].currency}}
        <svg class="m-l-0.5" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.72443 4.39844C2.45716 4.39844 2.32331 4.72158 2.5123 4.91057L5.78804 8.18631C5.9052 8.30346 6.09515 8.30346 6.21231 8.18631L9.48803 4.91057C9.67702 4.72158 9.54317 4.39844 9.2759 4.39844H2.72443Z" fill="#2C2C2C"/></svg>
      </div>
      <counterparties-drop
        class="hide_input vessel_owners__select_currency"
        ref="dropCurrency"
        :list="valutaList"
        :isDisabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
        :removeSelectItemBeforeClick="false"
        :dontSelect="true"
        :activeItem="activeCurrency"
        @select="selectedCurrency"
        title="Select currency"
      />
    </div>

    <div 
      class="flex border-b border-gray-dark"
      :class="{
        'h-full': !item.has_account && !menu.includes('account') && !menu.includes('linked') && !menu.includes('statement')
      }"
    >
      <div class="flex w-full border-r border-gray-dark entries_elems">
        <async-select
          class="w-full"
          :id="'client_client-'+item.id"
          placeholder="Choose Counterparty"
          url="/client/dropdown"
          :params="{is_archived: false}"
          watchKey="company_name"
          :openDirection="''"
          :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
          :closeOnSelect="true"
          :allowEmpty="true"
          v-model="item[column].client"
          @input="onSelectClient"
        />
      </div>

      <div class="flex w-7 flex-shrink-0">
        <router-link class="flex h-full w-full" v-if="item[column].client" :to="getUrl({id: item[column].client.id, model: 'Client'})" target='_blank'>
          <entries-arrow class="m-auto"/>
        </router-link>
      </div>
    </div>

    <div 
      class="flex border-b border-gray-dark entries_elems" 
      :class="{
        'h-full': !item.has_account && !menu.includes('account') && !menu.includes('linked')
      }"
      v-if="menu.includes('statement')"
    >
      <div class="w-7/12 border-r border-gray-dark">
        <async-select
          :id="'client_account-'+item.id"
          placeholder="Choose Account"
          url="/payment/account/"
          :params="{is_archive: false}"
          watchKey="name"
          :beforeWatchKey="'account_id'"
          :openDirection="''"
          :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
          :closeOnSelect="true"
          :allowEmpty="true"
          :isMultiple="true"
          v-model="item[column].account"
          @on-select="$emit('on-update', false)"
        />
      </div>

      <div class="w-5/12 border-r border-gray-dark">
        <default-select
          :id="'stat_member'+item.id+column"
          placeholder="Stat. Memb."
          :options="getCpDate"
          :closeOnSelect="true"
          :allowEmpty="true"
          :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
          trackBy=""
          v-model="activeSM"
          @on-select="onSelectCpDate"
        />
      </div>

      <div class="flex w-7 flex-shrink-0">
        <router-link class="flex h-full w-full" v-if="item[column].account && item[column].account.length" :to="{path: '', query: {account: item[column].account.map(el=>el.id)}}" target='_blank'>
          <entries-arrow class="m-auto"/>
        </router-link>
      </div>
    </div>

    <div 
    class="flex p-x-2 p-y-1" 
    :class="{
      'border-b border-gray-dark': item.has_account && menu.includes('account'),
      'h-full': !item.has_account && !menu.includes('account')
    }"
    v-if="menu.includes('linked')"
    >
      <linked-wrap
        class="w-full"
        @on-change="onChangeLinkeds"
        :isEntries="true"
        :disabled="$route.meta.isArchive"
        :linkedPayments="item[column].linked_payments"
        :linkedInvoices="item[column].linked_invoices"
        :linkedOwners="item[column].linked_owners"
        :linkedType="item.operation_type == 'payment' || item.operation_type == 'income' ? 'Payments' : 'Invoices'"
      />
    </div>
    <div class="flex h-full entries_elems" v-if="item.has_account && menu.includes('account')">
      <div class="w-7/12 border-r border-gray-dark">
        <async-select
          :id="'client_account-'+item.id"
          placeholder="Choose Real Account"
          url="/client/dropdown"
          :params="{is_archived: false}"
          watchKey="company_name"
          :openDirection="''"
          :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
          :closeOnSelect="true"
          :allowEmpty="true"
          v-model="item[column].real_account"
          @on-select="$emit('on-update', false)"
        />
      </div>

      <div class="w-5/12 border-r border-gray-dark">
        <default-select
          :id="'statement_members'+item.id+column"
          placeholder="Stat. Memb."
          :options="getRealCpDate"
          :closeOnSelect="true"
          :isMultiple="true"
          :searchable="false"
          :allowEmpty="true"
          :isDisabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
          trackBy=""
          v-model="activeRealSM"
          @on-select="onSelectRealCpDate"
        />
      </div>

      <div class="flex w-7 flex-shrink-0">
        <router-link class="flex h-full w-full" v-if="item[column].real_account" :to="getUrl({id: item[column].real_account.id, model: 'Client'})" target='_blank'>
          <entries-arrow class="m-auto"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex'
import moment from 'moment'

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import asyncSelect from '@/components/reuse/asyncSelect'
import entriesArrow from '@/components/entries/entriesArrow'
import linkedWrap from '@/components/entries/linked/linkedWrap'
import defaultSelect from '@/components/reuse/defaultSelect'
import inputFormatNumber from '@/components/reuse/inputFormatNumber'

export default {
  components: {
    counterpartiesDrop,
    asyncSelect,
    entriesArrow,
    linkedWrap,
    defaultSelect,
    inputFormatNumber
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    column: {
      type: String,
      required: true
    },
    menu: Array
  },
  data() {
    return {
      activeCurrency: null,
      activeSM: null,
      activeRealSM: [],
      localNumb: null
    }
  },
  watch: {
    'item.debit.total_amount': function(newVal, oldVal) {
      if(newVal != oldVal){
        this.localNumb = this.item.debit.total_amount
      }
    },
    localNumb: function() {
      this.onChangeNumb()
    }
  },
  computed: {
    ...mapState({
      default: state => state.entries.defaultEntriesDetails,
      valutaList: state => state.payment.valutaList
    }),
    ...mapGetters(["getPermissionsByType"]),
    getUrl() {
      return obj => {
        if(!obj.id) return '/accounts/entries'
        
        if(obj.model == 'Client')
          return `/counterparties/${obj.id}`

        return '/accounts/entries'
      }
    },
    getIsPayment() {
      return this.item.operation_type == 'payment' || this.item.operation_type == 'invoice' || this.item.operation_type == 'bill' || this.item.operation_type == 'income' 
    },
    getCpDate() {
      if(this.item.vessel?.cargo_contracts){
        let result = this.item.vessel.cargo_contracts.filter(el => {
          return el.owner?.id == this.item[this.column].client?.id
        })
        result = result.map(el => {
          return moment(el.cp_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          /* return {
            id: el.id,
            name: moment(el.cp_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          } */
        })
        result = [...new Set(result)]
        return result
      }else
        return []
    },
    getRealCpDate() {
      if(this.item.vessel?.cargo_contracts){
        let result = this.item.vessel.cargo_contracts.map(el => {
          return moment(el.cp_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          /* return {
            id: el.id,
            name: moment(el.cp_date).format('DD.MM.YYYY')
          } */
        })
        result = [...new Set(result)]
        return result
      }
      return []
    }
  },
  methods: {
    ...mapMutations(['addTip']),
    getPermissionShowDopInfoAcc() {
      return this.getPermissionsByType('show_dop_info_for_acc_in_payments')
    },
    selectedCurrency(currency) {
      let bankCol = this.column == 'debit' ? 'credit' : 'debit'
      if(this.item[bankCol].requisite){
        if(currency.name != this.item[bankCol].requisite.currency){
          this.addTip('Current bank/cash account is in the other currency. Please, choose another bank/cash account')
          this.$refs.dropCurrency.render()
          return false
        }
      }else if(this.item[bankCol].bank){
        if(currency.name != this.item[bankCol].bank.currency){
          this.addTip('Current bank/cash account is in the other currency. Please, choose another bank/cash account')
          this.$refs.dropCurrency.render()
          return false
        }
      }
      if(this.item.debit.currency != currency.name || this.item.credit.currency != currency.name){
        this.item.debit.currency = currency.name
        this.item.credit.currency = currency.name
        this.$emit('on-update', true)
      }
    },
    onSelectCpDate() {
      this.item[this.column].stat_member = this.activeSM
      this.$emit('on-update', false)
    },
    onSelectRealCpDate() {
      this.item[this.column].statement_members = this.activeRealSM
      this.$emit('on-update', false)
    },
    onSelectClient(client) {
      if(this.item[this.column].client?.type?.account){
        this.item[this.column].account = this.item[this.column].client?.type?.account
      }

      if(!client){
        this.item.debit.client = null
        this.item.credit.client = null
      }else{
        this.item.debit.client = client
        this.item.credit.client = client
      }

      this.$emit('on-update', false)
    },
    onChangeNumb() {
      if(this.localNumb == Math.abs(this.item.debit.total_amount) || this.localNumb == Math.abs(this.item.credit.total_amount)) return false

      let result = this.localNumb
      if(!result)
        result = 0
      if(this.item.debit.total_amount < 0) result *= -1
      this.item.debit.total_amount = result
      this.item.credit.total_amount = result
      this.$emit('on-update', true)
    },
    onChangeLinkeds() {
      if(this.column == 'debit'){
        this.item.credit.linked_payments = this.item.debit.linked_payments
        this.item.credit.linked_owners = this.item.debit.linked_owners
        this.item.credit.linked_invoices = this.item.debit.linked_invoices
      }else{
        this.item.debit.linked_payments = this.item.credit.linked_payments
        this.item.debit.linked_owners = this.item.credit.linked_owners
        this.item.debit.linked_invoices = this.item.credit.linked_invoices
      }
      this.$emit('on-update', false)
    }
  },
  beforeMount() {
    if(!this.item[this.column]) {
      this.item[this.column] = {...this.default}
    }

    this.activeCurrency = {
      id: this.item[this.column].currency,
      name: this.item[this.column].currency
    }

    if(this.item[this.column].stat_member){
      //this.activeSM = this.getCpDate.find(el => el.name == moment(this.item[this.column].stat_member).format('DD.MM.YYYY'))
      this.activeSM = moment(this.item[this.column].stat_member).format('DD.MM.YYYY')
    }

    if(this.item[this.column].statement_members?.length){
      this.activeRealSM = this.item[this.column].statement_members/* .filter(el=>{
        return this.getRealCpDate.find(item => item == el)
      }) */
    }
  },
  mounted() {
    if(this.item[this.column].total_amount)
      this.localNumb = Math.abs(this.item[this.column].total_amount)
  }
}
</script>

<style>

</style>