
<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title" v-if="account">Archive Bank Account <button class="modal-close" @click="closeModal()"></button></p>
        <p class="modal-content_title" v-else>Archive Bank Accounts <button class="modal-close" @click="closeModal()"></button></p>

        <p class="modal-content_descr" v-if="account">Are you sure you want to archive the Bank Account <b>{{account.id}}: {{account.name}} ({{account.financial_statement.name}})</b>?</p>
        <p class="modal-content_descr" v-else>Are you sure you want to archive <b>{{list.length}}</b> Bank Account{{list.length > 1 ? 's' : '' }}?</p>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal">Cancel</button>
        <button class="btn btn-next" @click="onSubmit">Archive</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['account', 'list'],
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      if(this.account)
        this.$emit('submit', [this.account.id])
      else
        this.$emit('submit', this.list)
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-wrap{
  .modal-content{
    max-width: 424px;
    .modal-content_bottom{
      border-top: none;
    }
  }
}
</style>