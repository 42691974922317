<template>
  <div class="wrap-folder">
    <button class="folder flex align-items-center" :class="classTitle" v-on:click="openContent = !openContent">
      <svg :class="{'r180': openContent}" class="pr8" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.76508 4.69922C2.49781 4.69922 2.36396 5.02236 2.55295 5.21135L5.82869 8.48709C5.94585 8.60424 6.1358 8.60424 6.25296 8.48708L9.52868 5.21135C9.71767 5.02236 9.58382 4.69922 9.31655 4.69922H2.76508Z" fill="#2C2C2C"/>
      </svg>
      <svg width="16" class="pr8" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7047 7.02664C15.4768 6.65058 15.0741 6.42578 14.6275 6.42578H4.68255C4.18958 6.42578 3.74123 6.71017 3.53999 7.15051L0.909607 12.7947C1.00891 13.2079 1.3861 13.5167 1.83537 13.5167H12.4161C12.8684 13.5167 13.2819 13.2549 13.4839 12.8407L15.7575 8.17708C15.937 7.80804 15.9171 7.378 15.7047 7.02664Z" fill="#9AA3B0"/>
        <path d="M3.02643 6.48583C3.2271 6.03417 3.67479 5.74228 4.16702 5.74228H13.5157V4.4846C13.5157 3.96616 13.0998 3.54429 12.5888 3.54429H7.13766C7.12967 3.54429 7.12418 3.54213 7.12213 3.54057L6.14646 2.10571C5.97366 1.85117 5.68826 1.69922 5.38333 1.69922H1.83674C1.32543 1.69922 0.909607 2.12109 0.909607 2.63953V11.1538L3.02643 6.48583Z" fill="#BDCBD6"/>
      </svg>
      <p class="uppercase text fz14 fw500 text-dots-overflow">{{name}}</p>
    </button>
    <div class="content" v-if="openContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "folder-in-list-vessel",
  props: {
    name: {
      type: String,
      default: '',
    },
    defaultOpen: {
      type: Boolean,
      default: true,
    },
    classTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      openContent: true,
    }
  },
  mounted() {
    this.openContent = this.defaultOpen;
  }
}
</script>

<style scoped lang="scss">
.wrap-folder {
  width: 100%;
  box-sizing: border-box;
}
.folder {
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background-color: var(--color-blue-lightest);
  }
}
.text {
  width: calc(100% - 44px);
  text-align: left;
}
</style>