<template>
  <div>
    <folders-list-links class="mt16" :items="ports"></folders-list-links>
  </div>
</template>

<script>
import FoldersListLinks from "@/components/folders/folders-list-links";
import {mapActions, mapMutations} from "vuex";
export default {
  name: "folders-ports-countries",
  components: {FoldersListLinks},
  props: {
    search: String,
    sort: String,
    routeChange: Number,
  },
  computed: {
    getPortsIds() {
      let detail = this.$route.params.countryDetail
      if(detail) {
        detail = JSON.parse(detail)
        return detail.idsPorts
      }
      return []
    },
  },
  data() {
    return {
      ports: []
    }
  },
  watch: {
    search: function () {
      this.loadData()
    },
    sort: function () {
      this.loadData()
    },
    routeChange: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions(['axiosGetPortsForFolders']),
    ...mapMutations(['setNameSectionFolders']),
    async loadData() {
      let results = await this.axiosGetPortsForFolders({params: {search: this.search, ordering: this.sort, port_ids: this.getPortsIds.toString()}})
      this.ports = results.map(a=> {
        return {
          name: a.name,
          last_updated: '',
          link: {name: 'Folders - Ports Countries Ports Options', params: {...this.$route.params, portDetail: JSON.stringify({name: a.name, id: a.id})}}
        }
      })
    },
  },
  mounted() {
    this.setNameSectionFolders('Ports')
    this.loadData()
  }
}
</script>

<style scoped>

</style>