<template>
  <div class="wrap-loader" :class="{'wrap-loader-local': isLocal}">
    <div class="sk-fading-circle">
      <div class="sk-circle sk-circle-1"></div>
      <div class="sk-circle sk-circle-2"></div>
      <div class="sk-circle sk-circle-3"></div>
      <div class="sk-circle sk-circle-4"></div>
      <div class="sk-circle sk-circle-5"></div>
      <div class="sk-circle sk-circle-6"></div>
      <div class="sk-circle sk-circle-7"></div>
      <div class="sk-circle sk-circle-8"></div>
      <div class="sk-circle sk-circle-9"></div>
      <div class="sk-circle sk-circle-10"></div>
      <div class="sk-circle sk-circle-11"></div>
      <div class="sk-circle sk-circle-12"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-loader",
  props: {
    isLocal: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$spinkit-size: 4em !default;
$spinkit-spinner-color: #319FEF !default;

.wrap-loader {
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(44,44,44,0.95);
  position: fixed;
  width: 100%;
  top: 0;
  height: 100vh;
  box-sizing: border-box;
  //top: 0;
  //right: 0px;
  .sk-fading-circle {
    $circleCount: 12;
    $animationDuration: 1.2s;

    width: $spinkit-size;
    height: $spinkit-size;
    position: relative;
    margin: auto;

    .sk-circle {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .sk-circle:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $spinkit-spinner-color;
      border-radius: 100%;
      animation: sk-fading-circle-delay $animationDuration infinite ease-in-out both;
    }

    @for $i from 2 through $circleCount {
      .sk-circle-#{$i} {
        transform: rotate((360deg / $circleCount * ($i - 1)));
      }
    }

    @for $i from 2 through $circleCount {
      .sk-circle-#{$i}:before {
        animation-delay: (-$animationDuration + $animationDuration / $circleCount * ($i - 1));
      }
    }

  }

  @keyframes sk-fading-circle-delay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
}

.wrap-loader-local{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color-bgc-page-white);
  .sk-fading-circle {
    width: 40px;
    height: 40px;
  }
}
</style>