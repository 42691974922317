<template>
  <div class="dafault-list-more_wrap">
    <button class="dafault-list-more" @click="$emit('load-more')" :class="{active: ifActive}">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="#094172" xmlns="http://www.w3.org/2000/svg"><path d="M18.5007 11.25H18.5C18.4015 11.2501 18.304 11.2696 18.213 11.3073C18.1221 11.3451 18.0394 11.4004 17.9699 11.4701C17.9003 11.5398 17.8451 11.6226 17.8075 11.7136C17.7699 11.8046 17.7505 11.9022 17.7506 12.0007C17.7497 13.0521 17.4606 14.0832 16.9146 14.9819C16.3687 15.8805 15.5869 16.6123 14.6542 17.0978C13.7215 17.5832 12.6736 17.8037 11.6243 17.7353C10.5751 17.6669 9.56467 17.3121 8.70291 16.7097C6.10604 14.8913 5.47263 11.2992 7.29101 8.70225C9.10938 6.10534 12.7015 5.47188 15.2984 7.29031C15.6913 7.56469 16.0484 7.88708 16.3613 8.25H14.2507C14.0518 8.25 13.861 8.32902 13.7203 8.46967C13.5797 8.61032 13.5007 8.80109 13.5007 9C13.5007 9.19891 13.5797 9.38968 13.7203 9.53033C13.861 9.67098 14.0518 9.75 14.2507 9.75H17.7507C17.9496 9.75 18.1403 9.67098 18.281 9.53033C18.4216 9.38968 18.5007 9.19891 18.5007 9V5.5C18.5007 5.30109 18.4216 5.11032 18.281 4.96967C18.1403 4.82902 17.9496 4.75 17.7507 4.75C17.5518 4.75 17.361 4.82902 17.2203 4.96967C17.0797 5.11032 17.0007 5.30109 17.0007 5.5V6.74897C16.7375 6.49925 16.4561 6.26945 16.1588 6.06147C15.3789 5.51542 14.4992 5.12832 13.5697 4.92227C12.6403 4.71622 11.6793 4.69525 10.7418 4.86057C9.80423 5.02589 8.90842 5.37426 8.1055 5.88578C7.30258 6.3973 6.60828 7.06196 6.06223 7.84181C4.95943 9.41679 4.52745 11.3653 4.86133 13.2588C5.19521 15.1523 6.2676 16.8356 7.84257 17.9384C9.05518 18.7925 10.5021 19.2511 11.9853 19.2513C12.4125 19.2511 12.8389 19.2137 13.2595 19.1393C14.9381 18.8432 16.4587 17.9652 17.5543 16.6595C18.6499 15.3538 19.2505 13.7038 19.2507 11.9993C19.2505 11.8006 19.1714 11.61 19.0308 11.4694C18.8901 11.3289 18.6995 11.25 18.5007 11.25Z"/></svg> Show more
    </button>
  </div>
</template>

<script>
export default {
  name: "pagination-panel-default",
  props: {
    ifActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
  @keyframes more-anim {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  .dafault-list-more_wrap .dafault-list-more{
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    color: var(--color-blue-dark);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    //transition: border 0.3s, color 0.3s;
    svg{
      margin-right: 6px;
      path {
        fill: var(--color-blue-dark)
      }
      //transition: fill 0.3s;
    }
    &:hover{
      border: 1px solid var(--color-blue-dark);
      background-color: var(--color-blue-dark);
      color: var(--color-white);
      svg{
        path {
          fill: var(--color-white) !important;
        }
      }
    }
    &.active{
      border: 1px solid var(--color-border);
      background-color: var(--color-blue-dark);
      color: #BDCBD6;
      cursor: default;
      svg{
        animation: more-anim 1s linear infinite;
        path {
          fill: #BDCBD6 !important;
        }
      }
    }
  }

</style>