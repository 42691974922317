import axios from 'axios'
import QueryString from "querystring";

export default {
    actions: {
        async axiosCreateNewCheckList(ctx, data) {
            return await axios.post(process.env.VUE_APP_URL+"/checklist/", data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            });
        },
        axiosGetCheckLists(ctx, data) {
            return axios.get(process.env.VUE_APP_URL+"/checklist/", {
                params: data,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(res=> {
                return res.data.results
            })
        },
        axiosPatchChecklist(ctx,data) {
            return axios.put(process.env.VUE_APP_URL + "/checklist/"+data.id, data.form, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
        async axiosGetCheckListDetail(ctx, params) {
            return await axios.get(process.env.VUE_APP_URL + '/checklist/detail/', {
                params: params,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            })
        },
        async axiosAddCheckboxesForTask(ctx, data) {
          return await axios.post(process.env.VUE_APP_URL + '/checklist/detail/', data, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          })
        },
        axiosChangeCheckboxInTask(ctx, data) {
            return axios.post(process.env.VUE_APP_URL + '/checklist/detail/', data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            })
        }
        // axiosDeleteCheckList(ctx, id) {
        //     return axios.delete(process.env.VUE_APP_URL + "/checklist/"+id, {
        //         headers: {
        //             'Authorization': 'token ' + ctx.getters.getToken,
        //         }
        //     })
        // }
    },
    mutations: {
        setStatusShowPageForCreateTask(state, data) {
            state.showPageForCreateTask = data;
        },
        sortNameList(state) {
          state.sortToName++
        },
    },
    state: {
        sortToName: -8732647823462,
        showPageForCreateTask: false,
    },
    getters: {

    },
}