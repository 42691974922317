<template>
  <section class="page_section__wrap bg-gray-lightest" :class="{'overflow-hidden': !overflowOn}">
    <div class="click-zone position-absolute w100Proc h100Proc z-10" v-if="isBurgerActive && windowWidth < 1688" v-on:click="hideList"></div>

    <div class="bread-panel border-b bg-white border-gray-light h32 box-border flex items-center p-x-6 p-y-2" v-if="$route.path.indexOf('archive') >= 0">
      <router-link :to="{name: 'Calculator'}" class="bread__link">Calculator</router-link>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="counterparties-add_bread__separator"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"></path></svg>
      <span class="bread__link">Archive</span>
    </div>

    <div class="w-full bg-white border-b border-gray-light min-h-44px box-border flex items-center p-x-6 p-y-2">
      <button 
        class="m-r-4 fz14 calc_burger" 
        :class="{'active': isBurgerActive}"
        v-if="windowWidth < 1688" 
        @click="isBurgerActive = !isBurgerActive"
      >
        <span class=""></span>
        <span class=""></span>
        <span class=""></span>
      </button>
      <div class="fw500" v-if="!activeTab">File is not chosen</div>
      <calc-top
          :class="{'widthCalc': windowWidth < 1688}"
        :isBurgerActive="isBurgerActive"
        @on-archive="onArchive"
        @on-burger="onBurger"
        @eventExportXLSX="eventExportXLSX"
        @eventExportPDF="downloadPDF"
        v-else
      />
    </div>

    <div class="flex h-full relative" :class="{'overflow-hidden': overflowOn}" >
      <div
        class="w-full max-w-210 bg-white border-r border-gray-light "
        :class="{
          'overflow-auto': overflowOn,
          'calc_left-panel': windowWidth < 1688,
          'open': isBurgerActive,
        }"
      >
        <calc-left-top v-if="creators" v-model="searchText" @on-create="onCreate"/>
        <calc-list :newCreateId="newCreateId" @modalEvent="overflowOn = !overflowOn" ref="calcList" v-if="tabs" :searchText="searchText"/>
      </div>
      <div class="flex w-full overflow-auto">
        <calc-empty v-if="!activeTab" @on-create="onCreate('list')"/>
        <router-view v-else/>
      </div>
    </div>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1745"
        :filename="getName()"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        class="pdf"
        :margin="5"
        ref="html2Pdf"
        @hasDownloaded="hasDownloaded"
    >
      <div slot="pdf-content" class="wrap-pdf">
        <div v-if="activeTab">
          <div class="html2pdf__page-break">
            <calc-top :exportPdf="true" />
            <tab-cargo-pdf :exportPdf="true"></tab-cargo-pdf>
          </div>
          <div class="">
            <calc-top :exportPdf="true" />
            <tab-vessels-pdf ref="tabVesselPdf" :exportPdf="true"></tab-vessels-pdf>
          </div>
          <div class="html2pdf__page-break">
            <calc-top :exportPdf="true" />
            <tab-compare></tab-compare>
          </div>
        </div>
      </div>
    </vue-html2pdf>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from "moment"
import calcTop from '@/components/calc/calcTop'
import calcLeftTop from '@/components/calc/calcLeftTop'
import calcList from '@/components/calc/calcList'
import calcEmpty from '@/components/calc/calcEmpty'
import TabCompare from "@/views/calc/tabCompare";
import TabCargoPdf from "@/views/calc/tabCargoPdf";
import TabVesselsPdf from "@/views/calc/tabVesselsPdf";

export default {
  components: {
    TabVesselsPdf,
    TabCargoPdf,
    TabCompare,
    calcTop,
    calcLeftTop,
    calcList,
    calcEmpty,
  },
  data() {
    return {
      searchText: '',
      isBurgerActive: false,
      overflowOn: true,
      newCreateId: null,
    }
  },
  computed: {
    ...mapState({
      activeCreator: state => state.tabs.activeCreator,
      creators: state => state.tabs.creators,
      tabs: state => state.tabs.tabs,
      activeFuelType: state => state.parsedVessel.activeFuelType,
      windowWidth: state => state.windowWidth
    }),
    ...mapGetters(['getCurrentTab', 'getNumbFromString', 'getDartMode', 'getLoginData']),
    activeTab() {
      return this.getCurrentTab(this.$route.params.id)
    },
  },
  methods: {
    ...mapActions(['axiosGetTabs', 'axiosPostTab', 'axiosGetCalcCargo', 'axiosGetXLSX', 'axiosGetCalcVessel']),
    ...mapMutations(['showLoad', 'hideLoad', 'setDartMode', 'setTurnOnDarkModeAfterDownloadPDF']),
    getValueFromXmlWithoutNumber(value) {
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined && value !== 'Invalid date') {
        // if(moment(""+value, "YYYY-MM-DD HH:mm:ss", true).isValid() || moment(""+value, "YYYY-MM-DD HH:mm", true).isValid() || moment(""+value, "YYYY-MM-DD", true).isValid()) {
        //   return moment(value).format("DD.MM.YYYY")
        // }
        return ""+value
      }
      return null
    },
    getName() {
      return this.activeTab ? this.activeTab.name : ''
    },
    getValueFromXml(value, toFixed) {
      if(value === 'No Linked Operation') {
        return null
      }
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined && value !== 'Invalid date') {
        if(moment(""+value, "YYYY-MM-DD HH:mm:ss", true).isValid() || moment(""+value, "YYYY-MM-DD HH:mm", true).isValid() || moment(""+value, "YYYY-MM-DD", true).isValid()) {
          return moment(value).format("DD.MM.YYYY")
        }
        let perc = false
        if(value[value.length-1] === '%') {
          perc = true;
          value = value.replaceAll('%','')
        }
        if(!isNaN(value)) {
          let fixedNumber = value ? value : 0
          if(fixedNumber < 0) {
            fixedNumber = fixedNumber * (-1)
          }
          fixedNumber = parseFloat(fixedNumber)
          fixedNumber = (''+((fixedNumber).toFixed(toFixed ? toFixed : 2))).replaceAll(".", ",").replaceAll(' ', '')
          if(perc) {
            fixedNumber+='%'
          }
          return fixedNumber
        }
        return ""+value
      }
      return null
    },
    async eventExportXLSX() {
      this.showLoad()
      let cargoList = await this.axiosGetCalcCargo(this.$route.params.id)
      // let editedCargoId = cargoList ? cargoList[cargoList.length-1].id : null
      // let detail = cargoList?.find(el=>el.id == editedCargoId)
      let formAxios= {
        sheet_title: this.activeTab.name,
        file_name: this.activeTab.name,
        data: []
      }
      formAxios.data.push({id:formAxios.data.length + 1, values: ['CARGOS TAB']});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      cargoList.map(detail=> {
        let isQuantityMt = !(!detail.quantity_mt && (detail.quantity_min || detail.quantity_max))
        formAxios.data.push({id:formAxios.data.length + 1, values: ['CARGO', null, null, null,
            'PORT OF LOADING', null, null, null,
            'PORT OF DISCHARGING', null, null, null,]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Cargo Class*', null, this.getValueFromXmlWithoutNumber(detail.cargo_class), null, null, null,
            'Load. Port*', null, this.getValueFromXmlWithoutNumber(detail.loading_port_name), null,
            'Disch. Port*', null, this.getValueFromXmlWithoutNumber(detail.discharging_port_name), null,]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Quantity', null, isQuantityMt ? (detail.quantity_mt ? this.getValueFromXml( detail.quantity_mt) + ' mt' : 'mt') : (detail.quantity_min ? this.getValueFromXml(detail.quantity_min) + ' mt' : 'mt'), isQuantityMt ? (detail.quantity_percentage ? this.getValueFromXml(detail.quantity_percentage) + ' %' : '%') : (detail.quantity_max ? this.getValueFromXml(detail.quantity_max) + ' mt' : 'mt'),
            detail?.loading_rate_type == 'RATE' ? 'Rate':'Days', null, this.getValueFromXmlWithoutNumber((detail?.loading_rate ? detail?.loading_rate : '') + (detail?.loading_rate_type == 'RATE' ? ' mt/day' : '')), null,
            detail?.discharging_rate_type == 'RATE' ? 'Rate':'Days', null, this.getValueFromXmlWithoutNumber((detail?.discharging_rate ? detail?.discharging_rate : '') + (detail?.discharging_rate_type == 'RATE' ? ' mt/day' : '')), null,]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Freight', null, this.getValueFromXmlWithoutNumber(detail.freight ? detail.freight + ' $/mt' : ' $/mt'), null,
            'Terms', null, this.getValueFromXmlWithoutNumber(detail.loading_terms), null,
            'Terms', null, this.getValueFromXmlWithoutNumber(detail.discharging_terms), null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Cg Comm', null, detail.cg_comm ? this.getValueFromXml(detail.cg_comm) + ' %' : '%', null,
            'Turn time', null, this.getValueFromXmlWithoutNumber(detail.loading_turn_time + ' h'), null,
            'Turn time', null, this.getValueFromXmlWithoutNumber(detail.discharging_turn_time + ' h'), null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Stowage Factor*', null, detail.stowage_factor_m ? this.getValueFromXml(detail.stowage_factor_m) + ' m³/mt' : 'm³/mt', detail.stowage_factor_ft ? this.getValueFromXml(detail.stowage_factor_ft) + ' ft³/mt' : 'ft³/mt',
            'Liner In', null, this.getValueFromXmlWithoutNumber((detail.loading_liner ? detail.loading_liner : '') + ' $/mt'), null,
            'Liner In', null, this.getValueFromXmlWithoutNumber((detail.discharging_liner ? detail.discharging_liner : '') + ' $/mt'), null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Extra Cargo Costs', null, detail.extra_cargo_costs ? this.getValueFromXml(detail.extra_cargo_costs) + ' $' : '$', null,
            'Draft', null, this.getValueFromXml((detail.loading_draft ? detail.loading_draft : '') + ' m'), null,
            'Draft', null, this.getValueFromXml((detail.discharging_draft ? detail.discharging_draft : '') + ' m'), null,]});
        formAxios.data.push({id:formAxios.data.length + 1, values: [null,  null, null, null,
            'Water density', null, this.getValueFromXmlWithoutNumber(detail.loading_water_density), null,
            'Water density', null, this.getValueFromXmlWithoutNumber(detail.discharging_water_density), null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null,
            'Seasonal mark', null, this.getValueFromXmlWithoutNumber(detail.loading_seasonal_mark), null,
            'Seasonal mark', null, this.getValueFromXmlWithoutNumber(detail.discharging_seasonal_mark), null]});
      })




      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: ['Cargo','Quantity', 'Freight', 'Load Port', 'Rate/Days', 'Terms', 'Turn time', 'Water', 'Season', 'Disch. Port', 'Rate/Days', 'Terms', 'Turn time', 'Water', 'Season']});
      for(let i=0; i<cargoList.length; i++) {
        let row = cargoList[i];
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXmlWithoutNumber(row.ballast_voyage ? 'Balast' : row.cargo_class), this.getValueFromXmlWithoutNumber(row.quantity_mt ? row.quantity_mt : (row.quantity_min && row.quantity_max) ? Math.round(((+row.quantity_min+row.quantity_max)/2)*1000000)/1000000 : row.quantity_min ? row.quantity_min : row.quantity_max ? row.quantity_max : null), this.getValueFromXmlWithoutNumber(row.freight), this.getValueFromXmlWithoutNumber(row.loading_port_name), this.getValueFromXmlWithoutNumber(row?.loading_rate), this.getValueFromXmlWithoutNumber(row.loading_terms), this.getValueFromXmlWithoutNumber(row.loading_turn_time), this.getValueFromXmlWithoutNumber(row.loading_water_density), this.getValueFromXmlWithoutNumber(row.loading_seasonal_mark), this.getValueFromXmlWithoutNumber(row.discharging_port_name), this.getValueFromXmlWithoutNumber(row?.discharging_rate), this.getValueFromXmlWithoutNumber(row.discharging_terms), this.getValueFromXmlWithoutNumber(row.discharging_turn_time), this.getValueFromXmlWithoutNumber(row.discharging_water_density), this.getValueFromXmlWithoutNumber(row.discharging_seasonal_mark)]});
      }
      // formAxios.data.push({id:formAxios.data.length + 1, values: []});
      // formAxios.data.push({id:formAxios.data.length + 1, values: []});

      let vessels = await this.axiosGetCalcVessel(this.$route.params.id)
      let cargos = await this.axiosGetCalcCargo(this.$route.params.id)
      vessels.forEach(vessel=>{
        this.setIntakeForRoutesInVessel(vessel, cargos)
      })
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});

      formAxios.data.push({id:formAxios.data.length + 1, values: ['VESSELS TAB']});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      vessels = JSON.parse(JSON.stringify(vessels))
      for(let i=0; i<vessels.length; i++) {
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        let vessel = vessels[i]
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Vessel name', 'DWT', 'Built', 'Hire']});
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXmlWithoutNumber(vessel.name), this.getValueFromXmlWithoutNumber(vessel.dwt), this.getValueFromXmlWithoutNumber(vessel.year), this.getValueFromXmlWithoutNumber(vessel.hire)]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Scruber', vessel.scrubber ? 'Active' : 'Not active']});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['DELIVERY', null, null, null, 'OTHER EXPENSES', null, null, null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Hire', null, vessel.hire ? this.getValueFromXml(vessel.hire) + '$' : '$', null, 'Expense', null, vessel.expenses ? this.getValueFromXml(vessel.expenses) + '$' : '$', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Ball.bonus', null, vessel.ballast_bonus ? this.getValueFromXml(vessel.ballast_bonus) + '$' : '$', null, 'Mist. cost', null, vessel.mins_cost ? this.getValueFromXml(vessel.mins_cost) + '$' : '$', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['AddCom', null, vessel.additional_commission ? this.getValueFromXml(vessel.additional_commission) + '%' : '%', null, 'Insurance', null, vessel.insurance ? this.getValueFromXml(vessel.insurance) + '$' : '$', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Date', null, this.getValueFromXml(vessel.date), null, 'Other', null, vessel.other ? this.getValueFromXml(vessel.other) + '$' : '$', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Time', null, this.getValueFromXmlWithoutNumber(vessel.time), null, 'Steaming', null, vessel.steaming_day ? this.getValueFromXmlWithoutNumber(vessel.steaming_day) + 'd' : 'd', vessel.steaming_hour ? this.getValueFromXmlWithoutNumber(vessel.steaming_hour) + 'h' : 'h']});
        formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, null, null, vessel.steaming_amount ? this.getValueFromXml(vessel.steaming_amount) + '$' : '$', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        let prefix = 'full';
        let prefix1 = 'eco';
        formAxios.data.push({id:formAxios.data.length + 1, values: ['SLOW SPEED', null, null, null, 'ECO SPEED', null, null, null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Sp Ball', null, vessel[prefix+'_sp_ball'] ? this.getValueFromXml(vessel[prefix+'_sp_ball']) + 'kt' : 'kt', null, 'Sp Ball', null, vessel[prefix1+'_sp_ball'] ? this.getValueFromXml(vessel[prefix1+'_sp_ball']) + 'kt' : 'kt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Ifo Ball', null, vessel[prefix+'_ifo_ball'] ? this.getValueFromXml(vessel[prefix+'_ifo_ball']) + 'mt' : 'mt', null, 'Ifo Ball', null, vessel[prefix1+'_ifo_ball'] ? this.getValueFromXml(vessel[prefix1+'_ifo_ball']) + 'mt' : 'mt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Mgo Ball', null, vessel[prefix+'_mgo_ball'] ? this.getValueFromXml(vessel[prefix+'_mgo_ball']) + 'mt' : 'mt', null, 'Mgo Ball', null, vessel[prefix1+'_mgo_ball'] ? this.getValueFromXml(vessel[prefix1+'_mgo_ball']) + 'mt' : 'mt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Sp Lad', null, vessel[prefix+'_sp_lad'] ? this.getValueFromXml(vessel[prefix+'_sp_lad']) + 'kt' : 'kt', null, 'Sp Lad', null, vessel[prefix1+'_sp_lad'] ? this.getValueFromXml(vessel[prefix1+'_sp_lad']) + 'kt' : 'kt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Ifo Lad', null, vessel[prefix+'_ifo_lad'] ? this.getValueFromXml(vessel[prefix+'_ifo_lad']) + 'mt' : 'mt', null, 'Ifo Lad', null, vessel[prefix1+'_ifo_lad'] ? this.getValueFromXml(vessel[prefix1+'_ifo_lad']) + 'mt' : 'mt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Mgo Lad', null, vessel[prefix+'_mgo_lad'] ? this.getValueFromXml(vessel[prefix+'_mgo_lad']) + 'mt' : 'mt', null, 'Mgo Lad', null, vessel[prefix1+'_mgo_lad'] ? this.getValueFromXml(vessel[prefix1+'_mgo_lad']) + 'mt' : 'mt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        prefix = 'slow'
        formAxios.data.push({id:formAxios.data.length + 1, values: ['FULL SPEED', null, null, null, 'DEDUCTIBLES', null, null, null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Sp Ball', null, vessel[prefix+'_sp_ball'] ? this.getValueFromXml(vessel[prefix+'_sp_ball']) + 'kt' : 'kt', null, 'Constants', null, vessel.constants ? this.getValueFromXml(vessel.constants) + 'mt' : 'mt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Ifo Ball', null, vessel[prefix+'_ifo_ball'] ? this.getValueFromXml(vessel[prefix+'_ifo_ball']) + 'mt' : 'mt', null, 'VLSFO price', null, vessel.vlsfo_price ? this.getValueFromXml(vessel.vlsfo_price) + '$' : '$', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Mgo Ball', null, vessel[prefix+'_mgo_ball'] ? this.getValueFromXml(vessel[prefix+'_mgo_ball']) + 'mt' : 'mt', null, 'HSFO price', null, vessel.hsfo_price ? this.getValueFromXml(vessel.hsfo_price) + '$' : '$', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Sp Lad', null, vessel[prefix+'_sp_lad'] ? this.getValueFromXml(vessel[prefix+'_sp_lad']) + 'kt' : 'kt', null, 'LSMGO price', null, vessel.lsmgo_price ? this.getValueFromXml(vessel.lsmgo_price) + '$' : '$', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Ifo Lad', null, vessel[prefix+'_ifo_lad'] ? this.getValueFromXml(vessel[prefix+'_ifo_lad']) + 'mt' : 'mt', null, 'BOB', null, vessel.bob ? this.getValueFromXml(vessel.bob) + 'mt' : 'mt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Mgo Lad', null, vessel[prefix+'_mgo_lad'] ? this.getValueFromXml(vessel[prefix+'_mgo_lad']) + 'mt' : 'mt', null, 'Fresh water', null, vessel.fresh_water ? this.getValueFromXml(vessel.fresh_water) + 'mt' : 'mt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['PORT CONSUMPTION']});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Ifo Idle', null, vessel.ifo_idle ? this.getValueFromXml(vessel.ifo_idle) + 'mt' : 'mt', null, 'Mgo Idle', null, vessel.mgo_idle ? this.getValueFromXml(vessel.mgo_idle) + 'mt' : 'mt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Ifo Wk', null, vessel.ifo_wk ? this.getValueFromXml(vessel.ifo_wk) + 'mt' : 'mt', null, 'Mgo Wk', null, vessel.mgo_wk ? this.getValueFromXml(vessel.mgo_wk) + 'kt' : 'kt', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['TONNAGE']});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['DWCC', null, vessel.dwcc ? this.getValueFromXml(vessel.dwcc) + 'mt' : 'mt', null, 'Grain', null, vessel.grain ? this.getValueFromXml(vessel.grain) + 'm³' : 'm³', null]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Draft', null, vessel.draft ? this.getValueFromXml(vessel.draft) + 'm' : 'm', null, 'TPC', null, vessel.tpc ? this.getValueFromXml(vessel.tpc) : null, null]});

        let detail = vessel
        prefix = 'full'
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['FULL' + (this.activeFuelType == 'full' ? ' (+)' : '')]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Result, $:', this.getValueFromXml(detail[prefix+'_result']), 'Hire BEP, $:', this.getValueFromXml(detail[prefix+'_hire']), 'Freight BEP, $:', this.getValueFromXml(detail[prefix+'_freight']), 'Voyage Days:', this.getValueFromXml(detail[prefix+'_voyage_days'])]});
        prefix = 'optimal'
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['OPTIMAL' + (this.activeFuelType == 'optimal' ? ' (+)' : '')]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Result, $:', this.getValueFromXml(detail[prefix+'_result']), 'Hire BEP, $:', this.getValueFromXml(detail[prefix+'_hire']), 'Freight BEP, $:', this.getValueFromXml(detail[prefix+'_freight']), 'Voyage Days:', this.getValueFromXml(detail[prefix+'_voyage_days'])]});
        prefix = 'eco'
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['ECO' + (this.activeFuelType == 'eco' ? ' (+)' : '')]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Result, $:', this.getValueFromXml(detail[prefix+'_result']), 'Hire BEP, $:', this.getValueFromXml(detail[prefix+'_hire']), 'Freight BEP, $:', this.getValueFromXml(detail[prefix+'_freight']), 'Voyage Days:', this.getValueFromXml(detail[prefix+'_voyage_days'])]});
        prefix = 'slow'
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['SLOW' + (this.activeFuelType == 'slow' ? ' (+)' : '')]});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Result, $:', this.getValueFromXml(detail[prefix+'_result']), 'Hire BEP, $:', this.getValueFromXml(detail[prefix+'_hire']), 'Freight BEP, $:', this.getValueFromXml(detail[prefix+'_freight']), 'Voyage Days:', this.getValueFromXml(detail[prefix+'_voyage_days'])]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        let getTimeBallast = 0;
        let getTimeLaden = 0;
        let getTimeInPort = 0;
        this.getActiveVesselRoutes(vessel).forEach(el=>{
          if(!el.intake){
            if(el[this.activeFuelType+'_streaming_days'])
              getTimeBallast += el[this.activeFuelType+'_streaming_days']
            // if(el[this.activeFuelType+'_streaming_time']){
            //   let time = el[this.activeFuelType+'_streaming_time'].split(':')
            //   getTimeBallast += +time[0]/24
            //   getTimeBallast += +time[1]/24/60
            // }
          }
          if(el.intake){
            if(el[this.activeFuelType+'_streaming_days'])
              getTimeLaden += el[this.activeFuelType+'_streaming_days']
            // if(el[this.activeFuelType+'_streaming_time']){
            //   let time = el[this.activeFuelType+'_streaming_time'].split(':')
            //   getTimeLaden += +time[0]/24
            //   getTimeLaden += +time[1]/24/60
            // }
          }
          if(el.port_stay){
            let time = el.port_stay.split(':')
            getTimeInPort += +time[0]/24
            getTimeInPort += +time[1]/24/60
          }
        })
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXmlWithoutNumber(vessel.name), null, null, 'Time ballast, d: ', this.getValueFromXml(getTimeBallast), null, 'Time laden, d:', this.getValueFromXml(getTimeLaden), null, 'Time in port/canal, d: ', this.getValueFromXml(getTimeInPort), null, 'Total time, d:', this.getValueFromXml(getTimeInPort+getTimeLaden+getTimeBallast)]});
        formAxios.data.push({id:formAxios.data.length + 1, values: []});

        formAxios.data.push({id:formAxios.data.length + 1, values: ['Intake, mt', 'Port / Canal', 'Status', 'Dist., m', 'SECA, m', 'Draft restr.', 'Disbursment, $', 'Days Steaming', null, 'Arrival', null,  'Port Stay,h', 'Departure', null, 'Speed', 'F', 'E', 'IFO, mt', 'MGO, mt']});

        let rows = vessel.additional_info.routes;
        for(let j=0; j<rows.length; j++) {
          let dot = rows[j];
          formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXmlWithoutNumber(dot.intake ? dot.intake : 'Balast'), this.getValueFromXmlWithoutNumber(dot.port_name), this.getValueFromXmlWithoutNumber(dot.status), this.getValueFromXmlWithoutNumber(dot.dist), dot.seca ? this.getValueFromXmlWithoutNumber(dot.seca) + (dot.has_seca ? ' (+)' : ' (-)') : (dot.has_seca ? ' (+)' : ' (-)'), this.getValueFromXmlWithoutNumber(dot.draft_restr), this.getValueFromXmlWithoutNumber(dot.disbursement), dot[this.activeFuelType+'_streaming_days'] ? this.getValueFromXmlWithoutNumber(dot[this.activeFuelType+'_streaming_days']) + '' : '', this.getValueFromXmlWithoutNumber(dot[this.activeFuelType+'_arrival_date']), this.getValueFromXmlWithoutNumber(dot[this.activeFuelType+'_arrival_time']), this.getValueFromXmlWithoutNumber(dot.port_stay), this.getValueFromXmlWithoutNumber(dot[this.activeFuelType+'_departure_date']), this.getValueFromXmlWithoutNumber(dot[this.activeFuelType + '_departure_time']), this.getValueFromXmlWithoutNumber(this.dotSpeed(vessel, dot)), this.activeFuelType == 'full' ? '+' : (this.activeFuelType == 'eco' || this.activeFuelType == 'slow') ? '-' : dot.is_full ? '+' : '-', this.activeFuelType == 'eco' ? '+' : (this.activeFuelType == 'full' || this.activeFuelType == 'slow') ? '-' : dot.is_eco ? '+' : '-', this.getValueFromXmlWithoutNumber(dot[this.activeFuelType+'_ifo_mt']), this.getValueFromXmlWithoutNumber(dot[this.activeFuelType+'_mgo_mt'])]});
        }
        formAxios.data.push({id:formAxios.data.length + 1, values: []});
        formAxios.data.push({id:formAxios.data.length + 1, values: ['TOTAL']});
        let total = 0
        let disbursement = 0;
        let days = Math.floor((this.countSteamingTime(rows))[0]/24);
        let getInfo = 0;
        let getMgo = 0;
        rows.forEach(el=>{
          total += +el.intake
          disbursement += +el.disbursement
          days += +el.streaming_days
          getInfo += +el[this.activeFuelType+'_ifo_mt']
          getMgo += +el[this.activeFuelType+'_mgo_mt']
        })
        let times = ((this.countSteamingTime(rows))[0]%24 < 10 ? '0' : '')+(this.countSteamingTime(rows))[0]%24+':'+((this.countSteamingTime(rows))[1] < 10 ? '0' : '')+(this.countSteamingTime(rows))[1]
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(total), null, null, null, null, null, this.getValueFromXml(disbursement), days ? this.getValueFromXmlWithoutNumber(days) + ' d' : 'd', times ? this.getValueFromXmlWithoutNumber(times) + ' h' : 'h', null, null, this.getPortStay(rows) ? this.getValueFromXmlWithoutNumber(this.getPortStay(rows)) : null, null, null, null, null, null, this.getValueFromXml(getInfo), this.getValueFromXml(getMgo)]});
      }
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});

      formAxios.data.push({id:formAxios.data.length + 1, values: ['COMPARE TAB']});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      formAxios.data.push({id:formAxios.data.length + 1, values: []});

      let fuelTypes = ['full', 'eco', 'optimal', 'slow'];
      formAxios.data.push({id:formAxios.data.length + 1, values: ['Sort best to worst by: ' + (''+this.activeFuelType).toUpperCase()]});
      formAxios.data.push({id:formAxios.data.length + 1, values: ['Vessel name', 'DWT', 'Built', 'Hire']});
      fuelTypes.map(()=> {
        formAxios.data[formAxios.data.length-1].values.push('Result, $', 'Hire BEP, $', 'Freight BEP, $', 'Voyage Days')
      })
      for(let i=0; i<this.sortingVessels(vessels).length; i++) {
        let vessel = this.sortingVessels(vessels)[i];
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXmlWithoutNumber(vessel.name), this.getValueFromXmlWithoutNumber(vessel.dwt), this.getValueFromXmlWithoutNumber(vessel.year), this.getValueFromXml(vessel.hire, 4)]});
        fuelTypes.map(fuel=> {
          formAxios.data[formAxios.data.length-1].values.push(this.getValueFromXml(vessel[fuel+'_result'], 4), this.getValueFromXml(vessel[fuel+'_hire'], 4), this.getValueFromXml(vessel[fuel+'_freight'], 4), this.getValueFromXml(vessel[fuel+'_voyage_days'], 4))
        })
      }






      formAxios.data.push({id:formAxios.data.length + 1, values: []});
      this.axiosGetXLSX(formAxios).then(response => {
        let link = response.data;
        function download(content, contentType, fileName) {
          var link1 = document.createElement("a");
          link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
          link1.download = fileName;
          link1.click();
        }
        download(link, "application/vnd.ms-excel", this.activeTab.name+".xls")
        this.hideLoad()

      });
    },
    getActiveVesselRoutes(vessel) {
      return vessel?.additional_info?.routes
    },
    sortingVessels(vessels) {
      let sorting = [...vessels]
      return sorting.sort((a,b) => {
        return a[this.activeFuelType+'_result'] < b[this.activeFuelType+'_result'] ? 1 : -1
      })
    },
    setIntakeForRoutesInVessel(vessel, cargos) {
      let routes = vessel.additional_info.routes
      routes.forEach(dot=>{
        if(!dot.cargo){
          return false
        } else{
          console.log('----');
          console.log('cargo or balast:', cargo?.cargo_class);
          console.log('port:', dot?.port?.name);
          let result

          let cargo = cargos.find(el => el.id == dot.cargo)
          if(cargo?.ballast_voyage){
            dot.intake = null
            return false
          }
          let water_destiny = this.getNumbFromString(cargo[dot.status+'_water_density'])

          let sswd = vessel.draft ? vessel.draft : 0
          let draft_restr = dot.draft_restr ? dot.draft_restr : 0
          let tpc = vessel.tpc ? vessel.tpc : 0
          let bob = vessel.bob ? vessel.bob : 0
          let constanta = vessel.constants ? vessel.constants : 0
          let fresh_water = vessel.fresh_water ? vessel.fresh_water : 0
          let sdwt = vessel.dwt ? vessel.dwt : 0

          if(cargo[dot.status+'_seasonal_mark'] == 'tropical'){
            sdwt = sdwt + (sswd/48)* tpc*100
            console.log(`tropical sdwt(${sdwt}) = sdwt(${vessel.dwt}) + (sswd(${sswd})/48) * tpc(${tpc})*100`);
          }else if(cargo[dot.status+'_seasonal_mark'] == 'winter'){
            sdwt = sdwt - (sswd/48)* tpc*100
            console.log(`winter sdwt(${sdwt}) = sdwt(${vessel.dwt}) - (sswd(${sswd})/48) * tpc(${tpc})*100`);
          }


          let fwa = (sswd/48)*1.1
          fwa = fwa ? Math.round(fwa*10000)/10000 : 0
          console.log(`fwa(${fwa}) = (sswd(${sswd})/48)*1.1`);

          let dwa = ((1.025 - water_destiny)/0.025)*fwa
          dwa = dwa ? Math.round(dwa*10000)/10000 : 0
          console.log(`dwa(${dwa}) = ((1.025 - water_destiny(${water_destiny}))/0.025)*fwa(${fwa})`);

          if(cargo[dot.status+'_seasonal_mark'] == 'tropical'){
            sswd = sswd + sswd/48 + dwa
            console.log(`tropical sswd(${sswd}) = sswd(${vessel.draft}) + sswd(${vessel.draft})/48 + dwa(${dwa})`);
          }else if(cargo[dot.status+'_seasonal_mark'] == 'winter'){
            sswd = sswd - sswd/48 + dwa
            console.log(`winter sswd(${sswd}) = sswd(${vessel.draft}) - sswd(${vessel.draft})/48 + dwa(${dwa})`);
          }

          let dwcc = sdwt - (sswd + dwa - draft_restr)*100*tpc
          dwcc = dwcc ? Math.round(dwcc*10000)/10000 : 0
          console.log(`dwcc(${dwcc}) = sdwt(${sdwt}) - (sswd(${sswd}) + dwa(${dwa}) - draft_restr(${draft_restr}))*100*tpc(${tpc})`);

          result = dwcc - bob - constanta - fresh_water
          console.log(`intake(${result}) = dwcc(${dwcc}) - bob(${bob}) - constanta(${constanta}) - fresh_water(${fresh_water})`);

          dot.intake = result ? Math.round(result*10000)/10000 : 0
        }
        return dot;
      })
      return vessel;
    },

    countSteamingTime(routes) {
      let hours = 0
      let minutes = 0
      routes.forEach(el=>{
        if(el.streaming_time){
          let timeArr = el.streaming_time.split(':')
          hours += +timeArr[0]
          minutes += +timeArr[1]
        }
      })
      hours += Math.floor(minutes/60)
      minutes = minutes%60
      return [hours,minutes]
    },
    getPortStay(routes) {
      let hours = 0
      let minutes = 0
      routes.forEach(el=>{
        if(el.port_stay){
          let timeArr = el.port_stay.split(':')
          hours += +timeArr[0]
          minutes += +timeArr[1]
        }
      })
      hours += Math.floor(minutes/60)
      minutes = minutes%60
      return (hours < 10 ? '0' : '')+hours+':'+(minutes < 10 ? '0' : '')+minutes
    },
    dotSpeed(vessel, dot) {
      let speed = 0
      let suff = dot.intake ? '_sp_lad' : '_sp_ball'
      if(this.activeFuelType != 'optimal') speed = vessel[this.activeFuelType+suff]
      else speed = dot.optimal_speed

      return speed
    },
    downloadPDF() {
      this.showLoad()
      this.setTurnOnDarkModeAfterDownloadPDF(this.getDartMode)
      this.setDartMode(false)
      this.$refs.tabVesselPdf.renderNew()

      setTimeout(()=>{
        // console.dir(Object(this.$refs.html2Pdf.$el))
        // console.log(this.$refs.html2Pdf.$el.offsetHeight)
        // console.dir(this.$refs.html2Pdf.$el.children[0].children[0].children[0].children[0].children)
        // let DOM = this.$refs.html2Pdf.$el.children[0].children[0].children[0].children[0].children;
        // let top = this.$refs.html2Pdf.$el.offsetTop;
        // let DOM1 = DOM[0].children[1].children
        // DOM1.forEach((children)=> {
        //   children.children.forEach((item)=> {
        //     if(((item.offsetTop + item.offsetHight) - top) > 700) {
        //       item.classList.add('html2pdf__page-break')
        //       console.log(item);
        //       top = item.offsetTop;
        //     }
        //   })
        // })
        // let DOM2 = DOM[1].children[1].children
        // // console.log(DOM2)
        // DOM2.forEach((children)=> {
        //   children.children.forEach((item, i)=> {
        //     // if(((item.offsetTop + item.offsetHight) - top) > 700) {
        //     //   item.classList.add('html2pdf__page-break')
        //     //   console.log(item);
        //     //   top = item.offsetTop;
        //     // }
        //     if(i===3 || i===2) {
        //       console.log(item)
        //       item.children.forEach((children)=> {
        //         // console.log('item', item)
        //         children.children.forEach((item)=> {
        //           if(((item.offsetTop + item.offsetHight) - top) > 700) {
        //             item.classList.add('html2pdf__page-break')
        //             console.log(item);
        //             top = item.offsetTop;
        //           }
        //         })
        //       })
        //     }
        //   })
        // })
        //
        // let DOM3 = DOM[2].children[1].children[0].children[1].children
        // DOM3.forEach((children)=> {
        //   children.children.forEach((item)=> {
        //     if(((item.offsetTop + item.offsetHight) - top) > 700) {
        //       item.classList.add('html2pdf__page-break')
        //       console.log(item);
        //       top = item.offsetTop;
        //     }
        //   })
        // })
        setTimeout(()=> {this.$refs.html2Pdf.generatePdf()}, 5000)}, 50)
    },
    hasDownloaded() {
      this.hideLoad();
    },
    onArchive(tab) {
      this.$refs.calcList.showModalConfirmDeleteFile(tab)
    },
    async onCreate(type) {
      let newTab = {
        name: type == 'list'? 'New file' : 'New folder',
        tab_type: type,
        addcom: 3.75,
        weather_factor: 10
      }
      this.showLoad()
      let newId = await this.axiosPostTab(newTab)
      this.hideLoad()
      if(type == 'list' && newId){
        this.$router.push(`/calculator/${newId}`)
        this.$refs.calcList.onOpenNew(newId)
        setTimeout(()=> {
          this.newCreateId = newId
        }, 600)

      } else {
        this.$refs.calcList.onRenameFolder(newId)

      }
    },

    onBurger() {
      this.isBurgerActive = !this.isBurgerActive
    },
    hideList() {
      // if(this.isBurgerActive && !(e.target.classList.contains('calc_burger') || e.target.parentElement.classList.contains('calc_burger'))) {
        this.isBurgerActive = false
      // }
    }
  },
  async beforeMount() {
    this.showLoad()

    await this.axiosGetTabs({is_archived: this.$route.path.indexOf('archive')>=0, creator: this.activeCreator ? this.activeCreator.id : this.getLoginData.data.user.id})
    this.hideLoad()
  },
  watch: {
    activeCreator: async function () {
      this.showLoad()
      await this.axiosGetTabs({is_archived: this.$route.path.indexOf('archive')>=0, creator: this.activeCreator ? this.activeCreator.id : this.getLoginData.data.user.id})
      this.hideLoad()
    },
    async $route(to, from) {
      this.newCreateId = null
      if((to.path.indexOf('archive')>=0 && from.path.indexOf('archive') === -1) || (to.path.indexOf('archive') === -1 && from.path.indexOf('archive') >= 0) || this.tabs.length === 0) {
        this.showLoad()
        await this.axiosGetTabs({is_archived: this.$route.path.indexOf('archive')>=0, creator: this.activeCreator ? this.activeCreator.id : this.getLoginData.data.user.id})
        // await this.axiosGetTabs(this.$route.path.indexOf('archive')>=0)
        this.hideLoad()
      }
    }
  }
}
</script>

<style lang="scss">
.calc_left-panel{
  position: absolute;
  height: 100%;
  z-index: 100;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  transform: translateX(-110%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  &.open{
    opacity: 1;
    transform: translateX(0);
  }
}
.calc_burger{
  position: relative;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0;
  >span{
    position: absolute;
    left: 0.125rem;
    top: calc(50% - 1px);
    width: calc(100% - 0.25rem);
    height: 2px;
    border-radius: 50%;
    background-color: var(--color-blue-dark);
    transition: transform 0.3s, opacity 0.3s;
    &:nth-child(1){
      transform: translateY(-6px);
    }
    &:nth-child(3){
      transform: translateY(6px);
    }
  }
  &.active{
    >span{
      &:nth-child(1){transform: translateY(0) rotate(45deg);}
      &:nth-child(2){opacity: 0;}
      &:nth-child(3){transform: translateY(0) rotate(-45deg);}
    }
  }
}
</style>
<style scoped lang="scss">
  .wrap-pdf {
    /deep/ {
      div {
        font-size: 12px;
        word-wrap: break-word !important;
        word-break: break-all !important;
        white-space: normal !important;
      }
    }

    &>div>div {
      padding: 10px;
    }
  }
  .widthCalc {
    width: calc(100% - 40px) !important;
  }
</style>