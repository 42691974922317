<template>
  <div class="invoice_table border border-dark rounded-lg"
    :class="{
      'with_trials': getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'
    }"
  >
    <div
      class="border-r flex items-center justify-between p-x-2 p-y-1"
      :class="{'border-b': detail.additional_info.payments && detail.additional_info.payments.length}"
    >
      {{getCharterer.contract_type == 'VOYAGE_CHARTERER' ? 'Less paid to Owners' : 'Less Received'}}
    </div>
    <div 
      class="border-r"
      :class="{'border-b': detail.additional_info.payments && detail.additional_info.payments.length}"
    >
      <input class="p-x-2 p-y-2" type="text" placeholder="Write a comment" v-model.lazy="detail.additional_info.comment">
    </div>
    <div 
      class="border-r"
      :class="{'border-b': detail.additional_info.payments && detail.additional_info.payments.length}"
      v-if="getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'"
    ></div>
    <div 
      class="border-r"
      :class="{
        'border-b': detail.additional_info.payments && detail.additional_info.payments.length,
        'colspan-4-7': getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account',
        'colspan-3-6': !(getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account')
      }"
    ></div>
    <div :class="{'border-b': detail.additional_info.payments && detail.additional_info.payments.length}"></div>

    <template v-for="(payment, i) in getSort(detail.additional_info.payments)">
      <div 
        class="border-r p-x-2 p-y-2 position-relative d-flex align-items-center"
        :class="{'border-b': i < detail.additional_info.payments.length-1, 'bg-gray-light text-gray-text': detail.additional_info.hide_payments.indexOf(payment.id)>=0}"
        :key="payment.id"
      >
        <switcher
            class="switcher-payment switcher_owners-row"
            :id="'paymentd'+payment.id"
            :value="detail.additional_info.hide_payments.indexOf(payment.id) == -1"
            @input="addToHidePayment(payment.id)"
        />
        {{getCharterer.contract_type == 'VOYAGE_CHARTERER' ? 'Less paid to Owners' : 'Less Received'}}

      </div>
      <div 
        class="border-r"
        :class="{'border-b': i < detail.additional_info.payments.length-1, 'bg-gray-light text-gray-text': detail.additional_info.hide_payments.indexOf(payment.id)>=0}"
        :key="payment.id + 'comment'"
      ></div>
      <div 
        v-if="getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'"
        class="border-r"
        :class="{'bg-gray-light text-gray-text': detail.additional_info.hide_payments.indexOf(payment.id)>=0, 'border-b': i < detail.additional_info.payments.length-1}"
        :key="payment.id + 'trials'"
      ></div>
      <div 
        class="border-r p-x-2 p-y-2"
        :class="{
        'bg-gray-light text-gray-text': detail.additional_info.hide_payments.indexOf(payment.id)>=0,
          'border-b': i < detail.additional_info.payments.length-1,
          'colspan-4-7': getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account',
          'colspan-3-6': !(getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account')
        }"
        :key="payment.id + 'date'"
      >{{payment.date}}</div>
      <div 
        class="" 
        :class="{
        'bg-gray-light text-gray-text': detail.additional_info.hide_payments.indexOf(payment.id)>=0,
        'border-b': i < detail.additional_info.payments.length-1}"
        :key="payment.id + 'amount'"
      >
        <p
          class="p-x-2 p-y-2 fw500 text-align-right box-border"
          :class="{'wrap-brackets': payment.amount < 0}"
        >{{Math.abs(payment.amount) | formatNumberTwo}}</p>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import Switcher from "@/components/reuse/switcher.vue";

export default {
  props: {
    detail: Object,
    chartererId: [Number, Object]
  },
  components: {
    Switcher

  },
  watch: {
    getFilteredPayments: {
      deep: true,
      handler() {
        this.setPayments()
      }
    }
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract,
    }),
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
    ...mapGetters(['getPaymentListOriginal', 'getPermissionsByType']),
    getStopCheckStatementMember() {
      let owners = []
      owners.push({id: 'main', owner: this.contract.owner ? this.contract.owner.id : ''})
      this.contract.cargo_contracts.forEach(cargo=> {
        owners.push({id: cargo.id, owner: cargo.owner ? cargo.owner.id : ''})
      })
      return this.getCharterer.owner ? owners.filter(a=> a.owner == this.getCharterer.owner.id).length == 1 : false

      // return this.getCharterer.owner ? owners.findIndex(a=> a.id == this.getCharterer.id) == owners.findIndex(a=> a.owner == this.getCharterer.owner.id) : false
    },
    getFilteredPayments() {
      if(!this.getPaymentListOriginal.length || !this.chartererId) return []

      let filtered = this.getPaymentListOriginal.filter(el => {
        // el.client?.id == this.chartererId && el.paid_date && !el.is_archive && el.status == 'paid'
        return el.paid_date && (this.getStopCheckStatementMember || (el.statement_member && el.statement_member === this.getCharterer.cp_date))
      })
      filtered = filtered.map(el => {
        let amount = (el.payment_type == 'expense' ? 1 : -1) * el.payment_amount
        amount = amount ? amount : 0
        let obj = {
          id: el.id,
          date: moment(el.paid_date).format('DD.MM.YYYY'),
          amount: amount
        }
        return obj
      })
      return filtered.sort((a,b)=>new Date(a.date)-new Date(b.date));
    },
  },
  methods: {
    ...mapActions(['axiosGetPayment']),
    ...mapMutations(['hideLoad']),
    removeToHidePayment(id) {
      if(!this.detail.additional_info.hide_payments) {
        this.detail.additional_info['hide_payments'] = []
        this.detail.additional_info = JSON.parse(JSON.stringify(this.detail.additional_info))
      }
      this.detail.additional_info.hide_payments = this.detail.additional_info.hide_payments.filter(a=> a !== id)
    },
    addToHidePayment(id) {
      if(!this.detail.additional_info.hide_payments) {
        this.detail.additional_info['hide_payments'] = []
        this.detail.additional_info = JSON.parse(JSON.stringify(this.detail.additional_info))
      }
      if(this.detail.additional_info.hide_payments.indexOf(id) == -1) {
        this.detail.additional_info.hide_payments.push(id)
      } else {
        this.detail.additional_info.hide_payments = this.detail.additional_info.hide_payments.filter(a=> a !== id)
      }
    },
    setPayments() {
      if(!this.detail.additional_info.payments) this.$set(this.detail.additional_info, 'payments', [])
      else this.detail.additional_info.payments = []
      this.detail.additional_info.payments = [...this.getFilteredPayments]
    },
    getSort(array) {
      let array1 = JSON.parse(JSON.stringify(array))
      return array1.sort((a,b)=>moment(a.date, 'DD.MM.YYYY').valueOf()-moment(b.date, 'DD.MM.YYYY').valueOf());
    }
  },
  async beforeMount() {
    console.log('clientId', this.chartererId)

    if(!this.detail.additional_info.hide_payments) {
      this.detail.additional_info['hide_payments'] = []
      this.detail.additional_info = JSON.parse(JSON.stringify(this.detail.additional_info))
    }
    let clientId = Number.isInteger(this.chartererId) === true ? this.chartererId : (this.chartererId.id ? this.chartererId.id : '');
    let params = {ordering: 'paid_date',ship: this.$route.params.id, type: 'payment', limit: 9999, is_archived: false, filter: {counterparty: [clientId]}, noLoad: true,  sortById: false}
    if(!this.getStopCheckStatementMember) {
      params['filter']['statement_member'] = this.getCharterer.cp_date
    } else {
      params['filter']['statement_member_empty'] = true
    }
    console.log('axiosGetPayment', params)
    await this.axiosGetPayment(params)
    this.hideLoad()
    // await this.axiosGetPayment({ordering: '-paid_date',ship: this.$route.params.id, type: 'payment', limit: 9999, })
    this.setPayments()
  }
}
</script>

<style scoped lang="scss">
.switcher-payment {
  //position: absolute;
  //left: -50px;
}

.bg-gray-light {
  border-color: var(--color-gray-text) !important;
}


</style>