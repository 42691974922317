<template>
<div>
  <folders-list-file-and-folder></folders-list-file-and-folder>
</div>
</template>

<script>
import FoldersListFileAndFolder from "@/components/folders/folders-list-file-and-folder";
import {mapMutations} from "vuex";
export default {
  name: "folder-task-files",
  components: {FoldersListFileAndFolder},
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setNameSectionFolders']),
  },
  mounted() {
    this.setNameSectionFolders('Tasks')

  }
}
</script>

<style scoped>

</style>