<template>
  <div class="border border-dark-gray rounded-lg calc_vessels__row_vessels__speed active:child-inputs-inset-shadow"
    :class="{'opacity-50 pointer-events-none': vessel.empty || !isAllow}"
  >
    <calc-vessels-speed-inputs
      title="Full Speed"
      prefix="full"
      :vessel="vessel"
    />

    <calc-vessels-speed-inputs
      title="Eco Speed"
      prefix="eco"
      :vessel="vessel"
    />

    <calc-vessels-speed-inputs
      title="Slow Speed"
      prefix="slow"
      :vessel="vessel"
      class="border-r-0"
    />


    <div class="border-r border-dark-gray">
      <div class="p-x-1.5 p-y-1 border-b border-dark-gray bg-gray-dark uppercase fz13 fw500">Deductibles</div>
      <div class="flex border-b border-gray-light">
        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Constants'">Constants</div>
        <div class="w-7/12 bg-white relative" v-tooltip="vessel.constants ? `${vessel.constants}` : null">
          <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="vessel.constants"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
          >
          <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
        </div>
      </div>
      <div class="flex border-b border-gray-light">
        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'BOB'">BOB</div>
        <div class="w-7/12 bg-white relative" v-tooltip="vessel.bob ? `${vessel.bob}` : null">
          <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="vessel.bob"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
          >
          <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
        </div>
      </div>
      <div class="flex  border-gray-light">
        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Fresh water'">Fresh water</div>
        <div class="w-7/12 bg-white relative" v-tooltip="vessel.fresh_water ? `${vessel.fresh_water}` : null">
          <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number"
              v-model.number.lazy="vessel.fresh_water"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
          >
          <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
        </div>
      </div>
<!--      <div class="flex border-b border-gray-light">-->
<!--        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13">&shy;</div>-->
<!--        <div class="w-7/12 bg-white"></div>-->
<!--      </div>-->
<!--      <div class="flex border-b border-gray-light">-->
<!--        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13">&shy;</div>-->
<!--        <div class="w-7/12 bg-white"></div>-->
<!--      </div>-->
<!--      <div class="flex border-b border-dark-gray">-->
<!--        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13">&shy;</div>-->
<!--        <div class="w-7/12 bg-white"></div>-->
<!--      </div>-->
    </div>

    <div class="colspan-2-4">
      <div class=" border-b border-dark-gray p-x-1.5 p-y-1 bg-gray-dark uppercase fz13 fw500">Port consumption</div>
      <div class="d-flex position-relative">
        <button class="blue-button position-absolute l0 w100Proc h24" v-if="vessel.vessel" v-on:click="$emit('saveTemplate')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19.7916 8.41651C19.6527 8.08332 19.4859 7.81935 19.2914 7.62496L16.3747 4.70825C16.1804 4.51397 15.9165 4.34723 15.5831 4.20826C15.25 4.06943 14.9443 4 14.6666 4H4.99992C4.72223 4 4.48606 4.09716 4.29159 4.29163C4.09712 4.48598 4 4.72212 4 4.99995V19C4 19.278 4.09712 19.5141 4.29159 19.7085C4.48606 19.9028 4.72223 20 4.99992 20H19C19.2779 20 19.514 19.9028 19.7084 19.7085C19.9027 19.5141 19.9998 19.278 19.9998 19V9.33334C19.9998 9.05547 19.9304 8.74995 19.7916 8.41651ZM9.33152 5.66663C9.33152 5.57637 9.36454 5.4984 9.43054 5.43229C9.49647 5.36647 9.57462 5.33348 9.66489 5.33348L12.499 5.33339C12.5891 5.33339 12.6672 5.3663 12.7332 5.43219C12.7993 5.4983 12.8323 5.57627 12.8323 5.66654L12.8323 8.2114C12.8323 8.30181 12.7991 8.37988 12.7332 8.44589C12.6672 8.51167 12.5891 8.54469 12.4991 8.54469L9.66492 8.54478C9.57466 8.54478 9.49647 8.51187 9.43058 8.44598C9.36457 8.37987 9.33156 8.3019 9.33156 8.21149L9.33152 5.66663ZM14.6666 18.6668H9.33238V14.6667H14.6666V18.6668ZM18.667 18.6668H15.9999L15.9996 14.3333C15.9996 14.0554 15.9024 13.8194 15.7081 13.6249C15.5137 13.4305 15.2775 13.3332 14.9999 13.3332H8.99909C8.72129 13.3332 8.48512 13.4305 8.29065 13.6249C8.09629 13.8192 7.99906 14.0554 7.99906 14.3333V18.6668H5.33321V5.33325H7.99902L7.99906 8.87813C7.99906 9.15596 8.09618 9.39199 8.29065 9.58645C8.48512 9.78081 8.72129 9.87808 8.99908 9.87808H13.1657C13.4434 9.87808 13.6798 9.78081 13.8739 9.58645C14.0682 9.3921 14.1656 9.15596 14.1656 8.87813L14.1655 5.33325C14.2697 5.33325 14.9061 5.36791 15.0729 5.43734C15.2397 5.50673 15.3577 5.57616 15.4272 5.6456L18.3544 8.57281C18.4238 8.64224 18.4933 8.76217 18.5627 8.93223C18.6324 9.10243 18.6669 9.23615 18.6669 9.33334L18.667 18.6668Z" fill="white"/>
          </svg>
          Save
        </button>
        <div class="border-r border-dark-gray w50Proc">
          <div class="flex border-b border-gray-light">
            <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Ifo Idle'">Ifo Idle</div>
            <div class="w-7/12 bg-white relative" v-tooltip="vessel.ifo_idle ? `${vessel.ifo_idle}` : null">
              <input
                  class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
                  type="number"
                  v-model.number.lazy="vessel.ifo_idle"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
            </div>
          </div>
          <div class="flex border-b border-gray-light h48">
            <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap " v-tooltip="'Ifo Wk'">Ifo Wk</div>
            <div class="w-7/12 bg-white relative" v-tooltip="vessel.ifo_wk ? `${vessel.ifo_wk}` : null">
              <input
                  class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
                  type="number"
                  v-model.number.lazy="vessel.ifo_wk"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
            </div>
          </div>
        </div>

        <div class=" border-dark-gray w50Proc">
          <div class="flex border-b border-gray-light">
            <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Mgo Idle'">Mgo Idle</div>
            <div class="w-7/12 bg-white relative" v-tooltip="vessel.mgo_idle ? `${vessel.mgo_idle}` : null">
              <input
                  class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
                  type="number"
                  v-model.number.lazy="vessel.mgo_idle"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
            </div>
          </div>
          <div class="flex h48">
            <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Mgo Wk'">Mgo Wk</div>
            <div class="w-7/12 bg-white relative" v-tooltip="vessel.mgo_wk ? `${vessel.mgo_wk}` : null">
              <input
                  class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
                  type="number"
                  v-model.number.lazy="vessel.mgo_wk"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
            </div>
          </div>
        </div>
      </div>

    </div>
<!--    <div class="colspan-3-5 border-b border-dark-gray p-x-1.5 p-y-1 bg-gray-dark uppercase fz13 fw500">Tonnage</div>-->




<!--    <div class="border-r border-dark-gray">-->
<!--      <div class="flex border-b border-gray-light">-->
<!--        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'DWCC'">DWCC</div>-->
<!--        <div class="w-7/12 bg-white relative" v-tooltip="vessel.dwcc ? `${vessel.dwcc}` : null">-->
<!--          <input-->
<!--            class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"-->
<!--            type="number" -->
<!--            v-model.number.lazy="vessel.dwcc"-->
<!--            @keypress="onlyNumber($event)"-->
<!--            @keyup.enter="$event.target.blur()"-->
<!--          >-->
<!--          <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="flex">-->
<!--        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Draft'">Draft</div>-->
<!--        <div class="w-7/12 bg-white relative" v-tooltip="vessel.draft ? `${vessel.draft}` : null">-->
<!--          <input-->
<!--            class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"-->
<!--            type="number" -->
<!--            v-model.number.lazy="vessel.draft"-->
<!--            @keypress="onlyNumber($event)"-->
<!--            @keyup.enter="$event.target.blur()"-->
<!--          >-->
<!--          <span class="absolute right-2 centered-y fz13 text-gray-text">m</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="">-->
<!--      <div class="flex border-b border-gray-light">-->
<!--        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Grain'">Grain</div>-->
<!--        <div class="w-7/12 bg-white relative" v-tooltip="vessel.grain ? `${vessel.grain}` : null">-->
<!--          <input-->
<!--            class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"-->
<!--            type="number" -->
<!--            v-model.number.lazy="vessel.grain"-->
<!--            @keypress="onlyNumber($event)"-->
<!--            @keyup.enter="$event.target.blur()"-->
<!--          >-->
<!--          <span class="absolute right-2 centered-y fz13 text-gray-text">m³</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="flex">-->
<!--        <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'TPC'">TPC</div>-->
<!--        <div class="w-7/12 bg-white relative rounded-br-lg" v-tooltip="vessel.tpc ? `${vessel.tpc}` : null">-->
<!--          <input-->
<!--            class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-l-none rounded-t-none rounded-br-lg fz13"-->
<!--            type="number" -->
<!--            v-model.number.lazy="vessel.tpc"-->
<!--            @keypress="onlyNumber($event)"-->
<!--            @keyup.enter="$event.target.blur()"-->
<!--          >-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import calcVesselsSpeedInputs from '@/components/calc/calcVesselsSpeedInputs'
import { mapActions } from 'vuex'

export default {
  props: {
    isAllow: Boolean,
    vessel: {
      type: Object,
      default: () => {
        return {
          empty: true
        }
      }
    }
  },
  components: {
    calcVesselsSpeedInputs
  },
  data() {
    return {
    }
  },
  watch: {
  },
  methods: {
    ...mapActions(['onlyNumber'])
  }
}
</script>

<style lang="scss">
.colspan-2-4 {
  grid-column-start: 2;
  grid-column-end: 4;
}
</style>
<style lang="scss" scoped>
.flex {
  height: 24px;
}
.w-5\/12 {
  padding: 4px 6px !important;
  font-weight: 700 !important;
  color: var(--color-new-dark-gray) !important
}
.p-x-1\.5.p-y-1 {
  padding: 4px 6px !important;
}
.p-y-1\.5.p-l-1\.5 {
  padding: 4px 6px !important;
  height: 24px;
}
.uppercase.fz13.fw500 {
  font-weight: 700 !important;
}
/deep/ {
  .uppercase.fz13.fw500 {
    font-weight: 700 !important;
  }
  .flex {
    height: 24px;
  }
  .w-5\/12 {
    padding: 4px 6px !important;
    font-weight: 700 !important;
    color: var(--color-new-dark-gray) !important
  }
  .p-x-1\.5.p-y-1 {
    padding: 4px 6px !important;
  }
  .p-y-1\.5.p-l-1\.5 {
    padding: 4px 6px !important;
    height: 24px;
  }
}
.rounded-lg {
  border-radius: 0 8px 8px 0 ;
}
.h48 {
  background-color: var(--color-bgc-page-white);
  height: 48px;
  border-bottom: none !important;
  //overflow: hidden;
  input {
    border-radius: 0 !important;
  }
  .w-7\/12 {
    height: 24px;
  }
  .w-5\/12 {
    height: 40px;
    background-color: var(--color-bgc-page) !important
  }
}
.blue-button {
  bottom: -1px;
  border-radius: 0 0 5px 0 !important;
}
</style>