<template>
  <div class="wrap-page" v-if="parsedVesselData">
    <modal-slots
        :showMod="showDeleteModal"
        :isBorder="false"
        textForFirstButton="Cancel"
        textForSecondButton="Delete"
        @close="closeModalDelete"
        @apply="deleteHatchByIndex(indexDeleteHatch)"
    >
      <template v-slot:title>
        <h2 class="fz16 fw500">Delete Hatch Size</h2>
      </template>
      <p class="p-x-6 p-t-2 p-b-8 fz14">Are you sure you want to delete this No. of the Hatch Size?</p>
    </modal-slots>
    <div class="top-panel">
      <span class="bread__link text-dark" v-if="$route.path.indexOf('create') == -1">{{parsedVesselData.mv}}{{parsedVesselData.dwt ? ' / '+ parsedVesselData.dwt : ''}}{{parsedVesselData.built_year ? ' / ' + parsedVesselData.built_year : ''}}</span>
      <span class="bread__link text-dark" v-else>Create Vessel Template</span>

      <router-link :to="{name: 'Create Parsed Vessels'}" class="button-blue-border h28 p0-10 fz13 fw500 ml-auto" v-if="$route.path.indexOf('create') == -1">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.7221 11.2222H13.2778V5.77773C13.2778 5.34849 12.9293 5 12.4999 5C12.0707 5 11.7222 5.34849 11.7222 5.77773V11.2222H6.27773C5.84849 11.2222 5.5 11.5707 5.5 11.9999C5.5 12.4293 5.84849 12.7778 6.27773 12.7778H11.7222V18.2221C11.7222 18.6515 12.0707 19 12.4999 19C12.9293 19 13.2778 18.6515 13.2778 18.2221V12.7778H18.7221C19.1515 12.7778 19.5 12.4293 19.5 11.9999C19.5 11.5707 19.1515 11.2222 18.7221 11.2222Z" fill="#094172"/>
        </svg>
        Add New Vessels
      </router-link>
      <button class="blue-button buttonW100 h28" :class="{'ml-8': $route.path.indexOf('create') == -1}" v-if="!editShipTemplateBool" v-on:click="turnOnEditMode">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#094172"/>
        </svg>
        Edit
      </button>
    </div>
    <div class="bottom-panel" v-if="editShipTemplateBool">
      <button class="blue-button-text fz14" v-if="$route.path.indexOf('create') == -1" v-on:click="cancelOnEditMode">Cancel</button>
      <button class="blue-button-text fz14" v-else v-on:click="$router.go(-1)">Cancel</button>
      <button class="blue-button fz14 fw500" v-if="$route.path.indexOf('create') == -1" v-on:click="saveChanges ">Save</button>
      <button class="blue-button fz14 fw500" v-else v-on:click="createNewShipTemplate">Create</button>
    </div>

    <div class="main-block">
      <div class="description-vessel" ref="shipTemplateHTML" :class="{'pb44': editShipTemplateBool}">
        <div :key="keyRender" @focusout="clickSaveEditShipTemplate" :contenteditable="editShipTemplateBool" v-html="getHtmlForShip(parsedVesselData.full_description)"></div>
      </div>
      <div :key="keyRender" class="particulars" :class="{'pb44': editShipTemplateBool}">
        <div class="d-flex justify-between mb-12 align-end">
          <p class="fz16 fw500 text-dark ">Particulars</p>
          <p class="fz14 fw500 text-gray">* - fields are used in the Calculator</p>
        </div>
        <div class="particulars__wrap-block border-dark-gray">
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray">MV*</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.mv = $event.target.innerText; $event.target.innerText = parsedVesselData.mv">
                  {{parsedVesselData.mv}}
                </p>
              </div>
            </div>
            <div class="weight-100-3 particulars__title fz14 fw500 text-dark border-dark-gray-bottom border-dark-gray-left">TONNAGE</div>
            <div class="weight-100-3-2 particulars__title fz14 fw500 text-dark border-dark-gray-bottom border-dark-gray-left">Summer salt*</div>
            <div class="weight-100-3-2 particulars__title fz14 fw500 text-dark border-dark-gray-bottom border-dark-gray-left">Tropical fresh</div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray">CallSign</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.call_sign = $event.target.innerText; $event.target.innerText = parsedVesselData.call_sign">
                  {{parsedVesselData.call_sign}}
                </p>
              </div>
            </div>
            <div class="weight-100-3 d-flex">
              <div class="weight-100-2 d-flex border-dark-gray-left">
                <div class="particulars__stroke-title fz500 fz14 text-gray">DWT*</div>
                <div class="particulars__stroke-value">
<!--                  {{parsedVesselData.dwt}}-->
<!--                  <input type="text" class="fz14 text-dark units-block">-->
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.dwt" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.dwt = checkNum(parsedVesselData.dwt)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.dwt = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.dwt)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">MT</span>
                </div>
              </div>
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-left">Grain*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.grain" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.grain = checkNum(parsedVesselData.grain)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.grain = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.grain)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">m³</span>
                </div>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">DWT*</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.dwt" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.dwt = checkNum(parsedVesselData.dwt)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.dwt = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.dwt)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">MT</span>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">DWT*</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tropical_fresh_dwt" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_fresh_dwt = checkNum(parsedVesselData.tropical_fresh_dwt)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_fresh_dwt = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tropical_fresh_dwt)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">MT</span>
              </div>
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex align-items-stretch">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Ex-Name(s)</div>
              <div class="particulars__stroke-value d-flex align-items-stretch ">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark w100Proc" @blur="checkMaxLengthValue" @focusout="parsedVesselData.ex_name = $event.target.innerText; $event.target.innerText = parsedVesselData.ex_name">
                  {{parsedVesselData.ex_name}}
                </p>
              </div>
            </div>
            <div class="weight-100-3 d-flex">
              <div class="weight-100-2 d-flex border-dark-gray-left">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Draft*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.draft" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.draft = checkNum(parsedVesselData.draft)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.draft = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.draft)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">m</span>
                </div>
              </div>
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-left">Bale</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.bale" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.bale = checkNum(parsedVesselData.bale)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.bale = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.bale)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">m³</span>
                </div>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">SSW*</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.draft" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.draft = checkNum(parsedVesselData.draft)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.draft = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.draft)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">m</span>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">SSW*</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tropical_fresh_ssw" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_fresh_ssw = checkNum(parsedVesselData.tropical_fresh_ssw)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_fresh_ssw = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tropical_fresh_ssw)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">m</span>
              </div>
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Type</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.type = $event.target.innerText; $event.target.innerText = parsedVesselData.type">
                  {{parsedVesselData.type}}
                </p>
              </div>
            </div>
            <div class="weight-100-3 d-flex">
              <div class="weight-100-2 d-flex border-dark-gray-left">
                <div class="particulars__stroke-title fz500 fz14 text-gray">TPC*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tpc" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tpc = checkNum(parsedVesselData.tpc)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tpc = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tpc)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">MT</span>

                </div>
              </div>
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-left">LOA</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.loa" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.loa = checkNum(parsedVesselData.loa)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.loa = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.loa)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">m</span>
                </div>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">TPC*</div>
              <div class="particulars__stroke-value">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tpc" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tpc = checkNum(parsedVesselData.tpc)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tpc = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tpc)}}-->
<!--                </p>-->
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">TPC</div>
              <div class="particulars__stroke-value">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tropical_fresh_tpc" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_fresh_tpc = checkNum(parsedVesselData.tropical_fresh_tpc)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_fresh_tpc = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tropical_fresh_tpc)}}-->
<!--                </p>-->
              </div>
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Sub type</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.sub_type = $event.target.innerText; $event.target.innerText = parsedVesselData.sub_type">
                  {{parsedVesselData.sub_type}}
                </p>
              </div>
            </div>
            <div class="weight-100-3 d-flex">
              <div class="weight-100-2 d-flex border-dark-gray-left">
                <div class="particulars__stroke-title fz500 fz14 text-gray">TPI</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tpi" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tpi = checkNum(parsedVesselData.tpi)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tpi = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tpi)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">LT</span>
                </div>
              </div>
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-left">Beam</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.beam" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.beam = checkNum(parsedVesselData.beam)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.beam = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.beam)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">m</span>
                </div>
              </div>
            </div>
            <div class="weight-100-3-2 particulars__title fz14 fw500 text-dark border-dark-gray-top border-dark-gray-bottom border-dark-gray-left">Tropical salt</div>
            <div class="weight-100-3-2 particulars__title fz14 fw500 text-dark border-dark-gray-top border-dark-gray-bottom border-dark-gray-left">Winter salt</div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Gears</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.gears = $event.target.innerText; $event.target.innerText = parsedVesselData.gears">
                  {{parsedVesselData.gears}}
                </p>
              </div>
            </div>
            <div class="weight-100-3 d-flex">
              <div class="weight-100-2 d-flex border-dark-gray-left">
                <div class="particulars__stroke-title fz500 fz14 text-gray">LBP</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.lbp" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.lbp = checkNum(parsedVesselData.lbp)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.lbp = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.lbp)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">m</span>
                </div>
              </div>
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-left"></div>
                <div class="particulars__stroke-value">
                  <span class="fz14 text-dark"></span>
                </div>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">DWT</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tropical_dwt" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_dwt = checkNum(parsedVesselData.tropical_dwt)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_dwt = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tropical_dwt)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">MT</span>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">DWT</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.winter_dwt" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.winter_dwt = checkNum(parsedVesselData.winter_dwt)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.winter_dwt = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.winter_dwt)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">MT</span>
              </div>
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Built year*</div>
              <div class="particulars__stroke-value">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.built_year" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.built_year = checkNum(parsedVesselData.built_year)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.built_year = $event.target.innerText = checkNumIn($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.built_year)}}-->
<!--                </p>-->
              </div>
            </div>
            <div class="weight-100-3 particulars__title fz14 fw500 text-dark border-dark-gray-top border-dark-gray-bottom border-dark-gray-left">Int'l tonnage</div>
            <div class="weight-100-3-2 d-flex ">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">SSW</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tropical_ssw" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_ssw = checkNum(parsedVesselData.tropical_ssw)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_ssw = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tropical_ssw)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">m</span>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">SSW</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.winter_ssw" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.winter_ssw = checkNum(parsedVesselData.winter_ssw)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.winter_ssw = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.winter_ssw)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">m</span>
              </div>
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Built by</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.built_by = $event.target.innerText; $event.target.innerText = parsedVesselData.built_by">
                  {{parsedVesselData.built_by}}
                </p>
              </div>
            </div>
            <div class="weight-100-3 d-flex">
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">GT</div>
                <div class="particulars__stroke-value">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.gt" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.gt = checkNum(parsedVesselData.gt)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.gt = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.gt)}}-->
<!--                </p>-->
              </div>
              </div>
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-left">NT</div>
                <div class="particulars__stroke-value">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.nt" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.nt = checkNum(parsedVesselData.nt)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.nt = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.nt)}}-->
<!--                </p>-->
              </div>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">TPC</div>
              <div class="particulars__stroke-value">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.tropical_tpc" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_tpc = checkNum(parsedVesselData.tropical_tpc)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.tropical_tpc = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.tropical_tpc)}}-->
<!--                </p>-->
              </div>
            </div>
            <div class="weight-100-3-2 d-flex ">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">TPC</div>
              <div class="particulars__stroke-value">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.winter_tpc" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.winter_tpc = checkNum(parsedVesselData.winter_tpc)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.winter_tpc = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.winter_tpc)}}-->
<!--                </p>-->
              </div>
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Flag</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.flag = $event.target.innerText; $event.target.innerText = parsedVesselData.flag">
                  {{parsedVesselData.flag}}
                </p>
              </div>
            </div>
            <div class="weight-2-3 particulars__title fz14 fw500 text-dark border-dark-gray-left border-dark-gray-top border-dark-gray-bottom position-relative">
              Scrubber*
              <button class="button-checkbox" v-on:click="parsedVesselData.has_scrubber = !parsedVesselData.has_scrubber" :disabled="!editShipTemplateBool">
                <svg v-if="!parsedVesselData.has_scrubber" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
                </svg>
                <svg v-if="parsedVesselData.has_scrubber" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="18" height="18" rx="4" fill="#094172"/>
                  <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex align-items-stretch">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Class</div>
              <div class="particulars__stroke-value d-flex align-items-stretch">

                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark w100Proc" @blur="checkMaxLengthValue" @focusout="parsedVesselData.ship_class = $event.target.innerText; $event.target.innerText = parsedVesselData.ship_class">
                  {{parsedVesselData.ship_class}}
                </p>
              </div>
            </div>
            <div class="weight-100-3 d-flex">
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Depth moul.</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.depth_moul" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.depth_moul = checkNum(parsedVesselData.depth_moul)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.depth_moul = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.depth_moul)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">m</span>
                </div>
              </div>
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-left">Light weight</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.light_weight" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.light_weight = checkNum(parsedVesselData.light_weight)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.light_weight = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.light_weight)}}-->
<!--                </p>-->
                  <span class="units fz14 text-gray">MT</span>

                </div>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex ">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">IFO capacity</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.ifo_capacity" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.ifo_capacity = checkNum(parsedVesselData.ifo_capacity)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.ifo_capacity = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.ifo_capacity)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">MT</span>

              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">FW capacity</div>
              <div class="particulars__stroke-value ">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.fw_capacity" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.fw_capacity = checkNum(parsedVesselData.fw_capacity)">
<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.fw_capacity = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.fw_capacity)}}-->
<!--                </p>-->
                <span class="units fz14 text-gray">m³</span>
              </div>
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Shipowner</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.shipowner = $event.target.innerText; $event.target.innerText = parsedVesselData.shipowner">
                  {{parsedVesselData.shipowner}}
                </p>
              </div>
            </div>
            <div class="weight-2-3 particulars__title fz14 fw500 text-dark border-dark-gray-left border-dark-gray-top border-dark-gray-bottom">
              Tank top strength (MT/<span class="fz14 fw500 text-dark">m²</span>)
            </div>
          </div>
          <div class="particulars__stroke border-bottom">
            <div class="weight-100-3 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray">IMO</div>
              <div class="particulars__stroke-value">
                <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.imo" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.imo = checkNum(parsedVesselData.imo)">

<!--                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.imo = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                  {{checkNum(parsedVesselData.imo)}}-->
<!--                </p>-->
              </div>
            </div>
            <div class="weight-100-3 d-flex">
              <div class="weight-100-2 d-flex border-dark-gray-left">
                <div class="particulars__stroke-title fz500 fz14 text-gray">#1</div>
                <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.one_strength = $event.target.innerText; $event.target.innerText = parsedVesselData.one_strength">
                  {{parsedVesselData.one_strength}}
                </p>
              </div>
              </div>
              <div class="weight-100-2 d-flex">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-left">#2</div>
                <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.two_strength = $event.target.innerText; $event.target.innerText = parsedVesselData.two_strength">
                  {{parsedVesselData.two_strength}}
                </p>
              </div>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">#3</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.three_strength = $event.target.innerText; $event.target.innerText = parsedVesselData.three_strength">
                  {{parsedVesselData.three_strength}}
                </p>
              </div>
            </div>
            <div class="weight-100-3-2 d-flex ">
              <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">#4</div>
              <div class="particulars__stroke-value">
                <p :contenteditable="editShipTemplateBool" class="fz14 text-dark" @blur="checkMaxLengthValue" @focusout="parsedVesselData.four_strength = $event.target.innerText; $event.target.innerText = parsedVesselData.four_strength">
                  {{parsedVesselData.four_strength}}
                </p>
              </div>
            </div>
          </div>
        </div>



        <div class="wrap-too-table">
          <p class="fz16 fw500 text-dark mb-12">Holds & Hatches</p>
          <p class="fz16 fw500 text-dark mb-12">Speed & Consumption</p>
          <div class="particulars__wrap-block border-dark-gray position-relative">
            <app-loader v-if="showLoadHatches" class="loader-local"></app-loader>
            <div class="particulars__stroke">
              <div class="weight-100-3 particulars__title fz14 fw500 text-dark border-dark-gray-bottom">Hatch size <span class="fz14 fw500 text-dark ">(m)</span></div>
              <div class="weight-2-3 particulars__title fz14 fw500 text-dark border-dark-gray-bottom border-dark-gray-left">Hold capacity</div>
            </div>
            <div class="particulars__stroke border-bottom" v-for="(item, i) in hatches" :key="i + '' + keyRenderArray">
              <div class="weight-100-3 d-flex">
                <div class="w70 particulars__stroke-title fz500 fz14 text-gray border-left position-relative">
                  No.{{i+1}}
                  <button class="delete-hatch" v-on:click="openDeleteModal(i)" v-if="editShipTemplateBool">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
                    </svg>
                  </button>
                </div>
                <div class="w100-70-2 particulars__stroke-value">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="item.no_x" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="item.no_x = checkNum(item.no_x)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="hatches[i].no_x = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(item.no_x)}}-->
<!--                  </p>-->
                </div>
                <div class="w100-70-2 particulars__stroke-value border-left">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="item.no_y" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="item.no_y = checkNum(item.no_y)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="hatches[i].no_y = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(item.no_y)}}-->
<!--                  </p>-->
                </div>
              </div>
              <div class="weight-2-3 d-flex">
                <div class="weight-100-2 d-flex">
                  <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Grain #{{i+1}}</div>
                  <div class="particulars__stroke-value ">
                    <input type="text" :disabled="!editShipTemplateBool" v-model="item.grain" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="item.grain = checkNum(item.grain)">
<!--                    <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="hatches[i].grain = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                      {{checkNum(item.grain)}}-->
<!--                    </p>-->
                    <span class="units fz14 text-gray">m³</span>
                  </div>
                </div>
                <div class="weight-100-2 d-flex">
                  <div class="particulars__stroke-title fz500 fz14 text-gray border-left">Bale #{{i+1}}</div>
                  <div class="particulars__stroke-value ">
                    <input type="text" :disabled="!editShipTemplateBool" v-model="item.bale" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="item.bale = checkNum(item.bale)">
<!--                    <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="hatches[i].bale = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                      {{checkNum(item.bale)}}-->
<!--                    </p>-->
                    <span class="units fz14 text-gray">m³</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="particulars__stroke border-dark-gray-top d-flex justify-end box-border p-2 h28">
              <button class="blue-button-text fz13 fw500" v-on:click="addOneHatch" v-if="editShipTemplateBool">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z" fill="#094172"/>
                </svg>
                Add one more
              </button>
            </div>
          </div>
          <div class="particulars__wrap-block border-dark-gray">
            <div class="particulars__stroke d-flex ">
              <div class="d-flex weight-100-3 particulars__title fz14 fw500 text-dark border-dark-gray-bottom">Full Speed</div>
              <div class="d-flex weight-100-3 particulars__title fz14 fw500 text-dark border-dark-gray-bottom border-dark-gray-left">Eco Speed</div>
              <div class="d-flex weight-100-3 particulars__title fz14 fw500 text-dark border-dark-gray-bottom border-dark-gray-left">Slow Speed</div>
            </div>
            <div class="particulars__stroke border-bottom d-flex">
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Sp Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.full_sp_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_sp_ball = checkNum(parsedVesselData.full_sp_ball)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_sp_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.full_sp_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">kt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Sp Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.eco_sp_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_sp_ball = checkNum(parsedVesselData.eco_sp_ball)">

<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_sp_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.eco_sp_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">kt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Sp Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.slow_sp_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_sp_ball = checkNum(parsedVesselData.slow_sp_ball)">

<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_sp_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.slow_sp_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">kt</span>
                </div>
              </div>
            </div>
            <div class="particulars__stroke border-bottom d-flex">
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Ifo Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.full_ifo_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_ifo_ball = checkNum(parsedVesselData.full_ifo_ball)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_ifo_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.full_ifo_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Ifo Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.eco_ifo_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_ifo_ball = checkNum(parsedVesselData.eco_ifo_ball)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_ifo_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.eco_ifo_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Ifo Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.slow_ifo_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_ifo_ball = checkNum(parsedVesselData.slow_ifo_ball)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_ifo_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.slow_ifo_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
            </div>
            <div class="particulars__stroke border-bottom d-flex">
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Mgo Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.full_mgo_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_mgo_ball = checkNum(parsedVesselData.full_mgo_ball)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_mgo_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.full_mgo_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Mgo Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.eco_mgo_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_mgo_ball = checkNum(parsedVesselData.eco_mgo_ball)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_mgo_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.eco_mgo_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Mgo Ball*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.slow_mgo_ball" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_mgo_ball = checkNum(parsedVesselData.slow_mgo_ball)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_mgo_ball = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.slow_mgo_ball)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
            </div>
            <div class="particulars__stroke border-bottom d-flex">
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Sp Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.full_sp_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_sp_lad = checkNum(parsedVesselData.full_sp_lad)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_sp_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.full_sp_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">kt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Sp Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.eco_sp_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_sp_lad = checkNum(parsedVesselData.eco_sp_lad)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_sp_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.eco_sp_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">kt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Sp Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.slow_sp_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_sp_lad = checkNum(parsedVesselData.slow_sp_lad)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_sp_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.slow_sp_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">kt</span>
                </div>
              </div>
            </div>
            <div class="particulars__stroke border-bottom d-flex">
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Ifo Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.full_ifo_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_ifo_lad = checkNum(parsedVesselData.full_ifo_lad)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_ifo_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.full_ifo_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Ifo Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.eco_ifo_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_ifo_lad = checkNum(parsedVesselData.eco_ifo_lad)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_ifo_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.eco_ifo_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Ifo Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.slow_ifo_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_ifo_lad = checkNum(parsedVesselData.slow_ifo_lad)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_ifo_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.slow_ifo_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
            </div>
            <div class="particulars__stroke d-flex">
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Mgo Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.full_mgo_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_mgo_lad = checkNum(parsedVesselData.full_mgo_lad)">

<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.full_mgo_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.full_mgo_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Mgo Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.eco_mgo_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_mgo_lad = checkNum(parsedVesselData.eco_mgo_lad)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.eco_mgo_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.eco_mgo_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight-100-3">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Mgo Lad*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.slow_mgo_lad" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_mgo_lad = checkNum(parsedVesselData.slow_mgo_lad)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.slow_mgo_lad = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.slow_mgo_lad)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
            </div>

            <div class="particulars__stroke d-flex ">
              <div class="d-flex w100 particulars__title fz14 fw500 text-dark border-dark-gray-bottom border-dark-gray-top">Port consumptionм</div>
            </div>
            <div class="particulars__stroke border-bottom d-flex">
              <div class="d-flex weight50Proc">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Ifo Idle*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.ifo_idle" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.ifo_idle = checkNum(parsedVesselData.ifo_idle)">

<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.ifo_idle = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.ifo_idle)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight50Proc">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Mgo Idle*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.mgo_idle" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.mgo_idle = checkNum(parsedVesselData.mgo_idle)">

                  <!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.mgo_idle = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.mgo_idle)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
<!--              <div class="d-flex weight-100-3">-->
<!--                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left"></div>-->
<!--                <div class="particulars__stroke-value">-->
<!--                  <span contenteditable="false" class="fz14 text-dark"></span>-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <div class="particulars__stroke d-flex">
              <div class="d-flex weight50Proc">
                <div class="particulars__stroke-title fz500 fz14 text-gray">Ifo Wk*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.ifo_wk" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.ifo_wk = checkNum(parsedVesselData.ifo_wk)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.ifo_wk = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.ifo_wk)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
              <div class="d-flex weight50Proc">
                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left">Mgo Wk*</div>
                <div class="particulars__stroke-value ">
                  <input type="text" :disabled="!editShipTemplateBool" v-model="parsedVesselData.mgo_wk" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.mgo_wk = checkNum(parsedVesselData.mgo_wk)">
<!--                  <p :contenteditable="editShipTemplateBool" class="fz14 text-dark units-block value-num" @keydown="checkingKeyDown" @blur="checkMaxLengthValue" @focusout="parsedVesselData.mgo_wk = $event.target.innerText = checkNum($event.target.innerText)">-->
<!--                    {{checkNum(parsedVesselData.mgo_wk)}}-->
<!--                  </p>-->
                  <span class="units fz14 text-gray">mt</span>
                </div>
              </div>
<!--              <div class="d-flex weight-100-3">-->
<!--                <div class="particulars__stroke-title fz500 fz14 text-gray border-dark-gray-left"></div>-->
<!--                <div class="particulars__stroke-value">-->
<!--                  <span contenteditable="false" class="fz14 text-dark"></span>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import AppLoader from "@/components/loader";
import ModalSlots from "@/components/modal/modal-slots";

export default {
  name: "shipTemplatePage",
  components: {ModalSlots, AppLoader},
  computed: {
    ...mapState({
      parsedVesselData: state => state.parsedVessel.parsedVesselData,
    })
  },
  data() {
    return {
      backUpData: null,
      backUpDataHatches: [],
      editShipTemplateBool: false,
      hatches: [],
      showLoadHatches: false,
      indexDeleteHatch: null,
      showDeleteModal: false,
      keyRender: -99999,
      originalHatches: [],
      keyRenderArray: -9234,
    }
  },
  watch: {
    editShipTemplateBool: function () {
      this.updateTextForNum()
    },
    'parsedVesselData.mv': function () {
      this.parsedVesselData.name = this.parsedVesselData.mv
    },
    // $route: async function (to) {
    //   this.hatches = []
    //   this.editShipTemplateBool = false
    //   this.hideLoad()
    //   console.log('to', to)
    //     if(to.path.indexOf('create') == -1) {
    //       if(to.params.idParsedVessel != this.parsedVesselData?.id) {
    //         await this.axiosGetParsedVesselById(to.params.idParsedVessel).then(()=> {this.getHatchByIds()})
    //       } else {
    //         this.getHatchByIds();
    //       }
    //       this.updateTextForNum();
    //     } else {
    //       this.setParsedVesselData({
    //         "id": null,
    //         "region": "",
    //         "name": "",
    //         "year": "",
    //         "position": "",
    //         "full_description": "",
    //         "mv": "",
    //         "call_sign": "",
    //         "ex_name": "",
    //         "type": "",
    //         "sub_type": "",
    //         "gears": "",
    //         "built_year": "",
    //         "built_by": "",
    //         "flag": "",
    //         "ship_class": "",
    //         "shipowner": "",
    //         "imo": "",
    //         "dwt": null,
    //         "draft": null,
    //         "tpc": null,
    //         "tpi": null,
    //         "lbp": null,
    //         "grain": null,
    //         "bale": null,
    //         "loa": null,
    //         "beam": null,
    //         "gt": null,
    //         "nt": null,
    //         "has_scrubber": false,
    //         "depth_moul": null,
    //         "light_weight": null,
    //         "ifo_capacity": null,
    //         "fw_capacity": null,
    //         "one_strength": null,
    //         "two_strength": null,
    //         "three_strength": null,
    //         "four_strength": null,
    //         "tropical_fresh_dwt": null,
    //         "tropical_fresh_ssw": null,
    //         "tropical_fresh_tpc": null,
    //         "tropical_dwt": null,
    //         "tropical_ssw": null,
    //         "tropical_tpc": null,
    //         "winter_dwt": null,
    //         "winter_ssw": null,
    //         "winter_tpc": null,
    //         "full_sp_ball": null,
    //         "full_ifo_ball": null,
    //         "full_mgo_ball": null,
    //         "full_sp_lad": null,
    //         "full_ifo_lad": null,
    //         "full_mgo_lad": null,
    //         "eco_sp_ball": null,
    //         "eco_ifo_ball": null,
    //         "eco_mgo_ball": null,
    //         "eco_sp_lad": null,
    //         "eco_ifo_lad": null,
    //         "eco_mgo_lad": null,
    //         "slow_sp_ball": null,
    //         "slow_ifo_ball": null,
    //         "slow_mgo_ball": null,
    //         "slow_sp_lad": null,
    //         "slow_ifo_lad": null,
    //         "slow_mgo_lad": null,
    //         "ifo_idle": null,
    //         "ifo_wk": null,
    //         "mgo_idle": null,
    //         "mgo_wk": null,
    //         "updated_at": "",
    //         "created_at": "",
    //         "hatch_size": [
    //         ]
    //       })
    //       this.editShipTemplateBool = true
    //       this.hatches = []
    //   }
    //
    // }
    $route: async function (to) {
      this.hatches = []
      this.editShipTemplateBool = false
      if(to.path.indexOf('create') == -1) {
        if(to.params.idParsedVessel != this.parsedVesselData?.id) {
          await this.axiosGetParsedVesselById(to.params.idParsedVessel).then(()=> {this.getHatchByIds()})
        } else {
          this.getHatchByIds();
        }
        this.updateTextForNum();
      } else {
        this.setParsedVesselData({
          "id": null,
          "region": "",
          "name": "",
          "year": "",
          "position": "",
          "full_description": "",
          "mv": "",
          "call_sign": "",
          "ex_name": "",
          "type": "",
          "sub_type": "",
          "gears": "",
          "built_year": "",
          "built_by": "",
          "flag": "",
          "ship_class": "",
          "shipowner": "",
          "imo": "",
          "dwt": null,
          "draft": null,
          "tpc": null,
          "tpi": null,
          "lbp": null,
          "grain": null,
          "bale": null,
          "loa": null,
          "beam": null,
          "gt": null,
          "nt": null,
          "has_scrubber": false,
          "depth_moul": null,
          "light_weight": null,
          "ifo_capacity": null,
          "fw_capacity": null,
          "one_strength": null,
          "two_strength": null,
          "three_strength": null,
          "four_strength": null,
          "tropical_fresh_dwt": null,
          "tropical_fresh_ssw": null,
          "tropical_fresh_tpc": null,
          "tropical_dwt": null,
          "tropical_ssw": null,
          "tropical_tpc": null,
          "winter_dwt": null,
          "winter_ssw": null,
          "winter_tpc": null,
          "full_sp_ball": null,
          "full_ifo_ball": null,
          "full_mgo_ball": null,
          "full_sp_lad": null,
          "full_ifo_lad": null,
          "full_mgo_lad": null,
          "eco_sp_ball": null,
          "eco_ifo_ball": null,
          "eco_mgo_ball": null,
          "eco_sp_lad": null,
          "eco_ifo_lad": null,
          "eco_mgo_lad": null,
          "slow_sp_ball": null,
          "slow_ifo_ball": null,
          "slow_mgo_ball": null,
          "slow_sp_lad": null,
          "slow_ifo_lad": null,
          "slow_mgo_lad": null,
          "ifo_idle": null,
          "ifo_wk": null,
          "mgo_idle": null,
          "mgo_wk": null,
          "updated_at": "",
          "created_at": "",
          "hatch_size": [
          ]
        })
        this.editShipTemplateBool = true
        this.hatches = []
      }


    },

  },
  methods: {
    ...mapActions(['axiosCreateSearchShipTemplate', 'axiosGetParsedVesselById', 'axiosChangeSearchShipTemplate', 'axiosGetHatchParsedVesselById', 'axiosDeleteHatch', 'axiosChangeHatch', 'axiosCreateHatch']),
    ...mapMutations(['addTip', 'setParsedVesselData', 'showLoad', 'hideLoad']),
    updateTextForNum() {
      this.parsedVesselData.dwt = this.checkNum(this.parsedVesselData.dwt)
      this.parsedVesselData.draft = this.checkNum(this.parsedVesselData.draft)
      this.parsedVesselData.tpc = this.checkNum(this.parsedVesselData.tpc)
      this.parsedVesselData.tpi = this.checkNum(this.parsedVesselData.tpi)
      this.parsedVesselData.lbp = this.checkNum(this.parsedVesselData.lbp)
      this.parsedVesselData.grain = this.checkNum(this.parsedVesselData.grain)
      this.parsedVesselData.bale = this.checkNum(this.parsedVesselData.bale)
      this.parsedVesselData.loa = this.checkNum(this.parsedVesselData.loa)
      this.parsedVesselData.beam = this.checkNum(this.parsedVesselData.beam)
      this.parsedVesselData.gt = this.checkNum(this.parsedVesselData.gt)
      this.parsedVesselData.nt = this.checkNum(this.parsedVesselData.nt)
      this.parsedVesselData.depth_moul = this.checkNum(this.parsedVesselData.depth_moul)
      this.parsedVesselData.light_weight = this.checkNum(this.parsedVesselData.light_weight)
      this.parsedVesselData.ifo_capacity = this.checkNum(this.parsedVesselData.ifo_capacity)
      this.parsedVesselData.fw_capacity = this.checkNum(this.parsedVesselData.fw_capacity)
      this.parsedVesselData.tropical_fresh_dwt = this.checkNum(this.parsedVesselData.tropical_fresh_dwt)
      this.parsedVesselData.tropical_fresh_ssw = this.checkNum(this.parsedVesselData.tropical_fresh_ssw)
      this.parsedVesselData.tropical_fresh_tpc = this.checkNum(this.parsedVesselData.tropical_fresh_tpc)
      this.parsedVesselData.tropical_dwt = this.checkNum(this.parsedVesselData.tropical_dwt)
      this.parsedVesselData.tropical_ssw = this.checkNum(this.parsedVesselData.tropical_ssw)
      this.parsedVesselData.tropical_tpc = this.checkNum(this.parsedVesselData.tropical_tpc)
      this.parsedVesselData.winter_dwt = this.checkNum(this.parsedVesselData.winter_dwt)
      this.parsedVesselData.winter_ssw = this.checkNum(this.parsedVesselData.winter_ssw)
      this.parsedVesselData.winter_tpc = this.checkNum(this.parsedVesselData.winter_tpc)
      this.parsedVesselData.full_sp_ball = this.checkNum(this.parsedVesselData.full_sp_ball)
      this.parsedVesselData.full_ifo_ball = this.checkNum(this.parsedVesselData.full_ifo_ball)
      this.parsedVesselData.full_mgo_ball = this.checkNum(this.parsedVesselData.full_mgo_ball)
      this.parsedVesselData.full_sp_lad = this.checkNum(this.parsedVesselData.full_sp_lad)
      this.parsedVesselData.full_ifo_lad = this.checkNum(this.parsedVesselData.full_ifo_lad)
      this.parsedVesselData.full_mgo_lad = this.checkNum(this.parsedVesselData.full_mgo_lad)
      this.parsedVesselData.eco_sp_ball = this.checkNum(this.parsedVesselData.eco_sp_ball)
      this.parsedVesselData.eco_ifo_ball = this.checkNum(this.parsedVesselData.eco_ifo_ball)
      this.parsedVesselData.eco_mgo_ball = this.checkNum(this.parsedVesselData.eco_mgo_ball)
      this.parsedVesselData.eco_sp_lad = this.checkNum(this.parsedVesselData.eco_sp_lad)
      this.parsedVesselData.eco_ifo_lad = this.checkNum(this.parsedVesselData.eco_ifo_lad)
      this.parsedVesselData.eco_mgo_lad = this.checkNum(this.parsedVesselData.eco_mgo_lad)
      this.parsedVesselData.slow_sp_ball = this.checkNum(this.parsedVesselData.slow_sp_ball)
      this.parsedVesselData.slow_ifo_ball = this.checkNum(this.parsedVesselData.slow_ifo_ball)
      this.parsedVesselData.slow_mgo_ball = this.checkNum(this.parsedVesselData.slow_mgo_ball)
      this.parsedVesselData.slow_sp_lad = this.checkNum(this.parsedVesselData.slow_sp_lad)
      this.parsedVesselData.slow_ifo_lad = this.checkNum(this.parsedVesselData.slow_ifo_lad)
      this.parsedVesselData.slow_mgo_lad = this.checkNum(this.parsedVesselData.slow_mgo_lad)
      this.parsedVesselData.ifo_idle = this.checkNum(this.parsedVesselData.ifo_idle)
      this.parsedVesselData.ifo_wk = this.checkNum(this.parsedVesselData.ifo_wk)
      this.parsedVesselData.mgo_idle = this.checkNum(this.parsedVesselData.mgo_idle)
      this.parsedVesselData.mgo_wk = this.checkNum(this.parsedVesselData.mgo_wk)
      this.hatches = this.hatches.map(a=> {
        a.no_x = this.checkNum(a.no_x)
        a.no_y = this.checkNum(a.no_y)
        a.grain = this.checkNum(a.grain)
        a.bale = this.checkNum(a.bale)
        return a
      })
      // keyRenderArray
      // this.backUpDataHatches = this.backUpDataHatches.map(a=> {
      //   a.no_x = this.checkNum(a.no_x)
      //   a.no_y = this.checkNum(a.no_y)
      //   a.grain = this.checkNum(a.grain)
      //   a.bale = this.checkNum(a.bale)
      //   return a
      // })
    },
    checkMaxLengthValue(event) {
      if(event.target.innerText.length > 100) {
        this.addTip('The limit of the field is 100 symbols')
      }
    },
    getValueFromFormAxios(value) {
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined) {
        let num = (''+value).replaceAll(',', '')
        if(!isNaN(num)) {
          return num
        }
        return ""+value
      }
      return null
    },
    async saveChanges() {
      this.showLoad()
      this.editShipTemplateBool = false;
      // this.keyRender++;

      this.backUpData = null
      this.backUpDataHatches = []
      let IDs = this.hatches.filter(a=> a.id).map(a=> { return a.id});
      let arrayFromDelete = this.parsedVesselData.hatch_size.filter(a=> IDs.indexOf(a) === -1);
      this.parsedVesselData.hatch_size = this.parsedVesselData.hatch_size.filter(a=> IDs.indexOf(a) >= 0)
      for(let i=0; i<arrayFromDelete.length; i++) {
        await this.axiosDeleteHatch(arrayFromDelete[i])
      }
      for(let i=0; i < this.hatches.length; i++) {
        if(this.hatches[i].id) {
          if(this.parsedVesselData.hatch_size.indexOf(this.hatches[i].id)>=0) {
            let item = this.originalHatches.find(a=> a.id === this.hatches[i].id)
            if(item && (item.no_x !== this.hatches[i].no_x || item.no_y !== this.hatches[i].no_y || item.grain !== this.hatches[i].grain || item.bale !== this.hatches[i].bale)) {
              let formHatch = {
                id: this.hatches[i].id,
                no_x: this.getValueFromFormAxios(this.hatches[i].no_x),
                no_y: this.getValueFromFormAxios(this.hatches[i].no_y),
                grain: this.getValueFromFormAxios(this.hatches[i].grain),
                bale: this.getValueFromFormAxios(this.hatches[i].bale)
              }
              await this.axiosChangeHatch(formHatch)
            }
          }
          // else {
          //   await this.axiosDeleteHatch(this.hatches[i].id)
          //   this.parsedVesselData.hatch_size = this.parsedVesselData.hatch_size.filter(a=> a !== this.hatches[i].id)
          // }
        } else {
          let formHatch = {
            no_x: this.getValueFromFormAxios(this.hatches[i].no_x),
            no_y: this.getValueFromFormAxios(this.hatches[i].no_y),
            grain: this.getValueFromFormAxios(this.hatches[i].grain),
            bale: this.getValueFromFormAxios(this.hatches[i].bale)
          }
          let item = await this.axiosCreateHatch(formHatch);
          if(item) {
            this.hatches[i]['id'] = item.id;
            this.parsedVesselData.hatch_size.push(item.id)
          }
        }

      }
      console.log(this.parsedVesselData)
      let form = {
        bale: this.getValueFromFormAxios(this.parsedVesselData.bale),
        beam: this.getValueFromFormAxios(this.parsedVesselData.beam),
        built_by: this.getValueFromFormAxios(this.parsedVesselData.built_by),
        built_year: this.getValueFromFormAxios(this.parsedVesselData.built_year),
        call_sign: this.getValueFromFormAxios(this.parsedVesselData.call_sign),
        created_at: this.getValueFromFormAxios(this.parsedVesselData.created_at),
        depth_moul: this.getValueFromFormAxios(this.parsedVesselData.depth_moul),
        draft: this.getValueFromFormAxios(this.parsedVesselData.draft),
        dwt: this.getValueFromFormAxios(this.parsedVesselData.dwt),
        eco_ifo_ball: this.getValueFromFormAxios(this.parsedVesselData.eco_ifo_ball),
        eco_ifo_lad: this.getValueFromFormAxios(this.parsedVesselData.eco_ifo_lad),
        eco_mgo_ball: this.getValueFromFormAxios(this.parsedVesselData.eco_mgo_ball),
        eco_mgo_lad: this.getValueFromFormAxios(this.parsedVesselData.eco_mgo_lad),
        eco_sp_ball: this.getValueFromFormAxios(this.parsedVesselData.eco_sp_ball),
        eco_sp_lad: this.getValueFromFormAxios(this.parsedVesselData.eco_sp_lad),
        ex_name: this.getValueFromFormAxios(this.parsedVesselData.ex_name),
        flag: this.getValueFromFormAxios(this.parsedVesselData.flag),
        four_strength: this.getValueFromFormAxios(this.parsedVesselData.four_strength),
        full_description: this.getValueFromFormAxios(this.parsedVesselData.full_description),
        full_ifo_ball: this.getValueFromFormAxios(this.parsedVesselData.full_ifo_ball),
        full_ifo_lad: this.getValueFromFormAxios(this.parsedVesselData.full_ifo_lad),
        full_mgo_ball: this.getValueFromFormAxios(this.parsedVesselData.full_mgo_ball),
        full_mgo_lad: this.getValueFromFormAxios(this.parsedVesselData.full_mgo_lad),
        full_sp_ball: this.getValueFromFormAxios(this.parsedVesselData.full_sp_ball),
        full_sp_lad: this.getValueFromFormAxios(this.parsedVesselData.full_sp_lad),
        fw_capacity: this.getValueFromFormAxios(this.parsedVesselData.fw_capacity),
        gears: this.getValueFromFormAxios(this.parsedVesselData.gears),
        grain: this.getValueFromFormAxios(this.parsedVesselData.grain),
        gt: this.getValueFromFormAxios(this.parsedVesselData.gt),
        has_scrubber: this.parsedVesselData.has_scrubber,
        hatch_size: this.parsedVesselData.hatch_size,
        ifo_capacity: this.getValueFromFormAxios(this.parsedVesselData.ifo_capacity),
        ifo_idle: this.getValueFromFormAxios(this.parsedVesselData.ifo_idle),
        ifo_wk: this.getValueFromFormAxios(this.parsedVesselData.ifo_wk),
        imo: this.getValueFromFormAxios(this.parsedVesselData.imo),
        lbp: this.getValueFromFormAxios(this.parsedVesselData.lbp),
        light_weight: this.getValueFromFormAxios(this.parsedVesselData.light_weight),
        loa: this.getValueFromFormAxios(this.parsedVesselData.loa),
        mgo_idle: this.getValueFromFormAxios(this.parsedVesselData.mgo_idle),
        mgo_wk: this.getValueFromFormAxios(this.parsedVesselData.mgo_wk),
        mv: this.getValueFromFormAxios(this.parsedVesselData.mv),
        name: this.getValueFromFormAxios(this.parsedVesselData.name),
        nt: this.getValueFromFormAxios(this.parsedVesselData.nt),
        one_strength: this.getValueFromFormAxios(this.parsedVesselData.one_strength),
        position: this.getValueFromFormAxios(this.parsedVesselData.position),
        region: (this.parsedVesselData.region && (''+this.parsedVesselData.region).length !== 0) ? this.parsedVesselData.region : null,
        ship_class: this.getValueFromFormAxios(this.parsedVesselData.ship_class),
        shipowner: this.getValueFromFormAxios(this.parsedVesselData.shipowner),
        slow_ifo_ball: this.getValueFromFormAxios(this.parsedVesselData.slow_ifo_ball),
        slow_ifo_lad: this.getValueFromFormAxios(this.parsedVesselData.slow_ifo_lad),
        slow_mgo_ball: this.getValueFromFormAxios(this.parsedVesselData.slow_mgo_ball),
        slow_mgo_lad: this.getValueFromFormAxios(this.parsedVesselData.slow_mgo_lad),
        slow_sp_ball: this.getValueFromFormAxios(this.parsedVesselData.slow_sp_ball),
        slow_sp_lad: this.getValueFromFormAxios(this.parsedVesselData.slow_sp_lad),
        sub_type: this.getValueFromFormAxios(this.parsedVesselData.sub_type),
        three_strength: this.getValueFromFormAxios(this.parsedVesselData.three_strength),
        tpc: this.getValueFromFormAxios(this.parsedVesselData.tpc),
        tpi: this.getValueFromFormAxios(this.parsedVesselData.tpi),
        tropical_dwt: this.getValueFromFormAxios(this.parsedVesselData.tropical_dwt),
        tropical_fresh_dwt: this.getValueFromFormAxios(this.parsedVesselData.tropical_fresh_dwt),
        tropical_fresh_ssw: this.getValueFromFormAxios(this.parsedVesselData.tropical_fresh_ssw),
        tropical_fresh_tpc: this.getValueFromFormAxios(this.parsedVesselData.tropical_fresh_tpc),
        tropical_ssw: this.getValueFromFormAxios(this.parsedVesselData.tropical_ssw),
        tropical_tpc: this.getValueFromFormAxios(this.parsedVesselData.tropical_tpc),
        two_strength: this.getValueFromFormAxios(this.parsedVesselData.two_strength),
        type: this.getValueFromFormAxios(this.parsedVesselData.type),
        winter_dwt: this.getValueFromFormAxios(this.parsedVesselData.winter_dwt),
        winter_ssw: this.getValueFromFormAxios(this.parsedVesselData.winter_ssw),
        winter_tpc: this.getValueFromFormAxios(this.parsedVesselData.winter_tpc),
        year: this.getValueFromFormAxios(this.parsedVesselData.year),
      }
      await this.axiosChangeSearchShipTemplate({data: form, id: this.parsedVesselData.id})
      this.originalHatches = JSON.parse(JSON.stringify(this.hatches))
      this.hideLoad()
      // this.keyRender++;
    },
    async createNewShipTemplate() {
      this.showLoad()
      this.editShipTemplateBool = false;
      // this.keyRender++;

      this.backUpData = null
      this.backUpDataHatches = []
      let IDs = this.hatches.filter(a=> a.id).map(a=> { return a.id});
      let arrayFromDelete = this.parsedVesselData.hatch_size.filter(a=> IDs.indexOf(a) === -1);
      this.parsedVesselData.hatch_size = this.parsedVesselData.hatch_size.filter(a=> IDs.indexOf(a) >= 0)
      for(let i=0; i<arrayFromDelete.length; i++) {
        await this.axiosDeleteHatch(arrayFromDelete[i])
      }
      for(let i=0; i < this.hatches.length; i++) {
        if(this.hatches[i].id) {
          if(this.parsedVesselData.hatch_size.indexOf(this.hatches[i].id)>=0) {
            let item = this.originalHatches.find(a=> a.id === this.hatches[i].id)
            if(item && (item.no_x !== this.hatches[i].no_x || item.no_y !== this.hatches[i].no_y || item.grain !== this.hatches[i].grain || item.bale !== this.hatches[i].bale)) {
              let formHatch = {
                id: this.hatches[i].id,
                no_x: this.getValueFromFormAxios(this.hatches[i].no_x),
                no_y: this.getValueFromFormAxios(this.hatches[i].no_y),
                grain: this.getValueFromFormAxios(this.hatches[i].grain),
                bale: this.getValueFromFormAxios(this.hatches[i].bale)
              }
              await this.axiosChangeHatch(formHatch)
            }
          }
          // else {
          //   await this.axiosDeleteHatch(this.hatches[i].id)
          //   this.parsedVesselData.hatch_size = this.parsedVesselData.hatch_size.filter(a=> a !== this.hatches[i].id)
          // }
        } else {
          let formHatch = {
            no_x: this.getValueFromFormAxios(this.hatches[i].no_x),
            no_y: this.getValueFromFormAxios(this.hatches[i].no_y),
            grain: this.getValueFromFormAxios(this.hatches[i].grain),
            bale: this.getValueFromFormAxios(this.hatches[i].bale)
          }
          let item = await this.axiosCreateHatch(formHatch);
          if(item) {
            this.hatches[i]['id'] = item.id;
            this.parsedVesselData.hatch_size.push(item.id)
          }
        }

      }
      console.log(this.parsedVesselData)
      let form = {
        bale: this.getValueFromFormAxios(this.parsedVesselData.bale),
        beam: this.getValueFromFormAxios(this.parsedVesselData.beam),
        built_by: this.getValueFromFormAxios(this.parsedVesselData.built_by),
        built_year: this.getValueFromFormAxios(this.parsedVesselData.built_year),
        call_sign: this.getValueFromFormAxios(this.parsedVesselData.call_sign),
        created_at: this.getValueFromFormAxios(this.parsedVesselData.created_at),
        depth_moul: this.getValueFromFormAxios(this.parsedVesselData.depth_moul),
        draft: this.getValueFromFormAxios(this.parsedVesselData.draft),
        dwt: this.getValueFromFormAxios(this.parsedVesselData.dwt),
        eco_ifo_ball: this.getValueFromFormAxios(this.parsedVesselData.eco_ifo_ball),
        eco_ifo_lad: this.getValueFromFormAxios(this.parsedVesselData.eco_ifo_lad),
        eco_mgo_ball: this.getValueFromFormAxios(this.parsedVesselData.eco_mgo_ball),
        eco_mgo_lad: this.getValueFromFormAxios(this.parsedVesselData.eco_mgo_lad),
        eco_sp_ball: this.getValueFromFormAxios(this.parsedVesselData.eco_sp_ball),
        eco_sp_lad: this.getValueFromFormAxios(this.parsedVesselData.eco_sp_lad),
        ex_name: this.getValueFromFormAxios(this.parsedVesselData.ex_name),
        flag: this.getValueFromFormAxios(this.parsedVesselData.flag),
        four_strength: this.getValueFromFormAxios(this.parsedVesselData.four_strength),
        full_description: this.getValueFromFormAxios(this.parsedVesselData.full_description),
        full_ifo_ball: this.getValueFromFormAxios(this.parsedVesselData.full_ifo_ball),
        full_ifo_lad: this.getValueFromFormAxios(this.parsedVesselData.full_ifo_lad),
        full_mgo_ball: this.getValueFromFormAxios(this.parsedVesselData.full_mgo_ball),
        full_mgo_lad: this.getValueFromFormAxios(this.parsedVesselData.full_mgo_lad),
        full_sp_ball: this.getValueFromFormAxios(this.parsedVesselData.full_sp_ball),
        full_sp_lad: this.getValueFromFormAxios(this.parsedVesselData.full_sp_lad),
        fw_capacity: this.getValueFromFormAxios(this.parsedVesselData.fw_capacity),
        gears: this.getValueFromFormAxios(this.parsedVesselData.gears),
        grain: this.getValueFromFormAxios(this.parsedVesselData.grain),
        gt: this.getValueFromFormAxios(this.parsedVesselData.gt),
        has_scrubber: this.parsedVesselData.has_scrubber,
        hatch_size: this.parsedVesselData.hatch_size,
        ifo_capacity: this.getValueFromFormAxios(this.parsedVesselData.ifo_capacity),
        ifo_idle: this.getValueFromFormAxios(this.parsedVesselData.ifo_idle),
        ifo_wk: this.getValueFromFormAxios(this.parsedVesselData.ifo_wk),
        imo: this.getValueFromFormAxios(this.parsedVesselData.imo),
        lbp: this.getValueFromFormAxios(this.parsedVesselData.lbp),
        light_weight: this.getValueFromFormAxios(this.parsedVesselData.light_weight),
        loa: this.getValueFromFormAxios(this.parsedVesselData.loa),
        mgo_idle: this.getValueFromFormAxios(this.parsedVesselData.mgo_idle),
        mgo_wk: this.getValueFromFormAxios(this.parsedVesselData.mgo_wk),
        mv: this.getValueFromFormAxios(this.parsedVesselData.mv),
        name: this.getValueFromFormAxios(this.parsedVesselData.name),
        nt: this.getValueFromFormAxios(this.parsedVesselData.nt),
        one_strength: this.getValueFromFormAxios(this.parsedVesselData.one_strength),
        position: this.getValueFromFormAxios(this.parsedVesselData.position),
        region: (this.parsedVesselData.region && (''+this.parsedVesselData.region).length !== 0) ? this.parsedVesselData.region : null,
        ship_class: this.getValueFromFormAxios(this.parsedVesselData.ship_class),
        shipowner: this.getValueFromFormAxios(this.parsedVesselData.shipowner),
        slow_ifo_ball: this.getValueFromFormAxios(this.parsedVesselData.slow_ifo_ball),
        slow_ifo_lad: this.getValueFromFormAxios(this.parsedVesselData.slow_ifo_lad),
        slow_mgo_ball: this.getValueFromFormAxios(this.parsedVesselData.slow_mgo_ball),
        slow_mgo_lad: this.getValueFromFormAxios(this.parsedVesselData.slow_mgo_lad),
        slow_sp_ball: this.getValueFromFormAxios(this.parsedVesselData.slow_sp_ball),
        slow_sp_lad: this.getValueFromFormAxios(this.parsedVesselData.slow_sp_lad),
        sub_type: this.getValueFromFormAxios(this.parsedVesselData.sub_type),
        three_strength: this.getValueFromFormAxios(this.parsedVesselData.three_strength),
        tpc: this.getValueFromFormAxios(this.parsedVesselData.tpc),
        tpi: this.getValueFromFormAxios(this.parsedVesselData.tpi),
        tropical_dwt: this.getValueFromFormAxios(this.parsedVesselData.tropical_dwt),
        tropical_fresh_dwt: this.getValueFromFormAxios(this.parsedVesselData.tropical_fresh_dwt),
        tropical_fresh_ssw: this.getValueFromFormAxios(this.parsedVesselData.tropical_fresh_ssw),
        tropical_fresh_tpc: this.getValueFromFormAxios(this.parsedVesselData.tropical_fresh_tpc),
        tropical_ssw: this.getValueFromFormAxios(this.parsedVesselData.tropical_ssw),
        tropical_tpc: this.getValueFromFormAxios(this.parsedVesselData.tropical_tpc),
        two_strength: this.getValueFromFormAxios(this.parsedVesselData.two_strength),
        type: this.getValueFromFormAxios(this.parsedVesselData.type),
        winter_dwt: this.getValueFromFormAxios(this.parsedVesselData.winter_dwt),
        winter_ssw: this.getValueFromFormAxios(this.parsedVesselData.winter_ssw),
        winter_tpc: this.getValueFromFormAxios(this.parsedVesselData.winter_tpc),
        year: this.getValueFromFormAxios(this.parsedVesselData.year),
      }
      let res = await this.axiosCreateSearchShipTemplate({data: form,})
      this.originalHatches = JSON.parse(JSON.stringify(this.hatches))
      console.log('res', res, res.id)
      this.hideLoad()
      this.$router.push({name: 'Parsed Vessels', params: {idParsedVessel: res.id}})
      // this.keyRender++;
    },
    closeModalDelete() {
      this.indexDeleteHatch = null;
      this.showDeleteModal = false;
    },
    openDeleteModal(index) {
      this.showDeleteModal = true;
      this.indexDeleteHatch = index;
    },
    turnOnEditMode() {
      this.backUpData = JSON.parse(JSON.stringify(this.parsedVesselData));
      this.backUpDataHatches = JSON.parse(JSON.stringify(this.hatches));
      this.editShipTemplateBool = true;
      // this.keyRender++;
    },
    cancelOnEditMode() {
      this.setParsedVesselData(JSON.parse(JSON.stringify(this.backUpData)));
      // this.hatches = [];
      this.hatches = JSON.parse(JSON.stringify(this.backUpDataHatches));
      this.backUpData = null;
      this.editShipTemplateBool = false;
      // this.keyRender++;

    },
    addOneHatch(){
      this.hatches.push({no_x: null, no_y: null, grain: null, bale: null})
    },
    deleteHatchByIndex(index) {
      this.hatches.splice(index, 1)
      this.indexDeleteHatch = null;
      this.showDeleteModal = false;
    },
    async getHatchByIds() {
      if(this.parsedVesselData?.hatch_size?.length !== 0) {
        this.hatches = []
        this.showLoadHatches = true
        for(let i=0; i<this.parsedVesselData.hatch_size.length; i++) {
          if(!(this.hatches.find(a=> a?.id === this.parsedVesselData.hatch_size[i]))) {
            let item = await this.axiosGetHatchParsedVesselById(this.parsedVesselData.hatch_size[i])
            this.hatches.push(item)
          }
        }
        this.originalHatches = JSON.parse(JSON.stringify(this.hatches))
        this.showLoadHatches = false
      }
    },
    checkingKeyDown(event) {
      // alert(event.key)
      // console.log(event)
        if(!((/^[0-9]$/i).test(event.key) || event.key === '.' || event.key === 'Backspace' || event.keyCode === 39 || event.keyCode === 37 || ((event.keyCode === 86 || event.keyCode === 65 || event.keyCode === 67) && (event.metaKey || event.ctrlKey)))) {
          event.preventDefault()
        }
    },
    checkNum(num) {
      if(num) {
        num = (''+num).replaceAll(',', '')
        if(num.indexOf(".")>=0) {
          let num1 = num.substring(0, num.indexOf("."));
          let num2 = num.substring(num.indexOf(".") + 1, num.length);

          num1 = num1.replace(/[^0-9]/g, "");
          num2 = num2.replace(/[^0-9]/g, "");

          if(num2.length > 4) {
            num2 = num2.substring(0, 4);
          }
          if(num2.length !== 0) {
            num = num1 + "." + num2;
          } else {
            num = num.replace(/[^0-9]/g, "");
          }
        } else {
          num = num.replace(/[^0-9]/g, "");
        }
        if(!this.editShipTemplateBool) {
          num = parseFloat(num)
          num = num.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
          num = (''+num).replaceAll('.00', '')
        }
      }
      return num;
    },
    checkNumIn(num) {
      return num.replace(/[^0-9]/g, "");
    },
    getHtmlForShip(html) {
      if(!html || html.length == 0) {
        return ''
      }
      let newHtml = html;
      if(newHtml.indexOf("<B>")>=0 && newHtml.indexOf("</B>")>=0) {
        newHtml = newHtml.replaceAll("<B>", "<br/><B>").replaceAll("<B>", "<b>").replaceAll("</B>", "</b>").replace("<br/>", "").replaceAll("-", "*")
        let line = "*******************************************************"
        for(let i=0; line.length > 4; i++) {
          newHtml = newHtml.replaceAll(line, "<br/>"+line.replaceAll("*", "-")+"<br/>")
          line = line.slice(0, line.length-1)
        }
      }
      return newHtml;
    },
    clickSaveEditShipTemplate() {
      let html = this.$refs.shipTemplateHTML.innerHTML;
      // let textBackup = html;
      html = html.slice(html.indexOf(`contenteditable="true">`), html.lastIndexOf('</div>')).replaceAll('contenteditable="true">', '').replaceAll('</div>', '').replaceAll('</span>', '').replaceAll('<dev>', '').replaceAll('<span>', '')
      if(html !==  this.parsedVesselData.full_description) {
        // this.axiosChangeSearchShipTemplate({full_description: html, id: this.parsedVesselData.id}).then(bool=> {
        //   if(bool) {
        //     this.addTip('Description saved')
        //   } else {
        //     this.addTip('Error')
        //     this.parsedVesselData.full_description = textBackup;
        //   }
        // })
        this.parsedVesselData.full_description = html;
      }
    },
  },
  mounted() {

  },
  async beforeMount() {
    if(this.$route.path.indexOf('create') == -1) {
      if(this.$route.params.idParsedVessel != this.parsedVesselData?.id) {
        await this.axiosGetParsedVesselById(this.$route.params.idParsedVessel).then(()=> {this.getHatchByIds()})
      } else {
        this.getHatchByIds();
      }
      this.updateTextForNum();
    } else {
      this.setParsedVesselData({
        "id": null,
        "region": "",
        "name": "",
        "year": "",
        "position": "",
        "full_description": "",
        "mv": "",
        "call_sign": "",
        "ex_name": "",
        "type": "",
        "sub_type": "",
        "gears": "",
        "built_year": "",
        "built_by": "",
        "flag": "",
        "ship_class": "",
        "shipowner": "",
        "imo": "",
        "dwt": null,
        "draft": null,
        "tpc": null,
        "tpi": null,
        "lbp": null,
        "grain": null,
        "bale": null,
        "loa": null,
        "beam": null,
        "gt": null,
        "nt": null,
        "has_scrubber": false,
        "depth_moul": null,
        "light_weight": null,
        "ifo_capacity": null,
        "fw_capacity": null,
        "one_strength": null,
        "two_strength": null,
        "three_strength": null,
        "four_strength": null,
        "tropical_fresh_dwt": null,
        "tropical_fresh_ssw": null,
        "tropical_fresh_tpc": null,
        "tropical_dwt": null,
        "tropical_ssw": null,
        "tropical_tpc": null,
        "winter_dwt": null,
        "winter_ssw": null,
        "winter_tpc": null,
        "full_sp_ball": null,
        "full_ifo_ball": null,
        "full_mgo_ball": null,
        "full_sp_lad": null,
        "full_ifo_lad": null,
        "full_mgo_lad": null,
        "eco_sp_ball": null,
        "eco_ifo_ball": null,
        "eco_mgo_ball": null,
        "eco_sp_lad": null,
        "eco_ifo_lad": null,
        "eco_mgo_lad": null,
        "slow_sp_ball": null,
        "slow_ifo_ball": null,
        "slow_mgo_ball": null,
        "slow_sp_lad": null,
        "slow_ifo_lad": null,
        "slow_mgo_lad": null,
        "ifo_idle": null,
        "ifo_wk": null,
        "mgo_idle": null,
        "mgo_wk": null,
        "updated_at": "",
        "created_at": "",
        "hatch_size": [
        ]
      })
      this.editShipTemplateBool = true
    }
  }
}
</script>

<style scoped lang="scss">
  .wrap-page {
    background-color: var(--color-bgc-page);
    width: 100vw;
    height: calc(100vh - 56px);
    .top-panel {
      display: flex;
      align-items: center;
      background-color: var(--color-bgc-page-white);
      height: 44px;
      border-bottom: 1px solid var(--color-border);
      .blue-button {
        margin-left: auto;
      }
    }
    .main-block {
      display: flex;
      height: calc(100% - 44px);
      .description-vessel {
        padding: 15px 25px;
        box-sizing: border-box;
        border-right: 1px solid var(--color-border);
        background-color: var(--color-bgc-page-white);
        height: 100%;
        min-width: 430px;
        max-width: 670px;
        width: 34.8%;
        overflow-y: auto;
        @media (max-width: 1445px) {
          width: 300px;
          min-width: 300px;
          max-width: 300px;
        }
      }
    }
  }
  .top-panel {
    padding: 10px 25px;
    box-sizing: border-box;
  }
  .particulars {
    overflow-y: auto;
    width: 100%;
    padding: 16px 25px;
    box-sizing: border-box;
    &__title {
      background-color: var(--color-gray-dark);
      text-transform: uppercase;
      padding: 6px 8px;
      box-sizing: border-box;
      span {
        text-transform: none;
      }
    }
    &__stroke {
      min-height: 30px;
      display: flex;
      &-title {
        background-color: var(--color-bgc-page);
        padding: 6px 8px;
        box-sizing: border-box;
        //height: 100%;
        width: 85px;
        @media (max-width: 1445px) {
          width: 80px;
        }
        min-height: 30px;
        border-right: 1px solid var(--color-border);
        //border: 1px solid transparent;
        //border-top: 1px solid var(--color-border);
        //border-bottom: 1px solid var(--color-border);
      }
      &-value {
        ::-webkit-scrollbar
        {
          width: 0px;
          height: 0px;
          background-color: transparent;
        }
        ::-webkit-scrollbar-thumb
        {
          background: transparent;
          border-radius: 0px;
          width: 0px;
        }
        position: relative;
        background-color: var(--color-modal);
        box-sizing: border-box;
        //height: 100%;
        overflow: hidden;
        width: calc(100% - 85px);
        max-width: calc(100% - 85px);
        @media (max-width: 1445px) {
          width: calc(100% - 80px);
          max-width: calc(100% - 80px);
        }
          word-break: break-all;
        min-height: 30px;
        border-radius: 0px;
        //border: none;
        p, input {
          background-color: var(--color-bgc-page-white);
          padding: 6px 8px;
          box-sizing: border-box;
          border: 1px solid transparent !important;
          border-radius: 0 !important;
          &:focus {
            border-color: var(--color-blue-dark) !important;
          }
        }
        &>span:not(.units) {
          min-height: 10px;
          min-width: 10px;
          width: 100%;
          height: 100%;
        }

      }
    }
    &__wrap-block {
      //border: 1px solid var(--color-dark-gray);
      border-radius: 8px;
      overflow: hidden;
    }
    .border-bottom {
      border-bottom: 1px solid var(--color-border);
    }
    .border-dark-gray {
      border: 1px solid var(--color-dark-gray);
    }
    .border-dark-gray-top {
      border-top: 1px solid var(--color-dark-gray);
    }
    .border-dark-gray-bottom {
      border-bottom: 1px solid var(--color-dark-gray);
    }
    .border-dark-gray-left {
      border-left: 1px solid var(--color-dark-gray);
    }
    .border-dark-gray-right {
      border-right: 1px solid var(--color-dark-gray);
    }
    .border-left {
      border-left: 1px solid var(--color-border);
    }
    .weight-100-3 {
      width: calc(100% / 3);
    }
    .weight-100-2 {
      width: 500%;
    }
    .weight-100-3-2 {
      width: calc((100% / 3) / 2);
    }
    .d-flex {
      display: flex;
    }
    .weight-2-3 {
      width: calc((100% / 3) * 2);
    }
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .wrap-too-table {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
    &>* {
      width: calc((100% - 14px) / 2);
    }
  }
  .w70 {
    width: 70px;
  }
  .w100 {
    width: 100%;
  }
  .w100-70-2 {
    width: calc((100% - 70px) / 2);
  }
  .units-block {
    padding-right: 30px !important;
    position: relative;
  }
  .units {
    display: flex;
    align-items: flex-end;
    position: absolute;
    height: 16px;
    top: 8px;
    right: 6px;
  }
  .loader-local {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    /deep/ {
      .sk-fading-circle {
        width: 50px;
        height: 50px;
      }
    }

    background-color: rgba(256, 256, 256, 0.8);
  }
  .dart-mode {
    .loader-local {
      background-color: rgba(46, 45, 58, 0.8);
    }
    .particulars__title {
      background-color: var(--color-filter-modal);
    }
  }
  .delete-hatch {
    position: absolute;
    top: 3px;
    right: 3px;
    width: max-content;
    height: max-content;
    svg {
      path {
        transition: .1s linear;
        fill: var(--color-gray);
      }
    }
    &:hover {
      svg {
        path {
          fill: var(--color-red)
        }
      }
    }
  }
  .bottom-panel {
    background-color: var(--color-bgc-page-white);
    border-top: 1px solid var(--color-border);
    z-index: 10;
    padding: 0 24px;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 44px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-end;
    .blue-button {
      width: 360px;
      margin-left: 100px;
    }
  }
  .pb44 {
    padding-bottom: 48px !important;
  }
  .button-checkbox {
    position: absolute;
    right: 5px;
  }

  .value-num {
    //height: 30px;
    //width: calc(100% - 30px);
    ////box-sizing: border-box;
    //white-space: nowrap;
    ////overflow-x: auto;
    //overflow: hidden;
  }
  .ml-8 {
    margin-left: 8px !important;
  }
  .weight50Proc {
    width: 50%;
  }
</style>