<template>
  <div class="counterparties-list-bottom" v-show="selectedListId.length">
    <span class="counterparties-list-bottom_text">Selected: </span>
    <span class="counterparties-list-bottom_count m-r-auto">{{selectedListId.length}}/{{trials.length}}</span>

    <button class="btn btn-next" v-if="getPermissionsByType('archive_accounts')" @click="onArchive"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.63636 1.36585C6.50732 1.36585 6.40909 1.46907 6.40909 1.58852V2.26399H9.59091V1.58852C9.59091 1.46907 9.49268 1.36585 9.36364 1.36585L6.63636 1.36585ZM10.9545 2.26399V1.58852C10.9545 0.707685 10.2387 2.90754e-07 9.36364 2.32603e-07L6.63636 0C5.76125 -5.81509e-08 5.04545 0.707684 5.04545 1.58852V2.26399H1.18182C0.80526 2.26399 0.5 2.56975 0.5 2.94692C0.5 3.32409 0.80526 3.62984 1.18182 3.62984H2.31818V13.5059C2.31818 14.8869 3.441 16 4.81818 16H11.1818C12.559 16 13.6818 14.8869 13.6818 13.5059V3.62984H14.8182C15.1947 3.62984 15.5 3.32409 15.5 2.94692C15.5 2.56975 15.1947 2.26399 14.8182 2.26399H10.9545ZM3.68182 3.62984V13.5059C3.68182 14.1255 4.18706 14.6341 4.81818 14.6341H11.1818C11.8129 14.6341 12.3182 14.1255 12.3182 13.5059V3.62984H3.68182ZM6.63636 5.88637C7.01292 5.88637 7.31818 6.19213 7.31818 6.5693V11.5501C7.31818 11.9272 7.01292 12.233 6.63636 12.233C6.25981 12.233 5.95455 11.9272 5.95455 11.5501V6.5693C5.95455 6.19213 6.25981 5.88637 6.63636 5.88637ZM9.36364 5.88637C9.74019 5.88637 10.0455 6.19213 10.0455 6.5693V11.5501C10.0455 11.9272 9.74019 12.233 9.36364 12.233C8.98708 12.233 8.68182 11.9272 8.68182 11.5501V6.5693C8.68182 6.19213 8.98708 5.88637 9.36364 5.88637Z" fill="white"/></svg>Archive</button>

    <button class="btn btn-close text-dark1-icon" @click="onClose"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#9AA3B0"/><path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#9AA3B0"/></svg></button>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    selectedListId: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    ...mapState({
      trials: state => state.trialBalance.trials,
    }),
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    onArchive() {
      this.$emit('on-archive')
    },
    onClose() {
      this.$emit('on-close')
    },
  },
}
</script>

<style lang="scss">
</style>