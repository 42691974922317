<template>
  <modal-slots @close="close" :isBorder="false" :footerHide="true" class="modal" :show-mod="true">
    <template v-slot:title>
      <p class="f18 fw500">To open access</p>
    </template>
    <div class="p0-16 position-relative z-10">
      <folders-drop-search-employees :dontShowMe="true" v-model="users"></folders-drop-search-employees>
    </div>
    <div class="d-flex align-items-center p0-16 w100Proc box-border mt16">
      <p class="fz14 fw500 text-dark">Users with access</p>
      <p class="fz14 text-dark ml-auto">Open to all</p>
      <div class="check ml8" v-on:click="selectAll">
        <svg v-if="users.length === usersForFolders.length" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000976562 4C0.000976562 1.79086 1.79184 0 4.00098 0H14.001C16.2101 0 18.001 1.79086 18.001 4V14C18.001 16.2091 16.2101 18 14.001 18H4.00098C1.79184 18 0.000976562 16.2091 0.000976562 14V4Z" fill="#094172"/>
          <path d="M14.501 5.02532C14.1877 4.67724 13.6709 4.66759 13.3466 5.00375C13.3398 5.01079 13.3331 5.01797 13.3265 5.02532L7.76493 10.9232L4.88453 8.7928C4.5602 8.45664 4.04338 8.46629 3.73016 8.81437C3.42458 9.15392 3.42458 9.6922 3.73016 10.0318L7.18774 12.7816C7.50657 13.1237 8.02334 13.1237 8.34211 12.7816L14.4809 6.26426C14.8052 5.92805 14.8142 5.37339 14.501 5.02532Z" fill="white"/>
        </svg>

        <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.00098 1.4C2.56504 1.4 1.40098 2.56406 1.40098 4V14C1.40098 15.4359 2.56504 16.6 4.00098 16.6H14.001C15.4369 16.6 16.601 15.4359 16.601 14V4C16.601 2.56406 15.4369 1.4 14.001 1.4H4.00098ZM0.000976562 4C0.000976562 1.79086 1.79184 0 4.00098 0H14.001C16.2101 0 18.001 1.79086 18.001 4V14C18.001 16.2091 16.2101 18 14.001 18H4.00098C1.79184 18 0.000976562 16.2091 0.000976562 14V4Z" fill="#9AA3B0"/>
        </svg>
      </div>
    </div>
    <div class="mt4 w100Proc box-border mt4 pb16">
      <template v-if="users.length !== usersForFolders.length">
        <button v-for="(user, i) in users" :key="i+'sds33'" class="user h32 w100Proc d-flex align-items-center position-relative p0-16 box-border">
          <img class="w24 h24 border-radius-100" :src="user.avatar" alt="" v-if="user.avatar">
          <svg v-if="!user.avatar" width="24" height="24" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"></circle><path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"></path></svg>
          <span class="fz13 ml8 text-dark-gray">{{user.first_name}} {{user.last_name}}</span>
          <svg class="r8 position-absolute cursor-pointer" v-on:click="users = users.filter(a=> a.id !== user.id)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51472 7.57727C6.2171 7.28258 6.2171 6.80478 6.51472 6.51008C6.81235 6.21539 7.2949 6.21539 7.59253 6.51008L17.4873 16.2961C17.7849 16.5908 17.7849 17.0685 17.4873 17.3632C17.1897 17.6579 16.7071 17.6579 16.4095 17.3632L6.51472 7.57727Z" fill="#2C2C2C"/>
            <path d="M16.4096 6.634C16.7072 6.33931 17.1898 6.33931 17.4874 6.634C17.785 6.9287 17.785 7.4065 17.4874 7.70119L7.59263 17.4872C7.295 17.7819 6.81245 17.7819 6.51483 17.4872C6.2172 17.1925 6.2172 16.7147 6.51483 16.42L16.4096 6.634Z" fill="#2C2C2C"/>
          </svg>

        </button>
      </template>

      <p class="mt20 fz13 text-gray mb24 text-align-center" v-if="users.length === 0">Only you have access to this file</p>
      <p class="mt20 fz13 text-gray mb24 text-align-center" v-if="users.length === usersForFolders.length">Access is opened to all users</p>
    </div>

  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import {mapActions, mapMutations, mapState} from "vuex";
import FoldersDropSearchEmployees from "@/components/folders/folders-drop-search-employees";
export default {
  name: "folders-to-open-access",
  components: {FoldersDropSearchEmployees, ModalSlots},
  computed: {
    ...mapState({
      dataForModalAccess: state=> state.folders.dataForModalAccess,
      usersForFolders: state => state.folders.usersForFolders,

    })
  },
  props: {
    selected: Array,
  },
  watch: {
    dataForModalAccess: function () {
      this.users = this.dataForModalAccess.shared_with
    }
  },
  data() {
    return {
      users: [],
    }
  },
  methods: {
    ...mapActions(['axiosPutFileForFolders', 'axiosSetAccessUsersForFolders']),
    ...mapMutations(['clearSelectedFoldersPage', 'createEmitGetActivityFolders']),
    close() {
      if(Array.isArray(this.selected) && this.selected.length) {
        let form = {
          ids: this.selected.map(a=> {return a.id}),
          shared_with: this.users.map(a=> {return a.id})
        }
        this.axiosSetAccessUsersForFolders({data: form}).then(()=> {
          this.createEmitGetActivityFolders()
        })
        this.clearSelectedFoldersPage()
      } else {
        if(this.dataForModalAccess.shared_with.map(a=> {return a.id}) !== this.users.map(a=> {return a.id})) {
          // let form = JSON.parse(JSON.stringify(this.dataForModalAccess))
          // delete form.id
          // delete form.user
          // form.shared_with = this.users.map(a=> {return a.id})
          let form = {
            ids: [this.dataForModalAccess.id],
            shared_with: this.users.map(a=> {return a.id})
          }
          this.axiosSetAccessUsersForFolders({data: form}).then(()=> {
            this.createEmitGetActivityFolders()
          })

          this.dataForModalAccess.shared_with = this.users
        }
      }

      this.$emit('close')
    },
    selectAll() {
      if(this.users.length === this.usersForFolders.length) {
        this.users = []
      } else {
        this.users = JSON.parse(JSON.stringify(this.usersForFolders))
      }
    },
  },
  mounted() {
    if(this.dataForModalAccess) {
      this.users = this.dataForModalAccess.shared_with
    }
  }
}
</script>

<style scoped lang="scss">
  .modal {
    /deep/ {
      .top-panel {
        padding: 14px 16px !important;
      }
    }
  }
  .user {
    &:hover {
      background-color: var(--color-gray-lightest);
    }
  }
</style>