<template>
  <div class="pagination" v-if="pagination.count != 0">
    <span class="num">{{getCurrentPage}} - {{getTotalPages}}</span>
    <button class="pagination__button prev" :class="{disabled: !pagination.previous}" @click="$emit('goPrevPage')">
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.70271 6.00586L6.81869 2.70355C7.08581 2.43169 7.51828 2.43215 7.78495 2.70496C8.05142 2.97773 8.05073 3.41961 7.78358 3.69168L5.15289 6.49984L7.78358 9.2171C8.0507 9.48921 8.05139 9.9308 7.78495 10.2036C7.65127 10.3404 7.47614 10.4088 7.30101 10.4088C7.12633 10.4088 6.95189 10.3408 6.81845 10.205L3.70271 6.99378C3.57406 6.86307 3.50187 6.68518 3.50187 6.49984C3.50187 6.3145 3.57427 6.13682 3.70271 6.00586Z" fill="#2C2C2C"/>
      </svg>
    </button>
    <button class="pagination__button next" :class="{disabled: !pagination.next}" @click="$emit('goNextPage')">
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.29728 6.90039L5.18131 10.2027C4.91419 10.4746 4.48172 10.4741 4.21504 10.2013C3.94858 9.92852 3.94927 9.48664 4.21642 9.21457L6.84711 6.40641L4.21642 3.68915C3.9493 3.41704 3.94861 2.97545 4.21504 2.70264C4.34872 2.56588 4.52386 2.4975 4.69899 2.4975C4.87367 2.4975 5.04811 2.56542 5.18155 2.70123L8.29728 5.91247C8.42594 6.04318 8.49813 6.22107 8.49813 6.40641C8.49813 6.59175 8.42573 6.76943 8.29728 6.90039Z" fill="#2C2C2C"/>
      </svg>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'paginationComponent',
    props: {
      pagination: {type: Object}
    },
    computed: {
      getCurrentPage(){
        return this.pagination.offset + 1
      },
      getTotalPages(){
        if((this.pagination.offset + this.pagination.limit) > this.pagination.count){
          return this.pagination.count
        }else{
          return this.pagination.offset + this.pagination.limit
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.pagination{
  margin-left: auto;
  display: flex;
  align-items: center;
  .num{
    margin-right: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--color-gray-text);
  }
  .pagination__button{
    width: 24px;
    height: 24px;
    min-width: 24px;
    &.prev{
      margin-right: 16px;
    }
    &.disabled{
      pointer-events: none;
      svg{
        path{
          fill: var(--color-gray-text);
        }
      }
    }
  }
  &.loading{
    opacity: .5;
    pointer-events: none;
  }
}
</style>