<template>
  <div class="bank-aside_total">
    <div class="bank-aside_total__text fz14">Total of assets <br> as of {{getToday}}</div>
    <div class="bank-aside_total__numb fz14 fw500">{{totalSumm | formatNumberTwo}} USD</div>
  </div>
</template>

<script>
import moment from 'moment';

import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      totalSumm: 0,
      rates: []
    }
  },
  watch: {
    banks: {
      deep: true,
      immediate: true,
      handler() {
        this.countSumm()
      }
    }
  },
  computed: {
    ...mapState({
      banks: state => state.banks.banks
    }),
    getToday() {
      return moment().format('DD.MM.YYYY')
    },
  },
  methods: {
    ...mapActions(['axiosGetCurrency']),
    countSumm() {
      this.totalSumm = 0
      if(this.banks){
        this.banks.forEach(bank => {
          if(bank.type == 'cash') {
            this.getConvert(bank.currency, bank.balance)
          }else{
            bank.requisites.forEach(cash => {
              if(!cash.is_archive)
                this.getConvert(cash.currency, cash.balance)
            })
          }
        })
      }
    },
    async getRate(currency) {
      await this.axiosGetCurrency({currency: currency}).then(response=> {
        let rate = response.data.results[response.data.results.length - 1]
        this.rates.push(rate)
      })
    },
    async getConvert(currency, value) {
      if(currency == 'USD'){
        this.totalSumm += value
      }else{
        let currRate = this.rates.find(el=>el.currency == currency)
        if(!currRate){
          await this.getRate(currency)
          currRate = this.rates.find(el=>el.currency == currency)
        }
        this.totalSumm += value / currRate.rate
      }
    }
  },
}
</script>

<style lang="scss">
  .bank-aside_total{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px 8px 23px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    &__text{
      color: #9AA3B0;
    }
    &__numb{
    }
  }
</style>