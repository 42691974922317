<template>
  <div class="flex items-center">
    <b-form-radio class="m-r-8" v-model="getDeliveryAdditional.checkboxes" value="offhire">{{typeBunkers == 'DELIVERY' ? 'On':'Off'}} hire Bunker Survey</b-form-radio>
    <b-form-radio v-model="getDeliveryAdditional.checkboxes" value="estimated">
      Estimated Bunkers on 
      {{typeBunkers == 'DELIVERY' ? 'Delivery':'Redelivery'}}
    </b-form-radio>
  </div>
</template>

<script>
import { BFormRadio } from 'bootstrap-vue'

export default {
  props: {
    bunkers: Array,
    typeBunkers: String
  },
  components: {
    BFormRadio
  },
  computed: {
    getDeliveryAdditional() {
      return this.bunkers.find(el => {
        return el.bunkers_type == this.typeBunkers && el.fuel_type == 'LSMGO'
      }).additional_info
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "./src/components/style-element/bootstrap";
  /deep/ .custom-control{
    display: flex;
    align-items: center;
    min-height: 0;
    .custom-control-label{
      margin-top: 1px;
    }
    .custom-control-label:after,
    .custom-control-label:before{
      top: -2px;
      width: 1.125rem;
      height: 1.125rem;
    }
    .custom-control-label::before{
      background-color: transparent;
      border: 2px solid var(--color-white);
    }
    .custom-control-label:after{
      border-radius: 50%;
      width: calc(1.125rem - 8px);
      height: calc(1.125rem - 8px);
      top: 2px;
      margin-left: 4px;
    }
    .custom-control-input:checked ~ .custom-control-label::before{
      background-color: transparent;
      border-color: var(--color-blue);
    }
    .custom-control-input:checked ~ .custom-control-label::after{
      background-color: var(--color-blue);
      background-image: none;
    }
    .custom-control-input:focus ~ .custom-control-label::before{
      box-shadow: none;
    }
  }
</style>
