<template>
  <div class="p-t-4">
    <button
      v-if="activeCreator && $route.path.indexOf('archive') === -1 && activeCreator.id === getLoginData.data.user.id && !exportPdf"
      class="w-full blue-button fz13 fw500 p-y-1.5"
      @click="onAddCargo"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="white"/></svg>
      Create new cargo
    </button>

    <div class="m-t-4 shadow-table border border-dark-gray rounded-lg flex relative" v-if="getEditableCargo">
      <div class="blocked-changes" v-if="$route.path.indexOf('archive') >= 0 || activeCreator.id !== getLoginData.data.user.id"></div>
      <cargo-port-detail :detail="getEditableCargo" :exportPdf="exportPdf"/>
      <cargo-port-loading :detail="getEditableCargo" :exportPdf="exportPdf" :alowedPorts="getLoadingPorts"/>
      <cargo-port-discharging :detail="getEditableCargo" :exportPdf="exportPdf" :alowedPorts="getDischargingPorts"/>
    </div>

    <div class="m-t-4 shadow-table border border-dark-gray rounded-lg overflow-hidden relative">
      <div class="blocked-changes" v-if="$route.path.indexOf('archive') >= 0 "></div>
      <div class="bg-gray-dark cargo_list__table_row cargo_list__table_row_head text-gray-text">
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Cargo'">Cargo</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Quantity'">Quantity</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Freight'">Freight</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Load Port'">Load Port</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Rate/Days'">Rate/Days</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Terms'">Terms</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Turn time'">Turn time</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Water'">Water</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Season'">Season</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Disch. Port'">Disch. Port</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Rate/Days'">Rate/Days</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Terms'">Terms</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Turn time'">Turn time</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Water'">Water</div>
        <div class="p-y-1 p-l-1 p-r-1" v-tooltip.top="'Season'">Season</div>
        <div class=""></div>
      </div>

      <cargo-row
        v-for="row in getOrderingCargo"
        :key="row.id"
        :row="row"
        :list="cargoList"
        :editedCargoId="editedCargoId"
        @on-delete="onDeleteRow(row.id)"
        @on-select="onSelectRow(row.id)"
      />
    </div>
    <div class="p-t-6"></div>
    <p>Average Cg Comm: {{averageCgComm}}</p>
  </div>
</template>

<script>
import cargoRow from '@/components/calc/cargoRow'
import cargoPortDetail from '@/components/calc/cargoPortDetail'
import cargoPortLoading from '@/components/calc/cargoPortLoading'
import cargoPortDischarging from '@/components/calc/cargoPortDischarging'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
export default {
  props: {
    exportPdf: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    cargoRow,
    cargoPortDetail,
    cargoPortLoading,
    cargoPortDischarging
  },
  data() {
    return {
      editedCargoId: null,
      emptyCargo: {
        tab: null,
        ballast_voyage: false,
        cargo_class: null,
        quantity_min: null,
        quantity_max: null,
        quantity_mt: null,
        quantity_percentage: null,
        freight: null,
        cg_comm: 3.75,
        stowage_factor_m: null,
        stowage_factor_ft: null,
        extra_cargo_costs: null,
        loading_wk_cranes: true,
        loading_port_name: null,
        loading_rate_type: 'RATE',
        loading_rate: null,
        loading_terms: null,
        loading_turn_time: '12',
        loading_liner: null,
        loading_draft: null,
        loading_water_density: 'Salt water (1,025)',
        loading_seasonal_mark: 'summer',
        discharging_wk_cranes: true,
        discharging_port_name: null,
        discharging_rate_type: 'RATE',
        discharging_rate: null,
        discharging_terms: null,
        discharging_turn_time: '12',
        discharging_liner: null,
        discharging_draft: null,
        discharging_water_density: 'Salt water (1,025)',
        discharging_seasonal_mark: 'summer',
        loading_port: null,
        discharging_port: null
      },
      routeList: [],
      cargoList: [],
    }
  },
  computed: {
    ...mapState({
      activeCreator: state => state.tabs.activeCreator,
      termsList: state => state.tabs.termsList,
    }),
    ...mapGetters(['getLoginData']),
    averageCgComm() {
      let average = 0;
      this.getOrderingCargo.forEach(route=> {
        average += parseFloat(route.cg_comm) ? parseFloat(route.cg_comm) : 0
      })
      return average / this.getOrderingCargo.length
    },
    getEditableCargo() {
      return this.cargoList?.find(el=>el.id == this.editedCargoId)
    },
    getLoadingPorts() {
      if(!this.routeList.length) return []
      return this.routeList.filter(el=>el.status == 'loading').map(el=>{
        return {
          ...el.port,
          route_id: el.id
        }
      })
    },
    getDischargingPorts() {
      if(!this.routeList.length) return []
      return this.routeList.filter(el=>el.status == 'discharging').map(el=>{
        return {
          ...el.port,
          route_id: el.id
        }
      })
    },
    getOrderingCargo() {
      let orderingList = []
      // this.routeList.forEach(route=>{
      //   let filteredCargo = this.cargoList.filter(cargo=>cargo.loading_port_name == `#${route.id}`)
      //   orderingList.push(...filteredCargo)
      // })
      //
      // let filteredCargo = this.cargoList.filter(cargo=>!cargo.loading_port_name)
      // orderingList.push(...filteredCargo)

      orderingList = this.cargoList.map(cargo=> {
        if(this.routeList.find(route=> cargo.loading_port_name == `#${route.id}`) || !cargo.loading_port_name) {
          return cargo;
        }
      }).filter(a=> a)


      return orderingList
    }
  },
  methods: {
    ...mapActions(['axiosGetCalcCargo', 'axiosPostCalcCargo', 'axiosDeleteCalcCargo', 'axiosGetCalcRoute']),
    ...mapMutations(['showLoad', 'hideLoad']),
    async onAddCargo() {
      let newCargo = JSON.parse(JSON.stringify(this.emptyCargo))
      newCargo.tab = +this.$route.params.id
      let rasponceCargo = await this.axiosPostCalcCargo(newCargo)
      this.cargoList.push(rasponceCargo)
      this.editedCargoId = rasponceCargo.id
    },
    async onDeleteRow(id) {
      await this.axiosDeleteCalcCargo(id)
      let delIndex = this.cargoList.findIndex(el=>el.id == id)
      if(delIndex >= 0)
        this.cargoList.splice(delIndex, 1)

      if(this.cargoList.length){
        this.editedCargoId = this.getOrderingCargo[this.cargoList.length-1].id
      }else{
        this.onAddCargo()
      }
    },
    onSelectRow(id) {
      this.editedCargoId = id
    },
    onSetTerms() {
      this.cargoList.forEach(cargo => {
        if(cargo.loading_terms){
          let terms = this.termsList.find(el=>el.name == cargo.loading_terms)
          if(terms)
            cargo.loading_terms = terms.name+' - '+terms.coof
        }
        if(cargo.discharging_terms){
          let terms = this.termsList.find(el=>el.name == cargo.discharging_terms)
          if(terms)
            cargo.discharging_terms = terms.name+' - '+terms.coof
        }
      })
    },
    checkCargos() {
      this.cargoList.forEach(cargo => {
        let loadCargoId = +cargo.loading_port_name?.replace('#','')
        let loadRoute = this.routeList.find(el=>el.id==loadCargoId)
        let dischargingCargoId = +cargo.discharging_port_name?.replace('#','')
        let dischargingRoute = this.routeList.find(el=>el.id==dischargingCargoId)
        if(cargo.loading_port && (cargo.loading_port_name?.indexOf('#') != 0 || !loadRoute || loadCargoId != loadRoute.id || loadRoute.port.name != cargo.loading_port.name)){
          cargo.loading_port = null
          cargo.loading_port_name = null
        }else if(cargo.loading_port){
          cargo.loading_port.route_id = +cargo.loading_port_name.replace('#', '')
        }
        if(cargo.discharging_port && (cargo.discharging_port_name?.indexOf('#') != 0 || !dischargingRoute || dischargingCargoId != dischargingRoute.id || dischargingRoute.port.name != cargo.discharging_port.name)){
          cargo.discharging_port = null
          cargo.discharging_port_name = null
        }else if(cargo.discharging_port){
          cargo.discharging_port.route_id = +cargo.discharging_port_name.replace('#', '')
        }
      })
    },
    async loadCargo() {
      this.showLoad()

      let routes = await this.axiosGetCalcRoute(this.$route.params.id)
      if(routes?.length)
        this.routeList = routes
      
      let cargoList = await this.axiosGetCalcCargo(this.$route.params.id)
      this.cargoList = cargoList
      if(Array.isArray(this.getOrderingCargo) === true && this.getOrderingCargo.length){
        console.log(this.getOrderingCargo.length)
        console.log(this.getOrderingCargo)
        console.log( this.getOrderingCargo[this.getOrderingCargo.length-1])
        this.onSetTerms()
        this.editedCargoId = this.getOrderingCargo[this.getOrderingCargo.length-1] ? this.getOrderingCargo[this.getOrderingCargo.length-1].id : null
      }else{
        await this.onAddCargo()
      }
      this.hideLoad()
      await this.$nextTick()
      this.checkCargos()
    },
  },
  beforeMount() {
    this.loadCargo()
  }
}
</script>

<style lang="scss">
.cargo_list__table_row{
  display: flex;
  >div{
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    &:not(:last-child){
      border-right: 1px solid var(--color-gray-text);
    }
    &:nth-child(1){width: 11.1386%;}/* 180 */
    &:nth-child(2){width: 5.5693%;}/* 90 */
    &:nth-child(3){width: 6.1881%;}/* 100 */
    &:nth-child(4){width: 10.5198%;}/* 170 */
    &:nth-child(5){width: 5.5693%;}/* 90 */
    &:nth-child(6){width: 5.3218%;}/* 86 */
    &:nth-child(7){width: 4.3317%;}/* 70 */
    &:nth-child(8){width: 5.9406%;}/* 96 */
    &:nth-child(9){width: 5.9406%;}/* 96 */
    &:nth-child(10){width: 10.5198%;}/* 170 */
    &:nth-child(11){width: 5.5693%;}/* 90 */
    &:nth-child(12){width: 5.3218%;}/* 86 */
    &:nth-child(13){width: 4.3317%;}/* 70 */
    &:nth-child(14){width: 5.9406%;}/* 96 */
    &:nth-child(15){width: 5.9406%;}/* 96 */
    &:nth-child(16){
      width: 30px;
      flex-shrink: 0;
      font-size: 0;
    }
  }
}
.cargo_list__table_row{
  >div{
    font-size: 13px;
  }
}

.default_select__in_calc{
  .multiselect__input, .multiselect__single{
    margin-bottom: 3px;
  }
  .multiselect__tags{
    min-height: 28px;
    padding-top: 4px;
    border: none;
    background-color: transparent;
  }
}
.blocked-changes {
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>