<template>
  <div class="m-t-4 border border-dark-gray rounded-lg">
    <div class="vessel_owners__table">
      <div class="first_element_stroke bg-blue-dark colspan-1-6 uppercase p-x-3 p-y-2 fz13 fw500 text-white">C/E/V</div>
    </div>
    <div
        class="vessel_owners__table_wide bg-gray-lightest rounded-lg"
        :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <div class=" border-b border-r"></div>
      <div class=" border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
      <div class=" border-b border-r"></div>
      <div
          class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1"
      >
        Rate, {{editData.currency}}/PMPR
      </div>
      <div class=" border-b border-r"></div>
      <div class=" border-b border-r"></div>
      <div class=" border-b border-r"></div>
      <div class=" border-b"></div>

      <div
          class="
          relative
          rounded-bl-lg
          border-r
          uppercase
          p-x-2 p-y-2
          fz13
          fw500
          text-dark
        "
          :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}"
      >
        C/E/V
        <switcher
            class="switcher_owners-row"
            id="bunkers-cev"
            v-model="getCEV.additional_info.is_row"
        />
      </div>
      <div class="border-r" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
            v-if="$route.params.subId"
            trialKey="SUBLET_Bunkers on Redelivery:Bunkers on Dev./Redev.: C/E/V"
        />
        <get-trials
            v-else
            trialKey="Bunkers on Redelivery:Bunkers on Dev./Redev.: C/E/V"
        />
      </div>
      <div class="border-r" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}">
        <select class="p-l-2 p-r-6 p-y-2 overflow-ellipsis" v-model="getCEV.additional_info.select" :disabled="!getCEV.additional_info.is_row">
          <option value="rate1">Rate*Numbers of day / (365/12)</option>
          <option value="rate2">Rate/30* Number of days</option>
        </select>
      </div>
      <div class="border-r relative" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}">
        <input-format-number
            v-model="getCEV.additional_info.rate"
            :inputClass="'p-x-2 p-y-2 '+(!getCEV.additional_info.is_row?'text-gray-text':'')"
            placeholder="Enter rate"
            :disabled="!getCEV.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-r relative" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}">
        <p
            class="p-x-2 p-y-2 text-align-right"
            v-if="getCEV.additional_info.trs_type == 'revenues'"
        >{{getCEV.debit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-r relative" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}">
        <p
            class="p-x-2 p-y-2 text-align-right"
            v-if="getCEV.additional_info.trs_type == 'expenses'"
        >{{getCEV.credit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-r">
        <trs-type-btn :additional="getCEV.additional_info" active="revenues" />
      </div>
      <div class="overflow-hidden rounded-br-lg">
        <trs-type-btn
            class=""
            :additional="getCEV.additional_info"
            active="expenses"
        />
      </div>
    </div>

  </div>

</template>

<script>
import getTrials from "@/components/vessels/owners/edit/getTrials";
import trsTypeBtn from "@/components/vessels/owners/edit/trsTypeBtn";
import inputFormatNumber from "@/components/reuse/inputFormatNumber";
import Switcher from "@/components/reuse/switcher";
import {mapGetters} from "vuex";

export default {
  name: "editBunkersOnTableCEV",
  props: {
    changeDateHire: Number,
    editData: Object,
    bunkersOnData: Array,
    hireData: Array,
    emptyBunkers: Array
  },
  components: {
    getTrials,
    trsTypeBtn,
    inputFormatNumber,
    Switcher
  },
  watch: {
    changeDateHire: function () {
      this.getCev();
    },
    "getCEV.additional_info.trs_type": function () {
      this.getCev();
    },
    "getCEV.additional_info.rate": function () {
      this.getCev();
    },
    "getCEV.additional_info.select": function () {
      this.getCev();
    },
    "getLastHire.additional_info.days": function() {
      this.getCev();
    },
    hireData: {
      deep: true,
      handler() {
        this.getCev();

      }
    }

  },
  computed: {
    ...mapGetters(["getPermissionsByType"]),
    getCEV() {
      return this.bunkersOnData.find((el) => {
        return el.bunkers_type == "C/E/V";
      });
    },
  },
  methods: {
    getCev() {
      console.log('')
      let result = this.getCEV.additional_info.rate;
      let days = 0
      this.hireData.forEach(hire=> {
        days+=hire.additional_info.days
      })
      if (days == 0) result = 0;
      else if (this.getCEV.additional_info.select == "rate1") {
        result = (result * days) / 30.4167;
      } else {
        console.log(result, days )
        result = (result / 30) * days;
      }
      console.log('days', days)



      result = result ? result : "";

      if (this.getCEV.additional_info.trs_type == "revenues") {
        this.getCEV.debit = result
        this.getCEV.credit = ""
      } else {
        this.getCEV.debit = ""
        this.getCEV.credit = result
      }
    },
    async checkIsRow() {
      await this.$nextTick()
      this.bunkersOnData.forEach(row => {
        if(!Object.prototype.hasOwnProperty.call(row.additional_info, 'is_row')){
          this.$set(row.additional_info, 'is_row', true)
        }
      })
    },
  },
  mounted() {
    this.checkIsRow()
  }
}
</script>

<style scoped>

</style>