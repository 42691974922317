<template>
  <div class="payment-accounts_list">
    <div class="payment-accounts_item" v-for="acc in getAccounts" :key="acc.id">
      <button 
        class="btn btn-unarchive" 
        v-if="getPermissionsByType('can_archive_client_payments')"
        @click="unArchive(acc)"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#094172"/><path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#094172"/></svg>
        Unarchive
      </button>
      <payment-account-row title="Holder" :value="acc.holder" />
      <payment-account-row title="Account Number" :value="acc.account_number" />
      <payment-account-row title="IBAN" :value="acc.iban" />
      <payment-account-row title="Description" :value="acc.description" />
      <payment-account-row title="Bank" :value="acc.bank" />
      <payment-account-row title="Clearing number" :value="acc.clearing_number" />
      <payment-account-row title="BIC / SWIFT" :value="acc.bic" />
      <payment-account-row title="Postal Account" :value="acc.postal" />
      <payment-account-row title="Currency" :value="acc.currency" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import paymentAccountRow from './paymentAccountRow'
export default {
  components: { paymentAccountRow },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['getPaymentAccountsArchiveByID', 'getPermissionsByType']),
    getAccounts() {
      return this.getPaymentAccountsArchiveByID(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions(['axiosUnArchivedPaymentAccount']),
    ...mapMutations(['addTip']),
    async unArchive(account) {
      await this.axiosUnArchivedPaymentAccount({clientId: account.client, id: account.id})
      this.addTip('Payment Account in unarchived')
    },
  },
  
}
</script>

<style scoped lang="scss">
  .payment-accounts{
    &_item{
      width: 24.16%;
      &:nth-child(3n) {
        margin-right: 1.1%;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
  .btn-unarchive{
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 24px;
    font-weight: 500;
    font-size: 13px;
    color: #094172;
    svg{
      margin-right: 4px;
    }
  }
</style>