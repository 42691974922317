<template>
  <div class="sidebar-row" :class="{active: isActivefolder}">
    <div class="sidebar-row-heading accordeon" @mouseup="mouseUp" :idFolder="folder.id" @dragenter="(e)=>{dragModeFolderActive ? e.target.classList.add('bg-peach') : null}" @dragleave="(e)=>{dragModeFolderActive ? e.target.classList.remove('bg-peach') : null}" :class="{'not-attach': disabledAccountDuringDrop}" @mouseleave="hideSubMenu" @click="setActiveFolder(folder.id, folder.is_custom ? 'Incoming' : folder.title)">
      <button
        v-if="folder.child_folders"
        class="arrow"
        v-on:click="$emit('showFolderEmit', folder.id)">
        <svg width="12" height="12">
          <use xlink:href="#accordeon_arrow_icn"></use>
        </svg>
      </button>
      <svg width="16" height="16" v-if="folder.title == 'Incoming'">
        <use xlink:href="#incoming_icn"></use>
      </svg>
      <svg width="16" height="17" v-else-if="folder.title == 'Sent'">
        <use xlink:href="#sent_icn"></use>
      </svg>
      <svg width="16" height="17" v-else-if="folder.title == 'Draft'">
        <use xlink:href="#draft_icn"></use>
      </svg>
      <svg width="16" height="17" v-else-if="folder.title == 'Archive'">
        <use xlink:href="#archive_icn"></use>
      </svg>
      <svg width="16" height="17" v-else-if="folder.title == 'Spam'">
        <use xlink:href="#spam_icn"></use>
      </svg>
      <svg width="16" height="16" v-else>
        <use xlink:href="#folderDefault_icn"></use>
      </svg>

      <span class="name"  :title="folder.title">{{folder.title}}</span>
      <span class="unread-mails-count" v-if="folder.unread_mails_count != 0">{{folder.unread_mails_count}}</span>
      <span class="mails-count">{{folder.mails_count}}({{folder.mails_count_in_folder}})</span>
      <button class="menu" v-click-outside="hideSubMenu">
        <svg width="24" height="24" @click.stop="showSubMenu($event)">
          <use xlink:href="#menu_icn"></use>
        </svg>
        <div class="sub-menu" v-if="!folder.is_custom">
          <button class="sub-menu__button" @click.stop="createFolder(folder)">
            <svg width="24" height="24">
              <use xlink:href="#create_folder_icn"></use>
            </svg>
            <span>Create Folder</span>
          </button>
          <button class="sub-menu__button" @click.stop="uploadLetter(folder.id)" v-if="folder.title !== 'Draft'">
            <svg width="24" height="24">
              <use xlink:href="#upload_letter_icn"></use>
            </svg>
            <span>Upload letter</span>
          </button>
        </div>
        <div class="sub-menu" v-else>
          <button class="sub-menu__button" @click.stop="showFolderSettings(folder, false)">
            <svg width="24" height="24">
              <use xlink:href="#edit_name_icn"></use>
            </svg>
            <span>Edit folder</span>
          </button>
          <button class="sub-menu__button" @click.stop="uploadLetter(folder.id)" v-if="folder.title !== 'Draft'">
            <svg width="24" height="24">
              <use xlink:href="#upload_letter_icn"></use>
            </svg>
            <span>Upload letter</span>
          </button>
          <button class="sub-menu__button" @click.stop="deleteFolder(folder.id)">
            <svg width="24" height="24">
              <use xlink:href="#delete_icn"></use>
            </svg>
            <span>Delete</span>
          </button>
          
        </div>
      </button>
    </div>
    <div class="accordeon-content child-folders" v-if="folder.child_folders && (showFoldersIds.indexOf(folder.id) >= 0 || dragModeActive)">
      <ChildFolder
          @dragEnd="$emit('dragEnd')"
        @dragStart="(e)=>{$emit('dragStart', e)}"
        v-for="child_folder in folder.child_folders"
        :key="child_folder.id + 'sdsd'"
        :parent_folder="folder"
        :child_folder="child_folder">
      </ChildFolder>
    </div>
  </div>
</template>

<script>
import ChildFolder from '@/components/mailbox/sidebar/ChildFolder'
  export default {
    name: 'folder',
    props: {
      dragModeActive: Boolean,
      showFoldersIds: Array,
      dragModeFolderActive: Boolean,
      disabledAccountDuringDrop: Boolean,
      folder: {type: Object},
      activeFolder: {type: Number}
    },
    inject: ['setActiveFolder', 'showFolderSettings', 'deleteFolder', 'uploadLetter'],
    components: {ChildFolder},
    methods: {
      mouseUp() {
        if(this.dragModeFolderActive) {
          this.$emit('moveToFolder', this.folder.id)
        }
      },
      showSubMenu(e){
        setTimeout(() => {
          e.target.nextSibling.classList.add('show')
        }, 100)
      },
      hideSubMenu(){
        let submenues = document.querySelectorAll('.sub-menu.show')
        submenues.forEach(element => {
          element.classList.remove('show')
        });
      },
      createFolder(folderInfo){
        this.showFolderSettings(folderInfo)
      }
    },
    computed: {
      isActivefolder(){
        return this.folder.id == this.activeFolder
      },
    }
  }
</script>

<style lang="scss" scoped>
@import "./src/components/mailbox/styles/folders";
.sidebar-row{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  // padding-left: 24px;
  &:hover{
    .sidebar-row-heading{
      background: var(--color-gray-lightest);
    }
  }
  &.active{
    .sidebar-row-heading{
      background: #E5EEFF;
    }
  }
  .sidebar-row-heading{
    height: 28px;
    // padding-left: 8px;
    padding-left: 32px;
    display: flex !important;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
    .name{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 8px;
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // &:hover{
      //   cursor: pointer;
      // }
    }
    .unread-mails-count{
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 6px;
      color: var(--color-white);
      border-radius: 10px;
      background: var(--color-blue);
      margin-left: 4px;
    }
    .mails-count{
      margin-left: auto;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &:hover{
      .menu{
        opacity: 1;
      }
    }
    .menu{
      opacity: 0;
      position: relative;
      margin-left: 8px;
      svg{
        use{
          pointer-events: none;
        }
      }
      &:hover{
        cursor: pointer;
      }
    }
    .sub-menu{
      position: absolute;
      padding: 8px;
      display: none;
      flex-direction: column;
      row-gap: 8px;
      right: 0;
      top: 0;
      background: var(--color-white);
      z-index: 9;
      border-radius: 4px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
      width: fit-content;
      &.show{
        display: flex;
      }
    }
    .sub-menu__button{
      display: flex;
      align-items: center;
      column-gap: 4px;
      span{
        white-space: nowrap;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &:hover{
        span{
          color: var(--color-blue);
        }
      }
    }
  }
}
.drag-block{
  position: relative;
}
.child-folders{
  padding-left: 24px;
}
//.accordeon {
//  position: relative;
//  &::before {
//    content: '';
//    position: absolute;
//    z-index: 40;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//  }
//  &:hover {
//    background-color: black !important;
//  }
//}
</style>