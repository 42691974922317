<template>
  <div class="fs__title">
    <h2 class="fz20 fw500">{{title}}</h2>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style lang="scss">
  .fs__title{
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    h2{
      margin-right: auto;
    }
  }
</style>