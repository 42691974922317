import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/login.vue'
import confirm from '../views/ConfirmationNumber/confirmationNumber'
import employees from '../views/employees/employees'
import addEmployees from "../views/addEmloyees/addEmloyees.vue"
import addEmployees2 from "../views/addEmployeesStep2/addEmployeesStep2"
import employeesPageView from "../views/employees/employeesPageView.vue"
import balticDryIndex from "@/views/balticDryIndex/balticDryIndex";
import addNewIndex from "@/views/addNewIndex/addNewIndex";
import tasks from "@/views/tasks/tasks";
import map from "@/views/map/mapStream";
import location from "@/views/location/location";


import checkListEdit from "@/views/checkListEdit/check-list-edit"
// import tasksArchive from "@/views/tasks-archive/tasks-archive";
import taskFullinfo from "@/views/taskFullinfo/taskFullinfo";
import employeeCard from "@/views/employeeCard/employeeCard";
import changeProfile from "@/views/changeProfile/changeProfile";
import changeRights from "@/views/changeRights/changeRights";
import archiveEmployees from "@/views/archiveEmployees/archiveEmployees";

/* Counterparties */
import counterparties from "@/views/counterparties/counterparties";
import counterpartiesList from "@/views/counterparties/counterpartiesList";
import counterpartiesAdd from "@/views/counterparties/counterpartiesAdd";
import counterpartiesEdit from "@/views/counterparties/counterpartiesEdit";
import counterpartiesUser from "@/views/counterparties/counterpartiesUser";
import counterpartiesArchive from "@/views/counterparties/counterpartiesArchive";
import counterpartiesPaymentAccounts from "@/views/counterparties/counterpartiesPaymentAccounts";
import counterpartiesPaymentAccountsArchive from "@/views/counterparties/counterpartiesPaymentAccountsArchive";
import counterpartiesPayments from "@/views/counterparties/counterpartiesPayments";
import counterpartiesBalance from "@/views/counterparties/counterpartiesBalance";
import counterpartiesActive from "@/views/counterparties/counterpartiesActive";
import counterpartiesClosed from "@/views/counterparties/counterpartiesClosed";

/* Payments */
import paymentCalendar from "@/views/paymentView/paymentCalendar/paymentCalendar";
import paymentInstructions from "@/views/paymentView/paymentInstructions/paymentInstructions";
import paymentList from "@/views/paymentView/paymentList/paymentList";
import paymentView from "@/views/paymentView/paymentView";

/* Accounting */
import accounts from "@/views/accounts/accounts";
import trialBalance from "@/views/accounts/trialBalance";
import trialBalanceArchive from "@/views/accounts/trialBalanceArchive";
import financialStatement from "@/views/accounts/financialStatement";
import bankAndCash from "@/views/accounts/bankAndCash";
import bankAndCashArchive from "@/views/accounts/bankAndCashArchive";
import requisiteArchive from "@/views/accounts/requisiteArchive";
import archivePayment from "@/views/archivePayment/archivePayment";
import paymentDetail from "@/components/paymentDetail/paymentDetail";
import entries from "@/views/entries/entries"

/* Vessels */
import vesselTaskView from "@/views/tasks/vessel-task/vessel-task-view"
import vesselTaskWorkSpace from "@/views/tasks/vessel-task/workspace/vessel-workspace"
import vesselsVies from "@/views/vessels/vessels-view"
import vesselsActual from '@/views/vessels/actual/actual-vessels'
// import vesselRedelivered from '@/views/vessels/redelivery/redelivery-vessels'
import vesselDetailWrap from '@/views/vessels/detail/vesselDetailWrap'
import vesselDetailTabs from '@/views/vessels/detail/vesselDetailTabs'
import vesselDetail from '@/views/vessels/detail/vesselDetail/vesselDetail'
import vesselOwners from '@/views/vessels/detail/vesselOwners'
import editOwners from '@/views/vessels/detail/editOwners'
import historyOwners from '@/views/vessels/detail/historyOwners'
// import vesselTaskDetail from "@/views/tasks/vessel-task/detail/vessel-vessel-detail"
import cashFlowVesselDetail from "@/views/vessels/detail/cashFlow/cashFlowVesselDetail"
import layTime from "@/views/vessels/detail/lay/layTime"
import layTimeDetail from "@/views/vessels/detail/lay/layTimeDetail"
import layTimeList from "@/views/vessels/detail/lay/layTimeList"
import layTimeEdit from "@/views/vessels/detail/lay/layTimeEdit"
import layTimeArchive from "@/views/vessels/detail/lay/layTimeArchive"
import balances from "@/views/vessels/detail/balances"
import vesselsReports from "@/views/vessels/vessels-reports/vessels-reports"
import vesselCharterers from '@/views/vessels/detail/vesselCharterers'
/* Charterers */
import charterer from '@/views/vessels/charterer/charterer'
import chartererDetails from '@/views/vessels/charterer/chartererDetails'
import chartererFreight from '@/views/vessels/charterer/chartererFreight'
import chartererVsoa from '@/views/vessels/charterer/chartererVsoa'
import chartererAdditionalFreight from '@/views/vessels/charterer/chartererAdditionalFreight'
import chartererDetention from '@/views/vessels/charterer/chartererDetention'
//import chartererLay from '@/views/vessels/charterer/chartererLay'
import chartererSoa from '@/views/vessels/charterer/chartererSoa'
import editSoaSublet from '@/views/vessels/detail/sublet/editSoaSublet'
import historySoaSublet from '@/views/vessels/detail/sublet/historySoaSublet'
import editVoyageInvoice from '@/views/vessels/detail/voyage/editVoyageInvoice'
import historyVoyageInvoice from '@/views/vessels/detail/voyage/historyVoyageInvoice'


import cashFlow from "@/views/cashFlow/cashFlow";
import cashFlowDiagram from "@/views/cashFlow/cashFlowDiagram/cashFlowDiagram";
import cashFlowList from "@/views/cashFlow/cashFlowList/cashFlowList";

/* Dashboard */
import dashboard from "@/views/dashboard/dashboard";

/* Notifications */
import notifications from "@/views/notifications/notifications";
import notificationsSettings from "@/views/notifications/settings";

/* Port */
import ports from "@/views/ports/ports"
import portsList from "@/views/ports/portsList"
import portsArchive from "@/views/ports/portsArchive"
import port from "@/views/ports/port"
import portDetail from "@/views/ports/portDetail"
import portBerth from "@/views/ports/portBerth"
import portContacts from "@/views/ports/portContacts"
import portArchive from "@/views/ports/portArchive"
import portArchiveBerth from "@/views/ports/portArchiveBerth"
import portArchiveContacts from "@/views/ports/portArchiveContacts"

/* Calculator */
import calc from "@/views/calc/calc"
import tabDetail from "@/views/calc/tabDetail"
import tabCargo from "@/views/calc/tabCargo"
import tabRoute from "@/views/calc/tabRoute"
import tabVessels from "@/views/calc/tabVessels"
import tabCompare from "@/views/calc/tabCompare"
import shipTemplatePage from "@/views/shipTemplatePage/shipTemplatePage";

/* Calendar */
import calendar from "@/views/calendar/calendar"
import maillingView from "@/views/mailling/mailling-view";
import maillingSent from "@/views/mailling/mailling-sent";
import maillingTemplates from "@/views/mailling/mailling-templates";
import maillingContactsList from "@/views/mailling/mailling-contacts-list";
import locationReadEmails from "@/components/location/location-read-emails/location-read-emails";
import locationEmailsPage from "@/views/location/location-emails-page";
import locationAllEmails from "@/views/location/location-all-emails";
import foldersPage from "@/views/foldersPage/foldersPage";
import foldersMyFiles from "@/components/folders/folders-lists/folders-my-files";
import foldersSharedWithMe from "@/components/folders/folders-lists/folders-shared-with-me";
import foldersTasks from "@/components/folders/folders-lists/folders-tasks";
import foldersPayments from "@/components/folders/folders-lists/folders-payments";
import foldersVessels from "@/components/folders/folders-lists/folders-vessels";
import foldersPorts from "@/components/folders/folders-lists/folders-ports";
import foldersCouterparties from "@/components/folders/folders-lists/folders-couterparties";
import foldersImportant from "@/components/folders/folders-lists/folders-important";
import folderTaskFiles from "@/components/folders/folders-lists/folder-task-files";
import foldersPaymentFiles from "@/components/folders/folders-lists/folders-payment-files";
import foldersVesselsOption from "@/components/folders/folders-lists/folders-vessels-option";
import foldersVesselsOptionCashFlow from "@/components/folders/folders-lists/folders-vessels-option-cash-flow";
import foldersVesselsOptionVesselDetail from "@/components/folders/folders-lists/folders-vessels-option-vessel-detail";
import foldersVesselsOptionOwners from "@/components/folders/folders-lists/folders-vessels-option-owners";
import foldersVesselsOptionCharterers from "@/components/folders/folders-lists/folders-vessels-option-charterers";
import foldersVesselsOptionCharteresFiles
  from "@/components/folders/folders-lists/folders-vessels-option-charteres-files";
import foldersPortsCountriesOptions from "@/components/folders/folders-lists/folders-ports-countries-options";
import foldersPortsCountries from "@/components/folders/folders-lists/folders-ports-countries";
import foldersPortsCountriesOptionsDetails
  from "@/components/folders/folders-lists/folders-ports-countries-options-details";
import foldersPortsCountriesOptionsBerthList
  from "@/components/folders/folders-lists/folders-ports-countries-options-berth-list";
import foldersPortsCountriesOptionsBethListDetail
  from "@/components/folders/folders-lists/folders-ports-countries-options-beth-list-detail";
import foldersPortsCountriesOptionsContacts
  from "@/components/folders/folders-lists/folders-ports-countries-options-contacts";
import foldersPortsCountriesContactsFiles
  from "@/components/folders/folders-lists/folders-ports-countries-contacts-files";
import foldersCounterpartiesFiles from "@/components/folders/folders-lists/folders-counterparties-files";
import foldersPaymentsDetail from "@/components/folders/folders-lists/folders-payments-detail";


// Mailbox
import mailbox from "@/views/mailbox/mailbox"
import foldersVesselsOptionCharterersFilesPath
  from "@/components/folders/folders-lists/folders-vessels-option-charterers-files-path.vue";
import foldersCounterpartiesOptions from "@/components/folders/folders-lists/folders-counterparties-options.vue";
import foldersCounterpartiesOptionsDetail
  from "@/components/folders/folders-lists/folders-counterparties-options-detail.vue";
import foldersCounterpartiesPayments from "@/components/folders/folders-lists/folders-counterparties-payments.vue";
import foldersImportantFiles from "@/components/folders/folders-lists/folders-important-files.vue";
import cashFlowDiagramNew from "@/views/cashFlow/cashFlowDiagramNew/cashFlowDiagramNew.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/phone-number-confirm',
    name: 'phone-number-confirm',
    component: confirm
  },
  {
    path: '/map',
    name: 'map',
    component: map,
  },
  {
    path: '/employees',
    component: employeesPageView,
    children: [
      {
        path: "",
        component: employees,
        name: "employees",
        meta: { title: 'Speed Logistic Marine - Employees' }
      },
      {
        path: "add-employees-step-1",
        component: addEmployees,
        name: 'Add Employee Step 1',
        meta: { title: 'Speed Logistic Marine - Add Employee Step One' }
      },
      {
        path: "add-employees-step-2",
        name: 'Add Employee Step 2',
        component: addEmployees2,
        meta: { title: 'Speed Logistic Marine - Add Employee Step Two' }
      },
      {
        path: "archive",
        component: archiveEmployees,
        meta: { title: 'Speed Logistic Marine - Archive Employees' }
      }
    ]
  },
  {
    path: "/baltic-dry-index",
    name: "Baltic Dry Index",
    component: balticDryIndex,
    meta: { title: 'Speed Logistic Marine - Baltic Dry Index' }
  },
  {
    path: "/baltic-dry-index-test",
    name: "Baltic Dry Index",
    component: balticDryIndex,
    meta: { title: 'Speed Logistic Marine - Baltic Dry Index' }
  },
  {
    path: "/add-new-index",
    name: "Add new index",
    component: addNewIndex,
    meta: { title: 'Speed Logistic Marine - Add New Index' }
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: tasks,
    meta: { title: 'Speed Logistic Marine - Tasks' },
    // children:[{
    //   path: "check-list",
    //   name: "Tasks checklists",
    //   component: checkListEdit,
    //   meta: { title: 'Speed Logistic Marine - Task Checklists' }
    // },]
    // meta: {
    //     access: store.getters.getPermissionsForUserCanSeeTask,
    // }
  },
  {
    path: "/tasks/check-list",
    name: "Tasks checklists",
    component: checkListEdit,
    meta: { title: 'Speed Logistic Marine - Task Checklists' }
  },
  {
    path: "/tasks-archive",
    name: "Archive",
    component: tasks,
    meta: { title: 'Speed Logistic Marine - Tasks Archive' }
  },
  {
    path: "/task/:id",
    name: "Task",
    component: taskFullinfo,
    meta: { title: 'Speed Logistic Marine - Task' }
  },
  {
    path: "/vessel-task/:id",
    name: "Vessel Task",
    component: vesselTaskView,
    children: [
      {
        path: "workspace",
        name: "Vessel Workspace",
        component: vesselTaskWorkSpace,
        meta: { title: 'Speed Logistic Marine - Vessel Workspace' }
      },
      {
        path: "detail",
        name: "Vessel Detail",
        component: taskFullinfo,
        meta: { title: 'Speed Logistic Marine - Vessel Detail' }
      },
    ]
  },
  {
    path: "/employee-card/:id",
    name: "Employee card",
    component: employeeCard,
    meta: { title: 'Speed Logistic Marine - Employee' }
  },
  {
    path: "/employee-card/change-profile/:id",
    name: "Change Profile",
    component: changeProfile,
    meta: { title: 'Speed Logistic Marine - Change Profile' }
  },
  {
    path: "/employee-card/change-rights/:id",
    name: "Change Rights",
    component: changeRights,
    meta: { title: 'Speed Logistic Marine - Change Rights' }
  },
  {
    path: "/counterparties",
    component: counterparties,
    children: [
      {
        path: '',
        name: "Сounterparties",
        component: counterpartiesList,
        meta: { title: 'Speed Logistic Marine - Сounterparties' }
      },
      {
        path: 'archive',
        component: counterpartiesArchive,
        meta: { title: 'Speed Logistic Marine - Сounterparties Archive' }
      },
      {
        path: 'add-counterparties',
        component: counterpartiesAdd,
        meta: { title: 'Speed Logistic Marine - Add Сounterparties' }
      },
      {
        path: ':id/payment-accounts-archive',
        component: counterpartiesPaymentAccountsArchive,
        meta: { title: 'Speed Logistic Marine - Payment Accounts Archive' }
      },
      {
        path: ':id/payment/:idPayment/detail',
        name: 'Counterparties Payment Detail',
        component: paymentDetail,
        meta: { title: 'Speed Logistic Marine - Сounterparties Payment Detail' }
      },
      {
        path: ':id',
        name: "Counterparties",
        redirect: ':id/payments',
        component: counterpartiesUser,
        children: [
          {
            path: 'active',
            component: counterpartiesActive,
            meta: { title: 'Speed Logistic Marine - Сounterparties Active' }
          },
          {
            path: 'closed',
            component: counterpartiesClosed,
            meta: { title: 'Speed Logistic Marine - Сounterparties Closed' }
          },
          {
            path: 'payments',
            name: 'Counterparties payments',
            component: counterpartiesPayments,
            meta: { title: 'Speed Logistic Marine - Сounterparties Payments' }
          },
          {
            path: 'payment-accounts',
            name: 'Counterparties payment accounts',
            component: counterpartiesPaymentAccounts,
            meta: { title: 'Speed Logistic Marine - Сounterparties Payments Account' }
          },
          {
            path: 'balances',
            name: 'Counterparties balances',
            component: counterpartiesBalance,
            meta: {
              title: 'Speed Logistic Marine - Сounterparties Balances',
              isBalances: true
            }
          },
        ]
      },
      {
        name: 'Edit Counterparties',
        path: 'edit-counterparties/:id',
        component: counterpartiesEdit,
        meta: { title: 'Speed Logistic Marine - Edit Сounterparties' }
      }
    ]
  },
  {
    path: '/accounts/trial-balance/archive',
    component: trialBalanceArchive,
    meta: { title: 'Speed Logistic Marine - Trial Balance Archive' }
  },
  {
    path: '/accounts/bank-and-cash/archive',
    component: bankAndCashArchive,
    meta: { title: 'Speed Logistic Marine - Bank And Cash Archive' }
  },
  {
    path: '/accounts/bank-and-cash/archive/:id',
    component: requisiteArchive,
    meta: { title: 'Speed Logistic Marine - Requisite Archive' }
  },
  {
    path: '/accounts/entries/archive',
    component: entries,
    meta: {
      title: 'Speed Logistic Marine - Archive Journal Entries',
      isArchive: true
    }
  },
  {
    path: "/accounts",
    redirect: '/accounts/trial-balance',
    component: accounts,
    children: [
      {
        path: 'trial-balance',
        component: trialBalance,
        meta: { title: 'Speed Logistic Marine - Trial Balance' }
      },
      {
        path: 'fs',
        component: financialStatement,
        meta: { title: 'Speed Logistic Marine - Financial Statement' }
      },
      {
        path: 'bank-and-cash',
        component: bankAndCash,
        meta: { title: 'Speed Logistic Marine - Bank And Cash Accounts' }
      },
      {
        path: 'entries',
        component: entries,
        meta: { title: 'Speed Logistic Marine - Journal Entries' },
      }
    ]
  },
  {
    path: "/payment",
    component: paymentView,
    children: [
      {
        path: 'calendar',
        name: "Payment Calendar",
        component: paymentCalendar,
        meta: { title: 'Speed Logistic Marine - Payment Calendar' }
      },
      {
        path: 'calendar/detail/:id',
        name: "Payment Calendar Detail",
        component: paymentDetail,
        meta: { title: 'Speed Logistic Marine - Payment Detail' }
      },
      {
        path: 'list',
        name: "Payment List",
        component: paymentList,
        meta: { title: 'Speed Logistic Marine - Payment List' },
      },
      {
        path: 'list/detail/:id',
        name: "Payment List Detail",
        component: paymentDetail,
        meta: { title: 'Speed Logistic Marine - Payment Detail' }
      },
      {
        path: 'archive',
        name: "Archive Payments",
        component: archivePayment,
        meta: { title: 'Speed Logistic Marine - Payments Archive' }
      },
      {
        path: 'instructions',
        name: "Payment Instructions",
        component: paymentInstructions,
        meta: { title: 'Speed Logistic Marine - Payments Instructions' }
      },
      {
        path: 'instructions/detail/:id',
        name: "Payment Instructions Detail",
        component: paymentDetail,
        meta: { title: 'Speed Logistic Marine - Payment Detail' }
      },
      {
        path: 'detail/:id',
        name: "Payment Detail",
        component: paymentDetail,
        meta: { title: 'Speed Logistic Marine - Payment Detail' }
      }
    ]
  },
  {
    path: "/cashflow",
    component: cashFlow,
    children: [
      {
        path: 'list',
        name: 'CashFlow List',
        component: cashFlowList,
        meta: { title: 'Speed Logistic Marine - CashFlow List' }
      },
      {
        path: 'new-diagram',
        name: 'CashFlow Diagram New',
        component: cashFlowDiagramNew,
        meta: { title: 'Speed Logistic Marine - CashFlow Diagram' },
      },
      {
        path: 'new-diagram/archive',
        name: 'CashFlow Diagram New Archive',
        component: cashFlowDiagramNew,
        meta: { title: 'Speed Logistic Marine - CashFlow Diagram' },
      },
      {
        path: 'diagram',
        name: 'CashFlow Diagram',
        component: cashFlowDiagram,
        meta: { title: 'Speed Logistic Marine - CashFlow Projection' },
      },
      {
        name: 'CashFlow Diagram Archive',
        path: 'diagram/archive',
        component: cashFlowDiagram,
        meta: { title: 'Speed Logistic Marine - CashFlow Projection Archive' }
      },
      {
        path: 'detail/:id',
        component: paymentDetail,
        meta: { title: 'Speed Logistic Marine - CashFlow Detail' }
      },
      {
        name: 'CashFlow Diagram detail',
        path: 'diagram/detail/:id',
        component: paymentDetail,
        meta: { title: 'Speed Logistic Marine - CashFlow Projection Detail' }
      },
      {
        name: 'CashFlow Diagram New detail',
        path: 'new-diagram/detail/:id',
        component: paymentDetail,
        meta: { title: 'Speed Logistic Marine - CashFlow Projection Detail' }
      },

      {
        path: 'list/archive',
        component: archivePayment,
        name: 'Cash Flow List Archive',
        meta: { title: 'Speed Logistic Marine - CashFlow List Archive' }
      },
    ]
  },
  {
    path: "/vessels",
    name: "Vessels",
    redirect: '/vessels/actual',
    component: vesselsVies,
    children: [
      {
        path: 'actual',
        name: 'Vessels Actual',
        component: vesselsActual,
        meta: { title: 'Speed Logistic Marine - Actual Vessels' }
      },
      {
        path: 'actual/archive',
        name: '',
        component: vesselsActual,
        meta: { title: 'Speed Logistic Marine - Actual Vessels Archive' }
      },
      {
        path: 'redelivered',
        name: 'Vessels Redelivered',
        component: vesselsActual,
        meta: { title: 'Speed Logistic Marine - Redelivered Vessels' }
      },
      {
        path: 'redelivered/archive',
        name: 'Vessels Redelivered Archive',
        component: vesselsActual,
        meta: { title: 'Speed Logistic Marine - Redelivered Vessels Archive' }
      }
    ]
  },
  {
    path: '/vessels/:id',
    component: vesselDetailWrap,
    redirect: '/vessels/:id/open',
    children: [
      {
        path: 'open',
        component: vesselDetailTabs,
        redirect: '/vessels/:id/open/detail',
        children: [
          {
            path: 'detail',
            name: "Detail Vessel",
            component: vesselDetail,
            meta: { title: 'Speed Logistic Marine - Vessel Detail' }
          },
          {
            name: "vesselOwners",
            path: 'owners',
            component: vesselOwners,
            meta: { title: 'Speed Logistic Marine - Vessel Owners' }
          },
          {
            name: "editOwners",
            path: 'owners/edit',
            component: editOwners,
            meta: { title: 'Speed Logistic Marine - Vessel Edit Owners' }
          },
          {
            path: 'charterers',
            component: vesselCharterers,
            meta: { title: 'Speed Logistic Marine - Vessel Charterers' },
            children: [
              {
                path: ':subId',
                component: charterer,
                redirect: '/vessels/:id/open/charterers/:subId/details',
                children: [
                  {
                    path: 'details',
                    component: chartererDetails,
                    meta: { title: 'Speed Logistic Marine - Charterer Details' }
                  },
                  {
                    path: 'freight',
                    component: chartererFreight,
                    meta: { 
                      title: 'Speed Logistic Marine - Freight Invoice',
                      invoiceType: 'Freight Invoice' 
                    },
                  },
                  {
                    path: 'freight/edit',
                    component: editVoyageInvoice,
                    meta: { 
                      title: 'Speed Logistic Marine - Edit Freight Invoice',
                      invoiceType: 'Freight Invoice'
                    }
                  },
                  {
                    path: 'vsoa',
                    component: chartererVsoa,
                    meta: { 
                      title: 'Speed Logistic Marine - Voyage Statement of Account',
                      invoiceType: 'Voyage Statement of Account'
                    }
                  },
                  {
                    path: 'vsoa/edit',
                    component: editVoyageInvoice,
                    meta: { 
                      title: 'Speed Logistic Marine - Edit Voyage Statement of Account',
                      invoiceType: 'Voyage Statement of Account'
                    }
                  },
                  {
                    path: 'additional_freight',
                    component: chartererAdditionalFreight,
                    meta: { 
                      title: 'Speed Logistic Marine - Additional Freight Invoice',
                      invoiceType: 'Additional Freight Invoice'
                    }
                  },
                  {
                    path: 'additional_freight/edit',
                    component: editVoyageInvoice,
                    meta: { 
                      title: 'Speed Logistic Marine - Edit Additional Freight Invoice',
                      invoiceType: 'Additional Freight Invoice'
                    }
                  },
                  {
                    path: 'detention',
                    component: chartererDetention,
                    meta: { 
                      title: 'Speed Logistic Marine - Detention Invoice',
                      invoiceType: 'Detention Invoice'
                    }
                  },
                  {
                    path: 'detention/edit',
                    component: editVoyageInvoice,
                    meta: { 
                      title: 'Speed Logistic Marine - Edit Detention Invoice',
                      invoiceType: 'Detention Invoice'
                    }
                  },
                  {
                    path: 'lay',
                    component: layTime,
                    redirect: 'lay/list',
                    children: [
                      {
                        path: 'list',
                        component: layTimeList,
                        meta: { 
                          title: 'Speed Logistic Marine - Charter LayTime',
                          isCharter: true
                        },
                      },
                      {
                        path: 'details',
                        component: layTimeDetail,
                        meta: { 
                          title: 'Speed Logistic Marine - Charter LayTime',
                          isCharter: true
                        },
                      }
                    ]
                  },
                  {
                    path: 'lay/:layId/edit',
                    component: layTimeEdit,
                    meta: { 
                      title: 'Speed Logistic Marine - Charter LayTime Edit',
                      isCharter: true
                    }
                  },
                  {
                    path: 'soa',
                    component: chartererSoa,
                    meta: { title: 'Speed Logistic Marine - SOA' }
                  },
                  {
                    path: 'soa/edit',
                    component: editSoaSublet,
                    meta: { title: 'Speed Logistic Marine - Edit SOA Sublet' }
                  },
                ]
              }
            ]
          },
          {
            path: 'cash-flow',
            name: "Vessels CashFlow",
            component: cashFlowVesselDetail,
            meta: { title: 'Speed Logistic Marine - Vessel CashFlow' }
          },
          {
            name: "Archive Vessels CashFlow",
            path: 'cash-flow/archive',
            component: cashFlowVesselDetail,
            meta: { title: 'Speed Logistic Marine - Vessel CashFlow Archive' }
          },
          {
            path: 'lay',
            component: layTime,
            redirect: 'lay/list',
            children: [
              {
                path: 'list',
                component: layTimeList,
                meta: { title: 'Speed Logistic Marine - Vessel LayTime' },
              },
              {
                path: 'details',
                component: layTimeDetail,
                meta: { title: 'Speed Logistic Marine - Vessel LayTime' },
              }
            ]
          },
          {
            path: 'cash-flow',
            name: "Vessel Cash-Flow",
            component: cashFlowVesselDetail,
            meta: { title: 'Speed Logistic Marine - Vessel Voyage Chrts' }
          },
          {
            name: "Detail Cash Flow",
            path: 'cash-flow/:paymentCashFlowID/detail',
            component: paymentDetail,
            meta: { title: 'Speed Logistic Marine - Vessel CashFlow Detail' }
          },
          {
            name: "Accounting Invoice",
            path: 'accounting-invoice',
            component: cashFlowVesselDetail,
            meta: { title: 'Speed Logistic Marine - Vessel Accounting Invoice' }
          },
          {
            name: "Archive Accounting Invoice",
            path: 'accounting-invoice/archive',
            component: cashFlowVesselDetail,
            meta: { title: 'Speed Logistic Marine - Vessel Accounting Invoice Archive' }
          },
          {
            name: "Detail Accounting Invoice",
            path: 'accounting-invoice/:paymentAccInvID/detail',
            component: paymentDetail,
            meta: { title: 'Speed Logistic Marine - Vessel Accounting Invoice Detail' }
          },
          {
            name: "Reports",
            path: "reports",
            component: vesselsReports,
            meta: { title: 'Speed Logistic Marine - Vessel Reports' }
          },
          {
            path: "reports/archive",
            component: vesselsReports,
            meta: { title: 'Speed Logistic Marine - Vessel Reports Archive' }
          },
          {
            path: 'balances',
            component: balances,
            meta: {
              title: 'Speed Logistic Marine - Vessel Balances',
              isBalances: true
            }
          },
          {
            name: "Vessel Mailing",
            path: "mailing",
            component: locationReadEmails,
            meta: { title: 'Speed Logistic Marine - Vessel Reports' }
          },
          {
            path: 'entries',
            component: entries,
            meta: {
              title: 'Speed Logistic Marine - Vessel Journal Entries',
              isVesselPage: true
            }
          }
        ]
      },
      {
        path: 'owners/history',
        component: historyOwners,
        meta: { title: 'Speed Logistic Marine - Vessel History Owners' }
      },
      {
        path: 'lay/:layId/edit',
        component: layTimeEdit,
        meta: { title: 'Speed Logistic Marine - Vessel LayTime Edit' }
      },
      {
        path: 'lay/archive',
        component: layTimeArchive,
        meta: { title: 'Speed Logistic Marine - Vessel LayTime Archive' }
      },
      {
        path: 'charterers/:subId/soa/history',
        component: historySoaSublet,
        meta: { title: 'Speed Logistic Marine - Vessel History SOA Sublet' }
      },
      {
        path: 'charterers/:subId/invoices/freight/history',
        component: historyVoyageInvoice,
        meta: { 
          title: 'Speed Logistic Marine - Vessel History Voyage Invoice',
          invoiceType: 'Freight Invoice'
        }
      },
      {
        path: 'charterers/:subId/invoices/vsoa/history',
        component: historyVoyageInvoice,
        meta: { 
          title: 'Speed Logistic Marine - Vessel History Voyage Invoice',
          invoiceType: 'Voyage Statement of Account'
        }
      },
      {
        path: 'charterers/:subId/invoices/additional_freight/history',
        component: historyVoyageInvoice,
        meta: { 
          title: 'Speed Logistic Marine - Vessel History Voyage Invoice',
          invoiceType: 'Additional Freight Invoice'
        }
      },
      {
        path: 'charterers/:subId/invoices/detention/history',
        component: historyVoyageInvoice,
        meta: { 
          title: 'Speed Logistic Marine - Vessel History Voyage Invoice',
          invoiceType: 'Detention Invoice'
        }
      },
      {
        path: 'entries/archive',
        component: entries,
        meta: {
          title: 'Speed Logistic Marine - Vessel Journal Entries Archive',
          isVesselPage: true,
          isArchive: true
        }
      }
    ]
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: dashboard,
    meta: { title: 'Speed Logistic Marine - Dashboard' }
  },
  {
    name: 'Notifications',
    path: '/notifications',
    component: notifications,
    meta: { title: 'Speed Logistic Marine - Notifications' }
  },
  {
    name: 'Notifications settings',
    path: '/notifications/settings',
    component: notificationsSettings,
    meta: { title: 'Speed Logistic Marine - Notifications Settings' }
  },
  {
    path: '/ports',
    component: ports,
    children: [
      {
        path: '',
        component: portsList,
        name: 'ports_list',
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'archive',
        name: 'ports-archive',
        component: portsArchive,
        meta: { title: 'Speed Logistic Marine - Ports archive' }
      },
      {
        path: ':id',
        component: port,
        name: 'port',
        redirect: ':id/detail',
        meta: { title: 'Speed Logistic Marine - Port' },
        children: [
          {
            path: 'detail',
            meta: { title: 'Speed Logistic Marine - Port detail' },
            component: portDetail,
          },
          {
            path: 'berth',
            meta: { title: 'Speed Logistic Marine - Port Berths' },
            component: portBerth,
          },
          {
            path: 'contacts',
            meta: { title: 'Speed Logistic Marine - Port Contacts' },
            component: portContacts,
          },
          {
            path: 'emails',
            meta: { title: 'Speed Logistic Marine - Port Emails' },
            component: locationReadEmails,
          },
        ]
      },
      {
        path: ':id/archive',
        component: portArchive,
        redirect: ':id/archive/berth',
        children: [
          {
            path: 'berth',
            name: 'port-archive-berths',
            meta: { title: 'Speed Logistic Marine - Port archive Berths' },
            component: portArchiveBerth,
          },
          {
            path: 'contacts',
            name: 'port-archive-contacts',
            meta: { title: 'Speed Logistic Marine - Port archive Contacts' },
            component: portArchiveContacts,
          }
        ]
      },
    ]
  },
  {
    path: '/calculator',
    component: calc,
    name: 'Calculator',
    meta: { title: 'Speed Logistic Marine - Calculator' },
    children: [
      {
        path: ':id',
        component: tabDetail,
        redirect: ':id/vessels',
        children: [
          {
            path: 'route',
            component: tabRoute,
            meta: { title: 'Speed Logistic Marine - Calculator Route' },
          },
          {
            path: 'cargo',
            component: tabCargo,
            meta: { title: 'Speed Logistic Marine - Calculator Cargo' },
          },
          {
            path: 'vessels',
            component: tabVessels,
            meta: { title: 'Speed Logistic Marine - Calculator Vessels' },
          },
          {
            path: 'compare',
            component: tabCompare,
            meta: { title: 'Speed Logistic Marine - Calculator Compare' },
          },
        ]
      },
    ]
  },
  {
    path: '/calculator-archive',
    component: calc,
    meta: { title: 'Speed Logistic Marine - Calculator Archive' },
    name: 'Calculator Archive',
    children: [
      {
        path: ':id',
        component: tabDetail,
        redirect: ':id/route',
        children: [
          {
            path: 'route',
            component: tabRoute,
            meta: { title: 'Speed Logistic Marine - Calculator Route' },
          },
          {
            path: 'cargo',
            component: tabCargo,
            meta: {title: 'Speed Logistic Marine - Calculator Cargo'},
          },
          {
            path: 'vessels',
            component: tabVessels,
            meta: {title: 'Speed Logistic Marine - Calculator Vessels'},
          },
          {
            path: 'compare',
            component: tabCompare,
            meta: {title: 'Speed Logistic Marine - Calculator Compare'},
          },
        ]
      }
    ]
  },
  {
    path: '/parsed-vessels/:idParsedVessel',
    meta: { title: 'Speed Logistic Marine - Parsed Vessels' },
    name: 'Parsed Vessels',
    component: shipTemplatePage,
  },
  {
    path: '/parsed-vessels/create',
    meta: { title: 'Speed Logistic Marine - Parsed Vessels' },
    name: 'Create Parsed Vessels',
    component: shipTemplatePage,
  },
  {
    path: '/evnts-calendar',
    component: calendar,
    name: 'EventsCalendar',
    meta: { title: 'Speed Logistic Marine - Calendar' },
    children: [
      {
        path: 'day',
        name: 'calendar_day',
        component: () => import('@/views/calendar/day.vue'),
      },
      {
        path: 'week',
        name: 'calendar_week',
        component: () => import('@/views/calendar/week.vue'),
      },
      {
        path: 'month',
        name: 'calendar_month',
        component: () => import('@/views/calendar/month.vue'),
      },
    ]
  },
  {
    path: "/mailling",
    name: "Mailling",
    redirect: '/mailling/sent',
    component: maillingView,
    children: [
      {
        path: 'sent',
        name: 'Mailling Sent',
        component: maillingSent,
        meta: { title: 'Speed Logistic Marine - Mailling Sent' }
      },
      {
        path: 'sent/archive',
        name: 'Mailling Sent Archive',
        component: maillingSent,
        meta: { title: 'Speed Logistic Marine - Mailling Sent Archive' }
      },
      {
        path: 'templates',
        name: 'Mailling Templates',
        component: maillingTemplates,
        meta: { title: 'Speed Logistic Marine - Mailling Templates' }
      },
      {
        path: 'templates/archive',
        name: 'Mailling Templates Archive',
        component: maillingTemplates,
        meta: { title: 'Speed Logistic Marine - Mailling Templates' }
      },
      {
        path: 'contacts',
        name: 'Mailling Contacts List',
        component: maillingContactsList,
        meta: { title: 'Speed Logistic Marine - Contacts List' }
      },
      {
        path: 'contacts/archive',
        name: 'Mailling Contacts List Archive',
        component: maillingContactsList,
        meta: { title: 'Speed Logistic Marine - Contacts List' }
      },

    ],
  },
  {
    path: '/location',
    name: 'Location',
    component: location,
    meta: { title: 'Speed Logistic Marine - Location' }
  },
  {
    path: '/location/:id/mails',
    name: 'Location Mails Vessel',
    component: locationReadEmails,
    meta: { title: 'Speed Logistic Marine - Location Mails Vessel' }
  },
  {
    path: '/location/emails',
    name: 'Location Emails Vessel',
    component: locationEmailsPage,
    meta: { title: 'Speed Logistic Marine - Location Emails Vessel' }
  },
  {
    path: '/location/emails/all',
    name: 'Location Emails Vessel All',
    component: locationAllEmails,
    meta: { title: 'Speed Logistic Marine - Location Emails Vessel All' }
  },
  {
    path: '/folders',
    name: 'Folders',
    component: foldersPage,
    redirect: '/folders/my-files',
    children: [
      {
        path: 'my-files',
        name: 'Folders - My files',
        component: foldersMyFiles,
        meta: { title: 'Speed Logistic Marine - Folders My Files' },
      },
      {
        path: 'my-files/archive',
        name: 'Folders - My files Archive',
        component: foldersMyFiles,
        meta: { title: 'Speed Logistic Marine - Folders My Files' },
      },
      {
        path: 'my-files/:path',
        name: 'Folders - My files Folder',
        component: foldersMyFiles,
        meta: { title: 'Speed Logistic Marine - Folders My Files' },
      },
      {
        path: 'my-files/archive/:path',
        name: 'Folders - My files Folder Archive',
        component: foldersMyFiles,
        meta: { title: 'Speed Logistic Marine - Folders My Files' },
      },
      {
        path: 'shared-with-me',
        name: 'Folders - Shared with me',
        component: foldersSharedWithMe,
        meta: { title: 'Speed Logistic Marine - Shared with me' }
      },
      {
        path: 'shared-with-me/:path',
        name: 'Folders - Shared with me Folder',
        component: foldersSharedWithMe,
        meta: { title: 'Speed Logistic Marine - Shared with me' }
      },
      {
        path: 'shared-with-me/archive',
        name: 'Folders - Shared with me Archive',
        component: foldersSharedWithMe,
        meta: { title: 'Speed Logistic Marine - Shared with me' }
      },
      {
        path: 'shared-with-me/archive/:path',
        name: 'Folders - Shared with me Folder Archive',
        component: foldersSharedWithMe,
        meta: { title: 'Speed Logistic Marine - Shared with me' }
      },
      {
        path: 'tasks',
        name: 'Folders - Tasks',
        component: foldersTasks,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a',
        name: 'Folders - Tasks Archive',
        component: foldersTasks,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/manually/:detailData/folders',
        name: 'Folders - Tasks Archive Manually Files',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/manually/:detailData/folders/:path',
        name: 'Folders - Tasks Archive Manually Files Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/manually/:detailData/folders-archive',
        name: 'Folders - Tasks Archive Manually Files Archive',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/manually/:detailData/folders/:path/archive',
        name: 'Folders - Tasks Archive Manually Files Archive Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/vessel/:detailData/folders',
        name: 'Folders - Tasks Archive Vessel Files',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/vessel/:detailData/folders/:path',
        name: 'Folders - Tasks Archive Vessel Files Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/vessel/:detailData/folders-archive',
        name: 'Folders - Tasks Archive Vessel Files Archive',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/vessel/:detailData/folders/:path/archive',
        name: 'Folders - Tasks Archive Vessel Files Archive Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/cargo/:detailData/folders',
        name: 'Folders - Tasks Archive Cargo Files',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/cargo/:detailData/folders/:path',
        name: 'Folders - Tasks Archive Cargo Files Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/cargo/:detailData/folders-archive',
        name: 'Folders - Tasks Archive Cargo Files Archive',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks-a/cargo/:detailData/folders/:path/archive',
        name: 'Folders - Tasks Archive Cargo Files Archive Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/manually/:detailData/folders',
        name: 'Folders - Tasks Manually Files',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/manually/:detailData/folders-archive',
        name: 'Folders - Tasks Manually Files Archive',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/manually/:detailData/folders/:path/archive',
        name: 'Folders - Tasks Manually Files Archive Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/manually/:detailData/folders/:path',
        name: 'Folders - Tasks Manually Files Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/vessel/:detailData/folders',
        name: 'Folders - Tasks Vessel Files',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/vessel/:detailData/folders-archive',
        name: 'Folders - Tasks Vessel Files Archive',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/vessel/:detailData/folders/:path/archive',
        name: 'Folders - Tasks Vessel Files Archive Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/vessel/:detailData/folders/:path',
        name: 'Folders - Tasks Vessel Files Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/cargo/:detailData/folders',
        name: 'Folders - Tasks Cargo Files',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/cargo/:detailData/folders-archive',
        name: 'Folders - Tasks Cargo Files Archive',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/cargo/:detailData/folders/:path/archive',
        name: 'Folders - Tasks Cargo Files Archive Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'tasks/cargo/:detailData/folders/:path',
        name: 'Folders - Tasks Cargo Files Path',
        component: folderTaskFiles,
        meta: { title: 'Speed Logistic Marine - Tasks' }
      },
      {
        path: 'payments',
        name: 'Folders - Payments',
        component: foldersPayments,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'payment/:detailData',
        name: 'Folders - Payments Folders',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'payment/:detailData/folders/:path',
        name: 'Folders - Payments Folders Path',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'a-payment/:detailData',
        name: 'Folders - Payments Folders Archive',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'a-payment/:detailData/folders/:path',
        name: 'Folders - Payments Folders Path Archive',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'payment/:detailData/folders/archive/:path',
        name: 'Folders - Simple payment and archive folders - path',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'a-payment/:detailData/folders/archive/:path',
        name: 'Folders - Payments Archive Folders Path Archive',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'payment/:detailData/archive/',
        name: 'Folders - Payments Folders Archive Path',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'a-payment/:detailData/archive/',
        name: 'Folders - Payments Folders Archive Path Archive',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'payments/archive',
        name: 'Folders - Payments Archive',
        component: foldersPayments,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'payments/:id/files',
        name: 'Folders - Payments File',
        component: foldersPaymentFiles,
        meta: { title: 'Speed Logistic Marine - Payments' }
      },
      {
        path: 'vessels/:vessels_is_archive',
        name: 'Folders - Vessels',
        component: foldersVessels,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/options',
        name: 'Folders - Vessels Options',
        component: foldersVesselsOption,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/owners/:ownersDetail/:owners_is_archive',
        name: 'Folders - Vessels Option Owners',
        component: foldersVesselsOptionOwners,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/charterers/:charterers_is_archive',
        name: 'Folders - Vessels Option Charterers',
        component: foldersVesselsOptionCharterers,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/charterers/:charterers_is_archive/:charterersDetail/files/:files_is_archive',
        name: 'Folders - Vessels Option Charterers Files',
        component: foldersVesselsOptionCharteresFiles,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/charterers/:charterers_is_archive/:charterersDetail/files/:files_is_archive/:path',
        name: 'Folders - Vessels Option Charterers Files Path',
        component: foldersVesselsOptionCharterersFilesPath,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/vessel-detail/:files_is_archive',
        name: 'Folders - Vessels Option Vessel Detail',
        component: foldersVesselsOptionVesselDetail,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/vessel-detail/:files_is_archive/:path',
        name: 'Folders - Vessels Option Vessel Detail Path',
        component: foldersVesselsOptionVesselDetail,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/cash-flow/:cash_flow_is_archive',
        name: 'Folders - Vessels Option CashFlow',
        component: foldersVesselsOptionCashFlow,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/cash-flow/:cash_flow_is_archive/:detailData/files/:files_is_archive',
        name: 'Folders - Vessels Option CashFlow Files',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/cash-flow/:cash_flow_is_archive/:detailData/files/:files_is_archive/:path',
        name: 'Folders - Vessels Option CashFlow Files Path',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/acc-invoice/:cash_flow_is_archive',
        name: 'Folders - Vessels Option Acc Invoice',
        component: foldersVesselsOptionCashFlow,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/acc-invoice/:cash_flow_is_archive/:detailData/files/:files_is_archive',
        name: 'Folders - Vessels Option Acc Invoice File',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'vessels/:vessels_is_archive/:detailVessel/option/acc-invoice/:cash_flow_is_archive/:detailData/files/:files_is_archive/:path',
        name: 'Folders - Vessels Option Acc Invoice Files Path',
        component: foldersPaymentsDetail,
        meta: { title: 'Speed Logistic Marine - Vessels' }
      },
      {
        path: 'countries/:countries_is_archive',
        name: 'Folders - Ports',
        component: foldersPorts,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'countries/:countries_is_archive/:countryDetail/ports',
        name: 'Folders - Ports Countries Ports',
        component: foldersPortsCountries,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'countries/:countries_is_archive/:countryDetail/port/:portDetail/options',
        name: 'Folders - Ports Countries Ports Options',
        component: foldersPortsCountriesOptions,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'countries/:countries_is_archive/:countryDetail/port/:portDetail/options/details/:is_archive_files',
        name: 'Folders - Ports Countries Ports Options Details',
        component: foldersPortsCountriesOptionsDetails,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'countries/:countries_is_archive/:countryDetail/port/:portDetail/options/details/:is_archive_files/:path',
        name: 'Folders - Ports Countries Ports Options Details Path',
        component: foldersPortsCountriesOptionsDetails,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'countries/:countries_is_archive/:countryDetail/port/:portDetail/options/berth-list/:is_archive_berth_list',
        name: 'Folders - Ports Countries Ports Options Berth List',
        component: foldersPortsCountriesOptionsBerthList,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'countries/:countries_is_archive/:countryDetail/port/:portDetail/options/berth-list/:is_archive_berth_list/:detailList/detail/:is_archive_files',
        name: 'Folders - Ports Countries Ports Options Berth List Detail',
        component: foldersPortsCountriesOptionsBethListDetail,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'countries/:countries_is_archive/:countryDetail/port/:portDetail/options/contacts/:is_archive_contacts',
        name: 'Folders - Ports Countries Ports Options Contacts',
        component: foldersPortsCountriesOptionsContacts,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'countries/:countries_is_archive/:countryDetail/port/:portDetail/options/contact/:is_archive_contacts/:detailContact/files/:is_archive_files',
        name: 'Folders - Ports Countries Ports Options Contact Files',
        component: foldersPortsCountriesContactsFiles,
        meta: { title: 'Speed Logistic Marine - Ports' }
      },
      {
        path: 'counterparties/:is_archive_counterparties',
        name: 'Folders - Counterparties',
        component: foldersCouterparties,
        meta: { title: 'Speed Logistic Marine - Counterparties' }
      },
      {
        path: 'counterparties/:is_archive_counterparties/:detailCounterparties/options',
        name: 'Folders - Counterparties Options',
        component: foldersCounterpartiesOptions,
        meta: { title: 'Speed Logistic Marine - Counterparties' }
      },
      {
        path: 'counterparties/:is_archive_counterparties/:detailCounterparties/options/detail/:is_archive_files',
        name: 'Folders - Counterparties Options Detail',
        component: foldersCounterpartiesOptionsDetail,
        meta: { title: 'Speed Logistic Marine - Counterparties' }
      },
      {
        path: 'counterparties/:is_archive_counterparties/:detailCounterparties/options/detail/:is_archive_files/:path',
        name: 'Folders - Counterparties Options Detail Path',
        component: foldersCounterpartiesOptionsDetail,
        meta: { title: 'Speed Logistic Marine - Counterparties' }
      },
      {
        path: 'counterparties/:is_archive_counterparties/:detailCounterparties/options/payments/:is_archive_payments',
        name: 'Folders - Counterparties Options Payment',
        component: foldersCounterpartiesPayments,
        meta: { title: 'Speed Logistic Marine - Counterparties' }
      },
      {
        path: 'counterparties/:is_archive_counterparties/:detailCounterparties/options/payments/:is_archive_payments/:detailData/files/:is_archive_files',
        name: 'Folders - Counterparties Options Payment Detail',
        component: foldersCounterpartiesFiles,
        meta: { title: 'Speed Logistic Marine - Counterparties' }
      },
      {
        path: 'counterparties/:is_archive_counterparties/:detailCounterparties/options/payments/:is_archive_payments/:detailData/files/:is_archive_files/:path',
        name: 'Folders - Counterparties Options Payment Detail Path',
        component: foldersCounterpartiesFiles,
        meta: { title: 'Speed Logistic Marine - Counterparties' }
      },
      {
        path: 'important/:is_archive_important',
        name: 'Folders - Important',
        component: foldersImportant,
        meta: { title: 'Speed Logistic Marine - Important' }
      },
      {
        path: 'important/:is_archive_important',
        name: 'Folders - Important',
        component: foldersImportant,
        meta: { title: 'Speed Logistic Marine - Important' }
      },
      {
        path: 'important/:is_archive_important/:path',
        name: 'Folders - Important Path',
        component: foldersImportantFiles,
        meta: { title: 'Speed Logistic Marine - Important' }
      },
    ]
  },
  {
    path: "/mailbox",
    name: "Mailbox",
    component: mailbox,
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes
});

const DEFAULT_TITLE = 'Speed Logistic Marine';
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

// router.afterEach((to) => {
//     if(to.name !== "/" && to.name !== "login" && to.name !== "phone-number-confirm") {
//         axios.post();
//         let socket;
//         let url = 'ws://134.209.119.20:8080/'
//         socket = new WebSocket(url);
//         socket.onopen = function() {
//             alert("Соединение установлено.");
//         };
//
//
//     }
// })

export default router
