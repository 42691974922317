import axios from 'axios'
import QueryString from "querystring"

let CancelToken = axios.CancelToken
let cancel

export default {
  actions: {
    async axiosGetEntries(ctx, data) {
      ctx.commit('showLoad');
      
      if(cancel){
        cancel('Operation canceled by the user.')
        cancel = null
      }


      let oldEntries = []
      if(data.isNext)
        oldEntries = ctx.state.entries.items//[...ctx.state.entries.items]

      await axios({
        method: 'get',
        url: data.isNext ? ctx.state.entriesNextUrl : process.env.VUE_APP_URL+'/entries',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        params: data.isNext ? {exclude_ids: data?.exclude_ids} : ctx.rootGetters.paramsGetFilter(JSON.parse(JSON.stringify(data.params).replace('bank_account', 'account'))),
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      }).then(response => {
        ctx.state.entries = response.data.results
        ctx.state.entries.items = [...oldEntries, ...ctx.state.entries.items]
        ctx.state.entriesNextUrl = response.data.next

        ctx.commit('hideLoad')
      }).catch(error => {
        if(error.message)
          console.log('axiosGetEntries', error.message);
        else{
          console.error('axiosGetEntries', error);
          ctx.commit('hideLoad');
        }
      });
    },
    async axiosAddEntries(ctx, data) {
      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/entries',
        data: data,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        console.log('axiosAddEntries', response)
        return response.data
      }).catch(error => {
        console.error('axiosAddEntries', error)
      })
    },
    async axiosEditEntriesPatch(ctx, data) {
      return await axios.patch(process.env.VUE_APP_URL+'/entries/'+data.id+'/update/date',
        data.data, {
            headers: {
              'Authorization': 'token ' + ctx.getters.getToken,
            }
          }
      ).then(response=> {
        return response.data
      })
    },
    async axiosEditEntries(ctx, data) {
      return await axios({
        method: 'put',
        url: process.env.VUE_APP_URL+'/entries/'+data.id,
        data: data,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        console.log('axiosEditEntries', response)
        return response.data
      }).catch(error => {
        console.error('axiosEditEntries', error)
      })
    }
  },
  mutations: {
    clearEntries(state) {
      state.entries = null
      state.entriesNextUrl = null
    },
    setEntriesItems(state, data) {
      state.entries = data.entries;
      state.entries.items = data.items;
      state.entriesNextUrl = data.next;
    }
  },
  state: {
    entriesNextUrl: null,
    entries: {},
    filters: {
      search: '',
      ordering: 'due_date',
      limit: 10,
      vessel: [],
      client: [],
      account: [],
      bank_account: [],
      bank: [],
      requisite: [],
      operation_type: [],
      is_manual: null,
      is_archive: false,
      due_date_after: null,
      due_date_before: null,
    },
    archiveFilters: {
      search: '',
      ordering: 'due_date',
      limit: 10,
      vessel: [],
      client: [],
      account: [],
      bank_account: [],
      bank: [],
      requisite: [],
      operation_type: [],
      is_manual: null,
      is_archive: true,
      due_date_after: null,
      due_date_before: null,
    },

    defaultEntriesDetails: {
      amount: 0,
      currency: 'USD',
      stat_member: null,
      statement_members: [],
      total_amount: 0,
      client: null,
      real_account: null,
      bank: null,
      requisite: null,
      bank_account: null,
      account: [],
      linked_payments: [],
      linked_owners: [],
      linked_invoices: []
    },
    defaultEntries: {
      debit: {},
      credit: {},
      column_type: 'unknown',
      operation_type: null,
      model_name: null,
      due_date: null,
      cp_date: null,
      has_account: false,
      remark: null,
      is_manual: true,
      vessel: null,
      ports: [],
    }
  }
}