<template>
  <modal-slots :noCloseAfterApply="true" :textForSecondButton="'Create'" :showMod="true" @close="$emit('close')" @apply="apply">
    <template v-slot:title>
      <p class="fz20 fw500 text-dark">Create Folder</p>
    </template>
    <div class="w100Proc box-border p32-16">
      <input :class="{'border-color-red': error}" v-model="name" type="text" placeholder="Enter the name" class="h36">
      <p class="text-red fz12 mt4" v-if="error">Enter at least one character</p>
    </div>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
export default {
  name: "folders-modal-add-folder",
  components: {ModalSlots},
  data() {
    return {
      error: false,
      name: '',
    }
  },
  methods: {
    apply() {
      console.log('apply', this.name)
      this.name = this.name.trim()
      if(!this.name.length && this.name.length == 0) {
        this.error = true
        return null
      }
      this.$emit('create', this.name)
    },
  }
}
</script>

<style scoped lang="scss">
  /deep/ {
    .content {
      min-width: 372px !important;
    }
  }
</style>