<template>
  <div class="p-x-6 p-b-12" v-if="client">
    <h2 class="m-y-4 fw500">
      Total balance due {{getResult >= 0 ? 'from' : 'to'}} {{client.company_name}}: 
      <span class="fw500" :class="{'wrap-brackets': getResult < 0}">{{Math.abs(getResult) | formatNumber}}</span> 
      USD
    </h2>

    <div class="overflow-hidden rounded-lg border border-blue-dark">
      <div class="client_balance__row bg-blue-dark text-white">
        <div class="fz13 fw500 text-white-always">Vessel Name</div>
        <div class="fz13 fw500 text-white-always">Debit, USD</div>
        <div class="fz13 fw500 text-white-always">Credit, USD</div>
      </div>
      <router-link
        v-for="(row, i) in balanceList"
        :key="row.vessel_id"
        :to="`/vessels/${row.vessel_id}/open/entries?client=${client.id}`"
        class="client_balance__row text-dark hover:text-blue"
        :class="{
          'bg-white-blue': i % 2,
          'bg-white': !(i % 2),
        }"
        target="_blank"
      >
        <div class="fz14">{{row.vessel_name}} - {{row.cp_date | formatDateFromCpDate}}</div>
        <div class="fz14" :class="{'fw700': row.debit > row.credit}">{{row.debit | formatNumber}}</div>
        <div class="fz14" :class="{'fw700': row.credit > row.debit}">{{row.credit | formatNumber}}</div>
      </router-link>
    </div>

    <div v-if="getProfit" class="p-r-6 p-l-4 box-border shadow-bottom bg-white fixed bottom-0 right-0" style="width:calc(100% - 320px)">
      <div class="client_balance__row">
        <div class="p-y-2 fz13 fw600">Total</div>
        <div class="p-y-2 border-l-0 fz13" :class="{'fw700': getProfit.debit > getProfit.credit}">{{getProfit.debit | formatNumber}}</div>
        <div class="p-y-2 border-l-0 fz13" :class="{'fw700': getProfit.credit > getProfit.debit}">{{getProfit.credit | formatNumber}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  props: {
    client: Object
  },
  data() {
    return {
      balances: null,
    }
  },
  computed: {
    balanceList() {
      if(this.balances?.data?.length > 1)
        return this.balances.data.filter(el=>el.balance_type == 'VESSEL')
      return []
    },
    getProfit() {
      let profit = {
        debit: 0,
        credit: 0
      }
      this.balanceList.forEach(el => {
        profit.debit += +el.debit
        profit.credit += +el.credit
      });
      return profit
    },
    getResult() {
      if(this.getProfit)
        return this.getProfit.debit - this.getProfit.credit
      return 0
    }
  },
  methods: {
    ...mapActions(['axiosGetClientBalance']),
    ...mapMutations(['showLoad', 'hideLoad'])
  },
  async beforeMount() {
    this.showLoad()
    this.balances = await this.axiosGetClientBalance(+this.$route.params.id)
    this.hideLoad()
  }
}
</script>

<style lang="scss">
.client_balance__row{
  display: flex;
  align-items: stretch;
  border-top: 1px solid var(--color-gray-text);
  &:nth-child(1), &:nth-child(2){
    border-top: 0;
  }
  > div{
    padding: 0.375rem 0.75rem;
    &:nth-child(1){
      width: 48%;
    }
    &:nth-child(3),
    &:nth-child(2){
      width: 26%;
      border-left: 1px solid var(--color-gray-text);
      text-align: right;
    }
  }
}
</style>