<template>
  <div 
    class="invoice_table"
    :class="{
      'with_trials': getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'
    }"
  >
    <div class="border-r rounded-tl-lg p-x-2 p-y-2 fw500">Ocean freight</div>
    <div class="border-r relative">
      <input
        class="p-x-2 p-y-2"
        type="text"
        placeholder="Write a comment"
        v-model.lazy="detail.additional_info.comment"
      />
    </div>
    <div class="border-r" v-if="getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'"></div>
    <div class="border-r relative">
      <input-format-number
          :fractionDigits="3"
          :noWatchBlur="true"
        v-model="detail.mt_amount"
        inputClass="p-l-2 p-r-8 p-y-2"
        placeholder="Weight"
        rightPlaceholder="mt"
          @input="$emit('changeMtAmount', detail.mt_amount)"
      ></input-format-number>
    </div>
    <div class="border-r relative">
      <input-format-number
          :fractionDigits="3"
        v-model="detail.rate"
        inputClass="p-l-2 p-r-18 p-y-2"
        placeholder="Rate"
        :rightPlaceholder="`Rate, ${activeCurrency}`"
      ></input-format-number>
    </div>
    <div class="border-r">
      <button
        class="
          w-1/2
          h-full
          border-t-0 border-b-0 border-l-0 border-r border-r-gray-light
          text-dark
        "
        @click="changeAmountType('INCOME')"
        :class="{ 'bg-gray-dark': detail.amount_type == 'INCOME' }"
      >
        Rev.
      </button>
      <button
        class="w-1/2 h-full text-dark"
        @click="changeAmountType('EXPENSE')"
        :class="{ 'bg-gray-dark': detail.amount_type == 'EXPENSE' }"
      >
        Exp.
      </button>
    </div>
    <div class="rounded-tr-lg">
      <p
        class="p-x-2 p-y-2 fw500 text-align-right box-border"
        :class="{ 'wrap-brackets': detail.percentage_amount < 0 }"
        contenteditable="false"
        ref="total"
      >
        {{ Math.abs(detail.percentage_amount) | formatNumberTwo }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import inputFormatNumber from '@/components/reuse/inputFormatNumber'

export default {
  props: {
    activeCurrency: String,
    total_quantity: Number,
    detail: Object,
  },
  components: {
    inputFormatNumber
  },
  data() {
    return {
    }
  },
  watch: {
    "detail.mt_amount": function () {
      this.getTotal()
    },
    "detail.rate": function () {
      this.getTotal()
    },
    "detail.percentage": function () {
      this.getTotal()
    },
    total_quantity: function () {
      this.getMtAmount();
      this.getTotal()
    },
    "detail.percentage_amount": function () {
      this.checkAmountType();
    },
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    getMtAmount() {
      this.detail.mt_amount = this.total_quantity;
      console.log('getMtAmount', this.detail.mt_amount, this.total_quantity)
    },
    getTotal() {
      let result = this.detail.mt_amount ? parseFloat(this.detail.mt_amount) : 0;
      if(+this.detail.percentage)
        result = result - (result * (this.detail.percentage/100))
      if (parseFloat(this.detail.rate)) result = result * parseFloat(this.detail.rate);

      this.setTotal(result);

    },
    setTotal(result) {
      result = result ? result : 0;
      this.detail.percentage_amount = result;
    },
    checkAmountType() {
      this.detail.amount_type =
        this.detail.percentage_amount < 0 ? "EXPENSE" : "INCOME";
    },
    changeAmountType(type) {
      this.detail.percentage_amount = Math.abs(this.detail.percentage_amount) * (type == "INCOME" ? 1 : -1);
    },
  },
};
</script>

<style>
</style>