<template>
  <div class="flex items-center p-x-6 p-t-4">
    <h1 class="fz20 fw500 m-r-4">
      {{$route.meta.isArchive ? 'Archive Entries' : 'Journal Entries' }}
    </h1>

    <button 
      class="blue-button fz13 fw500 h32 m-r-4 p-x-6" 
      v-if="!$route.meta.isArchive && getPermissionsByType('create_a_manual_entry')"
      @click="$emit('on-create')" 
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="white"/></svg>
      Create Entry
    </button>

    <router-link 
      :to="$route.meta.isVesselPage ? `/vessels/${$route.params.id}/entries/archive` : 'entries/archive'" 
      class="inline-flex m-r-4 svg-white-dart-stroke"
      v-if="!$route.meta.isArchive"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </router-link>

    <button
      v-on:click="emitXmlDownload"
      class="inline-flex m-r-auto svg-white-dart"
      v-if="!$route.meta.isArchive"
      v-tooltip.top="'Export to XLSX'"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.034 5C13.034 4.6134 12.7206 4.3 12.334 4.3C11.9474 4.3 11.634 4.6134 11.634 5H13.034ZM12.334 14L11.8399 14.4959C12.113 14.768 12.5549 14.768 12.828 14.4959L12.334 14ZM16.828 10.5102C17.1019 10.2373 17.1027 9.79412 16.8298 9.52026C16.5569 9.2464 16.1137 9.24561 15.8399 9.51848L16.828 10.5102ZM8.82804 9.51848C8.55419 9.24561 8.11097 9.2464 7.8381 9.52026C7.56522 9.79412 7.56602 10.2373 7.83987 10.5102L8.82804 9.51848ZM19.7006 16C19.7006 15.6134 19.3872 15.3 19.0006 15.3C18.614 15.3 18.3006 15.6134 18.3006 16H19.7006ZM6.03397 16C6.03397 15.6134 5.72057 15.3 5.33397 15.3C4.94737 15.3 4.63397 15.6134 4.63397 16H6.03397ZM11.634 5V14H13.034V5H11.634ZM12.828 14.4959L16.828 10.5102L15.8399 9.51848L11.8399 13.5041L12.828 14.4959ZM12.828 13.5041L8.82804 9.51848L7.83987 10.5102L11.8399 14.4959L12.828 13.5041ZM17.0006 18.3H7.33397V19.7H17.0006V18.3ZM18.3006 16V17H19.7006V16H18.3006ZM6.03397 17V16H4.63397V17H6.03397ZM7.33397 18.3C6.616 18.3 6.03397 17.718 6.03397 17H4.63397C4.63397 18.4912 5.84281 19.7 7.33397 19.7V18.3ZM17.0006 19.7C18.4918 19.7 19.7006 18.4912 19.7006 17H18.3006C18.3006 17.718 17.7186 18.3 17.0006 18.3V19.7Z" fill="#2C2C2C"/></svg>
    </button>


    <button class="flex items-center m-l-auto m-r-6 fz12 fw500 text-dark svg-white-dart-stroke" @click="changeOrdering">
      <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.12969 6.10078C5.12969 6.04555 5.17446 6.00078 5.22969 6.00078H18.2011C18.2563 6.00078 18.3011 6.04555 18.3011 6.10078C18.3011 6.15601 18.2563 6.20078 18.2011 6.20078H5.22969C5.17446 6.20078 5.12969 6.15601 5.12969 6.10078ZM5.12969 17.7008C5.12969 17.6456 5.17446 17.6008 5.22969 17.6008H10.2011C10.2563 17.6008 10.3011 17.6456 10.3011 17.7008C10.3011 17.756 10.2563 17.8008 10.2011 17.8008H5.22969C5.17446 17.8008 5.12969 17.756 5.12969 17.7008ZM5.12969 11.8111C5.12969 11.7558 5.17446 11.7111 5.22969 11.7111H14.2011C14.2563 11.7111 14.3011 11.7558 14.3011 11.8111C14.3011 11.8663 14.2563 11.9111 14.2011 11.9111H5.22969C5.17446 11.9111 5.12969 11.8663 5.12969 11.8111Z" stroke="#2C2C2C" stroke-width="1.4"/></svg>
      Sort by date
      <svg :class="{'rotate-180': filters.ordering == '-due_date'}" class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.30679 8.29156C8.15388 8.13938 7.90656 8.13996 7.75437 8.29287L6.39062 9.66322V1.39062C6.39062 1.17488 6.21574 1 6 1C5.78425 1 5.60937 1.17488 5.60937 1.39062V9.66324L4.24562 8.29285C4.09344 8.13994 3.84611 8.13936 3.6932 8.29154C3.54027 8.44375 3.53971 8.69107 3.69187 8.84398L5.72312 10.8851C5.72324 10.8852 5.72337 10.8853 5.72349 10.8854C5.87568 11.0379 6.1238 11.0384 6.2765 10.8854C6.27662 10.8853 6.27675 10.8852 6.27687 10.8851L8.30812 8.844C8.46027 8.69111 8.45974 8.44377 8.30679 8.29156Z" fill="#2C2C2C"/></svg>
    </button>

    <div class="search w240 position-relative mr12 d-flex align-items-center">
      <svg class="position-absolute l8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
      </svg>
      <input :placeholder="'Search'" class="h34 pl40 border-color-gray bgc-modal" v-model.lazy="filters.search" type="text">
    </div>

    <div class="fs_period-btns">
      <button class="fs_period-btn fz13 h32 text-dark" :class="{'active text-white-always': period == 'today'}" @click="setPeriod('today')">Today</button>
      <button class="fs_period-btn fz13 h32 text-dark" :class="{'active text-white-always': period == 'week'}" @click="setPeriod('week')">Week</button>
      <button class="fs_period-btn fz13 h32 text-dark" :class="{'active text-white-always': period == 'month'}" @click="setPeriod('month')">Month</button>
      <button class="fs_period-btn fz13 h32 text-dark" :class="{'active text-white-always': period == 'all'}" @click="setPeriod('all')">All time</button>
    </div>

    <date-picker
      class="max-w-220"
      format="DD.MM.YYYY"
      type="date"
      range-separator="-"
      :range="true"
      placeholder="Choose period"
      v-model="date"
      @clear="setPeriod('all')"
      @change="onChangeDate"

    ></date-picker>

    <button class="flex items-center m-l-3 p-x-14 p-y-1 bg-white border border-gray-light rounded fz14 fw500 text-dark svg-white-dart" @click="isFilters = true">
      <svg class="m-r-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H18.3392C18.7093 7.05017 19 6.75585 19 6.38127C19 6.00669 18.7093 5.71237 18.3392 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#2C2C2C"/><path d="M14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6204 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3361 15.326 9.61374 14.2423 9.61374ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#2C2C2C"/><path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5185 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8028 8.42731 20.5252 9.48458 20.5252C10.5683 20.5252 11.467 19.8028 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5185 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5553 10.5154 18.144C10.5154 18.7326 10.0396 19.1874 9.48458 19.1874C8.92951 19.1874 8.45374 18.7326 8.45374 18.144C8.48018 17.5553 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/></svg>
      Filter
    </button>

    <entries-filters 
      v-show="isFilters"
      :filters="filters" 
      @on-close="isFilters = false"
    />
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import entriesFilters from '@/components/entries/entriesFilters'
import { mapGetters } from 'vuex'

export default {
  components: {
    DatePicker,
    entriesFilters
  },
  props: {
    filters: Object
  },
  data() {
    return {
      period: 'all',
      date: [null, null],
      isFilters: false
    }
  },
  watch: {
    period() {
      this.setFilterDate()
    },
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    onChangeDate() {
      if (this.date[0]) {
        this.period = 'period'
        this.filters.due_date_after = moment(this.date[0]).format('YYYY-MM-DD HH:mm')
        this.filters.due_date_before = moment(this.date[1]).format('YYYY-MM-DD HH:mm')
      }
    },
    emitXmlDownload() {
      this.$emit('emitXmlDownload')
    },
    setFilterDate() {
      switch (this.period) {
        case 'period':
          break;

        case 'today':
          this.filters.due_date_after = moment().format('YYYY-MM-DD 00:00')
          this.filters.due_date_before = moment().format('YYYY-MM-DD 23:59')
          break;

        case 'week':
          this.filters.due_date_after = moment().startOf('isoWeek').format('YYYY-MM-DD 00:00')
          this.filters.due_date_before = moment().endOf('isoWeek').format('YYYY-MM-DD 23:59')
          break;

        case 'month':
          this.filters.due_date_after = moment().startOf('month').format('YYYY-MM-DD 00:00')
          this.filters.due_date_before = moment().endOf('month').format('YYYY-MM-DD 23:59')
          break;

        default:
          this.filters.due_date_after = null
          this.filters.due_date_before = null
          break;
      }
    },
    setPeriod(str) {
      if(this.date[0])
        this.date = [null, null]
      this.period = str
    },
    changeOrdering(){
      if(this.filters.ordering == 'due_date') this.filters.ordering = '-due_date'
      else this.filters.ordering = 'due_date'
    }
  },
  beforeMount() {
    this.filters.due_date_after = null
    this.filters.due_date_before = null
  }
}
</script>

<style scoped lang="scss">
.text-white-always {
  color: var(--color-white-always) !important;
}
.svg-white-dart {

}
</style>