<template>
  <div class="calc_vessels__dot_row bg-gray-dark rounded-bl-lg rounded-br-lg fz13">
    <div class="colspan-1-3 p-x-1 p-y-1.5 uppercase fw500"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Total'">Total</div></div>
    <div class=" p-x-1" v-tooltip="$options.filters.formatNumberInTotal(getSummInDotsLoading('intake'))">{{getSummInDotsLoading('intake') | formatNumberInTotal}}</div>
<!--    <div class="colspan-3-9 p-x-1" v-tooltip="$options.filters.formatNumberInTotal(getSummInDots('intake'))">{{getTotalFinalLoad | formatNumberInTotal}}</div>-->
   <div class="colspan-4-6"></div>
    <div class="p-x-1" v-tooltip="$options.filters.formatNumberInTotal(getTotalDist)">
      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">{{getTotalDist | formatNumberInTotal}}</div>
    </div>
    <div class="p-x-1" v-tooltip="$options.filters.formatNumberInTotal(getTotalDistSeca)">
      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">{{getTotalDistSeca | formatNumberInTotal}}</div>
    </div>
    <div class=""></div>

    <div class="p-x-1" v-tooltip="$options.filters.formatNumberInTotal(getSummInDots('disbursement'))">
      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">{{getSummInDots('disbursement') | formatNumberInTotal}}</div>
    </div>
    <div class="p-x-1 overflow-hidden" v-tooltip="getSteamingDays ? `${getSteamingDays}` : null">
      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">{{getSteamingDays}}</div>
<!--      <span class="m-l-auto text-gray-text">d</span>-->
    </div>
<!--    <div class="p-x-1 overflow-hidden" v-tooltip="getSteamingTime ? `${getSteamingTime}` : null">-->
<!--      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">{{getSteamingTime}}</div>-->
<!--      <span class="m-l-auto text-gray-text">h</span>-->
<!--    </div>-->
    <div class="p-x-1" v-tooltip="$options.filters.formatNumberInTotal(getSummInDots('port_stay'))">
      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">{{getSummInDots('port_stay') | formatNumberInTotal}}</div>
    </div>
    <div class="p-x-1" v-tooltip="$options.filters.formatNumberInTotal(getSummInDots('lay_ex'))">
      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">{{getSummInDots('lay_ex') | formatNumberInTotal}}</div>
    </div>
<!--    <div class="colspan-11-13"></div>-->

<div class="colspan-13-17"></div>
<!--    <div class=""></div>-->
<!--    <div class=""></div>-->
<!--    <div class=""></div>-->

    <!--    <div class="colspan-15-21"></div>-->
    <div class="p-x-1"><span class="overflow-hidden overflow-ellipsis" v-tooltip="$options.filters.formatNumberInTotal(getSummInDots(fuelType+'_ifo_mt'))">{{getSummInDots(fuelType+'_ifo_mt') | formatNumberInTotal}}</span></div>
    <div class="p-x-1"><span class="overflow-hidden overflow-ellipsis" v-tooltip="$options.filters.formatNumberInTotal(getSummInDots(fuelType+'_mgo_mt'))">{{getSummInDots(fuelType+'_mgo_mt') | formatNumberInTotal}}</span></div>
    <div class=""></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    fuelType: String,
    routes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  filters: {
    formatNumberInTotal: function (value) {
      let numb = parseFloat(value)
      numb = numb ? numb : 0
      numb = numb.toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 4})
      return numb
    }
  },
  computed: {
    ...mapGetters(['getTotalFinalLoad']),
    countSteamingTime() {
      let hours = 0
      let minutes = 0
      this.routes.forEach(el=>{
        if(el[this.fuelType+'_streaming_time']){
          let timeArr = el[this.fuelType+'_streaming_time'].split(':')
          hours += +timeArr[0]
          minutes += +timeArr[1]
        }
      })
      hours += Math.floor(minutes/60)
      minutes = minutes%60
      return [hours,minutes]
    },
    getSteamingTime() {
      return (this.countSteamingTime[0]%24 < 10 ? '0' : '')+this.countSteamingTime[0]%24+':'+(this.countSteamingTime[1] < 10 ? '0' : '')+this.countSteamingTime[1]
    },
    getSteamingDays() {
      let result = Math.floor(this.countSteamingTime[0]/24)
      this.routes.forEach(el=>{
        result += +el[this.fuelType+'_streaming_days']
      })
      return parseFloat(result.toFixed(5))
    },
    getTotalDist() {
      let dist = 0
      this.routes.forEach(el=>{
        dist += el.dist
      })
      return dist
    },
    getTotalDistSeca() {
      let distSeca = 0
      this.routes.forEach(el=>{
        distSeca += el.seca
      })
      return distSeca
    },
  },
  methods: {
    getSummInDots(key) {
      let result = 0
      this.routes.forEach(el=>{
        if(el[key])
          result += +el[key]
      })
      return result
    },
    getSummInDotsLoading(key) {
      let result = 0
      this.routes.forEach(el=>{
        if(el.status === 'loading')
        if(el[key])
          result += +el[key]
      })
      return result
    }
  },
}
</script>

<style lang="scss">
.colspan-13-17 {
  grid-column-start: 13;
  grid-column-end: 17;
}
.colspan-3-6 {
  grid-column-start: 3;
  grid-column-end: 6
}
.colspan-4-6 {
  grid-column-start: 4;
  grid-column-end: 6
}
</style>