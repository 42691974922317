<template>
  <div class="wrap position-relative" v-click-outside="hide">
    <input v-on:click="show = true" type="text" class="h32 bgc-white" placeholder="Search people" v-model="search">
    <div v-show="show" class="list border-radius-8 t34 position-absolute border border-color-normal bgc-white w100Proc box-border d-flex flex-column overflow-y-auto max-h-182">
      <button v-on:click="select(user)" class="h32 pt4 pb4 w100Proc box-border d-flex align-items-center justify-start pl12 pr40 position-relative" v-for="(user, i) in getSearched" :key="i+'asd3'">
        <img class="w24 h24 border-radius-100" :src="user.avatar" alt="" v-if="user.avatar">
        <svg v-if="!user.avatar" width="24" height="24" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"></circle><path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"></path></svg>
        <span class="ml8 fz13 text-dark">{{user.first_name}} {{user.last_name}}</span>
        <svg v-if="selectedIds.indexOf(user.id) >= 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="position-absolute r4"><path data-v-119cd272="" d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"></path></svg>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "folders-drop-search-employees",
  props: {
    value: [Array],
    dontShowMe: Boolean,
  },
  computed: {
    ...mapState({
      usersForFolders: state => state.folders.usersForFolders,
    }),
    ...mapGetters(['getLoginData']),
    selectedIds() {
      return this.value.map(a=> {return a.id})
    },
    getSearched() {
      let array = this.usersForFolders
      if(this.dontShowMe) {
        array = array.filter(user => user.id !== this.getLoginData.data.user.id)
      }
      array.sort(function(a, b){
        if(a.first_name < b.first_name) { return -1; }
        if(a.first_name > b.first_name) { return 1; }
        return 0;
      })
      return this.search ? array.filter(a=> (a.first_name.toLowerCase() + ' ' + a.last_name.toLowerCase()).indexOf(this.search.toLowerCase()) >= 0) : array
    }
  },
  data() {
    return {
      show: false,
      search: ''
    }
  },
  methods: {
    select(user) {
      let value = JSON.parse(JSON.stringify(this.value))
      if(this.selectedIds.indexOf(user.id) >= 0) {
        this.$emit('input', value.filter(a=> a.id !== user.id))
      } else {
        value.push(user)
        this.$emit('input', value)
      }
    },
    hide() {
      this.show = false
    }
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>