import axios from 'axios'

export default {
    actions: {
        axiosLogoutInServer(ctx, token) {
            return axios.delete(process.env.VUE_APP_URL+"/logout/" + token, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                // ctx.commit("deleteDataUser", {root: true});
            })
        }
    },
    mutations: {
    },
    state: {
    },
    getters: {
    },
}
