<template>
  <div class="lay_table__grid bg-white rounded-bl-lg rounded-br-lg shadow border-b-0" :class="{'pdfHideBlock': pdfHideBlock}">
    <div class="fw500 justify-start p-x-3 p-y-2">TOTAL</div>
    <div class=""></div>
    <div class="col-3-5 p-0">
      <div class="w-1/2 border-r border-gray-dark box-border self-stretch"></div>
      <div class="w-1/2 self-stretch box-border"></div>
    </div>
    <div class="col-5-8 p-0">
      <div class="flex items-center justify-center p-1 w-1/3 box-border self-stretch fw500">{{days}}</div>
      <div class="flex items-center justify-center p-1 w-1/3 border-l border-gray-dark self-stretch box-border fw500">{{hours}}</div>
      <div class="flex items-center justify-center p-1 w-1/3 border-l border-gray-dark self-stretch box-border fw500">{{minutes}}</div>
    </div>
    <div class=""></div>
    <div class=""></div>
    <div class="fw500">{{getTotalEquals | formatNumberTwo}}</div>
<!--        <div class="fw500">{{getTotalEquals | formatNumber}}</div>-->

    <div class="" v-if="!pdfHideBlock"></div>
    <div class="" v-if="!pdfHideBlock"></div>
    <div class="" v-if="!pdfHideBlock"></div>
  </div>
</template>

<script>
import moment from "moment"

export default {
  props: {
    rows: Array,
      pdfHideBlock: {
        type: Boolean,
        default: false,
      }
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0
    }
  },
  watch: {
    rows: {
      deep: true,
      immediate: true,
      handler() {
        let timeSumm = 0
        this.rows.forEach(row => {
          if(row.from_date && row.to_date && (!row.remarks || row.remarks == 'na')){
            let from = moment(row.from_date, 'hh:mm')
            let to = moment(row.to_date, 'hh:mm')
            timeSumm += to.diff(from, 'minutes')
          }
        })
        this.days = Math.floor(timeSumm / 60 / 24)
        this.hours = Math.floor((timeSumm / 60) - (this.days * 24))
        this.minutes = Math.floor(timeSumm - (this.days * 24 * 60) - (this.hours * 60))
      },
    }
  },
  computed: {
    getTotalEquals() {
      let summ = 0
      this.rows.forEach(row => {
        summ += row.equal_days
      })
      return summ
    },
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 1611px) {
  .lay_table__grid {
    grid-template-columns: 160px repeat(3, 6.38%) repeat(3, 3.59%) calc(37.79% - 160px) repeat(5, 6.46%);
  }
}
.pdfHideBlock {
  grid-template-columns: 11.64% repeat(3, 5.38%) repeat(3, 3.59%) 29.15% repeat(2, 16.2%) !important;
}
</style>