<template>
  <div class="wrap-page overflow-y-auto">
    <div class="top-panel">
      <router-link class="fz16 fw500 text-gray" :to="{name: 'Location'}">Vessel Location</router-link>
      <router-link class="fz16 fw500 text-gray"  :to="{name: 'Location Emails Vessel'}">Vessel Emails</router-link>
      <router-link class="fz16 fw500 text-gray" :class="{'active': $route.path.indexOf('ocation/emails/all') >= 0}" :to="{name: 'Location Emails Vessel All'}">All Emails</router-link>

    </div>
    <location-read-emails :allEmails="true"></location-read-emails>
  </div>
</template>

<script>
import LocationReadEmails from "@/components/location/location-read-emails/location-read-emails";
export default {
  name: "location-all-emails",
  components: {LocationReadEmails},
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
.top-panel {
  background-color: var(--color-bgc-page-white);
  padding: 0px 24px;
  box-sizing: border-box;
  height: 48px;
  width: 100vw;
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-bottom: 1px solid var(--color-card-border);
  &>* {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 108px;
  }
  &>*:not(:last-child) {
    margin-right: 30px;
  }
  .active {
    color: var(--color-blue-dark);
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      height: 2px;
      background-color: var(--color-blue-dark);
      width: 100%;
      box-sizing: border-box;
    }
  }
}
.wrap-page {
  background-color: var(--color-bgc-page);
  height: calc(100vh - 56px);
  .content {
    margin-top: 16px;
    padding: 0 24px
  }
}
</style>