<template>
  <div class="folders-menu d-flex align-items-center">
    <button class="button-blue-border w140 h32 p0 whitespace-nowrap fz14 mr8" v-if="!hideFolders" v-on:click="$emit('create-folder')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2241 13.2218H7.77975V9.77734C7.77975 9.3481 7.43126 8.99961 7.00188 8.99961C6.57264 8.99961 6.22415 9.3481 6.22415 9.77734V13.2218H2.77968C2.35044 13.2218 2.00195 13.5703 2.00195 13.9995C2.00195 14.4289 2.35044 14.7774 2.77968 14.7774H6.22415V18.2217C6.22415 18.6511 6.57264 18.9996 7.00188 18.9996C7.43126 18.9996 7.77975 18.6511 7.77975 18.2217V14.7774H11.2241C11.6535 14.7774 12.002 14.4289 12.002 13.9995C12.002 13.5703 11.6535 13.2218 11.2241 13.2218Z" fill="#094172"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.20038 4.4C5.48241 4.4 4.90038 4.98203 4.90038 5.7V6.7C4.90038 7.0866 4.58698 7.4 4.20038 7.4C3.81378 7.4 3.50038 7.0866 3.50038 6.7V5.7C3.50038 4.20883 4.70921 3 6.20038 3H9.53974C10.5087 3 11.4033 3.51917 11.884 4.36042L12.7323 5.3831C12.9638 5.78814 13.3945 6.03812 13.861 6.03812H18.2004C19.6916 6.03812 20.9004 7.24695 20.9004 8.73811V15.7C20.9004 17.1912 19.6916 18.4 18.2004 18.4H14.2004C13.8138 18.4 13.5004 18.0866 13.5004 17.7C13.5004 17.3134 13.8138 17 14.2004 17H18.2004C18.9184 17 19.5004 16.418 19.5004 15.7V8.73811C19.5004 8.02014 18.9184 7.43811 18.2004 7.43811H13.861C12.8921 7.43811 11.9975 6.91894 11.5168 6.07769L10.6685 5.05502C10.437 4.64997 10.0063 4.4 9.53974 4.4H6.20038Z" fill="#094172"/>
      </svg>
      Create Folder
    </button>
    <button class="button-blue-border w140 h32 p0 whitespace-nowrap fz14 mr8 position-relative">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29961 4.9C6.58164 4.9 5.99961 5.48203 5.99961 6.2V17.7C5.99961 18.418 6.58164 19 7.29961 19H11.0996V13.9299L9.30263 15.7868C9.03378 16.0646 8.59062 16.0718 8.31281 15.803C8.035 15.5341 8.02774 15.091 8.29659 14.8132L11.2966 11.7132C11.4285 11.5769 11.61 11.5 11.7996 11.5C11.9892 11.5 12.1708 11.5769 12.3026 11.7132L15.3026 14.8132C15.5715 15.091 15.5642 15.5341 15.2864 15.803C15.0086 16.0718 14.5654 16.0646 14.2966 15.7868L12.4996 13.9299V19H16.2996C17.0176 19 17.5996 18.418 17.5996 17.7V10.9H14.2996C12.8084 10.9 11.5996 9.69117 11.5996 8.2V4.9H7.29961ZM12.9996 5.88995L16.6097 9.5H14.2996C13.5816 9.5 12.9996 8.91797 12.9996 8.2V5.88995ZM4.59961 6.2C4.59961 4.70883 5.80844 3.5 7.29961 3.5H12.5896L18.9996 9.91005V17.7C18.9996 19.1912 17.7908 20.4 16.2996 20.4H7.29961C5.80844 20.4 4.59961 19.1912 4.59961 17.7V6.2Z" fill="#094172"/>
      </svg>
      Upload File
      <input type="file" multiple class="upload" @change="(e)=> {$emit('upload-file', e)}">
    </button>
    <button class="button-blue-border w140 h32 p0 whitespace-nowrap fz14 position-relative" v-if="!hideFolders">
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.4998 4.69687C5.78183 4.69687 5.1998 5.2789 5.1998 5.99688V16.4969C5.1998 17.2148 5.78183 17.7969 6.4998 17.7969H11.7998V12.7268L10.0028 14.5836C9.73397 14.8614 9.29081 14.8687 9.01301 14.5998C8.7352 14.331 8.72794 13.8878 8.99679 13.61L11.9968 10.5101C12.1287 10.3738 12.3102 10.2969 12.4998 10.2969C12.6894 10.2969 12.8709 10.3738 13.0028 10.5101L16.0028 13.61C16.2717 13.8878 16.2644 14.331 15.9866 14.5998C15.7088 14.8687 15.2656 14.8614 14.9968 14.5836L13.1998 12.7268V17.7969H18.4998C19.2178 17.7969 19.7998 17.2148 19.7998 16.4969V9.49687C19.7998 8.7789 19.2178 8.19687 18.4998 8.19687H14.4197C13.6315 8.19687 12.8826 7.85246 12.3697 7.25401L10.567 5.15085C10.32 4.86271 9.95944 4.69687 9.57993 4.69687H6.4998ZM3.7998 5.99688C3.7998 4.50571 5.00864 3.29688 6.4998 3.29688H9.57993C10.3681 3.29688 11.117 3.64129 11.6299 4.23974L13.4326 6.3429C13.6796 6.63104 14.0402 6.79688 14.4197 6.79688H18.4998C19.991 6.79688 21.1998 8.00571 21.1998 9.49687V16.4969C21.1998 17.988 19.991 19.1969 18.4998 19.1969H6.4998C5.00864 19.1969 3.7998 17.988 3.7998 16.4969V5.99688Z" fill="#094172"/>
      </svg>
      Upload Folder
      <input type="file"  class="upload" webkitdirectory directory multiple @change="(e)=> {$emit('upload-folder', e)}">
    </button>
  </div>
</template>

<script>
export default {
  name: "folders-upload-buttons",
  props: {
    hideFolders: Boolean,
  },
  data() {
    return {

    }
  },
  methods: {
    uploadFile() {

    },
  }
}
</script>

<style scoped lang="scss">
.upload {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>