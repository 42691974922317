<template>
  <div class="wrap-container">
    <div class="container-head border-bottom border-left border-right border-color-dark-gray" :class="{'last-row': lastRow}">
      <div class="number fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1 pt6 pl6 position-relative">
        {{index+1}}
        <svg v-on:click="$emit('delete', detail.id)" class="position-absolute b0 l0 delete-button cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/>
        </svg>

      </div>
      <div class="cargo fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1 position-relative">
        <input
            type="text"
            class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13"
            v-model="detail.cargo_class"
            @focus="onFocusCargo"
            @blur="onBlurCargo"
            @keyup.enter="$event.target.blur()"
            ref="cargoInput"
        >
<!--        <svg @click="onFocusCargo" class="absolute right-2 centered-y cursor-pointer" width="12" height="12" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M2.724 4.4a.3.3 0 0 0-.212.512l3.276 3.276a.3.3 0 0 0 .424 0l3.276-3.276a.3.3 0 0 0-.212-.512H2.724Z"/></svg>-->
        <default-select
            class="absolute left-0 top-full w-full default_select__hide_main_input multiselect_fz13"
            :id="'cargo_class'+detail.id"
            :options="cargoClassList"
            :allowEmpty="true"
            :closeOnSelect="true"
            :searchable="false"
            trackBy="name"
            v-model="cargoClassObj"
            @on-select="onSelectCargo"
            ref="cargoDrop"
        />
      </div>
      <div class="quantity fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1 d-flex align-items-stretch">
        <div class="w39 p6 box-border border-right border-color-gray-1 bgc-gray">
          <button class="text-blue fz12 fw700" v-if="isQuantityMt" v-on:click="onChangeQuantityType">MIN/ <br>MAX</button>
          <button class="text-blue fz12 fw700" v-if="!isQuantityMt" v-on:click="onChangeQuantityType"><svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.76271 5.48889V9.03333C5.76271 9.30947 5.53885 9.53333 5.26271 9.53333H4.7161C4.43996 9.53333 4.2161 9.30947 4.2161 9.03333V5.48889H0.5C0.223858 5.48889 0 5.26503 0 4.98889V4.54445C0 4.2683 0.223858 4.04445 0.5 4.04445H4.2161V0.5C4.2161 0.223858 4.43996 0 4.7161 0H5.26271C5.53885 0 5.76271 0.223858 5.76271 0.5V4.04445H9.5C9.77614 4.04445 10 4.2683 10 4.54445V4.98889C10 5.26503 9.77614 5.48889 9.5 5.48889H5.76271ZM0.5 13C0.223858 13 0 12.7761 0 12.5V12.0762C0 11.8 0.223858 11.5762 0.5 11.5762H9.5C9.77614 11.5762 10 11.8 10 12.0762V12.5C10 12.7761 9.77614 13 9.5 13H0.5Z" fill="#319FEF"/>
          </svg>
          </button>
        </div>
        <div class="quantity-inputs d-flex flex-column">
          <div v-if="isQuantityMt" class="w100Proc relative border-bottom border-gray-light h24">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                v-model.number.lazy="detail.quantity_mt"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('quantity_mt')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">mt</span>
          </div>
          <div v-if="isQuantityMt" class="w100Proc relative h24">
            <span class="absolute centered-y left-2">
              <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.332 5.162v2.955h-1.1V5.162H2.23V4.106h3V1.151h1.1v2.955h3.016v1.056H6.332ZM2.23 10.65V9.61h7.116v1.04H2.231Z" fill="#9AA3B0"/></svg>
            </span>
            <input
                type="number"
                class="p-y-1.5 p-x-6 border-0 bg-transparent rounded-none fz13 h24"
                v-model.number.lazy="detail.quantity_percentage"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('quantity_percentage')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">%</span>
          </div>
          <div v-if="!isQuantityMt" class="w100Proc relative border-bottom border-gray-light h24">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                placeholder="Min"
                v-model.number.lazy="detail.quantity_min"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('quantity_min')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">mt</span>
          </div>
          <div v-if="!isQuantityMt" class="w100Proc relative h24">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                placeholder="Max"
                v-model.number.lazy="detail.quantity_max"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('quantity_max')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">mt</span>
          </div>
        </div>
      </div>
      <div class="stowage-factor fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">
        <div class="w100Proc relative border-bottom border-gray-light h24">
          <input
              type="number"
              class="p-y-1.5 p-l-2 p-r-12 border-0 bg-transparent rounded-none fz13 h24"
              v-model.number.lazy="detail.stowage_factor_m"
              @blur="onBlurStowage('m')"
              @keyup.enter="onBlurStowage('m'), $event.target.blur()"
          >
          <span class="absolute centered-y right-2 text-gray-text fz13">m³/mt</span>
        </div>
        <div class="w100Proc relative h24">
          <input
              type="number"
              class="p-y-1.5 p-l-2 p-r-12 border-0 bg-transparent rounded-none fz13 h24"
              v-model.number.lazy="detail.stowage_factor_ft"
              @blur="onBlurStowage('ft')"
              @keyup.enter="onBlurStowage('ft'), $event.target.blur()"
          >
          <span class="absolute centered-y right-2 text-gray-text fz13">ft³/mt</span>
        </div>
      </div>
      <div class="button-create-new-cargo d-flex align-items-stretch border-right border-color-gray-1 ">
        <div class="w50Proc  border-right border-color-gray-1 box-border d-flex flex-column">
          <div class="w100Proc h24 border-bottom border-gray-light d-flex align-items-stretch">
            <div class="w64 bgc-gray text-color-new-dark-gray fz13 fw700 pl2 box-border border-right border-color-gray-1 d-flex align-items-center">Freight</div>
              <div class="flex-1 bg-white relative h24">
                <input
                    type="number"
                    class="p-y-1.5 p-l-2 p-r-10 border-0 bg-transparent rounded-none fz13 h24"
                    v-model.number.lazy="detail.freight"
                    @keypress="onlyNumber($event)"
                    @keyup.enter="$event.target.blur()"
                    @blur="onBlurDetailNumber('freight')"
                >
                <span class="absolute centered-y right-2 text-gray-text fz13">$/mt</span>
              </div>
          </div>
          <div class="w100Proc h24 d-flex align-items-stretch">
            <div class="w64 bgc-gray text-color-new-dark-gray fz13 fw700 pl2 box-border border-right border-color-gray-1 whitespace-nowrap d-flex align-items-center">Cg Comm</div>
            <div class="flex-1 bg-white relative h24">
              <input
                  type="number"
                  class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                  v-model.number.lazy="detail.cg_comm"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
                  @blur="onBlurDetailNumber('cg_comm')"
              >
              <span class="absolute centered-y right-2 text-gray-text fz13">%</span>
            </div>
          </div>
        </div>
        <div class="w50Proc d-flex flex-column bgc-gray">
          <div class="w100Proc h25 border-bottom border-gray-light d-flex align-items-center justify-between pl6 pr4 box-border">
            <p class="fz13 fw700 text-dark">Load</p>
            <p class="fz13 text-dark d-flex">
              Wk Cranes
              <switcher class="ml4" :id="'load_wk'+detail.id" v-model="detail.loading_wk_cranes"/>
            </p>
          </div>
          <div class="w100Proc h24 d-flex align-items-center justify-between pl6 pr4 box-border">
            <p class="fz13 fw700 text-dark">Disch</p>
            <p class="fz13 text-dark d-flex">
              Wk Cranes
              <switcher class="ml4" :id="'load_wk'+detail.id" v-model="detail.discharging_wk_cranes"/>
            </p>
          </div>
        </div>
      </div>
      <div class="port fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">
        <div class="h24 border-bottom border-gray-light">
          <default-select
              :classInput="'h24'"
              class="w-full default_select__in_calc default_select__fz13 multiselect_fz13 h24"
              :id="'loading_port'+detail.id"
              :options="getLoadingPorts"
              :allowEmpty="true"
              :closeOnSelect="true"
              :searchable="false"
              :trackBy="'route_id'"
              placeholder=""
              v-model="detail.loading_port"
              @on-select="onSelectPortLoading"
          />
        </div>
        <div class="h24">
          <default-select
              :classInput="'h24'"
              class="w-full default_select__in_calc default_select__fz13 multiselect_fz13 h24"
              :id="'discharging_port'+detail.id"
              :options="getDischargingPorts"
              :allowEmpty="true"
              :closeOnSelect="true"
              :searchable="false"
              :trackBy="'route_id'"
              placeholder=""
              v-model="detail.discharging_port"
              @on-select="onSelectPortDischarging"
          />
        </div>

      </div>
      <div class="days-or-rate fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">
        <div class="h24 border-gray-light border-bottom d-flex ">
          <div class="w36 bgc-gray border-right border-color-gray-1 d-flex align-items-center pl3 box-border">
            <button
                type="button"
                class=" text-blue fz13 fw700"
                @click="detail.loading_rate_type = detail.loading_rate_type == 'RATE'?'DAYS': 'RATE'"
            >
              {{detail.loading_rate_type == 'RATE' ? 'Rate':'Days'}}
            </button>
          </div>
          <div class="h24 flex-1 position-relative">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-14 border-0 bg-transparent rounded-none fz13 h24 w100Proc"
                v-model.number.lazy="detail.loading_rate"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('loading_rate')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13" v-if="detail.loading_rate_type == 'RATE'">mt/day</span>
          </div>
        </div>
        <div class="h24 d-flex ">
          <div class="w36 bgc-gray border-right border-color-gray-1 d-flex align-items-center pl3 box-border">
            <button
                type="button"
                class=" text-blue fz13 fw700"
                @click="detail.discharging_rate_type = detail.discharging_rate_type == 'RATE'?'DAYS': 'RATE'"
            >
              {{detail.discharging_rate_type == 'RATE' ? 'Rate':'Days'}}
            </button>
          </div>
          <div class="h24 flex-1 position-relative">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-14 border-0 bg-transparent rounded-none fz13 h24 w100Proc"
                v-model.number.lazy="detail.discharging_rate"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('discharging_rate')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13" v-if="detail.discharging_rate_type == 'RATE'">mt/day</span>
          </div>
        </div>
      </div>
      <div class="terms fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">
        <div class="h24 border-bottom border-gray-light">
          <div class="w100Proc bg-white">
            <default-select
                class="w-full default_select__in_calc default_select__fz13 multiselect_fz13 h24"
                :id="'loading_terms'+detail.id"
                :options="termsList.map(el=>el.name+' - '+el.coof)"
                :allowEmpty="true"
                :closeOnSelect="true"
                :searchable="false"
                :trackBy="null"
                placeholder=""
                v-model="detail.loading_terms"
            />
          </div>
        </div>
        <div class="h24">
          <div class="w100Proc bg-white">
            <default-select
                class="w-full default_select__in_calc default_select__fz13 multiselect_fz13 h24"
                :id="'discharging_terms'+detail.id"
                :options="termsList.map(el=>el.name+' - '+el.coof)"
                :allowEmpty="true"
                :closeOnSelect="true"
                :searchable="false"
                :trackBy="null"
                placeholder=""
                v-model="detail.discharging_terms"
            />
          </div>
        </div>
      </div>
      <div class="turn-time fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">
        <div class="h24 border-gray-light border-bottom">
          <div class="w100Proc relative">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                v-model.number.lazy="detail.loading_turn_time"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">h</span>
          </div>
        </div>
        <div class="h24">
          <div class="w100Proc relative">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                v-model.number.lazy="detail.discharging_turn_time"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">h</span>
          </div>
        </div>
      </div>
      <div class="draft fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">
        <div class="h24 border-bottom border-gray-light">
          <div class="w100Proc relative">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                v-model.number.lazy="detail.loading_draft"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('loading_draft')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">m</span>
          </div>
        </div>
        <div class="h24">
          <div class="w100Proc bg-white relative">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                v-model.number="detail.discharging_draft"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="detail.discharging_draft = Number(detail.discharging_draft); onBlurDetailNumber('discharging_draft')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">m</span>
          </div>
        </div>
      </div>
      <div class="water-density fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">
        <div class="h24 border-bottom border-gray-light">
          <div class="w100Proc relative svg-white-dart" v-click-outside="onBlurWaterLoading">
            <input
                type="text"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                v-model="detail.loading_water_density"
                @focus="onFocusWaterLoading"
                @blur="onBlurWaterLoading"
                @keyup.enter="$event.target.blur()"
                ref="waterInputLoading"
            >
            <svg @click="onFocusWaterLoading" class="absolute right-2 centered-y cursor-pointer" width="12" height="12" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M2.724 4.4a.3.3 0 0 0-.212.512l3.276 3.276a.3.3 0 0 0 .424 0l3.276-3.276a.3.3 0 0 0-.212-.512H2.724Z"/></svg>
            <default-select
                class="absolute left-0 top-full w-full default_select__hide_main_input multiselect_fz13"
                :id="'loading_water'+detail.id"
                :options="calcWaterDensity"
                :allowEmpty="true"
                :closeOnSelect="true"
                :searchable="false"
                trackBy="name"
                v-model="waterDensityObjLoading"
                @on-select="onSelectWaterLoading"
                ref="waterDropLoading"
            />
          </div>
        </div>
        <div class="h24">
          <div class="w100Proc relative svg-white-dart" v-click-outside="onBlurWaterDischarging">
            <input
                type="text"
                class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13 h24"
                v-model="detail.discharging_water_density"
                @focus="onFocusWaterDischarging"
                @blur="onBlurWaterDischarging"
                @keyup.enter="$event.target.blur()"
                ref="waterInputDischarging"
            >
            <svg @click="onFocusWaterDischarging" class="absolute right-2 centered-y cursor-pointer" width="12" height="12" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M2.724 4.4a.3.3 0 0 0-.212.512l3.276 3.276a.3.3 0 0 0 .424 0l3.276-3.276a.3.3 0 0 0-.212-.512H2.724Z"/></svg>
            <default-select
                class="absolute left-0 top-full w-full default_select__hide_main_input multiselect_fz13"
                :id="'discharging_water'+detail.id"
                :options="calcWaterDensity"
                :allowEmpty="true"
                :closeOnSelect="true"
                :searchable="false"
                trackBy="name"
                v-model="waterDensityObjDischarging"
                @on-select="onSelectWaterDischarging"
                ref="waterDropDischarging"
            />
          </div>
        </div>
      </div>
      <div class="season fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">
        <div class="h24 border-bottom border-gray-light">
          <div class="w100Proc bg-white relative">
            <default-select
                class="w-full default_select__in_calc capitalize-child default_select__fz13 multiselect_fz13 h24"
                :id="'loading_seasonal'+detail.id"
                :options="['summer', 'winter', 'tropical']"
                :allowEmpty="true"
                :closeOnSelect="true"
                :searchable="false"
                trackBy=""
                placeholder=""
                openDirection="top"
                v-model="detail.loading_seasonal_mark"
            />
          </div>
        </div>
        <div class="h24">
          <div class="w100Proc bg-white relative rounded-br-lg">
            <default-select
                class="w-full default_select__in_calc capitalize-child default_select__fz13 multiselect_fz13 h24"
                :id="'discharging_seasonal'+detail.id"
                :options="['summer', 'winter', 'tropical']"
                :allowEmpty="true"
                :closeOnSelect="true"
                :searchable="false"
                trackBy=""
                placeholder=""
                openDirection="top"
                v-model="detail.discharging_seasonal_mark"
            />
          </div>
        </div>
      </div>
      <div class="liner-in-out fz13 fw700 text-color-new-dark-gray">
        <div class="h24 border-bottom border-gray-light">
          <div class="w100Proc bg-white relative">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-10 border-0 bg-transparent rounded-none fz13 h24"
                v-model.number.lazy="detail.loading_liner"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('loading_liner')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">$/mt</span>
          </div>
        </div>
        <div class="h24">
          <div class="w100Proc bg-white relative">
            <input
                type="number"
                class="p-y-1.5 p-l-2 p-r-10 border-0 bg-transparent rounded-none fz13 h24"
                v-model.number="detail.discharging_liner"
                @keypress="onlyNumber($event)"
                @keyup.enter="$event.target.blur()"
                @blur="onBlurDetailNumber('discharging_liner')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">$/mt</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSelect from "@/components/reuse/defaultSelect";
import {mapActions, mapState} from "vuex";
import Switcher from "@/components/reuse/switcher";
export default {
  name: "cargo-item",
  components: {Switcher, DefaultSelect},
  props: {
    index: Number,
    detail: Object,
    routeList: Array,
    lastRow: Boolean,
  },
  computed: {
    ...mapState({
      termsList: state => state.tabs.termsList,
      cargoClassList: state => state.tabs.cargoClassList,
      calcWaterDensity: state => state.tabs.calcWaterDensityNew

    }),
    getLoadingPorts() {
      if(!this.routeList.length) return []
      return this.routeList.filter(el=>el.status == 'loading' && el.port).map(el=>{
        return {
          ...el.port,
          route_id: el.route_id
        }
      })
    },
    getDischargingPorts() {
      if(!this.routeList.length) return []
      return this.routeList.filter(el=>el.status == 'discharging' && el.port).map(el=>{
        return {
          ...el.port,
          route_id: el.route_id
        }
      })
    },
  },
  data() {
    return {
      cargoClassObj: null,
      isQuantityMt: true,
      minMax: true,
      waterDensityObjLoading: null,
      waterDensityObjDischarging: null,
      timeout: null,
    }
  },
  watch: {
    'detail.id': {
      immediate: true,
      handler() {
        if(!this.detail.quantity_mt && (this.detail.quantity_min || this.detail.quantity_max)){
          this.isQuantityMt = false
        }else {
          this.isQuantityMt = true
        }
        this.cargoClassObj = null
      }
    },
    detail: {
      deep: true,
      handler() {
        this.$emit('calc')
        if(this.$route.path.indexOf('archive') === -1) {
          if(this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.onSaveRow()
          }, 1000)
        }
      }
    }

  },
  methods: {
    ...mapActions(['onlyNumber', 'axiosPatchCalcCargo']),
    onSaveRow() {
      let savedRow = JSON.parse(JSON.stringify(this.detail))

      if(savedRow.loading_terms){
        let index = savedRow.loading_terms.indexOf(' ')
        if(index > 0)
          savedRow.loading_terms = savedRow.loading_terms.slice(0, index)
      }
      if(savedRow.discharging_terms){
        let index = savedRow.discharging_terms.indexOf(' ')
        if(index > 0)
          savedRow.discharging_terms = savedRow.discharging_terms.slice(0, index)
      }

      if(savedRow.loading_port) savedRow.loading_port = savedRow.loading_port.id
      if(savedRow.discharging_port) savedRow.discharging_port = savedRow.discharging_port.id

      this.axiosPatchCalcCargo(savedRow)
    },
    onSelectWaterDischarging() {
      if(this.waterDensityObjDischarging){
        this.detail.discharging_water_density = this.waterDensityObjDischarging.name
      }else
        this.detail.discharging_water_density = null
      this.$refs.waterInputDischarging.blur()
    },
    onFocusWaterDischarging() {
      this.$refs.waterDropDischarging.openDropdown()
    },
    onBlurWaterDischarging() {
      this.$refs.waterDropDischarging.closeDropdown()
      if(this.waterDensityObjDischarging && (this.waterDensityObjDischarging.name != this.detail.discharging_water_density)){
        this.waterDensityObjDischarging = null
        if(!parseFloat(this.detail.discharging_water_density))
          this.detail.discharging_water_density = 1.25
      }
    },
    onSelectWaterLoading() {
      if(this.waterDensityObjLoading){
        this.detail.loading_water_density = this.waterDensityObjLoading.name
      }else
        this.detail.loading_water_density = null
      this.$refs.waterInputLoading.blur()
    },
    onFocusWaterLoading() {
      this.$refs.waterDropLoading.openDropdown()
    },
    onBlurWaterLoading() {
      this.$refs.waterDropLoading.closeDropdown()
      if(this.waterDensityObjLoading && (this.waterDensityObjLoading.name != this.detail.loading_water_density)){
        this.waterDensityObjLoading = null
        if(!parseFloat(this.detail.loading_water_density))
          this.detail.loading_water_density = 1.25
      }
    },
    onSelectPortLoading() {
      if(this.detail.loading_port) {
        this.detail.loading_port_name = '#'+this.detail.loading_port.route_id
        // let router = this.routeList.find(a=> a.route_id == this.detail.loading_port_name.replace('#',''))
        // console.log('router', router, this.detail.loading_port_name.replace('#',''))
        // router.cargos = Array.isArray(router.cargos) === true ? router.cargos : []
        // if(router && router.cargos.indexOf(this.detail.id) === -1) {
        //   router.cargos.push(this.detail.id)
        //   router.cargos = Array.from(new Set(router.cargos))
        // }
      } else {
        // let router = this.routeList.find(a=> a.route_id === this.detail.loading_port_name.replace('#',''))
        // router.cargos = Array.isArray(router.cargos) === true ? router.cargos : []
        // router.cargos = router.cargos.filter(a=> a !== this.detail.id)
        this.detail.loading_port_name = null
      }
      this.$emit('checkRoutesAndCargos')
    },
    onSelectPortDischarging() {
      if(this.detail.discharging_port) {
        this.detail.discharging_port_name = '#'+this.detail.discharging_port.route_id
        // let router = this.routeList.find(a=> a.route_id == this.detail.discharging_port_name.replace('#',''))
        // router.cargos = Array.isArray(router.cargos) === true ? router.cargos : []
        // if(router.cargos.indexOf(this.detail.id) === -1) {
        //   router.cargos.push(this.detail.id)
        //   router.cargos = Array.from(new Set(router.cargos))
        // }
      } else {
        // let router = this.routeList.find(a=> a.route_id === this.detail.discharging_port_name.replace('#',''))
        // router.cargos = router.cargos.filter(a=> a !== this.detail.id)
        // router.cargos = Array.isArray(router.cargos) === true ? router.cargos : []
        this.detail.discharging_port_name = null
      }
      this.$emit('checkRoutesAndCargos')
    },
    onBlurStowage(type) {
      let factor = 0
      if(type == 'm'){

        if(parseFloat(this.detail.stowage_factor_m)) {
          this.detail.stowage_factor_m = Math.round((this.detail.stowage_factor_m)*100000)/100000
          factor = parseFloat(this.detail.stowage_factor_m)
        }
        this.detail.stowage_factor_ft = this.detail.stowage_factor_m ? Math.round((factor/0.02831684659)*100000)/100000 : null
      }else{
        if(parseFloat(this.detail.stowage_factor_ft)) {
          this.detail.stowage_factor_ft = Math.round((this.detail.stowage_factor_ft)*100000)/100000
          factor = parseFloat(this.detail.stowage_factor_ft)
        }
        this.detail.stowage_factor_m = this.detail.stowage_factor_ft ? Math.round(factor*0.02831684659*100000)/100000 : null
      }
    },
    onBlurDetailNumber(key) {
      console.log('typeof this.detail[key]', typeof this.detail[key])
      if(!this.detail[key] || typeof this.detail[key] != 'number'){
        this.detail[key] = null
      }
    },
    onSelectCargo() {
      if(this.cargoClassObj){
        this.detail.cargo_class = this.cargoClassObj.name
        this.detail.stowage_factor_m = this.cargoClassObj.m3
        this.detail.stowage_factor_ft = this.cargoClassObj.ft3
      }
      this.$refs.cargoInput.blur()
    },
    onFocusCargo() {
      this.$refs.cargoDrop.openDropdown()
    },
    onBlurCargo() {
      this.$refs.cargoDrop.closeDropdown()
      if(this.cargoClassObj && (this.cargoClassObj.name != this.detail.cargo_class))
        this.cargoClassObj = null
    },
    onChangeQuantityType() {
      if(this.isQuantityMt){/* mt to min/max */
        let numb = parseFloat(this.detail.quantity_mt) ? parseFloat(this.detail.quantity_mt) : 0
        let max = parseFloat(this.detail.quantity_percentage) ? (100 + parseFloat(this.detail.quantity_percentage))/100 : 1
        let min = parseFloat(this.detail.quantity_percentage) ? (100 - parseFloat(this.detail.quantity_percentage))/100 : 1
        this.detail.quantity_min = this.detail.quantity_mt ? Math.round(numb*min*1000000)/1000000 : null
        this.detail.quantity_max = this.detail.quantity_mt ? Math.round(numb*max*1000000)/1000000 : null
        this.detail.quantity_mt = null
        this.isQuantityMt = false
      }else{/* min/max to mt */
        let result = null
        if(parseFloat(this.detail.quantity_min)>=0) result = parseFloat(this.detail.quantity_min)
        if(parseFloat(this.detail.quantity_max)>=0) result = parseFloat(this.detail.quantity_max)
        if(parseFloat(this.detail.quantity_min)>=0 && parseFloat(this.detail.quantity_max)>=0)
          result = (parseFloat(this.detail.quantity_min) + parseFloat(this.detail.quantity_max))/2
        this.detail.quantity_mt = result >=0 ? Math.round(result*1000000)/1000000 : null
        this.isQuantityMt = true
      }
    },
  }
}
</script>

<style scoped lang="scss">
.container-head {  display: grid;
  grid-template-columns: 1.972% 6.373% 8.497% 7.587% 22.078% 8.497% 10.015% 7.511% 4.855% 4.097% 6.525% 5.538% 1fr;
  grid-template-rows: 49px;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  &>* {
    box-sizing: border-box;
  }

  background-color: var(--color-modal);
}


.number { grid-area: 1 / 1 / 2 / 2; }

.cargo { grid-area: 1 / 2 / 2 / 3; }

.quantity { grid-area: 1 / 3 / 2 / 4; }

.stowage-factor { grid-area: 1 / 4 / 2 / 5; }

.button-create-new-cargo { grid-area: 1 / 5 / 2 / 6; }

.port { grid-area: 1 / 6 / 2 / 7; }

.days-or-rate { grid-area: 1 / 7 / 2 / 8; }

.terms { grid-area: 1 / 8 / 2 / 9; }

.turn-time { grid-area: 1 / 9 / 2 / 10; }

.draft { grid-area: 1 / 10 / 2 / 11; }

.water-density { grid-area: 1 / 11 / 2 / 12; }

.season { grid-area: 1 / 12 / 2 / 13; }

.liner-in-out { grid-area: 1 / 13 / 2 / 14; }

.h24 {
  height: 24px;
}
.w64 {
  width: 64px;
}

.wrap-container::v-deep {
  .multiselect__tags {
    min-height: 24px;
    padding-top: 2px;
    padding-right: 22px !important;
  }
}
.delete-button {
  &:hover {
    path {
      fill: var(--color-red)
    }
  }
}
.last-row {
  border-radius: 0 0 8px 8px;
}
</style>