<template>
  <section class="wrap-page">
    <div class="top-panel">
      <div class="block-buttons position-relative">
        <router-link to="/payment/calendar" class="fz16 fw500 text-gray navigation-button-payment" :class="{'navigation-button-payment-active': $route.path==='/payment/calendar'}" v-on:click="changeActiveButton(0)">Payment Calendar</router-link>
        <router-link to="/payment/list" class="fz16 fw500 text-gray navigation-button-payment" :class="{'navigation-button-payment-active': $route.path==='/payment/list'}" v-on:click="changeActiveButton(1)">Payment List</router-link>
        <router-link to="/payment/instructions" class="fz16 fw500 text-gray navigation-button-payment" :class="{'navigation-button-payment-active': $route.path==='/payment/instructions'}" v-on:click="changeActiveButton(2)">Payment Instructions</router-link>
<!--        <span class="line"></span>-->
      </div>
    </div>
      <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: "paymentView",
  data() {
    return {
      oldIndex: 1,
    }
  },
  watch: {
    $route(to) {
      // console.log(to);
      if(to.fullPath === "/payment/calendar") {
        this.changeActiveButton(0);
      }
      if(to.fullPath === "/payment/list") {
        this.changeActiveButton(1);
      }
      if(to.fullPath === "/payment/instructions") {
        this.changeActiveButton(2);
      }
    }
  },
  methods: {
    changeActiveButton() {
      // if (index >= this.oldIndex) {
      //   this.allLine[0].style.left = this.element[index].offsetLeft + "px";
      //   this.allLine[0].style.width = this.element[index].clientWidth + "px";
      // } else {
      //   this.allLine[0].style.left = this.element[index].offsetLeft + "px";
      //   this.allLine[0].style.width = this.element[index].clientWidth + "px";
      // }
      // this.oldIndex = index;
    }
  },
  mounted() {
    // this.element = document.getElementsByClassName("navigation-button-payment");
    // this.allLine = document.getElementsByClassName("line");
    // // console.log("saihudaiusydiasydiouasd");
    // // console.log(this.element);
    // this.allLine[0].style.left = this.element[this.oldIndex].offsetLeft + "px";
    // this.allLine[0].style.width = this.element[this.oldIndex].clientWidth + "px";
    //
    // setTimeout(()=> {
    //   if(this.$route.path === "/payment/calendar") {
    //     this.allLine[0].style.left = (this.element[0].offsetLeft) + "px";
    //     this.allLine[0].style.width = this.element[0].clientWidth + "px";
    //   }
    //   if(this.$route.path === "/payment/list") {
    //     this.allLine[0].style.left = (this.element[1].offsetLeft) + "px";
    //     this.allLine[0].style.width = this.element[1].clientWidth + "px";
    //   }
    //   if(this.$route.path === "/payment/instructions") {
    //     this.allLine[0].style.left = (this.element[2].offsetLeft) + "px";
    //     this.allLine[0].style.width = this.element[2].clientWidth + "px";
    //   }
    // }, 10)
  }
}
</script>

<style scoped lang="scss">
  @import "paymentView";
</style>