<template>
  <div class="wrap-list">
    <folder-in-list-vessel class="folder-item" :name="'Actual vessels ('+actualVessels.length+')'" :classTitle="'h36 text-gray p-0-12'">
      <button v-on:click="pushToContract(ship.id, ship)" :disabled="ship.id === contract.id" class="h28 link w100Proc p-0-12 pl24" :class="{'active': ship.id === contract.id}" v-for="(ship, i) in actualVessels" :key="i">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.34414 3.81787C9.34414 4.16672 9.06465 4.44952 8.71988 4.44952L6.9869 4.44952C6.64213 4.44952 6.36264 4.16672 6.36264 3.81787L6.30561 0.631655C6.30561 0.282801 6.5851 5.37849e-07 6.92987 5.37849e-07L8.66285 0C9.00762 0 9.28711 0.282801 9.28711 0.631654L9.34414 3.81787Z" fill="#9AA3B0"/>
          <path d="M10.6548 13.2088C8.91403 14.601 6.68811 14.601 4.9473 13.2088C4.07689 13.9049 3.08522 14.2875 2.09356 14.2875H1.51289C1.04555 14.2875 0.666696 14.6709 0.666687 15.1438C0.666678 15.6166 1.04554 16 1.51289 16H2.09356C3.07452 16 4.04836 15.7191 4.9473 15.1899C6.74516 16.2442 8.85695 16.2442 10.6548 15.1899C11.5537 15.715 12.5276 16 13.5085 16H14.0892C14.5566 16 14.9354 15.6167 14.9355 15.1438C14.9355 14.6709 14.5566 14.2875 14.0892 14.2875H13.5086C12.5169 14.2875 11.5252 13.9049 10.6548 13.2088Z" fill="#9AA3B0"/>
          <path d="M14.8364 7.17307C15.2826 7.358 15.5027 7.86825 15.3334 8.32502L13.6787 12.4121H13.5205C12.5288 12.4121 11.5371 12.0294 10.6667 11.3333C8.92591 12.7255 6.69999 12.7255 4.95918 11.3333C4.15814 11.974 3.25439 12.3491 2.34246 12.4048L0.780759 8.32502C0.611424 7.86825 0.831537 7.358 1.2777 7.17307L3.39914 6.62237L3.39913 3.13742C3.39913 2.78857 3.67862 2.50577 4.02339 2.50577L6.9869 2.50579H9.12722L11.6929 2.50516C12.0376 2.50516 12.3171 2.78796 12.3171 3.13682V6.62176L14.8364 7.17307Z" fill="#9AA3B0"/>
        </svg>
        <p class="fz14 text-dark text-dots-overflow">{{ship.name}} - {{ship.cp_date | formatDateFromCpDateWithotTimeZome}}</p>
      </button>
    </folder-in-list-vessel>
    <folder-in-list-vessel class="folder-item" :name="'Redelivered vessels ('+redeliveryVessels.length+')'" :classTitle="'h36 text-gray p-0-12'">
      <folder-in-list-vessel v-for="(year, i) in years" :key="i" :name="year + ' ('+getRedeliveryFromYear(year).length+')'" :classTitle="'h28 text-dark p-0-12 pl24'">
        <button v-on:click="pushToContract(ship.id, ship)" :disabled="ship.id === contract.id" class="h28 link w100Proc p-0-12 pl36" :class="{'active': ship.id === contract.id}" v-for="(ship, i) in getRedeliveryFromYear(year)" :key="i">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.34414 3.81787C9.34414 4.16672 9.06465 4.44952 8.71988 4.44952L6.9869 4.44952C6.64213 4.44952 6.36264 4.16672 6.36264 3.81787L6.30561 0.631655C6.30561 0.282801 6.5851 5.37849e-07 6.92987 5.37849e-07L8.66285 0C9.00762 0 9.28711 0.282801 9.28711 0.631654L9.34414 3.81787Z" fill="#9AA3B0"/>
            <path d="M10.6548 13.2088C8.91403 14.601 6.68811 14.601 4.9473 13.2088C4.07689 13.9049 3.08522 14.2875 2.09356 14.2875H1.51289C1.04555 14.2875 0.666696 14.6709 0.666687 15.1438C0.666678 15.6166 1.04554 16 1.51289 16H2.09356C3.07452 16 4.04836 15.7191 4.9473 15.1899C6.74516 16.2442 8.85695 16.2442 10.6548 15.1899C11.5537 15.715 12.5276 16 13.5085 16H14.0892C14.5566 16 14.9354 15.6167 14.9355 15.1438C14.9355 14.6709 14.5566 14.2875 14.0892 14.2875H13.5086C12.5169 14.2875 11.5252 13.9049 10.6548 13.2088Z" fill="#9AA3B0"/>
            <path d="M14.8364 7.17307C15.2826 7.358 15.5027 7.86825 15.3334 8.32502L13.6787 12.4121H13.5205C12.5288 12.4121 11.5371 12.0294 10.6667 11.3333C8.92591 12.7255 6.69999 12.7255 4.95918 11.3333C4.15814 11.974 3.25439 12.3491 2.34246 12.4048L0.780759 8.32502C0.611424 7.86825 0.831537 7.358 1.2777 7.17307L3.39914 6.62237L3.39913 3.13742C3.39913 2.78857 3.67862 2.50577 4.02339 2.50577L6.9869 2.50579H9.12722L11.6929 2.50516C12.0376 2.50516 12.3171 2.78796 12.3171 3.13682V6.62176L14.8364 7.17307Z" fill="#9AA3B0"/>
          </svg>
          <p class="fz14 text-dark text-dots-overflow">{{ship.name}} - {{ship.cp_date | formatDateFromCpDateWithotTimeZome}}</p>
        </button>
      </folder-in-list-vessel>
    </folder-in-list-vessel>
  </div>
</template>

<script>
import FolderInListVessel from "@/components/vessels/menu-list-vessel/folder-in-list-vessel";
import {mapActions, mapMutations, mapState} from "vuex";
import moment from 'moment'
export default {
  name: "menu-list-vessel",
  components: {FolderInListVessel},
  data() {
    return {
      ships: [],
      years: [],
    }
  },
  computed: {
    actualVessels() {
      let actuals = this.ships.filter(a=> !a.is_redelivery);
      actuals.sort((a, b) => moment(b.cp_date, 'YYYY-MM-DD') - moment(a.cp_date, 'YYYY-MM-DD'))
      return actuals
    },
    redeliveryVessels() {
      return this.ships.filter(a=> a.is_redelivery)
    },
    ...mapState({
      contract: state => state.contract.activeContract,
      changeNameVesselEmitForListLeftMenu: state => state.ships.changeNameVesselEmitForListLeftMenu,
    })
  },
  watch: {
    changeNameVesselEmitForListLeftMenu: function () {
      if(this.changeNameVesselEmitForListLeftMenu && this.changeNameVesselEmitForListLeftMenu.id) {
        let item = this.ships.find(a=> a.id === this.changeNameVesselEmitForListLeftMenu.id)
          if(item) {
            item.name = this.changeNameVesselEmitForListLeftMenu.name
            item.cp_date = this.changeNameVesselEmitForListLeftMenu.cp_date
          }
      }
    }
  },
  methods: {
    ...mapActions(['axiosGetAllContractForList', 'axiosOpenContractById',]),
    ...mapMutations(['setStartLoadNewVessel', 'showLoad', 'hideLoad', 'refreshContractStateAdd', 'changeContractBread']),
    getRedeliveryFromYear(year) {
      return this.redeliveryVessels.filter(a=> a.cp_date.indexOf(year)>=0)
    },
    async pushToContract(id, ship) {
      this.setStartLoadNewVessel(true)
      this.changeContractBread({
        index: 1,
        obj: {
          name: ship.name + ' ' + moment(ship.cp_date).format('DD.MM.YYYY'),
          link: `/vessels/${ship.id}/open/detail`
        }
      })
      window.location.href = window.location.href.replace('vessels/'+this.contract.id, 'vessels/'+id);
      if(this.$route.path.indexOf('open/detail') >=0) {
        this.refreshContractStateAdd();
      }
      this.showLoad();
      await this.axiosOpenContractById(id)
      this.hideLoad();
      this.setStartLoadNewVessel(false)
      this.$emit('renderNew')
    },
  },
  async mounted() {
    this.ships = await this.axiosGetAllContractForList();
    this.ships.sort((a, b) => moment(b.cp_date, 'YYYY-MM-DD') - moment(a.cp_date, 'YYYY-MM-DD'))
    this.years =  Array.from(new Set(this.redeliveryVessels.map(a=> {return moment(a.cp_date, 'YYYY-MM-DD').format('YYYY')})))
    this.years.sort((a, b) => moment(b, 'YYYY') - moment(a, 'YYYY'))
  }
}
</script>

<style scoped lang="scss">
  .wrap-list {
    overflow-y: auto;
    background-color: var(--color-white);
    position: absolute;
    top: 146px;
    width: 260px;
    left: 0;
    height: calc(100vh - 146px);
    z-index: 150;
    box-sizing: border-box;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25), inset 0px 0px 0px var(--color-gray-light);
    border-right: 1px solid var(--color-gray-light);
  }
  .active {
    background-color: var(--color-blue-lightest) !important;
  }
  .link {
    box-sizing: border-box;
    &:hover {
      background-color: var(--color-bgc-page);
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    svg {
      margin-right: 8px;
    }
    p {
      width: calc(100% - 24px);
      vertical-align: middle;
      text-align: left
    }
  }
  .folder-item {
    border-bottom: 1px solid var(--color-border);
  }
</style>