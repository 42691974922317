<template>
  <div 
    class="border border-dark-gray m-b-4 rounded-lg overflow-hidden"
    v-if="getComment.additional_info.comment || getRows.length"
  >
    <div class="vessel_owners__table">
      <div class="colspan-1-6 bg-blue-dark uppercase p-x-3 p-y-2 fz13 fw500 text-white">Charterers expenses</div>
    </div>

    <div 
      class="vessel_owners__table bg-white overflow-hidden"
      v-for="(item, i) in getRows"
      :key="i"
    >
      <div class="border-t border-r p-x-3 p-y-2 text-dark fw500">#{{1+i}}</div>
      <div class="border-t colspan-2-4 border-r p-x-2 p-y-2 text-dark">{{item.additional_info.title ? item.additional_info.title : ''}}</div>
      <div class="border-t border-r p-x-2 p-y-2 text-dark">{{item.debit | formatNumberTwoZeroEmpty}}</div>
      <div class="border-t p-x-2 p-y-2">{{item.credit | formatNumberTwoZeroEmpty}}</div>
    </div>

    <div 
      class="vessel_owners__table bg-white overflow-hidden rounded-bl-lg rounded-br-lg"
      v-if="getComment.additional_info.comment"
    >
      <div class="border-t border-r p-x-3 p-y-2 text-dark fw500">Comment</div>
      <div class="border-t colspan-2-4 border-r p-x-2 p-y-2 text-dark">{{getComment.additional_info.comment}}</div>
      <div class="border-t border-r"></div>
      <div class="border-t"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expensesData: Array
  },
  computed: {
    getRows() {
      return this.expensesData.filter(el => {
        return el.additional_info.is_row && el.expense_type == 'charterer_expense' && 
          !el.is_comment && 
          (el.debit || el.credit || el.additional_info.title)
      })
    },
    getComment() {
      return this.expensesData.filter(el => {
        return el.expense_type == 'charterer_expense' && el.is_comment
      })[0]
    }
  }
}
</script>

<style>

</style>