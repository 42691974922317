<template>
  <table border="1" id="myTable" width="100%" class="mails-table" ref="mailsTable">
    <thead>
      <tr class="table-row">
        <th class="icn-col"></th>
        <th class="icn-col">
          <label class="my-custom-checkbox">
            <input 
              :checked="emailsList.length == checkedEmails.length && emailsList.length != 0"
              @click="$emit('checkAllEmails')"
              type="checkbox" />
            <span></span>
          </label>
        </th>
        <th class="icn-col">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75 15.0041H13.25C14.2165 15.0041 15 14.2305 15 13.2762V6.79688L8.85796 10.208C8.32523 10.5039 7.67477 10.5039 7.14204 10.208L1 6.79688V13.2762C1 14.2305 1.7835 15.0041 2.75 15.0041Z" fill="#787F89" stroke="#787F89" stroke-width="1.4"/>
            <path d="M1 6.79281V13.2722C1 14.2264 1.7835 15 2.75 15H13.25C14.2165 15 15 14.2264 15 13.2722V6.79281M1 6.79281V6.36085V5.81002C1 5.08358 1.39389 4.41427 2.02896 4.06157L7.14204 1.2219C7.67477 0.926035 8.32523 0.926035 8.85796 1.2219L13.971 4.06157C14.6061 4.41427 15 5.08358 15 5.81002V6.79281M1 6.79281L7.14204 10.2039C7.67477 10.4998 8.32523 10.4998 8.85796 10.2039L15 6.79281M15 6.79281V6.36085" stroke="#787F89" stroke-width="1.4"/>
          </svg>
        </th>
        <th class="icn-col">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9581 6.16765C15.8528 5.8507 15.5654 5.62631 15.2268 5.59633L10.6081 5.18612L8.78273 1.00539C8.64796 0.698241 8.34131 0.5 8 0.5C7.65869 0.5 7.35192 0.698241 7.21801 1.00539L5.39266 5.18612L0.773206 5.59633C0.434578 5.62691 0.147831 5.8513 0.0418719 6.16765C-0.0634764 6.48459 0.0338151 6.83223 0.289923 7.05197L3.78131 10.047L2.75187 14.4825C2.67656 14.8087 2.80595 15.1459 3.08257 15.3415C3.23125 15.4472 3.40594 15.5 3.58123 15.5C3.73187 15.5 3.88263 15.4608 4.01727 15.382L8 13.0523L11.982 15.382C12.2741 15.5528 12.6414 15.5371 12.9174 15.3415C13.194 15.1459 13.3234 14.8087 13.2481 14.4825L12.2187 10.047L15.7101 7.05197C15.9661 6.83223 16.0635 6.48531 15.9581 6.16765Z" fill="#787F89"/>
          </svg>
        </th>
        <th class="icn-col">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15.5C5.24268 15.5 3 13.3975 3 10.8125L3 4.25C3 3.90439 3.29871 3.62504 3.66663 3.62504C4.03467 3.62504 4.33337 3.90439 4.33337 4.25L4.33337 10.8125C4.33337 12.7075 5.97803 14.25 8 14.25C10.022 14.25 11.6666 12.7075 11.6666 10.8125V3.93746C11.6666 2.73125 10.62 1.75004 9.33337 1.75004C8.04663 1.75004 7 2.73125 7 3.93746V10.1875C7 10.7044 7.44861 11.125 8 11.125C8.55139 11.125 9 10.7044 9 10.1875V4.25C9 3.90439 9.29871 3.62504 9.66663 3.62504C10.0347 3.62504 10.3334 3.90439 10.3334 4.25V10.1875C10.3334 11.3937 9.28662 12.375 8 12.375C6.71338 12.375 5.66663 11.3937 5.66663 10.1875V3.93746C5.66663 2.04255 7.31128 0.5 9.33337 0.5C11.3553 0.5 13 2.04255 13 3.93746V10.8125C13 13.3975 10.7573 15.5 8 15.5Z" fill="#787F89"/>
          </svg>
        </th>
        <th class="from-col">From</th>
        <th class="to-col">To</th>
        <th class="subject-col">Subject</th>
        <th class="tags-col">Tags</th>
        <th class="date-col">
          <span class="d-flex align-items-center">
            Sent time
             <svg v-on:click="$emit('changeOrdering')" :class="{rotateX180: orderinCreatedAt}" class="cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V11.1893L10.4697 9.46967C10.7626 9.17678 11.2374 9.17678 11.5303 9.46967C11.8232 9.76256 11.8232 10.2374 11.5303 10.5303L8.53033 13.5303C8.23744 13.8232 7.76256 13.8232 7.46967 13.5303L4.46967 10.5303C4.17678 10.2374 4.17678 9.76256 4.46967 9.46967C4.76256 9.17678 5.23744 9.17678 5.53033 9.46967L7.25 11.1893V4C7.25 3.58579 7.58579 3.25 8 3.25Z" fill="#787F89"/>
            </svg>
          </span>
        </th>
        <th class="size-col">Size</th>
      </tr>
    </thead>
    <tbody>
      <tr 
        class="table-row mail-row"
        :class="{new: !mail.is_read, active: activeMail == mail.id, checked: checkedEmails.includes(mail.id)}"
        @click="getMailDetailInfo($event, mail.id, mail.folder.id)"
        v-for="mail in emailsList" :key="mail.id">
        <td class="icn-col drag-btn">
          <label class="my-custom-checkbox ">
            <input
                type="checkbox"
                />
            <span></span>
          </label>
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.432 7.47187C10.432 8.18984 9.85 8.77187 9.13203 8.77187C8.41406 8.77187 7.83203 8.18984 7.83203 7.47187C7.83203 6.7539 8.41406 6.17187 9.13203 6.17188C9.85 6.17188 10.432 6.7539 10.432 7.47187Z" fill="#9AA3B0"/>
            <path d="M16.268 7.47187C16.268 8.18984 15.6859 8.77187 14.968 8.77187C14.25 8.77187 13.668 8.18984 13.668 7.47187C13.668 6.7539 14.25 6.17187 14.968 6.17188C15.6859 6.17188 16.268 6.7539 16.268 7.47187Z" fill="#9AA3B0"/>
            <path d="M10.4321 12.1008C10.4321 12.8188 9.85005 13.4008 9.13208 13.4008C8.41411 13.4008 7.83208 12.8188 7.83208 12.1008C7.83208 11.3828 8.41411 10.8008 9.13208 10.8008C9.85005 10.8008 10.4321 11.3828 10.4321 12.1008Z" fill="#9AA3B0"/>
            <path d="M16.268 12.1008C16.268 12.8188 15.686 13.4008 14.968 13.4008C14.2501 13.4008 13.668 12.8188 13.668 12.1008C13.668 11.3828 14.2501 10.8008 14.968 10.8008C15.686 10.8008 16.268 11.3828 16.268 12.1008Z" fill="#9AA3B0"/>
            <path d="M10.4321 16.7336C10.4321 17.4516 9.85005 18.0336 9.13208 18.0336C8.41411 18.0336 7.83208 17.4516 7.83208 16.7336C7.83208 16.0156 8.41411 15.4336 9.13208 15.4336C9.85005 15.4336 10.4321 16.0156 10.4321 16.7336Z" fill="#9AA3B0"/>
            <path d="M16.268 16.7336C16.268 17.4516 15.686 18.0336 14.968 18.0336C14.2501 18.0336 13.668 17.4516 13.668 16.7336C13.668 16.0156 14.2501 15.4336 14.968 15.4336C15.686 15.4336 16.268 16.0156 16.268 16.7336Z" fill="#9AA3B0"/>
          </svg>


        </td>
        <td class="icn-col checkbox-col">
          <label class="my-custom-checkbox">
            <input 
            type="checkbox" 
            :checked="checkedEmails.includes(mail.id)"
            @change="$emit('checkEmailItem', mail.id)"/>
            <span></span>
          </label>
        </td>
        <td class="icn-col">
          <!-- <span class="dot" :class="{active: !mail.is_read}"></span> -->
          <span class="dot"></span>
        </td>
        <td class="icn-col star" :class="{active: mail.is_important}" @click="toggleIsImportant(mail)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2131 10.2377L19.2129 10.2377L14.5638 9.81564L14.1476 9.77785L13.9834 9.39347L12.1465 5.09299C12.1465 5.0929 12.1464 5.09281 12.1464 5.09273C12.1207 5.03314 12.0645 4.99687 12.0019 4.99687C11.9387 4.99687 11.8834 5.03347 11.8586 5.09151L11.8585 5.09184L10.0211 9.39347L9.85693 9.77786L9.44066 9.81564L4.79185 10.2376C4.79173 10.2376 4.79162 10.2376 4.7915 10.2377C4.73003 10.2435 4.67708 10.2848 4.65683 10.3456C4.63752 10.406 4.65587 10.471 4.70256 10.512C4.70258 10.512 4.7026 10.512 4.70261 10.512L8.21693 13.5936L8.53034 13.8684L8.43805 14.2749L7.402 18.838C7.40198 18.8381 7.40197 18.8382 7.40195 18.8382C7.38766 18.9019 7.4123 18.9642 7.46216 19.0003L7.46355 19.0013C7.49059 19.0209 7.52162 19.0306 7.55403 19.0306C7.5814 19.0306 7.60858 19.0235 7.63351 19.0086L7.63371 19.0085L11.6426 16.6114L12.0019 16.3966L12.3612 16.6114L16.3691 19.0084C16.423 19.0406 16.49 19.0377 16.541 19.0007L16.5416 19.0003C16.5915 18.9642 16.6161 18.9018 16.6017 18.838L15.5657 14.2749L15.4734 13.8684L15.7868 13.5936L19.301 10.5121L19.2131 10.2377ZM19.2131 10.2377C19.2745 10.2433 19.3271 10.2846 19.3467 10.3449M19.2131 10.2377L19.3467 10.3449M19.3467 10.3449L20.0124 10.1284L19.3467 10.3449Z" stroke="#9AA3B0" stroke-width="1.4"/>
          </svg>

        </td>
        <td class="icn-col file" :class="{hasFile: mail.files.length != 0}">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15.5C5.24268 15.5 3 13.3975 3 10.8125L3 4.25C3 3.90439 3.29871 3.62504 3.66663 3.62504C4.03467 3.62504 4.33337 3.90439 4.33337 4.25L4.33337 10.8125C4.33337 12.7075 5.97803 14.25 8 14.25C10.022 14.25 11.6666 12.7075 11.6666 10.8125V3.93746C11.6666 2.73125 10.62 1.75004 9.33337 1.75004C8.04663 1.75004 7 2.73125 7 3.93746V10.1875C7 10.7044 7.44861 11.125 8 11.125C8.55139 11.125 9 10.7044 9 10.1875V4.25C9 3.90439 9.29871 3.62504 9.66663 3.62504C10.0347 3.62504 10.3334 3.90439 10.3334 4.25V10.1875C10.3334 11.3937 9.28662 12.375 8 12.375C6.71338 12.375 5.66663 11.3937 5.66663 10.1875V3.93746C5.66663 2.04255 7.31128 0.5 9.33337 0.5C11.3553 0.5 13 2.04255 13 3.93746V10.8125C13 13.3975 10.7573 15.5 8 15.5Z" fill="#787F89"/>
          </svg>
        </td>
        <td class="text" :title="mail.email_from">{{mail.email_from}}</td>
        <td class="text" 
          :class="{elementsCount: mail.email_to.length >= 2}" 
          :elCount="'+' + mail.email_to.length" 
          :title="mail.email_to.join(', ')">
          {{mail.email_to.join(', ')}}
        </td>
        <td class="text subject-col" :title="mail.subject" :class="{elementsCount: mail.replies_count}" :elCount="mail.replies_count">{{mail.subject}}</td>
        <td class="text" :class="{elementsCount: mail.tags.length >= 2}" :elCount="mail.tags.length">
          <div class="tags-wrapper">
            <span class="tag" v-for="tag in mail.tags" :key="tag.id">{{tag.title}}</span>
          </div>
        </td>
        <td class="text">{{mail.created_at | formatDateAndTimeSpace }}</td>
        <td class="size-col">
          <div class="menu-col">
            <span class="size-info" :title="mail.size">{{mail.size}}</span>
            <div class="menu" v-click-outside="hideSubMenu">
              <svg width="24" height="24" @click="showSubMenu($event)">
                <use xlink:href="#menu_icn"></use>
              </svg>
              <div class="sub-menu">
                <button class="sub-menu__button" @click="$emit('menuAction', {actionName: 'moveToFolder', info: mail})">
                  <svg width="24" height="24">
                    <use xlink:href="#move_to_icn"></use>
                  </svg>
                  <span>Move to</span>
                </button>
                <button v-if="['Archive'].includes(activeFolderName)" class="sub-menu__button" @click="$emit('menuAction', {actionName: 'unarchive', info: mail})">
                  <svg width="24" height="24">
                    <use xlink:href="#unarchive_icn"></use>
                  </svg>
                  <span>Unarchive</span>
                </button>
                <button v-if="['Spam'].includes(activeFolderName)" class="sub-menu__button" @click="$emit('menuAction', {actionName: 'notSpam', info: mail})">
                  <svg width="24" height="24">
                    <use xlink:href="#unarchive_icn"></use>
                  </svg>
                  <span>Not spam</span>
                </button>
                <button class="sub-menu__button" @click="$emit('menuAction', {actionName: 'download', info: mail})">
                  <svg width="24" height="24">
                    <use xlink:href="#download_icn"></use>
                  </svg>
                  <span>Download</span>
                </button>
                <button class="sub-menu__button" @click="$emit('menuAction', {actionName: 'makeUnread', info: mail})">
                  <svg width="24" height="24">
                    <use xlink:href="#opened_envelope_icn"></use>
                  </svg>
                  <span>Mark as {{mail.is_read ? 'unread' : 'read'}}</span>
                </button>
                <button class="sub-menu__button" v-if="!['Spam'].includes(activeFolderName)" @click="$emit('menuAction', {actionName: 'attachTo', info: mail})">
                  <svg width="24" height="24">
                    <use xlink:href="#attach_to_icn"></use>
                  </svg>
                  <span>Attach to...</span>
                </button>
                <button v-if="!['Archive'].includes(activeFolderName)" class="sub-menu__button" @click="$emit('menuAction', {actionName: 'toArchive', info: mail})">
                  <svg width="24" height="24">
                    <use xlink:href="#to_archive_icn"></use>
                  </svg>
                  <span>To archive</span>
                </button>
                
                <button v-if="['Draft', 'Archive', 'Spam'].includes(activeFolderName)" class="sub-menu__button" @click="$emit('menuAction', {actionName: 'deleteForever', info: mail})">
                  <svg width="24" height="24">
                    <use xlink:href="#delete_icn"></use>
                  </svg>
                  <span>Delete forever</span>
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import CustomColumnResizer from 'custom-column-resizer';
  export default {
    name: 'mail-list',
    props: {
      orderinCreatedAt: Boolean,
      emailsList: {type: Array},
      checkedEmails: {type: Array},
      activeFolderName: {default: null},
      activeMail: Number,
    },
    inject: ['getMailDetail'],
    data: () => ({
      table: null,
      // activeMail: null,
    }),

    methods: {
      createTable(){
        // console.log('create table::: ');
        if(this.table) this.table.reset({ disable: true })
        // if(this.table) return
        let tablePercentWidth = document.querySelector("#myTable").offsetWidth/100;
        this.table = new CustomColumnResizer(document.querySelector("#myTable"),{
          liveDrag: false,
          draggingClass:"rangeDrag",
          gripInnerHtml:"<div class='rangeGrip'></div>",
          minWidth: 100,
          resizeMode: 'fit' ,
          disabledColumns: [0, 1, 2, 3, 4],
          partialRefresh: true,
          serialize: true,
          // widths: [32, 32, 32, 32, 32, null, null, null, 100, 150, 100],
          widths: [32, 32, 32, 32, 32, tablePercentWidth*15, tablePercentWidth*15, tablePercentWidth*15, tablePercentWidth*10, tablePercentWidth*15, tablePercentWidth*10],
          onResize: () => {
            this.getHiddenTags()
          }
        });
        this.getHiddenTags()
      },
      getMailDetailInfo(e, mailId, folderId, skipIf){
        // Не загружать деталку письма если клик на action колонку
        let actionsCols = ['checkbox-col', 'drag-btn', 'star', 'size-col']
        if(e && e.target && e.target.closest('td') && ![...e.target.closest('td').classList].some(className => actionsCols.includes(className)) || skipIf == true){
          this.getMailDetail(mailId, folderId)
          // this.activeMail = mailId;
        }
      },
      showSubMenu(e){
        let overflowParent = document.querySelectorAll('.splitter-paneL')[0]
        setTimeout(() => {
          if(overflowParent && e.target.getBoundingClientRect().bottom > overflowParent.clientHeight){
            e.target.nextSibling.classList.add('show', 'show_top')
          }else{
            e.target.nextSibling.classList.remove('show_top')
            e.target.nextSibling.classList.add('show')
          }
        }, 100)
      },
      hideSubMenu(){
        let submenues = this.$refs.mailsTable.querySelectorAll('.sub-menu.show')
        submenues.forEach(element => {
          element.classList.remove('show', 'show_top')
        });
      },
      toggleIsImportant(mail_item){
        let data = {
          ids: [mail_item.id],
          is_important: !mail_item.is_important,
          is_read: mail_item.is_read,
          tags: mail_item.tags,
          folder: mail_item.folder.id
        }
        this.$emit('updateMailItem', data)
      },
      getHiddenTags(){
        let tagsWrappers = this.$refs.mailsTable.querySelectorAll('.tags-wrapper')
        tagsWrappers.forEach(el => {
          let wrapperWidth = el.clientWidth;
          let contentWidth = el.scrollWidth;
          let isOverflowX = contentWidth > wrapperWidth;
          var overflowXAmount = isOverflowX ? contentWidth - wrapperWidth : 0;
          if(overflowXAmount == 0){
            el.closest('.text').classList.remove('elementsCount')
          }else{
            el.closest('.text').classList.add('elementsCount')
          }
        })
      }
    },
    mounted(){
      // this.createTable()
    },
  }
</script>

<style lang="scss" scoped>
@import "./src/components/mailbox/styles/mailsTableStyles";
.tags-wrapper{
  height: 25px;
  overflow: hidden;
  padding-top: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.drag-btn {
  .my-custom-checkbox {
    position: absolute;
    z-index: 10;
    top: 4px;
    opacity: 0;
  }
}
</style>