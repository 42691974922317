<template>
  <transition name="fade">
    <div class="wrap-transition" v-if="show">
      <div class="filter" v-on:click="hideMenu"></div>
      <div class="wrap" :class="{'show-menu': show}">
        <div class="top-block">
          <button class="close-menu fz14 fw500 text-dark" v-on:click="hideMenu">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
              <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
            Filter
          </button>
          <div class="clear-all text-white fz12" v-if="getCountActiveFilter() !== 0">
            {{getCountActiveFilter()}}
            <svg v-on:click="clearAll" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
              <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
            </svg>

          </div>
        </div>
        <!--      {{routesDetails}}-->
        <div v-if="$route.path.indexOf('actual')>=0">
          <div class="block-pre-dropdown">
            <button class="checkbox-button" v-on:click="changeCheckbox">
              <svg v-if="!showNotCargo" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
              </svg>
              <svg class="active-checkbox-style" v-if="showNotCargo" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="18" height="18" rx="4" fill="#094172"/>
                <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
              </svg>
              <p class="fz13 fw500 text-dark">Show Not Linked Cargos</p>
            </button>
          </div>
        </div>
        <div class="wrap-drop">
          <button class="clear fz13 text-red" v-if="assignedChartering.length" v-on:click="clearChartere">Clear</button>
          <div class="block-pre-dropdown" v-on:click="hideBlock1 = !hideBlock1">
            <p class="fz13 fw500 text-dark">Chartering Manager</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock1}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" v-if="!hideBlock1">
            <assignee-task :key="keyRenderAssigned" :default-active="defaultActiveChartering" :one-user="false" ref="assigneeTask" @oneElementClick="setAssignedChartering"></assignee-task>
          </div>
        </div>
        <div class="wrap-drop">
          <button class="clear fz13 text-red" v-if="assignedOperations.length" v-on:click="clearOperation">Clear</button>
          <div class="block-pre-dropdown" v-on:click="hideBlock2 = !hideBlock2">
            <p class="fz13 fw500 text-dark">Operation Manager</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock2}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" v-if="!hideBlock2">
            <assignee-task :key="keyRenderAssigned" :default-active="defaultActiveOperations" :one-user="false" ref="assigneeTask1" @oneElementClick="setAssignedOperations"></assignee-task>
          </div>
        </div>
        <div v-if="$route.path.indexOf('actual') >= 0" class="block-date">
          <button class="clear fz13 text-red" v-if="datepickFrom || datepickTo" v-on:click="clearCpDate">Clear</button>
          <div class="block-pre-dropdown" v-on:click="hideBlock7 = !hideBlock7">
            <p class="fz13 fw500 text-dark">CP Date</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock7}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" v-if="!hideBlock7">
            <div class="input-from-to">
              <date-picker
                  value-type="date"
                  placeholder="Choose period"
                  class="datepickeer"
                  v-model="datepickFrom"
                  format="DD.MM.YYYY"
                  @change="createEmitFilterAxios"
              ></date-picker>
              <date-picker
                  value-type="date"
                  placeholder="Choose period"
                  class="datepickeer"
                  v-model="datepickTo"
                  format="DD.MM.YYYY"
                  @change="createEmitFilterAxios"
              ></date-picker>
              <!--              <input type="text" placeholder="From" @focusout="createEmitFilterAxios" v-model="paymentFrom">-->
              <!--              <input type="text" placeholder="To" @focusout="createEmitFilterAxios" v-model="paymentTo">-->
            </div>
          </div>
        </div>
        <div class="wrap-drop" v-if="$route.path.indexOf('redelivered') >= 0">
          <button class="clear fz13 text-red" v-if="yearFrom || yearTo" v-on:click="clearYear">Clear</button>
          <div class="block-pre-dropdown" v-on:click="hideBlock3 = !hideBlock3">
            <p class="fz13 fw500 text-dark">Year</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock3}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" v-if="!hideBlock3">
            <div class="input-from-to">
              <input type="text" @input="yearFrom = checkNum(yearFrom)" placeholder="From" @focusout="createEmitFilterAxios" v-model="yearFrom">
              <input type="text" @input="yearTo = checkNum(yearTo)" placeholder="To" @focusout="createEmitFilterAxios" v-model="yearTo">
            </div>
          </div>
        </div>
        <div class="wrap-drop">
          <button class="clear fz13 text-red" v-if="builtFrom || builtTo" v-on:click="clearBuilt">Clear</button>
          <div class="block-pre-dropdown" v-on:click="hideBlock4 = !hideBlock4">
            <p class="fz13 fw500 text-dark">Built</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock4}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" v-if="!hideBlock4">
            <div class="input-from-to">
              <input type="text" @input="builtFrom = checkNum(builtFrom)" placeholder="From" @focusout="createEmitFilterAxios" v-model="builtFrom">
              <input type="text" @input="builtTo = checkNum(builtTo)" placeholder="To" @focusout="createEmitFilterAxios" v-model="builtTo">
            </div>
          </div>
        </div>
        <div class="wrap-drop">
          <button class="clear fz13 text-red" v-if="dwtFrom || dwtTo" v-on:click="clearDwt">Clear</button>
          <div class="block-pre-dropdown" v-on:click="hideBlock5 = !hideBlock5">
            <p class="fz13 fw500 text-dark">DWT</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock5}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" v-if="!hideBlock5">
            <div class="block-status">
              <div class="input-from-to">
                <input type="text" @input="dwtFrom = checkNum(dwtFrom)" placeholder="From" @focusout="createEmitFilterAxios" v-model="dwtFrom">
                <input type="text" @input="dwtTo = checkNum(dwtTo)" placeholder="To" @focusout="createEmitFilterAxios" v-model="dwtTo">
              </div>
            </div>
          </div>
        </div>
<!--        {{defaultActiveChartering}}-->
<!--        {{$route.path}}-->
        <div class="wrap-drop">
          <button class="clear fz13 text-red" v-if="activeCounterparty.length" v-on:click="clearOwner">Clear</button>
          <div class="block-pre-dropdown" v-on:click="hideBlock6 = !hideBlock6">
            <p class="fz13 fw500 text-dark">Owner</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock6}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" v-if="!hideBlock6">
            <counterparties-drop
                :key="keyRenderOwner"
                :is-multiple="true"
                :list="getClientsFormAddModal"
                :title="'Counterparty'"
                :active-item="activeCounterpartyDefault"
                @select="setActiveCounterparty"
            ></counterparties-drop>
          </div>
        </div>
        <div class="wrap-drop">
          <button class="clear fz13 text-red" v-if="activeCommonName.length" v-on:click="clearCommonName">Clear</button>
          <div class="block-pre-dropdown" v-on:click="hideBlock8 = !hideBlock8">
            <p class="fz13 fw500 text-dark">Common name</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock8}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" v-if="!hideBlock8">
            <async-select
                v-model="activeCommonName"
                :allowEmpty="true"
                :closeOnSelect="false"
                :isMultiple="true"
                label=""
                url="/client/dropdown"
                :placeholder="''"
                :params="{ordering: 'charter_name', is_archived: false, is_charter_name: true}"
                watchKey="charter_name"
                @input="createEmitFilterAxios"
            ></async-select>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import CounterpartiesDrop from "@/components/counterparties/counterpartiesAdd/counterpartiesDrop";
import AssigneeTask from "@/components/tasks/manually-task-add/assignee-task";
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import AsyncSelect from "@/components/reuse/asyncSelect";


export default {
  components: {AsyncSelect, DatePicker, AssigneeTask, CounterpartiesDrop},
  computed: {
    ...mapGetters(["getValutaList", "getPaymentsAccount", "getPaymentsCash", "GetDashboardPushYear"]),
    ...mapState({
      // clientsType: state => state.counterpartiesType.clientsType,
      // clientsSubtype: state => state.counterpartiesType.clientsSubtype,
      clients: state => state.counterparties.clientsDrop,

    }),
    getClientsFormAddModal() {
      let clients = [];
      clients = this.clients.map(a=> {
        return {id: a.id, name: a.company_name}
      })
      return clients
    },
  },

  name: "actual-vessel-filter",

  data() {
    return {
      defaultActiveOperations: [],
      defaultActiveChartering: [],
      assignedOperations: [],
      assignedChartering: [],
      show: false,
      hideBlock1: false,
      hideBlock2: false,
      hideBlock3: false,
      hideBlock4: false,
      hideBlock5: false,
      hideBlock6: false,
      hideBlock7: false,
      hideBlock8: false,
      showNotCargo: false,
      datepickFrom: null,
      datepickTo: null,
      activeCounterpartyDefault: null,
      activeCommonName: [],
      builtTo: "",
      builtFrom: "",
      dwtTo: "",
      dwtFrom: "",
      activeCounterparty: [],
      yearFrom: "",
      yearTo: "",
      keyRenderAssigned: -92131,
      keyRenderOwner: -2423,
    }
  },
  watch: {
    $route: function () {
      let data = JSON.parse(localStorage.getItem("vesselsFilter" + this.$route.path));
      if(data !== null && (""+data).length !== 0) {
        this.defaultActiveOperations = data.defaultActiveOperations;
        this.defaultActiveChartering = data.defaultActiveChartering;
        this.hideBlock1 = data.hideBlock1;
        this.hideBlock2 = data.hideBlock2;
        this.hideBlock3 = data.hideBlock3
        this.hideBlock4 = data.hideBlock4
        this.hideBlock5 = data.hideBlock5
        this.hideBlock6 = data.hideBlock6
        this.hideBlock7 = data.hideBlock7
        this.hideBlock8 = data.hideBlock8
        this.showNotCargo = data.showNotCargo
        this.datepickFrom = data.datepickFrom !== null && (""+data.datepickFrom).length !== null ? new Date(data.datepickFrom) : null
        this.datepickTo = data.datepickTo !== null && (""+data.datepickTo).length !== null ? new Date(data.datepickTo) : null
        this.activeCounterpartyDefault = data.activeCounterpartyDefault
        this.activeCommonName = data.activeCommonName
        this.builtTo = data.builtTo
        this.builtFrom = data.builtFrom
        this.dwtTo = data.dwtTo
        this.dwtFrom = data.dwtFrom
        this.yearFrom = data.yearFrom
        this.yearTo = data.yearTo
        this.keyRenderAssigned++;
      }
      if(this.GetDashboardPushYear !== null) {
        this.clearAll();
        this.yearFrom = this.GetDashboardPushYear;
        this.yearTo = this.GetDashboardPushYear;
        this.setDashboardPushYear(null)
        this.saveLocalStore();
      }
      this.createEmitFilterAxios();
    }
  },
  methods: {
    ...mapActions(["axiosGetClientsForDropdown"]),
    ...mapMutations(["setDashboardPushYear"]),
    changeCheckbox() {
      this.showNotCargo = !this.showNotCargo;
      this.$emit('changeShowNotLinkedCargo', this.showNotCargo)
      if(this.showNotCargo) {
        this.createEmitFilterAxios();
      }
    },
    clearAll() {
      this.defaultActiveOperations = [];
      this.defaultActiveChartering = [];
      this.assignedOperations = [];
      this.assignedChartering = [];
      this.hideBlock1 = false
      this.hideBlock2 = false
      this.hideBlock3 = false
      this.hideBlock4 = false
      this.hideBlock5 = false
      this.hideBlock6 = false
      this.hideBlock7 = false
      this.hideBlock8 = false
      this.datepickFrom = null
      this.datepickTo = null
      this.activeCounterpartyDefault = null;
      this.activeCommonName = []
      this.activeCounterparty = []
      this.builtTo = ""
      this.showNotCargo = false;
      this.builtFrom = ""
      this.dwtTo = ""
      this.dwtFrom = ""
      this.yearFrom = ""
      this.yearTo = ""
      this.keyRenderAssigned++;
      this.keyRenderOwner++;
      this.createEmitFilterAxios();
    },
    getCountActiveFilter() {
      let count = 0;
      if(Array.isArray(this.activeCounterparty)) {
        count+=this.activeCounterparty.length
      }
      if(Array.isArray(this.assignedChartering)) {
        count+=this.assignedChartering.length
      }
      if(Array.isArray(this.assignedOperations)) {
        count += this.assignedOperations.length
      }
      if(Array.isArray(this.activeCommonName) == true) {
        count += this.activeCommonName.length
      }
      if(this.dwtFrom !== null && ("" + this.dwtFrom).length !== 0) {
        count++;
      }
      if(this.dwtTo !== null && ("" + this.dwtTo).length !== 0) {
        count++;
      }

      if(this.builtFrom !== null && ("" + this.builtFrom).length !== 0) {
        count++;
      }
      if(this.builtTo !== null && ("" + this.builtTo).length !== 0) {
        count++;
      }

      if(this.$route.path.indexOf('actual')>=0) {
        if(this.showNotCargo) {
          count++;
        }
        if(this.datepickFrom !== null && ("" + this.datepickFrom).length !== 0) {
          count++;
        }
        if(this.datepickTo !== null && ("" + this.datepickTo).length !== 0) {
          count++;
        }
      } else  {
        if(this.yearTo !== null && ("" + this.yearTo).length !== 0) {
          count++;
        }
        if(this.yearFrom !== null && ("" + this.yearFrom).length !== 0) {
          count++;
        }
      }
      return count;
    },
    clearOwner() {
      this.activeCounterpartyDefault = [];
      this.activeCounterparty = [];
      this.createEmitFilterAxios();
    },
    clearCommonName() {
      this.activeCommonName = []
      this.createEmitFilterAxios();
    },
    clearDwt() {
      this.dwtFrom = "";
      this.dwtTo = "";
      this.createEmitFilterAxios();
    },
    clearYear() {
      this.yearTo = "";
      this.yearFrom = "";
      this.createEmitFilterAxios();
    },
    clearBuilt() {
      this.builtFrom = "";
      this.builtTo = "";
      this.createEmitFilterAxios();
    },
    clearCpDate() {
      this.datepickFrom = "";
      this.datepickTo = "";
      this.createEmitFilterAxios();
    },
    clearOperation() {
      this.$refs.assigneeTask1.clearData();
      this.defaultActiveOperations = [];
      this.assignedOperations = [];
      this.createEmitFilterAxios();
    },
    clearChartere() {
      this.$refs.assigneeTask.clearData();
      this.defaultActiveChartering = [];
      this.assignedChartering = [];
      this.createEmitFilterAxios();
    },
    checkNum(num) {
      if(num.indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");

        if(num2.length > 4) {
          num2 = num2.substring(0, 4);
        }
        num = num1 + "." + num2;
      } else {
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    setActiveCounterparty(data) {
      this.activeCounterparty = data;
      this.createEmitFilterAxios();
    },
    setAssignedOperations(data) {
      this.assignedOperations = data;
      this.createEmitFilterAxios();
    },
    setAssignedChartering(data) {
      this.assignedChartering = data;
      this.createEmitFilterAxios();
    },
    showMenu() {
      this.show = true;
    },
    hideMenu() {
      this.activeAccountNameDefault = this.activeAccountName;
      this.activeBankAccountDefault = this.activeBankAccount;
      this.activeCounterpartyDefault = this.activeCounterparty;
      this.activeValuePaymentDefault = this.activeValuePayment;
      this.show = false;
      // let data = {
      //   activeAccountNameDefault: this.activeAccountName,
      //   activeBankAccountDefault: this.activeBankAccount,
      //   activeCounterpartyDefault: this.activeCounterparty,
      //   activeValuePaymentDefault: this.activeValuePayment,
      //
      // }
      this.saveLocalStore();
      this.defaultActiveOperations = Array.isArray(this.assignedOperations) ? this.assignedOperations.map(a=> {return a.id}) : [];
      this.defaultActiveChartering = Array.isArray(this.assignedChartering) ? this.assignedChartering.map(a=> {return a.id}) : [];
    },
    saveLocalStore() {
      let data = {
        defaultActiveOperations: Array.isArray(this.assignedOperations) ? this.assignedOperations.map(a=> {return a.id}) : [],
        defaultActiveChartering: Array.isArray(this.assignedChartering) ? this.assignedChartering.map(a=> {return a.id}) : [],
        hideBlock1: this.hideBlock1,
        hideBlock2: this.hideBlock2,
        hideBlock3: this.hideBlock3,
        hideBlock4: this.hideBlock4,
        hideBlock5: this.hideBlock5,
        hideBlock6: this.hideBlock6,
        hideBlock7: this.hideBlock7,
        hideBlock8: this.hideBlock8,
        datepickFrom: this.datepickFrom,
        showNotCargo: this.showNotCargo,
        datepickTo: this.datepickTo,
        activeCounterpartyDefault: this.activeCounterpartyDefault,
        activeCommonName: this.activeCommonName,
        builtTo: this.builtTo,
        builtFrom: this.builtFrom,
        dwtTo: this.dwtTo,
        dwtFrom: this.dwtFrom,
        yearFrom: this.yearFrom,
        yearTo: this.yearTo,
      }
      localStorage.setItem("vesselsFilter" + this.$route.path, JSON.stringify(data));
    },
    createEmitFilterAxios() {
      // console.log(this.assignedOperations)
      // console.log(this.assignedChartering)
      if((this.yearFrom > this.yearTo) && this.yearFrom.length !== 0 && this.yearTo.length !== 0) {
        let a = this.yearTo;
        this.yearTo = this.yearFrom;
        this.yearFrom = a;
      }
      if((this.dwtFrom > this.dwtTo) && this.dwtFrom.length !== 0 && this.dwtTo.length !== 0) {
        let a = this.dwtTo;
        this.dwtTo = this.dwtFrom;
        this.dwtFrom = a;
      }
      if((this.builtFrom > this.builtTo) && this.builtFrom.length !== 0 && this.builtTo.length !== 0) {
        let a = this.builtTo;
        this.builtTo = this.builtFrom;
        this.builtFrom = a;
      }
      let cp_date_from = null;
      let cp_date_to = null;
      if(this.datepickFrom !== null && (this.datepickFrom).length !== 0) {
        cp_date_from = moment(this.datepickFrom).format('YYYY-MM-DD');
      }
      if(this.datepickTo !== null && (this.datepickTo).length !== 0) {
        cp_date_to = moment(this.datepickTo).format('YYYY-MM-DD');
      }
      let built_to = null;
      let built_from = null;
      if(this.builtTo.length !== 0) {
        built_to = this.builtTo;
      }
      if(this.builtFrom.length !== 0) {
        built_from = this.builtFrom;
      }
      let dwt_to = null;
      let dwt_from = null;
      if(this.dwtTo.length !== 0) {
        dwt_to = this.dwtTo;
      }
      if(this.dwtFrom.length !== 0) {
        dwt_from = this.dwtFrom;
      }
      if(this.$route.path.indexOf('redeliver')>=0) {
        if((""+this.yearFrom).length !== 0  && this.yearFrom !== null) {
          cp_date_from = moment((new Date(this.yearFrom, 0, 1))).format('YYYY-MM-DD')
        } else {
          cp_date_from = null;
        }
        if((""+this.yearTo).length !== 0 && this.yearTo !== null) {
          cp_date_to = moment((new Date(this.yearTo, 11, 31))).format('YYYY-MM-DD')
        } else {
          cp_date_to = null;
        }
      }
      let charter_names = this.activeCommonName.map(a=> {return a.charter_name})
      let form = {
        operations: this.assignedOperations.map(a=> {return a.id}),
        chartering: this.assignedChartering.map(a=> {return a.id}),
        charter_names: charter_names,
        dwt_min: dwt_from,
        dwt_max: dwt_to,
        buit_min: built_from,
        buit_max: built_to,
        cp_date_from: cp_date_from,
        cp_date_to: cp_date_to,
        owners: this.activeCounterparty.map(a=> {return a.id}),
      }
      // console.log(form)
      form.not_linked_cargo = this.showNotCargo
      // if(this.$route.path.indexOf('actual')>=0 && this.showNotCargo) {
      //   form.not_linked_cargo = true;
      //   form.contract_type = "SHIP";
      // }
      this.saveLocalStore()
      this.$emit("changeFilter", form)
    }
  },
  mounted() {
    // if(!this.clients.length) {
    //   this.axiosGetClientsForDropdown()
    // }
    let data = JSON.parse(localStorage.getItem("vesselsFilter" + this.$route.path));
    if(data !== null && (""+data).length !== 0) {
      this.defaultActiveOperations = data.defaultActiveOperations;
      this.defaultActiveChartering = data.defaultActiveChartering;
      this.hideBlock1 = data.hideBlock1;
      this.hideBlock2 = data.hideBlock2;
      this.hideBlock3 = data.hideBlock3
      this.hideBlock4 = data.hideBlock4
      this.hideBlock5 = data.hideBlock5
      this.hideBlock6 = data.hideBlock6
      this.hideBlock7 = data.hideBlock7
      this.hideBlock8 = data.hideBlock8
      this.showNotCargo = data.showNotCargo
      this.datepickFrom = data.datepickFrom !== null && (""+data.datepickFrom).length !== null ? new Date(data.datepickFrom) : null
      this.datepickTo = data.datepickTo !== null && (""+data.datepickTo).length !== null ? new Date(data.datepickTo) : null
      this.activeCounterpartyDefault = data.activeCounterpartyDefault
      this.activeCommonName = data.activeCommonName
      this.builtTo = data.builtTo
      this.builtFrom = data.builtFrom
      this.dwtTo = data.dwtTo
      this.dwtFrom = data.dwtFrom
      this.yearFrom = data.yearFrom
      this.yearTo = data.yearTo
      this.keyRenderAssigned++;
    }
    if(this.GetDashboardPushYear !== null) {
      this.clearAll();
      this.yearFrom = this.GetDashboardPushYear;
      this.yearTo = this.GetDashboardPushYear;
      this.setDashboardPushYear(null)
      this.saveLocalStore();
    }
    this.createEmitFilterAxios();

  },
}
</script>

<style scoped lang="scss">
.wrap-transition {
  position: fixed;
  top: 0px;
  right: 0;
  width: 100vw;
  height: calc(100vh);
  z-index: 1050;
  @media (max-width: 1280px) {
    height: 100vh;
  }
  @media (max-width: 768px) {
    top: 44px;
    position: fixed;
    height: calc(100% - 44px);
  }
}

.filter {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  right: 0;
  opacity: 0.15;
  background-color: var(--color-filter-modal);
}

.dart-mode {
  .filter {
    opacity: 0.75;
  }
}

.wrap {
  z-index: 50;
  width: 318px;
  box-sizing: border-box;
  border: 1px solid var(--color-card-border);
  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.15);
  background-color: var(--color-modal);
  //height: calc(100% - 56px);
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    height: calc(100%);
  }
  overflow-y: auto;
  position: absolute;
  //top: 56px;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: .3s linear;
  &>*:not(:first-child) {
    padding: 12px 16px 12px 16px;

    border-top: 1px solid var(--color-card-border);

  }
}

.top-block {
  height: 50px;
  box-sizing: border-box;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .close-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 12px;
    }
  }
}

.dart-mode {
  .top-block {
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }
}

.input-from-to {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  input {
    padding: 8px 10px;
    height: 32px;
    &:nth-child(1) {
      border-radius: 4px 0 0 4px;
    }
    &:nth-child(2) {
      border-radius: 0 4px 4px 0;
    }
  }
}



.block-pre-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .button-dropdown {
    svg {
      transition: .2s linear;
    }
  }
  cursor: pointer;
}
.wrap-drop {
  position: relative;

}
.clear {
  opacity: 0.7;
  position: absolute;
  right: 40px;
  top: 13px;
  &:hover {
    opacity: 1;
  }
}


.content {
  padding-top: 12px;
  box-sizing: border-box;
  transition: max-height 1s linear;
  /deep/ {
    .button-drop {
      border-radius: 4px !important;
    }
    .counterparties-drop {
      margin: 0 !important;
    }
    .counterparties-drop_title {
      display: none;
    }
    .btn {
      height: 32px;
    }
  }
}

.hide-content {
  max-height: 0px;
}



.rotate180 {
  transform: rotateX(180deg);
}

.block-input {
  padding-top: 8px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  input {
    background-color: var(--color-card);
    width: 50%;
    box-sizing: border-box;
    &:nth-child(2) {
      border-radius: 4px 0 0 4px;
      padding-left: 40px;
    }
    &:nth-child(3) {
      border-radius: 0 4px 4px 0;
    }
  }
  svg {
    position: absolute;
    left: 8px;
  }
}

.show-menu {
  transform: translateX(0);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  .wrap {
    transform: translateX(100%);
  }
}

.mt12 {
  margin-top: 12px;
}

.clear-all {
  cursor: pointer;
  display: flex;
  width: max-content;
  height: max-content;
  padding: 2px 5px;
  border-radius: 15px;
  background-color: var(--color-blue);
  align-items: center;
  opacity: 0.9;
  svg {
    path {
      fill: var(--color-white)
    }
  }
  &:hover {
    opacity: 1;
  }
}
.checkbox-button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  svg {
    margin-right: 9px;
  }
}

.block-date {
  position: relative;
}


</style>