import axios from 'axios'

export default {
  actions: {
    async axiosAddBerth(ctx, berth) {
      ctx.commit('showLoad')
      let filesId = await ctx.dispatch('axiosAddPortFiles', berth.files)
      berth.files = filesId

      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/port/berth/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: berth
      })
      .then(response => {
        // console.log('axiosAddBerth: ', response)
        ctx.commit('hideLoad')
        return response.data
      })
      .catch(error => {
        console.log('axiosAddBerth error: ', error)
        ctx.commit('hideLoad')
      })
    },
    async axiosEditBerth(ctx, berth) {
      if(berth.files.length)
        berth.files = berth.files.map(el=>el.id)
        delete berth.files
      return await axios({
        method: 'patch',
        url: process.env.VUE_APP_URL+'/port/berth/'+berth.id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: berth
      })
      .then(response => {
        console.log('axiosEditBerth: ', response)
        return response.data
      })
      .catch(error => {
        console.warn('axiosEditBerth error: ', error)
      })
    },
  },
  mutations: {
  },
  state: {
  },
}