<template>
  <div class="wrap-activity">

    <div class="wrap-text-block" v-for="(a, i) in getActivityData" :key="i">
      <p class="date fz12 text-gray" v-if="a.changes.length || a.action === 'create'">{{getDateFormat(a.created_at)}}</p>
      <p class="name fz14 text-dark" v-if="a.action === 'create'">
        <span class="text-blue">{{a.user.first_name}} {{a.user.last_name}}</span>
        created the Vessel
      </p>
      <p class="name fz14 text-dark" v-if="a.action === 'update' && a.changes.length">
        <span class="text-blue">{{a.user.first_name}} {{a.user.last_name}}</span>
        <span v-if="a.changes[0].field === 'status'"> changed the status from "{{getTextForStatus(a.changes[0].changed_from)}}" to "{{getTextForStatus(a.changes[0].changed_to)}}"</span>
        <span v-if="a.changes[0].field === 'paid_date' && a.changes[0].changed_to !== null && a.changes[0].changed_to.length"> changed the paid date to <span class="text-gray">{{getDateFormChangedActivity(a.changes[0].changed_to)}}</span></span>
        <span v-if="a.changes[0].field === 'paid_date' && (a.changes[0].changed_to === null || !a.changes[0].changed_to.length)"> removed a payment date</span>
        <span v-if="a.changes[0].field === 'files'"> {{textForFilesType(a.changes[0].changed_from.length, a.changes[0].changed_to.length)}}</span>
        <span v-if="a.changes[0].field === 'receipt_files'"> {{textForReceiptFilesType(a.changes[0].changed_from.length, a.changes[0].changed_to.length)}}</span>
<!--        <span v-if="a.changes[0].field === 'bank' && a.changes[0].changed_to !== null"> changed the {{a.changes[0].changed_to.holder}}</span>-->
<!--        <span v-if="a.changes[0].field === 'bank' && a.changes[0].changed_to === null"> clear Bank and Cash Accounts</span>-->
        <span v-if="a.changes[0].field === 'is_archive' && a.changes[0].changed_to.toLowerCase() === 'false'"> unarchived this vessel</span>
        <span v-if="a.changes[0].field === 'is_archive' && a.changes[0].changed_to.toLowerCase() === 'true'"> archived this vessel</span>
<!--        <span v-if="a.changes[0].field === 'comment' && a.changes[0].changed_from.length === 0"> add comment "{{ a.changes[0].changed_to }}"</span>-->
<!--        <span v-if="a.changes[0].field === 'comment' && a.changes[0].changed_from.length !== 0"> changed comment from "{{ a.changes[0].changed_from }}" to "{{ a.changes[0].changed_to }}"</span>-->

        <span v-for="(item, i) in a.changes" :key="i">
          <span v-if="getTextAction(item.field).length !== 0"> {{getTextAction(item.field)}}
            <span v-if="item.field === 'delivery' || item.field === 'redelivery' || item.field.indexOf('period')>=0"> - to "{{item.changed_to | formatDateAndTime}}"</span>
            <span v-if="item.field !== 'delivery' && item.field !== 'redelivery' && item.field.indexOf('period') === -1 && item.field.indexOf('manager') === -1 && item.field !== 'owner' && item.field !== 'broker' && item.field.indexOf('place') === -1">  - to "{{item.changed_to}}"</span>
            <span v-if="item.field.indexOf('manager') !== -1" class="user-block">
              <span>
                <img v-if="checkHaveAvatar(item)" :src="getUrlForAvatar(item.changed_to.avatar)" alt="">
                <svg v-else class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="80" cy="80" r="80" fill="white"/>
                  <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                </svg>
                <span class="fz13 text-dark" v-if="item.changed_to !== null">{{item.changed_to.first_name}} {{item.changed_to.last_name}}</span>
              </span>
            </span>
            <span v-if="item.field === 'owner' || item.field === 'broker'"> - to "{{item.changed_to ? item.changed_to.company_name : '-'}}"</span>
            <span v-if="item.field.indexOf('place') !== -1"> - to "{{item.changed_to[0].country}}: {{item.changed_to[0].name}}"</span>
            <span v-if="(i+1) !== a.changes.length">, </span> </span>
          <span v-if="item.field === 'cargo_contracts'">{{getTextForContract(item)}}</span>
        </span>

      </p>
      <div v-if="showPreviewFile(a)">
        <div class="files-block" v-if="(fileForView(a.changes[0].changed_from, a.changes[0].changed_to).length !== 0 && fileForView(a.changes[0].changed_from, a.changes[0].changed_to)[0].is_deleted !== true)">
          <!--            {{fileForView(a.changes[0].changed_from, a.changes[0].changed_to)}}-->
          <div class="wrap-file-type"  v-for="(f, a) in fileForView(a.changes[0].changed_from, a.changes[0].changed_to)" :key="'23s'+a">
            <div class="file-type" v-if="f.is_deleted !== true">
              <img :src="f.thumbnail" class="img-preview" alt="preview" v-if="f.thumbnail !== null && ''+f.thumbnail !== ''">
              <img src="../../../assets/imgNone.svg" v-if="f.thumbnail === null || ''+f.thumbnail === ''">
              <p v-if="f.name !== null" class="fz11 text-dark">{{f.name}}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--    <pre>-->
    <!--      {{getActivityData}}-->
    <!--    </pre>-->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';

export default {
  name: "vessel-detail-activity",
  computed: mapGetters(["getActivityData"]),
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(["axiosGetActivityList"]),
    // isDate(date) {
    //   return moment(date).isValid();
    // },
    checkHaveAvatar(item) {
      if(item?.changed_to?.avatar) {
        return true
      }
      return false
    },
    getUrlForAvatar(link) {
      if(link.indexOf(this.getURL()) >= 0) {
        return link;
      }
      return this.getURL() +"/media/" + link;
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
    getTextForContract(item) {
      if(item.changed_from.length < item.changed_to.length) {
        let array = [];
        array = item.changed_to.map(a=> {
          if((item.changed_from.filter(b=> b.id === a.id)).length === 0) {
            return a;
          }
          return null
        }).filter(a=> a !== null);
        let text = "";
        text = ""+ array.map(a=> {
          if(a.contract_type === 'CARGO') {
            return " linked the Voyage Contract to the Vessel"
          }
          if(a.contract_type === 'SUBLET') {
            return " linked the Sublet Contract to the Vessel"
          }
        })
        return text;
      } else {
        let array = [];
        array = item.changed_from.map(a=> {
          if((item.changed_to.filter(b=> b.id === a.id)).length === 0) {
            return a;
          }
          return null
        }).filter(a=> a !== null);
        let text = "";
        text = ""+ array.map(a=> {
          if(a.contract_type === 'CARGO') {
            return " unlinked the Voyage Contract to the Vessel"
          }
          if(a.contract_type === 'SUBLET') {
            return " unlinked the Sublet Contract to the Vessel"
          }
        })
        return text;
      }
    },
    getTextAction(text) {
      let returnText = "";
      switch (text) {
        case 'name':
          returnText = "changed the Vessel Name"
        break;
        case 'built':
          returnText = "changed the Built"
          break;
        case 'dwt':
          returnText = "changed the DWT"
          break;
        case 'percentage':
          returnText = "changed the Percentage"
          break;
        case 'owner':
          returnText = "changed the Owner"
          break;
        // case 'cp_date':
        //   returnText = "changed the CP Date"
        //   break;
        case 'delivery':
          returnText = "changed the Delivery Date"
          break;
        case 'redelivery':
          returnText = "changed the Redelivery Date"
          break;
        case 'delivery_place':
          returnText = "changed the Delivery"
          break;
        case 'redelivery_place':
          returnText = "changed the Redelivery"
          break;
        case 'period':
          returnText = "changed the Period"
          break;
        case 'laycan_date':
          returnText = "changed the Laycan Date"
          break;
        case 'operation_manager':
          returnText = "assigned the Operation Manager"
          break;
        case 'chartering_manager':
          returnText = "assigned the Chartering Manager"
          break;
        case 'broker':
          returnText = "changed the Broker"
          break;
        case 'comment':
          returnText = "changed the Comment"
          break;
        case 'period_from':
          returnText = "changed the Period From"
          break;
        case 'period_to':
          returnText = "changed the Period To"
          break;
      }
      return returnText;
    },
    textForReceiptFilesType(from, to) {
      if(to > from) {
        if((to - 1) === from) {
          return "attached a receipt file"
        } else {
          return "attached a receipt file"
        }
      }
      return "remove a receipt file"
    },
    textForFilesType(from, to) {
      if(to > from) {
        if((to - 1) === from) {
          return "attached a file"
        } else {
          return "attached a files"
        }
      }
      return "remove a file"
    },
    showPreviewFile(a) {
      if(a.changes.length) {
        if(a.changes[0].field === 'files' || a.changes[0].field === 'receipt_files') {
          // alert(1);
          if(a.changes[0].changed_from < a.changes[0].changed_to) {
            return true
          }
        }
      }
      return false;
    },
    fileForView(from, to) {
      let files = [];
      // console.log("from")
      // console.log(from)
      // console.log("to")
      // console.log(to)
      for(let i=0; i<to.length; i++) {
        let k=0;
        for(let j=0; j<from.length; j++) {
          if(to[i].id === from[j].id) {
            k++;
          }
        }
        if(k===0) {
          files.push(to[i]);
        }
      }
      // }

      return files;
    },
    renderActivity() {
      let id = this.$route.params.id;
      this.axiosGetActivityList({model: "contract", instance_id: id, ordering: "created_at"})
    },
    getDateFormat(date) {
      date = moment(date).format("D MMM YYYY, H:mm")
      return date
    },
    getTextForStatus(status) {
      if(status === 'not_paid') {
        return 'Not Paid'
      }
      if(status === 'in_progress') {
        return 'Not Paid'
      }
      if(status === 'paid') {
        return 'Paid'
      }
    },
    getDateFormChangedActivity(date) {
      return moment(date).format("D MMM YYYY");
    }
  },
  mounted() {
    this.renderActivity();
  }
}
</script>

<style scoped lang="scss">
@import "vessel-deatil-activity";
</style>