<template>
  <div class="account-row" :class="{'disabled-account-drag': disabledAccountDuringDrop || disabledAccountDuringDropFolder, 'drag-mode': dragModeActive, 'drag-mode-folder': dragModeFolderActive}">
    
    <div class="account-row__heading accordeon drag-block" @click="$emit('setActiveAccountInfo', accountInfo.id)">
      <button class="drag-btn">
        <svg width="16" height="16">
          <use xlink:href="#drag_icn"></use>
        </svg>
      </button>
      <button 
        v-b-toggle="'collapse'+accountInfo.id"
        class="arrow"
        @click.stop="($event) => {$event.target.parentElement.classList.toggle('show')}">
        <svg width="12" height="12">
          <use xlink:href="#accordeon_arrow_icn"></use>
        </svg>
      </button>
      <span class="name" :title="accountInfo.name">{{accountInfo.name}}</span>
      <span class="unread-mails-count" v-if="accountInfo.unread_mails_count != 0">{{accountInfo.unread_mails_count}}</span>
      <span class="mails-count">{{accountInfo.mails_count}}</span>
      <div class="menu" v-click-outside="hideSubMenu">
        <svg width="24" height="24" @click.stop="showSubMenu($event)">
          <use xlink:href="#menu_icn"></use>
        </svg>
        <div class="sub-menu">
          <button class="sub-menu__button" @click="createFolder(accountInfo)">
            <svg width="24" height="24">
              <use xlink:href="#create_folder_icn"></use>
            </svg>
            <span>Create Folder</span>
          </button>
          <button class="sub-menu__button" @click="$refs.editNameAccountModal.showModal(accountInfo)">
            <svg width="24" height="24">
              <use xlink:href="#edit_name_icn"></use>
            </svg>
            <span>Edit name</span>
          </button>
          <button class="sub-menu__button" @click="$refs.logoutModal.showModal(accountInfo.id)">
            <svg width="24" height="24">
              <use xlink:href="#log_out_icn"></use>
            </svg>
            <span>Log out</span>
          </button>
        </div>
      </div>
    </div>
    <b-collapse :id="'collapse'+accountInfo.id" class="accordeon-content" :visible="true">
<!--      || disabledFolders.indexOf(folder.title) >= 0-->
<!--      :class="{'disabled-during-drag': disabledAccountDuringDrop || disabledFolders.indexOf(folder.title) >= 0}"-->
      <Folder
          :dragModeActive="dragModeActive"
          :showFoldersIds="showFoldersIds"
          @showFolderEmit="(e)=>{$emit('showFolderEmit', e)}"
          @moveToFolder="(e)=> {$emit('moveToFolderDrop', e)}"
          :dragModeFolderActive="dragModeFolderActive"
          @dragStart="(e)=>{$emit('dragStart', e)}"
          @dragEnd="$emit('dragEnd')"
          :disabledAccountDuringDrop="disabledAccountDuringDrop || disabledAccountDuringDropFolder || disabledFolders.indexOf(folder.title) >= 0"
          :class="{'disabled-during-drag': disabledAccountDuringDrop || disabledAccountDuringDropFolder || disabledFolders.indexOf(folder.title) >= 0}"
        v-for="folder in accountInfo.folders"
        :activeFolder='activeFolder'
        :key="folder.id"
        :folder="folder">
      </Folder>
    </b-collapse>
    <TextModal
      ref="logoutModal"
      :title="'Log out'"
      :applyBtnText="'Log out'"
      :cancelBtnText="'Cancel'"
      :text="'Are you sure you want to log out from '+accountInfo.name+'?'"
      @apply="logout($event)"
    ></TextModal>
    <EditNameAccountModal
        @apply="setNewName($event)" ref="editNameAccountModal">
    </EditNameAccountModal>
  </div>
</template>

<script>
import Folder from '@/components/mailbox/sidebar/Folder'
import TextModal from "@/components/mailbox/TextModal"
import EditNameAccountModal from "@/components/mailbox/EditNameAccountModal"
// import draggable from 'vuedraggable'
  export default {
    props: {
      showFoldersIds: Array,
      dragFolderItem: null,
      dragModeFolderActive: Boolean,
      getIdDragAccount: [Object, Number],
      dragModeActive: Boolean,
      accountInfo: {type: Object},
      activeFolder: {type: Number},
      dragItem: null,
      mailsList: Array,
      checkedEmails: Array,
    },
    inject: ['logoutMailAccount', 'updAccountName', 'showFolderSettings'],
    components: {EditNameAccountModal, TextModal, Folder},
    computed: {
      disabledAccountDuringDropFolder() {
        if(this.dragFolderItem !== null) {
          let bool = false;
          this.accountInfo.folders.forEach(item=> {
            if(Array.isArray(item.child_folders) == true) {
              if(item.child_folders.find(a=> a.id == this.dragFolderItem.id)) {
                bool = true
                return true
              }
            }
          })
          return !bool
        }
        return false
      },
      disabledAccountDuringDrop() {
        if(this.dragItem) {
          return this.dragModeActive && this.getIdDragAccount !== this.accountInfo.id
        } else if(this.dragModeActive) {
          let accountsId = Array.from(new Set(this.mailsList.filter(a=> this.checkedEmails.indexOf(a.id) >= 0).map(a=> {return a.account_id})))
          if(accountsId.length > 1) {
            return true
          } else {
            return this.dragModeActive && accountsId[0] !== this.accountInfo.id
          }
        }
        return false
      },
      disabledFolders() {
        if(this.dragModeActive) {
          let folder = null
          let findId = this.activeFolder ? this.activeFolder : null
          if(!findId) {
            let idMail = (this.dragItem ? this.dragItem : (this.checkedEmails[0]))
            console.log('findId idMail', idMail)

            findId = this.mailsList.find(a=> a.id == idMail)
            findId = findId ? findId.folder.id : null
          }
          console.log('findId', findId)
          folder = this.accountInfo.folders.find(a=> {
              if(a.id == findId || (Array.isArray(a.child_folders) == true && a.child_folders.find(el=> el.id == findId))) {
                return a
              }
            })
          // } else {
          //
          // }
          let nameFolder = ''
          // let names = ['Incoming', 'Sent', 'Draft']
          // let names = ['Draft']

          if(folder) {
            nameFolder = folder.title
            if(nameFolder !== 'Draft') {
              return ['Draft']
            }
            // if(names.indexOf(nameFolder) >= 0) {
            //   names = names.filter(a=> a !== nameFolder)
            //   return names
            // }
            else {
              return []
            }
          } else {
            return []
          }
        }
      return  []
        // let bool2 = this.dragModeActive &&
      },
    },
    methods: {
      logout(e){
        this.logoutMailAccount(e)
      },
      setNewName(e){
        this.updAccountName(e)
      },
      showSubMenu(e){
        setTimeout(() => {
          e.target.nextSibling.classList.add('show')
        }, 100)
      },
      hideSubMenu(){
        let submenues = document.querySelectorAll('.sub-menu.show')
        submenues.forEach(element => {
          element.classList.remove('show')
        });
      },
      createFolder(folderInfo){
        this.showFolderSettings(folderInfo)
      }
    },
    
  }
</script>

<style lang="scss" scoped>
@import "./src/components/mailbox/styles/folders";
.account-row{
  padding: 8px 0;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
  .account-row__heading{
    height: 36px;
    padding-left: 32px;
    display: flex !important;
    align-items: center;
    
    
    .name{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover{
        cursor: pointer;
      }
    }
    .unread-mails-count{
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 6px;
      color: var(--color-white);
      border-radius: 10px;
      background: var(--color-blue);
      margin-left: 4px;
      pointer-events: none;
    }
    .mails-count{
      margin-left: auto;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      pointer-events: none;
    }
    &:hover{
      .menu{
        opacity: 1;
      }
    }
    .menu{
      opacity: 0;
      position: relative;
      margin-left: 8px;
      svg{
        use{
          pointer-events: none;
        }
      }
      &:hover{
        cursor: pointer;
      }
    }
    .sub-menu{
      left: auto !important;
      position: absolute;
      padding: 8px;
      display: none;
      flex-direction: column;
      row-gap: 8px;
      right: 0 !important;
      top: 0;
      background: var(--color-white);
      z-index: 9;
      border-radius: 4px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
      width: fit-content;
      &.show{
        display: flex;
      }
    }
    .sub-menu__button{
      display: flex;
      align-items: center;
      column-gap: 4px;
      span{
        white-space: nowrap;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &:hover{
        span{
          color: var(--color-blue);
        }
      }
    }
    .arrow{
      left: 18px;
    }
  }
  .drag-block{
    position: relative;
    .drag-btn{
      position: absolute;
      left: 0px;
      display: none;
    }
    &:hover{
      .drag-btn{
        display: flex;
      }
    }
  }
}
.account-row__heading{
  &:hover{
    cursor: pointer;
  }
}
.active-account{
  .account-row__heading{
    background: #E5EEFF;
  }
}
.disabled-account-drag {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}
.drag-mode {
  /deep/ {
    .sidebar-row-heading:not(.show) {

    }
    .accordeon-content {
      display: block !important;
    }
    .sidebar-row-heading, .inside-folder {
      position: relative;
      &::after {
        z-index: 40;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .sidebar-row-heading:hover, .inside-folder:hover {
      background-color: var(--color-peach) !important;
    }
  }
}
.disabled-during-drag {
  background-color: var(--color-gray-dark) !important;
  opacity: 0.3;
  &:hover {
    /deep/ {
      .sidebar-row-heading {
        background: transparent !important;
      }
    }

  }
  /deep/ {
    .menu {
      visibility: hidden !important;
    }
    .accordeon-content {
      display: none !important;
    }
  }
}

/deep/ .sub-menu {
  left: auto !important;
  right: 0 !important;

}
.drag-mode-folder {
  /deep/ {
    .sidebar-row-heading, .inside-folder {
      position: relative;
      &::after {
        z-index: 40;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>