<template>
  <transition name="fade">
    <div class="wrap" v-if="show">
      <div class="filter"></div>
      <div class="content">
        <div class="top-panel">
          <p class="fz18 fw500">{{editModal ? 'Edit Noon Report' : 'Create New Noon Report'}}</p>
          <button class="close" v-on:click="closeModal">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
              <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
          </button>
        </div>
        <div class="content__main-block">
          <div>
            <p class="text-gray fz13">Reference Number</p>
            <input type="text" maxlength="100" v-model="referenceNumber">
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">Date</p>
              <date-picker
                  value-type="date"
                  class="datepick"
                  format="DD.MM.YYYY"
                  v-model="date"
              ></date-picker>
            </div>
            <div>
              <p class="text-gray fz13">Time</p>
              <input type="text" maxlength="100" ref="time" id="time" v-model="time" placeholder="00:00">
            </div>
          </div>
          <div class="two">
            <div class="block-with-dropdown">
              <p class="text-gray fz13">Time Zone</p>
              <input type="text" ref="timeZone" id="timeZone" placeholder="+00:00 / -00:00" v-model="timeZone">
              <counterparties-drop
                  class="timeZoneDrop"
                  :title="''"
                  :list="listTimeZone"
                  :active-item="defaultActiveListTimeZone"
              ></counterparties-drop>
            </div>
            <div>
              <p class="text-gray fz13">HRS Since Last Report</p>
              <input type="text" maxlength="100" v-model="hrsSinceLastReport">
            </div>
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">Latitude</p>
              <input type="text" maxlength="100" v-model="latitude">
            </div>
            <div>
              <p class="text-gray fz13">Longitude</p>
              <input type="text" maxlength="100" v-model="longitude">
            </div>
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">Course</p>
              <input type="text" maxlength="100" v-model="course">
            </div>
            <div>
              <p class="text-gray fz13">Speed</p>
              <input type="text" maxlength="100" v-model="speed">
            </div>
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">RPM</p>
              <input type="text" maxlength="100" v-model="rpm">
            </div>
            <div>
              <p class="text-gray fz13">Slip</p>
              <input type="text" maxlength="100" v-model="slip">
            </div>
          </div>
          <div>
            <p class="text-gray fz13">Weather</p>
            <input type="text" maxlength="2000" v-model="weather">
          </div>
          <div>
            <p class="text-gray fz13">Sea Stade</p>
            <input type="text" v-model="sea_stade">
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">Distance Since Last Report</p>
              <input type="text" maxlength="100" v-model="distanceSinceLastReport">
            </div>
            <div>
              <p class="text-gray fz13">AVG Speed Since Last Report</p>
              <input type="text" maxlength="100" v-model="avg">
            </div>
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">BROB HSFO</p>
              <input type="text" maxlength="100" v-model="brob_hsfo">
            </div>
            <div>
              <p class="text-gray fz13">BROB VLSFO</p>
              <input type="text" maxlength="100" v-model="brob_vlsfo">
            </div>
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">BROB MDO</p>
              <input type="text" maxlength="100" v-model="brob_mdo">
            </div>
            <div>
              <p class="text-gray fz13">BROB LSMGO</p>
              <input type="text" maxlength="100" v-model="brob_lsmgo">
            </div>
          </div>
          <div>
            <p class="text-gray fz13">BROB FW</p>
            <input type="text" maxlength="2000" v-model="brob_fw">
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">Consumed HSFO (24hrs)</p>
              <input type="text" maxlength="100" v-model="consumedHSFO">
            </div>
            <div>
              <p class="text-gray fz13">Consumed VLSFO (24 hrs)</p>
              <input type="text" maxlength="100" v-model="consumedVLSFO">
            </div>
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">Consumed MDO (24 hrs)</p>
              <input type="text" maxlength="100" v-model="consumedMDO">
            </div>
            <div>
              <p class="text-gray fz13">Consumed LSMGO (24 hrs)</p>
              <input type="text" maxlength="100" v-model="consumedLSMGO">
            </div>
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">Distance From</p>
              <input type="text" maxlength="100" v-model="distanceFrom">
            </div>
            <div>
              <p class="text-gray fz13">Distance To Go</p>
              <input type="text" maxlength="100" v-model="distanceToGo">
            </div>
          </div>
          <div>
            <p class="text-gray fz13">Next Port Name / ETA (wp/agw/uce)</p>
            <input type="text" maxlength="2000" v-model="nextPortName">
          </div>
          <div>
            <p class="text-gray fz13">Instruted Speed / Consumption</p>
            <textarea maxlength="2000" v-model="instrutedSpeed"></textarea>
          </div>
          <div>
            <p class="text-gray fz13">Stoppages / Delays Ref Number</p>
            <textarea maxlength="2000" v-model="stoppagesNumber"></textarea>
          </div>
          <div>
            <p class="text-gray fz13">Stoppage / Delays Description</p>
            <textarea maxlength="2000" v-model="stoppagesDescription"></textarea>
          </div>
          <div>
            <p class="text-gray fz13">Total Revolution Counter (Last 24 hrs)</p>
            <input type="text" maxlength="2000" v-model="totalRevolution">
          </div>
          <div class="two">
            <div>
              <p class="text-gray fz13">Scavenge Air Pressure</p>
              <input type="text" maxlength="100" v-model="scavengeAir">
            </div>
            <div>
              <p class="text-gray fz13">Me Speed Up To Normal</p>
              <input type="text" maxlength="100" v-model="meSpeed">
            </div>
          </div>
          <div>
            <p class="text-gray fz13">Quantity of auxiliary engines in operation</p>
            <input type="text" maxlength="2000" v-model="quantityAux">
          </div>
          <div>
            <p class="text-gray fz13">Other Remarks</p>
            <textarea maxlength="2000" v-model="other_remarks"></textarea>
          </div>
        </div>
        <div class="bottom-panel">
          <button class="blue-button-text fz14" v-on:click="closeModal">Close</button>
          <button class="blue-button fz14 fw500 h32 buttonW180" v-on:click="clickCreate">{{editModal ? 'Change' : 'Create'}}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import CounterpartiesDrop from "@/components/counterparties/counterpartiesAdd/counterpartiesDrop";
import Inputmask from "inputmask"
import {mapActions} from "vuex";
import moment from 'moment'

export default {
  components: {CounterpartiesDrop, DatePicker},
  name: "vessels-reports-add-or-edit",
  props: {
    openDetailModal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      date: null,
      show: false,
      listTimeZone: [
        {name: "GMT", id: 2},
        {name: "UTC", id: 1},
      ],
      defaultActiveListTimeZone: {name: "GMT", id: 2},
      referenceNumber: "",
      time: "",
      timeZone: "",
      hrsSinceLastReport: "",
      latitude: "",
      longitude: "",
      course: "",
      speed: "",
      rpm: "",
      slip: "",
      weather: "",
      distanceSinceLastReport: "",
      avg: "",
      brob_hsfo: "",
      brob_vlsfo: "",
      brob_mdo: "",
      brob_lsmgo: "",
      brob_fw: "",
      sea_stade: "",
      consumedHSFO: "",
      consumedVLSFO: "",
      consumedMDO: "",
      consumedLSMGO: "",
      distanceFrom: "",
      distanceToGo: "",
      nextPortName: "",
      instrutedSpeed: "",
      stoppagesNumber: "",
      stoppagesDescription: "",
      totalRevolution: "",
      scavengeAir: "",
      meSpeed: "",
      quantityAux: "",
      other_remarks: "",
      editModal: false,
      idReport: null,
    }
  },
  watch: {
  },
  methods: {
    ...mapActions(["axiosChangeNonReport", "axiosCreateNonReport"]),
    closeModal() {
      this.clearData();
      this.show = false;
      this.$emit("closeModal");
    },
    clickCreate() {
      let form = {
        "id": this.idReport,
        "reference_number": this.referenceNumber,
        "time": this.time,
        "hrs_since_last_report": this.hrsSinceLastReport,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "course": this.course,
        "speed": this.speed,
        "rpm": this.rpm,
        "slip": this.slip,
        "sea_stade": this.sea_stade,
        "distance_since_last_report": this.distanceSinceLastReport,
        "avg_speed_since_last_report": this.avg,
        "brob_hsfo": this.brob_hsfo,
        "brob_vlsfo": this.brob_vlsfo,
        "brob_mdo": this.brob_mdo,
        "brob_lsmgo": this.brob_lsmgo,
        "brob_fw": this.brob_fw,
        "consumed_hsfo_24_hrs": this.consumedHSFO,
        "consumed_vlsfo_24_hrs": this.consumedVLSFO,
        "consumed_mdo_24_hrs": this.consumedMDO,
        "consumed_lsmgo_24_hrs": this.consumedLSMGO,
        "distance_from": this.distanceFrom,
        "distance_to_go": this.distanceToGo,
        "scavenge_air_pressure": this.scavengeAir,
        "me_speed_up_to_normal": this.meSpeed,
        "time_zone": this.timeZone,
        "date": (this.date !== null && (""+this.date).length !== 0) ? moment(this.date).format("YYYY-MM-DD") : null,
        "weather": this.weather,
        "next_port_name_eta_wpagwuce": this.nextPortName,
        "instruted_speed_consumption": this.instrutedSpeed,
        "stoppages_delays_ref_number": this.stoppagesNumber,
        "stoppage_delays_description": this.stoppagesDescription,
        "total_revolition_counter_24_hrs": this.totalRevolution,
        "other_remarks": this.other_remarks,
        "quantity_of_auxiliary_engines_in_operation": this.quantityAux,
        "is_archive": false,
        "contract": this.$route.params.id,
      }
      if(this.editModal === false) {
        this.axiosCreateNonReport(form).then(()=> {
          // if(res !== false) {
          //   this.$emit('changeReport', res)
          // }
          // this.$emit('renderList');
          this.closeModal();
        });
      } else {
        this.axiosChangeNonReport(form).then(res=> {
          if(res !== false && this.openDetailModal) {
            this.$emit('changeReport', res)
          }
          // this.$emit('renderList');
          this.closeModal();
        });
      }

    },
    openModal(data) {
      // console.log(data);
      this.show = true;
      // this.addInputMask();
      let vm = this;
      function as() {
        vm.addInputMask();
      }
      setTimeout(as, 500);
      this.editModal = false;
      if(data !== null && data !== undefined) {
        this.idReport = data.id;
        this.editModal = true;
        this.referenceNumber = data.reference_number;
        this.time = data.time;
        this.hrsSinceLastReport = data.hrs_since_last_report;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.course = data.course;
        this.speed = data.speed;
        this.rpm = data.rpm;
        this.sea_stade = data.sea_stade;
        this.totalRevolution = data.total_revolition_counter_24_hrs;
        this.slip =  data.slip;
        this.distanceSinceLastReport = data.distance_since_last_report;
        this.avg = data.avg_speed_since_last_report;
        this.brob_hsfo =  data.brob_hsfo;
        this.brob_vlsfo = data.brob_vlsfo;
        this.brob_mdo =  data.brob_mdo;
        this.brob_lsmgo = data.brob_lsmgo;
        this.brob_fw =  data.brob_fw;
        this.consumedHSFO = data.consumed_hsfo_24_hrs;
        this.consumedVLSFO =  data.consumed_vlsfo_24_hrs;
        this.consumedMDO =  data.consumed_mdo_24_hrs;
        this.consumedLSMGO =  data.consumed_lsmgo_24_hrs;
        this.distanceFrom =  data.distance_from;
        this.distanceToGo =  data.distance_to_go;
        this.scavengeAir =  data.scavenge_air_pressure;
        this.meSpeed =  data.me_speed_up_to_normal;
        this.timeZone =  data.time_zone;
        if(data.date !== null && (""+data.date).length !== 0) {
          this.date = new Date(data.date);
        }
        this.weather = data.weather;
        this.nextPortName = data.next_port_name_eta_wpagwuce;
        this.instrutedSpeed = data.instruted_speed_consumption;
        this.stoppagesNumber = data.stoppages_delays_ref_number;
        this.stoppagesDescription = data.stoppage_delays_description;
        this.other_remarks = data.other_remarks;
        this.quantityAux = data.quantity_of_auxiliary_engines_in_operation;
      }
    },
    clearData() {
      this.idReport = null;
      this.editModal = false;
      this.date = "";
      this.defaultActiveListTimeZone = {name: "GMT", id: 2};
      this.referenceNumber = "";
      this.time = "";
      this.timeZone = ""
      this.hrsSinceLastReport = "";
      this.latitude = "";
      this.longitude = "";
      this.course = "";
      this.speed = "";
      this.rpm = "";
      this.slip = "";
      this.weather = "";
      this.distanceSinceLastReport = "";
      this.avg = "";
      this.brob_hsfo = "";
      this.brob_vlsfo = "";
      this.brob_mdo = "";
      this.brob_lsmgo = "";
      this.brob_fw = "";
      this.sea_stade = "";
      this.consumedHSFO = "";
      this.consumedVLSFO = "";
      this.consumedMDO = "";
      this.consumedLSMGO = "";
      this.distanceFrom = "";
      this.distanceToGo = "";
      this.nextPortName = "";
      this.instrutedSpeed = "";
      this.stoppagesNumber = "";
      this.stoppagesDescription = "";
      this.totalRevolution = "";
      this.scavengeAir = "";
      this.meSpeed = "";
      this.quantityAux = "";
      this.other_remarks = "";
    },
    addInputMask() {
        Inputmask({ regex: "[+-](0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask( this.$refs.timeZone);
        Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask( this.$refs.time);
    }
  },
  mounted() {
    // this.addInputMask();
  }
}
</script>

<style scoped lang="scss">
.dart-mode {
  .filter {
    opacity: 0.75 !important;
  }
  .top-panel {
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }
}
  .wrap {
    overflow: auto;
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    .filter {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-filter-modal);
      opacity: 0.15;
    }
    .content {
      height: max-content;
      background-color: var(--color-modal);
      border-radius: 8px;
      margin-top: 200px;
      margin-bottom: 100px;
      position: relative;
      z-index: 10;
      min-width: 500px;
      .top-panel {
        padding: 14px 24px;
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-card-border);
      }
      .content__main-block {
        padding: 16px 24px;
        box-sizing: border-box;
        width: 100%;
        input {
          height: 36px;
        }
        .text-gray {
          margin-bottom: 4px;
        }
        &>*:not(:last-child) {
          margin-bottom: 12px;
        }
        .two {
          display: flex;
          justify-content: space-between;
          &>div {
            width: calc((100% - 16px) / 2);
          }
        }
      }
      .bottom-panel {
        border-top: 1px solid var(--color-card-border);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 24px;
        .blue-button {
          margin-left: 32px;
        }
      }
      .close {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .datepick {
      width: 100%;
      /deep/ {
        .mx-input {
          height: 36px;
        }
      }
    }
    .block-with-dropdown {
      position: relative;
      .timeZoneDrop {
        position: absolute;
        bottom: 10px;
        right: 9px;
        /deep/ {
          .dropdown-toggle {
            border: none !important;
            padding: 0 !important;
            margin: 0 !important;
          }
          .dropdown-menu {
            width: max-content;
          }
        }
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>