<template>
  <div class="position-relative z-10" v-click-outside="hide">
    <button class="d-flex align-items-center">
      <svg v-on:click="show = !show" class="pr5" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.12822 6.09688C5.12822 6.04165 5.17299 5.99687 5.22822 5.99687H18.1996C18.2549 5.99687 18.2996 6.04165 18.2996 6.09688C18.2996 6.1521 18.2549 6.19688 18.1996 6.19688H5.22822C5.17299 6.19688 5.12822 6.1521 5.12822 6.09688ZM5.12822 17.6969C5.12822 17.6417 5.17299 17.5969 5.22822 17.5969H10.1996C10.2549 17.5969 10.2996 17.6417 10.2996 17.6969C10.2996 17.7521 10.2549 17.7969 10.1996 17.7969H5.22822C5.17299 17.7969 5.12822 17.7521 5.12822 17.6969ZM5.12822 11.8072C5.12822 11.7519 5.17299 11.7072 5.22822 11.7072H14.1996C14.2549 11.7072 14.2996 11.7519 14.2996 11.8072C14.2996 11.8624 14.2549 11.9072 14.1996 11.9072H5.22822C5.17299 11.9072 5.12822 11.8624 5.12822 11.8072Z" stroke="#2C2C2C" stroke-width="1.4"/>
      </svg>
      <span class="fz13 text-dark pr6 cursor-pointer" v-on:click="show = !show">
        Sort by
      </span>
      <svg class="cursor-pointer" :class="{'rotate-180': rotate}" v-on:click="rotate = !rotate" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
      </svg>
    </button>
    <div class="list pt4 pb4 box-border position-absolute" v-if="show">
      <div class="pl12 h28 d-flex align-items-center">
        <p class="fz13 text-gray fw500 cursor-pointer" v-on:click="show = !show">Sort by</p>
        <svg width="16" class="ml4 cursor-pointer" v-on:click="rotate = !rotate" :class="{'rotate-180': rotate}" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
        </svg>
      </div>
      <button v-on:click="select('execute_date')" class="w100Proc fz13 text-dark h28 pl12 box-border d-flex align-items-center">
        Date
        <svg class="position-absolute r4" v-if="value.indexOf('execute_date') >= 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>
      </button>
      <button v-on:click="select('currency_amount')" class="w100Proc fz13 text-dark h28 pl12 box-border d-flex align-items-center">
        Amount
        <svg class="position-absolute r4" v-if="value.indexOf('currency_amount') >= 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>
      </button>
      <button v-on:click="select('updated_at')" class="w100Proc fz13 text-dark h28 pl12 box-border d-flex align-items-center">
        Last Updated
        <svg class="position-absolute r4" v-if="value.indexOf('updated_at') >= 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "folders-sort-by-payment",
  props: {
    value: [String],
  },
  data() {
    return {
      rotate: false,
      show: false
    }
  },
  watch: {
    rotate: function () {
      this.$emit('input', !this.rotate ? this.value.replaceAll('-', '') : '-'+this.value.replaceAll('-', ''))
    }
  },
  methods: {
    hide() {
      this.show = false
    },
    select(name) {
      this.$emit('input', !this.rotate ? name.replaceAll('-', '') : '-'+name.replaceAll('-', ''))
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
  .list {
    top: -4px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: var(--color-modal);
    width: 137px;
  }
</style>