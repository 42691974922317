<template>
  <div class="flex border-b border-gray-dark relative">
    <div class="w-1/6 flex-shrink-0 p-x-3 p-y-2 border-r border-gray-dark fz13 text-gray-text">{{title}}</div>
    <div 
      v-if="isEdit"
      class="w-5/6 p-x-3 p-y-1.5 fz15 hover:outline-gray-text focus:outline-blue-dark" 
      :class="{
        //'whitespace-pre': isPre,
        'outline-red': error,
        'p-r-16': isCurrency
      }"
      contenteditable="plaintext-only"
      ref="input"
      @blur="onBlur"
      v-html="value"
    ></div>
    <div 
      v-else
      class="w-5/6 p-x-3 p-y-1.5 fz15" 
      :class="{
        //'whitespace-pre': isPre,
        'p-r-16': isCurrency
      }"
    >{{value}}</div>

    <div v-if="isCurrency && isEdit" class="absolute top-2 right-3 flex items-center fz14 cursor-pointer svg-white-dart" @click="$refs.dropCurrency.openList()">
      {{activeCurr}}
      <svg class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.72443 4.39844C2.45716 4.39844 2.32331 4.72158 2.5123 4.91057L5.78804 8.18631C5.9052 8.30346 6.09515 8.30346 6.21231 8.18631L9.48803 4.91057C9.67702 4.72158 9.54317 4.39844 9.2759 4.39844H2.72443Z" fill="#2C2C2C"/></svg>
    </div>
    <div v-if="isCurrency && !isEdit" class="absolute top-2 right-3 fz14">{{activeCurr}}</div>

    <counterparties-drop
      v-if="isCurrency && isEdit"
      class="hide_input vessel_owners__select_currency"
      ref="dropCurrency"
      :list="valutaList"
      :dontSelect="true"
      :removeSelectItemBeforeClick="false"
      :activeItem="activeCurrency"
      @select="onSelect"
      title="Select currency"
    ></counterparties-drop>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Inputmask from 'inputmask'

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'

export default {
  props: {
    title: String,
    value: String,
    error: Boolean,
    /* isPre: Boolean, */
    isEdit: {
      type: Boolean,
      default: true
    },
    isCurrency: Boolean,
    activeCurr: String,
  },
  components: {
    counterpartiesDrop
  },
  data() {
    return {
      activeCurrency: {id: 'USD', name: 'USD'}
    }
  },
  watch: {
    activeCurr: {
      immediate: true,
      handler() {
        this.activeCurrency.id = this.activeCurr
        this.activeCurrency.name = this.activeCurr
      }
    },
  },
  computed: {
    ...mapState({
      valutaList: state => state.payment.valutaList
    })
  },
  methods: {
    onBlur(e) {
      let result = e.target.innerHTML
      if(this.isCurrency){
        if(parseFloat(result))
          result = parseFloat(result).toFixed(4)
        else
          result = ''
      }

      e.target.innerHTML = result
      this.$emit('on-update', result)
    },
    onSelect(item) {
      this.$emit('on-select', item.name)
    },
    addMask() {
      if (this.$refs.input && !this.$refs.input.inputmask)
        Inputmask({ 
          mask: '9{1,*}[Z9{4}]',
          definitions: {
            "Z": {
              validator: ".",
            }
          },
          autoUnmask: true,
          insertMode: false,
          insertModeVisual: false
        }).mask(this.$refs.input)
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>