<template>
  <div class="input-block position-relative">
    <input v-on:keydown.tab="(e)=>{e.preventDefault();clickTabPayment(e.target.parentNode)}" :maxlength="maxLength" class="fz13 text-dark click-element-border" :class="classSettings" type="text" :disabled="true" v-model.lazy="valueLocal" @change="input">
    <div class="position-absolute t0 l0 w100Proc h100Proc box-border pre-input-click-border">

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "element-input-block",
  props: {
    disabled: Boolean,
    value: String,
    maxLength: {
      type: Number,
      default: 2000,
    },
    classSettings: String
  },
  data() {
    return {
      valueLocal: this.value
    }
  },
  methods: {
    ...mapActions(['clickTabPayment']),
    input() {
      this.$emit('input', this.valueLocal)
    }
  }
}
</script>

<style scoped lang="scss">
.input-block {
  box-sizing: border-box;
  border: 0;
  height: 30px;
  input {
    padding: 4px;
    height: 100%;
    border: 2px solid transparent;
    border-radius: 0;
  }
}

</style>