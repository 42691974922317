<template>
  <b-dropdown left ref="dropdown">
    <template #button-content>
      <button class="button-drop">
        <div class="button-content">
          <div class="icon" v-html="flagActive.svg">
          </div>
          <p class="fz13 text-dark">{{flagActive.name}}</p>
        </div>
        <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/>
        </svg>
      </button>
    </template>
    <div class="content">
      <button v-on:click="selectFlag(i)" v-for="(t,i) in flag" :key="i" class="fz13 text-dark" :class="{'active-btn': t.status}"><span class="icon" v-html="t.svg"></span>{{t.name}}</button>
    </div>
  </b-dropdown>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "selectPriority",
  computed: mapGetters(["getAllPriority"]),
  data() {
    return {
      computed: (["getAllPriority"]),
      flagActive: {},
      flag: [
        {name: "Urgent", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#E85353\"/>\n" +
              "</svg>\n", status: false},
        {name: "High", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#FF9035\"/>\n" +
              "</svg>\n", status: false},
        {name: "Normal", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#0FA54E\"/>\n" +
              "</svg>\n", status: false},
        {name: "Low", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#9AA3B0\"/>\n" +
              "</svg>\n", status: false},
        {name: "No priority", svg: "", status: true},
      ],
    }
  },
  methods: {
    ...mapActions(["axiosGetPriorityTask"]),
    hideDropdown() {
      this.$refs.dropdown.hide(true);
    },
    setPriorityForDuplicate(data) {
      for(let i=0; i<this.flag.length; i++) {
        if(this.flag[i].id === data.id) {
          this.selectFlag(i);
        }
      }
    },
    selectFlag(index) {
      this.flagActive = JSON.parse(JSON.stringify(this.flag[index]));
      if(index === this.flag.length - 1) {
        this.flagActive.name = "";
      }
      for(let i=0; i<this.flag.length; i++) {
        this.flag[i].status = false;
      }
      this.flag[index].status = true;
      this.hideDropdown();
    },
    getPriorityinInput() {
      this.$emit("emitGetPriotity", this.flagActive.id);
    },
    clearData() {
      if(this.getAllPriority.length !== 0) {
        let array = [];
        for(let i=0; i<this.getAllPriority.length; i++) {
          for(let j=0; j<this.flag.length; j++) {
            if((this.getAllPriority[i].type).toLowerCase() === (this.flag[j].name).toLowerCase()) {
              let form = {
                name: this.getAllPriority[i].type,
                id: this.getAllPriority[i].id,
                svg: this.flag[j].svg,
                status: false,
              }
              array.push(form);
            }
          }
        }
        this.flag = JSON.parse(JSON.stringify(array));
        this.flagActive = this.flag[this.flag.length - 1];
      } else {
        this.axiosGetPriorityTask().then(()=> {
          let array = [];
          for(let i=0; i<this.getAllPriority.length; i++) {
            for(let j=0; j<this.flag.length; j++) {
              if((this.getAllPriority[i].type).toLowerCase() === (this.flag[j].name).toLowerCase()) {
                let form = {
                  name: this.getAllPriority[i].type,
                  id: this.getAllPriority[i].id,
                  svg: this.flag[j].svg,
                  status: false,
                }
                array.push(form);
              }
            }
          }
          this.flag = JSON.parse(JSON.stringify(array));
          this.flagActive = this.flag[this.flag.length - 1];
        })
      }

    }
  },
  mounted() {
    if(this.getAllPriority.length !== 0) {
      let array = [];
      for(let i=0; i<this.getAllPriority.length; i++) {
        for(let j=0; j<this.flag.length; j++) {
          if((this.getAllPriority[i].type).toLowerCase() === (this.flag[j].name).toLowerCase()) {
            let form = {
              name: this.getAllPriority[i].type,
              id: this.getAllPriority[i].id,
              svg: this.flag[j].svg,
              status: false,
            }
            array.push(form);
          }
        }
      }
      this.flag = JSON.parse(JSON.stringify(array));
      this.flagActive = JSON.parse(JSON.stringify(this.flag[this.flag.length - 1]));
      this.flagActive.name = ""
      this.$emit("mountedPriority");
    } else {
      this.axiosGetPriorityTask().then(()=> {
        let array = [];
        for(let i=0; i<this.getAllPriority.length; i++) {
          for(let j=0; j<this.flag.length; j++) {
            if((this.getAllPriority[i].type).toLowerCase() === (this.flag[j].name).toLowerCase()) {
              let form = {
                name: this.getAllPriority[i].type,
                id: this.getAllPriority[i].id,
                svg: this.flag[j].svg,
                status: false,
              }
              array.push(form);
            }
          }
        }
        this.flag = JSON.parse(JSON.stringify(array));
        this.flagActive = JSON.parse(JSON.stringify(this.flag[this.flag.length - 1]));
        this.flagActive.name = ""
        this.$emit("mountedPriority");
      })
    }


  }
}
</script>

<style scoped lang="scss">
@import "../../style-element/dropdown";
/deep/ .show {
  .arrow {
    position: absolute;
    right: 8px;
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  margin: 2px;
  width: 100%;
  background: transparent;
  border: none;
  min-width: 1px;
  box-sizing: border-box;
}
.icon {
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.button-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    text-transform: capitalize;
  }
}
.content {
  width: 100%;
  max-height: 260px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  button {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    transition: background-color .1s linear;
    background-color: var(--color-modal);
    height: 34px;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 12px;
    &:first-child {
      border-radius: 4px 4px 0 0px;
    }
    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }
    &:hover {
      background-color: var(--color-input);
    }
  }
}
.button-drop {
  box-shadow: none !important;
  background: var(--color-input);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  border-radius: 4px;
  height: 36px;
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2C;
  resize: none;
  transition: .2s linear;
  &:hover {
    border-color: #A3A3A3;
  }
  &:focus {
    border-color: #094172;
  }

  padding-right: 30px;
  display: flex;
  align-items: center;
  svg {
    position: absolute;
    right: 12px;
    margin-left: 6px;
  }
}
/deep/ .btn {
  width: 100%;
  background: transparent !important;
}

.active-btn {
  background-image: url(../../../assets/image/Select.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}
p {
  margin: 0;
}
</style>