<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content info">
      <div class="modal-content_body">
        <p class="modal-content_title">{{type=='type'? 'Delete counterparty type' : 'Delete client subtype'}} <button class="modal-close" @click="closeModal()"></button></p>
        <p class="modal-content_descr" v-if="msg" v-html="msg"></p>
        <p class="modal-content_descr" v-else>The {{type=='type'? 'type' : 'subtype'}} <b>{{data.name}}</b> is used in other clients. You can not Delete it.</p>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-next" @click="closeModal()">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'type'
    },
    data: {
      type: Object,
    },
    msg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss">

</style>