<template>
  <label
    class="relative"
  >
    <slot name="label"></slot>
    <input
      type="text" 
      :placeholder="placeholder"
      class="h36"
      :class="[inputClass]"
      ref="input"
      :disabled="disabled"
      v-model="input"
      @paste="onPaste"
      @keyup="keyup"
      @keydown="keydown"
      @blur="blurInput($event.target.value), $emit('blur')"
      @keypress.enter="blurInput($event.target.value), $emit('blur')"
    >
    <slot name="right"><span class="absolute right-2 centered-y text-gray-text" v-if="rightPlaceholder" v-html="rightPlaceholder"></span></slot>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    inputClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rightPlaceholder: {
      type: String,
      default: ''
    },
    fractionDigits: {
      type: Number,
      default: 2
    },
    isRoundOutput: {
      type: Boolean,
      default: true
    },
    noWatchBlur: {
      type: Boolean,
      default: false
    },
    isShowZero: Boolean,
    disabled: Boolean,
    copyPaste: Boolean,
    alignRight: Boolean,
  },
  data() {
    return {
      input: '',
      ctrlKey: false,
      vKey: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if(!this.noWatchBlur) {
          this.blurInput(newVal)
        } else {
          this.firstBlurInput(newVal)
        }
      }
    }
  },
  methods: {
    checkNum(num) {
      if(num.indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");

        if(num2.length > 4) {
          num2 = num2.substring(0, 4);
        }
        if(num1.length && num2.length) {
          num = num1 + "." + num2;
        } else {
          num = num1 + num2;
        }
      } else {
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    onPaste (evt) {
      if(this.copyPaste) {
        evt.preventDefault()
        let textInput1 = this.input.substr(0, this.$refs.input.selectionStart)
        let textInput2 = this.input.substr(this.$refs.input.selectionEnd,this.input.length)
        let text = this.checkNum(evt.clipboardData.getData('text'));
        this.input = textInput1 + text + textInput2
        if(this.input && this.input !== 0 && this.input !== '0.00') {
          this.$emit('paste')
        }
      }
    },
    focusInput() {
      this.$refs.input.selectionStart = 0;
      this.$refs.input.selectionEnd = this.$refs.input.value.length;
      this.$refs.input.focus()

    },
    keyup(e) {
      if(this.copyPaste) {
        if(this.ctrlKey) {
          if(e.key === 'Meta' || e.key === 'Control') {
            this.ctrlKey = false;
          }
        }
      }
    },
    keydown(e) {
      console.log(e)
      let bool = false
      if(this.copyPaste) {
        if(e.key === 'Meta' || e.key === 'Control') {
          this.ctrlKey = true;
        }
        if(e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) {
          if(this.ctrlKey) {
            bool=true
          }
        }
      }

      if(
          (e.keyCode < 48 || e.keyCode > 57) && // numbers
          (e.keyCode < 96 || e.keyCode > 105) && // numpad
          (e.keyCode < 37 || e.keyCode > 40) && // arrows
          e.key != '.' && e.keyCode != 13 && // 13 - enter
          e.keyCode != 8 && e.keyCode != 46 && // backspace / delete
          e.keyCode != 110 // decimal point
      ) {
        // if(copyPaste)
        if(!bool)
        e.preventDefault()
      }
    },
    blurInput(value) {
      let output = value

      if(output === '' || (!output && output != 0)){
        this.$emit('input', null)
        this.input = ''
        return false
      }

      if(typeof output != 'string'){
        output = output.toString()
      }

      output = parseFloat(output.replaceAll(',', ''))

      if(output && typeof output == 'number')
        output = this.isRoundOutput ? +output.toFixed(this.fractionDigits) : output
      else
        output = 0
      
      this.$emit('input', output)

      if(output == 0 && !this.isShowZero){
        output = ''
      }else{
        output = output.toLocaleString('en', {minimumFractionDigits: this.fractionDigits, maximumFractionDigits: this.fractionDigits})
      }

      this.input = output

    },
    firstBlurInput(value) {
  let output = value

  if(output === '' || (!output && output != 0)){
    // this.$emit('input', null)
    this.input = ''
    return false
  }

  if(typeof output != 'string'){
    output = output.toString()
  }

  output = parseFloat(output.replaceAll(',', ''))

  if(output && typeof output == 'number')
    output = this.isRoundOutput ? +output.toFixed(this.fractionDigits) : output
  else
    output = 0

  // this.$emit('input', output)

  if(output == 0 && !this.isShowZero){
    output = ''
  }else{
    output = output.toLocaleString('en', {minimumFractionDigits: this.fractionDigits, maximumFractionDigits: this.fractionDigits})
  }

  this.input = output

},

},
}
</script>

<style>

</style>