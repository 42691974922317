<template>
  <div>
    <div class="title d-flex align-items-center pb4 w100Proc border-bottom border-color-normal mt20">
      <svg v-on:click="manualShow = !manualShow" :class="{'rotate-180': manualShow}" class="mr8 cursor-pointer" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fw500 fz18 text-dark">
        Manually Tasks
      </p>
    </div>
    <folders-list-links v-show="manualShow" class="mt8" :items="manualTasks"></folders-list-links>
    <pagination-panel-default @load-more="loadMoreManually" v-if="manualNextLink && manualShow" :if-active="manuallyActiveShowMore"></pagination-panel-default>
    <div class="title d-flex align-items-center pb4 w100Proc border-bottom border-color-normal mt12">
      <svg v-on:click="vesselShow = !vesselShow" :class="{'rotate-180': vesselShow}" class="mr8 cursor-pointer" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fw500 fz18 text-dark">
        Vessel Search
      </p>
    </div>
    <folders-list-links v-show="vesselShow" class="mt8" :items="vesselTasks"></folders-list-links>
    <pagination-panel-default @load-more="loadMoreVessel" v-if="vesselNextLink && vesselShow" :if-active="vesselActiveShowMore"></pagination-panel-default>

    <div class="title d-flex align-items-center pb4 w100Proc border-bottom border-color-normal mt12">
      <svg v-on:click="cargoShow = !cargoShow" :class="{'rotate-180': cargoShow}" class="mr8 cursor-pointer" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fw500 fz18 text-dark">
        Cargo Search
      </p>
    </div>
    <folders-list-links v-show="cargoShow" class="mt8" :items="cargoTasks"></folders-list-links>
    <pagination-panel-default @load-more="loadMoreCargo" v-if="cargoNextLink && cargoShow" :if-active="cargoActiveShowMore"></pagination-panel-default>

  </div>
</template>

<script>
import FoldersListLinks from "@/components/folders/folders-list-links";
import {mapActions, mapMutations} from "vuex";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
export default {
  name: "folders-tasks",
  components: {PaginationPanelDefault, FoldersListLinks},
  props: {
    search: String,
    sort: String,
    routeChange: Number,
  },
  data() {
    return {
      manualShow: true,
      manualTasks: [],
      manualNextLink: null,
      manuallyActiveShowMore: false,
      vesselShow: true,
      vesselTasks: [],
      vesselNextLink: null,
      vesselActiveShowMore: false,
      cargoShow: true,
      cargoTasks: [],
      cargoNextLink: null,
      cargoActiveShowMore: false,
    }
  },
  watch: {
    search: function () {
      this.loadTasks()
    },
    sort: function () {
      this.loadTasks()
    },
    routeChange: function () {
      this.loadTasks()
    },
  },
  methods: {
    ...mapActions(['axiosGetFoldersTasksSection', 'axiosGetLoadMoreFoldersTasksSection']),
    ...mapMutations(['setNameSectionFolders']),
    loadTasks() {
      console.log('loadTasks')
      this.axiosGetFoldersTasksSection({search: this.search ,is_archived: this.$route.path.indexOf('tasks-a') >= 0, ordering: this.sort ? this.sort : 'name', type: 'MANUAL', limit: 25}).then(res=> {
        this.manualTasks = res.data.results.map(a=> {
          a['link'] = {name: this.$route.path.indexOf('tasks-a')>= 0 ? 'Folders - Tasks Archive Manually Files' : 'Folders - Tasks Manually Files', params: {detailData: JSON.stringify({name: a.name, id: a.id})}}
          a['updated_at'] = a.files_updated_at
          return a
        })
        this.manualNextLink = res.data.next
      })
      this.axiosGetFoldersTasksSection({search: this.search, is_archived: this.$route.path.indexOf('tasks-a') >= 0, ordering: this.sort ? this.sort : 'name', type: 'SHIP_SEARCH_WITH_CARGO', limit: 25}).then(res=> {
        this.vesselTasks = res.data.results.map(a=> {
          a['link'] = {name: this.$route.path.indexOf('tasks-a')>= 0 ? 'Folders - Tasks Archive Vessel Files' : 'Folders - Tasks Vessel Files', params: {detailData: JSON.stringify({name: a.name, id: a.id})}}
          a['updated_at'] = a.files_updated_at
          return a
        })
        this.vesselNextLink = res.data.next
      })
      this.axiosGetFoldersTasksSection({search: this.search, is_archived: this.$route.path.indexOf('tasks-a') >= 0, ordering: this.sort ? this.sort : 'name', type: 'CARGO_SEARCH_WITH_SHIP', limit: 25}).then(res=> {
        this.cargoTasks = res.data.results.map(a=> {
          a['link'] = {name: this.$route.path.indexOf('tasks-a')>= 0 ? 'Folders - Tasks Archive Cargo Files' : 'Folders - Tasks Cargo Files', params: {detailData: JSON.stringify({name: a.name, id: a.id})}}
          a['updated_at'] = a.files_updated_at
          return a
        })
        this.cargoNextLink = res.data.next
      })

    },
    async loadMoreManually() {
      this.manuallyActiveShowMore = true
      let res = await this.axiosGetLoadMoreFoldersTasksSection(this.manualNextLink)
      let tasks = res.data.results.map(a=> {
        a['link'] = {name: 'Folders - Tasks Manually Files', params: {detailData: JSON.stringify({name: a.name, id: a.id})}}
        return a
      })
      this.manualNextLink = res.data.next
      this.manualTasks = this.manualTasks.concat(tasks)
      this.manuallyActiveShowMore = false

    },
    async loadMoreVessel() {
      this.vesselActiveShowMore = true
      let res = await this.axiosGetLoadMoreFoldersTasksSection(this.vesselNextLink)
      let tasks = res.data.results.map(a=> {
        a['link'] = {name: 'Folders - Tasks Vessel Files', params: {detailData: JSON.stringify({name: a.name, id: a.id})}}
        return a
      })
      this.vesselNextLink = res.data.next
      this.vesselTasks = this.vesselTasks.concat(tasks)
      this.vesselActiveShowMore = false
    },
    async loadMoreCargo() {
      this.cargoActiveShowMore = true
      let res = await this.axiosGetLoadMoreFoldersTasksSection(this.cargoNextLink)
      let tasks = res.data.results.map(a=> {
        a['link'] = {name: 'Folders - Tasks Vessel Files', params: {detailData: JSON.stringify({name: a.name, id: a.id})}}
        return a
      })
      this.cargoNextLink = res.data.next
      this.cargoTasks = this.cargoTasks.concat(tasks)
      this.cargoActiveShowMore = false
    }
  },
  mounted() {
    this.setNameSectionFolders('Tasks')
    this.loadTasks()
  }
}
</script>

<style scoped>

</style>