<template>
  <div class="wrap-page" :class="{'shortVersion': getStatesLeftMenu && $route.path.indexOf('cash-flow') >= 0, 'cash-flow': $route.path.indexOf('cash-flow') >= 0}">
<!--    <payment-modal-duplicate-or-edit-payment :cash-flow="true" ref="modalDupOrEd" @needRender="renderList"></payment-modal-duplicate-or-edit-payment>-->
<!--    <payment-modal-create :cash-flow="true" ref="modalCreate" @needRender="renderList"></payment-modal-create>-->
    <payment-modal-delete @render="renderList" ref="modalDelete"></payment-modal-delete>
    <payment-panel-select-period class="panel-select-period" :hideLeftText="true" :createButtonRight="true" :textTitle="$route.path.indexOf('accounting')>=0 ? 'Accounting Invoices' : ''" @eventExportXLSX="downloadXLSXListPayment" @eventExportPDF="downloadPDFListPayment" @createPayment="showModalCreate" :cash-flow="true" @changeDate="emitChangeDate"></payment-panel-select-period>
    <payment-list-component v-if="getShowPaymentList()" v-click-outside="focusFalse" @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" @renderList="renderList" class="payment-list" ref="paymentList" @HtmlCode="PDFHtmlCode" @activeList="downloadXLSXListPayment" :cash-flow="true" @clickDelete="clickDelete" @clickCreate="showModalCreate" @clickEditPaymentCard="clickEditPaymentCard" @clickDuplicatePaymentCard="clickDuplicatePaymentCard"></payment-list-component>
    <virtual-payment-list @changeOrdering="changeOrdering" @changeGridSettings="(e)=>{gridSettings=e}" @scrollBottomEnd="(((link1 || link2) && getPaymentListOriginal.length !== 0 && $route.path.indexOf('open/cash-flow') >= 0) || (getNextLinkPayment && getPaymentListOriginal.length !== 0 && $route.path.indexOf('open/cash-flow') === -1)) && !showLoader ? paymentNext() : null" :key="$route.path" v-else @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" :short-view="getStatusShortView" ref="paymentList"></virtual-payment-list>
    <payment-gross-net class="b24" v-if="$route.path.indexOf('open/accounting-invoice') === -1"></payment-gross-net>
<!--    1-->
<!--    <pagination-panel-default class="pagination-panel" @load-more="paymentNext()" :if-active="showLoader" v-if="(link1 || link2) && getPaymentListOriginal.length !== 0 && $route.path.indexOf('open/cash-flow') >= 0"></pagination-panel-default>-->
<!--    <div class="pb55" v-if="!((link1 || link2) && getPaymentListOriginal.length !== 0) && $route.path.indexOf('open/cash-flow') >= 0"></div>-->
<!--    2-->
<!--    <pagination-panel-default class="pagination-panel" :dopMenuDisabled="getStatesLeftMenu" @load-more="paymentNext()" :if-active="showLoader" v-if="getNextLinkPayment && getPaymentListOriginal.length !== 0 && $route.path.indexOf('open/cash-flow') === -1"></pagination-panel-default>-->
<!--    <div class="pb55" v-if="!(getNextLinkPayment && getPaymentListOriginal.length !== 0) && $route.path.indexOf('open/cash-flow') === -1"></div>-->

    <loader-for-autoload v-if="showLoader && (((link1 || link2) && getPaymentListOriginal.length !== 0 && $route.path.indexOf('open/cash-flow') >= 0) || (getNextLinkPayment && getPaymentListOriginal.length !== 0 && $route.path.indexOf('open/cash-flow') === -1))"></loader-for-autoload>
    <div class="pb55" v-else></div>
    <modal-slots :noCloseFilter="true" class="detail-payment" :class="{'opacity-modal': ShowAddCounterparties}" :showMod="($route.path.indexOf('accounting-invoice') >= 0 && eventOpenDetailInvoice) ? true : false" @close="()=>{setEventOpenDetailInvoice(null); ShowAddCounterparties = false}">
      <template v-slot:title>
        <p>{{getTypePayment}}</p>
      </template>
      <payment-detail v-if="($route.path.indexOf('accounting-invoice') >= 0 && eventOpenDetailInvoice) ? true : false" @statusShowAddCounterparties="statusShowAddCounterparties" :id-payment="eventOpenDetailInvoice ? eventOpenDetailInvoice.id : null"></payment-detail>
    </modal-slots>
    <div v-if="$route.path.indexOf('open/cash-flow') >= 0" class="wrap-bottom-panel-total">
<!--      <div class="bottom-panel-total" v-if="gridSettings" :style="{'display': 'grid', 'grid-template-areas': JSON.stringify(gridSettings.area), 'grid-template-columns': gridSettings.columns, 'grid-template-rows': '40px'}">-->
<!--        &lt;!&ndash;      <p class="text fz13 fw600">TOTAL:</p>&ndash;&gt;-->
<!--        &lt;!&ndash;      <div class="amount ml3 fz14 fw700 text-dark">{{totalCashFlow.expense | formatNumberTwo}}</div>&ndash;&gt;-->
<!--        &lt;!&ndash;      <div class="amount fz14 fw700 text-dark">{{totalCashFlow.income | formatNumberTwo}}</div>&ndash;&gt;-->
<!--        <div class="due-date">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->
<!--        </div>-->
<!--        <div class="vessel-state-and-member">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="account">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="additionally">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="currency">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="expense">-->
<!--          <p class="text fz14 fw700 text-dark">-->
<!--            {{totalCashFlow.expense | formatNumberTwo}}-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="income">-->
<!--          <div class="text fz14 fw700 text-dark">-->
<!--            {{totalCashFlow.income | formatNumberTwo}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="converted">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->


<!--        </div>-->
<!--        <div class="status">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->


<!--        </div>-->
<!--        <div class="paid-date">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->


<!--        </div>-->
<!--        <div class="counterparty">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="bank-and-cash-account">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="remark">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="attachment">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="comment-from-accounting">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="settled-vsl">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="disbursement">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="real-account">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="statement-member">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="balance">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--        <div class="end-block">-->
<!--          <p>sdfsdskjfuhsdjkfhjsdkhfkjsdhfjksdhfsdkfdf</p>-->

<!--        </div>-->
<!--      </div>-->
      <virtual-payment-list-title
          :no-title="true"
          :expense="totalCashFlow.expense ? totalCashFlow.expense : 0"
          :income="totalCashFlow.income ? totalCashFlow.income : 0"
          :is-selected="false"
          :show-settings="gridSettings ? gridSettings.area : null"
          class="titles"
          v-if="gridSettings"
          :style="{'display': 'grid', 'grid-template-areas': JSON.stringify(gridSettings.area), 'grid-template-columns': gridSettings.columns, 'grid-template-rows': '44px'}"
      ></virtual-payment-list-title>
    </div>
  </div>
</template>

<script>
import PaymentListComponent from "@/components/payment/payment-list/payment-list-component";
import moment from 'moment';
import PaymentPanelSelectPeriod from "@/components/payment/payment-panel-select-period";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import PaymentModalDelete from "@/components/payment/payment-modal-delete";
import axios from "axios";
import PaymentGrossNet from "@/components/payment/payment-gross-net";
import VirtualPaymentList from "@/components/payment/payment-list/virtual-payment-list";
import LoaderForAutoload from "@/components/loader-for-autoload";
import ModalSlots from "@/components/modal/modal-slots";
import PaymentDetail from "@/components/paymentDetail/paymentDetail";
import VirtualPaymentListTitle from "@/components/payment/payment-list/virtual-payment-list-title";

export default {
  name: "cashFlowVesselDetail",
 computed: {
    ...mapState({
      eventOpenDetailInvoice: state=> state.payment.eventOpenDetailInvoice,
      totalCashFlow: state=> state.payment.totalCashFlow,
    }),
    ...mapGetters(['getStartLoadNewVessel', 'getStatesLeftMenu', 'getFilterDataPaymentAxios', "getInfoDetailPayment", "getNextLinkPayment", "getContracts","getPaymentList", "getPaymentListOriginal", "getDataPaymentModal", "getInfoDetailPayment", "getPaymentList", "getDateForViewPayments", 'getPermissionsByType']),
   getStatusShortView() {
   return this.getStatesLeftMenu && this.$route.path.indexOf('cash-flow') >= 0
  },
   getTypePayment() {
      // let payment = this.getPaymentListOriginal.find(a=> a.id === this.eventOpenDetailInvoice)
      return this.eventOpenDetailInvoice ? (this.eventOpenDetailInvoice.payment_type === 'expense' ? 'Bill' : 'Invoice') : ''
   }
 },
  components: {
    VirtualPaymentListTitle,
    PaymentDetail,
    ModalSlots,
    LoaderForAutoload,
    VirtualPaymentList, PaymentGrossNet, PaymentModalDelete, PaymentPanelSelectPeriod, PaymentListComponent},

  data() {
    return {
      orderingName: '',
      gridSettings: null,
      status_ordering: true,
      due_date_ordering_status: true,
      keyRender: -9999,
      showLoader: false,
      countRequest: 0,
      countRequestLoader: 0,
      link1: null,
      link2: null,
      showModalDetail: false,
      ShowAddCounterparties: false,
    }
  },
  beforeRouteLeave(to,from,next) {
    this.setEventOpenDetailInvoice(null)
    this.ShowAddCounterparties = false
    next()
  },
  watch: {
    $route: function () {
      if(!this.getStartLoadNewVessel) {
        this.renderList();
      }
    },
    countRequest: function () {
      if(this.countRequest !== 0) {
        this.showLoad();
      } else {
        this.hideLoad()
      }
    },
    countRequestLoader: function () {
      if(this.countRequestLoader !== 0) {
        this.showLoader = true;
      } else {
        this.showLoader = false
      }
    },
  },
  methods: {
    ...mapActions(["axiosPaymentNextLinkForVesselPdf", "axiosPaymentNextLinkPdf", "axiosGetPaymentForVessel", "axiosPaymentNextLinkForVessel", "axiosGetAllContracts", "axiosGetXLSX", "axiosGetPayment", "axiosGetPDF", "axiosPaymentNextLink"]),
    ...mapMutations(['setTotalCashFlow', 'setEventOpenDetailInvoice', 'setPaymentsList', 'setNextLinkPayment', 'setFilterDataPaymentAxios', "setPaymentsList", "showLoad", "hideLoad", "cancelPostPayment", "setDateForViewPayments", "setDataPaymentModal"]),
    statusShowAddCounterparties(bool) {
      this.ShowAddCounterparties = bool
    },
    getShowPaymentList() {
      return false
    },
    focusFalse() {
      this.$refs.paymentList.focusFalse()
    },
    changeOrderingStatus(data) {
      this.status_ordering = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrderingDueDate(data) {
      if(this.due_date_ordering_status !== data) {
        this.due_date_ordering_status = data;
        this.orderingName = ''
        this.axiosChangeGetList();
      }
    },
    changeOrdering(ordering) {
      this.orderingName = ordering
      this.axiosChangeGetList();
    },
    paymentNext() {
      if(this.$route.path.indexOf('open/cash-flow') >= 0) {
        this.countRequestLoader++;
        this.axiosPaymentNextLinkForVessel(this.link1).then(res=> {
          // this.$refs.paymentList.pushLoadData(this.getPaymentListOriginal)
          this.link1 = res;
          this.countRequestLoader--;
        })
        this.countRequestLoader++;
        this.axiosPaymentNextLinkForVessel(this.link2).then(res=> {
          // this.$refs.paymentList.pushLoadData(this.getPaymentListOriginal)
          this.link2 = res;
          this.countRequestLoader--;
        })
      } else {
        this.showLoader = true;
        this.axiosPaymentNextLink().then(()=> {
          // this.$refs.paymentList.pushLoadData(this.getPaymentListOriginal)
          this.showLoader = false;
        })
      }
    },
    PDFHtmlCode(codePage) {
      this.axiosGetPDF({html: codePage, name: "pdf"}).then(response=> {
        // console.log("File");
        // console.log(new File(, "file.pdf"));
        // var pdfAsDataUri = "data:application/pdf;base64,"+response.data;
        // window.open(pdfAsDataUri);
        console.log(response.data)
        let data = response.data;
        // window.open("data:application/pdf;base64," + encodeURI(response.data));
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
          var byteCharacters = atob(data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: 'application/pdf'});
          //var blob_iframe = document.querySelector('#blob-src-test');
          //blob_iframe.src = blob_url;
          window.navigator.msSaveOrOpenBlob(blob, "pdf");
        }
        else { // much easier if not IE
          // this.filePath = "data:application/pdf;base64," + data, '', "height=600,width=800"
          // window.open("data:application/pdf;base64," + data);
          let link = "data:application/pdf;base64," + data;
          axios.get(link, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                let name = "Payment List"
                if(this.$route.path.indexOf('accounting-invoice') >= 0) {
                  name = "Account Invoice"
                }
                if(this.$route.path.indexOf('cash')>=0) {
                  name = 'CashFlow List'
                }
                if(this.$route.path.indexOf('instructions') >= 0) {
                  name = "Payment Instructions List"
                }
                link.download = name
                link.click()
                URL.revokeObjectURL(link.href)
              }).catch(console.error)
        }
      })
    },

    async downloadPDFListPayment() {
      if(((this.link1 || this.link2) && this.getPaymentListOriginal.length !== 0 && this.$route.path.indexOf('open/cash-flow') >= 0) || (this.getNextLinkPayment && this.getPaymentListOriginal.length !== 0 && this.$route.path.indexOf('open/cash-flow') === -1)) {
        this.showLoad()
        if(this.$route.path.indexOf('open/cash-flow') >= 0) {
          this.link1 = await this.axiosPaymentNextLinkForVesselPdf(this.link1)
        } else {
          await this.axiosPaymentNextLinkPdf()
        }

      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadPDF()
      }, 1000)
    },
    getFormatDate(date) {
      if(date !==null) {
        return "" + moment(date).format('DD.MM.YYYY')
      } else {
        return "-"
      }
    },
    getTextAccount(a) {
      if(this.cashFlow !== true) {
        if(a!== null && a!== undefined) {
          if(a.account !== null && a.account !== undefined) {
            if(a.account.length) {
              return (""+a.account.map(ac=> {return ac.id + ": " + ac.name + "<br/>"})).replaceAll(",", "")}
          }
        }
        return  "";
      } else {
        if(a!== null && a!== undefined) {
          if(a.account !== null && a.account !== undefined) {
            if(a.account.length) {
              return (""+a.account.map(ac=> {return ac.name + "<br/>"})).replaceAll(",", "")}
          }
        }
        return  "";
      }

    },
    getTextForStatus(str) {
      return str.replaceAll("_", " ");
    },
    getTextBankAndCashAccounts(payment) {
      if(payment.cash !== null) {
        return {name: payment.cash.name, id: payment.id, id_cash: payment.cash.id, type: 'cash'}
      } else if(payment.bank !== null) {
        return {name: payment.bank.bank, id: payment.id, id_cash: payment.bank.id, type: 'bank'}
      } else {
        return {name: "", id: payment.id, id_cash: "", type: 'bank'}
      }
    },
    getActiveColumsList() {
      this.$refs.paymentList.emitGetActiveList();
    },
    async downloadXLSXListPayment() {
      if(((this.link1 || this.link2) && this.getPaymentListOriginal.length !== 0 && this.$route.path.indexOf('open/cash-flow') >= 0) || (this.getNextLinkPayment && this.getPaymentListOriginal.length !== 0 && this.$route.path.indexOf('open/cash-flow') === -1)) {
        this.showLoad()
        if(this.$route.path.indexOf('open/cash-flow') >= 0) {
          this.link1 = await this.axiosPaymentNextLinkForVesselPdf(this.link1)
        } else {
          await this.axiosPaymentNextLinkPdf()
        }
        this.hideLoad()
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadXML()
      }, 500)
    },
    clickEditPaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "edit");
    },
    clickDuplicatePaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "duplicate");
    },
    showModalCreate() {
      this.$refs.modalCreate.openModal();
      this.keyRender++;
    },
    clickDelete(id) {
      this.$refs.modalDelete.openModal(id);
    },
    emitChangeDate(date) {
      this.cancelPostPayment();
      this.dateSelected = date;

      this.axiosChangeGetList();
    },
    axiosChangeGetList() {
      this.setTotalCashFlow({expense: 0, income: 0})
      let before = "";
      let after = "";
      // console.log("DATEEE324234234234234234234")
      // console.log(this.dateSelected);
      if(""+this.dateSelected[0] !== 'null' && this.dateSelected[0].length !== 0) {
        before = ""+moment(this.dateSelected [0]).utcOffset(0, true).format("YYYY-MM-DD")+ " 00:00:00";
      }
      if(""+this.dateSelected[1] !== 'null' && this.dateSelected[1].length !== 0) {
        after = ""+moment(this.dateSelected [1]).utcOffset(0, true).format("YYYY-MM-DD")+ " 23:59:59";
      }
      let type = "payment";
      if(this.$route.fullPath.indexOf("accounting-invoice") >= 0) {
        type = "invoice";
      }
      console.log("his.$route")

      console.log(this.$route.params.id)
      let orderExecuteDate = ''
      if(this.status_ordering) {
        orderExecuteDate += '-status'
      } else {
        orderExecuteDate += 'status'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-paid_date'
      } else {
        orderExecuteDate += ',paid_date'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-execute_date'
      } else {
        orderExecuteDate += ',execute_date'
      }
      orderExecuteDate = this.orderingName ? this.orderingName : orderExecuteDate
      console.log('orderExecuteDate', orderExecuteDate)
      if(this.$route.path.indexOf('open/cash-flow') >= 0) {
        // this.setPaymentsList([])
        // this.$refs.paymentList.setDataPayment([])
        if(this.$route.path.indexOf('archive') >= 0) {
          this.countRequest++;
          this.setFilterDataPaymentAxios({ordering: orderExecuteDate , limit: 25, before: before,after: after, cash_flow_ship: this.$route.params.id, is_archived: true, type: type})
          this.axiosGetPaymentForVessel({ordering: orderExecuteDate , limit: 25, before: before,after: after, cash_flow_ship: this.$route.params.id, is_archived: true, type: type}).then(res=> {
            this.link1 = res;
            this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
            this.countRequest--;
          });
          // this.countRequest++;
          // this.setFilterDataPaymentAxios({ordering: orderExecuteDate , limit: 13, before: before,after: after, settled_ship: this.$route.params.id, is_archived: true, type: type})
          // this.axiosGetPaymentForVessel({ordering: orderExecuteDate , limit: 12, before: before,after: after, settled_ship: this.$route.params.id, is_archived: true, type: type}).then(res=> {
          //   this.link2 = res;
          //   this.countRequest--;
          // });
        } else {
          this.countRequest++;
          this.setFilterDataPaymentAxios({ordering: orderExecuteDate , limit: 25, before: before,after: after, cash_flow_ship: this.$route.params.id, type: type})
          this.axiosGetPaymentForVessel({ordering: orderExecuteDate , limit: 25, before: before,after: after, cash_flow_ship: this.$route.params.id, type: type}).then(res=> {
            this.link1 = res;
            this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
            this.countRequest--;
          });
          // this.countRequest++;
          // this.setFilterDataPaymentAxios({ordering: orderExecuteDate , limit: 13, before: before,after: after, settled_ship: this.$route.params.id, type: type})
          // this.axiosGetPaymentForVessel({ordering: orderExecuteDate , limit: 12, before: before,after: after, settled_ship: this.$route.params.id, type: type}).then(res=> {
          //   this.link1 = res;
          //   this.countRequest--;
          // });
        }
        // this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
      } else {
        if(this.$route.path.indexOf('archive') >= 0) {
          this.setFilterDataPaymentAxios({ordering: orderExecuteDate , before: before,after: after, ship: this.$route.params.id, is_archived: true, type: type})
          this.axiosGetPayment({ordering: orderExecuteDate , before: before,after: after, ship: this.$route.params.id, is_archived: true, type: type}).then(()=> {
            this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
          });
        } else {
          this.setFilterDataPaymentAxios({ordering: orderExecuteDate , before: before,after: after, ship: this.$route.params.id, type: type})
          this.axiosGetPayment({ordering: orderExecuteDate , before: before,after: after, ship: this.$route.params.id, type: type}).then(()=> {
            this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
          });
        }
      }

    },
    renderList() {
      this.emitChangeDate(this.dateSelected);
    },

  },
  beforeMount() {
    if(this.$route.path.indexOf('accounting')>=0 && !this.getPermissionsByType('show_tab_acc_invoices_in_the_vessel_contract')){
      this.$router.replace(`/vessels/${this.$route.params.id}/open/detail`)
      return false
    }
  },
  mounted() {
    if(this.getDataPaymentModal !== null) {
      console.log("DATDATDA")
      if(this.getDataPaymentModal === 'duplicate') {
        this.$refs.modalDupOrEd.openModalWithDetailView(this.getInfoDetailPayment, "duplicate");
      } else {
        this.$refs.modalDupOrEd.openModalWithDetailView(this.getInfoDetailPayment, "edit");
      }
      this.setDataPaymentModal(null);
    }
  },
}
</script>

<style scoped lang="scss">
  @import "cashFlowVesselDetail";
  .b24 {
    bottom: 24px;
  }
  .detail-payment {
    /deep/ {
      .bottom-panel {
        button {
          display: none !important;
        }
      }
      .wrap-page {
        position: relative;

        background-color: var(--color-modal);
        .top-panel {
          display: none;
        }
      }
      &>.content {
        margin-top: 50px;
        width: 600px;
      }
      .view-file  {
        top: 0 !important;
        height: 100vh !important;
      }
      .content-wrap {
        &>*:not(:first-child) {
          display: none;
        }
        &>*:first-child {
          //overflow-y: visible;
          //overflow-x: visible;
          width: 100% !important;
          border: none !important;
        }
      }
    }
  }
  .wrap-bottom-panel-total {
    padding: 0 24px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    height: 40px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    /deep/ {
      .title-payments {
        &>*:not(.expense, .income) {
          opacity: 0;
        }
        width: calc(100% - 4px);
        height: 40px !important;
        background-color: transparent !important;
        .expense, .income {
          opacity: 1;
          padding: 0 6px !important;
          p {
            width: calc(100% - 12px);
            font-weight: 700 !important;
            font-size: 14px !important;
            line-height: 17px !important;
            text-align: right;
            text-transform: uppercase;
            color: var(--color-dark) !important;
          }
        }
        .currency {
          //border-right: 1px solid var(--color-border);
        }
        .income {
          border-left: 1px solid var(--color-border);
          border-right: 1px solid var(--color-border);

        }
        .expense {
          border-left: 1px solid var(--color-border);
          //border-right: 1px solid var(--color-border);
          position: relative;
          &::after {
            content: 'TOTAL:';
            position: absolute;
            left: -52px;
            font-weight: 600 !important;
            font-size: 13px !important;
            line-height: 16px;
            color: var(--color-dark)
          }
        }
        .status {
        }
      }
      .due-date {
        grid-area: due-date;
        display: flex;
        align-items: center;
        //min-width: 80px;
        //width: 80px;
        //width: 5.9%;
        input {
          padding: 4px;
          padding-right: 2px;
          height: 100%;
          border: none;
          border-radius: 0px;
        }
      }
      .vessel-state-and-member {
        grid-area: vessel-state-and-member;
        //width: 9%;
        min-width: 62px;
        height: 30px;
        input {
          height: 100%;
        }
      }
      .account {
        grid-area: account;
        height: 30px;
        //width: 13.8%;
        //@media (max-width: 1650px) {
        //  width: 10.8%;
        //  min-width: 64px;
        //}
      }
      .additionally {
        grid-area: additionally;
        //width: 11.8%;
        //@media(max-width: 1650px) {
        //  min-width: 64px;
        //  width: 10.8%;
        //}
      }
      .currency {
        grid-area: currency;
        //width: 40px;
      }
      .expense {
        grid-area: expense;
        min-width: 110px;

        ////min-width: 130px;
        ////width: 13%;
        //@media(max-width: 1650px) {
        //  min-width: 100px;
        //  width: 11%;
        //}
      }
      .income {
        grid-area: income;
        min-width: 110px;

      }
      .converted {
        grid-area: converted;
        min-width: 64px;
        //width: 11%;
        //@media (max-width: 1650px) {
        //  min-width: 64px;
        //  width: 4.8%;
        //}
      }
      .status {
        grid-area: status;
        //min-width: 67px;
        //width: 67px;
        p {
          line-height: 1.084rem !important;
        }
      }
      .paid-date {
        grid-area: paid-date;
        //min-width: 78px;
        //width: 7%;
      }
      .counterparty {
        min-width: 63px;
        grid-area: counterparty;
        //width: 10.6%;
        //@media (max-width: 1650px) {
        //  width: 11%;
        //  min-width: 64px;
        //}

      }
      .bank-and-cash-account {
        grid-area: bank-and-cash-account;
      }
      .remark {
        grid-area: remark;
        //width: 10.3%;
        //@media (max-width: 1650px) {
        //  width: 11%;
        //  min-width: 64px;
        //}
      }
      .attachment {
        grid-area: attachment;
      }
      .comment-from-accounting {
        grid-area: comment-from-accounting;
      }
      .settled-vsl {
        min-width: 57px;
        grid-area: settled-vsl;
      }
      .disbursement {
        min-width: 57px;
        grid-area: disbursement;
      }
      .real-account {
        min-width: 57px;
        grid-area: real-account;
      }
      .statement-member {
        grid-area: statement-member;
      }
      .balance {
        grid-area: balance;
      }
      .end-block {
        grid-area: end-block;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bottom-panel-total {

  }
  .cash-flow {
    height: calc(100vh - 56px - 51px - 38px - 45px) !important;
    /deep/ {
      .autoloader {
        margin-bottom: 0;
      }
      .pb55 {
        padding-bottom: 0 !important;
      }
      .wrap-gross-net {
        bottom: 4px !important;
      }
    }
  }
  .shortVersion {
    .wrap-bottom-panel-total {
      width: calc(100% - 260px) !important;
    }
  }
  .opacity-modal {
    top: 56px !important;
    /deep/
    .filter {
      top: 56px;
    }
    &>.content {
      margin-top: 150px;
    }
    .content {
      .top-panel {
        display: none;
      }
      .main-block {
        max-height: 2px;
        background-color: transparent !important;
      }
      .wrap-page-accounting-invoice {
        max-height: 2px;
        background-color: transparent !important;
      }
      .bottom-panel {
        display: none;
      }
    }
  }
</style>