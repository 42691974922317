<template>
  <section class="counterparties-add_section">
    <div class="container counterparties-add_container">
      <counterparties-bread :isEdit="true" />
      <h1 class="counterparties-add_title">Edit counterpartie profile</h1>
      <counterparties-add :isEdit="true" :bus="bus" />
    </div>
    <counterparties-add-bottom @save="addClient" :isEdit="true" />
  </section>
</template>

<script>
import Vue from 'vue'

import counterpartiesBread from '@/components/counterparties/counterpartiesAdd/counterpartiesBread'
import counterpartiesAdd from '@/components/counterparties/counterpartiesAdd/counterpartiesAdd'
import counterpartiesAddBottom from '@/components/counterparties/counterpartiesAdd/counterpartiesAddBottom'

export default {
  components: {
    counterpartiesBread,
    counterpartiesAdd,
    counterpartiesAddBottom,
  },
  data() {
    return {
      bus: new Vue(),
    }
  },
  methods: {
    addClient() {
      if(this.isEdit)
        this.bus.$emit('addClient');
      else
        this.bus.$emit('editClient');
    },
  },
  beforeRouteLeave(to, from, next) {
    next()
  },
  beforeRouteUpdate(to, from, next) {
    next()
  }
}
</script>

<style lang="scss">
</style>