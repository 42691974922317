<template>
  <div>
    <folders-list-file-and-folder></folders-list-file-and-folder>
  </div>
</template>


<script>
import FoldersListFileAndFolder from "@/components/folders/folders-list-file-and-folder.vue";

export default {
  name: 'folders-important-files',
  components: {FoldersListFileAndFolder}
}
</script>

<style scoped lang="scss">

</style>