<template>
<div>
<!--  {{ userActivity }}-->
</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  computed: mapGetters(["getToken"]),
  name: "socket",
  watch: {
    $route() {
        let data = {
          "token": this.getToken,
          "event_type": "set_activity",
          "activity": {
            "page": this.$route.name
          }
        }
        // console.log("DATA");
        // console.log(data);
        this.socket.send(JSON.stringify(data));

    }
  },
  data() {
    return {
      socket: null,
      connected: false,
      userActivity: null,
    }
  },
  methods: {
    ...mapMutations(["setActivityEmployees", "setSocket", 'setNotificationDot', 'setMailNotificationDot']),
    connectSocked() {
      // let url = 'ws://'+process.env.VUE_APP_URL_SOCKET+':'+process.env.VUE_APP_PORT_SOCKET+'/'
      // Ваня К. попросил изменить
      // let url = 'wss://'+process.env.VUE_APP_URL_SOCKET+':'+process.env.VUE_APP_PORT_SOCKET+'/'
      let url = 'wss://'+process.env.VUE_APP_URL_SOCKET+'/'
      console.log('Для Вани К. url::: ', url);

      // let url = 'ws://134.209.119.20:8080/'
      this.socket = new WebSocket(url);
    },
    getActivity() {
      let data = {
        "token": this.getToken,
        "event_type": "get_activities",
        "activity": {
          "page": this.$route.name
        }
      }
      this.socket.send(JSON.stringify(data));
    }
  },
  mounted() {
    let vm=this;
    this.connectSocked();

    //OPEn socket
    this.socket.onopen = function() {
      vm.setSocket(vm.socket);
      console.log("Socket - Соединение установлено.");
      let data = {
        "token": vm.getToken,
        "event_type": "set_activity",
        "activity": {
          "page": vm.$route.name
        }
      }
      vm.socket.send(JSON.stringify(data));
      vm.connected = true;
    };

    //Close socket
    this.socket.onclose = function () {
      console.log("Socket - Соединение закрыто");
      vm.connected = false;
      vm.connectSocked();
    }

    //Message
    this.socket.onmessage = function(event) {
      if(JSON.parse(event.data).event_type == 'new_message'){
        if(JSON.parse(event.data).detail.is_need_bell){
          vm.setMailNotificationDot(JSON.parse(event.data).detail.is_need_bell)
        }
      }
      if(JSON.parse(event.data).event_type == 'set_notification_dot'){
        vm.setNotificationDot(JSON.parse(event.data).type)
      }
      if(JSON.parse(event.data).event_type === "get_activities") {
        vm.userActivity = event.data;
        vm.setActivityEmployees(JSON.parse(event.data).activities)
      } else {
        vm.getActivity();
      }
    };

    //Error
    this.socket.onerror = function(error) {
      console.log("Ошибка " + error.message);
    };
  }
}
</script>

<style scoped>

</style>