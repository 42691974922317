<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">Unarchive Financial Statement <button class="modal-close" @click="$emit('close')"></button></p>
        <p class="modal-content_descr">Are you sure you want to unarchive the Finantial Statement <b>{{data.name}}</b>?</p>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="$emit('close')">Cancel</button>
        <button class="btn btn-next" @click="onSubmit">Unarchive</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  props: ['data'],
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['axiosEditFS', 'axiosGetsetFinancialStatementlist', 'axiosGetsetFinancialStatementArchivelist']),
    ...mapMutations(['addTip']),
    async onSubmit() {
      await this.axiosEditFS({
        id: this.data.id,
        name: this.data.name,
        is_archive: false
      })
      this.$emit('close')
      this.axiosGetsetFinancialStatementlist()
      this.axiosGetsetFinancialStatementArchivelist()
      this.addTip(this.data.name + ' is unarchived')
    }
  }
}
</script>

<style lang="scss">

</style>