<template>
  <transition name="fade">
    <div class="wrap-modal" v-if="showModal">
      <div class="filter"></div>
      <modal-slots class="modal-delete" :showMod="showModalDelete" @apply="deleteChecklist" @close="closeModalDelete" :isBorder="false" textForFirstButton="No" text-for-second-button="Yes">
        <template v-slot:title>
          <p class="fz16 fw600 text-dark1">Delete the checklist</p>
        </template>
        <p class="fz14 text text-dark1">Are you sure you want to delete this point?</p>
      </modal-slots>
      <div class="modal" :class="{'show-menu': showModal}">
        <div class="header">
          <button class="close" v-on:click="closeModal">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
              <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
          </button>
          <p class="fz18 fw500 text-dark1">Edit checklists</p>
        </div>
        <div>
          <button class="button-blue-border fz13 fw500 create-new buttonW100P h32" v-on:click="addNewCheckList()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"/>
            </svg>
            Create new checklist
          </button>
        </div>
        <div class="block-scroll">
          <draggable
              class="drag"
              group="a"
              v-model="checklist"
              handle=".handle"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              @change="addElementInList"
          >
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <div class="drag__wrap-element" v-for="(element, i) in checklist" :key="'s23d'+i">
                <div class="drag__element">
                  <button class="drag__dropdown-button" :class="{'rotateX180': element.openView === true}" v-on:click="element['openView'] = !element['openView']">
                    <svg class="svg-dart" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 4.40039L6.00001 8.40039L10 4.40039H2Z" fill="#2C2C2C"/>
                    </svg>
                  </button>
                  <button class="handle"  @click="element.fixed = !element.fixed" :class="
                element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.1667 12.8038H5.83333C5.37333 12.8038 5 12.4438 5 12.0002C5 11.5567 5.37333 11.1967 5.83333 11.1967H18.1667C18.6267 11.1967 19 11.5567 19 12.0002C19 12.4438 18.6267 12.8038 18.1667 12.8038Z" fill="#9AA3B0"/>
                      <path d="M18.1667 7.60714H5.83333C5.37333 7.60714 5 7.24714 5 6.80357C5 6.36 5.37333 6 5.83333 6H18.1667C18.6267 6 19 6.36 19 6.80357C19 7.24714 18.6267 7.60714 18.1667 7.60714Z" fill="#9AA3B0"/>
                      <path d="M18.1667 18H5.83333C5.37333 18 5 17.64 5 17.1964C5 16.7529 5.37333 16.3929 5.83333 16.3929H18.1667C18.6267 16.3929 19 16.7529 19 17.1964C19 17.64 18.6267 18 18.1667 18Z" fill="#9AA3B0"/>
                    </svg>
                  </button>
                  <input type="text" v-model="element.name" @focus="setCurrentName(element.name)" @focusout="saveNewNameCheckList(element.id, i)" placeholder="Enter checklist name">
                  <button class="delete-item" v-on:click="openModalAcceptDeleteChekList(i)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
                    </svg>
                  </button>
                </div>
                <div v-if="element.openView === true || drag1===true">
                  <draggable
                      class="drag"
                      :id="'asd'+i"
                      group="b"
                      v-model="element.check_list"
                      handle=".handle"
                      v-bind="dragOptions"
                      @start="drag1 = true"
                      @end="drag1 = false"
                      @add="addElementInList(i)"
                      @remove="removeElementInList(i)"
                      @change="changeDrag"
                  >
                    <div class="drag__element" v-for="(element, k) in element.check_list" :key="'s23d'+k">

                      <button class="handle"  @click="element.fixed = !element.fixed" :class="
                element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.1667 12.8038H5.83333C5.37333 12.8038 5 12.4438 5 12.0002C5 11.5567 5.37333 11.1967 5.83333 11.1967H18.1667C18.6267 11.1967 19 11.5567 19 12.0002C19 12.4438 18.6267 12.8038 18.1667 12.8038Z" fill="#9AA3B0"/>
                          <path d="M18.1667 7.60714H5.83333C5.37333 7.60714 5 7.24714 5 6.80357C5 6.36 5.37333 6 5.83333 6H18.1667C18.6267 6 19 6.36 19 6.80357C19 7.24714 18.6267 7.60714 18.1667 7.60714Z" fill="#9AA3B0"/>
                          <path d="M18.1667 18H5.83333C5.37333 18 5 17.64 5 17.1964C5 16.7529 5.37333 16.3929 5.83333 16.3929H18.1667C18.6267 16.3929 19 16.7529 19 17.1964C19 17.64 18.6267 18 18.1667 18Z" fill="#9AA3B0"/>
                        </svg>
                      </button>
                      <input type="text" v-model="element.name" @focus="setCurrentName(element.name)" @focusout="saveNewNameCheckInCheckList(element.id, i, k)" placeholder="Enter checklist name">
                      <button class="delete-item" v-on:click="openModalAcceptDeleteChek(i, k)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
                        </svg>
                      </button>
                    </div>
                  </draggable>
                  <button class="blue-button-text fz13 fw500" v-on:click="addNewCheckInCheckList(i, element)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"/>
                    </svg>
                    Create new check
                  </button>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import draggable from 'vuedraggable'
import {mapActions, mapGetters} from "vuex";
import ModalSlots from "@/components/modal/modal-slots";
export default {
  name: "modal-checklist",
  components: {ModalSlots, draggable},
  computed: {
    ...mapGetters(["getCheckList"]),
    dragOptions() {
      return {
        drag: false,
        animation: 100,
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  data() {
    return {
      drag: false,
      drag1: false,
      checklist: [],
      checklist1: [
        {id:0, name: "1"},
        {id:1, name: "3"},
        {id:2, name: "2"}
      ],
      currentName: "",
      indexForAdd: null,
      indexForRemove: null,
      indexForDeleteFirst: null,
      indexForDeleteSecond: null,
      elementDrag: null,
      showModalDelete: false,
      showModal: false,
      change: false,
    }
  },
  methods: {
    ...mapActions(["axiosDeleteCheck", "axiosDeleteCheckList", "axiosAddNewChecklist", "axiosGetTaskCheckListWithLoader", "axiosAddNewCheckInCheckList", "axiosChangeCheckList", "axiosChangeCheck"]),
    changeEmitAfterClose() {
      if(this.change) {
        this.$emit('changeEmitAfterClose')
      }
    },
    openModalChekList() {
      this.showModal = true;
      this.axiosGetTaskCheckListWithLoader({is_template: true}).then(res=> {
        if(res !== false) {
          this.checklist = res;
        }
      })
    },
    closeModal() {
      this.changeEmitAfterClose();
      this.chane = false;
      this.showModal = false;
    },
    openModalAcceptDeleteChekList(index) {
      this.indexForDeleteFirst = index;
      this.indexForDeleteSecond = null;
      this.showModalDelete = true;
    },
    openModalAcceptDeleteChek(index1, index2) {
      this.indexForDeleteFirst = index1;
      this.indexForDeleteSecond = index2;
      this.showModalDelete = true;
    },
    closeModalDelete() {
      this.showModalDelete = false;
      this.indexForDeleteFirst = null;
      this.indexForDeleteSecond = null;
    },
    async deleteChecklist() {
      this.change = true;
      let index = this.indexForDeleteFirst;
      let index1 = this.indexForDeleteFirst;
      let index2 =this.indexForDeleteSecond
      if(this.indexForDeleteSecond !== null && this.indexForDeleteFirst !== null) {
        let res = await this.axiosDeleteCheck(this.checklist[this.indexForDeleteFirst].check_list[this.indexForDeleteSecond].id)

        if(res) {
          this.checklist[index1].check_list.splice(index2, 1)
        }
        this.indexForDeleteSecond = null;
        this.indexForDeleteFirst = null;
      } if(this.indexForDeleteFirst !== null) {
        // this.checklist[this.indexForDeleteFirst].check_list.map(a=> {
        //   this.axiosDeleteCheck(a.id)
        // })
        console.log("DELETE")
        console.log(this.checklist[this.indexForDeleteFirst].name)
        console.log(this.indexForDeleteFirst)
        console.log(this.checklist[this.indexForDeleteFirst])
        for(let i=0; i<this.checklist[this.indexForDeleteFirst].check_list.length; i++) {
          // console.log(this.checklist[this.indexForDeleteFirst].check_list[i].id)
          this.axiosDeleteCheck(this.checklist[this.indexForDeleteFirst].check_list[i].id)
        }
      console.log(1)
        let res = await this.axiosDeleteCheckList(this.checklist[this.indexForDeleteFirst].id)
        console.log(2)
        if(res) {
          console.log("INDEX")
          console.log(index)
          console.log(res)

          this.checklist.splice(index, 1)
        }
        console.log(3)
        this.indexForDeleteFirst = null;
      }

    },
    addElementInList(event) {
      this.indexForAdd = event;
    },
    changeDrag(event) {
      if(event.removed !== undefined) {
        this.elementDrag = event.removed.element
      } else if(event.added !== undefined ) {
        this.elementDrag = event.added.element
      } else {
        this.elementDrag = null;
      }
    },
    removeElementInList(event) {
      this.change = true;

      this.indexForRemove = event;
      this.axiosChangeCheckList({id: this.checklist[this.indexForRemove].id, data:{check_list: this.checklist[this.indexForRemove].check_list.map(a=> {return a.id})}}).then(()=> {
        this.indexForRemove = null
      })
      this.axiosChangeCheckList({id: this.checklist[this.indexForAdd].id, data:{check_list: this.checklist[this.indexForAdd].check_list.map(a=> {return a.id})}}).then(()=> {
        this.indexForAdd = null
      })
    },

    saveNewNameCheckList(id, index) {
      this.change = true;

      let name = this.checklist[index].name;
      let nameCancel = this.currentName;
      if(name !== this.currentName) {
        this.axiosChangeCheckList({id: id, data:{name: name}}).then(res=> {
          if(res=== false) {
            this.checklist = this.checklist.map(a=> {
              if(a.id === id) {
                a.name = nameCancel;
              }
              return a;
            })
          }
        })
      }
      this.currentName = "";
    },
    saveNewNameCheckInCheckList(id, index, indexTwo) {
      this.change = true;
      let name = this.checklist[index].check_list[indexTwo].name;
      let nameCancel = this.currentName;
      if(name !== this.currentName) {
        this.axiosChangeCheck({id: id, data:{name: name}}).then(res=> {
          if(res=== false) {
            this.checklist = this.checklist.map(a=> {
              a.check_list.map(b=> {
                if(b.id === id) {
                  b.name = nameCancel;
                }
                return b
              })
              return a;
            })
          }
        })
      }
      this.currentName = "";
    },
    setCurrentName(name) {
      this.currentName = name;
    },
    addNewCheckList() {
      this.change = true;
      this.axiosAddNewChecklist().then(res=> {
        if(res !== false) {
          this.checklist = [res].concat(this.checklist)
        }
      })
    },
    addNewCheckInCheckList(index, element) {
      console.log("element")
      console.log(element)
      let checklist = element.check_list.map(a=> {
        return a.id
      })
      console.log(checklist)
      this.change = true;
      this.axiosAddNewCheckInCheckList({check_list: checklist, id: element.id}).then(res=> {
        if(res !== false) {
          this.checklist[index].check_list = [res].concat(this.checklist[index].check_list)
        }
      })
    }
  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
  @import "modal-checklist";
</style>