<template>
  <section class="accounts-wrap">
    <accounts-tabs :links="getLinksByPermissions" v-if="permissions.length && getPermissionsByType('show_accounting_section')"/>
  </section>
</template>

<script>
import accountsTabs from "@/components/counterparties/counterpartiesUser/counterpartiesUserTabs";
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    accountsTabs
  },
  data() {
    return {
    }
  },
  watch: {
    permissions: function(val) {
      if(val.length && !this.getPermissionsByType('show_accounting_section'))
        this.$router.replace({name: 'employees'})
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.confirmation.permissionsForUser
    }),
    ...mapGetters(['getPermissionsByType']),
    getLinksByPermissions() {
      let result = []

      if(this.getPermissionsByType('show_financial_statement'))
        result.push({
          to: 'fs',
          text: 'Financial Statement'
        })
      if(this.getPermissionsByType('show_trial_balance'))
        result.push({
          to: 'trial-balance',
          text: 'Trial Balance'
        })
      if(this.getPermissionsByType('show_bank_and_cash_accounts'))
        result.push({
          to: 'bank-and-cash',
          text: 'Bank and Cash Accounts'
        })
      
      if(this.getPermissionsByType('show_journal_entries_section_in_accounting'))
        result.push({
          to: 'entries',
          text: 'Journal Entries'
        })

      return result
    }
  },
}
</script>

<style scoped lang="scss">
  section {
    background-color: var(--color-bgc-page);
    height: calc(100vh - 56px);
    overflow-y: auto;
    align-items: stretch;
  }
  /deep/ .counterparties-tabs_top{
    text-align: center;
  }
</style>