<template>
  <div>

    <transition name="fade">
      <div class="modal-wrap" v-if="show" :class="{'opacityModal': opacityModal}">
        <div class="bg" v-on:click="hideModal"></div>

        <div class="content-modal">
          <div class="search-block">
            <input type="text" v-focus placeholder="Global search" v-model="search" @input="inputSearch">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.154 18.2908L14.9832 14.12C15.7911 13.1221 16.2775 11.854 16.2775 10.4729C16.2775 7.27196 13.673 4.66742 10.472 4.66742C7.27101 4.66742 4.6665 7.27193 4.6665 10.4729C4.6665 13.6739 7.27104 16.2784 10.472 16.2784C11.8531 16.2784 13.1212 15.792 14.1191 14.9841L18.2899 19.1549C18.409 19.274 18.5655 19.3339 18.7219 19.3339C18.8784 19.3339 19.0348 19.274 19.154 19.1549C19.3929 18.9159 19.3929 18.5297 19.154 18.2908ZM10.472 15.0562C7.94447 15.0562 5.88872 13.0004 5.88872 10.4729C5.88872 7.94536 7.94447 5.88961 10.472 5.88961C12.9996 5.88961 15.0553 7.94536 15.0553 10.4729C15.0553 13.0004 12.9995 15.0562 10.472 15.0562Z" fill="#2C2C2C"/>
            </svg>
            <button class="close" v-on:click="hideModal">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>

          <div class="result-search">
            <transition name="fade">
              <app-loader class="loader" v-if="axiosLoader"/>
            </transition>
            <div v-if="resultsGlobalSearch && resultsGlobalSearch.ship_template">
              <p class="fz12 fw500 text-gray" v-if="resultsGlobalSearch && resultsGlobalSearch.ship_template.data.length">candidates</p>
              <span v-if="resultsGlobalSearch">
<!--              <button class="fz14 text-dark1" v-for="(item, k) in resultsGlobalSearch.ship_template" v-on:click="openModalShipTemplate(item)" :key="k" v-html="getTextForNameTemplate(item)"></button>-->
              <button class="fz14 text-dark1" v-for="(item, k) in resultsGlobalSearch.ship_template.data" v-on:click="pushToParsedVessel(item)" :key="k" v-html="getTextForNameTemplate(item)"></button>
            </span>
              <span class="no-results" v-if="!getIfLength(resultsGlobalSearch) && !axiosLoader">
              <p class="fz14 text-dark1" v-if="!getIfLength(resultsGlobalSearch) && !search.length && !axiosLoader">Enter a keyword or multiple words</p>
              <p class="fz14 text-dark1" v-if="!getIfLength(resultsGlobalSearch) && search.length && !axiosLoader">No results were found for your search</p>
            </span>

              <pagination-panel-default v-if="resultsGlobalSearch.ship_template.currentShowPage < resultsGlobalSearch.ship_template.total_page" :if-active="resultsGlobalSearch.ship_template.showLoad" @load-more="loadMore('ship_template', resultsGlobalSearch.ship_template.currentShowPage)"></pagination-panel-default>
            </div>

<!--{{getResultsGlobalSearch.ship_template}}-->
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="modal-view-ship-template" v-if="showModalShipTemplate">
        <div class="modal-view-ship-template__bg" v-on:click="closeModalShipTemplate()"></div>
        <div class="modal-view-ship-template__content" :class="{'editBlock' : editShipTemplateBool}">
          <div class="modal-view-ship-template__name-panel">
            <p class="text-dark1 fz18 fw500">{{openShipTemplate.name}} / {{openShipTemplate.dwt}} <span v-if="openShipTemplate.year">/ {{openShipTemplate | formatDataYear}}</span></p>
            <button class="modal-view-ship-template__button-edit" v-on:click="editShipTemplate()" v-if="!editShipTemplateBool">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#094172"/>
              </svg>
            </button>
            <button class="modal-view-ship-template__button-close" v-on:click="closeModalShipTemplate()">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
                <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
              </svg>
            </button>
          </div>
          <div class="modal-view-ship-template__info fz14">
            <div :contenteditable="editShipTemplateBool" ref="shipTemplateHTML" v-html="getHtmlForShip(openShipTemplate.full_description)">
            </div>
            <div class="modal-view-ship-template__bottom-panel" v-if="editShipTemplateBool">
              <button class="blue-button-text fz14" v-on:click="closeModalShipTemplate">Cancel</button>
              <button class="blue-button fz14 fw500 h32" v-on:click="clickSaveEditShipTemplate">Save</button>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import AppLoader from "@/components/loader";
import moment from "moment"
import PaginationPanelDefault from "@/components/ports/paginationDefault";

export default {
  name: "search-global",
  components: {PaginationPanelDefault, AppLoader},
  computed: {
    ...mapGetters(["getResultsGlobalSearch"]),
    resultsGlobalSearch: {
      cache: false,
      get() {
        return this.getResultsGlobalSearch
      }
    },
  },
  data() {
    return {
      show: false,
      search: "",
      axiosLoader: false,
      openShipTemplate: null,
      showModalShipTemplate: false,
      editShipTemplateBool: false,
      opacityModal: false,
    }
  },
  methods: {
    ...mapActions(["axiosGlobalSearch", "axiosChangeSearchShipTemplate", 'axiosShowMoreGlobalSearch']),
    ...mapMutations(["setGlobalSearch", "setParsedVesselData"]),
    loadMore(namePage, currentNumberPage) {
      this.axiosShowMoreGlobalSearch({search: this.search, page_type: namePage, page: currentNumberPage + 1})
    },
    pushToParsedVessel(item) {
      console.log(item)
      // this.setParsedVesselData(item);
      this.$router.push({name: 'Parsed Vessels', params: {idParsedVessel: item.id}})
      this.hideModal();

    },
    getIfLength(data) {
      return data?.ship_template?.data?.length
    },
    getHtmlForShip(html) {
      let newHtml = html;
      if(newHtml.indexOf("<B>")>=0 && newHtml.indexOf("</B>")>=0) {
        newHtml = newHtml.replaceAll("<B>", "<br/><B>").replaceAll("<B>", "<b>").replaceAll("</B>", "</b>").replace("<br/>", "").replaceAll("-", "*")
        let line = "*******************************************************"
        for(let i=0; line.length > 4; i++) {
          newHtml = newHtml.replaceAll(line, "<br/>"+line.replaceAll("*", "-")+"<br/>")
          line = line.slice(0, line.length-1)
        }
      }
      return newHtml;
    },
    editShipTemplate() {
      this.editShipTemplateBool = true
    },
    clickSaveEditShipTemplate() {
      let html = this.$refs.shipTemplateHTML;
      this.axiosChangeSearchShipTemplate({data: {full_description: html.innerHTML}, id: this.openShipTemplate.id})
      this.openShipTemplate.full_description = html.innerHTML;
      this.editShipTemplateBool = false
    },
    openModalShipTemplate(ship) {
      console.log("ship")
      console.log(ship)
      this.opacityModal = true
      this.openShipTemplate = ship;
      this.showModalShipTemplate = true;
    },
    closeModalShipTemplate() {
      if(!this.editShipTemplateBool) {
        this.openShipTemplate = null;
        this.showModalShipTemplate = false;
        this.opacityModal = false
      } else {
        this.editShipTemplateBool = false
      }
    },
    getTextForNameTemplate(item) {
      let name = ""+item.mv;
      let nameLower = (""+item.mv).toLowerCase()
      if(this.search !== null) {
        let part1 = name.substring(0, nameLower.indexOf(this.search.toLowerCase()));
        let part2 = "<span class='text-gray fz14 fw500'>"+ name.substring(nameLower.indexOf(this.search.toLowerCase()), nameLower.lastIndexOf(this.search.toLowerCase()) + this.search.length)+"</span>";
        let part3 = name.substring(nameLower.lastIndexOf(this.search.toLowerCase()) + this.search.length, name.length);
        name = part1 + part2 + part3
      }
      return name + " / " + item.dwt + " / " + (item.year ? moment(item.year).format('YYYY') : '');
    },
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.search = ""
      this.show = false;
      this.setGlobalSearch(null)
    },
    inputSearch() {
      if(this.search.length > 1)
      this.axiosLoader = true;
      this.axiosGlobalSearch(this.search).then(()=> {
        this.axiosLoader = false;
      })
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

  .modal-wrap {
    transition: .3s linear;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    background-color: rgba(44,44,44, 0.15);
    backdrop-filter: blur(1.5px);
    display: flex;
    justify-content: center;
    //overflow: auto;
    .bg {
      transition: 1s linear;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //background-color: red;
    }
    .content-modal {
      position: relative;
      z-index: 10;
      display: flex;
      align-items: center;
      flex-direction: column;
      //top: 20px;
      //position: absolute;
      margin-top: 10px;
      width: 700px;
      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        height: max-content;
        position: absolute;
        right: 16px;
      }
      @media (max-width: 768px) {
        width: 90.65vw;
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  .search-block {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    &>*:nth-child(2) {
      position: absolute;
      left: 10px;
    }
    input {
      width: 100%;
      height: 48px;
      padding-left: 42px;
      padding-right: 30px;
      border-radius: 8px 8px 0 0;
    }
  }
  //.close {
  //  position: absolute;
  //  right: 16px;
  //}
  .result-search {
    position: relative;
    width: 100%;
    padding-bottom: 12px;
    box-sizing: border-box;
    min-height: 150px;
    background-color: var(--color-bgc-page-white);
    border: 1px solid var(--color-border);
    border-top: none;
    border-radius: 0 0 8px 8px;
    &>div>p, button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    span {
      width: 100%;
    }
    & button {
      width: 100%;
      height: 32px;
      padding: 0 16px;
      box-sizing: border-box;
      &:hover {
        background-color: var(--color-input);
      }
    }
    &>div>p {
      margin-top: 12px;
      margin-bottom: 4px;
      padding: 0 16px;
      text-transform: uppercase;
    }
    overflow-y: auto;
  }
  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-bgc-page-white) !important;
  }
  .modal-view-ship-template {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow-y: auto;
    &__bg {
      background-color: var(--color-filter-modal);
      opacity: 0.15;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &__content {
      margin-top: 20px;
      width: 508px;
      height: max-content;
      background-color: var(--color-bgc-page-white);
      position: relative;
      z-index: 10;
      border-radius: 8px;
    }
    &__name-panel {
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 14px 24px;
      border-bottom: 1px solid var(--color-card-border);
      box-sizing: border-box;
    }
    &__button-close {
      svg {
        path {
          fill: var(--color-dark);
        }
      }
      &:hover {
        svg {
          path {
            fill: var(--color-blue-dark)
          }
        }
      }
      &:active {
        svg {
          path {
            fill: var(--color-blue)
          }
        }
      }

      //margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__button-edit {
      svg {
        path {
          fill: var(--color-dark);
        }
      }
      &:hover {
        svg {
          path {
            fill: var(--color-blue-dark)
          }
        }
      }
      &:active {
        svg {
          path {
            fill: var(--color-blue)
          }
        }
      }
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }
    &__info {
      &>*:nth-child(1) {
        width: 100%;
        height: 100%;
        padding: 12px 24px;
        box-sizing: border-box;
        overflow-y: auto;
        max-height: calc(100vh - 147px);
        border: 1px solid transparent;
      }
      text-align: left;
      /deep/ {
        b {
          font-weight: 500;
        }
      }

    }
    &__bottom-panel {
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 7px 24px;
      .blue-button {
        width: 176px;
        margin-left: 32px;
      }
      border-radius: 0 0 8px 8px;
    }
  }
  .dart-mode {
    .modal-view-ship-template__bg {
      opacity: 0.75;
    }
  }
  .editBlock {
    .modal-view-ship-template__button-close {
      margin-left: auto;
    }
    .modal-view-ship-template__info {
      &>*:nth-child(1) {
        border: 1px solid var(--color-blue-dark);
        box-sizing: border-box;
      }
    }
  }

  .no-results {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-bgc-page-white);
  }

  .opacityModal {
    opacity: 0;
  }

</style>