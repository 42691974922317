<template>
    <transition name="fade">
      <div class="wrap-content" v-if="show">
        <div class="filter" v-on:click="show=false"></div>
        <div class="content" :class="{'show-menu': show}">
          <div class="wrap-block">
            <button class="close-menu" v-on:click="show=false">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
              </svg>
            </button>
            <p class="title fz18 fw500 text-dark1">Assign tasks for</p>
            <div class="block-choosed" :class="{'show-selected': departmentsSelected.length !== 0 || employeesSelected.length !== 0}">
              <p class="no-selected fz13 text-dark-gray">Select departments or individual employees</p>
              <p class="selectedText fz13 text-dark-gray">You choosed</p>
              <div class="wrap-choosed">
                <div v-for="(dep, i) in departmentsSelected" :key="i">
                  <p class="fz12 text-dark1">{{ dep.name }}</p>
                  <button v-on:click="removeDepartmentsSelected(i)">
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.05373 1.9253C0.810215 1.68418 0.810216 1.29326 1.05373 1.05214C1.29724 0.811029 1.69206 0.811029 1.93557 1.05214L6.94574 5.97329C7.18925 6.21441 7.18925 6.60533 6.94574 6.84644C6.70223 7.08756 6.30741 7.08756 6.0639 6.84644L1.05373 1.9253Z" fill="#2C2C2C"/>
                      <path d="M6.06398 1.15353C6.3075 0.912416 6.70231 0.912416 6.94582 1.15353C7.18933 1.39465 7.18933 1.78557 6.94582 2.02669L1.93565 6.94783C1.69214 7.18895 1.29732 7.18895 1.05381 6.94783C0.810298 6.70672 0.810298 6.31579 1.05381 6.07468L6.06398 1.15353Z" fill="#2C2C2C"/>
                    </svg>
                  </button>
                </div>

                <div v-for="(emp, i) in employeesSelected" :key="i">
                  <p class="fz12 text-dark1">{{ emp.first_name }} {{emp.last_name}}</p>
                  <button v-on:click="removeEmployeesSelected(i)">
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.05373 1.9253C0.810215 1.68418 0.810216 1.29326 1.05373 1.05214C1.29724 0.811029 1.69206 0.811029 1.93557 1.05214L6.94574 5.97329C7.18925 6.21441 7.18925 6.60533 6.94574 6.84644C6.70223 7.08756 6.30741 7.08756 6.0639 6.84644L1.05373 1.9253Z" fill="#2C2C2C"/>
                      <path d="M6.06398 1.15353C6.3075 0.912416 6.70231 0.912416 6.94582 1.15353C7.18933 1.39465 7.18933 1.78557 6.94582 2.02669L1.93565 6.94783C1.69214 7.18895 1.29732 7.18895 1.05381 6.94783C0.810298 6.70672 0.810298 6.31579 1.05381 6.07468L6.06398 1.15353Z" fill="#2C2C2C"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="search-block">
              <input type="text" class="fz13" placeholder="Search by name" v-model="search" @input="searchByName">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.154 18.2908L14.9832 14.12C15.7911 13.1221 16.2775 11.854 16.2775 10.4729C16.2775 7.27196 13.673 4.66742 10.472 4.66742C7.27101 4.66742 4.6665 7.27193 4.6665 10.4729C4.6665 13.6739 7.27104 16.2784 10.472 16.2784C11.8531 16.2784 13.1212 15.792 14.1191 14.9841L18.2899 19.1549C18.409 19.274 18.5655 19.3339 18.7219 19.3339C18.8784 19.3339 19.0348 19.274 19.154 19.1549C19.3929 18.9159 19.3929 18.5297 19.154 18.2908ZM10.472 15.0562C7.94447 15.0562 5.88872 13.0004 5.88872 10.4729C5.88872 7.94536 7.94447 5.88961 10.472 5.88961C12.9996 5.88961 15.0553 7.94536 15.0553 10.4729C15.0553 13.0004 12.9995 15.0562 10.472 15.0562Z" fill="#2C2C2C"/>
              </svg>
            </div>
<!--            {{getDepartments}}-->
<!--            {{getEmployees}}-->
<!--            {{employeesSelected}}-->
<!--            {{titleActive}}-->
<!--            {{indexActive}}-->
<!--            {{departmentsSelected}}-->
<!--            {{selec2}}-->

            <div class="checkboxes-block">
              <checkbox-dropdown-in-modal :key="keyRender" @changeCheckbox="changeCheckbox" ref="checkbox1" :checkboxes="departments" :selec="selec1"></checkbox-dropdown-in-modal>
              <checkbox-dropdown-in-modal :key="keyRender1" @changeCheckbox="changeCheckbox" ref="checkbox2" :checkboxes="employees" :selec="selec2"></checkbox-dropdown-in-modal>
            </div>

          </div>
          <div class="bottom-panel">
            <button class="blue-button" v-on:click="clickSave">Save</button>
          </div>
        </div>
      </div>
    </transition>
</template>

<script>
import CheckboxDropdownInModal from "@/components/addEmployeesStep2/checkbox-dropdown-in-modal";
import {mapGetters, mapActions, mapMutations} from 'vuex'
export default {
  props: ["idActive", "titleActive", "indexActive"],
  name: "checkbox-right-menu",
  components: {CheckboxDropdownInModal},
  computed: mapGetters(["getEmployees", "getDepartments", "getPermissions", "getClickButton", "getShowAddDepartment", "getPermissionsAddDepartment", "getShowEditDepartment", "getPermissionsEditDepartment"]),
  data() {
    return {
      show: false,
      keyRender1: 0,
      keyRender: -100,
      search: "",
      departments: {
        title: "Departments",
        firstCount: 0,
        details: [
        ],
      },
      employees: {
        title: "Employees",
        firstCount: 0,
        details: [
        ],
      },
      departmentsSelected: [],
      employeesSelected: [],
      selec1: [],
      selec2: [],
    }
  },
  methods: {
    ...mapActions(["axiosGetEmployees", "axiosGetDepartments"]),
    ...mapMutations(["changeActiveCheckboxInMenu", "setClickButton"]),
    removeDepartmentsSelected(index) {
      this.departmentsSelected.splice(index, 1);
      this.selec1 = this.departmentsSelected;
      this.keyRender+=1;
      this.keyRender1+=1;
    },
    removeEmployeesSelected(index) {
      this.employeesSelected.splice(index, 1);
      this.selec2 = this.employeesSelected;
    },
    setSelected() {
      if(this.getShowAddDepartment) {
        // console.log("this.indexActive");
        this.selec1.splice(0, this.selec1.length);
        this.selec2.splice(0, this.selec2.length);
        this.indexActive = this.getClickButton;
        if(this.indexActive !== null) {
          for(let i=0; i<this.getPermissionsAddDepartment.length; i++) {
            if(this.titleActive === this.getPermissionsAddDepartment[i].title) {
              // console.log("Ты ждешь этого" + this.indexActive)
              this.selec1 = JSON.parse(JSON.stringify(this.getPermissionsAddDepartment[i].details[this.indexActive].activeCheckboxInMenu.departments));
              this.selec2 = JSON.parse(JSON.stringify(this.getPermissionsAddDepartment[i].details[this.indexActive].activeCheckboxInMenu.employees));
              // console.log(this.getPermissionsAddDepartment[i].details[this.indexActive].activeCheckboxInMenu.departments);
              // console.log(this.getPermissionsAddDepartment[i].details[this.indexActive].activeCheckboxInMenu.employees);
            }
          }
        }
        // console.log(this.keyRender);
      } else if (this.getShowEditDepartment) {
        this.selec1.splice(0, this.selec1.length);
        this.selec2.splice(0, this.selec2.length);
        this.indexActive = this.getClickButton;
        if(this.indexActive !== null) {
          for(let i=0; i<this.getPermissionsEditDepartment.length; i++) {
            if(this.titleActive === this.getPermissionsEditDepartment[i].title) {
              // console.log("Ты ждешь этого" + this.indexActive)
              this.selec1 = JSON.parse(JSON.stringify(this.getPermissionsEditDepartment[i].details[this.indexActive].activeCheckboxInMenu.departments));
              this.selec2 = JSON.parse(JSON.stringify(this.getPermissionsEditDepartment[i].details[this.indexActive].activeCheckboxInMenu.employees));
              // console.log(this.getPermissionsEditDepartment[i].details[this.indexActive].activeCheckboxInMenu.departments);
              // console.log(this.getPermissionsEditDepartment[i].details[this.indexActive].activeCheckboxInMenu.employees);
            }
          }
        }
      } else {
        // console.log("this.indexActive");
        this.selec1.splice(0, this.selec1.length);
        this.selec2.splice(0, this.selec2.length);
        this.indexActive = this.getClickButton;
        if(this.indexActive !== null) {
          for(let i=0; i<this.getPermissions.length; i++) {
            if(this.titleActive === this.getPermissions[i].title) {
              // console.log("Ты ждешь этого" + this.indexActive)
              this.selec1 = JSON.parse(JSON.stringify(this.getPermissions[i].details[this.indexActive].activeCheckboxInMenu.departments));
              this.selec2 = JSON.parse(JSON.stringify(this.getPermissions[i].details[this.indexActive].activeCheckboxInMenu.employees));
              // console.log(this.this.getPermissions[i].details[this.indexActive].activeCheckboxInMenu.departments);
              // console.log(this.getPermissions[i].details[this.indexActive].activeCheckboxInMenu.employees);
            }
          }
        }
        // console.log(this.keyRender);
      }
      // this.selec1 = this.getSelected1MenuCheckbox.departments;
      // this.selec2 = this.getSelected1MenuCheckbox.employees
    },
    setSelected1() {
      // // console.log("Вторая кнопка");
      // this.selec1 = JSON.parse(JSON.stringify(this.getSelected2MenuCheckbox.departments));
      // this.selec2 = JSON.parse(JSON.stringify(this.getSelected2MenuCheckbox.employees));
    },
    changeCheckbox(data) {
      // console.log(data);

      // console.log(111);
      if(data.title === this.departments.title) {
        this.departmentsSelected = data.selected;
      }
      if(data.title === this.employees.title) {
        this.employeesSelected = data.selected;
      }
    },
    showMenu() {
      this.employees.details = [];
      this.departments.details = [];
      for(let i=0; i<this.getEmployees.data.results.length; i++) {
        this.employees.details.push(this.getEmployees.data.results[i]);
      }
      for(let i=0; i<this.getDepartments.data.results.length; i++) {
        this.departments.details.push(this.getDepartments.data.results[i]);
      }
      this.search = "";
      this.show = true;
      // this.$refs.checkbox1.setSel();
      this.departmentsSelected.splice(0,this.departmentsSelected.length);
      this.employeesSelected.splice(0,this.employeesSelected.length);
    },
    // setSelected(selected1, selected2) {
    //   this.changeCheckbox1(selected1);
    //   this.changeCheckbox2(selected2);
    //   this.$refs.ch1.selSel(selected1);
    //   this.$refs.ch2.selSel(selected2);
    // },
    clickSave() {
      this.show = false;
      // console.log(this.departmentsSelected);
      this.$emit("save", { departments: this.departmentsSelected, employees: this.employeesSelected});
    },
    searchByName() {
      this.employees.details = [];
      this.departments.details = [];
      for(let i=0; i<this.getEmployees.data.results.length; i++) {
        let fullName = this.getEmployees.data.results[i].first_name + " " + this.getEmployees.data.results[i].last_name;
        if((fullName.toUpperCase()).indexOf(this.search.toUpperCase()) !== -1) {
          this.employees.details.push(this.getEmployees.data.results[i]);
        }
      }
      for(let i=0; i<this.getDepartments.data.results.length; i++) {
        if((this.getDepartments.data.results[i].name.toUpperCase()).indexOf(this.search.toUpperCase()) !== -1) {
          this.departments.details.push(this.getDepartments.data.results[i]);
        }
      }
    },
  },
  mounted() {
    // console.log("MENU");
    if(this.getEmployees === null) {
      this.axiosGetEmployees().then(()=> {
        for(let i=0; i<this.getEmployees.data.results.length; i++) {
          this.employees.details.push(this.getEmployees.data.results[i]);
        }
        this.employees.firstCount = this.getEmployees.data.results.length;
      });
    } else {
      for(let i=0; i<this.getEmployees.data.results.length; i++) {
        this.employees.details.push(this.getEmployees.data.results[i]);
      }
      this.employees.firstCount = this.getEmployees.data.results.length;
    }
   if(this.getDepartments === null) {
     this.axiosGetDepartments().then(()=> {
       for(let i=0; i<this.getDepartments.data.results.length; i++) {
         this.departments.details.push(this.getDepartments.data.results[i]);
       }
       this.departments.firstCount = this.getDepartments.data.results.length;
     });
   } else {
     for(let i=0; i<this.getDepartments.data.results.length; i++) {
       this.departments.details.push(this.getDepartments.data.results[i]);
     }
     this.departments.firstCount = this.getDepartments.data.results.length;
   }

  }
}
</script>

<style scoped lang="scss">
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/checkbox";
  .bottom-panel {
    height: 44px;
    width: 480px;
    padding: 4px 56px;
    background: var(--color-card);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    //overflow-y: auto;
    //overflow-x: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: fixed;
    z-index: 350;
    bottom: 0;
    //right: 0;

    .blue-button {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      @media (max-width: 1280px) {
        width: 368px;
      }
      @media (max-width: 768px) {
        width: 93.75vw;
      }
    }
    @media (max-width: 1280px) {
      position: fixed;
      bottom: 0;
      width: 100%;
      right: 0;
    }
    @media (max-width: 768px) {
      padding: 4px 0;
    }
  }
  .checkboxes-block {
    @media (min-width: 768px) {
      padding-bottom: 44px;
    }
    &>* {
      padding: 16px 0;
      border-top: 1px solid var(--color-card-border);
      @media (max-width: 768px) {
        padding: 20px 0;
      }
    }
    padding-bottom: 24px;
  }
  .search-block {
    //margin-left: auto;
    position: relative;
    display: flex;
    align-items: center;
    width: 100% !important;
    svg {
      position: absolute;
      left: 10px;
    }
    input {
      width: 100% !important;
      height: 32px !important;
      padding-left: 42px;

    }

    //margin-bottom: 21px;
    padding-top: 16px;
    padding-bottom: 21px;
    @media (max-width: 768px) {
      padding-top: 20px;
    }
  }
  .wrap-content {
    width: 100vw;
    height: 100%;
    z-index: 30000;
    overflow: hidden;
    position: fixed;
    //top: 0;
    bottom: 0;
    right: 0;
    //left: 0;
    body {
      overflow: hidden;
    }
  }
  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200;
    right: 0;
    background-color: rgba(44,44,44,0.15);
  }
  .content {
    .close-menu {
      position: absolute;
      width: 16px !important;
      height: 16px !important;
      left: 20px;
      top: 17px;
      @media (max-width: 1280px) {
        left: auto;
        right: 16px;
        top: 16px;
      }
      //@media (max-width: 768px) {
      //  right: calc(16px - 3.125vw);
      //}
    }
    .wrap-block {
      padding: 16px 56px 0px 56px;
      min-height: calc(100% - 44px);
      box-sizing: border-box;
      height: 100%;
      overflow-y: auto;
      @media (max-width: 1280px) {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
        &>*:not(.close-menu) {
          width: 360px !important;
        }
        padding: 18px 8px 0 0;
      }
      @media (max-width: 768px) {
        position: relative;
        width: 100%;
        padding: 18px 0;
        &>*:not(.close-menu) {
          width: 93.75vw !important;
        }
      }
    }
    width: 480px;
    height: 100%;
    //overflow-y: scroll;
    //overflow-x: hidden;
    overflow: hidden;

    background-color: var(--color-card);
    z-index: 300;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s linear;
    .title {
      margin-bottom: 14px;
    }
    &>* {
      //padding-right: 56px;
    }
    @media (max-width: 1280px) {
      width: 100%;
      display: flex;
      justify-content: center;
      //flex-direction: column;
      //align-items: center;
    }
  }
  .show-menu {
    transform: translateX(0);
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    .content {
      transform: translateX(100%);
    }
  }
  .blue-button-text {
    display: inline;
  }
  .border-block {
    width: 100%;
    height: 100%;
    padding: 16px 20px;
  }
  .block-choosed {
    //min-height: 38px;
    padding-top: 4px;
    //padding-bottom: 12px;
    p {
      margin-bottom: 0;
    }
    .selectedText {
      display: none;
    }
    .no-selected {
      //position: relative;
      //top: 10px;
    }
    .wrap-choosed {
      display: none;
    }
  }
  .show-selected {
    .no-selected {
      display: none;
    }
    .selectedText {
      display: block;
    }
    .wrap-choosed {
      //margin-top: 4px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &>* {

        margin-right: 4px;
        margin-top: 4px;
        position: relative;
        width: max-content;
        box-sizing: border-box;
        background-color: var(--color-card-border);
        padding: 2px 24px 2px 8px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        p {
          &::first-letter {
            text-transform: uppercase;
          }
        }
        button {
          cursor: pointer;
          position: absolute;
          //top: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 10px;
          svg {
            path {
              fill: var(--color-dark);
            }
          }
        }
      }
    }
  }

</style>