<template>
  <b-dropdown left ref="dropdown" @hide="closeDrop" @show="showDrop">
    <template #button-content>
      <button class="button-drop" :class="{'buttonShowAfterHover': buttonShowAfterHover && !openDrop, 'disabled' : disabled}">
        <span class="no-select" v-if="dueDate.length === 0 && customDate.length === 0 && !repeat && !workRepeat && !showDueDate && !showSelectCustomDays">
          <span class="fz14 text-dark1">
            {{textNoSelect}}
          </span>
        </span>
        <span class="title-template" v-if="(customDate.length === 0 && dueDate.length !== 0 && !repeat && !workRepeat && !showSelectCustomDays) || showDueDate">
          <button v-if="dueDate.length !== 0" class="button-opacity" v-on:click.stop="clearDueDate">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
            <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
          </button>
          <button v-if="dueDate.length === 0">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84211 3.03516C9.21998 3.03516 9.52632 3.33949 9.52632 3.71489V4.86522H14.4737V3.71489C14.4737 3.33949 14.78 3.03516 15.1579 3.03516C15.5358 3.03516 15.8421 3.33949 15.8421 3.71489V4.86522H16.2105C17.7511 4.86522 19 6.10595 19 7.63646V16.2639C19 17.7944 17.7511 19.0352 16.2105 19.0352H7.78947C6.24889 19.0352 5 17.7944 5 16.2639V7.63646C5 6.10595 6.24889 4.86522 7.78947 4.86522H8.15789V3.71489C8.15789 3.33949 8.46423 3.03516 8.84211 3.03516ZM8.15789 6.2247H7.78947C7.00465 6.2247 6.36842 6.85677 6.36842 7.63646V9.8979H17.6316V7.63646C17.6316 6.85677 16.9954 6.2247 16.2105 6.2247H15.8421V7.37502C15.8421 7.75043 15.5358 8.05476 15.1579 8.05476C14.78 8.05476 14.4737 7.75043 14.4737 7.37502V6.2247H9.52632V7.37502C9.52632 7.75043 9.21998 8.05476 8.84211 8.05476C8.46423 8.05476 8.15789 7.75043 8.15789 7.37502V6.2247ZM17.6316 11.2574H6.36842V16.2639C6.36842 17.0436 7.00465 17.6757 7.78947 17.6757H16.2105C16.9954 17.6757 17.6316 17.0436 17.6316 16.2639V11.2574Z" fill="#2c2c2c"/>
          </svg>
          </button>
          <span class="fz14 text-dark1 text" :class="{'due-date':dueDate.length !== 0}">{{dueDate.length === 0 ? 'Select due date' : getFormDate(dueDate)}}</span>
        </span>
        <span class="title-template" v-if="(customDate.length !== 0 && !repeat && !workRepeat && !showDueDate) || showSelectCustomDays">
          <button v-if="customDate.length !== 0" class="button-opacity" v-on:click.stop="clearCustomDate">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
            <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
          </button>
          <button v-if="customDate.length === 0">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 13C13.5 13.8284 12.8284 14.5 12 14.5C11.1716 14.5 10.5 13.8284 10.5 13C10.5 12.1716 11.1716 11.5 12 11.5C12.8284 11.5 13.5 12.1716 13.5 13Z" fill="#2C2C2C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52632 4.67974C9.52632 4.30433 9.21998 4 8.84211 4C8.46423 4 8.15789 4.30433 8.15789 4.67974V5.83007H7.78947C6.24889 5.83007 5 7.07079 5 8.60131V17.2288C5 18.7593 6.24889 20 7.78947 20H16.2105C17.7511 20 19 18.7593 19 17.2288V8.60131C19 7.07079 17.7511 5.83007 16.2105 5.83007H15.8421V4.67974C15.8421 4.30433 15.5358 4 15.1579 4C14.78 4 14.4737 4.30433 14.4737 4.67974V5.83007H9.52632V4.67974ZM7.79019 7.19141H8.15861V8.34173C8.15861 8.71714 8.46495 9.02147 8.84282 9.02147C9.2207 9.02147 9.52704 8.71714 9.52704 8.34173V7.19141H14.4744V8.34173C14.4744 8.71714 14.7807 9.02147 15.1586 9.02147C15.5365 9.02147 15.8428 8.71714 15.8428 8.34173V7.19141H16.2112C16.9961 7.19141 17.6323 7.82347 17.6323 8.60317V17.2306C17.6323 18.0103 16.9961 18.6424 16.2112 18.6424H7.79019C7.00537 18.6424 6.36914 18.0103 6.36914 17.2306V8.60317C6.36914 7.82348 7.00537 7.19141 7.79019 7.19141Z" fill="#2C2C2C"/>
          </svg>
          </button>
          <span class="fz14 text-dark1" :class="{'custom-date': customDate.length !== 0}" v-html="customDate.length === 0 ? 'Select custom days repeat' : getFormDateCustom(customDate)"></span>
        </span>
        <span class="title-template" v-if="customDate.length === 0 && !showSelectCustomDays && !showDueDate">
          <button v-if="repeat" class="button-opacity" v-on:click.stop="clearRepeat">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
            <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
          </button>
          <span class="fz14 text-dark1" v-if="repeat">Everyday repeat</span>
          <button v-if="workRepeat" class="button-opacity" v-on:click.stop="clearWorkRepeat">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
            <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
          </button>
          <span class="fz14 text-dark1" v-if="workRepeat">Every workday repeat</span>
        </span>
        <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/>
        </svg>
      </button>
    </template>
    <div class="wrap" v-if="!disabled">
      <div class="content" :class="{'displayNone': showDueDate || showSelectCustomDays}">
        <button v-on:click="openDueDate" class="fz14">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84211 3.03516C9.21998 3.03516 9.52632 3.33949 9.52632 3.71489V4.86522H14.4737V3.71489C14.4737 3.33949 14.78 3.03516 15.1579 3.03516C15.5358 3.03516 15.8421 3.33949 15.8421 3.71489V4.86522H16.2105C17.7511 4.86522 19 6.10595 19 7.63646V16.2639C19 17.7944 17.7511 19.0352 16.2105 19.0352H7.78947C6.24889 19.0352 5 17.7944 5 16.2639V7.63646C5 6.10595 6.24889 4.86522 7.78947 4.86522H8.15789V3.71489C8.15789 3.33949 8.46423 3.03516 8.84211 3.03516ZM8.15789 6.2247H7.78947C7.00465 6.2247 6.36842 6.85677 6.36842 7.63646V9.8979H17.6316V7.63646C17.6316 6.85677 16.9954 6.2247 16.2105 6.2247H15.8421V7.37502C15.8421 7.75043 15.5358 8.05476 15.1579 8.05476C14.78 8.05476 14.4737 7.75043 14.4737 7.37502V6.2247H9.52632V7.37502C9.52632 7.75043 9.21998 8.05476 8.84211 8.05476C8.46423 8.05476 8.15789 7.75043 8.15789 7.37502V6.2247ZM17.6316 11.2574H6.36842V16.2639C6.36842 17.0436 7.00465 17.6757 7.78947 17.6757H16.2105C16.9954 17.6757 17.6316 17.0436 17.6316 16.2639V11.2574Z" fill="#9AA3B0"/>
          </svg>
          <p class="fz14 text-dark1">
            Select due date
          </p>
        </button>
        <button class="fz14" v-on:click="selectRepeat">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5015 11.25C18.403 11.2501 18.3048 11.2696 18.2138 11.3073C18.1229 11.3451 18.0402 11.4004 17.9706 11.4701C17.9011 11.5398 17.8459 11.6226 17.8083 11.7136C17.7707 11.8046 17.7513 11.9022 17.7514 12.0007C17.7505 13.0521 17.4613 14.0832 16.9154 14.9819C16.3695 15.8805 15.5877 16.6123 14.655 17.0978C13.7223 17.5832 12.6744 17.8037 11.6251 17.7353C10.5759 17.6669 9.56546 17.3121 8.70371 16.7097C6.10683 14.8913 5.47343 11.2992 7.2918 8.70225C9.11018 6.10534 12.7023 5.47188 15.2992 7.29031C15.6921 7.56469 16.0491 7.88708 16.3621 8.25H14.2515C14.0525 8.25 13.8618 8.32902 13.7211 8.46967C13.5805 8.61032 13.5015 8.80109 13.5015 9C13.5015 9.19891 13.5805 9.38968 13.7211 9.53033C13.8618 9.67098 14.0525 9.75 14.2515 9.75H17.7515C17.9504 9.75 18.1411 9.67098 18.2818 9.53033C18.4224 9.38968 18.5015 9.19891 18.5015 9V5.5C18.5015 5.30109 18.4224 5.11032 18.2818 4.96967C18.1411 4.82902 17.9504 4.75 17.7515 4.75C17.5525 4.75 17.3618 4.82902 17.2211 4.96967C17.0805 5.11032 17.0015 5.30109 17.0015 5.5V6.74897C16.7383 6.49925 16.4569 6.26945 16.1596 6.06147C15.3797 5.51542 14.5 5.12832 13.5705 4.92227C12.6411 4.71622 11.6801 4.69525 10.7426 4.86057C9.80503 5.02589 8.90922 5.37426 8.1063 5.88578C7.30338 6.3973 6.60907 7.06196 6.06302 7.84181C4.96022 9.41679 4.52825 11.3653 4.86213 13.2588C5.196 15.1523 6.26839 16.8356 7.84336 17.9384C9.05597 18.7925 10.5029 19.2511 11.9861 19.2513C12.4133 19.2511 12.8397 19.2137 13.2603 19.1393C14.9389 18.8432 16.4595 17.9652 17.5551 16.6595C18.6507 15.3538 19.2513 13.7038 19.2515 11.9993C19.2513 11.8006 19.1722 11.61 19.0316 11.4694C18.8909 11.3289 18.7003 11.25 18.5015 11.25Z" fill="#2C2C2C"/>
            <path d="M13.5008 12C13.5008 12.8284 12.8292 13.5 12.0008 13.5C11.1724 13.5 10.5008 12.8284 10.5008 12C10.5008 11.1716 11.1724 10.5 12.0008 10.5C12.8292 10.5 13.5008 11.1716 13.5008 12Z" fill="#2C2C2C"/>
          </svg>
          <p class="fz14 text-dark1">
            Everyday repeat
          </p>
        </button>
        <button class="fz14" v-on:click="selectWorkRepeat">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5015 11.25C18.403 11.2501 18.3048 11.2696 18.2138 11.3073C18.1229 11.3451 18.0402 11.4004 17.9706 11.4701C17.9011 11.5398 17.8459 11.6226 17.8083 11.7136C17.7707 11.8046 17.7513 11.9022 17.7514 12.0007C17.7505 13.0521 17.4613 14.0832 16.9154 14.9819C16.3695 15.8805 15.5877 16.6123 14.655 17.0978C13.7223 17.5832 12.6744 17.8037 11.6251 17.7353C10.5759 17.6669 9.56546 17.3121 8.70371 16.7097C6.10683 14.8913 5.47343 11.2992 7.2918 8.70225C9.11018 6.10534 12.7023 5.47188 15.2992 7.29031C15.6921 7.56469 16.0491 7.88708 16.3621 8.25H14.2515C14.0525 8.25 13.8618 8.32902 13.7211 8.46967C13.5805 8.61032 13.5015 8.80109 13.5015 9C13.5015 9.19891 13.5805 9.38968 13.7211 9.53033C13.8618 9.67098 14.0525 9.75 14.2515 9.75H17.7515C17.9504 9.75 18.1411 9.67098 18.2818 9.53033C18.4224 9.38968 18.5015 9.19891 18.5015 9V5.5C18.5015 5.30109 18.4224 5.11032 18.2818 4.96967C18.1411 4.82902 17.9504 4.75 17.7515 4.75C17.5525 4.75 17.3618 4.82902 17.2211 4.96967C17.0805 5.11032 17.0015 5.30109 17.0015 5.5V6.74897C16.7383 6.49925 16.4569 6.26945 16.1596 6.06147C15.3797 5.51542 14.5 5.12832 13.5705 4.92227C12.6411 4.71622 11.6801 4.69525 10.7426 4.86057C9.80503 5.02589 8.90922 5.37426 8.1063 5.88578C7.30338 6.3973 6.60907 7.06196 6.06302 7.84181C4.96022 9.41679 4.52825 11.3653 4.86213 13.2588C5.196 15.1523 6.26839 16.8356 7.84336 17.9384C9.05597 18.7925 10.5029 19.2511 11.9861 19.2513C12.4133 19.2511 12.8397 19.2137 13.2603 19.1393C14.9389 18.8432 16.4595 17.9652 17.5551 16.6595C18.6507 15.3538 19.2513 13.7038 19.2515 11.9993C19.2513 11.8006 19.1722 11.61 19.0316 11.4694C18.8909 11.3289 18.7003 11.25 18.5015 11.25Z" fill="#2C2C2C"/>
            <path d="M13.5008 12C13.5008 12.8284 12.8292 13.5 12.0008 13.5C11.1724 13.5 10.5008 12.8284 10.5008 12C10.5008 11.1716 11.1724 10.5 12.0008 10.5C12.8292 10.5 13.5008 11.1716 13.5008 12Z" fill="#2C2C2C"/>
          </svg>
          <p class="fz14 text-dark1">
            Every workday repeat
          </p>
        </button>
        <button class="fz14" v-on:click="openSelectCustomDays">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 13C13.5 13.8284 12.8284 14.5 12 14.5C11.1716 14.5 10.5 13.8284 10.5 13C10.5 12.1716 11.1716 11.5 12 11.5C12.8284 11.5 13.5 12.1716 13.5 13Z" fill="#2C2C2C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52632 4.67974C9.52632 4.30433 9.21998 4 8.84211 4C8.46423 4 8.15789 4.30433 8.15789 4.67974V5.83007H7.78947C6.24889 5.83007 5 7.07079 5 8.60131V17.2288C5 18.7593 6.24889 20 7.78947 20H16.2105C17.7511 20 19 18.7593 19 17.2288V8.60131C19 7.07079 17.7511 5.83007 16.2105 5.83007H15.8421V4.67974C15.8421 4.30433 15.5358 4 15.1579 4C14.78 4 14.4737 4.30433 14.4737 4.67974V5.83007H9.52632V4.67974ZM7.79019 7.19141H8.15861V8.34173C8.15861 8.71714 8.46495 9.02147 8.84282 9.02147C9.2207 9.02147 9.52704 8.71714 9.52704 8.34173V7.19141H14.4744V8.34173C14.4744 8.71714 14.7807 9.02147 15.1586 9.02147C15.5365 9.02147 15.8428 8.71714 15.8428 8.34173V7.19141H16.2112C16.9961 7.19141 17.6323 7.82347 17.6323 8.60317V17.2306C17.6323 18.0103 16.9961 18.6424 16.2112 18.6424H7.79019C7.00537 18.6424 6.36914 18.0103 6.36914 17.2306V8.60317C6.36914 7.82348 7.00537 7.19141 7.79019 7.19141Z" fill="#2C2C2C"/>
          </svg>
          <p class="fz14 text-dark1">
            Select custom days repeat
          </p>
        </button>
      </div>
      <div class="content-date" :class="{'displayNone': !showDueDate}">
        <date-picker
            :inline="true"
            readonly="readonly"
            lang="en"
            :type="!showSelectTime ? 'date' : 'datetime'"
            class="date-select-due"
            ref="datepi"
            placeholder=""
            :show-time-panel="showTimePanel && showSelectTime"
            :format="!showSelectTime ? 'D MMMM YYYY' : 'D MMMM YYYY HH:mm'"
            value-type="date"
            v-model="dueDate"
            :editable="false"
        >
          <template v-slot:footer v-if="showSelectTime">
            <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
              {{ showTimePanel ? 'Select date' : 'Select time' }}
            </button>
          </template>
        </date-picker>
        <div class="bottom-panel">
          <button class="blue-button-text fz14" v-on:click="dueDateCancel">Cancel</button>
          <button class="blue-button fz14 fw500" v-on:click="dueDateAccept">Choose</button>
        </div>
      </div>
      <div class="content-date" :class="{'displayNone': !showSelectCustomDays}">
        <date-picker
            :multiple="true"
            :inline="true"
            readonly="readonly"
            type="date"
            lang="en"
            class="date-select-due"
            ref="datepi"
            placeholder=""
            format="D MMMM YYYY"
            value-type="date"
            v-model="customDate"
            :editable="false"
        ></date-picker>
        <div class="bottom-panel">
          <button class="blue-button-text fz14" v-on:click="customDateCancel">Cancel</button>
          <button class="blue-button fz14 fw500" v-on:click="customDateAccept">Choose</button>
        </div>
      </div>
    </div>

  </b-dropdown>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment"
export default {
  name: "selectDueDate",
  components: {DatePicker},
  props: {
    resetAllAfterSelectDueDate: Boolean,
    shortView: Boolean,
    itemForChange: {
      type: Object,
      default: null,
    },
    activeDueDate: {
      type: String,
      default: "",
    },
    activeCustomDays: null,
    due_date_status: {
      type: String,
      default: "",
    },
    textNoSelect: {
      type: String,
      default: ""
    },
    buttonShowAfterHover: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    showSelectTime: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    activeDueDate: function () {
      if(this.activeDueDate !== null && (""+this.activeDueDate).length !== 0) {
        this.dueDate = new Date(this.activeDueDate);
        this.createEmit();
      } else {
        this.dueDate = ""
        this.createEmit();
      }
    },
    activeCustomDays: function () {
      if(this.activeCustomDays !== null && (""+this.activeCustomDays).length !== 0) {
        this.customDate = this.activeCustomDays;
        this.createEmit();
      } else {
        this.customDate = []
        this.createEmit();
      }
    },
    due_date_status: function () {
      if(this.due_date_status === 'due_date') {
        this.repeat = false;
        this.workRepeat = false;
        this.customDate = [];
        this.createEmit();
      }
      if(this.due_date_status === 'every_day_repeat') {
        this.repeat = true;
        this.workRepeat = false;
        this.customDate = [];
        this.createEmit();
      }
      if(this.due_date_status === 'every_workday_repeat') {
        this.workRepeat = true;
        this.repeat = false;
        this.customDate = [];
        this.createEmit();
      }
      if(this.due_date_status === 'custom_days_repeat') {
        this.workRepeat = false;
        this.repeat = false;
        this.createEmit();
      }

      if(this.due_date_status === null || (''+this.due_date_status).length === 0) {
        this.workRepeat = false;
        this.repeat = false;
        this.customDate = [];
        this.createEmit();
      }

    }
  },
  data() {
    return {
      showDueDate: false,
      dueDate: "",
      customDate: [],
      repeat: false,
      workRepeat: false,
      showSelectCustomDays: false,
      valueCancel: null,
      openDrop: false,
      showTimePanel: false,
    }
  },
  methods: {
    clearData(e) {
      if(e) {
        e.preventDefault()
      }

      // this.repeat = false;
      // this.workRepeat = false;
      // this.customDate = [];
      // this.dueDate = ''
      this.clearWorkRepeat();
      this.clearCustomDate()
      this.clearRepeat()
      // this.createEmit();
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    openDropdown() {
      this.$refs.dropdown.show();
    },
    showDrop() {
      this.openDrop = true;
    },
    selectRepeat() {
      this.repeat = true;
      this.workRepeat = false;
      this.customDate = [];
      this.createEmit()
    },
    clearRepeat(e) {
      if(e) {
        e.preventDefault()
      }
      this.repeat = false;
      this.workRepeat = false;
      this.customDate = [];
      this.createEmit()
    },
    selectWorkRepeat() {
      this.repeat = false;
      this.workRepeat = true;
      this.customDate = [];
      this.createEmit()
    },
    clearWorkRepeat(e) {
      if(e) {
        e.preventDefault()
      }
      this.workRepeat = false;
      this.repeat = false;
      this.customDate = [];
      this.createEmit()
    },
    clearDueDate(e) {
      if(e) {
        e.preventDefault()
      }
      this.dueDate = "";
    },
    getFormDate(date) {
      if(this.showSelectTime) {
        if(moment(date).format("HH:mm") === "00:00") {
          return moment(date).format("DD MMM YYYY")
        }
        return moment(date).format("DD MMM YYYY HH:mm")
      }
      return moment(date).format("DD MMM YYYY")
    },
    getFormDateCustom(date) {
      let text = ""
      if(!this.shortView) {
        if(date.length <= 2) {
          date.map(a=> {
            text+= ""+moment(a).format("DD MMM YYYY")+ ", "
          })
          text = text.substring(0, text.length-2);
        } else {
          for(let i=0; i<2; i++) {
            text += ""+moment(date[i]).format("DD MMM YYYY")+ ", "
          }
          text += " <span class='circleNumber fz12'>+"+ (date.length - 2) +"</span>"
        }
      } else {
        if(date.length <= 1) {
          date.map(a=> {
            text+= ""+moment(a).format("DD MMM YYYY")
          })
          // text = text.substring(0, text.length-2);
        } else {
          for(let i=0; i<1; i++) {
            text += ""+moment(date[i]).format("DD MMM YYYY")
          }
          text += " <span class='circleNumber fz12'>+"+ (date.length - 1) +"</span>"
        }
      }
      return text;
    },
    openDueDate() {
      this.showDueDate = true;
      this.valueCancel = this.dueDate
    },
    dueDateCancel() {
      // this.dueDate = "";
      this.showDueDate = false;
      this.dueDate = this.valueCancel;
      this.valueCancel = null;
      this.showTimePanel = false
      // this.$emit("changeDueDate", this.dueDate)
    },
    dueDateAccept() {
      this.showDueDate = false;
      this.valueCancel = null;
      this.showTimePanel = false
      if(this.resetAllAfterSelectDueDate === true) {
        this.customDate = [];
        this.itemForChange.due_date_status = 'due_date'
      }
      this.createEmit()
    },
    closeDrop() {
      this.showTimePanel = false
      this.openDrop = false;
      if(this.showDueDate) {
        this.dueDate = this.valueCancel;
      }
      if(this.showSelectCustomDays) {
        this.customDate = this.valueCancel
      }
      this.valueCancel = null;
      this.showDueDate = false;
      this.showSelectCustomDays = false;
      let form = {
        days_for_repeat: this.customDate.length ? this.customDate.map(a=> {return Number(moment(a).format("D"))}) : [],
        due_date_status: !this.repeat && !this.workRepeat && !this.customDate.length ? "due_date" : this.repeat ? "every_day_repeat" : this.workRepeat ? "every_workday_repeat" : this.customDate.length ? "custom_days_repeat" : "",
        execute_time: ((''+this.dueDate).length !== 0 && this.dueDate !== null) ? moment(this.dueDate).format("YYYY-MM-DD HH:mm") : null
      }
      if(this.index !== null) {
        form.index = this.index
      }
      if(this.itemForChange) {
        this.itemForChange.days_for_repeat = form.days_for_repeat
        this.itemForChange.due_date_status = form.due_date_status
        this.itemForChange.due_date = form.execute_time
      }
      this.$emit("close", form)
    },
    openSelectCustomDays() {
      this.valueCancel = this.customDate;
      this.showSelectCustomDays = true;
    },
    customDateAccept() {
      this.showSelectCustomDays = false;
      this.valueCancel = null
      this.repeat = false;
      this.workRepeat = false;
      this.createEmit()
    },
    customDateCancel() {
      this.showSelectCustomDays = false;
      this.customDate = this.valueCancel;
      this.valueCancel = null
      this.createEmit()
    },
    clearCustomDate(e) {
      if(e) {
        e.preventDefault()
      }
      this.customDate = []
      this.repeat = false;
      this.workRepeat = false;
      this.createEmit()
    },
    createEmit() {
      let form = {
        days_for_repeat: this.customDate.length ? this.customDate.map(a=> {return Number(moment(a).format("D"))}) : [],
        due_date_status: !this.repeat && !this.workRepeat && !this.customDate.length ? "due_date" : this.repeat ? "every_day_repeat" : this.workRepeat ? "every_workday_repeat" : this.customDate.length ? "custom_days_repeat" : "",
        execute_time: ((''+this.dueDate).length !== 0 && this.dueDate !== null) ? moment(this.dueDate).format("YYYY-MM-DD HH:mm") : null
      }
      if(this.index !== null) {
        form.index = this.index
      }
      // console.log(form)
      this.$emit('change', form)
      this.$refs.dropdown.hide()
    }
  },
  mounted() {
    if(this.activeDueDate !== null && (""+this.activeDueDate).length !== 0) {
      this.dueDate = this.activeDueDate;
      this.createEmit();
    } else {
      this.dueDate = ""
      this.createEmit();
    }
    if(this.activeCustomDays !== null && (""+this.activeCustomDays).length !== 0) {
      this.customDate = this.activeCustomDays
      this.createEmit();
    } else {
      this.customDate = [];
      this.createEmit();
    }
    if(this.due_date_status === 'due_date') {
      this.repeat = false;
      this.workRepeat = false;
      this.customDate = [];
      this.createEmit()
    }
    if(this.due_date_status === 'every_day_repeat') {
      this.repeat = true;
      this.workRepeat = false;
      this.customDate = [];
      this.createEmit()
    }
    if(this.due_date_status === 'every_workday_repeat') {
      this.workRepeat = true;
      this.repeat = false;
      this.customDate = [];
      this.createEmit()
    }
    if(this.due_date_status === 'custom_days_repeat') {
      this.workRepeat = false;
      this.repeat = false;
      this.createEmit()
    }
    if(this.due_date_status === null || (''+this.due_date_status).length === 0) {
      this.workRepeat = false;
      this.repeat = false;
      this.customDate = [];
      this.createEmit();
    }
  }
}
</script>

<style scoped lang="scss">
  @import "selectDueDate";
</style>