<template>
  <div>
    <transition name="fade">
      <div class="view-file" v-show="showViewFiles">
        <div class="container">
          <div class="view-file__top-panel">
            <div class="edit-file-name">
              <button class="edit-file-name__button fz14 fw500" v-if="!isEdit">
                {{editNameFileInModal}}
              </button>
              <button class="edit-file-name__button fz14 fw500" v-on:click="editNameInModal" v-else-if="!editNameModeInModal">
                {{editNameFileInModal}}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="white"/>
                </svg>
              </button>
              <div class="edit-file-name__block" v-if="editNameModeInModal">
                <input type="text" class="fz14 fw500 text-white" v-model="editNameFileInModal">
                <button v-on:click="saveEditNameInModal">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="white"/>
                  </svg>
                </button>
                <button v-on:click="closeEditNameInModal">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.51424 7.57874C6.21661 7.28404 6.21661 6.80625 6.51424 6.51155C6.81186 6.21685 7.29441 6.21685 7.59204 6.51155L17.4868 16.2975C17.7844 16.5922 17.7844 17.07 17.4868 17.3647C17.1892 17.6594 16.7066 17.6594 16.409 17.3647L6.51424 7.57874Z" fill="white"/>
                    <path d="M16.4091 6.63547C16.7067 6.34077 17.1893 6.34077 17.4869 6.63547C17.7845 6.93016 17.7845 7.40796 17.4869 7.70266L7.59214 17.4886C7.29451 17.7833 6.81196 17.7833 6.51434 17.4886C6.21671 17.1939 6.21671 16.7161 6.51434 16.4214L16.4091 6.63547Z" fill="white"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="block-scale-button">
              <div class="block-scale-button__block">
                <button v-on:click="zoomOut">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2222 12.7778L5.77787 12.7778C5.34849 12.7778 5 12.4293 5 12.0001C5 11.5707 5.34849 11.2222 5.77787 11.2222L11.2222 11.2222L12.7778 11.2222L18.2223 11.2222C18.6515 11.2222 19 11.5707 19 12.0001C19 12.4293 18.6515 12.7778 18.2223 12.7778L12.7778 12.7778L11.2222 12.7778Z" fill="white"/>
                  </svg>
                </button>
                <p class="fz13 fw500 text-white-always">{{ zoomFile }}%</p>
                <button v-on:click="zoomIn">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"/>
                  </svg>
                </button>
              </div>
              <button v-on:click="zoomSetDefault">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.77951 5.77792L5.77951 5.07788C5.39293 5.07788 5.07955 5.3913 5.07955 5.77792L5.77951 5.77792ZM18.2224 18.2221L18.2249 18.9222C18.6095 18.9208 18.921 18.6093 18.9224 18.2247L18.2224 18.2221ZM18.9351 14.6819C18.9365 14.2953 18.6243 13.9808 18.2377 13.9794C17.8511 13.978 17.5366 14.2903 17.5352 14.6769L18.9351 14.6819ZM14.6775 17.5349C14.2909 17.5363 13.9787 17.8508 13.9801 18.2374C13.9815 18.624 14.296 18.9363 14.6826 18.9349L14.6775 17.5349ZM9.33462 6.47795C9.7212 6.47795 10.0346 6.16454 10.0346 5.77792C10.0346 5.3913 9.7212 5.07788 9.33462 5.07788L9.33462 6.47795ZM5.07955 9.33341C5.07955 9.72003 5.39293 10.0334 5.77951 10.0334C6.16609 10.0334 6.47947 9.72003 6.47947 9.33341L5.07955 9.33341ZM5.28456 6.27292L17.7275 18.7171L18.7174 17.7271L6.27446 5.28292L5.28456 6.27292ZM18.9224 18.2247L18.9351 14.6819L17.5352 14.6769L17.5225 18.2196L18.9224 18.2247ZM18.2199 17.5221L14.6775 17.5349L14.6826 18.9349L18.2249 18.9222L18.2199 17.5221ZM5.77951 6.47795L9.33462 6.47795L9.33462 5.07788L5.77951 5.07788L5.77951 6.47795ZM5.07955 5.77792L5.07955 9.33341L6.47947 9.33341L6.47947 5.77792L5.07955 5.77792Z" fill="white"/>
                  <path d="M18.2209 5.77824L18.9209 5.77824C18.9209 5.39162 18.6075 5.0782 18.2209 5.0782L18.2209 5.77824ZM5.77802 18.2225L5.07806 18.225C5.07944 18.6096 5.39089 18.9211 5.7755 18.9225L5.77802 18.2225ZM9.31786 18.9352C9.70444 18.9366 10.0189 18.6244 10.0203 18.2377C10.0217 17.8511 9.70948 17.5366 9.3229 17.5352L9.31786 18.9352ZM6.46522 14.6772C6.46383 14.2906 6.14932 13.9783 5.76274 13.9797C5.37617 13.9811 5.06392 14.2956 5.06531 14.6822L6.46522 14.6772ZM17.521 9.33373C17.521 9.72035 17.8343 10.0338 18.2209 10.0338C18.6075 10.0338 18.9209 9.72035 18.9209 9.33373L17.521 9.33373ZM14.6658 5.0782C14.2792 5.0782 13.9658 5.39162 13.9658 5.77824C13.9658 6.16486 14.2792 6.47827 14.6658 6.47827L14.6658 5.0782ZM17.726 5.28323L5.28307 17.7275L6.27296 18.7175L18.7159 6.27324L17.726 5.28323ZM5.7755 18.9225L9.31786 18.9352L9.3229 17.5352L5.78054 17.5224L5.7755 18.9225ZM6.47797 18.2199L6.46522 14.6772L5.06531 14.6822L5.07806 18.225L6.47797 18.2199ZM17.521 5.77824L17.521 9.33373L18.9209 9.33373L18.9209 5.77824L17.521 5.77824ZM18.2209 5.0782L14.6658 5.0782L14.6658 6.47827L18.2209 6.47827L18.2209 5.0782Z" fill="white"/>
                </svg>
              </button>
            </div>
            <div class="block-option-button">
<!--              <a v-if="activeFile" :href="activeFile.file" download target="_blank" class="block-option-button__download">-->
<!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M13.034 5C13.034 4.6134 12.7206 4.3 12.334 4.3C11.9474 4.3 11.634 4.6134 11.634 5H13.034ZM12.334 14L11.8399 14.4959C12.113 14.768 12.5549 14.768 12.828 14.4959L12.334 14ZM16.828 10.5102C17.1019 10.2373 17.1027 9.79412 16.8298 9.52026C16.5569 9.2464 16.1137 9.24561 15.8399 9.51848L16.828 10.5102ZM8.82804 9.51848C8.55419 9.24561 8.11097 9.2464 7.8381 9.52026C7.56522 9.79412 7.56602 10.2373 7.83987 10.5102L8.82804 9.51848ZM19.7006 16C19.7006 15.6134 19.3872 15.3 19.0006 15.3C18.614 15.3 18.3006 15.6134 18.3006 16H19.7006ZM6.03397 16C6.03397 15.6134 5.72057 15.3 5.33397 15.3C4.94737 15.3 4.63397 15.6134 4.63397 16H6.03397ZM11.634 5V14H13.034V5H11.634ZM12.828 14.4959L16.828 10.5102L15.8399 9.51848L11.8399 13.5041L12.828 14.4959ZM12.828 13.5041L8.82804 9.51848L7.83987 10.5102L11.8399 14.4959L12.828 13.5041ZM17.0006 18.3H7.33397V19.7H17.0006V18.3ZM18.3006 16V17H19.7006V16H18.3006ZM6.03397 17V16H4.63397V17H6.03397ZM7.33397 18.3C6.616 18.3 6.03397 17.718 6.03397 17H4.63397C4.63397 18.4912 5.84281 19.7 7.33397 19.7V18.3ZM17.0006 19.7C18.4918 19.7 19.7006 18.4912 19.7006 17H18.3006C18.3006 17.718 17.7186 18.3 17.0006 18.3V19.7Z" fill="white"/>-->
<!--                </svg>-->
<!--              </a>-->
              <button v-on:click="doenloadFile" v-if="activeFile" class="block-option-button__download">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.034 5C13.034 4.6134 12.7206 4.3 12.334 4.3C11.9474 4.3 11.634 4.6134 11.634 5H13.034ZM12.334 14L11.8399 14.4959C12.113 14.768 12.5549 14.768 12.828 14.4959L12.334 14ZM16.828 10.5102C17.1019 10.2373 17.1027 9.79412 16.8298 9.52026C16.5569 9.2464 16.1137 9.24561 15.8399 9.51848L16.828 10.5102ZM8.82804 9.51848C8.55419 9.24561 8.11097 9.2464 7.8381 9.52026C7.56522 9.79412 7.56602 10.2373 7.83987 10.5102L8.82804 9.51848ZM19.7006 16C19.7006 15.6134 19.3872 15.3 19.0006 15.3C18.614 15.3 18.3006 15.6134 18.3006 16H19.7006ZM6.03397 16C6.03397 15.6134 5.72057 15.3 5.33397 15.3C4.94737 15.3 4.63397 15.6134 4.63397 16H6.03397ZM11.634 5V14H13.034V5H11.634ZM12.828 14.4959L16.828 10.5102L15.8399 9.51848L11.8399 13.5041L12.828 14.4959ZM12.828 13.5041L8.82804 9.51848L7.83987 10.5102L11.8399 14.4959L12.828 13.5041ZM17.0006 18.3H7.33397V19.7H17.0006V18.3ZM18.3006 16V17H19.7006V16H18.3006ZM6.03397 17V16H4.63397V17H6.03397ZM7.33397 18.3C6.616 18.3 6.03397 17.718 6.03397 17H4.63397C4.63397 18.4912 5.84281 19.7 7.33397 19.7V18.3ZM17.0006 19.7C18.4918 19.7 19.7006 18.4912 19.7006 17H18.3006C18.3006 17.718 17.7186 18.3 17.0006 18.3V19.7Z" fill="white"/>
                </svg>
              </button>

              <button v-if="isEdit" class="block-option-button__delete" v-on:click="deleteFileInModal">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="white"/>
                </svg>
              </button>
              <button class="block-option-button__close" v-on:click="hideViewFileModal">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.51424 7.57874C6.21661 7.28404 6.21661 6.80625 6.51424 6.51155C6.81186 6.21685 7.29441 6.21685 7.59204 6.51155L17.4868 16.2975C17.7844 16.5922 17.7844 17.07 17.4868 17.3647C17.1892 17.6594 16.7066 17.6594 16.409 17.3647L6.51424 7.57874Z" fill="white"/>
                  <path d="M16.4091 6.63547C16.7067 6.34077 17.1893 6.34077 17.4869 6.63547C17.7845 6.93016 17.7845 7.40796 17.4869 7.70266L7.59214 17.4886C7.29451 17.7833 6.81196 17.7833 6.51434 17.4886C6.21671 17.1939 6.21671 16.7161 6.51434 16.4214L16.4091 6.63547Z" fill="white"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="view-file__main">
            <button class="button-prev" v-if="showNavigationButton && files.length > 1" v-on:click="prevFile">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.71834 11.5972L14.8343 2.29486C15.1014 2.023 15.5339 2.02345 15.8006 2.29626C16.067 2.56904 16.0664 3.01092 15.7992 3.28299L7.16851 12.0911L15.7992 20.8084C16.0663 21.0805 16.067 21.5221 15.8006 21.7949C15.6669 21.9317 15.4918 22.0001 15.3166 22.0001C15.142 22.0001 14.9675 21.9321 14.8341 21.7963L5.71834 12.5851C5.58968 12.4544 5.51749 12.2765 5.51749 12.0911C5.51749 11.9058 5.58989 11.7281 5.71834 11.5972Z" fill="white"/>
              </svg>
            </button>
            <div class="block-with-file position-relative">
              <app-loader :is-local="true" v-if="isLoadIframe"></app-loader>
              <img v-if="formatThisImg && activeFile" :src="activeFile.file" alt="" :style="{transform: 'scale(' + zoomFile/100 + ')'}">

              <video v-if="formatThisVideo && activeFile" controls="controls" :style="{transform: 'scale(' + zoomFile/100 + ')'}" :src="activeFile.file"></video>
<!--              :src="getlinkPDF(activeFile ? activeFile.file : '')"-->
              <iframe ref="iframe" v-show="formatIframe"
                      style="width: 100%; height: 100%;">Ваш браузер не поддерживает фреймы</iframe>

              <p v-if="!formatThisImg && !formatThisVideo && !formatIframe" class="fz16 fw500 text-white">Unable to display "{{activeFile ? activeFile.name : ''}}".<br>Download the file to view it on your device</p>
            </div>
            <button class="button-next" v-if="showNavigationButton && files.length > 1" v-on:click="nextFile">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.2817 12.4028L9.16569 21.7051C8.89857 21.977 8.46609 21.9765 8.19942 21.7037C7.93295 21.431 7.93364 20.9891 8.2008 20.717L16.8315 11.9089L8.2008 3.1916C7.93368 2.91949 7.93299 2.47789 8.19942 2.20508C8.3331 2.06832 8.50823 1.99994 8.68336 1.99994C8.85805 1.99994 9.03249 2.06786 9.16593 2.20367L18.2817 11.4149C18.4103 11.5456 18.4825 11.7235 18.4825 11.9089C18.4825 12.0942 18.4101 12.2719 18.2817 12.4028Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppLoader from "@/components/loader";
import axios from "axios";
export default {
  components: {AppLoader},
  props: {
    showNavigationButton: {
      type: Boolean,
      default: true
    },
    files: Array,
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showViewFiles: false,
      activeFile: null,
      showViewFileIndex: null,
      editNameFileInModal: '',
      formatTxt: false,
      editNameModeInModal: false,
      formatThisImg: false,
      formatThisVideo: false,
      zoomFile: 100,
      keyRenderIFRAME: -5000,
      formatIMG: [".jpeg", ".png", ".raw", ".jpg", ".bmp", ".gif", ".svg"],
      formatVIDEO: [".mkv", ".mov"],
      formatIFRAME: [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".xps", ".ppt", ".pptx", ".ps", ".eps", '.txt'],
      isLoadIframe: false,
      formatIframe: false,
    }
  },
  methods: {
    doenloadFile() {
      let file = this.files[this.showViewFileIndex]
      axios.get(file.file, { responseType: 'blob' })
          .then(response => {
            console.log(response)
            const blob = new Blob([response.data], { type: 'application/plain' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = file.name;
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    getlinkPDF(link) {
      // if(link.substring(link.lastIndexOf(".")).indexOf('txt') >=0) {
      //   this.formatTxt = true;
      //   return link
      // } else {
      //   this.formatTxt = false;
      // }
      // return 'https://view.officeapps.live.com/op/embed.aspx?src='+link
      return this.formatIframe ? "https://docs.google.com/gview?url="+link +"&embedded=true" : ''
      // return "http://docs.google.com/gview?url="+link +"&embedded=true"
    },
    prevFile() {
      if(this.showViewFileIndex !== 0) {
        this.showViewFileIndex -=1;
        this.zoomFile = 100;
        this.showViewFileModal(this.showViewFileIndex);
      }
    },
    nextFile() {
      if(this.showViewFileIndex !== this.files.length-1) {
        this.showViewFileIndex +=1;
        this.zoomFile = 100;
        this.showViewFileModal(this.showViewFileIndex);
      }
    },
    closeEditNameInModal() {
      this.editNameModeInModal = false;
      this.editNameFileInModal = JSON.parse(JSON.stringify(this.activeFile.name));
    },
    saveEditNameInModal() {
      this.editNameFileInModal = this.editNameFileInModal.replace(/\r?\n/g, "");
      let name = ""+this.editNameFileInModal;
      this.$emit('change-name', [this.activeFile.id, name])
      this.editNameModeInModal = false;
    },
    editNameInModal() {
      this.editNameModeInModal = true;
    },
    zoomSetDefault() {
      this.zoomFile = 100;
    },
    zoomOut() {
      if(this.zoomFile !== 10 && !this.formatThisVideo && !this.formatIframe) {
        this.zoomFile-=10
      }
    },
    zoomIn() {
      if(this.zoomFile !== 400 && !this.formatThisVideo && !this.formatIframe) {
        this.zoomFile+=10
      }
    },
    deleteFileInModal() {
      this.$emit('delete-file', this.activeFile.id)
      this.hideViewFileModal()
    },
    hideViewFileModal() {
      this.zoomFile = 100;
      this.showViewFileIndex = null;
      this.showViewFiles = false
      this.activeFile = null

    },
    showViewFileModal(index) {
      this.keyRenderIFRAME++
      this.formatThisImg = false;
      this.formatThisVideo = false;
      this.formatIframe = false;

      this.activeFile = this.files[index]

      this.editNameFileInModal = this.activeFile.name ? this.activeFile.name : this.activeFile.file
      let type = this.activeFile.file.substring(this.activeFile.file.lastIndexOf("."));
      if(this.formatIMG.indexOf(type.toLowerCase()) !== -1) {
        this.formatThisImg = true;
      } else if(this.formatVIDEO.indexOf(type.toLowerCase()) !== -1) {
        this.formatThisVideo = true;
      } else if(this.formatIFRAME.indexOf(type.toLowerCase()) !== -1){
        this.formatIframe = true;

        this.isLoadIframe = true;
        let iframe = this.$refs.iframe
        let vm = this;
        iframe.src=''
        iframe.src = this.getlinkPDF(this.activeFile ? this.activeFile.file : '');
        console.log('iframe', iframe.src)

        if (navigator.userAgent.indexOf("MSIE") > -1 && !window.opera) {
          iframe.onreadystatechange = function(){
            if (iframe.readyState == "complete"){
              // alert("Iframe is now loaded.");
              vm.isLoadIframe = false;
            }
          };
        } else {
          iframe.onload = function(){
            // alert("Iframe is now loaded.");
            vm.isLoadIframe = false;
          };
        }
        setTimeout(()=> {
          this.isLoadIframe = false
        }, 2500)
      }
      
      this.showViewFileIndex = index
      this.showViewFiles = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .view-file {
    .container {
      padding: 0;
    }
    display: flex;
    justify-content: center;
    z-index: 300;
    width: 100vw;
    height: calc(100vh - 56px);
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(44,44,44,0.95);
    &__top-panel {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 61px;
    }
    .edit-file-name {
      &__button {
        display: flex;
        align-items: center;
        color: var(--color-card);
        transition: .1s linear;
        svg {
          margin-left: 8px;
          path {
            fill: var(--color-card);
            transition: .1s linear;
          }
        }
        &:hover {
          color: #319FEF;
          svg {
            path {
              fill: #319FEF;
              transition: .1s linear;
            }
          }
        }
      }
      &__block {
        display: flex;
        width: max-content;
        input {
          background: #2C2C2C;
          border: 1px solid #4B4B4B;
          &:hover {
            border: 1px solid var(--color-card);
          }
          &:focus {
            border: 1px solid #319FEF;
          }
          height: 32px;
          width: 254px;
          border-radius: 4px 0 0 4px !important;
        }
        button {
          box-sizing: border-box;
          background: #2C2C2C;
          border: 1px solid #4B4B4B;
          width: 32px !important;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            path {
              fill: var(--color-card);
              transition: .1s linear;
            }
          }
          transition: .1s linear;
          &:hover {
            border: 1px solid var(--color-card);
          }
          &:active {
            transition: 0s;
            border: 1px solid #319FEF;
            svg {
              path {
                transition: 0s;
                fill: #319FEF;
              }
            }
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }
    .block-scale-button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      &__block {
        display: flex;
        align-items: center;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #4B4B4B;
          width: 32px;
          height: 32px;
          box-sizing: border-box;
          background-color: #2C2C2C;
          svg {
            path {
              fill: var(--color-card);
              transition: .1s linear;
            }
          }
          transition: .1s linear;
          &:hover {
            border: 1px solid var(--color-card);
          }
          &:active {
            transition: 0s;
            border: 1px solid #319FEF;
            svg {
              path {
                transition: 0s;
                fill: #319FEF;
              }
            }
          }
          &:nth-child(1) {
            border-radius: 4px 0 0 4px;
          }
          &:nth-child(3) {
            border-radius: 0px 4px 4px 0;
          }
        }
        p {
          box-sizing: border-box;
          border-top: 1px solid #4B4B4B;
          border-bottom: 1px solid #4B4B4B;
          background-color: #2C2C2C;
          height: 32px;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      display: flex;
      align-items: center;
      &>button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 24px;
        svg {
          path {
            //fill: #FFFFFF;
            transition: .1s linear;
          }
        }
        transition: .1s linear;
        &:hover {
          svg {
            path {
              fill: #319FEF;
            }
          }
        }
      }
      //padding-left: 48px;
    }
    .block-option-button {
      display: flex;
      align-items: center;
      button {
        svg {
          path {
            //fill: #FFFFFF;
            transition: .1s linear;
          }
        }
        transition: .1s linear;
        &:hover {
          svg {
            path {
              fill: #319FEF;
            }
          }
        }
        &:active {
          transition: 0s;
          svg {
            path {
              transition: 0s;
              fill: #319FEF;
            }
          }
        }
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
      }
      &__delete {
        margin-left: 24px;
      }
      &__close {
        margin-left: 64px;
      }
    }
    &__main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: calc(100vh - 61px);
      padding-bottom: 61px;
      box-sizing: border-box;
      .block-with-file {
        overflow: auto;
        width: calc(100% - (24px * 4));
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        img {
          //transform: scale(2);
          max-width: 100%;
          max-height: 100%;
          box-sizing: border-box;

        }
        video {
          width: 100%;
          height: 100%;
        }
        p {
          width: 287px;
          text-align: center;
          line-height: 24px;
        }
      }
      &>button {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          path {
            //fill: #FFFFFF;
            transition: .1s linear;
          }
        }
        transition: .1s linear;
        &:active {
          transition: 0s;
          svg {
            path {
              transition: 0s;
              fill: #319FEF;
            }
          }
        }
      }
    }
  }
  .formatTxt {
    background-color: var(--color-white);
    body {
      background-color: var(--color-white);
      color: var(--color-dark);
      pre {
        color: var(--color-dark);
      }
    }
  }
  /deep/ {
    .wrap-loader-local {
      background-color: rgba(44,44,44,0.95);
    }
  }
</style>