<template>
  <div class="fixed top-0 left-0 h-full w-full z-1000">
    <div @click="$emit('on-close')" class="absolute top-0 left-0 h-full w-full" style="background-color: rgba(44, 44, 44, 0.15);"><!-- bg --></div>
    <div class="absolute top-0 right-0 w-full max-w-320 h-full bg-white border border-gray-light box-border overflow-y-auto">
      <div class="">
        <div class="flex items-center p-x-4 p-y-3 border-b border-gray-light">
          <svg class="svg-dart" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H18.3392C18.7093 7.05017 19 6.75585 19 6.38127C19 6.00669 18.7093 5.71237 18.3392 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#2C2C2C"/>
            <path d="M14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6204 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3361 15.326 9.61374 14.2423 9.61374ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#2C2C2C"/>
            <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5185 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8028 8.42731 20.5252 9.48458 20.5252C10.5683 20.5252 11.467 19.8028 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5185 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5553 10.5154 18.144C10.5154 18.7326 10.0396 19.1874 9.48458 19.1874C8.92951 19.1874 8.45374 18.7326 8.45374 18.144C8.48018 17.5553 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
          </svg>

          <div class="m-l-3 m-r-auto fw500 fz14">Filter</div>

          <div class="flex items-center p-x-2 p-y-0.5 fz12 text-white bg-blue rounded-full cursor-pointer" v-if="filtersCounter" @click="clearAll">
            {{filtersCounter}}
            <svg class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.05373 3.92533C2.81022 3.68421 2.81022 3.29329 3.05373 3.05217C3.29724 2.81106 3.69206 2.81106 3.93557 3.05217L8.94574 7.97332C9.18925 8.21444 9.18925 8.60536 8.94574 8.84647C8.70223 9.08759 8.30741 9.08759 8.0639 8.84647L3.05373 3.92533Z" fill="white"/><path d="M8.06398 3.15356C8.3075 2.91245 8.70231 2.91245 8.94582 3.15356C9.18933 3.39468 9.18933 3.7856 8.94582 4.02672L3.93565 8.94786C3.69214 9.18898 3.29732 9.18898 3.05381 8.94786C2.8103 8.70675 2.8103 8.31582 3.05381 8.07471L8.06398 3.15356Z" fill="white"/></svg>
          </div>
        </div>

        <div class="p-x-4 p-b-4 border-b border-gray-light">
          <div class="flex items-center p-y-3">
            <div class="m-r-auto fw500 fz14">Max Draft</div>
            <div 
              class="fz12 text-blue cursor-pointer" 
              v-if="filters.draft.min || filters.draft.max"
              @click="filters.draft.min = '', filters.draft.max = ''"
            >Clear</div>
          </div>

          <div class="input-from-to">
            <input type="number" placeholder="From" v-model.number="filters.draft.min">
            <input type="number" placeholder="To" v-model.number="filters.draft.max">
          </div>
        </div>
        
        <div class="p-x-4 p-b-4 border-b border-gray-light">
          <div class="flex items-center p-y-3">
            <div class="m-r-auto fw500 fz14">Max LOA</div>
            <div 
              class="fz12 text-blue cursor-pointer"
              v-if="filters.loa.min || filters.loa.max"
              @click="filters.loa.min = '', filters.loa.max = ''"
            >Clear</div>
          </div>

          <div class="input-from-to">
            <input type="number" placeholder="From" v-model.number="filters.loa.min">
            <input type="number" placeholder="To" v-model.number="filters.loa.max">
          </div>
        </div>
        
        <div class="p-x-4 p-b-4 border-b border-gray-light">
          <div class="flex items-center p-y-3">
            <div class="m-r-auto fw500 fz14">Max Beam</div>
            <div 
              class="fz12 text-blue cursor-pointer"
              v-if="filters.beam.min || filters.beam.max"
              @click="filters.beam.min = '', filters.beam.max = ''"
            >Clear</div>
          </div>

          <div class="input-from-to">
            <input type="number" placeholder="From" v-model.number="filters.beam.min">
            <input type="number" placeholder="To" v-model.number="filters.beam.max">
          </div>
        </div>
        
        <div class="p-x-4 p-b-4 border-b border-gray-light">
          <div class="flex items-center p-t-3 m-b-3">
            <div class="m-r-auto fw500 fz14">Water Density</div>
            <div 
              class="fz12 text-blue cursor-pointer"
              v-if="filters.selectWater.length"
              @click="filters.selectWater = []"
            >Clear</div>
          </div>

          <b-form-checkbox
            class="m-t-3"
            v-for="type in getWater"
            v-model="filters.selectWater"
            :key="type.id"
            :value="type"
          >
            <div class="fz13 text-dark">{{ type.name }}</div>
          </b-form-checkbox>
        </div>

        <div class="p-x-4 p-b-4 border-b border-gray-light">
          <div class="flex items-center p-t-3 m-b-3">
            <div class="m-r-auto fw500 fz14">Bunkering</div>
            <div 
              class="fz12 text-blue cursor-pointer"
              v-if="filters.selectBunkering.length"
              @click="filters.selectBunkering = []"
            >Clear</div>
          </div>

          <b-form-checkbox
            class="m-t-3"
            v-for="type in bunkeringList"
            v-model="filters.selectBunkering"
            :key="type.id"
            :value="type"
          >
            <div class="fz13 text-dark">{{ type.name }}</div>
          </b-form-checkbox>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    filters: Object
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      waterList: state => state.ports.waterDensityList,
      bunkeringList: state => state.ports.bunkeringList,
    }),
    getWater() {
      return this.waterList.filter(el => {
        return el.id != 'null'
      })
    },
    filtersCounter() {
      let count = 0
      if(this.filters.draft.min || this.filters.draft.max) count++
      if(this.filters.loa.min || this.filters.loa.max) count++
      if(this.filters.beam.min || this.filters.beam.max) count++
      count += this.filters.selectBunkering.length
      count += this.filters.selectWater.length
      return count
    },
  },
  methods: {
    clearAll() {
      this.filters.draft.min = ''
      this.filters.draft.max = ''
      this.filters.loa.min = ''
      this.filters.loa.max = ''
      this.filters.beam.min = ''
      this.filters.beam.max = ''
      this.filters.selectBunkering = []
      this.filters.selectWater = []
    },
  }
}
</script>

<style scoped lang="scss">
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/checkbox";
</style>

<style lang="scss" scoped>
.input-from-to{
  display: flex;
  input{
    width: 50%;
    padding-top: 7px;
    padding-bottom: 7px;
    line-height: 16px;
    &:first-child{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>