<template>
  <div class="wrap-page">
<!--    <payment-modal-duplicate-or-edit-payment ref="modalDupOrEd" @needRender="renderList"></payment-modal-duplicate-or-edit-payment>-->
<!--    <payment-modal-create ref="modalCreate" @needRender="renderList"></payment-modal-create>-->
    <payment-modal-delete @render="renderList" ref="modalDelete"></payment-modal-delete>
    <div class="top-panel">
      <p class="fz20 fw500 text-dark">Payments <span class="text-gray">({{getTotalPaymentsCounterparties}})</span></p>
      <p class="fz14 fw500 text-dark">Total: <span class="text-blue-dark">{{balance | formatNumberTwo}} USD</span></p>
      <div class="block">
        <dropdown-choosed-status-for-payment-archive @changeStatusSelected="changeStatusSelected" class="choosedStatus" v-if="false"></dropdown-choosed-status-for-payment-archive>
<!--        <dropdown-sort-by-payment-archive @changeSort="changeSort" class="sortPayment"></dropdown-sort-by-payment-archive>-->
<!--        <input type="text" class="search h32" @focusout="searchOut" placeholder="Search" v-model="searchText">-->
      </div>
    </div>
    <div class="block-scroll-x">
      <payment-list-component v-if="getShowPaymentList()" ref="paymentList" v-click-outside="focusFalse" @changeAmount="refreshBalance" @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" class="payment-list" @clickDelete="clickDelete" @clickCreate="showModalCreate" @clickEditPaymentCard="clickEditPaymentCard" @clickDuplicatePaymentCard="clickDuplicatePaymentCard"></payment-list-component>
      <virtual-payment-list @changeOrdering="changeOrdering" @scrollBottomEnd="(getNextLinkPaymentForCounterparty || getNextLinkPaymentForCounterparty1) && getPaymentListOriginal.length !== 0 && !loadActive ? loadMore() : null" v-else @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" ref="paymentList"></virtual-payment-list>
    </div>
    <loader-for-autoload v-if="loadActive && (getNextLinkPaymentForCounterparty || getNextLinkPaymentForCounterparty1) && getPaymentListOriginal.length !== 0"></loader-for-autoload>
<!--    <pagination-default class="show-more" v-if="getPaymentListOriginal.length >= 1 && (getNextLinkPaymentForCounterparty || getNextLinkPaymentForCounterparty1)" :if-active="loadActive" @load-more="loadMore"></pagination-default>-->
    <div class="pb55" v-else></div>
    <payment-gross-net></payment-gross-net>
  </div>
</template>

<script>
import DropdownChoosedStatusForPaymentArchive from "@/components/archivePayment/dropdownChoosedStatusForPaymentArchive";
import PaymentListComponent from "@/components/payment/payment-list/payment-list-component";
import PaymentModalDelete from "@/components/payment/payment-modal-delete";
import {mapActions, mapGetters, mapMutations} from "vuex";
import PaymentGrossNet from "@/components/payment/payment-gross-net";
import VirtualPaymentList from "@/components/payment/payment-list/virtual-payment-list";
import LoaderForAutoload from "@/components/loader-for-autoload";
export default {
  components: {
    LoaderForAutoload,
    VirtualPaymentList,
    PaymentGrossNet,
    PaymentModalDelete,
    PaymentListComponent,
    DropdownChoosedStatusForPaymentArchive, 

  },
  data() {
    return {
      orderingName: '',
      showLoader: false,
      status_ordering: true,
      due_date_ordering_status: true,
      searchText: "",
      filterStatus: {
        paymentCheckbox: [],
        incomeCheckbox: [],
        searchText: "",
        sortText: "",
        is_archived: false,
      },
      searchTextCopy: "",
      loadActive: false,
      limit: 12,
      countOffset: 0,
      next: true,
      balance: 0,
    }
  },
  computed: {
    ...mapGetters(['getTotalPaymentsCounterparties','getPermissionsByType', "getPaymentListOriginal", "getNextPayment", "getNextLinkPaymentForCounterparty", "getNextLinkPaymentForCounterparty1"]),
  },
  methods: {
    ...mapActions(["getPaymentBalanceCounterparty", "axiosNextPaymentForCounterparties", "axiosGetPaymentArchive", "axiosGetPaymentNext", "axiosGetPaymentsForCounterparty"]),
    ...mapMutations(["setTotalPaymentsCounterparties", "setFilterDataPaymentAxios", "cancelPostPayment1", "cancelPostPayment"]),
    getShowPaymentList() {
      return false
    },
    focusFalse() {
      this.$refs.paymentList.focusFalse()
    },
    refreshBalance() {
      let textRoute = this.$route.path;
      let id = textRoute.replace(/[^0-9]/gim,'');
      setTimeout(()=> this.getPaymentBalanceCounterparty({client: id}).then(res=> {
        this.balance = res.income - res.expense
      }), 600)
    },
    changeOrderingStatus(data) {
      this.status_ordering = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrderingDueDate(data) {
      this.due_date_ordering_status = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrdering(ordering) {
      this.orderingName = ordering
      this.axiosChangeGetList();
    },
    clickDelete(id) {
      this.$refs.modalDelete.openModal(id);
    },
    async loadMore() {
      this.loadActive = true;

      await this.axiosNextPaymentForCounterparties({link1: this.getNextLinkPaymentForCounterparty, link2: this.getNextLinkPaymentForCounterparty1})
      this.loadActive = false;
    },
    showModalCreate() {
      this.$refs.modalCreate.openModal();
      this.keyRender++;
    },
    clickDuplicatePaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "duplicate");
    },
    changeSort(text) {
      if(this.filterStatus.sortText !== text) {
        this.filterStatus.sortText = text;
        this.emitChangeDate();
      }
    },
    changeStatusSelected(data) {
      if(data.paymentCheckbox !== this.filterStatus.paymentCheckbox || data.incomeCheckbox !== this.filterStatus.incomeCheckbox) {
        this.filterStatus.paymentCheckbox = data.paymentCheckbox;
        this.filterStatus.incomeCheckbox = data.incomeCheckbox;
        this.emitChangeDate();
      }
    },
    searchOut() {
      if(this.searchText !== this.searchTextCopy) {
        this.searchTextCopy = this.searchText
        this.filterStatus.searchText = this.searchText;
        this.emitChangeDate();
      }
    },
    clickEditPaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "edit");
    },
    renderList() {
      this.emitChangeDate();
    },
    emitChangeDate() {
      this.cancelPostPayment();
      this.cancelPostPayment1();
      this.axiosChangeGetList();
    },
    async axiosChangeGetList() {
      let before = "";
      let after = "";
      let textRoute = this.$route.path;
      let id = textRoute.replace(/[^0-9]/gim,'');
      this.getPaymentBalanceCounterparty({client: id}).then(res=> {
        this.balance = res.income - res.expense
      })

      let orderExecuteDate = ''
      if(this.status_ordering) {
        orderExecuteDate += '-status'
      } else {
        orderExecuteDate += 'status'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-paid_date'
      } else {
        orderExecuteDate += ',paid_date'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-execute_date'
      } else {
        orderExecuteDate += ',execute_date'
      }
      orderExecuteDate = this.orderingName ? this.orderingName : orderExecuteDate
      orderExecuteDate += ',id'
      this.loadActive = true;
      this.setFilterDataPaymentAxios({ordering: orderExecuteDate, before: before,after: after, filter: this.filterStatus, counterparty: id, data_type: 'payment'})
      await this.axiosGetPaymentsForCounterparty({ordering: orderExecuteDate, before: before,after: after, filter: this.filterStatus, counterparty: id, data_type: 'payment'}).then(()=> {
        this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)

      })
      this.loadActive = false;
    },
  },
  beforeMount() {
    this.setTotalPaymentsCounterparties(0);
    if(!this.getPermissionsByType('show_counterparties_payments')){
      this.$router.replace({
        name: 'Counterparties payment accounts', 
        params: {id: this.$route.params.id}
      })
    } else {
      this.renderList()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "counterpartiesPayments";
  .wrap-page {
    height: calc(100vh - 56px - 55px);
    overflow-y: auto;
  }
</style>