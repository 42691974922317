<template>
  <div>
    <b-form-checkbox
        class="check"
        id="checkbox-1"
        v-model="status"
        name="checkbox-1"
        @change="selectAll"
        v-if="title !== null && title !== undefined && title.length !== 0"
    >
      <p class="fz12 fw500 text-gray">{{title}}</p>
    </b-form-checkbox>
    <b-form-checkbox
        class="check"
        v-model="selected"
        :value="l"
        name="checkbox-2"
        @change="changeSelected"
        v-for="(l,i) in list"
        :key="i"
    >
      <p class="fz14 text-dark">{{l.name}}</p>
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "checkboxes-for-modal",
  props: ["title", "list"],
  data() {
    return {
      status: false,
      selected: [],
    }
  },
  methods: {
    selectAll() {
      if(this.status) {
        this.selected = JSON.parse(JSON.stringify(this.list));
      } else {
        this.selected.splice(0, this.selected.length);
      }
    },
    changeSelected() {
      if(this.selected.length === this.list.length) {
        this.status = true;
      } else {
        this.status = false;
      }
      this.$emit("emit", this.selected);
    }

  }
}
</script>

<style scoped lang="scss">
  @import "../../../components/style-element/bootstrap";
  @import "../../../components/style-element/checkbox";
  p {
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
  }
  .fz12 {
    position: relative;
    top: 1px;
  }
  .check {
    margin-top: 12px;
  }
</style>