<template>
  <div class="container-grid border-round border-color-normal">
    <div class="checkbox box-border p0 d-flex align-items-center justify-center border-color-normal border-right">
      <svg class="cursor-pointer" v-on:click="$emit('select')" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!selectedAll">
        <rect width="16" height="16" rx="4" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.55556 1.24444C2.27916 1.24444 1.24444 2.27916 1.24444 3.55556V12.4444C1.24444 13.7208 2.27916 14.7556 3.55556 14.7556H12.4444C13.7208 14.7556 14.7556 13.7208 14.7556 12.4444V3.55556C14.7556 2.27916 13.7208 1.24444 12.4444 1.24444H3.55556ZM0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#9AA3B0"/>
      </svg>
      <svg class="cursor-pointer" v-on:click="$emit('select')" v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="#094172"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.1499 7.99844C4.1499 7.529 4.53046 7.14844 4.9999 7.14844H10.9999C11.4693 7.14844 11.8499 7.529 11.8499 7.99844C11.8499 8.46788 11.4693 8.84844 10.9999 8.84844H4.9999C4.53046 8.84844 4.1499 8.46788 4.1499 7.99844Z" fill="white"/>
      </svg>

    </div>
    <div class="status box-border p6 d-flex align-items-center justify-center fz13 text-dark fw500 border-right border-color-normal">
      Mail
    </div>
    <div class="name box-border text-align-left fz13 text-dark fw500 border-right border-color-normal">Name</div>
    <div class="dwt box-border text-align-left fz13 text-dark fw500 border-right border-color-normal">DWT</div>
    <div class="built box-border text-align-left fz13 text-dark fw500 border-right border-color-normal">Built</div>
    <div class="position box-border text-align-left fz13 text-dark fw500 border-right border-color-normal">Position</div>
    <div class="zone box-border text-align-left fz13 text-dark fw500 border-right border-color-normal">Zone</div>
    <div class="date box-border text-align-left fz13 text-dark fw500 border-right border-color-normal">Date</div>
    <div class="update box-border text-align-right fz13 text-dark fw500">Update</div>
  </div>
</template>

<script>
export default {
  name: "location-list-title",
  props: {
    selectedList: {
      type: Array,
    },
    selectedAll: Boolean,
  }
}
</script>

<style scoped lang="scss">
.container-grid {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 28px 38px 1fr 118px 78px 188px 178px 180px 264px;
  grid-template-rows: 28px;
  background-color: var(--color-head-table);
  gap: 0px 0px;
  grid-template-areas:
    "checkbox status name dwt built position zone date update";
  &>* {
    padding: 6px 12px 6px 8px;
  }
}
.checkbox { grid-area: checkbox; }
.status { grid-area: status; }
.name { grid-area: name; }
.dwt { grid-area: dwt; }
.built { grid-area: built; }
.position { grid-area: position; }
.zone { grid-area: zone; }
.date { grid-area: date; }
.update { grid-area: update; }

.p0 {
  padding: 0 !important;
}
.d-flex {
  display: flex !important;
}
</style>