var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-full"},[_c('div',{staticClass:"relative flex border-b border-gray-dark p-t-px entries_elems"},[_c('input-format-number',{staticClass:"w-full",attrs:{"inputClass":"p-y-2 border-0 fw700 entries_outline h33","placeholder":"0.00","fractionDigits":2,"isRoundOutput":false,"boldText":true},model:{value:(_vm.localNumb),callback:function ($$v) {_vm.localNumb=$$v},expression:"localNumb"}}),_c('div',{staticClass:"absolute top-2 right-2 flex items-center fz14 cursor-pointer svg-white-dart",on:{"click":function($event){return _vm.$refs.dropCurrency.openList()}}},[_vm._v(" "+_vm._s(_vm.item[_vm.column].currency)+" "),_c('svg',{staticClass:"m-l-0.5",attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2.72443 4.39844C2.45716 4.39844 2.32331 4.72158 2.5123 4.91057L5.78804 8.18631C5.9052 8.30346 6.09515 8.30346 6.21231 8.18631L9.48803 4.91057C9.67702 4.72158 9.54317 4.39844 9.2759 4.39844H2.72443Z","fill":"#2C2C2C"}})])]),_c('counterparties-drop',{ref:"dropCurrency",staticClass:"hide_input vessel_owners__select_currency",attrs:{"list":_vm.valutaList,"isDisabled":!_vm.item.is_manual && !_vm.getIsPayment || _vm.$route.meta.isArchive,"removeSelectItemBeforeClick":false,"dontSelect":true,"activeItem":_vm.activeCurrency,"title":"Select currency"},on:{"select":_vm.selectedCurrency}})],1),_c('div',{staticClass:"flex border-b border-gray-dark",class:{
      'h-full': !_vm.item.has_account && !_vm.menu.includes('account') && !_vm.menu.includes('linked') && !_vm.menu.includes('statement')
    }},[_c('div',{staticClass:"flex w-full border-r border-gray-dark entries_elems"},[_c('async-select',{staticClass:"w-full",attrs:{"id":'client_client-'+_vm.item.id,"placeholder":"Choose Counterparty","url":"/client/dropdown","params":{is_archived: false},"watchKey":"company_name","openDirection":'',"disabled":!_vm.item.is_manual && !_vm.getIsPayment || _vm.$route.meta.isArchive,"closeOnSelect":true,"allowEmpty":true},on:{"input":_vm.onSelectClient},model:{value:(_vm.item[_vm.column].client),callback:function ($$v) {_vm.$set(_vm.item[_vm.column], "client", $$v)},expression:"item[column].client"}})],1),_c('div',{staticClass:"flex w-7 flex-shrink-0"},[(_vm.item[_vm.column].client)?_c('router-link',{staticClass:"flex h-full w-full",attrs:{"to":_vm.getUrl({id: _vm.item[_vm.column].client.id, model: 'Client'}),"target":"_blank"}},[_c('entries-arrow',{staticClass:"m-auto"})],1):_vm._e()],1)]),(_vm.menu.includes('statement'))?_c('div',{staticClass:"flex border-b border-gray-dark entries_elems",class:{
      'h-full': !_vm.item.has_account && !_vm.menu.includes('account') && !_vm.menu.includes('linked')
    }},[_c('div',{staticClass:"w-7/12 border-r border-gray-dark"},[_c('async-select',{attrs:{"id":'client_account-'+_vm.item.id,"placeholder":"Choose Account","url":"/payment/account/","params":{is_archive: false},"watchKey":"name","beforeWatchKey":'account_id',"openDirection":'',"disabled":!_vm.item.is_manual && !_vm.getIsPayment || _vm.$route.meta.isArchive,"closeOnSelect":true,"allowEmpty":true,"isMultiple":true},on:{"on-select":function($event){return _vm.$emit('on-update', false)}},model:{value:(_vm.item[_vm.column].account),callback:function ($$v) {_vm.$set(_vm.item[_vm.column], "account", $$v)},expression:"item[column].account"}})],1),_c('div',{staticClass:"w-5/12 border-r border-gray-dark"},[_c('default-select',{attrs:{"id":'stat_member'+_vm.item.id+_vm.column,"placeholder":"Stat. Memb.","options":_vm.getCpDate,"closeOnSelect":true,"allowEmpty":true,"disabled":!_vm.item.is_manual && !_vm.getIsPayment || _vm.$route.meta.isArchive,"trackBy":""},on:{"on-select":_vm.onSelectCpDate},model:{value:(_vm.activeSM),callback:function ($$v) {_vm.activeSM=$$v},expression:"activeSM"}})],1),_c('div',{staticClass:"flex w-7 flex-shrink-0"},[(_vm.item[_vm.column].account && _vm.item[_vm.column].account.length)?_c('router-link',{staticClass:"flex h-full w-full",attrs:{"to":{path: '', query: {account: _vm.item[_vm.column].account.map(function (el){ return el.id; })}},"target":"_blank"}},[_c('entries-arrow',{staticClass:"m-auto"})],1):_vm._e()],1)]):_vm._e(),(_vm.menu.includes('linked'))?_c('div',{staticClass:"flex p-x-2 p-y-1",class:{
    'border-b border-gray-dark': _vm.item.has_account && _vm.menu.includes('account'),
    'h-full': !_vm.item.has_account && !_vm.menu.includes('account')
  }},[_c('linked-wrap',{staticClass:"w-full",attrs:{"isEntries":true,"disabled":_vm.$route.meta.isArchive,"linkedPayments":_vm.item[_vm.column].linked_payments,"linkedInvoices":_vm.item[_vm.column].linked_invoices,"linkedOwners":_vm.item[_vm.column].linked_owners,"linkedType":_vm.item.operation_type == 'payment' || _vm.item.operation_type == 'income' ? 'Payments' : 'Invoices'},on:{"on-change":_vm.onChangeLinkeds}})],1):_vm._e(),(_vm.item.has_account && _vm.menu.includes('account'))?_c('div',{staticClass:"flex h-full entries_elems"},[_c('div',{staticClass:"w-7/12 border-r border-gray-dark"},[_c('async-select',{attrs:{"id":'client_account-'+_vm.item.id,"placeholder":"Choose Real Account","url":"/client/dropdown","params":{is_archived: false},"watchKey":"company_name","openDirection":'',"disabled":!_vm.item.is_manual && !_vm.getIsPayment || _vm.$route.meta.isArchive,"closeOnSelect":true,"allowEmpty":true},on:{"on-select":function($event){return _vm.$emit('on-update', false)}},model:{value:(_vm.item[_vm.column].real_account),callback:function ($$v) {_vm.$set(_vm.item[_vm.column], "real_account", $$v)},expression:"item[column].real_account"}})],1),_c('div',{staticClass:"w-5/12 border-r border-gray-dark"},[_c('default-select',{attrs:{"id":'statement_members'+_vm.item.id+_vm.column,"placeholder":"Stat. Memb.","options":_vm.getRealCpDate,"closeOnSelect":true,"isMultiple":true,"searchable":false,"allowEmpty":true,"isDisabled":!_vm.item.is_manual && !_vm.getIsPayment || _vm.$route.meta.isArchive,"trackBy":""},on:{"on-select":_vm.onSelectRealCpDate},model:{value:(_vm.activeRealSM),callback:function ($$v) {_vm.activeRealSM=$$v},expression:"activeRealSM"}})],1),_c('div',{staticClass:"flex w-7 flex-shrink-0"},[(_vm.item[_vm.column].real_account)?_c('router-link',{staticClass:"flex h-full w-full",attrs:{"to":_vm.getUrl({id: _vm.item[_vm.column].real_account.id, model: 'Client'}),"target":"_blank"}},[_c('entries-arrow',{staticClass:"m-auto"})],1):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }