<template>
  <div class="flex flex-col overflow-hidden h-full">
    <bread 
      class="bg-white"
      :allowOptions="false"
      :links="bread"
    />

    <div class="flex items-center p-x-6 border-b border-gray-light bg-white">
      <page-navigation :links="navigations"/>
    </div>

    <div class="h-full overflow-hidden">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import bread from '@/components/reuse/bread'
import pageNavigation from '@/components/reuse/pageNavigation'

export default {
  components: {
    bread,
    pageNavigation
  },
  data() {
    return {
      bread: [
        {name: 'Ports database', link: '/ports'},
        {name: 'Port', link: `/ports/${this.$route.params.id}`},
        {name: 'Archive of Port', link: `/ports/${this.$route.params.id}/archive`},
      ],
      navigations: [
        {name: 'Archive Berth List', link: 'berth'},
        {name: 'Archive Contacts', link: 'contacts'}
      ],
    }
  },
  computed: {
    ...mapState({
      port: state => state.ports.openPort
    })
  },
  methods: {
    ...mapActions(['axiosGetPortById']),
    ...mapMutations(['setOpenPort', 'showLoad', 'hideLoad'])
  },
  async beforeMount() {
    this.setOpenPort(null)
    this.showLoad()
    await this.axiosGetPortById(this.$route.params.id)
    this.bread[1].name = this.port.name
    this.bread[2].name = `Archive of ${this.port.name} Port`
    this.hideLoad()
  },
}
</script>

<style>

</style>