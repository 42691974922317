<template>
  <div class="m-t-4 border border-dark-gray rounded-lg">
    <div 
      class="vessel_owners__table_wide bg-blue-dark rounded-t-lg"
      :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <div class="border-r p-x-2 p-y-2 text-white fz13 fw500">REMITTANCES DONE</div>
      <div class="border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials :isWhite="true" v-if="$route.params.subId" trialKey="SUBLET_Remittances Done"/>
        <get-trials :isWhite="true" v-else trialKey="Remittances Done"/>
      </div>
      <div class="colspan-3-9"></div>
    </div>
    
    <div
      class="vessel_owners__table_wide bg-white rounded-b-lg"
      :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }"
      v-if="!additional.remittances.length"
    >
      <div class="border-r p-x-2 p-y-2 text-dark"></div>
      <div class="border-r p-x-2 p-y-2 text-dark" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
      <div class="border-r p-x-2 p-y-2 text-dark colspan-3-5">The list of remittances done is empty</div>
      <div class="border-r p-x-2 p-y-2 text-dark"></div>
      <div class="border-r p-x-2 p-y-2 text-dark"></div>
      <div class="border-r p-x-2 p-y-2 text-dark"></div>
      <div class=""></div>
    </div>

    <div
      class="vessel_owners__table_wide bg-white"
      :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations'),
        'rounded-b-lg': i == sortedByDate.length-1
      }"
      v-for="(hire, i) in sortedByDate" 
      :key="i"
    >
      <div 
        class="border-r p-x-2 p-y-2 relative"
        :class="{
          'border-b': i != additional.remittances.length - 1,
          'rounded-bl-lg': i == additional.remittances.length - 1,
          'bg-gray-light text-gray-text': !hire.is_row
        }" 
      >
        Hire #{{i+1}}
        <switcher 
          class="switcher_owners-row"
          :id="'hire'+i"
          v-model="hire.is_row"
        />
      </div>
      <div 
        class="border-r p-x-2 p-y-2" v-if="getPermissionsByType('show_accounts_in_all_operations')"
        :class="{
          'border-b': i != additional.remittances.length - 1,
          'bg-gray-light text-gray-text': !hire.is_row
        }"
      ></div>
      <div 
        class="border-r p-x-2 p-y-2 colspan-3-5"
        :class="{
          'border-b': i != additional.remittances.length - 1,
          'bg-gray-light text-gray-text': !hire.is_row
        }"
      >{{hire.date}}</div>
      <div 
        class="border-r"
        :class="{
          'border-b': i != additional.remittances.length - 1,
          'bg-gray-light text-gray-text': !hire.is_row
        }"
      >
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="hire.debit != null"
        >{{hire.debit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div 
        class="border-r"
        :class="{
          'border-b': i != additional.remittances.length - 1,
          'bg-gray-light text-gray-text': !hire.is_row
        }"
      >
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="hire.credit != null"
        >{{hire.credit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div 
        class="border-r"
        :class="{
          'border-b': i != additional.remittances.length - 1,
          'bg-gray-light text-gray-text': !hire.is_row
        }"
      ></div>
      <div 
        class=""
        :class="{
          'border-b': i != additional.remittances.length - 1,
          'rounded-br-lg': i == additional.remittances.length - 1,
          'bg-gray-light text-gray-text': !hire.is_row
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import getTrials from '@/components/vessels/owners/edit/getTrials'
import Switcher from '@/components/reuse/switcher.vue'

export default {
  props: {
    additional: Object
  },
  components: {
    getTrials,
    Switcher
  },
  watch: {
    /* getFilteredPayments: {
      deep: true,
      handler() {
        this.setPayments()
      }
    } */
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract,
      sublet: state => state.contract.activeSubcontract,
    }),
    ...mapGetters(['getPaymentListOriginal', 'getPermissionsByType']),
    getStopCheckStatementMember() {
      let owners = []
      owners.push({id: 'main', owner: this.contract.owner ? this.contract.owner.id : ''})
      this.contract.cargo_contracts.forEach(cargo=> {
        owners.push({id: cargo.id, owner: cargo.owner ? cargo.owner.id : ''})
      })
      return this.sublet.owner ? owners.filter(a=> a.owner == this.sublet.owner.id).length == 1 : false
      // return this.sublet.owner ? owners.findIndex(a=> a.id == this.sublet.id) == owners.findIndex(a=> a.owner == this.sublet.owner.id) : false
    },
    getFilteredPayments() {
      if(!this.getPaymentListOriginal.length) return []
      let subId = this.$route.params.subId
      let clientId = subId ? this.sublet?.owner?.id : this.contract?.owner?.id
      let filtered = this.getPaymentListOriginal.filter(el => {
        return this.$route.path.indexOf('open/charterers') === -1 ? (el.client?.id == clientId && el.paid_date && !el.is_archive && el.status == 'paid' && !el.statement_member) : (el.client?.id == clientId && el.paid_date && !el.is_archive && el.status == 'paid' && (this.getStopCheckStatementMember || el.statement_member === this.sublet?.cp_date))
      })
      if(subId && this.sublet && this.sublet.id == subId){
        filtered = filtered.filter(el => {
          return this.getStopCheckStatementMember || el.statement_member == this.sublet.cp_date
        })
      }
      return filtered.map(el => {
        let debit = el.payment_type == 'expense' ? el.payment_amount : null
        let credit = el.payment_type == 'expense' ? null : el.payment_amount

        let obj = {
          id: el.id,
          date: moment(el.paid_date).format('DD.MM.YYYY'),
          debit: debit,
          credit: credit,
          is_row: true
        }
        return obj
      })
    },
    sortedByDate() {
      let remittances = [...this.additional.remittances]
      return remittances.sort((a,b)=>new Date(moment(a.date, 'DD.MM.YYYY').format('YYYY-MM-DD'))-new Date(moment(b.date, 'DD.MM.YYYY').format('YYYY-MM-DD')));

    }
  },
  methods: {
    ...mapActions(['axiosGetPayment']),
    setPayments() {
      if(!this.additional.remittances) this.$set(this.additional, 'remittances', [])
      this.additional.remittances = [...this.onCheckIsRow(this.additional.remittances)]
    },
    inputNumb(e, key, i) {
      let result = parseFloat(e.target.value);
      if (!result || result == 0) {
        result = "";
      }
      e.target.value = result
      this.additional.remittances[i][key] = result
    },
    onCheckIsRow(oldRemitances) {
      let result = []
      if(!this.getFilteredPayments.length) return result
      this.getFilteredPayments.forEach(el=>{
        let newHire = {...el}
        let oldHire = oldRemitances.find(item => item.id == el.id)
        if(oldHire && Object.prototype.hasOwnProperty.call(oldHire, 'is_row')){
          newHire.is_row = oldHire.is_row
        }
        result.push({...newHire})
      })
      return result
    }
  },
  async beforeMount() {
    let subId = this.$route.params.subId
    let clientId = subId ? this.sublet?.owner?.id : this.contract?.owner?.id
    let params = {ordering: 'paid_date',ship: this.$route.params.id, type: 'payment', limit: 9999, is_archived: false, filter: {counterparty: [clientId], paymentCheckbox: ['paid'], incomeCheckbox: ['paid']}, sortById: false}
    if(!this.getStopCheckStatementMember) {
      params['filter']['statement_member'] = this.sublet.cp_date
    } else {
      params['filter']['statement_member_empty'] = true
    }
    await this.axiosGetPayment(params)
    this.setPayments()
  }
}
</script>

<style>

</style>