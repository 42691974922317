<template>
  <div :class="{'show-always': showBool}">
    <b-dropdown right ref="dropdown" v-click-outside="hide">
      <template #button-content>
        <button class="button-drop fz13 fw500" v-on:click="show">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.17 7.08012C9.57353 7.08012 9.09 6.59659 9.09 6.00012C9.09 5.40365 9.57353 4.92012 10.17 4.92012C10.7665 4.92012 11.25 5.40365 11.25 6.00012C11.25 6.59659 10.7665 7.08012 10.17 7.08012Z" fill="#2C2C2C"/>
            <ellipse cx="6.00008" cy="6.00017" rx="1.08" ry="1.08" transform="rotate(90 6.00008 6.00017)" fill="#2C2C2C"/>
            <path d="M1.83016 7.08017C1.23369 7.08017 0.750155 6.59663 0.750155 6.00017C0.750155 5.4037 1.23369 4.92017 1.83016 4.92017C2.42662 4.92017 2.91016 5.4037 2.91016 6.00017C2.91016 6.59663 2.42662 7.08017 1.83016 7.08017Z" fill="#2C2C2C"/>
          </svg>

        </button>
      </template>
      <div class="content">
        <button v-on:click="downloadItem(file)" class="fz13 text-dark">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.034 5C13.034 4.6134 12.7206 4.3 12.334 4.3C11.9474 4.3 11.634 4.6134 11.634 5H13.034ZM12.334 14L11.8399 14.4959C12.113 14.768 12.5549 14.768 12.828 14.4959L12.334 14ZM16.828 10.5102C17.1019 10.2373 17.1027 9.79412 16.8298 9.52026C16.5569 9.2464 16.1137 9.24561 15.8399 9.51848L16.828 10.5102ZM8.82804 9.51848C8.55419 9.24561 8.11097 9.2464 7.8381 9.52026C7.56522 9.79412 7.56602 10.2373 7.83987 10.5102L8.82804 9.51848ZM19.7006 16C19.7006 15.6134 19.3872 15.3 19.0006 15.3C18.614 15.3 18.3006 15.6134 18.3006 16H19.7006ZM6.03397 16C6.03397 15.6134 5.72057 15.3 5.33397 15.3C4.94737 15.3 4.63397 15.6134 4.63397 16H6.03397ZM11.634 5V14H13.034V5H11.634ZM12.828 14.4959L16.828 10.5102L15.8399 9.51848L11.8399 13.5041L12.828 14.4959ZM12.828 13.5041L8.82804 9.51848L7.83987 10.5102L11.8399 14.4959L12.828 13.5041ZM17.0006 18.3H7.33397V19.7H17.0006V18.3ZM18.3006 16V17H19.7006V16H18.3006ZM6.03397 17V16H4.63397V17H6.03397ZM7.33397 18.3C6.616 18.3 6.03397 17.718 6.03397 17H4.63397C4.63397 18.4912 5.84281 19.7 7.33397 19.7V18.3ZM17.0006 19.7C18.4918 19.7 19.7006 18.4912 19.7006 17H18.3006C18.3006 17.718 17.7186 18.3 17.0006 18.3V19.7Z" fill="#2C2C2C"/>
          </svg>
          Download
        </button>
        <button class="fz13 text-dark" v-on:click="createEmitEditName" v-if="getPermissionsForUserDeleteOrRenameFile && permEdit !== false">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#2C2C2C"/>
          </svg>
          Edit name
        </button>
        <button class="fz13 text-dark" v-on:click="createEmitDelete" v-if="permDelete !== false">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
          </svg>
          Delete
        </button>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
import axios from 'axios'
import JSZip from 'jszip'
import {mapActions, mapGetters} from "vuex";
export default {
  computed: mapGetters(["getPermissionsByType", "getPermissionsForUserDeleteOrRenameFile"]),
  name: "task-full-info-file-option-button",
  props: ["file", "permEdit", "permDelete"],
  data() {
    return {
      showBool: false,
    }
  },
  methods: {
    ...mapActions(["axiosDownloadFolder"]),
    getPermissionDeleteFile() {
      return this.permDelete;
    },
    download(content, contentType, fileName) {
      let link1 = document.createElement("a");
      link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
      link1.download = fileName;
      link1.click();
    },
    async downloadItem (file) {
      if(file.file_type === 'FOLDER') {
        let id = [];
        id.push(file.id)
        let response = await this.axiosDownloadFolder(id);
        // let content = JSZip.folder(response.data, 'zip')
        JSZip.loadAsync(response.data).then(res=> {
          // fileZip = res;

          res.generateAsync({type:"blob"})
              .then(function(content) {
                // see FileSaver.js
                // (content, "example.zip");
                console.log(content)
                let link1 = document.createElement("a");
                link1.href = window.URL.createObjectURL(content);
                link1.download = file.name;
                link1.click();
              });
        })


        // this.download( response.data, 'application/zip', file.name)
      } else {
        console.log(file)
        axios.get(file.file, { responseType: 'blob' })
            .then(response => {
              console.log(response)
              const blob = new Blob([response.data], { type: 'application/plain' })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = file.name;
              link.click()
              URL.revokeObjectURL(link.href)
            }).catch(console.error)
      }

      this.$refs.dropdown.hide(true);
    },
    createEmitDownload() {
      this.$refs.dropdown.hide(true);
    },
    createEmitEditName() {
      this.$emit("emitEditMode");
      this.$refs.dropdown.hide(true);
    },
    createEmitDelete() {
      this.$emit("emitDeleteFile");
      this.$refs.dropdown.hide(true);
    },
    hide() {
      this.showBool = false
      this.$emit('hide')
    },
    show() {
      this.showBool = true
      this.$emit('show')
    }
  },
  mounted() {

  }
}
</script>
<style scoped lang="scss">
@import "../style-element/dropdown";
@import "../style-element/checkbox";
.button-drop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  background-color: var(--color-input);
  transition: .1s linear;
  svg {
    path, circle {
      transition: .1s linear
    }
  }
  &:hover {
    svg {
      path, circle {
        fill: #2C2C2C;
      }
    }
    transform: scale(1.2);
  }
  box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.16);
}
/deep/ {

  .custom-control {
    min-height: 1px !important;
    padding: 0 8px 0 12px;
    width: 100%;
    /*height: 100%;*/
    /*box-sizing: border-box;*/
    /*align-items: center;*/
    .custom-control-label {
      width: 100%;

      font-size: 13px;
      line-height: 16px;
      color: #2C2C2C;
    }
  }
  .custom-control-label::before {
    height:16px;
    top: 0;
  }
  .custom-control-label::after {
    left: auto !important;
    right: 14px;
  }
  .custom-switch .custom-control-label::after {
    top: 2px!important;
  }
  .custom-switch .custom-control-label::before {
    left:auto;
    right: 0;
  }
  .custom-switch {
    top: 0 !important;
  }
}
.active-button {
  background-image: url("../../assets/ic_chsdeck.svg");
  background-position-x: calc(100% - 4px);
  background-position-y: center;
  background-repeat: no-repeat;
}
.svg-1 {
  margin-right: 8px;
}
.arrow {
  margin-left: 4px;
}
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  top: -29px !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  padding: 0 !important;
}
.content {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  width: 104px;
  background-color: var(--color-modal);
  border-radius: 4px;
  .title {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 5px 12px;
    display: flex;
    align-items: center;
    svg {
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    transition: .1s linear;
    &:hover {
      color: #2C2C2C !important;
    }
  }
  &>button,&>a{
    text-decoration: none !important;
    height: 28px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    padding: 0 4px;
    box-sizing: border-box;
    svg {
      margin-left: 4px;
    }
    transition: background-color .1s linear;
    &:hover {
      background-color: #DEE3EA;
    }
  }
}
.bottom-button {
  margin-top: 4px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-top: 1px solid #DEE3EA;
}

.dart-mode {
  .button-drop {
    svg {
      path {
        fill: #FFFFFF;
      }
      ellipse {
        fill: #ffffff;
      }
    }
    box-shadow: 0px 0px 2px 2px rgba(255,255,255,0.16);
  }
  .content {
    box-shadow: 0px 0px 2px 2px rgba(255,255,255,0.16);
    svg {
      path {
        fill: #FFFFFF;
      }
    }
    button, a {
      &:hover {
        svg {
          path {
            fill: var(--color-modal) !important;
          }
        }
          color: #2c2c2c !important;
      }
    }
  }
}
.show-always {
  display: flex !important;
}
</style>