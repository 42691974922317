<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">{{type=='type'? 'Delete counterparty type' : 'Delete client subtype'}} <button class="modal-close" @click="closeModal()"></button></p>
        <p class="modal-content_descr">Are you sure you want to delete the {{type=='type'? 'type' : 'subtype'}} <b>{{data.name}}</b>?</p>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal()">Cancel</button>
        <button class="btn btn-next" @click="onSubmit">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex';

export default {
  props: {
    type: {
      type: String,
      default: 'type'
    },
    data: {
      type: Object,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['axiosDelClientType', 'axiosDelClientSubtype']),
    ...mapMutations(['showLoad', 'hideLoad']),
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      this.showLoad()
      if(this.type == 'type'){
        this.axiosDelClientType(this.data.id).then(responce => {
          this.hideLoad()
          this.$emit('close')
          this.$emit('update')
          console.log(responce);
        })
      } else{
        this.axiosDelClientSubtype(this.data.id).then(responce => {
          this.hideLoad()
          this.$emit('close')
          this.$emit('update')
          console.log(responce);
        })
      }
    }
  }
}
</script>

<style lang="scss">

</style>