<template>
  <div class="wrap border-radius-8 box-border overflow-hidden bgc-white" :class="{'tileView': !listView}">
    <div class="title w100Proc d-flex align-items-center h22 pl8 box-border" v-if="items.length && listView">
      <p class="fz12 text-gray cp-date">Cp Date</p>
      <p class="fz12 text-gray">Owner’s name</p>

      <p class="fz12 text-gray second-item ml-auto">Last Updated</p>
    </div>
    <div class="wrap-list">
      <router-link class="list-item w100Proc d-flex align-items-center h28 pl8 box-border justify-between border-top border-color-normal" :to="item.link" v-for="(item, i) in items" :key="i+'asds3'">
        <p class="fz14 text-dark fw500 cp-date">{{item.cp_date |formatDateFromCpDateWithotTimeZome}}</p>
        <p class="fz14 text-dark">{{item.owner ? item.owner.company_name : ''}}</p>
        <p class="fz14 text-dark second-item ml-auto">{{item.updated_at | formatDateAndTimeSpace2}}</p>
      </router-link>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "folders-list-links-charterers",
  props: {
    items: Array
  },
  computed: {
    ...mapState({
      listView: state => state.folders.listView,
    })
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}
.second-item {
  width: 243px;
}
.cp-date {
  width: 140px;
}
.tileView {
  background-color: transparent !important;
  box-shadow: none !important;
  .wrap-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .list-item {
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center !important;
      padding: 0 14px;
      box-sizing: border-box;
      border: none !important;
      background-color: var(--color-modal);
      border-radius: 8px;
      width: calc((100% / 4) - 8px) !important;
      height: 40px !important;
      .second-item {
        display: none;
      }
      &>*:nth-child(2) {
        color: var(--color-gray) !important;
      }
    }
    &>*:not(:nth-child(-n+4)) {
      margin-top: 8px;
    }
    &>*:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>