<template>
  <div class="flex items-center">
    <div class="fs_period-btns relative h32 box-border">
      <date-picker
          class="datepicker_year_popup_wrap"
          format="YYYY"
          type="year"
          :popup-class="'datepicker_year_popup'"
          :open.sync="isOpen"
          value-type="YYYY"
          v-model="dateYear"
          @change="onChangeYear"
          :disabled-date="disabledYear"
      ></date-picker>
      <button class="fs_period-btn fz13 h100Proc" :class="{'active': period == 'year'}" @click="isOpen = !isOpen">{{dateYear ? dateYear : 'Year'}}</button>
      <button class="fs_period-btn fz13 h100Proc" :class="{'active': period == 'all'}" @click="setPeriod('all'); onChangeDate()">All time</button>
    </div>

    <date-picker
        class="max-w-220"
        input-class="border-blue-dark mx-input"
        format="DD.MM.YYYY"
        type="date"
        value-type="DD.MM.YYYY"
        range-separator="-"
        :range="true"
        placeholder="Choose period"
        v-model="date"
        @clear="setPeriod('all'); onChangeDate()"
        @change="onChangeDate"

    ></date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "chose-trial-balance-period",
  props: {
    fs: Boolean
  },
  components: {
    DatePicker
  },
  data() {
    return {
      period: 'all',
      date: [null, null],
      dateYear: null,
      isOpen: false,
      timeout: null,
      emitAfterAxios: false,
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      dateTrialBalance: state => state.trialBalance.dateTrialBalance
    }),
    getYears() {
      if(this.dateTrialBalance && this.dateTrialBalance?.data.length)
        return this.dateTrialBalance?.data
      return null
    },
  },
  methods: {
    ...mapActions(['axiosGetAvailableDateTrialBalance']),
    ...mapMutations(['showLoad', 'hideLoad']),
    setDate(date, period) {
      if(period) {
        this.period = period
      }
      if(period === 'year') {
        this.dateYear = moment(date[0], 'DD.MM.YYYY').format('YYYY')
      }
      this.date = date;
    },
    disabledYear(data) {
      if(this.getYears && this.getYears.length){
        return this.getYears.indexOf(data.getFullYear()) == -1
      }
      return true
    },
    setPeriod(str) {
      if(this.date[0])
        this.date = [null, null]
      if(this.dateYear)
        this.dateYear = null
      this.period = str;
    },
    onChangeDate() {
      if(this.dateYear)
        this.dateYear = null
      if (this.date[0]) {
        this.period = 'period'
      }
      if(this.period === 'all') {
        this.date = [moment(this.dateTrialBalance.min_date).format('DD.MM.YYYY'), moment(this.dateTrialBalance.max_date).format('DD.MM.YYYY')]
      }
      if(!this.fs) {
        this.$emit('changeDate', this.date)
      } else {
        this.$emit('selectPeriod', {period: this.period, date: this.date})
      }
    },
    onChangeYear() {
      // if(this.date[0])
        this.date = [moment(new Date(this.dateYear)).format('01.01.YYYY'), moment(new Date(this.dateYear)).format('31.12.YYYY')]
      if(this.dateYear)
        this.period = 'year'
      if(!this.fs) {
        this.$emit('selectPeriod', {period: 'year', date: this.dateYear})
      } else {
        this.$emit('selectPeriod', {period: this.period, date: this.date})
      }
    },
    mountLocalStorage() {
        this.emitAfterAxios = true;
    },
  },
  mounted() {
    this.axiosGetAvailableDateTrialBalance().then(()=> {
      if(!this.date[0]) {
        this.date = [moment(this.dateTrialBalance.min_date).format('DD.MM.YYYY'), moment(this.dateTrialBalance.max_date).format('DD.MM.YYYY')]
        sessionStorage.setItem('trial_period1', 'all')
        sessionStorage.setItem('trial_period', JSON.stringify([moment(this.dateTrialBalance.min_date).format('DD.MM.YYYY'), moment(this.dateTrialBalance.max_date).format('DD.MM.YYYY')]))
        console.log( sessionStorage.getItem('trial_period'))
      }
      this.$emit('mountNew')

      // if(this.emitAfterAxios) {
      //   console.log(1)
      //   setTimeout(()=> {
      //     this.$emit('mountNew')
      //   }, 1000)
      //   this.emitAfterAxios = false;
      // }
      // console.log(sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined, sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined && sessionStorage.getItem('trial_period') && sessionStorage.getItem('trial_period') !== null && sessionStorage.getItem('trial_period') !== undefined)
      // if(!(sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined) && !(sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined && sessionStorage.getItem('trial_period') && sessionStorage.getItem('trial_period') !== null && sessionStorage.getItem('trial_period') !== undefined)) {
      //   alert(1)
      //
      //   this.onChangeDate()
      // }
    })

  }
}
</script>

<style lang="scss">
.datepicker_year_popup_wrap{
  position: absolute;
  top: 100%;
  right: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
.datepicker_year_popup{
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  .mx-calendar-header-label > *{
    font-size: 13px;
    font-weight: 500;
  }
  .mx-calendar-decade-separator{
    &:after{
      content: '-';
    }
  }
  .mx-icon-double-left{
    &:after{
      content: none;
    }
    &:before{
      border-width: 3px 0 0 3px;
    }
  }
  .mx-icon-double-right{
    &:before{
      content: none;
    }
    &:after{
      border-width: 3px 0 0 3px;
    }
  }
  .mx-calendar-content{
    color: var(--color-dark)
  }
  .disabled {
    background-color: transparent !important;
  }
}
</style>