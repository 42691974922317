<template>
  <div class="m-t-4">
    <div class="border border-dark-gray rounded-lg">
      <div
        class="vessel_owners__table_wide bg-blue-dark rounded-t-lg"
        :class="{
          'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
        }"
      >
        <div
          class="
            border-b border-r
            uppercase
            p-x-2 p-y-2
            fz13
            fw500
            text-white
          "
        >
          Ballast bonus
        </div>
        <div class="border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')">
          <get-trials
            v-if="$route.params.subId"
            trialKey="SUBLET_Ballast Bonus"
            :isWhite="true"
          />
          <get-trials
            v-else
            trialKey="Ballast Bonus"
            :isWhite="true"
          />
        </div>
        <div class="colspan-3-9"></div>
      </div>

      <div
        class="vessel_owners__table_wide bg-white border-gray-light"
        :class="{
          'border-b': balastData.balast_type != 'Net balast bonus',
          'rounded-b-lg': balastData.balast_type == 'Net balast bonus',
          'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
        }"
      >
        <div
          class="border-r text-dark relative"
          :class="{
            'rounded-bl-lg': balastData.balast_type == 'Net balast bonus',
            'bg-gray-light text-gray-text': !balastData.additional_info.is_bonus
          }"
        >
          <select class="p-x-2 p-y-2 fw500" v-model="balastData.balast_type" :disabled="!balastData.additional_info.is_bonus">
            <option value="Gross balast bonus">Gross balast bonus</option>
            <option value="Net balast bonus">Net balast bonus</option>
          </select>

          <switcher 
            class="switcher_owners-row"
            id="balast-bonus"
            v-model="balastData.additional_info.is_bonus"
          />
        </div>
        <div class="border-r" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_bonus}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
          <get-trials
            v-if="$route.params.subId"
            :trialKey="'SUBLET_Ballast Bonus:' + balastData.balast_type"
          />
          <get-trials
            v-else
            :trialKey="'Ballast Bonus:' + balastData.balast_type"
          />
        </div>
        <div class="border-r" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_bonus}"></div>
        <div class="border-r" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_bonus}"></div>
        <div class="border-r" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_bonus}">
          <input-format-number
            v-if="balastData.additional_info.trs_type_bonus == 'revenues'"
            v-model="balastData.gross_balast_debit"
            :inputClass="'p-x-2 p-y-2 text-align-right '+(!balastData.additional_info.is_bonus?'text-gray-text':'')"
            placeholder="Enter the amount"
            :disabled="!balastData.additional_info.is_bonus"
          ></input-format-number>
        </div>
        <div class="border-r" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_bonus}">
          <input-format-number
            v-if="balastData.additional_info.trs_type_bonus == 'expenses'"
            v-model="balastData.gross_balast_credit"
            :inputClass="'p-x-2 p-y-2 text-align-right '+(!balastData.additional_info.is_bonus?'text-gray-text':'')"
            placeholder="Enter the amount"
            :disabled="!balastData.additional_info.is_bonus"
          ></input-format-number>
        </div>
        <div class="border-r">
          <trs-type-btn
            :additional="balastData.additional_info"
            type="trs_type_bonus"
            active="revenues"
          />
        </div>
        <div class="" :class="{'rounded-br-lg overflow-hidden': balastData.balast_type == 'Net balast bonus'}">
          <trs-type-btn
            :additional="balastData.additional_info"
            type="trs_type_bonus"
            active="expenses"
          />
        </div>
      </div>

      <div
        class="vessel_owners__table_wide bg-white rounded-b-lg"
        :class="{
          'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
        }"
        v-if="balastData.balast_type == 'Gross balast bonus'"
      >
        <div class="border-r p-x-2 p-y-2 fw500 text-dark rounded-bl-lg relative" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_commission}">
          Commission
          <switcher 
            class="switcher_owners-row"
            id="balast-commission"
            v-model="balastData.additional_info.is_commission"
          />
        </div>
        <div class="border-r" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_commission}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
          <get-trials
            v-if="$route.params.subId"
            trialKey="SUBLET_Ballast Bonus:Commission"
          />
          <get-trials
            v-else
            trialKey="Ballast Bonus:Commission"
          />
        </div>
        <div class="border-r" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_commission}"></div>
        <div class="border-r relative" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_commission}">
          <input-format-number
            v-model="balastData.commision_percentage"
            :inputClass="'p-l-2 p-r-6 p-y-2 '+(!balastData.additional_info.is_commission?'text-gray-text':'')"
            placeholder="Enter percentage"
            rightPlaceholder="%"
            :disabled="!balastData.additional_info.is_commission"
          ></input-format-number>
        </div>
        <div class="border-r text-dark relative" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_commission}">
          <p
            class="p-x-2 p-y-2 text-align-right"
            v-if="balastData.additional_info.trs_type_commission == 'revenues'"
          >{{balastData.commision_debit | formatNumberTwoZeroEmpty}}</p>
        </div>
        <div class="border-r relative" :class="{'bg-gray-light text-gray-text': !balastData.additional_info.is_commission}">
          <p
            class="p-x-2 p-y-2 text-align-right"
            v-if="balastData.additional_info.trs_type_commission == 'expenses'"
          >{{balastData.commision_credit | formatNumberTwoZeroEmpty}}</p>
        </div>
        <div class="border-r">
          <trs-type-btn
            :additional="balastData.additional_info"
            type="trs_type_commission"
            active="revenues"
          />
        </div>
        <div class="">
          <trs-type-btn
            class=""
            :additional="balastData.additional_info"
            type="trs_type_commission"
            active="expenses"
          />
        </div>
      </div>
    </div>

    <button
      type="button"
      class="m-t-2 flex items-center cursor-pointer text-blue-dark fw500 fz13"
      @click="$emit('on-delete', 'balast')"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"
          fill="#094172"
        />
      </svg>
      Delete Ballast Bonus Section
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import getTrials from "@/components/vessels/owners/edit/getTrials";
import trsTypeBtn from "@/components/vessels/owners/edit/trsTypeBtn";
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import Switcher from '@/components/reuse/switcher.vue'

export default {
  props: {
    balastData: Object,
  },
  components: {
    getTrials,
    trsTypeBtn,
    inputFormatNumber,
    Switcher
  },
  data() {
    return {};
  },
  watch: {
    "balastData.balast_type": function () {
      this.getCommision();
    },
    "balastData.commision_percentage": function () {
      this.getCommision();
    },
    "balastData.gross_balast_debit": function () {
      this.getCommision();
    },
    "balastData.gross_balast_credit": function () {
      this.getCommision();
    },
    "balastData.additional_info.trs_type_bonus": function () {
      this.getCommision(), this.changeTRS();
    },
    "balastData.additional_info.trs_type_commission": function () {
      this.getCommision();
    },
  },
  computed: {
    ...mapGetters(["getPermissionsByType"]),
  },
  methods: {
    changeTRS() {
      if (this.balastData.additional_info.trs_type_bonus == "revenues") {
        this.balastData.gross_balast_debit =
          this.balastData.gross_balast_credit;
        this.balastData.gross_balast_credit = "";
      } else {
        this.balastData.gross_balast_credit =
          this.balastData.gross_balast_debit;
        this.balastData.gross_balast_debit = "";
      }
    },
    getCommision() {
      let summ =
        this.balastData.additional_info.trs_type_bonus == "revenues"
          ? this.balastData.gross_balast_debit
          : this.balastData.gross_balast_credit;
      
      if(typeof summ == 'string')
        summ = summ.replaceAll(',','')

      let result = summ * (this.balastData.commision_percentage / 100);

      result = result ? result : "";

      if (this.balastData.balast_type == "Net balast bonus") result = "";

      if (this.balastData.additional_info.trs_type_commission == "revenues") {
        this.balastData.commision_debit = result
        this.balastData.commision_credit = "";
      } else {
        this.balastData.commision_debit = "";
        this.balastData.commision_credit = result
      }
    },
    checkIsRow() {
      if(!Object.prototype.hasOwnProperty.call(this.balastData.additional_info, 'is_bonus')){
        this.$set(this.balastData.additional_info, 'is_bonus', true)
      }
      if(!Object.prototype.hasOwnProperty.call(this.balastData.additional_info, 'is_commission')){
        this.$set(this.balastData.additional_info, 'is_commission', true)
      }
    }
  },
  mounted() {
    this.checkIsRow()
  }
};
</script>

<style>
</style>