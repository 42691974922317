<template>
  <div 
    class="border border-dark-gray m-b-4 rounded-lg overflow-hidden"
    v-if="(balastData.additional_info.is_commission && (balastData.commision_percentage || 
      balastData.commision_debit || 
      balastData.commision_credit)) || 
      (balastData.additional_info.is_bonus && (balastData.gross_balast_debit || 
      balastData.gross_balast_credit))"
  >
    <div 
      class="vessel_owners__table-wrap"
    >
      <div class="vessel_owners__table">
        <div class="first_element_stroke colspan-1-6 border-b bg-blue-dark uppercase p-x-3 p-y-2 fz13 fw500 text-white">Ballast bonus</div>

        <template v-if="balastData.additional_info.is_bonus">
          <div class="first_element_stroke border-r p-x-3 p-y-2 text-dark fw500">{{balastData.balast_type}}</div>
          <div class="border-r"></div>
          <div class="border-r"></div>
          <div class="border-r p-x-2 p-y-2 text-dark text-align-right amount">{{balastData.gross_balast_debit | formatNumberTwoZeroEmpty}}</div>
          <div class="p-x-2 p-y-2 text-align-right amount">{{balastData.gross_balast_credit | formatNumberTwoZeroEmpty}}</div>
        </template>
      </div>



      <div class="vessel_owners__table"
        v-if="balastData.additional_info.is_commission && balastData.balast_type == 'Gross balast bonus' && (
          balastData.commision_percentage || balastData.commision_debit || balastData.commision_credit
        )"
      >
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark fw500">Commission</div>
        <div class="border-t border-r"></div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative pr20">
          {{balastData.commision_percentage | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">%</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right amount">{{balastData.commision_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right amount">{{balastData.commision_credit | formatNumberTwoZeroEmpty}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    balastData: Object
  },
}
</script>

<style lang="scss" scoped>
.pr20 {
  padding-right: 20px !important;
}

</style>