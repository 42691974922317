<template>
  <div class="wrap " v-click-outside="hide">
    <div class="position-relative">
      <div class="select w100Proc box-border fz13 text-dark d-flex align-items-center" v-on:click="show = !show">
        {{ valueLocal}}
      </div>
      <div class="list" v-if="show">
        <button class="fz13 text-align-left p0-12 box-border w100Proc d-flex align-items-center position-relative" v-for="(name, i) in list" :key="i" v-on:click="select(name)">{{name}}
          <svg v-if="value === name" class="position-absolute r4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
          </svg>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "location-select-zone",
  props: {
    value: [Object, String, Number],
  },
  data() {
    return {
      valueLocal: null,
      show: false,
      list: ["Med-Bsea","Baltic - Continent","WAFR","Indian Ocean","Red Sea","SE Asia","AUSSIE","WC America","USEC","USG-NCSA","ECSA","Far East","NOPAC"]
    }
  },
  watch: {
    value: function () {
      this.valueLocal = JSON.parse(JSON.stringify(this.value))
    }
  },
  methods: {
    select(name) {
      this.show = false
      this.valueLocal = name;
      this.$emit('input', this.valueLocal)
    },
    hide() {
      this.show = false
    }
  },
  mounted() {
    this.valueLocal = JSON.parse(JSON.stringify(this.value))
  }
}
</script>

<style scoped lang="scss">
  .list {
    z-index: 100;
    background-color: var(--color-bgc-page-white);
    position: absolute;
    bottom: -2px;
    width: 100%;
    transform: translateY(100%);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    button {
      height: 32px;
    }
    max-height: 160px;
    overflow-y: auto;
  }
</style>