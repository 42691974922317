<template>
  <div class="container-grid border-round border-color-normal border-top-none">
    <div class="checkbox box-border p0 d-flex align-items-center justify-center border-color-normal border-right">
      <svg v-on:click="$emit('select', item.id)" class="cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!selectedList.find(a=> a.id === item.id)">
        <rect width="16" height="16" rx="4" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.55556 1.24444C2.27916 1.24444 1.24444 2.27916 1.24444 3.55556V12.4444C1.24444 13.7208 2.27916 14.7556 3.55556 14.7556H12.4444C13.7208 14.7556 14.7556 13.7208 14.7556 12.4444V3.55556C14.7556 2.27916 13.7208 1.24444 12.4444 1.24444H3.55556ZM0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#9AA3B0"/>
      </svg>
      <svg v-on:click="$emit('select', item.id)" class="cursor-pointer" v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="4" fill="#094172"/>
        <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
      </svg>
    </div>
    <div class="status box-border p6 d-flex align-items-center justify-center fz13 text-dark fw500 border-right border-color-normal">
      <svg v-if="!item.is_unread_mail" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="4" stroke="#9AA3B0" stroke-width="2"/>
      </svg>
      <svg v-else width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="5" fill="#21CA68"/>
      </svg>

    </div>
    <div class="name box-border text-align-left fz13 text-dark fw500 border-right border-color-normal d-flex align-items-center">
      <input type="text" v-model="item.name">
      <div class="w24 title-warning cursor-pointer ml-auto d-flex align-items-center justify-center h100Proc" v-if="getEmpty">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2 15C12.5866 15 12.9 15.3134 12.9 15.7V16.2C12.9 16.5866 12.5866 16.9 12.2 16.9C11.8134 16.9 11.5 16.5866 11.5 16.2V15.7C11.5 15.3134 11.8134 15 12.2 15Z" fill="#E85353"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2 7.5C12.5866 7.5 12.9 7.8134 12.9 8.2V13.2C12.9 13.5866 12.5866 13.9 12.2 13.9C11.8134 13.9 11.5 13.5866 11.5 13.2V8.2C11.5 7.8134 11.8134 7.5 12.2 7.5Z" fill="#E85353"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2 3.9C7.61604 3.9 3.9 7.61604 3.9 12.2C3.9 16.784 7.61604 20.5 12.2 20.5C16.784 20.5 20.5 16.784 20.5 12.2C20.5 7.61604 16.784 3.9 12.2 3.9ZM2.5 12.2C2.5 6.84284 6.84284 2.5 12.2 2.5C17.5572 2.5 21.9 6.84284 21.9 12.2C21.9 17.5572 17.5572 21.9 12.2 21.9C6.84284 21.9 2.5 17.5572 2.5 12.2Z" fill="#E85353"/>
        </svg>
      </div>
      <div class="cursor-pointer w24 d-flex align-items-center justify-end h100Proc" :class="{'ml-auto': !getEmpty}" v-on:click="$router.push({name: 'Location Mails Vessel', params: {id: item.id}})">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40039L5.18326 9.7027C4.91614 9.97456 4.48367 9.9741 4.217 9.70129C3.95053 9.42852 3.95122 8.98664 4.21837 8.71457L6.84906 5.90641L4.21837 3.18915C3.95125 2.91704 3.95057 2.47545 4.217 2.20264C4.35068 2.06588 4.52581 1.9975 4.70094 1.9975C4.87562 1.9975 5.05006 2.06542 5.1835 2.20123L8.29924 5.41247C8.42789 5.54318 8.50009 5.72107 8.50009 5.90641C8.50009 6.09175 8.42769 6.26943 8.29924 6.40039Z" fill="#2C2C2C"/>
        </svg>
      </div>
    </div>
    <div class="dwt box-border text-align-left fz13 text-dark fw500 border-right border-color-normal p0">
<!--      <input-format-number :input-class="'input-class'" v-model="item.dwt"></input-format-number>-->
      <input type="text" @input="item.dwt = item.dwt.replace(/[^0-9]/g, '')" v-model="item.dwt">
    </div>
    <div class="built box-border text-align-left fz13 text-dark fw500 border-right border-color-normal p0">
      <input type="text" @input="item.build = item.build.replace(/[^0-9]/g, '')" v-model="item.build">
    </div>
    <div class="position box-border text-align-left fz13 text-dark fw500 border-right border-color-normal p0">
      <input type="text" v-model="item.position">
    </div>
    <div class="zone box-border text-align-left fz13 text-dark fw500 border-right border-color-normal p0">
      <location-select-zone v-model="item.zone.name"></location-select-zone>
    </div>
    <div class="date box-border text-align-left fz13 text-dark fw500 border-right border-color-normal p0 position-relative">
      <div class="format-view fz13 fw500 text-dark box-border d-flex align-items-center">
        {{getFormatPeriod}}
      </div>
      <date-picker
          class="date-pick"
          placeholder=""
          type="date"
          format="DD.MM.YYYY"
          v-model="date"
          :range="true"
          range-separator="-"
          @change="changeDate"
      ></date-picker>
    </div>
    <div class="update box-border text-align-right fz13 text-dark fw500 d-flex align-items-center justify-end">{{getFormatUpdate(item.updated_at) }}</div>
  </div>
</template>

<script>
// import InputFormatNumber from "@/components/reuse/inputFormatNumber";
import moment from 'moment'
import LocationSelectZone from "@/components/location/location-list/location-select-zone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {mapActions} from "vuex";
export default {
  name: "location-list-item",
  components: {LocationSelectZone, DatePicker},
  props: {
    item: [Object],
    selectedList: {
      type: Array,
    },
    nwLoad: null,
  },
  watch: {
    nwLoad: function () {
      this.stopChange = true
      setTimeout(()=> {
        this.stopChange = false
      }, 300)
    },
    item: {
     deep: true,
     handler() {
       this.date = [this.item.date_from, this.item.date_to]
       if(!this.stopChange) {
         if(this.timeout) clearTimeout(this.timeout)
         this.timeout = setTimeout(() => {
           this.onSaveRow()
         }, 1000)
       }

      }
    },
  },
  computed: {
    getEmpty() {
      return !this.item.name || !this.item.dwt || !this.item.build || !this.item.position || (this.item.zone && !(this.item.zone.name)) || (!this.date || (Array.isArray(this.date) === true && !this.date[0]))
    },
    getFormatPeriod() {
      let from = this.date.length > 1 ? this.date[0] : null
      let to = this.date.length > 1 ? this.date[1] : null
      if(from && to) {
        if(from === to) {
          return moment(from).format('DD MMM YYYY')
        } else if(moment(from).format('MMM YYYY') === moment(to).format('MMM YYYY')) {
          return  moment(from).format('DD')+'-'+moment(to).format('DD MMM YYYY')
        } else {
          return moment(from).format('DD MMM YYYY') + ' - ' + moment(to).format('DD MMM YYYY')
        }
      }
      return ''
    }
  },
  data() {
    return {
      timeout: null,
      date: [],
      stopChange: true,
    }
  },
  methods: {
    ...mapActions(['axiosChangeLocationItem',]),
    getFormatUpdate(date){
      let offset = Math.round(moment(date).utcOffset()/60)
      offset = offset> 0 ? '+'+offset : '-'+offset
      return moment(date).format('DD MMM YYYY, HH:mm') + ' GMT' + offset
    },
    changeDate() {
      if(this.date.length > 1) {
        this.item.date_from = this.date[0]
        this.item.date_to = this.date[1]
      } else {
        this.item.date_from = null
        this.item.date_to = null
      }
    },
    onSaveRow() {
      let form = {
        "messages_incoming_count": this.item.messages_incoming_count,
        "messages_sent_count": this.item.messages_sent_count,
        "last_message_date": this.item.last_message_date,
        "is_unread_mail":  this.item.is_unread_mail,

        "name": this.item.name,
        "dwt": this.item.dwt ? this.item.dwt : null,
        "build": this.item.build ? this.item.build : null,
        "position": this.item.position ? this.item.position : '',
        "date_from": this.item.date_from ? moment(this.item.date_from).format('YYYY-MM-DD') : null,
        "date_to": this.item.date_to ? moment(this.item.date_to).format('YYYY-MM-DD') : null,
      }
      if(this.item.zone.name) {
        form['zone'] =  {
          "name": this.item.zone.name
        }
      }
      this.axiosChangeLocationItem({id: this.item.id, form: form})
    }
  },
  mounted() {
    console.log('mounted')
    this.date = [this.item.date_from, this.item.date_to]
    setTimeout(()=> {
      this.stopChange = false
    }, 600)
  }
}
</script>

<style scoped lang="scss">
.container-grid {
  //overflow: hidden;
  //border-radius: 10px 10px 0 0;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 28px 38px 1fr 118px 78px 188px 178px 180px 264px;
  grid-template-rows: 32px;
  background-color: var(--color-bgc-page-white);
  gap: 0px 0px;
  grid-template-areas:
    "checkbox status name dwt built position zone date update";
  &>* {
    padding: 6px 12px 6px 8px;
  }
}
.checkbox { grid-area: checkbox; }
.status { grid-area: status; }
.name {
  grid-area: name;
  input {
    width: calc(100% - 32px);
  }
}
.dwt { grid-area: dwt; }
.built { grid-area: built; }
.position { grid-area: position; }
.zone { grid-area: zone; }
.date { grid-area: date; }
.update { grid-area: update; }

.p0 {
  padding: 0 !important;
}
.d-flex {
  display: flex !important;
}
.border {
  border-top-style: hidden;
}
::v-deep {
  .input-class, .select, .mx-input {
    font-weight: 500;
    background-color: transparent;
    padding: 6px 12px 6px 8px !important;
    border: none !important;
    font-size: 0.8125rem !important;
    line-height: 1rem !important;
    height: 32px;
    width: 100%;
    background-image: none !important;
  }
  .mx-datepicker-range {
    width: 100%;
    i {
      display: none !important;
    }
  }
}
input, .format-view {
  font-weight: 500;
  height: 32px;
  background-color: transparent;
  padding: 6px 12px 6px 8px !important;
  border: none;
  font-size: 0.8125rem !important;
  line-height: 1rem !important;
}

.date {
  &::v-deep {
    .date-pick {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .mx-input {
        opacity: 0;
      }
    }
  }

}
.title-warning {
  position: relative;
  &:hover {
    &:after {
      bottom: calc(100% + 3px);
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      content: 'Not all vessel data was parsed. You can add this information manually';
      font-size: 13px;
      line-height: 16px;
      color: var(--color-dark);
      background-color: var(--color-bgc-page);
      padding: 8px 12px;
      width: 160px;
      box-sizing: border-box;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
  }

}
</style>