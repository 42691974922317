<template>
  <modal-slots :textForFirstButton="textForFirstButton ? textForFirstButton : 'Cancel'" :textForSecondButton="textForSecondButton ? textForSecondButton : 'Delete'" :noCloseFilter="true" :show-mod="true" @close="$emit('close')" @apply="$emit('apply')">
    <template v-slot:title>
      <p class="fz18 fw500 text-dark">{{title}}</p>
    </template>
    <p class="fz14 text-dark" v-html="text"></p>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
export default {
  name: "delete-modal",
  components: {ModalSlots},
  props: {
    text: String,
    title: String,
    textForSecondButton: {
      type: String,
      default: 'Delete'
    },
    textForFirstButton: {
      type: String,
      default: 'Cancel',
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ {
  .top-panel {
    padding: 16px !important;
    border-bottom: none !important;
  }
  .bottom-panel {
    padding: 24px 16px 16px 16px !important;
    border-top: none !important;
  }
  .main-block {
    padding: 0 16px !important;
  }
  .blue-button {
    width: 100px !important;
    height: 32px !important;
  }
  .content {
    min-width: 400px !important;
  }
}
</style>