<template>
  <div class="fs_block assets">
    <h3 class="fs_block__title">Equity and liablities <span>USD</span></h3>
    <div class="fs_block__list">
      <div>
        <div class="fs_block__row head">Equity attributable to owners of the company</div>
        <div>
          <div class="fs_block__row" v-if="getRows('equity_attributable_to_owners_of_the_parent').length == 0">No items in this category</div>
          <div class="fs_block__row uppercase" v-else v-for="item in getRows('equity_attributable_to_owners_of_the_parent')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>{{item.amount | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>
        </div>
        <div class="fs_block__row uppercase">Retained earnings <span>{{getRetainedEarnings() | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>
        <div class="fs_block__row subhead">Total equity <span>{{getAmountType('equity_attributable_to_owners_of_the_parent') + getRetainedEarnings() | formatNumberTwoAbsBrackets}}</span></div>
      </div>

      <div>
        <div class="fs_block__row head">Non-current liabilities</div>
        <div>
          <div class="fs_block__row" v-if="getRows('non_current_liabilities').length == 0">No items in this category</div>
          <div class="fs_block__row uppercase" v-else v-for="item in getRows('non_current_liabilities')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>{{item.amount | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>
        </div>
      </div>

      <div>
        <div class="fs_block__row head">Current liabilities</div>
        <div>
          <div class="fs_block__row" v-if="getRows('current_liabilities').length == 0">No items in this category</div>
          <div class="fs_block__row uppercase" v-else v-for="item in getRows('current_liabilities')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>{{item.amount | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>

          <div class="fs_block__row subhead">Total liabilities <span>{{getAmountTotal(['non_current_liabilities', 'current_liabilities']) | formatNumberTwoAbsBrackets}}</span></div>
          
          <div class="fs_block__row subhead">Total equity and liabilities <span>{{getAmountTotal(['equity_attributable_to_owners_of_the_parent', 'non_current_liabilities', 'current_liabilities']) | formatNumberTwoAbsBrackets}}</span></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['list'],
  computed: {
    ...mapGetters(['toFixed2Number'])
  },
  methods: {
    getOperatingProfit() {
      let revenueSumm = this.getRows('revenue_from_contracts_with_customers')
      if(revenueSumm.length){
        revenueSumm = revenueSumm.reduce(( previousValue, currentValue ) => previousValue + currentValue.amount, 0)
      }else revenueSumm = 0

      let operatingSumm = this.getRows('operating_expenses')
      if(operatingSumm.length){
        operatingSumm = operatingSumm.reduce(( previousValue, currentValue ) => previousValue + currentValue.amount, 0)
      }else operatingSumm = 0

      return revenueSumm + operatingSumm
    },
    getProfitBeforeTax() {
      // let operatingSumm = this.getSubRows('operating_profit')
      // if(operatingSumm.length){
      //   operatingSumm = operatingSumm.reduce(( previousValue, currentValue ) => previousValue + currentValue.amount, 0)
      // }else operatingSumm = 0

      // return this.getOperatingProfit() - operatingSumm
      return this.getOperatingProfit() + this.getAmountType('administrative_expenses')
    },
    getProfitByPeriod() {
      return this.getProfitBeforeTax() + this.getAmountType('income_tax')
    },
    getRetainedEarnings() {
      return this.getProfitByPeriod() - this.getAmountType('retained_earnings')
    },
    getAmountType(type) {
      let list = this.list.filter(item=>{
        return (item.financial_statement.type == type)
      })
      let amount = 0
      list.forEach(item=> {
        amount+=item.amount
      })
      return amount
    },
    getRows(key) {
      return this.list.filter(item=>{
        return item.financial_statement.type == key
      })
    },
    getAmount(key) {
      let count = 0
      this.list.forEach(item => {
        if(item.financial_statement.type == key)
          count += item.amount
      })
      return count
      // return this.toFixed2Number(count)
    },
    getAmountTotal(keyArr) {
      let count = 0
      keyArr.forEach(key => {
        this.list.forEach(item => {
          if(item.financial_statement.type == key)
            count += item.amount
        })
      });
      return count
      // return this.toFixed2Number(count)
    },
  }
}
</script>

<style>

</style>