<template>
  <div 
    class="border border-dark-gray m-b-4 rounded-lg overflow-hidden" 
    v-if="(chrtrsData.additional_info.is_paid && (chrtrsData.deposit_paid_debit ||
      chrtrsData.deposit_paid_credit)) ||
      (chrtrsData.additional_info.is_revert && (chrtrsData.deposit_revert_debit ||
      chrtrsData.deposit_revert_credit))"
    >
    <div class="vessel_owners__table-wrap">
      <div class="vessel_owners__table">
        <div class="colspan-1-6 border-b bg-blue-dark uppercase p-x-3 p-y-2 fz13 fw500 text-white">Deposit paid/revert</div>
      </div>

      <div class="vessel_owners__table" v-if="chrtrsData.additional_info.is_paid">
        <div class="border-b border-r p-x-3 p-y-2 fz13 fw500">Deposit paid by Chrts</div>
        <div class="border-b border-r"></div>
        <div class="border-b border-r"></div>
        <div class="border-b border-r p-x-2 p-y-2 text-dark">{{chrtrsData.deposit_paid_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-b p-x-2 p-y-2">{{chrtrsData.deposit_paid_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table" v-if="chrtrsData.additional_info.is_revert">
        <div class="border-r p-x-3 p-y-2 fz13 fw500" style="letter-spacing: -0.1px;">Deposit revert to Chrts</div>
        <div class="border-r"></div>
        <div class="border-r"></div>
        <div class="border-r p-x-2 p-y-2 text-dark text-align-right amount">{{chrtrsData.deposit_revert_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="p-x-2 p-y-2 text-align-right amount">{{chrtrsData.deposit_revert_credit | formatNumberTwoZeroEmpty}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chrtrsData: Object
  }
}
</script>

<style>

</style>