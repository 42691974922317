<template>
  <div class="wrap-page">
    <payment-modal-delete @render="renderList" ref="modalDelete"></payment-modal-delete>
    <div class="top-panel">
        <payment-dropdown-export class="download-list button-top-panel" @eventExportXLSX="downloadXLSXListPayment" @eventExportPDF="downloadPDFListPayment"></payment-dropdown-export>
        <div class="select-period">
          <button class="fz14 text-dark-gray" v-on:click="changePeriod('1M')" :class="{'period-active': periodActive === '1M'}">1M</button>
          <button class="fz14 text-dark-gray" v-on:click="changePeriod('3M')" :class="{'period-active': periodActive === '3M'}">3M</button>
          <button class="fz14 text-dark-gray" v-on:click="changePeriod('6M')" :class="{'period-active': periodActive === '6M'}">6M</button>
          <button class="fz14 text-dark-gray" v-on:click="changePeriod('1Y')" :class="{'period-active': periodActive === '1Y'}">1Y</button>
          <button class="fz14 text-dark-gray" v-on:click="changePeriod('All time')" :class="{'period-active': periodActive === 'All time'}">All time</button>
        </div>
        <router-link :to="{name: 'CashFlow Diagram Archive'}" class="button-dark-link button-archive fz13 fz500 text-dark flex align-items-center justify-center" v-if="$route.path.indexOf('archive') === -1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
    </div>
    <div class="wrap-block">
      <div class="position-relative">
        <cash-flow-double-diagram :periodActive="periodActive" ref="chart" :hideAllDate="true" class="position-relative" :classPosition="true" :hide-date="true" ></cash-flow-double-diagram>
      </div>
      <div class="payment-block position-relative">
        <transition name="fade">
          <app-loader :is-local="true" v-if="isLoader" class="loader-payment"></app-loader>
        </transition>
        <virtual-payment-list @changeOrdering="changeOrdering" @scrollBottomEnd="getNextLinkPayment && getPaymentListOriginal.length !== 0 && !showLoader ? paymentNext() : null" @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" ref="paymentList"></virtual-payment-list>
        <loader-for-autoload v-if="showLoader && getNextLinkPayment && getPaymentListOriginal.length !== 0"></loader-for-autoload>
        <div class="pb55" v-else></div>
      </div>
    </div>
    <transition name="fade">
      <payment-gross-net v-if="!isLoader && getPaymentListOriginal.length"></payment-gross-net>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CashFlowDoubleDiagram from "@/views/cashFlow/cashFlowDiagram/cash-flow-double-diagram";
import PaymentDropdownExport from "@/components/payment/payment-dropdown-export";
import PaymentModalDelete from "@/components/payment/payment-modal-delete";
import moment from 'moment';
import AppLoader from "@/components/loader";
import PaymentGrossNet from "@/components/payment/payment-gross-net";
import VirtualPaymentList from "@/components/payment/payment-list/virtual-payment-list";
import LoaderForAutoload from "@/components/loader-for-autoload";
export default {
name: "cashFlowDiagram",
  components: {
    LoaderForAutoload,
    VirtualPaymentList,
    PaymentGrossNet,
    AppLoader,
    PaymentModalDelete, PaymentDropdownExport, CashFlowDoubleDiagram},
  computed: {
    ...mapGetters(["getFilterDataPaymentAxios", "getNextLinkPayment", "getPermissionsForUser", "getPermissionsByType", "getContracts","getPaymentList", "getPaymentListOriginal", "getDataPaymentModal", "getInfoDetailPayment", "getPaymentList", "getDateForViewPayments"]),
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_cashflow_diagram") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
        next(from);
      }
    })
  },
  data() {
    return {
      orderingName: '',
      periodActive: '1M',
      status_ordering: true,
      due_date_ordering_status: true,
      element: null,
      keyRender: -1000,
      dateSelected: [new Date(), null],
      filterData: null,
      dateDefault: [],
      paymentCheckboxActive: false,
      paymentCheckboxIncomeActive: false,
      firstRequest: true,
      showLoader: false,
      showLoaderButton: false,
      isLoader: false,
      activeDate: ['', '']
    }
  },
  watch: {
    $route: function () {
      this.isLoader = true;
      this.axiosChangeGetList()
      this.changeDateForChart()
    }
  },
  methods: {
    ...mapActions(['axiosPaymentNextLinkPdf', "axiosGetPaymentBreakdown", "axiosAddNewPayment", "axiosPaymentNextLink", "axiosAddNoteToPayment", "axiosGetAllContracts", "axiosGetXLSX", "axiosGetPayment", "axiosGetPDF"]),
    ...mapMutations(["setPaymentsList", "setNextLinkPayment", "setFilterDataPaymentAxios", "cancelPostPayment", "setDateForViewPayments", "setDataPaymentModal", "hideLoad", "showLoad"]),
    getShowPaymentList() {
      return false
    },
    changePeriod(period) {
      this.periodActive = period;
      this.changeDateForChart();

    },
    changeDateForChart() {
      let date = [null, null];
      let type = 'day'
      switch(this.periodActive) {
        case "1M":
          date = [moment().format('YYYY-MM-DD'), moment().add(1, 'months').format('YYYY-MM-DD')]
          // date = [new Date(moment().format('YYYY-MM-01')) ,new Date(moment().endOf('month').format('YYYY-MM-DD'))]
          type = 'day'
          break;
        case "3M":
          date = [moment().format('YYYY-MM-DD'), moment().add(3, 'months').format('YYYY-MM-DD')]
          // date = [new Date(moment().add(-2,'month').format('YYYY-MM-01')) ,new Date(moment().endOf('month').format('YYYY-MM-DD'))]
          type = 'day'
          break;
        case "6M":
          date = [moment().format('YYYY-MM-DD'), moment().add(6, 'months').format('YYYY-MM-DD')]
          // date = [new Date(moment().add(-5,'month').format('YYYY-MM-01')) ,new Date(moment().endOf('month').format('YYYY-MM-DD'))]
          type = 'day'
          break;
        case "1Y":
          date = [moment().format('YYYY-MM-DD'), moment().add(1, 'years').format('YYYY-MM-DD')]
          // date = [new Date(moment().format('YYYY-01-01')) ,new Date(moment().format('YYYY-12-31'))]
          type = 'month'
          break;
        case "All time":
          type = 'year'
          break;
      }
      this.activeDate = date
      this.axiosChangeGetList()

      this.$refs.chart.changeDate({type: type, date: date})
    },
    async downloadPDFListPayment() {
      if(this.getNextLinkPayment) {
        this.showLoad()
        await this.axiosPaymentNextLinkPdf(true).then(()=> {
        })
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadPDF()
      }, 1000)
    },
    paymentNext() {
      this.showLoader = true;
      this.axiosPaymentNextLink(true).then(()=> {
        this.showLoader = false;
      })
    },
    changeOrderingStatus(data) {
      this.status_ordering = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrderingDueDate(data) {
      this.due_date_ordering_status = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrdering(ordering) {
      this.orderingName = ordering
      this.axiosChangeGetList();
    },
    focusFalse() {
      this.$refs.paymentList.focusFalse()
    },
    getActiveColumsList() {
      this.$refs.paymentList.emitGetActiveList();
    },
    async downloadXLSXListPayment() {
      if(this.getNextLinkPayment) {
        this.showLoad()
        await this.axiosPaymentNextLinkPdf().then(()=> {
        })
        this.hideLoad()
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadXML()
      }, 500)
    },
    clickDelete(id) {
      this.$refs.modalDelete.openModal(id);
    },
    renderList() {
      this.emitChangeDate(this.dateSelected);
    },
    emitChangeDate(date) {
      this.cancelPostPayment();
      this.dateSelected = date;

      this.axiosChangeGetList();
    },
    axiosChangeGetList() {
      // let before = "";
      // let after = "";
      // if(""+this.dateSelected[0] !== 'null' && this.dateSelected[0].length !== 0) {
      //   before = ""+moment(this.dateSelected [0]).utcOffset(0, true).format("YYYY-MM-DD")+ " 00:00:00";
      // }
      // if(""+this.dateSelected[1] !== 'null' && this.dateSelected[1].length !== 0) {
      //   after = ""+moment(this.dateSelected [1]).utcOffset(0, true).format("YYYY-MM-DD")+ " 23:59:59";
      // }
      let type = "payment";
      if(this.$route.fullPath.indexOf("accounting-invoice") >= 0) {
        type = "invoice";
      }
      // if(this.firstRequest) {
      //   this.showLoad();
      //   this.firstRequest = false;
      // }
      let orderExecuteDate = ''
      if(this.status_ordering) {
        orderExecuteDate += '-status'
      } else {
        orderExecuteDate += 'status'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-paid_date'
      } else {
        orderExecuteDate += ',paid_date'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-execute_date'
      } else {
        orderExecuteDate += ',execute_date'
      }
      orderExecuteDate = this.orderingName ? this.orderingName : orderExecuteDate
      this.isLoader = true;
      let is_archived = this.$route.path.indexOf('archive') >= 0;
      this.setFilterDataPaymentAxios({is_archived: is_archived, ordering: orderExecuteDate, type: type, before: this.activeDate[1] ? moment(this.activeDate[1]).format('YYYY-MM-DD 23:59:59') : '',after: this.activeDate[0] ? moment(this.activeDate[0]).format('YYYY-MM-DD 00:00:00') : '', filter: this.filterData})
      this.axiosGetPaymentBreakdown({is_archived: is_archived, ordering: orderExecuteDate, type: type, before: this.activeDate[1] ? moment(this.activeDate[1]).format('YYYY-MM-DD 23:59:59') : '',after: this.activeDate[0] ? moment(this.activeDate[0]).format('YYYY-MM-DD 00:00:00') : '', filter: this.filterData}).then(()=> {
        this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
        this.isLoader = false;
      });
    },

  },
  mounted() {
    // if(this.getPermissionsByType("show_cashflow_diagram") === false) {
    //   this.$router.push("/employees")
    // }
    this.isLoader = true;
    this.changeDateForChart()
  }
}
</script>

<style scoped lang="scss">
  @import "cashFlowDiagram";
  //.wrap-diagram {
  //  padding-top: 25px;
  //  display: flex;
  //  &>* {
  //    display: flex;
  //    align-items: center;
  //    width: 50%;
  //  }
  //}
  .dart-mode {
    .period-active {
      color: var(--color-white-always) !important
    }
    .button-archive {
      svg {
        path {
          fill: transparent !important;
          stroke: var(--color-blue-dark)
        }
      }
    }
  }
  /deep/ {
    .expense, .income {
      min-width: 100px;
    }
    .counterparty {
      min-width: 4px;
    }
    .account {
      min-width: 65px;
    }
  }
</style>