<template>
  <div class="wrap-page" :class="{'bg-workspace' : $route.path.indexOf('workspace') >=0 }">
    <div class="top-panel">
      <router-link to="/tasks" class="bread__link fw700 text-dark">Tasks</router-link>
      <svg class="bread__separator svg-dart" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"/></svg>
      <p class="fz20 fw700 text-dark link_title" v-if="getVesselTaskInfo !== null">{{getVesselTaskInfo.task.name}}</p>
<!--      {{getTaskInfo.status}}-->

      <p class="fz13 status" :class="{'status-open': (''+getTaskInfo.status).toUpperCase() === 'OPEN', 'status-done': (''+getTaskInfo.status).toUpperCase() === 'DONE', 'status-inProgress': (''+getTaskInfo.status).toUpperCase() === 'IN PROGRESS'}">{{getTaskInfo.status}}</p>
      <button class="status-button fz14 fw500" :class="{'status-open-button': (''+getStatusButtonNext()).toUpperCase() === 'OPEN', 'status-done-button': (''+getStatusButtonNext()).toUpperCase() === 'DONE', 'status-inProgress-button': getStatusButtonNext().toUpperCase() === 'IN PROGRESS'}" v-on:click="changeStatusTask(getStatusButtonNext())">{{ getStatusButtonNext() }}</button>
      <button class="copy-link" v-on:click="copyLink" title="Copy Link">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.633 16.1952L8.74761 18.0806C7.96598 18.8622 6.70019 18.8622 5.91921 18.0807C5.13808 17.2996 5.13808 16.0337 5.91906 15.2527L9.69042 11.4813C10.4714 10.7003 11.7373 10.7003 12.5183 11.4813C12.7786 11.7417 13.2008 11.7417 13.4611 11.4813C13.7214 11.221 13.7214 10.7989 13.4611 10.5385C12.1594 9.23683 10.0493 9.23683 8.74761 10.5385L4.97627 14.3099C3.67458 15.6116 3.67458 17.7217 4.97627 19.0234C6.27781 20.3257 8.38807 20.3257 9.69045 19.0234L11.5758 17.138C11.8362 16.8777 11.8362 16.4555 11.5758 16.1952C11.3155 15.9348 10.8933 15.9349 10.633 16.1952Z" fill="#2C2C2C"/>
          <path d="M19.0236 4.97627C17.7219 3.67458 15.6111 3.67458 14.3094 4.97627L12.0474 7.23828C11.787 7.49863 11.787 7.92076 12.0474 8.1811C12.3077 8.44145 12.7299 8.44145 12.9902 8.1811L15.2522 5.91909C16.0332 5.13808 17.2998 5.13808 18.0808 5.91909C18.8617 6.70006 18.8617 7.96598 18.0808 8.74695L13.9327 12.895C13.1517 13.676 11.8858 13.676 11.1049 12.895C10.8445 12.6347 10.4224 12.6347 10.1621 12.895C9.90171 13.1553 9.90171 13.5775 10.1621 13.8378C11.4638 15.1395 13.5739 15.1395 14.8756 13.8378L19.0236 9.6898C20.3253 8.3881 20.3253 6.27797 19.0236 4.97627Z" fill="#2C2C2C"/>
        </svg>
      </button>
<!--      <button class="duplicate">-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <rect x="4.75" y="3.75" width="9.5" height="11.5" rx="1.25" stroke="#2C2C2C" stroke-width="1.5"/>-->
<!--          <rect x="9.75" y="8.75" width="9.5" height="11.5" rx="1.25" stroke="#2C2C2C" stroke-width="1.5"/>-->
<!--        </svg>-->
<!--      </button>-->
      <task-full-info-button-option :archivestatus="getTaskInfo.is_archived" :status="getStatusButtonPrev()" @emitChangeStatus="changeStatusTask" @changeEmitArchiveTask="changeArchiveTask" @changeEmitMoveMode="changeEmitMoveMode" class="button-option"></task-full-info-button-option>
      <div class="navigation-block">
        <router-link :to="{name: 'Vessel Workspace'}" class="link fz16 fw500 blue-button-text" :class="{'link-active': $route.path.indexOf('workspace') >= 0}">Workspace</router-link>
        <router-link :to="{name: 'Vessel Detail'}" class="link fz16 fw500 blue-button-text" :class="{'link-active': $route.path.indexOf('detail') >= 0}">Detail</router-link>
      </div>
    </div>
    <modal-slots :show-mod="showModal" @close="showModal = false">
      <template v-slot:title>
        <p class="fz18 fw500">Fill in the Clean Fixed</p>
      </template>
      <p class="text-in-modal fz14 text-dark">You cannot transfer the task to Done until you fill in the Clean Fixed field.</p>
      <template v-slot:bottomFirstButton>
        <button></button>
      </template>
      <template v-slot:bottomSecondButton>
        <button class="blue-button fz14 fw500 h32 buttonW100" v-on:click="showModal = false">Ok</button>
      </template>
    </modal-slots>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import {mapActions, mapGetters, mapMutations} from "vuex";
import TaskFullInfoButtonOption from "@/components/taks-full-info/task-full-info-button-option";
import ModalSlots from "@/components/modal/modal-slots";
Vue.use(VueClipboard)

export default {
  name: "vessel-task-view",
  components: {ModalSlots, TaskFullInfoButtonOption},
  computed: mapGetters(["getPermissionsByType", "getVesselTaskInfo", "getMoveMode", "getTaskInfoConvert", "getTaskInfo", "getAllTag", "getEmployees"]),
  data() {
    return {
      statusTask: ["Open", "In Progress", "Done",],
      status: "Open",
      showModal: false,
    }
  },
  methods: {
    ...mapActions(["axiosCheckContract", "axiosChangeTask", "getAxiosInfoForTask", "axiosGetPriorityTask", "getAxiosAllTag", "axiosGetEmployees"]),
    ...mapMutations(["setVesselTaskInfoData", "moveModeChange", "conversionForTaskInfo", "addTip", "incKeyRenderTask"]),
    changeEmitMoveMode() {
      this.moveModeChange();
    },
    copyLink() {
      let url = window.location.href;
      this.$copyText(url).then(function (e) {
        console.log(e)
      }, function (e) {
        console.log(e)
      })
      this.addTip('Link copied')
    },
    changeArchiveTask() {
      let executors = [];
      let task = this.getTaskInfoConvert;
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      let tags = [];
      for(let i=0; i<task.tags.length; i++) {
        tags.push(task.tags[i].id);
      }
      this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.date, is_archived: !task.is_archived, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(()=> {
        if(!task.is_archived) {
          this.addTip("Task is archived")
          this.$router.push({name: "Tasks"})
        } else {
          this.addTip("Task is unarchived")
        }
        if(this.$route.path.indexOf("detail")>=0) {
          this.incKeyRenderTask()
        } else {
          this.renderTask();
        }
      });
    },

    renderTask() {
      let textRoute = this.$route.path;
      let idTask = textRoute.replace(/[^-0-9]/gim,'');
      this.getAxiosInfoForTask(idTask).then(()=> {
        let info = false;
        this.nameTask = this.getTaskInfo.name;
        this.descriptionTask = this.getTaskInfo.description;
        if(this.getTaskInfo.type === 'CARGO_SEARCH_WITH_SHIP' && !this.getPermissionsByType("can_view_cargo_task")) {
          this.$router.push({name: 'employees'})
        }
        if(this.getTaskInfo.type === 'SHIP_SEARCH_WITH_CARGO' && !this.getPermissionsByType("can_view_ship_task")) {
          this.$router.push({name: 'employees'})
        }


        info = true
        if(info && priority && tag && employees) {
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
        }
        let priority = false;
        this.axiosGetPriorityTask().then(()=> {
          priority = true;
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
          }
        })
        let tag = false;
        this.getAxiosAllTag().then(()=> {
          tag = true;
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
          }
        })
        let employees = false;
        this.axiosGetEmployees().then(()=> {
          employees = true
          if(info && priority && tag && employees) {
            this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
          }
        });
      });
    },

    changeStatusTask(status) {
      if(status === "Done" && this.getPermissionsByType("change_status_into_done_without_filling_in_the_clean_fixed") === false) {
       this.axiosCheckContract(this.getTaskInfoConvert.id).then(res=> {
         if(res === true) {
           console.log(status);
           let executors = [];
           let task = this.getTaskInfoConvert;
           for(let i=0; i<task.executors.length; i++) {
             executors.push(task.executors[i].id);
           }
           let tags = [];
           for(let i=0; i<task.tags.length; i++) {
             tags.push(task.tags[i].id);
           }
           this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.date, is_archived: task.is_archived, status: status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(()=> {
             if(this.$route.path.indexOf("detail")>=0) {
               this.incKeyRenderTask()
             } else {
               this.renderTask();
             }
           });
         } else {
           this.showModal = true
         }
       });
        // console.log("res");
        // console.log(res);
      } else {
        console.log(status);
        let executors = [];
        let task = this.getTaskInfoConvert;
        for(let i=0; i<task.executors.length; i++) {
          executors.push(task.executors[i].id);
        }
        let tags = [];
        for(let i=0; i<task.tags.length; i++) {
          tags.push(task.tags[i].id);
        }
        this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.date, is_archived: task.is_archived, status: status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(()=> {
          if(this.$route.path.indexOf("detail")>=0) {
            this.incKeyRenderTask()
          } else {
            this.renderTask();
          }
        });
      }
    },

    getStatusButtonNext() {
      // for(let i=0; i<this.statusTask.length; i++) {
      //   if(this.statusTask[i].toLowerCase() === this.status.toLowerCase()) {
      //     if(i === this.statusTask.length - 1) {
      //       return this.statusTask[0];
      //     } else {
      //       return this.statusTask[i+1];
      //     }
      //   }
      // }
      if(this.getTaskInfo.status === null || this.getTaskInfo.status === undefined) {
        return "";
      }
      for(let i=0; i<this.statusTask.length; i++) {
        if(this.statusTask[i].toLowerCase() === ("" + this.getTaskInfo.status).toLowerCase()) {
          if(i === this.statusTask.length - 1) {
            return this.statusTask[0];
          } else {
            return this.statusTask[i+1];
          }
        }
      }
    },
    getStatusButtonPrev() {
      for(let i=0; i<this.statusTask.length; i++) {
        if(this.statusTask[i].toLowerCase() === (""+this.getTaskInfo.status).toLowerCase()) {
          if(i === 0) {
            return this.statusTask[this.statusTask.length - 1];
          } else {
            return this.statusTask[i-1];
          }
        }
      }
    },
  },
  beforeRouteLeave(from, to, next) {
    this.setVesselTaskInfoData(null)
    next()

  },
  mounted() {
    // if(this.$route.path.indexOf("workspace")>=0) {
      this.renderTask();
    // }
  }
}
</script>

<style scoped lang="scss">
  @import "vessel-task-view";
</style>