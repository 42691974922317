<template>
  <div class="flex justify-between items-center bg-white p-y-2 box-border">
    <div class="relative w-1/2">
      <button 
        class="relative flex items-center text-blue-dark fz13 fw500 z-10"
        v-if="blocksCounter"
        @click="openMenu" 
      >
        <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="#094172" xmlns="http://www.w3.org/2000/svg"><path d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z"/></svg>
        Add new section
      </button>

      <div 
        class="absolute left-0 bottom-full w-full max-w-204 bg-white rounded shadow z-100"
        tabindex="0" 
        @blur="closeMenu()"
        v-show="isOpenMenu"
        ref="option_list"
      >
        <div
          class="p-x-3 border-gray-light"
          :class="{
            'p-y-1 border-b': i < blocks.length-1 && arr.length
          }"
          v-for="(arr, i) in blocks"
          :key="i"
        >
          <div 
            class="p-y-2 cursor-pointer fz14 leading-3"
            v-for="block in arr"
            :key="block.type"
            @click.stop="closeMenu(block)"
          >
            {{block.name}}
          </div>
        </div>
      </div>
    </div>

    <div class="flex w-1/2 justify-end">
      <button @click="$router.go(-1)" class="edit__owners_btn p-2 fz14 blue-button-text">Cancel</button>
      <button type="button" class="edit__owners_btn blue-button fz14 fw500" @click="$emit('on-save')">Save</button>
    </div>
  </div>
</template>

<script>
//import { mapGetters, mapMutations } from 'vuex';
export default {
  props: {
    blocks: Array
  },
  components: {
  },
  data() {
    return {
      isOpenMenu: false
    }
  },
  computed: {
    blocksCounter() {
      let counter = 0
      this.blocks.forEach(el => {
        counter += +el.length
      })
      return counter
    }
    //...mapGetters(['getExistInvoiceBlocks']),
    /* getInvoiceType() {
      return this.$route.meta.invoiceType
    },
    getBlocks() {
      return this.getExistInvoiceBlocks(this.$route.meta.invoiceType)
    },
    getBlocksArr() {
      let counter = 0
      this.getExistInvoiceBlocks(this.$route.meta.invoiceType).forEach(el => {
        counter += +el.length
      })

      return counter
    } */
  },
  methods: {
    //...mapMutations(['addBlockInvoice', 'clearAttachBlocks', 'clearInvoiceData']),
    openMenu() {
      this.isOpenMenu = true

      setTimeout(()=>{ // because when I call .focus() after change isOpen, it`s don`t work
        let optionList = this.$refs.option_list
        optionList.focus()
      }, 100)
    },
    closeMenu(block) {
      if(block)
        this.$emit('on-add-block', block)
      this.isOpenMenu = false
    },
  },
  beforeMount() {
    /* this.clearInvoiceData()
    this.clearAttachBlocks()
    if(this.getInvoiceType != 'Detention Invoice'){
      this.addBlockInvoice(
        {
          type: "ILOW",
          name: "ILOW"
        }
      )
    } */
  }
}
</script>

<style lang="scss">
</style>