<template>
  <div 
    class="vessel_owners__table_wide bg-white"
    :class="{
      'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
    }"
  >
    <div class="border-b border-r p-x-2 p-y-2 text-dark relative" :class="{'bg-gray-light text-gray-text ': !fuel.additional_info.is_row}">
      {{fuel.fuel_type == 'VLSFO_2' ? 'VLSFO' : fuel.fuel_type == 'LSMGO_2' ? 'LSMGO' : fuel.fuel_type}}
      <switcher 
        class="switcher_owners-row"
        :id="'bunkers-'+fuel.fuel_type+'-'+fuel.bunkers_type"
        v-model="fuel.additional_info.is_row"
      />
    </div>
    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text ': !fuel.additional_info.is_row}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
      <get-trials v-if="$route.params.subId" :trialKey="'SUBLET_Bunkers on '+(fuel.bunkers_type == 'REDELIVERY'?'Redelivery':'Delivery')+':' + (fuel.fuel_type == 'VLSFO_2' ? 'VLSFO (add.)': fuel.fuel_type == 'LSMGO_2' ? 'LSMGO (add.)' : fuel.fuel_type)"/>

      <get-trials v-else :trialKey="'Bunkers on '+(fuel.bunkers_type == 'REDELIVERY'?'Redelivery':'Delivery')+':' + (fuel.fuel_type == 'VLSFO_2' ? 'VLSFO (add.)': fuel.fuel_type == 'LSMGO_2' ? 'LSMGO (add.)' : fuel.fuel_type)"/>
    </div>

    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text ': !fuel.additional_info.is_row}">
      <input-format-number
        v-model="fuel.additional_info.quantity"
        :inputClass="'p-x-2 p-y-2 '+(!fuel.additional_info.is_row?'text-gray-text':'')"
        placeholder="Enter quantity"
        :fractionDigits="3"
        :disabled="!fuel.additional_info.is_row"
      ></input-format-number>
    </div>

    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text ': !fuel.additional_info.is_row}">
      <input-format-number
        v-model="fuel.additional_info.price"
        :inputClass="'p-x-2 p-y-2 '+(!fuel.additional_info.is_row?'text-gray-text':'')"
        placeholder="Enter price"
        :disabled="!fuel.additional_info.is_row"
      ></input-format-number>
    </div>

    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text ': !fuel.additional_info.is_row}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="fuel.additional_info.trs_type == 'revenues'"
      >{{fuel.debit | formatNumberTwoZeroEmpty}}</p>
    </div>

    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text ': !fuel.additional_info.is_row}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="fuel.additional_info.trs_type == 'expenses'"
      >{{fuel.credit | formatNumberTwoZeroEmpty}}</p>
    </div>

    <div class="border-b border-r">
      <trs-type-btn :additional="fuel.additional_info" active="revenues"/>
    </div>

    <div class="border-b">
      <trs-type-btn :additional="fuel.additional_info" active="expenses"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import getTrials from '@/components/vessels/owners/edit/getTrials'
import trsTypeBtn from '@/components/vessels/owners/edit/trsTypeBtn'
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import Switcher from '@/components/reuse/switcher.vue'

export default {
  props: {
    fuel: Object
  },
  components: {
    getTrials,
    trsTypeBtn,
    inputFormatNumber,
    Switcher
  },
  data() {
    return {
    }
  },
  watch: {
    'fuel.additional_info.price': function() {this.setSumm()},
    'fuel.additional_info.quantity': function() {this.setSumm()},
    'fuel.additional_info.trs_type': function() {this.setSumm()}
  },
  computed: {
    ...mapGetters(['getPermissionsByType']),
  },
  methods: {
    setSumm() {
      let creditResult = this.fuel.additional_info.quantity * this.fuel.additional_info.price
      creditResult = creditResult ? creditResult : ''

      if(this.fuel.additional_info.trs_type == 'revenues'){
        this.fuel.debit = creditResult
        this.fuel.credit = ''
      }else{
        this.fuel.debit = ''
        this.fuel.credit = creditResult
      }
    }
  },
}
</script>
