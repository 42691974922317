<template>
  <Transition name="fade">
    <div class="vue-modal" v-if="visibility">
      <div class="modal-container">
        <div class="modal-header">
          <span class="header-name">Move to</span>
          <span class="modal-close" @click.stop="hideModal">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2486 5.03572C19.5415 5.32861 19.5415 5.80348 19.2486 6.09638L13.2032 12.1417L19.2486 18.1871C19.5415 18.48 19.5415 18.9549 19.2486 19.2478C18.9557 19.5407 18.4808 19.5407 18.1879 19.2478L12.1426 13.2024L6.09721 19.2478C5.80432 19.5407 5.32944 19.5407 5.03655 19.2478C4.74366 18.9549 4.74366 18.48 5.03655 18.1871L11.0819 12.1417L5.03655 6.09637C4.74366 5.80348 4.74366 5.32861 5.03655 5.03571C5.32944 4.74282 5.80432 4.74282 6.09721 5.03571L12.1426 11.0811L18.1879 5.03572C18.4808 4.74282 18.9557 4.74282 19.2486 5.03572Z"
                fill="#262626"
              />
            </svg>
          </span>
        </div>
        <div class="modal-content">
          <div class="account" v-if="dataLoaded">
            <div class="account__name">{{accountInfo.name}}</div>
            <div class="folders">
              <div 
                class="folder-item"
                v-for="folder in accountInfo.folders"
                :key="folder.id"

              >
<!--                'disabled-folder': getDisabledSection.indexOf(folder.title) >= 0-->
                <div class="folder-item__heading" :class="{activeFolder: changedFolderId == folder.id, 'disabled-folder': folder.title == 'Draft' && disabledDraft}">
                  <button
                    v-if="folder.child_folders" 
                    class="arrow" 
                    v-b-toggle="'collapseChildFolder'+folder.id"
                    @click="($event) => {$event.target.parentElement.classList.toggle('show')}">
                    <svg width="12" height="12">
                      <use xlink:href="#accordeon_arrow_icn"></use>
                    </svg>
                  </button>
                  <svg width="16" height="16" v-if="folder.title == 'Incoming'">
                    <use xlink:href="#incoming_icn"></use>
                  </svg>
                  <svg width="16" height="17" v-else-if="folder.title == 'Sent'">
                    <use xlink:href="#sent_icn"></use>
                  </svg>
                  <svg width="16" height="17" v-else-if="folder.title == 'Draft'">
                    <use xlink:href="#draft_icn"></use>
                  </svg>
                  <svg width="16" height="17" v-else-if="folder.title == 'Archive'">
                    <use xlink:href="#archive_icn"></use>
                  </svg>
                  <svg width="16" height="17" v-else-if="folder.title == 'Spam'">
                    <use xlink:href="#spam_icn"></use>
                  </svg>
                  <svg width="16" height="16" v-else>
                    <use xlink:href="#folderDefault_icn"></use>
                  </svg>
                  <span class="name" @click="changedFolderId = folder.id">{{folder.title}}</span>
                </div>
                <b-collapse :id="'collapseChildFolder'+folder.id" class="child-folders" v-if="folder.child_folders">
                  <div class="child-folder" :class="{activeFolder: changedFolderId == childFolder.id}" v-for="childFolder in folder.child_folders" :key="childFolder.id">
                    <svg width="16" height="16">
                      <use xlink:href="#folderDefault_icn"></use>
                    </svg>
                    <span class="name" @click="changedFolderId = childFolder.id">{{childFolder.title}}</span>
                  </div>
                </b-collapse>
              </div>
              
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="cancel-btn" @click="hideModal">Cancel</button>
          <button class="modal-btn" :class="{disabled: !changedFolderId}" @click.stop="moveEmail()">
            Move
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
  export default {
    props: {
      accounts: {type: Array},
      messages: Array,
    },
    
    name: 'moveTo-modal',
    data: () => ({
      visibility: false,
      changedFolderId: null,
      checkedMailsId: [],
      accountID: null,
      dataLoaded: false
    }),
    methods: {
      showModal(checkedMailsId, accountID){
        this.accountID = accountID
        this.checkedMailsId = checkedMailsId;
        this.dataLoaded = true
        this.visibility = true
      },
      hideModal(){
        this.checkedMailsId = []
        this.accountID = null
        this.visibility = false
        this.dataLoaded = false
        this.changedFolderId = null
      },
      moveEmail(){
        this.$emit('moveEmail', {folderId: this.changedFolderId, mailsId: this.checkedMailsId})
      }
    },
    computed: {
      disabledDraft() {
        let mails = this.messages.filter(a=> this.checkedMailsId.indexOf(a.id)>=0).map(a=> {
          return a.folder.title
        })
        return mails.filter(a=> a == 'Draft').length !== mails.length
      },
      accountInfo(){
        return this.accounts.find(acc => acc.id == this.accountID[0])
      },
      getDisabledSection() {
        if(!this.checkedMailsId.length) {
          return []
        }
        let sections = ['Incoming', 'Sent', 'Draft']
        let message = this.messages.find(a=> a.id == this.checkedMailsId[0])
        console.log('getDisabledSection', message)
        if(message && sections.indexOf(message.folder.title) >= 0) {
          return sections.filter(a=> a !== message.folder.title)
        }
        return []
      }
    }
  }
</script>

<style lang="scss" scoped>
// @import "./src/components/style-element/bootstrap";
* {
  box-sizing: border-box;
}
.vue-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.3);
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: auto;
  }

  .modal-container {
    width: 508px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 13px 24px 0;
    // padding-top: 13px;
    // position: relative;
    // padding: 24px;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  
  border-bottom: 1px solid #DEE3EA;
  padding: 14px 16px;
  .header-name{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
  }
}
.modal-footer{
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  align-items: center;
  padding: 8px 24px;
  border-top: 1px solid #DEE3EA;

  .modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-white);
    background: var(--color-blue);
    transition: .3s;
    width: 180px;
    border-radius: 4px;
    &:hover{
      background: var(--color-blue-dark);
    }
    &.disabled{
      pointer-events: none;
      opacity: .5;
    }
  }
  .cancel-btn{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--color-blue-dark);
    transition: .3s;
    padding: 8px 24px;
    &:hover{
      color: var(--color-blue);
      transition: .3s;
    }
  }
}
.modal-content{
 padding: 12px 0 18px 0;
 border: none;
 border-radius: 0;
}
.modal-close {
  &:hover {
    cursor: pointer;
  }
}
.account{
  display: flex;
  flex-direction: column;
  .account__name{
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 16px;
  }
  .folders{
    display: flex;
    flex-direction: column;
  }
  .folder-item__heading{
    display: flex;
    align-items: center;
    height: 24px;
    position: relative;
    padding-left: 32px;
    
    &:hover{
      cursor: pointer;
    }
    &.activeFolder{
      background: #E5EEFF;
    }
    svg{
      margin-left: 2px;
    }
    .name{
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .not-collapsed{
      svg{
        transform: rotate(180deg);
      }
    }
  }
  .arrow{
    position: absolute;
    left: 16px;
    svg{
      pointer-events: none;
    }
  }
  .child-folder{
    padding-left: 48px;
    height: 24px;
    display: flex;
    align-items: center;
    svg{
      margin-left: 2px;
    }
    .name{
      margin-left: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 320px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
    }
    &.activeFolder{
      background: #E5EEFF;
    }
    &:hover{
      cursor: pointer;
    }
  }
}
.disabled-folder {
  position: relative;
  opacity: 0.7;
  &::after {
    content: '';
    position: absolute;
    z-index: 30;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

</style>