<template>
  <div class="w-1/3 border-r border-dark-gray flex flex-col">
    <div class="flex items-center p-l-3 p-r-2 p-y-1.5 bg-gray-dark border-b border-dark-gray rounded-tl-lg">
      <h2 class="m-r-auto fz13 fw500 uppercase">Cargo</h2>
      <label v-if="!exportPdf" :for="'ballast'+detail.id" class="m-r-2 fz13 text-dark-gray cursor-pointer">Balllast Voyage</label>
      <switcher v-if="!exportPdf" :id="'ballast'+detail.id" v-model="detail.ballast_voyage"/>
    </div>

    <div class="relative flex flex-col h-full">
      <div class="calc_cargo__ballast_dasabled_bg rounded-bl-lg" v-if="detail.ballast_voyage"></div>
      <div class="flex border-b border-gray-light">
        <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Cargo Class*</div>
        <div class="w-7/12 bg-white relative svg-white-dart" v-click-outside="onBlurCargo">
          <input
            type="text"
            class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13"
            v-model="detail.cargo_class"
            @focus="onFocusCargo"
            @blur="onBlurCargo"
            @keyup.enter="$event.target.blur()"
            ref="cargoInput"
          >
          <svg @click="onFocusCargo" class="absolute right-2 centered-y cursor-pointer" width="12" height="12" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M2.724 4.4a.3.3 0 0 0-.212.512l3.276 3.276a.3.3 0 0 0 .424 0l3.276-3.276a.3.3 0 0 0-.212-.512H2.724Z"/></svg>
          <default-select
            class="absolute left-0 top-full w-full default_select__hide_main_input multiselect_fz13"
            :id="'cargo_class'+detail.id"
            :options="cargoClassList"
            :allowEmpty="true"
            :closeOnSelect="true"
            :searchable="false"
            trackBy="name"
            v-model="cargoClassObj"
            @on-select="onSelectCargo"
            ref="cargoDrop"
          />
        </div>
      </div>

      <div class="flex border-b border-gray-light">
        <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 relative">
          Quantity
          <button class="absolute right-2 centered-y fz0 hover:child-fill-blue-dark" @click="onChangeQuantityType">
            <svg v-if="isQuantityMt" width="26" height="21" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path d="M9.334 9H8.133V2.777h-.041L5.543 8.936H4.57L2.016 2.777h-.041V9H.779V.545h1.518l2.736 6.674h.047L7.81.545h1.524V9Zm4.671 0h-1.312V.545h1.312V9Zm4.636 0h-1.277V.545h1.19l4.382 6.234h.047V.545h1.277V9h-1.183l-4.383-6.229h-.053V9ZM9.334 21H8.133v-6.223h-.041l-2.549 6.159H4.57l-2.554-6.159h-.041V21H.779v-8.455h1.518l2.736 6.674h.047l2.73-6.674h1.524V21Zm7.066 0-.773-2.285h-3.223L11.631 21h-1.365l3.052-8.455h1.418L17.79 21H16.4Zm-2.402-7.049-1.26 3.71h2.555l-1.26-3.71h-.035ZM18.047 21l2.806-4.236v-.041l-2.777-4.178h1.565l2.086 3.27h.046l2.11-3.27h1.482L22.5 16.77v.035L25.313 21h-1.536l-2.103-3.223h-.041L19.5 21h-1.453Z"/></svg>
            <svg v-else width="24" height="24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path d="M12.643 10.515v6.02h-1.559v-6.02H5V9.02h6.084V3h1.56v6.02h6.104v1.495h-6.105ZM5 20.123V18.65h13.748v1.474H5Z"/></svg>
          </button>
        </div>
        <div class="w-7/12 bg-white flex">
          <div v-if="isQuantityMt" class="w-1/2 relative border-r border-gray-light">
            <input 
              type="number" 
              class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13" 
              v-model.number.lazy="detail.quantity_mt" 
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
              @blur="onBlurDetailNumber('quantity_mt')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">mt</span>
          </div>
          <div v-if="isQuantityMt" class="w-1/2 relative">
            <span class="absolute centered-y left-2">
              <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.332 5.162v2.955h-1.1V5.162H2.23V4.106h3V1.151h1.1v2.955h3.016v1.056H6.332ZM2.23 10.65V9.61h7.116v1.04H2.231Z" fill="#9AA3B0"/></svg>
            </span>
            <input 
              type="number" 
              class="p-y-1.5 p-x-6 border-0 bg-transparent rounded-none fz13" 
              v-model.number.lazy="detail.quantity_percentage"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
              @blur="onBlurDetailNumber('quantity_percentage')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">%</span>
          </div>
          <div v-if="!isQuantityMt" class="w-1/2 relative border-r border-gray-light">
            <input 
              type="number" 
              class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13" 
              placeholder="Min" 
              v-model.number.lazy="detail.quantity_min"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
              @blur="onBlurDetailNumber('quantity_min')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">mt</span>
          </div>
          <div v-if="!isQuantityMt" class="w-1/2 relative">
            <input 
              type="number" 
              class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13" 
              placeholder="Max" 
              v-model.number.lazy="detail.quantity_max"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
              @blur="onBlurDetailNumber('quantity_max')"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">mt</span>
          </div>
        </div>
      </div>

      <div class="flex border-b border-gray-light">
        <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Freight</div>
        <div class="w-7/12 bg-white relative">
          <input 
            type="number" 
            class="p-y-1.5 p-l-2 p-r-10 border-0 bg-transparent rounded-none fz13" 
            v-model.number.lazy="detail.freight"
            @keypress="onlyNumber($event)"
            @keyup.enter="$event.target.blur()"
            @blur="onBlurDetailNumber('freight')"
          >
          <span class="absolute centered-y right-2 text-gray-text fz13">$/mt</span>
        </div>
      </div>

      <div class="flex border-b border-gray-light">
        <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Cg Comm</div>
        <div class="w-7/12 bg-white relative">
          <input 
            type="number" 
            class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13" 
            v-model.number.lazy="detail.cg_comm"
            @keypress="onlyNumber($event)"
            @keyup.enter="$event.target.blur()"
            @blur="onBlurDetailNumber('cg_comm')"
          >
          <span class="absolute centered-y right-2 text-gray-text fz13">%</span>
        </div>
      </div>

      <div class="flex border-b border-gray-light">
        <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Stowage Factor*</div>
        <div class="w-7/12 bg-white flex">
          <div class="w-1/2 relative border-r border-gray-light">
            <input
              type="number"
              class="p-y-1.5 p-l-2 p-r-12 border-0 bg-transparent rounded-none fz13"
              v-model.number.lazy="detail.stowage_factor_m"
              @blur="onBlurStowage('m')"
              @keyup.enter="onBlurStowage('m'), $event.target.blur()"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">m³/mt</span>
          </div>
          <div class="w-1/2 relative">
            <input
              type="number"
              class="p-y-1.5 p-l-2 p-r-12 border-0 bg-transparent rounded-none fz13"
              v-model.number.lazy="detail.stowage_factor_ft"
              @blur="onBlurStowage('ft')"
              @keyup.enter="onBlurStowage('ft'), $event.target.blur()"
            >
            <span class="absolute centered-y right-2 text-gray-text fz13">ft³/mt</span>
          </div>
        </div>
      </div>

      <div class="flex h-full">
        <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Extra Cargo Costs</div>
        <div class="w-7/12 bg-white relative">
          <input 
            type="number" 
            class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13" 
            v-model.number.lazy="detail.extra_cargo_costs"
            @keypress="onlyNumber($event)"
            @keyup.enter="$event.target.blur()"
            @blur="onBlurDetailNumber('extra_cargo_costs')"

          >
          <span class="absolute top-1 right-2 text-gray-text fz13">$</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Switcher from '@/components/reuse/switcher'
import defaultSelect from '@/components/reuse/defaultSelect'
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    detail: Object,
    exportPdf: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Switcher,
    defaultSelect
  },
  data() {
    return {
      cargoClassObj: null,
      isQuantityMt: true
    }
  },
  watch: {
    'detail.id': {
      immediate: true,
      handler() {
        if(!this.detail.quantity_mt && (this.detail.quantity_min || this.detail.quantity_max)){
          this.isQuantityMt = false
        }else {
          this.isQuantityMt = true
        }
        this.cargoClassObj = null
      }
    }
  },
  computed: {
    ...mapState({
      cargoClassList: state => state.tabs.cargoClassList
    }),
    /* isQuantityMt() {
      if(this.detail.quantity_mt) return true
      return false
    } */
  },
  methods: {
    ...mapActions(['onlyNumber']),
    onSelectCargo() {
      if(this.cargoClassObj){
        this.detail.cargo_class = this.cargoClassObj.name
        this.detail.stowage_factor_m = this.cargoClassObj.m3
        this.detail.stowage_factor_ft = this.cargoClassObj.ft3
      }
      this.$refs.cargoInput.blur()
    },
    onFocusCargo() {
      this.$refs.cargoDrop.openDropdown()
    },
    onBlurCargo() {
      this.$refs.cargoDrop.closeDropdown()
      if(this.cargoClassObj && (this.cargoClassObj.name != this.detail.cargo_class))
        this.cargoClassObj = null
    },
    
    onChangeQuantityType() {
      if(this.isQuantityMt){/* mt to min/max */
        let numb = parseFloat(this.detail.quantity_mt) ? parseFloat(this.detail.quantity_mt) : 0
        let max = parseFloat(this.detail.quantity_percentage) ? (100 + parseFloat(this.detail.quantity_percentage))/100 : 1
        let min = parseFloat(this.detail.quantity_percentage) ? (100 - parseFloat(this.detail.quantity_percentage))/100 : 1
        this.detail.quantity_min = this.detail.quantity_mt ? Math.round(numb*min*1000000)/1000000 : null
        this.detail.quantity_max = this.detail.quantity_mt ? Math.round(numb*max*1000000)/1000000 : null
        this.detail.quantity_mt = null
        this.isQuantityMt = false
      }else{/* min/max to mt */
        let result = null
        if(parseFloat(this.detail.quantity_min)>=0) result = parseFloat(this.detail.quantity_min)
        if(parseFloat(this.detail.quantity_max)>=0) result = parseFloat(this.detail.quantity_max)
        if(parseFloat(this.detail.quantity_min)>=0 && parseFloat(this.detail.quantity_max)>=0)
          result = (parseFloat(this.detail.quantity_min) + parseFloat(this.detail.quantity_max))/2
        this.detail.quantity_mt = result >=0 ? Math.round(result*1000000)/1000000 : null
        this.isQuantityMt = true
      }
    },
    onBlurStowage(type) {
      let factor = 0
      if(type == 'm'){
        if(parseFloat(this.detail.stowage_factor_m)) factor = parseFloat(this.detail.stowage_factor_m)
        this.detail.stowage_factor_ft = this.detail.stowage_factor_m ? Math.round((factor/0.02831684659)*1000000000)/1000000000 : null
      }else{
        if(parseFloat(this.detail.stowage_factor_ft)) factor = parseFloat(this.detail.stowage_factor_ft)
        this.detail.stowage_factor_m = this.detail.stowage_factor_ft ? Math.round(factor*0.02831684659*1000000000)/1000000000 : null
      }
    },
    onBlurDetailNumber(key) {
      if(!this.detail[key] || typeof this.detail[key] != 'number'){
        this.detail[key] = null
      }
    }
  },
}
</script>

<style lang="scss">
.calc_cargo__ballast_dasabled_bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-lightest);
  opacity: 0.5;
  z-index: 100;
}
</style>