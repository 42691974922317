<template>
  <div class="flex justify-between items-center p-y-3">
    <h2 class="fz20 fw500 overflow-hidden overflow-ellipsis whitespace-nowrap" :title="active">{{active ? active : 'Please choose country'}}</h2>

    <div class="relative svg-white-dart">
      <input class="p-l-10 p-r-6 h-8" type="text" placeholder="Search" :value="value" v-on:keydown.enter="$emit('input', $event.target.value)" @blur="$emit('input', $event.target.value)">

      <svg class="absolute top-1 left-2" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.154 18.291l-4.171-4.17a5.777 5.777 0 001.294-3.648 5.812 5.812 0 00-5.806-5.805 5.812 5.812 0 00-5.805 5.805 5.812 5.812 0 005.806 5.806c1.38 0 2.649-.486 3.647-1.294l4.17 4.17a.61.61 0 00.864 0 .61.61 0 000-.864zm-8.682-3.234a4.588 4.588 0 01-4.584-4.584 4.588 4.588 0 014.584-4.583 4.588 4.588 0 014.583 4.583 4.588 4.588 0 01-4.583 4.584z" fill="#2C2C2C"/></svg>

      <svg v-if="value" @click="$emit('input', '')" class="absolute top-1 right-1 cursor-pointer" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.514 7.577a.75.75 0 010-1.067.768.768 0 011.078 0l9.894 9.786a.75.75 0 010 1.067.767.767 0 01-1.078 0L6.514 7.577z" fill="#2C2C2C"/><path d="M16.409 6.634a.768.768 0 011.077 0 .75.75 0 010 1.067l-9.894 9.786a.767.767 0 01-1.078 0 .75.75 0 010-1.067l9.895-9.786z" fill="#2C2C2C"/></svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: String,
    value: String
  },
  computed: {
  }
}
</script>