<template>
  <b-dropdown right ref="dropdown1"  id="dropdown-2">
    <template #button-content>
      <button class="button-drop-tags fz13 fw500">
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.17 7.58012C9.57353 7.58012 9.09 7.09659 9.09 6.50012C9.09 5.90365 9.57353 5.42012 10.17 5.42012C10.7665 5.42012 11.25 5.90365 11.25 6.50012C11.25 7.09659 10.7665 7.58012 10.17 7.58012Z" fill="#2C2C2C"/>
          <ellipse cx="5.99959" cy="6.50017" rx="1.08" ry="1.08" transform="rotate(90 5.99959 6.50017)" fill="#2C2C2C"/>
          <path d="M1.83064 7.58017C1.23418 7.58017 0.750644 7.09663 0.750644 6.50017C0.750644 5.9037 1.23418 5.42017 1.83064 5.42017C2.42711 5.42017 2.91064 5.9037 2.91064 6.50017C2.91064 7.09663 2.42711 7.58017 1.83064 7.58017Z" fill="#2C2C2C"/>
        </svg>

      </button>
    </template>
    <div class="content-tags">
      <button class="fz13 text-dark" v-on:click="renameTag()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#2C2C2C"/>
        </svg>
        Rename
      </button>
      <button class="fz13 text-dark" v-on:click="deleteTag()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
        </svg>
        Delete
      </button>
    </div>
  </b-dropdown>

</template>

<script>
export default {
name: "task-full-info-tag-option",
  methods: {
    renameTag() {
      this.$emit("eventRenameTag");
      this.$refs.dropdown1.hide(false);
    },
    deleteTag() {
      this.$emit("eventDeleteTagInTask");
      this.$refs.dropdown1.hide(false);
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/components/style-element/bootstrap";
.button-drop-tags {
  height: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bgc-page-white);
  position: relative;
  top: -2px;
  svg {
    path, circle, ellipse {
      transition: .1s linear;
      fill: var(--color-blue-dark);
    }
  }
  &:hover {
    svg {
      path, circle, ellipse {
        fill: #319FEF;
      }
    }
    //transform: scale(1.2);
  }
}
.content-tags {
  position: relative;
  bottom: 10px;
  width: 96px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  .title {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 5px 12px;
    display: flex;
    align-items: center;
    svg {
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    transition: .1s linear;
    &:hover {
      color: #2C2C2C !important;
    }
  }
  &>button{
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    transition: background-color .1s linear;
    &:hover {
      background-color: var(--color-input);
    }
    &:first-child {
      padding-top: 4px;
      padding-bottom: 2px;
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      padding-bottom: 4px;
      padding-top: 2px;
      border-radius: 0 0 4px 4px;
    }
  }
}

</style>

