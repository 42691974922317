<template>
  <div class="wrap">
    <div class="wrap-checkbox">
      <div class="check-box" v-on:click="()=>{suez = !suez; checkCode()}">
        <svg v-if="!suez" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
        </svg>
        <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="18" height="18" rx="4" fill="#094172"/>
          <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
        </svg>
      </div>
      <p class="fz14 text-dark ml8">Suez</p>
    </div>
    <div class="wrap-checkbox ml16">
      <div class="check-box" v-on:click="()=>{panama = !panama; checkCode()}">
        <svg v-if="!panama" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
        </svg>
        <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="18" height="18" rx="4" fill="#094172"/>
          <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
        </svg>
      </div>
      <p class="fz14 text-dark ml8">Panama</p>
    </div>
    <div class="wrap-checkbox ml16">
      <div class="check-box" v-on:click="()=>{kiel = !kiel; checkCode()}">
        <svg v-if="!kiel" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
        </svg>
        <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="18" height="18" rx="4" fill="#094172"/>
          <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
        </svg>
      </div>
      <p class="fz14 text-dark ml8">Kiel</p>
    </div>

  </div>
</template>

<script>
export default {
  name: "canal-code",
  props: {
    value: [String, Number],
    dontLocalSave: Boolean,
    check: Number,
  },
  data() {
    return {
      suez: true,
      panama: true,
      kiel: true,
      canalPassCodes: [
        {code: '000', description: 'Exclude all canals', suez: false, panama: false, kiel: false,},
        {code: '001', description: 'Exclude ‘Suez’ and ‘Panama’', suez: false, panama: false, kiel: true,},
        {code: '010', description: 'Exclude ‘Suez’ and ‘Kiel’', suez: false, panama: true, kiel: false,},
        {code: '011', description: 'Exclude ‘Suez‘', suez: false, panama: true, kiel: true,},
        {code: '100', description: 'Exclude ‘Panama’ and ‘Kiel’', suez: true, panama: false, kiel: false,},
        {code: '101', description: 'Exclude ‘Panama’', suez: true, panama: false, kiel: true,},
        {code: '110', description: 'Exclude ‘Kiel’', suez: true, panama: true, kiel: false,},
        {code: '111', description: 'Not exclude canals', suez: true, panama: true, kiel: true,},
      ]
    }
  },
  watch: {
    value: function () {
      let item = this.canalPassCodes.find(a=> a.code === this.value)
      if(item) {
        if(this.suez !== item.suez || this.panama !== item.panama || this.kiel !== item.kiel) {
          this.suez = item.suez
          this.panama = item.panama
          this.kiel = item.kiel
        }
      }
    }
  },
  methods: {
    checkCode() {
      let canal = this.canalPassCodes.find(a=> this.suez === a.suez && this.panama === a.panama && this.kiel === a.kiel)
      if(!this.dontLocalSave) {
        localStorage.setItem(this.$route.params.id+'canalCode', JSON.stringify(canal))
      }
      if(canal) {
        this.$emit('input', canal.code)
      }
    },
  },
  mounted() {
    if(!this.dontLocalSave && localStorage.getItem(this.$route.params.id+'canalCode') && localStorage.getItem(this.$route.params.id+'canalCode') !== undefined && localStorage.getItem(this.$route.params.id+'canalCode') !== null) {
      let item = JSON.parse(localStorage.getItem(this.$route.params.id+'canalCode'))
      this.suez = item.suez;
      this.panama = item.panama;
      this.kiel = item.kiel
    }
    if(!this.dontLocalSave) {
      this.checkCode()
    } else if(this.value) {
      let item = this.canalPassCodes.find(a=> a.code === this.value)

      if(item) {
        if(this.suez !== item.suez || this.panama !== item.panama || this.kiel !== item.kiel) {
          this.suez = item.suez
          this.panama = item.panama
          this.kiel = item.kiel
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap-checkbox {
  display: flex;
  align-items: center;
}
.wrap {
  display: flex;
  align-items: center;
}

</style>