<template>
  <div class="option-btn_wrap attachment">
    <button class="option-btn">
      <svg class="option-btn_icon" @click.stop="openMenu" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.17 7.08183C9.57353 7.08183 9.09 6.5983 9.09 6.00183C9.09 5.40536 9.57353 4.92183 10.17 4.92183C10.7665 4.92183 11.25 5.40536 11.25 6.00183C11.25 6.5983 10.7665 7.08183 10.17 7.08183Z" fill="#2C2C2C"/><ellipse cx="5.99959" cy="6.00188" rx="1.08" ry="1.08" transform="rotate(90 5.99959 6.00188)" fill="#2C2C2C"/><path d="M1.83064 7.08188C1.23418 7.08188 0.750644 6.59834 0.750644 6.00188C0.750644 5.40541 1.23418 4.92187 1.83064 4.92187C2.42711 4.92187 2.91064 5.40541 2.91064 6.00188C2.91064 6.59834 2.42711 7.08188 1.83064 7.08188Z" fill="#2C2C2C"/></svg>
    </button>

    <div class="option-btn_list" tabindex="0" @blur="closeMenu" v-show="isOpen" ref="option_list">
      <div @click.stop="download" class="option-btn_item" v-if="options.indexOf('download') >= 0">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.0332 5C13.0332 4.6134 12.7198 4.3 12.3332 4.3C11.9466 4.3 11.6332 4.6134 11.6332 5H13.0332ZM12.3332 14L11.8391 14.4959C12.1123 14.768 12.5541 14.768 12.8273 14.4959L12.3332 14ZM16.8273 10.5102C17.1012 10.2373 17.102 9.79412 16.8291 9.52026C16.5562 9.2464 16.113 9.24561 15.8391 9.51848L16.8273 10.5102ZM8.82731 9.51848C8.55345 9.24561 8.11024 9.2464 7.83736 9.52026C7.56449 9.79412 7.56528 10.2373 7.83914 10.5102L8.82731 9.51848ZM19.6999 16C19.6999 15.6134 19.3865 15.3 18.9999 15.3C18.6133 15.3 18.2999 15.6134 18.2999 16H19.6999ZM6.03324 16C6.03324 15.6134 5.71984 15.3 5.33324 15.3C4.94664 15.3 4.63324 15.6134 4.63324 16H6.03324ZM11.6332 5V14H13.0332V5H11.6332ZM12.8273 14.4959L16.8273 10.5102L15.8391 9.51848L11.8391 13.5041L12.8273 14.4959ZM12.8273 13.5041L8.82731 9.51848L7.83914 10.5102L11.8391 14.4959L12.8273 13.5041ZM16.9999 18.3H7.33324V19.7H16.9999V18.3ZM18.2999 16V17H19.6999V16H18.2999ZM6.03324 17V16H4.63324V17H6.03324ZM7.33324 18.3C6.61527 18.3 6.03324 17.718 6.03324 17H4.63324C4.63324 18.4912 5.84207 19.7 7.33324 19.7V18.3ZM16.9999 19.7C18.4911 19.7 19.6999 18.4912 19.6999 17H18.2999C18.2999 17.718 17.7179 18.3 16.9999 18.3V19.7Z" fill="#2C2C2C"/></svg>
        Download<a :href="file.file" ref="link" download target="_blank"></a>
      </div>
      <div class="option-btn_item" @click.stop="$emit('edit-item')" v-if="options.indexOf('edit') >= 0 && canEdit">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#2C2C2C"/></svg>
        Edit name
      </div>
      <div class="option-btn_item" @click.stop="$emit('delete-item')" v-if="options.indexOf('delete') >= 0 && canEdit">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/></svg>
        Delete
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    file: Object,
    options: {
      type: Array,
      default: function() {
        return ['download', 'edit', 'delete']
      }
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    openMenu() {
      this.isOpen = true
      setTimeout(()=>{ // because when I call .focus() after change isOpen, it`s don`t work
        let optionList = this.$refs.option_list
        optionList.focus()
      }, 100)
    },
    closeMenu() {
      this.isOpen = false
    },
    download(){
      this.$refs.link.click()
    }
  },
}
</script>

<style lang="scss">
  .option-btn_wrap.attachment{
    position: absolute;
    top: -1px;
    right: 9px;
    /* opacity: 0;
    transition: opacity 0.3s; */
    .option-btn{
      background-color: #fff;
      width: 16px;
      height: 16px;
      display: flex;
      border-radius: 50%;
      svg{
        margin: auto;
      }
    }
    .option-btn_list{
      right: auto;
      left: 0;
    }
    .option-btn_item{
      padding-left: 4px;
      a{
        opacity: 0;
      }
    }
  }
</style>