<template>
  <section>
    <div class="sticky">
      <filter-b-d-i :panamaxSelected="getShipId == 2" ref="filter" @clickEditOrAddRoutes="clickEditOrAddRoutes" @changeParametersFilter="changeParametersFilter"></filter-b-d-i>
      <edit-or-add-routes ref="editOrAddRoutes" @changeRoutes="changeRoutesInFilter"></edit-or-add-routes>
    </div>
    <div class="wrap-content">
    <div class="top-panel">
      <div class="container">
        <p class="fz20 fw500 text-dark">Baltic Dry Index</p>
        <router-link to="/add-new-index" class="blue-button h32 buttonW130" v-if="getPermissionsByType('can_create_index')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="white"/>
          </svg>
          <p class="fz13 fw500">Add new index</p>
        </router-link>

        <div class="block-buttons">
          <span class="line"></span>
          <button class="button-baltic fz16 fw500 text-gray" v-for="(b,i) in buttons" :class="{'button-baltic-active': b.status}" :key="i" v-on:click="changeActiveButton(i)">{{b.name}} </button>
        </div>
<!--        {{element}}-->
        <div class="block-button-filter">
          <button class="button-filter fz14 fw500 text-dark svg-white-dart" v-on:click="showFilter">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.28634 3.73746C8.22907 3.73746 7.3304 4.45987 7.03965 5.44983H4.63436C4.26432 5.44983 4 5.74415 4 6.11873C4 6.49331 4.29075 6.78763 4.66079 6.78763H7.06608C7.3304 7.77759 8.25551 8.5 9.31277 8.5C10.3965 8.5 11.2952 7.77759 11.5859 6.78763H18.3392C18.7093 6.78763 19 6.49331 19 6.11873C19 5.74415 18.7093 5.44983 18.3392 5.44983H11.5859C11.2687 4.45987 10.37 3.73746 9.28634 3.73746ZM9.31277 5.07525C9.86784 5.07525 10.3436 5.5301 10.3436 6.11873C10.3436 6.70736 9.86784 7.16221 9.31277 7.16221C8.75771 7.16221 8.28194 6.70736 8.28194 6.11873C8.28194 5.5301 8.75771 5.07525 9.31277 5.07525Z" fill="#2C2C2C"/>
              <path d="M14.2423 9.61377C13.185 9.61377 12.2863 10.3362 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6205 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6205 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3362 15.326 9.61377 14.2423 9.61377ZM14.2687 10.9516C14.8238 10.9516 15.2996 11.4064 15.2996 11.995C15.2996 12.5837 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5837 13.2379 11.995C13.2379 11.4064 13.6872 10.9516 14.2687 10.9516Z" fill="#2C2C2C"/>
              <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5186 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8029 8.42731 20.5253 9.48458 20.5253C10.5683 20.5253 11.467 19.8029 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5186 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5554 10.5154 18.144C10.5154 18.7326 10.0396 19.1875 9.48458 19.1875C8.92951 19.1875 8.45374 18.7326 8.45374 18.144C8.48018 17.5554 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
            </svg>
            Filter
          </button>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="bdi-table-wrap" :class="{'hideTableWrap': showTable, 'hideBlock': getStatusRequestFilter}">
        <div class="block-if-hide-table text-gray fz12 fw500" :class="{'block-if-hide-table-show': showTable}">
          BDI TABLE
        </div>
        <button class="button-bdi-table" :class="{'button-active': showTable}" v-on:click="showTable = !showTable">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="23.5" y="23.5" width="23" height="23" rx="11.5" transform="rotate(-180 23.5 23.5)" stroke="#094172"/>
            <rect x="23.5" y="23.5" width="23" height="23" rx="11.5" transform="rotate(-180 23.5 23.5)" fill="white"/>
            <path d="M9.70076 11.5059L12.8167 8.20355C13.0839 7.93169 13.5163 7.93215 13.783 8.20496C14.0495 8.47773 14.0488 8.91961 13.7816 9.19168L11.1509 11.9998L13.7816 14.7171C14.0487 14.9892 14.0494 15.4308 13.783 15.7036C13.6493 15.8404 13.4742 15.9088 13.2991 15.9088C13.1244 15.9088 12.9499 15.8408 12.8165 15.705L9.70076 12.4938C9.57211 12.3631 9.49991 12.1852 9.49991 11.9998C9.49991 11.8145 9.57231 11.6368 9.70076 11.5059Z" fill="#094172"/>
          </svg>
        </button>

        <div class="bdi-table" :class="{'hideTable': showTable}" :key="keyRender">
          <transition name="fade1">
            <div class="wrap-wrap" v-if="getStatusRequestFilter">
              <div class="wrap-loading-wrap">
                <div class="wrap-loading">
                  <div class="holder">
                    <div class="preloader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
<!--s-->
          <div class="top-name">
            <div class="fz14 text-gray">Date</div>
            <div class="fz14 text-gray" v-for="(d,i) in getDataFilter.nameLines" :key="i">{{ d }}</div>
          </div>
          <div class="stroke" v-for="(d, i) in getListReverse(getDataFilter.data)" :key="i" v-on:click="addTextForDotsLine(d, i)" :class="{'activeStroke': getStatusForStroke[i] === true}">
            <div class="fz14 text-dark">{{ getDateTable(d.date)}}</div>
            <div class="fz14 text-dark" v-for="(v, j) in getDataFilter.valueLines" :key="j"><p class="fz14 text-dark" v-if="d[v] !== null && d[v] !== undefined">{{d[v] | formatNumberTwo}}</p><p v-else class="fz14 text-dark"> </p></div>
          </div>
          <p class="text-no-data fz13 text-gray" v-if="getDataFilter.nameLines.length === 0">No Data</p>
        </div>
      </div>
      <div class="bg"></div>

      <div class="block-with-line-chart">
        <type-of-graphs class="type-of-graphs" ref="typeOfGraphs" @changeType="changeType"></type-of-graphs>
        <div class="button-wrap-average">
          <button v-on:click="average = false" :class="{'active-average-button': !average}" class="fz13 text-dark fw500">
            Overlay
          </button>
          <button v-on:click="average = true" :class="{'active-average-button': average}" class="fz13 text-dark fw500">
            In queue
          </button>
        </div>
<!--        {{buttons}}-->
        <div class="block-choosed svg-white-dart" v-if="blockChoosed.years.length !==0 || blockChoosed.month.length !==0 || blockChoosed.routes.length !==0 || averageShow || blockChoosed.routesAll || blockChoosed.monthAll">
          <p class="fz12 text-gray">Active filters</p>
          <div class="content-choosed">
            <div v-for="(y, i) in blockChoosed.years" :key="'c3'+i" class="fz13 text-dark">
              {{ y.name }}
              <button v-on:click="deleteInFilterYears(y.index)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
                  <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div v-for="(m, i) in blockChoosed.month" :key="'a'+i" class="fz13 text-dark capitalize">
              {{ m.name }}
              <button v-on:click="deleteInFilterMonth(m.index)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
                  <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div v-if="blockChoosed.monthAll === true" class="fz13 text-dark capitalize">
              All month
              <button v-on:click="deleteAllMonth()">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
                  <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div class="fz13 text-dark" v-if="blockChoosed.periodDate !== 0 && blockChoosed.periodDate[0].length !== 0 && blockChoosed.periodDate[1].length !== 0">
              {{getFormatDate(blockChoosed.periodDate[0])}} - {{getFormatDate(blockChoosed.periodDate[1])}}
              <button v-on:click="deletePeriod">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
                  <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div v-for="(r, i) in blockChoosed.routes" :key="'b1'+i" class="fz13 text-dark">
              {{ r.name }}
              <button v-on:click="deleteInFilterRoutes(r.index)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
                  <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div class="fz13 text-dark" v-if="averageShow">
              Average
              <button v-on:click="deleteInFilterAverage">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
                  <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div v-if="blockChoosed.routesAll === true" class="fz13 text-dark capitalize">
              All routes
              <button v-on:click="deleteAllRoutes()">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
                  <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <div class="fz13 text-dark" v-if="blockChoosed.period.to.length !== 0 && blockChoosed.period.from.length !== 0">
              Days: from {{blockChoosed.period.from}} to {{blockChoosed.period.to}}
              <button v-on:click="deleteInFilterDate">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.05373 3.92508C2.81022 3.68397 2.81022 3.29304 3.05373 3.05193C3.29724 2.81082 3.69206 2.81082 3.93557 3.05193L8.94574 7.97308C9.18925 8.21419 9.18925 8.60512 8.94574 8.84623C8.70223 9.08735 8.30741 9.08735 8.0639 8.84623L3.05373 3.92508Z" fill="#2C2C2C"/>
                  <path d="M8.06398 3.15332C8.3075 2.9122 8.70231 2.9122 8.94582 3.15332C9.18933 3.39443 9.18933 3.78536 8.94582 4.02647L3.93565 8.94762C3.69214 9.18873 3.29732 9.18873 3.05381 8.94762C2.8103 8.7065 2.8103 8.31558 3.05381 8.07446L8.06398 3.15332Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <line-chart v-if="$route.path.indexOf('test') === -1" :showTable="showTable" :month-active="blockChoosed.month" ref="lineChart" class="line-chart" :type="typeActive"></line-chart>
        <line-chart-test v-else :showTable="showTable" :month-active="blockChoosed.month" ref="lineChart" class="line-chart" :type="typeActive"></line-chart-test>
<!--        {{keyRender}}-->
      </div>
    </div>
<!--    <button v-on:click="keyRender++">RENDER</button>-->
    </div>

  </section>
</template>

<script>
import FilterBDI from "@/components/balticDryIndex/filterBDI/filterBDI";
import EditOrAddRoutes from "@/components/balticDryIndex/editOrAddRoutes/editOrAddRoutes";
import LineChart from "@/components/balticDryIndex/lineChart/lineChart";
import {mapActions, mapGetters, mapMutations} from "vuex";
import TypeOfGraphs from "@/components/balticDryIndex/type-of-graphs/type-of-graphs";
import moment from "moment"
import LineChartTest from "@/components/balticDryIndex/lineChart/lineChartTest";

// import TypeOfGraphs from "@/components/balticDryIndex/type-of-graphs/type-of-graphs";
export default {
  computed: mapGetters(["getPermissionsByType", "getRoutes", "getShips", "getDataFilter", "getStatusRequestFilter", "getStatusForStroke", "getShipId"]),
  name: "balticDryIndex",
  components: {LineChartTest, TypeOfGraphs, LineChart, EditOrAddRoutes, FilterBDI},
  data() {
    return {
      buttons: [
      ],
      average: true,
      element: null,
      allLine: null,
      showTable: false,
      indexButtonActive: 0,
      averageShow: false,
      parametersAxiosFilter: {
        ranges: [],
        routes: [],
        ship_type: [],
      },
      blockChoosed: {
        years: [],
        month: [],
        routes: [],
        monthAll: false,
        routesAll: false,
        periodDate: ['', ''],
        period: {
          from: "",
          to: "",
        },
      },
      widthStrokeAmount: "max-content",
      keyRender: -290,
      oldIndex: 0,
      typeActive: 'Line',
    }
  },
  watch: {
    average: function() {
      this.addStatusRequestFilter();
      if(this.average) {
        this.$refs.typeOfGraphs.changeOne();
      }
      setTimeout(()=>{this.$refs.lineChart.changeAverageStatus(this.average)}, 300)

      setTimeout(()=>{this.decStatusRequestFilter()}, 2100)
    }
  },
  methods: {
    ...mapActions(["axiosGetShips", "axiosPostIndexFilter", "axiosGetRoutes"]),
    ...mapMutations(["decStatusRequestFilter", "addStatusRequestFilter", "decStatusRequestFilter", "setStatusRequestFilter", "setFirstRequest", "clearDataFilter", "setShipId", "cancelPostIndexFilter", "setStatusForStroke", "setFilterYear", "setFilterMonth", "setFilterRoutes"]),
    getFormatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getDateTable(date) {
      return moment(moment.utc(date).toDate()).format('D, MMM')
    },
    changeType(name) {
      if(name !== 'Line') {
        this.average = false;
      }
      this.typeActive = name;
    },
    getListReverse(array) {
      let array1 = JSON.parse(JSON.stringify(array))
      if(Array.isArray(array) === true) {
        return array1.reverse()
      }
      return []
    },
    deleteInFilterYears(index) {
      this.$refs.filter.changeYear(index);
    },
    deleteInFilterMonth(index) {
      this.$refs.filter.changeMonth(index);
    },
    deleteInFilterRoutes(index) {
      this.$refs.filter.changeRouteIndex(index);
    },
    deletePeriod() {
      this.$refs.filter.clearPeriodDate()
    },
    deleteInFilterAverage() {
      this.$refs.filter.averageAll();
    },
    deleteInFilterDate() {
      this.$refs.filter.deleteDate();
    },
    numberWithSpaces(value) {
      let fixedNumber = parseFloat(value)
      fixedNumber = fixedNumber ? fixedNumber : 0
      fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      return fixedNumber
      // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    changeActiveButton: function (index) {
      if(this.indexButtonActive !== index) {
        this.average = true
        this.indexButtonActive = index;
        let oldIndex = 0;
        for (let i = 0; i < this.buttons.length; i++) {
          if (this.buttons[i].status === true) {
            oldIndex = i;
          }
          this.buttons[i].status = false;
        }
        this.buttons[index].status = true;
        this.setShipId(this.buttons[index].id);
        this.$refs.filter.setRoutesForShip();
        this.parametersAxiosFilter.ship_type.splice(0,this.parametersAxiosFilter.ship_type.length);
        this.parametersAxiosFilter.ship_type.push(this.buttons[index].id);
        if (index >= oldIndex) {
          this.allLine[0].style.left = this.element[index].offsetLeft + "px";
          this.allLine[0].style.width = this.element[index].clientWidth + "px";
        } else {
          this.allLine[0].style.left = this.element[index].offsetLeft + "px";
          this.allLine[0].style.width = this.element[index].clientWidth + "px";
        }
        if(this.buttons[index].name === 'Panamax') {
          this.$refs.filter.setFilterForPanamax()
        } else {
          if(this.parametersAxiosFilter.ranges.length!==0 && this.parametersAxiosFilter.routes.length!==0 && this.parametersAxiosFilter.ship_type.length !==0) {
            this.cancelPostIndexFilter();
            this.axiosPostIndexFilter(this.parametersAxiosFilter).then(()=> {
              this.$refs.lineChart.createChart()
              // if(this.average) {
              //   this.$refs.lineChart.clearChart();
              //   this.$refs.lineChart.clearDataChart()
              //   this.$refs.lineChart.setDataChartInQueue();
              //   this.$refs.lineChart.createSeries('valueInQueue', '', null)
              //   this.$refs.lineChart.createSeriesAdding1("additional1", "s1");
              //   this.$refs.lineChart.createSeriesAdding("additional", "s");
              //   this.$refs.lineChart.getTolltipe();
              // } else {
              //   this.$refs.lineChart.clearChart();
              //   if(this.typeActive === 'Bars' || this.typeActive === 'Candles' || this.typeActive === 'Filled Candles' || this.typeActive === 'Hollow Candles') {
              //     this.$refs.lineChart.setDataChartInQueue();
              //   } else {
              //     this.$refs.lineChart.setDataChart();
              //   }
              //   this.$refs.lineChart.addSeries();
              //   this.$refs.lineChart.getTolltipe();
              // }
            });
            // this.$refs.filter.createEmitChangeParametersFilter();
          } else {
            this.cancelPostIndexFilter();
            this.clearDataFilter();
            this.$refs.lineChart.clearChart();
            this.$refs.lineChart.clearDataChart();
          }
        }

      }
    },
    addTextForDotsLine(data, index) {
      this.setStatusForStroke(index);
      this.keyRender ++;
      this.$refs.lineChart.addTextForDotsLine(data);
    },
    showFilter() {
      this.$refs.filter.showMenu();
    },
    clickEditOrAddRoutes(array) {
      this.$refs.editOrAddRoutes.showMenu(array);
    },
    changeRoutesInFilter() {
      this.$refs.filter.changeRoutesRenderNew();
    },
    deleteAllMonth() {
      this.$refs.filter.deleteAllMonth();
    },
    deleteAllRoutes() {
      this.$refs.filter.deleteAllRoutes();
    },
    changeParametersFilter(data) {
      let ship_type = [];
      for(let i=0; i< this.buttons.length; i++) {
        if(this.buttons[i].status === true) {
          ship_type.push(this.buttons[i].id);
        }
      }

      this.averageShow = data.average;
      this.blockChoosed.periodDate = data.periodDate
      this.blockChoosed.period.from = "" + data.period.from;
      this.blockChoosed.period.to = "" + data.period.to;
      this.blockChoosed.monthAll = data.monthAll;
      this.blockChoosed.routesAll = data.routesAll;
      this.blockChoosed.years.splice(0,this.blockChoosed.years.length);
      this.blockChoosed.month.splice(0,this.blockChoosed.month.length);
      this.blockChoosed.routes.splice(0,this.blockChoosed.routes.length);
      this.blockChoosed.years = data.yearFilter;
      this.blockChoosed.month = data.monthFilter;
      this.blockChoosed.routes = data.routesFilter;
      this.parametersAxiosFilter.ranges.splice(0,this.parametersAxiosFilter.ranges.length);
      this.parametersAxiosFilter.routes.splice(0,this.parametersAxiosFilter.routes.length);
      this.parametersAxiosFilter.ship_type.splice(0,this.parametersAxiosFilter.ship_type.length);
      this.parametersAxiosFilter.ranges = data.ranges;
      this.parametersAxiosFilter.routes = data.routes;
      this.parametersAxiosFilter.ship_type = ship_type;
      if(this.parametersAxiosFilter.ranges.length!==0 && this.parametersAxiosFilter.routes.length!==0 && this.parametersAxiosFilter.ship_type.length !==0) {
        this.cancelPostIndexFilter();
        this.widthStrokeAmount = "max-content";
        this.axiosPostIndexFilter(this.parametersAxiosFilter).then(()=> {
          let element = document.querySelectorAll(".stroke>*:not(:first-child)");
          let width = 0;
          for(let i=0; i<element.length; i++) {
            if(element[i].offsetWidth > width) {
              width = element[i].offsetWidth;
            }
          }
          this.widthStrokeAmount = 0 + "px";
          this.widthStrokeAmount = width + 9 + "px";
          // this.$refs.lineChart.clearChart();
          // this.$refs.lineChart.setDataChart();
          // this.$refs.lineChart.addSeries();
          this.$refs.lineChart.createChart()
          // if(this.average) {
          //   this.$refs.lineChart.clearChart();
          //   this.$refs.lineChart.clearDataChart()
          //   this.$refs.lineChart.setDataChartInQueue();
          //   this.$refs.lineChart.createSeries('valueInQueue', '', null)
          //   this.$refs.lineChart.createSeriesAdding1("additional1", "s1");
          //   this.$refs.lineChart.createSeriesAdding("additional", "s");
          //   this.$refs.lineChart.getTolltipe();
          // } else {
          //   this.$refs.lineChart.clearChart();
          //   if(this.typeActive === 'Bars' || this.typeActive === 'Candles' || this.typeActive === 'Filled Candles' || this.typeActive === 'Hollow Candles') {
          //     this.$refs.lineChart.setDataChartInQueue();
          //   } else {
          //     this.$refs.lineChart.setDataChart();
          //   }
          //   this.$refs.lineChart.addSeries();
          //   this.$refs.lineChart.getTolltipe();
          // }

        });
      } else {
        this.cancelPostIndexFilter();
        this.clearDataFilter();
        this.$refs.lineChart.clearChart();
        this.$refs.lineChart.clearDataChart();
      }
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(!vm.$store.getters.getPermissionsByType("can_view_index_page")) {
        next(from);
      }
    })
  },
  // beforeRouteLeave(to,from) {
  //
  // },
  mounted() {
    let buttonActive = 0;
    this.axiosGetShips().then(()=> {
      for(let i=0; i<this.getShips.length; i++) {
        this.buttons.push(this.getShips[i]);
      }
      if(this.getShipId.length !== 0) {
        for(let i=0; i<this.getShips.length; i++) {
          this.buttons[i].status = false;
          if(("" + this.buttons[i].id) === this.getShipId) {
            this.buttons[i].status = true;
            buttonActive = i;
            this.indexButtonActive = i;
          }
        }
      } else {
        this.setShipId(this.buttons[buttonActive].id);
      }
      return null;
    }).then(()=> {
      // this.$refs.filter.changeRoutesRenderNew();
      this.element = document.getElementsByClassName("button-baltic");
      this.allLine = document.getElementsByClassName("line");
      this.allLine[0].style.left = this.element[buttonActive].offsetLeft + "px";
      this.allLine[0].style.width = this.element[buttonActive].clientWidth + "px";
      if(!this.getRoutes.length) {
        this.axiosGetRoutes().then(()=> {
          this.$refs.filter.changeRoutesRenderNew();
          this.$refs.filter.createEmitChangeParametersFilter();
        })
      } else{
        this.$refs.filter.changeRoutesRenderNew();
        this.$refs.filter.createEmitChangeParametersFilter();

      }

    });
    // this.decStatusRequestFilter();



    // for (let i = 0; i<this.allLine.length; i++) {
    //   this.allLine[i].style.width = 0 + "px";
    //   this.allLine[i].style.right = 0 + "px";
    //   this.allLine[i].style.left = "auto";
    // }
  },
  beforeRouteLeave(to,from,next) {
    this.setStatusRequestFilter(0);
    this.setFirstRequest(false);
    this.$refs.filter.setVuexStore();
    next();
  }
}
</script>

<style scoped lang="scss">
  @import "balticDryIndex";
</style>