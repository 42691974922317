<template>
  <div class="m-t-4 border border-dark-gray rounded-lg">
    <div 
      class="vessel_owners__table_wide bg-white rounded-lg"
      :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <div
        class="
          rounded-tl-lg
          bg-blue-dark
          text-white
          border-b border-r
          uppercase
          p-x-2 p-y-2
          fw500
        "
      >
        Bunkers on Delivery
      </div>
      <div class="bg-blue-dark border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          :isWhite="true"
          v-if="$route.params.subId"
          trialKey="SUBLET_Bunkers on Delivery"
        />
        <get-trials
          :isWhite="true"
          v-else
          trialKey="Bunkers on Delivery"
        />
      </div>
      <div
        class="
          rounded-tr-lg
          bg-blue-dark
          border-b
          colspan-3-9
          flex
          items-center
          p-x-2 p-y-2
          justify-end
        "
      >
        <edit-checkboxes :bunkers="bunkersOnData" typeBunkers="DELIVERY" class="text-white"/>
      </div>

      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
      <div
        class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1"
      >
        Qntty, mt
      </div>
      <div
        class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1"
      >
        Price, {{editData.currency}}/mt
      </div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b"></div>
    </div>

    <div 
      class="vessel_owners__table_wide bg-white"
      :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <div class="border-b border-r p-x-2 p-y-2 text-dark bg-gray-lightest relative" :class="{'bg-gray-light-important text-gray-text ': !getDeliverVLSFO.additional_info.is_row}">
        {{ getDeliverVLSFO.fuel_type }}
        <switcher 
          class="switcher_owners-row"
          id="bunkers-DeliverVLSFO_ADD"
          v-model="getDeliverVLSFO.additional_info.is_row"
        />
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverVLSFO.additional_info.is_row}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          v-if="$route.params.subId"
          trialKey="SUBLET_Bunkers on Delivery:VLSFO"
        />
        <get-trials
          v-else
          trialKey="Bunkers on Delivery:VLSFO"
        />
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverVLSFO.additional_info.is_row}">
        <input-format-number
          v-model="getDeliverVLSFO.additional_info.quantity"
          :inputClass="'p-x-2 p-y-2 '+(!getDeliverVLSFO.additional_info.is_row?'text-gray-text':'')"
          placeholder="Enter quantity"
          :fractionDigits="3"
          :disabled="!getDeliverVLSFO.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverVLSFO.additional_info.is_row}">
        <input-format-number
          v-model="getDeliverVLSFO.additional_info.price"
          :inputClass="'p-x-2 p-y-2 '+(!getDeliverVLSFO.additional_info.is_row?'text-gray-text':'')"
          placeholder="Enter price"
          :disabled="!getDeliverVLSFO.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverVLSFO.additional_info.is_row}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="getDeliverVLSFO.additional_info.trs_type == 'revenues'"
        >{{getDeliverVLSFO.debit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverVLSFO.additional_info.is_row}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="getDeliverVLSFO.additional_info.trs_type == 'expenses'"
        >{{getDeliverVLSFO.credit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r bg-gray-lightest">
        <trs-type-btn
          :additional="getDeliverVLSFO.additional_info"
          active="revenues"
        />
      </div>
      <div class="border-b bg-gray-lightest">
        <trs-type-btn
          :additional="getDeliverVLSFO.additional_info"
          active="expenses"
        />
      </div>


      <div class="border-b border-r p-x-2 p-y-2 text-dark bg-gray-lightest relative" :class="{'bg-gray-light-important text-gray-text ': !getDeliverLSMGO.additional_info.is_row}">
        {{ getDeliverLSMGO.fuel_type }}
        <switcher 
          class="switcher_owners-row"
          id="bunkers-DeliverLSMGO_ADD"
          v-model="getDeliverLSMGO.additional_info.is_row"
        />
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverLSMGO.additional_info.is_row}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          v-if="$route.params.subId"
          trialKey="SUBLET_Bunkers on Delivery:LSMGO"
        />
        <get-trials
          v-else
          trialKey="Bunkers on Delivery:LSMGO"
        />
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverLSMGO.additional_info.is_row}">
        <input-format-number
          v-model="getDeliverLSMGO.additional_info.quantity"
          :inputClass="'p-x-2 p-y-2 '+(!getDeliverLSMGO.additional_info.is_row?'text-gray-text':'')"
          placeholder="Enter quantity"
          :fractionDigits="3"
          :disabled="!getDeliverLSMGO.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverLSMGO.additional_info.is_row}">
        <input-format-number
          v-model="getDeliverLSMGO.additional_info.price"
          :inputClass="'p-x-2 p-y-2 '+(!getDeliverLSMGO.additional_info.is_row?'text-gray-text':'')"
          placeholder="Enter price"
          :disabled="!getDeliverLSMGO.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverLSMGO.additional_info.is_row}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="getDeliverLSMGO.additional_info.trs_type == 'revenues'"
        >{{getDeliverLSMGO.debit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getDeliverLSMGO.additional_info.is_row}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="getDeliverLSMGO.additional_info.trs_type == 'expenses'"
        >{{getDeliverLSMGO.credit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r bg-gray-lightest">
        <trs-type-btn
          :additional="getDeliverLSMGO.additional_info"
          active="revenues"
        />
      </div>
      <div class="border-b bg-gray-lightest">
        <trs-type-btn
          :additional="getDeliverLSMGO.additional_info"
          active="expenses"
        />
      </div>
    </div>


    <div v-if="editData.additional_info.isDeliveryRoll">
      <edit-bunkers-on-table-row
        v-for="type in getDelivery"
        :key="type.fuel_type"
        :fuel="type"
      />
    </div>


    <div class="vessel_owners__table_wide bg-white">
      <button type="button" class="flex items-center p-2 text-blue-dark fz13 fw500" @click="onRoll('DELIVERY')">
        {{editData.additional_info.isDeliveryRoll ? 'Roll up' : 'Show more'}} <svg :class="{'rotate-180': editData.additional_info.isDeliveryRoll}" class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.3999L6.00001 8.3999L10 4.3999L2 4.3999Z" fill="#094172"/></svg>
      </button>
    </div>

<!-- <= Delivery - Redelivery => -->

    <div 
      class="vessel_owners__table_wide bg-white"
      :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <div
        class="
          border-b border-r
          bg-blue-dark
          text-white
          uppercase
          p-x-2 p-y-2
          fz13
          fw500
        "
      >
        Bunkers on Redelivery
      </div>
      <div class="border-b border-r bg-blue-dark" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          :isWhite="true"
          v-if="$route.params.subId"
          trialKey="SUBLET_Bunkers on Redelivery"
        />
        <get-trials
          :isWhite="true"
          v-else
          trialKey="Bunkers on Redelivery"
        />
      </div>
      <div
        class="
          border-b
          bg-blue-dark
          colspan-3-9
          flex
          items-center
          p-x-2 p-y-2
          justify-end
        "
      >
        <edit-checkboxes :bunkers="bunkersOnData" typeBunkers="REDELIVERY" class="text-white" />
      </div>

      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
      <div
        class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1"
      >
        Qntty, mt
      </div>
      <div
        class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1"
      >
        Price, {{editData.currency}}/mt
      </div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b"></div>
    </div>

    <div 
      class="vessel_owners__table_wide bg-white"
      :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <div class="border-b border-r p-x-2 p-y-2 text-dark bg-gray-lightest relative" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverVLSFO.additional_info.is_row}">
        {{ getRedeliverVLSFO.fuel_type }}
        <switcher 
          class="switcher_owners-row"
          id="bunkers-RedeliverVLSFO_ADD"
          v-model="getRedeliverVLSFO.additional_info.is_row"
        />
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverVLSFO.additional_info.is_row}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          v-if="$route.params.subId"
          trialKey="SUBLET_Bunkers on Redelivery:VLSFO"
        />
        <get-trials
          v-else
          trialKey="Bunkers on Redelivery:VLSFO"
        />
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverVLSFO.additional_info.is_row}">
        <input-format-number
          v-model="getRedeliverVLSFO.additional_info.quantity"
          :inputClass="'p-x-2 p-y-2 '+(!getRedeliverVLSFO.additional_info.is_row?'text-gray-text':'')"
          placeholder="Enter quantity"
          :fractionDigits="3"
          :disabled="!getRedeliverVLSFO.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverVLSFO.additional_info.is_row}">
        <input-format-number
          v-model="getRedeliverVLSFO.additional_info.price"
          :inputClass="'p-x-2 p-y-2 '+(!getRedeliverVLSFO.additional_info.is_row?'text-gray-text':'')"
          placeholder="Enter price"
          :disabled="!getRedeliverVLSFO.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverVLSFO.additional_info.is_row}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="getRedeliverVLSFO.additional_info.trs_type == 'revenues'"
        >{{getRedeliverVLSFO.debit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverVLSFO.additional_info.is_row}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="getRedeliverVLSFO.additional_info.trs_type == 'expenses'"
        >{{getRedeliverVLSFO.credit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r bg-gray-lightest">
        <trs-type-btn
          :additional="getRedeliverVLSFO.additional_info"
          active="revenues"
        />
      </div>
      <div class="border-b bg-gray-lightest">
        <trs-type-btn
          :additional="getRedeliverVLSFO.additional_info"
          active="expenses"
        />
      </div>


      <div class="border-b border-r p-x-2 p-y-2 text-dark bg-gray-lightest relative" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverLSMGO.additional_info.is_row}">
        {{ getRedeliverLSMGO.fuel_type }}
        <switcher 
          class="switcher_owners-row"
          id="bunkers-RedeliverLSMGO_ADD"
          v-model="getRedeliverLSMGO.additional_info.is_row"
        />
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverLSMGO.additional_info.is_row}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          v-if="$route.params.subId"
          trialKey="SUBLET_Bunkers on Redelivery:LSMGO"
        />
        <get-trials
          v-else
          trialKey="Bunkers on Redelivery:LSMGO"
        />
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverLSMGO.additional_info.is_row}">
        <input-format-number
          v-model="getRedeliverLSMGO.additional_info.quantity"
          :inputClass="'p-x-2 p-y-2 '+(!getRedeliverLSMGO.additional_info.is_row?'text-gray-text':'')"
          placeholder="Enter quantity"
          :fractionDigits="3"
          :disabled="!getRedeliverLSMGO.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverLSMGO.additional_info.is_row}">
        <input-format-number
          v-model="getRedeliverLSMGO.additional_info.price"
          :inputClass="'p-x-2 p-y-2 '+(!getRedeliverLSMGO.additional_info.is_row?'text-gray-text':'')"
          placeholder="Enter price"
          :disabled="!getRedeliverLSMGO.additional_info.is_row"
        ></input-format-number>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverLSMGO.additional_info.is_row}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="getRedeliverLSMGO.additional_info.trs_type == 'revenues'"
        >{{getRedeliverLSMGO.debit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r bg-gray-lightest" :class="{'bg-gray-light-important text-gray-text ': !getRedeliverLSMGO.additional_info.is_row}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="getRedeliverLSMGO.additional_info.trs_type == 'expenses'"
        >{{getRedeliverLSMGO.credit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r bg-gray-lightest">
        <trs-type-btn
          :additional="getRedeliverLSMGO.additional_info"
          active="revenues"
        />
      </div>
      <div class="border-b bg-gray-lightest">
        <trs-type-btn
          :additional="getRedeliverLSMGO.additional_info"
          active="expenses"
        />
      </div>
    </div>

    <div v-if="editData.additional_info.isRedeliveryRoll">
      <edit-bunkers-on-table-row
        v-for="(type, i) in getRedelivery"
        :key="type.fuel_type + i"
        :fuel="type"
      />
    </div>

    <div class="vessel_owners__table_wide bg-white border-radius-down">
      <button type="button" class="flex items-center p-2 text-blue-dark fz13 fw500" @click="onRoll('REDELIVERY')">
        {{editData.additional_info.isRedeliveryRoll ? 'Roll up' : 'Show more'}} <svg :class="{'rotate-180': editData.additional_info.isRedeliveryRoll}" class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.3999L6.00001 8.3999L10 4.3999L2 4.3999Z" fill="#094172"/></svg>
      </button>
    </div>

<!-- CEV -->
<!--    <div -->
<!--      class="vessel_owners__table_wide bg-gray-lightest rounded-b-lg"-->
<!--      :class="{-->
<!--        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')-->
<!--      }"-->
<!--    >-->
<!--      <div class="border-t border-b border-r"></div>-->
<!--      <div class="border-t border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>-->
<!--      <div class="border-t border-b border-r"></div>-->
<!--      <div-->
<!--        class="bg-white-dark border-t border-b border-r text-gray-text fz13 p-x-2 p-y-1"-->
<!--      >-->
<!--        Rate, {{editData.currency}}/PMPR-->
<!--      </div>-->
<!--      <div class="border-t border-b border-r"></div>-->
<!--      <div class="border-t border-b border-r"></div>-->
<!--      <div class="border-t border-b border-r"></div>-->
<!--      <div class="border-t border-b"></div>-->

<!--      <div-->
<!--        class="-->
<!--          relative-->
<!--          rounded-bl-lg-->
<!--          border-r-->
<!--          uppercase-->
<!--          p-x-2 p-y-2-->
<!--          fz13-->
<!--          fw500-->
<!--          text-dark-->
<!--        "-->
<!--        :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}"-->
<!--      >-->
<!--        C/E/V-->
<!--        <switcher -->
<!--          class="switcher_owners-row"-->
<!--          id="bunkers-cev"-->
<!--          v-model="getCEV.additional_info.is_row"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="border-r" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}" v-if="getPermissionsByType('show_accounts_in_all_operations')">-->
<!--        <get-trials-->
<!--          v-if="$route.params.subId"-->
<!--          trialKey="SUBLET_Bunkers on Redelivery:Bunkers on Dev./Redev.: C/E/V"-->
<!--        />-->
<!--        <get-trials-->
<!--          v-else-->
<!--          trialKey="Bunkers on Redelivery:Bunkers on Dev./Redev.: C/E/V"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="border-r" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}">-->
<!--        <select class="p-l-2 p-r-6 p-y-2 overflow-ellipsis" v-model="getCEV.additional_info.select" :disabled="!getCEV.additional_info.is_row">-->
<!--          <option value="rate1">Rate*Numbers of day / (365/12)</option>-->
<!--          <option value="rate2">Rate/30* Number of days</option>-->
<!--        </select>-->
<!--      </div>-->
<!--      <div class="border-r relative" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}">-->
<!--        <input-format-number-->
<!--          v-model="getCEV.additional_info.rate"-->
<!--          :inputClass="'p-x-2 p-y-2 '+(!getCEV.additional_info.is_row?'text-gray-text':'')"-->
<!--          placeholder="Enter rate"-->
<!--          :disabled="!getCEV.additional_info.is_row"-->
<!--        ></input-format-number>-->
<!--      </div>-->
<!--      <div class="border-r relative" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}">-->
<!--        <p -->
<!--          class="p-x-2 p-y-2 text-align-right"-->
<!--          v-if="getCEV.additional_info.trs_type == 'revenues'"-->
<!--        >{{getCEV.debit | formatNumberTwoZeroEmpty}}</p>-->
<!--      </div>-->
<!--      <div class="border-r relative" :class="{'bg-gray-light text-gray-text ': !getCEV.additional_info.is_row}">-->
<!--        <p -->
<!--          class="p-x-2 p-y-2 text-align-right"-->
<!--          v-if="getCEV.additional_info.trs_type == 'expenses'"-->
<!--        >{{getCEV.credit | formatNumberTwoZeroEmpty}}</p>-->
<!--      </div>-->
<!--      <div class="border-r">-->
<!--        <trs-type-btn :additional="getCEV.additional_info" active="revenues" />-->
<!--      </div>-->
<!--      <div class="overflow-hidden rounded-br-lg">-->
<!--        <trs-type-btn-->
<!--          class=""-->
<!--          :additional="getCEV.additional_info"-->
<!--          active="expenses"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import editCheckboxes from "@/components/vessels/owners/edit/editCheckboxes";
import getTrials from "@/components/vessels/owners/edit/getTrials";
import trsTypeBtn from "@/components/vessels/owners/edit/trsTypeBtn";
import editBunkersOnTableRow from "@/components/vessels/owners/edit/editBunkersOnTableRow";
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import Switcher from '@/components/reuse/switcher.vue'

export default {
  props: {
    editData: Object,
    bunkersOnData: Array,
    hireData: Array,
    emptyBunkers: Array
  },
  components: {
    editCheckboxes,
    getTrials,
    trsTypeBtn,
    editBunkersOnTableRow,
    inputFormatNumber,
    Switcher
  },
  data() {
    return {
      fuels: ["HSFO", "MGO", "VLSFO_2", "LSMGO_2"],
      fuelObject: {
        bunkers_type: '', // 'DELIVERY'/'REDELIVERY'
        fuel_type: '',
        debit: '',
        credit: '',
        additional_info: {
          price: '',
          quantity: '',
          is_row: true,
          trs_type: '' //'expenses'/'revenues'
        }
      },
    }
  },
  watch: {
    "getDeliverVLSFO.additional_info.price": function () {
      this.getSumm(this.getDeliverVLSFO);
    },
    "getDeliverVLSFO.additional_info.quantity": function () {
      this.getSumm(this.getDeliverVLSFO);
    },
    "getDeliverVLSFO.additional_info.trs_type": function () {
      this.getSumm(this.getDeliverVLSFO);
    },

    "getDeliverLSMGO.additional_info.price": function () {
      this.getSumm(this.getDeliverLSMGO);
    },
    "getDeliverLSMGO.additional_info.quantity": function () {
      this.getSumm(this.getDeliverLSMGO);
    },
    "getDeliverLSMGO.additional_info.trs_type": function () {
      this.getSumm(this.getDeliverLSMGO);
    },

    "getRedeliverVLSFO.additional_info.price": function () {
      this.getSumm(this.getRedeliverVLSFO);
    },
    "getRedeliverVLSFO.additional_info.quantity": function () {
      this.getSumm(this.getRedeliverVLSFO);
    },
    "getRedeliverVLSFO.additional_info.trs_type": function () {
      this.getSumm(this.getRedeliverVLSFO);
    },

    "getRedeliverLSMGO.additional_info.price": function () {
      this.getSumm(this.getRedeliverLSMGO);
    },
    "getRedeliverLSMGO.additional_info.quantity": function () {
      this.getSumm(this.getRedeliverLSMGO);
    },
    "getRedeliverLSMGO.additional_info.trs_type": function () {
      this.getSumm(this.getRedeliverLSMGO);
    },

    // "getCEV.additional_info.trs_type": function () {
    //   this.getCev();
    // },
    // "getCEV.additional_info.rate": function () {
    //   this.getCev();
    // },
    // "getCEV.additional_info.select": function () {
    //   this.getCev();
    // },
    // "getLastHire.additional_info.days": function() {
    //   this.getCev();
    // }
  },
  computed: {
    ...mapGetters(["getPermissionsByType"]),
    getDelivery() {
      let delivery = this.bunkersOnData.filter((el) => {
        return el.bunkers_type == "DELIVERY" && el.fuel_type != "VLSFO" && el.fuel_type != "LSMGO"
      });

      let list = [];
      this.fuels.forEach((el) => {
        list.push(delivery.find((item) => item.fuel_type == el));
      });
      return list;
    },
    getDeliverVLSFO() {
      return this.bunkersOnData.find((el) => {
        return el.bunkers_type == "DELIVERY" && el.fuel_type == "VLSFO";
      });
    },
    getDeliverLSMGO() {
      return this.bunkersOnData.find((el) => {
        return el.bunkers_type == "DELIVERY" && el.fuel_type == "LSMGO";
      });
    },
    getRedelivery() {
      let delivery = this.bunkersOnData.filter((el) => {
        return (
          el.bunkers_type == "REDELIVERY" && el.fuel_type != "VLSFO" && el.fuel_type != "LSMGO"
        );
      });

      let list = [];
      this.fuels.forEach((el) => {
        list.push(delivery.find((item) => item.fuel_type == el));
      });
      return list;
    },
    getRedeliverVLSFO() {
      return this.bunkersOnData.find((el) => {
        return el.bunkers_type == "REDELIVERY" && el.fuel_type == "VLSFO";
      });
    },
    getRedeliverLSMGO() {
      return this.bunkersOnData.find((el) => {
        return el.bunkers_type == "REDELIVERY" && el.fuel_type == "LSMGO";
      });
    },
    // getCEV() {
    //   return this.bunkersOnData.find((el) => {
    //     return el.bunkers_type == "C/E/V";
    //   });
    // },
    getLastHire() {
      if(this.hireData.length)
        return this.hireData[this.hireData.length - 1]
      return {}
    }
  },
  methods: {
    getSumm(type) {
      let creditResult =
        type.additional_info.quantity * type.additional_info.price;

      creditResult = creditResult ? creditResult : "";

      if (type.additional_info.trs_type == "revenues") {
        type.debit = creditResult
        type.credit = "";
      } else {
        type.debit = "";
        type.credit = creditResult
      }
    },
    // getCev() {
    //   let result = this.getCEV.additional_info.rate;
    //   let days = this.hireData.length
    //     ? this.hireData[this.hireData.length - 1].additional_info.days
    //     : 0;
    //   if (days == 0) result = 0;
    //   else if (this.getCEV.additional_info.select == "rate1") {
    //     result = (result * days) / 30.4167;
    //   } else {
    //     result = (result / 30) * days;
    //   }
    //
    //   result = result ? result : "";
    //
    //   if (this.getCEV.additional_info.trs_type == "revenues") {
    //     this.getCEV.debit = result
    //     this.getCEV.credit = ""
    //   } else {
    //     this.getCEV.debit = ""
    //     this.getCEV.credit = result
    //   }
    // },
    onRoll(type) {
      let rollType = type == 'DELIVERY' ? 'isDeliveryRoll' : 'isRedeliveryRoll'
      if(this.editData.additional_info[rollType]){
        this.editData.additional_info[rollType] = false
        this.onDeleteRareFuels(type)
      }else{
        this.editData.additional_info[rollType] = true
        this.onAddRareFuels(type)
      }
    },
    onAddRareFuels(type) {
      let trs_type = type == 'DELIVERY' ? 'expenses' : 'revenues'
      if(this.$route.params.subId){
        trs_type = type == 'DELIVERY' ? 'revenues' : 'expenses'
      }

      this.fuels.forEach(el => {
        let newFuel = JSON.parse(JSON.stringify(this.fuelObject))
        newFuel.fuel_type = el
        newFuel.bunkers_type = type
        newFuel.additional_info.trs_type = trs_type
        this.bunkersOnData.push(newFuel)
      })
    },
    onDeleteRareFuels(type) {
      let newArr = this.bunkersOnData.filter(el => {
        if(el.bunkers_type == type && el.fuel_type != "VLSFO" && el.fuel_type != "LSMGO") return false
        return true
      })
      this.editData.bunkers_delivery = newArr
    },
    async checkIsRow() {
      await this.$nextTick()
      this.bunkersOnData.forEach(row => {
        if(!Object.prototype.hasOwnProperty.call(row.additional_info, 'is_row')){
          this.$set(row.additional_info, 'is_row', true)
        }
      })
    },
    checkIsOldFuels() {
      if(!this.getDeliverLSMGO || !this.getDeliverVLSFO || !this.getRedeliverLSMGO || !this.getRedeliverVLSFO){
        this.editData.bunkers_delivery = this.emptyBunkers
      }
    }
  },
  beforeMount() {
    this.checkIsOldFuels()
    this.checkIsRow()
  }
};
</script>
<style lang="scss" scoped>
.border-radius-down {
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
