<template>
  <section>
    <router-view></router-view>
  </section>
</template>

<script>

export default {
  computed: {
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">
  section {
    background-color: var(--color-bgc-page);
    height: calc(100vh - 56px);
    overflow-y: auto;
  }
</style>
