<template>
  <router-link 
    class="box-border h28 flex items-center p-l-6 p-r-3 p-y-0.5 text-dark-gray fz0 hover:bg-gray-lightest"
    active-class="bg-gray-dark text-dark"
    :to="($route.path.indexOf('archive') >=0 ? '/calculator-archive/' : '/calculator/')+tab.id"
  >
    <div 
      v-if="!isEdit" 
      class="fz14 whitespace-nowrap overflow-ellipsis overflow-hidden" 
      :title="tab.name" 
      v-html="getTabName"
    ></div>

    <input 
      v-else
      type="text" 
      class="fz14 bg-transparent p-0 border-0" 
      ref="name" 
      :value="tab.name"
      @keyup.enter="onBlurName"
      @blur="onBlurName"
    >
    <calc-options
      class="m-l-auto svg-white-dart"
      :options="getOptionPerm()"
      @duplicate="onDuplicate"
      @move-to="onMoveTo"
      @rename="onRename"
      @archive="onArchive"
      v-if="getOptionPerm().length !== 0"
    >
      <svg v-if="$route.path.indexOf('archive') === -1" class="hover:fill-gray-text" width="24" height="24" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M6.44 10.56a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88ZM12 10.56a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88ZM17.56 10.56a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88Z"/></svg>
      <svg v-on:click="onArchive" v-else class="hover:fill-gray-text" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/><path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#2C2C2C"/></svg>
    </calc-options>
    
  </router-link>
</template>

<script>
import calcOptions from '@/components/calc/calcOptions'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

export default {
  props: {
    tab: Object,
    searchText: String
  },
  components: {
    calcOptions
  },
  data() {
    return {
      isEdit: false
    }
  },
  computed: {
    ...mapState({
      activeCreator: state => state.tabs.activeCreator,
    }),
    ...mapGetters(['getLoginData', 'getPermissionsByType']),
    getTabName() {
      let before = '<span class="text-gray">'
      let after = '</span>'
      let name = this.tab.name
      if(this.searchText) {
        let search = this.searchText.toLowerCase()
        let searchIndex = name.indexOf(search)
        if(searchIndex >= 0){
          let firstPart = name.slice(0, searchIndex)
          let searchPart = name.substr(searchIndex, search.length)
          let lastPart = name.slice(searchIndex+search.length)
          name = firstPart + before + searchPart + after + lastPart
        }
      }
      return name
    }
  },
  methods: {
    ...mapActions(['axiosPatchTab', 'axiosCopyTab', 'axiosDeleteTab']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    getOptionPerm() {
      let option = ['duplicate', 'move-to', 'rename', 'archive'];
      if(this.activeCreator.id !== this.getLoginData.data.user.id) {
        option = option.filter(a=> a !== 'move-to' && a !== 'rename')
      }
      if(this.$route.path.indexOf('archive')>=0) {
        option = option.filter(a=> a !== 'duplicate')
      }
      if(!this.getPermissionsByType('archive_and_unarchive_all_users_lists_and_folders') && this.activeCreator.id !== this.getLoginData.data.user.id ) {
        option = option.filter(a=> a !== 'archive')
      }
      return option;
    },
    async onDuplicate() {
      this.showLoad()
      await this.axiosCopyTab({list_pk: this.tab.id})
      this.addTip(`The file “${this.tab.name}” is duplicated`)
      this.hideLoad()
    },
    onMoveTo() {
      this.$emit('move-to', {file: this.tab, folder: null})
    },

    async onRename() {
      this.isEdit = true
      await this.$nextTick()
      this.$refs.name.focus()
      this.$refs.name.select()
    },
    onBlurName(e) {
      if(e.target.value && this.tab.name != e.target.value){
        let name = e.target.value
        if(name.length > 50){
          name = name.slice(0, 50)
          this.addTip(`The maximum number of characters is 50.`)
        }
        this.tab.name = name
        this.axiosPatchTab({
          id: this.tab.id,
          name: name
        })
      }
      this.isEdit = false
    },

    onArchive() {
      this.$emit('on-archive', this.tab)
    },
  },
}
</script>

<style>

</style>