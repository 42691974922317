<template>
  <div>
    <folders-list-links class="mt16" :items="options"></folders-list-links>
  </div>
</template>

<script>
import FoldersListLinks from "@/components/folders/folders-list-links";
import {mapMutations} from "vuex";
export default {
  name: "folders-ports-countries-options",
  components: {FoldersListLinks},
  data() {
    return {
      options: [
        {name: 'Details', last_updated: '', link: {name: 'Folders - Ports Countries Ports Options Details', params: {is_archive_files: false}}},
        {name: 'Berth List', last_updated: '', link: {name: 'Folders - Ports Countries Ports Options Berth List', params: {is_archive_berth_list: false}}},
        {name: 'Contacts', last_updated: '', link: {name: 'Folders - Ports Countries Ports Options Contacts', params: {is_archive_contacts: false}}}
      ]
    }
  },
  methods: {
    ...mapMutations(['setNameSectionFolders']),
  },
  mounted() {
    this.setNameSectionFolders('Ports')

  }
}
</script>

<style scoped>

</style>