<template>
  <div class="port_chat__wrap flex-col">
    <h2 class="m-b-3 fz18 fw500 text-dark">Comments</h2>

    <div class="port_chat h-full">
      <div class="port_chat__msgs">
        <div class="port_chat__msg" v-for="msg in userChat" :key="msg.id">
          <div class="port_chat__msg_data">
            <div class="port_chat__msg_logo">
              <img v-if="msg.avatar" :src="getURL+'/media/'+msg.avatar" class="img-profile" :alt="msg.username">
              <svg v-else width="24" height="24" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"/><path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/></svg>
            </div>
            <div class="port_chat__msg_name">{{msg.username}}</div>
            <div class="port_chat__msg_date">{{getDate(msg.created_at)}}</div>
          </div>
          <div class="port_chat__msg_text">{{msg.text}}</div>
        </div>
      </div>
      <label class="port_chat__seng">
        <textarea class="port_chat__area" v-model="message" @keydown.enter="sendMessage" placeholder="Write a comment"></textarea>
        <button class="port_chat__btn" @click="sendMessage">Send</button>
      </label>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      message: '',
      socket: null,
      connected: false,
      userChat: []
    }
  },
  computed: {
    ...mapGetters(["getChatResponse", "getToken"]),
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    }
  },
  methods: {
    ...mapActions(['axiosPostChat']),
    getMessages() {
      if(this.getChatResponse !== null){
        let idTask = this.getChatResponse.id;
        let data = {
          "chat_id": idTask,
          "token": this.getToken,
          "event_type": "get_history",
          "message": null
        }
        this.socket.send(JSON.stringify(data));
      }
    },
    connectSocked() {
      // let url = 'ws://'+process.env.VUE_APP_URL_SOCKET+':'+process.env.VUE_APP_PORT_CHAT+'/'
      let url = 'wss://'+process.env.VUE_APP_URL_CHAT+"/"


      this.socket = new WebSocket(url);
    },
    sendMessage() {
      if(this.message.length) {
        let idTask = this.getChatResponse.id;
        let data = {
          "chat_id": idTask,
          "token": this.getToken,
          "event_type": "send_message",
          "message": {
            "type": "text",
            "text": this.message,
          }
        }
        this.socket.send(JSON.stringify(data));
        this.message = ''
      }
    },
    getDate(date) {
      return moment(Number(date + '000')).format('DD MMM YYYY, HH:mm')
    }
  },
  mounted() {
    let vm = this

    let idPort = this.$route.params.id
    this.axiosPostChat({type: 'port', id: idPort}).then(()=> {
      vm.getMessages()
    })

    this.connectSocked()

    this.socket.onopen = function() {
      vm.getMessages()
      vm.connected = true
    }

    this.socket.onmessage = function(event) {
      if(JSON.parse(event.data).event_type === "get_history") {
        vm.userChat = JSON.parse(event.data).messages;
      } else {
        vm.getMessages();
      }
    }
    this.socket.onclose = function () {
      vm.connected = false;
      vm.connectSocked();
    }

    this.socket.onerror = function(error) {
      console.warn("socket.onerror in port " + error.message);
    };
  }
}
</script>

<style lang="scss">
  .port_chat{
    &__wrap{
      box-sizing: border-box;
      display: flex;
      height: 100%;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    &__msg{
      &_data{
        display: flex;
        align-items: center;
        margin-bottom: 6px;
      }
      &_logo{
        img{
          width: 24px;
          height: 24px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      &_name{
        margin-left: 8px;
        font-weight: 500;
        font-size: 13px;
      }
      &_date{
        margin-left: auto;
        font-size: 12px;
        color: #9AA3B0;
      }
      &_text{
        margin-bottom: 12px;
        padding: 8px 12px;
        background: var(--color-input);
        border-radius: 4px;
        font-size: 15px;
        line-height: 18px;
      }
    }
    &__seng{
      position: relative;
    }
    &__area{
      height: 108px;
      padding: 8px 12px 40px;
    }
    &__btn{
      position: absolute;
      bottom: 8px;
      right: 12px;
      width: 100px;
      padding: 6px;
      text-align: center;
      color: var(--color-card);
      font-weight: 500;
      font-size: 14px;
      background-color: #319FEF;
      border-radius: 4px;
      transition: background-color 0.3s;
      &:hover{
        background-color: var(--color-blue-dark);
      }
    }
  }
</style>