<template>
  <div class="p-12-24 w100vw box-border">
    <div class="top-panel  d-flex align-items-center">
      <p class="fz20 fw500 text-dark">Sent Mailings</p>
      <mailing-sort-by :type="'mail'" class="ml-auto" v-model="sort"></mailing-sort-by>
      <div class="relative ml12 d-flex align-items-center">
        <svg class="absolute l8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
        </svg>
        <input type="text" class="w240 h32 pl40 bgc-white" placeholder="Search" @focusout="search !== searchCopy ? searchMailings() : null" v-on:keydown.enter="search !== searchCopy ? searchMailings() : null" v-model="search">
      </div>
    </div>

    <mailling-list @clickItem="(e)=> {itemShow = e}" @changeSort="(e)=>{sort = e}" :sort="sort" :isLoad="isLoad" :searchActive="search.length !== 0" :items="maillings" class="mt16"></mailling-list>
    <pagination-panel-default v-if="maillingsNext" :if-active="isLoadNext" @load-more="loadNext"></pagination-panel-default>
    <modal-info-sent-mail v-if="itemShow" :item="itemShow" @close="itemShow = null"></modal-info-sent-mail>
  </div>
</template>

<script>
import MaillingList from "@/components/mailling/mailling-list";
import {mapActions, mapState} from "vuex";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import ModalInfoSentMail from "@/components/mailling/modal-info-sent-mail";
import MailingSortBy from "@/views/mailling/mailing-sort-by";
export default {
  name: "mailling-sent",
  components: {MailingSortBy, ModalInfoSentMail, PaginationPanelDefault, MaillingList},
  computed: {
    ...mapState({
      maillings: state => state.mailling.maillings,
      maillingsNext: state => state.mailling.maillingsNext,
      emitSent: state => state.mailling.emitSent,
    })
  },
  data() {
    return {
      sort: '-updated_at',
      isLoad: false,
      search: '',
      searchCopy: '',
      isLoadNext: false,
      itemShow: null,
    }
  },
  watch: {
    emitSent: function () {
      this.searchMailings();
    },
    sort: function () {
      this.searchMailings()
    }
  },
  methods: {
    ...mapActions(['axiosGetMailingSent', 'axiosGetNextMailing']),
    openEdit() {

    },
    loadNext() {
      this.isLoadNext = true
      this.axiosGetNextMailing().then(()=> {
        this.isLoadNext = false
      })
    },
    searchMailings() {
      this.typeModal = 'create'
      this.searchCopy = this.search
      this.isLoad = true
      this.axiosGetMailingSent({search: this.search, ordering: this.sort}).then(()=> {
        this.isLoad = false
      })
    },
  },
  mounted() {

  },
  beforeMount() {
    this.searchMailings()

  }
}
</script>

<style scoped>

</style>