export default {
    actions: {

    },
    mutations: {
        setSocket(state, data) {
            state.socket = data;
        },
        getActivitySocket(state, data) {
            let form = {
                "token": data.token,
                "event_type": "get_activities",
                "activity": {
                    "page": data.name
                }
            }
            if( state.socket !== null)
            state.socket.send(JSON.stringify(form));
        },


    },
    state: {
        socket: null,
    },
    getters: {
        getSocket(state) {
            return state.socket;
        },
    },
}
