<template>
  <div class="counterparties-add_bottom">
    <div class="container counterparties-add_container">
      <div class="counterparties-add_bottom__btns">
        <button type="button" class="btn btn-close text-dark1" @click="goBack">Cancel</button>
        <button type="button" class="btn btn-next" v-if="isEdit" @click="save">Save</button>
        <button type="button" class="btn btn-next" v-else @click="save">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    insideBlockTypeModal: {
      type: Boolean,
    }
  },
  methods: {
    goBack() {
      if(!this.insideBlockTypeModal) {
        if(window.history.length > 1){
          if(this.$route.query.from == 'vessels'){
            if(this.$route.query.vessel){
              if(this.$route.query.subcontract){
                if(this.$route.query.subcontract == 'voyage'){ // create contract
                  this.$router.push(`/vessels/${this.$route.query.vessel}/open/charterers?from=counterparties&newcharterer=voyage`)
                }else if(this.$route.query.subcontract == 'sublet'){ // create contract
                  this.$router.push(`/vessels/${this.$route.query.vessel}/open/charterers?from=counterparties&newcharterer=sublet`)
                }else // edit contract
                  this.$router.push(`/vessels/${this.$route.query.vessel}/open/charterers/${this.$route.query.subcontract}/details?from=counterparties`)
              }else // edit vessel
                this.$router.push(`/vessels/${this.$route.query.vessel}/open/detail/?from=counterparties`)
            }else // create vessel
              this.$router.push('/vessels/actual/?from=counterparties')
          }else if(this.$route.query.from == 'workspace'){
            this.$router.push(`/vessel-task/${this.$route.query.id}/workspace/?from=counterparties&id=${this.$route.query.rowid}&index=${this.$route.query.index}`)
          }else
            this.$router.go(-1)
        }else{
          this.$router.push('/counterparties')
        }
      } else {
        this.$emit('close')
      }

    },
    save() {
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss">
.counterparties-add_bottom{
  width: 100%;
  margin-top: 25px;
  padding-bottom: 4px;
  background: var(--color-modal);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  .counterparties-add_container{
    margin-left: auto;
    margin-right: auto;
    padding-top: 4px;
  }
  &__btns{
    display: flex;
    justify-content: space-between;
    .btn-close{
      min-width: none;
    }
    .btn-next{
      min-width: 266px;
      padding: 10px;
      border-radius: 4px;
      font-weight: 500;
      background-color: #319FEF;
      color: #fff;
    }
  }
}
</style>