var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.bunkersData.additional_info.is_vlsfo && (_vm.bunkersData.additional_info.vlsfo_quantity ||
    _vm.bunkersData.additional_info.vlsfo_price ||
    _vm.bunkersData.vlsfo_debit ||
    _vm.bunkersData.vlsfo_credit)) ||
    (_vm.bunkersData.additional_info.is_lsmgo && (_vm.bunkersData.additional_info.lsmgo_quantity ||
    _vm.bunkersData.additional_info.lsmgo_price ||
    _vm.bunkersData.lsmgo_debit ||
    _vm.bunkersData.lsmgo_credit)) ||
    _vm.bunkersData.additional_info.comment)?_c('div',{staticClass:"border border-dark-gray m-b-4 rounded-lg overflow-hidden"},[_c('div',{staticClass:"vessel_owners__table-wrap"},[_vm._m(0),_c('div',{staticClass:"vessel_owners__table"},[_c('div',{staticClass:"bg-white-dark border-t border-r"}),_c('div',{staticClass:"bg-white-dark border-t border-r text-gray-text fz13 p-x-2 p-y-1"},[_vm._v("Qntty, mt")]),_c('div',{staticClass:"bg-white-dark border-t border-r text-gray-text fz13 p-x-2 p-y-1"},[_vm._v("Price, "+_vm._s(_vm.activeCurrency)+"/mt")]),_c('div',{staticClass:"bg-white-dark border-t border-r"}),_c('div',{staticClass:"bg-white-dark border-t"})]),(_vm.bunkersData.additional_info.is_vlsfo && (_vm.bunkersData.additional_info.vlsfo_quantity ||
        _vm.bunkersData.additional_info.vlsfo_price ||
        _vm.bunkersData.vlsfo_debit ||
        _vm.bunkersData.vlsfo_credit))?_c('div',{staticClass:"vessel_owners__table"},[_c('div',{staticClass:"border-t border-r p-x-3 p-y-2 text-dark"},[_vm._v("VLSFO")]),_c('div',{staticClass:"border-t border-r p-x-2 p-y-2 text-dark"},[_vm._v(_vm._s(_vm._f("formatNumberThreeZeroEmpty")(_vm.bunkersData.additional_info.vlsfo_quantity)))]),_c('div',{staticClass:"border-t border-r p-x-2 p-y-2 text-dark"},[_vm._v(_vm._s(_vm._f("formatNumberTwoZeroEmpty")(_vm.bunkersData.additional_info.vlsfo_price)))]),_c('div',{staticClass:"border-t border-r p-x-2 p-y-2 text-dark text-align-right amount"},[_vm._v(_vm._s(_vm._f("formatNumberTwoZeroEmpty")(_vm.bunkersData.vlsfo_debit)))]),_c('div',{staticClass:"border-t p-x-2 p-y-2 text-align-right amount"},[_vm._v(_vm._s(_vm._f("formatNumberTwoZeroEmpty")(_vm.bunkersData.vlsfo_credit)))])]):_vm._e(),(_vm.bunkersData.additional_info.is_lsmgo && (_vm.bunkersData.additional_info.lsmgo_quantity ||
        _vm.bunkersData.additional_info.lsmgo_price ||
        _vm.bunkersData.lsmgo_debit ||
        _vm.bunkersData.lsmgo_credit))?_c('div',{staticClass:"vessel_owners__table"},[_c('div',{staticClass:"border-t border-r p-x-3 p-y-2 text-dark"},[_vm._v("LSMGO")]),_c('div',{staticClass:"border-t border-r p-x-2 p-y-2 text-dark"},[_vm._v(_vm._s(_vm._f("formatNumberThreeZeroEmpty")(_vm.bunkersData.additional_info.lsmgo_quantity)))]),_c('div',{staticClass:"border-t border-r p-x-2 p-y-2 text-dark"},[_vm._v(_vm._s(_vm._f("formatNumberTwoZeroEmpty")(_vm.bunkersData.additional_info.lsmgo_price)))]),_c('div',{staticClass:"border-t border-r p-x-2 p-y-2 text-dark text-align-right amount"},[_vm._v(_vm._s(_vm._f("formatNumberTwoZeroEmpty")(_vm.bunkersData.lsmgo_debit)))]),_c('div',{staticClass:"border-t p-x-2 p-y-2 text-align-right amount"},[_vm._v(_vm._s(_vm._f("formatNumberTwoZeroEmpty")(_vm.bunkersData.lsmgo_credit)))])]):_vm._e(),(_vm.bunkersData.additional_info.comment)?_c('div',{staticClass:"vessel_owners__table"},[_c('div',{staticClass:"border-t border-r p-x-3 p-y-2 text-dark"},[_vm._v("Comment")]),_c('div',{staticClass:"border-t border-r p-x-2 p-y-2 text-dark colspan-2-4"},[_vm._v(_vm._s(_vm.bunkersData.additional_info.comment))]),_c('div',{staticClass:"border-t border-r"}),_c('div',{staticClass:"border-t"})]):_vm._e()])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vessel_owners__table"},[_c('div',{staticClass:"colspan-1-6 bg-blue-dark uppercase p-x-3 p-y-2 fz13 fw500 text-white"},[_vm._v("Bunker supply by owns")])])}]

export { render, staticRenderFns }