<template>
  <div class="w-1/3 flex flex-col">
    <div class="flex items-center p-l-3 p-r-2 p-y-1.5 bg-gray-dark border-b border-dark-gray rounded-tr-lg">
      <h2 class="m-r-auto fz13 fw500 uppercase">Port of Discharging</h2>
      <label v-if="!exportPdf" :for="'disch_wk'+detail.id" class="m-r-2 fz13 text-dark-gray cursor-pointer">Wk Cranes</label>
      <switcher v-if="!exportPdf" :id="'disch_wk'+detail.id" v-model="detail.discharging_wk_cranes"/>
    </div>

    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Disch. Port*</div>
      <div class="w-7/12 bg-white relative svg-white-dart">
        <default-select
          class="w-full default_select__in_calc default_select__fz13 multiselect_fz13"
          :id="'discharging_port'+detail.id"
          :options="alowedPorts"
          :allowEmpty="true"
          :closeOnSelect="true"
          :searchable="false"
          :trackBy="'route_id'"
          placeholder=""
          v-model="detail.discharging_port"
          @on-select="onSelectPort"
        />
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="flex items-center w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">
        {{detail.discharging_rate_type == 'RATE' ? 'Rate':'Days'}}
        <button 
          type="button" 
          class="m-l-auto text-gray-text hover:text-blue-dark fz13 fw500" 
          @click="detail.discharging_rate_type = detail.discharging_rate_type == 'RATE'?'DAYS': 'RATE'"
        >
          {{detail.discharging_rate_type == 'RATE' ? 'or Days':'or Rate'}}
        </button>
      </div>
      <div class="w-7/12 bg-white relative">
        <input 
          type="number" 
          class="p-y-1.5 p-l-2 p-r-14 border-0 bg-transparent rounded-none fz13" 
          v-model.number="detail.discharging_rate" 
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
          @blur="onBlurDetailNumber('discharging_rate')"
        >
        <span class="absolute centered-y right-2 text-gray-text fz13" v-if="detail.discharging_rate_type == 'RATE'">mt/day</span>
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Terms</div>
      <div class="w-7/12 bg-white">
        <default-select
          class="w-full default_select__in_calc default_select__fz13 multiselect_fz13"
          :id="'discharging_terms'+detail.id"
          :options="termsList.map(el=>el.name+' - '+el.coof)"
          :allowEmpty="true"
          :closeOnSelect="true"
          :searchable="false"
          :trackBy="null"
          placeholder=""
          v-model="detail.discharging_terms"
        />
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Turn time</div>
      <div class="w-7/12 bg-white relative">
        <input 
          type="number" 
          class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13" 
          v-model.number="detail.discharging_turn_time"
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
          @blur="onBlurDetailNumber('discharging_turn_time')"
        >
        <span class="absolute centered-y right-2 text-gray-text fz13">h</span>
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Liner Out</div>
      <div class="w-7/12 bg-white relative">
        <input 
          type="number" 
          class="p-y-1.5 p-l-2 p-r-10 border-0 bg-transparent rounded-none fz13" 
          v-model.number="detail.discharging_liner"
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
          @blur="onBlurDetailNumber('discharging_liner')"
        >
        <span class="absolute centered-y right-2 text-gray-text fz13">$/mt</span>
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Draft</div>
      <div class="w-7/12 bg-white relative">
        <input 
          type="number" 
          class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13" 
          v-model.number="detail.discharging_draft"
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
          @blur="onBlurDetailNumber('discharging_draft')"
        >
        <span class="absolute centered-y right-2 text-gray-text fz13">m</span>
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Water density</div>
      <div class="w-7/12 bg-white relative svg-white-dart" v-click-outside="onBlurWater">
        <input
          type="text"
          class="p-y-1.5 p-l-2 p-r-6 border-0 bg-transparent rounded-none fz13"
          v-model="detail.discharging_water_density"
          @focus="onFocusWater"
          @blur="onBlurWater"
          @keyup.enter="$event.target.blur()"
          ref="waterInput"
        >
        <svg @click="onFocusWater" class="absolute right-2 centered-y cursor-pointer" width="12" height="12" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M2.724 4.4a.3.3 0 0 0-.212.512l3.276 3.276a.3.3 0 0 0 .424 0l3.276-3.276a.3.3 0 0 0-.212-.512H2.724Z"/></svg>
        <default-select
          class="absolute left-0 top-full w-full default_select__hide_main_input multiselect_fz13"
          :id="'discharging_water'+detail.id"
          :options="calcWaterDensity"
          :allowEmpty="true"
          :closeOnSelect="true"
          :searchable="false"
          trackBy="name"
          v-model="waterDensityObj"
          @on-select="onSelectWater"
          ref="waterDrop"
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-5/12 p-x-3 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500">Seasonal mark</div>
      <div class="w-7/12 bg-white relative rounded-br-lg">
        <default-select
          class="w-full default_select__in_calc capitalize-child default_select__fz13 multiselect_fz13"
          :id="'discharging_seasonal'+detail.id"
          :options="['summer', 'winter', 'tropical']"
          :allowEmpty="true"
          :closeOnSelect="true"
          :searchable="false"
          trackBy=""
          placeholder=""
          openDirection="top"
          v-model="detail.discharging_seasonal_mark"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Switcher from '@/components/reuse/switcher'
import defaultSelect from '@/components/reuse/defaultSelect'
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    detail: Object,
    alowedPorts: Array,
    exportPdf: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Switcher,
    defaultSelect
  },
  data() {
    return {
      isPortLoading: false,
      waterDensityObj: null
    }
  },
  computed: {
    ...mapState({
      termsList: state => state.tabs.termsList,
      calcWaterDensity: state => state.tabs.calcWaterDensity
    })
  },
  methods: {
    ...mapActions(['onlyNumber']),

    onSelectPort() {
      if(this.detail.discharging_port)
        this.detail.discharging_port_name = '#'+this.detail.discharging_port.route_id
      else
        this.detail.discharging_port_name = null
    },

    onSelectWater() {
      if(this.waterDensityObj){
        this.detail.discharging_water_density = this.waterDensityObj.name
      }else
        this.detail.discharging_water_density = null
      this.$refs.waterInput.blur()
    },
    onFocusWater() {
      this.$refs.waterDrop.openDropdown()
    },
    onBlurWater() {
      this.$refs.waterDrop.closeDropdown()
      if(this.waterDensityObj && (this.waterDensityObj.name != this.detail.discharging_water_density)){
        this.waterDensityObj = null
        if(!parseFloat(this.detail.discharging_water_density))
          this.detail.discharging_water_density = 1.25
      }
    },
    onBlurDetailNumber(key) {
      if(!this.detail[key] || typeof this.detail[key] != 'number'){
        this.detail[key] = null
      }
    }
  },
}
</script>

<style lang="scss">
</style>