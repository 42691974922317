<template>
  <div 
    class="vessel_owners__table_wide bg-white"
    :class="{
      'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
    }"
  >
    <div class="border-b border-r p-x-2 p-y-1 fw500 position-relative">
      <drop-select-number
          :indexSelect="hire.index_hire_selected"
          class="z-index-10"
          :count="lengthSpeed"
          @selectIndex="selectIndex"
      ></drop-select-number>
      <div
        class="flex items-center cursor-pointer text-blue-dark fw500 flex justify-between"
      >
        <button v-if="(''+hire.index_hire_selected).length && hire.index_hire_selected > -1" class="flex items-center cursor-pointer text-blue-dark fw500">
          Hire #{{1+hire.index_hire_selected}}
        </button>
        <button v-else-if="(''+hire.index_hire_selected).length && hire.index_hire_selected === -2" class="flex items-center cursor-pointer text-blue-dark fw500">
          Custom
        </button>
        <button v-else class="flex items-center cursor-pointer text-gray fw500">
          Select Hire
          <svg class="ml5" width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.402344L4.00001 4.40234L8 0.402344H0Z" fill="#2C2C2C"/>
          </svg>
        </button>

        <button
            v-on:click="$emit('on-delete')"
            class="flex items-center cursor-pointer text-blue-dark fw500 position-relative z-index-10"
        >
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"
                fill="#094172"
            />
          </svg>
        </button>

      </div>
    </div>
<!--    <div class="border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>-->
<!--    <div class="border-b border-r colspan-3-5">-->
<!--      <input-->
<!--        class="p-x-2 p-y-2"-->
<!--        type="text"-->
<!--        maxlength="255"-->
<!--        :placeholder="'Write a title for speed claim #'+(1+index)"-->
<!--        v-model="hire.additional_info.title"-->
<!--      />-->
<!--    </div>-->
    <div class="border-b border-r colspan-2-6">
      <input
          class="p-x-2 p-y-2"
          type="text"
          maxlength="255"
          :placeholder="'Off-hire: Write a title of hire'"
          v-model="hire.additional_info.title"
      />
    </div>
    <div class="colspan-6-9 border-r border-b relative">
      <input-format-number
          v-if="hire.index_hire_selected === -2"
          v-model="hire.additional_info.hire_rate"
          inputClass="p-l-2 p-r-24 p-y-2"
          placeholder="Enter rate"
          :rightPlaceholder="`Rate,&nbsp;${activeCurrency}/day`"
          @input="getSpeed"
      ></input-format-number>
      <input-format-number
          v-if="hire.index_hire_selected >= 0"
          :disabled="true"
          :value="hireData[this.hire.index_hire_selected].additional_info.rate"
          inputClass="p-l-2 p-r-24 p-y-2"
          placeholder="Enter rate"
          :rightPlaceholder="`Rate,&nbsp;${activeCurrency}/day`"
      ></input-format-number>
    </div>
<!--    <div class="border-b border-r"></div>-->
<!--    <div class="border-b border-r"></div>-->
<!--    <div class="border-b border-r"></div>-->
<!--    <div class="border-b"></div>-->

    <div class="border-b border-r p-x-2 p-y-2 text-dark relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_days}">
      Time Lost
      <switcher 
        class="switcher_owners-row"
        :id="'offhire-days-'+index"
        v-model="hire.additional_info.is_days"
      />
    </div>
    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_days}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
      <get-trials v-if="$route.params.subId" trialKey="SUBLET_Speed Claim:Time Lost"/>
      <get-trials v-else trialKey="Speed Claim:Time Lost"/>
    </div>
    <div class="border-b border-r relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_days}">
      <input-format-number
          :fractionDigits="4"
        v-model="hire.additional_info.hours"
        :inputClass="'p-l-2 p-r-8 p-y-2 '+(!hire.additional_info.is_days?'text-gray-text':'')"
        placeholder="Number of hours"
        rightPlaceholder="hrs"
        :disabled="!hire.additional_info.is_days"
      ></input-format-number>
    </div>
    <div class="border-b border-r p-x-2 p-y-2 text-dark relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_days}">
      {{hire.additional_info.days | formatNumberThreeZeroEmpty}}
      <span class="absolute top-2 right-3 text-gray-text fz14">Days</span>
    </div>
    <div class="border-b border-r relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_days}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_days == 'revenues'"
      >{{hire.time_lost_debit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-b border-r relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_days}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_days == 'expenses'"
      >{{hire.time_lost_credit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-b border-r">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_days" active="revenues" />
    </div>
    <div class="border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_days" active="expenses" />
    </div>

    <div class="border-r border-b p-x-2 p-y-2 text-dark fw500 relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}">
      Commission
      <switcher 
        class="switcher_owners-row"
        :id="'offhire-commission-'+index"
        v-model="hire.additional_info.is_commission"
      />
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
      <get-trials v-if="$route.params.subId" trialKey="SUBLET_Speed Claim:Commission"/>
      <get-trials v-else trialKey="Speed Claim:Commission"/>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}"></div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}">
      <input-format-number
        v-model="hire.additional_info.commision"
        :inputClass="'p-l-2 p-r-6 p-y-2 '+(!hire.additional_info.is_commission?'text-gray-text':'')"
        placeholder="Enter percentage"
        rightPlaceholder="%"
        :disabled="!hire.additional_info.is_commission"
      ></input-format-number>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_commision == 'revenues'"
      >{{hire.commision_debit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_commision == 'expenses'"
      >{{hire.commision_credit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-r border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_commision" active="revenues" />
    </div>
    <div class="border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_commision" active="expenses" />
    </div>

    <div class="bg-white-dark border-b border-r"></div>
    <div class="bg-white-dark border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="bg-white-dark border-b border-r"></div>
    <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Rate, {{activeCurrency}}/PMPR</div>
    <div class="bg-white-dark border-b border-r"></div>
    <div class="bg-white-dark border-b border-r"></div>
    <div class="bg-white-dark border-b border-r"></div>
    <div class="bg-white-dark border-b"></div>

    <div class="border-r border-b p-x-2 p-y-2 text-dark fw500 relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_cev}">
      C/E/V
      <switcher 
        class="switcher_owners-row"
        :id="'offhire-cev-'+index"
        v-model="hire.additional_info.is_cev"
      />
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_cev}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
      <get-trials v-if="$route.params.subId" trialKey="SUBLET_Speed Claim:C/E/V"/>
      <get-trials v-else trialKey="Speed Claim:C/E/V"/>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_cev}">
      <select class="p-l-2 p-r-6 p-y-2 overflow-ellipsis" v-model="hire.additional_info.select" @change="getCEV" :disabled="!hire.additional_info.is_cev">
        <option value="rate1">Rate*Numbers of day / (365/12)</option>
        <option value="rate2">Rate/30* Number of days</option>
      </select>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_cev}">
      <input-format-number
        v-model="hire.additional_info.rate"
        :inputClass="'p-x-2 p-y-2 '+(!hire.additional_info.is_cev?'text-gray-text':'')"
        placeholder="Enter rate"
        :disabled="!hire.additional_info.is_cev"
      ></input-format-number>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_cev}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_cev == 'revenues'"
      >{{hire.cev_debit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-b border-r relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_cev}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_cev == 'expenses'"
      >{{hire.cev_credit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-b border-r">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_cev" active="revenues" />
    </div>
    <div class="border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_cev" active="expenses" />
    </div>

    <div class="bg-white-dark border-b border-r p-x-2 p-y-1 fw500">Bunkers consumed during off-hire</div>
    <div class="bg-white-dark border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Qntty, mt</div>
    <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Price, {{activeCurrency}}/mt</div>
    <div class="bg-white-dark border-b border-r"></div>
    <div class="bg-white-dark border-b border-r"></div>
    <div class="bg-white-dark border-b border-r"></div>
    <div class="bg-white-dark border-b"></div>

    <div class="border-r border-b uppercase p-x-2 p-y-2 relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_hsfo}">
      VLSFO
      <switcher 
        class="switcher_owners-row"
        :id="'offhire-hsfo-'+index"
        v-model="hire.additional_info.is_hsfo"
      />
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_hsfo}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
      <get-trials v-if="$route.params.subId" trialKey="SUBLET_Speed Claim:HSFO"/>
      <get-trials v-else trialKey="Speed Claim:HSFO"/>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_hsfo}">
      <input-format-number
          :fractionDigits="3"
        v-model="hire.additional_info.hsfo_quantity"
        :inputClass="'p-x-2 p-y-2 '+(!hire.additional_info.is_hsfo?'text-gray-text':'')"
        placeholder="Enter quantity"
        :disabled="!hire.additional_info.is_hsfo"
      ></input-format-number>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_hsfo}">
      <input-format-number
        v-model="hire.additional_info.hsfo_price"
        :inputClass="'p-x-2 p-y-2 '+(!hire.additional_info.is_hsfo?'text-gray-text':'')"
        placeholder="Enter price"
        :disabled="!hire.additional_info.is_hsfo"
      ></input-format-number>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_hsfo}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_hsfo == 'revenues'"
      >{{hire.hsfo_debit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-b border-r relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_hsfo}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_hsfo == 'expenses'"
      >{{hire.hsfo_credit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-b border-r">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_hsfo" active="revenues" />
    </div>
    <div class="border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_hsfo" active="expenses" />
    </div>

    <div class="border-r border-b uppercase p-x-2 p-y-2 relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_lsmgo}">
      LSMGO
      <switcher 
        class="switcher_owners-row"
        :id="'offhire-lsmgo-'+index"
        v-model="hire.additional_info.is_lsmgo"
      />
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_lsmgo}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
      <get-trials v-if="$route.params.subId" trialKey="SUBLET_Speed Claim:LSMGO"/>
      <get-trials v-else trialKey="Speed Claim:LSMGO"/>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_lsmgo}">
      <input-format-number
          :fractionDigits="3"
        v-model="hire.additional_info.lsmgo_quantity"
        :inputClass="'p-x-2 p-y-2 '+(!hire.additional_info.is_lsmgo?'text-gray-text':'')"
        placeholder="Enter quantity"
        :disabled="!hire.additional_info.is_lsmgo"
      ></input-format-number>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_lsmgo}">
      <input-format-number
        v-model="hire.additional_info.lsmgo_price"
        :inputClass="'p-x-2 p-y-2 '+(!hire.additional_info.is_lsmgo?'text-gray-text':'')"
        placeholder="Enter price"
        :disabled="!hire.additional_info.is_lsmgo"
      ></input-format-number>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_lsmgo}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_lsmgo == 'revenues'"
      >{{hire.lsmgo_debit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-b border-r relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_lsmgo}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_lsmgo == 'expenses'"
      >{{hire.lsmgo_credit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-b border-r">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_lsmgo" active="revenues" />
    </div>
    <div class="border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_lsmgo" active="expenses" />
    </div>

    <div class="border-r p-x-2 p-y-2 text-dark fz13">Comment</div>
    <div class="border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="border-r colspan-3-5">
      <input
        class="p-x-2 p-y-2"
        type="text"
        maxlength="255"
        placeholder="Write a comment"
        v-model="hire.additional_info.comment"
      />
    </div>
    <div class="border-r"></div>
    <div class="border-r"></div>
    <div class="border-r"></div>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import getTrials from "@/components/vessels/owners/edit/getTrials";
import trsTypeBtn from "@/components/vessels/owners/edit/trsTypeBtn";
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import Switcher from '@/components/reuse/switcher.vue'
import DropSelectNumber from "@/components/vessels/owners/dropNumber/drop-select-number";

export default {
  props: {
    activeCurrency: String,
    speedData: Array,
    hireData: Array,
    hire: Object,
    index: Number,
    lengthSpeed: Number,
  },
  components: {
    DropSelectNumber,
    getTrials,
    trsTypeBtn,
    inputFormatNumber,
    Switcher
  },
  watch: {
    "hire.additional_info.hours": function() {
      this.getDays()
    },
    "hire.additional_info.days": function() {
      this.getSpeed(), this.getCEV();
    },
    "hire.additional_info.trs_type_days": function() {
      this.getSpeed();
    },
    "hire.additional_info.select": function () {
      this.getCEV();
    },
    hireData: {
      deep: true,
      handler: function() {
        this.getSpeed();
      }
    },

    "hire.time_lost_debit": function() {
      this.getCommision();
    },
    "hire.additional_info.commision": function() {
      this.getCommision();
    },
    "hire.additional_info.trs_type_commision": function() {
      this.getCommision();
    },

    "hire.additional_info.trs_type_cev": function() {
      this.getCEV();
    },
    "hire.additional_info.rate": function() {
      this.getCEV();
    },

    "hire.additional_info.trs_type_hsfo": function() {
      this.getHSFO();
    },
    "hire.additional_info.hsfo_price": function() {
      this.getHSFO();
    },
    "hire.additional_info.hsfo_quantity": function() {
      this.getHSFO();
    },

    "hire.additional_info.trs_type_lsmgo": function() {
      this.getLSMGO();
    },
    "hire.additional_info.lsmgo_price": function() {
      this.getLSMGO();
    },
    "hire.additional_info.lsmgo_quantity": function() {
      this.getLSMGO();
    }
  },
  computed: {
    ...mapGetters(["getPermissionsByType"])
  },
  methods: {
    selectIndex(index) {
      this.$emit('selectIndex', {indexThis: this.index, indexSelect: index})
      this.getSpeed();
    },
    getDays() {
      let result = this.hire.additional_info.hours / 24;
      this.hire.additional_info.days = result;
      return result;
    },
    getSpeed() {
      if(this.hire.index_hire_selected >= 0 ? this.hireData[this.hire.index_hire_selected] : this.hire.additional_info.hire_rate) {
        let hireObj = this.hire.index_hire_selected >= 0 ? this.hireData[this.hire.index_hire_selected].additional_info.rate : this.hire.additional_info.hire_rate

        // let summ = hireObj.additional_info.rate ? hireObj.additional_info.rate : 0
        let summ = hireObj ? hireObj : 0
        let result = this.hire.additional_info.days * summ;
        result = result ? result : "";

        if(this.hire.additional_info.trs_type_days == 'revenues'){
          this.hire.time_lost_debit = result
          this.hire.time_lost_credit = ''
        }else{
          this.hire.time_lost_debit = ''
          this.hire.time_lost_credit = result
        }
      } else {
        this.hire.time_lost_credit = ''
        this.hire.time_lost_debit = ''
      }
    },
    getCommision() {
      let summ = this.hire.additional_info.trs_type_days == 'revenues' ? this.hire.time_lost_debit : this.hire.time_lost_credit
      let result = summ * (this.hire.additional_info.commision / 100);
      result = result ? result : "";

      if(this.hire.additional_info.trs_type_commision == 'revenues'){
        this.hire.commision_debit = result
        this.hire.commision_credit = ''
      }else{
        this.hire.commision_debit = ''
        this.hire.commision_credit = result
      }
    },
    getCEV() {
      let result = this.hire.additional_info.select === 'rate2' ? ((this.hire.additional_info.rate/30) *  (this.hire.additional_info.days)) : (this.hire.additional_info.rate * this.hire.additional_info.days) / (365 / 12);
      result = result ? result : "";

      if(this.hire.additional_info.trs_type_cev == 'revenues'){
        this.hire.cev_debit = result
        this.hire.cev_credit = ''
      }else{
        this.hire.cev_debit = ''
        this.hire.cev_credit = result
      }
    },
    getHSFO() {
      let result =
        this.hire.additional_info.hsfo_price *
        this.hire.additional_info.hsfo_quantity;
      result = result ? result : "";

      if(this.hire.additional_info.trs_type_hsfo == 'revenues'){
        this.hire.hsfo_debit = result
        this.hire.hsfo_credit = ''
      }else{
        this.hire.hsfo_debit = ''
        this.hire.hsfo_credit = result
      }
    },
    getLSMGO() {
      let result =
        this.hire.additional_info.lsmgo_price *
        this.hire.additional_info.lsmgo_quantity;
      result = result ? result : "";

      if(this.hire.additional_info.trs_type_lsmgo == 'revenues'){
        this.hire.lsmgo_debit = result
        this.hire.lsmgo_credit = ''
      }else{
        this.hire.lsmgo_debit = ''
        this.hire.lsmgo_credit = result
      }
    },
    checkIsRow() {
      if(!Object.prototype.hasOwnProperty.call(this.hire.additional_info, 'is_days')){
        this.$set(this.hire.additional_info, 'is_days', true)
      }
      if(!Object.prototype.hasOwnProperty.call(this.hire.additional_info, 'is_commission')){
        this.$set(this.hire.additional_info, 'is_commission', true)
      }
      if(!Object.prototype.hasOwnProperty.call(this.hire.additional_info, 'is_cev')){
        this.$set(this.hire.additional_info, 'is_cev', true)
      }
      if(!Object.prototype.hasOwnProperty.call(this.hire.additional_info, 'is_hsfo')){
        this.$set(this.hire.additional_info, 'is_hsfo', true)
      }
      if(!Object.prototype.hasOwnProperty.call(this.hire.additional_info, 'is_lsmgo')){
        this.$set(this.hire.additional_info, 'is_lsmgo', true)
      }
    }
  },
  mounted() {
    this.checkIsRow()
    this.hire.additional_info.select = this.hire.additional_info.select ? this.hire.additional_info.select : 'rate1'
  }
};
</script>

<style>
</style>