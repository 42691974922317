<template>
  <modal-slots :textForSecondButton="typePage === 'mailing' ? 'Send' : 'Save'" :noCloseAfterApply="true" :noCloseFilter="true" :showMod="true" @close="close" @apply="typePage === 'mailing' ? applyMail() : apply()">
    <template v-slot:title>
      <p class="fz19 fw500 text-dark">{{type === 'create' ? 'Create' : 'Edit'}} Mailing {{typePage === 'mailing' ? 'List' : 'Template'}}</p>
    </template>
    <div class="content">
      <p class="fz13 text-gray">{{typePage === 'mailing' ? 'Mailing Template' : 'Template Name'}}</p>
      <async-select
          :key="showStatus + 'dsf3453'"
          v-if="typePage === 'mailing'"
          class="w-full mt4"
          :id="'port-'"
          :allowEmpty="false"
          :closeOnSelect="true"
          :clearOnSelect="true"
          v-model="template"
          url="/mailing/templates/"
          :params="{is_archived: false}"
          watchKey="name"
          :placeholder="'Optional'"
      />
      <input v-else type="text" class="h36 mt4" :class="{error: errorName}" v-model="form.name">
      <p class="fz13 text-gray mt12">Contact Lists</p>
      <contact-list :key="showStatus + '23ds'" ref="contactList" :class="{'error-contact': errorContactList}" class="mt4" v-model="form.contact_list" :emails="form.emails_list" @changeEmail="(e)=>{form.emails_list = e}"></contact-list>
      <p class="fz13 text-gray mt12">Subject</p>
      <input type="text" class="h36 mt4" v-model="form.subject" :class="{error: errorSubject}">
      <p class="fz13 text-gray mt12">Message Text</p>
      <vue-editor v-model="form.text" class="edit-block-wrap mt4" :class="{error: errorText}"></vue-editor>
      <div class="file-block mt12">
        <vue-dropzone class="" :options="dropzoneOptions" :useCustomSlot=true ref="dropzone" id="dropzone">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
            <div class="subtitle">...or click to select a file from your computer</div>
          </div>
        </vue-dropzone>
      </div>
      <payment-detail-atachements-file
          class="mt12"
          v-if="form.filesIds.length !== 0"
          :file-props="form.filesIds ? form.filesIds : []"
          :add-file-permission="false"
          :edit-permission="true"
          :delete-file-permission="true"
          :visible-add-file="true"
          :class-for-title="'fz13 text-gray mt12'"
          :normal-size-button="true"
          :disabled-add-new-folder="true"
          @fileDeleted="(e)=> {form.filesIds = form.filesIds.filter(a=> a.id !== e)}"
      >
      </payment-detail-atachements-file>

    </div>
  </modal-slots>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { VueEditor } from "vue2-editor";
import ModalSlots from "@/components/modal/modal-slots";
import ContactList from "@/views/mailling/create-edit-template-mailing/contact-list";
import {mapActions, mapMutations} from "vuex";
import PaymentDetailAtachementsFile from "@/components/paymentDetail/paymentDetailAtachementsFile";
import AsyncSelect from "@/components/reuse/asyncSelect";
export default {
  name: "create-edit-template-mailing",
  components: {AsyncSelect, PaymentDetailAtachementsFile, ContactList, ModalSlots, VueEditor, vueDropzone: vue2Dropzone},
  props: {
    showStatus: Boolean,
    type: {
      type: String,
      default: 'create'
    },
    typePage: {
      type: String,
      default: 'template'
    }
  },
  watch: {
    template: function () {
      if(this.template) {
        this.form.subject = this.template.subject
        this.form.contact_list = this.template.contact_list
        this.form.emails_list = this.template.emails_list
        this.form.text = this.template.text
        this.form.filesIds = this.template.files
      }
    }
  },
  data() {
    return {
      template: null,
      contacts: [],
      text: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      form: {
        contact_list: [],
        name: '',
        emails_list: [],
        subject: '',
        text: '',
        files: [],
        filesIds: [],
      },
      errorName: false,
      errorSubject: false,
      errorText: false,
      idEdit: null,
      errorContactList: false,
    }
  },
  methods: {
    ...mapActions(['axiosCreateMailingTemplate', 'axiosChangeMailingTemplate', 'axiosCreateMailing']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    close() {
      this.$emit('close')
      setTimeout(()=> {
        this.errorName = false
        this.errorText = false
        this.errorSubject = false
        this.errorContactList = false
        this.$refs.dropzone.removeAllFiles(true);
        this.form= {
          contact_list: [],
          name: '',
          emails_list: [],
          subject: '',
          text: '',
          files: [],
          filesIds: [],
        }
        this.template = null;
        this.$refs.contactList.clearText()
      }, 150)
    },
    openEdit(item) {
      this.idEdit = item.id
      this.form.contact_list = item.contact_list
      this.form.name = item.name
      this.form.emails_list = item.emails_list
      this.form.subject = item.subject
      this.form.text = item.text
      this.form.filesIds = item.files
    },
    async apply() {
      if(this.type === 'create') {
        this.errorName = !this.form.name.length;
        // this.errorText = !this.form.text.length;
        // this.errorSubject = !this.form.subject.length;
        if(this.errorText || this.errorName || this.errorSubject) {
          console.log(1)
          return null;
        }
        this.close();
        console.log(2)
        let files = this.$refs.dropzone.getAcceptedFiles();
        let form = {
          contact_list: this.form.contact_list.map(a=> {return a.id}),
          name: this.form.name,
          emails_list: this.form.emails_list,
          subject: this.form.subject,
          text: this.form.text,
          files: files
        }
        this.showLoad()
        await this.axiosCreateMailingTemplate(form)
          this.hideLoad();
          this.$emit('created')
      } else if(this.idEdit) {
        this.errorName = !this.form.name.length;
        // this.errorText = !this.form.text.length;
        // this.errorSubject = !this.form.subject.length;
        if(this.errorText || this.errorName || this.errorSubject) {
          return null;
        }
        this.close();
        let files = this.$refs.dropzone.getAcceptedFiles();
        let form = {
          id: this.idEdit,
          contact_list: this.form.contact_list.map(a=> {return a.id}),
          name: this.form.name,
          emails_list: this.form.emails_list,
          subject: this.form.subject,
          text: this.form.text.replaceAll('<p>', '<p style="margin: 0 !important">').replaceAll('<p class', '<p style="margin: 0 !important" class'),
          files: files,
          filesIds: this.form.filesIds.map(a=> {return a.id})
        }
        this.showLoad()
        await this.axiosChangeMailingTemplate(form)
        this.hideLoad();
        this.$emit('changed')
      }
    },
    async applyMail() {
      // this.errorName = !this.template
      this.errorText = !this.form.text.length;
      this.errorSubject = !this.form.subject.length;
      this.errorContactList = !(this.form.contact_list.length || this.form.emails_list.length)
      if(this.errorText || this.errorSubject || this.errorContactList) {
        return null;
      }
      this.close();
      let files = this.$refs.dropzone.getAcceptedFiles();
      let form = {
        id: this.idEdit,
        contact_list: this.form.contact_list.map(a=> {return a.id}),
        name: this.form.name,
        emails_list: this.form.emails_list,
        subject: this.form.subject,
        text: this.form.text.replaceAll('<p>', '<p style="margin: 0 !important">').replaceAll('<p class', '<p style="margin: 0 !important" class'),
        files: files,
        filesIds: this.form.filesIds.map(a=> {return a.id}),
        template: this.template ? this.template.id : null
      }
      let name = this.template ? this.template.name : ''
      this.showLoad()
      await this.axiosCreateMailing(form)
      this.addTip(`Mailing "${name}" successfully sent to contacts`)
      this.hideLoad();
      this.$emit('changed')
    }
  }
}
</script>

<style scoped lang="scss">
  .content {
    width: 720px;
    padding: 16px 24px 24px 24px;
    box-sizing: border-box;
  }
  .file-block {
    /deep/ {
      .vue-dropzone {
        display: flex;
        padding: 0;
        padding-top: 41px;
        flex-direction: column;
        min-height: max-content;
        border: 1px solid var(--color-border);
        border-radius: 4px;
        align-items: center;
        position: relative;
        &:hover {
          background-color: var(--color-input);
        }
        &::before {
          border-bottom: 1px solid var(--color-border);
          content: "+ Attach files";
          position: absolute;
          top: 0px;
          font-size: 13px;
          font-weight: 600;
          color: var(--color-blue-dark);
          //display: none;
          height: 40px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          //background-color: #fff;
          //&:hover {
          //  background-color: var(--color-input) !important;
          //}
        }
      }
      .dz-preview {
        &:hover {
          background-color: var(--color-input);
        }
        width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        height: 38px;
        min-height: 38px;
        max-height: 38px;
        box-sizing: border-box;
        .dz-image {
          margin-left: 6px;
          max-width: 30px;
          max-height: 30px;
          img {
            border-radius: 3px;
            filter: none !important;
            width: 100%;
            height: 100%;
          }
        }
        .dz-details {
          margin-left: 12px;
          opacity: 1;
          position: static;
          padding: 0;
          min-width: max-content;
          max-height: max-content;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row-reverse;
          width: 60%;
          overflow: hidden;
          .dz-size {
            margin: 0;
            margin-left: 12px;
            * {
              padding: 0;
              font-size: 13px;
              color: var(--color-gray-text);
            }
          }
          .dz-filename {
            //width: 70%;
            //overflow: hidden;
            * {
              font-size: 13px;
              color: #4B4B4B;
              padding: 0;
              width: 50%;
              white-space: nowrap; /* Запрещаем перенос строк */
              overflow: hidden; /* Обрезаем все, что не помещается в область */
              text-overflow: ellipsis; /* Добавляем многоточие */
            }
          }
        }
        .dz-remove {
          border: none !important;
          font-size: 0;
          opacity: 1;
          position: static;
          width: 0px;
          height: 0px;
          bottom: auto;
          margin: 0;
          display: flex;
          align-items: center;
          padding: 0;
          &::after {
            content: url("../../../assets/ic_close24.svg");
            color: var(--color-dark);
            position: absolute;
            right: 6px;
            top: calc(50% - 12px);
          }
        }
        .dz-error-message {
          z-index: 100;
          position: absolute;
          top: -15px;
          display: none;
        }
        .dz-success-mark {
          z-index: 100;
          position: absolute;
          top: -15px;
          display: none;
        }
        .dz-progress {
          opacity: 1;
          right: 33px;
          left: auto;
          top: auto;
          margin: 0;
          width: 120px;
          height: 4px;
          background: var(--color-card-border);
          .dz-upload {
            background: var(--color-blue-dark)
          }
        }
      }
    }
  }
.edit-block-wrap {
  border-radius: 4px;
  /deep/ {
    .ql-snow {
      border-radius: 4px 4px 0 0;
    }
    .ql-editor {
      border-radius: 0 0 4px 4px;
    }
    .ql-image {
      display: none;
    }
  }
}
.error {
  border: 1px solid var(--color-red)
}
.error-contact {
  /deep/ input {
    border: 1px solid var(--color-red) !important
  }
}
  .edit-block-wrap {
    /deep/  {
      em {
        font-style: italic !important;
      }
      strong {
        font-weight: 500;
      }
      .ql-video {
        display: none !important;
      }
    }
  }
  /deep/ {
    .multiselect__input {
      &::placeholder {
        font-size: 13px;
      }

      margin-bottom: 8px;
    }
  }
</style>