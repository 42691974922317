<template>
  <div class="lay_table__grid bg-white rounded-tl-lg rounded-tr-lg shadow fz13 text-gray-text" :class="{'pdfHideBlock': pdfHideBlock}">
    <div class="">Date</div>
    <div class="">Day</div>
    <div class="col-3-5 flex-wrap p-0">
      <div class="p-1 w-full border-b border-gray-dark self-stretch">Hours</div>
      <div class="p-1 w-1/2 border-r border-gray-dark box-border self-stretch">From</div>
      <div class="p-1 w-1/2 self-stretch box-border">To</div>
    </div>
    <div class="col-5-8 flex-wrap p-0">
      <div class="p-1 w-full border-b border-gray-dark self-stretch">Time used</div>
      <div class="p-1 w-1/3 box-border self-stretch">dd</div>
      <div class="p-1 w-1/3 border-l border-gray-dark self-stretch box-border">hh</div>
      <div class="p-1 w-1/3 border-l border-gray-dark self-stretch box-border">mm</div>
    </div>
    <div class="">Remarks</div>
    <div class="">Time to count</div>
    <div class="">Equal days</div>
    <div class="" v-if="!pdfHideBlock">Check if on dem</div>
    <div class="" v-if="!pdfHideBlock">Time</div>
    <div class="" v-if="!pdfHideBlock">Days Qty</div>
  </div>
</template>

<script>
export default {
  props: {
    pdfHideBlock: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped lang="scss">
  .pdfHideBlock {
    grid-template-columns: 11.64% repeat(3, 5.38%) repeat(3, 3.59%) 29.15% repeat(2, 16.2%) !important;
  }
  @media (max-width: 1611px) {
    .lay_table__grid {
      grid-template-columns: 160px repeat(3, 6.38%) repeat(3, 3.59%) calc(37.79% - 160px) repeat(5, 6.46%);
    }
  }
  //@media (max-width: 1420px) {
  //  .lay_table__grid {
  //    grid-template-columns: 11.64% repeat(3, 6.38%) repeat(3, 3.59%) 26.15% repeat(5, 6.46%);
  //  }
  //}

</style>