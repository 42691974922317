<template>
  <div class="position-relative" v-click-outside="hideList">
    <div class="selected p0-12 box-border d-flex align-items-center" v-on:click="show = !show">
      <p class="text-gray fz13" v-if="!value">Choose type</p>
      <p class="text-dark fz13" v-else v-html="getText"></p>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.76545 4.69531C2.49817 4.69531 2.36432 5.01846 2.55331 5.20744L5.82906 8.48318C5.94621 8.60034 6.13616 8.60034 6.25332 8.48318L9.52905 5.20744C9.71804 5.01845 9.58419 4.69531 9.31692 4.69531H2.76545Z" fill="#2C2C2C"/>
      </svg>
    </div>
    <div class="list d-flex flex-column w100Proc" v-if="show">
      <button class="fz13 text-align-left p0-12 box-border w100Proc d-flex align-items-center position-relative" v-for="(name, i) in list" :key="i" v-on:click="select(name)">{{name}}
        <svg v-if="value.indexOf(name)>=0" class="position-absolute r4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>
      </button>
      <button class="h32 border-top box-border blue-button-text p0-12 fz13" v-on:click="clear">Clear</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "location-select-type",
  props: {
    value: [String, Number, Object, Array],
  },
  data() {
    return {
      list: ['Capesize', 'Panamax', 'Handymax', 'Hendysize'],
      show: false,
      valueLocal: []
    }
  },
  computed: {
    getText() {
      let text = ''
      if(this.valueLocal.length > 0) {
        text = this.valueLocal[0]
        if(this.valueLocal.length > 1) {
          text += ' <span class="circle">+'+ (this.valueLocal.length - 1) +'</span>'
        }
      }
      return text
    }
  },
  watch: {
    value: function () {
      this.valueLocal = JSON.parse(JSON.stringify(this.value))
    }
  },
  methods: {
    select(name) {
      this.valueLocal = []
      if(this.valueLocal.indexOf(name) >= 0) {
        this.valueLocal = this.valueLocal.filter(a=> a !== name)
      } else {
        this.valueLocal.push(name)
      }
      this.$emit('input', this.valueLocal)
      this.show = false
    },
    clear() {
      if(this.valueLocal.length !== 0) {
        this.$emit('clear')
      }

      this.valueLocal = []
      this.$emit('input', this.valueLocal)
      this.show = false
    },
    hideList() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
  .selected {
    min-width: 140px;
    padding-right: 28px;
    background: var(--color-input);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    height: 32px;
    box-sizing: border-box;
    position: relative;
    svg {
      position: absolute;
      right: 8px;
    }
  }
  .list {
    background-color: var(--color-bgc-page-white);
    position: absolute;
    bottom: -2px;
    transform: translateY(100%);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    button {
      height: 32px;
    }
  }
  .circle {
    border: 1px solid var(--color-blue-dark);
  }
</style>