import axios from 'axios'
import QueryString from "querystring";

export default {
  actions: {
    onlyNumber(ctx, e) {
      if(
        (e.keyCode < 48 || e.keyCode > 57) && // numbers
        (e.keyCode < 96 || e.keyCode > 105) && // numpad
        (e.keyCode < 37 || e.keyCode > 40) && // arrows
        e.key != '.' && e.keyCode != 13 && // 13 - enter
        e.keyCode != 8 && e.keyCode != 46 && // backspace / delete
        e.keyCode != 110 // decimal point
      ) {
        e.preventDefault()
      }
    },
    onlyNumberTwoAfterDot(ctx, e) {
      // console.log(e)
      function checkNum(num) {
        let countAfterDot = 1
        if(e.key === '.') {
          num+='.'
        }
        if((''+num).indexOf(".")>=0) {
          num=''+num
          let num1 = num.substring(0, num.indexOf("."));
          let num2 = num.substring(num.indexOf(".") + 1, num.length);
          num1 = num1.replace(/[^0-9]/g, "");
          num2 = num2.replace(/[^0-9]/g, "");
          if(num2.length > countAfterDot) {
            num2 = num2.substring(0, countAfterDot);
          } else {
            num2 = num2.substring(0, (''+num2).length);
          }
          num = num1 + "." + num2;
        } else {
          // console.log(3)
          num = (''+num).replace(/[^0-9]/g, "")
        }
        // console.log(num)
        return num ? num : null;
      }
      if(
          (e.keyCode < 48 || e.keyCode > 57) && // numbers
          (e.keyCode < 96 || e.keyCode > 105) && // numpad
          (e.keyCode < 37 || e.keyCode > 40) && // arrows
          e.key != '.' && e.keyCode != 13 && // 13 - enter
          e.keyCode != 8 && e.keyCode != 46 && // backspace / delete
          e.keyCode != 110 // decimal point
      ) {
        e.preventDefault()
      } else {
        let value = (''+e.target.value).replaceAll(',', '.')
        if(value.indexOf(".")>=0 && (value.substring(value.indexOf(".") + 1, value.length)).length > 0) {
          e.target.value = checkNum(e.target.value)
        }
        // let value = (''+e.path[0].value).replaceAll(',', '.')
        // if(value.indexOf(".")>=0 && (value.substring(value.indexOf(".") + 1, value.length)).length > 0) {
        //   e.path[0].value = checkNum(e.path[0].value)
        // }
      }

    },

    async axiosGetTabs(ctx, data) {
      // console.log('data', data)
      await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/calculator/tab',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: {
          creator: data.creator,
          is_archived: data.is_archived,
        }
      })
      .then(response => {
        ctx.state.creators = (response.data.creators.find(a=> a.id === ctx.getters.getLoginData.data.user.id) ? response.data.creators : [ctx.getters.getLoginData.data.user].concat(response.data.creators))
        ctx.state.tabs = response.data.tabs
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.error('axiosGetTabs error: ', error)
      })
    },
    async axiosPatchTab(ctx, data) {
      return await axios({
        method: 'patch',
        url: process.env.VUE_APP_URL+'/calculator/tab/'+data.id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: data
      })
      .then(response => {
        let updateTab = ctx.state.tabs.find(el=>el.id==response.data.id)
        if(updateTab) updateTab = response.data
        console.log('axiosPatchTab: ', response)
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.error('axiosPatchTab error: ', error)
      })
    },
    async axiosDeleteTab(ctx, id) {
      return await axios({
        method: 'delete',
        url: process.env.VUE_APP_URL+'/calculator/tab/'+id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      })
      .then(response => {
        ctx.state.tabs = ctx.state.tabs.map(a=> {
          if(a.id !== id) {
            a.list_in_group = a.list_in_group.filter(b=> b.id !== id)
            return a;
          }
          return null
        }).filter(a=> a !== null)
        console.log('axiosDeleteTab: ', response)
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.error('axiosDeleteTab error: ', error)
      })
    },
    async axiosPostTab(ctx, data) {
      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/calculator/tab',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: data
      })
      .then(response => {
        if(response.data){
          if(data.parent){
            let parent = ctx.state.tabs.find(el=>el.id == data.parent)
            if(parent) parent.list_in_group.unshift(response.data)
          }else {
            ctx.state.tabs = [response.data].concat(ctx.state.tabs)
          }
          return response.data.id
        }
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
        console.error('axiosPostTab error: ', error)
      })
    },
    async axiosCopyTab(ctx, data) {
      await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/calculator/tab/copy/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: data
      })
      .then(response => {
        console.log('axiosCopyTab: ', response.data)
        ctx.state.tabs.push(response.data)
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
        console.error('axiosCopyTab error: ', error)
      })
    },

    async axiosGetCalcRoute(ctx, id) {
      if(Number.isInteger(+id)) {
        return await axios({
          method: 'get',
          url: process.env.VUE_APP_URL+'/calculator/route',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          params: {
            tab: id,
            ordering: 'ordering_index'
          }
        })
        .then(response => {
          return response.data
        })
        .catch(error => {
          // ctx.commit('addTip', error)
          console.log(error)
        })
      }
      return null
    },
    async axiosPostCalcRoute(ctx, data) {
      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/calculator/route',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },
    async axiosPatchCalcRoute(ctx, data) {
      await axios({
        method: 'patch',
        url: process.env.VUE_APP_URL+'/calculator/route/0',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },
    async axiosDeleteCalcRoute(ctx, id) {
      return await axios({
        method: 'delete',
        url: process.env.VUE_APP_URL+'/calculator/route/'+id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      })
      .then(()=>{
        return true
      })
      .catch(() => {
        // ctx.commit('addTip', error)
        return false
      })
    },

    async axiosGetCalcCargo(ctx, id) {
      if(Number.isInteger(+id)) {
        return await axios({
          method: 'get',
          url: process.env.VUE_APP_URL+'/calculator/cargo',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          params: {
            tab: id
          }
        })
            .then(response => {
              return response.data.results
            })
            .catch(error => {
              // ctx.commit('addTip', error)
              console.log(error)
            })
      }
      return null
    },
    async axiosGetCalcCargoByIdVessel(ctx, data) {
      let ct = axios.CancelToken.source();
      ctx.commit("cancelTokenSearchCargo")
      ctx.commit("setCancelTokenSearchCargo", ct);
      if(Number.isInteger(+data.idTab) && Number.isInteger(+data.idVessel)) {
        return await axios({
          method: 'get',
          url: process.env.VUE_APP_URL+'/calculator/cargo',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          params: {
            vessel: data.idVessel,
            tab: data.idTab
          },
          cancelToken: ct.token,

        })
            .then(response => {
              return response.data.results
            })
            .catch(error => {
              console.log(error)
              // ctx.commit('addTip', error)
            })
      }
      return null
    },
    async axiosCalculatorVesselsDuplicate(ctx, data) {
      return await axios.post(process.env.VUE_APP_URL+'/calculator/vessel/duplicate',{}, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: {
          vessel_from_id: data.vessel_from_id,
          vessel_to_id: data.vessel_to_id
        }
      }).then(()=> {
        return true
      }).catch(()=> {
        return false
      })
    },
    async axiosPostCalcCargo(ctx, data) {
      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/calculator/cargo',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },
    async axiosPatchCalcCargo(ctx, data) {
      await axios({
        method: 'patch',
        url: process.env.VUE_APP_URL+'/calculator/cargo/'+data.id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data
      })
      .then(() => {
        //ctx.commit('addTip', 'Cargo saved')
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },
    async axiosDeleteCalcCargo(ctx, id) {
      await axios({
        method: 'delete',
        url: process.env.VUE_APP_URL+'/calculator/cargo/'+id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      })
      .then(() => {})
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },

    async axiosGetCalcVesselList(ctx, search) { // search parsed vessels
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/calculator/vessel/list/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: {
          is_tab: false,
          can_used_as_template: true,
          search: search
        }
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },
    async axiosPinCalcVessel(ctx, payload) {
      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/calculator/vessel/list/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: payload.data,
        params: {
          tab: payload.tab
        }
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },
    async axiosGetCalcVesselById(ctx, id) {
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/calculator/vessel/'+id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        // params: {
        //   tab: id
        // }
      })
          .then(response => {
            return response.data
          })
          .catch(error => {
            // ctx.commit('addTip', error)
            console.log(error)
          })
    },
    async axiosGetCalcVessel(ctx, id) {
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/calculator/vessel',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: {
          tab: id
        }
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },
    async axiosPostCalcVessel(ctx, data) { // create vessel in calculator
      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/calculator/vessel/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        // ctx.commit('addTip', error)
        console.log(error)
      })
    },
    async axiosPatchCalcVessel(ctx, data) {
      let ct = axios.CancelToken.source();
      ctx.commit("cancelPostSaveVessels")
      ctx.commit("cancelTokenSourceVessel", ct);
      await axios({
        method: 'patch',
        url: process.env.VUE_APP_URL+'/calculator/vessel/0',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        cancelToken: ct.token,
        data
      })
      .then(() => {
        console.log('Vessels saved!');
      })
      .catch(error => {
        console.log(error)
        if((''+error.request.response).toLowerCase().indexOf('cancel') == -1) {
          // ctx.commit('addTip', error)
          console.log(error)
        }
      })
    },
    async axiosDeleteCalcVessel(ctx, id) {
      await axios({
        method: 'delete',
        url: process.env.VUE_APP_URL+'/calculator/vessel/'+id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      })
      .then(() => {
        //ctx.commit('addTip', 'Vessel deleted')
      })
      .catch(error => {
        console.log(error)
        // ctx.commit('addTip', error)
      })
    },

    async axiosGetRouteDistance(ctx, data) {
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/calculator/route/distance/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: {
          ports: data.ports,
          'piracy_option': data.piracy_option ? data.piracy_option : '000',
          'canal_pass_code': data.canal_pass_code ? data.canal_pass_code : '000',
          'use_local_eca': data.use_local_eca ? data.use_local_eca : 'False',
          'seca_bypass_option': data.seca_bypass_option ? data.seca_bypass_option.toUpperCase() : null,
          // 'seca_cost_ratio': data.seca_bypass_option === 'optimized' ? data.seca_cost_ratio.toUpperCase(): null,
        },
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error.response
      })
    }
  },
  mutations: {
    cancelTokenSearchCargo(state) {
      if(state.cancelTokenSource !== null) {
        state.cancelTokenSource.cancel();
      }
    },
    setCancelTokenSearchCargo(state, data) {
      state.cancelTokenSource = data
    },
    setCalcVesselActive(state, data) {
      state.calcVesselActive = data;
    },
    setActiveCreator(state, data) {
      state.activeCreator = data
    },
    addListAfterChange(state, data) {
      if(data.folder !== null) {
        state.tabs = state.tabs.map(a=> {
          if(a.id === data.folder.id) {
            a.list_in_group.push(data.file)
          }
          return a;
        })
      } else {
        state.tabs.push(data.file)
      }
    },
    removeListAfterChange(state, data) {
      if(data.folder !== null) {
        state.tabs = state.tabs.map(a=> {
          if(a.id === data.folder.id) {
            a.list_in_group = a.list_in_group.filter(b=> b.id !== data.file.id)
          }
          return a;
        })
      } else {
        state.tabs = state.tabs.filter(b=> b.id !== data.file.id)
      }
    },
    setVesselDataForPdf(state, data) {
      state.vesselDataForPdf = data;
    },
    cancelTokenSourceVessel(state, data) {
      state.cancelTokenSourceVessel = data;
    },
    cancelPostSaveVessels(state) {
      if(state.cancelTokenSourceVessel !== null) {
        state.cancelTokenSourceVessel.cancel();
      }
    },
  },
  state: {
    cancelTokenSourceVessel: null,
    cancelTokenSource: null,
    activeCreator: null,
    creators: null,
    tabs: null,
    cargoClassList: [
      {
        name: 'Coal',
        ft3: 42,
        m3: 1.189314
      },
      {
        name: 'Iron ore',
        ft3: 19,
        m3: 0.538023
      },
      {
        name: 'Pig iron',
        ft3: 14,
        m3: 0.396438
      },
      {
        name: 'Pet coke',
        ft3: 47,
        m3: 1.330899
      },
      {
        name: 'Coke',
        ft3: 63,
        m3: 1.783971
      },
      {
        name: 'Corn',
        ft3: 48,
        m3: 1.359216
      },
      {
        name: 'Barley',
        ft3: 53,
        m3: 1.500801
      },
      {
        name: 'Wheat',
        ft3: 45,
        m3: 1.274265
      },
      {
        name: 'Bauxite',
        ft3: 32,
        m3: 0.906144
      },
      {
        name: 'Bentonite',
        ft3: 40,
        m3: 1.13268
      },
      {
        name: 'Fertilizers',
        ft3: 40,
        m3: 1.13268
      },
      {
        name: 'Dap',
        ft3: 45,
        m3: 1.274265
      },
    ],
    termsList: [
      {
        name: 'shinc',
        coof: 1.0
      },
      {
        name: 'satpm',
        coof: 1.3333
      },
      {
        name: 'shex',
        coof: 1.2727
      },
      {
        name: 'sshex',
        coof: 1.5555
      },
      {
        name: 'fhex1',
        coof: 1.2727
      },
      {
        name: 'fhex2',
        coof: 1.5849
      },
      {
        name: 'fhinc',
        coof: 1.0
      },
      {
        name: 'shex1',
        coof: 1.2727
      },
      {
        name: 'shex2',
        coof: 1.4
      },
      {
        name: 'shex3',
        coof: 1.3548
      },
      {
        name: 'shex4',
        coof: 1.4
      },
      {
        name: 'shex6',
        coof: 1.5
      },
      {
        name: 'shex7',
        coof: 1.6153
      },
      {
        name: 'shex8',
        coof: 1.68
      }
    ],
    calcWaterDensity: [
      {
        name: 'Salt water (1,025)',
        coof: 1.025
      },
      {
        name: 'Fresh water (1,00)',
        coof: 1.0
      },
      {
        name: 'Brakish water (1,012)',
        coof: 1.012
      }
    ],
    calcWaterDensityNew: [
      {
        name: 'SW (1,025)',
        coof: 1.025
      },
      {
        name: 'FW (1,00)',
        coof: 1.0
      },
      {
        name: 'BW (1,012)',
        coof: 1.012
      }
    ],
    calcVesselActive: null,
    vesselDataForPdf:[],
  },
  getters: {
    getVesselDataForPdf(state) {
      return state.vesselDataForPdf
    },
    getCurrentTab: state => id => {
      let result = null
      if(id && state.tabs){
        result = state.tabs.find(el=>{
          if(el.tab_type == 'group'){
            let activeInFolder = el.list_in_group.find(subEl => {
              return id == subEl.id
            })
            if(activeInFolder) return activeInFolder
          }else{
            if(id == el.id) return true
          }
        })

        if(result?.tab_type == 'group'){
          result = result.list_in_group.find(el=>el.id==id)
        }
      }
      return result
    },
    getNumbFromString: () => str => {
      let result = str ? str.toString() : '0'

      result = result.replace( /^\D+/g, '')
      result = result.replace( ',', '.')
      result = parseFloat(result)

      return result ? result : 0
    }
  }
}