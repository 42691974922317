<template>
  <div class="entries_row relative">
    <div class="flex flex-col entries_col entries_elems border-r border-gray-dark">
      <div class="flex border-b border-gray-dark p-l-3">
        <div class="w-full flex items-center" v-if="item.is_manual">
          <svg v-if="!$route.meta.isArchive && getPermissionsByType('archive_and_unarchive_a_manual_entry')" @click="$emit('on-delete')" class="flex-shrink-0 cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/></svg>
          <span v-else-if="getPermissionsByType('archive_and_unarchive_a_manual_entry')" @click="$emit('on-delete')" class="fz0 svg-white-dart cursor-pointer">
            <svg class="flex-shrink-0 cursor-pointer" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78916L6.71267 7.00628L9.01736 4.22339C9.28441 3.91736 9.25326 3.45263 8.94722 3.18534C8.64119 2.91829 8.1767 2.94968 7.90916 3.25547L5.18172 6.52242C4.93943 6.79952 4.93943 7.21324 5.18172 7.49034L7.9092 10.7573C8.05485 10.9236 8.25865 11.0089 8.46365 11.0089C8.63532 11.0089 8.80769 10.9491 8.94722 10.8272C9.25329 10.5599 9.28465 10.0952 9.01736 9.78916Z" fill="#2C2C2C"/><path d="M13.6538 6.26953H5.73568C5.32934 6.26953 5 6.59884 5 7.00521C5 7.41155 5.32934 7.74089 5.73568 7.74089H13.6538C15.7904 7.74089 17.5286 9.60426 17.5286 11.9824C17.5286 14.3606 15.7904 16.4901 13.6538 16.4901H6.71661C6.31027 16.4901 5.98093 16.8194 5.98093 17.2258C5.98093 17.6321 6.31027 17.9615 6.71661 17.9615H13.6538C16.6014 17.9615 19 15.2159 19 11.9824C19 8.74895 16.6017 6.26953 13.6538 6.26953Z" fill="#2C2C2C"/></svg>
          </span>
          <default-select
            class="w-full m-l-1"
            :id="'operation-tipe'+item.id"
            placeholder="Choose Operation Type"
            :options="getTypesForDrop"
            :closeOnSelect="true"
            :disabled="$route.meta.isArchive"
            v-model="activeType"
            @on-select="selectedType"
          />
        </div>
        <div class="w-full flex items-center p-t-px" v-else>
          <span class="fz14 fw500 m-r-auto p-y-2">{{types[item.operation_type]}}</span>
          <router-link 
            class="flex items-center p-r-2" 
            v-if="getUrl({id: item.real_id, model: item.operation_type})"
            :to="getUrl({id: item.real_id, model: item.operation_type})"
            target='_blank'
          >
            <span class="fz14 fw500 text-blue-dark">#{{item.real_id ? item.real_id : item.instance_id}}</span>
            <entries-arrow class="m-l-0.5"/>
          </router-link>

          <span class="flex items-center p-r-2" v-else @click="onClickUnlink">
            <span class="fz14 fw500 text-blue-dark">#{{item.real_id ? item.real_id : item.instance_id}}</span>
            <entries-arrow class="m-l-0.5"/>
          </span>
        </div>
      </div>

      <div class="flex border-b border-gray-dark">
        <date-picker
          class="w-full"
          input-class="mx-input border-0 rounded-none"
          format="DD.MM.YYYY"
          value-type="YYYY-MM-DD HH:mm:ss"
          type="date"
          placeholder="Choose Date"
          v-model="item.due_date"
          @change="changeDate()"
        ></date-picker>
<!--        (item.operation_type == 'soa_sublet' || item.operation_type == 'voyage') ? changeDate() : onEditEntries(false)-->
<!--        :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"-->
      </div>

      <div
        class="flex border-b border-gray-dark"
        :class="{
          'h-full': !menu.includes('account') && !menu.includes('port')
        }"
      >
        <div class="w-7/12 border-r border-gray-dark" v-tooltip.top="item.vessel ? item.vessel.name : null">
          <async-select
            :id="'vessel'+item.id"
            placeholder="Choose Vessel"
            url="/contract/dropdown"
            :params="{contract_type: 'SHIP',is_archive: false}"
            watchKey="name"
            :openDirection="''"
            :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
            :closeOnSelect="true"
            afterWatchKeyBrackets="cp_date"
            v-model="item.vessel"
            @on-select="onEditEntries(false)"
          />
        </div>
        <div class="w-5/12 border-r border-gray-dark">
          <div class="p-2 fz14 text-dark-gray" v-if="item.cp_date || item.vessel">{{item.cp_date ? item.cp_date : item.vessel.cp_date | formatDateWithotTZ}}</div>
          <div class="p-2 text-gray-text fz14" v-else>CP Date</div>
        </div>
        <div class="flex w-7 flex-shrink-0">
          <router-link class="flex h-full w-full" v-if="item.vessel" :to="getUrl({id: item.vessel.id, model: 'Contract'})" target='_blank'>
            <entries-arrow class="m-auto"/>
          </router-link>
        </div>
      </div>
      
      <div class="h-full flex" v-if="menu.includes('account') || menu.includes('port')">
        <div class="w-7/12 border-r border-gray-dark">
          <div class="flex justify-between items-center p-l-3 p-y-2 p-r-2" v-if="item.operation_type != 'soa_with_owners' && item.operation_type != 'soa_sublet' && item.operation_type != 'voyage' && menu.includes('account')">
            <label :for="'real'+item.id" class="fz14">Real account</label>
            <switcher :id="'real'+item.id" v-model="item.has_account" :disabled="$route.meta.isArchive"/>
          </div>
        </div>
        <div 
          class="w-5/12 border-r border-gray-dark"
          v-tooltip.top="getPortsTooltip"
        >
          <async-select
            v-if="menu.includes('port')"
            :id="'port'+item.id"
            placeholder="Choose Port"
            url="/port/dropdown"
            :params="{is_archive: false}"
            watchKey="name"
            afterWatchKeyBrackets="country"
            :openDirection="''"
            :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
            :isMultiple="true"
            :allowEmpty="true"
            v-model="item.ports"
            @on-select="onEditEntries(false)"
          />
          <!-- <async-select-port 
            v-if="menu.includes('port')"
            :id="'port'+item.id"
            placeholder="Choose Port"
            :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
            :isMultiple="true"
            :allowEmpty="true"
            v-model="item.ports"
            @on-select="onEditEntries(false)"
          /> -->
        </div>
        <div class="flex w-7 flex-shrink-0">
          <svg v-tooltip.right="item.remark" v-if="item.remark && menu.includes('port')" class="m-x-auto m-t-1 hover:stroke-blue-dark" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path d="M7.58464 16.5676L7.62483 16.1531L7.27982 15.92C5.667 14.8302 4.7 13.2326 4.7 11.5C4.7 8.42556 7.82549 5.7 12 5.7C16.1745 5.7 19.3 8.42556 19.3 11.5C19.3 14.5744 16.1745 17.3 12 17.3C11.6849 17.3 11.3747 17.2841 11.0706 17.2533L10.8488 17.2308L10.6549 17.3407L7.32675 19.2269L7.58464 16.5676Z" stroke-width="1.4"/></svg>
        </div>
      </div>
    </div>

    <div class="entries_col border-r border-gray-dark">
      <div v-if="item.operation_type && item.is_manual && item.column_type == 'unknown' && !getIsPayment" class="p-3 fz18 text-gray-text">
        Choose 
        <button class="text-blue-dark hover:text-blue underline" @click="item.column_type = 'debit'">Account</button>
         or 
        <button class="text-blue-dark hover:text-blue underline" @click="item.column_type = 'credit'">Counterparty</button>
      </div>
      <div v-else-if="item.operation_type && item.is_manual && !getIsPayment && item.column_type == 'debit'">
        <entries-list-bank 
          :item="item" 
          column="debit" 
          :menu="menu" 
          @on-update="onEditEntries"
        />
      </div>
      <div v-else-if="item.operation_type && item.is_manual && !getIsPayment && item.column_type == 'credit'">
        <entries-list-counterparty 
          :item="item" 
          column="debit" 
          :menu="menu" 
          @on-update="onEditEntries"
        />
      </div>

      <template v-else-if="item.operation_type">
        <entries-list-counterparty 
          v-if="getIsCol" 
          :item="item" 
          column="debit" 
          :menu="menu" 
          @on-update="onEditEntries"
        />
        <entries-list-bank 
          v-else 
          :item="item" 
          column="debit" 
          :menu="menu" 
          @on-update="onEditEntries"
        />
      </template>
    </div>

    <div class="entries_col border-r border-gray-dark p-y-1.5 p-x-3">
      <template v-if="item.operation_type">
        <span class="fz14 fw700 word-break-all" v-if="item.debit && item.debit.amount">{{Math.abs(item.debit.amount) | formatNumberTwoInf}}</span>
        <span class="fz14 fw700" v-else>0</span>
      </template>
    </div>

    <div class="entries_col border-r border-gray-dark">
      <div v-if="item.operation_type && item.is_manual && item.column_type == 'unknown' && !getIsPayment" class="p-3 fz18 text-gray-text"></div>
      <div v-else-if="item.operation_type && item.is_manual && !getIsPayment && item.column_type == 'debit'">
        <entries-list-counterparty 
          :item="item" 
          column="credit" 
          :menu="menu"
          @on-update="onEditEntries"
        />
      </div>
      <div v-else-if="item.operation_type && item.is_manual && !getIsPayment && item.column_type == 'credit'">
        <entries-list-bank 
          :item="item" 
          column="credit" 
          :menu="menu"
          @on-update="onEditEntries"
        />
      </div>

      <template v-else-if="item.operation_type">
        <entries-list-bank
          v-if="getIsCol" 
          :item="item" 
          column="credit" 
          :menu="menu"
          @on-update="onEditEntries"
        />
        <entries-list-counterparty 
          v-else 
          :item="item" 
          column="credit" 
          :menu="menu"
          @on-update="onEditEntries"
        />
      </template>
    </div>

    <div class="entries_col p-y-1.5 p-x-3 fz14 fw500">
      <template v-if="item.operation_type">
        <span class="fz14 fw700 word-break-all" v-if="item.credit && item.credit.amount">{{Math.abs(item.credit.amount) | formatNumberTwoInf}}</span>
        <span class="fz14 fw700" v-else>0</span>
      </template>
    </div>

    <loader v-if="isLoad" :isLocal="true"/>
  </div>
</template>

<script>
import defaultSelect from '@/components/reuse/defaultSelect'
import DatePicker from 'vue2-datepicker'
import entriesArrow from '@/components/entries/entriesArrow'
import asyncSelect from '@/components/reuse/asyncSelect'
//import asyncSelectPort from '@/components/reuse/asyncSelectPort'
import Switcher from '@/components/reuse/switcher'
import EntriesListCounterparty from './entriesListCounterparty'
import EntriesListBank from './entriesListBank'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import loader from '@/components/loader'

export default {
  components: {
    defaultSelect,
    DatePicker,
    entriesArrow,
    asyncSelect,
    //asyncSelectPort,
    Switcher,
    EntriesListBank,
    EntriesListCounterparty,
    loader
  },
  props: {
    item: Object,
    menu: Array
  },
  data() {
    return {
      types: {
        payment: 'Payment',
        income: 'Income',
        bill: 'Bill',
        invoice: 'Invoice',
        soa_with_owners: 'SOA with Owners',
        soa_sublet: 'SOA Sublet',
        voyage: 'Voyage Statement of Account',
      },
      activeType: null,
      period: null,
      isLoad: false
    }
  },
  watch: {
    'item.has_account': function() {
      this.onEditEntries(false)
    },
    'item.column_type': function() {
      this.onEditEntries(false)
    }
  },
  computed: {
    ...mapState({
      defaultEntriesDetails: state => state.entries.defaultEntriesDetails
    }),
    ...mapGetters(['getPermissionsByType']),
    getUrl() {
      return obj => {
        if(!obj.id) return '/accounts/entries'
        
        if(obj.model == 'bill' || obj.model == 'invoice')
          return this.item.vessel ? `/vessels/${this.item.vessel.id}/open/accounting-invoice/${obj.id}/detail` : false
        
        if(obj.model == 'payment' || obj.model == 'income')
          return `/payment/list/detail/${obj.id}`
        
        if(obj.model == 'soa_sublet')
          return this.item.vessel ? `/vessels/${this.item.parent_vessel}/open/charterers/${this.item.vessel.id}/soa` : '/accounts/entries'

        if(obj.model == 'soa_with_owners')
          return this.item.vessel ? `/vessels/${this.item.vessel.id}/open/owners` : '/accounts/entries'

        if(obj.model == 'voyage'){
          if(this.item.parent_vessel && this.item.vessel){
            return `/vessels/${this.item.parent_vessel}/open/charterers/${this.item.vessel.id}/vsoa`
          }else{
            return '/accounts/entries'
          }
        }
          
        if(obj.model == 'Contract')
          return `/vessels/${obj.id}/open/detail`

        return '/accounts/entries'
      }
    },
    getIsCol() {
      if(this.item.operation_type == 'soa_with_owners' || this.item.operation_type == 'soa_sublet' || this.item.operation_type == 'voyage'){
        return this.item.transaction_type
      }else{
        return this.item.operation_type == 'payment' || this.item.operation_type == 'invoice'
      }
    },
    getIsPayment() {
      return this.item.operation_type == 'payment' || this.item.operation_type == 'invoice' || this.item.operation_type == 'bill' || this.item.operation_type == 'income' 
    },
    getTypesForDrop() {
      let arr = []
      Object.entries(this.types).forEach(([key, val]) => {
        arr.push({
          id: key,
          name: val
        })
      })
      return arr
    },
    getPortsTooltip() {
      if(!this.item.ports?.length) return null
      let tooltip = ''
      this.item.ports.forEach((el, i)=>{
        if(i != 0) tooltip += ', '
        tooltip += el.name+`(${el.country})`
      })
      return tooltip
    }
  },
  methods: {
    ...mapActions(['axiosAddEntries', 'axiosEditEntries', 'axiosEditEntriesPatch']),
    ...mapMutations(['addTip']),
    async selectedType() {
      this.item.operation_type = this.activeType.id
      this.item.column_type = 'unknown'
      this.item.debit = {...this.defaultEntriesDetails}
      this.item.credit = {...this.defaultEntriesDetails}

      if(this.item.operation_type == 'payment' || this.item.operation_type == 'invoice') this.item.transaction_type = true
      if(this.item.operation_type == 'income' || this.item.operation_type == 'bill') this.item.transaction_type = false
      
      if(!this.item.id){
        let saveObj = JSON.parse(JSON.stringify(this.item))
        saveObj.vessel = saveObj.vessel ? saveObj.vessel.id : null


        this.isLoad = true
        let newEntry = await this.axiosAddEntries(saveObj)
        this.isLoad = false
        this.$emit('on-replace', newEntry)
      }else{
        this.onEditEntries(false)
      }
    },
    async changeDate() {
      console.log('changeDate')
      if(this.item.id) {
        this.isLoad = true
        await this.axiosEditEntriesPatch({id: this.item.id, data: {due_date: this.item.due_date ? moment(this.item.due_date).format('YYYY-MM-DD 12:00:00') : this.item.due_date}})
        this.isLoad = false
      }

    },
    async onEditEntries(isUpdate) {
      //console.log('onEditEntries', isUpdate);
      if(this.item.id){
        let saveObj = this.fixSaveObj()
        this.isLoad = true
        let updatedEntry = await this.axiosEditEntries(saveObj)
        this.isLoad = false

        if(isUpdate){
          this.item.debit.total_amount = updatedEntry.debit.total_amount
          this.item.credit.total_amount = updatedEntry.credit.total_amount
          this.item.debit.amount = updatedEntry.debit.amount
          this.item.credit.amount = updatedEntry.credit.amount
          this.item.debit.currency = updatedEntry.debit.currency
          this.item.credit.currency = updatedEntry.credit.currency
        }
      }
    },
    fixSaveObj() {
      let saveObj = JSON.parse(JSON.stringify(this.item))

      if(saveObj.column_type == 'credit') saveObj.transaction_type = true
      if(saveObj.column_type == 'debit') saveObj.transaction_type = false

      saveObj.debit.client = this.fixIfObj(saveObj.debit.client, 'id')
      saveObj.credit.client = this.fixIfObj(saveObj.credit.client, 'id')

      saveObj.debit.real_account = this.fixIfObj(saveObj.debit.real_account, 'id')
      saveObj.credit.real_account = this.fixIfObj(saveObj.credit.real_account, 'id')
      
      saveObj.debit.bank_account = this.fixIfObj(saveObj.debit.bank_account, 'id')
      saveObj.credit.bank_account = this.fixIfObj(saveObj.credit.bank_account, 'id')

      saveObj.debit.requisite = this.fixIfObj(saveObj.debit.requisite, 'id')
      saveObj.credit.requisite = this.fixIfObj(saveObj.credit.requisite, 'id')
      saveObj.debit.bank = this.fixIfObj(saveObj.debit.bank, 'id')
      saveObj.credit.bank = this.fixIfObj(saveObj.credit.bank, 'id')

      saveObj.vessel = this.fixIfObj(saveObj.vessel, 'id')
      saveObj.ports = saveObj.ports?.map(el=>el.id)

      saveObj.debit.account = saveObj.debit.account?.map(el=>el.id)
      saveObj.credit.account = saveObj.credit.account?.map(el=>el.id)

      saveObj.debit.linked_payments = saveObj.debit.linked_payments?.map(el=>el.id)
      saveObj.credit.linked_payments = saveObj.credit.linked_payments?.map(el=>el.id)
      saveObj.debit.linked_owners = saveObj.debit.linked_owners?.map(el=>el.id)
      saveObj.credit.linked_owners = saveObj.credit.linked_owners?.map(el=>el.id)
      saveObj.debit.linked_invoices = saveObj.debit.linked_invoices?.map(el=>el.id)
      saveObj.credit.linked_invoices = saveObj.credit.linked_invoices?.map(el=>el.id)

      saveObj.debit.statement_members = saveObj.debit.statement_members?.map(el=>this.fixIfObj(el, 'name'))
      saveObj.credit.statement_members = saveObj.credit.statement_members?.map(el=>this.fixIfObj(el, 'name'))

      saveObj.debit.stat_member = this.fixIfObj(saveObj.debit.stat_member, 'name')
      if(saveObj.debit.stat_member) saveObj.debit.stat_member = moment(saveObj.debit.stat_member, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm')
      saveObj.credit.stat_member = this.fixIfObj(saveObj.credit.stat_member, 'name')
      if(saveObj.credit.stat_member) saveObj.credit.stat_member = moment(saveObj.credit.stat_member, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm')

      return saveObj
    },
    fixIfObj(item, returnKey) {
      if(item && typeof item == 'object'){
        return item[returnKey]
      }
      return item
    },
    onClickUnlink() {
      this.addTip('This VSOA was unlinked from the vessel. Link it back, please')
    }
  },
  beforeMount() {
    if(typeof this.item.operation_type == 'string')
      this.activeType = this.getTypesForDrop.find(el=>el.id == this.item.operation_type)

    if(typeof this.item.vessel == 'number') this.item.vessel = null
    if(typeof this.item.credit?.client == 'number') this.item.credit.client = null
    if(typeof this.item.debit?.client == 'number') this.item.debit.client = null
    if(typeof this.item.credit?.real_account == 'number') this.item.credit.real_account = null
    if(typeof this.item.debit?.real_account == 'number') this.item.debit.real_account = null
  }
}
</script>

<style lang="scss">
  .entries_outline:hover{
    outline: 1px solid var(--color-gray);
  }
  .entries_elems{
    .w-5\/12 {
      width: calc(41.666667% - 1.75rem - 1px);
    }
    /* .async_select_port{
      .multiselect__single{
        display: block !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    } */
    .multiselect__placeholder{
      color: var(--color-input-placeholder) !important;
    }
    .multiselect__tags{
      min-height: 34px;
      border: 0;
      border-radius: 0px;
      background-color: transparent;
      cursor: pointer;
      &:hover{
        outline: 1px solid var(--color-gray);
      }
    }
    .multiselect--disabled{
      background-color: transparent;
      .multiselect__select{
        background-color: transparent;
      }
    }
    .mx-input{
      cursor: pointer;
      border-radius: 0px;
      //&::placeholder{
      //  color: var(--color-gray-text);
      //}
      &:hover {
        border: none !important;
        outline: 1px solid var(--color-gray) !important;
      }
      &:disabled{
        background-color: transparent;
        cursor: default;
        outline: none !important;
        color: var(--color-dark-gray);
      }
    }
    .counterparties-drop{
      margin-top: 0;
      .dropdown-toggle{
        border: none !important;
        border-radius: 0;
        background-color: transparent !important;
        &:hover{
          outline: 1px solid var(--color-gray) !important;
        }
      }
      .button-text.text-gray-light{
        color: var(--color-gray-text) !important;
      }
    }
    .counterparties-drop_title{
      display: none;
    }
    .async_select__wrap .multiselect__single{
      white-space: normal;
    }
    input{
      background-color: transparent;
      border-radius: 0px;
    }
    .vessel_owners__select_currency{
      top: calc(100% - 0.7rem) !important;
    }
  }
.entries_row {
  * {
    //font-size: 13px !important;
    font-size: 0.8125rem !important;
    line-height: 1rem !important;
  }
  /deep/ {
    * {
      font-size: 0.8125rem !important;
      line-height: 1rem !important;
    }
  }
}


</style>
<style scoped lang="scss">
input {
  height: 24px !important;
}
.flex.border-b.border-gray-dark {
  height: 25px !important;
}

.flex.justify-between.items-center.p-l-3.p-y-2.p-r-2 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.p-2 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  align-items: center;
  height: 24px;
}
/deep/ {
  input {
    height: 24px !important;
  }
  .multiselect {
    height: 24px;
  }
  .multiselect__single {
    margin-bottom: 0;
  }
  .multiselect__input {
    margin-bottom: 0 !important;
    height: 20px !important;
  }
  .multiselect__tags {
    height: 24px;
    min-height: 24px !important;
    padding-top: 4px !important;
    margin-bottom: 3px;
  }
  .multiselect__select {
    height: 24px;
  }
  .p-2.fz14.text-dark-gray {
    height: 24px;
  }
  .flex.w-7.flex-shrink-0 {
    height: 24px;
  }
  .flex-col.entries_col.entries_elems.border-r .h-full.flex {
    .multiselect {
      min-height: 24px !important;
      height: max-content !important;
      margin-bottom: 6px;
      .multiselect__tags {
        height: max-content;

      }
    }
  }
  .w-full.border-r.border-gray-dark {
    height: 24px;
  }
  .entries_elems {
    .multiselect__tags {
      padding-top: 3px !important;
    }
  }
  .flex.border-b.border-gray-dark.entries_elems {
    height: 24px;
  }
}
</style>