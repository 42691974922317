<template>
  <div>
    <folders-list-links class="mt16" :items="links"></folders-list-links>
  </div>
</template>

<script>
import FoldersListLinks from "@/components/folders/folders-list-links";
import {mapActions, mapMutations} from "vuex";
export default {
  name: "folders-vessels-option",
  components: {FoldersListLinks},
  data() {
    return {
      links: [
        {name: 'Cash Flow', last_updated: '', link: {name: 'Folders - Vessels Option CashFlow', params: {...this.$route.params, cash_flow_is_archive: false}}},
        {name: 'Vsl Details', last_updated: '', link: {name: 'Folders - Vessels Option Vessel Detail', params: {...this.$route.params, files_is_archive: false}}},
        {name: 'Owners', last_updated: '', link: {name: 'Folders - Vessels Option Owners', params: {...this.$route.params, owners_is_archive: false, ownersDetail: ''}}},
        {name: 'Charterers', last_updated: '', link: {name: 'Folders - Vessels Option Charterers', params: {...this.$route.params, charterers_is_archive: false,}}},
        {name: 'Acc. Invoices', last_updated: '', link: {name: 'Folders - Vessels Option Acc Invoice', params: {...this.$route.params, cash_flow_is_archive: false}}},
      ]
    }
  },
  methods: {
    ...mapActions(['axiosGetFoldersForSOA']),
    ...mapMutations(['setNameSectionFolders']),
  },
  async mounted() {
    this.setNameSectionFolders('Vessels')
    let res = await this.axiosGetFoldersForSOA({noSetFile: true, is_archive_files: false, ordering: 'name', search: '', contract: JSON.parse(this.$route.params.detailVessel).id, limit: 1, folders: true})
    if(res) {
      let item = this.links.find(a => a.name == 'Owners')
      item.link.params['ownersDetail'] = JSON.stringify({id: res.id})
      item.id = res.id
    } else {
      this.links = this.links.filter(a=> a.name !== 'Owners')
    }
  }
}
</script>

<style scoped>

</style>