<template>
  <div class="option-btn_wrap">
    <button class="option-btn " @click.stop.prevent="openMenu">
      <slot>
        <svg class="option-btn_icon " width="24" height="24" viewBox="0 0 24 24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path d="M6.44 10.56C7.23529 10.56 7.88 11.2047 7.88 12C7.88 12.7953 7.23529 13.44 6.44 13.44C5.64471 13.44 5 12.7953 5 12C5 11.2047 5.64471 10.56 6.44 10.56Z"/><circle cx="12.0001" cy="11.9999" r="1.44" transform="rotate(-90 12.0001 11.9999)"/><path d="M17.5601 10.5602C18.3554 10.5602 19.0001 11.2049 19.0001 12.0002C19.0001 12.7955 18.3554 13.4402 17.5601 13.4402C16.7648 13.4402 16.1201 12.7955 16.1201 12.0002C16.1201 11.2049 16.7648 10.5602 17.5601 10.5602Z"/></svg>
      </slot>
    </button>

    <div class="option-btn_list whitespace-nowrap" tabindex="0" @blur="closeMenu" v-show="isOpen" ref="option_list">

      <div class="option-btn_item p-x-2 p-t-1" @click.stop.prevent="emit('create-list')" v-if="options.indexOf('create-list') >= 0">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.2221 11.2222H12.7778V5.77773C12.7778 5.34849 12.4293 5 11.9999 5C11.5707 5 11.2222 5.34849 11.2222 5.77773V11.2222H5.77773C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77773 12.7778H11.2222V18.2221C11.2222 18.6515 11.5707 19 11.9999 19C12.4293 19 12.7778 18.6515 12.7778 18.2221V12.7778H18.2221C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2221 11.2222Z" fill="#2C2C2C"/></svg>
        Create list
      </div>

      <div class="option-btn_item p-x-2 p-t-1" @click.stop.prevent="emit('create-folder')" v-if="options.indexOf('create-folder') >= 0">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.00244 7.00081V5.39668C4.00154 4.63098 4.63162 4.00769 5.41421 4.00009H10.6424C10.7028 3.99825 10.7607 4.02373 10.7993 4.06916L12.0934 6.57471H18.5907C19.3726 6.5823 20.0025 7.20467 20.0024 7.96976V16.6049C20.0025 17.37 19.3726 17.9924 18.5907 18H14.0024" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.2246 13.2222H7.78024V9.77773C7.78024 9.34849 7.43175 9 7.00237 9C6.57313 9 6.22464 9.34849 6.22464 9.77773V13.2222H2.78017C2.35093 13.2222 2.00244 13.5707 2.00244 13.9999C2.00244 14.4293 2.35093 14.7778 2.78017 14.7778H6.22464V18.2221C6.22464 18.6515 6.57313 19 7.00237 19C7.43175 19 7.78024 18.6515 7.78024 18.2221V14.7778H11.2246C11.654 14.7778 12.0024 14.4293 12.0024 13.9999C12.0024 13.5707 11.654 13.2222 11.2246 13.2222Z" fill="#2C2C2C"/></svg>
        Create folder
      </div>

      <div class="option-btn_item p-x-2 p-t-1" @click.stop.prevent="emit('duplicate')" v-if="options.indexOf('duplicate') >= 0">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.1719C4 9.13178 4.85439 8.28861 5.90834 8.28861H12.9456C13.9996 8.28861 14.854 9.13178 14.854 10.1719V17.1167C14.854 18.1568 13.9996 19 12.9456 19H5.90834C4.85439 19 4 18.1568 4 17.1167V10.1719ZM5.90834 9.60405C5.59056 9.60405 5.33294 9.85828 5.33294 10.1719V17.1167C5.33294 17.4303 5.59056 17.6846 5.90834 17.6846H12.9456C13.2634 17.6846 13.521 17.4303 13.521 17.1167V10.1719C13.521 9.85828 13.2634 9.60405 12.9456 9.60405H5.90834Z" fill="#2C2C2C"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.14605 5.88328C8.14605 4.84317 9.00044 4 10.0544 4H17.0917C18.1456 4 19 4.84317 19 5.88328V12.8281C19 13.8682 18.1456 14.7114 17.0917 14.7114C16.7236 14.7114 16.4252 14.4169 16.4252 14.0537C16.4252 13.6904 16.7236 13.396 17.0917 13.396C17.4094 13.396 17.6671 13.1417 17.6671 12.8281V5.88328C17.6671 5.56967 17.4094 5.31543 17.0917 5.31543H10.0544C9.7366 5.31543 9.47899 5.56967 9.47899 5.88328C9.47899 6.24652 9.1806 6.54099 8.81252 6.54099C8.44444 6.54099 8.14605 6.24652 8.14605 5.88328Z" fill="#2C2C2C"/></svg>
        Duplicate
      </div>

      <div class="option-btn_item p-x-2 p-t-1" @click.stop.prevent="emit('move-to')" v-if="options.indexOf('move-to') >= 0">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 4.46927C12.3866 4.46927 12.7 4.78267 12.7 5.16927C12.7 5.55587 12.3866 5.86927 12 5.86927L12 4.46927ZM7 18.8359L7 19.5359L7 18.8359ZM12 18.1359C12.3866 18.1359 12.7 18.4493 12.7 18.8359C12.7 19.2225 12.3866 19.5359 12 19.5359L12 18.1359ZM5.7 7.16927L5.7 16.8359L4.3 16.8359L4.3 7.16927L5.7 7.16927ZM12 5.86927L7 5.86927L7 4.46927L12 4.46927L12 5.86927ZM7 18.1359L12 18.1359L12 19.5359L7 19.5359L7 18.1359ZM5.7 16.8359C5.7 17.5539 6.28203 18.1359 7 18.1359L7 19.5359C5.50883 19.5359 4.3 18.3271 4.3 16.8359L5.7 16.8359ZM4.3 7.16927C4.3 5.6781 5.50884 4.46927 7 4.46927L7 5.86927C6.28203 5.86927 5.7 6.4513 5.7 7.16927L4.3 7.16927Z" fill="#2C2C2C"/><path d="M10 11.3C9.6134 11.3 9.3 11.6134 9.3 12C9.3 12.3866 9.6134 12.7 10 12.7L10 11.3ZM19 12L19.4959 12.4941C19.768 12.2209 19.768 11.7791 19.4959 11.5059L19 12ZM15.5102 7.50592C15.2373 7.23206 14.7941 7.23126 14.5203 7.50414C14.2464 7.77701 14.2456 8.22023 14.5185 8.49408L15.5102 7.50592ZM14.5185 15.5059C14.2456 15.7798 14.2464 16.223 14.5203 16.4959C14.7941 16.7687 15.2373 16.7679 15.5102 16.4941L14.5185 15.5059ZM10 12.7L19 12.7L19 11.3L10 11.3L10 12.7ZM19.4959 11.5059L15.5102 7.50592L14.5185 8.49408L18.5041 12.4941L19.4959 11.5059ZM18.5041 11.5059L14.5185 15.5059L15.5102 16.4941L19.4959 12.4941L18.5041 11.5059Z" fill="#2C2C2C"/></svg>
        Move-to
      </div>

      <div class="option-btn_item p-x-2 p-t-1" @click.stop.prevent="emit('rename')" v-if="options.indexOf('rename') >= 0">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#2C2C2C"/></svg>
        Rename
      </div>

      <div class="option-btn_item p-x-2 p-t-1" @click.stop.prevent="emit('archive')" v-if="options.indexOf('archive') >= 0 && $route.path.indexOf('archive') === -1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/></svg>
        Archive
      </div>
<!--      <div class="option-btn_item p-x-2 p-t-1" @click.stop.prevent="emit('archive')" v-if="options.indexOf('archive') >= 0 && $route.path.indexOf('archive') !== -1">-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/><path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#2C2C2C"/></svg>-->
<!--        Unarchive-->
<!--      </div>-->

    </div>
  </div>

</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => {
        return ['create-list', 'create-folder']
      }
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    openMenu() {
      if(this.$route.path.indexOf('archive') === -1) {
        this.isOpen = true
        setTimeout(()=>{ // because when I call .focus() after change isOpen, it`s don`t work
          let optionList = this.$refs.option_list
          optionList.focus()
        }, 100)
      }

    },
    closeMenu() {
      this.isOpen = false
    },
    emit(type) {
      this.$emit(type)
      this.closeMenu();
    },
  },
}
</script>

<style lang="scss" scoped>
  .option-btn {
    path, circle {
      fill: var(--color-dark) !important
    }
    &:hover {
      path, circle {
        fill: var(--color-gray) !important
      }
    }
  }
</style>