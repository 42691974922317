<template>
  <div class="invoice_data w-full m-r-auto m-l-auto">
    <actions @downloadPDF="beforeDownload" @downloadXLSX="downloadXLSX" @eventExportPDFBlackWhite="eventExportPDFBlackWhite" :invoice="invoice" :activeTab="activeTab"/>
    <invoice-block :blackAndWhitePDF="!invoice.color" :show-bank-info="invoice.bank_details" :show-stamp="invoice.stamp"  :invoice="invoice" :activeTab="activeTab"/>
    <div class="m-t-6">

      <div class="m-b-6" v-if="invoice && invoice.linked_payments.length">
        <p class="text-gray-text fz13 m-b-2">Linked Operation</p>
        <p 
          class="p-b-1 fz14" 
          v-for="payment in invoice.linked_payments"
          :key="payment.id"
        >
          <span v-if="payment.payment_type == 'expense'">Payment</span>
          <span v-if="payment.payment_type == 'income'">Income</span>
          #{{payment.id}}
          <span v-if="payment.execute_date">({{payment.execute_date | formatDate}})</span>
        </p>
      </div>
    </div>

    <div class="p-b-6">
      <files-attach :isEdit="false" v-if="invoice && invoice.files.length" :files="invoice.files"/>
    </div>

    <template>
      <div class="pdf-inc">
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1745"
            :filename="fileName"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            @hasDownloaded="hasDownloaded"
            ref="html2Pdf"
        >
          <div slot="pdf-content" class="pdf-wrap">
            <invoice-block class="w-invoice-pdf" :blackAndWhitePDF="blackAndWhitePDF" :pdf="true" :show-bank-info="invoice.bank_details" :show-stamp="invoice.stamp" :invoice="invoice" :activeTab="activeTab"/>
          </div>
        </vue-html2pdf>
      </div>
    </template>

  </div>
</template>

<script>
import actions from '@/components/vessels/invoice/actions'
import invoiceBlock from '@/components/vessels/invoice/invoiceBlock'
import filesAttach from '@/components/vessels/owners/filesAttach'
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import moment from 'moment';

import Vue from 'vue'
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueHtml2pdf)

export default {
  props: {
    invoice: Object,
    activeTab: String
  },
  computed: {
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
    ...mapState({
      contract: state => state.contract.activeContract,
    }),
    ...mapGetters(['getDartMode']),
  },
  components: {
    actions,
    invoiceBlock,
    filesAttach,
    VueHtml2pdf,
  },
  data() {
    return {
      fileName: 'Freight Invoice',
      blackAndWhitePDF: false,
    }
  },
  methods: {
    ...mapActions(["axiosGetXLSX"]),
    ...mapMutations(["hideLoad", 'showLoad', 'setDartMode', 'setTurnOnDarkModeAfterDownloadPDF']),
    hasDownloaded() {
      this.blackAndWhitePDF = false;
      this.hideLoad();
    },
    beforeDownload () {
      this.blackAndWhitePDF = !this.invoice.color;
      this.fileName = "Freight Invoice";
      if(this.$route.path.indexOf('vsoa')>=0) {
        this.fileName = "VSOA"
      }
      if(this.$route.path.indexOf('detention')>=0) {
        this.fileName = 'Detention Invoice'
      }
      if(this.$route.path.indexOf('additional_freight')>=0) {
        this.fileName = 'Additional Freight Invoice'
      }
      // if(sessionStorage.getItem('chrtrs_active_tab'))
      //   this.fileName = sessionStorage.getItem('chrtrs_active_tab')
      console.dir(Object(this.$refs.html2Pdf.$el))
      console.log(this.$refs.html2Pdf.$el.offsetTop)
      console.dir(this.$refs.html2Pdf.$el.children[0].children[0].children[0].children[0].children)
      let DOM = this.$refs.html2Pdf.$el.children[0].children[0].children[0].children[0].children;
      let top = 0;
      let offsetTopMain = 0;
      DOM.forEach((item, i)=> {
        if(item.className.indexOf('pdf-see-children') >= 0) {
          let top1 = 0;
          let bool = false;
          console.dir(item.children)
          console.log('top1')
          console.log('offsetMain', offsetTopMain)

          let offsetTop = offsetTopMain - top;
          item.children.forEach((item1, j)=> {
            if(item1.className.indexOf('invoice_right__table_border') === -1) {
              console.log('offsetTop', offsetTop)
              console.log('top1', top1)
              console.log('item1.clientHeight', item1.clientHeight)
              if(((offsetTop + item1.clientHeight) - top1) > 1050) {
                if(j !== 0) {
                  console.log('item1111111111')
                  console.log(item1)
                  console.log(((offsetTop + item1.clientHeight) - top1))
                  console.dir(item.children[j-1]);
                  item.children[j-1].classList.add('html2pdf__page-break')
                  console.log(item.children[j-1]);
                  top1 = offsetTop;
                  bool = true
                } else {
                  console.log('item1111111111')
                  console.log(item1)
                  console.log(((offsetTop + item1.clientHeight) - top1))
                  console.dir(DOM[i-1]);
                  DOM[i-1].classList.add('html2pdf__page-break')
                  console.log(DOM[i-1]);
                  top1 += offsetTop;
                  bool = true
                }
              }
              offsetTop+=item1.clientHeight
            }
          })
          if(bool) {
            top += top1
          }
        } else {
          if(((offsetTopMain + item.scrollHeight) - top) > 1050 && (item.scrollHeight) < 1050 && i > 0) {
            console.log(((offsetTopMain + item.scrollHeight) - top))
            console.dir(DOM[i-1]);
            DOM[i-1].classList.add('html2pdf__page-break')
            console.log(DOM[i-1]);
            top = offsetTopMain;
          }
        }

        offsetTopMain+=item.scrollHeight

      })
      this.showLoad();
      this.setTurnOnDarkModeAfterDownloadPDF(this.getDartMode)
      this.setDartMode(false)
      setTimeout(()=>{this.$refs.html2Pdf.generatePdf()}, 4000)
    },
    eventExportPDFBlackWhite() {
      this.blackAndWhitePDF = true;
      this.fileName = "Freight Invoice";
      if(this.$route.path.indexOf('vsoa')>=0) {
        this.fileName = "VSOA"
      }
      if(this.$route.path.indexOf('detention')>=0) {
        this.fileName = 'Detention Invoice'
      }
      if(this.$route.path.indexOf('additional_freight')>=0) {
        this.fileName = 'Additional Freight Invoice'
      }
      // if(sessionStorage.getItem('chrtrs_active_tab'))
      //   this.fileName = sessionStorage.getItem('chrtrs_active_tab')
      // console.dir(Object(this.$refs.html2Pdf.$el))
      // console.log(this.$refs.html2Pdf.$el.offsetTop)
      // console.dir(this.$refs.html2Pdf.$el.children[0].children[0].children[0].children[0].children)
      let DOM = this.$refs.html2Pdf.$el.children[0].children[0].children[0].children[0].children;
      let top = 0;
      let offsetTopMain = 0;
      DOM.forEach((item, i)=> {
        if(item.className.indexOf('pdf-see-children') >= 0) {
          let top1 = 0;
          let bool = false;
          console.dir(item.children)
          // console.log('top1')
          // console.log('offsetMain', offsetTopMain)

          let offsetTop = offsetTopMain - top;
          item.children.forEach((item1, j)=> {
            if(item1.className.indexOf('invoice_right__table_border') === -1) {
              console.log('offsetTop', offsetTop)
              console.log('top1', top1)
              console.log('item1.clientHeight', item1.clientHeight)
              if(((offsetTop + item1.clientHeight) - top1) > 1050) {
                if(j !== 0) {
                  console.log('item1111111111')
                  console.log(item1)
                  console.log(((offsetTop + item1.clientHeight) - top1))
                  console.dir(item.children[j-1]);
                  item.children[j-1].classList.add('html2pdf__page-break')
                  console.log(item.children[j-1]);
                  top1 = offsetTop;
                  bool = true
                } else {
                  console.log('item1111111111')
                  console.log(item1)
                  console.log(((offsetTop + item1.clientHeight) - top1))
                  console.dir(DOM[i-1]);
                  DOM[i-1].classList.add('html2pdf__page-break')
                  console.log(DOM[i-1]);
                  top1 += offsetTop;
                  bool = true
                }
              }
              offsetTop+=item1.clientHeight
            }
          })
          if(bool) {
            top += top1
          }
        } else {
          if(((offsetTopMain + item.scrollHeight) - top) > 1000 && (item.scrollHeight) < 1000 && i > 0) {
            console.log(((offsetTopMain + item.scrollHeight) - top))
            console.dir(DOM[i-1]);
            DOM[i-1].classList.add('html2pdf__page-break')
            console.log(DOM[i-1]);
            top = offsetTopMain;
          }
        }

        offsetTopMain+=item.scrollHeight

      })
      this.showLoad();
      this.setTurnOnDarkModeAfterDownloadPDF(this.getDartMode)
      this.setDartMode(false)
      setTimeout(()=>{this.$refs.html2Pdf.generatePdf()}, 4000)
    },
    /* isValidDate (str) {
      // optional condition to eliminate the tricky ones
      // since chrome will prepend zeros (000...) to the string and then parse it
      let noSpace = (""+str).replace(/\s/g, '')
      if( noSpace.length < 3) {
        return false
      }
      return Date.parse(noSpace) > 0
    }, */
    getValueFromXml(value, fractionDigits) {
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined) {
        if(moment(""+value, "YYYY-MM-DD HH:mm:ss", true).isValid() || moment(""+value, "YYYY-MM-DD HH:mm", true).isValid() || moment(""+value, "YYYY-MM-DD", true).isValid()) {
          return moment(value).format("DD.MM.YYYY")
        }
        let perc = false;
        if(value[value.length-1] === '%') {
          perc = true;
          value = value.replaceAll('%','')
        }
        if(!isNaN(value)) {
          let bool = false;
          if(value<0) {
            bool = true
          }
          let fixedNumber = value ? value : 0
          if(bool) {
            fixedNumber = fixedNumber*(-1)
          }
          fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: fractionDigits ? fractionDigits : 2, maximumFractionDigits: fractionDigits ? fractionDigits : 2})
          if((''+fixedNumber).indexOf('.') === -1) {
            fixedNumber+='.00'
          }
          if(perc) {
            fixedNumber+='%'
          }
          if(bool) {
            return '('+fixedNumber + ')'
          }
          return fixedNumber
        }
        return ""+value
      }
      return null
    },
    getRowByKey(key) {
      if(!this.invoice.blocks || !this.invoice.blocks.length) return null
      return this.invoice.blocks.find(el => {
        return el.block_type == key
      })
    },
    getRowsByKey(key) {
      if(!this.invoice.blocks || !this.invoice.blocks.length) return []
      let array =  this.invoice.blocks.filter(el => {
        return el.block_type == key
      })
      if(array?.length !== 0) {
        return  array
      }
      return  []
    },
    downloadXLSX() {
      let formAxios= {
        sheet_title: "Payment List",
        file_name: "Payment List",
        data: []
      }
      formAxios.data.push({id:formAxios.data.length + 1, values: ['http://'+this.getIP()+'/media/invoice_img_1.jpg', null, null, null, null, null, this.activeTab]})
      if(this.invoice.identifier) {
        formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, null, null, 'Number:', this.getValueFromXml(this.invoice.identifier)]})
      }
      if(this.invoice.invoice_date) {
        formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, null, null, 'Invoice date:', this.getValueFromXml(this.invoice.invoice_date)]})
      }
      if(!this.invoice.identifier)
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
      if(!this.invoice.invoice_date)
        formAxios.data.push({id:formAxios.data.length + 1, values: []})

      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})


      // formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, null, null, null, 'Due date', null, null, 'Created']})
      // formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, null, null, null, null, this.getValueFromXml(this.invoice.due_date), null, null, this.getValueFromXml(this.invoice.due_date)]})
      // formAxios.data.push({id:formAxios.data.length + 1, values: []})
      // formAxios.data.push({id:formAxios.data.length + 1, values: []})
      // formAxios.data.push({id: formAxios.data.length + 1, values: [this.invoice.charterer ? "Charterer" : null,null, null, "Vessel", null, null, this.invoice.cargo ? "Cargo" : null, null, null, this.invoice.loading_port ? "Port of loading" : null, null, null]})
      // formAxios.data.push({id: formAxios.data.length + 1, values: [this.invoice.charterer ? this.getValueFromXml(this.invoice.charterer.company_name) : null, null, null, this.getValueFromXml(this.invoice.ship_name), null, null, this.invoice.cargo ? this.getValueFromXml(this.invoice.cargo): null, null, null, this.invoice.loading_port ? this.getValueFromXml(this.invoice.loading_port.name + ", " + this.invoice.loading_port.country) : null]})
      // formAxios.data.push({id:formAxios.data.length + 1, values: []})
      // formAxios.data.push({id: formAxios.data.length + 1, values: [this.invoice.id ? "ID" : null, null, null, this.invoice.cp_date ? "CP Date" : null, null, null, this.invoice.total_quantity ? "Total Loaded Quantity" : null, null, null, this.invoice.discharge_port ? "Port of discharge" : null]})
      // formAxios.data.push({id: formAxios.data.length + 1, values: [this.invoice.id ? "#"+this.getValueFromXml(this.invoice.id) : null, null, null, this.invoice.cp_date ? this.getValueFromXml(this.invoice.cp_date) : null, null, null, this.invoice.total_quantity ? this.getValueFromXml(this.invoice.total_quantity + " mt") : null, null, null, this.invoice.discharge_port ? this.getValueFromXml(this.invoice.discharge_port.name + ", " + this.invoice.discharge_port.country) : null]})
      // formAxios.data.push({id:formAxios.data.length + 1, values: []})
      if(this.getValueFromXml(this.invoice.ship_name))
        formAxios.data.push({id:formAxios.data.length + 1, values: ["Vessel", this.getValueFromXml(this.invoice.ship_name)]})
      if(this.invoice.imo_number)
        formAxios.data.push({id:formAxios.data.length + 1, values: ["IMO number", this.getValueFromXml(this.invoice.imo_number)]})
      if(this.invoice.cargo)
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.invoice.cargo ? "Cargo" : null, this.invoice.cargo ? this.getValueFromXml(this.invoice.cargo): null]})
      if(this.invoice.total_quantity)
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.invoice.total_quantity ? "Total Loaded Quantity" : null, this.invoice.total_quantity ? this.getValueFromXml(this.invoice.total_quantity, 3) + " mt" : null]})
      if(this.invoice.charterer)
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.invoice.charterer ? "Charterer" : null, this.invoice.charterer ? this.getValueFromXml(this.invoice.charterer.company_name) : null]})
      if(this.invoice.cp_date)
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.invoice.cp_date ? "CP Date" : null, this.invoice.cp_date ? this.getValueFromXml(this.invoice.cp_date) : null]})
      if(this.invoice.loading_port)
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.invoice.loading_port ? "Port of loading" : null, this.invoice.loading_port ? this.getValueFromXml(this.invoice.loading_port.name + ", " + this.invoice.loading_port.country) : null]})
      if(this.invoice.discharge_port)
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.invoice.discharge_port ? "Port of discharge" : null, this.invoice.discharge_port ? this.getValueFromXml(this.invoice.discharge_port.name + ", " + this.invoice.discharge_port.country) : null]})
      if(this.invoice.bl_dated) {
        formAxios.data.push({id:formAxios.data.length + 1, values: ["BL Dated", this.invoice.bl_dated ? this.getValueFromXml(this.formatDate(this.invoice.bl_dated)) : null]})
      }
      if(this.invoice.charterer.full_style && this.invoice.has_full_style)
        formAxios.data.push({id:formAxios.data.length + 1, values: ["Full style", this.getValueFromXml(this.invoice.charterer.full_style)]})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      if(this.invoice.invoice_type == 'DETENTION') {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Description", null, null, null, null, null, null, "Amount"]})
        if(this.invoice?.detention_invoices?.total)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Detention - fm" + " " + this.formatDate(this.invoice.detention_invoices.date_from) +" " + this.formatTime(this.invoice.detention_invoices.date_from) +" " + this.invoice.detention_invoices.country + " It till"  + " " + this.formatDate(this.invoice.detention_invoices.date_to) + " " + this.formatTime(this.invoice.detention_invoices.date_to) + " " + this.invoice.detention_invoices.country, null, null, null, null, null, null, this.invoice.detention_invoices.total < 0 ? ("(" + this.getValueFromXml(this.invoice.detention_invoices.total*(-1)) + ")") : this.getValueFromXml(this.invoice.detention_invoices.total)]})
        if(this.invoice?.detention_invoices?.total)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["TOTAL", null, null, null, null, null, null, this.invoice.detention_invoices.total ? this.getValueFromXml(this.invoice.detention_invoices.total) : null]})
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
        // if(this.getRowByKey('LESS_ADDRESS_COMMISSION') &&
        //     (this.getRowByKey('LESS_ADDRESS_COMMISSION').percentage ||
        //         this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Address Commission" + (this.getRowByKey('LESS_ADDRESS_COMMISSION')?.additional_info?.comment ? ' ' +  this.getRowByKey('LESS_ADDRESS_COMMISSION')?.additional_info.comment : '') , null, null, this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').percentage) !== null ? (this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').percentage) + '%') : null, null, this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount < 0 ? ( "(" + this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount*(-1)) + ")") : this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount)]})
        // }
      for(let i=0; i<this.getRowsByKey('LESS_ADDRESS_COMMISSION')?.length; i++) {
        let row = this.getRowsByKey('LESS_ADDRESS_COMMISSION')[i]
        if(row && (row.percentage || row.total_amount))
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Address Commission" + (row?.additional_info?.comment ? ' ' +  row.additional_info.comment : '') , null, null, null, null, this.getValueFromXml(row.percentage) !== null ? (this.getValueFromXml(row.percentage) + '%') : null, null, row.total_amount < 0 ? ( "(" + this.getValueFromXml(row.total_amount*(-1)) + ")") : this.getValueFromXml(row.total_amount)]})
      }
        // if(this.getRowByKey('LESS_BROKERAGE_COMMISSION') &&
        //     (this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage ||
        //         this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Brokerage Commission" + (this.getRowByKey('LESS_BROKERAGE_COMMISSION')?.additional_info?.comment ? ' ' +  this.getRowByKey('LESS_BROKERAGE_COMMISSION')?.additional_info.comment : ''), null, null, this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage) !== null ? (this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage) + '%') : null, null, this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount < 0 ?  ('(' +this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('LESS_BROKERAGE_COMMISSION')?.length; i++) {
          let row = this.getRowsByKey('LESS_BROKERAGE_COMMISSION')[i]
          if(row && (row.percentage || row.total_amount))
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Brokerage Commission" + (row?.additional_info?.comment ? ' ' +  row?.additional_info.comment : ''), null, null, null, null, this.getValueFromXml(row.percentage) !== null ? (this.getValueFromXml(row.percentage) + '%') : null, null, row.total_amount < 0 ?  ('(' +this.getValueFromXml(row.total_amount*(-1)) + ')') : this.getValueFromXml(row.total_amount)]})
        }
        // if(this.getRowByKey('LESS_BROKERAGE_COMMISSION') &&
        //     (this.getRowByKey('LESS_BROKERAGE_COMMISSION').additional_percentage ||
        //         this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Tax on Brokerage Commission" + (this.getRowByKey('LESS_BROKERAGE_COMMISSION')?.additional_info?.comment_tax ? ' ' + this.getRowByKey('LESS_BROKERAGE_COMMISSION')?.additional_info.comment_tax : ''), null, null, this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').additional_percentage) !== null ? (this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').additional_percentage) + '%') : null, null, this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage_amount < 0 ? ('('+this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage_amount*(-1))+')') : this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('LESS_BROKERAGE_COMMISSION')?.length; i++) {
          let row = this.getRowsByKey('LESS_BROKERAGE_COMMISSION')[i]
          if(row.additional_percentage || row.percentage_amount)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Tax on Brokerage Commission" + (row?.additional_info?.comment_tax ? ' ' + row?.additional_info.comment_tax : ''), null, null, null, null, this.getValueFromXml(row.additional_percentage) !== null ? (this.getValueFromXml(row.additional_percentage) + '%') : null, null, row.percentage_amount < 0 ? ('('+this.getValueFromXml(row.percentage_amount*(-1))+')') : this.getValueFromXml(row.percentage_amount)]})
        }
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["TOTAL DUE TO OWNERS",null, null, (this.invoice && this.invoice.total_amount) < 0 ? ('('+ this.getValueFromXml(this.invoice.total_amount*(-1)) + ')') : this.getValueFromXml(this.invoice.total_amount)]})
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
      } else {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Description", null, null, "Qty", null, "Rate, USD/mt", null, "Amount"]})
        if(this.getRowByKey('ILOW') && (this.getRowByKey('ILOW').percentage ||
            this.getRowByKey('ILOW').mt_amount ||
            this.getRowByKey('ILOW').rate ||
            this.getRowByKey('ILOW').percentage_amount)) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Ocean freight bss"+ (this.getRowByKey('ILOW').percentage ? (" (ILOW " + (this.getRowByKey('ILOW').percentage ? (this.getValueFromXml(this.getRowByKey('ILOW').percentage) + '%)') : ')')) : '') + ' ' +this.getRowByKey('ILOW').additional_info.comment, null, null, this.getRowByKey('ILOW').mt_amount ? (this.getValueFromXml(this.getRowByKey('ILOW').mt_amount, 3) + 'mt') : null, null, this.getRowByKey('ILOW').rate ? this.getValueFromXml(this.getRowByKey('ILOW').rate) : null, null, this.getRowByKey('ILOW').percentage_amount<0 ? ('('+this.getValueFromXml(this.getRowByKey('ILOW').percentage_amount*(-1))+')') :this.getValueFromXml(this.getRowByKey('ILOW').percentage_amount)]})
        }
        if(this.getRowByKey('DEAD_FREIGHT') && (this.getRowByKey('DEAD_FREIGHT').percentage ||
            this.getRowByKey('DEAD_FREIGHT').mt_amount ||
            this.getRowByKey('DEAD_FREIGHT').rate ||
            this.getRowByKey('DEAD_FREIGHT').percentage_amount)) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Dead freight bss"+ (this.getRowByKey('DEAD_FREIGHT').percentage ? (" (ILOW " + (this.getRowByKey('DEAD_FREIGHT').percentage ? (this.getValueFromXml(this.getRowByKey('DEAD_FREIGHT').percentage) + '%)') : ')')) : '') + ' ' + this.getRowByKey('DEAD_FREIGHT').additional_info.comment, null, null, this.getRowByKey('DEAD_FREIGHT').mt_amount ? (this.getValueFromXml(this.getRowByKey('DEAD_FREIGHT').mt_amount, 3) + 'mt') : null, null, this.getRowByKey('DEAD_FREIGHT').rate ? this.getValueFromXml(this.getRowByKey('DEAD_FREIGHT').rate) : null, null, this.getRowByKey('DEAD_FREIGHT').percentage_amount<0 ? ('('+this.getValueFromXml(this.getRowByKey('DEAD_FREIGHT').percentage_amount*(-1))+')') : this.getValueFromXml(this.getRowByKey('DEAD_FREIGHT').percentage_amount)]})
        }
        if(this.getRowByKey('EXTRA_OCEAN_FREIGHT') && (this.getRowByKey('EXTRA_OCEAN_FREIGHT').percentage ||
            this.getRowByKey('EXTRA_OCEAN_FREIGHT').mt_amount ||
            this.getRowByKey('EXTRA_OCEAN_FREIGHT').rate ||
            this.getRowByKey('EXTRA_OCEAN_FREIGHT').percentage_amount)) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Extra ocean freight bss"+ (this.getRowByKey('EXTRA_OCEAN_FREIGHT').percentage ? (" (ILOW " + (this.getRowByKey('EXTRA_OCEAN_FREIGHT').percentage ? (this.getValueFromXml(this.getRowByKey('EXTRA_OCEAN_FREIGHT').percentage) + '%)') : ')')) : '') + ' ' + this.getRowByKey('EXTRA_OCEAN_FREIGHT').additional_info.comment, null, null, this.getRowByKey('EXTRA_OCEAN_FREIGHT').mt_amount ? (this.getValueFromXml(this.getRowByKey('EXTRA_OCEAN_FREIGHT').mt_amount, 3) + 'mt') : null, null, this.getRowByKey('EXTRA_OCEAN_FREIGHT').rate ? this.getValueFromXml(this.getRowByKey('EXTRA_OCEAN_FREIGHT').rate) : null, null, this.getRowByKey('EXTRA_OCEAN_FREIGHT').percentage_amount ? this.getValueFromXml(this.getRowByKey('EXTRA_OCEAN_FREIGHT').percentage_amount) : null]})
        }
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["TOTAL", null, null, null, null, null, null, (this.getRowByKey('ILOW') !== null && this.getRowByKey('ILOW')?.total_amount <0) ? ('('+this.getValueFromXml(this.getRowByKey('ILOW').total_amount*(-1))+')') : this.getValueFromXml(this.getRowByKey('ILOW')?.total_amount)]})
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Description", null, null, null, null, "Percent", null, "Amount"]})
        // if(this.getRowByKey('THEREOF') &&
        //     (this.getRowByKey('THEREOF').percentage ||
        //         this.getRowByKey('THEREOF').total_amount)) {
        //   formAxios.data.push({id:formAxios.data.length + 1, values: []})
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Thereof", null, null, null, null, this.getRowByKey('THEREOF').percentage ? this.getValueFromXml(this.getRowByKey('THEREOF').percentage + '%') : null, null, this.getRowByKey('THEREOF').total_amount < 0 ? ('('+this.getValueFromXml(this.getRowByKey('THEREOF').total_amount*(-1))+')') : this.getValueFromXml(this.getRowByKey('THEREOF').total_amount)]})
        //   formAxios.data.push({id:formAxios.data.length + 1, values: []})
        // }
        for(let i=0; i<this.getRowsByKey('THEREOF').length; i++) {
          let row = this.getRowsByKey('THEREOF')[i]
          if(row && (row.percentage || row.total_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Thereof" + (row?.additional_info?.comment ? ' ' + row.additional_info.comment : ''), null, null, null, null, row.percentage ? this.getValueFromXml(row.percentage + '%') : null, null, row.total_amount < 0 ? ('('+this.getValueFromXml(row.total_amount*(-1))+')') : this.getValueFromXml(row.total_amount)]})
        }
        // if(this.getRowByKey('LESS_ADDRESS_COMMISSION') &&
        //     (this.getRowByKey('LESS_ADDRESS_COMMISSION').percentage ||
        //         this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Address Commission" + (this.getRowByKey('LESS_ADDRESS_COMMISSION')?.additional_info?.comment ? ' ' +  this.getRowByKey('LESS_ADDRESS_COMMISSION')?.additional_info.comment : ''), null, null, null, null, this.getRowByKey('LESS_ADDRESS_COMMISSION').percentage ? this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').percentage + '%') : null, null, this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount < 0 ? ('('+this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount*(-1))+')') : this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('LESS_ADDRESS_COMMISSION')?.length; i++) {
          let row = this.getRowsByKey('LESS_ADDRESS_COMMISSION')[i]
          if(row && (row.percentage || row.total_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Address Commission" + (row?.additional_info?.comment ? ' ' +  row.additional_info.comment : '') , null, null, null, null, this.getValueFromXml(row.percentage) !== null ? (this.getValueFromXml(row.percentage) + '%') : null, null, row.total_amount < 0 ? ( "(" + this.getValueFromXml(row.total_amount*(-1)) + ")") : this.getValueFromXml(row.total_amount)]})
        }
        // if(this.getRowByKey('LESS_BROKERAGE_COMMISSION') &&
        //     (this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage ||
        //         this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Brokerage Commission" + (this.getRowByKey('LESS_BROKERAGE_COMMISSION')?.additional_info?.comment ? ' ' +  this.getRowByKey('LESS_BROKERAGE_COMMISSION')?.additional_info.comment : ''), null, null, null, null, this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage ? this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage + '%') : null, null, this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount < 0 ? ('('+ this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('LESS_BROKERAGE_COMMISSION')?.length; i++) {
          let row = this.getRowsByKey('LESS_BROKERAGE_COMMISSION')[i]
          if(row && (row.percentage || row.total_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Brokerage Commission" + (row?.additional_info?.comment ? ' ' +  row?.additional_info.comment : ''), null, null, null, null, this.getValueFromXml(row.percentage) !== null ? (this.getValueFromXml(row.percentage) + '%') : null, null, row.total_amount < 0 ?  ('(' +this.getValueFromXml(row.total_amount*(-1)) + ')') : this.getValueFromXml(row.total_amount)]})
        }
        // if(this.getRowByKey('LESS_BROKERAGE_COMMISSION') &&
        //     (this.getRowByKey('LESS_BROKERAGE_COMMISSION').additional_percentage ||
        //         this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Tax on Brokerage Commission" + (this.getRowByKey('LESS_BROKERAGE_COMMISSION')?.additional_info?.comment_tax ? ' ' + this.getRowByKey('LESS_BROKERAGE_COMMISSION')?.additional_info.comment_tax : ''), null, null, null, null, this.getRowByKey('LESS_BROKERAGE_COMMISSION').additional_percentage ? this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').additional_percentage + '%') : null, null, this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage_amount < 0 ? ('('+ this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('LESS_BROKERAGE_COMMISSION')?.length; i++) {
          let row = this.getRowsByKey('LESS_BROKERAGE_COMMISSION')[i]
          if(row && (row.additional_percentage || row.percentage_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Tax on Brokerage Commission" + (row?.additional_info?.comment_tax ? ' ' + row?.additional_info.comment_tax : ''), null, null, null, null, this.getValueFromXml(row.additional_percentage) !== null ? (this.getValueFromXml(row.additional_percentage) + '%') : null, null, row.percentage_amount < 0 ? ('('+this.getValueFromXml(row.percentage_amount*(-1))+')') : this.getValueFromXml(row.percentage_amount)]})
        }
        // if(this.getRowByKey('PLUS_DEMURRAGE') &&
        //     (this.getRowByKey('PLUS_DEMURRAGE').lay_report ||
        //         this.getRowByKey('PLUS_DEMURRAGE').total_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus Demurrage", null, null, null, null, this.getRowByKey('PLUS_DEMURRAGE').lay_report ? (this.getRowByKey('PLUS_DEMURRAGE').lay_report.loading_port ? (this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').lay_report.loading_port.name) + " (Load.)" + ((this.getRowByKey('PLUS_DEMURRAGE').lay_report.loading_port && this.getRowByKey('PLUS_DEMURRAGE').lay_report.discharge_port) ? ' - ': '') +  (this.getRowByKey('PLUS_DEMURRAGE').lay_report.discharge_port ? (this.getRowByKey('PLUS_DEMURRAGE').lay_report.discharge_port.name ? (this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').lay_report.discharge_port.name) + ' (Disch.)') : ''): '')): null) : null, null, this.getRowByKey('PLUS_DEMURRAGE').total_amount < 0 ? ('('+this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').total_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').total_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('PLUS_DEMURRAGE').length; i++) {
          let row = this.getRowsByKey('PLUS_DEMURRAGE')[i];
          if(row && (row.lay_report || row.total_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus Demurrage" + (row.additional_info.comment ? ' ' + row.additional_info.comment : ''), null, null, null, null, row.lay_report ? (row.lay_report.loading_port ? (this.getValueFromXml(row.lay_report.loading_port.name) + " (Load.)" + ((row.lay_report.loading_port && row.lay_report.discharge_port) ? ' - ': '') +  (row.lay_report.discharge_port ? (row.lay_report.discharge_port.name ? (this.getValueFromXml(row.lay_report.discharge_port.name) + ' (Disch.)') : ''): '')): null) : null, null, row.total_amount < 0 ? ('('+this.getValueFromXml(row.total_amount*(-1)) + ')') : this.getValueFromXml(row.total_amount)]})
        }

        // if(this.getRowByKey('PLUS_DEMURRAGE') &&
        //     (this.getRowByKey('PLUS_DEMURRAGE').percentage ||
        //         this.getRowByKey('PLUS_DEMURRAGE').percentage_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Address Commission" + (this.getRowByKey('PLUS_DEMURRAGE')?.additional_info?.comment ? ' ' +  this.getRowByKey('PLUS_DEMURRAGE')?.additional_info.comment : ''), null, null, null, null, this.getRowByKey('PLUS_DEMURRAGE').percentage ? this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').percentage + '%') : null, null, this.getRowByKey('PLUS_DEMURRAGE').percentage_amount<0 ? ('('+this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').percentage_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').percentage_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('PLUS_DEMURRAGE')?.length; i++) {
          let row = this.getRowsByKey('PLUS_DEMURRAGE')[i]
          if(row && (row.percentage || row.percentage_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Address Commission" + (row?.additional_info?.comment ? ' ' +  row.additional_info.comment : '') , null, null, null, null, this.getValueFromXml(row.percentage) !== null ? (this.getValueFromXml(row.percentage) + '%') : null, null, row.percentage_amount < 0 ? ( "(" + this.getValueFromXml(row.percentage_amount*(-1)) + ")") : this.getValueFromXml(row.percentage_amount)]})
        }
        // if(this.getRowByKey('PLUS_DEMURRAGE') &&
        //     (this.getRowByKey('PLUS_DEMURRAGE').additional_percentage ||
        //         this.getRowByKey('PLUS_DEMURRAGE').percentage_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Brokerage Commission" + (this.getRowByKey('PLUS_DEMURRAGE')?.additional_info?.comment ? ' ' +  this.getRowByKey('PLUS_DEMURRAGE')?.additional_info.comment : ''), null, null, null, null, this.getRowByKey('PLUS_DEMURRAGE').additional_percentage ? this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').additional_percentage + '%') : null, null, this.getRowByKey('PLUS_DEMURRAGE').percentage_amount<0 ? ('('+this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').percentage_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').percentage_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('PLUS_DEMURRAGE')?.length; i++) {
          let row = this.getRowsByKey('PLUS_DEMURRAGE')[i]
          if(row && (row.additional_percentage || row.additional_percentage_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Brokerage Commission" + (row?.additional_info?.comment ? ' ' +  row?.additional_info.comment : ''), null, null, null, null, row.additional_percentage ? this.getValueFromXml(row.additional_percentage + '%') : null, null, row.percentage_amount<0 ? ('('+this.getValueFromXml(row.percentage_amount*(-1)) + ')') : this.getValueFromXml(row.percentage_amount)]})
        }
        // if(this.getRowByKey('PLUS_DEMURRAGE') &&
        //     (this.getRowByKey('PLUS_DEMURRAGE').third_percentage ||
        //         this.getRowByKey('PLUS_DEMURRAGE').third_percentage_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Tax on Brokerage Commission" + (this.getRowByKey('PLUS_DEMURRAGE')?.additional_info?.comment_tax ? ' ' + this.getRowByKey('PLUS_DEMURRAGE')?.additional_info.comment_tax : ''), null, null, null, null, this.getRowByKey('PLUS_DEMURRAGE').third_percentage ? this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').third_percentage + '%') : null, null, this.getRowByKey('PLUS_DEMURRAGE').third_percentage_amount < 0 ? ('(' +this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').third_percentage_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('PLUS_DEMURRAGE').third_percentage_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('PLUS_DEMURRAGE')?.length; i++) {
          let row = this.getRowsByKey('PLUS_DEMURRAGE')[i]
          if(row && (row.third_percentage || row.third_percentage_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Tax on Brokerage Commission" + (row?.additional_info?.comment_tax ? ' ' + row?.additional_info.comment_tax : ''), null, null, null, null, row.third_percentage ? this.getValueFromXml(row.third_percentage + '%') : null, null, row.third_percentage_amount < 0 ? ('(' +this.getValueFromXml(row.third_percentage_amount*(-1)) + ')') : this.getValueFromXml(row.third_percentage_amount)]})
        }
        // if(this.getRowByKey('LESS_DISPATCH') &&
        //     (this.getRowByKey('LESS_DISPATCH').lay_report ||
        //         this.getRowByKey('LESS_DISPATCH').total_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Dispatch", null, null, null, null, this.getRowByKey('LESS_DISPATCH').lay_report ? (this.getRowByKey('LESS_DISPATCH').lay_report.loading_port ? (this.getValueFromXml(this.getRowByKey('LESS_DISPATCH').lay_report.loading_port.name) + " (Load.)" + ((this.getRowByKey('LESS_DISPATCH').lay_report.loading_port && this.getRowByKey('LESS_DISPATCH').lay_report.discharge_port) ? ' - ': '') +  (this.getRowByKey('LESS_DISPATCH').lay_report.discharge_port ? (this.getRowByKey('LESS_DISPATCH').lay_report.discharge_port.name ? (this.getValueFromXml(this.getRowByKey('LESS_DISPATCH').lay_report.discharge_port.name) + ' (Disch.)') : ''): '')): null) : null, null, this.getRowByKey('LESS_DISPATCH').total_amount ? this.getValueFromXml(this.getRowByKey('LESS_DISPATCH').total_amount) : null]})
        // }
        for(let i=0; i<this.getRowsByKey('LESS_DISPATCH')?.length; i++) {
          let row = this.getRowsByKey('LESS_DISPATCH')[i]
          if(row && (row.lay_report || row.total_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Dispatch" + (row.additional_info.comment ? ' ' + row.additional_info.comment : ''), null, null, null, null, row.lay_report ? (row.lay_report.loading_port ? (this.getValueFromXml(row.lay_report.loading_port.name) + " (Load.)" + ((row.lay_report.loading_port && row.lay_report.discharge_port) ? ' - ': '') +  (row.lay_report.discharge_port ? (row.lay_report.discharge_port.name ? (this.getValueFromXml(row.lay_report.discharge_port.name) + ' (Disch.)') : ''): '')): null) : null, null, row.total_amount ? this.getValueFromXml(row.total_amount) : null]})
        }
        // if(this.getRowByKey('PLUS_DETENTION') &&
        //     this.getRowByKey('PLUS_DETENTION').total_amount) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus detention", null, null, null, null, this.getRowByKey('PLUS_DETENTION').total_amount<0 ? ('('+this.getValueFromXml(this.getRowByKey('PLUS_DETENTION').total_amount*(-1)) +')') : this.getValueFromXml(this.getRowByKey('PLUS_DETENTION').total_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('PLUS_DETENTION')?.length; i++) {
          let row = this.getRowsByKey('PLUS_DETENTION')[i]
          if(row && (row.total_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus detention" + (row.additional_info.comment ? ' ' + row.additional_info.comment : ''), null, null, null, null, null, null, row.total_amount<0 ? ('('+this.getValueFromXml(row.total_amount*(-1)) +')') : this.getValueFromXml(row.total_amount)]})
        }
        // if(this.getRowByKey('PLUS_DETENTION') &&
        //     (this.getRowByKey('PLUS_DETENTION').percentage ||
        //         this.getRowByKey('PLUS_DETENTION').percentage_amount)) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Comission", null, null, null, null, this.getRowByKey('PLUS_DETENTION').percentage ? this.getValueFromXml(this.getRowByKey('PLUS_DETENTION').percentage + '%') : null, null, this.getRowByKey('PLUS_DETENTION').percentage_amount < 0 ? ('('+this.getValueFromXml(this.getRowByKey('PLUS_DETENTION').percentage_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('PLUS_DETENTION').percentage_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('PLUS_DETENTION')?.length; i++) {
          let row = this.getRowsByKey('PLUS_DETENTION')[i]
          if(row && (row.percentage || row.percentage_amount))
                formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Comission" + (row.additional_info.comment_tax ? ' ' + row.additional_info.comment_tax : ''), null, null, null, null, row.percentage ? this.getValueFromXml(row.percentage + '%') : null, null, row.percentage_amount < 0 ? ('('+this.getValueFromXml(row.percentage_amount*(-1)) + ')') : this.getValueFromXml(row.percentage_amount)]})
        }
        // if(this.getRowByKey('PLUS_CHARTERERS_EXPENSES') &&
        //     this.getRowByKey('PLUS_CHARTERERS_EXPENSES').total_amount) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus Charterers' expenses", null, null, null, null, null, null, this.getRowByKey('PLUS_CHARTERERS_EXPENSES').total_amount<0 ? ('('+this.getValueFromXml(this.getRowByKey('PLUS_CHARTERERS_EXPENSES').total_amount*(-1)) + ')') : this.getValueFromXml(this.getRowByKey('PLUS_CHARTERERS_EXPENSES').total_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('PLUS_CHARTERERS_EXPENSES')?.length; i++) {
          let row = this.getRowsByKey('PLUS_CHARTERERS_EXPENSES')[i]
          if(row && (row.total_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus Charterers' expenses" + (row.additional_info.comment ? ' ' + row.additional_info.comment : ''), null, null, null, null, null, null, row.total_amount<0 ? ('('+this.getValueFromXml(row.total_amount*(-1)) + ')') : this.getValueFromXml(row.total_amount)]})
        }
        // if(this.getRowByKey('LESS_OWNERS_EXPENSES') &&
        //     this.getRowByKey('LESS_OWNERS_EXPENSES').total_amount) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Owners' expenses", null, null, null, null, null, null, this.getRowByKey('LESS_OWNERS_EXPENSES').total_amount<0 ? ('('+this.getValueFromXml(this.getRowByKey('LESS_OWNERS_EXPENSES').total_amount)+')') : this.getValueFromXml(this.getRowByKey('LESS_OWNERS_EXPENSES').total_amount)]})
        // }
        for(let i=0; i<this.getRowsByKey('LESS_OWNERS_EXPENSES')?.length; i++) {
          let row = this.getRowsByKey('LESS_OWNERS_EXPENSES')[i]
          if(row && (row.total_amount))
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Owners' expenses" + (row.additional_info.comment ? ' ' + row.additional_info.comment : ''), null, null, null, null, null, null, row.total_amount ? this.getValueFromXml(row.total_amount) : null]})
        }
        if(this.getRowByKey('LESS_RECEIVED')) {
          if(this.getRowByKey('LESS_RECEIVED')?.additional_info?.payments) {
            if(Array.isArray(this.getRowByKey('LESS_RECEIVED').additional_info.payments) === true) {
              this.getRowByKey('LESS_RECEIVED').additional_info.payments.map(payment=> {
                formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Received " + (payment.date ? this.getValueFromXml(payment.date) : ''), null, null, null, null, null, null, payment.amount<0 ? ('('+this.getValueFromXml(payment.amount*(-1))+')') : this.getValueFromXml(payment.amount)]})
              })
            }
          }
        }

        // if(this.getRowByKey('LESS_OWNERS_EXPENSES') &&
        //     this.getRowByKey('LESS_OWNERS_EXPENSES').total_amount) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Owners' expenses", null, null, null, null, null, null, null, null, null, null, this.getRowByKey('LESS_OWNERS_EXPENSES').total_amount ? this.getValueFromXml(this.getRowByKey('LESS_OWNERS_EXPENSES').total_amount) : null]})
        // }
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["TOTAL DUE TO OWNERS", null, null, null, null, null, null, this.getValueFromXml(this.invoice.total_amount)]})
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
        //
        // if(this.invoice?.blocks?.length)
        // formAxios.data.push({id: formAxios.data.length + 1, values: [this.invoice.blocks[0].title + " (ILOW "+ this.invoice.blocks[0].percentage +"%)",  null, null, null, null, null, this.getValueFromXml(this.invoice.blocks[0].mt_amount) + " mt", null, this.getValueFromXml(this.invoice.blocks[0].rate), null, null, this.getValueFromXml(this.invoice.blocks[0].total_amount)]})
        // formAxios.data.push({id: formAxios.data.length + 1, values: ["TOTAL", null, null, null, null, null, null, null, null, null, null, this.invoice.blocks?.length ? this.getValueFromXml(this.invoice.blocks[0].total_amount) : null]})
        // formAxios.data.push({id:formAxios.data.length + 1, values: []})
        // formAxios.data.push({id: formAxios.data.length + 1, values: ["Description", null, null, null, null, null, null, null, "Percent", null, null, "Amount"]})
        // if(this.getRowByKey('THEREOF')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Thereof", null, null, null, null, null, null, null, this.getValueFromXml(this.getRowByKey('THEREOF').percentage) !== null ?  + this.getValueFromXml(this.getRowByKey('THEREOF').percentage) + "%" : "%", null, null, this.getValueFromXml(this.getRowByKey('THEREOF').total_amount)]})
        // }
        // if(this.getRowByKey('LESS_ADDRESS_COMMISSION')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Address Commission", null, null, null, null, null, null, null, this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').percentage) !== null ? this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').percentage) + "%" : "%", null, null, this.getValueFromXml(this.getRowByKey('LESS_ADDRESS_COMMISSION').total_amount)]})
        // }
        // if(this.getRowByKey('LESS_BROKERAGE_COMMISSION')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Brokerage Commission (Tax " + this.getRowByKey('LESS_BROKERAGE_COMMISSION').additional_percentage + ")", null, null, null, null, null, null, null, this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').percentage) + "%", null, null ,this.getValueFromXml(this.getRowByKey('LESS_BROKERAGE_COMMISSION').total_amount)]})
        // }
        // if(this.getRowByKey('PLUS_DEMURRAGE')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus Demurrage (port info...)", null, null, null, null, null, null, null, null, null, null, "0000"]})
        // }
        // if(this.getRowByKey('PLUS_DEMURRAGE')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Comission", null, null, null, null, null, null, null, "0000 %", null, null, "(0000)"]})
        // }
        // if(this.getRowByKey('LESS_DISPATCH')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Dispatch (port info...)", null, null, null, null, null, null, null, null, null, this.getValueFromXml(this.getRowByKey('LESS_DISPATCH').total_amount)]})
        // }
        // if(this.getRowByKey('PLUS_DETENTION')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus detention", null, null, null, null, null, null, null, null, null, this.getValueFromXml(this.getRowByKey('PLUS_DETENTION').mt_amount)]})
        // }
        // if(this.getRowByKey('PLUS_DETENTION')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Comission", null, this.getRowByKey('PLUS_DETENTION').percentage + "%", this.getValueFromXml(this.getRowByKey('PLUS_DETENTION').total_amount)]})
        // }
        // if(this.getRowByKey('PLUS_CHARTERERS_EXPENSES')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Plus Charterers' expenses", null, null, this.getValueFromXml(this.getRowByKey('PLUS_CHARTERERS_EXPENSES').total_amount)]})
        // }
        // if(this.getRowByKey('LESS_OWNERS_EXPENSES')) {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Less Owners' expenses", null, null, this.getValueFromXml(this.getRowByKey('LESS_OWNERS_EXPENSES').total_amount)]})
        // }
        // formAxios.data.push({id: formAxios.data.length + 1, values: ["Total due to owners", null, null, null, null, null, null, null, null, null, null, this.getValueFromXml(this.invoice.total_amount)]})
        // formAxios.data.push({id:formAxios.data.length + 1, values: []})
      }
      if(this.invoice.note) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Note"]})
        formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(this.invoice.note)]})
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
      }

      formAxios.data.push({id: formAxios.data.length + 1, values: [ this.invoice?.total_amount ? ("Please remit" + ( this.invoice.currency ? ' ' + this.getValueFromXml(this.invoice.currency) : '') + (this.invoice.total_amount ? ' ' + this.getValueFromXml(this.invoice.total_amount) : '') + " to following bank account not later than on" + (this.invoice.due_date ? ' ' + this.getValueFromXml(this.invoice.due_date) + ':' : '')) : null, null, null, null, null, null, null, this.invoice.stamp ? (this.invoice.color ? 'http://'+this.getIP()+'/media/img_stamp_color.jpg' : 'http://'+this.getIP()+'/media/img_stamp_bw.jpg') : null]})
      if(this.invoice.bank_details) {
        if(this.invoice?.account?.holder)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Holder:", this.getValueFromXml(this.invoice.account.holder)]})
        if(this.invoice?.account?.registered_address)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Registered Address:", this.getValueFromXml(this.invoice.account.registered_address)]})
        if(this.invoice?.account?.account_number)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Account Number:", this.invoice.account.account_number]})
        if(this.invoice?.account?.iban)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["IBAN:", this.invoice.account.iban]})
        if(this.invoice?.account?.description)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Description:", this.invoice.account.description]})
        if(this.invoice?.account?.bank)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Bank:", this.invoice.account.bank]})
        if(this.invoice.account?.clearing_number)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Clearing number:", this.invoice.account.clearing_number]})
        if(this.invoice?.account?.bic)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["BIC / SWIFT:", this.invoice.account.bic]})
        if(this.invoice.account?.currency)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Currency:", this.getValueFromXml(this.invoice.account.currency)]})
      }
      formAxios.data.push({id: formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: [ this.getValueFromXml('Payment: No later than on ' + (this.invoice.due_date && this.invoice.due_date !== null ? this.getValueFromXml(this.invoice.due_date) : ''))]})
      // formAxios.data.push({id: formAxios.data.length + 1, values: []})
      // formAxios.data.push({id:formAxios.data.length + 1, values: [(this.getCharterer && this.getCharterer.owner) ? 'With reference: m/v ' + this.getValueFromXml(this.getCharterer.name) + ' / ' + this.getValueFromXml(this.getCharterer.owner.company_name) : null]})

      let name = "Freight Invoice";
      if(this.$route.path.indexOf('vsoa')>=0) {
        name = "VSOA"
      }
      if(this.$route.path.indexOf('detention')>=0) {
        name = 'Detention Invoice'
      }
      if(this.$route.path.indexOf('additional_freight')>=0) {
        name = 'Additional Freight Invoice'
      }
      if(sessionStorage.getItem('chrtrs_active_tab'))
        name = sessionStorage.getItem('chrtrs_active_tab')
      this.axiosGetXLSX(formAxios).then(response => {

        let link = response.data;
        function download(content, contentType, fileName) {
          var link1 = document.createElement("a");
          link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
          link1.download = fileName;
          link1.click();
        }
        download(link, "application/vnd.ms-excel", name+".xls")
      });
    },
    getIP() {
      return ''+process.env.VUE_APP_URL_SOCKET+':8080'
    },
    formatDate(value) {
      if (!value) return ''
      return moment(value).utcOffset(6).format('DD.MM.YYYY')
    },
    formatTime(value) {
      if (!value) return ''
      return moment(value).format('HH:mm')
    },

  }
}
</script>

<style lang="scss">
.invoice_data{
  max-width: 868px;
}
//.pdf-content-inv {
//  background-color: red;
//}
.w-invoice-pdf {
  width: 820px;
}
</style>
<style lang="scss" scoped>
.pdf-inc {
  .content-wrapper {
    &>div {
      width: 100% !important;
    }
  }
}
.pdf-wrap {
  width: 815px;
}
</style>