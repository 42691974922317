import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import { BootstrapVue } from 'bootstrap-vue'
import browserDetect from "vue-browser-detect-plugin"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VTooltip from 'v-tooltip'
import ClickOutside from 'vue-click-outside'
import mitt from 'mitt'
import components from '@/components/globalCustomComponents/'
import GlobalMixins from '@/mixins/GlobalMixins';
import VueToast from 'vue-toast-notification';
import VueColumnsResizable from 'vue-columns-resizable';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast)
Vue.use(ClickOutside)
Vue.use(VTooltip)
Vue.use(VueCookies);
Vue.use(browserDetect);
Vue.use(require('vue-moment'));
Vue.use(VueAxios, axios);
Vue.use(browserDetect);
Vue.use(BootstrapVue);
Vue.use(VueColumnsResizable);

// import firebase from 'firebase'
// import "firebase/auth"
import VueVirtualScroller from 'vue-virtual-scroller'
import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue);
Vue.use(VueVirtualScroller)
Vue.mixin(GlobalMixins);


// initializeApp(firebaseConfig);
import firebase from 'firebase/app'
import "firebase/auth"

Vue.use(firebase)

const firebaseConfig = {
  apiKey: "AIzaSyBZb6ZG-wza-38EEaZSCa898St_MNbTdk8",
  authDomain: "slm-project-8c781.firebaseapp.com",
  projectId: "slm-project-8c781",
  storageBucket: "slm-project-8c781.appspot.com",
  messagingSenderId: "931959240353",
  appId: "1:931959240353:web:2d75b4c16813847891e2e0",
  measurementId: "G-L9BR20Y92X"
};

firebase.initializeApp(firebaseConfig);
// Vue.use(IconsPlugin);

components.forEach(component => {
  Vue.component(component.name, component)
});
// const emitter = mitt();
Vue.prototype.$myEmitter = mitt();

//
// Vue.directive('focus', {
//   // Когда привязанный элемент вставлен в DOM...
//   inserted: function (el) {
//     // console.log(el)
//     // Переключаем фокус на элемент
//     // el.focus().length
//   }
// });

Vue.filter('formatDate', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('DD.MM.YYYY')
})
Vue.filter('formatDateWithotTZ', function (value) {
  if (!value) return ''
  return moment(value).format('DD.MM.YYYY')
})
Vue.filter('formatDateCpDate', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('DD.MM.YYYY')
})
Vue.filter('formatDateFromCpDate', function (value) {
  if (!value) return ''
  return moment(value, 'YYYY-MM-DD').utcOffset(2).format('DD.MM.YYYY')
})
Vue.filter('formatDateFromCpDateWithotTimeZome', function (value) {
  // console.log('value::: ', value);
  if (!value) return ''
  return moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY')
})
Vue.filter('formatDateDDMM', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('D, MMM')
})
Vue.filter('formatDateMMDD', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('MMM, D').replace(/[\s.,%]/g, ' ')
})
Vue.filter('formatDateDDMMWithoutTimeZone', function (value) {
  if (!value) return ''
  return moment.utc(value).format('D, MMM')
})
Vue.filter('formatDateMonth', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('DD MMM YYYY')
})
Vue.filter('formatDateMonthWithoutTZ', function (value) {
  if (!value) return ''
  return moment(value).format('DD MMM YYYY')
})
Vue.filter('formatTime', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('HH:mm')
})
Vue.filter('formatTimeWithoutUtc', function (value) {
  if (!value) return ''
  return moment(value).format('HH:mm')
})
Vue.filter('formatDataYear', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('YYYY')
})
Vue.filter('formatDateAndTime', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('DD.MM.YYYY, HH:mm')
})
Vue.filter('formatDateAndTime2', function (value) {
  if (!value) return ''
  return moment(value).format('DD.MM.YYYY, HH:mm')
})
Vue.filter('formatDateAndTimeSpace', function (value) {
  if (!value) return ''
  return moment(value).utcOffset(2).format('DD MMM YYYY, HH:mm')
})
Vue.filter('formatDateAndTimeSpace2', function (value) {
  if (!value) return ''
  return moment(value).format('DD MMM YYYY, HH:mm')
})
Vue.filter('formatDateAndTimeSpace2AndSecond', function (value) {
  if (!value) return ''
  return moment(value).format('DD MMM YYYY, HH:mm:ss')
})
Vue.filter('formatNumber', function (value) {
  let fixedNumber = parseFloat(value)
  if(''+fixedNumber === '-Infinity' || ''+fixedNumber === 'Infinity') {
    fixedNumber = 0
  }
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})
  return fixedNumber
})

Vue.filter('formatNumber5', function (value) {
  let fixedNumber = parseFloat(value)
  if(''+fixedNumber === '-Infinity' || ''+fixedNumber === 'Infinity') {
    fixedNumber = 0
  }
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 5, maximumFractionDigits: 5})
  return fixedNumber
})

Vue.filter('formatNumberTwoInf', function (value) {
  let fixedNumber = parseFloat(value)
  if(''+fixedNumber === '-Infinity' || ''+fixedNumber === 'Infinity') {
    fixedNumber = 0
  }
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  return fixedNumber
})
Vue.filter('formatNumberTwo', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  return fixedNumber
})
Vue.filter('formatNumberFive', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 5, maximumFractionDigits: 5})
  return fixedNumber
})
Vue.filter('formatNumberAbs', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : 0
  if(fixedNumber < 0) {
    fixedNumber*=-1
  }
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  return fixedNumber
})
Vue.filter('formatNumberTwoEmptyStick', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : '-'
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  return fixedNumber
})
Vue.filter('formatNumberTwoAbsBrackets', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : 0
  let brackets = false;
  if(fixedNumber<0) {
    brackets = true
    fixedNumber = fixedNumber*(-1)
  }
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  if(brackets) {
    return '('+(fixedNumber)+')'
  }
  return fixedNumber
})
Vue.filter('formatNumberTwoEmptyStickABSMinusBrackets', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : '-'
  let brackets = false;
  if(fixedNumber !== '-' && fixedNumber<0) {
    brackets = true
    fixedNumber = fixedNumber*(-1)
  }
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  if(brackets) {
    return '('+(fixedNumber)+')'
  }
  return fixedNumber
})

Vue.filter('formatNumberThree', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 3, maximumFractionDigits: 3})
  return fixedNumber
})
Vue.filter('formatNumberFour', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})
  return fixedNumber
})
Vue.filter('formatNumberTwoWithoutEmptyAdditional', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : 0
  if(fixedNumber !== 0)
    fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  return fixedNumber
})
Vue.filter('formatNumberThreeWithoutEmptyAdditional', function (value) {
  let fixedNumber = parseFloat(value)
  fixedNumber = fixedNumber ? fixedNumber : 0
  if(fixedNumber !== 0)
    fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 3, maximumFractionDigits: 3})
  return fixedNumber
})
Vue.filter('formatNumberAbs', function (value) {
  let fixedNumber = Math.abs(parseFloat(value))
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})
  return fixedNumber
})
Vue.filter('formatNumberTwoAbs', function (value) {
  let fixedNumber = Math.abs(parseFloat(value))
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  return fixedNumber
})
Vue.filter('formatNumberZero', function (value) {
  let fixedNumber = Math.abs(parseFloat(value))
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})
  return fixedNumber
})
Vue.filter('formatNumberZeroNotAbs', function (value) {
  let fixedNumber = (parseFloat(value))
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 0})
  return fixedNumber
})
Vue.filter('formatNumberZeroEmpty', function (value) {
  let fixedNumber = parseFloat(value)
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})
  return fixedNumber
})
Vue.filter('formatNumberZeroEmptyFive', function (value) {
  let fixedNumber = parseFloat(value)
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 5, maximumFractionDigits: 5})
  return fixedNumber
})
Vue.filter('formatNumberTwoZeroEmpty', function (value) {
  let fixedNumber = parseFloat(value)
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  return fixedNumber
})
Vue.filter('formatNumberFourZeroEmpty', function (value) {
  let fixedNumber = parseFloat(value)
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})
  return fixedNumber
})

Vue.filter('formatNumberThreeZeroEmpty', function (value) {
  let fixedNumber = parseFloat(value)
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 3, maximumFractionDigits: 3})
  return fixedNumber
})
Vue.filter('formatNumberSOAZeroEmpty', function (value) {
  let fixedNumber = parseFloat(value)
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: window.location.href.indexOf('soa') >= 0 ? 3 : 2, maximumFractionDigits: window.location.href.indexOf('soa') >= 0 ? 3 : 2})
  return fixedNumber
})
Vue.filter('formatNumberSOAZeroEmptyThree', function (value) {
  let fixedNumber = parseFloat(value)
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 3, maximumFractionDigits: 3})
  return fixedNumber
})

Vue.filter('formatNumberZeroEmptyAbs', function (value) {
  let fixedNumber = Math.abs(parseFloat(value))
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4})/* .replace(',', ', ') */
  return fixedNumber
})
Vue.filter('formatNumberTwoZeroEmptyAbs', function (value) {
  let fixedNumber = Math.abs(parseFloat(value))
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})/* .replace(',', ', ') */
  return fixedNumber
})
Vue.filter('formatNumberThreeZeroEmptyAbs', function (value) {
  let fixedNumber = Math.abs(parseFloat(value))
  if(!fixedNumber || fixedNumber == 0) return ''
  fixedNumber = fixedNumber ? fixedNumber : 0
  fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 3, maximumFractionDigits: 3})/* .replace(',', ', ') */
  return fixedNumber
})

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

Vue.config.productionTip = false;
// OneSignalVue.ini("f981005a-3ff6-4888-a47c-bb47136481fa");
new Vue({
  router,
  store,
  render: h => h(App),
  // beforeMount() {
  //   this.$OneSignal.init({ appId: 'f981005a-3ff6-4888-a47c-bb47136481fa' });
  // }
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt()
  }
}).$mount('#app');

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error?.message) {
    if((""+error.message).indexOf('401') >= 0 || (""+error.message).indexOf('502') >= 0 || (""+error.message).toLowerCase().indexOf('token') >= 0) {
      store.commit('deleteDataUserWithRouterPushLogin')
    }
  }
  return Promise.reject(error);
});

