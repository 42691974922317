<template>
  <modal-slots class="modal" :textForSecondButton="'Save'" :show-mod="true" :noCloseAfterApply="true" @close="$emit('close')" @apply="apply">
    <template v-slot:title>
      <p class="fz18 fw500 text-dark">Edit Name</p>
    </template>
    <div class="p0-16 mt32 mb32 h36">
      <input :class="{'border-color-red': errorName || errorExistName}" type="text" v-model="name">
      <p class="text-red fz12 mt4" v-if="errorExistName">{{item.file_type == 'FOLDER' ? 'Folder' : 'File' }} with the same name already exists</p>
      <p class="text-red fz12 mt4" v-if="errorName">Enter at least one character</p>


    </div>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  props: {
    item: null,
  },
  name: "folders-modal-edit-name",
  components: {ModalSlots},
  computed: {
    ...mapState({
      folders: state=> state.folders.folders,
      files: state=> state.folders.files,
    })
  },
  data() {
    return {
      name: '',
      errorName: false,
      formatFile: null,
      errorExistName: false,
    }
  },
  methods: {
    ...mapActions(['axiosPutFileForFolders', 'axiosSetAccessUsersForFolders']),
    ...mapMutations(['showLoad', 'hideLoad', 'createEmitGetActivityFolders', 'changeNameForFolderLeftMenu']),
    apply() {
      if(!this.name) {
        this.errorName = true
        return null
      } else {
        this.errorName = false
      }
      if(this.item.file_type == 'FOLDER' ? (this.folders.find(a=> a.name == this.name && a.id !== this.item.id)) : (this.files.find(a=> a.name == this.name + this.formatFile && a.id !== this.item.id))) {
        this.name = ''
        this.errorExistName = true
        return null
      } else {
        this.errorExistName = true
      }
      this.showLoad()

      if(this.formatFile) {
        this.item.name = this.name+this.formatFile
      } else {
        this.item.name = this.name
      }
      // let form = JSON.parse(JSON.stringify(this.item))
      // delete form.id
      // delete form.user
      // form.shared_with = form.shared_with.map(a=> {return a.id})
      // form['is_ftp'] = false
      // if(this.$route.path.indexOf('my-files') >= 0) {
      //   form['is_ftp'] = true
      //   // delete form.shared_with
      // }
      // this.axiosPutFileForFolders({data: form, id: this.item.id}).then(res=> {
      //   // this.changeNameForFolderLeftMenu({id: res.id,name: res.name})
      //   this.createEmitGetActivityFolders()
      //   this.$emit('close')
      //   this.hideLoad()
      // })
      this.axiosSetAccessUsersForFolders({data: {ids: [this.item.id], physically_move_file: this.$route.path.indexOf('my-files') >= 0, name: this.item.name}}).then(res=> {
          this.changeNameForFolderLeftMenu({id: res.data[0].id,name: res.data[0].name})
        this.createEmitGetActivityFolders()
        this.$emit('close')
        this.hideLoad()
      })
    },
  },
  mounted() {
    this.name = this.item ? this.item.name : ''
    if(this.item.file_type === 'FILE') {
      this.formatFile = this.item.name.slice(this.item.name.lastIndexOf('.'), this.item.name.length)
      this.name = this.name.slice(0, this.item.name.lastIndexOf('.'))
    }
  }
}
</script>

<style scoped lang="scss">
.modal {

}
/deep/ {
  .top-panel {
    padding: 14px 16px !important;
  }
  .content {
    min-width: 372px !important;
  }
}
</style>