<template>
  <div class="border-r border-dark-gray">
    <div class="p-x-1.5 p-y-1 border-b border-dark-gray bg-gray-dark uppercase fz13 fw500" :class="{'rounded-tr-lg': title === 'Slow Speed'}">{{title}}</div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Sp Ball'">Sp Ball</div>
      <div class="w-7/12 bg-white relative" v-tooltip="vessel[prefix+'_sp_ball'] ? `${vessel[prefix+'_sp_ball']}` : null">
        <input
          v-model.number.lazy="vessel[prefix+'_sp_ball']"
          class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
          type="number" 
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
        >
        <span class="absolute right-2 centered-y fz13 text-gray-text">kt</span>
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Ifo Ball'">Ifo Ball</div>
      <div class="w-7/12 bg-white relative" v-tooltip="vessel[prefix+'_ifo_ball'] ? `${vessel[prefix+'_ifo_ball']}` : null">
        <input
          v-model.number.lazy="vessel[prefix+'_ifo_ball']"
          class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
          type="number" 
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
        >
        <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Mgo Ball'">Mgo Ball</div>
      <div class="w-7/12 bg-white relative" v-tooltip="vessel[prefix+'_mgo_ball'] ? `${vessel[prefix+'_mgo_ball']}` : null">
        <input
          v-model.number.lazy="vessel[prefix+'_mgo_ball']"
          class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
          type="number" 
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
        >
        <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Sp Lad'">Sp Lad {{prefix}}</div>
      <div class="w-7/12 bg-white relative" v-tooltip="vessel[prefix+'_sp_lad'] ? `${vessel[prefix+'_sp_lad']}` : null">
        <input
          v-model.number.lazy="vessel[prefix+'_sp_lad']"
          class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
          type="number" 
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
        >
        <span class="absolute right-2 centered-y fz13 text-gray-text">kt</span>
      </div>
    </div>
    <div class="flex border-b border-gray-light">
      <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Ifo Lad'">Ifo Lad</div>
      <div class="w-7/12 bg-white relative" v-tooltip="vessel[prefix+'_ifo_lad'] ? `${vessel[prefix+'_ifo_lad']}` : null">
        <input
          v-model.number.lazy="vessel[prefix+'_ifo_lad']"
          class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
          type="number" 
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
        >
        <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
      </div>
    </div>
    <div class="flex border-b border-dark-gray">
      <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Mgo Lad'">Mgo Lad</div>
      <div class="w-7/12 bg-white relative" v-tooltip="vessel[prefix+'_mgo_lad'] ? `${vessel[prefix+'_mgo_lad']}` : null">
        <input
          v-model.number.lazy="vessel[prefix+'_mgo_lad']"
          class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
          type="number" 
          @keypress="onlyNumber($event)"
          @keyup.enter="$event.target.blur()"
        >
        <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    title: String,
    prefix: String,
    vessel: Object
  },
  methods: {
    ...mapActions(['onlyNumber'])
  },
}
</script>

<style scoped>

</style>