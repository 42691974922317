<template>
  <div class="bank-aside_empty">
    <p class="bank-aside_empty__text fz16 fw500">No bank or cash account <br> has been created yet</p>
    <button class="bank-aside_empty__btn fz14" v-if="getPermissionsByType('create_new_bank_and_cash_accounts')" @click="$emit('add')">Create a new bank or cash account</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getPermissionsByType'])
  }
}
</script>

<style lang="scss">
  .bank-aside_empty{
    margin: auto 0;
    padding: 24px;
    text-align: center;
    &__text{
      margin-bottom: 8px;
    }
    &__btn{
      color: #094172;
    }
  }
</style>