<template>
  <div>
<!--    {{selectedProps}}-->
<!--    {{getPermissionsDepartmentsData}}-->
<!--    {{checkboxes}}-->
<!--    {{getDepartments}}-->
<!--    {{selected}}-->
    <b-form-checkbox-group
        @change="selectAll"
        v-model="status1"
    >
      <b-form-checkbox
          name="status"
          class="title"
      >
        <p class="fz14 fw500 text-dark1">{{ checkboxes.titleForButton }}</p>
      </b-form-checkbox>
    </b-form-checkbox-group>
<!--    {{getEmployees}}-->
<!--{{selectedProps}}-->
<!--    <br>-->
<!--    <br>-->
<!--    {{getPermissionsEditDepartment}}-->
    <b-form-group>
      <div
          v-for="(ch, i) in checkboxes.details"
          :key="i"
          class="check-wrap"
      >
        <b-form-checkbox
            @change="changeCheck"
            v-model="selected"
            :value="ch"
            name="flavour-3a"
            class="check"
        >
<!--          <pre>{{ch.name}}</pre>-->
          <div class="text-block">
            <span><p class="text1 fz14 text-dark-gray">{{ ucFirst(ch.original_name) }}</p> </span>
            <!--          <button v-if="ch.button2Show === true" v-on:click="showMenuRight1" class="everyone fz14 blue-button-text">-->
            <!--            {{ buttonText1 }}</button>-->
          </div>
        </b-form-checkbox>
        <span v-if="ch.button1Show === true && ch.button2Show !== true" v-on:click="showMenuRight({id: ch.id ,index: i})" class="everyone fz14 blue-button-text">
            {{ getButtonText(ch.buttonText) }}
        </span>
        <span v-if="ch.button2Show === true" v-on:click="showMenuAccount({title: checkboxes.titleForButton, title2: checkboxes.title, id: ch.id ,index: i, active: ch.related_account, defActive: ch.activeCheckboxInMenu.account})" class="everyone fz14 blue-button-text">
<!--            <pre>{{ch.activeCheckboxInMenu.account}}</pre>-->
          {{ getTextForButtonAccount(ch.related_account, ch.activeCheckboxInMenu.account) }}
        </span>
        <span v-if="ch.button3Show === true" v-on:click="showMenuColumn(i, ch.id, ch.activeCheckboxInMenu.columns)" class="everyone fz14 blue-button-text">
<!--            <pre>{{ch.activeCheckboxInMenu.account}}</pre>-->
          {{ getButtonTextColumn(ch.activeCheckboxInMenu.columns) }}
        </span>
      </div>

    </b-form-group>
<!--    {{selected.length}}-->
<!--    <pre>-->
<!--          {{selected}}-->
<!--    </pre>-->
<!--    <br>-->
<!--    <br>-->
<!--    {{// checkboxes.details.length}}-->

    <checkbox-right-menu ref="rightMenu" :idActive="idButtonOpenMenuRight" :indexActive="indexButtonOpenMenuRight" :titleActive="checkboxes.title" @save="save"></checkbox-right-menu>
<!--    <checkbox-right-menu ref="rightMenu1" @save="save1"></checkbox-right-menu>-->
  </div>
</template>

<script>
import CheckboxRightMenu from "@/components/addEmployeesStep2/checkbox-right-menu";
import {mapActions, mapGetters, mapMutations} from 'vuex'
import accounts from "@/views/accounts/accounts";
export default {
  components: {CheckboxRightMenu},
  props: ['checkboxes', "selectedProps"],
  name: "checkboxes",
  computed: mapGetters(["getPaymentsAccount", "getPermissionFromThisUser", "getPermissions", "getPermissionsAddDepartment", "getShowAddDepartment",
    "getPermissionsEditDepartment", "getShowEditDepartment", "getDepartments", "getPermissionsDepartmentsData", "getEmployees"]),
  watch: {
    selectedProps: function () {
      this.renderNew()
    },
  },
  data() {
    return {
      selected: [],
      status:false,
      status1: [],
      buttonText: "no one",
      buttonText1: "no one",
      buttonText2: "no one",
      idButtonOpenMenuRight: null,
      indexButtonOpenMenuRight: null,
      keyRender: 0,
    }
  },
  methods: {
    ...mapActions(["axiosGetPermissions"]),
    ...mapMutations(["changeActiveCheckboxInMenu", "setTextButton", "setClickButton", "setTextButtonAddDepartment", "changeActiveCheckboxInMenuAddDepartment", "setTextButtonEditDepartment", "changeActiveCheckboxInMenuEditDepartment"]),
    showMenuColumn(index, id, active) {
      this.$emit('showModalColumns', {index: index, id: id, active: active, titleEdit: this.checkboxes.title})
    },
    getButtonText(text) {
      if(text.length === 0) {
        return "no one"
      }
      return text
    },
    getButtonTextColumn(items) {
      if(Array.isArray(items) === true) {
        if(items.length !== 0) {
          let text = "";
          items.map(a=> {
            if(text.length === 0) {
              text += "" + a;
            } else {
              text = text + ", " + a
            }
          })
          text+='.'
          return text
        }
      }
      return "such columns"
    },
    showMenuAccount(data) {
      // if(!Array.isArray(data.active)) {
        data.active = data.defActive;
      // }

      this.$emit("openModalAccount", data)
    },
    getTextForButtonAccount(list, listDefaultActive) {
      if(Array.isArray(list)) {
        if(list.length !== 0) {
          return "" + list.map(a=> {
            return a.name
          });
        }
        // return "such Accounts"
      } else if(Array.isArray(listDefaultActive)) {
        if(listDefaultActive.length !== 0) {
          // let array = this.getPaymentsAccount.filter(a => listDefaultActive.indexOf(a.id) >=0)
          return "" + listDefaultActive.map(a=> {
            return a.name
          });
        }

      }
      return "such Accounts"

    },
    ucFirst(str) {
      if (!str) return str;
      str = str.replaceAll("_", " ")

      return str[0].toUpperCase() + str.slice(1);
    },
    changeCheck() {
      // // // console.log(this.selected.length);
      this.selected = this.selected.filter((thing, index, self) =>
          index === self.findIndex((t) => (
              t.id === thing.id
          ))
      )
      if(this.selected.length === this.checkboxes.details.length && this.selected.length !== 0) {
        this.status1 = ["true"];
        this.status = true;
      } else {
        this.status1 = [];
        this.status = false;
      }
      this.$emit("changeSelected", {title: this.checkboxes.title, selected: this.selected});
    },
    mountedSelectActiveCheckboxInMenuAll(data) {
      // console.log(2);
      // console.log("mountedSelectActiveCheckboxInMenuAll");
      // console.log(data);
      if (!this.getShowEditDepartment) {
        // console.log(3);
        this.changeActiveCheckboxInMenu({title: this.checkboxes.title, id: data.id, departments: data.departments, employees: data.employees, account: data.account, columns: data.columns});
      }
      this.changeActiveCheckboxInMenuEditDepartment({title: this.checkboxes.title, id: data.id, departments: data.departments, employees: data.employees, account: data.account, columns: data.columns});
      this.buttonText = "";
      let array = {};
      let perm = {};
      if (!this.getShowEditDepartment) {
        perm = this.getPermissions
      } else {
        perm = this.getPermissionsEditDepartment;
      }
      for(let i=0; i<perm.length; i++) {
        let indexBlock = i;
        if(perm[i].title === this.checkboxes.title) {
          // let indexForId = this.getPermissions[i].details.find(this.getPermissions[i].details.id === this.idButtonOpenMenuRight)
          array = perm[i].details.filter(d => d.id === data.id);
          // // // console.log(array);

          if(array.length !== 0) {
            // console.log("array[0].activeCheckboxInMenu");
            // console.log(array[0]);
            if(array[0].activeCheckboxInMenu != null) {
              for(let j=0; j<array[0].activeCheckboxInMenu.departments.length; j++) {
                this.buttonText += array[0].activeCheckboxInMenu.departments[j].name;
                if(j !== (array[0].activeCheckboxInMenu.departments.length - 1)) {
                  this.buttonText += ", ";
                } else {
                  if(array[0].activeCheckboxInMenu.employees.length !== 0) {
                    this.buttonText += ", "
                  } else {
                    this.buttonText += "."
                  }
                }
              }
              for(let j=0; j<array[0].activeCheckboxInMenu.employees.length; j++) {
                this.buttonText += array[0].activeCheckboxInMenu.employees[j].first_name + " " + array[0].activeCheckboxInMenu.employees[j].last_name;
                if(j !== (array[0].activeCheckboxInMenu.employees.length - 1)) {
                  this.buttonText += ", ";
                } else {
                  this.buttonText += "."
                }
              }

            }
          }
          let indexDetails = 0
          for(let r=0; r<perm[i].details.length; r++) {
            if(data.id === perm[i].details[r].id) {
              indexDetails = r;
            }
          }

          // this.checkboxes.details[this.indexButtonOpenMenuRight].textButton = this.buttonText;
          this.setTextButtonEditDepartment({indexBlock: indexBlock, indexDetails: indexDetails, text: this.buttonText});

          if (!this.getShowEditDepartment) {
            this.setTextButton({indexBlock: indexBlock, indexDetails: indexDetails, text: this.buttonText});
          }
          // this.$emit("renderCheckbox");
          // return;
        }
      }
    },
    save(data) {
      // this.keyRender++;
      if(this.getShowAddDepartment) {
        // // // console.log("ADDDEPRTMENT")
        // // // console.log("dep");
        // // // console.log(data.departments);
        // // // console.log("empl")
        // // // console.log(data.employees);
        this.changeActiveCheckboxInMenuAddDepartment({title: this.checkboxes.title, id: this.idButtonOpenMenuRight, departments: data.departments, employees: data.employees, columns: data.columns});
        this.buttonText1 = "";
        let array = {};
        for(let i=0; i<this.getPermissionsAddDepartment.length; i++) {
          let indexBlock = i;
          if(this.getPermissionsAddDepartment[i].title === this.checkboxes.title) {
            // let indexForId = this.getPermissions[i].details.find(this.getPermissions[i].details.id === this.idButtonOpenMenuRight)
            array = this.getPermissionsAddDepartment[i].details.filter(d => d.id === this.idButtonOpenMenuRight);
            // // // console.log("То что нужно");
            // // // console.log(array);
            // // // console.log(array[0].activeCheckboxInMenu);
            for(let j=0; j<array[0].activeCheckboxInMenu.departments.length; j++) {
              // // // console.log("departments");
              this.buttonText1 += array[0].activeCheckboxInMenu.departments[j].name;
              if(j !== (array[0].activeCheckboxInMenu.departments.length - 1)) {
                this.buttonText1 += ", ";
              } else {
                if(array[0].activeCheckboxInMenu.employees.length !== 0) {
                  this.buttonText1 += ", "
                } else {
                  this.buttonText1 += "."
                }
              }
            }
            for(let j=0; j<array[0].activeCheckboxInMenu.employees.length; j++) {
              this.buttonText1 += array[0].activeCheckboxInMenu.employees[j].first_name + " " + array[0].activeCheckboxInMenu.employees[j].last_name;
              if(j !== (array[0].activeCheckboxInMenu.employees.length - 1)) {
                this.buttonText1 += ", ";
              } else {
                this.buttonText1 += "."
              }
            }
            // this.checkboxes.details[this.indexButtonOpenMenuRight].textButton = this.buttonText;
            // // // console.log("text");
            // // // console.log(this.checkboxes.details[this.indexButtonOpenMenuRight]);
            this.setTextButtonAddDepartment({indexBlock: indexBlock, indexDetails: this.indexButtonOpenMenuRight, text: this.buttonText1});
            // this.$emit("renderCheckbox");
            return;
          }
        }
      } else if (this.getShowEditDepartment) {
        // // // console.log(33333333);
        this.changeActiveCheckboxInMenuEditDepartment({title: this.checkboxes.title, id: this.idButtonOpenMenuRight, departments: data.departments, employees: data.employees});
        this.buttonText2 = "";
        let array = {};
        for(let i=0; i<this.getPermissionsEditDepartment.length; i++) {
          let indexBlock = i;
          if(this.getPermissionsEditDepartment[i].title === this.checkboxes.title) {
            // let indexForId = this.getPermissions[i].details.find(this.getPermissions[i].details.id === this.idButtonOpenMenuRight)
            array = this.getPermissionsEditDepartment[i].details.filter(d => d.id === this.idButtonOpenMenuRight);
            // // // console.log("То что нужно");
            // // // console.log(array);
            // // // console.log(array[0].activeCheckboxInMenu);
            for(let j=0; j<array[0].activeCheckboxInMenu.departments.length; j++) {
              // // // console.log("departments");
              this.buttonText2 += array[0].activeCheckboxInMenu.departments[j].name;
              if(j !== (array[0].activeCheckboxInMenu.departments.length - 1)) {
                this.buttonText2 += ", ";
              } else {
                if(array[0].activeCheckboxInMenu.employees.length !== 0) {
                  this.buttonText2 += ", "
                } else {
                  this.buttonText2 += "."
                }
              }
            }
            for(let j=0; j<array[0].activeCheckboxInMenu.employees.length; j++) {
              this.buttonText2 += array[0].activeCheckboxInMenu.employees[j].first_name + " " + array[0].activeCheckboxInMenu.employees[j].last_name;
              if(j !== (array[0].activeCheckboxInMenu.employees.length - 1)) {
                this.buttonText2 += ", ";
              } else {
                this.buttonText2 += "."
              }
            }
            // this.checkboxes.details[this.indexButtonOpenMenuRight].textButton = this.buttonText;
            // // // console.log("text");
            // // // console.log(this.checkboxes.details[this.indexButtonOpenMenuRight]);
            this.setTextButtonEditDepartment({indexBlock: indexBlock, indexDetails: this.indexButtonOpenMenuRight, text: this.buttonText2});
            // this.$emit("renderCheckbox");
            return;
          }
        }
      } else {
        // // // console.log(22222222222222);
        // // // console.log({title: this.checkboxes.title, id: this.idButtonOpenMenuRight, departments: data.departments, employees: data.employees});
        this.changeActiveCheckboxInMenu({title: this.checkboxes.title, id: this.idButtonOpenMenuRight, departments: data.departments, employees: data.employees, account: data.account});
        this.buttonText = "";
        let array = {};
        for(let i=0; i<this.getPermissions.length; i++) {
          let indexBlock = i;
          if(this.getPermissions[i].title === this.checkboxes.title) {
            // let indexForId = this.getPermissions[i].details.find(this.getPermissions[i].details.id === this.idButtonOpenMenuRight)
            array = this.getPermissions[i].details.filter(d => d.id === this.idButtonOpenMenuRight);
            // // // console.log("То что нужно");
            // // // console.log(array);
            // // // console.log(array[0].activeCheckboxInMenu);
            for(let j=0; j<array[0].activeCheckboxInMenu.departments.length; j++) {
              // // // console.log("departments");
              this.buttonText += array[0].activeCheckboxInMenu.departments[j].name;
              if(j !== (array[0].activeCheckboxInMenu.departments.length - 1)) {
                this.buttonText += ", ";
              } else {
                if(array[0].activeCheckboxInMenu.employees.length !== 0) {
                  this.buttonText += ", "
                } else {
                  this.buttonText += "."
                }
              }
            }
            for(let j=0; j<array[0].activeCheckboxInMenu.employees.length; j++) {
              this.buttonText += array[0].activeCheckboxInMenu.employees[j].first_name + " " + array[0].activeCheckboxInMenu.employees[j].last_name;
              if(j !== (array[0].activeCheckboxInMenu.employees.length - 1)) {
                this.buttonText += ", ";
              } else {
                this.buttonText += "."
              }
            }
            // this.checkboxes.details[this.indexButtonOpenMenuRight].textButton = this.buttonText;
            // // // console.log("text");
            // // // console.log(this.checkboxes.details[this.indexButtonOpenMenuRight]);
            this.setTextButton({indexBlock: indexBlock, indexDetails: this.indexButtonOpenMenuRight, text: this.buttonText});
            // this.$emit("renderCheckbox");
            return;
          }
        }

      }

    },
    showMenuRight(data) {
      // for(let i=0; i< this.selected.length; i++) {
      //   // // console.log("id");
      //   // // console.log(data.id);
      //   // // console.log(this.selected[i].id);
      //   // if(data.id === this.selected[i].id) {
      //     // // console.log("YES");
      //     this.selected.splice(i, 1);
      //     i = 1000;
      //   // }
      // }
      this.setClickButton(data.index);
      this.indexButtonOpenMenuRight = data.index;

      this.idButtonOpenMenuRight = data.id;
      this.$refs.rightMenu.showMenu();
      this.$refs.rightMenu.setSelected();
    },
    // showMenuRight1() {
    //   this.$refs.rightMenu1.showMenu();
    //   this.$refs.rightMenu1.setSelected1();
    // },
    selectAll() {
      if(this.status === true) {
        this.status = false;
        this.selected.splice(0,this.selected.length);
      } else {
        this.status = true;
        this.selected.splice(0,this.selected.length);
        for(let i=0; i<this.checkboxes.details.length; i++) {
          // // // console.log(222);
          this.selected.push(this.checkboxes.details[i]);
        }
      }
      this.$emit("changeSelected", {title: this.checkboxes.title, selected: this.selected});

      // // // console.log(2);
      // // // console.log("L:"+this.checkboxes.details.length);
    },
    renderNew() {
      this.selected = [];
      // // console.log(this.selectedProps.permissions)
      if(this.selectedProps !== null) {
        console.log(this.getPermissions[0])
        if(this.selectedProps.permissions !== null && this.selectedProps.permissions !== undefined) {
          // // // console.log(1);
          for(let i=0; i<this.checkboxes.details.length; i++) {
            for(let j=0; j<this.selectedProps.permissions.length; j++) {
              if(this.checkboxes.details[i].id === this.selectedProps.permissions[j]) {
                this.selected.push(this.checkboxes.details[i]);
              }
            }
          }
          // console.log("a1")
          this.selected = this.selected.filter((thing, index, self) =>
              index === self.findIndex((t) => (
                  t.id === thing.id
              ))
          )
          this.$emit("changeSelected", {title: this.checkboxes.title, selected: this.selected});
          if(this.selected.length === this.checkboxes.details.length && this.selected.length !== 0) {
            this.status1 = ["true"];
            this.status = true;
          } else {
            this.status1 = [];
            this.status = false;
          }
          // console.log("a2")
            if(this.selectedProps.permissions.length !== 0 && this.selectedProps.permissionsUser !== true) {
              for(let i=0; i<this.getPermissions.length; i++) {
                for(let j=0; j<this.getPermissions[i].details.length; j++) {
                  let departments = [];
                  let employees = [];
                  let account = [];
                  let columns = [];
                  if(this.getPermissionsDepartmentsData !== null) {
                    for(let m=0; m<this.getPermissionsDepartmentsData.data.results.length; m++) {
                      // // // console.log("DATA")
                      // console.log("a3")
                      // console.log(i)
                      // console.log(j)
                      //
                      // console.log(this.getPermissions[i].details[j].id === undefined)
                      // console.log("a4")
                      // // // console.log(this.getPermissionsDepartmentsData.data.results[m].department);
                      // console.log(this.selectedProps.id);
                      // // // console.log(this.getPermissionsDepartmentsData.data.results[m].type);
                      // // // console.log(this.getPermissions[i].details[j].id);
                      if(this.getPermissionsDepartmentsData.data.results[m].department === this.selectedProps.id && this.getPermissionsDepartmentsData.data.results[m].type === this.getPermissions[i].details[j].id) {
                        // // console.log("Есть пробития")
                        // console.log("a4")
                        if(this.getPermissionsDepartmentsData.data.results[m].related_staff.length !==0) {
                          // // // console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
                          if(this.getEmployees !== null) {
                            for(let n=0; n<this.getPermissionsDepartmentsData.data.results[m].related_staff.length; n++) {
                              // employees.push(this.getPermissionsDepartmentsData.data.results[m].related_staff[n]);

                              for(let e=0; e<this.getEmployees.data.results.length; e++) {
                                // // console.log(this.getPermissionsDepartmentsData.data.results[m].related_staff[n])
                                // // console.log(this.getEmployees.data.results[e])
                                if(this.getPermissionsDepartmentsData.data.results[m].related_staff[n] === this.getEmployees.data.results[e].id) {
                                  employees.push(this.getEmployees.data.results[e]);
                                }
                              }
                            }
                          }

                        }
                        // console.log("a5")
                        if(this.getPermissionsDepartmentsData.data.results[m].related_department.length !==0) {
                          // // console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
                          for(let n=0; n<this.getPermissionsDepartmentsData.data.results[m].related_department.length; n++) {
                            // // // console.log(this.getPermissionsDepartmentsData.data.results[m].related_department[n])
                            // console.log("a6")
                            for(let d=0; d<this.getDepartments.data.results.length; d++) {
                              if(this.getPermissionsDepartmentsData.data.results[m].related_department[n] === this.getDepartments.data.results[d].id) {
                                departments.push(this.getDepartments.data.results[d]);
                              }
                            }
                            // .push(this.getPermissionsDepartmentsData.data.results[m].related_department[n]);
                          }
                        }
                        // console.log("a7")
                        account = this.getPermissionsDepartmentsData.data.results[m].related_account;
                        account = this.getPaymentsAccount.filter(a=> account.indexOf(a.id)>=0)
                        columns = this.getPermissionsDepartmentsData.data.results[m].such_columns !== null ? this.getPermissionsDepartmentsData.data.results[m].such_columns : []

                        if(employees.length !==0 || departments.length !== 0 || accounts.length !== 0 || columns.length !== 0) {
                          // console.log(employees)

                          this.mountedSelectActiveCheckboxInMenuAll({title: this.getPermissions[i].title, id: this.getPermissions[i].details[j].id, departments: departments, employees: employees, account: account, columns: columns})
                        }
                      }
                    }

                  }
                }
                // // // console.log("CHANESAJKDHSAKJDHAJS")
              }

            }
          else {
              if(this.selectedProps.permissionsUser === true && this.getEmployees !== null) {
                for(let i=0; i<this.getPermissions.length; i++) {
                  for(let j=0; j<this.getPermissions[i].details.length; j++) {
                    let departments = [];
                    let employees = [];
                    let account = [];
                    let columns = [];
                    for(let m=0; m<this.getPermissionFromThisUser.length; m++) {
                      if(this.getPermissionFromThisUser[m].type === this.getPermissions[i].details[j].id) {
                        if(this.getPermissionFromThisUser[m].related_staff.length !==0) {
                          for(let n=0; n<this.getPermissionFromThisUser[m].related_staff.length; n++) {
                              for(let e=0; e<this.getEmployees.data.results.length; e++) {
                                if(this.getPermissionFromThisUser[m].related_staff[n] === this.getEmployees.data.results[e].id) {
                                  employees.push(this.getEmployees.data.results[e]);
                                }
                              }

                          }
                        }
                        account = this.getPermissionFromThisUser[m].related_account;
                        account = this.getPaymentsAccount.filter(a=> account.indexOf(a.id)>=0)
                        columns = this.getPermissionFromThisUser[m].such_columns !== null ? this.getPermissionFromThisUser[m].such_columns : []

                        // if(employees.length !==0 || departments.length !== 0) {
                        // console.log(21);
                        this.mountedSelectActiveCheckboxInMenuAll({title: this.getPermissions[i].title, id: this.getPermissions[i].details[j].id, departments: departments, employees: employees, account: account, columns: columns})
                        // }
                      }
                    }
                  }
                }
              }
            }


        }

      }
    }
  },
  mounted() {
    console.log("CHECKBOXES");
    this.selected = [];
    // // // console.log(this.selectedProps.permissions)
    // // // console.log(23234332433);
    // console.log(1)
    if(this.selectedProps !== null) {
      if(this.selectedProps.permissions !== null && this.selectedProps.permissions !== undefined) {
        // // // console.log(1);
        // console.log(2)
        for(let i=0; i<this.checkboxes.details.length; i++) {
          for(let j=0; j<this.selectedProps.permissions.length; j++) {
            if(this.checkboxes.details[i].id === this.selectedProps.permissions[j]) {
              this.selected.push(this.checkboxes.details[i]);
            }
          }
        }
        this.selected = this.selected.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.id === thing.id
            ))
        )
        if(this.selected.length === this.checkboxes.details.length && this.selected.length !== 0) {
          this.status1 = ["true"];
          this.status = true;
        }
        // // // console.log("CHANESAJKDHSAKJDHAJS")
        this.$emit("changeSelected", {title: this.checkboxes.title, selected: this.selected});
        // console.log(3)
        if(this.selectedProps.permissions.length !== 0 && this.selectedProps.permissionsUser !== true) {
          // console.log(31)
          for(let i=0; i<this.getPermissions.length; i++) {
            // console.log(32)
            for(let j=0; j<this.getPermissions[i].details.length; j++) {
              // console.log(33)
              let departments = [];
              let employees = [];
              let account = [];
              let columns = [];
              // console.log(4)
              if(this.getPermissionsDepartmentsData !== null) {
                for(let m=0; m<this.getPermissionsDepartmentsData.data.results.length; m++) {
                  // // // console.log("DATA")
                  // // // console.log(this.getPermissionsDepartmentsData.data.results[m].department);
                  // // // console.log(this.selectedProps.id);
                  // // // console.log(this.getPermissionsDepartmentsData.data.results[m].type);
                  // // // console.log(this.getPermissions[i].details[j].id);
                  if(this.getPermissionsDepartmentsData.data.results[m].department === this.selectedProps.id && this.getPermissionsDepartmentsData.data.results[m].type === this.getPermissions[i].details[j].id) {
                    // console.log(5)
                    if(this.getPermissionsDepartmentsData.data.results[m].related_staff.length !==0) {
                      for(let n=0; n<this.getPermissionsDepartmentsData.data.results[m].related_staff.length; n++) {
                        for(let e=0; e<this.getEmployees.data.results.length; e++) {
                          if(this.getPermissionsDepartmentsData.data.results[m].related_staff[n] === this.getEmployees.data.results[e].id) {
                            employees.push(this.getEmployees.data.results[e]);
                          }
                        }
                      }
                    }
                    // console.log(6)
                    if(this.getPermissionsDepartmentsData.data.results[m].related_department.length !==0) {
                      for(let n=0; n<this.getPermissionsDepartmentsData.data.results[m].related_department.length; n++) {
                        for(let d=0; d<this.getDepartments.data.results.length; d++) {
                          if(this.getPermissionsDepartmentsData.data.results[m].related_department[n] === this.getDepartments.data.results[d].id) {
                            departments.push(this.getDepartments.data.results[d]);
                          }
                        }
                      }
                    }
                    // console.log(7)
                    account = this.getPermissionsDepartmentsData.data.results[m].related_account;
                    account = this.getPaymentsAccount.filter(a=> account.indexOf(a.id)>=0)
                    columns = this.getPermissionsDepartmentsData.data.results[m].such_columns !== null ? this.getPermissionsDepartmentsData.data.results[m].such_columns : []


                    // console.log(this.getPermissionFromThisUser[m])
                    if(employees.length !==0 || departments.length !== 0 || accounts.length !== 0 || columns.length !== 0) {
                      // console.log(employees)

                      this.mountedSelectActiveCheckboxInMenuAll({title: this.getPermissions[i].title, id: this.getPermissions[i].details[j].id, departments: departments, employees: employees, account: account, columns: columns})
                    }
                  }
                }

              }
            }
            // // // console.log("CHANESAJKDHSAKJDHAJS")
          }

        }
        else {
          if(this.selectedProps.permissionsUser === true && this.getEmployees !== null) {
            for(let i=0; i<this.getPermissions.length; i++) {
              for(let j=0; j<this.getPermissions[i].details.length; j++) {
                let departments = [];
                let employees = [];
                let account = [];
                let columns = []
                for(let m=0; m<this.getPermissionFromThisUser.length; m++) {

                  if(this.getPermissions[i].details[j] !== undefined) {
                    // console.log(this.getPermissions[i])
                    if(this.getPermissionFromThisUser[m].type === this.getPermissions[i].details[j].id) {
                      if(this.getPermissionFromThisUser[m].related_staff.length !==0) {
                        for(let n=0; n<this.getPermissionFromThisUser[m].related_staff.length; n++) {
                          // employees.push(this.getPermissionFromThisUser[m].related_staff[n]);
                          for(let e=0; e<this.getEmployees.data.results.length; e++) {
                            if(this.getPermissionFromThisUser[m].related_staff[n] === this.getEmployees.data.results[e].id) {
                              employees.push(this.getEmployees.data.results[e]);
                            }
                          }
                        }
                      }
                      account = this.getPermissionFromThisUser[m].related_account;
                      // console.log(1);??
                      // console.log(account)
                      account = this.getPaymentsAccount.filter(a=> account.indexOf(a.id)>=0)
                      columns = this.getPermissionFromThisUser[m].such_columns !== null ? this.getPermissionFromThisUser[m].such_columns : []

                      this.mountedSelectActiveCheckboxInMenuAll({title: this.getPermissions[i].title, id: this.getPermissions[i].details[j].id, departments: departments, employees: employees, account: account, columns: columns})
                    }

                  } else {
                    i = this.getPermissions.length
                    j = this.getPermissions[i].details.length
                  }
                }
              }
            }
          }
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/checkbox";
  .check-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
  p {
    margin: 0;
  }
  .check {

  }
  .everyone {
    margin-left: 5px;
    cursor: pointer;
    display: inline;
  }

  .text-block {
    //white-space: pre-wrap;
    //display: flex;
    * {
      //display: inline !important;
      //float: left;
    }
    p {
      display: inline !important;
      width: max-content !important;
      height: 20px;
    }
    button {
      //float: left;
      word-break: break-all;
      //margin-left: 5px;
    }
  }

  /deep/ .custom-control {
    p:first-letter {
      display: block !important;
      text-transform: uppercase;
    }
  }
</style>