<template>
  <div class="wrap-priority position-relative" v-click-outside="hideList">
    <button class="show-selected d-flex align-items-center" v-on:click="show = !show">
      <div v-html="getFlag(valueLocal ? valueLocal.type : '')"></div>
      <p v-if="valueLocal" :class="{'ml4': getFlag(valueLocal ? valueLocal.type : '')}" class="fz13 text-dark-local capitalize">{{valueLocal.type}}</p>
      <p v-else class="fz13 text-dark-local">No priority</p>
    </button>
    <div class="list-menu" v-if="show">
      <button class="d-flex align-items-center" v-for="(p, i) in priorityList" :key="i" v-on:click="setValue(p)">
        <div v-html="getFlag(p ? p.type : '')"></div>
        <p :class="{'ml4': getFlag(p ? p.type : '')}" class="fz13 text-dark capitalize">{{p.type}}</p>
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "checklist-select-priority",
  computed: {
    ...mapGetters(['getAllPriority']),
  },
  props: {
    value: [Object, Number],
  },
  watch: {
    value: function () {
      if(this.priorityList.length !== 0) {
        if(Number.isInteger(this.value) === true) {
          this.valueLocal = this.priorityList.find(a=> a.id === this.value)
        } else{
          this.valueLocal = JSON.parse(JSON.stringify(this.value))
        }
      }

    },
    priorityList: function () {
      if(!this.valueLocal) {
        if(Number.isInteger(this.value) === true) {
          let item = this.priorityList.find(a=> a.id === this.value)
          this.setValue(item)
        } else {
          let item = this.priorityList.find(a=> a.type.toLowerCase() === 'no priority')
          this.setValue(item)
        }

      }
    },
  },
  data() {
    return {
      show: false,
      priorityList: [],
      valueLocal: null,
      flag: [
        {name: "Urgent", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#E85353\"/>\n" +
              "</svg>\n", status: false},
        {name: "High", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#FF9035\"/>\n" +
              "</svg>\n", status: false},
        {name: "Normal", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#0FA54E\"/>\n" +
              "</svg>\n", status: false},
        {name: "Low", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#9AA3B0\"/>\n" +
              "</svg>\n", status: false},
        {name: "No priority", svg: "", status: true},
      ],
    }
  },
  methods: {
    ...mapActions(['axiosGetPriorityTask']),
    setValue(priority) {
      this.valueLocal = priority;
      this.$emit('input', priority)
      this.hideList()
    },
    hideList() {
      this.show = false
    },
    openList() {
      this.show = true;
    },
    getFlag(type) {
      if(type && (''+type).length) {
        let flag = this.flag.find(a=> a.name.toLowerCase() === type.toLowerCase())
        return flag ? flag.svg : ''
      }
      return ''
    },
  },
  mounted() {
    if(this.getAllPriority.length !== 0) {
      this.priorityList = this.getAllPriority;
    } else {
      this.axiosGetPriorityTask().then(()=> {
        this.priorityList = this.getAllPriority;
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .wrap-priority {
    position: relative;
  }
  .show-selected {
    width: 100%;
    min-height: 19px;
    height: 100%;
  }
  .list-menu {
    z-index: 100;
    position: absolute;
    bottom: -8px;
    left: -4px;
    transform: translateY(100%);
    border: 1px solid var(--color-border);
    background-color: var(--color-bgc-page-white);
    &>* {
      padding: 4px 12px;
      box-sizing: border-box;
      width: 100%;
      height: 34px;
      &:hover {
        background-color: var(--color-input);
      }
    }
  }
  p {
    white-space: nowrap
  }
</style>