<template>
  <div>
    <span>{{summ | formatNumberTwo}}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      summ: 0
    }
  },
  watch: {
    list: {
      deep: true,
      immediate: true,
      async handler() {
        this.summ = 0
        if(this.list.length) {
          for (let i = 0; i < this.list.length; i++) {
            const element = this.list[i];
            if(!element.converted){
              if(element.currency == 'USD'){
                element.converted = element.balance
              }else if(element.balance){
                await this.axiosGetCurrency({currency: element.currency})
                .then(response=> {
                  element.converted = element.balance / response.data.results[0].rate
                })
              }
            }
          }
          this.summ = this.getSumm()
        }
      }
    }
  },
  computed: {
    
    /* ifOnceCurrency() {
      if(this.list.length == this.list.filter(el=>{return el.currency == this.list[0].currency}).length)
        return true
      else
        return false
    } */
  },
  methods: {
    ...mapActions(['axiosGetCurrency']),
    getSumm() {
      let summ = 0
      this.list.forEach(el => {
        if(el.converted) summ += el.converted
      })
      return summ
    }
  },
}
</script>

<style>

</style>