<template>
  <div>
    <folders-list-links class="mt16" :items="contacts"></folders-list-links>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import FoldersListLinks from "@/components/folders/folders-list-links";

export default {
  name: "folders-ports-countries-options-contacts",
  components: {FoldersListLinks},
  data() {
    return {
      contacts: []
    }
  },
  props: {
    search: String,
    sort: String,
    routeChange: Number,
  },
  watch: {
    search: function () {
      this.loadData()
    },
    sort: function () {
      this.loadData()
    },
    routeChange: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions(['axiosGetFoldersForPort']),
    ...mapMutations(['setNameSectionFolders', 'setNameSectionFolders']),
    async loadData() {
      let res = await this.axiosGetFoldersForPort({typeFile: false, type: 'contacts', id: this.$route.params.portDetail ? JSON.parse(this.$route.params.portDetail).id : null, params: {ordering: this.sort, search: this.search, is_archive_files: this.$route.params.is_archive_files,}})
      this.contacts = res.map(item=> {
        return {
          name: item.dwt + (item.amount_local ? '/'+item.amount_local : '') + (item.source ? '/'+item.source : ''),
          last_updated: item.updated_at,
          link: {name: 'Folders - Ports Countries Ports Options Contact Files', params: {...this.$route.params, detailContact: JSON.stringify({id: item.id, name: item.dwt + (item.amount_local ? '/'+item.amount_local : '') + (item.source ? '/'+item.source : '')}), is_archive_files: false}}
        }
      })
    },
  },
  mounted() {
    this.setNameSectionFolders('Ports')
    this.loadData()
  }
}
</script>

<style scoped>

</style>