import axios from 'axios'

export default {
    actions: {
        axiosAddNewChecklist(ctx) {
            return axios.post(process.env.VUE_APP_URL+"/task/checklist/", {
                check_list: [],
                name: "Name",
                is_template: true,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                res.data["openView"] = true
                return res.data;
            }).catch(()=> {
                return false;
            })
        },
        async axiosAddNewChecklistAwait(ctx, data) {
            return await axios.post(process.env.VUE_APP_URL+"/task/checklist/", {
                check_list: data.check_list,
                name: data.name,
                is_template: data.is_template,
                parent_id: data.parent_id
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                res.data["openView"] = true
                return res.data;
            }).catch(()=> {
                return false;
            })
        },
        axiosAddNewCheckInCheckList(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/task/checklist/check", {
                is_done: false,
                name: "Name",
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                if(Array.isArray(data.check_list) === false) {
                    data.check_list = [];
                }
                data.check_list = [res.data.id].concat(data.check_list)
                ctx.dispatch("axiosChangeCheckList", {id: data.id, data: {check_list: data.check_list}})
                return res.data;
            }).catch(()=> {
                return false;
            })
        },
        axiosChangeCheckList(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL+"/task/checklist/" + data.id, data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                res.data["openView"] = true
                return res.data;
            }).catch(()=> {
                return false;
            })
        },
        axiosChangeCheck(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL+"/task/checklist/check/" + data.id, data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                res.data["openView"] = true
                return res.data;
            }).catch(()=> {
                return false;
            })
        },
        axiosGetTaskCheckList(ctx) {
            return axios.get(process.env.VUE_APP_URL+"/task/checklist/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    is_template: true,
                }
            }).then(res=> {
                ctx.commit("setCheckList", JSON.parse(JSON.stringify(res.data.results)))

                if(Array.isArray(res.data.results) === true) {
                    return res.data.results.map(a=> {
                        a["openView"] = false
                        return a
                    })
                }
                return res.data.results
            }).catch(()=> {
                // ctx.commit("setCheckList", [])
                return false
            })
        },
        axiosGetTaskCheckListWithLoader(ctx, data) {
            ctx.commit("showLoad")
            return axios.get(process.env.VUE_APP_URL+"/task/checklist/", {
                params: {
                    is_template: data.is_template
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                ctx.commit("setCheckList", JSON.parse(JSON.stringify(res.data.results)))
                ctx.commit("hideLoad")
                if(Array.isArray(res.data.results) === true) {
                    return res.data.results.map(a=> {
                        a["openView"] = false
                        return a
                    })
                }
                return res.data.results
            }).catch(()=> {
                ctx.commit("hideLoad")
                // ctx.commit("setCheckList", [])
                return false
            })
        },
        async axiosDeleteCheckList(ctx, id) {
            return await axios.delete(process.env.VUE_APP_URL+"/task/checklist/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true
            }).catch(()=> {
                ctx.commit("addTip", 'Server Error', {root: true})
                return false
            })
        },
        async axiosDeleteCheck(ctx, id) {
            return await axios.delete(process.env.VUE_APP_URL+"/task/checklist/check/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true
            }).catch(()=> {
                ctx.commit("addTip", 'Server Error', {root: true})
                return false
            })
        },
        axiosGetCheckListById(ctx, id) {
            return axios.get(process.env.VUE_APP_URL+'/task/checklist/' + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                return res;
            })
        }
    },
    mutations: {
        setCheckList(state, data) {
            state.checkList = data;
        },
        addNewCheckList(state, data) {
            state.checkList = [data].concat(state.checkList)
        }
    },
    state: {
        checkList: [],
    },
    getters: {
        getCheckList(state) {
            return state.checkList;
        },
        getCheckListForSelect(state) {
            return state.checkList.map(a=> {
                return {name: a.name, id: a.id}
            })
        },
    }
}