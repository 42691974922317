<template>
  <label class="custom-checkbox">
    <input
      ref="checkbox"
      :checked="checked"
      type="checkbox"
      @input="updateInput"
    />
    <span></span>

    <p v-if="slotPassed" class="checkbox-text">
      <slot></slot>
    </p>
  </label>
</template>
<script>
export default {
  name: "custom-checkbox",
  props: {
    modelValue: { type: Boolean },
    checked: { type: Boolean },
  },
  methods: {
    updateInput() {
      // this.$emit("update:modelValue", this.$refs.checkbox.checked);
    },
  },
  computed: {
    slotPassed() {
      return !!this.$slots.default;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-checkbox {
  position: relative;
  display: flex;

  &:hover {
    cursor: pointer;
  }
}
.checkbox-text {
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-dark-gray);
}
input {
  position: absolute;
  z-index: -99999;
  opacity: 0;
}
span {
  width: 18px;
  height: 18px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: 6px;
  transition: 0.3s;
  border: 2px solid var(--color-gray-text);
  box-sizing: border-box;
  &:hover {
    transition: 0.3s;
    border-color: var(--color-blue-dark);
  }
}
input:checked + span {
  background: var(--color-blue-dark);
  border-color: var(--color-blue-dark);
  color: var(--color-blue-dark);
  &:after {
    content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1.02141C11.1868 0.673336 10.67 0.663685 10.3456 0.999847C10.3388 1.00688 10.3321 1.01407 10.3255 1.02141L4.76395 6.91934L1.88355 4.7889C1.55922 4.45274 1.04241 4.46239 0.72918 4.81046C0.423607 5.15001 0.423607 5.68829 0.72918 6.02784L4.18677 8.77773C4.50559 9.1198 5.02236 9.1198 5.34114 8.77773L11.4799 2.26035C11.8042 1.92414 11.8132 1.36948 11.5 1.02141Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 10px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    // background: url("~@/assets/img/svg/checked_icn.svg");
  }
}
input:checked + span + .checkbox-text{
    color: var(--color-blue-dark);
}
</style>
