<template>
  <div class="tags-modal" v-if="show" v-click-outside="hideModal">
    <span class="name">Tags</span>
      <div class="tags-wrapper" :class="{loading: isLoading}">
        <label class="search-tag-wrapper">
          <input 
            type="text" 
            @keyup="asyncFind($event.target.value)" 
            @keyup.enter="createTag($event.target.value)"
            placeholder="Search or Create new">
        </label>
        <div class="tags-list">
          <div 
            class="tags-list__item" 
            v-for="tag in tagsOptions" :key="tag.id"
            @click="chooseTag(tag.id)"
            :class="{selected: selectedTags.includes(tag.id)}"
          >
            <span class="tag-name">{{tag.title}}</span>
            <svg class="checked-icn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
            </svg>

          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import Multiselect from "vue-multiselect";
  export default {
    name: 'tagsModal',
    // components: {Multiselect},
    data: () => ({
      show: false,
      tagsOptions: [],
      selectedTags: [],
      isLoading: false,
      searchTimer: null
    }),
    methods: {
      ...mapActions(['axiosGetMailboxTags', 'axiosMailboxEmailCreateTag']),
      showModal(){
        this.show = true;
        this.axiosGetMailboxTags({limit: 3})
        .then(response => {
          this.tagsOptions = response.data.results
          this.isLoading = false
        })
      },
      hideModal(){
        if(this.selectedTags.length != 0){
          let tags = JSON.parse(JSON.stringify(this.selectedTags))
          this.$emit('addTagsToMail', tags)
        }
        this.selectedTags = [];
        this.show = false
      },
      asyncFind (query) {
        let data;
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          query != '' ? data = {search: query} : data = {limit: 3}
          this.isLoading = true
          this.axiosGetMailboxTags(data)
          .then(response => {
            this.tagsOptions = response.data.results
            this.isLoading = false
          })
        }, 500);
      },
      chooseTag(tagID){
        if(this.selectedTags.includes(tagID)){
          this.selectedTags = this.selectedTags.filter(id => id != tagID)
        }else{
          this.selectedTags.push(tagID)
        }
      },
      createTag(tagName){
        if(tagName != '' && this.tagsOptions.length == 0){
          this.isLoading = true
          this.axiosMailboxEmailCreateTag({title: tagName})
          .then(() => {
            this.asyncFind(tagName)
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.tags-modal{
  position: absolute;
  min-width: 272px;
  z-index: 99999;
  top: 45px;
  display: flex;
  flex-direction: column;
  z-index: 999999;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  align-items: flex-start;
  padding: 16px 0;
  .name{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding: 0 16px;
  }
  .tags-wrapper{
    width: 100%;
    margin-top: 8px;
    &.loading{
      opacity: .5;
      pointer-events: none;
    }
  }
  .search-tag-wrapper{
    padding: 0 16px;
    display: flex;
    input{
      max-width: 100%;
    }
  }
  .tags-list{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    max-height: 200px;
    overflow-y: auto;
    position: relative;
    .tag-name{
      padding: 2px 6px;
      border-radius: 10px;
      background: #E5DBFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
    &__item{
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      &:hover{
        cursor: pointer;
        background: #F4F6F9;
      }
      .checked-icn{
        display: none;
      }
      &.selected{
        .checked-icn{
          display: flex;
        }
      }
    }
  }
}
</style>