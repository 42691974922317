<template>
  <div>
    <div class="title d-flex align-items-center pb4 w100Proc border-bottom border-color-normal mt12">
      <svg v-on:click="actualShow = !actualShow" :class="{'rotate-180': actualShow}" class="mr8 cursor-pointer" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fw500 fz18 text-dark">
        Actual Vessels
      </p>
    </div>
    <p class="fz14 text-dark mt12" v-if="actual_vessels.length == 0 && actualShow">No result were found for your search</p>
    <folders-list-links-vessels class="mt8" v-show="actualShow" :items="actual_vessels"></folders-list-links-vessels>
    <pagination-panel-default @load-more="loadMoreActualVessels" v-if="nextLinkActualVessels && actualShow" :if-active="loadActualVessel"></pagination-panel-default>
    <div class="title d-flex align-items-center pb4 w100Proc border-bottom border-color-normal mt12">
      <svg v-on:click="redeliveryShow = !redeliveryShow" :class="{'rotate-180': redeliveryShow}" class="mr8 cursor-pointer" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fw500 fz18 text-dark">
        Redelivered Vessels
      </p>
    </div>
    <p class="fz14 text-dark mt12" v-if="redelivery_years.length == 0 && redeliveryShow">No result were found for your search</p>
    <template v-if="redeliveryShow">
      <div class="" v-for="(item, i) in redelivery_years" :key="i+'ds33'">
        <div class="w100Proc pb4 border-bottom border-color-normal mt14">
          <button class="fw500 fz14 text-gray" v-on:click="openYearRedelivery(item)">
            <svg :class="{'rotate-180': item.showList}" class="mr8 cursor-pointer" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#9AA3B0"/>
            </svg>
            {{item.year}}
          </button>
        </div>

        <folders-list-links-vessels class="mt8" v-show="item.showList" :items="item.contracts"></folders-list-links-vessels>
        <pagination-panel-default @load-more="loadMoreRedelivery(item)" v-if="item.showList && item.nextLink" :if-active="item.loadMoreStatus"></pagination-panel-default>
      </div>
    </template>

  </div>
</template>

<script>
import moment from 'moment'
import {mapActions, mapMutations} from "vuex";
import PaginationPanelDefault from "@/components/ports/paginationDefault.vue";
import FoldersListLinksVessels from "@/components/folders/folders-list-links-vessels.vue";
export default {
  name: "folders-vessels",
  components: {FoldersListLinksVessels, PaginationPanelDefault},
  props: {
    search: String,
    sort: String,
    routeChange: Number,
  },
  computed: {
    getRedeliveryView() {
      let years = this.redelivery_vessels.map(a=> {return moment(a.cp_date).format('YYYY')})
      years = Array.from(new Set(years))
      let list = []
      years.forEach(year=> {
        let form = {
          year: year,
          list: this.redelivery_vessels.filter(a=> a.cp_date.indexOf(year)>= 0).sort((a, b) => a.name > b.name ? 1 : -1),
        }
        list.push(form)
      })
      return list
    }
  },
  watch: {
    search: function () {
      this.loadData()
    },
    sort: function () {
      this.loadData()
    },
    routeChange: function () {
      this.loadData()
    }
  },
  data() {
    return {
      actualShow: false,
      redeliveryShow: false,
      actual_vessels: [],
      redelivery_vessels: [],
      hideRedeliveryYears: [],
      nextLinkActualVessels: null,
      loadActualVessel: false,
      redelivery_years: []
    }
  },
  methods: {
    ...mapActions(['axiosGetShortViewVessels', 'axiosGetShortViewVesselsLoadMore', 'axiosGetContractYearsForFolders']),
    ...mapMutations(['setNameSectionFolders']),
    async loadMoreRedelivery(item) {
      item.loadMoreStatus = true
      let res = await this.axiosGetShortViewVesselsLoadMore(item.nextLink)
      if(res) {
        res.data.results.forEach(item=> {
          item['link'] = {name: 'Folders - Vessels Options', params: {detailVessel: JSON.stringify({id: item.id, name: item.name})}}
        })
        item.contracts = item.contracts.concat(res.data.results)
        item.nextLink = res.data.next
      }
      item.loadMoreStatus = false
    },
    async openYearRedelivery(item) {
      item.showList = !item.showList
      if(item.showList && !item.contracts.length) {
        let res = await this.axiosGetShortViewVessels({search: this.search, is_archive: this.$route.params.vessels_is_archive, cp_date_after: item.year+'-01-01 00:00:00', cp_date_before: item.year+'-12-31 23:59:59', is_redelivery: true, ordering: this.sort, limit: 20})
        if(res) {
          res.data.results.forEach(item=> {
            item['link'] = {name: 'Folders - Vessels Options', params: {detailVessel: JSON.stringify({id: item.id, name: item.name, cp_date: moment(item.cp_date).format('DD.MM.YYYY')})}}
          })
          item.contracts = res.data.results
          item.nextLink = res.data.next
        }
      }
    },
    hideYear(year) {
      if(this.hideRedeliveryYears.indexOf(year) >= 0) {
        this.hideRedeliveryYears = this.hideRedeliveryYears.filter(a=> a !== year)
      } else {
        this.hideRedeliveryYears.push(year)
      }
    },
    async loadData() {
      let res = await this.axiosGetShortViewVessels({contract_type: 'SHIP', search: this.search, is_archive: ''+this.$route.params.vessels_is_archive, is_redelivery: false, ordering: this.sort, limit: 20})
      if(res) {
        res.data.results.forEach(item=> {
          item['link'] = {name: 'Folders - Vessels Options', params: {detailVessel: JSON.stringify({id: item.id, name: item.name, cp_date: moment(item.cp_date).format('DD.MM.YYYY')})}}
        })
        this.actual_vessels = res.data.results
        this.nextLinkActualVessels = res.data.next
      }
      // redelivery_years
      this.redelivery_years = []
      res = await this.axiosGetContractYearsForFolders({search: this.search, is_archive: ''+this.$route.params.vessels_is_archive})
      console.log('years', res)
      res.years.forEach(year=> {
        let item = {
          year: year,
          showList: false,
          loadMoreStatus: false,
          nextLink: null,
          contracts: [],
        }
        this.redelivery_years.push(item)
      })
      // res = await this.axiosGetShortViewVessels({search: '', is_redelivery: true, ordering: '-cp_date', limit: 20})
      // if(res) {
      //   res.data.results.forEach(item=> {
      //     item['link'] = {name: 'Folders - Vessels Options', params: {id: item.id}}
      //   })
      //   this.redelivery_vessels = res.data.results
      // }
      // console.log(res)
    },
    async loadMoreActualVessels() {
      this.loadActualVessel = true
      let res = await this.axiosGetShortViewVesselsLoadMore(this.nextLinkActualVessels)
      if(res) {
        res.data.results.forEach(item=> {
          item['link'] = {name: 'Folders - Vessels Options', params: {detailVessel: JSON.stringify({id: item.id, name: item.name})}}
        })
        this.actual_vessels = this.actual_vessels.concat(res.data.results)
        this.nextLinkActualVessels = res.data.next
      }
      this.loadActualVessel = false
    }
  },
  mounted() {
    this.setNameSectionFolders('Vessels')
    this.loadData()
  }
}
</script>

<style scoped>

</style>