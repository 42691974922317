<template>
  <modal-slots  :noCloseAfterApply="true" :noCloseFilter="true" :textForSecondButton="'Save'" :showMod="true" @close="close" @apply="apply">
    <template v-slot:title>
      <p class="fz19 fw500 text-dark">{{type === 'create' ? 'Create' : 'Edit'}} Contact List</p>
    </template>
    <div class="content">
      <p class="fz13 text-gray">List Name</p>
      <input type="text" class="h36 mt4" :class="{error: errorName}" v-model="name">
      <p class="fz13 text-gray mt12">Add Emails</p>
      <div class="mt4 emails" v-on:click="checkFocus">
        <div v-if="!emails_list.length">
          <p class="fz14 text-gray"><span style="margin-right: 20px;">1</span> <span>email1@example.com</span></p>
          <p class="fz14 text-gray"><span style="margin-right: 20px;">2</span> <span>email2@example.com</span></p>
          <p class="fz14 text-gray"><span style="margin-right: 20px;">3</span> <span>email3@example.com</span></p>
        </div>
        <p class="fz14 text-dark" v-for="(email, i) in emails_list" :key="i + 'dsfs'+emails_list.length +keyRenderEmail"><span style="margin-right: 20px;">{{i+1}}</span> <span @paste="(e)=>{paste(e, i)}" :contenteditable="true" @blur="(e)=>{checkEmailBlur(e, i)}" v-on:keydown.enter="(e)=>{checkEmailEnter(e, i)}" :class="{'text-red': !getValidEmail(email) && email.length !== 0}" @focus="focusText = true" :ref="'input'+i" >{{email}}</span></p>
      </div>
    </div>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import {mapActions, mapMutations} from "vuex";
export default {
  name: "create-edit-contact-list",
  components: {ModalSlots},
  props: {
    type: {
      type: String,
      default: 'create'
    }
  },
  data() {
    return {
      keyRenderEmail: -1239812731,
      errorName: false,
      name: '',
      emails_list: [],
      focusText: false,
      idEdit: null,
    }
  },
  methods: {
    ...mapActions(['axiosCreateContactList', 'axiosGetContactById', 'axiosChangeContactList']),
    ...mapMutations(['addTip', 'showLoad', 'hideLoad']),
    paste(e, index) {
      // e.target.blur()
      let paste = (e.clipboardData || window.clipboardData).getData("text");
      let array = paste.replaceAll('null', '').replaceAll('\r', '').replaceAll('\n', ' ').replace(/ {1,}/g," ").replaceAll(',', ' ').replaceAll(';', ' ').replaceAll("\\s+", " ").split(' ')
      // let array1 = paste.replaceAll('\n', ' ').split(' ')
      // console.log(paste.replaceAll('\r', '').split('\n' ))
      // console.log(array)

      var regex = /[^a-zA-Z.0-9 ]|\.(?=\.)|\s(?=\s)/g;
      var regex1 = /[^a-zA-Z_,\-.0-9 ]|\.(?=\.)|\s(?=\s)/g;
      array.forEach(item=> {
        item = item.trim().slice(item.trim().lastIndexOf(' ')+1, item.trim().length)
        // item = item.replace(/\s/g,'')
        if(item.indexOf('@') > 0) {
          let firstPart = item.slice(0, item.indexOf('@'))
          let lastPart =  item.slice(item.indexOf('@'), item.length)
          item = firstPart.replace(regex1, "") + '@'+lastPart.replace(regex, "").slice(0, lastPart.replace(regex, "").length -1) + lastPart.replace(regex, "").slice(lastPart.replace(regex, "").length -1, lastPart.replace(regex, "").length ).replace(/^\s+|\s+$/g, "")
          // item = item.replace(lastPart,'@'+lastPart.replace(regex, "").slice(0, lastPart.replace(regex, "").length -1) + lastPart.replace(regex, "").slice(lastPart.replace(regex, "").length -1, lastPart.replace(regex, "").length ).replace(/^\s+|\s+$/g, ""))
        }
      })
      array = array.filter(a=> this.getValidEmail(a))
      console.log(array, index)
      // this.emails_list.splice(index, 0, '');
      array.reverse()
      array.forEach(item=> {
        this.emails_list[index] = item
        this.emails_list.splice(index, 0, '');

      })


      // let index = 0
      // let lastIndexEmail = this.emails_list.length-1
      // for(let i= this.emails_list.length-1; i< this.emails_list.length + array.length; i++) {
      //   this.emails_list[i] = array[index];
      //   this.emails_list.push('')
      //   index++;
      // }
      // setTimeout(()=> {
      //   array.forEach((item)=> {
      //     this.$refs['input'+lastIndexEmail][0].innerText = item
      //     lastIndexEmail++
      //
      //   })
      // }, 300)


    },
    close() {
      setTimeout(()=> {
        this.errorName = false;
        this.name = ''
        this.idEdit = null;
        this.emails_list = []
        this.$emit('close')
      }, 20)
    },
    apply() {
      let bool = true
      if(!this.name.length) {
        this.errorName = true;
        bool = false
        // return null;
      } else {
        this.errorName = false;
      }
      if(this.emails_list.filter(a=> !this.getValidEmail(a)).length !== 0) {
        bool = false
        this.addTip('Incorrect email')
      }
      if(bool) {
        if(this.type === 'create') {
          this.showLoad()
          this.close()
          this.axiosCreateContactList({name: this.name, emails_list: this.emails_list}).then(()=> {
            this.$emit('created')
            this.hideLoad()
          })
        } else if(this.idEdit) {
          this.showLoad()
          this.close()
          this.axiosChangeContactList({id: this.idEdit, name: this.name, emails_list: this.emails_list}).then(()=> {
            this.$emit('created')
            this.hideLoad()
          })
        }
      }

    },
    checkFocus() {
      setTimeout(()=> {
        if(!this.focusText) {
          // if(!this.emails_list.length) {
            this.emails_list.push('')
            setTimeout(()=> {
              console.log(this.$refs['input'+(this.emails_list.length-1)])

              this.$refs['input'+(this.emails_list.length-1)][0].focus();
            }, 0)
          // } else {
          //   this.$refs['input'+(this.emails_list.length-1)].focus();
          // }
        }
      }, 50)
    },
    checkEmailBlur(e, index) {
      this.focusText = false;
      let EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      var regex = /[^a-zA-Z.0-9 ]|\.(?=\.)|\s(?=\s)/g;
      var regex1 = /[^a-zA-Z_,\-.0-9 ]|\.(?=\.)|\s(?=\s)/g;
      e.target.innerText = e.target.innerText.trim().slice(e.target.innerText.trim().lastIndexOf(' ')+1, e.target.innerText.trim().length)
      // e.target.innerText = e.target.innerText.replace(/\s/g,'')
      if(e.target.innerText.indexOf('@') > 0) {
        let firstPart = e.target.innerText.slice(0, e.target.innerText.indexOf('@'))
        let lastPart =  e.target.innerText.slice(e.target.innerText.indexOf('@'), e.target.innerText.length)
        e.target.innerText = firstPart.replace(regex1, "") + '@'+lastPart.replace(regex, "").slice(0, lastPart.replace(regex, "").length -1) + lastPart.replace(regex, "").slice(lastPart.replace(regex, "").length -1, lastPart.replace(regex, "").length ).replace(/^\s+|\s+$/g, "")
        // e.target.innerText = e.target.innerText.replace(lastPart,'@'+lastPart.replace(regex, "").slice(0, lastPart.replace(regex, "").length -1) + lastPart.replace(regex, "").slice(lastPart.replace(regex, "").length -1, lastPart.replace(regex, "").length ).replace(/^\s+|\s+$/g, ""))
      }
      this.emails_list[index] = e.target.innerText
      e.target.innerText = this.emails_list[index]
      if(!EMAIL_REGEXP.test(e.target.innerText)) {
        if(e.target.innerText.length) {
          this.addTip('Incorrect email')
        } else {
          this.emails_list.splice(index, 1)
        }
      }
      // if(EMAIL_REGEXP.test(e.target.innerText)) {
      //   console.log(e.target.innerText)
      //   this.emails_list[index] = e.target.innerText
      //   e.target.innerText = this.emails_list[index]
      // } else {
      //   if(e.target.innerText.length) {
      //     this.addTip('Incorrect email')
      //   }
      //   this.emails_list.splice(index, 1)
      // }
      this.keyRenderEmail++;
    },
    getValidEmail(email) {
      let EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return EMAIL_REGEXP.test(email)
    },
    checkEmailEnter(e, index) {
      e.preventDefault()
      let EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      var regex = /[^a-zA-Z.0-9 ]|\.(?=\.)|\s(?=\s)/g;
      var regex1 = /[^a-zA-Z_,\-.0-9 ]|\.(?=\.)|\s(?=\s)/g;
      e.target.innerText = e.target.innerText.trim().slice(e.target.innerText.trim().lastIndexOf(' ')+1, e.target.innerText.trim().length)
      // e.target.innerText = e.target.innerText.replace(/\s/g,'')
      if(e.target.innerText.indexOf('@') > 0) {
        let firstPart = e.target.innerText.slice(0, e.target.innerText.indexOf('@'))
        let lastPart =  e.target.innerText.slice(e.target.innerText.indexOf('@'), e.target.innerText.length)
        e.target.innerText = firstPart.replace(regex1, "") + '@'+lastPart.replace(regex, "").slice(0, lastPart.replace(regex, "").length -1) + lastPart.replace(regex, "").slice(lastPart.replace(regex, "").length -1, lastPart.replace(regex, "").length ).replace(/^\s+|\s+$/g, "")
        // e.target.innerText = e.target.innerText.replace(lastPart,'@'+lastPart.replace(regex, "").slice(0, lastPart.replace(regex, "").length -1) + lastPart.replace(regex, "").slice(lastPart.replace(regex, "").length -1, lastPart.replace(regex, "").length ).replace(/^\s+|\s+$/g, ""))
      }

      this.emails_list[index] = e.target.innerText
      if(EMAIL_REGEXP.test(e.target.innerText)) {
        this.emails_list.push('')
        setTimeout(()=> {
          // console.log(this.$refs['input'+(this.emails_list.length-1)])
          this.$refs['input'+(this.emails_list.length-1)][0].focus();
        }, 0)
      } else {
        this.addTip('Incorrect email')
      }
      // if(EMAIL_REGEXP.test(e.target.innerText)) {
      //   this.emails_list[index] = e.target.innerText
      //   this.emails_list.push('')
      //   setTimeout(()=> {
      //     console.log(this.$refs['input'+(this.emails_list.length-1)])
      //     this.$refs['input'+(this.emails_list.length-1)][0].focus();
      //   }, 0)
      // } else {
      //     this.addTip('Incorrect email')
      // }

    },
    async loadEditData(id) {
      this.showLoad()
      let data = await this.axiosGetContactById(id)
      this.idEdit = data.id
      this.name = data.name
      this.emails_list = data.emails_list.filter(a=> a)
      this.hideLoad()
    },
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 720px;
  padding: 16px 24px 24px 24px;
  box-sizing: border-box;
}
.emails {
  background-color: var(--color-input);
  min-height: 274px;
  box-sizing: border-box;
  padding: 10px 12px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
}
.error {
  border: 1px solid var(--color-red)
}
</style>