import { render, staticRenderFns } from "./addPda.vue?vue&type=template&id=48c3950c&scoped=true&"
import script from "./addPda.vue?vue&type=script&lang=js&"
export * from "./addPda.vue?vue&type=script&lang=js&"
import style0 from "./addPda.vue?vue&type=style&index=0&id=48c3950c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c3950c",
  null
  
)

export default component.exports