<template>
  <label class="relative fz0">
    <input class="absolute w-0 h-0 opacity-0" :disabled="disabled" type="checkbox" :checked="value" @input="$emit('input', $event.target.checked)" @change="$emit('change')">
    <svg v-if="!value" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3ZM4 0a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Z" fill="#BDCBD6"/></svg>
    <svg v-else class="check-active" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="18" height="18" rx="4" fill="#094172"/><path d="M14.5 5.022a.778.778 0 0 0-1.175 0L7.764 10.92l-2.88-2.13a.778.778 0 0 0-1.155.02.923.923 0 0 0 0 1.218l3.458 2.75a.778.778 0 0 0 1.154 0l6.139-6.517a.922.922 0 0 0 .02-1.24Z" fill="#fff"/></svg>
  </label>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    value: Boolean,
  }
}
</script>

<style scoped lang="scss">
.dart-mode {
  .check-active {
    rect {
      fill: var(--color-blue-dark)
    }
  }
}
</style>