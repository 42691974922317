<template>
  <div 
    class="border-t border-gray-light cargo_list__table_row cursor-pointer" 
    :class="{
      'bg-blue-lightest text-dark': editedCargoId === row.id && list.length != 1,
      'bg-white text-dark-gray': editedCargoId !== row.id || list.length == 1
    }"
    @click="$emit('on-select')"
  >
    <div class="p-y-1.5 p-l-1 p-r-1">{{row.ballast_voyage ? 'Balast' : row.cargo_class}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="getQuantityForRow ? getQuantityForRow : null">{{getQuantityForRow}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.freight ? row.freight : null">{{row.freight}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.loading_port ? row.loading_port.name : null">
      {{row.loading_port ? row.loading_port.name : null}}
    </div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.loading_rate ? row.loading_rate : null">{{row.loading_rate}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.loading_terms ? row.loading_terms : null">{{row.loading_terms}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.loading_turn_time ? row.loading_turn_time : null">{{row.loading_turn_time}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.loading_water_density ? row.loading_water_density : null">{{row.loading_water_density}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1 capitalize" v-tooltip.top="row.loading_seasonal_mark ? row.loading_seasonal_mark : null">{{row.loading_seasonal_mark}}</div>

    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.discharging_port ? row.discharging_port.name : null">
      {{row.discharging_port ? row.discharging_port.name : null}}
    </div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.discharging_rate ? row.discharging_rate : null">{{row.discharging_rate}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.discharging_terms ? row.discharging_terms : null">{{row.discharging_terms}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.discharging_turn_time ? row.discharging_turn_time : null">{{row.discharging_turn_time}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1" v-tooltip.top="row.discharging_water_density ? row.discharging_water_density : null">{{row.discharging_water_density}}</div>
    <div class="p-y-1.5 p-l-1 p-r-1 capitalize" v-tooltip.top="row.discharging_seasonal_mark ? row.discharging_seasonal_mark : null">{{row.discharging_seasonal_mark}}</div>
    <div class="p-0.5">
      <button 
        type="button"
        @click.stop="$emit('on-delete')" 
        class="hover:child-fill-dark"
        :class="{
          'invisible': list.length == 1
        }"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"/></svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    list: Array,
    row: Object,
    editedCargoId: Number
  },
  data() {
    return {
      timeout: null
    }
  },
  watch: {
    row: {
      deep: true,
      handler() {
        if(this.$route.path.indexOf('archive') === -1) {
          if(this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.onSaveRow()
          }, 1000)
        }
      }
    }
  },
  computed: {
    getQuantityForRow() {
      if(this.row.quantity_mt) return this.row.quantity_mt
      else if(this.row.quantity_min && this.row.quantity_max) return Math.round(((+this.row.quantity_min+this.row.quantity_max)/2)*1000000)/1000000
      else if(this.row.quantity_max) return this.row.quantity_max
      else if(this.row.quantity_min) return this.row.quantity_min
      else return ''
    }
  },
  methods: {
    ...mapActions(['axiosPatchCalcCargo']),
    onSaveRow() {
      let savedRow = JSON.parse(JSON.stringify(this.row))

      if(savedRow.loading_terms){
        let index = savedRow.loading_terms.indexOf(' ')
        if(index > 0)
          savedRow.loading_terms = savedRow.loading_terms.slice(0, index)
      }
      if(savedRow.discharging_terms){
        let index = savedRow.discharging_terms.indexOf(' ')
        if(index > 0)
          savedRow.discharging_terms = savedRow.discharging_terms.slice(0, index)
      }

      if(savedRow.loading_port) savedRow.loading_port = savedRow.loading_port.id
      if(savedRow.discharging_port) savedRow.discharging_port = savedRow.discharging_port.id
      
      this.axiosPatchCalcCargo(savedRow)
    }
  },

}
</script>

<style>

</style>