<template>
  <div class="wrap svg-white-dart">
    <p class="fz20 fw500" v-if="hideLeftText !== true && $route.path.indexOf('accounting-invoice') === -1 && (textTitle === null || textTitle === undefined)">{{ (''+getTextForDate()).toLowerCase().indexOf('invalid') === -1 ? getTextForDate() : '' }}</p>
    <p class="fz20 fw500" v-if="hideLeftText !== true && $route.path.indexOf('accounting-invoice') !== -1 && $route.path.indexOf('archive') === -1 && textTitle === null && textTitle === undefined">Accounting Invoice</p>
    <p class="fz20 fw500" v-if="hideLeftText !== true && $route.path.indexOf('accounting-invoice/archive') !== -1 && textTitle === null && textTitle === undefined">Archive Accounting Invoices</p>
    <p class="fz20 fw500" v-if="hideLeftText !== true && textTitle !== null && textTitle !== undefined">{{textTitle}}</p>
    <button :class="{'button-right': createButtonRight === true}" :disabled="showLoaderButton" class="blue-button fz13 fw500 h32 buttonW150" v-if="$route.path.indexOf('reports') === -1 && cashFlow === true && getPermissionsCreatePayment() && $route.path.indexOf('archive') === -1" v-on:click="addNewPayment">
      <app-loader class="loader-button" v-if="showLoaderButton"></app-loader>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"></path></svg>
      <span class="fz13 fw500" v-if="$route.path.indexOf('open/') === -1">Create payment</span>
      <span class="fz13 fw500" v-if="$route.path.indexOf('open/') >= 0">Create New</span>
    </button>
    <button :class="{'button-right': createButtonRight === true}" :disabled="showLoaderButton" class="blue-button fz13 fw500 h32 buttonW150" v-if="$route.path.indexOf('reports') >= 0 && $route.path.indexOf('archive') === -1" v-on:click="addNewPayment">
      <app-loader class="loader-button" v-if="showLoaderButton"></app-loader>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"></path></svg>
      <span class="fz13 fw500" v-if="$route.path.indexOf('open/') === -1">Create payment</span>
      <span class="fz13 fw500" v-if="$route.path.indexOf('open/') >= 0">Create New</span>
    </button>
    <div class="panel-selected" :class="{'ml-for-button' : createButtonRight === true}">
      <payment-dropdown-export v-if="cashFlow === true && $route.path.indexOf('open/reports') === -1 && $route.path.indexOf('archive') === -1" class="download-list" @eventExportXLSX="downloadXLSXListPayment" @eventExportPDF="downloadPDFListPayment"></payment-dropdown-export>
<!--      <button v-if="$route.name === 'CashFlow List'" class="payment-comment-button svg-white-dart-stroke" v-on:click="clickNote">-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M5 5H19" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>-->
<!--          <path d="M12 8L12 16M12 8L9.5 10M12 8L14.5 10M12 16L14.5 14M12 16L9.5 14" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M5 19H19" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>-->
<!--        </svg>-->
<!--      </button>-->
      <div class="buttons-period">
        <button v-for="(b, i) in buttons" :key="i" v-on:click="changeButton(i)" class="fz13 text-dark" :class="{'buttons-period__active': b.status}">{{b.name}}</button>
      </div>
      <date-picker
          v-if="hideDatePicker !== true"
          placeholder="Choose period"
          class="datepickeer"
        v-model="datepick"
        range
        format="DD.MM.YYYY"
        @change="changeDate(datepick)"
      ></date-picker>
      <button v-on:click="$router.push('accounting-invoice/archive')" class="button-dark-link button-archive fz13 fz500 text-dark" v-if="$route.path.indexOf('accounting-invoice') !== -1 && $route.path.indexOf('archive') === -1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button v-on:click="$router.push('cash-flow/archive')" class="button-dark-link button-archive fz13 fz500 text-dark" v-if="$route.path.indexOf('cash-flow') !== -1 && $route.path.indexOf('archive') === -1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button v-on:click="$router.push('reports/archive')" class="button-dark-link button-archive fz13 fz500 text-dark" v-if="$route.path.indexOf('reports') !== -1 && $route.path.indexOf('archive') === -1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button v-on:click="$router.push({name: 'Cash Flow List Archive'})" class="button-dark-link button-archive fz13 fz500 text-dark" v-if="$route.path.indexOf('cashflow/list') !== -1 && $route.path.indexOf('archive') === -1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <button :class="{'text-blue': activeFilter === true}" class="filter" v-if="$route.path.indexOf('cashflow/list')>=0 && $route.path.indexOf('archive') === -1 && shortFilterView !== true" v-on:click="clickFilter">
      <svg v-if="activeFilter !== true" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H18.3392C18.7093 7.05017 19 6.75585 19 6.38127C19 6.00669 18.7093 5.71237 18.3392 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#2C2C2C"/>
        <path d="M14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6204 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3361 15.326 9.61374 14.2423 9.61374ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#2C2C2C"/>
        <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5185 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8028 8.42731 20.5252 9.48458 20.5252C10.5683 20.5252 11.467 19.8028 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5185 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5553 10.5154 18.144C10.5154 18.7326 10.0396 19.1874 9.48458 19.1874C8.92951 19.1874 8.45374 18.7326 8.45374 18.144C8.48018 17.5553 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
      </svg>
      <svg v-if="activeFilter === true" class="svg-blue" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 6C24 8.20914 22.2091 10 20 10C17.7909 10 16 8.20914 16 6C16 3.79086 17.7909 2 20 2C22.2091 2 24 3.79086 24 6Z" fill="#319FEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0068 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H14.0916C14.0314 6.70918 14 6.35826 14 6C14 5.90358 14.0023 5.80768 14.0068 5.71237ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#319FEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1809 10.6278C15.9425 10.4309 15.7196 10.2158 15.5143 9.98485C15.1482 9.74985 14.7127 9.61374 14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.9683 18.9985 11.9421 18.9957 11.9163C18.3705 11.811 17.7782 11.6089 17.2346 11.3261H16.5154C16.4412 11.0734 16.3273 10.8381 16.1809 10.6278ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#319FEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.21145 17.4751C7.5022 16.4851 8.40088 15.7627 9.45815 15.7627C10.5419 15.7627 11.4405 16.4851 11.7313 17.4751H18.3392C18.7093 17.4751 19 17.7694 19 18.144C19 18.5185 18.7093 18.8129 18.3392 18.8129H11.7577C11.467 19.8028 10.5683 20.5252 9.48458 20.5252C8.42731 20.5252 7.5022 19.8028 7.23789 18.8129H4.66079C4.29075 18.8129 4 18.5185 4 18.144C4 17.7694 4.26432 17.4751 4.66079 17.4751H7.21145ZM10.5154 18.144C10.5154 17.5553 10.0396 17.1005 9.48458 17.1005C8.92951 17.1005 8.48018 17.5553 8.45374 18.144C8.45374 18.7326 8.92951 19.1874 9.48458 19.1874C10.0396 19.1874 10.5154 18.7326 10.5154 18.144Z" fill="#319FEF"/>
      </svg>
      <p class="fw500 fz14 text-dark">Filter</p>
    </button>
    <button class="filter short-filter" v-if="$route.path.indexOf('cashflow/list')>=0 && $route.path.indexOf('archive') === -1 && shortFilterView === true" v-on:click="clickFilter">
      <svg v-if="activeFilter !== true" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H18.3392C18.7093 7.05017 19 6.75585 19 6.38127C19 6.00669 18.7093 5.71237 18.3392 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#2C2C2C"/>
        <path d="M14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6204 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3361 15.326 9.61374 14.2423 9.61374ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#2C2C2C"/>
        <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5185 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8028 8.42731 20.5252 9.48458 20.5252C10.5683 20.5252 11.467 19.8028 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5185 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5553 10.5154 18.144C10.5154 18.7326 10.0396 19.1874 9.48458 19.1874C8.92951 19.1874 8.45374 18.7326 8.45374 18.144C8.48018 17.5553 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
      </svg>
      <svg v-if="activeFilter === true" class="svg-blue" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 6C24 8.20914 22.2091 10 20 10C17.7909 10 16 8.20914 16 6C16 3.79086 17.7909 2 20 2C22.2091 2 24 3.79086 24 6Z" fill="#319FEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0068 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H14.0916C14.0314 6.70918 14 6.35826 14 6C14 5.90358 14.0023 5.80768 14.0068 5.71237ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#319FEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1809 10.6278C15.9425 10.4309 15.7196 10.2158 15.5143 9.98485C15.1482 9.74985 14.7127 9.61374 14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.9683 18.9985 11.9421 18.9957 11.9163C18.3705 11.811 17.7782 11.6089 17.2346 11.3261H16.5154C16.4412 11.0734 16.3273 10.8381 16.1809 10.6278ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#319FEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.21145 17.4751C7.5022 16.4851 8.40088 15.7627 9.45815 15.7627C10.5419 15.7627 11.4405 16.4851 11.7313 17.4751H18.3392C18.7093 17.4751 19 17.7694 19 18.144C19 18.5185 18.7093 18.8129 18.3392 18.8129H11.7577C11.467 19.8028 10.5683 20.5252 9.48458 20.5252C8.42731 20.5252 7.5022 19.8028 7.23789 18.8129H4.66079C4.29075 18.8129 4 18.5185 4 18.144C4 17.7694 4.26432 17.4751 4.66079 17.4751H7.21145ZM10.5154 18.144C10.5154 17.5553 10.0396 17.1005 9.48458 17.1005C8.92951 17.1005 8.48018 17.5553 8.45374 18.144C8.45374 18.7326 8.92951 19.1874 9.48458 19.1874C10.0396 19.1874 10.5154 18.7326 10.5154 18.144Z" fill="#319FEF"/>
      </svg>
<!--      <p class="fw500 fz14 text-dark">Filter</p>-->
    </button>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Vue from 'vue';

import moment from 'moment';
import PaymentDropdownExport from "@/components/payment/payment-dropdown-export";
import {mapActions, mapGetters} from "vuex";
import AppLoader from "@/components/loader";
Vue.prototype.$moment = moment;
export default {
  name: "payment-panel-select-period",
  components: {AppLoader, PaymentDropdownExport, DatePicker},
  computed: mapGetters(["getPermissionsByType", "getDataContractV"]),
  props: ["activeFilter", "shortFilterView", "createButtonRight", "hideDatePicker", "createPaymentEmit", "normalDate", "returnType", "activeDate", "cashFlow", 'textTitle', "defaultStatusButton", "id", 'hideLeftText'],
  data() {
    return {
      buttons: [
        {name: "Today", status: false},
        {name: "Week", status: false},
        {name: "Month", status: false},
        {name: "Year", status: false},
        {name: "All time", status: false},
      ],
      datepick: [(new Date()), (new Date())],
      showLoaderButton: false,
    }
  },
  watch: {
    activeDate: function () {
      if(this.activeDate !== null) {
        this.buttons = this.buttons.map(b=> {
          return {name: b.name, status: false}
        })
        this.datepick = this.activeDate;
        this.changeDate(this.datepick);
      }

    },
    defaultStatusButton: function() {
      if(this.defaultStatusButton !== null && this.defaultStatusButton !== undefined) {
        this.changeButton(Number(this.defaultStatusButton));
      } else {
        if(this.defaultStatusButton !== 'no') {
          this.changeButton(4);
        }
      }
    }
  },

  methods: {
    ...mapActions(['axiosAddNewPayment']),
    clickEmit() {
      this.$emit('clickEmit')
    },
    addNewPayment() {
      if(this.createPaymentEmit !== true) {
        this.showLoaderButton = true
        let data_type = this.$route.path.indexOf('accounting-invoice') === -1 ? 'payment' : 'invoice';
        let ship = null
        let cp_date = ''
        if(this.$route.path.indexOf('vessels')>=0) {
          if(this.getDataContractV !== null) {
            ship = this.getDataContractV.id
            if(this.getDataContractV.cp_date !== null && (""+this.getDataContractV.cp_date).length !== 0) {
              cp_date = this.getDataContractV.cp_date
            }
          }
        }
        this.axiosAddNewPayment({data_type: data_type, ship: ship, cp_date: cp_date}).then(()=> {
          this.showLoaderButton = false
        })
      } else {
        this.$emit('createPayment')
      }

    },
    getPermissionsCreatePayment() {
      if(this.$route.path.indexOf("open/cash-flow")>=0) {
        return this.getPermissionsByType("create_a_new_payment_or_income");
      }
      return this.getPermissionsByType("create_payments_incomes");
    },
    clickNote() {
      this.$emit("emitNote")
    },
    clickFilter() {
      this.$emit("clickFilter")
    },
    downloadXLSXListPayment() {
      this.$emit("eventExportXLSX")
    },
    downloadPDFListPayment() {
      this.$emit("eventExportPDF")
    },
    clickCreatePayment() {
      this.$emit("createPayment");
    },
    setTimeSelect(date) {
      this.datepick = date;
      this.changeDate(date);
    },
    changeButton(index) {
      this.buttons = this.buttons.map(b=> {
        return {name: b.name, status: false}
      })
      this.buttons[index]["status"] = true;
      let type = "";
      switch (index) {
        case 0:
          this.datepick = [(new Date()), (new Date())];
          type = 'day';
          break;
        case 1:
          var now = moment();
          var date1 = now.clone().weekday(1);
          var date2 = now.clone().weekday(7);
          this.datepick = [(new Date(date1._d)), (new Date(date2._d))];
          type = 'day';
          break;
        case 2:
          var date = (new Date()), y = date.getFullYear(), m = date.getMonth();
          var firstDay = (new Date(y, m, 1));
          var lastDay = (new Date(y, m + 1, 0));
          this.datepick = [new Date(firstDay), new Date(lastDay)];
          type = 'week';
          break;
        case 3:
          this.datepick = [(new Date(((new Date())).getFullYear(),0, 1)), new Date(new Date().getFullYear(),11, 31)];
          type = 'month';
          // alert(1)

          break;
        case 4:
          this.datepick = [null, null];
          type = 'year';
          break;
      }
      localStorage.removeItem("datePick"+this.$route.path + (""+this.id));
      localStorage.setItem("datePick"+this.$route.path + (""+this.id), JSON.stringify(this.datepick));
      if(this.normalDate !== true) {
        this.$emit("changeDate", [this.datepick[0], null])
      } else if(this.returnType === true) {
        // let date = [];
        // if(this.datepick)
        this.$emit("changeDate", {date:this.datepick, type: type, index: index})
      } else {
        this.$emit("changeDate", this.datepick)
      }
    },
    changeDate(date) {
      let type = "day"
      if(date[0] !== null && date[1] !== null) {
        if(""+date[0] === ""+date[1] && moment(date[0]).format("DD/MM/YYYY") === moment((new Date())).format("DD/MM/YYYY")) {
          this.buttons = this.buttons.map(b=> {
            return {name: b.name, status: false}
          })
          this.buttons[0].status = true;
        } else {
          var now = moment();
          var date1 = (now.clone().weekday(1))._d;
          var date2 = (now.clone().weekday(7))._d;
          date1 = (new Date(date1))
          date2 = (new Date(date2))
          if(date1.getDate() === date[0].getDate() && date2.getDate() === date[1].getDate() && date1.getMonth() === date[0].getMonth() && date2.getMonth() === date[1].getMonth() && date1.getFullYear() === date[0].getFullYear() && date2.getFullYear() === date[1].getFullYear()) {
            this.buttons = this.buttons.map(b=> {
              return {name: b.name, status: false}
            })
            this.buttons[1].status = true;
          } else {
            var dateNow = (new Date()), y = dateNow.getFullYear(), m = dateNow.getMonth();
            var firstDay = (new Date(y, m, 1));
            var lastDay = (new Date(y, m+1, 0));
            if(""+moment(firstDay).format("DD MM YYYY") === ""+moment(date[0]).format("DD MM YYYY") && ""+moment(lastDay).format("DD MM YYYY") === ""+moment(date[1]).format("DD MM YYYY")) {
              this.buttons = this.buttons.map(b=> {
                return {name: b.name, status: false}
              })
              this.buttons[2].status = true;
              type = "week"
            } else if(""+moment(firstDay).format("01 01 YYYY") === ""+moment(date[0]).format("DD MM YYYY") && ""+moment(lastDay).format("31 12 YYYY") === ""+moment(date[1]).format("DD MM YYYY")) {
              this.buttons = this.buttons.map(b=> {
                return {name: b.name, status: false}
              })
              this.buttons[3].status = true;
              type = "month"
            }
          }
        }
      } else {
        this.buttons = this.buttons.map(b=> {
          return {name: b.name, status: false}
        })
        this.buttons[4].status = true;
        type = "year"
      }
      localStorage.removeItem("datePick"+this.$route.path + (""+this.id));
      localStorage.setItem("datePick"+this.$route.path + (""+this.id), JSON.stringify(this.datepick));
      if(this.returnType === true) {
        this.$emit("changeDate", {date: this.datepick, type: type})
      }else {
        this.$emit("changeDate", this.datepick)
      }
    },
    getTextForDate() {
      if((""+this.datepick[0]).length !== 0 && this.datepick[0] !== null) {
        if(""+this.datepick[0] === ""+this.datepick[1]) {
          return ""+moment(this.datepick[0]).format('D MMMM YYYY')
        } else {
          var dateNow = (new Date(this.datepick[0])), y = dateNow.getFullYear(), m = dateNow.getMonth();
          var firstDay = (new Date(y, m, 1));
          var lastDay = (new Date(y, m + 1, 0));
          if(""+firstDay === ""+this.datepick[0] && ""+lastDay === ""+this.datepick[1]) {
            return ""+moment(this.datepick[0]).format('MMMM YYYY')
          } else {
            var today = (new Date(this.datepick[0]));
            var currentYear = today.getFullYear();
            var firstDayCurrYear  = (new Date( currentYear, 0, 1 ));
            var lastDayCurrYear  = (new Date( currentYear, 11, 31 ));
            if(""+firstDayCurrYear === ""+this.datepick[0] && ""+lastDayCurrYear === ""+this.datepick[1]) {
              return ""+moment(this.datepick[0]).format('YYYY')
            } else {
              if(""+this.datepick[0].getFullYear() === ""+this.datepick[1].getFullYear()) {
                return ""+moment(this.datepick[0]).format('D MMM') + " - " + moment(this.datepick[1]).format('D MMM YYYY')
              } else {
                return ""+moment(this.datepick[0]).format('D MMM YYYY') + " - " + moment(this.datepick[1]).format('D MMM YYYY')
              }
            }
          }
        }
      } else {
        return 'All time'
      }

    }
  },
  mounted() {
    // if(this.defaultStatusButton !== null && this.defaultStatusButton !== undefined) {
    //   this.changeButton(this.defaultStatusButton);
    // } else {
    //   // if(this.defaultStatusButton !== 'no') {
    //     this.changeButton(4);
    //   // }
    // }

    if(localStorage.getItem("datePick"+this.$route.path + (""+this.id)) !== null && (""+localStorage.getItem("datePick"+this.$route.path + (""+this.id))).length !== 0) {
      let date = JSON.parse(localStorage.getItem("datePick"+this.$route.path + (""+this.id)))
      this.datepick = [date[0] !== null ? new Date(date[0]) : null,  date[1] !== null ? new Date(date[1]) : null]
      this.changeDate([date[0] !== null ? new Date(date[0]) : null,  date[1] !== null ? new Date(date[1]) : null])
    } else {
      if(this.defaultStatusButton !== null && this.defaultStatusButton !== undefined) {
          this.changeButton(this.defaultStatusButton);
        } else {
        this.changeButton(4);
      }
    }


    // this.$emit("changeDate", this.datepick)
  },
  beforeCreate: function() {
    // document.body.className = 'payment-page';
  },
  beforeRouteLeave(to,from,next) {
    // document.body.className = '';
    // this.addLoadStatus();
    next();
  }
}
</script>

<style scoped lang="scss">
.payment-comment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}
.button-archive {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid var(--color-blue-dark);
  svg {
    path {
      stroke: var(--color-blue-dark);
    }
  }
  color: var(--color-blue-dark) !important;
  box-sizing: border-box;
}
  .wrap {
    position: relative;
    z-index: 5;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    .blue-button {
      margin-left: 30px;
    }
  }
  .panel-selected {
    display: flex;
    margin-left: auto;
    position: relative;
    .download-list {
      position: absolute;
      left: -45px;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      height: 34px;
      border-radius: 4px;
      border: 1px solid var(--color-blue-dark);
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      /deep/ {
        .user-button {
          svg {
            path {
              fill: var(--color-blue-dark)
            }
          }
        }
      }
    }
  }
  .buttons-period {
    display: flex;
    border: 1px solid var(--color-blue-dark);
    //border: 1px solid var(--color-border);
    border-radius: 4px;
    button {
      width: 56px;
      height: 32px;
      background-color: var(--color-modal);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s linear;
      &:not(:last-child) {
        border-right: 1px solid var(--color-blue-dark);
      }
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
    &__active {
      background-color: var(--color-blue-dark) !important;
      color: var(--color-white) !important;
    }
  }
  .datepickeer {
    margin-left: 12px;
    height: 32px;
    box-sizing: border-box;
    width: 211px;
  }
  .filter {
    width: 180px;
    height: 34px;
    border: 1px solid var(--color-blue-dark);
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--color-card);
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 12px;
    }
  }
  .short-filter {
      width: 38px !important;
      svg {
        margin: 0;
        path {
          fill: var(--color-blue-dark)
        }
      }
  }
.loader-button {
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  /deep/ {
    .sk-fading-circle {
      width: 20px;
      height: 20px;
    }
  }
}
.blue-button {
  position: relative;
}
/deep/ {
  .mx-input {
    border: 1px solid var(--color-blue-dark) !important
  }
}
.button-right {
  margin-left: auto !important;
}
.ml-for-button {
  margin-left: 55px !important;
}
.svg-blue {
  path {
    fill: var(--color-blue) !important;
  }
}
.dart-mode .button-archive {
  svg {
    path {
      fill: transparent !important;
      stroke: var(--color-blue-dark) !important;
    }
  }
}
</style>