<template>
  <div class="p-x-4">
    <div class="flex items-center cursor-pointer p-y-3 svg-white-dart" @click="isShow = !isShow">
      <div class="fz14 fw500 text-dark m-r-auto">{{title}}</div>
      <button class="m-l-auto fz12 text-blue" @click.stop="$emit('on-clear')" v-if="!isEmpty">Clear</button>
      <svg class="m-l-3 transition-transform" :class="{'rotate-180': isShow}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/></svg>
    </div>
    <b-collapse v-model="isShow" :id="id">
      <div class="p-b-4">
        <slot/>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    isEmpty: {
      type: Boolean,
      default: true
    },
    id: String
  },
  data() {
    return {
      isShow: true
    }
  }
}
</script>

<style>

</style>