<template>
  <div class="modal-wrap">
    <!-- <div class="modal-bg" @click="closeModal"></div> -->
    <div class="modal-bg"></div>
    <div class="modal-content">
      <div class="modal-content_head">
        <p class="modal-content_title" v-if="!account">Create New Bank Account <button class="modal-close" @click="closeModal"></button></p>
        <p class="modal-content_title" v-else>Edit Bank Account <button class="modal-close" @click="closeModal"></button></p>
      </div>
      <div class="modal-content_body">
        <label class="block-input" :class="{ 'block-input_error': $v.name.$error }">
          <p class="block-input_title text-gray">Holder</p>
          <input class="block-input_content" type="text" v-model.trim="$v.name.$model">
          <div class="error" v-if="!$v.name.required && $v.name.$dirty">Field is required</div>
          <div class="error" v-if="!$v.name.minLength">Name must have at least {{$v.name.$params.minLength.min}} letters.</div>
          <div class="error" v-if="!$v.name.maxLength">The maximum number of characters in the Holder is {{$v.name.$params.maxLength.max}}.</div>
        </label>

        <label class="block-input" :class="{ 'block-input_error': $v.registered_address.$error }">
          <p class="block-input_title text-gray">Registered Address</p>
          <input class="block-input_content" type="text" v-model.trim="$v.registered_address.$model">
          <div class="error" v-if="!$v.registered_address.maxLength">The maximum number of characters in the Registered Address is {{$v.registered_address.$params.maxLength.max}}.</div>
        </label>
        
        <label class="block-input" :class="{ 'block-input_error': $v.accountNumber.$error }">
          <p class="block-input_title text-gray">Account Number</p>
          <input class="block-input_content" type="text" v-model.trim="$v.accountNumber.$model">
          <div class="error" v-if="!$v.accountNumber.required && $v.accountNumber.$dirty">Field is required</div>
          <div class="error" v-if="!$v.accountNumber.minLength">Account Number must have at least {{$v.accountNumber.$params.minLength.min}} letters.</div>
          <div class="error" v-if="!$v.accountNumber.maxLength">The maximum number of characters in the Account Number is {{$v.accountNumber.$params.maxLength.max}}.</div>
        </label>

        <label class="block-input" :class="{ 'block-input_error': $v.iban.$error }">
          <p class="block-input_title text-gray">IBAN</p>
          <input class="block-input_content" type="text" v-model.trim="$v.iban.$model">
          <div class="error" v-if="!$v.iban.maxLength">The maximum number of characters in the IBAN is {{$v.iban.$params.maxLength.max}}.</div>
        </label>

        <label class="block-input" :class="{ 'block-input_error': $v.description.$error }">
          <p class="block-input_title text-gray">Description</p>
          <input class="block-input_content" type="text" v-model.trim="$v.description.$model">
          <div class="error" v-if="!$v.description.maxLength">The maximum number of characters in the Description is {{$v.description.$params.maxLength.max}}.</div>
        </label>

        <label class="block-input" :class="{ 'block-input_error': $v.bankField.$error }">
          <p class="block-input_title text-gray">Bank</p>
          <input class="block-input_content" type="text" v-model.trim="$v.bankField.$model">
          <div class="error" v-if="!$v.bankField.maxLength">The maximum number of characters in the Bank is {{$v.bankField.$params.maxLength.max}}.</div>
        </label>
        <label class="block-input">
          <p class="block-input_title text-gray">Bank Address</p>
          <input class="block-input_content" type="text" v-model.trim="bank_address">
<!--          <div class="error" v-if="!$v.bankField.maxLength">The maximum number of characters in the Bank is {{$v.bankField.$params.maxLength.max}}.</div>-->
        </label>

        
        <label class="block-input" :class="{ 'block-input_error': $v.clearingNumber.$error }">
          <p class="block-input_title text-gray">Clearing number</p>
          <input class="block-input_content" type="text" v-model.trim="$v.clearingNumber.$model">
          <div class="error" v-if="!$v.clearingNumber.maxLength">The maximum number of characters in the Clearing number is {{$v.clearingNumber.$params.maxLength.max}}.</div>
        </label>
        
        <label class="block-input" :class="{ 'block-input_error': $v.bic.$error }">
          <p class="block-input_title text-gray">BIC / SWIFT</p>
          <input class="block-input_content" type="text" v-model.trim="$v.bic.$model">
          <div class="error" v-if="!$v.bic.maxLength">The maximum number of characters in the BIC / SWIFT is {{$v.bic.$params.maxLength.max}}.</div>
        </label>

        <counterparties-drop 
          :class="{ 'block-input_error': $v.selectCurrency.$error }"
          ref="dropCurrency"
          :list="currencyList" 
          :activeItem="activeCurrency ? activeCurrency : null"
          @select="selectedCurrency" 
          title="Currency"
        ></counterparties-drop>
        <div class="error" v-if="!$v.selectCurrency.required && $v.selectCurrency.$dirty">Field is required</div>

        <div class="w100Proc position-relative">
          <button class="blue-button-text fz13 fw500 button-add-account" v-on:click="showAddAccount = true">Add new account</button>
          <counterparties-drop
              :class="{ 'block-input_error': $v.selectTrial.$error }"
              ref="dropAccount"
              :list="getTrialsInDrop"
              :activeItem="activeTrial ? activeTrial : null"
              @select="selectedTrial"
              title="Account Name"
          ></counterparties-drop>
          <div class="error" v-if="!$v.selectTrial.required && $v.selectTrial.$dirty">Field is required</div>
        </div>


      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal">Cancel</button>
        <button class="btn btn-next" @click="onSubmit" v-if="!account">Create</button>
        <button class="btn btn-next" @click="onSubmit" v-else>Save</button>
      </div>
    </div>
    <trial-balance-modal @added="loadTrialBalance" :account="null" :duplAccount="null" v-if="showAddAccount" @close="showAddAccount = false"/>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from "vuex"
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop';
import TrialBalanceModal from "@/components/accounts/trials/trialBalanceModal";
export default {
  mixins: [validationMixin],
  props: ['bank', 'account'],
  components: {
    TrialBalanceModal,
    counterpartiesDrop,
  },
  data() {
    return {
      bank_address: '',
      showAddAccount: false,
      name: '',
      registered_address: '',
      accountNumber: '',
      iban: '',
      description: '',
      clearingNumber: '',
      bankField: '',
      bic: '',
      selectCurrency: {},
      activeCurrency: null,
      selectTrial: {},
      activeTrial: null,
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(2)
    },
    registered_address: {
      maxLength: maxLength(1000),
    },
    accountNumber: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(2)
    },
    iban: {
      maxLength: maxLength(255),
    },
    description: {
      maxLength: maxLength(255),
    },
    bankField: {
      maxLength: maxLength(255),
    },
    clearingNumber: {
      maxLength: maxLength(255),
    },
    bic: {
      maxLength: maxLength(255),
    },
    selectCurrency: {
      required
    },
    selectTrial: {
      required
    },
  },
  computed: {
    ...mapState({
      currencyList: state => state.payment.valutaList,
      trials: state => state.trialBalance.trials
    }),
    ...mapGetters(['getPermissionsByType']),
    getTrialsInDrop() {
      return this.trials.map(el => {
        return {id: el.id, name: (this.getPermissionsByType('show_dop_info_for_acc_in_payments') ?  el.account_id+': '+el.name : el.name)}
      })
    },
    getFieldsInObj() {
      let obj = {
        bank_address: this.bank_address,
        holder: this.name,
        registered_address: this.registered_address,
        account_number: this.accountNumber,
        iban: this.iban,
        description: this.description,
        clearing_number: this.clearingNumber,
        bank: this.bankField,
        currency: this.selectCurrency.id,
        bic: this.bic,
        is_archive: false,
        cash: this.bank.id,
        account: this.selectTrial.id
      }
      return obj
    }
  },
  methods: {
    ...mapActions(['axiosGetTrials', 'axiosAddBankAccount', 'axiosEditBankAccount']),
    ...mapMutations(['showLoad', 'hideLoad']),
    async loadTrialBalance() {
      this.showLoad()
      await this.axiosGetTrials();
      this.hideLoad()
    },
    closeModal() {
      this.$emit('close')
    },
    async onSubmit() {
      if(!this.validateFields()) return false

      this.showLoad()
      if(this.account)
        await this.axiosEditBankAccount({id: this.account.id, account: this.getFieldsInObj})
      else
        await this.axiosAddBankAccount(this.getFieldsInObj)

      this.$emit('update')
      this.closeModal()
      this.hideLoad()
    },
    selectedCurrency(currency) {
      this.selectCurrency = currency
      this.$v.selectCurrency.$touch()
    },
    selectedTrial(trial) {
      this.selectTrial = trial
      this.$v.selectTrial.$touch()
    },
    validateFields() {
      this.$v.$touch()
      if (this.$v.$invalid)
        return false
      return true
    },
  },
  mounted() {
    if(!this.trials.length)
      this.axiosGetTrials()

    if(this.account){
      // console.log('lol', this.account);
      this.name = this.account.holder
      this.registered_address = this.account.registered_address
      this.accountNumber = this.account.account_number
      this.iban = this.account.iban
      this.description = this.account.description
      this.clearingNumber = this.account.clearing_number
      this.bankField = this.account.bank
      this.bank_address = this.account.bank_address
      this.bic = this.account.bic
      
      this.activeCurrency = this.currencyList.filter(el => {
        return el.name == this.account.currency
      })[0]
      this.activeTrial = this.getTrialsInDrop.filter(el => {
        return el.id == this.account.account.id
      })[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-wrap{
  .modal-content_head{
    padding: 16px 12px 8px 24px;
    border-bottom: 1px solid #DEE3EA;
  }
  .modal-content_title{
    margin-bottom: 0;
    font-size: 18px;
  }
  .modal-content_body{
    padding: 0 24px;
  }
  .modal-content{
    max-width: 420px;
  }
  .modal-content_bottom{
    margin-top: 20px;
    padding: 8px 24px;
    border-top: 1px solid #DEE3EA;
  }
  .btn{
    min-width: 136px;
  }
  .btn-close{
    min-width: 60px;
    margin-right: 25px;
  }
  .button-blue-border{
    display: inline-flex;
    margin-right: 8px;
    padding: 7px;
    border: 1px solid #094172;
  }
}
.error{
  margin-top: 4px;
  color: #E85353;
  font-size: 12px;
}
.bank-add_switcher{
  position: relative;
  display: flex;
  margin-left: -24px;
  margin-right: -24px;
  padding: 0 24px;
  border-bottom: 1px solid var(--color-border);
  label{
    position: relative;
    display: inline-block;
    width: 50%;
    text-align: center;
    color: #9AA3B0;
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.3s;
    &:after{
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #094172;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover{
      color: #094172;
    }
    &.active{
      color: #094172;
      &:after{
        opacity: 1;
      }
    }
    span{
      display: inline-block;
      padding: 16px;
      font-weight: 500;
    }
    input{
      position: absolute;
      opacity: 0;
      clip: rect(0, 0, 0, 0)
    }
  }
}
</style>

<style lang="scss">
.block-input.checkbox{
  display: flex;
  align-items: center;
  .custom-checkbox{
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    &:before{
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid var(--color-border);
      outline: none;
      box-shadow: none;
      pointer-events: none;
      box-sizing: border-box;
      transition: .1s linear;
    }
    &:after{
      position: absolute;
      left: 0px;
      top: 0px;
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      content: "";
      box-sizing: border-box;
      background: no-repeat 50% / 50% 50%;
    }
  }
  .block-input_content:checked ~ .custom-checkbox:before {
    border-color: var(--color-blue-dark) !important;
    background-color: #094172;
  }
  .block-input_content:checked ~ .custom-checkbox:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
}
</style>
<style lang="scss">
.button-add-account {
  position: absolute;
  right: 0;
}
</style>