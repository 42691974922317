<template>
  <div class="">
    <p class="m-b-1 fz13 text-gray-text">Attachments</p>
    <vue-dropzone :options="dropzoneOptions" :useCustomSlot=true ref="dropzone" id="dropzone">
      <div class="dropzone-custom-content">
        <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
        <div class="subtitle">...or click to select a file from your computer</div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'

export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
    }
  },
  methods: {
    getFiles() {
      return this.$refs.dropzone.getAcceptedFiles()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ {

  .vue-dropzone {
    display: flex;
    padding: 0;
    padding-top: 41px;
    flex-direction: column;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    border: 1px solid var(--color-border);
    background-color: var(--color-input);
    border-radius: 4px;
    align-items: center;
    position: relative;
    overflow-y: auto;

    &:after {
      border-bottom: 1px solid var(--color-border);
      content: "+ Attach files";
      position: absolute;
      top: 0px;
      font-size: 13px;
      font-weight: 600;
      color: var(--color-blue-dark);
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dz-preview {
    &:hover {
      background-color: var(--color-input);
    }
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    height: 38px;
    min-height: 38px;
    max-height: 38px;
    box-sizing: border-box;
    .dz-image {
      margin-left: 6px;
      max-width: 30px;
      max-height: 30px;
      img {
        border-radius: 3px;
        filter: none !important;
        width: 100%;
        height: 100%;
      }
    }
    .dz-details {
      margin-left: 12px;
      opacity: 1;
      position: static;
      padding: 0;
      min-width: max-content;
      max-height: max-content;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
      width: 60%;
      overflow: hidden;
      .dz-size {
        margin: 0;
        margin-left: 12px;
        * {
          padding: 0;
          font-size: 13px;
          color: #9AA3B0;
        }
      }
      .dz-filename {
        //width: 70%;
        //overflow: hidden;
        * {
          font-size: 13px;
          color: #4B4B4B;
          padding: 0;
          width: 50%;
          white-space: nowrap; /* Запрещаем перенос строк */
          overflow: hidden; /* Обрезаем все, что не помещается в область */
          text-overflow: ellipsis; /* Добавляем многоточие */
        }
      }
    }
    .dz-remove {
      border: none !important;
      font-size: 0;
      opacity: 1;
      position: static;
      width: 0px;
      height: 0px;
      bottom: auto;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 0;
      &::after {
        content: url("../../../assets/ic_close24.svg");
        color: #2C2C2C;
        position: absolute;
        right: 6px;
        top: calc(50% - 12px);
      }
    }
    .dz-error-message {
      z-index: 100;
      position: absolute;
      top: -15px;
      display: none;
    }
    .dz-success-mark {
      z-index: 100;
      position: absolute;
      top: -15px;
      display: none;
    }
    .dz-progress {
      opacity: 1 !important;
      right: 33px;
      left: auto;
      top: auto;
      margin: 0;
      width: 120px;
      height: 4px;
      z-index: 100;
      background: var(--color-card-border);
      .dz-upload {
        background: var(--color-blue-dark)
      }
    }
  }
}
</style>