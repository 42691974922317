import axios from 'axios'

export default {
    actions: {
        getAxiosTagsUsage(ctx, data) {
            let tags = [];
            tags.push(data.tag.id)
            return axios.post(process.env.VUE_APP_URL+"/get/task/filter/", {
                    tags: tags,
                }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                // console.log(response.data.data)
                if(response.data.data.length > 0) {
                    return true;
                } else {
                    return false
                }
            }).catch(()=> {
                return false;
            });
        },
        getAxiosAllTag(ctx) {
            return axios.get(process.env.VUE_APP_URL+"/tags/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                ctx.commit("setTag", response.data.results);
            },)
        },
        async getAxiosTagById(ctx, id) {
            let response = await axios.get(process.env.VUE_APP_URL + "/tags/"+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            })
            return response.data;
        },
        axiosTagCreate(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/tags/", {
                "name": data.name
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            });
        },
        axiosDeleteTag(ctx, data) {
            return axios.delete(process.env.VUE_APP_URL+"/tags/" + data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            });
        },
        axiosRenameTag(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL+"/tags/" + data.id, {
                "name": data.name
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        }
    },
    mutations: {
        setTag(state, data) {
            state.tag.splice(0, state.tag.length);
            for(let i=0; i<data.length; i++) {
                data[i]["status"] = false;
                data[i]["editStatus"] = false;
            }
            state.tag = JSON.parse(JSON.stringify(data));
        },
    },
    state: {
        tag: [],
    },
    getters: {
        getAllTag(state) {
            return state.tag;
        }
    },
}
