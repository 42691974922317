<template>
  <div class="payment-accounts_wrap">
    <payment-accounts-top @add-account="addAccount" v-if="getAccounts.length" />
    <payment-accounts @edit-account="editAccount" @on-archive="onArchive" v-if="getAccounts.length" />

    <div class="counterparties-list-empty" v-if="!getAccounts.length">
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.5905 121.467C34.3947 121.36 34.1483 121.218 33.9803 121.068C32.3841 119.647 30.2728 119.641 28.6707 121.068C27.6139 122.009 26.2481 122.009 25.1914 121.068C24.4072 120.37 23.4893 120.001 22.5366 120.001C21.5839 120.001 20.6659 120.37 19.8818 121.068C18.8249 122.009 17.4592 122.009 16.4024 121.068C14.8062 119.647 12.695 119.641 11.0929 121.068C10.9403 121.204 10.7216 121.344 10.4243 121.498C10.0574 121.687 9.89796 122.172 10.0682 122.581C10.2385 122.989 10.6739 123.167 11.0409 122.977C11.4518 122.765 11.7591 122.563 12.008 122.341C13.0648 121.4 14.4305 121.4 15.4874 122.341C17.0836 123.762 19.1948 123.768 20.7969 122.341C21.8537 121.4 23.2194 121.4 24.2763 122.341C25.8726 123.762 27.9838 123.768 29.5859 122.341C30.6427 121.4 32.0084 121.4 33.0653 122.341C33.3426 122.588 33.6652 122.777 33.9443 122.93C34.3073 123.129 34.7463 122.962 34.9247 122.558C35.1031 122.154 34.9536 121.666 34.5905 121.467Z" fill="#DEE3EA"/>
        <path d="M42.4095 72.467C42.6053 72.3598 42.8517 72.2176 43.0197 72.068C44.6159 70.6466 46.7272 70.6414 48.3293 72.068C49.3861 73.009 50.7519 73.009 51.8086 72.068C52.5928 71.3698 53.5107 71.0007 54.4634 71.0007C55.4161 71.0007 56.3341 71.3698 57.1182 72.068C58.1751 73.009 59.5408 73.009 60.5976 72.068C62.1938 70.6467 64.305 70.6414 65.9071 72.068C66.0597 72.2038 66.2784 72.3444 66.5757 72.498C66.9426 72.6875 67.102 73.1722 66.9318 73.5806C66.7615 73.989 66.3261 74.1665 65.9591 73.977C65.5482 73.7648 65.2409 73.5628 64.992 73.3411C63.9352 72.4001 62.5695 72.4 61.5126 73.3411C59.9164 74.7625 57.8052 74.7677 56.2031 73.3411C55.1463 72.4001 53.7806 72.4 52.7237 73.3411C51.1274 74.7625 49.0162 74.7677 47.4141 73.3411C46.3573 72.4001 44.9916 72.4 43.9347 73.3411C43.6574 73.588 43.3348 73.7774 43.0557 73.9302C42.6927 74.1289 42.2537 73.9623 42.0753 73.5583C41.8969 73.1542 42.0464 72.6657 42.4095 72.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 31.2C32.6627 31.2 33.2 30.6627 33.2 30C33.2 29.3373 32.6627 28.8 32 28.8C31.3373 28.8 30.8 29.3373 30.8 30C30.8 30.6627 31.3373 31.2 32 31.2ZM32 33C33.6569 33 35 31.6569 35 30C35 28.3431 33.6569 27 32 27C30.3431 27 29 28.3431 29 30C29 31.6569 30.3431 33 32 33Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M299 120.8C298.337 120.8 297.8 121.337 297.8 122C297.8 122.663 298.337 123.2 299 123.2C299.663 123.2 300.2 122.663 300.2 122C300.2 121.337 299.663 120.8 299 120.8ZM299 119C297.343 119 296 120.343 296 122C296 123.657 297.343 125 299 125C300.657 125 302 123.657 302 122C302 120.343 300.657 119 299 119Z" fill="#DEE3EA"/>
        <path d="M284.409 27.467C284.605 27.3598 284.852 27.2176 285.02 27.068C286.616 25.6466 288.727 25.6414 290.329 27.068C291.386 28.009 292.752 28.009 293.809 27.068C294.593 26.3698 295.511 26.0007 296.463 26.0007C297.416 26.0007 298.334 26.3698 299.118 27.068C300.175 28.009 301.541 28.009 302.598 27.068C304.194 25.6467 306.305 25.6414 307.907 27.068C308.06 27.2038 308.278 27.3444 308.576 27.498C308.943 27.6875 309.102 28.1722 308.932 28.5806C308.762 28.989 308.326 29.1665 307.959 28.977C307.548 28.7648 307.241 28.5628 306.992 28.3411C305.935 27.4001 304.569 27.4 303.513 28.3411C301.916 29.7625 299.805 29.7677 298.203 28.3411C297.146 27.4001 295.781 27.4 294.724 28.3411C293.127 29.7625 291.016 29.7677 289.414 28.3411C288.357 27.4001 286.992 27.4 285.935 28.3411C285.657 28.588 285.335 28.7774 285.056 28.9302C284.693 29.1289 284.254 28.9623 284.075 28.5583C283.897 28.1542 284.046 27.6657 284.409 27.467Z" fill="#DEE3EA"/>
        <path d="M247.409 69.467C247.605 69.3598 247.852 69.2176 248.02 69.068C249.616 67.6466 251.727 67.6414 253.329 69.068C254.386 70.009 255.752 70.009 256.809 69.068C257.593 68.3698 258.511 68.0007 259.463 68.0007C260.416 68.0007 261.334 68.3698 262.118 69.068C263.175 70.009 264.541 70.009 265.598 69.068C267.194 67.6467 269.305 67.6414 270.907 69.068C271.06 69.2038 271.278 69.3444 271.576 69.498C271.943 69.6875 272.102 70.1722 271.932 70.5806C271.762 70.989 271.326 71.1665 270.959 70.977C270.548 70.7648 270.241 70.5628 269.992 70.3411C268.935 69.4001 267.569 69.4 266.513 70.3411C264.916 71.7625 262.805 71.7677 261.203 70.3411C260.146 69.4001 258.781 69.4 257.724 70.3411C256.127 71.7625 254.016 71.7677 252.414 70.3411C251.357 69.4001 249.992 69.4 248.935 70.3411C248.657 70.588 248.335 70.7774 248.056 70.9302C247.693 71.1289 247.254 70.9623 247.075 70.5583C246.897 70.1542 247.046 69.6657 247.409 69.467Z" fill="#DEE3EA"/>
        <path d="M106 55.9875V114.438C106 122.169 112.268 128.438 120 128.438H176.725M106 55.9875V34.625C106 26.893 112.268 20.625 120 20.625H128.425M106 55.9875H214.675M214.675 55.9875V34.625C214.675 26.893 208.407 20.625 200.675 20.625H192.25M214.675 55.9875V93.9375M136.188 20.625H156.025M163.788 20.625H184.488" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M136.188 70.6484V106.011" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M160.337 70.6484V106.011" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M195.269 79.707L125.406 79.707" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M167.669 96.957L125.406 96.957" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M184.487 70.6484V86.6047" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <rect x="128.562" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <rect x="156.163" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <rect x="184.625" y="13" width="7.4875" height="18.7" rx="3.74375" stroke="#DEE3EA" stroke-width="2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M176.725 98.4084V145.649C176.725 148.419 180 149.824 181.946 147.888L186.792 143.07C187.893 141.975 189.621 141.878 190.833 142.844L198.981 149.335C200.094 150.222 201.656 150.222 202.769 149.335L210.917 142.844C212.129 141.878 213.857 141.975 214.958 143.07L219.804 147.888C221.751 149.824 225.025 148.419 225.025 145.649V98.4084C225.025 95.9392 223.059 93.9375 220.634 93.9375H181.116C178.691 93.9375 176.725 95.9392 176.725 98.4084Z" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M202 118.55H200C198.896 118.55 198 117.699 198 116.65V115.7C198 114.651 198.896 113.8 200 113.8H202C203.105 113.8 204 114.651 204 115.7C204 116.225 204.448 116.65 205 116.65C205.552 116.65 206 116.225 206 115.7C206 113.601 204.209 111.9 202 111.9V110.95C202 110.425 201.552 110 201 110C200.448 110 200 110.425 200 110.95V111.9C197.791 111.9 196 113.601 196 115.7V116.65C196 118.749 197.791 120.45 200 120.45H202C203.105 120.45 204 121.301 204 122.35V123.3C204 124.349 203.105 125.2 202 125.2H200C198.896 125.2 198 124.349 198 123.3C198 122.775 197.552 122.35 197 122.35C196.448 122.35 196 122.775 196 123.3C196 125.399 197.791 127.1 200 127.1V128.05C200 128.575 200.448 129 201 129C201.552 129 202 128.575 202 128.05V127.1C204.209 127.1 206 125.399 206 123.3V122.35C206 120.251 204.209 118.55 202 118.55Z" fill="#DEE3EA"/>
      </svg>
      <p>The list of payment accounts is empty</p>
      <p class="payment-accounts_empty-add"><button @click="addAccount">Create a new payment account</button></p>
    </div>

    <payment-account-modal v-if="isModalOpen" :account="editableAccount" @close="closeModal" @submit="saveAccount" @submit-and-new="saveAndNewAccount" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import paymentAccountsTop from '@/components/counterparties/paymentAccounts/paymentAccountsTop'
import paymentAccounts from '@/components/counterparties/paymentAccounts/paymentAccounts'
import paymentAccountModal from '@/components/counterparties/paymentAccounts/paymentAccountModal'
export default {
  components: { paymentAccountsTop, paymentAccounts, paymentAccountModal },
  data() {
    return {
      isModalOpen: false,
      editableAccount: null
    }
  },
  watch: {
    async $route() {
      await this.$nextTick()
      this.loadAccounts()
    }
  },
  computed: {
    ...mapGetters(['getPaymentAccountsByID']),
    getAccounts() {
      return this.getPaymentAccountsByID(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions(['axiosAddPaymentAccount', 'axiosEditPaymentAccount', 'axiosGetPaymentAccountsByID', 'axiosArchivedPaymentAccount']),
    ...mapMutations(['addTip']),
    openModal() {
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    },
    addAccount() {
      this.editableAccount = null
      this.openModal()
    },
    editAccount(account) {
      this.editableAccount = account
      this.openModal()
    },
    async onArchive(account) {
      await this.axiosArchivedPaymentAccount({clientId: account.client, id: account.id})
      this.addTip('Payment Account in archived')
    },
    saveAccount(account) {
      if(this.editableAccount){
        this.axiosEditPaymentAccount({account, id: this.editableAccount.id})
      }else{
        this.axiosAddPaymentAccount(account)
      }
      this.closeModal()
    },
    saveAndNewAccount(account) {
      this.axiosAddPaymentAccount(account)
    },
    loadAccounts() {
      if(this.getAccounts.length == 0)
        this.axiosGetPaymentAccountsByID(this.$route.params.id)
    }
  },
  mounted() {
    this.loadAccounts()
  }
}
</script>

<style lang="scss">
  .payment-accounts_empty-add{
    margin-top: 6px;
    text-align: center;
    button{
      font-size: 14px;
      color: #319FEF;
    }
  }
</style>