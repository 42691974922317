<template>
  <div class="wrap-filter">
    <modal-delete-tag ref="modalDeleteTag" @renderTask="convertToFormAxiosFilter"></modal-delete-tag>
    <div class="block-top">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H18.3392C18.7093 7.05017 19 6.75585 19 6.38127C19 6.00669 18.7093 5.71237 18.3392 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#2C2C2C"/>
        <path d="M14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6204 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3361 15.326 9.61374 14.2423 9.61374ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#2C2C2C"/>
        <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5185 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8028 8.42731 20.5252 9.48458 20.5252C10.5683 20.5252 11.467 19.8028 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5185 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5553 10.5154 18.144C10.5154 18.7326 10.0396 19.1874 9.48458 19.1874C8.92951 19.1874 8.45374 18.7326 8.45374 18.144C8.48018 17.5553 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
      </svg>
      <p class="fw500 fz14 text-dark">Filter</p>
      <div class="countActiveFilter fz12 text-white" v-if="countActiveFilter !== 0">
        {{countActiveFilter}}
        <button class="" v-on:click="clearAll">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.05373 3.92533C2.81022 3.68421 2.81022 3.29329 3.05373 3.05217C3.29724 2.81106 3.69206 2.81106 3.93557 3.05217L8.94574 7.97332C9.18925 8.21444 9.18925 8.60536 8.94574 8.84647C8.70223 9.08759 8.30741 9.08759 8.0639 8.84647L3.05373 3.92533Z" fill="white"/>
            <path d="M8.06398 3.15356C8.3075 2.91245 8.70231 2.91245 8.94582 3.15356C9.18933 3.39468 9.18933 3.7856 8.94582 4.02672L3.93565 8.94786C3.69214 9.18898 3.29732 9.18898 3.05381 8.94786C2.8103 8.70675 2.8103 8.31582 3.05381 8.07471L8.06398 3.15356Z" fill="white"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="wrap-block">
      <div class="content-button-collapse">
        <button class="blue-button-text fz12" v-if="selectedTaskType.length !== 0" v-on:click="clearTaskType">Clear</button>
        <b-button v-b-toggle.collapse-2 class="m-1" v-on:click="showCollapse7Copy = !showCollapse7Copy">
          <p class="fz13 fw500 text-dark">Task type</p>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/>
          </svg>
        </b-button>

      </div>
      <b-collapse :visible="showCollapse7" id="collapse-2">
        <b-card>
          <b-form-checkbox
              @change="convertToFormAxiosFilter"
              v-for="(option, k) in optionTaskType"
              v-model="selectedTaskType"
              :key="k"
              :value="option"
          >
            <p class="fz13 text-dark">{{ option.name }}</p>
          </b-form-checkbox>
        </b-card>
      </b-collapse>
    </div>
    <div class="wrap-block">
      <div class="content-button-collapse">
        <button class="blue-button-text fz12" v-if="selectedStatus.length !== 0 && selectedStatus.length !== 3" v-on:click="clearStatus">Clear</button>
        <b-button v-b-toggle.collapse-3 class="m-1" v-on:click="showCollapse6Copy = !showCollapse6Copy">
          <p class="fz13 fw500 text-dark">Status</p>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/>
          </svg>
        </b-button>

      </div>
      <b-collapse :visible="showCollapse6" id="collapse-3">
        <b-card>
          <b-form-checkbox
              @change="convertToFormAxiosFilter"
              v-for="(option, k) in optionStatus"
              v-model="selectedStatus"
              :key="k"
              :value="option"
          >
            <p class="text-with-svg fz13 text-dark"><span v-html="option.svg"></span>{{ option.name }}</p>
          </b-form-checkbox>
        </b-card>
      </b-collapse>
    </div>

    <div class="wrap-block block-priority">
      <div class="content-button-collapse">
        <button class="blue-button-text fz12" v-if="selectedPriority.length !== 0" v-on:click="clearPriority">Clear</button>
        <b-button v-b-toggle.collapse-5 class="m-1" v-on:click="showCollapse5Copy = !showCollapse5Copy">
          <p class="fz13 fw500 text-dark">Priority</p>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/>
          </svg>
        </b-button>

      </div>
      <b-collapse :visible="showCollapse5" id="collapse-5">
        <b-card>
          <b-form-checkbox
              @change="convertToFormAxiosFilter"
              v-for="(option, k) in optionPriority"
              v-model="selectedPriority"
              :key="k"
              :value="option"
          >
            <p class="text-with-svg fz13 text-dark"><span v-html="option.svg"></span>{{ option.name }}</p>
          </b-form-checkbox>
        </b-card>
      </b-collapse>
    </div>
    <div class="wrap-block">
      <div class="content-button-collapse">
        <button class="blue-button-text fz12" v-if="dateClearShow" v-on:click="clearDates">Clear</button>
        <b-button v-b-toggle.collapse-6 class="m-1" v-on:click="showCollapse4Copy = !showCollapse4Copy">
          <p class="fz13 fw500 text-dark">Dates</p>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/>
          </svg>
        </b-button>

      </div>
      <b-collapse :visible="showCollapse4" id="collapse-6">
        <b-card class="">
          <div class="date-block">
            <p class="fz13 text-dark">Created</p>
            <div class="">
              <date-picker @change="convertToFormAxiosFilter"           value-type="date"
                           popup-class="data-range" v-model="createdDate" range placeholder="Choose date or period"></date-picker>
            </div>
            <p class="fz13 text-dark">Due date</p>
            <div class="">
              <date-picker @change="convertToFormAxiosFilter"           value-type="date"
                           popup-class="data-range" v-model="dueDate" range placeholder="Choose date or period"></date-picker>
            </div>
          </div>



        </b-card>
      </b-collapse>
    </div>
<!--    <div class="wrap-block">-->
<!--      <div class="content-button-collapse">-->
<!--        <button class="blue-button-text fz12" v-if="selectedTerms.length !== 0" v-on:click="clearTerms">Clear</button>-->
<!--        <b-button v-b-toggle.collapse-7 class="m-1" v-on:click="showCollapse3Copy = !showCollapse3Copy">-->
<!--          <p class="fz13 fw500 text-dark">Terms</p>-->
<!--          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/>-->
<!--          </svg>-->
<!--        </b-button>-->
<!--      </div>-->
<!--      <b-collapse :visible="showCollapse3" id="collapse-7">-->
<!--        <b-card>-->
<!--          <b-form-checkbox-->
<!--              @change="convertToFormAxiosFilter"-->
<!--              v-for="(option, k) in optionTerms"-->
<!--              v-model="selectedTerms"-->
<!--              :key="k"-->
<!--              :value="option"-->
<!--          >-->
<!--            <p class="text-with-svg fz13 text-dark"><span v-html="option.svg"></span>{{ option.name }}</p>-->
<!--          </b-form-checkbox>-->
<!--        </b-card>-->
<!--      </b-collapse>-->
<!--    </div>-->
    <div class="wrap-block">
      <div class="content-button-collapse">
<!--        <button class="blue-button-text fz12" v-if="showClearTags" v-on:click="clearTags">Clear</button>-->
        <b-button v-b-toggle.collapse-8 class="m-1" v-on:click="showCollapse2Copy = !showCollapse2Copy">
          <p class="fz13 fw500 text-dark">Tags</p>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/>
          </svg>
        </b-button>
      </div>
      <b-collapse :visible="showCollapse2" id="collapse-8">
        <b-card>
          <div class="dropdown-block">
            <task-list-tag-select ref="tagDrop" @deleteUsageTag="deleteTagWhichUsed" @changeSelectTag="changeShowClearTags" @closeDropdown="convertToFormAxiosFilter"></task-list-tag-select>
          </div>
        </b-card>
      </b-collapse>
    </div>
    <div class="wrap-block">
      <div class="content-button-collapse">
        <button class="blue-button-text fz12" v-if="showClearTaskAssignee" v-on:click="clearTaskAssignee">Clear</button>
        <b-button v-b-toggle.collapse-9 class="m-1" v-on:click="showCollapse1Copy = !showCollapse1Copy">
          <p class="fz13 fw500 text-dark">Task assignee</p>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/>
          </svg>
        </b-button>
      </div>
      <b-collapse :visible="showCollapse1" id="collapse-9">
        <b-card>
          <div class="dropdown-block" v-if="getPermissionsForUserCanSeeTask.status">
            <async-select-employees :bgcWhite="true" v-model="assigneeSelect" @close="checkConvertToFormAxiosFilterAssign" :is-multiple="true"></async-select-employees>

<!--            <task-list-change-assignee :noAssigneed="true" ref="assigneeDrop" @changeEmployee="changeShowAssignee" @closeDropdown="convertToFormAxiosFilter"></task-list-change-assignee>-->
          </div>
        </b-card>
      </b-collapse>
    </div>
    <div class="wrap-block">
      <div class="content-button-collapse">
        <button class="blue-button-text fz12" v-if="showClearTaskCreator" v-on:click="clearTaskCreators">Clear</button>
        <b-button v-b-toggle.collapse-10 class="m-1" v-on:click="showCollapseCopy = !showCollapseCopy">
          <p class="fz13 fw500 text-dark">Task creator</p>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.3999L6.00001 8.3999L10 4.3999H2Z" fill="#2C2C2C"/>
          </svg>
        </b-button>
      </div>
      <b-collapse :visible="showCollapse" id="collapse-10">
        <b-card>
          <div class="dropdown-block" v-if="getPermissionsForUserCanSeeTask.status">
            <async-select-employees :bgcWhite="true" v-model="creatorSelect" @close="checkConvertToFormAxiosFilterAssign" :is-multiple="true"></async-select-employees>
<!--            <task-list-change-assignee :noAssigneed="true" ref="creatorDrop" @changeEmployee="changeShowCreate" @closeDropdown="convertToFormAxiosFilter"></task-list-change-assignee>-->
          </div>
        </b-card>
      </b-collapse>
    </div>
<!--{{JSON.parse(this.$cookies.get("dueDate"))}}-->


  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment'

import TaskListTagSelect from "@/components/tasks/tasks-list/task-list-tag-select";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ModalDeleteTag from "@/components/tasks/modalDeleteTag";
import AsyncSelectEmployees from "@/components/tasks/async-select-employees";

export default {
  computed: mapGetters(["getPermissionsByType", "getLoginData", "getPermissionsForUserCanSeeTask", "getDepartments", "getSortTaskBy", "getAllPriority", "getAllTag", "getEmployees"]),
  name: "task-list-filter",
  components: {AsyncSelectEmployees, ModalDeleteTag, TaskListTagSelect, DatePicker },
  props: {
    searchName: {
      type: String,
      default: '',
    }
  },
  watch: {
    getSortTaskBy: function () {
      if(!this.noAxios)
      this.convertToFormAxiosFilter();
    },
    getEmployees: function () {
      this.conversionForTasks({tags: this.getAllTag, executors: this.getEmployees.data.results});
    },
    $route() {
        this.optionTaskType= this.getOptionsTaskType()

        this.noAxios = true;

        window.onbeforeunload = closingCode;
        let vm = this;
        function closingCode(){
          vm.setCollapse();
          return null;
        }
        if(localStorage.getItem(this.$route.path+"showCollapseCopy") !== null && localStorage.getItem(this.$route.path+"showCollapseCopy") !== undefined) {
          this.showCollapse = ""+localStorage.getItem(this.$route.path+"showCollapseCopy") === "true";
          this.showCollapseCopy = ""+localStorage.getItem(this.$route.path+"showCollapseCopy") === "true";
          // console.log("this.showCollapse");
          // console.log(this.showCollapse);
          // console.log(this.showCollapseCopy);
        }
        if(localStorage.getItem(this.$route.path+"showCollapse1Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse1Copy") !== undefined) {
          this.showCollapse1 = ""+localStorage.getItem(this.$route.path+"showCollapse1Copy") === "true";
          this.showCollapse1Copy = ""+localStorage.getItem(this.$route.path+"showCollapse1Copy") === "true";
        }
        if(localStorage.getItem(this.$route.path+"showCollapse2Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse2Copy") !== undefined) {
          this.showCollapse2 = ""+localStorage.getItem(this.$route.path+"showCollapse2Copy") === "true";
          this.showCollapse2Copy = ""+localStorage.getItem(this.$route.path+"showCollapse2Copy") === "true";
        }
        if(localStorage.getItem(this.$route.path+"showCollapse3Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse3Copy") !== undefined) {
          this.showCollapse3 = ""+localStorage.getItem(this.$route.path+"showCollapse3Copy") === "true";
          this.showCollapse3Copy = ""+localStorage.getItem(this.$route.path+"showCollapse3Copy") === "true";
        }
        if(localStorage.getItem(this.$route.path+"showCollapse4Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse4Copy") !== undefined) {
          this.showCollapse4 = ""+localStorage.getItem(this.$route.path+"showCollapse4Copy") === "true";
          this.showCollapse4Copy = ""+localStorage.getItem(this.$route.path+"showCollapse4Copy") === "true";
        }
        if(localStorage.getItem(this.$route.path+"showCollapse5Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse5Copy") !== undefined) {
          this.showCollapse5 = ""+localStorage.getItem(this.$route.path+"showCollapse5Copy") === "true";
          this.showCollapse5Copy = ""+localStorage.getItem(this.$route.path+"showCollapse5Copy") === "true";
        }
        if(localStorage.getItem(this.$route.path+"showCollapse6Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse6Copy") !== undefined) {
          this.showCollapse6 = ""+localStorage.getItem(this.$route.path+"showCollapse6Copy") === "true";
          this.showCollapse6Copy = ""+localStorage.getItem(this.$route.path+"showCollapse6Copy") === "true";
        }
        if(localStorage.getItem(this.$route.path+"showCollapse7Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse7Copy") !== undefined) {
          this.showCollapse7 = ""+localStorage.getItem(this.$route.path+"showCollapse7Copy") === "true";
          this.showCollapse7Copy = ""+localStorage.getItem(this.$route.path+"showCollapse7Copy") === "true";
        }


        this.setCookiesTime = false;
        if(localStorage.getItem(this.$route.path+"selectedTaskType") !== null && localStorage.getItem(this.$route.path+"selectedTaskType") !== undefined) {
          this.selectedTaskType = JSON.parse(localStorage.getItem(this.$route.path+"selectedTaskType"));
        }
        if(localStorage.getItem(this.$route.path+"selectedStatus") !== null && localStorage.getItem(this.$route.path+"selectedStatus") !== undefined) {
          this.selectedStatus = JSON.parse(localStorage.getItem(this.$route.path+"selectedStatus"));
        }
        if(localStorage.getItem(this.$route.path+"createdDate") !== null && localStorage.getItem(this.$route.path+"createdDate") !== undefined) {
          if(JSON.parse(localStorage.getItem(this.$route.path+"dueDate")).length === 2) {
            if(""+JSON.parse(localStorage.getItem(this.$route.path+"createdDate"))[0] !== 'null' && (""+JSON.parse(localStorage.getItem(this.$route.path+"createdDate"))[0]).length !== 0) {
              this.createdDate = [new Date(JSON.parse(localStorage.getItem(this.$route.path+"createdDate"))[0]), new Date(JSON.parse(localStorage.getItem(this.$route.path+"createdDate"))[1])];
            }
          }

        }
        if(localStorage.getItem(this.$route.path+"dueDate") !== null && localStorage.getItem(this.$route.path+"dueDate") !== undefined) {
          if(JSON.parse(localStorage.getItem(this.$route.path+"dueDate")).length === 2) {
            if(""+JSON.parse(localStorage.getItem(this.$route.path+"dueDate"))[0] !== 'null' && (""+JSON.parse(localStorage.getItem(this.$route.path+"dueDate"))[0]).length !== 0) {
              this.dueDate = [new Date(JSON.parse(localStorage.getItem(this.$route.path+"dueDate"))[0]), new Date(JSON.parse(localStorage.getItem(this.$route.path+"dueDate"))[1])];
            }
          }

        }
        if(localStorage.getItem(this.$route.path+"selectedTerms") !== null && localStorage.getItem(this.$route.path+"selectedTerms") !== undefined) {
          this.selectedTerms = JSON.parse(localStorage.getItem(this.$route.path+"selectedTerms"));
        }
        if(localStorage.getItem(this.$route.path+"assigneeSelect") !== null && localStorage.getItem(this.$route.path+"assigneeSelect") !== undefined) {
          let array = JSON.parse(localStorage.getItem(this.$route.path+"assigneeSelect"));
          // array = array.map(a=> {
          //   return {id: a}
          // });
          this.assigneeSelect = array
          // this.$refs.assigneeDrop.setExecutors(array,true);
        }
        if(localStorage.getItem(this.$route.path+"creatorSelect") !== null && localStorage.getItem(this.$route.path+"creatorSelect") !== undefined) {
          let array = JSON.parse(localStorage.getItem(this.$route.path+"creatorSelect"));
          // array = array.map(a=> {
          //   return {id: a}
          // });
          this.creatorSelect = array;
          // this.$refs.creatorDrop.setExecutors(array,true);
        }
        if(localStorage.getItem(this.$route.path+"tagsSelect") !== null && localStorage.getItem(this.$route.path+"tagsSelect") !== undefined) {
          let array = JSON.parse(localStorage.getItem(this.$route.path+"tagsSelect"));
          array = array.map(a=> {
            return {id: a}
          });
          let form = {
            tags: array
          }
          setTimeout(()=> {this.$refs.tagDrop.setTags(form);}, 1000)

        }





        // console.log("287364782364786238746872364872")
        // console.log(JSON.parse(this.$cookies.get("dueDate")))

        // this.showLoad()

        if(this.getAllPriority.length === 0) {
          this.axiosGetPriorityTask().then(()=> {
            let array = [];
            for(let i=0; i<this.getAllPriority.length; i++) {
              for(let j=0; j<this.optionPriority.length; j++) {
                if((this.getAllPriority[i].type).toLowerCase() === (this.optionPriority[j].name).toLowerCase()) {
                  let form = {
                    name: this.getAllPriority[i].type,
                    id: this.getAllPriority[i].id,
                    svg: this.optionPriority[j].svg,
                    status: false,
                  }
                  array.push(form);
                }
              }
            }
            this.optionPriority = JSON.parse(JSON.stringify(array));
            if(localStorage.getItem(this.$route.path+"selectedPriority") !== null && localStorage.getItem(this.$route.path+"selectedPriority") !== undefined) {
              this.selectedPriority = JSON.parse(localStorage.getItem(this.$route.path+"selectedPriority"));

            } else {
              this.selectedPriority = JSON.parse(JSON.stringify(array));
            }
            this.setCookiesTime = true;

            this.convertToFormAxiosFilter();
          })

        } else {
          let array = [];
          for(let i=0; i<this.getAllPriority.length; i++) {
            for(let j=0; j<this.optionPriority.length; j++) {
              if((this.getAllPriority[i].type).toLowerCase() === (this.optionPriority[j].name).toLowerCase()) {
                let form = {
                  name: this.getAllPriority[i].type,
                  id: this.getAllPriority[i].id,
                  svg: this.optionPriority[j].svg,
                  status: false,
                }
                array.push(form);
              }
            }
          }
          this.optionPriority = JSON.parse(JSON.stringify(array));
          if(localStorage.getItem(this.$route.path+"selectedPriority") !== null && localStorage.getItem(this.$route.path+"selectedPriority") !== undefined) {
            this.selectedPriority = JSON.parse(localStorage.getItem(this.$route.path+"selectedPriority"));
          } else {
            this.selectedPriority = JSON.parse(JSON.stringify(array));
          }
          this.setCookiesTime = true;
          this.convertToFormAxiosFilter();
        }
      }
  },
  data() {
    return {
      creatorSelect: [],
      assigneeSelect: [],
      noAxios: true,

      showCollapse: false,
      showCollapse1: false,
      showCollapse2: false,
      showCollapse3: false,
      showCollapse4: false,
      showCollapse5: false,
      showCollapse6: false,
      showCollapse7: false,

      showCollapseCopy: false,
      showCollapse1Copy: false,
      showCollapse2Copy: false,
      showCollapse3Copy: false,
      showCollapse4Copy: false,
      showCollapse5Copy: false,
      showCollapse6Copy: false,
      showCollapse7Copy: false,

      countActiveFilter: 0,
      showClearTaskCreator: false,
      showClearTaskAssignee: false,
      showClearTags: false,
      dateClearShow: false,
      clearAllActive: false,
      optionTaskType: [],
      selectedTaskType: [
        {name: "Vessel Search", value: "SHIP_SEARCH_WITH_CARGO"},
        // {name: "Cargo Search", value: "SHIP_SEARCH_WITH_CARGO_WITHOUT_CARGO"},
        {name: "Manually", value: "MANUAL"},
        {name: "Manually with contract", value: "MANUAL_WITH_CONTRACT"},
        {name: "Search Cargo for our Vessel", value: "CARGO_SEARCH_WITH_SHIP"},
        // {name: "Cargo Search without Vessel", value: "CARGO_SEARCH_WITH_SHIP_WITHOUT_SHIP"}

      ],
      optionStatus: [
        {name: "Open", value: "Open"},
        {name: "In Progress", value: "In progress"},
        {name: "Done", value: "Done"},
      ],
      selectedStatus: [
        {name: "Open", value: "Open"},
        {name: "In Progress", value: "In progress"},
      ],
      optionPriority: [
        {name: "Urgent", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#E85353\"/>\n" +
              "</svg>\n", status: false},
        {name: "High", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#FF9035\"/>\n" +
              "</svg>\n", status: false},
        {name: "Normal", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#0FA54E\"/>\n" +
              "</svg>\n", status: false},
        {name: "Low", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
              "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#9AA3B0\"/>\n" +
              "</svg>\n", status: false},
        {name: "No priority", svg: "", status: true},
      ],
      selectedPriority: [

      ],
      createdDate: [],
      dueDate: [],
      optionTerms: [
        {name: "Burning"},
        {name: "Deadline soon"},
        {name: "No terms"},
      ],
      selectedTerms: [],
      setCookiesTime: false,
    }
  },
  methods: {
    ...mapActions(["axiosGetEmployees", "axiosGetPriorityTask", "axiosGetTasksForFilter"]),
    ...mapMutations(["hideLoad", "showLoad", "cancelPostTaskFilter", "setFilterContActivity", "conversionForTasks", "sortTasksByDepartmentsReverse", "sortTasksByDepartments", "sortTaskByDeadlineReverse", "sortTaskByDeadline",
    "sortTaskByPriorityReverse", "sortTaskByPriority", "sortTaskByStatusReverse", "sortTaskByNameReverse", "sortTaskByName", "sortTaskByStatus"]),
    getOptionsTaskType() {
      let array = [];
      if(this.getPermissionsByType("can_view_ship_task"))
        array.push({name: "Vessel Search", value: "SHIP_SEARCH_WITH_CARGO"})
      if(this.getPermissionsByType("can_view_manual_task"))
        array.push({name: "Manually", value: "MANUAL"})
      array.push({name: "Manually with contract", value: "MANUAL_WITH_CONTRACT"})
      if(this.getPermissionsByType("can_view_cargo_task"))
        array.push({name: "Search Cargo for our Vessel", value: "CARGO_SEARCH_WITH_SHIP"})
      return array
    },
    deleteTagWhichUsed(tag) {
      this.$refs.modalDeleteTag.openModal(tag);
    },
    doneStatusChange(bool) {
      if(bool) {
        let k = 0;
        for(let i=0; i<this.selectedStatus.length; i++) {
          if(this.selectedStatus[i].value === "Done") {
            k = i;
          }
        }
        this.selectedStatus.splice(k,1);
      } else {
        this.selectedStatus.push({name: "Done", value: "Done"})
      }
      this.convertToFormAxiosFilter();
    },
    showMeTask() {
      this.assigneeSelect = [this.getLoginData.data.user]
      this.convertToFormAxiosFilter();
      // this.$refs.assigneeDrop.showMeTask(id)
    },
    clearAll() {
      this.$emit('clearAll')
      this.clearAllActive = true;
      this.clearTaskType();
      this.clearStatus();
      this.clearPriority();
      this.clearDates();
      this.clearTerms();
      this.clearTaskAssignee();
      this.clearTags();
      this.clearTaskCreators();
      this.clearAllActive = false;
      // this.showLoad()
      this.convertToFormAxiosFilter();
    },
    clearTaskType() {
      this.selectedTaskType.splice(0, this.selectedTaskType.length);
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    clearStatus() {
      this.selectedStatus.splice(0, this.selectedStatus.length);
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    clearPriority() {
      this.selectedPriority.splice(0, this.selectedPriority.length);
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    clearDates() {
      this.createdDate = ["", ""];
      this.dueDate = ["", ""];
      this.dateClearShow = false;
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    clearTerms() {
      this.selectedTerms.splice(0, this.selectedTerms.length);
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    clearTags() {
      this.$refs.tagDrop.clearData();
      this.showClearTags = false;
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    clearTaskAssignee() {
      this.$emit('clear-assignee')
      this.assigneeSelect= [];
      this.showClearTaskAssignee = false;
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    clearTaskCreators() {
      this.creatorSelect = [];

      this.showClearTaskCreator = false;
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    changeShowAssignee(data) {
      this.showClearTaskAssignee = data;
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    changeShowCreate(data) {
      this.showClearTaskCreator = data;
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    changeShowClearTags(data) {
      this.showClearTags = data;
      if(!this.clearAllActive)
      this.convertToFormAxiosFilter();
    },
    checkConvertToFormAxiosFilterAssign(array, bool){
      if(bool) {
        this.convertToFormAxiosFilter();
      }
    },
    convertToFormAxiosFilter() {
      this.countActiveFilter = 0;
      let priority= [];
      for(let i=0; i<this.selectedPriority.length; i++) {
        priority.push(this.selectedPriority[i].id);
      }
      if(this.selectedPriority.length) {
        this.countActiveFilter++
      }
      // this.countActiveFilter += priority.length;
      let type = [];
      for(let i=0; i<this.selectedTaskType.length; i++) {
        let pushBool = true
        if(!this.getPermissionsByType("can_view_cargo_task") && this.selectedTaskType[i].value === 'CARGO_SEARCH_WITH_SHIP')
          pushBool = false
        if(!this.getPermissionsByType("can_view_ship_task") && this.selectedTaskType[i].value === 'SHIP_SEARCH_WITH_CARGO')
          pushBool = false
        if(!this.getPermissionsByType("can_view_manual_task") && this.selectedTaskType[i].value === 'MANUAL')
          pushBool = false
        if(pushBool) {
          type.push(this.selectedTaskType[i].value)
        }
      }
      if( type.length) {
        this.countActiveFilter++
      }
      // this.countActiveFilter += type.length;
      let status = [];
      if(this.selectedStatus.length === 0) {
        this.selectedStatus = [
          {name: "Open", value: "Open"},
          {name: "In Progress", value: "In progress"},
          {name: "Done", value: "Done"},
        ]
      }
      for(let i=0; i<this.selectedStatus.length; i++) {
        status.push(this.selectedStatus[i].value);
      }
      if(status.length < 3) {
        this.countActiveFilter++;
      }
      // this.countActiveFilter += status.length;
      let tags = this.$refs.tagDrop.getTagsForAxios();
      if(tags.length) {
        this.countActiveFilter ++;
      }
      let assignee = this.assigneeSelect.map(a=> {
        return a.id
      });
      // console.log("assignee");
      // console.log(assignee)
      if(assignee.length > 0) {
        if(assignee.length === 1) {
          if(assignee.indexOf(this.getLoginData.data.user.id)>=0) {
            this.$emit("changeAssigneeMe");
          }
        } else {
          this.$emit("changeAssignee");
        }
      }
      if(assignee.indexOf(this.getLoginData.data.user.id) === -1) {
        this.$emit('clear-assignee')
      }
      if(assignee.length) {
        this.countActiveFilter++;
      }
      let creator = this.creatorSelect.map(a=> {
        return a.id
      });
      if(creator.length) {
        this.countActiveFilter++;
      }
      let dateCurrent = [];

      // console.log(this.dueDate);
      // console.log(this.createdDate);
      let formDateCurrent = {
        date_from: (this.dueDate[0] !== null && this.dueDate[0] !== undefined && (""+this.dueDate[0]).length !== 0) ? moment(this.dueDate[0]).format("YYYY-MM-DD 00:00:00") : "",
        date_to: (this.dueDate[1] !== null && this.dueDate[1] !== undefined && (""+this.dueDate[1]).length !== 0) ? moment(this.dueDate[1]).format("YYYY-MM-DD 23:59:00") : "",
      };
      let dateCreate = [];
      let formDateCreate = {
        date_from: (this.createdDate[0] !== null && this.createdDate[0] !== undefined && (""+this.createdDate[0]).length !== 0) ? moment(this.createdDate[0]).format("YYYY-MM-DD 00:00:00") : "",
        date_to: (this.createdDate[1] !== null && this.createdDate[1] !== undefined && (""+this.createdDate[1]).length !== 0) ? moment(this.createdDate[1]).format("YYYY-MM-DD 23:59:00") : ""
      }
      // console.log(this.createdDate)
      // console.log(formDateCreate)
      if(((""+formDateCreate.date_from).length !== 0 && formDateCreate.date_from !== null) || ((""+formDateCurrent.date_from).length !== 0 && formDateCurrent.date_from !== null)) {
        dateCreate.push(formDateCreate);
        this.countActiveFilter += 1;
      }
      if(dateCreate.length !==0 || dateCurrent.length !==0) {
        this.dateClearShow = true;
      } else {
        this.dateClearShow = false;
      }
      this.setFilterContActivity(this.countActiveFilter);
      this.cancelPostTaskFilter();


      if(this.setCookiesTime) {
        localStorage.removeItem(this.$route.path+"selectedTaskType")
        localStorage.removeItem(this.$route.path+"selectedStatus")
        localStorage.removeItem(this.$route.path+"selectedPriority")
        localStorage.removeItem(this.$route.path+"createdDate")
        localStorage.removeItem(this.$route.path+"dueDate")
        localStorage.removeItem(this.$route.path+"selectedTerms")
        localStorage.removeItem(this.$route.path+"assigneeSelect")
        localStorage.removeItem(this.$route.path+"creatorSelect")
        localStorage.removeItem(this.$route.path+"tagsSelect")




        localStorage.setItem(this.$route.path+"selectedTaskType", JSON.stringify(this.selectedTaskType));
        localStorage.setItem(this.$route.path+"selectedStatus", JSON.stringify(this.selectedStatus));
        localStorage.setItem(this.$route.path+"selectedPriority", JSON.stringify(this.selectedPriority));
        localStorage.setItem(this.$route.path+"createdDate", JSON.stringify(this.createdDate));
        localStorage.setItem(this.$route.path+"dueDate", JSON.stringify(this.dueDate));
        localStorage.setItem(this.$route.path+"selectedTerms", JSON.stringify(this.selectedTerms));

        localStorage.setItem(this.$route.path+"assigneeSelect", JSON.stringify(this.assigneeSelect));
        localStorage.setItem(this.$route.path+"creatorSelect", JSON.stringify(this.creatorSelect));
        localStorage.setItem(this.$route.path+"tagsSelect", JSON.stringify(tags));


      }

      // console.log("JSON.parse(this.$cookies.get(\"selectedPriority\"));")
      // console.log(JSON.parse(this.$cookies.get("selectedPriority")));

      // this.showLoad()
      if(!this.getEmployees) {
        this.axiosGetEmployees().then(()=> {
          this.axiosGetTasksForFilter({is_archived: this.$route.path.indexOf('archive') >= 0, priority: priority, task_type: type, status: status, tags:tags, executors: assignee, creators: creator, execute_date: dateCurrent, create_date: dateCreate, ordering: this.getSortTaskBy, name: this.searchName}).then(async ()=> {
            this.conversionForTasks({tags: this.getAllTag, executors: this.getEmployees.data.results});
            this.hideLoad()
          });
        })

      } else {
        this.axiosGetTasksForFilter({is_archived: this.$route.path.indexOf('archive') >= 0 ,priority: priority, task_type: type, status: status, tags:tags, executors: assignee, creators: creator, execute_date: dateCurrent, create_date: dateCreate, ordering: this.getSortTaskBy, name: this.searchName}).then(async ()=> {
          this.conversionForTasks({tags: this.getAllTag, executors: this.getEmployees.data.results});
          this.hideLoad()
        });
      }

      if(this.selectedStatus.filter(v => v.value === "Done").length > 0) {
        this.$emit("doneStatusTrue");
      } else {
        this.$emit("doneStatusFalse");
      }
    },
    setCollapse() {
      localStorage.removeItem(this.$route.path+"showCollapseCopy")
      localStorage.setItem(this.$route.path+"showCollapseCopy", this.showCollapseCopy)
      localStorage.removeItem(this.$route.path+"showCollapse1Copy")
      localStorage.setItem(this.$route.path+"showCollapse1Copy", this.showCollapse1Copy)
      localStorage.removeItem(this.$route.path+"showCollapse2Copy")
      localStorage.setItem(this.$route.path+"showCollapse2Copy", this.showCollapse2Copy)
      localStorage.removeItem(this.$route.path+"showCollapse3Copy")
      localStorage.setItem(this.$route.path+"showCollapse3Copy", this.showCollapse3Copy)
      localStorage.removeItem(this.$route.path+"showCollapse4Copy")
      localStorage.setItem(this.$route.path+"showCollapse4Copy", this.showCollapse4Copy)
      localStorage.removeItem(this.$route.path+"showCollapse5Copy")
      localStorage.setItem(this.$route.path+"showCollapse5Copy", this.showCollapse5Copy)
      localStorage.removeItem(this.$route.path+"showCollapse6Copy")
      localStorage.setItem(this.$route.path+"showCollapse6Copy", this.showCollapse6Copy)
      localStorage.removeItem(this.$route.path+"showCollapse7Copy")
      localStorage.setItem(this.$route.path+"showCollapse7Copy", this.showCollapse7Copy)
    },
  },

  mounted() {
    this.optionTaskType= this.getOptionsTaskType()

        this.noAxios = true;

    window.onbeforeunload = closingCode;
    let vm = this;
    function closingCode(){
      vm.setCollapse();
      return null;
    }
    if(localStorage.getItem(this.$route.path+"showCollapseCopy") !== null && localStorage.getItem(this.$route.path+"showCollapseCopy") !== undefined) {
      this.showCollapse = ""+localStorage.getItem(this.$route.path+"showCollapseCopy") === "true";
      this.showCollapseCopy = ""+localStorage.getItem(this.$route.path+"showCollapseCopy") === "true";
      // console.log("this.showCollapse");
      // console.log(this.showCollapse);
      // console.log(this.showCollapseCopy);
    }
    if(localStorage.getItem(this.$route.path+"showCollapse1Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse1Copy") !== undefined) {
      this.showCollapse1 = ""+localStorage.getItem(this.$route.path+"showCollapse1Copy") === "true";
      this.showCollapse1Copy = ""+localStorage.getItem(this.$route.path+"showCollapse1Copy") === "true";
    }
    if(localStorage.getItem(this.$route.path+"showCollapse2Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse2Copy") !== undefined) {
      this.showCollapse2 = ""+localStorage.getItem(this.$route.path+"showCollapse2Copy") === "true";
      this.showCollapse2Copy = ""+localStorage.getItem(this.$route.path+"showCollapse2Copy") === "true";
    }
    if(localStorage.getItem(this.$route.path+"showCollapse3Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse3Copy") !== undefined) {
      this.showCollapse3 = ""+localStorage.getItem(this.$route.path+"showCollapse3Copy") === "true";
      this.showCollapse3Copy = ""+localStorage.getItem(this.$route.path+"showCollapse3Copy") === "true";
    }
    if(localStorage.getItem(this.$route.path+"showCollapse4Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse4Copy") !== undefined) {
      this.showCollapse4 = ""+localStorage.getItem(this.$route.path+"showCollapse4Copy") === "true";
      this.showCollapse4Copy = ""+localStorage.getItem(this.$route.path+"showCollapse4Copy") === "true";
    }
    if(localStorage.getItem(this.$route.path+"showCollapse5Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse5Copy") !== undefined) {
      this.showCollapse5 = ""+localStorage.getItem(this.$route.path+"showCollapse5Copy") === "true";
      this.showCollapse5Copy = ""+localStorage.getItem(this.$route.path+"showCollapse5Copy") === "true";
    }
    if(localStorage.getItem(this.$route.path+"showCollapse6Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse6Copy") !== undefined) {
      this.showCollapse6 = ""+localStorage.getItem(this.$route.path+"showCollapse6Copy") === "true";
      this.showCollapse6Copy = ""+localStorage.getItem(this.$route.path+"showCollapse6Copy") === "true";
    }
    if(localStorage.getItem(this.$route.path+"showCollapse7Copy") !== null && localStorage.getItem(this.$route.path+"showCollapse7Copy") !== undefined) {
      this.showCollapse7 = ""+localStorage.getItem(this.$route.path+"showCollapse7Copy") === "true";
      this.showCollapse7Copy = ""+localStorage.getItem(this.$route.path+"showCollapse7Copy") === "true";
    }


    this.setCookiesTime = false;
    if(localStorage.getItem(this.$route.path+"selectedTaskType") !== null && localStorage.getItem(this.$route.path+"selectedTaskType") !== undefined) {
      this.selectedTaskType = JSON.parse(localStorage.getItem(this.$route.path+"selectedTaskType"));
    }
    if(localStorage.getItem(this.$route.path+"selectedStatus") !== null && localStorage.getItem(this.$route.path+"selectedStatus") !== undefined) {
      this.selectedStatus = JSON.parse(localStorage.getItem(this.$route.path+"selectedStatus"));
    }
    if(localStorage.getItem(this.$route.path+"createdDate") !== null && localStorage.getItem(this.$route.path+"createdDate") !== undefined) {
      if(JSON.parse(localStorage.getItem(this.$route.path+"dueDate")).length === 2) {
          if(""+JSON.parse(localStorage.getItem(this.$route.path+"createdDate"))[0] !== 'null' && (""+JSON.parse(localStorage.getItem(this.$route.path+"createdDate"))[0]).length !== 0) {
          this.createdDate = [new Date(JSON.parse(localStorage.getItem(this.$route.path+"createdDate"))[0]), new Date(JSON.parse(localStorage.getItem(this.$route.path+"createdDate"))[1])];
        }
      }

    }
    if(localStorage.getItem(this.$route.path+"dueDate") !== null && localStorage.getItem(this.$route.path+"dueDate") !== undefined) {
      if(JSON.parse(localStorage.getItem(this.$route.path+"dueDate")).length === 2) {
        if(""+JSON.parse(localStorage.getItem(this.$route.path+"dueDate"))[0] !== 'null' && (""+JSON.parse(localStorage.getItem(this.$route.path+"dueDate"))[0]).length !== 0) {
          this.dueDate = [new Date(JSON.parse(localStorage.getItem(this.$route.path+"dueDate"))[0]), new Date(JSON.parse(localStorage.getItem(this.$route.path+"dueDate"))[1])];
        }
      }

    }
    if(localStorage.getItem(this.$route.path+"selectedTerms") !== null && localStorage.getItem(this.$route.path+"selectedTerms") !== undefined) {
        this.selectedTerms = JSON.parse(localStorage.getItem(this.$route.path+"selectedTerms"));
    }
    if(localStorage.getItem(this.$route.path+"assigneeSelect") !== null && localStorage.getItem(this.$route.path+"assigneeSelect") !== undefined) {
      let array = JSON.parse(localStorage.getItem(this.$route.path+"assigneeSelect"));
      // array = array.map(a=> {
      //   return {id: a}
      // });
      this.assigneeSelect = array
      // this.$refs.assigneeDrop.setExecutors(array,true);
    }
    if(localStorage.getItem(this.$route.path+"creatorSelect") !== null && localStorage.getItem(this.$route.path+"creatorSelect") !== undefined) {
      let array = JSON.parse(localStorage.getItem(this.$route.path+"creatorSelect"));
      // array = array.map(a=> {
      //   return {id: a}
      // });
      this.creatorSelect = array;
      // this.$refs.creatorDrop.setExecutors(array,true);
    }
    if(localStorage.getItem(this.$route.path+"tagsSelect") !== null && localStorage.getItem(this.$route.path+"tagsSelect") !== undefined) {
      let array = JSON.parse(localStorage.getItem(this.$route.path+"tagsSelect"));
      array = array.map(a=> {
        return {id: a}
      });
      let form = {
        tags: array
      }
      this.$refs.tagDrop.setTags(form);
    }





    // console.log("287364782364786238746872364872")
    // console.log(JSON.parse(this.$cookies.get("dueDate")))

    // this.showLoad()

    if(this.getAllPriority.length === 0) {
      this.axiosGetPriorityTask().then(()=> {
        let array = [];
        for(let i=0; i<this.getAllPriority.length; i++) {
          for(let j=0; j<this.optionPriority.length; j++) {
            if((this.getAllPriority[i].type).toLowerCase() === (this.optionPriority[j].name).toLowerCase()) {
              let form = {
                name: this.getAllPriority[i].type,
                id: this.getAllPriority[i].id,
                svg: this.optionPriority[j].svg,
                status: false,
              }
              array.push(form);
            }
          }
        }
        this.optionPriority = JSON.parse(JSON.stringify(array));
        if(localStorage.getItem(this.$route.path+"selectedPriority") !== null && localStorage.getItem(this.$route.path+"selectedPriority") !== undefined) {
          this.selectedPriority = JSON.parse(localStorage.getItem(this.$route.path+"selectedPriority"));

        } else {
          this.selectedPriority = JSON.parse(JSON.stringify(array));
        }
        this.setCookiesTime = true;

        this.convertToFormAxiosFilter();
      })

    } else {
      let array = [];
      for(let i=0; i<this.getAllPriority.length; i++) {
        for(let j=0; j<this.optionPriority.length; j++) {
          if((this.getAllPriority[i].type).toLowerCase() === (this.optionPriority[j].name).toLowerCase()) {
            let form = {
              name: this.getAllPriority[i].type,
              id: this.getAllPriority[i].id,
              svg: this.optionPriority[j].svg,
              status: false,
            }
            array.push(form);
          }
        }
      }
      this.optionPriority = JSON.parse(JSON.stringify(array));
      if(localStorage.getItem(this.$route.path+"selectedPriority") !== null && localStorage.getItem(this.$route.path+"selectedPriority") !== undefined) {
        this.selectedPriority = JSON.parse(localStorage.getItem(this.$route.path+"selectedPriority"));
      } else {
        this.selectedPriority = JSON.parse(JSON.stringify(array));
      }
      this.setCookiesTime = true;
      this.convertToFormAxiosFilter();
    }
  }
}
</script>

<style scoped lang="scss">
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/checkbox";
  @import "task-list-filter";
</style>