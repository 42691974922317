<template>
  <div class="wrap">
    <div class="top-panel d-flex align-items-center box-border">
      <p class="fz17 text-dark">Filter</p>
      <button class="blue-button-text fz14 fw500 ml-auto" v-on:click="hideRegion = !hideRegion">{{hideRegion ? 'Show Regions' : 'Hide Regions'}}</button>
    </div>
    <location-filter-map-region v-show="!hideRegion" v-model="selectedRegion" class="w100Proc"></location-filter-map-region>
    <div v-show="hideRegion" class="w100Proc border-bottom p-page box-borde d-flex flex-wrap align-items-center">
      <p class="fz13 fw500 text-dark mr6">Zones selected: {{selectedRegion.length}}</p>
      <div class="selected-block ml2 d-flex align-items-center fz13 text-dark position-relative" v-for="(name, i) in selectedRegion" :key="'sds'+i">{{name}}
        <svg v-on:click="selectedRegion = selectedRegion.filter(a=> a !== name)" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.53549 8.59341L6.94283 6.00076L9.53558 3.40801C9.79573 3.14785 9.79573 2.72542 9.53549 2.46518C9.27533 2.20502 8.8529 2.20502 8.59274 2.46518L6 5.05792L3.40726 2.46518C3.1471 2.20502 2.72467 2.20502 2.46451 2.46518C2.20427 2.72542 2.20427 3.14785 2.46442 3.40801L5.05717 6.00076L2.46451 8.59341C2.20427 8.85366 2.20427 9.27609 2.46442 9.53625C2.72467 9.79649 3.1471 9.79649 3.40734 9.53625L6 6.94359L8.59266 9.53625C8.8529 9.79649 9.27533 9.79649 9.53558 9.53625C9.79573 9.27609 9.79573 8.85366 9.53549 8.59341Z" fill="#2C2C2C"/>
        </svg>
      </div>
    </div>
    <div class="filters w100Proc d-flex box-border">
      <div class="search-block">
        <p class="fz13 text-dark fw500">Search Vessel</p>
        <div class="position-relative d-flex align-items-center mt8">

<!--          <input type="text" class="h32 pl36 search" placeholder="Enter Vessel Name" v-model="search">-->
          <location-search-ship ref="search" v-model="search"></location-search-ship>
        </div>
      </div>
      <div class="pl16 box-border dwt-block">
        <p class="fz13 text-dark fw500">DWT</p>
        <div class="position-relative d-flex align-items-center mt8 justify-between">
          <location-select-type @clear="()=>{dwt_from = ''; dwt_to = ''}" v-model="typeShip"></location-select-type>
          <div class="d-flex align-items-center position-relative">
            <input type="text" class="h32 pr36" placeholder="From" v-model="dwt_from" @input="dwt_from = checkNum(dwt_from)" @focusout="checkMinMax">
            <p class="text-gray fz13 position-absolute r8">MT</p>
          </div>
          <div class="d-flex align-items-center position-relative">
            <input type="text" class="h32 pr36" placeholder="To" v-model="dwt_to" @input="dwt_to = checkNum(dwt_to)" @focusout="checkMinMax">
            <p class="text-gray fz13 position-absolute r8">MT</p>
          </div>

        </div>
      </div>
      <div class="pl16 box-border built-block">
        <p class="fz13 text-dark fw500">Built</p>
        <div class="position-relative d-flex align-items-center mt8 justify-between">
          <input type="text" class="h32" placeholder="Max Age" v-model="built_to" @input="built_to = checkNum(built_to)">
          <!--            <input type="text" class="h32" placeholder="From" v-model="built_from" @input="built_from = checkNum(built_from)" @focusout="checkMinMax">-->
<!--            <input type="text" class="h32" placeholder="To" v-model="built_to" @input="built_to = checkNum(built_to)" @focusout="checkMinMax">-->
        </div>
      </div>
      <div class="pl16 box-border laycan-block">
        <p class="fz13 text-dark fw500">Laycan</p>
        <div class="position-relative d-flex align-items-center mt8 justify-between">
          <date-picker
              value-type="date"
              placeholder="From date"
              v-model="laycan_date_from"
              format="DD MMM YYYY"
              class="datepick h32"
          ></date-picker>
          <date-picker
              value-type="date"
              placeholder="To Date"
              v-model="laycan_date_to"
              format="DD MMM YYYY"
              class="datepick h32"
          ></date-picker>
        </div>
      </div>
      <div class="pl16 box-border update-block">
        <p class="fz13 text-dark fw500">Update Date</p>
        <div class="position-relative d-flex align-items-center mt8 justify-between">
          <date-picker
              value-type="date"
              placeholder="From date"
              v-model="update_date_from"
              format="DD MMM YYYY"
              class="datepick h32"
          ></date-picker>
          <date-picker
              value-type="date"
              placeholder="To Date"
              v-model="update_date_to"
              format="DD MMM YYYY"
              class="datepick h32"
          ></date-picker>
        </div>
      </div>
    </div>
    <div class="bottom-block p8-16 box-border w100Proc d-flex align-items-center border-top">
      <button class="button-text-red ml-auto fz14" v-on:click="clearFilter">Clear Filter</button>
      <button class="blue-button w250 ml64 fz14" v-on:click="applyFilter">Apply Filter</button>
    </div>
  </div>
</template>

<script>
import LocationFilterMapRegion from "@/components/location/location-filter-map-region";
import LocationSelectType from "@/components/location/location-select-type";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import {mapActions} from "vuex";
import LocationSearchShip from "@/components/location/location-search-ship";
export default {
  name: "location-filter",
  components: {LocationSearchShip, LocationSelectType, LocationFilterMapRegion, DatePicker},
  computed: {
    getIdsRegion() {
      let regions = [
        {name: 'NOPAC', id: 13},
        {name: 'Far East', id: 12},
        {name: 'ECSA', id: 11},
        {name: 'USG-NCSA', id: 10},
        {name: 'USEC', id: 9},
        {name: 'WC America', id: 8},
        {name: 'AUSSIE', id: 7},
        {name: 'SE Asia', id: 6},
        {name: 'Red Sea', id: 5},
        {name: 'Indian Ocean', id: 4},
        {name: 'WAFR', id: 3},
        {name: 'Baltic - Continent', id: 2},
        {name: 'Med-Bsea', id: 1},
      ]
      let IDS = []
      this.selectedRegion.forEach(item=> {
        let reg = regions.find(a=> a.name === item)
        IDS.push(reg.id)
      })
      return IDS
    },
  },
  watch: {
    typeShip: function () {
      if(this.typeShip.length !== 0) {
        let name = this.typeShip[0]
        let item = this.listTypesShip.find(a=> a.name === name);
        if(item) {
          this.dwt_from = item.from;
          this.dwt_to = item.to;
        }
      }
    },
  },
  data() {
    return {
      listTypesShip: [
        {name: 'Capesize', from: 100000, to: null},
        {name: 'Panamax', from: 67000, to: 99999},
        {name: 'Handymax', from: 40000, to: 66999},
        {name: 'Hendysize', from: 10000, to: 39999},

      ],
      hideRegion: false,
      selectedRegion: [],
      typeShip: [],
      laycan_date_from: null,
      laycan_date_to: null,
      search: '',
      update_date_from: null,
      update_date_to: null,
      built_to: '',
      built_from: '',
      dwt_from: '',
      dwt_to: '',
      ship: null
    }
  },
  methods: {
    ...mapActions(['axiosGetLocations', 'axiosGetTest']),
    clearFilter() {
      this.selectedRegion = []
      this.typeShip = []
      this.laycan_date_from = null
      this.laycan_date_to = null
      this.search = ''
      this.update_date_from = null
      this.update_date_to = null
      this.built_to = ''
      this.built_from = ''
      this.dwt_from = ''
      this.dwt_to = ''
      this.$refs.search.clear()
      // this.applyFilter()
    },
    checkMinMax() {
      // if(this.built_to < this.built_from && ((this.built_to !== null && this.built_from !== null) && ((''+this.built_to).length > 0 && (''+this.built_from).length > 0))) {
      //   let count = this.built_to
      //   this.built_to = this.built_from
      //   this.built_from = count
      // }
      if(this.dwt_to < this.dwt_from && ((this.dwt_to !== null && this.dwt_from !== null) && ((''+this.dwt_to).length > 0 && (''+this.dwt_from).length > 0))) {
        let count = this.dwt_to
        this.dwt_to = this.dwt_from
        this.dwt_from = count
      }
    },
    checkNum(num) {
      // if(num.indexOf(".")>=0) {
      //   let num1 = num.substring(0, num.indexOf("."));
      //   let num2 = num.substring(num.indexOf(".") + 1, num.length);
      //
      //   num1 = num1.replace(/[^0-9]/g, "");
      //   num2 = num2.replace(/[^0-9]/g, "");
      //
      //   if(num2.length > 2) {
      //     num2 = num2.substring(0, 2);
      //   }
      //   num = num1 + "." + num2;
      // } else {
        num = num.replace(/[^0-9]/g, "");
      // }
      return num;
    },
    async applyFilter() {
      // this.axiosGetTest()
      localStorage.setItem('filterLocale', JSON.stringify({
        update_date_from: this.update_date_from,
        update_date_to: this.update_date_to,
        laycan_date_from: this.laycan_date_from,
        laycan_date_to: this.laycan_date_to,
        built_from: this.built_from,
        built_to: this.built_to,
        dwt_from: this.dwt_from,
        dwt_to: this.dwt_to,
        search: this.search,
        selectedRegion: this.selectedRegion,
        typeShip: this.typeShip
      }))
      let form = {
        updated_at_after : this.update_date_from ? moment(this.update_date_from).format('YYYY-MM-DD 00:00:00') : null,
        updated_at_before: this.update_date_to ? moment(this.update_date_to).format('YYYY-MM-DD 23:59:59') : null,
        date_from: this.laycan_date_from ? moment(this.laycan_date_from).format('YYYY-MM-DD') : null,
        date_to: this.laycan_date_to ? moment(this.laycan_date_to).format('YYYY-MM-DD') : null,
        // build_min: this.built_to ? (Number(moment().format('YYYY')) - this.built_to)  : 0,
        // build_max: this.built_to ? (moment().format('YYYY')) : 900000,
        // dwt_min: this.dwt_from ? this.dwt_from : 0,
        // dwt_max: this.dwt_to ? this.dwt_to : 99999999,
        search: this.search ? this.search : '',
        zone: this.getIdsRegion,
      }
      if(this.built_to) {
        form['build_min'] = (Number(moment().format('YYYY')) - this.built_to)
        form['build_max'] = (moment().format('YYYY'))
      }
      if(this.dwt_from) {
        form['dwt_min'] = this.dwt_from
      }
      if(this.dwt_to) {
        form['dwt_max'] = this.dwt_to
      }
      let propsBool = this.update_date_from || this.update_date_to || this.laycan_date_from || this.laycan_date_to || this.built_from || this.built_to || this.dwt_from || this.dwt_to || this.search || this.getIdsRegion.length !== 0
      this.$emit('activeFilter', propsBool)
      console.log(form)
      await this.axiosGetLocations(form)
    },
  },
  mounted() {
    if(localStorage.getItem('filterLocale') !== null && localStorage.getItem('filterLocale') !== undefined && localStorage.getItem('filterLocale')) {
      let filter = JSON.parse(localStorage.getItem('filterLocale'))
      console.log('filter', filter)
      this.update_date_from= filter.update_date_from ? new Date(filter.update_date_from) : null
          this.update_date_to= filter.update_date_to ? new Date(filter.update_date_to) : null
          this.laycan_date_from= filter.laycan_date_from ? new Date(filter.laycan_date_from) : null
          this.laycan_date_to= filter.laycan_date_to ? new Date(filter.laycan_date_to) : null
          this.built_from= filter.built_from
          this.built_to= filter.built_to
          this.dwt_from= filter.dwt_from
      this.dwt_to = filter.dwt_to
          this.search= filter.search
          this.typeShip = filter.typeShip
          this.selectedRegion= filter.selectedRegion
    }

    this.applyFilter()
  }
}
</script>

<style scoped lang="scss">
.wrap {
  width: 100%;
  background-color: var(--color-bgc-page-white);
  border: 1px solid var(--color-border);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.top-panel {
  height: 40px;
  border-bottom: 1px solid var(--color-border);
  padding: 0 8px 0 16px;
}
.p-page {
  padding: 6px 8px 6px 16px;
}
.filters {
  padding: 16px 26px 24px 16px;
}
.datepick {
  /deep/ {
    .mx-input-wrapper {
      &:hover {
        input {
          //background-image: none !important;
        }
      }
    }
    input {
      height: 32px;
      padding-right: 36px !important;
      padding-left: 12px !important;
      background-color: var(--color-input);
      background-position: calc(100% - 4px) center !important;
    }
    i {
      display: none !important;
    }
  }
}
.search-block {
  width: 20%;
}
.dwt-block {
  width: 26.7%;
  &>div {
    &>*:nth-child(1) {
      width: 42.2%;
    }
    &>*:nth-child(2) {
      width: calc(28.9% - 4px);
    }
    &>*:nth-child(3) {
      width: calc(28.9% - 4px);
    }
  }
}
.built-block {
  width: 12.5%;
  //&>div {
  //  &>*:nth-child(1) {
  //    width: calc(50% - 2px);
  //  }
  //  &>*:nth-child(2) {
  //    width: calc(50% - 2px);
  //  }
  //}
}
.laycan-block {
  width: 20.4%;
  &>div {
    &>*:nth-child(1) {
      width: calc(50% - 2px);
    }
    &>*:nth-child(2) {
      width: calc(50% - 2px);
    }
  }
}
.update-block {
  width: 20.4%;
  &>div {
    &>*:nth-child(1) {
      width: calc(50% - 2px);
    }
    &>*:nth-child(2) {
      width: calc(50% - 2px);
    }
  }
}
.selected-block {
  background-color: var(--color-light-green1);
  border-radius: 20px;
  padding: 2px 22px 2px 8px;
  box-sizing: border-box;
  svg {
    cursor: pointer;
    position: absolute;
    right: 8px;
  }
}
.search {
  width: 100%;
  /deep/ {
    .multiselect__tags {
      min-height: 32px;
      height: 32px;
      padding-top: 5px;
    }
    input {
      font-size: 14px;
      line-height: 17px;
      padding-left: 38px;
    }
    .multiselect__placeholder, .multiselect__single {
      padding-left: 38px;
    }
    .multiselect__element {
      font-size: 13px;
      line-height: 16px;
    }
    .multiselect__option {
      position: relative;
      padding-left: 38px;
    }
  }
}



</style>