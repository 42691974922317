<template>
  <h1 class="fw500 fz18 text-dark">{{title}}</h1>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Edit SOA table'
    }
  }

}
</script>

<style>

</style>