<template>
  <div class="wrap-page overflow-y-auto overflow-x-hidden">
    <div class="top-panel">
      <router-link class="fz16 fw500 text-gray" :class="{'active': $route.path.indexOf('location') >= 0}" :to="{name: 'Location'}">Vessel Location</router-link>
      <router-link class="fz16 fw500 text-gray" :class="{'active': $route.path.indexOf('')}" :to="{name: 'Location Emails Vessel'}">Vessel Emails</router-link>
      <router-link class="fz16 fw500 text-gray" :class="{'active': $route.path.indexOf('')}" :to="{name: 'Location Emails Vessel All'}">All Emails</router-link>

    </div>
    <div class="content">
      <location-filter class="" @activeFilter="(e)=> {activeFilter=e}"></location-filter>
      <location-list @clickDelete="clickDelete = true" @clickSend="sendMessageModal = true" ref="list" @changeSelected="(e)=> {selectedList = e}" class="mt16" v-if="locationList.length"></location-list>
      <div class="not-found w100Proc d-flex flex-column justify-center align-items-center pb38 position-relative" v-if="locationList.length === 0 && activeFilter">
        <app-loader :is-local="true" v-if="load" class="loader-list"></app-loader>
        <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M158.5 36C136.685 36 119 53.6848 119 75.5C119 97.3152 136.685 115 158.5 115C170.662 115 181.54 109.505 188.787 100.858C194.538 93.9961 198 85.1536 198 75.5C198 53.6848 180.315 36 158.5 36ZM117 75.5C117 52.5802 135.58 34 158.5 34C181.42 34 200 52.5802 200 75.5C200 85.3113 196.594 94.3295 190.901 101.433L214.702 125.234L214.707 125.239C215.098 125.63 215.098 126.263 214.707 126.653C214.317 127.044 213.683 127.044 213.293 126.653L213.288 126.648L189.609 102.969C182.006 111.572 170.886 117 158.5 117C135.58 117 117 98.4198 117 75.5Z" fill="#DEE3EA"/>
          <path d="M40.4095 81.467C40.6053 81.3598 40.8517 81.2176 41.0197 81.068C42.6159 79.6466 44.7272 79.6414 46.3293 81.068C47.3861 82.009 48.7519 82.009 49.8086 81.068C50.5928 80.3698 51.5107 80.0007 52.4634 80.0007C53.4161 80.0007 54.3341 80.3698 55.1182 81.068C56.1751 82.009 57.5408 82.009 58.5976 81.068C60.1938 79.6467 62.305 79.6414 63.9071 81.068C64.0597 81.2038 64.2784 81.3444 64.5757 81.498C64.9426 81.6875 65.102 82.1722 64.9318 82.5806C64.7615 82.989 64.3261 83.1665 63.9591 82.977C63.5482 82.7648 63.2409 82.5628 62.992 82.3411C61.9352 81.4001 60.5695 81.4 59.5126 82.3411C57.9164 83.7625 55.8052 83.7677 54.2031 82.3411C53.1463 81.4001 51.7806 81.4 50.7237 82.3411C49.1274 83.7625 47.0162 83.7677 45.4141 82.3411C44.3573 81.4001 42.9916 81.4 41.9347 82.3411C41.6575 82.588 41.3348 82.7774 41.0557 82.9302C40.6927 83.1289 40.2537 82.9623 40.0753 82.5583C39.8969 82.1542 40.0464 81.6657 40.4095 81.467Z" fill="#DEE3EA"/>
          <path d="M48.4095 88.467C48.6053 88.3598 48.8517 88.2176 49.0197 88.068C50.6159 86.6466 52.7272 86.6414 54.3293 88.068C55.3861 89.009 56.7519 89.009 57.8086 88.068C58.5928 87.3698 59.5107 87.0007 60.4634 87.0007C61.4161 87.0007 62.3341 87.3698 63.1182 88.068C64.1751 89.009 65.5408 89.009 66.5976 88.068C68.1938 86.6467 70.305 86.6414 71.9071 88.068C72.0597 88.2038 72.2784 88.3444 72.5757 88.498C72.9426 88.6875 73.102 89.1722 72.9318 89.5806C72.7615 89.989 72.3261 90.1665 71.9591 89.977C71.5482 89.7648 71.2409 89.5628 70.992 89.3411C69.9352 88.4001 68.5695 88.4 67.5126 89.3411C65.9164 90.7625 63.8052 90.7677 62.2031 89.3411C61.1463 88.4001 59.7806 88.4 58.7237 89.3411C57.1274 90.7625 55.0162 90.7677 53.4141 89.3411C52.3573 88.4001 50.9916 88.4 49.9347 89.3411C49.6575 89.588 49.3348 89.7774 49.0557 89.9302C48.6927 90.1289 48.2537 89.9623 48.0753 89.5583C47.8969 89.1542 48.0464 88.6657 48.4095 88.467Z" fill="#DEE3EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M111 127.2C111.663 127.2 112.2 126.663 112.2 126C112.2 125.337 111.663 124.8 111 124.8C110.337 124.8 109.8 125.337 109.8 126C109.8 126.663 110.337 127.2 111 127.2ZM111 129C112.657 129 114 127.657 114 126C114 124.343 112.657 123 111 123C109.343 123 108 124.343 108 126C108 127.657 109.343 129 111 129Z" fill="#DEE3EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M207 19.2C207.663 19.2 208.2 18.6627 208.2 18C208.2 17.3373 207.663 16.8 207 16.8C206.337 16.8 205.8 17.3373 205.8 18C205.8 18.6627 206.337 19.2 207 19.2ZM207 21C208.657 21 210 19.6569 210 18C210 16.3431 208.657 15 207 15C205.343 15 204 16.3431 204 18C204 19.6569 205.343 21 207 21Z" fill="#DEE3EA"/>
          <path d="M221.409 55.467C221.605 55.3598 221.852 55.2176 222.02 55.068C223.616 53.6466 225.727 53.6414 227.329 55.068C228.386 56.009 229.752 56.009 230.809 55.068C231.593 54.3698 232.511 54.0007 233.463 54.0007C234.416 54.0007 235.334 54.3698 236.118 55.068C237.175 56.009 238.541 56.009 239.598 55.068C241.194 53.6467 243.305 53.6414 244.907 55.068C245.06 55.2038 245.278 55.3444 245.576 55.498C245.943 55.6875 246.102 56.1722 245.932 56.5806C245.762 56.989 245.326 57.1665 244.959 56.977C244.548 56.7648 244.241 56.5628 243.992 56.3411C242.935 55.4001 241.57 55.4 240.513 56.3411C238.916 57.7625 236.805 57.7677 235.203 56.3411C234.146 55.4001 232.781 55.4 231.724 56.3411C230.127 57.7625 228.016 57.7677 226.414 56.3411C225.357 55.4001 223.992 55.4 222.935 56.3411C222.657 56.588 222.335 56.7774 222.056 56.9302C221.693 57.1289 221.254 56.9623 221.075 56.5583C220.897 56.1542 221.046 55.6657 221.409 55.467Z" fill="#DEE3EA"/>
          <path d="M230.409 61.467C230.605 61.3598 230.852 61.2176 231.02 61.068C232.616 59.6466 234.727 59.6414 236.329 61.068C237.386 62.009 238.752 62.009 239.809 61.068C240.593 60.3698 241.511 60.0007 242.463 60.0007C243.416 60.0007 244.334 60.3698 245.118 61.068C246.175 62.009 247.541 62.009 248.598 61.068C250.194 59.6467 252.305 59.6414 253.907 61.068C254.06 61.2038 254.278 61.3444 254.576 61.498C254.943 61.6875 255.102 62.1722 254.932 62.5806C254.762 62.989 254.326 63.1665 253.959 62.977C253.548 62.7648 253.241 62.5628 252.992 62.3411C251.935 61.4001 250.57 61.4 249.513 62.3411C247.916 63.7625 245.805 63.7677 244.203 62.3411C243.146 61.4001 241.781 61.4 240.724 62.3411C239.127 63.7625 237.016 63.7677 235.414 62.3411C234.357 61.4001 232.992 61.4 231.935 62.3411C231.657 62.588 231.335 62.7774 231.056 62.9302C230.693 63.1289 230.254 62.9623 230.075 62.5583C229.897 62.1542 230.046 61.6657 230.409 61.467Z" fill="#DEE3EA"/>
          <path d="M260.409 99.467C260.605 99.3598 260.852 99.2176 261.02 99.068C262.616 97.6466 264.727 97.6414 266.329 99.068C267.386 100.009 268.752 100.009 269.809 99.068C270.593 98.3698 271.511 98.0007 272.463 98.0007C273.416 98.0007 274.334 98.3698 275.118 99.068C276.175 100.009 277.541 100.009 278.598 99.068C280.194 97.6467 282.305 97.6414 283.907 99.068C284.06 99.2038 284.278 99.3444 284.576 99.498C284.943 99.6875 285.102 100.172 284.932 100.581C284.762 100.989 284.326 101.167 283.959 100.977C283.548 100.765 283.241 100.563 282.992 100.341C281.935 99.4001 280.57 99.4 279.513 100.341C277.916 101.762 275.805 101.768 274.203 100.341C273.146 99.4001 271.781 99.4 270.724 100.341C269.127 101.762 267.016 101.768 265.414 100.341C264.357 99.4001 262.992 99.4 261.935 100.341C261.657 100.588 261.335 100.777 261.056 100.93C260.693 101.129 260.254 100.962 260.075 100.558C259.897 100.154 260.046 99.6657 260.409 99.467Z" fill="#DEE3EA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M73 46.2C74.7673 46.2 76.2 44.7673 76.2 43C76.2 41.2327 74.7673 39.8 73 39.8C71.2327 39.8 69.8 41.2327 69.8 43C69.8 44.7673 71.2327 46.2 73 46.2ZM73 48C75.7614 48 78 45.7614 78 43C78 40.2386 75.7614 38 73 38C70.2386 38 68 40.2386 68 43C68 45.7614 70.2386 48 73 48Z" fill="#DEE3EA"/>
        </svg>
        <p class="fw600 fz18 text mt16">No results were found for your search</p>
      </div>
      <div class="not-found w100Proc d-flex flex-column justify-center align-items-center pb38 position-relative" v-if="locationList.length === 0 && !activeFilter">
        <app-loader :is-local="true" v-if="load" class="loader-list"></app-loader>
        <svg width="140" height="120" viewBox="0 0 140 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M96.3145 17.3779C92.9427 17.3779 90.2226 20.0847 90.2226 23.4074V26.7301H102.405V23.2652C102.329 20.0082 99.6381 17.3779 96.3145 17.3779ZM104.789 23.4074H104.79C104.79 23.3471 104.79 23.287 104.788 23.227C104.691 18.6597 100.928 15 96.3145 15C91.6408 15 87.8387 18.7568 87.8387 23.4074V29.437H35.5245C34.9304 24.3516 30.5716 20.4139 25.2967 20.4139C19.6173 20.4139 15 24.9787 15 30.626V93.7879C15 99.4352 19.6173 104 25.2967 104H114.524C119.198 104 123 100.243 123 95.5926V37.8445C123 33.1939 119.198 29.437 114.524 29.437H104.789V23.4074ZM104.789 31.8149V79.3509C104.789 79.6221 104.696 79.8852 104.525 80.0965L97.243 89.1196C97.0167 89.3999 96.6754 89.563 96.3146 89.563C95.9538 89.563 95.6124 89.4 95.3862 89.1197L88.1023 80.0966C87.9317 79.8853 87.8387 79.6222 87.8387 79.3509V31.8149H35.5935V93.7879C35.5935 94.4446 35.0599 94.9769 34.4016 94.9769C33.7433 94.9769 33.2097 94.4446 33.2097 93.7879C33.2097 89.4685 29.6743 85.9537 25.2967 85.9537C20.9192 85.9537 17.3838 89.4685 17.3838 93.7879C17.3838 98.1073 20.9192 101.622 25.2967 101.622H114.524C117.896 101.622 120.616 98.9153 120.616 95.5926V37.8445C120.616 34.5217 117.896 31.8149 114.524 31.8149H104.789ZM17.3838 87.253C19.2738 85.0049 22.119 83.5759 25.2967 83.5759C28.4745 83.5759 31.3197 85.0049 33.2097 87.253V30.626C33.2097 26.3065 29.6743 22.7917 25.2967 22.7917C20.9192 22.7917 17.3838 26.3065 17.3838 30.626V87.253ZM102.405 29.1079H90.2226V33.9486H102.405V29.1079ZM102.405 36.3265H90.2226V78.9317L96.3144 86.4782L102.405 78.9318V36.3265Z" fill="#DEE3EA"/>
        </svg>

        <p class="fw600 fz18 text mt16">List is empty</p>
      </div>
    </div>
    <location-modal-send-message @is_reads="addIsReads" class="" :vessels="selectedList" v-if="sendMessageModal" @clearSelected="clearListSelected" @close="()=>{sendMessageModal = false;}"></location-modal-send-message>
    <modal-slots :isBorder="false" class="delete-modal" :showMod="clickDelete" @close="clickDelete = false">
      <template v-slot:title>
        <h2 class="fz16 fw500 text-dark">Delete selected</h2>
      </template>

      <p class="fz14 text-dark p24">Are you sure you want to delete selected lines?</p>

      <template v-slot:bottomFirstButton>
        <button class="blue-button-text fz14" @click="clickDelete = false">Cancel</button>
      </template>
      <template v-slot:bottomSecondButton>
        <button class="m-l-8 p-x-16 blue-button fz14 fw500" @click="deleteSelected()">Delete</button>
      </template>
    </modal-slots>
  </div>
</template>

<script>
import LocationFilter from "@/components/location/location-filter";
import LocationList from "@/components/location/location-list/location-list";
import {mapMutations, mapState} from "vuex";
import AppLoader from "@/components/loader";
import LocationModalSendMessage from "@/components/location/location-modal-send-message";
import ModalSlots from "../../components/modal/modal-slots";
export default {
  name: "location",
  components: {ModalSlots, LocationModalSendMessage, AppLoader, LocationList, LocationFilter},
  computed: {
    ...mapState({
      locationList: state => state.location.locationList,
      load: state => state.location.loadLocation,
    })
  },
  methods: {
    ...mapMutations(['setLocation']),
    clearListSelected() {
      this.$refs.list.clearListSelected()
    },
    addIsReads(ids) {
      this.$refs.list.setReads(ids)
    },
    deleteSelected() {
      this.clickDelete = false
      this.$refs.list.deleteSelected()
    },

  },
  data() {
    return {
      clickDelete: false,
      selectedList: [],
      activeFilter: false,
      sendMessageModal: false,
    }
  },
  destroyed() {
    this.setLocation([])
  }
}
</script>

<style scoped lang="scss">
  .top-panel {
    background-color: var(--color-bgc-page-white);
    padding: 0px 24px;
    box-sizing: border-box;
    height: 48px;
    width: 100vw;
    display: flex;
    align-items: stretch;
    justify-content: center;
    border-bottom: 1px solid var(--color-card-border);
    &>* {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      //height: 108px;
    }
    &>*:not(:last-child) {
      margin-right: 30px;
    }
    .active {
      color: var(--color-blue-dark);
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        height: 2px;
        background-color: var(--color-blue-dark);
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .wrap-page {
    background-color: var(--color-bgc-page);
    height: calc(100vh - 48px);
    .content {
      margin-top: 16px;
      padding: 0 16px
    }
  }
  .not-found {
    width: 100%;
    box-sizing: border-box;
    margin-top: 40px;
  }
</style>