<template>
  <div>
    <div class="container-head border border-color-dark-gray">
      <div class="number fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">№</div>
      <div class="cargo fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Cargo</div>
      <div class="quantity fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Quantity</div>
      <div class="stowage-factor fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Stowage Factor*</div>
      <div class="button-create-new-cargo d-flex align-items-center justify-center border-right border-color-gray-1">
        <button class="d-flex align-items-center justify-center text-blue fz14 fw600" v-on:click="$emit('add')" :disabled="loadAddCargo">
          <svg class="mr4" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#319FEF"/>
          </svg>
          Create new cargo
        </button>
      </div>
      <div class="port fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Port</div>
      <div class="days-or-rate fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Days or Rate</div>
      <div class="terms fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Terms</div>
      <div class="turn-time fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Turn time</div>
      <div class="draft fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Draft</div>
      <div class="water-density fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Water density</div>
      <div class="season fz13 fw700 text-color-new-dark-gray border-right border-color-gray-1">Season</div>
      <div class="liner-in-out fz13 fw700 text-color-new-dark-gray">Liner In/Out</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cargo-head",
  props: {
    loadAddCargo: Boolean,
  }
}
</script>

<style scoped lang="scss">
.container-head {
  border-radius: 8px 8px 0 0;
  display: grid;
  background-color: var(--color-head-table);
  grid-template-columns: 1.972% 6.373% 8.497% 7.587% 22.078% 8.497% 10.015% 7.511% 4.855% 4.097% 6.525% 5.538% 1fr;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  &>* {
    padding: 5px 6px;
    box-sizing: border-box;
  }
}


.number { grid-area: 1 / 1 / 2 / 2; }

.cargo { grid-area: 1 / 2 / 2 / 3; }

.quantity { grid-area: 1 / 3 / 2 / 4; }

.stowage-factor { grid-area: 1 / 4 / 2 / 5; }

.button-create-new-cargo { grid-area: 1 / 5 / 2 / 6; }

.port { grid-area: 1 / 6 / 2 / 7; }

.days-or-rate { grid-area: 1 / 7 / 2 / 8; }

.terms { grid-area: 1 / 8 / 2 / 9; }

.turn-time { grid-area: 1 / 9 / 2 / 10; }

.draft { grid-area: 1 / 10 / 2 / 11; }

.water-density { grid-area: 1 / 11 / 2 / 12; }

.season { grid-area: 1 / 12 / 2 / 13; }

.liner-in-out { grid-area: 1 / 13 / 2 / 14; }

.button-blue-border {
  padding: 0 !important;
}


</style>