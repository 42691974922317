<template>
  <div class="p-12-24 w100vw box-border">
    <div class="top-panel  d-flex align-items-center">
      <p class="fz20 fw500 text-dark">Mailing Templates {{$route.path.indexOf('archive') === -1 ? '' : 'Archive'}}</p>
      <router-link :to="{name: 'Mailling Templates Archive'}" v-if="$route.path.indexOf('archive') === -1" class="d-flex align-items-center ml16 button-dark-link archive-link svg-white-dart-stroke text-dark fw500 fz13">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Archive
      </router-link>
      <mailing-sort-by class="ml-auto" v-model="sort"></mailing-sort-by>
      <div class="relative d-flex ml12 align-items-center">
        <svg class="absolute l8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
        </svg>
        <input type="text" class="w240 h32 pl40 bgc-white" placeholder="Search" @focusout="search !== searchCopy ? searchTemplate() : null" v-on:keydown.enter="search !== searchCopy ? searchTemplate() : null" v-model="search">
        <button class="blue-button h32 ml12" v-on:click="openModal">
          <svg class="mr4" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8333 7.33332H9.16668V2.66662C9.16668 2.2987 8.86798 2 8.49994 2C8.13202 2 7.83332 2.2987 7.83332 2.66662V7.33332H3.16662C2.7987 7.33332 2.5 7.63202 2.5 7.99994C2.5 8.36798 2.7987 8.66668 3.16662 8.66668H7.83332V13.3333C7.83332 13.7013 8.13202 14 8.49994 14C8.86798 14 9.16668 13.7013 9.16668 13.3333V8.66668H13.8333C14.2013 8.66668 14.5 8.36798 14.5 7.99994C14.5 7.63202 14.2013 7.33332 13.8333 7.33332Z" fill="white"/>
          </svg>
          Create Mailing Template
        </button>
      </div>
    </div>
    <mailling-list @unarchiveSelected="(e)=> {undoSelected = e}" ref="list" @deleteSelected="(e)=> {deleteSelected = e}" @deleteReal="(e)=> {deleteRealItem = e}" @clickItem="openEdit" @changeSort="(e)=>{sort = e}" :sort="sort" @delete="(e)=> {idDeleteTemplate = e}" :isLoad="isLoad" :searchActive="search.length !== 0" :items="templatesMailing" class="mt16" :type="'template'"></mailling-list>
    <pagination-panel-default v-if="templatesMailingNext" :if-active="isLoadNext" @load-more="loadNext"></pagination-panel-default>
    <create-edit-template-mailing ref="modal" @changed="searchTemplate" @created="searchTemplate" :type="typeModal" :showStatus="showCreateMailingTemplate" v-show="showCreateMailingTemplate" @close="showCreateMailingTemplate = false"></create-edit-template-mailing>
  <delete-modal
      v-if="idDeleteTemplate"
    :title="$route.path.indexOf('archive') === -1 ? 'Archive Mailing Template' : 'Unarchive Mailing Template'"
    :text="$route.path.indexOf('archive') === -1 ? 'Are you sure you want to archive mailing template?' : 'Are you sure you want to unarchive mailing template?'"
    @close="idDeleteTemplate = null"
    @apply="deleteTemplate(idDeleteTemplate)"
      :textForSecondButton="$route.path.indexOf('archive') === -1 ? 'Delete' : 'Yes'"
      :textForFirstButton="$route.path.indexOf('archive') === -1 ? '' : 'No'"
  ></delete-modal>
    <delete-modal
        v-if="deleteRealItem"
        :title="'Delete Mailing Template'"
        :text="'Are you sure you want to delete mailing template?<br>It Will be impossible to recover'"
        @close="deleteRealItem = null"
        @apply="deleteTemplateReal(deleteRealItem)"
        :textForSecondButton="'Delete'"
    ></delete-modal>
    <delete-modal
        v-if="deleteSelected.length"
        :title="'Delete Selected Mailing Templates'"
        :text="'Are you sure you want to delete selected mailing templates?<br>It Will be impossible to recover'"
        @close="deleteSelected = []"
        @apply="deleteTemplateRealSelected(deleteSelected)"
        :textForSecondButton="'Yes'"
        :textForFirstButton="'No'"
    ></delete-modal>
    <delete-modal
        v-if="undoSelected.length"
        :title="'Unarchive Selected Mailing Templates'"
        :text="'Are you sure you want to unarchive selected mailing templates?'"
        @close="undoSelected = []"
        @apply="unarchiveSelectedTemplate(undoSelected)"
        :textForSecondButton="'Yes'"
        :textForFirstButton="'No'"
    ></delete-modal>
  </div>
</template>

<script>
import MaillingList from "@/components/mailling/mailling-list";
import CreateEditTemplateMailing from "@/views/mailling/create-edit-template-mailing/create-edit-template-mailing";
import {mapActions, mapMutations, mapState} from "vuex";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import DeleteModal from "@/components/mailling/delete-modal";
import MailingSortBy from "@/views/mailling/mailing-sort-by";
export default {
  name: "mailling-templates",
  components: {MailingSortBy, DeleteModal, PaginationPanelDefault, CreateEditTemplateMailing, MaillingList},
  computed: {
    ...mapState({
      templatesMailing: state=> state.mailling.templatesMailing,
      templatesMailingNext: state => state.mailling.templatesMailingNext,
    })
  },
  props: {
    selectedAll: Boolean,
  },
  data() {
    return {
      undoSelected: [],
      showCreateMailingTemplate: false,
      search: '',
      searchCopy: '',
      isLoad: false,
      sort: 'name',
      typeModal: 'create',
      isLoadNext: false,
      idDeleteTemplate: null,
      deleteRealItem: null,
      deleteSelected: [],
    }
  },
  watch: {
    sort: function () {
      this.searchTemplate()
    },
    $route() {
      this.searchTemplate()
      this.$refs.list.clearSelected()
    },
  },
  methods: {
    ...mapActions(['axiosRealDeleteMailingTemplate', 'axiosGetMailingTemplates', 'axiosDeleteMailingTemplate', 'axiosGetNextMailingTemplates', 'axiosChangeMailingTemplate']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    loadNext() {
      this.isLoadNext = true
      this.axiosGetNextMailingTemplates().then(()=> {
        this.isLoadNext = false
      })
    },
    openModal() {
      this.showCreateMailingTemplate = true
      this.typeModal = 'create'
    },
    openEdit(item) {
      this.showCreateMailingTemplate = true
      this.typeModal = 'edit'
      this.$refs.modal.openEdit(item)
    },
    searchTemplate() {
      this.typeModal = 'create'
      this.searchCopy = this.search
      this.isLoad = true
      this.axiosGetMailingTemplates({search: this.search, ordering: this.sort, is_archived: this.$route.path.indexOf('archive')>=0}).then(()=> {
        this.isLoad = false
      })
    },
    async deleteTemplate(item) {
      let form = {
        id: item.id,
        contact_list: item.contact_list.map(a=> {return a.id}),
        name: item.name,
        emails_list: item.emails_list,
        subject: item.subject,
        text: item.text,
        files: [],
        filesIds: item.files.map(a=> {return a.id}),
        is_archived: !item.is_archived
      }
      this.axiosChangeMailingTemplate(form)
      this.axiosDeleteMailingTemplate(item.id)
    },
    async deleteTemplateReal(item) {
      this.showLoad()
      let res = await this.axiosRealDeleteMailingTemplate(item.id)
      this.hideLoad()
      if(res) {
        this.deleteRealItem = null
        this.addTip('Mailing Template was deleted')
      } else {
        this.addTip('Error')
      }
    },
    async unarchiveSelectedTemplate(array) {
      this.showLoad()
      for(let i=0; i< array.length; i++) {
        let item = array[i]
        let form = {
          id: item.id,
          contact_list: item.contact_list.map(a=> {return a.id}),
          name: item.name,
          emails_list: item.emails_list,
          subject: item.subject,
          text: item.text,
          files: [],
          filesIds: item.files.map(a=> {return a.id}),
          is_archived: !item.is_archived
        }
        await this.axiosChangeMailingTemplate(form)
        this.axiosDeleteMailingTemplate(item.id)
      }
      this.hideLoad()
      this.$refs.list.clearSelected()
      this.undoSelected = []
    },
    async deleteTemplateRealSelected(array) {
      this.showLoad()
      for(let i=0; i< array.length; i++) {
        await this.axiosRealDeleteMailingTemplate(array[i].id)
      }
      this.hideLoad()
      this.$refs.list.clearSelected()
      this.deleteSelected = []
      // if(res) {
      //   this.deleteRealItem = null
      //   this.addTip('Mailing Template was deleted')
      // } else {
      //   this.addTip('Error')
      // }

    },
  },
  beforeMount() {
    this.searchTemplate()
  }
}
</script>

<style scoped lang="scss">
  .blue-button {
    background-color: var(--color-blue-dark);
    box-sizing: border-box;
    border: 1px solid var(--color-blue-dark);
    &:hover {
      color: var(--color-blue-dark);
      svg {
        path {
          fill: var(--color-blue-dark)
        }
      }
      background-color: transparent !important;
    }
  }
</style>