<template>
  <div class="wrap">
    <div class="list-emails overflow-y-auto ">
      <app-loader class="loader-list" :is-local="true" v-if="loadList"></app-loader>
      <div class="list-container list-title bgc-gray border-bottom box-border" :class="{'vesselModeTitle': vesselMode}">
        <div class="read fz13 fw500 text-gray d-flex align-items-center justify-start" :class="{'grid-helper': type !== 'incoming'}" v-if="!vesselMode">
          {{ type === 'incoming' ? 'Read' : 'Recipient' }}
        </div>
        <div v-else class="read fz13 fw500 text-gray d-flex align-items-center justify-end" :class="{'grid-helper': type !== 'incoming'}">
          <svg class="mr6" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75 15.0041H13.25C14.2165 15.0041 15 14.2305 15 13.2762V6.79688L8.85796 10.208C8.32523 10.5039 7.67477 10.5039 7.14204 10.208L1 6.79688V13.2762C1 14.2305 1.7835 15.0041 2.75 15.0041Z" fill="#787F89" stroke="#787F89" stroke-width="1.4"/>
            <path d="M1 6.79281V13.2722C1 14.2264 1.7835 15 2.75 15H13.25C14.2165 15 15 14.2264 15 13.2722V6.79281M1 6.79281V6.36085V5.81002C1 5.08358 1.39389 4.41427 2.02896 4.06157L7.14204 1.2219C7.67477 0.926035 8.32523 0.926035 8.85796 1.2219L13.971 4.06157C14.6061 4.41427 15 5.08358 15 5.81002V6.79281M1 6.79281L7.14204 10.2039C7.67477 10.4998 8.32523 10.4998 8.85796 10.2039L15 6.79281M15 6.79281V6.36085" stroke="#787F89" stroke-width="1.4"/>
          </svg>

        </div>
        <div class="sender fz13 fw500 text-gray d-flex align-items-center justify-start" v-if="type === 'incoming'">
          Sender
        </div>
        <div class="subject fz13 fw500 text-gray d-flex align-items-center justify-start">
          Subject
        </div>
        <div class="sent-time fz13 fw500 text-gray d-flex align-items-center justify-start">
          Sent time
        </div>
      </div>
    </div>
    <div class="list-container list-item bgc-modal border-bottom box-border position-relative" v-on:click="()=>{!modalSend ? select(msg) : null}" :class="{'list-item-active': selectedEmail && selectedEmail.id === msg.id, 'list-item-not-read': !msg.is_read, 'vesselMode': vesselMode}" v-for="(msg, i) in messages" :key="'dd'+i">
      <div class="click-zone" v-if="modalSend" v-on:click="select(msg)"></div>
      <div class="read d-flex align-items-center align-items-center" :class="{'grid-helper justify-start': type !== 'incoming', 'justify-center': type === 'incoming'}">
        <svg v-if="!msg.is_read && type === 'incoming'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="5" fill="#21CA68"/>
        </svg>
        <svg v-else-if="type === 'incoming'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="4" stroke="#9AA3B0" stroke-width="2"/>
        </svg>
        <p v-else class="fz14 text-dark d-flex" v-html="getTextRecipient(!vesselMode ? msg.message_to : msg.email_to)"></p>
      </div>
      <div class="sender fz14 text-dark d-flex align-items-center justify-start" v-if="type === 'incoming'">
        <span class="overflow-text-dots">{{ !vesselMode ? msg.message_from : msg.email_from}}</span>
      </div>
      <div class="subject fz14 text-dark d-flex align-items-center justify-start overflow-text-dots">
        <span class="overflow-text-dots">{{msg.subject}}</span>
      </div>
      <div class="sent-time fz14 text-dark d-flex align-items-center justify-start overflow-text-dots">
        <span class="overflow-text-dots">{{ type === 'incoming' && !vesselMode ? getFormatTimeMessage(msg.receive_date) : getFormatTimeMessage(msg.created_at)}}</span>
          <svg class="cursor-pointer" v-if="vesselMode" v-on:click="idUnattach = msg.id" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51228 7.57727C6.21466 7.28258 6.21466 6.80478 6.51228 6.51008C6.80991 6.21539 7.29246 6.21539 7.59009 6.51008L17.4849 16.2961C17.7825 16.5908 17.7825 17.0685 17.4849 17.3632C17.1872 17.6579 16.7047 17.6579 16.4071 17.3632L6.51228 7.57727Z" fill="#2C2C2C"/>
            <path d="M16.4072 6.634C16.7048 6.33931 17.1873 6.33931 17.485 6.634C17.7826 6.9287 17.7826 7.4065 17.485 7.70119L7.59019 17.4872C7.29256 17.7819 6.81001 17.7819 6.51238 17.4872C6.21476 17.1925 6.21476 16.7147 6.51238 16.42L16.4072 6.634Z" fill="#2C2C2C"/>
          </svg>
      </div>

      <div class="checkbox-additioanaly d-flex align-items-center justify-center" v-if="modalSend">
        <svg class="cursor-pointer" v-on:click="$emit('selectCheckbox', msg)" v-if="selected.find(a=> a.id === msg.id)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z" fill="#094172"/>
          <path d="M17.5 8.02532C17.1868 7.67724 16.67 7.66759 16.3456 8.00375C16.3388 8.01079 16.3321 8.01797 16.3255 8.02532L10.764 13.9232L7.88355 11.7928C7.55922 11.4566 7.04241 11.4663 6.72918 11.8144C6.42361 12.1539 6.42361 12.6922 6.72918 13.0318L10.1868 15.7816C10.5056 16.1237 11.0224 16.1237 11.3411 15.7816L17.4799 9.26426C17.8042 8.92805 17.8132 8.37339 17.5 8.02532Z" fill="white"/>
        </svg>
        <svg class="cursor-pointer" v-on:click="$emit('selectCheckbox', msg)" v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4.4C5.56406 4.4 4.4 5.56406 4.4 7V17C4.4 18.4359 5.56406 19.6 7 19.6H17C18.4359 19.6 19.6 18.4359 19.6 17V7C19.6 5.56406 18.4359 4.4 17 4.4H7ZM3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z" fill="#094172"/>
        </svg>
      </div>
    </div>
    <delete-modal
        @close="idUnattach = null"
        @apply="unattachMessage"
        v-if="idUnattach"
      :title="'Unattach message'"
      :text="'Are you sure you want to unattach the message?'"
      :textForSecondButton="'Unattach'"
    ></delete-modal>
  </div>

</template>

<script>
import AppLoader from "@/components/loader";
import moment from 'moment';
import DeleteModal from "@/components/mailling/delete-modal.vue";
import {mapActions, mapMutations} from "vuex";
export default {
  name: "location-read-emails-list",
  components: {DeleteModal, AppLoader},
  props: {
    vesselMode: Boolean,
    selected: Array,
    modalSend: Boolean,
    loadList: Boolean,
    selectedEmail: null,
    messages: Array,
    type: String,
  },
  data() {
    return {
      idUnattach: null,
    }
  },
  methods: {
    ...mapMutations(['showLoad', 'hideLoad']),
    ...mapActions(['axiosUnPinMessageFromObject']),
    async unattachMessage() {
      let id = this.idUnattach
      let data = {}
      this.showLoad()
      if(this.$route.path.indexOf('ports') >= 0) {
        data['ports_id'] = [this.$route.params.id]
        // this.mailDetailInfo.contracts = this.mailDetailInfo.contracts.filter(el=> el.id !== item.id)
      } else {
        data['contracts_id'] = [this.$route.params.id]
        // this.mailDetailInfo.ports = this.mailDetailInfo.ports.filter(el=> el.id !== item.id)
      }
      await this.axiosUnPinMessageFromObject({id: this.idUnattach, data: data})
      this.$emit('deleted', id)
      this.idUnattach = null
      this.hideLoad()
    },
    select(msg) {
      this.$emit('select', msg)
    },
    getTextRecipient(emails) {
      let text = ''
      if(emails.length > 1) {
        return '<span class="text-dots-overflow w-name box-border">'+emails[0]+'</span>' + '<span class="circle ml4">+'+ (emails.length-1) +'</span>'
      } else if(emails.length == 1) {
        return '<span class="text-dots-overflow w100Proc box-border">'+emails[0]+'</span>'
      }
      return text
    },
    getFormatTimeMessage(date) {
      return date ? moment(date).format('DD MMM YY, HH:mm:ss') : ''
    },
  }
}
</script>

<style scoped lang="scss">
/deep/.circle {
  border-radius: 20px;
  border: 1px solid var(--color-dark);
  padding: 2px 4px;
}
.w-name {
  max-width: calc(100% - 33px);
}
.grid-helper {
  grid-column: 1 / 3;
}
.loader-list  {
  z-index: 5;
  top: 44px;
  height: calc(100% - 44px);
  background-color: transparent !important;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bgc-page-white);
    opacity: 0.7;
  }
  /deep/ {
    .sk-fading-circle {
      position: relative;
      z-index: 10;
    }
  }
}
.list-emails {
  width: 100%;
}
.list-container {  display: grid;
  grid-template-columns: 50px 188px 1fr 148px;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "read sender subject sent-time";
  &>* {
    box-sizing: border-box;
  }
}


.read {
  grid-area: read;
  padding-left: 14px;
  padding-right: 7px;
}


.sender {
  grid-area: sender;
  padding-right: 8px;
}

.subject {
  grid-area: subject;
  padding-right: 8px;
}

.sent-time {
  grid-area: sent-time;
  padding-right: 12px;
}

.list-title {
  grid-template-rows: 24px;
}
.list-item {
  grid-template-rows: 40px;
  &:hover {
    background-color: var(--color-bgc-page);
    .sent-time {
      svg {
        display: block !important;
      }
    }
  }
}
.list-item-active {
  background-color: var(--color-blue-lightest) !important;
  .sent-time {
    svg {
      display: block !important;
    }
  }
}
.list-item-not-read {
  div span {
    font-weight: 600;
    color: var(--color-blue)
  }
}
.list-item-not-read.vesselMode {
  div span {
    font-weight: 600;
    color: var(--color-blue-dark)
  }
}
.click-zone {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% - 64px);
}
.vesselMode {
  grid-template-columns: 50px 188px 1fr 162px;
  .sent-time {
    padding-right: 36px;
    position: relative;
    svg {
      display: none;
      position: absolute;
      right: 8px;
    }
  }
}
.vesselModeTitle {
  grid-template-columns: 50px 188px 1fr 162px;

}

</style>