<template>
  <div class="p-t-3 ">
<!--    <button v-on:click="moveFromWithInvisibleVessel">sdasd</button>-->
<!--    <canal-code class="displayNone" v-model="codeCanal"></canal-code>-->
    <app-loader v-if="showLoader" :is-local="true" class="loader-patch"></app-loader>
    <div class="calc_vessels__row_vessels flex justify-between relative">
      <calc-vessels-list
          @renamed="checkCorrectNumberDuplicates"
        :exportPdf="exportPdf"
        :vessels="vessels.filter(a=> a.name !== 'Invisible vessel - not show')"
        :isAllow="ifAllowEdit"
        :selectedId="selectedId"
        :replaceMode="replaceMode"
        @on-select="onSelect"
        @on-pin="onPinVessels"
        @on-delete="onDelete"
        @on-create="onCreate"
        @on-calc="onCalcInVessel"
        @on-duplicate="onDuplicateVessel"
        @on-replace="onReplaceVessel"
        @on-replace-save="onReplaceSaveVessel"
        @replaceModeOff="replaceModeOff"
      />
      <calc-vessels-detail @changeScrubber="changeScrubber" :vessel="getActiveVessel" :isAllow="ifAllowEdit"/>
      <calc-vessels-speed @saveTemplate="saveTemplate" :vessel="getActiveVessel" :isAllow="ifAllowEdit"/>
    </div>

<!--    <div class="relative">-->
<!--      <div class="blocked-changes" v-if="!ifAllowEdit"></div>-->
<!--      <calc-vessels-speed-result class="m-t-4" :detail="getActiveVessel" v-model="fuelType"/>-->
<!--    </div>-->
    <div class="mt16 d-flex">
      <vessel-selected-and-calc-type :load-table="showLoaderTable" v-model="fuelType" :detail="getActiveVessel"></vessel-selected-and-calc-type>
      <div class="pr8"></div>
      <div class="d-flex flex-column border border-radius-8 flex-1 table-additional-info">
        <div class="d-flex h24 border-bottom border-gray-light">
          <calc-vessels-total-times
              :fuelType="fuelType"
              :routes="getActiveVesselRoutes"
              class="calc-total-times"
          />
          <button class="blue-button fz13 fw500" v-on:click="loadMap">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9205 4.37895C20.1266 4.51848 20.25 4.75114 20.25 5V17C20.25 17.3067 20.0633 17.5825 19.7785 17.6964L14.7785 19.6964C14.5997 19.7679 14.4003 19.7679 14.2215 19.6964L9.5 17.8078L4.77854 19.6964C4.54747 19.7888 4.28561 19.7606 4.07953 19.6211C3.87345 19.4815 3.75 19.2489 3.75 19V7C3.75 6.69333 3.93671 6.41754 4.22146 6.30365L9.22146 4.30365C9.40027 4.23212 9.59973 4.23212 9.77854 4.30365L14.5 6.19223L19.2215 4.30365C19.4525 4.21122 19.7144 4.23943 19.9205 4.37895ZM13.75 7.50778L10.25 6.10778V16.4922L13.75 17.8922V7.50778ZM15.25 17.8922L18.75 16.4922V6.10778L15.25 7.50778V17.8922ZM8.75 16.4922V6.10778L5.25 7.50778V17.8922L8.75 16.4922Z" fill="#094172"/>
            </svg>
            Map View
          </button>
        </div>
       <div class="d-flex h24">
         <div class="d-flex align-items-center table-additional-info-bottom-row align-items-stretch w100Proc">
             <div class="fz13  bgc-gray pl6 pr6 d-flex align-items-center text-color-new-dark-gray fw700">Weather Factor</div>
           <label class="relative flex items-center select-weather-factor">
           <input
                 type="text"
                 class=" p-x-2 p-y-1 "
                 :value="activeTab.weather_factor"
                 :disabled="isDisabled || $route.path.indexOf('archive') >= 0 || (activeCreator && getLoginData && getLoginData.data && activeCreator.id !== getLoginData.data.user.id)"
                 @blur="(e)=>{onBlurInfo(e, 'weather_factor'); setIntakeForRoutesInVessel(getActiveVessel)}"
                 @keyup.enter="onBlurInfo($event, 'weather_factor')"
             >
             <div class="absolute right-2 centered-y fz14 text-gray-text">%</div>
           </label>
           <p class="fz13  bgc-gray pl6 pr6 d-flex align-items-center text-color-new-dark-gray fw700">Bypass ECA</p>
           <select-white class="select-bypass"
                         :allow-empty="false"
                         :list="secaBypassOptionList"
                         v-model="secaBypassOption"
                         :watchKey="'option'"
                         :small-list="true"
           ></select-white>
           <select-white class="select-bypass"
                         v-if="secaBypassOption && secaBypassOption.option === 'optimized' && false"
                         :allow-empty="false"
                         :list="secaBypassOptionList"
                         v-model="secaCostRation"
                         :watchKey="'option'"
                         :small-list="true"
           ></select-white>
           <p class="fz13  bgc-gray pl6 pr6 d-flex align-items-center text-color-new-dark-gray fw700">Piracy options</p>
           <select-white class="select-piracy"
                         :allow-empty="false"
                         :list="piracyCodes"
                         v-model="piracyActive"
                         :watchKey="'code'"
                         :watchKey2="'description'"
           ></select-white>
           <canal-code v-if="getActiveVessel" @input="codeCanal = getActiveVessel ? getActiveVessel.additional_info.canal_code : ''" :dontLocalSave="true" class="canal-code" :key="$route.path" v-model="getActiveVessel.additional_info.canal_code"></canal-code>
         </div>
       </div>
      </div>
    </div>
    <cargo-list :selectedId="selectedId" @checkRoutesAndCargos="checkRoutesAndCargos" @calc="calcCargo" :routeList="getActiveVesselRoutes ? getActiveVesselRoutes : []" :cargoList="cargos"></cargo-list>

    <div class="m-t-4 flex items-center relative">
      <div class="w-1/2 fw500">
        <template v-if="!getActiveVessel">No vessel selected</template>
        <div class="inline-flex" v-else>
          <span class="block max-w-320 fw500 whitespace-nowrap overflow-hidden overflow-ellipsis">{{getActiveVessel.name}} </span> 
          <div class="fw500">
            <span class="fz0">space</span>
            <span class="fw500" v-if="getActiveVessel.dwt">{{' - '+getActiveVessel.dwt}}</span>
            <span class="fw500" v-if="getActiveVessel.year">{{' - '+getActiveVessel.year}}</span>
            (<span class="capitalize fw500">{{fuelType}}</span> Speed)
          </div>
        </div>
      </div>

    </div>
    <div
      class="m-t-3 border border-dark-gray rounded-lg relative"
      :class="{'opacity-50 pointer-events-none': !getActiveVessel}"
    >
      <calc-vessels-dots-head :vessel="getActiveVessel" @open-fast-calc="isFastCalc = getActiveVessel ? true : false"/>

      <draggable 
        v-if="getActiveVessel"
        v-model="getActiveVesselRoutes" 
        handle=".handle"
        @change="onUpdateDrag"
      >
        <calc-vessels-dots-row
          v-for="(row, i) in getActiveVesselRoutes"
          :key="i"
          :dot="row"
          :index="i"
          :routes="getActiveVesselRoutes"
          :cargos="cargos"
          :fuelType="fuelType"
          :vessel="getActiveVessel"
          @checkRoutesAndCargos="checkRoutesAndCargos"
          @on-set-disbursement="onSetDisbursement(row.disbursement, i)"
          @on-add="onCreateRoutes(i)"
          @on-delete="onDeleteRoute(i)"
          @on-update-intake="onUpdateIntakeInRoute(getActiveVessel)"
          @on-update-dist="onUpdateDistInRoute(getActiveVessel)"
          @on-update-seca="onUpdateSecaInRoute(getActiveVessel)"
          @on-update-draft="onUpdateDraftInRoute(getActiveVessel)"
          @on-update-port-stay="onUpdatePortStayInRoute(getActiveVessel)"
          @on-update-streaming="onUpdateSteamingInRoute(getActiveVessel)"
          @on-update-ifo-mgo="setHireAndFreightBepInVessel(getActiveVessel)"
          @on-change-check="onChengeCheckInOptimalInRoute(getActiveVessel)"
          @on-update-lay-ex="changeLayEx"
          @on-select-port="(newPort, oldPort) => onSelectPort(newPort, oldPort, i)"
        />
      </draggable>

      <calc-vessels-dots-total
        :routes="getActiveVesselRoutes"
        :fuelType="fuelType"
      />
      <div class="blocked-changes" v-if="!ifAllowEdit"></div>
    </div>
    <template v-if="getLocation.indexOf('https://speed-logistic-marine.com/') == -1">
      <div v-for="(item, i) in ladBallRoutes" :key="i">{{item}}</div>

      <div class="m-t-3">
        <table class="w-full">
          <tr>
            <th>route_id</th>
            <th>port</th>
            <th>dwa</th>
            <th>draft</th>
            <th>dw_calc</th>
            <th>dw_intake</th>
            <th>min</th>
            <th>max</th>
            <th>sf_int</th>
            <th>lim_max_qtt</th>
            <th>intake</th>
          </tr>
          <tr>
            <td>load:</td>
          </tr>
          <tr v-for="(row, i) in loadTable" :key="'l'+i">
            <td>{{row.route_id}}</td>
            <td>{{row.port ? row.port.name : ''}}</td>
            <td>{{row.dwa}}</td>
            <td>{{row.draft}}</td>
            <td>{{row.dw_calc}}</td>
            <td>{{row.dw_intake}}</td>
            <td>{{row.cargo_min}}</td>
            <td>{{row.cargo_max}}</td>
            <td>{{row.sf_int}}</td>
            <td>{{row.lim_max_qtt}}</td>
            <td>{{row.intake}}</td>
          </tr>
          <tr>
            <td>disch:</td>
          </tr>
          <tr v-for="(row, i) in dischTable" :key="'d'+i">
            <td>{{row.route_id}}</td>
            <td>{{row.port ? row.port.name : ''}}</td>
            <td>{{row.dwa}}</td>
            <td>{{row.draft}}</td>
            <td>{{row.dw_calc}}</td>
            <td>{{row.dw_intake}}</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </table>
      </div>
      <div class="m-t-3 w-full table-overflow">
        <table class="w-full " style="background-color: #ffdb5a;">
          <tr>
            <td>route_id</td>
            <td>Status</td>
            <td>Port name</td>
            <td>Final load</td>
            <td>Freight net</td>
            <td>Liner in costs</td>
            <td>Liner out costs</td>
            <td>Load time</td>
            <td>Disch time</td>
            <td>Loadtime+tt</td>
            <td>Distime+tt</td>
            <td>IFO(stay loadport).mt</td>
            <td>IFO(stay disport).mt</td>
            <td>MGO(stay loadport)</td>
            <td>MGO(stay disport)</td>
            <td>IFO load(eca terms)</td>
            <td>IFO disport(eca terms)</td>
            <td>MGO loadport(eca terms)</td>
            <td>MGO disport(eca terms)</td>
          </tr>
          <tr v-for="(row, i) in tableAdditionalInfoBssLoadedCargoes" :key="'l23ss'+i">
            <td>{{row.route_id}}</td>
            <td>{{row.status}}</td>
            <td>{{row.port_name}}</td>
            <td>{{row.final_load}}</td>
            <td>{{row.freight_net}}</td>
            <td>{{row.liner_in_costs}}</td>
            <td>{{row.liner_out_costs}}</td>
            <td>{{row.load_time}}</td>
            <td>{{row.disch_time}}</td>
            <td>{{row.loadtime_tt}}</td>
            <td>{{row.distime_tt}}</td>
            <td>{{row.ifo_stay_loadport}}</td>
            <td>{{row.ifo_stay_disport}}</td>
            <td>{{row.mgo_stay_loadport}}</td>
            <td>{{row.mgo_stay_disport}}</td>
            <td>{{row.ifo_load_eca_terms}}</td>
            <td>{{row.ifo_disport_eca_terms}}</td>
            <td>{{row.mgo_loadport_eca_terms}}</td>
            <td>{{row.mgo_disport_eca_terms}}</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>{{getTotalFinalLoad}}</td>
            <td>{{getTotalFreightNet}}</td>
            <td>{{getTotalLinerInCosts}}</td>
            <td>{{getTotalLinerOutCosts}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
      <div class="m-t-3 w-full table-overflow">
        <table class="w-full " style="background-color: #ffdb5a;">
          <tr>
            <td>LAD/BALL</td>
            <td>IFO layExD</td>
            <td>MGO layExD</td>
            <td></td>
            <td>IFO layExD Eco/Noneco</td>
            <td>MGO layExD Eco/Noneco</td>
            <td style="background-color: #fff;"></td>
            <td>Time NON_ECA</td>
            <td>Time ECA</td>
            <td>Total time</td>
            <td>IFO</td>
            <td>MGO</td>
            <td>IFO COST</td>
            <td>MSGO COST</td>
            <td>HIRE COST</td>
            <td>Bunker+hire</td>
            <td>IFO mt</td>
            <td>MGO mt</td>
          </tr>
          <tr v-for="(row, i) in tableFullSpeed" :key="'l2s13ss'+i">
            <td>{{ row.type_lad_ball }}</td>
            <td>{{ row.ifo_layExD }}</td>
            <td>{{ row.mgo_layExD }}</td>
            <td></td>
            <td>{{row.ifo_layExD_eca_non_eca}}</td>
            <td>{{row.mgo_layExD_eca_non_eca}}</td>
            <td style="background-color: #fff;"></td>
            <td style="background-color: #fff;">{{row[fuelType+'_time_non_eca']}}</td>
            <td style="background-color: #fff;">{{row[fuelType+'_time_eca']}}</td>
            <td style="background-color: #fff;">{{row[fuelType+'_total_time']}}</td>
            <td style="background-color: #fff;">{{row[fuelType+'_ifo_eca_non_eca']}}</td>
            <td style="background-color: #fff;">{{row[fuelType+'_mgo_eca_non_eca']}}</td>
            <td style="background-color: #fff;">{{row[fuelType+'_ifo_cost']}}</td>
            <td style="background-color: #fff;">{{row[fuelType+'_msgo_cost']}}</td>
            <td style="background-color: #fff;">{{row[fuelType+'_hire_cost']}}</td>
            <td style="background-color: #fff;">{{row[fuelType+'_bunker_plus_hire']}}</td>
            <td v-if="Array.isArray(getActiveVesselRoutes) === true && getActiveVesselRoutes.length && getActiveVesselRoutes[i]">{{getActiveVesselRoutes[i][fuelType+'_ifo_mt']}}</td>
            <td v-if="Array.isArray(getActiveVesselRoutes) === true && getActiveVesselRoutes.length && getActiveVesselRoutes[i]">{{getActiveVesselRoutes[i][fuelType+'_mgo_mt']}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{getTotalTimeNonEca[fuelType]}}</td>
            <td>{{getTotalTimeEca[fuelType]}}</td>
            <td></td>
            <td>{{getTotalIfoEcaNonEca[fuelType]}}</td>
            <td>{{getTotalMgoEcaNonEca[fuelType]}}</td>
            <td>{{getTotalIfoCost[fuelType]}}</td>
            <td>{{getTotalMsgoCost[fuelType]}}</td>
            <td>{{getTotalHireCost[fuelType]}}</td>
            <td>{{getTotalBunkerPlusHire[fuelType]}}</td>
          </tr>
        </table>
        <table class="m-t-3">
          <tr>
            <td>Costs full, USD</td>
            <td> </td>
          </tr>
          <tr>
            <td>bunker mgo</td>
            <td>{{tableCostsFull[fuelType+'_bunker_mgo']}}</td>
          </tr>
          <tr>
            <td>bunker ifo</td>
            <td>{{tableCostsFull[fuelType+'_bunker_ifo']}}</td>
          </tr>
          <tr>
            <td>hire costs</td>
            <td style="background-color: #ffdb5a;">{{tableCostsFull[fuelType+'_hire_costs']}}</td>
          </tr>
          <tr>
            <td>disbursements</td>
            <td style="background-color: #ffdb5a;">{{tableCostsFull[fuelType+'_disbursements']}}</td>
          </tr>
          <tr>
            <td>other costs</td>
            <td style="background-color: #ffdb5a;">{{tableCostsFull.other_costs}}</td>
          </tr>
          <tr>
            <td>liner in/out</td>
            <td style="background-color: #ffdb5a;">{{tableCostsFull.liner_in_out}}</td>
          </tr>
          <tr>
            <td>total costs</td>
            <td style="background-color: #ffdb5a;">{{tableCostsFull[fuelType+'_total_costs']}}</td>
          </tr>
          <tr></tr>
          <tr>
            <td>tce costs</td>
            <td style="background-color: #ffdb5a;">{{tableCostsFull[fuelType+'_tce_costs']}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>results</td>
            <td>{{tableCostsFull[fuelType+'_results']}}</td>
          </tr>
          <tr>
            <td>Freight BEP</td>
            <td>{{tableCostsFull[fuelType+'_freight_bep']}}</td>
          </tr>
          <tr>
            <td>Voyage days</td>
            <td>{{ tableCostsFull[fuelType+'_voyage_days'] }}</td>
          </tr>
          <tr>
            <td>Hire BEP</td>
            <td>{{tableCostsFull[fuelType+'_hire_bep']}}</td>
          </tr>
        </table>
      </div>
    </template>


    <div class="p-t-4"></div>

    <fast-calc v-if="isFastCalc" :isOpen="isFastCalc" :vessel="getActiveVessel" @close="isFastCalc = false"/>
    <modal-slots class="map-view-modal" :showMod="showModalMap" @close="closeModalMap">
      <template v-slot:title>
        <p class="fz20 fw500 text-dark">Map View</p>
      </template>
      <map-stream ref="mapStream" :render="resizeMapKey" class="map-component"></map-stream>
    </modal-slots>
  </div>
</template>

<script>
import calcVesselsList from '@/components/calc/calcVesselsList'
import calcVesselsDetail from '@/components/calc/calcVesselsDetail'
import calcVesselsSpeed from '@/components/calc/calcVesselsSpeed'
import calcVesselsTotalTimes from '@/components/calc/calcVesselsTotalTimes'
import calcVesselsDotsRow from '@/components/calc/calcVesselsDotsRow'
import calcVesselsDotsTotal from '@/components/calc/calcVesselsDotsTotal'
import calcVesselsDotsHead from '@/components/calc/calcVesselsDotsHead'
import fastCalc from '@/components/calc/fastCalc'
import draggable from 'vuedraggable'
import moment from 'moment'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import AppLoader from "@/components/loader";
import CanalCode from "@/components/canal-code";
import ModalSlots from "@/components/modal/modal-slots";
import MapStream from "@/views/map/mapStream";
import SelectWhite from "@/components/select-white";
import CargoList from "@/components/calc/cargoTable/cargo-list";
import VesselSelectedAndCalcType from "@/components/calc/cargoTable/vessel-selected-and-calc-type";

export default {
  props: {
    exportPdf: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    VesselSelectedAndCalcType,
    CargoList,
    SelectWhite,
    MapStream,
    ModalSlots,
    CanalCode,
    AppLoader,
    calcVesselsSpeed,
    calcVesselsList,
    calcVesselsDetail,
    calcVesselsTotalTimes,
    calcVesselsDotsTotal,
    calcVesselsDotsHead,
    calcVesselsDotsRow,
    fastCalc,
    draggable
  },
  data() {
    return {
      notClearLoadTable: false,
      timerId: null,
      showLoaderTable: false,
      isDisabled: false,

      resizeMapKey: -23487324,
      showModalMap: false,
      isFastCalc: false,
      cargos: [],
      vessels: [],
      routes: [],
      copyRoutes: [],
      selectedId: null,
      fuelType: 'full',
      fuelList: ['full', 'eco', 'slow', 'optimal'],
      timeout: null,
      emptyDot: {
        quantity: null,
        intake: null,
        route_v: '2', // version with routes tab
        route_id: null,
        ordering: null,
        status: 'passing',
        dist: null,
        seca: null,
        has_seca: false,
        from_port: null,
        draft_restr: null,
        disbursement: null,
        port_stay: null,
        lay_ex: null,
        is_full: false,
        is_eco: false,
        is_slow: false,
        optimal_speed: null,

        full_streaming_days: null,
        eco_streaming_days: null,
        slow_streaming_days: null,
        optimal_streaming_days: null,
        full_streaming_time: null,
        eco_streaming_time: null,
        slow_streaming_time: null,
        optimal_streaming_time: null,

        full_total_time: null,
        eco_total_time: null,
        slow_total_time: null,
        optimal_total_time: null,

        distime_tt: null,
        loadtime_tt: null,

        full_arrival_date: null,
        eco_arrival_date: null,
        slow_arrival_date: null,
        optimal_arrival_date: null,
        full_arrival_time: null,
        eco_arrival_time: null,
        slow_arrival_time: null,
        optimal_arrival_time: null,

        full_departure_date: null,
        eco_departure_date: null,
        slow_departure_date: null,
        optimal_departure_date: null,
        full_departure_time: null,
        eco_departure_time: null,
        slow_departure_time: null,
        optimal_departure_time: null,

        full_result: null,
        eco_result: null,
        slow_result: null,
        optimal_result: null,
        
        full_ifo_mt: null,
        eco_ifo_mt: null,
        slow_ifo_mt: null,
        optimal_ifo_mt: null,
        
        full_mgo_mt: null,
        eco_mgo_mt: null,
        slow_mgo_mt: null,
        optimal_mgo_mt: null,
        
        //port_name: null,
        port: null,
        cargos: null,

        index: null,
      },
      showLoader: false,
      codeCanal: null,
      piracyCodes: [
        {code: '000', description: 'The latest update of piracy area. Identified with code 009 as of\n' +
              'April, 2021'},
        {code: '001', description: 'The shortest path '},
        {code: '002', description: '250nm outer route from Somalia east coast'},
        {code: '003', description: '600NM outer route from Somalia east coast'},
        {code: '004', description: 'JWLA015(2nd Aug 2010)'},
        {code: '005', description: 'JWLA016(16th Dec 2010)'},
        {code: '006', description: 'JWLA016 Up to Mumbai'},
        {code: '007', description: 'JWLA016 Max Avoid Route'},
        {code: '008', description: 'JWLA023(14th Jun 2018)'},
        {code: '009', description: 'JWLA027(29th April 2021)'},
      ],
      piracyActive: {code: '001', description: 'The shortest path '},
      secaBypassOption: {option: 'normal', description: ''},
      secaCostRation: {option: 'normal', description: ''},
      secaBypassOptionList: [
        {option: 'none', description: ''},
        {option: 'normal', description: ''},
        {option: 'shortest', description: ''},
        // {option: 'optimized', description: ''},
      ],
      emptyRoute: {
        tab: null,
        ordering_index: 0,
        port: null,
        from_port: null,
        status: 'passing',
        dist: null,
        has_seca: false,
        seca: null
      },
      replaceMode: false,
      replaceVessel: null,
    }
  },
  watch: {
    selectedId: async function () {
      if(this.selectedId) {
        this.codeCanal = this.getActiveVessel.additional_info.canal_code
        this.cargos = []
        this.cargos = await this.axiosGetCalcCargoByIdVessel({idTab:this.$route.params.id, idVessel: this.selectedId})
        // this.setCorrectCargos()
        console.log('dopCalc setIntakeForRoutesInVessel', 'selectedId')
        // await this.setIntakeForRoutesInVessel(this.getActiveVessel)

      await this.onSetRoutesInfo(false, false)
        setTimeout(()=> {
          this.setIntakeForRoutesInVessel(this.getActiveVessel)
          this.checkTotalTimeRoutes()
          this.checkEmptyRoute()
        }, 1000)


      }
      if(!this.notClearLoadTable) {
        this.hideLoadTable()
      }


    },
    $route: async function() {
      if(localStorage.getItem('lastFuelTypeInCalc')){
        this.fuelType = localStorage.getItem('lastFuelTypeInCalc')
      }
      if(localStorage.getItem(this.$route.params.id+'piracyActive') && localStorage.getItem(this.$route.params.id+'piracyActive') !== undefined && localStorage.getItem(this.$route.params.id+'piracyActive') !== null) {
        this.piracyActive = JSON.parse(localStorage.getItem(this.$route.params.id+'piracyActive'))
      } else {
        this.piracyActive = {code: '001', description: 'The shortest path '}
      }
      if(localStorage.getItem(this.$route.params.id+'secaBypassOption') && localStorage.getItem(this.$route.params.id+'secaBypassOption') !== undefined && localStorage.getItem(this.$route.params.id+'secaBypassOption') !== null) {
        this.secaBypassOption = JSON.parse(localStorage.getItem(this.$route.params.id+'secaBypassOption'))
      } else {
        this.secaBypassOption = {option: 'normal', description: ''}
      }
      if(localStorage.getItem(this.$route.params.id+'secaCostRation') && localStorage.getItem(this.$route.params.id+'secaCostRation') !== undefined && localStorage.getItem(this.$route.params.id+'secaCostRation') !== null) {
        this.secaCostRation = JSON.parse(localStorage.getItem(this.$route.params.id+'secaCostRation'))
      } else {
        this.secaCostRation = {option: 'normal', description: ''}
      }
      this.showLoad()
      let vessel = await this.axiosGetCalcVessel(this.$route.params.id)
      // vessel.forEach(item=> {
      //   item.loadtime_tt = item.loadtime_tt ? item.loadtime_tt : null
      //   item.distime_tt = item.distime_tt ? item.distime_tt : null
      // })
      this.vessels = vessel;
      if(this.vessels.length === 0) {
        await this.onCreate('Invisible vessel - not show')
        // await this.onCreateRoutes(0)
        // await this.onCreateRoutes(0)
      }
      this.roundRoutesInVessel()
      this.setIndex()
      this.cargos = []
      this.cargos = await this.axiosGetCalcCargoByIdVessel({idTab:this.$route.params.id, idVessel: this.selectedId})
      this.routes = await this.axiosGetCalcRoute(this.$route.params.id)
      this.setCorrectCargos()

      if(this.vessels.filter(a=> a.name !== 'Invisible vessel - not show').length === 0) {
        if(!this.vessels[0].additional_info.routes.length) {
          let count = this.vessels[0].additional_info.routes.length
          for(let i=0; i < 4 - count; i++) {
            await this.onCreateRoutes(0)
          }
        }
      }
      this.hideLoad()
      this.checkRoutesAndCargos()

      // this.checkRoutes()
      // this.onSetRoutesInfo()

      this.checkTotalTimeRoutes()

      this.copyRoutes = this.getActiveVesselRoutes ? JSON.parse(JSON.stringify(this.getActiveVesselRoutes)) : []
      if(this.vessels.length){
        // if(this.vessels.find(a=> a.name == 'Invisible vessel - not show')) {
        //   let item = this.vessels.find(a=> a.name == 'Invisible vessel - not show')
        //   this.onSelect(item.id)
        // } else if(localStorage.getItem('lastActiveVesselInCalc')){
        //   let findItem = this.vessels.find(el=>el.id == localStorage.getItem('lastActiveVesselInCalc'))
        //   if(findItem)
        //     this.onSelect(findItem.id)
        //   else
        //     this.onSelect(this.vessels[0].id)
        // }else
        //   this.onSelect(this.vessels[0].id)
        let vesselsFiltered = this.vessels.filter(a=> a.name !== 'Invisible vessel - not show')
        let itemSelect = null
        if(vesselsFiltered.length !== 0) {
          itemSelect = vesselsFiltered[0]
        } else {
          itemSelect = this.vessels.find(a=> a.name == 'Invisible vessel - not show')
        }
          this.onSelect(itemSelect.id)

      }
      if(this.vessels.filter(a=> a.name !== 'Invisible vessel - not show').length === 0) {
        if(!this.vessels[0].additional_info.routes.length) {
          let count = this.vessels[0].additional_info.routes.length
          for(let i=0; i < 4 - count; i++) {
            await this.onCreateRoutes(0)
          }
        }
      }
      if(this.vessels[0].additional_info.routes.length == 4) {
        this.vessels[0].additional_info.routes[1].status = 'loading'
        this.vessels[0].additional_info.routes[2].status = 'discharging'
      }

       // this.setIntakeForRoutesInVessel(this.getActiveVessel)/**/
      this.setIntakeForRoutesInVesselLegacy(this.getActiveVessel)

    },
    piracyActive: function () {
      localStorage.setItem(this.$route.params.id+'piracyActive', JSON.stringify(this.piracyActive))
      this.setDistanceForRoutes();
      console.log('setDistanceForRoutes 1')
    },
    codeCanal: function() {
      this.setDistanceForRoutes();
      console.log('setDistanceForRoutes 2')
    },
    secaBypassOption: function () {
      localStorage.setItem(this.$route.params.id+'secaBypassOption', JSON.stringify(this.secaBypassOption))
      this.setDistanceForRoutes();
      console.log('setDistanceForRoutes 3')
    },
    secaCostRation: function () {
      localStorage.setItem(this.$route.params.id+'secaCostRation', JSON.stringify(this.secaCostRation))
      this.setDistanceForRoutes();
      console.log('setDistanceForRoutes 4')
    },
    fuelType: function() {
      this.setActiveFuelType(this.fuelType)
      console.log('dopCalc fuelType')

      this.setIntakeForRoutesInVessel(this.getActiveVessel)
      localStorage.setItem('lastFuelTypeInCalc', this.fuelType)
    },
    calcVesselActive: function () {
      if(this.calcVesselActive != this.selectedId) {
        this.showLoaderTable = true
        this.selectedId = this.calcVesselActive;
      }
    },
    vessels: {
      deep: true,
      handler(newVal, oldVal) {
        if(oldVal.length == 0) return false
        if(this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.onPatchVessels()
        }, 1000)
        //this.setVesselDataForPdf(this.vessels)
      }
    },
    watchesTabPrices: function() {
      this.onUpdatePriceInVessels()
    }
  },
  computed: {
    ...mapState({
      tabs: state => state.tabs.tabs,
      activeCreator: state => state.tabs.activeCreator,
      termsList: state => state.tabs.termsList,
      calcVesselActive: state => state.tabs.calcVesselActive,
      loadTable: state => state.tabsIntake.loadTable,
      dischTable: state => state.tabsIntake.dischTable,
      tableAdditionalInfoBssLoadedCargoes: state => state.tabsIntake.tableAdditionalInfoBssLoadedCargoes,
      tableFullSpeed: state => state.tabsIntake.tableFullSpeed,
      tableCostsFull: state => state.tabsIntake.tableCostsFull
    }),
    ...mapGetters(['getLoginData', 'getNumbFromString', 'getCurrentTab', 'getTotalFinalLoad', 'getTotalFreightNet', 'getTotalLinerInCosts', 'getTotalLinerOutCosts', 'getTotalBunkerPlusHire', 'getTotalHireCost', 'getTotalMsgoCost', 'getTotalIfoCost', 'getTotalMgoEcaNonEca', 'getTotalIfoEcaNonEca', 'getTotalTimeEca', 'getTotalTimeNonEca']),
    getLocation() {
      return window.location.href
    },
    activeTab() {
      return this.getCurrentTab(this.$route.params?.id)
    },
    ladBallRoutes() {
      if(this.getActiveVessel && this.getActiveVessel.additional_info) {
        let copyRoutesAll = JSON.parse(JSON.stringify(this.getActiveVessel.additional_info.routes))
        let arrayLadBall = []
        let intake = 0;
        let ladBool = false;
        copyRoutesAll.forEach((item, index)=> {
          if(index === 0) {
            arrayLadBall.push('ball')
          } else {
            if(ladBool) {
              arrayLadBall.push('lad ' + item.status)
            } else {
              arrayLadBall.push('ball ' + item.status)
            }
            if(item.status === 'loading') {
              ladBool = true;
              intake += parseFloat(item.intake) ? parseFloat(item.intake) : 0
            }
            if(item.status === 'discharging') {
              intake -= parseFloat(item.intake) ? parseFloat(item.intake) : 0
              if(intake === 0) {
                ladBool = false
              }
            }
          }
        })
        return arrayLadBall
      }
      return []
    },
    watchesTabPrices() {
      if(this.activeTab){
        return {
          vlsfo: this.activeTab.vlsfo,
          lsmgo: this.activeTab.lsmgo,
          hsfo: this.activeTab.hsfo
        }
      }else{
        return false
      }
    },
    getActiveVessel() {
      return this.vessels.find(el=>el.id==this.selectedId)
    },
    getActiveVesselRoutes: {
      get: function() {
        return this.getActiveVessel?.additional_info?.routes
      },
      set: function (newValue) {
        this.getActiveVessel.additional_info.routes = newValue
      }
    },
    ifAllowEdit() {
      let isCreator
      if(this.activeTab?.creator){
        isCreator = this.activeTab?.creator?.id === this.getLoginData.data.user?.id
      }else{
        let folders = this.tabs.filter(el=>el.tab_type == 'group')
        let currentFolder = folders.find(el=>{
          return el.list_in_group.find(tab=>tab.id == this.activeTab?.id)
        })
        isCreator = currentFolder?.creator?.id === this.getLoginData.data.user?.id
      }

      return this.$route.path.indexOf('archive') === -1 && isCreator
    },

    getRoutesForVessels() {
      let routes = []
      routes.push({...this.emptyDot})//delivery

      this.routes.forEach(route=>{
        routes.push({...this.createNewRoute(route)})
      })

      routes.push({...this.emptyDot})//redelivery
      return routes
    },
  },
  methods: {
    ...mapActions([
        'axiosGetCalcVesselById', 'axiosGetShipTemplateById',
        'axiosGetCalcCargoByIdVessel',
      'axiosGetCalcVessel', 
      'axiosPinCalcVessel', 
      'axiosPostCalcVessel',
      'axiosDeleteCalcVessel', 
      'axiosPatchCalcVessel',
      'axiosGetCalcCargo',
      'axiosGetRouteDistance',
      'axiosGetCalcRoute',
      'calcIntakeForVessel',
        'calcAdditionalyInfoForVessel',
        'axiosPatchCalcRoute', 'axiosPostCalcRoute', 'axiosDeleteCalcRoute', 'axiosPatchTab', 'axiosChangeSearchShipTemplate', 'axiosCalculatorVesselsDuplicate'
    ]),
    ...mapMutations(['addTip', 'setActiveFuelType', 'showLoad', 'hideLoad', "setCalcVesselActive", "setVesselDataForPdf"]),
    hideLoadTable() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(async ()=> {
        await this.$nextTick()
        this.showLoaderTable = false
        this.notClearLoadTable = false
      }, this.notClearLoadTable ? 2050 : 1200)
    },
    onReplaceVessel(vessel) {
      this.replaceVessel = vessel
      setTimeout(()=> {
        this.replaceMode = true
      }, 200)
    },
    replaceModeOff() {
      setTimeout(()=> {
        this.replaceMode = false
      }, 150)

    },
    async onReplaceSaveVessel(pin) {
      this.showLoaderTable = true
      this.notClearLoadTable = true
      let itemTo = this.replaceVessel
      if(pin[0].vessel_type == 'parsed') {
        let vesselCopy = await this.axiosGetShipTemplateById(pin[0].id)
        let name = itemTo["name"]
        itemTo["name"]=vesselCopy['name']
        itemTo["dwt"]=vesselCopy["dwt"] ? vesselCopy["dwt"] : itemTo['dwt']
        itemTo["year"]=vesselCopy["built_year"] ? vesselCopy["built_year"] : itemTo['year']
        itemTo["hire"]=vesselCopy["hire"] ? vesselCopy["hire"] : itemTo['hire']
        itemTo["ballast_bonus"]=vesselCopy["ballast_bonus"] ? vesselCopy["ballast_bonus"] : itemTo['ballast_bonus']
        itemTo["additional_commission"]=vesselCopy["additional_commission"] ? vesselCopy["additional_commission"] : itemTo['additional_commission']
        itemTo["date"]=vesselCopy["date"] ? vesselCopy["date"] : itemTo['date']
        itemTo["time"]=vesselCopy["time"] ? vesselCopy["time"] : itemTo['time']
        itemTo["expenses"]=vesselCopy["expenses"] ? vesselCopy["expenses"] : itemTo['expenses']
        itemTo["mins_cost"]=vesselCopy["mins_cost"] ? vesselCopy["mins_cost"] : itemTo['mins_cost']
        itemTo["insurance"]=vesselCopy["insurance"] ? vesselCopy["insurance"] : itemTo['insurance']
        itemTo["other"]=vesselCopy["other"] ? vesselCopy["other"] : itemTo['other']
        itemTo["steaming_day"]=vesselCopy["steaming_day"] ? vesselCopy["steaming_day"] : itemTo['steaming_day']
        itemTo["steaming_hour"]=vesselCopy["steaming_hour"] ? vesselCopy["steaming_hour"] : itemTo['steaming_hour']
        itemTo["steaming_amount"]=vesselCopy["steaming_amount"] ? vesselCopy["steaming_amount"] : itemTo['steaming_amount']
        itemTo["full_sp_ball"]=vesselCopy["full_sp_ball"] ? vesselCopy["full_sp_ball"] : itemTo['full_sp_ball']
        itemTo["full_ifo_ball"]=vesselCopy["full_ifo_ball"] ? vesselCopy["full_ifo_ball"] : itemTo['full_ifo_ball']
        itemTo["full_mgo_ball"]=vesselCopy["full_mgo_ball"] ? vesselCopy["full_mgo_ball"] : itemTo['full_mgo_ball']
        itemTo["full_sp_lad"]=vesselCopy["full_sp_lad"] ? vesselCopy["full_sp_lad"] : itemTo['full_sp_lad']
        itemTo["full_ifo_lad"]=vesselCopy["full_ifo_lad"] ? vesselCopy["full_ifo_lad"] : itemTo['full_ifo_lad']
        itemTo["full_mgo_lad"]=vesselCopy["full_mgo_lad"] ? vesselCopy["full_mgo_lad"] : itemTo['full_mgo_lad']
        itemTo["eco_sp_ball"]=vesselCopy["eco_sp_ball"] ? vesselCopy["eco_sp_ball"] : itemTo['eco_sp_ball']
        itemTo["eco_ifo_ball"]=vesselCopy["eco_ifo_ball"] ? vesselCopy["eco_ifo_ball"] : itemTo['eco_ifo_ball']
        itemTo["eco_mgo_ball"]=vesselCopy["eco_mgo_ball"] ? vesselCopy["eco_mgo_ball"] : itemTo['eco_mgo_ball']
        itemTo["eco_sp_lad"]=vesselCopy["eco_sp_lad"] ? vesselCopy["eco_sp_lad"] : itemTo['eco_sp_lad']
        itemTo["eco_ifo_lad"]=vesselCopy["eco_ifo_lad"] ? vesselCopy["eco_ifo_lad"] : itemTo['eco_ifo_lad']
        itemTo["eco_mgo_lad"]=vesselCopy["eco_mgo_lad"] ? vesselCopy["eco_mgo_lad"] : itemTo['eco_mgo_lad']
        itemTo["slow_sp_ball"]=vesselCopy["slow_sp_ball"] ? vesselCopy["slow_sp_ball"] : itemTo['slow_sp_ball']
        itemTo["slow_ifo_ball"]=vesselCopy["slow_ifo_ball"] ? vesselCopy["slow_ifo_ball"] : itemTo['slow_ifo_ball']
        itemTo["slow_mgo_ball"]=vesselCopy["slow_mgo_ball"] ? vesselCopy["slow_mgo_ball"] : itemTo['slow_mgo_ball']
        itemTo["slow_sp_lad"]=vesselCopy["slow_sp_lad"] ? vesselCopy["slow_sp_lad"] : itemTo['slow_sp_lad']
        itemTo["slow_ifo_lad"]=vesselCopy["slow_ifo_lad"] ? vesselCopy["slow_ifo_lad"] : itemTo['slow_ifo_lad']
        itemTo["slow_mgo_lad"]=vesselCopy["slow_mgo_lad"] ? vesselCopy["slow_mgo_lad"] : itemTo['slow_mgo_lad']
        itemTo["ifo_idle"]=vesselCopy["ifo_idle"] ? vesselCopy["ifo_idle"] : itemTo['ifo_idle']
        itemTo["ifo_wk"]=vesselCopy["ifo_wk"] ? vesselCopy["ifo_wk"] : itemTo['ifo_wk']
        itemTo["mgo_idle"]=vesselCopy["mgo_idle"] ? vesselCopy["mgo_idle"] : itemTo['mgo_idle']
        itemTo["mgo_wk"]=vesselCopy["mgo_wk"] ? vesselCopy["mgo_wk"] : itemTo['mgo_wk']
        itemTo["dwcc"]=vesselCopy["dwcc"] ? vesselCopy["dwcc"] : itemTo['dwcc']
        itemTo["draft"]=vesselCopy["draft"] ? vesselCopy["draft"] : itemTo['draft']
        itemTo["grain"]=vesselCopy["grain"] ? vesselCopy["grain"] : itemTo['grain']
        itemTo["tpc"]=vesselCopy["tpc"] ? vesselCopy["tpc"] : itemTo['tpc']
        itemTo["constants"]=vesselCopy["constants"] ? vesselCopy["constants"] : itemTo['constants']
        itemTo["bob"]=vesselCopy["bob"] ? vesselCopy["bob"] : itemTo['bob']
        itemTo["is_changed_vlsfo"]=vesselCopy["is_changed_vlsfo"] ? vesselCopy["is_changed_vlsfo"] : itemTo['is_changed_vlsfo']
        itemTo["is_changed_hsfo"]=vesselCopy["is_changed_hsfo"] ? vesselCopy["is_changed_hsfo"] : itemTo['is_changed_hsfo']
        itemTo["is_changed_lsmgo"]=vesselCopy["is_changed_lsmgo"] ? vesselCopy["is_changed_lsmgo"] : itemTo['is_changed_lsmgo']
        itemTo["vlsfo_price"]=vesselCopy["vlsfo_price"] ? vesselCopy["vlsfo_price"] : itemTo['vlsfo_price']
        itemTo["hsfo_price"]=vesselCopy["hsfo_price"] ? vesselCopy["hsfo_price"] : itemTo['hsfo_price']
        itemTo["lsmgo_price"]=vesselCopy["lsmgo_price"] ? vesselCopy["lsmgo_price"] : itemTo['lsmgo_price']
        itemTo["fresh_water"]=vesselCopy["fresh_water"] ? vesselCopy["fresh_water"] : itemTo['fresh_water']
        itemTo["scrubber"]=vesselCopy["scrubber"] ? vesselCopy["scrubber"] : itemTo['scrubber']
        itemTo["is_favorite"]=vesselCopy["is_favorite"] ? vesselCopy["is_favorite"] : itemTo['is_favorite']
        itemTo["can_used_as_template"]=itemTo["can_used_as_template"] ? itemTo["itemTocan_used_as_template"] : false
        itemTo["full_result"]=vesselCopy["full_result"] ? vesselCopy["full_result"] : itemTo['full_result']
        itemTo["full_hire"]=vesselCopy["full_hire"] ? vesselCopy["full_hire"] : itemTo['full_hire']
        itemTo["full_freight"]=vesselCopy["full_freight"] ? vesselCopy["full_freight"] : itemTo['full_freight']
        itemTo["full_voyage_days"]=vesselCopy["full_voyage_days"] ? vesselCopy["full_voyage_days"] : itemTo['full_voyage_days']
        itemTo["optimal_result"]=vesselCopy["optimal_result"] ? vesselCopy["optimal_result"] : itemTo['optimal_result']
        itemTo["optimal_hire"]=vesselCopy["optimal_hire"] ? vesselCopy["optimal_hire"] : itemTo['optimal_hire']
        itemTo["optimal_freight"]=vesselCopy["optimal_freight"] ? vesselCopy["optimal_freight"] : itemTo['optimal_freight']
        itemTo["optimal_voyage_days"]=vesselCopy["optimal_voyage_days"] ? vesselCopy["optimal_voyage_days"] : itemTo['optimal_voyage_days']
        itemTo["eco_result"]=vesselCopy["eco_result"] ? vesselCopy["eco_result"] : itemTo['eco_result']
        itemTo["eco_hire"]=vesselCopy["eco_hire"] ? vesselCopy["eco_hire"] : itemTo['eco_hire']
        itemTo["eco_freight"]=vesselCopy["eco_freight"] ? vesselCopy["eco_freight"] : itemTo['eco_freight']
        itemTo["eco_voyage_days"]=vesselCopy["eco_voyage_days"] ? vesselCopy["eco_voyage_days"] : itemTo['eco_voyage_days']
        itemTo["slow_result"]=vesselCopy["slow_result"] ? vesselCopy["slow_result"] : itemTo['slow_result']
        itemTo["slow_hire"]=vesselCopy["slow_hire"] ? vesselCopy["slow_hire"] : itemTo['slow_hire']
        itemTo["slow_freight"]=vesselCopy["slow_freight"] ? vesselCopy["slow_freight"] : itemTo['slow_freight']
        itemTo["slow_voyage_days"]=vesselCopy["slow_voyage_days"] ? vesselCopy["slow_voyage_days"] : itemTo['slow_voyage_days']
        itemTo["time_balast"]=vesselCopy["time_balast"] ? vesselCopy["time_balast"] : itemTo['time_balast']
        itemTo["time_laden"]=vesselCopy["time_laden"] ? vesselCopy["time_laden"] : itemTo['time_laden']
        itemTo["time_in_port"]=vesselCopy["time_in_port"] ? vesselCopy["time_in_port"] : itemTo['time_in_port']
        itemTo["total_time"]=vesselCopy["total_time"] ? vesselCopy["total_time"] : itemTo['total_time']
        itemTo["vessel"]=vesselCopy["id"]
        itemTo['additional_info']['duplicateNumber'] = null
        this.checkCorrectNumberDuplicates(name)

      } else {
        let vesselCopy = await this.axiosGetCalcVesselById(pin[0].id)
        let name = itemTo["name"]
        itemTo["name"]=vesselCopy['name']
        itemTo["dwt"]=vesselCopy["dwt"] ? vesselCopy["dwt"] : itemTo['dwt']
        itemTo["year"]=vesselCopy["year"] ? vesselCopy["year"] : itemTo['year']
        itemTo["hire"]=vesselCopy["hire"] ? vesselCopy["hire"] : itemTo['hire']
        itemTo["ballast_bonus"]=vesselCopy["ballast_bonus"] ? vesselCopy["ballast_bonus"] : itemTo['ballast_bonus']
        itemTo["additional_commission"]=vesselCopy["additional_commission"] ? vesselCopy["additional_commission"] : itemTo['additional_commission']
        itemTo["date"]=vesselCopy["date"] ? vesselCopy["date"] : itemTo['date']
        itemTo["time"]=vesselCopy["time"] ? vesselCopy["time"] : itemTo['time']
        itemTo["expenses"]=vesselCopy["expenses"] ? vesselCopy["expenses"] : itemTo['expenses']
        itemTo["mins_cost"]=vesselCopy["mins_cost"] ? vesselCopy["mins_cost"] : itemTo['mins_cost']
        itemTo["insurance"]=vesselCopy["insurance"] ? vesselCopy["insurance"] : itemTo['insurance']
        itemTo["other"]=vesselCopy["other"] ? vesselCopy["other"] : itemTo['other']
        itemTo["steaming_day"]=vesselCopy["steaming_day"] ? vesselCopy["steaming_day"] : itemTo['steaming_day']
        itemTo["steaming_hour"]=vesselCopy["steaming_hour"] ? vesselCopy["steaming_hour"] : itemTo['steaming_hour']
        itemTo["steaming_amount"]=vesselCopy["steaming_amount"] ? vesselCopy["steaming_amount"] : itemTo['steaming_amount']
        itemTo["full_sp_ball"]=vesselCopy["full_sp_ball"] ? vesselCopy["full_sp_ball"] : itemTo['full_sp_ball']
        itemTo["full_ifo_ball"]=vesselCopy["full_ifo_ball"] ? vesselCopy["full_ifo_ball"] : itemTo['full_ifo_ball']
        itemTo["full_mgo_ball"]=vesselCopy["full_mgo_ball"] ? vesselCopy["full_mgo_ball"] : itemTo['full_mgo_ball']
        itemTo["full_sp_lad"]=vesselCopy["full_sp_lad"] ? vesselCopy["full_sp_lad"] : itemTo['full_sp_lad']
        itemTo["full_ifo_lad"]=vesselCopy["full_ifo_lad"] ? vesselCopy["full_ifo_lad"] : itemTo['full_ifo_lad']
        itemTo["full_mgo_lad"]=vesselCopy["full_mgo_lad"] ? vesselCopy["full_mgo_lad"] : itemTo['full_mgo_lad']
        itemTo["eco_sp_ball"]=vesselCopy["eco_sp_ball"] ? vesselCopy["eco_sp_ball"] : itemTo['eco_sp_ball']
        itemTo["eco_ifo_ball"]=vesselCopy["eco_ifo_ball"] ? vesselCopy["eco_ifo_ball"] : itemTo['eco_ifo_ball']
        itemTo["eco_mgo_ball"]=vesselCopy["eco_mgo_ball"] ? vesselCopy["eco_mgo_ball"] : itemTo['eco_mgo_ball']
        itemTo["eco_sp_lad"]=vesselCopy["eco_sp_lad"] ? vesselCopy["eco_sp_lad"] : itemTo['eco_sp_lad']
        itemTo["eco_ifo_lad"]=vesselCopy["eco_ifo_lad"] ? vesselCopy["eco_ifo_lad"] : itemTo['eco_ifo_lad']
        itemTo["eco_mgo_lad"]=vesselCopy["eco_mgo_lad"] ? vesselCopy["eco_mgo_lad"] : itemTo['eco_mgo_lad']
        itemTo["slow_sp_ball"]=vesselCopy["slow_sp_ball"] ? vesselCopy["slow_sp_ball"] : itemTo['slow_sp_ball']
        itemTo["slow_ifo_ball"]=vesselCopy["slow_ifo_ball"] ? vesselCopy["slow_ifo_ball"] : itemTo['slow_ifo_ball']
        itemTo["slow_mgo_ball"]=vesselCopy["slow_mgo_ball"] ? vesselCopy["slow_mgo_ball"] : itemTo['slow_mgo_ball']
        itemTo["slow_sp_lad"]=vesselCopy["slow_sp_lad"] ? vesselCopy["slow_sp_lad"] : itemTo['slow_sp_lad']
        itemTo["slow_ifo_lad"]=vesselCopy["slow_ifo_lad"] ? vesselCopy["slow_ifo_lad"] : itemTo['slow_ifo_lad']
        itemTo["slow_mgo_lad"]=vesselCopy["slow_mgo_lad"] ? vesselCopy["slow_mgo_lad"] : itemTo['slow_mgo_lad']
        itemTo["ifo_idle"]=vesselCopy["ifo_idle"] ? vesselCopy["ifo_idle"] : itemTo['ifo_idle']
        itemTo["ifo_wk"]=vesselCopy["ifo_wk"] ? vesselCopy["ifo_wk"] : itemTo['ifo_wk']
        itemTo["mgo_idle"]=vesselCopy["mgo_idle"] ? vesselCopy["mgo_idle"] : itemTo['mgo_idle']
        itemTo["mgo_wk"]=vesselCopy["mgo_wk"] ? vesselCopy["mgo_wk"] : itemTo['mgo_wk']
        itemTo["dwcc"]=vesselCopy["dwcc"] ? vesselCopy["dwcc"] : itemTo['dwcc']
        itemTo["draft"]=vesselCopy["draft"] ? vesselCopy["draft"] : itemTo['draft']
        itemTo["grain"]=vesselCopy["grain"] ? vesselCopy["grain"] : itemTo['grain']
        itemTo["tpc"]=vesselCopy["tpc"] ? vesselCopy["tpc"] : itemTo['tpc']
        itemTo["constants"]=vesselCopy["constants"] ? vesselCopy["constants"] : itemTo['constants']
        itemTo["bob"]=vesselCopy["bob"] ? vesselCopy["bob"] : itemTo['bob']
        itemTo["is_changed_vlsfo"]=vesselCopy["is_changed_vlsfo"] ? vesselCopy["is_changed_vlsfo"] : itemTo['is_changed_vlsfo']
        itemTo["is_changed_hsfo"]=vesselCopy["is_changed_hsfo"] ? vesselCopy["is_changed_hsfo"] : itemTo['is_changed_hsfo']
        itemTo["is_changed_lsmgo"]=vesselCopy["is_changed_lsmgo"] ? vesselCopy["is_changed_lsmgo"] : itemTo['is_changed_lsmgo']
        itemTo["vlsfo_price"]=vesselCopy["vlsfo_price"] ? vesselCopy["vlsfo_price"] : itemTo['vlsfo_price']
        itemTo["hsfo_price"]=vesselCopy["hsfo_price"] ? vesselCopy["hsfo_price"] : itemTo['hsfo_price']
        itemTo["lsmgo_price"]=vesselCopy["lsmgo_price"] ? vesselCopy["lsmgo_price"] : itemTo['lsmgo_price']
        itemTo["fresh_water"]=vesselCopy["fresh_water"] ? vesselCopy["fresh_water"] : itemTo['fresh_water']
        itemTo["scrubber"]=vesselCopy["scrubber"] ? vesselCopy["scrubber"] : itemTo['scrubber']
        itemTo["is_favorite"]=vesselCopy["is_favorite"] ? vesselCopy["is_favorite"] : itemTo['is_favorite']
        itemTo["can_used_as_template"]=itemTo["can_used_as_template"] ? itemTo["itemTocan_used_as_template"] : false
        itemTo["full_result"]=vesselCopy["full_result"] ? vesselCopy["full_result"] : itemTo['full_result']
        itemTo["full_hire"]=vesselCopy["full_hire"] ? vesselCopy["full_hire"] : itemTo['full_hire']
        itemTo["full_freight"]=vesselCopy["full_freight"] ? vesselCopy["full_freight"] : itemTo['full_freight']
        itemTo["full_voyage_days"]=vesselCopy["full_voyage_days"] ? vesselCopy["full_voyage_days"] : itemTo['full_voyage_days']
        itemTo["optimal_result"]=vesselCopy["optimal_result"] ? vesselCopy["optimal_result"] : itemTo['optimal_result']
        itemTo["optimal_hire"]=vesselCopy["optimal_hire"] ? vesselCopy["optimal_hire"] : itemTo['optimal_hire']
        itemTo["optimal_freight"]=vesselCopy["optimal_freight"] ? vesselCopy["optimal_freight"] : itemTo['optimal_freight']
        itemTo["optimal_voyage_days"]=vesselCopy["optimal_voyage_days"] ? vesselCopy["optimal_voyage_days"] : itemTo['optimal_voyage_days']
        itemTo["eco_result"]=vesselCopy["eco_result"] ? vesselCopy["eco_result"] : itemTo['eco_result']
        itemTo["eco_hire"]=vesselCopy["eco_hire"] ? vesselCopy["eco_hire"] : itemTo['eco_hire']
        itemTo["eco_freight"]=vesselCopy["eco_freight"] ? vesselCopy["eco_freight"] : itemTo['eco_freight']
        itemTo["eco_voyage_days"]=vesselCopy["eco_voyage_days"] ? vesselCopy["eco_voyage_days"] : itemTo['eco_voyage_days']
        itemTo["slow_result"]=vesselCopy["slow_result"] ? vesselCopy["slow_result"] : itemTo['slow_result']
        itemTo["slow_hire"]=vesselCopy["slow_hire"] ? vesselCopy["slow_hire"] : itemTo['slow_hire']
        itemTo["slow_freight"]=vesselCopy["slow_freight"] ? vesselCopy["slow_freight"] : itemTo['slow_freight']
        itemTo["slow_voyage_days"]=vesselCopy["slow_voyage_days"] ? vesselCopy["slow_voyage_days"] : itemTo['slow_voyage_days']
        itemTo["time_balast"]=vesselCopy["time_balast"] ? vesselCopy["time_balast"] : itemTo['time_balast']
        itemTo["time_laden"]=vesselCopy["time_laden"] ? vesselCopy["time_laden"] : itemTo['time_laden']
        itemTo["time_in_port"]=vesselCopy["time_in_port"] ? vesselCopy["time_in_port"] : itemTo['time_in_port']
        itemTo["total_time"]=vesselCopy["total_time"] ? vesselCopy["total_time"] : itemTo['total_time']
        itemTo["vessel"]= null
        itemTo['additional_info']['duplicateNumber'] = null
        this.checkCorrectNumberDuplicates(name)
      }

      setTimeout(()=> {
        this.setIntakeForRoutesInVessel(this.getActiveVessel)
      }, 1000)
      this.hideLoadTable()
    },
    async changeScrubber() {
      await this.onSetRoutesInfo(false, false, true)
      setTimeout(()=> {
        this.setIntakeForRoutesInVessel(this.getActiveVessel)
        this.checkTotalTimeRoutes()
        // this.checkEmptyRoute()
        this.setHireAndFreightBepInVessel(this.getActiveVessel)

      }, 200)
    },
    checkCorrectNumberDuplicates(name) {
      let vessels = this.vessels.filter(a=> a.name == name && a.additional_info.duplicateNumber)
      if(vessels.length == 1) {
        vessels[0].additional_info.duplicateNumber = null
      } else {
        vessels.forEach((vessel, index) => {
          vessel.additional_info.duplicateNumber = index+1
        })
      }
    },
    async onDuplicateVessel(vessel) {
      this.showLoad()
      if(this.ifAllowEdit) {
        // vessel.name = vessel.name.trim()
        // let numberDuplicate = vessel.name.slice(vessel.name.lastIndexOf(' '), vessel.name.length)
        // console.log(numberDuplicate)
        // console.log(Number(numberDuplicate), Number.isInteger(Number(numberDuplicate)))
        // console.log(!Number.isInteger(Number(numberDuplicate)))
        // if(Number.isInteger(Number(numberDuplicate)) !== true) {
        //   vessel.name = vessel.name + ' 1'
        // }
        if(vessel['additional_info'] && !vessel['additional_info']['duplicateNumber']) {
          vessel['additional_info']['duplicateNumber'] = 1
        }
        // if(!vessel['additional_info']['canal_code']) {
        //   vessel['additional_info']['canal_code'] = '111'
        // }
        let NumbersAr = this.vessels.filter(el=> (''+el.name).indexOf(vessel.name) >= 0 && el['additional_info'] && el['additional_info']['duplicateNumber']).map(el=> {return Number(el['additional_info']['duplicateNumber'])})
        let numberVessel = Math.max(...NumbersAr)
        // this.vessels
        let routes = this.vessels?.length ? JSON.parse(JSON.stringify(this.vessels.find(a=> a.id == vessel.id).additional_info.routes)) : []
        routes.forEach(el=>{
          el.intake = null
          el.draft_restr = null
        })
        let idFrom = vessel.id
        let vesselCopy = JSON.parse(JSON.stringify(vessel))
        let canal_code = vesselCopy.additional_info.canal_code ? vesselCopy.additional_info.canal_code : '100'
        delete vesselCopy.additional_info
        delete vesselCopy.id
        delete vesselCopy.created_at
        delete vesselCopy.can_used_as_template
        delete vesselCopy.tab
        delete vesselCopy.updated_at
        delete vesselCopy.vessel


        let result = await this.axiosPinCalcVessel({
          data: [{
            id: null,
            vessel_type: null,
          }],
          tab: this.$route.params.id
        })
        let newVessel = result[0]
        newVessel['additional_info'] = {
          routes: routes,
          duplicateNumber: numberVessel + 1,
          canal_code: canal_code
        }

        // let can_used_as_template = newVessel.can_used_as_template

            newVessel = Object.assign({}, newVessel, vesselCopy)
        newVessel.can_used_as_template = false
        newVessel['is_duplicated'] = true
         await this.axiosCalculatorVesselsDuplicate({vessel_from_id: idFrom, vessel_to_id: newVessel.id})
        await this.updateRouteInfoForNew(newVessel, idFrom)
        this.hideLoad()

        this.vessels.push(newVessel)
        this.showLoaderTable = true
        this.notClearLoadTable = true
        this.selectedId = newVessel.id

        // await this.onSetRoutesInfo(false, true)
        this.onUpdatePriceInVessels()

        this.hideLoadTable()
      }
      return null
    },
    async updateRouteInfoForNew(newVessel, fromId) {
      // let newVessel = this.vessels[index]
      let vessel = await this.axiosGetCalcVesselById(fromId)
      // console.log('vessel is_duplicated', vessel)
      newVessel.additional_info.routes = vessel.additional_info.routes
      return await null
    },
    async saveTemplate() {
      this.showLoad()
      let form = {
        "year": this.getActiveVessel.year,
        "dwt": this.getActiveVessel.dwt,
        "draft": this.getActiveVessel.draft,
        "tpc": this.getActiveVessel.tpc,
        "grain": this.getActiveVessel.grain,
        "has_scrubber": this.getActiveVessel.scrubber,
        "full_sp_ball": this.getActiveVessel.full_sp_ball,
        "full_ifo_ball": this.getActiveVessel.full_ifo_ball,
        "full_mgo_ball": this.getActiveVessel.full_mgo_ball,
        "full_sp_lad": this.getActiveVessel.full_sp_lad,
        "full_ifo_lad": this.getActiveVessel.full_ifo_lad,
        "full_mgo_lad": this.getActiveVessel.full_mgo_lad,
        "eco_sp_ball": this.getActiveVessel.eco_sp_ball,
        "eco_ifo_ball": this.getActiveVessel.eco_ifo_ball,
        "eco_mgo_ball": this.getActiveVessel.eco_mgo_ball,
        "eco_sp_lad": this.getActiveVessel.eco_sp_lad,
        "eco_ifo_lad": this.getActiveVessel.eco_ifo_lad,
        "eco_mgo_lad": this.getActiveVessel.eco_mgo_lad,
        "slow_sp_ball": this.getActiveVessel.slow_sp_ball,
        "slow_ifo_ball": this.getActiveVessel.slow_ifo_ball,
        "slow_mgo_ball": this.getActiveVessel.slow_mgo_ball,
        "slow_sp_lad": this.getActiveVessel.slow_sp_lad,
        "slow_ifo_lad": this.getActiveVessel.slow_ifo_lad,
        "slow_mgo_lad": this.getActiveVessel.slow_mgo_lad,
        "ifo_idle": this.getActiveVessel.ifo_idle,
        "ifo_wk": this.getActiveVessel.ifo_wk,
        "mgo_idle": this.getActiveVessel.mgo_idle,
        "mgo_wk": this.getActiveVessel.mgo_wk,
      }
      await this.axiosChangeSearchShipTemplate({id: this.getActiveVessel.vessel ,data: form})
      this.hideLoad()
    },
    checkRoutesAndCargos() {
      // this.routes.forEach(dot=> {
      //   let IdsCargos = this.cargos.filter(a=> this.port && ( (a.loading_port && a.loading_port.id == this.port.id && dot.status === 'loading') || (a.discharging_port && a.discharging_port.id == this.port.id && dot.status === 'discharging'))).map(a=> {return a.id})
      //   this.cargos.forEach(cargo=> {
      //     if(cargo.loading_port_name.replace('#','') == dot.route_id) {
      //       console.log(1)
      //       cargo.loading_port = null
      //       cargo.loading_port_name = ''
      //       dot.cargos = dot.cargos.filter(a=> a !== dot.id && IdsCargos.indexOf(a) >= 0)
      //     }
      //     if(cargo.discharging_port_name.replace('#','') == dot.route_id) {
      //       cargo.discharging_port = null
      //       cargo.discharging_port_name = ''
      //       dot.cargos = dot.cargos.filter(a=> a !== this.dot.id && IdsCargos.indexOf(a) >= 0)
      //       console.log(2)
      //     }
      //   })
      // })


      // this.vessels.forEach(vessel => {
      //       vessel.additional_info.routes.filter(route=> {
      //         route.quantity = 0
      //         let relativeCargos = this.cargos.filter(cargo=>{
      //           let relativeRoute = cargo[route.status+'_port_name']
      //           if(!relativeRoute) return false
      //           relativeRoute = +relativeRoute.replace('#', '')
      //           return relativeRoute == route.route_id
      //         })
      //         relativeCargos.forEach(cargo => {
      //           route.quantity += cargo.ballast_voyage ? 0 : this.getQuantityFromCargo(cargo)
      //         })
      //         route.cargos = relativeCargos.map(el=>el.id)
      //         console.log(route.cargos)
      //       })
      // })
      if( this.getActiveVessel) {
        this.getActiveVessel.additional_info.routes.filter(route=> {
          route.quantity = 0
          let relativeCargos = (Array.isArray(this.cargos) == true ? this.cargos : []).filter(cargo=>{
            let relativeRoute = cargo[route.status+'_port_name']
            if(!relativeRoute) return false
            relativeRoute = +relativeRoute.replace('#', '')
            return relativeRoute == route.route_id
          })
          relativeCargos.forEach(cargo => {
            route.quantity += cargo.ballast_voyage ? 0 : this.getQuantityFromCargo(cargo)
          })
          route.cargos = relativeCargos.map(el=>el.id)
        })
      }

    },
    closeModalMap() {
      this.showModalMap = false
    },
    async calcCargo() {
      if(this.getActiveVessel) {
        console.log('dopCalc calcCargo')
        await this.onSetRoutesInfo(false, false, true)
        setTimeout(()=> {
          this.setIntakeForRoutesInVessel(this.getActiveVessel)
          this.checkTotalTimeRoutes()
          // this.checkEmptyRoute()
        }, 400)


        // this.checkRoutesAndCargos()
        // await this.onUpdateSecaInRoute(this.getActiveVessel, false)
        // setTimeout(()=> {
        //   await this.setIntakeForRoutesInVessel(this.getActiveVessel)
        //   this.checkChangePortStay(this.getActiveVessel)
        // }, 100)

        // this.onSetRoutesInfo()

        // this.checkTotalTimeRoutes()
        // this.checkEmptyRoute()

      }
    },
    async onBlurInfo(e, key) {
      if(e.target.value == this.activeTab[key]) return false
      if(parseFloat(e.target.value) == 0 || parseFloat(e.target.value)){
        this.isDisabled = true
        let saveVal = parseFloat(e.target.value)
        let saveObj = {id: this.activeTab?.id}
        saveObj[key] = saveVal
        this.activeTab[key] = saveVal
        e.target.value = saveVal
        await this.axiosPatchTab(saveObj)
        this.isDisabled = false
      }else{
        e.target.value = this.activeTab[key]
      }
    },
    loadMap() {
      this.showModalMap = true
      setTimeout(()=> {
        this.resizeMapKey++;
        // console.log('this.routes', this.routes)
        let error = false
        let routes = JSON.parse(JSON.stringify(this.getActiveVesselRoutes))
        if(routes.length >= 2) {
          if(!routes[0].port) {
            routes.shift()
          }
          if(!routes[routes.length -1].port){
            routes.pop()
          }
        }
        routes.filter(a=> a.route_id).forEach(a=> {
          if(!a.port) {
            error = true;
            return null;
          }
        })
        if(error) {
          this.addTip('All ports/canals must be filled')
          return null;
        }
        // let portsForAxios = []
        let arrayRoutes = routes.filter(a=> a.port)
        // arrayRoutes.forEach((rout, index)=> {
        //   if(index !== 0) {
        //     // console.log(this.getActiveVesselRoutes[index-1].port, this.getActiveVesselRoutes[index].port)
        //     portsForAxios.push({from_port:arrayRoutes[index-1].port.name, to_port:arrayRoutes[index].port.name, has_seca:arrayRoutes[index].has_seca ? 'True' : 'False'})
        //   }
        // })
        this.$refs.mapStream.getDistanceRouter({ seca_cost_ratio: this.secaCostRation ? this.secaCostRation.option : null, seca_bypass_option: this.secaBypassOption ? this.secaBypassOption.option: null,ports: arrayRoutes.map(a=>{return a.port.name}), piracyOption: this.piracyActive ? this.piracyActive.code : null, canalPassCode: this.codeCanal})
      }, 150)

    },
    async changeLayEx() {

      // await this.setIntakeForRoutesInVesselLegacy(this.getActiveVessel)
      // this.setVoyageDaysInVessel(this.getActiveVessel)
      // this.setHireAndFreightBepInVessel(this.getActiveVessel)
      // // await this.calcAdditionalyInfoForVessel({vessel: this.getActiveVessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
      // // await this.$nextTick()
      // // setTimeout(()=> {
      // await this.setIntakeForRoutesInVessel(this.getActiveVessel)
      // // this.checkTotalTimeRoutes()
      // // this.checkEmptyRoute()
      //
      //   this.setIfoMgoForRoutesInVessel(this.getActiveVessel)

      this.showLoaderTable = true
      this.setVoyageDaysInVessel(this.getActiveVessel)
      this.setHireAndFreightBepInVessel(this.getActiveVessel)
      await this.onSetRoutesInfo(false, false)
      setTimeout(()=> {
        this.setIntakeForRoutesInVessel(this.getActiveVessel)
        this.checkTotalTimeRoutes()
        // this.checkEmptyRoute()
        this.setIfoMgoForRoutesInVessel(this.getActiveVessel)
        this.showLoaderTable = false
      }, 300)
      // }, 300)
    },
    onCalcInVessel(vessel) {
      console.log('on-calc')
      this.onUpdateDataInVessel(vessel)
    },
    async onPinVessels(vessels) {
      if(!this.ifAllowEdit) return false

      let vesselsFiltered = this.vessels.filter(a=> a.name !== 'Invisible vessel - not show')
      let itemFrom = null
      if(vesselsFiltered.length !== 0) {
        itemFrom = vesselsFiltered[0]
      } else {
        itemFrom = this.vessels.find(a=> a.name == 'Invisible vessel - not show')
      }

      let result = await this.axiosPinCalcVessel({
        data: vessels.map(el => {
          return {
            id: el.id,
            vessel_type: el.vessel_type,
          }
        }),
        tab: this.$route.params.id
      })

      let oldRoutes = []
      if(this.vessels.length)
        oldRoutes = this.vessels[0].additional_info?.routes

      oldRoutes.forEach((el)=>{
        // if(i==0){
        //   el.port = null
        // }
        el.intake = null
        // el.disbursement = null
        el.draft_restr = null
      })


      result.forEach((el, index) => {
        if(oldRoutes?.length){
          el.additional_info.id_template = vessels[index].id
          // el.additional_info.routes = JSON.parse(JSON.stringify(oldRoutes))
          el.additional_info.routes = []
          el['additional_info']['duplicateNumber'] = null
          if(!el['additional_info']['canal_code']) {
            el['additional_info']['canal_code'] = '100'
          }
        }else{
          el.additional_info.id_template = vessels[index].id
          // el.additional_info.routes = JSON.parse(JSON.stringify(this.getRoutesForVessels))
          el.additional_info.routes = []
          el['additional_info']['duplicateNumber'] = null
          if(!el['additional_info']['canal_code']) {
            el['additional_info']['canal_code'] = '100'
          }
        }
      })

      // await this.$nextTick()
      //console.log(result);
      this.vessels.push(...result)
      this.showLoaderTable = true
      this.notClearLoadTable = true
      // this.selectedId = this.vessels[this.vessels.length - 1].id
      // if(this.vessels.length == 2 && this.vessels.filter(a=> a.name !== 'Invisible vessel - not show').length == 1) {
        this.moveFromWithInvisibleVessel(this.vessels[this.vessels.length - 1].id, itemFrom)
      // } else {
      //   await this.onCreateRoutes()
      //   await this.onCreateRoutes()
      //   await this.onCreateRoutes()
      //   await this.onCreateRoutes()
      //   this.onSetRoutesInfo()
      //   this.onUpdatePriceInVessels()
      // }

    },
    async onCreate(name) {//vessel
      if(this.ifAllowEdit) {
        // console.log('created')
        // let routes = this.vessels?.length ? JSON.parse(JSON.stringify(this.vessels[0].additional_info.routes)) : []
        // // console.log('routes', routes)
        // routes.forEach(el=>{
        //   // console.log('asdasd',el, el.disbursement)
        //   el.intake = null
        //   el.draft_restr = null
        //   // el.disbursement = el.disbursement
        // })
        let vesselsFiltered = this.vessels.filter(a=> a.name !== 'Invisible vessel - not show')
        let itemFrom = null
        if(vesselsFiltered.length !== 0) {
          itemFrom = vesselsFiltered[0]
        } else {
          itemFrom = this.vessels.find(a=> a.name == 'Invisible vessel - not show')
        }

        let newVessel = await this.axiosPostCalcVessel({
          name: name ? name : 'Created Vessel',
          tab: this.$route.params.id,
          additional_info: {
            routes: []
          }
        })
        newVessel['additional_info']['duplicateNumber'] = null
        if(!newVessel['additional_info']['canal_code']) {
          newVessel['additional_info']['canal_code'] = '100'
        }
        this.vessels.push(newVessel)
        this.showLoaderTable = true
        this.notClearLoadTable = true
        // this.setIndex()
        // if(this.vessels.length == 2 && this.vessels.filter(a=> a.name !== 'Invisible vessel - not show').length == 1) {
        //   console.log(1)
        if(name !== 'Invisible vessel - not show') {
          this.moveFromWithInvisibleVessel(newVessel.id, itemFrom)
        } else {
          this.selectedId = newVessel.id

          let item = this.vessels[this.vessels.length-1]
          if(!item['additional_info']['canal_code']) {
            item['additional_info']['canal_code'] = '100'
          }
          this.hideLoadTable()
        }
        // } else {
        //   await this.onCreateRoutes()
        //   await this.onCreateRoutes()
        //   await this.onCreateRoutes()
        //   await this.onCreateRoutes()
        //   this.onUpdatePriceInVessels()
        // }

        // this.onSelect(newVessel.id)
      }
      return null
    },
    async moveFromWithInvisibleVessel(idNewVessel, itemFrom) {
      if(this.vessels.filter(a=> a.name === 'Invisible vessel - not show').length != 0 && idNewVessel !== null && idNewVessel !== undefined) {
        this.showLoad()

        // eslint-disable-next-line no-unused-vars
        let itemTo = this.vessels.find(a=> a.id == idNewVessel)
        let vesselCopy = JSON.parse(JSON.stringify(itemFrom))
        // delete vesselCopy.additional_info
        // delete vesselCopy.id
        // delete vesselCopy.name
        // delete vesselCopy.created_at
        // delete vesselCopy.creator
        // delete vesselCopy.can_used_as_template
        // delete vesselCopy.tab
        // delete vesselCopy.updated_at
        // delete vesselCopy.vessel
        // itemTo = JSON.parse(JSON.stringify(Object.assign({}, itemTo, vesselCopy)))

        let res = await this.axiosCalculatorVesselsDuplicate({vessel_from_id: itemFrom.id, vessel_to_id: itemTo.id})
          if(res == true) {
            // await this.onDelete(itemFrom.id)
            let vessel = await this.axiosGetCalcVessel(this.$route.params.id)
            vessel.forEach(el=> {
              if(!el['additional_info']['duplicateNumber']) {
                el['additional_info']['duplicateNumber'] = null
              }
              if(!el['additional_info']['canal_code']) {
                el['additional_info']['canal_code'] = '100'
              }
            })
            this.vessels = vessel;
            this.cargos = await this.axiosGetCalcCargoByIdVessel({idTab:this.$route.params.id, idVessel: this.selectedId})
            this.setCorrectCargos()
            itemTo = this.vessels.find(a=> a.id == idNewVessel)
            itemTo["dwt"]=itemTo["dwt"] ?  itemTo["dwt"] : vesselCopy['dwt']
            itemTo["year"]=itemTo["year"] ? itemTo["year"] : vesselCopy['year']
            itemTo["hire"]=itemTo["hire"] ? itemTo["hire"] : vesselCopy['hire']
            itemTo["ballast_bonus"]=itemTo["ballast_bonus"] ? itemTo["ballast_bonus"] : vesselCopy['ballast_bonus']
            itemTo["additional_commission"]=itemTo["additional_commission"] ? itemTo["additional_commission"] : vesselCopy['additional_commission']
            itemTo["date"]=itemTo["date"] ? itemTo["date"] : vesselCopy['date']
            itemTo["time"]=itemTo["time"] ? itemTo["time"] : vesselCopy['time']
            itemTo["expenses"]=itemTo["expenses"] ? itemTo["expenses"] : vesselCopy['expenses']
            itemTo["mins_cost"]=itemTo["mins_cost"] ? itemTo["mins_cost"] : vesselCopy['mins_cost']
            itemTo["insurance"]=itemTo["insurance"] ? itemTo["insurance"] : vesselCopy['insurance']
            itemTo["other"]=itemTo["other"] ? itemTo["other"] : vesselCopy['other']
            itemTo["steaming_day"]=itemTo["steaming_day"] ? itemTo["steaming_day"] : vesselCopy['steaming_day']
            itemTo["steaming_hour"]=itemTo["steaming_hour"] ? itemTo["steaming_hour"] : vesselCopy['steaming_hour']
            itemTo["steaming_amount"]=itemTo["steaming_amount"] ? itemTo["steaming_amount"] : vesselCopy['steaming_amount']
            itemTo["full_sp_ball"]=itemTo["full_sp_ball"] ? itemTo["full_sp_ball"] : vesselCopy['full_sp_ball']
            itemTo["full_ifo_ball"]=itemTo["full_ifo_ball"] ? itemTo["full_ifo_ball"] : vesselCopy['full_ifo_ball']
            itemTo["full_mgo_ball"]=itemTo["full_mgo_ball"] ? itemTo["full_mgo_ball"] : vesselCopy['full_mgo_ball']
            itemTo["full_sp_lad"]=itemTo["full_sp_lad"] ? itemTo["full_sp_lad"] : vesselCopy['full_sp_lad']
            itemTo["full_ifo_lad"]=itemTo["full_ifo_lad"] ? itemTo["full_ifo_lad"] : vesselCopy['full_ifo_lad']
            itemTo["full_mgo_lad"]=itemTo["full_mgo_lad"] ? itemTo["full_mgo_lad"] : vesselCopy['full_mgo_lad']
            itemTo["eco_sp_ball"]=itemTo["eco_sp_ball"] ? itemTo["eco_sp_ball"] : vesselCopy['eco_sp_ball']
            itemTo["eco_ifo_ball"]=itemTo["eco_ifo_ball"] ? itemTo["eco_ifo_ball"] : vesselCopy['eco_ifo_ball']
            itemTo["eco_mgo_ball"]=itemTo["eco_mgo_ball"] ? itemTo["eco_mgo_ball"] : vesselCopy['eco_mgo_ball']
            itemTo["eco_sp_lad"]=itemTo["eco_sp_lad"] ? itemTo["eco_sp_lad"] : vesselCopy['eco_sp_lad']
            itemTo["eco_ifo_lad"]=itemTo["eco_ifo_lad"] ? itemTo["eco_ifo_lad"] : vesselCopy['eco_ifo_lad']
            itemTo["eco_mgo_lad"]=itemTo["eco_mgo_lad"] ? itemTo["eco_mgo_lad"] : vesselCopy['eco_mgo_lad']
            itemTo["slow_sp_ball"]=itemTo["slow_sp_ball"] ? itemTo["slow_sp_ball"] : vesselCopy['slow_sp_ball']
            itemTo["slow_ifo_ball"]=itemTo["slow_ifo_ball"] ? itemTo["slow_ifo_ball"] : vesselCopy['slow_ifo_ball']
            itemTo["slow_mgo_ball"]=itemTo["slow_mgo_ball"] ? itemTo["slow_mgo_ball"] : vesselCopy['slow_mgo_ball']
            itemTo["slow_sp_lad"]=itemTo["slow_sp_lad"] ? itemTo["slow_sp_lad"] : vesselCopy['slow_sp_lad']
            itemTo["slow_ifo_lad"]=itemTo["slow_ifo_lad"] ? itemTo["slow_ifo_lad"] : vesselCopy['slow_ifo_lad']
            itemTo["slow_mgo_lad"]=itemTo["slow_mgo_lad"] ? itemTo["slow_mgo_lad"] : vesselCopy['slow_mgo_lad']
            itemTo["ifo_idle"]=itemTo["ifo_idle"] ? itemTo["ifo_idle"] : vesselCopy['ifo_idle']
            itemTo["ifo_wk"]=itemTo["ifo_wk"] ? itemTo["ifo_wk"] : vesselCopy['ifo_wk']
            itemTo["mgo_idle"]=itemTo["mgo_idle"] ? itemTo["mgo_idle"] : vesselCopy['mgo_idle']
            itemTo["mgo_wk"]=itemTo["mgo_wk"] ? itemTo["mgo_wk"] : vesselCopy['mgo_wk']
            itemTo["dwcc"]=itemTo["dwcc"] ? itemTo["dwcc"] : vesselCopy['dwcc']
            itemTo["draft"]=itemTo["draft"] ? itemTo["draft"] : vesselCopy['draft']
            itemTo["grain"]=itemTo["grain"] ? itemTo["grain"] : vesselCopy['grain']
            itemTo["tpc"]=itemTo["tpc"] ? itemTo["tpc"] : vesselCopy['tpc']
            itemTo["constants"]=itemTo["constants"] ? itemTo["constants"] : vesselCopy['constants']
            itemTo["bob"]=itemTo["bob"] ? itemTo["bob"] : vesselCopy['bob']
            itemTo["is_changed_vlsfo"]=itemTo["is_changed_vlsfo"] ? itemTo["is_changed_vlsfo"] : vesselCopy['is_changed_vlsfo']
            itemTo["is_changed_hsfo"]=itemTo["is_changed_hsfo"] ? itemTo["is_changed_hsfo"] : vesselCopy['is_changed_hsfo']
            itemTo["is_changed_lsmgo"]=itemTo["is_changed_lsmgo"] ? itemTo["is_changed_lsmgo"] : vesselCopy['is_changed_lsmgo']
            itemTo["vlsfo_price"]=itemTo["vlsfo_price"] ? itemTo["vlsfo_price"] : vesselCopy['vlsfo_price']
            itemTo["hsfo_price"]=itemTo["hsfo_price"] ? itemTo["hsfo_price"] : vesselCopy['hsfo_price']
            itemTo["lsmgo_price"]=itemTo["lsmgo_price"] ? itemTo["lsmgo_price"] : vesselCopy['lsmgo_price']
            itemTo["fresh_water"]=itemTo["fresh_water"] ? itemTo["fresh_water"] : vesselCopy['fresh_water']
            itemTo["scrubber"]=itemTo["scrubber"] ? itemTo["scrubber"] : vesselCopy['scrubber']
            itemTo["is_favorite"]=itemTo["is_favorite"] ? itemTo["is_favorite"] : vesselCopy['is_favorite']
            itemTo["can_used_as_template"]=itemTo["can_used_as_template"] ? itemTo["can_used_as_template"] : false
            itemTo["full_result"]=itemTo["full_result"] ? itemTo["full_result"] : vesselCopy['full_result']
            itemTo["full_hire"]=itemTo["full_hire"] ? itemTo["full_hire"] : vesselCopy['full_hire']
            itemTo["full_freight"]=itemTo["full_freight"] ? itemTo["full_freight"] : vesselCopy['full_freight']
            itemTo["full_voyage_days"]=itemTo["full_voyage_days"] ? itemTo["full_voyage_days"] : vesselCopy['full_voyage_days']
            itemTo["optimal_result"]=itemTo["optimal_result"] ? itemTo["optimal_result"] : vesselCopy['optimal_result']
            itemTo["optimal_hire"]=itemTo["optimal_hire"] ? itemTo["optimal_hire"] : vesselCopy['optimal_hire']
            itemTo["optimal_freight"]=itemTo["optimal_freight"] ? itemTo["optimal_freight"] : vesselCopy['optimal_freight']
            itemTo["optimal_voyage_days"]=itemTo["optimal_voyage_days"] ? itemTo["optimal_voyage_days"] : vesselCopy['optimal_voyage_days']
            itemTo["eco_result"]=itemTo["eco_result"] ? itemTo["eco_result"] : vesselCopy['eco_result']
            itemTo["eco_hire"]=itemTo["eco_hire"] ? itemTo["eco_hire"] : vesselCopy['eco_hire']
            itemTo["eco_freight"]=itemTo["eco_freight"] ? itemTo["eco_freight"] : vesselCopy['eco_freight']
            itemTo["eco_voyage_days"]=itemTo["eco_voyage_days"] ? itemTo["eco_voyage_days"] : vesselCopy['eco_voyage_days']
            itemTo["slow_result"]=itemTo["slow_result"] ? itemTo["slow_result"] : vesselCopy['slow_result']
            itemTo["slow_hire"]=itemTo["slow_hire"] ? itemTo["slow_hire"] : vesselCopy['slow_hire']
            itemTo["slow_freight"]=itemTo["slow_freight"] ? itemTo["slow_freight"] : vesselCopy['slow_freight']
            itemTo["slow_voyage_days"]=itemTo["slow_voyage_days"] ? itemTo["slow_voyage_days"] : vesselCopy['slow_voyage_days']
            itemTo["time_balast"]=itemTo["time_balast"] ? itemTo["time_balast"] : vesselCopy['time_balast']
            itemTo["time_laden"]=itemTo["time_laden"] ? itemTo["time_laden"] : vesselCopy['time_laden']
            itemTo["time_in_port"]=itemTo["time_in_port"] ? itemTo["time_in_port"] : vesselCopy['time_in_port']
            itemTo["total_time"]=itemTo["total_time"] ? itemTo["total_time"] : vesselCopy['total_time']
            itemTo['additional_info']['duplicateNumber'] = null
            if(!itemTo['additional_info']['canal_code']) {
              itemTo['additional_info']['canal_code'] = '100'
            }
            if(vesselCopy['additional_info']['canal_code']) {
              itemTo['additional_info']['canal_code'] = vesselCopy['additional_info']['canal_code']
            }
          }
        console.log('moveFromWithInvisibleVessel', 10)
        await this.updateRouteInfoForNew(itemTo, itemFrom.id)
        this.selectedId = idNewVessel
        console.log('moveFromWithInvisibleVessel', 5)
        this.hideLoad()

        // await this.onSetRoutesInfo(false, true)
        this.onUpdatePriceInVessels()
        console.log('moveFromWithInvisibleVessel', 6)
        this.hideLoadTable()
      }
    },
    async onPatchVessels() {
      if(this.ifAllowEdit) {
        let savedVessels = JSON.parse(JSON.stringify(this.vessels))

        savedVessels.forEach(vessel => {
          for (let [key, value] of Object.entries(vessel)) {
            if(value === '') vessel[key] = null
          }
        })
        // this.setIndex()
        // console.log('lol1', savedVessels);
        savedVessels.forEach(vessel => {
          if(vessel.additional_info && Array.isArray(vessel.additional_info.routes) === true) {
            vessel.additional_info.routes.forEach((route, index) => {
              route['ordering'] = index
            })
          }
        })
        // if(this.showLoader == 0) {
        //   await this.setIntakeForRoutesInVessel(this.getActiveVessel)
        // }

        this.showLoader++;
        this.axiosPatchCalcVessel(savedVessels).then(()=> {
          this.showLoader--;
        }).catch(()=> {
          this.showLoader--;
        })
        // this.onPatchRoutes()
        console.log('dopCalc onPatchVessels')

      }
    },
    onPatchRoutes() {
      let patchRoutes = JSON.parse(JSON.stringify(this.routes))
      let bool = false
      patchRoutes.forEach(item => {
        let data = this.getActiveVesselRoutes.find(a=> a.route_id === item.id)
        // let dataIndex = this.getActiveVesselRoutes.findIndex(a=> a.route_id === item.id)
        if(data) {
          if(data.port?.id !== item.port?.id) {
            item.port = data.port
            bool = true
          }
          if(data.status !== item.status) {
            item.status = data.status;
            bool = true
          }
          if(data.dist - data.seca !== item.dist) {

            item.dist = Math.round((data.dist - data.seca)*100)/100
            bool = true
          }
          if(data.seca !== item.seca) {
            item.seca = Math.round((data.seca)*100)/100
            bool = true
          }
          if(data.has_seca !== item.has_seca) {
            item.has_seca = data.has_seca;
            bool = true
          }
        }
        if(item.port) item.port = item.port.id;
      });
      // console.log(bool)
      if(bool) {
        this.showLoader++;
        this.axiosPatchCalcRoute(patchRoutes).then(()=> {
          this.showLoader--
        }).catch(()=> {
          this.showLoader--;
        })
        this.copyRoutes = JSON.parse(JSON.stringify(this.getActiveVesselRoutes))
      }
    },
    onSelect(id) {//vessel
      if(this.selectedId != id){
        this.setCalcVesselActive(id)
        this.showLoaderTable = true
        this.selectedId = id
        localStorage.setItem('lastActiveVesselInCalc', id)
        // this.setIntakeForRoutesInVessel(this.getActiveVessel)
        // this.checkTotalTimeRoutes()
        // this.checkEmptyRoute()
      }
    },
    async checkEmptyRoute() {
      console.log('checkEmptyRoute')
      if(this.getActiveVesselRoutes.length === 0) {
        if(this.vessels.filter(a=> a.name !== 'Invisible vessel - not show').length === 0) {
          if(!this.vessels[0].additional_info.routes.length) {
            let count = this.vessels[0].additional_info.routes.length
            for(let i=0; i < 4 - count; i++) {
              await this.onCreateRoutes(0)
            }
          }
        } else {
          for(let i=0; i < 4; i++) {
            await this.onCreateRoutes(0)
          }
        }
      }
    },
    async onDelete(id) {//vessel
      if(!this.ifAllowEdit) return false

      let index = this.vessels.findIndex(el=>el.id==id)
      let lastRouteIndex = this.vessels[0].additional_info.routes.length-1
      let name = this.vessels[index].name
      if(id == this.selectedId) {

        this.selectedId = null
      }
      let oldPort = (this.vessels.find(el=>el.id==id)) && this.vessels.find(el=>el.id==id).additional_info.routes[lastRouteIndex] ? this.vessels.find(el=>el.id==id).additional_info.routes[lastRouteIndex].port : null
      if(index >= 0){
        await this.axiosDeleteCalcVessel(id)
        this.vessels.splice(index, 1)
      }
      if(!this.selectedId) this.onSelect(this.vessels[0].id)
      if(index == 0 && this.vessels[0] && this.vessels[0].additional_info.routes && this.vessels[0].additional_info.routes[lastRouteIndex] && this.vessels[0].additional_info.routes[lastRouteIndex].port){
        let newPort = this.vessels[0].additional_info.routes[lastRouteIndex].port
        this.onSelectPort(newPort, oldPort, this.vessels[0].additional_info.routes.length-1)
      }

      // let item = this.vessels.find(a=> a.name == 'Invisible vessel - not show')
      // this.onSelect(item.id)
      let vesselsFiltered = this.vessels.filter(a=> a.name !== 'Invisible vessel - not show')
      let itemSelect = null
      if(vesselsFiltered.length !== 0) {
        itemSelect = vesselsFiltered[0]
      } else {
        itemSelect = this.vessels.find(a=> a.name == 'Invisible vessel - not show')
      }
      this.onSelect(itemSelect.id)
      this.checkCorrectNumberDuplicates(name)

    },

    async onCreateRoutes(index = -1) {
      if(!this.ifAllowEdit) return false

      let newRoute = this.createNewRoute()
      let route = {...this.emptyRoute}
      route.tab = +this.$route.params.id
      let res = await this.axiosPostCalcRoute(route)
      if(res) {
        newRoute.route_id = res.id
      }
      // this.vessels.forEach(el=>{
      //   el.additional_info.routes.splice(1+index, 0, newRoute)
      // })
      this.getActiveVessel.additional_info.routes.splice(1+index, 0, newRoute)
      this.onSetRoutesInfo()
      return  null;
    },

    async onDeleteRoute(i) {
      if(this.ifAllowEdit) {
        // if(this.getActiveVesselRoutes[i].route_id) {
        //   this.axiosDeleteCalcRoute( this.getActiveVesselRoutes[i].route_id)
        // }
        // this.vessels.forEach(el=>{
        //   el.additional_info?.routes?.splice(i, 1)
        // })
        this.getActiveVessel.additional_info?.routes?.splice(i, 1)

        if(this.getActiveVessel.additional_info?.routes?.length == 0) {
          await this.onCreateRoutes(0)
        }

        if(this.vessels[0]?.additional_info?.routes?.length){
          this.onSetRoutesInfo()
        }
        this.setDistanceForRoutes()
      }
    },

    async setDistanceForRoutes() {
      console.log('setDistanceForRoutes start')
      // this.vessels.forEach(async vessel=>{
      let vessel = this.getActiveVessel
      if(!vessel) {
        return null
      }
        let lastIndex = vessel.additional_info.routes.findIndex(a=> a.port == null)
        let arrayPorts = vessel.additional_info.routes
        if(lastIndex >= 0) {
          arrayPorts = vessel.additional_info.routes.slice(0, lastIndex)
        }


        if(arrayPorts.length >= 2) {
          let distances = await this.axiosGetRouteDistance({
            seca_cost_ratio: this.secaCostRation ? this.secaCostRation.option : null,
            seca_bypass_option: this.secaBypassOption ? this.secaBypassOption.option: null,
            piracy_option: this.piracyActive ? this.piracyActive.code : null,
            canal_pass_code: this.codeCanal,
            // from_port: prevEl.port?.name,
            // to_port: route.port?.name,
            ports: arrayPorts.map(el => {return el.port?.name}).toString(),
            // use_local_eca: route.has_seca ? 'True' : 'False'
          })
          if(Array.isArray(distances) !== true) {
            console.log('distances', ''+distances.data.detail)
            if((''+distances.data.detail).indexOf('blocked') >= 0) {
              this.addTip('Your calculation number is over the day limit and blocked.')
              this.showLoaderTable = false
              return null
            }
          }
          distances.forEach((item, index)=> {
            if(index == 0) {
              let distance = item.response
              let route = vessel.additional_info.routes[index]
              route.dist = 0
              route.seca = 0
              route.has_seca =  distance.section.from_port.seca_port
            }
            let distance = item.response
            let route = vessel.additional_info.routes[index+1]
            route.dist = Math.round((distance.section.distance - distance.section.distance_seca)*100)/100
            route.seca = Math.round((distance.section.distance_seca)*100)/100
            route.has_seca =  distance.section.to_port.seca_port
          })
        }

        // vessel.additional_info.routes.forEach(async (route, i)=>{
        //   if(i == 0){
        //     route.dist = route.dist ? Math.round((route.dist)*100)/100 : 0
        //     route.seca = route.seca ? Math.round((route.seca)*100)/100 : 0
        //     // route.has_seca = false
        //     route.from_port = route.from_port ? route.from_port : null
        //     return false
        //   }
        //
        //   const prevEl = vessel.additional_info.routes[i-1];
        //   // if(route.from_port == prevEl.port?.name) return false
        //
        //   if(!prevEl.port?.name || !route.port?.name) return false
        //
        //
        //   let distance = await this.axiosGetRouteDistance({
        //     seca_cost_ratio: this.secaCostRation ? this.secaCostRation.option : null,
        //     seca_bypass_option: this.secaBypassOption ? this.secaBypassOption.option: null,
        //     piracy_option: this.piracyActive ? this.piracyActive.code : null,
        //     canal_pass_code: this.codeCanal,
        //     from_port: prevEl.port?.name,
        //     to_port: route.port?.name,
        //     use_local_eca: route.has_seca ? 'True' : 'False'
        //   })
        //   route.dist = distance?.status >= 400 ? 0 :  Math.round((distance.total_distance - distance.total_seca_distance)*100)/100
        //   route.seca = distance?.status >= 400 ? 0 :  Math.round((distance.total_seca_distance)*100)/100
        //   route.has_seca = distance?.status == 400 ? false : distance.section[0].to_port.seca_port
        //   if(!(distance?.status == 400) && i === 1) {
        //     vessel.additional_info.routes[i-1].has_seca = distance?.status == 400 ? false : distance.section[0].from_port.seca_port
        //   }
        //   route.from_port = prevEl.port?.name
        // })
      // })
    }, 

    async onUpdateDrag(type) {
      if(type.moved){
        // for (let i = 0; i < this.vessels.length; i++) {
        //   let el = this.vessels[i];
        let el = this.getActiveVessel;
        let routes = el.additional_info.routes

          if(type.moved.newIndex == 0){
            if(this.getActiveVessel.id == el.id){
              let movedEl = routes.splice(type.moved.newIndex, 1)
              routes.splice(type.moved.oldIndex, 0, ...movedEl)
            }
          }else if(type.moved.newIndex == routes.length-1){
            if(this.getActiveVessel.id == el.id){
              let movedEl = routes.splice(type.moved.newIndex, 1)
              routes.splice(type.moved.oldIndex, 0, ...movedEl)
            }
          }else if(this.getActiveVessel.id != el.id){ // active vessel already moved
            let movedEl = routes.splice(type.moved.oldIndex, 1)
            routes.splice(type.moved.newIndex, 0, ...movedEl)
          }
        // }

        this.onSetRoutesInfo()
      }
    },

    async onSetRoutesInfo(notCalcIntake, noCalcAdditionaly, noLoadDistance) {
      this.checkRoutesAndCargos()
      if(!noLoadDistance) {
        await this.setDistanceForRoutes()
      }
      if(!noCalcAdditionaly) {
        this.setIntakeForRoutes(noCalcAdditionaly)
      }
      this.setResultsForRoutes()
      this.setSteamingForRoutes()
      this.setPortStayForRoutes()
      this.setArrivalAndDepartureForRoutes()
      if(!noCalcAdditionaly) {
        console.log(1); await this.calcAdditionalyInfoForVessel({vessel: this.getActiveVessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
      }
      // await this.$nextTick()
      // setTimeout(()=> {
      if(!notCalcIntake) {
        this.setIfoMgoForRoutes()
      }
        this.setVoyageDays()
        this.setHireAndFreightBep()
      // }, 300)

      // this.setIndex()

    },

    async onUpdateDataInVessel(vessel) {
      await this.setIntakeForRoutesInVesselLegacy(vessel)
      this.setResultsForRoutesInVessel(vessel)
      this.checkChangeDaysSteaming(vessel);
      // this.setSteamingForRoutesInVessel(vessel)
      this.checkChangePortStay(vessel)
      this.setArrivalAndDepartureForRoutesInVessel(vessel)
      console.log(1); await this.calcAdditionalyInfoForVessel({vessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
      // await this.$nextTick()
      // setTimeout(()=> {
        this.setIfoMgoForRoutesInVessel(vessel)
        this.setVoyageDaysInVessel(vessel)
        this.setHireAndFreightBepInVessel(vessel)
      // }, 300)

    },

    async onSetDisbursement(disbursement, i) {
      // this.vessels.forEach(vessel=>{
      //   vessel.additional_info.routes[i].disbursement = disbursement
      // })
      console.log('disbursement', disbursement, i)
      this.setHireAndFreightBepInVessel(this.getActiveVessel)
      console.log(1); await this.calcAdditionalyInfoForVessel({vessel: this.getActiveVessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
    },
    setSteamingForRoutes() {
      // this.vessels.forEach(vessel=>{
      //   this.setSteamingForRoutesInVessel(vessel)
      // })
    },
    setSteamingForRoutesInVessel(vessel) {
      vessel.additional_info.routes.forEach(dot=>{
        let weather_factor = this.activeTab?.weather_factor ? this.activeTab.weather_factor : 0
        let dist = dot.dist ? dot.dist : 0

        let suff = dot.intake ? '_lad' : '_ball'

        let fullResult = (dist / (vessel['full_sp'+suff] - (vessel['full_sp'+suff] * weather_factor/100)))/24
        let ecoResult = (dist / (vessel['eco_sp'+suff] - (vessel['eco_sp'+suff] * weather_factor/100)))/24
        let slowResult = (dist / (vessel['slow_sp'+suff] - (vessel['slow_sp'+suff] * weather_factor/100)))/24
        let optimalResult

        if (dot.eco_result < dot.full_result) {//eco
          optimalResult = ecoResult
        } else {//full
          optimalResult = fullResult
        }

        // dot.full_streaming_days =  this.getTimeFromNumb(fullResult, 'days')
        // dot.full_streaming_time = this.getTimeFromNumb(fullResult, 'time')
        // dot.eco_streaming_days = this.getTimeFromNumb(ecoResult, 'days')
        // dot.eco_streaming_time = this.getTimeFromNumb(ecoResult, 'time')
        // dot.slow_streaming_days = this.getTimeFromNumb(slowResult, 'days')
        // dot.slow_streaming_time = this.getTimeFromNumb(slowResult, 'time')
        // dot.optimal_streaming_days = this.getTimeFromNumb(optimalResult, 'days')
        // dot.optimal_streaming_time = this.getTimeFromNumb(optimalResult, 'time')
        dot.full_streaming_days =  fullResult
        // dot.full_streaming_time = this.getTimeFromNumb(fullResult, 'time')
        dot.eco_streaming_days = ecoResult
        // dot.eco_streaming_time = this.getTimeFromNumb(ecoResult, 'time')
        dot.slow_streaming_days = slowResult
        // dot.slow_streaming_time = this.getTimeFromNumb(slowResult, 'time')
        dot.optimal_streaming_days = optimalResult
        // dot.optimal_streaming_time = this.getTimeFromNumb(optimalResult, 'time')





        /* if(result && result != Infinity){
          result = Math.round(result*10000)/10000
          let days = Math.floor(result)
          result = (result - days)*24
          let hours = Math.floor(result)
          result = (result-hours)*60
          let minutes = Math.floor(result)

          if(hours < 10) hours = '0'+hours
          if(minutes < 10) minutes = '0'+minutes

          dot.streaming_days = days
          dot.streaming_time = hours+':'+minutes
        }else{
          dot.full_streaming_days = null
          dot.full_streaming_time = null
          dot.eco_streaming_days = null
          dot.eco_streaming_time = null
          dot.slow_streaming_days = null
          dot.slow_streaming_time = null
          dot.optimal_streaming_days = null
          dot.optimal_streaming_time = null
        } */
      })
    },
    getTimeFromNumb(numb, key='days') {
      if(numb && numb != Infinity){
        if(key == 'days'){
          return Math.floor(numb)
        }else if(key == 'time'){
          let hours = (numb - Math.floor(numb))*24
          let minutes = Math.floor((hours - Math.floor(hours))*60)
          hours = Math.floor(hours)
          if(hours < 10) hours = '0'+hours
          if(minutes < 10) minutes = '0'+minutes
          return hours+':'+minutes
        }
      }else{
        return null
      }
    },
    setPortStayForRoutes() {
      this.vessels.forEach(vessel=>{
        this.checkChangePortStay(vessel)
      })
    },
    setPortStayForRoutesInVessel(vessel) {
      vessel.additional_info.routes.forEach(dot=>{
        if(dot.status === 'loading' || dot.status === 'discharging') {
          // console.log(dot.status, 'dot.status')
          let items = this.tableAdditionalInfoBssLoadedCargoes.filter(a=> a.status === dot.status && a.route_id === dot.route_id)
          if(Array.isArray(items) && items.length) {
            let port_stay = 0
            items.forEach(item=> {
              port_stay+=(dot.status === 'loading' ? (item.loadtime_tt && item.loadtime_tt !== 'NaN' ? item.loadtime_tt : 0) : (item.distime_tt && item.distime_tt !== 'NaN' ? item.distime_tt : 0))
            })
            dot.port_stay = port_stay

          } else {
            dot.port_stay = 0
          }
        }
        // if(dot?.port?.data_type == 'canal'){
        //   if(dot.port.transit_time){
        //     let result = dot.port.transit_time.split(':')
        //     result = +result[0]/24 + +result[1]/1.440
        //     result = result.toFixed(2)
        //     dot.port_stay = +result
        //   }
        // }else if(dot.cargos && dot.cargos.length && dot.status){
        //   let result = 0
        //   let cargo = dot.cargos ? dot.cargos[dot.cargos.length-1] : null
        //
        //   let intake = dot.intake ? +dot.intake : 0
        //   let turnTime = cargo[dot.status+'_turn_time'] ? +cargo[dot.status+'_turn_time'] : 0
        //   let rate = cargo[dot.status+'_rate'] ? cargo[dot.status+'_rate'] : 1
        //   let terms = this.termsList.find(el=>el.name == cargo[dot.status+'_terms'])
        //   terms = terms ? terms.coof : 0
        //   if(cargo[dot.status+'_rate_type'] == 'DAYS'){
        //     result = rate * terms + turnTime
        //   }else{
        //     result = (Math.abs(intake) / rate) * terms + turnTime
        //   }
        //   if(result){
        //     result = result.toFixed(2)
        //
        //     dot.port_stay = +result
        //   }else{
        //     dot.port_stay = null
        //   }
        // }else{
        //   dot.port_stay = null
        // }
      })

      // this.routes.forEach(dot=> {
      //   if(dot.status === 'loading' || dot.status === 'discharging') {
      //     console.log(dot.status, 'dot.status')
      //     let items = this.tableAdditionalInfoBssLoadedCargoes.filter(a=> a.status === dot.status && a.route_id === dot.route_id)
      //     if(Array.isArray(items) && items.length) {
      //       let port_stay = 0
      //       items.forEach(item=> {
      //         port_stay+=(dot.status === 'loading' ? (item.loadtime_tt && item.loadtime_tt !== 'NaN' ? item.loadtime_tt : 0) : (item.distime_tt && item.distime_tt !== 'NaN' ? item.distime_tt : 0))
      //       })
      //       dot.port_stay = port_stay
      //       console.log(dot.port_stay)
      //
      //     } else {
      //       dot.port_stay = 0
      //     }
      //   }
      // })
    },
    setArrivalAndDepartureForRoutes() {
      this.vessels.forEach(vessel=>{
        this.setArrivalAndDepartureForRoutesInVessel(vessel)
      })
    },
    setArrivalAndDepartureForRoutesInVessel(vessel) {
      let routes = vessel.additional_info.routes
      routes.forEach((dot, i)=>{
        /* Set arrival */
        if(i == 0){
          if(!vessel.date) return false
          dot.full_arrival_date = moment(vessel.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          dot.eco_arrival_date = moment(vessel.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          dot.slow_arrival_date = moment(vessel.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          dot.optimal_arrival_date = moment(vessel.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          dot.full_arrival_time = vessel.time
          dot.eco_arrival_time = vessel.time
          dot.slow_arrival_time = vessel.time
          dot.optimal_arrival_time = vessel.time
        }else{
          this.fuelList.forEach(fuel => {
            if(!routes[i-1][fuel+'_departure_date']) return false

            let newArrival = routes[i-1][fuel+'_departure_date'] + ' ' + (routes[i-1][fuel+'_departure_time'] ? routes[i-1][fuel+'_departure_time'] : '00:00')
            newArrival = moment(newArrival, 'DD.MM.YYYY HH:mm')


            let streaming_days = this.getTimeFromNumb(dot[fuel+'_streaming_days'], 'days')
            let streaming_time = this.getTimeFromNumb(dot[fuel+'_streaming_time'], 'time')
            if(streaming_days || streaming_time){
              streaming_time = streaming_time ? streaming_time.split(':') : [0,0]
              newArrival.add({
                'days': streaming_days,
                'hours': streaming_time[0],
                'minutes': streaming_time[1]
              })
            }

            dot[fuel+'_arrival_date'] = newArrival.format('DD.MM.YYYY')
            dot[fuel+'_arrival_time'] = newArrival.format('HH:mm')
          })
        }


        /* Set departures */
        this.fuelList.forEach(fuel => {
          if(!dot[fuel+'_arrival_date']) return false
          let newDeparture = dot[fuel+'_arrival_date'] + ' ' + (dot[fuel+'_arrival_time'] ? dot[fuel+'_arrival_time'] : '00:00')
          newDeparture = moment(newDeparture, 'DD.MM.YYYY HH:mm')

          if(dot.port_stay){
            let port_stay_hours = Math.floor(+dot.port_stay*24)
            let port_stay_minutes = Math.round(+dot.port_stay*24 - port_stay_hours)

            newDeparture.add({
              'hours': port_stay_hours,
              'minutes': port_stay_minutes
            })
          }
          dot[fuel+'_departure_date'] = newDeparture.format('DD.MM.YYYY')
          dot[fuel+'_departure_time'] = newDeparture.format('HH:mm')
        })
      })
    },
    setIntakeForRoutes() {
      console.log('dopCalc setIntakeForRoutes')
      // this.vessels.forEach(vessel=>{

        this.setIntakeForRoutesInVessel(this.getActiveVessel)
      // })
    },
    async setIntakeForRoutesInVesselLegacy(vessel) {
      let routes = await this.calcIntakeForVessel({vessel, cargos: this.cargos})
      if(routes) {
        routes.forEach(item=> {
          let savedItem = vessel.additional_info.routes.find(a=> a.route_id === item.route_id)
          if(savedItem) {
            savedItem.intake = item.intake
          }
        })
      }
    },
    async setIntakeForRoutesInVessel(vessel) {
      // console.warn('setIntakeForRoutesInVessel!!!: ', vessel, this.cargos);
      // console.log(setIntakeForRoutesInVessel)
      let routes = await this.calcIntakeForVessel({vessel, cargos: this.cargos})
      if(routes) {
        routes.forEach(item=> {
          let savedItem = vessel.additional_info.routes.find(a=> a.route_id === item.route_id)
          if(savedItem) {
            savedItem.intake = item.intake
          }
        })
      }
      // console.log('calcAdditionalyInfoForVessel setIntakeForRoutesInVessel')
      console.log(1);
       await this.calcAdditionalyInfoForVessel({vessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
      // this.setPortStayForRoutesInVessel(vessel)
      // vessel.additional_info.routes.forEach(dot=>{
      //   dot.port_stay = null;
      //   dot.port_stay = 1000
      // })
      this.checkChangeDaysSteaming(vessel)
      this.checkChangePortStay(vessel)

      /* let routes = vessel.additional_info.routes
      routes.forEach(dot=>{
        if(dot.cargos.length == 0){
          dot.intake = null
          return false
        }else{
          dot.intake = this.calcIntakeForRoute(vessel, this.cargos, index)
        }
        if(!dot.cargo){
          return false
        } else{
          let result

          let cargo = this.cargos.find(el => el.id == dot.cargo)

          if(cargo?.ballast_voyage){
            dot.intake = null
            return false
          }
          let water_destiny = this.getNumbFromString(cargo[dot.status+'_water_density'])

          let sswd = vessel.draft ? vessel.draft : 0
          let draft_restr = dot.draft_restr ? dot.draft_restr : 0
          let tpc = vessel.tpc ? vessel.tpc : 0
          let bob = vessel.bob ? vessel.bob : 0
          let constanta = vessel.constants ? vessel.constants : 0
          let fresh_water = vessel.fresh_water ? vessel.fresh_water : 0
          let sdwt = vessel.dwt ? vessel.dwt : 0

          if(cargo[dot.status+'_seasonal_mark'] == 'tropical'){
            sdwt = sdwt + (sswd/48)* tpc*100
          }else if(cargo[dot.status+'_seasonal_mark'] == 'winter'){
            sdwt = sdwt - (sswd/48)* tpc*100
          }


          let fwa = (sswd/48)*1.1
          fwa = fwa ? fwa : 0

          let dwa = ((1.025 - water_destiny)/0.025)*fwa
          dwa = dwa ? dwa : 0

          if(cargo[dot.status+'_seasonal_mark'] == 'tropical'){
            sswd = sswd + sswd/48 + dwa
          }else if(cargo[dot.status+'_seasonal_mark'] == 'winter'){
            sswd = sswd - sswd/48 + dwa
          }

          let dwcc = sdwt - (sswd + dwa - draft_restr)*100*tpc
          dwcc = dwcc ? dwcc : 0
          
          result = dwcc - bob - constanta - fresh_water

          dot.intake = result ? result : 0
        }
      }) */
    },

    setResultsForRoutes() {
      this.vessels.forEach(vessel => {
        this.setResultsForRoutesInVessel(vessel)
      })
    },
    setResultsForRoutesInVessel(vessel) {
      let weather_factor = this.activeTab?.weather_factor ? this.activeTab?.weather_factor : 0
      let add_com = this.activeTab?.addcom ? this.activeTab?.addcom : 0

      let vlsfo = vessel.vlsfo_price ? vessel.vlsfo_price : this.activeTab?.vlsfo ? this.activeTab?.vlsfo : 0
      let lsmgo = vessel.lsmgo_price ? vessel.lsmgo_price : this.activeTab?.lsmgo ? this.activeTab?.lsmgo : 0
      let hsfo = vessel.hsfo_price ? vessel.hsfo_price : this.activeTab?.hsfo ? this.activeTab?.hsfo : 0

      let routes = vessel.additional_info.routes
      let fuel_1 = vessel.scrubber ? hsfo : vlsfo
      let fuel_2 = vessel.scrubber ? hsfo : lsmgo

      routes.forEach(dot => {
        let suff = dot.intake ? '_lad' : '_ball'
        dot.full_result = this.getOneResult(
            dot.dist,
            dot.seca,
            vessel['full_sp' + suff],
            weather_factor,
            vessel.hire,
            add_com,
            vessel['full_ifo' + suff],
            vessel['full_mgo' + suff],
            fuel_1,
            fuel_2
        )

        
        dot.eco_result = this.getOneResult(
            dot.dist,
            dot.seca,
            vessel['eco_sp' + suff],
            weather_factor,
            vessel.hire,
            add_com,
            vessel['eco_ifo' + suff],
            vessel['eco_mgo' + suff],
            fuel_1,
            fuel_2
        )

        dot.slow_result = this.getOneResult(
            dot.dist,
            dot.seca,
            vessel['slow_sp' + suff],
            weather_factor,
            vessel.hire,
            add_com,
            vessel['slow_ifo' + suff],
            vessel['slow_mgo' + suff],
            fuel_1,
            fuel_2
        )

        // if ((dot.eco_result < dot.full_result) && (dot.eco_result < dot.slow_result) && dot.eco_result !== 0 || (dot.slow_result === 0 && dot.full_result === 0)) {
        //   dot.optimal_result = dot.eco_result
        //   dot.optimal_speed = vessel['eco_sp' + suff]
        //   dot.is_full = false
        //   dot.is_slow = false
        //   dot.is_eco = true
        // } else if ((dot.full_result < dot.slow_result && dot.full_result !== 0) || dot.slow_result === 0) {
        //   dot.optimal_result = dot.full_result
        //   dot.optimal_speed = vessel['full_sp' + suff]
        //   dot.is_eco = false
        //   dot.is_slow = false
        //   dot.is_full = true
        // } else {
        //   dot.optimal_result = dot.slow_result
        //   dot.optimal_speed = vessel['slow_sp' + suff]
        //   dot.is_eco = false
        //   dot.is_full = false
        //   dot.is_slow = true
        // }
        // console.log()
        if (dot.full_result != 0 && ((dot.full_result <= dot.slow_result && dot.full_result <= dot.eco_result && dot.eco_result != 0 && dot.slow_result != 0) || (dot.full_result <= dot.slow_result && dot.slow_result != 0 && dot.eco_result == 0) || (dot.full_result <= dot.eco_result && dot.eco_result != 0 && dot.slow_result == 0) || (dot.eco_result == 0 && dot.slow_result == 0))) {
          dot.is_eco = false
          dot.is_slow = false
          dot.is_full = true
          dot.optimal_speed = vessel['full_sp' + suff]
        } else if (dot.eco_result != 0 && ((dot.eco_result <= dot.slow_result && dot.eco_result <= dot.full_result && dot.full_result != 0 && dot.slow_result != 0) || (dot.eco_result <= dot.slow_result && dot.slow_result != 0 && dot.full_result == 0) || (dot.eco_result <= dot.full_result && dot.full_result != 0 && dot.slow_result == 0) || (dot.slow_result == 0 && dot.full_result == 0))) {
          dot.is_full = false
          dot.is_slow = false
          dot.is_eco = true
          dot.optimal_speed = vessel['eco_sp' + suff]
        } else if (dot.slow_result != 0 && ((dot.slow_result <= dot.eco_result && dot.slow_result <= dot.full_result && dot.full_result != 0 && dot.eco_result != 0) || (dot.slow_result <= dot.full_result && dot.full_result != 0 && dot.eco_result == 0) || (dot.slow_result <= dot.eco_result && dot.eco_result != 0 && dot.full_result == 0) || (dot.full_result == 0 && dot.eco_result == 0))) {
          dot.is_eco = false
          dot.is_full = false
          dot.is_slow = true
          dot.optimal_speed = vessel['slow_sp' + suff]
        } else if (dot.full_result == 0 && dot.eco_result == 0 && dot.slow_result == 0) {
          dot.is_eco = false
          dot.is_full = false
          dot.is_slow = true
          dot.optimal_speed = vessel['slow_sp' + suff]
        }
      })
      
      vessel.full_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.full_result, 0
      )
      vessel.eco_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.eco_result, 0
      )
      vessel.slow_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.slow_result, 0
      )
      vessel.optimal_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.optimal_result, 0
      )
      vessel.full_result = vessel.full_result ? Math.round(vessel.full_result*10000)/10000 : 0
      vessel.eco_result = vessel.full_result ? Math.round(vessel.eco_result*10000)/10000 : 0
      vessel.slow_result = vessel.slow_result ? Math.round(vessel.slow_result*10000)/10000 : 0
      vessel.optimal_result = vessel.optimal_result ? Math.round(vessel.optimal_result*10000)/10000 : 0
    },
    getOneResult(dist = 0, seca = 0, speed = 0, weather_factor = 0, hire = 0, add_com = 0, ifo = 0, mgo = 0, fuel_1 = 0, fuel_2 = 0) {
      let result = 0
      
      result = 
        (
          (
            (dist / speed + ((dist / speed) * weather_factor)/100) / 24
          ) 
          * 
          (
            hire - (hire * add_com)/100
          )
        )
        + 
        (
          (
            ((dist - seca) / speed + (((dist - seca) / speed) * weather_factor)/100) / 24
          ) 
          * 
          ifo * fuel_1
        ) 
        + 
        (
          (
            (seca / speed + ((seca / speed) * weather_factor)/100) / 24
          ) 
          * 
          mgo * fuel_2
        );

      //result = result ? Math.round(result*10000)/10000 : 0
      result = result ? result : 0
      return result
    },
    setIfoMgoForRoutes() {
      this.vessels.forEach(vessel=>{
        this.setIfoMgoForRoutesInVessel(vessel)
      })
    },
    setIfoMgoForRoutesInVessel(vessel) {
      // let weather_factor = this.activeTab?.weather_factor ? this.activeTab?.weather_factor : 0
      // let routes = vessel.additional_info.routes
      vessel.additional_info.routes.forEach((dot, index)=>{
        // let item = this.tableFullSpeed.find(a=> dot.route_id === a.route_id)
        let item = this.tableFullSpeed[index]
        let itemsCargo = this.tableAdditionalInfoBssLoadedCargoes.filter(a=> dot.route_id === a.route_id && dot.status === a.status);
        let itemAdditi = {
          ifo_load_eca_terms: 0,
          mgo_loadport_eca_terms: 0,
          ifo_layExD_eca_non_eca: 0,
          mgo_layExD_eca_non_eca: 0,
          ifo_disport_eca_terms: 0,
          mgo_disport_eca_terms: 0,
        }
        // console.log(dot.route_id, dot.status, dot.port.name,'itemsCargo', itemsCargo)
        itemsCargo.forEach(item=> {
          itemAdditi.ifo_load_eca_terms+=item.ifo_load_eca_terms;
          itemAdditi.mgo_loadport_eca_terms+=item.mgo_loadport_eca_terms;
          itemAdditi.ifo_disport_eca_terms+=item.ifo_disport_eca_terms;
          itemAdditi.mgo_disport_eca_terms+=item.mgo_disport_eca_terms;
        })

        // console.log('itemAdditi', itemAdditi)ы
        if(item) {
          let prefixOptimal = item.full_bunker_plus_hire === Math.min(item.full_bunker_plus_hire, item.eco_bunker_plus_hire, item.slow_bunker_plus_hire) ? 'full' : item.eco_bunker_plus_hire === Math.min(item.full_bunker_plus_hire, item.eco_bunker_plus_hire, item.slow_bunker_plus_hire) ? 'eco' : item.slow_bunker_plus_hire === Math.min(item.full_bunker_plus_hire, item.eco_bunker_plus_hire, item.slow_bunker_plus_hire) ? 'slow' : '';

          if(dot.status === 'loading') {
            dot.full_ifo_mt = Math.round(((item.full_ifo_eca_non_eca ? item.full_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca + (itemAdditi && itemAdditi.ifo_load_eca_terms ? itemAdditi.ifo_load_eca_terms : 0))*100)/100
            console.log('dot.full_ifo_mt', dot.full_ifo_mt)
            dot.full_mgo_mt = Math.round(((item.full_mgo_eca_non_eca ? item.full_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca + (itemAdditi && itemAdditi.mgo_loadport_eca_terms ? itemAdditi.mgo_loadport_eca_terms : 0))*100)/100
            //
            dot.eco_ifo_mt = Math.round(((item.eco_ifo_eca_non_eca ? item.eco_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca + (itemAdditi && itemAdditi.ifo_load_eca_terms ? itemAdditi.ifo_load_eca_terms : 0))*100)/100
            dot.eco_mgo_mt = Math.round(((item.eco_mgo_eca_non_eca ? item.eco_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca + (itemAdditi && itemAdditi.mgo_loadport_eca_terms ? itemAdditi.mgo_loadport_eca_terms : 0))*100)/100
            //
            dot.slow_ifo_mt = Math.round(((item.slow_ifo_eca_non_eca ? item.slow_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca + (itemAdditi && itemAdditi.ifo_load_eca_terms ? itemAdditi.ifo_load_eca_terms : 0))*100)/100
            dot.slow_mgo_mt = Math.round(((item.slow_mgo_eca_non_eca ? item.slow_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca + (itemAdditi && itemAdditi.mgo_loadport_eca_terms ? itemAdditi.mgo_loadport_eca_terms : 0))*100)/100
            //

            dot.optimal_ifo_mt = Math.round(((item[prefixOptimal+'_ifo_eca_non_eca'] ? item[prefixOptimal+'_ifo_eca_non_eca'] : 0) + item.ifo_layExD_eca_non_eca + (itemAdditi && itemAdditi.ifo_load_eca_terms ? itemAdditi.ifo_load_eca_terms : 0))*100)/100
            dot.optimal_mgo_mt = Math.round(((item[prefixOptimal+'_mgo_eca_non_eca'] ? item[prefixOptimal+'_mgo_eca_non_eca'] : 0) + item.mgo_layExD_eca_non_eca + (itemAdditi && itemAdditi.mgo_loadport_eca_terms ? itemAdditi.mgo_loadport_eca_terms : 0))*100)/100
          }
          if(dot.status === 'discharging') {
            // console.log(`status ${dot.status} mgo_mt= ${(item.eco_mgo_eca_non_eca ? item.eco_mgo_eca_non_eca : 0)} + ${item.mgo_layExD_eca_non_eca}`)

            dot.full_ifo_mt = Math.round(((item.full_ifo_eca_non_eca ? item.full_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca + (itemAdditi && itemAdditi.ifo_disport_eca_terms ? itemAdditi.ifo_disport_eca_terms : 0))*100)/100
            dot.full_mgo_mt = Math.round(((item.full_mgo_eca_non_eca ? item.full_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca + (itemAdditi && itemAdditi.mgo_disport_eca_terms ? itemAdditi.mgo_disport_eca_terms : 0))*100)/100
            // console.log(dot.status, dot.port.name, `full_ifo_eca_non_eca: ${item.full_ifo_eca_non_eca}  mgo_layExD_eca_non_eca: ${item.mgo_layExD_eca_non_eca} mgo_disport_eca_terms: ${itemAdditi.mgo_disport_eca_terms}`)
            // console.log(dot.status, dot.port.name, 'item.full_mgo_eca_non_eca', (item.full_mgo_eca_non_eca ? item.full_mgo_eca_non_eca : 0), 'item.mgo_layExD_eca_non_eca',item.mgo_layExD_eca_non_eca, 'itemAdditi.mgo_disport_eca_terms',(itemAdditi && itemAdditi.mgo_disport_eca_terms ? itemAdditi.mgo_disport_eca_terms : 0))
            dot.eco_ifo_mt = Math.round(((item.eco_ifo_eca_non_eca ? item.eco_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca + (itemAdditi && itemAdditi.ifo_disport_eca_terms ? itemAdditi.ifo_disport_eca_terms : 0))*100)/100
            dot.eco_mgo_mt = Math.round(((item.eco_mgo_eca_non_eca ? item.eco_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca + (itemAdditi && itemAdditi.mgo_disport_eca_terms ? itemAdditi.mgo_disport_eca_terms : 0))*100)/100
            //
            dot.slow_ifo_mt = Math.round(((item.slow_ifo_eca_non_eca ? item.slow_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca + (itemAdditi && itemAdditi.ifo_disport_eca_terms ? itemAdditi.ifo_disport_eca_terms : 0))*100)/100
            dot.slow_mgo_mt = Math.round(((item.slow_mgo_eca_non_eca ? item.slow_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca + (itemAdditi && itemAdditi.mgo_disport_eca_terms ? itemAdditi.mgo_disport_eca_terms : 0))*100)/100
            //
            dot.optimal_ifo_mt = Math.round(((item[prefixOptimal+'_ifo_eca_non_eca'] ? item[prefixOptimal+'_ifo_eca_non_eca'] : 0) + item.ifo_layExD_eca_non_eca + (itemAdditi && itemAdditi.ifo_disport_eca_terms ? itemAdditi.ifo_disport_eca_terms : 0))*100)/100
            dot.optimal_mgo_mt = Math.round(((item[prefixOptimal+'_mgo_eca_non_eca'] ? item[prefixOptimal+'_mgo_eca_non_eca'] : 0) + item.mgo_layExD_eca_non_eca + (itemAdditi && itemAdditi.mgo_disport_eca_terms ? itemAdditi.mgo_disport_eca_terms : 0))*100)/100
          }
          if(dot.status === 'passing' || dot.status === 'bunkering') {
            dot.full_ifo_mt = Math.round(((item.full_ifo_eca_non_eca ? item.full_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca)*100)/100
            dot.full_mgo_mt = Math.round(((item.full_mgo_eca_non_eca ? item.full_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca)*100)/100
            // console.log(`status ${dot.status} mgo_mt= ${(item.full_mgo_eca_non_eca ? item.full_mgo_eca_non_eca : 0)} + ${item.mgo_layExD_eca_non_eca}`)
            // console.log(`index: ${index} calc: ${(item.full_mgo_eca_non_eca ? item.full_mgo_eca_non_eca : 0)} + ${item.mgo_layExD_eca_non_eca} = ${Math.round(((item.full_mgo_eca_non_eca ? item.full_mgo_eca_non_eca : 0) + item.mgo_layExD)*100)/100}`)
            // console.log(`full_mgo_eca_non_eca: ${item.full_mgo_eca_non_eca} mgo_layExD_eca_non_eca: ${item.mgo_layExD_eca_non_eca}`)
            //
            dot.eco_ifo_mt = Math.round(((item.eco_ifo_eca_non_eca ? item.eco_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca)*100)/100
            dot.eco_mgo_mt = Math.round(((item.eco_mgo_eca_non_eca ? item.eco_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca)*100)/100
            //
            // console.log(`port ${dot.port?dot.port.name:''} status ${dot.status} ifo_mt= ${(item.eco_ifo_eca_non_eca ? item.eco_ifo_eca_non_eca : 0)} + ${item.mgo_layExD_eca_non_eca} = ${dot.eco_ifo_mt}`)
            // console.log(`port ${dot.port?dot.port.name:''} status ${dot.status} mgo_mt= ${(item.eco_mgo_eca_non_eca ? item.eco_mgo_eca_non_eca : 0)} + ${item.mgo_layExD_eca_non_eca} = ${dot.eco_mgo_mt}`)

            dot.slow_ifo_mt = Math.round(((item.slow_ifo_eca_non_eca ? item.slow_ifo_eca_non_eca : 0) + item.ifo_layExD_eca_non_eca)*100)/100
            dot.slow_mgo_mt = Math.round(((item.slow_mgo_eca_non_eca ? item.slow_mgo_eca_non_eca : 0) + item.mgo_layExD_eca_non_eca)*100)/100
            //

            // let prefixOptimal =
            dot.optimal_ifo_mt = Math.round(((item[prefixOptimal+'_ifo_eca_non_eca'] ? item[prefixOptimal+'_ifo_eca_non_eca'] : 0) + item.ifo_layExD_eca_non_eca)*100)/100
            dot.optimal_mgo_mt = Math.round(((item[prefixOptimal+'_mgo_eca_non_eca'] ? item[prefixOptimal+'_mgo_eca_non_eca'] : 0) + item.mgo_layExD_eca_non_eca)*100)/100
          }
          // console.log(`status ${dot.status} full_ifo_mt: ${dot.full_ifo_mt} full_mgo_mt: ${dot.full_mgo_mt}`)
        }

        // let cargo = dot.cargos ? dot.cargos[dot.cargos.length-1] : null
        //
        // let suff = dot.intake ? '_lad' : '_ball'
        // let wkSuff = '_idle'
        // if(cargo && cargo[dot.status+'_wk_cranes'])
        //   wkSuff = '_wk'
        //
        // let port_stay = dot.port_stay ? dot.port_stay : 0
        //
        // dot.full_ifo_mt = this.getOneIfoOrMgo(dot.dist, vessel['full_sp'+suff], weather_factor, vessel['full_ifo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        //
        // dot.full_mgo_mt = this.getOneIfoOrMgo(dot.dist, vessel['full_sp'+suff], weather_factor, vessel['full_mgo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        //
        // dot.eco_ifo_mt = this.getOneIfoOrMgo(dot.dist, vessel['eco_sp'+suff], weather_factor, vessel['eco_ifo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        // dot.eco_mgo_mt = this.getOneIfoOrMgo(dot.dist, vessel['eco_sp'+suff], weather_factor, vessel['eco_mgo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        // dot.slow_ifo_mt = this.getOneIfoOrMgo(dot.dist, vessel['slow_sp'+suff], weather_factor, vessel['slow_ifo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        // dot.slow_mgo_mt = this.getOneIfoOrMgo(dot.dist, vessel['slow_sp'+suff], weather_factor, vessel['slow_mgo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        //
        // let prefix = dot.is_full ? 'full_' : 'eco_'
        // dot.optimal_ifo_mt = this.getOneIfoOrMgo(dot.dist, vessel[prefix+'sp'+suff], weather_factor, vessel[prefix+'ifo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        // dot.optimal_mgo_mt = this.getOneIfoOrMgo(dot.dist, vessel[prefix+'sp'+suff], weather_factor, vessel[prefix+'mgo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
      })
    },
    getOneIfoOrMgo(dist=0, speed=0, weather_factor=0, weight=0, port_stay=0, consump_1=0, consump_2=0) {
      let result = 0

      result = (dist / (speed - (speed * weather_factor)/100) / 24 * weight) + (port_stay * (consump_1 + consump_2))

      result = result ? result : 0
      return result
    },

    setHireAndFreightBep() {
      this.vessels.forEach(vessel=>{
        this.setHireAndFreightBepInVessel(vessel)
      })
    },
    setHireAndFreightBepInVessel(vessel) {
      let add_com = this.activeTab?.addcom ? this.activeTab?.addcom : 0
      let vlsfo = vessel.vlsfo_price ? vessel.vlsfo_price : this.activeTab?.vlsfo ? this.activeTab?.vlsfo : 0
      let lsmgo = vessel.lsmgo_price ? vessel.lsmgo_price : this.activeTab?.lsmgo ? this.activeTab?.lsmgo : 0
      let hsfo = vessel.hsfo_price ? vessel.hsfo_price : this.activeTab?.hsfo ? this.activeTab?.hsfo : 0

      let totalIntake = 0
      let totalDisb = 0
      let totalIncome = 0
      let totalLayEx = 0

      let full_ifo_total = 0
      let eco_ifo_total = 0
      let slow_ifo_total = 0
      let optimal_ifo_total = 0
      let full_mgo_total = 0
      let eco_mgo_total = 0
      let slow_mgo_total = 0
      let optimal_mgo_total = 0

      let routes = vessel.additional_info.routes
      routes.forEach(dot=>{
        if(dot.status == 'discharging')
          totalIntake += dot.intake
        
        totalDisb += dot.disbursement
        full_ifo_total += dot.full_ifo_mt
        full_mgo_total += dot.full_mgo_mt
        eco_ifo_total += dot.eco_ifo_mt
        eco_mgo_total += dot.eco_mgo_mt
        slow_ifo_total += dot.slow_ifo_mt
        slow_mgo_total += dot.slow_mgo_mt
        optimal_ifo_total += dot.optimal_ifo_mt
        optimal_mgo_total += dot.optimal_mgo_mt

        let cargo = dot.cargos ? dot.cargos[dot.cargos.length-1] : null
        let wkSuff = '_idle'
        if(cargo && cargo[dot.status+'_wk_cranes'])
          wkSuff = '_wk'

        let lay_ex = dot.lay_ex ? +dot.lay_ex : 0
        totalLayEx += lay_ex * ((dot.has_seca ? lsmgo : hsfo) * vessel['ifo'+wkSuff] + (dot.has_seca ? lsmgo : vlsfo) * vessel['ifo'+wkSuff] + lsmgo * vessel['ifo'+wkSuff])

        if(!cargo) return false
        totalIncome += (cargo.freight * dot.intake) - (cargo.freight * dot.intake * cargo.cg_comm)/100
      })

      let hire = vessel.hire ? vessel.hire : 0
      let expenses = +vessel.expenses + +vessel.mins_cost + +vessel.insurance

      let full_consumed = full_ifo_total * (vessel.scrubber ? hsfo : vlsfo) + full_mgo_total * lsmgo + totalLayEx
      let eco_consumed = eco_ifo_total * (vessel.scrubber ? hsfo : vlsfo) + eco_mgo_total * lsmgo + totalLayEx
      let slow_consumed = slow_ifo_total * (vessel.scrubber ? hsfo : vlsfo) + slow_mgo_total * lsmgo + totalLayEx
      let optimal_consumed = optimal_ifo_total * (vessel.scrubber ? hsfo : vlsfo) + optimal_mgo_total * lsmgo + totalLayEx

      vessel.full_hire = totalIncome - (full_consumed + totalDisb + expenses) / vessel.full_voyage_days
      vessel.full_hire = vessel.full_hire ? Math.round(vessel.full_hire*10000)/10000 : 0
      vessel.eco_hire = totalIncome - (eco_consumed + totalDisb + expenses) / vessel.eco_voyage_days
      vessel.eco_hire = vessel.eco_hire ? Math.round(vessel.eco_hire*10000)/10000 : 0
      vessel.slow_hire = totalIncome - (slow_consumed + totalDisb + expenses) / vessel.slow_voyage_days
      vessel.slow_hire = vessel.slow_hire ? Math.round(vessel.slow_hire*10000)/10000 : 0
      vessel.optimal_hire = totalIncome - (optimal_consumed + totalDisb + expenses) / vessel.optimal_voyage_days
      vessel.optimal_hire = vessel.optimal_hire ? Math.round(vessel.optimal_hire*10000)/10000 : 0

      vessel.full_freight = (((hire * vessel.full_voyage_days) - ((hire * vessel.full_voyage_days * add_com)/100)) + full_consumed + totalDisb + expenses) / totalIntake
      vessel.full_freight = vessel.full_freight ? Math.round(vessel.full_freight*10000)/10000 : 0
      vessel.eco_freight = (((hire * vessel.eco_voyage_days) - ((hire * vessel.eco_voyage_days * add_com)/100)) + eco_consumed + totalDisb + expenses) / totalIntake
      vessel.eco_freight = vessel.eco_freight ? Math.round(vessel.eco_freight*10000)/10000 : 0
      vessel.slow_freight = (((hire * vessel.slow_voyage_days) - ((hire * vessel.slow_voyage_days * add_com)/100)) + slow_consumed + totalDisb + expenses) / totalIntake
      vessel.slow_freight = vessel.slow_freight ? Math.round(vessel.slow_freight*10000)/10000 : 0
      vessel.optimal_freight = (((hire * vessel.optimal_voyage_days) - ((hire * vessel.optimal_voyage_days * add_com)/100)) + optimal_consumed + totalDisb + expenses) / totalIntake
      vessel.optimal_freight = vessel.optimal_freight ? Math.round(vessel.optimal_freight*10000)/10000 : 0
    },

    setVoyageDays() {
      this.vessels.forEach(vessel=>{
        this.setVoyageDaysInVessel(vessel)
      })
    },
    setVoyageDaysInVessel(vessel) {
      let routes = vessel.additional_info.routes
      this.fuelList.forEach(fuel => {
        let voyage = 0
        routes.forEach(dot=>{
          if(dot[fuel+'_streaming_days'])
            voyage += dot[fuel+'_streaming_days']
          // if(dot[fuel+'_streaming_time']){
          //   let time = dot[fuel+'_streaming_time'].split(':')
          //   voyage += +time[0]/24
          //   voyage += +time[1]/24/60
          // }

          if(dot.port_stay)
            voyage += dot.port_stay
          
          if(dot.lay_ex)
            voyage += +dot.lay_ex
        })
        voyage = voyage ? Math.round(voyage*10000)/10000 : 0
        vessel[fuel+'_voyage_days'] = voyage
      })
    },

    async onUpdateIntakeInRoute(vessel) {
      this.checkChangeDaysSteaming(vessel);
      // this.setSteamingForRoutesInVessel(vessel)
      this.setResultsForRoutesInVessel(vessel)
      console.log(1); await this.calcAdditionalyInfoForVessel({vessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
      // await this.$nextTick()
      // setTimeout(()=> {
        this.setIfoMgoForRoutesInVessel(vessel)
        this.setHireAndFreightBepInVessel(vessel)
      // }, 300)

    },
    async onUpdateDistInRoute(vessel) {
      // this.setSteamingForRoutesInVessel(vessel)
      this.checkChangeDaysSteaming(vessel);
      this.setResultsForRoutesInVessel(vessel)

      console.log(1); await this.calcAdditionalyInfoForVessel({vessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
      // await this.$nextTick()
      // setTimeout(()=> {
        this.setIfoMgoForRoutesInVessel(vessel)
      // }, 300)
    },
    async onUpdateSecaInRoute(vessel, bool) {
      this.setHireAndFreightBepInVessel(vessel)
      this.setResultsForRoutesInVessel(vessel)
      if(bool !== true) {
        console.log(1); await this.calcAdditionalyInfoForVessel({vessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
      }
      // await this.$nextTick()
      // setTimeout(()=> {
        this.setIfoMgoForRoutesInVessel(vessel)
      // }, 300)
    },
    onUpdateDraftInRoute(vessel) {
      console.log('dopCalc onUpdateDraftInRoute')
      this.setIntakeForRoutesInVessel(vessel)
    },
    async onUpdatePortStayInRoute(vessel) {
      this.setArrivalAndDepartureForRoutesInVessel(vessel)
      console.log(1); await this.calcAdditionalyInfoForVessel({vessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
      // await this.$nextTick()
      // setTimeout(()=> {
        this.setIfoMgoForRoutesInVessel(vessel)
        this.setVoyageDaysInVessel(vessel)
      // }, 300)
    },
    async onUpdateSteamingInRoute(vessel) {
      this.setArrivalAndDepartureForRoutesInVessel(vessel)
      this.setVoyageDaysInVessel(vessel)
      console.log(1); await this.calcAdditionalyInfoForVessel({vessel: this.getActiveVessel, cargos: this.cargos, fuelType: this.fuelType, calculatorTab: this.activeTab})
    },
    onChengeCheckInOptimalInRoute(vessel) {
      this.setHireAndFreightBepInVessel(vessel)

      let routes = vessel.additional_info.routes
      vessel.optimal_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.optimal_result, 0
      )
      vessel.optimal_result = vessel.optimal_result ? Math.round(vessel.optimal_result*10000)/10000 : 0
    },
    onUpdatePriceInVessels() {
      if(!this.watchesTabPrices) return false
      let prices = this.watchesTabPrices

      this.vessels.forEach(vessel=>{
        if(!vessel.is_changed_vlsfo) vessel.vlsfo_price = prices.vlsfo
        if(!vessel.is_changed_lsmgo) vessel.lsmgo_price = prices.lsmgo
        if(!vessel.is_changed_hsfo) vessel.hsfo_price = prices.hsfo
      })
    },

    checkRoutes() {
      this.vessels.forEach(vessel => {
        vessel.additional_info.routes = vessel.additional_info.routes.filter(route=>{
          return route.route_v == '2' && (!route.route_id || this.routes.find(el=>el.id==route.route_id))
        }) //filtered old version routes and deleted from route tab 
        
        if(!vessel.additional_info.routes.length){
          vessel.additional_info.routes = JSON.parse(JSON.stringify(this.getRoutesForVessels))
        }else{
          vessel.additional_info.routes.forEach(route => { //update data
            let realRoute = this.routes.find(el=>el.id==route.route_id)
            if(!realRoute) return
            route.status = realRoute.status
            route.port = realRoute.port
            //route.port_name = realRoute.port?.name
            if(route.from_port && route.from_port != realRoute.from_port){
              route.dist = Math.round((realRoute.dist)*100)/100
              route.seca = Math.round((realRoute.seca)*100)/100
              route.has_seca = realRoute.has_seca
              route.from_port = realRoute.from_port
            }
            route.quantity = 0
            let relativeCargos = (Array.isArray(this.cargos) == true ? this.cargos : []).filter(cargo=>{
              let relativeRoute = cargo[realRoute.status+'_port_name']
              if(!relativeRoute) return false
              relativeRoute = +relativeRoute.replace('#', '')
              return relativeRoute == route.route_id
            })
            relativeCargos.forEach(cargo => {
              route.quantity += cargo.ballast_voyage ? 0 : this.getQuantityFromCargo(cargo)
            })
            route.cargos = relativeCargos.map(el=>el.id)
          })


          if(vessel.additional_info.routes.filter(el=>el.route_id).length != this.routes.length){ //need add new route
            // this.routes.sort((a, b)=> a.ordering-b.ordering)
            let indexPrev = 1
            this.routes.forEach((route, i)=>{
              if(!vessel.additional_info.routes.find(el=>el.route_id == route.id)){
                // console.log(route.id)
                if(i>0 && vessel.additional_info.routes.findIndex(el=>el.route_id == this.routes[i-1].id) >= 0)
                  indexPrev = vessel.additional_info.routes.findIndex(el=>el.route_id == this.routes[i-1].id)

                vessel.additional_info.routes.splice(indexPrev, 0, this.createNewRoute(route))

              }
            })
          }
          
        }

        //check ordering
        // vessel.additional_info.routes.forEach((route, i)=>{
        //   if(!route.route_id && !this.routes.find(el=>el.id==route.route_id)) route.ordering = i
        //   else{
        //     route.ordering = this.routes.find(el=>el.id==route.route_id).ordering_index+1
        //   }
        // })
        // vessel.additional_info.routes.sort((a,b)=>a.ordering - b.ordering)
      })
    },
    createNewRoute(route) {
      let newRoute = {...this.emptyDot}
      if(!route) return newRoute

      newRoute.route_id = route.id
      newRoute.status = route.status
      newRoute.port = route.port
      //newRoute.port_name = route.port?.name
      newRoute.dist = Math.round((route.dist)*100)/100
      newRoute.seca = Math.round((route.seca)*100)/100
      newRoute.has_seca = route.has_seca
      newRoute.from_port = route.from_port
      newRoute.quantity = 0

      let relativeCargos = (Array.isArray(this.cargos) == true ? this.cargos : []).filter(cargo=>{
        let relativeRoute = cargo[newRoute.status+'_port_name']
        if(!relativeRoute) return false
        relativeRoute = +relativeRoute.replace('#', '')
        return relativeRoute == newRoute.route_id
      })
      relativeCargos.forEach(cargo => {
        newRoute.quantity += cargo.ballast_voyage ? 0 : this.getQuantityFromCargo(cargo)
      })
      newRoute.cargos = relativeCargos.map(el=>el.id)

      return newRoute
    },
    async onSelectPort(newPort, oldPort, index) {

      // if(index == this.getActiveVesselRoutes.length-1){//redelivery
      //   if(this.vessels[0].id == this.getActiveVessel.id){//update other vessels
      //     this.vessels.forEach((vessel, i)=>{
      //       if(i == 0 || vessel.additional_info.routes[index].port?.id != oldPort?.id) return false
      //       vessel.additional_info.routes[index].port = newPort
      //     })
      //   }
      // }else if(index != 0){
      //   this.vessels.forEach(vessel=>{
      //     vessel.additional_info.routes[index].port = newPort
      //   })
      // }
      this.getActiveVessel.additional_info.routes[index].port = newPort
      this.getActiveVessel.additional_info.routes[index]['from_port'] = null
      // this.vessels.forEach(vessel=>{
      //   vessel.additional_info.routes[index]['from_port'] = null
      // })
      // await this.setDistanceForRoutes()
      this.showLoaderTable = true
      await this.onSetRoutesInfo(false, false, )
      // setTimeout(()=> {
        this.setIntakeForRoutesInVessel(this.getActiveVessel)
        this.checkTotalTimeRoutes()
        this.checkEmptyRoute()
      this.showLoaderTable = false
      // }, 100)
    },
    getQuantityFromCargo(cargo) {
      if(cargo.quantity_mt) return cargo.quantity_mt
      else if(cargo.quantity_min && cargo.quantity_max) return Math.round(((+cargo.quantity_min+cargo.quantity_max)/2)*1000000)/1000000
      else if(cargo.quantity_max) return cargo.quantity_max
      else if(cargo.quantity_min) return cargo.quantity_min
      else return 0
    },
    checkTotalTimeRoutes() {
      if(this.getActiveVesselRoutes && this.tableFullSpeed) {
        this.getActiveVesselRoutes.filter(el=>el.route_id).forEach((route, index)=> {
          if(this.tableFullSpeed[index]) {
            if(route['full_total_time'] == undefined || route['full_total_time'] == null) {
              route['full_total_time'] = this.tableFullSpeed[index].full_total_time
            }
            if(route['eco_total_time'] == undefined || route['eco_total_time'] == null) {
              route['eco_total_time'] = this.tableFullSpeed[index].eco_total_time
            }
            if(route['slow_total_time'] == undefined || route['slow_total_time'] == null) {
              route['slow_total_time'] = this.tableFullSpeed[index].slow_total_time
            }
            let typeOptimal = route.is_full ? 'full' : route.is_eco ? 'eco' : 'slow'
            // if(this.tableFullSpeed[index]) {
            //   if ((this.tableFullSpeed[index].eco_bunker_plus_hire < this.tableFullSpeed[index].full_bunker_plus_hire) && (this.tableFullSpeed[index].eco_bunker_plus_hire < this.tableFullSpeed[index].slow_bunker_plus_hire) && this.tableFullSpeed[index].eco_bunker_plus_hire !== 0 || (this.tableFullSpeed[index].slow_bunker_plus_hire === 0 && this.tableFullSpeed[index].full_bunker_plus_hire === 0)) {
            //     typeOptimal = 'eco'
            //   } else if ((this.tableFullSpeed[index].full_bunker_plus_hire < this.tableFullSpeed[index].slow_bunker_plus_hire && this.tableFullSpeed[index].full_bunker_plus_hire !== 0) || this.tableFullSpeed[index].slow_bunker_plus_hire === 0) {
            //     typeOptimal = 'full'
            //   } else {
            //     typeOptimal = 'slow'
            //   }
            // } else {
            //   typeOptimal = 'slow'
            // }

            if(route['optimal_total_time'] == undefined || route['optimal_total_time'] == null) {
              route['optimal_total_time'] = this.tableFullSpeed[index][typeOptimal+'_total_time']
            }
          }

        })
      }

    },
    checkChangeDaysSteaming(vessel) {
      console.log('checkChangeDaysSteaming start')
      // if(this.fuelType !== 'optimal') {
      // vessel.additional_info.routes.filter(el=>el.route_id)
      if(!vessel || !vessel.additional_info || Array.isArray(vessel.additional_info.routes) !== true) {
        return null;
      }
      console.log('checkChangeDaysSteaming 1')
        vessel.additional_info.routes.forEach((route, index)=> {
          if(this.tableFullSpeed[index] && this.tableFullSpeed[index].full_total_time) {
          // if((route['full_total_time'] || route['full_total_time'] == 0) && route.full_total_time !== this.tableFullSpeed[index].full_total_time || (!route['full_total_time'] && route['full_total_time'] !== 0)) {
            route['full_total_time'] = this.tableFullSpeed[index].full_total_time ? this.tableFullSpeed[index].full_total_time : 0
            let time = parseFloat(JSON.parse(JSON.stringify(this.tableFullSpeed[index].full_total_time)))
            route.full_streaming_days = time ? Math.round(time*100)/100 : 0;

            // route.full_streaming_time = time > 0 ? ( Math.trunc((time - route.full_streaming_days)*24) + ':' + Math.trunc(((time - route.full_streaming_days)*24 - Math.trunc((time - route.full_streaming_days)*24))*60) ) : '00:00';
          }
          if(this.tableFullSpeed[index] && this.tableFullSpeed[index].eco_total_time) {
          // if((route['eco_total_time'] || route['eco_total_time'] == 0) && route.eco_total_time !== this.tableFullSpeed[index].eco_total_time || (!route['eco_total_time'] && route['eco_total_time'] !== 0)) {
            route['eco_total_time'] = this.tableFullSpeed[index].eco_total_time ? this.tableFullSpeed[index].eco_total_time : 0
            let time = parseFloat(JSON.parse(JSON.stringify(this.tableFullSpeed[index].eco_total_time)))
            route.eco_streaming_days = time ? Math.round(time*100)/100 : 0;
            // route.eco_streaming_time = time > 0 ? ( Math.trunc((time - route.eco_streaming_days)*24) + ':' + Math.trunc(((time - route.eco_streaming_days)*24 - Math.trunc((time - route.eco_streaming_days)*24))*60)) : '00:00';
          }
          if(this.tableFullSpeed[index] && this.tableFullSpeed[index].slow_total_time) {
          // if((route['slow_total_time'] || route['slow_total_time'] == 0) && route.slow_total_time !== this.tableFullSpeed[index].slow_total_time || (!route['slow_total_time'] && route['slow_total_time'] !== 0)) {
            route['slow_total_time'] = this.tableFullSpeed[index].slow_total_time ? this.tableFullSpeed[index].slow_total_time : 0
            let time = parseFloat(JSON.parse(JSON.stringify(this.tableFullSpeed[index].slow_total_time)))
            route.slow_streaming_days = time ? Math.round(time*100)/100 : 0;
            // route.slow_streaming_time = time > 0 ? ( Math.trunc((time - route.slow_streaming_days)*24) + ':' + Math.trunc(((time - route.slow_streaming_days)*24 - Math.trunc((time - route.slow_streaming_days)*24))*60)) : '00:00';
          }
          let typeOptimal = route.is_full ? 'full' : route.is_eco ? 'eco' : 'slow'
          // if ((this.tableFullSpeed[index].eco_bunker_plus_hire < this.tableFullSpeed[index].full_bunker_plus_hire) && (this.tableFullSpeed[index].eco_bunker_plus_hire < this.tableFullSpeed[index].slow_bunker_plus_hire) && this.tableFullSpeed[index].eco_bunker_plus_hire !== 0 || (this.tableFullSpeed[index].slow_bunker_plus_hire === 0 && this.tableFullSpeed[index].full_bunker_plus_hire === 0)) {
          //   typeOptimal = 'eco'
          // } else if ((this.tableFullSpeed[index].full_bunker_plus_hire < this.tableFullSpeed[index].slow_bunker_plus_hire && this.tableFullSpeed[index].full_bunker_plus_hire !== 0) || this.tableFullSpeed[index].slow_bunker_plus_hire === 0) {
          //   typeOptimal = 'full'
          // } else {
          //   typeOptimal = 'slow'
          // }
          if(this.tableFullSpeed[index] && this.tableFullSpeed[index][typeOptimal + '_total_time']) {
          // if((route['optimal_total_time'] || route['optimal_total_time'] == 0) && route.optimal_total_time !== this.tableFullSpeed[index][typeOptimal + '_total_time'] || (!route['optimal_total_time']  && route['optimal_total_time'] !== 0)) {
            route['optimal_total_time'] = this.tableFullSpeed[index][typeOptimal + '_total_time'] ? this.tableFullSpeed[index][typeOptimal + '_total_time'] : 0
            let time = parseFloat(JSON.parse(JSON.stringify(this.tableFullSpeed[index][typeOptimal + '_total_time'])))
            route.optimal_streaming_days = time ? Math.round(time*100)/100 : 0;
            // route.optimal_streaming_time = time > 0 ? ( Math.trunc((time - route.optimal_streaming_days)*24) + ':' + Math.trunc(((time - route.optimal_streaming_days)*24 - Math.trunc((time - route.optimal_streaming_days)*24))*60)) : '00:00';
          }
          // console.log('optimal_total_time', route.optimal_total_time, route.optimal_streaming_days, route.optimal_streaming_time, typeOptimal)
        })
      // }
    },
    setCorrectCargos() {
      if(this.getActiveVessel) {
        this.getActiveVessel.additional_info.routes.forEach(route => {
          route.cargos = []
        })
        this.cargos.forEach(cargo=> {
          let route = this.getActiveVessel.additional_info.routes.find(a=> a.route_id == (''+cargo.discharging_port_name).replace('#',''))
          if(route) {
            route.cargos.push(cargo.id)
          }
          route = this.getActiveVessel.additional_info.routes.find(a=> a.route_id == (''+cargo.loading_port_name).replace('#',''))
          if(route) {
            route.cargos.push(cargo.id)
          }
          if(route) {
            route.cargos = Array.from(new Set(route.cargos))
          }
        })
      }
    },
    getShowTable() {
      return (''+window.location.href).indexOf('159.69.137.227') === -1
    },
    checkChangePortStay(vessel) {
      // console.log('checkChangePortStay')
      if(vessel && vessel.additional_info) {
        vessel.additional_info.routes.forEach(dot=> {
          if (dot.status === 'loading' || dot.status === 'discharging') {
            // console.log(dot.status, 'dot.status')
            let items = this.tableAdditionalInfoBssLoadedCargoes.filter(a => a.status === dot.status && a.route_id === dot.route_id)
            if (Array.isArray(items) && items.length) {
              // console.log('items', items)
              let port_stay = 0
              items.forEach(item => {
                port_stay += (dot.status === 'loading' ? (item.loadtime_tt && item.loadtime_tt !== 'NaN' ? item.loadtime_tt : 0) : (item.distime_tt && item.distime_tt !== 'NaN' ? item.distime_tt : 0))
                // console.log('item.distime_tt: ', item.distime_tt)
              })
              // console.log('port_stay', port_stay, )
              dot.port_stay = Math.round(port_stay*100)/100

            } else {
              dot.port_stay = 0
            }
          }
        })
      }

      // vessel.additional_info.routes.filter(el=>el.route_id).forEach((route)=> {
      //   let data = this.tableAdditionalInfoBssLoadedCargoes.find(a=> a.route_id === route.route_id)
      //   if(data) {
      //     if(route.status === 'loading') {
      //       // if((route['loadtime_tt'] || route['loadtime_tt'] == 0) && route.loadtime_tt !== this.tableFullSpeed[index].loadtime_tt) {
      //       // route.loadtime_tt = data.loadtime_tt ? data.loadtime_tt : 0;
      //       route.port_stay = JSON.parse(JSON.stringify(data.loadtime_tt ? data.loadtime_tt : 0))
      //       // console.log('data.loadtime_ttd',route.loadtime_tt, route.port_stay)
      //       // }
      //     }
      //     if(route.status === 'discharging') {
      //       // if((route['distime_tt'] || route['distime_tt'] == 0) && route.distime_tt !== this.tableFullSpeed[index].distime_tt) {
      //       // route.distime_tt = data.distime_tt ? data.distime_tt : 0;
      //       route.port_stay = JSON.parse(JSON.stringify(data.distime_tt ? data.distime_tt : 0))
      //       // }
      //     }
      //   }
      // })
    },
    setIndex() {
      this.vessels.forEach(vessel => {
        if(vessel.additional_info && Array.isArray(vessel.additional_info.routes) === true) {
          let sortBool = false;
          vessel.additional_info.routes.forEach((route) => {
            if(route['ordering'] !== undefined && route['ordering'] !== null){
              sortBool = true
            }
          })
          if(sortBool) {
            // console.log(vessel.additional_info.routes.map(a=> {return a.port ? a.port.name : 'no'}))
            // console.log(vessel.additional_info.routes.map(a=> {return a.ordering}))
            // vessel.additional_info.routes.sort((a, b) => a.ordering > b.ordering ? 1 : -1);
            // console.log(vessel.additional_info.routes.map(a=> {return a.port ? a.port.name : 'no'}))
            // console.log(vessel.additional_info.routes.map(a=> {return a.ordering}))
          }
          // vessel.additional_info.routes.forEach((route, index) => {
          //   route['ordering'] = index
          // })
        }
      })
    },
    roundRoutesInVessel() {
      this.vessels.forEach(vessel => {
        if(vessel.additional_info && Array.isArray(vessel.additional_info.routes) === true)
        vessel.additional_info.routes.forEach(rout=> {
          rout.dist = rout.dist ? Math.round(rout.dist*100)/100 : rout.dist
          rout.seca = rout.seca ? Math.round(rout.seca*100)/100 : rout.seca
          rout.draft_restr = rout.draft_restr ? Math.round(rout.draft_restr*100)/100 : rout.draft_restr
          rout.disbursements = rout.disbursements ? Math.round(rout.disbursements*100)/100 : rout.disbursements
          rout.full_streaming_days = rout.full_streaming_days ? Math.round(rout.full_streaming_days*100)/100 : rout.full_streaming_days
          rout.slow_streaming_days = rout.slow_streaming_days ? Math.round(rout.slow_streaming_days*100)/100 : rout.slow_streaming_days
          rout.eco_streaming_days = rout.eco_streaming_days ? Math.round(rout.eco_streaming_days*100)/100 : rout.eco_streaming_days
          rout.optimal_streaming_days = rout.optimal_streaming_days ? Math.round(rout.optimal_streaming_days*100)/100 : rout.optimal_streaming_days
          rout.port_stay = rout.port_stay ? Math.round(rout.port_stay*100)/100 : rout.port_stay
          rout.lay_ex = rout.lay_ex ? Math.round(rout.lay_ex*100)/100 : rout.lay_ex
          rout.full_ifo_mt = rout.full_ifo_mt ? Math.round(rout.full_ifo_mt*100)/100 : rout.full_ifo_mt
          rout.slow_ifo_mt = rout.slow_ifo_mt ? Math.round(rout.slow_ifo_mt*100)/100 : rout.slow_ifo_mt
          rout.eco_ifo_mt = rout.eco_ifo_mt ? Math.round(rout.eco_ifo_mt*100)/100 : rout.eco_ifo_mt
          rout.optimal_ifo_mt = rout.optimal_ifo_mt ? Math.round(rout.optimal_ifo_mt*100)/100 : rout.optimal_ifo_mt
          rout.full_mgo_mt = rout.full_mgo_mt ? Math.round(rout.full_mgo_mt*100)/100 : rout.full_mgo_mt
          rout.slow_mgo_mt = rout.slow_mgo_mt ? Math.round(rout.slow_mgo_mt*100)/100 : rout.slow_mgo_mt
          rout.eco_mgo_mt = rout.eco_mgo_mt ? Math.round(rout.eco_mgo_mt*100)/100 : rout.eco_mgo_mt
          rout.optimal_mgo_mt = rout.optimal_mgo_mt ? Math.round(rout.optimal_mgo_mt*100)/100 : rout.optimal_mgo_mt
        })
      })
    },
  },
  mounted() {
    this.setActiveFuelType(this.fuelType)
    // this.onSetRoutesInfo(true, true)
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if(localStorage.getItem('lastFuelTypeInCalc')){
      this.fuelType = localStorage.getItem('lastFuelTypeInCalc')
    }
    if(localStorage.getItem(this.$route.params.id+'piracyActive') && localStorage.getItem(this.$route.params.id+'piracyActive') !== undefined && localStorage.getItem(this.$route.params.id+'piracyActive') !== null) {
      this.piracyActive = JSON.parse(localStorage.getItem(this.$route.params.id+'piracyActive'))
    } else {
      this.piracyActive = {code: '001', description: 'The shortest path '}
    }
    if(localStorage.getItem(this.$route.params.id+'secaBypassOption') && localStorage.getItem(this.$route.params.id+'secaBypassOption') !== undefined && localStorage.getItem(this.$route.params.id+'secaBypassOption') !== null) {
      this.secaBypassOption = JSON.parse(localStorage.getItem(this.$route.params.id+'secaBypassOption'))
    } else {
      this.secaBypassOption = {option: 'normal', description: ''}
    }
    if(localStorage.getItem(this.$route.params.id+'secaCostRation') && localStorage.getItem(this.$route.params.id+'secaCostRation') !== undefined && localStorage.getItem(this.$route.params.id+'secaCostRation') !== null) {
      this.secaCostRation = JSON.parse(localStorage.getItem(this.$route.params.id+'secaCostRation'))
    } else {
      this.secaCostRation = {option: 'normal', description: ''}
    }

  },
  async beforeMount() {
    this.showLoaderTable = true
    if(localStorage.getItem('lastFuelTypeInCalc')){
      this.fuelType = localStorage.getItem('lastFuelTypeInCalc')
    }
    if(localStorage.getItem(this.$route.params.id+'piracyActive') && localStorage.getItem(this.$route.params.id+'piracyActive') !== undefined && localStorage.getItem(this.$route.params.id+'piracyActive') !== null) {
      this.piracyActive = JSON.parse(localStorage.getItem(this.$route.params.id+'piracyActive'))
    } else {
      this.piracyActive = {code: '001', description: 'The shortest path '}
    }
    if(localStorage.getItem(this.$route.params.id+'secaBypassOption') && localStorage.getItem(this.$route.params.id+'secaBypassOption') !== undefined && localStorage.getItem(this.$route.params.id+'secaBypassOption') !== null) {
      this.secaBypassOption = JSON.parse(localStorage.getItem(this.$route.params.id+'secaBypassOption'))
    } else {
      this.secaBypassOption = {option: 'normal', description: ''}
    }
    if(localStorage.getItem(this.$route.params.id+'secaCostRation') && localStorage.getItem(this.$route.params.id+'secaCostRation') !== undefined && localStorage.getItem(this.$route.params.id+'secaCostRation') !== null) {
      this.secaCostRation = JSON.parse(localStorage.getItem(this.$route.params.id+'secaCostRation'))
    } else {
      this.secaCostRation = {option: 'normal', description: ''}
    }
    this.showLoad()
    let vessel = await this.axiosGetCalcVessel(this.$route.params.id)
    // vessel.forEach(item=> {
    //   item.loadtime_tt = item.loadtime_tt ? item.loadtime_tt : null
    //   item.distime_tt = item.distime_tt ? item.distime_tt : null
    // })
    vessel.forEach(el=> {
      if(!el['additional_info']['duplicateNumber']) {
        el['additional_info']['duplicateNumber'] = null
      }
      if(!el['additional_info']['canal_code']) {
        el['additional_info']['canal_code'] = '100'
      }

    })
    this.vessels = vessel;

    if(this.vessels.length === 0) {
      await this.onCreate('Invisible vessel - not show')
      // setTimeout(async ()=> {
      //   await this.onCreateRoutes()
      //   await this.onCreateRoutes()
      //   await this.onCreateRoutes()
      //   await this.onCreateRoutes()
      //   setTimeout(()=> {
      //     this.vessels[0].additional_info.routes[1].status = 'loading'
      //     this.vessels[0].additional_info.routes[2].status = 'discharging'
      //   }, 100)
      //
      // }, 150)

    }
    this.roundRoutesInVessel()
    this.setIndex()
    this.cargos = []
    this.cargos = await this.axiosGetCalcCargoByIdVessel({idTab:this.$route.params.id, idVessel: this.selectedId})

    this.routes = await this.axiosGetCalcRoute(this.$route.params.id)

    this.setCorrectCargos()
    this.hideLoad()

    // this.checkRoutes()
    this.checkRoutesAndCargos()
    // this.onSetRoutesInfo()

    this.checkTotalTimeRoutes()

    this.copyRoutes = this.getActiveVesselRoutes ? JSON.parse(JSON.stringify(this.getActiveVesselRoutes)) : []

    if(this.vessels.length){
      // if(this.vessels.find(a=> a.name == 'Invisible vessel - not show')) {
      //   let item = this.vessels.find(a=> a.name == 'Invisible vessel - not show')
      //   this.onSelect(item.id)
      // } else if(localStorage.getItem('lastActiveVesselInCalc')){
      //   let findItem = this.vessels.find(el=>el.id == localStorage.getItem('lastActiveVesselInCalc'))
      //   if(findItem)
      //     this.onSelect(findItem.id)
      //   else
      //     this.onSelect(this.vessels[0].id)
      // }else
      //   this.onSelect(this.vessels[0].id)
      let vesselsFiltered = this.vessels.filter(a=> a.name !== 'Invisible vessel - not show')
      let itemSelect = null
      if(vesselsFiltered.length !== 0) {
        itemSelect = vesselsFiltered[0]
      } else {
        itemSelect = this.vessels.find(a=> a.name == 'Invisible vessel - not show')
      }
      this.onSelect(itemSelect.id)
    }

  }
}
</script>

<style lang="scss">
.calc_vessels__row_vessels{
  &>div{
    &:nth-child(1) {width: 40.44%;}
    &:nth-child(2) {width: 23.90%;}
    &:nth-child(3) {width: 35.68%;}
  }
}
.calc_vessels__row_vessels__speed{
  display: grid;
  grid-template-columns: repeat(3, 33.333%) ;
  .colspan-1-3{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .colspan-3-5{
    grid-column-start: 3;
    grid-column-end: 5;
  }
}
.calc_vessels__dot_row{
  display: grid;
  grid-template-columns: 
    2.2003%//1
    2.2003%//2
    7.2838%//Intake
    7.8906%//Port/canal
    6.9044%//Status
    3.7936%//Dist
    4.78%//Seca
    4.7187%//Draft rest
    5.6%//Disbursements
    7.2837%//Days steaming
     5.0835%//port stay


    4.4765%//layExd

    3.4143%//speed
    1.7451%//f
    1.7451%//e
    1.7451%//s
    4.173%//IFO
    4.173%//MGO
      5.8422%//arrival1
    3.4901%//arrival2
        5.8422%//departure1
    3.4901%//departure2
    2.1244%;//delete
  .colspan-1-3{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .colspan-3-9{
    grid-column-start: 3;
    grid-column-end: 9;
  }
  .colspan-10-12{
    grid-column-start: 10;
    grid-column-end: 12;
  }
  .colspan-11-13{
    grid-column-start: 11;
    grid-column-end: 13;
  }
  .colspan-15-17{
    grid-column-start: 15;
    grid-column-end: 17;
  }
  .colspan-15-21{
    grid-column-start: 15;
    grid-column-end: 21;
  }
  >div:not(:last-child){
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-gray-text);
  }
  .multiselect__tags {
    min-height: 28px;
    padding-top: 4px;
    padding-right: 20px;
    padding-left: 4px;
    border: 0;
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
  }
  .async_select__wrap .multiselect__single {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .multiselect, 
  .multiselect__input, 
  .multiselect__single{
    font-size: 14px;
  }
  .multiselect__input, 
  .multiselect__single{
    margin-bottom: 4px;
  }
}
.calc_vessels__list_row{
  display: grid;
  grid-template-columns: 48.44% 12.1% 9.77% 23.44% 6.25%;
  >div:not(:last-child){
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-gray-text);
  }
}
</style>

<style scoped lang="scss">
.shadow-table {
  position: relative;
}
.blocked-changes {
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.table-overflow {
  width: calc(100vw - 210px - 24px) !important;
  box-sizing: border-box;
  overflow-x: auto;
  td {
    padding-right: 5px;
  }
  &::-webkit-scrollbar
  {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb
  {
    background: #BDCBD6;
    border-radius: 30px;
    width: 9px;
  }
}
.loader-patch {
  /deep/ {
    width: 35px;
    height: 35px;
    position: fixed;
    top: auto;
    left: auto;
    bottom: 20px;
    right: 20px;
    background-color: transparent !important;
  }
}
.blue-button-border-text{
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  margin-left: 32px;
  border-radius: 4px;
  width: 100px;
  height: 28px;
  border: 1px solid var(--color-blue-dark);
  justify-content: center;
  color: var(--color-blue-dark);
  svg {
    path {
      fill: var(--color-blue-dark)
    }
  }
}
.map-view-modal {
  /deep/ {
    .map-component {
       position: absolute;
      top: 41px;
      left: 0;
      width: 100%;
      height: calc(100% - 41px);
      border-radius: 0 0 8px 8px;
      overflow: hidden;
    }
    .top-panel {
      padding: 8px 16px !important;
    }
    &>.content {
      margin: 0;
      top: 20px;
      width: calc(100vw - 48px);
      height: calc(100vh - 40px);
    }
    .bottom-panel {
      button {
        display: none;
      }
    }
  }
}
.table-additional-info {
  background-color: var(--color-bgc-page-white);
  .calc-total-times {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 0 !important;
    padding-left: 6px;
    /deep/ {
      &>* {
        flex: 1;
        color: var(--color-new-dark-gray);
      }
      span {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
  .blue-button {
    border-radius: 0 7px 0 0 ;
    margin-left: auto;
    background-color: var(--color-blue-dark);
    height: 24px;
    width: 148px;
  }
  /deep/ {

  }
  .table-additional-info-bottom-row {
    &>*:nth-child(1) {
      border-radius: 0 0 0 7px;
    }
    .select-weather-factor {
      border-radius: 0;
      border-left: 1px solid var(--color-gray-light);
      border-right: 1px solid var(--color-gray-light);
      input {
        border: none;
        background-color: transparent;
        height: 24px;
      }

      flex: 6;
    }
    .select-bypass, .select-piracy {
      border-radius: 0;
      border-left: 1px solid var(--color-gray-light);
      /deep/ {
        .block-selected {
          width: 100%;
          box-sizing: border-box;
          border: none;
          border-radius: 0;
          height: 24px;
        }
      }
    }
    .select-bypass {
      flex: 7;
      border-right: 1px solid var(--color-gray-light);
    }
    .select-piracy {
     flex: 17;
    }
    .canal-code {
      align-items: stretch;
      /deep/ {
        .wrap-checkbox {
          margin-left: 0;
          border-left: 1px solid var(--color-gray-light);
          display: flex;
          align-items: center;
          padding-left: 6px;
          padding-right: 7px;
        }
      }
    }
    &>* {

    }
  }
}
</style>