<template>
  <transition name="fade">
    <div class="wrap-transition" v-if="show">
      <div class="filter" v-on:click="hideMenu"></div>
      <div class="wrap" :class="{'show-menu': show}">
        <div class="top-block">
          <button class="close-menu fz14 fw500 text-dark" v-on:click="hideMenu">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
              <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
            </svg>
            Filter
          </button>
          <button class="clearButton fz14 text-red" v-on:click="clearFilter">Clear</button>
        </div>
        <!--      {{routesDetails}}-->
        <div class="">
          <div class="block-pre-dropdown" v-on:click="hideBlock1 = !hideBlock1">
            <p class="fz13 fw500 text-dark">Reference</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock1}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" :class="{'dnBlock': hideBlock1}">
            <async-select
                v-model="activeAccountName"
                :allowEmpty="true"
                label="Account name"
                :is-multiple="true"
                :beforeWatchKey="getPermissionShowDopInfoAcc() ? 'account_id' : null"
                url="/payment/account"
                :placeholder="''"
                :params="{is_archive: false}"
                :isMultiple="true"
            ></async-select>
          </div>
        </div>
        <div class="">
          <div class="block-pre-dropdown" v-on:click="hideBlock3 = !hideBlock3">
            <p class="fz13 fw500 text-dark">Bank and Cash Accounts</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock3}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" :class="{'dnBlock': hideBlock3}">
            <bank-and-cash-select
                class="bank-and-cash-select"
                :key="keyRenderBank + 'sdg'"
                :isMultiple="true"
                :placeholder="''"
                label="Bank and Cash Accounts"
                :allow-empty="true"
                :selected-requisites="activeRequisiteAccount"
                :selected-banks="activeBankAccount"
            ></bank-and-cash-select>
          </div>
        </div>
        <div class="">
          <div class="block-pre-dropdown" v-on:click="hideBlock4 = !hideBlock4">
            <p class="fz13 fw500 text-dark">Payment, Income</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock4}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" :class="{'dnBlock': hideBlock4}">
            <div class="input-from-to">
              <input type="text" placeholder="From" v-model="paymentFrom">
              <input type="text" placeholder="To" v-model="paymentTo">
            </div>
          </div>
        </div>
        <div class="" :class="{'dnBlock': $route.path.indexOf('payment/instructions') >= 0}">
          <div class="block-pre-dropdown" v-on:click="hideBlock5 = !hideBlock5">
            <p class="fz13 fw500 text-dark">Status</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock5}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" :class="{'dnBlock': hideBlock5}">
            <div class="block-status">
              <p class="fz13 text-gray">Payment</p>
              <payment-list-filter-checkboxes :list="listPaymentStatus" :select="selectPayment" @changeSelect="changeSelectPayment"></payment-list-filter-checkboxes>
              <p class="fz13 text-gray mt12" v-if="$route.path.indexOf('payment/instructions') === -1">Income</p>
              <payment-list-filter-checkboxes v-if="$route.path.indexOf('payment/instructions') === -1" :list="listIncomeStatus" :select="selectIncome" @changeSelect="changeSelectIncome"></payment-list-filter-checkboxes>
            </div>
          </div>
        </div>
        <div class="">
          <div class="block-pre-dropdown" v-on:click="hideBlock6 = !hideBlock6">
            <p class="fz13 fw500 text-dark">Counterparty</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock6}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" :class="{'dnBlock': hideBlock6}">
<!--            <counterparties-drop-->
<!--                :is-multiple="true"-->
<!--                :list="getClientsFormAddModal"-->
<!--                :title="'Counterparty'"-->
<!--                :active-item="activeCounterpartyDefault"-->
<!--                @select="setActiveCounterparty"-->
<!--            ></counterparties-drop>-->
            <async-select
                v-model="activeCounterparty"
                :allowEmpty="true"
                :closeOnSelect="true"
                :isMultiple="true"
                label="Counterparty"
                url="/client/dropdown"
                :placeholder="''"
                :params="{ordering: 'company_name', is_archived: false}"
                watchKey="company_name"
            ></async-select>
          </div>
        </div>
        <div class="">
          <div class="block-pre-dropdown" v-on:click="hideBlock8 = !hideBlock8">
            <p class="fz13 fw500 text-dark">Common name</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock8}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" :class="{'dnBlock': hideBlock8}">
            <async-select
                v-model="activeCommonName"
                :allowEmpty="true"
                :closeOnSelect="true"
                :isMultiple="true"
                label="Counterparty"
                url="/client/dropdown"
                :placeholder="''"
                :params="{ordering: 'charter_name', is_archived: false, is_charter_name: true}"
                watchKey="charter_name"
            ></async-select>
          </div>
        </div>

        <div class="">
          <div class="block-pre-dropdown" v-on:click="hideBlock7 = !hideBlock7">
            <p class="fz13 fw500 text-dark">Currency</p>
            <button class="button-dropdown">
              <svg :class="{rotate180: hideBlock7}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
              </svg>
            </button>
          </div>
          <div class="content" :class="{'dnBlock': hideBlock7}">
            <counterparties-drop
                :is-multiple="true"
                title=""
                :list="getValutaList"
                :active-item="activeValuePaymentDefault"
                @select="setActiveValuePayment"
            ></counterparties-drop>
          </div>
        </div>


<!--        <pre>{{activeCounterparty}}</pre>-->
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import CounterpartiesDrop from "@/components/counterparties/counterpartiesAdd/counterpartiesDrop";
import PaymentListFilterCheckboxes from "@/components/payment/payment-list-filter/payment-list-filter-checkboxes";
import AsyncSelect from "@/components/reuse/asyncSelect";
import BankAndCashSelect from "@/components/reuse/bankAndCashSelect";

export default {
  components: {BankAndCashSelect, AsyncSelect, PaymentListFilterCheckboxes, CounterpartiesDrop},
  computed: {
    ...mapGetters(["getPermissionsByType", "getPushDataCashFlowList", "getValutaList", "getPaymentsAccount", "getPaymentsCash", "getDashBoardPush", "getBankAndCashAccountForCashFlowPush"]),
    ...mapState({
      financialStatement: state => state.trialBalance.financialStatement,
      clients: state => state.counterparties.clientsDrop,
    }),
    getClientsFormAddModal() {
      let clients = [];
      clients = this.clients.map(a=> {
        return {id: a.id, name: a.company_name}
      })
      return clients
    },
  },

  name: "payment-list-filter",
  data() {
    return {
      listPaymentStatus: [
        {name: "Not Paid", value: "not_paid"},
        // // {name: "On payment", value: "in_progress"},
        {name: 'Paid', value: "paid"},
      ],
      listIncomeStatus: [
        {name: "Not Paid", value: "not_paid"},
        {name: 'Paid', value: "paid"},
      ],
      show: false,
      hideBlock1: false,
      hideBlock2: false,
      hideBlock3: false,
      hideBlock4: false,
      hideBlock5: false,
      hideBlock6: false,
      hideBlock7: false,
      hideBlock8: false,
      paymentTo: "",
      activeRequisiteAccount: [],
      paymentFrom: "",
      activeCommonName: [],
      activeAccountName: [],
      activeBankAccount: [],
      activeCounterparty: [],
      activeValuePayment: [],
      activeAccountNameDefault: [],
      activeBankAccountDefault: [],
      activeCounterpartyDefault: [],
      activeValuePaymentDefault: [],
      selectPayment: [
        {name: "Not Paid", value: "not_paid"},
        // // {name: "On payment", value: "in_progress"},
        {name: 'Paid', value: "paid"},
      ],
      selectIncome: [
        {name: "Not Paid", value: "not_paid"},
        {name: 'Paid', value: "paid"},
      ],
      keyRender: -9999,
      keyRenderStatus: -32423,
      openmodal: false,
      keyRenderBank: -23122,
    }
  },
  watch: {
    getPushDataCashFlowList: function () {
      console.log(this.getPushDataCashFlowList)

      if(this.getPushDataCashFlowList.counterparty.length !== 0 || this.getPushDataCashFlowList.account.length !== 0) {
        this.clearFilter()
        this.activeAccountName = this.getPushDataCashFlowList.account;
        // this.activeCounterparty = this.getPushDataCashFlowList.counterparty.map(a=> {
        //   return {id: a.id, name: a.company_name}
        // })
        this.activeCounterparty = this.getPushDataCashFlowList.counterparty
        console.log(this.activeCounterpartyDefault);
        this.keyRender++;
        this.setLocalStorage();

        this.setPushDataCashFlowList({counterparty: [], account: []})
        this.createEmitFilterAxios()
      }

    },

  },
  methods: {
    ...mapActions(["axiosGetsetFinancialStatementlist"]),
    ...mapMutations(["setPushDataCashFlowList", "setDashBoardPush", "setBankAndCashAccountForCashFlowPush"]),
    getPermissionShowDopInfoAcc() {
      return this.getPermissionsByType('show_dop_info_for_acc_in_payments')
    },

    clearFilter() {
      this.selectPayment = [
        {name: "Not Paid", value: "not_paid"},
        // {name: "On payment", value: "in_progress"},
        {name: 'Paid', value: "paid"},
      ];
      if(this.$route.path.indexOf("payment/instructions") === -1) {
        this.selectIncome = [
          {name: "Not Paid", value: "not_paid"},
          {name: 'Paid', value: "paid"},
        ];
      } else {
        this.selectIncome = [
        ];
      }

      this.paymentTo = "";
      this.paymentFrom = "";
      this.activeCommonName = []
      this.activeAccountName = [];
      this.activeBankAccount = [];
      this.activeCounterparty = [];
      this.activeValuePayment = [];
      this.activeAccountNameDefault = [];
      this.activeBankAccount = []
      this.activeRequisiteAccount = []
      this.keyRenderBank++;
      this.activeCounterpartyDefault = [];
      this.activeValuePaymentDefault = [];
      this.keyRender++;
      // this.createEmitFilterAxios()

    },
    selectPaymentCheckboxes() {
      this.selectPayment.splice(0, this.selectPayment.length);
      this.selectPayment = JSON.parse(JSON.stringify(this.listPaymentStatus))
      // this.listPaymentStatus.map(a=> {
      //   this.selectPayment.push(a);
      //   this.$set(this.selectPayment)
      // })
      // console.log(this.selectPayment)
      this.keyRenderStatus++;
      this.setLocalStorage()
      this.createEmitFilterAxios()
    },
    clearPaymentCheckboxes(emit) {
      // if(this.selectIncome.length !== 0) {
        this.selectPayment.splice(0, this.selectPayment.length);
        this.keyRenderStatus++;
        this.setLocalStorage()
      // } else {
      //   this.$emit("cancelPaymentCh")
      // }
      if(this.$route.path.indexOf('payment/instructions') >=0) {
        this.selectPayment.splice(0, this.selectPayment.length);
        // this.keyRenderStatus++;
        this.setLocalStorage()
        this.createEmitFilterAxios()
      }
      if(emit === true)
        this.createEmitFilterAxios()

    },
    setLocalStorage() {
      let data = {
        selectPayment: this.selectPayment,
        selectIncome: this.selectIncome,
        activeAccountName: this.activeAccountName,
        activeRequisiteAccount: this.activeRequisiteAccount,
        activeBankAccount: this.activeBankAccount,
        activeValuePaymentDefault: this.activeValuePaymentDefault,
        activeCounterparty: this.activeCounterparty,
        paymentTo: this.paymentTo,
        activeCommonName: this.activeCommonName,
        paymentFrom: this.paymentFrom,
        hideBlock1: this.hideBlock1,
        hideBlock2: this.hideBlock2,
        hideBlock3: this.hideBlock3,
        hideBlock4: this.hideBlock4,
        hideBlock5: this.hideBlock5,
        hideBlock6: this.hideBlock6,
        hideBlock7: this.hideBlock7,
        hideBlock8: this.hideBlock8,
      }
      localStorage.setItem("paymentFilter" + this.$route.path, JSON.stringify(data));
    },
    selectIncomeCheckboxes() {
      this.selectIncome.splice(0, this.selectIncome.length);
      this.selectIncome = JSON.parse(JSON.stringify(this.listIncomeStatus))
      this.keyRenderStatus++;

      this.setLocalStorage()
      this.createEmitFilterAxios()
    },
    clearIncomeCheckboxes(emit) {
      // if(this.selectPayment.length !== 0) {
        this.selectIncome = [];
        this.keyRenderStatus++;
        this.setLocalStorage()
      //
      // } else {
      //   this.$emit("cancelIncomeCh")
      // }
      if(emit === true)
        this.createEmitFilterAxios()

    },
    changeSelectPayment(data) {
      this.selectPayment = data;
      // this.createEmitFilterAxios()
    },
    changeSelectIncome(data) {
      this.selectIncome = data;
      // this.createEmitFilterAxios()
    },
    setActiveValuePayment(data) {
      this.activeValuePayment = data;
      // this.createEmitFilterAxios()
    },
    setActiveAccountName(data) {
      this.activeAccountName = data;
      // this.createEmitFilterAxios()
    },
    setActiveBankAccount(data) {
      this.activeBankAccount = data;
      // this.createEmitFilterAxios()
    },
    setActiveCounterparty(data) {
      this.activeCounterparty = data;
      // this.createEmitFilterAxios()
    },
    showMenu() {
      this.show = true;
      this.openmodal = true;
      let data = localStorage.getItem("paymentFilter" + this.$route.path);
      if(data !== null && (""+data).length !== 0) {
        data = JSON.parse(data);
        this.selectPayment = data.selectPayment;
        this.selectIncome = data.selectIncome;
        this.activeAccountName = data.activeAccountName
        this.activeRequisiteAccount = Array.isArray(data.activeRequisiteAccount) === true ? data.activeRequisiteAccount : []
        this.activeBankAccount = Array.isArray(data.activeBankAccount) === true ? data.activeBankAccount : []
        this.activeValuePaymentDefault = data.activeValuePaymentDefault
        this.activeCounterparty = data.activeCounterparty
        this.paymentTo = data.paymentTo;
        this.paymentFrom = data.paymentFrom
        this.hideBlock1 = data.hideBlock1;
        this.hideBlock2 = data.hideBlock2;
        this.hideBlock3 = data.hideBlock3;
        this.hideBlock4 = data.hideBlock4;
        this.hideBlock5 = data.hideBlock5;
        this.hideBlock6 = data.hideBlock6;
        this.hideBlock7 = data.hideBlock7;
        this.hideBlock8 = data.hideBlock8;
        this.activeCommonName = data.activeCommonName
      }
      this.openmodal = false
    },
    hideMenu() {

      this.activeBankAccountDefault = this.activeBankAccount;
      this.activeCounterpartyDefault = this.activeCounterparty;
      this.activeValuePaymentDefault = this.activeValuePayment;
      this.show = false;
      this.openmodal = false

      let data = {
        selectPayment: this.selectPayment,
        selectIncome: this.selectIncome,
        activeAccountName: this.activeAccountName,
        activeRequisiteAccount: this.activeRequisiteAccount,
        activeBankAccount: this.activeBankAccount,
        activeValuePaymentDefault: this.activeValuePaymentDefault,
        activeCounterparty: this.activeCounterparty,
        activeCommonName: this.activeCommonName,
        paymentTo: this.paymentTo,
        paymentFrom: this.paymentFrom,
        hideBlock1: this.hideBlock1,
        hideBlock2: this.hideBlock2,
        hideBlock3: this.hideBlock3,
        hideBlock4: this.hideBlock4,
        hideBlock5: this.hideBlock5,
        hideBlock6: this.hideBlock6,
        hideBlock7: this.hideBlock7,
        hideBlock8: this.hideBlock8,
      }
      let dataOld = localStorage.getItem("paymentFilter" + this.$route.path);
      if(dataOld !== JSON.stringify(data)) {
        this.createEmitFilterAxios();
      }
      localStorage.setItem("paymentFilter" + this.$route.path, JSON.stringify(data));
    },
    createEmitFilterAxios() {
      console.log(this.selectPayment)

      if(!this.openmodal) {
        let account_name = [];
        let filter_active = false;
        if(this.activeAccountName?.length) {
          account_name = this.activeAccountName.map(a=> {return a.id});
          filter_active = true
        }
        let bank = [];
        if(this.activeRequisiteAccount?.length) {
          bank = this.activeRequisiteAccount.map(a=> {return a.id})
          filter_active = true
        }
        let cash = [];
        if(this.activeBankAccount?.length) {
          cash = this.activeBankAccount.map(a=> {return a.id})
          filter_active = true
        }
        let counterparty = [];
        if(this.activeCounterparty?.length) {
          counterparty = this.activeCounterparty.map(a=> {return a.id});
          filter_active = true
        }
        let currency = [];
        if(this.activeValuePayment?.length) {
          currency = this.activeValuePayment.map(a=> {return a.name})
          filter_active = true
        }
        let paymentCheckbox = [];
        if(this.selectPayment?.length) {
          paymentCheckbox = this.selectPayment.map(a=> {return a.value});
        }
        let incomeCheckbox = [];
        if(this.selectIncome?.length) {
          incomeCheckbox = this.selectIncome.map(a=> {return a.value});
        }
        if(this.$route.path.indexOf('instructions') === -1) {
          if(this.selectPayment?.length !== 2) {
            filter_active = true
          }
          if(this.selectIncome?.length !== 2) {
            filter_active = true
          }
        }

        if(this.paymentFrom?.length) {
          filter_active = true
        }
        if(this.paymentTo?.length) {
          filter_active = true
        }
        let client__charter_name = []
        if(this.activeCommonName) {
          client__charter_name = this.activeCommonName.map(a=> {return a.charter_name})
        }
        let form = {
          client__charter_name: client__charter_name ? client__charter_name : [],
          account_name:account_name,
          bank:bank,
          cash: cash,
          counterparty:counterparty,
          currency:currency,
          paymentCheckbox:paymentCheckbox,
          incomeCheckbox:incomeCheckbox,
          paymentFrom: this.paymentFrom,
          paymentTo: this.paymentTo,
          filter_active: filter_active,
        }
        console.log('form', form)
        this.$emit("changeFilter", form)
      }
    }
  },
  mounted() {
    let data = localStorage.getItem("paymentFilter" + this.$route.path);
    if(data !== null && (""+data).length !== 0 ) {
      data = JSON.parse(data);
      this.selectPayment = data.selectPayment;
      this.selectIncome = data.selectIncome;
      this.activeAccountName = data.activeAccountName
      this.activeRequisiteAccount = Array.isArray(data.activeRequisiteAccount) === true ? data.activeRequisiteAccount : []
      this.activeBankAccount = Array.isArray(data.activeBankAccount) === true ? data.activeBankAccount : []
      this.activeValuePaymentDefault = data.activeValuePaymentDefault
      this.activeValuePayment = data.activeValuePaymentDefault
      this.activeCounterparty = data.activeCounterparty
      this.paymentTo = data.paymentTo;
      this.paymentFrom = data.paymentFrom
      this.hideBlock1 = data.hideBlock1;
      this.hideBlock2 = data.hideBlock2;
      this.hideBlock3 = data.hideBlock3;
      this.hideBlock4 = data.hideBlock4;
      this.hideBlock5 = data.hideBlock5;
      this.hideBlock6 = data.hideBlock6;
      this.hideBlock7 = data.hideBlock7;
      this.hideBlock7 = data.hideBlock8;
      this.activeCommonName = data.activeCommonName
    } else {
      if(this.$route.path.indexOf('payment/instructions')>=0) {
        this.selectPayment = [
          {name: "Not Paid", value: "not_paid"},
          // {name: "On payment", value: "in_progress"},
        ];
        this.selectIncome = [
        ];
      }
    }

    if(this.$route.path.indexOf('payment/instructions')>=0) {
      this.listPaymentStatus = [
        {name: "Not Paid", value: "not_paid"},
        // {name: "On payment", value: "in_progress"},
      ]
      this.listIncomeStatus= [
      ]
      this.selectPayment = this.selectPayment.filter(a=> a.value !== 'paid')
    }


    if((this.getPushDataCashFlowList.counterparty.length !== 0 || this.getPushDataCashFlowList.account.length !== 0) && !this.getDashBoardPush) {
      this.clearFilter()
      this.activeAccountName = this.getPushDataCashFlowList.account;
      this.activeCounterparty = this.getPushDataCashFlowList.counterparty
      this.keyRender++;
      this.setLocalStorage();

      this.setPushDataCashFlowList({counterparty: [], account: []})

    }
    if(this.$route.path.indexOf('cashflow/list') >= 0 && this.getDashBoardPush) {
      this.clearFilter()
      this.selectPayment = [
        {name: "Not Paid", value: "not_paid"},
        // {name: "On payment", value: "in_progress"},
      ]
      this.selectIncome = [
        {name: "Not Paid", value: "not_paid"},
      ]
      this.setDashBoardPush(false);
      this.setLocalStorage();
    }
    if(this.$route.path.indexOf('cashflow/list') >= 0 && this.getBankAndCashAccountForCashFlowPush.length) {
      this.clearFilter()
      this.selectPayment = [
        {name: 'Paid', value: "paid"},
      ]
      this.selectIncome = [
        {name: 'Paid', value: "paid"},
      ]
      this.activeRequisiteAccount = this.getBankAndCashAccountForCashFlowPush;
      this.setBankAndCashAccountForCashFlowPush([])
      this.setLocalStorage();
    }

    this.createEmitFilterAxios();
    // if(!this.financialStatement.length) {
    //   this.axiosGetsetFinancialStatementlist();
    // }
  },
}
</script>

<style scoped lang="scss">
  @import "payment-list-filter";
  .bank-and-cash-select {
    /deep/ {
      .multiselect--active {
        .multiselect__single, .multiselect__spinner {
          display: none !important;
        }
      }
    }
  }
</style>