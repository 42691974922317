<template>
  <section class="wrap-page" :class="{'week': week}">
    <payment-modal-create ref="modalCreate" @needRender="renderList"></payment-modal-create>
    <div class="container">
      <div class="top-panel">
<!--        <button class="fz13 fw500 blue-button h32 buttonW150" v-on:click="showModalCreate">-->
<!--          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="white"/>-->
<!--          </svg>-->
<!--          Create new-->
<!--        </button>-->
        <router-link v-if="getPermissionsByType('show_archive_of_payments_incomes') === true" to="/payment/archive" class="button-top-panel button-dark-link archive-link svg-white-dart-stroke fw500 fz13">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Archive
        </router-link>
        <payment-checkbox-payment @changeStatusCheckbox="changeStatusCheckboxPayment" :status="paymentCheckboxActive" class="ch1"></payment-checkbox-payment>
        <payment-checkbox-income @changeStatusCheckbox="changeStatusCheckboxIncome" :status="paymentCheckboxIncomeActive" class="ch2"></payment-checkbox-income>
        <button :class="{'text-blue': filterData && filterData.filter_active}" class="button-filter fz14 fw500" v-on:click="openFilter">
          <svg v-if="!filterData || (filterData && !filterData.filter_active)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.28634 3.73746C8.22907 3.73746 7.3304 4.45987 7.03965 5.44983H4.63436C4.26432 5.44983 4 5.74415 4 6.11873C4 6.49331 4.29075 6.78763 4.66079 6.78763H7.06608C7.3304 7.77759 8.25551 8.5 9.31277 8.5C10.3965 8.5 11.2952 7.77759 11.5859 6.78763H18.3392C18.7093 6.78763 19 6.49331 19 6.11873C19 5.74415 18.7093 5.44983 18.3392 5.44983H11.5859C11.2687 4.45987 10.37 3.73746 9.28634 3.73746ZM9.31277 5.07525C9.86784 5.07525 10.3436 5.5301 10.3436 6.11873C10.3436 6.70736 9.86784 7.16221 9.31277 7.16221C8.75771 7.16221 8.28194 6.70736 8.28194 6.11873C8.28194 5.5301 8.75771 5.07525 9.31277 5.07525Z" fill="#2C2C2C"/>
            <path d="M14.2423 9.61377C13.185 9.61377 12.2863 10.3362 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6205 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6205 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3362 15.326 9.61377 14.2423 9.61377ZM14.2687 10.9516C14.8238 10.9516 15.2996 11.4064 15.2996 11.995C15.2996 12.5837 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5837 13.2379 11.995C13.2379 11.4064 13.6872 10.9516 14.2687 10.9516Z" fill="#2C2C2C"/>
            <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5186 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8029 8.42731 20.5253 9.48458 20.5253C10.5683 20.5253 11.467 19.8029 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5186 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5554 10.5154 18.144C10.5154 18.7326 10.0396 19.1875 9.48458 19.1875C8.92951 19.1875 8.45374 18.7326 8.45374 18.144C8.48018 17.5554 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
          </svg>
          <svg v-if="filterData && filterData.filter_active" class="svg-blue" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 6C24 8.20914 22.2091 10 20 10C17.7909 10 16 8.20914 16 6C16 3.79086 17.7909 2 20 2C22.2091 2 24 3.79086 24 6Z" fill="#319FEF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0068 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H14.0916C14.0314 6.70918 14 6.35826 14 6C14 5.90358 14.0023 5.80768 14.0068 5.71237ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#319FEF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1809 10.6278C15.9425 10.4309 15.7196 10.2158 15.5143 9.98485C15.1482 9.74985 14.7127 9.61374 14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.9683 18.9985 11.9421 18.9957 11.9163C18.3705 11.811 17.7782 11.6089 17.2346 11.3261H16.5154C16.4412 11.0734 16.3273 10.8381 16.1809 10.6278ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#319FEF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.21145 17.4751C7.5022 16.4851 8.40088 15.7627 9.45815 15.7627C10.5419 15.7627 11.4405 16.4851 11.7313 17.4751H18.3392C18.7093 17.4751 19 17.7694 19 18.144C19 18.5185 18.7093 18.8129 18.3392 18.8129H11.7577C11.467 19.8028 10.5683 20.5252 9.48458 20.5252C8.42731 20.5252 7.5022 19.8028 7.23789 18.8129H4.66079C4.29075 18.8129 4 18.5185 4 18.144C4 17.7694 4.26432 17.4751 4.66079 17.4751H7.21145ZM10.5154 18.144C10.5154 17.5553 10.0396 17.1005 9.48458 17.1005C8.92951 17.1005 8.48018 17.5553 8.45374 18.144C8.45374 18.7326 8.92951 19.1874 9.48458 19.1874C10.0396 19.1874 10.5154 18.7326 10.5154 18.144Z" fill="#319FEF"/>
          </svg>
          Filter
        </button>
      </div>
<!--      {{filterData}}-->
      <div class="main-block">
        <div class="main-block__top-panel">
          <p class="fz13 text-gray" v-if="filterData.paymentCheckbox.length !==0 || (filterData.paymentCheckbox.length === 0 && filterData.incomeCheckbox.length === 0)">Payment:</p>
          <p class="status-stroke fz13 text-red" v-if="filterData.paymentCheckbox.indexOf('not_paid') > -1 || (filterData.paymentCheckbox.length === 0 && filterData.incomeCheckbox.length === 0)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.43363 2H9.56637C10.79 2 11.4988 3.38624 10.7824 4.37824L7.21602 9.31628C6.61728 10.1453 5.38272 10.1453 4.78398 9.31628L1.21762 4.37824C0.501177 3.38625 1.20998 2 2.43363 2Z" fill="#E85353"/>
            </svg>
            Not paid
          </p>
          <p class="status-stroke fz13 text-blue-dark" v-if="filterData.paymentCheckbox.indexOf('in_progress') > -1 || (filterData.paymentCheckbox.length === 0 && filterData.incomeCheckbox.length === 0)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.43363 2H9.56637C10.79 2 11.4988 3.38624 10.7824 4.37824L7.21602 9.31628C6.61728 10.1453 5.38272 10.1453 4.78398 9.31628L1.21762 4.37824C0.501177 3.38625 1.20998 2 2.43363 2Z" fill="#094172"/>
            </svg>
            On payment
          </p>
          <p class="status-stroke fz13 text-gray" v-if="filterData.paymentCheckbox.indexOf('paid') > -1 || (filterData.paymentCheckbox.length === 0 && filterData.incomeCheckbox.length === 0)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.43363 2H9.56637C10.79 2 11.4988 3.38624 10.7824 4.37824L7.21602 9.31628C6.61728 10.1453 5.38272 10.1453 4.78398 9.31628L1.21762 4.37824C0.501177 3.38625 1.20998 2 2.43363 2Z" fill="#9AA3B0"/>
            </svg>
            Paid
          </p>
          <p class="fz13 text-gray" :class="{'ml24': filterData.paymentCheckbox.length || (filterData.paymentCheckbox.length === 0 && filterData.incomeCheckbox.length === 0)}" v-if="filterData.incomeCheckbox.length !==0 || (filterData.paymentCheckbox.length === 0 && filterData.incomeCheckbox.length === 0)">Income:</p>
          <p class="status-stroke fz13 text-green" v-if="filterData.incomeCheckbox.indexOf('not_paid') > -1 || (filterData.paymentCheckbox.length === 0 && filterData.incomeCheckbox.length === 0)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.43363 10H9.56637C10.79 10 11.4988 8.61376 10.7824 7.62176L7.21602 2.68372C6.61728 1.8547 5.38272 1.8547 4.78398 2.68372L1.21762 7.62176C0.501177 8.61375 1.20998 10 2.43363 10Z" fill="#0FA54E"/>
            </svg>
            Not paid
          </p>
          <p class="status-stroke fz13 text-gray" v-if="filterData.incomeCheckbox.indexOf('paid') > -1 || (filterData.paymentCheckbox.length === 0 && filterData.incomeCheckbox.length === 0)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.43363 10H9.56637C10.79 10 11.4988 8.61376 10.7824 7.62176L7.21602 2.68372C6.61728 1.8547 5.38272 1.8547 4.78398 2.68372L1.21762 7.62176C0.501177 8.61375 1.20998 10 2.43363 10Z" fill="#9AA3B0"/>
            </svg>
            Paid
          </p>
          <div class="buttons-period">
            <button v-on:click="changeButton()" class="fz13 text-gray" :class="{'buttons-period__active': week}">Week</button>
            <button v-on:click="changeButton()" class="fz13 text-gray" :class="{'buttons-period__active': !week}">Month</button>
          </div>
          <div class="period-selector">
            <button v-on:click="prevDate">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.70088 5.50586L6.81686 2.20355C7.08398 1.93169 7.51645 1.93215 7.78312 2.20496C8.04959 2.47773 8.0489 2.91961 7.78175 3.19168L5.15106 5.99984L7.78175 8.7171C8.04887 8.98921 8.04955 9.4308 7.78312 9.70361C7.64944 9.84037 7.47431 9.90875 7.29918 9.90875C7.1245 9.90875 6.95006 9.84083 6.81662 9.70502L3.70088 6.49378C3.57223 6.36307 3.50003 6.18518 3.50003 5.99984C3.50003 5.8145 3.57244 5.63682 3.70088 5.50586Z" fill="#9AA3B0"/>
              </svg>
            </button>
            <p class="fz14 text-dark">{{ getDateTextForm() }}</p>
            <button v-on:click="nextDate">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="block-calendar">
          <div class="name-week fz12 fw500 text-gray" v-for="(n, i) in nameDay" :key="'2asd'+i">
            {{n}}
          </div>
          <div class="day-block" v-for="(d, i) in listDate" :key="i" :class="{'today': dateMatches(new Date(), d.date)}">
            <p :class="{'text-gray': new Date(d.date).getMonth() !== selectDate.getMonth(),
            'text-dark': new Date(d.date).getMonth() === selectDate.getMonth()}"
               class="fz12"
            >{{ ((new Date(d.date))).getDate()}}</p>
            <div class="select-all" v-if="d.array.length >= 3 && !week">
              <button class="blue-button-text" v-on:click="showAll(d.date)">Show all</button>
            </div>
            <div class="wrap-block" >
              <div class="block-payment" :class="{'all-gray': p.status === 'paid', 'deadlineOut': checkDeadline(d.date)  && p.status !== 'paid'}" v-for="(p, s) in d.array" :key="'22s' + s" v-on:click="openPayment(p.id)">
                <div>
                  <svg :class="{'rotate180': p.type === 'expense', 'not-paid': p.status === 'not_paid', 'in-progress': p.status === 'in_progress'}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.43363 11H9.56637C10.79 11 11.4988 9.61376 10.7824 8.62176L7.21602 3.68372C6.61728 2.8547 5.38272 2.8547 4.78398 3.68372L1.21762 8.62176C0.501177 9.61375 1.20998 11 2.43363 11Z" fill="#9AA3B0"/>
                  </svg>
                  <p :title="p.textAccount" class="fz13 text-dark" v-html="p.textAccount"></p>
                </div>
                <div> <p :title="p.counterparty" class="fz13 text-dark">{{ p.counterparty }}</p></div>
                <div> <p :title="p.payment_amount + ' ' + p.currency" class="fz13 text-dark fw500">  {{ p.payment_amount | formatNumberTwo}} {{p.currency}}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pre>
<!--    {{listDate}}-->
<!--      {{getPaymentListOriginal}}-->
    </pre>
    <payment-list-filter @changeFilter="changeFilter" class="filter" ref="filter"></payment-list-filter>
  </section>
</template>

<script>
import PaymentModalCreate from "@/components/payment/payment-modal-create/payment-modal-create";
import PaymentCheckboxPayment from "@/components/payment/payment-checkbox-payment";
import PaymentCheckboxIncome from "@/components/payment/payment-checkbox-income";
import PaymentListFilter from "@/components/payment/payment-list-filter/payment-list-filter";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Vue from 'vue';
import moment from 'moment';
Vue.prototype.$moment = moment;

export default {
  name: "paymentCalendar",
  components: {PaymentListFilter, PaymentCheckboxIncome, PaymentCheckboxPayment, PaymentModalCreate},
  computed: mapGetters(["getPaymentListOriginal", "getPermissionsByType", "getPermissionsForUser"]),
  watch: {
    getPaymentListOriginal: function () {
      this.listDate = this.listDate.map(a=> {
        if(this.getPaymentListOriginal.length) {
          let array = this.getPaymentListOriginal.filter(b=> moment(a.date, 'DD.MM.YYYY').format("DD MM YYYY") === moment(b.execute_date, 'DD.MM.YYYY').format("DD MM YYYY") && b.execute_date !== null)
          a.array = array.map(b => {
            let form = {
              textAccount: b.account? (b.account.id + ": " + b.account.name) : '',
              counterparty: b.client !== null ? b.client.company_name : "",
              payment_amount: b.payment_amount,
              currency: b.currency,
              type: b.payment_type,
              status: b.status,
              id: b.id
            }
            return form;
          })
        } else {
          a.array.splice(0, a.array.length)
        }

        // console.log(a.array);
        // a.array = this.getPaymentListOriginal;
        return a;
      });
      this.listDate = JSON.parse(JSON.stringify(this.listDate));
    },
    week: function () {
      this.getDateCurrent();
    },
    getPermissionsForUser: function() {
      if(this.getPermissionsByType("show_payment_calendar") === false) {
        this.$router.push('/employees')
      }
    }
  },
  data() {
    return {
      filterData: {
        paymentCheckbox: [],
        incomeCheckbox: [],
      },
      dateSelected: null,
      week: false,
      selectDate: (new Date()),
      before: "",
      after: "",
      nameDay: ["Mon", "Tus", "Wed", "Thu", "Fri", "Sat", "Sun"],
      listDate: [{
        date: "",
        array: []
      }],
      paymentCheckboxActive: false,
      paymentCheckboxIncomeActive: false,
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_payment_calendar") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
        next(from);
      }
    })
  },
  methods: {
    ...mapActions(["axiosGetPayment"]),
    ...mapMutations(["cancelPostPayment", "setDateForViewPayments"]),
    openPayment(id) {
      this.$router.push({name: 'Payment Calendar Detail', params: {id: id}});
    },
    changeStatusCheckboxPayment(status) {
      // console.log("changeStatusCheckboxPayment");
      // console.log(status)
      if(status) {
        this.$refs.filter.selectPaymentCheckboxes();
      } else {
        this.$refs.filter.clearPaymentCheckboxes();
      }
    },
    changeStatusCheckboxIncome(status) {
      // console.log("changeStatusCheckboxIncome")
      // console.log(status)
      if (status) {
        this.$refs.filter.selectIncomeCheckboxes();
      } else {
        this.$refs.filter.clearIncomeCheckboxes();
      }
    },
    showAll(date) {
      this.setDateForViewPayments([new Date(date), new Date(date)]);
      this.$router.push('/payment/list')
    },
    checkDeadline(date) {
        // return this.dateMatches(date, new Date());
      if(!this.dateMatches(date, (new Date()))) {
        return (moment(date).valueOf() < moment((new Date)).valueOf());
      }
      return false
    },
    dateMatches(date1, date2) {
      date1 = moment(date1).format("DD MM YYYY");
      date2 = moment(date2).format('DD MM YYYY');
      if(""+date1 ===""+ date2) {
        return true;
      }
      return false
    },
    renderList() {
      this.axiosChangeGetList();
    },
    axiosChangeGetList() {
      this.cancelPostPayment();
      let before = "";
      let after = "";
      // console.log(this.dateSelected);
      if(""+this.before !== 'null' && this.before.length !== 0) {
        before = ""+moment(this.before).format("YYYY-MM-DD")+ " 00:00:00";
      }
      if(""+this.after !== 'null' && this.after.length !== 0) {
        after = ""+moment(this.after).format("YYYY-MM-DD")+ " 23:59:59";
      }
      let type = "payment";
      if(this.$route.fullPath.indexOf("accounting-invoice") >= 0) {
        type = "invoice";
      }
      // this.setFilterDataPaymentAxios()
      this.axiosGetPayment({before: before,after: after, filter: this.filterData, type:type, limit: 50});
    },
    getDateTextForm() {
      return moment(this.selectDate).format("MMMM YYYY")
    },
    showModalCreate() {
      this.$refs.modalCreate.openModal();
      this.keyRender++;
    },
    openFilter() {
      this.$refs.filter.showMenu();
    },
    changeFilter(data) {
      this.paymentCheckboxIncomeActive = data.incomeCheckbox.length !== 0;
      this.paymentCheckboxActive = data.paymentCheckbox.length !== 0;
      this.filterData = data;
      this.axiosChangeGetList();
    },

    changeButton() {
      this.week = !this.week;
    },
    getDateCurrent() {
      if(this.week) {
        this.selectDate = (new Date());
        let date = this.selectDate;
        let now = moment(date);
        let date1 = now.clone().weekday(1);
        let date2 = now.clone().weekday(7);
        let start = date1._d;
        let end = date2._d;
        this.before = start;
        this.after = end;
        let listDate = [];
        let whileEnd = true;
        do {
          if((start.getDate() === end.getDate()) && (start.getMonth() === end.getMonth()) && (start.getFullYear() === end.getFullYear())) {
            whileEnd = false;
          }
          let form = {
            date: start,
            array: [],
          }
          listDate.push(form);
          start = moment(start).add(1, 'day')._d;
          // console.log(start + " - " + end)

        } while (whileEnd)
        this.listDate = listDate
        // console.log(listDate);
        this.renderList();
      } else {
        this.selectDate = (new Date());
        let date = this.selectDate, y = date.getFullYear(), m = date.getMonth();
        let firstDay = (new Date(y, m, 1));
        let lastDay = (new Date(y, m + 1, 0));
        let now = moment(firstDay);
        let date1 = now.clone().weekday(1);
        // let date2 = now.clone().weekday(7);
        // console.log(date1._d + " - " + date2._d)
        let now1 = moment(lastDay);
        // let date11 = now1.clone().weekday(1);
        let date21 = now1.clone().weekday(7);
        // console.log(date11._d + " - " + date21._d);
        let start = date1._d;
        let end = date21._d;
        this.before = start;
        this.after = end;
        let listDate = [];
        let whileEnd = true;
        do {
          if((start.getDate() === end.getDate()) && (start.getMonth() === end.getMonth()) && (start.getFullYear() === end.getFullYear())) {
            whileEnd = false;
          }
          let form = {
            date: start,
            array: [],
          }
          listDate.push(form);
          start = moment(start).add(1, 'day')._d;
          // console.log(start + " - " + end)

        } while (whileEnd)
        this.listDate = listDate
        // console.log(listDate);
        this.renderList();
      }

    },
    prevDate() {
      if(this.week) {
        this.selectDate = moment(this.selectDate).add(-1, 'month')._d;
        var date = this.selectDate;
        let now = moment(date);
        let date1 = now.clone().weekday(1);
        let date2 = now.clone().weekday(7);
        let start = date1._d;
        let end = date2._d;
        this.before = start;
        this.after = end;
        let listDate = [];
        let whileEnd = true;
        do {
          if((start.getDate() === end.getDate()) && (start.getMonth() === end.getMonth()) && (start.getFullYear() === end.getFullYear())) {
            whileEnd = false;
          }
          let form = {
            date: start,
            array: [],
          }
          listDate.push(form);
          start = moment(start).add(1, 'day')._d;
          // console.log(start + " - " + end)

        } while (whileEnd)
        this.listDate = JSON.parse(JSON.stringify(listDate))
        this.renderList();
      } else {
        this.selectDate = moment(this.selectDate).add(-1, 'month')._d;
        let date = this.selectDate, y = date.getFullYear(), m = date.getMonth();
        let firstDay = (new Date(y, m, 1));
        let lastDay = (new Date(y, m + 1, 0));
        let now = moment(firstDay);
        let date1 = now.clone().weekday(1);

        // let date2 = now.clone().weekday(7);
        // console.log(date1._d + " - " + date2._d)
        let now1 = moment(lastDay);
        // let date11 = now1.clone().weekday(1);
        let date21 = now1.clone().weekday(7);
        // console.log(date11._d + " - " + date21._d);
        let start = date1._d;
        let end = date21._d;
        this.before = start;
        this.after = end;
        let listDate = [];
        let whileEnd = true;
        do {
          if((start.getDate() === end.getDate()) && (start.getMonth() === end.getMonth()) && (start.getFullYear() === end.getFullYear())) {
            whileEnd = false;
          }
          let form = {
            date: start,
            array: [],
          }
          listDate.push(form);
          start = moment(start).add(1, 'day')._d;
          // console.log(start + " - " + end)

        } while (whileEnd)
        this.listDate = JSON.parse(JSON.stringify(listDate))
        this.renderList();
      }

    },
    nextDate() {
      if(this.week) {
        this.selectDate = moment(this.selectDate).add(+1, 'month')._d;
        let date = this.selectDate;
        let now = moment(date);
        let date1 = now.clone().weekday(1);
        let date2 = now.clone().weekday(7);
        let start = date1._d;
        let end = date2._d;
        this.before = start;
        this.after = end;
        let listDate = [];
        let whileEnd = true;
        do {
          if((start.getDate() === end.getDate()) && (start.getMonth() === end.getMonth()) && (start.getFullYear() === end.getFullYear())) {
            whileEnd = false;
          }
          let form = {
            date: start,
            array: [],
          }
          listDate.push(form);
          start = moment(start).add(1, 'day')._d;
          // console.log(start + " - " + end)

        } while (whileEnd)
        this.listDate = JSON.parse(JSON.stringify(listDate))
        this.renderList();
      } else {
        this.selectDate = moment(this.selectDate).add(+1, 'month')._d;
        let date = this.selectDate, y = date.getFullYear(), m = date.getMonth();

        let firstDay = moment(new Date(y, m, 1)).format('dddd') === 'Sunday' ? moment(new Date(y, m, 1)).add(-1, 'day') : (new Date(y, m, 1));
        let lastDay = moment(new Date(y, m + 1, 0)).format('dddd') === 'Sunday' ? moment(new Date(y, m + 1, 0)).add(-1, 'day') : (new Date(y, m + 1, 0));
        let now = moment(firstDay);
        console.log()
        let date1 = now.clone().weekday(1);
        // let date2 = now.clone().weekday(7);
        // console.log(date1._d + " - " + date2._d)
        let now1 = moment(lastDay);
        // let date11 = now1.clone().weekday(1);
        let date21 = now1.clone().weekday(7);
        // console.log(date11._d + " - " + date21._d);
        let start = date1._d;
        let end = date21._d;
        this.before = start;
        this.after = end;
        console.log(start, end)

        let listDate = [];
        let whileEnd = true;
        do {
          if((start.getDate() === end.getDate()) && (start.getMonth() === end.getMonth()) && (start.getFullYear() === end.getFullYear())) {
            whileEnd = false;
          }
          let form = {
            date: start,
            array: [],
          }
          listDate.push(form);
          start = moment(start).add(1, 'day')._d;
          // console.log(start + " - " + end)

        } while (whileEnd)
        this.listDate = listDate
        this.renderList();
      }
    },
  },
  mounted() {
    this.getDateCurrent();
  },


}
</script>

<style scoped lang="scss">
  @import "paymentCalendar";
  .button-top-panel {
    width: 132px;
    box-sizing: border-box;
    height: 32px;
  }
  .archive-link {
    margin-right: 24px;
    border-radius: 4px;
    border: 1px solid var(--color-blue-dark);
    justify-content: center;
    color: var(--color-blue-dark);
    svg {
      path {
        stroke: var(--color-blue-dark)
      }
    }
  }
  .button-filter {
    svg {
      path {
        fill: var(--color-blue-dark)
      }
    }
    color: var(--color-blue-dark)
  }
</style>