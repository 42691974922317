<template>
  <modal-slots 
    :showMod="isShowModal"
    :noCloseAfterApply="true"
    @close="$emit('on-close')"
    @apply="onAddPort"
  >
    <template v-slot:title>
      <h2 class="fz18 fw500" v-if="account && !isDupl">Edit {{typePort ? 'port' : 'canal'}}</h2>
      <h2 class="fz18 fw500" v-else>Create new port or canal</h2>
    </template>
    <div class="top-panel-select-type">
      <button class="fz16 fw500 text-gray" :disabled="account && !isDupl" v-on:click="typePort = true" :class="{'active': typePort}">Port</button>
      <button class="fz16 fw500 text-gray" :disabled="account && !isDupl" v-on:click="typePort = false" :class="{'active':!typePort }">Canal</button>
    </div>
    <div class="p-x-6 p-b-5" v-if="portObj">

      <label class="block-input" :class="{ 'block-input_error': $v.portObj.country.$error }">
        <p class="block-input_title text-gray">Country <span class="text-red">*</span></p>
        <input class="block-input_content" type="text" v-model.trim="$v.portObj.country.$model">
        <div class="fz10 text-red" v-if="!$v.portObj.country.required && $v.portObj.country.$dirty">Field is required</div>
        <div class="fz10 text-red" v-if="!$v.portObj.country.maxLength">The maximum number of characters in the Country is {{$v.portObj.country.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.portObj.name.$error }">
        <p class="block-input_title text-gray">{{typePort ? 'Port' : 'Canal'}} Name <span class="text-red">*</span></p>
        <input class="block-input_content" type="text" v-model.trim="$v.portObj.name.$model">
        <div class="fz10 text-red" v-if="!$v.portObj.name.required && $v.portObj.name.$dirty">Field is required</div>
        <div class="fz10 text-red" v-if="!$v.portObj.name.maxLength">The maximum number of characters in the Ports Name is {{$v.portObj.name.$params.maxLength.max}}.</div>
      </label>

        <div class=" flex justify-between" >
          <label class="col-3 block-input" :class="{ 'block-input_error': $v.portObj.max_draft.$error }">
            <p class="block-input_title text-gray">Max Draft</p>
            <input class="block-input_content" type="text" v-model.trim="$v.portObj.max_draft.$model">
            <div class="fz10 text-red" v-if="!$v.portObj.max_draft.maxLength">The maximum number of characters in the Max Draft is {{$v.portObj.max_draft.$params.maxLength.max}}.</div>
          </label>

          <label class="col-3 block-input" :class="{ 'block-input_error': $v.portObj.max_loa.$error }">
            <p class="block-input_title text-gray">Max LOA</p>
            <input class="block-input_content" type="text" v-model.trim="$v.portObj.max_loa.$model">
            <div class="fz10 text-red" v-if="!$v.portObj.max_loa.maxLength">The maximum number of characters in the Max LOA is {{$v.portObj.max_loa.$params.maxLength.max}}.</div>
          </label>

          <label class="col-3 block-input" :class="{ 'block-input_error': $v.portObj.max_beam.$error }">
            <p class="block-input_title text-gray">Max Beam</p>
            <input class="block-input_content" type="text" v-model.trim="$v.portObj.max_beam.$model">
            <div class="fz10 text-red" v-if="!$v.portObj.max_beam.maxLength">The maximum number of characters in the Max Beam is {{$v.portObj.max_beam.$params.maxLength.max}}.</div>
          </label>
        </div>

      <counterparties-drop 
        ref="drop"
        :list="waterDensityList" 
        @select="el=>portObj.water_density = el.id"
        :activeItem="activeWater"
        title="Water Density"
      ></counterparties-drop>

      <add-pda :class="{'displayNone': !typePort}" ref="pdaBlock" :list="portObj ? portObj.pda : []"/>
      <div v-if="!typePort" class="wrap-dwt-items">
        <div class="block-dwt-item flex justify-between mt14" :class="{'pb-15': !item.price.maxLength || !item.min.maxLength || !item.max.maxLength}" v-for="(item, i) in $v.dwtItems.$each.$iter" :key="i">
          <label class="col-2-3">
            <p class="block-input_title text-gray">DWT Range #{{i+1}}</p>
            <div class="input-from-to">
              <input class="block-input_content rounded-r-none w-1/2" :class="{ 'block-input_error': item.min.maxLength }" type="text" placeholder="Min" v-model="item.min.$model" @input="item.min.$model = checkNum(item.min.$model)" @focusout="checkMinMax(item.min.$model, item.max.$model, i)">
              <input class="block-input_content rounded-l-none w-1/2" :class="{ 'block-input_error': item.max.maxLength }" type="text" placeholder="Max" v-model="item.max.$model" @input="item.max.$model = checkNum(item.max.$model)" @focusout="checkMinMax(item.min.$model, item.max.$model, i)">
            </div>
            <p class="fz10 text-red" v-if="!item.min.maxLength || !item.max.maxLength">The maximum number of characters in the DWT is 50</p>

          </label>
          <label class="col-3">
            <p class="block-input_title text-gray">Price, $</p>
            <input class="block-input_content" :class="{ 'block-input_error': item.price.maxLength}" type="text" v-model="item.price.$model" @input="item.price.$model = checkNumFloat(item.price.$model)" @focusout="item.price.$model = checkNumFloatOut(item.price.$model)">
            <p class="fz10 text-red max163" v-if="!item.price.maxLength">The maximum number of characters in the Price is 50</p>
          </label>
          <button class="button-remove-item-dwt blue-button-text fz14 fw500" v-on:click="removeDwtItem(i)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="white"/>
            </svg>
            Delete DWT
          </button>
        </div>
        <button class="button-add-item-dwt blue-button-text fz14 fw500" v-on:click="addDwtItem">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z" fill="#094172"/>
          </svg>
          Add new DWT
        </button>
      </div>

      <label class="block-input" :class="{ 'block-input_error': $v.portObj.zone.$error }">
        <p class="block-input_title text-gray">Zone</p>
        <input class="block-input_content" type="text" v-model.trim="$v.portObj.zone.$model">
        <div class="fz10 text-red" v-if="!$v.portObj.zone.maxLength">The maximum number of characters in the Zone is {{$v.portObj.zone.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.portObj.latitude.$error || $v.portObj.longitude.$error }">
        <p class="block-input_title text-gray">Position</p>
        <div class="input-from-to">
          <input class="block-input_content rounded-r-none w-1/2" type="text" placeholder="Latitude" v-model.trim="$v.portObj.latitude.$model">
          <input class="block-input_content rounded-l-none w-1/2" type="text" placeholder="Longitude" v-model.trim="$v.portObj.longitude.$model">
        </div>
        <div class="fz10 text-red" v-if="!$v.portObj.latitude.maxLength">The maximum number of characters in the latitude is {{$v.portObj.latitude.$params.maxLength.max}}.</div>
        <div class="fz10 text-red" v-if="!$v.portObj.longitude.maxLength">The maximum number of characters in the longitude is {{$v.portObj.longitude.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{'displayNone' : typePort}">
        <p class="block-input_title text-gray">Transit time</p>
        <input class="block-input_content" type="text" ref="from_time" placeholder="hh:mm" v-model.lazy="portObj.transit_time" >
      </label>

      <bunkering :port="portObj"/>

      <label class="block-input m-b-3" :class="{ 'block-input_error': $v.portObj.weather_condition.$error }">
        <p class="m-b-1 fz12 text-gray-text">Weather condition</p>
        <textarea class="block-input_content" v-model.trim="$v.portObj.weather_condition.$model"></textarea>
        <!-- <input class="block-input_content" type="text" v-model.trim="$v.weather_condition.$model"> -->
        <div class="fz10 text-red" v-if="!$v.portObj.weather_condition.maxLength">The maximum number of characters in the Weather condition is {{$v.portObj.weather_condition.$params.maxLength.max}}.</div>
      </label>

      <files ref="dropzone"/>
    </div>

    <template v-slot:bottomSecondButton v-if="account && !isDupl">
      <button class="blue-button fz14 fw500 m-l-6 p-x-16" @click="onAddPort">Save</button>
    </template>
  </modal-slots>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, /* numeric */ } from 'vuelidate/lib/validators'
import { mapState, mapMutations, mapActions } from 'vuex'

import modalSlots from '@/components/modal/modal-slots'
import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import addPda from '@/components/ports/list/addPda'
import bunkering from '@/components/ports/list/bunkering'
import files from '@/components/ports/list/files'
import moment from 'moment';
import Inputmask from "inputmask";


export default {
  mixins: [validationMixin],
  components: {
    modalSlots,
    counterpartiesDrop,
    addPda,
    bunkering,
    files
  },
  props: {
    account: Object,
    isShowModal: Boolean,
    isDupl: Boolean,
    noRedirect: Boolean,
  },
  data() {
    return {
      dwtItems: [
        {min: null, max: null, price: null}
      ],
      typePort: true,
      clearPort: {
        dwt_fields: [],
        transit_time: '',
        country: '',
        name: '',
        max_draft: '',
        max_loa: '',
        max_beam: '',
        water_density: 'null',
        pda: [],
        zone: '',
        latitude: '',
        longitude: '',
        bunkerning_not_available: true,
        bunkerning_at_berth: false,
        bunkerning_at_road: false,
        bunkerning_in_transit: false,
        weather_condition: '',
        files: [],
        oldFiles: [],
        data_type: 'port'
      },
      portObj: null,
      activeWater: {
        id: 'null',
        name: '—'
      },
    }
  },
  validations: {
    dwtItems: {
      $each: {
        min: {
          maxLength: maxLength(50),
        },
        max: {
          maxLength: maxLength(50),
        },
        price: {
          maxLength: maxLength(50),
        },
      }
    },
    portObj: {
      country: {
        required,
        maxLength: maxLength(200),
      },
      name: {
        required,
        maxLength: maxLength(200),
      },
      max_draft: {
        maxLength: maxLength(7),
      },
      max_loa: {
        maxLength: maxLength(7),
      },
      max_beam: {
        maxLength: maxLength(7),
      },
      zone: {
        maxLength: maxLength(500),
      },
      latitude: {
        maxLength: maxLength(100),
      },
      longitude: {
        maxLength: maxLength(100),
      },
      weather_condition: {
        maxLength: maxLength(500),
      },
    }
  },
  watch: {
    isShowModal: function() {
      this.checkEdit()
      if(!this.isShowModal) {
        this.dwtItems = [
          {min: null, max: null, price: null}
        ]
      }
    }
  },
  computed: {
    ...mapState({
      waterDensityList: state => state.ports.waterDensityList
    }),
  },
  methods: {
    ...mapActions(['axiosDeleteDwt', 'axiosCreateDwtPort', 'axiosEditPort', 'axiosAddPort', 'axiosGetPortById', 'axiosChangeDwtPort']),
    ...mapMutations(['showLoad', 'hideLoad']),
    checkMinMax(min, max, index) {
      if(min !== null && max !== null) {
        if(parseFloat(max) < parseFloat(min)) {
          this.dwtItems[index].min = max;
          this.dwtItems[index].max = min;
        }
      }

    },
    checkFromTime(value) {
      if(value && !moment(value, 'HH:mm').isValid()){
        return '24:00'
      } else return value
    },
    checkNumFloatOut(value) {
      let fixedNumber = parseFloat(value)
      fixedNumber = fixedNumber ? fixedNumber : 0
      fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      if(fixedNumber == '0.00') {
        return null
      }
      return fixedNumber.replaceAll(',', '')
    },
    checkNumFloat(num) {
      if(num.indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);
        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");
        if(num2.length > 2) {
          num2 = num2.substring(0, 2);
        }
        num = num1 + "." + num2;
      } else {
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    checkNum(num) {
      num = num.replace(/[^0-9]/g, "");
      return num;
    },
    addDwtItem() {
      this.dwtItems.push({min: null, max: null, price: null})
    },
    removeDwtItem(index) {
      this.dwtItems.splice(index, 1);
      if(this.dwtItems.length === 0) {
        this.dwtItems.push({min: null, max: null, price: null})
      }
    },
    validateFields() {
      if(this.typePort) {
        this.$v.$touch()
        if(this.portObj.pda.length > 1 || (this.portObj.pda[0].cargo || this.portObj.pda[0].terminal))
          this.$refs.pdaBlock.$v.$touch()
        else
          this.$refs.pdaBlock.$v.$reset()

        if(this.$v.$invalid || (this.$refs.pdaBlock.$v.$invalid && this.$refs.pdaBlock.$v.$dirty))
          return false
        return true
      } else {
        this.$v.$touch()

        if(this.$v.portObj.country.$invalid || this.$v.portObj.name.$invalid || this.$v.dwtItems.$invalid) {
          return false
        } else {
          this.$refs.pdaBlock.$v.$reset()

        }
        return true
      }
    },
    async onAddPort() {
      if(this.validateFields()){
        let id = null
        this.showLoad()

        this.portObj.pda = this.portObj.pda.filter(el => {
          return (el.cargo || el.terminal)
        })
        this.portObj.files = this.$refs.dropzone.getFiles()

        let IDs = this.dwtItems.map(a=> {return a.id ? a.id : null}).filter(a=> a!== null);
        this.portObj.dwt_fields = this.portObj.dwt_fields.map(a=> {
          if(IDs.indexOf(a.id) === -1) {
            this.axiosDeleteDwt(a.id)
          } else {
            return a;
          }
        }).filter(a=> a !== undefined && a !== null)
        IDs = this.portObj.dwt_fields.map(a=> {return a.id})
        let newIds = [];
        for(let i=0; i<this.dwtItems.length; i++) {
          if(this.dwtItems[i]?.id) {
            let Item = this.portObj.dwt_fields.find(el=>el.id === this.dwtItems[i].id);
            if(Item) {
              if(Item.min !== this.dwtItems[i].min || Item.max !== this.dwtItems[i].max || Item.price !== this.dwtItems[i]) {
                await this.axiosChangeDwtPort(this.dwtItems[i])
              }
            }
          } else {
            if(this.dwtItems[i].min || this.dwtItems[i].max || this.dwtItems[i].price) {
              let item = await this.axiosCreateDwtPort(this.dwtItems[i]);
              newIds.push(item.id)
            }
          }
        }
        this.portObj.dwt_fields = (this.portObj.dwt_fields.map(a=> {return a.id})).concat(newIds)
        if(this.account && !this.isDupl){
          id = this.account.id

          await this.axiosEditPort(this.portObj)
        }else{
          if(!this.typePort) {
            for(let i=0; i<this.dwtItems.length; i++) {
              if(this.dwtItems[i].min || this.dwtItems[i].max || this.dwtItems[i].price) {
                let dwt = await this.axiosCreateDwtPort(this.dwtItems[i])
                this.portObj.dwt_fields.push(dwt.id)
              }
            }
          }
          this.portObj.data_type = this.typePort ? 'port' : 'canal'
          id = await this.axiosAddPort(this.portObj)
        }

        this.hideLoad()
        this.$emit('added')
        this.$emit('on-close')
        if(!this.noRedirect) {
          if(this.$route.name == 'ports_list')
            this.$router.push(`/ports/${id}/detail`)
          else{
            if(this.$route.params.id == id)
              this.axiosGetPortById(this.$route.params.id)
            else{
              this.$router.push(`/ports/${id}/detail`)
              this.axiosGetPortById(this.$route.params.id)
            }
          }
        }
      }
    },
    checkEdit() {
      if(this.account){
        this.portObj = JSON.parse(JSON.stringify(this.account))
        this.activeWater = this.waterDensityList.find(el=>el.id == this.account.water_density)
        this.portObj.oldFiles = this.account.files.map(el=>el.id)
        this.dwtItems = JSON.parse(JSON.stringify(this.portObj.dwt_fields))
        if(this.dwtItems.length === 0) {
          this.addDwtItem()
        }
        this.typePort = this.portObj.data_type === 'port'
        this.portObj.files = []
      }else{
        this.portObj = {...this.clearPort}
        this.activeWater = this.waterDensityList.find(el=>el.id == 'null')
      }
      setTimeout(()=> {
        if(this.$refs.from_time){
          if (!this.$refs.from_time.inputmask)
            Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.from_time)
        }
      }, 300)

    },
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
  .top-panel-select-type {
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-card-border);
    display: flex;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    &>* {
      width: 50%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid transparent;
      transition: .1s linear;
    }
    .active {
      color: var(--color-blue-dark);
      border-bottom: 2px solid var(--color-blue-dark);
    }
  }
  .col-2-3 {
    width: calc(((33.3333% - 8px) * 2) + 8px);
  }
  .mt14 {
    margin-top: 14px;
  }
  .wrap-dwt-items {
    .pb-15 {
      padding-bottom: 15px;
    }
    .max163 {
      max-width: 163px;
    }
    p {
      width: 100%;
      box-sizing: border-box;
      word-wrap: break-word;
      word-break: break-all; /* более приоритетно */
    }
    position: relative;
    .block-dwt-item {
      height: 88px;
      position: relative;
    }
    .button-add-item-dwt {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .button-remove-item-dwt {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }


</style>