<template>
  <div class="p-t-4">
    <h2 class="m-b-1 p-x-6 text-gray-text fz13 fw500" v-if="getTabsforCreator.length">ALL FILES</h2>
    <modal-slots :show-mod="showModal" @close="closeModal" class="modal-accept">
      <template v-slot:title>
        <p class="fz18 fw500">{{$route.path.indexOf('archive') === -1 ? 'Archive' : 'Unarchive'}} {{folder ? 'folder' : 'file'}}</p>
      </template>
      <p v-if="folder" class="text-in-modal fz14 text-dark">Are you sure you want to {{$route.path.indexOf('archive') === -1 ? 'archive' : 'unarchive'}} the folder <span class="fw600" v-if="idForDelete">“{{idForDelete.name}}”</span>? All files from this folder will also be archived.</p>
      <p v-if="!folder" class="text-in-modal fz14 text-dark">Are you sure you want to {{$route.path.indexOf('archive') === -1 ? 'archive' : 'unarchive'}} the file <span class="fw600" v-if="idForDelete">“{{idForDelete.name}}”</span>?</p>
      <!--      <template v-slot:bottomFirstButton>-->
<!--        <button></button>-->
<!--      </template>-->
      <template v-slot:bottomSecondButton>
        <button class="blue-button fz14 fw500 h32 buttonW100" v-on:click="onArchiveFolder(idForDelete.id)">{{$route.path.indexOf('archive') === -1 ? 'Archive' : 'Unarchive'}}</button>
      </template>
    </modal-slots>
    <modal-slots :show-mod="showModalMove" @close="closeModalMove" class="modal-move">
      <template v-slot:title>
        <p class="f18 fw500">Move to</p>
      </template>
      <div class="modal-move__content">
        <div class="button-move" v-on:click="changeMoveToFolder(null)" :class="{'move-active': folderMoveTo === null}">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="2" width="15" height="2" rx="1" fill="#9AA3B0"/>
            <rect x="1" y="7" width="10" height="2" rx="1" fill="#9AA3B0"/>
            <rect x="1" y="12" width="6" height="2" rx="1" fill="#9AA3B0"/>
          </svg>
          <p class="fz14 text-dark">All files</p>
          <svg class="svg-active" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
        </div>
        <button v-on:click="changeMoveToFolder(folder)" class="button-move" v-for="(folder, i) in getTabsForCreatorMoveMod" :key="i" :class="{'move-active': folderMoveTo !== null && folderMoveTo.id === folder.id}">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.7048 7.02591C15.4769 6.64984 15.0742 6.42505 14.6276 6.42505H4.68261C4.18964 6.42505 3.74129 6.70944 3.54006 7.14978L0.909668 12.7939C1.00897 13.2072 1.38616 13.516 1.83543 13.516H12.4161C12.8685 13.516 13.2819 13.2542 13.484 12.8399L15.7575 8.17635C15.937 7.80731 15.9171 7.37727 15.7048 7.02591Z" fill="#9AA3B0"/><path d="M3.0265 6.48486C3.22716 6.0332 3.67485 5.7413 4.16708 5.7413H13.5157V4.48363C13.5157 3.96518 13.0999 3.54331 12.5888 3.54331H7.13772C7.12973 3.54331 7.12425 3.54115 7.12219 3.53959L6.14652 2.10473C5.97372 1.8502 5.68832 1.69824 5.38339 1.69824H1.8368C1.3255 1.69824 0.909668 2.12011 0.909668 2.63856V11.1528L3.0265 6.48486Z" fill="#BDCBD6"/></svg>
          <p class="fz14 text-dark">{{folder.name}}</p>
          <svg class="svg-active" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
        </button>


      </div>
      <template v-slot:bottomSecondButton>
        <button class="blue-button fz14 fw500 h32 buttonW100" v-on:click="moveAccept">Move</button>
      </template>
    </modal-slots>
    <calc-list-item
        v-for="tab in searchTabs.filter(a=> a.id === newCreateId)"
        @move-to="openModalMove"
        @on-archive="showModalConfirmDeleteFile"
        :tab="tab"
        :searchText="searchText"
        :key="'li23st'+tab.id"
        :ref="'list'+tab.id"
    />
    <button v-on:click="showDrafts = !showDrafts" class="d-flex align-items-center fz14 text-dark h28 p-l-6 p-r-3 p-y-0.5 box-border hover:bg-gray-lightest w100Proc">
      <svg :class="{'rotate-180': !showDrafts}" class="mr4" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.72443 8.09961C2.45716 8.09961 2.32331 7.77647 2.5123 7.58748L5.78804 4.31174C5.9052 4.19458 6.09515 4.19458 6.21231 4.31174L9.48803 7.58748C9.67702 7.77647 9.54317 8.09961 9.2759 8.09961H2.72443Z" fill="#2C2C2C"/>
      </svg>

      <svg class="mr4" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8889 16.5H2.11111C1.49746 16.5 1 16.0224 1 15.4333V1.56667C1 0.977563 1.49746 0.5 2.11111 0.5H9L15 6.5V15.4333C15 16.0224 14.5025 16.5 13.8889 16.5ZM4.75 8.5C4.33579 8.5 4 8.83579 4 9.25C4 9.66421 4.33579 10 4.75 10H11.25C11.6642 10 12 9.66421 12 9.25C12 8.83579 11.6642 8.5 11.25 8.5H4.75ZM4.75 12C4.33579 12 4 12.3358 4 12.75C4 13.1642 4.33579 13.5 4.75 13.5H9.25C9.66421 13.5 10 13.1642 10 12.75C10 12.3358 9.66421 12 9.25 12H4.75Z" fill="#9AA3B0"/>
        <path d="M15 6.5L10.4286 6.5C9.63959 6.5 9 5.86041 9 5.07143L9 0.5L11.7143 3.26L15 6.5Z" fill="#BDCBD6"/>
      </svg>
      Drafts
    </button>
    <div v-show="showDrafts">
      <calc-list-item
          v-for="tab in searchTabs.filter(a=> a.name === 'New file' && a.tab_type == 'list' && a.id !== newCreateId)"
          @move-to="openModalMove"
          @on-archive="showModalConfirmDeleteFile"
          :tab="tab"
          class="p-l-10"
          :searchText="searchText"
          :key="'list'+tab.id"
          :ref="'list'+tab.id"
      />
    </div>
    <template
      v-for="tab in searchTabs.filter(a=> ((a.name !== 'New file' && a.tab_type == 'list') || a.tab_type !== 'list') && a.id !== newCreateId)"
    >
      <calc-list-item 
        @move-to="openModalMove" 
        @on-archive="showModalConfirmDeleteFile" 
        :tab="tab" 
        :searchText="searchText"
        :key="'list'+tab.id" 
        v-if="tab.tab_type == 'list'" 
        :ref="'list'+tab.id"
      />
      <calc-list-folder 
        @move-to="openModalMove" 
        @on-archive-file="showModalConfirmDeleteFile" 
        @on-archive="showModalConfirmDelete" 
        :tab="tab" 
        :searchText="searchText"
        :key="'folder'+tab.id" 
        v-else
        :ref="'folder'+tab.id"
      />
    </template>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import calcListItem from '@/components/calc/calcListItem'
import calcListFolder from '@/components/calc/calcListFolder'
import ModalSlots from "@/components/modal/modal-slots";
export default {
  props: {
    searchText: String,
    newCreateId: null,
  },
  components: {
    ModalSlots,
    calcListItem,
    calcListFolder
  },
  data() {
    return {
      showDrafts: false,
      showModalMove: false,
      showModal: false,
      idForDelete: null,
      folder: true,
      fileMove: null,
      folderDefMove: null,
      folderMoveTo: null,
    }
  },
  computed: {
    ...mapState({
      activeCreator: state => state.tabs.activeCreator,
      tabs: state => state.tabs.tabs
    }),
    getTabsforCreator() {
      if(!this.activeCreator || !this.tabs?.length) return []
      //let filteredTabs = JSON.parse(JSON.stringify(this.tabs))
      let filtered = this.tabs.filter(el=>{
        return el.creator?.id == this.activeCreator.id
      })
      
      return filtered
    },
    getTabsForCreatorMoveMod() {
      if(!this.activeCreator || !this.tabs?.length) return []
      let filteredTabs = JSON.parse(JSON.stringify(this.tabs))
      let filtered = filteredTabs.filter(el=>{
        return el.creator?.id == this.activeCreator.id && el.tab_type === 'group'
      })
      if(this.searchText){
        filtered = filtered.filter(el=>{
          if(el.tab_type == 'group'){
            let filterInGroup = el.list_in_group.filter(item=>{
              return item.name.indexOf(this.searchText) >= 0
            })
            el.list_in_group = filterInGroup
            return true
          }else{
            return el.name.indexOf(this.searchText) >= 0
          }
        })
      }
      return filtered
    },
    searchTabs() {
      let filtered = this.getTabsforCreator
      //let filtered = JSON.parse(JSON.stringify(this.getTabsforCreator))
      if(this.searchText){
        filtered = filtered.filter(el=>{
          if(el.tab_type == 'group'){
            /* el.list_in_group = el.list_in_group.filter(item=>{
              return item.name.indexOf(this.searchText) >= 0
            }) */
            return true
          }else{
            let name = el.name.toLowerCase()
            let search = this.searchText.toLowerCase()
            return name.indexOf(search) >= 0
          }
        })
      }
      filtered.forEach(item=> {
        if(item.tab_type == 'group') {
          item.list_in_group.sort((a, b)=> new Date(b.created_at) - new Date(a.created_at))
        }
      })
      return filtered.sort((a, b)=> new Date(b.created_at) - new Date(a.created_at))
    }
  },
  methods: {
    ...mapActions(['axiosDeleteTab', 'axiosPatchTab']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip', 'removeListAfterChange', 'addListAfterChange']),
    moveAccept() {
      let Folder1 = this.folderDefMove
      let Folder2 = this.folderMoveTo
      let file = this.fileMove;
      this.closeModalMove();
      this.showLoad();
      console.log(Folder1)
      console.log(Folder2)
      console.log(file)
      if(Folder1 !== null) {
        this.axiosPatchTab({id: Folder1.id, list_in_group: (Folder1.list_in_group.filter(a=> a.id !== file.id)).map(a=> {return a.id})})
      }
      if(Folder2 !== null) {
        let array = []
        if(Array.isArray(Folder2.list_in_group)) {
          array = Folder2.list_in_group.map(a=> {return a.id})
          array.push(file.id)
        }
        this.axiosPatchTab({id: Folder2.id, list_in_group: array})
      }
      this.removeListAfterChange({file: file, folder: Folder1})
      this.addListAfterChange({file: file, folder: Folder2})

      this.hideLoad()
    },
    changeMoveToFolder(folder) {
      this.folderMoveTo = folder
    },
    openModalMove(data) {
      this.showModalMove = true;
      this.fileMove = data.file;
      this.folderDefMove = data.folder
      this.folderMoveTo = data.folder
      this.$emit('modalEvent')
    },
    closeModalMove() {
      this.showModalMove = false
      this.fileMove = null
      this.folderDefMove = null
      this.folderMoveTo = null
      this.$emit('modalEvent')
    },
    showModalConfirmDeleteFile(id) {
      this.showModal = true;
      this.folder = false;
      this.idForDelete = id
      this.$emit('modalEvent')
    },
    showModalConfirmDelete(id) {
      this.showModal = true;
      this.folder = true;
      this.idForDelete = id
      this.$emit('modalEvent')
    },
    closeModal() {
      this.showModal = false
      this.idForDelete = null
      this.$emit('modalEvent')
    },
    async onArchiveFolder(id) {
      let item = this.idForDelete
      this.closeModal()
      this.showLoad()
      await this.axiosDeleteTab(id)
      this.hideLoad()
      if(item?.name) {
        this.addTip((this.folder ? "The folder " : "The file ") + "<span class='fw600 fz14'>“"+ item.name +"”</span>" + ' is ' + (item.is_archived ? 'unarchived' : 'archived'))
      } else {
        this.addTip((this.folder ? "The folder" : "The file") + ' is ' + (item.is_archived ? 'unarchived' : 'archived'))
      }
    },
    onOpenNew(newId) {
      console.log('onOpenNew', this.$refs['list'+newId])
      setTimeout(()=> {
        this.$refs['list'+newId][0].onRename()
      }, 2000)
    },
    onRenameFolder(newId) {
      setTimeout(()=> {
        this.$refs['folder'+newId][0].onRename()
      }, 500)
    }
  },
}
</script>

<style lang="scss" scoped>
  .modal-accept {
    /deep/ {
      .content {
        width: 424px;
      }
      .top-panel {
        border: none !important;
        padding: 12px 16px 0 16px !important;
      }
      .bottom-panel {
        border: none !important;
        padding: 0px 16px 12px 16px !important;
      }
    }
    .text-in-modal {
      box-sizing: border-box;
      padding: 16px;
    }
    .blue-button {
      margin-left: 33px;
    }
  }

  .modal-move {
    .blue-button {
      margin-left: 46px;
    }
    .button-move {
      position: relative;
      width: 100%;
      cursor: pointer;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg {
        margin-right: 4px;
      }
      padding: 0 22px;
      box-sizing: border-box;
      transition: .1s linear;
      &:hover {
        background-color: var(--color-bgc-page);
      }
      .svg-active {
        display: none;
        position: absolute;
        right: 12px;
      }
    }
    .move-active {
      background-color: var(--color-bgc-page);
      .svg-active {
        display: block;
      }
    }
  }
  .modal-move,.modal-accept {
    @media (max-width: 1687px) {
      z-index: 999;
      top: -45px !important;
      //height: calc(100vh - 55px);
      overflow: hidden;
    }
    /deep/ {
      .filter {
        position: absolute !important;
      }
    }
  }

</style>