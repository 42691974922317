<template>
  <div class="flex flex-col flex-grow-1 bg-gray-lightest">
    <div class="max-w-1060 m-x-auto p-b-12">
      <div class="box-border edit-block-invoice" v-if="invoiceEditData">
<!--        <button v-on:click="reSave()">sfsdf</button>-->
        <edit-title class="p-y-4" :title="(invoiceEditableItem ? 'Edit ' : 'Create ') + $route.meta.invoiceType"/>
        
        <invoice-detail @addNewPort="addNewPort" ref="details" :errorEndDate="errorEndDate" :errorStartDate="errorStartDate" :detail="invoiceEditData"/>

        <edit-detention-invoice
          v-if="$route.meta.invoiceType == 'Detention Invoice'"
          :invoice="invoiceEditData"
          @on-delete="onDeleteBlock"
        />
        
        <edit-freight-invoice
          :invoice="invoiceEditData"
          @on-delete="onDeleteBlock"
          v-else
        />

        <div class="invoice_table border border-dark overflow-hidden rounded-lg bg-gray-dark m-t-3 m-b-6"
          :class="{
            'with_trials': getPermissionsByType('show_accounts_in_all_operations') && ($route.meta.invoiceType == 'Voyage Statement of Account' || $route.meta.invoiceType == 'Detention Invoice')
          }"
        >
          <div class="border-r fw500 p-3">Total DUE TO OWNERS</div>
          <div 
            class="p-3 fw500 text-align-right break-words colspan-2-7"
            :class="{
              'colspan-2-8': getPermissionsByType('show_accounts_in_all_operations') && ($route.meta.invoiceType == 'Voyage Statement of Account' || $route.meta.invoiceType == 'Detention Invoice')
            }"
          >
            {{invoiceEditData.total_amount > 0 ? '':'('}}
            {{Math.abs(getTotalAmount()) | formatNumberTwo}}
            {{invoiceEditData.total_amount > 0 ? '':')'}}
          </div>
        </div>

        <div class="flex flex-wrap items-start bg-white rounded-lg shadow p-6">
          <div class="w-1/2 p-r-12 box-border">
            <linked-wrap class="m-b-6" v-if="$route.meta.invoiceType == 'Voyage Statement of Account' && !getPermissionHideLinkedOperation()" :linkedPayments="invoiceEditData.linked_payments" :linkedInvoices="[]" :linkedOwners="[]"/>

            <label class="block m-b-6">
              <p class="fw500 text-dark m-b-3">Note</p>
              <textarea
                rows="4"
                maxlength="3000"
                class="block-input_content rounded-lg bg-gray-lightest"
                placeholder="Write a total comment"
                v-model="invoiceEditData.note"
              ></textarea>
            </label>

            <files-attach :files="invoiceEditData.files" />
          </div>
          <div class="w-1/2 flex">
            <div class="w-1/3 block-radio-button">
              <p class="fw500 text-dark">Export settings</p>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.stamp = true">
                <svg  v-if="!export_settings.stamp" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg class="radio-button-active" v-if="export_settings.stamp" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Stamp enabled
              </button>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.stamp = false">
                <svg  v-if="export_settings.stamp" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg class="radio-button-active" v-if="!export_settings.stamp" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Stamp disabled
              </button>
            </div>
            <div class="w-1/3 block-radio-button">
              <p class="fz13 text-gray"></p>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.color = true">
                <svg  v-if="!export_settings.color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg class="radio-button-active" v-if="export_settings.color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Color
              </button>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.color = false">
                <svg v-if="export_settings.color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg class="radio-button-active" v-if="!export_settings.color" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Black and white
              </button>
            </div>
            <div class="w-1/3 block-radio-button">
              <p class="fz13 text-gray"></p>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.bank_details = true">
                <svg v-if="!export_settings.bank_details" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg class="radio-button-active" v-if="export_settings.bank_details" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>

                Bank details enabled
              </button>
              <button class="radio-button fz13 text-dark" v-on:click="export_settings.bank_details = false">
                <svg v-if="export_settings.bank_details" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
                </svg>
                <svg class="radio-button-active" v-if="!export_settings.bank_details" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
                  <circle cx="9" cy="9" r="5" fill="#094172"/>
                </svg>
                Bank details disabled
              </button>
            </div>
          </div>
        </div>
        <div class="p-t-8"></div>
      </div>
    </div>

    <div class="bg-white fixed bottom-0 z-100" :style="{width: 'calc(100% - 264px)'}">
      <edit-actions 
      class="max-w-1060 m-x-auto" 
      :blocks="getDropSections"
      @on-save="onSave" 
      @on-add-block="onAddBlock"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";

import editDetentionInvoice from "@/components/vessels/invoice/editDetentionInvoice";
import editFreightInvoice from "@/components/vessels/invoice/editFreightInvoice";

import editTitle from "@/components/vessels/owners/edit/editTitle";
import filesAttach from "@/components/vessels/owners/filesAttach";
import editActions from "@/components/vessels/invoice/editActions";
import invoiceDetail from "@/components/vessels/invoice/invoiceDetail";
import linkedWrap from '@/components/entries/linked/linkedWrap'

export default {
  components: {
    editDetentionInvoice,
    editFreightInvoice,

    editTitle,
    filesAttach,
    editActions,
    invoiceDetail,
    linkedWrap
  },
  updated() {
    setTimeout(()=> {
      let inputs = document.querySelectorAll('.edit-block-invoice input:not([type=checkbox], [type=radio], [disabled=disabled], .dropdown-menu input)')
      inputs.forEach((el, i)=> {
        el.setAttribute('tabindex', i)
      })
      console.log(inputs)
    }, 1000)
  },
  data() {
    return {
      reSaveMode: false,
      // idsReSave: [],
      invoiceEditData: null,
      countBlocks: 0,
      export_settings: {
        stamp: true,
        color: true,
        bank_details: true,
      },
      errorEndDate: false,
      errorStartDate: false,
    }
  },
  watch: {
    $route: async function () {
      if(this.reSaveMode) {
        this.showLoad()
        await this.axiosGetLastInvoice(this.$route.params.subId)
        if(!(this.$route.meta.invoiceType == 'Detention Invoice' && this.getByKey('LESS_RECEIVED').length !== 0)) {
          this.hideLoad()
        }

        this.onSetInvoiceData()

        this.export_settings.stamp = this.invoiceEditData.stamp;
        this.export_settings.color = this.invoiceEditData.color;
        this.export_settings.bank_details = this.invoiceEditData.bank_details;
        this.invoiceEditData.cargo_operations_start_time = this.invoiceEditData.cargo_operations_start_date && moment(this.invoiceEditData.cargo_operations_start_date).format('HH:mm') !== '00:00' ? moment(this.invoiceEditData.cargo_operations_start_date).format('HH:mm') : ''
        this.invoiceEditData.cargo_operations_end_time = this.invoiceEditData.cargo_operations_end_date && moment(this.invoiceEditData.cargo_operations_end_date).format('HH:mm') !== '00:00' ? moment(this.invoiceEditData.cargo_operations_end_date).format('HH:mm') : ''

        setTimeout(()=> {
          this.onSave()
        }, 5000)

      }
    }
  },
  computed: {
    ...mapState({
      activeContract: state => state.contract.activeContract,
      invoiceEditableItem: state => state.invoice.invoiceEditableItem,
      emptyEditData: state => state.invoice.invoiceEditData,
      invoiceBlocksData: state => state.invoice.invoiceBlocksData,
      idsReSave: state => state.invoice.idsReSave,
    }),
    ...mapGetters(['getInvoiceType', 'getInvoiceUrl', 'getPermissionsByType']),
    getOceanFreight() {
      return this.invoiceEditData?.blocks.find(el => el.block_type == 'ILOW')
    },
    getDeadFreight() {
      return this.invoiceEditData?.blocks.find(el => el.block_type == 'DEAD_FREIGHT')
    },
    getExtraFreight() {
      return this.invoiceEditData?.blocks.find(el => el.block_type == 'EXTRA_OCEAN_FREIGHT')
    },
    getDropSections() {
      let blocks = []
      let blocksFreight = []
      let blocksAnother = [
        {type: 'LESS_ADDRESS_COMMISSION', name: 'Less Address Commission'},
        {type: 'LESS_BROKERAGE_COMMISSION', name: 'Less Brokerage Commission'}
      ]
      if(this.getInvoiceType() != 'DETENTION'){
        if(!this.getDeadFreight)
          blocksFreight.push({type: 'DEAD_FREIGHT', name: 'Dead freight'})
        if(!this.getExtraFreight)
          blocksFreight.push({type: 'EXTRA_OCEAN_FREIGHT', name: 'Extra ocean freight'})
        if(this.getOceanFreight && !this.getOceanFreight.additional_info.is_ilow)
          blocksFreight.push({type: 'freight_ilow', name: 'Ocean Freight ILOW'})
        if(this.getDeadFreight && !this.getDeadFreight?.additional_info.is_ilow)
          blocksFreight.push({type: 'dead_ilow', name: 'Dead Freight ILOW'})
        if(this.getExtraFreight && !this.getExtraFreight?.additional_info.is_ilow)
          blocksFreight.push({type: 'extra_ilow', name: 'Extra Freight ILOW'})

        blocksAnother.unshift({type: 'THEREOF', name: 'Thereof'})
        blocksAnother.push({type: 'PLUS_DEMURRAGE', name: 'Plus Demurrage'})
        blocksAnother.push({type: 'LESS_DISPATCH', name: 'Less Dispatch'})
        blocksAnother.push({type: 'PLUS_DETENTION', name: 'Plus detention'})
        blocksAnother.push({type: 'PLUS_CHARTERERS_EXPENSES', name: 'Plus Charterers\' expenses'})
        blocksAnother.push({type: 'LESS_OWNERS_EXPENSES', name: 'Less Owners\' expenses'})
        if(this.getInvoiceType() == 'SHIP' && this.invoiceEditData && !this.invoiceEditData.blocks.find(el => el.block_type == 'LESS_RECEIVED')) blocksAnother.push({type: 'LESS_RECEIVED', name: 'Less Received'})
      }

      if(blocksFreight.length) blocks.push(blocksFreight)
      if(blocksAnother.length) blocks.push(blocksAnother)
      return blocks
    }
  },
  methods: {
    ...mapActions([
      'axiosGetLastInvoice',
      'axiosAddInvoice',
        'axiosGetTrials', 'axiosOpenContractById'
    ]),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip', 'deleteReseveId']),
    addNewPort() {
      this.invoiceEditData.ports.push(JSON.parse(JSON.stringify({
        discharge_port: null,
        loading_port: null,
        total_quantity: null,
      })))
    },
    getPermissionHideLinkedOperation() {
      return this.getPermissionsByType('hide_link_operations_in_payments')
    },
    onAddBlock(block) {
      if(block.type == 'freight_ilow') this.getOceanFreight.additional_info.is_ilow = true
      else if(block.type == 'dead_ilow') this.getDeadFreight.additional_info.is_ilow = true
      else if(block.type == 'extra_ilow') this.getExtraFreight.additional_info.is_ilow = true
      else{
        let newBlock = JSON.parse(JSON.stringify(this.invoiceBlocksData[block.type]))
        newBlock.count = this.countBlocks
        this.countBlocks++
        this.invoiceEditData.blocks.push(newBlock)
      }
    },
    onDeleteBlock(block){
      if(block.type == 'freight_ilow'){
        this.getOceanFreight.additional_info.is_ilow = false
        this.getOceanFreight.percentage = null
      }
      else if(block.type == 'dead_ilow'){
        this.getDeadFreight.additional_info.is_ilow = false
        this.getDeadFreight.percentage = null
      }
      else if(block.type == 'extra_ilow'){
        this.getExtraFreight.additional_info.is_ilow = false
        this.getExtraFreight.percentage = null
      }
      else{
        this.invoiceEditData.blocks.sort((a, b) => a.block_type.localeCompare(b.block_type))
        let index = this.invoiceEditData.blocks.findIndex(el => el.block_type == block.type)
        if(block.index >= -1) index += +block.index
        this.invoiceEditData.blocks.splice(index, 1)
      }
    },

    getTotalAmount() {
      let result = 0
      let sumDispatch = 0;
      this.invoiceEditData.blocks.forEach(block => {
        if(block.block_type != 'ILOW' && block.block_type != 'DEAD_FREIGHT' && block.block_type != 'EXTRA_OCEAN_FREIGHT'){
          if(this.$route.path.indexOf('soa')>=0) {
            if(block.block_type == 'LESS_RECEIVED'){
              let summ = 0
              console.log('block.additional_info', block.additional_info)
              if(!block.additional_info.hide_payments) {
                block.additional_info['hide_payments'] = []
                block.additional_info = JSON.parse(JSON.stringify(block.additional_info))
              }
              sumDispatch += block.additional_info.payments?.length ? block.additional_info.payments.filter(payment=> block.additional_info.hide_payments.indexOf(payment.id) == -1).reduce((a,b) => {
                return a + +b.amount
              }, summ) : summ
            }
          }
          result += parseFloat(block.total_amount) ? parseFloat(block.total_amount) : 0
          result += parseFloat(block.percentage_amount) ? parseFloat(block.percentage_amount) : 0
          result += parseFloat(block.additional_percentage_amount) ? parseFloat(block.additional_percentage_amount) : 0
          result += parseFloat(block.third_percentage_amount) ? parseFloat(block.third_percentage_amount) : 0
        }
      })

      result += parseFloat(this.invoiceEditData.detention_invoices?.total) ? parseFloat(this.invoiceEditData.detention_invoices?.total) : 0
      this.invoiceEditData.total_amount = result
      return result + sumDispatch
    },

    fixStrToNum(obj) {
      let kayArr = [
        'percentage', 
        'additional_percentage', 
        'third_percentage', 
        'rate', 
        'percentage_amount', 
        'additional_percentage_amount', 
        'third_percentage_amount', 
        'mt_amount', 
        'total_amount',
        'amount',
        'total',
      ]
      Object.entries(obj).forEach(([key, value]) => {
        if(value && value != null && typeof value == 'object'){
          this.fixStrToNum(obj[key])
        }else{
          if(kayArr.indexOf(key) >= 0){
            obj[key] = value ? parseFloat(value) : null
          }
        }
      });
    },
    fixNumToStr(obj) {
      let kayArr = [
        'percentage', 
        'additional_percentage', 
        'third_percentage', 
        'rate', 
        'mt_amount', 
        'amount',
      ]
      Object.entries(obj).forEach(([key, value]) => {
        if(value && value != null && typeof value == 'object'){
          this.fixNumToStr(obj[key])
        }else{
          if(kayArr.indexOf(key) >= 0){
            obj[key] = value ? value.toFixed(key === 'mt_amount' ? 3 : 2) : null
          }
        }
      });
    },

    async onSave() {
      if(!this.invoiceEditData.cp_date){
        this.addTip('CP Date is required!')
        return false
      }
      if(!this.invoiceEditData.cargo_operations_start_date && this.$route.path.indexOf('vsoa')>=0) {
        this.addTip('Cargo operations start date is required!')
        this.errorStartDate = true
      } else {
        this.errorStartDate = false
      }
      if(!this.invoiceEditData.cargo_operations_end_date && this.$route.path.indexOf('vsoa')>=0) {
        this.addTip('Cargo operations end date is required!')
        this.errorEndDate = true
      } else {
        this.errorEndDate = false
      }
      if((!this.invoiceEditData.cargo_operations_start_date || !this.invoiceEditData.cargo_operations_end_date) && this.$route.path.indexOf('vsoa')>=0) {
        return false
      }
      // if(this.invoiceEditData.cargo_operations_start_time)
      this.invoiceEditData.cargo_operations_start_date = this.$route.path.indexOf('vsoa') >= 0 ? (this.invoiceEditData.cargo_operations_start_time ? moment(this.invoiceEditData.cargo_operations_start_date).format('YYYY-MM-DD') + ' '+ this.invoiceEditData.cargo_operations_start_time : moment(this.invoiceEditData.cargo_operations_start_date).format('YYYY-MM-DD') + ' 00:00') : null
      this.invoiceEditData.cargo_operations_end_date = this.$route.path.indexOf('vsoa') >= 0 ? (this.invoiceEditData.cargo_operations_end_time ? moment(this.invoiceEditData.cargo_operations_end_date).format('YYYY-MM-DD') + ' '+ this.invoiceEditData.cargo_operations_end_time : moment(this.invoiceEditData.cargo_operations_end_date).format('YYYY-MM-DD') + ' 00:00') : null
      let saveObj = JSON.parse(JSON.stringify(this.invoiceEditData))
      saveObj.stamp = this.export_settings.stamp
      saveObj.color = this.export_settings.color
      saveObj.bank_details = this.export_settings.bank_details

      saveObj.cp_date = moment(saveObj.cp_date, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm')
      saveObj.invoice_type = this.getInvoiceType()
      saveObj.contract = this.$route.params.subId

      saveObj.loading_port = saveObj.loading_port?.id
      saveObj.discharge_port = saveObj.discharge_port?.id

      //saveObj.due_date = saveObj.due_date ? moment(saveObj.due_date, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm') : null
      if(Array.isArray(saveObj.ports) === true) {
        saveObj.ports.forEach(item=> {
          item.loading_port = item.loading_port ? item.loading_port.id : null
          item.discharge_port = item.discharge_port ? item.discharge_port.id : null
        })
      }
      saveObj.files = saveObj.files.map(el => {
        return el.id
      })
      saveObj.linked_payments = saveObj.linked_payments.map(el => el.id)

      saveObj.blocks.forEach(el=>{
        if(typeof el.lay_report == 'object')
          el.lay_report = el.lay_report?.id
      })

      let date_from = moment(saveObj.detention_invoices.date_from, "DD.MM.YYYY")
      if(saveObj.detention_invoices.time_from){
        date_from.set({
          'hour': saveObj.detention_invoices.time_from.split(':')[0], 
          'minute': saveObj.detention_invoices.time_from.split(':')[1]
        })
      }
      let date_to = moment(saveObj.detention_invoices.date_to, "DD.MM.YYYY")
      if(saveObj.detention_invoices.time_to){
        date_to.set({
          'hour': saveObj.detention_invoices.time_to.split(':')[0], 
          'minute': saveObj.detention_invoices.time_to.split(':')[1]
        })
      }

      saveObj.detention_invoices.date_from = date_from.isValid() ? date_from.format('YYYY-MM-DD HH:mm') : null
      saveObj.detention_invoices.date_to = date_to.isValid() ? date_to.format('YYYY-MM-DD HH:mm') : null
      if(this.reSaveMode) {
        saveObj.charterer = this.invoiceEditableItem.charterer ? this.invoiceEditableItem.charterer.id : null
      }
      saveObj = JSON.parse(JSON.stringify(saveObj))
      this.fixStrToNum(saveObj.blocks)
      await this.axiosAddInvoice(saveObj)
      console.log(`reSave SAVE`)

      if(!this.reSaveMode) {
        this.$router.push(`/vessels/${this.$route.params.id}/open/charterers/${this.$route.params.subId}/${this.getInvoiceUrl()}/`)
      } else {
        // setTimeout(()=> {
        //   this.reSave()
        // }, 1000)
        // console.log()
      }
    },
    async onSetInvoiceData() {
      if(this.invoiceEditableItem){
        this.invoiceEditData = JSON.parse(JSON.stringify(this.invoiceEditableItem))
        if(!this.getOceanFreight) this.invoiceEditData.blocks.push(JSON.parse(JSON.stringify(this.invoiceBlocksData.ILOW)))

        this.fixNumToStr(this.invoiceEditData)
        if(!this.invoiceEditData.blocks.find(el => el.block_type == 'LESS_RECEIVED')) this.invoiceEditData.blocks.push(JSON.parse(JSON.stringify(this.invoiceBlocksData.LESS_RECEIVED)))
        await this.$nextTick()
        this.showLoad()
        await this.axiosGetTrials()
        this.hideLoad()
        this.$refs.details.onSetEdit()
      }else{
        let invoice = JSON.parse(JSON.stringify(this.emptyEditData))
        if(this.getInvoiceType() != 'DETENTION') invoice.blocks.push(JSON.parse(JSON.stringify(this.invoiceBlocksData.ILOW)))
        if(this.getInvoiceType() == 'SHIP') invoice.blocks.push(JSON.parse(JSON.stringify(this.invoiceBlocksData.LESS_RECEIVED)))
        this.invoiceEditData = invoice
      }
      if(!this.invoiceEditData.ports) {
        this.invoiceEditData['ports'] = []
        if(this.invoiceEditData.loading_port || this.invoiceEditData.discharge_port || this.invoiceEditData.total_amount) {
          this.invoiceEditData.ports.push({
            loading_port: this.invoiceEditData.loading_port,
            discharge_port: this.invoiceEditData.discharge_port,
            total_amount: this.invoiceEditData.total_amount,
          })
        }
        if(!this.invoiceEditData.ports.length) {
          this.invoiceEditData.ports.push({
            loading_port: null,
            discharge_port: null,
            total_amount: null,
          })
        }
        this.invoiceEditData = JSON.parse(JSON.stringify(this.invoiceEditData))
      }
    },
    getByKey(type) {
      if(this.invoiceEditData && this.invoiceEditData.blocks && Array.isArray(this.invoiceEditData.blocks) === true) {
        return this.invoiceEditData.blocks.filter(el => el.block_type == type)
      }
      return []
    },
    async reSave() {
      console.log(`reSave start`, this.idsReSave)
      this.reSaveMode = this.idsReSave.length !== 0
      if(!this.reSaveMode) {
        return null
      }
      let item = this.idsReSave[0]
      window.location.href = window.location.href.replace('vessels/'+this.activeContract.id, 'vessels/'+item.idContract);

      await this.axiosOpenContractById(item.idContract)
      setTimeout(()=> {
        this.$router.push({name: this.$route.params.name, params: {subId: item.idCargo, id: item.idContract}})
        this.deleteReseveId(item.idCargo)
        console.log('reSave end', this.idsReSave)
      }, 1000)

    },
  },

  async mounted() {
    this.showLoad()
    await this.axiosGetLastInvoice(this.$route.params.subId)
    if(!(this.$route.meta.invoiceType == 'Detention Invoice' && this.getByKey('LESS_RECEIVED').length !== 0)) {
      this.hideLoad()
    }

    this.onSetInvoiceData()

    this.export_settings.stamp = this.invoiceEditData.stamp;
    this.export_settings.color = this.invoiceEditData.color;
    this.export_settings.bank_details = this.invoiceEditData.bank_details;
    this.invoiceEditData.cargo_operations_start_time = this.invoiceEditData.cargo_operations_start_date && moment(this.invoiceEditData.cargo_operations_start_date).format('HH:mm') !== '00:00' ? moment(this.invoiceEditData.cargo_operations_start_date).format('HH:mm') : ''
    this.invoiceEditData.cargo_operations_end_time = this.invoiceEditData.cargo_operations_end_date && moment(this.invoiceEditData.cargo_operations_end_date).format('HH:mm') !== '00:00' ? moment(this.invoiceEditData.cargo_operations_end_date).format('HH:mm') : ''

    // console.log('reSave 1')
    // setTimeout(()=> {
    //   this.reSave()
    // }, 5000)
  },
};
</script>

<style lang="scss">
.invoice_top_inputs {
  .counterparties-drop,
  .block-input {
    width: calc(25% - 12px);
  }
  .block-input_wide{
    width: calc(50% - 8px);
  }
}
.invoice_table {
  display: grid;
  //grid-template-columns: 1fr 0.79fr 0.544fr 0.72fr 0.72fr 0.35fr 0.509fr;
  grid-template-columns: 23.5% 19% 17.5% 17.5% 9.55% 12.95%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  /* border: 1px solid var(--color-gray-dark);
  border-radius: 0.5rem; */
  background-color: var(--color-white);
  font-size: 0.875rem;
  line-height: 1.063rem;
  & > div {
    border-color: var(--color-gray-light);
    text-overflow: ellipsis;
  }
  &.with_trials{
    grid-template-columns: 21.5% 17% 12% 15.5% 15.5% 7.55% 10.95%;
  }
  .colspan-1-8 {
    grid-column: 1 / 8;
  }
  .colspan-5-7 {
    grid-column: 5 / 7;
  }
  .colspan-5-8 {
    grid-column: 5 / 8;
  }
  .colspan-4-7 {
    grid-column: 4 / 7;
  }
  .colspan-4-6 {
    grid-column: 4 / 6;
  }
  .colspan-3-6 {
    grid-column: 3 / 6;
  }
  .colspan-1-6 {
    grid-column: 1 / 6;
  }
  .colspan-1-7 {
    grid-column: 1 / 7;
  }
  p[contenteditable="true"],
  input {
    height: 100%;
    border-width: 0;
    border-radius: inherit;
    background-color: transparent;
    &:hover,
    &:focus {
      box-shadow: 0px 0px 0px 1px var(--color-blue-dark) !important;
    }
    //&::placeholder {
    //  color: var(--color-input-placeholder) !important;
    //}
  }
  .counterparties-drop {
    margin-top: 0;
    .dropdown-toggle {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .counterparties-drop_title {
    display: none;
  }

  .mx-datepicker{
    width: 100%;
    height: 100%;
  }
  .mx-input-wrapper{
    height: 100%;
  }
  .mx-input-wrapper .mx-input{
    padding-left: 0.75rem !important;
    border: none !important;
    background-position: calc(100% - 12px) center;
  }
  .mx-icon-calendar,
  .mx-icon-clear{
    display: none !important;
  }
}
.invoice_port{
  .multiselect__tags{
    border: none;
  }
  .multiselect__input{
    &:focus{
      box-shadow: none !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.block-radio-button {
  p {
    height: 17px;
    margin-bottom: 24px;
  }
}
.radio-button {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 7px;
  }
  margin-bottom: 16px;
}
.radio-button {
  .radio-button-active {
    circle:nth-child(1) {
      stroke: var(--color-blue-dark)
    }
    circle:nth-child(2) {
      fill: var(--color-blue-dark)
    }
  }
}
</style>