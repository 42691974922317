<template>
  <div>
    <checklist-wrap-item :isDetailTask="isDetailTask" class="mt12" v-for="(itemCh, i) in checkListLocal" :value="itemCh" @change="changeChecklist" :key="i"></checklist-wrap-item>
  </div>
</template>

<script>
import ChecklistWrapItem from "@/components/checklist/checklist-wrap-item";
import {mapActions} from "vuex";
export default {
  name: "checklist-lists",
  components: {ChecklistWrapItem},
  props: {
    checkList: Array,
    isDetailTask: Boolean,
  },
  watch: {
    checkList: function () {
      this.loadNewCheckList()
    }
  },
  data() {
    return {
      checkListLocal: [],
      // checkList: [
      //   {name: 'sdsdsdasda', list: [
      //       {name: 'sdasd', priority: 1, due_date: '2019-01-01 12:23:44'},
      //       {name: 'sdasd', priority: 1, due_date: '2019-01-01 12:23:44'},
      //       {name: 'sdasd', priority: 1, due_date: '2019-01-01 12:23:44'}
      //       ]}
      // ]
    }
  },
  methods: {
    ...mapActions(['axiosGetCheckListDetail']),
    async loadNewCheckList() {
      this.checkListLocal = []
      let array = []
      for(let i=0; i<this.checkList.length; i++) {
        let form = {};
        if(this.isDetailTask) {
          form['task_id'] = this.checkList[i]
        } else {
          form['checklist_ids'] = this.checkList[i].id
        }
        let item = await this.axiosGetCheckListDetail(form)
        if(Array.isArray(item.data) === true && item.data.length !== 0) {
          if(this.isDetailTask) {
            array = item.data
          } else{
            array.push(item.data[0])
          }
        }
      }
      this.checkListLocal = array
    },
    changeChecklist(change) {
      console.log('change')
      let index = this.checkListLocal.findIndex(a=> a.id === change.id)
      if(index>=0) {
        this.checkListLocal[index] = change
      }
      this.$emit('changeCheclList', this.checkListLocal)
    },
  },
  mounted() {
    if(this.isDetailTask) {
      this.loadNewCheckList()
    }
  }
}
</script>

<style scoped lang="scss">

</style>