
<template>
  <section>
<!--    {{valueRequest}}-->
    <div class="content">
      <div class="wrap paths">
        <bread 
          class="p-x-0"
          :allowOptions="false" 
          :allowBorder="false" 
          :links="[
            {name: 'Employees', link: '/employees'},
            {name: nameProfile, link: '/employee-card/' + this.$route.params.id},
            {name: `Add employee`, link: ''},
          ]"
        />
        <!-- <div class="part1">
          <router-link to="/employees" class="fz12 text-gray">Employees</router-link>
          <router-link :to="'/employee-card/' + $route.params.id" class="fz12 text-gray">Edit Employee</router-link>
          <p class="fz12 text-gray">Permissions for an employee</p>
        </div>
        <div class="part2">
        </div> -->
      </div>
      <div class="wrap name-page">
        <div class="part1">
          <p class="fz20 text-dark fw600">Set up permissions for <br> an employee</p>
        </div>
        <div class="part2">
          <div class="name-step">
            <p class="text-gray fz12">Step 2 of 2 </p>
          </div>
          <div class="line">
            <div class="line-bg">
            </div>
          </div>
        </div>
      </div>
      <div class="wrap main">
        <div class="part1">
          <button class="blue-button-text fz13" v-on:click="showMenuAddEmployees">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z" fill="#094172"/>
            </svg>
            Add new department
          </button>
          <button class="blue-button-text fz13" v-on:click="showEditDepartment">
            <svg class="svg-relativ" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#094172"/>
            </svg>
            Edit departments
          </button>
        </div>
        <div class="part2">
          <div class="input-block">
            <p class="fz12 text-gray" v-on:click="renderDepartments">Department</p>
            <departments :key="KeyRenderDepartments" ref="dep" @changeDepartments="changeDepartments" @addCountRequest="valueRequest++" @decCountRequest="valueRequest--"></departments>
            <p class="fz12 text-gray mt24">Permissions</p>
            <!--            {{selected}}-->
            <!--            {{getResponseRegistration}}-->
            <!--            {{getPermissionsDepartmentsData}}-->
            <div class="checkbox-block" :key="keyRender">
              <!--              <checkboxes :checkboxes="tasksRights"></checkboxes>-->
              <!--              <checkboxes :checkboxes="accessRights"></checkboxes>-->
              <!--              <checkboxes :checkboxes="editRights"></checkboxes>-->
              <checkboxes @showModalColumns="showModalColumns($event, i)" @openModalAccount="openModalAccount" @renderCheckbox="renderCheckbox" @changeSelected="changeSelected" ref="checkboxes" v-for="(perm, i) in permissions" :key="i" :checkboxes="perm" :selected-props="activeCheckbox"></checkboxes>
              <!--{{getPermissions}}-->
<!--              <pre>-->
<!--              </pre>-->
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-panel">
        <div class="wrap">
          <div class="part1">
            <router-link to="/employees" class="blue-button-text fz14">Cancel</router-link>
          </div>
          <div class="part2">
            <div class="part2">
              <button class="blue-button fz14" v-on:click="nextClick">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-new-department @showModalColumns="showModalColumnsData" @openModalAccount="openModalAccount" ref="addDepartment" @addOk="renderDepartments"></add-new-department>
    <edit-department @showModalColumns="showModalColumnsData" @openModalAccount="openModalAccount" ref="editDepartment" @editDepartment="renderAfterEditDepartments" @removeDepartment="renderDepartments"></edit-department>
    <modal-select-accounts ref="modalAccount"></modal-select-accounts>
    <modal-select-column ref="modalColumn"></modal-select-column>
  </section>

</template>

<script>
import Departments from "@/components/addEmployeesStep2/departments";
import Checkboxes from "@/components/addEmployeesStep2/checkboxes";
import AddNewDepartment from "@/components/addEmployeesStep2/addNewDepartment";
import {mapGetters, mapActions, mapMutations} from 'vuex';
import EditDepartment from "@/components/addEmployeesStep2/editDepartment";
import ModalSelectAccounts from "@/components/addEmployeesStep2/modal-select-accounts";
import bread from '@/components/reuse/bread';
import ModalSelectColumn from "@/components/addEmployeesStep2/modal-select-column";
export default {
  name: "changeRights",
  computed: mapGetters(["getPaymentsAccount", "getPermissionFromThisUser", "getPermissionsForUser","getIdPermissionRelated", "getInfoForUser", "getPermissions", "getDataForAxiosRegistration", "getResponseRegistration", "getPermissionsDepartmentsData"]),
  components: {ModalSelectColumn, ModalSelectAccounts, EditDepartment, AddNewDepartment, Checkboxes, Departments, bread},
  data() {
    return {
      nameProfile: " ",
      valueRequest: 0,
      keyRender: -100,
      permissions: [],
      activeCheckbox: null,
      selected: [],
      KeyRenderDepartments: -102,
      tasksRights: {
        title: "Tasks (all active)",
        details: [
          {name: "Assign tasks for ", button1Show: true, value: "1"},
          {name: "Automatically receive tasks from the system", value: "2"},
          {name: "Change task statuses for ", value: "3", button2Show: true,},
        ]
      },
      accessRights: {
        title: "Access to the Database (all active)",
        details: [
          {name: "Access to the Client Database"},
          {name: "Access to the Ship Database"},
          {name: "Access to the Port Database"},
        ]
      },
      editRights: {
        title: "Edit Database (all active)",
        details: [
          {name: "Edit Client Database"},
          {name: "Edit Ship Database"},
          {name: "Edit Port Database", notEnabled: true},
        ]
      }
    }
  },
  watch: {
    valueRequest: function () {
      if(this.valueRequest !== 0) {
        this.showLoad()
      } else {
        this.hideLoad()
      }
    },
  },
  methods: {
    ...mapActions(["axiosGetEmployeeById", "axiosGetPermissionsForUserLogin", "axiosGetPaymentAccount", "axiosGetPermissionForThisUser", "axiosChangeDepartmentForUser", "axiosAddRelatedPermissionForUser", "axiosDeletePermissionRelated", "getAxiosIdPermissionRelated", "getAxiosInfoForUser", "axiosGetPermissions", "axiosRelatedPermissions", "axiosRegistration", "axiosGetPermissionsDepartments"]),
    ...mapMutations(["showLoad", "hideLoad", "setModalCreateUser"]),
    showModalColumns(data, indexOne) {
      this.$refs.modalColumn.openModal({id: data.id, title2: indexOne, indexTwo: data.index, active: data.active, textModalOpen: ''})
    },
    openModalAccount(data) {
      this.$refs.modalAccount.openModal(data);
    },
    showModalColumnsData(data) {
      this.$refs.modalColumn.openModal(data)
    },
    renderDepartments() {
      this.renderNew();
      // console.log("axiosEditPermissionInDepartments111");
      // console.log("RenderDepartdefdfsdfsf4353444444444444444444444444444444444444444444444444444444444");
      // this.KeyRenderDepartments += 1;
      // this.keyRender +=1;
    },
    renderAfterEditDepartments() {
      // this.KeyRenderDepartments += 1;
      // this.axiosGetPermissions().then(()=> {
      //   this.permissions.splice(0,this.permissions.length);
      //   this.permissions = [];
      //   for(let i=0; i< this.getPermissions.length; i++) {
      //     this.permissions.push(this.getPermissions[i]);
      //   }
      //   this.keyRender +=1;
      // });
      setTimeout(()=>this.renderNew(), 1000)

    },
    showEditDepartment() {
      this.$refs.editDepartment.showMenu();
    },
    changeSelected(data) {
      // // console.log(data);
      // // console.log(this.selected)
      // if(data.selected.length !== 0) {
      if(this.selected.length !== 0) {
        let coincidences = 0;
        for(let i=0; i<this.selected.length; i++) {
          if(this.selected[i].title === data.title) {
            coincidences+=1;
            // this.selected[i].selected.slice(0,this.selected[i].selected.length);
            this.selected[i].selected = [];
            // // console.log(2);
            this.selected[i].selected = data.selected;
            // // console.log(3);
          }
        }
        // // console.log(coincidences)
        if(coincidences === 0) {
          this.selected.push(data);
        }
      } else {
        this.selected.push(data);
      }
      // } else {
      //   // console.log("a");
      //   for(let i=0; i<this.selected.length; i++) {
      //     if(this.selected[i].title === data.title) {
      //       this.selected[i].selected = [];
      //     }
      //   }
      // }
    },
    renderCheckbox() {
      this.keyRender++;
    },
    changeDepartments(activeCheckbox) {
      if(this.getInfoForUser.department === activeCheckbox.id) {
        let permissionsForUser = this.getPermissionFromThisUser.map(a=> {
          return a.type;
        });
        console.log("activeCheckbox");
        this.activeCheckbox = activeCheckbox;
        this.activeCheckbox.permissions = permissionsForUser;
        this.activeCheckbox.permissionsUser = true;
        console.log(activeCheckbox);

      } else {
        this.activeCheckbox = activeCheckbox;
      }

      console.log("sduhfsadihfidhsfiosdhyfdsyhhsdufhysidufhisdughfidhsufu")
      // console.log(this.getPermissionFromThisUser);
      console.log(this.activeCheckbox);
      // this.keyRender++;
    },
    nextClick() {
      // console.log(this.getDataForAxiosRegistration);
      let permissions = [];
      for(let i=0; i<this.selected.length; i++) {
        for(let j=0; j<this.selected[i].selected.length; j++) {
          let formAxios = {
            type: 0,
            user: this.getInfoForUser.id,
            // user: 15,
            related_staff: [],
            related_department: [],
            related_account: [],
            such_columns: [],
          };
          // console.log("formAxios");
          formAxios.type = this.selected[i].selected[j].id;
          formAxios.such_columns = this.selected[i].selected[j].activeCheckboxInMenu.columns;
          if(this.selected[i].selected[j].activeCheckboxInMenu.employees.length !== 0) {
            for(let r=0; r<this.selected[i].selected[j].activeCheckboxInMenu.employees.length; r++) {
              formAxios.related_staff.push(this.selected[i].selected[j].activeCheckboxInMenu.employees[r].id);
            }
          }
          if(this.selected[i].selected[j].activeCheckboxInMenu.departments.length !== 0) {
            for(let r=0; r<this.selected[i].selected[j].activeCheckboxInMenu.departments.length; r++) {
              formAxios.related_department.push(this.selected[i].selected[j].activeCheckboxInMenu.departments[r].id);
              console.log(1);
              console.log(this.selected[i].selected[j].activeCheckboxInMenu.departments[r])
            }
          }
            console.log(this.selected[i])
          if(this.selected[i].selected[j].button2Show === true) {
            if(Array.isArray(this.selected[i].selected[j].activeCheckboxInMenu.account)) {
              formAxios.related_account = this.selected[i].selected[j].activeCheckboxInMenu.account.map(a=> {
                return a.id
              })
            }
          }
          // console.log(formAxios);
          // this.axiosRelatedPermissions(formAxios);
          permissions.push(formAxios)
        }
      }
      this.axiosChangeDepartmentForUser({id: this.getInfoForUser.id,department: this.activeCheckbox.id, email: this.getInfoForUser.email, phone_number: this.getInfoForUser.phone_number, first_name: this.getInfoForUser.first_name, last_name: this.getInfoForUser.last_name})
      this.showLoad()
      this.axiosAddRelatedPermissionForUser({user: this.getInfoForUser.id, permissions: permissions}).then(()=> {
        this.axiosGetPermissionsForUserLogin().then(()=>{
          this.hideLoad()
          this.$router.push({name:'Employee card'})

        });
      });
      // this.$router.push("/employees");
    },
    showMenuAddEmployees() {
      // console.log("met1");
      this.$refs.addDepartment.showMenu();
    },
    renderNew() {
      this.$refs.dep.renderNew()

      this.permissions = [];
      let textRoute = this.$route.path;
      let idTask1 = textRoute.replace(/[^0-9]/gim,'');
      this.setModalCreateUser({status: false, name: ""});
      console.log("1+")
      this.valueRequest++;
      this.axiosGetPermissionForThisUser(idTask1).then(()=> {
        console.log("2+")
        this.valueRequest++;
        this.axiosGetPermissions().then(()=> {
          console.log("3+")
          this.valueRequest++;
          this.axiosGetPermissionsDepartments().then(()=> {
            console.log("1-")
            this.valueRequest--;
          })
          if(this.getPaymentsAccount.length === 0) {
            console.log("4+")
            this.valueRequest++;
            this.axiosGetPaymentAccount().then(()=> {
              console.log("2-")
              this.valueRequest--;
              for(let i=0; i< this.getPermissions.length; i++) {
                this.permissions.push(this.getPermissions[i]);
              }
              let textRoute = this.$route.path;
              let idTask = textRoute.replace(/[^0-9]/gim,'');
              console.log("5+")
              this.valueRequest++;
              this.getAxiosInfoForUser(idTask).then(()=> {
                console.log("3-")
                this.valueRequest--;
                this.$refs.dep.setIdDepartmentUser(this.getInfoForUser.department);
                this.keyRender++;
              });
            })
          } else {
            for(let i=0; i< this.getPermissions.length; i++) {
              this.permissions.push(this.getPermissions[i]);
            }
            let textRoute = this.$route.path;
            let idTask = textRoute.replace(/[^0-9]/gim,'');
            console.log("6+")
            this.valueRequest++;
            this.getAxiosInfoForUser(idTask).then(()=> {
              console.log("4-")
              this.valueRequest--;
              this.$refs.dep.setIdDepartmentUser(this.getInfoForUser.department);
              this.keyRender++;
            });
          }
          console.log("5-")
          this.valueRequest--;
        });
        console.log("6-")
        this.valueRequest--;
      })
    }
  },
  async mounted() {
    if(this.nameProfile) {
      let employe = await this.axiosGetEmployeeById(this.$route.params.id)
      this.nameProfile = employe.first_name + ' ' + employe.last_name
    }
    // this.axiosGetPermissionsForUserLogin();
    this.renderNew()
  }
}
</script>

<style scoped lang="scss">
@import "changeRights.scss";
</style>