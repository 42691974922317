<template>
  <div class="bank-aside_controls">
    <button @click="$emit('add')" v-if="getPermissionsByType('create_new_bank_and_cash_accounts')" class="button-blue-border fw500 fz13">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>
        Create new bank or cash account
    </button>

    <router-link v-if="getPermissionsByType('show_archive_bank_and_cash_accounts')" to="/accounts/bank-and-cash/archive" class="archive-link svg-white-dart-stroke text-dark fw500 fz13">
      <svg ref="archiveLink" width="24" height="24" viewBox="0 0 24 24" stroke="#2C2C2C" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </router-link>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  mounted() {
    if(this.$refs.archiveLink){
      tippy(this.$refs.archiveLink, {
        arrow: false,
        content: 'Banks & Cash Archive'
      })
    }
  }
}
</script>

<style lang="scss">
  .bank-aside_controls{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
  }
  .archive-link{
    font-size: 0;
    &:hover{
      svg{
        stroke: #094172;
        background-color: var(--color-input);
      }
    }
    svg{
      border-radius: 4px;
      transition: background-color 0.3s, stroke 0.3s;
    }
  }
</style>