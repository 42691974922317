<template>
  <div class="fs_block assets">
    <div class="block__title flex justify-between align-end"><p class="fz20 text-dark fw500">Profit and Loss</p> <span class="fz13 fw500 text-gray">USD</span></div>
    <div class="fs_block__list">
      <div>
        <div class="fs_block__row head">Revenue from contracts with customers</div>
        <div>
          <div class="fs_block__row" v-if="getRows('revenue_from_contracts_with_customers').length == 0">No items in this category</div>
          <div class="fs_block__row uppercase" v-else v-for="item in getRows('revenue_from_contracts_with_customers')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>{{item.amount | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>
        </div>
      </div>

      <div>
        <div class="fs_block__row head">Operating expenses</div>
        <div>
          <div class="fs_block__row" v-if="getRows('operating_expenses').length == 0">No items in this category</div>
          <div class="fs_block__row uppercase" v-else-if="getRows('operating_expenses').length" v-for="item in getRows('operating_expenses')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>{{item.amount | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>

          <div class="fs_block__row subhead">Operating profit <span :class="{'wrap-brackets': getOperatingProfit() && getOperatingProfit() < 0}">{{getOperatingProfit() | formatNumberAbs}}</span></div>
          <div class="fs_block__row uppercase">Administrative expenses <span>{{getAmountType('administrative_expenses') | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>
<!--          <div class="fs_block__row" v-if="getSubRows('operating_profit').length == 0">No items in this category</div>-->
<!--          <div class="fs_block__row" v-else-if="getSubRows('operating_profit').length" v-for="item in getSubRows('operating_profit')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>({{item.amount | formatNumberTwo}})</span></div>-->

          <div class="fs_block__row subhead">Profit before tax <span :class="{'wrap-brackets': getProfitBeforeTax() && getProfitBeforeTax() < 0}">{{getProfitBeforeTax() | formatNumberAbs}}</span></div>
<!--          <div class="fs_block__row" v-if="getSubRows('profit_before_tax').length == 0">No items in this category</div>-->
<!--          <div class="fs_block__row" v-else-if="getSubRows('profit_before_tax').length" v-for="item in getSubRows('profit_before_tax')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>({{item.amount | formatNumberTwo}})</span></div>-->
          <div class="fs_block__row uppercase">Income tax <span>{{getAmountType('income_tax') | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>

          <div class="fs_block__row subhead" v-if="period">Profit for {{getProfitText}} <span :class="{'wrap-brackets': getProfitByPeriod() && getProfitByPeriod() < 0}">{{getProfitByPeriod() | formatNumberAbs}}</span></div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  props: ['list', 'period'],
  computed: {
    ...mapGetters(['toFixed2Number']),
    getProfitText() {
      if(typeof this.period == 'object' && this.period !== 'all'){
        // let date = [moment(this.period[0]).format('DD.MM.YYYY'), moment(this.period[1]).format('DD.MM.YYYY')]
        return this.period[0] + '-' + this.period[1]
      }
      if(this.period == 'day')
        return 'today'
      if(this.period == 'week')
        return 'the week'
      if(this.period == 'month')
        return 'a month'
      if(this.period == 'year')
        return 'the year'
      else
        return 'all time'
    }
  },
  methods: {
    getAmountType(type) {
      let list = this.list.filter(item=>{
        return (item.financial_statement.type == type)
      })
      let amount = 0
      list.forEach(item=> {
        amount+=item.amount
      })
      return amount
    },
    getRows(key) {
      return this.list.filter(item=>{
        return item.financial_statement.type == key
      })
    },
    getSubRows(key) {
      return this.list.filter(item=>{
        return (item.financial_statement.type == 'operating_expenses' && item.financial_statement.subtype == key)
      })
    },
    getOperatingProfit() {
      let revenueSumm = this.getRows('revenue_from_contracts_with_customers')
      if(revenueSumm.length){
        revenueSumm = revenueSumm.reduce(( previousValue, currentValue ) => previousValue + currentValue.amount, 0)
      }else revenueSumm = 0

      let operatingSumm = this.getRows('operating_expenses')
      if(operatingSumm.length){
        operatingSumm = operatingSumm.reduce(( previousValue, currentValue ) => previousValue + currentValue.amount, 0)
      }else operatingSumm = 0

      return revenueSumm + operatingSumm
    },
    getProfitBeforeTax() {
      // let operatingSumm = this.getSubRows('operating_profit')
      // if(operatingSumm.length){
      //   operatingSumm = operatingSumm.reduce(( previousValue, currentValue ) => previousValue + currentValue.amount, 0)
      // }else operatingSumm = 0

      // return this.getOperatingProfit() - operatingSumm
      return this.getOperatingProfit() + this.getAmountType('administrative_expenses')
    },
    getProfitByPeriod() {
      return this.getProfitBeforeTax() + this.getAmountType('income_tax')
    },
    getIncomeTax() {

    },
    getAmountTotal(keyArr) {
      let count = 0
      keyArr.forEach(key => {
        this.list.forEach(item => {
          if(item.financial_statement.type == key)
            count += item.amount
        })
      });
      return this.toFixed2Number(count)
    },
  }
}
</script>

<style lang="scss" scoped>
  .block__title {
    padding: 0 8px 14px 0;
  }
</style>