import { render, staticRenderFns } from "./folders-tasks.vue?vue&type=template&id=680ac0be&scoped=true&"
import script from "./folders-tasks.vue?vue&type=script&lang=js&"
export * from "./folders-tasks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680ac0be",
  null
  
)

export default component.exports