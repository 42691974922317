<template>
  <div class="page-gray" v-if="permissions.length && getPermissionsByType('show_archive_of_bank_accounts')">
    <bread
      v-if="banks"
      :allowOptions="false"
      :links="[
        {name: 'Banks accounts', link: '/accounts/bank-and-cash'}, 
        {name: `Archive Bank accounts in ${getName}`, link: ''}
      ]"
    />

    <requisite-archive-top v-if="banks" @search="onSearch"/>
    <requisite-archive-list :list="getRequisitesOnArchive" ref="trialList" @select="onSelect" @on-unarchive="onUnarchive" v-if="banks"/>
    <requisite-archive-bottom :list="getRequisitesOnArchive" @on-close="$refs.trialList.uncheckAll()" :selectedListId="selectedListId" @on-unarchive="onUnarchive" />

    <requisite-modal-unarchive :list="selectedListId" :account="editAccount" v-if="isModalArchiveOpen" @close="closeModal" @submit="unarchiveAccount"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import requisiteArchiveTop from '@/components/accounts/bankAndCash/archive/requisiteArchiveTop'
import requisiteArchiveList from '@/components/accounts/bankAndCash/archive/requisiteArchiveList'
import requisiteArchiveBottom from '@/components/accounts/bankAndCash/archive/requisiteArchiveBottom'
import requisiteModalUnarchive from '@/components/accounts/bankAndCash/archive/requisiteModalUnarchive'
import bread from '@/components/reuse/bread'
export default {
  components: {
    requisiteArchiveTop,
    requisiteArchiveList,
    requisiteArchiveBottom,
    requisiteModalUnarchive,
    bread
  },
  data() {
    return {
      selectedListId: [],
      editAccount: null,
      isModalArchiveOpen: false,
      searchText: ''
    }
  },
  watch: {
    permissions: function(val) {
      if(val.length && !this.getPermissionsByType('show_archive_of_bank_accounts'))
        this.$router.replace({name: 'employees'})
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.confirmation.permissionsForUser,
      banks: state => state.banks.banks,
    }),
    ...mapGetters(['getPermissionsByType']),
    getRequisitesOnArchive() {
      if(!this.banks) return []
      
      return this.banks.filter(el=>{
        return el.id == this.$route.params.id
      })[0].requisites.filter(el=>{
        if(this.searchText){
          return el.is_archive == true && 
          (el.holder.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0 || 
          el.account_number.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0 || 
          el.iban.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0 || 
          el.currency.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0)
        }else
          return el.is_archive == true
      })
    },
    getName() {
      return this.banks.filter(el=>{
        return el.id == this.$route.params.id
      })[0].name
    }
  },
  methods: {
    ...mapActions(['axiosBanksAccountsToArchive', 'axiosGetBanks']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    onUnarchive(account) {
      if(account)
        this.editAccount = account
      else
        this.editAccount = null
      this.isModalArchiveOpen = true
    },
    async unarchiveAccount(ids) {
      this.showLoad()
      await this.axiosBanksAccountsToArchive({ids: ids, bool: false})
      this.hideLoad()
      this.isModalArchiveOpen = false
      if(this.editAccount)
        this.addTip(this.editAccount.account_number + ' ' + this.editAccount.iban + ' is unarchived')
      else if(ids.length == 1)
        this.addTip(ids.length + ' account is unarchived')
      else
        this.addTip(ids.length + ' accounts are unarchived')

      this.axiosGetBanks()
      this.$refs.trialList.uncheckAll()
    },
    onSelect(list) {
      this.selectedListId = list.slice()
    },
    closeModal() {
      this.isModalArchiveOpen = false
    },
    onSearch(text) {
      this.searchText = text
    }
  },
  async mounted() {
    this.showLoad()
    if(!this.banks)
      await this.axiosGetBanks()
    this.hideLoad()
  },
}
</script>

<style>
 .page-gray{
   background-color: var(--color-bgc-page);
   height: calc(100vh - 56px);
    overflow-y: auto;
 }
</style>