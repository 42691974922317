<template>
  <div class="page_section vessel_owners__page_content bg-gray-lightest">
    <div class="flex h-full overflow-hidden">
      <div class="vessel_sidebar bg-white flex-shrink-0 border-r border-gray-light overflow-auto">
        <div 
          class="vessel_sidebar_item p-x-6 p-y-3 border-b border-gray-light fz14 fw500 cursor-pointer"
          :class="{'active': activeIndex == i}"
          v-for="(item, i) in historyList"
          :key="item.id"
          @click="selectItem(i)"
        >
          {{item.created_at | formatDate}} at {{item.created_at | formatTime}}
        </div>
      </div>

      <div class="w-full p-x-6 p-y-3 overflow-auto" v-if="activeOwners">
        <div class="vessel_owners__page_container">
          <h2 class="p-b-3 fz18 fw500 border-b border-gray-light" v-if="getCharterer">
            {{getCharterer.name}} /
            <template v-if="getCharterer.owner">{{getCharterer.owner.company_name}} / </template>
            CP Date {{getCharterer.cp_date | moment("DD.MM.YYYY")}}
            <span v-if="activeOwners" class="fw400 text-gray-text">(#{{activeOwners.id}})</span>
          </h2>
          <div class="flex justify-between p-y-4">
            <div class="text-align-right text-gray-text fz13">
              Last edit
              {{activeOwners.created_at | formatDate}}
              at {{activeOwners.created_at | formatTime}}
              by {{activeOwners.creator.first_name + ' ' + (activeOwners.creator.last_name ? activeOwners.creator.last_name : '')}}
              </div>
            <div class="text-align-right text-gray-text fz13 m-l-auto" style="width: 15.6%">Debit, {{activeOwners.currency ? activeOwners.currency : 'USD'}}</div>
            <div class="text-align-right text-gray-text fz13" style="width: 15.6%">Credit, {{activeOwners.currency ? activeOwners.currency : 'USD'}}</div>
          </div>
          <hire-table :hireData="activeOwners.charter_hire"/>
          <bunkers-on-table v-if="activeOwners.bunkers_delivery.length" :bunkersOnData="activeOwners.bunkers_delivery"/>
          <empty-bunkers-on-table v-else/>
          <balast-table v-if="activeOwners.balast_bonus" :balastData="activeOwners.balast_bonus"/>
          <deposit-table v-if="activeOwners.chrtrs" :chrtrsData="activeOwners.chrtrs"/>
          <bunkers-table v-if="activeOwners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'}).length" :bunkersData="activeOwners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'})[0]"/>
          <bunkers-supply-table v-if="activeOwners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'}).length" :bunkersData="activeOwners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'})[0]"/>
          <off-hire-table v-if="activeOwners.hire_off.length" :hireData="activeOwners.hire_off"/>
          <speed-table v-if="activeOwners.speed_claim.length" :speedData="activeOwners.speed_claim"/>
          <charterers-table v-if="activeOwners.expenses.filter(el=>{return el.expense_type == 'charterer_expense'}).length" :expensesData="activeOwners.expenses.filter(el=>{return el.expense_type == 'charterer_expense'})"/>
          <owners-table v-if="activeOwners.expenses.filter(el=>{return el.expense_type == 'owners_expense'}).length" :expensesData="activeOwners.expenses.filter(el=>{return el.expense_type == 'owners_expense'})"/>
          <remittances-table v-if="activeOwners.additional_info.is_remittances" :hireData="activeOwners.contract.payments"/>
          <div class="vessel_owners__table bg-gray-dark rounded-lg overflow-hidden m-t-2 m-b-6">
            <div class="colspan-1-4 border-b border-r uppercase p-3 fz16 fw500">Total</div>
            <div class="border-b border-r p-3 fz16 fw500 text-align-right">{{activeOwners.total_debit | formatNumber}}</div>
            <div class="border-b p-3 fz16 fw500 text-align-right">{{activeOwners.total_credit | formatNumber}}</div>
            <div class="colspan-1-4 border-r uppercase p-3 fz16 fw500">BALANCE DUE TO OWNERS</div>
            <div class="border-r p-3 fz16 fw500 text-align-right">
              {{activeOwners.balance >= 0 ? '':'('}}
              {{Math.abs(activeOwners.balance) | formatNumberZeroEmpty}}
              {{activeOwners.balance >= 0 ? '':')'}}
            </div>
            <div class=""></div>
          </div>
          <div class="m-b-6" v-if="activeOwners.comment">
            <p class="text-gray-text fz13 m-b-2">Note</p>
            <p class="fz14">
              <pre>{{activeOwners.comment}}</pre>
            </p>
          </div>
          <files-attach class="m-b-6" :isEdit="false" v-if="activeOwners.files.length" :files="activeOwners.files"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import moment from 'moment'

import hireTable from '@/components/vessels/owners/hireTable'
import bunkersOnTable from '@/components/vessels/owners/bunkersOnTable'
import emptyBunkersOnTable from '@/components/vessels/owners/emptyBunkersOnTable'
import balastTable from '@/components/vessels/owners/balastTable'
import depositTable from '@/components/vessels/owners/depositTable'
import bunkersTable from '@/components/vessels/owners/bunkersTable'
import bunkersSupplyTable from '@/components/vessels/owners/bunkersSupplyTable'
import offHireTable from '@/components/vessels/owners/offHireTable'
import speedTable from '@/components/vessels/owners/speedTable'
import charterersTable from '@/components/vessels/owners/charterersTable'
import ownersTable from '@/components/vessels/owners/ownersTable'
import remittancesTable from '@/components/vessels/owners/remittancesTable'
import filesAttach from '@/components/vessels/owners/filesAttach'

export default {
  components: {
    hireTable,
    bunkersOnTable,
    emptyBunkersOnTable,
    balastTable,
    depositTable,
    bunkersTable,
    bunkersSupplyTable,
    offHireTable,
    speedTable,
    charterersTable,
    ownersTable,
    remittancesTable,
    filesAttach
  },
  data() {
    return {
      activeOwners: null,
      activeIndex: 0
    }
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract,
      historyList: state => state.owners.ownersHistoryList
    }),
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
  },
  methods: {
    ...mapActions(['axiosGetOwnersHistory', 'axiosGetClientsById']),
    ...mapMutations(['changeContractBread', 'showLoad', 'hideLoad']),
    selectItem(i) {
      this.activeOwners = this.historyList[i]
      this.activeIndex = i
    }
  },
  async beforeMount() {
    this.showLoad()

    if(!this.historyList)
      await this.axiosGetOwnersHistory(this.$route.params.subId)

    this.hideLoad()

    this.activeOwners = this.historyList[0]

    if(this.getCharterer) {
      if(this.getCharterer.owner && Number.isInteger(this.getCharterer.owner))
        this.getCharterer.owner = await this.axiosGetClientsById(this.getCharterer.owner)

      this.changeContractBread({
        index: 2,
        obj: {
          name: `${this.getCharterer.name} ${moment(this.getCharterer.cp_date).format('DD.MM.YYYY')}`,
          link: `/vessels/${this.$route.params.id}/open/charterers/${this.$route.params.subId}/soa`
        }
      })
      this.changeContractBread({
        index: 3,
        obj: {
          name: 'History SOA Sublet',
          link: `/vessels/${this.$route.params.id}/charterers/${this.$route.params.subId}/soa/history`
        }
      })
    }
  },
  beforeDestroy() {
    this.changeContractBread({
      index: 3,
      obj: null
    })
    this.changeContractBread({
      index: 2,
      obj: null
    })
  }
}
</script>

<style lang="scss">
</style>