var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-t-4 border border-dark-gray rounded-lg"},[_c('div',{staticClass:"vessel_owners__table_wide bg-blue-dark rounded-t-lg",class:{
      'without_trials': !_vm.getPermissionsByType('show_accounts_in_all_operations')
    }},[_c('div',{staticClass:"border-r p-x-2 p-y-2 text-white fz13 fw500"},[_vm._v("REMITTANCES DONE")]),(_vm.getPermissionsByType('show_accounts_in_all_operations'))?_c('div',{staticClass:"border-r"},[(_vm.$route.params.subId)?_c('get-trials',{attrs:{"isWhite":true,"trialKey":"SUBLET_Remittances Done"}}):_c('get-trials',{attrs:{"isWhite":true,"trialKey":"Remittances Done"}})],1):_vm._e(),_c('div',{staticClass:"colspan-3-9"})]),(!_vm.additional.remittances.length)?_c('div',{staticClass:"vessel_owners__table_wide bg-white rounded-b-lg",class:{
      'without_trials': !_vm.getPermissionsByType('show_accounts_in_all_operations')
    }},[_c('div',{staticClass:"border-r p-x-2 p-y-2 text-dark"}),(_vm.getPermissionsByType('show_accounts_in_all_operations'))?_c('div',{staticClass:"border-r p-x-2 p-y-2 text-dark"}):_vm._e(),_c('div',{staticClass:"border-r p-x-2 p-y-2 text-dark colspan-3-5"},[_vm._v("The list of remittances done is empty")]),_c('div',{staticClass:"border-r p-x-2 p-y-2 text-dark"}),_c('div',{staticClass:"border-r p-x-2 p-y-2 text-dark"}),_c('div',{staticClass:"border-r p-x-2 p-y-2 text-dark"}),_c('div',{})]):_vm._e(),_vm._l((_vm.sortedByDate),function(hire,i){return _c('div',{key:i,staticClass:"vessel_owners__table_wide bg-white",class:{
      'without_trials': !_vm.getPermissionsByType('show_accounts_in_all_operations'),
      'rounded-b-lg': i == _vm.sortedByDate.length-1
    }},[_c('div',{staticClass:"border-r p-x-2 p-y-2 relative",class:{
        'border-b': i != _vm.additional.remittances.length - 1,
        'rounded-bl-lg': i == _vm.additional.remittances.length - 1,
        'bg-gray-light text-gray-text': !hire.is_row
      }},[_vm._v(" Hire #"+_vm._s(i+1)+" "),_c('switcher',{staticClass:"switcher_owners-row",attrs:{"id":'hire'+i},model:{value:(hire.is_row),callback:function ($$v) {_vm.$set(hire, "is_row", $$v)},expression:"hire.is_row"}})],1),(_vm.getPermissionsByType('show_accounts_in_all_operations'))?_c('div',{staticClass:"border-r p-x-2 p-y-2",class:{
        'border-b': i != _vm.additional.remittances.length - 1,
        'bg-gray-light text-gray-text': !hire.is_row
      }}):_vm._e(),_c('div',{staticClass:"border-r p-x-2 p-y-2 colspan-3-5",class:{
        'border-b': i != _vm.additional.remittances.length - 1,
        'bg-gray-light text-gray-text': !hire.is_row
      }},[_vm._v(_vm._s(hire.date))]),_c('div',{staticClass:"border-r",class:{
        'border-b': i != _vm.additional.remittances.length - 1,
        'bg-gray-light text-gray-text': !hire.is_row
      }},[(hire.debit != null)?_c('p',{staticClass:"p-x-2 p-y-2 text-align-right"},[_vm._v(_vm._s(_vm._f("formatNumberTwoZeroEmpty")(hire.debit)))]):_vm._e()]),_c('div',{staticClass:"border-r",class:{
        'border-b': i != _vm.additional.remittances.length - 1,
        'bg-gray-light text-gray-text': !hire.is_row
      }},[(hire.credit != null)?_c('p',{staticClass:"p-x-2 p-y-2 text-align-right"},[_vm._v(_vm._s(_vm._f("formatNumberTwoZeroEmpty")(hire.credit)))]):_vm._e()]),_c('div',{staticClass:"border-r",class:{
        'border-b': i != _vm.additional.remittances.length - 1,
        'bg-gray-light text-gray-text': !hire.is_row
      }}),_c('div',{class:{
        'border-b': i != _vm.additional.remittances.length - 1,
        'rounded-br-lg': i == _vm.additional.remittances.length - 1,
        'bg-gray-light text-gray-text': !hire.is_row
      }})])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }