<template>
  <div class="border border-dark-gray m-b-4 rounded-lg overflow-hidden">
    <div class="vessel_owners__table-wrap bg-white">
      <div class="vessel_owners__table">
        <div class="first_element_stroke bg-blue-dark colspan-1-6 uppercase p-x-3 p-y-2 fz13 fw500 text-white">Bunkers on Delivery</div>
      </div>

      <div class="vessel_owners__table">
        <div class="first_element_stroke bg-white-dark border-b border-r"></div>
        <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-3 p-y-1">Qntty, mt</div>
        <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-3 p-y-1">Price, USD/mt</div>
        <div class="bg-white-dark border-b border-r"></div>
        <div class="bg-white-dark border-b"></div>
      </div>

      <div class="vessel_owners__table" v-for="(type, i) in getDelivery" :key="i">
        <div 
          :key="type.fuel_type + '1'" 
          class="first_element_stroke border-b border-r p-x-3 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2'}"
        >
          {{type.additional_info.name ? type.additional_info.name : type.fuel_type}}
        </div>
        <div 
          :key="type.fuel_type + '2'" 
          class="border-b border-r p-x-3 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2'}"
        ></div>
        <div 
          :key="type.fuel_type + '3'" 
          class="border-b border-r p-x-3 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2'}"
        ></div>
        <div 
          :key="type.fuel_type + '4'" 
          class="border-b border-r"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2'}"
        ></div>
        <div 
          :key="type.fuel_type + '5'" 
          class="border-b p-x-3 p-y-2 text-align-right"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2'}"
        ></div>
      </div>

      <div class="vessel_owners__table">
        <div class="first_element_stroke bg-blue-dark colspan-1-6 uppercase p-x-3 p-y-2 fz13 fw500 text-white">Bunkers on Redelivery</div>
      </div>

      <div class="vessel_owners__table">
        <div class="first_element_stroke bg-white-dark border-b border-r"></div>
        <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-3 p-y-1">Qntty, mt</div>
        <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-3 p-y-1">Price, USD/mt</div>
        <div class="bg-white-dark border-b border-r"></div>
        <div class="bg-white-dark border-b"></div>
      </div>

      <div class="vessel_owners__table" v-for="(type, i) in getRedelivery" :key="'sd'+i">
        <div 
          :key="type.fuel_type + '1re'" 
          class="first_element_stroke border-r p-x-3 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2', 'border-b': i<getRedelivery.length-1}"
        >
          {{type.additional_info.name ? type.additional_info.name : type.fuel_type}}
        </div>
        <div 
          :key="type.fuel_type + '2re'" 
          class="border-r p-x-3 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2', 'border-b': i<getRedelivery.length-1}"
        ></div>
        <div 
          :key="type.fuel_type + '3re'" 
          class="border-r p-x-3 p-y-2 text-dark"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2', 'border-b': i<getRedelivery.length-1}"
        ></div>
        <div 
          :key="type.fuel_type + '4re'" 
          class="border-r"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2', 'border-b': i<getRedelivery.length-1}"
        ></div>
        <div 
          :key="type.fuel_type + '5re'" 
          class=" p-x-3 p-y-2 text-align-right"
          :class="{'bg-gray-lightest': type.fuel_type == 'VLSFO_2' || type.fuel_type == 'LSMGO_2', 'border-b': i<getRedelivery.length-1}"
        ></div>
      </div>

<!--      <div class="vessel_owners__table">-->
<!--        <div class="first_element_stroke border-r uppercase p-x-3 p-y-2 fz13 fw500 text-dark">C/E/V</div>-->
<!--        <div class="border-r"></div>-->
<!--        <div class="border-r p-x-3 p-y-2 relative">-->
<!--          <span class="absolute top-2 right-3 text-gray-text fz13">Rate, USD/PMPR</span>-->
<!--        </div>-->
<!--        <div class="border-r"></div>-->
<!--        <div class="p-x-3 p-y-2 text-align-right"></div>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bunkersOnData: [
        {
          bunkers_type: 'DELIVERY',
          fuel_type: 'VLSFO_2',
          debit: 0,
          credit: 0,
          additional_info: {
            name: 'VLSFO',
            price: '',
            quantity: ''
          }
        },
        {
          bunkers_type: 'DELIVERY',
          fuel_type: 'LSMGO_2',
          debit: 0,
          credit: 0,
          additional_info: {
            name: 'LSMGO',
            price: '',
            quantity: '',
            checkboxes: [],
            transaction_type: 'Expenses'
          }
        },
        {
          bunkers_type: 'REDELIVERY',
          fuel_type: 'VLSFO_2',
          debit: 0,
          credit: 0,
          additional_info: {
            price: '',
            quantity: '',
            name: 'VLSFO'
          }
        },
        {
          bunkers_type: 'REDELIVERY',
          fuel_type: 'LSMGO_2',
          debit: 0,
          credit: 0,
          additional_info: {
            price: '',
            quantity: '',
            name: 'LSMGO',
            checkboxes: [],
            transaction_type: 'Expenses'
          }
        },
        {
          bunkers_type: 'C/E/V',
          debit: 0,
          credit: 0,
          additional_info: {
            rate: '',
            select: 'rate1',
          }
        },
      ]
    }
  },
  computed: {
    getDelivery() {
      return this.bunkersOnData.filter(el => {
        return el.bunkers_type == 'DELIVERY'
      })
    },
    getRedelivery() {
      return this.bunkersOnData.filter(el => {
        return el.bunkers_type == 'REDELIVERY'
      })
    },
    getCEV() {
      return this.bunkersOnData.find(el => {
        return el.bunkers_type == 'C/E/V'
      })
    },
  }
}
</script>

<style>

</style>