<template>
  <modal-slots 
    :showMod="isShowModal"
    :noCloseAfterApply="true"
    @close="$emit('on-close')"
    @apply="addContact"
  >
    <template v-slot:title>
      <h2 class="fz18 fw500">Create new contact</h2>
    </template>

    <div class="p-x-6 p-b-5">
      <label class="block-input" :class="{ 'block-input_error': $v.contact.date.$error }">
        <p class="block-input_title text-gray">Date</p>
        <input class="block-input_content" type="text" v-model.trim="$v.contact.date.$model">
        <div class="fz10 text-red" v-if="!$v.contact.date.maxLength">The maximum number of characters in the Date is {{$v.contact.date.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.contact.vessel.$error }">
        <p class="block-input_title text-gray">Vessel</p>
        <input class="block-input_content" type="text" v-model.trim="$v.contact.vessel.$model">
        <div class="fz10 text-red" v-if="!$v.contact.vessel.maxLength">The maximum number of characters in the Vessel is {{$v.contact.vessel.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.contact.operation.$error }">
        <p class="block-input_title text-gray">Operation</p>
        <input class="block-input_content" type="text" v-model.trim="$v.contact.operation.$model">
        <div class="fz10 text-red" v-if="!$v.contact.operation.maxLength">The maximum number of characters in the Operation is {{$v.contact.operation.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.contact.dwt.$error }">
        <p class="block-input_title text-gray">DWT</p>
        <input class="block-input_content" type="text" v-model.trim="$v.contact.dwt.$model">
        <div class="fz10 text-red" v-if="!$v.contact.dwt.required && $v.contact.dwt.$dirty">Field is required</div>
        <div class="fz10 text-red" v-if="!$v.contact.dwt.maxLength">The maximum number of characters in the DWT is {{$v.contact.dwt.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input relative" :class="{ 'block-input_error': $v.contact.amount_local.$error }">
        <p class="block-input_title text-gray">Amount Local</p>
        <input class="block-input_content p-r-16" ref="amount" type="text" v-model.trim="$v.contact.amount_local.$model">
        <div class="fz10 text-red" v-if="!$v.contact.amount_local.required && $v.contact.amount_local.$dirty">Field is required</div>
        <div class="fz10 text-red" v-if="!$v.contact.amount_local.maxLength">The maximum number of characters in the Amount Local is {{$v.contact.amount_local.$params.maxLength.max}}.</div>

        <div class="absolute top-7 right-3 flex items-center fz14 cursor-pointer svg-white-dart" @click="$refs.dropCurrency.openList()">
          {{contact.currency}}
          <svg class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.72443 4.39844C2.45716 4.39844 2.32331 4.72158 2.5123 4.91057L5.78804 8.18631C5.9052 8.30346 6.09515 8.30346 6.21231 8.18631L9.48803 4.91057C9.67702 4.72158 9.54317 4.39844 9.2759 4.39844H2.72443Z" fill="#2C2C2C"/></svg>
        </div>

        <counterparties-drop
          class="hide_input vessel_owners__select_currency"
          ref="dropCurrency"
          :list="valutaList"
          :removeSelectItemBeforeClick="false"
          :activeItem="activeCurrency"
          @select="(item)=>contact.currency = item.name"
          title="Select currency"
        ></counterparties-drop>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.contact.source.$error }">
        <p class="block-input_title text-gray">Source</p>
        <textarea 
          class="block-input_content" 
          :class="{
            'border-red': $v.contact.source.$error
          }"
          v-model.trim="$v.contact.source.$model" 
          rows="5"
        ></textarea>
        <div class="fz10 text-red" v-if="!$v.contact.source.required && $v.contact.source.$dirty">Field is required</div>
        <div class="fz10 text-red" v-if="!$v.contact.source.maxLength">The maximum number of characters in the Source is {{$v.contact.source.$params.maxLength.max}}.</div>
      </label>

      <label class="block-input" :class="{ 'block-input_error': $v.contact.comment.$error }">
        <p class="block-input_title text-gray">Comments</p>
        <textarea class="block-input_content" v-model.trim="$v.contact.comment.$model" rows="2"></textarea>
        <div class="fz10 text-red" v-if="!$v.contact.comment.maxLength">The maximum number of characters in the Comments is {{$v.contact.comment.$params.maxLength.max}}.</div>
      </label>

      <files class="m-t-3" ref="dropzone"/>
    </div>

  </modal-slots>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { requiredIf, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import Inputmask from 'inputmask'

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import modalSlots from '@/components/modal/modal-slots'
import files from '@/components/ports/list/files'


export default {
  mixins: [validationMixin],
  components: {
    counterpartiesDrop,
    modalSlots,
    files
  },
  props: {
    isShowModal: Boolean,
  },
  data() {
    return {
      activeCurrency: {name:'USD', id:'USD'},
      contact: {
        date: '',
        vessel: '',
        operation: '',
        dwt: '',
        amount_local: '',
        source: '',
        comment: '',
        currency: 'USD',
        files: null,
        port: this.$route.params.id,
        is_archive: false
      }
    }
  },
  validations: {
    contact: {
      date: {maxLength: maxLength(2000)},
      vessel: {maxLength: maxLength(2000)},
      operation:{maxLength: maxLength(2000)},
      dwt: {
        required: requiredIf(function (nestedModel) {
          if(nestedModel.amount_local || nestedModel.source)
            return false
          else
            return true
        }),
        maxLength: maxLength(2000)
      },
      amount_local: {
        required: requiredIf(function (nestedModel) {
          if(nestedModel.dwt || nestedModel.source)
            return false
          else
            return true
        }),
        maxLength: maxLength(2000)
      },
      source: {
        required: requiredIf(function (nestedModel) {
          if(nestedModel.dwt || nestedModel.amount_local)
            return false
          else
            return true
        }),
        maxLength: maxLength(2000)
      },
      comment: {maxLength: maxLength(2000)},
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      valutaList: state => state.payment.valutaList
    })
  },
  methods: {
    ...mapActions(['axiosAddContact']),
    async addContact() {
      this.$v.$touch() //validation
      if (this.$v.$invalid) return false //validation

      this.contact.files = this.$refs.dropzone.getFiles()

      let newContact = await this.axiosAddContact(this.contact)
      this.$emit('on-add', newContact)
      this.$emit('on-close')
    },
    addMask() {
      if (this.$refs.amount && !this.$refs.amount.inputmask)
        Inputmask({ 
          mask: '9{1,*}[Z9{4}]',
          definitions: {
            "Z": {
              validator: ".",
            }
          },
          autoUnmask: true,
          insertMode: false,
          insertModeVisual: false
        }).mask(this.$refs.amount)
    }
  },
  mounted() {
    this.addMask()
  }
}
</script>

<style>

</style>