<template>
  <div class="calc_vessels__dot_row bg-gray-dark border-b border-gray-light rounded-tl-lg rounded-tr-lg fz13 text-gray-text">
    <div class=""></div>
    <div class="fz0 justify-center relative">
      <svg @click="$emit('open-fast-calc')" class="cursor-pointer" width="24" height="24" :fill="vessel ? '#319FEF':'#9AA3B0'" xmlns="http://www.w3.org/2000/svg"><path d="M11.541 4.292c-3.285 0-5.958 2.663-5.958 5.935 0 1.295.41 2.526 1.189 3.558l4.402 5.906c.011.015.028.02.04.033.22.234.538.175.694-.033 1.268-1.685 3.592-4.832 4.485-6.019v-.001l.006-.007a5.877 5.877 0 0 0 1.1-3.437c0-3.272-2.672-5.935-5.958-5.935Zm0 9.137c-1.769 0-3.214-1.44-3.214-3.202s1.445-3.202 3.214-3.202c1.77 0 3.215 1.44 3.215 3.202S13.31 13.43 11.54 13.43Z" /></svg>
    </div>
    <div class="p-x-1" v-tooltip="'Intake, mt'">
      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">Intake, mt</div>
    </div>
    <div class="p-x-1" v-tooltip="'Port / Canal'">
      <div class="overflow-ellipsis overflow-hidden whitespace-nowrap">Port / Canal</div>
    </div>
    <div class="p-x-1" v-tooltip="'Status'">Status</div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Dist., m'">Dist., m</div></div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'SECA, m'">SECA, m</div></div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Draft restr.'">Draft restr.</div></div>
    <div class="p-l-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Disbursment, $'">Disbursment, $</div></div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Days Steaming'">Days Steaming,d</div></div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Port Stay,d'">Port Stay,d</div></div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Port Stay,d'">layExD,d</div></div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Speed'">Speed</div></div>
    <div class="justify-center">F</div>
    <div class="justify-center">E</div>
    <div class="justify-center">S</div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'IFO, mt'">IFO, mt</div></div>
    <div class="p-x-1"><div class="overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'MGO, mt'">MGO, mt</div></div>
    <div class="colspan-19-21 p-x-1" v-tooltip="'Arrival'">Arrival</div>
    <div class="colspan-21-23 p-x-1" v-tooltip="'Departure'">Departure</div>
    <div class=""></div>
  </div>
</template>

<script>
export default {
  props: {
    vessel: Object
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
.colspan-12-14 {
  grid-column-start: 12;
  grid-column-end: 14;
}
.colspan-19-21 {
  grid-column-start: 19;
  grid-column-end: 21;
}
.colspan-21-23 {
  grid-column-start: 21;
  grid-column-end: 23;
}
</style>