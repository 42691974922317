<template>
  <div class="svg-container">
    <svg>
      <symbol id="incoming_icn" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.74203 4.0744C4.23597 4.0744 3.77613 4.37426 3.5642 4.84246L1.78962 8.76282H4.59864C4.85674 8.76282 5.0938 8.90785 5.21511 9.13996L5.6497 9.97151C5.87498 10.4026 6.31524 10.6719 6.79457 10.6719H9.2433C9.74059 10.6719 10.1941 10.3822 10.4108 9.9262L10.7727 9.16436C10.8894 8.9188 11.1336 8.76282 11.4014 8.76282H14.2548L12.6735 4.8885C12.4724 4.39558 11.9999 4.0744 11.4759 4.0744H10.9932C10.6075 4.0744 10.2948 3.75582 10.2948 3.36283C10.2948 2.96984 10.6075 2.65126 10.9932 2.65126H11.4759C12.5641 2.65126 13.5455 3.31834 13.9633 4.34208L15.9465 9.20118C15.9818 9.28777 16 9.38062 16 9.47439V13.2554C16 14.7712 14.7939 16 13.3061 16H2.69388C1.20609 16 0 14.7712 0 13.2554V9.47439C0 9.3715 0.0219023 9.26983 0.0641968 9.17639L2.29576 4.24646C2.73593 3.27405 3.69099 2.65126 4.74203 2.65126H5.27891C5.66463 2.65126 5.97732 2.96984 5.97732 3.36283C5.97732 3.75582 5.66463 4.0744 5.27891 4.0744H4.74203Z" fill="#9AA3B0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.57381 9.02019C7.84078 9.26937 8.25038 9.26937 8.51735 9.02019L10.8462 6.8244C11.1306 6.55894 11.1499 6.10883 10.8894 5.81906C10.6288 5.52928 10.187 5.50957 9.90261 5.77503L8.744 6.87863V0.711569C8.744 0.31858 8.43131 0 8.04558 0C7.65986 0 7.34717 0.31858 7.34717 0.711569V6.87863L6.18856 5.77503C5.90414 5.50957 5.46235 5.52928 5.2018 5.81906C4.94125 6.10883 4.96059 6.55894 5.24501 6.8244L7.57381 9.02019Z" fill="#9AA3B0"/>
      </symbol>
      <symbol id="sent_icn" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_10132_14469)">
        <path d="M4.77901 9.09161L10.5408 5.7993L7.24849 11.5611C7.19179 11.6603 7.16385 11.7734 7.16778 11.8876C7.17171 12.0018 7.20737 12.1126 7.27076 12.2077L9.84307 16.0662C9.90697 16.162 9.99649 16.238 10.1014 16.2855C10.2064 16.333 10.3226 16.3501 10.4368 16.3348C10.5509 16.3195 10.6585 16.2725 10.7473 16.199C10.8361 16.1256 10.9024 16.0288 10.9389 15.9195L15.8085 1.31007C15.8446 1.20164 15.8499 1.08528 15.8236 0.97404C15.7974 0.862797 15.7407 0.761064 15.6599 0.680242C15.579 0.599421 15.4773 0.542705 15.3661 0.516452C15.2548 0.4902 15.1385 0.495447 15.03 0.531607L0.420794 5.40133C0.311498 5.43776 0.214622 5.50414 0.141193 5.59291C0.067763 5.68169 0.0207285 5.78929 0.00544171 5.90348C-0.00984511 6.01767 0.00722971 6.13386 0.0547231 6.23882C0.102216 6.34379 0.178221 6.43331 0.274087 6.49721L4.13255 9.06951C4.22763 9.13284 4.33847 9.16845 4.45264 9.17235C4.56682 9.17625 4.67982 9.14829 4.77901 9.09161Z" fill="#9AA3B0"/>
        </g>
        <defs>
        <clipPath id="clip0_10132_14469">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
      </symbol>
      <symbol id="draft_icn" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8889 16.5H2.11111C1.49746 16.5 1 16.0224 1 15.4333V1.56667C1 0.977563 1.49746 0.5 2.11111 0.5H9L15 6.5V15.4333C15 16.0224 14.5025 16.5 13.8889 16.5ZM4.75 8.5C4.33579 8.5 4 8.83579 4 9.25C4 9.66421 4.33579 10 4.75 10H11.25C11.6642 10 12 9.66421 12 9.25C12 8.83579 11.6642 8.5 11.25 8.5H4.75ZM4.75 12C4.33579 12 4 12.3358 4 12.75C4 13.1642 4.33579 13.5 4.75 13.5H9.25C9.66421 13.5 10 13.1642 10 12.75C10 12.3358 9.66421 12 9.25 12H4.75Z" fill="#9AA3B0"/>
        <path d="M15 6.5L10.4286 6.5C9.63959 6.5 9 5.86041 9 5.07143L9 0.5L11.7143 3.26L15 6.5Z" fill="#BDCBD6"/>
      </symbol>
      <symbol id="archive_icn" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_10132_19160)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9545 2.08852V2.76399H14.8182C15.1947 2.76399 15.5 3.06975 15.5 3.44692C15.5 3.82409 15.1947 4.12984 14.8182 4.12984H13.6818V14.0059C13.6818 15.3869 12.559 16.5 11.1818 16.5H4.81818C3.441 16.5 2.31818 15.3869 2.31818 14.0059V4.12984H1.18182C0.80526 4.12984 0.5 3.82409 0.5 3.44692C0.5 3.06975 0.80526 2.76399 1.18182 2.76399H5.04545V2.08852C5.04545 1.20768 5.76125 0.5 6.63636 0.5H9.36364C10.2387 0.5 10.9545 1.20768 10.9545 2.08852ZM6.40909 2.08852C6.40909 1.96907 6.50732 1.86585 6.63636 1.86585L9.36364 1.86585C9.49268 1.86585 9.59091 1.96907 9.59091 2.08852V2.76399H6.40909V2.08852ZM7.31823 7.06965C7.31823 6.69248 7.01297 6.38672 6.63641 6.38672C6.25985 6.38672 5.95459 6.69248 5.95459 7.06965V12.0504C5.95459 12.4276 6.25985 12.7333 6.63641 12.7333C7.01297 12.7333 7.31823 12.4276 7.31823 12.0504V7.06965ZM10.0455 7.06965C10.0455 6.69248 9.74024 6.38672 9.36368 6.38672C8.98712 6.38672 8.68186 6.69248 8.68186 7.06965V12.0504C8.68186 12.4276 8.98712 12.7333 9.36368 12.7333C9.74024 12.7333 10.0455 12.4276 10.0455 12.0504V7.06965Z" fill="#9AA3B0"/>
        </g>
        <defs>
        <clipPath id="clip0_10132_19160">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
      </symbol>
      <symbol id="spam_icn" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1337 9.99852C15.6696 9.07031 15.6696 7.92672 15.1337 6.99852L12.8658 3.07031C12.3299 2.14211 11.3395 1.57031 10.2677 1.57031H5.73182C4.66002 1.57031 3.66964 2.14211 3.13374 3.07031L0.865791 6.99852C0.329893 7.92672 0.329892 9.07031 0.865791 9.99852L3.13374 13.9267C3.66964 14.8549 4.66002 15.4267 5.73182 15.4267H10.2677C11.3395 15.4267 12.3299 14.8549 12.8658 13.9267L15.1337 9.99852ZM9.05867 8.5666L10.9605 10.4685C11.2331 10.7411 11.2331 11.1836 10.9606 11.4561C10.688 11.7287 10.2455 11.7287 9.9729 11.4561L8.07105 9.55423L6.16921 11.4561C5.8966 11.7287 5.4541 11.7287 5.1815 11.4561C4.90898 11.1836 4.90898 10.7411 5.18159 10.4685L7.08343 8.5666L5.1815 6.66467C4.90898 6.39215 4.90898 5.94965 5.18159 5.67705C5.4541 5.40453 5.8966 5.40453 6.16912 5.67705L8.07105 7.57898L9.97299 5.67705C10.2455 5.40453 10.688 5.40453 10.9605 5.67705C11.2331 5.94965 11.2331 6.39215 10.9606 6.66467L9.05867 8.5666Z" fill="#9AA3B0"/>
      </symbol>
      <symbol id="folderDefault_icn" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7048 7.02274C15.4769 6.64667 15.0742 6.42188 14.6276 6.42188H4.68261C4.18964 6.42188 3.74129 6.70627 3.54006 7.14661L0.909668 12.7907C1.00897 13.204 1.38616 13.5128 1.83543 13.5128H12.4161C12.8685 13.5128 13.2819 13.251 13.484 12.8368L15.7575 8.17318C15.937 7.80414 15.9171 7.3741 15.7048 7.02274Z" fill="#9AA3B0"/>
        <path d="M3.0265 6.48193C3.22716 6.03027 3.67485 5.73837 4.16708 5.73837H13.5157V4.4807C13.5157 3.96225 13.0999 3.54038 12.5888 3.54038H7.13772C7.12973 3.54038 7.12425 3.53822 7.12219 3.53666L6.14652 2.1018C5.97372 1.84727 5.68832 1.69531 5.38339 1.69531H1.8368C1.3255 1.69531 0.909668 2.11718 0.909668 2.63563V11.1499L3.0265 6.48193Z" fill="#BDCBD6"/>
      </symbol>
      <symbol id="drag_icn" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.6 3.3C6.6 4.01797 6.01797 4.6 5.3 4.6C4.58203 4.6 4 4.01797 4 3.3C4 2.58203 4.58203 2 5.3 2C6.01797 2 6.6 2.58203 6.6 3.3Z" fill="#9AA3B0"/>
        <path d="M12.4359 3.3C12.4359 4.01797 11.8539 4.6 11.1359 4.6C10.418 4.6 9.83594 4.01797 9.83594 3.3C9.83594 2.58203 10.418 2 11.1359 2C11.8539 2 12.4359 2.58203 12.4359 3.3Z" fill="#9AA3B0"/>
        <path d="M6.60005 7.92891C6.60005 8.64688 6.01802 9.22891 5.30005 9.22891C4.58208 9.22891 4.00005 8.64688 4.00005 7.92891C4.00005 7.21094 4.58208 6.62891 5.30005 6.62891C6.01802 6.62891 6.60005 7.21094 6.60005 7.92891Z" fill="#9AA3B0"/>
        <path d="M12.436 7.92891C12.436 8.64688 11.854 9.22891 11.136 9.22891C10.418 9.22891 9.83599 8.64688 9.83599 7.92891C9.83599 7.21094 10.418 6.62891 11.136 6.62891C11.854 6.62891 12.436 7.21094 12.436 7.92891Z" fill="#9AA3B0"/>
        <path d="M6.60005 12.5617C6.60005 13.2797 6.01802 13.8617 5.30005 13.8617C4.58208 13.8617 4.00005 13.2797 4.00005 12.5617C4.00005 11.8437 4.58208 11.2617 5.30005 11.2617C6.01802 11.2617 6.60005 11.8437 6.60005 12.5617Z" fill="#9AA3B0"/>
        <path d="M12.436 12.5617C12.436 13.2797 11.854 13.8617 11.136 13.8617C10.418 13.8617 9.83599 13.2797 9.83599 12.5617C9.83599 11.8437 10.418 11.2617 11.136 11.2617C11.854 11.2617 12.436 11.8437 12.436 12.5617Z" fill="#9AA3B0"/>
      </symbol>
      <symbol id="create_folder_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2237 13.2218H7.77938V9.77734C7.77938 9.3481 7.4309 8.99961 7.00151 8.99961C6.57228 8.99961 6.22379 9.3481 6.22379 9.77734V13.2218H2.77931C2.35008 13.2218 2.00159 13.5703 2.00159 13.9995C2.00159 14.4289 2.35008 14.7774 2.77931 14.7774H6.22379V18.2217C6.22379 18.6511 6.57228 18.9996 7.00151 18.9996C7.4309 18.9996 7.77938 18.6511 7.77938 18.2217V14.7774H11.2237C11.6531 14.7774 12.0016 14.4289 12.0016 13.9995C12.0016 13.5703 11.6531 13.2218 11.2237 13.2218Z" fill="#2C2C2C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.20002 4.4C5.48205 4.4 4.90002 4.98203 4.90002 5.7V6.7C4.90002 7.0866 4.58662 7.4 4.20002 7.4C3.81342 7.4 3.50002 7.0866 3.50002 6.7V5.7C3.50002 4.20883 4.70885 3 6.20002 3H9.53937C10.5083 3 11.4029 3.51917 11.8836 4.36042L12.7319 5.3831C12.9634 5.78814 13.3941 6.03812 13.8607 6.03812H18.2C19.6912 6.03812 20.9 7.24695 20.9 8.73811V15.7C20.9 17.1912 19.6912 18.4 18.2 18.4H14.2C13.8134 18.4 13.5 18.0866 13.5 17.7C13.5 17.3134 13.8134 17 14.2 17H18.2C18.918 17 19.5 16.418 19.5 15.7V8.73811C19.5 8.02014 18.918 7.43811 18.2 7.43811H13.8607C12.8917 7.43811 11.9971 6.91894 11.5164 6.07769L10.6681 5.05502C10.4366 4.64997 10.0059 4.4 9.53937 4.4H6.20002Z" fill="#2C2C2C"/>
      </symbol>
      <symbol id="edit_name_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#2C2C2C"/>
      </symbol>
      <symbol id="log_out_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5.16927L7 5.16927C5.89543 5.16927 5 6.0647 5 7.16927L5 16.8359C5 17.9405 5.89543 18.8359 7 18.8359L12 18.8359" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
        <path d="M11 12L20 12M20 12L16.0143 8M20 12L16.0143 16" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      </symbol>
      <symbol id="delete_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
      </symbol>
      <symbol id="upload_letter_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0182 10.0801L8.71496 13.3833C8.44091 13.6574 8.44091 14.1017 8.71496 14.3757C8.98901 14.6498 9.43334 14.6498 9.70739 14.3757L11.3164 12.7667V19.0374C11.3164 19.425 11.6306 19.7392 12.0182 19.7392C12.4058 19.7392 12.7199 19.425 12.7199 19.0374V12.7667L14.329 14.3757C14.603 14.6498 15.0474 14.6498 15.3214 14.3757C15.5955 14.1017 15.5955 13.6574 15.3214 13.3833L12.0182 10.0801Z" fill="#2C2C2C"/>
        <path d="M17.6295 9.79387C17.5465 6.73148 15.0294 4.26562 11.9474 4.26562C10.5315 4.26562 9.17488 4.78945 8.1274 5.74054C7.22483 6.56003 6.61494 7.63746 6.37612 8.81776C5.52288 9.00124 4.74407 9.44259 4.14149 10.0922C3.40539 10.8859 3 11.9194 3 13.0024C3 15.3628 4.9203 17.2831 7.2807 17.2831C7.28629 17.2831 7.2918 17.2831 7.29585 17.283H7.31578C7.70335 17.283 8.01753 16.9688 8.01753 16.5812C8.01753 16.1937 7.70335 15.8795 7.31578 15.8795H7.29005L7.27669 15.8796C5.69205 15.8775 4.40351 14.5876 4.40351 13.0024C4.40351 11.5131 5.56475 10.2534 7.04722 10.1347L7.62969 10.0881L7.68935 9.50686C7.91382 7.31898 9.74441 5.6691 11.9474 5.6691C14.3078 5.6691 16.2281 7.5894 16.2281 9.94979V11.1779H17.2456C18.5419 11.1779 19.5965 12.2325 19.5965 13.5287C19.5965 14.825 18.5419 15.8796 17.2456 15.8796L16.7194 15.8795C16.3318 15.8794 16.0175 16.1936 16.0175 16.5812C16.0175 16.9688 16.3317 17.2829 16.7192 17.2829H17.2227C17.2304 17.2831 17.238 17.2832 17.2456 17.2832C19.3158 17.2832 21 15.5989 21 13.5288C21 11.5881 19.5201 9.98667 17.6295 9.79387Z" fill="#2C2C2C"/>
      </symbol>


      <symbol id="menu_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.44 10.5576C7.23529 10.5576 7.88 11.2023 7.88 11.9976C7.88 12.7928 7.23529 13.4376 6.44 13.4376C5.64471 13.4376 5 12.7928 5 11.9976C5 11.2023 5.64471 10.5576 6.44 10.5576Z" fill="#2C2C2C"/>
        <circle cx="12.0001" cy="11.9975" r="1.44" transform="rotate(-90 12.0001 11.9975)" fill="#2C2C2C"/>
        <path d="M17.5601 10.5575C18.3554 10.5575 19.0001 11.2022 19.0001 11.9975C19.0001 12.7928 18.3554 13.4375 17.5601 13.4375C16.7648 13.4375 16.1201 12.7928 16.1201 11.9975C16.1201 11.2022 16.7648 10.5575 17.5601 10.5575Z" fill="#2C2C2C"/>
      </symbol>
      <symbol id="accordeon_arrow_icn" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.76496 4.69531C2.49769 4.69531 2.36384 5.01846 2.55283 5.20744L5.82857 8.48318C5.94573 8.60034 6.13568 8.60034 6.25283 8.48318L9.52856 5.20744C9.71755 5.01845 9.5837 4.69531 9.31643 4.69531H2.76496Z" fill="#2C2C2C"/>
      </symbol>
      <symbol id="move_to_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5.16927L7 5.16927C5.89543 5.16927 5 6.0647 5 7.16927L5 16.8359C5 17.9405 5.89543 18.8359 7 18.8359L12 18.8359" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
        <path d="M11 12L20 12M20 12L16.0143 8M20 12L16.0143 16" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      </symbol>
      <symbol  id="download_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3333 5V14M12.3333 14L16.3333 10.0143M12.3333 14L8.33332 10.0143M19 16V17C19 18.1046 18.1046 19 17 19H7.33333C6.22876 19 5.33333 18.1046 5.33333 17V16" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      </symbol>
      <symbol id="opened_envelope_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 10.6204V18.0253C4 19.1159 4.89543 20 6 20H18C19.1046 20 20 19.1159 20 18.0253V10.6204M4 10.6204V10.1267V9.32902C4 8.60259 4.39389 7.93327 5.02896 7.58057L11.0195 4.2536C11.6283 3.91547 12.3717 3.91547 12.9805 4.25359L18.971 7.58057C19.6061 7.93327 20 8.60259 20 9.32902V10.6204M4 10.6204L11.0195 14.5188C11.6283 14.8569 12.3717 14.8569 12.9805 14.5188L20 10.6204M20 10.6204V10.1267" stroke="#2C2C2C" stroke-width="1.4"/>
      </symbol>
      <symbol id="attach_to_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6329 16.1955L8.74753 18.0808C7.9659 18.8624 6.70011 18.8624 5.91914 18.081C5.13801 17.2998 5.13801 16.0339 5.91898 15.253L9.69035 11.4816C10.4713 10.7006 11.7372 10.7006 12.5182 11.4816C12.7786 11.7419 13.2007 11.7419 13.461 11.4816C13.7214 11.2212 13.7214 10.7991 13.461 10.5388C12.1593 9.23708 10.0492 9.23708 8.74753 10.5388L4.97619 14.3101C3.6745 15.6118 3.6745 17.7219 4.97619 19.0236C6.27773 20.326 8.388 20.326 9.69038 19.0236L11.5757 17.1382C11.8361 16.8779 11.8361 16.4558 11.5757 16.1954C11.3154 15.9351 10.8932 15.9351 10.6329 16.1955Z" fill="#2C2C2C"/>
        <path d="M19.0229 4.97627C17.7212 3.67458 15.6104 3.67458 14.3087 4.97627L12.0467 7.23828C11.7863 7.49863 11.7863 7.92076 12.0467 8.1811C12.307 8.44145 12.7292 8.44145 12.9895 8.1811L15.2515 5.91909C16.0325 5.13808 17.2991 5.13808 18.0801 5.91909C18.861 6.70006 18.861 7.96598 18.0801 8.74695L13.932 12.895C13.151 13.676 11.8852 13.676 11.1042 12.895C10.8438 12.6347 10.4217 12.6347 10.1614 12.895C9.90102 13.1553 9.90102 13.5775 10.1614 13.8378C11.4631 15.1395 13.5732 15.1395 14.8749 13.8378L19.0229 9.6898C20.3246 8.3881 20.3246 6.27797 19.0229 4.97627Z" fill="#2C2C2C"/>
      </symbol>
      <symbol id="to_archive_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      </symbol>
      <symbol id="unarchive_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/>
        <path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#2C2C2C"/>
      </symbol>

      <symbol id="mail_placeholder_icn" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M72.5 72.6868V73.2235C72.5 75.0019 71.0673 76.4436 69.3 76.4436H60.4758M72.5 72.6868V35.0716C72.5 34.2096 72.1538 33.3843 71.54 32.7831M72.5 72.6868L46.1533 58.3297M8.5 72.6868V73.2235C8.5 75.0019 9.93269 76.4436 11.7 76.4436H54.5606M8.5 72.6868L34.6716 58.3297M8.5 72.6868V49.9606M46.1533 58.3297L41.9222 56.024C40.9688 55.5044 39.8188 55.506 38.8668 56.0283L34.6716 58.3297M46.1533 58.3297L66.74 37.6133M66.74 37.6133L71.54 32.7831M66.74 37.6133V28.0813M71.54 32.7831L66.74 28.0813M34.6716 58.3297L14.26 37.6521M14.26 37.6521L9.45362 32.7831M14.26 37.6521V28.0435M9.45362 32.7831C8.8437 33.3845 8.5 34.2077 8.5 35.067V44.9434M9.45362 32.7831L14.26 28.0435M66.74 28.0813V17.4082C66.74 15.6298 65.3073 14.1881 63.54 14.1881H52.5566M52.5566 14.1881L42.6329 4.46738C41.3875 3.24755 39.4024 3.25088 38.1611 4.47488L28.3109 14.1881M52.5566 14.1881H28.3109M28.3109 14.1881H17.46C15.6927 14.1881 14.26 15.6298 14.26 17.4082V28.0435M40.6067 42.7394C35.128 42.7394 30.6867 38.2221 30.6867 32.6497C30.6867 27.0774 35.128 22.5601 40.6067 22.5601C46.0853 22.5601 50.3133 26.7553 50.3133 32.3277C50.3133 35.2258 48.9267 37.9399 47.0067 38.1239C44.98 38.3182 43.8067 37.4799 43.9133 32.5424M43.9133 32.5424C43.9133 35.3286 42.1464 37.5872 39.9667 37.5872C37.787 37.5872 36.02 35.3286 36.02 32.5424C36.02 29.7562 37.787 27.4976 39.9667 27.4976C42.1464 27.4976 43.9133 29.7562 43.9133 32.5424ZM43.9133 32.5424V26.8535" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
      </symbol>
      <symbol id="no_search_result_icn" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M58 57.7132C63.5494 52.3493 67 44.8276 67 36.5C67 20.2076 53.7924 7 37.5 7C21.2076 7 8 20.2076 8 36.5C8 52.7924 21.2076 66 37.5 66C45.4648 66 52.6924 62.8435 58 57.7132ZM58 57.7132L73.2868 73" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
        <path d="M61 36.5C61 23.5213 50.4787 13 37.5 13" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
      </symbol>

      <symbol id="edit_template_icn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30005 6.99688C4.30005 4.95342 5.9566 3.29688 8.00005 3.29688H16C18.0435 3.29688 19.7 4.95342 19.7 6.99688V16.9969C19.7 19.0403 18.0435 20.6969 16 20.6969H8.00005C5.9566 20.6969 4.30005 19.0403 4.30005 16.9969V6.99688ZM8.00005 4.69687C6.72979 4.69687 5.70005 5.72662 5.70005 6.99688V16.9969C5.70005 18.2671 6.72979 19.2969 8.00005 19.2969H16C17.2703 19.2969 18.3 18.2671 18.3 16.9969V6.99688C18.3 5.72662 17.2703 4.69687 16 4.69687H8.00005ZM8.30005 7.99687C8.30005 7.61028 8.61345 7.29688 9.00005 7.29688H15C15.3866 7.29688 15.7 7.61028 15.7 7.99687C15.7 8.38347 15.3866 8.69687 15 8.69687H9.00005C8.61345 8.69687 8.30005 8.38347 8.30005 7.99687ZM8.30005 11.9969C8.30005 11.6103 8.61345 11.2969 9.00005 11.2969H15C15.3866 11.2969 15.7 11.6103 15.7 11.9969C15.7 12.3835 15.3866 12.6969 15 12.6969H9.00005C8.61345 12.6969 8.30005 12.3835 8.30005 11.9969ZM8.30005 15.9969C8.30005 15.6103 8.61345 15.2969 9.00005 15.2969H12C12.3866 15.2969 12.7 15.6103 12.7 15.9969C12.7 16.3835 12.3866 16.6969 12 16.6969H9.00005C8.61345 16.6969 8.30005 16.3835 8.30005 15.9969Z" fill="#2C2C2C"/>
      </symbol>

    </svg>
  </div>
</template>

<script>
  export default {
  }
</script>

<style lang="scss" scoped>
.svg-container{
  display: none;
}
</style>