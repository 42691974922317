<template>
  <section>
    <div class="content">
      <div class="wrap paths">
        <bread 
          class="p-x-0"
          :allowOptions="false" 
          :allowBorder="false" 
          :links="[
            {name: 'Employees', link: '/employees'}, 
            {name: `Add employee`, link: ''}
          ]"
        />
        <!-- <div class="part1">
          <router-link to="/employees" class="fz12 text-gray">Employees</router-link>
          <p class="fz12 text-gray">Add employee</p>
        </div>
        <div class="part2">
          
        </div> -->
      </div>
      <div class="wrap name-page">
        <div class="part1">
          <p class="fz20 text-dark fw500">Add employee</p>
        </div>
<!--        <div class="part2">-->
<!--          <div class="name-step">-->
<!--            <p class="text-gray fz12">Step 1 of 2</p>-->
<!--          </div>-->
<!--          <div class="line">-->
<!--            <div class="line-bg">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="wrap main">
        <div class="part1">
          <div class="wrap-image-block">
            <div class="image-block">
              <div class="filter" :class="{'not-image': !image, 'image-upload': image}"></div>
              <img id="image-user" ref="name" :class="{dn: !image}" :src="image" alt="">
              <label class="upload-photo__label" for="upload-photo"></label>

              <input class="upload-photo" accept="image/jpeg, image/png" type="file" id="upload-photo" @change="onFileChange">
            </div>
            <div class="errorImage">
              <p class="fz11 text-red">{{ textErrorImageOne }}</p>
              <p class="fz11 text-red">{{ textErrorImageTwo }}</p>
            </div>
          </div>

        </div>
        <div class="part2">
          <div class="input-two-block">
            <div class="input-block">
              <p class="fz12 text-gray">First Name</p>
              <input class="h36" maxlength="16" type="text" @input="inputName(firstName)" v-model="firstName">
            </div>
            <div class="input-block">
              <p class="fz12 text-gray">Last Name</p>
              <input class="h36" maxlength="16" type="text" @input="inputLastName(lastName)" v-model="lastName">
            </div>
          </div>
          <div class="input-block">
            <p class="fz12 text-gray">Contacts Number</p>
            <!--                      <input type="file"  @change="upload($event)" id="file-input">-->
            <!--                      <input class="h36" :class="{error:errorPhone}" type="text" id="input-phone" v-model="number">-->
            <inputphone @validPhone="changeValidPhone" ref="inputPhone" @changePhone="updatePhone" class="input h36" :class="{error:errorPhone}"></inputphone>


          </div>
          <div class="input-block">
            <p class="fz12 text-gray">E-mail</p>
            <input class="h36" type="email" :class="{error:error}" @focus="checkEmail" @blur="checkEmailOut" v-model="email">
          </div>
        </div>
      </div>
      <div class="bottom-panel">
        <div class="wrap">
          <div class="part1">
            <router-link to="/employees" class="blue-button-text fz14">Cancel</router-link>
          </div>
          <div class="part2">
            <div class="part2">
              <button class="blue-button fz14" v-on:click="nextClick">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import Inputmask from 'inputmask';
import {mapActions, mapGetters, mapMutations} from "vuex";
import Inputphone from "../../components/input-phone/inputphone";
import bread from '@/components/reuse/bread';


export default {
  name: "changeProfile",
  components: {Inputphone, bread},
  computed: mapGetters(["getInfoForUser", "getLoginData"]),
  data() {
    return {
      fileupload: false,
      avatarCopy: "",
      imageOnload: "",
      image: "",
      firstName: "",
      lastName: "",
      number: "",
      email: "",
      error: false,
      errorPhone: false,
      textErrorImageOne: "",
      textErrorImageTwo: "",
      errorImageBlock: false,
      file: null,
      validPhone: true,
    }
  },
  // watch: {
  //   image: function () {
  //     // let w = this.$refs.name.onload;
  //     // // console.log(w);
  //   } ,
  // },
  methods: {
    ...mapActions(["axiosCheckPhoneAndEmailForAddNewUser", "getAxiosInfoForUser", "axiosChangeUserProfile"]),
    ...mapMutations(["setUserDataUSER", "setAlert", "setDataForAxiosRegistration", "setModalCreateUser", "showLoad", 'hideLoad']),
    changeValidPhone(valid) {
      this.validPhone = valid
    },
    updatePhone(phone) {
      this.number = phone;
    },
    ucFirst(str) {
      if (!str) return str;

      return str[0].toUpperCase() + str.slice(1);
    },
    inputName(str) {
      this.firstName = str.replace(/[^A-Za-zА-Яа-яЁё]/g, "");
      this.firstName = this.firstName.toLowerCase();
      this.firstName = this.ucFirst(this.firstName.trim());
    },
    inputLastName(str) {
      this.lastName = str.replace(/[^A-Za-zА-Яа-яЁё]/g, "");
      this.lastName = this.lastName.toLowerCase();
      this.lastName = this.ucFirst(this.lastName.trim());
    },
    // upload(event) {
    //   // this.file = null;
    //   // this.file = new FormData();
    //   // let file = event.target.files[0];
    //   // //
    //   // data.append('name', 'my-file')
    //   // this.file.append('file', file);
    //   // // console.log(this.file.getAll("file"));
    //   // // console.log(event.target.files[0]);
    // },
    nextClick() {
      // this.checkEmail();
      // this.checkEmailOut();
      this.firstName = this.ucFirst(this.firstName.trim());
      this.lastName = this.ucFirst(this.lastName.trim());
      this.email = this.email.trim();
      // // console.log(this.firstName + " " + this.lastName + " " + this.number + " " + this.email + " " + this.error)
      let phone = (this.number.replace(/-/g, '')).replace(/\s+/g, '');
      // let code = phone[5] + phone[6];
      // console.log(phone);
      // console.log(phone.length);
      this.errorPhone = false;
      // if(phone.length === 16) {
      //     this.errorPhone = true;
      //   if(code === "67" || code === "68" || code === "96" || code === "97" || code === "98" || code === "50" || code === "66" || code === "95" || code === "99"  || code === "63" || code === "73" || code === "93" || code === "91" || code === "92" || code === "89" || code === "94") {
      //     this.errorPhone = false;
      //   }
      // }
      let errorInput = false;
      if(this.firstName.length === 0 || this.lastName.length === 0 || !this.validPhone || this.email.length === 0) {
        errorInput = true;
      }
      if(this.firstName.length === 0 || this.lastName.length === 0 || !this.validPhone || this.email.length === 0 || this.error === true || this.errorPhone === true) {
        if(errorInput) {
          this.setAlert({show: true, text: "Fill in all the fields please."})
        } else if(this.error) {
          this.setAlert({show: true, text: "Incorrect e-mail."})
        } else if(this.errorPhone === true){
          this.setAlert({show: true, text: "Invalid operator code."})
        } else if(!this.validPhone) {
          this.setAlert({show: true, text: "Invalid phone number."})
        }
        // if(this.error === false) {
        // }  else {
        // }
      } else {
        if(this.avatarCopy === this.image) {
          this.file = null;
        }
        this.showLoad()
        this.axiosCheckPhoneAndEmailForAddNewUser({phone_number: phone, email: this.email}).then(res=> {
          if((res.phone_is_valid || phone === this.getInfoForUser.phone_number) && (res.email_is_valid || this.email === this.getInfoForUser.email)) {
            this.axiosChangeUserProfile({id: this.getInfoForUser.id,first_name: this.firstName, last_name: this.lastName, phone: phone, email: this.email, avatar: this.file}).then(res=> {
              this.hideLoad()
              console.log()
              if(res.id === this.getLoginData.data.user.id)
                this.setUserDataUSER(this.getInfoForUser)
              this.$router.push({name:'Employee card'})
            }).catch(error=> {
              console.log(error.data)
            })
          } else {
            this.hideLoad()
            if(!(res.phone_is_valid || phone === this.getInfoForUser.phone_number) && !(res.email_is_valid || this.email === this.getInfoForUser.email)) {
              this.setAlert({show: true, text: "User with such email and phone already exist, please changing data."});
            } else if(!(res.phone_is_valid || phone === this.getInfoForUser.phone_number)) {
              this.setAlert({show: true, text: "User with such phone already exist, please changing data."});
            } else if(!(res.email_is_valid || this.email === this.getInfoForUser.email)) {
              this.setAlert({show: true, text: "User with such email already exist, please changing data."});
            }
          }
        })

      }
    },
    onFileChange(e) {
      this.fileupload = true;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;

      this.file = files[0];
      // console.log(document.getElementById("image-user"))
      // let data = new FormData(files[0]);
      // data.append()
      this.textErrorImageOne = "";
      this.textErrorImageTwo = "";
      this.errorImageBlock = false;
      if(files[0].size/ (1024*1024) > 16) {
        this.errorImageBlock = true;
        this.textErrorImageOne = "The photo should not be more than 16 MB";
        this.textErrorImageTwo = "";
      }
      this.createImage(files[0]);
    },

    createImage(file) {
      this.image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        // let data = new FormData();
        // let file = e.target;
        //
        // data.append('name', 'my-file')
        // data.append('file', file);
        //
        // // console.log("IMG");
        // // console.log(e.target);
        vm.image = e.target.result;
        let imageElement = document.createElement("img");
        imageElement.addEventListener('load', function() {
          // // console.log(imageElement);
          // if(imageElement.width > 1600 || imageElement.height > 1600) {
          //   vm.textErrorImageOne = "The photo must be less than";
          //   vm.textErrorImageTwo = "1600 x 1600 pixels.";
          //   vm.errorImageBlock = true;
          // } else
          if(imageElement.width < 160 || imageElement.height < 160) {
            vm.textErrorImageOne = "The photo must be larger than";
            vm.textErrorImageTwo = "160 x 160 pixels.";
            vm.errorImageBlock = true;
          }


        });
        imageElement.src = vm.image;
      };
      reader.readAsDataURL(file);
    },
    checkEmail() {
      // // console.log(1);
      if(this.email.length === 0) {
        this.error = false;
      } else {
        let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        // this.errorEmail = !re.test(String(email).toLowerCase());
        if(this.error === true && re.test(String(this.email).toLowerCase()) === true) {
          this.error = false;
        }
      }
    },
    checkEmailOut() {
      // // console.log(2);
      if(this.email.length !== 0) {
        let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        this.error = !re.test(String(this.email).toLowerCase());
      } else {
        this.error = false;
      }
    }
//            removeImage: function () {
//                this.image = '';
//            }
  },
  mounted() {
    let textRoute = this.$route.path;
    let idTask = textRoute.replace(/[^0-9]/gim,'');
    this.showLoad()
    this.getAxiosInfoForUser(idTask).then(()=> {
      this.hideLoad()
      this.image = this.getInfoForUser.avatar;
      this.avatarCopy = this.getInfoForUser.avatar;
      this.lastName = this.getInfoForUser.last_name;
      this.firstName = this.getInfoForUser.first_name;
      this.number = this.getInfoForUser.phone_number;
      this.$refs.inputPhone.setPhone(this.getInfoForUser.phone_number);
      this.email = this.getInfoForUser.email;
    });
    // this.setModalCreateUser({status: false, name: ""});
    // var im = new Inputmask("+999 99 999 9999");

    //   im.mask(document.getElementById('input-phone'));
  }
}
</script>
<style scoped lang="scss">
@import "changeProfile";
</style>