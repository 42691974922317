<template>
  <div class="counterparties-edit-types_form__block">
    <div class="counterparties-edit-types_form__block_head">
      <div class="triangle" :class="{active: colapsed}">
        <svg @click="toggleBlock" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.39844L6.00001 8.39844L10 4.39844L2 4.39844Z" fill="#2C2C2C"/></svg>
      </div>
      <div class="text" @click="editType(type)">{{type.name}}</div>
      <div class="delete">
        <svg @click="delType(type)" width="24" height="24" viewBox="0 0 24 24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"/></svg>
      </div>
    </div>
    <div class="counterparties-edit-types_form__block_body" :class="{colapsed: colapsed}" :style="{height: height + 'px'}">
      <div class="counterparties-edit-types_form__block_sybtype" v-for="el in subtypes" :key="el.id" ref="calcStr">
        <div class="text" @click="editType(el)">{{el.name}}</div>
        <div class="delete" @click="delType(el)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"/></svg>
        </div>
      </div>
      <div class="counterparties-edit-types_form__block_add" ref="calcAdd">
        <button class="btn" @click="addSubtype"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z" fill="#094172"/></svg>Create new subtype</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Object
    },
    subtypes: {
      type: Array
    }
  },
  data() {
    return {
      height: 0,
      colapsed: false
    }
  },
  methods: {
    toggleBlock() {
      if(!this.colapsed){
        this.colapsed = true
        return false
      }

      this.colapsed = false
      this.height = 0
      if(this.$refs.calcStr){
        this.$refs.calcStr.forEach(el => {
          this.height += el.offsetHeight
        })
      }
      this.height += this.$refs.calcAdd.offsetHeight
    },
    addSubtype() {
      this.$emit('addSubtype', this.type)
    },
    delType(data) {
      this.$emit('delType', data)
    },
    editType(data) {
      this.$emit('editType', data)
    }
  },
  updated() {
    let height = 0

    if(this.$refs.calcStr){
      this.$refs.calcStr.forEach(el => {
        height += el.offsetHeight
      })
    }
    height += this.$refs.calcAdd.offsetHeight
    if(height != this.height)
      this.height = height
  },
  mounted() {
    this.height = 0

    if(this.$refs.calcStr){
      this.$refs.calcStr.forEach(el => {
        this.height += el.offsetHeight
      })
    }
    this.height += this.$refs.calcAdd.offsetHeight
  }
}
</script>

<style lang="scss">
.counterparties-edit-types_form__block{
  margin-top: 25px;
  font-size: 14px;
  &_head{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    .text{
      font-weight: 500;
    }
    .triangle{
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      transition: transform 0.3s;
      &.active{
        transform: rotate(-90deg);
      }
    }
  }
  .text{
    word-break: break-all;
  }
  .delete{
    position: relative;
    top: -5px;
    cursor: pointer;
    svg{
      transition: fill 0.3s;
    }
    &:hover{
      svg{
        fill: var(--color-dark) !important;
      }
    }
  }
  &_body{
    overflow: hidden;
    transition: height 0.3s;
    &.colapsed{
      height: 0 !important;
    }
  }
  &_sybtype{
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    &:not(:last-of-type){
      padding-top: 6px;
    }
  }

  &_add{
    text-align: right;
    .btn{
      display: inline-flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      color: var(--color-blue-dark);
      &:hover{
        color: #1f64a0;
      }
    }
  }

}
</style>