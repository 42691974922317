<template>
  <div class="wrap-page">
      <div class="wrap-loading" v-if="getLoadStatus !==0">
        <div class="sk-fading-circle">
          <div class="sk-circle sk-circle-1"></div>
          <div class="sk-circle sk-circle-2"></div>
          <div class="sk-circle sk-circle-3"></div>
          <div class="sk-circle sk-circle-4"></div>
          <div class="sk-circle sk-circle-5"></div>
          <div class="sk-circle sk-circle-6"></div>
          <div class="sk-circle sk-circle-7"></div>
          <div class="sk-circle sk-circle-8"></div>
          <div class="sk-circle sk-circle-9"></div>
          <div class="sk-circle sk-circle-10"></div>
          <div class="sk-circle sk-circle-11"></div>
          <div class="sk-circle sk-circle-12"></div>
        </div>
      </div>
      <div class="top-panel">
        <router-link to="/baltic-dry-index" class="bread__link">Baltic Dry Index</router-link>
        <p class="text-gray fz12"> > </p>
        <span class="bread__link">Add new index</span>
        <router-link to="/baltic-dry-index" class="forMobile bread__link">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.70076 5.59668L6.81674 2.29437C7.08386 2.02251 7.51633 2.02297 7.783 2.29578C8.04947 2.56855 8.04878 3.01043 7.78163 3.2825L5.15093 6.09066L7.78163 8.80792C8.04875 9.08003 8.04943 9.52162 7.783 9.79443C7.64932 9.93119 7.47419 9.99957 7.29906 9.99957C7.12438 9.99957 6.94994 9.93165 6.8165 9.79584L3.70076 6.5846C3.57211 6.45389 3.49991 6.276 3.49991 6.09066C3.49991 5.90532 3.57231 5.72764 3.70076 5.59668Z" fill="#2C2C2C"/>
          </svg>

          Baltic Dry Index</router-link>

      </div>
    <div class="content">
      <div class="name">
        <p class="fz20 fw500 text-dark">Add new index</p>
        <div class="date-pick">
          <date-picker
              readonly="readonly"
              @blur="hiddenDatePicker"
              v-model="date"
              type="date"
              lang="en"
              ref="datepi"
              placeholder=""
              format="D MMMM YYYY"
              value-type="date"
              @open="openDatePicker"
              @close="closeDatePicker"
              @change="changeDate"
          ></date-picker>
          <svg :class="{'rotate-180': open}" class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.40039L6.00001 8.40039L10 4.40039H2Z" fill="#2C2C2C"/>
          </svg>
        </div>
      </div>
      <div class="main">
        <div class="block-buttons-wrap">
          <div class="block-buttons">
            <span class="line"></span>
            <button class="button-baltic fz16 fw500 text-gray" v-for="(b,i) in buttons" :class="{'button-baltic-active': b.status}" :key="i" v-on:click="changeActiveButton(i)">{{b.name}} </button>
          </div>
        </div>
        <div class="block-inputs">
          <div class="no-data" v-if="inputsArray.length === 0">
            <p class="fz13 text-gray">No data</p>
          </div>
          <div v-for="(r,i) in inputsArray" :key="i" :title="r.name">
            <p class="fz14 text-gray" >{{ r.name }}</p>
            <input maxlength="10" type="text" step="0.01" :disabled="!getPermissionsByType('can_edit_index')" v-model="r.amount" @input="r.amount = checkInput(r.amount)" @focusout="()=>{r.amount = checkDots(r.amount); checkCalc(r)}">
          </div>
        </div>
      </div>
      <div class="buttons-save">
        <button class="blue-button-text fz14 fw500" v-on:click="changeInputsArray" v-if="getPermissionsByType('can_edit_index')">Reset changes</button>
        <button class="blue-button fz14 fw500" v-on:click="saveIndex" v-if="getPermissionsByType('can_edit_index')">Save</button>
      </div>
    </div>

  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment"
import {mapActions, mapGetters, mapMutations} from "vuex";

  export default {
  name: "addNewIndex",
  components: {DatePicker},
    computed: mapGetters(["getShips", "getRoutesShip", "getAmount", "getLoadStatus", "getPermissionsByType"]),
  data() {
    return {
      buttons: [
      ],
      date: "",
      open: false,
      text: "",
      inputsArray: [],
      inputsArrayCopy: [],
    }
  },
    beforeRouteEnter: (to, from, next) => {
      console.log("from")
      console.log(from)
      next(vm => {
        if(!vm.$store.getters.getPermissionsByType("can_create_index")) {
          next(from);
        }
      })
    },
  methods: {
    ...mapActions(["axiosGetShips", "getRouteForShip", "getAmountForRoutes", "createAxiosIndex", "updateAxiosIndex", "deleteAxiosIndex"]),
    ...mapMutations(["hideLoad", "showLoad", "addTip", "addLoadStatus", "decLoadStatus", "setAlert"]),
    checkCalc(r) {
      let activeButton = this.buttons.find(a=> a.status)
      if(activeButton.name === 'Panamax' && r.name === 'P5TC 82' && moment(this.date).isAfter(new Date(2011, 12, 24))) {
        let el = this.inputsArray.find(a=> a.name === 'P4TC 74K')
        if(r.amount && r.amount > 0) {
          el.amount = (parseFloat(r.amount) - 1336)
        } else {
          el.amount = null
        }
      }
    },
    openDatePicker() {
      this.open = true;
    },
    hiddenDatePicker() {
      // this.$refs.datepi.hidden();
      // if(this.date === null || this.date.length === 0) {
      //   this.date = new Date();
      // }
    },
    checkDots(a) {
      if(a.length!==0) {
        if(a.length===1 && (a[0] === "." || a[0] === "-")) {
          return a.replace("-", "").replace(".", "");
        }
        if(a[a.length-1] === ".") {
          return a.replace(".", "");
        }
      }
      return a;
    },
    checkInput(num) {
      if(num.indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^-0-9]/g, "");
        num2 = num2.replace(/[^-0-9]/g, "");

        if(num2.length > 4) {
          num2 = num2.substring(0, 4);
        }
        num = num1 + "." + num2;
      } else {
        num = num.replace(/[^-0-9]/g, "");
      }
      return num;
    },
    closeDatePicker() {
      this.open = false;
    },
    changeActiveButton: function (index) {
      if(this.indexButtonActive !== index) {
        this.indexButtonActive = index;
        let oldIndex = 0;
        for (let i = 0; i < this.buttons.length; i++) {
          if (this.buttons[i].status === true) {
            oldIndex = i;
          }
          this.buttons[i].status = false;
        }
        this.buttons[index].status = true;
        if (index >= oldIndex) {
          // // console.log(this.element[index].offsetLeft);
          this.allLine[0].style.left = this.element[index].offsetLeft + "px";
          this.allLine[0].style.width = this.element[index].clientWidth + "px";
        } else {
          this.allLine[0].style.left = this.element[index].offsetLeft + "px";
          this.allLine[0].style.width = this.element[index].clientWidth + "px";
        }
            this.getRouteForShip(this.buttons[index].id).then(()=> {
              this.changeInputsArray();
            });
      }
      // // console.log(this.allLine);
    },
    async changeInputsArray() {
      this.inputsArray.splice(0, this.inputsArray.length);
      this.inputsArrayCopy.splice(0, this.inputsArrayCopy.length);
      let date ="";
      // date += (new Date(this.date)).getFullYear() + "-"
      // let mm = (new Date(this.date)).getMonth() + 1;
      // // console.log(2)
      // if(mm<10) {
      //   mm = "0" + mm;
      // }
      // // console.log(3)
      // date += mm + "-" + (new Date(this.date)).getDate() + "T00:00:00";
      date = moment(this.date).format('YYYY-MM-DD')
      for(let i=0; i<this.getRoutesShip.length; i++) {
        // // console.log(1);
        let form = {
          name: this.getRoutesShip[i].reduction,
          id: this.getRoutesShip[i].id,
          ship_type: this.getRoutesShip[i].ship_type,
          sorting_index: this.getRoutesShip[i].sorting_index,
          amount: "",
          index_id: "",
          date: date+'T00:00:00',
          created_at: date+'T00:00:00',
        }
        // // console.log(2);
        this.getAmountForRoutes({id:form.id, created_at_after: date+'T00:00:00', created_at_before: date+'T23:59:00',}).then(()=> {
          form.amount = this.getAmount.amount;
          form.index_id = this.getAmount.index_id;
          form.created_at = (''+this.getAmount.created_at).length !== 0 ? this.getAmount.created_at : form.created_at

          // // console.log(3);

          this.inputsArray.push(form);
          this.inputsArrayCopy.push(JSON.parse(JSON.stringify(form)));
          this.inputsArray.sort((a,b)=> a.sorting_index > b.sorting_index ? 1: -1);
          this.inputsArrayCopy.sort((a,b)=> a.sorting_index > b.sorting_index ? 1: -1);
          // // console.log(4);
        });
        // console.log(4)
      }
      // console.log(5)
    },
    changeDate() {
      if(this.date === null || this.date.length === 0) {
        this.date = new Date();
      } else {
        if(this.date.getFullYear() < 2000) {
          this.date = new Date(2000, this.date.getMonth(), this.date.getDate());
        }
        if(this.date.getFullYear() > 2100) {
          this.date = new Date(2100, this.date.getMonth(), this.date.getDate());
        }
      }
      this.changeInputsArray();
    },
    saveIndex() {
      // let change = false;
      let vm = this;
      async function array () {
        vm.showLoad()
        console.log(vm.inputsArrayCopy)
        for(let i=0; i<vm.inputsArray.length; i++) {
          // console.log(( "" + vm.inputsArray[i].index_id).length);
          if(vm.inputsArray[i].amount !== null && (""+vm.inputsArray[i].amount).length > 0 ) {
            // change = true;
            console.log(vm.inputsArray[i].amount , ''+vm.inputsArrayCopy[i].amount)
            if(vm.inputsArray[i]?.amount !== vm.inputsArrayCopy[i]?.amount) {
              console.log(vm.inputsArray[i].index_id)
              if(!vm.inputsArray[i]?.index_id) {
                // // console.log(1);
                let item = await vm.createAxiosIndex({amount: vm.inputsArray[i].amount, created_at: vm.inputsArray[i].created_at, ship_type: vm.inputsArray[i].ship_type, route_id: vm.inputsArray[i].id})
                vm.inputsArray[i].ammount = item.ammount;
                vm.inputsArray[i].index_id = item.id;
              } else {
                // // console.log("YES")
                let item = await vm.createAxiosIndex({amount: vm.inputsArray[i].amount, created_at: vm.inputsArray[i].created_at, ship_type: vm.inputsArray[i].ship_type, route_id: vm.inputsArray[i].id})
                vm.inputsArray[i].ammount = item.ammount;
                vm.inputsArray[i].index_id = item.id;
                // await vm.updateAxiosIndex({id: vm.inputsArray[i].index_id, amount: vm.inputsArray[i].amount, created_at: vm.inputsArray[i].created_at, ship_type: vm.inputsArray[i].ship_type, route_id: vm.inputsArray[i].id, id_index: vm.inputsArray[i].index_id})
              }
            }

          } else {
                if(vm.inputsArray[i]?.index_id !== "") {
                  await vm.deleteAxiosIndex(vm.inputsArray[i].index_id)
                  vm.inputsArray[i].amount = "";
                  vm.inputsArray[i].index_id = ""
                }
          }
        }
        vm.inputsArrayCopy = JSON.parse(JSON.stringify(vm.inputsArray))
        vm.hideLoad()
        return null;
      }
      array()
      // array().then(()=> {
      //   if(change) {
      //     this.changeInputsArray();
      //   }
      // })
    }

  },
  mounted() {
    this.date = new Date();
    this.axiosGetShips().then(()=> {
      for(let i=0; i<this.getShips.length; i++) {
        this.buttons.push(this.getShips[i]);
      }
      return null;
    }).then(()=> {
      this.element = document.getElementsByClassName("button-baltic");
      this.allLine = document.getElementsByClassName("line");
      this.allLine[0].style.left = this.element[0].offsetLeft + "px";
      this.allLine[0].style.width = this.element[0].clientWidth + "px";
      for(let i=0; i<this.buttons.length; i++) {
        if(this.buttons[i].status === true) {
          this.getRouteForShip(this.buttons[i].id).then(()=> {
            this.changeInputsArray();
          });
        }
      }
    });

  },
    beforeCreate: function() {
      document.body.className = 'addNewIndex';
    },
    beforeRouteLeave(to,from,next) {
      document.body.className = '';
      next();
    }
}
</script>

<style scoped lang="scss">
  @import "AddNewIndex";
</style>