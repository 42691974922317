<template>
  <div class="wrap-page">
    <div class="position-absolute t0 l0 w100Proc h100Proc d-flex align-items-center justify-center z-1000 error" v-if="errorText">
      <p class="fz20 text-dark fw500">{{errorText}}</p>
    </div>
    <l-map class="map" ref="map" :zoom="zoom" :center="center">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-polyline v-for="(pol, i) in polyline" :key="i+'asd'" :lat-lngs="pol.latlngs" :color="pol.color"></l-polyline>
      <l-polygon :weight="0" v-for="(polygon, i) in secaZonesSettings" :key="'2323l'+i" :lat-lngs="polygon.latlngs" :color="polygon.color"></l-polygon>
      <l-marker v-for="(mark, i) in markets" :lat-lng="mark.coordinate" :key="i">
        <l-icon
            class-name="someExtraClass"
        >
<!--          <div class="mark">-->
<!--            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <circle cx="5" cy="5" r="4" fill="#E85353" stroke="#2C2C2C" stroke-width="2"/>-->
<!--            </svg>-->
<!--          </div>-->

                    <img src="../../assets/mark.svg" alt="">
          <p class="fz13 fw500 text-dark">{{mark.name}}</p>
        </l-icon>
      </l-marker>
    </l-map>
    <div class="parameters">
      <div class="block">
        <p>From port</p>
        <select v-model="fromPort">
          <option v-for="(p, i) in ports" :key="i" :value="p.name">{{p.name}}</option>
        </select>
      </div>
      <div class="block">
        <p>To port</p>
        <select v-model="toPort">
          <option v-for="(p, i) in ports" :key="i" :value="p.name">{{p.name}}</option>
        </select>
      </div>
      <div class="block">
        <p>Piracy options</p>
        <select v-model="piracyOption">
          <option v-for="(p, i) in piracyCodes" :key="i" :value="p.code">{{p.code}} - {{p.description}}</option>
        </select>
      </div>
      <div class="block">
        <p>Canal Pass</p>
        <select v-model="canalPassCode">
          <option v-for="(p, i) in canalPassCodes" :key="i" :value="p.code">{{p.code}} - {{p.description}}</option>
        </select>
      </div>
      <div class="block">
        <p>Use local eca</p>
        <select v-model="useLocalEca">
          <option value="True">True</option>
          <option value="False">False</option>
        </select>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <button class="button-blue-border" v-on:click="getDistance()">Send</button>
      <p class="ml4">Total distance: {{totalDistance}}</p>
      <p class="ml4">Total seca distance: {{totalSecaDistance}}</p>
      <p class="ml-auto mr8">Today used {{usedLimit}}/150</p>


    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { LMap, LTileLayer, LMarker, LPolyline, LIcon, LPolygon} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {mapActions, mapGetters} from "vuex";

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-polyline', LPolyline);
Vue.component('l-icon', LIcon);
Vue.component('l-polygon', LPolygon);




export default {
  name: "mapStream",
  props: ['render'],
  data() {
    return {
      keyRender: -34873786234876243,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      markets: [],
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 8,
      center: [47.313220, -1.319482],
      polylineEmpty: {
        latlngs: [],
            // [[47.334852, -1.509485], [47.342596, -1.328731], [47.241487, -1.190568], [47.234787, -1.358337]],
        color: 'green'
      },
      polyline: [],
      fromPort: null,
      toPort: null,
      piracyOption: null,
      canalPassCode: null,
      totalDistance: 0,
      totalSecaDistance: 0,
      usedLimit: 0,
      useLocalEca: 'True',
      ports: [
        {name: 'Aabenraa'},
        {name: 'Aahus'},
        {name: 'Aasgard'},
        {name: 'Aggersund'},
        {name: 'Agnefest'},
        {name: 'Aioi'},
        {name: 'Ajos'},
        {name: 'Akinum'},
        {name: 'Akitsu'},
        {name: 'Al Hoceima'},
        {name: 'Alborg'},
        {name: 'Alemoa Terminal '},
        {name: 'Algiers'},
        {name: 'Amio'},
        {name: 'Ancona'},
        {name: 'Antalaha'},
        {name: 'Antofagasta'},
        {name: 'Arco Ardjuna'},
        {name: 'Arica'},
        {name: 'Arun Terminal'},
        {name: 'Ashdod'},
        {name: 'Atyrau'},
        {name: 'Babouillat'},
        {name: 'Bade'},
        {name: 'Bahrain'},
        {name: 'Baku'},
        {name: 'Bandar Khomeini '},
        {name: 'Barahona'},
        {name: 'Barranquilla'},
        {name: 'Big Creek '},
        {name: 'Bizerte'},
        {name: 'Bleckede'},
        {name: 'Boizenburg'},
        {name: 'Buenaventura'},
        {name: 'Busan'},
        {name: 'Butinge Terminal'},
        {name: 'Cape Good Hope (RP)'},
        {name: 'Cape Horn (RP) '},
        {name: 'Cape Londonderry'},
        {name: 'Casablanca'},
        {name: 'Ceiba Terminal '},
        {name: 'Chalna'},
        {name: 'Chilung'},
        {name: 'Conakry'},
        {name: 'Corfu Is.'},
        {name: 'Corinth'},
        {name: 'Cotonou'},
        {name: 'Curacao'},
        {name: 'Dampier'},
        {name: 'Den Helder'},
        {name: 'Dhekelia'},
        {name: 'Dholera'},
        {name: 'Diamond Harbour'},
        {name: 'Dighi'},
        {name: 'Djibouti'},
        {name: 'Dominica Pass'},
        {name: 'Drapetzona'},
        {name: 'Drummond'},
        {name: 'Elang Terminal'},
        {name: 'Elat'},
        {name: 'Elephant Point (BU) '},
        {name: 'Ennore'},
        {name: 'Esperance'},
        {name: 'Etame Terminal '},
        {name: 'Everton'},
        {name: 'Fauji Oil Termina'},
        {name: 'Fond Mombin'},
        {name: 'Fort De France'},
        {name: 'Freeport (BM)'},
        {name: 'Fremantle'},
        {name: 'Gamba Terminal'},
        {name: 'Gandhar'},
        {name: 'Geelong'},
        {name: 'Georgetown (GY) '},
        {name: 'Gijon'},
        {name: 'Goa'},
        {name: 'Gresik'},
        {name: 'Guayaquil'},
        {name: 'Haifa'},
        {name: 'Haiphong'},
        {name: 'Halong'},
        {name: 'Hastings (AU) '},
        {name: 'Hoquiam'},
        {name: 'Hunafloi'},
        {name: 'Hvammstangi'},
        {name: 'Jakarta'},
        {name: 'Kalmar'},
        {name: 'Kilindini'},
        {name: 'Kingston (JM)'},
        {name: 'Kingstown'},
        {name: 'Klaipeda'},
        {name: 'Koge'},
        {name: 'Kotor'},
        {name: 'Kowloon'},
        {name: 'Kuwait'},
        {name: 'Kwinana'},
        {name: 'Ky Ha'},
        {name: 'La Baule Escoublac'},
        {name: 'La Mailleraye'},
        {name: 'La Union'},
        {name: 'Lamma Is'},
        {name: 'Lankaran'},
        {name: 'Libreville'},
        {name: 'Los Angeles'},
        {name: 'Magellan Strait (RP)'},
        {name: 'Malau'},
        {name: 'Mandalay'},
        {name: 'Mariager Fjord'},
        {name: 'Marsa Bashayer'},
        {name: 'Matane'},
        {name: 'Matarani'},
        {name: 'Mentone'},
        {name: 'Mina Abdulla '},
        {name: 'Mobile'},
        {name: 'Mocambique Channel'},
        {name: 'Mombasa'},
        {name: 'Mont Louis'},
        {name: 'Moorea'},
        {name: 'Mori (SB)'},
        {name: 'Nabq'},
        {name: 'Narsarsuaq'},
        {name: 'New Richmond'},
        {name: 'Norfolk'},
        {name: 'Nouakchott'},
        {name: 'Nuuk'},
        {name: 'Nuweiba'},
        {name: 'Ocean Cay'},
        {name: 'Off Bahrain'},
        {name: 'Opotiki'},
        {name: 'Orinoco River'},
        {name: 'Otranto'},
        {name: 'Palau'},
        {name: 'Pamatacual'},
        {name: 'Panama Canal (RP)'},
        {name: 'Papeete'},
        {name: 'Paramaribo'},
        {name: 'Pasajes'},
        {name: 'Pedra De Lume'},
        {name: 'Port Latta '},
        {name: 'Port Louis (MU)'},
        {name: 'Port Said'},
        {name: 'Port Said East'},
        {name: 'Port of Skulte'},
        {name: 'Porto Cervo'},
        {name: 'Portsmouth (DO)'},
        {name: 'Portsmouth (Virginia)'},
        {name: 'Providence Channel'},
        {name: 'Puerto Barrios'},
        {name: 'Puerto Patache'},
        {name: 'Punta Gorda (BE)'},
        {name: 'Ras Shukheir'},
        {name: 'Risdon Vale'},
        {name: 'Roomassaar'},
        {name: 'Rota (MP)'},
        {name: 'Rybinsk'},
        {name: 'Sainte Croix'},
        {name: 'Samara'},
        {name: 'San Fernando (TT) '},
        {name: 'San Jose (GT)'},
        {name: 'Sande'},
        {name: 'Sangar'},
        {name: 'Sawakin'},
        {name: 'Sepangar Bay'},
        {name: 'Sept Iles'},
        {name: 'Serpiente Bay'},
        {name: 'Sevastopol'},
        {name: 'Shanghai'},
        {name: 'Siam Seaport '},
        {name: 'Sirte'},
        {name: 'Skopje'},
        {name: 'Sokcho'},
        {name: 'St. Lucia Channel'},
        {name: 'St. Romuald'},
        {name: 'Suez Canal (RP)'},
        {name: 'Swansea (AU)'},
        {name: 'Taboguilla Island'},
        {name: 'Taboguilla Terminal'},
        {name: 'Taean'},
        {name: 'Thursday Island'},
        {name: 'Tripoli (LB)'},
        {name: 'Tripoli (LY)'},
        {name: 'Uranggan'},
        {name: 'Ventspils'},
        {name: 'Veracruz'},
        {name: 'Vientiane'},
        {name: 'Vilvoorde'},
        {name: 'Virtsu'},
        {name: 'Vlore'},
        {name: 'Vuda Point'},
        {name: 'Wasum'},
        {name: 'Whitegate'},
        {name: 'Yainville'},
        {name: 'Yithion'},
        {name: 'Yucatan Channel'},
        {name: 'Zahrani Terminal'},
        {name: 'Zawia Terminal'},
        {name: 'Zhenzhou'},
        {name: 'Zhongshan'},
      ],
      piracyCodes: [
        {code: '000', description: 'The latest update of piracy area. Identified with code 009 as of\n' +
              'April, 2021'},
        {code: '001', description: 'The shortest path '},
        {code: '002', description: '250nm outer route from Somalia east coast'},
        {code: '003', description: '600NM outer route from Somalia east coast'},
        {code: '004', description: 'JWLA015(2nd Aug 2010)'},
        {code: '005', description: 'JWLA016(16th Dec 2010)'},
        {code: '006', description: 'JWLA016 Up to Mumbai'},
        {code: '007', description: 'JWLA016 Max Avoid Route'},
        {code: '008', description: 'JWLA023(14th Jun 2018)'},
        {code: '009', description: 'JWLA027(29th April 2021)'},
      ],
      canalPassCodes: [
        {code: '000', description: 'Exclude all canals'},
        {code: '001', description: 'Exclude ‘Suez’ and ‘Panama’'},
        {code: '010', description: 'Exclude ‘Suez’ and ‘Kiel’'},
        {code: '011', description: 'Exclude ‘Suez‘'},
        {code: '100', description: 'Exclude ‘Panama’ and ‘Kiel’'},
        {code: '101', description: 'Exclude ‘Panama’'},
        {code: '110', description: 'Exclude ‘Kiel’'},
        {code: '111', description: 'Not exclude canals'},

      ],
      errorText: ''
    }
  },
  computed: {
    ...mapGetters(['getSecaZones']),
    secaZonesSettings() {
      let polygon = [];
      console.log(this.getSecaZones)
      this.getSecaZones.forEach(items=> {
        polygon.push({
          latlngs: items.map(a=> {
            return [a.lat, a.lon]
          }),
          color: 'blue'
        })
      })
      return polygon
    }
  },
  watch: {
    render: function () {
      // setTimeout(()=> {
      //   this.renderMap();
      // }, 100)
      this.$refs.map.mapObject.invalidateSize()
    },
    totalSecaDistance: function () {
      this.$emit('changeSecaDistance', this.totalSecaDistance)
    },
    totalDistance: function () {
      this.$emit('changeTotalDistance', this.totalDistance)
    }
  },
  methods: {
    ...mapActions(['axiosGetDataMap']),
    renderMap() {
      this.keyRender++;
    },
    async getDistanceRouter(dataAxios) {
      this.errorText = ''
      console.log(dataAxios)
      if(!dataAxios.piracyOption || !dataAxios.canalPassCode) {
        alert('Please fill in all fields')
        return null;
      }
      this.polyline = [];
      this.markets = [];
      this.totalDistance = 0;
      this.totalSecaDistance = 0;
      console.log('dataAxios.ports', dataAxios.ports)
      if(Array.isArray(dataAxios.ports) === true) {
          let data = await this.axiosGetDataMap(
              {
                'ports': dataAxios.ports,
                'piracy_option': dataAxios.piracyOption,
                'canal_pass_code': dataAxios.canalPassCode,
                'seca_cost_ratio': dataAxios.seca_cost_ratio,
                'seca_bypass_option': dataAxios.seca_bypass_option
              })
          if(!data) {
            this.errorText = 'Some of the ports you selected are not supported'
            this.polyline = []
            this.markets = []
          }
          let array = []
          if(Array.isArray(data) === true) {
            array = data.map(a=> {
              a.response.total_distance = a.response.section.distance
              a.response.total_seca_distance = a.response.section.distance_seca
              a.response.section = [a.response.section]
              return a.response
            })
          }
          for(let i=0; i< array.length;i++) {
            let data = array[i]
            this.totalDistance += data.total_distance
            this.totalSecaDistance += data.total_seca_distance
            this.usedLimit = data.today_used
            let section = data.section;
            let seca_zone = section[0].waypoint[0].seca
            section.forEach(sec=> {
              if(sec.waypoint.length) {
                this.polyline.push(JSON.parse(JSON.stringify(this.polylineEmpty)))
                this.polyline[this.polyline.length-1].color = seca_zone ? '#0FA54E' : '#FF833E'
              }

              sec.waypoint.forEach(point=> {
                if(point.seca === seca_zone) {
                  this.polyline[this.polyline.length-1].latlngs.push([point.lat, (point.lon)])
                } else {
                  this.polyline[this.polyline.length-1].latlngs.push([point.lat, (point.lon)])
                  this.polyline.push(JSON.parse(JSON.stringify(this.polylineEmpty)))
                  this.polyline[this.polyline.length-1].latlngs.push([point.lat, (point.lon)])
                  seca_zone = point.seca
                  this.polyline[this.polyline.length-1].color = seca_zone ? '#0FA54E' : '#FF833E'
                  // console.log(this.polyline[this.polyline.length-1].color)
                }
              })
            })
            section.forEach((sec, index)=> {
              // this.markets.push({coordinate: [], name: sec.waypoint[0].name})
              // this.markets.push({coordinate: [], name: sec.waypoint[sec.waypoint.length - 1].name})
              this.markets.push({coordinate: [sec.waypoint[0].lat,(sec.waypoint[0].lon)], name: sec.waypoint[0].name})
              if(index === section.length-1) {
                this.markets.push({coordinate: [sec.waypoint[sec.waypoint.length - 1].lat,(sec.waypoint[sec.waypoint.length - 1].lon)], name: sec.waypoint[sec.waypoint.length - 1].name})
              }
            })
          }

          console.log('data', JSON.parse(JSON.stringify(data)))


      }

      // if(Array.isArray(dataAxios.ports) === true) {
      //
      //   for(let i=0; i<dataAxios.ports.length; i++) {
      //     let data = await this.axiosGetDataMap(
      //         {
      //           'from_port': dataAxios.ports[i].from_port,
      //           'to_port': dataAxios.ports[i].to_port,
      //           'piracy_option': dataAxios.piracyOption,
      //           'canal_pass_code': dataAxios.canalPassCode,
      //           'use_local_eca': dataAxios.ports[i].has_seca,
      //           'seca_cost_ratio': dataAxios.seca_cost_ratio,
      //           'seca_bypass_option': dataAxios.seca_bypass_option
      //         })
      //     if(!data) {
      //       this.errorText = 'Some of the ports you selected are not supported'
      //       this.polyline = []
      //       this.markets = []
      //     }
      //     console.log('data', JSON.parse(JSON.stringify(data)))
      //     this.totalDistance += data.total_distance
      //     this.totalSecaDistance += data.total_seca_distance
      //     this.usedLimit = data.today_used
      //     let section = data.section;
      //     let seca_zone = section[0].waypoint[0].seca
      //     section.forEach(sec=> {
      //       if(sec.waypoint.length) {
      //         this.polyline.push(JSON.parse(JSON.stringify(this.polylineEmpty)))
      //         this.polyline[this.polyline.length-1].color = seca_zone ? '#0FA54E' : '#FF833E'
      //       }
      //
      //       sec.waypoint.forEach(point=> {
      //         if(point.seca === seca_zone) {
      //           this.polyline[this.polyline.length-1].latlngs.push([point.lat, (point.lon)])
      //         } else {
      //           this.polyline[this.polyline.length-1].latlngs.push([point.lat, (point.lon)])
      //           this.polyline.push(JSON.parse(JSON.stringify(this.polylineEmpty)))
      //           this.polyline[this.polyline.length-1].latlngs.push([point.lat, (point.lon)])
      //           seca_zone = point.seca
      //           this.polyline[this.polyline.length-1].color = seca_zone ? '#0FA54E' : '#FF833E'
      //           // console.log(this.polyline[this.polyline.length-1].color)
      //         }
      //       })
      //     })
      //     section.forEach((sec, index)=> {
      //       // this.markets.push({coordinate: [], name: sec.waypoint[0].name})
      //       // this.markets.push({coordinate: [], name: sec.waypoint[sec.waypoint.length - 1].name})
      //       this.markets.push({coordinate: [sec.waypoint[0].lat,(sec.waypoint[0].lon)], name: sec.waypoint[0].name})
      //       if(index === section.length-1) {
      //         this.markets.push({coordinate: [sec.waypoint[sec.waypoint.length - 1].lat,(sec.waypoint[sec.waypoint.length - 1].lon)], name: sec.waypoint[sec.waypoint.length - 1].name})
      //       }
      //     })
      //   }
      //
      // }
      let vm=this
      function changeLonMaxRight(array, index) {
        array.forEach((el, i)=> {
          if(i<index) {
            el[1] = el[1] - 360
            let market = vm.markets.find(a=> a.coordinate[0].toFixed(4) == el[0].toFixed(4) && a.coordinate[1].toFixed(4) == (el[1] + 360).toFixed(4))
            // console.log('market', vm.markets[0].name,  vm.markets[0].coordinate, [el[0], el[1]+360])
            if(market) {
              market.coordinate = el
            }
          }
        })
      }
      function changeLonMaxLeft(array, index) {
        array.forEach((el, i)=> {
          if(i<index) {
            el[1] = el[1] + 360
            let market = vm.markets.find(a=> a.coordinate[0].toFixed(4) == el[0].toFixed(4) && a.coordinate[1].toFixed(4) == (el[1] - 360).toFixed(4))
            if(market) {
              market.coordinate = el
            }
          }
        })
      }
      this.markets = this.markets.reduce((o, i) => {
        if (!o.find(v => v.name == i.name)) {
          o.push(i);
        }
        return o;
      }, []);
      this.polyline.forEach((item, k)=> {
        item.latlngs.forEach((el, index)=> {
          if(index>0) {
            if(item.latlngs[index - 1][1] >= 101 && item.latlngs[index - 1][1] <= 180 && el[1] < 0) {
              this.polyline.forEach((a, f)=> {
                if(f<k) {
                  changeLonMaxRight(a.latlngs, a.latlngs.length)
                } else {
                  return
                }
              })
              changeLonMaxRight(item.latlngs, index)
              return;
            }
            if(item.latlngs[index - 1][1] >= -180 && item.latlngs[index - 1][1] <= -101 && el[1] > 0) {
              console.log('minus')
              this.polyline.forEach((a, f)=> {
                if(f<k) {
                  changeLonMaxLeft(a.latlngs, a.latlngs.length)
                } else {
                  return
                }
              })
              changeLonMaxLeft(item.latlngs, index)
              return;
            }
          }
        })
      })
      this.polyline = JSON.parse(JSON.stringify(this.polyline))
      this.center = this.polyline[0].latlngs[0]


    },

    // async getDistanceRouter(dataAxios) {
    //   if(!dataAxios.piracyOption || !dataAxios.canalPassCode) {
    //     alert('Please fill in all fields')
    //     return null;
    //   }
    //   this.polyline = [];
    //   this.markets = [];
    //   this.totalDistance = 0;
    //   this.totalSecaDistance = 0;
    //   if(Array.isArray(dataAxios.ports) === true) {
    //     for(let i=0; i<dataAxios.ports.length; i++) {
    //       let data = await this.axiosGetDataMap(
    //           {'from_port': dataAxios.ports[i].from_port,
    //             'to_port': dataAxios.ports[i].to_port,
    //             'piracy_option': dataAxios.piracyOption,
    //             'canal_pass_code': dataAxios.canalPassCode,
    //             'use_local_eca': dataAxios.ports[i].has_seca,})
    //       // console.log('data', JSON.parse(JSON.stringify(data)))
    //       this.totalDistance += data.total_distance
    //       this.totalSecaDistance += data.total_seca_distance
    //       this.usedLimit = data.today_used
    //       let section = data.section;
    //       let seca_zone = section[0].waypoint[0].seca
    //       section.forEach(sec=> {
    //         if(sec.waypoint.length) {
    //           this.polyline.push(JSON.parse(JSON.stringify(this.polylineEmpty)))
    //           this.polyline[this.polyline.length-1].color = seca_zone ? '#0FA54E' : '#FF833E'
    //         }
    //         sec.waypoint.forEach(point=> {
    //           if(point.seca === seca_zone) {
    //             this.polyline[this.polyline.length-1].latlngs.push([point.lat, Math.abs(point.lon)])
    //           } else {
    //             this.polyline[this.polyline.length-1].latlngs.push([point.lat, Math.abs(point.lon)])
    //             this.polyline.push(JSON.parse(JSON.stringify(this.polylineEmpty)))
    //             this.polyline[this.polyline.length-1].latlngs.push([point.lat, Math.abs(point.lon)])
    //             seca_zone = point.seca
    //             this.polyline[this.polyline.length-1].color = seca_zone ? '#0FA54E' : '#FF833E'
    //             // console.log(this.polyline[this.polyline.length-1].color)
    //           }
    //         })
    //       })
    //       this.center = this.polyline[0].latlngs[0]
    //       section.forEach(sec=> {
    //         this.markets.push({coordinate: [sec.waypoint[0].lat,Math.abs(sec.waypoint[0].lon)], name: sec.waypoint[0].name})
    //         this.markets.push({coordinate: [sec.waypoint[sec.waypoint.length - 1].lat,Math.abs(sec.waypoint[sec.waypoint.length - 1].lon)], name: sec.waypoint[sec.waypoint.length - 1].name})
    //       })
    //     }
    //
    //   }
    //   this.markets = this.markets.reduce((o, i) => {
    //     if (!o.find(v => v.name == i.name)) {
    //       o.push(i);
    //     }
    //     return o;
    //   }, []);
    //
    // },
    async getDistance() {
      if(!this.fromPort || !this.toPort || !this.piracyOption || !this.canalPassCode) {
        alert('Please fill in all fields')
        return null;
      }
      // console.log('asdasdadasdasdas')
      let data = await this.axiosGetDataMap(
          {'from_port': this.fromPort,
        'to_port': this.toPort,
        'piracy_option': this.piracyOption,
        'canal_pass_code': this.canalPassCode,
        'use_local_eca': this.useLocalEca,})
      // console.log('data', JSON.parse(JSON.stringify(data)))
      this.totalDistance = data.total_distance
      this.totalSecaDistance = data.total_seca_distance
      this.usedLimit = data.today_used
      let section = data.section;
      let seca_zone = section[0].waypoint[0].seca
      this.polyline = [];
      section.forEach(sec=> {
        if(sec.waypoint.length) {
          this.polyline.push(JSON.parse(JSON.stringify(this.polylineEmpty)))
          this.polyline[this.polyline.length-1].color = seca_zone ? '#0FA54E' : '#FF833E'
        }
        sec.waypoint.forEach(point=> {
          if(point.seca === seca_zone) {
            this.polyline[this.polyline.length-1].latlngs.push([point.lat, Math.abs(point.lon)])
          } else {
            this.polyline[this.polyline.length-1].latlngs.push([point.lat, Math.abs(point.lon)])
            this.polyline.push(JSON.parse(JSON.stringify(this.polylineEmpty)))
            this.polyline[this.polyline.length-1].latlngs.push([point.lat, Math.abs(point.lon)])
            seca_zone = point.seca
            this.polyline[this.polyline.length-1].color = seca_zone ? '#0FA54E' : '#FF833E'
            // console.log(this.polyline[this.polyline.length-1].color)
          }
        })
      })
      this.center = this.polyline[0].latlngs[0]
      this.markets = []
      section.forEach(sec=> {
        this.markets.push({coordinate: [sec.waypoint[0].lat,Math.abs(sec.waypoint[0].lon)], name: sec.waypoint[0].name})
        this.markets.push({coordinate: [sec.waypoint[sec.waypoint.length - 1].lat,Math.abs(sec.waypoint[sec.waypoint.length - 1].lon)], name: sec.waypoint[sec.waypoint.length - 1].name})
      })

    }
  },
  async mounted() {
    // this.markets.push({coordinate: [this.exampleRequest.section[0].waypoint[0].lat, this.exampleRequest.section[0].waypoint[0].lon], name: this.exampleRequest.section[0].waypoint[0].name})
    // this.markets.push({coordinate: [this.exampleRequest.section[0].waypoint[this.exampleRequest.section[0].waypoint.length-1].lat, this.exampleRequest.section[0].waypoint[this.exampleRequest.section[0].waypoint.length-1].lon], name: this.exampleRequest.section[0].waypoint[this.exampleRequest.section[0].waypoint.length-1].name})

  }
}
</script>

<style scoped lang="scss">
.wrap-page {
  position: relative;
  //width: 100vw;
  //height: calc(100vh - 56px);
}

/deep/ {
  .someExtraClass {
    position: relative;
    .mark {
      width: 13px !important;
      height: 13px !important;
      margin-top: -41px !important;
      margin-left: -10px !important;
    }
    svg {
      //margin-bottom: 2px;
    }
    img {
      width: 18px !important;
      height: 18px !important;
      margin-top: -26px !important;
      margin-left: -3px !important;
    }
    p {
      position: absolute;
      top: -14px;
      transform: translate(-50%, -100%);
      left: 50%;
      white-space: nowrap;
    }
  }
}
.parameters {
  padding: 10px;
  box-sizing: border-box;
}
.button-blue-border {
  margin-left: 10px;
}
.limit {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.error {
  background-color: rgba(256,256,256, 0.6);
}
</style>