<template>
  <div class="" v-click-outside="hide">
    <div class="position-relative">
      <svg v-on:click="show = !show" class="cursor-pointer option" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.44 10.5576C7.23529 10.5576 7.88 11.2023 7.88 11.9976C7.88 12.7928 7.23529 13.4376 6.44 13.4376C5.64471 13.4376 5 12.7928 5 11.9976C5 11.2023 5.64471 10.5576 6.44 10.5576Z" fill="#9AA3B0"/>
        <circle cx="12.0001" cy="11.9975" r="1.44" transform="rotate(-90 12.0001 11.9975)" fill="#9AA3B0"/>
        <path d="M17.5601 10.5575C18.3554 10.5575 19.0001 11.2022 19.0001 11.9975C19.0001 12.7928 18.3554 13.4375 17.5601 13.4375C16.7648 13.4375 16.1201 12.7928 16.1201 11.9975C16.1201 11.2022 16.7648 10.5575 17.5601 10.5575Z" fill="#9AA3B0"/>
      </svg>
      <div class="list pt4 pb4 bgc-modal border-radius-4 position-absolute t24 r0 w120 z-100" v-if="show">
        <button v-on:click="()=>{$emit('unarchive'); show =false}" class="h32 pl8 fz13 fw500 text-dark w100Proc box-border d-flex align-items-center justify-start">
          <svg class="pr8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/>
            <path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#2C2C2C"/>
          </svg>
          Unarchive
        </button>
        <button v-on:click="()=>{$emit('delete'); show =false}" class="h32 pl8 fz13 fw500 text-dark w100Proc box-border d-flex align-items-center justify-start">
          <svg class="pr8" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
          </svg>
          Delete
        </button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "mailing-drop-archive",
  data() {
    return {
      show: false,
    }
  },
  methods: {
    hide() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  button {
    &:hover {
      background-color: var(--color-hover-list);
    }
  }
}
</style>