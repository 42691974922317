<template>
  <div class="my-custom-select-wrapper">
    <multiselect v-model="selected" label="name" track-by="name" :options="valueList" :multiple="multiple"
      :taggable="false" :limit="1" :limit-text="limitText" :show-labels="false" @input="$emit('selected', selected)">
      <template slot="tag" slot-scope="{ option }">
        <span class="selected">
          <span>{{ option.name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__name">
          <div class="avatar" v-if="!props.option.avatar"></div>
          <div class="avatar" v-else :style="{ 'background-image': `url(${props.option.avatar})` }"></div>
          <span class="text">{{ props.option.name }}</span>
          
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "myCustom-select",
  components: { Multiselect },
  props: {
    valueList: Array,
    defaultValue: Array,
    multiple: {
      type: Boolean,
      default: true
    },
    clear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
      options: [],
    };
  },
  methods: {
    limitText(count) {
      return `+ ${count}`;
    },
  },
  watch: {
    valueList: function (val) {
      this.options = val;
    },
    defaultValue: function (val) {
      this.selected = val
    },
    clear: function (val) {
      if (val) this.selected = []
    }
  },
  mounted() {


    // if(this.defaultValue){
    //   this.selected = this.defaultValue;
    // }
  }
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z' fill='%23DEE3EA'/%3E%3C/svg%3E%0A");
  margin-right: 6px;
  background-size: cover;
}

.option__name {
  // width: 150px;
  width: calc(100% - 50px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  // width: 152px;
  .text{
    // border: 1px solid red;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.sidebar-select{
  .option__name{
    width: calc(100% - 15px);
  }
}

.multiselect__option--selected {
  position: relative;
  display: flex;
  align-items: center;

  .option__name:after {
    content: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z' fill='%23319FEF'/%3E%3C/svg%3E ");
    position: absolute;
    background: none;
    right: 6px;
  }
}

.my-custom-select-wrapper {
  .selected {
    display: inline-block;
    transform: translateY(3px);
  }
}
</style>
<style lang="scss">
#app {
  .my-custom-select-wrapper {
    .multiselect__strong {
      display: inline-flex;
      border-radius: 50%;
      border: 1px solid black;
      align-items: center;
      justify-content: center;
      padding: 4px 4px;
      font-size: 10px;
      line-height: 100%;
    }
    // .multiselect__content-wrapper{
    //   max-width: 100%;
    //   border: 1px solid blue;
    // }
    .multiselect__content{
      max-width: 100%;
    }
  }
}
</style>