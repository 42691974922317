<template>
  <div 
    class="vessel_owners__table_wide bg-white"
    :class="{
      'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
    }"
  >
    <div class="border-b border-r p-x-2 p-y-1 fw500 relative" :class="{'bg-gray-light text-gray-text': !row.additional_info.is_row}">
      <button
        type="button"
        class="flex items-center cursor-pointer text-blue-dark fw500"
        @click="$emit('on-delete')"
      >
        #{{1+index}}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"
            fill="#094172"
          />
        </svg>
      </button>
      <switcher 
        class="switcher_owners-row"
        :id="'charterers-'+index"
        v-model="row.additional_info.is_row"
      />
    </div>
    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !row.additional_info.is_row}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
      <get-trials v-if="$route.params.subId" trialKey="SUBLET_Charterers Expenses"/>
      <get-trials v-else trialKey="Charterers Expenses"/>
    </div>
    <div class="border-b border-r colspan-3-5" :class="{'bg-gray-light text-gray-text': !row.additional_info.is_row}">
      <input
        class="p-x-2 p-y-2"
        :class="{'text-gray-text': !row.additional_info.is_row}"
        type="text"
        maxlength="255"
        :placeholder="'Write a title for charterers expenses #'+(1+index)"
        v-model="row.additional_info.title"
        :disabled="!row.additional_info.is_row"
      />
    </div>
    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !row.additional_info.is_row}">
      <input-format-number
        v-if="row.additional_info.trs_type == 'revenues'"
        v-model="row.debit"
        :inputClass="'p-x-2 p-y-2 '+(!row.additional_info.is_row?'text-gray-text':'')"
        placeholder="Enter the amount"
        :disabled="!row.additional_info.is_row"
      ></input-format-number>
    </div>
    <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !row.additional_info.is_row}">
      <input-format-number
        v-if="row.additional_info.trs_type == 'expenses'"
        v-model="row.credit"
        :inputClass="'p-x-2 p-y-2 '+(!row.additional_info.is_row?'text-gray-text':'')"
        placeholder="Enter the amount"
        :disabled="!row.additional_info.is_row"
      ></input-format-number>
    </div>
    <div class="border-b border-r">
      <trs-type-btn :additional="row.additional_info" active="revenues" @on-click="changeTRS"/>
    </div>
    <div class="border-b">
      <trs-type-btn :additional="row.additional_info" active="expenses" @on-click="changeTRS"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import trsTypeBtn from "@/components/vessels/owners/edit/trsTypeBtn";
import getTrials from '@/components/vessels/owners/edit/getTrials'
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import Switcher from '@/components/reuse/switcher.vue'

export default {
  props: {
    row: Object,
    index: Number
  },
  components: {
    trsTypeBtn,
    getTrials,
    inputFormatNumber,
    Switcher
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    changeTRS() {
      if(this.row.additional_info.trs_type == 'revenues'){
        this.row.debit = this.row.credit
        this.row.credit = ''
      }else{
        this.row.credit = this.row.debit
        this.row.debit = ''
      }
    },
    checkIsRow() {
      if(!Object.prototype.hasOwnProperty.call(this.row.additional_info, 'is_row')){
        this.$set(this.row.additional_info, 'is_row', true)
      }
    }
  },
  mounted() {
    this.checkIsRow()
  }
};
</script>

<style>
</style>