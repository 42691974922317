import axios from 'axios'
export default {
    actions: {
        async axiosGetParsedVesselById(ctx, id) {
            ctx.commit('showLoad')
            return await axios.get(process.env.VUE_APP_URL+'/search/ship/template/' + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(res=> {
                ctx.state.parsedVesselData = res.data
                ctx.commit('hideLoad')
            }).catch(()=> {
                ctx.commit('hideLoad')

            })
        },
        async axiosGetHatchParsedVesselById(ctx, id) {
            return await axios.get(process.env.VUE_APP_URL+'/search/ship/template/hatch/'+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(res=> {
                return res.data
            })
        },
        async axiosCreateHatch(ctx, data) {
            return await axios.post(process.env.VUE_APP_URL+'/search/ship/template/hatch/', data,{
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(res=> {
                return res.data;
            })
        },
        async axiosChangeHatch(ctx, data) {
            return await axios.patch(process.env.VUE_APP_URL+'/search/ship/template/hatch/' + data.id, data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            })
        },
        async axiosDeleteHatch(ctx, id) {
            return await axios.delete(process.env.VUE_APP_URL+'/search/ship/template/hatch/' + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            })
        }
    },
    mutations: {
        setParsedVesselData(state, data) {
            state.parsedVesselData = data;
        },
        setActiveFuelType(state, data) {
            state.activeFuelType = data
        },
    },
    state: {
        activeFuelType: 'full',
        parsedVesselData: null,
    },
    getters: {

    }
}