<template>
    <section class="employees">
      <div class="modal-add-user" v-if="getModalCreateUser.status">
        <span class="fz13 text-dark">
          New employee
        </span>
        <span class="fz13 text-blue">
 {{getModalCreateUser.name}}
        </span>
        <span class="fz13 text-dark">
           successfully created
        </span>
        <button v-on:click="hideModalUser">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
            <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
          </svg>
        </button>
      </div>
        <div class="container">
            <div class="top-panel">
                <div class="part1">
                    <p class="fz20 fw500 text-dark">Employees</p>
                    <router-link to="/employees/add-employees-step-1" class="button-blue-border fw500 fz13">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/>
                        </svg>
                        Add employee
                    </router-link>
                    <router-link to="/employees/archive" class="button-dark-link button-archive fz13 fw500 text-dark">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Archive
                    </router-link>
                </div>
                <div class="part2">
                    <by-department @changeDepartmentTrue="departmentsView=true" @changeDepartmentFalse="departmentsView=false"></by-department>
                    <all-department @changeButton="setButtonDepartmentsMobile" ref="selectDepartment" class="filter"></all-department>
                    <view-table @changeView="changeView" ref="view" class="filter"></view-table>
                    <filter-employees-mobile @changeView="changeView" @changeButton="setButtonDepartments" ref="viewMobile" class="filter-mobile"></filter-employees-mobile>
                </div>
                <div class="part3">
<!--                  <button class="open-modal search-employees-modal" v-on:click="showModalSearchEmployees">-->
<!--                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M19.154 18.2908L14.9832 14.12C15.7911 13.1221 16.2775 11.854 16.2775 10.4729C16.2775 7.27196 13.673 4.66742 10.472 4.66742C7.27101 4.66742 4.6665 7.27193 4.6665 10.4729C4.6665 13.6739 7.27104 16.2784 10.472 16.2784C11.8531 16.2784 13.1212 15.792 14.1191 14.9841L18.2899 19.1549C18.409 19.274 18.5655 19.3339 18.7219 19.3339C18.8784 19.3339 19.0348 19.274 19.154 19.1549C19.3929 18.9159 19.3929 18.5297 19.154 18.2908ZM10.472 15.0562C7.94447 15.0562 5.88872 13.0004 5.88872 10.4729C5.88872 7.94536 7.94447 5.88961 10.472 5.88961C12.9996 5.88961 15.0553 7.94536 15.0553 10.4729C15.0553 13.0004 12.9995 15.0562 10.472 15.0562Z" fill="#2C2C2C"/>-->
<!--                    </svg>-->
<!--                  </button>-->
                    <div class="search-input">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.154 18.2908L14.9832 14.12C15.7911 13.1221 16.2775 11.854 16.2775 10.4729C16.2775 7.27196 13.673 4.66742 10.472 4.66742C7.27101 4.66742 4.6665 7.27193 4.6665 10.4729C4.6665 13.6739 7.27104 16.2784 10.472 16.2784C11.8531 16.2784 13.1212 15.792 14.1191 14.9841L18.2899 19.1549C18.409 19.274 18.5655 19.3339 18.7219 19.3339C18.8784 19.3339 19.0348 19.274 19.154 19.1549C19.3929 18.9159 19.3929 18.5297 19.154 18.2908ZM10.472 15.0562C7.94447 15.0562 5.88872 13.0004 5.88872 10.4729C5.88872 7.94536 7.94447 5.88961 10.472 5.88961C12.9996 5.88961 15.0553 7.94536 15.0553 10.4729C15.0553 13.0004 12.9995 15.0562 10.472 15.0562Z" fill="#2C2C2C"/>
                        </svg>
                        <input type="text" placeholder="Search by employees (keyword search)" @input="searchByEmployees" v-model="searchText">
                    </div>
                </div>
            </div>
            <div class="text-no-data fz13 text-gray" v-if="(getEmployees !== null ? getEmployees.data.results.length === 0 : false) ">
              <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M158.5 36C136.685 36 119 53.6848 119 75.5C119 97.3152 136.685 115 158.5 115C170.662 115 181.54 109.505 188.787 100.858C194.538 93.9961 198 85.1536 198 75.5C198 53.6848 180.315 36 158.5 36ZM117 75.5C117 52.5802 135.58 34 158.5 34C181.42 34 200 52.5802 200 75.5C200 85.3113 196.594 94.3295 190.901 101.433L214.702 125.234L214.707 125.239C215.098 125.63 215.098 126.263 214.707 126.653C214.317 127.044 213.683 127.044 213.293 126.653L213.288 126.648L189.609 102.969C182.006 111.572 170.886 117 158.5 117C135.58 117 117 98.4198 117 75.5Z" fill="#DEE3EA"/>
                <path d="M40.4095 81.467C40.6053 81.3598 40.8517 81.2176 41.0197 81.068C42.6159 79.6466 44.7272 79.6414 46.3293 81.068C47.3861 82.009 48.7519 82.009 49.8086 81.068C50.5928 80.3698 51.5107 80.0007 52.4634 80.0007C53.4161 80.0007 54.3341 80.3698 55.1182 81.068C56.1751 82.009 57.5408 82.009 58.5976 81.068C60.1938 79.6467 62.305 79.6414 63.9071 81.068C64.0597 81.2038 64.2784 81.3444 64.5757 81.498C64.9426 81.6875 65.102 82.1722 64.9318 82.5806C64.7615 82.989 64.3261 83.1665 63.9591 82.977C63.5482 82.7648 63.2409 82.5628 62.992 82.3411C61.9352 81.4001 60.5695 81.4 59.5126 82.3411C57.9164 83.7625 55.8052 83.7677 54.2031 82.3411C53.1463 81.4001 51.7806 81.4 50.7237 82.3411C49.1274 83.7625 47.0162 83.7677 45.4141 82.3411C44.3573 81.4001 42.9916 81.4 41.9347 82.3411C41.6575 82.588 41.3348 82.7774 41.0557 82.9302C40.6927 83.1289 40.2537 82.9623 40.0753 82.5583C39.8969 82.1542 40.0464 81.6657 40.4095 81.467Z" fill="#DEE3EA"/>
                <path d="M48.4095 88.467C48.6053 88.3598 48.8517 88.2176 49.0197 88.068C50.6159 86.6466 52.7272 86.6414 54.3293 88.068C55.3861 89.009 56.7519 89.009 57.8086 88.068C58.5928 87.3698 59.5107 87.0007 60.4634 87.0007C61.4161 87.0007 62.3341 87.3698 63.1182 88.068C64.1751 89.009 65.5408 89.009 66.5976 88.068C68.1938 86.6467 70.305 86.6414 71.9071 88.068C72.0597 88.2038 72.2784 88.3444 72.5757 88.498C72.9426 88.6875 73.102 89.1722 72.9318 89.5806C72.7615 89.989 72.3261 90.1665 71.9591 89.977C71.5482 89.7648 71.2409 89.5628 70.992 89.3411C69.9352 88.4001 68.5695 88.4 67.5126 89.3411C65.9164 90.7625 63.8052 90.7677 62.2031 89.3411C61.1463 88.4001 59.7806 88.4 58.7237 89.3411C57.1274 90.7625 55.0162 90.7677 53.4141 89.3411C52.3573 88.4001 50.9916 88.4 49.9347 89.3411C49.6575 89.588 49.3348 89.7774 49.0557 89.9302C48.6927 90.1289 48.2537 89.9623 48.0753 89.5583C47.8969 89.1542 48.0464 88.6657 48.4095 88.467Z" fill="#DEE3EA"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M111 127.2C111.663 127.2 112.2 126.663 112.2 126C112.2 125.337 111.663 124.8 111 124.8C110.337 124.8 109.8 125.337 109.8 126C109.8 126.663 110.337 127.2 111 127.2ZM111 129C112.657 129 114 127.657 114 126C114 124.343 112.657 123 111 123C109.343 123 108 124.343 108 126C108 127.657 109.343 129 111 129Z" fill="#DEE3EA"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M207 19.2C207.663 19.2 208.2 18.6627 208.2 18C208.2 17.3373 207.663 16.8 207 16.8C206.337 16.8 205.8 17.3373 205.8 18C205.8 18.6627 206.337 19.2 207 19.2ZM207 21C208.657 21 210 19.6569 210 18C210 16.3431 208.657 15 207 15C205.343 15 204 16.3431 204 18C204 19.6569 205.343 21 207 21Z" fill="#DEE3EA"/>
                <path d="M221.409 55.467C221.605 55.3598 221.852 55.2176 222.02 55.068C223.616 53.6466 225.727 53.6414 227.329 55.068C228.386 56.009 229.752 56.009 230.809 55.068C231.593 54.3698 232.511 54.0007 233.463 54.0007C234.416 54.0007 235.334 54.3698 236.118 55.068C237.175 56.009 238.541 56.009 239.598 55.068C241.194 53.6467 243.305 53.6414 244.907 55.068C245.06 55.2038 245.278 55.3444 245.576 55.498C245.943 55.6875 246.102 56.1722 245.932 56.5806C245.762 56.989 245.326 57.1665 244.959 56.977C244.548 56.7648 244.241 56.5628 243.992 56.3411C242.935 55.4001 241.57 55.4 240.513 56.3411C238.916 57.7625 236.805 57.7677 235.203 56.3411C234.146 55.4001 232.781 55.4 231.724 56.3411C230.127 57.7625 228.016 57.7677 226.414 56.3411C225.357 55.4001 223.992 55.4 222.935 56.3411C222.657 56.588 222.335 56.7774 222.056 56.9302C221.693 57.1289 221.254 56.9623 221.075 56.5583C220.897 56.1542 221.046 55.6657 221.409 55.467Z" fill="#DEE3EA"/>
                <path d="M230.409 61.467C230.605 61.3598 230.852 61.2176 231.02 61.068C232.616 59.6466 234.727 59.6414 236.329 61.068C237.386 62.009 238.752 62.009 239.809 61.068C240.593 60.3698 241.511 60.0007 242.463 60.0007C243.416 60.0007 244.334 60.3698 245.118 61.068C246.175 62.009 247.541 62.009 248.598 61.068C250.194 59.6467 252.305 59.6414 253.907 61.068C254.06 61.2038 254.278 61.3444 254.576 61.498C254.943 61.6875 255.102 62.1722 254.932 62.5806C254.762 62.989 254.326 63.1665 253.959 62.977C253.548 62.7648 253.241 62.5628 252.992 62.3411C251.935 61.4001 250.57 61.4 249.513 62.3411C247.916 63.7625 245.805 63.7677 244.203 62.3411C243.146 61.4001 241.781 61.4 240.724 62.3411C239.127 63.7625 237.016 63.7677 235.414 62.3411C234.357 61.4001 232.992 61.4 231.935 62.3411C231.657 62.588 231.335 62.7774 231.056 62.9302C230.693 63.1289 230.254 62.9623 230.075 62.5583C229.897 62.1542 230.046 61.6657 230.409 61.467Z" fill="#DEE3EA"/>
                <path d="M260.409 99.467C260.605 99.3598 260.852 99.2176 261.02 99.068C262.616 97.6466 264.727 97.6414 266.329 99.068C267.386 100.009 268.752 100.009 269.809 99.068C270.593 98.3698 271.511 98.0007 272.463 98.0007C273.416 98.0007 274.334 98.3698 275.118 99.068C276.175 100.009 277.541 100.009 278.598 99.068C280.194 97.6467 282.305 97.6414 283.907 99.068C284.06 99.2038 284.278 99.3444 284.576 99.498C284.943 99.6875 285.102 100.172 284.932 100.581C284.762 100.989 284.326 101.167 283.959 100.977C283.548 100.765 283.241 100.563 282.992 100.341C281.935 99.4001 280.57 99.4 279.513 100.341C277.916 101.762 275.805 101.768 274.203 100.341C273.146 99.4001 271.781 99.4 270.724 100.341C269.127 101.762 267.016 101.768 265.414 100.341C264.357 99.4001 262.992 99.4 261.935 100.341C261.657 100.588 261.335 100.777 261.056 100.93C260.693 101.129 260.254 100.962 260.075 100.558C259.897 100.154 260.046 99.6657 260.409 99.467Z" fill="#DEE3EA"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73 46.2C74.7673 46.2 76.2 44.7673 76.2 43C76.2 41.2327 74.7673 39.8 73 39.8C71.2327 39.8 69.8 41.2327 69.8 43C69.8 44.7673 71.2327 46.2 73 46.2ZM73 48C75.7614 48 78 45.7614 78 43C78 40.2386 75.7614 38 73 38C70.2386 38 68 40.2386 68 43C68 45.7614 70.2386 48 73 48Z" fill="#DEE3EA"/>
              </svg>
              <p class="fz18 fw500 text-dark">No results were found for your search</p>
            </div>
            <div class="cardView" v-if="cardsView && !departmentsView && getEmployees !== null">
                <div class="card" v-for="(employees, i) in getEmployees.data.results" :key="i">
                    <p class="name fz16 text-dark fw500">
                        {{employees.first_name}}
                    </p>
                    <p class="name name2 fz16 text-dark fw500">
                      {{ employees.last_name }}
                    </p>
                    <p class="role fz14 text-dark-gray">{{ employees.departmentName }}</p>
                    <a :href="'mailto:' + employees.email" class="email fz14 text-dark-gray">{{ employees.email }}</a>
                    <p class="phone text-dark-gray fz13">{{ employees.phone_number }}</p>
                    <p class="onlineTime fz12 text-gray" :class="{'online': employees.activity === 'Online'}">{{ employees.activity }}</p>
                    <img v-if="employees.avatar !== null" :src="getUrlForAvatar(employees.avatar)" class="img-profile" alt="">
                    <svg v-if="employees.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="80" cy="80" r="80" fill="white"/>
                    <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                    </svg>
                  <button-card @archiveUser="showModalArchiveEmit(employees)" :id="employees.id" class="button-card"></button-card>
                </div>
            </div>
          <div class="tableView" v-if="!cardsView && !departmentsView">
            <div class="card" :class="{'border-for-one-card': getEmployees.data.results.length === 1}" v-for="(employees, i) in getEmployees.data.results" :key="i">
              <p class="name fz16 text-dark fw500">
                {{employees.first_name}} {{ employees.last_name }}
              </p>

              <p class="role fz14 text-dark-gray">{{ employees.departmentName }}</p>
              <a :href="'mailto:' + employees.email" class="email fz14 text-dark-gray">{{ employees.email }}</a>
              <p class="phone text-dark-gray fz13">{{ employees.phone_number }}</p>
              <p class="onlineTime fz12 text-gray" :class="{'online': employees.activity === 'Online'}">{{ employees.activity }}</p>
              <img v-if="employees.avatar !== null" :src="getUrlForAvatar(employees.avatar)" class="img-profile" alt="">
              <svg v-if="employees.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="80" cy="80" r="80" fill="white"/>
                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
              </svg>
              <button-card @archiveUser="showModalArchiveEmit(employees)" :id="employees.id" class="button-card"></button-card>
            </div>
          </div>
          <div class="content-wrap" v-if="cardsView && departmentsView">
            <div class="content" v-for="(d, j) in getEmployeesSortByDepartments" :key="j">
              <p class="fz13 fw500 text-gray" v-if="d.array.length !== 0">{{ d.title }}</p>
              <div class="wrap-cards" v-if="d.array.length !== 0">
                <div class="cardView">
                  <div class="card" :class="{'border-for-one-card': d.array.length === 1}" v-for="(employees, i) in d.array" :key="i">
                    <p class="name fz16 text-dark fw500">
                      {{employees.first_name}}
                    </p>
                    <p class="name name2 fz16 text-dark fw500">
                      {{ employees.last_name }}
                    </p>
                    <p class="role fz14 text-dark-gray">{{ employees.departmentName }}</p>
                    <a :href="'mailto:' + employees.email" class="email fz14 text-dark-gray">{{ employees.email }}</a>
                    <p class="phone text-dark-gray fz13">{{ employees.phone_number }}</p>
                    <p class="onlineTime fz12 text-gray" :class="{'online': employees.activity === 'Online'}">{{ employees.activity }}</p>
                    <img v-if="employees.avatar !== null" :src="getUrlForAvatar(employees.avatar)" class="img-profile" alt="">
                    <svg v-if="employees.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="80" cy="80" r="80" fill="white"/>
                      <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                    </svg>
                    <button-card @archiveUser="showModalArchiveEmit(employees)" :id="employees.id" class="button-card"></button-card>
                  </div>
                </div>
              </div>
<!--              <p class="no-data fz13 text-gray" v-else>No data</p>-->
            </div>
          </div>
          <div class="content-wrap" v-if="!cardsView && departmentsView">
            <div class="content" v-for="(d, j) in getEmployeesSortByDepartments" :key="j">
              <p class="fz13 fw500 text-gray" v-if="d.array.length !== 0">{{ d.title }}</p>
              <div class="wrap-cards" v-if="d.array.length !== 0">
                <div class="tableView">
                  <div class="card" :class="{'border-for-one-card': d.array.length === 1}" v-for="(employees, i) in d.array" :key="i">
                    <p class="name fz16 text-dark fw500">
                      {{employees.first_name}} {{ employees.last_name }}
                    </p>
                    <p class="role fz14 text-dark-gray">{{ employees.departmentName }}</p>
                    <a :href="'mailto:' + employees.email" class="email fz14 text-dark-gray">{{ employees.email }}</a>
                    <p class="phone text-dark-gray fz13">{{ employees.phone_number }}</p>
                    <p class="onlineTime fz12 text-gray" :class="{'online': employees.activity === 'Online'}">{{ employees.activity }}</p>
                    <img v-if="employees.avatar !== null" :src="getUrlForAvatar(employees.avatar)" class="img-profile" alt="">
                    <svg v-if="employees.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="80" cy="80" r="80" fill="white"/>
                      <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                    </svg>
                    <button-card @archiveUser="showModalArchiveEmit(employees)" :id="employees.id" class="button-card"></button-card>
                  </div>
                </div>
              </div>
<!--              <p class="no-data fz13 text-gray" v-else>No data</p>-->
            </div>
          </div>
        </div>
      <search-employees-modal ref="modalSearchEmployees"></search-employees-modal>
      <transition name="fade">
        <div class="modal-archive-wrap" v-if="showModalArchive">
          <div class="filter-modal" v-on:click="showModalArchive = false"></div>
          <div class="modal-archive">
            <div class="top-block">
              <p class="fw500 fz16 text-dark">Archive the employee</p>
              <button class="button-close-modal" v-on:click="showModalArchive = false">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
                  <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
                </svg>
              </button>
            </div>
            <p class="fz14 text-dark">Are you sure you want to archive this employee?</p>
            <div class="block-buttons">
              <button class="blue-button-text fz14" v-on:click="showModalArchive = false">Cancel</button>
              <button class="blue-button fz14 fw500 buttonW100 h32" v-on:click="archiveOrUnArchiveUser">Archive</button>
            </div>
          </div>
        </div>
      </transition>
<!--      {{getSocket}}-->
<!--      {{getDepartmentsForEmployees}}-->

    </section>
</template>

<script>
    import ByDepartment from "../../components/employees/by-department";
    import AllDepartment from "../../components/employees/all-department";
    import ViewTable from "../../components/employees/view";
    import ButtonCard from "../../components/employees/button-card";
    import {mapGetters, mapActions, mapMutations} from 'vuex'
    import SearchEmployeesModal from "@/components/employees/search-employees-modal";
    import FilterEmployeesMobile from "@/components/employees/filter-employees-mobile";
    export default {
        name: "employees",
      computed: mapGetters(["getDepartmentsForEmployees", "getSocket","getEmployees", "getModalCreateUser", "getButtonsDepartments", "getEmployeesSortByDepartments", "getToken"]),
        components: {
          FilterEmployeesMobile,
          SearchEmployeesModal,
            ButtonCard,
            ViewTable,
            AllDepartment,
            ByDepartment
        },
      data() {
          return {
            cardsView: true,
            departmentsView: true,
            searchText: "",
            showModalArchive: false,
            userDataForArchive: null,
          }
      },
      methods: {
          ...mapActions(["axiosChangeArchiveUser", "axiosGetEmployees", "getAxiosDepartmentsEmployeesPage"]),
        ...mapMutations(['showLoad', 'hideLoad', "setDepartmentsEmployees", "setModalCreateUser", "modifyEmployeeAddDepartment", "searchForEmployees", "getActivitySocket"]),
        getUrlForAvatar(link) {
          if(link.indexOf(this.getURL()) >= 0) {
            return link;
          }
          return this.getURL() + link;
        },
        getURL() {
          return process.env.VUE_APP_URL_SIMPLE
        },
        hideModalUser() {
          this.setModalCreateUser({status: false, name: ""});
        },
        showModalArchiveEmit(user) {
            this.userDataForArchive = user;
            this.showModalArchive = true;
        },
        archiveOrUnArchiveUser() {
          this.showModalArchive = false;
          this.axiosChangeArchiveUser({id: this.userDataForArchive.id, department: this.userDataForArchive.department, email: this.userDataForArchive.email, first_name: this.userDataForArchive.first_name, last_name: this.userDataForArchive.last_name, phone_number: this.userDataForArchive.phone_number, is_active: !this.userDataForArchive.is_active}).then(this.refreshData);
          this.userDataForArchive = null;
          },
        showModalSearchEmployees() {
            this.$refs.modalSearchEmployees.showModal();
        },
        searchByEmployees() {
          this.searchForEmployees(this.searchText);
          this.$refs.selectDepartment.refresh();
          this.$refs.viewMobile.setButtonDepartments(0);
        },
        changeView() {
          this.cardsView = !this.cardsView;
          if(this.cardsView) {
            this.$refs.view.setButton(0);
            this.$refs.viewMobile.setButtonView(0);
          } else {
            this.$refs.view.setButton(1);
            this.$refs.viewMobile.setButtonView(1);
          }
        },
        setButtonDepartments(index) {
            this.$refs.selectDepartment.setButtonDep(index);
            this.searchText = '';
        },
        setButtonDepartmentsMobile(index) {
          this.$refs.viewMobile.setButtonDepartments(index);
          this.searchText = '';
        },
        async refreshData() {
          this.showLoad()
          await this.axiosGetEmployees().then(()=> {
            // console.log("v0")
            if(this.getDepartmentsForEmployees === null) {
              // console.log("v-1")
              console.log("DepartmentReq")
              this.getAxiosDepartmentsEmployeesPage().then(()=> {
                this.modifyEmployeeAddDepartment();
                this.hideLoad()
              })
            } else {
              // console.log("v1")
              this.setDepartmentsEmployees(this.getDepartmentsForEmployees)
              // console.log("v2")
              this.modifyEmployeeAddDepartment();
              this.hideLoad()
            }
          });
        },
      },
      updated() {
          //РАБОТАЕТ СОКЕТ В РЕЖИМЕ РЕАЛЬНОГО ВРЕМЕНИ
        // // console.log("UPDATE")
        this.getActivitySocket({name: this.$route.name, token: this.getToken});
      },

      mounted() {
        // this.refreshData();
        // // console.log("EMPLOYEES")


      },
      beforeMount() {
        this.refreshData();

      },
      watch: {
        getDepartmentsForEmployees: function () {
          this.setDepartmentsEmployees(this.getDepartmentsForEmployees)
          this.modifyEmployeeAddDepartment();
        }
      }
    }
</script>

<style scoped lang="scss">
    @import "employees";
    .dart-mode {
      .button-archive {
        color: #FFFFFF;
        svg {
          path {
            fill: transparent !important;
            stroke: #FFF
          }
        }
      }
    }

</style>
