<template>
  <div class="relative z-10" v-click-outside="close">
    <div class="block-selected position-relative d-flex align-items-center" v-on:click="showList = true">
      <input type="text" :disabled="true">
      <svg class="svg" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.72419 4.39844C2.45691 4.39844 2.32306 4.72158 2.51205 4.91057L5.7878 8.18631C5.90496 8.30346 6.0949 8.30346 6.21206 8.18631L9.48779 4.91057C9.67678 4.72158 9.54293 4.39844 9.27566 4.39844H2.72419Z" fill="#2C2C2C"/>
      </svg>
      <div class="block-view">
        <p v-if="!localValue.length && !localEmails.length && !focusActive" class="fz13 placeholder">Select recipients or enter an email addresses</p>

        <div class="click-zone" v-on:click="focusText">

        </div>
        <p v-for="(u, i) in localValue" :key="i" class="selected-name fz14 text-dark position-relative z-10">
          {{u.name}}
          <svg v-on:click="select(u)" class="cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.53549 9.09341L6.94283 6.50076L9.53558 3.90801C9.79573 3.64785 9.79573 3.22542 9.53549 2.96518C9.27533 2.70502 8.8529 2.70502 8.59274 2.96518L6 5.55792L3.40726 2.96518C3.1471 2.70502 2.72467 2.70502 2.46451 2.96518C2.20427 3.22542 2.20427 3.64785 2.46442 3.90801L5.05717 6.50076L2.46451 9.09341C2.20427 9.35366 2.20427 9.77609 2.46442 10.0362C2.72467 10.2965 3.1471 10.2965 3.40734 10.0362L6 7.44359L8.59266 10.0362C8.8529 10.2965 9.27533 10.2965 9.53558 10.0362C9.79573 9.77609 9.79573 9.35366 9.53549 9.09341Z" fill="#2C2C2C"/>
          </svg>
        </p>
        <p v-for="(u, i) in localEmails" :key="i+'324234ss'" class="selected-name fz14 text-dark position-relative z-10">
          {{u}}
          <svg v-on:click="selectEmail(u)" class="cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.53549 9.09341L6.94283 6.50076L9.53558 3.90801C9.79573 3.64785 9.79573 3.22542 9.53549 2.96518C9.27533 2.70502 8.8529 2.70502 8.59274 2.96518L6 5.55792L3.40726 2.96518C3.1471 2.70502 2.72467 2.70502 2.46451 2.96518C2.20427 3.22542 2.20427 3.64785 2.46442 3.90801L5.05717 6.50076L2.46451 9.09341C2.20427 9.35366 2.20427 9.77609 2.46442 10.0362C2.72467 10.2965 3.1471 10.2965 3.40734 10.0362L6 7.44359L8.59266 10.0362C8.8529 10.2965 9.27533 10.2965 9.53558 10.0362C9.79573 9.77609 9.79573 9.35366 9.53549 9.09341Z" fill="#2C2C2C"/>
          </svg>
        </p>
        <p :contenteditable="true" ref="inputText" class="fz13 text-dark position-relative mt4 z-10" @input="inputText" @focus="()=>{focusActive = true; text = ''}" @blur="blurText" v-on:keydown.enter="createEmail"></p>
      </div>

    </div>
    <div class="list" v-if="showList">
      <button class="fz14 text-dark w100Proc" :disabled="true" v-if="!contacts.length">List is empty</button>
      <button class="fz14 text-dark w100Proc overflow-text-dots" v-for="user in contacts" :key="user.id" v-on:click="select(user)">
        {{user.name}}
        <svg v-if="localValue.find(a=>a.id === user.id)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  name: "contact-list",
  props: {
    value: [Array, Object],
    emails: {
      type: Array,
      default: function () {
        return []
      },
    },
    type: {
      type: String,
      default: 'create'
    }
  },
  watch: {
    value: function () {
      this.localValue = JSON.parse(JSON.stringify(this.value))
    },
    emails: function () {
      this.localEmails = JSON.parse(JSON.stringify(this.emails))
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState({
      contacts: state => state.mailling.contactsList
    })
  },
  data() {
    return {
      localValue: [],
      localEmails: [],
      text: '',
      focusActive: false,
      showList: false,
    }
  },
  methods: {

    ...mapActions(['axiosGetMailingContacts']),
    ...mapMutations(['addTip']),
    close() {
      this.showList = false
    },
    clearText() {
      this.$refs.inputText.innerText = ''
    },
    focusText() {
      this.$refs.inputText.focus()
      // this.$refs.inputText.selectionStart =  this.$refs.inputText.length;
      const range = document.createRange();
      range.selectNodeContents(this.$refs.inputText);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    },
    select(user) {
      if(this.localValue.find(el=> el.id === user.id)) {
        this.localValue = this.localValue.filter(el=> el.id !== user.id)
      } else {
        this.localValue.push(user)
      }
      this.$emit('input', this.localValue)
    },
    inputText(e) {
      this.text = e.target.innerText
    },
    blurText(e) {
      setTimeout(()=> {
        if(e.target.innerText.length === 0) {
          this.focusActive =false;
        }
      }, 0)
      // this.focusActive =false;
      // this.$refs.inputText.innerText = ''
    },
    createEmail(e) {
      e.preventDefault()
      let text = this.text
      let EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      var regex = /[^a-zA-Z.0-9 ]|\.(?=\.)|\s(?=\s)/g;
      var regex1 = /[^a-zA-Z_,\-.0-9 ]|\.(?=\.)|\s(?=\s)/g;
      e.target.innerText = e.target.innerText.trim().slice(e.target.innerText.trim().lastIndexOf(' ')+1, e.target.innerText.trim().length)
      // e.target.innerText = e.target.innerText.replace(/\s/g,'')
      if(e.target.innerText.indexOf('@') > 0) {
        let firstPart = e.target.innerText.slice(0, e.target.innerText.indexOf('@'))
        let lastPart =  e.target.innerText.slice(e.target.innerText.indexOf('@'), e.target.innerText.length)
        e.target.innerText = firstPart.replace(regex1, "") + '@'+lastPart.replace(regex, "").slice(0, lastPart.replace(regex, "").length -1) + lastPart.replace(regex, "").slice(lastPart.replace(regex, "").length -1, lastPart.replace(regex, "").length ).replace(/^\s+|\s+$/g, "")
        // e.target.innerText = e.target.innerText.replace(lastPart,'@'+lastPart.replace(regex, "").slice(0, lastPart.replace(regex, "").length -1) + lastPart.replace(regex, "").slice(lastPart.replace(regex, "").length -1, lastPart.replace(regex, "").length ).replace(/^\s+|\s+$/g, ""))
      }
      if(EMAIL_REGEXP.test(text)) {
        this.selectEmail(text)
        e.target.innerText = ''
        this.$refs.inputText.blur()
        this.focusActive = false
      } else {
        this.addTip('Incorrect email')
        const range = document.createRange();
        range.selectNodeContents(this.$refs.inputText);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
    },
    selectEmail(email) {
      if(this.localEmails.indexOf(email) >= 0) {
        this.localEmails = this.localEmails.filter(el=> el !== email)
      } else {
        this.localEmails.push(email)
      }
      this.$emit('changeEmail', this.localEmails)
    },
  },
  mounted() {
    this.localValue = JSON.parse(JSON.stringify(this.value))
    this.localEmails = JSON.parse(JSON.stringify(this.emails))
  },
  beforeMount() {
    // if(!this.contacts.length) {
      this.axiosGetMailingContacts();
    // }
  }
}
</script>

<style scoped lang="scss">
  .block-selected {
    min-height: 36px;
    box-sizing: border-box;
    width: 100%;
    input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
    .svg {
      position: absolute;
      right: 8px;
    }
  }
  .list {
    overflow: auto;
    position: absolute;
    width: 100%;
    max-height: 210px;
    border-radius: 8px;
    background-color: var(--color-bgc-page-white);
    border: 1px solid var(--color-border);
    bottom: -2px;
    left: 0;
    transform: translateY(100%);
    button {
      display: flex;
      align-items: center;
      position: relative;
      padding: 8px 32px 8px 12px;
      text-align: left;
      box-sizing: border-box;
      &:hover {
        background-color: var(--color-hover-list);

      }
      svg {
        position: absolute;
        right: 8px
      }
    }
  }
  .selected-name {
    display: flex;
    align-items: center;
    margin-right: 2px;
    margin-top: 2px;
    padding: 2px 22px 2px 8px;
    border-radius: 20px;
    background-color: var(--color-blue-lightest);
    svg {
      position: absolute;
      right: 8px;
    }
  }
  .block-view {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 4px 8px;
    box-sizing: border-box;
  }
  .click-zone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .placeholder {
    color: var(--color-input-placeholder);
    position: relative;
    top: 3px;
  }
</style>