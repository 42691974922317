<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_head">
        <p class="modal-content_title" v-if="account">Edit payment account <button class="modal-close" @click="closeModal()"></button></p>
        <p class="modal-content_title" v-else>Add new payment account <button class="modal-close" @click="closeModal()"></button></p>
      </div>
      <div class="modal-content_body">
        <label class="block-input">
          <p class="block-input_title text-gray">Holder</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.trim="holder">
        </label>

        <label class="block-input">
          <p class="block-input_title text-gray">Account Number</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.trim="account_number">
        </label>

        <label class="block-input">
          <p class="block-input_title text-gray">IBAN</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.trim="iban">
        </label>

        <label class="block-input">
          <p class="block-input_title text-gray">Description</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.trim="description">
        </label>
        
        <label class="block-input">
          <p class="block-input_title text-gray">Bank</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.trim="bank">
        </label>

        <label class="block-input">
          <p class="block-input_title text-gray">Clearing number</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.trim="clearing_number">
        </label>

        <label class="block-input">
          <p class="block-input_title text-gray">BIC / SWIFT</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.trim="bic">
        </label>

        <label class="block-input">
          <p class="block-input_title text-gray">Postal Account</p>
          <input class="block-input_content" type="text" maxlength="255" v-model.trim="postal">
        </label>

        <counterparties-drop 
          ref="dropCurrency"
          :list="currencyList" 
          :activeItem="activeCurrency ? activeCurrency : null"
          @select="selectedCurrency" 
          title="Currency"
        ></counterparties-drop>

        <p class="error" v-if="isEmpty">Once of fields must be filled</p>

      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal">Cancel</button>
        <button class="btn button-blue-border" @click="onSubmitAndNew" v-if="!account">Save and add new</button>
        <button class="btn btn-next" @click="onSubmit">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop';
export default {
  props: ['account'],
  components: {
    counterpartiesDrop
  },
  data() {
    return {
      holder: '',
      account_number: '',
      iban: '',
      description: '',
      bank: '',
      clearing_number: '',
      bic: '',
      postal: '',
      selectCurrency: {},
      activeCurrency: null,
      isEmpty: false
    }
  },
  computed: {
    ...mapState({
      currencyList: state => state.payment.valutaList
    }),
    getFieldsInObj() {
      return {
        holder: this.holder,
        account_number: this.account_number,
        iban: this.iban,
        description: this.description,
        bank: this.bank,
        clearing_number: this.clearing_number,
        bic: this.bic,
        postal: this.postal,
        currency: this.selectCurrency.name,
        client: this.$route.params.id
      }
    }
  },
  methods: {
    selectedCurrency(currency) {
      this.selectCurrency = currency
    },
    validateFields() {
      if(
        this.holder || this.account_number || this.iban ||
        this.description || this.bank || this.clearing_number ||
        this.bic || this.postal || this.selectCurrency.name
      ){
        return true
      }else{
        this.isEmpty = true
        return false
      }
    },
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      this.isEmpty = false
      if(this.validateFields()){
        this.$emit('submit', this.getFieldsInObj)
      }
    },
    onSubmitAndNew() {
      this.isEmpty = false
      if(this.validateFields()){
        this.$emit('submit-and-new', this.getFieldsInObj)
      }
    }
  },
  mounted() {
    if(this.account){
      this.holder = this.account.holder
      this.account_number = this.account.account_number
      this.iban = this.account.iban
      this.description = this.account.description
      this.bank = this.account.bank
      this.clearing_number = this.account.clearing_number
      this.bic = this.account.bic
      this.postal = this.account.postal
      this.activeCurrency = {name: this.account.currency, id: this.account.currency}
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-wrap{
  .modal-content_head{
    padding: 16px 12px 8px 24px;
    border-bottom: 1px solid #DEE3EA;
  }
  .modal-content_title{
    margin-bottom: 0;
    font-size: 18px;
  }
  .modal-content_body{
    padding: 0 24px;
  }
  .modal-content{
    max-width: 420px;
  }
  .modal-content_bottom{
    margin-top: 20px;
    padding: 8px 24px;
    border-top: 1px solid #DEE3EA;
  }
  .btn{
    min-width: 136px;
  }
  .btn-close{
    min-width: 60px;
    margin-right: 25px;
  }
  .button-blue-border{
    display: inline-flex;
    margin-right: 8px;
    padding: 7px;
    border: 1px solid #094172;
  }
}
.error{
  margin-top: 12px;
  color: #E85353;
  font-size: 12px;
}
</style>