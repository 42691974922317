import axios from 'axios'
import QueryString from "querystring"

export default {
  actions: {
    async axiosGetClientsById(ctx, id) {
      return await axios.get(process.env.VUE_APP_URL+'/client/'+ id , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        return ctx.getters.clientConstructor(response.data)
      }).catch(error => {
        console.error('axiosGetClients', error);
        return false
      })
    },
    async axiosGetClientsByIdReturnResponse(ctx, id) {
      return await axios.get(process.env.VUE_APP_URL+'/client/'+ id , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        return response.data;
      }).catch(()=> {
        return null
      })
    },
    async axiosGetClientsForDropdown(ctx) {
      await axios.get(process.env.VUE_APP_URL+"/client", {
        params: {
          limit: 999999,
          ordering: "company_name",
          is_archived: false,
        },
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        ctx.commit("setClientForDropdown", response.data.results)
      })
    },
    async axiosGetClients(ctx, next) {
      let filters = ctx.state.clientsFilters
      let getUrl = next ? ctx.state.nextRequest : process.env.VUE_APP_URL+'/client/?ordering='+filters.orderDirection+filters.ordering
      await axios({
        method: 'get',
        url: getUrl,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        },
        params: next ? {} : {
          is_archived: false,
          limit: filters.limit,
          search: filters.search,
          type: filters.types.map(el=>el.id),
          subtype: filters.subtypes.map(el=>el.id),
          broker: filters.brokers.map(el=>el.id),
          port: filters.ports.map(el=>el.id),
          tags: filters.tags,
          charter_name: filters.charter_name.map(el=>el.charter_name),
        },
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      }).then(response=> {
        ctx.state.nextRequest = response.data.next
        ctx.dispatch("setClients", {
          results: response.data.results,
          next: next
        });
      }).catch(error => {
        console.error('axiosGetClients', error);
      })
    },
    async axiosGetClientsArchive(ctx, next) {
      let filters = ctx.state.clientsFiltersArchive
      let getUrl = next ? ctx.state.nextRequest : process.env.VUE_APP_URL+'/client/?ordering='+filters.orderDirection+filters.ordering
      await axios({
        method: 'get',
        url: getUrl,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        },
        params: next ? {} : {
          is_archived: true,
          limit: filters.limit,
          search: filters.search,
          type: filters.types.map(el=>el.id),
          subtype: filters.subtypes.map(el=>el.id),
          broker: filters.brokers.map(el=>el.id),
          port: filters.ports.map(el=>el.id),
          tags: filters.tags,
          charter_name: filters.charter_name.map(el=>el.charter_name),
        },
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      }).then(response=> {
        ctx.state.nextRequest = response.data.next
        ctx.dispatch("setClientsArchive", {
          results: response.data.results,
          next: next
        });
      }).catch(error => {
        console.error('axiosGetClientsArchive', error);
      })
    },
    async axiosGetBrokers(ctx, id) {
      await axios.get(process.env.VUE_APP_URL+'/client/?subtype='+id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        ctx.commit("setBrokers", response.data.results);
      }).catch(error => {
        console.error('axiosGetBrokers', error);
      })
    },
    async axiosUpdateFile(ctx, file) {
      await axios.patch(process.env.VUE_APP_URL+'/file/' + file.id, {
        name: file.name
      }, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        // console.log('axiosUpdateFile', response);
      }).catch(() => {
        // console.error('axiosUpdateFile', error);
      });
    },
    async axiosAddFilesToClient(ctx, data) {
      let filesId = data.files
      let notAddFile = false;

      for (let i = 0; i < data.newFiles.length; i++) {
        let file = data.newFiles[i];
        // console.log(file)
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
        if(file.parent_id !== undefined && file.parent_id !== null) {
          notAddFile = true;
          formData.append("parent_id", file.parent_id);
          formData.append("parent_file", file.parent_id);
        }
        filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
      }
      if(!notAddFile) {
        await axios.patch(process.env.VUE_APP_URL+'/client/'+ data.id, {
          files: filesId
        },{
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken,
          }
        }).then(() => {
          // console.log('axiosAddFilesToClient', response);
        }).catch(() => {
          // console.error('axiosAddFilesToClient', error);
        })
      }

    },
    async axiosAddFile(ctx, formData) { // returned id of file
      return await axios.post(process.env.VUE_APP_URL+'/file/', formData, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
          'Content-Type': 'multipart/form-data',
        }
      }).then(response => {
        // console.log('axiosAddFile', response);
        return response.data.id
      }).catch(() => {
        // console.error('axiosAddFile', error);
      });
    },
    async axiosAddFileReturnData(ctx, formData) { // returned data of file

      return await axios.post(process.env.VUE_APP_URL+'/file/', formData, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
          'Content-Type': 'multipart/form-data',
        }
      }).then(response => {
        // console.log('axiosAddFile', response);
        return response.data
      }).catch(() => {
        // console.error('axiosAddFile', error);
      });
    },
    async axiosDeleteFileAnyway(ctx, id) {
      await axios.delete(process.env.VUE_APP_URL+'/file/'+id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
    },
    async axiosAddClient(ctx, clientObj) {
      return await axios.post(process.env.VUE_APP_URL+'/client/', {
        company_name: clientObj.company_name,
        charter_name: clientObj.charter_name,
        location: clientObj.location,
        full_style: clientObj.full_style,
        is_archived: false,
        comment: clientObj.comment,
        type: clientObj.type,
        subtype: clientObj.subtype,
        broker: clientObj.broker,
        tags: clientObj.tags,
        contacts: clientObj.contacts,
        files: clientObj.attachments,
        port: clientObj.port
      },{
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      }).then(response => {
        // console.log('axiosAddClient', response);
        return response
      }).catch(error => {
        // console.error('axiosAddClient', error);
        return error
      })
    },
    async axiosGetClientById(ctx, id) {
      return await axios.get(process.env.VUE_APP_URL + '/client/'+id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(res=> {
        return res.data
      })
    },
    async axiosEditClient(ctx, clientObj) {
      await axios.put(process.env.VUE_APP_URL+'/client/'+ clientObj.id, {
        company_name: clientObj.company_name,
        charter_name: clientObj.charter_name,
        location: clientObj.location,
        full_style: clientObj.full_style,
        is_archived: false,
        comment: clientObj.comment,
        type: clientObj.type,
        subtype: clientObj.subtype,
        broker: clientObj.broker,
        tags: clientObj.tags,
        contacts: clientObj.contacts,
        port: clientObj.port,
        files: clientObj.attachments
      },{
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        // console.log('axiosEditClient', response);
      }).catch(() => {
        // console.error('axiosEditClient', error);
      })
      await ctx.dispatch('axiosGetClients')
    },
    async axiosDelClientContact(ctx, clientId) {
      await axios.delete(process.env.VUE_APP_URL+'/client/contact/' + clientId + '/', {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        // console.log('axiosDelClientContact', response);
      }).catch(() => {
        // console.error('axiosDelClientContact', error);
      });
    },
    async axiosClientToArchive(ctx, {ids, bool}) {
      await axios.post(process.env.VUE_APP_URL+'/client/archive/', {ids, is_archived: bool}, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      }).then(() => {
        // console.log('axiosClientToArchive', response);
      }).catch(() => {
        // console.error('axiosClientToArchive', error);
      })
      await ctx.dispatch('axiosGetClients')
      await ctx.dispatch('axiosGetClientsArchive')
    },
    setClients(ctx, obj) {
      if(!obj.next) ctx.state.clients = []
      obj.results.forEach(el => {
        ctx.state.clients.push(ctx.getters.clientConstructor(el))
      });
    },
    setClientsArchive(ctx, obj) {
      if(!obj.next) ctx.state.clientsArchive = []
      obj.results.forEach(el => {
        ctx.state.clientsArchive.push(ctx.getters.clientConstructor(el))
      });
    },
  },
  mutations: {
    setBrokers(state, data) {
      state.brokers = []
      data.forEach(el => {
        state.brokers.push({
          id: el.id,
          name: el.company_name
        })
      });
    },
    addTip(state, text) {
      state.tips.push({id: state.tipCount++, text: text})
    },
    removeTip(state, i) {
      if(i >= 0)
        state.tips.splice(i, 1)
      else
        state.tips.splice(0, 1)
    },
    setClientForDropdown(state, data) {
      state.clientsDrop = data;
    },
  },
  state: {
    clients: [],
    clientsDrop: [],
    clientsArchive: [],
    brokers: [],
    clientsFilters: {
      limit: 25,
      search: '',
      ordering: 'company_name',
      orderDirection: '+',
      types: [],
      subtypes: [],
      brokers: [],
      ports: [],
      tags: [],
      charter_name: [],
    },
    clientsFiltersArchive: {
      limit: 25,
      search: '',
      ordering: 'company_name',
      orderDirection: '+',
      types: [],
      subtypes: [],
      brokers: [],
      ports: [],
      tags: [],
      charter_name: [],
    },
    nextRequest: null,
    tips: [],
    tipCount: 0
  },
  getters: {
    clientConstructor: () => client => {
      //console.log('clientConstructor', client);
      let subtypeArr = []
      let brokersArr = []
      let tagsArr = []
      let contactsArr = []
      let filesArr = []
      client.subtype.forEach(type => {
        subtypeArr.push({
          id: type.id,
          name: type.name,
        })
      })
      client.broker.forEach(type => {
        brokersArr.push({
          id: type.id,
          name: type.company_name,
        })
      })
      client.tags.forEach(tag => {
        tagsArr.push({
          id: tag.id,
          name: tag.name,
        })
      })
      client.contacts.forEach(contact => {
        contactsArr.push({
          id: contact.id,
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          fax: contact.fax,
          send_invoice: contact.send_invoice,
        })
      })
      client.files.forEach(file => {
        // let fileName = file.name ? file.name : file.file
        filesArr.push(file)
      })

      return {
        id: client.id,
        type: {
          id: client.type.id,
          name: client.type.name,
        },
        subtype: subtypeArr,
        company_name: client.company_name,
        charter_name: client.charter_name,
        location: client.location,
        full_style: client.full_style,
        is_archived: client.is_archived,
        comment: client.comment,
        broker: brokersArr,
        tags: tagsArr,
        contacts: contactsArr,
        files: filesArr,
        port: client.port
      }
    },
    getClientById: (state) => id => {
      return state.clients.filter(el => {
        return el.id == id
      })
    }
  }
}