import axios from 'axios'

export default {
  actions: {
    async axiosGetFsBalance(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/fs/balance/', 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setFsBalanceLeft', response.data)
        ctx.commit('setFsBalanceRight', response.data)
      }).catch(error => {
        console.error('axiosGetFsBalance', error);
      })
    },
    async axiosGetFsBalanceLeft(ctx, time) {
      let timeStr = time ? '?date_after='+time[1]+'&date_before='+ time[0] : ''
      await axios.get(process.env.VUE_APP_URL+'/fs/balance/' + timeStr, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setFsBalanceLeft', response.data)
      }).catch(error => {
        console.error('axiosGetFsBalance', error);
      })
    },
    async axiosGetFsBalanceRight(ctx, time) {
      let timeStr = time ? '?date_after='+time[1]+'&date_before='+ time[0] : ''
      await axios.get(process.env.VUE_APP_URL+'/fs/balance/' + timeStr, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.commit('setFsBalanceRight', response.data)
      }).catch(error => {
        console.error('axiosGetFsBalance', error);
      })
    },
  },
  mutations: {
    setFsBalanceLeft(state, fs) {
      state.fsLeft = fs
    },
    setFsBalanceRight(state, fs) {
      state.fsRight = fs
    },
  },
  state: {
    fsLeft: null,
    fsRight: null,
  },
  getters: {
  }
}