<template>
  <div class="page-gray" v-if="permissions.length && getPermissionsByType('show_archive_bank_and_cash_accounts')">
    <bread
      :allowOptions="false"
      :links="[
        {name: 'Banks accounts', link: '/accounts/bank-and-cash'}, 
        {name: `Archive Banks and Cash`, link: ''}
      ]"
    />

    <bank-archive-top @search="onSearch"/>
    <bank-archive-list ref="trialList" :list="getFilteredArchiveList" @select="onSelect" @on-unarchive="onUnarchive" v-if="banksArchive"/>
    <bank-archive-bottom @on-close="$refs.trialList.uncheckAll()" :selectedListId="selectedListId" @on-unarchive="onUnarchive" />

    <bank-modal-unarchive :list="selectedListId" :account="editAccount" v-if="isModalArchiveOpen" @close="closeModal" @submit="unarchiveAccount"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import bankArchiveTop from '@/components/accounts/bankAndCash/archive/bankArchiveTop'
import bankArchiveList from '@/components/accounts/bankAndCash/archive/bankArchiveList'
import bankArchiveBottom from '@/components/accounts/bankAndCash/archive/bankArchiveBottom'
import bankModalUnarchive from '@/components/accounts/bankAndCash/archive/bankModalUnarchive'
import bread from '@/components/reuse/bread'
export default {
  components: {
    bankArchiveTop,
    bankArchiveList,
    bankArchiveBottom,
    bankModalUnarchive,
    bread
  },
  data() {
    return {
      selectedListId: [],
      editAccount: null,
      isModalArchiveOpen: false,
      searchText: ''
    }
  },
  watch: {
    permissions: function(val) {
      if(val.length && !this.getPermissionsByType('show_archive_bank_and_cash_accounts'))
        this.$router.replace({name: 'employees'})
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.confirmation.permissionsForUser,
      banksArchive: state => state.banks.banksArchive
    }),
    ...mapGetters(['getPermissionsByType']),
    getFilteredArchiveList() {
      return this.banksArchive.filter(el=>{
        if(this.searchText){
          if(el.currency){
            return el.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0 || el.currency.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0
          }else{
            return el.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0
          }
        }else
          return true
      })
    }
  },
  methods: {
    ...mapActions(['axiosGetBanksArchive', 'axiosBanksToArchive', 'axiosGetBanks']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    onUnarchive(account) {
      if(account)
        this.editAccount = account
      else
        this.editAccount = null
      this.isModalArchiveOpen = true
    },
    async unarchiveAccount(ids) {
      this.showLoad()
      await this.axiosBanksToArchive({ids: ids, bool: false})
      this.hideLoad()
      this.isModalArchiveOpen = false
      if(this.editAccount)
        this.addTip(this.editAccount.name + ' is unarchived')
      else if(ids.length == 1)
        this.addTip(ids.length + ' account is unarchived')
      else
        this.addTip(ids.length + ' accounts are unarchived')

      this.axiosGetBanks()
      this.axiosGetBanksArchive()
      this.$refs.trialList.uncheckAll()
    },
    onSelect(list) {
      this.selectedListId = list.slice()
    },
    closeModal() {
      this.isModalArchiveOpen = false
    },
    onSearch(text) {
      this.searchText = text
    }
  },
  async mounted() {
    this.showLoad()
    if(!this.banksArchive)
      await this.axiosGetBanksArchive()
    this.hideLoad()
  },
}
</script>

<style>
 .page-gray{
   background-color: var(--color-bgc-page);
   height: calc(100vh - 56px);
    overflow-y: auto;
 }
</style>