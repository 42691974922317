<template>
  <Transition name="fade">
    <div class="vue-modal" v-if="visibility">
      <div class="modal-container">
        <div class="modal-header">
          <div class="header-name">
            <p class="fz18 fw500">Edit recurring event</p>
          </div>
          <span class="modal-close" @click="hideModal">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2486 5.03572C19.5415 5.32861 19.5415 5.80348 19.2486 6.09638L13.2032 12.1417L19.2486 18.1871C19.5415 18.48 19.5415 18.9549 19.2486 19.2478C18.9557 19.5407 18.4808 19.5407 18.1879 19.2478L12.1426 13.2024L6.09721 19.2478C5.80432 19.5407 5.32944 19.5407 5.03655 19.2478C4.74366 18.9549 4.74366 18.48 5.03655 18.1871L11.0819 12.1417L5.03655 6.09637C4.74366 5.80348 4.74366 5.32861 5.03655 5.03571C5.32944 4.74282 5.80432 4.74282 6.09721 5.03571L12.1426 11.0811L18.1879 5.03572C18.4808 4.74282 18.9557 4.74282 19.2486 5.03572Z"
                fill="#262626"
              />
            </svg>
          </span>
        </div>
        <div class="modal-content">
          <div class="modal-row">
            <div class="radio-block">
              <label class="radio-wrapper p-b-2">
                <input
                  v-model="changeEvents"
                  type="radio"
                  value="All events"
                  name="changeEvents"
                />
                <span></span>
                <p class="fz14 text-dark1">All events</p>
              </label>
              <label class="radio-wrapper">
                <input
                  v-model="changeEvents"
                  value="Only this event"
                  type="radio"
                  name="changeEvents"
                />
                <span></span>
                <p class="fz14 text-dark1">Only this event</p>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="modal-btn del-btn" @click="visibility = false">
            Cancel
          </button>
          <button class="modal-btn edit-btn" @click="set()">
            Ok
          </button>
        </div>
        
      </div>
      
    </div>
  </Transition>
</template>
<script>
export default {
  data: () => {
    return {
      visibility: false,
      changeEvents: 'All events',
      type: null
    };
  },
  methods: {
    showModal(type) {
      this.visibility = true;
      this.type = type;
    },
    hideModal() {
      this.visibility = false;
      this.type = null;
    },
    set(){
      this.$emit('action', {type: this.type, changeEvents: this.changeEvents})
      this.hideModal()
    }
  },
};
</script>


<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.vue-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.3);
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: auto;
  }
  .modal-container {
    width: 430px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 13px;
    position: relative;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  height: 39px;
  margin-bottom: 12px;
  padding: 0 24px;
  border-bottom: 1px solid #dee3ea;
}

.modal-footer {
  height: 48px;
  border-top: 1px solid #dee3ea;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 32px;
    &.edit-btn {
      background: #319fef;
      border-radius: 4px;
      color: #fff;
      svg {
        margin-right: 9px;
      }
    }
    &.del-btn {
      color: #fff;
      color: #e85353;
      svg {
        margin-right: 9px;
      }
    }
  }
}
.modal-content {
  max-height: 70vh;
  overflow-y: auto;
  .modal-row {
    width: 100%;
    min-height: 37px;
    height: auto;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 24px;
    .title {
      padding-bottom: 3px;
    }
  }
  .radio-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  .radio-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    // height: 24px;
    &:hover {
      cursor: pointer;
    }
    &:nth-child(1) {
      margin-right: 35px;
    }
  }
  input {
    position: absolute;
    z-index: -99999;
    opacity: 0;
  }
  span {
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid var(--color-gray-blacker-than-black);
    position: relative;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
  input:checked + span {
    border-color: var(--color-blue-dark);
    &:after {
      content: "";
      position: absolute;
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--color-blue-dark);
    }
  }
}
}

.modal-close {
  &:hover {
    cursor: pointer;
  }
}
</style>