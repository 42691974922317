<template>
  <div class="repeat-select-wrapper">
    <multiselect
      v-model="selected.repeatOption"
      :options="repeatOptions"
      :show-labels="false"
      @input="setValue($event)"
    >
      <template slot="option" slot-scope="props">
        <p class="option__name">{{ props.option }}</p>
      </template>
      
      <template slot="selection" slot-scope="{ option }">
        <span class="selected">
          <span class="my-selected">{{ option }}
            <span class="end-repeat" v-if="selected.end_repeat_day && repeatEnd != 'never'">End: {{selected.end_repeat_day | formatDateFromCpDateWithotTimeZome}}
            </span>
          </span>
        </span>
      </template>
    </multiselect>
    <date-picker
      v-if="selected.repeatOption == 'Custom days repeat'"
      v-model="selected.repeatDays"
      :multiple="true"
      class="repeat-date-picker"
      ref="datePickDue"
      format="DD.MM.YYYY"
      :open.sync="openDatePicker"
      :input-class="'date-picker-input'"
      @clear='selected.repeatDays = []'
    >
      <template slot="input">
        <span v-if="selected.repeatDays.length <= 2">
          <span v-for="(day, index) in selected.repeatDays" :key="index">
            <span class="fz13">
              {{day | formatDateFromCpDateWithotTimeZome}}
            </span>
          </span>
        </span>
        <span v-else>
          <span v-for="(day, index) in selected.repeatDays" :key="index">
            <span v-if="index <= 1" class="fz13">
              {{day | formatDateFromCpDateWithotTimeZome}}
              <span class="fz13" v-if="index == 1">({{selected.repeatDays.length}})</span>
            </span>
          </span>
        </span>
      </template>
      
    </date-picker>
    <eventRepeatModal @action="addRepeatEndDate($event)" ref="eventRepeatModal"></eventRepeatModal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import eventRepeatModal from "@/components/calendar/eventRepeatModal";
export default {
  components: {
    Multiselect,
    DatePicker,
    eventRepeatModal
  },
  props: ['defaultValue', 'date'],
  data: () => {
    return {
      repeatOptions: [
        "Do no repeat",
        "Daily",
        "Every workday repeat",
        "Custom days repeat",
      ],
      selected: {
        repeatOption: "Do no repeat",
        repeatDays: [],
        end_repeat_day: null
      },
      openDatePicker: false,
      repeatEnd: 'never'
    };
  },
  methods: {
    setValue(val) {
      if (val == "Custom days repeat") {
        this.openDatePicker = true;
        this.selected.end_repeat_day = null;
      } else {
        if(['Daily', 'Every workday repeat'].includes(val)){
          this.selected.end_repeat_day = null;
          this.$refs.eventRepeatModal.showModal(this.date)
        }else{
          this.selected.end_repeat_day = null;
        }
        this.selected.repeatDays = []
        this.openDatePicker = false;
      }
    },
    getSelectedDays(){
      if(this.selected.repeatDays.length <= 2){
        return this.selected.repeatDays
      }else{
        return this.selected.repeatDays.slice(0, 2)
      }
    },
    addRepeatEndDate(date){
      this.selected.end_repeat_day = date.endDate;
      this.repeatEnd = date.repeatEnd
    },
    clear(){
      this.selected.end_repeat_day = null;
    }
  },
  watch: {
    selected: {
      handler: function(val){
        let data = val;
        if(this.repeatEnd == 'never'){
          data.is_never = true;
        }else{
          data.is_never = false;
        }
        
        this.$emit('change', val)
      },
      deep: true
    },
    defaultValue: function(val){
      let str = val.repeatOption[0].toUpperCase() + val.repeatOption.slice(1)
      this.selected.repeatOption = str.split('_').join(' ')
      
    }
  },
};
</script>
<style lang="scss" scoped>
.option__name {
  position: relative;
  width: 100%;
  padding: 6px 0;
}
.multiselect__option--selected {
  display: flex;
  align-items: center;
  
  .option__name {
    &:after {
      content: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z' fill='%23319FEF'/%3E%3C/svg%3E ");
      position: absolute;
      background: none;
      right: 6px;
    }
  }
}
.repeat-select-wrapper {
  position: relative;
  &.disabled{
    opacity: .5;
    pointer-events: none;
  }
  .mx-icon-calendar {
    display: none;
  }
}
.my-selected{
  position: absolute;
  right: 42%;
  top: 12px;
}
.repeat-date-picker {
  position: absolute;
  top: 10px;
  left: 155px;
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.invisible-select {
  .multiselect__tags {
    border: none;
    background: none;
    font-size: 14px;
  }
  .multiselect {
    width: fit-content;
  }
  .multiselect__single {
    font-size: 14px;
  }
}
#app {
  .repeat-select-wrapper {
    .mx-icon-calendar {
      display: none;
    }
    .mx-input-wrapper{
      width: 100%;
    }
    .date-picker-input{
      display: none;
    }
  }
}
</style>