<template>
  <div class="wrap svg-white-dart">
    <b-dropdown left ref="dropdown" @hide="createEmit">
      <template #button-content>
        <button class="button-drop fz13 fw500 text-dark1">
          Status
        </button>
      </template>
      <div class="content">
        <button class="title">
          <button v-on:click="$refs.dropdown.hide(true)" class=" fz13 fw500 text-gray">
            Status
          </button>
<!--          <button class="" v-on:click="clickSortReverse" :class="{'rotate': reverseSort}">-->
<!--            <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >-->
<!--              <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>-->
<!--            </svg>-->
<!--          </button>-->
        </button>
        <button :class="{'active-button': selectSeeAll}" v-on:click="seeAllClick" class="fz13 text-dark">
          See All
        </button>
        <button :class="{'active-button': choosedIncome}" class="fz13 fw600 text-dark">
          Income
        </button>
        <button v-for="(b, i) in buttonsIncome" :key="'2323'+i" :class="{'active-button': b.status}" v-on:click="changeActiveButtonIncome(i)" class="fz13 text-dark1">
          {{b.name}}
        </button>
        <button :class="{'active-button': choosedPayment}" class="fz13 fw600 text-dark1">
          Payment
        </button>
        <button v-for="(b, i) in buttonsPayment" :key="i" :class="{'active-button': b.status}" v-on:click="changeActiveButtonPayment(i)" class="fz13 text-dark1">
          {{b.name}}
        </button>

      </div>
    </b-dropdown>
    <svg  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 4.40039L6.00001 8.40039L10 4.40039H2Z" fill="#2C2C2C"/>
    </svg>

    <!--    <button class="" v-on:click="clickSortReverse" :class="{'rotate': reverseSort}">-->
<!--      <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >-->
<!--        <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>-->
<!--      </svg>-->
<!--    </button>-->

  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";

export default {
  name: "dropdownChoosedStatusForPaymentArchive",
  computed: mapGetters(["getDepartments"]),
  data() {
    return {
      ...mapMutations(["sortTasksByDepartmentsReverse", "sortTasksByDepartments", "sortTaskByName", "sortTaskByNameReverse", "sortTaskByStatus", "sortTaskByStatusReverse", "sortTaskByPriority", "sortTaskByPriorityReverse", "sortTaskByDeadlineReverse", "sortTaskByDeadline"]),
      buttonsIncome: [
        {name: "Not Paid", value: "not_paid", status: false},
        {name: "Paid", value: "paid", status: false},
      ],
      buttonsPayment: [
        {name: "Not Paid", value: "not_paid", status: false},
        // {name: "On Payment", value: "in_progress", status: false},
        {name: "Paid", value: "paid", status: false},
      ],
      selectSeeAll: true,
      indexActive: 0,
      buttonNameActive: "By departments",
      reverseSort: false,
      choosedIncome: false,
      choosedPayment: false,
      change: 0,
    }
  },
  methods: {
    seeAllClick() {
      this.buttonsIncome.forEach(a=> {
        a.status = false;
      })
      this.buttonsPayment.forEach(a=> {
        a.status = false;
      })
      this.choosedIncome = false;
      this.choosedPayment = false;
      this.selectSeeAll = true;
      this.change++;
    },
    createEmit() {
      if(this.change !== 0) {
        let paymentCheckbox = this.buttonsPayment.filter(a=> a.status === true).map(a=> {return a.value});
        let incomeCheckbox = this.buttonsIncome.filter(a=> a.status === true).map(a=> {return a.value});
        this.$emit("changeStatusSelected", {paymentCheckbox: paymentCheckbox, incomeCheckbox: incomeCheckbox})
      }
    },
    changeActiveButtonIncome(index) {
        // this.$refs.dropdown.hide(true);
        // for (let i = 0; i < this.buttons.length; i++) {
        //   this.buttons[i].status = false;
        // }
        this.buttonsIncome[index].status = !this.buttonsIncome[index].status;
        let k = this.buttonsIncome.filter(a=> a.status);
        if(k.length) {
          this.choosedIncome = true;
        } else {
          this.choosedIncome = false
        }
        if(this.buttonsIncome[index].status ) {
          this.selectSeeAll = false;
        }
      this.change++;
    },
    changeActiveButtonPayment(index) {
      // this.$refs.dropdown.hide(true);
      // for (let i = 0; i < this.buttons.length; i++) {
      //   this.buttons[i].status = false;
      // }
      this.buttonsPayment[index].status = !this.buttonsPayment[index].status;
      let k = this.buttonsPayment.filter(a=> a.status);
      if(k.length) {
        this.choosedPayment = true;
      } else {
        this.choosedPayment = false
      }
      if(this.buttonsIncome[index].status ) {
        this.selectSeeAll = false;
      }
      this.change++;
    },
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
@import "../style-element/dropdown";
.wrap {
  display: flex;
  align-items: center;
  &>svg {
    margin-left: 5px;
  }
}

.active-button {
  background-image: url("../../assets/ic_chsdeck.svg");
  background-position-x: calc(100% - 4px);
  background-position-y: center;
  background-repeat: no-repeat;
}
.svg-1 {
  margin-right: 8px;
}
.arrow {
  margin-left: 4px;
}
/deep/ .show {
  //.arrow {
  //  transform: rotateX(180deg);
  //}
}
/deep/ .dropdown-menu {
  top: -25px !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  padding: 4px 0 !important;
}
.content {
  width: 150px;
  background-color: var(--color-modal);
  border-radius: 4px;
  .title {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 5px 12px;
    display: flex;
    align-items: center;
    //svg {
    //  position: relative;
    //  top: 2px;
    //  margin-left: 4px;
    //}
    transition: .1s linear;
    button {
      width: max-content;
      &:hover {
        color: var(--color-dark) !important;
      }
    }

  }
  &>button:not(:first-child) {
    height: 28px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    transition: background-color .1s linear;
    &:hover {
      background-color: var(--color-card-border);
    }
  }
}

.rotate {
  transform: rotateX(180deg);
}
.button-drop {
  display: flex;
  align-items: center;
  //font-size: 13px !important;
  //min-width: 143px;
  //display: flex;
  //justify-content: flex-start;
  //align-items: center;
}
</style>

