var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex flex-shrink-0 h-full w-104 p-x-3 p-y-1 border-r border-gray-text"},[_c('button',{staticClass:"flex items-center m-y-auto radio-button text-dark hover:child-stroke-blue-dark hover:text-blue-dark",on:{"click":function($event){return _vm.$emit('on-click')}}},[(_vm.fuel == _vm.prefix)?_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{staticClass:"circle1",attrs:{"cx":"9","cy":"9","r":"8","stroke":"#094172","stroke-width":"2"}}),_c('circle',{staticClass:"circle2",attrs:{"cx":"9","cy":"9","r":"5","fill":"#094172"}})]):_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"9","cy":"9","r":"8","stroke":"#9AA3B0","stroke-width":"2"}})]),_c('span',{staticClass:"m-l-1.5 fz13 fw500 uppercase"},[_vm._v(_vm._s(_vm.prefix))])])]),_c('div',{staticClass:"w-full p-x-3 p-t-1 text-gray-text fz13 overflow-hidden position-relative"},[_c('div',{staticClass:"inline-block p-b-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis fw600",class:{
          'w-1/2': _vm.ifManyNumbers
        }},[_vm._v(" Result, $: "),_c('span',{staticClass:"m-l-1.5 m-r-4 text-dark fw600"},[_vm._v(" "+_vm._s((_vm.detail.hire ? Math.abs(parseFloat(_vm.detail.hire)) : 0) > (_vm.tableCostsFull[_vm.prefix+'_hire_bep'] ? (parseFloat(_vm.tableCostsFull[_vm.prefix+'_hire_bep'])) : 0) ? '-' : '')+_vm._s(_vm._f("formatNumberZero")(_vm.tableCostsFull[_vm.prefix+'_results'])))])]),_c('div',{staticClass:"inline-block p-b-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis fw600",class:{
          'w-1/2': _vm.ifManyNumbers
        }},[_vm._v(" Hire BEP, $: "),_c('span',{staticClass:"m-l-1.5 m-r-4 text-dark fw600"},[_vm._v(_vm._s(_vm._f("formatNumberZeroNotAbs")(_vm.tableCostsFull[_vm.prefix+'_hire_bep'])))])]),_c('div',{staticClass:"inline-block p-b-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis fw600",class:{
          'w-1/2': _vm.ifManyNumbers
        }},[_vm._v(" Freight BEP, $: "),_c('span',{staticClass:"m-l-1.5 m-r-4 text-dark fw600"},[_vm._v(_vm._s(_vm._f("formatNumberTwo")(_vm.tableCostsFull[_vm.prefix+'_freight_bep'])))])]),_c('div',{staticClass:"inline-block p-b-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis fw600",class:{
          'w-1/2': _vm.ifManyNumbers
        }},[_vm._v(" Voyage Days: "),_c('span',{staticClass:"m-l-1.5 m-r-4 text-dark fw600"},[_vm._v(_vm._s(_vm._f("formatNumberTwo")(_vm.tableCostsFull[_vm.prefix+'_voyage_days'])))])]),(_vm.loadTable)?_c('div',{staticClass:"load-block w100Proc h100Proc border-r-2 position-absolute z-20"}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }