import axios from 'axios'

export default {
    actions: {
        async axiosGetCalendarEventsList(ctx, filter = {}) {
            var url = process.env.VUE_APP_URL + "/event/";
            if(localStorage.getItem('membersFilter')){
                url = process.env.VUE_APP_URL + "/event/"+ JSON.parse(localStorage.getItem('membersFilter')).query_string
            }
            if (ctx.getters.getCalendarActiveFilters != '') {
                url = process.env.VUE_APP_URL + "/event/" + ctx.getters.getCalendarActiveFilters
            }
            let response = await axios.get(url, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: { ...filter }
            })
            ctx.commit("setCalendarEventsList", response.data);
            if(!ctx.state.calendarMembersList){
                await this.dispatch('axiosGetCalendarMembersList')
            }
            return response.data;
        },
        async axiosGetCalendarEventById(ctx, id) {
            var url = process.env.VUE_APP_URL + "/event/"+id;
            
            let response = await axios.get(url, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            })
            
            return response.data;
        },
        async axiosGetCalendarTasksList(ctx, filter = {}) {
            var url = process.env.VUE_APP_URL + "/task/";
            filter.only_for_me = true;
            if(localStorage.getItem('membersFilter')){
                url = process.env.VUE_APP_URL + "/task/"+ JSON.parse(localStorage.getItem('membersFilter')).query_string.replaceAll('employees', 'members');
                filter.only_for_me = false;
            }
            let response = await axios.get(url, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: { ...filter }
            })
            ctx.commit("setCalendarTasksList", response.data);
            return response.data;

        },
        async axiosGetCalendarPaymentsList(ctx, filter = {}) {
            let response = await axios.get(process.env.VUE_APP_URL + "/payment/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: { ...filter }
            })
            ctx.commit("setCalendarPaymentsList", response.data);
            return response.data;
        },
        async axiosGetCalendarMembersList(ctx, filter = {}) {
            let response = await axios.get(process.env.VUE_APP_URL + "/user/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: { ...filter }
            })
            ctx.commit("setCalendarMembersList", response.data);
            return response.data;
        },
        async axiosGetCalendarClientsList(ctx, filter = {}) {
            let response = await axios.get(process.env.VUE_APP_URL + "/client/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: { ...filter }
            })
            ctx.commit("setCalendarClientsList", response.data);
            return response.data;
        },
        async axiosGetCalendarClientByID(ctx, id) {
            let response = await axios.get(process.env.VUE_APP_URL + "/client/"+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            })
            return response.data;
        },
        async axiosGetCalendarVesselsList(ctx, filter = {}) {
            let response = await axios.get(process.env.VUE_APP_URL + "/contract/dropdown/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: { ...filter }
            })
            ctx.commit("setCalendarVesselsList", response.data);
            return response.data;
        },
        async axiosGetCalendarVesselsByID(ctx, id) {
            let response = await axios.get(process.env.VUE_APP_URL + "/contract/"+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            })
            return response.data;
        },
        async axiosCreateCalendarEvent(ctx, data) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: process.env.VUE_APP_URL + '/event/',
                    data: data,
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        async axiosUpdateCalendarEvent(ctx, data) {
            return new Promise((resolve, reject) => {
                let queryParams = {
                    delete_child: false
                }
                if(data.delete_child){
                    queryParams.delete_child = data.delete_child;
                }
                axios({
                    method: 'put',
                    url: process.env.VUE_APP_URL + '/event/'+data.eventID,
                    data: data,
                    params: queryParams,
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        async axiosDeleteCalendarEvent(ctx, id) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: process.env.VUE_APP_URL + '/event/'+id,
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        async axiosAcceptEvent(ctx, id) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'put',
                    url: process.env.VUE_APP_URL + `/event/members/${id}/`,
                    data: {is_accepted: true},
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        async axiosGetCalendarEventsInfo(ctx, filter = {}) {
            
            let membersFilter = localStorage.getItem('membersFilter');
            let url = process.env.VUE_APP_URL + "/event/calendar/info/";
            if(membersFilter){
                url += JSON.parse(membersFilter).query_string
            }
            let response = await axios.get(url, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: { ...filter }
            })
            return response.data;
        },
    },
    mutations: {
        setCalendarEventsList(state, data) {
            state.calendarEventsList = data;
        },
        setCalendarTasksList(state, data) {
            state.calendarTasksList = data;
        },
        setCalendarPaymentsList(state, data) {
            state.calendarPaymentsList = data;
        },
        setCalendarMembersList(state, data) {
            data.results.forEach(el => {
                el.name = `${el.first_name} ${el.last_name}`
            });
            state.calendarMembersList = data;
        },
        setCalendarClientsList(state, data) {
            state.calendarClientsList = data;
        },
        setCalendarVesselsList(state, data) {
            state.calendarVesselsList = data;
        },
        setCalendarCurrentDate(state, data) {
            state.calendarCurrentDate = data;
        },
        setCalendarActiveFilters(state, data) {
            state.calendarActiveFilters = data;
        },
        delCalendarActiveFilters(state) {
            state.calendarActiveFilters = "";
        },
        setCalendarActiveFiltersFull(state, data) {
            state.calendarActiveFiltersFull = data;
        },
        delCalendarActiveFiltersFull(state) {
            state.calendarActiveFiltersFull = {};
        },
        setCalendarCategoriesFilter(state, data) {
            state.calendarCategoriesFilter = data;
        },
    },
    state: {
        calendarEventsList: null,
        calendarTasksList: null,
        calendarMembersList: null,
        calendarPaymentsList: null,
        calendarClientsList: null,
        calendarVesselsList: null,
        calendarCurrentDate: null,
        calendarActiveFilters: '',
        calendarActiveFiltersFull: {},
        calendarCategoriesFilter: {
            payments: true,
            tasks: true,
            events: true
        }
    },
    getters: {
        getCalendarEventsList(state) {
            return state.calendarPaymentsList
        },
        getCalendarTasksList(state) {
            return state.calendarTasksList
        },
        getCalendarPaymentsList(state) {
            return state.calendarPaymentsList
        },
        getCalendarMembersList(state) {
            return state.calendarMembersList;
        },
        getCalendarMemberById: (state) => (id) => {
            if(!state.calendarMembersList){
                return state.calendarMembersList.results.find(member => member.id === id);
            }
        },
        getCalendarClientsList(state) {
            return state.calendarClientsList
        },
        getCalendarVesselsList(state) {
            return state.calendarVesselsList
        },
        getCalendarCurrentDate(state) {
            return state.calendarCurrentDate
        },
        getCalendarActiveFilters(state) {
            return state.calendarActiveFilters
        },
        getCalendarActiveFiltersFull(state) {
            return state.calendarActiveFiltersFull
        },
        getCalendarCategoriesFilter(state) {
            return state.calendarCategoriesFilter
        },
    },
}