<template>
  <transition name="slide-bottom">
    <div class="mail-toast" v-if="show">
      <!-- <span class="text" v-if="!newEmail"> -->
        <!-- Download emails: {{loadedEmails}}/{{totalEmails}} -->
      <!-- </span> -->
      <div class="load-emails-block" v-if="!newEmail">
        <span class="title">Download emails:</span>
        <div class="loadedAccount" v-for="account in loadedAccounts" :key="account.account_id">
          <span class="name">{{account.account_name}}</span>
          <span class="num">{{account.index}}/{{account.all_message_count}}</span>
        </div>
      </div>
      <div class="new-message" v-else>
        <span class="text">New message</span>
        <button class="show-btn" @click="showNewMessage">Show</button>
      </div>
      <button class="close-btn" @click="hideToast">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#2C2C2C"/>
          <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#2C2C2C"/>
        </svg>

      </button>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'newEmail',
    data: () => ({
      show: false,
      totalEmails: 0,
      loadedEmails: 0,
      newEmail: false,
      newEmailInfo: {
        mailId: null,
        folderId: null
      },
      loadedAccounts: []
    }),
    methods: {
      showToast(loadingInfo = null, newEmailInfo = null){
        if(loadingInfo){
          let currentLodaedAccount = this.loadedAccounts.find(account => account.account_id == loadingInfo.account_id)
          if(currentLodaedAccount){
            currentLodaedAccount.index = loadingInfo.index
          }else{
            this.loadedAccounts.push(loadingInfo)
          }
          this.show = true
        }
        if(newEmailInfo){
          this.newEmail = true
          this.newEmailInfo.mailId = newEmailInfo.mailId
          this.newEmailInfo.folderId = newEmailInfo.folderId
          this.show = true
        }
      },
      hideToast(){
        this.show = false
        this.totalEmails = 0
        this.loadedEmails = 0
        this.newEmail = false
        this.newEmailId = null
      },
      showNewMessage(){
        this.$emit('showNewMessage', this.newEmailInfo)
        this.hideToast()
      }
    }
  }
</script>

<style lang="scss" scoped>
.mail-toast{
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  display: flex;
  padding: 16px 18px;
  border-radius: 8px;
  background: var(--color-violet);
  justify-content: space-between;
  .text{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .close-btn{
    margin: 0;
    padding: 0;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    margin-left: auto;
    svg{
      path{
        transition: .3s;
      }
    }
    &:hover{
      svg{
        path{
          transition: .3s;
          fill: var(--color-blue)
        }
      }
    }
  }
  .new-message{
    display: flex;
    align-items: center;
    .show-btn{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      padding: 2px 4px;
      margin-left: 12px;
      border: 1px solid var(--color-purple);
      border-radius: 4px;
      color: var(--color-purple);
      transition: .3s;
      &:hover{
        color: var(--color-blue);
        border-color: var(--color-blue);
      }
    }
  }
  .load-emails-block{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
    .loadedAccount{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .title{
      font-weight: bold;
    }
    .name{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px;
      font-size: 14px;
    }
  }
}
.slide-bottom-enter-active {
  transition: all .3s ease;
}
.slide-bottom-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-bottom-enter, .slide-bottom-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>