<template>
  <div class="container">
    <counterparties-user v-if="client" :client="client"/>
    <counterparties-user-tabs :links="linksByPermission" :client="client"/>
  </div>
</template>

<script>
import counterpartiesUser from "@/components/counterparties/counterpartiesUser/counterpartiesUser";
import counterpartiesUserTabs from "@/components/counterparties/counterpartiesUser/counterpartiesUserTabs";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    counterpartiesUser,
    counterpartiesUserTabs,
  },
  data() {
    return {
      client: null
    }
  },
  computed: {
    ...mapGetters(['getPermissionsByType', 'getClientById']),
    linksByPermission() {
      let links = []
      if(this.getPermissionsByType('show_counterparties_payments')) links.push({
        to: 'payments',
        text: 'Payments'
      })
      /* permissions? */links.push({
        to: 'payment-accounts',
        text: 'Payment accounts'
      })
      /* permissions? */links.push({
        to: 'balances',
        text: 'Balances'
      })
      return links
    }
  },
  methods: {
    ...mapActions(['axiosGetClientsById']),
    async updateClient() {
      if(!this.getClientById(this.$route.params.id).length){
        let loadClient = await this.axiosGetClientsById(this.$route.params.id)
        if(loadClient){
          this.client = loadClient
          return false
        }else{
          this.$router.push('/counterparties')
          return false
        }
      }
      this.client = this.getClientById(this.$route.params.id)[0]
    },
  },
  beforeMount() {
    this.updateClient()
  }
  /* beforeRouteUpdate (to, from, next) {
    next()
  } */
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 0;
}
.counterparties-tabs {
  width: calc(100% - 280px);
  /deep/ {
    .wrap-page {
      overflow-y: auto;
    }
  }
}
</style>