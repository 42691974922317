<template>
  <div class="counterparties-drop">
    <b-dropdown class="bg-white" ref="dropdown" :disabled="isDisabled">
      <template #button-content>
        <span class="button-text" v-html="printDropResult"></span>
        <span class="button-drop"><svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2c2c2c"/></svg></span>
      </template>

      <li v-if="!list.length"><span class="dropdown-item text-dark">Please select type</span></li>
      <li v-for="item in list" :key="item.id">
        <a href="#" class="dropdown-item text-dark" @click.prevent="select(item)">{{item.name}}</a>
        <svg v-if="selectItem == item.name || selectItems.findIndex(el => el.name == item.name) >= 0" class="dropdown-check" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z" fill="#319FEF"/></svg>
      </li>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array
    },
    activeItem: {
      default: null
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    activeItem() {
      //console.log('this.activeItem ', this.activeItem);
      if(this.activeItem) {
        //this.select(this.activeItem)
        if(this.isMultiple) {
          this.activeItem.forEach(item => {
            this.select(item)
          });
        }else{
          this.select(this.activeItem)
        }
      }
    }
  },
  data() {
    return {
      selectItem: '',
      selectItems: [],
    }
  },
  computed: {
    printDropResult() {
      if(this.isMultiple) {
        let result = ''
        if(this.selectItems.length > 2){
          result = this.selectItems[0].name + `&nbsp;/&nbsp;<b>+${this.selectItems.length - 1}</b>`
        } else if(this.selectItems.length) {
          for (let i = 0; i < this.selectItems.length; i++) {
            const el = this.selectItems[i];
            if(i == 0 && this.selectItems.length > 1) {
              result += el.name + '&nbsp;/&nbsp;'
            }else{
              result += el.name
            }
          }
        }
        return result
      }else{
        return this.selectItem
      }
    },
  },
  methods: {
    select(type) {
      if(this.isMultiple) {
        let indexOfElem = this.selectItems.findIndex(el => el.name == type.name)
        if(indexOfElem >= 0){ //remove item
          this.selectItems.splice(indexOfElem, 1)
        }else{ // add item
          this.selectItems.push(type)
        }
        this.$emit('select', this.selectItems)
      }else{
        this.selectItem = type.name
        this.$emit('select', type)
        this.$refs.dropdown.hide()
      }
    },
    clearAll() {
      this.selectItem = ''
      this.selectItems = []
      this.$emit('select', [])
    },
    render() {
      this.selectItem = ''
      this.selectItems = []
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
  .counterparties-drop{
    margin-top: 0;
  }
</style>