<template>
  <div class="">
    <saved-invoice v-if="lastInvoice" :activeTab="activeTab" :invoice="lastInvoice"/>
    <empty-invoice v-else @on-create-invoice="onCreate"/>
  </div>
</template>

<script>
import emptyInvoice from '@/components/vessels/voyage/emptyInvoice'
import savedInvoice from '@/components/vessels/invoice/savedInvoice'
import { mapActions, mapMutations } from 'vuex'

export default {
  components: {
    emptyInvoice,
    savedInvoice
  },
  data() {
    return {
      activeTab: 'Additional Freight Invoice',
      lastInvoice: null
    }
  },
  computed: {
  },
  methods: {
    ...mapActions(['axiosGetLastInvoiceByType']),
    ...mapMutations(['showLoad', 'hideLoad']),
    onCreate() {
      this.$router.push(`/vessels/${this.$route.params.id}/open/charterers/${this.$route.params.subId}/additional_freight/edit`)
    }
  },
  async beforeMount() {
    this.showLoad()
    this.lastInvoice = await this.axiosGetLastInvoiceByType({id: this.$route.params.subId, type: 'ADDITIONAL_FREIGHT'})
    if(!this.lastInvoice) {
      this.hideLoad()
    }
  }
}
</script>

<style>

</style>