<template>
  <div class="w100Proc position-relative z-10" v-click-outside="hideList">
    <div class="block-search w100Proc position-relative d-flex align-items-center ">
      <svg class="position-absolute l7" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
      </svg>
      <input v-on:click="show=true" type="text" v-model="search" class="h32 w100Proc pl38" placeholder="Enter Vessel Name" @input="asyncFind(search)">
    </div>
    <div class="list position-absolute w100Proc" v-if="show">
      <button class="w100Proc d-flex align-items-center h32 pl38 box-border pr5" v-for="(ship, i) in results" :key="'2;'+i" v-on:click="select(ship.name)">
        <svg class="position-absolute l7" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
        </svg>
        {{ship.name}}
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

const linkedCancelToken = axios.CancelToken;
let linkedCancel;
import axios from "axios";
import QueryString from "querystring";

export default {
  name: "location-search-ship",
  props: {
    value: [String, Object]
  },
  data() {
    return {
      show: false,
      search: '',
      isLoading: false,
      results: [],
    }
  },
  computed: {
    ...mapGetters(['getToken']),
  },
  watch: {
    value: function () {
      if(this.search !== this.value) {
        this.search = JSON.parse(JSON.stringify(this.value))
      }
    },
    search: function () {
      this.$emit('input', this.search)
    }
  },
  methods: {
    clear() {
      this.search = ''
    },
    hideList() {
      this.show = false;
    },
    select(name) {
      this.search = name
      this.hideList()
    },
    asyncFind (query) {
      this.textSearch = query

      if(linkedCancel){
        linkedCancel()
        linkedCancel = null
        this.isLoading = false
      }
      this.isLoading = true

      // this.params[this.nameSearch] = query

      axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/location/',
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
        cancelToken: new linkedCancelToken(function executor(c) {
          linkedCancel = c;
        }),
        params: {
          ordering: 'name',
          search: query,
          limit: 20
        },
        paramsSerializer: function (params) {
          return QueryString.stringify(params)
        },
      }).then(response => {
        if(!this.isChecklists) {
          this.results = response.data.results
        } else {
          let data = response.data.results
          data = (data.filter(a=> a.is_basic)).concat(data.filter(a=> !a.is_basic))
          this.results = data
        }
        this.isLoading = false
      }).catch(error => {
        if(error.message)
          console.error('searchError: ', error);
      })


      /* this.axiosFindPorts(query).then(response => {
        this.ports = response
        this.isLoading = false
      }) */
    },

  },
  mounted() {
    this.search = this.value
  }

}
</script>

<style scoped lang="scss">
  .list {
    background-color: var(--color-modal);
    border-radius: 4px;
    bottom: 0;
    transform: translateY(100%);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    max-height: 320px;
    overflow-y: auto;
  }
</style>