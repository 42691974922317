<template>
  <section class="wrap-page" v-if="getPermissionsByType('show_archive_of_payments_incomes') === true">
    <!-- <div class="top-panel-page" v-if="$route.path.indexOf('cashflow') === -1">
      <div class="container">
        <router-link to="/payment/list" class="fz12 text-gray">Payments</router-link>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
        </svg>
        <p class="fz12 text-gray">Archive payments</p>
      </div>
    </div> -->
    <bread
      v-if="$route.path.indexOf('cashflow') === -1"
      :allowOptions="false"
      :links="[
        {name: 'Payments', link: '/payment/list'}, 
        {name: `Archive payments`, link: ''}
      ]"
    />

    <payment-modal-delete @render="renderList" ref="modalDelete"></payment-modal-delete>
    <div class="container">
      <div class="top-panel">
<!--        <p class="fz20 fw500 text-dark">Archive payments</p>-->
<!--        <dropdown-sort-by-payment-archive @changeSort="changeSort" class="sortPayment"></dropdown-sort-by-payment-archive>-->
<!--        <dropdown-choosed-status-for-payment-archive @changeStatusSelected="changeStatusSelected" class="choosedStatus"></dropdown-choosed-status-for-payment-archive>-->

        <payment-panel-select-period :shortFilterView="true" class="panel-select-period select-period" @changeDate="emitChangeDate"></payment-panel-select-period>
<!--        <input type="text" class="search border-blue-dark" @focusout="searchOut" v-model="searchText" v-on:keydown.enter="searchOut">-->
      </div>
<!--      <payment-list-archive @renderList="renderList"></payment-list-archive>-->
      <payment-list-component v-if="getShowPaymentList()" ref="paymentList" v-click-outside="focusFalse" @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" @renderList="renderList"></payment-list-component>
      <virtual-payment-list @changeOrdering="changeOrdering" @scrollBottomEnd="getNextLinkPayment && getPaymentListOriginal.length !== 0 && !showLoader ? paymentNext() : null" v-else @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" ref="paymentList"></virtual-payment-list>
<!--      <pagination-panel-default class="pagination-panel" @load-more="paymentNext()" :if-active="showLoader" v-if="getNextLinkPayment && getPaymentListOriginal.length !== 0"></pagination-panel-default>-->
      <loader-for-autoload v-if="showLoader && getNextLinkPayment && getPaymentListOriginal.length !== 0"></loader-for-autoload>
      <div class="pb55" v-else></div>

    </div>
<!--    sd-->
<!--    {{getPermissionAccessThisPage()}}-->
  </section>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import PaymentPanelSelectPeriod from "@/components/payment/payment-panel-select-period";
import Vue from 'vue';
import moment from 'moment';
import PaymentModalDelete from "@/components/payment/payment-modal-delete";
import PaymentListComponent from "@/components/payment/payment-list/payment-list-component";
import bread from '@/components/reuse/bread'
import VirtualPaymentList from "@/components/payment/payment-list/virtual-payment-list";
import LoaderForAutoload from "@/components/loader-for-autoload";
Vue.prototype.$moment = moment;
export default {
  name: "archivePayment",
  components: {
    LoaderForAutoload,
    VirtualPaymentList,
    PaymentListComponent,
    PaymentModalDelete,
    PaymentPanelSelectPeriod,
    bread
  },
  computed: {
    ...mapGetters(["getPaymentListOriginal", "getPermissionsByType", "getNextLinkPayment"]),
  },
  data() {
    return {
      orderingName: '',
      status_ordering: true,
      due_date_ordering_status: true,
      element: null,
      keyRender: -1000,
      dateSelected: [],
      filterData: null,
      filterStatus: {
        paymentCheckbox: [],
        incomeCheckbox: [],
        searchText: "",
        sortText: "",
      },
      searchText: "",
      searchTextCopy: "",
      showLoader: false,
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_archive_of_payments_incomes") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
        next(from);
      }
    })
  },
  methods: {
    ...mapActions(["axiosGetPayment", "axiosGetPaymentArchive", "axiosPaymentNextLink"]),
    ...mapMutations(["cancelPostPayment"]),
    getShowPaymentList() {
      return false
    },
    focusFalse() {
      this.$refs.paymentList.focusFalse()
    },
    changeOrderingStatus(data) {
      this.status_ordering = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrderingDueDate(data) {
      this.due_date_ordering_status = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrdering(ordering) {
      this.orderingName = ordering
      this.axiosChangeGetList();
    },
    paymentNext() {
      this.showLoader = true;
      this.axiosPaymentNextLink().then(()=> {
        this.showLoader = false;
      })
    },
    getPermissionAccessThisPage() {
      return this.getPermissionsByType("show_archive_of_payments_incomes");
    },
    changeSort(text) {
      if(this.filterStatus.sortText !== text) {
        this.filterStatus.sortText = text;
        this.axiosChangeGetList();
      }
    },
    changeStatusSelected(data) {
      if(data.paymentCheckbox !== this.filterStatus.paymentCheckbox || data.incomeCheckbox !== this.filterStatus.incomeCheckbox) {
        this.filterStatus.paymentCheckbox = data.paymentCheckbox;
        this.filterStatus.incomeCheckbox = data.incomeCheckbox;
        this.axiosChangeGetList();
      }
    },
    searchOut() {
      if(this.searchText !== this.searchTextCopy) {
        this.searchTextCopy = this.searchText
        this.filterStatus.searchText = this.searchText;
        this.axiosChangeGetList();
      }
    },
    axiosChangeGetList() {
      let before = "";
      let after = "";
      console.log("DATEEE324234234234234234234")
      console.log(this.dateSelected);
      if(""+this.dateSelected[0] !== 'null' && this.dateSelected[0].length !== 0) {
        before = ""+moment(this.dateSelected [0]).utcOffset(0, true).format("YYYY-MM-DD")+ " 00:00:00";
      }
      if(""+this.dateSelected[1] !== 'null' && this.dateSelected[1].length !== 0) {
        after = ""+moment(this.dateSelected [1]).utcOffset(0, true).format("YYYY-MM-DD")+ " 23:59:59";
      }
      let orderExecuteDate = ''
      if(this.status_ordering) {
        orderExecuteDate += '-status'
      } else {
        orderExecuteDate += 'status'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-paid_date'
      } else {
        orderExecuteDate += ',paid_date'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-execute_date'
      } else {
        orderExecuteDate += ',execute_date'
      }
      orderExecuteDate = this.orderingName ? this.orderingName : orderExecuteDate


      this.axiosGetPayment({ordering: orderExecuteDate, before: before,after: after, filter: this.filterStatus, is_archived: true}).then(()=> {
        this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
      });
    },
    openFilter() {
      this.$refs.filter.showMenu();
    },
    clickEditPaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "edit");
    },
    clickDuplicatePaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "duplicate");
    },
    emitChangeDate(date) {
      this.cancelPostPayment();
      this.dateSelected = date;
      this.axiosChangeGetList();
    },
    showModalCreate() {
      this.$refs.modalCreate.openModal();
      this.keyRender++;
    },
    renderList() {
      this.emitChangeDate(this.dateSelected);
    },
    clickDelete(id) {
      this.$refs.modalDelete.openModal(id);
    },
  },
  mounted() {
    // if(this.getPermissionsByType("show_archive_of_payments_incomes") === false) {
    //   this.$router.push("/employees")
    // }
  }
}
</script>

<style scoped lang="scss">
@import "arhivePayment";
</style>