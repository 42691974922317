<template>
  <div class="p-b-15">
    <div class="flex p-x-6 p-y-3 top-panel">
      <h1 class="fz20 fw500 m-r-auto">{{isArchive ? 'Archive Ports / Canals':'Ports / Canals'}}</h1>

      <router-link to="/ports/archive" class="fz0 svg-white-dart-stroke" v-if="!isArchive">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.12 9.96h1.6l-.96 9.28H5.96L5 9.96h1.76m11.36 0l.48-2.56h-2.08m1.6 2.56H6.76m0 0L6.12 7.4H8.2m0 0L7.72 5H17l-.48 2.4m-8.32 0h8.32m-6.56 5.92h4.8" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </router-link>

      <button type="button" class="fz0 m-l-4 svg-white-dart" @click="$emit('on-add')" v-if="!isArchive && getPermissionsByType('create_ports')">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.222 11.222h-5.444V5.778a.778.778 0 00-1.556 0v5.444H5.778a.778.778 0 000 1.556h5.444v5.444a.778.778 0 001.556 0v-5.444h5.444a.778.778 0 000-1.556z" fill="#2C2C2C"/></svg>
      </button>
    </div>

    <label class="block relative m-x-6 m-b-3">
      <input class="p-l-30px p-r-30px h-8" type="text" placeholder="Search" v-model.lazy="filters.search">

      <span class="absolute top-1 left-2 svg-white-dart fz0">
        <svg class="" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.154 18.291l-4.171-4.17a5.777 5.777 0 001.294-3.648 5.812 5.812 0 00-5.806-5.805 5.812 5.812 0 00-5.805 5.805 5.812 5.812 0 005.806 5.806c1.38 0 2.649-.486 3.647-1.294l4.17 4.17a.61.61 0 00.864 0 .61.61 0 000-.864zm-8.682-3.234a4.588 4.588 0 01-4.584-4.584 4.588 4.588 0 014.584-4.583 4.588 4.588 0 014.583 4.583 4.588 4.588 0 01-4.583 4.584z" fill="#2C2C2C"/></svg>
      </span>


      <div class="absolute top-1 right-2 flex items-center">
        <div class="flex items-center m-r-1 p-x-2 p-y-0.5 fz12 text-white bg-blue rounded-full cursor-pointer" v-if="filtersCounter" @click="clearAll">
          {{filtersCounter}}
          <svg class="m-l-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.05373 3.92533C2.81022 3.68421 2.81022 3.29329 3.05373 3.05217C3.29724 2.81106 3.69206 2.81106 3.93557 3.05217L8.94574 7.97332C9.18925 8.21444 9.18925 8.60536 8.94574 8.84647C8.70223 9.08759 8.30741 9.08759 8.0639 8.84647L3.05373 3.92533Z" fill="white"/><path d="M8.06398 3.15356C8.3075 2.91245 8.70231 2.91245 8.94582 3.15356C9.18933 3.39468 9.18933 3.7856 8.94582 4.02672L3.93565 8.94786C3.69214 9.18898 3.29732 9.18898 3.05381 8.94786C2.8103 8.70675 2.8103 8.31582 3.05381 8.07471L8.06398 3.15356Z" fill="white"/></svg>
        </div>
        <button @click="$emit('on-filters')" type="button" class="fz0 svg-white-dart">
          <svg :class="{
            'fill-blue': filtersCounter
          }" width="24" height="24" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M9.286 3.738c-1.057 0-1.956.723-2.246 1.713H4.634c-.37 0-.634.294-.634.669 0 .374.29.668.66.668h2.406a2.335 2.335 0 002.247 1.713 2.367 2.367 0 002.273-1.713h6.753c.37 0 .661-.294.661-.668a.658.658 0 00-.66-.67h-6.754c-.317-.99-1.216-1.712-2.3-1.712zm.027 1.338c.555 0 1.03.455 1.03 1.044 0 .588-.475 1.043-1.03 1.043S8.282 6.708 8.282 6.12c0-.59.476-1.044 1.03-1.044zM14.242 9.615c-1.057 0-1.956.722-2.246 1.712H4.66c-.397 0-.661.294-.661.669 0 .374.29.669.66.669h7.336a2.335 2.335 0 002.246 1.712 2.367 2.367 0 002.273-1.712h1.824c.37 0 .661-.295.661-.67a.658.658 0 00-.66-.668h-1.825a2.367 2.367 0 00-2.273-1.712zm.027 1.337c.555 0 1.03.455 1.03 1.044 0 .588-.475 1.043-1.03 1.043s-1.031-.454-1.031-1.043.45-1.044 1.03-1.044zM9.458 15.764c-1.057 0-1.956.722-2.247 1.712h-2.55c-.397 0-.661.294-.661.669 0 .374.29.669.66.669h2.578a2.335 2.335 0 002.247 1.712 2.367 2.367 0 002.273-1.712h6.581c.37 0 .661-.295.661-.67a.658.658 0 00-.66-.668H11.73a2.367 2.367 0 00-2.273-1.712zm.027 1.337c.555 0 1.03.455 1.03 1.044 0 .588-.475 1.043-1.03 1.043s-1.031-.455-1.031-1.043c.026-.589.476-1.044 1.03-1.044z"/></svg>
        </button>
      </div>
    </label>

    <div v-if="countryList && Object.keys(countryList).length">
      <div 
        class="p-x-6 p-y-2 fw500 text-dark-gray cursor-pointer hover:bg-gray-darkest hover:text-blue-dark overflow-hidden overflow-ellipsis whitespace-nowrap"
        :class="{
          'bg-gray-darkest text-blue-dark': active == country
        }"
        :title="country"
        v-for="(ports, country) in countryList" 
        :key="country" 
        @click="$emit('on-select', country)"
      >{{country}}</div>
    </div>

    <pagination class="m-x-6 m-t-2" @load-more="$emit('on-load-next')" :ifActive="ifLoaded" v-if="nextRequest"/>
  </div>
</template>

<script>
import pagination from '@/components/ports/paginationDefault'
import { mapGetters } from 'vuex';

export default {
  props: {
    isArchive: {
      type: Boolean,
      default: false
    },
    countryList: Object,
    active: String,
    ifLoaded: Boolean,
    nextRequest: [String, Number],
    filters: Object
  },
  components: {
    pagination
  },
  computed: {
    ...mapGetters(['getPermissionsByType']),
    filtersCounter() {
      let count = 0
      if(this.filters.draft.min || this.filters.draft.max) count++
      if(this.filters.loa.min || this.filters.loa.max) count++
      if(this.filters.beam.min || this.filters.beam.max) count++
      count += this.filters.selectBunkering.length
      count += this.filters.selectWater.length
      return count
    }
  },
  methods: {
    clearAll() {
      this.filters.draft.min = ''
      this.filters.draft.max = ''
      this.filters.loa.min = ''
      this.filters.loa.max = ''
      this.filters.beam.min = ''
      this.filters.beam.max = ''
      this.filters.selectBunkering = []
      this.filters.selectWater = []
    }
  }
}
</script>

<style scoped lang="scss">
  .top-panel {
    button, a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>