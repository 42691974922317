<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">Edit {{type=='type'? 'type' : 'subtype'}} name <button class="modal-close" @click="closeModal()"></button></p>
        <label class="block-input" :class="{ 'block-input_error': $v.input.$error }">
          <p class="block-input_title text-gray" v-if="type == 'type'">Type Name</p>
          <p class="block-input_title text-gray" v-else>Subtype Name</p>
          <input 
            ref="input" 
            type="text" 
            class="h36 block-input_content" 
            minlength="2" 
            maxlength="256" 
            v-model.trim="$v.input.$model" 
            @keyup.enter="onSubmit"
            :disabled="isCanEdit"
          >
          <div class="error" v-if="!$v.input.required && $v.input.$dirty">Field is required</div>
        </label>
        <div class="m-t-2 fz14" v-if="isCanEdit && type == 'type'">The type Company account can not be renamed. It is used for subtype Broker.</div>
        <div class="m-t-2 fz14" v-if="isCanEdit && type != 'type'">The subtype Broker can not be renamed. It is used in the detailed information about Counterparty.</div>

        <counterparties-drop
          v-if="type == 'type'"
          :list="getTrialsInDrop"
          :activeItem="activeAccounts"
          :isMultiple="true"
          @select="onSelectAccount"
          title="Account"
        ></counterparties-drop>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal()">Cancel</button>
        <button class="btn btn-next" @click="onSubmit">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions, mapState, mapGetters} from 'vuex';
import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: 'type'
    },
    data: {
      type: Object,
    }
  },
  components: {
    counterpartiesDrop
  },
  data() {
    return {
      input: '',
      accounts: [],
      activeAccounts: []
    }
  },
  validations: {
    input: {
      required
    }
  },
  computed: {
    ...mapState({
      trials: state => state.trialBalance.trials
    }),
    ...mapGetters(["getPermissionsByType"]),
    getTrialsInDrop() {
      if(!this.trials?.length) return []
      return this.trials.map(el => {
        return {id: el.id, name: el.account_id+': '+el.name}
      })
    },
    isCanEdit() {
      return this.data.name.toLowerCase() == 'broker' || this.data.name.toLowerCase() == 'company accounts'
    }
  },
  methods: {
    ...mapActions(['axiosEditClientType', 'axiosEditClientSubtype', 'axiosGetTrials']),
    ...mapMutations(['showLoad', 'hideLoad']),
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      this.showLoad()
      if(this.type == 'type'){
        this.axiosEditClientType({
          name: this.input, 
          id: this.data.id, 
          account: this.accounts.map(el=>el.id)
        }).then(responce => {
          this.hideLoad()
          this.$emit('close')
          this.$emit('update')
          console.log(responce);
        })
      }else if(this.data){
        this.axiosEditClientSubtype({name: this.input, id: this.data.id, parent_id: this.data.parent_id}).then(responce => {
          this.hideLoad()
          this.$emit('close')
          this.$emit('update')
          console.log(responce);
        })
      }
    },
    onSelectAccount(item) {
      this.accounts = item
    }
  },
  beforeMount() {
    this.input = this.data.name
    this.activeAccounts = this.data.account?.map(el=>{
      return {id: el.id, name: el.account_id+': '+el.name}
    })
  },
  mounted() {
    if(!this.trials.length)
      this.axiosGetTrials()
    
    this.$refs.input.focus();
  }
}
</script>

<style lang="scss">

</style>