<template>
  <div v-click-outside="hide" class="relative z-10">
    <div class="selected h32 w100Proc p10-12 box-border d-flex align-items-center position-relative" v-on:click="show = !show">
      <p class="fz13 text-dark">{{value}}</p>
      <div class="position-absolute r8">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.72425 4.39844C2.45697 4.39844 2.32312 4.72158 2.51211 4.91057L5.78786 8.18631C5.90502 8.30346 6.09497 8.30346 6.21212 8.18631L9.48785 4.91057C9.67684 4.72158 9.54299 4.39844 9.27572 4.39844H2.72425Z" fill="#2C2C2C"/>
        </svg>
      </div>
    </div>
    <div class="list overflow-y-auto" v-if="show">
      <button class="pl12 fz13 text-dark text-align-left d-flex align-items-center justify-between" v-for="(item, i) in listRegion" :key="'sdds'+i" v-on:click="select(item)">
        {{item}}
        <svg v-if="item === value" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String],
  },
  name: "location-drop-zones",
  data() {
    return {
      show: true,
      listRegion: [
        'Med-Bsea',
        'Baltic - Continent',
        'WAFR',
        'Indian Ocean',
        'Red Sea',
        'SE Asia',
        'AUSSIE',
        'WC America',
        'USEC',
        'USG-NCSA',
        'ECSA',
        'Far East',
        'NOPAC',
      ]
    }
  },
  methods: {
    hide() {
      this.show = false
    },
    select(name) {
      if(name === this.value) {
        this.$emit('input', '')
      } else {
        this.$emit('input', name)
      }
      this.show = false
    },
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
  .selected {
    background-color: var(--color-input);
    border: 1px solid var(--color-border);
    box-sizing: border-box;
    border-radius: 4px;
  }
  .list {
    border-radius: 4px;
    background-color: var(--color-bgc-page-white);
    position: absolute;
    top: 33px;
    width: 100%;
    border: 1px solid var(--color-border);
    box-sizing: border-box;
    max-height: 150px;
    button {
      width: 100%;
      height: 30px;

    }
  }
</style>