import axios from 'axios'
import moment from 'moment';


export default {
    actions: {
        getRouteForShip(ctx, id) {
            // ctx.commit("addLoadStatus");
            ctx.commit("showLoad")
            return axios.get(process.env.VUE_APP_URL+"/route/index/?ship_type=" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                ctx.commit("hideLoad")
                ctx.commit("setRouterForShip", response.data.results);
                // ctx.commit("decLoadStatus");
            }).catch(()=> {
                ctx.commit("hideLoad")
                // ctx.commit("decLoadStatus");
            })
        },
        getAmountForRoutes(ctx, data) {
            ctx.commit("addLoadStatus");
            // console.log(22)
            return axios.get(process.env.VUE_APP_URL+"/index", {
                params: {
                    route: data.id,
                    created_at_after: data.created_at_after,
                    created_at_before: data.created_at_before,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("decLoadStatus");
                ctx.commit("setAmount", response.data.results);
            }).catch(()=> {
                ctx.commit("decLoadStatus");
            })
        },
        async createAxiosIndex(ctx, data) {
            // ctx.commit("addLoadStatus");
            return await axios.post(process.env.VUE_APP_URL+"/index/", {
                "amount": data.amount,
                "created_at": data.created_at,
                "ship_type": data.ship_type,
                "route": data.route_id,
            },{
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(res=> {
                return res.data
                // ctx.commit("decLoadStatus");
            }).catch(()=> {
                // ctx.commit("decLoadStatus");
            })
        },
        updateAxiosIndex(ctx, data) {
            // ctx.commit("addLoadStatus");
            return axios.patch(process.env.VUE_APP_URL+"/index/" + data.id, {
                "amount": data.amount,
                "created_at": data.created_at,
                "ship_type": data.ship_type,
                "route": data.route_id,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                // ctx.commit("decLoadStatus");
            }).catch(()=> {
                // ctx.commit("decLoadStatus");
            })
        },
        async deleteAxiosIndex(ctx, id) {
            // ctx.commit("addLoadStatus");
            return await axios.delete(process.env.VUE_APP_URL+"/index/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                // ctx.commit("decLoadStatus");
            }).catch(()=> {
                // ctx.commit("decLoadStatus");
            });
        }
    },
    mutations: {
        addLoadStatus(state) {
            state.loadStatus +=1;
        },
        decLoadStatus(state) {
            state.loadStatus -=1;
        },
        setRouterForShip(state, data) {
            state.routerForShip.splice(0,state.routerForShip.length);
            state.routerForShip = data;
            state.routesForShip.sort((a,b)=> a.sorting_index > b.sorting_index ? 1: -1);
        },
        setAmount(state, data) {
            // if(data.)
            // console.log(data[0]);
            if(data.length !== 0) {
                data.sort((a, b) => moment(a.created_at, 'YYYY-MM-DD HH:mm:ss') - moment(b.created_at, 'YYYY-MM-DD HH:mm:ss'))
                if(data[0].amount !== undefined && data[0].amount !== null) {
                    // // console.log("YES")
                    state.amount.amount = data[0].amount;
                    state.amount.index_id = data[0].id;
                    state.amount.created_at = data[0].created_at
                } else {
                    state.amount.amount = "";
                    state.amount.index_id = "";
                    state.amount.created_at = "";
                }
            } else {
                state.amount.amount = "";
                state.amount.index_id = "";
                state.amount.created_at = ""
            }
        }

    },
    state: {
        routerForShip: [],
        amount: {
            amount: "",
            index_id: "",
            date: "",
        },
        loadStatus: 0,
    },
    getters: {
        getLoadStatus(state) {
            return state.loadStatus;
        },
        getRoutesShip(state) {
            return state.routerForShip.sort((a,b)=> a.sorting_index > b.sorting_index ? 1: -1);
        },
        getAmount(state) {
            return state.amount;
        }
    },
}
