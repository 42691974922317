<template>
  <div class="port_activity">
    <h2 class="m-b-3 fz18 fw500 text-dark">Activity</h2>

    <activity-item v-for="item in activity" :key="item.id" :item="item" />
  </div>
</template>

<script>
import activityItem from '@/components/ports/detail/activityItem'

export default {
  components: {
    activityItem
  },
  props: {
    activity: Array
  },

}
</script>

<style lang="scss">
  .port_activity{
    &__row{
      &>div{
        margin-top: 12px;
      }
    }
    &__date{
      width: 100%;
      margin-bottom: 4px;
      color: #9AA3B0;
    }
    &__info{
      font-size: 14px;
    }
    &__user{
      display: inline;
      color: var(--color-blue-dark);
    }
    &__type{
      display: inline;
      color: #4B4B4B;
      i{
        font-style: normal;
        color: #9AA3B0;
      }
      svg,
      img{
        display: block;
        max-width: 50px;
        max-height: 50px;
        margin-top: 6px;
        object-fit: cover;
      }
    }
  }
</style>