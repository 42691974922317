<template>
  <div class="async_select__wrap" :class="{'hideOthersElement':searchInputActive}">
    <div class="flex items-center justify-between flex-wrap">
      <label :for="id" class="block text-gray m-b-1 fz13" v-if="label">{{label}} <span v-if="importedData" class="text-red">*</span></label>
      <slot name="additional-label"></slot>
    </div>
    <svg class="search-svg" v-if="showSearchSvg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
    </svg>
    <multiselect
      :id="id"
      :class="{
        'error': isErrorRequired
      }"
      autocomplate="false"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      @open="onOpen()"
      :label="watchKey"
      :options="results"
      track-by="id"
      :multiple="isMultiple"
      :disabled="disabled"
      :taggable="false"
      :showLabels="false"
      :allowEmpty="allowEmpty"
      :clearOnSelect="clearOnSelect"
      :internalSearch="false"
      :preserveSearch="true"
      :loading="isLoading"
      :closeOnSelect="closeOnSelect"
      :maxHeight="200"
      :openDirection="openDirection"
      @search-change="asyncFind"
      :ref="'multiselect' + id"
      :text="'sdsd'"
      @close="close"
    >

      <template slot="singleLabel" slot-scope="props">
        <span class="fz14">
          {{beforeWatchKey ? props.option[beforeWatchKey]+': ' : ''}}
          {{props.option[watchKey]}}
          <template v-if="afterWatchKey && props.option[afterWatchKey]">
            <template v-if="afterWatchKey == 'cp_date'">
              ({{'('+props.option[afterWatchKey]+')' | formatDateFromCpDateWithotTimeZome}})
            </template>
            <template v-else>
              ({{props.option[afterWatchKey]}})
            </template>
          </template>
<!--          {{afterWatchKey ? '('+props.option[afterWatchKey]+')' : ''}}-->
        </span>
      </template>
      <template slot="option" slot-scope="props">
        <span class="flex items-center">
           <svg class="search-svg" v-if="showSearchSvg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
    </svg>
          {{beforeWatchKey ? props.option[beforeWatchKey]+': ' : ''}}
          {{props.option[watchKey]}}
          <template v-if="afterWatchKeyBrackets && props.option[afterWatchKeyBrackets]">
            <template v-if="afterWatchKeyBrackets == 'cp_date'">
              ({{props.option[afterWatchKeyBrackets] | formatDateFromCpDateWithotTimeZome}})
            </template>
            <template v-else>
              ({{props.option[afterWatchKeyBrackets]}})
            </template>
          </template>
          <svg class="img-profile" v-if="isChecklists && props.option.is_basic" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#6B8DAA"/>
          <path d="M9.86719 14H5.90918V4.13574H9.84668C10.7217 4.13574 11.4235 4.36133 11.9521 4.8125C12.4808 5.25911 12.7451 5.85384 12.7451 6.59668C12.7451 7.12077 12.5742 7.59017 12.2324 8.00488C11.8952 8.41504 11.4805 8.66113 10.9883 8.74316V8.79785C11.6628 8.87533 12.2051 9.13509 12.6152 9.57715C13.0254 10.0146 13.2305 10.5592 13.2305 11.2109C13.2305 12.0814 12.932 12.765 12.335 13.2617C11.738 13.7539 10.9154 14 9.86719 14ZM7.44043 5.38672V8.33301H9.15625C9.81706 8.33301 10.3252 8.20085 10.6807 7.93652C11.0361 7.6722 11.2139 7.29622 11.2139 6.80859C11.2139 6.35286 11.0635 6.00195 10.7627 5.75586C10.4619 5.50977 10.0335 5.38672 9.47754 5.38672H7.44043ZM7.44043 12.749H9.55273C10.2409 12.749 10.765 12.61 11.125 12.332C11.485 12.054 11.665 11.6484 11.665 11.1152C11.665 10.0397 10.9313 9.50195 9.46387 9.50195H7.44043V12.749Z" fill="white"/>
          </svg>
          <img class="img-profile" v-else-if="isChecklists && !props.option.is_basic && props.option.creator.avatar" :src="getUrlForAvatar(props.option.creator.avatar)" alt="">
          <svg v-else-if="isChecklists && !props.option.is_basic" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="80" cy="80" r="80" fill="white"/>
          <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
          </svg>

          <svg class="option_check m-l-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
        </span>
      </template>

      <template slot="selection" slot-scope="{ values, isOpen }">
        <input type="text" class="search-input-main" v-show="searchInputActive" ref="searchInputActive" v-model="textSearch" @focus="openDropdown" @blur="closeDropdown" @input="asyncFind(textSearch)">

        <span class="multiselect__single p-0 inline-flex flex-wrap items-center fz14" v-if="values.length >= 1 && !isOpen">
          {{beforeWatchKey ? values[0][beforeWatchKey]+': ' : ''}}
          {{values[0][watchKey]}}
          <template v-if="afterWatchKeyBrackets && values[0][afterWatchKeyBrackets]">
            <template v-if="afterWatchKeyBrackets == 'cp_date'">
              ({{values[0][afterWatchKeyBrackets] | formatDateFromCpDateWithotTimeZome}})
            </template>
            <template v-else>
              ({{values[0][afterWatchKeyBrackets]}})
            </template>
          </template>
          <span class="m-l-1" v-if="values.length > 1">/
            <span class="inline-flex items-center justify-center h-5 min-w-5 rounded-full border border-dark fz11">+{{ values.length-1 }}</span>
          </span>
        </span>
      </template>
    </multiselect>

    <div class="text-red fz12" v-if="isErrorRequired">Field is required</div>
  </div>
</template>

<script>
import axios from 'axios'
const linkedCancelToken = axios.CancelToken;
let linkedCancel;

import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import QueryString from "querystring";
export default {
  props: {
    showSearchSvg: Boolean,
    clearTextSearchAfterClose: Boolean,
    isChecklists: Boolean,
    searchInputActive: {
      type: Boolean,
      default: false,
    },
    searchInputText: {
      type: String,
      default: '',
    },
    value: [Object, Array],
    isMultiple: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      require: true
    },
    disabled: Boolean,
    openDirection: {
      type: String,
      default: 'bottom'
    },
    label: String,
    isErrorRequired: Boolean,
    allowEmpty: Boolean,
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true
    },
    params: Object,
    watchKey: {
      type: String,
      default: 'name'
    },
    placeholder: {
      type: String,
      default: 'Type to search'
    },
    afterWatchKey: String,
    beforeWatchKey: String,
    afterWatchKeyBrackets: String,
    nameSearch: {
      type: String,
      default: "search"
    },
    importedData: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      results: [],
      textSearch: '',
    }
  },
  computed: {
    ...mapGetters(['getToken']),
  },
  watch: {
    searchInputText: function () {
      this.textSearch = this.searchInputText !== null ? this.searchInputText : '';
    },
    isLoading: function() {
      this.$emit('on-change-loading', this.isLoading)
    }
  },
  methods: {
    getUrlForAvatar(link) {
      if(link.indexOf(this.getURL()) >= 0) {
        return link;
      }
      return this.getURL() + link;
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
    refreshList() {
      this.results = []
      this.asyncFind(this.textSearch);
    },
    asyncFind (query) {
      this.textSearch = query

      if(linkedCancel){
        linkedCancel()
        linkedCancel = null
        this.isLoading = false
      }
      this.isLoading = true

      // this.params[this.nameSearch] = query
      let search = {}
      if(this.params.is_charter_name) {
        search = {
          charter_name: query
        }
      } else {
        search = {
          search: query
        }
      }
      axios({
        method: 'get',
        url: process.env.VUE_APP_URL+this.url,
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
        cancelToken: new linkedCancelToken(function executor(c) {
          linkedCancel = c;
        }),
        params: {
          ...this.params,
          ...search,
          limit: 20
        },
        paramsSerializer: function (params) {
          return QueryString.stringify(params)
        },
      }).then(response => {
        console.log('response', response)
        if(this.url.indexOf('netpas') === -1) {
          console.log(1)
          if(!this.isChecklists) {
            this.results = response.data.results
          } else {
            let data = response.data.results
            data = (data.filter(a=> a.is_basic)).concat(data.filter(a=> !a.is_basic))
            this.results = data
          }
          this.isLoading = false
        } else {
          console.log(2)
          console.log('response', response, response.data)
          this.results = response.data
          this.isLoading = false
        }

      }).catch(error => {
        this.isLoading = false
        if(error.message)
          console.error('searchError: ', error);
      })


        /* this.axiosFindPorts(query).then(response => {
          this.ports = response
          this.isLoading = false
        }) */
    },
    onOpen() {
      if(this.results.length == 0)
        this.asyncFind(this.textSearch)
    },
    onInput(value) {
      this.$emit('input', value)
      this.$emit('on-select', value, this.value)
    },
    close() {
      this.isLoading = false
      if(linkedCancel){
        // linkedCancel()
        linkedCancel = null
        // this.isLoading = false
      }
      // if(this.clearTextSearchAfterClose) {
      //   this.textSearch = ''
      //   this.$refs.searchInputActive.value = ''
      //   console.log(this.$refs.searchInputActive)
      // }
      this.$emit("close")
    },
    openDropdown(focusOn) {
      if(!this.disabled) {
        this.$refs['multiselect'+this.id].isOpen = true;
        if(focusOn === true) {
          if(this.searchInputActive) {
            this.$refs.searchInputActive.focus();
          } else {
            this.$refs['multiselect'+this.id].$refs.search.focus()
          }
        }
        this.onOpen();
      }

    },
    closeDropdown() {
      this.$refs['multiselect'+this.id].isOpen = false;
      this.$emit('changeTextSelectInput', this.textSearch)
    }
  },
  mounted() {
    this.textSearch = this.searchInputText !== null ? this.searchInputText : '';
  }
}
</script>

<style lang="scss">
.async_select__wrap{
  .multiselect--disabled{
    background-color: transparent;
    opacity: 1;
    .multiselect__single{
      color: var(--color-dark-gray);
    }
    .multiselect__tags{
      color: var(--color-dark-gray);
    }
  }
  .multiselect__tags-wrap{
    display: none;
  }
  .multiselect__option{
    min-height: auto;
    padding: 0.25rem 0.25rem 0.25rem 0.75rem;
    background-color: transparent;
    white-space: normal;
    word-break: break-word;
    .option_check{
      opacity: 0;
      flex-shrink: 0;
    }
  }
  .multiselect__option--selected{
    .option_check{
      opacity: 1;
    }
  }
  .multiselect__option--highlight{
    background-color: var(--color-hover-list) !important;
  }
  .multiselect__content{
    width: 100%;
  }
  .multiselect__single{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-dark);
  }
  .multiselect__tags{
    color: var(--color-dark);
  }
  .multiselect__select:before{
    border-width: 4px 4px 0;
  }
  .multiselect__placeholder{
    color: var(--color-input-placeholder) !important;
  }
  .multiselect__option--disabled{
    background-color: transparent !important;
  }
}
.filter-drop-height{
  .multiselect__tags{
    min-height: 32px;
    padding-top: 4px;
  }
}
.multiselect .multiselect__select{
  height: calc(100% - 2px);
}

.dart-mode {
  //.multiselect__input {
  //  &::placeholder {
  //    color: var(--color-gray);
  //  }
  //}
}
.hideOthersElement {
  .multiselect__tags-wrap {
    display: none !important;
  }
  //.multiselect__spinner {
  //  display: none !important;
  //}
  .multiselect__input {
    display: none !important;
  }
  .multiselect__single {
    display: none !important;
  }
  .search-input-main {
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent !important;
  }
}
.async_select__hide_main_input{
  .multiselect__select{
    display: none;
  }
  .multiselect__tags{
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.img-profile {
  margin-left: 8px;
  width: 18px;
  height: 18px;
  border-radius: 50px;
}
.search-svg {
  position: absolute;
  z-index: 70;
  top: 4px;
  left: 7px;
}
/deep/ {
  .multiselect__content-wrapper {
    min-height: 50px;
  }
}
</style>
