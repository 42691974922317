import axios from 'axios'
// import * as QueryString from "querystring";
import Vue from 'vue';
import moment from 'moment';
import QueryString from "querystring";
// import moment from "moment"

Vue.prototype.$moment = moment;

export default {
    actions: {
        axiosChangeVesselTask(ctx, data) {
            // console.log(data);
            // return axios.path(process.env.VUE_APP_URL + "/search/ship/task/" + data.id, data.data, {
            //     headers: {
            //         'Authorization': 'token ' + ctx.getters.getToken,
            //     }
            // }).then(()=> {
            //     return true;
            // }).catch(()=> {
            //     return false
            // })
            return axios({
                    method: 'patch',
                    url: process.env.VUE_APP_URL + "/search/ship/task/" + data.id,
                    data: data.data,
                    headers: {
                                'Authorization': 'token ' + ctx.getters.getToken,
                    }
                });
        },
        axiosCreateTask(ctx, data) {
            // console.log('taskcre', data)
            return axios.post(process.env.VUE_APP_URL+"/task/", {
                "name": data.name,
                "description": data.description,
                "execute_time": (data.execute_time !== null && (""+data.execute_time).length !== 0) ? moment(data.execute_time).format("YYYY-MM-DD HH:mm") : null,
                "is_archived": false,
                "status": data.status,
                "type": data.type,
                "priority": data.priority,
                "vessel": data.ship,
                "executors": data.executors,
                "tags": data.tags,
                "checklists": data.check_list,
                "due_date_status": data.due_date_status,
                "days_for_repeat": data.days_for_repeat,
            },{
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                // console.log("response")

                // console.log(response)
                ctx.commit("setResponseCreateTask", response.data);
                ctx.commit("addTip", response.data.name + " was added")
            });
        },
        async axiosAddFileToTask(ctx, data) {
            let filesId = [];
            filesId = data.fileInPayment.map(a=> {return a.id});
            let notAddFile = false;
            if(data.file.length) {
                for (let i = 0; i < data.file.length; i++) {
                    let file = data.file[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
                    if(file.parent_id !== undefined && file.parent_id !== null) {
                        notAddFile = true;
                        formData.append("parent_id", file.parent_id);
                        formData.append("parent_file", file.parent_id);
                    }
                    filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
                    // console.log(file)
                }
            }
            console.log(data)
            if(!notAddFile) {
                await axios.patch(process.env.VUE_APP_URL+"/task/" + data.id, {
                    'files': filesId
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                });
            } else {
                return null
            }
        },
        axiosGetPriorityTask(ctx) {
            if(ctx.getters.getAllPriority?.length === 0) {
                return axios.get(process.env.VUE_APP_URL+"/priority/task/", {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response=> {
                    ctx.commit("setPriority", response);
                })
            } else {
                return ctx.getters.getAllPriority
            }
        },
        axiosGetTasksForFilter(ctx, data) {
            console.log(data)
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenSource", ct);
            let limit = ctx.getters.getLimit;
            if(data.limit !== undefined && data.limit !== null) {
                limit = data.limit;
            }
            ctx.commit('setLoadRequestFilterTask', true)
            let params = {
                "ordering": data.ordering,
                "limit": limit,
                "execute_date": data.execute_date,
                "create_date": data.create_date,
                execute_time_after: (data.execute_date && data.execute_date.length !== 0) ? data.execute_date[0].date_from : '',
                execute_time_before: (data.execute_date && data.execute_date.length !== 0) ? data.execute_date[0].date_to : '',
                created_at_after: (data.create_date && data.create_date.length !== 0) ? data.create_date[0].date_from : '',
                created_at_before: (data.create_date && data.create_date.length !== 0) ? data.create_date[0].date_to : '',
                "type": data.task_type,
                "is_archived": data.is_archived,
                "executors": data.executors,
                search: data.name,
            }
            if(data.creators) {
                params["creator_id"] = data.creators
            }
            if(data.status) {
                params["status"] = data.status
            }
            if(data.priority) {
                params["priority"] = data.priority
            }
            if(data.tags) {
                params["tags"] = data.tags
            }
            return axios.get(process.env.VUE_APP_URL+"/task/filter/", {
                params: params,
                paramsSerializer: params => {
                    return QueryString.stringify(params)
                },
                cancelToken: ct.token,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                console.log(response);
                ctx.commit("setCountTask", response.data.count)
                ctx.commit("setAllTasks",response.data.results)
                ctx.commit('setLoadRequestFilterTask', false)
            }).catch(()=> {
                // ctx.commit('setLoadRequestFilterTask', false)
            })
        },

        axiosGetAllTasks(ctx) {
            return axios.get(process.env.VUE_APP_URL+"/task/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setAllTasks", response.data.results);
            })
        },
        axiosGetNoArchivedTasks(ctx) {
            // ctx.commit("clearTasksList");
            return axios.get(process.env.VUE_APP_URL+"/task/?is_archived=false", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setAllTasks", response.data.results);
            })
        },
        axiosGetArchivedTasks(ctx) {
            // ctx.commit("clearTasksList");
            return axios.get(process.env.VUE_APP_URL+"/task/?is_archived=true", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setAllTasks", response.data.results);
            })
        },
        axiosDeleteTask(ctx, id) {
            return axios.delete(process.env.VUE_APP_URL+"/task/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            });
        },
        axiosChangeTask(ctx, data) {
            data.status = data.status.toLowerCase();
            data.status = data.status[0].toUpperCase() + data.status.slice(1);
            // let date = "";
            // if((""+data.execute_time).length !==0 && data.execute_time !== null) {
            //     date = "" + moment(data.execute_time).format('YYYY-MM-DD hh:mm:ss')
            // }
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenChangeTaskSource", ct);
            return axios.patch(process.env.VUE_APP_URL+"/task/" + data.id, {
                "name": data.name,
                "description": data.description,
                "execute_time": (data.execute_time !== null && (""+data.execute_time).length !== 0) ? moment(data.execute_time).format("YYYY-MM-DD HH:mm") : null,
                "is_archived": data.is_archived,
                "status": data.status,
                "type": data.type,
                "priority": data.priority,
                "executors": data.executors,
                "tags": data.tags,
                "vessel": data.ship,
                "due_date_status": data.due_date_status,
                "days_for_repeat": data.days_for_repeat,
                "checklists": data.check_list,
            }, {
                cancelToken: ct.token,
                headers: {
                    'accept': 'application/json',
                    'Content-Type':' application/json',
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
        axiosChangeManualTask(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL+"/task/" + data.id, data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            })
        },
        getAxiosInfoForTask(ctx, id) {
            id = id.replaceAll("-", "");
            return axios.get(process.env.VUE_APP_URL+"/task/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(async response=> {
                if(response.data?.files) {
                    let files = response.data.files;
                    if(Array.isArray(files) === true) {
                        for(let i=0; i<files.length; i++) {
                            if(Number.isInteger(files[i]) === true) {
                                files[i] = await ctx.dispatch('getFileInFolder', files[i])
                            }
                        }
                        response.data.files = files;
                    }
                }
                ctx.commit("setTaskInfo", response.data);
            });
        },
        async getAxiosInfoForVesselTask(ctx, id) {
            id = (""+id).replaceAll("-", "");
            // ctx.state.vesselTaskinfo = null;
            return await axios.get(process.env.VUE_APP_URL+"/search/ship/task/?task=" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setVesselTaskInfo", response.data);
            });
        },
        axiosCreateTaskVessell(ctx, data) {
            console.log(data)
            return axios.post(process.env.VUE_APP_URL+"/search/ship/task/", {
                "task": {
                    "name": data.task.name,
                    "description": data.task.description,
                    "execute_time": (data.task.execute_time !== null && (""+data.task.execute_time).length !== 0) ? moment(data.task.execute_time).format("YYYY-MM-DD HH:mm") : null,
                    "is_archived": data.task.is_archived,
                    "status": data.task.status,
                    "type": data.task.type,
                    "priority": data.task.priority,
                    "executors": data.task.executors,
                    "tags": data.task.tags,
                    "creator_id": data.task.creator_id,
                    "checklists": data.task.check_list,
                    "vessel": data.task.vessel,
                    "due_date_status": data.task.due_date_status,
                    "days_for_repeat": data.task.days_for_repeat,
                },
                "region": data.region,
                "cargo_text": data.cargo_text,
                "clients": data.clients,
                "brokers": data.brokers,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setResponseCreateTask", response.data);
                ctx.commit("addTip", response.data.task.name + " was added")
                return response;
            })
        },
        axiosCreateTaskCargo(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/search/ship/task/", {
                "task": {
                    "name": data.task.name,
                    "description": data.task.description,
                    "execute_time": (data.task.execute_time !== null && (""+data.task.execute_time).length !== 0) ? moment(data.task.execute_time).format("YYYY-MM-DD HH:mm") : null,
                    "is_archived": data.task.is_archived,
                    "status": data.task.status,
                    "type": data.task.type,
                    "priority": data.task.priority,
                    "executors": data.task.executors,
                    "tags": data.task.tags,
                    "creator_id": data.task.creator_id,
                    "checklists": data.task.check_list,
                    "due_date_status": data.task.due_date_status,
                    "days_for_repeat": data.task.days_for_repeat,
                },
                "region": data.region,
                "brokers": data.brokers,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setResponseCreateTask", response.data);
                ctx.commit("addTip", response.data.name + " was added")
            })
        },
        axiosCreateTaskOurVessel(ctx, data) {
            let ship = null;
            if(data.ship !== null) {
                ship = data.ship
            }
            return axios.post(process.env.VUE_APP_URL+"/search/ship/task/", {
                "task": {
                    "name": data.task.name,
                    "description": data.task.description,
                    "execute_time": (data.task.execute_time !== null && (""+data.task.execute_time).length !== 0) ? moment(data.task.execute_time).format("YYYY-MM-DD HH:mm") : null,
                    "is_archived": data.task.is_archived,
                    "status": data.task.status,
                    "type": data.task.type,
                    "priority": data.task.priority,
                    "executors": data.task.executors,
                    "tags": data.task.tags,
                    "creator_id": data.task.creator_id,
                    "checklists": data.task.check_list,
                    "due_date_status": data.task.due_date_status,
                    "days_for_repeat": data.task.days_for_repeat,
                    "vessel": data.task.vessel,
                },
                "region": data.region,
                "brokers": data.brokers,
                "ship": ship,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setResponseCreateTask", response.data);
                ctx.commit("addTip", response.data.task.name + " was added")
                return response;

            })
        },

        getAxiosInfoContractManual(ctx, id) {
            return axios.get( process.env.VUE_APP_URL+"/search/ship/manual/?task=" + id,{
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ctx.commit("setInfoContractManual", response.data.results);
            })
        }
    },
    mutations: {
        setLoadRequestFilterTask(state, data) {
            state.loadRequestFilterTask = data;
        },
        clearInfoContractManual(state) {
            state.infoContractManual = null;
        },
        setInfoContractManual(state, data) {
            state.infoContractManual = data[0];
        },
        cancelTokenSource(state, data) {
            state.cancelTokenSource = data;
        },
        cancelPostTaskFilter(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
            }
        },
        setLimit(state, data) {
          state.limit = data;
        },
        clearTasksList(state) {
            state.tasksList.splice(0,state.tasksList.length);
        },
        cancelTokenChangeTaskSource(state, data) {
            state.cancelTokenChangeTask = data
        },
        cancelAxiosChangeTask(state) {
            if(state.cancelTokenChangeTask !== null) {
                state.cancelTokenChangeTask.cancel();
            }
        },
        setTaskInfo(state, data) {

            state.taskInfo = data;
        },
        setVesselTaskInfo(state, data) {
            if(data.results.length !== 0) {
                state.vesselTaskinfo = data.results[0];
            } else {
                state.vesselTaskinfo = null;
            }
        },
        setVesselTaskInfoData(state, data) {
            state.vesselTaskinfo = data;
        },
        clearTaskInfo(state) {
            state.taskInfo = {};
        },
        setPriority(state, data) {
            localStorage.setItem('priority', JSON.stringify(data.data.results))
            state.priority = data.data.results;
        },
        setResponseCreateTask(state, data) {
            state.responseAfterAddTask = data;
            state.showModalResponseAfterAddTask = true;
        },
        setShowModalResponseAfterAddTask(state, data) {
            state.showModalResponseAfterAddTask = data;
        },
        setAllTasks(state, data) {
            if(Array.isArray(data) === true) {
                data.forEach(task=> {
                    task.checklists.forEach(item=> {
                        item.items = item.items.filter(a=> !a.is_hidden)
                    })
                })
            }
            state.allTasks = data;
        },
        taskRemoveById(state, id) {
          state.allTasks = state.allTasks.filter(a => a.id !== id)
        },
        conversionForTasks(state, data) {
            let resp = false;
            // console.log(1);
            // console.log(data);
            // if(data!== null && data.tags.length !==0 && data.executors.length !== 0) {

            if(data!== null ) {
                state.tasksList.splice(0,state.tasksList.length);
                for(let i=0; i<state.allTasks.length; i++) {
                    //set priority name and svg
                    let formPriority = {}
                    // console.log(i + "index0")
                    for(let j=0; j<state.priority.length; j++) {
                        if((""+state.allTasks[i].priority) === (""+state.priority[j].id)) {
                            for(let d=0; d<state.flagForPriority.length; d++) {
                                if((state.priority[j].type).toLowerCase() === (state.flagForPriority[d].name).toLowerCase()) {
                                    formPriority = {
                                        name: state.priority[j].type,
                                        id: state.priority[j].id,
                                        svg: state.flagForPriority[d].svg,
                                    }
                                }
                            }
                        }
                    }
                    //set tags
                    let tagsArray = [];
                    if(data.tags.length !==0) {
                        for(let j=0; j<state.allTasks[i].tags.length; j++) {
                            for(let n=0; n<data.tags.length; n++) {
                                // console.log(data.tags[n].id);
                                // console.log(state.allTasks[i].tags[j]);
                                if((""+state.allTasks[i].tags[j]) === (""+data.tags[n].id)) {
                                    tagsArray.push(data.tags[n]);
                                }
                            }
                        }
                    }

                    //set executors
                    let executorsArray = [];
                    if( data.executors.length !== 0) {
                        for(let j=0; j<state.allTasks[i].executors.length; j++) {
                            for(let n=0; n<data.executors.length; n++) {
                                if((""+state.allTasks[i].executors[j])===(""+data.executors[n].id)) {
                                    executorsArray.push(data.executors[n]);
                                }
                            }
                        }
                    }

                    state.tasksList.push(state.allTasks[i]);
                    state.tasksList[i].priority = formPriority;
                    state.tasksList[i].tags = tagsArray;
                    state.tasksList[i].executors = executorsArray;
                    state.tasksList[i] = JSON.parse(JSON.stringify(state.tasksList[i]));
                    if(i === state.allTasks.length - 1) {
                        resp=true;
                    }
                }
            }
            state.countTasksList = state.tasksList.length;
            // console.log(data);
            // console.log(state.allTasks);

            return resp;
        },
        conversionForTaskInfo(state, data) {
            let formPriority = {}
            for (let j = 0; j < state.priority.length; j++) {
                if (("" + state.taskInfo.priority) === ("" + state.priority[j].id)) {
                    for (let d = 0; d < state.flagForPriority.length; d++) {
                        if ((state.priority[j].type).toLowerCase() === (state.flagForPriority[d].name).toLowerCase()) {
                            formPriority = {
                                name: state.priority[j].type,
                                id: state.priority[j].id,
                                svg: state.flagForPriority[d].svg,
                            }
                        }
                    }
                }
            }
            //set tags
            let tagsArray = [];
            for (let j = 0; j < state.taskInfo.tags.length; j++) {
                for (let n = 0; n < data.tags.length; n++) {
                    // console.log(data.tags[n].id);
                    // console.log(state.taskInfo.tags[j]);
                    if (("" + state.taskInfo.tags[j]) === ("" + data.tags[n].id)) {
                        tagsArray.push(data.tags[n]);
                    }
                }
            }
            //set executors
            let executorsArray = [];
            let creator = [];
            for (let j = 0; j < state.taskInfo.executors.length; j++) {
                for (let n = 0; n < data.executors.length; n++) {
                    if (("" + state.taskInfo.executors[j]) === ("" + data.executors[n].id)) {
                        executorsArray.push(data.executors[n]);
                    }
                }
            }
            for (let n = 0; n < data.executors.length; n++) {
                if((""+state.taskInfo.creator_id) === (""+data.executors[n].id)) {
                    creator.push(data.executors[n]);
                }
            }
            state.taskInfoConvert = JSON.parse(JSON.stringify(state.taskInfo));
            state.taskInfoConvert.creator = creator;
            state.taskInfoConvert.priority = formPriority;
            state.taskInfoConvert.tags = tagsArray;
            state.taskInfoConvert.executors = executorsArray;
            state.taskInfoConvert= JSON.parse(JSON.stringify(state.taskInfoConvert));
            state.countTasksList = state.tasksList.length;
        },
        setSortTaskBy(state, data) {
            state.sortTaskBy = data;
        },
        sortTaskByName(state) {
            // state.sortTaskBy = "sortTaskByName";
            // console.log("setAllTasks")
            // console.log(state.tasksList);
            state.tasksList.sort(function(a, b){
              if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
              if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
              return 0;
          })
        },
        sortTaskByNameReverse(state) {
            // state.sortTaskBy = "sortTaskByNameReverse";
            state.tasksList.sort(function(a, b){
                if(a.name.toLowerCase() > b.name.toLowerCase()) { return -1; }
                if(a.name.toLowerCase() < b.name.toLowerCase()) { return 1; }
                return 0;
            })
        },
        sortTaskByStatus(state) {
            // state.sortTaskBy = "sortTaskByStatus";
            state.tasksList.sort(function(a, b){
                if(a.status.toLowerCase() < b.status.toLowerCase()) { return -1; }
                if(a.status.toLowerCase() > b.status.toLowerCase()) { return 1; }
                return 0;
            })
        },
        sortTaskByStatusReverse(state) {
            // state.sortTaskBy = "sortTaskByStatusReverse";
            state.tasksList.sort(function(a, b){
                if(a.status.toLowerCase() > b.status.toLowerCase()) { return -1; }
                if(a.status.toLowerCase() < b.status.toLowerCase()) { return 1; }
                return 0;
            })
        },
        sortTaskByPriority(state) {
            // state.sortTaskBy = "sortTaskByPriority";
            let arrayNoPriority= [];
            let arrayLow = [];
            let arrayNormal = [];
            let arrayHigh = [];
            let arrayUrgent = [];
            // console.log("START SORT");
            // console.log(state.tasksList);
            for(let i=0; i<state.tasksList.length; i++) {
                // console.log(state.tasksList[i])
                if(state.tasksList[i].priority.name.toLowerCase() === "no priority") {
                    arrayNoPriority.push(state.tasksList[i])
                } else if(state.tasksList[i].priority.name.toLowerCase() === "low") {
                    arrayLow.push(state.tasksList[i])
                } else if(state.tasksList[i].priority.name.toLowerCase() === "normal") {
                    arrayNormal.push(state.tasksList[i])
                } else if(state.tasksList[i].priority.name.toLowerCase() === "high") {
                    arrayHigh.push(state.tasksList[i])
                } else if(state.tasksList[i].priority.name.toLowerCase() === "urgent") {
                    arrayUrgent.push(state.tasksList[i])
                }
            }
            arrayNoPriority.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            arrayLow.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            arrayNormal.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            arrayHigh.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            arrayUrgent.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            let newTaskList = [];
            for(let i=0; i<arrayUrgent.length; i++) {
                newTaskList.push(arrayUrgent[i])
            }
            for(let i=0; i<arrayHigh.length; i++) {
                newTaskList.push(arrayHigh[i])
            }
            for(let i=0; i<arrayNormal.length; i++) {
                newTaskList.push(arrayNormal[i])
            }
            for(let i=0; i<arrayLow.length; i++) {
                newTaskList.push(arrayLow[i])
            }
            for(let i=0; i<arrayNoPriority.length; i++) {
                newTaskList.push(arrayNoPriority[i])
            }
            state.tasksList = JSON.parse(JSON.stringify(newTaskList));
            state.countTasksList = state.tasksList.length;
        },
        deleteTaskById(state, id) {
          state.tasksList = state.tasksList.filter(a=> a.id !== id)
        },
        sortTaskByPriorityReverse(state) {
            // state.sortTaskBy = "sortTaskByPriorityReverse";
            let arrayNoPriority= [];
            let arrayLow = [];
            let arrayNormal = [];
            let arrayHigh = [];
            let arrayUrgent = [];
            for(let i=0; i<state.tasksList.length; i++) {
                if(state.tasksList[i].priority.name.toLowerCase() === "no priority") {
                    arrayNoPriority.push(state.tasksList[i])
                } else if(state.tasksList[i].priority.name.toLowerCase() === "low") {
                    arrayLow.push(state.tasksList[i])
                } else if(state.tasksList[i].priority.name.toLowerCase() === "normal") {
                    arrayNormal.push(state.tasksList[i])
                } else if(state.tasksList[i].priority.name.toLowerCase() === "high") {
                    arrayHigh.push(state.tasksList[i])
                } else if(state.tasksList[i].priority.name.toLowerCase() === "urgent") {
                    arrayUrgent.push(state.tasksList[i])
                }
            }
            arrayNoPriority.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            arrayLow.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            arrayNormal.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            arrayHigh.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            arrayUrgent.sort(function(a, b){
                if(a.priority.name.toLowerCase() < b.priority.name.toLowerCase()) { return -1; }
                if(a.priority.name.toLowerCase() > b.priority.name.toLowerCase()) { return 1; }
                return 0;
            });
            let newTaskList = [];
            for(let i=0; i<arrayNoPriority.length; i++) {
                newTaskList.push(arrayNoPriority[i])
            }
            for(let i=0; i<arrayLow.length; i++) {
                newTaskList.push(arrayLow[i])
            }
            for(let i=0; i<arrayNormal.length; i++) {
                newTaskList.push(arrayNormal[i])
            }
            for(let i=0; i<arrayHigh.length; i++) {
                newTaskList.push(arrayHigh[i])
            }
            for(let i=0; i<arrayUrgent.length; i++) {
                newTaskList.push(arrayUrgent[i])
            }
            state.tasksList = JSON.parse(JSON.stringify(newTaskList));
            state.countTasksList = state.tasksList.length;
        },
        sortTaskByDeadline(state) {
            // state.sortTaskBy = "sortTaskByDeadline";
            state.tasksList.sort((a,b) => Math.abs(Date.parse(a.execute_time) - Date.now()) > Math.abs(Date.parse(b.execute_time) - Date.now()) ? 1 : -1)
        },
        sortTaskByDeadlineReverse(state) {
            // state.sortTaskBy = "sortTaskByDeadlineReverse";
            state.tasksList.sort((a,b) => Math.abs(Date.parse(a.execute_time) - Date.now()) < Math.abs(Date.parse(b.execute_time) - Date.now()) ? 1 : -1)
        },
        sortTasksByDepartments(state, department) {
            // console.log("DEP");
            // console.log(department);
            // state.sortTaskBy = "sortTasksByDepartments";
            function sortAr(array) {
                array.sort(function(a, b){
                    if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                });
                return array;
            }
            if(department !== null) {
                // state.sortTaskBy = "sortTasksByDepartments";

                // console.log(department);
                let dep = [
                    {name: "Superadmin", idDepartment: null, array: []},
                    {name: "Freighter", idDepartment: null, array: []},
                    {name: "OPS", idDepartment: null, array: []},
                    {name: "Custom", idDepartment: null, array: []},

                ]
                // console.log(department)
                for(let i=0; i<department.data.results.length; i++) {
                    for(let j=0; j<dep.length - 1; j++) {
                        if(department.data.results[i].name.toLowerCase() === dep[j].name.toLowerCase()) {
                            dep[j].idDepartment = department.data.results[i].id;
                        }
                    }
                }
                // console.log(2)
                for(let i=0; i<state.tasksList.length; i++) {
                    if(state.tasksList[i].executors.length === 1) {
                        let k=0;
                        for(let j=0; j<dep.length - 1; j++) {
                            if (state.tasksList[i].executors[0].department === dep[j].idDepartment) {
                                dep[j].array.push(state.tasksList[i]);
                                k++;
                            }
                        }
                        if(k===0) {
                            dep[dep.length - 1].array.push(state.tasksList[i]);
                        }
                    } else {
                        dep[dep.length - 1].array.push(state.tasksList[i]);
                    }
                }
                // console.log(3)

                for(let i=0; i<dep.length; i++) {
                    dep[i].array = JSON.parse(JSON.stringify(sortAr(dep[i].array)));
                }
                let NewTaskList = [];

                for(let i=0; i<dep.length; i++) {
                    for(let j=0; j<dep[i].array.length; j++) {
                        NewTaskList.push(dep[i].array[j]);
                    }
                }
                // console.log(dep)
                // console.log(NewTaskList);
                // console.log(state.tasksList)
                state.tasksList = JSON.parse(JSON.stringify(NewTaskList));
                state.countTasksList = state.tasksList.length;
            }

        },
        sortTasksByDepartmentsReverse(state, department) {
            // state.sortTaskBy = "sortTasksByDepartmentsReverse";
            let dep = [
                {name: "Superadmin", idDepartment: null, array: []},
                {name: "Freighter", idDepartment: null, array: []},
                {name: "OPS", idDepartment: null, array: []},
                {name: "Custom", idDepartment: null, array: []},

            ]
            // console.log(department)
            for(let i=0; i<department.data.results.length; i++) {
                for(let j=0; j<dep.length - 1; j++) {
                    if(department.data.results[i].name.toLowerCase() === dep[j].name.toLowerCase()) {
                        dep[j].idDepartment = department.data.results[i].id;
                    }
                }
            }
            // console.log(2)
            for(let i=0; i<state.tasksList.length; i++) {
                if(state.tasksList[i].executors.length === 1) {
                    let k=0;
                    for(let j=0; j<dep.length - 1; j++) {
                        if (state.tasksList[i].executors[0].department === dep[j].idDepartment) {
                            dep[j].array.push(state.tasksList[i]);
                            k++;
                        }
                    }
                    if(k===0) {
                        dep[dep.length - 1].array.push(state.tasksList[i]);
                    }
                } else {
                    dep[dep.length - 1].array.push(state.tasksList[i]);
                }
            }
            // console.log(3)
            function sortAr(array) {
                array.sort(function(a, b){
                    if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                });
                return array;
            }
            for(let i=0; i<dep.length; i++) {
                dep[i].array = JSON.parse(JSON.stringify(sortAr(dep[i].array)));
            }
            let NewTaskList = [];
            for(let j=0; j<dep[2].array.length; j++) {
                NewTaskList.push(dep[2].array[j]);
            }
            for(let j=0; j<dep[1].array.length; j++) {
                NewTaskList.push(dep[1].array[j]);
            }
            for(let j=0; j<dep[0].array.length; j++) {
                NewTaskList.push(dep[0].array[j]);
            }
            for(let j=0; j<dep[3].array.length; j++) {
                NewTaskList.push(dep[3].array[j]);
            }
            // console.log(dep)
            // console.log(NewTaskList);
            // console.log(state.tasksList)
            state.tasksList = JSON.parse(JSON.stringify(NewTaskList));
            state.countTasksList = state.tasksList.length;
        },
        searchForTasksList(state, textSearch) {
            textSearch = (("" + textSearch).replace(/\s/g, '')).toLowerCase();
            // console.log(state.allTasks);
            // console.log(state.tasksList.length);
            // console.log(state.countTasksList);
            if(state.countTasksList === state.tasksList.length) {
                state.tasksListCopy = JSON.parse(JSON.stringify(state.tasksList));
            } else {
                state.tasksList = JSON.parse(JSON.stringify(state.tasksListCopy));
            }
            if(textSearch.length !== 0) {
                let arrayResults = [];
                for(let i=0; i<state.tasksList.length; i++) {
                    if(((("" + state.tasksList[i].name).replace(/\s/g, '')).toLowerCase()).indexOf(textSearch) !== -1) {
                        arrayResults.push(state.tasksList[i]);
                    }
                }
                state.tasksList = JSON.parse(JSON.stringify(arrayResults));
            }


            // if (text.indexOf(textSearch) !== -1) {
            //     employeesSortByDepartments.push(state.employeesSortByDepartments[i].array[j]);
            // }
        },
        setFilterContActivity(state, data) {
            state.filterCountActivity = data;
        },
        clearTaskInfoConvert(state) {
            state.taskInfoConvert= {};
        },
        incKeyRenderTask(state) {
            state.keyRenderTask++;
        },
        setCountTask(state, data) {
          state.countTask = data;
        },
        emitCloseModalTask(state) {
            state.closeModalTask++
        }
    },
    state: {
        closeModalTask: -327828374,
        taskFilterActive: false,
        loadRequestFilterTask: false,
        countTask: 0,
        infoContractManual: null,
        keyRenderTask: 0,
        cancelTokenSource: null,
        filterCountActivity: 0,
        countTasksList: 0,
        sortTaskBy: "",
        cancelTokenChangeTask: null,
        taskInfo: {},
        taskInfoConvert: {},
        priority: [],
        limit: 20,
        responseAfterAddTask: null,
        allTasks: [],
        tasksList: [],
        tasksListCopy: [],
        vesselTaskinfo: null,
        flagForPriority: [
            {name: "Urgent", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#E85353\"/>\n" +
                    "</svg>\n", status: false},
            {name: "High", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#FF9035\"/>\n" +
                    "</svg>\n", status: false},
            {name: "Normal", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#0FA54E\"/>\n" +
                    "</svg>\n", status: false},
            {name: "Low", svg: "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                    "<path d=\"M10.8553 1.28944C10.9218 1.15646 10.8251 1 10.6764 1H2.5V0.75C2.5 0.335786 2.16421 0 1.75 0C1.33579 0 1 0.335786 1 0.75V11.25C1 11.6642 1.33579 12 1.75 12C2.16421 12 2.5 11.6642 2.5 11.25V9H10.6764C10.8251 9 10.9218 8.84354 10.8553 8.71056L9 5L10.8553 1.28944Z\" fill=\"#9AA3B0\"/>\n" +
                    "</svg>\n", status: false},
            {name: "No priority", svg: "", status: true},
        ],
        showModalResponseAfterAddTask: false,
    },
    getters: {
        getLoadRequestFilterTask(state) {
            return state.loadRequestFilterTask
        },
        getInfoContractManual(state) {
            return state.infoContractManual;
        },
        getVesselTaskInfo(state) {
            return state.vesselTaskinfo;
        },
        getKeyRenderTask(state) {
            return state.keyRenderTask;
        },
        getShowModalResponseAfterAddTask(state) {
            return state.showModalResponseAfterAddTask;
        },
        getFilterContActivity(state) {
          return state.filterCountActivity;
        },
        getTaskInfoConvert(state) {
            return state.taskInfoConvert;
        },
        getTaskInfo(state) {
            return state.taskInfo;
        },
        getAllPriority(state) {
            if(state.priority.length === 0 && localStorage.getItem('priority') !== null && localStorage.getItem('priority') !== undefined && (''+localStorage.getItem('priority')).length !== 0) {
                state.priority = JSON.parse(localStorage.getItem('priority'))
            }
            return state.priority;
        },
        getResponseCreateTask(state) {
            return state.responseAfterAddTask;
        },
        getAllTasks(state) {
            return state.allTasks;
        },
        getTasksList(state) {
            return state.tasksList;
        },
        getSortTaskBy(state) {
            return state.sortTaskBy;
        },
        getLimit(state) {
            return state.limit;
        },
        getCountTask(state) {
            return state.countTask
        }
    },
}
