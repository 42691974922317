<template>
  <div class="">
    <h2 class="fz18 fw500">{{port.name}}</h2>
    <div class="m-t-3" v-if="port.country">
      <div class="m-b-0.5 text-gray-text fz13">Country</div>
      <div class="fz15">{{port.country}}</div>
    </div>
    <div class="m-t-3" v-if="port.max_draft">
      <div class="m-b-0.5 text-gray-text fz13">Max Draft</div>
      <div class="fz15">{{port.max_draft}}</div>
    </div>
    <div class="m-t-3" v-if="port.max_loa">
      <div class="m-b-0.5 text-gray-text fz13">Max LOA</div>
      <div class="fz15">{{port.max_loa}}</div>
    </div>
    <div class="m-t-3" v-if="port.max_beam">
      <div class="m-b-0.5 text-gray-text fz13">Max Beam</div>
      <div class="fz15">{{port.max_beam}}</div>
    </div>
    <div class="m-t-3" v-if="port.water_density">
      <div class="m-b-0.5 text-gray-text fz13">Water Density</div>
      <div class="fz15">{{getWaterDensity(port.water_density)}}</div>
    </div>
    <div class="m-t-3" v-if="port.dwt_fields">
      <div class="m-b-0.5 text-gray-text fz13">DWT</div>
      <div class="fz15" v-for="(dwt, i) in port.dwt_fields" :key="i">{{dwt.min ? dwt.min : 0}} - {{dwt.max ? dwt.max : 0}} (${{dwt.price ? dwt.price : 0}})</div>
    </div>
    <div class="m-t-3" v-if="port.pda.length">
      <div class="m-b-0.5 text-gray-text fz13">PD/A</div>
      <div class="fz15">
        <div v-for="pda in port.pda" :key="pda.id">{{pda.cargo}} - {{pda.terminal}}</div>
      </div>
    </div>
    <div class="m-t-3" v-if="port.zone">
      <div class="m-b-0.5 text-gray-text fz13">Zone</div>
      <div class="fz15">{{port.zone}}</div>
    </div>
    <div class="m-t-3" v-if="port.latitude || port.longitude">
      <div class="m-b-0.5 text-gray-text fz13">Position</div>
      <div class="fz15">
        <span v-if="port.latitude">{{port.latitude}}</span><span v-if="port.latitude && port.longitude">, </span>
        <span v-if="port.longitude">{{port.longitude}}</span>
      </div>
    </div>
    <div class="m-t-3" v-if="port.transit_time">
      <div class="m-b-0.5 text-gray-text fz13">Transit Time</div>
      <div class="fz15">{{port.transit_time}}</div>
    </div>

    <div 
      class="m-t-3" 
      v-if="port.bunkerning_not_available ||
        port.bunkerning_at_berth ||
        port.bunkerning_at_road ||
        port.bunkerning_in_transit"
    >
      <div class="m-b-0.5 text-gray-text fz13">Bunkering</div>
      <div class="fz15">
        <span v-if="port.bunkerning_not_available">Not Available</span>
        <span v-if="port.bunkerning_at_berth">At Berth <span v-if="port.bunkerning_at_road">/ </span></span>
        <span v-if="port.bunkerning_at_road">At Road <span v-if="port.bunkerning_in_transit">/ </span></span>
        <span v-if="port.bunkerning_in_transit">In Transit</span>
      </div>
    </div>

    <div class="m-t-3" v-if="port.weather_condition">
      <div class="m-b-0.5 text-gray-text fz13">Weather Condition</div>
      <div class="fz15" v-html="wrapLinks(port.weather_condition)"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['port'],
  computed: {
    ...mapState({
      waterDensityList: state => state.ports.waterDensityList
    }),
  },
  methods: {
    getWaterDensity(id) {
      return this.waterDensityList.filter(el => {
        return el.id == id
      })[0].name
    },
    wrapLinks(text) {
      let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;  //eslint-disable-line
      return text.replace(urlRegex, function(url) {
          return '<a href="' + url + '">' + url + '</a>';
      });
    }
  }
}
</script>

<style lang="scss">
</style>