<template>
    <section>
        <div class="content" :class="{'dn':!recaptchaShow}">
          <svg class="logo" width="64" height="84" viewBox="0 0 64 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_14092_14044)">
              <path d="M36.2876 35.9782C50.945 38.1342 62.2515 51.1842 62.2515 66.8975C62.2553 70.3644 61.6989 73.8076 60.6053 77.0854L62.2603 77.6741C63.7993 73.0549 64.3279 68.141 63.808 63.2857C63.2881 58.4304 61.7326 53.7544 59.2534 49.594C56.7742 45.4335 53.4329 41.8919 49.4697 39.224C45.5065 36.556 41.02 34.828 36.3328 34.1641L36.2876 35.9782Z" fill="#113051"/>
              <path d="M28.2488 35.9004C13.3363 37.8139 1.74975 50.9844 1.74975 66.8934C1.74728 70.3124 2.28917 73.7085 3.35372 76.9459L1.69726 77.5271C0.169101 72.876 -0.336172 67.9317 0.218161 63.0538C0.772494 58.1758 2.3725 53.4866 4.9019 49.3271C7.4313 45.1675 10.8265 41.6421 14.8408 39.0071C18.8551 36.3721 23.3876 34.6936 28.1089 34.0938L28.2488 35.9004Z" fill="#113051"/>
              <path d="M29.3759 19.0156V59.2346C29.3759 59.2346 19.9168 59.888 12.2717 66.6629C12.2717 66.6629 24.9227 47.392 29.3759 19.0156Z" fill="#113051"/>
              <path d="M33.9443 0V59.118C33.9443 59.118 43.4719 59.3182 51.7339 66.0434C42.3842 46.6928 36.3897 24.73 33.9443 0Z" fill="#17A1E4"/>
              <path d="M13.3596 83.8615V82.0458H20.9085C21.8505 82.0458 22.3769 81.5023 22.3769 80.6908C22.3769 79.804 21.8505 79.3464 20.9085 79.3464H16.2818C14.3862 79.3464 13.2065 78.1028 13.2065 76.3865C13.2065 74.7123 14.3016 73.4688 16.3095 73.4688H23.5434V75.2844H16.3095C15.5061 75.2844 15.0351 75.7707 15.0351 76.5581C15.0351 77.3455 15.5236 77.8122 16.2964 77.8122H20.9085C22.95 77.8122 24.0392 78.7983 24.0392 80.8233C24.0392 82.5682 23.0185 83.869 20.9085 83.869L13.3596 83.8615Z" fill="#113051"/>
              <path d="M26.2427 83.8582V73.4609H28.0012V82.0425H34.7714V83.8582H26.2427Z" fill="#113051"/>
              <path d="M49.2652 83.8579L47.339 76.55L44.4854 83.3144C44.2915 83.7585 44.0144 84.0009 43.5857 84.0009C43.157 84.0009 42.8566 83.7585 42.6846 83.3144L39.8441 76.55L37.9193 83.8579H36.187L38.7782 74.0719C38.9021 73.572 39.1791 73.3281 39.6531 73.3281C39.8437 73.3268 40.0302 73.3849 40.1884 73.4947C40.3465 73.6046 40.4689 73.7611 40.5396 73.9439L43.644 81.2955L46.747 73.9439C46.809 73.7639 46.9234 73.608 47.0746 73.4978C47.2257 73.3876 47.4062 73.3283 47.5913 73.3281C48.0214 73.3281 48.2985 73.572 48.437 74.0719L51.0136 83.8579H49.2652Z" fill="#113051"/>
            </g>
            <defs>
              <clipPath id="clip0_14092_14044">
                <rect width="64" height="84" fill="white"/>
              </clipPath>
            </defs>
          </svg>


          <p class="fz18 fw500 text-dark">Enter your phone number to sign up or sign in</p>
          <inputphone @clickEnter="clickNext" @changePhone="updatePhone" @focus="focusInput" class="input"></inputphone>
          <div class="recaptcha" id="recaptcha-container"></div>
          <button class="blue-button buttonH48 buttonW100P position-relative" :disabled="blockButtonLogin"  v-on:click="clickNext">
            <app-loader class="load-local" :is-local="true" v-if="blockButtonLogin"></app-loader>
            Next
          </button>
        </div>
    </section>
</template>
<script>
import Inputphone from "../../components/input-phone/inputphone";
import {mapMutations, mapActions, mapGetters} from "vuex";
import firebase from 'firebase/app'
import AppLoader from "@/components/loader.vue";
export default {
  name: "login",
  components: {AppLoader, Inputphone},
  computed: {
    ...mapGetters(["getToken","getLoginStatus"]),
    getLocation() {
      return window.location.href
    },
  },
  data() {
    return {
      phone: "",
      recaptchaShow: false,
      blockButtonLogin: false,
    }
  },
  beforeCreate() {
    // this.setAlert({show:false, text: ""});
  },

  created() {
    this.setAlert({show:false, text: ""});
  },
  methods: {
    ...mapMutations(["hideLoad", "setAlert","setPhone", "updateLoginStatus", "deleteDataUser"]),
    ...mapActions(["axiosLogin", "axiosCheckUser", 'axiosFirebaseLoginTestAfterDelete']),
    focusInput() {
      this.recaptchaShow = false;
    },
    clickNext() {
      this.blockButtonLogin = true
      this.recaptchaShow = true;
      let phoneNumber = (this.phone.replace(/-/g, '')).replace(/\s+/g, '');
      this.axiosCheckUser(phoneNumber).then(res=> {
        if(res.is_valid && res.is_active) {
          this.submitPhoneNumberAuth();
        } else {
          this.blockButtonLogin = false
          if(res.is_valid === false) {
            this.setAlert({show: true, text: "This number not register"});
          } else if(res.is_active === false) {
            this.setAlert({show: true, text: "This number is archived"});
          }
          this.recaptchaShow = false;
        }
      })
    },
    submitPhoneNumberAuth() {
      var phoneNumber = (this.phone.replace(/-/g, '')).replace(/\s+/g, '');
      // let accessPhoned = ['+380666666666', '+380555555555', '+380999999999', '+380950477899']
      //    console.log(phoneNumber)
      // if(accessPhoned.indexOf(phoneNumber) >= 0 || this.getLocation.indexOf('https://speed-logistic-marine.com/') == -1) {
      //   this.axiosFirebaseLoginTestAfterDelete(phoneNumber).then(res=> {
      //     if(res == true) {
      //     this.$router.push({name: 'Dashboard'});
      //   }
      //  })
      // } else {
        var appVerifier = window.recaptchaVerifier;
        let vm = this;
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function(confirmationResult) {
              vm.blockButtonLogin = false
              console.log()
              window.confirmationResult = confirmationResult;
              // alert("AUTH")
              vm.setPhone(vm.phone);
              vm.phone = (vm.phone.replace(/-/g, '')).replace(/\s+/g, '');
              vm.$router.push("phone-number-confirm");
            })
            .catch(function(error) {
              vm.blockButtonLogin = false
              console.log("ERROR");
              if(error.message === "We have blocked all requests from this device due to unusual activity. Try again later.") {
                vm.setAlert({text: error.message, show: true});
              }
              if(error.message === "INVALID_PHONE_NUMBER : TOO_SHORT" || error.message === "TOO_SHORT") {
                vm.setAlert({text: "Invalid phone number : too short.", show: true});
              }
              if(error.message === "INVALID_PHONE_NUMBER" || error.message === "Invalid format.") {
                vm.setAlert({text: "Invalid phone number", show: true});
              }
              if(error.message === "CAPTCHA_CHECK_FAILED : Recaptcha verification failed - DUPE") {
                vm.setAlert({text: "Try again.", show: true});
              }
              if(error.message === "TOO_MANY_ATTEMPTS_TRY_LATER") {
                vm.setAlert({text: "Too many attempts try later.", show: true});
              }
              console.log(error);
              window.recaptchaVerifier.reset("recaptcha-container");
            });
      // }

    },
    updatePhone(phone) {
      this.phone = phone;
    }
  },

  mounted() {
    this.hideLoad()
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function() {
          }
        }
    );
    if(this.getToken.length < 20) {
      this.setAlert({show:false, text: ""});
      // this.$cookies.remove("userData");
      this.deleteDataUser();
      this.updateLoginStatus(null);
    } else {
      this.$router.push({name: 'Dashboard'});
    }

  },
}
</script>

<!--<script>-->
<!--    import Inputphone from "../../components/input-phone/inputphone";-->
<!--    import {mapMutations, mapActions, mapGetters} from "vuex";-->
<!--    // import firebase from 'firebase/app'-->
<!--    import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";-->
<!--    export default {-->
<!--        name: "login",-->
<!--        components: {Inputphone},-->
<!--      computed: mapGetters(["getToken","getLoginStatus"]),-->
<!--        data() {-->
<!--            return {-->
<!--                phone: "",-->
<!--              recaptchaShow: false,-->
<!--            }-->
<!--        },-->
<!--      beforeCreate() {-->
<!--          // this.setAlert({show:false, text: ""});-->
<!--      },-->

<!--      created() {-->
<!--        this.setAlert({show:false, text: ""});-->
<!--      },-->
<!--      methods: {-->
<!--            ...mapMutations(["hideLoad", "setAlert","setPhone", "updateLoginStatus", "deleteDataUser"]),-->
<!--          ...mapActions(["axiosLogin", "axiosCheckUser", 'axiosFirebaseLoginTestAfterDelete']),-->
<!--        focusInput() {-->
<!--          this.recaptchaShow = false;-->
<!--        },-->
<!--            clickNext() {-->
<!--              this.recaptchaShow = true;-->
<!--              let phoneNumber = (this.phone.replace(/-/g, '')).replace(/\s+/g, '');-->
<!--              this.axiosCheckUser(phoneNumber).then(res=> {-->
<!--                if(res.is_valid && res.is_active) {-->
<!--                  this.submitPhoneNumberAuth();-->
<!--                } else {-->
<!--                  if(res.is_valid === false) {-->
<!--                    this.setAlert({show: true, text: "This number not register"});-->
<!--                  } else if(res.is_active === false) {-->
<!--                    this.setAlert({show: true, text: "This number is archived"});-->
<!--                  }-->
<!--                  this.recaptchaShow = false;-->
<!--                }-->
<!--              })-->
<!--            },-->
<!--        submitPhoneNumberAuth() {-->

<!--          var phoneNumber = (this.phone.replace(/-/g, '')).replace(/\s+/g, '');-->
<!--          let accessPhoned = ['+380666666666', '+380555555555', '+380999999999', '+380950477899']-->
<!--          console.log(phoneNumber)-->
<!--          if(accessPhoned.indexOf(phoneNumber) >= 0) {-->
<!--            this.axiosFirebaseLoginTestAfterDelete(phoneNumber).then(res=> {-->
<!--              if(res == true) {-->
<!--                this.$router.push({name: 'Dashboard'});-->
<!--              }-->
<!--            })-->
<!--          } else {-->
<!--            var appVerifier = window.recaptchaVerifier;-->
<!--            let vm = this;-->
<!--            // firebase.-->
<!--            //     .auth().-->
<!--            const auth = getAuth();-->
<!--            console.log(appVerifier, getAuth())-->

<!--            signInWithPhoneNumber(auth, phoneNumber, appVerifier)-->
<!--                .then(function(confirmationResult) {-->
<!--                  console.log(confirmationResult)-->
<!--                  window.confirmationResult = confirmationResult;-->
<!--                  // alert("AUTH")-->
<!--                  vm.setPhone(vm.phone);-->
<!--                  vm.phone = (vm.phone.replace(/-/g, '')).replace(/\s+/g, '');-->
<!--                  vm.$router.push("phone-number-confirm");-->
<!--                })-->
<!--                .catch(function(error) {-->
<!--                  console.log("ERROR", error);-->
<!--                  if(error.message === "We have blocked all requests from this device due to unusual activity. Try again later.") {-->
<!--                    vm.setAlert({text: error.message, show: true});-->
<!--                  }-->
<!--                  if(error.message === "INVALID_PHONE_NUMBER : TOO_SHORT" || error.message === "TOO_SHORT") {-->
<!--                    vm.setAlert({text: "Invalid phone number : too short.", show: true});-->
<!--                  }-->
<!--                  if(error.message === "INVALID_PHONE_NUMBER" || error.message === "Invalid format.") {-->
<!--                    vm.setAlert({text: "Invalid phone number", show: true});-->
<!--                  }-->
<!--                  if(error.message === "CAPTCHA_CHECK_FAILED : Recaptcha verification failed - DUPE") {-->
<!--                    vm.setAlert({text: "Try again.", show: true});-->
<!--                  }-->
<!--                  if(error.message === "TOO_MANY_ATTEMPTS_TRY_LATER") {-->
<!--                    vm.setAlert({text: "Too many attempts try later.", show: true});-->
<!--                  }-->
<!--                  console.log(error);-->
<!--                  // window.recaptchaVerifier._reset("recaptcha-container");-->
<!--                  // RecaptchaVerifier.reset(window.recaptchaVerifier)-->
<!--                  window.recaptchaVerifier.recaptcha.reset();-->
<!--                  // window.recaptchaVerifier.clear();-->
<!--                });-->
<!--          }-->


<!--        },-->
<!--        updatePhone(phone) {-->
<!--          this.phone = phone;-->
<!--        }-->
<!--      },-->

<!--      mounted() {-->
<!--          this.hideLoad()-->
<!--          // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(-->
<!--          //   "recaptcha-container",-->
<!--          //   {-->
<!--          //     size: "invisible",-->
<!--          //     callback: function() {-->
<!--          //     }-->
<!--          //   }-->
<!--          // );-->
<!--        const auth = getAuth();-->
<!--        window.recaptchaVerifier = new RecaptchaVerifier(-->
<!--            auth,-->
<!--            'recaptcha-container', {-->
<!--          'size': 'invisible',-->
<!--          'callback': (response) => {-->
<!--            // reCAPTCHA solved, allow signInWithPhoneNumber.-->
<!--            // onSignInSubmit();-->
<!--            console.log(response)-->
<!--          }-->
<!--        });-->
<!--          if(this.getToken.length < 20) {-->
<!--            this.setAlert({show:false, text: ""});-->
<!--            // this.$cookies.remove("userData");-->
<!--            this.deleteDataUser();-->
<!--            this.updateLoginStatus(null);-->
<!--          } else {-->
<!--            this.$router.push({name: 'Dashboard'});-->
<!--          }-->

<!--      },-->
<!--    }-->
<!--</script>-->

<style scoped lang="scss">
    @import "login";
    .load-local {
      background-color: rgba(44, 44, 44, 0.3);
      border-radius: 4px;
    }
</style>
