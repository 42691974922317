<template>
  <div class="flex flex-col h-full w-full">
    <div class="flex items-center m-x-6 m-t-3 m-b-5">
      <h1 class="fz20 fw500" v-if="lays && lays.length">LayTime {{activeContract.name}} {{activeContract.cp_date | formatDate}}</h1>
      <button v-if="lays && lays.length" @click="$emit('on-create')" class="m-l-6 button-blue-border fw500 fz13 h32 p-x-6">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>
        Create new
      </button>
      <router-link v-if="!$route.meta.isCharter" class="m-l-auto flex items-center text-dark fz13 fw500" :to="`/vessels/${$route.params.id}/lay/archive`">
        <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="dark-mode-stroke-white" d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>Archive
      </router-link>
    </div>

    <div class="m-x-6 p-b-6" v-if="lays && layList.length">
      <div class="lay_time__table m-b-2">
        <div
          class="lay_time__table_row p-x-3 fz12 text-gray-text"
        >
          <div class="lay_time__table_col">Loading/Discharging</div>
          <div class="lay_time__table_col">Port</div>
          <div class="lay_time__table_col">Charterer, CP Date</div>
          <div class="lay_time__table_col">Total, USD</div>
          <div class="lay_time__table_col">Dem. / Disp.</div>
          <div class="lay_time__table_col">Status</div>
        </div>
      </div>
      <div class="lay_time__table bg-white rounded-lg shadow">
        <lay-table-list-row 
          v-for="lay in layList"
          :key="lay.id+'sd3j'"
          :lay="lay"
          @on-edit="$emit('on-edit', lay)"
          @on-archive="$emit('on-archive', lay)"
          @to-details="toDetails(lay)"
        />
      </div>
      <pagination-panel-default v-if="layList.length < countLayTime" :if-active="activeLoad" @load-more="$emit('load-more')"></pagination-panel-default>

    </div>

    <lay-empty v-else @on-create="$emit('on-create')"/>


  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import layTableListRow from '@/components/lay/layTableListRow'
import layEmpty from '@/components/lay/layEmpty'
import PaginationPanelDefault from "@/components/ports/paginationDefault";
export default {
  components: {
    PaginationPanelDefault,
    layTableListRow,
    layEmpty
  },
  props: {
    activeLoad: Boolean,
    countLayTime: Number,
  },
  computed: {
    ...mapState({
      activeContract: state => state.contract.activeContract,
      lays: state => state.lay.lays
    }),
    getCharterer() {
      return this.activeContract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
    layList() {
      if(!this.lays) return []

      if(this.$route.meta.isCharter)
        return this.lays.filter(el => {
          return el.cp_date == this.getCharterer?.cp_date
        })

      return this.lays
    }
  },
  methods: {
    ...mapActions(['axiosGetClientsByIdReturnResponse']),
    ...mapMutations(['setActiveLay']),
    toDetails(lay) {
      this.setActiveLay(lay)
      if(this.$route.meta.isCharter)
        this.$router.push(`/vessels/${this.$route.params.id}/open/charterers/${this.$route.params.subId}/lay/details`)
      else
        this.$router.push(`/vessels/${this.$route.params.id}/open/lay/details`)
    }
  },
}
</script>

<style lang="scss">
.lay_time__table{
  &_row{
    display: flex;
    align-items: center;
    &:not(:last-child){
      border-bottom: 1px solid var(--color-gray-dark);
    }
  }
  &_col{
    &:nth-child(1){width: 15%}
    &:nth-child(2){width: 25.7%}
    &:nth-child(3){width: 20%}
    &:nth-child(4){width: 12.4%}
    &:nth-child(5){width: 10.8%}
    &:nth-child(6){width: 12.4%}
    &:nth-child(7){margin-left: auto;}
  }
}
</style>