import axios from 'axios'

export default {
    actions: {
        axiosLogin(ctx, data) {
             return axios.post(process.env.VUE_APP_URL+"/login/code", {
                phone_number: data.phone,
                // password: "admin",
            },
             ).then(response => {
                 // console.log("RESPONSE LOGIN")
                // console.log(response);
                ctx.commit("updateLoginStatus", response);
            }).catch(() => {
                 if(ctx.getters.getToken>20)
                     ctx.commit("setAlert", {show: true, text: "Number not found"}, {root: true});
            });
        },
        axiosCheckUser(ctx, phone) {
            return axios.post(process.env.VUE_APP_URL+"/login/check", {
                phone_number: phone
            }).then(response=> {
                console.log(response.data.is_valid)
                return response.data;
            }).catch(()=> {
                return false;
            })
        }

    },
    mutations: {
        updateLoginStatus(state, data) {
            state.statusLogin = data;
        },
    },
    state: {
        statusLogin: {},
    },
    getters: {
        getLoginStatus(state) {
            return state.statusLogin;
        },
    },
}
