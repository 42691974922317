<template>
  <div class="container">
    <counterparties-archive-bread />
    <div class="main">
      <counterparties-top-panel :filters="clientsFiltersArchive"/>
      <counterparties-list ref="clienList" @select="onSelect"/>
      <pagination class="m-t-4" @load-more="onLoadNext" :ifActive="ifLoaded" v-if="nextRequest"/>
    </div>
    
    <div class="w-1/4 box-border p-r-6">
      <filters-wrap :filters="clientsFiltersArchive"/>
    </div>

    <counterparties-list-bottom @on-close="$refs.clienList.uncheckAll()" :selectedListId="selectedListId"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import counterpartiesArchiveBread from "@/components/counterparties/counterpartiesArchive/counterpartiesArchiveBread"
import counterpartiesList from "@/components/counterparties/counterpartiesArchive/counterpartiesList"
import counterpartiesTopPanel from "@/components/counterparties/counterpartiesArchive/counterpartiesTopPanel"
import counterpartiesListBottom from "@/components/counterparties/counterpartiesArchive/counterpartiesListBottom"
import filtersWrap from "@/components/counterparties/filters/filtersWrap"
import pagination from '@/components/ports/paginationDefault'

export default {
  components: {
    counterpartiesArchiveBread,
    counterpartiesList,
    counterpartiesTopPanel,
    counterpartiesListBottom,
    filtersWrap,
    pagination
  },
  data() {
    return {
      selectedListId: [],
      ifLoaded: false
    }
  },
  watch: {
    clientsFiltersArchive: {
      deep: true,
      handler() {
        this.axiosGetClientsArchive()
      }
    }
  },
  computed: {
    ...mapState({
      clientsFiltersArchive: state => state.counterparties.clientsFiltersArchive,
      nextRequest: state => state.counterparties.nextRequest
    })
  },
  methods: {
    ...mapActions(['axiosGetClientsArchive']),
    ...mapMutations(['showLoad', 'hideLoad']),
    onSelect(list) {
      this.selectedListId = list.slice()
    },
    async onLoadNext() {
      this.ifLoaded = true
      await this.axiosGetClientsArchive(true)
      this.ifLoaded = false
    }
  },
  async beforeMount() {
    this.showLoad()
    await this.axiosGetClientsArchive()
    this.hideLoad()
  }
}
</script>

<style scoped lang="scss">
  .container {
    width: 100%;
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .main {
    box-sizing: border-box;
    width: 75%;
    padding: 0 24px;
    margin-bottom: 46px;
  }
</style>