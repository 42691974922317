<template>
  <div>
    <p class="fz18 fw500 text-dark">Activity</p>
    <div class="mt12" v-for="(item, i) in activityFolders" :key="i+'3орl'">
      <p class="fz12 text-gray mb4">{{item.created_at | formatDateAndTimeSpace2}}</p>
      <p class="fz14 text-dark" v-if="item.action == 'created'">{{item.user.first_name}} {{item.user.last_name}} created {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 }" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span></p>
      <p class="fz14 text-dark" v-if="item.action == 'attached'">{{item.user.first_name}} {{item.user.last_name}}
        {{ $route.path.indexOf('important') >= 0 ? 'added to important' : 'attached' }} {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 }" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span></p>
      <p class="fz14 text-dark" v-if="item.action == 'archived'">{{item.user.first_name}} {{item.user.last_name}} archived {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 }" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span></p>
      <p class="fz14 text-dark" v-if="item.action == 'moved'">{{item.user.first_name}} {{item.user.last_name}} moved {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 || (item.move_to_name == 'MyFiles' && item.user.id != idCurrentUser)}" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span> from <span class="text-blue-dark">{{item.move_from_name}}</span> to <span class="text-blue-dark">{{item.move_to_name}}</span></p>
      <p class="fz14 text-dark" v-if="item.action == 'renamed'">{{item.user.first_name}} {{item.user.last_name}} renamed {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 }" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span></p>
      <p class="fz14 text-dark" v-if="item.action == 'unarchived'">{{item.user.first_name}} {{item.user.last_name}} unarchived {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 }" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span></p>
      <p class="fz14 text-dark" v-if="item.action == 'delete'">{{item.user.first_name}} {{item.user.last_name}}
        {{ $route.path.indexOf('importnat' >= 0) ? 'removed from important' : 'deleted' }} {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 }" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span></p>
      <p class="fz14 text-dark" v-if="item.action == 'opened_access'">{{item.user.first_name}} {{item.user.last_name}} open access to {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 }" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span> for <span v-for="(user, i) in item.open_access" :key="i"> <span v-if="i !== 0">, </span>{{user.first_name}} {{user.last_name}}</span></p>
      <p class="fz14 text-dark" v-if="item.action == 'closed_access'">{{item.user.first_name}} {{item.user.last_name}} close access to {{item.instance_type === 'OBJECTS' ? 'the objects' : 'the'}} {{item.instance_type == 'FILE' ? 'file' : item.instance_type !== 'OBJECTS' ? 'folder' : ''}} <span class="text-blue-dark link-activity" :class="{'disabled-link': $route.path.indexOf('shared-with-me') >= 0 && getIdAllFiles.indexOf(item.instances[j]) == -1 }" v-on:click="openPage(item, j)" v-for="(name, j) in (item.instance_type == 'OBJECTS' ? [] : (item.instance_name ? item.instance_name.split(',') : []))" :key="j"><span v-if="j !== 0">, </span>{{name}}</span> for <span v-for="(user, i) in item.close_access" :key="i"> <span v-if="i !== 0">, </span>{{user.first_name}} {{user.last_name}}</span></p>
    </div>
    <pagination-panel-default @load-more="loadNext" v-if="nextLinkActivity" :ifActive="is_load"></pagination-panel-default>
<!--<pre>-->
<!--  {{activityFolders.filter(a=> a.instance_name).map(a=> {return a.instance_name})}}-->
<!--&lt;!&ndash;  {{activityFolders}}&ndash;&gt;-->
<!--</pre>-->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import moment from 'moment'

export default {
  name: "folders-activity",
  components: {PaginationPanelDefault},
  computed: {
    ...mapGetters(['getLoginData']),
    ...mapState({
      files: state=> state.folders.files,
      folders: state=> state.folders.folders,
      activityFolders: state => state.folders.activityFolders,
      loadActivityEmit: state => state.folders.loadActivityEmit,
      nextLinkActivity: state => state.folders.nextLinkActivity,
      nameSectionFolders: state => state.folders.nameSectionFolders
    }),
    idCurrentUser() {
      return this.getLoginData.data.user.id
    },
    getIdAllFiles() {
      return (this.files.concat(this.folders)).map(a=> {return a.id})
    },
    getSection() {
      switch(this.nameSectionFolders) {
        case 'My Files': return 'myfiles'
        case 'Payments': return 'payments'
        case 'Tasks': return 'tasks'
        case 'Vessels': return 'vessels'
        case 'Ports': return 'ports'
        case 'Counterparties': return 'counterparties'
        case 'Important': return 'important'

      }
      // MYFILES = 'myfiles'
      // ARCHIVE = 'archive'
      // PAYMENTS = "payments"
      // TASKS = "tasks"
      // VESSELS = "vessels"
      // COUNTERPARTIES = "counterparties"
      // PORTS = "ports"
      return ''
    },
  },
  data() {
    return {
      is_load: false,
    }
  },
  watch: {
    loadActivityEmit: function () {
      this.loadActivity()
    }
  },
  methods: {
    ...mapActions(['axiosGetPathActivityToSection', 'axiosGetActivityForFolderSections', 'axiosloadFoldersActivityNext']),
    ...mapMutations(['setIdFileForFocus']),
    async loadNext() {
      this.is_load = true
      await this.axiosloadFoldersActivityNext({section: this.getSection})
      this.is_load = false
    },
    loadActivity() {
      let data = {
        section: this.getSection
      }
      if(this.$route.path.indexOf('shared-with-me') >= 0) {
        data['shared_with_me'] = true
      }
      this.axiosGetActivityForFolderSections(data)
    },
    async openPage(item1, index) {
      let item = JSON.parse(JSON.stringify(item1))
          item.instances = item.instances.map(a=> {return a.id})
      if(this.$route.path.indexOf('shared-with-me')>=0) {
        if(!this.files.find(a=> a.id  == item.instances[index]) && !this.folders.find(a=> a.id  == item.instances[index])) {
          return null
        }
      }
      this.setIdFileForFocus(item.instances[index])
      console.log('item', item)
      if(this.nameSectionFolders == 'My Files') {
        let res = await this.axiosGetPathActivityToSection({id: item.instances[index], section: 'myfiles'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.instances[index])
        if(data.full_path.length !== 0) {
          if(fileArchive) {
            this.$router.push({name: 'Folders - My files Folder Archive', params: {path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
          } else {
            this.$router.push({name: 'Folders - My files Folder', params: {path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
          }
        } else {
          if(fileArchive) {
            this.$router.push({name: 'Folders - My files Archive'})
          } else {
            this.$router.push({name: 'Folders - My files'})
          }
        }
      }
      if(this.nameSectionFolders == 'Shared with me') {
        let res = await this.axiosGetPathActivityToSection({id: item.instances[index], section: '',})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.instances[index])
        if(data.full_path.length == 0) {
          if(fileArchive) {
            this.$router.push({name: 'Folders - Shared with me Archive'})
          } else {
            this.$router.push({name: 'Folders - Shared with me'})
          }
        } else {
          if(fileArchive) {
            this.$router.push({name: 'Folders - Shared with me Folder Archive', path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))})
          } else {
            this.$router.push({name: 'Folders - Shared with me Folder', path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))})
          }
        }
      }
      if(this.nameSectionFolders == 'Tasks') {
        let res = await this.axiosGetPathActivityToSection({id: item.instances[index], section: 'tasks'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.instances[index])
        if(data.task.is_archived) {
          if(data.full_path.length !== 0) {
            if(data.task.type == 'MANUAL') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Archive Manually Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}})) }})
              } else {
                this.$router.push({name: 'Folders - Tasks Archive Manually Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
            if(data.task.type == 'SHIP_SEARCH_WITH_CARGO') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Archive Vessel Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Archive Vessel Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
            if(data.task.type == 'CARGO_SEARCH_WITH_SHIP') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Archive Cargo Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Archive Cargo Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
          } else {
            if (data.task.type == 'MANUAL') {
              if (fileArchive) {
                this.$router.push({
                  name: 'Folders - Tasks Archive Manually Files Archive',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              } else {
                this.$router.push({
                  name: 'Folders - Tasks Archive Manually Files',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              }
            }
            if (data.task.type == 'SHIP_SEARCH_WITH_CARGO') {
              if (fileArchive) {
                this.$router.push({
                  name: 'Folders - Tasks Archive Vessel Files Archive',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              } else {
                this.$router.push({
                  name: 'Folders - Tasks Archive Vessel Files',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              }
            }
            if (data.task.type == 'CARGO_SEARCH_WITH_SHIP') {
              if (fileArchive) {
                this.$router.push({
                  name: 'Folders - Tasks Archive Cargo Files Archive',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              } else {
                this.$router.push({
                  name: 'Folders - Tasks Archive Cargo Files',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              }
            }

          }
        } else {
          if(data.full_path.length !== 0) {
            if(data.task.type == 'MANUAL') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Manually Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Manually Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
            if(data.task.type == 'SHIP_SEARCH_WITH_CARGO') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Vessel Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Vessel Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
            if(data.task.type == 'CARGO_SEARCH_WITH_SHIP') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Cargo Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Cargo Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
          } else {
            if(data.task.type == 'MANUAL') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Manually Files Archive', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              } else {
                this.$router.push({name: 'Folders - Tasks Manually Files', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              }
            }
            if(data.task.type == 'SHIP_SEARCH_WITH_CARGO') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Vessel Files Archive', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              } else {
                this.$router.push({name: 'Folders - Tasks Vessel Files', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              }
            }
            if(data.task.type == 'CARGO_SEARCH_WITH_SHIP') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Cargo Files Archive', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              } else {
                this.$router.push({name: 'Folders - Tasks Cargo Files', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              }
            }
          }
        }
      }
      if(this.nameSectionFolders == 'Payments') {
        let res = await this.axiosGetPathActivityToSection({id: item.instances[index], section: 'payments'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.instances[index])
        if(data.full_path.length) {
          if(data.payment.is_archive) {
            if(fileArchive) {
              this.$router.push({name: 'Folders - Payments Archive Folders Path Archive', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
            } else {
              this.$router.push({name: 'Folders - Payments Folders Path Archive', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
            }
          } else {
            if(fileArchive) {
              this.$router.push({name: 'Folders - Simple payment and archive folders - path', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
            } else {
              this.$router.push({name: 'Folders - Payments Folders Path', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
            }
          }
        } else {
          if(data.payment.is_archive) {
            if(fileArchive) {
              this.$router.push({name: 'Folders - Payments Folders Archive Path Archive', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})}})
            } else {
              this.$router.push({name: 'Folders - Payments Folders Archive', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})}})
            }
          } else {
            if(fileArchive) {
              this.$router.push({name: 'Folders - Payments Folders Archive Path', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})}})
            } else {
              this.$router.push({name: 'Folders - Payments Folders', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})}})
            }
          }
        }

      }
      if(this.nameSectionFolders == 'Vessels') {
        let res = await this.axiosGetPathActivityToSection({id: item.instances[index], section: 'vessels'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.instances[index])
        if(data.owner) {
          if(data.charterer) {
            this.$router.push({name: 'Folders - Vessels Option Charterers Files', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, charterersDetail: JSON.stringify({id: data.charterer.id, type: (data.charterer.contract_type == 'VOYAGE_CHARTERER' || data.charterer.contract_type == 'CARGO' ? 'Voyage' : data.charterer.contract_type == 'SUBLET' ? 'sublet' : ''), cp_date: moment(data.charterer.cp_date).format('DD.MM.YYYY'), owner_name: data.charterer.owner ? data.charterer.owner.company_name : ''}), charterers_is_archive: data.charterer.is_archive,   }})

          } else {
            this.$router.push({name: 'Folders - Vessels Option Owners', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), owners_is_archive: fileArchive, ownersDetail: JSON.stringify({id: data.owner.id})  }})
          }
        } else if(data.payment) {
          if(data.payment.data_type == 'payment') {
            if(data.full_path.length !== 0) {
              this.$router.push({name: 'Folders - Vessels Option CashFlow Files Path', params: { path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))  }})
            } else {
              this.$router.push({name: 'Folders - Vessels Option CashFlow Files', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, cash_flow_is_archive: data.payment.is_archive, detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})} })
            }
          } else {
            if(data.full_path.length !== 0) {
              this.$router.push({name: 'Folders - Vessels Option CashFlow Files Path', params: { path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))  }})
            } else {
              this.$router.push({name: 'Folders - Vessels Option CashFlow Files', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, cash_flow_is_archive: data.payment.is_archive, detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})} })
            }
          }
        } else if(data.ship && data.charterer) {
          if(data.full_path.length !== 0) {
            this.$router.push({name: 'Folders - Vessels Option Charterers Files Path', params: {charterersDetail: JSON.stringify({id: data.charterer.id, type: (data.charterer.contract_type == 'VOYAGE_CHARTERER' || data.charterer.contract_type == 'CARGO' ? 'Voyage' : data.charterer.contract_type == 'SUBLET' ? 'sublet' : ''), cp_date: moment(data.charterer.cp_date).format('DD.MM.YYYY'), owner_name: data.charterer.owner ? data.charterer.owner.company_name : ''}), charterers_is_archive: data.charterer.is_archive, vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))  }})
          } else {
            this.$router.push({name: 'Folders - Vessels Option Charterers Files', params: {charterersDetail: JSON.stringify({id: data.charterer.id, type: (data.charterer.contract_type == 'VOYAGE_CHARTERER' || data.charterer.contract_type == 'CARGO' ? 'Voyage' : data.charterer.contract_type == 'SUBLET' ? 'sublet' : ''), cp_date: moment(data.charterer.cp_date).format('DD.MM.YYYY'), owner_name: data.charterer.owner ? data.charterer.owner.company_name : ''}), charterers_is_archive: data.charterer.is_archive, vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive}})
          }
        } else if(data.ship) {
          if(data.full_path.length !== 0) {
            this.$router.push({name: 'Folders - Vessels Option Vessel Detail Path', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))  }})
          } else {
            this.$router.push({name: 'Folders - Vessels Option Vessel Detail', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive}})
          }
        }
      }
      if(this.nameSectionFolders == 'Ports') {
        let res = await this.axiosGetPathActivityToSection({id: item.instances[index], section: 'ports'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.instances[index])
        if(!data.berth_list && !data.port_contract) {
          if(data.full_path.length !== 0) {
            this.$router.push({name: 'Folders - Ports Countries Ports Options Details Path', params: {countries_is_archive: false, countryDetail: JSON.stringify({name: data.country, idsPorts: data.ports_ids}), portDetail: JSON.stringify({name: data.port.name, id: data.port.id}), is_archive_files: fileArchive,  path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}})) }})
          } else {
            this.$router.push({name: 'Folders - Ports Countries Ports Options Details', params: {countries_is_archive: false, countryDetail: JSON.stringify({name: data.country, idsPorts: data.ports_ids}), portDetail: JSON.stringify({name: data.port.name, id: data.port.id}), is_archive_files: fileArchive }})
          }
        } else if(data.berth_list) {
            this.$router.push({name: 'Folders - Ports Countries Ports Options Berth List Detail', params: {is_archive_berth_list: data.berth_list.is_archive, detailList: JSON.stringify({name: data.berth_list.name, id: data.berth_list.id}),countries_is_archive: false, countryDetail: JSON.stringify({name: data.country, idsPorts: data.ports_ids}), portDetail: JSON.stringify({name: data.port.name, id: data.port.id}), is_archive_files: fileArchive }})
        } else if(data.port_contract) {
          this.$router.push({name: 'Folders - Ports Countries Ports Options Contact Files', params: {is_archive_contacts: data.port_contract.is_archive, detailContact: JSON.stringify({name: data.port_contract.dwt +  (data.port_contract.amount_local ?  '/'+ data.port_contract.amount_local : '') + ( data.port_contract.source ? '/'+data.port_contract.source : ''), id: data.port_contract.id}),countries_is_archive: false, countryDetail: JSON.stringify({name: data.country, idsPorts: data.ports_ids}), portDetail: JSON.stringify({name: data.port.name, id: data.port.id}), is_archive_files: fileArchive }})
        }

      }
      if(this.nameSectionFolders == 'Counterparties') {
        let res = await this.axiosGetPathActivityToSection({id: item.instances[index], section: 'counterparties'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.instances[index])
        if(data.client) {
          if(data.full_path.length !== 0) {
            this.$router.push({name: 'Folders - Counterparties Options Detail Path', params: {is_archive_counterparties: data.client.is_archived, detailCounterparties: JSON.stringify({id: data.client.id, name: data.client.company_name }), is_archive_files: fileArchive, path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}})) }})
          } else {
            this.$router.push({name: 'Folders - Counterparties Options Detail', params: {is_archive_counterparties: data.client.is_archived, detailCounterparties: JSON.stringify({id: data.client.id, name: data.client.company_name }), is_archive_files: fileArchive}})
          }
        }
      }
    }
  },
  mounted() {
    this.loadActivity()
  }
}
</script>

<style scoped lang="scss">
  /deep/ {
    .dafault-list-more {
      margin-bottom: 0;
    }
  }
  .link-activity {
    cursor: pointer;
    &:hover {
      color: var(--color-blue);
      text-decoration: underline;
    }
  }
  .link-activity {
    word-break: break-word;
  }
  .disabled-link {
    text-decoration: none !important;
    color: var(--color-dark);
    &:hover {
      color: var(--color-dark);
    }
  }
</style>