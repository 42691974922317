<template>
  <div class="">
    <detention-invoice-edit :activeCurrency="invoice.currency" :detail="invoice.detention_invoices"/>

    <address-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('LESS_ADDRESS_COMMISSION')"
      :key="row.id ? row.id+'id' : row.count+'count'"
      :detail="row"
      :total_amount="parseFloat(invoice.detention_invoices.total)"
      @on-delete="onDeleteBlock('LESS_ADDRESS_COMMISSION', i)"
    />

    <brokerage-edit 
      class="m-t-3"
      v-for="(row, i) in getByKey('LESS_BROKERAGE_COMMISSION')"
      :key="row.id ? row.id+'id' : row.count+'count'"
      :detail="row"
      :total_amount="parseFloat(invoice.detention_invoices.total)"
      @on-delete="onDeleteBlock('LESS_BROKERAGE_COMMISSION', i)"
    />

    <modal-slots
      :showMod="isShowModalDelete"
      :isBorder="false"
      textForSecondButton="Delete"
      @apply="deleteBlockAfterModal"
      @close="isShowModalDelete = false"
    >
      <template v-slot:title>
        <h2 class="fw500">Chrts Invoices</h2>
      </template>

      <p class="m-b-4 p-x-6 fz14">Are you sure you want to delete this block?</p>
    </modal-slots>
  </div>
</template>

<script>
import detentionInvoiceEdit from "@/components/vessels/invoice/detentionInvoiceEdit";
import addressEdit from "@/components/vessels/invoice/addressEdit";
import brokerageEdit from "@/components/vessels/invoice/brokerageEdit";
import modalSlots from '@/components/modal/modal-slots'


export default {
  props: {
    invoice: Object
  },
  components: {
    detentionInvoiceEdit,
    addressEdit,
    brokerageEdit,
    modalSlots
  },
  data() {
    return {
      isShowModalDelete: false,
      deletedBlock: null
    }
  },
  computed: {
  },
  methods: {
    getByKey(type) {
      return this.invoice.blocks.filter(el => el.block_type == type)
    },

    onDeleteBlock(type, i) {
      this.deletedBlock = {type, index: i}
      this.isShowModalDelete = true
    },
    deleteBlockAfterModal() {
      this.$emit('on-delete', this.deletedBlock)
      this.deletedBlock = null
    },
  },
};
</script>

<style lang="scss">
</style>