<template>
  <div class="container">
    <div class="main">
      <counterparties-top-panel :filters="clientsFilters"/>
      <counterparties-list ref="clienList" @select="onSelect"/>

      <pagination class="m-t-4" @load-more="onLoadNext" :ifActive="ifLoaded" v-if="nextRequest"/>
    </div>

    <div class="w-1/4 box-border p-r-6">
      <filters-wrap :filters="clientsFilters"/>
    </div>

    <counterparties-list-bottom @on-close="$refs.clienList.uncheckAll()" :selectedListId="selectedListId"/>
  </div>
</template>

<script>
import counterpartiesList from "@/components/counterparties/counterpartiesList/counterpartiesList"
import counterpartiesTopPanel from "@/components/counterparties/counterpartiesList/counterpartiesTopPanel"
import counterpartiesListBottom from "@/components/counterparties/counterpartiesList/counterpartiesListBottom"
import filtersWrap from "@/components/counterparties/filters/filtersWrap"
import pagination from '@/components/ports/paginationDefault'

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    counterpartiesList,
    counterpartiesTopPanel,
    counterpartiesListBottom,
    filtersWrap,
    pagination
  },
  data() {
    return {
      selectedListId: [],
      ifLoaded: false
    }
  },
  watch: {
    clientsFilters: {
      deep: true,
      handler() {
        this.axiosGetClients()
      }
    }
  },
  computed: {
    ...mapState({
      clientsFilters: state => state.counterparties.clientsFilters,
      nextRequest: state => state.counterparties.nextRequest
    })
  },
  methods: {
    ...mapActions(['axiosGetClients']),
    ...mapMutations(['showLoad', 'hideLoad']),
    onSelect(list) {
      this.selectedListId = list.slice()
    },
    async onLoadNext() {
      this.ifLoaded = true
      await this.axiosGetClients(true)
      this.ifLoaded = false
    }
  },
  async beforeMount() {
    this.showLoad()
    await this.axiosGetClients()
    this.hideLoad()
  }
}
</script>

<style scoped lang="scss">
  .container {
    width: 100%;
    padding-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .main {
    box-sizing: border-box;
    width: 75%;
    padding: 0 24px;
    margin-bottom: 60px;
  }
</style>