import axios from 'axios'

export default {
  actions: {
    async axiosAddContact(ctx, contact) {
      ctx.commit('showLoad')
      let filesId = await ctx.dispatch('axiosAddPortFiles', contact.files)
      contact.files = filesId

      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/port/contact/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: contact
      })
      .then(response => {
        console.log('axiosAddContact: ', response)
        ctx.commit('hideLoad')
        return response.data
      })
      .catch(error => {
        console.warn('axiosAddContact error: ', error)
        ctx.commit('hideLoad')
      })
    },
    async axiosEditContact(ctx, contact) {
      contact.files = contact.files.map(el=>el.id)
      delete contact.files
      return await axios({
        method: 'put',
        url: process.env.VUE_APP_URL+'/port/contact/'+contact.id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: contact
      })
      .then(response => {
        console.log('axiosEditContact: ', response)
        return response.data
      })
      .catch(error => {
        console.warn('axiosEditContact error: ', error)
      })
    },
  },
  mutations: {
  },
  state: {
  },
}