<template>
    <div
        class="rounded-lg bg-white shadow p-l-6 p-r-4 border border-dark-gray p-b-4"
        v-if="getLoadingPorts().length !== 0 || getDischargingPorts().length !== 0"
    >
      <div class="w100Proc flex" v-for="(port, i) in getPorts()" :key="i+'213hjk3d'">
        <div class="w-1/2 h-max-content">
          <div v-if="port.loading">
            <div class="p-t-4"></div>
            <div class="">
              <p class="fz13 text-dark fw500 text-dots-overflow">Loading #{{getIndexPort(port.loading)+1}} ({{port.loading.name}}, {{port.loading.country}})</p>
            </div>
            <div class="pb12"></div>
            <div class="flex " >
              <div class="w-1/2 p-r-10" v-if="port.loading.arrived_load_date || port.loading.notice_tendered_loading_date || port.loading.loading_arrived_time || port.loading.loading_notice_time">
                <div class="m-b-2" v-if="port.loading.arrived_load_date || port.loading.loading_arrived_time">
                  <p class="m-b-1 fz13 text-gray-text">Vessel arrived load port</p>
                  <p class="fz13 text-gray-text">
                  <span class="fz13 text-gray-text" v-if="port.loading.arrived_load_date">
                    Date: <span class="text-dark">{{port.loading.arrived_load_date | getDate}}</span>
                  </span>
                    <span class="fz13 text-gray-text" v-if="port.loading.loading_arrived_time">
                     <br v-if="pdfHideBlock">
                    Time: <span class="text-dark">{{port.loading.loading_arrived_time}}</span>
                  </span>

                  </p>
                </div>
                <div class="m-b-2" v-if="port.loading.notice_tendered_loading_date || port.loading.loading_notice_time">
                  <p class="m-b-1 fz13 text-gray-text">Notice of Readiness tendered</p>
                  <p class="fz13 text-gray-text">
                  <span class="fz13 text-gray-text" v-if="port.loading.notice_tendered_loading_date">
                    Date: <span class="text-dark">{{port.loading.notice_tendered_loading_date | getDate}}</span>
                  </span>
                    <span class="fz13 text-gray-text" v-if="port.loading.loading_notice_time">
                    <br v-if="pdfHideBlock">
                    Time: <span class="text-dark">{{port.loading.loading_notice_time}}</span>
                  </span>
                  </p>
                </div>

                <div class="m-b-2">
                  <p class="m-b-1 fz13 text-gray-text">Time allowed</p>
                  <p class="fz13 text-dark">{{timeAllowedLoading(lay.lay_ports[getIndexPort(port.loading)]) | formatNumberTwo}}</p>
                </div>
              </div>

              <div class="w-1/2 p-r-10" v-if="port.loading.loading_commenced_date || port.loading.loading_completed_date || port.loading.loading_commenced_time || port.loading.loading_completed_time">
                <div class="m-b-2" v-if="port.loading.loading_commenced_date || port.loading.loading_commenced_time">
                  <p class="m-b-1 fz13 text-gray-text">Loading commenced</p>
                  <p class="fz13 text-gray-text">
                  <span class="fz13 text-gray-text" v-if="port.loading.loading_commenced_date">
                    Date: <span class="text-dark">{{port.loading.loading_commenced_date | getDate}}</span>
                  </span>
                    <span class="fz13 text-gray-text" v-if="port.loading.loading_commenced_time">
                    <br v-if="pdfHideBlock">
                    Time: <span class="text-dark">{{port.loading.loading_commenced_time}}</span>
                  </span>

                  </p>
                </div>
                <div class="m-b-2" v-if="port.loading.loading_completed_date || port.loading.loading_completed_time">
                  <p class="m-b-1 fz13 text-gray-text">Loading completed</p>
                  <p class="fz13 text-gray-text">
                  <span class="fz13 text-gray-text" v-if="port.loading.loading_completed_date">
                    Date: <span class="text-dark">{{port.loading.loading_completed_date | getDate}}</span>
                  </span>
                    <span class="fz13 text-gray-text" v-if="port.loading.loading_completed_time">
                     <br v-if="pdfHideBlock">
                    Time: <span class="text-dark">{{port.loading.loading_completed_time}}</span>
                  </span>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="w-1/2 h-max-content">
          <div v-if="port.discharging">
            <div class="p-t-4"></div>
            <div class="">
              <p class="fz13 text-dark fw500 text-dots-overflow">Discharging #{{getIndexPort(port.discharging, 'discharging')+1}} ({{port.discharging.name}}, {{port.discharging.country}})</p>
            </div>
            <div class="pb12"></div>
            <div class="flex">
              <div class="w-1/2 p-r-10" v-if="port.discharging.arrived_discharging_date || port.discharging.notice_tendered_date || port.discharging.discharging_arrived_time || port.discharging.discharging_notice_time">
                <div class="m-b-2" v-if="port.discharging.arrived_discharging_date || port.discharging.discharging_arrived_time">
                  <p class="m-b-1 fz13 text-gray-text">Vessel arrived discharging port</p>
                  <p class="fz13 text-gray-text">
                    <span class="fz13 text-gray-text" v-if="port.discharging.arrived_discharging_date">
                      Date: <span class="text-dark">{{port.discharging.arrived_discharging_date | getDate}}</span>
                    </span>
                    <span class="fz13 text-gray-text" v-if="port.discharging.discharging_arrived_time">
                      <br v-if="pdfHideBlock">
                      Time: <span class="text-dark">{{port.discharging.discharging_arrived_time}}</span>
                    </span>
                  </p>
                </div>
                <div class="m-b-2" v-if="port.discharging.notice_tendered_date || port.discharging.discharging_notice_time">
                  <p class="m-b-1 fz13 text-gray-text">Notice of Readiness tendered</p>
                  <p class="fz13 text-gray-text">
                    <span class="fz13 text-gray-text" v-if="port.discharging.notice_tendered_date">
                      Date: <span class="text-dark">{{port.discharging.notice_tendered_date | getDate}}</span>
                    </span>
                    <span class="fz13 text-gray-text" v-if="port.discharging.discharging_notice_time">
                      <br v-if="pdfHideBlock">
                      Time: <span class="text-dark">{{port.discharging.discharging_notice_time}}</span>
                    </span>
                  </p>
                </div>
                <div class="m-b-2">
                  <p class="m-b-1 fz13 text-gray-text">Time allowed</p>
                  <p class="fz13 text-dark">{{timeAllowedDischarging(lay.lay_ports[getIndexPort(port.discharging)]) | formatNumberTwo}}</p>
                </div>
              </div>

              <div class="w-1/2 p-r-10" v-if="port.discharging.discharging_commenced_date || port.discharging.discharging_completed_date || port.discharging.discharging_commenced_time || port.discharging.discharging_completed_time">
                <div class="m-b-2" v-if="port.discharging.discharging_commenced_date || port.discharging.discharging_commenced_time">
                  <p class="m-b-1 fz13 text-gray-text">Discharging commenced</p>
                  <p class="fz13 text-gray-text">
                    <span class="fz13 text-gray-text" v-if="port.discharging.discharging_commenced_date">
                       Date: <span class="text-dark">{{port.discharging.discharging_commenced_date | getDate}}</span>
                    </span>
                    <span class="fz13 text-gray-text" v-if="port.discharging.discharging_commenced_time">
                      <br v-if="pdfHideBlock">
                      Time: <span class="text-dark">{{port.discharging.discharging_commenced_time}}</span>
                    </span>
                  </p>
                </div>
                <div class="m-b-2" v-if="port.discharging.discharging_completed_date || port.discharging.discharging_completed_time">
                  <p class="m-b-1 fz13 text-gray-text">Discharging completed</p>
                  <p class="fz13 text-gray-text">
                    <span class="fz13 text-gray-text" v-if="port.discharging.discharging_completed_date">
                      Date: <span class="text-dark">{{port.discharging.discharging_completed_date | getDate}}</span>
                    </span>
                    <span class="fz13 text-gray-text" v-if="port.discharging.discharging_completed_time">
                      <br v-if="pdfHideBlock">
                      Time: <span class="text-dark">{{port.discharging.discharging_completed_time}}</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
</template>

<script>
import moment from "moment"

export default {
  props: {
    lay: Object,
    pdfHideBlock: Boolean,
  },
  computed: {

  },
  filters: {
    getDate: function (val) {
      if(!val || val === 'Invalid date') return ''
      return moment(new Date(val)).format('DD.MM.YYYY')
    },
    getTime: function (val) {
      if(!val || val === 'Invalid date') return ''
      return moment(new Date(val)).format('HH:mm')
    }
  },
  methods: {
    timeAllowedLoading(el) {
      return el.loading_quantity / el.loading_rate
    },
    timeAllowedDischarging(el) {
      return el.discharging_quantity / el.discharging_rate
    },
    getIndexPort(port, namePort) {
      let id = port?.PORT_ID ? port.PORT_ID : null
      if(namePort === 'discharging') {
        return this.lay.lay_ports.findIndex(a=> a.id === id)
      } else {
        return this.lay.lay_ports.findIndex(a=> a.id === id)
      }
    },
    getLoadingPorts() {
      let ports = this.lay.additional_info?.loading_ports?.length ? JSON.parse(JSON.stringify(this.lay.additional_info.loading_ports)) : [];
      ports = ports.filter(port=> (port.arrived_load_date || port.notice_tendered_loading_date || port.loading_arrived_time || port.loading_notice_time || port.loading_commenced_date || port.loading_completed_date || port.loading_commenced_time || port.loading_completed_time))
      return ports.sort((a, b) => a.id_port > b.id_port ? 1 : -1)
    },
    getDischargingPorts() {
      let ports = this.lay.additional_info?.discharging_ports?.length ? JSON.parse(JSON.stringify(this.lay.additional_info.discharging_ports)) : [];
      ports = ports.filter(port=> (port.arrived_discharging_date || port.notice_tendered_date || port.discharging_arrived_time || port.discharging_notice_time || port.discharging_commenced_date || port.discharging_completed_date || port.discharging_commenced_time || port.discharging_completed_time))
      return ports.sort((a, b) => a.id_port > b.id_port ? 1 : -1)
    },
    getPorts() {
      let count = this.getLoadingPorts().length >= this.getDischargingPorts().length ? this.getLoadingPorts().length : this.getDischargingPorts().length
      let array = []
      for(let i=0; i<count; i++) {
        array.push({loading: this.getLoadingPorts()[i] ?  this.getLoadingPorts()[i] : null, discharging: this.getDischargingPorts()[i] ?  this.getDischargingPorts()[i] : null})
      }
      return array;
    }
  }
}
</script>

<style scoped lang="scss">
  .text-gray-text {
    color: var(--color-gray-text) !important;
  }
</style>