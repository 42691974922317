<template>
  <div class="flex w-full h-full flex-col items-center justify-center">
    <svg width="148" height="160" viewBox="0 0 148 160" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M122.5 113.5L122.052 35.948C122.023 30.9978 118.002 27 113.052 27H38C33.0294 27 29 31.0294 29 36V72.5M122.5 113.5L99.5 136M122.5 113.5H105.5C102.186 113.5 99.5 116.186 99.5 119.5V136M99.5 136H83.5M40.5 39.5H45M52.5 39.5H110M40.5 51.5H45M52.5 51.5H97M40.5 63.5H45M52.5 63.5H64.5M114.5 105.5V101M114.5 94V67" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><circle cx="52.5" cy="107.5" r="32.5" stroke="#DEE3EA" stroke-width="2"/><path d="M52.5 82V107L63.5 118" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>

    <p class="m-t-2 m-b-4 fz16 fw500" v-if="!isArchive">LayTime not created yet</p>
    <p class="m-t-2 m-b-4 fz16 fw500" v-else>Archive is empty</p>

    <button class="blue-button p-x-6 p-y-1" @click="$emit('on-create')" v-if="!isArchive">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"></path></svg>
      Create new
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isArchive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>