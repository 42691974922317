import axios from "axios";
import QueryString from "querystring";

export default {
    actions: {
        clickTabPayment(ctx, element) {
            console.log('element Next', element)
            // console.log('element', element.parentNode.parentNode.nextElementSibling.children[0].children[0])
            // console.dir(element.parentNode.parentNode.nextElementSibling.children[0].children[0])
            // console.log(element.nextElementSibling.classList.contains('input-block'))
            if(!element.nextElementSibling.classList.contains('noTab')) {
                console.log(1, element.nextElementSibling)
                if(element.nextElementSibling.classList.contains('due-date') || element.nextElementSibling.classList.contains('input-block') || element.nextElementSibling.classList.contains('payment-income') || element.nextElementSibling.classList.contains('converted')) {
                    let event = new Event("dblclick")
                    console.log('element.nextElementSibling.childNodes', element.nextElementSibling.childNodes[0])
                    element.nextElementSibling.childNodes[0].click()
                    element.nextElementSibling.childNodes[0].dispatchEvent(event);

                    let list = document.querySelectorAll('.click-active-border');
                    list.forEach(item=> {
                        item.classList.toggle("click-active-border");
                        item.classList.remove("dbl-click-active-input");
                        item.setAttribute("disabled", "disabled");
                    })
                    ctx.commit('setSelectionPayments', [], {root: true})
                    element.nextElementSibling.childNodes[0].classList.toggle("click-active-border");

                    element.nextElementSibling.childNodes[0].classList.toggle("dbl-click-active-input");
                    element.nextElementSibling.childNodes[0].removeAttribute('disabled')
                    setTimeout(()=> {
                        element.nextElementSibling.childNodes[0].focus()
                        if(!element.nextElementSibling.childNodes[0].matches('.input-payment'))
                            element.nextElementSibling.childNodes[0].selectionStart = event.target.value.length;
                    }, 50)

                } else {
                    element.nextElementSibling.childNodes[0].click()
                    setTimeout(()=> {
                        let event = new Event("dblclick")
                        element.nextElementSibling.dispatchEvent(event);
                    }, 50)

                }
                ctx.commit('closeListPayment')
            } else {
                console.log(2)
                if(!element.nextElementSibling.classList.contains('end-block')) {
                    console.log(3)
                    // if(element.nextElementSibling !== null) {
                    console.log('1111x')

                    ctx.dispatch('clickTabPayment', element.nextElementSibling)
                } else {
                    console.log(4)
                    console.log('2222x')

                    console.log('nextRow', element.parentNode.parentNode.nextElementSibling.children[0].children[0])
                    ctx.dispatch('clickTabPayment', element.parentNode.parentNode.nextElementSibling.children[0].children[0])
                }
            }


            // this.currentActiveElement = e.target.parentNode.nextSibling;
        },

        openListPayment(ctx, data) {
            ctx.state.listSettings = data;
            ctx.state.isShow = true
            ctx.state.noTextSearch = true
            if(Array.isArray(data.localList) === true && data.localList.length || !data.url.length) {
                ctx.state.results = data.localList;
                ctx.state.isShowResults = true
            } else {
                ctx.dispatch('axiosGetDataForList', data)
            }
        },
        axiosGetDataForList(ctx, data) {
            let ct = axios.CancelToken.source();
            ctx.commit("cancelToken", ct);
            ctx.commit("cancelTokenSource", ct);
            ctx.state.isLoad = true
            axios({
                method: 'get',
                url: process.env.VUE_APP_URL+data.url,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                cancelToken: ct.token,
                params: {
                    ...data.params,
                    search: !ctx.state.noTextSearch ? data.searchText : '',
                    limit: 20
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                ctx.state.noTextSearch = false
                ctx.state.isLoad = false
                if(ctx.state.isShow) {
                    ctx.state.isShowResults = true
                }
                if(ctx.state.listSettings.nameValue !== 'bank') {
                    ctx.state.results = response.data.results
                } else {
                    ctx.state.results = response.data
                }
            }).catch(error => {
                ctx.state.noTextSearch = false
                console.error('searchError: ', error);
            })
        }
    },
    mutations: {
        closeListPayment(state) {
            state.isShow = false
            state.isShowResults = false
            state.isLoad = false
            state.noTextSearch = false
                state.listSettings = {
                    id: null,
                    url: '',
                    params: null,
                    value: null,
                    watchKey: null,
                    localList: null,
                    watchKeyBefore: null,
                    watchKeyAfter: null,
                    searchText: null,
                    element: null,
                    nameValue: '',
                    nameValueText: '',
                }
            state.results = []
        },
        cancelToken(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
            }
        },
        cancelTokenSource(state, data) {
            state.cancelTokenSource = data
        },
        setShowListResults(state, data) {
            state.isShowResults = data;
        },
        setSelectedItem(state, data) {
            state.settingsForPath = state.listSettings
            state.settingsForPath.searchText = data ? data.searchText : ''
            state.selectedItem = data ? data.value : null
            state.emitChangeDataSelected++;
            console.log('asdasd')
        },
        setPausedChange(state, bool) {
            state.pausedChange = bool
        },
        setSelectionPayments(state, data) {
            data.sort((a, b) => a.index > b.index ? 1 : -1);
            state.typesSelection = data
            // if(state.typesSelection.filter(a=> a.type === 'currency').length) {
            //     state.typesSelection = state.typesSelection.filter(a=> a.type === 'currency')
            // } else {
            //     state.typesSelection = state.typesSelection.filter(a=> a.type !== 'currency')
            // }
            state.typesSelectionExpense = state.typesSelection.filter(a=> a.type === 'expense');
            state.typesSelectionIncome = state.typesSelection.filter(a=> a.type === 'income');
            state.typesSelectionCurrency = state.typesSelection.filter(a=> a.type === 'currency');
        },

    },
    state: {
        emitChangeDataSelected: -2734623784,
        typesSelection: [],
        typesSelectionExpense: [],
        typesSelectionIncome: [],
        typesSelectionCurrency: [],
        pausedChange: false,
        noTextSearch: false,
        selectedItem: null,
        cancelTokenSource: null,
        isShowResults: false,
        listSettings: {
            id: null,
            url: '',
            params: null,
            value: null,
            watchKey: null,
            localList: null,
            watchKeyBefore: null,
            watchKeyAfter: null,
            searchText: null,
            element: null,
            nameValue: '',
            nameValueText: '',
        },
        isShow: false,
        isLoad: false,
        results: [],
        settingsForPath: null,

    },
    getters: {
    },
}
