<template>
  <div class="calc_routes_table border-t border-gray-text">
    <div class="border-r border-gray-text"></div>
    <div class="border-r border-gray-text"></div>
    <div class="border-r border-gray-text">
      <async-select
        class="w-full"
        :id="'port-'+direction"
        :allowEmpty="true"
        :closeOnSelect="true"
        :clearOnSelect="true"
        v-model="port"
        @on-select="onSelectPort"
        url="/port/dropdown"
        :params="{is_archive: false}"
        watchKey="name"
        afterWatchKeyBrackets="country"
        placeholder="Add port or canal"
      />
    </div>
    <div class="border-r border-gray-text"></div>
    <div class="border-r border-gray-text"></div>
    <div class="border-r border-gray-text"></div>
    <div class=""></div>
  </div>
</template>

<script>
import AsyncSelect from '@/components/reuse/asyncSelect'

export default {
  props: {
    direction: {
      type: String,
      required: true
    }
  },
  components: {
    AsyncSelect
  },
  data() {
    return {
      port: null
    }
  },
  methods: {
    onSelectPort() {
      if(this.port) this.$emit('on-add', this.port)
      this.port = null
    }
  },
}
</script>