<template>
  <div class="async_select_port">
    <label :for="id" class="block text-gray m-b-1 fz13" v-if="label">{{label}}</label>

    <multiselect
      :id="id"
      :class="{
        'error': isErrorRequired
      }"
      ref="multiselect"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      @open="onOpen()"
      :options="ports"
      track-by="id"
      :multiple="isMultiple"
      :taggable="false"
      :disabled="disabled"
      :showLabels="false"
      :allowEmpty="allowEmpty"
      :clearOnSelect="false"
      :internalSearch="false"
      :preserveSearch="true"
      :loading="isPortsLoading"
      :closeOnSelect="closeOnSelect"
      @search-change="asyncFind"
    >
      <template slot="singleLabel" slot-scope="props">
        <span class="text-dark fz14">{{props.option.name}}{{props.option.country ? ', '+props.option.country : ''}}</span>
      </template>
      <template slot="option" slot-scope="props" >
        <span>{{props.option.name}}{{props.option.country ? ', '+props.option.country : ''}}</span>
      </template>

      <template slot="selection" slot-scope="{ values }">
        <span class="multiselect__single p-0 inline-flex items-center fz14 text-dark" v-if="values.length >= 1">
          {{values[0].name}}{{values[0].country ? ', '+values[0].country : ''}}
          <span class="m-l-1" v-if="values.length > 1">/
            <span class="inline-flex items-center justify-center h-5 min-w-5 rounded-full border border-dark fz11">+{{ values.length-1 }}</span>
          </span>
        </span>
      </template>
    </multiselect>

    <div class="text-red fz12" v-if="isErrorRequired">Field is required</div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex';
import { setTimeout, clearTimeout } from 'timers';
export default {
  props: {
    value: [Object, Array],
    isMultiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Type to search",
    },
    disabled: Boolean,
    id: String,
    label: String,
    isErrorRequired: Boolean,
    allowEmpty: Boolean,
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Multiselect
  },
  data() {
    return {
      isPortsLoading: false,
      ports: [],
      timeout: null
    }
  },
  methods: {
    ...mapActions(['axiosFindPorts']),
    asyncFind (query) {
      this.isPortsLoading = true
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(()=>{
        this.axiosFindPorts(query).then(response => {
          this.ports = response
          this.isPortsLoading = false
        })
      }, 600)
    },
    onOpen() {
      if(this.ports.length == 0)
        this.asyncFind('')
    },
    openDropdown() {
      this.$refs.multiselect.isOpen = true;
    },
    closeDropdown() {
      this.$refs.multiselect.isOpen = false;
    },
    onInput(value) {
      this.$emit('input', value)
      this.$emit('on-select')
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.multiselect{
  min-height: auto;
  color: var(--color-dark);
}
.error{
  .multiselect__tags{
    border: 1px solid var(--color-red);
  }
}
.multiselect__tags{
  box-sizing: border-box;
  min-height: 36px;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}
.multiselect__select{
  height: 36px;
  width: 26px;
}
.multiselect__input, .multiselect__single{
  margin-bottom: 6px;
  padding: 0;
  background-color: transparent;
}
.multiselect__placeholder{
  margin-bottom: 8px;
}
.multiselect__select:before{
  border-top-color: var(--color-dark);
}
.multiselect__option{
  white-space: wrap;
}
.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight{
  //background-color: #DEE3EA;
  background-color: var(--color-hover-list);
  color: var(--color-dark);
}
.multiselect__content-wrapper{
  background-color: var(--color-modal);
}
.multiselect__spinner{
  background-color: var(--color-modal);
  height: calc(100% - 2px);
}
</style>