<template>
  <div class="flex items-center p-t-5 p-b-4">
    <div>
      <p class="fz12 text-gray-text m-b-1">Status</p>
      <button 
        class="fz13 fw500 p-x-2 p-y-0.5 text-white rounded-full"
        :class="{
          'bg-red': !invoice.is_confirmed,
          'bg-green': invoice.is_confirmed
        }"
        @click="changeConfirm"
      >{{invoice.is_confirmed ? 'Confirmed' : 'Not confirmed'}}</button>
    </div>
    <div class="m-l-6" v-if="invoice.due_date">
      <p class="fz12 text-gray-text m-b-1">Due date</p>
      <p class="fz14">{{invoice.due_date | formatDateWithotTZ}}</p>
    </div>
    <div class="m-l-6">
      <p class="fz12 text-gray-text m-b-1">Created</p>
      <p class="fz14">{{invoice.created_at | formatDate}}</p>
    </div>
    <div class="m-l-6">
      <p class="fz12 text-gray-text m-b-1">Last edits</p>
      <p class="fz14">
        {{invoice.updated_at | formatDate}}
        at {{invoice.updated_at | formatTime}}
        by {{invoice.creator.first_name + ' ' + (invoice.creator.last_name ? invoice.creator.last_name : '')}}
      </p>
    </div>
    <div class="m-l-auto"></div>

    <router-link :to="getHistoryLink" class="button-blue-border m-l-2 h32" v-if="invoiceList && invoiceList.length > 1">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M10.9989 8.25L10.9971 12.6226C10.9971 12.7886 11.063 12.9473 11.1802 13.0645L14.3082 16.1919L15.192 15.3081L12.247 12.3638L12.2489 8.25H10.9989Z" fill="#2C2C2C"/><path d="M12.25 3.25C7.85069 3.25 4.2097 6.51721 3.59989 10.75H1L4.125 13.875L7.25 10.75H4.8629C5.46083 7.20859 8.54098 4.50001 12.25 4.50001C16.3857 4.50001 19.75 7.86426 19.75 12C19.75 16.1358 16.3858 19.5 12.25 19.5C9.92519 19.5 7.76944 18.4478 6.33569 16.6131L5.35059 17.3821C7.02293 19.522 9.53759 20.75 12.25 20.75C17.0748 20.75 21 16.8242 21 12C21 7.17582 17.0748 3.25004 12.25 3.25Z" fill="#2C2C2C"/></g><defs><clipPath id="clip0"><rect width="20" height="20" fill="white" transform="translate(1 2)"/></clipPath></defs></svg>
    </router-link>

    <vsoa-export class="download-list m-l-2" :isBlueBtn="true" @eventExportXLSX="downloadXLSX" @eventExportPDFBlackWhite="eventExportPDFBlackWhite" @eventExportPDF="downloadPDF"/>

    <router-link :to="`/vessels/${$route.params.id}/open/charterers/${$route.params.subId}/${getInvoiceUrl()}/edit`" class="w-130 blue-button fz13 fw500 h32 m-l-2">
      <svg class="m-r-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#094172"/></svg>
      Edit
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import vsoaExport from "@/components/vessels/sublet/vsoaExport";
export default {
  components: {vsoaExport},
  props: {
    invoice: Object,
    activeTab: String
  },
  computed: {
    ...mapState({
      invoiceList: state => state.invoice.invoiceList
    }),
    ...mapGetters(['getInvoiceUrl', 'getInvoiceType']),
    getHistoryLink() {
      return `/vessels/${this.$route.params.id}/charterers/${this.$route.params.subId}/invoices/${this.getInvoiceUrl()}/history`
    }
  },
  methods: {
    ...mapActions(['axiosGetInvoicesById', 'axiosChangeInvoice']),
    downloadPDF() {
      this.$emit("downloadPDF")
    },
    downloadXLSX() {
      this.$emit("downloadXLSX");
    },
    eventExportPDFBlackWhite() {
      this.$emit('eventExportPDFBlackWhite')
    },
    changeConfirm() {
      this.invoice.is_confirmed = !this.invoice.is_confirmed
      
      this.axiosChangeInvoice({
        id: this.invoice.id,
        data: {
          is_confirmed: this.invoice.is_confirmed
        }
      })
    },
  },
  beforeMount() {
    this.axiosGetInvoicesById({
      id: this.$route.params.subId,
      type: this.getInvoiceType()
    })
  }
}
</script>

<style lang="scss" scoped>
  .dart-mode {
    .flex {
      svg {
        path {
          fill: #FFFFFF;
        }
      }
    }
  }
</style>