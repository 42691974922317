
<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">Archive the counterparty <button class="modal-close" @click="closeModal()"></button></p>
        <p class="modal-content_descr">Are you sure you want to archive this counterparty?</p>
      </div>
      <div class="modal-content_bottom">
        <button class="btn btn-close text-dark1" @click="closeModal">No</button>
        <button class="btn btn-next" @click="onSubmit">Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      this.$emit('submit')
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-wrap{
  .modal-content{
    max-width: 424px;
    .modal-content_bottom{
      border-top: none;
    }
  }
}
</style>