<template>
  <div class="counterparties-list-bottom" v-show="selectedListId.length">
    <span class="counterparties-list-bottom_text">Selected:</span>
    <span class="counterparties-list-bottom_count">{{selectedListId.length}}/{{clients.length}}</span>

    <button class="btn btn-next" @click="unArchive"><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.01736 6.78525L1.71267 4.00237L4.01736 1.21949C4.28441 0.913453 4.25326 0.448723 3.94722 0.18143C3.64119 -0.0856168 3.1767 -0.0542222 2.90916 0.251567L0.181723 3.51852C-0.0605742 3.79562 -0.0605742 4.20934 0.181723 4.48644L2.9092 7.75342C3.05485 7.91968 3.25865 8.00504 3.46365 8.00504C3.63532 8.00504 3.80769 7.9452 3.94722 7.82331C4.25329 7.55602 4.28465 7.09129 4.01736 6.78525Z" fill="white"/><path d="M8.65379 3.26562H0.73568C0.329344 3.26562 0 3.59493 0 4.0013C0 4.40764 0.329344 4.73698 0.73568 4.73698H8.65379C10.7904 4.73698 12.5286 6.60036 12.5286 8.97852C12.5286 11.3567 10.7904 13.4862 8.65379 13.4862H1.71661C1.31027 13.4862 0.98093 13.8155 0.98093 14.2219C0.98093 14.6282 1.31027 14.9576 1.71661 14.9576H8.65379C11.6014 14.9576 14 12.212 14 8.97852C14 5.74505 11.6017 3.26562 8.65379 3.26562Z" fill="white"/></svg>Unarchive</button>

    <button class="btn btn-close text-dark1-icon" @click="onClose"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#9AA3B0"/><path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#9AA3B0"/></svg></button>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    selectedListId: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      clients: state => state.counterparties.clientsArchive
    })
  },
  methods: {
    ...mapActions(['axiosClientToArchive']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    async unArchive() {
      this.showLoad()
      await this.axiosClientToArchive({ids: this.selectedListId, bool: false})
      this.addTip(this.selectedListId.length + ' counterparties are unarchived')
      this.$emit('on-close')
      this.hideLoad()
    },
    onClose() {
      this.$emit('on-close')
    }
  },
}
</script>

<style lang="scss">
</style>