<template>
  <transition name="fade">
    <div class="wrap" v-if="show">
      <div class="filter" v-on:click="closeModal"></div>
      <div class="content">
        <p class="fz16 fw500 text-dark title">Archive the payment</p>
        <button class="close" v-on:click="closeModal">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
            <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
          </svg>
        </button>
        <p class="fz14 text-dark text">Are you sure you want to archive this payment?</p>
        <div class="bottom-block">
          <button class="blue-button-text fz14" v-on:click="closeModal">No</button>
          <button class="blue-button fz14 fw500" v-on:click="archivePayment">Yes</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "payment-modal-delete",
  data() {
    return {
      show: false,
      id: null,
    }
  },
  methods: {
    ...mapActions(["axiosArchivePayment"]),
    closeModal() {
      this.id = null;
      this.show = false;
    },
    openModal(id) {
      this.id = id
      this.show = true;
    },
    archivePayment() {
      this.axiosArchivePayment({id: this.id, is_archived: true}).then(()=> {
        this.$emit("render")
      });
      this.closeModal();
    }
  }
}
</script>

<style scoped lang="scss">
  .wrap {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter {
    background-color: var(--color-filter-modal);
    opacity: 0.15;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .dart-mode {
    .filter {
      opacity: 0.75;
    }
  }
  .content {
    background-color: var(--color-modal);
    width: 424px;
    height: max-content;
    border-radius: 8px;
    padding: 12px 16px;
    position: relative;
    z-index: 100;
  }
  .close {
    position: absolute;
    top: 12px;
    right: 16px;
  }
  .title {
    margin-bottom: 20px;
  }
  .text {
    margin-bottom: 24px;
  }
  .bottom-block {
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-end;
    .blue-button {
      width: 100px;
      height: 32px;
      margin-left: 45px;
    }
  }
</style>