<template>
  <div class="d-flex border-radius-8 overflow-hidden bgc-white border  w-max wrap">
    <div class="border-right border-gray-light">
      <div class="d-flex border-bottom border-gray-light">
        <div class="w86 h24 pl6 box-border d-flex align-items-center border-right border-gray-light">
          <button class="flex items-center m-y-auto radio-button text-dark hover:child-stroke-blue hover:text-blue-dark" @click="$emit('input', 'full')">
            <svg v-if="value == 'full'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="#319FEF" stroke-width="2"/>
              <circle cx="7.99913" cy="8.00011" r="4.44444" fill="#319FEF"/>
            </svg>

            <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="#2C2C2C" stroke-width="2"/>
            </svg>

            <span class="m-l-1.5 fz13 fw700 uppercase text-dark">full</span>
          </button>
        </div>
        <div class="w86 h24 pl6 box-border d-flex align-items-center">
          <button class="flex items-center m-y-auto radio-button text-dark hover:child-stroke-blue hover:text-blue-dark" @click="$emit('input', 'eco')">
            <svg v-if="value == 'eco'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="#319FEF" stroke-width="2"/>
              <circle cx="7.99913" cy="8.00011" r="4.44444" fill="#319FEF"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="#2C2C2C" stroke-width="2"/>
            </svg>

            <span class="m-l-1.5 fz13 fw700 uppercase text-dark">eco</span>
          </button>
        </div>
      </div>
      <div class="d-flex ">
        <div class="w86 h24 pl6 box-border d-flex align-items-center border-right border-gray-light">
          <button class="flex items-center m-y-auto radio-button text-dark hover:child-stroke-blue hover:text-blue-dark" @click="$emit('input', 'slow')">
            <svg v-if="value == 'slow'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="#319FEF" stroke-width="2"/>
              <circle cx="7.99913" cy="8.00011" r="4.44444" fill="#319FEF"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="#2C2C2C" stroke-width="2"/>
            </svg>

            <span class="m-l-1.5 fz13 fw700 uppercase text-dark">slow</span>
          </button>
        </div>
        <div class="w86 h24 pl6 box-border d-flex align-items-center">
          <button class="flex items-center m-y-auto radio-button text-dark hover:child-stroke-blue hover:text-blue-dark" @click="$emit('input', 'optimal')">
            <svg v-if="value == 'optimal'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="#319FEF" stroke-width="2"/>
              <circle cx="7.99913" cy="8.00011" r="4.44444" fill="#319FEF"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="#2C2C2C" stroke-width="2"/>
            </svg>

            <span class="m-l-1.5 fz13 fw700 uppercase text-dark">optimal</span>
          </button>
        </div>
      </div>
    </div>
    <calcVesselsSpeedResultRow
        :load-table="loadTable"
        class="calc border-r border-b border-gray-text"
        :fuel="value"
        :prefix="value"
        :detail="detail"
    />
  </div>

</template>

<script>
import CalcVesselsSpeedResultRow from "@/components/calc/calcVesselsSpeedResultRow";
export default {
  name: "vessel-selected-and-calc-type",
  components: {CalcVesselsSpeedResultRow},
  props: {
    loadTable: Boolean,
    value: String,
    detail: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.wrap {
  width: calc(32.2%)
}
.calc {
  border: none !important;
  /deep/ {
    .w-104 {
      display: none !important;
    }
    .w-full {
      padding: 0 !important;
      //width: ; !important;
      display: flex;
      flex-wrap: wrap;
      .inline-block {
        display: flex;
        align-items: center;
        width: 50%;
        height: 24px;
        background-color: var(--color-bgc-page);
        font-weight: 700;
        color: var(--color-new-dark-gray);
        padding-left: 4px;
        box-sizing: border-box;
        span {
          display: flex;
          padding-left: 6px;
          padding-right: 6px;
          box-sizing: border-box;
          align-items: center;
          margin-right: 0 !important;
          margin-left: auto;
          width: calc(100% - 87px);
          height: 100%;
          background-color: var(--color-bgc-page-white);
          border-left: 1px solid var(--color-gray-light);
        }
      }
      &>*:nth-child(-n+2) {
        border-bottom: 1px solid var(--color-gray-light);
      }
      &>*:nth-child(2), &>*:nth-child(4) {
        border-left: 1px solid var(--color-gray-light);
      }
    }
  }
}
</style>