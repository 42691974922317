import axios from 'axios'

export default {
  actions: {
    async axiosOpenOwnersById(ctx, id) {
      ctx.state.activeOwners = null
      await axios.get(process.env.VUE_APP_URL+'/owners/?contract='+ id +'&limit=1&ordering=-created_at' , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
      .then( response => {
        if(response.data.results.length){
          ctx.state.activeOwners = response.data.results[0]
          ctx.state.ownersHistoryCount = response.data.count
        }
      })
      .catch((error) => {
        console.error('axiosOpenOwnersById', error);
      })
    },
    async axiosAddOwners(ctx, obj) {
      ctx.commit('showLoad')
      await axios.post(process.env.VUE_APP_URL+'/owners/', obj,{
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      })
      .then(() => {
        // console.log('axiosAddOwners', response);
        ctx.commit('hideLoad')
      })
      .catch((error) => {
        alert('Server error. Contact the developers')
        console.error('axiosAddOwners', error);
        ctx.commit('hideLoad')
      })
    },
    async axiosGetOwnersHistory(ctx, id) {
      ctx.state.ownersHistoryList = null
      await axios.get(process.env.VUE_APP_URL+'/owners/?contract='+ id +'&limit=999&ordering=-created_at' , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
      .then( response => {
        ctx.state.ownersHistoryList = response.data.results
      })
      .catch((error) => {
        console.error('axiosGetOwnersHistory', error);
      })
    }
  },
  mutations: {
    addEmitAddPaymentForVessels(state) {
      state.emitAddPaymentForVessels += 1;
    },
  },
  state: {
    emitAddPaymentForVessels: 0,
    activeOwners: null,
    ownersHistoryCount: 0,
    ownersHistoryList: null
  },
  getters: {
    getEmitAddPaymentForVessels(state) {
      return state.emitAddPaymentForVessels;
    }
  }
}