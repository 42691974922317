<template>
  <div class="position-relative">
    <input v-on:keydown.tab="(e)=>{e.preventDefault();clickTabPayment(e.target.parentNode)}"  :class="{'text-red' : isCompare, 'disabled-always': disabled}" :disabled="true" type="text" class="fz13 click-element-border" v-model="valueLocal" v-if="!showToDay" @change="input">
    <input v-on:keydown.tab="(e)=>{e.preventDefault();clickTabPayment(e.target.parentNode)}" :class="{'text-red' : isCompare, 'disabled-always': true}" :disabled="true" type="text" class="fz13 click-element-border" :value="getToday()" v-else @change="input">
    <div class="position-absolute t0 l0 w100Proc h100Proc box-border pre-input-click-border">

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapActions, mapMutations} from "vuex";

export default {
  props: {
    disabled: Boolean,
    value: [Object, Array, String, Number],
    status: String,
    compareShow: Boolean,
    showToDay: Boolean
  },
  name: "element-due-date",
  data() {
    return {
      valueLocal: this.value,
      isCompare: false
    }
  },
  methods: {
    ...mapActions(['clickTabPayment']),
    ...mapMutations(['addTip']),
    getToday() {
      return moment().format('DD.MM.YYYY')
    },
    input() {
      console.log(this.valueLocal)
      if(moment(this.valueLocal, 'DD.MM.YYYY', true).isValid() === true || this.valueLocal === null || (''+this.valueLocal).length === 0) {
        if(this.compareShow && this.$route.path.indexOf('accounting-invoice') === -1) {
          this.isCompare = this.compareDate(this.valueLocal) && this.status !== 'paid'
        }
        this.$emit('input', this.valueLocal)
      } else {
        this.valueLocal = this.value
        this.addTip('Wrong Due Date format. Enter dd.mm.yyyy')
      }
    },
    compareDate(a) {
      return moment(new Date()).format('DD.MM.YYYY') !== a ? moment(new Date()).isAfter(new Date(moment(a, 'DD.MM.YYYY').format('YYYY.MM.DD'))) : false
    },

  },
  mounted() {
    if(this.compareShow && this.$route.path.indexOf('accounting-invoice') === -1) {
      this.isCompare = this.compareDate(this.valueLocal) && this.status !== 'paid'
    }
  }
}
</script>

<style scoped>
  .click-element-border {
    border: 2px solid transparent !important;
  }
</style>