<template>
  <div class="flex items-center w-full p-t-4 p-b-4">
    <div class="fz13 m-r-auto">
      Created 
      <div class="text-gray-text">
        {{lay.created_at | formatDate}} at {{lay.created_at | formatTime}} by
        {{lay.creator.last_name ? lay.creator.first_name+' '+lay.creator.last_name : lay.creator.last_name}}
      </div>
    </div>

    <payment-dropdown-export class="" btnText="Export" @eventExportXLSX="downloadXLSX" @eventExportPDF="downloadPDF"></payment-dropdown-export>

    <button class="w-130 button-blue-border m-r-6 m-l-2 fw500 fz13 h32" @click="$emit('on-create')">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"></path></svg>
      Create new
    </button>

    <options @editItem="$emit('on-edit')" @archiveItem="$emit('on-archive')" :options="['edit', 'archive']" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import options from '@/components/reuse/options'
import PaymentDropdownExport from "@/components/payment/payment-dropdown-export";

export default {
  components: {
    PaymentDropdownExport,
    options
  },
  computed: {
    ...mapState({
      lay: state => state.lay.activeLay
    })
  },
  methods: {
    downloadPDF() {
      this.$emit("downloadPDF")
    },
    downloadXLSX() {
      this.$emit("downloadXLSX");
    }
  }
}
</script>

<style>

</style>