<template>
  <div>
    <span v-if="list.length == 0">0.0000</span>

    <span v-if="ifOnceCurrency">{{summ | formatNumber}}</span>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data() {
    return {
      summ: 0,
      ifOnceCurrency: false,
    }
  },
  computed: {
  },
  async mounted() {
    if(this.list.length) {
      if(this.list.length == this.list.filter(el=>{return el.currency == this.list[0].currency}).length){
        this.ifOnceCurrency = true
      }
    }

    this.list.forEach(el => {
      this.summ += el.balance
    })
  }
}
</script>

<style>

</style>