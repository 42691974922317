<template>
  <div class="flex justify-center border-b border-b-gray-light bg-white p-3">
    <div class="flex items-center w-full max-w-920">
      <router-link to="/notifications" class="bread__link">Notifications</router-link>
      <svg class="m-x-1 counterparties-add_bread__separator" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.29924 6.40283L5.18326 9.70514C4.91614 9.977 4.48367 9.97655 4.217 9.70373C3.95053 9.43096 3.95122 8.98908 4.21837 8.71701L6.84906 5.90885L4.21837 3.19159C3.95125 2.91948 3.95057 2.47789 4.217 2.20508C4.35068 2.06832 4.52581 1.99994 4.70094 1.99994C4.87562 1.99994 5.05006 2.06786 5.1835 2.20367L8.29924 5.41491C8.42789 5.54562 8.50009 5.72351 8.50009 5.90885C8.50009 6.09419 8.42769 6.27187 8.29924 6.40283Z" fill="#9AA3B0"/></svg>
      <span class="bread__link">Settings</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>