<template>
  <div class="w-full box-border border-b border-gray-dark">
    <div class="p-y-4 flex items-center justify-between fz14">
      {{getTitle}}
      <b-form-checkbox v-model="item.is_active" @change="$emit('on-change')"></b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  computed: {
    getTitle() {
      if(this.item.notification_type == 'payment') return 'Payments'
      if(this.item.notification_type == 'port') return 'Ports'
      if(this.item.notification_type == 'voyage') return 'Vessels'
      if(this.item.notification_type == 'invoice') return 'Accounting Invoices'
      if(this.item.notification_type == 'shipsearchtask') return 'Vessel Contracts'
      if(this.item.notification_type == 'index') return 'Indexes'
      if(this.item.notification_type == 'user') return 'Employees'
      if(this.item.notification_type == 'client') return 'Counterparties'
      if(this.item.notification_type == 'paymentaccount') return 'Accounting'
      if(this.item.notification_type == 'task') return 'Tasks'
      if(this.item.notification_type == 'task_is_overdue') return 'Task is overdue'
      if(this.item.notification_type == 'invoice_is_overdue') return 'Invoice/Bill is overdue'
      if(this.item.notification_type == 'payment_is_overdue') return 'Payment/Income is overdue'
      if(this.item.notification_type == 'event') return 'Calendar'
      return 'Unknown part'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/checkbox";
</style>