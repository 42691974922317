var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex border-b border-gray-dark relative"},[_c('div',{staticClass:"w-1/6 flex-shrink-0 p-x-3 p-y-2 border-r border-gray-dark fz13 text-gray-text"},[_vm._v(_vm._s(_vm.title))]),(_vm.isEdit)?_c('div',{ref:"input",staticClass:"w-5/6 p-x-3 p-y-1.5 fz15 hover:outline-gray-text focus:outline-blue-dark",class:{
      //'whitespace-pre': isPre,
      'outline-red': _vm.error,
      'p-r-16': _vm.isCurrency
    },attrs:{"contenteditable":"plaintext-only"},domProps:{"innerHTML":_vm._s(_vm.value)},on:{"blur":_vm.onBlur}}):_c('div',{staticClass:"w-5/6 p-x-3 p-y-1.5 fz15",class:{
      //'whitespace-pre': isPre,
      'p-r-16': _vm.isCurrency
    }},[_vm._v(_vm._s(_vm.value))]),(_vm.isCurrency && _vm.isEdit)?_c('div',{staticClass:"absolute top-2 right-3 flex items-center fz14 cursor-pointer svg-white-dart",on:{"click":function($event){return _vm.$refs.dropCurrency.openList()}}},[_vm._v(" "+_vm._s(_vm.activeCurr)+" "),_c('svg',{staticClass:"m-l-1",attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2.72443 4.39844C2.45716 4.39844 2.32331 4.72158 2.5123 4.91057L5.78804 8.18631C5.9052 8.30346 6.09515 8.30346 6.21231 8.18631L9.48803 4.91057C9.67702 4.72158 9.54317 4.39844 9.2759 4.39844H2.72443Z","fill":"#2C2C2C"}})])]):_vm._e(),(_vm.isCurrency && !_vm.isEdit)?_c('div',{staticClass:"absolute top-2 right-3 fz14"},[_vm._v(_vm._s(_vm.activeCurr))]):_vm._e(),(_vm.isCurrency && _vm.isEdit)?_c('counterparties-drop',{ref:"dropCurrency",staticClass:"hide_input vessel_owners__select_currency",attrs:{"list":_vm.valutaList,"dontSelect":true,"removeSelectItemBeforeClick":false,"activeItem":_vm.activeCurrency,"title":"Select currency"},on:{"select":_vm.onSelect}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }