export default {
    actions: {
    },
    mutations: {
        setAlert(state, data) {
            if(data.show !== null && data.show !== undefined)
                state.showAlert = data.show;
            if(data.text !== null && data.text !== undefined)
                state.textAlert = data.text;
        }
    },
    state: {
        showAlert: false,
        textAlert: "",
    },
    getters: {
        getShowAlert(state) {
            return state.showAlert;
        },
        getTextAlert(state) {
            return state.textAlert;
        }
    },
}
