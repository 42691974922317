<template>
  <div class="bank-details_top">
    <h1 class="bank-details_top__title fz20 fw500" :title="activeBank.name">{{activeBank.name}}</h1>

    <div class="bank-details_top__total fw500"><div class="bank-details_top__total_title">Total:</div> <bank-archive-get-total :list="activeBank.requisites.filter(el=>{return el.is_archive == false})"/> USD</div>

    <button class="button-blue-border fw500 fz13" @click="$emit('add-account')" v-if="getPermissionsByType('create_new_bank_accounts')">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>
        Create new account
    </button>

    <router-link v-if="getPermissionsByType('show_archive_of_bank_accounts')" :to="'/accounts/bank-and-cash/archive/'+activeBank.id" class="archive-link svg-white-dart-stroke text-dark fw500 fz13">
      <svg ref="archiveLink" width="24" height="24" viewBox="0 0 24 24" stroke="#2C2C2C" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </router-link>
  </div>
</template>

<script>
import tippy from 'tippy.js';

import { mapState, mapGetters } from 'vuex'
import bankArchiveGetTotal from '@/components/accounts/bankAndCash/archive/bankArchiveGetTotal';

export default {
  components: { bankArchiveGetTotal },
  computed: {
    ...mapState({
      activeBank: state => state.banks.activeBank,
    }),
    ...mapGetters(['getPermissionsByType']),
  },
  mounted() {
    if(this.$refs.archiveLink) {
      tippy(this.$refs.archiveLink, {
        arrow: false,
        content: 'Bank Accounts Archive'
      })
    }
  }
}
</script>

<style lang="scss">
  .bank-details_top{
    display: flex;
    align-items: center;
    padding: 12px 24px;
    &__title{
      max-width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10%;
      cursor: pointer;
      transition: color 0.3s;
      &:hover{
        color: #319FEF;
      }
    }
    &__total{
      margin-right: auto;
      div{
        display: inline-block;
      }
      span{
        font-weight: 500;
      }
      &_title{
        color: #9AA3B0;
      }
    }
    .button-blue-border{
      margin-left: auto;
    }
    .archive-link{
      margin-left: 24px;
      &:hover{
        svg{
          background-color: var(--color-card);
        }
      }
    }
  }
</style>