<template>
  <div class="flex flex-col h-full entries_elems">
    <div class="relative flex border-b border-gray-dark p-t-px">
      <input-format-number
        class="w-full"
        v-model="localNumb"
        inputClass="p-y-2 border-0 fw700 entries_outline h33"
        placeholder="0.00"
        :fractionDigits="2"
        :isRoundOutput="false"
      ></input-format-number>
      <div class="absolute top-2 right-2 flex items-center fz14 cursor-pointer svg-white-dart" @click="$refs.dropCurrency.openList()">
        {{item[column].currency}}
        <svg class="m-l-0.5" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.72443 4.39844C2.45716 4.39844 2.32331 4.72158 2.5123 4.91057L5.78804 8.18631C5.9052 8.30346 6.09515 8.30346 6.21231 8.18631L9.48803 4.91057C9.67702 4.72158 9.54317 4.39844 9.2759 4.39844H2.72443Z" fill="#2C2C2C"/></svg>
      </div>
      <counterparties-drop
        class="hide_input vessel_owners__select_currency"
        ref="dropCurrency"
        :list="valutaList"
        :removeSelectItemBeforeClick="false"
        :dontSelect="true"
        :isDisabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
        :activeItem="activeCurrency"
        @select="selectedCurrency"
        title="Select currency"
      />
    </div>

    <div class="flex border-b border-gray-dark" v-if="!isItemSoa && item.operation_type != 'invoice' && item.operation_type != 'bill'">
      <div class="w-full border-r border-gray-dark">
        <bank-and-cash-select
          placeholder="Choose Bank or Cash"
          :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
          :allowEmpty="true"
          :closeOnSelect="true"
          :selectedBanks="item[column].bank"
          :selectedRequisites="item[column].requisite"
          @onSetBank="onSetRequisite"
          @onSetRequisite="onSetBank"
        />
      </div>

      <div class="flex w-7 flex-shrink-0">
        <router-link class="flex h-full w-full" v-if="item[column].bank" :to="{path: '/accounts/bank-and-cash', query: {bank: item[column].bank.id}}" target='_blank'>
          <entries-arrow class="m-auto"/>
        </router-link>
        <router-link class="flex h-full w-full" v-else-if="item[column].requisite" :to="{path: '/accounts/bank-and-cash', query: {bank: [item[column].requisite.cash]}}" target='_blank'>
          <entries-arrow class="m-auto"/>
        </router-link>
      </div>
    </div>

    <div 
      class="flex border-b border-gray-dark"
      :class="{
        'h-full': !item.has_account && !menu.includes('account')
      }"
    >
      <div class="w-full border-r border-gray-dark">
        <async-select
          :id="'client_account-'+item.id"
          placeholder="Choose Account"
          url="/payment/account/"
          :params="{is_archive: false}"
          watchKey="name"
          :beforeWatchKey="'account_id'"
          :openDirection="''"
          :disabled="!item.is_manual && !getIsPayment || $route.meta.isArchive"
          :closeOnSelect="true"
          :allowEmpty="true"
          :isMultiple="true"
          v-model="item[column].account"
          @on-select="$emit('on-update', false)"
        />
      </div>

      <div class="flex w-7 flex-shrink-0">
        <router-link class="flex h-full w-full" v-if="item[column].account" :to="{path: '', query: {account: [item[column].account.id]}}" target='_blank'>
          <entries-arrow class="m-auto"/>
        </router-link>
      </div>
    </div>

    <div class="flex h-full" v-if="item.has_account && menu.includes('account')">
      <div class="w-full border-r border-gray-dark p-x-2 p-y-2 fz14">
        <span class="text-gray-light" v-if="!item[getOppositeCol].client">Counterparty</span>
        <span class="" v-else>{{item[getOppositeCol].client.company_name}}</span>
      </div>

      <div class="flex w-7 flex-shrink-0">
        <router-link class="flex h-full w-full" v-if="item[getOppositeCol].client" :to="'/counterparties/'+item[getOppositeCol].client.id" target='_blank'>
          <entries-arrow class="m-auto"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import asyncSelect from '@/components/reuse/asyncSelect'
import bankAndCashSelect from '@/components/reuse/bankAndCashSelect'
import entriesArrow from '@/components/entries/entriesArrow'
import inputFormatNumber from '@/components/reuse/inputFormatNumber'

export default {
  components: {
    counterpartiesDrop,
    asyncSelect,
    bankAndCashSelect,
    entriesArrow,
    inputFormatNumber
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    column: {
      type: String,
      required: true
    },
    menu: Array
  },
  data() {
    return {
      activeCurrency: null,
      localNumb: null
    }
  },
  watch: {
    'item.debit.total_amount': function(newVal, oldVal) {
      if(newVal != oldVal){
        this.localNumb = this.item.debit.total_amount
      }
    },
    localNumb: function() {
      this.onChangeNumb()
    }
  },
  computed: {
    ...mapState({
      default: state => state.entries.defaultEntriesDetails,
      valutaList: state => state.payment.valutaList,
      trials: state => state.store.trialsForBankAndCash,

    }),
    ...mapGetters(["getPermissionsByType"]),
    isItemSoa() {
      return this.item.operation_type == 'soa_sublet' || this.item.operation_type == 'soa_with_owners' || this.item.operation_type == 'voyage'
    },
    getIsPayment() {
      return this.item.operation_type == 'payment' || this.item.operation_type == 'invoice' || this.item.operation_type == 'bill' || this.item.operation_type == 'income' 
    },
    getOppositeCol() {
      return this.column == 'debit' ? 'credit' : 'debit'
    }
  },
  methods: {
    ...mapMutations(['addTip']),
    getPermissionShowDopInfoAcc() {
      return this.getPermissionsByType('show_dop_info_for_acc_in_payments')
    },
    selectedCurrency(currency) {
      if(this.item[this.column].requisite){
        if(currency.name != this.item[this.column].requisite.currency){
          this.addTip('Current bank/cash account is in the other currency. Please, choose another bank/cash account')
          this.$refs.dropCurrency.render()
          return false
        }
      }else if(this.item[this.column].bank){
        if(currency.name != this.item[this.column].bank.currency){
          this.addTip('Current bank/cash account is in the other currency. Please, choose another bank/cash account')
          this.$refs.dropCurrency.render()
          return false
        }
      }
      if(this.item.debit.currency != currency.name || this.item.credit.currency != currency.name){
        this.item.debit.currency = currency.name
        this.item.credit.currency = currency.name
        this.$emit('on-update', true)
      }
    },
    onSetBank(val) {
      console.log('bank', val);
      this.item[this.column].requisite = null
      this.item[this.column].bank = val
      this.onSetAccount()
      this.onSetCurrency(val?.currency)
    },
    onSetRequisite(val) {
      console.log('req', val);
      this.item[this.column].bank = null
      this.item[this.column].requisite = val
      this.onSetAccount()
      this.onSetCurrency(val?.currency)
    },
    onSetAccount() {
      if(this.item[this.column]?.requisite?.account){
        this.item[this.column].account = []
        console.log(this.item[this.column]?.requisite?.account)
        let item = Number.isInteger(this.item[this.column].requisite.account) ? this.trials.find(a=> a.id === this.item[this.column].requisite.account) : this.item[this.column].requisite.account
        this.item[this.column].account.push(item)
      }
      else if(this.item[this.column]?.bank?.account){
        console.log(this.item[this.column]?.bank?.account)
        this.item[this.column].account = []
        let item = Number.isInteger(this.item[this.column].bank.account) ? this.trials.find(a=> a.id === this.item[this.column].bank.account) : this.item[this.column].bank.account
        this.item[this.column].account.push(item)
      }else
        this.item[this.column].account = []
    },
    onSetCurrency(currency) {
      if(currency && (this.item.debit.currency != currency || this.item.credit.currency != currency)){
        this.item.debit.currency = currency
        this.item.credit.currency = currency
        this.$emit('on-update', true)
      }else{
        this.$emit('on-update', false)
      }
    },
    onChangeNumb() {
      if(this.localNumb == Math.abs(this.item.debit.total_amount) || this.localNumb == Math.abs(this.item.credit.total_amount)) return false
      
      let result = this.localNumb
      if(!result)
        result = 0
      if(this.item.debit.total_amount < 0) result *= -1
      this.item.debit.total_amount = result
      this.item.credit.total_amount = result
      this.$emit('on-update', true)
    }
  },
  beforeMount() {
    if(!this.item[this.column]) {
      this.item[this.column] = {...this.default}
    }

    this.activeCurrency = {
      id: this.item[this.column].currency,
      name: this.item[this.column].currency
    }

    if(this.item[this.column].bank) this.bankAndCash = this.item[this.column].bank
    if(this.item[this.column].requisite) this.bankAndCash = this.item[this.column].requisite
  },
  mounted() {
    if(this.item[this.column].total_amount)
      this.localNumb = Math.abs(this.item[this.column].total_amount)

    if(this.item.model_name === "Payment" && this.item[this.column].bank_account) {
      this.item[this.column].account = [this.item[this.column].bank_account]
    }

    if(this.item[this.column].account && this.item[this.column].account[0] && this.item[this.column].account[0].name && this.item[this.column].account[0].name.indexOf('Speed Claim')>=0 && this.item[this.column].account[0].name.indexOf('HSFO')>=0) {
      this.item[this.column].account[0].name = this.item[this.column].account[0].name.replace('HSFO', 'VLSFO')
    }
    if(this.item.operation_type == 'soa_with_owners' || this.item.operation_type == 'soa_sublet' && this.item[this.column].account && this.item[this.column].account[0] && this.item[this.column].account[0].name && this.item[this.column].account[0].name.indexOf('Off-Hire')>=0 && this.item[this.column].account[0].name.indexOf('HSFO')>=0) {
      this.item[this.column].account[0].name = this.item[this.column].account[0].name.replace('HSFO', 'VLSFO')
    }
  }
}
</script>

<style scoped lang="scss">

</style>