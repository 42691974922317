<template>
  <div class="filter-block">
    <button class="filter-button" @click="showfilter" :class="{isActiveFilters: hasActiveFilters}">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H18.3392C18.7093 7.05017 19 6.75585 19 6.38127C19 6.00669 18.7093 5.71237 18.3392 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#2C2C2C"/>
        <path d="M14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6204 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3361 15.326 9.61374 14.2423 9.61374ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#2C2C2C"/>
        <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5185 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8028 8.42731 20.5252 9.48458 20.5252C10.5683 20.5252 11.467 19.8028 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5185 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5553 10.5154 18.144C10.5154 18.7326 10.0396 19.1874 9.48458 19.1874C8.92951 19.1874 8.45374 18.7326 8.45374 18.144C8.48018 17.5553 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
      </svg>
      Filter
      <svg class="clear-filters" @click.stop="clearFilters" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="white"/>
        <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="white"/>
      </svg>
    </button>
    <div class="filter-background" @click.prevent="hideAndClean" :class="{show: show}">
    </div>
    <div class="filter-wrapper">
      <div class="filter">
        <div class="filter-loader" v-if="mainFilterLoader">
          <div class="sk-fading-circle">
            <div class="sk-circle sk-circle-1"></div>
            <div class="sk-circle sk-circle-2"></div>
            <div class="sk-circle sk-circle-3"></div>
            <div class="sk-circle sk-circle-4"></div>
            <div class="sk-circle sk-circle-5"></div>
            <div class="sk-circle sk-circle-6"></div>
            <div class="sk-circle sk-circle-7"></div>
            <div class="sk-circle sk-circle-8"></div>
            <div class="sk-circle sk-circle-9"></div>
            <div class="sk-circle sk-circle-10"></div>
            <div class="sk-circle sk-circle-11"></div>
            <div class="sk-circle sk-circle-12"></div>
          </div>
        </div>
        <div class="filter-heading">
          <button class="close-btn" @click="hideFilter">
            <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0004 4.9984C19.3518 5.34988 19.3518 5.91972 19.0004 6.2712L13.2728 11.9988L19.0004 17.7263C19.3518 18.0778 19.3518 18.6476 19.0004 18.9991C18.6489 19.3506 18.079 19.3506 17.7276 18.9991L12 13.2716L6.27243 18.9991C5.92096 19.3506 5.35111 19.3506 4.99964 18.9991C4.64817 18.6476 4.64817 18.0778 4.99964 17.7263L10.7272 11.9988L4.99964 6.27119C4.64817 5.91972 4.64817 5.34987 4.99964 4.9984C5.35112 4.64693 5.92096 4.64693 6.27244 4.9984L12 10.726L17.7276 4.9984C18.079 4.64693 18.6489 4.64693 19.0004 4.9984Z" fill="#202226"/>
            </svg>
          </button>
          <span class="filter-title">Filter</span>
          <button class="clear-filters-btn" v-if="hasActiveFilters" @click="clearFilters">Clear</button>
        </div>
        <div class="filter-content">
          <div class="accordeon-heading">
            <button class="accordeon-button" @click="isManually = true" :class="{active: isManually}">Manually</button>
            <button class="accordeon-button" @click="isManually = false" :class="{active: !isManually}">Templates</button>
          </div>
          <div class="accordeon-content" v-if="isManually">
            <div class="filter-row" v-if="!activeFolder">
              <span class="name">Accounts</span>
              <div class="input-wrapper">
                <Multiselect 
                  v-model="account" 
                  track-by="id" 
                  label="name"
                  :show-labels="false"
                  :options="getDropdownAccounts">
                </Multiselect>
              </div>
            </div>
            <div class="filter-row">
              <span class="name">Condition</span>
              <div class="input-wrapper">
                <span class="sub-name">From</span>
                <div class="input-block">
                  <input 
                    type="text" 
                    v-model="getMailsFrom" 
                    @keyup.enter="$event.target.blur()" 
                    @blur="onEnter('emailFrom')"
                  >
                  <div class="helpers-block" v-if="getFilterHelperEmailsFrom(searchEmailFrom).length != 0">
                    <span 
                      class="helpers-block__item" 
                      @click.stop="addFilterEmail('emailFrom', email)"
                      v-for="(email, index) in getFilterHelperEmailsFrom(searchEmailFrom)" 
                      :key="index" v-html="email">
                    </span>
                  </div>
                  
                </div>
              </div>
              <div class="input-wrapper">
                <span class="sub-name">To</span>
                <div class="input-block">
                  <input 
                    type="text" 
                    v-model="getMailsTo" 
                    @keyup.enter="$event.target.blur()" 
                    @blur="onEnter('emailTo')"
                  >
                  <div class="helpers-block" v-if="getFilterHelperEmailsTo(searchEmailTo).length != 0">
                    <span 
                      class="helpers-block__item" 
                      @click.stop="addFilterEmail('emailTo', email)"
                      v-for="(email, index) in getFilterHelperEmailsTo(searchEmailTo)" 
                      :key="index" v-html="email">
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="filter-row">
              <div class="radio-wrapper">
                <label class="radio-item">
                  <input type="radio" v-model="filters.subject_choice" value="and" name="subject">
                  <span class="my-radio-input"></span>
                  AND
                </label>
                <label class="radio-item">
                  <input type="radio" v-model="filters.subject_choice" value="or" name="subject">
                  <span class="my-radio-input"></span>
                  OR
                </label>
              </div>
              <div class="input-wrapper ">
                <div class="sub-name m-t-2">
                  Subject
                  <button class="contains-button" @click="$emit('subjectContains', !filters.subject.contains)">
                    <span v-if="filters.subject.contains">Contains</span>
                    <span v-else>Doesn’t contain</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 7.0678V5.28814C12.5 5.10092 12.3508 4.94915 12.1667 4.94915H3M3 4.94915L4.91667 3M3 4.94915L4.91667 6.89831M3.5 8.9322V10.7119C3.5 10.8991 3.64924 11.0508 3.83333 11.0508H13M13 11.0508L11.0833 13M13 11.0508L11.0833 9.1017" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
                <label class="input-block">
                  <input type="text" v-model="subject">
                </label>
              </div>
              <div class="radio-wrapper m-t-4">
                <label class="radio-item">
                  <input type="radio" name="text" v-model="filters.text_choice" value="and">
                  <span class="my-radio-input"></span>
                  AND
                </label>
                <label class="radio-item">
                  <input type="radio" name="text" v-model="filters.text_choice" value="or">
                  <span class="my-radio-input" ></span>
                  OR
                </label>
              </div>
              <div class="input-wrapper ">
                <div class="sub-name m-t-2">
                  Text
                  <button class="contains-button" @click="$emit('textContains', !filters.text.contains)">
                    <span v-if="filters.text.contains">Contains</span>
                    <span v-else>Doesn’t contain</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 7.0678V5.28814C12.5 5.10092 12.3508 4.94915 12.1667 4.94915H3M3 4.94915L4.91667 3M3 4.94915L4.91667 6.89831M3.5 8.9322V10.7119C3.5 10.8991 3.64924 11.0508 3.83333 11.0508H13M13 11.0508L11.0833 13M13 11.0508L11.0833 9.1017" stroke="#094172" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
                <label class="input-block">
                  <input type="text" v-model="text">
                </label>
              </div>
            </div>
            <div class="filter-row">
              <span class="name">Date or Period</span>
              <div class="datepicker-wrapper">
                <DatePicker
                  class="my-datepicker"
                  :placeholder="'Select Date'"
                  v-model="date"
                  range
                  format="DD.MM.YYYY">
                </DatePicker>
              </div>
            </div>
            <div class="filter-row">
              <span class="name">Tags</span>
              <div class="input-wrapper">
                <Multiselect 
                  class="my-tags-select"
                  id="ajax" 
                  label="title" 
                  track-by="id" 
                  v-model="filters.tags"
                  placeholder="Type to search" 
                  :options="tagsOptions" 
                  :multiple="true" 
                  :taggable="false"
                  :searchable="true" 
                  :loading="isLoading" 
                  :internal-search="false" 
                  :close-on-select="true" 
                  :options-limit="3" 
                  :max-height="150" 
                  :show-no-results="true" 
                  :hide-selected="true" 
                  :show-labels="false"
                  :clearOnSelect="true"
                  @search-change="asyncFind">
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </Multiselect>
              </div>
            </div>
            <div class="filter-row">
              <span class="name">Files </span>
              <div class="input-wrapper checkbox-wrapper">
                <label class="my-custom-checkbox">
                  <input type="checkbox" v-model="filters.has_files"/>
                  <span></span>
                  <p class="checkbox-name">Contains attached files</p>
                </label>
              </div>
            </div>
            <div class="filter-row border-0" v-if="mailsListType != 'is_read'">
              <span class="name">Status</span>
              <div class="radio-wrapper m-t-2">
                <label class="radio-item">
                  <input type="radio" name="status" v-model="filters.status" value="all">
                  <span class="my-radio-input"></span>
                  All
                </label>
                <label class="radio-item">
                  <input type="radio" name="status" v-model="filters.status" value="unread">
                  <span class="my-radio-input"></span>
                  Unread
                </label>
                <label class="radio-item">
                  <input type="radio" name="status" v-model="filters.status" value="read">
                  <span class="my-radio-input"></span>
                  Read
                </label>
              </div>
            </div>
          </div>
          <div class="accordeon-content" ref="templatesWrapper" v-else>
            <div class="template-item" 
              :class="{active: activeTemplate == template.id, edited: !template.readonly}" 
              v-for="(template, index) in templates" 
              :key="template.id" 
              @click="activeTemplate = template.id">
              <span class="num">{{++index}}.</span>
              <input type="text"  
                @keyup.enter="$event.target.blur()"
                @blur="saveTemplate(template.id)" 
                :readonly="template.readonly" 
                class="template-name" 
                v-model="template.name">

              <div class="menu" v-click-outside="hideSubMenu">
                <svg class="menu-btn" width="24" height="24" @click="showSubMenu($event)">
                  <use xlink:href="#menu_icn"></use>
                </svg>
                <div class="sub-menu">
                  <button class="sub-menu__button" @click="editName(template, $event)">
                    <svg width="24" height="24">
                      <use xlink:href="#edit_name_icn"></use>
                    </svg>
                    <span>Edit name</span>
                  </button>
                  <button class="sub-menu__button" @click="editTemplate(template)">
                    <svg width="24" height="24">
                      <use xlink:href="#edit_template_icn"></use>
                    </svg>
                    <span>Edit Template</span>
                  </button>
                  <button class="sub-menu__button" @click="$emit('delTemplate', template.id)">
                    <svg width="24" height="24">
                      <use xlink:href="#delete_icn"></use>
                    </svg>
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="placeholder" v-if="templates.length == 0">
              <span class="title">No template created yet</span>
              <span class="text">You can customize the filter options and save them as a template</span>
            </div>
          </div>
        </div>
        <div class="filter-footer" :class="{hidden: templates.length == 0 && !isManually}">
          <button class="footer-btn" :class="{ loader: showFooterButtonLoader}" @click="createTemplate" v-if="isManually">
            <span v-if="!isEditTemplate">Create Template</span>
            <span v-else>Save Template</span>
            <svg class="loader-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <g transform="translate(20 50)">
              <circle cx="0" cy="0" r="6" fill="#319FEF">
                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
              </g><g transform="translate(40 50)">
              <circle cx="0" cy="0" r="6" fill="#319FEF">
                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
              </g><g transform="translate(60 50)">
              <circle cx="0" cy="0" r="6" fill="#319FEF">
                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
              </g><g transform="translate(80 50)">
              <circle cx="0" cy="0" r="6" fill="#319FEF">
                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
              </circle>
              </g>
            </svg>
          </button>
          <button class="footer-btn blue-btn" 
            :class="{'full-width': !isManually, disabled: !activeTemplate && !isManually}" 
            @click="apply">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { mapGetters, mapActions } from "vuex";
  export default {
    name: 'mailFilter',
    props: {
      filters: {type: Object},
      accounts: {type: Array},
      hasActiveFilters: {type: Boolean},
      mailsListType: {type: String},
      templates: {type: Array},
      activeFolder: {default: null}
    },
    components: {Multiselect, DatePicker},
    inject: ['clearFilterFields'],
    data: () => ({
      show: false,
      isManually: true,
      showFooterButtonLoader: false,
      selectedAccount: null,
      searchEmailFrom: '',
      searchEmailTo: '',
      tagsOptions: [],
      selectedTags: [],
      isLoading: false,
      activeTemplate: null,
      isEditTemplate: false,
      mainFilterLoader: false
    }),
    methods: {
      ...mapActions(['axiosGetMailboxTags']),
      showfilter(){
        this.show = true
        this.isLoading = true
        this.axiosGetMailboxTags()
        .then(response => {
          this.tagsOptions = response.data.results
          this.isLoading = false
        })
      },
      hideFilter(){
        this.show = false
        this.isManually = true
        this.activeTemplate = null
        this.isEditTemplate = false
        this.mainFilterLoader = false
        this.showFooterButtonLoader = false
      },
      hideAndClean(){
        this.hideFilter()

        // this.clearFilterFields()
        // this.apply()
      },
      showTemplateButtonLoader(){
        this.showFooterButtonLoader = true;
      },
      hideTemplateButtonLoader(){
        this.showFooterButtonLoader = false;
      },
      clearFilters(){
        this.$emit('clearFilters')
        this.hideFilter()
      },
      createTemplate(){
        this.showTemplateButtonLoader()
        if(!this.isEditTemplate){
          // Создание нового шаблона
          this.$emit('createFilterTemplate')
        }else{
          // Пересохранить текущий
          this.$emit('saveFilterTemplate')
        }
      },
      saveTemplate(id){
        this.$emit('changeTemplateName', id)
      },
      async setActiveTemplate(id){
        this.activeTemplate = id;
        this.isManually = false;
        this.showFooterButtonLoader = false;
        await this.$nextTick()
        let inputs = this.$refs.templatesWrapper.querySelectorAll('.template-name')
        for(var i = 0; i < inputs.length; i++){
          if(!inputs[i].getAttribute('readonly')){
            inputs[i].focus()
            break
          }
        }
      },
      
      onEnter(name){
        let string = ''
        if(name == 'emailFrom'){
          if(this.filters.email_from.length != '' && this.searchEmailFrom != ''){
            string = `${this.filters.email_from}, `;
            setTimeout(() => {
              this.searchEmailFrom = ''
            }, 500)
            this.$emit('updateEmailFrom', string)
          }
        }else{
          if(this.filters.email_to.length != '' && this.searchEmailTo != ''){
            string = `${this.filters.email_to}, `;
            setTimeout(() => {
              this.searchEmailTo = ''
            }, 500)
            this.$emit('updateEmailTo', string)
          }
        }
      },
      addFilterEmail(name, email){
        if(name == 'emailFrom'){
          let arr = this.filters.email_from.split(',')
          arr.length = arr.length - 2;
          arr.push(email.replace(/<\/?strong>/g, ''))
          let string = arr.join(',') + ', '
          this.$emit('updateEmailFrom', string)
        }else{
          let arr = this.filters.email_to.split(',')
          arr.length = arr.length - 2;
          arr.push(email.replace(/<\/?strong>/g, ''))
          let string = arr.join(',') + ', '
          this.$emit('updateEmailTo', string)
        }
      },
      // tags
      asyncFind (query) {
        this.isLoading = true
        this.axiosGetMailboxTags({search: query})
        .then(response => {
          this.tagsOptions = response.data.results
          this.isLoading = false
        })
      },
      apply(){
        if(this.isManually){
          this.$emit('applyFilters')
        }else{
          this.$emit('applyTemplate', this.activeTemplate)
        }
        this.hideFilter()
      },
      showSubMenu(e){
        setTimeout(() => {
          e.target.nextSibling.classList.add('show')
        }, 100)
      },
      hideSubMenu(){
        let submenues = this.$refs.templatesWrapper.querySelectorAll('.sub-menu.show')
        submenues.forEach(element => {
          element.classList.remove('show')
        });
      },
      editName(template, e){
        template.readonly = false;
        this.hideSubMenu()
        e.target.closest('.template-item').querySelector('.template-name').focus()
      },
      editTemplate(template){
        this.mainFilterLoader = true
        this.activeTemplate = template.id;
        this.$emit('editTemplate', template.id)
      },
      showEditTemplate(){
        this.isEditTemplate = true
        this.isManually = true
        this.mainFilterLoader = false
      }
    },
    computed: {
      ...mapGetters(["getFilterHelperEmailsTo", "getFilterHelperEmailsFrom"]),
      // isActiveFilters(){
      //   return true;
      // },
      getMailsFrom: {
        get(){
          return this.filters.email_from
        },
        set(val){
          let arr = val.split(', ')
          let string = arr[arr.length -1].trim()
          this.searchEmailFrom = string;
          this.$emit('updateEmailFrom', val)
        }
      },
      getMailsTo: {
        get(){
          return this.filters.email_to
        },
        set(val){
          let arr = val.split(', ')
          let string = arr[arr.length -1].trim()
          this.searchEmailTo = string;
          this.$emit('updateEmailTo', val)
        }
      },
      subject: {
        get(){
          return this.filters.subject.subject_contains
        },
        set(val){
          this.$emit('updSubject', val )
        }
      },
      text: {
        get(){
          return this.filters.text.text_contains
        },
        set(val){
          this.$emit('updText', val )
        }
      },
      date: {
        get(){
          return [this.filters.date.date_after, this.filters.date.date_before]
        },
        set(val){
          this.$emit('updDate', {
            date_after: val[0],
            date_before: val[1]
          })
        }
      },
      account: {
        get(){
          return this.filters.account
        },
        set(val){
          this.$emit('updAccount', val)
        }
      },
      getDropdownAccounts() {
        let accounts = this.accounts;
        accounts.unshift({
          id: null,
          name: 'All inboxes'
        })
        return accounts
      }
    },
    // directives: {
    //   'custom-click-outside': {
    //     bind: function (el, binding, vnode) {
    //       el.clickOutsideEvent = function (event) {
    //         if (!(el == event.target || el.contains(event.target))) {
    //           vnode.context[binding.expression](event)
    //         }
    //       };
    //       document.body.addEventListener('click', el.clickOutsideEvent)
    //     },
    //     unbind: function (el) {
    //       document.body.removeEventListener('click', el.clickOutsideEvent)
    //     },
    //   }
    // },
  }
</script>

<style lang="scss" scoped>
$spinkit-size: 2em !default;
$spinkit-spinner-color: #319FEF !default;
.filter-block{
  margin-left: auto;
}
.filter-button{
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  column-gap: 8px;
  .clear-filters{
    display: none;
  }
  &.isActiveFilters{
    background: var(--color-blue);
    color: var(--color-white);
    svg{
      path{
        fill: var(--color-white);
      }
    }
    .clear-filters{
      display: flex;
    }
  }
}
.filter-background{
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.20);
  z-index: 4;
  transition: .3s;
  opacity: 0;
  pointer-events: none;
  &.show{
    opacity: 1;
    transition: .3s;
    pointer-events: auto;
  }
}
.filter-wrapper{
  position: fixed;
  height: 100vh;
  background: #fff;
  width: 0;
  right: 0;
  top: 0;
  z-index: 999;
  transition: width .3s;
  overflow: hidden;
}
.filter-background.show + .filter-wrapper{
  width: 375px;
  transition: width .3s;
}
.filter{
  height: 100vh;
  display: flex;
  flex-direction: column;
  
  .filter-heading{
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-dark);
    height: 48px;
    min-height: 48px;
    min-width: 320px;
    & *{
      transition: .3s;
    }
    .close-btn{
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      &:hover{
        svg{
          path{
            fill: var(--color-blue-dark);
          }
        }
      }
    }
    .filter-title{
      margin-right: auto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-left: 8px;
    }
    .clear-filters-btn{
      font-size: 13px;
      font-weight: 500;
      line-height: normal;
      color: var(--color-red);
    }
  }
  .filter-footer{
    padding: 0 16px;
    border-top: 1px solid var(--color-gray-dark);
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    min-width: 320px;
    &.hidden{
      display: none;
    }
    .footer-btn{
      height: 32px;
      flex-grow: 1;
      border-radius: 4px;
      border: 1px solid var(--color-blue);
      color: var(--color-blue);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      transition: .3s;
      width: 50%;
      .loader-svg{
        display: none;
      }
      &:hover{
        color: var(--color-blue-dark);
        border-color: var(--color-blue-dark);
        transition: .3s;
      }
      &.blue-btn{
        color: var(--color-white);
        background: var(--color-blue);
        &:hover{
          border-color: var(--color-blue-dark);
          background: var(--color-blue-dark);
          transition: .3s;
        }
      }
      &.full-width{
        width: 100%;
      }
      &.disabled{
        pointer-events: none;
        opacity: 0.5;
      }
      &.loader{
        pointer-events: none;
        opacity: 0.5;
        span{
          display: none;
        }
        .loader-svg{
          display: flex;
          margin: 0 auto;
        }
      }
    }
  }
  .filter-content{
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    // border: 1px solid red;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray-bread);
      border-radius: 9px;
    }
  }
  .placeholder{
    align-self: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4px;
    .title{
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .text{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 213px;
      text-align: center;
    }
  }
}
.accordeon-heading{
  height: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-dark);
  min-width: 320px;
  .accordeon-button{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    &.active{
      color: var(--color-blue-dark);
      &::after{
        content: '';
        display: flex;
        position: absolute;
        width: 100%;
        height: 2px;
        background: var(--color-blue-dark);
        bottom: 0;
      }
    }
  }
}
.accordeon-content{
  flex-grow: 1;
}
.filter-row{
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-gray-dark);
  .name{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .sub-name{
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-new-dark-gray);
  }
  .input-wrapper{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    input{
      z-index: 2;
    }
    &.checkbox-wrapper{
      align-items: flex-start;
    }
  }
  .input-block{
    margin-top: 5px;
    position: relative;
  }
}
.radio-wrapper{
  display: flex;
  align-items: center;
  column-gap: 16px;
  .radio-item{
    display: flex;
    align-items: center;
    position: relative;
    *{
      transition: .3s;
    }
    &:hover{
      cursor: pointer;
      .my-radio-input{
        border-color:  var(--color-blue-dark);
      }
    }
    input[type="radio"]{
      position: absolute;
      z-index: -99999;
      opacity: 0;
    }
    .my-radio-input{
      display: flex;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      min-width: 18px;
      border: 2px solid var(--color-gray-text);
      margin-right: 8px;
      position: relative;
      justify-content: center;
      align-items: center;
    }
    input[type="radio"]:checked + .my-radio-input{
      border-color: var(--color-blue-dark);
      &::after{
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        background: var(--color-blue-dark);
        border-radius: 50%;
      }
    }
  }
}
.contains-button{
  display: inline-flex;
  align-items: center;
  padding-left: 8px;
  span{
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-blue-dark);
  }
  svg{
    position: relative;
    top: 1px;
    left: 4px;
  }
}
.my-custom-checkbox{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  [type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -9999;
  }
  &:hover{
    cursor: pointer;
  }
  span {
    display: flex;
    align-items: center;
    &:before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid var(--color-gray-text);
      right: 0;
      left: 0;
      margin: auto;
    }
  }
  [type="checkbox"]:checked + span:before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1.02141C11.1868 0.673336 10.67 0.663685 10.3456 0.999847C10.3388 1.00688 10.3321 1.01407 10.3255 1.02141L4.76395 6.91934L1.88355 4.7889C1.55922 4.45274 1.04241 4.46239 0.72918 4.81046C0.423607 5.15001 0.423607 5.68829 0.72918 6.02784L4.18677 8.77773C4.50559 9.1198 5.02236 9.1198 5.34114 8.77773L11.4799 2.26035C11.8042 1.92414 11.8132 1.36948 11.5 1.02141Z' fill='%234B4B4B'/%3E%3C/svg%3E%0A");
  }
  .checkbox-name{
    padding-left: 8px;
  }
}
.helpers-block{
  position: absolute;
  // padding: 6px 0px;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  width: calc(100% - 40px);
  // row-gap: 6px;
  z-index: 2;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.8);
  max-height: 200px;
  overflow-y: auto;
  .helpers-block__item{
    // color: var(--color-gray-text);
    color: var(--color-dark);
    font-size: 13px;
    transition: .3s;
    min-height: 38px;
    padding: 3px 6px;
    box-sizing: border-box;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-text);
    /deep/{
      strong{
        font-weight: bold;
        color: var(--color-dark);
      }
    }
    &:last-child{
      border-bottom: none;
    }
    &:hover{
      cursor: pointer;
      color: var(--color-blue-dark);
      transition: .3s;
    }
  }
}
.template-item{
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  &.active{
    background: #F4F6F9;
  }
  &.edited{
    background: #E5EEFF;
  }
  &:hover{
    &:not(.edited){
      background: #F4F6F9;
      cursor: pointer;
    }
  }
  .num{
    font-size: 14px;
  }
  .template-name{
    max-width: 80%;
    border: none;
    background: none;
    padding: 0;
    &:read-only{
      pointer-events: none;
    }
  }
  .menu{
    position: relative;
    svg{
      use{
        pointer-events: none;
      }
    }
  }
  .sub-menu{
    position: absolute;
    padding: 8px;
    display: none;
    flex-direction: column;
    row-gap: 8px;
    right: 0;
    top: 0;
    background: var(--color-white);
    z-index: 9;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    &.show{
      display: flex;
    }
  }
  .sub-menu__button{
    display: flex;
    align-items: center;
    column-gap: 4px;
    span{
      white-space: nowrap;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:hover{
      span{
        color: var(--color-blue);
      }
    }
  }
  .menu-btn{
    &:hover{
      cursor: pointer;
    }
  }
}
.filter-loader{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba($color: #fff, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .sk-fading-circle {
    $circleCount: 12;
    $animationDuration: 1.2s;

    width: $spinkit-size;
    height: $spinkit-size;
    position: relative;
    margin: auto;

    .sk-circle {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .sk-circle:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $spinkit-spinner-color;
      border-radius: 100%;
      animation: sk-fading-circle-delay $animationDuration infinite ease-in-out both;
    }

    @for $i from 2 through $circleCount {
      .sk-circle-#{$i} {
        transform: rotate((360deg / $circleCount * ($i - 1)));
      }
    }

    @for $i from 2 through $circleCount {
      .sk-circle-#{$i}:before {
        animation-delay: (-$animationDuration + $animationDuration / $circleCount * ($i - 1));
      }
    }
  }

  @keyframes sk-fading-circle-delay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
}

</style>
<style lang="scss">
  #app{
    .datepicker-wrapper{
      padding-top: 4px;
      .my-datepicker{
        width: 100%;
        .mx-input-wrapper{
          .mx-input{
            background-color: var(--color-gray-lightest) !important;
            height: 39px;
          }
        }
      }
    }
    .filter-block{
      .input-wrapper{
        .multiselect__select:before{
          border-width: 4px 4px 0;
        }
      }
    }
    .input-wrapper{
      
      .my-tags-select{
        min-height: 39px;
        .multiselect__tags{
          min-height: 39px;
        }
        .multiselect__tag{
          background: var(--color-violet);
          color: var(--color-dark);
          .multiselect__tag-icon{
            &:after{
              color: var(--color-dark);
            }
            &:hover{
              background: var(--color-purple);
              &:after{
                color: var(--color-white);
              }
            }
          }
        }
      }
    }
  }
</style>