<template>
  <modal-slots :show-mod="true" class="modal-archive" :textForSecondButton="'Delete'" @close="$emit('close')" @apply="apply">
    <template v-slot:title>
      <p class="fz16 text-dark fw500">Delete forever</p>
    </template>
    <p class="fz14 text-dark-gray mb p16 pt0">Are you sure you want to delete forever it?</p>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import {mapActions, mapMutations} from "vuex";
export default {
  name: "folders-modal-delete",
  components: {ModalSlots},
  props: {
    items: [Array],
    isSelectedMode: Boolean,
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(['axiosSetAccessUsersForFolders', 'axiosDeleteFolderForeverNew']),
    ...mapMutations(['showLoad', 'hideLoad', 'deleteFileWithFoldersSection', 'clearSelectedFoldersPage', 'addTip', 'createEmitGetActivityFolders']),
    async apply() {
      // for(let i=0 ; i<this.items.length; i++) {
      //   let form = {
      //     id: this.items[i].id,
      //     is_archive: this.$route.path.indexOf('archive') === -1
      //   }
      //   this.axiosPutFileForFolders()
      // }
      this.showLoad()

      let items = this.items
      let bool = await this.axiosDeleteFolderForeverNew(items.map(a=> {return a.id}))
      if(bool) {
        for(let i=0; i< items.length; i++) {
          // await this.axiosDeleteFolderForever(items[i].id)
          this.deleteFileWithFoldersSection(items[i].id)
        }
      } else {
        this.addTip('Error')
      }

      this.hideLoad()
      this.$emit('close')
      this.createEmitGetActivityFolders()
      if(this.isSelectedMode) {
        this.clearSelectedFoldersPage()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-archive {
  /deep/ {
    .top-panel {
      border: none !important;
      padding: 16px !important;
    }
    .bottom-panel {
      border: none !important;
    }
    .blue-button {
      width: 100px !important;
    }
    .content {
      min-width: 400px !important;
    }
  }
}
</style>