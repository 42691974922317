import Axios from 'axios'
var qs = require('qs');
export default {
  state: {
    filterHelperEmailsTo: [],
    filterHelperEmailsFrom: [],
    signaturesList: []
  },
  mutations: {
    setFilterHelperEmailsTo(state, data){
      state.filterHelperEmailsTo = data;
    },
    setFilterHelperEmailsFrom(state, data){
      state.filterHelperEmailsFrom = data;
    },
    setSignatures(state, data){
      state.signaturesList = data;
    }
  },
  actions: {
    // Получить все аккаунты
    axiosGetMailboxAccounts(ctx){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/accounts/",
          // params: {
          //   from_partner_login: true
          // }
        })
        .then(resp => {
          let emails = resp.data.map(account => account.email)
          ctx.commit("setFilterHelperEmailsTo", emails);
          resolve(resp)
        })
        .then(() => {
          ctx.dispatch("axiosGetMailboxEmailsFromList", {type: 'email_from'})
          ctx.dispatch("axiosGetMailboxEmailsFromList", {type: 'email_to'})
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // Подсказки для фильтра emails
    axiosGetMailboxEmailsFromList(ctx, query){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/filter/emails/list/",
          params: {...query}
        })
        .then(resp => {
          if(query.type == 'email_from'){
            ctx.commit("setFilterHelperEmailsFrom", resp.data.emails_list)
          }else{
            ctx.commit("setFilterHelperEmailsTo", resp.data.emails_list)
          }
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    axiosUpdateNameMailboxAccount(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'patch',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/accounts/"+data.id,
          data: {
            name: data.name
          }
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    axiosUpdateSignatureMailboxAccount(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'patch',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/accounts/"+data.id,
          data: {
            signature: data.signature
          }
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Logout
    axiosLogoutMailboxAccount(ctx, accountId){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'delete',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/accounts/"+accountId,
          // params: {
          //   id: accountId
          // }
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Кол-во всех писем
    axiosGetAllEmailsCountMailbox(ctx){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/accounts/mail/count",
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Get emails list
    axiosGetMailboxEmails(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/",
          params: {...data},
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: "repeat" })
          },
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Get mail detail
    axiosGetMailboxEmailDetail(ctx, id){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/"+id,
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Get mails list detail
    axiosGetMailboxEmailsByIds(ctx, ids){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/detail/",
          params: {...ids},
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: "comma" })
          },
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Del tag
    axiosGetMailboxEmailDelTag(ctx, id){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'delete',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/tags/"+id,
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Remove tags from detail email
    axiosMailboxRemoveTagFromEmail(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'patch',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/"+data.mailId+"/untying/",
          data: data.info
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Get tags
    axiosGetMailboxTags(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/tags/",
          params: {...data}
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Get tag
    axiosGetMailboxTagById(ctx, id){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/tags/"+id+'/',
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    
    /**
     * Create tag
     * @param {title: String} data 
     * @returns 
     */
    axiosMailboxEmailCreateTag(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'post',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/tags/",
          data: data
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // filters templates
    // Get templates all
    axiosGetMailboxFilterTemplatesAll(ctx){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/filter/templates/",
        })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // Create template
    axiosCreateMailboxFilterTemplate(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'post',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/filter/templates/",
          data: data
        })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // Get single template
    axiosGetSingleMailboxFilterTemplate(ctx, id){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/filter/templates/"+id,
        })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // Change template
    axiosChangeMailboxFilterTemplate(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'put',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/filter/templates/"+data.id,
          data: data.info
        })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // Delete template
    axiosDeleteMailboxFilterTemplate(ctx, id){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'delete',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/filter/templates/"+id,
        })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // Action buttons on nav panel && upd draft
    axiosUpdateMailboxEmailItem(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'patch',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/update/bulk/",
          data: data
        })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // Download emails
    axiosMailboxDownloadEmails(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          responseType: 'blob',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/download/",
          params: {...data},
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: "comma" })
          },
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Create folder
     * @param {*} ctx 
     * @param {
     *  title: String
     *  account: int (account id)
     *  parent_folder: int (parent folder id)
     *  tag_words: Array (tags list)
     * } data 
     * @returns 
     */
    axiosMailboxCreateFolder(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'post',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/folders/",
          data: data
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Update folder
     * @param {*} ctx 
     * @param {
    *  title: String
    *  account: int (account id)
    *  parent_folder: int (parent folder id)
    *  tag_words: Array (tags list)
    *  id: int (current folder id)
    * } data 
    * @returns 
    */
    axiosMailboxUpdateFolder(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'patch',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/folders/"+data.id,
          data: data
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    axiosMailboxDeleteFolder(ctx, folderId){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'delete',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/folders/"+folderId,
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // get folder info
    axiosGetMailboxFolderInfo(ctx, id){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/folders/"+id+'/',
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Check folder tag
    axiosCheckMailboxFolderTag(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/folders/tag/check/",
          params: {...data},
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: "repeat" })
          },
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * 
     * @param {*} ctx 
     * @param {
     *  subject: String
     *  text: String
     *  email_from: String
     *  email_to: Array
     *  email_to_cc: Array
     *  email_to_bcc: Array
     *  files: [uniqueID]
     *  type: String ('send', 'drafts')
     *  reply_to_message_id: String
     * } data 
     * @returns 
     */
    axiosMailboxCreateEmail(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'post',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/",
          data: data
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * 
     * @param {*} ctx 
     * @param {
     *  ids: Array
     * } data 
     * @returns 
     */
    axiosDeleteMailboxEmailItem(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'delete',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/delete/bulk/",
          data: data
        })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Upload eml file
     * @param {*} ctx 
     * @param {
     *  file: file
     *  folder_id: Number
     * } formData 
     * @returns 
     */
    axiosMailboxUploadEml(ctx, formData){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'post',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/mails/upload-eml/",
          data: formData,
        })
        .then(resp => {
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // Get signatures list
    axiosGetMailboxSignature(ctx){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'get',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/signature/",
        })
        .then(({data}) => {
          ctx.commit("setSignatures", data.results);
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    /** Update signatures list
     * 
     * @param {*} ctx 
     * @param {
     *  type: Array 
     *  [{
     *    id: Int,
     *    *content: String
     *  }]
     * } data 
     * @returns 
     */
    axiosUpdateMailboxSignature(ctx, data){
      return new Promise((resolve, reject) => {
        Axios({
          method: 'post',
          headers: {
            'Authorization': 'token ' + ctx.getters.getToken
          },
          url: process.env.VUE_APP_URL + "/mailbox/signature/bulk/",
          data: data
        })
        .then(({data}) => {
          ctx.dispatch("axiosGetMailboxSignature")
          .then(() => {
            resolve(data)
          })
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // updateSignatures(ctx, data){
    //   ctx.commit("setSignatures", data);
    // }

  },
  getters: {
    getFilterHelperEmailsTo: (state) => string => {
      if(string != ''){
        // return state.filterHelperEmailsTo.map((item) => item.indexOf(string) >= 0 ? item : -1).filter(item => item != -1)
        return state.filterHelperEmailsTo.map((item) => item.indexOf(string) >= 0 ? item.replace(string, `<strong>${string}</strong>`) : -1).filter(item => item != -1)
      }else{
        return []
      }
    },
    getFilterHelperEmailsFrom: (state) => string => {
      if(string != ''){
        return state.filterHelperEmailsFrom.map((item) => item.indexOf(string) >= 0 ? item.replace(string, `<strong>${string}</strong>`) : -1).filter(item => item != -1)
      }else{
        return []
      }
    },
    getSignatures: (state) => {
      return state.signaturesList
    },
  }
}