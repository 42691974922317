<template>
  <div class="flex flex-wrap box-border-child border border-dark-gray bg-gray-dark rounded-lg">
    <calcVesselsSpeedResultRow 
      class="w-1/2 border-r border-b border-gray-text"
      :fuel="value"
      prefix="full"
      :detail="detail"
      @on-click="$emit('input', 'full')"
    />
    <calcVesselsSpeedResultRow 
      class="w-1/2 border-b border-gray-text"
      :fuel="value"
      prefix="eco"
      :detail="detail"
      @on-click="$emit('input', 'eco')"
    />
    <calcVesselsSpeedResultRow 
      class="w-1/2 border-r border-gray-text"
      :fuel="value"
      prefix="optimal"
      :detail="detail"
      @on-click="$emit('input', 'optimal')"
    />
    <calcVesselsSpeedResultRow 
      class="w-1/2"
      :fuel="value"
      prefix="slow"
      :detail="detail"
      @on-click="$emit('input', 'slow')"
    />
  </div>
</template>

<script>
import calcVesselsSpeedResultRow from '@/components/calc/calcVesselsSpeedResultRow'
export default {
  props: {
    value: String,
    detail: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {
    calcVesselsSpeedResultRow
  },
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>