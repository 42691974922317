import axios from 'axios'

export default {
    actions: {
        getAxiosFileForTask(ctx, id) {
            return axios.get(process.env.VUE_APP_URL+"/file/task/?task_id=" + id,  {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                // let ids = response.data.results.map(a=> {
                //     return a.id;
                // })
                ctx.commit("setFileForTask", response.data.results);
            })
        },
        changeAxiosFileForTask(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL+"/file/task/" + data.id, {
                // "file": data.file,
                // "thumbnail": data.thumbnail,
                // "type": data.type,
                "name": data.name,
                "task_id": data.task_id,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
            axiosDeleteFileTask(ctx, data) {
                return  axios.patch(process.env.VUE_APP_URL+"/task/" + data.id, {
                    'files': data.filesId
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                });
        },
        axiosGetActivity(ctx, id) {
            id = id.replaceAll("-", "");
            return axios.get(process.env.VUE_APP_URL+"/activity/task/?task=" + id, {
                params: {
                    "ordering": 'created_at'
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                // console.log("RESPOPNSE Activity");
                // console.log(response)
                ctx.commit("setTaskActivity", response.data.results);
            })
        }
    },
    mutations: {
        setFileForTask(state, data) {
            state.fileForTask = data;
        },
        setTaskActivity(state, data) {
            state.taskActivity = data;
        },
        setIdTaskDuplicate(state, data) {
            state.idTaskDuplicate = data;
        },
        setIdTaskDuplicateVessel(state, data) {
          state.idTaskDuplicateVessel = data;
        },
        setIdTaskDuplicateCoupe(state, data) {
            state.idTaskDuplicateCoupe = data;
        },
        setIdTaskDuplicateWithoutVessel(state, data) {
            state.idTaskDuplicateWithoutVessel = data;
        },
        setIdTaskDuplicateWithVessel(state, data) {
            state.idTaskDuplicateWithVessel = data;
        },
    },
    state: {
        taskActivity: [],
        taskInfo: {},
        fileForTask: [],
        idTaskDuplicate: null,
        idTaskDuplicateVessel: null,
        idTaskDuplicateCoupe: null,
        idTaskDuplicateWithoutVessel: null,
        idTaskDuplicateWithVessel: null
    },
    getters: {
        getFileForTask(state) {
            return state.fileForTask;
        },
        getTaskActivity(state) {
            return state.taskActivity;
        },
        getIdTaskDuplicate(state) {
            return state.idTaskDuplicate;
        },
        getIdTaskDuplicateVessel(state) {
            return state.idTaskDuplicateVessel;
        },
        getIdTaskDuplicateCoupe(state) {
            return state.idTaskDuplicateCoupe;
        },
        getIdTaskDuplicateWithoutVessel(state) {
            return state.idTaskDuplicateWithoutVessel;
        },
        getIdTaskDuplicateWithVessel(state) {
            return state.idTaskDuplicateWithVessel;
        },
    },
}
