<template>
  <div class="border border-dark-gray m-b-4 rounded-lg overflow-hidden" v-if="getCEV.additional_info.is_row">
    <div class="vessel_owners__table">
      <div class="first_element_stroke bg-blue-dark colspan-1-6 uppercase p-x-3 p-y-2 fz13 fw500 text-white">C/E/V</div>
    </div>
    <div class="vessel_owners__table" v-if="getCEV.additional_info.is_row">
      <div class="first_element_stroke bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Qntty, mt</div>
      <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Price, {{owners ? owners.currency : 'USD'}}/mt</div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b"></div>
    </div>
    <div class="vessel_owners__table" v-if="getCEV.additional_info.is_row">
      <div class="first_element_stroke border-r uppercase p-x-3 p-y-2 fz13 fw500 text-dark">C/E/V</div>
      <div class="border-r"></div>
      <div class="border-r p-x-2 p-y-2 relative pr95 box-border">
        {{getCEV.additional_info.rate | formatNumberTwoZeroEmpty}}
        <span class="absolute top-2 right-2 text-gray-text fz13">Rate, {{owners ? owners.currency : 'USD'}}/PMPR</span>
      </div>
      <div class="border-r p-x-2 p-y-2 text-align-right amount">{{getCEV.debit | formatNumberTwoZeroEmpty}}</div>
      <div class="p-x-2 p-y-2 text-align-right amount">{{getCEV.credit | formatNumberTwoZeroEmpty}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bunkersOnTableCEV",
  props: {
    owners: Object,
    bunkersOnData: Array
  },
  computed: {
    getCEV() {
      return this.bunkersOnData.find(el => {
        return el.bunkers_type == 'C/E/V'
      })
    },
  }
}
</script>

<style scoped>
.pr95 {
  padding-right: 103px !important;
}

</style>