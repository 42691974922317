<template>
  <div class="" v-if="permissions.length && getPermissionsByType('show_trial_balance')">
    <trial-balance-top @changeSearch="(e)=> {search = e}" @downloadPDF="downloadPDF" @downloadXLSX="downloadXLSX" @add-account="addAccount" />
    <trial-balance-list :search="search" ref="trialList" @select="onSelect" @on-edit="onEdit" @on-dupl="onDupl" @on-archive="onArchive" />

    <trial-balance-bottom @on-close="$refs.trialList.uncheckAll()" :selectedListId="selectedListId" @on-archive="onArchive" />

    <trial-balance-modal :account="editAccount" :duplAccount="duplAccount" v-if="isModalOpen" @close="closeModal"/>
    <trial-balance-modal-archive :list="selectedListId" :account="editAccount" v-if="isModalArchiveOpen" @close="closeModal" @submit="archiveAccount"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import trialBalanceTop from '@/components/accounts/trials/trialBalanceTop'
import trialBalanceList from '@/components/accounts/trials/trialBalanceList'
import trialBalanceBottom from '@/components/accounts/trials/trialBalanceBottom'
import trialBalanceModal from '@/components/accounts/trials/trialBalanceModal'
import trialBalanceModalArchive from '@/components/accounts/trials/trialBalanceModalArchive'

export default {
  components: {
    trialBalanceTop,
    trialBalanceList,
    trialBalanceBottom,
    trialBalanceModal,
    trialBalanceModalArchive
  },
  data() {
    return {
      search: '',
      selectedListId: [],
      editAccount: null,
      duplAccount: null,
      isModalOpen: false,
      isModalArchiveOpen: false
    }
  },
  watch: {
    permissions: function(val) {
      if(val.length && !this.getPermissionsByType('show_trial_balance'))
        this.$router.replace({name: 'employees'})
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.confirmation.permissionsForUser,
      trials: state => state.trialBalance.trials,
      financialStatement: state => state.trialBalance.financialStatement
    }),
    ...mapGetters(['getPermissionsByType']),
  },
  methods: {
    ...mapActions(['axiosGetTrials', 'axiosGetsetFinancialStatementlist', 'axiosTrialAccountToArchive', 'axiosGetTrialsArchive']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    downloadPDF() {
      this.$refs.trialList.downloadPDF();
    },
    downloadXLSX() {
      this.$refs.trialList.downloadXLSX();
    },
    onSelect(list) {
      this.selectedListId = list.slice()
    },
    addAccount() {
      this.editAccount = null
      this.duplAccount = null
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
      this.isModalArchiveOpen = false
    },
    onArchive(account) {
      if(account)
        this.editAccount = account
      else 
        this.editAccount = null

      this.isModalArchiveOpen = true
    },
    async archiveAccount(ids) {
      await this.axiosTrialAccountToArchive({ids: ids, bool: true})
      if(this.editAccount)
        this.addTip(this.editAccount.name + ' is archived')
      else if(ids.length == 1)
        this.addTip(ids.length + ' account is archived')
      else
        this.addTip(ids.length + ' accounts are archived')

      this.axiosGetTrials()
      this.axiosGetTrialsArchive()
      this.$refs.trialList.uncheckAll()

      this.isModalArchiveOpen = false
    },
    onEdit(account) {
      this.editAccount = account
      this.duplAccount = null
      this.isModalOpen = true
    },
    onDupl(account) {
      this.editAccount = null
      this.duplAccount = account
      this.isModalOpen = true
    }
  },
  async mounted() {
    // this.showLoad()
    // await this.axiosGetTrials()
    // this.hideLoad()

    if(!this.financialStatement.length)
      await this.axiosGetsetFinancialStatementlist()
  },
}
</script>

<style>

</style>