<template>
  <div class="calc_routes_table bg-gray-dark fz13 rounded-t-lg">
    <div class="border-r border-gray-text"></div>
    <div class="border-r border-gray-text"></div>
    <div class="border-r border-gray-text fw500 text-gray-text p-x-1 p-y-1">Port / Canal</div>
    <div class="border-r border-gray-text fw500 text-gray-text p-x-1 p-y-1">Status</div>
    <div class="border-r border-gray-text fw500 text-gray-text p-x-1 p-y-1">Dist</div>
    <div class="border-r border-gray-text fw500 text-gray-text p-x-1 p-y-1">SECA</div>
    <div class=""></div>
  </div>
</template>

<script>
export default {

}
</script>