<template>
  <div class="trial-balance_list">
    <div class="trial-balance_list__block" v-if="getBanks.length">
      <div class="bank-archive-line bank fw500 fz12">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.14453 5.14955V5.56141C1.14453 6.22607 1.68523 6.76677 2.34989 6.76677H2.75167V10.9122C2.28501 11.0786 1.9481 11.5204 1.9481 12.0435V12.4453H14.0552V12.0435C14.0552 11.5204 13.7184 11.0786 13.2517 10.9122V6.76677H13.6535C14.3181 6.76677 14.8588 6.22607 14.8588 5.56141V5.14955H1.14453ZM6.76953 10.8382H5.00195V6.76677H6.76953V10.8382ZM11.002 10.8382H9.23382V6.76677H11.002V10.8382Z" fill="#319FEF"/><path d="M8.20602 1.18411C8.09301 1.12762 7.90605 1.12762 7.79304 1.18411L1.44922 4.35602H14.5498L8.20602 1.18411Z" fill="#319FEF"/><path d="M1.14453 13.1607V14.4553C1.14453 14.6772 1.32442 14.8571 1.54632 14.8571H14.457C14.6789 14.8571 14.8588 14.6772 14.8588 14.4553V13.1607H1.14453Z" fill="#319FEF"/></svg>
        Banks
      </div>
      <div class="trial-balance_list__row head">
        <div class="trial-balance_list__col check">
          <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" @change="checkAll"></b-form-checkbox>
        </div>
        <div class="trial-balance_list__col name">Bank Name</div>
        <div class="trial-balance_list__col currency">Currency</div>
        <div class="trial-balance_list__col balance">Balance</div>
        <div class="trial-balance_list__col total">Total in USD</div>
      </div>

      <div class="trial-balance_list__data">
        <div class="trial-balance_list__row" v-for="bank in getBanks" :key="bank.id">
          <div class="trial-balance_list__col check">
            <b-form-checkbox v-model="checkedList" :value="bank.id"></b-form-checkbox>
          </div>
          <div class="trial-balance_list__col name">{{bank.name}}</div>
          <div class="trial-balance_list__col currency">
            <div v-if="bank.requisites.length == 0">USD</div>
            <div v-for="cash in uniqBy(bank.requisites, it=>it.currency)" :key="cash.id">{{cash.currency}}</div>
          </div>
        <div class="trial-balance_list__col balance"><bank-archive-get-balance :list="bank.requisites"/></div>
        <div class="trial-balance_list__col total"><bank-archive-get-total v-if="bank.requisites.length" :list="bank.requisites"/></div>
          <div class="trial-balance_list__col menu">
            <svg class="menu-unarchive" v-if="getPermissionsByType('unarchive_bank_and_cash_accounts')" @click="unArchive(bank)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/><path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#9AA3B0"/></svg>
          </div>
        </div>
      </div>
    </div>

    <div class="trial-balance_list__block" v-if="getCashes.length">
      <div class="bank-archive-line cash fw500 fz12">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M4.45133 5.10863L10.2169 8.52937V8.55696L6.0411 11.0224C5.95834 11.0776 5.848 11.0776 5.76524 11.0224L0.385858 7.93271C0.220339 7.82237 0.220339 7.60168 0.385858 7.49133L4.45133 5.10863Z" fill="#0FA54E"/><path d="M15.7315 5.08471L12.5039 6.97952L6.73828 3.55878L10.2055 1.58989C10.2883 1.53472 10.3986 1.53472 10.4814 1.58989L15.7315 4.64333C15.897 4.75368 15.897 5.00195 15.7315 5.08471Z" fill="#0FA54E"/><path d="M5.42549 11.6041C5.73795 11.7836 6.1228 11.781 6.43288 11.5975L10.1897 9.37387V10.46L6.10399 12.8627C5.98006 12.9356 5.82655 12.9364 5.70189 12.8647L0.580812 9.92131C0.318353 9.77045 0.312097 9.39402 0.569398 9.23453L0.920605 9.01683L5.42549 11.6041Z" fill="#0FA54E"/><path d="M15.5557 6.49637C15.7713 6.67314 15.7445 7.01077 15.5036 7.15124L12.625 8.83016V7.74404L15.2268 6.22678L15.5557 6.49637Z" fill="#0FA54E"/><path d="M5.72316 13.6724C5.85109 13.7472 6.00991 13.7452 6.13588 13.6671L10.1897 11.1544V12.3595L6.10572 14.8484C5.98103 14.9244 5.82483 14.9264 5.69823 14.8536L0.565016 11.9032C0.306278 11.7545 0.295623 11.3851 0.54536 11.2217L1.01203 10.9164L5.72316 13.6724Z" fill="#0FA54E"/><path d="M15.4838 8.48543C15.7246 8.65203 15.7107 9.01238 15.4577 9.15989L12.625 10.812V9.72592L15.044 8.18108L15.4838 8.48543Z" fill="#0FA54E"/><path d="M12.0265 7.43741V11.2444L10.7851 12.0168V8.12708L5.01953 4.70634L6.23334 3.96151L11.9989 7.40983L12.0265 7.43741Z" fill="#0FA54E"/></g><defs><clipPath id="clip0"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
        Cash
      </div>
      <div class="trial-balance_list__row head">
        <div class="trial-balance_list__col check">
          <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" @change="checkAll"></b-form-checkbox>
        </div>
        <div class="trial-balance_list__col name">Bank Name</div>
        <div class="trial-balance_list__col currency">Currency</div>
        <div class="trial-balance_list__col balance">Balance</div>
        <div class="trial-balance_list__col total">Total in USD</div>
      </div>

      <div class="trial-balance_list__data">
        <div class="trial-balance_list__row" v-for="cash in getCashes" :key="cash.id">
          <div class="trial-balance_list__col check">
            <b-form-checkbox v-model="checkedList" :value="cash.id"></b-form-checkbox>
          </div>
          <div class="trial-balance_list__col name">{{cash.name}}</div>
          <div class="trial-balance_list__col currency">{{cash.currency}}</div>
        <div class="trial-balance_list__col balance">{{cash.balance | formatNumber}}</div>
        <div class="trial-balance_list__col total"><convert v-if="cash.currency != 'USD'" :currency="cash.currency" :value="cash.balance" /></div>
          <div class="trial-balance_list__col menu">
            <svg class="menu-unarchive" v-if="getPermissionsByType('unarchive_bank_and_cash_accounts')" @click="unArchive(cash)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/><path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#9AA3B0"/></svg>
          </div>
        </div>
      </div>
    </div>

    <div class="counterparties-list-empty" v-else-if="!getCashes.length && !getBanks.length">
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.5905 121.467C34.3947 121.36 34.1483 121.218 33.9803 121.068C32.3841 119.647 30.2728 119.641 28.6707 121.068C27.6139 122.009 26.2481 122.009 25.1914 121.068C24.4072 120.37 23.4893 120.001 22.5366 120.001C21.5839 120.001 20.6659 120.37 19.8818 121.068C18.8249 122.009 17.4592 122.009 16.4024 121.068C14.8062 119.647 12.695 119.641 11.0929 121.068C10.9403 121.204 10.7216 121.344 10.4243 121.498C10.0574 121.687 9.89796 122.172 10.0682 122.581C10.2385 122.989 10.6739 123.167 11.0409 122.977C11.4518 122.765 11.7591 122.563 12.008 122.341C13.0648 121.4 14.4305 121.4 15.4874 122.341C17.0836 123.762 19.1948 123.768 20.7969 122.341C21.8537 121.4 23.2194 121.4 24.2763 122.341C25.8726 123.762 27.9838 123.768 29.5859 122.341C30.6427 121.4 32.0084 121.4 33.0653 122.341C33.3426 122.588 33.6652 122.777 33.9443 122.93C34.3073 123.129 34.7463 122.962 34.9247 122.558C35.1031 122.154 34.9536 121.666 34.5905 121.467Z" fill="#DEE3EA"/>
        <path d="M52.4095 72.467C52.6053 72.3598 52.8517 72.2176 53.0197 72.068C54.6159 70.6466 56.7272 70.6414 58.3293 72.068C59.3861 73.009 60.7519 73.009 61.8086 72.068C62.5928 71.3698 63.5107 71.0007 64.4634 71.0007C65.4161 71.0007 66.3341 71.3698 67.1182 72.068C68.1751 73.009 69.5408 73.009 70.5976 72.068C72.1938 70.6467 74.305 70.6414 75.9071 72.068C76.0597 72.2038 76.2784 72.3444 76.5757 72.498C76.9426 72.6875 77.102 73.1722 76.9318 73.5806C76.7615 73.989 76.3261 74.1665 75.9591 73.977C75.5482 73.7648 75.2409 73.5628 74.992 73.3411C73.9352 72.4001 72.5695 72.4 71.5126 73.3411C69.9164 74.7625 67.8052 74.7677 66.2031 73.3411C65.1463 72.4001 63.7806 72.4 62.7237 73.3411C61.1274 74.7625 59.0162 74.7677 57.4141 73.3411C56.3573 72.4001 54.9916 72.4 53.9347 73.3411C53.6574 73.588 53.3348 73.7774 53.0557 73.9302C52.6927 74.1289 52.2537 73.9623 52.0753 73.5583C51.8969 73.1542 52.0464 72.6657 52.4095 72.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 31.2C32.6627 31.2 33.2 30.6627 33.2 30C33.2 29.3373 32.6627 28.8 32 28.8C31.3373 28.8 30.8 29.3373 30.8 30C30.8 30.6627 31.3373 31.2 32 31.2ZM32 33C33.6569 33 35 31.6569 35 30C35 28.3431 33.6569 27 32 27C30.3431 27 29 28.3431 29 30C29 31.6569 30.3431 33 32 33Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M299 120.8C298.337 120.8 297.8 121.337 297.8 122C297.8 122.663 298.337 123.2 299 123.2C299.663 123.2 300.2 122.663 300.2 122C300.2 121.337 299.663 120.8 299 120.8ZM299 119C297.343 119 296 120.343 296 122C296 123.657 297.343 125 299 125C300.657 125 302 123.657 302 122C302 120.343 300.657 119 299 119Z" fill="#DEE3EA"/>
        <path d="M281.409 27.467C281.605 27.3598 281.852 27.2176 282.02 27.068C283.616 25.6466 285.727 25.6414 287.329 27.068C288.386 28.009 289.752 28.009 290.809 27.068C291.593 26.3698 292.511 26.0007 293.463 26.0007C294.416 26.0007 295.334 26.3698 296.118 27.068C297.175 28.009 298.541 28.009 299.598 27.068C301.194 25.6467 303.305 25.6414 304.907 27.068C305.06 27.2038 305.278 27.3444 305.576 27.498C305.943 27.6875 306.102 28.1722 305.932 28.5806C305.762 28.989 305.326 29.1665 304.959 28.977C304.548 28.7648 304.241 28.5628 303.992 28.3411C302.935 27.4001 301.569 27.4 300.513 28.3411C298.916 29.7625 296.805 29.7677 295.203 28.3411C294.146 27.4001 292.781 27.4 291.724 28.3411C290.127 29.7625 288.016 29.7677 286.414 28.3411C285.357 27.4001 283.992 27.4 282.935 28.3411C282.657 28.588 282.335 28.7774 282.056 28.9302C281.693 29.1289 281.254 28.9623 281.075 28.5583C280.897 28.1542 281.046 27.6657 281.409 27.467Z" fill="#DEE3EA"/>
        <path d="M237.409 69.467C237.605 69.3598 237.852 69.2176 238.02 69.068C239.616 67.6466 241.727 67.6414 243.329 69.068C244.386 70.009 245.752 70.009 246.809 69.068C247.593 68.3698 248.511 68.0007 249.463 68.0007C250.416 68.0007 251.334 68.3698 252.118 69.068C253.175 70.009 254.541 70.009 255.598 69.068C257.194 67.6467 259.305 67.6414 260.907 69.068C261.06 69.2038 261.278 69.3444 261.576 69.498C261.943 69.6875 262.102 70.1722 261.932 70.5806C261.762 70.989 261.326 71.1665 260.959 70.977C260.548 70.7648 260.241 70.5628 259.992 70.3411C258.935 69.4001 257.569 69.4 256.513 70.3411C254.916 71.7625 252.805 71.7677 251.203 70.3411C250.146 69.4001 248.781 69.4 247.724 70.3411C246.127 71.7625 244.016 71.7677 242.414 70.3411C241.357 69.4001 239.992 69.4 238.935 70.3411C238.657 70.588 238.335 70.7774 238.056 70.9302C237.693 71.1289 237.254 70.9623 237.075 70.5583C236.897 70.1542 237.046 69.6657 237.409 69.467Z" fill="#DEE3EA"/>
        <path d="M103 93.5V136.5H217V93.5M103 93.5H122.5M103 93.5L118 32.5H136.5M217 93.5H197M217 93.5L202 32.5H183M122.5 93.5H139.5L145 106.5H175.5L181 93.5H197M122.5 93.5V70.5H129.5M197 93.5V70.5H190M129.5 70.5V47H136.5M129.5 70.5H190M190 70.5V47H183M136.5 47H183M136.5 47V32.5M183 47V32.5M136.5 32.5V23H183V32.5M145 121.5H175.5" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p class="fz18 fw500">Archive is empty or there are no search results</p>
      <p class="fz14">The accounts you have archived <br> will be displayed here.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import convert from '@/components/accounts/bankAndCash/bankAsideConvert'
import bankArchiveGetTotal from '@/components/accounts/bankAndCash/archive/bankArchiveGetTotal'
import bankArchiveGetBalance from '@/components/accounts/bankAndCash/archive/bankArchiveGetBalance'

export default {
  props: ['list'],
  components: {convert, bankArchiveGetTotal, bankArchiveGetBalance},
  data() {
    return {
      indeterminate: false,
      allSelected: false,
      checkedList: [],
    }
  },
  watch: {
    checkedList(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.list.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }

      this.$emit('select', this.checkedList)
    }
  },
  computed: {
    ...mapGetters(['getPermissionsByType']),
    getBanks() {
      return this.list.filter(el => {
        return el.type == 'bank'
      })
    },
    getCashes() {
      return this.list.filter(el => {
        return el.type == 'cash'
      })
    }
  },
  methods: {
    checkAll(checked) {
      this.checkedList = checked ? this.list.map((acc) => {return acc.id}) : []
    },
    uncheckAll() {
      this.checkedList = []
    },
    unArchive(account) {
      this.$emit('on-unarchive', account)
    },
    uniqBy(a, key) {
      return [
        ...new Map(
          a.map(x => [key(x), x])
        ).values()
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/checkbox";
</style>

<style lang="scss" scoped>
  .trial-balance_list{
    &__col{
      &.name{
        width: 21.8%;
        word-break: break-all;
      }
      &.currency{
        width: 10%;
        text-align: center;
      }
      &.balance{
        width: 14.7%;
        text-align: right;
      }
      &.total{
        width: 19.1%;
        text-align: right;
        color: #9AA3B0;
      }
      &.menu{
        margin-left: auto;
        padding-right: 16px;
      }
    }
  }
  .bank-archive-line{
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    text-transform: uppercase;
    color: #319FEF;
    border-bottom: 1px solid var(--color-card-border);
    svg{
      margin-right: 12px;
      transform: translateY(-1px);
    }
    &.cash{
      color: #0FA54E;
    }
  }
</style>