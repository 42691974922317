<template>
  <div class="counterparties-edit-types">
    <button class="counterparties-edit-types_btn" @click="toggleEditForm">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9815 1.02666C12.3235 0.365661 11.4509 0 10.5175 0C9.58888 0 8.71156 0.365661 8.05357 1.02666L0.846338 8.276C0.743673 8.37914 0.683007 8.5104 0.659674 8.65104L0.00634799 13.2499C-0.0216517 13.4515 0.043681 13.6578 0.188346 13.8031C0.309678 13.9297 0.482343 14 0.655007 14C0.683007 14 0.70634 14 0.73434 13.9953L5.38695 13.414C5.53162 13.3953 5.66695 13.3296 5.76962 13.2265L12.9815 5.97246C14.3395 4.60357 14.3395 2.39086 12.9815 1.02666ZM5.00429 12.1342L1.42033 12.5795L1.92433 9.04952L7.80157 3.13624L10.8769 6.22561L5.00429 12.1342ZM12.0622 5.04893L11.8055 5.30677L8.73023 2.2174L8.98689 1.95957C9.39289 1.53765 9.93888 1.31263 10.5222 1.31263C11.1009 1.31263 11.6515 1.53765 12.0622 1.95488C12.4728 2.37211 12.7015 2.91591 12.7015 3.5019C12.7015 4.08321 12.4775 4.63639 12.0622 5.04893Z" fill="#094172"/></svg>
      Edit counterparty types
    </button>

    <div class="counterparties-edit-types_form" v-if="isEditOpen">
      <div class="counterparties-edit-types_form__bg" @click="isEditOpen = false"></div>

      <div class="counterparties-edit-types_form__content">
        <h3 class="counterparties-edit-types_form__title">
          <button class="counterparties-edit-types_form__close" @click="isEditOpen = false">close</button>
          Edit counterparty types
        </h3>

        <button class="counterparties-edit-types_form__add button-blue-border fw500 fz13" @click="openModalAdd('type')">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>
          Create New Type
        </button>

        <counterparties-edit-types-block 
          v-for="type in clientsType" 
          :key="type.id" 
          :type="type" 
          :subtypes="subtypeDepend(type.id)" 
          @addSubtype="openModalAdd('subtype', type)"
          @editType="openModalEdit"
          @delType="openModalDel"
        ></counterparties-edit-types-block>
      </div>

      <modal-add :type="addType" :data="dataType" v-if="isModalAddOpen" @close="closeModalAdd" @update="updateData"></modal-add>
      <modal-edit :type="addType" :data="dataType" v-if="isModalEditOpen" @close="closeModalEdit" @update="updateData"></modal-edit>
      <modal-del :type="addType" :data="dataType" v-if="isModalDelOpen" @close="closeModalDel" @update="updateData"></modal-del>
      <modal-info :type="addType" :data="dataType" :msg="infoStr" v-if="isModalInfoOpen" @close="closeModalInfo"></modal-info>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import modalAdd from '@/components/counterparties/counterpartiesAdd/counterpartiesModalAdd'
import modalEdit from '@/components/counterparties/counterpartiesAdd/counterpartiesModalEdit'
import modalDel from '@/components/counterparties/counterpartiesAdd/counterpartiesModalDel'
import modalInfo from '@/components/counterparties/counterpartiesAdd/counterpartiesModalInfo'
import counterpartiesEditTypesBlock from '@/components/counterparties/counterpartiesAdd/counterpartiesEditTypesBlock'

export default {
  components: {
    modalAdd,
    modalEdit,
    modalDel,
    modalInfo,
    counterpartiesEditTypesBlock
  },
  data() {
    return {
      isEditOpen: false,
      isModalAddOpen: false,
      isModalEditOpen: false,
      isModalDelOpen: false,
      isModalInfoOpen: false,
      addType: 'type',
      dataType: {},
      infoStr: ''
    }
  },
  computed: {
    ...mapState({
      clientsType: state => state.counterpartiesType.clientsType,
      clientsSubtype: state => state.counterpartiesType.clientsSubtype,
    })
  },
  methods: {
    ...mapActions(['axiosGetClientsType', 'axiosGetClientsSubtype']),
    ...mapMutations(['showLoad', 'hideLoad']),
    updateData() {
      this.showLoad()
      this.axiosGetClientsType().then(() => {
        this.axiosGetClientsSubtype().then(() => {
          this.hideLoad()
        })
      })
    },
    toggleEditForm() {
      this.isEditOpen = !this.isEditOpen
    },
    closeModalAdd() {
      this.isModalAddOpen = false
    },
    openModalAdd(type, data) {
      this.addType = type
      if(data){
        this.dataType = data
      }
      this.isModalAddOpen = true
    },
    closeModalEdit() {
      this.isModalEditOpen = false
    },
    openModalEdit(data) {
      this.dataType = data
      if(data.parent_id){
        this.addType = 'subtype'
      } else{
        this.addType = 'type'
      }
      this.isModalEditOpen = true
    },
    closeModalDel() {
      this.isModalDelOpen = false
    },
    openModalDel(data) {
      this.infoStr = ''
      if(data.name.toLowerCase() == 'broker'){
        this.infoStr = 'The subtype <b>Broker</b> can not be deleted. It is used in the detailed information about Counterparty.'
        this.isModalInfoOpen = true
        return false
      }

      this.dataType = data
      if(data.parent_id){
        this.addType = 'subtype'
      } else{
        this.addType = 'type'
        if(this.subtypeDepend(data.id).length > 0){
          this.isModalInfoOpen = true
          return false
        }
      }
      this.isModalDelOpen = true
    },
    closeModalInfo() {
      this.isModalInfoOpen = false
    },
    /* openModalInfo(data) {
      console.log(data);
    }, */
    subtypeDepend(id) {
      let subtypes = this.clientsSubtype.filter(elem => {
        return id == elem.parent_id
      })
      return subtypes
    }
  },
  /* async mounted() {
    this.showLoad()
    await this.axiosGetBrokers()
    await this.axiosGetClientsType()
    await this.axiosGetClientsSubtype()
    this.hideLoad()
  } */
}
</script>

<style lang="scss">
  .counterparties-edit-types{
    text-align: right;
    &_btn{
      margin-top: 17px;
      font-size: 13px;
      font-weight: 500;
      color: var(--color-blue-dark);
      svg{
        margin-right: 7px;
        transform: translateY(2px);
      }
    }
    &_form{
      box-sizing: border-box;
      position: fixed;
      top: 0;
      right: 0;
      width: 360px;
      height: 100%;
      z-index: 999;
      background-color: var(--color-bgc-page-white);
      text-align: left;
      &__bg{
        z-index: -1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-filter-modal);
        opacity: 0.15;
      }
      .dart-mode {
        .counterparties-edit-types_form__bg {
          opacity: 0.75;
        }
      }
      &__content{
        box-sizing: border-box;
        position: relative;
        height: 100vh;
        overflow: auto;
        padding: 18px 24px;
        background-color: var(--color-bgc-page-white);
      }
      &__title{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 500;
      }
      &__close{
        width: 15px;
        height: 15px;
        margin-right: 21px;
        margin-top: -2px;
        font-size: 0;
        position: relative;
        &:after,
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 1px;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: var(--color-dark);
        }
        &:after{
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
      &__add{
        width: 100%;
      }
    }
  }
  .dart-mode {
    .counterparties-edit-types_btn {
      svg {
        path {
          fill: var(--color-blue-dark)
        }
      }
    }
  }
</style>