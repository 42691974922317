<template>
  <div class="w-full">
    <lay-active-header @downloadPDF="downloadPDF" @downloadXLSX="downloadXLSX" @on-create="$emit('on-create')" @on-edit="$emit('on-edit')" @on-archive="$emit('on-archive')"/>


    <div class="p-x-3 p-b-6 p-t-2 bg-white rounded-lg shadow">
      <div class="flex items-center p-b-4">
        <svg class="slm-logo" width="37" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2669_6165)"><path d="M20.979 20.56c8.474 1.232 15.01 8.69 15.01 17.668a18.142 18.142 0 01-.952 5.822l.957.336c.89-2.64 1.196-5.447.895-8.222-.3-2.774-1.2-5.446-2.633-7.824a18.722 18.722 0 00-5.656-5.925 18.286 18.286 0 00-7.595-2.892l-.026 1.037zM16.331 20.517c-8.621 1.093-15.32 8.62-15.32 17.71a18.159 18.159 0 00.928 5.744l-.958.332a19.257 19.257 0 01-.855-8.27 19.145 19.145 0 012.708-7.844 18.71 18.71 0 015.746-5.897 18.275 18.275 0 017.67-2.808l.081 1.033z" fill="#113051"/><path d="M16.983 10.867V33.85s-5.469.374-9.888 4.245c0 0 7.313-11.012 9.888-27.227z" fill="#113051"/><path d="M19.624 0v33.782s5.508.114 10.285 3.957C24.503 26.682 21.038 14.131 19.624 0z" fill="#17A1E4"/><path d="M7.724 47.92v-1.038h4.364c.544 0 .849-.31.849-.775 0-.506-.305-.768-.85-.768H9.414c-1.096 0-1.778-.71-1.778-1.691 0-.957.633-1.668 1.794-1.668h4.182v1.038H9.43c-.465 0-.737.278-.737.728 0 .45.283.716.73.716h2.666c1.18 0 1.81.564 1.81 1.721 0 .997-.59 1.74-1.81 1.74l-4.364-.004zM15.171 47.918v-5.941h1.017v4.903h3.914v1.038h-4.93zM28.481 47.92l-1.113-4.177-1.65 3.866c-.112.254-.272.392-.52.392s-.422-.138-.521-.392l-1.642-3.866-1.113 4.176h-1.001l1.498-5.592c.071-.285.231-.425.505-.425a.54.54 0 01.31.096.557.557 0 01.203.256l1.795 4.201 1.794-4.2a.528.528 0 01.189-.256.51.51 0 01.299-.097c.248 0 .409.14.489.425l1.49 5.592H28.48z" fill="#113051"/></g><defs><clipPath id="clip0_2669_6165"><path fill="#fff" d="M0 0h37v48H0z"/></clipPath></defs></svg>
        <h2 class="m-l-4 p-t-4 fz18 fw500">Speed Logistics Marine LTD</h2>
        <p class="ml-auto fz20 text-dark fw500">LAYTIME CALCULATION</p>
      </div>

      <lay-active-info  :lay="lay" :timeAllowed="getTimeAllowed" @changeCharterer="charterer = $event"/>

      <div class="flex justify-end p-t-4 p-b-3">
        <router-link :to="getDetailLink" class="blue-button p-x-6 fz13 fw500 h32">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#fff"/></svg>
          Edit table
        </router-link>
      </div>
      
      <lay-active-dates class="m-b-3" :lay="lay"/>
      <lay-active-table :lay="lay" :timeAllowed="getTimeAllowed"/>
      <div class="w100Proc flex justify-center align-items-center block-bottom-logo">
        <svg class="slm-logo" width="37" height="48" viewBox="0 0 37 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_5018_11808)">
            <path d="M20.9785 20.5601C29.4523 21.7921 35.9889 29.2492 35.9889 38.2282C35.9911 40.2093 35.6694 42.1769 35.0372 44.0499L35.994 44.3863C36.8837 41.7468 37.1893 38.9388 36.8888 36.1644C36.5882 33.3899 35.6889 30.7179 34.2556 28.3405C32.8223 25.9631 30.8906 23.9394 28.5994 22.4148C26.3082 20.8903 23.7145 19.9028 21.0046 19.5234L20.9785 20.5601Z" fill="#113051"/>
            <path d="M16.3314 20.5167C7.71003 21.6102 1.01158 29.1362 1.01158 38.227C1.01015 40.1807 1.32342 42.1214 1.93887 43.9713L0.981228 44.3034C0.0977614 41.6456 -0.194349 38.8204 0.126124 36.033C0.446598 33.2455 1.3716 30.566 2.83391 28.1891C4.29622 25.8123 6.25909 23.7977 8.57986 22.292C10.9006 20.7863 13.521 19.8272 16.2504 19.4844L16.3314 20.5167Z" fill="#113051"/>
            <path d="M16.9821 10.8672V33.8494C16.9821 33.8494 11.5136 34.2228 7.09375 38.0942C7.09375 38.0942 14.4076 27.0823 16.9821 10.8672Z" fill="#113051"/>
            <path d="M19.625 0V33.7817C19.625 33.7817 25.1331 33.8961 29.9096 37.7391C24.5043 26.6816 21.0387 14.1314 19.625 0Z" fill="#17A1E4"/>
            <path d="M7.72328 47.9192V46.8816H12.0875C12.6321 46.8816 12.9364 46.5711 12.9364 46.1074C12.9364 45.6006 12.6321 45.3391 12.0875 45.3391H9.41265C8.31675 45.3391 7.63477 44.6285 7.63477 43.6477C7.63477 42.6911 8.26786 41.9805 9.42867 41.9805H13.6108V43.018H9.42867C8.96418 43.018 8.69189 43.2959 8.69189 43.7458C8.69189 44.1958 8.97429 44.4625 9.42108 44.4625H12.0875C13.2677 44.4625 13.8974 45.026 13.8974 46.1831C13.8974 47.1802 13.3073 47.9235 12.0875 47.9235L7.72328 47.9192Z" fill="#113051"/>
            <path d="M15.1719 47.9179V41.9766H16.1885V46.8803H20.1026V47.9179H15.1719Z" fill="#113051"/>
            <path d="M28.4808 47.9194L27.3672 43.7434L25.7174 47.6088C25.6053 47.8626 25.4451 48.0011 25.1973 48.0011C24.9494 48.0011 24.7758 47.8626 24.6763 47.6088L23.0342 43.7434L21.9214 47.9194H20.9199L22.4179 42.3273C22.4896 42.0417 22.6498 41.9024 22.9237 41.9024C23.0339 41.9016 23.1418 41.9348 23.2332 41.9976C23.3246 42.0603 23.3954 42.1498 23.4363 42.2542L25.231 46.4551L27.0249 42.2542C27.0608 42.1513 27.1269 42.0623 27.2143 41.9993C27.3017 41.9363 27.406 41.9024 27.513 41.9024C27.7617 41.9024 27.9219 42.0417 28.0019 42.3273L29.4915 47.9194H28.4808Z" fill="#113051"/>
          </g>
          <defs>
            <clipPath id="clip0_5018_11808">
              <rect width="37" height="48" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <p class="fz18 fw500 text-dark">Speed Logistics Marine LTD</p>
      </div>
    </div>

<!--    <div class="pdf-lay-time">-->
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1745"
          filename="LayTime"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"
          ref="html2Pdf"
          @hasDownloaded="hasDownloaded"
      >
        <div slot="pdf-content" class="wrap-pdf-content">
          <div class="flex items-center pdf-not-see-children">
            <svg width="37" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2669_6165)"><path d="M20.979 20.56c8.474 1.232 15.01 8.69 15.01 17.668a18.142 18.142 0 01-.952 5.822l.957.336c.89-2.64 1.196-5.447.895-8.222-.3-2.774-1.2-5.446-2.633-7.824a18.722 18.722 0 00-5.656-5.925 18.286 18.286 0 00-7.595-2.892l-.026 1.037zM16.331 20.517c-8.621 1.093-15.32 8.62-15.32 17.71a18.159 18.159 0 00.928 5.744l-.958.332a19.257 19.257 0 01-.855-8.27 19.145 19.145 0 012.708-7.844 18.71 18.71 0 015.746-5.897 18.275 18.275 0 017.67-2.808l.081 1.033z" fill="#113051"/><path d="M16.983 10.867V33.85s-5.469.374-9.888 4.245c0 0 7.313-11.012 9.888-27.227z" fill="#113051"/><path d="M19.624 0v33.782s5.508.114 10.285 3.957C24.503 26.682 21.038 14.131 19.624 0z" fill="#17A1E4"/><path d="M7.724 47.92v-1.038h4.364c.544 0 .849-.31.849-.775 0-.506-.305-.768-.85-.768H9.414c-1.096 0-1.778-.71-1.778-1.691 0-.957.633-1.668 1.794-1.668h4.182v1.038H9.43c-.465 0-.737.278-.737.728 0 .45.283.716.73.716h2.666c1.18 0 1.81.564 1.81 1.721 0 .997-.59 1.74-1.81 1.74l-4.364-.004zM15.171 47.918v-5.941h1.017v4.903h3.914v1.038h-4.93zM28.481 47.92l-1.113-4.177-1.65 3.866c-.112.254-.272.392-.52.392s-.422-.138-.521-.392l-1.642-3.866-1.113 4.176h-1.001l1.498-5.592c.071-.285.231-.425.505-.425a.54.54 0 01.31.096.557.557 0 01.203.256l1.795 4.201 1.794-4.2a.528.528 0 01.189-.256.51.51 0 01.299-.097c.248 0 .409.14.489.425l1.49 5.592H28.48z" fill="#113051"/></g><defs><clipPath id="clip0_2669_6165"><path fill="#fff" d="M0 0h37v48H0z"/></clipPath></defs></svg>
            <h2 class="m-l-4 p-t-4 fz18 fw500">Speed Logistics Marine LTD</h2>
            <p class="ml-auto fz20 text-dark fw500">LAYTIME CALCULATION</p>
          </div>
          <div class="p-t-4 pdf-not-see-children"></div>
          <lay-active-info :pdfHideBlock="true" :lay="lay" :timeAllowed="getTimeAllowed" @changeCharterer="charterer = $event"/>
          <div class="p-t-3 pdf-not-see-children"></div>
          <lay-active-dates :pdfHideBlock="true" class="block-ports-pdf" :lay="lay"/>
          <div class="p-t-3 pdf-not-see-children"></div>
          <lay-active-table ref="layActiveTablePdf" class="" :pdfHideBlock="true" :lay="lay" :timeAllowed="getTimeAllowed"/>
          <div class="w100Proc flex justify-center align-items-center block-bottom-logo pdf-not-see-children">
            <svg width="37" height="48" viewBox="0 0 37 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5018_11808)">
                <path d="M20.9785 20.5601C29.4523 21.7921 35.9889 29.2492 35.9889 38.2282C35.9911 40.2093 35.6694 42.1769 35.0372 44.0499L35.994 44.3863C36.8837 41.7468 37.1893 38.9388 36.8888 36.1644C36.5882 33.3899 35.6889 30.7179 34.2556 28.3405C32.8223 25.9631 30.8906 23.9394 28.5994 22.4148C26.3082 20.8903 23.7145 19.9028 21.0046 19.5234L20.9785 20.5601Z" fill="#113051"/>
                <path d="M16.3314 20.5167C7.71003 21.6102 1.01158 29.1362 1.01158 38.227C1.01015 40.1807 1.32342 42.1214 1.93887 43.9713L0.981228 44.3034C0.0977614 41.6456 -0.194349 38.8204 0.126124 36.033C0.446598 33.2455 1.3716 30.566 2.83391 28.1891C4.29622 25.8123 6.25909 23.7977 8.57986 22.292C10.9006 20.7863 13.521 19.8272 16.2504 19.4844L16.3314 20.5167Z" fill="#113051"/>
                <path d="M16.9821 10.8672V33.8494C16.9821 33.8494 11.5136 34.2228 7.09375 38.0942C7.09375 38.0942 14.4076 27.0823 16.9821 10.8672Z" fill="#113051"/>
                <path d="M19.625 0V33.7817C19.625 33.7817 25.1331 33.8961 29.9096 37.7391C24.5043 26.6816 21.0387 14.1314 19.625 0Z" fill="#17A1E4"/>
                <path d="M7.72328 47.9192V46.8816H12.0875C12.6321 46.8816 12.9364 46.5711 12.9364 46.1074C12.9364 45.6006 12.6321 45.3391 12.0875 45.3391H9.41265C8.31675 45.3391 7.63477 44.6285 7.63477 43.6477C7.63477 42.6911 8.26786 41.9805 9.42867 41.9805H13.6108V43.018H9.42867C8.96418 43.018 8.69189 43.2959 8.69189 43.7458C8.69189 44.1958 8.97429 44.4625 9.42108 44.4625H12.0875C13.2677 44.4625 13.8974 45.026 13.8974 46.1831C13.8974 47.1802 13.3073 47.9235 12.0875 47.9235L7.72328 47.9192Z" fill="#113051"/>
                <path d="M15.1719 47.9179V41.9766H16.1885V46.8803H20.1026V47.9179H15.1719Z" fill="#113051"/>
                <path d="M28.4808 47.9194L27.3672 43.7434L25.7174 47.6088C25.6053 47.8626 25.4451 48.0011 25.1973 48.0011C24.9494 48.0011 24.7758 47.8626 24.6763 47.6088L23.0342 43.7434L21.9214 47.9194H20.9199L22.4179 42.3273C22.4896 42.0417 22.6498 41.9024 22.9237 41.9024C23.0339 41.9016 23.1418 41.9348 23.2332 41.9976C23.3246 42.0603 23.3954 42.1498 23.4363 42.2542L25.231 46.4551L27.0249 42.2542C27.0608 42.1513 27.1269 42.0623 27.2143 41.9993C27.3017 41.9363 27.406 41.9024 27.513 41.9024C27.7617 41.9024 27.9219 42.0417 28.0019 42.3273L29.4915 47.9194H28.4808Z" fill="#113051"/>
              </g>
              <defs>
                <clipPath id="clip0_5018_11808">
                  <rect width="37" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <p class="fz18 fw500 text-dark">Speed Logistics Marine LTD</p>
          </div>
        </div>
      </vue-html2pdf>

<!--    </div>-->

  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import moment from "moment"

import layActiveHeader from '@/components/lay/layActiveHeader'
import layActiveInfo from '@/components/lay/layActiveInfo'
import layActiveDates from '@/components/lay/layActiveDates'
import layActiveTable from '@/components/lay/layActiveTable'

export default {
  components: {
    layActiveHeader,
    layActiveInfo,
    layActiveDates,
    layActiveTable,
  },
  computed: {
    ...mapState({
      lay: state => state.lay.activeLay,
      activeContract: state => state.contract.activeContract,
      remarksList: state => state.lay.remarksList,
    }),
    ...mapGetters(['getTimeAllowed', 'getDartMode']),
    getDetailLink() {
      if(this.$route.meta.isCharter)
        return `/vessels/${this.$route.params.id}/open/charterers/${this.$route.params.subId}/lay/${this.lay.id}/edit`
      else
        return `/vessels/${this.$route.params.id}/lay/${this.lay.id}/edit`
    }
  },
  watch: {
    'lay.demurrage': function () {
      this.save()
    }
  },
  data() {
    return {
      charterer: null,
    }
  },
  methods: {
    ...mapActions(["axiosGetXLSX", 'axiosPutLay']),
    ...mapMutations(['showLoad', 'hideLoad', 'setDartMode', 'setTurnOnDarkModeAfterDownloadPDF']),
    async save() {
      let saveObj = JSON.parse(JSON.stringify(this.lay))
      saveObj.contract = saveObj.contract.id
      saveObj.owner = saveObj.owner ? saveObj.owner.id : null;
      saveObj.lay_ports.forEach(el => {
        if(el.discharging_port){
          el.discharging_port = el.discharging_port.id
        }
        if(el.loading_port){
          el.loading_port = el.loading_port.id
        }
      })

      saveObj.lay_times = []
      this.rows.forEach(el => {
        let obj = {...el}
        obj.date = obj.date ? moment(obj.date, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm') : null
        saveObj.lay_times.push(obj)
      })
      console.log(saveObj)
      await this.axiosPutLay(saveObj)
    },
    hasDownloaded() {
      this.hideLoad();
    },
    downloadPDF() {
      this.showLoad()
      // let DOM = this.$refs.layActiveTablePdf.$el.children
      let DOM = this.$refs.html2Pdf.$el.children[0].children[0].children[0].children;
      let top = 0;
      // let offsetTopMain = DOMHTML[0].offsetHeight + DOMHTML[1].offsetHeight + 15;
      let offsetTopMain = 0
      // console.log(DOMHTML)
      console.log(top, offsetTopMain)
      console.log(DOM)
      // console.dir(DOMHTML)
      DOM.forEach((item, i)=> {
        if(item.className.indexOf('pdf-not-see-children') >= 0 && item.className.indexOf('pdf-bottom-block') === -1) {
          if(((offsetTopMain + item.scrollHeight) - top) > 1100 && i > 0) {
            console.log(((offsetTopMain + item.scrollHeight) - top))
            console.dir(DOM[i-1]);
            DOM[i-1].classList.add('html2pdf__page-break')
            DOM[i].classList.add('break-after')

            // DOM[i-1]
            console.log(DOM[i-1]);
            console.log(DOM[i])
            top = offsetTopMain;
          }
        } else {
          let top1 = 0;
          let bool = false;
          console.dir(item.children)
          let offsetTop = offsetTopMain - top;
          let children = (item.className.indexOf('pdf-bottom-block') >= 0) ? item.children[0].children : item.children;

          if(item.className.indexOf('block-ports-pdf') >= 0) {
            console.log(`item.children`, item.children)
            children = item.children
            top1 = top1 + (item.scrollHeight - item.scrollHeight)
            // children = item.children[0].scrollHeight > item.children[1].scrollHeight ? item.children[0].children : item.children[1].children
            // top1 = top1 + (item.scrollHeight - (item.children[0].scrollHeight > item.children[1].scrollHeight ? item.children[0].scrollHeight : item.children[1].scrollHeight))
          }
          let array = [];
          if(item.className.indexOf('pdf-bottom-block') >= 0) {
            let height = 0;
            children.forEach((item1)=> {
              height+=item1.scrollHeight;
            })
            if(height < 190 && (((offsetTop + 190) - top1) > 1100)) {
              DOM[i-1].classList.add('html2pdf__page-break')
              DOM[i].classList.add('break-after')
              console.log(DOM[i])
              return;
            }
          }
          children.forEach((item1)=> {
            if(item1.className.indexOf('lay-time-table-children-connect') >= 0) {
              item1.children.forEach((a)=> {
                if(a.className.indexOf('lay-time-table-children-connect') >= 0) {
                  a.children.forEach((b)=> {
                    array.push(b)
                  })
                } else {
                  array.push(a)
                }
              })
            } else {
              array.push(item1)
            }
          })
          children = array
          console.log(array)
          children.forEach((item1, j)=> {
            console.log('offsetTop', offsetTop)
            if(((offsetTop + item1.offsetHeight) - top1) > 1100) {
              if(j !== 0) {
                console.log(((offsetTop + item1.offsetHeight) - top1))
                console.dir(children[j-1]);
                children[j-1].classList.add('html2pdf__page-break')
                children[j].classList.add('break-after')
                console.log(children[j])
                console.log(children[j-1]);
                top1 = offsetTop;
                bool = true
              } else {
                console.log(((offsetTop + item1.offsetHeight) - top1))
                console.dir(DOM[i-1]);
                DOM[i-1].classList.add('html2pdf__page-break')
                DOM[i].classList.add('break-after')
                console.log(DOM[i-1]);
                console.log(DOM[i])

                top1 += offsetTop;
                bool = true
              }
            }
            offsetTop+=item1.offsetHeight
          })
          if(bool) {
            top += top1
          }

        }
        offsetTopMain+=item.scrollHeight
      })

      // DOM.forEach((item, i)=> {
      //   console.log(DOM[i-1])
      //   if(i !== 1) {
      //     if(((offsetTopMain + item.scrollHeight) - top) > 1100 && i > 0) {
      //       console.log(((offsetTopMain + item.scrollHeight) - top))
      //       console.dir(DOM[i-1]);
      //       DOM[i-1].classList.add('html2pdf__page-break')
      //       console.log(DOM[i-1]);
      //       top = offsetTopMain;
      //     }
      //   } else {
      //     let top1 = 0;
      //     let bool = false;
      //     console.dir(item.children)
      //     // console.log('top1')
      //     let offsetTop = offsetTopMain - top;
      //     item.children.forEach((item1, j)=> {
      //       console.log('offsetTop', offsetTop)
      //       if(((offsetTop + item1.offsetHeight) - top1) > 1100) {
      //         if(j !== 0) {
      //           console.log(((offsetTop + item1.offsetHeight) - top1))
      //           console.dir(item.children[j-1]);
      //           item.children[j-1].classList.add('html2pdf__page-break')
      //           console.log(item.children[j-1]);
      //           top1 = offsetTop;
      //           bool = true
      //         } else {
      //           console.log(((offsetTop + item1.offsetHeight) - top1))
      //           console.dir(DOM[i-1]);
      //           DOM[i-1].classList.add('html2pdf__page-break')
      //           console.log(DOM[i-1]);
      //           top1 += offsetTop;
      //           bool = true
      //         }
      //       }
      //       offsetTop+=item1.offsetHeight
      //     })
      //     if(bool) {
      //       top += top1
      //     }
      //
      //   }
      //   offsetTopMain+=item.scrollHeight
      // })
      this.setTurnOnDarkModeAfterDownloadPDF(this.getDartMode)
      this.setDartMode(false)
      setTimeout(()=>{this.$refs.html2Pdf.generatePdf()}, 4000)
    },
    getValueFromXml(value, FractionDigits) {
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined) {
        if(moment(""+value, "YYYY-MM-DD HH:mm:ss", true).isValid() || moment(""+value, "YYYY-MM-DD HH:mm", true).isValid() || moment(""+value, "YYYY-MM-DD", true).isValid()) {
          return moment(value).format("DD.MM.YYYY")
        }
        let perc = false;
        if(value[value.length-1] === '%') {
          perc = true;
          value = value.replaceAll('%','')
        }
        if(!isNaN(value)) {
          let bool = false;
          if(value<0) {
            bool = true
          }
          let fixedNumber = value ? value : 0
          if(bool) {
            fixedNumber = fixedNumber*(-1)
          }
          fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: FractionDigits ? FractionDigits : 2, maximumFractionDigits: FractionDigits ? FractionDigits : 2})
          if((''+fixedNumber).indexOf('.') === -1) {
            fixedNumber+='.00'
          }
          if(perc) {
            fixedNumber+='%'
          }
          if(bool) {
            // return '('+fixedNumber + ')'
          }
          return fixedNumber
        }
        return ""+value
      }
      return null
    },
    getDate: function (val) {
      if(!val) return ''
      return moment(new Date(val)).format('DD.MM.YYYY')
    },

    getTime: function (val) {
      if(!val) return ''
      return moment(new Date(val)).format('HH:mm')
    },
    getTimeUsed(row, type) {
      if(row.from_date && row.to_date){
        let from = moment(row.from_date, 'hh:mm')
        let to = moment(row.to_date, 'hh:mm')
        let diff = to.diff(from, type)

        if(type == 'hours')
          return diff - (to.diff(from, 'days') * 24)
        else if(type == 'minutes')
          return diff - (to.diff(from, 'hours') * 60)
        else
          return diff
      }else{
        return ''
      }
    },
    getFullRemark(row) {
      let name = this.remarksList.find(el=>{
        return el.id == row.remarks
      })
      name = name ? name.name : ''

      if(name.indexOf('Own Remark') >= 0 && row.remark_info)
        name = name.replace('Own Remark', row.remark_info)

      return name
    },
    getIP() {
      return ''+process.env.VUE_APP_URL_SOCKET+':8080'
    },
    downloadXLSX() {
      let formAxios = {
        sheet_title: "LayTime",
        file_name: "LayTime",
        data: []
      }
      formAxios.data.push({id:formAxios.data.length + 1, values: ['http://'+this.getIP()+'/media/invoice_img_2.jpg', null, null, null, null, null, 'LAYTIME CALCULATION']})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})

      // formAxios.data.push({id: formAxios.data.length + 1, values: ["Vessel", "CP Date", "Demurrage Rate", "Status", 'Currency']})
      // formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(this.activeContract.name), this.getValueFromXml(this.lay.cp_date), this.getValueFromXml(this.lay.demurrage_rate ? this.lay.demurrage_rate : null), this.getValueFromXml(this.lay.status == 'confirmed' ? 'Confirmed' : this.lay.status == 'under_dispute' ? 'Under dispute' : 'Not confirmed'), this.getValueFromXml(this.lay.currency ? this.lay.currency : null) ]})
      // formAxios.data.push({id: formAxios.data.length + 1, values: ["Owner", "Charterer", "Dispatch Rate", "Time allowed", "Cargo"]})
      // formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(this.activeContract.owner.company_name), this.getValueFromXml(this.charterer ? this.charterer : null), this.getValueFromXml(this.lay.dispatch_rate ? this.lay.dispatch_rate : null), this.getValueFromXml(this.getTimeAllowed ? this.getTimeAllowed : null), this.getValueFromXml(this.lay.cargo ? this.lay.cargo : null)]})
      if(this.activeContract?.name)
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Vessel:", this.getValueFromXml(this.activeContract.name)]})
      if(this.lay?.cp_date)
        formAxios.data.push({id: formAxios.data.length + 1, values: ["CP Date: ", this.getValueFromXml(this.lay.cp_date)]})
      if(this.lay?.demurrage_rate)
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Demurrage Rate: ", this.getValueFromXml(this.lay.demurrage_rate, 3)]})
      if(this.lay?.cargo)
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Cargo", this.getValueFromXml(this.lay.cargo)]})
      // if(this.lay?.currency)
        // formAxios.data.push({id: formAxios.data.length + 1, values: ["Currency:", this.getValueFromXml(this.lay.currency)]})
      if(this.lay?.owner)
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Owner", this.getValueFromXml(this.lay.owner.company_name)]})
      if(this.charterer)
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Charterer", this.getValueFromXml(this.charterer)]})
      if(this.lay?.dispatch_rate)
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Dispatch Rate", this.getValueFromXml(this.lay.dispatch_rate, 3)]})
      if(this.getTimeAllowed)
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Time allowed", this.getValueFromXml(this.getTimeAllowed)]})
      // if(this.lay?.status)
      //   formAxios.data.push({id: formAxios.data.length + 1, values: ["Status:", this.getValueFromXml(this.lay.status == 'confirmed' ? 'Confirmed' : this.lay.status == 'under_dispute' ? 'Under dispute' : 'Not confirmed')]})

      formAxios.data.push({id: formAxios.data.length + 1, values: []})
      let ports = this.getFilterPorts(this.lay.lay_ports)
      for(let i=0; i<ports.length; i++) {
        let item = ports[i]
        if(item.loading_port) {
          if(item.loading_port)
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Port of Loading #" + ((this.lay.lay_ports.findIndex(a=> a.id === item.id))+1) +': ', this.getValueFromXml(item.loading_port.name) + ', ' + this.getValueFromXml(item.loading_port.country)]})
          if(item.loading_quantity && item.loading_port)
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Quantity of Loading Cargo: ", this.getValueFromXml(item.loading_quantity, 3)]})
          if(item.loading_rate && item.loading_port)
            formAxios.data.push({id: formAxios.data.length + 1, values: [!item.loading_is_day ? 'Rate of Loading: ' : 'Days: ', this.getValueFromXml(item.loading_rate)]})
          if(item.loading_terms && item.loading_port)
            formAxios.data.push({id: formAxios.data.length + 1, values: ['Terms of Loading: ', this.getValueFromXml(item.loading_terms)]})
        }
        if(item.discharging_port) {
          formAxios.data.push({id: formAxios.data.length + 1, values: []})
          if(item.discharging_port) {
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Port of Discharging #"+((this.lay.lay_ports.findIndex(a=> a.id === item.id))+1) + ': ', this.getValueFromXml(item.discharging_port.name + ', ' + item.discharging_port.country)]})
          }
          if(item.discharging_quantity)
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Quantity of Discharging Cargo", this.getValueFromXml(item.discharging_quantity, 3)]})
          if(item.discharging_rate)
            formAxios.data.push({id: formAxios.data.length + 1, values: [!item.discharging_is_day ? "Rate of Discharging" : "Days", this.getValueFromXml(item.discharging_rate)]})
          if(item.discharging_terms)
            formAxios.data.push({id: formAxios.data.length + 1, values: ["Terms of Discharging", this.getValueFromXml(item.discharging_terms)]})
          formAxios.data.push({id: formAxios.data.length + 1, values: []})
        }
      }
      this.getLoadingPorts().forEach(port=> {
        formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml('Loading #'+(this.getIndexPort(port)+1)+' ('+port.name+', '+port.country+')')]})
        if(port.arrived_load_date || port.loading_arrived_time) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ['Vessel arrived load port', null, 'Date', this.getValueFromXml(this.getDate(port.arrived_load_date)), 'Time', this.getValueFromXml(port.loading_arrived_time)]})
        }
        if(port.notice_tendered_loading_date || port.loading_notice_time) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ['Notice of Readiness tendered', null, 'Date', this.getValueFromXml(this.getDate(port.notice_tendered_loading_date)), 'Time', this.getValueFromXml(port.loading_notice_time)]})
        }
        if(port.loading_commenced_date || port.loading_commenced_time) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ['Loading commenced', null, 'Date', this.getValueFromXml(this.getDate(port.loading_commenced_date)), 'Time', this.getValueFromXml(port.loading_commenced_time)]})
        }
        if(port.loading_completed_date || port.loading_completed_time) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ['Loading completed', null, 'Date', this.getValueFromXml(this.getDate(port.loading_completed_date)), 'Time', this.getValueFromXml(port.loading_completed_time)]})
        }
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
      })
      this.getDischargingPorts().forEach(port=> {
        formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml('Discharging #'+(this.getIndexPort(port, 'discharging')+1)+' ('+port.name+', '+port.country+')')]})
        if(port.arrived_discharging_date || port.discharging_arrived_time) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ['Vessel arrived discharging port', null, 'Date', this.getValueFromXml(this.getDate(port.arrived_discharging_date)), 'Time', this.getValueFromXml(port.discharging_arrived_time)]})
        }
        if(port.notice_tendered_date || port.discharging_notice_time) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ['Notice of Readiness tendered', null, 'Date', this.getValueFromXml(this.getDate(port.notice_tendered_date)), 'Time', this.getValueFromXml(port.discharging_notice_time)]})
        }
        if(port.discharging_commenced_date || port.discharging_commenced_time) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ['Discharging commenced', null, 'Date', this.getValueFromXml(this.getDate(port.discharging_commenced_date)), 'Time', this.getValueFromXml(port.discharging_commenced_time)]})
        }
        if(port.discharging_completed_date || port.discharging_completed_time) {
          formAxios.data.push({id: formAxios.data.length + 1, values: ['Discharging completed', null, 'Date', this.getValueFromXml(this.getDate(port.discharging_completed_date)), 'Time', this.getValueFromXml(port.discharging_completed_time)]})
        }
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
      })
      if(this.lay.arrived_load_date ) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Vessel arrived load port", null, "Date", this.getValueFromXml(this.getDate(this.lay.arrived_load_date)), "Time", this.getValueFromXml(this.getTime(this.lay.arrived_load_date))]})
      }
      if(this.lay.notice_tendered_loading_date) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Notice of Readiness tendered", null, "Date", this.getValueFromXml(this.getDate(this.lay.notice_tendered_loading_date)), "Time", this.getValueFromXml(this.getTime(this.lay.notice_tendered_loading_date))]})
      }
      if(this.lay.loading_commenced_date ) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Loading commenced", null, "Date", this.getValueFromXml(this.getDate(this.lay.loading_commenced_date)), "Time", this.getValueFromXml(this.getTime(this.lay.loading_commenced_date))]})
      }
      if(this.lay.loading_completed_date ) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Loading completed", null, "Date", this.getValueFromXml(this.getDate(this.lay.loading_completed_date)), "Time", this.getValueFromXml(this.getTime(this.lay.loading_completed_date))]})
      }
      if(this.lay.arrived_discharging_date ) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Vessel arrived discharging port", null, "Date", this.getValueFromXml(this.getDate(this.lay.arrived_discharging_date)), "Time", this.getValueFromXml(this.getTime(this.lay.arrived_discharging_date))]})
      }
      if(this.lay.notice_tendered_date ) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Notice of Readiness tendered", null, "Date", this.getValueFromXml(this.getDate(this.lay.notice_tendered_date)), "Time", this.getValueFromXml(this.getTime(this.lay.notice_tendered_date))]})
      }
      if(this.lay.discharging_commenced_date ) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Discharging commenced", null, "Date", this.getValueFromXml(this.getDate(this.lay.discharging_commenced_date)), "Time", this.getValueFromXml(this.getTime(this.lay.discharging_commenced_date))]})
      }
      if(this.lay.discharging_completed_date ) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Discharging completed", null, "Date", this.getValueFromXml(this.getDate(this.lay.discharging_completed_date)), "Time", this.getValueFromXml(this.getTime(this.lay.discharging_completed_date))]})
      }
      formAxios.data.push({id: formAxios.data.length + 1, values: []})
      formAxios.data.push({id: formAxios.data.length + 1, values: [null, null, "Hours", null, null, "Time used", null]})
      formAxios.data.push({id: formAxios.data.length + 1, values: ["Date", "Day", "From", "To", "dd", "hh", "mm", "Remarks", "Time to count", "Equal days", "Check if on dem", "Time", "Days Qty"]})
      for(let i=0; i<this.lay.lay_times.length; i++) {
        if(((this.lay.lay_times[i].remarks == 'ntc_remark' || this.lay.lay_times[i].remarks == 'own_remark') && this.lay.lay_times[i].is_remark) || (this.lay.lay_times[i].remarks != 'ntc_remark' && this.lay.lay_times[i].remarks != 'own_remark'))
        formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(this.lay.lay_times[i].date), this.getValueFromXml(this.lay.lay_times[i].day), this.getValueFromXml(this.lay.lay_times[i].from_date), this.getValueFromXml(this.lay.lay_times[i].to_date), this.getValueFromXml(!this.lay.lay_times[i].remarks || this.lay.lay_times[i].remarks == 'na' ? this.getTimeUsed(this.lay.lay_times[i], 'days') : ''), this.getValueFromXml(!this.lay.lay_times[i].remarks || this.lay.lay_times[i].remarks == 'na' ? this.getTimeUsed(this.lay.lay_times[i], 'hours') : ''), this.getValueFromXml(!this.lay.lay_times[i].remarks || this.lay.lay_times[i].remarks == 'na' ? this.getTimeUsed(this.lay.lay_times[i], 'minutes') : ''), this.getValueFromXml(this.getFullRemark(this.lay.lay_times[i]) === 'N/A' ? '' : this.getFullRemark(this.lay.lay_times[i])), this.getValueFromXml(this.lay.lay_times[i].time_count), this.getValueFromXml(this.lay.lay_times[i].equal_days), this.getValueFromXml(this.lay.lay_times[i].check_if > this.getTimeAllowed ? 'on dem' : ':('), this.getValueFromXml(this.lay.lay_times[i].time), this.getValueFromXml(this.lay.lay_times[i].days_qty)]})
      }

      let rows = this.lay.lay_times ? this.lay.lay_times : [];
      let timeSumm = 0
      rows.forEach(row => {
        if(row.from_date && row.to_date && (!row.remarks || row.remarks == 'na')){
          let from = moment(row.from_date, 'hh:mm')
          let to = moment(row.to_date, 'hh:mm')
          timeSumm += to.diff(from, 'minutes')
        }
      })
      let days = Math.floor(timeSumm / 60 / 24)
      let hours = Math.floor((timeSumm / 60) - (days * 24))
      let minutes = Math.floor(timeSumm - (days * 24 * 60) - (hours * 60))
      let summ = 0
      rows.forEach(row => {
        summ += row.equal_days
      })
      formAxios.data.push({id: formAxios.data.length + 1, values: ["TOTAL", null, null, null, this.getValueFromXml(days), this.getValueFromXml(hours), this.getValueFromXml(minutes), null, null, this.getValueFromXml(summ)]})
      formAxios.data.push({id: formAxios.data.length + 1, values: []})
      formAxios.data.push({id: formAxios.data.length + 1, values: ["Time allowed, days", "Time used, days", this.getTotalEquals() >= this.getTimeAllowed ? ('Demurrage'  + " Time, days") : ('Dispatch' + " Time, days"), this.getTotalEquals() >= this.getTimeAllowed ? ('Demurrage'+" Rate, "+this.lay.currency) : ('Dispatch'+" Rate, "+this.lay.currency), (this.getTotalEquals() >= this.getTimeAllowed ? 'Demurrage' : 'Dispatch') + ', '+this.lay.currency]})
      formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(this.getTimeAllowed, 4), this.getValueFromXml(summ, 4), this.getValueFromXml((summ - this.getTimeAllowed), 4), this.getValueFromXml(this.getTotalEquals() >= this.getTimeAllowed ? this.lay.demurrage_rate : this.lay.dispatch_rate, 4), this.getValueFromXml(this.getDemurrage())]})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id: formAxios.data.length + 1, values: [null, null, null, null, null, 'http://'+this.getIP()+'/media/invoice_img_2.jpg']})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id:formAxios.data.length + 1, values: []})



      this.axiosGetXLSX(formAxios).then(response => {
        let link = response.data;
        function download(content, contentType, fileName) {
          var link1 = document.createElement("a");
          link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
          link1.download = fileName;
          link1.click();
        }
        download(link, "application/vnd.ms-excel", "LayTime.xls")
      });
    },
    getIndexPort(port, namePort) {
      let id = port?.PORT_ID ? port.PORT_ID : null
      if(namePort === 'discharging') {
        return this.lay.lay_ports.findIndex(a=> a.id === id)
      } else {
        return this.lay.lay_ports.findIndex(a=> a.id === id)
      }
    },
    getTotalEquals() {
      let summ = 0
      let rows = this.lay.lay_times ? this.lay.lay_times : []
      rows.forEach(row => {
        summ += row.equal_days
      })
      return summ
    },
    getDemurrage() {
      let dem_rate = this.getTimeAllowed > this.getTotalEquals() ? this.lay.demurrage_rate/2 : this.lay.demurrage_rate
      let result = this.getDemTime() * dem_rate
      result = Math.round(result*10000000)/10000000

      this.lay.demurrage = result
      if(this.getTotalEquals() < this.getTimeAllowed) {
        result = Math.trunc(result) + Math.floor(((result % 1)*100))/100
      }
      return result
    },
    getDemTime() {
      return Math.abs((Math.round(this.getTimeAllowed*100000000)/100000000) - (Math.round(this.getTotalEquals() * 100000000)/100000000))
    },
    getLoadingPorts() {
      let ports = this.lay.additional_info?.loading_ports?.length ? JSON.parse(JSON.stringify(this.lay.additional_info.loading_ports)) : [];
      ports = ports.filter(port=> (port.arrived_load_date || port.notice_tendered_loading_date || port.loading_arrived_time || port.loading_notice_time || port.loading_commenced_date || port.loading_completed_date || port.loading_commenced_time || port.loading_completed_time))
      return ports.sort((a, b) => a.id_port > b.id_port ? 1 : -1)
    },
    getDischargingPorts() {
      let ports = this.lay.additional_info?.discharging_ports?.length ? JSON.parse(JSON.stringify(this.lay.additional_info.discharging_ports)) : [];
      ports = ports.filter(port=> (port.arrived_discharging_date || port.notice_tendered_date || port.discharging_arrived_time || port.discharging_notice_time || port.discharging_commenced_date || port.discharging_completed_date || port.discharging_commenced_time || port.discharging_completed_time))
      return ports.sort((a, b) => a.id_port > b.id_port ? 1 : -1)
    },
    getFilterPorts(ports) {
      let array = JSON.parse(JSON.stringify(ports))
      let loadingIds = this.getLoadingPorts().map(a=> {return a.PORT_ID})
      let dischargingIds = this.getDischargingPorts().map(a=> {return a.PORT_ID})
      return array.map(a=> {
        let bool = false;
        if(loadingIds.indexOf(a.id) >= 0) {
          bool = true
        } else {
          a.loading_port = null
        }
        if(dischargingIds.indexOf(a.id) >= 0) {
          bool = true
        } else {
          a.discharging_port = null
        }
        if(bool) {
          return a;
        }
      }).filter(a=> a)
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.content-wrapper {
  &>div {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
}

///deep/ {
//  .pdf-lay-time {
//    width: 100%;
//    /deep/ {
//      .lay_table__grid.bg-white.rounded-tl-lg.rounded-tr-lg {
//        grid-template-columns: 11.64% repeat(3, 5.38%) repeat(3, 3.59%) 29.15% repeat(2, 16.2%);
//        &>*:nth-last-child(-n+3) {
//          display: none !important;
//        }
//        &>*:nth-last-child(4) {
//          border-right-width: 0;
//        }
//      }
//      .lay_table__grid.bg-white.rounded-bl-lg.rounded-br-lg {
//        grid-template-columns: 11.64% repeat(3, 5.38%) repeat(3, 3.59%) 29.15% repeat(2, 16.2%);
//        &>*:nth-last-child(-n+3) {
//          display: none !important;
//        }
//        &>*:nth-last-child(4) {
//          border-right-width: 0;
//        }
//      }
//    }
//  }
//}
.wrap-pdf-content {
  width: 760px !important;
  /deep/ {
    .lay_table__grid {
      div {
        font-size: 12px !important;
        padding: 1px !important;
      }
    }
  }

}
.block-bottom-logo {
  svg {
    margin-right: 16px;
  }
  padding-top: 16px;
  padding-bottom: 15px;
  p {
    margin-top: 15px;
  }
}
///deep/ {
//  .html2pdf__page-break {
//    //overflow: hidden;
//    padding-bottom: 15px !important;
//    position: relative;
//    &::after {
//      content: '';
//      position: absolute;
//      bottom: -50px;
//      left: -400px;
//      background-color: #fff;
//      height: 50px;
//      width: 2000px;
//    }
//  }
//}
.dart-mode {
  .slm-logo {
    g {
      path {
        fill: var(--color-blue-dark)
      }
    }
  }
}

</style>