<template>
  <div>
    <b-dropdown right ref="dropdown">
      <template #button-content>
        <button class="button-drop fz13 fw500">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.44 10.5601C7.23529 10.5601 7.88 11.2048 7.88 12.0001C7.88 12.7954 7.23529 13.4401 6.44 13.4401C5.64471 13.4401 5 12.7954 5 12.0001C5 11.2048 5.64471 10.5601 6.44 10.5601Z" fill="#A3A3A3"/>
            <circle cx="12.0001" cy="12" r="1.44" transform="rotate(-90 12.0001 12)" fill="#A3A3A3"/>
            <path d="M17.5601 10.5601C18.3554 10.5601 19.0001 11.2048 19.0001 12.0001C19.0001 12.7954 18.3554 13.4401 17.5601 13.4401C16.7648 13.4401 16.1201 12.7954 16.1201 12.0001C16.1201 11.2048 16.7648 10.5601 17.5601 10.5601Z" fill="#A3A3A3"/>
          </svg>
        </button>
      </template>
      <div class="content">
        <button class="fz13 text-dark edit" v-on:click="clickEdit" v-if="$route.path.indexOf('archive') === -1 && archive === false">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#094172"/>
          </svg>
          Edit
        </button>
        <button class="fz13 text-dark" v-on:click="clickUnlink" v-if="dop === true && $route.path.indexOf('archive') === -1 && getPermissionsLinkContract() && type === 'CARGO'">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 5C4.25 4.0335 5.0335 3.25 6 3.25H12C12.9665 3.25 13.75 4.0335 13.75 5V5.63636C13.75 6.05058 13.4142 6.38636 13 6.38636C12.5858 6.38636 12.25 6.05058 12.25 5.63636V5C12.25 4.86193 12.1381 4.75 12 4.75H6C5.86193 4.75 5.75 4.86193 5.75 5V12C5.75 12.1381 5.86193 12.25 6 12.25H6.77778C7.19199 12.25 7.52778 12.5858 7.52778 13C7.52778 13.4142 7.19199 13.75 6.77778 13.75H6C5.0335 13.75 4.25 12.9665 4.25 12V5Z" fill="#2C2C2C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.75 19C19.75 19.9665 18.9665 20.75 18 20.75H12C11.0335 20.75 10.25 19.9665 10.25 19V18.3636C10.25 17.9494 10.5858 17.6136 11 17.6136C11.4142 17.6136 11.75 17.9494 11.75 18.3636V19C11.75 19.1381 11.8619 19.25 12 19.25H18C18.1381 19.25 18.25 19.1381 18.25 19V12C18.25 11.8619 18.1381 11.75 18 11.75H17.2222C16.808 11.75 16.4722 11.4142 16.4722 11C16.4722 10.5858 16.808 10.25 17.2222 10.25H18C18.9665 10.25 19.75 11.0335 19.75 12V19Z" fill="#2C2C2C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4898 4.43236C18.8035 4.70287 18.8385 5.17646 18.5679 5.49014L6.56787 19.4052C6.29736 19.7189 5.82377 19.7539 5.51009 19.4833C5.19641 19.2128 5.16142 18.7393 5.43193 18.4256L17.432 4.51053C17.7025 4.19684 18.1761 4.16185 18.4898 4.43236Z" fill="#2C2C2C"/>
          </svg>
          Unlink
        </button>
        <button class="fz13 text-dark" v-on:click="clickArchive" v-if="archive !== true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Archive
        </button>
        <button class="fz13 text-dark" v-on:click="clickArchive" v-if="archive === true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#2C2C2C"/>
          <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#2C2C2C"/>
          </svg>
          Unarchive
        </button>

      </div>
    </b-dropdown>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "drop-menu-actual-vesssel",
  props: ["dop", "archive", "type"],
  computed: mapGetters(['getPermissionsByType']),
  data() {
    return {

    }
  },
  methods: {
    getPermissionsLinkContract() {
      return this.getPermissionsByType("link_and_unlink_voyage_contracts");
    },
    clickEdit() {
      this.$emit("edit")
      this.$refs.dropdown.hide(false)
    },
    clickUnlink() {
      this.$emit("unlink")
      this.$refs.dropdown.hide(false)
    },
    clickArchive() {
      this.$emit("archive")
      this.$refs.dropdown.hide(false)
    }
  },
  mounted() {

  }
}
</script>
<style scoped lang="scss">
@import "../../style-element/dropdown";
@import "../../style-element/checkbox";
.edit {
  svg {
    path {
      fill: #2C2C2C;
    }
  }
}

.button-drop {
  transition: .1s linear;
  svg {
    path, circle {
      transition: .1s linear
    }
  }
  &:hover {
    svg {
      path, circle {
        fill: var(--color-dark);
      }
    }
    transform: scale(1.2);
  }
}
/deep/ {

  .custom-control {
    min-height: 1px !important;
    padding: 0 8px 0 12px;
    width: 100%;
    /*height: 100%;*/
    /*box-sizing: border-box;*/
    /*align-items: center;*/
    .custom-control-label {
      width: 100%;

      font-size: 13px;
      line-height: 16px;
      color: #2C2C2C;
    }
  }
  .custom-control-label::before {
    height:16px;
    top: 0;
  }
  .custom-control-label::after {
    left: auto !important;
    right: 14px;
  }
  .custom-switch .custom-control-label::after {
    top: 2px!important;
  }
  .custom-switch .custom-control-label::before {
    left:auto;
    right: 0;
  }
  .custom-switch {
    top: 0 !important;
  }
}
.active-button {
  background-image: url("../../../assets/ic_chsdeck.svg");
  background-position-x: calc(100% - 4px);
  background-position-y: center;
  background-repeat: no-repeat;
}
.svg-1 {
  margin-right: 8px;
}
.arrow {
  margin-left: 4px;
}
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  top: -29px !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  padding: 4px 0 !important;
  background-color: var(--color-modal) !important;
}
.content {
  width: max-content;
  background-color: var(--color-modal);
  border-radius: 4px;
  .title {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 5px 12px;
    display: flex;
    align-items: center;
    svg {
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    transition: .1s linear;
    &:hover {
      color: var(--color-dark) !important;
    }
  }
  &>*{
    svg {
      margin-right: 4px;
    }
    height: 28px;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    transition: background-color .1s linear;
    &:hover {
      background-color: var(--color-card-border);
    }
  }

}
.bottom-button {
  margin-top: 4px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-card-border);
}

.dart-mode {

  .content {
    svg {
      path {
        fill: var(--color-modal);
      }
    }
  }
}

</style>