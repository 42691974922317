import axios from "axios";
import QueryString from "querystring";

export default {
    actions: {
        async axiosGetMessageForContract(ctx, data) {
          return await axios.get(process.env.VUE_APP_URL+'/mailbox/mails/', {
              params: data.params,
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(res=> {
              console.log('axiosGetMessageForContract', res)
          })
        },
        async axiosChangeVesselLocation(ctx, data) {
          return await axios.patch(process.env.VUE_APP_URL+'/location/'+data.id, data.data, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(()=> {
              return true;
          }).catch(()=> {
              return false;
          })
        },
        async axiosCreateVesselLocation(ctx, data) {
          return await axios.post(process.env.VUE_APP_URL+'/location/', data, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(()=> {
              return true
          }).catch(()=> {
              return false
          })
        },
        async axiosSendMessage(ctx, data) {
          return await axios.post(process.env.VUE_APP_URL+'/location/messages/', data, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(()=> {
              return true
          }).catch(()=> {
              return false
          })
        },
        async axiosDeleteSelectedLocation(ctx, id) {
          return await axios.delete(process.env.VUE_APP_URL+'/location/' + id, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(()=> {
              ctx.state.locationList = ctx.state.locationList.filter(a=> a.id !== id)
              return true
          }).catch(()=> {
              return false
          })
        },
        async axiosGetMailsForVeselById(ctx, data) {
            data.params['limit'] = 20
          return await  axios.get(process.env.VUE_APP_URL+'/location/' + data.id, {
                params: data.params,
                  headers: {
                      'Authorization': 'token ' + ctx.getters.getToken,
                  }
              }).then(response => {
              ctx.state.nextLinkLocationMessage = response.data.next
              return response.data
          }).catch(() => {
              return false
          })
        },
        async axiosGetAllMails(ctx, data) {
            data.params['limit'] = 40
            return await  axios.get(process.env.VUE_APP_URL+'/location/messages', {
                params: {
                    ...data.params,
                    ordering: (data.params && data.params.type == 'incoming' ? '-receive_date' :'-created_at') + ',is_read'
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                ctx.state.nextLinkLocationMessage = response.data.next
                return response.data
            }).catch(() => {
                return false
            })
        },
        async axiosLoadMoreAllMails(ctx) {
            return await axios.get(ctx.state.nextLinkLocationMessage, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                ctx.state.nextLinkLocationMessage = response.data.next
                return response.data
            }).catch(() => {
                return false
            })
        },
        async axiosGetMessagesById(ctx, id) {
          return await   axios.get(process.env.VUE_APP_URL+'/location/messages/' + id, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(response => {
              return response.data
          }).catch(() => {
              return false
          })
        },
        async axiosGetLocationById(ctx, id) {
          return await axios.get(process.env.VUE_APP_URL+'/location/'+id, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
          }).then(res=> {
              return res.data;
          }).catch(()=> {
              return false
          })
        },
        async axiosGetLocations(ctx, data) {
            let ct = axios.CancelToken.source();
            ctx.commit("cancelLocation")
            ctx.commit("cancelTokenSource", ct);
            console.log(ctx.getters.getToken)
            data['limit'] = 20
            ctx.state.loadLocation = true
            return await axios.get(process.env.VUE_APP_URL+'/location/',
                {
                    params: data,
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    },
                    paramsSerializer: function (params) {
                        return QueryString.stringify(params)
                    },
                    cancelToken: ct.token,
                }).then(response => {
                console.log('location', response.data)
                response.data.results.forEach(item=> {
                    if(!item.zone) {
                        item.zone = {name: ''}
                    }
                })
                ctx.state.locationList = response.data.results
                ctx.state.nextLinkLocation = response.data.next
                ctx.state.loadLocation = false
            }).catch(() => {
                ctx.state.loadLocation = false
            })
        },
        async axiosGetNextLocation(ctx) {
          return await axios.get(ctx.state.nextLinkLocation,
              {
                  headers: {
                      'Authorization': 'token ' + ctx.getters.getToken,
                  },
              }).then(response => {
              response.data.results.forEach(item=> {
                  if(!item.zone) {
                      item.zone = {name: ''}
                  }
              })
              ctx.state.locationList = ctx.state.locationList.concat(response.data.results)
              ctx.state.nextLinkLocation = response.data.next
          }).catch(() => {
          })
        },
        async axiosChangeLocationItem(ctx, data) {
            return await axios.patch(process.env.VUE_APP_URL+'/location/'+data.id, data.form, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true
            }).catch(()=> {
                return false
            })
        },
        async axiosGetTest(ctx) {
            return await axios.get(process.env.VUE_APP_URL+'/event/calendar/info/',
                {
                    params: {
                        date_before: '2023-03-26',
                        date_after: '2024-04-01',
                    },
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response => {
                console.log(response)
            }).catch(error => {
                console.log(''+error)
            })
        },
    },
    mutations: {
        cancelLocation(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
            }
        },
        cancelTokenSource(state, data) {
            state.cancelTokenSource = data;
        },
        setLocation(state, data) {
            state.locationList = data
        },
    },
    state: {
        nextLinkLocationMessage: null,
        cancelTokenSource: null,
        nextLinkLocation: null,
        locationList: [],
        loadLocation: false,
    },
    getters: {

    }
}