<template>
  <Transition name="fade">
    <div class="vue-modal" v-if="visibility">
      <div class="modal-container">
        <div class="vue-modal__modal-header">
          <span class="header-name">{{title}}</span>
          <span class="modal-close" @click="hideModal">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2486 5.03572C19.5415 5.32861 19.5415 5.80348 19.2486 6.09638L13.2032 12.1417L19.2486 18.1871C19.5415 18.48 19.5415 18.9549 19.2486 19.2478C18.9557 19.5407 18.4808 19.5407 18.1879 19.2478L12.1426 13.2024L6.09721 19.2478C5.80432 19.5407 5.32944 19.5407 5.03655 19.2478C4.74366 18.9549 4.74366 18.48 5.03655 18.1871L11.0819 12.1417L5.03655 6.09637C4.74366 5.80348 4.74366 5.32861 5.03655 5.03571C5.32944 4.74282 5.80432 4.74282 6.09721 5.03571L12.1426 11.0811L18.1879 5.03572C18.4808 4.74282 18.9557 4.74282 19.2486 5.03572Z"
                fill="#262626"
              />
            </svg>
          </span>
        </div>
        <div class="vue-modal__modal-content">
          <span class="text">{{text}}</span>
        </div>
        <div class="vue-modal__modal-footer">
          <button class="modal-button" @click="hideModal">{{cancelBtnText}}</button>
          <button class="modal-button modal-button_apply" @click="apply">{{applyBtnText}}</button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
  export default {
    name: 'text-modal',
    props: {
      title: {type: String, default: 'Modal title'},
      applyBtnText: {type: String, default: 'Apply'},
      cancelBtnText: {type: String, default: 'Cancel'},
      text: {type: String, default: 'Some text'},
    },
    data: () => ({
      visibility: false,
      id: null
    }),
    methods: {
      showModal(id = null){
        if(id){
          this.id = id
        }
        this.visibility = true
      },
      hideModal(){
        this.id = null;
        this.visibility = false
      },
      apply(){
        this.$emit('apply', this.id)
        this.hideModal()
      }
    }
  }
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.vue-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.3);
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: auto;
  }

  .modal-container {
    width: 430px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 16px;
  }
}

.vue-modal__modal-header {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .header-name{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.vue-modal__modal-footer{
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  align-items: center;
  margin-top: 24px;
  *{
    transition: .3s;
  }
  .modal-button{
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--color-blue-dark);
    border-radius: 4px;
    
    &:hover{
      color: var(--color-blue);
    }
    &_apply{
      background: var(--color-blue);
      color: var(--color-white);
      &:hover{
        color: var(--color-white);
        background: var(--color-blue-dark);
      }
    }
  }
}
.vue-modal__modal-content{
  padding-top: 16px;
  .text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    width: 16px;
    height: 16px;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>