<template>
  <div>
    <div class="p-t-3 html2pdf__page-break" v-for="(vessel, i) in vessels" :key="i">
      <div class="calc_vessels__row_vessels flex justify-between relative">
        <calc-vessels-list
            :exportPdf="exportPdf"
            :vessels="vessels"
            :isAllow="ifAllowEdit"
            :selectedId="vessel.id"
            @on-select="onSelect"
            @on-pin="onPinVessels"
            @on-delete="onDelete"
            @on-create="onCreate"
            @on-calc="onCalcInVessel"
        />
        <calc-vessels-detail :vessel="vessel" :isAllow="ifAllowEdit"/>
        <calc-vessels-speed :vessel="vessel" :isAllow="ifAllowEdit"/>
      </div>

      <div class="relative">
        <div class="blocked-changes" v-if="!ifAllowEdit"></div>
        <calc-vessels-speed-result class="m-t-4" :detail="vessel" v-model="fuelType"/>
      </div>

      <div class="m-t-4 flex items-center relative">
        <div class="w-1/2 fw500">
          <template v-if="!vessel">No vessel selected</template>
          <div class="inline-flex" v-else>
            <span class="block max-w-320 fw500 whitespace-nowrap overflow-hidden overflow-ellipsis">{{vessel.name}} </span>
            <div class="fw500">
              <span class="fz0">space</span>
              <span class="fw500" v-if="vessel.dwt">{{' - '+vessel.dwt}}</span>
              <span class="fw500" v-if="vessel.year">{{' - '+vessel.year}}</span>
              (<span class="capitalize fw500">{{fuelType}}</span> Speed)
            </div>
          </div>
        </div>
        <calc-vessels-total-times
            :fuelType="fuelType"
            :routes="getActiveVesselRoutes(vessel)"
        />
      </div>

      <div
          class="m-t-3 border border-dark-gray rounded-lg relative"
          :class="{'opacity-50 pointer-events-none': !vessel}"
      >
        <calc-vessels-dots-head
            :routes="getActiveVesselRoutes(vessel)"
            :cargos="cargos"
            @on-add="onCreateRoutes"
        />
        <calc-vessels-dots-empty :isTop="true" @on-select="(port) => onCreateRoutes(port, -1)"/>

        <draggable
            v-if="getActiveVessel"
            handle=".handle"
            @change="onUpdateDrag"
        >
          <calc-vessels-dots-row
              v-for="(row, i) in getActiveVesselRoutes(vessel)"
              :key="i"
              :dot="row"
              :routes="getActiveVesselRoutes(vessel)"
              :cargos="cargos"
              :fuelType="fuelType"
              :vessel="vessel"
              @on-set-disbursement="onSetDisbursement(vessel.dwt, row.disbursement, i)"
              @on-add="(arr) => onCreateRoutes(arr, i)"
              @on-delete="onDeleteRoute(i)"
          />
        </draggable>

        <calc-vessels-dots-empty @on-select="(port) => onCreateRoutes(port, getActiveVesselRoutes(vessel).length-1)"/>
        <calc-vessels-dots-total
            :routes="getActiveVesselRoutes(vessel)"
            :fuelType="fuelType"
        />
        <div class="blocked-changes" v-if="!ifAllowEdit"></div>
      </div>

      <div class="p-t-4"></div>
    </div>

  </div>
</template>

<script>
import calcVesselsList from '@/components/calc/calcVesselsList'
import calcVesselsDetail from '@/components/calc/calcVesselsDetail'
import calcVesselsSpeed from '@/components/calc/calcVesselsSpeed'
import calcVesselsTotalTimes from '@/components/calc/calcVesselsTotalTimes'
import calcVesselsSpeedResult from '@/components/calc/calcVesselsSpeedResult'
import calcVesselsDotsRow from '@/components/calc/calcVesselsDotsRow'
import calcVesselsDotsTotal from '@/components/calc/calcVesselsDotsTotal'
import calcVesselsDotsHead from '@/components/calc/calcVesselsDotsHead'
import draggable from 'vuedraggable'
import moment from 'moment'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  props: {
    exportPdf: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    calcVesselsSpeed,
    calcVesselsList,
    calcVesselsDetail,
    calcVesselsTotalTimes,
    calcVesselsSpeedResult,
    calcVesselsDotsTotal,
    calcVesselsDotsHead,
    calcVesselsDotsRow,
    draggable
  },
  data() {
    return {
      cargos: [],
      vessels: [],
      selectedId: null,
      fuelType: 'full',
      fuelList: ['full', 'eco', 'slow', 'optimal'],
      timeout: null,
      emptyDot: {
        intake: null,
        status: 'passing',
        dist: null,
        seca: null,
        has_seca: false,
        draft_restr: null,
        disbursement: null,
        port_stay: null,
        is_full: false,
        is_eco: false,
        optimal_speed: null,

        full_streaming_days: null,
        eco_streaming_days: null,
        slow_streaming_days: null,
        optimal_streaming_days: null,
        full_streaming_time: null,
        eco_streaming_time: null,
        slow_streaming_time: null,
        optimal_streaming_time: null,

        full_arrival_date: null,
        eco_arrival_date: null,
        slow_arrival_date: null,
        optimal_arrival_date: null,
        full_arrival_time: null,
        eco_arrival_time: null,
        slow_arrival_time: null,
        optimal_arrival_time: null,

        full_departure_date: null,
        eco_departure_date: null,
        slow_departure_date: null,
        optimal_departure_date: null,
        full_departure_time: null,
        eco_departure_time: null,
        slow_departure_time: null,
        optimal_departure_time: null,

        full_result: null,
        eco_result: null,
        slow_result: null,
        optimal_result: null,
        
        full_ifo_mt: null,
        eco_ifo_mt: null,
        slow_ifo_mt: null,
        optimal_ifo_mt: null,
        
        full_mgo_mt: null,
        eco_mgo_mt: null,
        slow_mgo_mt: null,
        optimal_mgo_mt: null,
        
        port_name: null,
        port: null,
        cargo: null
      }
    }
  },
  watch: {
    fuelType: function() {
      this.setActiveFuelType(this.fuelType);
    },
    calcVesselActive: function () {
      if(this.calcVesselActive != this.selectedId) {
        this.selectedId = this.calcVesselActive;
      }
    },
    getVesselDataForPdf: function () {
      this.vessels = this.getVesselDataForPdf
    },
  },
  computed: {
    ...mapState({
      activeCreator: state => state.tabs.activeCreator,
      termsList: state => state.tabs.termsList,
      calcVesselActive: state => state.tabs.calcVesselActive,
    }),
    ...mapGetters(['getLoginData', 'getNumbFromString', 'getCurrentTab', 'getVesselDataForPdf']),
    activeTab() {
      return this.getCurrentTab(this.$route.params?.id)
    },
    getActiveVessel() {
      return this.vessels.find(el=>el.id==this.selectedId)
    },

    ifAllowEdit() {
      return this.$route.path.indexOf('archive') === -1 && this.activeTab?.creator?.id === this.getLoginData.data.user?.id
    } 
  },
  methods: {
    ...mapActions([
      'axiosGetCalcVessel', 
      'axiosPinCalcVessel', 
      'axiosPostCalcVessel',
      'axiosDeleteCalcVessel', 
      'axiosPatchCalcVessel',
      'axiosGetCalcCargo',
      'axiosGetRouteDistance',
    ]),
    ...mapMutations(['setActiveFuelType', 'showLoad', 'hideLoad', "setCalcVesselActive"]),
    getActiveVesselRoutes(vessel) {
      return vessel?.additional_info?.routes
    },
    onCalcInVessel(vessel) {
      console.log('onCalcInVessel', vessel);
      this.onUpdateDataInVessel(vessel)
    },
    async onPinVessels(vessels) {
      if(!this.ifAllowEdit) return false

      let result = await this.axiosPinCalcVessel({
        data: vessels.map(el => {
          return {
            id: el.id,
            vessel_type: el.vessel_type,
          }
        }),
        tab: this.$route.params.id
      })

      let vesselWithRoutes = result.find(el=>el.additional_info?.routes && el.additional_info?.routes.length)
      let routes = vesselWithRoutes ? JSON.parse(JSON.stringify(vesselWithRoutes.additional_info.routes)) : []

      //console.log(routes);
      routes.forEach(el=>{
        el.intake = null,
        el.disbursement = null
      })

      result.forEach(el => {
        if(!el.additional_info?.routes?.length)
          el.additional_info.routes = routes
      })

      await this.$nextTick()
      this.vessels = result

      this.onSetRoutesInfo()
    },
    async onCreate() {//vessel
      if(this.ifAllowEdit) {
        let routes = this.vessels?.length ? JSON.parse(JSON.stringify(this.vessels[0].additional_info.routes)) : []
        routes.forEach(el=>{
          el.intake = null,
          el.disbursement = null
        })

        let newVessel = await this.axiosPostCalcVessel({
          name: 'Created Vessel',
          tab: this.$route.params.id,
          additional_info: {
            routes
          }
        })
        this.vessels.unshift(newVessel)
        this.selectedId = newVessel.id
      }
    },
    onPatchVessels() {
      if(this.ifAllowEdit) {
        let savedVessels = JSON.parse(JSON.stringify(this.vessels))
        savedVessels.forEach(vessel => {
          if(vessel.time === '') vessel.time = null
        })
        this.axiosPatchCalcVessel(savedVessels)
      }
    },
    onSelect(id) {//vessel
      // if(this.ifAllowEdit) {
        if(this.selectedId != id){
          this.setCalcVesselActive(id)
          this.selectedId = id
          localStorage.setItem('lastActiveVesselInCalc', id)
        }
      // }
    },
    async onDelete(id) {//vessel
      if(this.ifAllowEdit) {
        if(id == this.selectedId) this.selectedId = null
        let index = this.vessels.findIndex(el=>el.id==id)
        if(index >= 0){
          await this.axiosDeleteCalcVessel(id)
          this.vessels.splice(index, 1)
        }
      }
    },

    async onCreateRoutes(created, index = -1) {
      if(this.ifAllowEdit) {
        let routes = []

        if(Array.isArray(created)){
          created.forEach(el => {
            let cargo = this.cargos.find(item=>item.id == el)
            if(cargo.loading_port_name){
              routes.push({
                ...this.emptyDot,
                port: cargo.loading_port,
                port_name: cargo.loading_port_name,
                draft_restr: cargo.loading_draft,
                status: 'loading',
                cargo: cargo.id,
                is_full: this.fuelType == 'full',
                is_eco: this.fuelType == 'eco'
              })
            }
            if(cargo.discharging_port_name){
              routes.push({
                ...this.emptyDot,
                port: cargo.discharging_port,
                port_name: cargo.discharging_port_name,
                draft_restr: cargo.discharging_draft,
                status: 'discharging',
                cargo: cargo.id,
                is_full: this.fuelType == 'full',
                is_eco: this.fuelType == 'eco'
              })
            }
          })
        }else{
          routes.push({
            ...this.emptyDot,
            port: created,
            port_name: created.name,
            draft_restr: created.max_draft,
            is_full: this.fuelType == 'full',
            is_eco: this.fuelType == 'eco'
          })
        }
        this.vessels.forEach(el=>{
          if(created?.dwt_fields?.length){
            let dwt = el.dwt ? el.dwt : 0
            let disbursement = created.dwt_fields.find(item => dwt >= item.min && dwt <= item.max)
            routes[0].disbursement = disbursement?.price ? disbursement.price : null
          }
          el.additional_info.routes.splice(1+index, 0, ...JSON.parse(JSON.stringify(routes)))
        })

        this.onSetRoutesInfo()
      }
    },
    async onDeleteRoute(i) {
      if(this.ifAllowEdit) {
        this.vessels.forEach(el=>{
          el.additional_info.routes.splice(i, 1)
        })

        if(this.vessels[0].additional_info.routes.length){
          this.onSetRoutesInfo()
        }
      }
    },
    checkIfCargoExist() {
      if(this.vessels.length == 0) return false
      let vessel = this.vessels[0]
      let routes = vessel?.additional_info?.routes
      if(!routes) return false
      routes.forEach((route, i) => {
        if(route.cargo == null) return false

        let existCargo = this.cargos.find(el=>el.id == route.cargo)
        if(!existCargo){
          this.onDeleteRoute(i)
        }
      })
    },

    async setDistanceForRoutes() {
      if(!this.getActiveVesselRoutes) return false
        let distances = []
        let routes = JSON.parse(JSON.stringify(this.getActiveVesselRoutes))

        for (let i = 1; i < routes.length; i++) {
          const prevEl = routes[i-1];
          const el = routes[i];
          let distance = await this.axiosGetRouteDistance({
            from: prevEl.port_name,
            to: el.port_name
          })
          distances.push({
            dist: distance?.status == 400 ? 0 : distance.distance,
            seca: distance?.status == 400 ? 0 : distance.distance_seca,
            has_seca: distance?.status == 400 ? false : distance.to_is_seca
            /* dist: Math.floor(Math.random()*(100000 - 1000)) + 1000,
            seca: Math.floor(Math.random()*(1000 - 10)) + 10,
            has_seca: Math.random() > 0.5 */
          })
        }

        for (let i = 0; i < this.vessels.length; i++) {
          let el = this.vessels[i];
          let routes = el.additional_info.routes
          routes[0].dist = 0
          routes[0].seca = 0
          routes[0].has_seca = false
          for (let j = 1; j < routes.length; j++) {
            let element = routes[j];
            element.dist = distances[j-1].dist
            element.seca = distances[j-1].seca
            element.has_seca = distances[j-1].has_seca
          }
        }
    },

    async onUpdateDrag(type) {
        if(type.moved){
          for (let i = 0; i < this.vessels.length; i++) {
            let el = this.vessels[i];
            if(this.getActiveVessel.id != el.id){ // active vessel already moved
              let routes = el.additional_info.routes
              let movedEl = routes.splice(type.moved.oldIndex, 1)
              routes.splice(type.moved.newIndex, 0, ...movedEl)
            }
          }

          this.onSetRoutesInfo()
        }
    },

    async onSetRoutesInfo() {
      await this.setDistanceForRoutes()
      this.setIntakeForRoutes()
      this.setResultsForRoutes()
      this.setSteamingForRoutes()
      this.setPortStayForRoutes()
      this.setArrivalAndDepartureForRoutes()
      this.setIfoMgoForRoutes()
      this.setVoyageDays()
      this.setHireAndFreightBep()
    },

    onUpdateDataInVessel(vessel) {
      this.setIntakeForRoutesInVessel(vessel)
      this.setResultsForRoutesInVessel(vessel)
      this.setSteamingForRoutesInVessel(vessel)
      this.setPortStayForRoutesInVessel(vessel)
      this.setArrivalAndDepartureForRoutesInVessel(vessel)
      this.setIfoMgoForRoutesInVessel(vessel)
      this.setVoyageDaysInVessel(vessel)
      this.setHireAndFreightBepInVessel(vessel)
    },

    onSetDisbursement(dwt, disbursement, i) {
      this.vessels.forEach(vessel=>{
        let routes = vessel.additional_info.routes
        if(!routes[i].disbursement){
          let rate = disbursement / dwt
          rate = rate ? rate : 0
          let result = rate * vessel.dwt
          result = result ? result : 0
          routes[i].disbursement = result
        }
      })
    },
    setSteamingForRoutes() {
      this.vessels.forEach(vessel=>{
        this.setSteamingForRoutesInVessel(vessel)
      })
    },
    setSteamingForRoutesInVessel(vessel) {
      vessel.additional_info.routes.forEach(dot=>{
        let weather_factor = this.activeTab?.weather_factor ? this.activeTab.weather_factor : 0
        let dist = dot.dist ? dot.dist : 0

        let suff = dot.intake ? '_lad' : '_ball'

        let fullResult = (dist / (vessel['full_sp'+suff] - (vessel['full_sp'+suff] * weather_factor/100)))/24
        let ecoResult = (dist / (vessel['eco_sp'+suff] - (vessel['eco_sp'+suff] * weather_factor/100)))/24
        let slowResult = (dist / (vessel['slow_sp'+suff] - (vessel['slow_sp'+suff] * weather_factor/100)))/24
        let optimalResult

        if (dot.eco_result < dot.full_result) {//eco
          optimalResult = ecoResult
        } else {//full
          optimalResult = fullResult
        }

        dot.full_streaming_days = this.getTimeFromNumb(fullResult, 'days')
        dot.full_streaming_time = this.getTimeFromNumb(fullResult, 'time')
        dot.eco_streaming_days = this.getTimeFromNumb(ecoResult, 'days')
        dot.eco_streaming_time = this.getTimeFromNumb(ecoResult, 'time')
        dot.slow_streaming_days = this.getTimeFromNumb(slowResult, 'days')
        dot.slow_streaming_time = this.getTimeFromNumb(slowResult, 'time')
        dot.optimal_streaming_days = this.getTimeFromNumb(optimalResult, 'days')
        dot.optimal_streaming_time = this.getTimeFromNumb(optimalResult, 'time')

        /* if(result && result != Infinity){
          result = Math.round(result*10000)/10000
          let days = Math.floor(result)
          result = (result - days)*24
          let hours = Math.floor(result)
          result = (result-hours)*60
          let minutes = Math.floor(result)

          if(hours < 10) hours = '0'+hours
          if(minutes < 10) minutes = '0'+minutes

          dot.streaming_days = days
          dot.streaming_time = hours+':'+minutes
        }else{
          dot.full_streaming_days = null
          dot.full_streaming_time = null
          dot.eco_streaming_days = null
          dot.eco_streaming_time = null
          dot.slow_streaming_days = null
          dot.slow_streaming_time = null
          dot.optimal_streaming_days = null
          dot.optimal_streaming_time = null
        } */
      })
    },
    getTimeFromNumb(numb, key='days') {
      if(numb && numb != Infinity){
        if(key == 'days'){
          return Math.floor(numb)
        }else if(key == 'time'){
          let hours = (numb - Math.floor(numb))*24
          let minutes = Math.floor((hours - Math.floor(hours))*60)
          hours = Math.floor(hours)
          if(hours < 10) hours = '0'+hours
          if(minutes < 10) minutes = '0'+minutes
          return hours+':'+minutes
        }
      }else{
        return null
      }
    },
    setPortStayForRoutes() {
      this.vessels.forEach(vessel=>{
        this.setPortStayForRoutesInVessel(vessel)
      })
    },
    setPortStayForRoutesInVessel(vessel) {
      vessel.additional_info.routes.forEach(dot=>{
        if(dot?.port?.data_type == 'canal'){
          if(dot.port.transit_time)
            dot.port_stay = dot.port.transit_time
        }else if(dot.cargo && dot.status){
          //console.log('----');
          let result = 0
          let cargo = this.cargos.find(el => el.id == dot.cargo)
          //console.log('PortStay cargo class', cargo.cargo_class);
          let rate = cargo && cargo[dot.status+'_rate'] ? cargo[dot.status+'_rate'] : 0
          let terms = this.termsList.find(el=>el.name == cargo[dot.status+'_terms'])
          terms = terms ? terms.coof : 0
          if(cargo[dot.status+'_rate_type'] == 'DAYS'){
            result = rate * terms
            //console.log(`PortStay days(${result}) = rate(${rate}) * terms(${terms})`);
          }else{
            let quantity = cargo.quantity_mt
            if(!cargo.quantity_mt){
              let min = cargo.quantity_min ? cargo.quantity_min : 0
              let max = cargo.quantity_max ? cargo.quantity_max : 0
              quantity = (min + max)/2
            }

            result = (quantity / rate / 24) * terms
            //console.log(`PortStay rate(${result}) = (quantity(${quantity}) / rate(${rate}) / 24) * terms(${terms})`);
          }
          if(result){
            //console.log('PortStay after round: ', result);
            result = result*24
            let hours = Math.floor(result)
            result = result - hours
            let minutes = Math.floor(result*60)
            if(hours < 10) hours = '0'+hours
            if(minutes < 10) minutes = '0'+minutes

            //console.log('PortStay HH:mm - ', hours, minutes);
            dot.port_stay = hours+':'+minutes
          }else{
            dot.port_stay = null
          }
        }else{
          dot.port_stay = null
        }
      })
    },
    setArrivalAndDepartureForRoutes() {
      this.vessels.forEach(vessel=>{
        this.setArrivalAndDepartureForRoutesInVessel(vessel)
      })
    },
    setArrivalAndDepartureForRoutesInVessel(vessel) {
      let routes = vessel.additional_info.routes
      routes.forEach((dot, i)=>{
        /* Set arrival */
        if(i == 0){
          if(!vessel.date) return false
          dot.full_arrival_date = moment(vessel.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          dot.eco_arrival_date = moment(vessel.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          dot.slow_arrival_date = moment(vessel.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          dot.optimal_arrival_date = moment(vessel.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
          dot.full_arrival_time = vessel.time
          dot.eco_arrival_time = vessel.time
          dot.slow_arrival_time = vessel.time
          dot.optimal_arrival_time = vessel.time
        }else{
          this.fuelList.forEach(fuel => {
            if(!routes[i-1][fuel+'_departure_date']) return false

            let newArrival = routes[i-1][fuel+'_departure_date'] + ' ' + (routes[i-1][fuel+'_departure_time'] ? routes[i-1][fuel+'_departure_time'] : '00:00')
            newArrival = moment(newArrival, 'DD.MM.YYYY HH:mm')

            if(dot[fuel+'_streaming_days'] || dot[fuel+'_streaming_time']){
              let streaming_time = dot[fuel+'_streaming_time'] ? dot[fuel+'_streaming_time'].split(':') : [0,0]
              newArrival.add({
                'days': dot[fuel+'_streaming_days'],
                'hours': streaming_time[0],
                'minutes': streaming_time[1]
              })
            }

            dot[fuel+'_arrival_date'] = newArrival.format('DD.MM.YYYY')
            dot[fuel+'_arrival_time'] = newArrival.format('HH:mm')
          })
        }


        /* Set departures */
        this.fuelList.forEach(fuel => {
          if(!dot[fuel+'_arrival_date']) return false
          let newDeparture = dot[fuel+'_arrival_date'] + ' ' + (dot[fuel+'_arrival_time'] ? dot[fuel+'_arrival_time'] : '00:00')
          newDeparture = moment(newDeparture, 'DD.MM.YYYY HH:mm')

          if(dot.port_stay){
            let port_stay_time = dot.port_stay.split(':')
            newDeparture.add({
              'hours': port_stay_time[0],
              'minutes': port_stay_time[1]
            })
          }
          dot[fuel+'_departure_date'] = newDeparture.format('DD.MM.YYYY')
          dot[fuel+'_departure_time'] = newDeparture.format('HH:mm')
        })
      })
    },
    setIntakeForRoutes() {
      this.vessels.forEach(vessel=>{
        this.setIntakeForRoutesInVessel(vessel)
      })
    },
    setIntakeForRoutesInVessel(vessel) {
      let routes = vessel.additional_info.routes
      routes.forEach((dot, i)=>{
        if(!dot.cargo){
          return false
        } else{
          //console.log('----');
          //console.log('cargo or balast:', cargo?.cargo_class);
          //console.log('port:', dot?.port?.name);
          let result

          let cargo = this.cargos.find(el => el.id == dot.cargo)
          if(!cargo){
            this.onDeleteRoute(i)
            return false
          }
          if(cargo?.ballast_voyage){
            dot.intake = null
            return false
          }
          let water_destiny = this.getNumbFromString(cargo[dot.status+'_water_density'])

          let sswd = vessel.draft ? vessel.draft : 0
          let draft_restr = dot.draft_restr ? dot.draft_restr : 0
          let tpc = vessel.tpc ? vessel.tpc : 0
          let bob = vessel.bob ? vessel.bob : 0
          let constanta = vessel.constants ? vessel.constants : 0
          let fresh_water = vessel.fresh_water ? vessel.fresh_water : 0
          let sdwt = vessel.dwt ? vessel.dwt : 0

          if(cargo[dot.status+'_seasonal_mark'] == 'tropical'){
            sdwt = sdwt + (sswd/48)* tpc*100
            //console.log(`tropical sdwt(${sdwt}) = sdwt(${vessel.dwt}) + (sswd(${sswd})/48) * tpc(${tpc})*100`);
          }else if(cargo[dot.status+'_seasonal_mark'] == 'winter'){
            sdwt = sdwt - (sswd/48)* tpc*100
            //console.log(`winter sdwt(${sdwt}) = sdwt(${vessel.dwt}) - (sswd(${sswd})/48) * tpc(${tpc})*100`);
          }


          let fwa = (sswd/48)*1.1
          fwa = fwa ? fwa : 0
          //console.log(`fwa(${fwa}) = (sswd(${sswd})/48)*1.1`);

          let dwa = ((1.025 - water_destiny)/0.025)*fwa
          dwa = dwa ? dwa : 0
          //console.log(`dwa(${dwa}) = ((1.025 - water_destiny(${water_destiny}))/0.025)*fwa(${fwa})`);

          if(cargo[dot.status+'_seasonal_mark'] == 'tropical'){
            sswd = sswd + sswd/48 + dwa
            //console.log(`tropical sswd(${sswd}) = sswd(${vessel.draft}) + sswd(${vessel.draft})/48 + dwa(${dwa})`);
          }else if(cargo[dot.status+'_seasonal_mark'] == 'winter'){
            sswd = sswd - sswd/48 + dwa
            //console.log(`winter sswd(${sswd}) = sswd(${vessel.draft}) - sswd(${vessel.draft})/48 + dwa(${dwa})`);
          }

          let dwcc = sdwt - (sswd + dwa - draft_restr)*100*tpc
          dwcc = dwcc ? dwcc : 0
          //console.log(`dwcc(${dwcc}) = sdwt(${sdwt}) - (sswd(${sswd}) + dwa(${dwa}) - draft_restr(${draft_restr}))*100*tpc(${tpc})`);
          
          result = dwcc - bob - constanta - fresh_water
          //console.log(`intake(${result}) = dwcc(${dwcc}) - bob(${bob}) - constanta(${constanta}) - fresh_water(${fresh_water})`);

          dot.intake = result ? result : 0
        }
      })
    },

    setResultsForRoutes() {
      this.vessels.forEach(vessel => {
        this.setResultsForRoutesInVessel(vessel)
      })
    },
    setResultsForRoutesInVessel(vessel) {
      let weather_factor = this.activeTab?.weather_factor ? this.activeTab?.weather_factor : 0
      let add_com = this.activeTab?.addcom ? this.activeTab?.addcom : 0
      let vlsfo = this.activeTab?.vlsfo ? this.activeTab?.vlsfo : 0
      let lsmgo = this.activeTab?.lsmgo ? this.activeTab?.lsmgo : 0
      let hsfo = this.activeTab?.hsfo ? this.activeTab?.hsfo : 0

      let routes = vessel.additional_info.routes
      let fuel_1 = vessel.scrubber ? hsfo : vlsfo
      let fuel_2 = vessel.scrubber ? hsfo : lsmgo

      routes.forEach(dot => {
        let suff = dot.intake ? '_lad' : '_ball'

        dot.full_result = this.getOneResult(
            dot.dist,
            dot.seca,
            vessel['full_sp' + suff],
            weather_factor,
            vessel.hire,
            add_com,
            vessel['full_ifo' + suff],
            vessel['full_mgo' + suff],
            fuel_1,
            fuel_2
        )
        
          console.log(`route full result(${dot.full_result}) = 
            (
              (
                (dist(${dot.dist}) / speed(${vessel['full_sp' + suff]}) + ((dist / speed)(${dot.dist/vessel['full_sp' + suff]}) * weather_factor(${weather_factor}))/100) / 24
              ) 
              * 
              (
                hire(${vessel.hire}) - (hire(${vessel.hire}) * add_com(${add_com}))/100
              )
            )
            + 
            (
              (
                ((dist(${dot.dist}) - seca(${dot.seca})) / speed(${vessel['full_sp' + suff]}) + (((dist(${dot.dist}) - seca(${dot.seca})) / speed(${vessel['full_sp' + suff]})) * weather_factor(${weather_factor}))/100) / 24
              ) * ifo(${vessel['full_ifo' + suff]}) * fuel_1(${fuel_1})
            ) 
            + 
            (
              (
                (seca(${dot.seca}) / speed(${vessel['full_sp' + suff]}) + ((seca(${dot.seca}) / speed(${vessel['full_sp' + suff]})) * weather_factor(${weather_factor}))/100) / 24
              ) * mgo(${vessel['full_mgo' + suff]}) * fuel_2(${fuel_2})
            )
          `);
          console.log(`route full rows result(${dot.full_result}) = 
            (
              (${(dot.dist / vessel['full_sp' + suff] + ((dot.dist / vessel['full_sp' + suff]) * weather_factor)/100) / 24}) 
              * 
              (${vessel.hire - (vessel.hire * add_com)/100})
            )
            + 
            (
              (
                ${((dot.dist - dot.seca) / vessel['full_sp' + suff] + (((dot.dist - dot.seca) / vessel['full_sp' + suff]) * weather_factor)/100) / 24}
              ) 
              * 
              ${vessel['full_ifo' + suff] * fuel_1}
            ) 
            + 
            (
              (
                ${(dot.seca / vessel['full_sp' + suff] + ((dot.seca / vessel['full_sp' + suff]) * weather_factor)/100) / 24}
              ) 
              * 
              ${vessel['full_mgo' + suff] * fuel_2}
            )
          `);
          console.log(`route full rows 2 result(${dot.full_result}) = 
            (${((dot.dist / vessel['full_sp' + suff] + ((dot.dist / vessel['full_sp' + suff]) * weather_factor)/100) / 24) * (vessel.hire - (vessel.hire * add_com)/100)})
            + 
            (${(((dot.dist - dot.seca) / vessel['full_sp' + suff] + (((dot.dist - dot.seca) / vessel['full_sp' + suff]) * weather_factor)/100) / 24)*vessel['full_ifo' + suff] * fuel_1}) 
            + 
            (${((dot.seca / vessel['full_sp' + suff] + ((dot.seca / vessel['full_sp' + suff]) * weather_factor)/100) / 24)*vessel['full_mgo' + suff] * fuel_2})
          `);
        dot.eco_result = this.getOneResult(
            dot.dist,
            dot.seca,
            vessel['eco_sp' + suff],
            weather_factor,
            vessel.hire,
            add_com,
            vessel['eco_ifo' + suff],
            vessel['eco_mgo' + suff],
            fuel_1,
            fuel_2
        )
        dot.slow_result = this.getOneResult(
            dot.dist,
            dot.seca,
            vessel['slow_sp' + suff],
            weather_factor,
            vessel.hire,
            add_com,
            vessel['slow_ifo' + suff],
            vessel['slow_mgo' + suff],
            fuel_1,
            fuel_2
        )

        if (dot.eco_result < dot.full_result) {
          dot.optimal_result = dot.eco_result
          dot.optimal_speed = vessel['eco_sp' + suff]
          dot.is_full = false
          dot.is_eco = true
        } else {
          dot.optimal_result = dot.full_result
          dot.optimal_speed = vessel['full_sp' + suff]
          dot.is_full = true
          dot.is_eco = false
        }
      })
      
      vessel.full_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.full_result, 0
      )
      vessel.eco_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.eco_result, 0
      )
      vessel.slow_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.slow_result, 0
      )
      vessel.optimal_result = routes.reduce(
        (accumulator, currentValue) => accumulator + currentValue.optimal_result, 0
      )
      vessel.full_result = vessel.full_result ? Math.round(vessel.full_result*10000)/10000 : 0
      vessel.eco_result = vessel.full_result ? Math.round(vessel.eco_result*10000)/10000 : 0
      vessel.slow_result = vessel.slow_result ? Math.round(vessel.slow_result*10000)/10000 : 0
      vessel.optimal_result = vessel.optimal_result ? Math.round(vessel.optimal_result*10000)/10000 : 0
    },
    getOneResult(dist = 0, seca = 0, speed = 0, weather_factor = 0, hire = 0, add_com = 0, ifo = 0, mgo = 0, fuel_1 = 0, fuel_2 = 0) {
      let result = 0
      
      result = 
        (
          (
            (dist / speed + ((dist / speed) * weather_factor)/100) / 24
          ) 
          * 
          (
            hire - (hire * add_com)/100
          )
        )
        + 
        (
          (
            ((dist - seca) / speed + (((dist - seca) / speed) * weather_factor)/100) / 24
          ) 
          * 
          ifo * fuel_1
        ) 
        + 
        (
          (
            (seca / speed + ((seca / speed) * weather_factor)/100) / 24
          ) 
          * 
          mgo * fuel_2
        );

      //result = result ? Math.round(result*10000)/10000 : 0
      result = result ? result : 0
      return result
    },

    setIfoMgoForRoutes() {
      this.vessels.forEach(vessel=>{
        this.setIfoMgoForRoutesInVessel(vessel)
      })
    },
    setIfoMgoForRoutesInVessel(vessel) {
      let weather_factor = this.activeTab?.weather_factor ? this.activeTab?.weather_factor : 0

      let routes = vessel.additional_info.routes
      routes.forEach(dot=>{
        let cargo = this.cargos.find(el => el.id == dot.cargo)

        let suff = dot.intake ? '_lad' : '_ball'
        let wkSuff
        if(cargo){
          wkSuff = cargo[dot.status+'_wk_cranes'] ? '_wk' : '_idle'
        }else{
          wkSuff = '_idle'
        }

        let port_stay = 0
        if(dot.port_stay){
          port_stay = dot.port_stay.split(':')
          let minutes = port_stay[1]/60
          port_stay = (+port_stay[0] + minutes)/24
        }
        // console.log('setIfoMgoForRoutes - port_stay:', port_stay);
        // console.log(`setIfoMgoForRoutes - vessel: ${vessel.id}, ${vessel.name}, dwt(${vessel.dwt}), weight = full_ifo${suff}`);

        dot.full_ifo_mt = this.getOneIfoOrMgo(dot.dist, vessel['full_sp'+suff], weather_factor, vessel['full_ifo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        // console.log(`full ifo(${dot.full_ifo_mt}): (dist(${dot.dist}) / (speed(${vessel['full_sp'+suff]}) - (speed(${vessel['full_sp'+suff]}) * weather_factor(${weather_factor}))/100) / 24 * weight(${vessel['full_ifo'+suff]})) + (port_stay(${port_stay}) * (consump_1(${vessel['ifo'+wkSuff]}) + consump_2(${vessel['mgo'+wkSuff]})))`);
        
        dot.full_mgo_mt = this.getOneIfoOrMgo(dot.dist, vessel['full_sp'+suff], weather_factor, vessel['full_mgo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        // console.log(`full mgo(${dot.full_mgo_mt}): (dist(${dot.dist}) / (speed(${vessel['full_sp'+suff]}) - (speed(${vessel['full_sp'+suff]}) * weather_factor(${weather_factor}))/100) / 24 * weight(${vessel['full_mgo'+suff]})) + (port_stay(${port_stay}) * (consump_1(${vessel['ifo'+wkSuff]}) + consump_2(${vessel['mgo'+wkSuff]})))`);

        dot.eco_ifo_mt = this.getOneIfoOrMgo(dot.dist, vessel['eco_sp'+suff], weather_factor, vessel['eco_ifo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        dot.eco_mgo_mt = this.getOneIfoOrMgo(dot.dist, vessel['eco_sp'+suff], weather_factor, vessel['eco_mgo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        dot.slow_ifo_mt = this.getOneIfoOrMgo(dot.dist, vessel['slow_sp'+suff], weather_factor, vessel['slow_ifo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        dot.slow_mgo_mt = this.getOneIfoOrMgo(dot.dist, vessel['slow_sp'+suff], weather_factor, vessel['slow_mgo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        
        let prefix = dot.is_full ? 'full_' : 'eco_'
        dot.optimal_ifo_mt = this.getOneIfoOrMgo(dot.dist, vessel[prefix+'sp'+suff], weather_factor, vessel[prefix+'ifo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
        dot.optimal_mgo_mt = this.getOneIfoOrMgo(dot.dist, vessel[prefix+'sp'+suff], weather_factor, vessel[prefix+'mgo'+suff], port_stay, vessel['ifo'+wkSuff], vessel['mgo'+wkSuff])
      })
    },
    getOneIfoOrMgo(dist=0, speed=0, weather_factor=0, weight=0, port_stay=0, consump_1=0, consump_2=0) {
      let result = 0

      result = (dist / (speed - (speed * weather_factor)/100) / 24 * weight) + (port_stay * (consump_1 + consump_2))

      result = result ? result : 0
      return result
    },

    setHireAndFreightBep() {
      this.vessels.forEach(vessel=>{
        this.setHireAndFreightBepInVessel(vessel)
      })
    },
    setHireAndFreightBepInVessel(vessel) {
      let vlsfo = this.activeTab?.vlsfo ? this.activeTab.vlsfo : 0
      let lsmgo = this.activeTab?.lsmgo ? this.activeTab.lsmgo : 0
      let hsfo = this.activeTab?.hsfo ? this.activeTab.hsfo : 0
      let totalIntake = 0
      let totalDisb = 0
      let totalIncome = 0

      let full_ifo_total = 0
      let eco_ifo_total = 0
      let slow_ifo_total = 0
      let optimal_ifo_total = 0
      let full_mgo_total = 0
      let eco_mgo_total = 0
      let slow_mgo_total = 0
      let optimal_mgo_total = 0

      console.log(`setHireBep - vessel: ${vessel.id}, ${vessel.name}, dwt(${vessel.dwt})`);

      let routes = vessel.additional_info.routes
      routes.forEach(dot=>{
        totalIntake += dot.intake
        totalDisb += dot.disbursement
        full_ifo_total += dot.full_ifo_mt
        full_mgo_total += dot.full_mgo_mt
        eco_ifo_total += dot.eco_ifo_mt
        eco_mgo_total += dot.eco_mgo_mt
        slow_ifo_total += dot.slow_ifo_mt
        slow_mgo_total += dot.slow_mgo_mt
        optimal_ifo_total += dot.optimal_ifo_mt
        optimal_mgo_total += dot.optimal_mgo_mt

        if(!dot.cargo) return false
        let cargo = this.cargos.find(el => el.id == dot.cargo)
        totalIncome += (cargo.freight * dot.intake) - (cargo.freight * dot.intake)*cargo.cg_comm/100
        console.log(`setHireBep: income in route(${cargo.freight * dot.intake - (cargo.freight * dot.intake)*cargo.cg_comm/100}) cargo.freight(${cargo.freight}) * dot.intake(${dot.intake}) - cargo.cg_comm%(${(cargo.freight * dot.intake)*cargo.cg_comm/100})`);
      })

      let hire = vessel.hire ? vessel.hire : 0
      let expenses = +vessel.expenses + +vessel.mins_cost + +vessel.insurance
      console.log(`setHireBep: expenses(${expenses}) = +vessel.expenses(${vessel.expenses}) + +vessel.mins_cost(${vessel.mins_cost}) + +vessel.insurance(${vessel.insurance})`);
      let full_consumed = full_ifo_total * (vessel.scrubber ? hsfo : vlsfo) * full_mgo_total * lsmgo
      console.log(`setHireBep: full_consumed(${full_consumed}) = full_ifo_total(${full_ifo_total}) * (vessel.scrubber(${vessel.scrubber}) ? hsfo(${hsfo}) : vlsfo(${vlsfo})) * full_mgo_total(${full_mgo_total}) * lsmgo(${lsmgo})`);
      let eco_consumed = eco_ifo_total * (vessel.scrubber ? hsfo : vlsfo) * eco_mgo_total * lsmgo
      let slow_consumed = slow_ifo_total * (vessel.scrubber ? hsfo : vlsfo) * slow_mgo_total * lsmgo
      let optimal_consumed = optimal_ifo_total * (vessel.scrubber ? hsfo : vlsfo) * optimal_mgo_total * lsmgo

      vessel.full_hire = totalIncome - (full_consumed + totalDisb + expenses) / vessel.full_voyage_days
      vessel.full_hire = vessel.full_hire ? Math.round(vessel.full_hire*10000)/10000 : 0
      console.log(`setHireBep: full_hire(${vessel.full_hire}) = totalIncome(${totalIncome}) - (full_consumed(${full_consumed}) + totalDisb(${totalDisb}) + expenses(${expenses})) / vessel.full_voyage_days(${vessel.full_voyage_days})`);
      vessel.eco_hire = totalIncome - (eco_consumed + totalDisb + expenses) / vessel.eco_voyage_days
      vessel.eco_hire = vessel.eco_hire ? Math.round(vessel.eco_hire*10000)/10000 : 0
      vessel.slow_hire = totalIncome - (slow_consumed + totalDisb + expenses) / vessel.slow_voyage_days
      vessel.slow_hire = vessel.slow_hire ? Math.round(vessel.slow_hire*10000)/10000 : 0
      vessel.optimal_hire = totalIncome - (optimal_consumed + totalDisb + expenses) / vessel.optimal_voyage_days
      vessel.optimal_hire = vessel.optimal_hire ? Math.round(vessel.optimal_hire*10000)/10000 : 0

      vessel.full_freight = (hire + full_consumed + totalDisb + expenses) / totalIntake
      vessel.full_freight = vessel.full_freight ? Math.round(vessel.full_freight*10000)/10000 : 0
      vessel.eco_freight = (hire + eco_consumed + totalDisb + expenses) / totalIntake
      vessel.eco_freight = vessel.eco_freight ? Math.round(vessel.eco_freight*10000)/10000 : 0
      vessel.slow_freight = (hire + slow_consumed + totalDisb + expenses) / totalIntake
      vessel.slow_freight = vessel.slow_freight ? Math.round(vessel.slow_freight*10000)/10000 : 0
      vessel.optimal_freight = (hire + optimal_consumed + totalDisb + expenses) / totalIntake
      vessel.optimal_freight = vessel.optimal_freight ? Math.round(vessel.optimal_freight*10000)/10000 : 0
    },

    setVoyageDays() {
      this.vessels.forEach(vessel=>{
        this.setVoyageDaysInVessel(vessel)
      })
    },
    setVoyageDaysInVessel(vessel) {
      let routes = vessel.additional_info.routes
      this.fuelList.forEach(fuel => {
        let voyage = 0
        routes.forEach(dot=>{
          if(dot[fuel+'_streaming_days']) voyage += dot[fuel+'_streaming_days']
          if(dot[fuel+'_streaming_time']){
            let time = dot[fuel+'_streaming_time'].split(':')
            voyage += +time[0]/24
            voyage += +time[1]/24/60
          }
          if(dot.port_stay){
            let time = dot.port_stay.split(':')
            voyage += +time[0]/24
            voyage += +time[1]/24/60
          }
        })
        voyage = voyage ? Math.round(voyage*10000)/10000 : 0
        vessel[fuel+'_voyage_days'] = voyage
      })
    },
    async renderNew() {
      this.vessels = await this.axiosGetCalcVessel(this.$route.params.id)
      this.cargos = await this.axiosGetCalcCargo(this.$route.params.id)
      this.checkIfCargoExist()
    },
  },

  mounted() {
    this.setActiveFuelType(this.activeFuel)
  },
  async beforeMount() {


    // if(this.vessels.length){
    //   if(localStorage.getItem('lastActiveVesselInCalc')){
    //     let findItem = this.vessels.find(el=>el.id == localStorage.getItem('lastActiveVesselInCalc'))
    //     if(findItem)
    //       this.onSelect(findItem.id)
    //     else
    //       this.onSelect(this.vessels[0].id)
    //   }else
    //     this.onSelect(this.vessels[0].id)
    // }
  }
}
</script>

<style lang="scss">
.calc_vessels__row_vessels{
  &>div{
    &:nth-child(1) {width: 32.2%;}
    &:nth-child(2) {width: 24.4%;}
    &:nth-child(3) {width: 42.4%;}
  }
}
.calc_vessels__row_vessels__speed{
  display: grid;
  grid-template-columns: repeat(3, 24.2%) 27.4%;
  .colspan-1-3{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .colspan-3-5{
    grid-column-start: 3;
    grid-column-end: 5;
  }
}
.calc_vessels__dot_row{
  display: grid;
  grid-template-columns: 1.8% 1.8% 6% 14.48% 7.55% 4.6% 4.5% 4.6% 6.2% 3.1% 4.33% 5.32% 3.9% 5% 5.32% 3.9% 3.1% 2.1% 2.1% 4.1% 4.3% 1.9%;
  .colspan-1-3{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .colspan-3-9{
    grid-column-start: 3;
    grid-column-end: 9;
  }
  .colspan-10-12{
    grid-column-start: 10;
    grid-column-end: 12;
  }
  .colspan-11-13{
    grid-column-start: 11;
    grid-column-end: 13;
  }
  .colspan-15-17{
    grid-column-start: 15;
    grid-column-end: 17;
  }
  .colspan-15-20{
    grid-column-start: 15;
    grid-column-end: 20;
  }
  >div:not(:last-child){
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-gray-text);
  }
  .multiselect__tags {
    min-height: 28px;
    padding-top: 4px;
    padding-right: 20px;
    border: 0;
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
  }
  .async_select__wrap .multiselect__single {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .multiselect, 
  .multiselect__input, 
  .multiselect__single{
    font-size: 14px;
  }
  .multiselect__input, 
  .multiselect__single{
    margin-bottom: 4px;
  }
}
.calc_vessels__list_row{
  display: grid;
  grid-template-columns: 48.44% 12.1% 9.77% 23.44% 6.25%;
  >div:not(:last-child){
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-gray-text);
  }
}
</style>

<style scoped lang="scss">
.shadow-table {
  position: relative;
}
.blocked-changes {
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>