import axios from "axios";
import QueryString from "querystring";

export default {
    actions: {

        axiosGetMailboxById(ctx, id) {
            return axios.get(process.env.VUE_APP_URL + '/mailbox/mails/'+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res.data
            })
        },
        async axiosGetMaillingForObject(ctx, data) {
            return axios.get(process.env.VUE_APP_URL + '/mailbox/mails/', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: params => {
                    return QueryString.stringify(params)
                },
                params: {
                    ...data.params,
                    ordering: '-created_at'
                },
            }).then(res=> {
                return res.data
            })
        },
        async axiosUnPinMessageFromObject(ctx, data) {
            return await axios.patch(process.env.VUE_APP_URL + '/mailbox/mails/'+data.id+'/untying', data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            })
        },
        async axiosGetMailById(ctx, id) {
            return await axios.get(process.env.VUE_APP_URL + "/mailbox/mails/"+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res.data
            }).catch(()=> {
                return false
            })
        },
        async axiosAttachMessageToVessel(ctx, data) {
            return await axios.patch(process.env.VUE_APP_URL+'/mailbox/mails/update/bulk', data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res
            }).catch(()=> {
                return false
            })
        },
        // async axiosAttachMessageToPorts(ctx, data) {
        //     return await axios.patch(process.env.VUE_APP_URL+'/mailbox/mails/update/bulk', data, {
        //         headers: {
        //             'Authorization': 'token ' + ctx.getters.getToken,
        //         },
        //     }).then(res=> {
        //         return res
        //     }).catch(()=> {
        //         return false
        //     })
        // },
        axiosGetMailingSent(ctx, data) {
            let ct = axios.CancelToken.source();
            ctx.commit("cancelMailingTemplates")
            ctx.commit("cancelTokenSourceMailingTemplate", ct);
          return axios.get(process.env.VUE_APP_URL+'/mailing', {
              cancelToken: ct.token,
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              },
              params: {
                  limit: 20,
                  search: data.search,
                  ordering: data.ordering,
              }
          }).then(res=> {
              console.log('res.data.results', res.data.results)
              ctx.state.maillings = res.data.results
              ctx.state.maillingsNext = res.data.next
          })
        },
        async axiosGetMailingSentById(ctx, id) {
            return await axios.get(process.env.VUE_APP_URL+'/mailing/'+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res.data;
            })
        },
        axiosGetNextMailing(ctx) {
            return axios.get(ctx.state.maillingsNext, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                ctx.state.maillings = ctx.state.maillings.concat(res.data.results)
                ctx.state.maillingsNext = res.data.next
            })
        },
        axiosGetMailingTemplates(ctx, data) {
            let ct = axios.CancelToken.source();
            ctx.commit("cancelMailingTemplates")
            ctx.commit("cancelTokenSourceMailingTemplate", ct);
            return axios.get(process.env.VUE_APP_URL+'/mailing/templates/', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                cancelToken: ct.token,
                params: {
                    is_archived: data.is_archived,
                    limit: 20,
                    search: data.search,
                    ordering: data.ordering,
                }
            }).then(res=> {
                ctx.state.templatesMailing = res.data.results
                ctx.state.templatesMailingNext = res.data.next
            })
        },
        axiosGetNextMailingTemplates(ctx) {
            return axios.get(ctx.state.templatesMailingNext, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                ctx.state.templatesMailing = ctx.state.templatesMailing.concat(res.data.results)
                ctx.state.templatesMailingNext = res.data.next
            })
        },
        axiosGetMailingContactList(ctx, data) {
            let ct = axios.CancelToken.source();
            ctx.commit("cancelMailingTemplates")
            ctx.commit("cancelTokenSourceMailingTemplate", ct);
            return axios.get(process.env.VUE_APP_URL+'/mailing/contacts/', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                cancelToken: ct.token,
                params: {
                    is_archived: data.is_archived,
                    limit: 20,
                    search: data.search,
                    ordering: data.ordering,
                },

            }).then(res=> {
                // console.log('res', res, res.data)
                ctx.state.contacts = res.data.results
                ctx.state.contactsNext = res.data.next
                console.log(ctx.state.contactsNext)
            })
        },
        axiosGetNextMailingContacts(ctx) {
            return axios.get(ctx.state.contactsNext, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                ctx.state.contacts = ctx.state.contacts.concat(res.data.results)
                ctx.state.contactsNext = res.data.next
            })
        },
        axiosGetMailingContacts(ctx) {
            return axios.get(process.env.VUE_APP_URL+"/mailing/contacts/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
                params: {
                    is_archived: false,
                }
            }).then(res=> {
                ctx.state.contactsList = res.data.results
                console.log('axiosGetMailingContacts', res.data)
            })
        },
        async axiosCreateMailingTemplate(ctx, data) {
            let filesId = []
            if(data.files.length) {
                for (let i = 0; i < data.files.length; i++) {
                    let file = data.files[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
                    filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
                }
            }
            data.files = filesId
            return await axios.post(process.env.VUE_APP_URL+'/mailing/templates/', data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
            })
        },
        async axiosChangeMailingTemplate(ctx, data) {
            let filesId = data.filesIds
            if(data.files.length) {
                for (let i = 0; i < data.files.length; i++) {
                    let file = data.files[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
                    filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
                }
            }
            data.files = filesId
            return await axios.put(process.env.VUE_APP_URL+'/mailing/templates/' + data.id, data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
            })
        },
        async axiosCreateMailing(ctx, data) {
            let filesId = data.filesIds
            if(data.files.length) {
                for (let i = 0; i < data.files.length; i++) {
                    let file = data.files[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
                    filesId.push(await ctx.dispatch('axiosAddFile', formData, {root: true}))
                }
            }
            data.files = filesId
            return await axios.post(process.env.VUE_APP_URL+'/mailing/', data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
            }).then(()=> {
                ctx.state.emitSent++
            })
        },
        async axiosDeleteMailingTemplate(ctx, id) {
            ctx.state.templatesMailing = ctx.state.templatesMailing.filter(a=> a.id !== id)
            // return await axios.delete(process.env.VUE_APP_URL+'/mailing/templates/'+id, {
            //     headers: {
            //         'Authorization': 'token ' + ctx.getters.getToken
            //     },
            // })
            return null
        },
        async axiosRealDeleteMailingTemplate(ctx, id) {
            return await axios.delete(process.env.VUE_APP_URL+'/mailing/templates/'+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
            }).then(()=> {
                ctx.state.templatesMailing = ctx.state.templatesMailing.filter(a=> a.id !== id)
                return true
            }).catch(()=> {
                return false
            })
        },
        async axiosDeleteMailingContact(ctx, id) {
            ctx.state.contacts = ctx.state.contacts.filter(a=> a.id !== id)
            // return await axios.delete(process.env.VUE_APP_URL+'/mailing/contacts/'+id, {
            //     headers: {
            //         'Authorization': 'token ' + ctx.getters.getToken
            //     },
            // })
        },
        async axiosDeleteRealMailingContact(ctx, id) {
            return await axios.delete(process.env.VUE_APP_URL+'/mailing/contacts/'+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
            }).then(()=> {
                ctx.state.contacts = ctx.state.contacts.filter(a=> a.id !== id)
                return true
            }).catch(()=> {
                return false
            })
        },
        axiosCreateContactList(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+'/mailing/contacts', data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
            })
        },
        axiosChangeContactList(ctx, data) {
            return axios.put(process.env.VUE_APP_URL+'/mailing/contacts/'+data.id, data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
            })
        },
        async axiosGetContactById(ctx, id) {
            console.log('getcont', id)
            return await axios.get(process.env.VUE_APP_URL+'/mailing/contacts/'+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                },
            }).then(res=> {
                return res.data;
            })
        }
    },
    mutations: {
        cancelMailingTemplates(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
            }
        },
        cancelTokenSourceMailingTemplate(state, data) {
            state.cancelTokenSource = data;
        }
    },
    state: {
        contactsNext: null,
        cancelTokenSource: null,
        contactsList: [],
        contacts: [],
        templatesMailing: [],
        templatesMailingNext: null,
        maillings: [],
        maillingsNext: null,
        emitSent: -3742384,
    },
    getters: {

    }
}