<template>

  <div class="" v-on:click="setIdFileForFocus(null)">
    <div v-if="getFolders.length || getFiles.length">
      <p class="fz14 fw500 text-gray mt16" v-if="!hideFolders">Folders</p>
      <div class="folders-list mt6 d-flex flex-wrap position-relative l-16" v-if="!listView && !hideFolders">
        <div class="position-relative file-item mt8 border-radius-8 w120 pt32 d-flex flex-column align-items-center cursor-pointer" v-for="(item, i) in getFolders" :key="i">
          <div class="click-zone w100Proc l0imp position-absolute t0 h100Proc box-border" v-on:click="openFolder(item)"></div>

          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M54.9646 24.5874C54.167 23.2712 52.7575 22.4844 51.1943 22.4844H16.3869C14.6616 22.4844 13.0923 23.4797 12.388 25.0209L3.18164 44.7754C3.5292 46.222 4.84937 47.3026 6.4218 47.3026H43.4542C45.0375 47.3026 46.4846 46.3863 47.1917 44.9365L55.1492 28.6139C55.7774 27.3223 55.7078 25.8172 54.9646 24.5874Z" fill="#9AA3B0"/>
            <path d="M10.5905 22.6985C11.2929 21.1177 12.8598 20.096 14.5826 20.096H47.3029V15.6942C47.3029 13.8796 45.8475 12.4031 44.0587 12.4031H24.9798C24.9518 12.4031 24.9327 12.3955 24.9255 12.39L21.5106 7.36804C20.9058 6.47715 19.9069 5.94531 18.8397 5.94531H6.42661C4.63704 5.94531 3.18164 7.42185 3.18164 9.23641V39.0362L10.5905 22.6985Z" fill="#BDCBD6"/>
          </svg>
          <p class="fz11 text-dark text-dots-overflow text-folder w100Proc box-border text-align-center">{{item.name}}</p>
          <div class="checkbox-block position-absolute w-max h24" v-on:click="getPermissionSelect ? selectFolder(item) : null" :class="{'d-block': selectedFolders.map(a=> {return a.id}).indexOf(item.id) >= 0}">
            <svg v-if="selectedFolders.map(a=> {return a.id}).indexOf(item.id) === -1 && getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.55556 1.24444C2.27916 1.24444 1.24444 2.27916 1.24444 3.55556V12.4444C1.24444 13.7208 2.27916 14.7556 3.55556 14.7556H12.4444C13.7208 14.7556 14.7556 13.7208 14.7556 12.4444V3.55556C14.7556 2.27916 13.7208 1.24444 12.4444 1.24444H3.55556ZM0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#9AA3B0"/>
            </svg>
            <svg v-else-if="getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#094172"/>
              <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="4" fill="#EEF2F8"/>
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M4 1.4C2.56406 1.4 1.4 2.56406 1.4 4V14C1.4 15.4359 2.56406 16.6 4 16.6H14C15.4359 16.6 16.6 15.4359 16.6 14V4C16.6 2.56406 15.4359 1.4 14 1.4H4ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#9AA3B0"/>
            </svg>

          </div>
          <div class="favorite position-absolute w-max h24" :class="{'d-block': item.is_important}" v-on:click="true ? changeFavorite(item) : null" v-if="$route.path.indexOf('archive') == -1 && ''+$route.params.files_is_archive !== 'true' && ''+$route.params.is_archive_files !== 'true'">
            <svg v-if="!item.is_important" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.2131 10.2377L19.2129 10.2377L14.5638 9.81564L14.1476 9.77785L13.9834 9.39347L12.1465 5.09299C12.1465 5.0929 12.1464 5.09281 12.1464 5.09273C12.1207 5.03314 12.0645 4.99687 12.0019 4.99687C11.9387 4.99687 11.8834 5.03347 11.8586 5.09151L11.8585 5.09184L10.0211 9.39347L9.85693 9.77786L9.44066 9.81564L4.79185 10.2376C4.79173 10.2376 4.79162 10.2376 4.7915 10.2377C4.73003 10.2435 4.67708 10.2848 4.65683 10.3456C4.63752 10.406 4.65587 10.471 4.70256 10.512C4.70258 10.512 4.7026 10.512 4.70261 10.512L8.21693 13.5936L8.53034 13.8684L8.43805 14.2749L7.402 18.838C7.40198 18.8381 7.40197 18.8382 7.40195 18.8382C7.38766 18.9019 7.4123 18.9642 7.46216 19.0003L7.46355 19.0013C7.49059 19.0209 7.52162 19.0306 7.55403 19.0306C7.5814 19.0306 7.60858 19.0235 7.63351 19.0086L7.63371 19.0085L11.6426 16.6114L12.0019 16.3966L12.3612 16.6114L16.3691 19.0084C16.423 19.0406 16.49 19.0377 16.541 19.0007L16.5416 19.0003C16.5915 18.9642 16.6161 18.9018 16.6017 18.838L15.5657 14.2749L15.4734 13.8684L15.7868 13.5936L19.301 10.5121L19.2131 10.2377ZM19.2131 10.2377C19.2745 10.2433 19.3271 10.2846 19.3467 10.3449M19.2131 10.2377L19.3467 10.3449M19.3467 10.3449L20.0124 10.1284L19.3467 10.3449Z" stroke="#9AA3B0" stroke-width="1.4"/>
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.0124 10.1284C19.9063 9.80231 19.6171 9.57143 19.2762 9.54059L14.6271 9.11851L12.7898 4.81688C12.6541 4.50085 12.3454 4.29688 12.0019 4.29688C11.6583 4.29688 11.3495 4.50085 11.2147 4.81688L9.37738 9.11851L4.72752 9.54059C4.38667 9.57204 4.09803 9.80292 3.99137 10.1284C3.88533 10.4545 3.98326 10.8122 4.24106 11.0383L7.75542 14.1199L6.71921 18.6837C6.6434 19.0193 6.77364 19.3663 7.05208 19.5676C7.20174 19.6763 7.37758 19.7306 7.55403 19.7306C7.70565 19.7306 7.85741 19.6903 7.99294 19.6092L12.0019 17.2122L16.0101 19.6092C16.3041 19.785 16.6738 19.7689 16.9517 19.5676C17.2301 19.3663 17.3603 19.0193 17.2845 18.6837L16.2483 14.1199L19.7627 11.0383C20.0204 10.8122 20.1184 10.4553 20.0124 10.1284Z" fill="#FF833E"/>
            </svg>
          </div>
          <div class="favorite position-absolute w-max h24" v-else v-on:click="deleteForeverModalShow = item">
            <svg class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/>
            </svg>
          </div>
          <div class="dop-option position-absolute z-100 w-max h24" v-if="$route.path.indexOf('shared-with-me') == -1">
            <svg v-on:click="unarchive(item)" v-if="$route.path.indexOf('archive')>= 0 || ''+$route.params.files_is_archive == 'true' || ''+$route.params.is_archive_files == 'true'" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
              <path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#9AA3B0"/>
            </svg>
            <folders-option-file v-else @moveTo="moveTo(item)" @archive="itemArchive = item" @download="downloadFile(item)" @editName="itemEditName = item" @clickOpenAccess="showModalOpenAccess(item)"></folders-option-file>
          </div>
        </div>
      </div>
      <div class="files-list mt6 d-flex flex-column" v-else-if="!hideFolders">
        <div class="list-item list-title d-flex align-items-stretch" v-if="getFolders.length">
          <div class="checkbox-block d-flex align-items-center justify-center h24" v-on:click="getPermissionSelect ? selectFolderAll({items: getFolders, bool: hasAll(selectedFolders, getFolders)}) : null">
            <svg v-if="hasAll(selectedFolders, getFolders) && getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.55556 1.24444C2.27916 1.24444 1.24444 2.27916 1.24444 3.55556V12.4444C1.24444 13.7208 2.27916 14.7556 3.55556 14.7556H12.4444C13.7208 14.7556 14.7556 13.7208 14.7556 12.4444V3.55556C14.7556 2.27916 13.7208 1.24444 12.4444 1.24444H3.55556ZM0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#9AA3B0"/>
            </svg>
            <svg v-else-if="getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#094172"/>
              <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="4" fill="#EEF2F8"/>
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M4 1.4C2.56406 1.4 1.4 2.56406 1.4 4V14C1.4 15.4359 2.56406 16.6 4 16.6H14C15.4359 16.6 16.6 15.4359 16.6 14V4C16.6 2.56406 15.4359 1.4 14 1.4H4ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#9AA3B0"/>
            </svg>
          </div>
          <div class="name d-flex align-items-center">
            <p class="text-gray fz12">Name</p>
          </div>
          <div class="open-access d-flex align-items-center"  v-if="$route.path.indexOf('payment') === -1">
            <p class="text-gray fz12">Open access for</p>
          </div>
          <div class="last-update d-flex align-items-center">
            <p class="text-gray fz12">Last updated</p>
          </div>
          <div class="dop">

          </div>
        </div>
        <div class="list-item d-flex align-items-stretch border-color-normal position-relative" :class="{'border-top': true, 'bgc-focus': item.id == idFileForFocus}" v-for="(item, i) in getFolders" :key="i">
          <div class="click-zone position-absolute t0  h100Proc box-border" v-on:click="openFolder(item)"></div>
          <div class="checkbox-block position-relative z-10 d-flex align-items-center justify-center" v-on:click="getPermissionSelect ? selectFolder(item) : null">
            <svg v-if="selectedFolders.map(a=> {return a.id}).indexOf(item.id) === -1 && getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.55556 1.24444C2.27916 1.24444 1.24444 2.27916 1.24444 3.55556V12.4444C1.24444 13.7208 2.27916 14.7556 3.55556 14.7556H12.4444C13.7208 14.7556 14.7556 13.7208 14.7556 12.4444V3.55556C14.7556 2.27916 13.7208 1.24444 12.4444 1.24444H3.55556ZM0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#9AA3B0"/>
            </svg>
            <svg v-else-if="getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#094172"/>
              <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="4" fill="#EEF2F8"/>
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M4 1.4C2.56406 1.4 1.4 2.56406 1.4 4V14C1.4 15.4359 2.56406 16.6 4 16.6H14C15.4359 16.6 16.6 15.4359 16.6 14V4C16.6 2.56406 15.4359 1.4 14 1.4H4ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#9AA3B0"/>
            </svg>
          </div>
          <div class="name d-flex align-items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.7784 10.7626C21.4892 10.2853 20.9781 10 20.4112 10H7.78874C7.16305 10 6.59398 10.361 6.33857 10.9199L3 18.0836C3.12604 18.6081 3.60478 19 4.175 19H17.6043C18.1785 19 18.7033 18.6677 18.9597 18.142L21.8454 12.2228C22.0732 11.7544 22.0479 11.2086 21.7784 10.7626V10.7626Z" fill="#9AA3B0"/>
              <path d="M5.68674 10.0753C5.94143 9.50206 6.50965 9.13157 7.13441 9.13157H19V7.53529C19 6.87727 18.4722 6.34182 17.8235 6.34182H10.9048C10.8947 6.34182 10.8877 6.33908 10.8851 6.33709L9.64677 4.51593C9.42745 4.19286 9.06521 4 8.67818 4H4.17675C3.52778 4 3 4.53545 3 5.19347V16L5.68674 10.0753Z" fill="#BDCBD6"/>
            </svg>
            <p class="fz14 ml4 text-dark">{{item.name}}</p>

          </div>
          <div class="open-access d-flex align-items-center " v-if="$route.path.indexOf('payment') === -1">
            <!--                <p class="fz14 text-dark">John Doe</p>-->
            <folders-selected-employees class="position-relative z-10" v-model="item.shared_with"></folders-selected-employees>
          </div>
          <div class="last-update d-flex align-items-center">
            <p class="fz14 text-dark">{{item.updated_at | formatDateAndTimeSpace2}}</p>
          </div>
          <div class="dop d-flex align-items-center justify-between pr8 box-border">
            <div class="favorite w-max h24" v-on:click="true ? changeFavorite(item) : null" v-if="$route.path.indexOf('archive') == -1 && ''+$route.params.files_is_archive !== 'true' && ''+$route.params.is_archive_files !== 'true'">
              <svg v-if="!item.is_important" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2131 10.2377L19.2129 10.2377L14.5638 9.81564L14.1476 9.77785L13.9834 9.39347L12.1465 5.09299C12.1465 5.0929 12.1464 5.09281 12.1464 5.09273C12.1207 5.03314 12.0645 4.99687 12.0019 4.99687C11.9387 4.99687 11.8834 5.03347 11.8586 5.09151L11.8585 5.09184L10.0211 9.39347L9.85693 9.77786L9.44066 9.81564L4.79185 10.2376C4.79173 10.2376 4.79162 10.2376 4.7915 10.2377C4.73003 10.2435 4.67708 10.2848 4.65683 10.3456C4.63752 10.406 4.65587 10.471 4.70256 10.512C4.70258 10.512 4.7026 10.512 4.70261 10.512L8.21693 13.5936L8.53034 13.8684L8.43805 14.2749L7.402 18.838C7.40198 18.8381 7.40197 18.8382 7.40195 18.8382C7.38766 18.9019 7.4123 18.9642 7.46216 19.0003L7.46355 19.0013C7.49059 19.0209 7.52162 19.0306 7.55403 19.0306C7.5814 19.0306 7.60858 19.0235 7.63351 19.0086L7.63371 19.0085L11.6426 16.6114L12.0019 16.3966L12.3612 16.6114L16.3691 19.0084C16.423 19.0406 16.49 19.0377 16.541 19.0007L16.5416 19.0003C16.5915 18.9642 16.6161 18.9018 16.6017 18.838L15.5657 14.2749L15.4734 13.8684L15.7868 13.5936L19.301 10.5121L19.2131 10.2377ZM19.2131 10.2377C19.2745 10.2433 19.3271 10.2846 19.3467 10.3449M19.2131 10.2377L19.3467 10.3449M19.3467 10.3449L20.0124 10.1284L19.3467 10.3449Z" stroke="#9AA3B0" stroke-width="1.4"/>
              </svg>
              <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0124 10.1284C19.9063 9.80231 19.6171 9.57143 19.2762 9.54059L14.6271 9.11851L12.7898 4.81688C12.6541 4.50085 12.3454 4.29688 12.0019 4.29688C11.6583 4.29688 11.3495 4.50085 11.2147 4.81688L9.37738 9.11851L4.72752 9.54059C4.38667 9.57204 4.09803 9.80292 3.99137 10.1284C3.88533 10.4545 3.98326 10.8122 4.24106 11.0383L7.75542 14.1199L6.71921 18.6837C6.6434 19.0193 6.77364 19.3663 7.05208 19.5676C7.20174 19.6763 7.37758 19.7306 7.55403 19.7306C7.70565 19.7306 7.85741 19.6903 7.99294 19.6092L12.0019 17.2122L16.0101 19.6092C16.3041 19.785 16.6738 19.7689 16.9517 19.5676C17.2301 19.3663 17.3603 19.0193 17.2845 18.6837L16.2483 14.1199L19.7627 11.0383C20.0204 10.8122 20.1184 10.4553 20.0124 10.1284Z" fill="#FF833E"/>
              </svg>

            </div>
            <div class="delete w-max h24" v-else v-on:click="deleteForeverModalShow = item">
              <svg class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/>
              </svg>
            </div>
            <div class="dop-option w-max h24" v-if="$route.path.indexOf('shared-with-me') == -1">
              <svg v-on:click="unarchive(item)" v-if="$route.path.indexOf('archive')>= 0 || ''+$route.params.files_is_archive == 'true' || ''+$route.params.is_archive_files == 'true'" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
                <path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#9AA3B0"/>
              </svg>
              <folders-option-file v-else @moveTo="moveTo(item)" @archive="itemArchive = item" @download="downloadFile(item)" @editName="itemEditName = item" @clickOpenAccess="showModalOpenAccess(item)"></folders-option-file>
            </div>
          </div>
        </div>
      </div>
      <p class="fz14 fw500 text-gray mt8">Files</p>
      <div class="files-list-tile mt6 d-flex flex-wrap position-relative l-16" v-if="!listView">
        <div class="position-relative file-item mt8 border-radius-8 w120 pt32 d-flex flex-column align-items-center cursor-pointer" v-for="(item, i) in getFiles" :key="i">
          <div class="click-zone position-absolute t0 h100Proc box-border" v-on:click="openFileWatcher(i)"></div>

          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.66699 56H48.3337C49.9905 56 51.3337 54.6569 51.3337 53V15.1667L36.167 0H7.66699C6.01014 0 4.66699 1.34314 4.66699 3V53C4.66699 54.6569 6.01014 56 7.66699 56Z" fill="#DEE3EA"/>
            <path d="M19.776 36.8839L27.4068 14V32.9831C27.4068 33.2519 27.2503 33.4957 27.0069 33.606L19.776 36.8839Z" fill="#9AA3B0"/>
            <path d="M36.2246 36.8839L28.5938 14V32.9831C28.5938 33.2519 28.7503 33.4957 28.9937 33.606L36.2246 36.8839Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4371 20.1511C19.5875 20.9323 14.2649 26.7881 14.2649 33.8954C14.2649 36.293 14.8702 38.5471 15.9352 40.5122L27.1082 35.1427V36.0819L15.5895 41.6213L15.3936 41.283C14.1369 39.1124 13.417 36.5879 13.417 33.8954C13.417 26.3488 19.0683 20.1322 26.3417 19.3026L26.4371 20.1511ZM41.7358 33.8954C41.7358 26.7881 36.4132 20.9323 29.5636 20.1511L29.659 19.3026C36.9323 20.1322 42.5837 26.3488 42.5837 33.8954C42.5837 36.5879 41.8638 39.1124 40.607 41.283L40.4089 41.6253L28.8925 36.098L28.8805 35.1427L40.0677 40.5081C41.1313 38.5439 41.7358 36.2913 41.7358 33.8954Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1082 39.1176L17.4572 43.8354L17.0886 43.0664L27.1082 38.1569V39.1176Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1082 41.8626L19.4392 45.6632L19.0728 44.8931L27.1082 40.9234V41.8626Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1082 44.5951L21.3046 47.5417L20.938 46.7717L27.1082 43.6558V44.5951Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.8925 39.1176L38.8123 43.7841L39.1809 43.0151L28.8925 38.1569V39.1176Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.8925 41.8626L37.0329 45.6632L37.3992 44.8931L28.8925 40.9234V41.8626Z" fill="#9AA3B0"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.8925 44.5951L35.1675 47.5417L35.534 46.7717L28.8925 43.6558V44.5951Z" fill="#9AA3B0"/>
            <path d="M51.334 15.1641L39.1673 15.1641C37.5105 15.1641 36.1673 13.8209 36.1673 12.1641L36.1673 -0.00260295L43.0284 6.97406L51.334 15.1641Z" fill="#BDCBD6"/>
          </svg>
          <p class="fz11 text-dark text-dots-overflow text-folder w100Proc box-border text-align-center">{{item.name}}</p>
          <p class="fz10 text-gray" v-if="item.size">{{item.size}}</p>

          <div class="checkbox-block position-absolute w-max h24" v-on:click="getPermissionSelect ? selectFile(item) : null">
            <svg v-if="selectedFiles.map(a=> {return a.id}).indexOf(item.id) === -1 && getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.55556 1.24444C2.27916 1.24444 1.24444 2.27916 1.24444 3.55556V12.4444C1.24444 13.7208 2.27916 14.7556 3.55556 14.7556H12.4444C13.7208 14.7556 14.7556 13.7208 14.7556 12.4444V3.55556C14.7556 2.27916 13.7208 1.24444 12.4444 1.24444H3.55556ZM0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#9AA3B0"/>
            </svg>
            <svg v-else-if="getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#094172"/>
              <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="4" fill="#EEF2F8"/>
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M4 1.4C2.56406 1.4 1.4 2.56406 1.4 4V14C1.4 15.4359 2.56406 16.6 4 16.6H14C15.4359 16.6 16.6 15.4359 16.6 14V4C16.6 2.56406 15.4359 1.4 14 1.4H4ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#9AA3B0"/>
            </svg>
          </div>
          <div class="favorite position-absolute w-max h24" v-on:click="true ? changeFavorite(item) : null" v-if="$route.path.indexOf('archive') == -1 && ''+$route.params.files_is_archive !== 'true' && ''+$route.params.is_archive_files !== 'true'">
            <svg v-if="!item.is_important" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.2131 10.2377L19.2129 10.2377L14.5638 9.81564L14.1476 9.77785L13.9834 9.39347L12.1465 5.09299C12.1465 5.0929 12.1464 5.09281 12.1464 5.09273C12.1207 5.03314 12.0645 4.99687 12.0019 4.99687C11.9387 4.99687 11.8834 5.03347 11.8586 5.09151L11.8585 5.09184L10.0211 9.39347L9.85693 9.77786L9.44066 9.81564L4.79185 10.2376C4.79173 10.2376 4.79162 10.2376 4.7915 10.2377C4.73003 10.2435 4.67708 10.2848 4.65683 10.3456C4.63752 10.406 4.65587 10.471 4.70256 10.512C4.70258 10.512 4.7026 10.512 4.70261 10.512L8.21693 13.5936L8.53034 13.8684L8.43805 14.2749L7.402 18.838C7.40198 18.8381 7.40197 18.8382 7.40195 18.8382C7.38766 18.9019 7.4123 18.9642 7.46216 19.0003L7.46355 19.0013C7.49059 19.0209 7.52162 19.0306 7.55403 19.0306C7.5814 19.0306 7.60858 19.0235 7.63351 19.0086L7.63371 19.0085L11.6426 16.6114L12.0019 16.3966L12.3612 16.6114L16.3691 19.0084C16.423 19.0406 16.49 19.0377 16.541 19.0007L16.5416 19.0003C16.5915 18.9642 16.6161 18.9018 16.6017 18.838L15.5657 14.2749L15.4734 13.8684L15.7868 13.5936L19.301 10.5121L19.2131 10.2377ZM19.2131 10.2377C19.2745 10.2433 19.3271 10.2846 19.3467 10.3449M19.2131 10.2377L19.3467 10.3449M19.3467 10.3449L20.0124 10.1284L19.3467 10.3449Z" stroke="#9AA3B0" stroke-width="1.4"/>
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.0124 10.1284C19.9063 9.80231 19.6171 9.57143 19.2762 9.54059L14.6271 9.11851L12.7898 4.81688C12.6541 4.50085 12.3454 4.29688 12.0019 4.29688C11.6583 4.29688 11.3495 4.50085 11.2147 4.81688L9.37738 9.11851L4.72752 9.54059C4.38667 9.57204 4.09803 9.80292 3.99137 10.1284C3.88533 10.4545 3.98326 10.8122 4.24106 11.0383L7.75542 14.1199L6.71921 18.6837C6.6434 19.0193 6.77364 19.3663 7.05208 19.5676C7.20174 19.6763 7.37758 19.7306 7.55403 19.7306C7.70565 19.7306 7.85741 19.6903 7.99294 19.6092L12.0019 17.2122L16.0101 19.6092C16.3041 19.785 16.6738 19.7689 16.9517 19.5676C17.2301 19.3663 17.3603 19.0193 17.2845 18.6837L16.2483 14.1199L19.7627 11.0383C20.0204 10.8122 20.1184 10.4553 20.0124 10.1284Z" fill="#FF833E"/>
            </svg>
          </div>
          <div class="favorite position-absolute w-max h24" v-else v-on:click="deleteForeverModalShow = item">
            <svg class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/>
            </svg>
          </div>
          <div class="dop-option position-absolute z-100 w-max h24" v-if="$route.path.indexOf('shared-with-me') == -1">
            <svg v-on:click="unarchive(item)" v-if="$route.path.indexOf('archive')>= 0 || ''+$route.params.files_is_archive == 'true' || ''+$route.params.is_archive_files == 'true'" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
              <path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#9AA3B0"/>
            </svg>
            <folders-option-file v-else @moveTo="moveTo(item)" @archive="itemArchive = item" @download="downloadFile(item)" @editName="itemEditName = item" @clickOpenAccess="showModalOpenAccess(item)"></folders-option-file>
          </div>



        </div>
      </div>
      <div class="files-list mt6 d-flex flex-column" v-else>
        <div class="list-item list-title d-flex align-items-stretch" v-if="getFiles.length">
          <div class="checkbox-block d-flex align-items-center justify-center h24" v-on:click="getPermissionSelect ? selectFileAll({items: getFiles, bool: hasAll(selectedFiles, getFiles)}) : null">
            <svg v-if="hasAll(selectedFiles, getFiles) && getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.55556 1.24444C2.27916 1.24444 1.24444 2.27916 1.24444 3.55556V12.4444C1.24444 13.7208 2.27916 14.7556 3.55556 14.7556H12.4444C13.7208 14.7556 14.7556 13.7208 14.7556 12.4444V3.55556C14.7556 2.27916 13.7208 1.24444 12.4444 1.24444H3.55556ZM0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#9AA3B0"/>
            </svg>
            <svg v-else-if="getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#094172"/>
              <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="4" fill="#EEF2F8"/>
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M4 1.4C2.56406 1.4 1.4 2.56406 1.4 4V14C1.4 15.4359 2.56406 16.6 4 16.6H14C15.4359 16.6 16.6 15.4359 16.6 14V4C16.6 2.56406 15.4359 1.4 14 1.4H4ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#9AA3B0"/>
            </svg>
          </div>
          <div class="name d-flex align-items-center">
            <p class="text-gray fz12">Name</p>
          </div>
          <div class="open-access d-flex align-items-center" v-if="$route.path.indexOf('payment') === -1">
            <p class="text-gray fz12">Open access for</p>
          </div>
          <div class="last-update d-flex align-items-center">
            <p class="text-gray fz12">Last updated</p>
          </div>
          <div class="dop">

          </div>
        </div>
        <div class="list-item d-flex align-items-stretch border-color-normal position-relative" :class="{'border-top': true, 'bgc-focus': item.id == idFileForFocus}" v-for="(item, i) in getFiles" :key="i">
          <div class="click-zone position-absolute t0 h100Proc box-border" v-on:click="openFileWatcher(i)"></div>

          <div class="checkbox-block d-flex align-items-center justify-center position-relative z-10" v-on:click="getPermissionSelect ? selectFile(item) : null">
            <svg v-if="selectedFiles.map(a=> {return a.id}).indexOf(item.id) === -1 && getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.55556 1.24444C2.27916 1.24444 1.24444 2.27916 1.24444 3.55556V12.4444C1.24444 13.7208 2.27916 14.7556 3.55556 14.7556H12.4444C13.7208 14.7556 14.7556 13.7208 14.7556 12.4444V3.55556C14.7556 2.27916 13.7208 1.24444 12.4444 1.24444H3.55556ZM0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#9AA3B0"/>
            </svg>
            <svg v-else-if="getPermissionSelect" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#094172"/>
              <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="18" height="18" rx="4" fill="#EEF2F8"/>
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M4 1.4C2.56406 1.4 1.4 2.56406 1.4 4V14C1.4 15.4359 2.56406 16.6 4 16.6H14C15.4359 16.6 16.6 15.4359 16.6 14V4C16.6 2.56406 15.4359 1.4 14 1.4H4ZM0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z" fill="#9AA3B0"/>
            </svg>

          </div>
          <div class="name d-flex align-items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.11111 20H17.8889C18.5025 20 19 19.5224 19 18.9333V10L13 4H6.11111C5.49746 4 5 4.47756 5 5.06667V18.9333C5 19.5224 5.49746 20 6.11111 20Z" fill="#BDCBD6"/>
              <path d="M19 10L14.4286 10C13.6396 10 13 9.36041 13 8.57143L13 4L15.7143 6.76L19 10Z" fill="#9AA3B0"/>
            </svg>
            <p class="fz14 ml4 text-dark">{{item.name}}</p>

          </div>
          <div class="open-access d-flex align-items-center" v-if="$route.path.indexOf('payment') === -1">
            <!--                <p class="fz14 text-dark">John Doe</p>-->
            <folders-selected-employees class="position-relative z-10" v-model="item.shared_with"></folders-selected-employees>
          </div>
          <div class="last-update d-flex align-items-center">
            <p class="fz14 text-dark">{{item.updated_at | formatDateAndTimeSpace2}}</p>
          </div>
          <div class="dop d-flex align-items-center justify-between pr8 box-border">
            <div class="favorite w-max h24 cursor-pointer" v-on:click="true ? changeFavorite(item) : null" v-if="$route.path.indexOf('archive') == -1 && ''+$route.params.files_is_archive !== 'true' && ''+$route.params.is_archive_files !== 'true'">
              <svg v-if="!item.is_important" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2131 10.2377L19.2129 10.2377L14.5638 9.81564L14.1476 9.77785L13.9834 9.39347L12.1465 5.09299C12.1465 5.0929 12.1464 5.09281 12.1464 5.09273C12.1207 5.03314 12.0645 4.99687 12.0019 4.99687C11.9387 4.99687 11.8834 5.03347 11.8586 5.09151L11.8585 5.09184L10.0211 9.39347L9.85693 9.77786L9.44066 9.81564L4.79185 10.2376C4.79173 10.2376 4.79162 10.2376 4.7915 10.2377C4.73003 10.2435 4.67708 10.2848 4.65683 10.3456C4.63752 10.406 4.65587 10.471 4.70256 10.512C4.70258 10.512 4.7026 10.512 4.70261 10.512L8.21693 13.5936L8.53034 13.8684L8.43805 14.2749L7.402 18.838C7.40198 18.8381 7.40197 18.8382 7.40195 18.8382C7.38766 18.9019 7.4123 18.9642 7.46216 19.0003L7.46355 19.0013C7.49059 19.0209 7.52162 19.0306 7.55403 19.0306C7.5814 19.0306 7.60858 19.0235 7.63351 19.0086L7.63371 19.0085L11.6426 16.6114L12.0019 16.3966L12.3612 16.6114L16.3691 19.0084C16.423 19.0406 16.49 19.0377 16.541 19.0007L16.5416 19.0003C16.5915 18.9642 16.6161 18.9018 16.6017 18.838L15.5657 14.2749L15.4734 13.8684L15.7868 13.5936L19.301 10.5121L19.2131 10.2377ZM19.2131 10.2377C19.2745 10.2433 19.3271 10.2846 19.3467 10.3449M19.2131 10.2377L19.3467 10.3449M19.3467 10.3449L20.0124 10.1284L19.3467 10.3449Z" stroke="#9AA3B0" stroke-width="1.4"/>
              </svg>
              <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0124 10.1284C19.9063 9.80231 19.6171 9.57143 19.2762 9.54059L14.6271 9.11851L12.7898 4.81688C12.6541 4.50085 12.3454 4.29688 12.0019 4.29688C11.6583 4.29688 11.3495 4.50085 11.2147 4.81688L9.37738 9.11851L4.72752 9.54059C4.38667 9.57204 4.09803 9.80292 3.99137 10.1284C3.88533 10.4545 3.98326 10.8122 4.24106 11.0383L7.75542 14.1199L6.71921 18.6837C6.6434 19.0193 6.77364 19.3663 7.05208 19.5676C7.20174 19.6763 7.37758 19.7306 7.55403 19.7306C7.70565 19.7306 7.85741 19.6903 7.99294 19.6092L12.0019 17.2122L16.0101 19.6092C16.3041 19.785 16.6738 19.7689 16.9517 19.5676C17.2301 19.3663 17.3603 19.0193 17.2845 18.6837L16.2483 14.1199L19.7627 11.0383C20.0204 10.8122 20.1184 10.4553 20.0124 10.1284Z" fill="#FF833E"/>
              </svg>
            </div>
            <div class="delete w-max h24" v-else v-on:click="deleteForeverModalShow = item">
              <svg class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#9AA3B0"/>
              </svg>
            </div>
            <div class="dop-option w-max h24" v-if="$route.path.indexOf('shared-with-me') == -1">
              <svg v-on:click="unarchive(item)" v-if="$route.path.indexOf('archive')>= 0 || ''+$route.params.files_is_archive == 'true' || ''+$route.params.is_archive_files == 'true'" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
                <path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#9AA3B0"/>
              </svg>
              <folders-option-file v-else @moveTo="moveTo(item)" @archive="itemArchive = item" @download="downloadFile(item)" @editName="itemEditName = item" @clickOpenAccess="showModalOpenAccess(item)"></folders-option-file>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-files d-flex align-items-center flex-column justify-center" v-if="!search && !getFolders.length && !getFiles.length" :class="{'no-files-local': localMode, 'no-files-short': noFilesShort}">
      <svg width="140" height="120" viewBox="0 0 140 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M124.83 55.2682C123.108 52.4257 120.064 50.7266 116.688 50.7266H41.518C37.7919 50.7266 34.403 52.8762 32.8819 56.2045L15.0649 94.4352C13.7278 97.3043 13.111 100.962 15.6379 102.868C16.8479 103.781 18.3592 104.324 19.9973 104.324H99.9726C103.392 104.324 106.517 102.345 108.044 99.2141L125.229 63.964C126.586 61.1745 126.435 57.924 124.83 55.2682Z" stroke="#BDCBD6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M108.284 42.5599V36.0536C108.284 32.1349 105.141 28.9462 101.278 28.9462H60.0754C60.015 28.9462 59.9735 28.9298 59.958 28.918L52.5833 18.0725C51.2772 16.1486 49.1199 15 46.8151 15H20.0078C16.1431 15 13 18.1887 13 22.1075V83.4632" stroke="#BDCBD6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p class="fz18 fw500 text-dark mt16">Files not added yet</p>
    </div>
    <div class="no-files d-flex align-items-center flex-column justify-center" v-else-if="search.length" :class="{'no-files-local': localMode, 'no-files-short': noFilesShort}">
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M158.5 36C136.685 36 119 53.6848 119 75.5C119 97.3152 136.685 115 158.5 115C170.662 115 181.54 109.505 188.787 100.858C194.538 93.9961 198 85.1536 198 75.5C198 53.6848 180.315 36 158.5 36ZM117 75.5C117 52.5802 135.58 34 158.5 34C181.42 34 200 52.5802 200 75.5C200 85.3113 196.594 94.3295 190.901 101.433L214.702 125.234L214.707 125.239C215.098 125.63 215.098 126.263 214.707 126.653C214.317 127.044 213.683 127.044 213.293 126.653L213.288 126.648L189.609 102.969C182.006 111.572 170.886 117 158.5 117C135.58 117 117 98.4198 117 75.5Z" fill="#DEE3EA"/>
        <path d="M40.4095 81.467C40.6053 81.3598 40.8517 81.2176 41.0197 81.068C42.6159 79.6466 44.7272 79.6414 46.3293 81.068C47.3861 82.009 48.7519 82.009 49.8086 81.068C50.5928 80.3698 51.5107 80.0007 52.4634 80.0007C53.4161 80.0007 54.3341 80.3698 55.1182 81.068C56.1751 82.009 57.5408 82.009 58.5976 81.068C60.1938 79.6467 62.305 79.6414 63.9071 81.068C64.0597 81.2038 64.2784 81.3444 64.5757 81.498C64.9426 81.6875 65.102 82.1722 64.9318 82.5806C64.7615 82.989 64.3261 83.1665 63.9591 82.977C63.5482 82.7648 63.2409 82.5628 62.992 82.3411C61.9352 81.4001 60.5695 81.4 59.5126 82.3411C57.9164 83.7625 55.8052 83.7677 54.2031 82.3411C53.1463 81.4001 51.7806 81.4 50.7237 82.3411C49.1274 83.7625 47.0162 83.7677 45.4141 82.3411C44.3573 81.4001 42.9916 81.4 41.9347 82.3411C41.6575 82.588 41.3348 82.7774 41.0557 82.9302C40.6927 83.1289 40.2537 82.9623 40.0753 82.5583C39.8969 82.1542 40.0464 81.6657 40.4095 81.467Z" fill="#DEE3EA"/>
        <path d="M48.4095 88.467C48.6053 88.3598 48.8517 88.2176 49.0197 88.068C50.6159 86.6466 52.7272 86.6414 54.3293 88.068C55.3861 89.009 56.7519 89.009 57.8086 88.068C58.5928 87.3698 59.5107 87.0007 60.4634 87.0007C61.4161 87.0007 62.3341 87.3698 63.1182 88.068C64.1751 89.009 65.5408 89.009 66.5976 88.068C68.1938 86.6467 70.305 86.6414 71.9071 88.068C72.0597 88.2038 72.2784 88.3444 72.5757 88.498C72.9426 88.6875 73.102 89.1722 72.9318 89.5806C72.7615 89.989 72.3261 90.1665 71.9591 89.977C71.5482 89.7648 71.2409 89.5628 70.992 89.3411C69.9352 88.4001 68.5695 88.4 67.5126 89.3411C65.9164 90.7625 63.8052 90.7677 62.2031 89.3411C61.1463 88.4001 59.7806 88.4 58.7237 89.3411C57.1274 90.7625 55.0162 90.7677 53.4141 89.3411C52.3573 88.4001 50.9916 88.4 49.9347 89.3411C49.6575 89.588 49.3348 89.7774 49.0557 89.9302C48.6927 90.1289 48.2537 89.9623 48.0753 89.5583C47.8969 89.1542 48.0464 88.6657 48.4095 88.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M111 127.2C111.663 127.2 112.2 126.663 112.2 126C112.2 125.337 111.663 124.8 111 124.8C110.337 124.8 109.8 125.337 109.8 126C109.8 126.663 110.337 127.2 111 127.2ZM111 129C112.657 129 114 127.657 114 126C114 124.343 112.657 123 111 123C109.343 123 108 124.343 108 126C108 127.657 109.343 129 111 129Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M207 19.2C207.663 19.2 208.2 18.6627 208.2 18C208.2 17.3373 207.663 16.8 207 16.8C206.337 16.8 205.8 17.3373 205.8 18C205.8 18.6627 206.337 19.2 207 19.2ZM207 21C208.657 21 210 19.6569 210 18C210 16.3431 208.657 15 207 15C205.343 15 204 16.3431 204 18C204 19.6569 205.343 21 207 21Z" fill="#DEE3EA"/>
        <path d="M221.409 55.467C221.605 55.3598 221.852 55.2176 222.02 55.068C223.616 53.6466 225.727 53.6414 227.329 55.068C228.386 56.009 229.752 56.009 230.809 55.068C231.593 54.3698 232.511 54.0007 233.463 54.0007C234.416 54.0007 235.334 54.3698 236.118 55.068C237.175 56.009 238.541 56.009 239.598 55.068C241.194 53.6467 243.305 53.6414 244.907 55.068C245.06 55.2038 245.278 55.3444 245.576 55.498C245.943 55.6875 246.102 56.1722 245.932 56.5806C245.762 56.989 245.326 57.1665 244.959 56.977C244.548 56.7648 244.241 56.5628 243.992 56.3411C242.935 55.4001 241.57 55.4 240.513 56.3411C238.916 57.7625 236.805 57.7677 235.203 56.3411C234.146 55.4001 232.781 55.4 231.724 56.3411C230.127 57.7625 228.016 57.7677 226.414 56.3411C225.357 55.4001 223.992 55.4 222.935 56.3411C222.657 56.588 222.335 56.7774 222.056 56.9302C221.693 57.1289 221.254 56.9623 221.075 56.5583C220.897 56.1542 221.046 55.6657 221.409 55.467Z" fill="#DEE3EA"/>
        <path d="M230.409 61.467C230.605 61.3598 230.852 61.2176 231.02 61.068C232.616 59.6466 234.727 59.6414 236.329 61.068C237.386 62.009 238.752 62.009 239.809 61.068C240.593 60.3698 241.511 60.0007 242.463 60.0007C243.416 60.0007 244.334 60.3698 245.118 61.068C246.175 62.009 247.541 62.009 248.598 61.068C250.194 59.6467 252.305 59.6414 253.907 61.068C254.06 61.2038 254.278 61.3444 254.576 61.498C254.943 61.6875 255.102 62.1722 254.932 62.5806C254.762 62.989 254.326 63.1665 253.959 62.977C253.548 62.7648 253.241 62.5628 252.992 62.3411C251.935 61.4001 250.57 61.4 249.513 62.3411C247.916 63.7625 245.805 63.7677 244.203 62.3411C243.146 61.4001 241.781 61.4 240.724 62.3411C239.127 63.7625 237.016 63.7677 235.414 62.3411C234.357 61.4001 232.992 61.4 231.935 62.3411C231.657 62.588 231.335 62.7774 231.056 62.9302C230.693 63.1289 230.254 62.9623 230.075 62.5583C229.897 62.1542 230.046 61.6657 230.409 61.467Z" fill="#DEE3EA"/>
        <path d="M260.409 99.467C260.605 99.3598 260.852 99.2176 261.02 99.068C262.616 97.6466 264.727 97.6414 266.329 99.068C267.386 100.009 268.752 100.009 269.809 99.068C270.593 98.3698 271.511 98.0007 272.463 98.0007C273.416 98.0007 274.334 98.3698 275.118 99.068C276.175 100.009 277.541 100.009 278.598 99.068C280.194 97.6467 282.305 97.6414 283.907 99.068C284.06 99.2038 284.278 99.3444 284.576 99.498C284.943 99.6875 285.102 100.172 284.932 100.581C284.762 100.989 284.326 101.167 283.959 100.977C283.548 100.765 283.241 100.563 282.992 100.341C281.935 99.4001 280.57 99.4 279.513 100.341C277.916 101.762 275.805 101.768 274.203 100.341C273.146 99.4001 271.781 99.4 270.724 100.341C269.127 101.762 267.016 101.768 265.414 100.341C264.357 99.4001 262.992 99.4 261.935 100.341C261.657 100.588 261.335 100.777 261.056 100.93C260.693 101.129 260.254 100.962 260.075 100.558C259.897 100.154 260.046 99.6657 260.409 99.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M73 46.2C74.7673 46.2 76.2 44.7673 76.2 43C76.2 41.2327 74.7673 39.8 73 39.8C71.2327 39.8 69.8 41.2327 69.8 43C69.8 44.7673 71.2327 46.2 73 46.2ZM73 48C75.7614 48 78 45.7614 78 43C78 40.2386 75.7614 38 73 38C70.2386 38 68 40.2386 68 43C68 45.7614 70.2386 48 73 48Z" fill="#DEE3EA"/>
      </svg>
      <p class="fz18 fw500 text-dark mt16">No results were found for your search</p>
    </div>
    <folders-modal-edit-name @close="itemEditName=null" :item="itemEditName" v-if="itemEditName"></folders-modal-edit-name>
    <folders-modal-archive @close="itemArchive = null" v-if="itemArchive" :items="[itemArchive]"></folders-modal-archive>
    <folders-modal-delete @close="deleteForeverModalShow = null" :items="[deleteForeverModalShow]" v-if="deleteForeverModalShow"></folders-modal-delete>
    <file-viewer ref="fileWatch" :isEdit="false" :files="getFilesForWatch"></file-viewer>
  </div>
</template>

<script>
import FoldersOptionFile from "@/components/folders/folders-option-file";
import {mapActions, mapMutations, mapState} from "vuex";
import FoldersSelectedEmployees from "@/components/folders/foldersSelectedEmployees";
import FoldersModalEditName from "@/components/folders/folders-modal-edit-name";
import JSZip from "jszip";
import axios from "axios";
import FoldersModalArchive from "@/components/folders/folders-lists/folders-modal-archive";
import FoldersModalDelete from "@/components/folders/folders-modal-delete";
import FileViewer from "@/components/counterparties/counterpartiesUser/fileViewer";
export default {
  name: "folders-list-file-and-folder",
  components: {
    FileViewer,
    FoldersModalDelete,
    FoldersModalArchive, FoldersModalEditName, FoldersSelectedEmployees, FoldersOptionFile},
  props: {
    noFilesShort: Boolean,
    localMode: Boolean,
    localFiles: null,
    localFolders: null,
    hideFolders: Boolean,
    sort: String,
    getPermissionSelect: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      listView: state=> state.folders.listView,
      selectedFolders: state=> state.folders.selectedFolders,
      selectedFiles: state=> state.folders.selectedFiles,
      files: state=> state.folders.files,
      folders: state=> state.folders.folders,
      search: state=> state.folders.search,
      idFileForFocus: state=> state.folders.idFileForFocus,
    }),
    getFilesForWatch() {
      let array = JSON.parse(JSON.stringify(this.files))
      array.forEach(item=> {
        item['file'] = this.getUrlForFile(item.url)
      })
      return array
    },
    getFiles() {
      if(this.localFiles && this.localMode) {
        return this.localFiles
      }
      return this.files
    },
    getFolders() {
      if(this.localFolders && this.localMode) {
        return this.localFolders
      }
      return this.folders
    },
    getNameRoute() {
      if(this.$route.path.indexOf('payment') >= 0 && this.$route.path.indexOf('counterparties') >= 0) {
        return  'Folders - Counterparties Options Payment Detail Path'
      }
      if(this.$route.path.indexOf('my-files')>=0) {
        if(this.$route.path.indexOf('archive')>=0) {
          return 'Folders - My files Folder Archive'
        } else {
          return 'Folders - My files Folder'
        }
      }
      if(this.$route.path.indexOf('shared-with-me') >= 0) {
        if(this.$route.path.indexOf('archive')>=0) {
          return 'Folders - Shared with me Folder Archive'
        } else {
          return 'Folders - Shared with me Folder'
        }
      }
      if(this.$route.path.indexOf('a-payment')>=0) {
        if(this.$route.path.indexOf('archive')>=0) {
          return 'Folders - Payments Archive Folders Path Archive'
        } else {
          return 'Folders - Payments Folders Path Archive'
        }
      }
      if(this.$route.path.indexOf('payment')>=0) {
        if(this.$route.path.indexOf('archive')>=0) {
          return 'Folders - Simple payment and archive folders - path'
        } else {
          return 'Folders - Payments Folders Path'
        }
      }
      if(this.$route.path.indexOf('tasks') >= 0 && this.$route.path.indexOf('tasks-a') === -1) {
        if(this.$route.path.indexOf('manually') >= 0) {
          return this.$route.path.indexOf('archive') >= 0 ? 'Folders - Tasks Manually Files Archive Path' : 'Folders - Tasks Manually Files Path'
        }
        if(this.$route.path.indexOf('vessel') >= 0) {
          return this.$route.path.indexOf('archive') >= 0 ? 'Folders - Tasks Vessel Files Archive Path' : 'Folders - Tasks Vessel Files Path'
        }
        if(this.$route.path.indexOf('cargo') >= 0) {
          return this.$route.path.indexOf('archive') >= 0 ? 'Folders - Tasks Cargo Files Archive Path' : 'Folders - Tasks Cargo Files Path'
        }
      }
      if(this.$route.path.indexOf('tasks-a') >= 0) {
        if(this.$route.path.indexOf('manually') >= 0) {
          return this.$route.path.indexOf('archive') >= 0 ? 'Folders - Tasks Archive Manually Files Archive Path' : 'Folders - Tasks Archive Manually Files Path'
        }
        if(this.$route.path.indexOf('vessel') >= 0) {
          return this.$route.path.indexOf('archive') >= 0 ? 'Folders - Tasks Archive Vessel Files Archive Path' : 'Folders - Tasks Archive Vessel Files Path'
        }
        if(this.$route.path.indexOf('cargo') >= 0) {
          return this.$route.path.indexOf('archive') >= 0 ? 'Folders - Tasks Archive Cargo Files Archive Path' : 'Folders - Tasks Archive Cargo Files Path'
        }
      }
      if(this.$route.path.indexOf('cash-flow') >= 0) {
        return 'Folders - Vessels Option CashFlow Files Path'
      }
      if(this.$route.path.indexOf('acc-invoice') >= 0) {
        return 'Folders - Vessels Option Acc Invoice Files Path'
      }
      if(this.$route.path.indexOf('vessel-detail') >= 0) {
        return 'Folders - Vessels Option Vessel Detail Path'
      }
      if(this.$route.path.indexOf('option/charterers') >= 0) {
        return 'Folders - Vessels Option Charterers Files Path'
      }
      if((this.$route.path.indexOf('options/details') >= 0 && this.$route.path.indexOf('port') >= 0)) {
        return 'Folders - Ports Countries Ports Options Details Path'
      }
      if(this.$route.name === 'Folders - Counterparties Options Detail' || this.$route.name === 'Folders - Counterparties Options Detail Path') {
        return 'Folders - Counterparties Options Detail Path'
      }
      if(this.$route.name === 'Folders - Counterparties Options Payment Detail') {
        return 'Folders - Counterparties Options Payment Detail Path'
      }
      if(this.$route.name == 'Folders - Important' || this.$route.name == 'Folders - Important Path') {
        return 'Folders - Important Path'
      }
      return  ''
    },
  },
  data() {
    return {
      deleteForeverModalShow: null,
      itemArchive: null,
      filesLocal: [],
      itemEditName: null,
    }
  },
  watch: {
    files: function () {
      this.filesLocal = JSON.parse(JSON.stringify(this.files))
    }
  },
  methods: {
    ...mapActions(['axiosGetFtpDownload', 'axiosPutFileForFolders', 'axiosDownloadFolder']),
    ...mapMutations(['setIdFileForFocus', 'setFoldersForMoveTo', 'selectFile', 'selectFolder', 'selectFolderAll', 'selectFileAll', 'showModalOpenAccess']),
    getUrlForFile(link) {
      if((''+link).indexOf(this.getURL()) >= 0) {
        return link;
      }
      if((''+link).indexOf('/media/') >= 0) {
        return this.getURL() + link;
      }
      if((''+link).indexOf('media/') >= 0) {
        return this.getURL() +"/" + link;
      }
      return this.getURL() +"/media/" + link;
    },
    openFileWatcher(index) {
      this.$refs.fileWatch.showViewFileModal(index)
    },
    unarchive(item) {
      this.setFoldersForMoveTo([item])
    },
    moveTo(item) {
      this.setFoldersForMoveTo([item])
    },
    async downloadFile (file) {
      if(file.file_type === 'FOLDER') {
        let id = [];
        id.push(file.id)
        let response = await this.axiosGetFtpDownload([id])
        // let content = JSZip.folder(response.data, 'zip')
        JSZip.loadAsync(response.data).then(res=> {

          res.generateAsync({type:"blob"})
              .then(function(content) {
                // see FileSaver.js
                // (content, "example.zip");
                console.log(content)
                let link1 = document.createElement("a");
                link1.href = window.URL.createObjectURL(content);
                link1.download = file.name;
                link1.click();
              });
        })


        // this.download( response.data, 'application/zip', file.name)
      } else {
        console.log(file)
        axios.get(this.getUrlLink(file.url), { responseType: 'blob' })
            .then(response => {
              console.log(response)
              const blob = new Blob([response.data], { type: 'application/plain' })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = file.name;
              link.click()
              URL.revokeObjectURL(link.href)
            }).catch(console.error)
      }

    },
    getUrlLink(link) {
      if(link && link.indexOf(process.env.VUE_APP_URL_SIMPLE)>=0) {
        return link;
      }
      return this.getURL()+ link
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
    openFolder(item) {
      this.$emit('open', item)
      console.log(1)
      if(this.$route.path.indexOf('my-files') >= 0 || this.$route.path.indexOf('shared-with-me') >= 0 || this.$route.path.indexOf('payment') >= 0 || this.$route.path.indexOf('tasks') >= 0 || this.$route.path.indexOf('cash-flow') >= 0 || this.$route.path.indexOf('acc-invoice') >= 0 || this.$route.path.indexOf('vessel-detail') >= 0 || this.$route.path.indexOf('option/charterers') >= 0 || (this.$route.path.indexOf('options/details') >= 0 && this.$route.path.indexOf('port') >= 0) || (this.$route.path.indexOf('options/detail') >= 0 && this.$route.path.indexOf('counterparties') >= 0)) {
        console.log(2)
        let path = this.$route.params.path ? JSON.parse(this.$route.params.path) : []
        path.push({name: item.name, id: item.id})
        let params = {path: JSON.stringify(path)}
        console.log(3)
        if(this.$route.path.indexOf('payment') >= 0 || this.$route.path.indexOf('tasks') >= 0 || this.$route.path.indexOf('cash-flow') >= 0 || this.$route.path.indexOf('acc-invoice') >= 0 || this.$route.path.indexOf('vessel-detail') >= 0) {
          if(this.$route.path.indexOf('vessel-option') >= 0) {
            params['detailVessel'] = this.$route.params.detailVessel
          } else if(this.$route.path.indexOf('option/charterers') >= 0) {
            params['detailVessel'] = this.$route.params.charterersDetail
          } else {
            params['detailData'] = this.$route.params.detailData
          }
        }
        this.$router.push({name: this.getNameRoute, params: params})
      }
    },
    hasAll(arr1, arr2){
      // return arr1.map(a=> {return a.id}).every((e) => arr2.includes(e.id));
      arr2 = arr2.map(a=> {return a.id})
      return !(arr1.filter(a=> arr2.indexOf(a.id) >= 0).length === arr2.length)
    },
    changeFavorite(item) {
      item.is_important = !item.is_important
      // let form = JSON.parse(JSON.stringify(item))
      // delete form.id
      // delete form.user
      let form = {
        is_important: item.is_important
      }
      // form.shared_with = form.shared_with.map(a=> {return a.id})
      this.axiosPutFileForFolders({data: form, id: item.id})
    }
  }
}
</script>

<style scoped lang="scss">
.no-files {
  height: calc(100vh - 300px)
}
.no-files-local {
  height: 250px
}
.text-folder {
  padding: 8px 11px 11px 11px;
}


.files-list-tile, .folders-list {
  &>* {
    margin-left: 16px;
  }
  .checkbox-block {
    display: none;
    top: 8px;
    left: 8px;
  }
  .favorite {
    display: none;
    top: 4px;
  }
  .dop-option {
    display: none;
    position: absolute;
    right: 8px;
    top: 4px;
  }
}
.file-item {
  background-color: var(--color-white-dark);
  &:hover {
    background-color: var(--color-modal);
    .checkbox-block, .favorite, .dop-option {
      display: block;
    }
  }
}
.list-item {
  background-color: var(--color-modal);

  .checkbox-block {
    width: 32px;
  }
  .name {
    flex: 1;
  }
  .open-access {
    width: 200px;
  }
  .last-update {
    width: 180px;
  }
  .dop {
    width: 64px;
  }

}
.files-list {
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
  &>*:nth-child(1) {
    border-radius: 8px 8px 0 0 ;
  }
  &>*:last-child {
    border-radius: 0 0 8px 8px ;
  }
}
.list-title {
  height: 22px;
}
.list-item {
  height: 28px;
}
.click-zone {
  left: 32px;
  width: calc(100% - 32px - 64px);
}
.no-files-short {
  &>*:first-child {
    display: none;
  }
  height: 50px;

}
.bgc-focus {
  background-color: #FFEDC3;
}
.dart-mode {
  .bgc-focus {
    background-color: #4F4838;
  }
}
</style>