<template>
  <div class="overflow-y-auto bg-gray-lightest" style="height: calc(100vh - 56px);">
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>