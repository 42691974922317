<template>
  <div class="title-payments" :class="{'not-show-end-block': !showEndBlock}">
    <div class="block-for-checkbox" v-if="showSettings.indexOf('block-for-checkbox') >= 0 && showCheckBox">
<!--      <p class="fz12 text-white-always fw600">-->
<!--        -->
<!--      </p>-->
      <button class="button-checkbox" v-on:click="selectCheckboxAll">
        <svg v-if="!isSelected" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
        </svg>
        <svg v-if="isSelected" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 4.5C0 2.01472 2.01472 0 4.5 0H13.5C15.9853 0 18 2.01472 18 4.5V13.5C18 15.9853 15.9853 18 13.5 18H4.5C2.01472 18 0 15.9853 0 13.5V4.5Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 1.4C2.78792 1.4 1.4 2.78792 1.4 4.5V13.5C1.4 15.2121 2.78792 16.6 4.5 16.6H13.5C15.2121 16.6 16.6 15.2121 16.6 13.5V4.5C16.6 2.78792 15.2121 1.4 13.5 1.4H4.5ZM4.5 0C2.01472 0 0 2.01472 0 4.5V13.5C0 15.9853 2.01472 18 4.5 18H13.5C15.9853 18 18 15.9853 18 13.5V4.5C18 2.01472 15.9853 0 13.5 0H4.5Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6687 8.99922C4.6687 8.4711 5.09683 8.04297 5.62495 8.04297H12.375C12.9031 8.04297 13.3312 8.4711 13.3312 8.99922C13.3312 9.52734 12.9031 9.95547 12.375 9.95547H5.62495C5.09683 9.95547 4.6687 9.52734 4.6687 8.99922Z" fill="white"/>
        </svg>
      </button>
    </div>
    <div class="due-date position-relative" :class="{'border-left': $route.path.indexOf('cashflow/diagram') >= 0 ||  $route.path.indexOf('cashflow/new-diagram') >= 0}" v-if="showSettings.indexOf('due-date') >= 0" v-tooltip.top="'Due Date <br>(dd.mm.yyyy)'">
      <p class="fz12 text-white-always fw600">Due Date <br> <span class="fz12">(dd.mm.yyyy)</span></p>
      <button class="sort-button" v-on:click="changeDueDateSort" :class="{'r180': dueDateSort}" v-if="$route.path.indexOf('cashflow/diagram') === -1 && showSortButton">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
    <div class="vessel-state-and-member" :class="{'border-left-none': $route.path.indexOf('cashflow/diagram') >= 0 || $route.path.indexOf('cashflow/new-diagram') >= 0}" v-if="showSettings.indexOf('vessel-state-and-member') >= 0" v-tooltip.top="'Vessel, <br>CP Date'">
      <p class="fz12 text-white-always fw600">Vessel, <br>CP Date</p>
    </div>
    <div class="account" v-if="showSettings.indexOf('account') >= 0" v-tooltip.top="noTitle ? '' : 'Reference'">
      <p class="fz12 text-white-always fw600">Reference</p>
    </div>
    <div class="additionally" v-if="showSettings.indexOf('additionally') >= 0" v-tooltip.top="noTitle ? '' : 'Additionally'">
      <p class="fz12 text-white-always fw600">Additionally</p>
    </div>
    <div class="currency" v-if="showSettings.indexOf('currency') >= 0" v-tooltip.top="noTitle ? '' : 'Currency'">
      <p class="fz12 text-white-always fw600">Cur.</p>
    </div>
    <div class="expense position-relative" v-if="showSettings.indexOf('expense') >= 0" v-tooltip.top="noTitle ? '' : $route.path.indexOf('accounting-invoice')>=0 ? 'Bill' : 'Payments'">
      <p class="fz12 text-white-always fw600" v-if="!expense && expense !== 0">{{expense ? expense : $route.path.indexOf('accounting-invoice')>=0 ? 'Bill' : 'Payments'}}</p>
      <p class="fz12 text-white-always fw600" v-else>{{expense | formatNumberTwo}}</p>
      <button class="sort-button top12" v-on:click="changePaymentSort" :class="{'r180': !paymentSort}" v-if="$route.path.indexOf('cashflow/diagram') === -1 && showSortButton">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
    <div class="income position-relative" v-if="showSettings.indexOf('income') >= 0" v-tooltip.top="noTitle ? '' : $route.path.indexOf('accounting-invoice')>=0 ? 'Invoice' : 'Incomes'">
      <p class="fz12 text-white-always fw600" v-if="!income && income !== 0">{{income ? income : $route.path.indexOf('accounting-invoice')>=0 ? 'Invoice' : 'Incomes'}}</p>
      <p class="fz12 text-white-always fw600" v-else>{{income | formatNumberTwo}}</p>
      <button class="sort-button top12" v-on:click="changeIncomeSort" :class="{'r180': !incomeSort}" v-if="$route.path.indexOf('cashflow/diagram') === -1 && showSortButton">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
    <div class="converted" v-if="showSettings.indexOf('converted') >= 0" v-tooltip.top="noTitle ? '' : 'USD amount'">
      <p class="fz12 text-white-always fw600">USD amount</p>
    </div>
    <div class="status position-relative" v-if="showSettings.indexOf('status') >= 0" v-tooltip.top="noTitle ? '' : 'Status'">
      <p class="fz12 text-white-always fw600">*Status</p>
      <button class="sort-button top12" v-on:click="changeStatusSort" :class="{'r180': !statusSort}" v-if="$route.path.indexOf('cashflow/diagram') === -1 && showSortButton">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
    <div class="paid-date" v-if="showSettings.indexOf('paid-date') >= 0" v-tooltip.top="noTitle ? '' : 'Paid Date <br>(dd.mm.yyyy)'">
      <p class="fz12 text-white-always fw600">Paid Date <br> <span class="fz12">(dd.mm.yyyy)</span></p>
      <button class="sort-button" v-on:click="changeDueDateSort" :class="{'r180': dueDateSort}" v-if="$route.path.indexOf('cashflow/diagram') === -1 && showSortButton">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
    <div class="counterparty position-relative" v-if="showSettings.indexOf('counterparty') >= 0" v-tooltip.top="noTitle ? '' : 'Counterparty'">
      <p class="fz12 text-white-always fw600">Counterparty</p>
      <button class="sort-button top12" v-on:click="changeStatusCounterparty" :class="{'r180': !counterpartySort}" v-if="$route.path.indexOf('cashflow/diagram') === -1 && showSortButton">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
    <div class="bank-and-cash-account" v-if="showSettings.indexOf('bank-and-cash-account') >= 0" v-tooltip.top="noTitle ? '' : 'Bank and Cash Accounts'">
      <p class="fz12 text-white-always fw600">Bank and Cash Accounts</p>
    </div>
    <div class="remark" v-if="showSettings.indexOf('remark') >= 0" v-tooltip.top="noTitle ? '' : 'Remarks'">
      <p class="fz12 text-white-always fw600">Remarks</p>
    </div>
    <div class="attachment" v-if="showSettings.indexOf('attachment') >= 0" v-tooltip.top="noTitle ? '' : 'Attachments'">
      <p class="fz12 text-white-always fw600">Attachments</p>
    </div>
    <div class="bank-receipt" v-if="showSettings.indexOf('bank-receipt') >= 0" v-tooltip.top="noTitle ? '' : 'Bank Receipt'">
      <p class="fz12 text-white-always fw600">Bank Receipt</p>
    </div>
    <div class="comment-from-accounting" v-if="showSettings.indexOf('comment-from-accounting') >= 0" v-tooltip.top="noTitle ? '' : 'Accounting remarks'">
      <p class="fz12 text-white-always fw600">Account. remarks</p>
    </div>
    <div class="settled-vsl" v-if="showSettings.indexOf('settled-vsl') >= 0" v-tooltip.top="noTitle ? '' : 'Settled vessel'">
      <p class="fz12 text-white-always fw600">Settled vsl</p>
    </div>
    <div class="disbursement" v-if="showSettings.indexOf('disbursement') >= 0" v-tooltip.top="noTitle ? '' : 'Disbursements'">
      <p class="fz12 text-white-always fw600">Disbursements</p>
    </div>
    <div class="real-account" v-if="showSettings.indexOf('real-account') >= 0" v-tooltip.top="noTitle ? '' : 'Real Account'">
      <p class="fz12 text-white-always fw600">Real Account</p>
    </div>
    <div class="statement-member" v-if="showSettings.indexOf('statement-member') >= 0" v-tooltip.top="noTitle ? '' : 'Statement Member'">
      <p class="fz12 text-white-always fw600">Statem. Member</p>
    </div>
    <div class="balance" v-if="showSettings.indexOf('balance') >= 0" v-tooltip.top="noTitle ? '' : 'Balance'">
      <p class="fz12 text-white-always fw600">Balance, USD</p>
    </div>
    <div class="end-block" v-if="showEndBlock">
      <p class="fz12 text-white-always fw600">
        <svg v-if="$route.path.indexOf('cashflow/diagram') === -1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-6a8333d4="" fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.66668 5.33332H9.33325C9.70129 5.33332 10 5.63202 10 5.99994C10 6.36798 9.70129 6.66668 9.33325 6.66668H6.66668V9.33325C6.66668 9.70129 6.36798 10 5.99994 10C5.63202 10 5.33332 9.70129 5.33332 9.33325V6.66668H2.66662C2.2987 6.66668 2 6.36798 2 5.99994C2 5.63202 2.2987 5.33332 2.66662 5.33332H5.33332V2.66662C5.33332 2.2987 5.63202 2 5.99994 2C6.36798 2 6.66668 2.2987 6.66668 2.66662V5.33332Z" fill="#9AA3B0"></path></svg>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "virtual-payment-list-title",
  props: {
    noTitle: Boolean,
    income: Number,
    expense: Number,
    showSettings: String,
    isSelected: Boolean,
    showEndBlock: {
      type: Boolean,
      default: true,
    },
    showCheckBox: {
      type: Boolean,
      default: true,
    },
    showSortButton: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      statusSort: true,
      dueDateSort: true,
      counterpartySort: true,
      incomeSort: false,
      paymentSort: false,
    }
  },
  methods: {
    selectCheckboxAll() {
      this.$emit('select-all')
    },
    changeStatusSort() {
      this.statusSort = !this.statusSort
      this.$emit('changeOrderingStatus', this.statusSort)
    },
    changeIncomeSort() {
      this.incomeSort = !this.incomeSort
      this.$emit('changeOrdering', '-payment_type,'+(this.incomeSort ? '-' : '')+'payment_amount')
    },
    changePaymentSort() {
      this.paymentSort = !this.paymentSort
      this.$emit('changeOrdering', 'payment_type,'+(this.paymentSort ? '-' : '')+'payment_amount')
    },
    changeStatusCounterparty() {
      this.counterpartySort = !this.counterpartySort
      this.$emit('changeOrdering', (this.counterpartySort ? '-' : '')+'client_name')
    },
    changeDueDateSort() {
      this.dueDateSort = !this.dueDateSort
      this.$emit('changeOrderingDueDate', this.dueDateSort)
    },
  }
}
</script>

<style scoped lang="scss">
  .title-payments {
    border-radius: 7px 7px 0 0 ;
    background-color: var(--color-blue-dark);
    &>*:not(:last-child) {

      position: relative;
      //top: 0;
      //left: 0;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 0px 6px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        width: calc(100% - 6px);
        box-sizing: border-box;
      }
    }
  }
  .end-block {
    &>p>svg {
      path {
        fill: #fff
      }
    }
  }
  .sort-button {
    position: absolute;
    top: 7px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        fill: var(--color-white-always)
      }
    }
  }
  .top12 {
    top: 12px !important;
  }
  .border-left {
    border-left: 1px solid var(--color-border)
  }
  .border-left-none {
    border-left: none !important;
  }
  .dart-mode {
    .button-checkbox {
      svg {
        path:first-child {
          fill: #fff;
        }
        path:not(:first-child) {
          fill: #222;
        }
      }
    }
  }
  .not-show-end-block {
    &>*:last-child {
      position: relative;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 0px 6px;
    }
  }
</style>