<template>
  <div>
    <div class="shadow bg-white rounded-lg m-b-2 flex p-x-4 p-y-3 flex notifiction-wrapper" v-if="info.activity.instance.type == 'Invited'">
      <div class="avatar" v-if="!info.activity.user.avatar"></div>
      <div class="avatar" v-else :style="{ 'background-image': `url(${info.activity.user.avatar})` }"></div>
      <div class="notification-info">
        <span class="notification-info__name text-gray-text fz12">Calendar</span>
        <div class="notification-info__text">
          <!-- <p class="user-name text-blue-dark fz14">{{info.activity.user.first_name}} {{info.activity.user.last_name}}</p> -->
          <p class="user-name text-blue-dark fz14">{{getCreatorName}}</p>
          <p class="fz14 action-text p-l-1">invited you to an event</p>
          <p class="text-blue-dark fz14 event-name" @click="showEventInfo(info.activity.instance.event)">
            {{info.activity.instance.event_name || '-'}}</p>
          <span class="date text-gray-text fz12">{{info.activity.created_at | notificationDateTime}}</span>
        </div>
      </div>
    </div>
    <div class="shadow bg-white rounded-lg m-b-2 flex p-x-4 p-y-3 flex notifiction-wrapper" v-else-if="info.activity.instance.type == 'Reminder'">
      <div class="calendar-img">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 8L5 8V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V8ZM6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H9H6Z" fill="#0FA54E"/>
        <path d="M7 2.5C7 1.94772 7.44772 1.5 8 1.5C8.55228 1.5 9 1.94772 9 2.5V3V4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4V2.5Z" fill="#0FA54E"/>
        <path d="M15 2.5C15 1.94772 15.4477 1.5 16 1.5C16.5523 1.5 17 1.94772 17 2.5V4C17 4.55228 16.5523 5 16 5C15.4477 5 15 4.55228 15 4V2.5Z" fill="#0FA54E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.029 9.77376C16.431 10.0661 16.5198 10.629 16.2275 11.031L12.2275 16.531C12.0727 16.7439 11.8324 16.8783 11.57 16.8989C11.3076 16.9195 11.0493 16.8241 10.8632 16.638L8.36321 14.138C8.01174 13.7866 8.01174 13.2167 8.36321 12.8652C8.71469 12.5138 9.28453 12.5138 9.63601 12.8652L11.3912 14.6205L14.7717 9.97227C15.0641 9.57028 15.627 9.48141 16.029 9.77376Z" fill="#0FA54E"/>
        </svg>
      </div>
      <div class="notification-info">
        <span class="notification-info__name text-gray-text fz12">Calendar</span>
        <div class="notification-info__text">
          <p class="fz14 action-text">{{info.activity.instance.text.replace('before start', '')}} left before the</p>
          <p class="text-blue-dark fz14 event-name" @click="showEventInfo(info.activity.instance.event)">{{info.activity.instance.event_name}}</p>
          <span class="date text-gray-text fz12">{{info.activity.created_at | notificationDateTime}}</span>
        </div>
      </div>
    </div>
    <div class="shadow bg-white rounded-lg m-b-2 flex p-x-4 p-y-3 flex notifiction-wrapper" v-else-if="info.activity.instance.type == 'Delete'">
      <div class="calendar-img">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 8L5 8V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V8ZM6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H9H6Z" fill="#E85353"/>
        <path d="M7 2.5C7 1.94772 7.44772 1.5 8 1.5C8.55228 1.5 9 1.94772 9 2.5V3V4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4V2.5Z" fill="#E85353"/>
        <path d="M15 2.5C15 1.94772 15.4477 1.5 16 1.5C16.5523 1.5 17 1.94772 17 2.5V4C17 4.55228 16.5523 5 16 5C15.4477 5 15 4.55228 15 4V2.5Z" fill="#E85353"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.029 9.77376C16.431 10.0661 16.5198 10.629 16.2275 11.031L12.2275 16.531C12.0727 16.7439 11.8324 16.8783 11.57 16.8989C11.3076 16.9195 11.0493 16.8241 10.8632 16.638L8.36321 14.138C8.01174 13.7866 8.01174 13.2167 8.36321 12.8652C8.71469 12.5138 9.28453 12.5138 9.63601 12.8652L11.3912 14.6205L14.7717 9.97227C15.0641 9.57028 15.627 9.48141 16.029 9.77376Z" fill="#E85353"/>
        </svg>

      </div>
      <div class="notification-info">
        <span class="notification-info__name text-gray-text fz12">Calendar</span>
        <div class="notification-info__text">
          <p class="fz14 action-text">{{info.activity.instance.text | canceledEventTextFormatted}}</p>
          <span class="date text-gray-text fz12">{{info.activity.created_at | notificationDateTime}}</span>
        </div>
      </div>
    </div>
    <div class="shadow bg-white rounded-lg m-b-2 flex p-x-4 p-y-3 flex notifiction-wrapper" v-else-if="info.activity.instance.type == 'Update'">
      <div class="calendar-img">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 8L5 8V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V8ZM6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H9H6Z" fill="#0FA54E"/>
        <path d="M7 2.5C7 1.94772 7.44772 1.5 8 1.5C8.55228 1.5 9 1.94772 9 2.5V3V4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4V2.5Z" fill="#0FA54E"/>
        <path d="M15 2.5C15 1.94772 15.4477 1.5 16 1.5C16.5523 1.5 17 1.94772 17 2.5V4C17 4.55228 16.5523 5 16 5C15.4477 5 15 4.55228 15 4V2.5Z" fill="#0FA54E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.029 9.77376C16.431 10.0661 16.5198 10.629 16.2275 11.031L12.2275 16.531C12.0727 16.7439 11.8324 16.8783 11.57 16.8989C11.3076 16.9195 11.0493 16.8241 10.8632 16.638L8.36321 14.138C8.01174 13.7866 8.01174 13.2167 8.36321 12.8652C8.71469 12.5138 9.28453 12.5138 9.63601 12.8652L11.3912 14.6205L14.7717 9.97227C15.0641 9.57028 15.627 9.48141 16.029 9.77376Z" fill="#0FA54E"/>
        </svg>
      </div>
      <div class="notification-info">
        <span class="notification-info__name text-gray-text fz12">Calendar</span>
        <div class="notification-info__text">
          <p class="fz14 action-text">The event </p>
          <p class="text-blue-dark fz14 event-name" @click="showEventInfo(info.activity.instance.event)">{{info.activity.instance.event_name}}</p>
          <p class="fz14 action-text p-l-1">has been edited</p>
          <span class="date text-gray-text fz12">{{info.activity.created_at | notificationDateTime}}</span>
        </div>
      </div>
    </div>
    <eventInfoModal
      @delEvent="deleteEvent($event)"
      ref="eventInfoModal"
    ></eventInfoModal>
  </div>
</template>

<script>
import eventInfoModal from "@/components/calendar/eventInfoModal";
import moment from 'moment'
import { mapActions, mapGetters } from "vuex";
export default {
  components: { eventInfoModal },
  props: ['info'],
  data: () => {
    return {
      creator: null
    }
  },
  methods: {
    ...mapActions(["axiosDeleteCalendarEvent", "axiosGetCalendarMembersList"]),
    ...mapGetters(["getCalendarMembersList"]),
    deleteEvent(id) {
      this.showLoad();
      this.axiosDeleteCalendarEvent(id)
        .then(async () => {
          this.hideLoad();
        })
        .catch(() => {
          this.hideLoad();
        });
    },
    showEventInfo(event){
      if(event){
        this.$refs.eventInfoModal.showModal(event)
      }else{
        this.$toast.open({
            message: 'Event has been deleted!',
            type: 'error',
        });
      }
    }
    
  },
  
  filters: {
    notificationDateTime: function(date) {
      if(!moment(date).isValid()) return ''
      // date = moment(date).add('2', 'hours')
      return moment(date).format('DD MMM') + ' at '+ moment(date).format('HH:mm')
    },
    textFormat: function(str){
      return str.replace('before start', '')
    },
    canceledEventTextFormatted: function(str){
      let eventName = str.replace('The event','').replace('was cancelled','');
      return `The event ${eventName} was cancelled`

    }
  },
  computed: {
    getCreatorName(){
      let name = 'User';
      if(this.info.activity.instance.text){
        let arr = this.info.activity.instance.text.split(' ').slice(0, 2);
        name = arr.join(' ');
      }
      return name
    }
  }
}
</script>
<style lang="scss" scoped>
.notifiction-wrapper{
  position: relative;
}
.avatar {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.37328 0 0 5.37222 0 11.9995C0 18.6267 5.37275 23.9989 12 23.9989C18.6278 23.9989 24 18.6267 24 11.9995C24 5.37222 18.6278 0 12 0ZM12 3.58798C14.1927 3.58798 15.9696 5.36537 15.9696 7.55706C15.9696 9.74928 14.1927 11.5261 12 11.5261C9.80831 11.5261 8.03145 9.74928 8.03145 7.55706C8.03145 5.36537 9.80831 3.58798 12 3.58798ZM11.9974 20.8616C9.81042 20.8616 7.80743 20.0652 6.2625 18.7469C5.88615 18.4259 5.66898 17.9552 5.66898 17.4613C5.66898 15.2385 7.46798 13.4595 9.69129 13.4595H14.3098C16.5336 13.4595 18.3257 15.2385 18.3257 17.4613C18.3257 17.9557 18.1096 18.4254 17.7328 18.7464C16.1884 20.0652 14.1848 20.8616 11.9974 20.8616Z' fill='%23DEE3EA'/%3E%3C/svg%3E%0A");
  margin-right: 12px;
  background-size: cover;
}
.calendar-img{
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  background: #F4F6F9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-info{
  display: flex;
  flex-direction: column;
}
.notification-info__text{
  display: flex;
  align-items: center;
}
.action-text{
  // padding-left: 6px;
}
.event-name{
  text-transform: capitalize;
  padding-left: 6px;
  &:hover{
    cursor: pointer;
  }
}
.date{
  position: absolute;
  top: 12px;
  right: 16px;
}
</style>