<template>
  <div class="w-full">
    <div class="flex items-center w-full p-t-4 p-b-4">
      <h1 class="fz20 fw500">Archive LayTime</h1>
      <div class="m-l-auto m-r-6 fz13 text-gray-text">Edited {{lay.updated_at | formatDate}} at {{lay.updated_at | formatTime}} by {{lay.creator.first_name}} {{lay.creator.last_name ? lay.creator.last_name : ''}}</div>
      <button class="fz0" @click="$emit('on-unarchive')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/><path d="M13.6538 6.26562H5.73568C5.32934 6.26562 5 6.59493 5 7.0013C5 7.40764 5.32934 7.73698 5.73568 7.73698H13.6538C15.7904 7.73698 17.5286 9.60036 17.5286 11.9785C17.5286 14.3567 15.7904 16.4862 13.6538 16.4862H6.71661C6.31027 16.4862 5.98093 16.8155 5.98093 17.2219C5.98093 17.6282 6.31027 17.9576 6.71661 17.9576H13.6538C16.6014 17.9576 19 15.212 19 11.9785C19 8.74505 16.6017 6.26562 13.6538 6.26562Z" fill="#9AA3B0"/></svg>
      </button>
    </div>

    <lay-active-info :lay="lay" :timeAllowed="getTimeAllowedArchive"/>

    <lay-active-dates class="m-t-3 m-b-3" :lay="lay"/>

    <lay-active-table :lay="lay" :timeAllowed="getTimeAllowedArchive"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import layActiveInfo from '@/components/lay/layActiveInfo'
import layActiveDates from '@/components/lay/layActiveDates'
import layActiveTable from '@/components/lay/layActiveTable'

export default {
  components: {
    layActiveInfo,
    layActiveDates,
    layActiveTable,
  },
  computed: {
    ...mapState({
      lay: state => state.lay.activeLayArchive
    }),
    ...mapGetters(['getTimeAllowedArchive'])
  },
}
</script>

<style>

</style>