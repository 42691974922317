<template>
    <div>
      <folders-list-links-payment class="mt16" :items="payments"></folders-list-links-payment>
      <pagination-panel-default @load-more="loadMore" :if-active="isLoad" v-if="nextLinkPayment"></pagination-panel-default>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
  import FoldersListLinksPayment from "@/components/folders/folders-list-links-payment.vue";
  import PaginationPanelDefault from "@/components/ports/paginationDefault.vue";
  import moment from 'moment';
  export default {
    components: {PaginationPanelDefault, FoldersListLinksPayment},
    props: {
      search: String,
      sort: String,
      routeChange: Number,
    },
    watch: {
      search: function () {
        this.loadPayment()
      },
      sort: function () {
        this.loadPayment()
      },
      routeChange: function () {
        this.loadPayment()
      }
    },
    computed: {
      ...mapState({
        nextLinkPayment: state => state.folders.nextLinkPayment,
      }),
    },
    data() {
      return {
        payments: [],
        isLoad: false,
      }
    },
    methods: {
      ...mapActions(['axiosGetFolderPayments', 'axiosFoldersGetPaymentLoadMore']),
      ...mapMutations(['setNameSectionFolders', 'showLoad', 'hideLoad']),
      async loadPayment() {
        console.log('loadPayment', this.$route.params)
        let form = {
          counterparty: JSON.parse(this.$route.params.detailCounterparties).id,
          data_type: 'payment',
          record_type: 'PAYMENT',
          is_archived: this.$route.params.is_archive_payments,
          limit: 25,
          ordering: this.sort,
          search: this.search,
        }
        this.showLoad()
        let payments = await this.axiosGetFolderPayments(form)
        payments.forEach(item=> {
            item['link'] = {name: 'Folders - Counterparties Options Payment Detail', params: {is_archive_files: false, detailData: JSON.stringify({id: item.id, date: item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'})}}
        })
        this.payments = payments
        this.hideLoad()
      },
      async loadMore() {
        this.isLoad = true
        let res = await this.axiosFoldersGetPaymentLoadMore()
        res.forEach(item=> {
            item['link'] = {name: 'Folders - Counterparties Options Payment Detail', params: {is_archive_files: false, detailData: JSON.stringify({id: item.id, date: item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'})}}
        })
        this.payments = this.payments.concat(res)
        this.isLoad = false
      },
    },
    mounted() {
      this.setNameSectionFolders('Counterparties')
      this.loadPayment()
    }
  }
</script>

<style scoped lang="scss">

</style>