<template>
  <section class="wrap-page payment-list-wrap">
<!--    <payment-modal-duplicate-or-edit-payment ref="modalDupOrEd" @needRender="renderList"></payment-modal-duplicate-or-edit-payment>-->
<!--    <payment-modal-create ref="modalCreate" @needRender="renderList"></payment-modal-create>-->
    <payment-modal-delete @render="renderList" ref="modalDelete"></payment-modal-delete>

    <div class="container">
      <div class="top-panel">
        <button class="fz13 fw500 blue-button h32 buttonW150" :disabled="showLoaderButton" v-on:click="addNewPayment" v-if="getPermissionsCreatePayment()">
            <app-loader class="loader-button" v-if="showLoaderButton"></app-loader>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="white"/>
          </svg>
          Create new
        </button>
        <router-link to="/payment/archive" class="button-top-panel button-dark-link archive-link svg-white-dart-stroke text-dark fw500 fz13" v-if="getPermissionsByType('show_archive_of_payments_incomes') === true">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Archive
        </router-link>
<!--        <button class="download-list" v-on:click="downloadPDFListPayment()">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M13.0335 5C13.0335 4.6134 12.7201 4.3 12.3335 4.3C11.9469 4.3 11.6335 4.6134 11.6335 5H13.0335ZM12.3335 14L11.8394 14.4959C12.1125 14.768 12.5544 14.768 12.8276 14.4959L12.3335 14ZM16.8276 10.5102C17.1014 10.2373 17.1022 9.79412 16.8293 9.52026C16.5565 9.2464 16.1132 9.24561 15.8394 9.51848L16.8276 10.5102ZM8.82756 9.51848C8.5537 9.24561 8.11048 9.2464 7.83761 9.52026C7.56473 9.79412 7.56553 10.2373 7.83939 10.5102L8.82756 9.51848ZM19.7002 16C19.7002 15.6134 19.3868 15.3 19.0002 15.3C18.6136 15.3 18.3002 15.6134 18.3002 16H19.7002ZM6.03348 16C6.03348 15.6134 5.72008 15.3 5.33348 15.3C4.94689 15.3 4.63348 15.6134 4.63348 16H6.03348ZM11.6335 5V14H13.0335V5H11.6335ZM12.8276 14.4959L16.8276 10.5102L15.8394 9.51848L11.8394 13.5041L12.8276 14.4959ZM12.8276 13.5041L8.82756 9.51848L7.83939 10.5102L11.8394 14.4959L12.8276 13.5041ZM17.0002 18.3H7.33349V19.7H17.0002V18.3ZM18.3002 16V17H19.7002V16H18.3002ZM6.03348 17V16H4.63348V17H6.03348ZM7.33349 18.3C6.61551 18.3 6.03348 17.718 6.03348 17H4.63348C4.63348 18.4912 5.84232 19.7 7.33349 19.7V18.3ZM17.0002 19.7C18.4913 19.7 19.7002 18.4912 19.7002 17H18.3002C18.3002 17.718 17.7181 18.3 17.0002 18.3V19.7Z" fill="#2C2C2C"/>-->
<!--          </svg>-->
<!--        </button>-->
        <keep-alive>
          <payment-dropdown-export :btnText="'Export'" class="download-list button-top-panel" @eventExportXLSX="downloadXLSXListPayment" @eventExportPDF="downloadPDFListPayment"></payment-dropdown-export>
        </keep-alive>
        <keep-alive>
          <payment-checkbox-payment  @changeStatusCheckbox="changeStatusCheckboxPayment" :status="paymentCheckboxActive" class="ch1"></payment-checkbox-payment>
        </keep-alive>
        <keep-alive>
          <payment-checkbox-income @changeStatusCheckbox="changeStatusCheckboxIncome" :status="paymentCheckboxIncomeActive" class="ch2"></payment-checkbox-income>
        </keep-alive>
        <button class="button-filter fz14 fw500" :class="{'text-blue': filterData && filterData.filter_active}" v-on:click="openFilter">
          <svg v-if="!filterData || (filterData && !filterData.filter_active)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.28634 3.73746C8.22907 3.73746 7.3304 4.45987 7.03965 5.44983H4.63436C4.26432 5.44983 4 5.74415 4 6.11873C4 6.49331 4.29075 6.78763 4.66079 6.78763H7.06608C7.3304 7.77759 8.25551 8.5 9.31277 8.5C10.3965 8.5 11.2952 7.77759 11.5859 6.78763H18.3392C18.7093 6.78763 19 6.49331 19 6.11873C19 5.74415 18.7093 5.44983 18.3392 5.44983H11.5859C11.2687 4.45987 10.37 3.73746 9.28634 3.73746ZM9.31277 5.07525C9.86784 5.07525 10.3436 5.5301 10.3436 6.11873C10.3436 6.70736 9.86784 7.16221 9.31277 7.16221C8.75771 7.16221 8.28194 6.70736 8.28194 6.11873C8.28194 5.5301 8.75771 5.07525 9.31277 5.07525Z" fill="#2C2C2C"/>
            <path d="M14.2423 9.61377C13.185 9.61377 12.2863 10.3362 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6205 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6205 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3362 15.326 9.61377 14.2423 9.61377ZM14.2687 10.9516C14.8238 10.9516 15.2996 11.4064 15.2996 11.995C15.2996 12.5837 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5837 13.2379 11.995C13.2379 11.4064 13.6872 10.9516 14.2687 10.9516Z" fill="#2C2C2C"/>
            <path d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5186 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8029 8.42731 20.5253 9.48458 20.5253C10.5683 20.5253 11.467 19.8029 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5186 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5554 10.5154 18.144C10.5154 18.7326 10.0396 19.1875 9.48458 19.1875C8.92951 19.1875 8.45374 18.7326 8.45374 18.144C8.48018 17.5554 8.92951 17.1005 9.48458 17.1005Z" fill="#2C2C2C"/>
          </svg>
          <svg v-if="filterData && filterData.filter_active" class="svg-blue" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 6C24 8.20914 22.2091 10 20 10C17.7909 10 16 8.20914 16 6C16 3.79086 17.7909 2 20 2C22.2091 2 24 3.79086 24 6Z" fill="#319FEF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0068 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H14.0916C14.0314 6.70918 14 6.35826 14 6C14 5.90358 14.0023 5.80768 14.0068 5.71237ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z" fill="#319FEF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1809 10.6278C15.9425 10.4309 15.7196 10.2158 15.5143 9.98485C15.1482 9.74985 14.7127 9.61374 14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.9683 18.9985 11.9421 18.9957 11.9163C18.3705 11.811 17.7782 11.6089 17.2346 11.3261H16.5154C16.4412 11.0734 16.3273 10.8381 16.1809 10.6278ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z" fill="#319FEF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.21145 17.4751C7.5022 16.4851 8.40088 15.7627 9.45815 15.7627C10.5419 15.7627 11.4405 16.4851 11.7313 17.4751H18.3392C18.7093 17.4751 19 17.7694 19 18.144C19 18.5185 18.7093 18.8129 18.3392 18.8129H11.7577C11.467 19.8028 10.5683 20.5252 9.48458 20.5252C8.42731 20.5252 7.5022 19.8028 7.23789 18.8129H4.66079C4.29075 18.8129 4 18.5185 4 18.144C4 17.7694 4.26432 17.4751 4.66079 17.4751H7.21145ZM10.5154 18.144C10.5154 17.5553 10.0396 17.1005 9.48458 17.1005C8.92951 17.1005 8.48018 17.5553 8.45374 18.144C8.45374 18.7326 8.92951 19.1874 9.48458 19.1874C10.0396 19.1874 10.5154 18.7326 10.5154 18.144Z" fill="#319FEF"/>
          </svg>
          Filter
        </button>
      </div>
      <keep-alive>
        <payment-list-charts class="chart-block"></payment-list-charts>
      </keep-alive>
      <payment-panel-select-period @changeDate="emitChangeDate" :active-date="dateDefault"></payment-panel-select-period>
<!--        <payment-list-component v-if="getShowPaymentList()" v-click-outside="focusFalse" @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate"  @HtmlCode="PDFHtmlCode" ref="paymentList" class="payment-list" @clickDelete="clickDelete" @clickCreate="showModalCreate" @clickEditPaymentCard="clickEditPaymentCard" @clickDuplicatePaymentCard="clickDuplicatePaymentCard"></payment-list-component>-->
      <virtual-payment-list @changeOrdering="changeOrdering" @scrollBottomEnd="getNextLinkPayment && getPaymentListOriginal.length !== 0 && !showLoader ? paymentNext() : null" @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" ref="paymentList"></virtual-payment-list>
<!--      <pagination-panel-default class="pagination-panel" @load-more="paymentNext()" :if-active="showLoader" v-if="getNextLinkPayment && getPaymentListOriginal.length !== 0"></pagination-panel-default>-->
      <loader-for-autoload v-if="showLoader && getNextLinkPayment && getPaymentListOriginal.length !== 0"></loader-for-autoload>
      <div class="pb55" v-else></div>
      <payment-gross-net></payment-gross-net>
    </div>
    <payment-list-filter @cancelPaymentCh="cancelPaymentCh" @cancelIncomeCh="cancelIncomeCh" @changeFilter="changeFilter" class="filter" ref="filter"></payment-list-filter>
<!--    <iframe :src="filePath" style="width: 400px; height: 500px;"></iframe>-->
  </section>
</template>

<script>
import PaymentCheckboxPayment from "@/components/payment/payment-checkbox-payment";
import PaymentCheckboxIncome from "@/components/payment/payment-checkbox-income";
import {mapActions, mapGetters, mapMutations} from "vuex";
import PaymentPanelSelectPeriod from "@/components/payment/payment-panel-select-period";
import PaymentListFilter from "@/components/payment/payment-list-filter/payment-list-filter";
import PaymentModalDelete from "@/components/payment/payment-modal-delete";
import PaymentListCharts from "@/components/payment/payment-list-charts/payment-list-charts";
import Vue from 'vue';
import moment from 'moment';
import axios from "axios";
import PaymentDropdownExport from "@/components/payment/payment-dropdown-export";
import AppLoader from "@/components/loader";
import PaymentGrossNet from "@/components/payment/payment-gross-net";
import VirtualPaymentList from "@/components/payment/payment-list/virtual-payment-list";
import LoaderForAutoload from "@/components/loader-for-autoload";
Vue.prototype.$moment = moment;
export default {
  name: "paymentList",
  components: {
    LoaderForAutoload,
    VirtualPaymentList,
    PaymentGrossNet,
    AppLoader,
    PaymentDropdownExport,
    PaymentListCharts,
    PaymentModalDelete,
    PaymentListFilter,
    PaymentPanelSelectPeriod,
     PaymentCheckboxIncome, PaymentCheckboxPayment},
  computed: {
    ...mapGetters(["getFilterDataPaymentAxios", "getNextLinkPayment", "getPermissionsForUser", "getPermissionsByType", "getContracts","getPaymentList", "getPaymentListOriginal", "getDataPaymentModal", "getInfoDetailPayment", "getPaymentList", "getDateForViewPayments"]),
  },
  data() {
    return {
      orderingName: '',
      status_ordering: true,
      due_date_ordering_status: true,
      element: null,
      keyRender: -1000,
      dateSelected: [],
      filterData: null,
      dateDefault: [],
      paymentCheckboxActive: false,
      paymentCheckboxIncomeActive: false,
      firstRequest: true,
      showLoader: false,
      showLoaderButton: false,
    }
  },
  methods: {
    ...mapActions(['axiosPaymentNextLinkPdf', "axiosAddNewPayment", "axiosPaymentNextLink", "axiosAddNoteToPayment", "axiosGetAllContracts", "axiosGetXLSX", "axiosGetPayment", "axiosGetPDF"]),
    ...mapMutations(["setPaymentsList", "setNextLinkPayment", "setFilterDataPaymentAxios", "cancelPostPayment", "setDateForViewPayments", "setDataPaymentModal", "hideLoad", "showLoad"]),
    getShowPaymentList() {
      return false
    },
    focusFalse() {
      this.$refs.paymentList.focusFalse()
    },
    changeOrderingStatus(data) {
      this.status_ordering = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrderingDueDate(data) {
      this.due_date_ordering_status = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrdering(ordering) {
      this.orderingName = ordering
      this.axiosChangeGetList();
    },
    addNewPayment() {
      this.showLoaderButton = true
      let data_type = this.$route.path.indexOf('accounting-invoice') === -1 ? 'payment' : 'invoice';

      this.axiosAddNewPayment({data_type: data_type}).then(()=> {
        // this.$refs.paymentList.addNewPaymentData(res)
        this.showLoaderButton = false
      })
    },
    paymentNext() {
      this.showLoader = true;
      this.axiosPaymentNextLink().then(()=> {
        this.showLoader = false;
      })
    },
    getPermissionsCreatePayment() {
      return this.getPermissionsByType("create_payments_incomes");
    },
    getActiveColumsList() {
      this.$refs.paymentList.emitGetActiveList();
    },
    async downloadXLSXListPayment() {
      if(this.getNextLinkPayment) {
        this.showLoad()
        await this.axiosPaymentNextLinkPdf().then(()=> {
        })
        this.hideLoad()
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadXML()
      }, 500)
    },

    getTextBankAndCashAccounts(payment) {
      if(payment.cash !== null) {
        return {name: payment.cash.name, id: payment.id, id_cash: payment.cash.id, type: 'cash'}
      } else if(payment.bank !== null) {
        return {name: payment.bank.bank, id: payment.id, id_cash: payment.bank.id, type: 'bank'}
      } else {
        return {name: "-", id: payment.id, id_cash: "", type: 'bank'}
      }
    },
    getTextForStatus(str) {
      if(str.length) {
        str = str.replaceAll("_", " ");
        str = str[0].toUpperCase() + str.slice(1);
        return str
      }
        return "-"
    },
    getFormatDate(date) {
      if(date !==null) {
        return "" + moment(date).format('DD.MM.YYYY')
      } else {
        return "-"
      }
    },
    getTextAccount(a) {
      if(a!== null && a!== undefined) {
        if(a.account !== null && a.account !== undefined) {
          if(a.account.length) {
            return (""+a.account.map(ac=> {return ac.id + ": " + ac.name + " "})).replaceAll(",", "")}
        }
      }
      return  "-";
    },
    PDFHtmlCode(codePage) {
      this.axiosGetPDF({html: codePage, name: "pdf"}).then(response=> {
        // console.log("File");
        // console.log(new File(, "file.pdf"));
        // var pdfAsDataUri = "data:application/pdf;base64,"+response.data;
        // window.open(pdfAsDataUri);
        // console.log(response.data)
        let data = response.data;
        // window.open("data:application/pdf;base64," + encodeURI(response.data));
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
          var byteCharacters = atob(data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: 'application/pdf'});
          //var blob_iframe = document.querySelector('#blob-src-test');
          //blob_iframe.src = blob_url;
          window.navigator.msSaveOrOpenBlob(blob, "pdf");
        }
        else { // much easier if not IE
          // this.filePath = "data:application/pdf;base64," + data, '', "height=600,width=800"
          // window.open("data:application/pdf;base64," + data);
          let link = "data:application/pdf;base64," + data;
          axios.get(link, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                let name = "Payment List"
                if(this.$route.path.indexOf('accounting-invoice') >= 0) {
                  name = "Account Invoice"
                }
                if(this.$route.path.indexOf('cash')>=0) {
                  name = 'CashFlow List'
                }
                if(this.$route.path.indexOf('instructions') >= 0) {
                  name = "Payment Instructions List"
                }
                link.download = name
                link.click()
                URL.revokeObjectURL(link.href)
              }).catch(console.error)
        }
      })
    },
    cancelIncomeCh() {
      this.paymentCheckboxIncomeActive = false;
      this.paymentCheckboxIncomeActive = true;
    },
    cancelPaymentCh() {
      this.paymentCheckboxActive = false;
      this.paymentCheckboxActive = true;
    },
    changeStatusCheckboxPayment(status) {
      // console.log("changeStatusCheckboxPayment");
      // console.log(status)
      if(status) {
        this.$refs.filter.selectPaymentCheckboxes();
      } else {
        this.$refs.filter.clearPaymentCheckboxes(true);
      }
    },
    changeStatusCheckboxIncome(status) {
      // console.log("changeStatusCheckboxIncome")
      // console.log(status)
      if(status) {
        this.$refs.filter.selectIncomeCheckboxes();
      } else {
        this.$refs.filter.clearIncomeCheckboxes(true);
      }
    },
    changeFilter(data) {
      // console.log("data");
      // console.log(data);
      this.paymentCheckboxIncomeActive = data.incomeCheckbox.length !== 0;
      this.paymentCheckboxActive = data.paymentCheckbox.length !== 0;
      this.filterData = data;
      this.axiosChangeGetList();
    },
    axiosChangeGetList() {
      let before = "";
      let after = "";
      if(""+this.dateSelected[0] !== 'null' && this.dateSelected[0].length !== 0) {
        before = ""+moment(this.dateSelected [0]).utcOffset(0, true).format("YYYY-MM-DD")+ " 00:00:00";
      }
      if(""+this.dateSelected[1] !== 'null' && this.dateSelected[1].length !== 0) {
        after = ""+moment(this.dateSelected [1]).utcOffset(0, true).format("YYYY-MM-DD")+ " 23:59:59";
      }
      let type = "payment";
      if(this.$route.fullPath.indexOf("accounting-invoice") >= 0) {
        type = "invoice";
      }
      // if(this.firstRequest) {
      //   this.showLoad();
      //   this.firstRequest = false;
      // }
      let orderExecuteDate = ''
      if(this.status_ordering) {
        orderExecuteDate += '-status'
      } else {
        orderExecuteDate += 'status'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-paid_date'
      } else {
        orderExecuteDate += ',paid_date'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-execute_date'
      } else {
        orderExecuteDate += ',execute_date'
      }
      orderExecuteDate = this.orderingName ? this.orderingName : orderExecuteDate
      this.setFilterDataPaymentAxios({ordering: orderExecuteDate, type: type, before: before,after: after, filter: this.filterData})
      this.axiosGetPayment({ordering: orderExecuteDate, type: type, before: before,after: null, filter: this.filterData}).then(()=> {
        this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
        this.hideLoad();
      });
    },
    openFilter() {
      this.$refs.filter.showMenu();
    },
    clickEditPaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "edit");
    },
    clickDuplicatePaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "duplicate");
    },
    emitChangeDate(date) {
      this.cancelPostPayment();
      this.dateSelected = date;

      this.axiosChangeGetList();
    },
    showModalCreate() {
      this.$refs.modalCreate.openModal();
      this.keyRender++;
    },
    renderList() {
      this.emitChangeDate(this.dateSelected);
    },
    clickDelete(id) {
      this.$refs.modalDelete.openModal(id);
    },
    async downloadPDFListPayment() {
      // if(this.getFilterDataPaymentAxios !== null) {
      //   this.showLoad()
      //   let data = this.getFilterDataPaymentAxios;
      //   let linkNext = this.getNextLinkPayment;
      //   let payments = this.getPaymentListOriginal
      //   data['noLoad'] = true
      //   data['limit'] = 9999999
      //   this.axiosGetPayment(data).then(()=> {
      //     this.$refs.paymentList.getHTMLList()
      //     setTimeout(()=> {
      //       this.setNextLinkPayment(linkNext)
      //       this.setPaymentsList(payments)
      //       this.hideLoad()
      //     }, 300)
      //   })
      // }


      if(this.getNextLinkPayment) {
        this.showLoad()
        await this.axiosPaymentNextLinkPdf().then(()=> {
        })
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadPDF()
      }, 1000)
    },
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_payment_list") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
        next(from);
      }
    })
  },
  watch: {
    getPermissionsForUser: function() {
      if(this.getPermissionsByType("show_payment_list") === false) {
        this.$router.push('/employees')
      }
    }
  },
  mounted() {
    // if(!this.getPaymentList.length) {
    //   this.axiosGetPayment();
    // }
    if(this.getDateForViewPayments !== null) {
      this.dateDefault = this.getDateForViewPayments;
    }
    if(this.getDataPaymentModal !== null) {
      if(this.getDataPaymentModal === 'duplicate') {
        this.$refs.modalDupOrEd.openModalWithDetailView(this.getInfoDetailPayment, "duplicate");
      } else {
        this.$refs.modalDupOrEd.openModalWithDetailView(this.getInfoDetailPayment, "edit");
      }
      this.setDataPaymentModal(null);
    }

  },
  beforeRouteLeave(to, from, next) {
    this.setDateForViewPayments(null);
    next();
  }
}
</script>

<style scoped lang="scss">
  @import "paymentList";
  .button-top-panel {
    width: 132px;
    box-sizing: border-box;
    height: 32px;
  }
  .archive-link {
    border-radius: 4px;
    border: 1px solid var(--color-blue-dark);
    justify-content: center;
    color: var(--color-blue-dark) !important;
    svg {
      path {
        stroke: var(--color-blue-dark)
      }
    }
  }
  .top-panel {
    .blue-button {
      width: 132px;
    }
  }

</style>