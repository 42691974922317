<template>
  <div class="flex h-full w-full">
    <div v-if="!$route.meta.isCharter" class="lay_sidebar h-full bg-white flex-shrink-0 border-r border-gray-light overflow-auto">
      <div class="flex items-center justify-between p-l-6 p-r-4 p-y-1 bg-gray-lightest border-b border-gray-light text-gray-text fz12 fw500">
        PORTS
        <router-link class="fz0" :to="`/vessels/${$route.params.id}/lay/archive`">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </router-link>
      </div>

      <div 
        v-for="lay in lays" 
        :key="lay.id"
        @click="setActiveLay(lay)"
        class="lay_list relative p-l-6 p-r-8 p-y-2 border-b border-gray-dark cursor-pointer min-h-34px box-border"
        :class="{
          'active bg-gray-dark': activeLay && activeLay.id == lay.id
        }"
      >
        <template v-if="lay.lay_ports && lay.lay_ports.length">
          <span v-if="getFirstPortsWithName(lay)">
                      <span class="fz14 fw500" v-if="getFirstPortsWithName(lay).loading_port">{{getFirstPortsWithName(lay).loading_port.name}}, {{getFirstPortsWithName(lay).loading_port.country}}</span>
          <span class="fz14 fw500" v-if="getFirstPortsWithName(lay).loading_port && getFirstPortsWithName(lay).discharging_port"> - <br></span>
          <span class="fz14 fw500" v-if="getFirstPortsWithName(lay).discharging_port">{{getFirstPortsWithName(lay).discharging_port.name}}, {{getFirstPortsWithName(lay).discharging_port.country}}</span>
          </span>

          <div v-if="getPortsWithoutEmpty(lay).length > 1" class="lay_list__dot inline-flex w-5 h-5 rounded-full border border-dark fz11 box-border">
            <span class="m-auto" v-if="getFirstPortsWithName(lay)">+{{getPortsWithoutEmpty(lay).length - 1}}</span>
<!--            <span class="m-auto" v-else>{{lay.lay_ports.length}}</span>-->
          </div>
        </template>
      </div>
    </div>
    <div class="w-full h-full overflow-auto p-x-6">
      <div class="flex h-full">
        <lay-active v-if="lays && activeLay" @on-create="$emit('on-create')" @on-edit="$emit('on-edit', activeLay)" @on-archive="$emit('on-archive', activeLay)"/>

        <lay-empty v-else @on-create="$emit('on-create')"/>
      </div>
    </div>
  </div>
</template>

<script>
import layEmpty from '@/components/lay/layEmpty'
import layActive from '@/components/lay/layActive'
import { mapState, mapMutations } from 'vuex';

export default {
  components: {
    layEmpty,
    layActive,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      lays: state => state.lay.lays,
      activeLay: state => state.lay.activeLay
    }),
  },
  watch: {

  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setActiveLay']),
    getPortsWithoutEmpty(lay) {
      return lay.lay_ports.filter(a=> a.loading_port || a.discharging_port)
    },
    getFirstPortsWithName(lay) {
      return lay.lay_ports.find(a=> a.loading_port || a.discharging_port)
    },
  },
}
</script>

<style lang="scss">
.lay_list__dot{
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
}
.min-h-34px {
  min-height: 34px;
}
</style>