<template>
  <div>
    <div class="pb4 w100Proc border-bottom border-color-normal d-flex align-items-center mt22 ">
      <svg class="mr8 cursor-pointer" :class="{'rotate-180': showMyFiles}" v-on:click="showMyFiles = !showMyFiles" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fz18 fw500">My Files</p>
    </div>
    <div class="position-relative" v-show="showMyFiles">
      <app-loader v-if="loadShowMyFiles" :is-local="true"></app-loader>
      <folders-list-file-and-folder @open="(item)=> openPage(item, 'My Files')" :no-files-short="true" :localMode="true" :local-folders="filesMyFiles.filter(a=> a.file_type == 'FOLDER')" :local-files="filesMyFiles.filter(a=> a.file_type == 'FILE')"></folders-list-file-and-folder>
    </div>
    <div class="pb4 w100Proc border-bottom border-color-normal d-flex align-items-center mt14">
      <svg class="mr8 cursor-pointer" :class="{'rotate-180': showShared}" v-on:click="showShared = !showShared" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fz18 fw500">Shared with me</p>
    </div>
    <div class="position-relative" v-show="showShared">
      <app-loader v-if="loadShowSharedWithMe" :is-local="true"></app-loader>
      <folders-list-file-and-folder @open="(item)=> openPage(item, 'Shared with me')" :no-files-short="true" :localMode="true" :local-folders="filesSharedWithMe.filter(a=> a.file_type == 'FOLDER')" :local-files="filesSharedWithMe.filter(a=> a.file_type == 'FILE')"></folders-list-file-and-folder>
    </div>
    <div class="pb4 w100Proc border-bottom border-color-normal d-flex align-items-center mt14">
      <svg class="mr8 cursor-pointer" :class="{'rotate-180': showTasks}" v-on:click="showTasks = !showTasks" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fz18 fw500">Tasks</p>
    </div>
    <div class="position-relative" v-show="showTasks">
      <app-loader v-if="loadShowTasks" :is-local="true"></app-loader>
      <folders-list-file-and-folder @open="(item)=> openPage(item, 'Tasks')" :no-files-short="true" :localMode="true" :local-folders="filesTasks.filter(a=> a.file_type == 'FOLDER')" :local-files="filesTasks.filter(a=> a.file_type == 'FILE')"></folders-list-file-and-folder>
    </div>
    <div class="pb4 w100Proc border-bottom border-color-normal d-flex align-items-center mt14">
      <svg class="mr8 cursor-pointer" :class="{'rotate-180': showPayments}" v-on:click="showPayments = !showPayments" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fz18 fw500">Payments</p>
    </div>
    <div class="position-relative" v-show="showPayments">
      <app-loader v-if="loadShowPayment" :is-local="true"></app-loader>
      <folders-list-file-and-folder @open="(item)=> openPage(item, 'Payments')" :no-files-short="true" :localMode="true" :local-folders="filesPayments.filter(a=> a.file_type == 'FOLDER')" :local-files="filesPayments.filter(a=> a.file_type == 'FILE')"></folders-list-file-and-folder>
    </div>
    <div class="pb4 w100Proc border-bottom border-color-normal d-flex align-items-center mt14">
      <svg class="mr8 cursor-pointer" :class="{'rotate-180': showVessels}" v-on:click="showVessels = !showVessels" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fz18 fw500">Vessels</p>
    </div>
    <div class="position-relative" v-show="showVessels">
      <app-loader v-if="loadShowVessels" :is-local="true"></app-loader>
      <folders-list-file-and-folder @open="(item)=> openPage(item, 'Vessels')" :no-files-short="true" :localMode="true" :local-folders="filesVessels.filter(a=> a.file_type == 'FOLDER')" :local-files="filesVessels.filter(a=> a.file_type == 'FILE')"></folders-list-file-and-folder>
    </div>
    <div class="pb4 w100Proc border-bottom border-color-normal d-flex align-items-center mt14">
      <svg class="mr8 cursor-pointer" :class="{'rotate-180': showPorts}" v-on:click="showPorts = !showPorts" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fz18 fw500" v-show="showPorts">Ports</p>
    </div>
    <div class="position-relative" v-show="showPorts">
      <app-loader v-if="loadShowPorts" :is-local="true"></app-loader>
      <folders-list-file-and-folder @open="(item)=> openPage(item, 'Ports')" :no-files-short="true" :localMode="true" :local-folders="filesPorts.filter(a=> a.file_type == 'FOLDER')" :local-files="filesPorts.filter(a=> a.file_type == 'FILE')"></folders-list-file-and-folder>
    </div>
    <div class="pb4 w100Proc border-bottom border-color-normal d-flex align-items-center mt14">
      <svg class="mr8 cursor-pointer" :class="{'rotate-180': showCounterparties}" v-on:click="showCounterparties = !showCounterparties" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
      </svg>
      <p class="fz18 fw500">Counterparties</p>
    </div>
    <div class="position-relative" v-show="showCounterparties">
      <app-loader v-if="loadShowClient" :is-local="true"></app-loader>
      <folders-list-file-and-folder @open="(item)=> openPage(item, 'Counterparties')" :no-files-short="true" :localMode="true" :local-folders="filesClients.filter(a=> a.file_type == 'FOLDER')" :local-files="filesClients.filter(a=> a.file_type == 'FILE')"></folders-list-file-and-folder>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import FoldersListFileAndFolder from "@/components/folders/folders-list-file-and-folder";
import AppLoader from "@/components/loader.vue";
import moment from 'moment'
export default {
  name: "folders-important",
  components: {AppLoader, FoldersListFileAndFolder},
  props: {
    search: String,
    sort: String,
    routeChange: Number,
  },
  data() {
    return {
      showMyFiles: true,
      showPorts: true,
      showCounterparties: true,
      showVessels: true,
      showPayments: true,
      showTasks: true,
      showShared: true,
      filesMyFiles: [],
      filesSharedWithMe: [],
      loadShowMyFiles: false,
      loadShowSharedWithMe: false,
      loadShowTasks: false,
      filesTasks: [],
      filesPayments: [],
      loadShowPayment: false,
      loadShowVessels: 0,
      filesVessels: [],
      filesPorts: [],
      loadShowPorts: 0,
      loadShowClient: 0,
      filesClients: [],
      notLoad: false,
    }
  },
  watch: {
    search: function () {
      this.loadData()
    },
    sort: function () {
      this.loadData()
    },
    routeChange: function () {
      if(!this.notLoad) {
        this.loadData()
      }
    },
  },
  computed: {
    ...mapState({
      listSectionsIds: state => state.folders.listSectionsIds,
    }),
    getParentIdSection() {
        if(''+this.$route.params.is_archive_important == 'true') {
          return this.listSectionsIds.find(a=> a.name == "archive").id
        } else {
          return this.listSectionsIds.find(a=> a.name == "myfiles").id
        }
    },
  },
  methods: {
    ...mapActions(['axiosGetPathActivityToSection', 'axiosGetFoldersForClientImportant', 'axiosGetFoldersForPortImportant', 'axiosGetVesselsFoldersImportant', 'axiosGetFoldersForFtp', 'axiosGetIdsSectionsForFolders', 'axiosGetTaskFoldersImportant', 'axiosGetPaymentFoldersImportant']),
    ...mapMutations(['setNameSectionFolders', 'setIdFileForFocus']),
    async openPage(item, nameSection) {
      console.log('item', item)
      if(nameSection == 'My Files') {
        let res = await this.axiosGetPathActivityToSection({id: item.id, section: 'myfiles'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.id)
        if(data.full_path.length !== 0) {
          if(fileArchive) {
            this.$router.push({name: 'Folders - My files Folder Archive', params: {path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
          } else {
            this.$router.push({name: 'Folders - My files Folder', params: {path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
          }
        } else {
          if(fileArchive) {
            this.$router.push({name: 'Folders - My files Archive'})
          } else {
            this.$router.push({name: 'Folders - My files'})
          }
        }
      }
      if(nameSection == 'Shared with me') {
        let res = await this.axiosGetPathActivityToSection({id: item.id, section: ''})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.id)
        if(data.full_path.length == 0) {
          if(fileArchive) {
            this.$router.push({name: 'Folders - Shared with me Archive'})
          } else {
            this.$router.push({name: 'Folders - Shared with me'})
          }
        } else {
          if(fileArchive) {
            this.$router.push({name: 'Folders - Shared with me Folder Archive', path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))})
          } else {
            this.$router.push({name: 'Folders - Shared with me Folder', path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))})
          }
        }
      }
      if(nameSection == 'Tasks') {
        let res = await this.axiosGetPathActivityToSection({id: item.id, section: 'tasks'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.id)
        if(data.task.is_archived) {
          if(data.full_path.length !== 0) {
            if(data.task.type == 'MANUAL') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Archive Manually Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Archive Manually Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
            if(data.task.type == 'SHIP_SEARCH_WITH_CARGO') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Archive Vessel Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Archive Vessel Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
            if(data.task.type == 'CARGO_SEARCH_WITH_SHIP') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Archive Cargo Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Archive Cargo Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
          } else {
            if (data.task.type == 'MANUAL') {
              if (fileArchive) {
                this.$router.push({
                  name: 'Folders - Tasks Archive Manually Files Archive',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              } else {
                this.$router.push({
                  name: 'Folders - Tasks Archive Manually Files',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              }
            }
            if (data.task.type == 'SHIP_SEARCH_WITH_CARGO') {
              if (fileArchive) {
                this.$router.push({
                  name: 'Folders - Tasks Archive Vessel Files Archive',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              } else {
                this.$router.push({
                  name: 'Folders - Tasks Archive Vessel Files',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              }
            }
            if (data.task.type == 'CARGO_SEARCH_WITH_SHIP') {
              if (fileArchive) {
                this.$router.push({
                  name: 'Folders - Tasks Archive Cargo Files Archive',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              } else {
                this.$router.push({
                  name: 'Folders - Tasks Archive Cargo Files',
                  params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}
                })
              }
            }

          }
        } else {
          if(data.full_path.length !== 0) {
            if(data.task.type == 'MANUAL') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Manually Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Manually Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
            if(data.task.type == 'SHIP_SEARCH_WITH_CARGO') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Vessel Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Vessel Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
            if(data.task.type == 'CARGO_SEARCH_WITH_SHIP') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Cargo Files Archive Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              } else {
                this.$router.push({name: 'Folders - Tasks Cargo Files Path', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
              }
            }
          } else {
            if(data.task.type == 'MANUAL') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Manually Files Archive', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              } else {
                this.$router.push({name: 'Folders - Tasks Manually Files', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              }
            }
            if(data.task.type == 'SHIP_SEARCH_WITH_CARGO') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Vessel Files Archive', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              } else {
                this.$router.push({name: 'Folders - Tasks Vessel Files', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              }
            }
            if(data.task.type == 'CARGO_SEARCH_WITH_SHIP') {
              if(fileArchive) {
                this.$router.push({name: 'Folders - Tasks Cargo Files Archive', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              } else {
                this.$router.push({name: 'Folders - Tasks Cargo Files', params: {detailData: JSON.stringify({id: data.task.id, name: data.task.name})}})
              }
            }
          }
        }
      }
      if(nameSection == 'Payments') {
        let res = await this.axiosGetPathActivityToSection({id: item.id, section: 'payments'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.id)
        if(data.full_path.length) {
          if(data.payment.is_archive) {
            if(fileArchive) {
              this.$router.push({name: 'Folders - Payments Archive Folders Path Archive', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
            } else {
              this.$router.push({name: 'Folders - Payments Folders Path Archive', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
            }
          } else {
            if(fileArchive) {
              this.$router.push({name: 'Folders - Simple payment and archive folders - path', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
            } else {
              this.$router.push({name: 'Folders - Payments Folders Path', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'}), path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))}})
            }
          }
        } else {
          if(data.payment.is_archive) {
            if(fileArchive) {
              this.$router.push({name: 'Folders - Payments Folders Archive Path Archive', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})}})
            } else {
              this.$router.push({name: 'Folders - Payments Folders Archive', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})}})
            }
          } else {
            if(fileArchive) {
              this.$router.push({name: 'Folders - Payments Folders Archive Path', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})}})
            } else {
              this.$router.push({name: 'Folders - Payments Folders', params: {detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})}})
            }
          }
        }

      }
      if(nameSection == 'Vessels') {
        let res = await this.axiosGetPathActivityToSection({id: item.id, section: 'vessels'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.id)
        if(data.owner) {
          if(data.charterer) {
            this.$router.push({name: 'Folders - Vessels Option Charterers Files', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, charterersDetail: JSON.stringify({id: data.charterer.id, type: (data.charterer.contract_type == 'VOYAGE_CHARTERER' || data.charterer.contract_type == 'CARGO' ? 'Voyage' : data.charterer.contract_type == 'SUBLET' ? 'sublet' : ''), cp_date: moment(data.charterer.cp_date).format('DD.MM.YYYY'), owner_name: data.charterer.owner ? data.charterer.owner.company_name : ''}), charterers_is_archive: data.charterer.is_archive,   }})

          } else {
            this.$router.push({name: 'Folders - Vessels Option Owners', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), owners_is_archive: fileArchive, ownersDetail: JSON.stringify({id: data.owner.id})  }})
          }
        } else if(data.payment) {
          if(data.payment.data_type == 'payment') {
            if(data.full_path.length !== 0) {
              this.$router.push({name: 'Folders - Vessels Option CashFlow Files Path', params: { path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))  }})
            } else {
              this.$router.push({name: 'Folders - Vessels Option CashFlow Files', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, cash_flow_is_archive: data.payment.is_archive, detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})} })
            }
          } else {
            if(data.full_path.length !== 0) {
              this.$router.push({name: 'Folders - Vessels Option CashFlow Files Path', params: { path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))  }})
            } else {
              this.$router.push({name: 'Folders - Vessels Option CashFlow Files', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, cash_flow_is_archive: data.payment.is_archive, detailData: JSON.stringify({id: data.payment.id, name: data.payment.execute_date ? moment(data.payment.execute_date).format('DD.MM.YYYY') : 'Payment'})} })
            }
          }
        } else if(data.ship && data.charterer) {
          if(data.full_path.length !== 0) {
            this.$router.push({name: 'Folders - Vessels Option Charterers Files Path', params: {charterersDetail: JSON.stringify({id: data.charterer.id, type: (data.charterer.contract_type == 'VOYAGE_CHARTERER' || data.charterer.contract_type == 'CARGO' ? 'Voyage' : data.charterer.contract_type == 'SUBLET' ? 'sublet' : ''), cp_date: moment(data.charterer.cp_date).format('DD.MM.YYYY'), owner_name: data.charterer.owner ? data.charterer.owner.company_name : ''}), charterers_is_archive: data.charterer.is_archive, vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))  }})
          } else {
            this.$router.push({name: 'Folders - Vessels Option Charterers Files', params: {charterersDetail: JSON.stringify({id: data.charterer.id, type: (data.charterer.contract_type == 'VOYAGE_CHARTERER' || data.charterer.contract_type == 'CARGO' ? 'Voyage' : data.charterer.contract_type == 'SUBLET' ? 'sublet' : ''), cp_date: moment(data.charterer.cp_date).format('DD.MM.YYYY'), owner_name: data.charterer.owner ? data.charterer.owner.company_name : ''}), charterers_is_archive: data.charterer.is_archive, vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive}})
          }
        } else if(data.ship) {
          if(data.full_path.length !== 0) {
            this.$router.push({name: 'Folders - Vessels Option Vessel Detail Path', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive, path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}}))  }})
          } else {
            this.$router.push({name: 'Folders - Vessels Option Vessel Detail', params: {vessels_is_archive: data.ship.is_archive, detailVessel: JSON.stringify({name: data.ship.name, cp_date: data.ship.cp_date ? moment(data.ship.cp_date).format('DD.MM.YYYY') : '', id: data.ship.id}), files_is_archive: fileArchive}})
          }
        }
      }
      if(nameSection == 'Ports') {
        let res = await this.axiosGetPathActivityToSection({id: item.id, section: 'ports'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.id)
        if(!data.berth_list && !data.port_contract) {
          if(data.full_path.length !== 0) {
            this.$router.push({name: 'Folders - Ports Countries Ports Options Details Path', params: {countries_is_archive: false, countryDetail: JSON.stringify({name: data.country, idsPorts: data.ports_ids}), portDetail: JSON.stringify({name: data.port.name, id: data.port.id}), is_archive_files: fileArchive,  path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}})) }})
          } else {
            this.$router.push({name: 'Folders - Ports Countries Ports Options Details', params: {countries_is_archive: false, countryDetail: JSON.stringify({name: data.country, idsPorts: data.ports_ids}), portDetail: JSON.stringify({name: data.port.name, id: data.port.id}), is_archive_files: fileArchive }})
          }
        } else if(data.berth_list) {
          this.$router.push({name: 'Folders - Ports Countries Ports Options Berth List Detail', params: {is_archive_berth_list: data.berth_list.is_archive, detailList: JSON.stringify({name: data.berth_list.name, id: data.berth_list.id}),countries_is_archive: false, countryDetail: JSON.stringify({name: data.country, idsPorts: data.ports_ids}), portDetail: JSON.stringify({name: data.port.name, id: data.port.id}), is_archive_files: fileArchive }})
        } else if(data.port_contract) {
          this.$router.push({name: 'Folders - Ports Countries Ports Options Contact Files', params: {is_archive_contacts: data.port_contract.is_archive, detailContact: JSON.stringify({name: data.port_contract.dwt +  (data.port_contract.amount_local ?  '/'+ data.port_contract.amount_local : '') + ( data.port_contract.source ? '/'+data.port_contract.source : ''), id: data.port_contract.id}),countries_is_archive: false, countryDetail: JSON.stringify({name: data.country, idsPorts: data.ports_ids}), portDetail: JSON.stringify({name: data.port.name, id: data.port.id}), is_archive_files: fileArchive }})
        }

      }
      if(nameSection == 'Counterparties') {
        let res = await this.axiosGetPathActivityToSection({id: item.id, section: 'counterparties'})
        console.log(res)
        let data = res.data
        let fileArchive = data.full_path[0].is_archive
        data.full_path = data.full_path.filter(a=> a.id !== item.id)
        if(data.client) {
          if(data.full_path.length !== 0) {
            this.$router.push({name: 'Folders - Counterparties Options Detail Path', params: {is_archive_counterparties: data.client.is_archived, detailCounterparties: JSON.stringify({id: data.client.id, name: data.client.company_name }), is_archive_files: fileArchive, path: JSON.stringify(data.full_path.map(a=> {return {id: a.id, name: a.name}})) }})
          } else {
            this.$router.push({name: 'Folders - Counterparties Options Detail', params: {is_archive_counterparties: data.client.is_archived, detailCounterparties: JSON.stringify({id: data.client.id, name: data.client.company_name }), is_archive_files: fileArchive}})
          }
        }
      }
      setTimeout(()=> {
        this.setIdFileForFocus(item.id)
      }, 50)
    },

    loadData() {
      this.loadFolderByType('My Files')
      this.loadFolderByType('Shared with me')
      this.loadFolderByType('Tasks')
      this.loadFolderByType('Payments')
      this.loadFolderByType('Vessels')
      this.loadFolderByType('Ports')
      this.loadFolderByType('Counterparties')


    },
    async loadFolderByType(type) {
      let res = null
      switch (type) {
        case 'My Files':
          this.loadShowMyFiles = true
          res = await this.axiosGetFoldersForFtp({params:{is_important: true, ordering: this.sort, search: this.search, parent_file: this.getParentIdSection}})
          this.filesMyFiles = res.results
          this.loadShowMyFiles = false
          break;
        case 'Shared with me':
          this.loadShowSharedWithMe = true
          res = await this.axiosGetFoldersForFtp({params:{is_important: true, is_archive: this.$route.params.is_archive_important, ordering: this.sort, search: this.search, shared_for_me: true, offset: 0}})
          this.filesSharedWithMe = res.results
          this.loadShowSharedWithMe = false
          break;
        case 'Tasks':
          this.loadShowTasks = true
          res = await this.axiosGetTaskFoldersImportant({params: {for_important: true, is_archive: this.$route.params.is_archive_important, ordering: this.sort, search: this.search}})
          this.filesTasks = res.results
          this.loadShowTasks = false
          break;
        case 'Payments':
          this.loadShowPayment = true
          res = await this.axiosGetPaymentFoldersImportant({params: {data_type: 'payment', ftp: true, for_important: true, ordering: this.sort, search: this.search, is_archive_files:this.$route.params.is_archive_important}})
          this.filesPayments = res.results
          this.loadShowPayment = false
          break;
        case 'Vessels':
          this.filesVessels = []
          this.loadShowVessels = 0
          this.loadShowVessels = 1
          this.axiosGetVesselsFoldersImportant({params: {for_important: true, is_archive: this.$route.params.is_archive_important, ordering: this.sort, search: this.search}}).then(res=> {
            this.loadShowVessels = 0
            this.filesVessels.push(...res.results)
          })
          // this.loadShowVessels++
          // this.axiosGetPaymentFoldersImportant({params: {ftp: true, for_important: true, data_type: 'payment', ordering: this.sort, search: this.search, is_archive_files:false, only_with_ship_or_settled_ship: true}}).then(res=> {
          //   this.loadShowVessels--
          //   this.filesVessels.push(...res.results)
          // })
          // this.loadShowVessels++
          // this.axiosGetPaymentFoldersImportant({params: {ftp: true, for_important: true, data_type: 'invoice', ordering: this.sort, search: this.search, is_archive_files:false, only_with_ship_or_settled_ship: true}}).then(res=> {
          //   this.loadShowVessels--
          //   this.filesVessels.push(...res.results)
          // })
          break;
        case 'Ports':
          this.filesPorts = []
          this.loadShowPorts = 0
          this.loadShowPorts = 1
          this.axiosGetFoldersForPortImportant({params: {for_important: true, is_archive: this.$route.params.is_archive_important, ordering: this.sort, search: this.search}}).then(res=> {
            this.loadShowPorts = 0
            this.filesPorts.push(...res.results)
          })
          break;
        case 'Counterparties':
          this.filesClients = []
          this.loadShowClient = 0
          this.loadShowClient++
          this.axiosGetFoldersForClientImportant({params: {for_important: true, is_archive: this.$route.params.is_archive_important, ordering: this.sort, search: this.search, offset: 0}}).then(res=> {
            this.loadShowClient--
            this.filesClients.push(...res.results)
          })
          this.loadShowClient++
          this.axiosGetPaymentFoldersImportant({params: {ftp: true, for_important: true, ordering: this.sort, search: this.search, is_archive_files:this.$route.params.is_archive_important, only_with_client: true}}).then(res=> {
            this.loadShowClient--
            this.filesClients.push(...res.results)
          })
          break;
      }
    }
  },
  async mounted() {
    this.notLoad = true
    this.setNameSectionFolders('Important')
    await this.axiosGetIdsSectionsForFolders();
    this.loadData()
    this.notLoad = false
  }
}
</script>

<style scoped lang="scss">
  /deep/ {
    .fz18.fw600.text-dark {
      font-size: 0.875rem !important;
      line-height: 1.0629rem !important;
    }
    .files-list-tile, .folders-list {
      border: none !important;
    }

  }
  .position-relative {
    /deep/ {
      .wrap-loader-local {
        opacity: 0.7;
        background-color: var(--color-bgc-page);
      }
    }
  }
</style>