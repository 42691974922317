<template>
  <div class="bread p-x-6 p-y-3 bg-white">
    <a href="#/ports" class="bread__link">Ports / Canals</a>

    <svg class="bread__separator" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"/></svg>

    <span class="bread__link">Archive Ports / Canals</span>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>