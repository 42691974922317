<template>
  <div class="async_select__wrap">
    <multiselect
      v-model="selected"
      :options="options"
      @input="$emit('selected', $event)"
      :show-labels="false"
      :placeholder="placeholder"
    >
      <template slot="option" slot-scope="props">
        <p class="option__name">{{ props.option }}</p>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    options: [Object, Array],
    placeholder: String,
    selectedValue: String,
    clear: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    
  },
  watch: {
    clear: function(val){
      if(val) this.selected = null
    }
  },
  activated(){
    console.log('active')
  },
  mounted() {
    if (this.selectedValue) {
      this.selected = this.selectedValue;
    }
  },
};
</script>

<style lang="scss" scoped>
.option__name {
  position: relative;
  width: 100%;
  padding: 6px 0;
}
.multiselect__option--selected {
  display: flex;
  align-items: center;
  .option__name {
    &:after {
      content: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z' fill='%23319FEF'/%3E%3C/svg%3E ");
      position: absolute;
      background: none;
      right: 6px;
    }
  }
}
</style>
<style lang="scss">
.invisible-select {
  .multiselect__tags {
    border: none;
    background: none;
    font-size: 14px;
  }
  .multiselect{
    width: fit-content;
  }
  .multiselect__single{
    font-size: 14px;
  }
}
</style>
