<template>
  <section class="page_section__wrap">
<!--    <payment-modal-create :cash-flow="true" ref="modalCreate"></payment-modal-create>-->
    <div class="page_section overflow-hidden">
      <bread :links="contractBread" :options="['edit', 'archive']" class="position-relative z-index-10" @on-edit="onEdit" @on-archive="onArchive"/>
      
      <router-view v-if="contract"></router-view>
    </div>
  </section>
</template>

<script>
import bread from '@/components/reuse/bread'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import moment from 'moment';
// import PaymentModalCreate from "@/components/payment/payment-modal-create/payment-modal-create";

export default {
  components: {
    // PaymentModalCreate,
    bread,
  },
  data() {
    return {
    }
  },
  watch: {
    getEmitAddPaymentForVessels: function () {
      this.showModalCreate();
    },
    refreshBreadEvent: function() {
      this.changeContractBread({
        index: 1,
        obj: {
          name: this.contract.name + ' ' + moment(this.contract.cp_date).format('DD.MM.YYYY'),
          link: `/vessels/${this.$route.params.id}/open/detail`
        }
      })
    },
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract,
      contractBread: state => state.contract.contractBread,
      refreshBreadEvent: state=> state.contract.refreshBreadEvent
    }),
    ...mapGetters(["getEmitAddPaymentForVessels", "getDataDetailContract"])
  },
  methods: {
    ...mapMutations(["setDataContract", 'changeContractBread', "emitEditContract"]),
    ...mapActions(["axiosChangeParametersForContract", 'axiosGetClientsById', 'checkContract', 'axiosGetContractById']),
    showModalCreate() {
      this.$refs.modalCreate.openModal();
    },
    onEdit() {
      // console.log('lol');
      this.emitEditContract();
    },
    onArchive() {
      let form = {
        "is_archive": true
      }
      this.axiosChangeParametersForContract({id: this.$route.params.id, data: form}).then(res=> {
        if(res === true) {
          if(this.getDataDetailContract !== null) {
            if(this.getDataDetailContract.is_redelivery) {
              this.$router.push({name: "Vessels Redelivered"})
            } else {
              this.$router.push({name: "Vessels"})
            }
          } else {
            this.$router.push({name: "Vessels"})
          }
        }
      });
    },
  },
  async beforeMount() {
    await this.checkContract(this.$route.params.id)
    this.setDataContract(this.contract);

    this.contract.cargo_contracts.forEach((el, i) => {
      if(Number.isInteger(el))
        this.axiosGetContractById(el).then(response => {
          this.contract.cargo_contracts[i] = response
        })
    })

    await this.$nextTick()
    this.changeContractBread({
      index: 1,
      obj: {
        name: this.contract.name + ' ' + moment(this.contract.cp_date).format('DD.MM.YYYY'),
        link: `/vessels/${this.$route.params.id}/open/detail`
      }
    })

    if(this.contract.owner && Number.isInteger(this.contract.owner))
      this.contract.owner = await this.axiosGetClientsById(this.contract.owner)

    if(this.$route.query.from == 'counterparties' && !this.$route.query.newcharterer){
      this.emitEditContract()
    }
  }
}
</script>

<style lang="scss">
.vessels_detail_nav{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-gray-light);
  }
}
</style>