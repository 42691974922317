<template>
  <div v-click-outside="closeList" :class="{'displayNone': !isShow}" class="wrap-list" :style="styleObject">
    <input type="text" :class="{'p-4-2' : listSettings.nameValue === 'currency'}" class="search-input" ref="search" v-model="searchText" @focus="focusEvent" @input="search" v-on:keydown="keyDownEvent">
<!--    {'bgc-2': indexChangingPayment && (indexChangingPayment%2 !== 0), 'bgc-white': indexChangingPayment && (indexChangingPayment%2 === 0) || indexChangingPayment === 0-->
    <div class="list" ref="list" :class="{'displayNone': !isShowResults}" v-if="listSettings.nameValue !== 'bank'">
      <button class="fz14" v-for="(item, i) in getFilteredResults" :key="i" v-on:click="selectItem(item)" :class="{'selected': listSettings.value && ((typeof listSettings.value === 'object' && item.id === listSettings.value.id) || (typeof listSettings.value === 'string' && item.id === listSettings.value))}">
        {{listSettings.watchKeyBefore && listSettings.watchKeyBefore === 'account_id' ? item[listSettings.watchKeyBefore]+':' : ''}} {{listSettings.nameValue !== 'statement_member' ? item[listSettings.watchKey] : getFormatCpDate(item[listSettings.watchKey])}} {{listSettings.watchKeyAfter && listSettings.watchKeyAfter !== 'cp_date' ? (item[listSettings.watchKeyAfter]) : ''}} {{listSettings.watchKeyAfter && listSettings.watchKeyAfter === 'cp_date' ? '('+getFormatCpDate(item[listSettings.watchKeyAfter])+')' : ''}}
        <svg v-if="listSettings.value && ((typeof listSettings.value === 'object' && item.id === listSettings.value.id) || (typeof listSettings.value === 'string' && item.id === listSettings.value))" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="option_check m-l-auto"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"></path></svg>
      </button>
      <p class="fz14 empty" v-if="!getFilteredResults.length">List is empty</p>
    </div>
    <div class="list" ref="list" v-else :class="{'displayNone': !isShowResults}">
      <div class="w100Proc" v-for="(item, i) in getBanks(results)" :key="i" >
        <button class="fz14 text-gray cursor-default">{{item.name}}</button>
        <button class="fz14" v-for="(requisite, k) in item.requisites" :key="k" v-on:click="selectItem(requisite)" :class="{'selected': listSettings.value && listSettings.value.bank && listSettings.value.bank.id === requisite.id}">
          {{item.type === 'bank' ? requisite.account.account_id + ': ' + requisite.account.name : requisite.name}}
          <svg v-if="listSettings.value && listSettings.value.bank && listSettings.value.bank.id === requisite.id" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="option_check m-l-auto"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"></path></svg>
        </button>
      </div>
      <div class="w100Proc">
        <button class="fz14 text-gray cursor-default" v-if="getCashes(results).length">Cash Accounts</button>
        <button class="fz14" v-for="(cash, k) in getCashes(results)" :key="k" v-on:click="selectItem(cash)" :class="{'selected': listSettings.value && listSettings.value.cash && listSettings.value.cash.id === cash.id}">
          {{cash.name}}
          <svg v-if="listSettings.value && listSettings.value.cash && listSettings.value.cash.id === cash.id" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="option_check m-l-auto"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"></path></svg>
        </button>
      </div>
      <div class="w100Proc">
        <p class="fz14 empty" v-if="!getCashes(results).length && !getBanks(results).length">List is empty</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import moment from 'moment'
export default {
  name: "element-select-list",
  props: {
    indexChangingPayment: {
      default: null,
    },
  },
  data() {
    return {
      searchText: '',
      selectItemEmit: false,
      styleObject: {
        left: 0,
        top: 0,
        width: 0,
      },
    }
  },
  watch: {
    listSettings: function () {
      this.searchText =  this.listSettings && this.listSettings.searchText ? this.listSettings.searchText : ''
      this.value = this.listSettings.value;
      if(this.listSettings.element) {
        this.styleObject.left = (Number(this.listSettings.element.offsetLeft)+1) + 'px';
        this.styleObject.width = this.listSettings.element.clientWidth + 'px'
        // console.log(this.listSettings.element.getAttribute())
        let top = this.listSettings.element.parentElement.parentElement.style.transform
        top = (''+top).slice(top.indexOf('translateY'), top.indexOf('px')+1)
        console.log('top', top)
        // console.log('ss',Number((''+this.listSettings.element.parentElement.parentElement.style.transform).replace(/[^0-9]/g, "") ))
        this.styleObject.top = (Number((''+top).replace(/[^0-9]/g, "")) + 45) + 'px';
        console.log(this.styleObject.top )
        // this.styleObject.top = this.listSettings.element.clientTop
      }
    },
    isShow: function() {
      if(this.isShow) {
        setTimeout(()=> {
          this.$refs.search.focus()
        }, 50)
      }
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapState({
      listSettings: state => state.paymentListXY.listSettings,
      isShow: state => state.paymentListXY.isShow,
      isLoad: state => state.paymentListXY.isLoad,
      results: state => state.paymentListXY.results,
      isShowResults: state => state.paymentListXY.isShowResults,
    }),
    getFilteredResults() {
      return Array.isArray(this.listSettings.localList) && this.listSettings.localList.length !== 0 ? this.results.filter(el => ((el[this.listSettings.watchKey]).toLowerCase()).indexOf((''+this.searchText).toLowerCase()) >= 0) : this.results;
    }
  },
  methods: {
    ...mapActions(['axiosGetDataForList', 'clickTabPayment']),
    ...mapMutations(['closeListPayment', 'setShowListResults', 'setSelectedItem', '']),
    keyDownEvent(e) {
      console.log(e)
      if(e.keyCode === 40 || e.keyCode === 38) {
        if(e.keyCode === 40) { // down
          // console.log(this.$refs.list.querySelector('.navigation-element-list'))
          if(!this.$refs.list.querySelector('.navigation-element-list')) {
            let element = this.$refs.list.querySelector('.selected');
            element = element ? element : this.$refs.list.children[0]
            element.classList.add('navigation-element-list')
            // console.dir(element)
            this.$refs.list.scrollTo(0, element.offsetTop - (200 - element.clientHeight) / 2)
          } else{
            let element = this.$refs.list.querySelector('.navigation-element-list');
            if(element.nextElementSibling) {
              element.classList.remove('navigation-element-list')
              element.nextElementSibling.classList.add('navigation-element-list')
              this.$refs.list.scrollTo(0, element.nextElementSibling.offsetTop - (200 - element.nextElementSibling.clientHeight) / 2)
            } else if(this.listSettings.nameValue === 'bank') {
              let element = this.$refs.list.querySelector('.navigation-element-list');
              if(element.parentNode.nextElementSibling) {
                element.classList.remove('navigation-element-list')
                element.parentNode.nextElementSibling.children[0].classList.add('navigation-element-list')
                this.$refs.list.scrollTo(0, element.parentNode.nextElementSibling.children[0].offsetTop - (200 - element.parentNode.nextElementSibling.children[0].clientHeight) / 2)
              }

            }
          }
          // console.log(this.$refs.list)

          // let scrollTop = this.$refs.list.scrollTop;
          // console.log('scrollTop', scrollTop)
          // this.$refs.list.scrollTo(0,scrollTop + 180)
        } else { // up
          // let scrollTop = this.$refs.list.scrollTop;
          // console.log('scrollTop', scrollTop)
          // this.$refs.list.scrollTo(0,scrollTop - 180)
          if(!this.$refs.list.querySelector('.navigation-element-list')) {
            let element = this.$refs.list.querySelector('.selected');
            element = element ? element : this.$refs.list.children[0]
            element.classList.add('navigation-element-list')
            this.$refs.list.scrollTo(0, element.offsetTop - (200 - element.clientHeight) / 2)
          } else{
            let element = this.$refs.list.querySelector('.navigation-element-list');
            if(element.previousElementSibling) {
              element.classList.remove('navigation-element-list')
              element.previousElementSibling.classList.add('navigation-element-list')
              this.$refs.list.scrollTo(0, element.previousElementSibling.offsetTop - (200 - element.previousElementSibling.clientHeight) / 2)
            } else if(this.listSettings.nameValue === 'bank') {
              let element = this.$refs.list.querySelector('.navigation-element-list');
              if (element.parentNode.previousElementSibling) {
                element.classList.remove('navigation-element-list')
                element.parentNode.previousElementSibling.children[element.parentNode.previousElementSibling.children.length-1].classList.add('navigation-element-list')
                this.$refs.list.scrollTo(0, element.parentNode.previousElementSibling.children[element.parentNode.previousElementSibling.children.length-1].offsetTop - (200 - element.parentNode.previousElementSibling.children[element.parentNode.previousElementSibling.children.length-1].clientHeight) / 2)
              }
            }
          }
        }
      }
      if(e.keyCode === 13) { //enter
        if(this.$refs.list.querySelector('.navigation-element-list')) {
          let element = this.$refs.list.querySelector('.navigation-element-list');
          element.click()
        }
      }
      if(e.keyCode === 9) { // tab
        e.preventDefault()
        this.clickTabPayment(this.listSettings.element)
      }
    },
    getBanks(array1) {
      let array = JSON.parse(JSON.stringify(array1))
      let arrayLocal = array.map(a=> {
        if(a.type === 'bank') {
          a.requisites = a.requisites.filter(requisite=> (requisite.account.account_id + ': ' + requisite.account.name).toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0)
        }
        return a
      })
      return arrayLocal.filter(a=> a.type === 'bank' && a.requisites.length)
    },
    getCashes(array) {
      return array.filter(a=> a.type === 'cash' && a.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0)
    },
    getFormatCpDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    focusEvent() {
      if(!this.isLoad)
      this.setShowListResults(true)
    },
    closeList() {
      if(this.listSettings.nameValueText  && this.listSettings.nameValueText.length) {
        if(!this.selectItemEmit && this.searchText !== this.listSettings.searchText) {
          console.log({value: this.listSettings.value, searchText: this.searchText})
          this.setSelectedItem({value: this.listSettings.value, searchText: this.searchText})
        }
      }
      this.searchText = ''
      this.closeListPayment();
      this.selectItemEmit = false
    },
    search() {
      if(!(Array.isArray(this.listSettings.localList) && this.listSettings.localList.length !== 0) && this.listSettings.url && this.listSettings.nameValue !== 'bank') {
        this.axiosGetDataForList({url: this.listSettings.url, params: this.listSettings.params, searchText: this.searchText})
      }
    },
    selectItem(item) {
      this.selectItemEmit = true
      if((this.listSettings.nameValue === 'currency') || (this.listSettings.nameValue === 'statement_member')) {
        this.setSelectedItem({value: item.name !== this.value ? item.name : '', searchText: this.searchText})
      } else {
        if(item?.id !== this.value?.id) {
          this.setSelectedItem({value: item, searchText: this.searchText})
        } else {
          this.setSelectedItem(null)
        }
      }

      this.closeList()
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.bgc-2 {
  background-color: var(--color-white-blue);
}
.search-input {
  background-color: var(--color-gray-dark);
  border-radius: 0;
  height: 30px;
  border: 2px solid var(--color-yellow);
  padding: 4px;
  padding-top: 5px;
  box-sizing: border-box;
  font-size: 0.8125rem !important;
  line-height: 1rem !important;
}
  .list {
    position: absolute;
    top: 31px;
    max-height: 200px !important;
    width: 204px;
    background-color: var(--color-card);
    min-height: 20px;
    overflow-y: auto;
    border: 1px solid #e8e8e8;
    .empty {
      padding: 6px;
      width: 100%;
      height: 30px;
      box-sizing: border-box;
    }
    button {
      position: relative;
      padding: 6px;
      text-align: left;
      min-height: 30px;
      padding-right: 30px;
      height: max-content;
      box-sizing: border-box;
      width: 100%;
      word-break: break-word;
      color: var(--color-dark);
      &:not(.text-gray):hover {
        background-color: var(--color-hover-list);
      }
      svg {
        top: 3px;
        position: absolute;
        right: 4px;
      }
    }
  }
  .wrap-list {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;
  }
  .dart-mode {
    background-color: var(--color-hover-list)
  }
  .p-4-2 {
    padding: 4px 2px 4px 3px;
  }
.text-gray {
  color: var(--color-gray) !important;
}

.navigation-element-list {
  background-color: var(--color-hover-list);
}

</style>