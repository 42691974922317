<template>
  <div class="border border-dark-gray m-b-4 rounded-lg overflow-hidden" v-if="filteredSpeedData.length">
    <div class="vessel_owners__table">
      <div class="first_element_stroke colspan-1-6 bg-blue-dark uppercase p-x-3 p-y-2 fz13 fw500 text-white">SPEED CLAIM</div>
    </div>

    <div 
      v-for="(item, i) in filteredSpeedData"
      :key="i"
      class="vessel_owners__table-wrap bg-white overflow-hidden"
      :class="{'border-t border-dark-gray': i > 0}"
    >
      <div class="vessel_owners__table">
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark fw500">{{(''+item.index_hire_selected).length !== 0 && + item.index_hire_selected !== null && item.index_hire_selected > -1? 'Hire #'+ (1+item.index_hire_selected) : item.index_hire_selected === -2 ? 'Custom' : 'Not selected'}}</div>
        <div class="colspan-2-4 border-r p-x-2 p-y-2 text-dark relative">
          {{item.additional_info.title ? item.additional_info.title : ''}}
        </div>
        <div class="colspan-4-7 p-x-2 p-y-2 text-dark relative justify-start pr95">
          {{item.additional_info.hire_rate | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">Rate,&nbsp;{{activeCurrency}}/day</span>
        </div>
      </div>


      <div class="vessel_owners__table" v-if="item.additional_info.is_days">
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark">Time Lost</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative pr30">
          {{item.additional_info.hours | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">hrs</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative pr40">
          {{item.additional_info.days | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">Days</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right">{{item.time_lost_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right">{{item.time_lost_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="item.additional_info.is_commission && (item.additional_info.commision ||
          item.commision_debit ||
          item.commision_credit)"
      >
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark fw500">Commission</div>
        <div class="border-t border-r"></div>
        <div class="border-t border-r relative p-x-2 p-y-2 pr20">
          {{item.additional_info.commision | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">%</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right">{{item.commision_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right">{{item.commision_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="item.additional_info.is_cev && (item.additional_info.rate ||
          item.cev_debit ||
          item.cev_credit)"
      >
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark fw500">C/E/V</div>
        <div class="border-t border-r"></div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark relative pr95">
          {{item.additional_info.rate | formatNumberTwoZeroEmpty}}
          <span class="absolute top-2 right-2 text-gray-text">Rate, {{activeCurrency}}/PMPR</span>
        </div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right">{{item.cev_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right">{{item.cev_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="(item.additional_info.is_hsfo && (item.additional_info.hsfo_quantity ||
          item.additional_info.hsfo_price ||
          item.hsfo_debit ||
          item.hsfo_credit)) ||
          (item.additional_info.is_lsmgo && (item.additional_info.lsmgo_quantity ||
          item.additional_info.lsmgo_price ||
          item.lsmgo_debit ||
          item.lsmgo_credit))"
      >
        <div class="first_element_stroke bg-white-dark border-t border-r p-x-3 p-y-2 text-dark fw500">Bunkers overconsumed during Speed claim</div>
        <div class="bg-white-dark border-t border-r p-x-2 p-y-2 text-dark fz13 text-gray-text">Qntty, mt</div>
        <div class="bg-white-dark border-t border-r p-x-2 p-y-2 text-dark fz13 text-gray-text">Price, {{activeCurrency}}/mt</div>
        <div class="bg-white-dark border-t border-r"></div>
        <div class="bg-white-dark border-t"></div>
      </div>

      <div class="vessel_owners__table"
        v-if="item.additional_info.is_hsfo && (item.additional_info.hsfo_quantity ||
          item.additional_info.hsfo_price ||
          item.hsfo_debit ||
          item.hsfo_credit)"
      >
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark">VLSFO</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{item.additional_info.hsfo_quantity | formatNumberThreeZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{item.additional_info.hsfo_price | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right">{{item.hsfo_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right">{{item.hsfo_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="item.additional_info.is_lsmgo && (item.additional_info.lsmgo_quantity ||
          item.additional_info.lsmgo_price ||
          item.lsmgo_debit ||
          item.lsmgo_credit)"
      >
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark">LSMGO</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{item.additional_info.lsmgo_quantity | formatNumberThreeZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{item.additional_info.lsmgo_price | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right">{{item.lsmgo_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right">{{item.lsmgo_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table" v-if="item.additional_info.comment">
        <div class="first_element_stroke border-t border-r p-x-3 p-y-2 text-dark">Comment</div>
        <div class="border-t colspan-2-5 border-r p-x-2 p-y-2 text-dark">{{item.additional_info.comment}}</div>
        <div class="border-t"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeCurrency: String,
    speedData: Array
  },
  computed: {
    filteredSpeedData() {
      return this.speedData.filter(el => {
        return el.title ||
          el.additional_info.days ||
          el.time_lost_debit ||
          el.time_lost_credit ||
          el.additional_info.commision ||
          el.commision_debit ||
          el.commision_credit ||
          el.additional_info.rate ||
          el.cev_debit ||
          el.cev_credit ||
          el.additional_info.hsfo_quantity ||
          el.additional_info.hsfo_price ||
          el.hsfo_debit ||
          el.hsfo_credit ||
          el.additional_info.lsmgo_quantity ||
          el.lsmgo_debit ||
          el.lsmgo_credit ||
          el.additional_info.comment
      })
    }
  }
}
</script>

<style scoped>
.pr30 {
  padding-right: 30px !important;
}
.pr40 {
  padding-right: 40px !important;
}
.pr95 {
  padding-right: 95px !important;
}
.pr20 {
  padding-right: 20px !important;
}

</style>