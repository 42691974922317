<template>
  <div class="calc_vessels__dot_row border-b border-gray-light bg-white active:child-inputs-inset-shadow">
    <div  v-tooltip.left="'full_results:'+dot.full_result + ' '+'eco_results:'+dot.eco_result+ ' '+'slow_results:'+dot.slow_result" class="fz0 justify-center">
      <button class="fz0 cursor-grab handle" v-if="index && index < routes.length-1">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.432 7.468a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 0 1 2.6 0ZM16.268 7.468a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 0 1 2.6 0Z" fill="#9AA3B0"/><circle cx="9.132" cy="12.097" r="1.3" fill="#9AA3B0"/><circle cx="14.968" cy="12.097" r="1.3" fill="#9AA3B0"/><path d="M10.432 16.73a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 0 1 2.6 0ZM16.268 16.73a1.3 1.3 0 1 1-2.6 0 1.3 1.3 0 0 1 2.6 0Z" fill="#9AA3B0"/></svg>
      </button>
    </div>

    <div class="fz0 justify-center relative">
      <button class="fz0" v-if="index < routes.length-1" @click="$emit('on-add')">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.222 11.222h-4.444V6.778a.778.778 0 0 0-1.556 0v4.444H6.778a.778.778 0 0 0 0 1.556h4.444v4.444a.778.778 0 0 0 1.556 0v-4.444h4.444a.778.778 0 0 0 0-1.556Z" fill="#9AA3B0"/></svg>
      </button>
    </div>

    <div class="" v-tooltip="dot.intake ? `${dot.intake}` : dot.status == 'passing' || dot.status == 'bunkering' ? null : 'Balast'">
      <span v-if="index == 0" class="p-x-1 p-y-1 fz13 fw500 uppercase">Delivery:</span>
      <span v-else-if="index == routes.length-1" class="p-x-1 p-y-1 fz13 fw500 uppercase">Redelivery:</span>
      <span v-else-if="dot.status == 'passing' || dot.status == 'bunkering'" class="p-x-1 p-y-1 fz13 fw500 uppercase"></span>
      <input 
        v-else
        type="text"
        class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
        :value="dot.intake ? dot.intake : 'Balast'"
        @blur="onBlurIntake"
        @input="dot.intake = (''+dot.intake).replace(/[^0-9]/g, '')"
        @keyup.enter="$event.target.blur()"
      >
    </div>

    <div class="" v-tooltip="dot.port ? `${dot.port.name}` : null">
<!--      <span class="p-x-1 p-y-1 fz13 overflow-ellipsis whitespace-nowrap overflow-hidden" v-if="dot.route_id">{{dot.port.name}}</span>-->
<!--      <async-select-->
<!--        v-else-->
<!--        class="w-full active:child-inputs-shadow-none"-->
<!--        :id="'port-dot'+index"-->
<!--        :allowEmpty="true"-->
<!--        :closeOnSelect="true"-->
<!--        :clearOnSelect="true"-->
<!--        v-model="dot.port"-->
<!--        openDirection="top"-->
<!--        @on-select="onSelectPort"-->
<!--        url="/port/dropdown"-->
<!--        :params="{is_archive: false}"-->
<!--        watchKey="name"-->
<!--        afterWatchKeyBrackets="country"-->
<!--        placeholder=""-->
<!--      />-->
      <async-select
          class="w-full active:child-inputs-shadow-none"
          :id="'port-dot'+index"
          :allowEmpty="true"
          :closeOnSelect="true"
          :clearOnSelect="true"
          v-model="dot.port"
          openDirection="top"
          @on-select="onSelectPort"
          url="/port/netpas"
          :params="{is_archive: false}"
          watchKey="name"
          afterWatchKeyBrackets="country"
          placeholder=""
      />
<!--             url="/port/netpas"
  -->

    </div>

    <div class="" v-tooltip="dot.status ? `${dot.status}` : null">
<!--      <span v-if="dot.route_id || index == 0 || index == routes.length-1" class="p-x-1 p-y-1 fz13 capitalize">{{dot.status}}</span>-->
<!--      <default-select-->
<!--        v-else-->
<!--        class="w-full capitalize-child default_select__fz13"-->
<!--        :id="'status-'+index"-->
<!--        :closeOnSelect="true"-->
<!--        :searchable="false"-->
<!--        trackBy=""-->
<!--        :options="statusList"-->
<!--        v-model="dot.status"-->
<!--        openDirection="top"-->
<!--        placeholder="Status"-->
<!--      />-->
      <default-select
          class="w-full capitalize-child default_select__fz13 default-selected"
          :id="'status-'+index"
          :closeOnSelect="true"
          :searchable="false"
          trackBy=""
          :disabled="index == 0 || index == routes.length-1"
          :options="statusList"
          v-model="dot.status"
          openDirection="top"
          placeholder="Status"
          @on-select="checkCargos"
      />
    </div>

    <div class="" v-tooltip="dot.dist ? `${dot.dist}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="dot.dist"
        @keypress="onlyNumberTwoAfterDot($event)"
        @blur="$emit('on-update-dist')"
        @keyup.enter="$event.target.blur()"
      >
    </div>

    <div class="relative" v-tooltip="dot.seca ? `${dot.seca}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-l-1 p-r-6 border-0 bg-transparent rounded-none fz13"
        :class="{'text-gray-light': !dot.has_seca}"
        v-model.number.lazy="dot.seca"
        @keypress="onlyNumberTwoAfterDot($event)"
        @blur="$emit('on-update-seca')"
        @keyup.enter="$event.target.blur()"
      >
      <checkbox class="absolute right-1 centered-y" v-model="dot.has_seca" @change="$emit('on-update-seca')"/>
    </div>

    <div class="" v-tooltip="dot.draft_restr ? `${dot.draft_restr}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="dot.draft_restr"
        @keypress="onlyNumberTwoAfterDot($event)"
        @blur="$emit('on-update-draft')"
        @keyup.enter="$event.target.blur()"
      >
    </div>

    <div class="" v-tooltip="dot.disbursement ? `${dot.disbursement}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="dot.disbursement"
        @keypress="onlyNumberTwoAfterDot($event)"
        @blur="$emit('on-set-disbursement')"
        @keyup.enter="$event.target.blur()"
      >
    </div>

    <div class="relative" v-tooltip="dot[fuelType+'_streaming_days'] ? `${dot[fuelType+'_streaming_days']}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-l-1 p-r-4 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="dot[fuelType+'_streaming_days']"
        @keypress="onlyNumberTwoAfterDot($event)"
        @blur="$emit('on-update-streaming')"
        @keyup.enter="$event.target.blur()"
      >
<!--      <span class="absolute centered-y right-1 text-gray-text fz13">d</span>-->
    </div>
    <div class="" v-tooltip="dot.port_stay ? `${dot.port_stay}` : null">
      <input
          type="number"
          class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
          v-model.number.lazy="dot.port_stay"
          @keypress="onlyNumberTwoAfterDot($event)"
          @blur="$emit('on-update-port-stay')"
          @keyup.enter="$event.target.blur()"
      >
    </div>

<!--    <div class="relative" v-tooltip="dot[fuelType+'_streaming_time'] ? `${dot[fuelType+'_streaming_time']}` : null">-->
<!--      <input-->
<!--        type="text"-->
<!--        class="p-y-1.5 p-l-1 p-r-4 border-0 bg-transparent rounded-none fz13"-->
<!--        ref="time_streaming"-->
<!--        v-model.lazy="dot[fuelType+'_streaming_time']"-->
<!--        @blur="$emit('on-update-streaming')"-->
<!--        @keyup.enter="$event.target.blur()"-->
<!--      >-->
<!--      <span class="absolute centered-y right-1 text-gray-text fz13">h</span>-->
<!--    </div>-->





    <div class="" v-tooltip="dot.lay_ex ? `${dot.lay_ex}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="dot.lay_ex"
        @change="$emit('on-update-lay-ex')"
        @keypress="onlyNumberTwoAfterDot($event)"
        @keyup.enter="$event.target.blur()"
      >
    </div>



    <div class="" v-tooltip="dotSpeed ? `${dotSpeed}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="dotSpeed"
        disabled
      >
    </div>

    <div class="flex p-y-0.5 fz0 justify-center" :class="{'disable-check': index == 0}">
      <checkbox v-if="fuelType == 'full'" :value="true" :disabled="true"/>
      <checkbox v-else-if="fuelType == 'eco' || fuelType == 'slow'" :value="false" :disabled="true"/>
      <checkbox v-else-if="index !== 0" v-model="dot.is_full" :disabled="false"/>
      <checkbox v-else :value="false" :disabled="true"/>
    </div>
    <div class="flex p-y-0.5 fz0 justify-center" :class="{'disable-check': index == 0}">
      <checkbox v-if="fuelType == 'eco'" :value="true" :disabled="true"/>
      <checkbox v-else-if="fuelType == 'full' || fuelType == 'slow'" :value="false" :disabled="true"/>
      <checkbox v-else-if="index !== 0" v-model="dot.is_eco" :disabled="false"/>
      <checkbox v-else :value="false" :disabled="true"/>
    </div>
    <div class="flex p-y-0.5 fz0 justify-center" :class="{'disable-check': index == 0}">
      <checkbox v-if="fuelType == 'slow'" :value="true" :disabled="true"/>
      <checkbox v-else-if="fuelType == 'full' || fuelType == 'eco'" :value="false" :disabled="true"/>
      <checkbox v-else-if="index !== 0" v-model="dot.is_slow" :disabled="false"/>
      <checkbox v-else :value="false" :disabled="true"/>
    </div>

    <div class="" v-tooltip="dot[fuelType+'_ifo_mt'] ? `${dot[fuelType+'_ifo_mt']}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="dot[fuelType+'_ifo_mt']"
        @keypress="onlyNumberTwoAfterDot($event)"
        @blur="$emit('on-update-ifo-mgo')"
        @keyup.enter="$event.target.blur()"
      >
    </div>

    <div class="" v-tooltip="dot[fuelType+'_mgo_mt'] ? `${dot[fuelType+'_mgo_mt']}` : null">
      <input 
        type="number"
        class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
        v-model.number.lazy="dot[fuelType+'_mgo_mt']"
        @keypress="onlyNumberTwoAfterDot($event)"
        @blur="$emit('on-update-ifo-mgo')"
        @keyup.enter="$event.target.blur()"
      >
    </div>

    <div class="" v-tooltip="dot[fuelType+'_arrival_date'] ? `${dot[fuelType+'_arrival_date']}` : null">
      <input
          type="text"
          class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
          placeholder="dd.mm.yy"
          v-model.lazy="dot[fuelType+'_arrival_date']"
          @keyup.enter="$event.target.blur()"
      >
    </div>

    <div class="" v-tooltip="dot[fuelType+'_arrival_time'] ? `${dot[fuelType+'_arrival_time']}` : null">
      <input
          type="text"
          class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
          placeholder="hh:mm"
          ref="arrival_time"
          v-model.lazy="dot[fuelType+'_arrival_time']"
          @keyup.enter="$event.target.blur()"
      >
    </div>
    <div class="" v-tooltip="dot[fuelType+'_departure_date'] ? `${dot[fuelType+'_departure_date']}` : null">
      <input
          type="text"
          class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
          placeholder="dd.mm.yy"
          v-model.lazy="dot[fuelType+'_departure_date']"
          @keyup.enter="$event.target.blur()"
      >
    </div>

    <div class="" v-tooltip="dot[fuelType+'_departure_time'] ? `${dot[fuelType+'_departure_time']}` : null">
      <input
          type="text"
          class="p-y-1.5 p-x-1 border-0 bg-transparent rounded-none fz13"
          placeholder="hh:mm"
          ref="departure_time"
          v-model.lazy="dot[fuelType+'_departure_time']"
          @keyup.enter="$event.target.blur()"
      >
    </div>
    <div class="flex p-y-0.5 fz0 justify-center">
      <button class="fz0" @click="$emit('on-delete')" v-if="index && index < routes.length-1">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.636 4.366a.225.225 0 0 0-.227.223v.675h3.182v-.675a.225.225 0 0 0-.227-.223h-2.728Zm4.319.898v-.675A1.59 1.59 0 0 0 13.364 3h-2.728a1.59 1.59 0 0 0-1.59 1.589v.675H5.181a.682.682 0 0 0 0 1.366h1.136v9.876A2.497 2.497 0 0 0 8.818 19h6.364c1.377 0 2.5-1.113 2.5-2.494V6.63h1.136a.682.682 0 0 0 0-1.366h-3.864ZM7.681 6.63v9.876c0 .62.505 1.128 1.136 1.128h6.364c.63 0 1.136-.509 1.136-1.128V6.63H7.682Zm2.954 2.256c.377 0 .682.306.682.683v4.981a.682.682 0 1 1-1.363 0V9.57c0-.378.305-.684.681-.684Zm2.728 0c.376 0 .681.306.681.683v4.981a.682.682 0 1 1-1.363 0V9.57c0-.378.305-.684.682-.684Z" fill="#9AA3B0"/></svg>
      </button>
    </div>
  </div>
</template>

<script>
import defaultSelect from '@/components/reuse/defaultSelect'
import asyncSelect from '@/components/reuse/asyncSelect'
import checkbox from '@/components/reuse/checkbox'
import Inputmask from "inputmask"
import { mapActions } from 'vuex'

export default {
  props: {
    dot: Object,
    routes: Array,
    cargos: Array,
    fuelType: String,
    vessel: Object,
    index: Number
  },
  components: {
    defaultSelect,
    asyncSelect,
    checkbox,
  },
  data() {
    return {
      // statusList: ['loading', 'discharging', 'passing', 'bunkering'],
      statusList: ['loading', 'discharging', 'passing'],
      dotSpeed: 0
    }
  },
  watch: {
    vessel() {
      this.onSetSpeed()
    },
    fuelType() {
      this.onSetSpeed()
      this.onSetOptimal()
    },
    'dot.is_eco': function(newVal){
      if(newVal){
        this.dot.is_full = false
        this.dot.is_slow = false
        this.onChangeCheck()
      }
    },
    'dot.is_full': function(newVal){
      if(newVal){
        this.dot.is_eco = false
        this.dot.is_slow = false
        this.onChangeCheck()
      }
    },
    'dot.is_slow': function(newVal){
      if(newVal){
        this.dot.is_eco = false
        this.dot.is_full = false
        this.onChangeCheck()
      }
    }
  },
  computed: {
  },
  methods: {
    ...mapActions(['onlyNumberTwoAfterDot']),
    checkCargos() {
      // if(this.dot)
      // this.detail.loading_port_name = '#'+this.detail.loading_port.route_id
      // let router = this.routeList.find(a=> a.route_id == this.detail.loading_port_name.replace('#',''))
      // let cargo = this.cargos.find(a=> .loading_port_name.replace('#',''))
      // let IdsCargos = this.cargos.filter(a=> this.port && ( (a.loading_port && a.loading_port.id == this.port.id && this.dot.status === 'loading') || (a.discharging_port && a.discharging_port.id == this.port.id && this.dot.status === 'discharging'))).map(a=> {return a.id})
      this.cargos.forEach(cargo=> {
        if((''+cargo.loading_port_name).replace('#','') == this.dot.route_id) {
          cargo.loading_port = null
          cargo.loading_port_name = ''
          // this.dot.cargos = this.dot.cargos.filter(a=> a !== this.dot.id && IdsCargos.indexOf(a) >= 0)
          // console.log(cargo)
          this.$emit('checkRoutesAndCargos')
        }
        if((''+cargo.discharging_port_name).replace('#','') == this.dot.route_id) {
          cargo.discharging_port = null
          cargo.discharging_port_name = ''
          // this.dot.cargos = this.dot.cargos.filter(a=> a !== this.dot.id && IdsCargos.indexOf(a) >= 0)
          // console.log(cargo)
          this.$emit('checkRoutesAndCargos')
        }
      })
    },
    checkNum(num) {
      let countAfterDot = 2
      if((''+num).indexOf(".")>=0) {
        num=''+num
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");
        if(num2.length > countAfterDot) {
          num2 = num2.substring(0, countAfterDot);
        }
        num = num1 + "." + num2;
      } else {
        num = (''+num).replace(/[^0-9]/g, "")
      }

      return num ? num : null;
    },
    onBlurIntake(e) {
      this.dot.intake = parseFloat(e.target.value) ? parseFloat(e.target.value) : null
      e.target.value = parseFloat(e.target.value) ? e.target.value : 'Balast'

      this.$emit('on-update-intake')
    },
    onSetSpeed() {
      // let speed = 0
      // let suff = this.dot.intake ? '_sp_lad' : '_sp_ball'
      // if(this.fuelType != 'optimal') speed = this.vessel[this.fuelType+suff]
      // else speed = this.dot.optimal_speed
      //
      // this.dotSpeed = speed
      let array = JSON.parse(JSON.stringify(this.routes))
      let arrayLadBall = []
      let intake = 0;
      let ladBool = false;
      array.forEach((item, index)=> {
        if(index === 0) {
          arrayLadBall.push('ball')
        } else {
          if(ladBool) {
            arrayLadBall.push('lad')
          } else {
            arrayLadBall.push('ball')
          }
          if(item.status === 'loading') {
            ladBool = true;
            intake += parseFloat(item.intake) ? parseFloat(item.intake) : 0
          }
          if(item.status === 'discharging') {
            intake -= parseFloat(item.intake) ? parseFloat(item.intake) : 0
            if(intake === 0) {
              ladBool = false
            }
          }
        }
      })
      function getLabOrBal(index) {
        return arrayLadBall[index] ? arrayLadBall[index] : 'ball'
      }
      // let suff = this.dot.intake ? '_sp_lad' : '_sp_ball'
      let suff = getLabOrBal(this.index)

      // let preff = 'full'
      // if(this.dot.is_eco){
      //   preff = 'eco'
      // }else if(this.dot.is_slow){
      //   preff = 'slow'
      // }
      // console.log('arrayLadBall', arrayLadBall, this.index)
      // console.log('suff', suff, this.vessel[preff+'_sp_'+suff])
      let speed = 0
      if(this.fuelType != 'optimal') speed = this.vessel[this.fuelType+'_sp_'+suff]
      else speed = this.dot.optimal_speed

      this.dotSpeed = speed
    },
    onChangeCheck() {
      let array = JSON.parse(JSON.stringify(this.routes))
      let arrayLadBall = []
      let intake = 0;
      let ladBool = false;
      array.forEach((item, index)=> {
        if(index === 0) {
          arrayLadBall.push('ball')
        } else {
          if(ladBool) {
            arrayLadBall.push('lad')
          } else {
            arrayLadBall.push('ball')
          }
          if(item.status === 'loading') {
            ladBool = true;
            intake += parseFloat(item.intake) ? parseFloat(item.intake) : 0
          }
          if(item.status === 'discharging') {
            intake -= parseFloat(item.intake) ? parseFloat(item.intake) : 0
            if(intake === 0) {
              ladBool = false
            }
          }
        }
      })
      function getLabOrBal(index) {
        return arrayLadBall[index] ? arrayLadBall[index] : 'ball'
      }
      // let suff = this.dot.intake ? '_sp_lad' : '_sp_ball'
      let suff = getLabOrBal(this.index)

      let preff = 'full'
      if(this.dot.is_eco){
        preff = 'eco'
      }else if(this.dot.is_slow){
        preff = 'slow'
      }
      // console.log('arrayLadBall', arrayLadBall, this.index)
      // console.log('suff', suff, this.vessel[preff+'_sp_'+suff])
      this.dotSpeed = this.vessel[preff+'_sp_'+suff]

      //
      // let suff = this.dot.intake ? '_sp_lad' : '_sp_ball'
      //
      // let preff = 'full'
      // if(this.dot.is_eco){
      //   preff = 'eco'
      // }else if(this.dot.is_slow){
      //   preff = 'slow'
      // }
      // this.dotSpeed = this.vessel[preff+suff]

      this.dot.optimal_ifo_mt = this.dot[preff+'_ifo_mt']
      this.dot.optimal_mgo_mt = this.dot[preff+'_mgo_mt']
      this.dot.optimal_result = this.dot[preff+'_result']
      this.dot.optimal_speed = this.dotSpeed
      this.$emit('on-change-check')
    },
    onSetOptimal() {
      // if ((this.dot.eco_result < this.dot.full_result) && (this.dot.eco_result < this.dot.slow_result) && this.dot.eco_result !== 0 || (this.dot.slow_result === 0 && this.dot.full_result === 0)) {
      //   this.dot.is_full = false
      //   this.dot.is_slow = false
      //   this.dot.is_eco = true
      // } else if ((this.dot.full_result < this.dot.slow_result && this.dot.full_result !== 0) || this.dot.slow_result === 0) {
      //   this.dot.is_eco = false
      //   this.dot.is_slow = false
      //   this.dot.is_full = true
      // } else {
      //   this.dot.is_eco = false
      //   this.dot.is_full = false
      //   this.dot.is_slow = true
      // }
      // console.log('this.dot.eco_result:', this.dot.eco_result, 'this.dot.full_result:', this.dot.full_result, 'this.dot.slow_result:', this.dot.slow_result)
      if (this.dot.full_result != 0 && ((this.dot.full_result <= this.dot.slow_result && this.dot.full_result <= this.dot.eco_result && this.dot.eco_result != 0 && this.dot.slow_result != 0) || (this.dot.full_result <= this.dot.slow_result && this.dot.slow_result != 0 && this.dot.eco_result == 0) || (this.dot.full_result <= this.dot.eco_result && this.dot.eco_result != 0 && this.dot.slow_result == 0) || (this.dot.eco_result == 0 && this.dot.slow_result == 0))) {
        this.dot.is_eco = false
        this.dot.is_slow = false
        this.dot.is_full = true
      } else if (this.dot.eco_result != 0 && ((this.dot.eco_result <= this.dot.slow_result && this.dot.eco_result <= this.dot.full_result && this.dot.full_result != 0 && this.dot.slow_result != 0) || (this.dot.eco_result <= this.dot.slow_result && this.dot.slow_result != 0 && this.dot.full_result == 0) || (this.dot.eco_result <= this.dot.full_result && this.dot.full_result != 0 && this.dot.slow_result == 0) || (this.dot.slow_result == 0 && this.dot.full_result == 0))) {
        this.dot.is_full = false
        this.dot.is_slow = false
        this.dot.is_eco = true
      } else if (this.dot.slow_result != 0 && ((this.dot.slow_result <= this.dot.eco_result && this.dot.slow_result <= this.dot.full_result && this.dot.full_result != 0 && this.dot.eco_result != 0) || (this.dot.slow_result <= this.dot.full_result && this.dot.full_result != 0 && this.dot.eco_result == 0) || (this.dot.slow_result <= this.dot.eco_result && this.dot.eco_result != 0 && this.dot.full_result == 0) || (this.dot.full_result == 0 && this.dot.eco_result == 0))) {
        this.dot.is_eco = false
        this.dot.is_full = false
        this.dot.is_slow = true
      } else if (this.dot.full_result == 0 && this.dot.eco_result == 0 && this.dot.slow_result == 0) {
        this.dot.is_eco = false
        this.dot.is_full = false
        this.dot.is_slow = true
      }
    },
    onSelectPort(newPort, oldPort) {
      this.checkCargos()
      /* if(this.dot.port) {
        this.dot.port_name = this.dot.port.name
      }else{
        this.dot.port_name = ''
      } */
    //write console log
      this.$emit('on-select-port', newPort, oldPort)
      if(this.index < this.routes.length-1 && !this.routes[this.index+1].port) {
        setTimeout(()=> {
          // console.log(document.getElementById('port-dot'+(this.index+1)))
          let item = document.getElementById('port-dot'+(this.index+1))
          item.focus()
        }, 300)
      }
    }
  },
  mounted() {
    this.onSetSpeed()
    if(this.$refs.streaming_time && !this.$refs.streaming_time.inputmask)
      Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.streaming_time)
    if(this.$refs.arrival_time && !this.$refs.arrival_time.inputmask)
      Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.arrival_time)
    if(this.$refs.departure_time && !this.$refs.departure_time.inputmask)
      Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.departure_time)

  }
}
</script>

<style lang="scss" scoped>
  .disable-check {
    background-color: var(--color-anti-flash-white);
    /deep/ {
      svg {
        opacity: 0.5;
      }
    }
  }
  .default-selected {
    /deep/ {
      .multiselect--disabled {
        background-color: var(--color-anti-flash-white);
        &>* {
          opacity: 0.7;
        }
        .multiselect__select {
          background: transparent !important;
        }
      }
    }
  }

</style>