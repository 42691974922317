<template>
  <div class="bank-aside_block bank-aside_list">
    <div class="bank-aside_block__title fz13 fw500" :class="{collapsed: collaps}" @click="toggleHeight">
      {{title}}
      <svg v-if="collaps" :class="{collaps: isCollapsed && collaps}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.27752 7.60001C9.5448 7.60001 9.67865 7.27686 9.48966 7.08787L6.21391 3.81214C6.09675 3.69498 5.9068 3.69498 5.78965 3.81214L2.51392 7.08787C2.32493 7.27686 2.45878 7.60001 2.72605 7.60001L9.27752 7.60001Z" fill="#2C2C2C"/></svg>
    </div>

    <div class="bank-aside_block__list" :class="{collaps: isCollapsed && collaps, progress: ifProgress}" :style="{height: listHeight + 'px'}" ref="list">
      <div class="bank-aside_block__item" v-for="item in list" :key="item.name+item.id" ref="listItem" :class="{active: item.id == activeBank.id}" @click="setActive(item)">
        <div class="bank-aside_block__item-icon">
          <svg v-if="item.type == 'bank'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="32" height="32" rx="8" fill="#319FEF"/><path d="M7 12.2585V12.7991C7 13.6715 7.70966 14.3811 8.58203 14.3811H9.10937V19.822C8.49688 20.0404 8.05469 20.6203 8.05469 21.3069V21.8343H23.9453V21.3069C23.9453 20.6203 23.5032 20.0404 22.8906 19.822V14.3811H23.418C24.2903 14.3811 25 13.6715 25 12.7991V12.2585H7ZM14.3828 19.7249H12.2734V14.3811H14.3828V19.7249ZM19.7266 19.7249H17.6172V14.3811H19.7266V19.7249Z" fill="white"/><path d="M16.2706 7.05439C16.1223 6.98024 15.8769 6.98024 15.7286 7.05439L7.40234 11.2175H24.5969L16.2706 7.05439Z" fill="white"/><path d="M7 22.7735V24.4727C7 24.7639 7.23611 25 7.52734 25H24.4727C24.7639 25 25 24.7639 25 24.4727V22.7735H7Z" fill="white"/></svg>
          <svg v-else width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="34" height="34" rx="8" fill="#0FA54E"/><g clip-path="url(#clip0)"><path d="M12.1201 13.0244L20.0478 17.7279V17.7659L14.306 21.1559C14.1922 21.2317 14.0405 21.2317 13.9267 21.1559L6.53007 16.9075C6.30248 16.7558 6.30248 16.4523 6.53007 16.3006L12.1201 13.0244Z" fill="white"/><path d="M27.6313 12.9914L23.1933 15.5968L15.2656 10.8933L20.0331 8.18605C20.1469 8.11018 20.2986 8.11018 20.4124 8.18605L27.6313 12.3845C27.8589 12.5363 27.8589 12.8776 27.6313 12.9914Z" fill="white"/><path d="M13.6497 22.0645C13.9621 22.244 14.347 22.2415 14.657 22.0579L20.0108 18.8891V20.3825L14.3178 23.7305C14.1939 23.8033 14.0404 23.8041 13.9157 23.7325L6.58081 19.5166C6.31835 19.3658 6.3121 18.9893 6.5694 18.8298L7.26583 18.3981L13.6497 22.0645Z" fill="white"/><path d="M27.556 15.0693C27.7716 15.2461 27.7448 15.5837 27.5039 15.7242L23.3594 18.1414V16.648L26.9369 14.5618L27.556 15.0693Z" fill="white"/><path d="M13.9473 24.8451C14.0753 24.9199 14.2341 24.9179 14.36 24.8398L20.0108 21.3372V22.9943L14.3195 26.4627C14.1948 26.5387 14.0387 26.5407 13.9121 26.4679L6.56502 22.2451C6.30628 22.0963 6.29562 21.7269 6.54536 21.5635L7.39155 21.0099L13.9473 24.8451Z" fill="white"/><path d="M27.4841 17.8017C27.7249 17.9683 27.711 18.3286 27.458 18.4761L23.3594 20.8666V19.3732L26.6854 17.249L27.4841 17.8017Z" fill="white"/><path d="M22.537 16.2264V21.4609L20.83 22.523V17.1747L12.9023 12.4712L14.5713 11.447L22.499 16.1885L22.537 16.2264Z" fill="white"/></g><defs><clipPath id="clip0"><rect width="22" height="22" fill="white" transform="translate(6 6)"/></clipPath></defs></svg>
        </div>
        <div class="bank-aside_block__item-info">
          <p class="bank-aside_block__item-name fz14 fw500">{{item.name}}</p>
          <div class="bank-aside_block__item-balance">
            
            <div v-if="item.type == 'cash'">
              <div class="bank-aside_block__item-balance_all fz14 fw500">{{item.balance | formatNumber}} {{item.currency}}</div>
              <div class="bank-aside_block__item-balance_convert" v-if="item.currency != 'USD'">(<convert :currency="item.currency" :value="item.balance" /> USD)</div>
            </div>
            <convert-requisites :requisites="getRequisitesNotArchive(item.requisites)" v-else />
          </div>
        </div>
        <counterparties-list-option-btn @editItem="$emit('edit-bank', item)" @archiveItem="$emit('archive-bank', item)" :canEdit="getPermissionsByType('edit_bank_and_cash_accounts')" :canArchive="getPermissionsByType('archive_bank_and_cash_accounts')"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
//import moment from 'moment';

import convert from '@/components/accounts/bankAndCash/bankAsideConvert'
import convertRequisites from '@/components/accounts/bankAndCash/bankAsideConvertRequisites'
import counterpartiesListOptionBtn from '@/components/counterparties/counterpartiesList/counterpartiesListOptionBtn'
export default {
  components: { counterpartiesListOptionBtn, convert, convertRequisites },
  props: {
    title: {
      type: String,
      default: 'List'
    },
    collaps: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isCollapsed: true,
      ifProgress: false,
      listHeight: 0,
      rate: null
    }
  },
  watch: {
    list() {
      this.setHeight()
    }
  },
  computed: {
    ...mapState({
      activeBank: state => state.banks.activeBank,
    }),
    ...mapGetters(['getPermissionsByType']),
    
  },
  methods: {
    ...mapMutations(['setActiveBank']),
    setActive(item) {
      if(item.type == 'bank')
        this.setActiveBank(item)
    },
    toggleHeight() {
      this.isCollapsed = !this.isCollapsed
      this.ifProgress = true
      setTimeout(()=>{
        this.ifProgress = false
      }, 310)
    },
    async setHeight() {
      this.listHeight = 0
      await this.$nextTick()
      if(this.$refs.listItem){
        this.$refs.listItem.forEach(el => {
          this.listHeight += el.offsetHeight
        })
      }
    },
    getRequisitesNotArchive(list) {
      return list.filter(el=>{
        return el.is_archive == false
      })
    },
  },
  async mounted() {
    await this.$nextTick()
    this.setHeight()
  }
}
</script>

<style lang="scss">
.bank-aside_block{
    &__title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;
      padding: 9px 5px 8px 24px;
      border-bottom: 1px solid var(--color-card-border);
      transition: background-color 0.3s;
      &.collapsed{
        cursor: pointer;
        &:hover{
          background-color: var(--color-input);
        }
        svg{
          transition: transform 0.3s;
        }
        .collaps{
          transform: rotate(-180deg);
        }
      }
    }
    &__list{
      transition: height 0.3s;
      &.progress{
        overflow: hidden;
      }
      &.collaps{
        height: 0 !important;
        overflow: hidden;
      }
    }
    &__item{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 12px 10px 12px 24px;
      border-bottom: 1px solid var(--color-card-border);
      transition: background-color 0.3s;
      cursor: pointer;
      &.active{
        background-color: var(--color-input);
      }
      &:hover{
        background-color: var(--color-input);
        .bank-aside_block__item-name,
        .bank-aside_block__item-balance{
          color: var(--color-blue-dark);
        }
      }
      &-icon{
        margin-right: 12px;
      }
      &-info{
        width: 100%;
      }
      &-name{
        word-break: break-all;
        transition: color 0.3s;
      }
      &-balance{
        margin-top: 4px;
        color: #9AA3B0;
        transition: color 0.3s;
        &_convert{
          margin-top: 6px;
          font-size: 15px;
        }
      }
      .option-btn_wrap{
        margin-top: 2px;
        margin-left: 12px;
      }
    }
  }
</style>