<template>
  <div class="invoice_top_inputs m-b-6 bg-white rounded-lg shadow border border-dark">
    <div class="p16-24 pb24 pt2 flex flex-wrap justify-between border-bottom border-color-normal pt20">
      <div class="block-input soa_due_date">
        <p class="fz13 text-gray-text m-b-1">Due date</p>
        <date-picker
            placeholder="Choose date"
            class="datepickeer invoice_detail__datepicker w-full"
            v-model="detail.due_date"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD HH:mm:ss"
        ></date-picker>
      </div>

      <label class="block-input">
        <p class="fz13 text-gray-text m-b-1">{{ getCharterer.contract_type == 'VOYAGE_CHARTERER' ? 'Owner' : 'Charterer' }}</p>
        <input class="block-input_content" type="text" :value="getCharterer.owner.company_name" disabled>
      </label>

      <label class="block-input">
        <p class="fz13 text-gray-text m-b-1">Cargo</p>
        <input class="block-input_content" type="text" v-model="detail.cargo" maxlength="1246">
      </label>

      <counterparties-drop
          v-if="getPermissionsByType('show_input_bank_and_cash_accounts_in_freight_additional_freight_detention_invoices_and_voyage_statement_of_account')"
          :list="getRequisites"
          :activeItem="activeAccount"
          @select="(item)=>{detail.account = item.id}"
          title="Bank Account"
          @change="changeTest"
      ></counterparties-drop>

      <label class="block-input">
        <p class="fz13 text-gray-text m-b-1">Vessel</p>
        <input class="block-input_content" type="text" v-model="detail.ship_name" disabled>
      </label>

      <counterparties-drop
          :list="valutaList"
          :removeSelectItemBeforeClick="false"
          :activeItem="activeCurrency"
          @select="(item)=>{detail.currency = item.id}"
          title="Currency"
      ></counterparties-drop>

      <label class="block-input">
        <p class="fz13 text-gray-text m-b-1">CP Date</p>
        <input class="block-input_content" type="text" :value="detail.cp_date" disabled>
      </label>

      <div class="block-input soa_due_date">
        <p class="fz13 text-gray-text m-b-1">Invoice date</p>
        <date-picker
            placeholder="Choose date"
            class="datepickeer invoice_detail__datepicker w-full"
            v-model="detail.invoice_date"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
        ></date-picker>
      </div>
      <div class="block-input soa_due_date">
        <p class="fz13 text-gray-text m-b-1">BL Dated</p>
        <date-picker
            placeholder="Choose date"
            class="datepickeer invoice_detail__datepicker w-full"
            v-model="detail.bl_dated"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
        ></date-picker>
      </div>
      <div class="block-input soa_due_date" v-if="$route.path.indexOf('vsoa') >= 0">
        <p class="fz13 text-gray-text m-b-1">Cargo operations start date</p>
        <date-picker
            :class="{'error-date': errorStartDate}"
            placeholder="Choose date"
            class="datepickeer invoice_detail__datepicker w-full"
            v-model="detail.cargo_operations_start_date"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
        ></date-picker>
      </div>
      <div class="block-input soa_due_date" v-if="$route.path.indexOf('vsoa') >= 0">
        <p class="fz13 text-gray-text m-b-1">Cargo operations end date</p>
        <date-picker
            :class="{'error-date': errorEndDate}"
            placeholder="Choose date"
            class="datepickeer invoice_detail__datepicker w-full"
            v-model="detail.cargo_operations_end_date"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
        ></date-picker>
      </div>
      <div class="block-input" v-if="$route.path.indexOf('vsoa') >= 0"></div>
      <div class="block-input soa_due_date" v-show="$route.path.indexOf('vsoa') >= 0">
        <p class="fz13 text-gray-text m-b-1">Cargo operations start time</p>
<!--        <date-picker-->
<!--            placeholder="Choose time"-->
<!--            class="datepickeer invoice_detail__datepicker w-full"-->
<!--            v-model="detail.cargo_operations_start_time"-->
<!--            format="HH:mm"-->
<!--            value-type="HH:mm"-->
<!--        ></date-picker>-->
        <div class="w100Proc relative d-flex align-items-center">
          <span class="absolute right-2 text-gray">UTC</span>
          <input type="text" class="h36" placeholder="hh:mm" ref="to_time" v-model.lazy="detail.cargo_operations_start_time">
        </div>
      </div>
      <div class="block-input soa_due_date" v-show="$route.path.indexOf('vsoa') >= 0">
        <p class="fz13 text-gray-text m-b-1">Cargo operations end time</p>
<!--        <date-picker-->
<!--            placeholder="Choose time"-->
<!--            class="datepickeer invoice_detail__datepicker w-full"-->
<!--            v-model="detail.cargo_operations_end_time"-->
<!--            format="HH:mm"-->
<!--            value-type="HH:mm"-->
<!--        ></date-picker>-->
        <div class="w100Proc relative d-flex align-items-center">
          <span class="absolute right-2 text-gray">UTC</span>
          <input type="text" class="h36" placeholder="hh:mm" ref="from_time" v-model.lazy="detail.cargo_operations_end_time">
        </div>

      </div>
      <div class="block-input soa_due_date" v-if="$route.path.indexOf('vsoa') >= 0"></div>

      <!--    <label class="block-input">&lt;!&ndash; for spacing &ndash;&gt;</label>-->

      <label class="block-input_wide m-t-3">
        <p class="fz13 text-gray-text m-b-1">IMO number</p>
        <textarea v-model="detail.imo_number" maxlength="50" class="w-full" rows="2"></textarea>
      </label>
      <label class="block-input_wide m-t-3">
        <p class="fz13 text-gray-text m-b-1">Full style</p>
        <div class="relative">
          <textarea :value="getChartererFullStyle" disabled class="w-full" rows="2" style="padding-right: 40%"></textarea>
          <label class="block absolute centered-y right-4 fz14">
            Show in export
            <switcher id="is-full-style" class="m-l-2 switcher_dark-bg" v-model="detail.has_full_style"/>
          </label>
        </div>
      </label>

<!--      <async-select-port-->
<!--          class="block-input"-->
<!--          :id="'load_port'+2"-->
<!--          label="Port of loading"-->
<!--          v-model="detail.loading_port"-->
<!--          :allowEmpty="true"-->
<!--      />-->
<!--      <async-select-port-->
<!--          class="block-input"-->
<!--          :id="'discharge_port'+1"-->
<!--          label="Port of discharge"-->
<!--          v-model="detail.discharge_port"-->
<!--          :allowEmpty="true"-->
<!--      />-->
<!--      <label class="block-input">-->
<!--        <p class="fz13 text-gray-text m-b-1">Total Loaded Quantity</p>-->
<!--        <input-format-number-->
<!--            :fractionDigits="3"-->
<!--            v-model="detail.total_quantity"-->
<!--            inputClass="block-input_content p-r-8"-->
<!--        >-->
<!--          <template v-slot:right>-->
<!--            <span class="absolute centered-y right-3 fz14">mt</span>-->
<!--          </template>-->
<!--        </input-format-number>-->
<!--      </label>-->
    </div>
    <div class="p16-24 pb24 pt8 border-bottom border-color-normal" v-if="detail.ports.length">
      <div v-for="(item, i) in detail.ports" :key="i+'djk3 '+detail.ports.length">
        <div class="d-flex align-items-center position-relative mt12">
          <p class="fz14 fw500 text-dark">Loading/Discharging Port #{{i+1}}</p>
          <button class="blue-button-text position-absolute r0 fz13" v-on:click="detail.ports.splice(i, 1)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#094172"/>
            </svg>
            Delete
          </button>
        </div>
        <div class="flex flex-wrap justify-between">
          <async-select-port
              class="block-input"
              :id="'load_port'+i"
              label="Port of loading"
              v-model="item.loading_port"
              :allowEmpty="true"
          />
          <async-select-port
              class="block-input"
              :id="'discharge_port'+i"
              label="Port of discharge"
              v-model="item.discharge_port"
              :allowEmpty="true"
          />
          <label class="block-input">
            <p class="fz13 text-gray-text m-b-1">Total Loaded Quantity</p>
            <input-format-number
                :fractionDigits="3"
                v-model="item.total_quantity"
                inputClass="block-input_content p-r-8"
                @input="detail.ports.length-1 === i ? changeTotalQuantity(item.total_quantity) : null"
            >
              <template v-slot:right>
                <span class="absolute centered-y right-3 fz14">mt</span>
              </template>
            </input-format-number>
          </label>
        </div>
      </div>
    </div>
    <div class="p16-24">
      <button class="button-blue-border h32 w308" v-on:click="addNewPorts">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.2221 11.2222H12.7778V5.77773C12.7778 5.34849 12.4293 5 11.9999 5C11.5707 5 11.2222 5.34849 11.2222 5.77773V11.2222H5.77773C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77773 12.7778H11.2222V18.2221C11.2222 18.6515 11.5707 19 11.9999 19C12.4293 19 12.7778 18.6515 12.7778 18.2221V12.7778H18.2221C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2221 11.2222Z" fill="#094172"/>
        </svg>
        Add new Loading/Discharging Port
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from "moment";

import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import asyncSelectPort from '@/components/reuse/asyncSelectPort'
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import Switcher from '@/components/reuse/switcher.vue';
import Inputmask from "inputmask";

export default {
  components: {
    DatePicker,
    counterpartiesDrop,
    asyncSelectPort,
    inputFormatNumber,
    Switcher
  },
  props: {
    detail: Object,
    errorEndDate: Boolean,
    errorStartDate: Boolean,
  },
  data() {
    return {
      activeAccount: null,
      activeCurrency: null
    }
  },
  watch: {
    'detail.account': function () {
      // console.log(this.detail.account, this.getTrialsInDrop)
      // let item = this.getRequisites.filter(a=> a.id === this.detail.account)[0]
      // console.log('itre', item)
      // if(item) {
      //   this.detail.currency = item.currency
      //   this.activeCurrency = {id: item.currency, name: item.currency}
      // }
    }
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract,
      valutaList: state => state.payment.valutaList,
      requisites: state => state.banks.requisites,
      trials: state => state.trialBalance.trials,
      //detail: state => state.invoice.detail,
    }),
    ...mapGetters(['getPermissionsByType']),
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
    getTrialsInDrop() {
      return this.trials.map(el => {
        return {id: el.id, name: (this.getPermissionsByType('show_dop_info_for_acc_in_payments') ?  el.account_id+': '+el.name : el.name)}
      })
    },
    getChartererFullStyle() {
      return this.getCharterer.owner.full_style
    },
    getRequisites() {
      if(!this.requisites) return []
      return this.requisites.filter(el=>{
        return !el.is_archive
      }).map(el=>{
        return {id:el.id, currency: el.currency, name: el.cash.name+ (this.getTrialsInDrop.filter(a=> a.id === el.account.id).length ? (', ' + this.getTrialsInDrop.filter(a=> a.id === el.account.id)[0].name) : '')}
      })
    },
    getOceanFreight() {
      return this.detail.blocks.find(el => el.block_type == 'ILOW')
    },
  },
  methods: {
    ...mapActions([
      'axiosGetRequisites',
      'axiosGetTrials'
    ]),
    changeTest() {
      console.log(this.detail.account, this.getTrialsInDrop)
      let item = this.getRequisites.filter(a=> a.id === this.detail.account)[0]
      console.log('itre', item)
      if(item) {
        this.detail.currency = item.currency
        this.activeCurrency = {id: item.currency, name: item.currency}
      }
    },
    addNewPorts() {
      this.$emit('addNewPort')
    },
    changeTotalQuantity(rate) {
      if(this.$route.path.indexOf('vsoa')>= 0 || this.$route.path.indexOf('freight')>= 0 || this.$route.path.indexOf('additional_freight')>= 0) {
        if(this.getOceanFreight) {
          this.getOceanFreight.mt_amount = rate
        }
      }
    },
    onSetEdit() {
      console.log('this.getTrialsInDrop', this.getTrialsInDrop)
      let data = JSON.parse(JSON.stringify(this.detail))
      if(data.due_date)
        data.due_date = data.due_date ? moment(data.due_date).format('DD.MM.YYYY') : null
      console.log()
      if(data.account && typeof data.account == 'object'){
        this.activeAccount = {
          id: data.account?.id,
          name: this.getTrialsInDrop.filter(a=> a.id === data.account.account.id)[0].name
        }
      }
      this.activeCurrency = {
        id: data.currency,
        name: data.currency
      }
    },
  },
  async beforeMount() {
    await this.axiosGetTrials()
    document.addEventListener('keydown',function(event){
      if(event.keyCode === 9 && event.target) {
        event.preventDefault()
        let element = event.target
        if(element.hasAttribute('tabindex')) {
          let inputs = document.querySelectorAll('.edit-block-invoice input:not([type=checkbox], [type=radio], [disabled=disabled], .dropdown-menu input)')
          let currentElement = inputs[Number(element.getAttribute('tabindex'))]
          let nextElement = inputs[Number(element.getAttribute('tabindex'))+1]
          console.log(currentElement, nextElement, inputs)
          nextElement.focus()
        }
      }
    });
    if (!this.trials.length) await this.axiosGetTrials();
    await this.$nextTick()
    if (!this.requisites) await this.axiosGetRequisites();
    if (this.contract) {
      this.detail.ship_name = this.contract.name;
      this.detail.cp_date = moment(this.getCharterer?.cp_date).format('DD.MM.YYYY')
      this.detail.charterer = this.getCharterer?.owner.id
      if(!this.detail.discharge_port) {
        this.detail.discharge_port = this.getCharterer.redelivery_place.length ? this.getCharterer.redelivery_place[0] : null
      }
      if(!this.detail.loading_port) {
        this.detail.loading_port = this.getCharterer.delivery_place.length ? this.getCharterer.delivery_place[0] : null
      }
      if(!this.detail.cargo) {
        this.detail.cargo = this.getCharterer.cargo_comment
      }
    }
  },
  mounted() {
    if(this.$refs.from_time){
      if (!this.$refs.from_time.inputmask)
        Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.from_time)
    }
    if(this.$refs.to_time){
      if (!this.$refs.to_time.inputmask)
        Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.to_time)
    }
  }
}
</script>

<style lang="scss">
.invoice_detail__datepicker{
  input{
    background-color: var(--color-input);
  }
}
</style>
<style scoped lang="scss">
.block-input, .counterparties-drop {
  width: calc(33.33% - 12px) !important;
}
.error-date {
  /deep/ {
    .mx-input {
      border: 1px solid var(--color-red) !important;
    }
  }
}
</style>