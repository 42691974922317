<template>
  <div>
    <div class="p-l-6 p-r-2 border-b border-gray-light">
      <div class="contacts_table berths_table">
        <div class="contacts_table__item berths_table__item fz13 text-gray-text p-b-1">DWT</div>
        <div class="contacts_table__item berths_table__item fz13 text-gray-text p-b-1">Amount Local</div>
        <div class="contacts_table__item berths_table__item fz13 text-gray-text p-b-1">Curr.</div>
        <div class="contacts_table__item berths_table__item fz13 text-gray-text p-b-1">USD</div>
        <div class="contacts_table__item berths_table__item fz13 text-gray-text p-b-1">Source</div>
        <div class="contacts_table__item berths_table__item"></div>
      </div>
    </div>
    <div 
      class="berths_table__wrap p-l-6 p-r-2 p-y-1 border-b border-gray-light cursor-pointer" 
      :class="{
        'active': active && active.id == contact.id
      }"
      v-for="contact in list" 
      :key="contact.id"
      @click.stop="$emit('on-select', contact)"
    >
      <div class="contacts_table berths_table">
        <div class="contacts_table__item berths_table__item fz14 fw500">{{contact.dwt}}</div>
        <div class="contacts_table__item berths_table__item fz14 fw500">{{contact.amount_local | formatNumber}}</div>
        <div class="contacts_table__item berths_table__item fz14 fw500">{{contact.currency}}</div>
        <div class="contacts_table__item berths_table__item fz14 fw500">
          ${{contact.currency == 'USD' ? 
            contact.amount_local : 
            getConverted(contact)
          | formatNumber}}
        </div>
        <div class="contacts_table__item berths_table__item fz14 fw500">{{contact.source}}</div>
        <div class="contacts_table__item berths_table__item fz0 text-align-right">
          <button class="fz0" @click.stop="$emit('on-archive', contact)">
            <svg v-if="$route.name == 'port-archive-contacts'" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.017 9.785L6.713 7.002 9.017 4.22a.736.736 0 00-1.108-.967L5.182 6.519a.735.735 0 000 .967l2.727 3.267a.735.735 0 101.108-.968z" fill="#9AA3B0"/><path d="M13.654 6.266H5.736a.736.736 0 100 1.471h7.918c2.136 0 3.875 1.863 3.875 4.242 0 2.378-1.739 4.507-3.875 4.507H6.717a.736.736 0 100 1.472h6.937c2.947 0 5.346-2.746 5.346-5.98 0-3.233-2.398-5.712-5.346-5.712z" fill="#9AA3B0"/></svg>
            <svg v-else width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.636 4.366a.225.225 0 00-.227.223v.675h3.182v-.675a.225.225 0 00-.227-.223h-2.728zm4.319.898v-.675A1.59 1.59 0 0013.364 3h-2.728a1.59 1.59 0 00-1.59 1.589v.675H5.181a.682.682 0 000 1.366h1.136v9.876A2.497 2.497 0 008.818 19h6.364c1.377 0 2.5-1.113 2.5-2.494V6.63h1.136a.682.682 0 000-1.366h-3.864zM7.681 6.63v9.876c0 .62.505 1.128 1.136 1.128h6.364c.63 0 1.136-.509 1.136-1.128V6.63H7.682zm2.954 2.256c.377 0 .682.306.682.683v4.981a.682.682 0 11-1.363 0V9.57c0-.378.305-.684.681-.684zm2.728 0c.376 0 .681.306.681.683v4.981a.682.682 0 11-1.363 0V9.57c0-.378.305-.684.682-.684z" fill="#9AA3B0"/></svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    list: Array,
    active: Object
  },
  data() {
    return {
      rates: []
    }
  },
  watch: {
    list: {
      deep: true,
      immediate: true,
      handler() {
        this.loadRates()
      }
    }
  },
  computed: {
  },
  methods: {
    ...mapActions(['axiosGetCurrency']),
    getConverted(contact) {
      let converted = 0
      let rate = this.rates.find(el=>el.currency == contact.currency)
      if(rate){
        converted = parseFloat(contact.amount_local) / rate.rate
      }
      return converted
    },
    async loadRates() {
      for (let i = 0; i < this.list.length; i++) {
        if(this.rates.findIndex(el=>el.currency == this.list[i].currency) == -1){
          let rate = await this.axiosGetCurrency({currency: this.list[i].currency})
          this.rates.push(rate.data.results[0])
        }
      }
    }
  },
}
</script>

<style>

</style>