<template>
  <div>
    <div class="title-name">
      <p class="fz13 text-dark fw500">{{list.name}}</p> <p class="amount-checkbox text-white-always fz11 fw500">{{getCompletedCheckbox}}</p>
    </div>
    <checklist-task-list-checkbox class="ch-item"  v-for="(ch, k) in getSortList(list.items)" :key="k" :ch="ch"></checklist-task-list-checkbox>
  </div>
</template>

<script>
import ChecklistTaskListCheckbox from "@/components/checklist/checklist-task-list-checkbox";
import {mapActions} from "vuex";
export default {
  name: "checklist-task-list-wrap-items",
  components: {ChecklistTaskListCheckbox},
  props: {
    doneStatus: Boolean,
    list: Object,
    ID: Number,
  },
  computed: {
    getCompletedCheckbox() {
      return `${this.list.items.filter(a=> a.is_completed).length}/${this.list.items.length}`
    }
  },
  data() {
    return {
      checklist: this.list,
      watchDisabled: true,
    }
  },
  watch: {
    checklist: {
      deep: true,
      handler() {
        if(!this.watchDisabled) {
          this.changeTaskCheckBox()
        }
      }
    }
  },
  methods: {
    ...mapActions(['axiosChangeCheckboxInTask']),
    changeTaskCheckBox() {
      let array = [];
      this.checklist.items.forEach((item)=> {
        let form = {
          task: this.ID ? this.ID : null,
          checklist_item: item.id,
          priority: item.priority ? item.priority.id : null,
          due_date: item.due_date ? item.due_date : null,
          days_for_repeat: Array.isArray(item.days_for_repeat) === true ? item.days_for_repeat : [],
          due_date_status: item.due_date_status ? item.due_date_status : null,
          is_hidden: item.is_hidden !== null ? item.is_hidden : false,
          is_completed: item.is_completed !== null ? item.is_completed : false,
        }
        array.push(form)
      })
      this.axiosChangeCheckboxInTask(array);
    },
    getSortList(items) {
      if(this.doneStatus) {
        return (items.filter(a=> !a.is_completed)).concat(items.filter(a=> a.is_completed))
      }
      return (items.filter(a=> !a.is_completed))
    }
  },
  mounted() {
    this.checklist = this.list;
    setTimeout(()=> {
      this.watchDisabled = false;
    }, 500)
  }
}
</script>

<style scoped lang="scss">
.title-name {
  width: 100%;
  height: 27px;
  padding: 0 12px;
  background-color: var(--color-blue-lightest);
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.ch-item {
  &:not(:first-child) {
    border-top: 1px solid var(--color-card-border);
  }
  &:last-child {
    border-bottom: 1px solid var(--color-card-border);
  }
}
.dart-mode {
  .title-name {
    background-color: #333E58;
  }
}
.amount-checkbox {
  margin-left: 8px !important;
  border-radius: 40px;
  background-color: var(--color-blue-dark);
  padding: 2px 4px;
}
</style>