<template>
  <div class="settings-block">
    
    <div class="settings-background" @click.prevent="hideSettingsMenu" :class="{show: show}">
    </div>
    <div class="settings-wrapper">
      <div class="settings">
        <div class="settings-loader" v-if="settingsLoader">
          <div class="sk-fading-circle">
            <div class="sk-circle sk-circle-1"></div>
            <div class="sk-circle sk-circle-2"></div>
            <div class="sk-circle sk-circle-3"></div>
            <div class="sk-circle sk-circle-4"></div>
            <div class="sk-circle sk-circle-5"></div>
            <div class="sk-circle sk-circle-6"></div>
            <div class="sk-circle sk-circle-7"></div>
            <div class="sk-circle sk-circle-8"></div>
            <div class="sk-circle sk-circle-9"></div>
            <div class="sk-circle sk-circle-10"></div>
            <div class="sk-circle sk-circle-11"></div>
            <div class="sk-circle sk-circle-12"></div>
          </div>
        </div>
        <div class="settings-heading">
          <span class="settings-title">Folder Settings</span>
          <button class="close-btn" @click="hideSettingsMenu">
            <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0004 4.9984C19.3518 5.34988 19.3518 5.91972 19.0004 6.2712L13.2728 11.9988L19.0004 17.7263C19.3518 18.0778 19.3518 18.6476 19.0004 18.9991C18.6489 19.3506 18.079 19.3506 17.7276 18.9991L12 13.2716L6.27243 18.9991C5.92096 19.3506 5.35111 19.3506 4.99964 18.9991C4.64817 18.6476 4.64817 18.0778 4.99964 17.7263L10.7272 11.9988L4.99964 6.27119C4.64817 5.91972 4.64817 5.34987 4.99964 4.9984C5.35112 4.64693 5.92096 4.64693 6.27244 4.9984L12 10.726L17.7276 4.9984C18.079 4.64693 18.6489 4.64693 19.0004 4.9984Z" fill="#202226"/>
            </svg>
          </button>
        </div>
        <div class="settings-content">
          <div class="settings-row">
            <span class="settings-row__title">Folder name*</span>
            <label class="input-wrapper" :class="{error: $v.$dirty && $v.title.$invalid}">
              <input type="text" v-model="title"/>
              <span class="error-text" v-if="$v.$dirty && !$v.title.required" >Field is required</span>
            </label>
          </div>
          <div class="settings-row">
            <span class="settings-row__title">Folder Location*</span>
            <label class="input-wrapper" @click="$refs.moveToFolderModal.showModal()">
              <input type="text" readonly class="path-input" v-model="getfolderPath" />
              <svg class="arrow_icn" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.69775 2.76154C4.69775 2.49427 5.0209 2.36042 5.20989 2.54941L8.48562 5.82515C8.60278 5.94231 8.60278 6.13226 8.48562 6.24942L5.20989 9.52514C5.0209 9.71413 4.69775 9.58028 4.69775 9.31301L4.69775 2.76154Z" fill="#2C2C2C"/>
              </svg>
            </label>
          </div>
          <div class="settings-row">
            <span class="settings-row__title">Tag words</span>
            <div class="tags-wrapper" @click="$event.target.querySelector('.input-item').focus()">
              <div class="tag-item" v-for="(tag, index) in tag_words" :key="index">
                <span class="text">{{tag}}</span>
                <button class="del-button" @click.stop="removeTag(tag)">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.53549 8.59341L6.94283 6.00076L9.53558 3.40801C9.79573 3.14785 9.79573 2.72542 9.53549 2.46518C9.27533 2.20502 8.8529 2.20502 8.59274 2.46518L6 5.05792L3.40726 2.46518C3.1471 2.20502 2.72467 2.20502 2.46451 2.46518C2.20427 2.72542 2.20427 3.14785 2.46442 3.40801L5.05717 6.00076L2.46451 8.59341C2.20427 8.85366 2.20427 9.27609 2.46442 9.53625C2.72467 9.79649 3.1471 9.79649 3.40734 9.53625L6 6.94359L8.59266 9.53625C8.8529 9.79649 9.27533 9.79649 9.53558 9.53625C9.79573 9.27609 9.79573 8.85366 9.53549 8.59341Z" fill="#2C2C2C"/>
                  </svg>
                </button>
              </div>
              <div 
                class="input-item" 
                contenteditable
                @keyup.enter="$event.target.blur()"
                @blur="addTag($event)"
              ></div>
            </div>
            <span class="description">Letters with these tag words will be moved to this<br> folder</span>
          </div>
        </div>
        <div class="settings-footer">
          <button class="footer-btn blue-btn full-width" @click="saveFolder">
            Save
          </button>
        </div>
      </div>
    </div>
    <MoveToModal 
      ref="moveToFolderModal" 
      :account="currentAccount"
      @selectedLocation="selectedLocation($event)"
    ></MoveToModal>
    <TagErrorModal
      @removeTag="removeTag($event)"
      ref="tagErrorModal"
    ></TagErrorModal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import MoveToModal from '@/components/mailbox/sidebar/MoveToFolder'
import TagErrorModal from '@/components/mailbox/sidebar/TagErrorModal'
  export default {
    name: 'folderSettings',
    mixins: [validationMixin],
    props: {
      accountsInfo: {type: Array}
    },
    components: {MoveToModal, TagErrorModal},
    data: () => ({
      show: false,
      settingsLoader: false,
      title: '',
      tag_words: [],
      folderPath: '',
      currentAccount: null,
      parentfolder: null,
      isEditFolder: false,
      editedFolderId: null
    }),
    validations(){
      return {
        title: {required}
      }
    },
    methods: {
      ...mapActions(["axiosGetMailboxFolderInfo", "axiosCheckMailboxFolderTag"]),
      showsettingsMenu(folderInfo, isCreatefolder){
        console.log('folderInfo::: ', folderInfo);
        if(isCreatefolder){
          this.fillData(folderInfo)
        }else{
          this.fillEditData(folderInfo)
          this.isEditFolder = true
        }
        this.show = true
        // this.getFolderPathString(folderInfo.id)
      },
      // getFolderPathString(folderID){
      //   let path;
      //   this.accountsInfo.find(account => {
      //     if(account.id == folderID){
      //       path = account.name
      //       return;
      //     }else{
      //       let folder = account.folders.find(folder => folder.id == folderID)
      //       if(folder){
      //         path = `${account.name}/${folder.title}`
      //         return;
      //       }
      //     }
      //   });
      //   return path;
      // },
      fillData(folderInfo){
        // Если папка создается в корне
        if(!folderInfo.title){
          this.folderPath = `${folderInfo.name}`
          this.currentAccount = folderInfo
        }else{
          // Если папка создается в другой папке
          this.currentAccount = this.accountsInfo.find(account => {
            let folder = account.folders.find(folder => folder.id == folderInfo.id)
            if(folder){
              this.parentfolder = folder
              this.folderPath = `${account.name}/${folder.title}`
              return folder
            }
          })
        }
      },
      fillEditData(folderInfo){
        this.settingsLoader = true;
        this.editedFolderId = folderInfo.id
        this.axiosGetMailboxFolderInfo(folderInfo.id)
        .then(({data}) => {
          this.title = data.title
          this.tag_words = data.tag_words || []
          this.currentAccount = this.accountsInfo.find(acc => acc.id == data.account.id)
          
          this.currentAccount.folders.forEach(folder => {
            if(folder.id == folderInfo.id){
              this.folderPath = `${this.currentAccount.name}`
            }else if(folder.child_folders){
              let folderChild = folder.child_folders.find(childFolder => childFolder.id == folderInfo.id)
              if(folderChild){
                this.folderPath = `${this.currentAccount.name}/${folder.title}`
                this.parentfolder = folder
              }
            }
          });
          this.settingsLoader = false;
        })
        
      },
      hideSettingsMenu(){
        this.folderPath = ''
        this.currentAccount = null
        this.title = ''
        this.tag_words = []
        this.isEditFolder = false
        this.editedFolderId = null
        this.parentfolder = null
        this.$v.$reset()
        this.show = false
      },
      addTag(event){
        let tagName = event.target.textContent
        if(tagName.trim() != ''){
          this.tag_words.push(tagName)
        }
        this.axiosCheckMailboxFolderTag({account_id: this.currentAccount.id, tag: tagName})
        .then(response => {
          console.log('response::: ', response);
        })
        .catch(err => {
          // Если тег добавлен к другой папке, показать попап
          if(err.response.data.custom_status){
            this.$refs.tagErrorModal.showModal(err.response.data.message, tagName)
          }
        })
        // Удалить одинаковые названия
        this.tag_words = [...new Set(this.tag_words)]
        event.target.textContent = ''
      },
      removeTag(tagName){
        this.tag_words = this.tag_words.filter(tag => tag != tagName)
      },
      saveFolder(){
        this.$v.$touch();
        if (this.$v.$invalid) return false;
        let data = {
          title: this.title,
          account: this.currentAccount.id,
          parent_folder: this.parentfolder ? this.parentfolder.id : null,
          tag_words: this.tag_words
        }

        if(!this.isEditFolder){
          this.$emit('createFolder', data)
          this.hideSettingsMenu()
        }else{
          data.id = this.editedFolderId
          this.$emit('saveFolder', data)
          this.hideSettingsMenu()
        }
        
      },
      selectedLocation(folderId){
        if(folderId == 'mainFolder'){
          this.folderPath = `${this.currentAccount.name}`
          this.parentfolder = null
        }else{
          let currentFolder = this.currentAccount.folders.find(folder => folder.id == folderId)
          this.folderPath = `${this.currentAccount.name}/${currentFolder.title}`
          this.parentfolder = currentFolder
        }
      }
    },
    computed: {
      getfolderPath: {
        get(){
          return `${this.folderPath}/${this.title}`
        },
        set(val){
          this.title = val
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
$spinkit-size: 2em !default;
$spinkit-spinner-color: #319FEF !default;
.settings-background{
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.20);
  z-index: 4;
  transition: .3s;
  opacity: 0;
  pointer-events: none;
  &.show{
    opacity: 1;
    transition: .3s;
    pointer-events: auto;
  }
}
.settings-wrapper{
  position: fixed;
  height: 100vh;
  background: #fff;
  width: 0;
  left: 0;
  top: 0;
  z-index: 999;
  transition: width .3s;
  overflow: hidden;
}
.settings-background.show + .settings-wrapper{
  width: 375px;
  transition: width .3s;
}
.settings{
  height: 100vh;
  display: flex;
  flex-direction: column;
  
  .settings-heading{
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-dark);
    height: 48px;
    min-width: 320px;
    & *{
      transition: .3s;
    }
    .close-btn{
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      &:hover{
        svg{
          path{
            fill: var(--color-blue-dark);
          }
        }
      }
    }
    .settings-title{
      margin-right: auto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-left: 8px;
    }
  }
  .settings-footer{
    padding: 0 16px;
    border-top: 1px solid var(--color-gray-dark);
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    min-width: 320px;
    .footer-btn{
      height: 32px;
      flex-grow: 1;
      border-radius: 4px;
      border: 1px solid var(--color-blue);
      color: var(--color-blue);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      transition: .3s;
      width: 50%;
      .loader-svg{
        display: none;
      }
      &:hover{
        color: var(--color-blue-dark);
        border-color: var(--color-blue-dark);
        transition: .3s;
      }
      &.blue-btn{
        color: var(--color-white);
        background: var(--color-blue);
        &:hover{
          border-color: var(--color-blue-dark);
          background: var(--color-blue-dark);
          transition: .3s;
        }
      }
      &.full-width{
        width: 100%;
      }
      &.disabled{
        pointer-events: none;
        opacity: 0.5;
      }
      &.loader{
        pointer-events: none;
        opacity: 0.5;
        span{
          display: none;
        }
        .loader-svg{
          display: flex;
          margin: 0 auto;
        }
      }
    }
  }
  .settings-content{
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    padding: 16px;
    row-gap: 12px;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray-bread);
      border-radius: 9px;
    }
  }
}
.settings-row{
  display: flex;
  flex-direction: column;
  .settings-row__title{
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-new-dark-gray);
    padding-bottom: 4px;
  }
  .input-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
    .error-text{
      position: absolute;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      bottom: -14px;
      right: 0;
      color: var(--color-red);
    }
    &.error{
      input{
        border-color: var(--color-red);
      }
    }
    input{
      padding-right: 32px;
    }
    .path-input{
      pointer-events: none;
    }
    .arrow_icn{
      position: absolute;
      right: 8px;
    }
  }
  .description{
    padding-top: 4px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--color-new-dark-gray);
  }
}
.settings-loader{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba($color: #fff, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .sk-fading-circle {
    $circleCount: 12;
    $animationDuration: 1.2s;

    width: $spinkit-size;
    height: $spinkit-size;
    position: relative;
    margin: auto;

    .sk-circle {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .sk-circle:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $spinkit-spinner-color;
      border-radius: 100%;
      animation: sk-fading-circle-delay $animationDuration infinite ease-in-out both;
    }

    @for $i from 2 through $circleCount {
      .sk-circle-#{$i} {
        transform: rotate((360deg / $circleCount * ($i - 1)));
      }
    }

    @for $i from 2 through $circleCount {
      .sk-circle-#{$i}:before {
        animation-delay: (-$animationDuration + $animationDuration / $circleCount * ($i - 1));
      }
    }
  }

  @keyframes sk-fading-circle-delay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
}
.tags-wrapper{
  padding: 7px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  column-gap: 4px;
  row-gap: 4px;
  border-radius: 4px;
  border: 1px solid #BDCBD6;
  min-height: 91px;
  .tag-item{
    display: flex;
    align-items: center;
    column-gap: 2px;
    padding: 2px 8px;
    border-radius: 20px;
    background: var(--color-violet);
    align-self: flex-start;
    .text{
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .del-button{
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .input-item{
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 16px;
  }
}

</style>
<style lang="scss">
  #app{
    .datepicker-wrapper{
      padding-top: 4px;
      .my-datepicker{
        width: 100%;
        .mx-input-wrapper{
          .mx-input{
            background-color: var(--color-gray-lightest) !important;
            height: 39px;
          }
        }
      }
    }
    .input-wrapper{
      .my-tags-select{
        min-height: 39px;
        .multiselect__tags{
          min-height: 39px;
        }
        .multiselect__tag{
          background: var(--color-blue);
          .multiselect__tag-icon{
            &:after{
              color: var(--color-dark);
            }
            &:hover{
              background: var(--color-blue-dark);
              &:after{
                color: var(--color-white);
              }
            }
          }
        }
      }
    }
  }
</style>