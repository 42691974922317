<template>
  <div class="page_section vessel_owners__page_content bg-gray-lightest">
    <div class="flex h-full">
      <div class="vessel_sidebar h-full bg-white flex-shrink-0 border-r border-gray-light overflow-auto">
        <div 
          class="vessel_sidebar_item p-x-6 p-y-3 border-b border-gray-light fz14 fw500 cursor-pointer"
          :class="{'active': activeIndex == i}"
          v-for="(item, i) in invoiceList"
          :key="item.id"
          @click="selectItem(i)"
        >
          {{item.created_at | formatDate}} at {{item.created_at | formatTime}}
        </div>
      </div>

      <div class="w-full h-full overflow-auto p-x-6">
        <div class="invoice_data w-full m-r-auto m-l-auto" v-if="activeItem">

          <p class="m-y-6 fz13 text-gray-text">
            Last edit 
            {{activeItem.updated_at | formatDate}} 
            at {{activeItem.updated_at | formatTime}} 
            by {{activeItem.creator.first_name + ' ' + (activeItem.creator.last_name ? activeItem.creator.last_name : '')}}
          </p>

          <invoice-block :invoice="activeItem" :activeTab="$route.meta.invoiceType"/>

          <div class="m-t-6 p-b-6">
            <files-attach :isEdit="false" v-if="activeItem && activeItem.files.length" :files="activeItem.files"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from 'moment'

import invoiceBlock from '@/components/vessels/invoice/invoiceBlock'
import filesAttach from '@/components/vessels/owners/filesAttach'

export default {
  components: {
    invoiceBlock,
    filesAttach
  },
  data() {
    return {
      activeItem: null,
      activeIndex: 0
    }
  },
  watch: {
    activeItem: function() {
      this.checkCharterer()
    }
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract,
      invoiceList: state => state.invoice.invoiceList,
    }),
    ...mapGetters(['getInvoiceType', 'getInvoiceUrl']),
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
  },
  methods: {
    ...mapActions(['axiosGetInvoicesById', 'axiosGetClientsById']),
    ...mapMutations(['changeContractBread', 'showLoad', 'hideLoad']),
    selectItem(i) {
      this.activeItem = this.invoiceList[i]
      this.activeIndex = i
    },
    async checkCharterer() {
      if(this.activeItem) {
        if(this.activeItem.charterer && Number.isInteger(this.activeItem.charterer))
          this.activeItem.charterer = await this.axiosGetClientsById(this.activeItem.charterer)
      }
    },
  },
  async beforeMount() {
    this.showLoad()
    await this.axiosGetInvoicesById({id: this.$route.params.subId, type: this.getInvoiceType()})
    this.hideLoad()

    if (this.getCharterer) {
      if(this.getCharterer.owner && Number.isInteger(this.getCharterer.owner))
        this.getCharterer.owner = await this.axiosGetClientsById(this.getCharterer.owner)

      this.changeContractBread({
        index: 2,
        obj: {
          name: `${this.getCharterer.name} ${moment(this.getCharterer.cp_date).format('DD.MM.YYYY')}`,
          link: `/vessels/${this.$route.params.id}/open/charterers/${this.$route.params.subId}/${this.getInvoiceUrl()}`
        }
      })
      this.changeContractBread({
        index: 3,
        obj: {
          name: 'History Voyage Invoice',
          link: `/vessels/${this.$route.params.id}/charterers/${this.$route.params.subId}/invoices`
        }
      })
    }

    if(this.invoiceList.length)
      this.activeItem = this.invoiceList[0]
  },
  beforeDestroy() {
    this.changeContractBread({
      index: 3,
      obj: null
    })
    this.changeContractBread({
      index: 2,
      obj: null
    })
  }
};
</script>

<style lang="scss">
</style>