<template>
  <div>
    <div class="block-for-checkbox noTab" v-show="showSettings.indexOf('block-for-checkbox') >= 0">
<!--      $route.path.indexOf('archive') >= 0 || $route.path.indexOf('open/accounting-invoice') >=0 || true-->


      <button class="button-checkbox" v-on:click="selectCheckbox">
        <svg v-if="isSelected" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
        </svg>
        <svg class="active-checkbox-style" v-if="!isSelected" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="18" height="18" rx="4" fill="#094172"/>
          <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
        </svg>
      </button>
    </div>
    <element-due-date @clickTab="(e)=> nextElementActive" @input="onChange('due')" :class="{'border-left-none': showSettings.indexOf('block-for-checkbox') === -1 && showSettings.indexOf('due-date') <=3, 'noTab': (disabledItems && disabledItems.due_date) || !(showSettings.indexOf('due-date') >= 0)}"  :disabled="disabledItems && disabledItems.due_date" :compare-show="true" class="due-date" v-show="showSettings.indexOf('due-date') >= 0" v-model="item.execute_date" :showToDay="item.id === -1" :status="item.status"></element-due-date>
    <element-select-list-wrap-for-item
        v-tooltip.top="item.ship ? item.ship.name + ' ('+getFormatCpDate(item.ship.cp_date)+')' : ''"
        :disabled="disabledItems && disabledItems.vessel_state_and_member"
        v-show="showSettings.indexOf('vessel-state-and-member') >= 0 && item.id !== -1"
        :textInfo="item.ship ? item.ship.name + ' ('+getFormatCpDate(item.ship.cp_date)+')' : ''"
        class="vessel-state-and-member"
        :class="{'border-left-none': $route.path.indexOf('cashflow/diagram') >= 0, 'noTab': (disabledItems && disabledItems.vessel_state_and_member) || !(showSettings.indexOf('vessel-state-and-member') >= 0 && item.id !== -1)}"
        :value="item.ship"
        :settings=" {
        id: item.id,
        url: '/contract/dropdown',
        params: {is_archive: false, contract_type: ['SHIP']},
        value: item.ship,
        watchKey: 'name',
        localList: null,
        watchKeyBefore: null,
        watchKeyAfter: 'cp_date',
        searchText: item.ship ? item.ship.name : '',
        element: null,
        nameValue: 'ship',
        nameValueText: '',
      }"
    >
    </element-select-list-wrap-for-item>
    <div class="vessel-state-and-member noTab" :class="{'border-left-none': $route.path.indexOf('cashflow/diagram') >= 0}" v-if="showSettings.indexOf('vessel-state-and-member') >= 0 && item.id === -1" v-tooltip.top="'BALANCE ON ACCOUNT'">
      <p class="text-info fz14 click-element-border text-overflow fw700">BALANCE ON ACCOUNT</p>
    </div>
    <element-select-list-wrap-for-item
        v-tooltip.top="item.account_name"
        :disabled="disabledItems && disabledItems.account"
        v-show="showSettings.indexOf('account') >= 0"
        :textInfo="item.account_name"
        class="account"
        :class="{'noTab': (disabledItems && disabledItems.account) || !(showSettings.indexOf('account') >= 0)}"
        :value="item.account"
        :settings=" {
        id: item.id,
        url: '/payment/account',
        params: {is_archive: false},
        value: item.account,
        watchKey: 'name',
        localList: null,
        watchKeyBefore: watchKeyBeforeForAccount,
        watchKeyAfter: null,
        searchText: item.account_name ? item.account_name : '',
        element: null,
        nameValue: 'account',
        nameValueText: 'account_name',
      }"
    >
    </element-select-list-wrap-for-item>
    <element-input-block
        :class="{'noTab': (disabledItems && disabledItems.additionally) || !(showSettings.indexOf('additionally') >= 0)}"
        @input="onChange('additionally')"
        v-tooltip.top="item.additionally"
        :disabled="disabledItems && disabledItems.additionally"
        v-show="showSettings.indexOf('additionally') >= 0"
        v-model="item.additionally"
      :max-length="15"
      :classSettings="'text-uppercase' + (disabledItems && disabledItems.additionally ? 'disabled-always' : '')"
      class="additionally"
    ></element-input-block>
    <element-select-list-wrap-for-item
        :class="{'noTab': ((disabledItems && disabledItems.currency) || getBoolDontSelect({bank: item.bank, cash: item.cash})) || !(showSettings.indexOf('currency') >= 0)}"
        :disabled="(disabledItems && disabledItems.currency) || getBoolDontSelect({bank: item.bank, cash: item.cash})"
        v-show="showSettings.indexOf('currency') >= 0"
        :textInfo="item.currency"
        class="currency"
        :value="item.currency"
        :settings=" {
        id: item.id,
        url: '',
        params: {is_archive: false},
        value: item.currency,
        watchKey: 'name',
        localList: getValutaList,
        watchKeyBefore: null,
        watchKeyAfter: null,
        searchText: '',
        element: null,
        nameValue: 'currency',
        nameValueText: '',
      }"
    ></element-select-list-wrap-for-item>
    <element-input-payment-amount @input="onChange('expense')" @changeType="onChange('expense')" @mousedown="(e)=> {$emit('mousedown', e)}" @mouseup="(e)=> {$emit('mouseup', e)}" :class="{'noTab': (disabledItems && disabledItems.expense) || !(showSettings.indexOf('expense') >= 0)}" :disabled="disabledItems && disabledItems.expense" v-show="showSettings.indexOf('expense') >= 0" class="payment-income expense" :item="item" type="expense"></element-input-payment-amount>
    <element-input-payment-amount @input="onChange('income')" @changeType="onChange('income')" @mousedown="(e)=> {$emit('mousedown', e)}" @mouseup="(e)=> {$emit('mouseup', e)}" :class="{'noTab': (disabledItems && disabledItems.income) || !(showSettings.indexOf('income') >= 0)}" :disabled="disabledItems && disabledItems.income" v-show="showSettings.indexOf('income') >= 0" class="payment-income income" :item="item" type="income"></element-input-payment-amount>
    <element-input-currency-amount @input="onChange('converted')" @mousedown="(e)=> {$emit('mousedown', e)}" @mouseup="(e)=> {$emit('mouseup', e)}" :class="{'noTab': (disabledItems && disabledItems.converted) || !(showSettings.indexOf('converted') >= 0)}" :disabled="disabledItems && disabledItems.converted" v-show="showSettings.indexOf('converted') >= 0" class="converted" :item="item" :payment_type="item.payment_type" :status="item.status" v-model="item.currency_amount"></element-input-currency-amount>
    <div class="status noTab" v-show="showSettings.indexOf('status') >= 0">
      <p class="text-info fz14 click-element-border" :class="{'text-red': item.status !== 'paid'}">{{item.status === 'paid' ? 'Paid' : 'Not Paid'}}</p>
    </div>
<!--    <element-due-date class="paid-date" v-model="item.paid_date" :status="item.status"></element-due-date>-->
    <div class="paid-date noTab" v-show="showSettings.indexOf('paid-date') >= 0">
      <p class="text-info fz13 click-element-border" >{{item.paid_date | formatDateFromCpDateWithotTimeZome}}</p>
    </div>
    <element-select-list-wrap-for-item
        v-tooltip.top="item.client_name"
        :class="{'noTab': (disabledItems && disabledItems.counterparty) || !(showSettings.indexOf('counterparty') >= 0)}"
        :disabled="disabledItems && disabledItems.counterparty"
        v-show="showSettings.indexOf('counterparty') >= 0"
        :textInfo="item.client_name"
        class="counterparty"
        :value="item.client"
        :settings=" {
        id: item.id,
        url: '/client/dropdown',
        params: {ordering: 'company_name', is_archived: false},
        value: item.client,
        watchKey: 'company_name',
        localList: null,
        watchKeyBefore: null,
        watchKeyAfter: null,
        searchText: item.client_name,
        element: null,
        nameValue: 'client',
        nameValueText: 'client_name',
      }"
    >
    </element-select-list-wrap-for-item>
    <element-select-list-wrap-for-item
        v-tooltip.top="(item.bank ? item.bank.account.account_id + ': ' + item.bank.account.name : (item.cash ? item.cash.name : ''))"
        :disabled="disabledItems && disabledItems.bank_and_cash_account"
        :class="{'noTab': (disabledItems && disabledItems.bank_and_cash_account) || !(showSettings.indexOf('bank-and-cash-account') >= 0)}"
        v-show="showSettings.indexOf('bank-and-cash-account') >= 0"
        :textInfo="(item.bank ? item.bank.account.account_id + ': ' + item.bank.account.name : (item.cash ? item.cash.name : ''))"
        class="bank-and-cash-account"
        :value="null"
        :settings=" {
        id: item.id,
        url: '/payment/cash/balance',
        params: {is_archive_cash: 'false', is_archive_requisite: 'false'},
        value: item,
        watchKey: '',
        localList: null,
        watchKeyBefore: null,
        watchKeyAfter: null,
        searchText: '',
        element: null,
        nameValue: 'bank',
        nameValueText: '',
      }"
    >
    </element-select-list-wrap-for-item>
    <element-input-block
        @input="onChange('remark')"
        v-tooltip.top="item.remark"
        :class="{'noTab': (disabledItems && disabledItems.remark) || !(showSettings.indexOf('remark') >= 0)}"
        :disabled="disabledItems && disabledItems.remark"
        v-show="showSettings.indexOf('remark') >= 0"
        v-model="item.comment"
        :classSettings="(disabledItems && disabledItems.remark ? 'disabled-always' : '')"
        class="remark"
    ></element-input-block>
    <div class="attachment noTab" v-show="showSettings.indexOf('attachment') >= 0">
      <div class="flex-center click-element-border needed-after">
        <svg v-if="item.status === 'paid' && item.files.length" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H8.06611C6.41648 19.4 5.07635 18.0681 5.06617 16.4185L5 5.7Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
          <path d="M6.17355 16.8696V13.5868C6.17355 12.7104 5.46313 12 4.58678 12V12C3.71042 12 3 12.7104 3 13.5868V18C3 19.6569 4.34315 21 6 21V21C7.65685 21 9 19.6569 9 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
        </svg>
        <svg v-else-if="item.files.length" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H8.06611C6.41648 19.4 5.07635 18.0681 5.06617 16.4185L5 5.7Z" fill="#E9ED22"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
          <path d="M6.17355 16.8696V13.5868C6.17355 12.7104 5.46313 12 4.58678 12V12C3.71042 12 3 12.7104 3 13.5868V18C3 19.6569 4.34315 21 6 21V21C7.65685 21 9 19.6569 9 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
    <div class="bank-receipt noTab" v-show="showSettings.indexOf('bank-receipt') >= 0">
      <div class="flex-center click-element-border needed-after">
        <svg v-if="item.receipt_files.length !== 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.99609 5.7C4.99609 4.20883 6.20492 3 7.69609 3L13.342 3L19.4002 9.22821V16.6896C19.4002 18.1808 18.1914 19.3896 16.7002 19.3896H8.06219C6.41257 19.3896 5.07244 18.0577 5.06225 16.4081L4.99609 5.7Z" fill="#37D379"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.7C5 4.20883 6.20883 3 7.7 3H13.3418L19.4 9.22821V16.7C19.4 18.1912 18.1912 19.4 16.7 19.4H15.2C14.8134 19.4 14.5 19.0866 14.5 18.7C14.5 18.3134 14.8134 18 15.2 18H16.7C17.418 18 18 17.418 18 16.7V10.2125H14.0462C13.1073 10.2125 12.3462 9.45138 12.3462 8.5125V4.4H7.7C6.98203 4.4 6.4 4.98203 6.4 5.7V8.7C6.4 9.0866 6.0866 9.4 5.7 9.4C5.3134 9.4 5 9.0866 5 8.7V5.7ZM13.7462 5.42358L17.0426 8.8125H14.0462C13.8805 8.8125 13.7462 8.67818 13.7462 8.5125V5.42358Z" fill="#2C2C2C"/>
          <path d="M5.67355 16.8696V13.5868C5.67355 12.7104 4.96313 12 4.08678 12V12C3.21042 12 2.5 12.7104 2.5 13.5868V18C2.5 19.6569 3.84315 21 5.5 21V21C7.15685 21 8.5 19.6569 8.5 18V12" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
    <div class="comment-from-accounting noTab" v-show="showSettings.indexOf('comment-from-accounting') >= 0">
      <div class="flex-center click-element-border needed-after">
        <svg v-if="item.has_messages" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0835 6.61999C20.0835 5.41084 19.076 4.40332 17.8668 4.40332H6.1335C4.92435 4.40332 3.91683 5.41084 3.91683 6.61999V14.6867C3.91683 15.8958 4.92435 16.9033 6.1335 16.9033H6.60572V18.5978C6.60572 19.0081 6.8579 19.3769 7.24122 19.5253L7.24467 19.5266L7.24468 19.5266C7.3588 19.5701 7.47918 19.5922 7.60016 19.5922C7.83687 19.5922 8.06877 19.5074 8.25101 19.3495L11.8501 16.9033H17.8668C19.076 16.9033 20.0835 15.8958 20.0835 14.6867V6.61999ZM17.8668 5.90332C18.2475 5.90332 18.5835 6.23927 18.5835 6.61999V14.6867C18.5835 15.0674 18.2475 15.4033 17.8668 15.4033H11.6193C11.469 15.4033 11.3221 15.4485 11.1977 15.533L8.10572 17.6346V16.1533C8.10572 15.7391 7.76993 15.4033 7.35572 15.4033H6.1335C5.75278 15.4033 5.41683 15.0674 5.41683 14.6867V6.61999C5.41683 6.23927 5.75278 5.90332 6.1335 5.90332H17.8668Z" fill="#4B4B4B"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 9C7.75 8.58579 8.08579 8.25 8.5 8.25H15.5C15.9142 8.25 16.25 8.58579 16.25 9C16.25 9.41421 15.9142 9.75 15.5 9.75H8.5C8.08579 9.75 7.75 9.41421 7.75 9Z" fill="#4B4B4B"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 12C7.75 11.5858 8.08579 11.25 8.5 11.25H12C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75H8.5C8.08579 12.75 7.75 12.4142 7.75 12Z" fill="#4B4B4B"/>
        </svg>
      </div>
    </div>
    <element-select-list-wrap-for-item
        :class="{'noTab': (disabledItems && disabledItems.settled_vsl) || !(showSettings.indexOf('settled-vsl') >= 0)}"
        v-tooltip.top="item.settled_ship ? item.settled_ship.name + ' ('+getFormatCpDate(item.settled_ship.cp_date)+')' : ''"
        :disabled="disabledItems && disabledItems.settled_vsl"
        v-show="showSettings.indexOf('settled-vsl') >= 0"
        :textInfo="item.settled_ship ? item.settled_ship.name + ' ('+getFormatCpDate(item.settled_ship.cp_date)+')' : ''"
        class="settled-vsl"
        :value="item.settled_ship"
        :settings=" {
        id: item.id,
        url: '/contract/dropdown',
        params: {is_archive: false, contract_type: ['SHIP']},
        value: item.settled_ship,
        watchKey: 'name',
        localList: null,
        watchKeyBefore: null,
        watchKeyAfter: 'cp_date',
        searchText: item.settled_ship ? item.settled_ship.name : '',
        element: null,
        nameValue: 'settled_ship',
        nameValueText: '',
      }"
    ></element-select-list-wrap-for-item>

    <element-select-list-wrap-for-item
        v-tooltip.top="item.ports ? item.ports.name + ' ('+item.ports.country+')' : ''"
        :disabled="disabledItems && disabledItems.disbursement"
        :class="{'noTab': (disabledItems && disabledItems.disbursement) || !(showSettings.indexOf('disbursement') >= 0)}"
        v-show="showSettings.indexOf('disbursement') >= 0"
        :textInfo="item.ports ? item.ports.name + ' ('+item.ports.country+')' : ''"
        class="disbursement"
        :value="item.ports"
        :settings=" {
        id: item.id,
        url: '/port/dropdown',
        params: {is_archived: false},
        value: item.ports,
        watchKey: 'name',
        localList: null,
        watchKeyBefore: null,
        watchKeyAfter: 'country',
        searchText: item.ports ? item.ports.name : '',
        element: null,
        nameValue: 'ports',
        nameValueText: '',
      }"
    ></element-select-list-wrap-for-item>
    <element-select-list-wrap-for-item
        v-tooltip.top="item.real_account ? item.real_account.company_name : ''"
        :disabled="disabledItems && disabledItems.real_account"
        :class="{'noTab': (disabledItems && disabledItems.real_account) || !(showSettings.indexOf('real-account') >= 0)}"
        v-show="showSettings.indexOf('real-account') >= 0"
        :textInfo="item.real_account ? item.real_account.company_name : ''"
        class="real-account"
        :value="item.real_account"
        :settings=" {
        id: item.id,
        url: '/client/dropdown',
        params: {ordering: 'company_name', is_archived: false},
        value: item.real_account,
        watchKey: 'company_name',
        localList: null,
        watchKeyBefore: null,
        watchKeyAfter: null,
        searchText: item.real_account ? item.real_account.company_name : '',
        element: null,
        nameValue: 'real_account',
        nameValueText: '',
      }"
    ></element-select-list-wrap-for-item>
    <element-select-list-wrap-for-item
        v-tooltip.top="item.statement_member && (''+item.statement_member).toLowerCase() !== 'invalid date' ? getFormatCpDate(item.statement_member) : ''"
        :disabled="disabledItems && disabledItems.statement_member"
        :class="{'noTab': (disabledItems && disabledItems.statement_member) || !(showSettings.indexOf('statement-member') >= 0)}"
        v-show="showSettings.indexOf('statement-member') >= 0"
        :textInfo="item.statement_member"
        class="statement-member"
        :value="item.statement_member"
        :settings=" {
        id: item.id,
        url: '',
        params: {is_archive: false},
        value: item.statement_member,
        watchKey: 'name',
        localList: availableCpDate,
        watchKeyBefore: null,
        watchKeyAfter: null,
        searchText: '',
        element: null,
        nameValue: 'statement_member',
        nameValueText: '',
      }"
    ></element-select-list-wrap-for-item>
    <div class="balance noTab" v-show="showSettings.indexOf('balance') >= 0">
      <p class="text-info fz13 click-element-border" >{{item.balance | formatNumberTwo}}</p>
    </div>
    <div class="end-block flex-center position-relative noTab">
      <app-loader v-if="showLoader" class="loader"></app-loader>
      <button class="option" v-on:click="unarchivedThis()" v-if="$route.path.indexOf('archive')>=0 && getPermissionsForUnArchive()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
          <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"/>
        </svg>
      </button>


      <router-link :to="$route.path.indexOf('payment/list')>=0 ? {name: 'Payment List Detail', params: {id: item.id}} : $route.path.indexOf('payment/instructions') >=0 ? {name: 'Payment Instructions Detail', params: {id: item.id}} : {name: 'Payment Detail', params: {id: item.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cash-flow') === -1 && $route.path.indexOf('accounting-invoice') === -1 && $route.path.indexOf('cashflow') === -1 && $route.path.indexOf('counterparties') === -1">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
        </svg>
      </router-link>
      <router-link :to="{name: 'Detail Cash Flow', params: {paymentCashFlowID: item.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cash-flow') !== -1">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
        </svg>
      </router-link>
<!--      <router-link :to="'accounting-invoice/'+ item.id+ '/detail'" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('accounting-invoice') !== -1">-->
<!--        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>-->
<!--        </svg>-->
<!--      </router-link>-->
      <button class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('accounting-invoice') !== -1" v-on:click="openDetailInvoice">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
        </svg>
      </button>
      <router-link :to="'detail/'+ item.id" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cashflow/list') !== -1">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
        </svg>
      </router-link>
      <router-link :to="{name: 'CashFlow Diagram detail', params: {id: item.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cashflow/diagram') !== -1">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
        </svg>
      </router-link>
      <router-link :to="{name: 'CashFlow Diagram New detail', params: {id: item.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('cashflow/new-diagram') !== -1">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
        </svg>
      </router-link>
      <router-link :to="{name: 'Counterparties Payment Detail', params: {idPayment: item.id}}" class="link-block" v-if="$route.path.indexOf('archive') === -1 && $route.path.indexOf('counterparties') !== -1">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.29924 6.40332L5.18326 9.70563C4.91614 9.97749 4.48367 9.97703 4.217 9.70422C3.95053 9.43145 3.95122 8.98957 4.21837 8.7175L6.84906 5.90934L4.21837 3.19208C3.95125 2.91997 3.95057 2.47838 4.217 2.20557C4.35068 2.06881 4.52581 2.00043 4.70094 2.00043C4.87562 2.00043 5.05006 2.06835 5.1835 2.20416L8.29924 5.4154C8.42789 5.54611 8.50009 5.724 8.50009 5.90934C8.50009 6.09468 8.42769 6.27236 8.29924 6.40332Z" fill="#9AA3B0"/>
        </svg>
      </router-link>
<!--      {{getsdsd()}}-->
<!--      <p class="fz10">{{// item.id}}</p>-->
    </div>
  </div>
</template>

<script>
import ElementDueDate from "@/components/payment/payment-list/elements-item/element-due-date";
import ElementSelectListWrapForItem from "@/components/payment/payment-list/elements-item/element-select-list-wrap-for-item";
import moment from 'moment'
import {mapGetters, mapMutations} from "vuex";
import ElementInputBlock from "@/components/payment/payment-list/elements-item/element-input-block";
import ElementInputPaymentAmount from "@/components/payment/payment-list/elements-item/element-input-payment-amount";
import ElementInputCurrencyAmount from "@/components/payment/payment-list/elements-item/element-input-currency-amount";
import AppLoader from "@/components/loader";
export default {
  name: "virtual-payment-list-item",
  components: {AppLoader, ElementInputCurrencyAmount, ElementInputPaymentAmount, ElementInputBlock, ElementSelectListWrapForItem, ElementDueDate},
  computed: {
    ...mapGetters(['getPermissionsByType', 'getValutaList']),
    availableCpDate() {
      if(this.item.ship) {
        let vessel = JSON.parse(JSON.stringify(this.item.ship))
        let client = this.item.client
        let real_account = this.item.real_account
        if(vessel !== null) {
          let cp_dates = [];
          if(vessel.cargo_contracts?.length) {
            if(client && !real_account) {
              vessel.cargo_contracts =  vessel.cargo_contracts.filter(a=> a.owner === client.id)
            }
            if(real_account) {
              vessel.cargo_contracts =  vessel.cargo_contracts.filter(a=> a.owner === real_account.id)
            }
            vessel.cargo_contracts.map(a=> {
              if(a.cp_date?.length) {
                cp_dates.push(a.cp_date)
              }
            })
          }
          cp_dates = Array.from(new Set(cp_dates));
          cp_dates = cp_dates.map(a=> {
            return {name: a, id: a}
          })
          return cp_dates;
        }
      }


      return [];
    }
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    showLoader: Boolean,
    showSettings: String,
    disabledItems: Object,
    isSelected: Boolean,
  },
  data() {
    return{
      watchKeyBeforeForAccount: '',
      currentActiveElement: null,
    }
  },
  // watch: {
  //   'item': {
  //     handler() {
  //       console.log('item is changed ')
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    ...mapMutations(['setEventOpenDetailInvoice']),
    nextElementActive(e) {
      e.preventDefault()
      // console.dir(e.target)
      // console.log(e.target.parentNode.nextElementSibling)
      e.target.parentNode.nextElementSibling.childNodes[0].click()
      let event = new Event("dblclick")
      e.target.parentNode.nextElementSibling.dispatchEvent(event);
      this.currentActiveElement = e.target.parentNode.nextElementSibling;
    },
    openDetailInvoice() {
      this.setEventOpenDetailInvoice(this.item)
    },
    unarchivedThis() {
      this.$emit('delete')
    },
    getPermissionsForUnArchive() {
      return this.getPermissionsByType("unarchive_payments_incomes");
    },
    onChange(name) {
      console.log('onChange', name)
      this.$emit('on-change')
    },
    getBoolDontSelect(data) {
      if(data.bank !== null) {
        if(data.bank.currency !== null && (''+data.bank.currency).length !== 0) {
          return true
        }
      }
      if(data.cash !== null) {
        if(data.cash.currency !== null && (''+data.cash.currency).length !== 0) {
          return true
        }
      }
      return false
    },
    getsdsd() {
      // console.log(1)
      return 1
    },
    getFormatCpDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    selectCheckbox() {
        this.$emit('selected')
    },
    getPermissionShowDopInfoAcc() {

    },
  },
  mounted() {
    if(this.getPermissionsByType('show_dop_info_for_acc_in_payments')) {
     this.watchKeyBeforeForAccount = 'account_id'
    }
  },
  destroyed() {
    // console.log('destroyed')
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap');
.loader {
  position: absolute;
  z-index: 20;
  top: -1px;
  left: 1px;
  width: 100%;
  height: 31px;
  /deep/ .sk-fading-circle {
    width: 20px;
    height: 20px;
  }
}
.end-block {
  overflow: visible !important;
}
.text-overflow {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  vertical-align: middle;
  display: block !important;
}
.border-left-none {
  border-left: none !important;
}

.payment-income {
  /deep/ {
    .input-payment {
      font-family: 'Roboto Mono', monospace;
    }
  }
}
</style>