import axios from 'axios'
import moment from 'moment'

export default {
    actions: {
        axiosGetShips(ctx) {
            // ctx.commit("addStatusRequestFilter");
            // ctx.commit("showLoad")
            return axios.get(process.env.VUE_APP_URL+"/ship/index/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                // ctx.commit("hideLoad")
                ctx.commit("setShips", response.data.results)
                // ctx.commit("decStatusRequestFilter");
            }).catch(() => {
                // ctx.commit("hideLoad")
                // ctx.commit("decStatusRequestFilter");
                // if(ctx.getters.getToken>20)
                // ctx.commit("setAlert", {show: true, text: error}, {root: true});
            });
        },
        axiosGetYearsForFilter(ctx) {
            // ctx.commit("addStatusRequestFilter");
            return axios.get(process.env.VUE_APP_URL+"/get/index/year/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                // ctx.commit("decStatusRequestFilter");
                ctx.commit("setYearsForFilter", response.data.years)
            }).catch((error) => {
                // ctx.commit("decStatusRequestFilter");
                if(ctx.getters.getToken>20)
                    ctx.commit("setAlert", {show: true, text: error}, {root: true});
            });
        },
        axiosGetRoutes(ctx) {
            // ctx.commit("addStatusRequestFilter");
            return axios.get(process.env.VUE_APP_URL+"/route/index/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                // ctx.commit("decStatusRequestFilter");
                ctx.commit("updateRoutes", response.data.results)
            }).catch((error) => {
                // ctx.commit("decStatusRequestFilter");
                if(ctx.getters.getToken>20)
                    ctx.commit("setAlert", {show: true, text: error}, {root: true});
            });
        },
        axiosDeleteRoute(ctx, data) {
            return axios.delete(process.env.VUE_APP_URL+"/route/index/" + data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).catch((error) => {
                if(ctx.getters.getToken>20)
                    ctx.commit("setAlert", {show: true, text: error}, {root: true});
            }).then(()=> {
                // ctx.commit("deleteRoute", data);
            }).catch((error) => {
                if(ctx.getters.getToken>20)
                    ctx.commit("setAlert", {show: true, text: error}, {root: true});
            });
        },
        axiosCreateRoute(ctx, data) {
            if(data.sorting_index === null || (""+data.sorting_index).length === 0) {
                data.sorting_index = 9999;
            }
            // // console.log("data create route");
            // // console.log(data);
            return axios.post(process.env.VUE_APP_URL+"/route/index/", {
                reduction: data.reduction,
                route: data.route,
                sorting_index: Number(data.sorting_index),
                ship_type: data.ship_type
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                    "accept": "application/json",
                    "Content-Type": "application/json",
                }
            }).then(() => {
                // ctx.commit("addNewRoute", response.data)
            }).catch((error) => {
                if(ctx.getters.getToken>20)
                    ctx.commit("setAlert", {show: true, text: error}, {root: true});
            });
        },
        axiosChangeRoute(ctx, data) {
            if(data.sorting_index === null) {
                data.sorting_index = 9999;
            }
            // // console.log("CHANGEE");
            // // console.log(data);
            return axios.put(process.env.VUE_APP_URL+"/route/index/" + data.id, {
                reduction: data.reduction,
                route: data.route,
                sorting_index: Number(data.sorting_index),
                ship_type: data. ship_type
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                // ctx.commit("changeRoute", data);
            }).catch(error=> {
                if(ctx.getters.getToken>20)
                    ctx.commit("setAlert", {show: true, text: error}, {root: true});
            })
        },
        axiosPostIndexFilter(ctx, data) {
            // console.log(9);
            if(data.ranges.length !== 0 && data.routes.length !== 0 && data.ship_type.length !== 0) {
                let ct = axios.CancelToken.source();
                ctx.commit("cancelTokenSource", ct);
                ctx.commit("addStatusRequestFilter");
                // console.log(10);
                return axios.post(process.env.VUE_APP_URL+"/get/index/filter", {
                    "ranges": data.ranges,
                    "routes": data.routes,
                    "ship_type": data.ship_type,
                }, {
                    cancelToken: ct.token,
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(response => {
                    // // console.log("RESPONSE FILTER");
                    // // console.log(response);

                    ctx.commit("updateDataFilter", response.data);
                    ctx.commit("decStatusRequestFilter");
                }).catch(()=> {
                    // ctx.commit("setStatusRequestFilter", false);
                    ctx.commit("decStatusRequestFilter");

                })
            } else {
                return null
            }
        },
    },
    mutations: {
        addStatusRequestFilter(state) {
            state.statusRequestFilter++;
        },
        decStatusRequestFilter(state) {
            if(state.statusRequestFilter > 0) {
                state.statusRequestFilter--;
            }
        },
        setStatusRequestFilter(state, data) {
            state.statusRequestFilter = data;
        },
        cancelPostIndexFilter(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
                // state.statusRequestFilter = false;
            }
        },
        cancelTokenSource(state, data) {
            state.cancelTokenSource = data;
        },
        setShips(state, data) {
            state.ships.splice(0, state.ships.length);
            state.ships = [];
            for(let i=0; i<data.length; i++) {
                let form = {
                    id: data[i].id,
                    name: data[i].name,
                    status: false,
                }
                if (i===0) {
                    form.status = true;
                }
                state.ships.push(form);
            }
        },
        setYearsForFilter(state, data) {
            state.yearsForFilter.splice(0, state.yearsForFilter.length);
            state.yearsForFilter = [];
            for(let i=0; i<data.length; i++) {
                let form = {
                    name: data[i],
                    status: false,
                }
                state.yearsForFilter.push(form);
            }
        },
        updateRoutes(state, data) {
            // // console.log(data);
            for(let i=0; i<data.length; i++) {
                data[i].status = false;
                if(data[i].route === null) {
                    data[i].route = "";
                }
            }
            state.routes = data;
            state.routes.sort((a,b)=> a.sorting_index > b.sorting_index ? 1: -1);

        },
        addNewRoute(state, data) {
            data.status = false;
            if(data.route === null) {
                data.route = "";
            }
            state.routes.push(data);
            state.routes.sort((a,b)=> a.sorting_index > b.sorting_index ? 1: -1);
        },
        deleteRoute(state, id) {
            for(let i=0; i<state.routes.length; i++) {
                if(state.routes[i].id === id) {
                    state.routes.splice(i,1);
                }
            }
            state.routes.sort((a,b)=> a.sorting_index > b.sorting_index ? 1: -1);
        },
        changeRoute(state, data) {
            for(let i=0; i<state.routes.length; i++) {
                if(state.routes[i].id === data.id) {
                    state.routes[i].reduction = data.reduction;
                    state.routes[i].route = data.route;
                    if(state.routes[i].route === null || state.routes[i].route === undefined) {
                        state.routes[i].route = "";
                    }
                    state.routes[i].ship_type = data.ship_type;
                    state.routes[i].sorting_index = data.sorting_index;
                }
            }
            state.routes.sort((a,b)=> a.sorting_index > b.sorting_index ? 1: -1);
        },
        updateDataFilter(state, data) {
            state.dataFilter.nameLines.splice(0,state.dataFilter.nameLines.length);
            state.dataFilter.valueLines.splice(0,state.dataFilter.valueLines.length);
            state.dataFilter.data.splice(0,state.dataFilter.data.length);
            data.sort((a, b) => moment(b.created_at, 'YYYY-MM-DD HH:mm:ss') - moment(a.created_at, 'YYYY-MM-DD HH:mm:ss'))
            data = data.map(a=> {
                a.created_at = moment(a.created_at).format('YYYY-MM-DD 00:00:00')
                return a
            })
            data = data.filter((item,index)=> {
                return (data.map(a=> {return moment(a.created_at).format('DD.MM.YYYY')})).indexOf(moment(item.created_at).format('DD.MM.YYYY')) === index;
            })
            data.sort((a, b) => moment(new Date(a.created_at), 'DD.MM.YY') - moment(new Date(b.created_at), 'DD.MM.YY'))
            state.statusForStroke.splice(0, state.statusForStroke.length);


            let nameValue = Array.from(new Set(data.map(a=> {return Number(moment(a.created_at).format('YYYY'))})))
            let valueName = nameValue.map(a=> {return 'value'+a})
            let maxValueChart = Math.max(...data.map(a=> {return a.amount}))
            let orderingDates =  Array.from(new Set(JSON.parse(JSON.stringify(data.map(a=> {return moment(a.created_at).format('2024-MM-DD')})))))
            orderingDates.sort((a, b) => moment(new Date(a.date), 'DD.MM.YY') - moment(new Date(b.date), 'DD.MM.YY'))
            let dataChart = [];
            orderingDates.forEach(date=> {
                let items = data.filter(a=> moment(a.created_at).format('DD.MM') === moment(date).format('DD.MM'))
                let form = {
                    additional: 10,
                    additional1: maxValueChart,
                    date: date,
                    showTooltip: '',
                }
                items.forEach(a=> {
                    form['value'+moment(a.created_at).format('YYYY')] = Math.round((a.amount)*1000)/1000
                })
                dataChart.push(form)
            })
            state.dataFilter.data = dataChart.sort((a, b) => moment(new Date(a.date), 'DD.MM.YY') - moment(new Date(b.date), 'DD.MM.YY'));
            state.dataFilter.nameLines = nameValue;
            state.dataFilter.valueLines = valueName;
            let orderingDatesInQueue =JSON.parse(JSON.stringify(data.map(a=> {return moment(a.created_at).format('YYYY-MM-DD')})))
            orderingDatesInQueue.sort((a, b) => moment(new Date(a.date), 'DD.MM.YY') - moment(new Date(b.date), 'DD.MM.YY'))
            let dataChartInQueue = []
            let array = JSON.parse(JSON.stringify(data))
            array.forEach(item=> {
                let form = {
                    date: item.created_at,
                    valueInQueue: Math.round((item.amount)*1000)/1000,
                    valueInQueueYesterday: 0,
                    showTooltip: "",
                    additional: 10,
                    additional1: maxValueChart
                }
                dataChartInQueue.push(form)
            })
            state.dataFilterInQueue.data = dataChartInQueue
            state.dataFilterInQueue.nameLines = nameValue;
            state.dataFilterInQueue.valueLines = valueName;
            state.dataFilterInQueue.data.forEach((item, i) => {
                if(i !== 0) {
                    item.valueInQueueYesterday = state.dataFilterInQueue.data[i-1].valueInQueue
                }
            })

        },
        clearDataFilter(state) {
            state.dataFilter.nameLines.splice(0, state.dataFilter.nameLines.length);
            state.dataFilter.valueLines.splice(0, state.dataFilter.valueLines.length);
            if(state.dataFilter.data !== null) {
                state.dataFilter.data.splice(0, state.dataFilter.data.length);
            }
            state.statusRequestFilter = 0;
        },
        setStatusForStroke(state, index) {
            // // console.log(index);
            state.statusForStroke[index] = !state.statusForStroke[index];
            // // console.log(state.statusForStroke);
        },
        setFirstRequest(state, data) {
            state.firstRequest = data;
        }
    },
    state: {
        ships: [],
        yearsForFilter: [],
        routes: [],
        cancelTokenSource: null,
        dataFilter: {
            nameLines: [],
            valueLines: [],
            data: [],
        },
        dataFilterInQueue: {
            nameLines: [],
            valueLines: [],
            data: [],
        },
        statusForStroke: [],
        routesForShip: [],
        statusRequestFilter: 0,
        firstRequest: false,
    },
    getters: {
        getDataFilterInQueue(state) {
            return state.dataFilterInQueue;
        },
        getShips(state) {
            return state.ships;
        },
        getYearForFilter(state) {
            return state.yearsForFilter;
        },
        getRoutes(state) {
            return state.routes;
        },
        getDataFilter(state) {
            return state.dataFilter;
        },
        getStatusRequestFilter(state) {
            return state.statusRequestFilter;
        },
        getStatusForStroke(state) {
          return state.statusForStroke;
        },
    },
}
