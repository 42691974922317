<template>
  <div 
    class="border border-dark-gray m-b-4 rounded-lg overflow-hidden"
    v-if="(bunkersData.additional_info.is_vlsfo && (bunkersData.additional_info.vlsfo_quantity ||
      bunkersData.additional_info.vlsfo_price ||
      bunkersData.vlsfo_debit ||
      bunkersData.vlsfo_credit)) ||
      (bunkersData.additional_info.is_lsmgo && (bunkersData.additional_info.lsmgo_quantity ||
      bunkersData.additional_info.lsmgo_price ||
      bunkersData.lsmgo_debit ||
      bunkersData.lsmgo_credit)) ||
      bunkersData.additional_info.comment"
  >
    <div class="vessel_owners__table-wrap">
      <div class="vessel_owners__table">
        <div class="colspan-1-6 bg-blue-dark uppercase p-x-3 p-y-2 fz13 fw500 text-white">Bunker supply by owns</div>
      </div>

      <div class="vessel_owners__table">
        <div class="bg-white-dark border-t border-r"></div>
        <div class="bg-white-dark border-t border-r text-gray-text fz13 p-x-2 p-y-1">Qntty, mt</div>
        <div class="bg-white-dark border-t border-r text-gray-text fz13 p-x-2 p-y-1">Price, {{activeCurrency}}/mt</div>
        <div class="bg-white-dark border-t border-r"></div>
        <div class="bg-white-dark border-t"></div>
      </div>

      <div class="vessel_owners__table"
        v-if="bunkersData.additional_info.is_vlsfo && (bunkersData.additional_info.vlsfo_quantity ||
          bunkersData.additional_info.vlsfo_price ||
          bunkersData.vlsfo_debit ||
          bunkersData.vlsfo_credit)"
      >
        <div class="border-t border-r p-x-3 p-y-2 text-dark">VLSFO</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{bunkersData.additional_info.vlsfo_quantity | formatNumberThreeZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{bunkersData.additional_info.vlsfo_price | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right amount">{{bunkersData.vlsfo_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right amount">{{bunkersData.vlsfo_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="bunkersData.additional_info.is_lsmgo && (bunkersData.additional_info.lsmgo_quantity ||
          bunkersData.additional_info.lsmgo_price ||
          bunkersData.lsmgo_debit ||
          bunkersData.lsmgo_credit)"
      >
        <div class="border-t border-r p-x-3 p-y-2 text-dark">LSMGO</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{bunkersData.additional_info.lsmgo_quantity | formatNumberThreeZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark">{{bunkersData.additional_info.lsmgo_price | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark text-align-right amount">{{bunkersData.lsmgo_debit | formatNumberTwoZeroEmpty}}</div>
        <div class="border-t p-x-2 p-y-2 text-align-right amount">{{bunkersData.lsmgo_credit | formatNumberTwoZeroEmpty}}</div>
      </div>

      <div class="vessel_owners__table"
        v-if="bunkersData.additional_info.comment"
      >
        <div class="border-t border-r p-x-3 p-y-2 text-dark">Comment</div>
        <div class="border-t border-r p-x-2 p-y-2 text-dark colspan-2-4">{{bunkersData.additional_info.comment}}</div>
        <div class="border-t border-r"></div>
        <div class="border-t"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeCurrency: String,
    bunkersData: Object
  },
}
</script>

<style>

</style>