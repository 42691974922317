import axios from 'axios'

export default {
  actions: {
    async axiosGetBanks(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/payment/cash/balance/?is_archive_cash=false',
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.dispatch('setBanks', response.data)
      }).catch(error => {
        console.error('axiosGetBanks', error);
      })
    },
    async axiosGetRequisites(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/payment/cash/requisite/?is_archive=false',
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.state.requisites = response.data.results
      }).catch(error => {
        console.error('axiosGetRequisites', error);
      })
    },
    async axiosGetBanksArchive(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/payment/cash/balance/?is_archive_cash=true',
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.dispatch('setBanksArchive', response.data)
      }).catch(error => {
        console.error('axiosGetBanksArchive', error);
      })
    },
    async axiosBanksToArchive(ctx, {ids, bool}) {
      return await axios.post(process.env.VUE_APP_URL+'/payment/cash/archive/', {ids, is_archived: bool}, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
    },
    async axiosBanksAccountsToArchive(ctx, {ids, bool}) {
      return await axios.post(process.env.VUE_APP_URL+'/payment/cash/requisite/archive/', {ids, is_archived: bool}, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
    },
    async axiosAddBank(ctx, bank) {
      await axios.post(process.env.VUE_APP_URL+'/payment/cash/', bank, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
    },
    async axiosEditBank(ctx, {bank, id}) {
      await axios.patch(process.env.VUE_APP_URL+'/payment/cash/'+ id, bank, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
    },
    async axiosAddBankAccount(ctx, account) {
      await axios.post(process.env.VUE_APP_URL+'/payment/cash/requisite/', account, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
    },
    async axiosEditBankAccount(ctx, {id, account}) {
      await axios.patch(process.env.VUE_APP_URL+'/payment/cash/requisite/' + id, account, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
    },
    async axiosGetCurrencyRates(ctx) {
      // "AED","rate":3.67263,"created_at":"2021-08-30"
      // "AED","rate":3.672471862580903,"created_at":"2021-10-04"
      await axios.get(process.env.VUE_APP_URL+'/currency/', 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        },
        params: {
          'ordering':'created_at',
          'limit': 9999,
        },
      }).then(response => {
        ctx.commit('setCurrencyRates', response.data.results)
      })
    },
    setBanks(ctx, banks) {
      ctx.state.banks = []
      
      banks.sort((a, b) => {
        if (a.type < b.type)
          return -1;
        if (a.type > b.type)
          return 1;
        
        return 0
      })
      
      banks.forEach(bank => {
        if(bank.type == 'cash'){
          ctx.state.banks.push(ctx.getters.cashConstructor(bank))
        }else{
          ctx.state.banks.push(ctx.getters.bankConstructor(bank))
          if(bank.is_favorite && !ctx.state.activeBank)
            ctx.state.activeBank = ctx.getters.bankConstructor(bank)
        }
      })

      if(!ctx.state.activeBank)
        ctx.state.activeBank = ctx.state.banks.filter(el => {
          return el.type == 'bank'
        })[0]

      console.log('ctx.state.banks', ctx.state.banks)
    },
    setBanksArchive(ctx, banks) {
      ctx.state.banksArchive = []
      
      banks.forEach(bank => {
        if(bank.type == 'cash'){
          ctx.state.banksArchive.push(ctx.getters.cashConstructor(bank))
        }else{
          ctx.state.banksArchive.push(ctx.getters.bankConstructor(bank))
        }
      })
    }
  },
  mutations: {
    setActiveBank(state, bank) {
      state.activeBank = bank
    },
    setCurrencyRates(state, rates) {
      let localRates = rates.slice()
      state.rates = []

      function uniqBy(a, key) {
        return [
          ...new Map(
            a.map(x => [key(x), x])
          ).values()
        ]
      }
      state.rates = uniqBy(localRates, it=>it.currency)
    }
  },
  state: {
    banks: null,
    requisites: null,
    banksArchive: null,
    activeBank: null, // selected bank on page "bank-and-cash"
    rates: null
  },
  getters: {
    toFixedNumber: () => number => {
      let fixedNumber = parseFloat(number)
      fixedNumber = fixedNumber ? fixedNumber : 0
      fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 4, maximumFractionDigits: 4}).replace(',', ', ')
      return fixedNumber
    },
    toFixed2Number: () => number => {
      let fixedNumber = parseFloat(number)
      fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(',', ', ')
      return fixedNumber
    },
    strToNumber: () => str => {
      let fixedNumber = str.replaceAll(/\s/g, '').replaceAll(',', '.')
      fixedNumber = parseFloat(fixedNumber)
      fixedNumber = fixedNumber ? fixedNumber : 0
      return fixedNumber
    },
    bankConstructor: (state, getters) => bank => {
      let requisites = []
      if(bank.requisites){
        bank.requisites.forEach(cash => {
          requisites.push(getters.bankCashConstructor(cash))
        })
      }
      return {
        id: bank.id,
        type: bank.type,
        name: bank.name,
        currency: bank.currency,
        is_favorite: bank.is_favorite,
        requisites: requisites
      }
    },
    bankCashConstructor: () => cash => {
      return {
        id: cash.id,
        holder: cash.holder,
        registered_address: cash.registered_address,
        account_number: cash.account_number,
        iban: cash.iban,
        description: cash.description,
        bank: cash.bank,
        bank_address: cash.bank_address,
        clearing_number: cash.clearing_number,
        bic: cash.bic,
        is_archive: cash.is_archive,
        currency: cash.currency,
        account: cash.account,
        balance: cash.balance
      }
    },
    cashConstructor: () => cash => {
      return {
        id: cash.id,
        type: cash.type,
        name: cash.name,
        currency: cash.currency,
        account: cash.account,
        is_favorite: cash.is_favorite,
        balance: cash.balance,
      }
    },
    getFavouriteBanks(state) {
      if(!state.banks) return false
      return state.banks.filter(bank => {
        return bank.is_favorite == true
      })
    },
    getNotFavouriteBanks(state) {
      if(!state.banks) return false
      return state.banks.filter(bank => {
        return bank.is_favorite == false
      })
    }
  }
}