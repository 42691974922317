<template>
  <div class="fixed left-0 bottom-0 flex items-center w-full p-l-9 p-y-2 p-r-6 bg-white shadow-bottom box-border z-10">
    <div class="m-r-auto fz13 fw500 text-gray-text">
      Selected:
      <span class="text-blue-dark">{{selected.length}}/{{ports.length}}</span>
    </div>

    <button class="bg-blue-dark text-white-always p-y-1 p-x-6 flex items-center fz14 fw500 rounded" v-if="$route.name != 'ports-archive' && getPermissionsByType('archive_and_unarchive_ports')" @click="onArchive">
      <svg class="m-r-1" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.636 4.366a.225.225 0 00-.227.223v.675h3.182v-.675a.225.225 0 00-.227-.223h-2.728zm4.319.898v-.675A1.59 1.59 0 0013.364 3h-2.728a1.59 1.59 0 00-1.59 1.589v.675H5.181a.682.682 0 000 1.366h1.136v9.876A2.497 2.497 0 008.818 19h6.364c1.377 0 2.5-1.113 2.5-2.494V6.63h1.136a.682.682 0 000-1.366h-3.864zM7.681 6.63v9.876c0 .62.505 1.128 1.136 1.128h6.364c.63 0 1.136-.509 1.136-1.128V6.63H7.682zm2.954 2.256c.377 0 .682.306.682.683v4.981a.682.682 0 11-1.363 0V9.57c0-.378.305-.684.681-.684zm2.728 0c.376 0 .681.306.681.683v4.981a.682.682 0 11-1.363 0V9.57c0-.378.305-.684.682-.684z" fill="#F4F6F9"/></svg>
      Archive
    </button>
    <button class="bg-blue-dark text-white-always p-y-1 p-x-6 flex items-center fz14 fw500 rounded" v-else-if="getPermissionsByType('archive_and_unarchive_ports')" @click="onUnArchive">
      <svg class="m-r-1" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.017 9.785L6.713 7.002 9.017 4.22a.736.736 0 00-1.108-.967L5.182 6.519a.735.735 0 000 .967l2.727 3.267a.735.735 0 101.108-.968z" fill="#fff"/><path d="M13.654 6.266H5.736a.736.736 0 100 1.471h7.918c2.136 0 3.875 1.863 3.875 4.242 0 2.378-1.739 4.507-3.875 4.507H6.717a.736.736 0 100 1.472h6.937c2.947 0 5.346-2.746 5.346-5.98 0-3.233-2.398-5.712-5.346-5.712z" fill="#fff"/></svg>
      Unarchive
    </button>

    <button class="m-l-6 fz0" @click="$emit('on-unselect')"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#9AA3B0"/><path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#9AA3B0"/></svg></button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    ports: Array,
    selected: Array
  },
  computed: {
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    onArchive() {
      this.$emit('on-archive')
    },
    onUnArchive() {
      this.$emit('on-unarchive')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>