<template>
  <div v-on:dblclick="showList()" ref="shipBlock" class="position-relative">
    <p class="fz13 text-dark text-info click-element-border overflow-hidden" v-if="settings.nameValue !== 'statement_member' && settings.nameValue !== 'client'" :class="{'p-4-2' : settings.nameValue === 'currency', 'pr-26': settings.value && settings.nameValue === 'client'}">{{textInfo && textInfo.length ? textInfo : (value ? value.name : '')}}</p>
    <p class="fz13 text-dark text-info click-element-border" v-else-if="settings.nameValue !== 'client'">{{textInfo | formatDateFromCpDateWithotTimeZome}}</p>
    <p class="click-element-border fz13 text-dark text-info overflow-hidden client-text" :textAfter="textInfo && textInfo.length ? textInfo : (value ? value.name : '')" v-else :class="{'p-4-2' : settings.nameValue === 'currency', 'pr-26': settings.value && settings.nameValue === 'client'}">
    </p>

    <svg v-if="settings.value && settings.nameValue === 'client'" class="counterparty-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 4.5L5.84211 2M3 4.5L5.84211 7M3 4.5H13" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13 11.5L10.1579 9M13 11.5L10.1579 14M13 11.5H3" stroke="#9AA3B0" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "element-select-list-wrap-for-item",
  props: {
    disabled: Boolean,
    value: [Object, Array, String, Number],
    settings: Object,
    textInfo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(['openListPayment']),
    showList() {
      if(!this.disabled) {
        this.settings.element = this.$refs.shipBlock
        console.log(this.settings.element.parentElement.parentElement)
        console.dir(this.settings.element.parentElement.parentElement.style.transform.replace(/[^0-9]/g, "").slice(0, this.settings.element.parentElement.parentElement.style.transform.replace(/[^0-9]/g, "").length-1))
        this.openListPayment(this.settings)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .p-4-2 {
    padding: 6px 2px 4px 2px!important;
  }
  .counterparty-svg {
    position: absolute;
    right: 4px;
    top: 6px;
    left: auto;
    width: 18px;
    height: 18px;
  }
  .dart-mode {
    .counterparty-svg {
      &>*:nth-child(1) {
        stroke: #FFFFFF;
      }
    }
  }
  .pr-26 {
    padding-right: 26px !important;
  }
  .client-text {
    position: relative;
    &::after {
      position: absolute;
      width: calc(100% - 26px);
      content: attr(textAfter);
      box-sizing: border-box;
      height: 30px;
      border: none !important;
      padding: 0;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: flex;
      align-items: center;
      font-size: 0.8125rem !important;
      line-height: 1rem !important;
      color: var(--color-dark) !important;

    }
  }
</style>