<template>
  <div class="flex flex-col overflow-hidden h-full">
    <bread />
    
    <div class="w-full h-full overflow-y-auto">
      <div class="flex w-full min-h-full">
        <div class="w-1/6 flex-shrink-0">
          <div class="min-h-full bg-white border-r border-gray-light">
            <aside-ports 
              :countryList="portsCountry" 
              :active="activeCountry" 
              :ifLoaded="ifLoaded"
              :nextRequest="nextRequest"
              :filters="filters"
              :isArchive="true"
              @on-select="onSelectCountry"
              @on-filters="isFilters = true"
              @on-load-next="onLoadNext"
            />
          </div>
        </div>
        <div class="w-5/6 p-x-6 p-b-15">
          <list-top :active="activeCountry" v-model.lazy="localSearch"/>
          <list-ports
              :loadListLoader="loadListLoader"
              @load-next="showNext"
              :loadActive="loadActiveNext"
              :next="nextLintForPortsList"
            ref="list"
            :ports="ports"
            @on-select="(arr)=>selectedPorts=arr"
            @on-unarchive="onUnarchive"
          />
        </div>
      </div>
    </div>

    <filters-ports 
      v-if="isFilters" 
      :filters="filters" 
      @on-close="isFilters = false"
    />

    <bottom-ports 
      v-if="selectedPorts.length" 
      :ports="getPortsByActive" 
      :selected="selectedPorts"
      @on-unselect="$refs.list.uncheckAll()"
      @on-unarchive="onUnarchive(selectedPorts)"
    />

    <archive-modal 
      :isShowModal="isShowArchiveModal"
      :ports="archivedPorts"
      @on-close="onCloseModal"
      @on-send="sendUnarchive"
    ></archive-modal>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import bread from '@/components/ports/archive/bread'
import asidePorts from '@/components/ports/list/aside'
import listTop from '@/components/ports/list/listTop'
import listPorts from '@/components/ports/list/list'
import filtersPorts from '@/components/ports/list/filters'
import bottomPorts from '@/components/ports/list/bottom'
import archiveModal from '@/components/ports/archive/modal'

export default {
  components: {
    bread,
    asidePorts,
    listTop,
    listPorts,
    filtersPorts,
    bottomPorts,
    archiveModal
  },
  data() {
    return {
      loadListLoader: false,
      activeCountry: '',
      ifLoaded: false,
      selectedPorts: [],
      localSearch: '',
      isFilters: false,
      archivedPorts: null,
      isShowArchiveModal: false,
      loadActiveNext: false,
    }
  },
  watch: {
    localSearch: function () {
      this.loadPorts()
    },
    filters: {
      deep: true,
      async handler() {
        await this.axiosGetPortsArchiveByCountry()
        this.onSelectCountry(Object.keys(this.portsCountry)[0])
      }
    }
  },
  computed: {
    ...mapState({
      nextLintForPortsList: state => state.ports.nextLintForPortsList,
      ports: state => state.ports.portsArchive,
      portsCountry: state => state.ports.portsCountryArchive,
      nextRequest: state => state.ports.nextRequest,
      filters: state => state.ports.filtersArchive,
    }),
    /* countryList() {
      if(!this.ports) return []
      let result = []
      this.ports.forEach(el => {
        if(el.country){
          if(result.indexOf(el.country) == -1)
            result.push(el.country)
        }
      })

      return result
    },
    getPortsByActive() {
      if(!this.ports) return []
      return this.ports.filter(el => {
        return el.country == this.activeCountry
      })
    } */
  },
  methods: {
    ...mapActions(['axiosGetPortsArchiveByCountry', 'axiosGetPortsListArchive', 'axiosPortArchive']),
    ...mapMutations(['addTip', 'showLoad', 'hideLoad']),
    async loadPorts() {
      this.loadListLoader = true
      await this.axiosGetPortsListArchive({search: this.localSearch, ports: this.portsCountry[this.activeCountry]})
      this.loadListLoader = false
    },
    async showNext() {
      this.loadActiveNext = true
      await this.axiosShowNextPortsList({next: this.nextLintForPortsList, is_archive: true})
      this.loadActiveNext = false
    },
    async onSelectCountry(country) {
      this.loadListLoader = true

      this.activeCountry = country
      let ports = this.portsCountry[country]
      await this.axiosGetPortsListArchive({search: this.localSearch, ports:ports})
      this.loadListLoader = false
    },
    async onLoadNext() {
      this.ifLoaded = true
      await this.axiosGetPortsArchiveByCountry(true)
      this.ifLoaded = false
    },

    onUnarchive(ports) {
      this.archivedPorts = ports
      this.isShowArchiveModal = true
    },
    onCloseModal() {
      this.archivedPorts = null
      this.isShowArchiveModal = false
    },
    async sendUnarchive() {
      let str = ''
      let obj = {
        ids: null,
        is_archived: false
      }
      if(Array.isArray(this.archivedPorts)){
        obj.ids = this.archivedPorts
        str = this.archivedPorts.length + (this.archivedPorts.length == 1 ? ' port is':' ports are') +' unarchived'
      }else{
        obj.ids = [this.archivedPorts.id]
        str = this.archivedPorts.name + ` is unarchived`
      }
      
      await this.axiosPortArchive(obj)
      this.addTip(str)
      this.onCloseModal()

      this.portsCountry[this.activeCountry] = this.portsCountry[this.activeCountry].filter(el=>obj.ids.indexOf(el)==-1)
      await this.axiosGetPortsListArchive({search: this.localSearch, ports:this.portsCountry[this.activeCountry]})
      /* await this.axiosGetPortsListArchive()
      if(this.countryList.findIndex(el => el == this.activeCountry) == -1)
        this.activeCountry = '' */
    }
  },
  async beforeMount() {
    this.showLoad()
    await this.axiosGetPortsArchiveByCountry()
    if(this.ports?.length){
      this.activeCountry = this.ports[0].country
      await this.axiosGetPortsListArchive({search: this.localSearch, ports:this.portsCountry[this.activeCountry]})
    }else{
      await this.onSelectCountry(Object.keys(this.portsCountry)[0])
    }
    this.hideLoad()
  }
}
</script>

<style>

</style>