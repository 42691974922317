<template>
  <modal-slots :show-mod="true" :footerHide="true" @close="$emit('close')">
    <template v-slot:title>
      <p class="fz18 fw500 text-dark">{{ unarchiveView ? 'Unarchive to' : 'Move to' }}</p>
    </template>
    <div class="list d-flex flex-column">
      <div class="w100Proc" v-for="(item, i) in list" :key="i" v-on:click="nameSectionTo = item.name">
        <div class="w100Proc d-flex align-items-center h36 p0-16 box-border cursor-pointer position-relative" :class="{'active': activeFolder == item.name}">
          <div class="position-absolute t0 l0 w100Proc h100Proc" v-on:click="(item.name !== 'Payments' && item.name !== 'Vessels' && item.name !== 'Tasks') ? setActiveFolder(item.name) : null"></div>
          <svg class="mr4 position-relative z-10" v-on:click="openFolder(item)" :class="{'rotate-180': item.is_show}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.27362 4.39844C9.54089 4.39844 9.67474 4.72158 9.48575 4.91057L6.21001 8.18631C6.09285 8.30346 5.9029 8.30346 5.78574 8.18631L2.51001 4.91057C2.32102 4.72158 2.45487 4.39844 2.72215 4.39844L9.27362 4.39844Z" fill="#2C2C2C"/>
          </svg>
          <span class="mr8" v-html="getSvg(item.name)"></span>
          <p class="fz16 fw500 text-dark">{{item.name}}</p>
        </div>
        <template v-if="item.folders && item.is_show">
          <folders-drop :contains_files="getContainsFiles" :contains_folders="getContainsFolders" :moveToMode="true" @delete="(id)=>(deleteItem(id, i))" @clickItem="(e)=> {itemClick = e}" @addedFolder="$emit('addedFolder')" :parent_id="getIdsSection(item.name)" :activeType="activeFolder ? activeFolder.type : null" :activeFolder="activeFolder ? activeFolder.id : null" @clickZone="clickZone" class="pl24" v-for="(item1, i) in item.folders" :key="i" :item="item1"></folders-drop>
          <pagination-panel-default class="p0-24" :if-active="loadMorePaymentBool" @load-more="loadMorePayment(item)" v-if="nextLinkPaymentLeftList && item.name == 'Payments'"></pagination-panel-default>
          <pagination-panel-default class="p0-24" @load-more="loadNextCountries(item)" :if-active="loadingNextPageCountries" v-if="portsCurrentPage<portsTotalPage && item.name == 'Ports'"></pagination-panel-default>
          <pagination-panel-default class="p0-24" :if-active="loadMoreClientBool" @load-more="loadMoreClient(item)" v-if="nextLinkClientLeftList && item.name == 'Counterparties'"></pagination-panel-default>
        </template>
      </div>
    </div>
    <div class="h48 w100Proc d-flex align-items-center border-top border-color-normal p0-24 box-border">
      <button class="blue-button-text fz14 fw500 " :class="{'opacity-0': unarchiveView}" :disabled="!activeFolder || unarchiveView" v-on:click="createFolder">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.2221 13.2218H7.7778V9.77734C7.7778 9.3481 7.42931 8.99961 6.99993 8.99961C6.57069 8.99961 6.2222 9.3481 6.2222 9.77734V13.2218H2.77773C2.34849 13.2218 2 13.5703 2 13.9995C2 14.4289 2.34849 14.7774 2.77773 14.7774H6.2222V18.2217C6.2222 18.6511 6.57069 18.9996 6.99993 18.9996C7.42931 18.9996 7.7778 18.6511 7.7778 18.2217V14.7774H11.2221C11.6515 14.7774 12 14.4289 12 13.9995C12 13.5703 11.6515 13.2218 11.2221 13.2218Z" fill="#094172"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.19843 4.4C5.48046 4.4 4.89843 4.98203 4.89843 5.7V6.7C4.89843 7.0866 4.58503 7.4 4.19843 7.4C3.81183 7.4 3.49843 7.0866 3.49843 6.7V5.7C3.49843 4.20883 4.70726 3 6.19843 3H9.53779C10.5067 3 11.4013 3.51917 11.882 4.36042L12.7304 5.3831C12.9618 5.78814 13.3926 6.03812 13.8591 6.03812H18.1984C19.6896 6.03812 20.8984 7.24695 20.8984 8.73811V15.7C20.8984 17.1912 19.6896 18.4 18.1984 18.4H14.1984C13.8118 18.4 13.4984 18.0866 13.4984 17.7C13.4984 17.3134 13.8118 17 14.1984 17H18.1984C18.9164 17 19.4984 16.418 19.4984 15.7V8.73811C19.4984 8.02014 18.9164 7.43811 18.1984 7.43811H13.8591C12.8902 7.43811 11.9955 6.91894 11.5148 6.07769L10.6665 5.05502C10.435 4.64997 10.0043 4.4 9.53779 4.4H6.19843Z" fill="#094172"/>
        </svg>
        Create Folder
      </button>
      <button class="blue-button-text fz14 ml-auto fw500" v-on:click="$emit('close')">
        Cancel
      </button>
      <button class="blue-button h32 w180 fz14 ml42" :disabled="!activeFolder" v-on:click="move">{{ unarchiveView ? 'Unarchive' :  'Move' }}</button>
    </div>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import {mapActions, mapMutations, mapState} from "vuex";
import FoldersDrop from "@/components/folders/folders-drop";
import moment from 'moment';
import PaginationPanelDefault from "@/components/ports/paginationDefault.vue";
export default {
  name: "folders-modal-move-to",
  components: {PaginationPanelDefault, FoldersDrop, ModalSlots},
  computed: {
    ...mapState({
      listSections: state => state.folders.listSections,
      foldersForMoveTo: state => state.folders.foldersForMoveTo,
      listSectionsIds: state => state.folders.listSectionsIds,
    }),
    getContainsFiles() {
      return this.foldersForMoveTo.filter(a=> a.file_type != 'FOLDER').length !== 0
    },
    getContainsFolders() {
      return this.foldersForMoveTo.filter(a=> a.file_type == 'FOLDER').length !== 0
    },
    getFromSection() {
      if(this.$route.path.indexOf('/option/charterers') >= 0) {
        let item = this.foldersForMoveTo[0]
        if(item) {
          if(!item.filter_type) {
            return 'vessels'
          }
          if(item.filter_type == 'FREIGHT') {
            return 'invoices'
          } else {
            return 'owners'
          }
        }
      }
      if(this.$route.path.indexOf('my-files') >= 0) {
        return 'myfiles'
      }
      if(this.$route.path.indexOf('payment') >=0 || this.$route.path.indexOf('cash-flow') >=0 || this.$route.path.indexOf('acc-invoice') >=0) {
        return 'payments'
      }
      if(this.$route.path.indexOf('tasks') >=0) {
        return 'tasks'
      }
      if(this.$route.path.indexOf('vessel-detail') >= 0) {
        return 'vessels'
      }
      if(this.$route.path.indexOf('owners') >= 0) {
        return 'owners'
      }
      if(this.$route.path.indexOf('options/details') >= 0 && this.$route.path.indexOf('port') >= 0) {
        return 'ports'
      }
      if(this.$route.path.indexOf('options/berth-list') >= 0) {
        return 'ports_berth_list'
      }
      if(this.$route.path.indexOf('options/contact') >= 0) {
        return 'ports_contracts'
      }
      if(this.$route.path.indexOf('counterparties') >= 0 && this.$route.path.indexOf('options/detail') >= 0) {
        return 'counterparties'
      }
      if(this.$route.path.indexOf('counterparties') >= 0 && this.$route.path.indexOf('options/payments') >= 0) {
        return 'payments'
      }
      return ''
    },
    getToSection() {
      if(this.nameSectionTo === 'My Files') {
        return 'myfiles'
      }
      if(this.nameSectionTo === 'Payments') {
        return 'payments'
      }
      if(this.nameSectionTo === 'Tasks') {
        return 'tasks'
      }
      if(this.nameSectionTo === 'Vessels') {
        if(this.activeFolder && (this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder)) {
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'payment') {
            return 'payments'
          }
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'vessel_owners') {
            return 'owners'
          }
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'vessel_owners') {
            return 'owners'
          }
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'vessel_details') {
            return 'vessels'
          }
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'voyage_invoice') {

            return this.itemClick.name == 'SOA Sublet' ? 'owners' : 'invoices'
          }
        }
      }
      if(this.nameSectionTo === 'Ports') {
        if(this.activeFolder && (this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder)) {
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'port_details') {
            return 'ports'
          }
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'berth_list') {
            return 'ports_berth_list'
          }
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'contacts') {
            return 'ports_contracts'
          }
        }
      }
      if(this.nameSectionTo === 'Counterparties') {
        if(this.activeFolder && (this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder)) {
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'counterparties_detail') {
            return 'counterparties'
          }
          if((this.activeFolder.type ? this.activeFolder.type : this.activeFolder.type_object_folder) == 'payment') {
            return 'payments'
          }
        }
      }
      return ''
    },
    getListActiveSectionTo() {
      return this.list.find(el=> el.name == this.nameSectionTo).folders
    },
    getFromObjectId() {
      if(this.$route.params.detailData) {
        return this.$route.params.detailData ? JSON.parse(this.$route.params.detailData).id : null
      }
      if(this.$route.params.charterersDetail) {
        return this.$route.params.charterersDetail ? JSON.parse(this.$route.params.charterersDetail).id : null
      }
      if(this.$route.params.detailList) {
        return this.$route.params.detailList ? JSON.parse(this.$route.params.detailList).id : null
      }
      if(this.$route.params.detailContact) {
        return this.$route.params.detailContact ? JSON.parse(this.$route.params.detailContact).id : null
      }
      if(this.$route.params.portDetail) {
        return this.$route.params.portDetail ? JSON.parse(this.$route.params.portDetail).id : null
      }
      if(this.$route.params.detailVessel) {
        return this.$route.params.detailVessel ? JSON.parse(this.$route.params.detailVessel).id : null
      }
      if(this.$route.params.detailCounterparties) {
        return this.$route.params.detailCounterparties ? JSON.parse(this.$route.params.detailCounterparties).id : null
      }
      return null
    },
  },
  props: {
    unarchiveView: Boolean,
  },
  data() {
    return {
      nextLinkClientLeftList: null,
      loadMoreClientBool: null,
      loadingNextPageCountries: null,
      nextLinkPaymentLeftList: null,
      loadMorePaymentBool: null,
      portsCurrentPage: 1,
      portsTotalPage: 0,
      nameSectionTo: '',
      itemClick: null,
      activeFolder: null,
      list: [],
      svgs: [
        {
          name: 'My Files',
          svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M18.9636 8.86314C18.6984 8.4256 18.2299 8.16406 17.7102 8.16406H6.13968C5.56613 8.16406 5.04449 8.49494 4.81035 9.00726L1.75 15.574C1.86554 16.0549 2.30438 16.4141 2.82709 16.4141H15.1373C15.6636 16.4141 16.1447 16.1095 16.3797 15.6275L19.0249 10.2016C19.2338 9.77227 19.2106 9.27194 18.9636 8.86314Z" fill="#9AA3B0"/>\n' +
              '<path d="M4.21285 8.2331C4.44631 7.70762 4.96718 7.36801 5.53988 7.36801H16.4167V5.90475C16.4167 5.30156 15.9329 4.81073 15.3382 4.81073H8.9961C8.9868 4.81073 8.98042 4.80822 8.97803 4.8064L7.84287 3.137C7.64183 2.84086 7.30978 2.66406 6.955 2.66406H2.82868C2.2338 2.66406 1.75 3.15489 1.75 3.75808V13.6641L4.21285 8.2331Z" fill="#9AA3B0"/>\n' +
              '</svg>\n'
        },
        {
          name: 'Tasks',
          svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 2.5C3.84315 2.5 2.5 3.84315 2.5 5.5V14.5C2.5 16.1569 3.84315 17.5 5.5 17.5H14.5C16.1569 17.5 17.5 16.1569 17.5 14.5V5.5C17.5 3.84315 16.1569 2.5 14.5 2.5H5.5ZM13.8908 8.41579C14.1679 8.1079 14.1429 7.63369 13.8351 7.35659C13.5272 7.0795 13.053 7.10446 12.7759 7.41234L9.55469 10.9914L8.03033 9.46707C7.73744 9.17417 7.26256 9.17417 6.96967 9.46707C6.67678 9.75996 6.67678 10.2348 6.96967 10.5277L9.053 12.6111C9.19849 12.7565 9.39737 12.8359 9.60305 12.8305C9.80873 12.8251 10.0032 12.7354 10.1408 12.5825L13.8908 8.41579Z" fill="#9AA3B0"/>\n' +
              '</svg>\n'
        },
        {
          name: 'Payments',
          svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path fill-rule="evenodd" clip-rule="evenodd" d="M15 4.16667H5C4.53976 4.16667 4.16667 4.53976 4.16667 5V15C4.16667 15.4602 4.53976 15.8333 5 15.8333H15C15.4602 15.8333 15.8333 15.4602 15.8333 15V5C15.8333 4.53976 15.4602 4.16667 15 4.16667ZM5 2.5C3.61929 2.5 2.5 3.61929 2.5 5V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V5C17.5 3.61929 16.3807 2.5 15 2.5H5Z" fill="#9AA3B0"/>\n' +
              '<path d="M2.5 5C2.5 3.61929 3.61929 2.5 5 2.5H15C16.3807 2.5 17.5 3.61929 17.5 5V8.33333H2.5V5Z" fill="#9AA3B0"/>\n' +
              '<path d="M5.83333 2.08333C5.83333 1.6231 6.20643 1.25 6.66667 1.25C7.1269 1.25 7.5 1.6231 7.5 2.08333V3.33333C7.5 3.79357 7.1269 4.16667 6.66667 4.16667C6.20643 4.16667 5.83333 3.79357 5.83333 3.33333V2.08333Z" fill="#9AA3B0"/>\n' +
              '<path d="M12.5 2.08333C12.5 1.6231 12.8731 1.25 13.3333 1.25C13.7936 1.25 14.1667 1.6231 14.1667 2.08333V3.33333C14.1667 3.79357 13.7936 4.16667 13.3333 4.16667C12.8731 4.16667 12.5 3.79357 12.5 3.33333V2.08333Z" fill="#9AA3B0"/>\n' +
              '</svg>\n'
        },
        {
          name: 'Vessels',
          svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path fill-rule="evenodd" clip-rule="evenodd" d="M4.00008 10.0251L9.76996 7.66157V12.0986C9.76996 12.3189 9.94849 12.4974 10.1687 12.4974C10.389 12.4974 10.5675 12.3189 10.5675 12.0986V7.64797L16.3706 10.0251C16.8709 10.2301 17.1177 10.7955 16.9278 11.3017L15.4804 15.1603H15.3324C14.4058 15.1603 13.4791 14.8069 12.6658 14.1641C11.0391 15.4498 8.95911 15.4498 7.33242 14.1641C6.58404 14.7556 5.73972 15.102 4.88774 15.1536L3.44285 11.3017C3.25297 10.7955 3.49979 10.2301 4.00008 10.0251ZM5.09189 5.69686C5.09189 5.31027 5.40529 4.99686 5.79189 4.99686H9.06866C9.45526 4.99686 9.76865 5.31027 9.76865 5.69686V6.24441C9.76865 6.54047 9.5824 6.80452 9.30349 6.90384L6.02673 8.07078C5.57096 8.23308 5.09189 7.89515 5.09189 7.41134V5.69686ZM15.2788 7.41134C15.2788 7.89515 14.7997 8.23308 14.3439 8.07078L11.0671 6.90384C10.7882 6.80452 10.602 6.54047 10.602 6.24441V5.69687C10.602 5.31027 10.9154 4.99686 11.302 4.99686H14.5788C14.9654 4.99686 15.2788 5.31027 15.2788 5.69686V7.41134ZM12.9161 3.86353C12.9161 4.02921 12.7818 4.16353 12.6161 4.16353H7.79942C7.63373 4.16353 7.49942 4.02921 7.49942 3.86353V3.20781C7.49942 2.82121 7.81282 2.50781 8.19942 2.50781H12.2161C12.6027 2.50781 12.9161 2.82121 12.9161 3.20781V3.86353Z" fill="#9AA3B0"/>\n' +
              '<path d="M12.6653 15.25C11.0387 16.5357 8.95868 16.5357 7.332 15.25C6.51865 15.8929 5.592 16.2462 4.66534 16.2462H4.12275C3.68604 16.2462 3.33202 16.6003 3.33202 17.037C3.33201 17.4737 3.68603 17.8277 4.12275 17.8277H4.66534C5.582 17.8277 6.492 17.5683 7.332 17.0796C9.012 18.0533 10.9853 18.0533 12.6653 17.0796C13.5053 17.5645 14.4153 17.8277 15.332 17.8277H15.8746C16.3113 17.8277 16.6653 17.4737 16.6653 17.037C16.6654 16.6003 16.3113 16.2462 15.8746 16.2462H15.332C14.4053 16.2462 13.4787 15.8929 12.6653 15.25Z" fill="#9AA3B0"/>\n' +
              '</svg>\n',
        },
        {
          name: 'Ports',
          svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M15.9736 14.3779L16.6757 14.5957C17.055 14.7129 17.4205 14.3763 17.3322 13.988L16.5905 10.712C16.5021 10.3252 16.0285 10.1774 15.736 10.447L13.2703 12.7285C12.9779 12.998 13.0876 13.4823 13.4683 13.6011L14.111 13.8007C13.7272 14.2408 13.2749 14.6185 12.7692 14.917C12.1966 15.2551 11.5676 15.4851 10.9127 15.5947V10.1241H12.4951C12.9992 10.1241 13.4089 9.71446 13.4089 9.21033C13.4089 8.70622 12.9992 8.29653 12.4951 8.29653H10.9127V7.54265C11.8996 7.17104 12.6032 6.21916 12.6032 5.10433C12.6032 3.66814 11.4351 2.5 9.99891 2.5C8.56273 2.5 7.39459 3.66814 7.39459 5.10433C7.39459 6.21916 8.09821 7.17256 9.08511 7.54265V8.29653H7.50272C6.99861 8.29653 6.58892 8.70622 6.58892 9.21033C6.58892 9.71446 6.99861 10.1241 7.50272 10.1241H9.08511V15.5947C8.43175 15.4836 7.80123 15.2551 7.22858 14.917C6.72447 14.617 6.27063 14.2393 5.88682 13.7991L6.52952 13.5996C6.90875 13.4823 7.01841 12.998 6.72751 12.7269L4.26178 10.447C3.96936 10.1774 3.49571 10.3252 3.40738 10.712L2.66568 13.9895C2.57734 14.3763 2.94286 14.7145 3.32209 14.5972L4.02419 14.3794C4.62121 15.2353 5.39794 15.9572 6.29803 16.4903C7.41439 17.1512 8.69523 17.5 9.99891 17.5C11.3026 17.5 12.5819 17.1512 13.6998 16.4903C14.5999 15.9557 15.3766 15.2338 15.9736 14.3779ZM10.7756 5.1028C10.7756 5.2216 10.7482 5.3343 10.701 5.43634C10.5761 5.6983 10.3081 5.87953 9.99891 5.87953C9.68974 5.87953 9.4217 5.6983 9.29681 5.43634C9.24808 5.33582 9.22218 5.22312 9.22218 5.1028C9.22218 4.67484 9.57095 4.32608 9.99891 4.32608C10.4269 4.32608 10.7756 4.67484 10.7756 5.1028Z" fill="#9AA3B0"/>\n' +
              '</svg>\n'
        },
        {
          name: 'Counterparties',
          svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M12.333 6.4974C12.333 7.78606 11.2883 8.83073 9.99963 8.83073C8.71097 8.83073 7.6663 7.78606 7.6663 6.4974C7.6663 5.20873 8.71097 4.16406 9.99963 4.16406C11.2883 4.16406 12.333 5.20873 12.333 6.4974Z" fill="#9AA3B0"/>\n' +
              '<path d="M9.99964 9.4974C6.17371 9.4974 6.33297 12.9201 6.33297 15.4974C6.33297 15.4974 7.5916 16.4974 10.333 16.4974C13.0744 16.4974 13.6663 15.4974 13.6663 15.4974C13.6663 12.9201 13.8256 9.4974 9.99964 9.4974Z" fill="#9AA3B0"/>\n' +
              '<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5704 14.7754C14.5521 13.0778 14.4214 11.2373 13.4976 10.0029C13.9299 9.71048 14.5225 9.53604 15.3346 9.53604C18.3451 9.53604 18.3391 11.9328 18.3342 13.8514C18.3341 13.9272 18.3339 14.0024 18.3339 14.0766C18.3339 14.0766 17.8497 14.8333 15.6073 14.8333C15.2271 14.8333 14.8818 14.8116 14.5704 14.7754ZM6.63828 10.1007C5.73833 11.345 5.55945 13.1552 5.52419 14.8148C5.34434 14.8267 5.15017 14.8333 4.94066 14.8333C2.69826 14.8333 1.66874 14.0766 1.66874 14.0766C1.66874 14.0024 1.66855 13.9272 1.66836 13.8514C1.66354 11.9328 1.65753 9.53604 4.66797 9.53604C5.56694 9.53604 6.19692 9.74977 6.63828 10.1007ZM6.57657 7.26577C6.57657 8.24097 5.72206 9.03153 4.66797 9.03153C3.61387 9.03153 2.75936 8.24097 2.75936 7.26577C2.75936 6.29056 3.61387 5.5 4.66797 5.5C5.72206 5.5 6.57657 6.29056 6.57657 7.26577ZM15.3346 9.03153C16.3887 9.03153 17.2432 8.24097 17.2432 7.26577C17.2432 6.29056 16.3887 5.5 15.3346 5.5C14.2805 5.5 13.426 6.29056 13.426 7.26577C13.426 8.24097 14.2805 9.03153 15.3346 9.03153Z" fill="#9AA3B0"/>\n' +
              '</svg>\n'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['axiosGetClientsForFolder', 'axiosGetMoreCounterparties', 'axiosGetCountriesForFolders', 'axiosGetFolderPayments', 'axiosGetFileById', 'axiosSetAccessUsersForFolders']),
    ...mapMutations(['createEmitGetActivityFolders', 'showLoad', 'hideLoad', 'clearSelectedFoldersPage', 'moveToLocalFildesForFoldersSection', 'deleteFileWithFoldersSection']),
    async loadMorePayment(item) {
      this.loadMorePaymentBool = true
      let res = await this.axiosGetMoreCounterparties(this.nextLinkPaymentLeftList)
      console.log('loadMorePayment', res)
      res.results.forEach(item=> {
        item['link'] = ''
        item['folders'] = []
        item['hide_svg'] = true
        item['type_load'] = 'payment'
        item['type_object'] = 'payment'
        item['contains_files'] = true
        item['contains_folders'] = true
        item['is_show'] = false
        item['is_load'] = false
        item['created_in'] = false
        item['name'] = item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'
      })
      item.folders = item.folders.concat(res.results)
      this.nextLinkPaymentLeftList = res.next
      this.loadMorePaymentBool = false
    },
    async loadNextCountries(item) {
      this.loadingNextPageCountries = true
      this.portsCurrentPage++
      let res = await this.axiosGetCountriesForFolders({params: {page: this.portsCurrentPage, ordering: 'country', limit: 20, is_archive: this.$route.params.countries_is_archive}})
      res.ports.forEach(item=> {
        item['folders'] = []
        item['hide_svg'] = true
        item['type_load'] = 'country'
        item['type_object'] = 'country'
        item['contains_files'] = false
        item['contains_folders'] = false
        item['is_show'] = false
        item['is_load'] = false
        item['created_in'] = false
      })
      item.folders = item.folders.concat(res.ports)
      this.portsCurrentPage = res.current_page
      this.portsTotalPage = res.total_page
      this.loadingNextPageCountries = false
    },
    async loadMoreClient(item) {
      this.loadMoreClientBool = true
      let res = await this.axiosGetMoreCounterparties(this.nextLinkClientLeftList)
      console.log('counterparties', res)
      res.results.forEach(item=> {
        item['link'] = ''
        item['folders'] = []
        item['contains_folders'] = false
        item['contains_files'] = false
        item['hide_svg'] = true
        item['type_load'] = 'counterparties'
        item['type_object'] = 'counterparties'
        item['is_show'] = false
        item['is_load'] = false
        item['created_in'] = false
        item['name'] = item.company_name
      })
      item.folders = item.folders.concat(res.results)
      this.nextLinkClientLeftList = res.next
      this.loadMoreClientBool = false
    },
    deleteItem(id, index) {
      this.list[index].folders = this.list[index].folders.filter(a=> a.id !== id)
    },
    createFolder() {
      console.log('createFolder')
      if(Number.isInteger(this.activeFolder) == true) {
        // const dfs = (tree) => {
        //   // console.log(tree)
        //   if (Array.isArray(tree) !== true && tree.id == this.activeFolder) {
        //     return tree;
        //   }
        //   const children = tree.folders;
        //   children.forEach(dfs);
        // };
        // let item = this.getListActiveSectionTo.folders.find(item=> {
        //   if(dfs(item)) {
        //     return dfs(item)
        //   }
        // })


        let itemFind = null
        let findFolder = (array) => {
          if(Array.isArray(array) == true) {
            let item = array.find(el=> el.id == this.activeFolder)
            if(item) {
              itemFind = item
              return null;
            } else {
              return array.forEach(el=> findFolder(el.folders))
            }
          }
        }
        findFolder(this.getListActiveSectionTo)
        let item = itemFind
        item.is_show = true
        item.created_in = true
      } else {
        let item = this.list.find(a=> a.name == this.activeFolder)
        item.is_show = true
        this.openFolder(item, true)
        let formCreated = {
            folders: [],
            name: '',
            id: -1,
            is_load: false,
            is_show: false,
            file_type: 'FOLDER',
            is_important: false,
            is_archive: false,
            created_in: false,
            shared_with: [],
          }
          item.folders = ([formCreated]).concat(item.folders)
          item.created_in = false

      }

    },
    setActiveFolder(name) {
      console.log(name)
      this.activeFolder = name
      // if(name === '')
    },
    getIdsSection(name) {
      switch (name) {
        case 'My Files':
          return this.listSectionsIds.find(a=> a.name == 'myfiles').id
      }
      return  null
    },
    async move() {
      this.showLoad()
      let form = null
      if(!this.unarchiveView) {
        form = {
          move_from: this.getFromSection,
          ids: this.foldersForMoveTo.map(a=> {return a.id}),
          move_to: (!this.itemClick || !this.itemClick.type_load) ? (typeof(this.activeFolder) !== 'string' ? this.activeFolder.id : this.getIdsSection(this.activeFolder)) : null,
          move_to_object_id: (this.itemClick && this.itemClick.type_load) ? (this.activeFolder ? this.activeFolder.id : null) : null,
          move_from_object_id: this.getFromObjectId ? this.getFromObjectId : null,
          physically_move_file: this.getFromSection == 'myfiles' || this.getToSection == 'myfiles'
        }
        if(this.getToSection) {
          form['destination'] = this.getToSection

        }
      } else {
        if(this.getFromSection == 'myfiles' && this.getToSection == 'myfiles') {
          form = {
            ids: this.foldersForMoveTo.map(a=> {return a.id}),
            is_archive: false,
            physically_move_file: true,
            unarchive_destination: (typeof(this.activeFolder) !== 'string' ? this.activeFolder.id : this.getIdsSection(this.activeFolder)),

          }
        } else {
          form = {
            move_from: this.getFromSection,
            destination: this.getToSection,
            ids: this.foldersForMoveTo.map(a=> {return a.id}),
            move_to: (!this.itemClick || !this.itemClick.type_load) ? (typeof(this.activeFolder) !== 'string' ? this.activeFolder.id : this.getIdsSection(this.activeFolder)) : null,
            move_to_object_id: (this.itemClick && this.itemClick.type_load) ? (this.activeFolder ? this.activeFolder.id : null) : null,
            move_from_object_id: this.getFromObjectId ? this.getFromObjectId : null,
            is_archive_for_move: false,
            physically_move_file: this.getFromSection == 'myfiles' || this.getToSection == 'myfiles'

          }
        }


      }

      console.log(form)
      let res = await this.axiosSetAccessUsersForFolders({data: form})
      if(res == false) {
        this.hideLoad()
        return null
      }
      this.foldersForMoveTo.forEach(item=> {
        this.deleteFileWithFoldersSection(item.id)
      })
      if(!this.unarchiveView) {
        this.moveToLocalFildesForFoldersSection({items: JSON.parse(JSON.stringify(this.foldersForMoveTo.filter(a=> a.file_type == 'FOLDER'))), idMoveTo: Number.isInteger(this.activeFolder) ? this.activeFolder : this.getIdsSection(this.activeFolder), inSection: Number.isInteger(this.activeFolder) == false, pushInSection: true})
      }
      this.$emit('moved')
      this.clearSelectedFoldersPage()
      this.$emit('close')
      this.createEmitGetActivityFolders()

      this.hideLoad()
    },
    clickZone(id, type) {
      this.activeFolder = id
      this.activeTypeObject = type
      console.log(id)
    },
    getSvg(name) {
      let item = this.svgs.find(a=> a.name == name)
      if(item) {
        return item.svg
      }
      return ''
    },
    async openFolder(item, notChangeShow) {
      if(!item.is_show || notChangeShow == true) {
        if(notChangeShow !== true) {
          item.is_show = !item.is_show
        }
        if(item.name === 'My Files' && item.is_show && !item.folders.length) {
          let res = await this.axiosGetFileById(this.listSectionsIds.find(a=> a.name == "myfiles").id)
          console.log(res)
          res = res.filter(a=> !a.is_archive)
          res.forEach(item=> {
            item['folders'] = []
            item['is_load'] = false
            item['is_show'] = false
            item['created_in'] = false
          })
          item.folders = item.folders.concat(res)
        }
        if(item.name === 'Payments' && item.is_show && !item.folders.length) {
          let form = {
            type: 'payment',
            is_archived: this.$route.path.indexOf('payments/archive') >= 0 || this.$route.path.indexOf('a-payment') >= 0,
            limit: 25,
            ordering: this.sort,
            search: this.search,
            stopCancel: true,
            move_to: true,
          }
          let data = await this.axiosGetFolderPayments(form)
          let payments = data.results
          this.nextLinkPaymentLeftList = data.next
          payments.forEach(item=> {
            item['link'] = {name: 'Folders - Payments Folders', params: {detailData: JSON.stringify({id: item.id, date: item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'})}}
            item['folders'] = []
            item['hide_svg'] = true
            item['type_load'] = 'payment'
            item['is_show'] = false
            item['is_load'] = false
            item['created_in'] = false
            item['name'] = item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'
          })
          console.log(payments)
          item.folders = item.folders.concat(payments)

          // this.setFoldersList(JSON.parse(JSON.stringify(payments)))
        }
        if(item.name === 'Tasks' && item.is_show && !item.folders.length) {
          let array = [
            {
              link: '',
              folders: [],
              hide_svg: true,
              type_load: 'pre_tasks',
              type_object: 'pre_tasks',
              is_show: false,
              is_load: false,
              create_in: false,
              name: 'Manually Tasks'
            },
            {
              link: '',
              folders: [],
              hide_svg: true,
              type_load: 'pre_tasks',
              type_object: 'pre_tasks',
              is_show: false,
              is_load: false,
              create_in: false,
              name: 'Vessel Search'
            },
            {
              link: '',
              folders: [],
              hide_svg: true,
              type_load: 'pre_tasks',
              type_object: 'pre_tasks',
              is_show: false,
              is_load: false,
              create_in: false,
              name: 'Cargo Search'
            }
          ]
          item.folders = item.folders.concat(array)

        }
        if(item.name == 'Vessels' && item.is_show && !item.folders.length) {
          item.folders =  [
            {
              contains_folders: false,
              contains_files: false,
              link: '',
              folders: [],
              hide_svg: true,
              type_load: 'actual_vessel',
              type_object: 'actual_vessel',
              is_show: false,
              is_load: false,
              create_in: false,
              name: 'Actual Vessel'
            },
            {
              contains_folders: false,
              contains_files: false,
              link: '',
              folders: [],
              hide_svg: true,
              type_load: 'redelivered_vessel',
              type_object: 'redelivered_vessel',
              is_show: false,
              is_load: false,
              create_in: false,
              name: 'Redelivered Vessel'
            },
          ]
        }
        if(item.name === 'Ports' && item.is_show && !item.folders.length) {
          this.portsCurrentPage = 1
          let res = await this.axiosGetCountriesForFolders({params: {page: this.portsCurrentPage, ordering: 'country', limit: 20, is_archive: this.$route.params.countries_is_archive}})
          console.log('axiosGetCountriesForFolders', res)
          res.ports.forEach(item=> {
            item['folders'] = []
            item['hide_svg'] = true
            item['type_load'] = 'country'
            item['type_object'] = 'country'
            item['contains_folders'] = false
            item['contains_files'] = false
            item['is_show'] = false
            item['is_load'] = false
            item['created_in'] = false
          })
          item.folders=res.ports
          this.portsCurrentPage = res.current_page
          this.portsTotalPage = res.total_page
        }
        if(item.name === 'Counterparties' && item.is_show && !item.folders.length) {
          let form = {
            ordering: 'company_name',
            is_archived: this.$route.params.is_archive_counterparties,
            limit: 20
          }
          let res = await this.axiosGetClientsForFolder({params: form})
          console.log('counterparties', res)
          res.results.forEach(item=> {
            item['link'] = ''
            item['folders'] = []
            item['hide_svg'] = true
            item['contains_folders'] = false
            item['contains_files'] = false
            item['type_load'] = 'counterparties'
            item['type_object'] = 'counterparties'
            item['is_show'] = false
            item['is_load'] = false
            item['created_in'] = false
            item['name'] = item.company_name
          })
          item.folders = res.results
          this.nextLinkClientLeftList = res.next
        }
      } else {
        item.is_show = false
      }

    },
  },
  mounted() {
    this.list = JSON.parse(JSON.stringify(this.listSections))
  }
}
</script>

<style scoped lang="scss">
  .active {
    svg {
      path {
        fill: var(--color-blue) !important
      }
    }
    /deep/ {
      svg {
        path {
          fill: var(--color-blue) !important
        }
      }
    }
    p {
      color: var(--color-blue) !important;
    }
  }
</style>