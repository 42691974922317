<template>
  <div class="wrap" :class="{'archive-view': isArchive}">
    <div class="name-block" v-if="item">
      <p :contenteditable="(item.is_basic && getPermissionsByType('create_or_edit_base_checklist') || !item.is_basic && (item.creator && item.creator.id === getLoginData.data.user.id)) && !isArchive" class="fz14 fw400 text-white-always" @focusin="checkNameCheckListForEdit" @blur="setNewInputValueName" :key="item.name">{{item.name}}</p>
      <span class="dop-info">
          <svg v-tooltip.top="'Basic checklist'" class="img-profile" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="item.is_basic">
        <circle cx="9" cy="9" r="9" fill="#6B8DAA"/>
        <path d="M9.86719 14H5.90918V4.13574H9.84668C10.7217 4.13574 11.4235 4.36133 11.9521 4.8125C12.4808 5.25911 12.7451 5.85384 12.7451 6.59668C12.7451 7.12077 12.5742 7.59017 12.2324 8.00488C11.8952 8.41504 11.4805 8.66113 10.9883 8.74316V8.79785C11.6628 8.87533 12.2051 9.13509 12.6152 9.57715C13.0254 10.0146 13.2305 10.5592 13.2305 11.2109C13.2305 12.0814 12.932 12.765 12.335 13.2617C11.738 13.7539 10.9154 14 9.86719 14ZM7.44043 5.38672V8.33301H9.15625C9.81706 8.33301 10.3252 8.20085 10.6807 7.93652C11.0361 7.6722 11.2139 7.29622 11.2139 6.80859C11.2139 6.35286 11.0635 6.00195 10.7627 5.75586C10.4619 5.50977 10.0335 5.38672 9.47754 5.38672H7.44043ZM7.44043 12.749H9.55273C10.2409 12.749 10.765 12.61 11.125 12.332C11.485 12.054 11.665 11.6484 11.665 11.1152C11.665 10.0397 10.9313 9.50195 9.46387 9.50195H7.44043V12.749Z" fill="white"/>
          </svg>
          <img class="img-profile" v-else-if="item && item.creator && item.creator.avatar" :src="getUrlForAvatar(item.creator.avatar)" alt="">
        <svg v-else class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="80" cy="80" r="80" fill="white"/>
          <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
        </svg>
      </span>
    </div>
    <div class="main-content">
      <draggable
          class="drag"
          group="a"
          v-model="localValue"
          handle=".handle"
          @change="onUpdateDrag"
          @start="drag = true"
          @end="drag = false"
          v-bind="dragOptions"
          :move="checkMove"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <template v-for="ch in localValue">
            <div class="checkbox-item position-relative" v-if="(!getPermissionsByType('create_or_edit_base_checklist') && (ch.is_basic || (!ch.is_basic && ch.creator && ch.creator.id === getLoginData.data.user.id))) || getPermissionsByType('create_or_edit_base_checklist')" :key="ch.index + 'asd' + (ch.id ? ch.id : '23s')">
              <button class="handle" v-if="!isArchive && ((item.is_basic && ((getPermissionsByType('create_or_edit_base_checklist')) || (!ch.is_basic && ch.creator && ch.creator.id === getLoginData.data.user.id))) || (!item.is_basic && ch.creator && ch.creator.id === getLoginData.data.user.id))">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.432 7.46797C10.432 8.18594 9.85 8.76797 9.13203 8.76797C8.41406 8.76797 7.83203 8.18594 7.83203 7.46797C7.83203 6.75 8.41406 6.16797 9.13203 6.16797C9.85 6.16797 10.432 6.75 10.432 7.46797Z" fill="#9AA3B0"/>
                  <path d="M16.268 7.46797C16.268 8.18594 15.6859 8.76797 14.968 8.76797C14.25 8.76797 13.668 8.18594 13.668 7.46797C13.668 6.75 14.25 6.16797 14.968 6.16797C15.6859 6.16797 16.268 6.75 16.268 7.46797Z" fill="#9AA3B0"/>
                  <path d="M10.4321 12.0969C10.4321 12.8148 9.85005 13.3969 9.13208 13.3969C8.41411 13.3969 7.83208 12.8148 7.83208 12.0969C7.83208 11.3789 8.41411 10.7969 9.13208 10.7969C9.85005 10.7969 10.4321 11.3789 10.4321 12.0969Z" fill="#9AA3B0"/>
                  <path d="M16.268 12.0969C16.268 12.8148 15.686 13.3969 14.968 13.3969C14.2501 13.3969 13.668 12.8148 13.668 12.0969C13.668 11.3789 14.2501 10.7969 14.968 10.7969C15.686 10.7969 16.268 11.3789 16.268 12.0969Z" fill="#9AA3B0"/>
                  <path d="M10.4321 16.7297C10.4321 17.4477 9.85005 18.0297 9.13208 18.0297C8.41411 18.0297 7.83208 17.4477 7.83208 16.7297C7.83208 16.0117 8.41411 15.4297 9.13208 15.4297C9.85005 15.4297 10.4321 16.0117 10.4321 16.7297Z" fill="#9AA3B0"/>
                  <path d="M16.268 16.7297C16.268 17.4477 15.686 18.0297 14.968 18.0297C14.2501 18.0297 13.668 17.4477 13.668 16.7297C13.668 16.0117 14.2501 15.4297 14.968 15.4297C15.686 15.4297 16.268 16.0117 16.268 16.7297Z" fill="#9AA3B0"/>
                </svg>
              </button>
              <div class="description" v-on:click="!isArchive ? focusText : null">
                <p class="description-text fz14 text-dark" :key="ch.description" :contenteditable="!isArchive && (item.is_basic ? (getPermissionsByType('create_or_edit_base_checklist') || (!ch.is_basic && (ch.creator && ch.creator.id === getLoginData.data.user.id))) : (ch.creator && ch.creator.id === getLoginData.data.user.id))" @blur="(e)=>{setNewInputValueDescription(e, ch.index)}" @focus="focusDescription">{{ch.description}}</p>
                <span class="img-desc" v-if="item.is_basic">
                 <img class="img-profile ml6" v-if="!ch.is_basic && ch.creator && ch.creator.avatar" :src="getUrlForAvatar(ch.creator.avatar)" alt="">
              <svg v-else-if="!ch.is_basic && ch.creator" class="img-profile ml6" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="80" cy="80" r="80" fill="white"/>
                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
              </svg>
              </span>

              </div>
              <div class="delete cursor-pointer" v-on:click="deleteCheckbox(ch.index)" v-if="!isArchive && ((ch.creator && ch.creator.id === getLoginData.data.user.id) || (item.is_basic && (getPermissionsByType('create_or_edit_base_checklist'))))">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#9AA3B0"/>
                  <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#9AA3B0"/>
                </svg>
              </div>
            </div>
          </template>
        </transition-group>

      </draggable>
    </div>
    <div class="bottom-panel" v-if="!isArchive">
      <button class="blue-button-text fz13 fw500" v-on:click="createNewCheckbox" :disabled="disabledCreate || ((!item.is_basic && !(item.creator && item.creator.id === getLoginData.data.user.id)))" :class="{'disabled-create': disabledCreate || ((!item.is_basic && !(item.creator && item.creator.id === getLoginData.data.user.id)))}">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="white"/>
        </svg>
        Add new check list item
      </button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {mapGetters, mapMutations} from "vuex";
export default {
  components: {draggable},
  name: "checklist-checkboxes-list",
  props: {
    value: [Object, Array],
    item: Object,
    isArchive: Boolean,
    disabledCreate: Boolean
  },
  computed: {
    ...mapGetters(['getPermissionsByType', 'getLoginData']),
    dragOptions() {
      return {
        drag: false,
        animation: 100,
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    value: function() {
      this.localValue = Array.isArray(this.value) === true ? this.value : []
    },
  },
  data() {
    return {
      localValue: [],
      emptyForm: {
        description: 'Description',
        index: 0,
        is_basic: this.item.is_basic ? this.getPermissionsByType('create_or_edit_base_checklist') : this.item.is_basic
      },
      drag: false,
      inputText: 'Name',
      focus: false,
    }
  },
  methods: {
    ...mapMutations(['sortNameList']),
    // checkMove(e) {
    //   let ch = e.draggedContext.element.is_basic
    //   return (ch.is_basic && this.getPermissionsByType('create_or_edit_base_checklist')) || (!ch.is_basic && ch.creator && ch.creator.id === this.getLoginData.data.user.id)
    // },
    checkNameCheckListForEdit(e) {
      if(e && e.target.innerText === 'Name') {
        setTimeout(()=> {
          e.target.innerText = ''
        }, 50)
      }
    },
    focusDescription(e) {
      if(e.target.innerText === 'Description') {
        e.target.innerText = ''
      }
    },
    deleteCheckbox(index) {
      // let item = this.localValue.find(a=> a.index === index)
      // if(item) {
      //   item['is_deleted'] = true;
      //   console.log('deleted', item , this.localValue)
      //   // this.$emit('input', this.localValue)
      // }
      this.$emit('delete', index)
    },
    checkMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      relatedElement['fixed'] = !((this.item.is_basic && ((this.getPermissionsByType('create_or_edit_base_checklist')) || (!relatedElement.is_basic && relatedElement.creator && relatedElement.creator.id === this.getLoginData.data.user.id))) || (!this.item.is_basic && relatedElement.creator && relatedElement.creator.id === this.getLoginData.data.user.id))
      draggedElement['fixed'] = !((this.item.is_basic && ((this.getPermissionsByType('create_or_edit_base_checklist')) || (!draggedElement.is_basic && draggedElement.creator && draggedElement.creator.id === this.getLoginData.data.user.id))) || (!this.item.is_basic && draggedElement.creator && draggedElement.creator.id === this.getLoginData.data.user.id))

      // relatedElement['fixed'] = !((this.item.is_basic && ((relatedElement.is_basic && this.getPermissionsByType('create_or_edit_base_checklist')) || (!relatedElement.is_basic && relatedElement.creator && relatedElement.creator.id === this.getLoginData.data.user.id))) || (!this.item.is_basic && relatedElement.creator && relatedElement.creator.id === this.getLoginData.data.user.id))
      // draggedElement['fixed'] = !((this.item.is_basic && ((draggedElement.is_basic && this.getPermissionsByType('create_or_edit_base_checklist')) || (!draggedElement.is_basic && draggedElement.creator && draggedElement.creator.id === this.getLoginData.data.user.id))) || (!this.item.is_basic && draggedElement.creator && draggedElement.creator.id === this.getLoginData.data.user.id))

      return (
          (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    getUrlForAvatar(link) {
      if(link.indexOf(this.getURL()) >= 0) {
        return link;
      }
      return this.getURL() + link;
    },
    getURL() {
      return process.env.VUE_APP_URL_SIMPLE
    },
    setNewInputValueName(e) {
      if(e.target.innerText.length !== 0 && e.target.innerText !== this.item.name) {
        e.target.innerText = e.target.innerText.slice(0, 255)
        this.item.name = e.target.innerText
      } else{
        e.target.innerText = this.item.name
      }
      setTimeout(()=> {
        this.sortNameList()
      }, 100)
    },
    focusText(el) {
      if(el.target.classList.contains('description')) {
        el.target.children[0].focus()
        // console.log(el.selectNodeContents(nativeElement.firstChild))
        // console.log(el.target.children[0].innerText.length)
        // setTimeout(()=> {
        // const range = document.createRange();
        // range.selectNodeContents(el.target.children[0]);
        // selection.addRange(range);
        //   el.target.children[0].selectionStart = el.target.children[0].innerText.length-5;
        //   el.target.children[0].selectionEnd = el.target.children[0].innerText.length-3;
        // }, 200)

      }
    },
    setNewInputValueDescription(e, index) {
      let item = this.localValue.find(a=> a.index === index)
      if(item) {
        if(e.target.innerText.length !== 0 && e.target.innerText !== item.description) {
          e.target.innerText = e.target.innerText.slice(0, 255)
          item.description = e.target.innerText
        } else{
          e.target.innerText = item.description
        }
      }
      this.$emit('input', this.localValue)
    },
    onUpdateDrag() {
      this.localValue.forEach((item, index)=> {
        item.index = index
      })
      this.$emit('input', this.localValue)
    },
    createNewCheckbox() {
      let form = JSON.parse(JSON.stringify(this.emptyForm))
      form.index = this.localValue.length
      // form.is_basic = this.item.is_basic ? this.getPermissionsByType('create_or_edit_base_checklist') : false
      this.localValue.push(form)
      this.$emit('create')
      setTimeout(()=> {
        this.$emit('input', this.localValue)
      }, 50)
    },

    addElementInList(event) {
      this.indexForAdd = event;
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
  .wrap {
    width: 100%;
    box-sizing: border-box;
  }
  .name-block {
    border-radius: 8px 8px 0 0;
    background-color: var(--color-blue-dark);
    padding: 7px 12px;
    min-height: 32px;
    border: 1px solid var(--color-blue-dark);
    border-bottom: 1px solid var(--color-border);
    box-sizing: border-box;
    p {
      width: max-content;
    }
    &>* {
      display: inline;
    }
    .dop-info {
      width: 18px;
      height: 18px;
      position: relative;
      &>* {
        position: absolute;
        top: 1.5px;
        left: 0;
      }
      margin-left: 8px;
    }
  }
  .checkbox-item {
    background-color: var(--color-modal);
    &:nth-child(even) {
      background-color: var(--color-bgc-page);
    }
    .delete {
      display: none;
    }
    .text-dark {
      color: #4B4B4B !important;
    }
    &:hover {
      background-color: var(--color-gray-dark);
      .delete {
        display: flex;
      }
      .text-dark {
        color: #2C2C2C !important;
      }
    }
    display: flex;
    align-items: center;
    width: 100%;
    border-left: 1px solid var(--color-blue-dark);
    border-right: 1px solid var(--color-blue-dark);
    box-sizing: border-box;
    .description {
      width: 100%;
      box-sizing: border-box;
      padding: 7px 42px 7px 42px;
      min-height: 32px;
      p {
        display: inline;
      }
    }
    .handle {
      top: 4px;
      position: absolute;
      left: 12px;
    }
    .delete {
      width: 24px;
      height: 24px;
      top: 4px;
      position: absolute;
      right: 8px;
      z-index: 10;
    }
    & {
      border-bottom: 1px solid var(--color-border);
    }

  }
  .bottom-panel {
    display: flex;
    align-items: center;
    background-color: var(--color-bgc-page-white);
    border-radius: 0 0 8px 8px;
    border: 1px solid var(--color-blue-dark);
    border-top: none;
    padding: 4px 17px;
    height: 32px;
    box-sizing: border-box;
  }
  .img-profile {
    border-radius: 50px;
    width: 18px;
    height: 18px;
  }
  .img-desc {
    width: 18px;
    height: 18px;
    display: inline;
    position: relative;
    &>* {
      //position: absolute;
      //top: 0;
      //left: 0;
    }
    .img-profile {
      position: absolute;
    }
  }
  .archive-view {
    .checkbox-item {
      &:last-child {
        border-bottom: 1px solid var(--color-blue-dark);
        border-radius: 0 0 8px 8px;
      }
      .description {
        padding: 7px 12px;
      }
    }
  }
  .dart-mode {
    .blue-button-text {
      svg {
        path {
          fill: var(--color-blue)
        }
      }
      color: var(--color-blue);
    }
    .checkbox-item {
      & {
        border-bottom: 1px solid #4D546D;
      }
      .text-dark {
        color: #BDCBD6 !important;
      }
      &:hover {
        .text-dark {
          color: #FFFFFF !important;
        }
      }
    }
    .name-block {
      border-bottom: 1px solid #4D546D;
    }
  }
  .disabled-create {
    svg {
      path {
        fill: var(--color-gray) !important;
      }
    }
    color: var(--color-gray) !important;
  }
</style>