<template>
  <div>
    <folders-list-file-and-folder></folders-list-file-and-folder>
  </div>
</template>
<script>
import {mapMutations} from "vuex";
import FoldersListFileAndFolder from "@/components/folders/folders-list-file-and-folder.vue";
export default {
  components: {FoldersListFileAndFolder},
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setNameSectionFolders']),
  },
  mounted() {
    this.setNameSectionFolders('Counterparties')
  }
}
</script>
<style scoped lang="scss">

</style>