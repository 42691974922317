<template>
  <div class="page-gray" v-if="permissions.length && getPermissionsByType('show_archive_of_trial_balance')">
    <bread
      :allowOptions="false"
      :links="[
        {name: 'Trial Balance', link: '/accounts/trial-balance'}, 
        {name: `Archive Trial Balance`, link: ''}
      ]"
    />
    
    <trial-balance-archive-top @search="onSearch"/>
    <trial-balance-archive-list ref="trialList" :list="getTrialsArchiveSearch" @select="onSelect" @on-unarchive="onUnarchive" v-if="trialsArchive"/>
    <trial-balance-archive-bottom @on-close="$refs.trialList.uncheckAll()" :selectedListId="selectedListId" @on-unarchive="onUnarchive" />

    <trial-balance-modal-unarchive :list="selectedListId" :account="editAccount" v-if="isModalArchiveOpen" @close="closeModal" @submit="unarchiveAccount"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import trialBalanceArchiveTop from '@/components/accounts/trials/trialBalanceArchiveTop'
import trialBalanceArchiveList from '@/components/accounts/trials/trialBalanceArchiveList'
import trialBalanceArchiveBottom from '@/components/accounts/trials/trialBalanceArchiveBottom'
import trialBalanceModalUnarchive from '@/components/accounts/trials/trialBalanceModalUnarchive'
import bread from '@/components/reuse/bread'
export default {
  components: {
    trialBalanceArchiveTop,
    trialBalanceArchiveList,
    trialBalanceArchiveBottom,
    trialBalanceModalUnarchive,
    bread
  },
  data() {
    return {
      selectedListId: [],
      editAccount: null,
      isModalArchiveOpen: false,
      searchText: ''
    }
  },
  watch: {
    permissions: function(val) {
      if(val.length && !this.getPermissionsByType('show_archive_of_trial_balance'))
        this.$router.replace({name: 'employees'})
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.confirmation.permissionsForUser,
      trialsArchive: state => state.trialBalance.trialsArchive,
    }),
    ...mapGetters(['getPermissionsByType']),
    getTrialsArchiveSearch() {
      return this.trialsArchive.filter(el=>{
        if(this.searchText){
          return el.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0 || 
          el.id.toString().toLowerCase().indexOf(this.searchText.toLowerCase()) >=0 ||
          el.description.toLowerCase().indexOf(this.searchText.toLowerCase()) >=0
        }else
          return true
      })
    }
  },
  methods: {
    ...mapActions(['axiosGetTrialsArchive', 'axiosTrialAccountToArchive', 'axiosGetTrials']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    onUnarchive(account) {
      if(account)
        this.editAccount = account
      else
        this.editAccount = null
      this.isModalArchiveOpen = true
    },
    async unarchiveAccount(ids) {
      await this.axiosTrialAccountToArchive({ids: ids, bool: false})
      if(this.editAccount)
        this.addTip(this.editAccount.name + ' is unarchived')
      else if(ids.length == 1)
        this.addTip(ids.length + ' account is unarchived')
      else
        this.addTip(ids.length + ' accounts are unarchived')

      this.axiosGetTrials()
      this.axiosGetTrialsArchive()
      this.$refs.trialList.uncheckAll()

      this.isModalArchiveOpen = false
    },
    onSelect(list) {
      this.selectedListId = list.slice()
    },
    closeModal() {
      this.isModalArchiveOpen = false
    },
    onSearch(text) {
      this.searchText = text
    }
  },
  async mounted() {
    this.showLoad()
    if(!this.trialsArchive.length)
      await this.axiosGetTrialsArchive()
    this.hideLoad()
  },
}
</script>

<style>
 .page-gray{
   background-color: var(--color-bgc-page);
   height: calc(100vh - 56px);
    overflow-y: auto;
 }
</style>