<template>
  <div class="">
    <div class="border border-dark-gray rounded-lg shadow lay-time-table-children-connect">
      <lay-active-table-head :pdfHideBlock="pdfHideBlock" />
      <lay-active-table-info class="lay-time-table-children-connect" :pdfHideBlock="pdfHideBlock" :lay="lay" :timeAllowed="timeAllowed"/>
      
      <lay-active-table-total :pdfHideBlock="pdfHideBlock" :rows="lay.lay_times ? lay.lay_times : []"/>
    </div>
    <div class="p-t-4"></div>
    <div class=" border border-dark-gray rounded-lg shadow">
      <lay-active-total class="" :lay="lay" :timeAllowed="timeAllowed" :rows="lay.lay_times ? lay.lay_times : []"/>
    </div>
  </div>
</template>

<script>
import layActiveTableHead from '@/components/lay/edit/layTableHead'
import layActiveTableInfo from '@/components/lay/layActiveTableInfo'
import layActiveTableTotal from '@/components/lay/edit/layTableTotal'
import layActiveTotal from '@/components/lay/edit/layTotal'

export default {
  props: {
    lay: Object,
    timeAllowed: Number,
    pdfHideBlock: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    layActiveTableHead,
    layActiveTableInfo,
    layActiveTableTotal,
    layActiveTotal
  },
}
</script>

<style lang="scss">
.lay_table__grid{
  display: grid;
  grid-template-columns: 11.64% repeat(3, 5.38%) repeat(3, 3.59%) 29.15% repeat(5, 6.46%);
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid var(--color-gray-dark);
  &>div{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.25rem;
    word-break: break-word;
    border-right: 1px solid var(--color-gray-dark);
    &:last-of-type{
      border-right-width: 0;
    }
  }
  .col-3-5{
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .col-5-8{
    grid-column-start: 5;
    grid-column-end: 8;
  }
  .mx-input{
    border: none !important;
  }
}
</style>