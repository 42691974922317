<template>
  <div class="invoice_table border border-dark rounded-lg"
    :class="{
      'with_trials': getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'
    }"
  >
    <div class="border-b border-r flex items-center justify-between p-x-2 p-y-1">
      Plus Demurrage
      <svg class="cursor-pointer" @click="$emit('on-delete')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#4B4B4B"/></svg>
    </div>
    <div class="border-b border-r">
      <input class="p-x-2 p-y-2" type="text" placeholder="Write a comment" v-model.lazy="detail.additional_info.comment">
    </div>
    <div class="border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'">
      <get-trials trialKey="Plus Demurrage"/>
    </div>
    <div class="border-b border-r relative">
      <counterparties-drop
        :list="getLay"
        :activeItem="activeLay"
        @select="onSelect"
        placeholder="Choose Port"
        ref="drop"
      ></counterparties-drop>
      <button class="absolute right-2 centered-y fz0 bg-white cursor-pointer z-100" v-show="detail.lay_report" @click="onClearLay"><svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.054 3.925a.613.613 0 0 1 0-.873.628.628 0 0 1 .882 0l5.01 4.921a.613.613 0 0 1 0 .873.628.628 0 0 1-.882 0l-5.01-4.92Z" fill="#2C2C2C"/><path d="M8.064 3.154a.628.628 0 0 1 .882 0 .613.613 0 0 1 0 .873l-5.01 4.92a.628.628 0 0 1-.882 0 .613.613 0 0 1 0-.872l5.01-4.921Z" fill="#2C2C2C"/></svg></button>
    </div>
    <div class="border-b border-r"></div>
    <div class="border-b border-r">
      <button 
        class="w-1/2 h-full border-t-0 border-b-0 border-l-0 border-r border-r-gray-light text-dark"
        @click="changeAmountType('INCOME', 'total_amount')" 
        :class="{'bg-gray-dark': detail.amount_type == 'INCOME'}"
      >Rev.</button>
      <button 
        class="w-1/2 h-full text-dark"
        @click="changeAmountType('EXPENSE', 'total_amount')" 
        :class="{'bg-gray-dark': detail.amount_type == 'EXPENSE'}"
      >Exp.</button>
    </div>
    <div class="border-b rounded-tr-lg">
      <p
        class="p-x-2 p-y-2 fw500 text-align-right box-border"
        :class="{'wrap-brackets': detail.total_amount < 0}"
      >{{Math.abs(detail.total_amount) | formatNumberTwo}}</p>
    </div>

    <div class="border-b border-r p-x-2 p-y-2 text-dark">Less Address Commission</div>
    <div class="border-b border-r">
      <input class="p-x-2 p-y-2" type="text" placeholder="Write a comment" v-model.lazy="detail.additional_info.comment_address">
    </div>
    <div class="border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'">
      <get-trials trialKey="Plus Demurrage:Less Address Commission"/>
    </div>
    <div class="border-b border-r relative">
      <input-format-number
          :fractionDigits="$route.path.indexOf('soa') >= 0 ? 3 : 2"

          v-model="detail.percentage"
        inputClass="p-l-2 p-r-6 p-y-2"
        placeholder="Enter tax percentage"
        rightPlaceholder="%"
      ></input-format-number>
    </div>
    <div class="border-b border-r"></div>
    <div class="border-b border-r">
      <button 
        class="w-1/2 h-full border-t-0 border-b-0 border-l-0 border-r border-r-gray-light text-dark"
        @click="changeAmountType('INCOME', 'percentage_amount')" 
        :class="{'bg-gray-dark': detail.additional_info.address_amount_type == 'INCOME'}"
      >Rev.</button>
      <button 
        class="w-1/2 h-full text-dark"
        @click="changeAmountType('EXPENSE', 'percentage_amount')" 
        :class="{'bg-gray-dark': detail.additional_info.address_amount_type == 'EXPENSE'}"
      >Exp.</button>
    </div>
    <div class="border-b">
      <p
        class="p-x-2 p-y-2 fw500 text-align-right box-border"
        :class="{'wrap-brackets': detail.percentage_amount < 0}"
      >{{Math.abs(detail.percentage_amount) | formatNumberTwo}}</p>
    </div>

    <div class="border-b border-r p-x-2 p-y-2 text-dark">Less Brokerage Commission</div>
    <div class="border-b border-r">
      <input class="p-x-2 p-y-2" type="text" placeholder="Write a comment" v-model.lazy="detail.additional_info.comment_brokerage">
    </div>
    <div class="border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'">
      <get-trials trialKey="Plus Demurrage:Less Brokerage Commission"/>
    </div>
    <div class="border-b border-r relative">
      <input-format-number
          :fractionDigits="$route.path.indexOf('soa') >= 0 ? 3 : 2"
          v-model="detail.additional_percentage"
        inputClass="p-l-2 p-r-6 p-y-2"
        placeholder="Enter tax percentage"
        rightPlaceholder="%"
      ></input-format-number>
    </div>
    <div class="border-b border-r"></div>
    <div class="border-b border-r">
      <button 
        class="w-1/2 h-full border-t-0 border-b-0 border-l-0 border-r border-r-gray-light text-dark"
        @click="changeAmountType('INCOME', 'additional_percentage_amount')" 
        :class="{'bg-gray-dark': detail.additional_info.brokerage_amount_type == 'INCOME'}"
      >Rev.</button>
      <button 
        class="w-1/2 h-full text-dark"
        @click="changeAmountType('EXPENSE', 'additional_percentage_amount')" 
        :class="{'bg-gray-dark': detail.additional_info.brokerage_amount_type == 'EXPENSE'}"
      >Exp.</button>
    </div>
    <div class="border-b ">
      <p
        class="p-x-2 p-y-2 fw500 text-align-right box-border"
        :class="{'wrap-brackets': detail.additional_percentage_amount < 0}"
      >{{Math.abs(detail.additional_percentage_amount) | formatNumberTwo}}</p>
    </div>

    <div class="border-r p-x-2 p-y-2 text-dark">Less Tax on Brokerage Commission</div>
    <div class="border-r">
      <input class="p-x-2 p-y-2" type="text" placeholder="Write a comment" v-model.lazy="detail.additional_info.comment_tax">
    </div>
    <div class="border-r" v-if="getPermissionsByType('show_accounts_in_all_operations') && $route.meta.invoiceType == 'Voyage Statement of Account'">
      <get-trials trialKey="Plus Demurrage:Less Tax on Brokerage Commission"/>
    </div>
    <div class="border-r relative">
      <input-format-number
          :fractionDigits="$route.path.indexOf('soa') >= 0 ? 3 : 2"
          v-model="detail.third_percentage"
        inputClass="p-l-2 p-r-6 p-y-2"
        placeholder="Enter tax percentage"
        rightPlaceholder="%"
      ></input-format-number>
    </div>
    <div class="border-r"></div>
    <div class="border-r">
      <button 
        class="w-1/2 h-full border-t-0 border-b-0 border-l-0 border-r border-r-gray-light text-dark"
        @click="changeAmountType('INCOME', 'third_percentage_amount')" 
        :class="{'bg-gray-dark': detail.additional_info.tax_amount_type == 'INCOME'}"
      >Rev.</button>
      <button 
        class="w-1/2 h-full text-dark"
        @click="changeAmountType('EXPENSE', 'third_percentage_amount')" 
        :class="{'bg-gray-dark': detail.additional_info.tax_amount_type == 'EXPENSE'}"
      >Exp.</button>
    </div>
    <div class="rounded-br-lg">
      <p
        class="p-x-2 p-y-2 fw500 text-align-right box-border"
        :class="{'wrap-brackets': detail.third_percentage_amount < 0}"
      >{{Math.abs(detail.third_percentage_amount) | formatNumberTwo}}</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import getTrials from '@/components/vessels/owners/edit/getTrials'
import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import moment from 'moment'

export default {
  props: {
    detail: Object,
    cpDate: String
  },
  components: {
    getTrials,
    counterpartiesDrop,
    inputFormatNumber
  },
  data() {
    return {
      activeLay: null
    }
  },
  watch: {
    'detail.lay_report': {
      deep: true,
      handler() {this.getTotal()}
    },
    'detail.total_amount': function() {this.getPercentageAmount(), this.getAddPercentageAmount(), this.checkAmountType()},
    'detail.percentage': function() {this.getPercentageAmount()},
    'detail.additional_percentage': function() {this.getAddPercentageAmount()},
    'detail.third_percentage': function() {this.getThirdPercentageAmount()},

    'detail.percentage_amount': function() {this.checkAddressAmountType()},
    'detail.additional_percentage_amount': function() {this.checkBrokerageAmountType(), this.getThirdPercentageAmount()},
    'detail.third_percentage_amount': function() {this.checkTaxAmountType()},
  },
  computed: {
    ...mapState({
      lays: state => state.lay.lays,
    }),
    ...mapGetters(['getPermissionsByType']),
    getLay() {
      if(!this.lays) return []
      return this.lays.filter(el=>{
        // return this.cpDate == moment(el.cp_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
        // console.log('this.getTotalEquals(el)',  this.getTotalEquals(el), this.getTotalEquals(el))
        return this.getTotalEquals(el) >= this.getTimeAllowed(el) && this.cpDate == moment(el.cp_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
      }).map(el => {
        let name = el.lay_ports && el.lay_ports.length ? '' : 'Without ports'
        el.lay_ports.forEach((port, index) => {
          let load = port.loading_port
          let disch = port.discharging_port
          if(load) name += load.name + ' (Load.)'
          if(load && disch) name += ' - '
          if(disch) name += disch.name + ' (Disch.)'
          if(index != el.lay_ports.length - 1 && (load || disch)) name += ', '
        })

        return {
          id: el.id,
          name: name,
          demurrage: el.demurrage
        }
      })
    },
  },
  methods: {
    ...mapActions(['axiosGetLays']),
    onSelect(item) {
      if(!item || this.detail.lay_report?.id == item.id)
        this.detail.lay_report = null
      else
        this.detail.lay_report = item
    },
    onClearLay() {
      this.detail.lay_report = null
      this.$refs.drop.render()
    },
    setInput(result, field) {
      result = result ? result : 0
      this.detail[field] = result
    },
    getTotal() {
      let result = 0
      if(this.detail.lay_report) result = this.detail.lay_report.demurrage
      this.setInput(result, 'total_amount')
    },
    getPercentageAmount() {
      let result = 0
      if(+this.detail.percentage) {
        result = this.detail.total_amount * (this.detail.percentage/100)
      }

      this.setInput(result*-1, 'percentage_amount')
    },
    getAddPercentageAmount() {
      let result = 0
      if(+this.detail.additional_percentage) {
        result = this.detail.total_amount * (this.detail.additional_percentage/100)
      }

      this.setInput(result*-1, 'additional_percentage_amount')
    },
    getThirdPercentageAmount() {
      let result = 0
      if(+this.detail.third_percentage) {
        result = this.detail.additional_percentage_amount * (this.detail.third_percentage/100)
      }

      this.setInput(result, 'third_percentage_amount')
    },

    checkAmountType() {
      this.detail.amount_type = this.detail.total_amount < 0 ? 'EXPENSE' : 'INCOME'
    },
    checkAddressAmountType() {
      this.detail.additional_info.address_amount_type = this.detail.percentage_amount <= 0 ? 'EXPENSE' : 'INCOME'
    },
    checkBrokerageAmountType() {
      this.detail.additional_info.brokerage_amount_type = this.detail.additional_percentage_amount <= 0 ? 'EXPENSE' : 'INCOME'
    },
    checkTaxAmountType() {
      this.detail.additional_info.tax_amount_type = this.detail.third_percentage_amount <= 0 ? 'EXPENSE' : 'INCOME'
    },

    changeAmountType(type, field) {
      this.detail[field] = Math.abs(this.detail[field]) * (type == 'INCOME' ? 1 : -1)
    },

    getTotalEquals(lay) {
      let summ = 0
      lay.lay_times.forEach(row => {
        summ += row.equal_days
      })
      return summ
    },
    getTimeAllowed(lay) {
      let a_time = 0

      lay.lay_ports.forEach(el => {
        // if(el.discharging_port){
        //   a_time += el.discharging_quantity / el.discharging_rate
        // }
        // if(el.loading_port){
        //   a_time += el.loading_quantity / el.loading_rate
        // }
        if(lay.additional_info.reversible_between && lay.additional_info.reversible_between === 'Loading ports') {
          if(el.loading_port){
            a_time += el.loading_quantity / el.loading_rate
          }
        } else if(lay.additional_info.reversible_between && lay.additional_info.reversible_between === 'Discharging ports') {
          if(el.discharging_port){
            a_time += el.discharging_quantity / el.discharging_rate
          }
        } else {
          if(el.discharging_port){
            a_time += el.discharging_quantity / el.discharging_rate
          }
          if(el.loading_port){
            a_time += el.loading_quantity / el.loading_rate
          }
        }
      });

      let demTime = Math.abs((Math.round(a_time*10000000)/10000000) - (Math.round(this.getTotalEquals(lay) * 10000000)/10000000))
      let dem_rate = a_time > this.getTotalEquals(lay) ? Math.round((lay.demurrage_rate/2)*10000000)/10000000 :  Math.round(lay.demurrage_rate*10000000)/10000000
      let result = demTime * dem_rate
      result = Math.round(result*10000000)/10000000
      lay.demurrage = result

      return a_time
    },
  },
  async beforeMount() {
    await this.$nextTick()

    if(!this.lays?.length)
      await this.axiosGetLays(this.$route.params.id)
  },
  mounted() {
    if(!this.detail.lay_report){
      return false
    }else{
      let lay = JSON.parse(JSON.stringify(this.detail.lay_report))
      this.detail.lay_report = null
  
      let name = lay.lay_ports && lay.lay_ports.length ? '' : 'Without ports'
      lay.lay_ports.forEach((port, index) => {
        let load = port.loading_port
        let disch = port.discharging_port
        if(load) name += load.name + ' (Load.)'
        if(load && disch) name += ' - '
        if(disch) name += disch.name + ' (Disch.)'
        if(index != lay.lay_ports.length - 1) name += ', '
      })
      this.getTimeAllowed(lay)
      this.activeLay = {
        id: lay.id,
        name: name,
        demurrage: lay.demurrage
      }
      this.getTotal()
    }
  }
}
</script>

<style>

</style>