<template>
  <div class="flex bg-white rounded-lg p-x-6 p-y-3 shadow">
    <div class="m-r-8">
      <p class="fz13 text-gray-text">Time allowed {{lay.additional_info.reversible_between ? (lay.additional_info.reversible_between === 'Loading ports' ? 'for loading ports' : (lay.additional_info.reversible_between === 'Discharging ports' ? 'for discharging ports' : '')) : ''}}, days</p>
      <p class="fz14 m-t-1">{{timeAllowed | formatNumber5}}</p>
    </div>

    <div class="m-r-8">
      <p class="fz13 text-gray-text">Time used, days</p>
      <p class="fz14 m-t-1">{{getTotalEquals | formatNumber5}}</p>
    </div>

    <div class="m-r-8">
      <p class="fz13 text-gray-text">{{getTotalEquals >= timeAllowed ? 'Demurrage' : 'Dispatch'}} Time, days</p>
      <p class="fz14 m-t-1">{{getDemTime | formatNumber5}}</p>
    </div>

    <div class="m-r-8">
      <p class="fz13 text-gray-text">{{getTotalEquals >= timeAllowed ? 'Demurrage' : 'Dispatch'}} Rate, {{lay.currency}}</p>
      <p class="fz14 m-t-1">{{getTotalEquals >= timeAllowed ? lay.demurrage_rate : lay.dispatch_rate | formatNumberTwo}}</p>
    </div>

    <div class="m-l-auto text-align-right">
<!--      {{getTotalEquals}} {{timeAllowed}}-->
      <p class="fz13 fw500">{{getTotalEquals >= timeAllowed ? 'Demurrage' : 'Dispatch'}}, {{lay.currency}}</p>
      <p class="fz18 fw500 m-t-1">{{getDemurrage() | formatNumberTwo}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: Array,
    lay: Object,
    timeAllowed: Number
  },
  computed: {
    getTotalEquals() {
      let summ = 0
      this.rows.forEach(row => {
        summ += Math.round(row.equal_days*10000000)/10000000
      })
      return Math.round(summ*10000000)/10000000
    },
    getDemTime() {
      return Math.abs((Math.round(this.timeAllowed*10000000)/10000000) - (Math.round(this.getTotalEquals * 10000000)/10000000))
    }
  },
  methods: {
    getDemurrage() {
      let dem_rate = this.timeAllowed > this.getTotalEquals ? this.lay.dispatch_rate : this.lay.demurrage_rate
      let result =  Math.round(this.getDemTime*10000000)/10000000 * Math.round(dem_rate*10000000)/10000000
      result = Math.round(result*10000000)/10000000
      console.log('demurage', this.getDemTime, dem_rate)

      this.lay.demurrage = result
      if(this.getTotalEquals < this.timeAllowed) {
        result = Math.trunc(result) + Math.floor(((result % 1)*100))/100
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1611px) {
  .lay_table__grid {
    grid-template-columns: 160px repeat(3, 6.38%) repeat(3, 3.59%) calc(37.79% - 160px) repeat(5, 6.46%);
  }
}

</style>