<template>
  <div class="flex h-max flex-col border-t border-dark-gray  active:child-inputs-inset-shadow"
    :class="{'opacity-50 pointer-events-none': vessel.empty || !isAllow}"
  >
    <div class="flex">
      <div class="w-1/2  border-dark-gray">
        <div class="p-x-1.5 p-y-1 border-b border-dark-gray bg-gray-dark uppercase fz13 fw500">Delivery</div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500" v-tooltip="'Hire'">Hire</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.hire ? `${vessel.hire}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.hire"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Ball.bonus'">Ball.bonus</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.ballast_bonus ? `${vessel.ballast_bonus}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.ballast_bonus"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'AddCom'">AddCom</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.additional_commission ? `${vessel.additional_commission}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.additional_commission"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">%</span>
          </div>
        </div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500" v-tooltip="'Date'">Date</div>
          <div 
            class="w-7/12 bg-white relative calc_vessels__date" 
            v-tooltip="vessel.date ? getFormatDate : null" 
            @keyup.enter="$refs.date.closePopup(), $refs.date.$refs.input.blur()"
          >
            <date-picker
              class="w-full"
              input-class="p-y-1.5 p-l-1.5 p-r-1.5 border-0 bg-transparent rounded-none fz13"
              format="DD.MM.YYYY"
              value-type="YYYY-MM-DD"
              type="date"
              ref="date"
              v-model="vessel.date"
            ></date-picker>
            <span class="absolute right-0 centered-y fz0 calc_vessels__date_icon">
              <svg v-if="!vessel.date" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.842 3.034c.378 0 .684.305.684.68v1.15h4.948v-1.15c0-.375.306-.68.684-.68.378 0 .684.305.684.68v1.15h.368A2.78 2.78 0 0 1 19 7.635v8.628a2.78 2.78 0 0 1-2.79 2.771H7.79A2.78 2.78 0 0 1 5 16.263V7.635a2.78 2.78 0 0 1 2.79-2.77h.368V3.713c0-.375.306-.68.684-.68Zm-.684 3.19h-.369c-.784 0-1.42.632-1.42 1.411v2.262h11.263V7.635c0-.78-.637-1.411-1.422-1.411h-.368v1.15c0 .375-.306.68-.684.68a.682.682 0 0 1-.684-.68v-1.15H9.526v1.15c0 .375-.306.68-.684.68a.682.682 0 0 1-.684-.68v-1.15Zm9.474 5.032H6.368v5.007c0 .78.637 1.412 1.421 1.412h8.421c.785 0 1.422-.632 1.422-1.412v-5.007Z" fill="#9AA3B0"/></svg>
              <svg @click="vessel.date = null" v-else class="cursor-pointer" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.514 7.579a.75.75 0 0 1 0-1.067.767.767 0 0 1 1.078 0l9.894 9.785a.75.75 0 0 1 0 1.068.767.767 0 0 1-1.078 0L6.514 7.579Z" fill="#9AA3B0"/><path d="M16.409 6.635a.767.767 0 0 1 1.077 0 .75.75 0 0 1 0 1.068l-9.894 9.786a.767.767 0 0 1-1.078 0 .75.75 0 0 1 0-1.068l9.895-9.786Z" fill="#9AA3B0"/></svg>
            </span>
          </div>
        </div>
        <div class="flex">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500" v-tooltip="'Time'">Time</div>
          <div class="w-7/12 bg-white" v-tooltip="vessel.time ? `${vessel.time}` : null">
            <input
              type="text"
              v-model.lazy="vessel.time"
              ref="time"
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              @keyup.enter="$event.target.blur()"
            >
          </div>
        </div>

        <div class="p-x-1.5 p-y-1 border-b border-t border-dark-gray bg-gray-dark uppercase fz13 fw500">Bunker price</div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'VLSFO price'">VLSFO</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.vlsfo_price ? `${vessel.vlsfo_price}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.vlsfo_price"
              @change="onChangePrice('vlsfo')"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'HSFO price'">HSFO</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.hsfo_price ? `${vessel.hsfo_price}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.hsfo_price"
              @change="onChangePrice('hsfo')"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
        <div class="flex h48  border-b border-dark-gray">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap h48" v-tooltip="'LSMGO price'">LSMGO</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.lsmgo_price ? `${vessel.lsmgo_price}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.lsmgo_price"
              @change="onChangePrice('lsmgo')"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
      </div>
      <div class="w-1/2 flex flex-col h-max border-l border-dark-gray">
        <div class="p-x-1.5 p-y-1 border-b border-dark-gray bg-gray-dark uppercase fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap flex-shrink-0">Other Expenses</div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Expenses'">Expenses</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.expenses ? `${vessel.expenses}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.expenses"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Mist. cost'">Mist. cost</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.mins_cost ? `${vessel.mins_cost}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.mins_cost"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
        <div class="flex border-b border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Insurance'">Insurance</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.insurance ? `${vessel.insurance}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.insurance"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
        <div class="flex  border-gray-light">
          <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Other'">Other</div>
          <div class="w-7/12 bg-white relative" v-tooltip="vessel.other ? `${vessel.other}` : null">
            <input
              class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
              type="number" 
              v-model.number.lazy="vessel.other"
              @keypress="onlyNumber($event)"
              @keyup.enter="$event.target.blur()"
            >
            <span class="absolute right-2 centered-y fz13 text-gray-text">$</span>
          </div>
        </div>
        <div class="flex flex-column bg-white rounded-br-lg h-full h-max">
          <div class="p-x-1.5 p-y-1 border-b border-t border-dark-gray bg-gray-dark uppercase fz13 fw500 relative d-flex align-items-center">Tonnage
            <calc-scrubber class="scrubber" v-if="true" v-model="vessel.scrubber"/>
          </div>
          <div class="flex border-b border-gray-light">
            <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'DWCC'">DWCC</div>
            <div class="w-7/12 bg-white relative" v-tooltip="vessel.dwcc ? `${vessel.dwcc}` : null">
              <input
                  class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
                  type="number"
                  v-model.number.lazy="vessel.dwcc"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">mt</span>
            </div>
          </div>
          <div class="flex border-b border-gray-light">
            <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Draft'">SSW Draft</div>
            <div class="w-7/12 bg-white relative" v-tooltip="vessel.draft ? `${vessel.draft}` : null">
              <input
                  class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
                  type="number"
                  v-model.number.lazy="vessel.draft"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">m</span>
            </div>
          </div>
          <div class="flex border-b border-gray-light">
            <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'Grain'">Grain cap.</div>
            <div class="w-7/12 bg-white relative" v-tooltip="vessel.grain ? `${vessel.grain}` : null">
              <input
                  class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-none fz13"
                  type="number"
                  v-model.number.lazy="vessel.grain"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
              >
              <span class="absolute right-2 centered-y fz13 text-gray-text">m³</span>
            </div>
          </div>
          <div class="flex border-b border-dark-gray h48">
            <div class="w-5/12 p-x-1.5 p-y-1.5 border-r border-gray-light text-gray-text fz13 fw500 overflow-ellipsis overflow-hidden whitespace-nowrap" v-tooltip="'TPC'">TPC</div>
            <div class="w-7/12 bg-white relative rounded-br-lg" v-tooltip="vessel.tpc ? `${vessel.tpc}` : null">
              <input
                  class="p-y-1.5 p-l-1.5 p-r-6 border-0 bg-transparent rounded-l-none rounded-t-none rounded-br-lg fz13"
                  type="number"
                  v-model.number.lazy="vessel.tpc"
                  @keypress="onlyNumber($event)"
                  @keyup.enter="$event.target.blur()"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex w-full h-full bg-white rounded-bl-lg rounded-br-lg" v-if="false">
      
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import Inputmask from "inputmask"
import calcScrubber from '@/components/calc/calcScrubber'
import { mapActions } from 'vuex'
export default {
  props: {
    isAllow: Boolean,
    vessel: {
      type: Object,
      default: () => {
        return {
          empty: true
        }
      }
    }
  },
  components: {
    DatePicker,
    calcScrubber
  },
  data() {
    return {
    }
  },
  watch: {
    'vessel.scrubber': function (){
      this.$emit('changeScrubber')
    }
  },
  computed: {
    getFormatDate() {
      return this.vessel.date ? moment(this.vessel.date).format('DD.MM.YYYY') : null
    }
  },
  methods: {
    ...mapActions(['onlyNumber']),
    onChangePrice(key) {
      console.log('onChangePrice ', key);
      this.vessel['is_changed_'+key] = true
    }
  },
  mounted() {
    if(this.$refs.time){
      if (!this.$refs.time.inputmask)
        Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.time)
    }
    if(this.$refs.steaming_hour){
      if (!this.$refs.steaming_hour.inputmask)
        Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.steaming_hour)
    }
  }
}
</script>

<style lang="scss">
  .calc_vessels__date{
    .mx-icon-clear,
    .mx-icon-calendar{
      display: none !important;
    }
  }
</style>
<style lang="scss" scoped>
.scrubber {
  padding: 0 !important;
  position: absolute;
  right: -5px;
  top: 4px;
  /deep/ {
    .custom-control {
      padding-left: 35px;
    }
    .fz13 {
      text-transform: capitalize !important;
    }
  }
}
.flex {
  height: 24px;
}
.w-5\/12 {
  padding: 4px 6px !important;
  font-weight: 700 !important;
  color: var(--color-new-dark-gray) !important
}
.uppercase.fz13.fw500 {
  font-weight: 700 !important;
}
.p-x-1\.5.p-y-1 {
  padding: 4px 6px !important;
}
.p-y-1\.5.p-l-1\.5 {
  padding: 4px 6px !important;
  height: 24px;
}
/deep/ {
  .p-y-1\.5.p-l-1\.5 {
    padding: 4px 6px !important;
    height: 24px;
  }
}
.h-max {
  height: max-content !important;
}
.h48 {
  background-color: var(--color-bgc-page-white);
  height: 49px;
  //overflow: hidden;
  input {
    border-radius: 0 !important;
  }
  .w-7\/12 {
    height: 24px;
  }
  .w-5\/12 {
    height: 41px;
    background-color: var(--color-bgc-page) !important
  }
}
</style>