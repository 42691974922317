import axios from 'axios'
import QueryString from "querystring"

export default {
  actions: {
    async axiosFindPorts(ctx, query) {
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/port',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: {
          is_archive: false,
          limit: 50,
          search: query
        },
      })
      .then(response => {
        return response.data.results
      });
    },

    async axiosGetPortsByCountry(ctx, ifNext) {
      let filters = ctx.state.filters
      let params = {
        limit: filters.limit,
        is_archive: filters.is_archive,
        search: filters.search,
        max_draft_min: filters.draft.min,
        max_draft_max: filters.draft.max,
        max_loa_min: filters.loa.min,
        max_loa_max: filters.loa.max,
        max_beam_min: filters.beam.min,
        max_beam_max: filters.beam.max,
        water_density: filters.selectWater.map(el=>el.id),
      }
      filters.selectBunkering.forEach(el => {
        params[el.id] = true
      })

      let url = process.env.VUE_APP_URL+'/port/country/?ordering=+country'
      if(ifNext && ctx.state.nextRequest)
        url += '&page='+ctx.state.nextRequest

      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: params,
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      })
      .then(response => {
        if(response.data.current_page < response.data.total_page)
          ctx.state.nextRequest = 1+parseInt(response.data.current_page)
        else
          ctx.state.nextRequest = null

        if(ifNext){
          ctx.state.portsCountry = {...ctx.state.portsCountry, ...response.data.ports}
        }else{
          ctx.state.portsCountry = response.data.ports
        }
      })
    },
    async axiosGetPorts(ctx, ports) {
      if(!ports || !ports.length) return false
      let url = process.env.VUE_APP_URL+'/port/?ordering=+name&limit=999'
      url += '&port_ids='+ports.toString()

      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
      })
      .then(response => {
        ctx.state.ports = response.data.results
      })
    },
    async axiosGetPortsList(ctx, data) {
      if(!data.ports || !data.ports.length) return false
      let url = process.env.VUE_APP_URL+'/port/?ordering=+name&limit=10'
      url += '&port_ids='+data.ports.toString()

      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: {
          search: data.search
        }
      })
          .then(response => {
            ctx.state.nextLintForPortsList = response.data.next
            ctx.state.ports = response.data.results
          })
    },
    async axiosShowNextPortsList(ctx, data) {
      return await axios.get(data.next, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
      }).then(res=> {
        if(data.is_archive) {
          ctx.state.portsArchive = ctx.state.portsArchive.concat(res.data.results)

        } else {
          ctx.state.ports = ctx.state.ports.concat(res.data.results)

        }
        ctx.state.nextLintForPortsList = res.data.next
      })
    },
    async axiosGetPortsArchive(ctx, ports) {
      if(!ports || !ports.length) return false
      let url = process.env.VUE_APP_URL+'/port/?ordering=+name&limit=999'
      url += '&port_ids='+ports.toString()

      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
      })
      .then(response => {
        ctx.state.portsArchive = response.data.results
      })
    },
    async axiosGetPortsListArchive(ctx, data) {
      if(!data.ports || !data.ports.length) return false
      let url = process.env.VUE_APP_URL+'/port/?ordering=+name&limit=10'
      url += '&port_ids='+data.ports.toString()

      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: {
          search: data.search
        }
      })
          .then(response => {
            ctx.state.nextLintForPortsList = response.data.next
            ctx.state.portsArchive = response.data.results
          })
    },
    async axiosGetPortsArchiveByCountry(ctx, ifNext) {
      let filters = ctx.state.filtersArchive
      let params = {
        limit: filters.limit,
        is_archive: filters.is_archive,
        search: filters.search,
        max_draft_min: filters.draft.min,
        max_draft_max: filters.draft.max,
        max_loa_min: filters.loa.min,
        max_loa_max: filters.loa.max,
        max_beam_min: filters.beam.min,
        max_beam_max: filters.beam.max,
        water_density: filters.selectWater.map(el=>el.id),
      }
      filters.selectBunkering.forEach(el => {
        params[el.id] = true
      })

      let url = process.env.VUE_APP_URL+'/port/country/?ordering=+country'
      if(ifNext && ctx.state.nextRequest)
        url += '&page='+ctx.state.nextRequest

      await axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        params: params,
        paramsSerializer: params => {
          return QueryString.stringify(params)
        }
      })
      .then(response => {
        if(response.data.current_page < response.data.total_page)
          ctx.state.nextRequest = 1+parseInt(response.data.current_page)
        else
          ctx.state.nextRequest = null

        if(ifNext){
          ctx.state.portsCountryArchive = {...ctx.state.portsCountryArchive, ...response.data.ports}
        }else{
          ctx.state.portsCountryArchive = response.data.ports
        }
      })
    },

    async axiosAddPda(ctx, pda) {
      return await axios({
        method: pda.id ? 'put' : 'post',
        url: process.env.VUE_APP_URL+'/port/pda/' + (pda.id ? pda.id : ''),
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: pda
      })
      .then(response => {
        return response.data.id
      })
    },

    async axiosCreateDwtPort(ctx, data) {
      return await axios.post(process.env.VUE_APP_URL+'/port/dwt', {
        "min": data.min,
        "max": data.max,
        "price": data.price
      }, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
      }).then(res=> {
        return res.data;
      })
    },
    async axiosChangeDwtPort(ctx, data) {
      return await axios.patch(process.env.VUE_APP_URL+'/port/dwt/' + data.id, {
        "min": data.min,
        "max": data.max,
        "price": data.price
      }, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
      }).then(res=> {
        return res.data;
      })
    },
    async axiosDeleteDwt(ctx, id) {
      return await axios.delete(process.env.VUE_APP_URL+'/port/dwt/' + id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
      })
    },

    async axiosAddPort(ctx, portObj) {
      let filesId = await ctx.dispatch('axiosAddPortFiles', portObj.files)
      portObj.files = [...filesId, ...portObj.oldFiles]

      let pda = []
      for (let i = 0; i < portObj.pda.length; i++) {
        pda.push(await ctx.dispatch('axiosAddPda', portObj.pda[i]))
      }
      portObj.pda = pda

      return await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/port/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: portObj
      })
      .then(response => {
        // console.log('axiosAddPort: ', response)
        return response.data.id
      })
      .catch(() => {
        // console.log('axiosAddPort error: ', error)
      })
    },
    async axiosEditPort(ctx, portObj) {
      let filesId = await ctx.dispatch('axiosAddPortFiles', portObj.files)
      portObj.files = [...filesId, ...portObj.oldFiles]

      let pda = []
      for (let i = 0; i < portObj.pda.length; i++) {
        pda.push(await ctx.dispatch('axiosAddPda', portObj.pda[i]))
      }
      portObj.pda = pda

      await axios({
        method: 'put',
        url: process.env.VUE_APP_URL+'/port/'+portObj.id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: portObj
      })
      .then(() => {
        // console.log('axiosAddPort: ', response)
      })
      .catch(() => {
        // console.error('axiosAddPort error: ', error)
      })
    },
    async axiosPortArchive(ctx, portObj) {
      await axios({
        method: 'post',
        url: process.env.VUE_APP_URL+'/port/archive/',
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        },
        data: {
          ids: portObj.ids,
          is_archived: portObj.is_archived
        }
      })
      .then(() => {
        // console.log('axiosPortArchive: ', response)
      })
      .catch(() => {
        // console.error('axiosPortArchive error: ', error)
      })
    },

    async axiosGetPortById(ctx, id) {
      ctx.commit('setOpenPort', null)
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/port/'+ id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      })
      .then(response => {
        ctx.commit('setOpenPort', response.data)
        return response.data
      })
      .catch(error => {
        console.error('axiosGetPortById error: ', error)
      })
    },
    async axiosGetPortByIdWithoutDeleteData(ctx, id) {
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/port/'+ id,
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      })
          .then(response => {
            ctx.commit('setOpenPort', response.data)
            return response.data
          })
          .catch(error => {
            console.error('axiosGetPortById error: ', error)
          })
    },

    async axiosAddPortFiles(ctx, files) {
      let filesId = []
      if(files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          let formData = new FormData();
          formData.append("file", file);
          formData.append("name", file.name);
          formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
          if(file.parent_id !== undefined && file.parent_id !== null) {
            formData.append("parent_id", file.parent_id);
            formData.append("parent_file", file.parent_id);
          }
          filesId.push(await ctx.dispatch('axiosAddFile', formData))
        }
      }
      return filesId


    },
    async axiosEditPortFiles(ctx, portObj) {
      await axios.patch(process.env.VUE_APP_URL+'/port/'+ portObj.id, {
        files: portObj.files
      },{
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      }).then(() => {
        // console.log('axiosEditPort', response);
      }).catch(() => {
        // console.error('axiosEditPort', error);
      })
    },

    async axiosGetPortActivityById(ctx, id) {
      return await axios.get(process.env.VUE_APP_URL+'/activity/?limit=9999&model=port&instance_id='+ id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        return response.data.results
      }).catch(error => {
        console.error('axiosGetPortById', error);
      })
    },
  },
  mutations: {
    setOpenPort(state, port) {
      state.openPort = port
    }
  },
  state: {
    nextLintForPortsList: null,
    ports: null,
    portsArchive: null,
    portsCountry: null,
    portsCountryArchive: null,
    nextRequest: null,
    openPort: null,

    filters: {
      is_archive: false,
      limit: 20,
      search: '',
      selectBunkering: [],
      selectWater: [],
      draft: {
        min: '',
        max: ''
      },
      loa: {
        min: '',
        max: ''
      },
      beam: {
        min: '',
        max: ''
      }
    },
    filtersArchive: {
      is_archive: true,
      limit: 20,
      search: '',
      selectBunkering: [],
      selectWater: [],
      draft: {
        min: '',
        max: ''
      },
      loa: {
        min: '',
        max: ''
      },
      beam: {
        min: '',
        max: ''
      }
    },

    waterDensityList: [
      {
        name: '—',
        id: 'null'
      },
      {
        name: 'Sea water (SW 1.000)',
        id: 'sea_water'
      },
      {
        name: 'Brakish water (BW 0.9878)',
        id: 'brakish_water'
      },
      {
        name: 'Fresh water (FW 0.9756)',
        id: 'fresh_water'
      },
      {
        name: 'Tropical Fresh water (TFW 0.9717)',
        id: 'tropical_water'
      }
    ],
    bunkeringList: [
      {
        id: 'bunkerning_not_available',
        name: 'Not Available'
      },
      {
        id: 'bunkerning_at_berth',
        name: 'At Berth'
      },
      {
        id: 'bunkerning_at_road',
        name: 'At Road'
      },
      {
        id: 'bunkerning_in_transit',
        name: 'In Transit'
      }
    ],
  },
}