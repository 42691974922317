<template>
  <div class="wrap">
    <div class="d-flex align-items-center pb4 border-bottom border-color-normal mt22">
      <svg :class="{'rotate-180': showBlock}" v-on:click="showBlock = !showBlock" class="mr8 cursor-pointer" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.27557 4.39844C9.54284 4.39844 9.67669 4.72158 9.4877 4.91057L6.21196 8.18631C6.0948 8.30346 5.90485 8.30346 5.78769 8.18631L2.51197 4.91057C2.32298 4.72158 2.45683 4.39844 2.7241 4.39844L9.27557 4.39844Z" fill="#2c2c2c"/>
      </svg>
      <p class="fz18 fw500 text-dark">{{title}}</p>
    </div>
    <folders-upload-buttons @create-folder="$emit('create-folder')" @upload-file="(e)=>{$emit('upload-file', e)}" @upload-folder="(e)=> {$emit('upload-folder', e)}" :hideFolders="hideFolders" class="mt8" v-if="showBlock"></folders-upload-buttons>
    <folders-list-file-and-folder :getPermissionSelect="getPermissionSelect" :hideFolders="hideFolders" :localMode="true" :localFiles="files" :localFolders="folders" v-if="showBlock"></folders-list-file-and-folder>
  </div>
</template>
<script>
import FoldersUploadButtons from "@/components/folders/folders-lists/folders-upload-buttons.vue";
import FoldersListFileAndFolder from "@/components/folders/folders-list-file-and-folder.vue";
import {mapMutations} from "vuex";

export default {
  components: {FoldersListFileAndFolder, FoldersUploadButtons},
  props: {
    getPermissionSelect: {
      type: Boolean,
      default: true
    },
    hideFolders: {
      type: Boolean,
      default: false,
    },
    title: String,
    files: Array,
    folders: Array,
  },
  data() {
    return {
      showBlock: true
    }
  },
  methods: {
    ...mapMutations(['setNameSectionFolders']),
  },
  mounted() {
    this.setNameSectionFolders('Vessels')
  }
}
</script>
<style scoped lang="scss">
  .wrap {

  }
</style>