<template>
  <label class="switcher" :class="{'slider-active': value}">
    <input type="checkbox" :id="id" :value="value" @change="onChange" :disabled="disabled">
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      require: true
    },
    value: [Boolean, String],
    disabled: Boolean
  },
  data() {
    return {
    }
  },
  methods: {
    onChange() {
      if(this.value)
        this.$emit('input', false)
      else
        this.$emit('input', true)
    }
  },
}
</script>

<style lang="scss">
.switcher {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
  &.slider-active {
    input + .slider {
      background-color: #2196F3;
    }

    input + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input + .slider:before {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }
  }
  &.switcher_dark-bg{
    &.slider-active {
      input + .slider {
        background-color: var(--color-blue-dark);
      }
      input + .slider {
        box-shadow: 0 0 1px var(--color-blue-dark);
      }
    }
  }
  &.switcher_owners-row{
    position: absolute;
    right: calc(100% + 0.75rem);
    top: calc(50% - 8px);
    &.slider-active {
      input + .slider {
        background-color: var(--color-blue-dark);
      }
      input + .slider {
        box-shadow: 0 0 1px var(--color-blue-dark);
      }
    }
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}

</style>