export default {
    actions: {
    },
    mutations: {
        updateSelected1(state, data) {
            state.selected1MenuCheckbox.departments = data.departments;
            state.selected1MenuCheckbox.employees = data.employees
        },
        updateSelected2(state, data) {
            state.selected1MenuCheckbox.departments = data.departments;
            state.selected1MenuCheckbox.employees = data.employees
        },
        changeActivePermissions(state, data) {
            // state.activePermissions.splice(0, state.activePermissions.length);
            // for(let i=0; i<data.length; i++) {
            //     state.activePermissions.push(data[i]);
            // }
            state.activePermissions = data;
        },
        setClickButton(state, data) {
            state.clickButton = data;
        }
    },
    state: {
        clickButton: null,
        activePermissions: null,
        selected1MenuCheckbox: {
            departments: [],
            employees: [],
        },
        selected2MenuCheckbox: {
            departments: [],
            employees: [],
        },
    },
    getters: {
        getSelected1MenuCheckbox(state) {
            return state.selected1MenuCheckbox;
        },
        getSelected2MenuCheckbox(state) {
            return state.selected2MenuCheckbox;
        },
        getActivePermissions(state) {
            return state.activePermissions;
        },
        getClickButton(state) {
            return state.clickButton;
        }
    },
}
