<template>
  <div class="h-full overflow-y-auto bg-gray-lightest">
    <div class="flex h-full">
      <div class="lay_sidebar h-full bg-white flex-shrink-0 border-r border-gray-light overflow-auto">
        <div class="p-x-6 p-y-2 bg-gray-lightest border-b border-gray-light text-gray-text fz12 fw500 uppercase">Archive</div>

        <div 
          v-for="lay in lays" 
          :key="lay.id"
          @click="setActiveLayArchive(lay)"
          class="lay_list relative p-x-6 p-y-4 border-b border-gray-dark cursor-pointer"
          :class="{
            'active bg-gray-dark': activeLay && activeLay.id == lay.id
          }"
        >
          <span class="fz14 fw500" v-if="lay.lay_ports[0].loading_port">{{lay.lay_ports[0].loading_port.name}}, {{lay.lay_ports[0].loading_port.country}}</span>
          <span class="fz14 fw500" v-if="lay.lay_ports[0].loading_port && lay.lay_ports[0].discharging_port"> - <br></span>
          <span class="fz14 fw500" v-if="lay.lay_ports[0].discharging_port">{{lay.lay_ports[0].discharging_port.name}}, {{lay.lay_ports[0].discharging_port.country}}</span>
          <div class="fz12 text-gray-text">
            Archived {{lay.updated_at | formatDate}} at {{lay.updated_at | formatTime}}
          </div>
          <div v-if="lay.lay_ports.length > 1" class="lay_list__dot inline-flex w-5 h-5 rounded-full border border-dark fz11 box-border">
            <span class="m-auto">+{{lay.lay_ports.length - 1}}</span>
          </div>
        </div>
      </div>
      <div class="w-full h-full overflow-auto p-x-6">
        <div class="flex h-full">
          <lay-active v-if="lays && activeLay" @on-unarchive="isArchiveModalShow = true"/>

          <lay-empty v-else :isArchive="true"/>
        </div>
      </div>
    </div>

    <modal-slots :showMod="isArchiveModalShow" @close="isArchiveModalShow = false">
      <template v-slot:title>
        <h2 class="fz18 fw500">Unarchive the LayTime</h2>
      </template>

      <p class="p-x-6 p-t-4 p-b-8">Are you sure you want to unarchive this LayTime?</p>

      <template v-slot:bottomFirstButton>
        <button class="blue-button-text fz14" @click="isArchiveModalShow = false">No</button>
      </template>
      <template v-slot:bottomSecondButton>
        <button class="m-l-8 p-x-16 blue-button fz14 fw500" @click="onUnarchive">Yes</button>
      </template>
    </modal-slots>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

import layEmpty from '@/components/lay/layEmpty'
import layActive from '@/components/lay/layActiveArchive'
import modalSlots from '@/components/modal/modal-slots'

export default {
  components: {
    layEmpty,
    layActive,
    modalSlots
  },
  data() {
    return {
      isArchiveModalShow: false
    }
  },
  computed: {
    ...mapState({
      lays: state => state.lay.laysArchive,
      activeLay: state => state.lay.activeLayArchive
    })
  },
  methods: {
    ...mapActions(['axiosGetLaysArchive', 'axiosOnArchive']),
    ...mapMutations(['changeContractBread', 'setActiveLayArchive']),
    async onUnarchive() {
      await this.axiosOnArchive({id: this.activeLay.id, is_archive: false})
      this.isArchiveModalShow = false
      
      if(this.lays && this.lays.length)
        this.setActiveLayArchive(this.lays[0])
    }
  },
  async beforeMount() {
    await this.axiosGetLaysArchive(this.$route.params.id)

    if(this.lays && this.lays.length && !this.activeLay){
      this.setActiveLayArchive(this.lays[0])
    }

    this.changeContractBread({
      index: 2,
      obj: {
        name: `Archive LayTime`,
        link: `/vessels/${this.$route.params.id}/lay/archive`
      }
    })
  },
  beforeDestroy() {
    this.changeContractBread({
      index: 2,
      obj: null
    })
  }
}
</script>

<style>

</style>