<template>
  <div class="invoice_block bg-white rounded-lg m-b-6 p-y-4">
    <div class="flex items-center p-x-6 p-b-8 border-b border-gray-dark">
      <svg class="m-r-6 slm-logo" width="75" height="97" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2682_4874)"><path d="M42.525 41.546C59.7 44.036 72.95 59.106 72.95 77.251a36.566 36.566 0 01-1.93 11.764l1.94.68a38.803 38.803 0 001.814-16.615 38.622 38.622 0 00-5.337-15.811 37.884 37.884 0 00-11.466-11.975 37.13 37.13 0 00-15.395-5.843l-.052 2.095zM33.104 41.457C15.628 43.667 2.05 58.876 2.05 77.247a36.594 36.594 0 001.88 11.608l-1.941.671A38.804 38.804 0 01.256 72.813a38.61 38.61 0 015.488-15.851 37.863 37.863 0 0111.648-11.917A37.115 37.115 0 0132.94 39.37l.164 2.086z" fill="#113051"/><path d="M34.425 21.96v46.444s-11.085.755-20.044 8.578c0 0 14.825-22.253 20.044-55.021z" fill="#113051"/><path d="M39.779 0v68.267s11.165.231 20.847 7.998C49.669 53.918 42.644 28.556 39.779 0z" fill="#17A1E4"/><path d="M15.656 96.837V94.74h8.846c1.104 0 1.721-.627 1.721-1.564 0-1.024-.617-1.553-1.72-1.553H19.08c-2.221 0-3.604-1.436-3.604-3.418 0-1.933 1.284-3.37 3.637-3.37h8.477v2.098h-8.477c-.942 0-1.494.561-1.494 1.47 0 .91.573 1.449 1.478 1.449h5.405c2.393 0 3.669 1.138 3.669 3.477 0 2.015-1.196 3.517-3.669 3.517l-8.846-.009zM30.753 96.836V84.83h2.06v9.91h7.935v2.096h-9.995zM57.733 96.835l-2.258-8.439-3.344 7.812c-.227.512-.552.792-1.054.792s-.854-.28-1.056-.792l-3.329-7.812-2.255 8.44h-2.03l3.036-11.301c.145-.578.47-.86 1.025-.86.224 0 .442.066.628.193.185.127.329.308.411.519l3.638 8.49 3.637-8.49c.072-.208.206-.388.384-.515.177-.128.388-.196.605-.196.504 0 .829.281.991.859l3.02 11.3h-2.05z" fill="#113051"/></g><defs><clipPath id="clip0_2682_4874"><path fill="#fff" d="M0 0h75v97H0z"/></clipPath></defs></svg>

      <div class="m-t-5">
        <h1 class="m-b-1 fz18 fw500">Speed Logistics Marine LTD</h1>
        <p class="fz13 text-gray-text text-uppercase">Office B on 15/F Wayson Commercial House, <br> 68-70 Lockhart Road, Wai Chai, Hong Kong</p>
      </div>

      <div class="m-l-auto text-align-right">
        <h2 class="m-b-2 fz20 fw500 uppercase">{{activeTab}}</h2>
        <div class="flex items-center justify-end">
          <div class="m-r-8" v-if="invoice.identifier">
            <p class="fz12 text-gray-text m-b-1">Number</p>
            <p class="fz14">{{invoice.identifier}}</p>
          </div>
          <div class="" v-if="invoice.invoice_date">
            <p class="fz12 text-gray-text m-b-1">Invoice date</p>
            <p class="fz14">{{invoice.invoice_date | formatDateFromCpDateWithotTimeZome}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-start p-x-6 p-y-2 border-b border-gray-dark" v-if="invoice.ship_name || invoice.imo_number || invoice.cargo || invoice.total_quantity">
      <div class="w-1/4 p-r-2 box-border" v-if="invoice.ship_name">
        <p class="m-b-0.5 fz12 text-gray-text">Vessel</p>
        <p class="fz14">{{invoice.ship_name}}</p>
      </div>
      <div class="w-1/4 p-r-2 box-border" v-if="invoice.imo_number">
        <p class="m-b-0.5 fz12 text-gray-text">IMO number</p>
        <p class="fz14 word-break-all">{{invoice.imo_number}}</p>
      </div>
      <div class="w-1/4 p-r-2 box-border" v-if="invoice.cargo">
        <p class="m-b-0.5 fz12 text-gray-text">Cargo</p>
        <p class="fz14 word-break-all">{{invoice.cargo}}</p>
      </div>
      <div class="flex items-start p-x-6 p-y-2 border-b border-gray-dark" v-if="invoice.charterer.full_style && invoice.has_full_style">
        <div class="w-1 box-border">
          <p class="m-b-1 fz12 text-gray-text">Full style</p>
          <p class="fz14 word-break-break-word">{{invoice.charterer.full_style}}</p>
        </div>
      </div>
    </div>

    <div class="flex items-start p-x-6 p-y-2 border-b border-gray-dark" v-if="invoice.charterer || invoice.cp_date">
      <div class="w-1/4 p-r-2 box-border" v-if="invoice.charterer">
        <p class="m-b-0.5 fz12 text-gray-text">{{ getCharterer.contract_type === 'VOYAGE_CHARTERER' ? 'Owner' : 'Charterer' }}</p>
        <p class="fz14">{{invoice.charterer.company_name}}</p>
      </div>
      <div class="w-1/4 p-r-2 box-border" v-if="invoice.cp_date">
        <p class="m-b-0.5 fz12 text-gray-text">CP Date</p>
        <p class="fz14">{{invoice.cp_date | formatDateFromCpDateWithotTimeZome}}</p>
      </div>
      <div class="w-1/4 p-r-2 box-border" v-if="invoice.bl_dated">
        <p class="m-b-0.5 fz12 text-gray-text">BL Dated</p>
        <p class="fz14">{{invoice.bl_dated | formatDateFromCpDateWithotTimeZome}}</p>
      </div>
      <div class="w-1/4 p-r-2 box-border" v-if="invoice.cargo_operations_start_date && $route.path.indexOf('vsoa') >= 0">
        <p class="m-b-0.5 fz12 text-gray-text">Cargo operations start date</p>
        <p class="fz14">{{invoice.cargo_operations_start_date | formatDateFromCpDateWithotTimeZome}}</p>
      </div>
      <div class="w-1/4 p-r-2 box-border" v-if="invoice.cargo_operations_end_date && $route.path.indexOf('vsoa') >= 0">
        <p class="m-b-0.5 fz12 text-gray-text">Cargo operations end date</p>
        <p class="fz14">{{invoice.cargo_operations_end_date | formatDateFromCpDateWithotTimeZome}}</p>
      </div>
    </div>

    <template v-for="(item, i) in invoice.ports" >
      <div class=" p-x-6 p-y-2 border-b border-gray-dark" :key="i+'213'" v-if="item.loading_port || item.discharge_port || item.total_quantity">
        <p class="fz13 text-dark fw500 mb3">Loading/Discharging Port #{{i+1}}</p>
        <div class="flex items-start">
          <div class="w-1/4 p-r-2 box-border" v-if="item.loading_port">
            <p class="m-b-0.5 fz12 text-gray-text">Port of loading</p>
            <p class="fz14">{{item.loading_port.name}}, {{item.loading_port.country}}</p>
          </div>

          <div class="w-1/4 p-r-2 box-border" v-if="item.discharge_port">
            <p class="m-b-0.5 fz12 text-gray-text">Port of discharge </p>
            <p class="fz14">{{item.discharge_port.name}}, {{item.discharge_port.country}}</p>
          </div>
          <div class="w-1/4 p-r-2 box-border" v-if="item.total_quantity">
            <p class="m-b-0.5 fz12 text-gray-text">Total Loaded Quantity </p>
            <p class="fz14 word-break-all">{{item.total_quantity | formatNumberThreeWithoutEmptyAdditional}} mt</p>
          </div>
        </div>

      </div>
    </template>



    <div class="m-t-6"></div>

    <div v-if="invoice.invoice_type == 'DETENTION'" class="pdf-see-children m-x-6 border border-dark-gray relative break-words">
      <div class="invoice_right__table_border"></div>
      <div class="flex justify-between p-x-4 p-t-2 p-b-2 border-b border-dark-gray" v-if="invoice.detention_invoices.total">
        <div class="fz14">
          Detention - fm 
          {{invoice.detention_invoices.date_from | formatDate}} 
          {{invoice.detention_invoices.date_from | formatTimeWithoutUtc}} 
          {{invoice.detention_invoices.country}} It till 
          {{invoice.detention_invoices.date_to | formatDate}} 
          {{invoice.detention_invoices.date_to | formatTimeWithoutUtc}} 
          {{invoice.detention_invoices.country}}
        </div>
        <div class="fz14 fw500">{{invoice.detention_invoices.total | formatNumberTwo}}</div>
      </div>
      <div class="flex justify-between p-x-4 p-t-2 p-b-2 border-b border-dark-gray" v-if="invoice.detention_invoices.total">
        <div class="fz14 fw500 uppercase">Total</div>
        <div 
          class="fz14 fw500"
          :class="{'wrap-brackets': invoice.detention_invoices.total < 0}"
        >{{invoice.detention_invoices.total | formatNumberTwoZeroEmptyAbs}}</div>
      </div>

      <div v-for="row in getRowsByKey('LESS_ADDRESS_COMMISSION')" :key="row.id">
        <div
          v-if="row.percentage || row.total_amount"
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
        >
          <div class="w-1/2">
            Less Address Commission
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.percentage | formatNumberTwoZeroEmpty}}
            {{row.percentage ? '%' : ''}}
          </div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >{{row.total_amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
      </div>

      <div v-for="row in getRowsByKey('LESS_BROKERAGE_COMMISSION')" :key="row.id">
        <div 
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14" 
          v-if="row.percentage || row.total_amount"
        >
          <div class="w-1/2">
            Less Brokerage Commission
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.percentage | formatNumberTwoZeroEmpty}}
            {{row.percentage ? '%' : ''}}
          </div>
          <div 
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >
            {{row.total_amount | formatNumberTwoZeroEmptyAbs}}
          </div>
        </div>
        <div 
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14" 
          v-if="row.additional_percentage || row.percentage_amount"
        >
          <div class="w-1/2">
            Less Tax on Brokerage Commission
            <span v-if="row.additional_info.comment_tax"> {{row.additional_info.comment_tax}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.additional_percentage | formatNumberTwoZeroEmpty}}
            {{row.additional_percentage ? '%' : ''}}
          </div>
          <div 
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.percentage_amount < 0}"
          >
            {{row.percentage_amount | formatNumberTwoZeroEmptyAbs}}
          </div>
        </div>
      </div>

      <div class="flex p-x-4 p-t-2 p-b-2 border-t border-dark-gray fz14">
        <div class="w-1/2 uppercase fw500">Total due to owners</div>
        <div class="w-1/6"></div>
        <div class="w-1/6"></div>
        <div 
          class="w-1/6 fw500 text-align-right break-words "
          :class="{'wrap-brackets': invoice.total_amount < 0}"
        >{{invoice.total_amount | formatNumberTwoAbs}}</div>
      </div>
    </div>
    <div v-else class="pdf-see-children m-x-6 border border-dark-gray relative break-words">
      <div class="invoice_right__table_border"></div>
      <div 
        class="flex p-x-4 p-t-2 p-b-2 fz14" 
        v-if="getRowByKey('ILOW') && (getRowByKey('ILOW').percentage ||
          getRowByKey('ILOW').mt_amount ||
          getRowByKey('ILOW').rate ||
          getRowByKey('ILOW').percentage_amount)"
      >
        <div class="w-1/2">
          Ocean freight bss 
          <span v-if="getRowByKey('ILOW').percentage && getRowByKey('ILOW').additional_info.is_ilow">(ILOW {{getRowByKey('ILOW').percentage | formatNumberTwoZeroEmpty}}%)</span>
          <span v-if="getRowByKey('ILOW').additional_info.comment"> {{getRowByKey('ILOW').additional_info.comment}}</span>
        </div>
        <div class="w-1/6">{{getRowByKey('ILOW').mt_amount | formatNumberSOAZeroEmptyThree}} {{getRowByKey('ILOW').mt_amount ? 'mt' : ''}}</div>
        <div class="w-1/6">{{getRowByKey('ILOW').rate | formatNumberThreeZeroEmpty}}</div>
        <div
          class="w-1/6 fw500 text-align-right"
          :class="{'wrap-brackets': getRowByKey('ILOW').percentage_amount < 0}"
        >{{getRowByKey('ILOW').percentage_amount | formatNumberTwoZeroEmpty}}</div>
      </div>
      <div 
        class="flex p-x-4 p-t-2 p-b-2 border-t border-gray-dark fz14" 
        v-if="getRowByKey('DEAD_FREIGHT') && (getRowByKey('DEAD_FREIGHT').percentage ||
          getRowByKey('DEAD_FREIGHT').mt_amount ||
          getRowByKey('DEAD_FREIGHT').rate ||
          getRowByKey('DEAD_FREIGHT').percentage_amount)"
      >
        <div class="w-1/2">
          Dead freight bss 
          <span v-if="getRowByKey('DEAD_FREIGHT').percentage && getRowByKey('DEAD_FREIGHT').additional_info.is_ilow">(ILOW {{getRowByKey('DEAD_FREIGHT').percentage | formatNumberTwoZeroEmpty}}%)</span>
          <span v-if="getRowByKey('DEAD_FREIGHT').additional_info.comment"> {{getRowByKey('DEAD_FREIGHT').additional_info.comment}}</span>
        </div>
        <div class="w-1/6">{{getRowByKey('DEAD_FREIGHT').mt_amount | formatNumberSOAZeroEmptyThree}} {{getRowByKey('DEAD_FREIGHT').mt_amount ? 'mt' : ''}}</div>
        <div class="w-1/6">{{getRowByKey('DEAD_FREIGHT').rate | formatNumberThreeZeroEmptyAbs}}</div>
        <div 
          class="w-1/6 fw500 text-align-right"
          :class="{'wrap-brackets': getRowByKey('DEAD_FREIGHT').percentage_amount < 0}"
        >{{getRowByKey('DEAD_FREIGHT').percentage_amount | formatNumberTwoZeroEmpty}}</div>
      </div>
      <div 
        class="flex p-x-4 p-t-2 p-b-2 border-t border-gray-dark fz14" 
        v-if="getRowByKey('EXTRA_OCEAN_FREIGHT') && (getRowByKey('EXTRA_OCEAN_FREIGHT').percentage ||
          getRowByKey('EXTRA_OCEAN_FREIGHT').mt_amount ||
          getRowByKey('EXTRA_OCEAN_FREIGHT').rate ||
          getRowByKey('EXTRA_OCEAN_FREIGHT').percentage_amount)"
      >
        <div class="w-1/2">
          Extra ocean freight bss 
          <span v-if="getRowByKey('EXTRA_OCEAN_FREIGHT').percentage && getRowByKey('EXTRA_OCEAN_FREIGHT').additional_info.is_ilow">(ILOW {{getRowByKey('EXTRA_OCEAN_FREIGHT').percentage | formatNumberTwoZeroEmpty}}%)</span>
          <span v-if="getRowByKey('EXTRA_OCEAN_FREIGHT').additional_info.comment"> {{getRowByKey('EXTRA_OCEAN_FREIGHT').additional_info.comment}}</span>
        </div>
        <div class="w-1/6">{{getRowByKey('EXTRA_OCEAN_FREIGHT').mt_amount |  formatNumberSOAZeroEmptyThree}} {{getRowByKey('EXTRA_OCEAN_FREIGHT').mt_amount ? 'mt' : ''}}</div>
        <div class="w-1/6">{{getRowByKey('EXTRA_OCEAN_FREIGHT').rate |  formatNumberThreeZeroEmpty}}</div>
        <div 
          class="w-1/6 fw500 text-align-right"
          :class="{'wrap-brackets': getRowByKey('EXTRA_OCEAN_FREIGHT').percentage_amount < 0}"
        >{{getRowByKey('EXTRA_OCEAN_FREIGHT').percentage_amount | formatNumberTwoZeroEmpty}}</div>
      </div>
      <div class="flex p-x-4 p-t-2 p-b-2 border-t border-b border-dark-gray fz14" v-if="getRowByKey('ILOW')">
        <div class="w-1/2 uppercase fw500">Total</div>
        <div class="w-1/6"></div>
        <div class="w-1/6"></div>
        <div
          class="w-1/6 fw500 text-align-right"
          :class="{'wrap-brackets': getRowByKey('ILOW').total_amount < 0}"
        >{{getRowByKey('ILOW').total_amount | formatNumberTwoAbs}}</div>
      </div>

      <div v-for="row in getRowsByKey('THEREOF')" :key="row.id">
        <div
          v-if="row.percentage || row.total_amount"
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
        >
          <div class="w-1/2">
            Thereof
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.percentage | formatNumberSOAZeroEmpty}}
            {{row.percentage ? '%' : ''}}
          </div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >{{row.total_amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
      </div>

      <div v-for="row in getRowsByKey('LESS_ADDRESS_COMMISSION')" :key="row.id">
        <div
          v-if="row.percentage || row.total_amount"
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
        >
          <div class="w-1/2">
            Less Address Commission
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.percentage | formatNumberSOAZeroEmpty}}
            {{row.percentage ? '%' : ''}}
          </div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >{{row.total_amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
      </div>

      <div
        v-for="row in getRowsByKey('LESS_BROKERAGE_COMMISSION')" :key="row.id"
      >
        <div 
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14" 
          v-if="row.percentage || row.total_amount"
        >
          <div class="w-1/2">
            Less Brokerage Commission
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.percentage | formatNumberSOAZeroEmpty}}
            {{row.percentage ? '%' : ''}}
          </div>
          <div 
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >
            {{row.total_amount | formatNumberTwoZeroEmptyAbs}}
          </div>
        </div>
        <div 
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14" 
          v-if="row.additional_percentage || row.percentage_amount"
        >
          <div class="w-1/2">
            Less Tax on Brokerage Commission
            <span v-if="row.additional_info.comment_tax"> {{row.additional_info.comment_tax}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.additional_percentage | formatNumberSOAZeroEmpty}}
            {{row.additional_percentage ? '%' : ''}}
          </div>
          <div 
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.percentage_amount < 0}"
          >
            {{row.percentage_amount | formatNumberTwoZeroEmptyAbs}}
          </div>
        </div>
      </div>
      <div v-for="row in getRowsByKey('PLUS_DEMURRAGE')" :key="row.id">
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.lay_report || row.total_amount"
        >
          <div class="w-1/2">
            Plus Demurrage
<!--            <template v-if="row.lay_report.lay_ports.length">-->

<!--            </template>-->
            <template v-if="row.lay_report.lay_ports.length">
               <span> - </span>
              <span v-for="(el, i) in row.lay_report.lay_ports" :key="'gf'+i">
                  <span v-if="el.loading_port">
                {{el.loading_port.name}} (Load.)
              </span>
              <span v-if="el.loading_port && el.discharging_port"> - </span>
              <span v-if="el.discharging_port">
                {{el.discharging_port.name}} (Disch.)
              </span>              <span v-if="i !== row.lay_report.lay_ports.length - 1"> - </span>
              </span>

            </template>
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6"></div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >
            {{row.total_amount | formatNumberTwoZeroEmptyAbs}}
          </div>
        </div>
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.percentage || row.percentage_amount"
          >
          <div class="w-1/2">
            Less Address Commission
            <span v-if="row.additional_info.comment_address"> {{row.additional_info.comment_address}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.percentage | formatNumberSOAZeroEmpty}}
            {{row.percentage ? '%' : ''}}
          </div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.percentage_amount < 0}"
          >
            {{row.percentage_amount | formatNumberTwoZeroEmptyAbs}}
          </div>
        </div>
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.additional_percentage || row.additional_percentage_amount"
        >
          <div class="w-1/2">
            Less Brokerage Commission
            <span v-if="row.additional_info.comment_brokerage"> {{row.additional_info.comment_brokerage}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.additional_percentage | formatNumberSOAZeroEmpty}}
            {{row.additional_percentage ? '%' : ''}}
          </div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.additional_percentage_amount < 0}"
          >
            {{row.additional_percentage_amount | formatNumberTwoZeroEmptyAbs}}
          </div>
        </div>
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.third_percentage || row.third_percentage_amount"
        >
          <div class="w-1/2">
            Less Tax on Brokerage Commission
            <span v-if="row.additional_info.comment_tax"> {{row.additional_info.comment_tax}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.third_percentage | formatNumberSOAZeroEmpty}}
            {{row.third_percentage ? '%' : ''}}
          </div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.third_percentage_amount < 0}"
          >
            {{row.third_percentage_amount | formatNumberTwoZeroEmptyAbs}}
          </div>
        </div>
      </div>

      <div v-for="row in getRowsByKey('LESS_DISPATCH')" :key="row.id">
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.lay_report || row.total_amount"
        >
          <div class="w-1/2">Less Dispatch
            <template v-if="row.lay_report && row.lay_report.lay_ports.length">
              <span> - </span>

              <span v-for="(el, i) in row.lay_report.lay_ports" :key="i+'df'">
                   <span v-if="el.loading_port">
                {{el.loading_port.name}} (Load.)
              </span>
              <span v-if="el.loading_port && el.discharging_port"> - </span>
              <span v-if="el.discharging_port">
                {{el.discharging_port.name}} (Disch.)
              </span>
                              <span v-if="i !== row.lay_report.lay_ports.length - 1"> - </span>

              </span>

            </template>
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6"></div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >{{row.total_amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
      </div>

      <div v-for="row in getRowsByKey('PLUS_DETENTION')" :key="row.id">
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.total_amount"
        >
          <div class="w-1/2">
            Plus detention
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6"></div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >{{row.total_amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.percentage || row.percentage_amount"
        >
          <div class="w-1/2">
            Less Comission
            <span v-if="row.additional_info.comment_tax"> {{row.additional_info.comment_tax}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6">
            {{row.percentage | formatNumberSOAZeroEmpty}}
            {{row.percentage ? '%' : ''}}
          </div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.percentage_amount < 0}"
          >{{row.percentage_amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
      </div>

      <div v-for="row in getRowsByKey('PLUS_CHARTERERS_EXPENSES')" :key="row.id">
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.total_amount"
        >
          <div class="w-1/2">
            Plus Charterers' expenses
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6"></div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >{{row.total_amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
      </div>

      <div v-for="row in getRowsByKey('LESS_OWNERS_EXPENSES')" :key="row.id">
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="row.total_amount"
        >
          <div class="w-1/2">
            Less Owners' expenses
            <span v-if="row.additional_info.comment"> {{row.additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6"></div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': row.total_amount < 0}"
          >{{row.total_amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
      </div>

      <div v-if="$route.path.indexOf('soa')>=0 || $route.path.indexOf('additional_freight') >= 0">
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-if="getRowByKey('LESS_RECEIVED').additional_info.comment"
        >
          <div class="w-1/2">
            {{getCharterer.contract_type == 'VOYAGE_CHARTERER' ? 'Less paid to Owners' : 'Less Received'}}
            <span> {{getRowByKey('LESS_RECEIVED').additional_info.comment}}</span>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6"></div>
          <div class="w-1/6"></div>
        </div>
        <div
          class="flex p-x-4 p-t-2 p-b-2 border-b border-gray-dark fz14"
          v-for="payment in sortedByDate"
          :key="payment.id"
        >
          <div class="w-1/2">
            {{getCharterer.contract_type == 'VOYAGE_CHARTERER' ? 'Less paid to Owners' : 'Less Received'}}
            <template v-if="payment.date">on {{payment.date}}</template>
          </div>
          <div class="w-1/6"></div>
          <div class="w-1/6"></div>
          <div
            class="w-1/6 fw500 text-align-right"
            :class="{'wrap-brackets': payment.amount < 0}"
          >{{payment.amount | formatNumberTwoZeroEmptyAbs}}</div>
        </div>
      </div>

      <div class="flex p-x-4 p-t-2 p-b-2 border-t border-dark-gray fz14">
        <div class="w-1/2 uppercase fw500">Total due to owners</div>
        <div class="w-1/6"></div>
        <div class="w-1/6"></div>
        <div 
          class="w-1/6 fw500 text-align-right"
          :class="{'wrap-brackets': invoice.total_amount < 0}"
        >
          {{invoice.total_amount | formatNumberTwoZeroEmpty}}
        </div>
      </div>
    </div>

    <div class="m-x-6 p-t-5" v-if="invoice.note">
      <div class="fz12 text-gray-text">Note</div>
      <div class="fz14 break-words">{{invoice.note}}</div>
    </div>

    <div class="m-x-6 p-t-5 min-h-204">
      <h3 class="fw500 m-b-3" v-if="invoice.total_amount">
        Please remit 
        <span class="text-red fw500">{{invoice.currency}} {{invoice.total_amount | formatNumberTwo }}</span> 
        to following bank account not later than on <span class="text-red fw500">{{invoice.due_date | formatDateWithotTZ}}</span>:
      </h3>
      <div class="flex">
        <div class="w-1/2 break-words">
          <div v-if="invoice.account && showBankInfo">
            <p class="m-t-1 fz14" v-if="invoice.account.holder">
              <span class="text-gray-text">Holder:</span> {{invoice.account.holder}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.registered_address">
              <span class="text-gray-text">Registered Address: </span> {{invoice.account.registered_address}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.account_number">
              <span class="text-gray-text">Account Number:</span> {{invoice.account.account_number}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.iban">
              <span class="text-gray-text">IBAN:</span> {{invoice.account.iban}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.description">
              <span class="text-gray-text">Description:</span> {{invoice.account.description}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.bank">
              <span class="text-gray-text">Bank:</span> {{invoice.account.bank}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.bank_address">
              <span class="text-gray-text">Bank Address:</span> {{invoice.account.bank_address}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.clearing_number">
              <span class="text-gray-text">Clearing number:</span> {{invoice.account.clearing_number}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.bic">
              <span class="text-gray-text">BIC / SWIFT:</span> {{invoice.account.bic}}
            </p>
            <p class="m-t-1 fz14" v-if="invoice.account.currency">
              <span class="text-gray-text">Currency:</span> {{invoice.account.currency}}
            </p>
          </div>
        </div>
        <div class="w-1/2 m-t-auto text-align-right pr10" v-if="showStamp">
          <img class="w100Proc" src="@/assets/img_stamp_color.png" v-if="!blackAndWhitePDF" alt="stamp">
          <img class="w100Proc" src="@/assets/img_stamp_bw.png" v-if="blackAndWhitePDF" alt="stamp">
        </div>
      </div>
    </div>
    <div class="flex justify-center w100Proc">
      <div class="block-no-later m-t-6">
        <p class="fz16 fw500 text-dark">Payment: <span class="fz16 fw500 text-red">No later than on {{invoice.due_date | formatDateFromCpDateWithotTimeZome}}</span></p>
      </div>
    </div>
    <p :class="{'m-t-6':!invoice.cp_date}" class="m-t-4 fw500 text-align-center text-gray-text" v-if="getCharterer && getCharterer.owner && !pdf">With reference: m/v {{getCharterer.name}} / {{getCharterer.owner.company_name}}</p>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import moment from 'moment'

export default {
  props: {
    invoice: Object,
    activeTab: String,
    blackAndWhitePDF: {
      type: Boolean,
      default: false,
    },
    showStamp: {
      type: Boolean,
      default: true,
    },
    showBankInfo: {
      type: Boolean,
      default: true,
    },
    pdf: Boolean,
  },
  watch: {
    invoice: function() {
      this.checkCharterer()
    }
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract,

    }),
    ...mapGetters(['getPaymentListOriginal']),
    getStopCheckStatementMember() {
      let owners = []
      owners.push({id: 'main', owner: this.contract.owner ? this.contract.owner.id : ''})
      this.contract.cargo_contracts.forEach(cargo=> {
        owners.push({id: cargo.id, owner: cargo.owner ? cargo.owner.id : ''})
      })
      return this.getCharterer.owner ? owners.filter(a=> a.owner == this.getCharterer.owner.id).length == 1 : false

      // return this.getCharterer.owner ? owners.findIndex(a=> a.id == this.getCharterer.id) == owners.findIndex(a=> a.owner == this.getCharterer.owner.id) : false
    },
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
    sortedByDate() {
      let payments = [...this.getRowByKey('LESS_RECEIVED').additional_info.payments.filter(a=> !this.getRowByKey('LESS_RECEIVED').additional_info.hide_payments || this.getRowByKey('LESS_RECEIVED').additional_info.hide_payments.indexOf(a.id) == -1)]
      return payments.sort((a,b)=>moment(a.date, 'DD.MM.YYYY').valueOf()-moment(b.date, 'DD.MM.YYYY').valueOf());
    },
    getFilteredPayments() {
      if(!this.getPaymentListOriginal.length || !(this.getCharterer.owner ? this.getCharterer.owner.id : null)) return []

      let filtered = this.getPaymentListOriginal.filter(el => {
        console.log(el.statement_member, this.getCharterer.cp_date)
        return el.client?.id == (this.getCharterer.owner ? this.getCharterer.owner.id : null) && el.paid_date && !el.is_archive && el.status == 'paid' && (this.getStopCheckStatementMember || (el.statement_member && el.statement_member === this.getCharterer.cp_date))
      })
      filtered = filtered.map(el => {
        let amount = (el.payment_type == 'expense' ? 1 : -1) * el.payment_amount
        amount = amount ? amount.toFixed(2) : 0
        let obj = {
          id: el.id,
          date: moment(el.paid_date).format('DD.MM.YYYY'),
          amount: amount
        }
        return obj
      })
      return filtered.sort((a,b)=>moment(a.date, 'DD.MM.YYYY').valueOf()-moment(b.date, 'DD.MM.YYYY').valueOf());
    }
  },
  methods: {
    ...mapActions(['axiosGetClientsById', 'axiosChangeInvoice', 'axiosGetPayment', 'axiosGetLays']),
    ...mapMutations(['hideLoad']),
    async checkCharterer() {
      if(this.invoice) {
        if(this.invoice.charterer && Number.isInteger(this.invoice.charterer))
          this.invoice.charterer = await this.axiosGetClientsById(this.invoice.charterer)
      }
      return false
    },
    getRowByKey(key) {
      if(!this.invoice.blocks || !this.invoice.blocks.length) return null
      return this.invoice.blocks.find(el => {
        return el.block_type == key
      })
    },
    getRowsByKey(key) {
      if(!this.invoice.blocks || !this.invoice.blocks.length) return []
      return this.invoice.blocks.filter(el => {
        return el.block_type == key
      })
    },
    async updateReceived() {
      if(!this.getRowByKey('LESS_RECEIVED')){
        this.invoice.blocks.push({
          block_type: "LESS_RECEIVED",
          additional_info: {
            comment:"",
            payments:[],
            hide_payments: []
          }
        })
      }
      let params = {
        ordering: 'paid_date',ship: this.$route.params.id, type: 'payment', limit: 999, sortById: false, filter: {counterparty: [this.getCharterer.owner ? this.getCharterer.owner.id : null], paymentCheckbox: ['paid'], incomeCheckbox: ['paid'],}
      }
      if(!this.getStopCheckStatementMember) {
        params['filter']['statement_member'] = this.getCharterer.cp_date
      } else {
        params['filter']['statement_member_empty'] = true
      }
      await this.axiosGetPayment(params)
      console.log('total_amount: '+this.invoice.total_amount)

      if(!this.getRowByKey('LESS_RECEIVED').additional_info.payments) this.$set(this.getRowByKey('LESS_RECEIVED').additional_info, 'payments', [])
      //
      // this.getRowByKey('LESS_RECEIVED').additional_info.payments.filter(el=> !this.getRowByKey('LESS_RECEIVED').additional_info.hide_payments || this.getRowByKey('LESS_RECEIVED').additional_info.hide_payments.indexOf(el.id) == -1).forEach(el => {
      //   this.invoice.total_amount -= el.amount ? parseFloat(el.amount) : 0
      // })
      console.log('total_amount: '+this.invoice.total_amount)

      this.getRowByKey('LESS_RECEIVED').additional_info.payments = [...this.getFilteredPayments.filter(el=> !this.getRowByKey('LESS_RECEIVED').additional_info.hide_payments || this.getRowByKey('LESS_RECEIVED').additional_info.hide_payments.indexOf(el.id) == -1)]

      this.getRowByKey('LESS_RECEIVED').additional_info.payments.filter(payment=> this.getRowByKey('LESS_RECEIVED').additional_info.hide_payments.indexOf(payment.id) == -1).forEach(el => {
        this.invoice.total_amount += el.amount ? parseFloat(el.amount) : 0
      })
      console.log('total_amount: '+this.invoice.total_amount)

    },
    getTimeAllowed(lay) {
      let a_time = 0

      lay.lay_ports.forEach(el => {
        if(lay.additional_info.reversible_between && lay.additional_info.reversible_between === 'Loading ports') {
          if(el.loading_port){
            a_time += el.loading_quantity / el.loading_rate
          }
        } else if(lay.additional_info.reversible_between && lay.additional_info.reversible_between === 'Discharging ports') {
          if(el.discharging_port){
            a_time += el.discharging_quantity / el.discharging_rate
          }
        } else {
          if(el.discharging_port){
            a_time += el.discharging_quantity / el.discharging_rate
          }
          if(el.loading_port){
            a_time += el.loading_quantity / el.loading_rate
          }
        }
      });

      lay.time_allowed = Math.round(a_time*10000000)/10000000


      let demTime = Math.abs((Math.round(a_time*10000000)/10000000) - (Math.round(this.getTotalEquals(lay) * 10000000)/10000000))
      let dem_rate = a_time > this.getTotalEquals(lay) ? lay.dispatch_rate : lay.demurrage_rate
      let result =  Math.round(demTime*10000000)/10000000 * Math.round(dem_rate*10000000)/10000000
      result = Math.round(result*10000000)/10000000
      lay.demurrage = result
      return result
    },
    getTotalEquals(lay) {
      let summ = 0
      lay.lay_times.forEach(row => {
        summ += row.equal_days
      })
      return summ
    },
  },
  async beforeMount() {
    await this.checkCharterer()
    if(this.$route.path.indexOf('soa') === -1) {
      this.hideLoad()
    }
    let amount = 0
    this.getRowsByKey('PLUS_DEMURRAGE').forEach(demurrage=> {
      if(demurrage.lay_report) {
        demurrage.lay_report.demurrage = this.getTimeAllowed(demurrage.lay_report)
        amount += demurrage.lay_report.demurrage - demurrage.total_amount
        demurrage.total_amount = demurrage.lay_report.demurrage;
      }
    })
    this.invoice.total_amount += amount
        // && this.$route.path.indexOf('soa')>=0
    if(this.invoice?.invoice_type != 'DETENTION' && (this.$route.path.indexOf('soa')>=0 || this.$route.path.indexOf('additional_freight') >= 0))
      this.updateReceived()
  }
}
</script>

<style lang="scss">
.invoice_block{
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.invoice_right__table_border{
  position: absolute;
  top: 0;
  right: calc(16.77777% + 1rem);
  height: 100%;
  width: 1px;
  background-color: var(--color-dark-gray);
}
</style>
<style scoped lang="scss">
.block-no-later {
  width: max-content;
  border: 2px solid var(--color-dark);
  padding: 7px 19px;
  p {
    text-transform: uppercase;
  }
}
.dart-mode {
  .slm-logo {
    path {
      fill: var(--color-blue-dark)
    }
  }
}

</style>