<template>
    <section :class="{'dn':!recaptchaShow}">
      <svg class="logo" width="64" height="84" viewBox="0 0 64 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_14092_14044)">
          <path d="M36.2876 35.9782C50.945 38.1342 62.2515 51.1842 62.2515 66.8975C62.2553 70.3644 61.6989 73.8076 60.6053 77.0854L62.2603 77.6741C63.7993 73.0549 64.3279 68.141 63.808 63.2857C63.2881 58.4304 61.7326 53.7544 59.2534 49.594C56.7742 45.4335 53.4329 41.8919 49.4697 39.224C45.5065 36.556 41.02 34.828 36.3328 34.1641L36.2876 35.9782Z" fill="#113051"/>
          <path d="M28.2488 35.9004C13.3363 37.8139 1.74975 50.9844 1.74975 66.8934C1.74728 70.3124 2.28917 73.7085 3.35372 76.9459L1.69726 77.5271C0.169101 72.876 -0.336172 67.9317 0.218161 63.0538C0.772494 58.1758 2.3725 53.4866 4.9019 49.3271C7.4313 45.1675 10.8265 41.6421 14.8408 39.0071C18.8551 36.3721 23.3876 34.6936 28.1089 34.0938L28.2488 35.9004Z" fill="#113051"/>
          <path d="M29.3759 19.0156V59.2346C29.3759 59.2346 19.9168 59.888 12.2717 66.6629C12.2717 66.6629 24.9227 47.392 29.3759 19.0156Z" fill="#113051"/>
          <path d="M33.9443 0V59.118C33.9443 59.118 43.4719 59.3182 51.7339 66.0434C42.3842 46.6928 36.3897 24.73 33.9443 0Z" fill="#17A1E4"/>
          <path d="M13.3596 83.8615V82.0458H20.9085C21.8505 82.0458 22.3769 81.5023 22.3769 80.6908C22.3769 79.804 21.8505 79.3464 20.9085 79.3464H16.2818C14.3862 79.3464 13.2065 78.1028 13.2065 76.3865C13.2065 74.7123 14.3016 73.4688 16.3095 73.4688H23.5434V75.2844H16.3095C15.5061 75.2844 15.0351 75.7707 15.0351 76.5581C15.0351 77.3455 15.5236 77.8122 16.2964 77.8122H20.9085C22.95 77.8122 24.0392 78.7983 24.0392 80.8233C24.0392 82.5682 23.0185 83.869 20.9085 83.869L13.3596 83.8615Z" fill="#113051"/>
          <path d="M26.2427 83.8582V73.4609H28.0012V82.0425H34.7714V83.8582H26.2427Z" fill="#113051"/>
          <path d="M49.2652 83.8579L47.339 76.55L44.4854 83.3144C44.2915 83.7585 44.0144 84.0009 43.5857 84.0009C43.157 84.0009 42.8566 83.7585 42.6846 83.3144L39.8441 76.55L37.9193 83.8579H36.187L38.7782 74.0719C38.9021 73.572 39.1791 73.3281 39.6531 73.3281C39.8437 73.3268 40.0302 73.3849 40.1884 73.4947C40.3465 73.6046 40.4689 73.7611 40.5396 73.9439L43.644 81.2955L46.747 73.9439C46.809 73.7639 46.9234 73.608 47.0746 73.4978C47.2257 73.3876 47.4062 73.3283 47.5913 73.3281C48.0214 73.3281 48.2985 73.572 48.437 74.0719L51.0136 83.8579H49.2652Z" fill="#113051"/>
        </g>
        <defs>
          <clipPath id="clip0_14092_14044">
            <rect width="64" height="84" fill="white"/>
          </clipPath>
        </defs>
      </svg>


      <div class="content">

          <p class="fz18 fw500 text-dark">Enter authorization code</p>
            <p class="fz14 text-dark-gray">We send a code to {{getPhoneNumber}}</p>
            <input autocomplete="off" v-focus placeholder="______" id="input-code" class="input" type="text" :class="{'input-error': error}" v-model="code" @input="inputCode">
            <p v-if="error" class="text-red fz12">Incorrect code</p>
        <div class="wrap-button w100Proc mt32 position-relative">
          <app-loader class="load-local" :is-local="true" v-if="loadButton"></app-loader>
          <button :disabled="loadButton" class="blue-button mt0 buttonH48 buttonW100P" v-on:click="submitPhoneNumberAuthCode">Confirm</button>
        </div>
            <p v-if="timeBool" class="timer text-before-button fz13 text-gray text-align-center">The code doesn't come? You can request a new one after {{getTime()}}</p>
            <button v-if="!timeBool" class="timer blue-button-text text-before-button fz14 fw500" v-on:click="sendAgain">Send again</button>
        </div>
      <router-link to="/login" class="blue-button-text link-login">
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.82059 5.95736L6.72716 2.99986C6.94086 2.78841 7.28684 2.78877 7.50018 3.00096C7.71335 3.21312 7.7128 3.5568 7.49907 3.76841L4.98073 6.34157L7.49929 8.9147C7.71299 9.12634 7.71354 9.46981 7.5004 9.68199C7.39345 9.78836 7.25335 9.84155 7.11324 9.84155C6.9735 9.84155 6.83394 9.78872 6.72719 9.68309L3.82059 6.72575C3.71767 6.62408 3.65991 6.48573 3.65991 6.34157C3.65991 6.19741 3.71783 6.05922 3.82059 5.95736Z" fill="#094172"/>
        </svg>
        Change contact number
      </router-link>
      <div  class="recaptcha" id="recaptcha-container"></div>

    </section>
</template>

<script>
import Inputmask from "inputmask";
import {mapGetters, mapActions, mapMutations} from "vuex"
import firebase from 'firebase/app'
import "firebase/auth"
import AppLoader from "@/components/loader.vue";

export default {
  name: "confirmationNumber",
  components: {AppLoader},
  computed: mapGetters(["getPermissionsForUser","getPhoneNumber", "getLoginData", "getToken", ]),
  data() {
    return {
      countNumberInCode: 0,
      recaptchaShow: false,
      code: "",
      timeWatch: 30,
      currentTime: 30,
      countAttempts: 0,
      timer: null,
      timeBool: true,
      error: false,
      requestSend: false,
      loadButton: false,
    }
  },
  methods: {
    ...mapActions(["axiosFirebaseLogin", "axiosGetPermissionInDepartment","axiosGetPermissionsForUserLogin","axiosConfirmationPhone"]),
    ...mapMutations(["setAlert","deleteDataUser", "updateLoginStatus"]),
    getTime() {
      let s = 0;
      let m = 0;
      let h = 0;

      h = Math.floor((this.currentTime/60)/60);
      m = Math.floor(this.currentTime/60) - (h*60);
      s = this.currentTime - m*60 - h*60*60;

      let text = ""
      if(h!==0) {
        text += h + " hour ";
      }
      if(m!==0) {
        text += m + " min ";
      }
      if(s!==0) {
        text+= s + " sec."
      }

      return text;
    },
    sendAgain() {
      this.countAttempts+=1;
      if(this.countAttempts === 4) {
        this.currentTime = 7200
      }
      this.clickRefreshCode();
      this.startTimer()
    },
    clickNext() {
      this.axiosConfirmationPhone({phone: this.getPhoneNumber, code: '1111'}).then(()=> {
        if(this.getToken.length > 20) {
          // this.$cookies.set("userData", {data: {token: this.getLoginData.data.token, user: this.getLoginData.data.user}}, '30d');
          localStorage.setItem("userData", JSON.stringify({data: {token: this.getLoginData.data.token, user: this.getLoginData.data.user}}));
          // console.log(this.$cookies.get("userData"));
          // this.axiosGetPermissionInDepartment();
          // if(this.getPermissionsForUser.length === 0) {
          //   this.axiosGetPermissionsForUserLogin().then(()=> {
          //     console.log("PESR")
          //     console.log(this.getPermissionsForUser)
          //   })
          // }

          this.$router.push({name: 'Dashboard'});
        } else {
          this.error = true;
          this.code = ""
        }
      });
      // if(this.code === "1111") {
      //     // console.log(1);
      //     this.$router.push({name: 'Dashboard'});
      //     this.error = false;
      // } else {
      //     this.code = "";
      //     this.error = true;
      // }
    },
    inputCode() {
      if((parseInt(this.code) + "").length > 0) {
        this.error = false;
      }
      if((parseInt(this.code) + "").length === 6) {
        this.submitPhoneNumberAuthCode();
      }
    },
    startTimer() {
      // console.log("start");
      this.timeBool = true;
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timeBool = false;
    },
    submitPhoneNumberAuthCode() {
      var code = this.code
      let vm = this;
      function logout() {
        vm.$router.push('/login');
      }
      this.loadButton = true
      console.log(firebase.confirmationResult)
      window.confirmationResult.confirm(code)
          .then(function(result) {
            var user = result.user;
            console.log(user);
            if (user) {
              let userToken = user.getIdToken();
              console.log(userToken);
              // axios.post('/firebase',{
              //   'firebasetoken':userToken
              // })
              if(!vm.requestSend) {
                vm.requestSend = true;
                vm.axiosFirebaseLogin(userToken).then((response)=> {
                  vm.loadButton = false
                  console.log("response.data");
                  console.log("232"+response);
                  if(response === true) {
                    // alert(2);
                    if(vm.getToken.length > 20) {
                      // vm.$cookies.set("userData", {data: {token: vm.getLoginData.data.token, user: vm.getLoginData.data.user}}, '30d');
                      localStorage.setItem("userData", JSON.stringify({data: {token: vm.getLoginData.data.token, user: vm.getLoginData.data.user}}));

                      // console.log(vm.$cookies.get("userData"));
                      // this.axiosGetPermissionInDepartment();
                      // vm.axiosGetPermissionsForUserLogin().then(()=> {
                      //   console.log("PESR")
                      //   console.log(vm.getPermissionsForUser)
                      // })
                      vm.$router.push({name: 'Dashboard'});
                    } else {
                      vm.error = true;
                      vm.code = ""
                    }
                  } else {
                    // console.log("response")
                    // console.log(response)
                    let s = {
                      s: response
                    }
                    // console.log(1)
                    // console.log(s)
                    //  console.log(2)
                    //  console.log(response.status)
                    // console.log(response.data)
                    //  console.log(3)
                    // console.log(response.detail)
                    if(s.s.response.status === 404) {
                      vm.setAlert({show: true, text: "This number not register"});
                    } if(s.s.response.status === 406) {
                      vm.setAlert({show: true, text: "Log in failed. This user is archived"});
                    } else {
                      vm.setAlert({show: true, text: ""+response});
                    }
                    setTimeout(logout, 10000);
                  }
                  vm.requestSend = false;
                }).catch(()=> {
                  vm.loadButton = false
                  vm.requestSend = false;
                })
              }
              console.log("USER LOGGED IN");
            } else {
              // No user is signed in.
              console.log("USER NOT LOGGED IN");
            }
            // alert("OK");
            // vm.clickNext();
          })
          .catch(function(error) {
            vm.loadButton = false
            console.log(error);
            vm.error = true;
            vm.code = ""
            // alert("HE OK")
          });
    },
    clickRefreshCode() {

      this.recaptchaShow = true;
      var phoneNumber = (this.getPhoneNumber.replace(/-/g, '')).replace(/\s+/g, '');
      var appVerifier = window.recaptchaVerifier;
      let vm = this;
      firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function(confirmationResult) {
            vm.recaptchaShow = false;
            window.confirmationResult = confirmationResult;
          })
          .catch(function(error) {
            vm.recaptchaShow = false;
            console.log(error);
          });
    },
    submitPhoneNumberAuth1() {
      this.recaptchaShow = false;
      var phoneNumber = (this.phone.replace(/-/g, '')).replace(/\s+/g, '');
      var appVerifier = window.recaptchaVerifier;
      let vm = this;

      firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function(confirmationResult) {
            console.log()
            // this.recaptchaShow = true;
            vm.recaptchaShow = false;
            // alert(vm.recaptchaShow)
            window.confirmationResult = confirmationResult;
          })
          .catch(function(error) {
            console.log("ERROR");
            console.log(error);
            vm.recaptchaShow = false;
            // alert(vm.recaptchaShow)
            if(error.message === "We have blocked all requests from this device due to unusual activity. Try again later.") {
              vm.setAlert({text: error.message, show: true});
            }
            if(error.message === "INVALID_PHONE_NUMBER : TOO_SHORT" || error.message === "TOO_SHORT") {
              vm.setAlert({text: "Invalid phone number : too short.", show: true});
            }
            if(error.message === "INVALID_PHONE_NUMBER" || error.message === "Invalid format.") {
              vm.setAlert({text: "Invalid phone number", show: true});
            }
            if(error.message === "CAPTCHA_CHECK_FAILED : Recaptcha verification failed - DUPE") {
              vm.setAlert({text: "Try again.", show: true});
            }
            if(error.message === "TOO_MANY_ATTEMPTS_TRY_LATER") {
              vm.setAlert({text: "Too many attempts try later.", show: true});
            }
            console.log(error);
            window.recaptchaVerifier.reset("recaptcha-container");

          });
    },

  },
  beforeRouteLeave(to,from,next) {
    // this.$cookies.remove('userPhoneNumber');
    localStorage.removeItem('userPhoneNumber');
    next();
  },
  watch: {
    currentTime(time) {
      this.timeWatch = time;
      if (time === 0) {
        this.stopTimer();
        this.currentTime = 30;
        this.timeBool = false;
      }
    }
  },
  mounted() {
    let vm = this;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function() {
            vm.submitPhoneNumberAuth1();
          }
        }
    );

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userToken = user.getIdToken();
        console.log(userToken);
        // axios.post('/firebase',{
        //   'firebasetoken':userToken
        // })
        // vm.axiosFirebaseLogin(userToken).then(()=> {
        //   if(vm.getToken.length > 20) {
        //     vm.$cookies.set("userData", {data: {token: vm.getLoginData.data.token, user: vm.getLoginData.data.user}}, '30d');
        //     console.log(vm.$cookies.get("userData"));
        //     // this.axiosGetPermissionInDepartment();
        //     vm.axiosGetPermissionsForUserLogin().then(()=> {
        //       console.log("PESR")
        //       console.log(vm.getPermissionsForUser)
        //     })
        //     vm.$router.push({name: 'Dashboard'});
        //   } else {
        //     vm.error = true;
        //     vm.code = ""
        //   }
        // })
        console.log("USER LOGGED IN");
      } else {
        // No user is signed in.
        console.log("USER NOT LOGGED IN");
      }
    });

    // this.$cookies.set('userPhoneNumber', this.getPhoneNumber);
    localStorage.setItem('userPhoneNumber', JSON.stringify(this.getPhoneNumber));

    if(this.getToken.length<20) {
      this.deleteDataUser();
    }
    this.updateLoginStatus(null);
    var im = new Inputmask("999999");
    im.mask(document.getElementById('input-code'));
    this.startTimer();
  }
}
</script>

<!--<script>-->
<!--    import Inputmask from "inputmask";-->
<!--    import {mapGetters, mapActions, mapMutations} from "vuex"-->
<!--    // import firebase from 'firebase/app'-->
<!--    // import "firebase/auth"-->
<!--    import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";-->

<!--    export default {-->
<!--        name: "confirmationNumber",-->
<!--        computed: mapGetters(["getPermissionsForUser","getPhoneNumber", "getLoginData", "getToken", ]),-->
<!--        data() {-->
<!--            return {-->
<!--                countNumberInCode: 0,-->
<!--              recaptchaShow: false,-->
<!--                code: "",-->
<!--                timeWatch: 30,-->
<!--                currentTime: 30,-->
<!--                countAttempts: 0,-->
<!--                timer: null,-->
<!--                timeBool: true,-->
<!--                error: false,-->
<!--              requestSend: false,-->
<!--            }-->
<!--        },-->
<!--        methods: {-->
<!--          ...mapActions(["axiosFirebaseLogin", "axiosGetPermissionInDepartment","axiosGetPermissionsForUserLogin","axiosConfirmationPhone"]),-->
<!--          ...mapMutations(['showLoad', 'hideLoad', "setAlert","deleteDataUser", "updateLoginStatus"]),-->
<!--          getTime() {-->
<!--            let s = 0;-->
<!--            let m = 0;-->
<!--            let h = 0;-->

<!--            h = Math.floor((this.currentTime/60)/60);-->
<!--            m = Math.floor(this.currentTime/60) - (h*60);-->
<!--            s = this.currentTime - m*60 - h*60*60;-->

<!--            let text = ""-->
<!--            if(h!==0) {-->
<!--              text += h + " hour ";-->
<!--            }-->
<!--            if(m!==0) {-->
<!--              text += m + " min ";-->
<!--            }-->
<!--            if(s!==0) {-->
<!--              text+= s + " sec."-->
<!--            }-->

<!--            return text;-->
<!--          },-->
<!--            sendAgain() {-->
<!--              this.countAttempts+=1;-->
<!--              if(this.countAttempts === 4) {-->
<!--                this.currentTime = 7200-->
<!--              }-->
<!--              this.clickRefreshCode();-->
<!--              this.startTimer()-->
<!--            },-->
<!--            clickNext() {-->
<!--            this.axiosConfirmationPhone({phone: this.getPhoneNumber, code: '1111'}).then(()=> {-->
<!--              if(this.getToken.length > 20) {-->
<!--                // this.$cookies.set("userData", {data: {token: this.getLoginData.data.token, user: this.getLoginData.data.user}}, '30d');-->
<!--                localStorage.setItem("userData", JSON.stringify({data: {token: this.getLoginData.data.token, user: this.getLoginData.data.user}}));-->
<!--                // console.log(this.$cookies.get("userData"));-->
<!--                // this.axiosGetPermissionInDepartment();-->
<!--                // if(this.getPermissionsForUser.length === 0) {-->
<!--                //   this.axiosGetPermissionsForUserLogin().then(()=> {-->
<!--                //     console.log("PESR")-->
<!--                //     console.log(this.getPermissionsForUser)-->
<!--                //   })-->
<!--                // }-->

<!--                this.$router.push({name: 'Dashboard'});-->
<!--              } else {-->
<!--                this.error = true;-->
<!--                this.code = ""-->
<!--              }-->
<!--            });-->
<!--                // if(this.code === "1111") {-->
<!--                //     // console.log(1);-->
<!--                //     this.$router.push({name: 'Dashboard'});-->
<!--                //     this.error = false;-->
<!--                // } else {-->
<!--                //     this.code = "";-->
<!--                //     this.error = true;-->
<!--                // }-->
<!--            },-->
<!--            inputCode() {-->
<!--                if((parseInt(this.code) + "").length > 0) {-->
<!--                    this.error = false;-->
<!--                }-->
<!--                if((parseInt(this.code) + "").length === 6) {-->
<!--                        this.submitPhoneNumberAuthCode();-->
<!--                }-->
<!--            },-->
<!--            startTimer() {-->
<!--                // console.log("start");-->
<!--                this.timeBool = true;-->
<!--                this.timer = setInterval(() => {-->
<!--                    this.currentTime&#45;&#45;-->
<!--                }, 1000)-->
<!--            },-->
<!--            stopTimer() {-->
<!--                clearTimeout(this.timer);-->
<!--                this.timeBool = false;-->
<!--            },-->
<!--          submitPhoneNumberAuthCode() {-->
<!--            this.showLoad()-->
<!--            var code = this.code-->
<!--            let vm = this;-->
<!--            function logout() {-->
<!--              vm.$router.push('/login');-->
<!--            }-->
<!--            // console.log(firebase.confirmationResult)-->
<!--            window.confirmationResult.confirm(code)-->
<!--                .then(async function(result) {-->
<!--                  var user = result.user;-->
<!--                  console.log(user);-->
<!--                  if (user) {-->
<!--                    let userToken = await user.getIdToken();-->
<!--                    userToken = {-->
<!--                      "a": 2,-->
<!--                      "i": userToken,-->
<!--                      "c": null,-->
<!--                      "b": null,-->
<!--                      "f": null,-->
<!--                      "h": false,-->
<!--                      "g": false-->
<!--                    }-->
<!--                    console.log(userToken);-->

<!--                    // userToken = user.accessToken-->
<!--                    // console.log(userToken);-->
<!--                    // axios.post('/firebase',{-->
<!--                    //   'firebasetoken':userToken-->
<!--                    // })-->
<!--                    if(!vm.requestSend) {-->
<!--                      vm.requestSend = true;-->
<!--                      vm.axiosFirebaseLogin(userToken).then((response)=> {-->
<!--                        vm.hideLoad()-->
<!--                        console.log("response.data");-->
<!--                        console.log("232"+response);-->
<!--                        if(response === true) {-->
<!--                          // alert(2);-->
<!--                          if(vm.getToken.length > 20) {-->
<!--                            // vm.$cookies.set("userData", {data: {token: vm.getLoginData.data.token, user: vm.getLoginData.data.user}}, '30d');-->
<!--                            localStorage.setItem("userData", JSON.stringify({data: {token: vm.getLoginData.data.token, user: vm.getLoginData.data.user}}));-->
<!--                            // console.log(vm.$cookies.get("userData"));-->
<!--                            // this.axiosGetPermissionInDepartment();-->
<!--                            // vm.axiosGetPermissionsForUserLogin().then(()=> {-->
<!--                            //   console.log("PESR")-->
<!--                            //   console.log(vm.getPermissionsForUser)-->
<!--                            // })-->
<!--                            vm.$router.push({name: 'Dashboard'});-->
<!--                            // vm.$OneSignal.getUserId().then(userId=> {-->
<!--                            //   console.log('getUserId', userId)-->
<!--                            //   vm.$OneSignal.setExternalUserId(''+vm.getLoginData.data.user.id, userId)-->
<!--                            // })-->
<!--                            vm.$OneSignal.setExternalUserId(''+vm.getLoginData.data.user.id)-->
<!--                          } else {-->
<!--                            vm.error = true;-->
<!--                            vm.code = ""-->
<!--                          }-->
<!--                        } else {-->
<!--                          // console.log("response")-->
<!--                          // console.log(response)-->
<!--                          let s = {-->
<!--                            s: response-->
<!--                          }-->
<!--                          // console.log(1)-->
<!--                          // console.log(s)-->
<!--                          //  console.log(2)-->
<!--                          //  console.log(response.status)-->
<!--                          // console.log(response.data)-->
<!--                          //  console.log(3)-->
<!--                          // console.log(response.detail)-->
<!--                          if(s.s.response.status === 404) {-->
<!--                            vm.setAlert({show: true, text: "This number not register"});-->
<!--                          } if(s.s.response.status === 406) {-->
<!--                            vm.setAlert({show: true, text: "Log in failed. This user is archived"});-->
<!--                          } else {-->
<!--                            vm.setAlert({show: true, text: ""+response});-->
<!--                          }-->
<!--                          setTimeout(logout, 10000);-->
<!--                        }-->
<!--                        vm.requestSend = false;-->
<!--                      }).catch(()=> {-->
<!--                        vm.hideLoad()-->
<!--                        vm.requestSend = false;-->
<!--                      })-->
<!--                    }-->
<!--                    console.log("USER LOGGED IN");-->
<!--                  } else {-->
<!--                    // No user is signed in.-->
<!--                    console.log("USER NOT LOGGED IN");-->
<!--                  }-->
<!--                  // alert("OK");-->
<!--                  // vm.clickNext();-->
<!--                })-->
<!--                .catch(function(error) {-->
<!--                  vm.hideLoad()-->
<!--                  console.log(error);-->
<!--                  vm.error = true;-->
<!--                  vm.code = ""-->
<!--                  // alert("HE OK")-->
<!--                });-->
<!--          },-->
<!--          clickRefreshCode() {-->

<!--            this.recaptchaShow = true;-->
<!--            var phoneNumber = (this.getPhoneNumber.replace(/-/g, '')).replace(/\s+/g, '');-->
<!--            var appVerifier = window.recaptchaVerifier;-->
<!--            let vm = this;-->
<!--            // firebase-->
<!--            //     .auth()-->
<!--            const auth = getAuth();-->
<!--            signInWithPhoneNumber(auth, phoneNumber, appVerifier)-->
<!--                .then(function(confirmationResult) {-->
<!--                  vm.recaptchaShow = false;-->
<!--                  window.confirmationResult = confirmationResult;-->
<!--                })-->
<!--                .catch(function(error) {-->
<!--                  vm.recaptchaShow = false;-->
<!--                  console.log(error);-->
<!--                });-->
<!--          },-->
<!--          submitPhoneNumberAuth1() {-->
<!--            this.recaptchaShow = false;-->
<!--            var phoneNumber = (this.phone.replace(/-/g, '')).replace(/\s+/g, '');-->
<!--            var appVerifier = window.recaptchaVerifier;-->
<!--            let vm = this;-->

<!--            const auth = getAuth();-->
<!--            signInWithPhoneNumber(auth, phoneNumber, appVerifier)-->
<!--                .then(function(confirmationResult) {-->
<!--                  console.log()-->
<!--                  // this.recaptchaShow = true;-->
<!--                  vm.recaptchaShow = false;-->
<!--                  // alert(vm.recaptchaShow)-->
<!--                  window.confirmationResult = confirmationResult;-->
<!--                })-->
<!--                .catch(function(error) {-->
<!--                  console.log("ERROR");-->
<!--                  console.log(error);-->
<!--                  vm.recaptchaShow = false;-->
<!--                  // alert(vm.recaptchaShow)-->
<!--                  if(error.message === "We have blocked all requests from this device due to unusual activity. Try again later.") {-->
<!--                    vm.setAlert({text: error.message, show: true});-->
<!--                  }-->
<!--                  if(error.message === "INVALID_PHONE_NUMBER : TOO_SHORT" || error.message === "TOO_SHORT") {-->
<!--                    vm.setAlert({text: "Invalid phone number : too short.", show: true});-->
<!--                  }-->
<!--                  if(error.message === "INVALID_PHONE_NUMBER" || error.message === "Invalid format.") {-->
<!--                    vm.setAlert({text: "Invalid phone number", show: true});-->
<!--                  }-->
<!--                  if(error.message === "CAPTCHA_CHECK_FAILED : Recaptcha verification failed - DUPE") {-->
<!--                    vm.setAlert({text: "Try again.", show: true});-->
<!--                  }-->
<!--                  if(error.message === "TOO_MANY_ATTEMPTS_TRY_LATER") {-->
<!--                    vm.setAlert({text: "Too many attempts try later.", show: true});-->
<!--                  }-->
<!--                  console.log(error);-->
<!--                  window.recaptchaVerifier.reset("recaptcha-container");-->

<!--                });-->
<!--          },-->

<!--        },-->
<!--      beforeRouteLeave(to,from,next) {-->
<!--        // this.$cookies.remove('userPhoneNumber');-->
<!--        localStorage.removeItem('userPhoneNumber');-->
<!--        next();-->
<!--      },-->
<!--        watch: {-->
<!--            currentTime(time) {-->
<!--                this.timeWatch = time;-->
<!--                if (time === 0) {-->
<!--                    this.stopTimer();-->
<!--                    this.currentTime = 30;-->
<!--                    this.timeBool = false;-->
<!--                }-->
<!--            }-->
<!--        },-->
<!--        mounted() {-->
<!--          let vm = this;-->
<!--          // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(-->
<!--          //     "recaptcha-container",-->
<!--          //     {-->
<!--          //       size: "invisible",-->
<!--          //       callback: function() {-->
<!--          //         vm.submitPhoneNumberAuth1();-->
<!--          //       }-->
<!--          //     }-->
<!--          // );-->
<!--          const auth = getAuth();-->
<!--          window.recaptchaVerifier = new RecaptchaVerifier(-->
<!--              auth,-->
<!--              'recaptcha-container', {-->
<!--                'size': 'invisible',-->
<!--                'callback': () => {-->
<!--                  // reCAPTCHA solved, allow signInWithPhoneNumber.-->
<!--                            vm.submitPhoneNumberAuth1();-->
<!--                }-->
<!--              });-->
<!--          // firebase.auth().onAuthStateChanged(function (user) {-->
<!--          //   if (user) {-->
<!--          //     let userToken = user.getIdToken();-->
<!--          //     console.log(userToken);-->
<!--          //-->
<!--          //     console.log("USER LOGGED IN");-->
<!--          //   } else {-->
<!--          //     // No user is signed in.-->
<!--          //     console.log("USER NOT LOGGED IN");-->
<!--          //   }-->
<!--          // });-->
<!--          auth.onAuthStateChanged(function (user) {-->
<!--            if (user) {-->
<!--              let userToken = user.getIdToken();-->
<!--              console.log(userToken);-->

<!--              console.log("USER LOGGED IN");-->
<!--            } else {-->
<!--              // No user is signed in.-->
<!--              console.log("USER NOT LOGGED IN");-->
<!--            }-->
<!--          });-->
<!--          // this.$cookies.set('userPhoneNumber', this.getPhoneNumber);-->
<!--          localStorage.setItem('userPhoneNumber', JSON.stringify(this.getPhoneNumber));-->

<!--          if(this.getToken.length<20) {-->
<!--            this.deleteDataUser();-->
<!--          }-->
<!--          this.updateLoginStatus(null);-->
<!--            var im = new Inputmask("999999");-->
<!--            im.mask(document.getElementById('input-code'));-->
<!--            this.startTimer();-->
<!--        }-->
<!--    }-->
<!--</script>-->

<style scoped lang="scss">
    @import "confirmationNumber";
    .load-local {
      background-color: rgba(44, 44, 44, 0.3);
      border-radius: 4px;
      ///deep/ {
      //  .wrap-loader {
      //    margin-left: auto;
      //    margin-right: 20px;
      //  }
      //}
    }
</style>
