import axios from 'axios'
import router from '@/router'

export default {
  actions: {
    async axiosCreateLay(ctx, lay) {
      ctx.commit('showLoad')
      lay.additional_info = lay.additional_info ? lay.additional_info : {}
      return await axios.post(process.env.VUE_APP_URL+'/lay/report/', lay, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        // console.log('axiosCreateLay123', response);
        ctx.commit('hideLoad')
        return response.data
      }).catch(() => {
        // console.error('axiosCreateLay123', error);
        ctx.commit('hideLoad')
        return false
      })
    },
    async axiosChangeLay(ctx, lay) {
      ctx.commit('showLoad')
      return await axios.put(process.env.VUE_APP_URL+'/lay/report/'+lay.id, lay,
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        // console.log('axiosChangeLay321', response);
        let index = ctx.state.lays.findIndex(el => {
          return el.id == lay.id
        })
        ctx.state.lays.splice(index, 1, response.data)
        ctx.commit('hideLoad')

        return response.data

      }).catch(() => {
        ctx.commit('hideLoad')

        // console.error('axiosChangeLay321', error);
      })
    },
    async axiosPutLay(ctx, lay) {
      // ctx.commit('showLoad')
      await axios.put(process.env.VUE_APP_URL+'/lay/report/'+lay.id, lay, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        // console.log('axiosPutLay321', response);
        let index = ctx.state.lays.findIndex(el => {
          return el.id == lay.id
        })
        ctx.state.lays.splice(index, 1, response.data)
      }).catch(() => {
        // console.error('axiosPutLay321', error);
      })
      // ctx.commit('hideLoad')
    },
    async axiosGetLays(ctx, id) {
      ctx.commit('showLoad')
      await axios.get(process.env.VUE_APP_URL+'/lay/report/?limit=999&is_archive=false&ordering=-created_at&contract='+id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.state.lays = response.data.results.length ? response.data.results : null
      }).catch(error => {
        console.error('axiosGetLays', error);
        ctx.state.lays = null
      })
      ctx.commit('hideLoad')
    },
    async axiosGetLaysByFilter(ctx, data) {
      return await axios.get(process.env.VUE_APP_URL+'/lay/report/?is_archive=false&ordering=-created_at&contract='+data.id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        },
        params: data.params,
      }).then(response => {
        if(!data.loadMore) {
          ctx.state.lays = response.data.results.length ? response.data.results : []
        } else {
          ctx.state.lays =  ctx.state.lays.concat(response.data.results)
        }
        return response.data.count
      }).catch(error => {
        console.error('axiosGetLays', error);
        ctx.state.lays = null
        return 0
      })
    },
    async axiosGetLaysArchive(ctx, id) {
      ctx.commit('showLoad')
      await axios.get(process.env.VUE_APP_URL+'/lay/report/?limit=999&is_archive=true&ordering=-updated_at&contract='+id, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        ctx.state.laysArchive = response.data.results.length ? response.data.results : null
      }).catch(error => {
        console.error('axiosGetLays', error);
        ctx.state.laysArchive = null
      })
      ctx.commit('hideLoad')
    },
    async checkLayEdit(ctx, id) {
      if(ctx.state.activeLay && ctx.state.activeLay.id == router.currentRoute.params.layId) return true

      if(!ctx.state.lays || !ctx.state.lays.length)
        await ctx.dispatch('axiosGetLays', id)

      if(ctx.state.lays){
        let lay = ctx.state.lays.find(el=>{
          return el.id == router.currentRoute.params.layId
        })
        if(lay)
          ctx.commit('setActiveLay', lay)
        else{
          router.push(`/vessels/${router.currentRoute.params.id}/open/lay`)
          ctx.commit('addTip', 'The LayTime doesn\'t exist')
        }
      }
    },
    async axiosOnArchive(ctx, lay) {
      ctx.commit('showLoad')
      await axios.patch(process.env.VUE_APP_URL+'/lay/report/'+lay.id, lay, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        // console.log('axiosOnArchive', response);
        ctx.commit('addTip', `LayTime is ${lay.is_archive ? 'archived':'unarchived'}.`)
      }).catch(error => {
        console.error('axiosOnArchive', error);
      })
      if(lay.is_archive)
        await ctx.dispatch('axiosGetLays', router.currentRoute.params.id)
      else
        await ctx.dispatch('axiosGetLaysArchive', router.currentRoute.params.id)
      
      ctx.commit('hideLoad')
    },
    async axiosChangeLayStatus(ctx, lay) {
      await axios.patch(process.env.VUE_APP_URL+'/lay/report/'+lay.id, lay, 
      {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(() => {
        // console.log('axiosChangeLayStatus', response);
      }).catch(() => {
        // console.error('axiosOnArchive', error);
      })
    }
  },
  mutations: {
    setActiveLay(state, lay) {
      state.activeLay = lay
    },
    setActiveLayArchive(state, lay) {
      state.activeLayArchive = lay
    }
  },
  state: {
    lays: null,
    laysArchive: null,
    activeLay: null,
    activeLayArchive: null,
    remarksList: [
      {
        id: 'na',
        name: 'N/A'
      },
      {
        id: 'ntc',
        name: 'NTC'
      },
      {
        id: 'ntc_remark',
        name: 'NTC (Own Remark)'
      },
      {
        id: 'vessel_arrived_ps_nor_tendered',
        name: 'Vessel arrived PS / NOR tendered'
      },
      {
        id: 'nor_tendered',
        name: 'NOR tendered'
      },
      {
        id: 'laytime_commenced',
        name: 'Laytime Commenced'
      },
      {
        id: 'laytime_completed',
        name: 'Laytime Completed'
      },
      {
        id: 'loading_completed',
        name: 'Loading Completed'
      },
      {
        id: 'vsl_sailed_steaming',
        name: 'VSL Sailed / Steaming'
      },
      {
        id: 'trimming',
        name: 'Trimming'
      },
      {
        id: 'steaming',
        name: 'Steaming'
      },
      {
        id: 'discharging_completed',
        name: 'Discharging Completed'
      },
      {
        id: 'final_draft_survey_conducted',
        name: 'Final Draft Survey Conducted'
      },
      {
        id: 'own_remark',
        name: 'Own Remark'
      },
    ],
  },
  getters: {
    getTimeAllowed(state) {
      let result = 0
      if(!state.activeLay) return result
      state.activeLay.lay_ports.forEach(el => {
        if(state.activeLay.additional_info.reversible_between && state.activeLay.additional_info.reversible_between === 'Loading ports') {
          if(el.loading_port){
            result += el.loading_quantity / el.loading_rate
          }
        } else if(state.activeLay.additional_info.reversible_between && state.activeLay.additional_info.reversible_between === 'Discharging ports') {
          if(el.discharging_port){
            result += el.discharging_quantity / el.discharging_rate
          }
        } else {
          if(el.discharging_port){
            result += el.discharging_quantity / el.discharging_rate
          }
          if(el.loading_port){
            result += el.loading_quantity / el.loading_rate
          }
        }
      });

      return result
    },
    getTimeAllowedArchive(state) {
      let result = 0
      if(!state.activeLayArchive) return result

      if(state.activeLayArchive.discharge_port){
        result += state.activeLayArchive.discharge_quantity / state.activeLayArchive.discharge_rate
      }
      if(state.activeLayArchive.loading_port){
        result += state.activeLayArchive.cargo_quantity / state.activeLayArchive.loading_rate
      }

      return result
    }
  }
}