<template>
  <div class="modal-wrap">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-content">
      <div class="modal-content_body">
        <p class="modal-content_title">Create new {{type=='type'? 'type' : 'subtype'}} <button class="modal-close" @click="closeModal()"></button></p>
        <label class="block-input" :class="{ 'block-input_error': $v.input.$error }">
          <p class="block-input_title text-gray" v-if="type == 'type'">Type Name</p>
          <p class="block-input_title text-gray" v-else>Subtype Name</p>
          <input ref="input" type="text" class="h36 block-input_content" minlength="2" maxlength="256" v-model.trim="$v.input.$model" @keyup.enter="onSubmit">
          <div class="error" v-if="!$v.input.required && $v.input.$dirty">Field is required</div>
        </label>

        <counterparties-drop
          v-if="type == 'type'"
          :list="getTrialsInDrop"
          :activeItem="null"
          :isMultiple="true"
          @select="onSelectAccount"
          title="Account"
        ></counterparties-drop>
      </div>
      <div class="modal-content_bottom border-top">
        <button class="btn btn-close text-dark1" @click="closeModal()">Cancel</button>
        <button class="btn btn-next" @click="onSubmit">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions, mapState, mapGetters} from 'vuex';
import counterpartiesDrop from '@/components/counterparties/counterpartiesAdd/counterpartiesDrop'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: 'type'
    },
    data: {
      type: Object,
    }
  },
  components: {
    counterpartiesDrop
  },
  data() {
    return {
      input: '',
      accounts: [],
    }
  },
  validations: {
    input: {
      required
    }
  },
  computed: {
    ...mapState({
      trials: state => state.trialBalance.trials
    }),
    ...mapGetters(['getPermissionsByType']),
    getTrialsInDrop() {
      if(!this.trials?.length) return []
      return this.trials.map(el => {
        return {id: el.id, name: this.getPermissionsByType('show_dop_info_for_acc_in_payments') ? el.account_id+': '+el.name : el.name}
      })
    },
  },
  methods: {
    ...mapActions(['axiosSetClientType', 'axiosSetClientSubtype', 'axiosGetTrials']),
    ...mapMutations(['showLoad', 'hideLoad']),
    closeModal() {
      this.$emit('close')
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      this.showLoad()
      if(this.type == 'type'){
        this.axiosSetClientType({
          name: this.input,
          account: this.accounts.map(el=>el.id)
        }).then(responce => {
          this.hideLoad()
          this.$emit('close')
          this.$emit('update')
          console.log(responce);
        })
      }else if(this.data){
        this.axiosSetClientSubtype({name: this.input, id: this.data.id}).then(responce => {
          this.hideLoad()
          this.$emit('close')
          this.$emit('update')
          console.log(responce);
        })
      }
    },
    onSelectAccount(item) {
      this.accounts = item
    }
  },
  mounted() {
    if(!this.trials.length)
      this.axiosGetTrials()
      
    this.$refs.input.focus();
  }
}
</script>

<style lang="scss">
  .modal-wrap{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    overflow: auto;
    &:after{
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    .modal-bg{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-filter-modal);
      opacity: 0.15;
    }
    .modal-close{
      position: relative;
      top: -4px;
      width: 24px;
      height: 24px;
      font-size: 0;
      &:after,
      &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 64%;
        height: 1px;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: var(--color-dark);
      }
      &:after{
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    .modal-content{
      position: relative;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      width: 100%;
      max-width: 320px;
      background-color: var(--color-modal);
      border: none;
      border-radius: 8px;
      &.info{
        .modal-content_body{
          padding-bottom: 12px;
        }
        .modal-content_bottom{
          border-top-color: transparent;
        }
      }
      &_body{
        padding: 17px 16px 24px 16px;
      }
      &_bottom{
        border-top: 1px solid var(--color-card-border);
        padding: 8px 16px 8px 16px;
        text-align: right;
      }
      &_title{
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        font-weight: 500;
      }
      &_descr{
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.42;
        b{
          font-weight: 500;
        }
      }
    }
    .btn{
      min-width: 100px;
      padding: 8px;
      font-size: 14px;
      line-height: normal;
      border: none;
    }
    .btn-next{
      margin-left: 4px;
      border-radius: 4px;
      font-weight: 500;
      background-color: #319FEF;
      color: #fff;
    }
    .btn-close{
      color: var(--color-blue-dark);
    }
  }
  .dart-mode {
    .modal-bg {
      opacity: 0.75;
    }
  }
</style>