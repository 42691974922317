var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice_table border border-dark rounded-lg",class:{
    'with_trials': _vm.getPermissionsByType('show_accounts_in_all_operations') && _vm.$route.meta.invoiceType == 'Voyage Statement of Account'
  }},[_c('div',{staticClass:"border-r flex items-center justify-between p-x-2 p-y-1",class:{'border-b': _vm.detail.additional_info.payments && _vm.detail.additional_info.payments.length}},[_vm._v(" "+_vm._s(_vm.getCharterer.contract_type == 'VOYAGE_CHARTERER' ? 'Less paid to Owners' : 'Less Received')+" ")]),_c('div',{staticClass:"border-r",class:{'border-b': _vm.detail.additional_info.payments && _vm.detail.additional_info.payments.length}},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.detail.additional_info.comment),expression:"detail.additional_info.comment",modifiers:{"lazy":true}}],staticClass:"p-x-2 p-y-2",attrs:{"type":"text","placeholder":"Write a comment"},domProps:{"value":(_vm.detail.additional_info.comment)},on:{"change":function($event){return _vm.$set(_vm.detail.additional_info, "comment", $event.target.value)}}})]),(_vm.getPermissionsByType('show_accounts_in_all_operations') && _vm.$route.meta.invoiceType == 'Voyage Statement of Account')?_c('div',{staticClass:"border-r",class:{'border-b': _vm.detail.additional_info.payments && _vm.detail.additional_info.payments.length}}):_vm._e(),_c('div',{staticClass:"border-r",class:{
      'border-b': _vm.detail.additional_info.payments && _vm.detail.additional_info.payments.length,
      'colspan-4-7': _vm.getPermissionsByType('show_accounts_in_all_operations') && _vm.$route.meta.invoiceType == 'Voyage Statement of Account',
      'colspan-3-6': !(_vm.getPermissionsByType('show_accounts_in_all_operations') && _vm.$route.meta.invoiceType == 'Voyage Statement of Account')
    }}),_c('div',{class:{'border-b': _vm.detail.additional_info.payments && _vm.detail.additional_info.payments.length}}),_vm._l((_vm.getSort(_vm.detail.additional_info.payments)),function(payment,i){return [_c('div',{key:payment.id,staticClass:"border-r p-x-2 p-y-2 position-relative d-flex align-items-center",class:{'border-b': i < _vm.detail.additional_info.payments.length-1, 'bg-gray-light text-gray-text': _vm.detail.additional_info.hide_payments.indexOf(payment.id)>=0}},[_c('switcher',{staticClass:"switcher-payment switcher_owners-row",attrs:{"id":'paymentd'+payment.id,"value":_vm.detail.additional_info.hide_payments.indexOf(payment.id) == -1},on:{"input":function($event){return _vm.addToHidePayment(payment.id)}}}),_vm._v(" "+_vm._s(_vm.getCharterer.contract_type == 'VOYAGE_CHARTERER' ? 'Less paid to Owners' : 'Less Received')+" ")],1),_c('div',{key:payment.id + 'comment',staticClass:"border-r",class:{'border-b': i < _vm.detail.additional_info.payments.length-1, 'bg-gray-light text-gray-text': _vm.detail.additional_info.hide_payments.indexOf(payment.id)>=0}}),(_vm.getPermissionsByType('show_accounts_in_all_operations') && _vm.$route.meta.invoiceType == 'Voyage Statement of Account')?_c('div',{key:payment.id + 'trials',staticClass:"border-r",class:{'bg-gray-light text-gray-text': _vm.detail.additional_info.hide_payments.indexOf(payment.id)>=0, 'border-b': i < _vm.detail.additional_info.payments.length-1}}):_vm._e(),_c('div',{key:payment.id + 'date',staticClass:"border-r p-x-2 p-y-2",class:{
      'bg-gray-light text-gray-text': _vm.detail.additional_info.hide_payments.indexOf(payment.id)>=0,
        'border-b': i < _vm.detail.additional_info.payments.length-1,
        'colspan-4-7': _vm.getPermissionsByType('show_accounts_in_all_operations') && _vm.$route.meta.invoiceType == 'Voyage Statement of Account',
        'colspan-3-6': !(_vm.getPermissionsByType('show_accounts_in_all_operations') && _vm.$route.meta.invoiceType == 'Voyage Statement of Account')
      }},[_vm._v(_vm._s(payment.date))]),_c('div',{key:payment.id + 'amount',class:{
      'bg-gray-light text-gray-text': _vm.detail.additional_info.hide_payments.indexOf(payment.id)>=0,
      'border-b': i < _vm.detail.additional_info.payments.length-1}},[_c('p',{staticClass:"p-x-2 p-y-2 fw500 text-align-right box-border",class:{'wrap-brackets': payment.amount < 0}},[_vm._v(_vm._s(_vm._f("formatNumberTwo")(Math.abs(payment.amount))))])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }