<template>
  <div id="app" class="" :class="{'dart-mode': getDartMode}">
<!--    <transition name="fade">-->
      <app-loader v-if="isLoading"/>
<!--    </transition>-->
    <transition name="fade-alert">
    <div class="alert-modal-wrap" v-if="getShowAlert" >
      <div class="filter" v-on:click="closeAlert">
      </div>
      <div class="alert-modal">
        <div class="alert-modal__content">
          <p class="text-dark fz14">{{getTextAlert}}</p>
        </div>
        <div class="alert-modal__button-part">
          <button class="hover-orange text-gray fw500 fz14" v-on:click="closeAlert">
            ОК
          </button>
        </div>
      </div>
    </div>
    </transition>
    <adblock-modal></adblock-modal>
    <navigation-panel v-if="$route.path !== '/login' && $route.path !== '/phone-number-confirm' && getBoolShowNav"></navigation-panel>
      <router-view :class="{'hide-nav': !getBoolShowNav}"></router-view>
    <socket></socket>
    <tips-list />
<!--    <loader-pdf-convert v-if="showLoaderPDF"></loader-pdf-convert>-->
  </div>
</template>

<style lang="scss">
  @import "app";
  @import "helpers";
  .hide-nav {
    height: 100vh !important;
  }
</style>
<script>
  import NavigationPanel from "./components/navigation-panel/navigation-panel";
  import appLoader from "./components/loader";
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import Socket from "@/components/socket";
  import tipsList from "@/components/counterparties/counterpartiesList/counterpartiesTips"
  import { detectAnyAdblocker } from 'vue-adblock-detector'
  import AdblockModal from "@/components/adblock-modal";
  export default {
    components: {AdblockModal, Socket, NavigationPanel, appLoader, tipsList},
    computed: {
      ...mapGetters(["getNoTurnOffLoader", "getContracts","getPaymentsAccount","getPermissionsByType", "getDartMode", "getStatusDartMode", "showLoaderPDF", "getPermissionsForUserCanViewArchive", "getPermissionsForUserCanSeeTask", "getPermissionsForUser","getToken","getShowAlert", "getTextAlert"]),
      ...mapState(['isLoading']),
      getBoolShowNav() {
        return window.location.href.indexOf('hideNavigation') == -1
      }
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations(["setShowAdBlock", "setNoTurnOffLoader", "setPaymentsList", "hideLoad", "deleteDataUser", "setAlert", "setPermissionForUser", 'onWindowResize']),
      ...mapActions(["axiosGetPaymentAccount", "axiosGetAllContracts", "axiosGetPermissionsForUserLogin","axiosRelatedPermissions"]),
      closeAlert() {
        this.setAlert({show: false, text: ""})
      }
    },
    beforeMount() {
      this.$OneSignal.init({ appId: 'f981005a-3ff6-4888-a47c-bb47136481fa' });
      this.$nextTick(() => {
        this.onWindowResize()
        window.addEventListener('resize', this.onWindowResize);
      })
    },
    mounted() {
      console.log('build v1.11')
      detectAnyAdblocker().then((detected) => {
        if (detected) {
          if(localStorage.getItem('noShowAdBlock') === undefined || localStorage.getItem('noShowAdBlock') === null) {
            this.setShowAdBlock(true)
          }
        }
      })
      // if(localStorage.getItem('dartMode') !== null && localStorage.getItem('dartMode') !== undefined) {
      //     state.statusDartMode = JSON.parse(localStorage.getItem('dartMode'))
      // }
      if(this.getDartMode) {
        document.body.classList.add('dart-mode');
      } else {
        document.body.classList.remove('dart-mode');
      }
      // console.log("COOOOOOKIES")
      // console.log(this.$cookies.keys())
      let arrayCookies = this.$cookies.keys();
      if(Array.isArray(arrayCookies)) {
        arrayCookies.map(a=> {
          this.$cookies.remove(a)
        })
      }
      /* console.log("PROCESS")
      console.log(process.env) */
      // console.log("TOKEN");
      // console.log(this.getToken.length);

      // if(this.getToken.length === 0 && this.$route.path.indexOf('login') === -1 && this.$route.path.indexOf('phone-number-confirm') === -1) {
      //   this.deleteDataUser()
      //   // this.$router.push('/login');
      // }
      if(this.getPermissionsForUser.length === 0 && this.getToken.length !== 0 && this.$route.name !== "phone-number-confirm" && this.$route.name !== "login") {
        this.setPermissionForUser(JSON.parse(localStorage.getItem("per")));
      }
      if(this.$route.path.indexOf('login') === -1 && this.$route.path.indexOf('phone-number-confirm') === -1)
        this.axiosGetPermissionsForUserLogin().then(()=> {
        // console.log("this.$route.fullPath")
        // console.log(this.$route)
        if(this.$route.fullPath === '/payment/archive' && !this.getPermissionsByType("show_archive_of_payments_incomes")) {
          this.$router.push({name: 'Dashboard'})
        }
        if(this.$route.fullPath === '/baltic-dry-index' && !this.getPermissionsByType("can_view_index_page")) {
          this.$router.push({name: 'Dashboard'})
        }
        if(this.$route.fullPath === '/add-new-index' && !this.getPermissionsByType("show_cashflow_list")) {
          this.$router.push({name: 'Dashboard'})
        }
        if(this.$route.fullPath === '/cashflow' && this.getPermissionsByType("show_cashflow_section") === false) {
          this.$router.push({name: 'Dashboard'})
        }
        if(this.$route.fullPath === '/cashflow/diagram' && !this.getPermissionsByType("show_cashflow_diagram")) {
          this.$router.push({name: 'Dashboard'})
        }

        if(this.$route.fullPath === '/cashflow/list' && !this.getPermissionsByType("show_cashflow_list")) {
          this.$router.push({name: 'Dashboard'})
        }
        if(this.$route.path.indexOf('employee') >=0 && !this.getPermissionsByType("show_employes_section")) {
          this.$router.push({name: 'Dashboard'})
        }



        if(this.$route.fullPath === '/tasks' && !this.getPermissionsByType('show_manual_tasks')) {
          this.$router.push({name: 'employees'});
        }
        if(this.$route.fullPath === '/tasks-archive' && !this.getPermissionsForUserCanViewArchive) {
          if(this.getPermissionsByType('show_manual_tasks')) {
            this.$router.push({name: 'Tasks'});
          } else {
            this.$router.push({name: 'employees'});
          }
        }
        if(this.$route.fullPath === '/tasks-archive' && this.getPermissionsForUserCanViewArchive && !this.getPermissionsByType('show_manual_tasks')) {
          this.$router.push({name: 'employees'});
        }
        if(this.$route.name === 'Task' && !this.getPermissionsByType('show_manual_tasks')) {
          this.$router.push({name: 'employees'});
        }
        localStorage.setItem("per", JSON.stringify(this.getPermissionsForUser))
        // console.log("LocalStore")
      });

      // if(!this.getPaymentsAccount.length) {
      //   this.axiosGetPaymentAccount();
      // }
      // if(!this.getContracts.length) {
      //   this.axiosGetAllContracts();
      // }
      // this.axiosRelatedPermissions();
      // // console.log(213123);
      // this.$router.push('/login');

    },
    watch: {
      $route (to){
        // console.log(this.$route.fullPath)
        this.setPaymentsList([])
        if(to.path.indexOf('calculator') === -1 && !this.getNoTurnOffLoader) {
          this.hideLoad()
        }
        this.setNoTurnOffLoader(false)
        if(this.getDartMode) {
          document.body.classList.add('dart-mode');
        } else {
          document.body.classList.remove('dart-mode');
        }
        // if(to.name !== "phone-number-confirm" && to.name !== "login") {
        //   if(this.getToken.length === 0) {
        //     this.$router.push('/login');
        //   }
        //   if(this.getPermissionsForUser.length === 0) {
        //     // this.axiosGetPermissionsForUserLogin();
        //   }
        // }

        // if(this.getPermissionsForUser.length !== 0) {
        //   console.log()
        //   if(!this.getPermissionsForUserCanSeeTask.status && (to.name === "Tasks" || to.name === 'Task')) {
        //     // next(false)
        //     this.$router.push({name:from.name})
        //   }
        // }
      }
    },
    // beforeRouteUpdate(to, from, next) {
    //   alert(to.name);
    //   next();
    // },

  }
</script>
