<template>
  <transition name="fade">
    <div class="wrap-transition" v-if="show">
    <div class="filter" v-on:click="hideMenu"></div>
    <div class="wrap" :class="{'show-menu': show}">
      <div class="top-block svg-white-dart">
        <button class="close-menu fz14 fw500 text-dark" v-on:click="hideMenu">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
            <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
          </svg>
          Filter
        </button>
        <button class="clear text-red fz13 blue-button-text" v-on:click="clearAll(false)">Clear all</button>
      </div>
<!--      {{routesDetails}}-->
      <div class="block-period-date block-year position-relative">
        <button class="blue-button-text fz13" v-on:click="clearPeriodDate()">Clear</button>
        <div class="block-pre-dropdown " v-on:click="hidePeriodDate = !hidePeriodDate">
          <p class="fz13 fw500 text-dark">Period</p>
          <button class="button-dropdown">
            <svg :class="{rotate180: hidePeriodDate}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
            </svg>
          </button>
        </div>
        <div class="content" :class="{'hide-content': hidePeriodDate}">
          <date-picker
              placeholder="Choose period"
              type="date"
              format="DD.MM.YYYY"
              v-model="multipleDate"
              :range="true"
              range-separator="-"
              @change="changePeriod()"
          ></date-picker>
        </div>
      </div>
      <div class="block-year">
        <div class="block-pre-dropdown" v-on:click="hideYear = !hideYear">
          <p class="fz13 fw500 text-dark">Year</p>
          <button class="button-dropdown">
            <svg :class="{rotate180: hideYear}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
            </svg>
          </button>
        </div>
        <div class="content" :class="{'hide-content': hideYear}">
          <button v-for="(y, i) in year" :key="i" class="button-select fz13 text-dark" :class="{'button-active': y.status}" v-on:click="changeYear(i)">{{y.name}}</button>
          <div class="content-buttons">
            <button class="blue-button-text fz13"></button>
            <button class="blue-button-text fz13" v-on:click="clearAllInBlock(year)">Clear</button>
          </div>
        </div>
      </div>
      <div class="block-month">
        <div class="block-pre-dropdown" v-on:click="hideMonth = !hideMonth">
          <p class="fz13 fw500 text-dark">Month</p>
          <button class="button-dropdown">
            <svg :class="{rotate180: hideMonth}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
            </svg>
          </button>
        </div>
        <div class="content" :class="{'hide-content': hideMonth}">
          <button v-for="(m, i) in month" :key="i" class="button-select fz13 text-dark" :class="{'button-active': m.status}" v-on:click="changeMonth(i)">{{m.name}}</button>
          <div class="content-buttons">
            <button class="blue-button-text fz13" v-on:click="selectAllInBlock(month)">Select all</button>
            <button class="blue-button-text fz13" v-on:click="clearAllInBlock(month)">Clear</button>
          </div>
        </div>
      </div>
<!--      {{routes}}-->
      <div class="block-period">
        <div class="block-pre-dropdown" v-on:click="hidePeriod = !hidePeriod">
          <p class="fz13 fw500 text-dark">Period</p>
          <button class="button-dropdown">
            <svg :class="{rotate180: hidePeriod}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
            </svg>
          </button>
        </div>
        <div class="content" :class="{'hide-content': hidePeriod}">
          <p class="fz13">Enter days manually</p>
          <div class="block-input">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84211 3.03516C9.21998 3.03516 9.52632 3.33949 9.52632 3.71489V4.86522H14.4737V3.71489C14.4737 3.33949 14.78 3.03516 15.1579 3.03516C15.5358 3.03516 15.8421 3.33949 15.8421 3.71489V4.86522H16.2105C17.7511 4.86522 19 6.10595 19 7.63646V16.2639C19 17.7944 17.7511 19.0352 16.2105 19.0352H7.78947C6.24889 19.0352 5 17.7944 5 16.2639V7.63646C5 6.10595 6.24889 4.86522 7.78947 4.86522H8.15789V3.71489C8.15789 3.33949 8.46423 3.03516 8.84211 3.03516ZM8.15789 6.2247H7.78947C7.00465 6.2247 6.36842 6.85677 6.36842 7.63646V9.8979H17.6316V7.63646C17.6316 6.85677 16.9954 6.2247 16.2105 6.2247H15.8421V7.37502C15.8421 7.75043 15.5358 8.05476 15.1579 8.05476C14.78 8.05476 14.4737 7.75043 14.4737 7.37502V6.2247H9.52632V7.37502C9.52632 7.75043 9.21998 8.05476 8.84211 8.05476C8.46423 8.05476 8.15789 7.75043 8.15789 7.37502V6.2247ZM17.6316 11.2574H6.36842V16.2639C6.36842 17.0436 7.00465 17.6757 7.78947 17.6757H16.2105C16.9954 17.6757 17.6316 17.0436 17.6316 16.2639V11.2574Z" fill="#9AA3B0"/>
            </svg>
            <input type="text" class="h32 fz13" maxlength="2" placeholder="From" v-model="period.from" @input="changeInput1" @focusout="checkInput1">
            <input type="text" class="h32 fz13" maxlength="2" placeholder="To" v-model="period.to" @input="changeInput2" @focusout="checkInput2">
          </div>
          <div class="content-buttons">
          </div>
        </div>
      </div>
      <div class="block-routes">
        <div class="block-pre-dropdown" v-on:click="hideRoutes = !hideRoutes">
          <p class="fz13 fw500 text-dark">Routes</p>
          <button class="button-dropdown">
            <svg :class="{rotate180: hideRoutes}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
            </svg>
          </button>
        </div>
        <div class="content" :class="{'hide-content': hideRoutes}">
          <button v-for="(r, i) in routesForShip" :key="i+'2323ss'" class="button-select" :class="{'button-active': r.status}" v-on:click="changeRoute(i)" :title="r.reduction"><p class=" fz13 text-dark">{{r.reduction}}</p></button>
          <div class="content-buttons">
<!--            <button class="blue-button-text fz13" :class="{'average-active': averageActived}" v-on:click="averageAll">Average all</button>-->
            <button class="blue-button-text fz13" v-on:click="selectAllInBlockRoutes"></button>

            <button class="blue-button-text fz13" v-on:click="clearAllInBlockRoutes">Clear</button>
          </div>
          <div class="block-route-details">
            <div class="block-pre-dropdown">
              <button class="fz13 text-dark fw500 button-dropdown" v-on:click="hideRoutesDetails = !hideRoutesDetails">
                Route details
                <svg :class="{rotate180: hideRoutesDetails}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.27573 7.60001C9.54301 7.60001 9.67686 7.27686 9.48787 7.08787L6.21212 3.81214C6.09496 3.69498 5.90502 3.69498 5.78786 3.81214L2.51213 7.08787C2.32314 7.27686 2.45699 7.60001 2.72426 7.60001L9.27573 7.60001Z" fill="#2C2C2C"/>
                </svg>
              </button>
              <button class="button-text-gray fz13" v-on:click="clickEditOrAddRoutes">
                Edit or add routes
              </button>
            </div>
            <div class="content" :class="{'hide-content': hideRoutesDetails}">
              <p class="fz13 text-dark-gray" v-for="(d,i) in routesDetailsForShip" :key="i" :title="d.text">{{d.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>

  </transition>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import DatePicker from 'vue2-datepicker'
import moment from 'moment';


export default {
  computed: mapGetters(["getFilterPeriod", "getYearForFilter", "getRoutes", "getFilterYear", "getFilterMonth", "getFilterRoutes", "getShipId"]),
  components: {DatePicker},
  name: "filterBDI",
  props: {
    panamaxSelected: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hidePeriodDate: false,
      hideYear: false,
      hideMonth: false,
      hidePeriod: false,
      hideRoutes: false,
      show: false,
      hideRoutesDetails: false,
      period: {
        from: "",
        to: "",
      },
      averageActived: true,
      year: [
        // {name: "2003", status: false},
        // {name: "2004", status: false},
        // {name: "2005", status: false},
        // {name: "2006", status: false},
        // {name: "2007", status: false},
        // {name: "2008", status: false},
        // {name: "2009", status: false},
        // {name: "2010", status: false},
        // {name: "2011", status: false},
        // {name: "2012", status: false},
        // {name: "2013", status: false},
        // {name: "2014", status: false},
        // {name: "2015", status: false},
        // {name: "2016", status: false},
        // {name: "2017", status: false},
        // {name: "2018", status: false},
        // {name: "2019", status: false},
        // {name: "2020", status: false},
      ],
      month: [
        {name: "jan", value: "01",status: false},
        {name: "feb", value: "02",status: false},
        {name: "mar", value: "03",status: false},
        {name: "apr", value: "04",status: false},
        {name: "may", value: "05",status: false},
        {name: "jun", value: "06",status: false},
        {name: "jul", value: "07",status: false},
        {name: "aug", value: "08",status: false},
        {name: "sep", value: "09",status: false},
        {name: "oct", value: "10",status: false},
        {name: "nov", value: "11",status: false},
        {name: "dec", value: "12",status: false},
      ],
      routesForShip: [],
      routes: [
        // {name: "C9_14", status: false},
        // {name: "c10_03", status: false},
        // {name: "c2", status: false},
        // {name: "c3", status: false},
        // {name: "c4", status: false},
        // {name: "c5", status: false},
        // {name: "c7", status: false},
        // {name: "c8-03", status: false},
        // {name: "c9_03", status: false},
        // {name: "c10_03", status: false},
        // {name: "c11_03", status: false},
        // {name: "c2", status: false},
        // {name: "avg3", status: false},
        // {name: "c4", status: false},
        // {name: "c5", status: false},
        // {name: "c6", status: false},
        // {name: "c7", status: false},
        // {name: "c2", status: false},
        // {name: "c2", status: false},
      ],
      routesDetails: [
        // {text: "C2: Tubarao - Rotterdam"},
        // {text: "C3: Tubarao - Qingdao"},
        // {text: "C4: Richards Bay - Rotterdam"},
        // {text: "C5: W Australia - Qingdao"},
        // {text: "C7: Bolivar - Rotterdam"},
        // {text: "C8_07: Gibraltar/Hamburg trans Atlantic RV"},
        // {text: "C9_03: Continent/Mediterranean trip Far East Continent/Mediterranean trip Far East"},
        // {text: "C10_03: Pacific RV"},
        // {text: "C11_03: China/Japan trip Mediterranean/Cont"},
      ],
      routesDetailsForShip: [],
      // ship_id: "",
      multipleDate: ["", ""],
    }
  },
  methods: {
    ...mapActions(["axiosGetYearsForFilter", "axiosGetRoutes"]),
    ...mapMutations(["setFilterPeriod", "setFilterYear", "setFilterMonth", "setFilterRoutes", "setShipId", "setCountRoutersForChoosedBlock"]),
    changePeriod() {
      this.clearAll(true);
      this.createEmitChangeParametersFilter();
    },
    clearPeriodDate() {
      if(this.multipleDate.length !== 0 && this.multipleDate[0].length !== 0 && this.multipleDate[1].length !== 0) {
        this.multipleDate = ["", ""];
        this.createEmitChangeParametersFilter();
      }
    },
    changeYear(index) {
      this.multipleDate = ["", ""];
      this.year[index].status = !this.year[index].status;
      this.createEmitChangeParametersFilter();
      let k=0;
      for(let i=0; i< this.month.length; i++) {
        if(this.month[i].status === true) {
          k++;
        }
      }
      if(k===0) {
        this.selectAllInBlock(this.month);
      }
    },
    changeMonth(index) {
      this.multipleDate = ["", ""];
      this.month[index].status = !this.month[index].status;
      this.createEmitChangeParametersFilter();
    },
    changeRoute(index) {
      if(this.averageActived) {
        this.averageActived = false;
      }
      // this.routesForShip[index].status = !this.routesForShip[index].status;
      // this.routes[this.routesForShip[index].index].status = !this.routes[this.routesForShip[index].index].status;
      this.clearAllInBlockRoutes();
      this.routesForShip[index].status = !this.routesForShip[index].status;
      this.routes[this.routesForShip[index].index].status = !this.routes[this.routesForShip[index].index].status;
      this.createEmitChangeParametersFilter();
    },
    changeRouteIndex(index) {
      for(let i=0; i<this.routesForShip.length; i++) {
        if(this.routesForShip[i].index === index) {
          this.routesForShip[i].status = !this.routesForShip[i].status;
          this.routes[this.routesForShip[i].index].status = !this.routes[this.routesForShip[i].index].status;
        }
      }
      this.createEmitChangeParametersFilter();
    },
    changeInput1() {
      this.period.from = this.period.from.replace(/\D/, '');
      if(this.period.from === 0) {
        this.period.from = 1;
      }
      if(this.period.from > 31) {
        this.period.from = 31;
      }
      // this.createEmitChangeParametersFilter();
    },
    changeInput2() {
      this.multipleDate = ["", ""];
      this.period.to = this.period.to.replace(/\D/, '');
      if(this.period.to === 0) {
        this.period.to = 1;
      }
      if(this.period.to > 31) {
        this.period.to = 31;
      }
      // this.createEmitChangeParametersFilter();
    },
    checkInput2() {
      this.multipleDate = ["", ""];
      if(this.period.from.length !==0 && this.period.to.length === 0) {
        this.period.to = 31;
      }
      if(Number(this.period.from) > Number(this.period.to)) {
        let n = this.period.from;
        this.period.from = this.period.to;
        this.period.to = n;
      }
      this.createEmitChangeParametersFilter();
    },
    checkInput1() {
      if(this.period.to.length !==0 && this.period.from.length === 0) {
        this.period.from = 1;
      }
      if(Number(this.period.from) > Number(this.period.to)) {
        let n = this.period.from;
        this.period.from = this.period.to;
        this.period.to = n;
      }
      this.createEmitChangeParametersFilter();
    },
    showMenu() {
      this.show = true;
    },
    hideMenu() {
      this.show = false;
    },
    clearAll(notClearRoutes) {
      for(let i=0; i<this.year.length; i++) {
        this.year[i].status = false
      }
      for(let i=0; i<this.month.length; i++) {
        this.month[i].status = false
      }
      if(notClearRoutes !== true) {
        for(let i=0; i<this.routes.length; i++) {
          this.routes[i].status = false
        }
      }
      for(let i=0; i<this.routesForShip.length; i++) {
        this.routesForShip[i].status = false;
        this.routes[this.routesForShip[i].index].status = false;
      }

      this.period.from = "";
      this.period.to = "";
      this.averageActived = true;
      this.createEmitChangeParametersFilter();
    },
    selectAllInBlock(array) {
      for(let i=0; i < array.length; i++) {
        array[i].status = true;
      }
      this.createEmitChangeParametersFilter();
    },
    selectAllInBlockRoutes() {
      for(let i=0; i<this.routesForShip.length; i++) {
        this.routesForShip[i].status = true;
        this.routes[this.routesForShip[i].index].status = true;
      }
      this.createEmitChangeParametersFilter();
    },
    clearAllInBlock(array) {
      for(let i=0; i < array.length; i++) {
        array[i].status = false;
      }
      if(array === this.routes) {
        this.averageActived = true;
      }
      this.createEmitChangeParametersFilter();
    },
    clearAllInBlockRoutes() {
      for(let i=0; i<this.routesForShip.length; i++) {
        this.routesForShip[i].status = false;
        this.routes[this.routesForShip[i].index].status = false;
      }
      this.createEmitChangeParametersFilter();
    },
    averageAll() {
      this.averageActived = !this.averageActived;
      for(let i=0; i < this.routes.length; i++) {
        this.routes[i].status = false;
      }
      this.createEmitChangeParametersFilter();
    },
    clickEditOrAddRoutes() {
      this.$emit("clickEditOrAddRoutes", this.routesDetails);
    },
    setFilterForPanamax() {
      this.multipleDate = [new Date(moment(new Date).add(-5, 'month').format('YYYY-MM-01')), new Date(moment(new Date()).endOf('month').format('YYYY-MM-DD'))]
      // let boolYear = false;
      // this.year.forEach((year)=> {
      //   year.status = false;
      //   if(''+year.name === ''+moment(new Date).format('YYYY')) {
      //     boolYear = true;
      //     year.status = true;
      //   }
      // })
      // if(!boolYear) {
      //   this.year[this.year.length - 1].status = true
      // }
      // let month = [];
      // for(let i=0; i<6; i++) {
      //   month.push((''+moment(new Date()).add(-i, 'month').format('MMM')).toLowerCase())
      // }
      // this.month.forEach(item=> {
      //   if(month.indexOf(item.name) >= 0) {
      //     item.status = true;
      //   }
      // })
      this.clearAll(true);
      let bool = false;
      this.routes.forEach(item=> {
        item.status = false;
        if(item.reduction === 'P5TC 82') {
          bool = true;
          item.status = true;
        }
      })
      this.routesForShip.forEach(item=> {
        item.status = false;
        if(item.reduction === 'P5TC 82') {
          bool = true;
          item.status = true;
        }
      })
      if(!bool) {
        console.log(1)
        this.routes[0].status = true;
      }
      this.createEmitChangeParametersFilter();
    },
    changeRoutesRenderNew() {
      this.period = {
        from: "1",
        to: "31",
      }
      this.routes = [];
      this.routes = this.getRoutes;
      this.routesDetails.splice(0, this.routesDetails.length);
      let count = 0
      if(!this.panamaxSelected) {
        if(this.getFilterRoutes.length !==0) {
          for(let i=0; i<this.routes.length; i++) {
            for(let j=0; j<this.getFilterRoutes.length; j++) {
              if(this.routes[i].id === this.getFilterRoutes[j].id) {
                this.routes[i].status = this.getFilterRoutes[j].status;
                if(this.getFilterRoutes[j].status) {
                  count++;
                }
              }
            }
          }
        }
      } else {
        count++
        this.setFilterForPanamax()
      }


      // this.createEmitChangeParametersFilter();
      for(let i=0; i<this.routes.length; i++) {
        let text = this.routes[i].reduction + ": No Data";
        // if(this.routes[i].route_to.length !== 0 && this.routes[i].route_from.length !== 0) {
        //   text = this.routes[i].reduction + ": " + this.routes[i].route_from + " - " + this.routes[i].route_to;
        // }
        if(this.routes[i].route.length !==0) {
          text = this.routes[i].reduction + ": " + this.routes[i].route;
        }
        let form = {
          text: text,
          id: this.routes[i].id,
          abbreviation: this.routes[i].reduction,
          ship_type: this.routes[i].ship_type,
          sorting_index: this.routes[i].sorting_index,
          // from: this.routes[i].route_from,
          // to: this.routes[i].route_to,
          route: this.routes[i].route,
        }
        this.routesDetails.push(form);
      }
      // this.$emit("clickEditOrAddRoutes", this.routesDetails);
      // this.setRoutesForShip();
      this.routesDetailsForShip = [];
      for(let i=0; i<this.routesDetails.length; i++) {
        if((""+this.routesDetails[i].ship_type) === (""+this.getShipId)) {
          this.routesDetailsForShip.push(JSON.parse(JSON.stringify(this.routesDetails[i])));
        }
      }
      this.routesForShip = [];
      for(let i=0; i<this.routes.length; i++) {
        if((""+this.routes[i].ship_type) === (""+this.getShipId)) {
          let formRoutes= {
            id: this.routes[i].id,
            reduction: this.routes[i].reduction,
            // route_to: this.routes[i].route_to,
            // route_from: this.routes[i].route_from,
            sorting_index: this.routes[i].sorting_index,
            route: this.routes[i].route,
            ship_type: this.routes[i].ship_type,
            status: this.routes[i].status,
            index: i,
          }
          this.routesForShip.push(formRoutes);
        }
      }
      if(count === 0) {
        this.routesForShip[0].status = !this.routesForShip[0].status;
        this.routes[this.routesForShip[0].index].status = !this.routes[this.routesForShip[0].index].status;
      }
      // this.createEmitChangeParametersFilter();
    },
    deleteDate() {
      this.period.from = 1;
      this.period.to = 31;
      this.createEmitChangeParametersFilter();
    },
    deleteAllMonth() {
      this.clearAllInBlock(this.month);
    },
    deleteAllRoutes() {
      this.clearAllInBlockRoutes();
    },
    createEmitChangeParametersFilter() {
      let ranges = [];
      let routes = [];
      if(this.multipleDate.length !== 0 && this.multipleDate[0].length !== 0 && this.multipleDate[1].length !== 0) {
        if(this.period.from.length === 0) {
          this.period.from = 1;
        }
        if(this.period.to.length === 0) {
          this.period.to = 31;
        }
      }
      let YearsFilter= [];
      let MonthFilter=[];
        for(let i=0; i<this.year.length; i++) {
          if(this.year[i].status === true) {
            let form1 = {
              name: this.year[i].name,
              index: i,
            }
            YearsFilter.push(form1);

          }
        }
      for(let i=0; i<this.month.length; i++) {
        if(this.month[i].status === true) {
          let form1 = {
            name: this.month[i].name,
            index: i,
          }
          MonthFilter.push(form1);
        }
      }
      for(let i=0; i<this.year.length; i++) {
        if(this.year[i].status === true) {
          for(let j=0; j<this.month.length; j++) {
            // for(let j=0; j<this.month.length; j++) {
            //   if(this.month[j].status === true) {
            //     let y = this.year[i].name;
            //     let m = this.month[j].value;
            //     let days = new Date(y, m, 0).getDate();
            //     if(days<this.period.to) {
            //       this.period.to = days;
            //     }
            //     if(days<this.period.from) {
            //       this.period.from = days;
            //     }
              // }
            // }
            if(this.month[j].status === true) {
              let fromDay = this.period.from;
              let toDay = this.period.to;
              let y = this.year[i].name;
              let m = this.month[j].value;
              let days = new Date(y, m, 0).getDate();
              if(days<this.period.to) {
                toDay = days;
              }
              if(days<this.period.from) {
                fromDay = days;
              }

              if(fromDay < 10) {
                fromDay = "0" + fromDay;
              }
              if(toDay < 10) {
                toDay = "0" + toDay;
              }
              let formDate = {
                date_from: this.year[i].name + "-" + this.month[j].value + "-" + fromDay + " 00:00:00",
                date_to: this.year[i].name + "-" + this.month[j].value + "-" + toDay + " 23:59:59",
              }
              ranges.push(formDate);
            }
          }
        }
      }
      let RoutesFilter = [];
      for(let i=0; i<this.routes.length; i++) {
        // if(!this.averageActived) {
          if(this.routes[i].status === true) {
            routes.push(this.routes[i].id);
            let form = {
              name: this.routes[i].reduction,
              index: i,
            }
            RoutesFilter.push(form);
          }
        // } else {
        //   routes.push(this.routes[i].id);
        // }
      }
      let fromPeriod = "";
      let toPeriod = "";
      if(""+this.period.from !== "1" ) {
        fromPeriod = this.period.from;
        toPeriod = this.period.to;
      }
      if(""+this.period.to !== "31") {
        fromPeriod = this.period.from;
        toPeriod = this.period.to;
      }
      let monthAll = false;
      if(MonthFilter.length === 12) {
        monthAll = true;
        MonthFilter = [];
      }
      let routesAll = false;
      if(RoutesFilter.length === this.routesForShip.length) {
        routesAll = true;
        RoutesFilter = [];
      }
      if(this.multipleDate.length !== 0 && this.multipleDate[0].length !== 0 && this.multipleDate[1].length !== 0) {
        ranges = [{date_from: moment(this.multipleDate[0]).format('YYYY-MM-DD 00:00:00'), date_to: moment(this.multipleDate[1]).format('YYYY-MM-DD 23:59:59')}]
      }
      this.$emit("changeParametersFilter", {periodDate: this.multipleDate, ranges: ranges, routes: routes, routesFilter: RoutesFilter, yearFilter: YearsFilter, monthFilter: MonthFilter, average: false, period: {from: fromPeriod, to: toPeriod}, monthAll: monthAll, routesAll: routesAll});
    },
    setVuexStore() {
      this.setFilterYear(this.year);
      this.setFilterMonth(this.month);
      this.setFilterRoutes(this.routesForShip);
      this.setFilterPeriod(this.multipleDate);
      // this.setShipId(this.ship_id)
      // alert(2322);
    },
    setRoutesForShip() {
      this.routesDetailsForShip = [];
      for(let i=0; i<this.routesDetails.length; i++) {
        if((""+this.routesDetails[i].ship_type) === (""+this.getShipId)) {
          this.routesDetailsForShip.push(JSON.parse(JSON.stringify(this.routesDetails[i])));
        }
      }
      this.routesForShip = [];
      for(let i=0; i<this.routes.length; i++) {
        if((""+this.routes[i].ship_type) === (""+this.getShipId)) {
          let formRoutes= {
            id: this.routes[i].id,
            reduction: this.routes[i].reduction,
            sorting_index: this.routes[i].sorting_index,
            route: this.routes[i].route,
            ship_type: this.routes[i].ship_type,
            status: this.routes[i].status,
            index: i,
          }
          this.routesForShip.push(formRoutes);
        }
      }
      this.setCountRoutersForChoosedBlock(this.routesForShip.length)
      this.clearAllInBlock(this.routes);
      // this.selectAllInBlockRoutes();
      if(this.routesForShip.length !== 0 && this.routes.length !== 0) {
        this.routesForShip[0].status = !this.routesForShip[0].status;
        this.routes[this.routesForShip[0].index].status = !this.routes[this.routesForShip[0].index].status;
      }
      this.createEmitChangeParametersFilter();
    },
    setRoutesForShipMount() {
      if(this.getFilterRoutes.length !== 0) {
        this.routesDetailsForShip = [];
        for(let i=0; i<this.routesDetails.length; i++) {
          if((""+this.routesDetails[i].ship_type) === (""+this.getShipId)) {
            this.routesDetailsForShip.push(JSON.parse(JSON.stringify(this.routesDetails[i])));
          }
        }
        this.routesForShip = [];
        for(let i=0; i<this.routes.length; i++) {
          if((""+this.routes[i].ship_type) === (""+this.getShipId)) {
            let formRoutes= {
              id: this.routes[i].id,
              reduction: this.routes[i].reduction,
              // route_to: this.routes[i].route_to,
              // route_from: this.routes[i].route_from,
              sorting_index: this.routes[i].sorting_index,
              route: this.routes[i].route,
              ship_type: this.routes[i].ship_type,
              status: this.routes[i].status,
              index: i,
            }
            this.routesForShip.push(formRoutes);
          }
        }
        this.createEmitChangeParametersFilter();
      } else {
        for(let i=0; i<this.routes.length; i++) {
          for(let j=0; j<this.getFilterRoutes.length; j++) {
            if(this.routes[i].id === this.getFilterRoutes[j].id) {
              this.routes[i].status = this.getFilterRoutes[j].status;
            }
          }
        }
        this.routesDetailsForShip = [];
        for(let i=0; i<this.routesDetails.length; i++) {
          if((""+this.routesDetails[i].ship_type) === (""+this.getShipId)) {
            this.routesDetailsForShip.push(JSON.parse(JSON.stringify(this.routesDetails[i])));
          }
        }
        this.routesForShip = [];
        for(let i=0; i<this.routes.length; i++) {
          if((""+this.routes[i].ship_type) === (""+this.getShipId)) {
            let formRoutes= {
              id: this.routes[i].id,
              reduction: this.routes[i].reduction,
              sorting_index: this.routes[i].sorting_index,
              route: this.routes[i].route,
              ship_type: this.routes[i].ship_type,
              status: this.routes[i].status,
              index: i,
            }
            this.routesForShip.push(formRoutes);
          }
        }
        this.setCountRoutersForChoosedBlock(this.routesForShip.length)
        // this.selectAllInBlockRoutes();
        this.createEmitChangeParametersFilter();
      }
    }
  },
  mounted() {
    this.year = this.getFilterYear;
    this.multipleDate = (this.getFilterPeriod.length !== 0 && this.getFilterPeriod[0].length !== 0 && this.getFilterPeriod[1].length !== 0) ? [new Date(this.getFilterPeriod[0]), new Date(this.getFilterPeriod[1])] : ["", ""]
    console.log(this.year)

    // vm.setVuexStore()
    // this.createEmitChangeParametersFilter();
    this.axiosGetYearsForFilter().then(()=> {
      if(!this.panamaxSelected) {
        if(this.getFilterYear.length === 0) {
          this.year = this.getYearForFilter;
          this.changeYear(this.year.length-1);
          // this.createEmitChangeParametersFilter();
        } else {
          this.year = this.getYearForFilter;
          for(let i=0; i<this.year.length; i++) {
            for(let j=0; j<this.getFilterYear.length; j++) {
              if(this.year[i].name === this.getFilterYear[j].name) {
                this.year[i].status = this.getFilterYear[j].status;
              }
            }
          }
        }
      } else {
        this.setFilterForPanamax();
      }

    });
    if(!this.panamaxSelected) {
      if(this.getFilterMonth.length === 0) {
        this.selectAllInBlock(this.month);
      } else {
        for(let i=0; i<this.month.length; i++) {
          this.month[i].status = this.getFilterMonth[i].status;
        }
        // alert(1);
        // this.createEmitChangeParametersFilter();
      }
    }
    let vm =this;
    window.onbeforeunload = function() {
      vm.setVuexStore();
    };
  },
}
</script>

<style scoped lang="scss">
  @import "filterBDI";
  .block-period-date {
    .blue-button-text {
      position: absolute;
      right: 49px;
      top: 12px;
    }
    .content {
      padding-bottom: 16px;
      /deep/ {
        i {
          display: none !important;
        }
        .mx-input {
          background-color: var(--color-input);
        }
      }
    }
    .hide-content {
      padding-bottom: 0 !important;
      &>* {
        display: none !important;
      }
    }
  }
</style>