<template>
  <Transition name="fade">
    <div class="vue-modal" v-if="visibility">
      <div class="modal-container">
        <div class="vue-modal__modal-header">
          <span class="header-name">Signature Settings</span>
          <span class="modal-close" @click="hideModal">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2486 5.03572C19.5415 5.32861 19.5415 5.80348 19.2486 6.09638L13.2032 12.1417L19.2486 18.1871C19.5415 18.48 19.5415 18.9549 19.2486 19.2478C18.9557 19.5407 18.4808 19.5407 18.1879 19.2478L12.1426 13.2024L6.09721 19.2478C5.80432 19.5407 5.32944 19.5407 5.03655 19.2478C4.74366 18.9549 4.74366 18.48 5.03655 18.1871L11.0819 12.1417L5.03655 6.09637C4.74366 5.80348 4.74366 5.32861 5.03655 5.03571C5.32944 4.74282 5.80432 4.74282 6.09721 5.03571L12.1426 11.0811L18.1879 5.03572C18.4808 4.74282 18.9557 4.74282 19.2486 5.03572Z"
                fill="#262626"
              />
            </svg>
          </span>
        </div>
        <div class="vue-modal__modal-content">
          <div class="empty-block" v-if="signaturesList.length == 0">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M64.4161 24.905V11.7725C64.4161 8.58444 61.8316 6 58.6436 6H14.7725C11.5844 6 9 8.58444 9 11.7725V68.0544C9 71.2425 11.5844 73.8269 14.7725 73.8269H58.6436C61.8316 73.8269 64.4161 71.2425 64.4161 68.0544V54.7777" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
              <path d="M70.0963 40.9227L75.6612 35.3578C77.5053 33.5137 77.5053 30.524 75.6612 28.6799C73.8172 26.8359 70.8274 26.8359 68.9833 28.6799L63.4184 34.2448M70.0963 40.9227L51.2993 59.7196L40.0459 64.2952L44.6215 53.0418L63.4184 34.2448M70.0963 40.9227L63.4184 34.2448" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.4102 18.125H51.8601" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
              <path d="M21.4102 27.3594H51.8601" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
              <path d="M21.4102 36.5938H51.8601" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
              <path d="M21.4102 45.8281H42.7684" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
              <path d="M17.6592 64.4513H21.1227L24.5862 58.1016L27.6168 64.4513H33.8222" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="text">No signatures have been created yet</span>
            <button class="create-signature" @click="createSignature">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.2221 11.2222H12.7778V5.77773C12.7778 5.34849 12.4293 5 11.9999 5C11.5707 5 11.2222 5.34849 11.2222 5.77773V11.2222H5.77773C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77773 12.7778H11.2222V18.2221C11.2222 18.6515 11.5707 19 11.9999 19C12.4293 19 12.7778 18.6515 12.7778 18.2221V12.7778H18.2221C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2221 11.2222Z" fill="#094172"/>
              </svg>
              Create Signature
            </button>

          </div>
          <div class="content-block" v-else>
            <div class="left-col">
              <div 
                class="signature-item" 
                :class="{
                  active: checkedSignature.adminId == signature.adminId,
                  error: $v.$dirty && $v.signaturesList.$each.$iter[signature.validatorIndex].$error
                }" 
                v-for="signature in signaturesList" 
                :key="signature.adminId" 
                @click="checkSignature(signature)"
                >
                <input placeholder="Empty signature" class="signature-name" type="text" disabled v-model="signature.content">
                <!-- <span>{{signature.content}}</span> -->
                <svg class="check-icn" @click.stop="delSignature(signature.adminId)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
                </svg>
              </div>
              
            </div>
            <div class="right-col">
              <div class="right-col__item">
                <span class="name">Top signature</span>
                <div class="textarea-wrapper" @click="focusInput($event)">
                  <div 
                    class="input-item" 
                    :class="{'show-time': checkedSignature.top_content && checkedSignature.top_content != ''}"
                    v-contenteditable-model="topContentText"
                    contenteditable
                    @focus="$event.target.closest('.textarea-wrapper').classList.add('focus')"
                    @blur="blurInput($event)"
                  ></div>
                </div>
              </div>
              <div class="right-col__item">
                <span class="name">Bottom signature</span>
                <textarea 
                  class="signature-textarea"
                  :class="{error: $v.$dirty && $v.signaturesList.$each.$iter[checkedSignature.validatorIndex].$error}" 
                  placeholder="Enter signature text" 
                  v-model="checkedSignature.content"
                ></textarea>
                <span class="error-text" v-if="$v.$dirty && $v.signaturesList.$each.$iter[checkedSignature.validatorIndex].$error" >Field is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vue-modal__modal-footer">
          <button class="modal-button modal-button_create" @click="createSignature" :class="{disabled: disabledCreateBtn}" v-if="signaturesList.length != 0">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2221 11.2222H12.7778V5.77773C12.7778 5.34849 12.4293 5 11.9999 5C11.5707 5 11.2222 5.34849 11.2222 5.77773V11.2222H5.77773C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77773 12.7778H11.2222V18.2221C11.2222 18.6515 11.5707 19 11.9999 19C12.4293 19 12.7778 18.6515 12.7778 18.2221V12.7778H18.2221C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2221 11.2222Z" fill="#094172"/>
            </svg>
            Create Signature
          </button>
          <button class="modal-button" @click="hideModal">Cancel</button>
          <button class="modal-button modal-button_apply" @click="apply" :class="{disabled: savedForm}">Save</button>
        </div>
      </div>
      <WarningModal 
        class="signature-warning-popup"
        ref="warningModal"
        :text="'Are you sure you want to close the popup? Changes will not be saved?'"
        :title="'Changes not saved'"
        :applyText="'Close popup'"
        :cancelText="'Back to Signature Settings'"
        @apply="clearForm"
      ></WarningModal>
    </div>
  </Transition>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import { mapActions, mapMutations } from "vuex";
  import WarningModal from "@/components/mailbox/WarningModal"
  export default {
    name: 'signatureSettings-modal',
    mixins: [validationMixin],
    components: {WarningModal},
    data: () => ({
      visibility: false,
      signaturesList: [],
      disabledCreateBtn: false,
      savedForm: true,
      // topContentText: '',
      checkedSignature: {
        content: '',
        adminId: null,
        validatorIndex: null,
        top_content: null
      }
    }),
    validations(){
      return {
        signaturesList: {
          $each: {
            content: {required}
          }
        }
      }
      
    },
    methods: {
      ...mapMutations(["showLoad", "hideLoad"]),
      ...mapActions(['axiosUpdateMailboxSignature']),
      showModal(signatures = []){
        this.signaturesList = JSON.parse(JSON.stringify(signatures))
        if(this.signaturesList.length != 0){
          let adminId = Date.parse(new Date)
          this.signaturesList.forEach((el, index) => {
            el.adminId = adminId++
            el.validatorIndex = index  // for validator
          })
          this.checkSignature(this.signaturesList[0])
        }
        this.unwatch = this.$watch(
          "signaturesList",
          function () {
            this.savedForm = false;
          },
          { deep: true }
        );
        this.visibility = true
      },
      hideModal(){
        if(this.savedForm){
          this.visibility = false
          if(this.unwatch) this.unwatch()
          this.$v.$reset()
        }else{
          this.$refs.warningModal.showModal()
        }
      },
      clearForm(){
        this.signaturesList = []
        this.savedForm = true;
        this.checkedSignature = {
          content: '',
          adminId: null,
          validatorIndex: null
        }
        if(this.unwatch) this.unwatch()
        this.$v.$reset()
        this.visibility = false
      },
      apply(){
        this.$v.$touch();
        if (this.$v.$invalid) return false;
        this.showLoad()
        this.axiosUpdateMailboxSignature(this.signaturesList)
        .then(() => {
          this.savedForm = true;
          this.hideModal()
          this.hideLoad()
          this.$emit('updateSignatures')
        })
        .catch(err => {
          console.log('err::: ', err);
          this.$toast.open({
            message: 'Oops! Something went wrong :(',
            type: 'error',
            duration: 3000
          });
          this.hideLoad()
        })
      },
      checkSignature(signature){
        this.checkedSignature = signature
      },
      delSignature(id){
        this.signaturesList = this.signaturesList.filter(el => el.adminId != id)
        this.signaturesList.forEach((el, index) => {
          el.validatorIndex = index  // for validator
        })
        if(this.checkedSignature.adminId == id){
          this.checkedSignature = this.signaturesList[0]
          if(this.signaturesList.length != 0){
            this.checkedSignature = this.signaturesList[0]
          }else{
            this.checkedSignature = {
              content: '',
              adminId: null,
              validatorIndex: null
            }
          }
        }
      },
      createSignature(){
        this.disabledCreateBtn = true;
        setTimeout(() => {
          this.disabledCreateBtn = false;
        }, 500)
        let signature = {
          adminId: Date.parse(new Date),
          content: '',
          top_content: ''
        }
        this.signaturesList.push(signature)
        this.signaturesList.forEach((el, index) => {
          el.validatorIndex = index  // for validator
        })
        this.checkedSignature = signature
      },
      toggleTimePlaceholder(el){
        if(el.textContent != ''){
          el.classList.add('show-time')
          return
        }
        if(document.activeElement == el){
          el.classList.add('show-time')
          return
        }else{
          el.classList.remove('show-time')
        }

      },
      focusInput(e){
        if(e.target.classList.contains('input-item')){
          e.target.focus()
          this.toggleTimePlaceholder(e.target)
        }else{
          e.target.querySelector('.input-item').focus()
          this.toggleTimePlaceholder(e.target.querySelector('.input-item'))
        }
      },
      blurInput(e){
        e.target.closest('.textarea-wrapper').classList.remove('focus')
        this.toggleTimePlaceholder(e.target)
      }
    },
    directives: {
      'contenteditable-model': {
        bind: function(el, binding, vnode) {
          el.textContent = binding.value;
          el.addEventListener('keyup', function() {
            vnode.context[binding.expression] = el.textContent;
          });
        },
        update: function(el, binding) {
          if (el.textContent !== binding.value) {
            el.textContent = binding.value;
          }
        }
      }
    },
    computed: {
      topContentText: {
        get(){
          if(this.checkedSignature){
            return this.checkedSignature.top_content || ''
          }else{
            return ''
          }
        },
        set(val){
          this.checkedSignature.top_content = val
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.vue-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.3);
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: auto;
  }

  .modal-container {
    width: 680px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    // padding: 16px;
  }
}

.vue-modal__modal-header {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 48px;
  padding: 0 16px;
  border-bottom: 1px solid var(--color-gray-light);
  align-items: center;
  
  .header-name{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.vue-modal__modal-footer{
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid var(--color-gray-light);
  *{
    transition: .3s;
  }
  .modal-button{
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--color-blue-dark);
    border-radius: 4px;
    min-width: 180px;
    
    &:hover{
      color: var(--color-blue);
    }
    &_apply{
      background: var(--color-blue);
      color: var(--color-white);
      &:hover{
        color: var(--color-white);
        background: var(--color-blue-dark);
      }
    }
    &_create{
      margin-right: auto;
      border: 1px solid var(--color-blue-dark);
      &:hover{
        color: var(--color-white);
        background: var(--color-blue-dark);
        svg{
          path{
            fill: var(--color-white);
          }
        }
      }
    }
  }
}
.disabled{
  opacity: .5;
  pointer-events: none;
}
.vue-modal__modal-content{
  .text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .empty-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    *{
      transition: .3s;
    }
    .text{
      margin-top: 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .create-signature{
      margin-top: 24px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--color-blue-dark);
      border-radius: 4px;
      min-width: 180px;
      border: 1px solid var(--color-blue-dark);
      &:hover{
        color: var(--color-white);
        background: var(--color-blue-dark);
        svg{
          path{
            fill: var(--color-white);
          }
        }
      }
    }
  }
  .content-block{
    
    min-height: 400px;
    max-height: 400px;
    display: flex;
    .left-col,
    .right-col{
      width: 100%;
      max-width: 50%;
    }
    .right-col{
      padding: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .error-text{
        padding-top: 12px;
        font-size: 12px;
        color: var(--color-red);
      }
    }
    .left-col{
      border-right: 1px solid var(--color-gray-light);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
    .signature-item{
      width: 100%;
      height: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      border: 1px solid transparent;
      &:hover{
        cursor: pointer;
        background: var(--color-gray-lightest);
        .check-icn{
          display: flex;
        }
      }
      &.active{
        background: var(--color-blue-gray-list);

      }
      &.error{
        border-color: var(--color-red);
      }
      .check-icn{
        min-width: 24px;
        margin-left: auto;
        display: none;
      }
      // span{
      //   max-width: 100%;
      //   font-size: 14px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
        
      // }
      .signature-name{
        max-width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0;
        background: none;
        border: none;
        pointer-events: none;
      }
    }
    .signature-textarea{
      margin-top: 4px;
      height: 140px;
      -webkit-text-fill-color: inherit;
      &.error{
        border-color: var(--color-red);
      }
    }
    .textarea-wrapper{
      margin-top: 4px;
      height: 140px;
      background: var(--color-input);
      border: 1px solid var(--color-border);
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      padding: 10px 12px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      &:hover{
        border-color: var(--color-gray);
      }
      &.focus{
        border-color: var(--color-blue-dark);
      }
    }
    .input-item{
      font-size: 14px;
      line-height: 17px;
      color: var(--color-dark);
      width: fit-content;
      max-width: 100%;
      &.show-time{
        &::after{
          content: ' [Current date and time]';
          color: var(--color-gray-text);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          pointer-events: none;
        }
      }
    }
    .right-col__item{
      .name{
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--color-gray-text);
      }
    }
  }
}
.modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    width: 16px;
    height: 16px;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>