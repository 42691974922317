<template>
  <div class="bread p-x-6 p-y-2" :class="{'is_border': allowBorder}">
    <template v-for="(link, i) in links">
      <span v-if="i >= links.length-1 || !link.link" class="bread__link" :key="link.link">{{link.name}}</span>
      <router-link v-else :to="link.link" class="bread__link" :key="link.link">{{link.name}}</router-link>

      <svg v-if="i < links.length-1" :key="link.link+'_sep'" class="bread__separator" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.29728 6.40332L5.18131 9.70563C4.91419 9.97749 4.48172 9.97703 4.21504 9.70422C3.94858 9.43145 3.94927 8.98957 4.21642 8.7175L6.84711 5.90934L4.21642 3.19208C3.9493 2.91997 3.94861 2.47838 4.21504 2.20557C4.34872 2.06881 4.52386 2.00043 4.69899 2.00043C4.87367 2.00043 5.04811 2.06835 5.18155 2.20416L8.29728 5.4154C8.42594 5.54611 8.49813 5.724 8.49813 5.90934C8.49813 6.09468 8.42573 6.27236 8.29728 6.40332Z" fill="#6A6A6A"/></svg>
    </template>

    <options v-if="allowOptions && options.length" @editItem="$emit('on-edit')" @duplicateItem="$emit('on-dupl')" @archiveItem="$emit('on-archive')" :options="options" />
    <span v-else class="h-6 inline-flex"></span>
  </div>
</template>

<script>
import options from '@/components/reuse/options'

export default {
  props: {
    links: {
      type: Array,
      default() {
        return [
          {name: 'Main', link: '/'}, 
          {name: 'Current', link: ''}
        ]
      }
    },
    allowOptions: {
      type: Boolean,
      default: true
    },
    allowBorder: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default() {
        return ['edit', 'duplicate', 'archive']
      }
    }
  },
  components: {options}
}
</script>

<style lang="scss">
.bread{
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  &.is_border{
    box-shadow: inset 0px -1px 0px var(--color-border);
  }
  &__link{
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
    font-weight: 700;
    color: var(--color-gray-bread);
    transition: color 0.3s;
    &:not(span) {
      &:hover{
        color: var(--color-blue-dark);
      }
    }
  }
  &__separator{
    margin-left: 4px;
    margin-right: 4px;
  }
  .option-btn_wrap{
    margin-left: auto;
  }
}
</style>