<template>
  <div class="p-x-6 p-t-4">
    <div v-if="activeCreator" class="flex items-center justify-between relative">
      <h1 class="fw500">Files</h1>

      <div class="flex items-center cursor-pointer" @click.stop="openCreators" :title="activeCreator.first_name+' '+activeCreator.last_name">
        <svg v-if="!activeCreator.avatar" width="20" height="20" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"></circle><path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"></path></svg>
        <img :src="getDomen+activeCreator.avatar" class="flex-shrink-0 w-5 h-5 rounded-full" v-else>

        <span class="fz0 svg-white-dart">
          <svg class="m-l-1" :class="{'rotete-180': isOpenCreators}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.72248 4.39844C2.4552 4.39844 2.32135 4.72158 2.51034 4.91057L5.78609 8.18631C5.90325 8.30346 6.0932 8.30346 6.21035 8.18631L9.48608 4.91057C9.67507 4.72158 9.54122 4.39844 9.27395 4.39844H2.72248Z" fill="#2C2C2C"/></svg>
        </span>
      </div>
      <div class="option-btn_list whitespace-nowrap top-full left-0 right-auto w-full overflow-hidden" tabindex="0" @blur="closeCreators" v-show="isOpenCreators" ref="option_list">
        <div 
          class="option-btn_item p-l-2 p-r-1 p-t-1" 
          @click.stop="selectCreator(user)" 
          v-for="user in getCreatorsList"
          :key="user.id"
        >
          <svg v-if="!user.avatar" class="flex-shrink-0 m-r-1" width="24" height="24" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="80" cy="80" r="80" fill="white"></circle><path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"></path></svg>
          <img :src="getDomen+user.avatar" class="flex-shrink-0 w-6 h-6 m-r-1 rounded-full" v-else>
          <div class="overflow-ellipsis overflow-hidden" :title="user.first_name+' '+user.last_name">{{user.first_name}} {{user.last_name}}</div>
          <svg class="m-l-auto flex-shrink-0 m-r-0" :class="{'opacity-0': !activeCreator || activeCreator.id !== user.id}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
        </div>
      </div>

      <calc-options @create-list="onCreateTab('list')" @create-folder="onCreateTab('group')" v-if="$route.path.indexOf('archive') === -1 && activeCreator.id === getLoginData.data.user.id" class="svg-white-dart">
        <svg class="hover:fill-gray-text" width="24" height="24" viewBox="0 0 24 24" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M18.2221 11.2222H12.7778V5.77773C12.7778 5.34849 12.4293 5 11.9999 5C11.5707 5 11.2222 5.34849 11.2222 5.77773V11.2222H5.77773C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77773 12.7778H11.2222V18.2221C11.2222 18.6515 11.5707 19 11.9999 19C12.4293 19 12.7778 18.6515 12.7778 18.2221V12.7778H18.2221C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2221 11.2222Z"/></svg>
      </calc-options>

      <router-link :to="{name: 'Calculator Archive'}" class="fz0 svg-white-dart-stroke" v-if="$route.name !== 'Calculator Archive'">
        <svg class="svg-dart1 hover:stroke-gray-text" width="24" height="24" fill="none" stroke="#2C2C2C" xmlns="http://www.w3.org/2000/svg"><path d="M18.12 9.96h1.6l-.96 9.28H5.96L5 9.96h1.76m11.36 0l.48-2.56h-2.08m1.6 2.56H6.76m0 0L6.12 7.4H8.2m0 0L7.72 5H17l-.48 2.4m-8.32 0h8.32m-6.56 5.92h4.8" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </router-link>
    </div>

    <label class="block m-t-3 relative fz0 svg-white-dart">
      <input 
        class="p-l-10 p-y-1.5 fz13"
        type="text" 
        placeholder="Search"
        @input="$emit('input', $event.target.value)"
        @keyup.enter="$event.target.blur()"
      >
      <svg class="absolute svg-dart left-2 centered-y" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.1554 18.2913L14.9846 14.1205C15.7925 13.1226 16.279 11.8546 16.279 10.4735C16.279 7.27251 13.6744 4.66797 10.4734 4.66797C7.27248 4.66797 4.66797 7.27248 4.66797 10.4734C4.66797 13.6744 7.27251 16.279 10.4735 16.279C11.8546 16.279 13.1226 15.7925 14.1205 14.9846L18.2913 19.1554C18.4105 19.2746 18.5669 19.3345 18.7234 19.3345C18.8799 19.3345 19.0363 19.2746 19.1555 19.1554C19.3944 18.9165 19.3944 18.5303 19.1554 18.2913ZM10.4735 15.0567C7.94594 15.0567 5.89019 13.001 5.89019 10.4734C5.89019 7.94591 7.94594 5.89016 10.4735 5.89016C13.001 5.89016 15.0568 7.94591 15.0568 10.4734C15.0568 13.001 13.001 15.0567 10.4735 15.0567Z" fill="#2C2C2C"/></svg>
    </label>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import calcOptions from '@/components/calc/calcOptions'
export default {
  components: {
    calcOptions
  },
  props: {
    value: String
  },
  data() {
    return {
      isOpenCreators: false
    }
  },
  computed: {
    ...mapState({
      creators: state => state.tabs.creators,
      activeCreator: state => state.tabs.activeCreator,
    }),
    getCreatorsList() {
      let users = []
    users = this.creators.filter(a=> a.id !== this.getLoginData.data.user.id)
      return [this.getLoginData.data.user].concat(users)
    },
    ...mapGetters(['getLoginData']),
    getDomen() {
      return process.env.VUE_APP_URL_SIMPLE
    }
  },
  methods: {
    ...mapMutations(['setActiveCreator']),
    onCreateTab(type){
      this.$emit('on-create', type)
    },
    openCreators() {
      this.isOpenCreators = true
      setTimeout(()=>{ // because when I call .focus() after change isOpen, it`s don`t work
        let optionList = this.$refs.option_list
        optionList.focus()
      }, 100)
    },
    closeCreators() {
      this.isOpenCreators = false
    },
    selectCreator(user) {
      this.setActiveCreator(user)
      localStorage.setItem('activeTabCreator', JSON.stringify(user))
      this.closeCreators()
    }
  },
  mounted() {
    localStorage.removeItem('activeTabCreator')
    if(!this.activeCreator){
      let currentUser = localStorage.getItem('activeTabCreator') ? JSON.parse(localStorage.getItem('activeTabCreator')) : this.getLoginData?.data?.user
      if(!currentUser) return false
      this.selectCreator({
        id: currentUser?.id,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        avatar: currentUser.avatar
      })
    }
  }
}
</script>

<style scoped lang="scss">
.svg-dart1 {
  stroke: var(--color-dark) !important;
  &:hover {
    stroke: var(--color-gray-text);
  }
}
</style>