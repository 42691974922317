<template>
  <div class="w100Proc box-border">
<!--    <button @click="clickEventChangedList()" class="button-emit-invisibility opacity-0 p0 m0" v-if="getStateUpdatedFolders" :class="{active: getStateUpdatedFolders}"></button>-->
    <div class="d-flex align-items-center cursor-pointer h36 position-relative" :class="{active: item.id && ((moveToMode ? ((activeFolder && activeFolder == item.id && activeType ==item.type_object) || (activeFolder && activeFolder == item.id && !activeType)) : (activeFolder == item.id)) || (activeObject && activeObject == item.id && item.type_load == activeType))}">
      <div v-if="item.id !== -1" class="click-zone position-absolute t0 l0 w100Proc h100Proc" v-on:click="()=>{moveToMode ? (((item.contains_files == undefined && item.contains_folders == undefined) || ((item.contains_files == true && contains_files == true) || (item.contains_folders == true && contains_folders == true))) ? clickZone({id: item.id, type: item.type_object, type_object_folder: typeObject}) : null ) : clickZone({id: item.id, type: item.type_object, type_object_folder: typeObject}); clickLink(); clickItem(item)}"></div>
      <button class="position-relative z-10 d-flex mr4 align-items-center justify-center" v-on:click="openFolder" :class="{'rotate-180': item.is_show, 'opacity-0': (!(Array.isArray(item.folders) === true && item.folders.length) && item.is_load) || item.is_empty === true}">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.27557 4.39844C9.54284 4.39844 9.67669 4.72158 9.4877 4.91057L6.21196 8.18631C6.0948 8.30346 5.90485 8.30346 5.78769 8.18631L2.51197 4.91057C2.32298 4.72158 2.45683 4.39844 2.7241 4.39844L9.27557 4.39844Z" fill="#9AA3B0"/>
        </svg>
      </button>
      <svg v-if="!item.hide_svg" class="mr4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.7784 10.7626C21.4892 10.2853 20.9781 10 20.4112 10H7.78874C7.16305 10 6.59398 10.361 6.33857 10.9199L3 18.0836C3.12604 18.6081 3.60478 19 4.175 19H17.6043C18.1785 19 18.7033 18.6677 18.9597 18.142L21.8454 12.2228C22.0732 11.7544 22.0479 11.2086 21.7784 10.7626Z" fill="#9AA3B0"/>
        <path d="M5.68674 10.0753C5.94143 9.50206 6.50965 9.13158 7.13441 9.13158H19V7.5353C19 6.87727 18.4722 6.34182 17.8235 6.34182H10.9048C10.8947 6.34182 10.8877 6.33908 10.8851 6.33709L9.64677 4.51593C9.42745 4.19286 9.06521 4 8.67818 4H4.17675C3.52778 4 3 4.53545 3 5.19347V16L5.68674 10.0753Z" fill="#BDCBD6"/>
      </svg>
      <p class="fz14 fw500 text-dark" v-if="item.id !== -1">
        {{item.name}}
      </p>
      <input type="text" :auto="focus()" v-else autofocus ref="nameInput" v-model="item.name" @focusout="createFolder" class="fz14 fw500 text-dark border-none p0 bgc-white">
    </div>
    <template v-if="(Array.isArray(item.folders) === true && item.folders.length) && item.is_show">
      <folders-drop :typeObject="item.type_object ? item.type_object : typeObject" :contains_files="contains_files" :contains_folders="contains_folders" :activeType="activeType" :moveToMode="moveToMode" :index="i" :sequenceOfLinkNestings="sequenceOfLinkNestings ? Array.isArray(sequenceOfLinkNestings) === true ? (sequenceOfLinkNestings[i][item.type_object]) : (item.type_object ? sequenceOfLinkNestings[item.type_object] : sequenceOfLinkNestings) : null" :activeObject="activeObject" :link_active="link_active" @linkTo="linkTo" @addedFolder="$emit('addedFolder')" :parent_id="item.id" @delete="deleteItem" :activeFolder="activeFolder" @clickItem="clickItem" @clickZone="clickZone" class="pl24 box-border w100Proc" v-for="(folder, i) in item.folders" :key="i+ '23ff'" :item="folder"></folders-drop>
    </template>
    <pagination-panel-default v-if="nextLink && item.is_show" :if-active="loadNextItems" @load-more="loadMore"></pagination-panel-default>
  </div>
</template>

<script>
import FoldersDrop from "@/components/folders/folders-drop";
import moment from 'moment'
import {mapActions, mapMutations, mapState} from "vuex";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import {moveTo} from "@amcharts/amcharts4/.internal/core/rendering/Path";
export default {
  name: "folders-drop",
  components: {PaginationPanelDefault, FoldersDrop},
  props: {
    typeObject: String,
    contains_files: Boolean,
    contains_folders: Boolean,
    activeType: [String],
    moveToMode: Boolean,
    sequenceOfLinkNestings: [Object, Array],
    activeObject: [Number, String],
    link_active: Boolean,
    item: Object,
    activeFolder: [Number, String],
    parent_id: [Number, String, Object],
    index: [Number],
  },
  data() {
    return {
      show: false,
      nextLink: '',
      loadNextItems: false,
    }
  },
  computed: {
    ...mapState({
      folders: state => state.folders.folders,
    }),
    getStateUpdatedFolders() {
      return ((this.activeFolder && this.activeFolder == this.item.id) || (this.activeObject == this.item.id && this.item.type_load))
    },
  },
  watch: {
    // emitChangeList: function () {
    //   this.clickEventChangedList()
    // },
    'item.created_in': function () {
      console.log(this.item.created_in)
      if(this.item.created_in) {
        let formCreated = {
          folders: [],
          name: '',
          id: -1,
          is_load: false,
          is_show: false,
          file_type: 'FOLDER',
          is_important: false,
          is_archive: false,
          created_in: false,
          shared_with: [],
        }
        this.item.folders = ([formCreated]).concat(this.item.folders)
        this.item.created_in = false
        // setTimeout(()=> {
        //   console.log(this.$refs.nameInput)
        //   this.$refs.nameInput.click()
        // }, 2000)

      }

    },
    'item.is_show': async function () {
      // !this.item.folders.filter(a=> a.id !== -1).length
      console.log('this.item.is_show:', this.item.is_show, 'this.item.is_load:', this.item.is_load)
      if(this.item.is_show && !this.item.is_load) {
        if(!this.item.type_load) {
          let res = await this.axiosGetFileById(this.item.id)
          if(!this.moveToMode) {
            res = res.filter(a=> a.is_archive == this.$route.path.indexOf('archive') >= 0)
          }
          console.log(res)
          res.forEach(item=> {
            item['folders'] = []
            item['is_load'] = false
            item['is_show'] = false
            item['created_in'] = false
          })
          res = res.filter(a=> this.item.folders.map(a=> {return a.id}).indexOf(a.id) === -1)
          this.item.folders = this.item.folders.concat(res)
          this.item.is_load = true
        } else {
          let res = await this.getInFoldersByType(this.item.type_load)
          console.log('getInFoldersByType', res)
          res.forEach(item=> {
            item['folders'] = item['folders'] ? item['folders'] : []
            item['is_load'] = item['is_load'] ? item['is_load'] : false
            item['is_show'] = false
            item['created_in'] = false
          })
          if(this.item.type_load !== 'pre_tasks') {
            res = res.filter(a=> this.item.folders.map(a=> {return a.id}).indexOf(a.id) === -1)
            this.item.folders = this.item.folders.concat(JSON.parse(JSON.stringify(res)))
          } else {
            console.log('task, res', res)
            this.item.folders = JSON.parse(JSON.stringify(res))
          }

          this.item.is_load = true
        }

      }
    }
  },
  methods: {
    moveTo,
    ...mapActions(['axiosGetFoldersForCounterparty', 'axiosGetFoldersForPort', 'axiosGetPortsForFolders', 'axiosGetCharterersForFolders', 'axiosGetFoldersForInvoice', 'axiosGetFoldersForSOA', 'axiosGetFoldersVorVessel', 'axiosGetFolderPayments', 'axiosGetContractYearsForFolders', 'axiosGetShortViewVessels', 'axiosGetFoldersForTasks', 'axiosGetFileById', 'axiosCreateFileInFolder', 'axiosGetFoldersForPaymentDetail', 'axiosGetFoldersTasksSection', 'axiosLoadMoreFoldersInLeftListSection']),
    ...mapMutations(['showLoad', 'hideLoad', 'addedFolderInMoveToModal']),
    async loadMore() {
      let res = null
      this.loadNextItems = true
      switch(this.item.type_load) {
        case 'pre_tasks':
          res = await this.axiosLoadMoreFoldersInLeftListSection({link: this.nextLink})
          res.data.results.forEach(item => {
            item['folders'] = []
            item['is_load'] = false
            item['is_show'] = false
            item['created_in'] = false
            item['type_load'] = 'task'
            item['type_object'] = 'task'
            item['hide_svg'] = true
          })
          this.item.folders = this.item.folders.concat(res.data.results)
          this.nextLink = res.data.next
          break;
        case 'counterparties_payments':
        case 'vessel_cash_flow':
        case 'vessel_acc_invoice':
          res = await this.axiosLoadMoreFoldersInLeftListSection({link: this.nextLink})
          res.data.results.forEach(item => {
            item['link'] = ''
            item['folders'] = []
            item['hide_svg'] = true
            item['type_load'] = 'payment'
            item['type_object'] = 'payment'
            item['contains_files'] = true
            item['contains_folders'] = true
            item['is_show'] = false
            item['is_load'] = false
            item['created_in'] = false
            item['name'] = item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'
          })
          this.item.folders = this.item.folders.concat(res.data.results)
          this.nextLink = res.data.next
          break;
        case 'actual_vessel':
        case 'vessels_by_year':
          res = await this.axiosLoadMoreFoldersInLeftListSection({link: this.nextLink})

          res.data.results.forEach(item=> {
            item['link'] = ''
            item['folders'] = []
            item['hide_svg'] = true
            item['type_load'] = 'vessel'
            item['type_object'] = 'vessel'
            item['contains_files'] = false
            item['contains_folders'] = false
            item['is_show'] = false
            item['is_load'] = false
            item['created_in'] = false
            return item
          })
          this.item.folders = this.item.folders.concat(res.data.results)
          this.nextLink = res.data.next
          break;
      }
      this.loadNextItems = false
    },
    clickEventChangedList() {
      console.log('clickEventChangedList', this.getStateUpdatedFolders)

      if(this.getStateUpdatedFolders) {
        console.log('clickEventChangedList')
        let existFolders = this.folders.map(a=> {return a.id})
        this.item.folders = this.item.folders.filter(el=> existFolders.indexOf(el.id) >= 0)
        this.folders.forEach(folder=> {
          let item = this.item.folders.find(el => el.id == folder.id)
          // console.log(item, folder)
          if(item) {
            // console.log(true)
            item.name = folder.name
            item.is_important = folder.is_important
          } else {
            // console.log(false)
            this.item.folders.push({
              name: folder.name,
              file_type: folder.file_type,
              folders: [],
              id: folder.id,
              is_archive: folder.is_archive,
              is_important: folder.is_important,
              is_load: false,
              is_show: false,
            })
          }
        })
      }
    },
    async getInFoldersByType(type) {
      console.log('getInFoldersByType', type)
      if(type === 'payment') {
        console.log(type === 'payment')
          return await this.axiosGetFoldersForPaymentDetail({onlyFolder: true, notAdded: true, id: this.item.id, is_archive_files: this.$route.path.indexOf('archive') >= 0})
      }
      if(type === 'pre_tasks') {
        let res = await this.axiosGetFoldersTasksSection({forList: true, is_archived: this.$route.path.indexOf('tasks-a') >= 0, ordering: 'name', type: this.item.name == 'Manually Tasks' ? 'MANUAL' :  this.item.name == 'Vessel Search' ? 'SHIP_SEARCH_WITH_CARGO' : this.item.name == 'Cargo Search' ? 'CARGO_SEARCH_WITH_SHIP' : '', limit: 25})
        this.nextLink = res.data.next
        return res.data.results
      }
      if(type === 'task') {
        let res = await this.axiosGetFoldersForTasks({forList: true, id: this.item.id, params: { is_archive_files: this.$route.path.indexOf('archive') >= 0, ordering: 'name'}})
        return res.data.files.filter(a=> a.file_type == 'FOLDER')
      }
      if(type === 'actual_vessel') {
        let res = await this.axiosGetShortViewVessels({search: '', is_archive: (''+this.$route.params.vessels_is_archive) == 'true', is_redelivery: false, ordering: 'name', limit: 20})
        let contracts = []
        contracts = res.data.results.map(item=> {
          item['link'] = ''
          item['folders'] = []
          item['hide_svg'] = true
          item['type_load'] = 'vessel'
          item['type_object'] = 'vessel'
          item['contains_files'] = false
          item['contains_folders'] = false
          item['is_show'] = false
          item['is_load'] = false
          item['created_in'] = false
          return item
        })
        this.nextLink = res.data.next

        return contracts
      }
      if(type === 'redelivered_vessel'){
        let years = await this.axiosGetContractYearsForFolders()
        years = years.years
        return years.map(year=> {
          let form = {
            link: '',
            folders: [],
            hide_svg: true,
            contains_folders: false,
            contains_files: false,
            type_load: 'vessels_by_year',
            type_object: 'vessels_by_year',
            is_show: false,
            is_load: false,
            created_in: false,
            name: year
          }
          return form
        })
      }
      if(type === 'vessels_by_year') {
        let res = await this.axiosGetShortViewVessels({search: '', is_archive: (''+this.$route.params.vessels_is_archive) == true, cp_date_after: this.item.name+'-01-01 00:00:00', cp_date_before: this.item.name+'-12-31 23:59:59', is_redelivery: true, ordering: 'name', limit: 20})
        let contracts = []
        contracts = res.data.results.map(item=> {
          item['link'] = ''
          item['folders'] = []
          item['hide_svg'] = true
          item['type_load'] = 'vessel'
          item['type_object'] = 'vessel'
          item['contains_files'] = false
          item['contains_folders'] = false
          item['is_show'] = false
          item['is_load'] = false
          item['created_in'] = false
          return item
        })
        this.nextLink = res.data.next

        return contracts
      }
      if(type == 'vessel') {
        let array =  [
          {
            id: this.item.id,
            link: '',
            folders: [],
            hide_svg: true,
            contains_folders: false,
            contains_files: false,
            type_load: 'vessel_cash_flow',
            type_object: 'vessel_cash_flow',
            is_show: false,
            is_load: false,
            created_in: false,
            name: 'Cash Flow'
          },
          {
            id: this.item.id,
            link: '',
            folders: [],
            hide_svg: true,
            type_load: 'vessel_details',
            type_object: 'vessel_details',
            is_show: false,
            is_load: false,
            created_in: false,
            name: 'Vsl Details'
          },
          {
            id: this.item.id,
            link: '',
            folders: [],
            hide_svg: true,
            contains_folders: false,
            contains_files: true,
            type_load: 'vessel_owners',
            type_object: 'vessel_owners',
            is_show: true,
            is_load: true,
            created_in: false,
            name: 'Owners'
          },
          {
            id: this.item.id,
            link: '',
            folders: [],
            hide_svg: true,
            contains_folders: false,
            contains_files: false,
            type_load: 'vessel_charterers',
            type_object: 'vessel_charterers',
            is_show: false,
            is_load: false,
            created_in: false,
            name: 'Charterers'
          },
          {
            id: this.item.id,
            link: '',
            folders: [],
            hide_svg: true,
            contains_folders: false,
            contains_files: false,
            type_load: 'vessel_acc_invoice',
            type_object: 'vessel_acc_invoice',
            is_show: false,
            is_load: false,
            created_in: false,
            name: 'Acc. Invoices'
          },
        ]
        let res = await this.axiosGetFoldersForSOA({noSetFile: true, is_archive_files: this.$route.params.files_is_archive, ordering: this.sort, search: this.search, contract: this.item.id, limit: 1, folders: true})
        if(res) {
          let item = array.find(a=> a.name == 'Owners')
          item.id = res.id
        } else {
          array = array.filter(a=> a.name !== 'Owners')
        }
        return  array
      }
      if(type === 'vessel_cash_flow') {

        let form = {
          type: 'payment',
          is_archived: this.$route.params.cash_flow_is_archive,
          limit: 10,
          ordering: 'cp_date',
          cash_flow_ship: this.item.id,
          move_to: true
        }
        let res = await this.axiosGetFolderPayments(form)
        console.log('psdsd', res)
        let payments = res.results.map(item=> {
          item['link'] = ''
          item['folders'] = []
          item['hide_svg'] = true
          item['type_load'] = 'payment'
          item['type_object'] = 'payment'
          item['contains_files'] = true
          item['contains_folders'] = true
          item['is_show'] = false
          item['is_load'] = false
          item['created_in'] = false
          item['name'] = item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'
          return item
        })
        this.nextLink = res.next
        return payments
      }
      if(type === 'vessel_details') {
        let folders = await this.axiosGetFoldersVorVessel({notSetFiles: true, id: this.item.id, params: {is_archive_files: this.$route.params.files_is_archive, ordering: 'name'}})
        return folders.filter(a=> a.file_type == 'FOLDER')
      }
      if(type === 'vessel_acc_invoice') {
        let form = {
          type: 'invoice',
          is_archived: this.$route.params.cash_flow_is_archive,
          limit: 10,
          ordering: 'cp_date',
          ship: this.item.id,
          move_to: true
        }
        let res = await this.axiosGetFolderPayments(form)
        console.log('psdsd', res)
        let payments = res.results.map(item=> {
          item['link'] = ''
          item['folders'] = []
          item['hide_svg'] = true
          item['type_load'] = 'payment'
          item['type_object'] = 'payment'
          item['contains_files'] = true
          item['contains_folders'] = true
          item['is_show'] = false
          item['is_load'] = false
          item['created_in'] = false
          item['name'] = item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'
          return item
        })
        this.nextLink = res.next
        return payments
      }
      // if(type === 'vessel_owners') {
      //   let res = await this.axiosGetFoldersForSOA({is_archive_files: this.$route.params.owners_is_archive, ordering: 'name', contract: this.item.id, limit: 1, folders: true})
      //   return res.filter(a=> a.file_type == 'FOLDER')
      // }
      if(type === 'vessel_charterers') {
        let res = await this.axiosGetCharterersForFolders({contract_id: this.item.id,search: this.search, is_archive: this.$route.params.charterers_is_archive, ordering: this.sort, limit: 999})
        console.log('charterers', res)
        let charterers = res.data.results.map(item=> {
          item['link'] = ''
          item['folders'] = []
          item['hide_svg'] = true
          item['type_load'] = item.contract_type == 'SUBLET' ? 'sublet' : 'voyage'
          item['type_object'] = item.contract_type == 'SUBLET' ? 'sublet' : 'voyage'
          item['contains_files'] = false
          item['contains_folders'] = false
          item['is_show'] = false
          item['is_load'] = false
          item['created_in'] = false
          item['name'] = (item.contract_type == 'SUBLET' ? 'Sublet' : 'Voyage') + (item.cp_date ? '/'+moment(item.cp_date).format('DD.MM.YYYY') : '') + (item.owners ? '/'+item.owners.name : '')
          return item
        })
        this.nextLink = res.data.next
        return charterers
      }
      if(type === 'voyage') {
        let res = await this.axiosGetFoldersForInvoice({notSetFiles: true, params: {contract: this.item.id, folders: true}})
        let listTypes = res.map(a=> {return {type: a.invoice_type, id: a.id}})
        let array = [
          {
            id: this.item.id,
            link: '',
            folders: [],
            hide_svg: true,
            type_load: 'vessel_details',
            type_object: 'vessel_details',
            is_show: false,
            is_load: false,
            contains_folders: true,
            contains_files: true,
            created_in: false,
            name: 'Details'
          },
        ]
        let item = listTypes.find(a=> a.type == 'FREIGHT')
        if(item) {
          array.push({
            id: item.id,
            link: '',
            folders: [],
            hide_svg: true,
            type_load: 'voyage_invoice',
            type_object: 'voyage_invoice',
            contains_folders: false,
            contains_files: true,
            is_show: false,
            is_load: true,
            created_in: false,
            name: 'Freight Invoice'
          })
        }
        item = listTypes.find(a=> a.type == 'SHIP')
        if(item) {
          array.push({
            id: item.id,
            link: '',
            folders: [],
            hide_svg: true,
            type_load: 'voyage_invoice',
            type_object: 'voyage_invoice',
            contains_folders: false,
            contains_files: true,
            is_show: false,
            is_load: true,
            created_in: false,
            name: 'VSOA'
          })
        }
        item = listTypes.find(a=> a.type == 'ADDITIONAL_FREIGHT')
        if(item) {
          array.push({
            id: item.id,
            link: '',
            folders: [],
            hide_svg: true,
            type_load: 'voyage_invoice',
            type_object: 'voyage_invoice',
            contains_folders: false,
            contains_files: true,
            is_show: false,
            is_load: true,
            created_in: false,
            name: 'Additional Freight Invoice'
          })
        }
        item = listTypes.find(a=> a.type == 'DETENTION')
        if(item) {
          array.push( {
            id: item.id,
            link: '',
            folders: [],
            hide_svg: true,
            type_load: 'voyage_invoice',
            type_object: 'voyage_invoice',
            contains_folders: false,
            contains_files: true,
            is_show: false,
            is_load: true,
            created_in: false,
            name: 'Detention Invoice'
          })
        }
        return array
      }
      if(type === 'sublet') {
        let array = [
          {
            id: this.item.id,
            link: '',
            folders: [],
            hide_svg: true,
            type_load: 'vessel_details',
            type_object: 'vessel_details',
            contains_folders: true,
            contains_files: true,
            is_show: false,
            is_load: false,
            created_in: false,
            name: 'Details'
          },

        ]
        let res = await this.axiosGetFoldersForSOA({noSetFile: true, is_archive_files: this.$route.params.files_is_archive, ordering: this.sort, search: this.search, contract: this.item.id, limit: 1, folders: true})
        if(res) {
          array.push( {
            id: res.id,
            link: '',
            folders: [],
            hide_svg: true,
            type_load: 'voyage_invoice',
            type_object: 'voyage_invoice',
            contains_folders: false,
            contains_files: true,
            is_show: false,
            is_load: true,
            created_in: false,
            name: 'SOA Sublet'
          })
        }
        return  array
      }
      if(type === 'country') {
        let res = await this.axiosGetPortsForFolders({params: {ordering: 'name', port_ids: this.item.ids.toString()}})
        let array = res.map(item => {
          return  {
            'link': '',
            'folders': [],
            'hide_svg': true,
            'type_load': 'port',
            'type_object': 'port',
            'is_show': false,
            'is_load': false,
            'created_in': false,
            'contains_files': false,
            'contains_folders': false,
            'name': item.name,
            'id': item.id,
          }
        })
        console.log('array', array)
        return array

      }
      if(type === 'port') {
        let res = await this.axiosGetFoldersForPort({returnFullResponse: true, id: this.item.id ,params: {is_archive_files: this.$route.params.is_archive_files, ordering: 'name'}})
        let files = res.files.filter(a=> a.file_type == 'FOLDER').map(item=> {
          item['link'] = ''
          item['folders'] = []
          item['hide_svg'] = false
          item['is_show'] = false
          item['is_load'] = false
          item['created_in'] = false
          return item
        })
        let berths = res.berths.map(item=> {
          let form = {
            id: item.id,
            link: '',
            folders: [],
            hide_svg: true,
            contains_files: true,
            contains_folders: false,
            type_load: 'berth_list',
            type_object: 'berth_list',
            is_show: false,
            is_load: true,
            created_in: false,
            name: item.name
          }
          return form
        })
        let contacts = res.contacts.map(item=> {
          let form = {
            id: item.id,
            link: '',
            folders: [],
            hide_svg: true,
            contains_files: true,
            contains_folders: false,
            type_load: 'contacts',
            type_object: 'contacts',
            is_show: false,
            is_load: true,
            created_in: false,
            name: item.dwt + (item.amount_local ? '/'+item.amount_local : '') + (item.source ? '/'+item.source : '')
          }
          return form
        })
        console.log('port', files)
        let array =  [
          {
            id: this.item.id,
            link: '',
            folders: files,
            hide_svg: true,
            type_load: 'port_details',
            type_object: 'port_details',
            contains_files: true,
            contains_folders: true,
            is_show: false,
            is_load: true,
            created_in: false,
            name: 'Details'
          },
          {
            id: this.item.id,
            link: '',
            folders: berths,
            hide_svg: true,
            type_load: 'port_berth_list',
            type_object: 'port_berth_list',
            contains_files: false,
            contains_folders: false,
            is_show: false,
            is_load: true,
            created_in: false,
            name: 'Berth List'
          },
          {
            id: this.item.id,
            link: '',
            folders: contacts,
            hide_svg: true,
            type_load: 'port_contacts',
            type_object: 'port_contacts',
            contains_files: false,
            contains_folders: false,
            is_show: false,
            is_load: true,
            created_in: false,
            name: 'Contacts'
          }
        ]
        console.log('return', array)
        return  array
      }
      if(type === 'counterparties') {
          let array = []
        array.push({
            'link': '',
            'folders': [],
            'hide_svg': true,
            'type_load': 'counterparties_detail',
            'type_object': 'counterparties_detail',
            'is_show': false,
            'is_load': false,
            'created_in': false,
            'contains_files': true,
            'contains_folders': true,
          'counterparties_id': this.item.id,
            'name': 'Details',
          'id': this.item.id,

          })
        array.push({
          'link': '',
          'folders': [],
          'hide_svg': true,
          'type_load': 'counterparties_payments',
          'type_object': 'counterparties_payments',
          'is_show': false,
          'is_load': false,
          'created_in': false,
          'contains_files': false,
          'contains_folders': false,
          'counterparties_id': this.item.id,
          'name': 'Payments',
          'id': this.item.id,
        })
        return array
      }
      if(type == 'counterparties_detail') {
        let res = await this.axiosGetFoldersForCounterparty({notSetFile: true, id: this.item.counterparties_id,params: {is_archive_files: this.$route.params.is_archive_files, ordering: 'name'}})
        let files = res.files.filter(a=> a.file_type == 'FOLDER').map(item=> {
          item['link'] = ''
          item['folders'] = []
          item['hide_svg'] = false
          item['is_show'] = false
          item['is_load'] = false
          item['created_in'] = false
          return item
        })
        return files
      }
      if(type == 'counterparties_payments') {
        let form = {
          counterparty: this.item.counterparties_id,
          data_type: 'payment',
          record_type: 'PAYMENT',
          is_archived: this.$route.params.is_archive_payments,
          limit: 10,
          ordering: 'cp_date',
          move_to: true,
        }
        let res = await this.axiosGetFolderPayments(form)
        console.log('psdsd', res)
        let payments = res.results.map(item=> {
          item['link'] = ''
          item['folders'] = []
          item['hide_svg'] = true
          item['type_load'] = 'payment'
          item['type_object'] = 'payment'
          item['contains_files'] = true
          item['contains_folders'] = true
          item['is_show'] = false
          item['is_load'] = false
          item['created_in'] = false
          item['name'] = item.execute_date ? moment(item.execute_date).format('DD.MM.YYYY') : 'Payment'
          return item
        })
        this.nextLink = res.next
        return payments
      }
      // if(type === 'port_details') {
      //   res.forEach(item=> {
      //     item['link'] = ''
      //     item['folders'] = []
      //     item['hide_svg'] = true
      //     item['type_load'] = 'port'
      //     item['type_object'] = 'port'
      //     item['is_show'] = false
      //     item['is_load'] = false
      //     item['created_in'] = false
      //   })
      // }
      // let filesDetail = await this.axiosGetFoldersVorVessel({notSetFiles: true, id: this.$route.params.charterersDetail ? JSON.parse(this.$route.params.charterersDetail).id : null, params: {is_archive_files: this.$route.params.files_is_archive,ordering: this.sort, search: this.search}})

      return []
    },
    clickItem(item) {
      this.$emit('clickItem', item)
    },
    deleteItem(id) {
      this.item.folders = this.item.folders.filter(a=> a.id !== id)
    },
    async createFolder() {
      if(this.item.id == -1) {
        if(this.item.name) {
          this.showLoad()
          let formData = ''
          formData = {
            file_type: 'FOLDER',
            type: this.getTypeFileBySection,
            name: this.item.name,
            // path_to_folder: this.getPathToFolder,
            is_important: false,
            is_archive: false,
            shared_with: [],
          }
            formData["parent_file"] = this.parent_id
          let active = this.activeFolder
          let res = await this.axiosCreateFileInFolder(formData)
          this.item.id = res.id
          this.addedFolderInMoveToModal({activeFolder: active, item: this.item})
          this.hideLoad()
          this.$emit('addedFolder')

        } else {
          this.$emit('delete', this.item.id)
        }
      }
    },
    clickZone(data) {
      this.$emit('clickZone', data)
    },
    clickLink() {
      if(this.link_active) {
        let name = ''
        if(!this.item.type_object) {
          name = !this.item.is_archive ? this.sequenceOfLinkNestings['folder']['routePath'] : this.sequenceOfLinkNestings['folder']['routePathArchive']
        } else {
          name = !this.item.is_archive ? (Array.isArray(this.sequenceOfLinkNestings) === true ? (this.sequenceOfLinkNestings[this.index][this.item.type_object]['route']) : this.sequenceOfLinkNestings[this.item.type_object]['route']) : (Array.isArray(this.sequenceOfLinkNestings) === true ? (this.sequenceOfLinkNestings[this.index][this.item.type_object]['routeArchive']) : this.sequenceOfLinkNestings[this.item.type_object]['routeArchive'])
        }
        console.log('name: ', name)
        let link =  []
        if(!this.item.type_object) {
          link = [{name: this.item.name, id: this.item.id}]
        }
        let data = {name: name, link:link}
        switch(this.item.type_object) {
          case 'payment':
            data['detailData'] = {id: this.item.id, date: this.item.execute_date ? moment(this.item.execute_date).format('DD.MM.YYYY') : 'Payment'}
            if(this.$route.path.indexOf('vessels') >= 0) {
              data['files_is_archive'] = false
            }
            if(this.$route.path.indexOf('counterparties') >= 0) {
              data['files_is_archive'] = false
            }
            break;
          case 'task':
            data['detailData'] = {id: this.item.id, name: this.item.name}
            break;
          case 'actual_vessel':
            data['vessels_is_archive'] = false
            break;
          case 'redelivered_vessel':
            data['vessels_is_archive'] = false
            break;
          case 'vessel':
            data['detailVessel'] = {id: this.item.id, name: this.item.name, cp_date: this.item.cp_date ? moment(this.item.cp_date).format('DD.MM.YYYY') : ''}
            break;
          case 'vessel_cash_flow':
            data['cash_flow_is_archive'] = false
            break;
          case 'vessel_acc_invoice':
            data['cash_flow_is_archive'] = false
            break;
          case 'vessel_details':
            data['files_is_archive'] = false
            break;
          case 'vessel_owners':
            data['ownersDetail'] = {id: this.item.id}
            data['owners_is_archive'] = false
            break;
          case 'vessel_charterers':
            data['charterers_is_archive'] = false
            break;
          case 'voyage':
            data['charterersDetail'] = {id: this.item.id, name: this.item.name, cp_date: this.item.cp_date ? moment(this.item.cp_date).format('DD.MM.YYYY') : '', type: this.item.contract_type == 'SUBLET' ? 'sublet' : 'Voyage'}
            data['files_is_archive'] = false
            break;
          case 'sublet':
            data['charterersDetail'] = {id: this.item.id, name: this.item.name, cp_date: this.item.cp_date ? moment(this.item.cp_date).format('DD.MM.YYYY') : '', type: this.item.contract_type == 'SUBLET' ? 'sublet' : 'Voyage'}
            data['files_is_archive'] = false
            break;
          case 'country':
            data['countryDetail'] = {name: this.item.name, idsPorts: this.item.ids}
            data['countries_is_archive'] = false
            break;
          case 'port':
            data['portDetail'] = {id: this.item.id, name: this.item.name}
            break;
          case 'port_details':
            data['is_archive_files'] = false
            break;
          case 'port_berth_list':
            data['is_archive_berth_list'] = false
            break;
          case 'berth_list':
            data['detailList'] = {id: this.item.id, name: this.item.name}
            data['is_archive_files'] = false
            break;
          case 'port_contacts':
            data['is_archive_contacts'] = false
            break;
          case 'contacts':
            data['detailContact'] = {id: this.item.id, name: this.item.name}
            data['is_archive_files'] = false
            break;
          case 'counterparties':
            data['detailCounterparties'] = {id: this.item.id, name: this.item.name}
            data['is_archive_counterparties'] = false
            break;
          case 'counterparties_detail':
            data['is_archive_files'] = false
            break;
          case 'counterparties_payments':
            data['is_archive_payments'] = false
            break;
        }
        // console.log(data)
        this.$emit('linkTo', data)
      }
    },
    linkTo(data) {
      // console.log(data)
      if(!this.item.type_object) {
        data.link = [{name: this.item.name, id: this.item.id}].concat(data.link)
      }
      switch(this.item.type_object) {
        case 'payment':
          data['detailData'] = {id: this.item.id, date: this.item.execute_date ? moment(this.item.execute_date).format('DD.MM.YYYY') : 'Payment'}
            if(this.$route.path.indexOf('vessels') >= 0) {
              data['files_is_archive'] = false
            }
          break;
        case 'task':
          data['detailData'] = {id: this.item.id, name: this.item.name}
          break;
        case 'actual_vessel':
          data['vessels_is_archive'] = false
          break;
        case 'redelivered_vessel':
          data['vessels_is_archive'] = false
          break;
        case 'vessel':
          data['detailVessel'] = {id: this.item.id, name: this.item.name, cp_date: this.item.cp_date ? moment(this.item.cp_date).format('DD.MM.YYYY') : ''}
          break;
        case 'vessel_cash_flow':
          data['cash_flow_is_archive'] = false
          break;
        case 'vessel_acc_invoice':
          data['cash_flow_is_archive'] = false
          break;
        case 'vessel_details':
          data['files_is_archive'] = false
          break;
        case 'vessel_owners':
          data['ownersDetail'] = {id: this.item.id}
          data['owners_is_archive'] = false
          break;
        case 'vessel_charterers':
          data['charterers_is_archive'] = false
          break;
        case 'voyage':
          data['charterersDetail'] = {id: this.item.id, name: this.item.name, cp_date: this.item.cp_date ? moment(this.item.cp_date).format('DD.MM.YYYY') : '', type: this.item.contract_type == 'SUBLET' ? 'sublet' : 'Voyage'}
          data['files_is_archive'] = false
          break;
        case 'sublet':
          data['charterersDetail'] = {id: this.item.id, name: this.item.name, cp_date: this.item.cp_date ? moment(this.item.cp_date).format('DD.MM.YYYY') : '', type: this.item.contract_type == 'SUBLET' ? 'sublet' : 'Voyage'}
          data['files_is_archive'] = false
          break;
        case 'country':
          data['countryDetail'] = {name: this.item.name, idsPorts: this.item.ids}
          data['countries_is_archive'] = false
          break;
        case 'port':
          data['portDetail'] = {id: this.item.id, name: this.item.name}
          break;
        case 'port_details':
          data['is_archive_files'] = false
          break;
        case 'port_berth_list':
          data['is_archive_berth_list'] = false
          break;
        case 'berth_list':
          data['detailList'] = {id: this.item.id, name: this.item.name}
          data['is_archive_files'] = false
          break;
        case 'port_contacts':
          data['is_archive_contacts'] = false
          break;
        case 'contacts':
          data['detailContact'] = {id: this.item.id, name: this.item.name}
          data['is_archive_files'] = false
          break;
        case 'counterparties':
          data['detailCounterparties'] = {id: this.item.id, name: this.item.name}
          data['is_archive_counterparties'] = false
          break;
        case 'counterparties_detail':
          data['is_archive_files'] = false
          break;
        case 'counterparties_payments':
          data['is_archive_payments'] = false
          break;
      }
      this.$emit('linkTo', data)
    },
    async openFolder() {
      this.item.is_show = !this.item.is_show
      console.log('openFolder', this.item.is_show)

    },
    focus() {
      setTimeout(()=> {
        this.$refs.nameInput.focus()
      }, 500)
      return ''
    }
  },
  mounted() {
    if(this.item.id == -1 || this.$refs.nameInput) {
      setTimeout(()=> {
        this.$refs.nameInput.focus()
      }, 500)
    }
    if(this.item.type_object !== 'port_contacts' && this.item.type_object !== 'port' && this.item.type_object !== 'port_berth_list' && this.item.type_object !== 'voyage' && this.item.type_object !== 'sublet' && this.item.type_object !== 'vessel' && this.item.type_object !== 'vessel_owners' && this.item.type_object !== 'vessel_cash_flow' && this.item.type_object !== 'vessel_charterers' && this.item.type_object !== 'vessel_acc_invoice') {
      console.log('1+1', 'changeList'+this.item.id, this.item)

      document.addEventListener('changeList'+this.item.id, ()=> {
        this.clickEventChangedList()
      })
    }

  }
}
</script>

<style scoped lang="scss">
  .active {
    //background-color: var(--color-blue-lightest);
    svg path {
      fill: var(--color-blue)
    }
    p {
      color: var(--color-blue) !important
    }
  }
</style>