import axios from 'axios'
// import QueryString from "querystring";

export default {
    actions: {
        async axiosAddFilesToObject(ctx, data) {
            let notAddFile = false;
            let filesId = []
            if(data.files.length) {
                for (let i = 0; i < data.files.length; i++) {
                    let file = data.files[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
                    if(file.parent_id !== undefined && file.parent_id !== null) {
                        notAddFile = true;
                        formData.append("parent_id", file.parent_id);
                        formData.append("parent_file", file.parent_id);
                    }
                    filesId.push(await ctx.dispatch('axiosAddFileReturnData', formData, {root: true}))
                    // console.log(file)
                }
            }
            if(!notAddFile) {
                return await axios.patch(process.env.VUE_APP_URL+"/file/add", {
                    model_id: data.model_id,
                    file_ids: filesId.map(a=> {return a.id}),
                    model: data.model,
                }, {
                    headers: {
                        'Authorization': 'token ' + ctx.getters.getToken,
                    }
                }).then(()=> {
                    return filesId
                }).catch(()=> {
                    return false
                })
            } else {
                return null
            }
        },
        axiosFileChangeName(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL+"/file/" + data.id, {
                'name': data.name,
                'type': data.type,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            });
        },
        axiosFileDelete(ctx, id) {
            return axios.delete(process.env.VUE_APP_URL+"/file/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
        async FileAdd(ctx, data) {
            let files = [];
            if(data.file.length) {
                for (let i = 0; i < data.file.length; i++) {
                    const file = data.file[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    formData.append("file_type", (file.file_type ? file.file_type : 'FILE'));
                    if(file.parent_id !== undefined && file.parent_id !== null) {
                        formData.append("parent_id", file.parent_id);
                        formData.append("parent_file", file.parent_id);
                    }
                    files.push(await ctx.dispatch('axiosFileAdd', formData))
                }
            }
            return files;
        },
        async axiosFileAdd(ctx, formData) { // returned id of file
            return await axios.post(process.env.VUE_APP_URL+'/file/', formData, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                // console.log('axiosAddFile', response);
                return response.data
            }).catch(error => {
                console.error('axiosAddFile', error);
            });
        },
        async getFileInFolder(ctx, id) {
            return await axios.get(process.env.VUE_APP_URL+'/file/'+id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                return response.data;
            }).catch(()=> {
                return null
            })
        },
        async axiosDownloadFolder(ctx, ids) {
            let text = "";
            ids.map(a=> {
                text += a + ","
            })
            text = text.substr(0, text.length-1)
            return await axios.get(process.env.VUE_APP_URL+'/folder/download/', {
                params: {
                    ids: text
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                responseType: "blob",
                // paramsSerializer: params => {
                //     return QueryString.stringify(params)
                // }
            }).then(response => {
                return response;
            }).catch(()=> {
                return null
            })
        }
    },
    mutations: {
    },
    state: {
    },
    getters: {
    },
}
