<template>
  <div class="bg-gray-lightest">
    <div class="vessel_owners__page_container">
      <div class="flex p-y-4">
        <div class="fz14" v-if="owners">
          <div class="text-gray-text fz12">Last edits</div>
          {{owners.updated_at | formatDate}}
          at {{owners.updated_at | formatTime}}
          by {{owners.creator.first_name + ' ' + (owners.creator.last_name ? owners.creator.last_name : '')}}
        </div>
        <actions class="m-l-auto" @downloadPDF="beforeDownload" @downloadXLSX="downloadXLSX" v-if="getCharterer" :contract="getCharterer" :owners="owners"/>
      </div>
      
      <div class="bg-white m-t-2 p-x-6 p-y-3 rounded-lg shadow">
        <div class="flex items-center p-b-8 position-relative">
          <div class="border-b border-gray-dark border-for-position"></div>
          <svg class="m-r-6" width="75" height="97" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2682_4874)"><path d="M42.525 41.546C59.7 44.036 72.95 59.106 72.95 77.251a36.566 36.566 0 01-1.93 11.764l1.94.68a38.803 38.803 0 001.814-16.615 38.622 38.622 0 00-5.337-15.811 37.884 37.884 0 00-11.466-11.975 37.13 37.13 0 00-15.395-5.843l-.052 2.095zM33.104 41.457C15.628 43.667 2.05 58.876 2.05 77.247a36.594 36.594 0 001.88 11.608l-1.941.671A38.804 38.804 0 01.256 72.813a38.61 38.61 0 015.488-15.851 37.863 37.863 0 0111.648-11.917A37.115 37.115 0 0132.94 39.37l.164 2.086z" fill="#113051"/><path d="M34.425 21.96v46.444s-11.085.755-20.044 8.578c0 0 14.825-22.253 20.044-55.021z" fill="#113051"/><path d="M39.779 0v68.267s11.165.231 20.847 7.998C49.669 53.918 42.644 28.556 39.779 0z" fill="#17A1E4"/><path d="M15.656 96.837V94.74h8.846c1.104 0 1.721-.627 1.721-1.564 0-1.024-.617-1.553-1.72-1.553H19.08c-2.221 0-3.604-1.436-3.604-3.418 0-1.933 1.284-3.37 3.637-3.37h8.477v2.098h-8.477c-.942 0-1.494.561-1.494 1.47 0 .91.573 1.449 1.478 1.449h5.405c2.393 0 3.669 1.138 3.669 3.477 0 2.015-1.196 3.517-3.669 3.517l-8.846-.009zM30.753 96.836V84.83h2.06v9.91h7.935v2.096h-9.995zM57.733 96.835l-2.258-8.439-3.344 7.812c-.227.512-.552.792-1.054.792s-.854-.28-1.056-.792l-3.329-7.812-2.255 8.44h-2.03l3.036-11.301c.145-.578.47-.86 1.025-.86.224 0 .442.066.628.193.185.127.329.308.411.519l3.638 8.49 3.637-8.49c.072-.208.206-.388.384-.515.177-.128.388-.196.605-.196.504 0 .829.281.991.859l3.02 11.3h-2.05z" fill="#113051"/></g><defs><clipPath id="clip0_2682_4874"><path fill="#fff" d="M0 0h75v97H0z"/></clipPath></defs></svg>
          <div class="m-t-5">
            <h1 class="m-b-1 fz18 fw500">Speed Logistics Marine LTD</h1>
            <p class="fz13 text-gray-text text-uppercase">Office B on 15/F Wayson Commercial House, <br> 68-70 Lockhart Road, Wai Chai, Hong Kong</p>
          </div>
          <div class="text-align-right m-l-auto m-t-5">
            <h2 class="fz20 fw500 uppercase">Statement of Accounts</h2>
            <div v-if="owners && owners.identifier">
              <p class="fz14 text-gray-text mt8">Number</p>
              <p class="fz14 text-dark mt2">{{owners.identifier}}</p>
            </div>
          </div>
        </div>

        <div class="flex p-b-3 p-t-6">
          <h1 class="fz16 text-dark fw500">
            {{contract.name}} /
            <template v-if="getContractOwnerName">{{getContractOwnerName}} / </template>
            CP Date {{getCharterer.cp_date | moment("DD.MM.YYYY")}}
          </h1>
          <div class="m-l-auto text-align-right text-gray-text fz13" style="width: 15.6%">Debit, {{owners && owners.currency ? owners.currency : 'USD'}}</div>
          <div class="text-align-right text-gray-text fz13" style="width: 15.6%">Credit, {{owners && owners.currency ? owners.currency : 'USD'}}</div>
        </div>

        <hire-table :activeCurrency="owners ? owners.currency : 'USD'" :hireData="owners ? owners.charter_hire : []"/>
        <bunkers-on-table v-if="owners && (getDelivery.length !== 0 || getRedelivery.length !== 0)" :bunkersOnData="owners ? owners.bunkers_delivery : []" :owners="owners"/>
        <empty-bunkers-on-table v-else/>
        <bunkers-on-table-c-e-v :bunkersOnData="owners ? owners.bunkers_delivery : []" :owners="owners"  v-if="getCEV && getCEV.additional_info && getCEV.additional_info.rate"></bunkers-on-table-c-e-v>
<!--        v-if="owners && (owners.bunkers_delivery.filter(a=> a.bunkers_type === 'C/E/V')).length"-->
        <balast-table v-if="owners && owners.balast_bonus" :balastData="owners.balast_bonus"/>
        <deposit-table v-if="owners && owners.chrtrs" :chrtrsData="owners.chrtrs"/>
        <bunkers-table v-if="owners && owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'}).length" :activeCurrency="owners ? owners.currency : 'USD'" :bunkersData="owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'})[0]"/>
        <bunkers-supply-table v-if="owners && owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'}).length" :activeCurrency="owners ? owners.currency : 'USD'" :bunkersData="owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'})[0]"/>
        <off-hire-table v-if="owners && owners.hire_off.length" :hireData="owners ? owners.hire_off : []" :activeCurrency="owners ? owners.currency : 'USD'"/>
        <speed-table v-if="owners && owners.speed_claim.length" :speedData="owners ? owners.speed_claim : []" :activeCurrency="owners ? owners.currency : 'USD'"/>
        <charterers-table v-if="owners && owners.expenses.filter(el=>{return el.expense_type == 'charterer_expense'}).length" :expensesData="owners.expenses.filter(el=>{return el.expense_type == 'charterer_expense'})"/>
        <owners-table v-if="owners && owners.expenses.filter(el=>{return el.expense_type == 'owners_expense'}).length" :expensesData="owners.expenses.filter(el=>{return el.expense_type == 'owners_expense'})"/>
        <remittances-table v-if="owners" :remittances="owners.additional_info.remittances"/>
        <remittances-table v-if="localRemittances" :remittances="localRemittances"/>

        <div class="vessel_owners__table border bg-gray-dark bg-gray-dark rounded-lg overflow-hidden m-t-2 m-b-6" v-if="owners">
          <div class="colspan-1-4 border-b border-r uppercase p-3 fz14 fw500">Total</div>
          <div class="border-b border-r p-3 fz13 fw500 text-align-right">{{owners.total_debit | formatNumberTwo}}</div>
          <div class="border-b p-3 fz13 fw500 text-align-right">{{owners.total_credit | formatNumberTwo}}</div>
          <div class="colspan-1-4 border-r uppercase p-3 fz14 fw500">BALANCE DUE TO {{owners.balance >= 0 ? 'CHARTERS' : 'OWNERS'}}</div>
          <div class="border-r p-3 fz13 fw500 text-align-right">
            <template v-if="owners.balance >= 0">{{Math.abs(owners.balance) | formatNumberTwoZeroEmpty}}</template>
          </div>
          <div class="p-3 fz13 fw500 text-align-right">
            <template v-if="owners.balance < 0">{{Math.abs(owners.balance) | formatNumberTwoZeroEmpty}}</template>
          </div>
        </div>
        <div class="vessel_owners__table border bg-gray-dark bg-gray-dark rounded-lg overflow-hidden m-t-2 m-b-6" v-else>
          <div class="colspan-1-4 border-b border-r uppercase p-3 fz14 fw500">Total</div>
          <div class="border-b border-r p-3 fz13 fw500 text-align-right">{{localDebit | formatNumberTwoZeroEmpty}}</div>
          <div class="border-b p-3 fz13 fw500 text-align-right">{{localCredit | formatNumberTwoZeroEmpty}}</div>
          <div class="colspan-1-4 border-r uppercase p-3 fz14 fw500">
            {{localBalance >= 0 ? 'BALANCE DUE TO CHARTERS' : 'BALANCE DUE TO OWNERS'}}
          </div>
          <div class="border-r p-3 fz13 fw500 text-align-right">
            <template v-if="localBalance >= 0">{{Math.abs(localBalance) | formatNumberTwoZeroEmpty}}</template>
          </div>
          <div class="p-3 fz13 fw500 text-align-right">
            <template v-if="localBalance < 0">{{Math.abs(localBalance) | formatNumberTwoZeroEmpty}}</template>
          </div>
        </div>
        <div class="flex">
          <div class="w-2/3">
            <div class="m-b-6" v-if="owners && owners.linked_payments.length">
              <p class="text-gray-text fz13 m-b-2">Linked Operation</p>
              <p
                class="p-b-1 fz14"
                v-for="payment in owners.linked_payments"
                :key="payment.id"
              >
                <span v-if="payment.payment_type == 'expense'">Payment</span>
                <span v-if="payment.payment_type == 'income'">Income</span>
                #{{payment.id}}
                <span v-if="payment.execute_date">({{payment.execute_date | formatDate}})</span>
              </p>
            </div>
            <div class="" v-if="owners && owners.comment">
              <p class="text-gray-text fz13 m-b-2">Note</p>
              <p class="fz14 word-break-all">
                {{owners.comment}}
              </p>
            </div>
            <div class="m-t-6" v-if="owners && getSoaRequisite">
              <div v-if="owners.bank_details">
                <h3 class="fw500 m-b-3">
                  Please remit
                  <span class="text-red fw500">{{owners.currency}} {{owners.balance | formatNumberTwoInf }}</span>
                  to following bank account not later than on <span class="text-red fw500">{{owners.due_date | formatDateWithotTZ}}</span>:
                </h3>
                <p class="m-t-1 fz14">
                  <span class="text-gray-text">Holder:</span> {{getSoaRequisite.holder}}
                </p>
                <p class="m-t-1 fz14">
                  <span class="text-gray-text">Account Number:</span> {{getSoaRequisite.account_number}}
                </p>
                <p class="m-t-1 fz14">
                  <span class="text-gray-text">IBAN:</span> {{getSoaRequisite.iban}}
                </p>
                <p class="m-t-1 fz14">
                  <span class="text-gray-text">Description:</span> {{getSoaRequisite.description}}
                </p>
                <p class="m-t-1 fz14">
                  <span class="text-gray-text">Bank:</span> {{getSoaRequisite.bank}}
                </p>
                <p class="m-t-1 fz14" v-if="getSoaRequisite.bank_address">
                  <span class="text-gray-text">Bank Address:</span> {{getSoaRequisite.bank_address}}
                </p>
                <p class="m-t-1 fz14">
                  <span class="text-gray-text">Clearing number:</span> {{getSoaRequisite.clearing_number}}
                </p>
                <p class="m-t-1 fz14">
                  <span class="text-gray-text">BIC / SWIFT:</span> {{getSoaRequisite.bic}}
                </p>
                <p class="m-t-1 fz14">
                  <span class="text-gray-text">Currency:</span> {{getSoaRequisite.currency}}
                </p>
              </div>
            </div>
          </div>
        <!--        <div class="w-1/3 m-t-auto text-align-right">-->
        <!--          <img src="@/assets/img_stamp_color.png" alt="stamp">-->
        <!--        </div>-->
          <div class="w-1/3 m-t-auto text-align-right pr10" v-if="owners && owners.stamp">
            <img class="w100Proc" src="@/assets/img_stamp_color.png" v-if="owners.color" alt="stamp">
            <img class="w100Proc" src="@/assets/img_stamp_bw.png" v-if="!owners.color" alt="stamp">
          </div>
        </div>
        <p class="m-t-3 fw500 text-align-center text-gray-text" v-if="contract && contract.owner">With reference: m/v {{contract.name}} / {{contract.owner.company_name}}</p>
      </div>

      <files-attach class="m-b-6" :isEdit="false" v-if="owners && owners.files.length" :files="owners.files"/>


      <template>
        <div>
          <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1745"
              filename="SOA sublet"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              ref="html2Pdf"
              @hasDownloaded="hasDownloaded"
          >
            <div slot="pdf-content" class="pdf-wrap">
              <actions class="pdf-not-see-children vessel_owners__table-pdf" :pdf="true" :contract="contract" :owners="owners"/>

              <div class=" pdf-not-see-children">
                <div class="flex items-center p-b-8 position-relative">
                  <div class="border-b border-gray-dark border-for-position"></div>
                  <svg class="m-r-6" width="75" height="97" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2682_4874)"><path d="M42.525 41.546C59.7 44.036 72.95 59.106 72.95 77.251a36.566 36.566 0 01-1.93 11.764l1.94.68a38.803 38.803 0 001.814-16.615 38.622 38.622 0 00-5.337-15.811 37.884 37.884 0 00-11.466-11.975 37.13 37.13 0 00-15.395-5.843l-.052 2.095zM33.104 41.457C15.628 43.667 2.05 58.876 2.05 77.247a36.594 36.594 0 001.88 11.608l-1.941.671A38.804 38.804 0 01.256 72.813a38.61 38.61 0 015.488-15.851 37.863 37.863 0 0111.648-11.917A37.115 37.115 0 0132.94 39.37l.164 2.086z" fill="#113051"/><path d="M34.425 21.96v46.444s-11.085.755-20.044 8.578c0 0 14.825-22.253 20.044-55.021z" fill="#113051"/><path d="M39.779 0v68.267s11.165.231 20.847 7.998C49.669 53.918 42.644 28.556 39.779 0z" fill="#17A1E4"/><path d="M15.656 96.837V94.74h8.846c1.104 0 1.721-.627 1.721-1.564 0-1.024-.617-1.553-1.72-1.553H19.08c-2.221 0-3.604-1.436-3.604-3.418 0-1.933 1.284-3.37 3.637-3.37h8.477v2.098h-8.477c-.942 0-1.494.561-1.494 1.47 0 .91.573 1.449 1.478 1.449h5.405c2.393 0 3.669 1.138 3.669 3.477 0 2.015-1.196 3.517-3.669 3.517l-8.846-.009zM30.753 96.836V84.83h2.06v9.91h7.935v2.096h-9.995zM57.733 96.835l-2.258-8.439-3.344 7.812c-.227.512-.552.792-1.054.792s-.854-.28-1.056-.792l-3.329-7.812-2.255 8.44h-2.03l3.036-11.301c.145-.578.47-.86 1.025-.86.224 0 .442.066.628.193.185.127.329.308.411.519l3.638 8.49 3.637-8.49c.072-.208.206-.388.384-.515.177-.128.388-.196.605-.196.504 0 .829.281.991.859l3.02 11.3h-2.05z" fill="#113051"/></g><defs><clipPath id="clip0_2682_4874"><path fill="#fff" d="M0 0h75v97H0z"/></clipPath></defs></svg>
                  <div class="m-t-5">
                    <h1 class="m-b-1 fz18 fw500">Speed Logistics Marine LTD</h1>
                    <p class="fz13 text-gray-text text-uppercase">Office B on 15/F Wayson Commercial House, <br> 68-70 Lockhart Road, Wai Chai, Hong Kong</p>
                  </div>
                  <div class="text-align-right m-l-auto m-t-5">
                    <h2 class="fz20 fw500 uppercase">Statement of Accounts</h2>
                    <div v-if="owners && owners.identifier">
                      <p class="fz14 text-gray-text mt8">Number</p>
                      <p class="fz14 text-dark mt2">{{owners.identifier}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex p-b-3 p-t-6">
                <h1 class="fz16 text-dark fw500">
                  {{contract.name}} /
                  <template v-if="getContractOwnerName">{{getContractOwnerName}} / </template>
                  CP Date {{getCharterer.cp_date | moment("DD.MM.YYYY")}}
                </h1>
                <div class="m-l-auto text-align-right text-gray-text fz13" style="width: 18.9%">Debit, {{owners && owners.currency ? owners.currency : 'USD'}}</div>
                <div class="text-align-right text-gray-text fz13" style="width: 18.9%">Credit, {{owners && owners.currency ? owners.currency : 'USD'}}</div>
              </div>
              <hire-table :activeCurrency="owners ? owners.currency : 'USD'" class="vessel_owners__table-pdf" :hireData="owners ? owners.charter_hire : []"/>
              <div><div class="p-b-4"></div></div>
              <bunkers-on-table class="vessel_owners__table-pdf" v-if="owners && (getDelivery.length !== 0 || getRedelivery.length !== 0)" :bunkersOnData="owners ? owners.bunkers_delivery : []" :owners="owners"/>
              <empty-bunkers-on-table class="vessel_owners__table-pdf" v-else/>
              <div><div class="p-b-4"></div></div>
              <bunkers-on-table-c-e-v class="cevTable" :bunkersOnData="owners ? owners.bunkers_delivery : []"  v-if="getCEV && getCEV.additional_info && getCEV.additional_info.rate" :owners="owners"></bunkers-on-table-c-e-v>
              <div><div class="p-b-4"></div></div>
              <balast-table class="vessel_owners__table-pdf" v-if="owners && owners.balast_bonus" :balastData="owners.balast_bonus"/>
              <div v-if="owners && owners.balast_bonus"><div class="p-b-4"></div></div>
              <deposit-table class="vessel_owners__table-pdf" v-if="owners && owners.chrtrs" :chrtrsData="owners.chrtrs"/>
              <div v-if="owners && owners.chrtrs"><div class="p-b-4"></div></div>
              <bunkers-table :activeCurrency="owners ? owners.currency : 'USD'" class="vessel_owners__table-pdf" v-if="owners && owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'}).length" :bunkersData="owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'})[0]"/>
              <div v-if="owners && owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'}).length"><div class="p-b-4"></div></div>
              <bunkers-supply-table :activeCurrency="owners ? owners.currency : 'USD'" class="vessel_owners__table-pdf" v-if="owners && owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'}).length" :bunkersData="owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'})[0]"/>
              <div v-if="owners && owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'}).length"><div class="p-b-4"></div></div>
              <off-hire-table :activeCurrency="owners ? owners.currency : 'USD'" class="vessel_owners__table-pdf" v-if="owners && owners.hire_off.length" :hireData="owners ? owners.hire_off : []"/>
              <div v-if="owners && owners.hire_off.length"><div class="p-b-4"></div></div>
              <speed-table :activeCurrency="owners ? owners.currency : 'USD'" class="vessel_owners__table-pdf" v-if="owners && owners.speed_claim.length" :speedData="owners ? owners.speed_claim : []"/>
              <div v-if="owners && owners.speed_claim.length"><div class="p-b-4"></div></div>
              <charterers-table class="vessel_owners__table-pdf" v-if="owners && owners.expenses.filter(el=>{return el.expense_type == 'charterer_expense'}).length" :expensesData="owners.expenses.filter(el=>{return el.expense_type == 'charterer_expense'})"/>
              <div v-if="owners && owners.expenses.filter(el=>{return el.expense_type == 'charterer_expense'}).length"><div class="p-b-4"></div></div>
              <owners-table class="vessel_owners__table-pdf" v-if="owners && owners.expenses.filter(el=>{return el.expense_type == 'owners_expense'}).length" :expensesData="owners.expenses.filter(el=>{return el.expense_type == 'owners_expense'})"/>
              <div v-if="owners && owners.expenses.filter(el=>{return el.expense_type == 'owners_expense'}).length"><div class="p-b-4"></div></div>
              <remittances-table class="vessel_owners__table-pdf" v-if="owners" :remittances="owners.additional_info.remittances"/>
              <div v-if="owners"><div class="p-b-4"></div></div>
              <remittances-table class="vessel_owners__table-pdf" v-if="localRemittances" :remittances="localRemittances"/>
              <div v-if="localRemittances"><div class="p-b-2"></div></div>
              <div class="vessel_owners__table bg-gray-dark rounded-lg overflow-hidden pdf-not-see-children vessel_owners__table-pdf" v-if="owners">
                <div class="first_element_stroke colspan-1-4 border-b border-r uppercase p-3 fz16 fw500">Total</div>
                <div class="border-b border-r p-3 fz16 fw500 text-align-right">{{owners.total_debit | formatNumberTwoZeroEmpty}}</div>
                <div class="border-b p-3 fz16 fw500 text-align-right">{{owners.total_credit | formatNumberTwoZeroEmpty}}</div>

                <div class="colspan-1-4 border-r uppercase p-3 fz16 fw500">
                  {{owners.balance >= 0 ? 'BALANCE DUE TO CHARTERS' : 'BALANCE DUE TO OWNERS'}}
                </div>
                <div class="border-r p-3 fz16 fw500 text-align-right">
                  <template v-if="owners.balance >= 0">{{Math.abs(owners.balance) | formatNumberTwoZeroEmpty}}</template>
                </div>
                <div class="p-3 fz16 fw500 text-align-right">
                  <template v-if="owners.balance < 0">{{Math.abs(owners.balance) | formatNumberTwoZeroEmpty}}</template>
                </div>
              </div>
              <div class="vessel_owners__table bg-gray-dark rounded-lg overflow-hidden pdf-not-see-children vessel_owners__table-pdf" v-else>
                <div class="first_element_stroke colspan-1-4 border-b border-r uppercase p-3 fz16 fw500">Total</div>
                <div class="border-b border-r p-3 fz16 fw500 text-align-right">{{localDebit | formatNumberTwoZeroEmpty}}</div>
                <div class="border-b p-3 fz16 fw500 text-align-right">{{localCredit | formatNumberTwoZeroEmpty}}</div>

                <div class="colspan-1-4 border-r uppercase p-3 fz16 fw500">
                  {{localBalance >= 0 ? 'BALANCE DUE TO CHARTERS' : 'BALANCE DUE TO OWNERS'}}
                </div>
                <div class="border-r p-3 fz16 fw500 text-align-right">
                  <template v-if="localBalance >= 0">{{Math.abs(localBalance) | formatNumberTwoZeroEmpty}}</template>
                </div>
                <div class="p-3 fz16 fw500 text-align-right">
                  <template v-if="localBalance < 0">{{Math.abs(localBalance) | formatNumberTwoZeroEmpty}}</template>
                </div>
              </div>
              <div><div class="p-b-4"></div></div>
              <div class="flex pdf-bottom-block vessel_owners__table-pdf ">
                <div class="w-2/3">
                  <div class="m-b-6" v-if="owners && owners.linked_payments.length">
                    <p class="text-gray-text fz13 m-b-2">Linked Operation</p>
                    <p
                        class="p-b-1 fz14"
                        v-for="payment in owners.linked_payments"
                        :key="payment.id"
                    >
                      <span v-if="payment.payment_type == 'expense'">Payment</span>
                      <span v-if="payment.payment_type == 'income'">Income</span>
                      #{{payment.id}}
                      <span v-if="payment.execute_date">({{payment.execute_date | formatDate}})</span>
                    </p>
                  </div>
                  <div class="" v-if="owners && owners.comment">
                    <p class="text-gray-text fz13 m-b-2">Note</p>
                    <p class="fz14 word-break-all">
                      {{owners.comment}}
                    </p>
                  </div>
                  <div class="m-t-6" v-if="owners && getSoaRequisite">
                    <div v-if="owners.bank_details">
                      <h3 class="fw500 m-b-3">
                        Please remit
                        <span class="text-red fw500">{{owners.currency}} {{owners.balance | formatNumberTwoInf }}</span>
                        to following bank account not later than on <span class="text-red fw500">{{owners.due_date | formatDateWithotTZ}}</span>:
                      </h3>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">Holder:</span> {{getSoaRequisite.holder}}
                      </p>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">Account Number:</span> {{getSoaRequisite.account_number}}
                      </p>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">IBAN:</span> {{getSoaRequisite.iban}}
                      </p>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">Description:</span> {{getSoaRequisite.description}}
                      </p>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">Bank:</span> {{getSoaRequisite.bank}}
                      </p>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">Bank Address:</span> {{getSoaRequisite.bank_address}}
                      </p>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">Clearing number:</span> {{getSoaRequisite.clearing_number}}
                      </p>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">BIC / SWIFT:</span> {{getSoaRequisite.bic}}
                      </p>
                      <p class="m-t-1 fz14">
                        <span class="text-gray-text">Currency:</span> {{getSoaRequisite.currency}}
                      </p>
                    </div>
                  </div>
                </div>
                <!--        <div class="w-1/3 m-t-auto text-align-right">-->
                <!--          <img src="@/assets/img_stamp_color.png" alt="stamp">-->
                <!--        </div>-->
                <div class="w-1/3 m-t-auto text-align-right min-h-184 pr10" v-if="owners && owners.stamp">
                  <img class="w100Proc" src="@/assets/img_stamp_color.png" v-if="owners.color" alt="stamp">
                  <img class="w100Proc" src="@/assets/img_stamp_bw.png" v-if="!owners.color" alt="stamp">
                </div>
              </div>
            </div>
          </vue-html2pdf>
        </div>
      </template>



    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueHtml2pdf)

import filesAttach from '@/components/vessels/owners/filesAttach'
import actions from '@/components/vessels/sublet/actions'
import hireTable from '@/components/vessels/owners/hireTable'
import bunkersOnTable from '@/components/vessels/owners/bunkersOnTable'
import emptyBunkersOnTable from '@/components/vessels/owners/emptyBunkersOnTable'
import balastTable from '@/components/vessels/owners/balastTable'
import depositTable from '@/components/vessels/owners/depositTable'
import bunkersTable from '@/components/vessels/owners/bunkersTable'
import bunkersSupplyTable from '@/components/vessels/owners/bunkersSupplyTable'
import offHireTable from '@/components/vessels/owners/offHireTable'
import speedTable from '@/components/vessels/owners/speedTable'
import charterersTable from '@/components/vessels/owners/charterersTable'
import ownersTable from '@/components/vessels/owners/ownersTable'
import remittancesTable from '@/components/vessels/owners/remittancesTable'
import BunkersOnTableCEV from "@/components/vessels/owners/bunkersOnTableCEV";
// import axios from "axios";

export default {
  components: {
    BunkersOnTableCEV,
    filesAttach,
    actions,
    hireTable,
    bunkersOnTable,
    emptyBunkersOnTable,
    balastTable,
    depositTable,
    bunkersTable,
    bunkersSupplyTable,
    offHireTable,
    speedTable,
    charterersTable,
    ownersTable,
    remittancesTable,
  },
  data() {
    return {
      blackWhiteExport: false,
      localRemittances: null,
      localDebit: 0,
      localCredit: 0,
      localBalance: 0,
      fuels: [
        'VLSFO',
        'LSMGO',
        'HSFO',
        'MGO',
        'VLSFO_2',
        'LSMGO_2'
      ],
    }
  },
  computed: {
    ...mapGetters(['getDartMode', 'getPaymentListOriginal']),
    ...mapState({
      getCEV() {
        let bunkersOnData = this.owners ? this.owners.bunkers_delivery : []
        return bunkersOnData.find(el => {
          return el.bunkers_type == 'C/E/V'
        })
      },
      contract: state => state.contract.activeContract,
      sublet: state => state.contract.activeSubcontract,
      owners: state => state.owners.activeOwners,
      historyList: state => state.owners.ownersHistoryList,
      requisites: state => state.banks.requisites
    }),
    getStopCheckStatementMember() {
      let owners = []
      owners.push({id: 'main', owner: this.contract.owner ? this.contract.owner.id : ''})
      this.contract.cargo_contracts.forEach(cargo=> {
        owners.push({id: cargo.id, owner: cargo.owner ? cargo.owner.id : ''})
      })
      return this.sublet.owner ? owners.filter(a=> a.owner == this.sublet.owner.id).length == 1 : false
      // return this.sublet.owner ? owners.findIndex(a=> a.id == this.sublet.id) == owners.findIndex(a=> a.owner == this.sublet.owner.id) : false
    },
    getDelivery() {
      let delivery = (this.owners ? this.owners.bunkers_delivery : []).filter(el => {
        return el.additional_info.is_row && el.bunkers_type == 'DELIVERY' &&
            (el.credit || el.debit ||
                el.additional_info.price ||
                el.additional_info.quantity)
      })

      let list = []
      this.fuels.forEach(el => {
        let fuel = delivery.find(item => item.fuel_type == el)
        if(fuel)
          list.push(delivery.find(item => item.fuel_type == el))
      })
      return list
    },
    getRedelivery() {
      let delivery = (this.owners ? this.owners.bunkers_delivery : []).filter(el => {
        return el.additional_info.is_row && el.bunkers_type == 'REDELIVERY' &&
            (el.credit || el.debit ||
                el.additional_info.price ||
                el.additional_info.quantity)
      })

      let list = []
      this.fuels.forEach(el => {
        let fuel = delivery.find(item => item.fuel_type == el)
        if(fuel)
          list.push(delivery.find(item => item.fuel_type == el))
      })
      return list
    },
    getCharterer() {
      return this.contract.cargo_contracts.find(el => el.id == this.$route.params.subId)
    },
    getSoaRequisite() {
      if(!this.owners || !this.owners.bank_accounts || !this.requisites) return null

      return this.requisites.find(el=>{
        return el.id == this.owners.bank_accounts
      })
    },
    getFilteredRemitances() {
      if(!this.getPaymentListOriginal.length) return []
      let subId = this.$route.params.subId
      let clientId = subId ? this.getCharterer?.owner?.id : this.contract?.owner?.id
      let filtered = this.getPaymentListOriginal.filter(el => {
        return el.client?.id == clientId && el.paid_date && !el.is_archive && el.status == 'paid' && (this.getStopCheckStatementMember || (el.statement_member && el.statement_member === this.getCharterer?.cp_date))
      })

      if(subId && this.getCharterer && this.getCharterer.id == subId){
        filtered = filtered.filter(el => {
          return this.getStopCheckStatementMember || el.statement_member == this.getCharterer.cp_date
        })
      }
      return filtered.map(el => {
        let debit = el.payment_type == 'expense' ? el.payment_amount : null
        let credit = el.payment_type == 'expense' ? null : el.payment_amount

        let isRow = true
        let oldEl = this.owners?.additional_info.remittances.find(item => item.id==el.id)
        if(oldEl && Object.prototype.hasOwnProperty.call(oldEl, 'is_row')){
          isRow = oldEl.is_row
        }

        let obj = {
          id: el.id,
          date: moment(el.paid_date).format('DD.MM.YYYY'),
          debit: debit,
          credit: credit,
          is_row: isRow
        }
        return obj
      })

    },
    getContractOwnerName() {
      if(this.$route.params.subId){
        let subcontract = this.contract.cargo_contracts.find(el=>el.id == this.$route.params.subId)
        return subcontract.owner.company_name
      }else{
        return this.contract.owner.company_name
      }
    }
  },
  methods: {
    ...mapActions(["axiosGetXLSX", "axiosGetPDF", 'axiosOpenOwnersById', 'axiosGetOwnersHistory', 'axiosGetRequisites', 'axiosGetPayment']),
    ...mapMutations(['showLoad', 'hideLoad', 'setDartMode', 'setTurnOnDarkModeAfterDownloadPDF']),
    hasDownloaded() {
      this.blackWhiteExport = false
      this.hideLoad();
    },
    getValueFromXml(value, numberAfterDot) {
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined) {
        if(moment(""+value, "YYYY-MM-DD HH:mm:ss", true).isValid() || moment(""+value, "YYYY-MM-DD HH:mm", true).isValid() || moment(""+value, "YYYY-MM-DD", true).isValid()) {
          return moment(value).format("DD.MM.YYYY")
        }
        let perc = false;
        if(value[value.length-1] === '%') {
          perc = true;
          value = value.replaceAll('%','')
        }
        if(!isNaN(value)) {
          let bool = false;
          if(value<0) {
            bool = true
          }
          let fixedNumber = value ? value : 0
          if(bool) {
            fixedNumber = fixedNumber*(-1)
          }
          if(numberAfterDot) {
            fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: numberAfterDot, maximumFractionDigits: numberAfterDot})
          } else {
            fixedNumber = fixedNumber.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
          }
          if((''+fixedNumber).indexOf('.') === -1) {
            fixedNumber+='.00'
          }
          if(perc) {
            fixedNumber+='%'
          }
          if(bool) {
            return '('+fixedNumber + ')'
          }
          return fixedNumber
        }
        return ""+value
      }
      return null
    },

    downloadXLSX() {
      let formAxios= {
        sheet_title: "SOA Sublet",
        file_name: "SOA Sublet",
        data: []
      }
      console.log(this.sublet)
      // if(this.sublet?.name && this.sublet?.owner?.company_name && this.sublet?.cp_date) {
      formAxios.data.push({id:formAxios.data.length + 1, values: ['http://'+this.getIP()+'/media/invoice_img_1.jpg', null, null, null, null, null, 'STATEMENT OF ACCOUNTS']});
      if(this.owners && this.owners.identifier)
        formAxios.data.push({id: formAxios.data.length + 1, values: [null, null, null, null, null, null, 'Number:', this.owners.identifier]});
      formAxios.data.push({id: formAxios.data.length + 1, values: []});
      formAxios.data.push({id: formAxios.data.length + 1, values: []});
      formAxios.data.push({id: formAxios.data.length + 1, values: []});
      formAxios.data.push({id: formAxios.data.length + 1, values: []});
      formAxios.data.push({id: formAxios.data.length + 1, values: []});
      formAxios.data.push({id: formAxios.data.length + 1, values: []});
      formAxios.data.push({id: formAxios.data.length + 1, values: []});

      let hire_table = this.owners ? this.owners.charter_hire : [];
      if(hire_table.length !== 0) {
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(this.contract.name) +  (this.getContractOwnerName ? ' / ' + this.getValueFromXml(this.getContractOwnerName) : '') + (this.getCharterer.cp_date ? ' / ' + this.getValueFromXml(this.getCharterer.cp_date) : ''), null, null, null, "Debit, "+(this.owners ? this.owners.currency : 'USD')+"", "Credit, "+(this.owners ? this.owners.currency : 'USD')+""]});

      }
      for(let i=0; i < hire_table.length; i++) {
        if(hire_table[i].additional_info) {
          if(formAxios.data[formAxios.data.length-1].values.length === 0) {
            formAxios.data.push({id: formAxios.data.length + 1, values: []});
          }
          formAxios.data.push({id:formAxios.data.length + 1, values: ["CHARTER HIRE #" + (i +1), null, "Rate, "+(this.owners ? this.owners.currency : 'USD')+"/day:", this.getValueFromXml(hire_table[i].additional_info.rate) !== null ? (this.getValueFromXml(hire_table[i].additional_info.rate)) : null, null]});
          formAxios.data.push({id:formAxios.data.length + 1, values: [null, 'UTC', null, hire_table[i].additional_info.is_days ? 'Days' : null]});
          if(hire_table[i].additional_info.from_time || hire_table[i].additional_info.from_date)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["From", this.getValueFromXml(hire_table[i].additional_info.from_time) !== null ? (this.getValueFromXml(hire_table[i].additional_info.from_time)) : null, this.getValueFromXml(hire_table[i].additional_info.from_date), null, null]});
          if(hire_table[i].additional_info.to_time || hire_table[i].additional_info.to_date || hire_table[i].additional_info.days || hire_table[i].hire_debit || hire_table[i].hire_credit)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["To", this.getValueFromXml(hire_table[i].additional_info.to_time) !== null ? (this.getValueFromXml(hire_table[i].additional_info.to_time)) : null, this.getValueFromXml(hire_table[i].additional_info.to_date), hire_table[i].additional_info.is_days ? this.getValueFromXml(hire_table[i].additional_info.days) : null, hire_table[i].additional_info.is_days ? this.getValueFromXml(hire_table[i].hire_debit) : null, hire_table[i].additional_info.is_days ? this.getValueFromXml(hire_table[i].hire_credit) : null]});
          // formAxios.data.push({id:formAxios.data.length + 1, values: ["Days", null, this.getValueFromXml(hire_table[i].additional_info.days),this.getValueFromXml(hire_table[i].hire_debit), this.getValueFromXml(hire_table[i].hire_credit)]});
          formAxios.data.push({id:formAxios.data.length + 1, values: []});
          if(hire_table[i].additional_info.is_commission && (hire_table[i].additional_info.commision || hire_table[i].commision_debit || hire_table[i].commision_credit)) {
            formAxios.data.push({id:formAxios.data.length + 1, values: ["COMMISSION", null, null, this.getValueFromXml(hire_table[i].additional_info.commision) !== null ? this.getValueFromXml(hire_table[i].additional_info.commision)+"%" : "%", this.getValueFromXml(hire_table[i].commision_debit),this.getValueFromXml(hire_table[i].commision_credit)]});
            formAxios.data.push({id:formAxios.data.length + 1, values: []});
          }
          if(hire_table[i].additional_info.is_broker && (hire_table[i].additional_info.broker_commision || hire_table[i].broker_commision_debit || hire_table[i].broker_commision_credit)) {
            formAxios.data.push({id:formAxios.data.length + 1, values: ["BROKER COMMISSION", null, null, this.getValueFromXml(hire_table[i].additional_info.broker_commision) !== null ? this.getValueFromXml(hire_table[i].additional_info.commision)+"%" : "%", this.getValueFromXml(hire_table[i].broker_commision_debit),this.getValueFromXml(hire_table[i].broker_commision_credit)]});
            formAxios.data.push({id:formAxios.data.length + 1, values: []});
          }
        }
      }

      let bunkers_on_table = this.owners ? this.owners.bunkers_delivery : [];
      if(bunkers_on_table.length !== 0) {
        let getDelivery = bunkers_on_table.filter(el => {
          return el.additional_info.is_row && el.bunkers_type == 'DELIVERY' &&
              (el.fuel_type == 'VLSFO_2' || el.fuel_type == 'LSMGO_2' ||
                  el.credit || el.debit ||
                  el.additional_info.price ||
                  el.additional_info.quantity)
        })
        let list = []
        this.fuels.forEach(el => {
          let fuel = getDelivery.find(item => item.fuel_type == el)
          if(fuel)
            list.push(getDelivery.find(item => item.fuel_type == el))
        })
        getDelivery =  list
        let getRedelivery = bunkers_on_table.filter(el => {
          return el.additional_info.is_row && el.bunkers_type == 'REDELIVERY' &&
              (el.fuel_type == 'VLSFO_2' || el.fuel_type == 'LSMGO_2' ||
                  el.credit || el.debit ||
                  el.additional_info.price ||
                  el.additional_info.quantity)
        })
        list = []
        this.fuels.forEach(el => {
          let fuel = getRedelivery.find(item => item.fuel_type == el)
          if(fuel)
            list.push(getRedelivery.find(item => item.fuel_type == el))
        })
        getRedelivery = list
        if(getDelivery.length !== 0) {
          let getDeliverLSMGO_ADD = bunkers_on_table.find(el => {
            return el.bunkers_type == 'DELIVERY' && el.fuel_type == 'LSMGO'
          })
          formAxios.data.push({id:formAxios.data.length + 1, values: [(getDeliverLSMGO_ADD.additional_info.checkboxes == 'estimated' ? 'ESTIMATED ':'') +"BUNKERS ON DELIVERY"]});
          formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, "Price, "+(this.owners ? this.owners.currency : 'USD')+"/mt", null, null]})
        }
        for(let i=0; i<getDelivery.length; i++) {
          if(getDelivery[i].additional_info.quantity || getDelivery[i].additional_info.price || getDelivery[i].debit || getDelivery[i].credit)
            formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(getDelivery[i].additional_info.name ? getDelivery[i].additional_info.name : (getDelivery[i].fuel_type == 'VLSFO_2' ? 'VLSFO' : getDelivery[i].fuel_type == 'LSMGO_2' ? 'LSMGO' : getDelivery[i].fuel_type)), this.getValueFromXml(getDelivery[i].additional_info.quantity, 3), 'MT', this.getValueFromXml(getDelivery[i].additional_info.price), this.getValueFromXml(getDelivery[i].debit), this.getValueFromXml(getDelivery[i].credit)]})
        }
        if(getDelivery.length !== 0) {
          formAxios.data.push({id:formAxios.data.length + 1, values: []});
        }
        if(getRedelivery.length !== 0) {
          let getRedeliverLSMGO_ADD = bunkers_on_table.find(el => {
            return el.bunkers_type == 'REDELIVERY' && el.fuel_type == 'LSMGO'
          })
          formAxios.data.push({id:formAxios.data.length + 1, values: [(getRedeliverLSMGO_ADD.additional_info.checkboxes == 'estimated' ? 'ESTIMATED ':'')+"BUNKERS ON REDELIVERY"]});
          // formAxios.data.push({id:formAxios.data.length + 1, values: [null, "Qntty, mt", "Price, USD/mt", null, null]})
          formAxios.data.push({id:formAxios.data.length + 1, values: [null, null, null, "Price, "+(this.owners ? this.owners.currency : 'USD')+"/mt", null, null]})
        }
        for(let i=0; i<getRedelivery.length; i++) {
          if(getRedelivery[i].additional_info.quantity || getRedelivery[i].additional_info.price || getRedelivery[i].debit || getRedelivery[i].credit)
            formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(getRedelivery[i].additional_info.name ? getRedelivery[i].additional_info.name : (getRedelivery[i].fuel_type == 'VLSFO_2' ? 'VLSFO' : getRedelivery[i].fuel_type == 'LSMGO_2' ? 'LSMGO' : getRedelivery[i].fuel_type)), this.getValueFromXml(getRedelivery[i].additional_info.quantity, 3), 'MT', this.getValueFromXml(getRedelivery[i].additional_info.price), this.getValueFromXml(getRedelivery[i].debit), this.getValueFromXml(getRedelivery[i].credit)]})
        }
        let getCV = bunkers_on_table.find(el => {
          return el.bunkers_type == 'C/E/V'
        })
        if(getCV) {
          if((getCV.additional_info.rate || getCV.debit || getCV.credit) && getCV.additional_info.is_row) {
            formAxios.data.push({id:formAxios.data.length + 1, values: []});
            formAxios.data.push({id:formAxios.data.length + 1, values: ["C/E/V", null, "Rate, "+(this.owners ? this.owners.currency : 'USD')+"/PMPR:", this.getValueFromXml(getCV.additional_info.rate) !== null ? this.getValueFromXml(getCV.additional_info.rate) : null, this.getValueFromXml(getCV.debit), this.getValueFromXml(getCV.credit)]});
          }
        }

        formAxios.data.push({id:formAxios.data.length + 1, values: []});

      }
      if(this.owners) {
        let balast_bonus = this.owners.balast_bonus;
        if(this.owners && this.owners?.balast_bonus && (balast_bonus?.commision_percentage ||
            balast_bonus?.commision_debit ||
            balast_bonus?.commision_credit ||
            balast_bonus?.gross_balast_debit ||
            balast_bonus?.gross_balast_credit) && (balast_bonus.additional_info.is_bonus || balast_bonus.additional_info.is_commission)) {
          formAxios.data.push({id:formAxios.data.length + 1, values: ["BALLAST BONUS", null, null, this.getValueFromXml(balast_bonus.balast_debit), this.getValueFromXml(balast_bonus.balast_credit)]});
          if(balast_bonus.additional_info.is_bonus)
            formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(balast_bonus.balast_type), null, null, null, this.getValueFromXml(balast_bonus.gross_balast_debit), this.getValueFromXml(balast_bonus.gross_balast_credit)]});
          if(balast_bonus.balast_type == 'Gross balast bonus' && (
              balast_bonus.commision_percentage || balast_bonus.commision_debit || balast_bonus.commision_credit) && balast_bonus.additional_info.is_commission)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["Commission", null, null, this.getValueFromXml(balast_bonus.commision_percentage) !== null ? this.getValueFromXml(balast_bonus.commision_percentage) + "%" : "%", this.getValueFromXml(balast_bonus.commision_debit), this.getValueFromXml(balast_bonus.commision_credit)]});
          formAxios.data.push({id:formAxios.data.length + 1, values: []});
        }

        let deposit_table = this.owners.chrtrs;
        if(this.owners && this.owners.chrtrs && (deposit_table?.deposit_paid_debit ||
            deposit_table?.deposit_paid_credit ||
            deposit_table?.deposit_revert_debit ||
            deposit_table?.deposit_revert_credit) && (deposit_table.additional_info.is_paid || deposit_table.additional_info.is_revert)) {
          if(deposit_table.additional_info.is_paid)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["DEPOSIT PAID BY CHRTS", null, null, null, this.getValueFromXml(deposit_table.deposit_paid_debit), this.getValueFromXml(deposit_table.deposit_paid_credit)]});
          if(deposit_table.additional_info.is_revert)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["DEPOSIT REVERT TO CHRTS", null, null, null, this.getValueFromXml(deposit_table.deposit_revert_debit), this.getValueFromXml(deposit_table.deposit_revert_credit)]});
          formAxios.data.push({id:formAxios.data.length + 1, values: []});
        }

        let bunkers_table = this.owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'})[0];
        if(this.owners && this.owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers'}).length) {
          if((bunkers_table.additional_info.vlsfo_quantity ||
              bunkers_table.additional_info.vlsfo_price ||
              bunkers_table.vlsfo_debit ||
              bunkers_table.vlsfo_credit ||
              bunkers_table.additional_info.lsmgo_quantity ||
              bunkers_table.additional_info.lsmgo_price ||
              bunkers_table.lsmgo_debit ||
              bunkers_table.lsmgo_credit) && (bunkers_table.additional_info.is_vlsfo || bunkers_table.additional_info.is_lsmgo)) {
            formAxios.data.push({id:formAxios.data.length + 1, values: ["EXCESS BUNKER ON REDEL."]});
            formAxios.data.push({id:formAxios.data.length + 1, values: [null, null,null, "Price, "+(this.owners ? this.owners.currency : 'USD')+"/mt", null, null]});
            if((bunkers_table.additional_info.vlsfo_quantity ||
                bunkers_table.additional_info.vlsfo_price ||
                bunkers_table.vlsfo_debit ||
                bunkers_table.vlsfo_credit) && bunkers_table.additional_info.is_vlsfo)
              formAxios.data.push({id:formAxios.data.length + 1, values: ["VLSFO", this.getValueFromXml(bunkers_table.additional_info.vlsfo_quantity), 'MT', this.getValueFromXml(bunkers_table.additional_info.vlsfo_price), this.getValueFromXml(bunkers_table.vlsfo_debit), this.getValueFromXml(bunkers_table.vlsfo_credit)]});
            if((bunkers_table.additional_info.lsmgo_quantity ||
                bunkers_table.additional_info.lsmgo_price ||
                bunkers_table.lsmgo_debit ||
                bunkers_table.lsmgo_credit) && bunkers_table.additional_info.is_lsmgo)
              formAxios.data.push({id:formAxios.data.length + 1, values: ["LSMGO", this.getValueFromXml(bunkers_table.additional_info.lsmgo_quantity), 'MT', this.getValueFromXml(bunkers_table.additional_info.lsmgo_price), this.getValueFromXml(bunkers_table.lsmgo_debit), this.getValueFromXml(bunkers_table.lsmgo_credit)]});
            formAxios.data.push({id:formAxios.data.length + 1, values: []});
          }
        }
        let bunker_supply_table = this.owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'})[0];
        if(this.owners && this.owners.bunkers.filter(el=>{return el.bunker_type == 'bunkers_supply'}).length && (bunker_supply_table?.additional_info.vlsfo_quantity ||
            bunker_supply_table?.additional_info.vlsfo_price ||
            bunker_supply_table?.vlsfo_debit ||
            bunker_supply_table?.vlsfo_credit ||
            bunker_supply_table?.additional_info.lsmgo_quantity ||
            bunker_supply_table?.additional_info.lsmgo_price ||
            bunker_supply_table?.lsmgo_debit ||
            bunker_supply_table?.lsmgo_credit ||
            bunker_supply_table?.additional_info.comment) && (bunker_supply_table?.additional_info.is_vlsfo || bunker_supply_table?.additional_info.is_lsmgo)) {
          formAxios.data.push({id:formAxios.data.length + 1, values: ["BUNKER SUPPLY BY OWNS"]});
          formAxios.data.push({id:formAxios.data.length + 1, values: [null, null,null, "Price, "+(this.owners ? this.owners.currency : 'USD')+"/mt", null, null]});
          if((bunker_supply_table.additional_info.vlsfo_quantity ||
              bunker_supply_table.additional_info.vlsfo_price ||
              bunker_supply_table.vlsfo_debit ||
              bunker_supply_table.vlsfo_credit) && bunker_supply_table?.additional_info.is_vlsfo)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["VLSFO", this.getValueFromXml(bunker_supply_table.additional_info.vlsfo_quantity), 'MT', this.getValueFromXml(bunker_supply_table.additional_info.vlsfo_price), this.getValueFromXml(bunker_supply_table.vlsfo_debit), this.getValueFromXml(bunker_supply_table.vlsfo_credit)]});
          if((bunker_supply_table.additional_info.lsmgo_quantity ||
              bunker_supply_table.additional_info.lsmgo_price ||
              bunker_supply_table.lsmgo_debit ||
              bunker_supply_table.lsmgo_credit) && bunker_supply_table?.additional_info.is_lsmgo)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["LSMGO", this.getValueFromXml(bunker_supply_table.additional_info.lsmgo_quantity), 'MT', this.getValueFromXml(bunker_supply_table.additional_info.lsmgo_price), this.getValueFromXml(bunker_supply_table.lsmgo_debit), this.getValueFromXml(bunker_supply_table.lsmgo_credit)]});
          if(bunker_supply_table.additional_info.comment)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["Comment", bunker_supply_table.additional_info.comment ? bunker_supply_table.additional_info.comment : null, null, null, null]});
          formAxios.data.push({id:formAxios.data.length + 1, values: []});
        }

        let off_gire_table = this.owners ? this.owners.hire_off : [];
        if(off_gire_table.length !== 0) {
          for(let i=0; i<off_gire_table.length; i++) {
            if(formAxios.data[formAxios.data.length-1].values.length === 0) {
              formAxios.data.push({id: formAxios.data.length + 1, values: []});
            }
            formAxios.data.push({id:formAxios.data.length + 1, values: ["OFF-HIRE #" + (i+1), off_gire_table[i].additional_info.title ? off_gire_table[i].additional_info.title : null, null, null]});
            if(off_gire_table[i].additional_info.from_time || off_gire_table[i].additional_info.from_date)
              formAxios.data.push({id:formAxios.data.length + 1, values: ["From", this.getValueFromXml(off_gire_table[i].additional_info.from_time + " " + off_gire_table[i].additional_info.time_type), this.getValueFromXml(off_gire_table[i].additional_info.from_date), null, null]});
            if(off_gire_table[i].additional_info.to_time || off_gire_table[i].additional_info.to_date || off_gire_table[i].additional_info.days || off_gire_table[i].hire_debit || off_gire_table[i].hire_credit)
              formAxios.data.push({id:formAxios.data.length + 1, values: ["To", this.getValueFromXml(off_gire_table[i].additional_info.to_time + " " + off_gire_table[i].additional_info.time_type), this.getValueFromXml(off_gire_table[i].additional_info.to_date), off_gire_table[i].additional_info.is_days ? this.getValueFromXml(off_gire_table[i].additional_info.days) : null, off_gire_table[i].additional_info.is_days ? this.getValueFromXml(off_gire_table[i].hire_debit) : null, off_gire_table[i].additional_info.is_days ? this.getValueFromXml(off_gire_table[i].hire_credit) : null]});
            // formAxios.data.push({id:formAxios.data.length + 1, values: ["Days", null, this.getValueFromXml(off_gire_table[i].additional_info.days), this.getValueFromXml(off_gire_table[i].hire_debit), this.getValueFromXml(off_gire_table[i].hire_credit)]});
            formAxios.data.push({id:formAxios.data.length + 1, values: []});
            if(off_gire_table[i].additional_info.is_commission && ( off_gire_table[i].additional_info.commision || off_gire_table[i].commision_debit || off_gire_table[i].commision_credit)) {
              formAxios.data.push({id:formAxios.data.length + 1, values: ["Commission", null, null, this.getValueFromXml(off_gire_table[i].additional_info.commision) !== null ? this.getValueFromXml(off_gire_table[i].additional_info.commision) + "%" : null, this.getValueFromXml(off_gire_table[i].commision_debit), this.getValueFromXml(off_gire_table[i].commision_credit)]});
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            }
            if(off_gire_table[i].additional_info.is_cev && (off_gire_table[i].additional_info.rate || off_gire_table[i].cev_debit || off_gire_table[i].cev_credit)) {
              formAxios.data.push({id:formAxios.data.length + 1, values: ["C/E/V", null, null, this.getValueFromXml(off_gire_table[i].additional_info.rate) !== null ? this.getValueFromXml(off_gire_table[i].additional_info.rate) + " Rate, "+(this.owners ? this.owners.currency : 'USD')+"/PMPR" : null, this.getValueFromXml(off_gire_table[i].cev_debit), this.getValueFromXml(off_gire_table[i].cev_credit)]});
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            }
            if((off_gire_table[i].additional_info.is_hsfo || off_gire_table[i].additional_info.is_lsmgo) && (off_gire_table[i].additional_info.hsfo_quantity || off_gire_table[i].additional_info.hsfo_price || off_gire_table[i].hsfo_debit || off_gire_table[i].hsfo_credit || off_gire_table[i].additional_info.lsmgo_quantity || off_gire_table[i].additional_info.lsmgo_price || off_gire_table[i].lsmgo_debit || off_gire_table[i].lsmgo_credit || off_gire_table[i].additional_info.comment)) {
              formAxios.data.push({id:formAxios.data.length + 1, values: ["Bunkers consumed during off-hire", null, null, "Price, "+(this.owners ? this.owners.currency : 'USD')+"/mt", null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            }
            if(off_gire_table[i].additional_info.is_hsfo && (off_gire_table[i].additional_info.hsfo_quantity || off_gire_table[i].additional_info.hsfo_price || off_gire_table[i].hsfo_debit || off_gire_table[i].hsfo_credit))
              formAxios.data.push({id:formAxios.data.length + 1, values: ["HSFO", this.getValueFromXml(off_gire_table[i].additional_info.hsfo_quantity), 'MT', this.getValueFromXml(off_gire_table[i].additional_info.hsfo_price), this.getValueFromXml(off_gire_table[i].hsfo_debit), this.getValueFromXml(off_gire_table[i].hsfo_credit)]});
            if(off_gire_table[i].additional_info.is_lsmgo && (off_gire_table[i].additional_info.lsmgo_quantity || off_gire_table[i].additional_info.lsmgo_price || off_gire_table[i].lsmgo_debit || off_gire_table[i].lsmgo_credit))
              formAxios.data.push({id:formAxios.data.length + 1, values: ["LSMGO", this.getValueFromXml(off_gire_table[i].additional_info.lsmgo_quantity), 'MT', this.getValueFromXml(off_gire_table[i].additional_info.lsmgo_price), this.getValueFromXml(off_gire_table[i].lsmgo_debit), this.getValueFromXml(off_gire_table[i].lsmgo_credit)]});
            if(off_gire_table[i].additional_info.comment)
              formAxios.data.push({id:formAxios.data.length + 1, values: ["Comment", off_gire_table[i].additional_info.comment ? off_gire_table[i].additional_info.comment : null]});
          }
          formAxios.data.push({id:formAxios.data.length + 1, values: []});
        }
        let speed_table = this.owners ? this.owners.speed_claim : [];
        if(speed_table.length !== 0) {
          for(let i=0; i<speed_table.length; i++) {
            if(formAxios.data[formAxios.data.length-1].values.length === 0) {
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            }
            formAxios.data.push({id:formAxios.data.length + 1, values: []});
            formAxios.data.push({id:formAxios.data.length + 1, values: ["SPEED CLAIM #" + (i+1), speed_table[i].title ? speed_table[i].title : null, null, null, null]});
            if(speed_table[i].additional_info.is_days && (speed_table[i].additional_info.hours || speed_table[i].additional_info.days || speed_table[i].time_lost_debit || speed_table[i].time_lost_credit)) {
              formAxios.data.push({id:formAxios.data.length + 1, values: ["Time Lost", this.getValueFromXml(speed_table[i].additional_info.hours) !== null ? this.getValueFromXml(speed_table[i].additional_info.hours) + " hrs" : null, this.getValueFromXml(speed_table[i].additional_info.days) !== null ? this.getValueFromXml(speed_table[i].additional_info.days) + " days" : null, null, this.getValueFromXml(speed_table[i].time_lost_debit), this.getValueFromXml(speed_table[i].time_lost_credit)]});
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            }
            if( speed_table[i].additional_info.is_commission && (speed_table[i].additional_info.commision || speed_table[i].commision_debit || speed_table[i].commision_credit)) {
              formAxios.data.push({id:formAxios.data.length + 1, values: ["Commission", null, null, this.getValueFromXml(speed_table[i].additional_info.commision) !== null ? this.getValueFromXml(speed_table[i].additional_info.commision) + "%" : null, this.getValueFromXml(speed_table[i].commision_debit), this.getValueFromXml(speed_table[i].commision_credit)]});
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            }
            if(speed_table[i].additional_info.is_cev && (speed_table[i].additional_info.rate || speed_table[i].cev_debit || speed_table[i].cev_credit)) {
              formAxios.data.push({id:formAxios.data.length + 1, values: ["C/E/V", null, null, this.getValueFromXml(speed_table[i].additional_info.rate) !== null ? this.getValueFromXml(speed_table[i].additional_info.rate) + " Rate, "+(this.owners ? this.owners.currency : 'USD')+"/PMPR" : null, this.getValueFromXml(speed_table[i].cev_debit), this.getValueFromXml(speed_table[i].cev_credit)]});
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            }

            if((speed_table[i].additional_info.is_hsfo || speed_table[i].additional_info.is_lsmgo) && (speed_table[i].additional_info.hsfo_quantity || speed_table[i].additional_info.hsfo_price || speed_table[i].hsfo_debit || speed_table[i].hsfo_credit || speed_table[i].additional_info.lsmgo_quantity || speed_table[i].additional_info.lsmgo_price || speed_table[i].lsmgo_debit || speed_table[i].lsmgo_credit || speed_table[i].additional_info.comment)) {
              formAxios.data.push({id:formAxios.data.length + 1, values: ["Bunkers overconsumed during Speed claim", null, null, "Price, "+(this.owners ? this.owners.currency : 'USD')+"/mt", null, null]});
              formAxios.data.push({id:formAxios.data.length + 1, values: []});
            }
            if(speed_table[i].additional_info.is_hsfo && (speed_table[i].additional_info.hsfo_quantity || speed_table[i].additional_info.hsfo_price || speed_table[i].hsfo_debit || speed_table[i].hsfo_credit))
              formAxios.data.push({id:formAxios.data.length + 1, values: ["HSFO", this.getValueFromXml(speed_table[i].additional_info.hsfo_quantity), 'MT', this.getValueFromXml(speed_table[i].additional_info.hsfo_price), this.getValueFromXml(speed_table[i].hsfo_debit), this.getValueFromXml(speed_table[i].hsfo_credit)]});
            if(speed_table[i].additional_info.is_lsmgo && (speed_table[i].additional_info.lsmgo_quantity || speed_table[i].additional_info.lsmgo_price || speed_table[i].lsmgo_debit || speed_table[i].lsmgo_credit))
              formAxios.data.push({id:formAxios.data.length + 1, values: ["LSMGO", this.getValueFromXml(speed_table[i].additional_info.lsmgo_quantity), 'MT', this.getValueFromXml(speed_table[i].additional_info.lsmgo_price), this.getValueFromXml(speed_table[i].lsmgo_debit), this.getValueFromXml(speed_table[i].lsmgo_credit)]});
            if(speed_table[i].additional_info.comment)
              formAxios.data.push({id:formAxios.data.length + 1, values: ["Comment", speed_table[i].additional_info.comment ? speed_table[i].additional_info.comment : null]});
          }
          formAxios.data.push({id:formAxios.data.length + 1, values: []});
        }
        let charterers_table = this.owners.expenses.filter(el=>{return el.additional_info.is_row && el.expense_type == 'charterer_expense' &&
            !el.is_comment &&
            (el.debit || el.credit)});
        if(this.owners && charterers_table.length) {
          // formAxios.data.push({id:formAxios.data.length + 1, values: ["CHARTERERS EXPANSES"]});
          let rows = charterers_table.filter(el => {
            return el.additional_info.is_row && el.expense_type == 'charterer_expense' &&
                !el.is_comment &&
                (el.debit || el.credit)
          })
          for(let i=0; i<rows.length; i++) {
            formAxios.data.push({id:formAxios.data.length + 1, values: ["CHARTERERS EXPANSES #" + (i+1), rows[i].additional_info.title ? rows[i].additional_info.title : null, null, null, this.getValueFromXml(rows[i].debit), this.getValueFromXml(rows[i].credit)]});
          }
          let comment = this.owners.expenses.filter(el => {
            return el.expense_type == 'charterer_expense' && el.is_comment
          })[0]
          if(comment)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["Comment", comment.additional_info.comment ? comment.additional_info.comment : null]});

          formAxios.data.push({id:formAxios.data.length + 1, values: []});
        }
        let owners_table = this.owners.expenses.filter(el=>{return el.expense_type == 'owners_expense' &&
            !el.is_comment &&
            ((el.additional_info.is_row && (el.debit ||
                el.credit)) ||
                (el.additional_info.is_commission && (el.additional_info.commision ||
                    el.commision_debit ||
                    el.commision_credit)))});
        if(this.owners && owners_table.length) {
          // formAxios.data.push({id:formAxios.data.length + 1, values: ["OWNERS EXPENSES"]});
          let rows = owners_table.filter(el => {
            return el.expense_type == 'owners_expense' && !el.is_comment && !el.is_commision;
          })
          for(let i=0; i<rows.length; i++) {
            formAxios.data.push({id:formAxios.data.length + 1, values: ["OWNERS EXPENSES #" + (i+1), rows[i].additional_info.title ? rows[i].additional_info.title : null, null, null, this.getValueFromXml(rows[i].debit), this.getValueFromXml(rows[i].credit)]});
            let item = rows[i]
            if(item.additional_info.commision || item.commision_debit || item.commision_credit) {
              // let commision = owners_table.filter(el => {
              //   return el.expense_type == 'owners_expense' && el.is_commision
              // })[0]
              // formAxios.data.push({id:formAxios.data.length + 1, values: ["Commision on OE", this.getValueFromXml(commision.additional_info.commision)]});
              formAxios.data.push({id:formAxios.data.length + 1, values: ["Commision on OE", null, null, this.getValueFromXml(item.additional_info.commision) !== null ? this.getValueFromXml(item.additional_info.commision) + "%" : null, this.getValueFromXml(item.commision_debit), this.getValueFromXml(item.commision_credit)]});
            }
          }

          let comment = owners_table.filter(el => {
            return el.expense_type == 'owners_expense' && el.is_comment
          })[0]
          if(comment?.additional_info)
            formAxios.data.push({id:formAxios.data.length + 1, values: ["Comment", comment.additional_info.comment ? comment.additional_info.comment : null]});
          formAxios.data.push({id:formAxios.data.length + 1, values: []});
        }
      }


      if(this.owners || this.localRemittances) {
        let remittances = (this.owners !== null && this.owners !== undefined) ? this.sortedByDate(this.owners.additional_info.remittances) : (this.localRemittances ? this.sortedByDate(this.localRemittances) : []);
        if(remittances.length) {
          formAxios.data.push({id:formAxios.data.length + 1, values: ["REMITTANCES DONE"]});
          for(let i=0; i<remittances.length; i++) {
            formAxios.data.push({id:formAxios.data.length + 1, values: ["Hire #"+(1+i), this.getValueFromXml(remittances[i].date), null, null, this.getValueFromXml(remittances[i].debit), this.getValueFromXml(remittances[i].credit)]});
          }
          formAxios.data.push({id:formAxios.data.length + 1, values: []});

        }
      }
      if(this.owners) {
        formAxios.data.push({id:formAxios.data.length + 1, values: ["TOTAL", null, null, null, this.getValueFromXml(this.owners.total_debit), this.getValueFromXml(this.owners.total_credit)]});
        if(this.owners?.balance)
          formAxios.data.push({id:formAxios.data.length + 1, values: [ this.owners?.balance >=0 ? 'BALANCE DUE TO CHARTERS' : 'BALANCE DUE TO OWNERS', null,null,null, this.owners.balance >= 0 ? this.getValueFromXml(Math.abs(this.owners?.balance)) : null, this.owners.balance < 0 ? this.getValueFromXml(Math.abs(this.owners?.balance)) : null ]});
      } else {
        formAxios.data.push({id:formAxios.data.length + 1, values: ["TOTAL", null, null, null, this.getValueFromXml(this.localDebit), this.getValueFromXml(this.localCredit)]});
        formAxios.data.push({id:formAxios.data.length + 1, values: [ this.localBalance >=0 ? 'BALANCE DUE TO CHARTERS' : 'BALANCE DUE TO OWNERS', null,null,null, this.localBalance >= 0 ? this.getValueFromXml(Math.abs(this.localBalance)) : null, this.localBalance < 0 ? this.getValueFromXml(Math.abs(this.localBalance)) : null ]});
      }

      if(this.owners && this.owners.comment) {
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
        formAxios.data.push({id:formAxios.data.length + 1, values: ['Note']})
        formAxios.data.push({id:formAxios.data.length + 1, values: [this.getValueFromXml(this.owners.comment)]})
      }

      if(this.owners)
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
      formAxios.data.push({id: formAxios.data.length + 1, values: ["Please remit" + ( this.owners?.currency ? ' ' + this.getValueFromXml(this.owners.currency) : '') + (this.owners?.balance ? ' ' + this.getValueFromXml(this.owners?.balance) : '') + " to following bank account not later than on" + ((this.owners.due_date) ? ' ' + this.getValueFromXml(this.owners.due_date) + ':' : ''), null, null, null, null, null, null]})
      formAxios.data.push({id:formAxios.data.length + 1, values: [ null, null, null, null, this.owners?.stamp ? (this.owners.color ? 'http://'+this.getIP()+'/media/img_stamp_color.jpg' : 'http://'+this.getIP()+'/media/img_stamp_bw.jpg') : null]})
      if(this.owners && this.owners.bank_details && this.getSoaRequisite ) {
        formAxios.data.push({id:formAxios.data.length + 1, values: []})
        if(this.getSoaRequisite?.holder)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Holder:", this.getValueFromXml(this.getSoaRequisite.holder)]})
        if(this.getSoaRequisite.account_number)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Account Number:", this.getValueFromXml(this.getSoaRequisite.account_number)]})
        if(this.getSoaRequisite?.iban)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["IBAN:", this.getValueFromXml(this.getSoaRequisite.iban)]})
        if(this.getSoaRequisite?.description)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Description:", this.getValueFromXml(this.getSoaRequisite.description)]})
        if(this.getSoaRequisite?.bank)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Bank:", this.getValueFromXml(this.getSoaRequisite.bank)]})
        if(this.getSoaRequisite?.clearing_number)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Clearing number:", this.getValueFromXml(this.getSoaRequisite.clearing_number)]})
        if(this.getSoaRequisite?.bic)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["BIC / SWIFT:", this.getValueFromXml(this.getSoaRequisite.bic)]})
        if(this.getSoaRequisite?.currency)
          formAxios.data.push({id: formAxios.data.length + 1, values: ["Currency:", this.getValueFromXml(this.getSoaRequisite.currency)]})
      }


      this.axiosGetXLSX(formAxios).then(response => {

        let link = response.data;
        //  // window.open(link)·
        // // console.log(1);
        function download(content, contentType, fileName) {
          var link1 = document.createElement("a");
          link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
          link1.download = fileName;
          link1.click();
        }
        download(link, "application/vnd.ms-excel", "SOA Sublet.xls")
      });
    },
    sortedByDate(remittance) {
      let remittances = [...remittance]
      remittances = remittances.filter(el=>el.is_row)
      return remittances.sort((a,b)=>new Date(moment(a.date, 'DD.MM.YYYY').format('YYYY-MM-DD'))-new Date(moment(b.date, 'DD.MM.YYYY').format('YYYY-MM-DD')));
      // return remittances.sort((a,b)=>new Date(a.date)-new Date(b.date));
    },
    getIP() {
      return ''+process.env.VUE_APP_URL_SOCKET+':8080'
    },
    beforeDownload () {
      console.dir(Object(this.$refs.html2Pdf.$el))
      console.log(this.$refs.html2Pdf.$el.offsetTop)
      console.dir(this.$refs.html2Pdf.$el.children[0].children[0].children[0].children)
      let DOM = this.$refs.html2Pdf.$el.children[0].children[0].children[0].children;
      let top = 0;
      let offsetTopMain = 0;
      DOM.forEach((item, i)=> {
        if(item.className.indexOf('pdf-not-see-children') >= 0 && item.className.indexOf('pdf-bottom-block') === -1) {
          if(((offsetTopMain + item.scrollHeight) - top) > 1100 && i > 0) {
            console.log(((offsetTopMain + item.scrollHeight) - top))
            console.dir(DOM[i-1]);
            DOM[i-1].classList.add('html2pdf__page-break')
            console.log(DOM[i-1]);
            top = offsetTopMain;
          }
        } else {
          let top1 = 0;
          let bool = false;
          console.dir(item.children)
          // console.log('top1')
          let offsetTop = offsetTopMain - top;
          let children = (item.className.indexOf('pdf-bottom-block') >= 0) ? item.children[0].children : item.children;
          let array = [];
          if(item.className.indexOf('pdf-bottom-block') >= 0) {
            let height = 0;
            children.forEach((item1)=> {
              height+=item1.scrollHeight;
            })
            if(height < 190 && (((offsetTop + 190) - top1) > 1100)) {
              DOM[i-1].classList.add('html2pdf__page-break')
              return;
            }
          }
          children.forEach((item1)=> {
            if(item1.className.indexOf('vessel_owners__table-wrap') >= 0) {
              item1.children.forEach((a)=> {
                array.push(a)
              })
            } else {
              array.push(item1)
            }
          })
          children = array
          console.log(array)
          children.forEach((item1, j)=> {
            console.log('offsetTop', offsetTop)
            if(((offsetTop + item1.offsetHeight) - top1) > 1100) {
              if(j !== 0) {
                console.log(((offsetTop + item1.offsetHeight) - top1))
                console.dir(children[j-1]);
                children[j-1].classList.add('html2pdf__page-break')
                console.log(children[j-1]);
                top1 = offsetTop;
                bool = true
              } else {
                console.log(((offsetTop + item1.offsetHeight) - top1))
                console.dir(DOM[i-1]);
                DOM[i-1].classList.add('html2pdf__page-break')
                console.log(DOM[i-1]);
                top1 += offsetTop;
                bool = true
              }
            }
            offsetTop+=item1.offsetHeight
          })
          if(bool) {
            top += top1
          }

        }
        offsetTopMain+=item.scrollHeight
      })
      this.showLoad()
      this.setTurnOnDarkModeAfterDownloadPDF(this.getDartMode)
      this.setDartMode(false)
      setTimeout(()=>{this.$refs.html2Pdf.generatePdf()}, 4000)
    },

    async updateRemitances() {
      let subId = this.$route.params.subId
      let clientId = subId ? this.getCharterer?.owner?.id : this.contract?.owner?.id
      let params = {ordering: 'paid_date',ship: this.$route.params.id, type: 'payment', limit: 9999, is_archived: false, filter: {counterparty: clientId ? [clientId] : [], paymentCheckbox: ['paid'], incomeCheckbox: ['paid']}, sortById: false}
      if(!this.getStopCheckStatementMember) {
        params['filter']['statement_member'] = this.getCharterer.cp_date
      } else {
        params['filter']['statement_member_empty'] = true
      }
      await this.axiosGetPayment(params)
      
      if(!this.owners.additional_info.remittances) this.$set(this.owners.additional_info, 'remittances', [])
      
      this.owners.additional_info.remittances.forEach(el=>{
        this.owners.total_debit -= el.debit ? parseFloat(el.debit) : 0
        this.owners.total_credit -= el.credit ? parseFloat(el.credit) : 0
      })

      this.owners.additional_info.remittances = [...this.getFilteredRemitances]

      this.owners.additional_info.remittances.forEach(el=>{
        this.owners.total_debit += el.debit ? parseFloat(el.debit) : 0
        this.owners.total_credit += el.credit ? parseFloat(el.credit) : 0
      })

      this.owners.balance = +this.owners.total_debit - +this.owners.total_credit
    },
    async setNewRemitances() {
      let subId = this.$route.params.subId
      let clientId = subId ? this.getCharterer?.owner?.id : this.contract?.owner?.id
      await this.axiosGetPayment({ordering: 'paid_date',ship: this.$route.params.id, type: 'payment', limit: 9999, is_archived: false, filter: {counterparty: clientId ? [clientId] : [], paymentCheckbox: ['paid'], incomeCheckbox: ['paid']}, sortById: false,})

      this.localRemittances = [...this.getFilteredRemitances]

      this.localRemittances.forEach(el=>{
        this.localDebit += el.debit ? +el.debit : 0
        this.localCredit += el.credit ? +el.credit : 0
      })
      this.localBalance = +this.localDebit - +this.localCredit
    },
  },
  async beforeMount() {
    this.showLoad()
    await this.axiosOpenOwnersById(this.$route.params.subId)
    this.hideLoad()

    if(!this.requisites)
      this.axiosGetRequisites()

    if(this.owners) this.updateRemitances()
    else this.setNewRemitances()
  }
}
</script>

<style lang="scss" scoped>
  @import "soa";
  .border-for-position {
    position: absolute;
    bottom: 0;
    left: -24px;
    width: calc(100% + 48px);
  }
  /deep/ {
    .vessel_owners__table {
      &>div {
        word-break: break-all;
        text-align: left;
      }
    }
  }
</style>