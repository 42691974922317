<template>
  <label class="flex justify-between p-l-2 p-t-8">
    <div class="fz13">Scrubber</div>
    <b-form-checkbox
      id="calc_scrubber"
      :checked="value"
      @input="(val) => $emit('input', val)"
    ></b-form-checkbox>
  </label>
</template>

<script>
export default {
  props: ['value']
}
</script>

<style scoped lang="scss">
  @import "./src/components/style-element/bootstrap";
  @import "./src/components/style-element/checkbox";
</style>