<template>
  <b-dropdown right ref="dropdown">
    <template #button-content>
      <button class="blue-button">
        <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="white"/>
        </svg>
      </button>
    </template>
    <div class="content">
      <button class="fz13 text-dark" v-on:click="emitVessel" v-if="getPermissionsForUserCreateManualTask && getPermissionsForUserCreateVesselTasks">
        Vessel search
        <!--        Vessel search with Cargo-->
      </button>
<!--      <button class="fz13" v-on:click="emitCargo" v-if="(getPermissionsForUserCreateCargoTasks && getPermissionsForUserCreateVesselTasks) || (getPermissionsForUserCreateCargoTasks && getPermissionsForUserCreateManualTask)">Vessel search without Cargo</button>-->
      <button class="fz13 text-dark" v-on:click="emitCargoOurVessel">
        Cargo Search
        <!--        Search Cargo for our Vessel-->
      </button>
<!--      <button class="fz13" v-on:click="emitCargoWithoutVessel">Cargo Search without Vessel</button>-->
    </div>
  </b-dropdown>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: mapGetters(["getPermissionsForUserCreateManualTask", "getPermissionsForUserCreateVesselTasks", "getPermissionsForUserCreateCargoTasks"]),
name: "manual-task-dropdown",
  methods: {
    hideDropdown() {
      this.$refs.dropdown.hide(true);
    },
    emitVessel() {
      this.$emit("vesselEmit")
      this.$refs.dropdown.hide(true);
    },
    emitCargo() {
      this.$emit("cargoEmit")
      this.$refs.dropdown.hide(true);
    },
    emitCargoOurVessel() {
      this.$emit("cargoOurVessel")
      this.$refs.dropdown.hide(true);
    },
    emitCargoWithoutVessel() {
      this.$emit("cargoWithoutVessel")
      this.$refs.dropdown.hide(true);
    }

  }
}
</script>

<style scoped lang="scss">
@import "../style-element/dropdown";
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  background: transparent;
  border: none;
  min-width: 1px;
  width: max-content;
}
.blue-button {
  height: 32px;
  width: 32px;
  padding: 0 !important;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #46A9F1;
}
.content {
  //width: 150px;
  border-radius: 4px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  button {
    text-align: left;
    transition: background-color .1s linear;
    background-color: var(--color-modal);
    height: 34px;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 12px;
    &:first-child {
      border-radius: 4px 4px 0 0px;
    }
    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }
    &:hover {
      background-color: var(--color-input);
    }
  }
}
</style>