<template>
    <section>
        <router-view></router-view>
    </section>
</template>
<script>
export default {
  name: "employees-page-view",
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_employes_section") === false) {
        next(from);
      }
    })
  },
}
</script>
<style scoped lang="scss">
    section {
        width: 100%;
    }
</style>