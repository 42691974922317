<template>
  <div v-tooltip.top="localValue !== '0.00' && localValue !== 0 ? localValue : ''" v-on:mousedown="mouseDown" v-on:mouseup="mouseUp" class="position-relative " ref="element">
      <input
          v-on:keydown.tab="(e)=>{e.preventDefault();clickTabPayment(e.target.parentNode)}"
          ref="input"
          type="text"
          @keyup="keyup"
          @keydown="keydown"
          class="input-payment fz13 click-element-border"
          :class="{'fw700': localValue !== '0.00' && localValue !== 0, 'text-green-dark': type === 'income' && parseFloat(localValue) && item.status === 'paid', 'expense': type === 'expense', 'income': type === 'income',
        'border-select-top': showTopBorder(),'border-select-bottom': showBottomBorder(), 'border-select-right': showRightBorder(), 'border-select-left': showLeftBorder()}"
          v-model.lazy="localValue"
          @change="checkType"
          @focusout="focusout"
          @paste="onPaste"
          :disabled="true"
      >
    <div class="position-absolute t0 l0 w100Proc h100Proc box-border pre-input-click-border">

    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "element-input-payment-amount",
  props: {
    item: {
      type: Object,
      default: null,
    },
    type: String,
    copyPaste: Boolean,
  },
  watch: {
    'item.payment_type': {
      handler() {
        if(this.item.payment_type !== this.type) {
          this.localValue = '0.00'
        }
      },
      deep: true
    },
    typesSelection: function () {
      if((this.typesSelectionExpense.length || this.typesSelectionIncome.length) && ((this.type === 'expense' && (!this.typesSelectionIncome.length || (this.typesSelectionIncome.length && this.typesSelectionExpense.length && this.typesSelectionExpense[this.typesSelectionExpense.length -1].index > this.typesSelectionIncome[this.typesSelectionIncome.length -1].index)) || (this.type === 'income' && (!this.typesSelectionExpense.length || (this.typesSelectionIncome.length && this.typesSelectionExpense.length && this.typesSelectionIncome[this.typesSelectionIncome.length -1].index >= this.typesSelectionExpense[this.typesSelectionExpense.length -1].index)))))) {
        this.$emit('position-total', this.$refs.element)
      }
    },
  },
  computed: {
    ...mapState({
      typesSelection: state => state.paymentListXY.typesSelection,
      typesSelectionExpense: state => state.paymentListXY.typesSelectionExpense,
      typesSelectionIncome: state => state.paymentListXY.typesSelectionIncome,
    })
  },
  data() {
    return {
      localValue: '0.00',
      ctrlKey: false,
      pasteEvent: false,
    }
  },
  methods: {
    ...mapActions(['clickTabPayment']),
    getIds(array) {
      return array.map(a=> {return a.id})
    },
    showTopBorder() {
      let array = JSON.parse(JSON.stringify(this.type === 'expense' ? this.typesSelectionExpense : this.typesSelectionIncome))
      let IDs = this.getIds(array)
      if(IDs.indexOf(this.item.id) >= 0) {
        let item = array.find(a=> a.id === this.item.id)
        let itemIndex = array.findIndex(a=> a.id === this.item.id)
        if(itemIndex === 0) {
          return true
        }
        if(item.index-1 !== array[itemIndex-1].index) {
          return true
        }
      }
      return false
    },
    showBottomBorder() {
      let array = this.type === 'expense' ? this.typesSelectionExpense : this.typesSelectionIncome
      let IDs = this.getIds(array)
      if(IDs.indexOf(this.item.id) >= 0) {
        let item = array.find(a=> a.id === this.item.id)
        let itemIndex = array.findIndex(a=> a.id === this.item.id)
        if(itemIndex === array.length-1) {
          return true
        }
        if(item.index+1 !== array[itemIndex+1].index) {
          return true
        }
      }
      return false
    },
    showRightBorder() {
      let array = this.type === 'expense' ? this.typesSelectionExpense : this.typesSelectionIncome
      let IDs = this.getIds(array)
      if(IDs.indexOf(this.item.id) >= 0) {
        if(this.type === 'expense') {
          let array = this.typesSelectionIncome
          if(!array.find(a=> a.id === this.item.id)) {
            return true
          }
        } else {
          return true
        }
      }
      return false
    },
    showLeftBorder() {
      let array = this.type === 'expense' ? this.typesSelectionExpense : this.typesSelectionIncome
      let IDs = this.getIds(array)
      if(IDs.indexOf(this.item.id) >= 0) {
        if(this.type === 'income') {
          let array = this.typesSelectionExpense
          if(!array.find(a=> a.id === this.item.id)) {
            return true
          }
        } else {
          return true
        }
      }
      return false
    },
    mouseDown() {
      this.$emit('mousedown', this.type)
    },
    mouseUp() {
      this.$emit('mouseup', this.type)
    },
    checkNum(num) {
      if(num.indexOf(".")>=0) {
        let num1 = num.substring(0, num.indexOf("."));
        let num2 = num.substring(num.indexOf(".") + 1, num.length);

        num1 = num1.replace(/[^0-9]/g, "");
        num2 = num2.replace(/[^0-9]/g, "");

        if(num2.length > 4) {
          num2 = num2.substring(0, 4);
        }
        if(num1.length && num2.length) {
          num = num1 + "." + num2;
        } else {
          num = num1 + num2;
        }
      } else {
        num = num.replace(/[^0-9]/g, "");
      }
      return num;
    },
    onPaste (evt) {
        evt.preventDefault()
        let textInput1 = this.localValue.substr(0, this.$refs.input.selectionStart)
        let textInput2 = this.localValue.substr(this.$refs.input.selectionEnd,this.localValue.length)
        let text = this.checkNum(evt.clipboardData.getData('text'));
      text = textInput1 + text + textInput2
        this.localValue = text && (''+text).length ? text : '0.00'
      this.pasteEvent = true
    },
    focusout() {
      this.localValue = parseFloat((''+this.localValue).replaceAll(',', '')).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      if(this.pasteEvent) {
        this.checkType();
        this.pasteEvent = false
      }
    },
    keyup(e) {
        if(this.ctrlKey) {
          if(e.key === 'Meta' || e.key === 'Control') {
            this.ctrlKey = false;
          }
        }
    },
    keydown(e) {
      let bool = false
        if(e.key === 'Meta' || e.key === 'Control') {
          this.ctrlKey = true;
        }
        if(e.keyCode === 86 || e.keyCode === 67 || e.keyCode === 65) {
          if(this.ctrlKey) {
            bool=true
          }
        }

      if(
          (e.keyCode < 48 || e.keyCode > 57) && // numbers
          (e.keyCode < 96 || e.keyCode > 105) && // numpad
          (e.keyCode < 37 || e.keyCode > 40) && // arrows
          e.key != '.' && e.keyCode != 13 && // 13 - enter
          e.keyCode != 8 && e.keyCode != 46 && // backspace / delete
          e.keyCode != 110 // decimal point
      ) {
        // if(copyPaste)
        if(!bool)
          e.preventDefault()
      }
    },
    checkType() {
      let changeOnlyType = false
      console.log('checkType', this.item.payment_amount, this.localValue, parseFloat((''+this.item.payment_amount).replaceAll(',', '')) == parseFloat((''+this.localValue).replaceAll(',', '')))
      this.localValue = this.localValue ? this.localValue : '0.00'
      if(parseFloat((''+this.item.payment_amount).replaceAll(',', '')) == parseFloat((''+this.localValue).replaceAll(',', ''))) {
        changeOnlyType = true
        // this.item.payment_amount = parseFloat(this.localValue ? parseFloat(''+this.localValue.replaceAll(',', '')) : '0')+0.01
      }
      this.item.payment_amount = this.localValue ? parseFloat(''+this.localValue.replaceAll(',', '')) : '0'
      this.item.payment_type = this.type;
      if(changeOnlyType) {
        this.$emit('changeType')
      }
    },
  },
  mounted() {
    if(this.item) {
      this.item.payment_amount = (''+this.item.payment_amount).indexOf('NaN') >= 0 ? 0 : this.item.payment_amount
    }
    this.localValue = (this.item && this.type === this.item.payment_type && this.item.payment_amount !== null) ? parseFloat((''+this.item.payment_amount).replaceAll(',', '')).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'
  }
}
</script>

<style scoped lang="scss">
.input-payment {
  border-radius: 0;
  height: 30px;
  border: 2px solid transparent;
  padding: 4px;
  text-align: right;
}
.total-calc {
  position: absolute;
  bottom: -20px;
  height: 20px;
  width: 100%;
  background-color: red;
}
//.click-element-border {
//  border: none !important;
//  position: relative;
//  &::after {
//    content: '';
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//  }
//}
//.click-active-border {
//  &::after {
//    content: none !important;
//  }
//  border: none !important;
//  input {
//    border: 2px solid var(--color-yellow) !important;
//    background-color: var(--color-gray-dark) !important;
//  }
//}


</style>