<template>
  <div class="flex">
    <router-link 
      v-for="(link, i) in links" 
      :key="link.title"
      :to="link.link" 
      class="border-l border-t-0 border-b-0 border-r border-gray-light calc_tab__link fz0"
      :class="{'border-r-0': i != links.length-1}"
    >
      <div 
        class="relative flex items-center fz14 fw500"
        :class="{
          'm-x-8': windowWidth >= 1688,
          'm-x-4': windowWidth < 1688,
        }"
      >
        <span 
          class="fz0"
          :class="{'m-r-2': windowWidth >= 1688}"
          v-html="link.svg"
        ></span>
        <template v-if="windowWidth >= 1688">
          {{link.title}}
        </template>
        <span class="calc_tab__link_sub absolute w-full left-0"></span>
      </div>
    </router-link>


  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      links: [
        // {
        //   title: 'Route',
        //   link: 'route',
        //   svg: '<svg width="24" height="24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 2.5C7.916 2.5 5 5.405 5 8.975a6.4 6.4 0 0 0 1.297 3.881l4.802 6.443c.012.016.03.022.044.036.24.256.586.191.757-.036 1.383-1.838 3.918-5.27 4.893-6.566v-.002l.007-.007a6.411 6.411 0 0 0 1.2-3.75c0-3.57-2.916-6.474-6.5-6.474Zm0 9.968c-1.93 0-3.507-1.571-3.507-3.493 0-1.923 1.577-3.493 3.507-3.493 1.93 0 3.507 1.57 3.507 3.493 0 1.922-1.577 3.493-3.507 3.493Z"/></svg>'
        // },
        // {
        //   title: 'Cargo',
        //   link: 'cargo',
        //   svg: '<svg width="24" height="24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path d="M11.331 10.336 3.245 6.93a.176.176 0 0 0-.244.162v9.102c0 .23.14.437.354.522l7.843 3.137a.176.176 0 0 0 .241-.163v-9.193a.176.176 0 0 0-.108-.162ZM12.07 9.369l7.816-3.291a.176.176 0 0 0 .006-.321L12.24 2.186a.563.563 0 0 0-.476 0l-7.652 3.57a.176.176 0 0 0 .006.322l7.816 3.29a.176.176 0 0 0 .136 0ZM12.564 10.498v9.193c0 .125.126.21.241.163l7.843-3.137a.562.562 0 0 0 .354-.522V7.093a.176.176 0 0 0-.244-.162l-8.086 3.405a.176.176 0 0 0-.108.162Z"/></svg>'
        // },
        {
          title: 'Estimator',
          link: 'vessels',
          svg: '<svg width="24" height="24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m4.621 12.106 7.102-2.91v5.325a.478.478 0 1 0 .957 0V9.18l7.145 2.927a1 1 0 0 1 .557 1.277l-1.805 4.813H18.4c-1.112 0-2.224-.425-3.2-1.196-1.952 1.543-4.448 1.543-6.4 0-.898.71-1.911 1.126-2.934 1.187l-1.802-4.804a1 1 0 0 1 .557-1.277ZM6.111 6.7a.7.7 0 0 1 .7-.7h4.212a.7.7 0 0 1 .7.7v.895a.7.7 0 0 1-.465.66l-4.212 1.5a.7.7 0 0 1-.935-.66V6.7Zm12.224 2.395a.7.7 0 0 1-.935.66l-4.212-1.5a.7.7 0 0 1-.465-.66V6.7a.7.7 0 0 1 .7-.7h4.212a.7.7 0 0 1 .7.7v2.395ZM15.5 4.7a.3.3 0 0 1-.3.3H9.3a.3.3 0 0 1-.3-.3v-.987a.7.7 0 0 1 .7-.7h5.1a.7.7 0 0 1 .7.7v.987Z"/><path d="M15.2 18.305c-1.952 1.543-4.448 1.543-6.4 0-.976.771-2.088 1.195-3.2 1.195h-.651a.949.949 0 1 0 0 1.898H5.6c1.1 0 2.192-.311 3.2-.898 2.016 1.169 4.384 1.169 6.4 0 1.008.582 2.1.898 3.2.898h.651a.949.949 0 1 0 0-1.898H18.4c-1.112 0-2.224-.424-3.2-1.195Z"/></svg>'
        },
        {
          title: 'Compare',
          link: 'compare',
          svg: '<svg width="24" height="24" fill="#9AA3B0" xmlns="http://www.w3.org/2000/svg"><path d="M18.042 21.06a4.87 4.87 0 0 0 4.744-3.616.46.46 0 0 0-.453-.566h-.174l-3.666-9.995a.48.48 0 0 0-.902 0l-3.666 9.995h-.174a.461.461 0 0 0-.453.566 4.868 4.868 0 0 0 4.744 3.616Zm0-12.618 3.094 8.436h-6.189l3.095-8.436ZM10.25 16.878h-.175L6.409 6.883a.48.48 0 0 0-.902 0l-3.666 9.995h-.174a.461.461 0 0 0-.453.566 4.87 4.87 0 0 0 4.744 3.616 4.868 4.868 0 0 0 4.744-3.616.46.46 0 0 0-.453-.566Zm-7.387 0 3.095-8.436 3.094 8.436H2.863ZM19.769 4.49H13.97a2.027 2.027 0 0 0-3.94 0H4.23a.48.48 0 1 0 0 .96h5.799a2.027 2.027 0 0 0 3.94 0h5.799a.48.48 0 1 0 0-.96Zm-7.77 1.55A1.07 1.07 0 1 1 12 3.899a1.07 1.07 0 0 1 0 2.14Z"/></svg>'
        },
      ]
    }
  },
  computed: {
    ...mapState({
      windowWidth: state => state.windowWidth
    })
  }
}
</script>

<style lang="scss">
.calc_tab__link{
    color: var(--color-gray-text);
  &:hover,
  &.router-link-active{
    color: var(--color-blue-dark);
    svg{
      fill: var(--color-blue-dark);
    }
  }
  &.router-link-active{
    .calc_tab__link_sub{
      opacity: 1;
    }
  }
  .calc_tab__link_sub{
    height: 2px;
    bottom: calc(-2px - 0.5rem);
    background-color: var(--color-blue-dark);
    opacity: 0;
  }
}
</style>