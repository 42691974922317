<template>
  <div class="page_section__wrap bg-gray-lightest items-center">
    <setting-bread/>
    <setting-header v-if="settings" :types="settings" :isChecked="isChecked" @on-check="onCheck"/>

    <div class="flex justify-center p-b-6" v-if="settings">
      <div class="flex flex-col items-center w-full max-w-920 shadow bg-white box-border p-x-6 rounded-b-lg">
        <setting-item v-for="(type, i) in settings" :key="type.notification_type" :item="type" @on-change="onChange" :class="{
          'border-b-0': i == settings.length-1
        }"/>
      </div>
    </div>
    
  </div>
</template>

<script>
import settingBread from '@/components/notifications/settingBread'
import settingHeader from '@/components/notifications/settingHeader'
import settingItem from '@/components/notifications/settingItem'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    settingBread,
    settingHeader,
    settingItem
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      settings: state => state.notifications.settings
    }),
    isChecked() {
      let checked = this.settings.filter(el => {
        return el.is_active
      })
      return checked.length ? true : false
    }
  },
  methods: {
    ...mapActions(['axiosGetNotificationSettings', 'axiosSetNotificationSettings']),
    onSave() {
      this.axiosSetNotificationSettings(this.settings.map(el => {
        return {
          is_active: el.is_active,
          notification_type: el.notification_type
        }
      }))
    },
    onCheck() {
      let bool = true
      if(this.isChecked) {
        bool = false
      }
      this.settings.forEach(el => {
        el.is_active = bool
      })
      this.onSave()
    },
    onChange() {
      this.onSave()
    }
  },
  async beforeMount() {
    await this.axiosGetNotificationSettings()
  }
}
</script>

<style>

</style>