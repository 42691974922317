import { render, staticRenderFns } from "./calcVesselsList.vue?vue&type=template&id=588412d1&scoped=true&"
import script from "./calcVesselsList.vue?vue&type=script&lang=js&"
export * from "./calcVesselsList.vue?vue&type=script&lang=js&"
import style0 from "./calcVesselsList.vue?vue&type=style&index=0&id=588412d1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588412d1",
  null
  
)

export default component.exports