<template>
    <div>
        <b-dropdown left ref="dropdown">
            <template #button-content>
                <button class="button-drop fz13 fw500 text-dark1">
                    View
                    <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2C2C2C"/>
                    </svg>
                </button>
            </template>
            <div class="content">
                <button v-on:click="$refs.dropdown.hide(true)" class="title fw500 text-gray">
                    View
                </button>
                <button v-for="(b, i) in buttons" :key="i" :class="{'active-button': b.status}" v-on:click="changeAciveButton(i)" class="fz13 text-dark">
                    {{b.name}}
                </button>
                <!--<div class="bottom-button">-->
                    <!--<b-form-checkbox-->
                            <!--id="checkbox-1"-->
                            <!--v-model="status"-->
                            <!--name="checkbox-1"-->
                            <!--switch-->
                    <!--&gt;-->
                        <!--By departments-->
                    <!--</b-form-checkbox>-->
                <!--</div>-->
            </div>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        name: "view-table",
        data() {
            return {
                buttons: [
                    {name: "Cards", status: true},
                    {name: "Table", status: false},
                ],
                status: true,
                indexActive: 0,
            }
        },
        methods: {
            changeAciveButton(index) {
              if(this.indexActive !==index) {
                this.$refs.dropdown.hide(true);
                this.indexActive = index;
                for(let i=0; i<this.buttons.length; i++) {
                  this.buttons[i].status = false;
                }
                this.buttons[index].status = true;
                this.$emit("changeView");
              } else {
                this.$refs.dropdown.hide(true);
              }
            },
            setButton(index) {
              this.indexActive = index;
              for(let i=0; i<this.buttons.length; i++) {
                this.buttons[i].status = false;
              }
              this.buttons[index].status = true;
            }
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    @import "../style-element/dropdown";
    @import "../style-element/checkbox";
    /deep/ {

        .custom-control {
            min-height: 1px !important;
            padding: 0 8px 0 12px;
            width: 100%;
            /*height: 100%;*/
            /*box-sizing: border-box;*/
            /*align-items: center;*/
            .custom-control-label {
                width: 100%;

                font-size: 13px;
                line-height: 16px;
                color: #2C2C2C;
            }
        }
        .custom-control-label::before {
            height:16px;
            top: 0;
        }
        .custom-control-label::after {
            left: auto !important;
            right: 14px;
        }
        .custom-switch .custom-control-label::after {
            top: 2px!important;
        }
        .custom-switch .custom-control-label::before {
            left:auto;
            right: 0;
        }
        .custom-switch {
            top: 0 !important;
        }
    }
    .active-button {
        background-image: url("../../assets/ic_chsdeck.svg");
        background-position-x: calc(100% - 4px);
        background-position-y: center;
        background-repeat: no-repeat;
    }
    .svg-1 {
        margin-right: 8px;
    }
    .arrow {
        margin-left: 4px;
    }
    /deep/ .show {
        .arrow {
            transform: rotateX(180deg);
        }
    }
    /deep/ .dropdown-menu {
        top: -29px !important;
        border-radius: 4px;
        border: none;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
        padding: 4px 0 !important;
    }
    .content {
        width: 160px;
        background-color: var(--color-modal);
        border-radius: 4px;
        .title {
            width: 100%;
            box-sizing: border-box;
            margin: 0;
            padding: 4px 12px 5px 12px;
            display: flex;
            align-items: center;
            svg {
                position: relative;
                top: 2px;
                margin-left: 4px;
            }
            transition: .1s linear;
            &:hover {
                color: #2C2C2C !important;
            }
        }
        button:not(:first-child) {
            height: 28px;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 12px;
            box-sizing: border-box;
            transition: background-color .1s linear;
            &:hover {
                background-color: #DEE3EA;
            }
        }
    }
    .bottom-button {
        margin-top: 4px;
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        border-top: 1px solid #DEE3EA;
    }
</style>
