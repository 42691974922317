import axios from 'axios'
import router from '@/router'
//import moment from 'moment'

export default {
  actions: {
    async axiosGetLastInvoice(ctx, id) {
      ctx.state.invoiceEditableItem = null
      await axios.get(process.env.VUE_APP_URL+`/invoice/?contract=${id}&invoice_type=${ctx.getters.getInvoiceType()}&limit=1&ordering=-created_at`, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
      .then( response => {
        if(response.data.results.length)
          ctx.state.invoiceEditableItem = response.data.results[0]
      })
      .catch( error => {
        console.error('axiosGetLastInvoice', error);
      })
    },
    async axiosGetLastInvoiceByType(ctx, data) {
      return await axios.get(process.env.VUE_APP_URL+`/invoice/?contract=${data.id}&invoice_type=${data.type}&limit=1&ordering=-created_at`, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
      .then( response => {
        if(response.data.results.length)
          return response.data.results[0]
        return false
      })
      .catch( error => {
        console.error('axiosGetLastInvoiceByType', error);
        return false
      })
    },
    async axiosGetInvoicesById(ctx, obj) {
      ctx.state.invoiceList = null
      await axios.get(process.env.VUE_APP_URL+`/invoice/?contract=${obj.id}&invoice_type=${obj.type}&limit=99999&ordering=-created_at` , {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      })
      .then( response => {
        if(response.data.results.length)
          ctx.state.invoiceList = response.data.results
        else
          ctx.state.invoiceList = null
      })
      .catch((error) => {
        ctx.state.invoiceList = null
        console.error('axiosGetInvoicesById', error);
      })
    },
    async axiosAddInvoice(ctx, obj) {
      ctx.commit('showLoad')
      if(obj && obj.account && Number.isInteger(obj.account) !== true) {
        obj.account = obj.account.id
      }
      await axios.post(process.env.VUE_APP_URL+'/invoice/', obj,{
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      })
      .then(response => {
        console.log('axiosAddInvoice', response);
      })
      .catch((error) => {
        alert('Server error. Contact the developers')
        console.error('axiosAddInvoice', error);
      })
      ctx.commit('hideLoad')
    },
    async axiosChangeInvoice(ctx, obj) {
      return await axios.patch(process.env.VUE_APP_URL+'/invoice/' + obj.id, obj.data, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken
        }
      }).then(()=> {
        return true;
      }).catch(()=> {
        alert('Server error. Contact the developers')
        return false;
      })
    }
  },
  mutations: {
    deleteReseveId(state, id) {
      state.idsReSave = state.idsReSave.filter(a=> a.idCargo !== id)
    },
  },
  state: {
    // idsReSave: [770, 744, 724, 761, 751],
    idsReSave: [
        {idCargo: 778, idContract: 750},
      {idCargo: 777, idContract: 750},
      {idCargo: 752, idContract: 750},
    ],

    invoiceList: null,
    invoiceEditableItem: null,
    invoiceEditData: {
      cargo_operations_start_date: null,
      cargo_operations_end_date: null,
      cargo_operations_start_time: null,
      cargo_operations_end_time: null,
      blocks: [],
      detention_invoices: {
        time_from: '',
        time_to: '',
        date_from: null,
        date_to: null,
        country: '',
        rate: null,
        total: null,
      },
      note: '',
      contract: null,
      files: [],
      is_confirmed: false,
      due_date: null,
      charterer: null,
      cargo: '',
      loading_port: null,
      discharge_port: null,
      account: null,
      ship_name: '',
      cp_date: '',
      total_quantity: null,
      currency: 'USD',
      total_amount: 0,
      invoice_type: 'STANDARD',
      linked_payments: [],
      linked_invoices: [],
      linked_owners: [],
    },
    invoiceBlocksData: {
      ILOW: {
        block_type: "ILOW",
        amount_type: "INCOME",
        mt_amount: null,
        percentage: null,
        rate: null,
        percentage_amount: 0,
        total_amount: 0,
        additional_info: {
          is_ilow: false,
          comment: '',
        }
      },
      DEAD_FREIGHT: {
        block_type: "DEAD_FREIGHT",
        amount_type: "INCOME",
        mt_amount: null,
        percentage: null,
        rate: null,
        total_amount: 0,
        percentage_amount: 0,
        additional_info: {
          is_ilow: false,
          comment: '',
        }
      },
      EXTRA_OCEAN_FREIGHT: {
        block_type: "EXTRA_OCEAN_FREIGHT",
        amount_type: "INCOME",
        mt_amount: null,
        percentage: null,
        rate: null,
        total_amount: 0,
        percentage_amount: 0,
        additional_info: {
          is_ilow: false,
          comment: '',
        }
      },
      THEREOF: {
        block_type: "THEREOF",
        amount_type: "INCOME",
        percentage: null,
        total_amount: 0,
        additional_info: {
          comment: ''
        }
      },
      LESS_ADDRESS_COMMISSION: {
        block_type: "LESS_ADDRESS_COMMISSION",
        amount_type: "EXPENSE",
        percentage: null,
        total_amount: 0,
        additional_info: {
          comment: ''
        }
      },
      LESS_BROKERAGE_COMMISSION: {
        block_type: "LESS_BROKERAGE_COMMISSION",
        amount_type: "EXPENSE",
        percentage: null,
        additional_percentage: null,
        percentage_amount: 0,
        total_amount: 0,
        additional_info: {
          tax_amount_type: "EXPENSE",
          comment: '',
          comment_tax: ''
        }
      },
      PLUS_DEMURRAGE: {
        block_type: "PLUS_DEMURRAGE",
        amount_type: "INCOME",
        percentage: null,
        additional_percentage: null,
        third_percentage: null,
        total_amount: 0,
        percentage_amount: 0,
        additional_percentage_amount: 0,
        third_percentage_amount: 0,
        lay_report: null,
        additional_info: {
          address_amount_type: "EXPENSE",
          brokerage_amount_type: "EXPENSE",
          tax_amount_type: "EXPENSE",
          comment: '',
          comment_address: '',
          comment_brokerage: '',
          comment_tax: ''
        }
      },
      LESS_DISPATCH: {
        block_type: "LESS_DISPATCH",
        amount_type: "EXPENSE",
        total_amount: 0,
        lay_report: null,
        additional_info: {
          comment: ''
        }
      },
      PLUS_DETENTION: {
        block_type: "PLUS_DETENTION",
        amount_type: "INCOME",
        percentage: null,
        percentage_amount: 0,
        total_amount: 0,
        additional_info: {
          tax_amount_type: "EXPENSE",
          comment: '',
          comment_tax: ''
        }
      },
      PLUS_CHARTERERS_EXPENSES: {
        block_type: "PLUS_CHARTERERS_EXPENSES",
        amount_type: "INCOME",
        total_amount: 0,
        additional_info: {
          comment: ''
        }
      },
      LESS_OWNERS_EXPENSES: {
        block_type: "LESS_OWNERS_EXPENSES",
        amount_type: "EXPENSE",
        total_amount: 0,
        additional_info: {
          comment: ''
        }
      },
      LESS_RECEIVED: {
        block_type: "LESS_RECEIVED",
        total_amount: 0,
        additional_info: {
          comment: '',
          payments: [],
          hide_payments: []

        }
      },
    }
  },
  getters: {
    getInvoiceType: () => () => {
      let type = router.currentRoute.meta.invoiceType

      if(type == 'Freight Invoice')
        return 'FREIGHT'
      
      if(type == 'Additional Freight Invoice')
        return 'ADDITIONAL_FREIGHT'
      
      if(type == 'Voyage Statement of Account')
        return 'SHIP'
      
      if(type == 'Detention Invoice')
        return 'DETENTION'
      
      return 'STANDARD'
    },
    getInvoiceUrl: () => () => {
      let type = router.currentRoute.meta.invoiceType

      if(type == 'Additional Freight Invoice')
        return 'additional_freight'
      
      if(type == 'Voyage Statement of Account')
        return 'vsoa'
      
      if(type == 'Detention Invoice')
        return 'detention'
      
      return 'freight'
    }
  }
}