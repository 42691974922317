<template>
  <div class="wrap position-fixed w100vw h100vh t0 l0 overflow-y-auto z-1000">
    <div class="filter position-absolute l0 t0 w100Proc h100Proc" v-on:click="$emit('close')">
    </div>
    <div class="content w320 position-absolute t0 r0 bgc-modal">
      <div class="h47 p0-16 box-border d-flex align-items-center border-bottom border-color-gray">
        <svg class="cursor-pointer" v-on:click="$emit('close')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#2C2C2C"/>
          <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#2C2C2C"/>
        </svg>
        <p class="fz16 fw500 ml8">Attach to</p>
      </div>
      <div class="d-flex border-bottom border-color-gray">
        <div class="w50Proc pre-active cursor-pointer h44 d-flex align-items-center justify-center fz16 fw500 text-gray" v-on:click="type = 'vessels'" :class="{'active': type == 'vessels'}">
          Vessels
        </div>
        <div class="w50Proc pre-active cursor-pointer h44 d-flex align-items-center justify-center fz16 fw500 text-gray" v-on:click="type = 'ports'" :class="{'active': type == 'ports'}">
          Ports
        </div>
      </div>
      <div class="p8-16 w100Proc box-border">
        <div class="search position-relative d-flex align-items-center">
          <svg class="position-absolute l9" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.154 18.2874L14.9832 14.1166C15.7911 13.1187 16.2775 11.8507 16.2775 10.4696C16.2775 7.2686 13.673 4.66406 10.472 4.66406C7.27101 4.66406 4.6665 7.26857 4.6665 10.4695C4.6665 13.6705 7.27104 16.2751 10.472 16.2751C11.8531 16.2751 13.1212 15.7886 14.1191 14.9807L18.2899 19.1515C18.409 19.2707 18.5655 19.3306 18.7219 19.3306C18.8784 19.3306 19.0348 19.2707 19.154 19.1515C19.3929 18.9126 19.3929 18.5264 19.154 18.2874ZM10.472 15.0528C7.94447 15.0528 5.88872 12.9971 5.88872 10.4695C5.88872 7.942 7.94447 5.88625 10.472 5.88625C12.9996 5.88625 15.0553 7.942 15.0553 10.4695C15.0553 12.9971 12.9995 15.0528 10.472 15.0528Z" fill="#2C2C2C"/>
          </svg>
          <input placeholder="Search" type="text" class="w100Proc h32 pl40" v-model="search" @input="asyncFind">

        </div>
      </div>
      <div class="h28 p0-16 d-flex align-items-center box-border border-bottom border-color-gray border-top">
        <p class="fz14 fw500">Selected: {{selected.length}}</p>
        <button class="d-flex align-items-center ml-auto text-red fz13 fw500" v-on:click="selected = []" v-if="selected.length">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#E85353"/>
            <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#E85353"/>
          </svg>
          Clear
        </button>
      </div>
      <div class="content-list overflow-y-auto overflow-x-hidden position-relative" v-if="type == 'vessels'">
        <app-loader v-if="isLoading" :is-local="true"></app-loader>
        <button :class="{'pr45': getIdsSelected.indexOf(item.id) >= 0}" class="text-align-left p8-32 h32 box-border w100Proc fz14 text-dark hover:bg-blue-gray-list d-flex align-items-center justify-start position-relative" v-on:click="select(item)" v-for="(item, k) in results" :key="k">
          {{item.name}} {{item.cp_date | formatDateFromCpDateWithotTimeZome}}
          <svg v-if="getIdsSelected.indexOf(item.id) >= 0" class="position-absolute r16" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
          </svg>

        </button>
        <div class="p8-16 w100Proc box-border">
          <pagination-panel-default class="mb0" @load-more="loadMore" v-if="next" :if-active="isLoadMore"></pagination-panel-default>
        </div>
      </div>
      <div class="content-list overflow-y-auto overflow-x-hidden position-relative" v-else>
        <app-loader v-if="isLoading" :is-local="true"></app-loader>
        <div class="w100Proc" v-for="(item, k) in results" :key="k" >
          <button v-on:click="openPorts(item)" :class="{'load-mode': item.is_load}" class="p8-32 h32 box-border w100Proc fz14 fw500 text-dark d-flex align-items-center justify-start position-relative text-align-left" >
            <svg :class="{'rotateX180': item.is_show}" class="position-absolute l16" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.27362 4.89844C9.54089 4.89844 9.67474 5.22158 9.48575 5.41057L6.21001 8.68631C6.09285 8.80346 5.9029 8.80346 5.78574 8.68631L2.51001 5.41057C2.32102 5.22158 2.45487 4.89844 2.72215 4.89844L9.27362 4.89844Z" fill="#2C2C2C"/>
            </svg>
            {{item.name}}

          </button>
          <template v-if="item.is_show">
<!--            search.length ? item.ports.filter(a=>a.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) : item.ports-->
            <button class="p8-32 h32 box-border w100Proc fz14 text-dark hover:bg-blue-gray-list d-flex align-items-center justify-start position-relative text-align-left" v-for="(item, k) in item.ports" :key="k" v-on:click="select(item)">
              {{item.name}}
              <svg v-if="getIdsSelected.indexOf(item.id) >= 0" class="position-absolute r16" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
              </svg>
            </button>
          </template>
        </div>
        <div class="p8-16 w100Proc box-border">
          <pagination-panel-default class="mb0" @load-more="loadMore" v-if="currentPage < totalPage" :if-active="isLoadMore"></pagination-panel-default>
        </div>


      </div>


      <div class="bottom-panel position-fixed w320 b0 r0 h48 box-border p8-16">
        <button class="blue-button w100Proc h32 fz14 fw500" :class="{'disabled': disabledAttach}" :disabled="disabledAttach" v-on:click="attachMessage">Attach Message</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

const linkedCancelToken = axios.CancelToken;
let linkedCancel;
  import axios from "axios";
  import QueryString from "querystring";
import PaginationPanelDefault from "@/components/ports/paginationDefault.vue";
import AppLoader from "@/components/loader.vue";

  export default {
    components: {AppLoader, PaginationPanelDefault},
    props: {
      item: Object,
    },
    data() {
      return {
        type: 'vessels',
        search: '',
        results: [],
        isLoading: false,
        url: '/contract/dropdown',
        selected: [],
        isLoadMore: false,
        next: '',
        currentPage: 1,
        totalPage: 0,
        ports: [],
        vessels: [],
      }
    },
    watch: {
      type: function () {
        this.totalPage = 0
        this.search = ''
        this.results = []
        this.selected = this.type == 'vessels' ? JSON.parse(JSON.stringify(this.vessels)) : JSON.parse(JSON.stringify(this.ports))
        this.next = null
        if(this.type == 'vessels') {
          this.url = '/contract/dropdown'
        } else {
          this.url = '/port/mail/dropdown/'
        }
        this.asyncFind()
      }
    },
    computed: {
      ...mapGetters(['getToken']),
      disabledAttach() {
        if(this.type == 'vessels') {
          return !(this.selected.filter(el=> this.vessels.map(a=> {return a.id}).indexOf(el.id) == -1).length || this.vessels.filter(el=> this.selected.map(a=> {return a.id}).indexOf(el.id) == -1).length)
        } else {
          return !(this.selected.filter(el=> this.ports.map(a=> {return a.id}).indexOf(el.id) == -1).length || this.ports.filter(el=> this.selected.map(a=> {return a.id}).indexOf(el.id) == -1).length)
        }
      },
      getIdsSelected() {
        return this.selected.map(a=> {return a.id})
      },
      getParams() {
        return this.type == 'vessels' ? {
          is_archive: false,
          contract_type: ['SHIP']
        } : {
          is_archive: false,
          page: this.currentPage
        }
      }
    },
    methods: {
      ...mapActions(['axiosAttachMessageToVessel', 'axiosGetMailById', 'axiosUnPinMessageFromObject']),
      ...mapMutations(['showLoad', 'hideLoad']),
      async attachMessage() {
        this.showLoad()
        if(this.type == 'vessels') {
          await this.axiosAttachMessageToVessel({ids: Array.isArray(this.item) == true ? this.item : [this.item.id], contracts: this.selected.map(a=> {return a.id})})
          let unPinIds = this.vessels.filter(el=> this.selected.map(a=> {return a.id}).indexOf(el.id) == -1).map(a=> {return a.id})
          if(unPinIds.length) {
            await this.axiosUnPinMessageFromObject({id: this.item.id, data: {contracts_id: unPinIds}})
          }
        } else {
          await this.axiosAttachMessageToVessel({ids: Array.isArray(this.item) == true ? this.item : [this.item.id], ports: this.selected.map(a=> {return a.id})})
          let unPinIds = this.ports.filter(el=> this.selected.map(a=> {return a.id}).indexOf(el.id) == -1).map(a=> {return a.id})
          if(unPinIds.length) {
            await this.axiosUnPinMessageFromObject({id: this.item.id, data: {ports_id: unPinIds}})
          }
        }
        this.$emit('attached-multi', Array.isArray(this.item))
        this.$emit('attached', this.item.id)
        this.$emit('close')
        this.hideLoad()
      },
      select(item) {
        if(this.selected.find(a=> a.id == item.id)) {
          this.selected = this.selected.filter(a=> a.id !== item.id)
        } else {
          this.selected.push(item)
        }
      },
      async openPorts(item) {
        item.is_show = !item.is_show
        // if(!item.ports.length) {
        //   item.is_load = true
        //   let url = process.env.VUE_APP_URL+'/port/?ordering=+name&limit=999'
        //   url += '&port_ids='+item.ids.toString()
        //   await axios({
        //     method: 'get',
        //     url: url,
        //     headers: {
        //       'Authorization': 'token ' + this.getToken,
        //     },
        //   }).then(response => {
        //     item.ports = response.data.results
        //   })
        //   item.is_load = false
        // }
      },
      asyncFind () {
        if(linkedCancel){
          linkedCancel()
          linkedCancel = null
          this.isLoading = false
        }
        this.isLoading = true
        this.currentPage = 1;

        axios({
          method: 'get',
          url: process.env.VUE_APP_URL+this.url,
          headers: {
            'Authorization': 'token ' + this.getToken,
          },
          cancelToken: new linkedCancelToken(function executor(c) {
            linkedCancel = c;
          }),
          params: {
            ...this.getParams,
            search: this.search,
            limit: 18,
            ordering: 'name'

          },
          paramsSerializer: function (params) {
            return QueryString.stringify(params)
          },
        }).then(response => {
          console.log('response', response)
          if(this.type == 'vessels') {
            this.results = response.data.results
            this.next = response.data.next
          } else {
            this.results = Object.keys(response.data.results).map(name=> {
              return {
                name: name,
                ids: [],
                ports: response.data.results[name],
                is_show: false,
                is_load: false,
              }
            })
            this.totalPage = response.data.count
          }
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          if(error.message)
            console.error('searchError: ', error);
        })
      },
      async loadMore() {
        if(this.type == 'vessels') {
          this.isLoadMore = true
          await axios({
            method: 'get',
            url: this.next,
            headers: {
              'Authorization': 'token ' + this.getToken,
            },
          }).then(response=> {
            if(this.type == 'vessels') {
              this.results = this.results.concat(response.data.results)
              this.next = response.data.next
            }
            this.isLoadMore = false
          }).catch(()=> {
            this.isLoadMore = false
          })
        } else {
          this.isLoadMore = true
          this.currentPage++
          axios({
            method: 'get',
            url: process.env.VUE_APP_URL+this.url,
            headers: {
              'Authorization': 'token ' + this.getToken,
            },
            cancelToken: new linkedCancelToken(function executor(c) {
              linkedCancel = c;
            }),
            params: {
              ...this.getParams,
              ...this.search,
              limit: 18,
              ordering: 'name'
            },
            paramsSerializer: function (params) {
              return QueryString.stringify(params)
            },
          }).then(response => {
            this.results = this.results.concat(Object.keys(response.data.results).map(name=> {
              return {
                name: name,
                ids: [],
                ports: response.data.results[name],
                is_show: false,
                is_load: false,
              }
            }))
            this.totalPage = response.data.count/18
            this.isLoadMore = false

          })
        }

      },

    },
    async mounted() {
      this.showLoad()
      if(Array.isArray(this.item) == true) {
        let item = await this.axiosGetMailById(this.item.id)
        if(item) {
          this.vessels = item.contracts
          this.ports = item.ports
        }
      }

      this.hideLoad()
      this.asyncFind()
      this.selected = this.type == 'vessels' ? JSON.parse(JSON.stringify(this.vessels)) : JSON.parse(JSON.stringify(this.ports))

    }
  }
</script>

<style scoped lang="scss">
.wrap {
  height: calc(100vh);
}
.content {
  height: 100%;
}
.filter {
  background-color: var(--color-filter-modal);
  opacity: 0.15;
}
  .pre-active {
    border-bottom: 2px solid transparent;
  }
  .active {
    border-bottom: 2px solid var(--color-blue-dark);
    color: var(--color-blue-dark)
  }
  .content-list {
    height: calc(100vh - 188px - 28px);
    width: 100%;
  }
.dart-mode {
  .filter {
    opacity: 0.75;
  }
}
/deep/ {
  .dafault-list-more {
    margin: 0;
  }
}
.load-mode {
  background-color: var(--color-gray-light) !important;
  color: var(--color-gray)
}
.disabled {
  background-color: var(--color-gray);
  color: var(--color-white);
}
</style>