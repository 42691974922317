<template>
  <transition name="fade">
    <div class="wrap" v-if="show">
      <div class="filter">
      </div>
      <div class="main-block">
        <button class="close" v-on:click="closeModal">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
            <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
          </svg>
        </button>
        <p class="fz16 fw500" v-if="$route.path.indexOf('archive') === -1">Archive the vessel</p>
        <p class="fz16 fw500" v-if="$route.path.indexOf('archive') !== -1">Unarchive the vessel</p>
        <p class="fz14 text-dark" v-if="$route.path.indexOf('archive') === -1">Are you sure you want to archive this report?</p>
        <p class="fz14 text-dark" v-if="$route.path.indexOf('archive') !== -1">Are you sure you want to unarchive this report?</p>
        <div class="bottom-panel">
          <button class="blue-button-text fz14" v-on:click="closeModal">No</button>
          <button class="blue-button fz14 fw500 h32 buttonW100" v-on:click="closeModalYes">Yes</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "vessel-report-modal-delete",
  data() {
    return {
      show: false,
    }
  },
  methods: {
    openModal() {
      this.show = true;
    },
    closeModalYes() {
      this.$emit("clickYes");
      this.show = false;
    },
    closeModal() {
      this.show = false;
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
  @import "vessel-report-modal-delete";
</style>