<template>
  <modal-slots 
    :showMod="isShowModal"
    :isBorder="false"
    @close="$emit('on-close')"
    @apply="$emit('on-send')"
  >
    <template v-slot:title>
      <h2 class="fz18 fw500" v-if="$route.name != 'ports-archive'">Archive {{Array.isArray(ports) ? 'ports': 'the port'}}</h2>
      <h2 class="fz18 fw500" v-else>Unarchive {{Array.isArray(ports) ? 'ports': 'the port'}}</h2>
    </template>

    <p class="p-6 fz14" v-if="$route.name != 'ports-archive'">Are you sure you want to archive {{Array.isArray(ports) ? (ports.length > 1 ? ports.length + ' ports':'1 port'): 'this port'}}?</p>
    <p class="p-6 fz14" v-else>Are you sure you want to unarchive {{Array.isArray(ports) ? (ports.length > 1 ? ports.length + ' ports':'1 port'): 'this port'}}?</p>

    <template v-slot:bottomFirstButton>
      <button class="blue-button-text fz14 fw500 m-l-6 p-x-16" @click="$emit('on-close')">No</button>
    </template>
    <template v-slot:bottomSecondButton>
      <button class="blue-button fz14 fw500 m-l-6 p-x-16" @click="$emit('on-send')">Yes</button>
    </template>
  </modal-slots>
</template>

<script>
import modalSlots from '@/components/modal/modal-slots'

export default {
  components: {
    modalSlots
  },
  props: {
    isShowModal: Boolean,
    ports: [Array, Object]
  }
}
</script>

<style>

</style>