<template>
  <modal-slots :showMod="true" class="modal-location" :footerHide="true" @close="hideModal">
    <template v-slot:title>
      <p class="fz24 fw600">Send Message</p>
    </template>
    <div class="modal-location-content d-flex align-items-stretch">
      <div class="names-vessel border-right box-border overflow-y-auto">
        <p class="h36 p0-16 fz16 fw600 text-dark d-flex align-items-center box-border">Vessels</p>
        <button v-on:click="selectVessel(vessel)" v-tooltip.top="vessel.name ? vessel.name : 'Without name'" :class="{'active-button': selectedVessel && selectedVessel.id === vessel.id}" class="hover:bg-gray-lightest justify-start vessels h40 p0-16 box-border d-flex align-items-center w100Proc" v-for="(vessel, i) in vesselsLocal" :key="i+'jhgs67'">
          <span class="fz14 name-vessel text-dots-overflow text-align-left" :class="{'text-dark': vessel.name, 'text-gray': !vessel.name}">{{vessel.name ? vessel.name : 'Without name'}}</span>
          <span class="vessel-circle d-flex align-items-center justify-center ml-auto text-white fz12 fw600" :class="{'bgc-g': !vessel.selected.length}">{{vessel.selected.length}}</span>
        </button>
      </div>
      <div class="recipients border-right box-border d-flex align-items-stretch flex-column justify-stretch">
        <p class="h36 p0-16 fz16 fw600 d-flex align-items-center justify-start border-bottom box-border">Select Recipients</p>

        <div class="wrap-list box-border overflow-y-auto border-bottom position-relative">
          <location-read-emails-list @select="selectDetail" :selected="selectedVessel.selected" @selectCheckbox="selectCheckbox" :modal-send="true" v-if="selectedVessel && selectedVessel.messages.length" :type="'incoming'" :selected-email="selectedDetailId" :loadList="loadList" :messages="selectedVessel.messages"></location-read-emails-list>
          <div class="not-found w100Proc d-flex flex-column justify-center align-items-center position-absolute t0 l0 h100Proc" v-else>
            <app-loader :is-local="true" v-if="loadList" class="loader-list"></app-loader>
            <svg width="140" height="120" viewBox="0 0 140 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M96.3145 17.3779C92.9427 17.3779 90.2226 20.0847 90.2226 23.4074V26.7301H102.405V23.2652C102.329 20.0082 99.6381 17.3779 96.3145 17.3779ZM104.789 23.4074H104.79C104.79 23.3471 104.79 23.287 104.788 23.227C104.691 18.6597 100.928 15 96.3145 15C91.6408 15 87.8387 18.7568 87.8387 23.4074V29.437H35.5245C34.9304 24.3516 30.5716 20.4139 25.2967 20.4139C19.6173 20.4139 15 24.9787 15 30.626V93.7879C15 99.4352 19.6173 104 25.2967 104H114.524C119.198 104 123 100.243 123 95.5926V37.8445C123 33.1939 119.198 29.437 114.524 29.437H104.789V23.4074ZM104.789 31.8149V79.3509C104.789 79.6221 104.696 79.8852 104.525 80.0965L97.243 89.1196C97.0167 89.3999 96.6754 89.563 96.3146 89.563C95.9538 89.563 95.6124 89.4 95.3862 89.1197L88.1023 80.0966C87.9317 79.8853 87.8387 79.6222 87.8387 79.3509V31.8149H35.5935V93.7879C35.5935 94.4446 35.0599 94.9769 34.4016 94.9769C33.7433 94.9769 33.2097 94.4446 33.2097 93.7879C33.2097 89.4685 29.6743 85.9537 25.2967 85.9537C20.9192 85.9537 17.3838 89.4685 17.3838 93.7879C17.3838 98.1073 20.9192 101.622 25.2967 101.622H114.524C117.896 101.622 120.616 98.9153 120.616 95.5926V37.8445C120.616 34.5217 117.896 31.8149 114.524 31.8149H104.789ZM17.3838 87.253C19.2738 85.0049 22.119 83.5759 25.2967 83.5759C28.4745 83.5759 31.3197 85.0049 33.2097 87.253V30.626C33.2097 26.3065 29.6743 22.7917 25.2967 22.7917C20.9192 22.7917 17.3838 26.3065 17.3838 30.626V87.253ZM102.405 29.1079H90.2226V33.9486H102.405V29.1079ZM102.405 36.3265H90.2226V78.9317L96.3144 86.4782L102.405 78.9318V36.3265Z" fill="#DEE3EA"/>
            </svg>

            <p class="fw600 fz14 text mt16">List is empty</p>
          </div>
        </div>
        <div class="wrap-detail overflow-y-auto position-relative flex-1 p16 box-border">
          <location-read-emails-detail :hideAddVessel="true" :hideReply="true" :getShow="getShow"  :selected-email="selectedDetail" :load-message="loadMessage" v-if="selectedDetail"></location-read-emails-detail>
          <div class="position-absolute t0 l0 w100Proc h100Proc box-border d-flex align-items-center justify-center position-relative" v-else>
            <app-loader :is-local="true" v-if="loadMessage"></app-loader>
            <div class="no-selected d-flex align-items-center flex-column">
              <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M72.5 72.6868V73.2235C72.5 75.0019 71.0673 76.4436 69.3 76.4436H60.4758M72.5 72.6868V35.0716C72.5 34.2096 72.1538 33.3843 71.54 32.7831M72.5 72.6868L46.1533 58.3297M8.5 72.6868V73.2235C8.5 75.0019 9.93269 76.4436 11.7 76.4436H54.5606M8.5 72.6868L34.6716 58.3297M8.5 72.6868V49.9606M46.1533 58.3297L41.9222 56.024C40.9688 55.5044 39.8188 55.506 38.8668 56.0283L34.6716 58.3297M46.1533 58.3297L66.74 37.6133M66.74 37.6133L71.54 32.7831M66.74 37.6133V28.0813M71.54 32.7831L66.74 28.0813M34.6716 58.3297L14.26 37.6521M14.26 37.6521L9.45362 32.7831M14.26 37.6521V28.0435M9.45362 32.7831C8.8437 33.3845 8.5 34.2077 8.5 35.067V44.9434M9.45362 32.7831L14.26 28.0435M66.74 28.0813V17.4082C66.74 15.6298 65.3073 14.1881 63.54 14.1881H52.5566M52.5566 14.1881L42.6329 4.46738C41.3875 3.24755 39.4024 3.25088 38.1611 4.47488L28.3109 14.1881M52.5566 14.1881H28.3109M28.3109 14.1881H17.46C15.6927 14.1881 14.26 15.6298 14.26 17.4082V28.0435M40.6067 42.7394C35.128 42.7394 30.6867 38.2221 30.6867 32.6497C30.6867 27.0774 35.128 22.5601 40.6067 22.5601C46.0853 22.5601 50.3133 26.7553 50.3133 32.3277C50.3133 35.2258 48.9267 37.9399 47.0067 38.1239C44.98 38.3182 43.8067 37.4799 43.9133 32.5424M43.9133 32.5424C43.9133 35.3286 42.1464 37.5872 39.9667 37.5872C37.787 37.5872 36.02 35.3286 36.02 32.5424C36.02 29.7562 37.787 27.4976 39.9667 27.4976C42.1464 27.4976 43.9133 29.7562 43.9133 32.5424ZM43.9133 32.5424V26.8535" stroke="#9AA3B0" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <p class="fz14 fw500 text-dark mt16">Select an email to see its content here</p>
            </div>
          </div>
        </div>
      </div>
      <div class="reply d-flex flex-column">
        <p class="h36 p0-16 fz16 fw600 d-flex align-items-center justify-start border-bottom box-border">Select Recipients</p>
        <div class="h150 p8-16 box-border border-bottom overflow-y-auto min-h-150">
          <div class="d-flex" v-for="(vessel, i) in vesselsLocal" :class="{'mt8': i !== 0}" :key="i+'sdm33'">
            <div class="mr2">
              <p class="fz14 text-gray">{{vessel.name ? vessel.name : 'Without name'}}:</p>
            </div>
            <div class="d-flex flex-wrap">
              <p v-if="!vessel.selected.length" class="ml2 text-red fz14">No recipient selected</p>
              <p class="selected-mail fz14 text-dark position-relative d-flex align-items-center" v-for="(select, j) in vessel.selected" :key="j+'l343'">{{select.message_from}}
                <svg v-on:click="deleteSelected(i, j)" class="position-absolute r8 cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.53549 9.09341L6.94283 6.50076L9.53558 3.90801C9.79573 3.64785 9.79573 3.22542 9.53549 2.96518C9.27533 2.70502 8.8529 2.70502 8.59274 2.96518L6 5.55792L3.40726 2.96518C3.1471 2.70502 2.72467 2.70502 2.46451 2.96518C2.20427 3.22542 2.20427 3.64785 2.46442 3.90801L5.05717 6.50076L2.46451 9.09341C2.20427 9.35366 2.20427 9.77609 2.46442 10.0362C2.72467 10.2965 3.1471 10.2965 3.40734 10.0362L6 7.44359L8.59266 10.0362C8.8529 10.2965 9.27533 10.2965 9.53558 10.0362C9.79573 9.77609 9.79573 9.35366 9.53549 9.09341Z" fill="#2C2C2C"/>
                </svg>

              </p>
            </div>
          </div>
        </div>
        <div class="p8-16 box-border w100Proc flex-1">
<!--          <p class="fz13 text-gray">Sender</p>-->
<!--          <input :class="{'error': errorSender}" type="text" class="w100Proc box-border h36 mt4" v-model="sender">-->
          <vue-editor :class="{'errorDescription': errorDescription}" v-model="description" class="edit-block-wrap mt12" />
          <button v-on:click="send()" class="blue-button w100Proc h32 fz14 fw500 mt8">Send Message</button>
        </div>
      </div>
    </div>
    <modal-slots :show-mod="recipientNotSelected" @close="recipientNotSelected = false" class="modal-not-selected modal-info">
      <div class="p16 box-border w100Proc position-relative d-flex flex-column">
        <button v-on:click="recipientNotSelected = false" class="w-max h-max d-flex align-items-center justify-center position-absolute t17 r16">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57727C2.21563 3.28258 2.21563 2.80478 2.51326 2.51008C2.81089 2.21539 3.29344 2.21539 3.59106 2.51008L13.4858 12.2961C13.7835 12.5908 13.7835 13.0685 13.4858 13.3632C13.1882 13.6579 12.7057 13.6579 12.408 13.3632L2.51326 3.57727Z" fill="#222222"/>
            <path d="M12.4081 2.634C12.7058 2.33931 13.1883 2.33931 13.4859 2.634C13.7836 2.9287 13.7836 3.4065 13.4859 3.70119L3.59116 13.4872C3.29354 13.7819 2.81099 13.7819 2.51336 13.4872C2.21573 13.1925 2.21573 12.7147 2.51336 12.42L12.4081 2.634Z" fill="#222222"/>
          </svg>
        </button>
        <p class="fz16 fw500 text-dark">Recipients not selected</p>
        <p class="fz14 text-dark mt14">Please select at least one recipient</p>
        <div class="d-flex align-items-center justify-end m-t-auto">
          <button class="blue-button w100 h32 fz14 fw500" v-on:click="recipientNotSelected = false">Ok</button>
        </div>
      </div>
    </modal-slots>
    <modal-slots :show-mod="recipientNotSelectedInVessel" @close="recipientNotSelectedInVessel = false" class="modal-not-selected modal-info">
      <div class="p16 box-border w100Proc position-relative d-flex flex-column">
        <button v-on:click="recipientNotSelectedInVessel = false" class="w-max h-max d-flex align-items-center justify-center position-absolute t17 r16">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57727C2.21563 3.28258 2.21563 2.80478 2.51326 2.51008C2.81089 2.21539 3.29344 2.21539 3.59106 2.51008L13.4858 12.2961C13.7835 12.5908 13.7835 13.0685 13.4858 13.3632C13.1882 13.6579 12.7057 13.6579 12.408 13.3632L2.51326 3.57727Z" fill="#222222"/>
            <path d="M12.4081 2.634C12.7058 2.33931 13.1883 2.33931 13.4859 2.634C13.7836 2.9287 13.7836 3.4065 13.4859 3.70119L3.59116 13.4872C3.29354 13.7819 2.81099 13.7819 2.51336 13.4872C2.21573 13.1925 2.21573 12.7147 2.51336 12.42L12.4081 2.634Z" fill="#222222"/>
          </svg>
        </button>
        <p class="fz16 fw500 text-dark">Recipients not selected from all vessels</p>
        <p class="fz14 text-dark mt14">Are you sure you want to continue sending?</p>
        <div class="d-flex align-items-center justify-end m-t-auto">
          <button class="blue-button-text fz14 mr33" v-on:click="recipientNotSelectedInVessel = false">Cancel</button>
          <button class="blue-button w100 h32 fz14 fw500" v-on:click="sendAccept()">Send</button>
        </div>
      </div>
    </modal-slots>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import LocationReadEmailsList from "@/components/location/location-read-emails/location-read-emails-list";
import {mapActions, mapMutations} from "vuex";
import AppLoader from "@/components/loader";
import { VueEditor } from "vue2-editor";
import LocationReadEmailsDetail from "@/components/location/location-read-emails/location-read-emails-detail";
export default {
  name: "location-modal-send-message",
  props: {
    vessels: Array,
  },
  components: {LocationReadEmailsDetail, AppLoader, LocationReadEmailsList, ModalSlots, VueEditor},
  data() {
    return {
      description: '',
      sender: '',
      vesselsLocal: [],
      loadList: false,
      selectedEmail: null,
      selectedVessel: null,
      selectedDetail: null,
      loadMessage: false,
      getShow: false,
      selectedDetailId: {
        id: null
      },
      errorSender: false,
      errorDescription: false,
      recipientNotSelected: false,
      recipientNotSelectedInVessel: false,
      subject: '',
      errorSubject: false,
    }
  },
  watch: {
    vessels: function () {
      this.vesselsLocal = JSON.parse(JSON.stringify(this.vessels))
      this.vesselsLocal.forEach(item=> {
        item['messages'] = [];
        item['selected'] = [];
        item['nextLink'] = '';
      })
      this.vesselsLocal = JSON.parse(JSON.stringify(this.vesselsLocal))
      this.selectedVessel = this.vesselsLocal[0]
    },
    selectedVessel: async function () {
      this.selectedDetailId = {id: null}
      this.selectedDetail = null;
      this.loadMessage = false;
      if(this.selectedVessel && !this.selectedVessel.messages.length) {
        this.loadList = true
        let response = await this.axiosGetMailsForVeselById({id: this.selectedVessel.id, params: {type: 'incoming'} })
        this.loadList = false
        console.log('response', response.messages)
        if(response !== false)
          this.selectedVessel.messages = response.messages
      }

    },
  },
  methods: {
    ...mapActions(['axiosGetMailsForVeselById', 'axiosGetMessagesById', 'axiosSendMessage']),
    hideModal() {
      let isReads = []
      this.vesselsLocal.forEach(item=> {
        if(item.messages.length > 0 && item.messages.length === item.messages.map(a=> {return a.is_read}).length) {
          isReads.push(item.id)
        }
      })
      this.$emit('is_reads', isReads)
      this.recipientNotSelected = false
      this.recipientNotSelectedInVessel = false
      this.sender = ''
      this.description = ''
      this.errorSubject = false
      this.subject = ''
      this.$emit('close')
    },
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    async sendAccept() {
      this.recipientNotSelectedInVessel = false
      this.showLoad()
      let selected = []
      this.vesselsLocal.forEach(item=> {
        if(item.selected.length > 0) {
          // to_emails = to_emails.concat(item.selected.map(el=> {return el.message_from}))
          // Ids.push(item.id)
          item.selected.forEach(item=> {
            item['id_ship'] = item.id
          })
          item.selected = JSON.parse(JSON.stringify(item.selected))
          selected = selected.concat(item.selected)
        }
      })
      // let form = {
      //   subject: this.subject ? this.subject : '1234',
      //   text: this.description,
      //   message_from: this.sender,
      //   message_to: Array.from(new Set(to_emails)),
      //   bind_ships: Ids,
      // }
      let res= null
      for(let i=0; i<selected.length; i++) {
        let form = {
          subject: 'Re:' + selected[i].subject,
          text: this.description,
          message_from: selected[i].message_to.length ? selected[i].message_to[0] : '',
          message_to: [selected[i].message_from],
          bind_ships: [selected[i].id_ship],
        }
        res = await this.axiosSendMessage(form)

      }
      this.hideLoad()
      if(res) {
        this.hideModal()
        this.$emit('clearSelected')
        this.addTip(selected.length > 1 ? "Messages sent" : 'Message sent')
      }
    },
    send() {
      let regex = /( |<([^>]+)>)/ig
      // this.errorSender = !this.sender.length || !this.getValidEmail(this.sender)
      this.errorDescription = !this.description.replace(regex, "").length
      if(this.errorDescription) {
        return null;
      }
      let selectedCount = 0
      let notSelectedInVessel = false;
      this.vesselsLocal.forEach(item=> {
        if(item.selected.length === 0) {
          notSelectedInVessel = true;
        }
        selectedCount+=item.selected.length
      })
      if(selectedCount === 0) {
        this.recipientNotSelected = true;
        return  null
      }
      if(notSelectedInVessel) {
        this.recipientNotSelectedInVessel = true;
        return  null;
      }
      this.sendAccept();
    },
    getValidEmail(email) {
      let EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return EMAIL_REGEXP.test(email)
    },
    deleteSelected(i, j) {
      this.vesselsLocal[i].selected.splice(j, 1)
    },
    selectCheckbox(msg) {
      if(this.selectedVessel.selected.find(a=> a.id === msg.id)) {
        this.selectedVessel.selected = this.selectedVessel.selected.filter(a => a.id !== msg.id)
      } else {
        this.selectedVessel.selected.push(msg)
      }
    },
    selectVessel(vessel) {
      this.selectedVessel = vessel
    },
    async selectDetail(msg) {
      this.selectedDetail = null
      this.selectedDetailId = {id:msg.id}
      this.loadMessage = true
      let response = await this.axiosGetMessagesById(msg.id)
      this.selectedDetail = response !== false ? response : null
      if(this.selectedDetail) {
        msg.is_read = true
        setTimeout(()=> {
          this.getShow = document.getElementById('toText').clientWidth > 590
        }, 120)
      }
      this.loadMessage = false
    },
  },
  async mounted() {
    this.vesselsLocal = JSON.parse(JSON.stringify(this.vessels))
    this.vesselsLocal.forEach(item=> {
      item['messages'] = [];
      item['selected'] = [];
      item['nextLink'] = '';
    })
    this.vesselsLocal = JSON.parse(JSON.stringify(this.vesselsLocal))
    this.selectedVessel = this.vesselsLocal[0]
  }
}
</script>

<style scoped lang="scss">
  .modal-location {
    /deep/ {
      .content {
        margin-top: 24px;
        margin-bottom: 0px;
        height: calc(100vh - 48px);
      }
    }
  }
  .modal-location-content {
    width: 1318px;
    height: calc(100vh - 48px - 45px);
  }
  .names-vessel {
    width: 190px;
  }
  .reply {
    width: 538px;
  }
  .recipients {
    width: calc(100% - 190px - 538px);
  }
  .border-right, .border-bottom {
    border-color: var(--color-card-border);
  }
  .name-vessel {
    max-width: calc(100% - 30px);
  }
  .active-button {
    background-color: var(--color-blue-lightest);
  }
  .wrap-list {
    min-height: 224px;
    height: 38%;
  }
  .wrap-detail {
    height: max-content;
    .h100Proc {
      height: calc(100% - 32px) !important;
    }
  }
  /deep/ {
    .list-container {
      grid-template-columns: 50px 164px 1fr 157px 64px;
      grid-template-areas: "read sender subject sent-time checkbox-additioanaly";
    }
  }
  .vessel-circle {
    background-color: var(--color-blue);
    min-width: 20px;
    height: 20px;
    border-radius: 40px;
    padding: 0 4px;
    box-sizing: border-box;
  }
  .bgc-g {
    background-color: var(--color-gray-blacker-than-black);
  }
/deep/ {
    .view-file {
      top: 0;
      height: 100vh !important;
    }
}
.selected-mail {
  margin-left: 2px;
  padding: 2px 22px 2px 8px;
  background-color: var(--color-blue-lightest);
  border-radius: 20px;
}
.edit-block-wrap {
  /deep/ {
    .ql-editor {
      height: calc(100vh - 430px);
      min-height: calc(100vh - 430px);
      overflow-y: auto;
      background-color: var(--color-input);
      em {
        font-style: italic !important;
      }
      strong {
        font-weight: 500;
      }
    }
  }
}
.error {
  border-color: var(--color-red) !important;
}
.errorDescription {
/deep/ {
  .ql-toolbar {
    border-top: 1px solid var(--color-red);
    border-left: 1px solid var(--color-red);
    border-right: 1px solid var(--color-red);
  }
  .ql-container {
    border-bottom: 1px solid var(--color-red);
    border-left: 1px solid var(--color-red);
    border-right: 1px solid var(--color-red);
  }
}
}
.modal-not-selected {

}
.modal-info {
  align-items: center;
  /deep/ {
    .top-panel {
      display: none !important;
    }
    .bottom-panel {
      display: none !important;
    }
    .content {
      margin: 0 !important;
      width: 400px;
      height: 144px;
      min-width: 400px;
    }
    .main-block {
      &>div {
        height: 144px;
      }
    }
  }
}
</style>