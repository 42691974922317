<template>
  <div class="account_top-panel">
    <p class="fz20 text-dark fw500">Archive Banks and Cash Accounts</p>

    <div class="block-search">
      <input type="text" v-model="searchText" @input="onSearch" placeholder="Search by keyword">
      <button class="icon-close" @click="clearSearch" v-show="searchText">
        <svg width="12" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.05373 1.9253C0.810215 1.68418 0.810216 1.29326 1.05373 1.05214C1.29724 0.811029 1.69206 0.811029 1.93557 1.05214L6.94574 5.97329C7.18925 6.21441 7.18925 6.60533 6.94574 6.84644C6.70223 7.08756 6.30741 7.08756 6.0639 6.84644L1.05373 1.9253Z" fill="#2C2C2C"/>
          <path d="M6.06398 1.15353C6.3075 0.912416 6.70231 0.912416 6.94582 1.15353C7.18933 1.39465 7.18933 1.78557 6.94582 2.02669L1.93565 6.94783C1.69214 7.18895 1.29732 7.18895 1.05381 6.94783C0.810298 6.70672 0.810298 6.31579 1.05381 6.07468L6.06398 1.15353Z" fill="#2C2C2C"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: ''
    }
  },
  methods: {
    onSearch() {
      this.$emit('search', this.searchText)
    },
    clearSearch() {
      this.searchText = ''
      this.$emit('search', this.searchText)
    }
  }
}
</script>

<style lang="scss" scoped>
  .block-search {
    margin-left: auto;
    width: 224px;
    display: flex;
    align-items: center;
    position: relative;
    input {
      width: 100%;
      box-sizing: border-box;
      height: 32px;
      padding-left: 42px;
      padding-right: 32px;
      background-color: #fff;
      background-image: url(../../../../assets/image/search1.svg);
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-position: 10px center;
    }
    .icon-close {
      position: absolute;
      padding: 6px;
      right: 4px;
      transition: .1s linear;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
</style>