<template>
  <div class="wrap">
<!--    {{buttons}}-->

    <b-dropdown right ref="dropdown"
    @show="show=true"
    @hide="show = false">
      <template #button-content>
        <button class="select-block">
          {{nameActive}}
          <svg :class="{'rotate': show}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="#2C2C2C"/>
          </svg>
        </button>
      </template>
      <div class="content">
        <button class="text-dark fz14" v-for="(b, i) in buttons" :key="i" v-on:click="clickButton(i)">{{b.name}}
          <svg :class="{'svg-active': b.status === true}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/>
        </svg>
        </button>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
export default {
  name: "departments",
  computed: mapGetters(["getDepartments", "getPermissionsDepartmentsData"]),
  data() {
    return {
      buttons: [],
      nameActive: "",
      show: false,
      IdDepartmentUser: 0,
    }
  },
  methods: {
    ...mapActions(["axiosGetDepartments", "axiosGetPermissionsDepartments"]),
    ...mapMutations(["clearActiveCheckboxInMenu"]),
    clickButton(index) {
      this.clearActiveCheckboxInMenu();
      console.log(index);
      for(let i=0; i<this.buttons.length; i++) {
        this.buttons[i].status = false;
      }
      this.buttons[index].status = true;
      this.nameActive = this.buttons[index].name;
      // console.log(this.buttons);
      this.$refs.dropdown.hide(true);
      console.log("change");
      this.$emit("changeDepartments", this.buttons[index]);
    },
    setIdDepartmentUser(idDep) {
      this.IdDepartmentUser = idDep;
      this.setDepartmentForUser(idDep)
    },
    setDepartmentForUser(idDep) {
      if(idDep !== null && idDep !== undefined) {
        let index = 0;
        for(let i=0; i<this.buttons.length; i++) {
          this.buttons[i].status = false;
          if(this.buttons[i].id === idDep) {
            index = i;
          }
        }
        this.buttons[index].status = true;
        this.nameActive = this.buttons[index].name;
        // console.log(this.buttons);
        this.$refs.dropdown.hide(true);
        console.log("change");
        this.$emit("changeDepartments", this.buttons[index]);
      }

    },
    addCountRequest() {
      this.$emit('addCountRequest')
    },
    decCountRequest() {
      this.$emit('decCountRequest')
    },
    renderNew() {
      this.buttons = [];
      this.addCountRequest()
      this.axiosGetDepartments().then(()=> {
        this.decCountRequest();
        this.addCountRequest()

        this.axiosGetPermissionsDepartments().then(()=> {
          this.decCountRequest();

          if(this.getDepartments.data.results.length !== 0) {
            for (let i = 0; i < this.getDepartments.data.results.length; i++) {
              let obj = {
                name: "",
                id: "",
                status: false,
                permissions: [],
              }
              obj.name = this.getDepartments.data.results[i].name;
              obj.id = this.getDepartments.data.results[i].id;
              // if (this.getDepartments.data.results[i].permissions !== null && this.getDepartments.data.results[i].permissions !== undefined) {
              for (let j = 0; j < this.getPermissionsDepartmentsData.data.results.length; j++) {
                if(this.getPermissionsDepartmentsData.data.results[j].department === this.getDepartments.data.results[i].id) {
                  obj.permissions.push(this.getPermissionsDepartmentsData.data.results[j].type);
                }
              }
              // }

              this.buttons.push(obj);
            }
            this.buttons[0].status = true;
            this.nameActive = this.buttons[0].name;
            console.log("0Perm2");
            console.log(this.buttons[0].permissions);
            console.log("1Perm2");
            this.clickButton(0);
            this.$emit("changeDepartments", this.buttons[0]);
            this.setDepartmentForUser(this.IdDepartmentUser)
          }
        })
      })
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
@import "../style-element/dropdown";
.rotate {
  transform: rotateX(180deg);
}
.wrap {
  width: 100%;
}
/deep/ .dropdown {
  width: 100%;
}
/deep/ .show {
  .select-block {
    border-color: var(--color-blue-dark) !important;
  }
}
p {
  margin: 0;
}
/deep/ .show {
  .arrow {
    transform: rotateX(180deg);
  }
}
/deep/ .dropdown-menu {
  background: transparent;
  border: none;
  min-width: 1px;
  width: 100%;
}
.content {
  background: var(--color-modal);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
  //box-sizing: border-box;
  //overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    .svg-active {
      display: block !important;
    }
  &>button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 28px;
    width: 100%;
     box-sizing: border-box;
    padding-left: 12px;
    padding-right: 32px;
    text-transform: capitalize;
    position: relative;
    svg {
      position: absolute;
      right: 4px;
      display: none;
    }
    transition: background-color .1s linear;
    &:hover {
      background-color: var(--color-hover-list);
    }
  }
}


.select-block {
  width: 100%;
  background: var(--color-input);
  border: 1px solid var(--color-border);
  box-sizing: border-box;
  border-radius: 4px;
  height: 36px;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-dark);
  transition: .2s linear;
  display: flex;
  justify-content: flex-start;
  &:hover {
    border-color: #A3A3A3;
  }
  &:focus {
    border-color: var(--color-blue-dark);
  }
  position: relative;
  align-items: center;
  svg {
    transition: .1s linear;
    position: absolute;
    right: 12px;
    path {
      fill: var(--color-dark)
    }
  }
}

</style>