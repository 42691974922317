<template>
  <div class="port_detail flex w-full h-full">
    <div class="col border-r border-gray-light p-x-6 p-y-4">
      <detail v-if="port" :port="port"/>
    </div>

    <div class="col border-r border-gray-light p-x-6 p-y-4">
      <files v-if="port" :files="port.files" @update="update"/>
    </div>

    <div class="col border-r border-gray-light p-x-6 p-y-4">
      <comments v-if="port"/>
    </div>

    <div class="col p-4">
      <activity v-if="port" :activity="activity ? activity : []"/>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

import detail from '@/components/ports/detail/detail'
import files from '@/components/ports/detail/files'
import comments from '@/components/ports/detail/comments'
import activity from '@/components/ports/detail/activity'
export default {
  components: {
    detail,
    files,
    comments,
    activity
  },
  data() {
    return {
      activity: null
    }
  },
  watch: {
    port: {
      deep: true,
      handler() {
        this.getActivity()
      }
    }
  },
  computed: {
    ...mapState({
      port: state => state.ports.openPort
    }),
    ...mapGetters(['getPermissionsByType'])
  },
  methods: {
    ...mapActions(['axiosGetPortByIdWithoutDeleteData', 'axiosGetPortActivityById']),
    ...mapMutations(['showLoad', 'hideLoad']),
    async getActivity() {
      if(!this.getPermissionsByType('show_activity_in_ports_detailed_page')) return false
      this.activity = await this.axiosGetPortActivityById(this.$route.params.id)
    },
    async update() {
      // this.showLoad()
      await this.axiosGetPortByIdWithoutDeleteData(this.port.id)
      // this.hideLoad()
    },
  },
  mounted() {
    this.getActivity()
  }
}
</script>

<style lang="scss">
.port_detail{
  .col{
    overflow-y: auto;
    box-sizing: border-box;
    &:nth-child(1){
      width: 21%;
    }
    &:nth-child(2){
      width: 28%;
    }
    &:nth-child(3){
      width: 28%;
    }
    &:nth-child(4){
      width: 23%;
    }
  }
}
</style>