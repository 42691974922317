<template>
  <div>
    <folders-list-links class="mt16" :items="list"></folders-list-links>
  </div>
</template>

<script>

  import FoldersListLinks from "@/components/folders/folders-list-links.vue";
  import {mapMutations} from "vuex";

  export default {
    name: 'foldersCounterpartiesOption',
    components: {FoldersListLinks},
    data() {
      return {
        list: [
          {name: 'Details', updated_at: null, link: {name: 'Folders - Counterparties Options Detail', params: {is_archive_files: false}}},
          {name: 'Payments', updated_at: null, link: {name: 'Folders - Counterparties Options Payment', params: {is_archive_payments: false}}},
        ]
      }
    },
    methods: {
      ...mapMutations(['setNameSectionFolders']),
    },
    mounted() {
      this.setNameSectionFolders('Counterparties')
    }
  }
</script>

<style scoped lang="scss">

</style>