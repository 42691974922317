<template>
  <div class="async_select__wrap dafault_multiselect">
    <label :for="id" class="block text-gray m-b-1 fz13" v-if="label">{{label}}</label>

    <multiselect
      :id="id"
      :class="{
        'error': isError,
      }"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      :label="watchKey"
      :options="options"
      :track-by="trackBy"
      :searchable="searchable"
      :multiple="isMultiple"
      :disabled="disabled"
      :taggable="false"
      :showLabels="false"
      :allowEmpty="allowEmpty"
      :clearOnSelect="false"
      :internalSearch="false"
      :preserveSearch="true"
      :closeOnSelect="closeOnSelect"
      :maxHeight="200"
      :openDirection="openDirection"
      ref="multiselect"
      @close="close"
    >
      <template slot="singleLabel" slot-scope="props">
        <span class="text-dark fz14">
          <template v-if="typeof props.option === 'object' && props.option !== null">
            {{props.option[watchKey]}}
          </template>
          <template v-else>
            {{props.option}}
          </template>
        </span>
      </template>

      <template slot="option" slot-scope="props" >
        <span class="flex items-center">
          <template v-if="typeof props.option === 'object' && props.option !== null">
            {{props.option[watchKey]}}
          </template>
          <template v-else>
            {{props.option}}
          </template>
          <svg class="option_check m-l-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
        </span>
      </template>

      <template slot="selection" slot-scope="{ values, isOpen }">
        <span class="multiselect__single p-0 inline-flex flex-wrap items-center fz14 text-dark" v-if="values.length >= 1 && !isOpen">
          <template v-if="typeof values[0] === 'object' && values[0] !== null">
            {{values[0][watchKey]}}
          </template>
          <template v-else>
            {{values[0]}}
          </template>
          <span class="m-l-1" v-if="values.length > 1">/
            <span class="inline-flex items-center justify-center h-5 min-w-5 rounded-full border border-dark fz11">+{{ values.length-1 }}</span>
          </span>
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    value: [Object, Array, String, Number],
    options: {
      type: Array,
      required: true
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    searchable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    openDirection: {
      type: String,
      default: 'bottom'
    },
    label: String,
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    watchKey: {
      type: String,
      default: 'name'
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    placeholder: String,
  },
  components: {
    Multiselect
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit("close")
    },
    onInput(value) {
      this.$emit('input', value)
      this.$emit('on-select')
    },
    openDropdown() {
      this.$refs.multiselect.isOpen = true;
    },
    closeDropdown() {
      this.$refs.multiselect.isOpen = false;
    }
  }
}
</script>

<style lang="scss">
.default_select__hide_main_input{
  .multiselect__tags,
  .multiselect__select{
    display: none;
  }
}
.default_select__fz13{
  .multiselect__single{
    span{
      font-size: 0.8125rem !important;
      line-height: 1rem !important;
    }
  }
}
</style>
