<template>
  <div class="task-list">
    <modal-delete-tag ref="modalDeleteTag" @renderTask="renderNew"></modal-delete-tag>
    <div class="panel-name"  v-if="getTasksList.length !==0">
<!--      {{getIdTaskDuplicateCoupe}}-->
      <span>
        <b-form-checkbox
            id="checkbox-1"
            v-model="status"
            name="checkbox-1"
            value="all"
            unchecked-value="notAll"
            @change="selectAll"
        >
        </b-form-checkbox>
        <p class="fz12 text-gray">Name</p>
      </span>
      <p class="fz12 text-gray">Status</p>
      <p class="fz12 text-gray">Priority</p>
      <p class="fz12 text-gray">Assignee</p>
      <p class="fz12 text-gray">Due date</p>
      <p class="fz12 text-gray">Done date</p>
    </div>
    <div class="no-task position-relative" v-if="getTasksList.length ===0 && getFilterContActivity === 0">
      <app-loader v-if="getLoadRequestFilterTask" class="loader-task-list loader-task-list-no-task"></app-loader>
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M188 43H133C131.343 43 130 41.6569 130 40V32C130 30.3431 131.343 29 133 29H143C144.333 29 147 27.9 147 23.5C147 16.0442 153.044 10 160.5 10C167.956 10 174 16.0442 174 23.5C174 27.9 176.667 29 178 29H188C189.657 29 191 30.3431 191 32V40C191 41.6569 189.657 43 188 43Z" stroke="#DEE3EA" stroke-width="2"/>
        <circle cx="160.5" cy="22.5" r="4.5" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M130 33H122C118.134 33 115 36.134 115 40V133C115 136.866 118.134 140 122 140H199C202.866 140 206 136.866 206 133V40C206 36.134 202.866 33 199 33H191" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M130.5 41H127C124.791 41 123 42.7909 123 45V128C123 130.209 124.791 132 127 132H194C196.209 132 198 130.209 198 128V45C198 42.7909 196.209 41 194 41H191.5" stroke="#DEE3EA" stroke-width="2"/>
        <path d="M167.948 73.6645C165.505 72.4024 162.732 71.6897 159.793 71.6897C149.966 71.6897 142 79.6559 142 89.4828C142 99.3097 149.966 107.276 159.793 107.276C169.62 107.276 177.586 99.3097 177.586 89.4828C177.586 88.7296 177.539 87.9873 177.449 87.2587" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M150.896 86.5172L159.793 93.931L181.5 71.5" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M280.591 128.467C280.395 128.36 280.148 128.218 279.98 128.068C278.384 126.647 276.273 126.641 274.671 128.068C273.614 129.009 272.248 129.009 271.191 128.068C270.407 127.37 269.489 127.001 268.537 127.001C267.584 127.001 266.666 127.37 265.882 128.068C264.825 129.009 263.459 129.009 262.402 128.068C260.806 126.647 258.695 126.641 257.093 128.068C256.94 128.204 256.722 128.344 256.424 128.498C256.057 128.687 255.898 129.172 256.068 129.581C256.238 129.989 256.674 130.167 257.041 129.977C257.452 129.765 257.759 129.563 258.008 129.341C259.065 128.4 260.431 128.4 261.487 129.341C263.084 130.762 265.195 130.768 266.797 129.341C267.854 128.4 269.219 128.4 270.276 129.341C271.873 130.762 273.984 130.768 275.586 129.341C276.643 128.4 278.008 128.4 279.065 129.341C279.343 129.588 279.665 129.777 279.944 129.93C280.307 130.129 280.746 129.962 280.925 129.558C281.103 129.154 280.954 128.666 280.591 128.467Z" fill="#DEE3EA"/>
        <path d="M20.4095 81.467C20.6053 81.3598 20.8517 81.2176 21.0197 81.068C22.6159 79.6466 24.7272 79.6414 26.3293 81.068C27.3861 82.009 28.7519 82.009 29.8086 81.068C30.5928 80.3698 31.5107 80.0007 32.4634 80.0007C33.4161 80.0007 34.3341 80.3698 35.1182 81.068C36.1751 82.009 37.5408 82.009 38.5976 81.068C40.1938 79.6467 42.305 79.6414 43.9071 81.068C44.0597 81.2038 44.2784 81.3444 44.5757 81.498C44.9426 81.6875 45.102 82.1722 44.9318 82.5806C44.7615 82.989 44.3261 83.1665 43.9591 82.977C43.5482 82.7648 43.2409 82.5628 42.992 82.3411C41.9352 81.4001 40.5695 81.4 39.5126 82.3411C37.9164 83.7625 35.8052 83.7677 34.2031 82.3411C33.1463 81.4001 31.7806 81.4 30.7237 82.3411C29.1274 83.7625 27.0162 83.7677 25.4141 82.3411C24.3573 81.4001 22.9916 81.4 21.9347 82.3411C21.6574 82.588 21.3348 82.7774 21.0557 82.9302C20.6927 83.1289 20.2537 82.9623 20.0753 82.5583C19.8969 82.1542 20.0464 81.6657 20.4095 81.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M79 133.2C79.6627 133.2 80.2 132.663 80.2 132C80.2 131.337 79.6627 130.8 79 130.8C78.3373 130.8 77.8 131.337 77.8 132C77.8 132.663 78.3373 133.2 79 133.2ZM79 135C80.6569 135 82 133.657 82 132C82 130.343 80.6569 129 79 129C77.3431 129 76 130.343 76 132C76 133.657 77.3431 135 79 135Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M287 54.2C287.663 54.2 288.2 53.6627 288.2 53C288.2 52.3373 287.663 51.8 287 51.8C286.337 51.8 285.8 52.3373 285.8 53C285.8 53.6627 286.337 54.2 287 54.2ZM287 56C288.657 56 290 54.6569 290 53C290 51.3431 288.657 50 287 50C285.343 50 284 51.3431 284 53C284 54.6569 285.343 56 287 56Z" fill="#DEE3EA"/>
        <path d="M228.409 23.467C228.605 23.3598 228.852 23.2176 229.02 23.068C230.616 21.6466 232.727 21.6414 234.329 23.068C235.386 24.009 236.752 24.009 237.809 23.068C238.593 22.3698 239.511 22.0007 240.463 22.0007C241.416 22.0007 242.334 22.3698 243.118 23.068C244.175 24.009 245.541 24.009 246.598 23.068C248.194 21.6467 250.305 21.6414 251.907 23.068C252.06 23.2038 252.278 23.3444 252.576 23.498C252.943 23.6875 253.102 24.1722 252.932 24.5806C252.762 24.989 252.326 25.1665 251.959 24.977C251.548 24.7648 251.241 24.5628 250.992 24.3411C249.935 23.4001 248.569 23.4 247.513 24.3411C245.916 25.7625 243.805 25.7677 242.203 24.3411C241.146 23.4001 239.781 23.4 238.724 24.3411C237.127 25.7625 235.016 25.7677 233.414 24.3411C232.357 23.4001 230.992 23.4 229.935 24.3411C229.657 24.588 229.335 24.7774 229.056 24.9302C228.693 25.1289 228.254 24.9623 228.075 24.5583C227.897 24.1542 228.046 23.6657 228.409 23.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M62 20.2C63.7673 20.2 65.2 18.7673 65.2 17C65.2 15.2327 63.7673 13.8 62 13.8C60.2327 13.8 58.8 15.2327 58.8 17C58.8 18.7673 60.2327 20.2 62 20.2ZM62 22C64.7614 22 67 19.7614 67 17C67 14.2386 64.7614 12 62 12C59.2386 12 57 14.2386 57 17C57 19.7614 59.2386 22 62 22Z" fill="#DEE3EA"/>
      </svg>
      <p class="fz18 fw500 text-dark1">Tasks list is empty</p>
      <button class="blue-button-text fz14" v-if="$route.path.indexOf('archive') === -1" v-on:click="showModalCreateTask">Create a new task</button>
    </div>
    <div class="no-task position-relative" v-if="getTasksList.length === 0 && getFilterContActivity !== 0">
      <app-loader v-if="getLoadRequestFilterTask" class="loader-task-list loader-task-list-no-task"></app-loader>
      <svg width="320" height="160" viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M189.554 101.5C195.45 94.4651 199 85.3972 199 75.5C199 53.1325 180.868 35 158.5 35C136.132 35 118 53.1325 118 75.5C118 97.8675 136.132 116 158.5 116C170.97 116 182.124 110.364 189.554 101.5ZM189.554 101.5C189.554 101.5 213.185 125.131 214 125.946" stroke="#DEE3EA" stroke-width="2" stroke-linecap="round"/>
        <path d="M40.4095 81.467C40.6053 81.3598 40.8517 81.2176 41.0197 81.068C42.6159 79.6466 44.7272 79.6414 46.3293 81.068C47.3861 82.009 48.7519 82.009 49.8086 81.068C50.5928 80.3698 51.5107 80.0007 52.4634 80.0007C53.4161 80.0007 54.3341 80.3698 55.1182 81.068C56.1751 82.009 57.5408 82.009 58.5976 81.068C60.1938 79.6467 62.305 79.6414 63.9071 81.068C64.0597 81.2038 64.2784 81.3444 64.5757 81.498C64.9426 81.6875 65.102 82.1722 64.9318 82.5806C64.7615 82.989 64.3261 83.1665 63.9591 82.977C63.5482 82.7648 63.2409 82.5628 62.992 82.3411C61.9352 81.4001 60.5695 81.4 59.5126 82.3411C57.9164 83.7625 55.8052 83.7677 54.2031 82.3411C53.1463 81.4001 51.7806 81.4 50.7237 82.3411C49.1274 83.7625 47.0162 83.7677 45.4141 82.3411C44.3573 81.4001 42.9916 81.4 41.9347 82.3411C41.6574 82.588 41.3348 82.7774 41.0557 82.9302C40.6927 83.1289 40.2537 82.9623 40.0753 82.5583C39.8969 82.1542 40.0464 81.6657 40.4095 81.467Z" fill="#DEE3EA"/>
        <path d="M48.4095 88.467C48.6053 88.3598 48.8517 88.2176 49.0197 88.068C50.6159 86.6466 52.7272 86.6414 54.3293 88.068C55.3861 89.009 56.7519 89.009 57.8086 88.068C58.5928 87.3698 59.5107 87.0007 60.4634 87.0007C61.4161 87.0007 62.3341 87.3698 63.1182 88.068C64.1751 89.009 65.5408 89.009 66.5976 88.068C68.1938 86.6467 70.305 86.6414 71.9071 88.068C72.0597 88.2038 72.2784 88.3444 72.5757 88.498C72.9426 88.6875 73.102 89.1722 72.9318 89.5806C72.7615 89.989 72.3261 90.1665 71.9591 89.977C71.5482 89.7648 71.2409 89.5628 70.992 89.3411C69.9352 88.4001 68.5695 88.4 67.5126 89.3411C65.9164 90.7625 63.8052 90.7677 62.2031 89.3411C61.1463 88.4001 59.7806 88.4 58.7237 89.3411C57.1274 90.7625 55.0162 90.7677 53.4141 89.3411C52.3573 88.4001 50.9916 88.4 49.9347 89.3411C49.6574 89.588 49.3348 89.7774 49.0557 89.9302C48.6927 90.1289 48.2537 89.9623 48.0753 89.5583C47.8969 89.1542 48.0464 88.6657 48.4095 88.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M111 127.2C111.663 127.2 112.2 126.663 112.2 126C112.2 125.337 111.663 124.8 111 124.8C110.337 124.8 109.8 125.337 109.8 126C109.8 126.663 110.337 127.2 111 127.2ZM111 129C112.657 129 114 127.657 114 126C114 124.343 112.657 123 111 123C109.343 123 108 124.343 108 126C108 127.657 109.343 129 111 129Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M207 19.2C207.663 19.2 208.2 18.6627 208.2 18C208.2 17.3373 207.663 16.8 207 16.8C206.337 16.8 205.8 17.3373 205.8 18C205.8 18.6627 206.337 19.2 207 19.2ZM207 21C208.657 21 210 19.6569 210 18C210 16.3431 208.657 15 207 15C205.343 15 204 16.3431 204 18C204 19.6569 205.343 21 207 21Z" fill="#DEE3EA"/>
        <path d="M221.409 55.467C221.605 55.3598 221.852 55.2176 222.02 55.068C223.616 53.6466 225.727 53.6414 227.329 55.068C228.386 56.009 229.752 56.009 230.809 55.068C231.593 54.3698 232.511 54.0007 233.463 54.0007C234.416 54.0007 235.334 54.3698 236.118 55.068C237.175 56.009 238.541 56.009 239.598 55.068C241.194 53.6467 243.305 53.6414 244.907 55.068C245.06 55.2038 245.278 55.3444 245.576 55.498C245.943 55.6875 246.102 56.1722 245.932 56.5806C245.762 56.989 245.326 57.1665 244.959 56.977C244.548 56.7648 244.241 56.5628 243.992 56.3411C242.935 55.4001 241.569 55.4 240.513 56.3411C238.916 57.7625 236.805 57.7677 235.203 56.3411C234.146 55.4001 232.781 55.4 231.724 56.3411C230.127 57.7625 228.016 57.7677 226.414 56.3411C225.357 55.4001 223.992 55.4 222.935 56.3411C222.657 56.588 222.335 56.7774 222.056 56.9302C221.693 57.1289 221.254 56.9623 221.075 56.5583C220.897 56.1542 221.046 55.6657 221.409 55.467Z" fill="#DEE3EA"/>
        <path d="M230.409 61.467C230.605 61.3598 230.852 61.2176 231.02 61.068C232.616 59.6466 234.727 59.6414 236.329 61.068C237.386 62.009 238.752 62.009 239.809 61.068C240.593 60.3698 241.511 60.0007 242.463 60.0007C243.416 60.0007 244.334 60.3698 245.118 61.068C246.175 62.009 247.541 62.009 248.598 61.068C250.194 59.6467 252.305 59.6414 253.907 61.068C254.06 61.2038 254.278 61.3444 254.576 61.498C254.943 61.6875 255.102 62.1722 254.932 62.5806C254.762 62.989 254.326 63.1665 253.959 62.977C253.548 62.7648 253.241 62.5628 252.992 62.3411C251.935 61.4001 250.569 61.4 249.513 62.3411C247.916 63.7625 245.805 63.7677 244.203 62.3411C243.146 61.4001 241.781 61.4 240.724 62.3411C239.127 63.7625 237.016 63.7677 235.414 62.3411C234.357 61.4001 232.992 61.4 231.935 62.3411C231.657 62.588 231.335 62.7774 231.056 62.9302C230.693 63.1289 230.254 62.9623 230.075 62.5583C229.897 62.1542 230.046 61.6657 230.409 61.467Z" fill="#DEE3EA"/>
        <path d="M260.409 99.467C260.605 99.3598 260.852 99.2176 261.02 99.068C262.616 97.6466 264.727 97.6414 266.329 99.068C267.386 100.009 268.752 100.009 269.809 99.068C270.593 98.3698 271.511 98.0007 272.463 98.0007C273.416 98.0007 274.334 98.3698 275.118 99.068C276.175 100.009 277.541 100.009 278.598 99.068C280.194 97.6467 282.305 97.6414 283.907 99.068C284.06 99.2038 284.278 99.3444 284.576 99.498C284.943 99.6875 285.102 100.172 284.932 100.581C284.762 100.989 284.326 101.167 283.959 100.977C283.548 100.765 283.241 100.563 282.992 100.341C281.935 99.4001 280.569 99.4 279.513 100.341C277.916 101.762 275.805 101.768 274.203 100.341C273.146 99.4001 271.781 99.4 270.724 100.341C269.127 101.762 267.016 101.768 265.414 100.341C264.357 99.4001 262.992 99.4 261.935 100.341C261.657 100.588 261.335 100.777 261.056 100.93C260.693 101.129 260.254 100.962 260.075 100.558C259.897 100.154 260.046 99.6657 260.409 99.467Z" fill="#DEE3EA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M73 46.2C74.7673 46.2 76.2 44.7673 76.2 43C76.2 41.2327 74.7673 39.8 73 39.8C71.2327 39.8 69.8 41.2327 69.8 43C69.8 44.7673 71.2327 46.2 73 46.2ZM73 48C75.7614 48 78 45.7614 78 43C78 40.2386 75.7614 38 73 38C70.2386 38 68 40.2386 68 43C68 45.7614 70.2386 48 73 48Z" fill="#DEE3EA"/>
      </svg>
      <p class="fz18 fw500 text-dark1">No results matched your filter</p>
    </div>
    <div class="main position-relative" :class="{'view-ordering-created-date' : getSortTaskBy.indexOf('created_at') >= 0 || getSortTaskBy.indexOf('execute_time') >= 0}" v-if="getTasksList.length !==0" :key="keyRender">
      <app-loader v-if="getLoadRequestFilterTask" class="loader-task-list"></app-loader>
      <div v-for="(task, i) in getTasksList" :key="i" :class="{'task-with-checklist': task.checklists.length}">
        <p class="fz14 fw500 text-date-created-ordering" v-if="getShowTextDateForOrderingCreatedDate(i) && (getSortTaskBy.indexOf('created_at') >= 0 || getSortTaskBy.indexOf('execute_time') >= 0)">{{getTextDateForCreatedOrdering(getSortTaskBy.indexOf('created_at') >= 0 ? task.created_at : task.execute_time)}}</p>
        <div class="tasks-checkbox"
             :class="{'oneTask': getTasksList.length === 1 || (getShowTextDateForOrderingCreatedDate(i) && getShowTextDateForOrderingCreatedDateBorder(i) && (getSortTaskBy.indexOf('created_at') >= 0 || getSortTaskBy.indexOf('execute_time') >= 0)), 'bottom-border-task' : getShowTextDateForOrderingCreatedDateBorder(i) && !getShowTextDateForOrderingCreatedDate(i) && (getSortTaskBy.indexOf('created_at') >= 0 || getSortTaskBy.indexOf('execute_time') >= 0), 'top-border-task' : getShowTextDateForOrderingCreatedDate(i) && !getShowTextDateForOrderingCreatedDateBorder(i) && (getSortTaskBy.indexOf('created_at') >= 0 || getSortTaskBy.indexOf('execute_time') >= 0)}"
        >
          <router-link v-if="task.type === 'MANUAL' || task.type === 'MANUAL_WITH_CONTRACT'" :to="'/task/' + task.id" class="click-zone"></router-link>
          <router-link v-if="task.type === 'SHIP_SEARCH_WITH_CARGO' || task.type === 'CARGO_SEARCH_WITH_SHIP' || task.type === 'CARGO_SEARCH_WITHOUT_SHIP' || task.type === 'SHIP_SEARCH_WITHOUT_CARGO'" :to="{name: 'Vessel Workspace', params: {id: task.id}}" class="click-zone"></router-link>
          <div class="part1">
            <b-form-checkbox
                :value="task"
                v-model="selected"
            >
            </b-form-checkbox>
<!--            v-on:click="openChecklist(task)"-->
            <button v-if="task.checklists.length" class="open-checklist d-flex align-items-center justify-center mr4" v-on:click="toggleChecklistIds(task.id)" :class="{'rotate180' : checklistIdsOpened.indexOf(task.id) >= 0}">
              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.76496 5.19922C2.49769 5.19922 2.36384 5.52236 2.55283 5.71135L5.82857 8.98709C5.94573 9.10424 6.13568 9.10424 6.25283 8.98708L9.52856 5.71135C9.71755 5.52236 9.5837 5.19922 9.31643 5.19922H2.76496Z" fill="#2C2C2C"/>
              </svg>
            </button>
            <p class="name fz13 text-dark1">
              {{task.checklists.length ? (task.vessel ? task.vessel.name : '') :task.name}}
            </p>
            <p class="amount-checkbox text-white-always fz11 fw500" v-if="task.checklists.length">{{getCompletedCheckboxes(task.checklists)}}</p>
            <span class="svg-type" v-if="task.type === 'SHIP_SEARCH_WITHOUT_CARGO' || task.type === 'SHIP_SEARCH_WITH_CARGO'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00809 2.8634C7.00809 3.12504 6.79847 3.33714 6.53989 3.33714L5.24016 3.33714C4.98158 3.33714 4.77197 3.12504 4.77197 2.8634L4.72919 0.473741C4.72919 0.212101 4.93881 4.03386e-07 5.19739 4.03386e-07L6.49712 0C6.7557 0 6.96532 0.212101 6.96532 0.473741L7.00809 2.8634Z" fill="#094172"/>
            <path d="M7.99109 9.90658C6.68551 10.9507 5.01606 10.9507 3.71046 9.90658C3.05765 10.4287 2.3139 10.7157 1.57015 10.7157H1.13465C0.784149 10.7157 0.500007 11.0032 0.5 11.3578C0.499993 11.7125 0.78414 12 1.13465 12H1.57015C2.30588 12 3.03626 11.7893 3.71046 11.3924C5.05885 12.1832 6.64269 12.1832 7.99109 11.3924C8.66529 11.7863 9.39567 12 10.1314 12H10.5669C10.9174 12 11.2016 11.7125 11.2016 11.3578C11.2016 11.0032 10.9174 10.7157 10.5669 10.7157H10.1314C9.38765 10.7157 8.6439 10.4287 7.99109 9.90658Z" fill="#094172"/>
            <path d="M11.1273 5.37981C11.4619 5.5185 11.627 5.90118 11.5 6.24376L10.259 9.30907H10.1403C9.39655 9.30907 8.6528 9.02208 8 8.5C6.69442 9.54415 5.02497 9.54415 3.71937 8.5C3.11859 8.98047 2.44078 9.26184 1.75683 9.30363L0.585554 6.24376C0.458553 5.90118 0.623638 5.5185 0.958256 5.37981L2.54934 4.96678L2.54933 2.35307C2.54933 2.09143 2.75895 1.87933 3.01753 1.87933L5.24016 1.87934H6.8454L8.76963 1.87887C9.0282 1.87887 9.23782 2.09097 9.23782 2.35261V4.96632L11.1273 5.37981Z" fill="#094172"/>
            </svg>
          </span>
            <span class="svg-type" v-if="task.type === 'CARGO_SEARCH_WITHOUT_SHIP' || task.type === 'CARGO_SEARCH_WITH_SHIP'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.40694 5.86059L0.162656 3.59085C0.0854297 3.55832 0 3.61504 0 3.69885V9.46353C0 9.61686 0.0933516 9.75477 0.235734 9.8117L5.31795 11.9031C5.39492 11.9339 5.47866 11.8772 5.47866 11.7943V5.96859C5.47866 5.92143 5.45039 5.87889 5.40694 5.86059Z" fill="#094172"/>
            <path d="M6.04503 4.76647L11.2555 2.57258C11.3492 2.53316 11.3517 2.40135 11.2596 2.35839L6.15814 0.124041C6.05762 0.0771426 5.94151 0.0771426 5.84099 0.124041L0.739511 2.35839C0.647425 2.40135 0.649933 2.53316 0.743589 2.57258L5.9541 4.76647C5.98316 4.77873 6.01597 4.77873 6.04503 4.76647Z" fill="#094172"/>
            <path d="M6.52124 5.94417V11.7945C6.52124 11.8774 6.60498 11.9341 6.68195 11.9033L11.7642 9.81186C11.9065 9.75491 11.9999 9.61702 11.9999 9.4637V3.67444C11.9999 3.59063 11.9145 3.53391 11.8372 3.56644L6.59296 5.83617C6.54951 5.85448 6.52124 5.89702 6.52124 5.94417Z" fill="#094172"/>
            </svg>
          </span>

            <div class="check_list_block fz11 fw500 text-white" v-if="getTextForCheckList(task.check_list).length !== 0">
              {{ getTextForCheckList(task.check_list) }}
            </div>

            <div class="block-with-option-tag">
              <div class="wrap-block-tag" v-for="(t,j) in task.tags" :key="j">
                <input class="fz13 editTagName" type="text" v-model="nameEditTags" @focusout="editModeTagNameFalse" v-if="t.editStatus" v-on:keyup.enter="renameTagAxios(t)">
                <div v-if="j<2" class="tag-wrap">
                  <div class="button-tag" v-if="!t.editStatus">
                    <p class="fz13 text-dark1">{{ t.name }}</p>
                    <!--          <tag-options class="tag-options"></tag-options>-->

                    <div class="tag-options" v-if="getPermissionsForUserCanOffTags">
                      <b-dropdown right :ref="'dropdonw1' + i + '' + j">
                        <template #button-content>
                          <button class="button-drop-tags fz13 fw500">
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.17 7.58012C9.57353 7.58012 9.09 7.09659 9.09 6.50012C9.09 5.90365 9.57353 5.42012 10.17 5.42012C10.7665 5.42012 11.25 5.90365 11.25 6.50012C11.25 7.09659 10.7665 7.58012 10.17 7.58012Z" fill="#2C2C2C"/>
                              <ellipse cx="5.99959" cy="6.50017" rx="1.08" ry="1.08" transform="rotate(90 5.99959 6.50017)" fill="#2C2C2C"/>
                              <path d="M1.83064 7.58017C1.23418 7.58017 0.750644 7.09663 0.750644 6.50017C0.750644 5.9037 1.23418 5.42017 1.83064 5.42017C2.42711 5.42017 2.91064 5.9037 2.91064 6.50017C2.91064 7.09663 2.42711 7.58017 1.83064 7.58017Z" fill="#2C2C2C"/>
                            </svg>

                          </button>
                        </template>
                        <div class="content-tags">
                          <button class="fz13 text-dark1" v-on:click="editModeTagName(('dropdonw1' + i + '' + j), i, j)">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M17.9815 6.02666C17.3235 5.36566 16.4509 5 15.5175 5C14.5889 5 13.7116 5.36566 13.0536 6.02666L5.84634 13.276C5.74367 13.3791 5.68301 13.5104 5.65967 13.651L5.00635 18.2499C4.97835 18.4515 5.04368 18.6578 5.18835 18.8031C5.30968 18.9297 5.48234 19 5.65501 19C5.68301 19 5.70634 19 5.73434 18.9953L10.387 18.414C10.5316 18.3953 10.667 18.3296 10.7696 18.2265L17.9815 10.9725C19.3395 9.60357 19.3395 7.39086 17.9815 6.02666ZM10.0043 17.1342L6.42033 17.5795L6.92433 14.0495L12.8016 8.13624L15.8769 11.2256L10.0043 17.1342ZM17.0622 10.0489L16.8055 10.3068L13.7302 7.2174L13.9869 6.95957C14.3929 6.53765 14.9389 6.31263 15.5222 6.31263C16.1009 6.31263 16.6515 6.53765 17.0622 6.95488C17.4728 7.37211 17.7015 7.91591 17.7015 8.5019C17.7015 9.08321 17.4775 9.63639 17.0622 10.0489Z" fill="#2C2C2C"/>
                            </svg>
                            Rename
                          </button>
                          <button class="fz13 text-dark1" v-on:click="deleteTag(('dropdonw1' + i + '' + j), t.id)" v-if="getPermissionsForUserCanDeleteTags">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
                            </svg>
                            Delete
                          </button>
                        </div>
                      </b-dropdown>
                    </div>
                    <button class="button-drop-tags1 fz13 fw500" v-on:click="unselectTag(t.id, i)" v-if="getPermissionsForUserCanOffTags">
                      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.05373 4.42533C2.81022 4.18421 2.81022 3.79329 3.05373 3.55217C3.29724 3.31106 3.69206 3.31106 3.93557 3.55217L8.94574 8.47332C9.18925 8.71444 9.18925 9.10536 8.94574 9.34647C8.70223 9.58759 8.30741 9.58759 8.0639 9.34647L3.05373 4.42533Z" fill="#319FEF"/>
                        <path d="M8.06398 3.65356C8.3075 3.41245 8.70231 3.41245 8.94582 3.65356C9.18933 3.89468 9.18933 4.2856 8.94582 4.52672L3.93565 9.44786C3.69214 9.68898 3.29732 9.68898 3.05381 9.44786C2.8103 9.20675 2.8103 8.81582 3.05381 8.57471L8.06398 3.65356Z" fill="#319FEF"/>
                      </svg>
                    </button>

                  </div>
                </div>
              </div>
              <div class="numberMore fz11 text-dark1" v-if="task.tags.length>2">
                +{{task.tags.length - 2}}
              </div>
              <button class="button-tags-filter" v-on:click="openChangeSelectTag(('tagSelect' + i), task.tags, i)" v-if="getPermissionsForUserCanOffTags && $route.path.indexOf('archive') === -1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.96699 11.837L4.967 11.837L4.96355 11.8336C4.80041 11.6727 4.7 11.4504 4.7 11.2V5.60001C4.7 5.10259 5.10259 4.7 5.60001 4.7H11.2C11.4444 4.7 11.6702 4.79917 11.839 4.96494L19.033 12.159C19.2001 12.326 19.3 12.5504 19.3 12.8C19.3 13.0444 19.2009 13.2701 19.0351 13.4389L13.437 19.037C13.2756 19.1984 13.0478 19.3 12.8 19.3C12.5541 19.3 12.3298 19.1998 12.167 19.037L4.96699 11.837Z" stroke="#BDCBD6" stroke-width="1.4"/>
                  <path d="M7 8.2C7 8.86401 7.53599 9.4 8.2 9.4C8.86397 9.4 9.4 8.86401 9.4 8.2C9.4 7.53599 8.86401 7 8.2 7C7.53599 7 7 7.53599 7 8.2Z" fill="#BDCBD6"/>
                </svg>
              </button>
              <task-list-tag-select  @deleteUsageTag="deleteTagWhichUsed" @changeTagsForTaskEmit="changeTagsForTask" class="tag-select-dropdown" :ref="'tagSelect' + i"></task-list-tag-select>
            </div>
          </div>
          <div class="part2">
            <p class="fz13 status" :class="{'status-open': task.status.toUpperCase() === 'OPEN', 'status-done': task.status.toUpperCase() === 'DONE', 'status-inProgress': task.status.toUpperCase() === 'IN PROGRESS'}">{{task.status}}</p>
          </div>
          <div class="part3">
          <span class="priority">
          <span v-html="task.priority.svg"></span>
            <p class="fz13 text-dark1">{{task.priority.name}}</p>
          </span>
            <button class="priority-button buttonDashedBlue fz13" v-on:click="openChangePriorityDropdown(('changePriorityDropdown' + i)  ,task.priority, i)">
              Set priority
            </button>
            <task-list-change-priority class="changePriority" :ref="'changePriorityDropdown' + i" @changePriorityForTaskEmit="changePriorityForTask"></task-list-change-priority>
          </div>
          <div class="part4">
<!--            <button class="one-user one-user-button" v-if="task.executors.length === 1" v-on:click="openUserSelectForTask('changeAssignee'+i, task.executors)">-->
<!--              <img v-if="task.executors[0].avatar !== null" :src="task.executors[0].avatar" class="img-profile" alt="">-->
<!--              <svg v-if="task.executors[0].avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <circle cx="80" cy="80" r="80" fill="white"/>-->
<!--                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>-->
<!--              </svg>-->
<!--              <button class="fz13 text-dark1">{{task.executors[0].first_name}} {{task.executors[0].last_name}}</button>-->
<!--            </button>-->
<!--            <button class="more-user more-user-button" v-if="task.executors.length > 1" v-on:click="openUserSelectForTask('changeAssignee'+i, task.executors)">-->
<!--              <div class="user-image-wrap" v-for="(u, m) in task.executors" :key="m" :style="'z-index:'+ (task.executors.length - m)">-->
<!--                <div class="user-image" v-if="m<6">-->
<!--                  <img v-if="u.avatar !== null" :src="u.avatar" class="img-profile" alt="">-->
<!--                  <svg v-if="u.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <circle cx="80" cy="80" r="80" fill="white"/>-->
<!--                    <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>-->
<!--                  </svg>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="moreUser fz11 text-blue-dark" v-if="task.executors.length > 6">-->
<!--                +{{task.executors.length - 6}}-->
<!--              </div>-->
<!--            </button>-->
<!--            <button class="no-assigned" v-if="task.executors.length === 0" v-on:click="openUserSelectForTask('changeAssignee'+i, [])">-->
<!--              <svg width="24" class="svg-da" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M12.212 10.8719C13.9294 10.8719 15.3223 9.47945 15.3223 7.7612C15.3223 6.04334 13.9302 4.65048 12.212 4.65088C10.4949 4.65088 9.10206 6.04374 9.10206 7.7612C9.10206 9.47945 10.4949 10.8719 12.212 10.8719Z" stroke="#4B4B4B" stroke-width="1.4"/>-->
<!--                <path d="M6.41675 17.2501C6.41675 18.0457 7.06159 18.6906 7.85722 18.6906H16.9136C17.7088 18.6906 18.3537 18.0457 18.3537 17.2501V16.4378C18.3537 14.6961 16.9425 13.2981 15.2017 13.2981H9.57984C7.83937 13.2981 6.41675 14.6969 6.41675 16.4378V17.2501Z" stroke="#4B4B4B" stroke-width="1.4"/>-->
<!--              </svg>-->
<!--              <p class="fz13 text-dark1">No assignee</p>-->
<!--            </button>-->
            <async-select-employees :permissionAssigned="(task.type === 'CARGO_SEARCH_WITH_SHIP' || task.type === 'SHIP_SEARCH_WITH_CARGO') ? 'ship_task' : ''" @close="(array, bool) => {changeUserForTask(array, bool, i)}" class="change-assignee" :bgc-white="true" :showNoEmployees="true" :is-disabled="!getPermissionsForUserCanAssignTask.status" v-model="task.executors" :is-multiple="true"></async-select-employees>
<!--            <task-list-change-assignee :showNoAssignee="true" v-if="getPermissionsForUserCanAssignTask.status" @changeEmployeeForTaskEmit="changeUserForTaskAddArray" @changeEmployeeForTaskEmitIndex="changeUserForTask(i)" class="change-assignee" :assignee="task.executors" :ref="'changeAssignee' +i"></task-list-change-assignee>-->
          </div>
          <div class="part5">
            <select-due-date
                :disabled="$route.path.indexOf('archive') >= 0"
                class="select-due-date"
                :text-no-select="$route.path.indexOf('archive') === -1 ? 'Set due date' : ''"
                :button-show-after-hover="true"
                :due_date_status="task.due_date_status"
                :active-due-date="task.execute_time"
                :active-custom-days="getActiveCustomDays(task.days_for_repeat)"
                :index="i"
                @close="changeDateForTask($event, task)"
                :showSelectTime="true"
            ></select-due-date>
            <!--          <button v-if="task.execute_time!==null" :class="{'expired': checkDate(task.execute_time), 'buttonDashedBlue': !checkDate(task.execute_time)}" class="fz13" v-on:click="openDatePickerChangeDateForTask(('changeDate' + i), task.execute_time, i)">{{formatDate(task.execute_time)}}</button>-->
            <!--          <button v-if="task.execute_time===null" class="fz13 setDateIfNoDate buttonDashedBlue" v-on:click="openDatePickerChangeDateForTask(('changeDate' + i), '', i)">Set due date</button>-->
            <!--          <task-list-change-date @changeDateForTaskEmit="changeDateForTask" :ref="'changeDate' + i" class="changeDateTask"></task-list-change-date>-->
          </div>
          <div class="part6 position-relative">
            <p class="fz13 text-dark1">{{task.done_date | formatDateAndTime2}}</p>
            <task-list-option-button v-if="$route.path.indexOf('archive') === -1" class="button-option-task" @clickDelete="deleteTask(task)" @clickDuplicate="task.type.indexOf('MANUAL') >= 0 ? duplicateTask(task) : task.type.indexOf('SHIP') >= 0 ? duplicateCargoOrVesselTask(task) : ''"></task-list-option-button>
            <button class="button-option-task" v-else v-on:click="unArchiveTask(task.id)">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.01736 9.78525L6.71267 7.00237L9.01736 4.21949C9.28441 3.91345 9.25326 3.44872 8.94722 3.18143C8.64119 2.91438 8.1767 2.94578 7.90916 3.25157L5.18172 6.51852C4.93943 6.79562 4.93943 7.20934 5.18172 7.48644L7.9092 10.7534C8.05485 10.9197 8.25865 11.005 8.46365 11.005C8.63532 11.005 8.80769 10.9452 8.94722 10.8233C9.25329 10.556 9.28465 10.0913 9.01736 9.78525Z" fill="#9AA3B0"/>
                <path d="M13.6538 6.2666H5.73568C5.32934 6.2666 5 6.59591 5 7.00228C5 7.40862 5.32934 7.73796 5.73568 7.73796H13.6538C15.7904 7.73796 17.5286 9.60133 17.5286 11.9795C17.5286 14.3577 15.7904 16.4872 13.6538 16.4872H6.71661C6.31027 16.4872 5.98093 16.8165 5.98093 17.2229C5.98093 17.6292 6.31027 17.9585 6.71661 17.9585H13.6538C16.6014 17.9585 19 15.213 19 11.9795C19 8.74602 16.6017 6.2666 13.6538 6.2666Z" fill="#9AA3B0"/>
              </svg>
            </button>
          </div>
        </div>
        <checklist-task-list :doneStatus="doneStatus" :ID="task.id" v-model="task.checklists" v-if="checklistIdsOpened.indexOf(task.id) >= 0"></checklist-task-list>
      </div>
    </div>
<!--    <button class="show-more fz13 fw500" v-if="getTasksList.length > 19 && getTasksList.length < getCountTask" v-on:click="showMore()">-->
<!--      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M18.5008 11.25H18.5001C18.4016 11.2501 18.3041 11.2696 18.2131 11.3073C18.1221 11.3451 18.0395 11.4004 17.9699 11.4701C17.9003 11.5398 17.8452 11.6226 17.8075 11.7136C17.7699 11.8046 17.7506 11.9022 17.7507 12.0007C17.7498 13.0521 17.4606 14.0832 16.9147 14.9819C16.3688 15.8805 15.587 16.6123 14.6543 17.0978C13.7216 17.5832 12.6736 17.8037 11.6244 17.7353C10.5751 17.6669 9.56473 17.3121 8.70298 16.7097C6.1061 14.8913 5.47269 11.2992 7.29107 8.70225C9.10944 6.10534 12.7016 5.47188 15.2985 7.29031C15.6914 7.56469 16.0484 7.88708 16.3614 8.25H14.2507C14.0518 8.25 13.861 8.32902 13.7204 8.46967C13.5797 8.61032 13.5007 8.80109 13.5007 9C13.5007 9.19891 13.5797 9.38968 13.7204 9.53033C13.861 9.67098 14.0518 9.75 14.2507 9.75H17.7507C17.9496 9.75 18.1404 9.67098 18.2811 9.53033C18.4217 9.38968 18.5007 9.19891 18.5007 9V5.5C18.5007 5.30109 18.4217 5.11032 18.2811 4.96967C18.1404 4.82902 17.9496 4.75 17.7507 4.75C17.5518 4.75 17.361 4.82902 17.2204 4.96967C17.0797 5.11032 17.0007 5.30109 17.0007 5.5V6.74897C16.7375 6.49925 16.4561 6.26945 16.1589 6.06147C15.379 5.51542 14.4992 5.12832 13.5698 4.92227C12.6403 4.71622 11.6794 4.69525 10.7418 4.86057C9.8043 5.02589 8.90849 5.37426 8.10557 5.88578C7.30265 6.3973 6.60834 7.06196 6.06229 7.84181C4.95949 9.41679 4.52751 11.3653 4.86139 13.2588C5.19527 15.1523 6.26766 16.8356 7.84263 17.9384C9.05524 18.7925 10.5021 19.2511 11.9854 19.2513C12.4126 19.2511 12.8389 19.2137 13.2596 19.1393C14.9382 18.8432 16.4588 17.9652 17.5544 16.6595C18.65 15.3538 19.2506 13.7038 19.2507 11.9993C19.2506 11.8006 19.1715 11.61 19.0308 11.4694C18.8902 11.3289 18.6996 11.25 18.5008 11.25Z" fill="#094172"/>-->
<!--      </svg>-->
<!--      Show more-->
<!--    </button>-->
    <pagination-panel-default class="show-more" @load-more="showMore()" :if-active="getLoadRequestFilterTask" v-if="getTasksList.length > 19 && getTasksList.length < getCountTask"></pagination-panel-default>
<!--    {{selected}}-->
<!--    <br>-->
<!--    <b-dropdown id="dropdown-1" text="Dropdown Button 1" class="m-md-2">-->
<!--      <b-dropdown-item>First Action 1</b-dropdown-item>-->
<!--      <b-dropdown-item>Second Action 1</b-dropdown-item>-->
<!--      <b-dropdown id="dropdown-2" text="Dropdown Button 2" class="m-md-2">-->
<!--        <b-dropdown-item>First Action 2</b-dropdown-item>-->
<!--        <b-dropdown-item>Second Action 2</b-dropdown-item>-->
<!--      </b-dropdown>-->
<!--    </b-dropdown>-->
<!--    <br>-->
<!--    {{getTasksList}}-->
<!--    <br>-->
<!--    {{getTasksList}} {{getTasksList.length}}--><!---->
<!--    <br>-->
<!--    <br>-->
<!--    <br>-->
<!--    {{getAllTasks}} {{getAllTasks.length}}-->
<!--    {{getPermissionsForUserCanArchiveTask}}-->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import TaskListOptionButton from "@/components/tasks/tasks-list/task-list-option-button";
import TaskListChangePriority from "@/components/tasks/tasks-list/task-list-change-priority";
import TaskListTagSelect from "@/components/tasks/tasks-list/task-list-tag-select";
import ModalDeleteTag from "@/components/tasks/modalDeleteTag";
import SelectDueDate from "@/components/tasks/selectDueDate/selectDueDate";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import moment from 'moment';
import AppLoader from "@/components/loader";
import AsyncSelectEmployees from "@/components/tasks/async-select-employees";
import ChecklistTaskList from "@/components/checklist/checklist-task-list";


export default {
  components: {
    ChecklistTaskList,
    AsyncSelectEmployees,
    AppLoader,
    PaginationPanelDefault,
    SelectDueDate,
    ModalDeleteTag,
    TaskListTagSelect,
    TaskListChangePriority, TaskListOptionButton},
  computed: mapGetters(["getLoadRequestFilterTask", "getLoadRequestFilterTask", "getCountTask", "getIdTaskDuplicateWithoutVessel", "getIdTaskDuplicateWithVessel", "getTaskInfoConvert", "getTaskInfo", "getVesselTaskInfo", "getIdTaskDuplicateCoupe", "getIdTaskDuplicateVessel", "getIdTaskDuplicate", "getPermissionsForUserCanAssignTask", "getPermissionsForUserCanOffTags","getPermissionsForUserCanDeleteTags", "getPermissionsForUserCanArchiveTask", "getFilterContActivity", "getLimit", "getSortTaskBy", "getDepartments", "getAllTasks", "getTasksList", "getEmployeesSortByDepartments", "getAllTag", "getEmployees"]),
  name: "tasks-list",
  data() {
    return {
      status: 'notAll',
      selected: [],
      empForAxios: [],
      keyRender: -1000,
      nameEditTags: '',
      countRequests: 0,
      checklistIdsOpened: [],
    }
  },
  watch: {
    countRequests: function () {
      if(this.countRequests !== 0) {
        this.showLoad()
      } else {
        this.hideLoad();
      }
    }
  },
  props: {
    doneStatus: Boolean,
  },
  methods: {
    ...mapActions([ "getAxiosDepartmentsEmployeesPage", "axiosChangeManualTask", "getAxiosInfoForTask", "getAxiosInfoForVesselTask", "axiosGetTasksForFilter","axiosGetDepartments", "axiosRenameTag","axiosDeleteTag","axiosGetAllTasks", "axiosGetPriorityTask", "getAxiosAllTag", "axiosGetEmployees", "axiosDeleteTask", "axiosGetNoArchivedTasks", "axiosChangeTask"]),
    ...mapMutations(["deleteTaskById", "showLoad", "hideLoad", "modifyEmployeeAddDepartment", "setIdTaskDuplicate", "setIdTaskDuplicateVessel","setIdTaskDuplicateCoupe", "setIdTaskDuplicateWithoutVessel", "setIdTaskDuplicateWithVessel", "hideLoad", "showLoad", "conversionForTaskInfo", "addTip", "setLimit", "sortTaskByNameReverse", "sortTaskByStatusReverse", "sortTaskByStatus","sortTaskByPriority", "sortTaskByPriorityReverse", "sortTasksByDepartmentsReverse", "sortTasksByDepartments", "sortTaskByDeadlineReverse", "sortTaskByDeadline","sortTaskByName", "conversionForTasks"]),
    getCompletedCheckboxes(list) {
      let items = [];
      list.forEach(item=> {
        items = items.concat(item.items)
      })
      return `${items.filter(a=> a.is_completed).length}/${items.length}`
    },
    toggleChecklistIds(id) {
      if(this.checklistIdsOpened.indexOf(id) >= 0) {
        this.checklistIdsOpened = this.checklistIdsOpened.filter(a=> a !== id)
      } else {
        this.checklistIdsOpened.push(id)
      }
    },
    unArchiveTask(id) {
      this.axiosChangeManualTask({id: id, data: {is_archived: false}})
      this.deleteTaskById(id)
    },
    getActiveCustomDays(array) {
      if(Array.isArray(array) === true) {
        let dateCurrent = new Date();
        return  array.map(a=> {
          return new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), a)
        })
      }
      return [];
    },
    getTextForCheckList(array) {
      let check_list = [];
      if(Array.isArray(array) === true) {
        array.map(a=> {
          if(Number.isInteger(a) !== true) {
            a.check_list.map(b=> {
              check_list.push(b);
            })
          }
        })
        let done = [];
        done = check_list.filter(a=> a.is_done !== false)
        if(check_list.length !== 0) {
          return ""+done.length+"/"+check_list.length
        } else {
          return ""
        }
      }
      return ""
    },
    getShowTextDateForOrderingCreatedDate(index) {
      if(this.getTasksList.length !== 1) {
        if(index === 0) {
          return true
        }
        if(index === this.getTasksList.length -1) {
          index--
        }
        // let indexDec = index++;
        let indexInc = index - 1
        if(indexInc < 0) {
          indexInc = 0;
        }
        // if(indexDec > this.getTasksList.length - 1) {
        //   indexDec = this.getTasksList.length - 1;
        // }
        if(this.getSortTaskBy.indexOf('created_at') >= 0) {
          return moment(this.getTasksList[index].created_at).format('DD.MM.YYYY') !== moment(this.getTasksList[indexInc].created_at).format('DD.MM.YYYY')
        } else {
          return moment(this.getTasksList[index].execute_time).format('DD.MM.YYYY') !== moment(this.getTasksList[indexInc].execute_time).format('DD.MM.YYYY')
        }
        // return index + " " + indexInc + " " + moment(this.getTasksList[index].created_at).format('DD.MM.YYYY') + '  ' +  moment(this.getTasksList[indexInc].created_at).format('DD.MM.YYYY') + "  " + (moment(this.getTasksList[index].created_at).format('DD.MM.YYYY') !== moment(this.getTasksList[indexInc].created_at).format('DD.MM.YYYY'))
        // return (moment(this.getTasksList[index].created_at).format('DD.MM.YYYY') !== moment(this.getTasksList[indexInc].created_at).format('DD.MM.YYYY')) ? true : false
      }
      return true
    },
    getShowTextDateForOrderingCreatedDateBorder(index) {
      if(this.getTasksList.length !== 1) {
        if(index === this.getTasksList.length - 1) {
          index--;
        }
        if(this.getSortTaskBy.indexOf('created_at') >= 0) {
          return (moment(this.getTasksList[index].created_at).format('DD.MM.YYYY') !== moment(this.getTasksList[index + 1].created_at).format('DD.MM.YYYY')) ? true : false
        } else {
          return (moment(this.getTasksList[index].execute_time).format('DD.MM.YYYY') !== moment(this.getTasksList[index + 1].execute_time).format('DD.MM.YYYY')) ? true : false
        }
      }
      return false
    },
    getTextDateForCreatedOrdering(date) {
      let text = "";
      if(date === null || date === undefined) {
        return 'No Date'
      }
      if(moment(date).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) {
        text += 'Today '
      }
      if(moment(date).format('DD.MM.YYYY') === moment().add(-1, 'day').format('DD.MM.YYYY')) {
        text += 'Yesterday '
      }
      text+=moment(date).format('DD.MM.YYYY')
      return text
    },
    duplicateTask(data) {
      this.$emit("duplicateTask", data);
    },
    duplicateCargoOrVesselTask(task) {
      this.showLoad()
      this.getAxiosInfoForVesselTask(task.id).then(()=> {
        if(task.type === 'CARGO_SEARCH_WITH_SHIP') {
          let data = this.getVesselTaskInfo;
          let vessel = data.task.vessel;
          data.task = JSON.parse(JSON.stringify(task));
          data.task.vessel = vessel
          this.duplicateTaskWithVessel(data);
        } else {
          let data = this.getVesselTaskInfo;
          let vessel = data.task.vessel;
          data.task = JSON.parse(JSON.stringify(task));
          data.task.vessel = vessel
          this.duplicateTaskVessel(data);
        }
        this.hideLoad();

      }).catch(()=> {
        this.hideLoad();
      })
    },
    duplicateTaskVessel(data) {
      this.$emit("duplicateTaskVessel", data);
    },
    duplicateTaskCoupe(data) {
      this.$emit("duplicateTaskCoupe", data);
    },
    duplicateTaskWithVessel(data) {
      this.$emit("duplicateTaskWithVessel", data);
    },
    duplicateTaskWithoutVessel(data) {
      this.$emit("duplicateTaskWithoutVessel", data);
    },
    deleteTagWhichUsed(tag) {
      this.$refs.modalDeleteTag.openModal(tag);
    },
    showMore() {
      this.setLimit(this.getLimit + 20);
      this.renderNew();
    },
    selectAll() {
      // alert(1);
    },
    renameTagAxios(tag) {
      this.axiosRenameTag({id: tag.id, name: this.nameEditTags}).then(()=> {
        this.renderNew();
      });
      this.editModeTagNameFalse();
    },
    editModeTagName(refName, indexTask, indexTag) {
      for(let i=0; i<this.getTasksList.length; i++) {
        for(let j=0; j<this.getTasksList[i].tags.length; j++) {
          this.getTasksList[i].tags[j].editStatus = false;
        }
      }
      this.getTasksList[indexTask].tags[indexTag].editStatus = true;
      this.nameEditTags = this.getTasksList[indexTask].tags[indexTag].name;
      this.$refs[refName][0].hide(false);
      this.keyRender++;
    },
    editModeTagNameFalse() {
      for(let i=0; i<this.getTasksList.length; i++) {
        for(let j=0; j<this.getTasksList[i].tags.length; j++) {
          this.getTasksList[i].tags[j].editStatus = false;
        }
      }
      this.nameEditTags = '';
      this.keyRender++;
    },
    deleteTag(refName,id) {
      if(this.getPermissionsForUserCanDeleteTags) {
        this.$refs[refName][0].hide(false);
        this.axiosDeleteTag(id).then(this.renderNew);
      }

    },
    unselectTag(id, index) {
      let executors = [];
      let task = this.getTasksList[index];
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      let tags = [];
      for(let i=0; i<task.tags.length; i++) {
        if(id !== task.tags[i].id) {
          tags.push(task.tags[i].id);
        }
      }
      this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.execute_time, is_archived: task.is_archived, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(this.renderNew);
    },
    changeTagsForTask(data) {
      let executors = [];
      let task = this.getTasksList[data.index];
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      let tags = [];
      for(let i=0; i<data.tags.length; i++) {
        tags.push(data.tags[i].id);
      }
      let k=0;
      task.tags.map(a=> {
        if(tags.indexOf(a.id) === -1) {
          k++
        }
      })
      console.log(k, task.tags, tags)
      if(k !== 0 || task.tags.length !== tags.length) {
        this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.execute_time, is_archived: task.is_archived, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(this.renderNew);
      }
    },
    openChangeSelectTag(refName, tags, index) {
      this.$refs[refName][0].showDropdown({tags: tags, index: index});
    },
    changeDateForTask(data, task) {
      // let executors = [];
      // let task = this.getTasksList[data.index];
      // for(let i=0; i<task.executors.length; i++) {
      //   executors.push(task.executors[i].id);
      // }
      // let tags = [];
      // for(let i=0; i<task.tags.length; i++) {
      //   tags.push(task.tags[i].id);
      // }
      if(JSON.stringify(data.days_for_repeat) !== JSON.stringify(task.days_for_repeat) || data.due_date_status !== task.due_date_status || moment(data.execute_time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') !== moment(task.execute_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm') ) {
        this.axiosChangeManualTask({id: this.getTasksList[data.index].id, data: {days_for_repeat: data.days_for_repeat, due_date_status: data.due_date_status, execute_time: data.execute_time}}).then(()=> {
          this.renderNew()
        })
      }

    },
    openDatePickerChangeDateForTask(refName, date, index) {
      this.$refs[refName][0].showDatePicker({date: date, index:index});
    },
    changePriorityForTask(data) {
      let executors = [];
      let task = this.getTasksList[data.index];
      for(let i=0; i<task.executors.length; i++) {
        executors.push(task.executors[i].id);
      }
      let tags = [];
      for(let i=0; i<task.tags.length; i++) {
        tags.push(task.tags[i].id);
      }
      console.log(data)
      console.log(task.priority)
      if((''+data.priority?.id) !== (''+task.priority?.id))
      this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.execute_time, is_archived: task.is_archived, status: task.status, type: task.type, priority: data.priority.id, executors: executors, tags: tags}).then(this.renderNew);
    },
    openChangePriorityDropdown(refName, priority, index) {
      this.$refs[refName][0].showDropdown({priority: priority, index: index});
    },
    openUserSelectForTask(refName, array) {
      // // console.log(refName, array);
      this.$refs[refName][0].showDropdown(array, true);
    },
    showModalCreateTask() {
      this.$emit("showModalCreateTask");
    },
    formatDate(date) {
        date = (new Date(date));
        let text = "";
        let mm = date.toLocaleString('en', {
        month: 'short'
      });
      let min = date.getMinutes();
      if(min<10) {
        min = "0"+min;
      }
      text = date.getDate() + ", " + mm + ", " + date.getHours() + ":" + min;
      return text;
    },
    checkDate(date) {
      if(((new Date()).getFullYear())>=(((new Date(date)))).getFullYear() && ((new Date())).getMonth()>=((new Date(date))).getMonth() && ((new Date()).getDate())>=((new Date(date))).getDate() && (new Date()).getHours()>=(new Date(date)).getHours() && (new Date()).getMinutes()>(new Date(date)).getMinutes()) {
        return true;
      }
      return false;
    },
    renderNew() {
      this.$emit("renderNew");
    },
    deleteTask(task) {
      if(this.getPermissionsForUserCanArchiveTask) {
        let executors = [];
        for(let i=0; i<task.executors.length; i++) {
          executors.push(task.executors[i].id);
        }
        let tags = [];
        for(let i=0; i<task.tags.length; i++) {
          tags.push(task.tags[i].id);
        }
        this.axiosChangeTask({id: task.id,name: task.name, description: task.description, execute_time: task.execute_time, is_archived: true, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(()=> {
          this.addTip("Task " + task.name + " is archived")
          this.renderNew();
      })
      }
      // // console.log(task)
      // this.axiosDeleteTask(id).then(this.renderNew);

    },
    changeUserForTaskAddArray(array) {
      this.empForAxios = JSON.parse(JSON.stringify(array));
    },
    changeUserForTask(array, bool, index) {
      let executors = [];
      for(let i=0; i<array.length; i++) {
        executors.push(array[i].id);
      }
      let task = this.getTasksList[index];
      let tags = [];
      for(let i=0; i<task.tags.length; i++) {
        tags.push(task.tags[i].id);
      }
      if(bool) {
        this.axiosChangeTask({id: task.id, name: task.name, description: task.description, execute_time: task.execute_time, is_archived: task.is_archived, status: task.status, type: task.type, priority: task.priority.id, executors: executors, tags: tags}).then(this.renderNew);
        this.empForAxios.splice(0, this.empForAxios.length);
      }
    }
  },
  mounted() {
    if(this.getIdTaskDuplicate !== null || this.getIdTaskDuplicateVessel !== null || this.getIdTaskDuplicateCoupe !== null || this.getIdTaskDuplicateWithVessel !== null) {
      if(this.getIdTaskDuplicateWithoutVessel !== null) {
        let data = this.getVesselTaskInfo;
        data.task = JSON.parse(JSON.stringify(this.getTaskInfoConvert));
        this.duplicateTaskWithoutVessel(data);
      } else
      if(this.getIdTaskDuplicateWithVessel !== null) {
        let data = this.getVesselTaskInfo;
        data.task = JSON.parse(JSON.stringify(this.getTaskInfoConvert));
        this.duplicateTaskWithVessel(data);
      } else
      if(this.getIdTaskDuplicateCoupe !== null) {
        let data = this.getVesselTaskInfo;
        data.task = JSON.parse(JSON.stringify(this.getTaskInfoConvert));
        this.duplicateTaskCoupe(data);
      } else if(this.getIdTaskDuplicateVessel !== null) {
        let data = this.getVesselTaskInfo;
        data.task = JSON.parse(JSON.stringify(this.getTaskInfoConvert));
        this.duplicateTaskVessel(data);
      } else {
        this.getAxiosInfoForTask(this.getIdTaskDuplicate).then(()=> {
          this.conversionForTaskInfo({tags: this.getAllTag, executors: this.getEmployees.data.results})
          this.duplicateTask(this.getTaskInfoConvert);
        })
      }
    }

    this.setIdTaskDuplicate(null);
    this.setIdTaskDuplicateVessel(null);
    this.setIdTaskDuplicateCoupe(null);
    this.setIdTaskDuplicateWithoutVessel(null);
    this.setIdTaskDuplicateWithVessel(null);

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if(bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true;
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if(bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false;
      }
      if(this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import "tasks-list";
@import "./src/components/style-element/bootstrap";
@import "./src/components/style-element/checkbox";
.mr4 {
  margin-right: 4px !important;
}
.open-checklist {
  position: relative;
  z-index: 10;
}
.amount-checkbox {
  margin-right: 8px !important;
  border-radius: 40px;
  background-color: var(--color-blue-dark);
  padding: 2px 4px;
}
.dart-mode {
  .open-checklist {
    path {
      fill: var(--color-dark)
    }
  }
}
</style>