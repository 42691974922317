<template>
  <modal-slots :show-mod="true" :textForSecondButton="'Send'" :noCloseAfterApply="true" @apply="send" @close="$emit('close')">
    <template v-slot:title>
      <p class="fz18 fw500 text-dark">Send Message</p>
    </template>
    <div class="modal-content w720 p16-24 box-border">
      <p class="text-gray fz13">Reply to <span class="text-dark">{{selectedEmail.message_from}}</span></p>
      <p class="text-gray fz13 mt12">Sender</p>
      <input type="text" class="mt4" :class="{'error': errorSender}" v-model="sender">
      <vue-editor :class="{'errorDescription': errorDescription}" v-model="description" class="edit-block-wrap mt12" />
    </div>
  </modal-slots>
</template>

<script>
import ModalSlots from "@/components/modal/modal-slots";
import { VueEditor } from "vue2-editor";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "location-modal-reply",
  components: {ModalSlots, VueEditor},
  props: {
    selectedEmail: Object,
  },
  data() {
    return {
      sender: '',
      description: '',
      errorDescription: false,
      errorSender: false,
    }
  },
  methods: {
    ...mapActions(['axiosSendMessage']),
    ...mapMutations(['showLoad', 'hideLoad', 'addTip']),
    getValidEmail(email) {
      let EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return EMAIL_REGEXP.test(email)
    },
    async send() {
      let regex = /( |<([^>]+)>)/ig
      this.errorSender = !this.sender.length || !this.getValidEmail(this.sender)
      this.errorDescription = !this.description.replace(regex, "").length
      if(this.errorDescription || this.errorSender) {
        return null;
      }
      let form = {
        subject: 'Subject',
        text: this.description,
        message_from: this.sender,
        message_to: [this.selectedEmail.message_from],
        bind_ships: [this.selectedEmail.id],
      }
      this.showLoad();
      let res = await this.axiosSendMessage(form)
      this.hideLoad();
      if(res) {
        this.$emit('close')
        this.addTip( 'Message sent')
      }
    },
  },
  watch: {
    selectedEmail: function () {
      if(this.selectedEmail) {
        this.sender = this.selectedEmail.message_to.length ? this.selectedEmail.message_to[0] : ''
      }
    }
  },
  mounted() {
    if(this.selectedEmail) {
      this.sender = this.selectedEmail.message_to.length ? this.selectedEmail.message_to[0] : ''
    }
  }
}
</script>

<style scoped lang="scss">
  .modal-content {

  }
  .edit-block-wrap {
    /deep/ {
      .ql-editor {
        height: 422px;
        //height: calc(100vh - 487px);
        //min-height: calc(100vh - 487px);
        overflow-y: auto;
        background-color: var(--color-input);
        em {
          font-style: italic !important;
        }
        strong {
          font-weight: 500;
        }
      }
    }
  }
  .error {
    border-color: var(--color-red) !important;
  }
  .errorDescription {
    /deep/ {
      .ql-toolbar {
        border-top: 1px solid var(--color-red);
        border-left: 1px solid var(--color-red);
        border-right: 1px solid var(--color-red);
      }
      .ql-container {
        border-bottom: 1px solid var(--color-red);
        border-left: 1px solid var(--color-red);
        border-right: 1px solid var(--color-red);
      }
    }
  }
  .edit-block-wrap {
    //height: 505px;
  }
</style>