export default  {
  methods: {
    log(name = '', data = null) {
      console.clear()
      console.log(name, data)
    },
    generateUniqueHash() {
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      let hash = '';
      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        hash += characters.charAt(randomIndex);
      }
      return hash;
    },
  }
}
