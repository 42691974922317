<template>
  <div 
    class="vessel_owners__table_wide bg-white"
    :class="{
      'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
    }"
  >
    <div class="border-r border-b p-x-2 p-y-1">
      <button type="button" class="flex items-center cursor-pointer text-blue-dark fw500" @click="$emit('on-delete')">
        Hire #{{1+index}} 
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#094172"/></svg>
      </button>
    </div>
    <div class="border-r border-b" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="colspan-3-5 border-r border-b relative">
      <input-format-number
        v-model="hire.additional_info.rate"
        inputClass="p-l-2 p-r-24 p-y-2"
        placeholder="Enter rate"
        :rightPlaceholder="`Rate,&nbsp;${activeCurrency}/day`"
      ></input-format-number>
    </div>
    
    <div class="border-r border-b"></div>
    <div class="border-r border-b"></div>
    <div class="border-r border-b"></div>
    <div class="border-b"></div>

    <div class="border-r border-b p-x-2 p-y-2 text-dark" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">From</div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="border-r border-b text-gray-text relative" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">
      <input @change="$emit('change-date-hire')" class="p-x-2 p-y-2" :class="{'text-gray-text':!hire.additional_info.is_days}" type="text" ref="from_time" placeholder="hh:mm" v-model.lazy="hire.additional_info.from_time" :disabled="!hire.additional_info.is_days">
      <span class="absolute top-2 right-2">UTC</span>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">
      <date-picker
          @change="$emit('change-date-hire')"
        v-model="hire.additional_info.from_date"
        format="DD.MM.YYYY"
        value-type="format"
        type="date"
        placeholder="dd.mm.yyyy"
        :disabled="!hire.additional_info.is_days"
      ></date-picker>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"></div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"></div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"></div>
    <div class="border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"></div>

    <div class="border-r border-b p-x-2 p-y-2 text-dark" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">To</div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="border-r border-b text-gray-text relative" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">
      <input @change="$emit('change-date-hire')" class="p-x-2 p-y-2" :class="{'text-gray-text':!hire.additional_info.is_days}" type="text" placeholder="hh:mm" ref="to_time" v-model.lazy="hire.additional_info.to_time" :disabled="!hire.additional_info.is_days">
      <span class="absolute top-2 right-2">UTC</span>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">
      <date-picker
          @change="$emit('change-date-hire')"
          v-model="hire.additional_info.to_date"
        format="DD.MM.YYYY"
        value-type="format"
        type="date"
        placeholder="dd.mm.yyyy"
        :disabled="!hire.additional_info.is_days"
      ></date-picker>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"></div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"></div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"></div>
    <div class="border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"></div>


    <div 
      class="border-r border-b p-x-2 p-y-2 text-dark relative"
      :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}"
    >
      Days
      <switcher 
        class="switcher_owners-row"
        :id="'hire-days-'+index"
        v-model="hire.additional_info.is_days"
      />
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
    <div class="colspan-3-5 border-r border-b relative" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">
      <p class="p-x-2 p-y-2 text-align-right">{{hire.additional_info.days | formatNumberThreeZeroEmpty}}</p>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_days == 'revenues'"
      >{{hire.hire_debit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text ': !hire.additional_info.is_days}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_days == 'expenses'"
      >{{hire.hire_credit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-r border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_days" active="revenues"/>
    </div>
    <div class="border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_days" active="expenses"/>
    </div>

    <div 
      class="border-r border-b p-x-2 p-y-2 fw500 text-dark relative"
      :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}"
    >
      Commission
      <switcher 
        class="switcher_owners-row"
        :id="'hire-commission-'+index"
        v-model="hire.additional_info.is_commission"
      />
    </div>
    <div 
      class="border-r border-b"
      :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}"
      v-if="getPermissionsByType('show_accounts_in_all_operations')"
    >
      <get-trials v-if="$route.params.subId" trialKey="SUBLET_Charter Hire:Commission"/>
      <get-trials v-else trialKey="Charter Hire:Commission"/>
    </div>
    <div class="border-r border-b" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}"></div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}">
      <input-format-number
        v-model="hire.additional_info.commision"
        :inputClass="'p-l-2 p-r-6 p-y-2 '+(!hire.additional_info.is_commission?'text-gray-text':'')"
        placeholder="Enter percentage"
        rightPlaceholder="%"
        :disabled="!hire.additional_info.is_commission"
      ></input-format-number>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_commission == 'revenues'"
      >{{hire.commision_debit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-r border-b relative" :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_commission}">
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_commission == 'expenses'"
      >{{hire.commision_credit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-r border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_commission" active="revenues"/>
    </div>
    <div class="border-b">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_commission" active="expenses"/>
    </div>


    <div 
      class="border-r p-x-2 p-y-2 fw500 text-dark relative"
      :class="{
        'rounded-bl-lg': index == hireData.length-1,
        'bg-gray-light text-gray-text ': !hire.additional_info.is_broker
      }"
    >
      Broker commission
      <switcher 
        class="switcher_owners-row"
        :id="'hire-broker-'+index"
        v-model="hire.additional_info.is_broker"
      />
    </div>
    <div 
      class="border-r" 
      :class="{'bg-gray-light': !hire.additional_info.is_broker}"
      v-if="getPermissionsByType('show_accounts_in_all_operations')"
    >
      <get-trials v-if="$route.params.subId" trialKey="SUBLET_Charter Hire:Broker Commission"/>
      <get-trials v-else trialKey="Charter Hire:Broker Commission"/>
    </div>
    <div 
      class="border-r"
      :class="{'bg-gray-light': !hire.additional_info.is_broker}"
    ></div>
    <div 
      class="border-r relative"
      :class="{'bg-gray-light': !hire.additional_info.is_broker}"
    >
      <input-format-number
        v-model="hire.additional_info.broker_commision"
        :inputClass="'p-l-2 p-r-6 p-y-2 '+(!hire.additional_info.is_broker?'text-gray-text':'')"
        placeholder="Enter percentage"
        rightPlaceholder="%"
        :disabled="!hire.additional_info.is_broker"
      ></input-format-number>
    </div>
    <div 
      class="border-r relative"
      :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_broker}"
    >
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_broker == 'revenues'"
      >{{hire.broker_commision_debit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div 
      class="border-r relative"
      :class="{'bg-gray-light text-gray-text': !hire.additional_info.is_broker}"
    >
      <p 
        class="p-x-2 p-y-2 text-align-right"
        v-if="hire.additional_info.trs_type_broker == 'expenses'"
      >{{hire.broker_commision_credit | formatNumberTwoZeroEmpty}}</p>
    </div>
    <div class="border-r">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_broker" active="revenues"/>
    </div>
    <div class="" :class="{'overflow-hidden rounded-br-lg': index == hireData.length-1}">
      <trs-type-btn :additional="hire.additional_info" type="trs_type_broker" active="expenses"/>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import Inputmask from "inputmask"
import moment from "moment"
import { mapGetters } from 'vuex'

import getTrials from '@/components/vessels/owners/edit/getTrials'
import trsTypeBtn from '@/components/vessels/owners/edit/trsTypeBtn'
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import Switcher from '@/components/reuse/switcher.vue'

export default {
  props: {
    hireData: Array,
    hire: Object,
    index: Number,
    activeCurrency: String
  },
  components: {
    DatePicker,
    getTrials,
    trsTypeBtn,
    inputFormatNumber,
    Switcher
},
  data() {
    return {
      test: true
    }
  },
  watch: {
    'hire.additional_info.from_date': function() {this.getDays()},
    'hire.additional_info.to_date': function() {this.getDays()},
    'hire.additional_info.from_time': function() {this.getDays()},
    'hire.additional_info.to_time': function() {this.getDays()},

    'hire.additional_info.days': function() {this.getTotal()},
    'hire.additional_info.rate': function() {this.getTotal()},
    'hire.additional_info.trs_type_days': function() {this.getTotal()},

    'hire.hire_debit': function() {
      this.getCommision()
      this.getBrokerCommision()
    },
    'hire.hire_credit': function() {
      this.getCommision()
      this.getBrokerCommision()
    },

    'hire.additional_info.commision': function() {this.getCommision()},
    'hire.additional_info.trs_type_commission': function() {this.getCommision()},
    'hire.additional_info.broker_commision': function() {this.getBrokerCommision()},
    'hire.additional_info.trs_type_broker': function() {this.getBrokerCommision()}
  },
  computed: {
    ...mapGetters(['getPermissionsByType']),
  },
  methods: {
    getTimeVal(time) {
      let timeArr = time.split(':')
      if(timeArr.length < 2) return 0
      let timeToNumb = 0
      if(timeArr[0] != 0)
        timeToNumb += timeArr[0] * 60 * 60
      if(timeArr[1] != 0)
        timeToNumb += timeArr[1] * 60
        
      timeToNumb = timeToNumb / 86400
      
      return timeToNumb
    },
    getDays() {
      let diff = 0,
      from_time = this.getTimeVal(this.hire.additional_info.from_time),
      to_time = this.getTimeVal(this.hire.additional_info.to_time)

      if(this.hire.additional_info.to_date && this.hire.additional_info.from_date) {
        var start = moment(this.hire.additional_info.from_date, "DD-MM-YYYY")
        var end = moment(this.hire.additional_info.to_date, "DD-MM-YYYY")
        diff = end.diff(start, "days")
      }
      console.log(from_time, to_time)
      let result = diff + to_time - from_time
      result = result ? result : ''
      console.log(result)
      this.hire.additional_info.days = result
    },
    getTotal() {
      // console.log(this.hire.additional_info.rate, parseFloat(this.hire.additional_info.days))
      let result = this.hire.additional_info.rate * parseFloat(this.hire.additional_info.days)
      // console.log(result)


      result = result ? result : ''
      if(this.hire.additional_info.trs_type_days == 'revenues'){
        this.hire.hire_debit = result
        this.hire.hire_credit = ''
      }else{
        this.hire.hire_debit = ''
        this.hire.hire_credit = result
      }
    },
    getCommision() {
      let summ = this.hire.additional_info.trs_type_days == 'revenues' ? this.hire.hire_debit : this.hire.hire_credit
      let result = summ * (this.hire.additional_info.commision / 100)

      result = result ? result : ''

      if(this.hire.additional_info.trs_type_commission == 'revenues'){
        this.hire.commision_debit = result
        this.hire.commision_credit = ''
      }else{
        this.hire.commision_debit = ''
        this.hire.commision_credit = result
      }
    },
    getBrokerCommision() {
      let summ = this.hire.additional_info.trs_type_days == 'revenues' ? this.hire.hire_debit : this.hire.hire_credit
      let result = summ * (this.hire.additional_info.broker_commision / 100)
      
      result = result ? result : ''

      if(this.hire.additional_info.trs_type_broker == 'revenues'){
        this.hire.broker_commision_debit = result
        this.hire.broker_commision_credit = ''
      }else{
        this.hire.broker_commision_debit = ''
        this.hire.broker_commision_credit = result
      }
    },
    addInputMask() {
      if(this.$refs.from_time){
        if (!this.$refs.from_time.inputmask)
          Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.from_time)
      }
      if(this.$refs.to_time){
        if (!this.$refs.to_time.inputmask)
          Inputmask({ regex: "(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]", "clearIncomplete": true }).mask(this.$refs.to_time)
      }
    },
    checkIsRow() {
      if(!Object.prototype.hasOwnProperty.call(this.hire.additional_info, 'is_days')){
        this.$set(this.hire.additional_info, 'is_days', true)
      }
      if(!Object.prototype.hasOwnProperty.call(this.hire.additional_info, 'is_commission')){
        this.$set(this.hire.additional_info, 'is_commission', true)
      }
      if(!Object.prototype.hasOwnProperty.call(this.hire.additional_info, 'is_broker')){
        this.$set(this.hire.additional_info, 'is_broker', true)
      }
    }
  },
  mounted() {
    this.addInputMask()

    this.checkIsRow()
  }
}
</script>

<style lang="scss">

</style>