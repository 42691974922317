<template>
  <transition name="fade">
    <div class="wrap" v-if="show">
      <div class="filter"></div>
      <div class="content">
        <p class="fz16 fw500">Delete tag</p>
        <button class="close" v-on:click="closeModal">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.51326 3.57865C2.21563 3.28395 2.21563 2.80615 2.51326 2.51146C2.81089 2.21676 3.29344 2.21676 3.59106 2.51146L13.4858 12.2974C13.7835 12.5921 13.7835 13.0699 13.4858 13.3646C13.1882 13.6593 12.7057 13.6593 12.408 13.3646L2.51326 3.57865Z" fill="#2C2C2C"/>
            <path d="M12.4081 2.63537C12.7058 2.34068 13.1883 2.34068 13.4859 2.63538C13.7836 2.93007 13.7836 3.40787 13.4859 3.70256L3.59116 13.4885C3.29354 13.7832 2.81099 13.7832 2.51336 13.4885C2.21573 13.1938 2.21573 12.716 2.51336 12.4213L12.4081 2.63537Z" fill="#2C2C2C"/>
          </svg>
        </button>
        <p class="text fz14 text-dark">Are you sure you want to delete <span class="fw500"> {{tagName}} </span> tag everywhere?</p>
        <div class="bottom-panel">
          <button class="blue-button-text fz14" v-on:click="closeModal">Cancel</button>
          <button class="blue-button fz14 fw500 buttonW100" v-on:click="deleteTag">Delete</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "modalDeleteTag",
  data() {
    return {
      show: false,
      tagName: "sdsd",
      id: null
    }
  },
  methods: {
    ...mapActions(["axiosDeleteTag"]),
    closeModal() {
      this.show = false;
      this.tagName = "";
      this.id = null;
    },
    openModal(tag) {
      this.show = true;
      this.tagName = tag.name;
      this.id = tag.id;
    },
    deleteTag() {
      this.axiosDeleteTag(this.id).then(()=> {
        this.$emit("renderTask");
        this.closeModal();
      });
    },
  },
  mounted() {
  }

}
</script>

<style scoped lang="scss">
  .wrap {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .filter {
      background-color: #2C2C2C;
      opacity: 0.15;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .content {
      position: relative;
      padding: 12px 16px;
      border-radius: 8px;
      background-color: var(--color-modal);
      width: 424px;
      height: max-content;
    }
    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 13px;
      right: 16px;
    }
    .text {
      margin-top: 34px;
      margin-bottom: 26px;
    }
    .bottom-panel {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .blue-button {
        border-radius: 4px;
        margin-left: 33px;
        height: 32px;
      }
    }
  }
</style>