<template>
  <div>
    <b-form-group>
      <div
          v-for="(ch, i) in getAccount()"
          :key="i"
          class="check-wrap"
      >
        <b-form-checkbox
            @change="changeCheck"
            v-model="selected"
            :value="ch"
            name="flavour-3a"
            class="check"
        >
          <!--          <pre>{{selected}}</pre>-->
          <div class="text-block">
            <span><p class="text1 fz14 text-dark-gray"> {{ch.name}}</p> </span>
            <!--          <button v-if="ch.button2Show === true" v-on:click="showMenuRight1" class="everyone fz14 blue-button-text">-->
            <!--            {{ buttonText1 }}</button>-->
          </div>
        </b-form-checkbox>
<!--        <span v-if="ch.button1Show === true" v-on:click="showMenuRight({id: ch.id ,index: i})" class="everyone fz14 blue-button-text">-->
<!--            {{ ch.buttonText }}-->
<!--        </span>-->
      </div>

    </b-form-group>
<!--    {{getPaymentsAccount}}-->
<!--    {{selected}}-->
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "checkboxes-for-select-account",
  computed: mapGetters(["getPaymentsAccount"]),
  props: ["searchText", "setSel"],

  data() {
    return {
      // list: [{name: "2222", id:0},
      //   {name: "111", id:1},
      //   {name: "333", id:2}],
      selected: [],
    }
  },
  watch: {
  },
  methods: {
    ...mapActions(["axiosGetPaymentAccount"]),

    changeCheck() {
      this.$emit("change", this.selected);
    },
    setSelect(sel) {
      if(Array.isArray(sel)) {
        this.selected = sel;
        // alert(1)
      }
    },
    getAccount() {
      if(this.searchText !== null && (""+this.searchText).length !== 0) {
        return this.getPaymentsAccount.filter(a=> a.name.indexOf(this.searchText)>=0)
      }
      return this.getPaymentsAccount;
    }
  },
  mounted() {

    this.$emit("mountedEnd")
  }

}
</script>

<style scoped lang="scss">
@import "./src/components/style-element/bootstrap";
@import "./src/components/style-element/checkbox";
.check-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
}
p {
  margin: 0;
}
.check {

}
.everyone {
  margin-left: 5px;
  cursor: pointer;
  display: inline;
}

.text-block {
  //white-space: pre-wrap;
  //display: flex;
  * {
    //display: inline !important;
    //float: left;
  }
  p {
    display: inline !important;
    width: max-content !important;
    height: 20px;
  }
  button {
    //float: left;
    word-break: break-all;
    //margin-left: 5px;
  }
}

/deep/ .custom-control {
  p:first-letter {
    display: block !important;
    text-transform: uppercase;
  }
}
</style>