<template>
  <div>
    <div class="bank-aside_block__item-balance_all fz14 fw500" v-if="!requisites.length">0.0000 USD</div>

    <div class="bank-aside_block__item-balance_all fz14 fw500" v-if="!ifOnceCurrency && requisites.length">{{convertedSumm | formatNumber}} USD</div>


    <div class="bank-aside_block__item-balance_all fz14 fw500" v-if="ifOnceCurrency">{{summ | formatNumber}} {{requisites[0].currency}}</div>
    <div class="bank-aside_block__item-balance_convert" v-if="ifOnceCurrency && requisites.length && requisites[0].currency != 'USD'">({{convertedSumm | formatNumber}} USD)</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    requisites: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      summ: 0,
      convertedSumm: 0,
      ifOnceCurrency: false,
      rates: []
    }
  },
  computed: {
  },
  methods: {
    ...mapActions(['axiosGetCurrency']),
    async getRate(currency) {
      await this.axiosGetCurrency({currency: currency}).then(response=> {
        let rate = response.data.results[response.data.results.length - 1].rate
        this.rates.push(rate)
      })
    },
    countConvertSumm() {
      this.requisites.forEach((el, i) => {
        if(el.currency == 'USD'){
          this.convertedSumm += el.balance
        }else{
          this.convertedSumm += el.balance / this.rates[i]
        }
      })
    }
  },
  async mounted() {
    if(this.requisites.length) {
      if(this.requisites.length == this.requisites.filter(el=>{return el.currency == this.requisites[0].currency}).length){
        this.ifOnceCurrency = true
      }
    }

    for(let i=0; i < this.requisites.length; i++){
      const element = this.requisites[i]
      await this.getRate(element.currency)
      this.summ += element.balance
    }
    this.countConvertSumm()
  }
}
</script>

<style>

</style>