<template>
  <div 
    class="relative flex flex-col overflow-y-auto h-full"
    :class="{'page-gray': $route.meta.isArchive && !$route.meta.isVesselPage}"
  >
    <bread :links="breadLinks" :allowOptions="false" v-if="$route.meta.isArchive && !$route.meta.isVesselPage"/>
    <entries-header @emitXmlDownload="emitXmlDownload" :filters="filters" @on-create="onCreate"/>
    <entries-empty v-if="!entries || (entries.items && !entries.items.length)" @on-create="onCreate"/>
    <entries-list class="list" ref="listEntries" :filters="filters" v-if="entries && entries.items && entries.items.length" :entries="entries" @on-delete="onDelete" @on-update="onFiltered"/>

    <modal-slots
      :showMod="isArchiveModal"
      :isBorder="false"
      textForFirstButton="No"
      textForSecondButton="Yes"
      @close="isArchiveModal = false"
      @apply="onArchive"
    >
      <template v-slot:title>
        <h2 class="fz16 fw500">Archive the Entry</h2>
      </template>
      <p class="p-x-6 p-t-2 p-b-8 fz14">Are you sure you want to archive this Entry?</p>
    </modal-slots>

    <modal-slots
      :showMod="isUnarchiveModal"
      :isBorder="false"
      textForFirstButton="No"
      textForSecondButton="Yes"
      @close="isUnarchiveModal = false"
      @apply="onUnarchive"
    >
      <template v-slot:title>
        <h2 class="fz16 fw500">Unarchive the Entry</h2>
      </template>
      <p class="p-x-6 p-t-2 p-b-8 fz14">Are you sure you want to unarchive this Entry?</p>
    </modal-slots>
  </div>
</template>

<script>
import axios from 'axios'
//import QueryString from "querystring";
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import bread from '@/components/reuse/bread'
import entriesHeader from '@/components/entries/entriesHeader'
import entriesEmpty from '@/components/entries/entriesEmpty'
import entriesList from '@/components/entries/entriesList'
import modalSlots from '@/components/modal/modal-slots'

export default {
  components: {
    bread,
    entriesHeader,
    entriesEmpty,
    entriesList,
    modalSlots
  },
  data() {
    return {
      isArchiveModal: false,
      archivedItem: null,
      isUnarchiveModal: false,
      breadLinks: [
        {
          name: 'Journal Entries',
          link: '/accounts/entries'
        },
        {
          name: 'Archive Journal Entries',
          link: ''
        }
      ]
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.onFiltered()
      }
    }
  },
  computed: {
    ...mapState({
      activeContract: state => state.contract.activeContract,
      entries: state => state.entries.entries,
      mainFilters: state => state.entries.filters,
      archiveFilters: state => state.entries.archiveFilters,
      defaultEntries: state => state.entries.defaultEntries,
      defaultEntriesDetails: state => state.entries.defaultEntriesDetails
    }),
    ...mapGetters(['getToken']),
    filters() {
      if(this.$route.meta.isArchive)
        return this.archiveFilters
      else
        return this.mainFilters
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_journal_entries_section_in_accounting") === false) {
        next(from);
      }
    })
  },
  methods: {
    ...mapActions(['axiosGetEntries', 'axiosEditEntries']),
    ...mapMutations(['showLoad', 'hideLoad', 'clearEntries', 'changeContractBread']),

    emitXmlDownload() {
      this.$refs.listEntries.downloadXML()
    },
    onCreate() {
      let newEntry = {...this.defaultEntries}
      newEntry.debit = {...this.defaultEntriesDetails}
      newEntry.credit = {...this.defaultEntriesDetails}

      if(this.$route.meta.isVesselPage)
        newEntry.vessel = this.activeContract
      this.entries.items.unshift(newEntry)
    },
    onDelete(item, index) {
      if(item.id){
        this.archivedItem = item
        if(this.$route.meta.isArchive){
          this.isUnarchiveModal = true
        }else{
          this.isArchiveModal = true
        }
      }else{
        this.entries.items.splice(index, 1)
      }
    },
    async onArchive() {
      await this.axiosEditEntries({
        id: this.archivedItem.id,
        operation_type: this.archivedItem.operation_type,
        is_archive: true
      })
      this.onFiltered()
    },
    async onUnarchive() {
      await this.axiosEditEntries({
        id: this.archivedItem.id,
        operation_type: this.archivedItem.operation_type,
        is_archive: false
      })
      this.onFiltered()
    },
    async onFiltered() {
      //this.showLoad()
      await this.axiosGetEntries({
        params: this.filters
      })
      //this.hideLoad()
    },
    async setFilterAccount(id) {
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/payment/account/'+id,
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
      }).then(response => {
        return response.data
      }).catch(error => {
        console.error('setFilterAccount: ', error);
      })
    },
    async setFilterVessel(id) {
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/contract/'+id,
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
      }).then(response => {
        return response.data
      }).catch(error => {
        console.error('setFilterVessel: ', error);
      })
    },
    async setFilterClient(id) {
      return await axios({
        method: 'get',
        url: process.env.VUE_APP_URL+'/client/'+id,
        headers: {
          'Authorization': 'token ' + this.getToken,
        },
      }).then(response => {
        return response.data
      }).catch(error => {
        console.error('setFilterClient: ', error);
      })
    }
  },
  async beforeMount() {
    this.clearEntries()

    /* CHANGE it, if have enought time */
    this.filters.vessel = []
    this.filters.client = []
    this.filters.bank_account = []
    this.filters.bank = []
    this.filters.requisite = []
    this.filters.operation_type = []
    this.filters.is_manual = null
    this.filters.due_date_after = null
    this.filters.due_date_before = null

    //this.showLoad()

    if(Object.keys(this.$route.query).length != 0){
      if(this.$route.query.account){
        if(this.filters.bank_account.length) this.filters.bank_account.length = 0
        if(Array.isArray(this.$route.query.account)){
          let accounts = []
          for (let i = 0; i < this.$route.query.account.length; i++) {
            let account = await this.setFilterAccount(this.$route.query.account[i])
            accounts.push(account)
          }
          this.filters.bank_account = accounts
        }else{
          let account = await this.setFilterAccount(this.$route.query.account)
          this.filters.bank_account.push(account)
        }
      }
      if(this.$route.query.vessel){
        if(this.filters.vessel.length) this.filters.vessel.length = 0
        if(Array.isArray(this.$route.query.vessel)){
          let vessels = []
          for (let i = 0; i < this.$route.query.vessel.length; i++) {
            let vessel = await this.setFilterVessel(this.$route.query.vessel[i])
            vessels.push(vessel)
          }
          this.filters.vessel = vessels
        }else{
          let vessel = await this.setFilterVessel(this.$route.query.vessel)
          this.filters.vessel.push(vessel)
        }
      }
      if(this.$route.query.client){
        if(this.filters.client.length) this.filters.client.length = 0
        if(Array.isArray(this.$route.query.client)){
          let clients = []
          for (let i = 0; i < this.$route.query.client.length; i++) {
            let client = await this.setFilterClient(this.$route.query.client[i])
            clients.push(client)
          }
          this.filters.client = clients
        }else{
          let client = await this.setFilterClient(this.$route.query.client)
          this.filters.client.push(client)
        }
      }
    }else if(this.$route.meta.isVesselPage){
      if(this.filters.vessel.length) this.filters.vessel.length = 0

      this.filters.vessel.push(this.activeContract)
    }else{
      await this.onFiltered()
    }
    //this.hideLoad()

    if(this.$route.meta.isVesselPage && this.$route.meta.isArchive){
      this.changeContractBread({
        index: 2,
        obj: {
          name: 'Archive entries',
          link: `/vessels/${this.$route.params.id}/entries/archive`
        }
      })
    }
  },
  beforeDestroy() {
    if(this.$route.meta.isVesselPage && !this.$route.meta.isArchive){
      this.changeContractBread({
        index: 2,
        obj: null
      })
    }
  }
}
</script>

<style scoped lang="scss">
    .list {
      overflow-y: auto;
      padding-top: 10px;
      margin-top: 10px;
      height: calc(100vh - 181px);
    }
</style>