<template>
    <div>
        <b-dropdown right ref="dropdown" @show="openDrop">
            <template #button-content>
                <button class="user-button">
                    <p class="name fz13 text-white">{{ getLoginData.data.user.first_name }}</p>
                    <svg class="no-avatar" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="getLoginData.data.user.avatar === null || getLoginData.data.user.avatar === undefined">
                    <circle cx="80" cy="80" r="80" fill="white"/>
                    <path d="M80 0.00308228C35.8218 0.00308228 0 35.8179 0 79.9996C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9996C160 35.8179 124.185 0.00308228 80 0.00308228ZM80 23.923C94.6183 23.923 106.464 35.7722 106.464 50.3835C106.464 64.9983 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9983 53.543 50.3835C53.543 35.7722 65.3887 23.923 80 23.923ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                    </svg>
                  <img :src="getUrlUser(getLoginData.data.user.avatar)" alt="" v-if="getLoginData.data.user.avatar !== null && getLoginData.data.user.avatar !== undefined">
                    <svg class="arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="white"/>
                    </svg>
                </button>
            </template>
            <div class="content">
              <button class="fz15 text-dark" v-on:click="clickProfile()">Profile</button>
              <button class="fz15 text-dark" v-on:click="clickLogout()">Log out</button>
              <button class="fz15 text-dark mode-dark">
                <span class="zone-click" v-on:click="clickDartMode()"></span>
                Dark mode
                <label class="switch" :class="{'slider-active': sliderActive}">
                  <input type="checkbox">
                  <span class="slider round"></span>
                </label>
              </button>
            </div>
        </b-dropdown>
    </div>
</template>

<script>
import "firebase/auth"
import {mapActions, mapGetters, mapMutations} from 'vuex';
import firebase from 'firebase/app'
    export default {
      computed: mapGetters(['getLoginData', "getToken", "getDartMode"]),
        name: "userDropdown",
        data() {
            return {
              sliderActive: false,
            }
        },
        methods: {
        ...mapActions(["axiosLogoutInServer"]),
          ...mapMutations(["deleteDataUser", "setDartMode"]),
          openDrop() {
            this.sliderActive = this.getDartMode
          },
          getUrlUser(link) {
            if(link && link.indexOf(process.env.VUE_APP_URL)>=0) {
              return link;
            }
            return this.getURL()+ link
          },
          clickDartMode() {
            this.sliderActive = !this.sliderActive;

            if(this.sliderActive) {
              // document.body.classList.add('dart-mode')
              this.setDartMode(true)
            } else {
              this.setDartMode(false)
              // document.body.classList.remove('dart-mode');
            }
          },
          getURL() {
            return process.env.VUE_APP_URL_SIMPLE
          },
            clickProfile() {
          // // console.log(this.getLoginData.data.avatar);
                this.$router.push('/employee-card/' + this.getLoginData.data.user.id)
                this.$refs.dropdown.hide(true);
            },
            // clickLogout() {
            //     this.$refs.dropdown.hide(true);
            //     this.axiosLogoutInServer(this.getToken).then(()=> {
            //       // this.$router.push("/login");
            //     });
            //   const auth = getAuth();
            //
            //   auth.signOut().then(() => {
            //     // Sign-out successful.
            //   }).catch(() => {
            //     // An error happened.
            //   });
            //   this.deleteDataUser()
            //   this.$router.push("/login");
            // },
          clickLogout() {
            this.$refs.dropdown.hide(true);
            this.axiosLogoutInServer(this.getToken).then(()=> {
              // this.$router.push("/login");
            });
            firebase.auth().signOut().then(() => {
              // Sign-out successful.
            }).catch(() => {
              // An error happened.
            });
            this.deleteDataUser()
            this.$router.push("/login");
          }
        },
      mounted() {
        this.sliderActive = this.getDartMode
      }
    }
</script>

<style scoped lang="scss">
    @import "../style-element/dropdown";
    .user-button {
        display: flex;
        align-items: center;
      @media (max-width: 768px) {
        p {
          display: none;

        }
      }
    }
    p {
        margin: 0;
    }
    .name {
        margin-right: 8px;
    }
    img, .no-avatar {
        width: 32px;
        height: 32px;
        border-radius: 40px;
    }
    .arrow {
        margin-left: 4px;
    }
    /deep/ .show {
        .arrow {
            transform: rotateX(180deg);
        }
    }
    /deep/ .dropdown-menu {
        background: transparent;
        border: none;
        min-width: 1px;
        width: max-content;
    }
    .content {
        border-radius: 4px;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;

        button {
          transition: background-color .1s linear;
            background-color: var(--color-modal);
            height: 32px;
            width: 135px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 10px;
          box-sizing: border-box;
            &:first-child {
                border-radius: 4px 4px 0 0px;
            }
            &:last-child {
                border-radius: 0px 0px 4px 4px;
            }
            &:hover {
                background-color: var(--color-hover-list);
            }
        }
      .mode-dark {
        position: relative;
        border-top: 1px solid var(--color-border);
        justify-content: space-between;
        height: 36px;
        label {
          margin: 0 !important;
        }
        .zone-click {
          z-index: 10;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .switch {
      z-index: 0;
      position: relative;
      display: inline-block;
      width: 26px;
      height: 16px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    .slider-active {
      input + .slider {
        background-color: #2196F3;
      }

      input + .slider {
        box-shadow: 0 0 1px #2196F3;
      }

      input + .slider:before {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
      }
    }
    //input:checked + .slider {
    //  background-color: #2196F3;
    //}
    //
    //input:focus + .slider {
    //  box-shadow: 0 0 1px #2196F3;
    //}
    //
    //input:checked + .slider:before {
    //  -webkit-transform: translateX(10px);
    //  -ms-transform: translateX(10px);
    //  transform: translateX(10px);
    //}

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

</style>
