<template>
  <span class="block relative">
    <span 
      @click.stop="openMenu"
      class="inline-flex fz13 fw500 rounded-full p-x-2 p-y-0.5" 
      :class="{
        'text-gray-text bg-gray-lightest': balanceType == 'N/A balance',
        'text-white bg-red': balanceType == 'Unsettled balance',
        'text-white bg-green': balanceType == 'Settled balance',
      }"
    >{{balanceType}}</span>

    <div class="option-btn_list left-0 right-auto top-auto bottom-full" tabindex="0" @blur="closeMenu()" v-show="isShowMenu" ref="option_list">
      <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('N/A balance')">
        N/A balance
        <svg :class="{'opacity-100': balanceType == 'N/A balance'}" class="m-l-2 m-r-0 opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
      </div>
      <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('Unsettled balance')">
        Unsettled balance
        <svg :class="{'opacity-100': balanceType == 'Unsettled balance'}" class="m-l-2 m-r-0 opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
      </div>
      <div class="option-btn_item flex justify-between p-l-3 p-r-1" @click.stop="closeMenu('Settled balance')">
        Settled balance
        <svg :class="{'opacity-100': balanceType == 'Settled balance'}" class="m-l-2 m-r-0 opacity-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z" fill="#319FEF"/></svg>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    balanceType: String
  },
  data() {
    return {
      isShowMenu: false
    }
  },
  methods: {
    openMenu() {
      this.isShowMenu = true

      setTimeout(()=>{ // because when I call .focus() after change isOpen, it`s don`t work
        let optionList = this.$refs.option_list
        optionList.focus()
      }, 100)
    },
    closeMenu(status) {
      if(status){
        this.$emit('on-change', status)
      }
      this.isShowMenu = false
    },
  }
}
</script>

<style>

</style>