<template>
  <div class="wrap">
    <div class="top-panel">
      <button class="fz16 fw500 mr32" :class="{'active': $route.path.indexOf('list')>=0}" v-on:click="$router.push({name: 'CashFlow List'})">Cash Flow List</button>
      <button class="fz16 fw500 mr32" :class="{'active': $route.path.indexOf('diagram')>=0 && $route.path.indexOf('new-diagram') === -1}" v-on:click="$router.push({name: 'CashFlow Diagram'})">Cash Flow Projection</button>
      <button class="fz16 fw500" :class="{'active': $route.path.indexOf('new-diagram')>=0}" v-on:click="$router.push({name: 'CashFlow Diagram New'})">Cash Flow Diagram</button>

    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
name: "cashFlow",
  computed: mapGetters(["getPermissionsByType"]),
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_cashflow_section") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
        next(from);
      }
    })
  },
  mounted() {
    // if(this.getPermissionsByType("show_cashflow_section") === false) {
    //   this.$router.push("/employees")
    // }
  }
}
</script>

<style scoped lang="scss">
.top-panel {
  position: relative;
  z-index: 1;
  padding: 0 24px;
  height: 51px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-card);
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--color-border);
  button {
    height: 100%;
    color: #9AA3B0;
    transition: .1s linear;
    //&:hover {
    //  color: #094172;
    //}
    border-bottom: 2px solid transparent;
  }
  .active {
    color: var(--color-blue-dark);
    border-bottom: 2px solid var(--color-blue-dark);
  }
}
.wrap {
    min-height: calc(100vh - 56px - 51px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /deep/ .wrap-page {
      //display: flex;
      //align-items: flex-start;
      justify-content: flex-start;
      padding-top: 0;
    }
  }
</style>