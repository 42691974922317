<template>
    <div>
        <vue-tel-input @enter="enter" @input="inputPhone" @focus="focus" @blur="blur" :class="{focus:inpFocus}" v-model="phone" v-bind="bindProps">
            <span slot="arrow-icon" class="arrow-svg">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 4.39999L6.00001 8.39999L10 4.39999H2Z" fill="black"/>
                </svg>
            </span>
        </vue-tel-input>
        <!--        {{phone}}-->
    </div>
</template>

<script>
    import {VueTelInput} from 'vue-tel-input'
    export default {
        name: "inputphone",
        components: {VueTelInput},
        data() {
            return {
                inpFocus: false,
                phone: "",
                bindProps: {
                    mode: "international",
                    defaultCountry: "GR",
                    disabledFetchingCountry: false,
                    disabled: false,
                    disabledFormatting: false,
                    placeholder: "Phone number",
                    required: false,
                    enabledCountryCode: false,
                    enabledFlags: true,
                    // preferredCountries: ["AU", "BR"],
                    onlyCountries: [],
                    validCharactersOnly: true,
                    ignoredCountries: [],
                    autocomplete: "off",
                    name: "telephone",
                    maxLen: 25,
                    wrapperClasses: "input-phone",
                    inputClasses: "",
                    dropdownOptions: {
                        disabledDialCode: false,
                        tabindex: 10,
                    },
                    inputOptions: {
                        showDialCode: true,
                    }
                }
            }
        },
        methods: {
            inputPhone(data, obj) {
                if(obj.valid === true) {
                    this.bindProps.maxLen = this.phone.length;
                } else {
                    this.bindProps.maxLen = 25;
                }
                this.$emit("changePhone", this.phone);
                this.$emit('validPhone', obj.valid)
            },
            focus() {
                this.inpFocus = true;
                this.$emit("focus")
            },
            blur() {
                this.inpFocus = false;
            },
            enter() {
                this.$emit("clickEnter");
            },
          setPhone(phone) {
              this.phone = phone;
          }
        }
    }
</script>

<style scoped lang="scss">
    div {
        width: 100%;
    }



    .input-phone {
        box-shadow: none !important;
        /*margin-bottom: 24px;*/

        width: 100%;
        box-sizing: border-box;
        height: 48px !important;
        border: 1px solid var(--color-border) !important;
        transition: .2s linear;
        &/deep/ {
            .vti__dropdown {
                transition: .2s linear;
            }
        }
        &:hover {
            border-color: #A3A3A3 !important;
            &/deep/ {
                .vti__dropdown {
                    border-color: #A3A3A3 !important;
                }
            }
        }

        /deep/ input {
            border: none !important;
            height: 100%;
        }
    }
    /deep/ {
        /*.vti__dropdown-arrow {*/
        /*    content: ">>" !important;*/
        /*}*/
        .arrow-svg {
            /*position: relative;*/
            /*bottom: 2px;*/
            margin: 0 !important;
        }
        .vti__selection {
            flex-direction: row-reverse;
            margin: 0 !important;
        }
        .vti__input {
            padding-left: 12px;
        }
        .vti__dropdown {
            width: 56px;
            height: 100%;
            background: var(--color-input);
            box-sizing: border-box;
            border-radius: 4px 0px 0px 4px;
            border-right: 1px solid var(--color-border);
            padding: 12px 10px 12px 6px;

            display: flex;
            align-items: center;
            svg {
                position: relative;
                //top: 1px;
            }
            img {
                width: 20px;
                height: 13px;
            }
            span {
                color: #222222;
                font-size: 14px;
                margin-left: 4px;
                font-family: "Montserrat", sans-serif;
            }
            .vti__country-code {
                background-color: #F4F4F6;
            }
            .vti__flag {
                margin: 0;
                margin-left: 4px;
            }

        }
        .open {
            .arrow-svg {
                svg {
                    transform: rotateX(180deg);

                }
            }
        }
        .vti__dropdown-list.below {
            top: 50px;
            width: 360px;
            box-sizing: border-box;
            background-color: var(--color-input);
          .highlighted {
            background-color: var(--color-hover-list);
          }
            .vti__dropdown-item {
              * {
                color: var(--color-dark);
              }
            }
        }
    }

    .focus {
        border-color: var(--color-blue-dark) !important;
        &/deep/ {
            .vti__dropdown {
                border-color: var(--color-blue-dark) !important;
            }
        }
        &:hover {
            border-color: var(--color-blue-dark) !important;
            & /deep/ {
                .vti__dropdown {
                    border-color: var(--color-blue-dark) !important;
                }
            }
        }
    }
</style>
