<template>
  <div>
    <b-form-checkbox
        v-for="l in list"
        :key="l.name"
        v-model="select"
        :value="l"
        @change="changeSelect"
        class="checkbox-blcok"
    >
      <p class="fz14 text-dark1">{{l.name}}</p>
    </b-form-checkbox>
  </div>

</template>

<script>
export default {
  name: "payment-list-filter-checkboxes",
  props: ["list", "select"],
  data() {
    return {
      localSelect: [],
    }
  },
  watch: {
    select: function () {
      this.localSelect = this.select
    },
  },
  methods: {
    changeSelect() {
      this.$emit("changeSelect", this.localSelect);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style-element/bootstrap";
@import "../../style-element/checkbox";
p {
  margin: 0;
}
.checkbox-blcok {
  margin-top: 12px;
}
</style>