<template>
  <div class="flex items-center">
    <div class="flex flex-shrink-0 h-full w-104 p-x-3 p-y-1 border-r border-gray-text">
      <button class="flex items-center m-y-auto radio-button text-dark hover:child-stroke-blue-dark hover:text-blue-dark" @click="$emit('on-click')">
        <svg v-if="fuel == prefix" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle class="circle1" cx="9" cy="9" r="8" stroke="#094172" stroke-width="2"/>
          <circle class="circle2" cx="9" cy="9" r="5" fill="#094172"/>
        </svg>
        <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="8" stroke="#9AA3B0" stroke-width="2"/>
        </svg>
        <span class="m-l-1.5 fz13 fw500 uppercase">{{prefix}}</span>
      </button>
    </div>
    <div class="w-full p-x-3 p-t-1 text-gray-text fz13 overflow-hidden position-relative">

      <div 
        class="inline-block p-b-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis fw600" 
        :class="{
          'w-1/2': ifManyNumbers
        }"
      >
<!--        Result, $: <span class="m-l-1.5 m-r-4 text-dark fw600">{{detail[prefix+'_result'] | formatNumber}}</span>-->
<!--        {{(detail.hire ? Math.abs(parseFloat(detail.hire)) : 0)}} {{}}-->
        Result, $: <span class="m-l-1.5 m-r-4 text-dark fw600"> {{(detail.hire ? Math.abs(parseFloat(detail.hire)) : 0)  > (tableCostsFull[prefix+'_hire_bep'] ? (parseFloat(tableCostsFull[prefix+'_hire_bep'])) : 0) ? '-' : ''}}{{tableCostsFull[prefix+'_results'] | formatNumberZero}}</span>

      </div>
      <div 
        class="inline-block p-b-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis fw600"
        :class="{
          'w-1/2': ifManyNumbers
        }"
      >
<!--        Hire BEP, $: <span class="m-l-1.5 m-r-4 text-dark fw600">{{detail[prefix+'_hire'] | formatNumber}}</span>-->
        Hire BEP, $: <span class="m-l-1.5 m-r-4 text-dark fw600">{{tableCostsFull[prefix+'_hire_bep'] | formatNumberZeroNotAbs}}</span>
      </div>
      <div
        class="inline-block p-b-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis fw600"
        :class="{
          'w-1/2': ifManyNumbers
        }"
      >
<!--        Freight BEP, $: <span class="m-l-1.5 m-r-4 text-dark fw600">{{detail[prefix+'_freight'] | formatNumber}}</span>-->
        Freight BEP, $: <span class="m-l-1.5 m-r-4 text-dark fw600">{{tableCostsFull[prefix+'_freight_bep'] | formatNumberTwo}}</span>
      </div>
      <div
        class="inline-block p-b-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis fw600"
        :class="{
          'w-1/2': ifManyNumbers
        }"
      >
<!--        Voyage Days: <span class="m-l-1.5 m-r-4 text-dark fw600">{{detail[prefix+'_voyage_days'] | formatNumber}}</span>-->
        Voyage Days: <span class="m-l-1.5 m-r-4 text-dark fw600">{{tableCostsFull[prefix+'_voyage_days'] | formatNumberTwo}}</span>
      </div>
      <div class="load-block w100Proc h100Proc border-r-2 position-absolute z-20" v-if="loadTable">
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    loadTable: Boolean,
    fuel: String,
    prefix: String,
    detail: Object
  },
  computed: {
    ...mapState({
      tableCostsFull: state => state.tabsIntake.tableCostsFull
    }),
    ifManyNumbers() {
      let count = 0
      count += this.detail[this.prefix+'_result'] ? this.detail[this.prefix+'_result'].toString().length : 0
      count += this.detail[this.prefix+'_hire'] ? this.detail[this.prefix+'_hire'].toString().length : 0
      count += this.detail[this.prefix+'_freight'] ? this.detail[this.prefix+'_freight'].toString().length : 0
      count += this.detail[this.prefix+'_voyage_days'] ? this.detail[this.prefix+'_voyage_days'].toString().length : 0
      return count > 30
    }
  }
}
</script>

<style lang="scss" scoped>
.dart-mode {
  .circle1 {
    stroke: #FFFFFF;
  }
  .circle2 {
    fill: #FFFFFF;
  }
}
.radio-button {
  &:hover {
    svg {
      circle {
        stroke: var(--color-blue) !important;
      }
    }
  }
}
.load-block {
  backdrop-filter: blur(1.5px);
 }
</style>