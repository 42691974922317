<template>
  <div class="mail-item">
    <div class="name">
      <div class="checkbox-block cursor-pointer position-relative z-index-10" v-on:click="$emit('select')" v-if="$route.path.indexOf('archive') >= 0">
        <svg v-if="!selectedAll" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4444 0.888889H3.55556C2.0828 0.888889 0.888889 2.0828 0.888889 3.55556V12.4444C0.888889 13.9172 2.0828 15.1111 3.55556 15.1111H12.4444C13.9172 15.1111 15.1111 13.9172 15.1111 12.4444V3.55556C15.1111 2.0828 13.9172 0.888889 12.4444 0.888889ZM3.55556 0C1.59188 0 0 1.59188 0 3.55556V12.4444C0 14.4081 1.59188 16 3.55556 16H12.4444C14.4081 16 16 14.4081 16 12.4444V3.55556C16 1.59188 14.4081 0 12.4444 0H3.55556Z" fill="#BDCBD6"/>
        </svg>
        <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 3.55556C0 1.59188 1.59188 0 3.55556 0H12.4444C14.4081 0 16 1.59188 16 3.55556V12.4444C16 14.4081 14.4081 16 12.4444 16H3.55556C1.59188 16 0 14.4081 0 12.4444V3.55556Z" fill="#094172"/>
          <path d="M12.8891 4.46434C12.6107 4.15494 12.1513 4.14637 11.863 4.44518C11.857 4.45143 11.851 4.45782 11.8451 4.46434L6.90151 9.70695L4.34115 7.81322C4.05286 7.51441 3.59347 7.52299 3.31504 7.83239C3.04342 8.13421 3.04342 8.61268 3.31504 8.91451L6.38845 11.3589C6.67185 11.6629 7.1312 11.6629 7.41456 11.3589L12.8712 5.56563C13.1595 5.26677 13.1675 4.77374 12.8891 4.46434Z" fill="white"/>
        </svg>
      </div>
      <p :class="{'pl13': $route.path.indexOf('archive') >= 0}" class="fz13 text-gray">{{type === 'template' ? 'Template name' : type === 'contact' ? 'List Name' : 'Mailing Name'}}</p>
      <div class="d-flex align-items-center  h-max-content">
<!--        <svg :class="{'rotate-180': sort !== (type === 'mail' ? '-template__name' : '-name'), 'active': sort === (type === 'mail' ? '-template__name' : '-name') || sort === (type === 'mail' ? 'template__name' : 'name')}" v-on:click="sort.indexOf('-')>=0 || sort.indexOf((type === 'mail' ? 'template__name' : 'name')) === -1 ? $emit('changeSort', (type === 'mail' ? 'template__name' : 'name')) : $emit('changeSort', (type === 'mail' ? '-template__name' : '-name'))"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow"><path data-v-2a39f82b="" d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"></path></svg>-->
<!--        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path v-on:click="$emit('changeSort', '-name')" :class="{'active': sort === '-name'}" class="cursor-pointer" d="M5.29419 10C5.03262 10 4.90163 10.3335 5.08658 10.5286L8.2924 13.9093C8.40706 14.0302 8.59296 14.0302 8.70761 13.9093L11.9134 10.5286C12.0984 10.3335 11.9674 10 11.7058 10H5.29419Z" fill="#9AA3B0"/>-->
<!--          <path v-on:click="$emit('changeSort', 'name')" :class="{'active': sort === 'name'}" class="cursor-pointer" d="M11.7058 7C11.9674 7 12.0984 6.6665 11.9134 6.47145L8.7076 3.09069C8.59294 2.96977 8.40704 2.96977 8.29239 3.09069L5.08658 6.47145C4.90162 6.6665 5.03262 7 5.29418 7L11.7058 7Z" fill="#9AA3B0"/>-->
<!--        </svg>-->
      </div>
    </div>
    <div class="contact-lists" v-if="type !== 'contact'">
      <p class="fz13 text-gray">Contact Lists</p>
      <div class="d-flex align-items-center  h-max-content">
<!--        <svg :class="{'rotate-180': sort !== '-contact_list__name', 'active': sort === '-contact_list__name' || sort === 'contact_list__name'}" v-on:click="sort.indexOf('-')>=0 || sort.indexOf('contact_list__name') === -1  ? $emit('changeSort', 'contact_list__name') : $emit('changeSort', '-contact_list__name')"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow"><path data-v-2a39f82b="" d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"></path></svg>-->
<!--        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path v-on:click="$emit('changeSort', '-contact_list')" :class="{'active': sort === '-contact_list'}" class="cursor-pointer" d="M5.29419 10C5.03262 10 4.90163 10.3335 5.08658 10.5286L8.2924 13.9093C8.40706 14.0302 8.59296 14.0302 8.70761 13.9093L11.9134 10.5286C12.0984 10.3335 11.9674 10 11.7058 10H5.29419Z" fill="#9AA3B0"/>-->
<!--          <path v-on:click="$emit('changeSort', 'contact_list')" :class="{'active': sort === 'contact_list'}" class="cursor-pointer" d="M11.7058 7C11.9674 7 12.0984 6.6665 11.9134 6.47145L8.7076 3.09069C8.59294 2.96977 8.40704 2.96977 8.29239 3.09069L5.08658 6.47145C4.90162 6.6665 5.03262 7 5.29418 7L11.7058 7Z" fill="#9AA3B0"/>-->
<!--        </svg>-->
      </div>
    </div>
    <div class="subject">
      <p class="fz13 text-gray">{{type === 'contact' ? 'Number of contacts' : 'Subject'}}</p>
      <div class="d-flex align-items-center  h-max-content">
<!--        <svg v-if="type === 'contact'" :class="{'rotate-180': sort !== '-count_emails_list', 'active': sort === '-count_emails_list' || sort === 'count_emails_list'}" v-on:click="sort.indexOf('-')>=0 || sort.indexOf('count_emails_list') === -1 ? $emit('changeSort', 'count_emails_list') : $emit('changeSort', '-count_emails_list')"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow"><path data-v-2a39f82b="" d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"></path></svg>-->
<!--        <svg v-else :class="{'rotate-180': sort !== '-subject', 'active': sort === '-subject' || sort === 'subject'}" v-on:click="sort.indexOf('-')>=0 || sort.indexOf('subject') === -1 ? $emit('changeSort', 'subject') : $emit('changeSort', '-subject')"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow"><path data-v-2a39f82b="" d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"></path></svg>-->

<!--        <svg v-if="type === 'contact'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path v-on:click="$emit('changeSort', '-count_emails_list')" :class="{'active': sort === '-count_emails_list'}" class="cursor-pointer" d="M5.29419 10C5.03262 10 4.90163 10.3335 5.08658 10.5286L8.2924 13.9093C8.40706 14.0302 8.59296 14.0302 8.70761 13.9093L11.9134 10.5286C12.0984 10.3335 11.9674 10 11.7058 10H5.29419Z" fill="#9AA3B0"/>-->
<!--          <path v-on:click="$emit('changeSort', 'count_emails_list')" :class="{'active': sort === 'count_emails_list'}" class="cursor-pointer" d="M11.7058 7C11.9674 7 12.0984 6.6665 11.9134 6.47145L8.7076 3.09069C8.59294 2.96977 8.40704 2.96977 8.29239 3.09069L5.08658 6.47145C4.90162 6.6665 5.03262 7 5.29418 7L11.7058 7Z" fill="#9AA3B0"/>-->
<!--        </svg>-->
<!--        <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path v-on:click="$emit('changeSort', '-subject')" :class="{'active': sort === '-subject'}" class="cursor-pointer" d="M5.29419 10C5.03262 10 4.90163 10.3335 5.08658 10.5286L8.2924 13.9093C8.40706 14.0302 8.59296 14.0302 8.70761 13.9093L11.9134 10.5286C12.0984 10.3335 11.9674 10 11.7058 10H5.29419Z" fill="#9AA3B0"/>-->
<!--          <path v-on:click="$emit('changeSort', 'subject')" :class="{'active': sort === 'subject'}" class="cursor-pointer" d="M11.7058 7C11.9674 7 12.0984 6.6665 11.9134 6.47145L8.7076 3.09069C8.59294 2.96977 8.40704 2.96977 8.29239 3.09069L5.08658 6.47145C4.90162 6.6665 5.03262 7 5.29418 7L11.7058 7Z" fill="#9AA3B0"/>-->
<!--        </svg>-->
      </div>
    </div>
    <div class="text" v-if="type !== 'contact'">
      <p class="fz13 text-gray">Message Text</p>
    </div>
    <div class="date">
      <p class="fz13 text-gray">{{ type !== 'mail' ? 'Created' : 'Sent'}}</p>
      <div class="d-flex align-items-center  h-max-content">
<!--        <svg :class="{'rotate-180': sort !== '-updated_at', 'active': sort === '-updated_at' || sort ==='updated_at'}" v-on:click="sort.indexOf('-')>=0 || sort.indexOf('updated_at') === -1 ? $emit('changeSort', ('updated_at')) : $emit('changeSort', ('-updated_at'))"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow"><path data-v-2a39f82b="" d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"></path></svg>-->

<!--        <svg :class="{'rotate-180': sort !== (type !== 'mail' ? '-created_at' : '-updated_at'), 'active': sort === (type !== 'mail' ? '-created_at' : '-updated_at') || sort === (type !== 'mail' ? 'created_at' : 'updated_at')}" v-on:click="sort.indexOf('-')>=0 || sort.indexOf((type !== 'mail' ? 'created_at' : 'updated_at')) === -1 ? $emit('changeSort', (type !== 'mail' ? 'created_at' : 'updated_at')) : $emit('changeSort', (type !== 'mail' ? '-created_at' : '-updated_at'))"  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow"><path data-v-2a39f82b="" d="M10.3068 10.2916C10.1539 10.1394 9.90656 10.14 9.75437 10.2929L8.39062 11.6632V3.39062C8.39062 3.17488 8.21574 3 8 3C7.78425 3 7.60937 3.17488 7.60937 3.39062V11.6632L6.24562 10.2929C6.09344 10.1399 5.84611 10.1394 5.6932 10.2915C5.54027 10.4437 5.53971 10.6911 5.69187 10.844L7.72312 12.8851C7.72324 12.8852 7.72337 12.8853 7.72349 12.8854C7.87568 13.0379 8.1238 13.0384 8.2765 12.8854C8.27662 12.8853 8.27675 12.8852 8.27687 12.8851L10.3081 10.844C10.4603 10.6911 10.4597 10.4438 10.3068 10.2916Z" fill="#2C2C2C"></path></svg>-->
<!--        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path v-on:click="$emit('changeSort', (type !== 'mail' ? '-created_at' : '-updated_at'))" :class="{'active': sort === (type !== 'mail' ? '-created_at' : '-updated_at')}" class="cursor-pointer" d="M5.29419 10C5.03262 10 4.90163 10.3335 5.08658 10.5286L8.2924 13.9093C8.40706 14.0302 8.59296 14.0302 8.70761 13.9093L11.9134 10.5286C12.0984 10.3335 11.9674 10 11.7058 10H5.29419Z" fill="#9AA3B0"/>-->
<!--          <path v-on:click="$emit('changeSort', (type !== 'mail' ? 'created_at' : 'updated_at'))" :class="{'active': sort === (type !== 'mail' ? 'created_at' : 'updated_at')}" class="cursor-pointer" d="M11.7058 7C11.9674 7 12.0984 6.6665 11.9134 6.47145L8.7076 3.09069C8.59294 2.96977 8.40704 2.96977 8.29239 3.09069L5.08658 6.47145C4.90162 6.6665 5.03262 7 5.29418 7L11.7058 7Z" fill="#9AA3B0"/>-->
<!--        </svg>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mailling-title",
  props: {
    type: String,
    sort: String,
    selectedAll: Boolean,
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.mail-item {
  &>* {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.active {
  path {
    fill: var(--color-blue)
  }
}
</style>