<template>
  <div>
    <folders-list-links class="mt16" :items="counterparties"></folders-list-links>
    <pagination-panel-default @load-more="loadNext" v-if="nextLink" :if-active="showLoadData"></pagination-panel-default>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import FoldersListLinks from "@/components/folders/folders-list-links";
import PaginationPanelDefault from "@/components/ports/paginationDefault.vue";

export default {
  name: "folders-couterparties",
  components: {PaginationPanelDefault, FoldersListLinks},
  props: {
    sort: String,
    search: String,
    routeChange: Number,
  },
  watch: {
    sort: function () {
      this.loadData()
    },
    search: function () {
      this.loadData()
    },
    routeChange: function () {
      this.loadData()
    }
  },
  data() {
    return {
      counterparties: [{name: 'sasdasda', last_updated: 'asd', link: {name: 'Folders - Counterparties Files', params: {id: 3}}}],
      showLoadData: false,
      nextLink: null,
    }
  },
  methods: {
    ...mapActions(['axiosGetClientsForFolder', 'axiosGetMoreCounterparties']),
    ...mapMutations(['setNameSectionFolders']),
    async loadNext() {
      this.showLoadData = true
      let res = await this.axiosGetMoreCounterparties(this.nextLink)
      if(res !== false) {
        this.counterparties = this.counterparties.concat(res.results.map(el=> {
          return {
            id: el.id,
            name: el.company_name,
            updated_at: el.updated_at,
            link: {name: 'Folders - Counterparties Options', params: {is_archive_counterparties: this.$route.params.is_archive_counterparties, detailCounterparties: JSON.stringify({id: el.id, name: el.company_name})}}
          }
        }))
        this.nextLink = res.next
      }
      this.showLoadData = false
    },
    async loadData() {
      // is_archive_counterparties
      this.showLoadData = true
      let res = await this.axiosGetClientsForFolder({params: {ordering: this.sort.replace('name', 'company_name'), search: this.search, is_archived: this.$route.params.is_archive_counterparties, limit: 20}})
      this.counterparties = res.results.map(el=> {
        return {
          id: el.id,
          name: el.company_name,
          updated_at: el.updated_at,
          link: {name: 'Folders - Counterparties Options', params: {is_archive_counterparties: this.$route.params.is_archive_counterparties, detailCounterparties: JSON.stringify({id: el.id, name: el.company_name})}}
        }
      })
      this.nextLink = res.next
      this.showLoadData = false
    },
  },
  mounted() {
    this.setNameSectionFolders('Counterparties')
    this.loadData()
  }
}
</script>

<style scoped>

</style>