export default {
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {
        document.body.addEventListener("keydown", (event)=> {
            if(!this.mailDetailLoader && (event.key == 'ArrowDown' || event.keyCode == 40)) {
                event.preventDefault()
                let table = document.querySelector('.splitter-pane')
                let activeElement = document.querySelector('.table-row.mail-row.active')
                // console.dir(activeElement.offsetTop + 27)
                console.dir(table)
                console.log(activeElement.offsetTop, table.clientHeight)
                if((table.scrollTop + table.clientHeight) < (activeElement.offsetTop + 27*2)) {
                    table.scrollTop = (activeElement.offsetTop - table.clientHeight + 29*2)
                }
               if(this.mailDetailInfo) {
                   let index = this.mailsList.findIndex(a=> a.id == this.mailDetailInfo.id)
                   if(index !== this.mailsList.length-1) {
                       this.getMailDetailInfo(this.mailsList[index+1].id, this.activeFolder)
                   }
               } else {
                   this.getMailDetailInfo(this.mailsList[0].id, this.activeFolder)
               }
            }
            if(!this.mailDetailLoader && (event.key == 'ArrowUp' || event.keyCode == 38)) {
                event.preventDefault()
                let table = document.querySelector('.splitter-pane')
                let activeElement = document.querySelector('.table-row.mail-row.active')
                if(table.scrollTop > activeElement.offsetTop - 54) {
                    table.scrollTop = activeElement.offsetTop - 54
                }
                if(this.mailDetailInfo) {
                    let index = this.mailsList.findIndex(a=> a.id == this.mailDetailInfo.id)
                    if(index !== 0) {
                        this.getMailDetailInfo(this.mailsList[index-1].id, this.activeFolder, false)
                    }
                } else {
                    this.getMailDetailInfo(this.mailsList[0].id, this.activeFolder)
                }
            }
        })
    }
}