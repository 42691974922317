<template>
  <div class="fs_block assets">
    <div class="block__title flex justify-between align-end"><p class="fz20 text-dark fw500">Balance Sheet</p> <span class="fz13 fw500 text-gray">USD</span></div>
    <div class="fs_block__list">
      <div>
        <div class="fs_block__row head">Non-current assets</div>
        <div>
          <div class="fs_block__row" v-if="getRows('non_current_assets').length == 0">No items in this category</div>
          <div class="fs_block__row uppercase" v-else v-for="item in getRows('non_current_assets')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>{{item.amount | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>
        </div>
      </div>

      <div>
        <div class="fs_block__row head">Current assets</div>
        <div>
          <div class="fs_block__row" v-if="getRows('current_assets').length == 0">No items in this category</div>
          <div class="fs_block__row uppercase" v-else v-for="item in getRows('current_assets')" :key="item.financial_statement.name">{{item.financial_statement.name}} <span>{{item.amount | formatNumberTwoEmptyStickABSMinusBrackets}}</span></div>

          <div class="fs_block__row subhead">Total current assets <span>{{getAmount('current_assets') | formatNumberTwoAbsBrackets}}</span></div>
          <div class="fs_block__row subhead">Total assets <span>{{getAmountTotal(['current_assets', 'non_current_assets']) | formatNumberTwoAbsBrackets}}</span></div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['list'],
  computed: {
    ...mapGetters(['toFixed2Number'])
  },
  methods: {
    getRows(key) {
      return this.list.filter(item=>{
        return item.financial_statement.type == key
      })
    },
    getAmount(key) {
      let count = 0
      this.list.forEach(item => {
        if(item.financial_statement.type == key)
          count += item.amount
      })
      return count
      // return this.toFixed2Number(count)
    },
    getAmountTotal(keyArr) {
      let count = 0
      keyArr.forEach(key => {
        this.list.forEach(item => {
          if(item.financial_statement.type == key)
            count += item.amount
        })
      });
      return count
      // return this.toFixed2Number(count)
    },
  }
}
</script>

<style lang="scss" scoped>
.block__title {
  padding: 0 8px 14px 0;
}
</style>