<template>
  <div class="m-t-4">
    <div 
      class="vessel_owners__table_wide bg-white border border-dark-gray rounded-lg"
      :class="{
        'without_trials': !getPermissionsByType('show_accounts_in_all_operations')
      }"
    >
      <div class="bg-blue-dark border-b border-r uppercase p-x-2 p-y-2 fz13 fw500 text-white rounded-tl-lg">
        Excess bunk. on redel.
      </div>
      <div class="bg-blue-dark border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          :isWhite="true"
          v-if="$route.params.subId"
          trialKey="SUBLET_Excess bunker on redelivery"
        />
        <get-trials
          :isWhite="true"
          v-else
          trialKey="Excess bunker on redelivery"
        />
      </div>
      <div class="colspan-3-9 bg-blue-dark border-b rounded-tr-lg"></div>

      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r" v-if="getPermissionsByType('show_accounts_in_all_operations')"></div>
      <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Qntty, mt</div>
      <div class="bg-white-dark border-b border-r text-gray-text fz13 p-x-2 p-y-1">Price, {{activeCurrency}}/mt</div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b border-r"></div>
      <div class="bg-white-dark border-b"></div>

      <div class="border-b border-r uppercase p-x-2 p-y-2 fz13 relative" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_vlsfo}">
        VLSFO
        <switcher 
          class="switcher_owners-row"
          id="bunkers-redel-vlsfo"
          v-model="bunkersData.additional_info.is_vlsfo"
        />
      </div>
      <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_vlsfo}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          v-if="$route.params.subId"
          trialKey="SUBLET_Excess bunker on redelivery:VLSFO"
        />
        <get-trials
          v-else
          trialKey="Excess bunker on redelivery:VLSFO"
        />
      </div>
      <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_vlsfo}">
        <input-format-number
            :fractionDigits="3"
          v-model="bunkersData.additional_info.vlsfo_quantity"
          :inputClass="'p-x-2 p-y-2 '+(!bunkersData.additional_info.is_vlsfo?'text-gray-text':'')"
          placeholder="Enter quantity"
          :disabled="!bunkersData.additional_info.is_vlsfo"
        ></input-format-number>
      </div>
      <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_vlsfo}">
        <input-format-number
          v-model="bunkersData.additional_info.vlsfo_price"
          :inputClass="'p-x-2 p-y-2 '+(!bunkersData.additional_info.is_vlsfo?'text-gray-text':'')"
          placeholder="Enter price"
          :disabled="!bunkersData.additional_info.is_vlsfo"
        ></input-format-number>
      </div>
      <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_vlsfo}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="bunkersData.additional_info.trs_type_vlsfo == 'revenues'"
        >{{bunkersData.vlsfo_debit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_vlsfo}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="bunkersData.additional_info.trs_type_vlsfo == 'expenses'"
        >{{bunkersData.vlsfo_credit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-b border-r">
        <trs-type-btn
          :additional="bunkersData.additional_info"
          type="trs_type_vlsfo"
          active="revenues"
        />
      </div>
      <div class="border-b">
        <trs-type-btn
          :additional="bunkersData.additional_info"
          type="trs_type_vlsfo"
          active="expenses"
        />
      </div>

      <div class="border-r uppercase p-x-2 p-y-2 fz13 relative rounded-bl-lg" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_lsmgo}">
        LSMGO
        <switcher 
          class="switcher_owners-row"
          id="bunkers-redel-lsmgo"
          v-model="bunkersData.additional_info.is_lsmgo"
        />
      </div>
      <div class="border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_lsmgo}" v-if="getPermissionsByType('show_accounts_in_all_operations')">
        <get-trials
          v-if="$route.params.subId"
          trialKey="SUBLET_Excess bunker on redelivery:LSMGO"
        />
        <get-trials
          v-else
          trialKey="Excess bunker on redelivery:LSMGO"
        />
      </div>
      <div class="border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_lsmgo}">
        <input-format-number
            :fractionDigits="3"
          v-model="bunkersData.additional_info.lsmgo_quantity"
          :inputClass="'p-x-2 p-y-2 '+(!bunkersData.additional_info.is_lsmgo?'text-gray-text':'')"
          placeholder="Enter quantity"
          :disabled="!bunkersData.additional_info.is_lsmgo"
        ></input-format-number>
      </div>
      <div class="border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_lsmgo}">
        <input-format-number
          v-model="bunkersData.additional_info.lsmgo_price"
          :inputClass="'p-x-2 p-y-2 '+(!bunkersData.additional_info.is_lsmgo?'text-gray-text':'')"
          placeholder="Enter price"
          :disabled="!bunkersData.additional_info.is_lsmgo"
        ></input-format-number>
      </div>
      <div class="border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_lsmgo}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="bunkersData.additional_info.trs_type_lsmgo == 'revenues'"
        >{{bunkersData.lsmgo_debit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-r" :class="{'bg-gray-light text-gray-text': !bunkersData.additional_info.is_lsmgo}">
        <p 
          class="p-x-2 p-y-2 text-align-right"
          v-if="bunkersData.additional_info.trs_type_lsmgo == 'expenses'"
        >{{bunkersData.lsmgo_credit | formatNumberTwoZeroEmpty}}</p>
      </div>
      <div class="border-r">
        <trs-type-btn
          :additional="bunkersData.additional_info"
          type="trs_type_lsmgo"
          active="revenues"
        />
      </div>
      <div class="">
        <trs-type-btn
          :additional="bunkersData.additional_info"
          type="trs_type_lsmgo"
          active="expenses"
        />
      </div>
    </div>

    <button
      type="button"
      class="m-t-2 flex items-center cursor-pointer text-blue-dark fw500 fz13"
      @click="$emit('on-delete', 'bunkers')"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z"
          fill="#094172"
        />
      </svg>
      Delete Bunkers Section
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import getTrials from "@/components/vessels/owners/edit/getTrials";
import trsTypeBtn from "@/components/vessels/owners/edit/trsTypeBtn";
import inputFormatNumber from '@/components/reuse/inputFormatNumber'
import Switcher from '@/components/reuse/switcher.vue'

export default {
  props: {
    activeCurrency: String,
    bunkersData: Object
  },
  components: {
    getTrials,
    trsTypeBtn,
    inputFormatNumber,
    Switcher
  },
  watch: {
    'bunkersData.additional_info.lsmgo_price': function() {this.getSumm('lsmgo')},
    'bunkersData.additional_info.lsmgo_quantity': function() {this.getSumm('lsmgo')},
    'bunkersData.additional_info.trs_type_lsmgo': function() {this.getSumm('lsmgo')},

    'bunkersData.additional_info.vlsfo_price': function() {this.getSumm('vlsfo')},
    'bunkersData.additional_info.vlsfo_quantity': function() {this.getSumm('vlsfo')},
    'bunkersData.additional_info.trs_type_vlsfo': function() {this.getSumm('vlsfo')}
  },
  computed: {
    ...mapGetters(["getPermissionsByType"])
  },
  methods: {
    getSumm(key) {
      let result =
        this.bunkersData.additional_info[key+'_price'] *
        this.bunkersData.additional_info[key+'_quantity']

      result = result ? result : "";

      if(this.bunkersData.additional_info['trs_type_'+key] == 'revenues'){
        this.bunkersData[key+'_debit'] = result
        this.bunkersData[key+'_credit'] = ''
      }else{
        this.bunkersData[key+'_debit'] = ''
        this.bunkersData[key+'_credit'] = result
      }
    },
    checkIsRow() {
      if(!Object.prototype.hasOwnProperty.call(this.bunkersData.additional_info, 'is_vlsfo')){
        this.$set(this.bunkersData.additional_info, 'is_vlsfo', true)
      }
      if(!Object.prototype.hasOwnProperty.call(this.bunkersData.additional_info, 'is_lsmgo')){
        this.$set(this.bunkersData.additional_info, 'is_lsmgo', true)
      }
    }
  },
  mounted() {
    this.checkIsRow()
  }
};
</script>

<style>
</style>
