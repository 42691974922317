<template>
  <section
    class="calendar-section page_section__wrap bg-gray-lightest"
    :class="{ 'overflow-hidden': !overflowOn }"
  >
    <div
      class="flex h-full relative"
      :class="{ 'overflow-hidden': overflowOn }"
    >
      <!-- Sidebar -->
      <div
        class="w-full calendar-page-aside bg-white border-r border-gray-light"
        :class="{
          'overflow-auto': overflowOn,
        }"
      >
        <date-picker
          inline
          value-type="date"
          class="datepickeer border-gray-light border-b"
          v-model="datepickFrom"
          format="DD.MM.YYYY"
          @change="createEmitFilterAxios"
          @calendar-change="getNewDots()"
          style="width: 224px; display: flex; overflow: hidden"
        ></date-picker>
        <div
          class="checkbox-wrapper flex flex-col p-4 border-gray-light border-b"
        >
          <span class="fz13 fw500 p-b-4">Categories</span>
          <label class="my-custom-checkbox" v-if="getPermissionsByType('can_show_payments_in_the_calendar')">
            <input type="checkbox" v-model="categories.payments" />
            <span class="fz13">Payments</span>
          </label>
          <label class="my-custom-checkbox orange" v-if="getPermissionsByType('can_show_tasks_in_the_calendar')">
            <input type="checkbox" v-model="categories.tasks" />
            <span class="fz13">Tasks</span>
          </label>
          <label class="my-custom-checkbox violet">
            <input type="checkbox" v-model="categories.events" />
            <span class="fz13">Personal Events</span>
          </label>
        </div>
        <div class="employees-search flex flex-col" v-if="isShowMembers">
          <span class="fz13 fw500 p-b-4">Employees</span>
          <myCustom-select
            class="sidebar-select"
            :valueList="filteredMembersList"
            :defaultValue="getFilteredMembersSelected"
            @selected="selectMember($event)"
          ></myCustom-select>
        </div>
        <div class="emploees-list flex flex-col" v-if="isShowMembers">
          <div class="emploees-list__item flex" v-if="currentUser" :class="{disabled: isCurrentUserDisabled}">
            <label class="custom-checkbox">
              <input
                ref="checkbox"
                :value="currentUser"
                :checked="currentUser.checked"
                type="checkbox"
                @input="setCurrentUserFilter($event)"
              />
              <span></span>

              <p class="checkbox-text current-user">
                {{ currentUser.name }}
              </p>
            </label>
          </div>
          <!-- <div
            class="emploees-list__item flex"
            v-for="(user) in members.selected"
            :key="user.id"
          > -->
          <div
            class="emploees-list__item flex"
            v-for="(user) in getFilteredMembersSelected"
            :key="user.id"
          >
            <label class="custom-checkbox">
              <input
                ref="checkbox"
                :value="user"
                :checked="user.checked"
                type="checkbox"
                @input="setMemberFilter($event, user.id)"
              />
              <span></span>

              <p class="checkbox-text">
                {{ user.name }}
              </p>
            </label>
            <span class="del-btn" @click="delSelectedEmployeer(user.id)">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.514236 1.57727C0.216608 1.28258 0.216609 0.80478 0.514236 0.510085C0.811864 0.215389 1.29441 0.215388 1.59204 0.510084L11.4868 10.2961C11.7844 10.5908 11.7844 11.0685 11.4868 11.3632C11.1892 11.6579 10.7066 11.6579 10.409 11.3632L0.514236 1.57727Z"
                  fill="#2C2C2C"
                />
                <path
                  d="M10.4091 0.634002C10.7067 0.339306 11.1893 0.339307 11.4869 0.634002C11.7845 0.928698 11.7845 1.4065 11.4869 1.70119L1.59214 11.4872C1.29451 11.7819 0.811964 11.7819 0.514337 11.4872C0.21671 11.1925 0.21671 10.7147 0.514337 10.42L10.4091 0.634002Z"
                  fill="#2C2C2C"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="flex w-full p-x-6 p-y-4 calendar-content">
        <div class="live-calendar-wrapper">
          <div class="heading-block">
            <button
              class="create-event-button"
              @click="showModalEditClean = true"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3333 5.33332H6.66668V0.666623C6.66668 0.298705 6.36798 0 5.99994 0C5.63202 0 5.33332 0.298705 5.33332 0.666623V5.33332H0.666623C0.298705 5.33332 0 5.63202 0 5.99994C0 6.36798 0.298705 6.66668 0.666623 6.66668H5.33332V11.3333C5.33332 11.7013 5.63202 12 5.99994 12C6.36798 12 6.66668 11.7013 6.66668 11.3333V6.66668H11.3333C11.7013 6.66668 12 6.36798 12 5.99994C12 5.63202 11.7013 5.33332 11.3333 5.33332Z"
                  fill="white"
                />
              </svg>
              Personal Event
            </button>
            <div class="tabs-nav">
              <router-link
                :to="{ name: 'calendar_day' }"
                :class="{ active: $route.path.indexOf('day') >= 0 }"
                class="tab-nav__item"
                >Day</router-link
              >
              <router-link
                :to="{ name: 'calendar_week' }"
                :class="{ active: $route.path.indexOf('week') >= 0 }"
                class="tab-nav__item"
                >Week</router-link
              >
              <router-link
                :to="{ name: 'calendar_month' }"
                :class="{ active: $route.path.indexOf('month') >= 0 }"
                class="tab-nav__item"
                >Month</router-link
              >
            </div>
            <button class="filter-button fz14 fw500" v-on:click="openFilter">
              <svg
                v-if="!applyFilters"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.28634 3.73746C8.22907 3.73746 7.3304 4.45987 7.03965 5.44983H4.63436C4.26432 5.44983 4 5.74415 4 6.11873C4 6.49331 4.29075 6.78763 4.66079 6.78763H7.06608C7.3304 7.77759 8.25551 8.5 9.31277 8.5C10.3965 8.5 11.2952 7.77759 11.5859 6.78763H18.3392C18.7093 6.78763 19 6.49331 19 6.11873C19 5.74415 18.7093 5.44983 18.3392 5.44983H11.5859C11.2687 4.45987 10.37 3.73746 9.28634 3.73746ZM9.31277 5.07525C9.86784 5.07525 10.3436 5.5301 10.3436 6.11873C10.3436 6.70736 9.86784 7.16221 9.31277 7.16221C8.75771 7.16221 8.28194 6.70736 8.28194 6.11873C8.28194 5.5301 8.75771 5.07525 9.31277 5.07525Z"
                  fill="#2C2C2C"
                />
                <path
                  d="M14.2423 9.61377C13.185 9.61377 12.2863 10.3362 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6205 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.6205 18.7093 11.3261 18.3392 11.3261H16.5154C16.2247 10.3362 15.326 9.61377 14.2423 9.61377ZM14.2687 10.9516C14.8238 10.9516 15.2996 11.4064 15.2996 11.995C15.2996 12.5837 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5837 13.2379 11.995C13.2379 11.4064 13.6872 10.9516 14.2687 10.9516Z"
                  fill="#2C2C2C"
                />
                <path
                  d="M9.45815 15.7627C8.40088 15.7627 7.5022 16.4851 7.21145 17.4751H4.66079C4.26432 17.4751 4 17.7694 4 18.144C4 18.5186 4.29075 18.8129 4.66079 18.8129H7.23789C7.5022 19.8029 8.42731 20.5253 9.48458 20.5253C10.5683 20.5253 11.467 19.8029 11.7577 18.8129H18.3392C18.7093 18.8129 19 18.5186 19 18.144C19 17.7694 18.7093 17.4751 18.3392 17.4751H11.7313C11.4405 16.4851 10.5419 15.7627 9.45815 15.7627ZM9.48458 17.1005C10.0396 17.1005 10.5154 17.5554 10.5154 18.144C10.5154 18.7326 10.0396 19.1875 9.48458 19.1875C8.92951 19.1875 8.45374 18.7326 8.45374 18.144C8.48018 17.5554 8.92951 17.1005 9.48458 17.1005Z"
                  fill="#2C2C2C"
                />
              </svg>
              <svg
                v-else
                class="svg-blue"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 6C24 8.20914 22.2091 10 20 10C17.7909 10 16 8.20914 16 6C16 3.79086 17.7909 2 20 2C22.2091 2 24 3.79086 24 6Z"
                  fill="#319FEF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.0068 5.71237H11.5859C11.2687 4.72241 10.37 4 9.28634 4C8.22907 4 7.3304 4.72241 7.03965 5.71237H4.63436C4.26432 5.71237 4 6.00669 4 6.38127C4 6.75585 4.29075 7.05017 4.66079 7.05017H7.06608C7.3304 8.04013 8.25551 8.76254 9.31277 8.76254C10.3965 8.76254 11.2952 8.04013 11.5859 7.05017H14.0916C14.0314 6.70918 14 6.35826 14 6C14 5.90358 14.0023 5.80768 14.0068 5.71237ZM9.31277 5.33779C9.86784 5.33779 10.3436 5.79264 10.3436 6.38127C10.3436 6.9699 9.86784 7.42475 9.31277 7.42475C8.75771 7.42475 8.28194 6.9699 8.28194 6.38127C8.28194 5.79264 8.75771 5.33779 9.31277 5.33779Z"
                  fill="#319FEF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.1809 10.6278C15.9425 10.4309 15.7196 10.2158 15.5143 9.98485C15.1482 9.74985 14.7127 9.61374 14.2423 9.61374C13.185 9.61374 12.2863 10.3361 11.9956 11.3261H4.66079C4.26432 11.3261 4 11.6204 4 11.995C4 12.3696 4.29075 12.6639 4.66079 12.6639H11.9956C12.2599 13.6539 13.185 14.3763 14.2423 14.3763C15.326 14.3763 16.2247 13.6539 16.5154 12.6639H18.3392C18.7093 12.6639 19 12.3696 19 11.995C19 11.9683 18.9985 11.9421 18.9957 11.9163C18.3705 11.811 17.7782 11.6089 17.2346 11.3261H16.5154C16.4412 11.0734 16.3273 10.8381 16.1809 10.6278ZM14.2687 10.9515C14.8238 10.9515 15.2996 11.4064 15.2996 11.995C15.2996 12.5836 14.8238 13.0385 14.2687 13.0385C13.7137 13.0385 13.2379 12.5836 13.2379 11.995C13.2379 11.4064 13.6872 10.9515 14.2687 10.9515Z"
                  fill="#319FEF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.21145 17.4751C7.5022 16.4851 8.40088 15.7627 9.45815 15.7627C10.5419 15.7627 11.4405 16.4851 11.7313 17.4751H18.3392C18.7093 17.4751 19 17.7694 19 18.144C19 18.5185 18.7093 18.8129 18.3392 18.8129H11.7577C11.467 19.8028 10.5683 20.5252 9.48458 20.5252C8.42731 20.5252 7.5022 19.8028 7.23789 18.8129H4.66079C4.29075 18.8129 4 18.5185 4 18.144C4 17.7694 4.26432 17.4751 4.66079 17.4751H7.21145ZM10.5154 18.144C10.5154 17.5553 10.0396 17.1005 9.48458 17.1005C8.92951 17.1005 8.48018 17.5553 8.45374 18.144C8.45374 18.7326 8.92951 19.1874 9.48458 19.1874C10.0396 19.1874 10.5154 18.7326 10.5154 18.144Z"
                  fill="#319FEF"
                />
              </svg>
              Filter
            </button>
          </div>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </div>
      </div>
    </div>
    <calendar-filter
      @applyFilters="setFilters($event)"
      class="filter"
      ref="filter"
    ></calendar-filter>

    <calendarCreatemodal
      :show="showModalEditClean"
      @close="closeModalEditClean"
      :eventID="editEventId"
      :editParentEvent="editParentEvent"
      :isClonedEvent="isClonedEvent"
      :editAllEvents="editAllEvents"
    ></calendarCreatemodal>
  </section>
</template>

<script>
import CalendarFilter from "@/components/calendar/calendarFilter";
import calendarCreatemodal from "@/components/calendar/calendarCreatemodal";
import { mapMutations } from "vuex";
import DatePicker from "vue2-datepicker";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    DatePicker,
    CalendarFilter,
    calendarCreatemodal,
  },
  data() {
    return {
      date: new Date(),
      showModalEditClean: false,
      editEventId: null,
      editParentEvent: null,
      isClonedEvent: false,
      editAllEvents: false,
      searchText: "",
      isBurgerActive: false,
      overflowOn: true,
      datepickFrom: "",
      applyFilters: false,
      currentUser: null,
      members: {
        list: [],
        selected: [],
      },
      categories: {
        payments: true,
        tasks: true,
        events: true,
      },
    };
  },
  methods: {
    getNewDots(){
      setTimeout(() => {
        this.addDots()
      }, 500)  
    },
    ...mapActions([
      "axiosGetCalendarMembersList",
      "axiosGetCalendarClientsList",
      "axiosGetCalendarVesselsList",
      "axiosGetCalendarEventsInfo"
    ]),
    ...mapMutations(["showLoad", "hideLoad", "setCalendarCategoriesFilter", "setCalendarCurrentDate"]),
    ...mapGetters(["getCalendarMembersList"]),
    createEmitFilterAxios(date) {
      this.date = date;
      this.setCalendarCurrentDate(date)
      this.$myEmitter.emit("lodadNewData", date);
      this.addDots()
    },
    async getMembers() {
      let members;
      let currentUser = await JSON.parse(localStorage.getItem("userData")).data.user;
      currentUser.name = `${currentUser.first_name} ${currentUser.last_name}`
      currentUser.checked = true;
      this.currentUser = currentUser;
      
      let storageMembers = localStorage.getItem('membersFilter')
      let storeMembers = this.getCalendarMembersList();
      if (storeMembers) {
        members = storeMembers;
      } else {
        members = await this.axiosGetCalendarMembersList('main calend 2');
      }
      this.members.list = members.results.map((el) => {
        el.checked = true;
        return el;
      });
      if(storageMembers){
        // this.members.selected = JSON.parse(storageMembers).selected;
        this.members.selected = this.getFilteredStoregeMembers;
      }
    },
    selectMember(selectedMembers) {
      this.members.selected = selectedMembers;
    },
   
    delSelectedEmployeer(employeerID) {
      this.members.selected = this.members.selected.filter(
        (item) => item.id != employeerID
      );
    },
    openFilter() {
      this.$refs.filter.showMenu();
    },
    closeModalEditClean() {
      this.showModalEditClean = false;
      this.editEventId = null;
    },
    setFilters(isFiltered) {
      this.applyFilters = isFiltered;
    },
    setCurrentUserFilter(e){
      this.currentUser.checked = e.target.checked;
      this.createFilterString()
    },
    setMemberFilter(e, id){
      let member = this.members.selected.find(item => item.id == id)
      member.checked = e.target.checked;
      this.createFilterString()
    },
    setCookiesFilter(query_string){
      if(this.members.selected.length > 0){
        let members = {
          selected: this.members.selected,
          query_string
        }
        localStorage.setItem('membersFilter', JSON.stringify(members))
      }else{
        localStorage.removeItem('membersFilter')
      }
    },
    createFilterString(){
      let query_string = '?'
      if(this.currentUser.checked){
        query_string += `employees=${this.currentUser.id}&`
      }else{
        query_string = '?'
      }

      if(this.members.selected.length != 0){
        // Показывать события выбранных пользователей
        for (var key in this.members.selected){
          if(this.members.selected[key].checked){
            query_string += `employees=${this.members.selected[key].id}&`
          }
        }
        // let currentUser = JSON.parse(localStorage.getItem("userData"));
        // query_string += `employees=${currentUser.data.user.id}&`
        let arr = query_string.split('&')
        arr.pop()
        let str = arr.join('&')
        this.setCookiesFilter(str)
        this.$myEmitter.emit("lodadNewData", this.date);
      }else{
        this.setCookiesFilter('')
        this.$myEmitter.emit("lodadNewData", this.date);
      }
      this.getNewDots()

    },
    async getCalendarEventsInfo(start, end){
      let data = {
        date_after: start,
        date_before: end
      }
      let response = await this.axiosGetCalendarEventsInfo(data);
      return response;

    },
    async addDots(){
      let daysList = document.querySelectorAll('.mx-table-date .cell');
      let days = await this.getCalendarEventsInfo(daysList[0].getAttribute('title'), daysList[daysList.length - 1].getAttribute('title'))
      daysList.forEach(element => {
        if(element.classList.contains('dot')) element.classList.remove('dot')
        let day = days.find(el => el.date == element.getAttribute('title')).is_event
        if(day){
          element.classList.add('dot')
        }
      });
    }
  },
  async beforeMount() {
    this.getMembers();
  },
  async mounted(){
    if(!this.isShowMembers){
      this.setCookiesFilter('')
    }
    this.$myEmitter.on("updateDots", () => {
      this.addDots();
    });
    this.$myEmitter.on("showEditForm", (data) => {
      this.editEventId = data.id;
      this.editParentEvent = data.parentEvent;
      this.showModalEditClean = true;
      this.isClonedEvent = data.isClonedEvent;
      this.editAllEvents = data.editAllEvents;
    }); 
    this.addDots();
  },
  computed: {
    ...mapGetters(['getPermissionsByType', 'getPermissionsFullByType']),
    filteredMembersList(){
      const ids = new Set(this.getPermissionsFullByType('can_view_employee_calendar_for').users.map(user => user.id));
      return this.members.list.filter(el => {
        return ids.has(el.id) && (this.currentUser.id != el.id)
      })
    },
    getFilteredMembersSelected(){
      const ids = new Set(this.getPermissionsFullByType('can_view_employee_calendar_for').users.map(user => user.id));
      return this.members.selected.filter(el => {
        return ids.has(el.id) && (this.currentUser.id != el.id)
      })
    },
    getFilteredStoregeMembers(){
      const ids = new Set(this.getPermissionsFullByType('can_view_employee_calendar_for').users.map(user => user.id));
      let storageMembers = localStorage.getItem('membersFilter')
      let storegeMemberSelected = JSON.parse(storageMembers).selected;
      return storegeMemberSelected.filter(el => {
        return ids.has(el.id) && (this.currentUser.id != el.id)
      })
    },
    isShowMembers(){
      if(
        this.getPermissionsFullByType('can_view_employee_calendar_for').has_permission 
        // && this.getPermissionsFullByType('can_view_employee_calendar_for').users.length != 0
        ){
          return true
        }else{
          return false
        }
    },
    isCurrentUserDisabled(){
      if(this.members.selected.length != 0){
        return false;
      }else{
        return true;
      }
    },
  },
  watch: {
    categories: {
      handler(value) {
        this.setCalendarCategoriesFilter(value);
        this.$myEmitter.emit("setCategoryFilter");
      },
      deep: true,
    },
    members: {
      handler(val) {
        this.createFilterString()
        this.$myEmitter.emit("lodadNewData", this.date);
        if(val.selected.length == 0) this.currentUser.checked = true;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
#app {
  .custom-checkbox.crop-name {
    .checkbox-text {
      width: 145px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .calendar-page-aside {
    .mx-datepicker-main {
      z-index: 1 !important;
    }
  }
  .cell.dot{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      display: flex;
      width: 5px;
      height: 5px;
      background: var(--color-blue);
      right: 0;
      left: 0;
      margin: auto;
      top: 2px;
      border-radius: 50%;
    }
    &.active{
      &:before{
        background: #fff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.calendar-page-aside {
  max-width: 224px;
  min-width: 224px;
  .mx-datepicker-main {
    border: none;
  }
  .checkbox-wrapper {
    .my-custom-checkbox {
      display: flex;
      position: relative;
      &:not(:last-child) {
        padding-bottom: 14px;
      }

      &:hover {
        cursor: pointer;
      }
      [type="checkbox"] {
        position: absolute;
        opacity: 0;
        z-index: -9999;
      }
      span {
        display: flex;
        align-items: center;
        &:before {
          content: "";
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          width: 18px;
          height: 18px;
          background: #dee3ea;
          margin-right: 8px;
          border-radius: 6px;
        }
      }
      [type="checkbox"]:checked + span:before {
        content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1.02141C11.1868 0.673336 10.67 0.663685 10.3456 0.999847C10.3388 1.00688 10.3321 1.01407 10.3255 1.02141L4.76395 6.91934L1.88355 4.7889C1.55922 4.45274 1.04241 4.46239 0.72918 4.81046C0.423607 5.15001 0.423607 5.68829 0.72918 6.02784L4.18677 8.77773C4.50559 9.1198 5.02236 9.1198 5.34114 8.77773L11.4799 2.26035C11.8042 1.92414 11.8132 1.36948 11.5 1.02141Z' fill='%234B4B4B'/%3E%3C/svg%3E%0A");
      }
      &.orange {
        span:before {
          background: #ffedc3;
        }
        [type="checkbox"]:checked + span:before {
          content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1.02141C11.1868 0.673336 10.67 0.663685 10.3456 0.999847C10.3388 1.00688 10.3321 1.01407 10.3255 1.02141L4.76395 6.91934L1.88355 4.7889C1.55922 4.45274 1.04241 4.46239 0.72918 4.81046C0.423607 5.15001 0.423607 5.68829 0.72918 6.02784L4.18677 8.77773C4.50559 9.1198 5.02236 9.1198 5.34114 8.77773L11.4799 2.26035C11.8042 1.92414 11.8132 1.36948 11.5 1.02141Z' fill='%23785400'/%3E%3C/svg%3E ");
        }
      }
      &.violet {
        span:before {
          background: #e5dbff;
        }
        [type="checkbox"]:checked + span:before {
          content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1.02141C11.1868 0.673336 10.67 0.663685 10.3456 0.999847C10.3388 1.00688 10.3321 1.01407 10.3255 1.02141L4.76395 6.91934L1.88355 4.7889C1.55922 4.45274 1.04241 4.46239 0.72918 4.81046C0.423607 5.15001 0.423607 5.68829 0.72918 6.02784L4.18677 8.77773C4.50559 9.1198 5.02236 9.1198 5.34114 8.77773L11.4799 2.26035C11.8042 1.92414 11.8132 1.36948 11.5 1.02141Z' fill='%233D2E67'/%3E%3C/svg%3E ");
        }
      }
    }
  }
  .employees-search {
    padding: 12px 16px;
    .multiselect__single {
      font-size: 13px;
      line-height: 16px;
      padding-top: 2px;
      color: #9aa3b0;
    }
    .multiselect__input {
      font-size: 13px;
      line-height: 16px;
      color: #9aa3b0;
    }
    .multiselect__element {
      span {
        font-size: 13px;
        line-height: 16px;
      }
    }
    .multiselect__option--selected {
      position: relative;
      display: flex;
      align-items: center;
      .option__name:after {
        content: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9783 0.267503C11.6651 -0.0805703 11.1483 -0.0902217 10.8239 0.245941C10.8172 0.252974 10.8105 0.260161 10.8039 0.267503L4.53496 8.89149L1.38355 6.26105C1.05922 5.92489 0.542408 5.93454 0.22918 6.28262C-0.0763934 6.62217 -0.0763934 7.16045 0.22918 7.5L3.95778 10.7499C4.2766 11.092 4.79337 11.092 5.11214 10.7499L11.9582 1.50645C12.2826 1.17023 12.2915 0.615576 11.9783 0.267503Z' fill='%23319FEF'/%3E%3C/svg%3E ");
        position: absolute;
        background: none;
        right: 6px;
      }
    }
    .option__name {
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .emploees-list {
    .emploees-list__item {
      padding: 0 16px;
      height: 32px;
      position: relative;
      transition: 0.3s;
      display: flex;
      align-items: center;
      .del-btn {
        position: absolute;
        display: flex;
        right: 14px;
        opacity: 0;
        transition: 0.3s;
      }
      &:hover {
        background: var(--color-bgc-page);
        transition: 0.3s;
        cursor: pointer;
        .del-btn {
          opacity: 1;
          transition: 0.3s;
        }
      }
      &.disabled{
        opacity: .5;
        pointer-events: none;
      }
    }
  }
  .custom-checkbox {
    position: relative;
    display: flex;

    &:hover {
      cursor: pointer;
    }
    .checkbox-text {
      margin-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--color-dark-gray);
      &.current-user{
        font-weight: 600;
      }
    }
input {
  position: absolute;
  z-index: -99999;
  opacity: 0;
}
span {
  width: 18px;
  height: 18px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: 6px;
  transition: 0.3s;
  border: 2px solid var(--color-gray-text);
  box-sizing: border-box;
  &:hover {
    transition: 0.3s;
    border-color: var(--color-blue-dark);
  }
}
input:checked + span {
  background: var(--color-blue-dark);
  border-color: var(--color-blue-dark);
  color: var(--color-blue-dark);
  &:after {
    content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1.02141C11.1868 0.673336 10.67 0.663685 10.3456 0.999847C10.3388 1.00688 10.3321 1.01407 10.3255 1.02141L4.76395 6.91934L1.88355 4.7889C1.55922 4.45274 1.04241 4.46239 0.72918 4.81046C0.423607 5.15001 0.423607 5.68829 0.72918 6.02784L4.18677 8.77773C4.50559 9.1198 5.02236 9.1198 5.34114 8.77773L11.4799 2.26035C11.8042 1.92414 11.8132 1.36948 11.5 1.02141Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 10px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    // background: url("~@/assets/img/svg/checked_icn.svg");
  }
}
input:checked + span + .checkbox-text{
    color: var(--color-blue-dark);
}
  }
}
.calendar-content{
  max-width: calc(100% - 276px);
}
.live-calendar-wrapper {
  background: var(--color-card);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .heading-block {
    padding: 12px;
    border-bottom: 1px solid var(--color-card-border);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background: var(--color-card);
    z-index: 5;
    .create-event-button {
      height: 32px;
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: var(--color-blue);
      color: var(--color-white);
      svg {
        margin-right: 6px;
      }
    }
    .tabs-nav {
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid var(--color-blue-dark);
      display: flex;
      margin-left: auto;
      height: 32px;
      box-sizing: border-box;
      .tab-nav__item {
        width: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-blue-dark);
        &:nth-child(2) {
          border-left: 1px solid var(--color-blue-dark);
          border-right: 1px solid var(--color-blue-dark);
        }
        &.active {
          background: var(--color-blue-dark);
          color: var(--color-white);
        }
      }
    }
    .filter-button {
      width: 160px;
      height: 32px;
      border: 1px solid var(--color-blue-dark);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-blue-dark);
      margin-left: 12px;
    }
  }
}
</style>
