import axios from 'axios'

export default {
    actions: {
        axiosCreateNonReport(ctx, data) {
            ctx.commit("showLoad")
            return axios.post(process.env.VUE_APP_URL+"/noon", {
                "reference_number": data.reference_number,
                "time": data.time,
                "hrs_since_last_report": data.hrs_since_last_report,
                "latitude": data.latitude,
                "longitude": data.longitude,
                "course": data.course,
                "speed": data.speed,
                "rpm": data.rpm,
                "slip": data.slip,
                "distance_since_last_report": data.distance_since_last_report,
                "avg_speed_since_last_report": data.avg_speed_since_last_report,
                "brob_hsfo": data.brob_hsfo,
                "brob_vlsfo": data.brob_vlsfo,
                "brob_mdo": data.brob_mdo,
                "brob_lsmgo": data.brob_lsmgo,
                "sea_stade": data.sea_stade,
                "brob_fw": data.brob_fw,
                "consumed_hsfo_24_hrs": data.consumed_hsfo_24_hrs,
                "consumed_vlsfo_24_hrs": data.consumed_vlsfo_24_hrs,
                "consumed_mdo_24_hrs": data.consumed_mdo_24_hrs,
                "consumed_lsmgo_24_hrs": data.consumed_lsmgo_24_hrs,
                "distance_from": data.distance_from,
                "distance_to_go": data.distance_to_go,
                "scavenge_air_pressure": data.scavenge_air_pressure,
                "me_speed_up_to_normal": data.me_speed_up_to_normal,
                "time_zone": data.time_zone,
                "date": data.date,
                "weather": data.weather,
                "next_port_name_eta_wpagwuce": data.next_port_name_eta_wpagwuce,
                "instruted_speed_consumption": data.instruted_speed_consumption,
                "stoppages_delays_ref_number": data.stoppages_delays_ref_number,
                "stoppage_delays_description": data.stoppage_delays_description,
                "total_revolition_counter_24_hrs": data.total_revolition_counter_24_hrs,
                "other_remarks": data.other_remarks,
                "quantity_of_auxiliary_engines_in_operation": data.quantity_of_auxiliary_engines_in_operation,
                "is_archive": false,
                "contract": Number(data.contract),
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(res=> {
                ctx.commit('addNewReports', res.data);
                ctx.commit("hideLoad")
                return true;
            }).catch(()=> {
                ctx.commit("hideLoad")
                return false;
            })
        },
        axiosChangeNonReport(ctx, data) {
            ctx.commit("showLoad")
            return axios.patch(process.env.VUE_APP_URL+"/noon/" + data.id, {
                "reference_number": data.reference_number,
                "time": data.time,
                "hrs_since_last_report": data.hrs_since_last_report,
                "latitude": data.latitude,
                "longitude": data.longitude,
                "course": data.course,
                "speed": data.speed,
                "rpm": data.rpm,
                "slip": data.slip,
                "sea_stade": data.sea_stade,
                "distance_since_last_report": data.distance_since_last_report,
                "avg_speed_since_last_report": data.avg_speed_since_last_report,
                "brob_hsfo": data.brob_hsfo,
                "brob_vlsfo": data.brob_vlsfo,
                "brob_mdo": data.brob_mdo,
                "brob_lsmgo": data.brob_lsmgo,
                "brob_fw": data.brob_fw,
                "consumed_hsfo_24_hrs": data.consumed_hsfo_24_hrs,
                "consumed_vlsfo_24_hrs": data.consumed_vlsfo_24_hrs,
                "consumed_mdo_24_hrs": data.consumed_mdo_24_hrs,
                "consumed_lsmgo_24_hrs": data.consumed_lsmgo_24_hrs,
                "distance_from": data.distance_from,
                "distance_to_go": data.distance_to_go,
                "scavenge_air_pressure": data.scavenge_air_pressure,
                "me_speed_up_to_normal": data.me_speed_up_to_normal,
                "time_zone": data.time_zone,
                "date": data.date,
                "weather": data.weather,
                "next_port_name_eta_wpagwuce": data.next_port_name_eta_wpagwuce,
                "instruted_speed_consumption": data.instruted_speed_consumption,
                "stoppages_delays_ref_number": data.stoppages_delays_ref_number,
                "stoppage_delays_description": data.stoppage_delays_description,
                "total_revolition_counter_24_hrs": data.total_revolition_counter_24_hrs,
                "other_remarks": data.other_remarks,
                "quantity_of_auxiliary_engines_in_operation": data.quantity_of_auxiliary_engines_in_operation,
                "is_archive": false,
                "contract": Number(data.contract),
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(res=> {
                ctx.commit('changeReports', res.data);
                ctx.commit("hideLoad")
                return res.data;
            }).catch(()=> {
                ctx.commit("hideLoad")
                return false;
            })
        },
        axiosGetNoReports(ctx, data) {

            ctx.commit('showLoad')
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenReport")
            ctx.commit("cancelPostReport", ct);
            return axios.get(process.env.VUE_APP_URL+"/noon", {
                params: {
                    limit: 20,
                  is_archive: data.is_archive,
                    date_after: data.date_after,
                    date_before: data.date_before,
                },
                cancelToken: ct.token,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(response=> {
                ctx.commit('hideLoad')
                ctx.commit("setNextLink", response.data.next)
                ctx.commit("setListNoReports", response.data.results)
            }).catch(()=> {
                ctx.commit('hideLoad')
            })
        },
        axiosLoadMoreNonReports(ctx, link) {

            return axios.get(link, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(res=> {
                ctx.commit("setNextLink", res.data.next)
                ctx.commit("addNoNReports", res.data.results)
            })
        },
        async axiosArchiveNonReport(ctx, id) {
            return await axios.patch(process.env.VUE_APP_URL+"/noon/"+ id, {
                "is_archive": true,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(response=> {
                ctx.commit("deleteReport", response.data.id)
            })
        },
        async axiosUnarchiveNonReport(ctx, id) {
            return await axios.patch(process.env.VUE_APP_URL+"/noon/"+ id, {
                "is_archive": false,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken
                }
            }).then(response=> {
                ctx.commit("deleteReport", response.data.id)
            })
        },
    },
    mutations: {
        addNoNReports(state, data) {
            state.listNoReports = state.listNoReports.concat(data)
        },
        setListNoReports(state, data) {
            state.listNoReports = data
        },
        deleteReport(state, id) {
            state.listNoReports = state.listNoReports.filter(a=> a.id !== id);
        },
        changeReports(state, data) {
            state.listNoReports = state.listNoReports.map(a=> {
                if(a.id === data.id) {
                    a = data;
                }
                return a;
            })
        },
        addNewReports(state, data) {
            state.listNoReports = [data].concat(state.listNoReports);
        },
        setNextLink(state, link) {
            state.linkNext = link;
        },
        cancelPostReport(state, data) {
            state.cancelTokenSourceReport = data;
        },
        cancelTokenReport(state) {
            if(state.cancelTokenSourceReport !== null) {
                state.cancelTokenSourceReport.cancel();
            }
        },
    },
    state: {
        listNoReports: [],
        linkNext: null,
        cancelTokenSourceReport: null,
    },
    getters: {
        getListNoReports(state) {
            return state.listNoReports;
        },
        getLinkNextNonReport(state) {
            return state.linkNext
        },
    }
}