<template>
  <div class="flex h-full overflow-hidden">
    <div class="max-w-258 w-full flex-shrink-0 border-r-2 border-gray-text h-full overflow-auto">
      <div class="flex items-center justify-between p-l-6 p-y-2 p-r-4 border-b border-gray-light">
        <h1 class="fz18 fw500">Charterers</h1>
        <drop-menu-create-contract></drop-menu-create-contract>
      </div>
      <router-link
        v-for="cargo in (!contract.is_archive ? contract.cargo_contracts.filter(el=> !el.is_archive) : contract.cargo_contracts)"
        :key="cargo.id +'sdsd'"
        :to="`/vessels/${$route.params.id}/open/charterers/${cargo.id}/`"
        active-class="bg-gray-dark"
        class="block border-0 border-b border-gray-light p-l-6 p-r-4 p-y-2 charterer_list__link"
      >
        <span class="flex items-center justify-between m-b-1 text-gray-text">
          <span class="fz13">CP Date {{cargo.cp_date | formatDateFromCpDateWithotTimeZome}}</span>
          <span class="fz12">{{cargo.contract_type == 'CARGO' || cargo.contract_type == 'VOYAGE_CHARTERER' ? 'VOYAGE' : 'SUBLET'}}</span>
        </span>
        <span class="block m-b-1 fz14 fw500 text-dark" v-if="cargo.owner">{{cargo.owner.company_name}}</span>
        <charterer-balance-type :balanceType="cargo.balance_type" @on-change="(type) => {onChangeType(cargo, type)}"/>
      </router-link>
    </div>
    <div class="w-full overflow-auto">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import DropMenuCreateContract from "@/components/vessels/vessel-detail-chat/drop-menu-create-contract";
import chartererBalanceType from "@/components/vessels/charterer/chartererBalanceType";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    DropMenuCreateContract,
    chartererBalanceType
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      contract: state => state.contract.activeContract
    })
  },
  methods: {
    ...mapActions(['axiosChangeContract']),
    onChangeType(cargo, type) {
      //console.log('lol1', cargo, type);
      this.axiosChangeContract({
        id: cargo.id,
        data: {
          balance_type: type
        }
      }).then(response => {
        if(response){
          let changesContract = this.contract.cargo_contracts.find(el=>el.id==cargo.id)
          if(changesContract) changesContract.balance_type = type
        }
      })
    }
  },
}
</script>

<style lang="scss">
.charterer_list__link{
  &:hover{
    .text-gray-text,
    .text-dark{
      color: var(--color-blue-dark) !important;
    }
    .bg-gray-lightest{
      background-color: var(--color-gray-dark) !important;
    }
  }
}
</style>