<template>
  <section class="wrap-page" :class="{'cash-flow-list': $route.path.indexOf('cashflow') >= 0}">
<!--    <payment-modal-duplicate-or-edit-payment ref="modalDupOrEd" @needRender="renderList"></payment-modal-duplicate-or-edit-payment>-->
<!--    <payment-modal-create ref="modalCreate" @needRender="renderList"></payment-modal-create>-->
    <payment-modal-delete @render="renderList" ref="modalDelete"></payment-modal-delete>
    <div class="container">
      <payment-panel-select-period :activeFilter="filterData && filterData.filter_active" :shortFilterView="true" class="panel-select-period" :hideLeftText="true" :createButtonRight="true" @emitNote="emitNote" @createPayment="showModalCreate" @clickFilter="openFilter" @eventExportXLSX="downloadXLSXListPayment" @eventExportPDF="downloadPDFListPayment" :cash-flow="true" @changeDate="emitChangeDate"></payment-panel-select-period>
      <payment-list-component v-if="getShowPaymentList()" v-click-outside="focusFalse" @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" ref="paymentList" @activeList="downloadXLSXListPayment" @HtmlCode="PDFHtmlCode" class="payment-list cashflow-page-list" @clickDelete="clickDelete" @clickCreate="showModalCreate" @clickEditPaymentCard="clickEditPaymentCard" @clickDuplicatePaymentCard="clickDuplicatePaymentCard"></payment-list-component>
      <virtual-payment-list @changeOrdering="changeOrdering" @scrollBottomEnd="getNextLinkPayment && getPaymentListOriginal.length !== 0 && !showLoader ? paymentNext() : null" v-else @changeOrderingStatus="changeOrderingStatus" @changeOrderingDueDate="changeOrderingDueDate" ref="paymentList"></virtual-payment-list>
      <!--      <pre>{{getPaymentList}}</pre>-->
      <loader-for-autoload v-if="showLoader && getNextLinkPayment && getPaymentListOriginal.length !== 0"></loader-for-autoload>
      <!--      <pagination-panel-default class="pagination-panel" @load-more="paymentNext()" :if-active="showLoader" v-if="getNextLinkPayment && getPaymentListOriginal.length !== 0"></pagination-panel-default>-->
      <div class="pb55" v-else></div>
    </div>
    <payment-gross-net></payment-gross-net>
    <payment-list-filter @changeFilter="changeFilter" class="filter" ref="filter"></payment-list-filter>
  </section>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import PaymentListComponent from "@/components/payment/payment-list/payment-list-component";
import PaymentPanelSelectPeriod from "@/components/payment/payment-panel-select-period";
import Vue from 'vue';
import moment from 'moment';
import PaymentListFilter from "@/components/payment/payment-list-filter/payment-list-filter";
import PaymentModalDelete from "@/components/payment/payment-modal-delete";
import axios from "axios";
import PaymentGrossNet from "@/components/payment/payment-gross-net";
import VirtualPaymentList from "@/components/payment/payment-list/virtual-payment-list";
import LoaderForAutoload from "@/components/loader-for-autoload";
Vue.prototype.$moment = moment;
export default {
  name: "cashFlowList",
  components: {
    LoaderForAutoload,
    VirtualPaymentList,
    PaymentGrossNet,
    PaymentModalDelete,
    PaymentListFilter,
    PaymentPanelSelectPeriod,
    PaymentListComponent},
  computed: {
    ...mapGetters(["getFilterDataPaymentAxios", "getInfoDetailPayment", "getPermissionsByType", "getPaymentList", "getPaymentListOriginal", "getDataPaymentModal", "getNextLinkPayment"]),
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.$store.getters.getPermissionsByType("show_cashflow_list") === false && vm.$store.getters.getPermissionsForUser.length !== 0) {
        next(from);
      }
    })
  },
  data() {
    return {
      due_date_ordering_status: true,
      status_ordering: true,
      element: null,
      keyRender: -1000,
      dateSelected: [],
      filterData: null,
      paymentCheckboxActive: false,
      paymentCheckboxIncomeActive: false,
      showLoader: false,
      orderingName: '',
    }
  },
  methods: {
    ...mapActions(['axiosPaymentNextLinkPdf', "axiosGetPayment", "axiosGetXLSX", "axiosGetPDF", "axiosPaymentNextLink"]),
    ...mapMutations(['showLoad', 'hideLoad', 'setPaymentsList', 'setNextLinkPayment', 'setFilterDataPaymentAxios', "cancelPostPayment", "setDataPaymentModal"]),
    getShowPaymentList() {
      return false
    },
    focusFalse() {
      this.$refs.paymentList.focusFalse()
    },
    changeOrderingStatus(data) {
      this.status_ordering = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrderingDueDate(data) {
      this.due_date_ordering_status = data;
      this.orderingName = ''
      this.axiosChangeGetList();
    },
    changeOrdering(ordering) {
      this.orderingName = ordering
      this.axiosChangeGetList();
    },
    paymentNext() {
      this.showLoader = true;
      this.axiosPaymentNextLink().then(()=> {
        this.showLoader = false;
      })
    },
    emitNote() {
      this.$refs.paymentList.addNoteToPayment();
    },
    async downloadPDFListPayment() {
      if(this.getNextLinkPayment) {
        this.showLoad()
        await this.axiosPaymentNextLinkPdf().then(()=> {
        })
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadPDF()
      }, 1000)
    },
    getActiveColumsList() {
      this.$refs.paymentList.emitGetActiveList();
    },
    async downloadXLSXListPayment() {
      if(this.getNextLinkPayment) {
        this.showLoad()
        await this.axiosPaymentNextLinkPdf().then(()=> {
        })
        this.hideLoad()
      }
      setTimeout(()=> {
        this.$refs.paymentList.downloadXML()
      }, 500)
    },
    getTextBankAndCashAccounts(payment) {
      if(payment.cash !== null) {
        return {name: payment.cash.name, id: payment.id, id_cash: payment.cash.id, type: 'cash'}
      } else if(payment.bank !== null) {
        return {name: payment.bank.bank, id: payment.id, id_cash: payment.bank.id, type: 'bank'}
      } else {
        return {name: "-", id: payment.id, id_cash: "", type: 'bank'}
      }
    },
    getTextForStatus(str) {
      if(str.length) {
        str = str.replaceAll("_", " ");
        str = str[0].toUpperCase() + str.slice(1);
        return str
      }
      return "-"
    },
    getTextAccount(a) {
      if(a!== null && a!== undefined) {
        if(a.account !== null && a.account !== undefined) {
          if(a.account.length) {
            return (""+a.account.map(ac=> {return ac.id + ": " + ac.name + " "})).replaceAll(",", "")}
        }
      }
      return  "-";
    },
    PDFHtmlCode(codePage) {
      this.axiosGetPDF({html: codePage, name: "pdf"}).then(response=> {
        let data = response.data;
        // window.open("data:application/pdf;base64," + encodeURI(response.data));
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
          var byteCharacters = atob(data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: 'application/pdf'});
          //var blob_iframe = document.querySelector('#blob-src-test');
          //blob_iframe.src = blob_url;
          window.navigator.msSaveOrOpenBlob(blob, "pdf");
        }
        else { // much easier if not IE
          // this.filePath = "data:application/pdf;base64," + data, '', "height=600,width=800"
          // window.open("data:application/pdf;base64," + data);
          let link = "data:application/pdf;base64," + data;
          axios.get(link, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                let name = "Payment List"
                if(this.$route.path.indexOf('accounting-invoice') >= 0) {
                  name = "Account Invoice"
                }
                if(this.$route.path.indexOf('cash')>=0) {
                  name = 'CashFlow List'
                }
                if(this.$route.path.indexOf('instructions') >= 0) {
                  name = "Payment Instructions List"
                }
                link.download = name
                link.click()
                URL.revokeObjectURL(link.href)
              }).catch(console.error)
        }
      })
    },
    getFormatDate(date) {
      if(date !==null) {
        return "" + moment(date).format('DD.MM.YYYY')
      } else {
        return "-"
      }
    },
    changeStatusCheckboxPayment(status) {
      if(status) {
        this.$refs.filter.selectPaymentCheckboxes();
      } else {
        this.$refs.filter.clearPaymentCheckboxes();
      }
    },
    changeStatusCheckboxIncome(status) {
      if (status) {
        this.$refs.filter.selectIncomeCheckboxes();
      } else {
        this.$refs.filter.clearIncomeCheckboxes();
      }
    },
    changeFilter(data) {
      this.paymentCheckboxIncomeActive = data.incomeCheckbox.length !== 0;
      this.paymentCheckboxActive = data.paymentCheckbox.length !== 0;
      this.filterData = data;
      this.axiosChangeGetList();
    },
    axiosChangeGetList() {
      let before = "";
      // let after = "";
      if(""+this.dateSelected[0] !== 'null' && this.dateSelected[0].length !== 0) {
        before = ""+moment(this.dateSelected [0]).utcOffset(0, true).format("YYYY-MM-DD")+ " 00:00:00";
      }
      // if(""+this.dateSelected[1] !== 'null' && this.dateSelected[1].length !== 0) {
      //   after = ""+moment(this.dateSelected [1]).utcOffset(0, true).format("YYYY-MM-DD")+ " 23:59:59";
      // }
      let type = "payment";
      if(this.$route.fullPath.indexOf("accounting-invoice") >= 0) {
        type = "invoice";
      }
      let orderExecuteDate = ''
      if(this.status_ordering) {
        orderExecuteDate += '-status'
      } else {
        orderExecuteDate += 'status'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-paid_date'
      } else {
        orderExecuteDate += ',paid_date'
      }
      if(!this.due_date_ordering_status) {
        orderExecuteDate += ',-execute_date'
      } else {
        orderExecuteDate += ',execute_date'
      }
      orderExecuteDate = this.orderingName ? this.orderingName : orderExecuteDate
      this.setFilterDataPaymentAxios({ordering: orderExecuteDate, before: before,after: null, filter: this.filterData, type: type})
      this.axiosGetPayment({ordering: orderExecuteDate, before: before,after: null, filter: this.filterData, type: type}).then(()=> {
        this.$refs.paymentList.setDataPayment(this.getPaymentListOriginal)
      });
    },
    openFilter() {
      this.$refs.filter.showMenu();
    },
    clickEditPaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "edit");
    },
    clickDuplicatePaymentCard(id) {
      this.$refs.modalDupOrEd.openModal(id, "duplicate");
    },
    emitChangeDate(date) {
      this.cancelPostPayment();
      this.dateSelected = date;

      this.axiosChangeGetList();
    },
    showModalCreate() {
      this.$refs.modalCreate.openModal();
      this.keyRender++;
    },
    renderList() {
      this.emitChangeDate(this.dateSelected);
    },
    clickDelete(id) {
      this.$refs.modalDelete.openModal(id);
    },
  },
  mounted() {
    // alert(this.getPermissionsByType("show_cashflow_list"))

    if(this.getDataPaymentModal !== null) {
      if(this.getDataPaymentModal === 'duplicate') {
        this.$refs.modalDupOrEd.openModalWithDetailView(this.getInfoDetailPayment, "duplicate");
      } else {
        this.$refs.modalDupOrEd.openModalWithDetailView(this.getInfoDetailPayment, "edit");
      }
      this.setDataPaymentModal(null);
    }
    // if(!this.getPaymentList.length) {
    //   this.axiosGetPayment();
    // }
  }
}
</script>

<style scoped lang="scss">
  @import "cashFlowList";
</style>