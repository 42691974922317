<template>
  <div class="fs_wrap" v-if="permissions.length && getPermissionsByType('show_financial_statement')">
    <div class="fs_col">
      <button class="blue-button fz13 fw500 position-relative h34 w140">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.0335 5C13.0335 4.6134 12.7201 4.3 12.3335 4.3C11.9469 4.3 11.6335 4.6134 11.6335 5H13.0335ZM12.3335 14L11.8394 14.4959C12.1125 14.768 12.5544 14.768 12.8276 14.4959L12.3335 14ZM16.8276 10.5102C17.1014 10.2373 17.1022 9.79412 16.8293 9.52026C16.5565 9.2464 16.1132 9.24561 15.8394 9.51848L16.8276 10.5102ZM8.82756 9.51848C8.5537 9.24561 8.11048 9.2464 7.83761 9.52026C7.56473 9.79412 7.56553 10.2373 7.83939 10.5102L8.82756 9.51848ZM19.7002 16C19.7002 15.6134 19.3868 15.3 19.0002 15.3C18.6136 15.3 18.3002 15.6134 18.3002 16H19.7002ZM6.03348 16C6.03348 15.6134 5.72008 15.3 5.33348 15.3C4.94689 15.3 4.63348 15.6134 4.63348 16H6.03348ZM11.6335 5V14H13.0335V5H11.6335ZM12.8276 14.4959L16.8276 10.5102L15.8394 9.51848L11.8394 13.5041L12.8276 14.4959ZM12.8276 13.5041L8.82756 9.51848L7.83939 10.5102L11.8394 14.4959L12.8276 13.5041ZM17.0002 18.3H7.33349V19.7H17.0002V18.3ZM18.3002 16V17H19.7002V16H18.3002ZM6.03348 17V16H4.63348V17H6.03348ZM7.33349 18.3C6.61551 18.3 6.03348 17.718 6.03348 17H4.63348C4.63348 18.4912 5.84232 19.7 7.33349 19.7V18.3ZM17.0002 19.7C18.4913 19.7 19.7002 18.4912 19.7002 17H18.3002C18.3002 17.718 17.7181 18.3 17.0002 18.3V19.7Z" fill="white"/>
        </svg>
        Export
        <fs-export class="button-export-drop" @downloadXLSX="downloadXLSX" @downloadPDF="downloadPDF" />
      </button>
<!--      <fs-title class="mt12" title=""></fs-title>-->
      <!--      <fs-period @change-date="changeDateLeft" :savePeriod="saveLeftPeriod" :saveDate="saveLeftDate"/>-->
      <fs-block-assets class="mt12 all-uppercase" v-if="fsLeft" :list="fsLeft" />
      <fs-block-equity class="mt12 all-uppercase" v-if="fsLeft" :list="fsLeft" />
    </div>
    <div class="fs_col all-uppercase">
      <div class="flex justify-end">
        <chose-trial-balance-period ref="choseDate" :fs="true" @selectPeriod="(data)=> {this.changeDateRight(data.period, data.date); this.changeDateLeft(data.period, data.date)}"></chose-trial-balance-period>
      </div>
<!--      <fs-title class="mt12" title=""></fs-title>-->
<!--      <fs-period @change-date="changeDateRight" :savePeriod="saveRightPeriod" :saveDate="saveRightDate"/>-->
      <fs-block-profit class="mt12" v-if="fsRight" :list="fsRight" :period="rightPeriod"/>
    </div>

    <template>
      <div>
        <vue-html2pdf
            @hasDownloaded="hasDownloaded"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1745"
            filename="Financial Statement"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            class="pdf"
            ref="html2Pdf"
        >
          <div slot="pdf-content">
            <div class="">
              <fs-title title="Balance Sheet"></fs-title>
<!--              <fs-period @change-date="changeDateLeft" :savePeriod="saveLeftPeriod" :saveDate="saveLeftDate"/>-->
              <fs-block-assets v-if="fsLeft" :list="fsLeft" />
              <fs-block-equity v-if="fsLeft" :list="fsLeft" />
            </div>
            <div class="">
              <fs-title title="Profit and Loss"></fs-title>
<!--              <fs-period @change-date="changeDateRight" :savePeriod="saveRightPeriod" :saveDate="saveRightDate"/>-->
              <fs-block-profit v-if="fsRight" :list="fsRight" :period="rightPeriod" />
            </div>
          </div>
        </vue-html2pdf>

      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'

import fsTitle from '@/components/accounts/fs/fsTitle'
import fsExport from '@/components/accounts/fs/fsExport'
import fsBlockAssets from '@/components/accounts/fs/fsBlockAssets'
import fsBlockEquity from '@/components/accounts/fs/fsBlockEquity'
import fsBlockProfit from '@/components/accounts/fs/fsBlockProfit'
import Vue from 'vue'
import VueHtml2pdf from 'vue-html2pdf'
import ChoseTrialBalancePeriod from "@/components/trial-balance/chose-trial-balance-period";
Vue.use(VueHtml2pdf)
export default {
  components: {
    ChoseTrialBalancePeriod,
    fsTitle,
    fsExport,
    fsBlockAssets,
    fsBlockEquity,
    fsBlockProfit,
    VueHtml2pdf
  },
  data() {
    return {
      rightPeriod: 'all',
      saveLeftPeriod: '',
      saveLeftDate: '',
      saveRightPeriod: '',
      saveRightDate: '',
    }
  },
  watch: {
    permissions: function(val) {
      if(val.length && !this.getPermissionsByType('show_financial_statement'))
        this.$router.replace({name: 'employees'})
    }
  },
  computed: {
    ...mapGetters(['toFixed2Number', 'getPermissionsByType', 'getDartMode']),
    ...mapState({
      permissions: state => state.confirmation.permissionsForUser,
      fsLeft: state => state.fs.fsLeft,
      fsRight: state => state.fs.fsRight
    })
  },
  methods: {
    ...mapActions(["axiosGetXLSX", 'axiosGetFsBalance', 'axiosGetFsBalanceLeft', 'axiosGetFsBalanceRight']),
    ...mapMutations(["setLoaderPDF", 'showLoad', 'hideLoad', 'setDartMode', 'setTurnOnDarkModeAfterDownloadPDF']),
    hasDownloaded() {
      this.hideLoad();
    },
    getRows(key) {
      return this.fsLeft.filter(item=>{
        return item.financial_statement.type == key
      })
    },
    getValueFromXml(value) {
      if((""+value).length !== 0 && value !== null && value !== 0 && value !== undefined) {
        if(moment(""+value, "YYYY-MM-DD HH:mm:ss", true).isValid() || moment(""+value, "YYYY-MM-DD HH:mm", true).isValid() || moment(""+value, "YYYY-MM-DD", true).isValid()) {
          return moment(value).format("DD.MM.YYYY")
        }
        if(!isNaN(value)) {
          let bool = false;
          if(value<0) {
            bool = true
          }
          let fixedNumber = value ? value : 0
          fixedNumber = fixedNumber.toLocaleString('ru', {minimumFractionDigits: 4, maximumFractionDigits: 4}).replace(',', ', ').replaceAll(".", ",")
          if(bool) {
            return '('+fixedNumber*(-1) + ')'
          }
          return fixedNumber
        }
        return ""+value
      }
      return null
    },
    getAmount(key) {
      let count = 0
      this.fsLeft.forEach(item => {
        if(item.financial_statement.type == key)
          count += item.amount
      })
      return this.toFixed2Number(count)
    },
    getAmountTotal(keyArr) {
      let count = 0
      keyArr.forEach(key => {
        this.fsLeft.forEach(item => {
          if(item.financial_statement.type == key)
            count += item.amount
        })
      });
      return this.toFixed2Number(count)
    },
    getSubRows(key) {
      return this.fsRight.filter(item=>{
        return (item.financial_statement.type == 'operating_expenses' && item.financial_statement.subtype == key)
      })
    },
    getRows1(key) {
      return this.fsRight.filter(item=>{
        return item.financial_statement.type == key
      })
    },
    getSubAmount(key) {
      let count = 0
      this.fsRight.forEach(item => {
        if(item.financial_statement.type == 'operating_expenses' && item.financial_statement.subtype == key)
          count += item.amount
      })
      return this.toFixed2Number(count)
    },
    downloadXLSX() {
      let formAxios= {
        sheet_title: "Payment List",
        file_name: "Payment List",
        data: []
      }
      if(this.fsLeft) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["ASSETS", "USD"]})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Non-current assets"]})
        this.getRows('non_current_assets').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Current assets"]})
        this.getRows('current_assets').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Total current assets", this.getValueFromXml(this.getAmount('current_assets'))]})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Total assets", this.getValueFromXml(this.getAmountTotal(['current_assets', 'non_current_assets']))]})
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Equity and liablities", "USD"]})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Equity attributable to owners of the parent"]})
        this.getRows('equity_attributable_to_owners_of_the_parent').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Total equity", this.getValueFromXml(this.getAmount('equity_attributable_to_owners_of_the_parent'))]})
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Non-current liabilities"]})
        this.getRows('non_current_liabilities').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Current liabilities"]})
        this.getRows('current_liabilities').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Total liabilities", this.getAmount('current_liabilities')]})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Total equity and liabilities", this.getAmountTotal(['equity_attributable_to_owners_of_the_parent', 'non_current_liabilities', 'current_liabilities'])]})
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
      }
      if(this.fsRight) {
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Revenue from contracts with customers"]})
        this.getRows1('revenue_from_contracts_with_customers').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Operating expenses"]})
        this.getSubRows('general').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        formAxios.data.push({id: formAxios.data.length + 1, values: []})
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Operating profit", this.getValueFromXml(this.getSubAmount('operating_profit'))]})
        this.getSubRows('operating_profit').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        formAxios.data.push({id: formAxios.data.length + 1, values: ["Profit before tax", this.getValueFromXml(this.getSubAmount('profit_before_tax'))]})
        this.getSubRows('profit_before_tax').map(item=> {
          formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        })
        // this.getSubRows('general').map(item=> {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        // })
        // this.getSubRows('general').map(item=> {
        //   formAxios.data.push({id: formAxios.data.length + 1, values: [this.getValueFromXml(item.financial_statement.name), this.getValueFromXml(this.toFixed2Number(item.amount)), this.getValueFromXml(item.financial_statement.created_at)]})
        // })

      }
      // if() {
      //
      // }
      this.getValueFromXml()
      this.axiosGetXLSX(formAxios).then(response => {
        let link = response.data;
        function download(content, contentType, fileName) {
          var link1 = document.createElement("a");
          link1.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
          link1.download = fileName;
          link1.click();
        }
        download(link, "application/vnd.ms-excel", "Financial Statement.xls")
      });
    },
    downloadPDF() {
      this.showLoad()
      this.setTurnOnDarkModeAfterDownloadPDF(this.getDartMode)
      this.setDartMode(false)
      setTimeout(()=>{this.$refs.html2Pdf.generatePdf()}, 1000)
    },
    getTime(period, date) {
      if(date){
        return [moment(date[0], 'DD.MM.YYYY').format('YYYY-MM-DD'), moment(date[1], 'DD.MM.YYYY').format('YYYY-MM-DD')]
      }else if(period == 'day'){
        return [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
      }else if(period == 'week'){
        return [moment(new Date()).weekday(1).format('YYYY-MM-DD'), moment(new Date()).weekday(7).format('YYYY-MM-DD')]
      }else if(period == 'month'){
        return [moment(new Date()).startOf('month').format('YYYY-MM-DD'), moment(new Date()).endOf('month').format('YYYY-MM-DD')]
      }else if(period == 'year'){
        return [moment(new Date()).startOf('year').format('YYYY-MM-DD'), moment(new Date()).endOf('year').format('YYYY-MM-DD')]
      }else{
        return false
      }
    },
    async changeDateLeft(period, date) {
      this.showLoad()
      sessionStorage.setItem('fs_left_period', period)
      sessionStorage.setItem('fs_left_date', date ? date : '')
      if(period == 'all')
        await this.axiosGetFsBalanceLeft()
      else
        await this.axiosGetFsBalanceLeft(this.getTime(period, date))
      this.hideLoad()
    },
    async changeDateRight(period, date) {
      this.showLoad()
      sessionStorage.setItem('fs_right_period', period)
      sessionStorage.setItem('fs_right_date', date ? date : '')
      if(period == 'all')
        await this.axiosGetFsBalanceRight()
      else
        await this.axiosGetFsBalanceRight(this.getTime(period, date))
      this.hideLoad()

      // this.rightPeriod = date ? date : period
      this.rightPeriod = period === 'period' ? date : period;
    },
  },
  beforeMount() {
    // let leftDate = sessionStorage.getItem('fs_left_date')
    // let leftPeriod = sessionStorage.getItem('fs_left_period')
    let rightDate = sessionStorage.getItem('fs_right_date')
    let rightPeriod = sessionStorage.getItem('fs_right_period')
    // if(leftPeriod) {
    //   this.saveLeftPeriod = leftPeriod
    //   this.saveLeftDate = leftDate
    //   this.changeDateLeft(leftPeriod, leftDate ? leftDate.split(',') : null)
    // }
    if(rightPeriod) {
      this.saveRightPeriod = rightPeriod
      this.saveRightDate = rightDate
      this.changeDateLeft(rightPeriod, rightPeriod ? rightPeriod.split(',') : null)
      this.changeDateRight(rightPeriod, rightDate ? rightDate.split(',') : null)
    }
  },
  async mounted() {
    this.showLoad()
    await this.axiosGetFsBalance()
    this.hideLoad()
    let rightDate = sessionStorage.getItem('fs_right_date')
    let rightPeriod = sessionStorage.getItem('fs_right_period')
    if(rightPeriod && rightDate) {
      console.log(rightDate)
      this.$refs.choseDate.setDate(rightDate ? rightDate.split(',') : null, rightPeriod)
    }

  },
}
</script>

<style lang="scss">
  .fs{
    &_wrap{
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      .fs_period{
        padding-bottom: 13px;
      }
      .fs_period-btn {
        min-width: 79px;
      }
      .panel-selected{
        width: 100%;
      }
    }
    &_col{
      width: 49.09%;
    }
    &_block{
      padding-bottom: 18px;
      &__title{
        display: flex;
        padding: 4px 12px 9px;
        color: #9AA3B0;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        span{
          margin-left: auto;
        }
      }
      &__list{
        background: var(--color-card);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
      }
      &__row{
        position: relative;
        display: flex;
        padding: 9px 12px 10px;
        font-size: 14px;
        color: var(--color-dark-gray);
        &:not(:last-of-type){
          &:after{
            content: '';
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--color-card-border);
          }
        }
        span{
          flex-shrink: 0;
          margin-left: auto;
        }
        &.head{
          background-color: var(--color-blue-dark);
          color: #fff;
          font-weight: 500;
          &:after{
            display: none;
          }
        }
        &.subhead{
          background-color: var(--color-table);
          color: var(--color-blue-dark);
          font-weight: 500;
        }
      }
    }
  }

</style>
<style scoped lang="scss">
.pdf {
  //position: absolute;
  //width: 100%;
  //height: 100%;
    .fs_col {
      width: 100% !important;
    }
}
.content-wrapper {
  &>div {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
}
.button-export-drop {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  /deep/ {
    .btn {
      opacity: 0;
      height: 34px;
      width: 140px;
    }
  }
}
.all-uppercase {
  * {
    text-transform: uppercase !important;
  }
  /deep/ {
    * {
      text-transform: uppercase !important;
    }
  }
}
</style>