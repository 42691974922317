<template>
  <div class="account_top-panel">
    <p class="fz20 text-dark fw500">Trial Balance</p>

    <button @click="$emit('add-account')" class="blue-button h32 fw500 fz13" v-if="getPermissionsByType('create_new_accounts')">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 7.33332H8.66668V2.66662C8.66668 2.2987 8.36798 2 7.99994 2C7.63202 2 7.33332 2.2987 7.33332 2.66662V7.33332H2.66662C2.2987 7.33332 2 7.63202 2 7.99994C2 8.36798 2.2987 8.66668 2.66662 8.66668H7.33332V13.3333C7.33332 13.7013 7.63202 14 7.99994 14C8.36798 14 8.66668 13.7013 8.66668 13.3333V8.66668H13.3333C13.7013 8.66668 14 8.36798 14 7.99994C14 7.63202 13.7013 7.33332 13.3333 7.33332Z" fill="#094172"/></svg>
        Create new
    </button>

    <router-link to="/accounts/trial-balance/archive" class=" archive-link svg-white-dart-stroke text-dark fw500 fz13" v-if="getPermissionsByType('show_archive_of_trial_balance')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.12 9.96H19.72L18.76 19.24H5.96L5 9.96H6.76M18.12 9.96L18.6 7.4H16.52M18.12 9.96H6.76M6.76 9.96L6.12 7.4H8.2M8.2 7.4L7.72 5H17L16.52 7.4M8.2 7.4H16.52M9.96 13.32H14.76" stroke="#2C2C2C" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </router-link>

<!--    <button class="export"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.032 5C13.032 4.6134 12.7186 4.3 12.332 4.3C11.9454 4.3 11.632 4.6134 11.632 5H13.032ZM12.332 14L11.8379 14.4959C12.1111 14.768 12.5529 14.768 12.8261 14.4959L12.332 14ZM16.8261 10.5102C17.0999 10.2373 17.1007 9.79412 16.8279 9.52026C16.555 9.2464 16.1118 9.24561 15.8379 9.51848L16.8261 10.5102ZM8.82609 9.51848C8.55223 9.24561 8.10902 9.2464 7.83614 9.52026C7.56327 9.79412 7.56406 10.2373 7.83792 10.5102L8.82609 9.51848ZM19.6987 16C19.6987 15.6134 19.3853 15.3 18.9987 15.3C18.6121 15.3 18.2987 15.6134 18.2987 16H19.6987ZM6.03202 16C6.03202 15.6134 5.71862 15.3 5.33202 15.3C4.94542 15.3 4.63202 15.6134 4.63202 16H6.03202ZM11.632 5V14H13.032V5H11.632ZM12.8261 14.4959L16.8261 10.5102L15.8379 9.51848L11.8379 13.5041L12.8261 14.4959ZM12.8261 13.5041L8.82609 9.51848L7.83792 10.5102L11.8379 14.4959L12.8261 13.5041ZM16.9987 18.3H7.33202V19.7H16.9987V18.3ZM18.2987 16V17H19.6987V16H18.2987ZM6.03202 17V16H4.63202V17H6.03202ZM7.33202 18.3C6.61405 18.3 6.03202 17.718 6.03202 17H4.63202C4.63202 18.4912 5.84085 19.7 7.33202 19.7V18.3ZM16.9987 19.7C18.4899 19.7 19.6987 18.4912 19.6987 17H18.2987C18.2987 17.718 17.7167 18.3 16.9987 18.3V19.7Z" fill="#2C2C2C"/></svg></button>-->
    <div class="position-relative ml-auto d-flex align-items-center">
      <svg class="position-absolute l4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
      </svg>
      <input type="text" class="w240 pl38 h32 bgc-white" v-model="search" @input="$emit('changeSearch', search)">
    </div>
    <div class="period fs_period ml12">
<!--      <div class="fs_period-btns">-->
<!--        <button class="fs_period-btn fz13" :class="{active: period == 'year'}" @click="selectPeriod('year')">Year</button>-->
<!--        <button class="fs_period-btn fz13" :class="{active: period == 'all'}" @click="selectPeriod('all')">All time</button>-->
<!--      </div>-->
<!--      <trial-balance-period ref="datepicker" @changeDate="changeDate" :datepick="datepick" />-->
      <chose-trial-balance-period :fs="true" ref="choseDate" @mountNew="()=>{befoMountNew(); mountNew()}" @selectPeriod="selectPeriod"></chose-trial-balance-period>
    </div>
    <payment-dropdown-export :btnText="'Export'" class="export" @eventExportXLSX="downloadXLSX" @eventExportPDF="downloadPDF"></payment-dropdown-export>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters, mapState} from 'vuex'
import moment from 'moment';

import PaymentDropdownExport from "@/components/payment/payment-dropdown-export";
import ChoseTrialBalancePeriod from "@/components/trial-balance/chose-trial-balance-period";
export default {
  components: {
    ChoseTrialBalancePeriod,
    PaymentDropdownExport,
  },
  data() {
    return {
      period: 'all',
      datepick: [null, null],
      search: '',
    }
  },
  computed: {
    ...mapGetters(['getPermissionsByType']),
    ...mapState({
        dateTrialBalance: state => state.trialBalance.dateTrialBalance
    })
  },
  methods: {
    ...mapActions(['axiosGetTrials', 'axiosGetTrialsByTime', 'axiosGetDateForAllPeriodTrialBalance']),
    ...mapMutations(['showLoad', 'hideLoad']),
    downloadPDF() {
      this.$emit("downloadPDF")
    },
    downloadXLSX() {
      this.$emit("downloadXLSX");
    },
    async changeDate(date) {
      this.showLoad()
      if(date[0]){
        sessionStorage.setItem('trial_period', JSON.stringify(date))
        this.period = 'period'
        sessionStorage.setItem('trial_period1', this.period)
        await this.axiosGetTrialsByTime([moment(date[1], 'DD.MM.YYYY').format('YYYY-MM-DD'), moment(date[0], 'DD.MM.YYYY').format('YYYY-MM-DD')])
      }else{
        this.period = 'all'
        sessionStorage.setItem('trial_period', JSON.stringify([moment(this.dateTrialBalance.min_date).format('DD.MM.YYYY'), moment(this.dateTrialBalance.max_date).format('DD.MM.YYYY')]))
        console.log( sessionStorage.getItem('trial_period'))
        sessionStorage.setItem('trial_period1', this.period)
        // sessionStorage.removeItem('trial_period')
        await this.axiosGetTrials()
      }
      this.hideLoad()
    },
    async selectPeriod(data) {
      console.log('data', data)
      this.showLoad()
      // sessionStorage.removeItem('trial_period')
      // sessionStorage.removeItem('trial_period1')
      this.period = data.period
      this.datepick = data.date
      sessionStorage.setItem('trial_period', JSON.stringify(this.datepick))
      sessionStorage.setItem('trial_period1', this.period)
      await this.axiosGetTrialsByTime([moment(data.date[1], 'DD.MM.YYYY').format('YYYY-MM-DD'), moment(data.date[0], 'DD.MM.YYYY').format('YYYY-MM-DD')])

      // this.period = data.period
      // if(data.period == 'year'){
      //   // this.$refs.datepicker.setDate([moment(new Date(data.date)).format('01.01.YYYY'), moment(new Date(data.date)).format('31.12.YYYY')])
      //   sessionStorage.setItem('trial_period', JSON.stringify([moment(new Date(data.date)).format('01.01.YYYY'), moment(new Date(data.date)).format('31.12.YYYY')]))
      //   this.datepick = [moment(new Date(data.date)).format('01.01.YYYY'), moment(new Date(data.date)).format('31.12.YYYY')]
      //
      //   await this.axiosGetTrialsByTime([moment(new Date(data.date)).format('YYYY-12-31'), moment(new Date(data.date)).format('YYYY-01-01')])
      // }else{
      //   await this.axiosGetTrials()
      // }
      // sessionStorage.setItem('trial_period1', this.period)

      this.hideLoad()
    },
    befoMountNew() {
      let trialDate = (sessionStorage.getItem('trial_period') && sessionStorage.getItem('trial_period') !== null && sessionStorage.getItem('trial_period') !== undefined) ? sessionStorage.getItem('trial_period') : [null, null];
      let trialPeriod = (sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined) ? sessionStorage.getItem('trial_period1') : 'all';
      if(sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined && sessionStorage.getItem('trial_period') && sessionStorage.getItem('trial_period') !== null && sessionStorage.getItem('trial_period') !== undefined){
        this.datepick = JSON.parse(trialDate)

        this.selectPeriod({period: trialPeriod, date: this.datepick})
      }
    },
    mountNew() {
      console.log((sessionStorage.getItem('trial_period1')), '(sessionStorage.getItem(\'trial_period1\')', sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined)
      if(sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined){
        this.$refs.choseDate.setDate([moment(this.datepick[0], 'DD.MM.YYYY').format('DD.MM.YYYY'), moment(this.datepick[1], 'DD.MM.YYYY').format('DD.MM.YYYY')], this.period)
      } else {
        console.log(this.$refs.choseDate)
        setTimeout(()=> {
          this.$refs.choseDate.mountLocalStorage()
        }, 300)
      }
    }
  },
  beforeMount() {
    // sessionStorage.removeItem('trial_period')
    let trialDate = (sessionStorage.getItem('trial_period') && sessionStorage.getItem('trial_period') !== null && sessionStorage.getItem('trial_period') !== undefined) ? sessionStorage.getItem('trial_period') : [null, null];
    let trialPeriod = (sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined) ? sessionStorage.getItem('trial_period1') : 'all';
    if(sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined && sessionStorage.getItem('trial_period') && sessionStorage.getItem('trial_period') !== null && sessionStorage.getItem('trial_period') !== undefined){
      this.datepick = JSON.parse(trialDate)

      // if(trialPeriod === 'year') {
        this.selectPeriod({period: trialPeriod, date: this.datepick})
      // } else {
      //   this.changeDate(JSON.parse(this.datepick))
      // }
      // if(trialPeriod == 'year')
      //   this.selectPeriod('year')
      // else if(trialPeriod != 'all'){
      //   this.changeDate(trialPeriod.split(','))
      // }
    }
  },

  async mounted() {
    // let trialPeriod = (sessionStorage.getItem('trial_period1') && sessionStorage.getItem('trial_period1') !== null && sessionStorage.getItem('trial_period1') !== undefined) ? sessionStorage.getItem('trial_period1') : 'all';
    // this.mountNew()
  }
}
</script>

<style lang="scss">
  .account_top-panel{
    display: flex;
    align-items: center;
    padding: 12px 24px 19px;
    .button-blue-border{
      margin-left: 24px;
    }
    .archive-link{
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      margin-left: 8px;
      border-radius: 4px;
      width: 40px;
      height: 32px;
      border: 1px solid var(--color-blue-dark);
      justify-content: center;
      color: var(--color-blue-dark);
      svg {
        path {
          stroke: var(--color-blue-dark)
        }
      }
    }
    .export{
      margin-left: 8px;
      //width: 116px;
      //height: 32px;
      //border: 1px solid var(--color-blue-dark);
      box-sizing: border-box;
      /deep/ {
        svg {
          path {
            fill: var(--color-blue-dark);
          }
        }
        .user-button {
          color: var(--color-blue-dark);
        }
      }
    }
  }
  .fs_period{
    display: flex;
    margin-left: auto;
    &-btns{
      display: flex;
      margin-right: 12px;
      background: var(--color-card);
      border: 1px solid var(--color-blue-dark);
      border-radius: 4px;
      overflow: hidden;
      flex-shrink: 0;
    }
    &-btn{
      min-width: 72px;
      color: #4B4B4B;
      &.active{
        background-color: var(--color-blue-dark);
        font-weight: 500;
        color: #fff;
      }
      &:not(:last-child){
        border-right: 1px solid var(--color-blue-dark);
      }
    }
  }
</style>
<style lang="scss" scoped>
.blue-button {
  width: 116px;
  margin-left: 24px;
}
/deep/ {
  .mx-input {
    box-sizing: border-box;
    height: 32px;
    border: 1px solid var(--color-blue-dark) !important;
  }
}
.ml12 {
  margin-left: 12px !important;
}
</style>