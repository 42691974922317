<template>
  <button 
    type="button"
    class="w-full h-full capitalize"
    :class="{
      'text-gray-text': additional[type] != active,
      'text-dark bg-gray-light': additional[type] == active
    }" 
    @click="onClick"
  >{{active == 'expenses' ? 'Exp.' : 'Rev.'}}</button>
</template>

<script>
export default {
  props: {
    additional: Object,
    type: {
      type: String,
      default: 'trs_type',
    },
    active: String
  },
  methods: {
    onClick() {
      if(this.additional[this.type]){
        if(this.additional[this.type] != this.active){
          this.additional[this.type] = this.active
          this.$emit('on-click')
        }
      }else{
        this.$set( this.additional, this.type, this.active )
        this.$emit('on-click')
      }
    }
  }
}
</script>

<style>

</style>