<template>
  <Transition name="fade">
    <div class="vue-modal" v-if="visibility">
      <div class="modal-container">
        <div class="modal-header">
          <span class="header-name">Add account</span>
          <span class="modal-close" @click.stop="hideModal">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2486 5.03572C19.5415 5.32861 19.5415 5.80348 19.2486 6.09638L13.2032 12.1417L19.2486 18.1871C19.5415 18.48 19.5415 18.9549 19.2486 19.2478C18.9557 19.5407 18.4808 19.5407 18.1879 19.2478L12.1426 13.2024L6.09721 19.2478C5.80432 19.5407 5.32944 19.5407 5.03655 19.2478C4.74366 18.9549 4.74366 18.48 5.03655 18.1871L11.0819 12.1417L5.03655 6.09637C4.74366 5.80348 4.74366 5.32861 5.03655 5.03571C5.32944 4.74282 5.80432 4.74282 6.09721 5.03571L12.1426 11.0811L18.1879 5.03572C18.4808 4.74282 18.9557 4.74282 19.2486 5.03572Z"
                fill="#262626"
              />
            </svg>
          </span>
        </div>
        <div class="modal-content">
          <div class="input-wrapper" :class="{error: $v.$dirty && $v.name.$invalid}">
            <span class="name" >Account name</span>
            <input type="text" v-model="name">
            <span class="error-text" v-if="$v.$dirty && !$v.name.required" >Field is required</span>
          </div>
          <div class="input-wrapper">
            <span class="name" >Load letters from the year</span>
            <Multiselect 
              v-model="checkedYearForGoogleAccount" 
              track-by="key" 
              label="name"
              :show-labels="false"
              :options="yearsOptions">
              <template slot="option" slot-scope="{option}">
                <span >{{option.name}}</span>
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="modal-footer">
          <button class="modal-btn" @click.stop="addAccount()">
            Add account
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
  export default {
    name: 'createAccount-modal',
    mixins: [validationMixin],
    components: {Multiselect},
    data: () => ({
      visibility: false,
      name: '',
      checkedYearForGoogleAccount: {
        key: `all`,
        name: 'All time'
      }
    }),
    validations(){
      return {
        name: {required}
      }
    },
    methods: {
      showModal(){
        this.visibility = true
      },
      hideModal(){
        this.visibility = false
        this.$v.$reset()
        this.name = ''
      },
      addAccount(){
        this.$v.$touch();
        if (this.$v.$invalid) return false;
        let data = {
          name: this.name,
          start_parse_date: null
        }
        if(this.checkedYearForGoogleAccount && this.checkedYearForGoogleAccount.key != 'all'){
          data.start_parse_date = this.checkedYearForGoogleAccount.key
        }
        this.$emit('addAccount', data);
        this.hideModal()
      }
    },
    computed: {
      yearsOptions(){
        let arr = []
        for(let i = new Date().getFullYear(); i >= 1990; i-- ){
          arr.push({
            key: `01.01.${i}`,
            name: i
          })
        }
        arr.unshift({
          key: `all`,
          name: 'All time'
        })
        return arr;
      }
    }
  }
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.vue-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.3);
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: auto;
  }

  .modal-container {
    width: 430px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 13px 24px 0;
    // padding-top: 13px;
    position: relative;
    padding: 24px;
  }
}

.modal-header {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modal-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  .modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--color-white);
    background: var(--color-blue);
    transition: .3s;
    width: 100%;
    border-radius: 4px;
    &:hover{
      background: var(--color-blue-dark);
    }
  }
}
.modal-content{
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .input-wrapper{
    // padding-top: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    input{
      margin-top: 4px;
    }
    .multiselect{
      margin-top: 4px;
      
    }
    .name{
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--color-new-dark-gray);
      text-align: start;
      width: 100%;
    }
    &.error{
      input{
        border-color: red;
      }
    }
    .error-text{
      position: absolute;
      bottom: -18px;
      left: 0;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: red;
    }
    /deep/{
      .multiselect__element:first-child{
        border-bottom: 1px solid var(--color-gray-dark);
        position: sticky;
        top: 0;
        z-index: 1;
        background: #fff;
      }
      .multiselect__option{
        position: relative;
        display: flex;
        align-items: center;
        &.multiselect__option--selected{
          &::before{
            content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.9783 6.2675C17.6651 5.91943 17.1483 5.90978 16.8239 6.24594C16.8172 6.25297 16.8105 6.26016 16.8039 6.2675L10.535 14.8915L7.38355 12.2611C7.05922 11.9249 6.54241 11.9345 6.22918 12.2826C5.92361 12.6222 5.92361 13.1604 6.22918 13.5L9.95778 16.7499C10.2766 17.092 10.7934 17.092 11.1121 16.7499L17.9582 7.50645C18.2826 7.17023 18.2915 6.61558 17.9783 6.2675Z' fill='%23319FEF'/%3E%3C/svg%3E%0A");
            position: absolute;
            right: 10px;
          }
        }
      }
    }
  }
}
.modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
  &:hover {
    cursor: pointer;
  }
}
</style>