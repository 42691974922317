<template>
  <div class="wrap-workspace" :class="{'pb38': getMoveMode}" v-if="getVesselTaskInfo">
    <select-on-the-map @changeRegion="changeRegion" ref="modalMap"></select-on-the-map>
    <div class="d-flex align-items-center justify-between w100Proc">
      <p class="fz20 text-dark fw500 ml21">Workspace</p>
      <div class="search d-flex align-items-center">
        <svg class="svg1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.8045 18.862L15.2545 14.312C16.1359 13.2233 16.6665 11.84 16.6665 10.3333C16.6665 6.84134 13.8252 4 10.3332 4C6.84128 4 4 6.84131 4 10.3333C4 13.8253 6.84131 16.6667 10.3333 16.6667C11.8399 16.6667 13.2232 16.136 14.3119 15.2547L18.8618 19.8047C18.9918 19.9347 19.1625 20 19.3332 20C19.5039 20 19.6745 19.9347 19.8045 19.8047C20.0652 19.544 20.0652 19.1227 19.8045 18.862ZM10.3333 15.3333C7.57596 15.3333 5.33333 13.0907 5.33333 10.3333C5.33333 7.57597 7.57596 5.33331 10.3333 5.33331C13.0906 5.33331 15.3332 7.57597 15.3332 10.3333C15.3332 13.0907 13.0906 15.3333 10.3333 15.3333Z" fill="#2C2C2C"/>
        </svg>
        <input type="text" v-model="search">
        <svg v-on:click="search = ''" class="svg2 cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.51375 7.57727C6.21612 7.28258 6.21612 6.80478 6.51375 6.51008C6.81138 6.21539 7.29392 6.21539 7.59155 6.51008L17.4863 16.2961C17.784 16.5908 17.784 17.0685 17.4863 17.3632C17.1887 17.6579 16.7062 17.6579 16.4085 17.3632L6.51375 7.57727Z" fill="#2C2C2C"/>
          <path d="M16.4086 6.634C16.7063 6.33931 17.1888 6.33931 17.4864 6.634C17.7841 6.9287 17.7841 7.4065 17.4864 7.70119L7.59165 17.4872C7.29402 17.7819 6.81148 17.7819 6.51385 17.4872C6.21622 17.1925 6.21622 16.7147 6.51385 16.42L16.4086 6.634Z" fill="#2C2C2C"/>
        </svg>

      </div>
    </div>
    <div class="main">
      <modal-slots :show-mod="showModalSelectedWayCreateContract" class="selected-way-create" @close="closeSelectedWayCreatedModal">
        <template v-slot:title>
          <p class="fz18 fw500">It looks like this vessel with that name already exists</p>
        </template>
        <div v-on:click="replaceVesselWay" class="hover:bg-gray-lightest cursor-pointer w100Proc p16-24 box-border border-bottom d-flex justify-between align-items-center">
          <div class="">
            <p class="fz16 text-dark fw500">Replace vessel</p>
            <p class="fz13 fw400 text-dark-gray mt4">This vessel will replace the old one with the same name.<br> The old vessel will be removed.</p>
          </div>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40039L5.18326 9.7027C4.91614 9.97456 4.48367 9.9741 4.217 9.70129C3.95053 9.42852 3.95122 8.98664 4.21837 8.71457L6.84906 5.90641L4.21837 3.18915C3.95125 2.91704 3.95057 2.47545 4.217 2.20264C4.35068 2.06588 4.52581 1.9975 4.70094 1.9975C4.87562 1.9975 5.05006 2.06542 5.1835 2.20123L8.29924 5.41247C8.42789 5.54318 8.50009 5.72107 8.50009 5.90641C8.50009 6.09175 8.42769 6.26943 8.29924 6.40039Z" fill="#9AA3B0"/>
          </svg>
        </div>
        <div v-on:click="createDuplicateWay" class="hover:bg-gray-lightest cursor-pointer w100Proc p16-24 box-border d-flex justify-between align-items-center">
          <div class="">
            <p class="fz16 text-dark fw500">Create a duplicate</p>
            <p class="fz13 fw400 text-dark-gray mt4">A new vessel with the same name will be added to the list.</p>
          </div>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.29924 6.40039L5.18326 9.7027C4.91614 9.97456 4.48367 9.9741 4.217 9.70129C3.95053 9.42852 3.95122 8.98664 4.21837 8.71457L6.84906 5.90641L4.21837 3.18915C3.95125 2.91704 3.95057 2.47545 4.217 2.20264C4.35068 2.06588 4.52581 1.9975 4.70094 1.9975C4.87562 1.9975 5.05006 2.06542 5.1835 2.20123L8.29924 5.41247C8.42789 5.54318 8.50009 5.72107 8.50009 5.90641C8.50009 6.09175 8.42769 6.26943 8.29924 6.40039Z" fill="#9AA3B0"/>
          </svg>

        </div>
      </modal-slots>
      <modal-slots :show-mod="showModalDeleteDate"  @apply="deleteDate" @close="closeModalDeleteDate" :isBorder="false" :textForSecondButton="'Delete'" class="modal-delete-date">
        <template v-slot:title>
          <p class="fz16 fw500">Delete Time on subs</p>
        </template>
        <p class="fz14 text-dark delete-text">Are you sure you want to delete Time on subs?</p>
      </modal-slots>
      <modal-slots :show-mod="showCandidates" @close="showCandidates = false">
        <template v-slot:title>
          <p class="fz18 fw500">Add Candidates</p>
        </template>
        <div class="modal-add-content" :key="showCandidates">
          <counterparties-drop
              class="drop"
              title="Region"
              :list = "regionList"
              :active-item="defaultRegionActive"
              @select="setActiveRegion"
          ></counterparties-drop>
          <div class="map-block">
            <button class="blue-button-text fz13 fw500" v-on:click="openMapModal">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9205 4.37895C20.1266 4.51848 20.25 4.75114 20.25 5V17C20.25 17.3067 20.0633 17.5825 19.7785 17.6964L14.7785 19.6964C14.5997 19.7679 14.4003 19.7679 14.2215 19.6964L9.5 17.8078L4.77854 19.6964C4.54747 19.7888 4.28561 19.7606 4.07953 19.6211C3.87345 19.4815 3.75 19.2489 3.75 19V7C3.75 6.69333 3.93671 6.41754 4.22146 6.30365L9.22146 4.30365C9.40027 4.23212 9.59973 4.23212 9.77854 4.30365L14.5 6.19223L19.2215 4.30365C19.4525 4.21122 19.7144 4.23943 19.9205 4.37895ZM13.75 7.50778L10.25 6.10778V16.4922L13.75 17.8922V7.50778ZM15.25 17.8922L18.75 16.4922V6.10778L15.25 7.50778V17.8922ZM8.75 16.4922V6.10778L5.25 7.50778V17.8922L8.75 16.4922Z" fill="#094172"/>
              </svg>
              Select on the map
            </button>
          </div>
          <checkboxes-for-modal title="ALL VESSELS" :list="getShipsListTemplate"></checkboxes-for-modal>
        </div>
      </modal-slots>
      <modal-slots :show-mod="showEditOwners" @close="closeModalEditOwmners" @apply="applyOwners" class="modal-edit-owners">
        <template v-slot:title>
          <p class="fz18 fw500">Edit Owners Idea</p>
        </template>
        <div class="modal-edit-content" :key="showEditOwners">
          <counterparties-drop
              class="drop"
              title="Broker"
              :list = "getClientsFormAddModal"
              @select="setActiveBroker"
              :active-item="defaultActiveBroker"
          ></counterparties-drop>
          <p class="fz13 text-gray">Comment</p>
          <textarea v-model="editOwnersComment"></textarea>
        </div>
      </modal-slots>
      <modal-slots :show-mod="showModalChat" @close="closeModalChat" class="modal-edit-owners modal-chat" :is-pointer-bg="false">
        <template v-slot:title>
          <p class="fz18 fw500">Chat in Out Offer / Our Target</p>
        </template>
        <div class="modal-edit-content" :key="showEditOwners">
          <ship-chat @chatEmit="setChatForShip" :key="showModalChat" :id="idShipForModal"></ship-chat>
        </div>
      </modal-slots>
      <modal-slots :show-mod="showModalDelete"  @apply="deleteShip" @close="closeModalDeleteShip" :isBorder="false" :textForSecondButton="'Delete'">
        <template v-slot:title>
          <p class="fz16 fw500">Delete row</p>
        </template>
        <p class="fz14 text-dark delete-text">Are you sure you want to delete this row?</p>
      </modal-slots>
      <counterparties-add class="add-client-modal" @close="showAddClient = false" :inside-block-type-modal="true" :key="showAddClient" v-if="showAddClient"></counterparties-add>
      <modal-slots :class="{'displayNone': showAddClient}" :no-close-after-apply="true" :is-pointer-bg="false" :show-mod="showModalEditClean" @close="closeModalEditClean" @apply="applyCleanFixed" class="modal-edit-clean">
        <template v-slot:title>
          <p class="fz18 fw500">Edit Clean Fixed</p>
        </template>
        <div class="modal-edit-clean-content" :key="showEditOwners">
          <async-select
              :imported-data="true"
              :class="{ 'error-drop': errorOwners }"
              class="drop block-from-inputs"
              id="count33e234rparty"
              :label="(getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP') ? 'Owner' : 'Charterer'"
              url="/client/dropdown"
              :params="{is_archived: false, ordering: 'company_name'}"
              watchKey="company_name"
              :allowEmpty="true"
              :closeOnSelect="true"
              v-model="activeOwner"
              placeholder="Search Charterer"
          >
            <template v-slot:additional-label>
              <button class="m-b-1 text-blue-dark fz13" v-on:click="showAddClient = true">Create new counterparty</button>
            </template>
          </async-select>
          <div class="block-from-inputs" :class="{'error-input1': errorName}">
            <p class="fz13 text-gray">Vessel Name <span class="text-red">*</span></p>
            <input type="text" class="h36" maxlength="100" v-model="vesselNameEdit" >
          </div>
          <div class="block-from-inputs">
            <p class="fz13 text-gray">CP Date <span class="text-red">*</span></p>
            <date-picker
                v-model="CPDate"
                class="datepick"
                :class="{'error-date': errorCPDate}"
                ref="datePickDue"
                format="DD.MM.YYYY"
            >
            </date-picker>
          </div>
          <div class="block-from-inputs">
            <p class="fz13 text-gray">Laycan Date</p>
<!--            <input type="text" class="h36" maxlength="300" v-model="laycanDateEdit">-->
            <date-picker
                value-type="format"
                placeholder=""
                class="datepickeer w100Proc"
                v-model="laycanDateEdit"
                range
                format="DD.MM.YYYY"
                popup-class="dateLeft0"
            ></date-picker>
          </div>
          <div class="block-from-inputs" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray">DWT</p>
            <input type="text" maxlength="50" class="h36" v-model="dwtEdit">
          </div>
          <div class="block-from-inputs" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray">Built</p>
            <input type="text" class="h36" maxlength="50" v-model="builtEdit">
          </div>
          <div class="block-from-inputs" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray">Index ration</p>
            <div class="position-relative">
              <input type="text" maxlength="50" class="h36 pr30" v-model="perEdit">
              <span class="input-symbol fz13 text-gray">%</span>
            </div>
          </div>
          <div class="block-from-inputs" v-if="getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray">Cargo</p>
            <textarea maxlength="2000" v-model="cargoCommentEdit"></textarea>
          </div>
          <div v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <div class="two-inputs">
              <div class="position-relative box-border">
                <div class="blue-button-text button-new-port fz13 t12" v-on:click="isShowModal = true">Create new port</div>
                <async-select-port
                    :key="keyRenderPorts"
                    class="drop block-from-inputs"
                    label="Delivery"
                    v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'"
                    v-model="port_of_loading"
                ></async-select-port>
              </div>
              <div class="block-from-inputs">
                <p class="fz13 text-gray">Delivery Date</p>
                <date-picker
                    v-model="deliveryDate"
                    type="datetime"
                    class="datepick"
                    :show-time-panel="showTimePanel"
                    ref="datePickDue"
                    format="DD.MM.YYYY, HH:mm"
                    @close="showTimePanel=false"
                >
                  <template v-slot:footer>
                    <button class="mx-btn mx-btn-text" @click="showTimePanel= !showTimePanel">
                      {{ showTimePanel ? 'Select date' : 'Select time' }}
                    </button>
                  </template>
                </date-picker>
              </div>
            </div>
            <div class="two-inputs">
              <div class="position-relative box-border" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
                <div class="blue-button-text button-new-port fz13 t12" v-on:click="isShowModal = true">Create new port</div>
                <async-select-port
                    class="drop block-from-inputs"
                    label="Redelivery"
                    :key="keyRenderPorts"
                    v-model="port_of_discharge"
                ></async-select-port>
              </div>
              <div class="block-from-inputs">
                <p class="fz13 text-gray">Redelivery Date</p>
                <date-picker
                    v-model="redeliveryDate"
                    type="datetime"
                    class="datepick"
                    :show-time-panel="showTimePanel"
                    ref="datePickDue"
                    format="DD.MM.YYYY, HH:mm"
                    @close="showTimePanel=false"
                >
                  <template v-slot:footer>
                    <button class="mx-btn mx-btn-text" @click="showTimePanel= !showTimePanel">
                      {{ showTimePanel ? 'Select date' : 'Select time' }}
                    </button>
                  </template>
                </date-picker>
              </div>
            </div>
          </div>
          <div class="position-relative box-border" v-if="getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'">
            <div class="blue-button-text button-new-port fz13" v-on:click="isShowModal = true">Create new port</div>
            <async-select-port
                class="drop block-from-inputs"
                label="Port of Loading"
                :key="keyRenderPorts"
                v-model="port_of_loading"
            ></async-select-port>
          </div>
          <div class="position-relative box-border" v-if="getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'">
            <div class="blue-button-text button-new-port fz13" v-on:click="isShowModal = true">Create new port</div>
            <async-select-port
                class="drop block-from-inputs"
                label="Port of Discharge"
                :key="keyRenderPorts"
                v-model="port_of_discharge"
            ></async-select-port>
          </div>
          <div class="block-from-inputs" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray">Period</p>
            <input type="text" v-model="periodEdit">
          </div>
          <div class="block-from-inputs" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray">Hire rate</p>
            <input type="text" class="h36" maxlength="50" v-model="hireEdit" @input="hireEdit =checkNum(hireEdit)">
            <counterparties-drop
                :removeSelectItemBeforeClick="false"
                class="valute-drop"
                title=""
                :list="valutaList"
                @select="setValuta"
                :active-item="defaultActiveValuta"
            ></counterparties-drop>
          </div>
          <div class="block-from-inputs" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray">Operations Manager</p>
            <async-select-employees  :height36="true"  :permission-assigned="'ship_task'" :is-multiple="false" v-model="assignedOperations"></async-select-employees>
<!--            <assignee-task  :default-active="defaultActiveOperations" :one-user="true" ref="assigneeTask" @oneElementClick="setAssignedOperations"></assignee-task>-->
          </div>
          <div class="block-from-inputs">
            <p class="fz13 text-gray">Chartering Manager</p>
            <async-select-employees  :height36="true" :permission-assigned="'ship_task'" :is-multiple="false" v-model="assignedChartering"></async-select-employees>
          </div>
          <div class="block-from-inputs">
            <async-select
                id="counterparty"
                label="Broker"
                url="/client/dropdown"
                :params="{is_archived: false, ordering: 'company_name', has_broker: true}"
                watchKey="company_name"
                :allowEmpty="true"
                :closeOnSelect="true"
                v-model="activeBroker"
            >
              <template v-slot:additional-label>
                <button class="m-b-1 text-blue-dark fz13" v-on:click="showAddClient = true">Create new broker</button>
              </template>
            </async-select>
          </div>


          <div class="block-from-inputs">
            <p class="fz13 text-gray">Comment</p>
            <textarea maxlength="2000" v-model="commentEdit"></textarea>
          </div>
          <div class="block-from-inputs" :class="{'block-input_error': errorFile}">
            <p class="fz13 text-gray">Attachments <span class="text-red">*</span></p>
            <div class="file-block">
              <vue-dropzone :options="dropzoneOptions" :useCustomSlot=true ref="dropzone" id="dropzone">
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                  <div class="subtitle">...or click to select a file from your computer</div>
                </div>
              </vue-dropzone>
            </div>
          </div>
        </div>
      </modal-slots>
      <modal-slots :no-close-after-apply="true" :show-mod="showMoveTask" @close="closeMoveTask" class="modal-task">
        <template v-slot:title>
          <p class="fz18 fw500 text-dark">Move to other task</p>
        </template>
        <transition name="fade">
          <app-loader class="loader" v-if="moveTaskLoader"></app-loader>
        </transition>
        <div class="main" v-if="getTasksList.length !==0" :key="keyRender">
          <div class="tasks-checkbox"
               v-for="(task, i) in getTasksList.filter(a=> a.id !== getVesselTaskInfo.task.id)"
               :key="i"
               :class="{'oneTask': getTasksList.length === 1}"
               v-on:click="openModalAcceptMove(task)"
          >
            <div class="part1">
              <p class="name fz13 text-dark">
                {{task.name}}
              </p>
              <span class="svg-type" v-if="task.type === 'SHIP_SEARCH_WITH_CARGO'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00809 2.8634C7.00809 3.12504 6.79847 3.33714 6.53989 3.33714L5.24016 3.33714C4.98158 3.33714 4.77197 3.12504 4.77197 2.8634L4.72919 0.473741C4.72919 0.212101 4.93881 4.03386e-07 5.19739 4.03386e-07L6.49712 0C6.7557 0 6.96532 0.212101 6.96532 0.473741L7.00809 2.8634Z" fill="#094172"/>
            <path d="M7.99109 9.90658C6.68551 10.9507 5.01606 10.9507 3.71046 9.90658C3.05765 10.4287 2.3139 10.7157 1.57015 10.7157H1.13465C0.784149 10.7157 0.500007 11.0032 0.5 11.3578C0.499993 11.7125 0.78414 12 1.13465 12H1.57015C2.30588 12 3.03626 11.7893 3.71046 11.3924C5.05885 12.1832 6.64269 12.1832 7.99109 11.3924C8.66529 11.7863 9.39567 12 10.1314 12H10.5669C10.9174 12 11.2016 11.7125 11.2016 11.3578C11.2016 11.0032 10.9174 10.7157 10.5669 10.7157H10.1314C9.38765 10.7157 8.6439 10.4287 7.99109 9.90658Z" fill="#094172"/>
            <path d="M11.1273 5.37981C11.4619 5.5185 11.627 5.90118 11.5 6.24376L10.259 9.30907H10.1403C9.39655 9.30907 8.6528 9.02208 8 8.5C6.69442 9.54415 5.02497 9.54415 3.71937 8.5C3.11859 8.98047 2.44078 9.26184 1.75683 9.30363L0.585554 6.24376C0.458553 5.90118 0.623638 5.5185 0.958256 5.37981L2.54934 4.96678L2.54933 2.35307C2.54933 2.09143 2.75895 1.87933 3.01753 1.87933L5.24016 1.87934H6.8454L8.76963 1.87887C9.0282 1.87887 9.23782 2.09097 9.23782 2.35261V4.96632L11.1273 5.37981Z" fill="#094172"/>
            </svg>
          </span>
              <span class="svg-type" v-if="task.type === 'CARGO_SEARCH_WITH_SHIP'">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.40694 5.86059L0.162656 3.59085C0.0854297 3.55832 0 3.61504 0 3.69885V9.46353C0 9.61686 0.0933516 9.75477 0.235734 9.8117L5.31795 11.9031C5.39492 11.9339 5.47866 11.8772 5.47866 11.7943V5.96859C5.47866 5.92143 5.45039 5.87889 5.40694 5.86059Z" fill="#094172"/>
            <path d="M6.04503 4.76647L11.2555 2.57258C11.3492 2.53316 11.3517 2.40135 11.2596 2.35839L6.15814 0.124041C6.05762 0.0771426 5.94151 0.0771426 5.84099 0.124041L0.739511 2.35839C0.647425 2.40135 0.649933 2.53316 0.743589 2.57258L5.9541 4.76647C5.98316 4.77873 6.01597 4.77873 6.04503 4.76647Z" fill="#094172"/>
            <path d="M6.52124 5.94417V11.7945C6.52124 11.8774 6.60498 11.9341 6.68195 11.9033L11.7642 9.81186C11.9065 9.75491 11.9999 9.61702 11.9999 9.4637V3.67444C11.9999 3.59063 11.9145 3.53391 11.8372 3.56644L6.59296 5.83617C6.54951 5.85448 6.52124 5.89702 6.52124 5.94417Z" fill="#094172"/>
            </svg>
          </span>
              <div class="block-with-option-tag">
                <div class="wrap-block-tag" v-for="(t,j) in task.tags" :key="j">
                  <div v-if="j<2" class="tag-wrap">
                    <div class="button-tag" v-if="!t.editStatus">
                      <p class="fz13 text-dark">{{ t.name }}</p>
                      <button class="button-drop-tags1 fz13 fw500">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.05373 4.42533C2.81022 4.18421 2.81022 3.79329 3.05373 3.55217C3.29724 3.31106 3.69206 3.31106 3.93557 3.55217L8.94574 8.47332C9.18925 8.71444 9.18925 9.10536 8.94574 9.34647C8.70223 9.58759 8.30741 9.58759 8.0639 9.34647L3.05373 4.42533Z" fill="#319FEF"/>
                          <path d="M8.06398 3.65356C8.3075 3.41245 8.70231 3.41245 8.94582 3.65356C9.18933 3.89468 9.18933 4.2856 8.94582 4.52672L3.93565 9.44786C3.69214 9.68898 3.29732 9.68898 3.05381 9.44786C2.8103 9.20675 2.8103 8.81582 3.05381 8.57471L8.06398 3.65356Z" fill="#319FEF"/>
                        </svg>
                      </button>

                    </div>
                  </div>
                </div>
                <div class="numberMore fz11 text-dark" v-if="task.tags.length>2">
                  +{{task.tags.length - 2}}
                </div>
                <button class="button-tags-filter">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.96699 11.837L4.967 11.837L4.96355 11.8336C4.80041 11.6727 4.7 11.4504 4.7 11.2V5.60001C4.7 5.10259 5.10259 4.7 5.60001 4.7H11.2C11.4444 4.7 11.6702 4.79917 11.839 4.96494L19.033 12.159C19.2001 12.326 19.3 12.5504 19.3 12.8C19.3 13.0444 19.2009 13.2701 19.0351 13.4389L13.437 19.037C13.2756 19.1984 13.0478 19.3 12.8 19.3C12.5541 19.3 12.3298 19.1998 12.167 19.037L4.96699 11.837Z" stroke="#BDCBD6" stroke-width="1.4"/>
                    <path d="M7 8.2C7 8.86401 7.53599 9.4 8.2 9.4C8.86397 9.4 9.4 8.86401 9.4 8.2C9.4 7.53599 8.86401 7 8.2 7C7.53599 7 7 7.53599 7 8.2Z" fill="#BDCBD6"/>
                  </svg>
                </button>
<!--                <task-list-tag-select  @deleteUsageTag="deleteTagWhichUsed" @changeTagsForTaskEmit="changeTagsForTask" class="tag-select-dropdown" :ref="'tagSelect' + i"></task-list-tag-select>-->
              </div>

            </div>
            <div class="part2">
              <p class="fz13 status" :class="{'status-open': task.status.toUpperCase() === 'OPEN', 'status-done': task.status.toUpperCase() === 'DONE', 'status-inProgress': task.status.toUpperCase() === 'IN PROGRESS'}">{{task.status}}</p>
            </div>

            <div class="part4">
              <button class="one-user one-user-button" v-if="task.executors.length === 1">
                <img v-if="task.executors[0].avatar !== null" :src="task.executors[0].avatar" class="img-profile" alt="">
                <svg v-if="task.executors[0].avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="80" cy="80" r="80" fill="white"/>
                  <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                </svg>
                <button class="fz13">{{task.executors[0].first_name}} {{task.executors[0].last_name}}</button>
              </button>
              <button class="more-user more-user-button" v-if="task.executors.length > 1">
                <div class="user-image-wrap" v-for="(u, m) in task.executors" :key="m" :style="'z-index:'+ (task.executors.length - m)">
                  <div class="user-image" v-if="m<6">
                    <img v-if="u.avatar !== null" :src="u.avatar" class="img-profile" alt="">
                    <svg v-if="u.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="80" cy="80" r="80" fill="white"/>
                      <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
                    </svg>
                  </div>
                </div>
                <div class="moreUser fz11 text-blue-dark" v-if="task.executors.length > 6">
                  +{{task.executors.length - 6}}
                </div>
              </button>
              <button class="no-assigned" v-if="task.executors.length === 0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.212 10.8719C13.9294 10.8719 15.3223 9.47945 15.3223 7.7612C15.3223 6.04334 13.9302 4.65048 12.212 4.65088C10.4949 4.65088 9.10206 6.04374 9.10206 7.7612C9.10206 9.47945 10.4949 10.8719 12.212 10.8719Z" stroke="#4B4B4B" stroke-width="1.4"/>
                  <path d="M6.41675 17.2501C6.41675 18.0457 7.06159 18.6906 7.85722 18.6906H16.9136C17.7088 18.6906 18.3537 18.0457 18.3537 17.2501V16.4378C18.3537 14.6961 16.9425 13.2981 15.2017 13.2981H9.57984C7.83937 13.2981 6.41675 14.6969 6.41675 16.4378V17.2501Z" stroke="#4B4B4B" stroke-width="1.4"/>
                </svg>
                <p class="fz13 text-dark">No assignee</p>
              </button>
<!--              <task-list-change-assignee v-if="getPermissionsForUserCanAssignTask.status" @changeEmployeeForTaskEmit="changeUserForTaskAddArray" @changeEmployeeForTaskEmitIndex="changeUserForTask(i)" class="change-assignee" :assignee="task.executors" :ref="'changeAssignee' +i"></task-list-change-assignee>-->
            </div>
          </div>
        </div>
        <pagination-panel-default class="show-more" @load-more="showMore()" :if-active="getLoadRequestFilterTask" v-if="getTasksList.length > 19 && getTasksList.length < getCountTask"></pagination-panel-default>
        <template v-slot:bottomFirstButton><p></p></template>
        <template v-slot:bottomSecondButton>
          <button class="blue-button-text fz14 fw500" v-on:click="openCreateTask()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.7778 18.2221L12.7778 12.7778L18.2223 12.7778C18.6515 12.7778 19 12.4293 19 11.9999C19 11.5707 18.6515 11.2222 18.2223 11.2222L12.7778 11.2222L12.7778 5.77773C12.7778 5.34849 12.4293 5 12.0001 5C11.5707 5 11.2222 5.34849 11.2222 5.77773L11.2222 11.2222L5.77787 11.2222C5.34849 11.2222 5 11.5707 5 11.9999C5 12.4293 5.34849 12.7778 5.77787 12.7778L11.2222 12.7778L11.2222 18.2221C11.2222 18.6515 11.5707 19 12.0001 19C12.4293 19 12.7778 18.6515 12.7778 18.2221Z" fill="white"/>
            </svg>
            Create new task
          </button>
        </template>
      </modal-slots>
      <modal-slots :textForSecondButton="'import'" :show-mod="showAcceptMoveModalTask" @close="closeModalAcceptMove" @apply="applyModalAcceptMove" class="modal-edit-clean modal-import">
        <template v-slot:title>
          <p class="fz18 fw500">Import to other task</p>
        </template>
        <p class="fz14 text-dark text-in-modal">Are you sure you want to move chosen {{ getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP' ? 'cargoes' : 'vessels' }} into this task?</p>
      </modal-slots>
      <modal-slots :textForSecondButton="'Delete'" :show-mod="showDeleteAllModal" @close="showDeleteAllModal = false" @apply="deleteSelected" class="modal-edit-clean modal-import">
        <template v-slot:title>
          <p class="fz18 fw500">Delete Selected</p>
        </template>
        <p class="fz14 text-dark text-in-modal">Are you sure you want to delete selected lines?</p>
      </modal-slots>
      <manually-task-add ref="manuallyTask" @renderTaskList="showModalMoveMode"></manually-task-add>
      <add-modal
          class="modalPort"
          :no-redirect="true"
          :isShowModal="isShowModal"
          @on-close="isShowModal = false"
          @added="reloadPorts"
      ></add-modal>
<!--      {{keyRenderList}}-->
<!--      :key="keyRenderList"-->
      <div class="stroke stroke-title mt12" :class="{'stroke-open-title': showFullInfo.length !== 0}">
        <div class="candidates" :class="{'candidates-cargo': getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP'}">
          <button v-on:click="showOrhideAll">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 4.40039L6.00001 8.40039L10 4.40039H2Z" fill="#2C2C2C"/>
            </svg>
          </button>
          <p class="fz14 fw500 text-dark">{{getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' ? 'Candidates' : 'Cargo'}} <span class="text-gray"> ({{getShipsLength()}})</span></p>
          <div class="stick-change-size" draggable currentClass="candidates" @dragstart="dragStart" @dragend="dragEnd"></div>
          <div class="block-select">
            <button v-if="getCountAvailableCheckbox" v-on:click="selectAllCheckBox" class="button-select">
              <svg v-if="selectedShips.length !== getCountAvailableCheckbox" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
              </svg>
              <svg class="w18 h18" v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="4" fill="#094172"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.15039 7.99844C4.15039 7.529 4.53095 7.14844 5.00039 7.14844H11.0004C11.4698 7.14844 11.8504 7.529 11.8504 7.99844C11.8504 8.46788 11.4698 8.84844 11.0004 8.84844H5.00039C4.53095 8.84844 4.15039 8.46788 4.15039 7.99844Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>

        <div class="dwt" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
          <p class="fz14 fw500">DWT</p>
          <div class="stick-change-size" draggable currentClass="dwt" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="years" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
          <p class="fz14 fw500">Built</p>
          <div class="stick-change-size" draggable currentClass="years" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="per" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
          <p class="fz14 fw500">Index ratio</p>
          <div class="stick-change-size" draggable currentClass="per" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="delivery" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
          <p class="fz14 fw500">Delivery</p>
          <div class="stick-change-size" draggable currentClass="delivery" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="dates" :class="{'dates-cargo': getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP'}" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP'">
          <p class="fz14 fw500">{{getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' ? 'Dates' : 'Client'}}</p>
          <div class="stick-change-size" draggable currentClass="dates" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="owners" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
          <p class="fz14 fw500">Owners Idea</p>
          <div class="stick-change-size" draggable currentClass="owners" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="comment-cargo" v-if="getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'">
          <p class="fz14 fw500">Comment</p>
          <div class="stick-change-size" draggable currentClass="comment-cargo" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="charterers-cargo" v-if="getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'">
          <p class="fz14 fw500">Owners Idea</p>
          <div class="stick-change-size" draggable currentClass="charterers-cargo" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="our" :class="{'our-cargo': getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'}">
          <p class="fz14 fw500">Our Offer / Our Target</p>
          <div class="stick-change-size" draggable currentClass="our" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
        <div class="clean" :class="{'clean-cargo': getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'}">
          <p class="fz14 fw500">Clean Fixed</p>
          <div class="stick-change-size displayNone" draggable currentClass="clean" @dragstart="dragStart" @dragend="dragEnd"></div>
        </div>
      </div>
      <div class="stroke stroke-info" :class="{'stroke-open': true, 'rejected-stroke': data.is_rejected, 'date-failed': data.on_subs_status === true, 'date-normal': data.on_subs_status === false, 'searched': data.is_search}" v-for="(data, i) in getFilteredList" :key="i">
        <div class="candidates" :class="{'candidates-cargo': getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP', 'pb2': showFullInfo.indexOf(data.name + ''+ data.id) === -1}" v-tooltip.top="showFullInfo.indexOf(data.name + ''+ data.id) >= 0 ? '' : data.name">
          <button v-on:click="showFullInfoMeth(data.name + '' + data.id)">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'rotate-180': showFullInfo.indexOf(data.name + ''+ data.id) !== 0}">
              <path d="M2 4.40039L6.00001 8.40039L10 4.40039H2Z" fill="#2C2C2C"/>
            </svg>
          </button>
          <p v-tooltip.top="showFullInfo.indexOf(data.name + ''+ data.id) >= 0 ? data.name : ''" :key="keyRender+'23sd'" class="fz14 text-dark w100Proc min-w-100Proc" contenteditable="true" @input="inputEditName" @focus="focusEditName(data.index, data.name)" @focusout="focusOutChangeText('name', data.index, 'text')" v-html="getTextSearched(data.name)">{{ data.name }}</p>
          <button :key="keyRender+'ss2'" v-if="!getMoveMode" v-on:click="clickFavorites(data, data.index)" :class="{'favorite': data.is_favorite}">
            <svg class="is_favorite" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.1816 10.0511L19.1818 10.0512C19.3207 10.0637 19.4389 10.1576 19.4826 10.2915C19.5264 10.4266 19.4859 10.5726 19.3805 10.6652C19.3804 10.6652 19.3804 10.6653 19.3803 10.6653L15.8891 13.7266L15.6652 13.9229L15.7311 14.2133L16.7604 18.7468C16.7919 18.886 16.7379 19.0275 16.6245 19.1094L16.6241 19.1097C16.5101 19.1923 16.3591 19.1989 16.2385 19.1268L12.2566 16.7455L12 16.5921L11.7434 16.7455L7.7607 19.1269L7.76056 19.127C7.70491 19.1603 7.64313 19.1766 7.58125 19.1766C7.50884 19.1766 7.43758 19.1546 7.37649 19.1102L7.3755 19.1094C7.26218 19.0275 7.20821 18.8862 7.23953 18.7471C7.23955 18.747 7.23957 18.7469 7.2396 18.7468L8.26891 14.2133L8.33484 13.9229L8.11097 13.7266L4.61961 10.6652C4.6196 10.6652 4.61959 10.6652 4.61958 10.6652C4.51426 10.5728 4.47381 10.4265 4.5172 10.2923C4.56143 10.158 4.67976 10.0641 4.81876 10.0511C4.8189 10.0511 4.81903 10.0511 4.81916 10.0511L9.43788 9.63182L9.73522 9.60483L9.85249 9.33027L11.6778 5.05676L11.6779 5.05652C11.7333 4.92684 11.859 4.84375 12 4.84375C12.1406 4.84375 12.2669 4.92651 12.3231 5.0572C12.3232 5.05732 12.3232 5.05745 12.3233 5.05758L14.1483 9.33027L14.2656 9.60483L14.5629 9.63182L19.1816 10.0511Z" stroke="#BDCBD6"/>
            </svg>
          </button>
          <div class="block-select">
            <button v-if="data.name.length" v-on:click="selectShip(data.id)" :class="{'select-ship': data.is_favorite}" class="button-select">
              <svg v-if="selectedShips.indexOf(data.id) === -1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V4C17 2.34315 15.6569 1 14 1ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#BDCBD6"/>
              </svg>
              <svg class="active-checkbox-style" v-if="selectedShips.indexOf(data.id) !== -1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="18" height="18" rx="4" fill="#094172"/>
                <path d="M14.5 5.02141C14.1868 4.67334 13.67 4.66368 13.3456 4.99985C13.3388 5.00688 13.3321 5.01407 13.3255 5.02141L7.76395 10.9193L4.88355 8.7889C4.55922 8.45274 4.04241 8.46239 3.72918 8.81046C3.42361 9.15001 3.42361 9.68829 3.72918 10.0278L7.18677 12.7777C7.50559 13.1198 8.02236 13.1198 8.34114 12.7777L14.4799 6.26035C14.8042 5.92414 14.8132 5.36948 14.5 5.02141Z" fill="white"/>
              </svg>
            </button>
          </div>
          <div class="search-ship" v-if="data.showTemplate === true && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'" :key="keyRenderTemplate">
            <app-loader class="loader" v-if="searchShipActive"></app-loader>
            <button class="fz14 text-dark fw500" v-on:mousedown="setThisTemplate(i, j)" v-for="(item, j) in listShipsTemplateSearch" :key="j" v-html="getTextForNameTemplate(item)"></button>
            <p v-if="listShipsTemplateSearch.length === 0" class="fz14 text-dark fw500">List empty</p>
          </div>
          <div class="on-subs-date-wrap position-relative" v-show="showFullInfo.indexOf(data.name + ''+ data.id) !== -1">
            <svg width="24" class="iconDate" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84211 3.03516C9.21998 3.03516 9.52632 3.33949 9.52632 3.71489V4.86522H14.4737V3.71489C14.4737 3.33949 14.78 3.03516 15.1579 3.03516C15.5358 3.03516 15.8421 3.33949 15.8421 3.71489V4.86522H16.2105C17.7511 4.86522 19 6.10595 19 7.63646V16.2639C19 17.7944 17.7511 19.0352 16.2105 19.0352H7.78947C6.24889 19.0352 5 17.7944 5 16.2639V7.63646C5 6.10595 6.24889 4.86522 7.78947 4.86522H8.15789V3.71489C8.15789 3.33949 8.46423 3.03516 8.84211 3.03516ZM8.15789 6.2247H7.78947C7.00465 6.2247 6.36842 6.85677 6.36842 7.63646V9.8979H17.6316V7.63646C17.6316 6.85677 16.9954 6.2247 16.2105 6.2247H15.8421V7.37502C15.8421 7.75043 15.5358 8.05476 15.1579 8.05476C14.78 8.05476 14.4737 7.75043 14.4737 7.37502V6.2247H9.52632V7.37502C9.52632 7.75043 9.21998 8.05476 8.84211 8.05476C8.46423 8.05476 8.15789 7.75043 8.15789 7.37502V6.2247ZM17.6316 11.2574H6.36842V16.2639C6.36842 17.0436 7.00465 17.6757 7.78947 17.6757H16.2105C16.9954 17.6757 17.6316 17.0436 17.6316 16.2639V11.2574Z" fill="#094172"/>
            </svg>
            <p class="fz13 text-red text-fail">Time of subs is due</p>
            <date-picker
                v-model="data.on_subs"
                format="DD.MM.YYYY, HH:mm"
                type="datetime"
                :placeholder="'On Subs'"
                class="on-subs-date"
                @focus="inputEditText = data.on_subs"
                @close="focusOutChangeText('on_subs', data.index, 'text')"
            >
            </date-picker>
            <svg v-on:click="openModalDeleteDate(data.index)" v-if="(''+data.on_subs).length !== 0 && data.on_subs" v-tooltip.top="'Fail'" class="clearDate" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.51424 7.57923C6.21661 7.28453 6.21661 6.80673 6.51424 6.51204C6.81186 6.21734 7.29441 6.21734 7.59204 6.51204L17.4868 16.298C17.7844 16.5927 17.7844 17.0705 17.4868 17.3652C17.1892 17.6599 16.7066 17.6599 16.409 17.3652L6.51424 7.57923Z" fill="#094172"/>
              <path d="M16.4091 6.63595C16.7067 6.34126 17.1893 6.34126 17.4869 6.63596C17.7845 6.93065 17.7845 7.40845 17.4869 7.70314L7.59214 17.4891C7.29451 17.7838 6.81196 17.7838 6.51434 17.4891C6.21671 17.1944 6.21671 16.7166 6.51434 16.4219L16.4091 6.63595Z" fill="#094172"/>
            </svg>
          </div>

          <button class="rejection-button fz13 fw500" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1" v-on:click="clickRejected(data, data.index)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18.6C15.6451 18.6 18.6 15.6451 18.6 12C18.6 8.35492 15.6451 5.4 12 5.4C8.35492 5.4 5.4 8.35492 5.4 12C5.4 15.6451 8.35492 18.6 12 18.6ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" fill="#E85353"/>
              <path d="M8.50001 9.83275C8.20239 9.53805 8.20239 9.06026 8.50002 8.76556C8.79764 8.47087 9.28019 8.47087 9.57782 8.76556L15.23 14.3089C15.5276 14.6036 15.5276 15.0814 15.23 15.3761C14.9323 15.6708 14.4498 15.6708 14.1522 15.3761L8.50001 9.83275Z" fill="#E85353"/>
              <path d="M14.1523 8.88948C14.4499 8.59478 14.9324 8.59478 15.2301 8.88948C15.5277 9.18418 15.5277 9.66197 15.2301 9.95667L9.57792 15.5C9.28029 15.7947 8.79774 15.7947 8.50012 15.5C8.20249 15.2053 8.20249 14.7275 8.50012 14.4328L14.1523 8.88948Z" fill="#E85353"/>
            </svg>
            Rejection
          </button>
        </div>
        <div class="dwt" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'" v-tooltip.top="data.dwt">
          <p class="fz14 text-dark" contenteditable="true" @input="inputEdit" @focus="focusInInput" @focusout="focusOutChangeText('dwt', data.index, 'number')" :key="keyRender" v-html="getTextSearched(data.dwt)">{{data.dwt}}</p>
        </div>
        <div class="years" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'" v-tooltip.top="data.year">
          <p class="fz14 text-dark" contenteditable="true" :key="keyRender" @focus="focusInInput" @input="inputEdit" @focusout="focusOutChangeText('year', data.index, 'number')" v-html="getTextSearched(data.year)">{{data.year}}</p>
        </div>
        <div class="per position-relative" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'" v-tooltip.top="data.fuel_use">
          <span class="fz14 text-gray symbol">%</span>
          <p class="fz14 text-dark" contenteditable="true" :key="keyRender" @input="inputEdit" @focus="focusInInput" @focusout="focusOutChangeText('fuel_use', data.index, 'text')" v-html="getTextSearched(data.fuel_use)">{{data.fuel_use}}</p>
        </div>
<!--        <div class="delivery" v-on:click="openSelectPortDrop(data.name + data.id)" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'" :class="{'add-title': getShowTitle(getTextForDelivery(data.delivery))}" v-tooltip.top="getTextForDelivery(data.delivery).replaceAll('<br/>', '\n')">-->
        <div class="delivery" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'" v-tooltip.top="data.delivery_placeholder">
          <p class="fz14 text-dark" contenteditable="true" :key="keyRender" @input="inputEditDelivery" @focus="focusEditDelivery(data.index, data.delivery_placeholder)" @focusout="focusOutChangeText('delivery_placeholder', data.index, 'text')" v-html="getTextSearched(data.delivery_placeholder)">{{data.delivery_placeholder}}</p>
          <div class="search-ship" v-if="data.showTemplateDelivery === true && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'" :key="keyRenderTemplate">
            <app-loader class="loader" v-if="searchDeliveryActive"></app-loader>
            <button class="fz14 text-blue-purple fw500" v-on:mousedown="setThisDelivery(item, data.index)" v-for="(item, j) in listShipsDeliverySearch" :key="j" v-html="getTextForNameDelivery(item)"></button>
            <p v-if="listShipsDeliverySearch.length === 0" class="fz14 text-dark fw500">List empty</p>
          </div>
<!--          <work-space-ports-->
<!--              :isMultiple="true"-->
<!--              v-model="data.delivery"-->
<!--              class="drop-ports-workspace"-->
<!--              @input="closeDrop(data.id, i)"-->
<!--              :delivery_placeholder="data.delivery_placeholder"-->
<!--              :index="i"-->
<!--              @editText="editTextDelivery"-->
<!--          >-->
<!--          </work-space-ports>-->
        </div>
        <div class="dates" :class="{ 'dates-cargo': getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP'}" v-tooltip.top="data.ship_arrival" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP'">
          <p class="fz14 text-dark" contenteditable="true" :key="keyRender" @input="inputEdit" @focus="focusInInput" @focusout="focusOutChangeText('ship_arrival', data.index, 'text')" v-html="getTextSearched(data.ship_arrival)">{{data.ship_arrival}}</p>
        </div>
        <div class="comment-cargo" v-if="getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'" v-tooltip.top="data.comment">
          <p class="fz14 text-dark" contenteditable="true" :key="keyRender" @input="inputEdit" @focus="focusInInput" @focusout="focusOutChangeText('comment', data.index, 'text')" v-html="getTextSearched(data.comment)">{{data.comment}}</p>
        </div>
        <div class="charterers-cargo" v-if="getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'" v-tooltip.top="data.charterers_idea">
          <p class="fz14 text-dark" contenteditable="true" :key="keyRender" @input="inputEdit" @focus="focusInInput" @focusout="focusOutChangeText('charterers_idea', data.index, 'text')" v-html="getTextSearched(data.charterers_idea)">{{data.charterers_idea}}</p>
        </div>
        <div class="owners" v-if="getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'" v-tooltip.top="data.comment">
          <p class="fz14 text-dark" contenteditable="true" :key="keyRender" @input="inputEdit" @focus="focusInInput" @focusout="focusOutChangeText('comment', data.index, 'text')" v-html="getTextSearched(data.comment)">{{data.comment}}</p>
          <!--          <p v-if="showFullInfo.indexOf(data.name + ''+ data.id) === -1" class="fz14 fw500">{{data.owners}}</p>-->
<!--          <button  v-on:click="showEditOwnersOpen(data.id, i, data.comment)" class="blue-button-text fz13 f2500">-->
<!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#094172"/>-->
<!--            </svg>-->
<!--            Edit-->
<!--          </button>-->
<!--          <p class="fz14 text-dark" v-if="data.comment !== null && showFullInfo.indexOf(data.name + ''+ data.id) === -1">{{data.comment.comment}}</p>-->
<!--          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.comment !== null">-->
<!--            <p class="fz13 text-gray">Comment</p>-->
<!--            <p class="fz14 text-dark">{{data.comment.comment}}</p>-->
<!--          </div>-->
<!--          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.comment !== null">-->
<!--            <p class="fz13 text-gray">Broker</p>-->
<!--            <p class="fz14 text-dark">{{data.comment.broker.company_name}}</p>-->
<!--          </div>-->
        </div>
        <div class="our" :class="{'our-cargo': getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'}" v-tooltip.top="data.comment_cargo">
          <p class="fz14 text-dark" contenteditable="true" :key="keyRender" @input="inputEdit" @focus="focusInInput" @focusout="focusOutChangeText('comment_cargo', data.index, 'text')" v-html="getTextSearched(data.comment_cargo)">{{data.comment_cargo}}</p>
        </div>
        <div class="clean" :key="keyRender" :class="{'clean-cargo': getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'}">
          <p class="fz14 text-dark" v-if="showFullInfo.indexOf(data.name + ''+ data.id) === -1">
            <span v-if="data.contract !== null" class="firstSpan">
              <svg class="svg-attach" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0835 6.61999C20.0835 5.41084 19.076 4.40332 17.8668 4.40332H6.1335C4.92435 4.40332 3.91683 5.41084 3.91683 6.61999V14.6867C3.91683 15.8958 4.92435 16.9033 6.1335 16.9033H6.60572V18.5978C6.60572 19.0081 6.8579 19.3769 7.24122 19.5253L7.24467 19.5266L7.24468 19.5266C7.3588 19.5701 7.47918 19.5922 7.60016 19.5922C7.83687 19.5922 8.06877 19.5074 8.25101 19.3495L11.8501 16.9033H17.8668C19.076 16.9033 20.0835 15.8958 20.0835 14.6867V6.61999ZM17.8668 5.90332C18.2475 5.90332 18.5835 6.23927 18.5835 6.61999V14.6867C18.5835 15.0674 18.2475 15.4033 17.8668 15.4033H11.6193C11.469 15.4033 11.3221 15.4485 11.1977 15.533L8.10572 17.6346V16.1533C8.10572 15.7391 7.76993 15.4033 7.35572 15.4033H6.1335C5.75278 15.4033 5.41683 15.0674 5.41683 14.6867V6.61999C5.41683 6.23927 5.75278 5.90332 6.1335 5.90332H17.8668Z" fill="#4B4B4B"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 9C7.75 8.58579 8.08579 8.25 8.5 8.25H15.5C15.9142 8.25 16.25 8.58579 16.25 9C16.25 9.41421 15.9142 9.75 15.5 9.75H8.5C8.08579 9.75 7.75 9.41421 7.75 9Z" fill="#4B4B4B"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 12C7.75 11.5858 8.08579 11.25 8.5 11.25H12C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75H8.5C8.08579 12.75 7.75 12.4142 7.75 12Z" fill="#4B4B4B"/>
                  </svg>
            </span>
            <span v-if="data.contract !== null">
                <svg v-if="data.contract.files.length" class="svg-attach" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 20C9.24268 20 7 17.7573 7 15V8C7 7.63135 7.29871 7.33337 7.66663 7.33337C8.03467 7.33337 8.33337 7.63135 8.33337 8V15C8.33337 17.0214 9.97803 18.6666 12 18.6666C14.022 18.6666 15.6666 17.0214 15.6666 15V7.66663C15.6666 6.38 14.62 5.33337 13.3334 5.33337C12.0466 5.33337 11 6.38 11 7.66663V14.3334C11 14.8846 11.4486 15.3334 12 15.3334C12.5514 15.3334 13 14.8846 13 14.3334V8C13 7.63135 13.2987 7.33337 13.6666 7.33337C14.0347 7.33337 14.3334 7.63135 14.3334 8V14.3334C14.3334 15.62 13.2866 16.6666 12 16.6666C10.7134 16.6666 9.66663 15.62 9.66663 14.3334V7.66663C9.66663 5.64539 11.3113 4 13.3334 4C15.3553 4 17 5.64539 17 7.66663V15C17 17.7573 14.7573 20 12 20Z" fill="#4B4B4B"/>
            </svg>
            </span>
          </p>
          <button v-on:click="openModalClean(data.id, data.index)" class="blue-button-text fz13 fw500">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1088 5.89833C17.5331 5.31995 16.7695 5 15.9528 5C15.1403 5 14.3726 5.31995 13.7969 5.89833L5.74055 13.9915C5.65071 14.0817 5.59763 14.1966 5.57721 14.3197L5.00555 18.3437C4.98105 18.5201 5.03822 18.7006 5.1648 18.8277C5.27097 18.9385 5.42205 19 5.57313 19C5.59763 19 5.61805 19 5.64255 18.9959L9.71358 18.4873C9.84017 18.4708 9.95858 18.4134 10.0484 18.3232L18.1088 10.2259C19.2971 9.02813 19.2971 7.092 18.1088 5.89833ZM9.37875 17.3674L6.24279 17.7571L6.68379 14.6683L13.5764 7.74421L16.2673 10.4474L9.37875 17.3674ZM17.3044 9.41781L17.0798 9.64342L14.3889 6.94023L14.6135 6.71462C14.9688 6.34544 15.4465 6.14855 15.9569 6.14855C16.4633 6.14855 16.9451 6.34544 17.3044 6.71052C17.6637 7.07559 17.8638 7.55142 17.8638 8.06417C17.8638 8.57281 17.6678 9.05684 17.3044 9.41781Z" fill="#094172"/>
            </svg>
            Edit
          </button>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null">
            <p class="fz13 text-gray" v-if="data.contract.broker !== null && data.contract.broker !== undefined">Broker</p>
            <p class="fz14 text-dark" v-if="data.contract.broker !== null && data.contract.broker !== undefined">{{data.contract.broker.company_name}}</p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null && data.contract !== null">
            <p class="fz13 text-gray">CP Date</p>
            <p class="fz14 text-dark">{{getTextDate(data.contract.cp_date)}}</p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null && data.contract !== null && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP' && data.contract.period !== null && (''+data.contract.period).length !== 0">
            <p class="fz13 text-gray">Period</p>
            <p class="fz14 text-dark">{{data.contract.period}}</p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null && data.contract !== null && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray" v-if="(''+data.contract.hire_rate).length !== 0 && data.contract.hire_rate !== null">Hire Rate</p>
            <p class="fz14 text-dark" v-if="(''+data.contract.hire_rate).length !== 0 && data.contract.hire_rate !== null">{{data.contract.hire_rate}} {{data.contract.currency}}</p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null && data.contract !== null">
            <p class="fz13 text-gray" v-if="data.contract.laycan_date !== null && (''+getLaycanDate(data.contract.laycan_date)).length !== 0">Laycan Date</p>
            <p class="fz14 text-dark" v-if="data.contract.laycan_date !== null && (''+getLaycanDate(data.contract.laycan_date)).length !== 0">{{getLaycanDate(data.contract.laycan_dat)}}</p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null && data.contract !== null && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray" v-if="data.contract.delivery !== null && (''+data.contract.delivery).length !== 0">Delivery Date</p>
            <p class="fz14 text-dark" v-if="data.contract.delivery !== null && (''+data.contract.delivery).length !== 0">{{getTextDateWithTime(data.contract.delivery)}}</p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null">
            <p class="fz13 text-gray" v-if="data.contract.delivery_place.length !== 0 && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">Delivery</p>
            <p class="fz13 text-gray" v-if="data.contract.delivery_place.length !== 0 && getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'">Port of Loading</p>
            <p class="fz14 text-dark" v-if="data.contract.delivery_place.length !== 0">{{data.contract.delivery_place[0].name}} <span  v-if="data.contract.delivery_place[0].country !== null && data.contract.delivery_place[0].country !== undefined">: {{data.contract.delivery_place[0].country}}</span></p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null && data.contract !== null && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">
            <p class="fz13 text-gray" v-if="data.contract.redelivery !== null && (''+data.contract.redelivery).length !== 0">Redelivery Date</p>
            <p class="fz14 text-dark" v-if="data.contract.redelivery !== null && (''+data.contract.redelivery).length !== 0">{{getTextDateWithTime(data.contract.redelivery)}}</p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null && data.contract !== null">
            <p class="fz13 text-gray" v-if="data.contract.redelivery_place.length !== 0 && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP'">Redelivery</p>
            <p class="fz13 text-gray" v-if="data.contract.redelivery_place.length !== 0 && getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP'">Port of Discharge</p>
            <p class="fz14 text-dark" v-if="data.contract.redelivery_place.length !== 0">{{data.contract.redelivery_place[0].name}} <span v-if="data.contract.redelivery_place[0].country !== null && data.contract.redelivery_place[0].country !== undefined">: {{data.contract.redelivery_place[0].country}}</span></p>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && getShowChartering(data) && data.contract !== null">
            <p class="fz13 text-gray">Chartering manager</p>
            <div class="user">
              <img v-if="data.contract.chartering_manager.avatar !== null && data.contract.localInfo === true" :src="getUrlUser(data.contract.chartering_manager.avatar)" class="img-profile" alt="">
              <img v-if="data.contract.chartering_manager.avatar !== null && data.contract.localInfo !== true" :src="getUrlUser(data.contract.chartering_manager.avatar)" class="img-profile" alt="">
              <svg v-if="data.contract.chartering_manager.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="80" cy="80" r="80" fill="white"/>
                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
              </svg>
              <p class="fz14 text-dark">{{data.contract.chartering_manager.first_name}} {{data.contract.chartering_manager.last_name}}</p>
            </div>
          </div>
          <div class="block-info-stroke" v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && getShowOperations(data)">
            <p class="fz13 text-gray">Operation manager</p>
            <div class="user">
              <img v-if="data.contract.operation_manager.avatar !== null && data.contract.localInfo === true" :src="getUrlUser(data.contract.operation_manager.avatar)" class="img-profile" alt="">
              <img v-if="data.contract.operation_manager.avatar !== null && data.contract.localInfo !== true" :src="getUrlUser(data.contract.operation_manager.avatar)" class="img-profile" alt="">
              <svg v-if="data.contract.operation_manager.avatar === null" class="img-profile" width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="80" cy="80" r="80" fill="white"/>
                <path d="M80 0.00305176C35.8218 0.00305176 0 35.8179 0 79.9995C0 124.181 35.8183 159.996 80 159.996C124.185 159.996 160 124.181 160 79.9995C160 35.8179 124.185 0.00305176 80 0.00305176ZM80 23.9229C94.6183 23.9229 106.464 35.7722 106.464 50.3834C106.464 64.9982 94.6183 76.844 80 76.844C65.3887 76.844 53.543 64.9982 53.543 50.3834C53.543 35.7722 65.3887 23.9229 80 23.9229ZM79.9824 139.081C65.4028 139.081 52.0495 133.771 41.75 124.982C39.241 122.842 37.7932 119.704 37.7932 116.412C37.7932 101.593 49.7865 89.7334 64.6086 89.7334H95.3984C110.224 89.7334 122.172 101.593 122.172 116.412C122.172 119.708 120.731 122.839 118.218 124.979C107.922 133.771 94.5656 139.081 79.9824 139.081Z" fill="#DEE3EA"/>
              </svg>
              <p class="fz14 text-dark">{{data.contract.operation_manager.first_name}} {{data.contract.operation_manager.last_name}}</p>
            </div>
          </div>
          <payment-detail-atachements-file
              :key="keyRenderFile"
              v-if="showFullInfo.indexOf(data.name + ''+ data.id) !== -1 && data.contract !== null"
              ref="fileNormal"
              class="file-attachments"
              :class-for-title="'fz13 text-gray mt12'"
              :file-props="getFileForShip(data.contract.files)"
              :visible-add-file="false"
              :await-delete-file="false"
              @fileDeleted="fileDeleteWithContract"
              @beforeFileDeleted="setDataForDeleteFile(data.index)"
          >
          </payment-detail-atachements-file>
        </div>
        <button class="delete-ship" v-on:click="showModalDeleteShip(data.id)" v-if="data.name">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6364 4.36585C10.5073 4.36585 10.4091 4.46907 10.4091 4.58852V5.26399H13.5909V4.58852C13.5909 4.46907 13.4927 4.36585 13.3636 4.36585L10.6364 4.36585ZM14.9545 5.26399V4.58852C14.9545 3.70768 14.2387 3 13.3636 3L10.6364 3C9.76125 3 9.04545 3.70768 9.04545 4.58852V5.26399H5.18182C4.80526 5.26399 4.5 5.56975 4.5 5.94692C4.5 6.32409 4.80526 6.62984 5.18182 6.62984H6.31818V16.5059C6.31818 17.8869 7.441 19 8.81818 19H15.1818C16.559 19 17.6818 17.8869 17.6818 16.5059V6.62984H18.8182C19.1947 6.62984 19.5 6.32409 19.5 5.94692C19.5 5.56975 19.1947 5.26399 18.8182 5.26399H14.9545ZM7.68182 6.62984V16.5059C7.68182 17.1255 8.18706 17.6341 8.81818 17.6341H15.1818C15.8129 17.6341 16.3182 17.1255 16.3182 16.5059V6.62984H7.68182ZM10.6364 8.88637C11.0129 8.88637 11.3182 9.19213 11.3182 9.5693V14.5501C11.3182 14.9272 11.0129 15.233 10.6364 15.233C10.2598 15.233 9.95455 14.9272 9.95455 14.5501V9.5693C9.95455 9.19213 10.2598 8.88637 10.6364 8.88637ZM13.3636 8.88637C13.7402 8.88637 14.0455 9.19213 14.0455 9.5693V14.5501C14.0455 14.9272 13.7402 15.233 13.3636 15.233C12.9871 15.233 12.6818 14.9272 12.6818 14.5501V9.5693C12.6818 9.19213 12.9871 8.88637 13.3636 8.88637Z" fill="#2C2C2C"/>
          </svg>
        </button>
      </div>

<!--      <div class="bottom-panel svg-white-dart">-->
<!--        <button v-on:click="addCargo">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M17.3333 11.3333H12.6667V6.66662C12.6667 6.2987 12.368 6 11.9999 6C11.632 6 11.3333 6.2987 11.3333 6.66662V11.3333H6.66662C6.2987 11.3333 6 11.632 6 11.9999C6 12.368 6.2987 12.6667 6.66662 12.6667H11.3333V17.3333C11.3333 17.7013 11.632 18 11.9999 18C12.368 18 12.6667 17.7013 12.6667 17.3333V12.6667H17.3333C17.7013 12.6667 18 12.368 18 11.9999C18 11.632 17.7013 11.3333 17.3333 11.3333Z" fill="#094172"/>-->
<!--          </svg>-->
<!--        </button>-->
<!--      </div>-->
    </div>
    <div class="panel-move-mode" v-if="selectedShips.length">
      <p class="fz14 text-dark">Selected: {{selectedShips.length}}</p>
      <button class="button-blue-border h32 ml-auto w130" v-on:click="showDeleteAllModal = true">Delete</button>
      <button class="blue-button h32 buttonW130 ml16" v-on:click="showModalMoveMode">Import to</button>
      <button class="move-close" v-on:click="moveModeFalse">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.51375 7.57923C6.21612 7.28453 6.21612 6.80673 6.51375 6.51204C6.81138 6.21734 7.29392 6.21734 7.59155 6.51204L17.4863 16.298C17.784 16.5927 17.784 17.0705 17.4863 17.3652C17.1887 17.6599 16.7062 17.6599 16.4085 17.3652L6.51375 7.57923Z" fill="#222222"/>
          <path d="M16.4086 6.63595C16.7063 6.34126 17.1888 6.34126 17.4864 6.63596C17.7841 6.93065 17.7841 7.40845 17.4864 7.70314L7.59165 17.4891C7.29402 17.7838 6.81148 17.7838 6.51385 17.4891C6.21622 17.1944 6.21622 16.7166 6.51385 16.4219L16.4086 6.63595Z" fill="#222222"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import DatePicker from 'vue2-datepicker';

import CounterpartiesDrop from "@/components/counterparties/counterpartiesAdd/counterpartiesDrop";
import ModalSlots from "@/components/modal/modal-slots";
import SelectOnTheMap from "@/components/tasks/manually-task-add/selectOnTheMap/selectOnTheMap";
import CheckboxesForModal from "@/components/tasks/vessel-task/checkboxes-for-modal";
import moment from 'moment'

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import PaymentDetailAtachementsFile from "@/components/paymentDetail/paymentDetailAtachementsFile";
import ShipChat from "@/components/tasks/vessel-task/ship-chat";
import ManuallyTaskAdd from "@/components/tasks/manually-task-add/manually-task-add";
import AppLoader from "@/components/loader";
import AsyncSelectPort from "@/components/reuse/asyncSelectPort";
import AsyncSelect from "@/components/reuse/asyncSelect";
import PaginationPanelDefault from "@/components/ports/paginationDefault";
import AsyncSelectEmployees from "@/components/tasks/async-select-employees";
import addModal from '@/components/ports/list/add'
import CounterpartiesAdd from "@/views/counterparties/counterpartiesAdd";

// import Vue from "vue";
export default {
  name: "vessel-workspace",
  components: {
    CounterpartiesAdd,
    addModal,
    AsyncSelectEmployees,
    PaginationPanelDefault,
    AsyncSelect,
    AsyncSelectPort,
    AppLoader,
    ManuallyTaskAdd,
    ShipChat, PaymentDetailAtachementsFile, CheckboxesForModal, DatePicker, SelectOnTheMap, ModalSlots, CounterpartiesDrop, vueDropzone: vue2Dropzone,},
  computed: {
    ...mapGetters(["getLimit","getLoadRequestFilterTask", "getCountTask", "getTasksList","getAllTag", "getEmployees","getMoveMode", "getLoginData", "getComment", "getPortsForVessel", "getShipsListTemplate", "getVesselTaskInfo", "getShipsList"]),
    ...mapState({
      clients: state => state.counterparties.clientsDrop,
    }),
    getCountAvailableCheckbox() {
      return this.listShips.filter(a=> a.name.length !== 0).length
    },
    getClientsFormAddModal() {
      let clients = [];
      clients = this.clients.map(a=> {
        return {id: a.id, name: a.company_name}
      })
      return clients
    },
    getFilteredList() {
      this.listShips.forEach(item=> {
        item['is_search'] = false
      })
      if(!this.search) {
        return this.listShips
      }
      let array1 = this.listShips.filter(a=> (a.name + '*'+a.dwt+'*'+a.year+'*'+a.fuel_use+'*'+a.delivery_placeholder+'*'+a.ship_arrival+'*'+a.comment+'*'+a.charterers_idea+'*'+a.comment_cargo).toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
      let array2 = this.listShips.filter(a=> (a.name + '*'+a.dwt+'*'+a.year+'*'+a.fuel_use+'*'+a.delivery_placeholder+'*'+a.ship_arrival+'*'+a.comment+'*'+a.charterers_idea+'*'+a.comment_cargo).toLowerCase().indexOf(this.search.toLowerCase()) === -1)
      array1.forEach(item=> {
        item['is_search'] = true
      })

      return array1.concat(array2)
    },
  },
  data() {
    return {
      inputEditTextCopy: '',
      indexChangeVesselName: null,
      notCheckWayCreated: false,
      showModalSelectedWayCreateContract: false,
      showDeleteAllModal: false,
      search: '',
      dragStartEvent: null,
      showModalDeleteDate: false,
      listShipsDeliverySearch: [],
      port_of_loading: null,
      port_of_discharge: null,
      searchShipActive: false,
      showSelectPort: "",
      errorOwners: false,
      searchDeliveryActive: false,
      list: [
        {name: "Majulah Harbourfront", dwt: "260 000, 032", years: 2007, per: 100, delivery: "ort Of Porbandar", dates: "28/30 of jan", owners: "Comment text for Owners Idea", our: "Good price", on_subs: "Comment text for On Subs"},
        {name: "Majulah Harbont", dwt: "260 000, 032", years: 2007, per: 100, delivery: "ort Of Porbandar", dates: "28/30 of jan", owners: "Comment ", our: "Good price", on_subs: "Comment "},
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      regionList: [
        {name: "AG", id: "AG"},
        {name: "ARAG", id: "ARAG"},
        {name: "BALTIC", id: "BALTIC"},
        {name: "BLACK SEA", id: "BLACK SEA"},
        {name: "CARIBS", id: "CARIBS"},
        {name: "CASPIAN", id: "CASPIAN"},
        {name: "CENTRAL ATLANTIC", id: "CENTRAL ATLANTIC"},
        {name: "E AFRICA", id: "E AFRICA"},
        {name: "E AUSSIE", id: "E AUSSIE"},
        {name: "E MED", id: "E MED"},
        {name: "EC CAN", id: "EC CAN"},
        {name: "EC INDIA", id: "EC INDIA"},
        {name: "ECCA", id: "ECCA"},
        {name: "ECSA", id: "ECSA"},
        {name: "FEAST", id: "FEAST"},
        {name: "French Atl", id: "French Atl"},
        {name: "GREAT LAKES", id: "GREAT LAKES"},
        {name: "INDIAN OCEAN", id: "INDIAN OCEAN"},
        {name: "MID PACIFIC", id: "MID PACIFIC"},
        {name: "MIDNORTH ATLANTIC", id: "MIDNORTH ATLANTIC"},
        {name: "N CONT", id: "N CONT"},
        {name: "NCSA", id: "NCSA"},
        {name: "NEST PASSAGE", id: "NEST PASSAGE"},
        {name: "NOPAC", id: "NOPAC"},
        {name: "NW AFRICA", id: "NW AFRICA"},
        {name: "NWST PASSAGE", id: "NWST PASSAGE"},
        {name: "NZ", id: "NZ"},
        {name: "RED SEA", id: "RED SEA"},
        {name: "SE ASIA", id: "SE ASIA"},
        {name: "ST LAWRENCE", id: "ST LAWRENCE"},
        {name: "STH AFRICA", id: "STH AFRICA"},
        {name: "STH ANTARCTICA", id: "STH ANTARCTICA"},
        {name: "STH INDIAN OCEAN", id: "STH INDIAN OCEAN"},
        {name: "STH PACIFIC", id: "STH PACIFIC"},
        {name: "SW AFRICA", id: "SW AFRICA"},
        {name: "Spain Atl", id: "Spain Atl"},
        {name: "UK EIRE", id: "UK EIRE"},
        {name: "USEC", id: "USEC"},
        {name: "ASG", id: "ASG"},
        {name: "W AUSSIE", id: "W AUSSIE"},
        {name: "W MED", id: "W MED"},
        {name: "WC INDIA", id: "WC INDIA"},
        {name: "WCCA", id: "WCCA"},
        {name: "WCSA", id: "WCSA"},
      ],
      keyRenderTemplate: -933,
      defaultRegionActive: {},
      activeRegion: {},
      listCheckboxes: [
        {name: "Agnes/160 054,213/2007/100%", id:1},
        {name: "Agnes/160 054,213/2007/100%", id:12},
        {name: "Agnes/160 054,213/2007/100%", id:13},
        {name: "Agnes/160 054,213/2007/100%", id:14},
        {name: "Agnes/160 054,213/2007/100%", id:15},
        {name: "Agnes/160 054,213/2007/100%", id:16},
        {name: "Agnes/160 054,213/2007/100%", id:17},
        {name: "Agnes/160 054,213/2007/100%", id:18},
        {name: "Agnes/160 054,213/2007/100%", id:19},
        {name: "Agnes/160 054,213/2007/100%", id:10},
        {name: "Agnes/160 054,213/2007/100%", id:0},
        {name: "Agnes/160 054,213/2007/100%", id:2},
        {name: "Agnes/160 054,213/2007/100%", id:3},
        {name: "Agnes/160 054,213/2007/100%", id:4},
        {name: "Agnes/160 054,213/2007/100%", id:5},
        {name: "Agnes/160 054,213/2007/100%", id:6},
        {name: "Agnes/160 054,213/2007/100%", id:7},
        {name: "Agnes/160 054,213/2007/100%", id:8},
        {name: "Agnes/160 054,213/2007/100%", id:9},
        {name: "Agnes/160 054,213/2007/100%", id:22},
        {name: "Agnes/160 054,213/2007/100%", id:21},
        {name: "Agnes/160 054,213/2007/100%", id:23},
        {name: "Agnes/160 054,213/2007/100%", id:24},
        {name: "Agnes/160 054,213/2007/100%", id:25},
        {name: "Agnes/160 054,213/2007/100%", id:26},
        {name: "Agnes/160 054,213/2007/100%", id:27},
        {name: "Agnes/160 054,213/2007/100%", id:28},
        {name: "Agnes/160 054,213/2007/100%", id:29},
        {name: "Agnes/160 054,213/2007/100%", id:302},
      ],
      acceptCreate: true,
      listSameContract: [],
      interval: null,
      showCandidates: false,
      showEditOwners: false,
      showModalChat: false,
      editOwnersComment: "",
      showModalEditClean: false,
      CPDate: "",
      showTimePanel: false,
      deliveryDate: "",
      redeliveryDate: "",
      showFullInfo: [],
      inputEditText: "",
      listShips: [],
      keyRender: -9999,
      activePort: null,
      ports: [],
      idShipForModal: null,
      indexShipForModal: null,
      activeBroker: null,
      defaultActiveBroker: null,
      idCommentModal: null,
      vesselNameEdit: "",
      builtEdit: "",
      dwtEdit: "",
      perEdit: "",
      defaultOwnerActive: null,
      activeOwner: null,
      deliveryActive: null,
      redeliveryActive: null,
      defaultDeliveryActive: null,
      defaultRedeliveryActive: null,
      activeValuta: null,
      defaultActiveValuta: {name: "USD", id: "USD"},
      laycanDateEdit: "",
      hireEdit: "",
      assignedOperations: null,
      assignedChartering: null,
      showAddClient: false,
      commentEdit: "",
      periodEdit: "",
      defaultActiveOperations: null,
      defaultActiveChartering: null,
      errorCPDate: false,
      keyRenderChat: -999123,
      keyRenderFile: 3242343,
      selectedShips: [],
      showMoveTask: false,
      moveTaskLoader: false,
      taskMoveModeModal: null,
      showAcceptMoveModalTask: false,
      cargoCommentEdit: "",
      axiosBool: false,
      listShipsTemplateSearch: [],
      keyRenderList: -932432,
      errorName: false,
      errorFile: false,
      showModalDelete: false,
      idForDeleteModal: null,
      keyRenderPorts: -657124,
      isShowModal: false,
      valutaList: [
        {
          "name": "USD",
          "id": "USD"
        },
        {
          "name": "ZMW",
          "id": "ZMW"
        },
        {
          "name": "ZAR",
          "id": "ZAR"
        },
        {
          "name": "YER",
          "id": "YER"
        },
        {
          "name": "XPF",
          "id": "XPF"
        },
        {
          "name": "XOF",
          "id": "XOF"
        },
        {
          "name": "XCD",
          "id": "XCD"
        },
        {
          "name": "XAF",
          "id": "XAF"
        },
        {
          "name": "WST",
          "id": "WST"
        },
        {
          "name": "VUV",
          "id": "VUV"
        },
        {
          "name": "VND",
          "id": "VND"
        },
        {
          "name": "VES",
          "id": "VES"
        },
        {
          "name": "UZS",
          "id": "UZS"
        },
        {
          "name": "UYU",
          "id": "UYU"
        },
        {
          "name": "UGX",
          "id": "UGX"
        },
        {
          "name": "UAH",
          "id": "UAH"
        },
        {
          "name": "TZS",
          "id": "TZS"
        },
        {
          "name": "TWD",
          "id": "TWD"
        },
        {
          "name": "TVD",
          "id": "TVD"
        },
        {
          "name": "TTD",
          "id": "TTD"
        },
        {
          "name": "TRY",
          "id": "TRY"
        },
        {
          "name": "TOP",
          "id": "TOP"
        },
        {
          "name": "TND",
          "id": "TND"
        },
        {
          "name": "TMT",
          "id": "TMT"
        },
        {
          "name": "TJS",
          "id": "TJS"
        },
        {
          "name": "THB",
          "id": "THB"
        },
        {
          "name": "SZL",
          "id": "SZL"
        },
        {
          "name": "SYP",
          "id": "SYP"
        },
        {
          "name": "STN",
          "id": "STN"
        },
        {
          "name": "SSP",
          "id": "SSP"
        },
        {
          "name": "SRD",
          "id": "SRD"
        },
        {
          "name": "SOS",
          "id": "SOS"
        },
        {
          "name": "SLL",
          "id": "SLL"
        },
        {
          "name": "SHP",
          "id": "SHP"
        },
        {
          "name": "SGD",
          "id": "SGD"
        },
        {
          "name": "SEK",
          "id": "SEK"
        },
        {
          "name": "SDG",
          "id": "SDG"
        },
        {
          "name": "SCR",
          "id": "SCR"
        },
        {
          "name": "SBD",
          "id": "SBD"
        },
        {
          "name": "SAR",
          "id": "SAR"
        },
        {
          "name": "RWF",
          "id": "RWF"
        },
        {
          "name": "RUB",
          "id": "RUB"
        },
        {
          "name": "RSD",
          "id": "RSD"
        },
        {
          "name": "RON",
          "id": "RON"
        },
        {
          "name": "QAR",
          "id": "QAR"
        },
        {
          "name": "PYG",
          "id": "PYG"
        },
        {
          "name": "PRB",
          "id": "PRB"
        },
        {
          "name": "PND",
          "id": "PND"
        },
        {
          "name": "PLN",
          "id": "PLN"
        },
        {
          "name": "PKR",
          "id": "PKR"
        },
        {
          "name": "PHP",
          "id": "PHP"
        },
        {
          "name": "PGK",
          "id": "PGK"
        },
        {
          "name": "PEN",
          "id": "PEN"
        },
        {
          "name": "PAB",
          "id": "PAB"
        },
        {
          "name": "OMR",
          "id": "OMR"
        },
        {
          "name": "NZD",
          "id": "NZD"
        },
        {
          "name": "NPR",
          "id": "NPR"
        },
        {
          "name": "NOK",
          "id": "NOK"
        },
        {
          "name": "NIO",
          "id": "NIO"
        },
        {
          "name": "NGN",
          "id": "NGN"
        },
        {
          "name": "NAD",
          "id": "NAD"
        },
        {
          "name": "MZN",
          "id": "MZN"
        },
        {
          "name": "MYR",
          "id": "MYR"
        },
        {
          "name": "MXN",
          "id": "MXN"
        },
        {
          "name": "MWK",
          "id": "MWK"
        },
        {
          "name": "MVR",
          "id": "MVR"
        },
        {
          "name": "MUR",
          "id": "MUR"
        },
        {
          "name": "MRU",
          "id": "MRU"
        },
        {
          "name": "MOP",
          "id": "MOP"
        },
        {
          "name": "MNT",
          "id": "MNT"
        },
        {
          "name": "MMK",
          "id": "MMK"
        },
        {
          "name": "MKD",
          "id": "MKD"
        },
        {
          "name": "MGA",
          "id": "MGA"
        },
        {
          "name": "MDL",
          "id": "MDL"
        },
        {
          "name": "MAD",
          "id": "MAD"
        },
        {
          "name": "LYD",
          "id": "LYD"
        },
        {
          "name": "LSL",
          "id": "LSL"
        },
        {
          "name": "LRD",
          "id": "LRD"
        },
        {
          "name": "LKR",
          "id": "LKR"
        },
        {
          "name": "LBP",
          "id": "LBP"
        },
        {
          "name": "LAK",
          "id": "LAK"
        },
        {
          "name": "KZT",
          "id": "KZT"
        },
        {
          "name": "KYD",
          "id": "KYD"
        },
        {
          "name": "KWD",
          "id": "KWD"
        },
        {
          "name": "KRW",
          "id": "KRW"
        },
        {
          "name": "KPW",
          "id": "KPW"
        },
        {
          "name": "KMF",
          "id": "KMF"
        },
        {
          "name": "KHR",
          "id": "KHR"
        },
        {
          "name": "KGS",
          "id": "KGS"
        },
        {
          "name": "KES",
          "id": "KES"
        },
        {
          "name": "JPY",
          "id": "JPY"
        },
        {
          "name": "JOD",
          "id": "JOD"
        },
        {
          "name": "JMD",
          "id": "JMD"
        },
        {
          "name": "ISK",
          "id": "ISK"
        },
        {
          "name": "IRR",
          "id": "IRR"
        },
        {
          "name": "IQD",
          "id": "IQD"
        },
        {
          "name": "INR",
          "id": "INR"
        },
        {
          "name": "ILS",
          "id": "ILS"
        },
        {
          "name": "IDR",
          "id": "IDR"
        },
        {
          "name": "HUF",
          "id": "HUF"
        },
        {
          "name": "HTG",
          "id": "HTG"
        },
        {
          "name": "HRK",
          "id": "HRK"
        },
        {
          "name": "HNL",
          "id": "HNL"
        },
        {
          "name": "HKD",
          "id": "HKD"
        },
        {
          "name": "GYD",
          "id": "GYD"
        },
        {
          "name": "GTQ",
          "id": "GTQ"
        },
        {
          "name": "GNF",
          "id": "GNF"
        },
        {
          "name": "GMD",
          "id": "GMD"
        },
        {
          "name": "GIP",
          "id": "GIP"
        },
        {
          "name": "GHS",
          "id": "GHS"
        },
        {
          "name": "GGP",
          "id": "GGP"
        },
        {
          "name": "GEL",
          "id": "GEL"
        },
        {
          "name": "GBP",
          "id": "GBP"
        },
        {
          "name": "FOK",
          "id": "FOK"
        },
        {
          "name": "FKP",
          "id": "FKP"
        },
        {
          "name": "FJD",
          "id": "FJD"
        },
        {
          "name": "EUR",
          "id": "EUR"
        },
        {
          "name": "ETB",
          "id": "ETB"
        },
        {
          "name": "ERN",
          "id": "ERN"
        },
        {
          "name": "EGP",
          "id": "EGP"
        },
        {
          "name": "DZD",
          "id": "DZD"
        },
        {
          "name": "DOP",
          "id": "DOP"
        },
        {
          "name": "DKK",
          "id": "DKK"
        },
        {
          "name": "DJF",
          "id": "DJF"
        },
        {
          "name": "CZK",
          "id": "CZK"
        },
        {
          "name": "CVE",
          "id": "CVE"
        },
        {
          "name": "CUP",
          "id": "CUP"
        },
        {
          "name": "CUC",
          "id": "CUC"
        },
        {
          "name": "CRC",
          "id": "CRC"
        },
        {
          "name": "COP",
          "id": "COP"
        },
        {
          "name": "CNY",
          "id": "CNY"
        },
        {
          "name": "CLP",
          "id": "CLP"
        },
        {
          "name": "CKD",
          "id": "CKD"
        },
        {
          "name": "CHF",
          "id": "CHF"
        },
        {
          "name": "CDF",
          "id": "CDF"
        },
        {
          "name": "CAD",
          "id": "CAD"
        },
        {
          "name": "BZD",
          "id": "BZD"
        },
        {
          "name": "BYN",
          "id": "BYN"
        },
        {
          "name": "BWP",
          "id": "BWP"
        },
        {
          "name": "BTN",
          "id": "BTN"
        },
        {
          "name": "BSD",
          "id": "BSD"
        },
        {
          "name": "BRL",
          "id": "BRL"
        },
        {
          "name": "BOB",
          "id": "BOB"
        },
        {
          "name": "BND",
          "id": "BND"
        },
        {
          "name": "BMD",
          "id": "BMD"
        },
        {
          "name": "BIF",
          "id": "BIF"
        },
        {
          "name": "BHD",
          "id": "BHD"
        },
        {
          "name": "BGN",
          "id": "BGN"
        },
        {
          "name": "BDT",
          "id": "BDT"
        },
        {
          "name": "BBD",
          "id": "BBD"
        },
        {
          "name": "BAM",
          "id": "BAM"
        },
        {
          "name": "AZN",
          "id": "AZN"
        },
        {
          "name": "AWG",
          "id": "AWG"
        },
        {
          "name": "AUD",
          "id": "AUD"
        },
        {
          "name": "ARS",
          "id": "ARS"
        },
        {
          "name": "AOA",
          "id": "AOA"
        },
        {
          "name": "ANG",
          "id": "ANG"
        },
        {
          "name": "AMD",
          "id": "AMD"
        },
        {
          "name": "ALL",
          "id": "ALL"
        },
        {
          "name": "AFN",
          "id": "AFN"
        },
        {
          "name": "AED",
          "id": "AED"
        }
      ],

    }
  },
  watch: {
    // search: function () {
    //   this.sortOldList()
    // },
    getVesselTaskInfo: function () {
      // this.listShips = this.getVesselTaskInfo.ships;
      this.sortNewList()
      if(this.clients.length !== 0) {
        this.listShips = this.listShips.map(a=> {
          if(a.contract !== null) {
            if(a.contract.owner !== null) {
              let value = this.clients.filter(b=> b.id === a.contract.owner);
              if(value.length !== 0) {
                // console.log("OWN")
                // console.log(value)
                a.contract.owner = value[0];
              }
            }
          }
          a['on_subs_status'] = this.checkDate(a.on_subs);
          return a
        })
      }
    },
    getMoveMode: function () {
      if(!this.getMoveMode) {
        this.selectedShips = []
      }
    },
    async $route(){
      this.moveModeFalse();
      if(!this.clients.length) {
        this.axiosGetClientsForDropdown();
      }
      let textRoute = this.$route.path;
      let idTask = textRoute.replace(/[^0-9]/gim,'');
      this.showLoad();
      await this.getAxiosInfoForVesselTask(idTask).then(()=> {
        if(this.getVesselTaskInfo !== null ) {
          if(this.getVesselTaskInfo.ships.length < 30) {
            for (let i=0; i<29 - this.getVesselTaskInfo.ships.length; i++) {
              this.addCargo();
              // this.axiosCreateSearchShipRESPAsync({data: {name: "", region: "AG"}, task: this.getVesselTaskInfo.task.id})
            }

          } else {
            // console.log(this.getVesselTaskInfo.ships);
            let instance_ids = this.getVesselTaskInfo.ships.map(a=> {return a.id})
            let form = {
              "instance_ids": instance_ids,
              "room_type": "out_offer"
            }
            // console.log(form)
            this.getAxiosCommentForChat(form).then(()=> {
              this.getComment.messages.map(b=> {
                b.views = b.views.map(c=> {
                  return c.id
                })
              })
              this.listShips = this.listShips.map(a=> {
                a.chat = this.getComment.messages.filter(b=> b.chat.instance_id === a.id);
                return a;
              })
            })
          }
        }
        this.hideLoad();
      });
    }
  },
   methods: {
     ...mapActions(["axiosArchiveContractByIDS", "axiosGetAllContractsLocal", "axiosSearchShipDelivery", "axiosDeleteShipsWorkSpace", "axiosSearchShipTemplate", "axiosCreateSearchShipRESPAsync", "axiosCreateSearchShipRESP", "axiosShipDuplicate", "axiosGetTasksForFilter", "axiosGetEmployees", "getAxiosAllTag", "axiosGetDepartments", "axiosGetPriorityTask","getAxiosCommentForChat", "axiosChangeContractForShip", "axiosCreatContractForShip", "axiosChangeCommentForShip", "axiosCreateCommentForShip", "getAxiosPortsForVessel", "getAxiosShipsTemplate", "axiosCreateSearchShip", "axiosChangeShip", "axiosGetClientsForDropdown", "getAxiosInfoForVesselTask", "getAxiosShips"]),
     ...mapMutations(["addTip", "showLoad", "hideLoad", "setLimit", "moveModeChangeFalse", "conversionForTasks", "taskRemoveById"]),
     checkFocusOutName(index) {
       if(this.listShips[index].name !== this.inputEditText && this.getVesselTaskInfo.task.type === 'SHIP_SEARCH_WITH_CARGO') {
         this.inputEditTextCopy = this.inputEditText
         let arrayDuplicate = this.listShips.filter(a=> a.name === this.inputEditText)
         // console.log(arrayDuplicate.length)
         if(arrayDuplicate.length > 0) {
           this.listSameContract = arrayDuplicate.map(a=> {return a.id})
           this.showModalSelectedWayCreateContract = true
           this.indexChangeVesselName = index
         } else {
           this.notCheckWayCreated = true
           this.focusOutChangeText('name', index, 'text')
         }
       } else if(this.getVesselTaskInfo.task.type !== 'SHIP_SEARCH_WITH_CARGO') {
         this.notCheckWayCreated = true
         this.focusOutChangeText('name', index, 'text')
       }
       // console.log(this.listShips[index].name, this.inputEditText)
       // this.listShips[index]
       // this.focusOutChangeText('name', index, 'text')
       // this.showModalSelectedWayCreateContract = true
     },
     createDuplicateWay() {
       // this.acceptCreate = true
       this.notCheckWayCreated = true
       // this.applyCleanFixed()
       this.inputEditText = this.inputEditTextCopy ? this.inputEditTextCopy : ''
       this.focusOutChangeText('name', this.indexChangeVesselName, 'text')
       this.indexChangeVesselName = null
       this.inputEditTextCopy = ''
       this.showModalSelectedWayCreateContract = false;
     },
     replaceVesselWay() {
       // this.axiosArchiveContractByIDS(this.listSameContract.map(a=> {return a.id}))
       // this.acceptCreate = true
       if(this.changeShipProcess) {
         let data = {};
         this.inputEditText = ''
         data["name"] = ''
         data["dwt"] = null
         data["year"] = null
         this.axiosChangeShip({id: this.listShips[this.indexChangeVesselName].id, data: data}).then(rsp=> {
           if(rsp !== false) {
             this.listShips[this.indexChangeVesselName]["name"] = data["name"];
             this.listShips[this.indexChangeVesselName]["dwt"] = data["dwt"];
             this.listShips[this.indexChangeVesselName]["year"] = data["year"];
             // this.listShips[index]["region"] = data["region"];
             // this.sortOldList()
             this.keyRender++;
             this.notCheckWayCreated = false
             this.inputEditText = this.inputEditTextCopy ? this.inputEditTextCopy : ''
             this.focusOutChangeText('name', this.indexChangeVesselName, 'text')
             for(let i=0; i<this.listSameContract.length-1; i++) {
               this.axiosDeleteShipsWorkSpace(this.listSameContract[i])
               this.listShips = this.listShips.filter(a=> a.id !== this.listSameContract[i])
             }
             this.sortOldList()
             this.listSameContract = []
             this.indexChangeVesselName = null
             // this.applyCleanFixed()
             this.inputEditTextCopy = null
             this.showModalSelectedWayCreateContract = false;
           }
         });
       } else {
         this.keyRender++;
         this.notCheckWayCreated = false
         this.inputEditText = this.inputEditTextCopy ? this.inputEditTextCopy : ''
         this.focusOutChangeText('name', this.indexChangeVesselName, 'text')
         for(let i=0; i<this.listSameContract.length-1; i++) {
           this.axiosDeleteShipsWorkSpace(this.listSameContract[i])
           this.listShips = this.listShips.filter(a=> a.id !== this.listSameContract[i])
         }
         this.sortOldList()
         this.listSameContract = []
         this.indexChangeVesselName = null
         // this.applyCleanFixed()
         this.inputEditTextCopy = null
         this.showModalSelectedWayCreateContract = false;
       }

     },
     closeSelectedWayCreatedModal() {
       console.log(this.changeShipProcess)

       this.showModalSelectedWayCreateContract = false;
       this.listSameContract = []
       this.notCheckWayCreated = false
       if(this.changeShipProcess) {
         let data = {};
         this.inputEditText = ''
         data["name"] = ''
         data["dwt"] = null
         data["year"] = null
         this.axiosChangeShip({id: this.listShips[this.indexChangeVesselName].id, data: data}).then(rsp=> {
           if(rsp !== false) {
             this.listShips[this.indexChangeVesselName]["name"] = data["name"];
             this.listShips[this.indexChangeVesselName]["dwt"] = data["dwt"];
             this.listShips[this.indexChangeVesselName]["year"] = data["year"];
             // this.listShips[index]["region"] = data["region"];
             // this.sortOldList()
             this.keyRender++;
           }
         });
       } else {
         this.keyRender++;
       }
       this.changeShipProcess = false
     },
     getTextSearched(text) {
       let name = ""+text;
       let nameLower = (""+text).toLowerCase()
       if(this.search && nameLower.indexOf(this.search.toLowerCase()) >= 0) {
         let part1 = name.substring(0, nameLower.indexOf(this.search.toLowerCase()));
         let part2 = "<span class='text-blue'>"+ name.substring(nameLower.indexOf(this.search.toLowerCase()), nameLower.indexOf(this.search.toLowerCase()) + this.search.length)+"</span>";
         let part3 = name.substring(nameLower.indexOf(this.search.toLowerCase()) + this.search.length, name.length);
         name = part1 + part2 + part3
       }
       return text ? name : text
     },
     selectAllCheckBox() {
       if(this.selectedShips.length === this.getCountAvailableCheckbox) {
         this.selectedShips = []
       } else {
         this.selectedShips = this.listShips.filter(a=> a.name.length !== 0).map(a=> {return a.id})
       }
     },
     dragStart(e) {
       // console.log('drag start')
       this.dragStartEvent = e;
       // console.log(e)
     },
     dragEnd(e) {
       // console.log('drag stop')
       // console.log(e)
       if(this.dragStartEvent) {
         let changeSize = e.x - this.dragStartEvent.x
         // console.log('e.path', e.target.closest('div:not([draggable])'))
         // console.log(changeSize, e.target.getAttribute('currentClass'), e.target.closest('div:not([draggable])').nextElementSibling.lastChild.getAttribute('currentClass'))
         let lists1 = document.getElementsByClassName(e.target.getAttribute('currentClass'))
         let lists2 = document.getElementsByClassName(e.target.closest('div:not([draggable])').nextElementSibling.lastChild.getAttribute('currentClass'))
         let width1 = e.target.closest('div:not([draggable])').clientWidth + 1
         let width2 = e.target.closest('div:not([draggable])').nextElementSibling.clientWidth + 1
         if(changeSize<0) {
           if((changeSize*(-1)) > (width1 - 50)) {
             changeSize = -(width1 - 50)
           }
         } else {
           if(changeSize > (width2 - 50)) {
             changeSize = width2 - 50
           }
         }
         lists1.forEach(el=> {
           el.setAttribute('style', 'width:'+(width1 + changeSize) + 'px !important')
         })
         lists2.forEach(el=> {
           el.setAttribute('style', 'width:'+(width2 - changeSize) + 'px !important')
         })
         // lists2.style['width']  = e.path[1].nextElementSibling + changeSize
         // console.log(lists1, lists2)
       }
     },
     reloadPorts() {
       this.keyRenderPorts++;
     },
     checkDate(date) {
       // if(moment(date).isValid()) {
       //   return moment(new Date(date)).isBefore(new Date());
       // }
       // return null
       return Object.prototype.toString.call(date) === "[object Date]" ? (new Date(date)).valueOf() < (new Date()).valueOf() : null
     },
     closeModalDeleteDate() {
       this.showModalDeleteDate = false;
       this.idShipForModal = null;
     },
     openModalDeleteDate(id) {
       this.showModalDeleteDate = true;
       this.idShipForModal = id;
     },
     deleteDate() {
       this.inputEditText = new Date();
       this.listShips[this.idShipForModal].on_subs = '';
       this.focusOutChangeText('on_subs', this.idShipForModal, 'text');
       this.closeModalDeleteDate()
     },
     showModalDeleteShip(id) {
       this.showModalDelete = true;
       this.idForDeleteModal = id;
     },
     deleteShip() {
       this.listShips = this.listShips.filter(a=> a.id !== this.idForDeleteModal)
       this.axiosDeleteShipsWorkSpace(this.idForDeleteModal).then(res=> {
         if(res) {
           this.addTip('Row deleted')
         } else {
           this.addTip('Error')
         }
       })
       this.sortOldList();
       this.closeModalDeleteShip()
     },
     closeModalDeleteShip() {
       this.showModalDelete = false;
       this.idForDeleteModal = null;
     },
     sortNewList() {
      let array = (this.getVesselTaskInfo.ships.filter(a=> a.is_favorite)).concat((this.getVesselTaskInfo.ships.filter(a=> !a.is_favorite)))

       let array1_1 = array.filter(a=> !a.is_rejected && a.name.length !== 0).sort((a) => {return a.contract ? -1: 1});
       let array1_2 = array.filter(a=> !a.is_rejected && a.name.length === 0).sort((a) => {return a.contract ? -1: 1});
       let array2 = array.filter(a=> a.is_rejected).sort((a) => {return a.contract ? -1: 1});
       this.listShips = (array1_1.concat(array2.concat(array1_2))).map(a=> {
         if(a.on_subs) {
           a.on_subs = new Date(a.on_subs)
         }
         a['on_subs_status'] = this.checkDate(a.on_subs)
         return a;
       })
       if(array1_2.length === 0) {
          this.addCargo()
       }
       this.listShips.forEach((item, index)=> {
         item['index'] = index
         item['is_search'] = false
       })
       // this.listShips = this.listShips.map(a=> {
       //   a['showTemplate'] = false;
       //   return a;
       // })
     },
     sortOldList() {
       let array = (this.listShips.filter(a=> a.is_favorite)).concat((this.listShips.filter(a=> !a.is_favorite)))

       let array1_1 = array.filter(a=> !a.is_rejected && a.name.length !== 0).sort((a) => {return a.contract ? -1: 1});
       let array1_2 = array.filter(a=> !a.is_rejected && a.name.length === 0).sort((a) => {return a.contract ? -1: 1});
       let array2 = array.filter(a=> a.is_rejected).sort((a) => {return a.contract ? -1: 1});
       this.listShips = (array1_1.concat(array2.concat(array1_2))).map(a=> {
         if(a.on_subs) {
           a.on_subs = new Date(a.on_subs)
         }
         return a;
       })
       if(array1_2.length === 0) {
         this.addCargo()
       }
       this.listShips.forEach((item, index)=> {
         item['index'] = index
         item['is_search'] = false
       })
         // this.listShips = !this.search ? this.listShips : this.listShips.filter(a=> (a.name + '*'+a.dwt+'*'+a.year+'*'+a.fuel_use+'*'+a.delivery_placeholder+'*'+a.ship_arrival+'*'+a.comment+'*'+a.charterers_idea+'*'+a.comment_cargo).indexOf(this.search) >= 0).concat(this.listShips.filter(a=> (a.name + '*'+a.dwt+'*'+a.year+'*'+a.fuel_use+'*'+a.delivery_placeholder+'*'+a.ship_arrival+'*'+a.comment+'*'+a.charterers_idea+'*'+a.comment_cargo).indexOf(this.search) === -1))

       // this.listShips = this.listShips.map(a=> {
       //   a['showTemplate'] = false;
       //   return a;
       // })
     },
     getShowTitle(data) {
      if(data !== null) {
        if((""+data).length !== 0) {
          return true
        }
      }
      return false
     },
     getTextForNameTemplate(item) {
       let name = ""+item.mv;
       let nameLower = (""+item.mv).toLowerCase()
       if(this.inputEditText && this.inputEditText !== 'NOTEXTNOCHANGE' && nameLower.indexOf(this.inputEditText.toLowerCase())>= 0) {
         let part1 = name.substring(0, nameLower.indexOf(this.inputEditText.toLowerCase()));
         let part2 = "<span class='text-gray fz14 fw500'>"+ name.substring(nameLower.indexOf(this.inputEditText.toLowerCase()), nameLower.indexOf(this.inputEditText.toLowerCase()) + this.inputEditText.length)+"</span>";
         let part3 = name.substring(nameLower.indexOf(this.inputEditText.toLowerCase()) + this.inputEditText.length, name.length);
         name = part1 + part2 + part3
       }
       return name + " - " + item.dwt + " - " + item.year;
     },
     getTextForNameDelivery(item) {
       let name = ""+item;
       let nameLower = (""+item).toLowerCase()
       if(this.inputEditText && this.inputEditText !== 'NOTEXTNOCHANGE' && nameLower.indexOf(this.inputEditText.toLowerCase()) >= 0) {
         let part1 = name.substring(0, nameLower.indexOf(this.inputEditText.toLowerCase()));
         let part2 = "<span class='text-purple fz14 fw500'>"+ name.substring(nameLower.indexOf(this.inputEditText.toLowerCase()), nameLower.indexOf(this.inputEditText.toLowerCase()) + this.inputEditText.length)+"</span>";
         let part3 = name.substring(nameLower.indexOf(this.inputEditText.toLowerCase()) + this.inputEditText.length, name.length);
         name = part1 + part2 + part3
       }
       return name
     },
     setThisTemplate(index, indexTemplate) {
       this.changeShipProcess = false
        // console.log(index)
       // console.log(indexTemplate)
       let data = {};
        let oldName = this.listShips[index]["name"]
        this.inputEditText = this.listShipsTemplateSearch[indexTemplate].name;
        // alert( this.inputEditText)
       data["name"] = this.listShipsTemplateSearch[indexTemplate].name;
       data["dwt"] = this.listShipsTemplateSearch[indexTemplate].dwt;
       data["year"] = moment(this.listShipsTemplateSearch[indexTemplate].year).format("YYYY");
       // data["region"] = this.listShipsTemplateSearch[indexTemplate].region;
       // console.log(oldName, this.inputEditText)
       if(oldName !== this.inputEditText && this.getVesselTaskInfo.task.type === 'SHIP_SEARCH_WITH_CARGO') {
         this.inputEditTextCopy = this.inputEditText
         let arrayDuplicate = this.listShips.filter(a=> a.name === this.inputEditText)
         // console.log(arrayDuplicate.length)
         if(arrayDuplicate.length > 0) {
           this.changeShipProcess = true
           this.listSameContract = arrayDuplicate.map(a=> {return a.id})
           this.showModalSelectedWayCreateContract = true
           this.indexChangeVesselName = index
         }
       }
       // if(!this.showModalSelectedWayCreateContract) {
         this.axiosChangeShip({id: this.listShips[index].id, data: data}).then(rsp=> {
           this.notCheckWayCreated = false
           if(rsp !== false) {
             this.listShips[index]["name"] = data["name"];
             this.listShips[index]["dwt"] = data["dwt"];
             this.listShips[index]["year"] = data["year"];
             // this.listShips[index]["region"] = data["region"];
             // this.sortOldList()
             this.keyRender++;
           }
         });
       // }
     },
     focusEditName(index, name) {
       this.listShips[index]["showTemplate"] = true;
       this.listShips = JSON.parse(JSON.stringify(this.listShips));
       this.listShips.forEach(el=> {
         el.on_subs = el.on_subs ? new Date(el.on_subs) : ''
       })
       this.inputEditText = name;
       this.searchShipActive = true;
       this.axiosSearchShipTemplate(name).then(res=> {
         if(res !== false) {
           this.listShipsTemplateSearch = res.data.results;

         } else {
           this.listShipsTemplateSearch = [];
         }
         this.searchShipActive = false;
       }).catch(()=> {
         this.listShipsTemplateSearch = [];
         this.searchShipActive = false;
       })
       this.keyRenderTemplate++;
     },
     addCargo() {
       this.axiosCreateSearchShipRESP({data: {name: "", region: "AG"}, task: this.getVesselTaskInfo.task.id}).then(res=> {
         if(res !== false) {
           res.data["contract"] = null;
           res.data["comment"] = null;
           res.data["showTemplate"] = false
           res.data["index"] = this.listShips.length
           // console.log('addCargo', res.data)
           this.listShips.push(res.data);
         }
       });
     },
     openCreateTask() {
       this.$refs.manuallyTask.setSelectTypeTask(false)
       this.$refs.manuallyTask.showModal();
       if(this.getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP') {
         this.$refs.manuallyTask.vesselSearchEvent();
       } else {
         this.$refs.manuallyTask.cargoOurVesselSearchEvent();
       }
     },
     closeMoveTask() {
         this.showMoveTask = false
     },
     openModalAcceptMove(task) {
        this.showAcceptMoveModalTask = true;
        this.taskMoveModeModal = task;
     },
     closeModalAcceptMove() {
       this.showAcceptMoveModalTask = false;
       // this.taskMoveModeModal = null;
     },
     deleteSelected() {
       this.showDeleteAllModal = false
       for(let i=0; i<this.selectedShips.length; i++) {
         this.axiosDeleteShipsWorkSpace(this.selectedShips[i])
         this.listShips = this.listShips.filter(a=> a.id !== this.selectedShips[i])
       }
       this.selectedShips = [];
       setTimeout(()=> {
         this.sortOldList()
       }, 100)
     },
     applyModalAcceptMove() {
      // let task = JSON.parse(JSON.stringify(this.taskMoveModeModal));
      // console.log("task");
      // console.log(task.type)
       this.showLoad()
       this.axiosShipDuplicate({ship_ids: this.selectedShips, task_id: this.taskMoveModeModal.id}).then(()=> {
         // console.log("task");
         // console.log(task.type)
         // if(task.type === "SHIP_SEARCH_WITH_CARGO") {
         //   console.log("vessel-task/"+task.id+"/workspace")
         //   this.$router.push("../" + task.id + "/workspace")
         // }
         // for(let i=0; i<this.selectedShips.length; i++) {
         //   this.axiosDeleteShipsWorkSpace(this.selectedShips[i])
         // }
         this.closeMoveTask();
         this.moveModeFalse();
         this.hideLoad()
       })
     },
     showModalMoveMode() {
      this.showMoveTask = true;
      this.moveTaskLoader = true
       let dep = false;
       this.axiosGetDepartments().then(()=> {
         dep = true;
         if(dep && priority && tag && employees && task) {
           this.conversionForTasks({tags: this.getAllTag, executors: this.getEmployees.data.results});
           this.moveTaskLoader = false;
         }
       });
       let priority = false;
       this.axiosGetPriorityTask().then(()=> {
         priority = true;
         if(dep && priority && tag && employees && task) {
           this.conversionForTasks({tags: this.getAllTag, executors: this.getEmployees.data.results});
           this.moveTaskLoader = false;
         }
       });
       let tag = false;
       this.getAxiosAllTag().then(()=> {
         tag = true;
         if(dep && priority && tag && employees && task) {
           this.conversionForTasks({tags: this.getAllTag, executors: this.getEmployees.data.results});
           this.moveTaskLoader = false;
         }
       });
       let employees = false;
       this.axiosGetEmployees().then(()=> {
         employees = true;
         if(dep && priority && tag && employees && task) {
           this.conversionForTasks({tags: this.getAllTag, executors: this.getEmployees.data.results});
           this.moveTaskLoader = false;
         }
       });

       let task = false;
       this.axiosGetTasksForFilter({executors: [], priority: [], is_archived: false, task_type: this.getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' ? ["SHIP_SEARCH_WITH_CARGO"] : ["CARGO_SEARCH_WITH_SHIP"], status: ["In progress", "Open"], ordering: "name"}).then(()=> {
         task = true;
         if(dep && priority && tag && employees && task) {
           let textRoute = this.$route.path;
           let idTask = textRoute.replace(/[^0-9]/gim,'');
           this.taskRemoveById(idTask)
           this.conversionForTasks({tags: this.getAllTag, executors: this.getEmployees.data.results});
           this.moveTaskLoader = false;
         }
       });
     },
     showMore() {
       this.setLimit(this.getLimit + 20);
       this.showModalMoveMode();
     },
     moveModeFalse() {
      this.selectedShips = [];
      this.moveModeChangeFalse();
     },
     selectShip(id) {
        if(this.selectedShips.indexOf(id) === -1) {
          this.selectedShips.push(id);
        } else {
          this.selectedShips = this.selectedShips.filter(a=> a !== id)
        }
     },
     readThisMessage(message) {
      let bool = false;
      // console.log(message);
       if(this.getVesselTaskInfo.task.executors.indexOf(this.getLoginData.data.user.id) >= 0 || this.getVesselTaskInfo.task.creator_id === this.getLoginData.data.user.id) {
         if(message.views.indexOf(this.getLoginData.data.user.id)>=0) {
            bool = true
          }
       }
       return bool;
     },
     closeModalChat() {
       this.showModalChat = false;
       this.indexShipForModal = null;
       this.idShipForModal = null;
     },
     setChatForShip(data) {
      // console.log("Emit")
      // console.log(data);
      this.listShips[this.indexShipForModal].chat = data;
      this.keyRenderChat++;
     },
     setDataForDeleteFile(index) {
      this.indexShipForModal = index;
     },
     fileDeleteWithContract(id) {
        this.listShips[this.indexShipForModal].contract.files = this.listShips[this.indexShipForModal].contract.files.filter(a=> a.id !== id);
       this.indexShipForModal = null;
     },
     getFormDate(date) {
       // return new Date(Number(date))
       return "" + moment(new Date(date)).format('DD MMM, HH:mm')
     },
     getFileForShip(data) {
      if(Array.isArray(data)) {
        data = data.map(a=> {
          if(a.file.indexOf(this.getURL()) === -1) {
            a.file  = this.getURL() + a.file;
          }
          return a;
        })
        return data;
      }
      return []
     },
     clickFavorites(data, index) {
      // console.log(data);
       let dataAxios = {
         'is_favorite': !data.is_favorite
       }
       this.axiosChangeShip({data: dataAxios, id: data.id}).then(response=> {
         if(response !== false) {
           this.listShips[index]["is_favorite"] = dataAxios.is_favorite;
           this.keyRender++;
           this.sortOldList();
         }
       })
     },
     getShowChartering(data) {
      if(data.contract !== null) {
        if(data.contract.chartering_manager) {
          // if(data.contract.chartering_manager.is_active !== false) {
            return true
          // }
        }
      }
        return false;
     },
     getShowOperations(data) {
       if(data.contract !== null) {
         if(data.contract.operation_manager) {
           // if(data.contract.operation_manager.is_active !== false) {
             return true;
           // }
         }
       }
       return false;
     },
     getTextDate(date) {
      if(date !== null && (""+date).length !== 0) {
        return moment(date).format("DD MMM YYYY")
      }
      return "";
     },
     getLaycanDate(item){
       if(item) {
         return (Array.isArray(JSON.parse(''+item))===true) ? JSON.parse(''+item)[0] + ' - ' + JSON.parse(''+item)[1] : ''
       }
       return ''
     },
     getTextDateWithTime(date) {
       if(date !== null && (""+date).length !== 0) {
         return moment(date).format("DD MMM YYYY, HH:mm")
       }
       return "";
     },
     getShipsLength() {
      return this.listShips.filter(a=> {return a.name.length !== 0}).length
     },
     closeModalEditClean() {
      if(!this.axiosBool) {
        this.showModalEditClean = false;
        this.errorCPDate = false;
        this.CPDate = null;
        this.deliveryDate = null;
        this.redeliveryDate = null;
        this.activeBroker = null;
        this.defaultActiveBroker = null;
        this.vesselNameEdit = "";
        this.builtEdit = "";
        this.dwtEdit = "";
        this.perEdit = "";
        this.defaultOwnerActive = null;
        this.activeOwner = null;
        this.deliveryActive = null;
        this.redeliveryActive = null;
        this.defaultDeliveryActive = null;
        this.defaultRedeliveryActive = null;
        this.activeValuta = null;
        this.defaultActiveValuta = {name: "USD", id: "USD"};
        this.laycanDateEdit = "";
        this.hireEdit = "";
        this.assignedOperations = [];
        this.assignedChartering = [];
        this.commentEdit = "";
        this.cargoCommentEdit = "";
        this.periodEdit = "";
        this.defaultActiveOperations = null;
        this.defaultActiveChartering = null;
        this.port_of_discharge = null;
        this.port_of_loading = null;
        this.errorName = false;
        this.errorOwners = false;
        this.errorFile = false;
        if(this.$refs.dropzone)
          this.$refs.dropzone.removeAllFiles(true);
        if(this.$refs.assigneeTask)
          this.$refs.assigneeTask.clearData();
        if(this.$refs.assigneeTask1)
          this.$refs.assigneeTask1.clearData();
      }
     },
     getURL() {
       return process.env.VUE_APP_URL_SIMPLE
     },
     getUrlUser(link) {
       if(link && link.indexOf(process.env.VUE_APP_URL)>=0) {
         return link;
       }
       return this.getURL()+ link
     },
     checkNum(num) {
       if(num.indexOf(".")>=0) {
         let num1 = num.substring(0, num.indexOf("."));
         let num2 = num.substring(num.indexOf(".") + 1, num.length);

         num1 = num1.replace(/[^0-9]/g, "");
         num2 = num2.replace(/[^0-9]/g, "");

         if(num2.length > 4) {
           num2 = num2.substring(0, 4);
         }
         num = num1 + "." + num2;
       } else {
         num = num.replace(/[^0-9]/g, "");
       }
       return num;
     },
     async applyCleanFixed() {
       let dataShip = this.listShips[this.indexShipForModal];
       let cp_date = null;
       if(this.CPDate !== null && (""+this.CPDate).length !== 0) {
         if(this.CPDate.length !== 0) {
           cp_date = moment(this.CPDate).format("YYYY-MM-DD")
           this.errorCPDate = false;
         } else {
           this.errorCPDate = true;
         }
       } else {
         this.errorCPDate = true;
       }
       let owners = null;
       let ownersArray = [];
       if(this.activeOwner !== null) {
         // owners = this.activeOwner.map(a=> {return a.id});
         owners = this.activeOwner.id;
         ownersArray = this.activeOwner;
         this.errorOwners = false;
       } else {
         this.errorOwners = true;
       }
       let name = this.vesselNameEdit;
       if(name.length === 0) {
         this.errorName = true
       } else {
         this.errorName = false
       }
       let files = this.$refs.dropzone.getAcceptedFiles();
       let filesInContract = [];
       if(dataShip.contract !== null) {
         filesInContract = dataShip.contract.files;
       }
       if(Array.isArray(files)) {
         if(files.length !== 0 || filesInContract.length !== 0 ) {
           this.errorFile = false;
         } else {
           this.errorFile = true;
         }
       } else {
         this.errorFile = true;
       }
       if (!this.errorCPDate && !this.errorOwners && !this.errorName && !this.errorFile) {
         this.axiosBool = true;

         let currency = "";
         if(this.activeValuta !== null) {
           currency = this.activeValuta.name
         }

         let broker = "";
         if(this.activeBroker !== null) {
           broker = this.activeBroker.id;
         }
         // let delivery = "";
         // if(this.deliveryActive !== null) {
         //   delivery = this.deliveryActive.id;
         // }
         let redelivery = [];
         if(this.port_of_discharge !== null) {
           redelivery.push(this.port_of_discharge.id);
         }
         let deliveryPlace = [];
         if(this.port_of_loading !== null) {
           deliveryPlace.push(this.port_of_loading.id);
         }
         let period = "";
         if(this.periodEdit.length !== 0) {
           period = this.periodEdit
         }
         let hire_rate = null;
         if((""+this.hireEdit).length !== 0) {
           hire_rate = this.hireEdit;
         }

         let operation_manager = null;
         let operation_managerLocal = null;
         if(this.assignedOperations !== null) {
           operation_manager = this.assignedOperations.id;
           operation_managerLocal = this.assignedOperations
         }
         let chartering_manager = null;
         let chartering_managerLocal = null;
         if(this.assignedChartering !== null) {
           chartering_manager = this.assignedChartering.id;
           chartering_managerLocal = this.assignedChartering;
         }
         let delivery = null;
         if(this.deliveryDate !== null && (""+this.deliveryDate).length !== 0) {
           delivery = moment(this.deliveryDate).format("YYYY-MM-DDTHH:mm") + "Z";
         }
         let redeliveryDate = null;
         if(this.redeliveryDate !== null && (""+this.redeliveryDate).length !== 0) {
           redeliveryDate = moment(this.redeliveryDate).format("YYYY-MM-DDTHH:mm") + "Z";
         }
         let dwt = this.dwtEdit;
         let build = null;
         if(this.builtEdit !== null) {
           if(this.builtEdit.length !==0) {
             build = this.builtEdit;
           }
         }

         let percentage = this.perEdit;
         let laycan_date = this.laycanDateEdit ? JSON.stringify(this.laycanDateEdit) : '';
         let comment = this.commentEdit;
         let cargo = this.cargoCommentEdit;
         let ship = JSON.parse(JSON.stringify(this.idShipForModal));
         let task = this.getVesselTaskInfo.task.id;
         let fileContract = [];
         if(this.listShips[this.indexShipForModal].contract !== null) {
           fileContract = this.listShips[this.indexShipForModal].contract.files;
         }
          let contract_type = 'SHIP';
         if (this.getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITH_SHIP' || this.getVesselTaskInfo.task.type === 'CARGO_SEARCH_WITHOUT_SHIP') {
           contract_type = 'CARGO'
         }
         let form = {
           "name": name,
           "dwt": dwt,
           "built": build,
           "percentage": percentage,
           "cp_date": cp_date,
           "delivery": delivery,
           "redelivery": redeliveryDate,
           "period": period,
           "laycan_date": laycan_date,
           "hire_rate": hire_rate,
           "currency": currency,
           "comment": comment,
           "cargo_comment": cargo,
           // "ship": ship,
           "task": task,
           "operation_manager": operation_manager,
           "chartering_manager": chartering_manager,
           "broker": broker,
           "owners": owners,
           "delivery_place": deliveryPlace,
           "redelivery_place": redelivery,
           "filesContract": fileContract,
           "files": files,
           "contract_type": contract_type,
         }
         // console.log(form)
         if(dataShip.contract !== null) {
           form["id"] = dataShip.contract.id;
           this.showModalEditClean = false
           this.showLoad()
           this.axiosChangeContractForShip(form).then(res=> {

             if(res !== false) {
               this.listShips[this.indexShipForModal].contract["id"] = res.data.id;
               this.listShips[this.indexShipForModal].contract["files"] = res.file;
               this.listShips[this.indexShipForModal].contract["name"] = name;
               this.listShips[this.indexShipForModal].contract["dwt"] = dwt;
               this.listShips[this.indexShipForModal].contract["built"] = build;
               this.listShips[this.indexShipForModal].contract["percentage"] = percentage;
               this.listShips[this.indexShipForModal].contract["localInfo"] = true;
               this.listShips[this.indexShipForModal].contract["cp_date"] = cp_date;
               this.listShips[this.indexShipForModal].contract["delivery"] = delivery;
               this.listShips[this.indexShipForModal].contract["redelivery"] = redeliveryDate;
               this.listShips[this.indexShipForModal].contract["period"] = period;
               this.listShips[this.indexShipForModal].contract["laycan_date"] = laycan_date;
               this.listShips[this.indexShipForModal].contract["hire_rate"] = hire_rate;
               this.listShips[this.indexShipForModal].contract["currency"] = currency;
               this.listShips[this.indexShipForModal].contract["comment"] = comment;
               this.listShips[this.indexShipForModal].contract["cargo_comment"] = cargo;
               this.listShips[this.indexShipForModal].contract["ship"] = ship;
               this.listShips[this.indexShipForModal].contract["task"] = task;
               this.listShips[this.indexShipForModal].contract["operation_manager"] = operation_managerLocal;
               this.listShips[this.indexShipForModal].contract["chartering_manager"] = chartering_managerLocal;
               if(this.activeBroker !== null) {
                 this.listShips[this.indexShipForModal].contract["broker"] = {company_name: this.activeBroker.name, id: this.activeBroker.id};
               }
               // this.listShips[this.indexShipForModal].contract["owners"] = ownersArray.map(a=>{ return {company_name: a.name, id: a.id}});
               this.listShips[this.indexShipForModal].contract["owner"] = {company_name: ownersArray.name, id: ownersArray.id};
               // console.log("this.deliveryActive")
               // console.log(this.deliveryActive)
               if(this.port_of_loading !== null) {
                 this.listShips[this.indexShipForModal].contract["delivery_place"] = [];
                 this.listShips[this.indexShipForModal].contract["delivery_place"].push(this.port_of_loading);
               }
               // console.log("this.redeliveryActive")
               // console.log(this.redeliveryActive)
               if(this.port_of_discharge !== null) {
                 this.listShips[this.indexShipForModal].contract["redelivery_place"] = [];
                 this.listShips[this.indexShipForModal].contract["redelivery_place"].push(this.port_of_discharge);
               }
               // console.log("contract")
               // console.log(this.listShips[this.indexShipForModal].contract)
               // this.listShips[this.indexShipForModal].contract = JSON.parse(JSON.stringify(this.listShips[this.indexShipForModal].contract))
               this.axiosBool = false;
               this.closeModalEditClean();
               this.keyRender++;
               this.hideLoad()
             }
           }).catch(()=> {
             this.axiosBool = false;
             this.closeModalEditClean();
             this.hideLoad()
           })
         } else {
           // axiosCreatContractForShip
           // alert(ship)
           // if(this.getVesselTaskInfo.task.type === "SHIP_SEARCH_WITH_CARGO" && !this.notCheckWayCreated) {
           //   this.acceptCreate = false
           //   this.showLoad()
           //   this.listSameContract = await this.axiosGetAllContractsLocal({is_redelivery: false, search: form.name, is_archive: false})
           //   this.listSameContract = this.listSameContract.filter(a=> a.name === form.name)
           //   console.log('this.listSameContract', this.listSameContract.map(a=> {return a.name}))
           //   if(this.listSameContract.length !== 0) {
           //     this.showModalSelectedWayCreateContract = true
           //     this.showModalEditClean = false
           //   } else {
           //     this.acceptCreate = true
           //   }
           //   this.hideLoad()
           // } else {
           //   this.acceptCreate = true
           // }
           // if(!this.acceptCreate) {
           //   return null;
           // }
           this.showModalEditClean = false
           this.showLoad()
           this.axiosCreatContractForShip(form).then(res=> {
             this.notCheckWayCreated = false
             this.acceptCreate = true
             if(res !== false) {
               this.axiosDeleteShipsWorkSpace(ship).then(()=> {
                 this.listShips = this.listShips.filter(a=> a.id !== ship)
                 this.sortOldList()
                 this.hideLoad();
               })
               // console.log(1);
               // this.listShips[this.indexShipForModal].contract = {};
               // this.listShips[this.indexShipForModal].contract["id"] = res.data.id;
               // this.listShips[this.indexShipForModal].contract["files"] = res.file;
               // this.listShips[this.indexShipForModal].contract["name"] = name;
               // this.listShips[this.indexShipForModal].contract["dwt"] = dwt;
               // this.listShips[this.indexShipForModal].contract["built"] = build;
               // this.listShips[this.indexShipForModal].contract["percentage"] = percentage;
               // this.listShips[this.indexShipForModal].contract["localInfo"] = true;
               // this.listShips[this.indexShipForModal].contract["cp_date"] = cp_date;
               // this.listShips[this.indexShipForModal].contract["delivery"] = delivery;
               // this.listShips[this.indexShipForModal].contract["redelivery"] = redeliveryDate;
               // this.listShips[this.indexShipForModal].contract["period"] = period;
               // this.listShips[this.indexShipForModal].contract["laycan_date"] = laycan_date;
               // this.listShips[this.indexShipForModal].contract["hire_rate"] = hire_rate;
               // this.listShips[this.indexShipForModal].contract["currency"] = currency;
               // this.listShips[this.indexShipForModal].contract["comment"] = comment;
               // this.listShips[this.indexShipForModal].contract["cargo_comment"] = cargo;
               // this.listShips[this.indexShipForModal].contract["ship"] = ship;
               // this.listShips[this.indexShipForModal].contract["task"] = task;
               // this.listShips[this.indexShipForModal].contract["operation_manager"] = null;
               //     this.listShips[this.indexShipForModal].contract["operation_manager"] = operation_managerLocal;
               // this.listShips[this.indexShipForModal].contract["chartering_manager"] = null;
               //     this.listShips[this.indexShipForModal].contract["chartering_manager"] = chartering_managerLocal;
               // if(this.activeBroker !== null) {
               //   this.listShips[this.indexShipForModal].contract["broker"] = {company_name: this.activeBroker.name, id: this.activeBroker.id};
               // }
               // // this.listShips[this.indexShipForModal].contract["owners"] = ownersArray.map(a=>{ return {company_name: a.name, id: a.id}});
               // this.listShips[this.indexShipForModal].contract["owner"] = {company_name: ownersArray.name, id: ownersArray.id};
               // if(this.deliveryActive !== null) {
               //   this.listShips[this.indexShipForModal].contract["delivery_place"] = [];
               //   this.listShips[this.indexShipForModal].contract["delivery_place"].push({name: this.deliveryActive.namePort, id: this.deliveryActive.id, country: this.deliveryActive.countryPort});
               // } else {
               //   this.listShips[this.indexShipForModal].contract["delivery_place"] = [];
               // }
               // console.log(5);
               // if(this.redeliveryActive !== null) {
               //   this.listShips[this.indexShipForModal].contract["redelivery_place"] = [];
               //   this.listShips[this.indexShipForModal].contract["redelivery_place"].push({name: this.redeliveryActive.namePort, id: this.redeliveryActive.id, country: this.redeliveryActive.countryPort});
               // } else {
               //   this.listShips[this.indexShipForModal].contract["redelivery_place"] = [];
               // }
               // console.log(6);
               // // this.listShips[this.indexShipForModal].contract = JSON.parse(JSON.stringify(this.listShips[this.indexShipForModal].contract))
               // console.log("contract")
               // console.log(this.listShips[this.indexShipForModal].contract)
               // this.keyRender++;
               this.axiosBool = false;
               this.closeModalEditClean();
               this.sortOldList();
             } else {
               this.hideLoad()
             }
           }).catch(()=> {
             this.hideLoad()
             this.axiosBool = false;
             this.closeModalEditClean();
           })
         }
       }
     },
     setAssignedOperations(data) {
       // console.log(data);
       this.assignedOperations = data;
     },
     setAssignedChartering(data) {
      // console.log(data);
       this.assignedChartering = data;
     },
     mountedAssigned() {
       if(this.duplicateAssigned.length !==0) {
         this.$refs.assigneeTask.setAssignedForDuplicate(this.duplicateAssigned)
       }
     },
     setValuta(data) {
        this.activeValuta = data;
     },
     setDeliveryPlace(data) {
        this.deliveryActive = data;
     },
     setRedeliveryPlace(data) {
       this.redeliveryActive = data;
     },
     selectOwner(data) {
        this.activeOwner = data;
     },
     setActiveBroker(data) {
        this.activeBroker = data;
     },
     applyOwners() {
      // console.log(this.listShips[this.indexShipForModal].comment)
        if(this.listShips[this.indexShipForModal].comment.ship === null) {
          let index = this.indexShipForModal;
          let broker = this.activeBroker;
          this.axiosCreateCommentForShip({ship: this.idShipForModal, comment: this.editOwnersComment, broker: this.activeBroker.id}).then(response=> {
            if(response !== false) {
              let form = {
                id: response.data.id,
                comment: response.data.comment,
                broker: {company_name: broker.name, id: broker.id}
              }
              this.listShips[index]["comment"] = form;
            }
          })
        } else {
          let index = this.indexShipForModal;
          let broker = this.activeBroker;
          this.axiosChangeCommentForShip({id: this.idCommentModal,ship: this.idShipForModal, comment: this.editOwnersComment, broker: this.activeBroker.id}).then(response=> {
            if(response !== false) {
              let form = {
                id: response.data.id,
                comment: response.data.comment,
                broker: {company_name: broker.name, id: broker.id}
              }
              this.listShips[index]["comment"] = form;
            }
          })
        }
     },
     getTextForDelivery(list) {
        if(list.length !== 0) {
          let text = "";
          list.map(a=> {
            text += a.name + ": " + a.country + "<br/>";
          });
          return text
        }
        return ""
     },
     inputEditName(e) {
       // console.log(e.target.innerText)
       this.inputEditText = e.target.innerText;
       if(this.getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && this.getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP') {
         this.searchShipActive = true;
         this.axiosSearchShipTemplate(e.target.innerText).then(res=> {
           if(res !== false) {
             this.listShipsTemplateSearch = res.data.results;
           } else {
             this.listShipsTemplateSearch = [];
           }
           this.searchShipActive = false;
         }).catch(()=> {
           this.listShipsTemplateSearch = [];
           this.searchShipActive = false;
         })
       }

     },
     inputEdit(e) {
        this.inputEditText = e.target.innerText;
     },
     inputEditDelivery(e) {
       this.inputEditText = e.target.innerText;
       if(this.getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITH_SHIP' && this.getVesselTaskInfo.task.type !== 'CARGO_SEARCH_WITHOUT_SHIP') {
         this.searchDeliveryActive = true;
         this.axiosSearchShipDelivery(e.target.innerText).then(res=> {
           if(res !== false) {
             this.listShipsDeliverySearch = res.data.delivery;
           } else {
             this.listShipsDeliverySearch = [];
           }
           this.searchDeliveryActive = false;
         }).catch(()=> {
           this.listShipsDeliverySearch = [];
           this.searchDeliveryActive = false;
         })
       }
     },
     focusEditDelivery(index, name) {
       this.listShips[index]["showTemplateDelivery"] = true;
       this.listShips = JSON.parse(JSON.stringify(this.listShips));
       this.inputEditText = name;
       this.searchDeliveryActive = true;
       this.axiosSearchShipDelivery(name).then(res=> {
         if(res !== false) {
           this.listShipsDeliverySearch = res.data.delivery;
         } else {
           this.listShipsDeliverySearch = [];
         }
         this.searchDeliveryActive = false;
       }).catch(()=> {
         this.listShipsDeliverySearch = [];
         this.searchDeliveryActive = false;
       })
       this.keyRenderTemplate++;
     },
     focusInInput(e) {
       this.inputEditText = e.target.innerText;
     },
     setActivePort(data) {
       // this.showSelectPort = "";
       this.activePort = data;
       // this.closeDrop();
     },
     closeDrop(id, index) {
         let array = this.listShips[index].delivery;
         if(Array.isArray(array)) {
           let data = {
             'delivery': array.map(a=> {
               return a.id
             })
           }
           this.axiosChangeShip({data: data, id: id}).then(response=> {
             if(response !== false) {
               // this.listShips[index].delivery = this.activePort.map(a=> {return {name: a.namePort, country: a.countryPort, id: a.id}});
               this.keyRender++;
             }
             this.activePort = null;
             this.showSelectPort = "";
           }).catch(()=> {
             this.activePort = null;
             this.showSelectPort = "";
           })
         }
     },
     clickRejected(data, index) {
       // console.log(data);
       let dataAxios = {
         'is_rejected': !data.is_rejected
       }
       this.axiosChangeShip({data: dataAxios, id: data.id}).then(response=> {
         if(response !== false) {
           this.listShips[index]["is_rejected"] = dataAxios.is_rejected;
           this.sortOldList()

           this.keyRender++;
         }
       })
     },
     editTextDelivery(val) {
        let data = {}
        data["delivery_placeholder"] = val.text;
       let cancelValue = this.listShips[val.index]["delivery_placeholder"];

       this.axiosChangeShip({id: this.listShips[val.index].id, data: data}).then(rsp=> {
         if(!rsp) {
           this.listShips[val.index]["delivery_placeholder"] = cancelValue;
           this.keyRender++;
         } else {

           this.listShips[val.index]["delivery_placeholder"] = data["delivery_placeholder"];
           this.keyRender++;
         }
       });
     },
     setThisDelivery(item, i) {
      this.inputEditText = item;
      this.focusOutChangeText('delivery_placeholder', i, 'text')
     },
     getDate(date) {
       return moment(date).format('DD.MM.YYYY, HH:mm')
     },
     focusOutChangeText(valueName, index, type) {
       console.log(valueName, index, this.listShips[index])
      if(valueName === 'name') {
        // console.log('focusOutChangeText', this.notCheckWayCreated)
        if(!this.notCheckWayCreated) {
          this.checkFocusOutName(index)
        }
        this.acceptCreate = false
        setTimeout(()=>this.listShips[index]["showTemplate"] = false, 150);
      } else {
        this.acceptCreate = true
      }

       if(valueName === 'name' && !this.acceptCreate && !this.notCheckWayCreated) {
         // console.log('error')
         return null;
       }
       this.notCheckWayCreated = false;


       if(valueName === 'delivery_placeholder') {
         setTimeout(()=>this.listShips[index]["showTemplateDelivery"] = false, 150);
       }

       if(this.inputEditText !== "NOTEXTNOCHANGE" && this.listShips[index][valueName] !== this.inputEditText) {
        let cancelValue = this.listShips[index][valueName];
        let data = {};
         if(valueName === 'on_subs') {
           cancelValue = this.inputEditText
           this.inputEditText = this.listShips[index][valueName];
           this.listShips[index]['on_subs_status'] = this.checkDate(this.listShips[index][valueName])
           this.listShips[index]['on_subs'] = this.listShips[index][valueName]
         }
        if(type === 'number') {
          // data[valueName] = Number(this.inputEditText);
          if(this.inputEditText.length === 0) {
            this.inputEditText = null;
          }
        }
        if(this.inputEditText && this.inputEditText.length > 2000) {
          this.inputEditText = this.inputEditText.slice(0, 1999)
        }
        data[valueName] = this.inputEditText;
        this.inputEditText = "NOTEXTNOCHANGE"
         this.axiosChangeShip({id: this.listShips[index].id, data: data}).then(rsp=> {
          this.acceptCreate = true
          // console.log(rsp);
          if(!rsp) {
            // console.log(cancelValue);
            // this.listShips[index][valueName] = "1111";
            this.listShips[index][valueName] = cancelValue;
            if(valueName === 'on_subs') {
              this.listShips[index]['on_subs_status'] = this.checkDate(this.listShips[index].on_subs)
            }
            // let value = this.listShips[index]
            // this.$set(this.listShips, index, value)
            // this.listShips = JSON.parse(JSON.stringify(this.listShips))
            this.keyRender++;
          } else {

            // this.listShips[index][valueName] = data[valueName];
            // let value = this.listShips[index]
            // this.$set(this.listShips, index, value)
            // this.keyRender++;
            if(valueName === 'name') {
              if((''+this.listShips[index][valueName]).length === 0) {
                // this.clickRejected(this.listShips[index], index)
              } else {
                this.sortOldList()
              }
            }
            // this.keyRenderList++;
          }
        });
         this.listShips[index][valueName] = data[valueName];
         this.keyRender++;


       }
     },
     showOrhideAll() {
        if(this.showFullInfo.length) {
          this.showFullInfo = [];
        } else {
          this.showFullInfo = this.listShips.map(l=> {return l.name + '' + l.id});
       }
     },
     addShowFullInfo(name) {
        this.showFullInfo.push(name);
     },
     removeShowFullInfo(name) {
      this.showFullInfo = this.showFullInfo.filter(a=> a !== name)
     },
     showFullInfoMeth(name) {
        if(this.showFullInfo.indexOf(name) >= 0) {
          this.removeShowFullInfo(name);
        } else {
          this.addShowFullInfo(name);
        }
     },
     openModalClean(id, index) {
       this.showModalEditClean = true;
       this.indexShipForModal = index;
       this.idShipForModal = id
       let dataShip = this.listShips[this.indexShipForModal];
       // console.log(dataShip)
       if(dataShip.contract !== null) {
         this.vesselNameEdit = dataShip.contract.name;
         this.builtEdit = dataShip.contract.built;
         this.cargoCommentEdit = dataShip.contract.cargo_comment;
         this.commentEdit = dataShip.contract.comment;
         this.laycanDateEdit = dataShip.contract.laycan_date ? JSON.parse(''+dataShip.contract.laycan_date) : '';
         this.dwtEdit = dataShip.contract.dwt;
         this.perEdit = dataShip.contract.percentage;
         if(dataShip.contract.redelivery_place.length !== 0 || dataShip.contract.delivery_place.length !== 0) {
           if(dataShip.contract.delivery_place.length !== 0) {
             this.port_of_loading = dataShip.contract.delivery_place[0]
           }
           if(dataShip.contract.redelivery_place.length !== 0) {
             this.port_of_discharge = dataShip.contract.redelivery_place[0]
           }
         }
         if(dataShip.contract.broker !== null && dataShip.contract.broker !== undefined) {
           this.activeBroker = dataShip.contract.broker
         }
       } else {
         this.vesselNameEdit = dataShip.name;
         this.dwtEdit = dataShip.dwt;
         this.builtEdit = dataShip.year;
         this.perEdit = dataShip.fuel_use;
         this.commentEdit = dataShip.comment.comment;
         if(dataShip.comment.broker.id !== null && dataShip.comment.broker.id !== undefined) {
           this.activeBroker = dataShip.comment.broker
         }
       }
       // if(dataShip.contract.owners.length !== 0) {
       //   this.defaultOwnerActive = dataShip.contract.owners.map(a=> {
       //     return {name: a.company_name, id: a.id}
       //   })
       // }
       // console.log("owner")
       // console.log(dataShip.contract.owner)

       if(dataShip.contract.owner !== null) {
         // console.log("dataShip.contract.owner")
         this.activeOwner = dataShip.contract.owner
         // console.log(this.defaultOwnerActive)
       }
       // console.log(dataShip.contract.cp_date)
       if(dataShip.contract.cp_date !== null) {
         // console.log((""+dataShip.contract.cp_date).length)
         if((""+dataShip.contract.cp_date).length !== 0) {
           this.CPDate = new Date(dataShip.contract.cp_date);
           // console.log(this.CPDate)
         }
       }
       if(dataShip.contract.period !== null) {
         this.periodEdit = dataShip.contract.period
       }
       if(dataShip.contract.delivery !== null) {
         this.deliveryDate = new Date(dataShip.contract.delivery);
       }
       if(dataShip.contract.redelivery !== null) {
         this.redeliveryDate = new Date(dataShip.contract.redelivery);
       }
       this.laycanDateEdit = dataShip.contract.laycan_date ? JSON.parse(''+dataShip.contract.laycan_date) : '';
       this.hireEdit = dataShip.contract.hire_rate;
       if(dataShip.contract.currency !== null) {
         this.defaultActiveValuta = {name: dataShip.contract.currency, id: dataShip.contract.currency}
       }
       if(dataShip.contract.chartering_manager?.id !== null && dataShip.contract.chartering_manager?.id !== undefined) {
         this.assignedChartering = dataShip.contract.chartering_manager;
         // this.defaultActiveChartering = dataShip.contract.chartering_manager;
       }
       if(dataShip.contract.operation_manager?.id !== null && dataShip.contract.operation_manager?.id !== undefined) {
         this.assignedOperations = dataShip.contract.operation_manager;
         // this.defaultActiveOperations = dataShip.contract.operation_manager;
       }
       // console.log('assignedChartering', this.assignedChartering)
       // console.log('assignedOperations',this.assignedOperations)

     },
     openModalChat(id, index) {
      this.idShipForModal = id;
      this.indexShipForModal = index;
      this.showModalChat = true;
     },
     showEditOwnersOpen(id, index, comment) {
      this.idShipForModal = id;
       this.indexShipForModal = index;
       this.showEditOwners = true;
       if(comment !== null) {
         this.idCommentModal = comment.id
         this.editOwnersComment = comment.comment;
         this.activeBroker = comment.broker
       }
     },
    closeModalEditOwmners() {
       this.editOwnersComment = ""
      this.activeBroker = null;
      this.showEditOwners = false;
      this.defaultActiveBroker = {};
    },
    closeModal() {
      this.showCandidates = false;
    },
     showAddCandidates() {
       this.showCandidates = true;
     },
     setActiveRegion(data) {
       this.activeRegion = data;
     },
     changeRegion(name) {
       // console.log(name);
       this.defaultRegionActive = {name: name, id: name}
     },
     openSelectPortDrop(name) {

       // if(this.showFullInfo.indexOf(name) === -1) {
         // alert(3)
         this.showSelectPort = name;
       // }
     },
     openMapModal() {
       if(this.activeRegion.name !== null && this.activeRegion.name !== undefined) {
         this.$refs.modalMap.openModal(this.activeRegion.name);
       } else {
         this.$refs.modalMap.openModal("");
       }
     },
     getPortsForDrop(list) {
        return list.map(a=>{return {name: a.name + ": " + a.country, id: a.id, namePort: a.name, countryPort: a.country}})
     },
     getNamePortsForDelivery(list) {
        // return this.getPortsForVessel.filter(a=>{a.id === list[0]})
       if(list !== null) {
         if(list.length !==0) {
           if(this.getPortsForVessel.length !== 0) {
             let port = list.map(b=> {return this.getPortsForVessel.filter(a=> a.id === b)});
             port = port.map(a=> {
               return a[0];
             });
             // return port
             let text = ""
             port.map(a=> {
               text += a.name + ": " + a.country +"<br/>";
             })
             return text;
           }
         }
       }
       return ""
     },
     getActivePortDefault(list) {
       if(list !== null) {
         if(list.length !==0) {
           if(this.getPortsForVessel.length !== 0) {
             let port = list.map(b=>{return this.getPortsForVessel.filter(a=> a.id === b)});
             return port.map(a=> {return a[0]})
           }
         }
       }
       return []
     },
   },
  mounted() {
    let vm = this;
      vm.interval = setInterval(()=> {
        vm.listShips.forEach(item => {
          item.on_subs_status = vm.checkDate(item.on_subs)
        })
      }, 10000)
  },
  beforeRouteLeave(from, to, next) {
    clearInterval(this.interval)
    this.interval = null;
    next()
  },
  async beforeMount() {
    // if(!this.getPortsForVessel.length) {
    //   this.getAxiosPortsForVessel().then(()=> {
    //     this.ports = this.getPortsForDrop(this.getPortsForVessel);
    //   });
    // } else {
    //   this.ports = this.getPortsForDrop(this.getPortsForVessel);
    // }
    if(!this.clients.length) {
      this.axiosGetClientsForDropdown();
    }
    this.listShips = this.listShips.map(a=> {
      if(a.contract !== null) {
        if(a.contract.owner !== null) {
          let value = this.clients.filter(b=> b.id === a.contract.owner);
          if(value.length !== 0) {
            a.contract.owner = value[0];
          }
        }
      }
      a['on_subs_status'] = this.checkDate(a.on_subs)
      return a
    })
    let textRoute = this.$route.path;
    let idTask = textRoute.replace(/[^0-9]/gim,'');
    this.showLoad()
    await this.getAxiosInfoForVesselTask(idTask).then(()=> {
      if(this.getVesselTaskInfo !== null) {
        if(this.getVesselTaskInfo.ships.length < 30) {
          for (let i=0; i<29 - this.getVesselTaskInfo.ships.length; i++) {
            this.addCargo();
            // this.axiosCreateSearchShipRESPAsync({data: {name: "", region: "AG"}, task: this.getVesselTaskInfo.task.id})
          }

        } else {
          // console.log(this.getVesselTaskInfo.ships);
          let instance_ids = this.getVesselTaskInfo.ships.map(a=> {return a.id})
          let form = {
            "instance_ids": instance_ids,
            "room_type": "out_offer"
          }
          // console.log(form)
          this.getAxiosCommentForChat(form).then(()=> {
            this.getComment.messages.map(b=> {
              b.views = b.views.map(c=> {
                return c.id
              })
            })
            this.listShips = this.listShips.map(a=> {
              a.chat = this.getComment.messages.filter(b=> b.chat.instance_id === a.id);
              return a;
            })
            // console.log(this.listShips)
          })
        }
      }
      this.hideLoad()
    });
    if(this.$route.query.from == 'counterparties'){
      this.openModalClean(this.$route.query.id, this.$route.query.index)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "vessel-workspace";
  .button-new-port {
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 10;
    cursor: pointer;
  }
  .t12 {
    top: 12px;
  }
  .modalPort {
    z-index: 210;
  }
  /deep/ {
    .top-panel-select-type button {
      border-radius: 0 !important;
    }
  }
  .add-client-modal {
    height: calc(100vh - 56px);
    width: 100vw;
    z-index: 400;
    position: absolute;
    top: 56px;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    /deep/ {
      .counterparties-add_bread {
        display: none;
      }
    }
  }
  .min-w-100Proc {
    min-width: 100% !important;
  }
  .pb2 {
    padding-bottom: 2px !important;
  }
  .modal-task {
    .main {
      left: 0 !important;
    }
    /deep/.main-block {
      min-height: 150px;
    }
  }
  .modal-import {
    /deep/ {
      .top-panel {
        padding: 14px 16px !important;
        border-bottom: none !important;
      }
      .bottom-panel {
        border-top: none !important;
      }
    }
  }
  .search {
    position: relative;
    .svg1 {
      position: absolute;
      left: 8px;
    }
    .svg2 {
      position: absolute;
      right: 4px;
    }
    input {
      background-color: var(--color-bgc-page-white);
      padding-right: 32px;
      padding-left: 40px;
      width: 240px;
      height: 32px;
      &:focus {
      border-color: var(--color-blue-dark) !important
    }
    }
  }
  .searched > *:not(.delete-ship) {
    background-color: var(--color-blue-lightest) !important;
  }
  .selected-way-create {
    /deep/ {
      .bottom-panel {
        padding: 15px 24px !important;
      }
      .blue-button {
        display: none;
      }
      .close-button {
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>