import axios from 'axios'

export default {
  actions: {
    async axiosGetClientsType(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/client/type?ordering=id', {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        ctx.commit("setClientsType", response.data.results);
      }).catch(error => {
        console.error(error);
      })
    },
    async axiosGetClientsSubtype(ctx) {
      await axios.get(process.env.VUE_APP_URL+'/client/type/subtype?ordering=id', {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response=> {
        ctx.commit("setClientsSubtype", response.data.results);
      }).catch(error => {
        console.error(error);
      })
    },
    async axiosSetClientType(ctx, {name, account}) {
      console.log('name = ', name);
      await axios.post(process.env.VUE_APP_URL+'/client/type/', {name, account},{
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        console.log(response);
      }).catch(error => {
        console.error(error);
      });
    },
    async axiosSetClientSubtype(ctx, {name, id}) {
      await axios.post(process.env.VUE_APP_URL+'/client/type/subtype', {
        name: name,
        main_type: id
      },{
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        console.log(response);
      }).catch(error => {
        console.error(error);
      });
    },
    async axiosDelClientType(ctx, id) {
      await axios.delete(process.env.VUE_APP_URL+'/client/type/' + id + '/', {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        console.log(response);
      }).catch(error => {
        console.error(error);
      });
    },
    async axiosDelClientSubtype(ctx, id) {
      await axios.delete(process.env.VUE_APP_URL+'/client/type/subtype/' + id + '/', {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        console.log(response);
      }).catch(error => {
        console.error(error);
      });
    },
    async axiosEditClientType(ctx, {name, id, account}) {
      await axios.put(process.env.VUE_APP_URL+'/client/type/' + id + '/', {name, account}, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        console.log(response);
      }).catch(error => {
        console.error(error);
      });
    },
    async axiosEditClientSubtype(ctx, {name, id, parent_id}) {
      await axios.put(process.env.VUE_APP_URL+'/client/type/subtype/' + id + '/', {
        name: name,
        main_type: parent_id
      }, {
        headers: {
          'Authorization': 'token ' + ctx.getters.getToken,
        }
      }).then(response => {
        console.log(response);
      }).catch(error => {
        console.error(error);
      });
    }
  },
  mutations: {
    setClientsType(state, data) {
      state.clientsType = []
      data.forEach(el => {
        state.clientsType.push({
          id: el.id,
          name: el.name,
          account: el.account
        })
      });
    },
    setClientsSubtype(state, data) {
      state.clientsSubtype = []
      data.forEach(el => {
        state.clientsSubtype.push({
          parent_id: el.main_type.id,
          id: el.id,
          name: el.name
        })
      });
    }
  },
  state: {
    clientsType: [],
    clientsSubtype: [],
  },
  getters: {}
}