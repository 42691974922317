<template>
  <div>
    <folders-list-file-and-folder></folders-list-file-and-folder>
  </div>
</template>

<script>
import FoldersListFileAndFolder from "@/components/folders/folders-list-file-and-folder";
export default {
  name: "folders-counterparties-files",
  components: {FoldersListFileAndFolder}
}
</script>

<style scoped>

</style>