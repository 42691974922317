import axios from 'axios'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import router from "@/router";
Vue.use(VueCookies);

export default {
    actions: {
        axiosConfirmationPhone(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/login", {
                phone_number: data.phone,
                code: data.code,
            }).then(response => {
                ctx.commit("updateLoginData", response);
                ctx.commit("setToken", response);
            })
                .catch(() => {
                    // ctx.commit("setAlertarraySeries", {show: true, text: error}, {root: true});
            });
        },
        axiosFirebaseLoginTestAfterDelete(ctx, phone_number) {
            return axios.post(process.env.VUE_APP_URL+"/login", {
                'phone_number': phone_number,
                'code': '111111',
                'firebasetoken': null,
            }).then(response => {
                ctx.commit("updateLoginData", response);
                ctx.commit("setToken", response);
                ctx.commit("setPermissionForUser", response.data.permissions);
                localStorage.setItem("per", JSON.stringify(response.data.permissions))

                return true
            })
                .catch(error => {
                    return error
                    // //console.log(error)
                });
        },
        axiosFirebaseLogin(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/login", {
                'firebasetoken':data,
            }).then(response => {
                ctx.commit("updateLoginData", response);
                ctx.commit("setToken", response);
                ctx.commit("setPermissionForUser", response.data.permissions);
                localStorage.setItem("per", JSON.stringify(response.data.permissions))

                return true
            })
                .catch(error => {
                    return error
                    // //console.log(error)
                });
        },
        axiosGetPermissionsForUserLogin(ctx) {
            /* //console.log("axiosGetPermissionsForUserLogin");
            //console.log(ctx.getters.getToken); */
            return axios.post(process.env.VUE_APP_URL+"/login/permission/?limit=99999", {
                name: ""
            },{
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                ////console.log(response);
                ctx.commit("setPermissionForUser", response.data.permissions);
            }).catch(error=> {
                // console.log('perm resp')
                // console.log(error)
                if(error?.message) {
                    if((""+error.message).indexOf('401') >= 0 || (""+error.message).indexOf('502') >= 0 || (""+error.message).toLowerCase().indexOf('token') >= 0) {
                        ctx.commit("deleteDataUserWithRouterPushLogin");
                    }
                }
            })
        },
        axiosCheckPhoneAndEmailForAddNewUser(ctx, data) {
            return axios.post(process.env.VUE_APP_URL+"/user/employee/check/", {
                "phone_number": data.phone_number,
                "email": data.email
            },{
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                return response.data
            })
        }
    },
    mutations: {
        setUserDataUSER(state, data) {
            state.loginData['data']['user'] = data;
            localStorage.setItem("userData", JSON.stringify({data: {token: state.loginData.data.token, user: state.loginData.data.user}}));
        },
        updateLoginData(state, data) {
            state.loginData = JSON.parse(JSON.stringify(data));
        },
        setToken(state, data) {
            // //console.log(data.data.token)
            state.TOKEN = data.data.token;
        },
        deleteDataUser(state) {
            state.loginData = {};
            state.TOKEN = "";
            localStorage.removeItem('userData');
        },
        deleteDataUserWithRouterPushLogin(state) {
            state.loginData = {};
            state.TOKEN = "";
            localStorage.removeItem('userData');
            router.push('/login');
        },
        setPermissionForUser(state, data) {
            if(data !== null && Array.isArray(data)) {
                let m = JSON.parse(JSON.stringify(data)).filter(p => p.type === "can_see_task");
                if(m[0].has_permission === true) {
                    let array1 = JSON.parse(JSON.stringify(data)).filter(p => p.type === "can_see_task");
                    array1 = array1[0].users;
                    let array2 = JSON.parse(JSON.stringify(data)).filter(p => p.type === "can_assign_task");
                    array2 = array2[0].users;
                    let array = [];
                    for(let i=0; i<array2.length; i++) {
                        let k=0;
                        for(let j=0; j<array1.length; j++) {
                            if(array1[j].id === array2[i].id) {
                                k++;
                            }
                        }
                        if(k===0) {
                            array.push(array2[i]);
                        }
                    }
                    for(let i=0; i<array1.length; i++) {
                        array.push(array1[i]);
                    }
                    for(let i=0; i<data.length; i++) {
                        if(data[i].type === 'can_see_task') {
                            data[i].users = array;
                        }
                    }
                }
                state.permissionsForUser = JSON.parse(JSON.stringify(data));
                localStorage.setItem("per", JSON.stringify(state.permissionsForUser))
                // Vue.prototype.$cookies.set("permissionsLength", JSON.stringify(data.length), "30d");
                // if(Vue.prototype.$cookies.isKey("permissionsLength")) {
                //     for(let i=0; i<Vue.prototype.$cookies.get("permissionsLength"); i++) {
                //
                //     }
                // }
                // for(let i=0; i<data.length; i++) {
                //     let form = {
                //         has_permission: data[i].has_permission,
                //         type: data[i].type,
                //         users: [],
                //     }
                //     Vue.prototype.$cookies.set("permissionsLengthUser" + i, JSON.stringify(data[i].users.length), "30d");
                //     for(let j=0; j<data[i].users.length; j++) {
                //         Vue.prototype.$cookies.set("permissions" + i + "-" + j, JSON.stringify(data[i].users[j]), "30d");
                //     }
                //     Vue.prototype.$cookies.set("permissions" + i, JSON.stringify(form), "30d");
                // }

            }

            // console.log("111111")
            // console.log(data);


        }
    },
    state: {
        loginData: {},
        TOKEN: "",
        permissionsForUser: [],
    },
    getters: {
        getLoginData(state) {
            if(state.TOKEN.length < 20) {
                // //console.log(2);
                // //console.log(Vue.prototype.$cookies.get('userData'));
                // state.loginData = Vue.prototype.$cookies.get('userData');
                state.loginData = JSON.parse(localStorage.getItem('userData'));
                state.TOKEN = state.loginData.data.token;
            }
            return state.loginData;
        },
        getToken(state) {
            if(state.TOKEN.length < 20) {
                //console.log(2);
                //console.log(Vue.prototype.$cookies.get('userData'));
                state.loginData = JSON.parse(localStorage.getItem('userData'));

                if(state.loginData === null) {
                    state.TOKEN = ""
                } else {
                    state.TOKEN = state.loginData.data.token;
                }
            }

            return state.TOKEN;
        },
        getPermissionsForUser(state) {
            // JSON.parse(localStorage.getItem("per")
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            return state.permissionsForUser;
        },
        getPermissionsForUserCanArchiveTask(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            //console.log("getPermissionsForUserCanArchiveTask")
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_archive_task");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },
        getPermissionsForUserCanEditTask(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_edit_task");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },
        getPermissionsForUserCanAddFile(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_add_file");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },
        getPermissionsForUserCreateManualTask(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_create_manual_task");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },

        getPermissionsForUserCanDeleteTags(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_delete_tags");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },

        getPermissionsForUserCanOffTags(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_off_tags");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },

        getPermissionsForUserCanSeeTask(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                ////console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_see_task");
                ////console.log(per)
                return {status: per[0].has_permission, users: per[0].users}
            }
            return {status: false, users: []};
        },
        getPermissionsForUserCanAssignTask(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_assign_task");
                //console.log(per)
                return {status: per[0].has_permission, users: per[0].users}
            }
            return {status: false, users: []};
        },
        getPermissionsForUserCanManualTask(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_view_manual_task");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },
        getPermissionsForUserCanViewArchive(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_view_archive_task");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },

        getPermissionsForUserDeleteOrRenameFile(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_delete_or_rename_file");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },

        getPermissionsForUserCreateVesselTasks(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_create_ship_task");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },
        getPermissionsForUserCreateCargoTasks(state) {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                //console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === "can_create_cargo_task");
                //console.log(per)
                return per[0].has_permission;
            }
            return false;
        },
        getPermissionsByType: (state) => (name) => {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                ////console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === name);
                ////console.log(per)
                if(per.length !== 0) {
                    return per[0].has_permission;
                }
                return false
            }
            return false;
        },
        getPermissionsFullByType: (state) => (name) => {
            if(state.permissionsForUser.length === 0 && localStorage.getItem("per") !== undefined && localStorage.getItem("per") !== null && (""+localStorage.getItem("per")).length !== 0) {
                state.permissionsForUser = JSON.parse(localStorage.getItem("per"));
            }
            if(state.permissionsForUser.length !== 0) {
                ////console.log(state.permissionsForUser)
                let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === name);
                ////console.log(per)
                if(per.length !== 0) {
                    return per[0];
                }
                return false
            }
            return false;
        },
        // getNameAccessiblePage(state) {
        //     function getPermOfName(name) {
        //         let per = JSON.parse(JSON.stringify(state.permissionsForUser)).filter(p => p.type === name);
        //         if(per.length !== 0) {
        //             return per[0];
        //         }
        //         return false
        //     }
        //     if(getPermOfName('')) {
        //         return 'employees'
        //     }
        //
        // },
    },
}
