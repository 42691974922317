import axios from "axios";
import * as QueryString from "querystring";
import Vue from 'vue';
import moment from 'moment';
Vue.prototype.$moment = moment;

export default {
    actions: {
        async axiosGetYearsFoeRedeliveryVessels(ctx, data) {
            let params = {};
            if(data !== null && data !== undefined) {

                if(data.is_redelivery !== undefined && data.is_redelivery !== null) {
                    params['is_redelivery']= data.is_redelivery
                }
                if(data.is_archive !== undefined && data.is_archive !== null) {
                    params["is_archive"] = data.is_archive
                }
                if(data.search !== null && data.search !== undefined) {
                    params["search"] = data.search;
                }
                if(data.propsData !== null && data.propsData !== undefined) {
                    if(data.propsData.search !== null && data.propsData.search !== undefined) {
                        params["search"] = data.propsData.search;
                    }
                    if(data.propsData.contract_type !== null && data.propsData.contract_type !== undefined) {
                        params["contract_type"] = data.propsData.contract_type;
                    } else {
                        params["contract_type"] = ["SHIP"];
                    }
                    if(data.propsData.owners !== undefined && data.propsData.owners !== null) {
                        params["owner"] = data.propsData.owners;
                    }
                    if(data.propsData.charter_names !== undefined && data.propsData.charter_names !== null) {
                        params['owner_charter_name'] = data.propsData.charter_names
                    }
                    params["operation_manager"] = data.propsData.operations;
                    params["chartering_manager"] = data.propsData.chartering;
                    params["cp_date_after"] = data.propsData.cp_date_from;
                    params["cp_date_before"] = data.propsData.cp_date_to;

                    params["dwt_min"] = data.propsData.dwt_min;
                    params["dwt_max"] = data.propsData.dwt_max;
                    params["built_min"] = data.propsData.buit_min;
                    params["built_max"] = data.propsData.buit_max;
                    params["ordering"] = data.propsData['ordering'] ? data.propsData['ordering'] : 'name'
                }
            }

            params["ordering"] = params["ordering"] ? params["ordering"] : 'name'
            return await axios.get(process.env.VUE_APP_URL+'/contract/years/', {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
                params: params
            }).then(res=> {
                ctx.state.yearsRedelivery = res.data.years
                return true
            }).catch(()=> {
                ctx.state.yearsRedelivery = []
                return false
            })
        },

        getAxiosShips(ctx, data) {
            return axios.get(process.env.VUE_APP_URL+"/search/ship/", {
                params: {
                    ordering: "created_at",
                    task: data.task,
                    region: data.region,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                            return QueryString.stringify(params)
                },
            }).then(response=> {
                ctx.commit("setShipsList", response.data.results)
            })
        },
        getAxiosShipsTemplate(ctx, data) {
            return axios.get(process.env.VUE_APP_URL+"/search/ship/template", {
                params: {
                    // ordering: "created_at",
                    region: data.region,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response=> {
                ctx.commit("setShipsListTemplate", response.data.results)
            });
        },
        axiosChangeShip(ctx, data) {
          return axios({
              method: 'patch',
              url: process.env.VUE_APP_URL + "/search/ship/" + data.id,
              data: data.data,
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(response=> {
            return response;
          }).catch(()=> {
              return false;
          })
        },
        getAxiosPortsForVessel(ctx) {
          return axios.get(process.env.VUE_APP_URL + "/port", {
              params: {
                  is_archive: false,
                  ordering: "name",
              },
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(response => {
              ctx.commit("setPortsForVessel", response.data.results)
          })
        },
        axiosCreateCommentForShip(ctx, data) {
            return axios.post(process.env.VUE_APP_URL + "/search/ship/comment/", {
                "comment": data.comment,
                "ship": data.ship,
                "broker": data.broker
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                return response;
            }).catch(()=> {
                return false;
            })
        },
        axiosChangeCommentForShip(ctx, data) {
            return axios.patch(process.env.VUE_APP_URL + "/search/ship/comment/" + data.id, {
                "comment": data.comment,
                "ship": data.ship,
                "broker": data.broker
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response=> {
                return response;
            }).catch(()=> {
                return false;
            })
        },
        async axiosCreateSearchShip(ctx, data) {
            await axios.post(process.env.VUE_APP_URL + "/search/ship/", {
                "name": data.data.name,
                "dwt": data.data.dwt,
                "year": data.data.year,
                "region": data.data.region,
                "fuel_use": data.data.fuel_use,
                "ship_arrival": data.data.ship_arrival,
                "position": data.data.position,
                "is_rejected": data.data.is_rejected,
                "is_favorite": data.data.is_favorite,
                "on_subs": data.data.on_subs,
                "task": data.task,
                "delivery": data.data.delivery,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                return response;
            }).catch(()=> {
                return false;
            })
        },
        axiosCreateSearchShipRESP(ctx, data) {
            return  axios.post(process.env.VUE_APP_URL + "/search/ship/", {
                "name": data.data.name,
                "dwt": data.data.dwt,
                "year": data.data.year,
                "region": data.data.region,
                "fuel_use": data.data.fuel_use,
                "ship_arrival": data.data.ship_arrival,
                "position": data.data.position,
                "is_rejected": data.data.is_rejected,
                "is_favorite": data.data.is_favorite,
                "on_subs": data.data.on_subs,
                "task": data.task,
                "delivery": data.data.delivery,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                return response;
            }).catch(()=> {
                return false;
            })
        },
        async axiosCreateSearchShipRESPAsync(ctx, data) {
            await axios.post(process.env.VUE_APP_URL + "/search/ship/", {
                "name": data.data.name,
                "dwt": data.data.dwt,
                "year": data.data.year,
                "region": data.data.region,
                "fuel_use": data.data.fuel_use,
                "ship_arrival": data.data.ship_arrival,
                "position": data.data.position,
                "is_rejected": data.data.is_rejected,
                "is_favorite": data.data.is_favorite,
                "on_subs": data.data.on_subs,
                "task": data.task,
                "delivery": data.data.delivery,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                return response;
            }).catch(()=> {
                return false;
            })
        },
        axiosChangeParametersForContract(ctx, data) {
          return axios.patch(process.env.VUE_APP_URL + "/contract/" + data.id, data.data, {
              headers: {
                  'Authorization': 'token ' + ctx.getters.getToken,
              }
          }).then(()=> {
              return true;
          }).catch(()=> {
              return false;
          })
        },
        async axiosChangeParametersForContractAsync(ctx, data) {
            return await axios.patch(process.env.VUE_APP_URL + "/contract/" + data.id, data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            })
        },
        async axiosAsyncChangeParametersForContract(ctx, data) {
            await axios.patch(process.env.VUE_APP_URL + "/contract/" + data.id, data.data, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(()=> {
                return true;
            }).catch(()=> {
                return false;
            })
        },
        async axiosCreatContractForShip(ctx, data) {

            // ctx.commit("cancelTokenContractCANCEL");
            // console.log(data.files)
            let files = []
            if(data.files.length) {
                for (let i = 0; i < data.files.length; i++) {
                    const file = data.files[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    files.push(await ctx.dispatch('axiosAddFileReturnData', formData))
                }
            }
            // console.log("filesId");
            // console.log(files);
            let filesID = files.map(a=> {
                return a.id
            })
            return axios.post(process.env.VUE_APP_URL + "/contract", {
                "name": data.name,
                "dwt": data.dwt,
                "built" : data.built,
                "percentage": data.percentage,
                "cp_date": data.cp_date,
                "delivery": (data.delivery !== null && (""+data.delivery).length !== 0) ? moment(data.delivery).format("YYYY-MM-DD HH:mm") : null,
                "redelivery": (data.redelivery !== null && (""+data.redelivery).length !== 0) ? moment(data.redelivery).format("YYYY-MM-DD HH:mm") : null,
                "period": data.period,
                "laycan_date": data.laycan_date,
                "hire_rate": data.hire_rate,
                "currency": data.currency,
                "comment": data.comment,
                "cargo_comment": data.cargo_comment,
                "ship": data.ship,
                "task": data.task,
                "operation_manager": data.operation_manager,
                "chartering_manager": data.chartering_manager,
                "broker": data.broker,
                "owner": data.owners,
                "delivery_place": data.delivery_place,
                "redelivery_place": data.redelivery_place,
                "files": filesID,
                "contract_type": data.contract_type,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                response["file"] = files;
                if(ctx.getters.getDataContractV !== null && response.data.contract_type !== 'SHIP') {
                    let dataContract = ctx.getters.getDataContractV;
                    dataContract.cargo_contracts = [response.data].concat(dataContract.cargo_contracts)
                    ctx.commit('setDataContract', dataContract)
                }
                return response;
            }).catch(()=> {
                return false;
            })
        },
        async axiosChangeContractForShip(ctx, data) {
            // console.log(data.files)
            let files = []
            if(data.files.length) {
                for (let i = 0; i < data.files.length; i++) {
                    const file = data.files[i];
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("name", file.name);
                    files.push(await ctx.dispatch('axiosAddFileReturnData', formData))
                }
            }
            data.filesContract.map(a=> {
                files.push(a);
            })
            // console.log("filesId");
            // console.log(files);
            let filesID = files.map(a=> {
                return a.id
            })
            ctx.commit('showLoad')
            return axios.patch(process.env.VUE_APP_URL + "/contract/" + data.id, {
                "name": data.name,
                "dwt": data.dwt,
                "built" : data.built,
                "percentage": data.percentage,
                "cp_date": data.cp_date,
                "delivery": (data.delivery !== null && (""+data.delivery).length !== 0) ? moment(data.delivery).format("YYYY-MM-DD HH:mm") : null,
                "redelivery": (data.redelivery !== null && (""+data.redelivery).length !== 0) ? moment(data.redelivery).format("YYYY-MM-DD HH:mm") : null,
                "period": ''+data.period,
                "laycan_date": data.laycan_date,
                "hire_rate": data.hire_rate,
                "currency": data.currency,
                "comment": data.comment,
                "cargo_comment": data.cargo_comment,
                "ship": data.ship,
                "task": data.task,
                "operation_manager": data.operation_manager,
                "chartering_manager": data.chartering_manager,
                "broker": data.broker,
                "owner": data.owners,
                "delivery_place": data.delivery_place,
                "redelivery_place": data.redelivery_place,
                "files": filesID,
                "contract_type": data.contract_type,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                ctx.commit('hideLoad')
                response["file"] = files;
                if(ctx.getters.getDataContractV !== null && response.data.contract_type !== 'SHIP') {
                    let dataContract = ctx.getters.getDataContractV;
                    dataContract.cargo_contracts = dataContract.cargo_contracts.map(a=> {
                        if(a.id === response.data.id)
                            a = response.data
                        return a
                    })
                    ctx.commit('setDataContract', dataContract)
                }
                return response;
            }).catch(()=> {
                return false;
            })
        },
        async axiosCheckContract(ctx, id) {
            let res = null;
            await axios.get(process.env.VUE_APP_URL + "/contract/?task=" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            }).then(response => {
                console.log("CONTRACRT")
                console.log(response.data.results)
                if(response.data.results.length !== 0) {
                    res = true;
                } else {
                    res = false
                }
            }).catch(()=> {
                res = false;
            })
            return res;
        },
        async axiosDeleteShip(ctx, id) {
            await axios.delete(process.env.VUE_APP_URL + "/search/ship/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
        axiosShipDuplicate(ctx, data) {
            return axios.post(process.env.VUE_APP_URL + "/search/ship/duplicate/", {
                "ship_ids": data.ship_ids,
                "task_id": data.task_id,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                }
            })
        },
        axiosArchiveContractByIDS(ctx, IDS) {
            return axios.post(process.env.VUE_APP_URL + '/contract/archive', {
                ids: IDS,
                is_archived: true,
            }, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            })
        },
        async axiosGetAllContractsLocal(ctx, data) {
            ctx.commit("cancelTokenContractCANCEL",);
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenContract", ct);
            let params = {limit: 999, contract_type: 'SHIP'};
            // console.log("axiosGetAllContracts")
            // console.log(data)
            // params["limit"] = 10
            if(data !== null && data !== undefined) {
                if(data.is_redelivery !== undefined && data.is_redelivery !== null) {
                    params['is_redelivery']= data.is_redelivery
                }
                if(data.is_archive !== undefined && data.is_archive !== null) {
                    params["is_archive"] = data.is_archive
                }
                if(data.search !== null && data.search !== undefined) {
                    params["search"] = data.search;
                }
            }
            params["ordering"] = 'name'
            return await axios.get(process.env.VUE_APP_URL + "/contract/", {
                params: params,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                cancelToken: ct.token,
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                return response.data.results
            })
        },
        axiosGetAllContracts(ctx, data) {
            ctx.commit("cancelTokenContractCANCEL",);
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenContract", ct);
            let params = {limit: 20};
            // console.log("axiosGetAllContracts")
            // console.log(data)
            // params["limit"] = 10
            if(data !== null && data !== undefined) {
                if(data.limit !== undefined && data.limit !== null) {
                    params["limit"] = data;
                }
                if(data.is_redelivery !== undefined && data.is_redelivery !== null) {
                    params['is_redelivery']= data.is_redelivery
                }
                if(data.is_archive !== undefined && data.is_archive !== null) {
                    params["is_archive"] = data.is_archive
                }
                if(data.search !== null && data.search !== undefined) {
                    params["search"] = data.search;
                }
                if(data.propsData !== null && data.propsData !== undefined) {
                    if(data.propsData.search !== null && data.propsData.search !== undefined) {
                        params["search"] = data.propsData.search;
                    }
                    // if(data.propsData.not_linked_cargo !== null && data.propsData.not_linked_cargo !== undefined) {
                    //     params["not_linked_cargo"] = data.propsData.not_linked_cargo;
                    // }
                    if(data.propsData.contract_type !== null && data.propsData.contract_type !== undefined) {
                        params["contract_type"] = data.propsData.contract_type;
                    } else {
                        params["contract_type"] = ["SHIP"];
                    }
                    if(data.propsData.owners !== undefined && data.propsData.owners !== null) {
                        params["owner"] = data.propsData.owners;
                    }
                    if(data.propsData.charter_names !== undefined && data.propsData.charter_names !== null) {
                        params['owner_charter_name'] = data.propsData.charter_names
                    }
                    params["operation_manager"] = data.propsData.operations;
                    params["chartering_manager"] = data.propsData.chartering;
                    params["cp_date_after"] = data.propsData.cp_date_from;
                    params["cp_date_before"] = data.propsData.cp_date_to;

                    params["dwt_min"] = data.propsData.dwt_min;
                    params["dwt_max"] = data.propsData.dwt_max;
                    params["built_min"] = data.propsData.buit_min;
                    params["built_max"] = data.propsData.buit_max;
                    params["ordering"] = data.propsData['ordering'] ? data.propsData['ordering'] : 'name'
                }
            }

            params["ordering"] = params["ordering"] ? params["ordering"] : 'name'


            console.log('params', params)
            // ctx.commit('showLoad')
            return axios.get(process.env.VUE_APP_URL + "/contract/", {
                params: params,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                cancelToken: ct.token,
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                if(!data.notSetContract) {
                    ctx.commit("setContracts", response.data.results);
                    ctx.commit("setNextVesselsLink", response.data.next)
                }
                if(data.pushContract === true) {
                    ctx.commit("addNewContracts", response.data.results);
                    return {year: data.year, linkNext: response.data.next}
                }
            })
        },
        axiosGetNextVessels(ctx, next) {
            return axios.get(next, {
                limit: 10,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                ctx.commit("addNewContracts", response.data.results);
                ctx.commit("setNextVesselsLink", response.data.next)
                return response.data.next
                // ctx.commit('hideLoad')
            })
        },
        axiosGetNextVesselsCargo(ctx, next) {
            return axios.get(next, {
                limit: 10,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                ctx.commit("addNewContractsCargo", response.data.results);
                ctx.commit("setNextVesselsCargoLink", response.data.next)
                // ctx.commit('hideLoad')
            })
        },
        axiosGetAllContractsCargo(ctx, data) {
            ctx.commit("cancelTokenContractCANCEL1",);
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenContract1", ct);
            let params = {limit: 999999};
            if(data !== null && data !== undefined) {
                if(data.is_redelivery !== undefined && data.is_redelivery !== null) {
                    params = {is_redelivery: data.is_redelivery}
                }
                if(data.is_archive !== undefined && data.is_archive !== null) {
                    params["is_archive"] = data.is_archive
                }
                if(data.search !== null && data.search !== undefined) {
                    params["search"] = data.search;
                }
                if(data.propsData !== null && data.propsData !== undefined) {
                    if(data.propsData.search !== null && data.propsData.search !== undefined) {
                        params["search"] = data.propsData.search;
                    }
                    // if(data.propsData.not_linked_cargo !== null && data.propsData.not_linked_cargo !== undefined) {
                    // }
                    // if(data.propsData.contract_type !== null && data.propsData.contract_type !== undefined) {
                    //     params["contract_type"] = data.propsData.contract_type;
                    // }
                        params["contract_type"] = ["CARGO", "SUBLET"];
                    // }
                    params["owner"] = data.propsData.owners;
                    if(data.propsData.charter_names !== undefined && data.propsData.charter_names !== null) {
                        params['owner_charter_name'] = data.propsData.charter_names
                    }
                    params["operation_manager"] = data.propsData.operations;
                    params["chartering_manager"] = data.propsData.chartering;
                    params["cp_date_after"] = data.propsData.cp_date_from;
                    params["cp_date_before"] = data.propsData.cp_date_to;

                    params["dwt_min"] = data.propsData.dwt_min;
                    params["dwt_max"] = data.propsData.dwt_max;
                    params["built_min"] = data.propsData.buit_min;
                    params["built_max"] = data.propsData.buit_max;

                }
            }
            params["not_linked_cargo"] = true;
            params["limit"] = 10
            params["ordering"] = '-owner'


            // ctx.commit('showLoad')
            return axios.get(process.env.VUE_APP_URL + "/contract/", {
                params: params,
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                cancelToken: ct.token,
                paramsSerializer: function (params) {
                    return QueryString.stringify(params)
                },
            }).then(response => {
                ctx.commit("setContractsCargo", response.data.results);
                ctx.commit("setNextVesselsCargoLink", response.data.next)
            })
        },


        axiosSearchShipTemplate(ctx, name) {
            ctx.commit('cancelTokenContractCANCEL')
            let ct = axios.CancelToken.source();
            ctx.commit("cancelTokenContract", ct);
            return axios.get(process.env.VUE_APP_URL + "/search/ship/template/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    search: name
                },
                cancelToken: ct.token,
            }).then(res=> {
                return res;
            }).catch(()=> {
                return false;
            })
        },
        axiosSearchShipDelivery(ctx, name) {
            return axios.get(process.env.VUE_APP_URL + "/search/ship/delivery/", {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
                params: {
                    s: name
                }
            }).then(res=> {
                return res;
            }).catch(()=> {
                return false;
            })
        },
        async axiosDeleteShipsWorkSpace(ctx, id) {
            return await axios.delete(process.env.VUE_APP_URL + "/search/ship/" + id, {
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(()=> {
                return true
            }).catch(()=> {
                return false
            })
        },
        axiosGetShipsForLinkCargo(ctx, search) {
            return axios.get(process.env.VUE_APP_URL + "/contract/", {
                params: {
                    ordering: 'name',
                    contract_type: "SHIP",
                    is_archive: false,
                    limit: 10,
                    search: search,
                },
                headers: {
                    'Authorization': 'token ' + ctx.getters.getToken,
                },
            }).then(res=> {
                return res;
            }).catch(()=> {
                return [];
            })
        }

    },
    mutations: {
        setNextVesselsLink(state, data) {
            state.nextVessels = data;
        },
        setNextVesselsCargoLink(state, data) {
            state.nextVesselsCargo = data;
        },
        setContracts(state, data) {
            data = data.map(a=> {
                // a.cargo_contracts = a.cargo_contracts.filter(b=> b.is_archive === false)
                return a
            })
          state.contracts = data
        },
        addNewContracts(state, data) {
            data.map(a=> {
                state.contracts.push(a);
            })
        },
        setContractsCargo(state, data) {
            state.contractsCargo = data
        },
        addNewContractsCargo(state, data) {
            data.map(a=> {
                state.contractsCargo.push(a);
            })
        },
        setShipsList(state, data) {
            state.shipsList = data
        },
        setShipsListTemplate(state, data) {
            state.shipsListTemplate = data;
        },
        setPortsForVessel(state, data) {
            state.portsForVessel = data;
        },
        moveModeChange(state) {
            state.moveMode = !state.moveMode;
        },
        moveModeChangeFalse(state) {
            state.moveMode = false;
        },
        cancelTokenContract(state, data) {
            state.cancelTokenSource = data;
        },
        cancelTokenContractCANCEL(state) {
            if(state.cancelTokenSource !== null) {
                state.cancelTokenSource.cancel();
            }
        },
        cancelTokenContract1(state, data) {
            state.cancelTokenSource1 = data;
        },
        cancelTokenContractCANCEL1(state) {
            if(state.cancelTokenSource1 !== null) {
                state.cancelTokenSource1.cancel();
            }
        },
        setStatusOpenEditModelInDetail(state, data) {
            state.statusOpenEditModelInDetail = data;
        },
        setStatesLeftMenu(state, data) {
            state.statesLeftMenu = data
        },
        SetChangeNameVesselEmitForListLeftMenu(state, data) {
            state.changeNameVesselEmitForListLeftMenu = data;
        },
    },
    state: {
        yearsRedelivery: [],
        changeNameVesselEmitForListLeftMenu: null,
        statesLeftMenu: false,
        nextLinkForShipLink: null,
        statusOpenEditModelInDetail: false,
        shipsList: [],
        shipsListTemplate: [],
        portsForVessel: [],
        moveMode: false,
        contracts: [],
        contractsCargo: [],
        cancelTokenSource: null,
        cancelTokenSource1: null,
        nextVesselsCargo: null,
        nextVessels: null,
    },
    getters: {
        getNextVessels(state) {
            return state.nextVessels;
        },
        getNextVesselsCargo(state) {
            return state.nextVesselsCargo;
        },
        getContracts(state) {
            return state.contracts;
        },
        getContractsCargo(state) {
            return state.contractsCargo
        },
        getShipsList(state) {
            return state.shipsList;
        },
        getShipsListTemplate(state) {
            return state.shipsListTemplate;
        },
        getPortsForVessel(state) {
            return state.portsForVessel;
        },
        getMoveMode(state) {
            return state.moveMode;
        },
        getStatesLeftMenu(state) {
            return state.statesLeftMenu
        }
    },
}
