<template>
  <div>
    <p class="text-gray fz14 fw500 mt16">Voyage (Owners)</p>
    <folders-list-links-charterers class="mt8" :items="voyage_owners"></folders-list-links-charterers>
    <p class="text-gray fz14 fw500 mt8">Voyage (Charterers)</p>
    <folders-list-links-charterers class="mt8" :items="voyage_charterers"></folders-list-links-charterers>
    <p class="text-gray fz14 fw500 mt8">Sublet</p>
    <folders-list-links-charterers class="mt8" :items="sublets"></folders-list-links-charterers>

  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import FoldersListLinksCharterers from "@/components/folders/folders-list-links-charterers.vue";
import moment from 'moment';
export default {
  props: {
    search: String,
    sort: String,
  },
  name: "folders-vessels-option-charterers",
  components: {FoldersListLinksCharterers},
  computed: {
    getIdShip() {
      let data = this.$route.params.detailVessel ? JSON.parse(this.$route.params.detailVessel) : null
      return data ? data.id : null
    },
  },
  data() {
    return {
      voyage_owners: [],
      nextLinkVoyageOwners: null,
      voyage_charterers: [],
      nextLinkVoyageCharterers: null,
      sublets: [],
      nextLinkSublets: null,
    }
  },
  watch: {
    sort: function () {
      this.loadData()
    },
    search: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions(['axiosGetCharterersForFolders']),
    ...mapMutations(['showLoad', 'hideLoad', 'setNameSectionFolders']),
    async loadData() {
      console.log('owners_is_archive', this.$route.params.charterers_is_archive)
      let res = await this.axiosGetCharterersForFolders({contract_id: this.getIdShip, contract_type: 'VOYAGE_CHARTERER', search: this.search, is_archive: this.$route.params.charterers_is_archive, ordering: this.sort, limit: 20})
      if(res) {
        res.data.results.forEach(item=> {
          item['link'] = {name: 'Folders - Vessels Option Charterers Files', params: {files_is_archive: false, charterersDetail: JSON.stringify({type: 'Voyage', id: item.id, cp_date: moment(item.cp_date).format('DD.MM.YYYY'), owner_name: item.owner ? item.owner.company_name : ''})}}
        })
        this.voyage_owners = res.data.results
        this.nextLinkVoyageOwners = res.data.next
      }
      res = await this.axiosGetCharterersForFolders({contract_id: this.getIdShip, contract_type: 'CARGO',search: this.search, is_archive: this.$route.params.charterers_is_archive, ordering: this.sort, limit: 20})
      if(res) {
        res.data.results.forEach(item=> {
          item['link'] = {name: 'Folders - Vessels Option Charterers Files', params: {files_is_archive: false, charterersDetail: JSON.stringify({type: 'Voyage', id: item.id, cp_date: moment(item.cp_date).format('DD.MM.YYYY'), owner_name: item.owner ? item.owner.company_name : ''})}}
        })
        this.voyage_charterers = res.data.results
        this.nextLinkVoyageCharterers = res.data.next
      }
      res = await this.axiosGetCharterersForFolders({contract_id: this.getIdShip, contract_type: 'SUBLET', search: this.search, is_archive: this.$route.params.charterers_is_archive, ordering: this.sort, limit: 20})
      if(res) {
        res.data.results.forEach(item=> {
          item['link'] = {name: 'Folders - Vessels Option Charterers Files', params: {files_is_archive: false, charterersDetail: JSON.stringify({id: item.id, name: item.name, type: 'sublet', cp_date: moment(item.cp_date).format('DD.MM.YYYY'), owner_name: item.owner ? item.owner.company_name : ''})}}
        })
        this.sublets = res.data.results
        this.nextLinkSublets = res.data.next
      }
    },
  },
  mounted() {
    this.setNameSectionFolders('Vessels')
    this.loadData()
  }
}
</script>

<style scoped>

</style>