import {mapActions, mapMutations} from "vuex";

var elementDrag = null;
export default {
    data() {
        return {
            dragModeActive: false,
            mouseDownState: false,
            timeOut: null,
            elementDrag: null,
            eventChangeCursor: null,
            firstXYPosition: '',
            idItem: null,
            dragSelected: false,

            dragStart: null,
            timeOutClearDraFolder: null,
            safariMode: false,
        }
    },
    computed: {
        getIdDragAccount() {
          let item = this.mailsList.find(a=> a.id == this.idItem)
          return item ? item.account_id : null
        },
    },
    watch: {
        dragStart: function () {
          // if(Number.isNumber(this.dragStart) == true) {
          //
          // }  else {
          //
          // }
        },
        mailsList: function () {
            this.setOptionalDragAndDrop()
        },
        // account_list: {
        //     async handler(){
        //         await this.$nextTick()
        //         let items = document.querySelectorAll('.sidebar-row-heading, .inside-folder')
        //         let indexActive = -1;
        //         items.forEach((item, index)=> {
        //             item.addEventListener('mouseenter', ()=> {
        //                 if(this.dragModeActive && !item.classList.contains('not-attach')) {
        //                     if(indexActive !== index) {
        //                         console.log('mouseenter true')
        //                         item.classList.add('bg-peach')
        //                         indexActive = index
        //                     }
        //                     items.forEach((el, i)=> {
        //                         if(i !== indexActive) {
        //                             el.classList.remove('bg-peach')
        //                         }
        //                     })
        //                 }
        //             })
        //             item.addEventListener('mouseup', ()=> {
        //                 // alert(1)
        //                 console.log('mouseup', this.dragModeActive, !item.classList.contains('not-attach'))
        //                 if(this.dragModeActive && !item.classList.contains('not-attach')) {
        //                     console.log(11111)
        //                 }
        //             })
        //         })
        //     },
        //     deep: true,
        // },
        dragModeActive: function () {

            if(this.dragModeActive) {
                elementDrag = document.createElement("div")
                elementDrag.classList.add('drag-element-count')
                elementDrag.innerText = this.dragSelected ? this.checkedEmails.length : 1
                document.body.classList.add('unselectable')
                document.body.appendChild(elementDrag)
                elementDrag.style.cssText = this.firstXYPosition
                document.addEventListener("mousemove", this.changePositionElement);


            } else {
                elementDrag.remove()
                elementDrag = null
                document.removeEventListener("mousemove", this.changePositionElement)
                document.body.classList.remove('unselectable')
                this.firstXYPosition = ''
                let items = document.querySelectorAll('.sidebar-row-heading, .inside-folder')
                items.forEach(item=> {
                    item.classList.remove('bg-peach')
                })
                this.idsDisabledSection = []
            }
        }
    },
    methods: {
        ...mapActions(['axiosMailboxUpdateFolder']),
        ...mapMutations(['showLoad', 'hideLoad']),
        async dragEndFolder() {
            this.timeOutClearDraFolder = setTimeout(()=> {
                this.dragStart = null
            }, 700)
        },
        dragStartFolder(id) {
            if(this.timeOutClearDraFolder) {
                clearTimeout(this.timeOutClearDraFolder)
            }
            this.dragStart = id
        },
        changePositionElement(event) {
            // console.log('mounsemove', event.target.classList )

            if(this.safariMode && !event.target.classList.contains('bg-peach') && (event.target.classList.contains('sidebar-row-heading') || event.target.classList.contains('inside-folder'))) {
                let items = document.querySelectorAll('.sidebar-row-heading, .inside-folder')
                items.forEach(item=> {
                    item.classList.remove('bg-peach')
                })
                event.target.classList.add('bg-peach')
                // console.log('mounsemove', event.target )

            }
            // elementDrag.style.cssText = 'top:'+event.y+'px;left:'+event.x+'px';
            if (window.getSelection) {
                window.getSelection().removeAllRanges();
            } else { // старый IE
                document.selection.empty();
            }
            let item = document.querySelector('.drag-element-count')
            item.style.transform = 'translate3d(' + (event.x + 5) + 'px, ' + (event.y + 5) + 'px, 0px)';
            // event.preventDefault()
        },
        async setOptionalDragAndDrop() {
            await this.$nextTick()
            let objects = document.querySelectorAll('.mail-row')
            objects.forEach((object, index)=> {
                object.addEventListener("mousedown", (event) => {
                    if(event.buttons == 1) {
                        if(this.timeOut) {
                            clearTimeout(this.timeOut)
                        }
                        // console.log('mousedown', event)
                        this.mouseDownState = true
                        this.timeOut = setTimeout(()=> {
                            this.dragSelected = object.classList.contains('checked')
                            this.idItem = this.dragSelected ? null : this.mailsList[index].id
                            this.dragModeActive = true
                            // this.firstXYPosition = 'top:'+event.y+'px;left:'+event.x+'px';
                            this.firstXYPosition = 'translate3d(' + event.x + 'px, ' + event.y + 'px, 0px)';
                        }, 350)
                    }
                });
            })
        },
        moveToMessage(folderId) {
            let data  = {
                ids: this.idItem ? [this.idItem] : this.checkedEmails,
                folder: folderId
            }
            this.showLoad()
            this.axiosUpdateMailboxEmailItem(data).then(()=> {
                this.mailsList = this.mailsList.filter(a=> (this.idItem ? [this.idItem] : this.checkedEmails).indexOf(a.id) == -1 )
                // let account = this.account_list.find(a=> a.id == account_id)
                // account.folders.forEach(folder=> {
                //     if(folder.id == this.activeFolder) {
                //         folder.mails_count -= (this.idItem ? 1 : this.checkedEmails.length)
                //     }
                //     if(folder.id == folderId) {
                //         folder.mails_count += (this.idItem ? 1 : this.checkedEmails.length)
                //     }
                //     if(Array.isArray(folder.child_folders) == true) {
                //         folder.child_folders.forEach(folder=> {
                //             if(folder.id == this.activeFolder) {
                //                 folder.mails_count -= (this.idItem ? 1 : this.checkedEmails.length)
                //             }
                //             if(folder.id == folderId) {
                //                 folder.mails_count += (this.idItem ? 1 : this.checkedEmails.length)
                //             }
                //         })
                //     }
                // })
                this.idItem = null
                this.checkedEmails = []
                this.getAccounts(true)

                // this.hideLoad()
            }).catch(()=> {
                this.idItem = null
                this.checkedEmails = []
                this.hideLoad()
            })
        },
        moveToFolderDrop(idTo) {
            // this.moveToFolder(JSON.parse(JSON.stringify(this.dragStart)), event.target.getAttribute('idFolder'))
            let item = JSON.parse(JSON.stringify(this.dragStart))
            // console.log('drag', idTo, item)
            this.showLoad()
            item['parent_folder'] = idTo
            this.axiosMailboxUpdateFolder(item).then(()=> {
                this.getAccounts(true)

            })
        },
    },
    mounted() {

        if (navigator.userAgent.includes('Safari')) {
            this.safariMode = true
        }
        document.addEventListener('mouseup', event=> {
            // console.log('mouseup', event.target)
            if(this.dragModeActive && event && event.target && event.target.classList && (event.target.classList.contains('inside-folder') || event.target.classList.contains('sidebar-row-heading')) && !event.target.classList.contains('not-attach')) {
                // console.log('mouseup1')
                if(event.target.getAttribute('idFolder')) {
                    this.moveToMessage(event.target.getAttribute('idFolder'), this.getIdDragAccount)
                }
            } else {
                this.idItem = null
            }
            this.mouseDownState = false
            if(this.timeOut) {
                clearTimeout(this.timeOut)
            }
            setTimeout(()=> {
                this.dragModeActive = false
            }, 40)
            if(this.safariMode) {
                let items = document.querySelectorAll('.sidebar-row-heading, .inside-folder')
                items.forEach(item=> {
                    item.classList.remove('bg-peach')
                })
            }

        })
    }
}